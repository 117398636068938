"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _integral = require("@/api/integral.js");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _mem = require("@/api/mem.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/integral.xlsx"),
      successShow: false,
      storeList: [],
      list: [],
      newList: [],
      tabTh: [{
        prop: 'phone',
        label: '会员手机号',
        width: 100
      }, {
        prop: 'score',
        label: '当前积分',
        width: 100
      }, {
        prop: 'number',
        label: '积分变动数',
        width: 80
      }, {
        prop: 'newNumber',
        label: '变动后积分',
        width: 80
      }, {
        prop: 'documentNo',
        label: '相关单据号',
        width: 80
      }, {
        prop: 'storeCode',
        label: '门店编码',
        width: 80
      }, {
        prop: 'state',
        label: '积分变动理由',
        width: 200
      }]
    };
  },
  computed: {
    getnewList: function getnewList() {
      return this.newList.filter(function (item) {
        return item.newNumber >= 0;
      });
    },
    getUnNewList: function getUnNewList() {
      return this.newList.filter(function (item) {
        return item.newNumber < 0;
      });
    }
  },
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({
      companyId: ''
    }).then(function (res) {
      _this.storeList = res.data;
    });
  },
  methods: {
    handleScore: function handleScore() {
      var tHeader = this.tabTh.filter(function (item) {
        return item.prop && item.label;
      }).map(function (item) {
        return item.label;
      });
      var filterVal = this.tabTh.filter(function (item) {
        return item.prop && item.label;
      }).map(function (item) {
        return item.prop;
      });
      this.getHandleDownload(tHeader, filterVal, this.getnewList, '积分可抵扣用户');
    },
    handleUnScore: function handleUnScore() {
      var tHeader = this.tabTh.filter(function (item) {
        return item.prop && item.label;
      }).map(function (item) {
        return item.label;
      });
      var filterVal = this.tabTh.filter(function (item) {
        return item.prop && item.label;
      }).map(function (item) {
        return item.prop;
      });
      this.getHandleDownload(tHeader, filterVal, this.getUnNewList, '积分不足抵扣');
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;

      if (!row.uid || !row.storeCode || !row.state || !row.number || row.newNumber < 0 || !row.documentNo) {
        return 'err-row';
      }
    },
    affirmNext: function affirmNext() {
      var _this2 = this;

      var arr = [];
      this.getnewList.forEach(function (item) {
        if (item.uid && item.storeCode && item.number && item.state && item.newNumber >= 0 && item.documentNo) {
          arr.push({
            userId: item.uid,
            storeCode: item.storeCode,
            changeScore: item.number,
            changeRemark: item.state,
            documentNo: item.documentNo
          });
        }
      });
      console.log(JSON.stringify(arr)); // return

      this.openLoading();
      (0, _integral.userScoreChanges)({
        userScores: arr
      }).then(function (res) {
        _this2.openLoading().close();

        _this2.$router.replace('/integral/adjust/list');
      }).catch(function () {
        _this2.openLoading().close();
      });
    },
    next: function next() {
      var self = this;
      self.newList = [];
      self.openLoading();
      var promises = self.list.map(function (item) {
        return (0, _integral.getTotalScoreByMobile)(item.phone);
      });
      Promise.allSettled(promises).then(function (posts) {
        posts.forEach(function (data, key) {
          if (data.status === 'fulfilled') {
            self.newList.push({
              phone: self.list[key].phone,
              storeCode: self.list[key].storeCode,
              number: self.list[key].number,
              state: self.list[key].state,
              documentNo: self.list[key].documentNo,
              uid: data.value.data.userId,
              score: data.value.data.totalScore,
              newNumber: (data.value.data.totalScore + Number(self.list[key].number)).toFixed(5)
            });
            console.log(self.newList);
          } else {
            self.newList.push({
              phone: self.list[key].phone,
              storeCode: self.list[key].storeCode,
              number: self.list[key].number,
              state: self.list[key].state,
              documentNo: self.list[key].documentNo,
              uid: '',
              score: '',
              newNumber: ''
            });
          }
        });
        self.successShow = true;
        self.openLoading().close();
      }).catch(function (reason) {
        // console.log(reason)
        this.$message.error('网络连接超时，请稍后再试！');
        self.openLoading().close(); // ...
      });
    },
    deleteItem: function deleteItem(index) {
      this.list.splice(index, 1);
    },
    handleSuccess: function handleSuccess(_ref2) {
      var results = _ref2.results,
          header = _ref2.header;
      var arr = [];
      console.log(results);
      results.forEach(function (data) {
        data.changeScore = Math.abs(data.changeScore);
        console.log(data.type);
        arr.push({
          type: data.type,
          phone: data.mobile,
          number: data.type === 1 ? data.changeScore : -data.changeScore,
          state: data.changeRemark,
          storeCode: data.storeCode,
          documentNo: String(data.documentNo).replace(/\s+/g, '')
        });
        /* if (myreg.test(data.phone) && data.card && data.number && data.state) {
           } */
      });
      this.list = arr;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    addList: function addList() {
      this.list.push({
        phone: '',
        card: '',
        number: '',
        state: '',
        storeCode: ''
      });
    }
  }
};
exports.default = _default;