"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("../api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      tableData: [],
      requestData: {
        name: null,
        account: null,
        pageSize: 10,
        page: 1
      },
      searchData: [{
        label: '销售单号',
        type: 'input',
        span: 4,
        placeholder: '请输入销售单号',
        value: 'groupCode'
      }, {
        label: '开始时间',
        type: 'dateTime',
        span: 3,
        placeholder: '请选择开始时间',
        value: 'startDate'
      }, {
        label: '结束时间',
        type: 'dateTime',
        span: 3,
        placeholder: '请选择结束时间',
        value: 'endDate'
      }, {
        label: '状态',
        type: 'radio',
        span: 3,
        placeholder: '选择状态',
        value: 'status',
        select: [{
          label: '待审核',
          value: 1
        }, {
          label: '已审核',
          value: 2
        }, {
          label: '已驳回',
          value: 3
        }]
      }]
    };
  },
  mounted: function mounted() {
    this.$refs.wTable.setRequestValue('type', 2);
  },
  methods: {
    goAudit: function goAudit(row, type) {
      var _self = this;

      (0, _api.goChangeStatus)({
        id: row.id,
        status: type
      }).then(function (res) {
        _self.$refs.wTable.getList();

        _self.$message({
          type: 'success',
          message: '操作成功!'
        });
      });
    }
  }
};
exports.default = _default;