"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateEndCardNo = calculateEndCardNo;
exports.payList = payList;

var _request = _interopRequireDefault(require("@/utils/request"));

function payList(data) {
  return (0, _request.default)({
    url: '/api/card/make/pay/category/list',
    method: 'get'
  });
}

function calculateEndCardNo(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/calculateEndCardNo',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
挂失不用填新卡号

多种支付方式返利  金额不对


出库驳回后  库存没有返回


 */