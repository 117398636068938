"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '聚合支付',
      list: [{
        tit: '设备列表',
        txtArr: '设备列表',
        imgPath: require('@/assets/tab/23.png'),
        path: '/payment/device'
      }, {
        tit: '支付通道模板',
        txtArr: '支付通道模板列表',
        imgPath: require('@/assets/tab/23.png'),
        path: '/payment/tem'
      }, {
        tit: '支付通道',
        txtArr: '支付通道列表',
        imgPath: require('@/assets/tab/23.png'),
        path: '/payment/cfg'
      }, {
        tit: '通道关联',
        txtArr: '通道关联列表',
        imgPath: require('@/assets/tab/20.png'),
        path: '/payment/channels'
      }]
    };
  }
};
exports.default = _default;