"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMerchant = addMerchant;
exports.editMerchant = editMerchant;
exports.getArticle = getArticle;
exports.getFloorOptions = getFloorOptions;
exports.getMerchantById = getMerchantById;
exports.getWxDeptList = getWxDeptList;
exports.getWxDeptPersonList = getWxDeptPersonList;
exports.listMerchantPage = listMerchantPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 品牌
 */
function getArticle(data) {
  return (0, _request.default)({
    url: '/api/merchant/listSupplierInfo',
    method: 'post',
    data: data
  });
}
/*
商户分页

 */


function listMerchantPage(data) {
  return (0, _request.default)({
    url: '/api/merchant/listMerchantPage',
    method: 'post',
    // baseUrl:'https://admin-api.starli.com.cn',
    data: data
  });
}
/*
商户新增

 */


function addMerchant(data) {
  return (0, _request.default)({
    url: '/api/merchant/addMerchant',
    method: 'post',
    data: data
  });
}
/*
商户编辑

 */


function editMerchant(data) {
  return (0, _request.default)({
    url: '/api/merchant/editMerchant',
    method: 'post',
    data: data
  });
}
/*
商户查询

 */


function getMerchantById(data) {
  return (0, _request.default)({
    url: '/api/merchant/getMerchantById',
    method: 'get',
    params: data
  });
}
/*
获取部门
 */


function getWxDeptList(data) {
  return (0, _request.default)({
    url: '/api/merchant/getWxDeptList',
    method: 'post',
    data: data
  });
}
/*
获取指定部门人员信息
 */


function getWxDeptPersonList(data) {
  var id = '';

  if (data) {
    id = '/' + data;
  }

  return (0, _request.default)({
    url: '/api/merchant/getWxDeptPersonList' + id,
    method: 'post'
  });
}

function getFloorOptions(storeId) {
  return (0, _request.default)({
    url: "/api/merchant/listOrgFloor/".concat(storeId),
    method: 'get'
  });
}
/*
获取部门：/api/store/getWxDeptList
获取指定部门人员信息：/api/store/getWxDeptPersonList/{deptId}

 */

/*

 商户管理
 2、新增：/api/store/addMerchant
       参数：

 companyId;   // 公司id
 storeId;   // 门店id
 fullName;  // 商户全称
 shortName;  // 商户简称
 merchantStatus; // 商户状态(1:开业准备、2:正常营业、3:暂停营业、4:作废)
 provinceCode; // 省份编码
 cityCode;  // 城市编码
 countyCode;  // 区县编码
 detailAddress; // 详细地址
 longitude;  // 经度
 latitude;  // 纬度
 contactPeople; // 联系人
 contactPhone; // 联系电话

 3、编辑：/api/store/editMerchant
       参数：merchantId;  // id
 companyId;   // 公司id
 storeId;   // 门店id
 fullName;  // 商户全称
 shortName;  // 商户简称
 merchantStatus; // 商户状态(1:开业准备、2:正常营业、3:暂停营业、4:作废)
 provinceCode; // 省份编码
 cityCode;  // 城市编码
 countyCode;  // 区县编码
 detailAddress; // 详细地址
 longitude;  // 经度
 latitude;  // 纬度
 contactPeople; // 联系人
 contactPhone; // 联系电话

 4、查询单个商户：/api/store/getMerchantById/{merchantId}
 */