"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '新增礼品',
      list: [{
        tit: '逐条录入',
        txtArr: '<p>逐条录入礼品</p>',
        imgPath: require('@/assets/tab/13.png'),
        path: '/member/gift/add'
      }, {
        tit: '批量上传',
        txtArr: '<p>批量上传礼品</p>   ',
        imgPath: require('@/assets/tab/31.png'),
        path: '/member/gift/adds'
      }]
    };
  }
};
exports.default = _default;