var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-member-form" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    新增要客\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticStyle: { width: "100%" },
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "会员手机号：", prop: "mobile" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "form_input",
                          attrs: { disabled: !!_vm.id, placeholder: "请输入" },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.searchMember($event)
                            }
                          },
                          model: {
                            value: _vm.ruleForm.mobile,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "mobile", $$v)
                            },
                            expression: "ruleForm.mobile"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "append", type: "primary" },
                              on: { click: _vm.searchMember },
                              slot: "append"
                            },
                            [_vm._v("添加一项")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            width: "15%",
                            "margin-left": "20px"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "margin-right": "20px",
                                "line-height": "1"
                              },
                              attrs: {
                                href: _vm.downloadUrl,
                                download: "上传手机号码模板.xlsx"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "success",
                                    icon: "el-icon-download"
                                  }
                                },
                                [_vm._v("下载模板")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("upload-excel-component", {
                            attrs: {
                              "btn-text": "导入名单",
                              type: "btn",
                              size: "mini",
                              "on-success": _vm.handleUploadSuccess,
                              "before-upload": _vm.handleBeforeUpload
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "要客等级：", prop: "levelName" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.levelName,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "levelName", $$v)
                        },
                        expression: "ruleForm.levelName"
                      }
                    },
                    _vm._l(_vm.levelList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.name }
                        },
                        [
                          _vm._v(
                            "\n           " + _vm._s(item.name) + "\n         "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.tableData, border: "" }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "ID",
                            type: "index",
                            width: "80",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    "\n               " +
                                      _vm._s(scope.row.id || scope.$index + 1) +
                                      "\n             "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "mobile",
                            label: "手机号",
                            align: "center"
                          }
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            width: "80",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#FF7272"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.delMember(scope.$index)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "amount" }, [
                      _vm._v(
                        "\n           合计数量：" +
                          _vm._s(_vm.tableData.length) +
                          "人\n         "
                      )
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100px", height: "50px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm", "add")
                        }
                      }
                    },
                    [_vm._v("\n         立即新建\n       ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100px", height: "50px" },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }