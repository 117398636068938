"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _mem = require("@/api/mem.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    wTable: _index.default
  },
  data: function data() {
    return {
      orderStatus: [],
      storeList: [],
      downloadLoading: false,
      requestData: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        recommendMobile: null,
        mainUserMobile: null,
        secondUserMobile: null,
        storeCode: null,
        startTime: null,
        endTime: null,
        type: null
      },
      time: '',
      tableData: [],
      columns: [{
        label: '主卡手机号码',
        prop: 'mainUseMobile',
        width: 50
      }, {
        label: '主卡UID',
        prop: 'uid',
        width: 50
      }, {
        label: '副卡手机号码',
        prop: 'secondUserMobile',
        width: 50
      }, {
        label: '副卡UID',
        prop: 'otherUid',
        width: 50
      }, {
        label: '门店',
        prop: 'recommendStoreName',
        width: 50
      }, {
        label: '门店编码',
        prop: 'recommendStore',
        width: 50
      },
      /* {
         label: '员工',
         prop: 'sysUsername',
         width: 50
       }, */
      {
        label: '推广用户电话',
        prop: 'recommendMobile',
        width: 50
      }, {
        slot: 'type'
      }, {
        label: '备注',
        prop: 'remark',
        width: 60
      }, {
        label: '操作时间',
        prop: 'handleTime',
        width: 50
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      console.log(res);
      _this.storeList = res.data;
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    goRelieve: function goRelieve(row) {
      var _this2 = this;

      if (row.type !== 1) return;

      var _self = this;

      this.$confirm('此操作将解绑亲友卡, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _api.relieveViceCard)({
          phone: row.mainUseMobile
        }).then(function (res) {
          _self.getList();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消解绑'
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this3.requestData[key]) {
          data[key] = _this3.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.selectMainUserBindLogList)(data).then(function (res) {
        res.data.records.forEach(function (item) {
          item.storeName = item.sysUserVo ? item.sysUserVo.deptName : null;
          item.storeId = item.sysUserVo ? item.sysUserVo.deptCode : null;
          item.sysUsername = item.sysUser ? item.sysUser.username : null;
          item.sysMobile = item.sysUser ? item.sysUser.mobile : null;
          item.otherMobile = item.otherUser ? item.otherUser.mobile : null;
          item.mainMobile = item.mainUser ? item.mainUser.mobile : null;
        });
        list = res.data.records;
        _this3.downloadLoading = false;

        _this3.getHandleDownload(tHeader, filterVal, list, '亲友卡绑定记录');
      }).catch(function (res) {
        _this3.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this4 = this;

      if (type == 'search') {
        this.pageNo = 1;
      }

      if (type == 'remove') {
        this.time = '';
        this.removeObj(this.requestData);
      }

      this.requestData.page = this.requestData.pageNo;
      (0, _api.selectMainUserBindLogList)(this.requestData).then(function (res) {
        /* res.data.items.forEach((item) => {
          item.storeName = item.sysUserVo ? item.sysUserVo.deptName : null
          item.storeId = item.sysUserVo ? item.sysUserVo.deptCode : null
          item.sysUsername = item.sysUser ? item.sysUser.username : null
          item.sysMobile = item.sysUser ? item.sysUser.mobile : null
           item.otherMobile = item.otherUser ? item.otherUser.mobile : null
          item.mainMobile = item.mainUser ? item.mainUser.mobile : null
        }) */
        if (!_this4.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.secondUserMobile) {
              item.secondUserMobile = item.secondUserMobile.replace(item.secondUserMobile.substring(3, 7), "****");
            }

            if (item.mainUseMobile) {
              item.mainUseMobile = item.mainUseMobile.replace(item.mainUseMobile.substring(3, 7), "****");
            }
          });
        }

        _this4.tableData = res.data.items;
        _this4.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;