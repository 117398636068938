"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _axios = _interopRequireDefault(require("axios"));

var _router = _interopRequireDefault(require("@/router"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _auth = require("@/utils/auth");

var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  //baseURL: baseUrl(),
  isErrMsg: false,
  // withCredentials: true,
  timeout: 100000 // request timeout

});

var loadingInstance, requestData;
var num = 0;
service.interceptors.request.use(function (config) {
  config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  config.headers['Access-Control-Allow-Headers'] = 'X-Requested-With,Content-Type,Accept,Origin';
  config.headers['authorized_key'] = 'afsadfasdfasdfasdf';

  if (_store.default.getters.token) {
    config.headers['Token'] = (0, _auth.getToken)();
  }

  requestData = config; //num++
  //if (requestData.loading == 'loading') {

  if (requestData.loading !== false) loadingInstance = _elementUi.Loading.service({
    text: '加载中...',
    background: 'rgba(0,0,0,0.2)'
  }); //}

  return config;
}, function (error) {
  return Promise.reject(error);
});
service.interceptors.response.use(function (response) {
  //if (requestData.loading == 'loading') {
  if (requestData.loading !== false) loadingInstance.close(); //}

  var res = response.data;

  if (res.ret === 6000) {
    console.log(_router.default.currentRoute.path);

    if (_router.default.currentRoute.path === '/login') {
      return;
    }

    _elementUi.MessageBox.confirm(res.msg, '系统提示', {
      confirmButtonText: '去登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    });

    return Promise.reject(new Error(res.msg || 'Error'));
  } else if (res.ret !== 1000) {
    /* MessageBox.confirm(res.msg, '系统提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning'
      }) */
    var str = '';

    if (res.data) {
      var strArr = [];
      Object.keys(res.data).map(function (item) {
        strArr.push(res.data[item]);
      });
      str = strArr.join('、');
    }

    if (!requestData.isErrMsg) {
      (0, _elementUi.Message)({
        message: str || res.msg,
        type: 'error',
        duration: 5 * 1000
      });
    }

    return Promise.reject(res);
    /* if (response.config.url.indexOf('api/mainUser/handleMidUser') === -1) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
       return Promise.reject(new Error(res.msg || 'Error'))
    } else {} */
  } else {
    return res;
  }
}, function (error) {
  console.error(error);
  loadingInstance.close();

  if (requestData.loading == 'loading') {
    if (requestData.loading !== false) loadingInstance.close();
  }

  (0, _elementUi.Message)({
    message: '网络连接超时，请稍后再试',
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;