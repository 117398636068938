"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.array.sort");

require("core-js/modules/web.dom.iterable");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

var _index = _interopRequireDefault(require("@/components/column/index.vue"));

var _columnGoods = _interopRequireDefault(require("@/components/column/columnGoods.vue"));

var _index2 = _interopRequireDefault(require("@/components/Tinymce/index.vue"));

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

var _sortablejs = _interopRequireDefault(require("sortablejs"));

var _template = require("@/api/template.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    couponPop: _radioItem.default,
    coupon: _getCoupon.default,
    column: _index.default,
    columnGoods: _columnGoods.default,
    draggable: _vuedraggable.default,
    Tinymce: _index2.default
  },
  data: function data() {
    return {
      iconIdxs: ['circle', 'rectangle', 'inline'],
      unTemItem: ['bigscreen1000'],

      /*
      文案模板
      */
      textData: ['button1009', 'text1000', 'text1001', 'text1002', 'text1003', 'video1001', 'product_com1015', 'coupon_com1013'],
      text2Data: ['product_com1015', 'coupon_com1013'],
      tichText: ['text1004'],
      textLabel: {
        button1009: '分享文案：',
        text1000: '滚动图文案：',
        video1001: "副文案：",
        product_com1015: '标题：',
        coupon_com1013: '标题：'
      },
      text2Label: {
        product_com1015: '备注：',
        coupon_com1013: '备注：'
      },
      weekGoodsList: ['product_com1004'],
      couponType: 'goods',
      legouGoods: ['legouGoods1000', 'legouGoods1001', 'legouGoods1006', 'legouGoods1002', 'legouGoods1004', 'legouGoods1005', 'group1001'],
      legouPtGoods: ['legouGoods1003'],
      moreList: ['coupon_com1004', 'coupon_com1005', 'coupon_com1007', 'legouGoods1005', 'coupon_com1009', 'coupon_com1008', 'product_com1004', 'product_com1005', 'product_com1007', 'product_com1009', 'product_com1008', 'coupon_com1014', 'product_com1016'],
      imgWH: ['image1013', 'image1014', 'image1015', 'image1016', 'image1017', 'video1000', 'image1021', 'image1022'],
      rotation: ['rotation1000', 'rotation1001', 'rotation1002', 'rotation1003', 'rotation1004', 'rotation1005', 'rotation1006', 'rotation1007', 'rotation1008', 'rotation1009', 'rotation1010', 'rotation1011', 'rotation1012', 'rotation1013', 'rotation1015'],
      styleList: ['video1000', 'image1000', 'image1001', 'image1009', 'image1010', 'image1011', 'image1012', 'button1006', 'image1020', 'button1009', 'video1001', 'text1004'],
      couponStyleList: ['coupon1000', 'coupon_com1011', 'coupon_com1012', 'coupon_com1013', 'coupon1001', 'coupon1002', 'coupon1003', 'coupon1004', 'coupon1006', 'coupon1007', 'coupon1009', 'coupon1011', 'coupon1012', 'coupon1013', 'coupon1015', 'coupon1016', 'coupon_com1013', 'coupon_com1000', 'coupon_com1001', 'coupon_com1002', 'coupon_com1003', 'coupon_com1005', 'coupon_com1004', 'coupon_com1006', 'coupon_com1007', 'coupon_com1008', 'coupon_com1009', 'coupon_com1010', 'column1000', 'coupon_com1014'],
      cardList: ['card1000', 'card1001', 'card1002', 'card1003', 'card1004', 'card1005', 'card1006', 'card1009', 'card1012', 'card1015', 'card1018', 'card1007', 'card1010', 'card1013', 'card1016', 'card1019', 'card1008', 'card1011', 'card1014', 'card1017'],
      goodsList: ['group1000'],
      lavantActivity: ['lavantActivity1000'],
      imgText: {
        legouGoods1003: '列表首图：',
        image1021: '头部图：',
        legouGoods1001: '头部图：',
        button1009: '分享图：',
        image1013: '头部图',
        legouGoods1004: '背景图',
        coupon_com1014: '左侧图片',
        legouGoods1005: '左侧图片',
        product_com1016: '左侧图片',
        lavantActivity1000: "活动banner图",
        image1023: "头部图",
        image1024: "背景图",
        text1002: "跳转链接：",
        video1001: "查看更多：",
        product_com1015: '背景图：',
        coupon_com1013: '背景图：',
        bigscreen1000: '页面背景图：'
      },
      imgRemark: {
        coupon_com1014: "种草轮播模板图片320*504；默认模板：宽度480*560",
        product_com1016: "种草轮播模板图片320*504；默认模板：宽度480*560",
        legouGoods1005: "种草轮播模板图片320*504；默认模板：宽度480*560"
      },
      imgTextNoPath: ['button1009', 'bigscreen1000'],

      /*
      图片1  需要填宽高的图
      */
      temImg1Width: ['legouGoods1004'],

      /*
      图片1  只配置跳转链接 不配置图片
      */
      unTemImg1: ['text1002', 'video1001'],

      /*
      图片1  配置模板
      */
      temImg1List: ['legouGoods1005', 'coupon_com1014', 'product_com1016', 'lavantActivity1000', 'legouGoods1003', 'image1021', 'legouGoods1001', 'button1009', 'image1013', 'legouGoods1004', 'image1023', 'text1002', 'video1001', 'product_com1015', 'coupon_com1013', 'bigscreen1000', 'image1024'],
      boxImg: ['image1019', 'image1017', 'coupon_com1006', 'product_com1006', 'product_com1011', 'coupon_com1009', 'product_com1009', 'coupon_com1012', 'legouGoods1003'],
      boxTitle: ['image1019', 'coupon_com1004', 'coupon_com1006', 'coupon_com1007', 'coupon_com1008', 'product_com1004', 'product_com1005', 'product_com1006', 'product_com1011', 'product_com1007', 'product_com1008', 'coupon_com1009', 'product_com1009', 'coupon_com1011', 'legouGoods1003'],
      couponText: ['image1019', 'image1017', 'coupon_com1000', 'coupon_com1001', 'coupon_com1002', 'coupon_com1003', 'coupon_com1005', 'coupon_com1004', 'coupon_com1006', 'coupon_com1007', 'coupon_com1008', 'coupon_com1009', 'coupon_com1010', 'product_com1000', 'product_com1001', 'product_com1002', 'product_com1003', 'product_com1004', 'product_com1005', 'product_com1006', 'product_com1011', 'product_com1007', 'product_com1008', 'product_com1009', 'product_com1010', 'coupon_com1012', 'legouGoods1003'],
      btnWidth: ['button1000', 'button1001', 'button1002', 'button1003', 'button1004', 'button1005', 'button1006', 'button1007', 'button1008', 'button1010'],
      isUnBtnImage: ['functionTem1000', 'functionTem1001'],
      functionTemText: ['functionTem1000', 'functionTem1001'],
      btnNameList: ['image1019', 'button1001', 'button1003', 'button1005', 'button1007', 'button1008', 'rotation1016', 'functionTem1002', 'button1010'],
      btnRemarkList: ['rotation1016', 'image1013'],

      /*
      需要输入框或选择ID
      */
      urlTypeList: _selectUrl.default.urlTypeList,

      /*
      不能选择ID
      */
      noSelect: _selectUrl.default.noSelect,

      /*
      需要输入选择id 的链接
      */
      linkUrlData: _selectUrl.default.linkUrlData,
      imgSrc: this.$store.state.user.imgSrc,
      sortable: null,
      temClass: [],
      modifyTime: '',
      createTime: '',
      urlList: _selectUrl.default.urlList,
      appIdArr: _selectUrl.default.appIdArr,
      temList: [],
      whiteList: [],
      styleCode: '',
      classCode: '',
      temItemlist: [],
      temId: '',
      styleName: '',
      temCover: '',
      temShow: false,
      id: '',
      popType: '',
      couponData: {
        uuid: '',
        couponType: '',
        total: '',
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      couponShow: false,
      couponShows: false,
      options: [{
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣券'
      }, {
        value: 3,
        label: '礼品券'
      }, {
        value: 4,
        label: '体验券'
      }, {
        value: 5,
        label: '外部优惠券'
      }],
      currentRow: null,
      tableItemShow: false,
      ruleForm: {
        tmplateName: '',
        classId: '',
        temBiaoshi: '',
        temRemark: '',
        id: ''
      },
      templateList: [],
      newList: [],
      listIndex: 0,
      temObjIndex: 0,
      lavantActivityType: null,
      //选择的lavant 活动类型
      rules: {
        tmplateName: [{
          required: true,
          message: '请输入页面模板名称'
        }],
        temBiaoshi: [{
          required: true,
          message: '请输入页面标识'
        }],
        classId: [{
          required: true,
          message: '请选择所属分类'
        }],
        temRemark: [{
          required: true,
          message: '请输入备注'
        }]
      },
      rulesItem: {
        moduleName: [{
          required: true,
          message: '请输入栏目名称'
        }],
        styleCode: [{
          required: true,
          message: '请选择使用模板'
        }],
        moduleStatus: [{
          required: true,
          message: '请选择栏目状态'
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    this.setGoodStyle();
    (0, _template.listStyleType)({
      styleStatus: 1
    }).then(function (res) {
      console.log(res);
      _this.temList = res.data;
    });
    (0, _template.listTmplateClass)().then(function (res) {
      _this.temClass = res.data;
    });

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _template.getTmplate)(this.ruleForm.id).then(function (res) {
        _this.modifyTime = res.data.modifyTime;
        _this.createTime = res.data.createTime;

        _this.fzObj(res.data, _this.ruleForm);

        _this.init();
      });
    }

    this.getWhiteList();
  },
  methods: {
    /*
    lavant活动  场地  服务 预约选择
     */
    getLavantActivity: function getLavantActivity(type) {
      console.log(type);
      this.lavantActivityType = type; // this.listIndex = index

      this.$refs.coupon1.getList(type);
      this.couponShow = true;
    },
    delPageTemGoods: function delPageTemGoods(index) {
      this.templateList[this.temObjIndex].moduleAttr.dataItem.splice(index, 1);
    },

    /*
    添加栏目模板数据
     */
    addColumnGoods: function addColumnGoods() {},
    getWhiteList: function getWhiteList(e) {
      var _this2 = this;

      this.apiPost('/api/whiteList/listWhiteListPage', {
        status: 1,
        pageNo: 1,
        pageSize: 100,
        name: e
      }).then(function (res) {
        _this2.whiteList = res.data.items;
      });
    },
    goEditTmplate: function goEditTmplate() {
      var _this3 = this;

      var data = {
        id: this.ruleForm.id,
        tmplateName: this.ruleForm.tmplateName,
        classId: this.ruleForm.classId,
        tmplateStatus: '1'
      };
      (0, _template.editTmplate)(data).then(function (res) {
        _this3.$message({
          message: '修改成功！',
          type: 'success'
        });
      });
    },
    setGoodStyle: function setGoodStyle() {
      for (var i = 0; i <= 17; i++) {
        this.goodsList.push('product' + (1000 + i));
      }

      for (var _i = 0; _i <= 16; _i++) {
        this.goodsList.push('product_com' + (1000 + _i));
      }
    },

    /*
                              获取数据项
                              */
    init: function init() {
      var _this4 = this;

      (0, _template.listModule)({
        tmplateId: this.ruleForm.id
      }).then(function (res) {
        _this4.templateList = res.data;

        _this4.templateList.forEach(function (item) {
          item.moduleAttr = JSON.parse(item.moduleAttr);
        });

        console.log(_this4.templateList);
        var arr = Object.assign([], _this4.templateList);
        _this4.newList = arr;

        _this4.$nextTick(function () {
          _this4.setSort();
        });
      });
    },

    /*
                              保存排序
                              */
    setListSort: function setListSort() {
      var _this5 = this;

      var arr = [];
      this.newList.forEach(function (item) {
        if (item.id) {
          arr.push(item.id);
        }
      });
      (0, _template.editModuleSort)({
        id: this.ruleForm.id,
        moduleAttr: JSON.stringify(arr)
      }).then(function (res) {
        _this5.$message({
          message: '保存成功',
          type: 'success'
        });
      });
    },
    changeTemSort: function changeTemSort() {
      this.templateList.sort(this.compare('moduleSort'));
    },

    /*
                              拖拽
                              */
    setSort: function setSort() {
      var _this6 = this;

      var el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
      this.sortable = _sortablejs.default.create(el, {
        ghostClass: 'sortable-ghost',
        filter: '.filtered',
        setData: function setData(dataTransfer) {
          dataTransfer.setData('Text', '');
        },
        onEnd: function onEnd(evt) {
          var targetRow = _this6.newList.splice(evt.oldIndex, 1)[0];

          _this6.newList.splice(evt.newIndex, 0, targetRow);
        }
      });
    },
    getTem: function getTem() {
      var _this7 = this;

      this.temShow = true;
      this.temId = this.templateList[this.temObjIndex].temId || this.temId;
      this.temList.forEach(function (item) {
        if (item.value === _this7.temId) {
          _this7.temItemlist = item.list;
        }
      });
      this.styleCode = this.templateList[this.temObjIndex].styleCode;
    },
    setTem: function setTem() {
      this.temShow = false;
      var tem = this.templateList[this.temObjIndex];
      var temItem = this.templateList[this.temObjIndex].moduleAttr;
      tem.thumbnailPath = this.temCover;
      tem.styleName = this.styleName;
      tem.temId = this.temId;

      if (tem.styleCode !== this.styleCode) {
        tem.styleCode = this.styleCode; // tem.moduleAttr.dataItem = []
      }

      if (this.cardList.indexOf(this.styleCode) >= 0) {
        this.couponType = 'card';
      }

      if (this.goodsList.indexOf(this.styleCode) >= 0) {
        this.couponType = 'goods';
      }

      if (this.couponStyleList.indexOf(this.styleCode) >= 0) {
        this.couponType = 'coupon';
      }

      switch (this.styleCode) {
        case 'rotation1000':
          temItem.moduleType = 'circle';
          temItem.height = 190;
          temItem.width = 710;
          break;

        case 'rotation1001':
          temItem.moduleType = 'rectangle';
          temItem.height = 190;
          temItem.width = 710;
          break;

        case 'rotation1002':
          temItem.moduleType = 'circle';
          temItem.height = 240;
          temItem.width = 710;
          break;

        case 'rotation1003':
          temItem.moduleType = 'rectangle';
          temItem.height = 240;
          temItem.width = 710;
          break;

        case 'rotation1004':
          temItem.moduleType = 'circle';
          temItem.height = 300;
          temItem.width = 710;
          break;

        case 'rotation1005':
          temItem.moduleType = 'rectangle';
          temItem.height = 300;
          temItem.width = 710;
          break;

        case 'rotation1006':
          temItem.moduleType = 'circle';
          temItem.height = 200;
          temItem.width = 750;
          break;

        case 'rotation1007':
          temItem.moduleType = 'rectangle';
          temItem.height = 200;
          temItem.width = 750;
          break;

        case 'rotation1008':
          temItem.moduleType = 'circle';
          temItem.height = 240;
          temItem.width = 750;
          break;

        case 'rotation1009':
          temItem.moduleType = 'rectangle';
          temItem.height = 240;
          temItem.width = 750;
          break;

        case 'rotation1010':
          temItem.moduleType = 'circle';
          temItem.height = 300;
          temItem.width = 750;
          break;

        case 'rotation1011':
          temItem.moduleType = 'rectangle';
          temItem.height = 300;
          temItem.width = 750;
          break;

        case 'button1000':
          temItem.height = 110;
          temItem.width = 110;
          break;

        case 'button1001':
          temItem.height = 110;
          temItem.width = 110;
          break;

        case 'button1002':
          temItem.height = 90;
          temItem.width = 90;
          break;

        case 'button1003':
          temItem.height = 64;
          temItem.width = 64;
          break;

        case 'button1004':
          temItem.height = 90;
          temItem.width = 90;
          break;

        case 'button1005':
          temItem.height = 90;
          temItem.width = 90;
          break;

        case 'button1008':
          temItem.height = 64;
          temItem.width = 64;
          break;

        case 'coupon1000':
          temItem.coverHeight = 220;
          temItem.coverWidth = 220;
          temItem.type = 'couponBg';
          break;

        case 'coupon1004':
          temItem.coverHeight = 160;
          temItem.coverWidth = 160;
          temItem.type = 'couponBg';
          break;

        case 'coupon1002':
          temItem.coverHeight = 220;
          temItem.coverWidth = 220;
          temItem.type = 'unBg';
          break;

        case 'coupon1006':
          temItem.coverHeight = 160;
          temItem.coverWidth = 160;
          temItem.type = 'unBg';
          break;

        case 'coupon1007':
          temItem.type = 'couponBg';
          break;

        case 'coupon1009':
          temItem.type = 'unBg';
          break;

        case 'coupon1011':
          temItem.type = 'couponBg';
          break;

        case 'coupon1012':
          temItem.type = 'unBg';
          break;

        case 'coupon1013':
          temItem.coverHeight = 250;
          temItem.coverWidth = 250;
          break;

        case 'coupon1015':
          temItem.coverHeight = 220;
          temItem.coverWidth = 220;
          break;

        case 'card1000':
          temItem.coverWidth = 460;
          temItem.type = 'left';
          break;

        case 'card1001':
          temItem.coverWidth = 460;
          temItem.type = 'left';
          break;

        case 'card1002':
          temItem.coverWidth = 640;
          temItem.type = 'left';
          break;

        case 'card1003':
          temItem.coverWidth = 640;
          temItem.type = 'left';
          break;

        case 'card1004':
          temItem.coverWidth = 650;
          temItem.type = 'center';
          break;

        case 'card1005':
          temItem.coverWidth = 650;
          temItem.type = 'center';
          break;

        case 'card1006':
          temItem.coverWidth = 310;
          temItem.type = 'center';
          temItem.padding = 6;
          break;

        case 'card1007':
          temItem.coverWidth = 330;
          temItem.type = 'left';
          temItem.padding = 0;
          break;

        case 'card1008':
          temItem.coverWidth = 330;
          temItem.type = 'left';
          temItem.padding = 0;
          break;

        case 'card1009':
          temItem.coverWidth = 345;
          temItem.type = 'left';
          temItem.padding = 0;
          break;

        case 'card1010':
          temItem.coverWidth = 345;
          temItem.type = 'left';
          temItem.padding = 0;
          break;

        case 'card1011':
          temItem.coverWidth = 230;
          temItem.type = 'left';
          temItem.padding = 0;
          break;

        case 'card1012':
          temItem.coverWidth = 230;
          temItem.type = 'left';
          temItem.padding = 0;
          break;

        case 'card1013':
          temItem.coverWidth = 310;
          temItem.coverHeight = 190;
          temItem.type = 'left';
          temItem.padding = 0;
          break;

        case 'card1014':
          temItem.coverWidth = 310;
          temItem.coverHeight = 190;
          temItem.type = 'center';
          temItem.padding = 0;
          break;

        case 'card1015':
          temItem.coverWidth = 310;
          temItem.coverHeight = 190;
          temItem.padding = 6;
          temItem.type = 'center';
          break;

        case 'card1016':
          temItem.coverWidth = 310;
          temItem.coverHeight = 190;
          temItem.type = 'left';
          temItem.padding = 0;
          break;

        case 'card1017':
          temItem.coverWidth = 460;
          temItem.coverHeight = 280;
          temItem.type = 'left';
          temItem.padding = 0;
          break;

        case 'card1018':
          temItem.coverWidth = 460;
          temItem.coverHeight = 280;
          temItem.type = 'center';
          temItem.padding = 0;
          break;

        case 'card1019':
          temItem.coverWidth = 460;
          temItem.coverHeight = 280;
          temItem.type = 'left';
          temItem.padding = 0;
          break;

        case 'product1000':
          temItem.coverHeight = 220;
          temItem.coverWidth = 220;
          temItem.type = 'couponBg';
          break;

        case 'product1002':
          temItem.coverHeight = 220;
          temItem.coverWidth = 220;
          temItem.type = 'unBg';
          break;

        case 'product1004':
          temItem.coverHeight = 160;
          temItem.coverWidth = 160;
          temItem.type = 'couponBg';
          break;

        case 'product1006':
          temItem.coverHeight = 160;
          temItem.coverWidth = 160;
          temItem.type = 'unBg';
          break;

        case 'product1008':
          temItem.type = 'couponBg';
          break;

        case 'product1010':
          temItem.type = 'unBg';
          break;

        case 'product1012':
          temItem.type = 'couponBg';
          break;

        case 'product1013':
          temItem.type = 'unBg';
          break;

        case 'product1014':
          temItem.coverHeight = 250;
          temItem.coverWidth = 250;
          break;

        case 'product1016':
          temItem.coverHeight = 220;
          temItem.coverWidth = 220;
          break;

        default:
          break;
      }

      if (this.styleCode === 'image1019') {
        temItem = {
          chunkName: '',
          // 标题名称
          linkCode: '',
          // 链接类型  链接地址
          linkId: '',
          // 链接ID  Appid
          linkPath: '',
          // 路径  其他小程序
          pageTitle: '',
          dataItem: []
        };
      }

      if (this.styleCode === 'image1009' || this.styleCode === 'image1010' || this.styleCode === 'image1011') {
        temItem = {
          imgWidth: '',
          imgHeight: '',
          dataItem: [{
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }, {
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }, {
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }, {
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }]
        };
      }

      if (this.styleCode === 'image1020') {
        temItem = {
          imgWidth: '',
          imgHeight: '',
          dataItem: [{
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }, {
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }, {
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }]
        };
      }

      if (this.styleCode === 'image1012') {
        temItem = {
          imgWidth: '',
          imgHeight: '',
          dataItem: [{
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }, {
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }, {
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }, {
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }, {
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }]
        };
      }

      if (this.styleCode === 'image1000' || this.styleCode === 'image1001') {
        temItem = {
          dataItem: [{
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }]
        };
      }

      if (this.styleCode === 'image1013' || this.styleCode === 'image1014' || this.styleCode === 'image1015' || this.styleCode === 'image1016') {
        temItem = {
          imgHeight: '',
          imgWidth: '',
          marginLeft: '',
          bgColor: '',
          dataItem: [{
            dataPath: '',
            linkCode: '',
            linkId: '',
            linkPath: '',
            dataName: '',
            pageTitle: ''
          }]
        };
      }

      if (this.styleCode === 'image1017') {
        temItem = {
          chunkName: '',
          // 标题名称
          linkCode: '',
          // 链接类型  链接地址
          linkId: '',
          // 链接ID  Appid
          linkPath: '',
          // 路径  其他小程序
          pageTitle: '',
          dataItem: []
        };
      }

      this.templateList[this.temObjIndex].moduleAttr = temItem;
    },
    setTemItem: function setTemItem(item) {
      this.styleCode = item.styleCode;
      this.temCover = item.thumbnailPath;
      this.styleName = item.styleName;
    },
    setTemList: function setTemList(item) {
      var _this8 = this;

      if (this.temId !== item.id) {
        this.temId = item.id;
        this.classCode = item.styleCode;
        (0, _template.listStyle)({
          parentId: item.id
        }).then(function (res) {
          _this8.temItemlist = res.data.filter(function (item) {
            return item.styleStatus === 1;
          });
        });
      }
    },

    /*
                              链接类型选择
                              */
    dataItemChange: function dataItemChange(e, index) {
      var data = this.templateList[this.temObjIndex].moduleAttr.dataItem[index];
      data.linkId = '';
      data.linkPath = '';
      data.dataName = '';
    },
    moreChange: function moreChange(e) {
      var data = this.templateList[this.temObjIndex].moduleAttr;
      data.moreId = '';
      data.morePath = '';
    },
    dataChange: function dataChange(e, type) {
      var data = this.templateList[this.temObjIndex].moduleAttr;

      if (type === 'chunkName') {
        this.$set(this.templateList[this.temObjIndex].moduleAttr, 'linkId', '');
        this.$set(this.templateList[this.temObjIndex].moduleAttr, 'linkPath', '');
      }

      if (type === 'img') {
        this.$set(this.templateList[this.temObjIndex].moduleAttr, 'imgId', '');
        this.$set(this.templateList[this.temObjIndex].moduleAttr, 'imgPath', '');
      }
    },

    /*
                              选择ID后
                              */
    setCoupon: function setCoupon(e) {
      var temItem = this.templateList[this.temObjIndex].moduleAttr;

      if (this.lavantActivity.includes(this.templateList[this.temObjIndex].styleCode)) {
        console.log("lavantActivity活动选择");
        var pageUrl = {
          svipActivityDetail: '/svip/activity/detail?id=',
          svipServiceDetail: '/svip/service/detail?id=',
          svipSpaceDetail: '/svip/space/detail?id='
        };
        e.activityPath = pageUrl[this.lavantActivityType] + e.id;
        temItem.dataItem.push(e);
        this.couponShow = false;
        this.popType = '';
        return;
      }

      var linkHandlers = {
        vipSubsidy: function vipSubsidy(temItem, e) {
          temItem.linkId = e.uuid;
        },
        vipCity: function vipCity(temItem, e) {
          temItem.linkId = e.uuid;
        },
        card: function card(temItem, e) {
          temItem.linkId = e.uuid;
          temItem.cardType = e.type;
        },
        coupon: function coupon(temItem, e) {
          temItem.linkId = e.uuid;
        },
        couponBag: function couponBag(temItem, e) {
          temItem.linkId = e.id;
        },
        help: function help(temItem, e) {
          temItem.linkId = e.id;
        },
        apply: function apply(temItem, e) {
          temItem.linkId = e.id;
        },
        vipCityLife: function vipCityLife(temItem, e) {
          temItem.linkId = e.productCode;
          temItem.citylifeId = e.id;
        },
        goods: function goods(temItem, e) {
          temItem.linkId = e.productCode;
          temItem.productSource = e.productSource;
        },
        pond: function pond(temItem, e) {
          temItem.linkId = e.code;
        },
        tem: function tem(temItem, e) {
          temItem.linkId = e.pageCode;
        }
      };
      var handler = linkHandlers[this.popType];

      if (this.listIndex === 'moduleAttr') {
        handler(temItem, e);
      } else {
        handler(temItem.dataItem[this.listIndex], e);
      }

      this.couponShow = false;
      this.popType = '';
    },
    getLinkId: function getLinkId() {
      var moduleAttr = this.templateList[this.temObjIndex].moduleAttr;
      var linkCode = moduleAttr.linkCode,
          linkId = moduleAttr.linkId;
      this.listIndex = 'moduleAttr';
      this.popType = linkCode;
      this.$refs.coupon1.getList(linkCode, linkId);
      this.couponShow = true;
      /* const temItem = this.templateList[this.temObjIndex].moduleAttr
       this.listIndex = 'moduleAttr'
       this.popType = temItem.linkCode
       this.$refs.coupon1.getList(this.popType, temItem.linkId)
       this.couponShow = true */
    },

    /* 选择链接ID*/
    getCoupon: function getCoupon(index) {
      var _this9 = this;

      var tem = this.templateList[this.temObjIndex];
      var temItem = this.templateList[this.temObjIndex].moduleAttr;

      if (this.couponStyleList.includes(tem.styleCode) || this.cardList.includes(tem.styleCode) || this.goodsList.includes(tem.styleCode) || this.legouGoods.includes(tem.styleCode) || this.legouPtGoods.includes(tem.styleCode)) {
        /* 多选*/
        var couponTypeMap = {
          lavantActivity: ['lavantActivity'],
          columnGoodsType: ['column'],
          vipCity: ['coupon_com1011'],
          vipSubsidy: ['coupon_com1012'],
          card: this.cardList,
          coupon: this.couponStyleList,
          goods: this.goodsList,
          legouGoods: this.legouGoods,
          legouPtGoods: this.legouPtGoods
        };

        for (var type in couponTypeMap) {
          if (couponTypeMap[type].indexOf(tem.styleCode) >= 0) {
            this.couponType = type;
            break;
          }
        }

        this.couponShows = true;
        this.$nextTick(function () {
          _this9.$refs.couponPop.init();

          _this9.$refs.couponPop.assignCoupon(temItem.dataItem);
        });
      } else {
        /* 单选选*/
        this.listIndex = index;
        this.popType = temItem.dataItem[index].linkCode;
        this.$refs.coupon1.getList(this.popType, temItem.dataItem[index].linkId); // this.$refs.coupon1.getList(this.popType, temItem.dataItem[index].linkId)

        this.couponShow = true;
      }
    },

    /*
                              多选优惠券赋值
                              */
    setCoupons: function setCoupons(e) {
      var _this10 = this;

      var temItem = this.templateList[this.temObjIndex].moduleAttr;
      temItem.dataItem = [];
      var typeMap = {
        legouGoods: {
          linkId: 'id',
          pic_url: 'pic_url',
          name: 'name',
          price: 'price',
          original_price: 'original_price',
          discount_rate: 'discount_rate',
          linkCode: 'legouGoods',
          user_mult_limit: 'user_mult_limit'
        },
        legouPtGoods: {
          linkId: 'id',
          pic_url: 'cover_pic',
          name: 'name',
          price: 'price',
          original_price: 'original_price',
          discount_rate: 'discount_rate',
          linkCode: 'legouPtGoods',
          user_mult_limit: 'user_mult_limit'
        },
        coupon: {
          linkId: 'uuid',
          linkCode: 'coupon',
          cover: 'mainImage',
          name: 'name'
        },
        card: {
          linkId: 'uuid',
          linkCode: 'card',
          cardType: 'type',
          cover: 'cover',
          name: 'name'
        },
        goods: {
          linkId: 'productCode',
          linkCode: 'goods',
          productSource: 'productSource',
          cover: 'imageUrl',
          name: 'productName'
        },
        vipCity: {
          linkId: 'productCode',
          linkCode: 'vipCity',
          citylifeId: 'id'
        },
        vipSubsidy: {
          linkId: 'productCode',
          linkCode: 'vipSubsidy',
          citylifeId: 'id'
        }
      };

      if (e.length > 0) {
        e.forEach(function (item) {
          var newItem = {};
          var typeProps = typeMap[_this10.couponType];

          for (var prop in typeProps) {
            newItem[prop] = item[typeProps[prop]] || typeProps[prop];
          }

          temItem.dataItem.push(newItem);
        });
      }

      this.couponShows = false;
    },

    /*
                              关闭弹框
                              */
    closeCouponPop: function closeCouponPop() {
      this.couponShows = false;
    },

    /*
                              编辑是table颜色
                               */
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;

      if (!row.id) {
        return 'filtered';
      }

      if (rowIndex === this.temObjIndex) {
        return 'warning-row';
      }

      return '';
    },

    /*
                              添加栏目项
                              */
    goAddTemplate: function goAddTemplate() {
      if (this.templateList.length < 30) {
        this.templateList.push({
          temId: '',
          styleCode: '',
          styleName: '',
          moduleName: '',
          moduleStatus: 1,
          thumbnailPath: '',
          moduleAttr: {
            dataItem: [],
            width: '',
            height: '',
            temMargin: null,
            imgWidth: null,
            imgHeight: null,
            videoUrl: null,
            videoImg: null,
            marginLeft: null,
            bgColor: null,
            textColor: null,
            text: null,
            imgMarginTop: null,
            chunkName: null,
            img: null,
            imgCode: null,
            imgPath: null,
            lnkCode: null,
            linkId: null,
            lkPath: null,
            radio: null,
            onGift: null,
            moreCode: null,
            moreId: null,
            morePath: null,
            morePeTitle: null
          }
        });
      } else {
        this.$message({
          message: '最多可添加30个栏目',
          type: 'warning'
        });
      }
    },

    /*
                              删除栏目项
                              */
    removeItem: function removeItem(row, index) {
      var _this11 = this;

      this.$confirm('此操作将删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (row.id) {
          _this11.openLoading();

          (0, _template.delModule)(row.id).then(function (res) {
            _this11.openLoading().close();

            _this11.templateList.splice(index, 1);

            _this11.tableItemShow = false;
          }).catch(function () {
            _this11.openLoading().close();
          });
        } else {
          _this11.templateList.splice(index, 1);

          _this11.tableItemShow = false;
        }
      }).catch(function () {});
    },

    /*
                              编辑栏目项
                              */
    editItem: function editItem(row, index) {
      this.currentRow = row;
      this.tableItemShow = true;
      this.temObjIndex = index;
    },

    /*
                              添加数据项
                              */
    goAddTemList: function goAddTemList() {
      var temItem = this.templateList[this.temObjIndex].moduleAttr;
      temItem.dataItem.push({
        dataPath: '',
        linkCode: '',
        linkId: '',
        linkPath: '',
        dataName: '',
        pageTitle: ''
      });
    },

    /*
                              删除数据项
                              */
    deleteList: function deleteList(index) {
      var temItem = this.templateList[this.temObjIndex].moduleAttr;
      temItem.dataItem.splice(index, 1);
    },

    /*
                              上传图片  获取下标
                              */
    setIndex: function setIndex(index) {
      this.listIndex = index;
    },
    removeVideoUrl: function removeVideoUrl(e) {
      var temItem = this.templateList[this.temObjIndex].moduleAttr;
      this.$set(temItem, 'videoImg', null);
    },
    setVideoUrl: function setVideoUrl(e) {
      var temItem = this.templateList[this.temObjIndex].moduleAttr; // temItem.videoImg = e

      this.$set(temItem, 'videoImg', e);
      console.log(temItem);
    },

    /*
                              优惠券组图  图片
                              */
    removeChunkName: function removeChunkName(e, type) {
      var temItem = this.templateList[this.temObjIndex].moduleAttr;
      this.$set(this.templateList[this.temObjIndex].moduleAttr, type, null);
    },
    setChunkName: function setChunkName(e, type) {
      var temItem = this.templateList[this.temObjIndex].moduleAttr;
      this.$set(this.templateList[this.temObjIndex].moduleAttr, type, e); // temItem.chunkName = e

      console.log(temItem);
    },

    /*
                              数据项图片
                              */
    removeCover: function removeCover(e, index) {
      var temItem = this.templateList[this.temObjIndex].moduleAttr;
      temItem.dataItem[index].dataPath = null;
    },
    setCover: function setCover(e) {
      var temItem = this.templateList[this.temObjIndex].moduleAttr;
      temItem.dataItem[this.listIndex].dataPath = e;
    },
    setPath: function setPath(item) {
      /* const typeMap = {
         coupon: {
           linkPath: `/pages/coupons/couponDea/couponDea?type=3&id=${item.linkId}`
         },
         card: {
           linkPath: `/pages/starCard/recharge/recharge?id=${item.linkId}`
         },
         activity: {
           linkPath: `/pages/discover/detail/index/index?id=${item.linkId}`
         },
         vote: {
           linkPath: `/pages/voteActivity/voteActivity?id=${item.linkId}`
         },
         goods: {
           linkPath: `/mall/goods/dea?id=${item.linkId}`
         },
         pond: {
           linkPath: `/pages/pond/pond/pond?id=${item.linkId}`
         },
         tem: {
           linkPath: `/pages/tem/tem?id=${item.linkId}&title=${item.pageTitle}`
         }
       };
       const typeProps = typeMap[item.linkCode];
       for (let prop in typeProps) {
         item[prop] = typeProps[prop];
       }
       return */
      if (item.linkCode === 'coupon') {
        item.linkPath = '/pages/coupons/couponDea/couponDea?type=3&id=' + item.linkId;
      }

      if (item.linkCode === 'card') {
        item.linkPath = '/pages/starCard/recharge/recharge?id=' + item.linkId;
      }

      if (item.linkCode === 'activity') {
        item.linkPath = '/pages/discover/detail/index/index?id=' + item.linkId;
      }

      if (item.linkCode === 'vote') {
        item.linkPath = '/pages/voteActivity/voteActivity?id=' + item.linkId;
      }

      if (item.linkCode === 'goods') {
        item.linkPath = '/mall/goods/dea?id=' + item.linkId;
      }

      if (item.linkCode === 'pond') {
        item.linkPath = '/pages/pond/pond/pond?id=' + item.linkId;
      }

      if (item.linkCode === 'tem') {
        item.linkPath = '/pages/tem/tem?id=' + item.linkId + '&title=' + item.pageTitle;
      }
    },
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },

    /*
                              保存
                              */
    submitForm: function submitForm(formName) {
      var _this12 = this;

      var pageUrl = this.linkUrlData;
      this.$refs[formName].validate(function (linkId) {
        if (linkId) {
          _this12.templateList[_this12.temObjIndex].moduleAttr.dataItem.sort(_this12.compare('sort'));

          var temItem = _this12.templateList[_this12.temObjIndex].moduleAttr;
          temItem.dataItem.forEach(function (item) {
            if (item.linkCode === 'bigPuzzle') {
              item.linkPath = '/pages/puzzle/start?gameId=' + item.linkId;
            } else if (item.linkCode === 'bigYang') {
              item.linkPath = '/pages/yang/start?gameId=' + item.linkId;
            } else if (item.linkCode === 'mikaPuzzle') {
              item.linkPath = '/activity/game/imgsStart?gameId=' + item.linkId;
            } else if (item.linkCode === 'giveScore') {
              item.linkPath = '/activity/giveScore/giveScore?id=' + item.linkId;
            } else if (item.linkCode === 'mikaYang') {
              item.linkPath = '/activity/game/yangStart?gameId=' + item.linkId;
            } else if (item.linkCode === 'legouGoods') {
              item.linkPath = pageUrl[item.linkCode].url + item.linkId;
            } else if (item.linkCode === 'legouPtGoods') {
              item.linkPath = '/legou/pages/pt/details/details?id=' + item.linkId;
            } else if (item.linkCode === 'coupon') {
              item.linkPath = '/pages/coupons/couponDea/couponDea?type=3&id=' + item.linkId;
            } else if (item.linkCode === 'legouGoods') {
              item.linkPath = "/legou/pages/goods/goods?id=".concat(item.linkId);
            } else if (item.linkCode === 'legouTem') {
              item.linkPath = "/legou/pages/index/index?page_id=".concat(item.linkId);
            } else if (item.linkCode === 'specialActivity') {
              item.linkPath = "/activity/newGift/special?id=".concat(item.linkId);
            } else if (item.linkCode === 'waterCoupon') {
              item.linkPath = "/activity/waterCoupon/index?id=".concat(item.linkId);
            } else if (item.linkCode === 'legouLive') {
              item.linkPath = "plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=".concat(item.linkId);
            } else if (item.linkCode === 'legouShop') {
              item.linkPath = "/legou/mch/shop/shop?mch_id=".concat(item.linkId);
            } else if (item.linkCode === 'svipActivityClass') {
              item.linkPath = "/svip/activity/index?classId=".concat(item.linkId);
            } else if (item.linkCode === 'legouPt') {
              item.linkPath = "/legou/pages/pt/details/details?id=".concat(item.linkId);
            } else if (item.linkCode === 'vipCityLife') {
              item.linkPath = "/pages/coupons/couponDea/couponDea?share=unShare&type=3&vip=isVip&id=".concat(item.linkId, "&citylifeId=").concat(item.citylifeId);
            } else if (item.linkCode === 'svipActivityDetail') {
              item.linkPath = "/svip/activity/detail?id=".concat(item.linkId);
            } else if (item.linkCode === 'svipServiceDetail') {
              item.linkPath = "/svip/service/detail?id=".concat(item.linkId);
            } else if (item.linkCode === 'svipSpaceDetail') {
              item.linkPath = "/svip/space/detail?id=".concat(item.linkId);
            } else if (item.linkCode === 'couponBag') {
              item.linkPath = '/pages/coupons/couponGift/couponGift?id=' + item.linkId;
            } else if (item.linkCode === 'help') {
              item.linkPath = '/activity/help/help?id=' + item.linkId;
            } else if (item.linkCode === 'apply') {
              item.linkPath = '/activity/apply/dea?id=' + item.linkId;
            } else if (item.linkCode === 'vipCity') {
              item.linkPath = '/pages/coupons/couponDea/couponDea?share=unShare&type=3&vip=isVip&id=' + item.linkId + '&citylifeId=' + item.citylifeId;
            } else if (item.linkCode === 'vipSubsidy') {
              item.linkPath = '/pages/coupons/couponDea/couponDea?type=3&vip=subsidy&id=' + item.linkId + '&citylifeId=' + item.citylifeId;
            } else if (item.linkCode === 'card') {
              console.log(item);

              if (item.cardType == 3 || item.cardType == 4) {
                item.linkPath = '/pages/starCard/giftToken/giftToken?id=' + item.linkId;
              } else {
                item.linkPath = '/pages/starCard/recharge/recharge?id=' + item.linkId;
              }
            } else if (item.linkCode === 'activity') {
              item.linkPath = '/pages/discover/detail/index/index?id=' + item.linkId;
            } else if (item.linkCode === 'vote') {
              item.linkPath = '/pages/voteActivity/voteActivity?id=' + item.linkId;
            } else if (item.linkCode === 'goods') {
              if (item.productSource === 2) {
                if (temItem.openGift === 1) {
                  item.linkPath = '/vip/myGift?id=' + item.linkId;
                } else {
                  item.linkPath = '/mall/goods/dea?id=' + item.linkId;
                }

                return;
              }

              if (item.productSource === 1 || item.productSource == 6) {
                if (temItem.openGift === 1) {
                  item.linkPath = '/mall/goods/dea?type=openGift&id=' + item.linkId;
                } else {
                  item.linkPath = '/mall/goods/dea?id=' + item.linkId;
                }

                return;
              }

              if (item.productSource === 3) {
                item.linkPath = 'packMember/pages/member-subsidies/index'; // 'pages/goods/goods?id=' + item.linkId

                return;
              }

              if (item.productSource === 4) {
                item.linkPath = 'pages/index/index?jump_path=/pages/goods/detail%3Fid$' + item.linkId; // 'pages/index/index?jump_path=/pages/goods/detail%3Fid$' + item.linkId

                return;
              }

              item.linkPath = '/mall/goods/dea?id=' + item.linkId;
            } else if (item.linkCode === 'pond') {
              item.linkPath = '/pages/pond/pond/pond?id=' + item.linkId;
            } else if (item.linkCode === 'blind') {
              item.linkPath = '/activity/pond/pond?id=' + item.linkId;
            } else if (item.linkCode === 'tem') {
              item.linkPath = '/pages/tem/tem?id=' + item.linkId + '&title=' + item.pageTitle;
            } else if (item.linkCode === 'auto') {
              item.linkPath = '/pages/tem/auto?id=' + item.linkId + '&title=' + item.pageTitle;
            } else if (item.linkCode === 'storeTem') {
              item.linkPath = '/pages/storeTem/storeTem?id=' + item.linkId + '&title=' + item.pageTitle;
            } else if (item.linkCode === 'storeMap') {
              item.linkPath = '/pages/storeTem/map?id=' + item.linkId;
            } else if (item.linkCode === '4' || item.linkCode === '5') {
              return;
            } else {
              item.linkPath = item.linkCode;
            }
          });

          if (temItem.linkCode) {
            if (temItem.linkCode === 'bigPuzzle') {
              temItem.linkPath = '/pages/puzzle/start?gameId=' + temItem.linkId;
            } else if (temItem.linkCode === 'bigYang') {
              temItem.linkPath = '/pages/yang/start?gameId=' + temItem.linkId;
            } else if (temItem.linkCode === 'mikaPuzzle') {
              temItem.linkPath = '/activity/game/imgsStart?gameId=' + temItem.linkId;
            } else if (temItem.linkCode === 'giveScore') {
              temItem.linkPath = '/activity/giveScore/giveScore?id=' + temItem.linkId;
            } else if (temItem.linkCode === 'mikaYang') {
              temItem.linkPath = '/activity/game/yangStart?gameId=' + temItem.linkId;
            } else if (temItem.linkCode === 'legouGoods') {
              temItem.linkPath = '/legou/pages/goods/goods?id=' + temItem.linkId;
            } else if (temItem.linkCode === 'svipActivityDetail') {
              temItem.linkPath = "/svip/activity/detail?id=".concat(temItem.linkId);
            } else if (temItem.linkCode === 'svipServiceDetail') {
              temItem.linkPath = "/svip/service/detail?id=".concat(temItem.linkId);
            } else if (temItem.linkCode === 'svipSpaceDetail') {
              temItem.linkPath = "/svip/space/detail?id=".concat(temItem.linkId);
            } else if (temItem.linkCode === 'coupon') {
              temItem.linkPath = '/pages/coupons/couponDea/couponDea?type=3&id=' + temItem.linkId;
            } else if (temItem.linkCode === 'legouGoods') {
              temItem.linkPath = "/legou/pages/goods/goods?id=".concat(temItem.linkId);
            } else if (temItem.linkCode === 'legouTem') {
              temItem.linkPath = "/legou/pages/index/index?page_id=".concat(temItem.linkId);
            } else if (temItem.linkCode === 'specialActivity') {
              temItem.linkPath = "/activity/newGift/special?id=".concat(temItem.linkId);
            } else if (temItem.linkCode === 'waterCoupon') {
              temItem.linkPath = "/activity/waterCoupon/index?id=".concat(temItem.linkId);
            } else if (temItem.linkCode === 'legouLive') {
              temItem.linkPath = "plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=".concat(temItem.linkId);
            } else if (temItem.linkCode === 'legouShop') {
              temItem.linkPath = "/legou/mch/shop/shop?mch_id=".concat(temItem.linkId);
            } else if (temItem.linkCode === 'svipActivityClass') {
              temItem.linkPath = "/svip/activity/index?classId=".concat(temItem.linkId);
            } else if (temItem.linkCode === 'legouPt') {
              temItem.linkPath = "/legou/pages/pt/details/details?id=".concat(temItem.linkId);
            } else if (temItem.linkCode === 'vipCityLife') {
              temItem.linkPath = "/pages/coupons/couponDea/couponDea?share=unShare&type=3&vip=isVip&id=".concat(temItem.linkId, "&citylifeId=").concat(temItem.citylifeId);
            } else if (temItem.linkCode === 'couponBag') {
              temItem.linkPath = '/pages/coupons/couponGift/couponGift?id=' + temItem.linkId;
            } else if (temItem.linkCode === 'help') {
              temItem.linkPath = '/activity/help/help?id=' + temItem.linkId;
            } else if (temItem.linkCode === 'apply') {
              temItem.linkPath = '/activity/apply/dea?id=' + temItem.linkId;
            } else if (temItem.linkCode === 'card') {
              if (temItem.cardType == 3 || item.cardType == 4) {
                temItem.linkPath = '/pages/starCard/giftToken/giftToken?id=' + temItem.linkId;
              } else {
                temItem.linkPath = '/pages/starCard/recharge/recharge?id=' + temItem.linkId;
              }
            } else if (temItem.linkCode === 'activity') {
              temItem.linkPath = '/pages/discover/detail/index/index?id=' + temItem.linkId;
            } else if (temItem.linkCode === 'vote') {
              temItem.linkPath = '/pages/voteActivity/voteActivity?id=' + temItem.linkId;
            } else if (temItem.linkCode === 'goods') {
              temItem.linkPath = '/mall/goods/dea?id=' + temItem.linkId;
            } else if (temItem.linkCode === 'pond') {
              temItem.linkPath = '/pages/pond/pond/pond?id=' + temItem.linkId;
            } else if (temItem.linkCode === 'blind') {
              temItem.linkPath = '/activity/pond/pond?id=' + temItem.linkId;
            } else if (temItem.linkCode === 'tem') {
              temItem.linkPath = '/pages/tem/tem?id=' + temItem.linkId + '&title=' + temItem.pageTitle;
            } else if (temItem.linkCode === 'auto') {
              temItem.linkPath = '/pages/tem/auto?id=' + temItem.linkId + '&title=' + temItem.pageTitle;
            } else if (temItem.linkCode === 'storeTem') {
              temItem.linkPath = '/pages/storeTem/storeTem?id=' + temItem.linkId + '&title=' + temItem.pageTitle;
            } else if (temItem.linkCode === 'storeMap') {
              temItem.linkPath = '/pages/storeTem/map?id=' + temItem.linkId;
            } else if (temItem.linkCode === '4' || temItem.linkCode === '5') {
              return;
            } else {
              temItem.linkPath = temItem.linkCode;
            }
          }

          if (temItem.imgCode) {
            if (temItem.imgCode === 'bigPuzzle') {
              temItem.imgPath = '/pages/puzzle/start?gameId=' + temItem.imgId;
            } else if (temItem.imgCode === 'bigYang') {
              temItem.imgPath = '/pages/yang/start?gameId=' + temItem.imgId;
            } else if (temItem.imgCode === 'mikaPuzzle') {
              temItem.imgPath = '/activity/game/imgsStart?gameId=' + temItem.imgId;
            } else if (temItem.imgCode === 'giveScore') {
              temItem.imgPath = '/activity/giveScore/giveScore?id=' + temItem.imgId;
            } else if (temItem.imgCode === 'mikaYang') {
              temItem.imgPath = '/activity/game/yangStart?gameId=' + temItem.imgId;
            } else if (temItem.imgCode === 'legouGoods') {
              temItem.imgPath = '/legou/pages/goods/goods?id=' + temItem.imgId;
            } else if (temItem.imgCode === 'svipActivityDetail') {
              temItem.imgPath = "/svip/activity/detail?id=".concat(temItem.imgId);
            } else if (temItem.imgCode === 'svipServiceDetail') {
              temItem.imgPath = "/svip/service/detail?id=".concat(temItem.linkId);
            } else if (temItem.imgCode === 'svipSpaceDetail') {
              temItem.imgPath = "/svip/space/detail?id=".concat(temItem.linkId);
            } else if (temItem.imgCode === 'coupon') {
              temItem.imgPath = '/pages/coupons/couponDea/couponDea?type=3&id=' + temItem.imgId;
            } else if (temItem.imgCode === 'legouGoods') {
              temItem.imgPath = "/legou/pages/goods/goods?id=".concat(temItem.imgId);
            } else if (temItem.imgCode === 'legouTem') {
              temItem.imgPath = "/legou/pages/index/index?page_id=".concat(temItem.imgId);
            } else if (temItem.imgCode === 'specialActivity') {
              temItem.imgPath = "/activity/newGift/special?id=".concat(temItem.imgId);
            } else if (temItem.imgCode === 'waterCoupon') {
              temItem.imgPath = "/activity/waterCoupon/index?id=".concat(temItem.imgId);
            } else if (temItem.imgCode === 'legouLive') {
              temItem.imgPath = "plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=".concat(temItem.imgId);
            } else if (temItem.imgCode === 'legouShop') {
              temItem.imgPath = "/legou/mch/shop/shop?mch_id=".concat(temItem.imgId);
            } else if (temItem.imgCode === 'svipActivityClass') {
              temItem.imgPath = "/svip/activity/index?classId=".concat(temItem.imgId);
            } else if (temItem.imgCode === 'legouPt') {
              temItem.imgPath = "/legou/pages/pt/details/details?id=".concat(temItem.imgId);
            } else if (temItem.imgCode === 'vipCityLife') {
              temItem.imgPath = "/pages/coupons/couponDea/couponDea?share=unShare&type=3&vip=isVip&id=".concat(temItem.imgId, "&citylifeId=").concat(temItem.citylifeId);
            } else if (temItem.imgCode === 'couponBag') {
              temItem.imgPath = '/pages/coupons/couponGift/couponGift?id=' + temItem.imgId;
            } else if (temItem.imgCode === 'help') {
              temItem.imgPath = '/activity/help/help?id=' + temItem.imgId;
            } else if (temItem.imgCode === 'apply') {
              temItem.imgPath = '/activity/apply/dea?id=' + temItem.imgId;
            } else if (temItem.imgCode === 'card') {
              if (temItem.cardType == 3 || item.cardType == 4) {
                temItem.imgPath = '/pages/starCard/giftToken/giftToken?id=' + temItem.imgId;
              } else {
                temItem.imgPath = '/pages/starCard/recharge/recharge?id=' + temItem.imgId;
              }
            } else if (temItem.imgCode === 'activity') {
              temItem.imgPath = '/pages/discover/detail/index/index?id=' + temItem.imgId;
            } else if (temItem.imgCode === 'vote') {
              temItem.imgPath = '/pages/voteActivity/voteActivity?id=' + temItem.imgId;
            } else if (temItem.imgCode === 'goods') {
              temItem.imgPath = '/mall/goods/dea?id=' + temItem.imgId;
            } else if (temItem.imgCode === 'pond') {
              temItem.imgPath = '/pages/pond/pond/pond?id=' + temItem.imgId;
            } else if (temItem.imgCode === 'blind') {
              temItem.imgPath = '/activity/pond/pond?id=' + temItem.imgId;
            } else if (temItem.imgCode === 'tem') {
              temItem.imgPath = '/pages/tem/tem?id=' + temItem.imgId + '&title=' + temItem.pageTitle;
            } else if (temItem.imgCode === 'auto') {
              temItem.imgPath = '/pages/tem/auto?id=' + temItem.imgId + '&title=' + temItem.pageTitle;
            } else if (temItem.imgCode === 'storeTem') {
              temItem.imgPath = '/pages/storeTem/storeTem?id=' + temItem.imgId + '&title=' + temItem.pageTitle;
            } else if (temItem.imgCode === 'storeMap') {
              temItem.imgPath = '/pages/storeTem/map?id=' + temItem.imgId;
            } else if (temItem.imgCode === '4' || temItem.imgCode === '5') {} else {
              temItem.imgPath = temItem.imgCode;
            }
          }

          if (temItem.moreCode) {
            if (temItem.moreCode === 'bigPuzzle') {
              temItem.morePath = '/pages/puzzle/start?gameId=' + temItem.moreId;
            } else if (temItem.moreCode === 'bigYang') {
              temItem.morePath = '/pages/yang/start?gameId=' + temItem.moreId;
            } else if (temItem.moreCode === 'mikaPuzzle') {
              temItem.morePath = '/activity/game/imgsStart?gameId=' + temItem.moreId;
            } else if (temItem.moreCode === 'giveScore') {
              temItem.morePath = '/activity/giveScore/giveScore?id=' + temItem.moreId;
            } else if (temItem.moreCode === 'mikaYang') {
              temItem.morePath = '/activity/game/yangStart?gameId=' + temItem.moreId;
            } else if (temItem.moreCode === 'legouGoods') {
              temItem.morePath = '/legou/pages/goods/goods?id=' + temItem.moreId;
            } else if (temItem.moreCode === 'svipActivityDetail') {
              temItem.morePath = "/svip/activity/detail?id=".concat(temItem.moreId);
            } else if (temItem.moreCode === 'svipServiceDetail') {
              temItem.morePath = "/svip/service/detail?id=".concat(temItem.moreId);
            } else if (temItem.moreCode === 'svipSpaceDetail') {
              temItem.morePath = "/svip/space/detail?id=".concat(temItem.moreId);
            } else if (temItem.moreCode === 'coupon') {
              temItem.morePath = '/pages/coupons/couponDea/couponDea?type=3&id=' + temItem.moreId;
            } else if (temItem.moreCode === 'legouGoods') {
              temItem.morePath = "/legou/pages/goods/goods?id=".concat(temItem.moreId);
            } else if (temItem.moreCode === 'legouTem') {
              temItem.morePath = "/legou/pages/index/index?page_id=".concat(temItem.moreId);
            } else if (temItem.moreCode === 'specialActivity') {
              temItem.morePath = "/activity/newGift/special?id=".concat(temItem.moreId);
            } else if (temItem.moreCode === 'waterCoupon') {
              temItem.morePath = "/activity/waterCoupon/index?id=".concat(temItem.moreId);
            } else if (temItem.moreCode === 'legouLive') {
              temItem.morePath = "plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=".concat(temItem.moreId);
            }

            if (temItem.moreCode === 'vipCityLife') {
              temItem.morePath = "/pages/coupons/couponDea/couponDea?share=unShare&type=3&vip=isVip&id=".concat(temItem.moreId, "&citylifeId=").concat(temItem.citylifeId);
            }

            if (temItem.moreCode === 'couponBag') {
              temItem.morePath = '/pages/coupons/couponGift/couponGift?id=' + temItem.moreId;
            }

            if (temItem.moreCode === 'card') {
              if (temItem.cardType == 3 || item.cardType == 4) {
                temItem.morePath = '/pages/starCard/giftToken/giftToken?id=' + temItem.moreId;
              } else {
                temItem.morePath = '/pages/starCard/recharge/recharge?id=' + temItem.moreId;
              }
            }

            if (temItem.moreCode === 'activity') {
              temItem.morePath = '/pages/discover/detail/index/index?id=' + temItem.moreId;
            }

            if (temItem.moreCode === 'vote') {
              temItem.morePath = '/pages/voteActivity/voteActivity?id=' + temItem.moreId;
            }

            if (temItem.moreCode === 'goods') {
              temItem.morePath = '/mall/goods/dea?id=' + temItem.moreId;
            }

            if (temItem.moreCode === 'pond') {
              temItem.morePath = '/pages/pond/pond/pond?id=' + temItem.moreId;
            }

            if (temItem.moreCode === 'blind') {
              temItem.morePath = '/activity/pond/pond?id=' + temItem.moreId;
            }

            if (temItem.moreCode === 'tem') {
              temItem.morePath = '/pages/tem/tem?id=' + temItem.moreId + '&title=' + temItem.morePageTitle;
            } else if (temItem.moreCode === 'auto') {
              temItem.morePath = '/pages/tem/auto?id=' + temItem.moreId + '&title=' + temItem.morePageTitle;
            } else if (temItem.linkCode === 'storeTem') {
              temItem.morePath = '/pages/storeTem/storeTem?id=' + temItem.moreId + '&title=' + temItem.morePageTitle;
            } else if (temItem.linkCode === 'storeMap') {
              temItem.morePath = '/pages/storeTem/map?id=' + temItem.moreId;
            } else if (temItem.moreCode === '4' || temItem.moreCode === '5') {
              return;
            } else {
              temItem.morePath = temItem.moreCode;
            }
          }

          var data = {
            tmplateId: _this12.ruleForm.id,
            moduleName: _this12.templateList[_this12.temObjIndex].moduleName,
            moduleStatus: _this12.templateList[_this12.temObjIndex].moduleStatus,
            styleCode: _this12.templateList[_this12.temObjIndex].styleCode,
            whiteId: _this12.templateList[_this12.temObjIndex].whiteId,
            moduleAttr: JSON.stringify(temItem),
            temMargin: _this12.templateList[_this12.temObjIndex].temMargin
          };
          console.log(data);

          _this12.openLoading();

          if (_this12.templateList[_this12.temObjIndex].id) {
            data.id = _this12.templateList[_this12.temObjIndex].id;
            (0, _template.editModule)(data).then(function (res) {
              _this12.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this12.openLoading().close();
            });
          } else {
            (0, _template.addModule)(data).then(function (res) {
              _this12.$message({
                type: 'success',
                message: '新增成功!'
              });

              _this12.init();

              _this12.openLoading().close();
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;