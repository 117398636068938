"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

var _report = require("@/api/report.js");

var _mem = require("@/api/mem.js");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      userInfo: {},
      userCard: {},
      storeList: [],
      isGoCard: false,
      rules: {
        uid: [{
          required: true,
          message: '请先输入手机号查询用户'
        }],
        amount: [{
          required: true,
          message: '请输入冻结金额'
        }],
        voucherUrl: [{
          required: true,
          message: '请上传冻结凭证'
        }],
        lockReason: [{
          required: true,
          message: '请输入冻结理由'
        }]
      },
      ruleForm: {
        amount: null,
        uid: false,
        voucherUrl: '',
        lockReason: null,
        userMobile: '',
        institutionCode: null
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.storeList = res.data;
    });
  },
  methods: {
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    changeMobile: function changeMobile() {
      this.isGoCard = false;
    },
    getUserInfo: function getUserInfo() {
      var _this2 = this;

      var data = {
        mobile: this.ruleForm.userMobile,
        type: 1
      };
      this.isGoCard = false;
      this.userInfo = {};
      this.userCard = {};
      (0, _report.handleMidUser)(data).then(function (res) {
        console.log(111111111);
        _this2.isGoCard = true;
        _this2.userInfo = res.data.user;
        _this2.ruleForm.uid = res.data.user.uid;
        (0, _api.getUserCard)({
          uid: res.data.user.uid,
          institutionCode: _this2.ruleForm.institutionCode
        }).then(function (card) {
          _this2.userCard = card.data;
        });
      });
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.goRecharge)(_this3.ruleForm).then(function (res) {
            _this3.$router.go(-1);

            _this3.$message({
              type: 'success',
              message: '冻结成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;