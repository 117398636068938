var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 活动券B订单 "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", size: "mini", placeholder: "订单号" },
                model: {
                  value: _vm.requestData.orderNo,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "orderNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.orderNo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "search_select",
                  attrs: {
                    size: "mini",
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择状态"
                  },
                  on: {
                    change: function($event) {
                      return _vm.recordPage("search")
                    }
                  },
                  model: {
                    value: _vm.requestData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "status", $$v)
                    },
                    expression: "requestData.status"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "已完成", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已取消", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "search_select",
                  attrs: {
                    size: "mini",
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择门店编码"
                  },
                  on: {
                    change: function($event) {
                      return _vm.recordPage("search")
                    }
                  },
                  model: {
                    value: _vm.requestData.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "storeId", $$v)
                    },
                    expression: "requestData.storeId"
                  }
                },
                _vm._l(_vm.storeList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.shortName, value: item.storeCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 7 } },
            [
              _c("el-date-picker", {
                staticClass: "search_input",
                attrs: {
                  size: "mini",
                  type: "datetimerange",
                  "range-separator": "——",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  "default-time": ["00:00:00", "23:59:59"]
                },
                on: {
                  change: function($event) {
                    return _vm.recordPage("search")
                  }
                },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.recordPage("search")
                }
              }
            },
            [_vm._v("查询")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "100"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 1
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("\n          已完成\n        ")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("已取消")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { slot: "operate", align: "center", width: "100" },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/couponActivity/couponCOrderDea?id=" +
                                scope.row.orderNo
                            )
                          }
                        }
                      },
                      [_vm._v("详情\n        ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }