var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 优惠券列表 "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  size: "mini",
                  icon: "el-icon-circle-plus-outline",
                  type: "primary"
                },
                on: { click: _vm.goAdd }
              },
              [_vm._v("新增优惠券")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "优惠券编码" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.removeInit($event)
                  }
                },
                model: {
                  value: _vm.uuid,
                  callback: function($$v) {
                    _vm.uuid = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "uuid"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "优惠券名称" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.removeInit($event)
                  }
                },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "优惠券类型"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.couponType,
                    callback: function($$v) {
                      _vm.couponType = $$v
                    },
                    expression: "couponType"
                  }
                },
                _vm._l(_vm.options, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "是否商户券"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.merchantType,
                    callback: function($$v) {
                      _vm.merchantType = $$v
                    },
                    expression: "merchantType"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "非商户券", value: 0 } }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "商户券（乐购使用）", value: 1 }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { size: "mini", clearable: "", placeholder: "状态" },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.status,
                    callback: function($$v) {
                      _vm.status = $$v
                    },
                    expression: "status"
                  }
                },
                _vm._l(_vm.options1, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "所属总部"
                  },
                  on: { change: _vm.setShort },
                  model: {
                    value: _vm.memberId,
                    callback: function($$v) {
                      _vm.memberId = $$v
                    },
                    expression: "memberId"
                  }
                },
                _vm._l(_vm.memberArr, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.shortName, value: item.companyCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "所属门店"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.storeCode,
                    callback: function($$v) {
                      _vm.storeCode = $$v
                    },
                    expression: "storeCode"
                  }
                },
                _vm._l(_vm.storeList, function(item, index) {
                  return _c(
                    "el-option",
                    {
                      key: index,
                      attrs: {
                        label:
                          item.shortName + '"（"' + item.storeCode + '"）"',
                        value: item.storeCode
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  size: "mini",
                  type: "daterange",
                  "range-separator": "——",
                  format: "yyyy 年 MM 月 dd 日",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                on: {
                  change: function($event) {
                    return _vm.getList("search")
                  }
                },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.getList("search")
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "info",
                    size: "mini",
                    icon: "el-icon-refresh"
                  },
                  on: { click: _vm.remove }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list_table" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { size: "mini", data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  width: "50",
                  align: "center",
                  label: "ID",
                  fixed: "left"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " + _vm._s(scope.row.id) + "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "优惠券图", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "50px" },
                          attrs: {
                            src: _vm.imgSrc + scope.row.mainImage,
                            "preview-src-list": [
                              _vm.imgSrc + scope.row.mainImage
                            ]
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "可购买人群标签",
                  align: "center",
                  "mni-width": "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getUserLabel(scope.row.labelContent)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _vm._l(_vm.tabTh, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    sortable: item.sortable,
                    prop: item.prop,
                    label: item.tit,
                    "min-width": item.width,
                    align: "center"
                  }
                })
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "是否可分享", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "span",
                          {
                            class:
                              scope.row.ifShare === 1 ? "success" : "danger"
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.ifShare === 1 ? "是" : "否")
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  width: "240",
                  align: "center",
                  label: "操作",
                  fixed: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.couponType === 5
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleOpenCodeList(scope.row)
                                  }
                                }
                              },
                              [_vm._v("\n            券码\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/coupon/coupon1/addVolume?id=" +
                                    scope.row.id +
                                    "&couponType=" +
                                    scope.row.couponType +
                                    "&type=copy"
                                )
                              }
                            }
                          },
                          [_vm._v("\n            复制\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.setBtn(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n            详情\n          ")]
                        ),
                        _vm._v(" "),
                        scope.row.status === 2 &&
                        _vm.setMenuPerms("coupon:qrcode")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.getCode(scope.row)
                                  }
                                }
                              },
                              [_vm._v("\n            二维码\n\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 2 &&
                        scope.row.couponType !== 5 &&
                        scope.row.couponType != 3 &&
                        _vm.setMenuPerms("coupon:qrcode")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.getCode5(scope.row)
                                  }
                                }
                              },
                              [_vm._v("\n            外部码\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 2 &&
                        scope.row.couponType === 5 &&
                        scope.row.isOnline == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.goAddStock(scope.row)
                                  }
                                }
                              },
                              [_vm._v("\n            追加库存\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            style: {
                              color: scope.row.isOnline == 1 ? "red" : ""
                            },
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.setIsOnline(scope.row, scope.$index)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.isOnline == 1 ? "下架" : "上架"
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        scope.row.status == 1 &&
                        _vm.setMenuPerms("coupon:audit")
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.setStatus(
                                      scope.row,
                                      scope.$index
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n            审核\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 2
                          ? _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v("\n            已审核\n          ")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 6
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: { type: "text" }
                              },
                              [_vm._v("\n            已终止\n          ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0"
              }
            ],
            attrs: { total: _vm.total, page: _vm.pageNo, limit: _vm.pageSize },
            on: {
              "update:page": function($event) {
                _vm.pageNo = $event
              },
              "update:limit": function($event) {
                _vm.pageSize = $event
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              url: _vm.url,
              "qr-code": _vm.qrCode,
              "coupon-u-uid": _vm.couponUUid
            },
            on: { closePop: _vm.closePop }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isExchange
        ? _c("div", { staticClass: "fixed_center" }, [
            _c(
              "div",
              {
                staticClass: "fixed_center_box",
                staticStyle: {
                  width: "600px",
                  padding: "20px",
                  background: "#FFFFFF"
                }
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      size: "small",
                      "label-width": "150px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "数量：", prop: "number" } },
                      [
                        _c("el-input-number", {
                          attrs: { controls: false, min: 1 },
                          model: {
                            value: _vm.ruleForm.number,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "number",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.number"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注：", prop: "remark" } },
                      [
                        _c("el-input", {
                          staticClass: "form_input",
                          model: {
                            value: _vm.ruleForm.remark,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "remark",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.remark"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "凭证：", prop: "token" } },
                      [
                        _c("add-load", {
                          attrs: {
                            width: 100,
                            height: 100,
                            cover: _vm.ruleForm.token
                          },
                          on: { setCover: _vm.setCover }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                _vm.isExchange = false
                              }
                            }
                          },
                          [_vm._v("取消")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("ruleForm")
                              }
                            }
                          },
                          [_vm._v("立即导出")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("external-code-list", { ref: "externalCodeListRef" }),
      _vm._v(" "),
      _vm.isAddStock
        ? _c(
            "div",
            { staticClass: "fixed_center" },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex_between",
                      staticStyle: { "align-items": "center" },
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", { staticClass: "view_title" }, [
                        _vm._v(
                          "追加券码-" +
                            _vm._s(_vm.addStockCoupon.name) +
                            "-" +
                            _vm._s(_vm.addStockCoupon.uuid)
                        )
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-close danger",
                        staticStyle: { "font-size": "20px", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            _vm.isAddStock = false
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("upload-excel-component", {
                    attrs: {
                      size: "mini",
                      "btn-text": "导入券码",
                      type: "btn",
                      "on-success": _vm.handleSuccess,
                      "before-upload": _vm.beforeUpload
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { margin: "10px 0" },
                      attrs: {
                        data: _vm.couponRecodes,
                        border: "",
                        "max-height": "500",
                        size: "mini"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "外部券码",
                          align: "center",
                          prop: "receivedCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "兑换密码",
                          align: "center",
                          prop: "receivedPwd"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.couponRecodes.length > 0
                    ? _c(
                        "el-popconfirm",
                        {
                          attrs: { title: "确认追加券码？" },
                          on: {
                            confirm: function($event) {
                              return _vm.goAffrimAddStock()
                            }
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                icon: "el-icon-circle-plus-outline",
                                type: "primary",
                                size: "mini"
                              },
                              slot: "reference"
                            },
                            [_vm._v("\n          确认追加券码\n        ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("追加库存后优惠券需要审核")
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }