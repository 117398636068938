"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typeOptions = exports.statusOptions = exports.sliceNumOptions = exports.rewardTypeOptions = exports.raffleTypeOptions = exports.raffleShapeOptions = void 0;
var typeOptions = [{
  value: 1,
  label: '拼图'
}, {
  value: 2,
  label: '消消乐'
}];
exports.typeOptions = typeOptions;
var statusOptions = [{
  value: 1,
  label: '启用'
}, {
  value: 2,
  label: '禁用'
}];
exports.statusOptions = statusOptions;
var sliceNumOptions = [{
  value: 9,
  label: '9片'
}, {
  value: 16,
  label: '16片'
}, {
  value: 25,
  label: '25片'
}, {
  value: 36,
  label: '30片'
}];
exports.sliceNumOptions = sliceNumOptions;
var rewardTypeOptions = [{
  value: 1,
  label: '会员积分'
}, {
  value: 2,
  label: '优惠券包'
}];
exports.rewardTypeOptions = rewardTypeOptions;
var raffleTypeOptions = [{
  value: 1,
  label: '大转盘'
}, {
  value: 2,
  label: '九宫格'
}];
exports.raffleTypeOptions = raffleTypeOptions;
var raffleShapeOptions = [{
  value: 1,
  label: '每日均可抽奖'
}, {
  value: 2,
  label: '一次性抽奖'
}];
exports.raffleShapeOptions = raffleShapeOptions;