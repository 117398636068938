var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("方案内容")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c("ul", [
          _c("li", { staticClass: "dea_box_li" }, [
            _c(
              "div",
              { staticClass: "dea_box_li_div" },
              [
                _c("p", { staticClass: "dea_box_li_tit" }, [
                  _vm._v("优惠券：")
                ]),
                _vm._v(" "),
                _vm._l(_vm.couponId, function(item, index) {
                  return _c(
                    "p",
                    { key: index, staticClass: "dea_box_li_txt" },
                    [
                      _c("span", { staticClass: "coupon_id" }, [
                        _vm._v(_vm._s(item.uuid))
                      ]),
                      _vm._v(
                        "\n                -" +
                          _vm._s(item.typeName) +
                          "-\n                "
                      ),
                      _c("span", { staticClass: "coupon_name" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _vm._v(
                        "\n                -（" +
                          _vm._s(item.validTime) +
                          "）\n              "
                      )
                    ]
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("优惠券名称：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.couponId[0].name))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("优惠券类型：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.couponId[0].typeName))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("投放时间：")
              ]),
              _vm._v(" "),
              _vm.orientObj.onlineTime == 1
                ? _c("p", { staticClass: "dea_box_li_txt" }, [
                    _vm._v(
                      _vm._s(_vm.orientObj.onlineBeginTime) +
                        " 至 " +
                        _vm._s(_vm.orientObj.onlineEndTime)
                    )
                  ])
                : _c("p", { staticClass: "dea_box_li_txt" }, [
                    _vm._v("立即上线")
                  ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dea_box_li_div" },
              [
                _c("p", { staticClass: "dea_box_li_tit" }, [
                  _vm._v("投放数量：")
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  model: {
                    value: _vm.putNumber,
                    callback: function($$v) {
                      _vm.putNumber = $$v
                    },
                    expression: "putNumber"
                  }
                }),
                _vm._v(" "),
                _vm.putNumbers === -1
                  ? _c("p", { staticClass: "dea_box_li_tit" }, [
                      _vm._v("\n                不限制数量\n              ")
                    ])
                  : _c("p", { staticClass: "dea_box_li_tit" }, [
                      _vm._v(
                        "\n                剩余可投放数量\n                "
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.putNumbers))])
                    ])
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.next } },
          [_vm._v("确认提交方案")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }