var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-manager-detail" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("预约详情")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "基本信息" } },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "预约单号"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.orderSn) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "会员手机号"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.mobile) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "会员名称"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.realname) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "会员类型"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.SvipTypes[_vm.manager.memberType]) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "预约类型"
                      }
                    },
                    [
                      _c("div", [
                        _vm.manager.type === 1
                          ? _c("div", [_vm._v("服务预约")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.manager.type === 2
                          ? _c("div", [_vm._v("场地预约")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.manager.type === 3
                          ? _c("div", [_vm._v("活动预约")])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "服务名称"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "svip-manager-detail_itemName",
                          on: {
                            click: function($event) {
                              return _vm.goto(_vm.manager)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.manager.itemName) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.manager.institutionName
                ? _c(
                    "el-descriptions",
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "预约品牌"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.manager.institutionName) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "预约状态"
                      }
                    },
                    [
                      _vm.manager.status === 1
                        ? _c(
                            "div",
                            {
                              staticStyle: { display: "flex" },
                              on: { click: _vm.submitForm }
                            },
                            [
                              _c("div", [_vm._v("待支付")]),
                              _vm._v(" "),
                              _c("img", {
                                staticStyle: { width: "20px", height: "20px" },
                                attrs: {
                                  src: require("./images/icon-code.png")
                                }
                              })
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.manager.status === 2
                        ? _c("div", [_vm._v("已预约")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.manager.status === 3
                        ? _c("div", [_vm._v("已使用")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.manager.status === 4
                        ? _c("div", [_vm._v("已失效")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.manager.status === 5
                        ? _c("div", [_vm._v("已取消")])
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _vm.manager.status === 5 && _vm.manager.paid
                    ? _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "关联售后单"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "svip-manager-detail_itemName",
                              on: {
                                click: function($event) {
                                  return _vm.goPage(_vm.manager)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.manager.refundSn) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _vm.manager.cancelRemark
                    ? _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "撤销核销原因"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.manager.cancelRemark) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.manager.serviceInfo
                ? _c(
                    "el-descriptions",
                    { attrs: { title: "预约信息" } },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "预约客户"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.manager.serviceInfo.customerName) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.manager.serviceInfo
                ? _c(
                    "el-descriptions",
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "预约人数"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.manager.serviceInfo.reserveNum) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.manager.serviceInfo
                ? _c(
                    "el-descriptions",
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "是否上门"
                          }
                        },
                        [
                          _c("div", [
                            _vm.manager.serviceInfo &&
                            _vm.manager.serviceInfo.needD2d === true
                              ? _c("div", [_vm._v("是")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.manager.serviceInfo &&
                            _vm.manager.serviceInfo.needD2d === false
                              ? _c("div", [_vm._v("否")])
                              : _vm._e()
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.manager.serviceInfo
                ? _c(
                    "el-descriptions",
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "服务地址"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.manager.serviceInfo.doorAddress) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.manager.activityInfo
                ? _c(
                    "el-descriptions",
                    { attrs: { title: "预约信息" } },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "预约客户"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.manager.activityInfo.customerName) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.manager.activityInfo
                ? _c(
                    "el-descriptions",
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "姓名"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.manager.activityInfo.userName) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.manager.activityInfo
                ? _c(
                    "el-descriptions",
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "性别"
                          }
                        },
                        [
                          _c("div", [
                            _vm.manager.activityInfo.sex === 1
                              ? _c("div", [_vm._v("男")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.manager.activityInfo.sex === 2
                              ? _c("div", [_vm._v("女")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.manager.activityInfo.sex === 0
                              ? _c("div", [_vm._v("未知")])
                              : _vm._e()
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.manager.activityInfo
                ? _c(
                    "el-descriptions",
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "手机号"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.manager.activityInfo.reserveMobile) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.manager.activityInfo
                ? _c(
                    "el-descriptions",
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "预约人数"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.manager.activityInfo.reserveNum) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.manager.placeOrderInfo
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-descriptions",
                        { attrs: { title: "预约信息" } },
                        [
                          _vm.manager.placeOrderInfo
                            ? _c(
                                "el-descriptions-item",
                                {
                                  attrs: {
                                    "label-class-name": "my-label",
                                    label: "场地名称"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.manager.placeOrderInfo.name) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions",
                        [
                          _vm.manager.placeOrderInfo
                            ? _c(
                                "el-descriptions-item",
                                {
                                  attrs: {
                                    "label-class-name": "my-label",
                                    label: "预约客户"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.manager.placeOrderInfo.customerName
                                    ) + "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions",
                        [
                          _vm.manager.placeOrderInfo
                            ? _c(
                                "el-descriptions-item",
                                {
                                  attrs: {
                                    "label-class-name": "my-label",
                                    label: "预约时间"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.manager.placeOrderInfo
                                        .reserveStartTime
                                    ) +
                                      " ~\n            " +
                                      _vm._s(
                                        _vm.manager.placeOrderInfo
                                          .reserveEndTime
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions",
                        [
                          _vm.manager.placeOrderInfo
                            ? _c(
                                "el-descriptions-item",
                                {
                                  attrs: {
                                    "label-class-name": "my-label",
                                    label: "预约人数"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.manager.placeOrderInfo.reserveNum
                                    ) + "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions",
                        [
                          !_vm.manager.placeOrderInfo.itemNames &&
                          _vm.manager.placeOrderInfo
                            ? _c(
                                "el-descriptions-item",
                                {
                                  attrs: {
                                    "label-class-name": "my-label",
                                    label: "服务项目"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.manager.placeOrderInfo.itemNames.toString()
                                    ) + "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions",
                        [
                          _vm.manager.placeOrderInfo
                            ? _c(
                                "el-descriptions-item",
                                {
                                  attrs: {
                                    "label-class-name": "my-label",
                                    label: "其他需求"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.manager.placeOrderInfo.otherNeed
                                    ) + "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _vm.manager.serviceInfo
                    ? _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "预约时间"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.manager.serviceInfo.reserveStartTime) +
                              " ~\n          " +
                              _vm._s(_vm.manager.serviceInfo.reserveEndTime)
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.manager.activityInfo
                    ? _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            "label-class-name": "my-label",
                            label: "预约时间"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.manager.activityInfo.startTime) +
                              " ~\n          " +
                              _vm._s(_vm.manager.activityInfo.endTime)
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                { attrs: { title: "支付信息" } },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "订单总价"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.totalAmount) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "积分抵扣"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.integralAmount) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "购物卡抵扣"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.cardAmount) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: { "label-class-name": "my-label", label: "实付款" }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.payAmount) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "下单时间"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.createTime) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "支付时间"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.payTime) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.manager.status !== 5
        ? _c(
            "div",
            { staticClass: "svip-manager-detail_subscribe" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.reasonOpen("reject", _vm.subOrderSn)
                    }
                  }
                },
                [_vm._v("\n      取消预约\n    ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.reasonVisible, width: "350px" },
          on: {
            "update:visible": function($event) {
              _vm.reasonVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "reasonForm", attrs: { model: _vm.reasonForm } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "reason",
                    rules: [
                      { required: true, message: "请输入原因", trigger: "blur" }
                    ]
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: {
                      placeholder: "请输入原因",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.reasonForm.reason,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.reasonForm,
                          "reason",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "reasonForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.reasonVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.reject("reasonForm")
                    }
                  }
                },
                [_vm._v("\n        确 定\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.payReasonVisible,
            width: "350px"
          },
          on: {
            "update:visible": function($event) {
              _vm.payReasonVisible = $event
            }
          }
        },
        [
          _c("div", [_vm._v("确认取消预约吗？")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.payReasonVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v(" 确 定 ")
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("PayQrcode", {
        attrs: {
          show: _vm.isShowPayQrcode,
          "create-time": _vm.payQrCodeParams.createTime,
          "now-date": _vm.payQrCodeParams.nowDate,
          minutes: _vm.payQrCodeParams.minutes,
          "pay-qrcode": _vm.payQrCodeParams.payQrCode
        },
        on: { closePayQrcode: _vm.closePayQrcode }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }