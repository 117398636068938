"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.getData = getData;
exports.getList = getList;
exports.getUserPage = getUserPage;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: '/api/activity/lx-regist-gift/list',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addData(data) {
  return (0, _request.default)({
    url: '/api/activity/lx-regist-gift/saveOrUpdate',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getData(data) {
  return (0, _request.default)({
    url: '/api/activity/lx-regist-gift/getById',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function getUserPage(data) {
  return (0, _request.default)({
    url: '/api/activity/lx-regist-gift/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}