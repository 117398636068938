var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list" }, [
    _c(
      "div",
      { staticClass: "list_top" },
      [
        _c("h2", { staticClass: "box_tit" }, [_vm._v("会员体系")]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "has",
                rawName: "v-has",
                value: "cardMember:add",
                expression: "'cardMember:add'"
              }
            ],
            staticClass: "btn",
            attrs: {
              size: "small",
              icon: "el-icon-circle-plus-outline",
              type: "primary"
            },
            on: { click: _vm.goAdd }
          },
          [_vm._v("新增会员体系")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list_table" },
      [
        _c("table-list", {
          attrs: {
            "dea-path": _vm.deaPath,
            "table-data": _vm.tableData,
            "tab-th": _vm.tabTh,
            total: _vm.total,
            "page-no": _vm.pageNo,
            "page-size": _vm.pageSize,
            btn: _vm.btn,
            "btn-type": _vm.btnType,
            operation: _vm.operation
          },
          on: { getList: _vm.getList, setBtn: _vm.setTab }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }