var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增/编辑签到奖励 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            disabled: _vm.disabled,
            "label-width": "120px",
            rules: _vm.rules,
            size: "mini",
            model: _vm.form
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "数量：", prop: "signDays" } },
            [
              _c("el-input-number", {
                staticClass: "form_input",
                attrs: { min: 1, controls: false, precision: 0 },
                model: {
                  value: _vm.form.signDays,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "signDays", $$v)
                  },
                  expression: "form.signDays"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "form_remark" }, [_vm._v("天")])
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.status < 2
            ? _c(
                "el-form-item",
                { attrs: { label: "状态：", prop: "status" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [_vm._v("禁用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [_vm._v("启用")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "每月限领：", prop: "monthLimitNum" } },
            [
              _c("el-input-number", {
                staticClass: "form_input",
                attrs: { min: 0, controls: false, precision: 0 },
                model: {
                  value: _vm.form.monthLimitNum,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "monthLimitNum", $$v)
                  },
                  expression: "form.monthLimitNum"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "form_remark" }, [_vm._v("次")]),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("每月限制领取几次，0为不限制")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动时间：", prop: "startTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择开始时间"
                    },
                    model: {
                      value: _vm.form.startTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "startTime", $$v)
                      },
                      expression: "form.startTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "", "label-width": "20px", prop: "endTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      disabled: !_vm.form.startTime,
                      "picker-options": _vm.pickerTime,
                      type: "datetime",
                      "default-time": "23:59:59",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择结束时间"
                    },
                    model: {
                      value: _vm.form.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "endTime", $$v)
                      },
                      expression: "form.endTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "labelContent",
              attrs: { prop: "labelContent", label: "参与用户标签：" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "输入标签名称搜索~",
                    "value-key": "id",
                    clearable: ""
                  },
                  model: {
                    value: _vm.form.labelContent,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "labelContent", $$v)
                    },
                    expression: "form.labelContent"
                  }
                },
                _vm._l(_vm.userLabelList, function(so) {
                  return _c("el-option", {
                    key: so.id,
                    attrs: { label: "" + so.labelName, value: so.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "奖励类型：", prop: "rewardType" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.typeChange },
                  model: {
                    value: _vm.form.rewardType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "rewardType", $$v)
                    },
                    expression: "form.rewardType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("积分")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("优惠券")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("购物卡")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.rewardType === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "积分：", prop: "price" } },
                [
                  _c("el-input-number", {
                    staticClass: "form_input",
                    attrs: { controls: false, min: 1, precision: 0 },
                    model: {
                      value: _vm.form.price,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "price", $$v)
                      },
                      expression: "form.price"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "form_remark" }, [_vm._v("积分")])
                ],
                1
              )
            : typeof _vm.form.rewardType === "number"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          "选择" + _vm.giftText[_vm.form.rewardType] + "：",
                        prop: "rewardContent"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.getAward }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.form.rewardType === 2
                                  ? "选择优惠券"
                                  : "选择购物卡"
                              ) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.form.rewardContent) +
                          "\n      "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.form.rewardType === 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "数量：", prop: "num" } },
                        [
                          _c("el-input-number", {
                            staticClass: "form_input",
                            attrs: { min: 1, controls: false, precision: 0 },
                            model: {
                              value: _vm.form.num,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "num", $$v)
                              },
                              expression: "form.num"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "form_remark" }, [
                            _vm._v("份")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.rewardType === 3
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "奖励金额：", prop: "price" } },
                        [
                          _c("el-input-number", {
                            staticClass: "form_input",
                            attrs: { min: 1, controls: false, precision: 0 },
                            model: {
                              value: _vm.form.price,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "price", $$v)
                              },
                              expression: "form.price"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "form_remark" }, [
                            _vm._v("元")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "规则说明：", prop: "description" } },
            [
              _c("Tinymce", {
                attrs: { width: 750, height: 360 },
                model: {
                  value: _vm.form.description,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "max-width": "200px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: _vm.popType, "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }