"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user.js");

var _test = _interopRequireDefault(require("./test.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    JsonView: _test.default
  },
  data: function data() {
    return {
      id: '',
      data: {
        createTime: '',
        executeRemark: '',
        reqIp: '',
        reqParams: '',
        reqUrl: '',
        userMobile: '',
        userName: ''
      }
    };
  },
  created: function created() {
    var _this = this;

    this.id = this.$route.query.id;
    this.openLoading();
    (0, _user.getSysLog)(this.id).then(function (res) {
      _this.openLoading().close();

      _this.fzObj(res.data, _this.data);

      _this.data.reqParams = JSON.parse(_this.data.reqParams);
    }).catch(function (err) {
      _this.openLoading().close();
    });
  }
};
exports.default = _default;