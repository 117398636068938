var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "140px",
            rules: _vm.rules,
            size: "mini",
            model: _vm.form
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品来源", prop: "productSource" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: this.form.id > 0 },
                  on: { change: _vm.changeType },
                  model: {
                    value: _vm.form.productSource,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "productSource", $$v)
                    },
                    expression: "form.productSource"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("积分商城")]),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    { attrs: { disabled: _vm.isSourceOne, label: 2 } },
                    [_vm._v("大罗马")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    { attrs: { disabled: _vm.isSourceOne, label: 3 } },
                    [_vm._v("乐购")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    { attrs: { disabled: _vm.isSourceOne, label: 4 } },
                    [_vm._v("乐品")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    { attrs: { disabled: _vm.isSourceOne, label: 6 } },
                    [_vm._v("话费充值")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.productSource == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "大罗马商品", prop: "productCode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%", "max-width": "300px" },
                      attrs: {
                        filterable: "",
                        placeholder: "请选择大罗马商品",
                        disabled: _vm.form.id > 0
                      },
                      on: { change: _vm.changeDlm },
                      model: {
                        value: _vm.form.productCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "productCode", $$v)
                        },
                        expression: "form.productCode"
                      }
                    },
                    _vm._l(_vm.dlmListGoods, function(item) {
                      return _c("el-option", {
                        key: item.goods_sn,
                        attrs: { label: item.goods_name, value: item.goods_sn }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.productSource == 6
            ? _c(
                "el-form-item",
                { attrs: { label: "话费充值商品", prop: "productCode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%", "max-width": "300px" },
                      attrs: {
                        filterable: "",
                        placeholder: "请选择充值商品",
                        disabled: _vm.form.id > 0
                      },
                      on: { change: _vm.changeDlm },
                      model: {
                        value: _vm.form.productCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "productCode", $$v)
                        },
                        expression: "form.productCode"
                      }
                    },
                    _vm._l(_vm.recharge, function(item) {
                      return _c("el-option", {
                        key: item.goods_sn,
                        attrs: { label: item.goods_name, value: item.goods_sn }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.productSource == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "大罗马商品规格", prop: "skuid" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%", "max-width": "300px" },
                      attrs: {
                        disabled: _vm.form.id > 0,
                        filterable: "",
                        placeholder: "请选择大罗马规格"
                      },
                      on: { change: _vm.changeDlm },
                      model: {
                        value: _vm.form.skuid,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "skuid", $$v)
                        },
                        expression: "form.skuid"
                      }
                    },
                    _vm._l(_vm.productSku, function(item) {
                      return _c("el-option", {
                        key: item.skuid,
                        attrs: { label: item.sku_desc, value: item.skuid }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.productSource == 3 || _vm.form.productSource == 4
            ? _c(
                "el-form-item",
                { attrs: { label: "商品编码", prop: "productCode" } },
                [
                  _c("el-input", {
                    staticClass: "input_width",
                    model: {
                      value: _vm.form.productCode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "productCode", $$v)
                      },
                      expression: "form.productCode"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品名称", prop: "productName" } },
            [
              _c("el-input", {
                staticClass: "input_width",
                attrs: { "max-length": "20" },
                model: {
                  value: _vm.form.productName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productName", $$v)
                  },
                  expression: "form.productName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品简介", prop: "productInstr" } },
            [
              _c("el-input", {
                staticClass: "input_width",
                model: {
                  value: _vm.form.productInstr,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productInstr", $$v)
                  },
                  expression: "form.productInstr"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品分类", prop: "categoryId" } },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("el-tree", {
                    ref: "treeForm",
                    staticClass: "li_input",
                    attrs: {
                      "default-expanded-keys": _vm.defaultKeys,
                      data: _vm.firstList,
                      "show-checkbox": "",
                      "node-key": "id",
                      props: _vm.props,
                      "check-strictly": true,
                      "expand-on-click-node": false
                    },
                    on: { "check-change": _vm.handleClick }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品封面图", prop: "imageUrl" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: { width: 100, height: 100, cover: _vm.form.imageUrl },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "imageUrl")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("建议尺寸750*750")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品模板图", prop: "activityPicture" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: {
                  width: 100,
                  height: 100,
                  cover: _vm.form.activityPicture
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "activityPicture")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("商品模板图538*710")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "成本价", prop: "costPrice" } },
            [
              _c("el-input", {
                staticClass: "input_width",
                model: {
                  value: _vm.form.costPrice,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "costPrice", $$v)
                  },
                  expression: "form.costPrice"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "市场价", prop: "marketPrice" } },
            [
              _c("el-input", {
                staticClass: "input_width",
                model: {
                  value: _vm.form.marketPrice,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "marketPrice", $$v)
                  },
                  expression: "form.marketPrice"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品折扣", prop: "productDiscount" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "input_width",
                  attrs: { type: "number" },
                  model: {
                    value: _vm.form.productDiscount,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "productDiscount",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.productDiscount"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v("请填写折扣比率，8折填写80，8.5折填写85，以此类推")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "可购买会员类型", prop: "memberTypes" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.memberTypes,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "memberTypes", $$v)
                    },
                    expression: "form.memberTypes"
                  }
                },
                _vm._l(_vm.vipTypeList, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否可搜索", prop: "canSearch" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.canSearch,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "canSearch", $$v)
                    },
                    expression: "form.canSearch"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("支持")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("不支持")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否开放购买", prop: "canPurchase" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.canPurchase,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "canPurchase", $$v)
                    },
                    expression: "form.canPurchase"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开放")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("不开放")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.canSearch == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "搜索关键词推荐" } },
                [
                  _c("el-input", {
                    staticClass: "input_width",
                    model: {
                      value: _vm.form.searchKeyword,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "searchKeyword", $$v)
                      },
                      expression: "form.searchKeyword"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("多个关联词请用英文逗号“,”隔开")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否使用规格", prop: "useSpec" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeUseSpec },
                  model: {
                    value: _vm.form.useSpec,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "useSpec", $$v)
                    },
                    expression: "form.useSpec"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("使用")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("不使用")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.useSpec == 0
            ? _c(
                "el-form-item",
                { attrs: { label: "销售价格", prop: "salestypes" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      ref: "priceIntegral",
                      staticStyle: { width: "100%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.form.salestypes,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "salestypes", $$v)
                        },
                        expression: "form.salestypes"
                      }
                    },
                    _vm._l(_vm.form.buyType, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "flex" },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("el-radio", { attrs: { label: item.type } }, [
                                _vm._v(_vm._s(item.label))
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          item.type == 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "原价：",
                                    "label-width": "130px",
                                    prop: "buyType." + index + ".price",
                                    rules: [
                                      {
                                        required: _vm.form.salestypes === 1,
                                        message: "请输入销售价格"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      disabled: _vm.form.salestypes != 1,
                                      controls: false,
                                      min: 0.01
                                    },
                                    model: {
                                      value: item.price,
                                      callback: function($$v) {
                                        _vm.$set(item, "price", $$v)
                                      },
                                      expression: "item.price"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "form_remark" }, [
                                    _vm._v("元")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.type == 2
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "原价：",
                                    "label-width": "130px",
                                    prop: "buyType." + index + ".score",
                                    rules: [
                                      {
                                        required: _vm.form.salestypes === 2,
                                        message: "请输入销售积分"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      disabled: _vm.form.salestypes != 2,
                                      controls: false,
                                      min: 1
                                    },
                                    model: {
                                      value: item.score,
                                      callback: function($$v) {
                                        _vm.$set(item, "score", $$v)
                                      },
                                      expression: "item.score"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "form_remark" }, [
                                    _vm._v("分")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.type == 1 && item.price > 0
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "积分可抵扣：",
                                    "label-width": "120px"
                                  }
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ff4949",
                                      "active-value": item.price,
                                      "inactive-value": 0
                                    },
                                    model: {
                                      value: item.deductiblePrice,
                                      callback: function($$v) {
                                        _vm.$set(item, "deductiblePrice", $$v)
                                      },
                                      expression: "item.deductiblePrice"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.type == 3
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "",
                                    "label-width": "40px",
                                    prop: "buyType." + index + ".price",
                                    rules: [
                                      {
                                        required: _vm.form.salestypes === 3,
                                        message: "请输入销售价格"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      disabled: _vm.form.salestypes != 3,
                                      controls: false,
                                      min: 0.01
                                    },
                                    model: {
                                      value: item.price,
                                      callback: function($$v) {
                                        _vm.$set(item, "price", $$v)
                                      },
                                      expression: "item.price"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "form_remark" }, [
                                    _vm._v("元")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "form_remark",
                                      staticStyle: { margin: "0 10px" }
                                    },
                                    [_vm._v("+")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.type == 3
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "",
                                    "label-width": "0px",
                                    prop: "buyType." + index + ".score",
                                    rules: [
                                      {
                                        required: _vm.form.salestypes === 3,
                                        message: "请输入销售积分"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      disabled: _vm.form.salestypes != 3,
                                      controls: false,
                                      min: 1
                                    },
                                    model: {
                                      value: item.score,
                                      callback: function($$v) {
                                        _vm.$set(item, "score", $$v)
                                      },
                                      expression: "item.score"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "form_remark" }, [
                                    _vm._v("分")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "周期活动价格：" } },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("el-switch", {
                    staticStyle: { "margin-top": "4px" },
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 5,
                      "inactive-value": 0
                    },
                    model: {
                      value: _vm.salestypesWeek,
                      callback: function($$v) {
                        _vm.salestypesWeek = $$v
                      },
                      expression: "salestypesWeek"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "原价：",
                        "label-width": "140px",
                        rules: [
                          {
                            required: _vm.salestypesWeek === 5,
                            message: "请输入销售价格"
                          }
                        ]
                      }
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          disabled: _vm.salestypesWeek != 5,
                          controls: false,
                          min: 1
                        },
                        model: {
                          value: _vm.salestypesWeekData.price,
                          callback: function($$v) {
                            _vm.$set(_vm.salestypesWeekData, "price", $$v)
                          },
                          expression: "salestypesWeekData.price"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "form_remark" }, [_vm._v("元")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "积分可抵扣：", "label-width": "120px" }
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                          "active-value": _vm.salestypesWeekData.price,
                          "inactive-value": 0
                        },
                        model: {
                          value: _vm.salestypesWeekData.deductiblePrice,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.salestypesWeekData,
                              "deductiblePrice",
                              $$v
                            )
                          },
                          expression: "salestypesWeekData.deductiblePrice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v(
                  "周期活动价格，仅在周期推荐中有效；配置后周期推荐商品购买有效"
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm.form.useSpec == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "规则组和规格值" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "input_width",
                      attrs: { placeholder: "如:颜色 尺码  套餐等" },
                      model: {
                        value: _vm.attrName,
                        callback: function($$v) {
                          _vm.attrName = $$v
                        },
                        expression: "attrName"
                      }
                    },
                    [_c("template", { slot: "prepend" }, [_vm._v("规格组")])],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.addAttrs } },
                    [_vm._v("添加")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.productAttrs, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "attrName",
                        staticStyle: {
                          width: "600px",
                          border: "1px solid #CCCCCC",
                          padding: "20px",
                          "margin-top": "10px"
                        }
                      },
                      [
                        _c(
                          "p",
                          { staticStyle: { "margin-bottom": "10px" } },
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  size: "medium",
                                  type: "dark",
                                  closable: !_vm.form.id
                                },
                                on: {
                                  close: function($event) {
                                    return _vm.delCLick(index)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.attrName))]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(item.attrDetails, function(tag, tagIndex) {
                          return _c(
                            "el-tag",
                            {
                              key: tagIndex,
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                closable: !_vm.form.id,
                                "disable-transitions": false
                              },
                              on: {
                                close: function($event) {
                                  return _vm.handleClose(index, tagIndex)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(tag["value" + index]) +
                                  "\n        "
                              )
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.inputVisible && index == _vm.attrIndex
                          ? _c("el-input", {
                              ref: "saveTagInput" + index,
                              refInFor: true,
                              staticClass: "input-new-tag",
                              staticStyle: { width: "100px" },
                              attrs: { size: "small" },
                              on: {
                                blur: function($event) {
                                  return _vm.handleInputConfirm(index)
                                }
                              },
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleInputConfirm(index)
                                }
                              },
                              model: {
                                value: _vm.inputValue,
                                callback: function($$v) {
                                  _vm.inputValue = $$v
                                },
                                expression: "inputValue"
                              }
                            })
                          : _c(
                              "el-button",
                              {
                                staticClass: "button-new-tag",
                                attrs: { size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.showInput(index)
                                  }
                                }
                              },
                              [_vm._v("添加\n        ")]
                            )
                      ],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        "max-width": "100%",
                        "margin-top": "20px"
                      },
                      attrs: {
                        size: "mini",
                        data: _vm.form.attrList,
                        border: "",
                        "header-cell-class-name": "table_header_cell"
                      }
                    },
                    [
                      _vm._l(_vm.tabTh, function(item, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            width: "100",
                            align: "center",
                            prop: item.value,
                            label: item.label
                          }
                        })
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { "min-width": "400", label: "销售价格" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticClass: "coupon_price",
                                      staticStyle: { width: "100%" },
                                      attrs: { size: "small" },
                                      model: {
                                        value: scope.row.salestypes,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "salestypes", $$v)
                                        },
                                        expression: "scope.row.salestypes"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 3 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: { "label-width": "0" }
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 1 } },
                                                    [_vm._v("金额")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: { "label-width": "0" }
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 2 } },
                                                    [_vm._v("积分")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: { "label-width": "0" }
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 3 } },
                                                    [_vm._v("金额+积分")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    "label-width": "0",
                                                    prop:
                                                      "attrList." +
                                                      scope.$index +
                                                      ".price",
                                                    rules: [
                                                      {
                                                        required:
                                                          scope.row
                                                            .salestypes === 1,
                                                        message: "请输入价格"
                                                      }
                                                    ]
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          scope.row
                                                            .salestypes != 1,
                                                        size: "mini"
                                                      },
                                                      model: {
                                                        value: scope.row.price,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "price",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.price"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("元")]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    "label-width": "0",
                                                    prop:
                                                      "attrList." +
                                                      scope.$index +
                                                      ".score",
                                                    rules: [
                                                      {
                                                        required:
                                                          scope.row
                                                            .salestypes === 2,
                                                        message: "请输入价格"
                                                      }
                                                    ]
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          scope.row
                                                            .salestypes != 2,
                                                        size: "mini"
                                                      },
                                                      model: {
                                                        value: scope.row.score,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "score",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.score"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("积分")]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "flex" },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        "label-width": "0",
                                                        prop:
                                                          "attrList." +
                                                          scope.$index +
                                                          ".price",
                                                        rules: [
                                                          {
                                                            required:
                                                              scope.row
                                                                .salestypes ===
                                                              3,
                                                            message:
                                                              "请输入价格"
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-input",
                                                        {
                                                          staticStyle: {
                                                            "max-width": "150px"
                                                          },
                                                          attrs: {
                                                            disabled:
                                                              scope.row
                                                                .salestypes !=
                                                              3,
                                                            size: "mini"
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.price,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "price",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.price"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "append" },
                                                            [_vm._v("元")]
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        "label-width": "0"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "form_remark",
                                                          staticStyle: {
                                                            margin: "0 10px"
                                                          }
                                                        },
                                                        [_vm._v("+")]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        "label-width": "0",
                                                        prop:
                                                          "attrList." +
                                                          scope.$index +
                                                          ".score",
                                                        rules: [
                                                          {
                                                            required:
                                                              scope.row
                                                                .salestypes ===
                                                              3,
                                                            message:
                                                              "请输入价格"
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-input",
                                                        {
                                                          staticStyle: {
                                                            "max-width": "150px"
                                                          },
                                                          attrs: {
                                                            disabled:
                                                              scope.row
                                                                .salestypes !=
                                                              3,
                                                            size: "mini"
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.score,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "score",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.score"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "append" },
                                                            [_vm._v("积分")]
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8, offset: 1 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "积分可抵扣：",
                                                    "label-width": "98px"
                                                  }
                                                },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#13ce66",
                                                      "inactive-color":
                                                        "#ff4949",
                                                      "active-value":
                                                        scope.row.price,
                                                      "inactive-value": 0
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .deductiblePrice,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "deductiblePrice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.deductiblePrice"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "列表显示：",
                                                    prop: "listShow",
                                                    "label-width": "98px"
                                                  }
                                                },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#13ce66",
                                                      "inactive-color":
                                                        "#ff4949",
                                                      "active-value": 1,
                                                      "inactive-value": 0
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.setListShow(
                                                          $event,
                                                          scope.$index
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: scope.row.listShow,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "listShow",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.listShow"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          172465645
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "100", align: "center", label: "库存" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "attrList." + scope.$index + ".stock",
                                        required: "",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入库存"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { size: "mini" },
                                        model: {
                                          value: scope.row.stock,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "stock", $$v)
                                          },
                                          expression: "scope.row.stock"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1518072630
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "120", align: "center", label: "图片" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "attrList." + scope.$index + ".image",
                                        required: "",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请上传商品图"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.setIndex(scope.$index)
                                            }
                                          }
                                        },
                                        [
                                          _c("add-load", {
                                            staticClass: "img_list",
                                            attrs: {
                                              width: 100,
                                              height: 100,
                                              cover: scope.row.image
                                            },
                                            on: { setCover: _vm.setCover1 }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          228389413
                        )
                      })
                    ],
                    2
                  )
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "送货方式", prop: "deliveryType" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.form.productSource === 6 },
                  model: {
                    value: _vm.form.deliveryType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "deliveryType", $$v)
                    },
                    expression: "form.deliveryType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("到店自提")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("邮寄到家")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("到店自提-邮寄到家")
                  ]),
                  _vm._v(" "),
                  _vm.form.productSource == 2 || _vm.form.productSource == 6
                    ? _c("el-radio", { attrs: { label: 3 } }, [_vm._v("无")])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.deliveryType === 1 ||
          _vm.form.deliveryType == 2 ||
          _vm.form.deliveryType == 3
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.form.deliveryType === 3 ? "商品库存" : "邮寄库存",
                    prop: "stock"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "input_width",
                    model: {
                      value: _vm.form.stock,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "stock", $$v)
                      },
                      expression: "form.stock"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "每人限购", prop: "limitNumber" } },
            [
              _c("el-input", {
                staticClass: "input_width",
                model: {
                  value: _vm.form.limitNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "limitNumber", $$v)
                  },
                  expression: "form.limitNumber"
                }
              }),
              _vm._v(" "),
              _c("p", [_vm._v("一个用户限购的数量（不限购数量请填0）")])
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.deliveryType === 0 || _vm.form.deliveryType === 2
            ? _c(
                "el-form-item",
                { attrs: { label: "自提门店", prop: "storeCodes" } },
                [
                  _c(
                    "el-select",
                    {
                      ref: "storeSelect",
                      staticStyle: { width: "100%", "max-width": "500px" },
                      attrs: {
                        multiple: _vm.form.useSpec != 1,
                        filterable: "",
                        placeholder: "请选择自提门店"
                      },
                      on: { change: _vm.changeStore },
                      model: {
                        value: _vm.form.storeCodes,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "storeCodes", $$v)
                        },
                        expression: "form.storeCodes"
                      }
                    },
                    _vm._l(_vm.storeList, function(item) {
                      return _c("el-option", {
                        key: item.storeId,
                        attrs: {
                          label: item.shortName + "（" + item.storeCode + "）",
                          value: item.storeCode
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.deliveryType === 0 || _vm.form.deliveryType === 2
            ? _c(
                "el-form-item",
                { attrs: { label: "门店库存管理" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { "max-width": "600px" },
                      attrs: {
                        size: "mini",
                        data: _vm.form.productStores,
                        border: "",
                        "max-height": "500",
                        "header-cell-class-name": "table_header_cell"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { width: "100", align: "center", label: "排序" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.$index + 1) +
                                      "\n          "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          896436857
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "30",
                          align: "center",
                          prop: "storeName",
                          label: "门店名称"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "20",
                          align: "center",
                          label: "库存"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "productStores." +
                                          scope.$index +
                                          ".storeStock",
                                        required: "",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入库存"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { size: "mini" },
                                        model: {
                                          value: scope.row.storeStock,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "storeStock",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.storeStock"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1179961824
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.deliveryType === 1 || _vm.form.deliveryType === 2
            ? _c(
                "el-form-item",
                { attrs: { label: "运费模板", prop: "freightId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%", "max-width": "300px" },
                      attrs: { placeholder: "请选择运费模板" },
                      model: {
                        value: _vm.form.freightId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "freightId", $$v)
                        },
                        expression: "form.freightId"
                      }
                    },
                    _vm._l(_vm.freightList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.freightName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "供应商/货源地", prop: "productSourceId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%", "max-width": "300px" },
                  attrs: { placeholder: "请选择供应商/货源地" },
                  model: {
                    value: _vm.form.productSourceId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "productSourceId", $$v)
                    },
                    expression: "form.productSourceId"
                  }
                },
                _vm._l(_vm.sourceList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.productSourceName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品详情", prop: "productDetail" } },
                    [
                      _c("Tinymce", {
                        key: _vm.form.id,
                        attrs: { width: 400, height: 300 },
                        model: {
                          value: _vm.form.productDetail,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "productDetail", $$v)
                          },
                          expression: "form.productDetail"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "兑换说明", prop: "changeInstr" } },
                    [
                      _c("Tinymce", {
                        key: _vm.form.id,
                        attrs: { width: 400, height: 300 },
                        model: {
                          value: _vm.form.changeInstr,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "changeInstr", $$v)
                          },
                          expression: "form.changeInstr"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "分享标题" } },
            [
              _c("el-input", {
                staticClass: "input_width",
                model: {
                  value: _vm.form.shareTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "shareTitle", $$v)
                  },
                  expression: "form.shareTitle"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "分享图片" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: { width: 260, height: 208, cover: _vm.form.shareImage },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "shareImage")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("图片尺寸：520px * 416px")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "none" } }, [
            _vm._v(_vm._s(_vm.productList))
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "max-width": "200px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }