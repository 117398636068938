"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _legouMall = require("@/api/legouMall.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isUpdate: false,
      treeProps: {
        children: 'children',
        label: 'gcName',
        disabled: function disabled(node) {
          return node.gcLevel > 2;
        },
        isLeaf: function isLeaf(node) {
          return node.gcLevel > 1;
        }
      },
      specList: [],
      formType: null,
      formData: [{
        slot: 'gcParentId'
      }, {
        label: '分类名称：',
        type: 'input',
        placeholder: '请输入分类名称',
        value: 'gcName',
        max: 30
      }, {
        label: '排序：',
        type: 'inputNumber',
        placeholder: '请输入排序',
        value: 'gcSort',
        max: 255,
        remark: "数字越大越靠前,最大255"
      }, {
        slot: 'gcGoodsSpec'
      }, {
        label: '图标：',
        type: 'img',
        placeholder: '请上传图标100*100',
        value: 'gcIcon'
      }],
      ruleForm: {
        gcSort: undefined,
        gcParentId: 0,
        gcGoodsSpec: [],
        gcName: null,
        gcIcon: null
      },
      rules: {
        gcGoodsSpec: [{
          required: true,
          message: '请选择规格'
        }],
        gcName: [{
          required: true,
          message: '请输入分类名称'
        }],
        gcSort: [{
          required: true,
          message: '请输入排序'
        }],
        gcIcon: [{
          required: true,
          message: '请上传图标'
        }]
      },
      tableData: []
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/mall/goods/attribute/spec').then(function (res) {
      _this.specList = res.data;
    });
    this.init();
  },
  watch: {
    isUpdate: function isUpdate(newValue, oldValue) {
      if (!newValue) {
        this.ruleForm = {
          gcSort: undefined,
          gcParentId: 0,
          gcGoodsSpec: [],
          gcName: null,
          gcIcon: null
        };
      }
    }
  },
  methods: {
    handleClick: function handleClick(data, checked) {
      if (checked) {
        this.ruleForm.gcParentId = data.id;
        this.$refs.treeForm.setCheckedKeys([data.id]);
      } else if (this.ruleForm.gcParentId == data.id) {
        this.ruleForm.gcParentId = 0;
      }
    },
    changeSpec: function changeSpec(e) {
      if (e.length > 3) {
        this.ruleForm.gcGoodsSpec = e.filter(function (item) {
          return item != e[e.length - 1];
        });
        this.$message.error('最多可选择三个规格');
      }
    },
    goAddItem: function goAddItem(row) {
      this.ruleForm.gcParentId = row.id;
      this.isUpdate = true;
    },
    goUppdateItem: function goUppdateItem(row, type) {
      this.formType = type;
      var upData = (0, _objectSpread2.default)({}, row);
      upData.gcGoodsSpec = row.gcGoodsSpec.split(' ');
      this.ruleForm = upData;
      this.isUpdate = true;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var apiUrl = this.ruleForm.id && this.formType != 'copy' ? '/api/mall/goods/category/update' : '/api/mall/goods/category/create';
      var requestData = (0, _objectSpread2.default)({}, this.ruleForm);
      requestData.gcGoodsSpec = this.ruleForm.gcGoodsSpec.join(' ');
      this.apiPost(apiUrl, requestData).then(function (res) {
        _this2.isUpdate = false;

        _this2.init();

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    },
    init: function init() {
      var _this3 = this;

      (0, _legouMall.getClassList)({}).then(function (res) {
        _this3.tableData = res.data;
      });
    },
    addDepartment: function addDepartment() {
      // 新增
      this.$router.push('/system/menu/menuDea');
    },
    setBtn: function setBtn(menuId) {
      // 操作按钮执行函数
      this.$router.push('/system/menu/menuDea?menuId=' + menuId);
    }
  }
};
exports.default = _default;