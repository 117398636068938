var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            size: "mini"
          }
        },
        [
          _c(
            "el-form-item",
            {
              ref: "ruleName",
              attrs: { label: "返券规则名称：", prop: "ruleName" }
            },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                model: {
                  value: _vm.ruleForm.ruleName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "ruleName", $$v)
                  },
                  expression: "ruleForm.ruleName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "rangeId",
              attrs: { label: "返券活动范围：", prop: "rangeId" }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "maxWidth",
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.ruleForm.rangeId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "rangeId", $$v)
                    },
                    expression: "ruleForm.rangeId"
                  }
                },
                _vm._l(_vm.rangeList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.rangeName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "ruleChannels",
              attrs: { label: "适用渠道：", prop: "ruleChannels" }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "maxWidth",
                  attrs: {
                    filterable: "",
                    placeholder: "请选择适用渠道",
                    multiple: ""
                  },
                  model: {
                    value: _vm.ruleForm.ruleChannels,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "ruleChannels", $$v)
                    },
                    expression: "ruleForm.ruleChannels"
                  }
                },
                _vm._l(_vm.channeList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "scorePrice",
              attrs: { label: "返券活动时间：", prop: "scorePrice" }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.ruleForm.scorePrice,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "scorePrice", $$v)
                  },
                  expression: "ruleForm.scorePrice"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "ruleType",
              attrs: { label: "返券方式：", prop: "ruleType" }
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.typeChange },
                  model: {
                    value: _vm.ruleForm.ruleType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "ruleType", $$v)
                    },
                    expression: "ruleForm.ruleType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("门槛返")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("阶梯返")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.ruleForm.ruleItems, function(item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    refInFor: true,
                    attrs: {
                      rules: _vm.rulesItem,
                      model: item,
                      "label-width": "160px",
                      size: "mini"
                    }
                  },
                  [
                    _c("el-divider"),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        ref: "ruleStatus" + index,
                        refInFor: true,
                        attrs: { label: "规则状态：", prop: "ruleStatus" }
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: item.ruleStatus,
                              callback: function($$v) {
                                _vm.$set(item, "ruleStatus", $$v)
                              },
                              expression: "item.ruleStatus"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("启用")
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("禁用")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !item.id && _vm.ruleForm.ruleItems.length > 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteRule(index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        ref: "thresholdAmount" + index,
                        refInFor: true,
                        attrs: { label: "返券门槛：", prop: "thresholdAmount" }
                      },
                      [
                        _c("el-input", {
                          staticClass: "maxWidth",
                          model: {
                            value: item.thresholdAmount,
                            callback: function($$v) {
                              _vm.$set(item, "thresholdAmount", $$v)
                            },
                            expression: "item.thresholdAmount"
                          }
                        }),
                        _vm._v("\n          元\n        ")
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        ref: "limitPeople" + index,
                        refInFor: true,
                        attrs: { label: "会员返券上限：", prop: "limitPeople" }
                      },
                      [
                        _c("el-input", {
                          staticClass: "maxWidth",
                          staticStyle: { width: "100px" },
                          attrs: { type: "number" },
                          model: {
                            value: item.limitPeople,
                            callback: function($$v) {
                              _vm.$set(item, "limitPeople", $$v)
                            },
                            expression: "item.limitPeople"
                          }
                        }),
                        _vm._v("\n          次\n          "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "40px",
                              color: "#999999",
                              "font-size": "14px",
                              display: "inline-block"
                            }
                          },
                          [_vm._v('限制单个会员总返券次数；填"0"表示无上限。')]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        ref: "limitType" + index,
                        refInFor: true,
                        attrs: { label: "返券上限：", prop: "limitType" }
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticStyle: { display: "inline-block" },
                            model: {
                              value: item.limitType,
                              callback: function($$v) {
                                _vm.$set(item, "limitType", $$v)
                              },
                              expression: "item.limitType"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("返券总人数")
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("返券总次数")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            ref: "limitNumber" + index,
                            refInFor: true,
                            staticStyle: { display: "inline-block" },
                            attrs: { prop: "limitNumber", "label-width": "0" }
                          },
                          [
                            _c("el-input", {
                              staticClass: "maxWidth",
                              staticStyle: { width: "100px" },
                              attrs: { type: "number" },
                              model: {
                                value: item.limitNumber,
                                callback: function($$v) {
                                  _vm.$set(item, "limitNumber", $$v)
                                },
                                expression: "item.limitNumber"
                              }
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(item.limitType == 1 ? "人" : "次") +
                                "\n          "
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "#999999",
                              "font-size": "14px"
                            }
                          },
                          [
                            _vm._v(
                              '限制返券总会员数量和返券总次数；填"0"表示无上限。'
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        ref: "ruleItemCycle" + index,
                        refInFor: true,
                        attrs: { label: "返券周期：", prop: "ruleItemCycle" }
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticStyle: { display: "inline-block" },
                            model: {
                              value: item.ruleItemCycle,
                              callback: function($$v) {
                                _vm.$set(item, "ruleItemCycle", $$v)
                              },
                              expression: "item.ruleItemCycle"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("每日")
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("活动期")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        item.ruleItemCycle == 2
                          ? _c(
                              "el-form-item",
                              {
                                ref: "cycleTime" + index,
                                refInFor: true,
                                staticStyle: { display: "inline-block" },
                                attrs: { prop: "cycleTime" }
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "datetimerange",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期"
                                  },
                                  model: {
                                    value: item.cycleTime,
                                    callback: function($$v) {
                                      _vm.$set(item, "cycleTime", $$v)
                                    },
                                    expression: "item.cycleTime"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#999999",
                              "font-size": "14px",
                              display: "inline-block"
                            }
                          },
                          [
                            _vm._v(
                              '选择"每日"则表示返券时间内每天重新计算会员返券上限；选择"活动期"表示整个返券时间内计算会员返券上限。'
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        ref: "couponInfos" + index,
                        refInFor: true,
                        attrs: { label: "投放优惠券：", prop: "couponInfos" }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.shortNameClick(index)
                                      }
                                    }
                                  },
                                  [_vm._v(" 选择")]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-table",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: {
                                  "max-height": "500px",
                                  border: "",
                                  size: "mini",
                                  data: item.couponInfos
                                }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "序号",
                                    "min-width": "30"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(scope.$index + 1) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "name",
                                    label: "优惠券名称",
                                    align: "center",
                                    "min-width": "100"
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "uuid",
                                    label: "编码",
                                    align: "center",
                                    "min-width": "100"
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "couponType",
                                    label: "类型",
                                    align: "center",
                                    "min-width": "50"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            scope.row.couponType == 1
                                              ? _c("span", [_vm._v("代金券")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.couponType == 2
                                              ? _c("span", [_vm._v("折扣券")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.couponType == 3
                                              ? _c("span", [_vm._v("礼品券")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.couponType == 4
                                              ? _c("span", [_vm._v("体验券")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.couponType == 5
                                              ? _c("span", [_vm._v("外联券")])
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "面额/折扣",
                                    align: "center",
                                    "min-width": "50"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            scope.row.couponType == 2
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.discount / 10
                                                      ) +
                                                      "折"
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.denomination
                                                    ) + "元"
                                                  )
                                                ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "validTimeType",
                                    label: "有效期",
                                    align: "center",
                                    "min-width": "150"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            scope.row.validTime
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(scope.row.validTime)
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.validTimeType == 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.validBeginTime
                                                      ) +
                                                      " 至 " +
                                                      _vm._s(
                                                        scope.row.validEndTime
                                                      )
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.validTimeType == 2
                                              ? _c("span", [
                                                  _vm._v(
                                                    "领取后 " +
                                                      _vm._s(
                                                        scope.row.validDay
                                                      ) +
                                                      "天内有效"
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    prop: "sendNumber",
                                    "min-width": "80",
                                    label: "返券数量"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input-number", {
                                              attrs: {
                                                size: "mini",
                                                min: 1,
                                                max:
                                                  scope.row.limitNumber == 0
                                                    ? 100000
                                                    : scope.row.limitNumber
                                              },
                                              model: {
                                                value: scope.row.sendNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "sendNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.sendNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    prop: "attrName",
                                    "min-width": "30",
                                    label: "操作"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "table_td_btn",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteCoupon(
                                                      index,
                                                      scope.$index
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("删除")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  color: "#999999",
                                  "font-size": "14px"
                                }
                              },
                              [
                                _vm._v(
                                  '"单次返券数量"指单次触发返券，此优惠券的发放数量。'
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "#999999",
                              "font-size": "14px"
                            }
                          },
                          [_vm._v("数量：" + _vm._s(_vm.storeArr.length))]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.ruleForm.ruleType == 1
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "160px" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: { click: _vm.goAdd }
                },
                [_vm._v("新增返券门槛")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "ruleRemark",
              attrs: { label: "备注：", prop: "ruleRemark" }
            },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 },
                  placeholder: "百货（包含自营）九月积分返券活动"
                },
                model: {
                  value: _vm.ruleForm.ruleRemark,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "ruleRemark", $$v)
                  },
                  expression: "ruleForm.ruleRemark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "p",
                { staticClass: "form_ramrk", staticStyle: { color: "red" } },
                [_vm._v("编辑规则会重置返券上限制")]
              ),
              _vm._v(" "),
              _c(
                "el-popconfirm",
                {
                  attrs: {
                    title:
                      _vm.id > 0
                        ? "编辑规则会重置返券数量上限，确认编辑？"
                        : "确认新增返券规则？"
                  },
                  on: {
                    confirm: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "reference",
                        type: "primary",
                        size: "mini"
                      },
                      slot: "reference"
                    },
                    [_vm._v(_vm._s(_vm.id > 0 ? "编辑规则" : "立即创建"))]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.shortNameShow,
              expression: "shortNameShow"
            }
          ],
          staticClass: "pop_shop"
        },
        [
          _c(
            "div",
            { staticClass: "pop_box" },
            [
              _c("i", {
                staticClass: "el-icon-circle-close",
                on: {
                  click: function($event) {
                    _vm.shortNameShow = false
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { padding: "10px 0" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: { size: "mini", placeholder: "券编码" },
                        model: {
                          value: _vm.couponData.uuid,
                          callback: function($$v) {
                            _vm.$set(_vm.couponData, "uuid", $$v)
                          },
                          expression: "couponData.uuid"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 4, offset: 1 } },
                    [
                      _c("el-input", {
                        attrs: { size: "mini", placeholder: "券名称" },
                        model: {
                          value: _vm.couponData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.couponData, "name", $$v)
                          },
                          expression: "couponData.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 4, offset: 1 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "search_input",
                          attrs: { size: "mini", placeholder: "优惠券类型" },
                          model: {
                            value: _vm.couponData.couponType,
                            callback: function($$v) {
                              _vm.$set(_vm.couponData, "couponType", $$v)
                            },
                            expression: "couponData.couponType"
                          }
                        },
                        _vm._l(_vm.options, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 4, offset: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.getStore("search")
                            }
                          }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "shortTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "450",
                    size: "mini",
                    "row-key": _vm.getRowKeys,
                    data: _vm.storeList,
                    "tooltip-effect": "dark"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "reserve-selection": true,
                      type: "selection",
                      width: "50"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "优惠券名称",
                      align: "center",
                      "min-width": "100"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "uuid",
                      label: "编码",
                      align: "center",
                      "min-width": "100"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "couponType",
                      label: "类型",
                      align: "center",
                      "min-width": "50"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.couponType == 1
                              ? _c("span", [_vm._v("代金券")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.couponType == 2
                              ? _c("span", [_vm._v("折扣券")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.couponType == 3
                              ? _c("span", [_vm._v("礼品券")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.couponType == 4
                              ? _c("span", [_vm._v("体验券")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.couponType == 5
                              ? _c("span", [_vm._v("外联券")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "面额/折扣",
                      align: "center",
                      "min-width": "50"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.couponType == 2
                              ? _c("span", [
                                  _vm._v(
                                    " " + _vm._s(scope.row.discount / 10) + "折"
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.denomination) + "元")
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "validTimeType",
                      label: "有效期",
                      align: "center",
                      "min-width": "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.validTimeType == 1
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.validBeginTime) +
                                      " 至 " +
                                      _vm._s(scope.row.validEndTime)
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.validTimeType == 2
                              ? _c("span", [
                                  _vm._v(
                                    "领取后 " +
                                      _vm._s(_vm.getValidDay(scope.row)) +
                                      "天内有效"
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0"
                  }
                ],
                staticStyle: { padding: "0 20px" },
                attrs: {
                  total: _vm.total,
                  page: _vm.couponData.pageNo,
                  limit: _vm.couponData.pageSize
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.couponData, "pageNo", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.couponData, "pageSize", $event)
                  },
                  pagination: _vm.getStore
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        size: "mini"
                      },
                      on: { click: _vm.clearShort }
                    },
                    [_vm._v("清空")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.affirmShort }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }