"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vip = _interopRequireDefault(require("@/common/vip.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      discountType: _vip.default.discountType,
      columns: [{
        label: '门店名称',
        prop: 'consumeStoreName',
        width: 80
      }, {
        label: '门店编码',
        prop: 'consumeStore',
        width: 50
      }, {
        label: '订单号',
        prop: 'documentNo',
        width: 100
      }, {
        label: '消费金额',
        prop: 'consumeAmount',
        width: 50
      }, {
        label: '消费积分',
        prop: 'consumeStore',
        width: 50
      }, {
        slot: 'discountType',
        label: '优惠类型',
        prop: 'discountType',
        width: 50
      }, {
        label: '优惠金额',
        prop: 'discountAmount',
        width: 50
      }, {
        label: '优惠积分',
        prop: 'discountScore',
        width: 50
      }, {
        label: '优惠积分折合金额',
        prop: 'discountScoreAmount',
        width: 50
      }, {
        label: '优惠时间',
        prop: 'discountTime',
        width: 120
      }],
      searchData: [{
        type: 'input',
        placeholder: '相关单号',
        value: 'documentNo'
      },
      /* {
        type: 'radio',
        placeholder: '优惠类型',
        value: 'discountType',
        select:[
          {
            value:1,
            label:"待审核"
          },
          {
            value:2,
            label:"审核不通过"
          },
          {
            value:3,
            label:"审核通过"
          }
        ]
      }, */
      {
        type: 'date',
        placeholder: '开始时间',
        value: 'discountTimeStart',
        dateType: 'date',
        valueFormat: 'yyyy-MM-dd'
      }, {
        type: 'date',
        placeholder: '结束时间',
        value: 'discountTimeEnd',
        defaultTime: '23:59:59',
        dateType: 'date',
        valueFormat: 'yyyy-MM-dd'
      }],
      SRCPREFIX: this.$store.state.user.imgSrc
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.$route.query.id) {
      this.$refs.wTable.setRequestValue('discountId', this.$route.query.id);
      this.$refs.wTable.getList();
    }
  },
  methods: {}
};
exports.default = _default;