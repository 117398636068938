"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

var _auth = require("@/utils/auth");

var _report = require("@/api/report.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      userInfo: {},
      isGoCard: false,
      currentPrice: null,
      changType: 2,
      popType: 'storeCard',
      couponShow: false,
      action: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
      rules: {
        voucherPic: [{
          required: true,
          message: '请上传凭证'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        phone: [{
          required: true,
          message: '请输入手机号码'
        }],
        cardNo: [{
          required: true,
          message: '请选择购物卡'
        }],
        money: [{
          required: true,
          message: '请输入补发金额'
        }],
        type: [{
          required: true,
          message: '请选择补发类型'
        }]
      },
      ruleForm: {
        type: null,
        remark: null,
        cardNo: null,
        phone: null,
        voucherPic: null,
        money: null
      }
    };
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        'Token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {},
  methods: {
    changeMobile: function changeMobile() {
      this.isGoCard = false;
    },
    getUserInfo: function getUserInfo() {
      var _this = this;

      var data = {
        mobile: this.ruleForm.phone,
        type: 1
      };
      this.isGoCard = false;
      (0, _report.handleMidUser)(data).then(function (res) {
        console.log(111111111);
        _this.isGoCard = true;
        _this.userInfo = res.data.user;
        _this.userInfo.status = res.data.status;
      });
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    setCoupon: function setCoupon(e) {
      if (this.popType === 'card') {
        this.ruleForm.cardNo = e.uuid;
      } else {
        this.ruleForm.cardNo = e.cardNo;
      }

      this.couponShow = false;
    },
    getAward: function getAward(e) {
      this.popType = e;
      this.$refs.coupon1.getList(this.popType, this.ruleForm.cardNo);
      this.couponShow = true;
    },
    clearForm: function clearForm(ruleForm) {
      this.cardData = {};
      this.$refs[ruleForm].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.$confirm("\u786E\u8BA4\u8865\u53D1\u8D2D\u7269\u5361", '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            (0, _api.goReward)(_this2.ruleForm).then(function (res) {
              _this2.clearForm(formName);

              _this2.$message({
                type: 'success',
                message: '补发成功!'
              });
            });
          }).catch(function () {
            _this2.$message({
              type: 'info',
              message: '已取消补卡'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;