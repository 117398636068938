"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      time: null,
      requestData: {
        mobile: null,
        rightName: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '权益名称',
        prop: 'rightName',
        width: 100
      }, {
        label: '礼品名称',
        prop: 'giftName',
        width: 80
      }, {
        label: '会员手机号',
        prop: 'userMobile',
        width: 80
      }, {
        label: '会员UID',
        prop: 'username',
        width: 80
      }, {
        slot: 'recived',
        label: '是否领取',
        prop: 'recived',
        width: 80
      }, {
        label: '领取时间',
        prop: 'recivedTime',
        width: 60
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    isPop: function isPop(newValue, oldValue) {
      if (!newValue) {
        this.ruleForm.activityId = null;
        this.ruleForm.description = null;
        this.status = null;
      }
    },
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {// this.getList()
  },
  methods: {
    goExamBox: function goExamBox(row, status) {
      var _this = this;

      (0, _api.pushRightsRemoveById)({
        parm: row.id
      }).then(function (res) {
        _this.getList();
      });
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this2.requestData[key]) {
          data[key] = _this2.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getList)(data).then(function (res) {
        list = res.data.records;
        _this2.downloadLoading = false;

        _this2.getHandleDownload(tHeader, filterVal, list, '会员权益推送记录');
      }).catch(function (res) {
        _this2.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getList)(this.requestData).then(function (res) {
        _this3.tableData = res.data.records;
        _this3.requestData.total = Number(res.data.total);
        return;
      });
    }
  }
};
exports.default = _default;