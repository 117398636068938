var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "img1021 " }, [
    _c(
      "div",
      { staticClass: "flex_between" },
      _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
        return _c("img", {
          key: index,
          style: {
            width: _vm.data.moduleAttr.imgWidth / 2 + "px",
            height: _vm.data.moduleAttr.imgHeight / 2 + "px"
          },
          attrs: { src: _vm.imgSrc + item.dataPath }
        })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }