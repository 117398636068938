"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("./index.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      form: {
        remark: '',
        fullName: '',
        shortName: '',
        systemId: '',
        companyId: '' // companyCode:''

      },
      systemList: [],
      rules: {
        /* companyCode:[{
          required: true,
          message: '请填写总部编码',
        }], */
        fullName: [{
          required: true,
          message: '请填写总部名称'
        }],
        shortName: [{
          required: true,
          message: '请填写总部简称'
        }],
        systemId: [{
          required: true,
          message: '请选择ERP系统'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.form.companyId = this.$route.query.companyId;
    console.log(this.form.companyId);

    if (this.form.companyId) {
      (0, _index.getCompanyById)(this.form.companyId).then(function (res) {
        console.log(res);
        _this.form.fullName = res.data.fullName; // this.form.companyCode = res.data.companyCode||''

        _this.form.shortName = res.data.shortName;
        _this.form.systemId = res.data.systemId;
        _this.form.remark = res.data.remark || '';
      });
    }

    (0, _index.listSystem)().then(function (res) {
      _this.systemList = res.data;
    });
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (!_this2.form.companyId) {
            (0, _index.addCompany)(_this2.form).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '新增成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _index.editCompany)(_this2.form).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;