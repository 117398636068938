"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      tableData: [],
      requestData: {
        status: null,
        categoryName: null
      }
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.apiPost('/api/category/label/listUserCategory', this.requestData).then(function (res) {
        var result = [];
        var map = {};
        res.data.forEach(function (item) {
          map[item.id] = item;
        });
        res.data.forEach(function (item) {
          var parent = map[item.paterId];

          if (parent) {
            if (!parent.children) parent.children = [];
            parent.children.push(item);
          } else {
            result.push(item);
          }
        });
        _this.tableData = result;
      });
    }
  }
};
exports.default = _default;