"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _mem = require("@/api/mem.js");

var state = {
  companyList: [],
  storeList: [],
  activeMenu: ''
};
var mutations = {
  SET_COMPANY: function SET_COMPANY(state, data) {
    state.companyList = data;
  },
  SET_STORE: function SET_STORE(state, data) {
    state.storeList = data;
  },
  SET_ACTIVE_MENU: function SET_ACTIVE_MENU(state, data) {
    state.activeMenu = data;
  }
};
var actions = {
  getCompany: function getCompany(_ref, data) {
    var commit = _ref.commit;
    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      commit('SET_COMPANY', res.data);
    });
    (0, _mem.listStore)({
      companyId: ''
    }).then(function (res) {
      commit('SET_STORE', res.data);
    });
  },
  set_active_menu: function set_active_menu(_ref2, data) {
    var commit = _ref2.commit;
    commit('SET_ACTIVE_MENU', data);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;