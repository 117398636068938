"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _getCoupon = require("@/api/getCoupon.js");

var _table = _interopRequireDefault(require("@/components/tableList/table.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tables: _table.default
  },
  data: function data() {
    return {
      title: '领券中心',
      id: '',
      list: [],
      total: 0,
      obj: {
        pageNo: 1,
        pageSize: 10,
        channelType: '',
        couponName: '',
        couponCode: '',
        centerStatus: ''
        /* labelName:'',
          classId:'',
          labelStatus:'' */

      },
      listLabel: [],
      tabTh: [{
        prop: 'centerId',
        label: 'ID',
        width: 70
      }, {
        prop: 'couponName',
        label: '方案名称（优惠券名称）',
        width: 150
      }, {
        prop: 'couponCode',
        label: '优惠券编码',
        width: 110
      }, {
        prop: 'couponType',
        label: '类型',
        width: 80
      }, {
        prop: 'couponTime',
        label: '券有效期',
        width: 150
      }, {
        prop: 'onlineTime',
        label: '领券中心上线时间',
        width: 150
      }, {
        prop: 'putNumber',
        label: '投放数',
        width: 80
      },
      /* {
          prop:'labelStatusText',
          label:'领购率',
          width:70
        }, */
      {
        prop: 'centerStatus',
        label: '状态',
        width: 70
      }]
    };
  },
  created: function created() {
    this.id = Number(this.$route.query.id);

    switch (this.id) {
      case 1:
        this.title = '领券中心-乐品到家小程序';
        break;

      case 2:
        this.title = '领券中心-星力乐购小程序';
        break;

      case 3:
        this.title = '领券中心-MIKA积分商城小程序';
        break;

      case 4:
        this.title = '领券中心-汇金星力城小程序';
        break;

      case 5:
        this.title = '领券中心-乐品鲜活APP';
        break;

      default:
        break;
    }

    this.init();
  },
  methods: {
    setTab: function setTab(row, index) {
      this.$router.push('/getCoupon/deatil?id=' + row.centerId + '&channelType=' + this.id);
    },
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
      }

      if (type == 'remove') {
        this.obj.pageNo = 1;
        this.obj.labelName = '';
        this.obj.classId = '';
        this.obj.labelStatus = '';
        this.obj.couponName = '';
        this.obj.couponCode = '';
        this.obj.centerStatus = '';
      }

      if (type) {
        this.obj.pageNo = type.page || 1;
        this.obj.pageSize = type.limit || 10;
      }

      this.obj.channelType = this.id;
      console.log(this.obj);
      (0, _getCoupon.listRecenterPage)(this.obj).then(function (res) {
        _this.total = res.data.totalNum;
        _this.list = res.data.items;

        _this.list.forEach(function (item) {
          item.centerStatus = item.centerStatus == 1 ? '停用' : '启用';

          if (item.onlineType == 1) {
            item.onlineTime = item.onlineStartTime + '-' + item.onlineEndTime;
          } else {
            item.onlineTime = '立即上线';
          }

          if (item.validTimeType == 1) {
            item.couponTime = item.validBeginTime + '-' + item.validEndTime;
          } else {
            item.couponTime = '领取后' + item.validDay + '天内有效';
          }

          switch (item.couponType) {
            case 1:
              item.couponType = '代金券';
              break;

            case 2:
              item.couponType = '折扣券';
              break;

            case 3:
              item.couponType = '礼品券';
              break;

            case 4:
              item.couponType = '体验券';
              break;

            case 5:
              item.couponType = '外部优惠券';
              break;

            default:
              break;
          }
        });
      });
    },
    goAdd: function goAdd(type) {
      this.$router.push('/getCoupon/add?channelType=' + this.id);
    }
  }
};
exports.default = _default;