var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          attrs: {
            "form-data": _vm.formData,
            "rule-form": _vm.ruleForm,
            rules: _vm.rules
          },
          on: { submitForm: _vm.submitForm }
        },
        [
          _c(
            "div",
            {
              attrs: { slot: "templateColumnList" },
              slot: "templateColumnList"
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "templateColumnList",
                  attrs: { label: "阶段配置：", prop: "templateColumnList" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.goAddGrade }
                        },
                        [_vm._v("添加字段")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "form_remark",
                          staticStyle: {
                            "margin-left": "10px",
                            color: "#F56C6C"
                          }
                        },
                        [
                          _vm._v(
                            "\n            阶段配置必须依次递增，最高签到天数不可超过31天\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        size: "mini",
                        data: _vm.ruleForm.templateColumnList,
                        "max-height": "800px",
                        border: "",
                        "header-cell-class-name": "table_header_cell"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "排序",
                          type: "index",
                          align: "center",
                          width: "100"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "字段名称",
                          align: "center",
                          "min-width": "100"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "templateColumnList." +
                                      scope.$index +
                                      ".column1",
                                    attrs: {
                                      prop:
                                        "templateColumnList." +
                                        scope.$index +
                                        ".column1",
                                      rules: _vm.rules.column1
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "字段名称" },
                                      model: {
                                        value: scope.row.column1,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "column1",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "scope.row.column1"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "字段描述",
                          align: "center",
                          "min-width": "100"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "templateColumnList." +
                                      scope.$index +
                                      ".columDesc",
                                    attrs: {
                                      prop:
                                        "templateColumnList." +
                                        scope.$index +
                                        ".columDesc",
                                      rules: _vm.rules.columDesc
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "字段描述" },
                                      model: {
                                        value: scope.row.columDesc,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "columDesc",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "scope.row.columDesc"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "默认值",
                          align: "center",
                          "min-width": "100"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "templateColumnList." +
                                      scope.$index +
                                      ".columDefault",
                                    attrs: { rules: _vm.rules.columDefault }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "默认值" },
                                      model: {
                                        value: scope.row.columDefault,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "columDefault",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "scope.row.columDefault"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          fixed: "right",
                          width: "80"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "确认删除此该字段？" },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.delGrade(
                                          scope.row,
                                          scope.$index
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "danger",
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                          size: "mini"
                                        },
                                        slot: "reference"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  删除\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }