"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      gradeType: {
        8888: '银卡会员',
        8889: '金卡会员',
        8890: '砖卡会员',
        8891: '白砖卡会员',
        8892: '黑卡会员'
      },
      downloadLoading: false,
      time: null,
      requestData: {
        name: null,
        startTime: null,
        endTime: null,
        userMobile: null,
        pageNo: 1,
        pageSize: 10,
        total: 0,
        beSvip: '',
        firstChargeYear: '',
        status: 1
      },
      tableData: [],
      columns: [{
        label: '时间',
        prop: 'createTime',
        width: 120
      }, {
        label: '订单号',
        prop: 'orderNo',
        width: 100
      }, {
        label: '活动名称',
        prop: 'activityName',
        width: 100
      }, {
        label: '权益名称',
        prop: 'rightName',
        width: 100
      }, {
        label: '充值金额',
        prop: 'chargeAmount',
        width: 80
      }, {
        slot: 'equityCard',
        label: '参考返利金额',
        prop: 'equityCard',
        width: 80
      }, {
        label: '用户手机号',
        prop: 'mobile',
        width: 80
      }, {
        label: '用户昵称',
        prop: 'name',
        width: 80
      }, {
        label: '推荐人姓名',
        prop: 'recommendSysUserName',
        width: 80
      }, {
        label: '推荐人手机号',
        prop: 'recommendSysUserMobile',
        width: 80
      }, {
        label: '门店编码',
        prop: 'recommendSysUserStoreCode',
        width: 80
      }, {
        label: '门店名称',
        prop: 'recommendSysUserStoreName',
        width: 80
      }, {
        slot: 'received',
        label: '是否领取',
        prop: 'received'
      }, {
        label: '备注',
        prop: 'remark',
        width: 80
      }, {
        label: '推广奖励金额',
        prop: 'recommendReward',
        width: 100
      }, {
        slot: 'beSvip',
        label: '是否成为了要客',
        prop: 'beSvip',
        width: 100
      }, {
        slot: 'status',
        label: '状态',
        prop: 'payStatus',
        width: 100
      }, {
        slot: 'firstChargeYear',
        label: '是否年首充',
        prop: 'firstChargeYear',
        width: 100
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {
    this.getList();
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this.requestData[key]) {
          data[key] = _this.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.orderPage)(data).then(function (res) {
        // list = res.data.records
        _this.downloadLoading = false;
        res.data.records.forEach(function (item) {
          var date = new Date(item.createTime);

          if (!_this.setMenuPerms('user_mobile_idCard')) {
            if (item.mobile) {
              item.mobile = item.mobile.replace(item.mobile.substring(3, 7), "****");
            }
          }

          item.createTime = _this.setFormatData(date);

          if (item.chargeAmount >= 500000) {
            item.equityCard = 13000;
          } else if (item.chargeAmount >= 100000) {
            item.equityCard = 2500;
          } else if (item.chargeAmount >= 50000) {
            item.equityCard = 1200;
          } else if (item.chargeAmount >= 30000) {
            item.equityCard = 600;
          } else if (item.chargeAmount >= 10000) {
            item.equityCard = 200;
          }
        });

        _this.getHandleDownload(tHeader, filterVal, res.data.records, '充值记录');
      }).catch(function (res) {
        _this.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this2 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.orderPage)(this.requestData).then(function (res) {
        _this2.tableData = res.data.records;
        _this2.requestData.total = res.data.total;
        res.data.records.forEach(function (item) {
          var date = new Date(item.createTime);
          item.createTime = _this2.setFormatData(date);

          if (!_this2.setMenuPerms('user_mobile_idCard')) {
            if (item.mobile) {
              item.mobile = item.mobile.replace(item.mobile.substring(3, 7), "****");
            }
          }
        });
      });
    }
  }
};
exports.default = _default;