"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _member = require("@/api/member.js");

var _report = require("@/api/report.js");

var _user = require("@/api/user.js");

var _index = require("@/api/svip/index.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    var _userInfo;

    return {
      passwordType: 'password',
      password: '',
      type: 1,
      sendBtn: '发送验证码',
      timer: null,
      mobileShow: true,
      sendSuccess: false,
      isSzm: true,
      code: '',
      cardList: [],
      lavant: null,
      tabTh: [{
        prop: 'id',
        tit: '礼品ID',
        width: '1'
      }, {
        prop: 'giftName',
        tit: '礼品名称',
        width: '2'
      }, {
        prop: 'giftTypeText',
        tit: '礼品类型',
        width: '1'
      }, {
        prop: 'storeCode',
        tit: '所属门店',
        width: '1'
      }, {
        prop: 'giftWorth',
        tit: '价值',
        width: '1'
      }, {
        prop: 'scorePrice',
        tit: '积分价格',
        width: '1'
      }, {
        prop: 'discountScore',
        tit: '365积分价格',
        width: '1'
      }, {
        prop: 'stockNumber',
        tit: '库存数',
        width: '1'
      }],
      userInfo: (_userInfo = {
        payStatus365: '',
        realname: '',
        status: '',
        mobile: '',
        score: '',
        sex: '',
        birthday: '',
        idNo: '',
        nation: '',
        gradeName: '',
        growthValue: '',
        customerType: '',
        uid: '',
        storeCardBalance: null,
        extensionStoreName: null,
        savaAmountTotal: null
      }, (0, _defineProperty2.default)(_userInfo, "payStatus365", null), (0, _defineProperty2.default)(_userInfo, "savaAmountInCurrentCycle", null), (0, _defineProperty2.default)(_userInfo, "extensionUserName", null), (0, _defineProperty2.default)(_userInfo, "extensionTime", null), _userInfo),
      username: '',
      giftShow: false,
      giftList: [],
      mobile: '',
      exchangeRemark: '',
      consumeStore: 0,
      tableData: [],
      presentObj: {
        pageNo: 1,
        pageSize: 10,
        exchangeUmobile: ''
      },
      presentTotal: 0,
      giftObj: {
        giftName: '',
        giftType: '',
        stockIden: 1,
        giftStatus: 1,
        pageNo: 1,
        pageSize: 10
      },
      giftTotal: 0,
      gifts: []
    };
  },
  created: function created() {
    var _this = this;

    (0, _member.listType)({}).then(function (res) {
      _this.cardList = res.data;
    });
  },
  methods: {
    showPwd: function showPwd() {
      var _this2 = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    goUserPwd: function goUserPwd() {
      var _this3 = this;

      var data = {
        uid: this.userInfo.uid,
        password: this.password
      };
      this.openLoading();
      (0, _user.checkUserPwd)(data).then(function (res) {
        _this3.openLoading().close();

        _this3.isSzm = false;

        _this3.$message({
          message: '验证成功',
          type: 'success'
        });
      }).catch(function () {
        _this3.openLoading().close();
      });
    },
    goSendSms: function goSendSms() {
      var _this4 = this;

      this.openLoading('验证码发送中...');
      (0, _user.sendSms)(this.userInfo.mobile).then(function (res) {
        _this4.openLoading().close();

        _this4.$message({
          type: 'success',
          message: '发送成功'
        });

        _this4.sendSuccess = true;
        var count = 60;
        var self = _this4;
        _this4.timer = setInterval(function () {
          if (count > 0) {
            count--;
            self.sendBtn = count + '秒之后再次发送';
          } else {
            clearInterval(self.timer);
            self.sendBtn = '发送验证码';
            self.sendSuccess = false;
          }
        }, 1000);

        _this4.openLoading().close();
      }).catch(function () {
        _this4.sendSuccess = false;

        _this4.openLoading().close();
      });
    },
    goCheckSms: function goCheckSms() {
      var _this5 = this;

      this.openLoading();
      (0, _user.checkSms)({
        mobile: this.userInfo.mobile,
        code: this.code
      }).then(function (res) {
        _this5.openLoading().close();

        _this5.isSzm = false;
        clearInterval(_this5.timer);
        _this5.sendBtn = '验证成功';

        _this5.$message({
          message: '验证成功',
          type: 'success'
        });
      }).catch(function () {
        _this5.openLoading().close();
      });
    },
    getUserInfo: function getUserInfo() {
      var _this6 = this;

      var slef = this;
      /*
        获取用户信息
        */

      var data = {
        mobile: this.mobile,
        type: this.type
      };
      slef.presentList();
      this.openLoading();
      this.mobileShow = true;
      (0, _report.handleMidUser)(data).then(function (res) {
        (0, _index.getMember)({
          id: res.data.user.mobile
        }, {
          isErrMsg: true
        }).then(function (res) {
          _this6.lavant = res.data;
        });

        _this6.openLoading().close();

        _this6.mobileShow = false;
        Object.keys(res.data.user).forEach(function (key) {
          Object.keys(slef.userInfo).forEach(function (row) {
            if (key === row) {
              if (key === 'sex') {
                if (res.data.user[key] === 1) {
                  slef.userInfo[row] = '男';
                }

                if (res.data.user[key] === 2) {
                  slef.userInfo[row] = '女';
                }

                if (res.data.user[key] === 0) {
                  slef.userInfo[row] = '未知';
                }
              } else {
                slef.userInfo[row] = res.data.user[key];
              }
            }
          });
        });
        slef.userInfo.status = res.data.status;
      }).catch(function () {
        _this6.openLoading().close();
      });
    },
    presentList: function presentList() {
      var _this7 = this;

      this.presentObj.exchangeUmobile = this.mobile;
      (0, _member.listExchangePage)(this.presentObj).then(function (res) {
        _this7.tableData = res.data.items;
        _this7.presentTotal = res.data.totalNum;
      });
    },
    getGiftList: function getGiftList(type) {
      var _this8 = this;

      if (type === 'search') {
        this.giftObj.pageNo = 1;
        this.giftObj.pageSize = 10;
        this.giftList = [];
      }

      if (this.giftList.length > 0 && type !== 'add') {
        this.giftShow = true;
      } else {
        this.openLoading();
        (0, _member.listGiftPage)(this.giftObj).then(function (res) {
          _this8.openLoading().close();

          _this8.giftTotal = res.data.total;
          res.data.records.forEach(function (item) {
            item.exchangeNumber = 1;

            switch (item.giftType) {
              case 1:
                item.giftTypeText = '生日礼';
                break;

              case 2:
                item.giftTypeText = '升级礼';
                break;

              case 3:
                item.giftTypeText = '积兑卡';
                break;

              case 5:
                item.giftTypeText = '积分礼品';
                break;

              case 6:
                item.giftTypeText = '幸运礼';
                break;

              case 7:
                item.giftTypeText = '2020年积分兑换';
                break;

              case 8:
                item.giftTypeText = '365付费会员自提开卡礼';
                break;

              case 9:
                item.giftTypeText = '2021年积分兑换';
                break;

              case 10:
                item.giftTypeText = '2022年积分兑换';
                break;

              case 4:
                item.giftTypeText = '其他';
                break;

              default:
                break;
            }
          });
          _this8.giftList = res.data.records;
          _this8.giftShow = true;
        }).catch(function () {
          _this8.openLoading().close();
        });
      }
    },
    clearShort: function clearShort() {
      this.$refs.giftTable.clearSelection();
    },
    affirmGift: function affirmGift() {
      var _this9 = this;

      var data = this.$refs.giftTable.store.states.selection;
      this.consumeStore = 0;
      data.forEach(function (item) {
        item.exchangeNumber = 1;

        if (_this9.userInfo.payStatus365 === 1) {
          _this9.consumeStore += (item.discountScore || item.scorePrice) * item.exchangeNumber;
        } else {
          _this9.consumeStore += item.scorePrice * item.exchangeNumber;
        }
      });
      this.consumeStore = this.consumeStore.toFixed(2);
      this.gifts = data;
      this.giftShow = false;
    },
    deleteGifts: function deleteGifts(index, row) {
      this.gifts.splice(index, 1);
      this.setStore();
    },
    setStore: function setStore(e) {
      var _this10 = this;

      this.consumeStore = 0;
      this.gifts.forEach(function (item) {
        if (_this10.userInfo.payStatus365 === 1) {
          _this10.consumeStore += (item.discountScore || item.scorePrice) * item.exchangeNumber;
        } else {
          _this10.consumeStore += item.scorePrice * item.exchangeNumber;
        }
      });
      this.consumeStore = this.consumeStore.toFixed(2);
    },
    next: function next() {
      var data = {
        exchangeUmobile: this.mobile,
        gifts: this.gifts,
        exchangeRemark: this.exchangeRemark,
        username: this.username,
        userInfo: {
          realname: this.userInfo.realname,
          mobile: this.userInfo.mobile,
          idNo: this.userInfo.idNo,
          score: this.userInfo.score,
          uid: this.userInfo.uid
        },
        consumeStore: this.consumeStore
      };

      if (this.consumeStore > this.userInfo.score) {
        this.$message({
          showClose: true,
          message: '会员积分不足',
          type: 'warning'
        });
      } else if (this.gifts.length === 0) {
        this.$message({
          showClose: true,
          message: '请选择礼品',
          type: 'warning'
        });
      } else {
        /* this.gifts.forEach(item => {
             if (item.exchangeNumber) {
               data.gifts.push({
                 giftId: item.id,
                 exchangeNumber: item.exchangeNumber
               });
             }
           }); */
        console.log(data);
        this.$router.push('/member/present/affrim?data=' + JSON.stringify(data));
        /* addExchange(data).then(res => {
             console.log(res);
           }); */
      }
    }
  }
};
exports.default = _default;