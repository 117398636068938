"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var couponLabelRouter = {
  path: '/couponLabel',
  component: _layout.default,
  redirect: '/couponLabel/list',
  name: 'couponLabel',
  meta: {
    title: '优惠券标签'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./list.vue"));
      });
    },
    name: 'couponLabelList',
    meta: {
      title: '优惠券标签',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/coupon-category/page',
      addUrl: '/couponLabel/add',
      addTxt: "新增标签"
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./add.vue"));
      });
    },
    name: 'couponLabelAdd',
    meta: {
      title: '新增编辑标签'
    }
  }]
};
var _default = couponLabelRouter;
exports.default = _default;