"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.detailRegistrationLogById = detailRegistrationLogById;
exports.editData = editData;
exports.getData = getData;
exports.getEquityList = getEquityList;
exports.orderPage = orderPage;

var _request = _interopRequireDefault(require("@/utils/request"));

function getEquityList(data) {
  return (0, _request.default)({
    url: '/api/pm/single-right/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addData(data) {
  return (0, _request.default)({
    url: '/api/pm/single-right/saveOrUpdate',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editData(data) {
  return (0, _request.default)({
    url: '/api/activityOffLine/edit',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getData(data) {
  return (0, _request.default)({
    url: '/api/pm/single-right/getById',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function orderPage(data) {
  return (0, _request.default)({
    url: '/api/pay365/receivedLog/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function detailRegistrationLogById(data) {
  return (0, _request.default)({
    url: '/api/activityOffLine/detailRegistrationLogById',
    method: 'get',
    loading: 'loading',
    params: data
  });
}