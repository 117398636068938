"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      infoOption: [{
        title: "卡内余额",
        val: "",
        default: "-"
      }, {
        title: "状态",
        val: "",
        default: "-"
      }, {
        title: "卡种ID",
        val: "",
        default: "-"
      }, {
        title: "卡种名称",
        val: "",
        default: "-"
      }, {
        title: "卡类型",
        val: "",
        default: "-"
      }, {
        title: "消费积分",
        val: "",
        default: "-"
      }, {
        title: "记名类型",
        val: "",
        default: "-"
      }, {
        title: "卡片面额",
        val: "",
        default: "-"
      }, {
        title: "激活后有效期",
        val: "",
        default: "-"
      }, {
        title: "可绑定为电子卡",
        val: "",
        default: "-"
      }, {
        title: "备注",
        val: "",
        default: "-"
      }, {
        title: "创建时间",
        val: "",
        default: "-"
      }, {
        title: "总库存",
        val: "",
        default: "-"
      }],
      params: {
        name: "",
        remark: ""
      },
      rules: {}
    };
  }
};
exports.default = _default;