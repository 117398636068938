var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-header-item" },
        [
          _c("span", [_vm._v("游戏名称：")]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择游戏", size: "small" },
              on: {
                change: function($event) {
                  return _vm.init()
                }
              },
              model: {
                value: _vm.currentGameId,
                callback: function($$v) {
                  _vm.currentGameId = $$v
                },
                expression: "currentGameId"
              }
            },
            _vm._l(_vm.gamesOptions, function(game) {
              return _c("el-option", {
                key: game.id,
                attrs: { value: game.id, label: game.gameName }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page-header-item" }, [
        _c("span", [_vm._v("计算基准时间：")]),
        _vm._v(_vm._s(_vm.currentGame.startTime) + "\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page-header-item" }, [
        _c("span", [_vm._v("计算完成时间：")]),
        _vm._v(_vm._s(_vm.currentGame.endTime) + "\n    ")
      ]),
      _vm._v(" "),
      _vm._m(0)
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "total-card" },
      _vm._l(_vm.dataTypeOptions, function(dataType) {
        return _c(
          "div",
          { key: dataType.name, staticClass: "total-card-item" },
          [
            _c("div", { staticClass: "total-card-item-content" }, [
              _c("div", { staticClass: "total-card-item-lab" }, [
                _vm._v(_vm._s(dataType.label))
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.totalData[dataType.name]))])
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "group" }, [
      _c("div", { staticClass: "group-title" }, [_vm._v("门店分布情况")]),
      _vm._v(" "),
      _c("div", { staticClass: "group-content" }, [
        _c(
          "div",
          { staticClass: "tags" },
          [
            _c("div", { staticClass: "tags-label" }, [_vm._v("数据类型：")]),
            _vm._v(" "),
            _vm._l(_vm.dataTypeOptions, function(dataType) {
              return _c(
                "div",
                {
                  key: dataType.name,
                  staticClass: "tags-item",
                  class: { active: _vm.currentStoreDataType == dataType.name },
                  on: {
                    click: function($event) {
                      _vm.currentStoreDataType = dataType.name
                    }
                  }
                },
                [_vm._v("\n          " + _vm._s(dataType.label) + "\n        ")]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tags" },
          [
            _c("div", { staticClass: "tags-label" }, [_vm._v("时间范围：")]),
            _vm._v(" "),
            _vm._l(_vm.dateOptions, function(dateItem) {
              return _c(
                "div",
                {
                  key: dateItem.value,
                  staticClass: "tags-item",
                  class: {
                    active:
                      _vm.currentStoreDate == dateItem.value &&
                      _vm.currentStoreTimes.length <= 0
                  },
                  on: {
                    click: function($event) {
                      return _vm.currentStoreDateChange(dateItem.value)
                    }
                  }
                },
                [_vm._v("\n          " + _vm._s(dateItem.label) + "\n        ")]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tags-times" },
              [
                _c("el-date-picker", {
                  attrs: {
                    size: "mini",
                    type: "datetimerange",
                    "range-separator": "~",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    clearable: ""
                  },
                  on: { change: _vm.getStoreData },
                  model: {
                    value: _vm.currentStoreTimes,
                    callback: function($$v) {
                      _vm.currentStoreTimes = $$v
                    },
                    expression: "currentStoreTimes"
                  }
                })
              ],
              1
            )
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-progress" },
          [
            _vm._m(1),
            _vm._v(" "),
            _vm._l(_vm.storeData, function(storeItem, storeIndex) {
              return _c(
                "div",
                { key: storeIndex, staticClass: "table-progress-rows" },
                [
                  _c("div", { staticClass: "table-progress-col" }, [
                    _vm._v(_vm._s(storeItem.storeName))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-progress-col",
                      staticStyle: { flex: "1", "max-width": "500px" }
                    },
                    [
                      _vm.currentStoreDataType == "memberPlayPersonNum"
                        ? _c("el-progress", {
                            attrs: {
                              percentage: storeItem.memberPlayPersonNum_bl
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.currentStoreDataType == "totalPlayNum"
                        ? _c("el-progress", {
                            attrs: { percentage: storeItem.totalPlayNum_bl }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.currentStoreDataType == "memberHits"
                        ? _c("el-progress", {
                            attrs: { percentage: storeItem.memberHits_bl }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.currentStoreDataType == "totalHits"
                        ? _c("el-progress", {
                            attrs: { percentage: storeItem.totalHits_bl }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-progress-col" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(storeItem[_vm.currentStoreDataType]) +
                        "\n            "
                    ),
                    _vm.currentStoreDataType == "memberPlayPersonNum"
                      ? _c("span", [_vm._v("\n              人\n            ")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentStoreDataType == "totalPlayNum"
                      ? _c("span", [_vm._v("\n              次\n            ")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentStoreDataType == "memberHits"
                      ? _c("span", [_vm._v("\n              人\n            ")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentStoreDataType == "totalHits"
                      ? _c("span", [
                          _vm._v("\n               次\n            ")
                        ])
                      : _vm._e()
                  ])
                ]
              )
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "group" }, [
      _c("div", { staticClass: "group-title" }, [_vm._v("每日数据详情")]),
      _vm._v(" "),
      _c("div", { staticClass: "group-content" }, [
        _c(
          "div",
          { staticClass: "checkbox" },
          [
            _c("div", { staticClass: "checkbox-label" }, [
              _vm._v("统计范围：")
            ]),
            _vm._v(" "),
            _c(
              "el-radio-group",
              {
                on: { change: _vm.getDayStatistics },
                model: {
                  value: _vm.currentDayStoreId,
                  callback: function($$v) {
                    _vm.currentDayStoreId = $$v
                  },
                  expression: "currentDayStoreId"
                }
              },
              [
                _c("el-radio", { attrs: { label: 0 } }, [_vm._v("全部门店")]),
                _vm._v(" "),
                _vm._l(_vm.storeData, function(storeItem, storeIndex) {
                  return _c(
                    "el-radio",
                    { key: storeIndex, attrs: { label: storeItem.storeId } },
                    [_vm._v(_vm._s(storeItem.storeName))]
                  )
                })
              ],
              2
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "checkbox" },
          [
            _c("div", { staticClass: "checkbox-label" }, [
              _vm._v("可见字段：")
            ]),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                model: {
                  value: _vm.currentDayDataType,
                  callback: function($$v) {
                    _vm.currentDayDataType = $$v
                  },
                  expression: "currentDayDataType"
                }
              },
              _vm._l(_vm.dataTypeOptions, function(dataType) {
                return _c(
                  "el-checkbox",
                  { key: dataType.name, attrs: { label: dataType.name } },
                  [_vm._v(_vm._s(dataType.label))]
                )
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tags", staticStyle: { "margin-top": "15px" } },
          [
            _c("div", { staticClass: "tags-label" }, [_vm._v("时间范围：")]),
            _vm._v(" "),
            _vm._l(_vm.dateOptions, function(dateItem) {
              return _c(
                "div",
                {
                  key: dateItem.value,
                  staticClass: "tags-item",
                  class: {
                    active:
                      _vm.currentDayDate == dateItem.value &&
                      _vm.currentDayTimes.length <= 0
                  },
                  on: {
                    click: function($event) {
                      return _vm.currentDayDateChange(dateItem.value)
                    }
                  }
                },
                [_vm._v("\n          " + _vm._s(dateItem.label) + "\n        ")]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tags-times" },
              [
                _c("el-date-picker", {
                  attrs: {
                    size: "mini",
                    type: "datetimerange",
                    "range-separator": "~",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    clearable: ""
                  },
                  on: { change: _vm.getDayStatistics },
                  model: {
                    value: _vm.currentDayTimes,
                    callback: function($$v) {
                      _vm.currentDayTimes = $$v
                    },
                    expression: "currentDayTimes"
                  }
                })
              ],
              1
            )
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-data" },
          [
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.dayData,
                  "header-cell-style": { background: "#f5f5f5" },
                  size: "small"
                }
              },
              _vm._l(_vm.filterColumns, function(baseItem) {
                return _c("el-table-column", {
                  key: baseItem.prop,
                  attrs: { prop: baseItem.prop, label: baseItem.label }
                })
              }),
              1
            ),
            _vm._v(" "),
            _vm.dayPage.total > 0
              ? _c(
                  "div",
                  { staticClass: "table-data-footer" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-upload2",
                          size: "mini",
                          plain: ""
                        },
                        on: { click: _vm.goDownDayData }
                      },
                      [_vm._v("导出Excel")]
                    ),
                    _vm._v(" "),
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.dayPage.pageNo,
                        "page-sizes": [10, 20, 30, 40, 50],
                        "page-size": _vm.dayPage.pageSize,
                        total: _vm.dayPage.total,
                        layout: "prev,pager,next,sizes,jumper"
                      },
                      on: {
                        "size-change": _vm.handleDaySizeChange,
                        "current-change": _vm.handleDayCurrentChange
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "group" }, [
      _c("div", { staticClass: "group-title" }, [_vm._v("堆积分布图")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "group-content" },
        [
          _c(
            "div",
            { staticClass: "checkbox" },
            [
              _c("div", { staticClass: "checkbox-label" }, [
                _vm._v("统计范围：")
              ]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.getChartStatistics },
                  model: {
                    value: _vm.currentChartStoreId,
                    callback: function($$v) {
                      _vm.currentChartStoreId = $$v
                    },
                    expression: "currentChartStoreId"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("全部门店")]),
                  _vm._v(" "),
                  _vm._l(_vm.storeData, function(storeItem, storeIndex) {
                    return _c(
                      "el-radio",
                      { key: storeIndex, attrs: { label: storeItem.storeId } },
                      [_vm._v(_vm._s(storeItem.storeName))]
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tags", staticStyle: { "margin-top": "15px" } },
            [
              _c("div", { staticClass: "tags-label" }, [_vm._v("时间范围：")]),
              _vm._v(" "),
              _vm._l(_vm.dateOptions, function(dateItem) {
                return _c(
                  "div",
                  {
                    key: dateItem.value,
                    staticClass: "tags-item",
                    class: {
                      active:
                        _vm.currentChartDate == dateItem.value &&
                        _vm.currentChartTimes.length <= 0
                    },
                    on: {
                      click: function($event) {
                        return _vm.currentChartDateChange(dateItem.value)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(dateItem.label) + "\n        "
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tags-times" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "mini",
                      type: "datetimerange",
                      "range-separator": "~",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: ""
                    },
                    on: { change: _vm.getChartStatistics },
                    model: {
                      value: _vm.currentChartTimes,
                      callback: function($$v) {
                        _vm.currentChartTimes = $$v
                      },
                      expression: "currentChartTimes"
                    }
                  })
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          [
            _c("div", {
              staticClass: "chart-main",
              style: {
                height: _vm.chartHeight,
                width: "100%",
                marginTop: "30px"
              },
              attrs: { id: "chartMainRef" }
            })
          ]
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header-item" }, [
      _c("span", [_vm._v("更新方式：")]),
      _vm._v("每天00:00更新")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-progress-rows" }, [
      _c("div", { staticClass: "table-progress-col" }, [_vm._v("门店名称")]),
      _vm._v(" "),
      _c("div", {
        staticClass: "table-progress-col",
        staticStyle: { flex: "1", "max-width": "500px" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "table-progress-col" }, [_vm._v("分布占比")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }