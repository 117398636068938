"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _manage = require("@/api/manage.js");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _icon = _interopRequireDefault(require("@/common/icon.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default
  },
  data: function data() {
    return {
      showPop: false,
      icons: _icon.default,
      treeProps: {
        children: 'childMenu',
        label: 'name'
      },
      tableData: [],
      expand: [],
      isUpdate: false,
      formData: [{
        slot: 'parentId'
      }, {
        label: '资源类型：',
        type: 'radio',
        placeholder: '选择资源类型',
        value: 'type',
        selectList: [{
          value: 0,
          label: '目录'
        }, {
          value: 1,
          label: '菜单'
        }, {
          value: 2,
          label: '按钮'
        }]
      }, {
        label: '资源名称：',
        type: 'input',
        placeholder: '请输入资源名称',
        value: 'name',
        max: 30
      }, {
        slot: "url"
      }, {
        slot: 'perms'
      }, {
        slot: 'icon'
      }, {
        label: '排序：',
        type: 'inputNumber',
        placeholder: '请输入排序',
        value: 'orderNum',
        max: 255,
        remark: "数字越小越靠前,最大255"
      }],
      formType: null,
      ruleForm: {
        parentId: 0,
        orderNum: undefined,
        type: null,
        name: null,
        perms: null,
        icon: null,
        url: null
      },
      rules: {
        type: [{
          required: true,
          message: '请选择类型'
        }],
        name: [{
          required: true,
          message: '请选择输入名称'
        }],
        perms: [{
          required: true,
          message: '请选择输入权限表示'
        }],
        icon: [{
          required: true,
          message: '请输入图标'
        }],
        orderNum: [{
          required: true,
          message: '请输入排序'
        }],
        url: [{
          required: true,
          message: '请输入路径'
        }]
      }
    };
  },
  watch: {
    isUpdate: function isUpdate(newValue, oldValue) {
      if (!newValue) {
        this.ruleForm = {
          parentId: 0,
          orderNum: undefined,
          type: null,
          name: null,
          perms: null,
          icon: null,
          url: null
        };
      }
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    goAddClass: function goAddClass(row) {
      this.ruleForm.parentId = row.menuId;
      this.isUpdate = true;
    },
    setIcon: function setIcon(item) {
      this.ruleForm.icon = item.class;
      this.showPop = false;
      console.log(this.$refs.popover); // this.$refs.popover.hide()
    },
    handleClick: function handleClick(data, checked) {
      if (checked) {
        this.ruleForm.parentId = data.menuId;
        this.$refs.treeForm.setCheckedKeys([data.menuId]);
      } else if (this.ruleForm.parentId == data.menuId) {
        this.ruleForm.parentId = 0;
      }

      console.log(this.ruleForm.parentId);
    },
    changeSpec: function changeSpec(e) {
      if (e.length > 2) {
        this.ruleForm.gcGoodsSpec = e.filter(function (item) {
          return item != e[e.length - 1];
        });
        this.$message.error('最多可选择两个规格');
      }
    },
    goUppdateItem: function goUppdateItem(row, type) {
      this.formType = type;
      var upData = (0, _objectSpread2.default)({}, row);
      delete upData.createDate;
      this.ruleForm = upData;
      this.isUpdate = true;
    },
    submitForm: function submitForm(formName) {
      var _this = this;

      var apiUrl = this.ruleForm.menuId && this.formType != 'copy' ? '/api/sysMenu/editSysMenu' : '/api/sysMenu/addSysMenu';
      var requestData = (0, _objectSpread2.default)({}, this.ruleForm);
      this.apiPost(apiUrl, requestData).then(function (res) {
        _this.isUpdate = false;

        _this.init();

        _this.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    },
    init: function init() {
      var _this2 = this;

      this.openLoading();
      (0, _manage.listMenuTree)().then(function (res) {
        _this2.openLoading().close();

        _this2.tableData = res.data;
        /* this.tableData.forEach((item) => {
          this.expand.push(String(item.menuId))
        }) */
      }).catch(function (err) {
        _this2.openLoading().close();
      });
    },
    addDepartment: function addDepartment() {
      // 新增
      this.$router.push('/system/menu/menuDea');
    },
    setBtn: function setBtn(menuId) {
      // 操作按钮执行函数
      this.$router.push('/system/menu/menuDea?menuId=' + menuId);
    },
    deleteMenu: function deleteMenu(item) {
      var _this3 = this;

      (0, _manage.delMenu)(item.menuId).then(function (res) {
        _this3.init();
      }).catch(function (err) {
        _this3.openLoading().close();
      });
    }
  }
};
exports.default = _default;