"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.couponOrderDetail = couponOrderDetail;
exports.couponOrderPage = couponOrderPage;
exports.couponOrderRefund = couponOrderRefund;
exports.listCouponDonationLogPage = listCouponDonationLogPage;
exports.listCouponUsedLogPage = listCouponUsedLogPage;
exports.listSysUserKpiPage = listSysUserKpiPage;
exports.userKpiReward = userKpiReward;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
核销助手核销优惠券分页：/api/coupon/listCouponUsedLogPage
{"pagNo":"当前页","pageSize":"页条数","couponCode":"优惠券编码","couponType":"券类型","uname":"核销人姓名",
"umobile":"核销人手机号","userMobile":"会员手机号","storeId":"门店编码"}
 */
function listCouponDonationLogPage(data) {
  return (0, _request.default)({
    url: '/api/coupon/listCouponDonationLogPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listCouponUsedLogPage(data) {
  return (0, _request.default)({
    url: '/api/coupon/listCouponUsedLogPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*

 系统用户推广指标分页：/api/sysUser/listSysUserKpiPage
 {"pageNo":"当前页","pageSize":"页条数","mobile":"推广人手机号","username":"推广人手姓名","startTime":"开始时间","endTime":"结束时间"}
 */


function listSysUserKpiPage(data) {
  return (0, _request.default)({
    url: '/api/sysUser/listSysUserKpiPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function userKpiReward(data) {
  return (0, _request.default)({
    url: '/api/sysUser/userKpiReward',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
 系统用户推广指标奖励发放：/api/sysUser/userKpiReward
 {"uid":uid,"mobile":"手机号"}

 优惠券退款：/api/order/couponOrderRefund/{orderNo}

 */

/*
1、优惠券订单分页：/api/order/couponOrderPage
{"buyUtphone":"会员手机号","goodsCode":"券编码"}

2、优惠券订单明细：/api/order/couponOrderDetail/{orderNo}

 */


function couponOrderPage(data) {
  return (0, _request.default)({
    url: '/api/order/couponOrderPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function couponOrderRefund(data) {
  return (0, _request.default)({
    url: '/api/order/couponOrderRefund',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function couponOrderDetail(data) {
  return (0, _request.default)({
    url: '/api/order/couponOrderDetail/' + data,
    method: 'post',
    loading: 'loading'
  });
}