var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "chat_top" }, [
        _c("div", { staticClass: "top_lable" }, [_vm._v("企业微信")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "top_edit" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.setStree }
              },
              [_vm._v("编辑")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "form_item", attrs: { label: "" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.weChatList,
                size: "mini",
                "header-cell-style": {
                  background: "#f5f5f5",
                  height: "20px",
                  padding: "0px 0"
                },
                "max-height": "500"
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", align: "center", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.$index + 1) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "部门", align: "center", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.form.deptName) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _vm._l(_vm.tabTh, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    align: "center",
                    "min-width": item.width
                  }
                })
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "微信头像", align: "center", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("img", {
                          staticStyle: { width: "40px", height: "40px" },
                          attrs: { src: scope.row.thumbAvatar }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.treeShow,
              expression: "treeShow"
            }
          ],
          staticClass: "pop"
        },
        [
          _c(
            "div",
            { staticClass: "pop_box" },
            [
              _c("i", {
                staticClass: "pop_close el-icon-circle-close",
                on: {
                  click: function($event) {
                    _vm.treeShow = false
                  }
                }
              }),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "输入关键字进行过滤" },
                model: {
                  value: _vm.filterText,
                  callback: function($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText"
                }
              }),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("el-tree", {
                ref: "tree",
                staticClass: "filter-tree",
                staticStyle: { "max-height": "400px", overflow: "auto" },
                attrs: {
                  "default-expanded-keys": [_vm.form.deptId],
                  "default-checked-keys": [_vm.form.deptId],
                  data: _vm.treeList,
                  "node-key": "id",
                  "show-checkbox": "",
                  props: _vm.defaultProps,
                  "filter-node-method": _vm.filterNode,
                  "check-strictly": true,
                  "expand-on-click-node": false
                },
                on: { "check-change": _vm.handleClick }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        size: "mini"
                      },
                      on: { click: _vm.clearTree }
                    },
                    [_vm._v("清空")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.addTree }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }