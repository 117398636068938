"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var _mem = require("@/api/mem.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      storeList: [],
      columns: [{
        label: '活动主题',
        prop: 'activtyThme',
        width: 80
      }, {
        label: '分类',
        prop: 'categoryName',
        width: 80
      }, {
        label: '按钮文字',
        prop: 'buttonDesc',
        width: 80
      }, {
        label: '开始时间',
        prop: 'startTime',
        width: 80
      }, {
        label: '结束时间',
        prop: 'endTime',
        width: 80
      }, {
        label: '门店编码',
        prop: 'storeCode',
        width: 80
      }, {
        label: '门店名称',
        prop: 'soreName',
        width: 100
      }, {
        label: '操作人',
        prop: 'createUser',
        width: 80
      }, {
        label: '操作人手机号',
        prop: 'createUserMobile',
        width: 80
      }, {
        slot: 'status'
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '活动主题',
        value: 'activtyThme'
      }, {
        type: 'input',
        span: 4,
        placeholder: '门店编码',
        value: 'storeCode'
      }, {
        type: 'input',
        span: 4,
        placeholder: '创建人手机号',
        value: 'createUserMobile'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '状态',
        value: 'status',
        select: [{
          label: '启用通过',
          value: 1
        }, {
          label: '停用',
          value: -1
        }]
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.storeList = res.data;
    });
    this.apiPost('/api/org-store/activity/ctgPage', {
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      var classList = res.data.records.map(function (item) {
        return {
          label: item.categoryName,
          value: item.id
        };
      });

      _this.searchData.push({
        type: 'radio',
        span: 4,
        placeholder: '所属分类',
        value: 'activityCategoryId',
        select: classList
      });
    });
  },
  methods: {
    getStoreName: function getStoreName(storeCode) {
      var storeItem = this.storeList.find(function (item) {
        return storeCode == item.storeCode;
      });
      return storeItem ? storeItem.shortName : '';
    },
    delGrade: function delGrade(row, status) {
      var _this2 = this;

      this.apiGet('/api/org-store/activity/removeAtyById/' + row.id).then(function (res) {
        _this2.$refs.wTable.getList();
      });
    },
    goStop: function goStop(row) {
      var _this3 = this;

      this.apiPost('/api/smsTask/editSmsTask', {
        id: row.id,
        executeStatus: 3
      }).then(function (res) {
        _this3.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;