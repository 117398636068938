var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addCard" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.obj,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "提货卡名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        model: {
                          value: _vm.obj.name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.obj,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "obj.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否权益卡：", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.obj.type,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "type", $$v)
                            },
                            expression: "obj.type"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("否")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("是")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "一句话文案：", prop: "description" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        model: {
                          value: _vm.obj.description,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.obj,
                              "description",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "obj.description"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "remark" }, [
                        _vm._v("简短的一句话文案(24字以内)")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "可充值金额：", prop: "rechargeRmount" }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "number" },
                        model: {
                          value: _vm.obj.rechargeRmount,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "rechargeRmount", $$v)
                          },
                          expression: "obj.rechargeRmount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "提货卡图案：", prop: "cover" } },
                    [
                      _c("add-load", {
                        attrs: { width: 150, height: 91, cover: _vm.obj.cover },
                        on: { setCover: _vm.setCover }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "remark" }, [
                        _vm._v(" 图片尺寸：900px * 550px ")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提货卡卡面：", prop: "album" } },
                    [
                      _c(
                        "div",
                        { staticClass: "vendor_img flex" },
                        [
                          _vm._l(_vm.obj.album, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "up_img" },
                              [
                                _c("add-load", {
                                  attrs: {
                                    width: 150,
                                    height: 91,
                                    cover: item.image
                                  },
                                  on: {
                                    setCover: function($event) {
                                      return _vm.setAlbum($event, index)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "close flex_center",
                                    on: {
                                      click: function($event) {
                                        return _vm.delAlbum(index)
                                      }
                                    }
                                  },
                                  [_vm._v("x")]
                                )
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c("add-load", {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { width: 91, height: 91 },
                            on: {
                              setCover: function($event) {
                                return _vm.setAlbum(
                                  $event,
                                  _vm.obj.album.length
                                )
                              }
                            }
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "remark" }, [
                        _vm._v(" 图片尺寸：900px * 550px ")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.obj.scopeType == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "会员机构：", prop: "institutionIds" } },
                [
                  _c(
                    "div",
                    { staticClass: "list_tree" },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          data: _vm.selectEffMembers,
                          "show-checkbox": "",
                          "node-key": "code",
                          "default-checked-keys": _vm.obj.institutionIds,
                          props: _vm.defaultProps
                        },
                        on: { "check-change": _vm.handleClick }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否可用商品码：", prop: "usableType" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.usableType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "usableType", $$v)
                    },
                    expression: "obj.usableType"
                  }
                },
                [_vm._v("是")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.obj.usableType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "usableType", $$v)
                    },
                    expression: "obj.usableType"
                  }
                },
                [_vm._v("否")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品码：", prop: "goodsIds" } },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.downloadUrl,
                        download: "提货卡商品码模板.xlsx"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "upLoad",
                          staticStyle: { "margin-right": "10px" },
                          attrs: { size: "mini", icon: "el-icon-download" }
                        },
                        [_vm._v("下载模板")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("upload-excel-component", {
                    attrs: {
                      "btn-text": "上传商品码",
                      type: "btn",
                      "on-success": _vm.handleSuccess,
                      "before-upload": _vm.beforeUpload
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.obj.goodsIds.length > 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "10px",
                        border: "1px solid #CCCCCC",
                        "margin-top": "10px",
                        "max-height": "300px",
                        overflow: "auto"
                      },
                      style: {
                        color: _vm.obj.usableType === 1 ? "#67C23A" : "#F56C6C"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.obj.goodsIds.join("、")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "有效时间类型：", prop: "validTimeType" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.validTimeType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "validTimeType", $$v)
                    },
                    expression: "obj.validTimeType"
                  }
                },
                [_vm._v("时间段")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.obj.validTimeType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "validTimeType", $$v)
                    },
                    expression: "obj.validTimeType"
                  }
                },
                [_vm._v("领取后N天内有效")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _vm.obj.validTimeType === 1
                ? _c(
                    "el-form-item",
                    { ref: "effStartTime", attrs: { prop: "effStartTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择开始时间"
                        },
                        model: {
                          value: _vm.obj.effStartTime,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "effStartTime", $$v)
                          },
                          expression: "obj.effStartTime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.obj.validTimeType === 1
                ? _c(
                    "el-form-item",
                    {
                      ref: "effEndTime",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { "label-width": "0", prop: "effEndTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: !_vm.obj.effStartTime,
                          "picker-options": _vm.pickerTime,
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择结束时间"
                        },
                        model: {
                          value: _vm.obj.effEndTime,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "effEndTime", $$v)
                          },
                          expression: "obj.effEndTime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.obj.validTimeType === 2
            ? _c(
                "el-form-item",
                { ref: "validDay", attrs: { prop: "validDay" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: {
                      size: "small",
                      onmousewheel: "return false;",
                      type: "number",
                      placeholder: ""
                    },
                    model: {
                      value: _vm.obj.validDay,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "validDay", $$v)
                      },
                      expression: "obj.validDay"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "coupon_box_remark" }, [
                    _vm._v("天")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "提货卡说明：", prop: "remark" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 600, height: 300 },
                model: {
                  value: _vm.obj.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "remark", $$v)
                  },
                  expression: "obj.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即创建")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }