"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      formData: [{
        label: '分类名称：',
        type: 'input',
        placeholder: '请输入分类名称',
        value: 'categoryName'
      }, {
        label: '是否显示小程序：',
        type: 'radio',
        placeholder: '请选择是否显示小程序',
        value: 'showType',
        selectList: [{
          label: '是',
          value: 1
        }, {
          label: '否',
          value: 2
        }]
      }],
      ruleForm: {
        showType: null,
        categoryName: null,
        id: null
      },
      rules: {
        categoryName: [{
          required: true,
          message: '请输入分类名称'
        }],
        showType: [{
          required: true,
          message: '请选择是否显示小程序'
        }]
      }
    };
  },
  components: {},
  computed: {},
  created: function created() {
    var _this = this;

    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/coupon-category/getById/' + this.ruleForm.id).then(function (res) {
        _this.ruleForm = res.data;
      });
    }
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var apiUrl = this.ruleForm.id ? '/api/coupon-category/update' : '/api/coupon-category/save';
      this.apiPost(apiUrl, this.ruleForm).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
      return;
      addData(this.ruleForm).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;