var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            pageApi: "/api/mall/deliveryFree/list",
            "search-data": _vm.searchData
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "price",
              label: "价格",
              align: "left",
              width: "160"
            },
            slot: "price",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "p",
                      [
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              width: "45px",
                              "text-align": "center",
                              "margin-right": "6px"
                            },
                            attrs: { size: "mini", type: "warning" }
                          },
                          [_vm._v("成本价")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "form_remark" }, [
                          _vm._v(_vm._s(scope.row.spuCostPrice) + "元")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticStyle: { margin: "6px 0" } },
                      [
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              width: "45px",
                              "text-align": "center",
                              "margin-right": "6px"
                            },
                            attrs: { size: "mini" }
                          },
                          [_vm._v("原价")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "form_remark" }, [
                          _vm._v(_vm._s(scope.row.spuOriginalPrice) + "元")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              width: "45px",
                              "text-align": "center",
                              "margin-right": "6px"
                            },
                            attrs: { size: "mini", type: "success" }
                          },
                          [_vm._v("售价")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "form_remark" }, [
                          _vm._v(_vm._s(scope.row.spuGoodsPrice) + "元")
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "isEnable",
              label: "状态",
              align: "center",
              width: "60"
            },
            slot: "isEnable",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { class: scope.row.isEnable == 1 ? "success" : "danger" },
                      [
                        _vm._v(
                          _vm._s(scope.row.isEnable == 1 ? "启用" : "停用")
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              fixed: "right",
              align: "center",
              width: "90"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          size: "mini",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/legou/basic/freeAdd?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }