"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '设备id',
        prop: 'cashierNo',
        width: 80
      }, {
        label: '设备编码',
        prop: 'deviceSn',
        width: 80
      }, {
        label: '设备名称',
        prop: 'deviceName',
        width: 80
      }, {
        label: '关联门店',
        prop: 'storeId',
        width: 80
      }, {
        label: '合同号',
        prop: 'contractNo',
        width: 80
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status',
        width: 80
      }, {
        label: '经度',
        prop: 'longitude',
        width: 80
      }, {
        label: '纬度',
        prop: 'latitude',
        width: 80
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '请输入设备编码',
        value: 'deviceSn'
      }, {
        type: 'input',
        span: 4,
        placeholder: '请输入门店编码',
        value: 'storeId'
      }, {
        type: 'input',
        span: 4,
        placeholder: '请输入合同号',
        value: 'contractNo'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '状态',
        value: 'status',
        select: [{
          value: 1,
          label: '启用'
        }, {
          value: 0,
          label: '禁用'
        }]
      }]
    };
  },
  methods: {
    delGrade: function delGrade(row) {
      var _this = this;

      this.apiPost('/api/org/device/removeDeviceByIds', [row.cashierNo]).then(function (res) {
        _this.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;