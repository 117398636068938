"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var externalRouter = {
  path: '/external',
  component: _layout.default,
  redirect: '/external/tab',
  name: 'external',
  meta: {
    title: '对外商户'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tab.vue"));
      });
    },
    name: 'externalTab',
    meta: {
      title: '对外商户'
    }
  }, {
    path: 'merchant',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./merchant/list.vue"));
      });
    },
    name: 'externalMerchant',
    meta: {
      activeMenu: '/external/tab',
      title: '商户列表',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/third/party/merchant/listThirdPartyMerchantPage',
      addUrl: '/external/merchantAdd',
      addTxt: "新增商户"
    }
  }, {
    path: 'merchantAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./merchant/add.vue"));
      });
    },
    name: 'externalMerchantAdd',
    meta: {
      title: '新增编辑商户',
      activeMenu: '/external/tab'
    }
  }, {
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./merchant/goods.vue"));
      });
    },
    name: 'externalMerchantGoods',
    meta: {
      activeMenu: '/external/tab',
      title: '商户商品',
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      isOneRequest: 'no',
      api: '/api/third/party/merchant/listThirdPartyMerchantGoodsPage'
      /* addUrl: '/external/select',
       addTxt: "添加商品" */

    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./merchant/order.vue"));
      });
    },
    name: 'externalMerchantorder',
    meta: {
      activeMenu: '/external/tab',
      title: '商户订单列表',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      // isOneRequest:'no',
      api: '/api/third/party/merchant/listThirdPartyMerchantLogPage'
      /* addUrl: '/external/select',
       addTxt: "添加商品" */

    }
  }, {
    path: 'select',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./merchant/select.vue"));
      });
    },
    name: 'externalMerchantSelect',
    meta: {
      title: '添加商品',
      activeMenu: '/external/tab'
    }
  }]
};
var _default = externalRouter;
exports.default = _default;