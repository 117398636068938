"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var _meta4;

var memberRouter = {
  path: '/member',
  component: _layout.default,
  redirect: '/member/equity/manageArticle',
  hidden: true,
  name: 'Member',
  meta: {
    title: '会员',
    icon: 'nested'
  },
  children: [{
    path: 'equity',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/index.vue'));
      });
    },
    name: 'Equity',
    redirect: '/member/equity/tab',
    meta: {
      title: '会员权益'
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/tab.vue'));
        });
      },
      name: 'memberTab',
      meta: {
        title: '会员管理'
      }
    }, {
      path: 'grade',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/grade.vue'));
        });
      },
      name: 'Grade',
      meta: {
        title: '会员等级'
      }
    }, {
      path: 'gradeAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/gradeAdd.vue'));
        });
      },
      name: 'GradeAdd',
      meta: {
        title: '新增/编辑会员等级'
      }
    }, {
      path: 'equityList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/equity/list.vue'));
        });
      },
      name: 'EquityList',
      meta: {
        title: '会员权益管理',
        keepAlive: true
      }
    }, {
      path: 'equityAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/equity/add.vue'));
        });
      },
      name: 'EquityAdd',
      meta: {
        title: '编辑/新增会员权益',
        activeMenu: '/member/equity/equityList'
      }
    }]
  }, {
    path: 'major',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/major/index.vue'));
      });
    },
    name: 'Major',
    redirect: '/member/major/tab',
    meta: {
      title: '黑卡会员权益兑换'
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/major/tab.vue'));
        });
      },
      name: 'MajorTab',
      meta: {
        title: '黑卡会员权益兑换'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/major/add.vue'));
        });
      },
      name: 'MajorAdd',
      meta: {
        title: '兑换权益'
      }
    }, {
      path: 'search',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/major/search.vue'));
        });
      },
      name: 'MajorSearch',
      meta: {
        title: '兑换权益'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/major/list.vue'));
        });
      },
      name: 'MajorList',
      meta: {
        title: '兑换记录'
      }
    }, {
      path: 'dea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/major/dea.vue'));
        });
      },
      name: 'MajorDea',
      meta: {
        title: '兑换详情'
      }
    }]
  }, {
    path: 'price',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/major/index.vue'));
      });
    },
    name: 'price',
    redirect: '/member/price/tab',
    meta: {
      title: '会员价'
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/price/tab.vue'));
        });
      },
      name: 'priceTab',
      meta: {
        title: '会员价'
      }
    }, {
      path: 'base',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/price/base/list.vue'));
        });
      },
      name: 'baseList',
      hidden: true,
      meta: {
        title: '会员价-基础方案',
        keepAlive: true,
        activeMenu: '/member/price/tab'
      }
    }, {
      path: 'goods',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/price/base/goods.vue'));
        });
      },
      name: 'goodsList',
      hidden: true,
      meta: {
        title: '商品-基础方案',
        keepAlive: true,
        activeMenu: '/member/price/tab'
      }
    }, {
      path: 'baseAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/price/base/add.vue'));
        });
      },
      name: 'baseAdd',
      hidden: true,
      meta: {
        title: '会员价-基础方案',
        activeMenu: '/member/price/tab'
      }
    }, {
      path: 'activity',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/price/activity/list.vue'));
        });
      },
      name: 'activityList',
      hidden: true,
      meta: {
        title: '会员价-活动方案',
        keepAlive: true,
        activeMenu: '/member/price/tab'
      }
    }, {
      path: 'activiTyGoods',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/price/activity/goods.vue'));
        });
      },
      name: 'activiTyGoods',
      hidden: true,
      meta: {
        title: '商品-活动方案',
        keepAlive: true,
        activeMenu: '/member/price/tab'
      }
    }, {
      path: 'activityAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/price/activity/add.vue'));
        });
      },
      name: 'activityAdd',
      hidden: true,
      meta: {
        title: '会员价-活动方案',
        activeMenu: '/member/price/tab'
      }
    }]
  }, {
    path: 'unlock',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/index.vue'));
      });
    },
    name: 'unlock',
    redirect: '/member/unlock/view/tab',
    meta: {
      title: '权益解锁'
    },
    children: [{
      path: 'view',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/vipCitylife/index.vue'));
        });
      },
      redirect: '/member/unlock/view/tab',
      name: 'unlockView',
      meta: {
        title: '权益解锁'
      },
      children: [{
        path: 'tab',
        //component: () => import('@/views/member/vipCitylife/index.vue'),
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/unlock/tab.vue'));
          });
        },
        name: 'vipCityLiftTab',
        meta: {
          title: '权益解锁'
        }
      }, {
        path: 'list',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/unlock/list.vue'));
          });
        },
        name: 'unlockList',
        meta: {
          title: '权益列表',
          keepAlive: true,
          activeMenu: '/member/unlock/view/tab'
        }
      }, {
        path: 'add',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/unlock/add.vue'));
          });
        },
        name: 'unlockAdd',
        hidden: true,
        meta: {
          title: '编辑权益',
          activeMenu: '/member/unlock/view/tab'
        }
      }, {
        path: 'activation',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/unlock/activation/list.vue'));
          });
        },
        name: 'unlockactivation',
        meta: {
          title: '激活列表',
          keepAlive: true,
          activeMenu: '/member/unlock/view/tab'
        }
      }]
    }]
    /*    children: [
          {
            path: 'list',
            component: () => import('@/views/member/unlock/list.vue'),
            name: 'unlockList',
            meta: { title: '权益解锁', keepAlive: true }
          },
          {
            path: 'add',
            component: () => import('@/views/member/unlock/add.vue'),
            name: 'unlockAdd',
            hidden: true,
            meta: { title: '编辑权益解锁' }
          }
        ] */

  }, {
    path: 'class',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/index.vue'));
      });
    },
    name: 'classPage',
    redirect: '/member/class/list',
    meta: {
      title: '付费会员-类型'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/class/list.vue'));
        });
      },
      name: 'classList',
      meta: {
        title: '付费会员-类型',
        keepAlive: true
      }
    }, {
      path: 'kin',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/class/kin.vue'));
        });
      },
      name: 'classkin',
      meta: {
        title: '亲友卡记录',
        keepAlive: true
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/class/add.vue'));
        });
      },
      name: 'classAdd',
      hidden: true,
      meta: {
        title: '付费会员-类型',
        activeMenu: '/member/class/list'
      }
    }, {
      path: 'equity',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/class/equity.vue'));
        });
      },
      name: 'classequityAdd',
      hidden: true,
      meta: {
        title: '权益配置'
      }
    }]
  }, {
    path: 'coupon',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/index.vue'));
      });
    },
    name: 'memberCoupon',
    redirect: '/member/coupon/list',
    meta: {
      title: '会员发券'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/coupon/list.vue'));
        });
      },
      name: 'memberCouponList',
      meta: {
        title: '会员发券',
        keepAlive: true
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/coupon/add.vue'));
        });
      },
      name: 'memberCouponAdd',
      hidden: true,
      meta: {
        title: '会员发券',
        activeMenu: '/member/coupon/list'
      }
    }]
  }, {
    path: 'openGift',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/index.vue'));
      });
    },
    name: 'openGift',
    redirect: '/member/openGift/openGiftTab',
    meta: {
      title: '会员开卡礼'
    },
    children: [{
      path: 'openGiftTab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/openGift/tab.vue'));
        });
      },
      name: 'openGiftTab',
      meta: {
        title: '会员开卡礼',
        keepAlive: true
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/openGift/open/list.vue'));
        });
      },
      name: 'openGiftList',
      meta: {
        title: '会员开卡礼',
        keepAlive: true
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/openGift/open/add.vue'));
        });
      },
      name: 'openCardAdd',
      hidden: true,
      meta: {
        title: '新增会员开卡礼',
        activeMenu: '/member/openGift/list'
      }
    }, {
      path: 'edit',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/openGift/open/edit.vue'));
        });
      },
      name: 'openCardedit',
      hidden: true,
      meta: {
        title: '编辑会员开卡礼'
      }
    }, {
      path: 'classList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/openGift/class/list.vue'));
        });
      },
      name: 'openCardclassList',
      hidden: true,
      meta: {
        title: '会员开卡礼分类',
        keepAlive: true
      }
    }, {
      path: 'classAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/openGift/class/add.vue'));
        });
      },
      name: 'openCardclassAdd',
      hidden: true,
      meta: {
        title: '开卡礼分类详情'
      }
    }]
  }, {
    path: 'memberTab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/memberTab.vue'));
      });
    },
    name: 'MemberTab',
    meta: {
      title: '会员管理'
    }
  }, {
    path: 'gift',
    name: 'MemberGift',
    redirect: '/member/gift/list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/gift/index.vue'));
      });
    },
    meta: {
      title: '会员礼品管理'
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/gift/tab.vue'));
        });
      },
      name: 'memberGiftTab',
      meta: {
        title: '新增礼品',
        activeMenu: '/member/present/presentTab'
      }
    }, {
      path: 'giftCoupon',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/gift/giftCoupon.vue'));
        });
      },
      name: 'GiftCoupon',
      meta: {
        title: '礼品邮寄',
        keepAlive: true
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/gift/list.vue'));
        });
      },
      name: 'memberGiftList',
      meta: {
        title: '会员礼品管理',
        keepAlive: true,
        activeMenu: '/member/present/presentTab'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/gift/add.vue'));
        });
      },
      name: 'memberGiftAdd',
      meta: {
        title: '新增/编辑会员礼品管理',
        activeMenu: '/member/present/presentTab'
      }
    }, {
      path: 'adds',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/gift/adds.vue'));
        });
      },
      name: 'memberGiftAdds',
      meta: {
        title: '批量导入',
        activeMenu: '/member/present/presentTab'
      }
    }]
  }, {
    path: 'present',
    name: 'MemberPresent',
    redirect: '/member/present/list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/present/index.vue'));
      });
    },
    meta: {
      title: '会员馈赠'
    },
    children: [{
      path: 'presentTab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/present/tab.vue'));
        });
      },
      name: 'presentTab',
      meta: {
        title: '会员馈赠'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/present/list.vue'));
        });
      },
      name: 'memberPresentList',
      meta: {
        title: '会员馈赠',
        keepAlive: true,
        activeMenu: '/member/present/presentTab'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/present/add.vue'));
        });
      },
      name: 'memberPresentAdd',
      meta: (0, _defineProperty2.default)({
        title: '新增/编辑会员馈赠',
        activeMenu: '/member/present/list'
      }, "activeMenu", '/member/present/presentTab')
    }, {
      path: 'affrim',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/present/affrim.vue'));
        });
      },
      name: 'memberPresentAffrim',
      meta: (0, _defineProperty2.default)({
        title: '会员馈赠单确认',
        activeMenu: '/member/present/list'
      }, "activeMenu", '/member/present/presentTab')
    }, {
      path: 'returnSales',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/present/returnSales.vue'));
        });
      },
      name: 'memberReturnSales',
      meta: (0, _defineProperty2.default)({
        title: '馈赠详情',
        activeMenu: '/member/present/list'
      }, "activeMenu", '/member/present/presentTab')
    }, {
      path: 'report',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/present/report.vue'));
        });
      },
      name: 'giftReport',
      meta: {
        title: '馈赠报表',
        keepAlive: true,
        activeMenu: '/member/present/presentTab'
      }
    }]
  }, {
    path: 'vipCityLift',
    name: 'vipCityLift',
    redirect: '/member/vipCityLift/view/tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/vipCitylife/index.vue'));
      });
    },
    meta: {
      title: '会员同城生活'
    },
    children: [{
      path: 'view',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/vipCitylife/index.vue'));
        });
      },
      redirect: '/member/vipCityLift/view/tab',
      name: 'vipCityLiftView',
      meta: {
        title: '同城生活'
      },
      children: [{
        path: 'tab',
        //component: () => import('@/views/member/vipCitylife/index.vue'),
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/vipCitylife/tab.vue'));
          });
        },
        name: 'vipCityLiftViewTab',
        meta: {
          title: '同城生活'
        }
      }, {
        path: 'list',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/vipCitylife/list.vue'));
          });
        },
        name: 'vipCityLiftList',
        meta: {
          title: '同城生活',
          keepAlive: true,
          activeMenu: '/member/vipCityLift/view/tab'
        }
      }, {
        path: 'add',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/vipCitylife/add.vue'));
          });
        },
        name: 'vipCityLiftAdd',
        meta: {
          title: '新增同城生活',
          activeMenu: '/member/vipCityLift/view/tab'
        }
      }, {
        path: 'edit',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/vipCitylife/edit.vue'));
          });
        },
        name: 'vipCityLiftedit',
        meta: {
          title: '编辑同城生活',
          activeMenu: '/member/vipCityLift/view/tab'
        }
      }, {
        path: 'classList',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/vipCitylife/class/list.vue'));
          });
        },
        name: 'vipCityLiftClassList',
        meta: {
          title: '同城生活分类',
          activeMenu: '/member/vipCityLift/view/tab',
          keepAlive: true
        }
      }, {
        path: 'classAdd',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/vipCitylife/class/add.vue'));
          });
        },
        name: 'vipCityLiftClassAdd',
        meta: {
          title: '新增/编辑同城生活分类',
          activeMenu: '/member/vipCityLift/view/tab'
        }
      }]
    }]
  }, {
    path: 'hardCoupon',
    name: 'hardCoupon',
    redirect: '/member/hardCoupon/view/tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/hardCoupon/index.vue'));
      });
    },
    meta: {
      title: '硬核好券'
    },
    children: [{
      path: 'view',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/hardCoupon/index.vue'));
        });
      },
      redirect: '/member/hardCoupon/view/tab',
      name: 'hardCouponView',
      meta: {
        title: '硬核好券'
      },
      children: [{
        path: 'tab',
        //component: () => import('@/views/member/vipCitylife/index.vue'),
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/hardCoupon/tab.vue'));
          });
        },
        name: 'hardCouponTab',
        meta: {
          title: '硬核好券'
        }
      }, {
        path: 'list',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/hardCoupon/list.vue'));
          });
        },
        name: 'hardCouponList',
        meta: {
          title: '硬核好券',
          keepAlive: true
        }
      }, {
        path: 'add',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/hardCoupon/add.vue'));
          });
        },
        name: 'hardCouponAdd',
        meta: {
          title: '新增硬核好券'
        }
      }, {
        path: 'edit',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/hardCoupon/edit.vue'));
          });
        },
        name: 'hardCouponedit',
        meta: {
          title: '编辑硬核好券'
        }
      }, {
        path: 'classList',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/hardCoupon/class/list.vue'));
          });
        },
        name: 'hardCouponClassList',
        meta: {
          title: '硬核好券分类',
          keepAlive: true
        }
      }, {
        path: 'classAdd',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/member/hardCoupon/class/add.vue'));
          });
        },
        name: 'hardCouponClassAdd',
        meta: {
          title: '新增/编辑硬核好券分类'
        }
      }]
    }]
  }, {
    path: 'addMember',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/addMember.vue'));
      });
    },
    name: 'AddMembers',
    meta: {
      title: '新增会员',
      activeMenu: '/member/addList'
    }
  }, {
    path: 'addList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/add/list.vue'));
      });
    },
    name: 'AddList',
    meta: {
      title: '新增会员',
      keepAlive: true
    }
  }, {
    path: 'memberMerge',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/merge.vue'));
      });
    },
    name: 'MemberMerge',
    meta: {
      title: '会员合并'
    }
  }, {
    path: 'memberCancel',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/cancel/list.vue'));
      });
    },
    name: 'MemberCancel',
    meta: {
      title: '注销记录'
    }
  }, {
    path: 'memberChange',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/changePhone/index.vue'));
      });
    },
    name: 'MemberChange',
    meta: {
      title: '切换手机号记录'
    }
  }, {
    path: 'maotaiMember',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/maotai/index.vue'));
      });
    },
    name: 'maotaiMember',
    meta: {
      title: 'MIKAPLUS'
    }
  }, {
    path: 'maotaiOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/maotai/order.vue'));
      });
    },
    name: 'maotaiOrder',
    meta: {
      title: '付费会员统计',
      keepAlive: true
    }
  }, {
    path: 'vipWhite',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/maotai/white.vue'));
      });
    },
    name: 'vipOrderWhite',
    meta: {
      title: '365团购订单白名单'
    }
  }, {
    path: 'maotaiList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/maotai/list.vue'));
      });
    },
    name: 'maotaiList',
    meta: {
      title: 'MIKA PLUS类型',
      keepAlive: true
    }
  }, {
    path: 'maotaiAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/maotai/add.vue'));
      });
    },
    name: 'maotaiAdd',
    meta: {
      title: 'MIKA PLUS类型新增/编辑',
      activeMenu: '/member/maotaiList'
    }
  }, {
    path: '365Member',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/365/list.vue'));
      });
    },
    name: '365Member',
    meta: {
      title: '365会员',
      keepAlive: true
    }
  }, {
    path: '365changeTab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/365/changeScore/tab.vue'));
      });
    },
    name: '365changeTab',
    meta: {
      title: '365会员节省金额兑换积分'
    }
  }, {
    path: '365ChangeScore',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/365/changeScore/changeScore.vue'));
      });
    },
    name: '365ChangeScore',
    meta: {
      title: '365会员节省金额兑换积分',
      keepAlive: true,
      pageList: 'items',
      pageTotal: 'totalNum',
      activeMenu: '/member/365changeTab'
    }
  }, {
    path: '365Key',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/365/changeScore/keyList.vue'));
      });
    },
    name: '365ChangeScoreKey',
    meta: (_meta4 = {
      title: '关键词',
      keepAlive: true,
      pageList: 'items',
      pageTotal: 'totalNum'
    }, (0, _defineProperty2.default)(_meta4, "pageList", null), (0, _defineProperty2.default)(_meta4, "addTxt", "新增关键词"), (0, _defineProperty2.default)(_meta4, "addUrl", "/member/365KeyAdd"), (0, _defineProperty2.default)(_meta4, "activeMenu", '/member/365changeTab'), _meta4)
  }, {
    path: '365KeyAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/365/changeScore/addKey.vue'));
      });
    },
    name: '365ChangeScoreKey',
    meta: {
      title: '新增关键词',
      activeMenu: '/member/365changeTab'
    }
  }, {
    path: '365Report',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/365/report.vue'));
      });
    },
    name: '365Report',
    meta: {
      title: '365推广统计',
      keepAlive: true
    }
  }, {
    path: '365Tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/365/tab.vue'));
      });
    },
    name: '365Tab',
    meta: {
      title: 'MIKA365',
      keepAlive: true
    }
  }, {
    path: '365PullNew',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/365/pullNew.vue'));
      });
    },
    name: '365PullNew',
    meta: {
      title: '365拉新记录',
      keepAlive: true
    }
  }, {
    path: 'pullNew',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/pullNew.vue'));
      });
    },
    name: 'UserPullNew',
    meta: {
      title: '普通用户拉新',
      keepAlive: true
    }
  }, {
    path: 'kinOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/365/kinOrder.vue'));
      });
    },
    name: 'kinOrder',
    meta: {
      title: '亲友卡记录',
      keepAlive: true
    }
  }, {
    path: 'refund',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/365/refund.vue'));
      });
    },
    name: 'kinOrderRefund',
    meta: {
      title: '买贵包退记录',
      keepAlive: true
    }
  }, {
    path: 'refundDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/365/refundDea.vue'));
      });
    },
    name: 'kinOrderReufundDea',
    meta: {
      title: '买贵包退记录详情',
      isRequest: "no",
      pageList: null
    }
  }, {
    path: 'improve',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/index.vue'));
      });
    },
    name: 'classimprove',
    redirect: '/member/improve/record',
    meta: {
      title: '完善资料'
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/improve/tab.vue'));
        });
      },
      name: 'improveTab',
      meta: {
        title: '奖励领取记录',
        keepAlive: true
      }
    }, {
      path: 'record',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/improve/record.vue'));
        });
      },
      name: 'improveRecord',
      meta: {
        title: '奖励领取记录',
        keepAlive: true,
        activeMenu: '/member/improve/tab'
      }
    }, {
      path: 'setting',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/improve/setting.vue'));
        });
      },
      name: 'improveSetting',
      meta: {
        title: '奖励设置',
        activeMenu: '/member/improve/tab'
      }
    }]
  }]
};
var _default = memberRouter;
exports.default = _default;