var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 轮播图列表 "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticStyle: { "max-width": "100%" },
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: {
                  click: function($event) {
                    return _vm.wPage("/activity/week/bannerAdd")
                  }
                }
              },
              [_vm._v("新增轮播图")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "linkCode",
              label: "页面类型",
              align: "center",
              "mni-width": "100"
            },
            slot: "linkCode",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.linkCode === "4"
                      ? _c("div", [
                          scope.row.appid
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.appIdArr.find(function(item) {
                                        return item.value === scope.row.appid
                                      }).label
                                    ) +
                                    "\n            ：\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.hyperLink) +
                              "\n        "
                          )
                        ])
                      : scope.row.linkCode === "5"
                      ? _c("div", [
                          _vm._v(
                            "\n          web页面：\n          " +
                              _vm._s(scope.row.hyperLink) +
                              "\n        "
                          )
                        ])
                      : scope.row.linkCode
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.urlList.find(function(item) {
                                  return item.value === scope.row.linkCode
                                }).label
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "image",
              label: "banner",
              align: "center",
              width: "120"
            },
            slot: "image",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px" },
                      attrs: {
                        src: _vm.imgSrc + scope.row.image,
                        "preview-src-list": [_vm.imgSrc + scope.row.image]
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "video",
              label: "视频",
              align: "center",
              width: "250"
            },
            slot: "video",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.video && scope.row.video.length > 10
                      ? _c("video", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            autoplay: true,
                            src: scope.row.video,
                            controls: "controls"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "80"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 1
                      ? _c("span", { staticClass: "success" }, [_vm._v("启用")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == -1
                      ? _c("span", { staticClass: "danger" }, [_vm._v("禁用")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "weekTag",
              label: "周期",
              align: "center",
              "mni-width": "100"
            },
            slot: "weekTag",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.weekTag[scope.row.weekTag]) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              fixed: "right",
              align: "center",
              width: "120"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/activity/week/bannerAdd?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确认删除该轮播图？" },
                        on: {
                          confirm: function($event) {
                            return _vm.delBanner(scope.row)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "danger",
                            attrs: {
                              slot: "reference",
                              type: "text",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }