"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAndEditGroupManage = addAndEditGroupManage;
exports.getGroupBuyList = getGroupBuyList;
exports.getInfo = getInfo;

var _request = _interopRequireDefault(require("@/utils/request"));

var _da = _interopRequireDefault(require("element-ui/src/locale/lang/da"));

// 查询团购经理列表
function getGroupBuyList(data) {
  return (0, _request.default)({
    url: "api/groupManage/listGroupManagePage",
    method: 'post',
    data: data
  });
} // 新增或者编辑团购经理


function addAndEditGroupManage(data) {
  return (0, _request.default)({
    url: data.id ? '/api/groupManage/updateGroupManage' : 'api/groupManage/addGroupManage',
    method: 'post',
    data: data
  });
} // 获取详情


function getInfo(id) {
  return (0, _request.default)({
    url: '/api/groupManage/queryGroupManageById/' + id,
    method: 'get'
  });
}