var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tem flex_between" }, [
    _vm.dataItem.length > 0
      ? _c(
          "div",
          { staticClass: "tem_class" },
          _vm._l(_vm.dataItem, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "tem_class_item",
                class: { tem_class_active: _vm.tabIndex == index },
                attrs: { id: "tab" + index },
                on: {
                  click: function($event) {
                    return _vm.changeTab(index)
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(item.dataName) + "\n    ")]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "tem_content" }, [
      _c("img", {
        staticClass: "tem_content_cover",
        attrs: { src: _vm.imgSrc + _vm.dataItem[_vm.tabIndex].dataPath }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }