"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '购物卡充值返利',
      list: [{
        tit: '购物卡充值返利列表',
        txtArr: '<p>购物卡充值返利列表</p>',
        imgPath: require('@/assets/tab/activity/1.png'),
        path: '/activity/cardRebate/list'
      }, {
        tit: '充值返利记录',
        txtArr: '<p>充值返利记录</p>',
        imgPath: require('@/assets/tab/activity/6.png'),
        path: '/activity/cardRebate/order'
      }, {
        tit: '充值购物卡',
        txtArr: '<p>充值购物卡</p>',
        imgPath: require('@/assets/tab/activity/2.png'),
        path: '/activity/cardRebate/rechargeList'
      }, {
        tit: '后台充值',
        txtArr: '<p>充值购物卡</p>',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: '/activity/cardRebate/recharge',
        limit: 'card_erp:recharge'
      }, {
        tit: '礼品领取记录',
        txtArr: '<p>礼品领取记录</p>',
        imgPath: require('@/assets/tab/activity/4.png'),
        path: '/activity/cardRebate/giftOrder'
      }]
    };
  }
};
exports.default = _default;