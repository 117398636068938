var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        {
          staticClass: "row_div",
          staticStyle: { padding: "0 0 20px 0" },
          attrs: { gutter: 24, type: "flex", justify: "space-between" }
        },
        [
          _c("div"),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "max-width": "100%", padding: "7px 5px" },
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-circle-plus-outline"
              },
              on: {
                click: function($event) {
                  return _vm.goAdd("/template/styleAdd?type=class")
                }
              }
            },
            [_vm._v("新增样式类型")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            data: _vm.list,
            border: "",
            "header-cell-class-name": "table_header_cell"
          }
        },
        [
          _c("el-table-column", {
            attrs: { width: "70", align: "center", label: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.$index + 1) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.tabTh, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: item.prop,
                label: item.label,
                align: "center",
                "min-width": item.width
              }
            })
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "100", align: "center", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.styleStatus == 0
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("禁用")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.styleStatus == 1
                      ? _c("span", { staticStyle: { color: "limegreen" } }, [
                          _vm._v("启用")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "100", align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "table_td_btn",
                        on: {
                          click: function($event) {
                            return _vm.goAdd(
                              "/template/styleAdd?type=class&id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }