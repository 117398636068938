"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _flowCoupon = require("@/api/flowCoupon.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      obj: {
        pageNo: 1,
        pageSize: 10,
        mobile: '',
        idNo: ''
      },
      time: [],
      list: [],
      tabTh: [{
        prop: 'ruleCode',
        label: '返券规则编码',
        width: 120
      }, {
        prop: 'ruleName',
        label: '返券规则名称',
        width: 100
      }, {
        prop: 'rangeName',
        label: '返券活动范围名称',
        width: 120
      }, {
        prop: 'ruleStartTime',
        label: '开始时间',
        width: 100
      }, {
        prop: 'ruleEndTime',
        label: '结束时间',
        width: 100
      }],
      total: 0
    };
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
        this.searchTime = [];
      }

      this.openLoading();
      (0, _flowCoupon.listSendCouponRulePage)(this.obj).then(function (res) {
        _this.openLoading().close();

        _this.list = res.data.items;
        _this.total = res.data.totalNum;
      }).catch(function (err) {
        _this.openLoading().close();
      });
    },
    goAdd: function goAdd(url) {
      this.$router.push(url);
    }
  }
};
exports.default = _default;