var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c(
      "div",
      { staticClass: "card_box" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.detailCtmfield, "max-height": "500" }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "date",
                label: "字段名称",
                align: "center",
                width: "120"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { size: "mini" },
                            model: {
                              value: scope.row.tit,
                              callback: function($$v) {
                                _vm.$set(scope.row, "tit", $$v)
                              },
                              expression: "scope.row.tit"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "name", label: "字段详情", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 4 },
                          placeholder: "请输入内容"
                        },
                        model: {
                          value: scope.row.text,
                          callback: function($$v) {
                            _vm.$set(scope.row, "text", $$v)
                          },
                          expression: "scope.row.text"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "排序",
                width: "120",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        attrs: { size: "mini", type: "number" },
                        model: {
                          value: scope.row.sort,
                          callback: function($$v) {
                            _vm.$set(scope.row, "sort", $$v)
                          },
                          expression: "scope.row.sort"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "address",
                label: "编辑",
                width: "120",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.deletedetailCtmfield(scope.$index)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.adddetailCtmfield }
              },
              [_vm._v("添加")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.setdetailCtm }
              },
              [_vm._v("保存")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }