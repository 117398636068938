"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.find-index");

var _api = require("./api.js");

var _api2 = require("../shop/api.js");

var _api3 = require("../store/api.js");

var _api4 = require("../category/api.js");

var _api5 = require("../class/api.js");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    var _self = this;

    return {
      categoryList: [],
      classList: [],
      couponTableShow: false,
      tableData: [],
      pageSize: 10,
      pageNo: 1,
      total: 0,
      count: '',
      action: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
      imgSrc: this.$store.state.user.imgSrc,
      requestData: {
        pageNo: 1,
        pageSize: 10,
        status: 1,
        cardNo: null,
        cardCategoryId: null,
        cardKindsId: null
      },
      userTabTh: [{
        label: '卡号',
        prop: 'cardNo',
        width: 120
      }, {
        label: '卡种名称',
        prop: 'cardKindsName',
        width: 100
      }, {
        label: '卡类型',
        prop: 'cardCategoryName',
        width: 100
      }, {
        label: '卡片面额（元）',
        prop: 'cardLimitPrice',
        width: 100
      }, {
        label: '激活后有效期（年）',
        prop: 'expireTradeYear',
        width: 100
      }, {
        label: '制卡商',
        prop: 'makeFactoryName',
        width: 100
      }, {
        label: '入库时间',
        prop: 'inTime',
        width: 120
      }, {
        label: '状态',
        prop: 'statusDesc',
        width: 50
      }, {
        label: '出入库状态',
        prop: 'childStatusDesc',
        width: 80
      }],
      columns: [{
        slot: 'cardNo',
        label: '卡号',
        prop: 'cardNo'
      }, {
        slot: 'rebatePrice',
        label: '金额',
        prop: 'rebatePrice'
      }, {
        slot: 'operate'
      }],
      storeList: [],
      rules: {
        rebatePrice: [{
          validator: function validator(rule, value, callback) {
            if (value > _self.orderData.orderRebatePrice) {
              callback(new Error("\u8FD4\u5229\u91D1\u989D\u4E0D\u53EF\u5927\u4E8E".concat(_self.orderData.orderRebatePrice, "\u5143")));
            } else {
              callback();
            }
          }
        }],
        priceParamList: [{
          required: true,
          message: '请添加返利卡'
        }],
        'orderParam.remark': [{
          required: true,
          message: '请输入备注'
        }],
        'orderParam.attachment': [{
          required: true,
          message: '请上传附件'
        }]
      },
      ruleForm: {
        rebatePrice: 0,
        orderParam: {
          orderNo: '',
          remark: null,
          attachment: []
        },
        priceParamList: []
      },
      cardNo: null,
      orderAmount: null,
      orderData: {}
    };
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        'Token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    var _this = this;

    (0, _api4.categoryPage)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.categoryList = res.data.records;
    });
    (0, _api5.classPage)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.classList = res.data.records;
    });
    this.getCardList();
    this.ruleForm.orderParam.orderNo = this.$route.query.id;
    (0, _api.getOrderMessage)(this.ruleForm.orderParam.orderNo).then(function (res) {
      _this.orderData = res.data;
    });
  },
  methods: {
    setRowClass: function setRowClass(row) {
      var rowIndex = this.ruleForm.priceParamList.findIndex(function (item) {
        return row.row.cardNo === item.cardNo;
      });
      return rowIndex >= 0 ? 'errActive' : '';
    },
    isSelect: function isSelect(row) {
      var rowIndex = this.ruleForm.priceParamList.findIndex(function (item) {
        return row.cardNo === item.cardNo;
      });
      return rowIndex >= 0;
    },
    delOrder: function delOrder(index) {
      this.ruleForm.priceParamList.splice(index, 1);
    },
    rebatePriceChange: function rebatePriceChange(e) {
      console.log(e);
      var rebatePrice = 0;
      this.ruleForm.priceParamList.forEach(function (item) {
        rebatePrice = rebatePrice + item.rebatePrice;
      });
      this.ruleForm.rebatePrice = rebatePrice;
    },
    rowClick: function rowClick(item) {
      this.cardNo = item.cardNo;
    },
    getCardNo: function getCardNo(scope) {
      this.cardNo = scope.row.cardNo;
      this.count = scope.$index;
      this.couponTableShow = true;
    },
    getCardList: function getCardList() {
      var _this2 = this;

      (0, _api3.getList)(this.requestData).then(function (res) {
        _this2.tableData = res.data.records;
        _this2.total = res.data.count;
      });
    },
    clearCoupon: function clearCoupon() {
      this.cardNo = '';
    },
    affirmAddCoupon: function affirmAddCoupon() {
      this.ruleForm.priceParamList[this.count].cardNo = this.cardNo;
      this.couponTableShow = false;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onSuccess: function onSuccess(res) {
      var _this3 = this;

      this.dialogVisible = true;

      if (res.ret === 1000) {
        this.ruleForm.orderParam.attachment.push(res.data);
      } else if (res.ret === 6000) {
        this.$confirm(res.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this3.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this4 = this;

      this.ruleForm.orderParam.attachment = [];
      fileList.forEach(function (item) {
        _this4.ruleForm.orderParam.attachment.push(item.response.data);
      });
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    addParam: function addParam() {
      this.ruleForm.priceParamList.push({
        cardNo: null,
        rebatePrice: null
      });
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this5.ruleForm));
          data.orderParam.attachment = JSON.stringify(data.orderParam.attachment);
          (0, _api.addCategory)(data).then(function (res) {
            _this5.$router.replace('/card/rebateOrder');

            _this5.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;