var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "departmentDea" }, [
    _c(
      "div",
      { staticClass: "flex_center addHeader" },
      [
        _c(
          "el-menu",
          {
            attrs: { "default-active": _vm.activeIndex, mode: "horizontal" },
            on: { select: _vm.handleSelect }
          },
          [
            _c("el-menu-item", { attrs: { index: "goods_one" } }, [
              _vm._v("一键采集")
            ]),
            _vm._v(" "),
            _c("el-menu-item", { attrs: { index: "goods_info" } }, [
              _vm._v("基本信息")
            ]),
            _vm._v(" "),
            _c("el-menu-item", { attrs: { index: "goods_type" } }, [
              _vm._v("商品属性")
            ]),
            _vm._v(" "),
            _c("el-menu-item", { attrs: { index: "goods_coupon" } }, [
              _vm._v("营销设置")
            ]),
            _vm._v(" "),
            _c("el-menu-item", { attrs: { index: "goods_set" } }, [
              _vm._v("商品设置")
            ])
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { height: "30px" } }),
    _vm._v(" "),
    (_vm.ruleForm.id > 0 && _vm.isDetail) || !_vm.ruleForm.id
      ? _c(
          "div",
          { staticClass: "add_content" },
          [
            _c(
              "WForm",
              {
                ref: "addGoods",
                attrs: {
                  "form-data": _vm.formData,
                  isTitle: false,
                  isSetRules: "",
                  isHttps: "",
                  "rule-form": _vm.ruleForm
                },
                on: {
                  goScrollIntoView: _vm.goScrollIntoView,
                  changeForm: _vm.changeForm,
                  changeCascader: _vm.changeCascader,
                  submitForm: _vm.submitForm
                }
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "getJD" }, slot: "getJD" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "一键采集：" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "form_input",
                            attrs: { placeholder: "仅支持京东" },
                            model: {
                              value: _vm.jdGoodsUrl,
                              callback: function($$v) {
                                _vm.jdGoodsUrl = $$v
                              },
                              expression: "jdGoodsUrl"
                            }
                          },
                          [
                            _c(
                              "template",
                              { slot: "append" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      disabled: !_vm.jdGoodsUrl
                                    },
                                    on: { click: _vm.getJdGoods }
                                  },
                                  [_vm._v("立即获取")]
                                )
                              ],
                              1
                            )
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "form_remark" }, [
                          _vm._v("复制京东商品详情页完整地址")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { attrs: { slot: "selectCover" }, slot: "selectCover" },
                  [
                    _c("el-form-item", {
                      ref: "selectCover",
                      attrs: { label: "", prop: "selectCover" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { attrs: { slot: "goodsSku" }, slot: "goodsSku" },
                  [
                    _vm.ruleForm.isMultipleSku == 1
                      ? _c(
                          "el-form-item",
                          {
                            ref: "goodsSkuDTO",
                            attrs: { label: "商品规格：", prop: "goodsSkuDTO" }
                          },
                          [
                            _vm.changeSpecList.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(_vm.changeSpecList, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticStyle: { "margin-bottom": "10px" }
                                      },
                                      [
                                        _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              closable: "",
                                              type: "primary",
                                              effect: "dark",
                                              "disable-transitions": false
                                            },
                                            on: {
                                              close: function($event) {
                                                return _vm.removeSpecItem(
                                                  item.value,
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.value) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n              ：\n              "
                                        ),
                                        _vm._l(item.list, function(tag) {
                                          return _c(
                                            "el-tag",
                                            {
                                              key: tag,
                                              staticStyle: {
                                                margin: "0 10px 10px 0"
                                              },
                                              attrs: {
                                                closable: "",
                                                "disable-transitions": false
                                              },
                                              on: {
                                                close: function($event) {
                                                  return _vm.handleClose(
                                                    tag,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(tag) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        }),
                                        _vm._v(" "),
                                        item.inputVisible
                                          ? _c("el-input", {
                                              ref: "saveTagInput",
                                              refInFor: true,
                                              staticClass: "input-new-tag",
                                              staticStyle: { width: "200px" },
                                              attrs: { size: "small" },
                                              on: {
                                                blur: function($event) {
                                                  return _vm.handleInputConfirm(
                                                    $event,
                                                    index
                                                  )
                                                }
                                              },
                                              nativeOn: {
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.handleInputConfirm(
                                                    $event,
                                                    index
                                                  )
                                                }
                                              },
                                              model: {
                                                value: _vm.inputValue,
                                                callback: function($$v) {
                                                  _vm.inputValue =
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                },
                                                expression: "inputValue"
                                              }
                                            })
                                          : _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "success",
                                                  size: "mini"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.showInput(index)
                                                  }
                                                }
                                              },
                                              [_vm._v("+ 添加")]
                                            )
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "90px",
                                      label: "批量设置："
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        controls: false,
                                        min: 0,
                                        precision: 2,
                                        placeholder: "原价"
                                      },
                                      model: {
                                        value: _vm.skuForm.skuOriginalPrice,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.skuForm,
                                            "skuOriginalPrice",
                                            $$v
                                          )
                                        },
                                        expression: "skuForm.skuOriginalPrice"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("el-input-number", {
                                      attrs: {
                                        controls: false,
                                        min: 0,
                                        precision: 2,
                                        placeholder: "售价"
                                      },
                                      model: {
                                        value: _vm.skuForm.skuGoodsPrice,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.skuForm,
                                            "skuGoodsPrice",
                                            $$v
                                          )
                                        },
                                        expression: "skuForm.skuGoodsPrice"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("el-input-number", {
                                      attrs: {
                                        controls: false,
                                        min: 0,
                                        precision: 0,
                                        placeholder: "库存"
                                      },
                                      model: {
                                        value: _vm.skuForm.skuStock,
                                        callback: function($$v) {
                                          _vm.$set(_vm.skuForm, "skuStock", $$v)
                                        },
                                        expression: "skuForm.skuStock"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticStyle: { width: "150px" },
                                      attrs: { placeholder: "昂捷编码" },
                                      model: {
                                        value: _vm.skuForm.goodsCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.skuForm,
                                            "goodsCode",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "skuForm.goodsCode"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: { title: "确认批量保存？" },
                                        on: {
                                          confirm: function($event) {
                                            return _vm.setAllSku()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              type: "primary"
                                            },
                                            slot: "reference"
                                          },
                                          [_vm._v("批量保存")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  size: "mini",
                                  data: _vm.ruleForm.goodsSkuDTO,
                                  "max-height": "800px",
                                  border: "",
                                  "header-cell-class-name": "table_header_cell"
                                }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "序号",
                                    type: "index",
                                    align: "center",
                                    width: "60"
                                  }
                                }),
                                _vm._v(" "),
                                _vm._l(_vm.changeSpecList, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-table-column", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      align: "center",
                                      width: "80"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    scope.row.saleProperties[
                                                      index
                                                    ]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "原价",
                                    align: "center",
                                    "min-width": "100"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "goodsSkuDTO." +
                                                  scope.$index +
                                                  ".skuOriginalPrice",
                                                attrs: {
                                                  prop:
                                                    "goodsSkuDTO." +
                                                    scope.$index +
                                                    ".skuOriginalPrice",
                                                  rules:
                                                    _vm.rules.skuOriginalPrice
                                                }
                                              },
                                              [
                                                _c("el-input-number", {
                                                  staticClass:
                                                    "form_grade_number",
                                                  attrs: {
                                                    disabled:
                                                      scope.row.opType == 2,
                                                    controls: false,
                                                    min: 0,
                                                    precision: 2
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .skuOriginalPrice,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "skuOriginalPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.skuOriginalPrice"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "form_remark",
                                                    staticStyle: {
                                                      "margin-left": "4px"
                                                    }
                                                  },
                                                  [_vm._v("元")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2560536739
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "售价",
                                    align: "center",
                                    "min-width": "100"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "goodsSkuDTO." +
                                                  scope.$index +
                                                  ".skuGoodsPrice",
                                                attrs: {
                                                  prop:
                                                    "goodsSkuDTO." +
                                                    scope.$index +
                                                    ".skuGoodsPrice",
                                                  rules: _vm.rules.skuGoodsPrice
                                                }
                                              },
                                              [
                                                _c("el-input-number", {
                                                  staticClass:
                                                    "form_grade_number",
                                                  attrs: {
                                                    disabled:
                                                      scope.row.opType == 2,
                                                    controls: false,
                                                    min: 0,
                                                    max:
                                                      scope.row
                                                        .skuOriginalPrice,
                                                    precision: 2
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.skuGoodsPrice,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "skuGoodsPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.skuGoodsPrice"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "form_remark",
                                                    staticStyle: {
                                                      "margin-left": "4px"
                                                    }
                                                  },
                                                  [_vm._v("元")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    787079480
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "库存",
                                    align: "center",
                                    "min-width": "100"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "goodsSkuDTO." +
                                                  scope.$index +
                                                  ".skuStock",
                                                attrs: {
                                                  prop:
                                                    "goodsSkuDTO." +
                                                    scope.$index +
                                                    ".skuStock",
                                                  rules: _vm.rules.skuStock
                                                }
                                              },
                                              [
                                                _c("el-input-number", {
                                                  staticClass:
                                                    "form_grade_number",
                                                  attrs: {
                                                    disabled:
                                                      scope.row.opType == 2,
                                                    controls: false,
                                                    min: 0,
                                                    precision: 0
                                                  },
                                                  model: {
                                                    value: scope.row.skuStock,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "skuStock",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.skuStock"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    872940645
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "昂捷编码",
                                    align: "center",
                                    "min-width": "100"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "goodsSkuDTO." +
                                                  scope.$index +
                                                  ".goodsCode",
                                                attrs: {
                                                  prop:
                                                    "goodsSkuDTO." +
                                                    scope.$index +
                                                    ".goodsCode",
                                                  rules: _vm.rules.goodsCode
                                                }
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass:
                                                    "form_grade_number",
                                                  attrs: {
                                                    disabled:
                                                      scope.row.opType == 2
                                                  },
                                                  model: {
                                                    value: scope.row.goodsCode,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "goodsCode",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.goodsCode"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    751413144
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "图片",
                                    align: "center",
                                    width: "100"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "goodsSkuDTO." +
                                                  scope.$index +
                                                  ".skuImage",
                                                attrs: {
                                                  prop:
                                                    "goodsSkuDTO." +
                                                    scope.$index +
                                                    ".skuImage",
                                                  rules: _vm.rules.skuImage
                                                }
                                              },
                                              [
                                                _c("add-load", {
                                                  attrs: {
                                                    width: 80,
                                                    height: 80,
                                                    cover: scope.row.skuImage,
                                                    isHttp: ""
                                                  },
                                                  on: {
                                                    setCover: function($event) {
                                                      return _vm.setCover(
                                                        $event,
                                                        scope.$index,
                                                        "skuImage"
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2327332220
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "状态",
                                    align: "center",
                                    width: "80"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            scope.row.opType == 2
                                              ? _c(
                                                  "el-tag",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "danger"
                                                    }
                                                  },
                                                  [_vm._v("已删除")]
                                                )
                                              : _c(
                                                  "el-tag",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "success"
                                                    }
                                                  },
                                                  [_vm._v("正常")]
                                                )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2959272028
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "操作",
                                    align: "center",
                                    fixed: "right",
                                    width: "80"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            scope.row.opType != 2
                                              ? _c(
                                                  "el-popconfirm",
                                                  {
                                                    attrs: {
                                                      title:
                                                        "确认删除规格" +
                                                        scope.row.saleProperties.join(
                                                          ","
                                                        ) +
                                                        "？"
                                                    },
                                                    on: {
                                                      confirm: function(
                                                        $event
                                                      ) {
                                                        return _vm.delSkuItem(
                                                          scope.row,
                                                          scope.$index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticClass: "danger",
                                                        attrs: {
                                                          slot: "reference",
                                                          type: "text",
                                                          size: "mini"
                                                        },
                                                        slot: "reference"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    删除\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "el-popconfirm",
                                                  {
                                                    attrs: {
                                                      title:
                                                        "确认恢复规格" +
                                                        scope.row.saleProperties.join(
                                                          ","
                                                        ) +
                                                        "？"
                                                    },
                                                    on: {
                                                      confirm: function(
                                                        $event
                                                      ) {
                                                        return _vm.delSkuItem(
                                                          scope.row,
                                                          scope.$index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticClass: "success",
                                                        attrs: {
                                                          slot: "reference",
                                                          type: "text",
                                                          size: "mini"
                                                        },
                                                        slot: "reference"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    恢复\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1485424493
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }