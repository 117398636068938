var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "" } }, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("确认优惠券包")]),
    _vm._v(" "),
    _c("div", { staticClass: "gift_dea" }, [
      _c("div", { staticClass: "dea_box" }, [
        _c("p", { staticClass: "dea_box_tit" }, [_vm._v("券包详情")]),
        _vm._v(" "),
        _c("div", { staticClass: "dea_box_border" }, [
          _c("ul", [
            _c("li", { staticClass: "dea_box_li" }, [
              _c("div", { staticClass: "dea_box_li_div" }, [
                _c("p", { staticClass: "dea_box_li_tit" }, [
                  _vm._v("券包名称：")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "dea_box_li_txt" }, [
                  _vm._v(_vm._s(_vm.orientObj.name))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "dea_box_li" }, [
              _c("div", { staticClass: "dea_box_li_div" }, [
                _c("p", { staticClass: "dea_box_li_tit" }, [
                  _vm._v("券包BANNER：")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "dea_box_li_txt" }, [
                  _c("img", {
                    attrs: { src: _vm.imgSrc + _vm.orientObj.mainImage }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "dea_box_li" }, [
              _c(
                "div",
                {
                  staticClass: "dea_box_li_div",
                  staticStyle: { "line-height": "30px" }
                },
                [
                  _c("p", { staticClass: "dea_box_li_tit" }, [
                    _vm._v("券包介绍/文案：")
                  ]),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "dea_box_li_txt",
                    domProps: { innerHTML: _vm._s(_vm.orientObj.remark) }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "dea_box_li" }, [
              _c("div", { staticClass: "dea_box_li_div" }, [
                _c("p", { staticClass: "dea_box_li_tit" }, [
                  _vm._v("优惠券：")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dea_box_li_coupon" },
                  _vm._l(_vm.orientObj.couponIdBags, function(item, index) {
                    return _c(
                      "p",
                      { key: index, staticClass: "dea_box_li_txt" },
                      [
                        _c("span", { staticClass: "coupon_id" }, [
                          _vm._v(_vm._s(item.uuid))
                        ]),
                        _vm._v(
                          "\n                  -" +
                            _vm._s(item.typeName) +
                            "-\n                  "
                        ),
                        _c("span", { staticClass: "coupon_name" }, [
                          _vm._v(_vm._s(item.name))
                        ]),
                        _vm._v(
                          "\n                  -" +
                            _vm._s(item.validTime) +
                            "\n                "
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.next } },
            [_vm._v("确认提交")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }