var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "appliDea" }, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("活动详情")]),
    _vm._v(" "),
    _c("div", { staticClass: "deatil_box" }, [
      _c("p", { staticClass: "deatil_tit" }, [
        _vm._v("\n      活动状态\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c(
          "div",
          { staticClass: "box_step" },
          [
            _c(
              "el-steps",
              { attrs: { active: _vm.active } },
              _vm._l(_vm.step, function(item, index) {
                return _c("el-step", {
                  key: index,
                  attrs: { title: item.tit, description: item.txt }
                })
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "box_btn" }, [
          _c("p", [
            _vm._v("当前状态："),
            _c("span", { staticClass: "color" }, [_vm._v(_vm._s(_vm.status))])
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "activityPullnew:edit",
                          expression: "'activityPullnew:edit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.goEdit }
                    },
                    [_vm._v("修改活动")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "activityPullnew:editAuditStatus",
                          expression: "'activityPullnew:editAuditStatus'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.passAudit(2)
                        }
                      }
                    },
                    [_vm._v("通过审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "activityPullnew:editAuditStatus",
                          expression: "'activityPullnew:editAuditStatus'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.passAudit(3)
                        }
                      }
                    },
                    [_vm._v("撤回审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "activityPullnew:editAuditStatus",
                          expression: "'activityPullnew:editAuditStatus'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.passAudit(4)
                        }
                      }
                    },
                    [_vm._v("撤销审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 2
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "activityPullnew:editAuditStatus",
                          expression: "'activityPullnew:editAuditStatus'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.passAudit(6)
                        }
                      }
                    },
                    [_vm._v("终止活动")]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "appli_tit" }, [_vm._v("\n    活动详情\n  ")]),
    _vm._v(" "),
    _c("div", { staticClass: "appli_box" }, [
      _c("ul", [
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动名称：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.obj.activityName))
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动时间：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(
              _vm._s(_vm.obj.activityStartTime) +
                "至" +
                _vm._s(_vm.obj.activityEndTime)
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动地点：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.obj.activityAddress))
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动BANNER：")]),
          _vm._v(" "),
          _c("div", { staticClass: "li_banner" }, [
            _c("img", { attrs: { src: _vm.imgSrc + _vm.obj.bannerImg } })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("会员机构：")]),
          _vm._v(" "),
          _c("div", { staticClass: "li_institutionNames" }, [
            _c("span", [_vm._v(_vm._s(_vm.institutionNames))])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动详情图：")]),
          _vm._v(" "),
          _c("div", { staticClass: "li_detailImg" }, [
            _c("img", { attrs: { src: _vm.imgSrc + _vm.obj.detailImg } })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("小程序卡片标题：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.obj.shareTitle))
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("小程序卡片图：")]),
          _vm._v(" "),
          _c("div", { staticClass: "li_shareImg" }, [
            _c("img", { attrs: { src: _vm.imgSrc + _vm.obj.shareImg } })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动上线时间：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [_vm._v(_vm._s(_vm.beginTime))])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "appli_tit" }, [_vm._v("\n    活动数据\n  ")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "appli_box" },
      [
        _c("p", { staticClass: "appli_box_tit" }, [_vm._v("邀请者")]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.obj.tableData, border: "" }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "invitePeople",
                label: "邀请人数",
                align: "center",
                width: "180"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "name", label: "获得优惠券", "min-width": "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(scope.row.getCoupons.join("、")) +
                            "\n          "
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "getGrowth",
                label: "获得成长值",
                align: "center",
                width: "140"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "remark", label: "备注", "min-width": "200" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("p", { staticClass: "appli_box_tit" }, [_vm._v("受邀者")]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.obj.tableData1, border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { prop: "name", label: "获得优惠券", "min-width": "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(scope.row.getCoupons.join("、")) +
                            "\n          "
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "getGrowth",
                label: "获得成长值",
                align: "center",
                width: "140"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "remark", label: "备注", "min-width": "200" }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }