"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isPop: false,
      cardList: [],
      ruleForm: {
        "applyId": 0,
        "auditNote": null,
        "auditFlg": null,
        "refundAmount": null,
        refundStoreCardUuid: null
      },
      tableItem: null,
      rules: {
        auditNote: [{
          required: true,
          message: '请输入备注'
        }],
        auditFlg: [{
          required: true,
          message: '请选择是否通过'
        }],
        refundAmount: [{
          required: true,
          message: '请输入退款金额'
        }],
        refundStoreCardUuid: [{
          required: true,
          message: '请选择退款购物卡'
        }]
      },
      columns: [{
        label: '用户昵称',
        prop: 'username'
      }, {
        label: '用户手机号',
        prop: 'userMobile'
      }, {
        label: '付费会员类型',
        prop: 'payMemberTypeName'
      }, {
        label: '购买价格',
        prop: 'buyPrice'
      }, {
        label: '总消费',
        prop: 'consumeTotal'
      }, {
        label: '总节省',
        prop: 'discountTotal'
      }, {
        label: '可退金额',
        prop: 'refundAmount',
        width: 50
      }, {
        label: '实际退款金额',
        prop: 'refundAmountReal'
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status'
      }, {
        label: '申请时间',
        prop: 'applyTimes'
      }, {
        slot: 'dea'
      }],
      searchData: [{
        type: 'input',
        placeholder: '用户手机号',
        value: 'userMobile'
      }, {
        type: 'radio',
        placeholder: '状态',
        value: 'status',
        select: [{
          value: 1,
          label: "待审核"
        }, {
          value: 2,
          label: "审核不通过"
        }, {
          value: 3,
          label: "审核通过"
        }]
      }],
      SRCPREFIX: this.$store.state.user.imgSrc
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/pay-member-type/page', {
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      var selectList = res.data.records.map(function (item) {
        return {
          label: item.payMemberNameShort,
          value: item.id
        };
      });

      _this.searchData.unshift({
        type: 'radio',
        placeholder: '请选择365会员类型',
        value: 'payMemberTypeId',
        select: selectList
      });
    });
  },
  methods: {
    getCardList: function getCardList(e) {
      var _this2 = this;

      this.apiPost('/api/cardStored/listCardStored', {
        status: 2,
        pageNo: 1,
        pageSize: 100,
        name: e,
        type: [1]
      }).then(function (res) {
        _this2.cardList = res.data.items;
      });
    },
    goUpdateStatus: function goUpdateStatus(row) {
      this.tableItem = row;
      this.ruleForm.applyId = row.id; //this.ruleForm.refundAmount = row.refundAmount

      this.isPop = true;
    },
    submitForm: function submitForm(type) {
      var _this3 = this;

      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this3.apiPost('/api/pay-member/discount/refundAudit', _this3.ruleForm).then(function (res) {
            _this3.$refs.wTable.getList();

            _this3.isPop = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;