"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'SelectGoods',
  data: function data() {
    return {
      visible: false,
      columns: [{
        label: '门店名称',
        prop: 'storeCodeStr',
        align: "left",
        width: 100
      }, {
        label: '点位名称',
        prop: 'pointName',
        width: 80
      }, {
        label: '获得积分',
        prop: 'receiveScoreNum',
        width: 100
      }, {
        label: '退还积分',
        prop: 'returnScoreNum',
        width: 80
      }, {
        label: '领取时间',
        prop: 'createTime',
        width: 80
      }]
    };
  },
  props: {
    orderData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    orderList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  methods: {
    open: function open() {
      this.visible = true;
    }
  }
};
exports.default = _default2;