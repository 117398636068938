var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("w-table", {
    attrs: {
      searchData: _vm.searchData,
      mobile: ["mobile"],
      columns: _vm.columns
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }