"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _shopCard = require("@/api/shopCard");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      params: {
        id: undefined,
        name: undefined,
        number: undefined,
        mobile: undefined,
        remark: undefined,
        type: 2
      },
      rules: {
        name: [{
          required: true,
          message: '请输入客户名称',
          trigger: 'blur'
        }],
        number: [{
          required: true,
          message: '请输入身份证号',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.params.id = this.$route.query.customerId;
    this.getCustomer();
  },
  methods: {
    onConfirm: function onConfirm() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          delete _this.params.createTime;
          (0, _shopCard.customerSave)(_this.params).then(function () {
            _this.$message.success("保存成功");

            _this.onCancel();
          });
        }
      });
    },
    onCancel: function onCancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push("/shopCard/customer");
    },
    getCustomer: function getCustomer() {
      var _this2 = this;

      if (!this.params.id) return;
      (0, _shopCard.customerDetail)(this.params.id).then(function (res) {
        _this2.params = res.data;
      });
    }
  }
};
exports.default = _default;