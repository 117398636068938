"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      isDetail: false,
      templateDownload: {
        url: "".concat(process.env.BASE_URL, "xlsx/phone.xlsx"),
        name: '电话名录批量导入模板.xlsx',
        btn: '下载模板'
      },
      form: {
        mobile2: [],
        mobile1: []
      },
      mobileArr: []
    };
  },
  methods: {
    handleCommit: function handleCommit() {
      var _self = this;

      var intersectionSet = _self.form.mobile2.filter(function (e) {
        return !_self.form.mobile1.includes(e);
      });

      this.mobileArr = intersectionSet;
    },
    handleCancel: function handleCancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.back();
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    handleUploadSuccess: function handleUploadSuccess(data) {
      var _phoneItem = [];
      data.results.forEach(function (col) {
        if (col['手机号']) {
          _phoneItem.push(String(col['手机号']));
        }
      });
      this.form.mobile1 = _phoneItem;
    },
    handleUploadSuccess1: function handleUploadSuccess1(data) {
      var _phoneItem = [];
      data.results.forEach(function (col) {
        if (col['手机号']) {
          _phoneItem.push(String(col['手机号']));
        }
      });
      this.form.mobile2 = _phoneItem;
    }
  }
};
exports.default = _default;