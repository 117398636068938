"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon.js");

var _manage = require("@/api/manage.js");

var _face = require("@/api/face.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      couponTableShow: false,
      // 选择优惠券弹框
      userTableShow: false,
      // 选择用户弹框
      sendName: '',
      // 发券名称
      sendStatus: 1,
      // 发券状态
      obj: {
        pageNo: 1,
        pageSize: 10,
        status: 2,
        couponType: ''
      },
      options: [{
        value: -1,
        label: '全部类型'
      }, {
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣券'
      }, {
        value: 3,
        label: '礼品券'
      }, {
        value: 4,
        label: '体验券'
      }, {
        value: 5,
        label: '外部优惠券'
      }],
      userObj: {
        pageNo: 1,
        pageSize: 10
      },
      getRowKeys: function getRowKeys(row) {
        return row.uuid;
      },
      getUserRowKeys: function getUserRowKeys(row) {
        return row.userId;
      },
      total: 0,
      userTotal: 0,
      multipleSelection: [],
      // 选中的优惠卷
      couponArr: [],
      // 选中优惠券编码
      userIds: [],
      // 选中用户ID
      userNames: [],
      // 选中用户昵称
      userAccounts: [],
      // 选中用户账号
      jobNumbers: [],
      // 选中用户工号
      userMobiles: [],
      // 选中用户手机号
      userSelection: [],
      // 选中用户
      userTable: [],
      // 用户列表
      userTabTh: [{
        tit: '所属机构',
        prop: 'deptName'
      }, {
        tit: '用户昵称',
        prop: 'username'
      }, {
        tit: '手机号码',
        prop: 'mobile',
        width: 120
      }, {
        tit: '工号',
        prop: 'jobNumber',
        width: 100
      }, {
        tit: '状态',
        prop: 'status',
        width: 80
      }],
      tableData: [],
      tabTh: [{
        tit: '优惠券编码',
        prop: 'uuid',
        width: 140
      }, {
        tit: '优惠券名称',
        prop: 'name',
        width: 180
      }, {
        tit: '类型',
        prop: 'couponType',
        width: 80
      }, {
        tit: '优惠券有效期',
        prop: 'time',
        width: 180
      },
      /* {
              tit: "发布时间",
              prop: 'createTime',
              width: 180,
              sortable: true
            }, */
      {
        tit: '状态',
        prop: 'statusText',
        width: 90
      }]
    };
  },
  created: function created() {
    this.init();
    this.userInit();
  },
  methods: {
    /*
        关闭弹框
        */
    closePop: function closePop() {
      this.couponTableShow = false;
      this.userTableShow = false;
    },

    /*
        清空优惠券选择
        */
    clearCoupon: function clearCoupon() {
      this.$refs.multipleTable.clearSelection();
    },

    /*
        清空用户选择
        */
    clearUser: function clearUser() {
      this.$refs.multipleUser.clearSelection();
    },

    /*
        保存
        */
    next: function next() {
      var _this = this;

      var data = {
        sendName: this.sendName,
        sendStatus: this.sendStatus,
        userIds: this.userIds,
        userNames: this.userNames,
        userAccounts: this.userAccounts,
        jobNumbers: this.jobNumbers,
        userMobiles: this.userMobiles,
        couponCodes: this.couponArr
      };

      if (this.sendName.trim() === '') {
        this.$message.error('请填写发券名');
      } else if (this.sendStatus === '') {
        this.$message.error('请选择状态');
      } else if (this.couponArr === [] || this.couponArr.length === 0) {
        this.$message.error('请选择发放优惠券');
      } else if (this.userIds === [] || this.userIds.length === 0) {
        this.$message.error('请选择用户');
      } else {
        this.openLoading('努力提交中');
        (0, _face.addSend)(data).then(function (res) {
          _this.openLoading().close();

          _this.$message({
            type: 'success',
            message: '保存成功!'
          });

          _this.$router.go(-1);
        }).catch(function (res) {
          _this.openLoading().close();
        });
      }
    },
    init: function init() {
      var _this2 = this;

      var _self = this;

      (0, _coupon.listCouponPage)(this.obj).then(function (res) {
        var data = res.data.items;
        _this2.total = res.data.totalNum;
        data.filter(function (value, key, arr) {
          value.createTime = value.createTime; // .substring(0, 10)

          if (value.validTimeType === 1) {
            value.time = value.validBeginTime.substring(0, 10) + '至' + value.validEndTime.substring(0, 10);
          } else if (value.validTimeType === 2) {
            value.time = _self.getValidDay(value); //'领取后' + value.validDay + '天内有效'
          }

          if (value.couponType === 1) {
            value.couponType = '代金券';
          } else if (value.couponType === 2) {
            value.couponType = '折扣券';
          } else if (value.couponType === 3) {
            value.couponType = '礼品券';
          } else if (value.couponType === 4) {
            value.couponType = '体验券';
          } else if (value.couponType === 5) {
            value.couponType = '外部优惠券';
          }

          switch (value.status) {
            case 1:
              value.statusText = '待审核';
              break;

            case 2:
              value.statusText = '已审核';
              break;

            case 3:
              value.statusText = '审核撤回';
              break;

            case 4:
              value.statusText = '审核撤销';
              break;

            case 5:
              value.statusText = '已过期';
              break;

            case 6:
              value.statusText = '已终止';
              break;

            case 8:
              value.statusText = '已投放';
              break;

            case 9:
              value.statusText = '投放完成';
              break;
          }
        });
        _this2.tableData = data;
      });
    },

    /*
        清空用户查询
         */
    removeUser: function removeUser() {
      this.userObj = {
        pageNo: 1,
        pageSize: 10
      };
      this.userInit();
    },

    /*
        清空优惠券查询
         */
    removeCoupon: function removeCoupon() {
      this.obj = {
        pageNo: 1,
        pageSize: 10,
        status: 2
      };
      this.init();
    },
    userInit: function userInit() {
      var _this3 = this;

      console.log(this.userObj);
      (0, _manage.listSysUserPage)(this.userObj).then(function (res) {
        console.log(res);
        _this3.userTotal = res.data.totalNum;
        _this3.userTable = res.data.items;

        _this3.userTable.forEach(function (item) {
          if (item.status === 1) {
            item.status = '启用';
          } else {
            item.status = '停用';
          }
        });
      });
    },
    addCoupon: function addCoupon() {
      this.couponTableShow = true;
    },
    addUser: function addUser() {
      this.userTableShow = true;
    },
    affirmAddUser: function affirmAddUser() {
      var _this4 = this;

      this.userIds = [];
      this.userNames = [];
      this.userAccounts = [];
      this.jobNumbers = [];
      this.userMobiles = [];
      this.userSelection.forEach(function (item) {
        if (_this4.userIds.indexOf(item.userId) === -1) {
          _this4.userIds.push(item.userId);

          _this4.userNames.push(item.username);

          _this4.userAccounts.push(item.account);

          _this4.jobNumbers.push(item.jobNumber);

          _this4.userMobiles.push(item.mobile);
        }
      });
      this.userTableShow = false;
    },
    affirmAddCoupon: function affirmAddCoupon() {
      var _this5 = this;

      this.couponArr = [];
      this.multipleSelection.forEach(function (item) {
        if (_this5.couponArr.indexOf(item.uuid) === -1) {
          _this5.couponArr.push(item.uuid);
        }
      });
      this.couponTableShow = false;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectionChangeUser: function selectionChangeUser(val) {
      this.userSelection = val;
    }
  }
};
exports.default = _default;