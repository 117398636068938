"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      storeList: [],
      cardData: {},
      tabIndex: 0,
      activeName: 'first',
      tableData: [],
      select: 1,
      requestData: {
        cardNo: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      columns: [{
        label: '卡号',
        prop: 'cardNo',
        width: 120
      }, {
        label: '订单号',
        prop: 'orderNo',
        width: 100
      }, {
        label: '订单金额（元）',
        prop: 'payMoney',
        width: 100
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 60
      }],
      ruleForm: {
        cardNo: null,
        userName: null,
        phone: null,
        idCard: null
      }
    };
  },
  created: function created() {
    this.ruleForm.cardNo = this.$route.query.id;

    if (this.ruleForm.cardNo) {
      this.goCardInfo();
    }
  },
  methods: {
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this = this;

      (0, _api.tradePage)(this.requestData).then(function (res) {
        _this.tableData = res.data.records;
        _this.requestData.total = res.data.total;
        return;
      });
    },
    goCardInfo: function goCardInfo() {
      var _this2 = this;

      this.cardData = {};
      this.tabIndex = 0;
      this.tableData = []; // magstripeCode

      var data = {};
      data[this.select === 1 ? 'cardNo' : 'magstripeCode'] = this.ruleForm.cardNo;
      (0, _api.getCardInfo)(data).then(function (res) {
        _this2.tabIndex = 1;
        _this2.requestData.cardNo = _this2.ruleForm.cardNo;

        _this2.getList();

        _this2.cardData = res.data || {};
      });
    }
  }
};
exports.default = _default;