"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '签到',
      list: [{
        tit: '连续签到管理',
        txtArr: '<p>签到方案管理</p>',
        imgPath: require('@/assets/tab/activity/1.png'),
        path: '/activity/sign/list'
      }, {
        tit: '日常签到管理',
        txtArr: '<p>签到方案管理</p>',
        imgPath: require('@/assets/tab/activity/6.png'),
        path: '/activity/sign/daily'
      }, {
        tit: '补卡',
        txtArr: '<p>补卡</p>',
        imgPath: require('@/assets/tab/activity/1.png'),
        path: '/activity/sign/repair'
      }, {
        tit: '补卡领取记录',
        txtArr: '<p>补卡领取记录</p>',
        imgPath: require('@/assets/tab/activity/5.png'),
        path: '/activity/sign/repairOrder'
      }, {
        tit: '签到记录',
        txtArr: '<p>签到记录</p>',
        imgPath: require('@/assets/tab/activity/5.png'),
        path: '/activity/sign/order'
      }, {
        tit: '礼品领取记录',
        txtArr: '<p>礼品领取记录</p>',
        imgPath: require('@/assets/tab/activity/5.png'),
        path: '/activity/sign/giftOrder'
      }, {
        tit: '签到补发购物卡',
        txtArr: '<p>签到补发购物卡</p>',
        imgPath: require('@/assets/tab/activity/6.png'),
        path: '/activity/sign/repairGift'
      }]
    };
  }
};
exports.default = _default;