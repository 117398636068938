var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-member-list" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    商品列表\n    "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: { click: _vm.openGoodsPopup }
              },
              [_vm._v("\n        添加商品\n      ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticClass: "row_div",
          staticStyle: { padding: "0 0 10px 0" },
          attrs: { gutter: 10 }
        },
        [
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                staticClass: "input2",
                attrs: { size: "mini", placeholder: "商品名称" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetch("search")
                  }
                },
                model: {
                  value: _vm.searchParams.goodsName,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "goodsName", $$v)
                  },
                  expression: "searchParams.goodsName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                staticClass: "input2",
                attrs: { size: "mini", placeholder: "商品ID" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetch("search")
                  }
                },
                model: {
                  value: _vm.searchParams.goodsId,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "goodsId", $$v)
                  },
                  expression: "searchParams.goodsId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    filterable: "",
                    placeholder: "要客专享"
                  },
                  model: {
                    value: _vm.searchParams.isYaokeGoods,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "isYaokeGoods", $$v)
                    },
                    expression: "searchParams.isYaokeGoods"
                  }
                },
                _vm._l(_vm.statusList, function(s) {
                  return _c("el-option", {
                    key: s.value,
                    attrs: { label: s.label, value: s.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.fetch("search")
                }
              }
            },
            [_vm._v("\n      查询\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "mini", icon: "el-icon-refresh" },
              on: {
                click: function($event) {
                  return _vm.fetch("reset")
                }
              }
            },
            [_vm._v("\n      重置\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: { "padding-bottom": "10px" },
          attrs: { type: "flex", justify: "end" }
        },
        [
          !_vm.isBatch
            ? [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.openBatch }
                  },
                  [_vm._v("\n        批量移除\n      ")]
                )
              ]
            : [
                _c(
                  "el-button",
                  {
                    attrs: { plain: "", size: "mini" },
                    on: { click: _vm.closeBatch }
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-popconfirm",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { title: "确定要移除吗？" },
                    on: { confirm: _vm.batchDelCategoryGoods }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          slot: "reference",
                          type: "primary",
                          size: "mini"
                        },
                        slot: "reference"
                      },
                      [_vm._v("\n          批量移除\n        ")]
                    )
                  ],
                  1
                )
              ]
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            data: _vm.list,
            border: "",
            "header-cell-class-name": "table_header_cell"
          },
          on: { "selection-change": _vm.selectItems }
        },
        [
          _vm.isBatch
            ? _c("el-table-column", {
                attrs: { type: "selection", width: "40" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "70", align: "center", label: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          (_vm.searchParams.page - 1) * _vm.searchParams.limit +
                            scope.$index +
                            1
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.columns, function(column) {
            return _c("el-table-column", {
              key: column.prop,
              attrs: {
                label: column.label,
                prop: column.prop,
                width: column.width,
                formatter: column.formatter,
                align: "center"
              }
            })
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "200",
              align: "center",
              fixed: "right",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "center" } },
                      [
                        !(_vm.searchParams.page === 1 && scope.$index === 0)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.moveCategoryGoods(
                                      scope.row.id,
                                      "TOP"
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n            上移\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !(
                          _vm.searchParams.page === _vm.totalPageNum &&
                          scope.$index === _vm.list.length - 1
                        )
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.moveCategoryGoods(
                                      scope.row.id,
                                      "DOWN"
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n            下移\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-popconfirm",
                          {
                            style: _vm.list.length != 1 && "margin-left: 10px",
                            attrs: { title: "确定要移除吗？" },
                            on: {
                              confirm: function($event) {
                                return _vm.delCategoryGoods([scope.row.id])
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference"
                              },
                              [_vm._v("移除")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.searchParams.page,
          limit: _vm.searchParams.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchParams, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchParams, "limit", $event)
          },
          pagination: _vm.fetch
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.goodsTableShow,
              expression: "goodsTableShow"
            }
          ],
          staticClass: "addCoupon_table"
        },
        [
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-row",
                { staticClass: "row_div", attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        staticClass: "input2",
                        attrs: { placeholder: "商品名称" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.goodsInit($event)
                          }
                        },
                        model: {
                          value: _vm.goodsObj.goodsName,
                          callback: function($$v) {
                            _vm.$set(_vm.goodsObj, "goodsName", $$v)
                          },
                          expression: "goodsObj.goodsName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        staticClass: "input2",
                        attrs: { placeholder: "商品ID" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.goodsInit($event)
                          }
                        },
                        model: {
                          value: _vm.goodsObj.goodsId,
                          callback: function($$v) {
                            _vm.$set(_vm.goodsObj, "goodsId", $$v)
                          },
                          expression: "goodsObj.goodsId"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "要客专享"
                          },
                          model: {
                            value: _vm.goodsObj.isYaokeGoods,
                            callback: function($$v) {
                              _vm.$set(_vm.goodsObj, "isYaokeGoods", $$v)
                            },
                            expression: "goodsObj.isYaokeGoods"
                          }
                        },
                        _vm._l(_vm.statusList, function(s) {
                          return _c("el-option", {
                            key: s.value,
                            attrs: { label: s.label, value: s.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-search",
                            size: "small"
                          },
                          on: { click: _vm.goodsInit }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "info",
                            size: "small",
                            icon: "el-icon-refresh"
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeGoods()
                            }
                          }
                        },
                        [_vm._v("重置")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "multipleUser",
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    "max-height": "500px",
                    "row-key": _vm.getGoodsRowKeys,
                    data: _vm.goodsTable,
                    "tooltip-effect": "dark"
                  },
                  on: { "selection-change": _vm.selectionChangeGoods }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      align: "center",
                      "reserve-selection": true,
                      selectable: _vm.setSelectable
                    }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.columns, function(column) {
                    return _c("el-table-column", {
                      key: column.prop,
                      attrs: {
                        label: column.label,
                        prop: column.prop,
                        width: column.width,
                        formatter: column.formatter,
                        align: "center"
                      }
                    })
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userTotal > 0,
                    expression: "userTotal > 0"
                  }
                ],
                attrs: {
                  total: _vm.userTotal,
                  page: _vm.goodsObj.page,
                  limit: _vm.goodsObj.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.goodsObj, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.goodsObj, "limit", $event)
                  },
                  pagination: _vm.goodsInit
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        size: "small",
                        icon: "el-icon-close"
                      },
                      on: { click: _vm.closeGoodsPopup }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.batchAddCategoryGoods }
                    },
                    [_vm._v("确认添加")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }