"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

var _auth = require("@/utils/auth");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'UploadImage',
  model: {
    prop: 'value',
    event: 'updateValue'
  },
  props: {
    value: {
      type: String,
      required: true
    },
    tip: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: Number,
      default: 2
    },
    //  ["image/jpg","audio/mpeg"]
    fileTypes: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      prefix: this.imgSrc,
      url: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile'
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)('user', ['imgSrc'])), {}, {
    headers: function headers() {
      var token = (0, _auth.getToken)();
      return {
        ContentType: 'multipart/form-data',
        Token: token
      };
    },
    fullSrc: function fullSrc() {
      return this.imgSrc + this.value;
    }
  }),
  methods: {
    handDelete: function handDelete() {},
    handPreview: function handPreview() {},
    handDownload: function handDownload() {},
    handUploadSuccess: function handUploadSuccess(res) {
      this.$emit('updateValue', res.data);
    },
    handUploadBefore: function handUploadBefore(file) {
      console.log(file.type);
      var isFileType = this.fileTypes.length > 0 ? this.fileTypes.includes(file.type) : true;
      var isLimit = file.size / 1024 / 1024 < this.size;

      if (!isFileType) {
        this.$message.error("\u4E0A\u4F20\u6587\u4EF6\u53EA\u80FD\u662F ".concat(this.fileTypes.join('|'), " \u683C\u5F0F!"));
        return isFileType;
      }

      if (!isLimit) {
        this.$message.error("\u4E0A\u4F20\u6587\u4EF6\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7 ".concat(this.size, "MB!"));
        return isFileType;
      }

      return isFileType && isLimit;
    }
  }
};
exports.default = _default2;