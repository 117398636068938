"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _video = _interopRequireDefault(require("@/components/addLoad/video.vue"));

var _axios = _interopRequireDefault(require("axios"));

var _pageUrl = _interopRequireDefault(require("@/utils/pageUrl.json"));

var _regionData = _interopRequireDefault(require("@/utils/regionData.json"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      cover: '',
      selectRgion: [],
      regionForm: {
        select0: [],
        select1: [],
        select2: [],
        select3: [],
        select4: [],
        select5: [],
        select6: []
      },
      checkList: [],
      props: {
        multiple: true,
        checkStrictly: true
      },
      changType: 1,
      isChangePrice: false,
      fileList: [],
      ruleForm: {
        goodsLIst: [{
          'attr': '40Kg/袋',
          'pic': 'https://ymlimg.bodata.cn/web/uploads/image/a5/a5d2cc5eda86f0bcc548370f533b3a60612f5085.png',
          'goods_name': '测试商品勿拍',
          'one_level_price': '22.00',
          'order_detail_id': '2005',
          'order_id': '1726',
          'goods_id': '13972',
          'num': '15',
          'price': '1000.00',
          'send_count': '5'
        }, {
          'attr': '50Kg/袋',
          'pic': 'https://ymlimg.bodata.cn/web/uploads/image/5d/5dce01b57ab87f33081c3c269bea7b17ec8523da.png',
          'goods_name': '测试商品勿拍',
          'one_level_price': '33.00',
          'order_detail_id': '2006',
          'order_id': '1726',
          'goods_id': '13972',
          'num': '8',
          'price': '33.00',
          'send_count': '8'
        }]
      }
    };
  },
  components: {
    Wvideo: _video.default
  },
  created: function created() {
    var arr = [1, 2, 3, 4, 5, 6, 8, 7, 9];
    var num = 0;

    for (var i in arr) {
      for (var j in arr) {
        if (j > i && arr[i] > arr[j]) {
          num++;
        }
      }
    }

    console.log(num); //console.log(pageJson)

    return;
    var json = '[{"key":"room_id","value":""},{"key":"activityId","value":""},{"key":"mch_list","value":"[{\\"mch_id\\":572,\\"goods_list\\":[{\\"goods_id\\":\\"197204\\",\\"num\\":2,\\"room_id\\":\\"\\",\\"ww_share_code\\":\\"\\",\\"is_cycle_goods\\":\\"\\",\\"attr\\":[{\\"attr_group_id\\":8,\\"attr_id\\":73281},{\\"attr_group_id\\":3,\\"attr_id\\":73283}]}]}]"},{"key":"ww_share_code","value":""}]';
    var pareJson = JSON.parse(json);
    pareJson.forEach(function (item) {
      if (item.value) {
        console.log(item.value);
        console.log(JSON.parse(item.value));
      }
    }); // console.log(JSON.stringify(wxOrder).replace(/\n/, ','))
  },
  mounted: function mounted() {},
  methods: {
    setCover: function setCover(e) {
      this.cover = e;
    },
    getGoodsList: function getGoodsList(order_id) {
      var _this = this;

      _axios.default.get('https://mall.xinglico.com/index.php', {
        params: {
          r: 'api/goods/venue-goods/goods-list',
          order_id: order_id
        }
      }).then(function (res) {
        if (res.data.code === 1) {
          _this.goodsLIst = res.data.data;
          _this.isChangePrice = true;
        } else {
          _this.$message.error(res.data.msg);
        }
      }).catch(function () {
        _this.$message.error('链接错误');
      });
    },
    goChangePrice: function goChangePrice() {
      var change_json = this.ruleForm.goodsLIst.map(function (item) {
        return {
          order_detail_id: item.order_detail_id,
          price: item.newPrice
        };
      });

      _axios.default.post('https://mall.xinglico.com/index.php', {
        r: 'api/goods/venue-goods/goods-list',
        change_json: change_json
      }).then(function (res) {});
    },
    goShipments: function goShipments() {
      var list = this.ruleForm.goodsLIst.filter(function (item) {
        return item.batchNum > 0;
      });
      var batch_json = list.map(function (item) {
        return {
          order_id: item.order_id,
          order_detail_id: item.order_detail_id,
          num: item.batchNum
        };
      });

      _axios.default.post('https://mall.xinglico.com/index.php', {
        r: 'api/goods/venue-goods/goods-list',
        batch_json: batch_json
      }).then(function (res) {});
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this2.changType === 1) {
            _this2.goChangePrice();

            return;
          }

          if (_this2.changType === 2) {
            _this2.goShipments();

            return;
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;