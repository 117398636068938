"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _mem = require("@/api/mem.js");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    wTable: _index.default
  },
  data: function data() {
    return {
      companyList: [],
      storeList: [],
      orderStatus: [],
      downloadLoading: false,
      requestData: {
        goodsName: '',
        goodsCode: '',
        companyCode: '',
        storeCode: '',
        classCode: '',
        brandCode: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '商品名称',
        prop: 'goodsName',
        width: 100
      }, {
        label: '商品编码',
        prop: 'goodsCode',
        width: 100
      },
      /* {
        label: '',
        prop: 'discountRate',
        width: 100
      }, */
      {
        slot: 'discountRate',
        prop: 'discountRate',
        label: '折扣'
      }, {
        label: '总部名称',
        prop: 'companyName',
        width: 80
      }, {
        label: '门店',
        prop: 'storeName',
        width: 80
      }, {
        label: '分类编码',
        prop: 'classCode',
        width: 60
      }, {
        label: '品牌',
        prop: 'brandCode',
        width: 60
      }, {
        label: '状态',
        prop: 'validStatus',
        slot: 'validStatus'
        /*  label: '执行状态',
          prop: 'basicStatus',
          width: 100 */

      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companyList = res.data;
    });
    (0, _mem.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    setShort: function setShort(e) {
      var _this2 = this;

      this.requestData.storeCode = '';
      this.companyList.forEach(function (item) {
        if (item.companyCode == e) {
          (0, _mem.listStore)({
            companyId: item.companyId
          }).then(function (res) {
            _this2.storeList = res.data;
          });
        }
      });
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];
        var data = {};

        _this3.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this3.requestData).forEach(function (key) {
          if (_this3.requestData[key]) {
            data[key] = _this3.requestData[key];
          }
        });
        data.pageSize = _this3.requestData.total;
        data.pageNo = 1;

        _this3.openLoading();

        (0, _api.listVippriceBasicGoodsPage)(data).then(function (res) {
          list = res.data.items;
          list.forEach(function (item) {
            item.discountRate = item.discountRate > 0 ? item.discountRate / 10 + '折' : '不打折';
            item.validStatus = item.validStatus === 1 ? '启用' : '停用';
          });

          var data = _this3.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '商品-基础方案'
          });
          _this3.downloadLoading = false;

          _this3.openLoading().close();
        }).catch(function (res) {
          _this3.downloadLoading = false;

          _this3.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this4 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.removeObj(this.requestData);
      }

      (0, _api.listVippriceBasicGoodsPage)(this.requestData).then(function (res) {
        _this4.tableData = res.data.items;
        _this4.requestData.total = res.data.totalNum;
      });
    }
  }
};
exports.default = _default;