"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _self = this;

    return {
      api: '/api/invoiceNoFlowingWater/queryInvoiceAmount',
      queryParams: [{
        type: 'radio',
        placeholder: '请选择主体',
        value: 'companyId',
        select: [{
          label: '贵州星力乐品鲜活商贸有限公司',
          value: 2
        }, {
          label: '遵义星力城乐品鲜活商贸有限公司',
          value: 3
        }, {
          label: '贵州星力城商业管理有限公司',
          value: 4
        }]
      }, {
        type: 'cascaderSearch',
        placeholder: '选择分类',
        value: 'classCode',
        props: {
          lazy: true,
          checkStrictly: true,
          value: 'code',
          label: 'name',
          lazyLoad: function lazyLoad(node, resolve) {
            var value = node.value;

            _self.apiPost('/api/invoiceNoFlowingWater/queryCategroy', {
              classCode: node.level == 0 ? '' : value
            }).then(function (res) {
              resolve(res.data || []);
            }).catch(function () {
              resolve([]);
            });

            return;
            setTimeout(function () {
              var nodes = Array.from({
                length: level + 1
              }).map(function (item) {
                return {
                  value: ++id,
                  label: "\u9009\u9879".concat(id),
                  leaf: level >= 2
                };
              }); // 通过调用resolve将子节点数据返回，通知组件数据加载完成

              resolve(nodes);
            }, 1000);
          }
        }
      }, {
        type: 'input',
        placeholder: '商品编码',
        value: 'productCode'
      }, {
        type: 'input',
        placeholder: '商品名称',
        value: 'productName'
      }],
      columns: [{
        label: '门店编码',
        prop: 'storeCode',
        width: 50
      }, {
        label: '税务编号',
        prop: 'astaxNo',
        width: 80
      }, {
        label: '商品名称',
        prop: 'productName',
        width: 100
      }, {
        label: '商品编码',
        prop: 'productCode',
        width: 50
      }, {
        label: '规格型号',
        prop: 'specifications',
        width: 50
      }, {
        label: '包装含量',
        prop: 'packContent',
        width: 50
      }, {
        label: '总可开票数量',
        prop: 'invoiceNumSurplus',
        width: 50
      }, {
        label: '本次可开票数量',
        prop: 'thisNum',
        width: 50
      }, {
        label: '总可开票金额',
        prop: 'invoiceAmountSurplus',
        width: 50
      }, {
        label: '本次可开票金额',
        prop: 'thisAmount',
        width: 50
      }, {
        label: '税率',
        prop: 'taxRate',
        width: 50
      }]
    };
  },
  methods: {
    getList: function getList() {
      this.$refs.wTable.getList();
    }
  }
};
exports.default = _default;