"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var _options = require("../options");

var _index = _interopRequireDefault(require("../components/CheckInvoice/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    CheckInvoice: _index.default
  },
  data: function data() {
    return {
      api: '/api/invoiceNoFlowingWater/listNoFlowingWaterPage',
      queryParams: [{
        type: 'input',
        placeholder: '订单号/流水号',
        value: 'code'
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime'
      }, {
        type: 'radio',
        placeholder: '发票类型',
        value: 'status',
        select: [{
          label: "蓝票",
          value: 1
        }, {
          label: "红票",
          value: 2
        }]
      }],
      columns: [{
        prop: 'code',
        label: '订单号/流水号',
        width: 160
      }, {
        prop: 'amount',
        label: '开票金额',
        width: 100
      }, // { prop: 'taxAmount', label: '税价合计', width: 100 },
      {
        prop: 'num',
        label: '数量',
        width: 100
      }, {
        prop: 'createTime',
        label: '开票时间',
        width: 180
      }, {
        prop: 'createUserMobile',
        label: '手机号',
        width: 100
      }, {
        slot: 'status',
        prop: 'status',
        label: '票据类型',
        width: 100
      }, {
        prop: 'remark',
        label: '备注',
        width: 180,
        align: "left"
      }, {
        slot: 'actions'
      }]
    };
  },
  methods: {
    getList: function getList() {
      this.$refs.wTable.getList();
    },
    handCheck: function handCheck(row) {
      var _this = this;

      this.apiPost('/api/invoiceNoFlowingWater/updateNoFlowingWater', {
        status: 2,
        id: row.id
      }).then(function (res) {
        _this.$refs.wTable.getList();
      });
    },
    handDetails: function handDetails(row) {
      this.$router.push('/finance/no-transaction-flow/invoice/details?id=' + row.code);
    },
    tableFormatter: function tableFormatter(row, _ref) {
      var property = _ref.property;
      var options = [];
      var value = '';

      if (property == 'type') {
        options = _options.typeOptions;
        value = row.type;
      }

      if (property == 'status') {
        return row.status == 1 ? '蓝票' : "红票";
      }

      if (!value || options.length == 0) return '';
      return options.find(function (item) {
        return item.value == value;
      }).label;
    }
  }
};
exports.default = _default;