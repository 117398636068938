"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _tableList = _interopRequireDefault(require("@/components/tableList"));

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default
  },
  data: function data() {
    return {
      deaPath: '/coupon/couponPut/putDea?id=touch&unid=',
      operation: 2,
      btn: '查看详情',
      btnType: 'primary',
      total: 0,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [
      /* {
        	tit:"方案ID",
        	prop:'uuid'
        },*/
      {
        tit: '方案名称',
        prop: 'name',
        align: 'center',
        width: 250
      }, {
        tit: '方案有效期',
        prop: 'time',
        align: 'center',
        width: 250
      }, {
        tit: '创建时间',
        prop: 'createTime',
        width: 180,
        align: 'center'
      }, {
        tit: '券包数',
        prop: 'couponBagIds',
        align: 'center',
        width: 80
      }, {
        tit: '券数',
        prop: 'couponIds',
        align: 'center',
        width: 80
      }, {
        tit: '投放数量',
        prop: 'putNumber',
        align: 'center',
        width: 100
      }, {
        tit: '状态',
        prop: 'status',
        align: 'center',
        width: 80
      }],
      time: '',
      couponId: '',
      name: '',
      issuer: '',
      options1: [{
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '已审核'
      }, {
        value: 3,
        label: '已撤回'
      }, {
        value: 4,
        label: '已撤销'
      }, {
        value: 6,
        label: '已终止'
      }, {
        value: 9,
        label: '投放完成'
      }],
      status: ''
    };
  },
  watch: {},

  /* destroyed() {
      const initInfo = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        status: this.status,
        name: this.name,
        time: this.time || []
      };
      this.$store.dispatch("buffer/setTouchPutList", initInfo);
    }, */
  activated: function activated() {
    /* if (this.$store.state.buffer.touchPutList.pageNo) {
        const initInfo = this.$store.state.buffer.touchPutList;
        this.pageNo = initInfo.pageNo;
        this.pageSize = initInfo.pageSize;
        this.status = initInfo.status;
        this.name = initInfo.name;
        this.time = initInfo.time;
      } */
    this.init();
  },
  methods: {
    init: function init(e) {
      var _this = this;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      this.time = this.time ? this.time : [];
      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        createBeginTime: this.time[0],
        createEndTime: this.time[1],
        status: this.status,
        name: this.name.trim()
      };
      this.openLoading();
      (0, _coupon.listPutTiggerPage)(data).then(function (res) {
        _this.openLoading().close();

        _this.tableData = res.data.records;

        _this.tableData.forEach(function (value) {
          value.couponIds = JSON.parse(value.couponIds).length;
          value.couponBagIds = JSON.parse(value.couponBagIds).length;
          value.time = value.startTime + '至' + value.endTime; // value.startTime=value.startTime.substring(0, 10)

          if (value.putNumber === 0) {
            value.putNumber = '无限制';
          }

          switch (value.status) {
            case 1:
              value.status = '待审核';
              break;

            case 2:
              value.status = '已审核';
              break;

            case 3:
              value.status = '已撤回';
              break;

            case 4:
              value.status = '已撤销';
              break;

            case 5:
              value.status = '已过期';
              break;

            case 6:
              value.status = '已终止';
              break;

            case 8:
              value.status = '投放中';
              break;

            case 9:
              value.status = '投放完成';
              break;
          }
        });

        _this.total = res.data.total;
      }).catch(function (res) {
        _this.openLoading().close();
      });
    },
    setBtn: function setBtn(index) {
      console.log(index);
      this.$router.push('/coupon/couponPut/putDea?id=touch&unid=' + index.id);
    },
    goAdd: function goAdd() {
      this.$router.push('/coupon/couponPut/addPut?id=touch');
    },
    getList: function getList() {
      console.log('sdfsdf');
    },
    remove: function remove() {
      this.time = [];
      this.name = '';
      this.status = '';
      this.pageNo = 1;
      this.pageSize = 10;
      this.init();
    }
  }
};
exports.default = _default;