var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px", size: "mini" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "页面模板名称：", prop: "tmplateName" } },
                    [
                      _c("el-input", {
                        staticClass: "maxWidth",
                        model: {
                          value: _vm.ruleForm.tmplateName,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "tmplateName", $$v)
                          },
                          expression: "ruleForm.tmplateName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "创建时间：" } }, [
                    _vm._v(_vm._s(_vm.createTime))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "页面模板分类：", prop: "classId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "maxWidth",
                          attrs: {
                            filterable: "",
                            placeholder: "请选择页面模板分类"
                          },
                          model: {
                            value: _vm.ruleForm.classId,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "classId", $$v)
                            },
                            expression: "ruleForm.classId"
                          }
                        },
                        _vm._l(_vm.temClass, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.className, value: item.id }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.goEditTmplate }
                        },
                        [_vm._v("修改模板名称/分类")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "最后修改：" } }, [
                    _vm._v(_vm._s(_vm.modifyTime))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "giftType", "label-width": "0" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: { click: _vm.goAddTemplate }
                },
                [_vm._v("新增栏目")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    "font-size": "12px",
                    color: "#999999"
                  }
                },
                [_vm._v("最多可添加20个栏目")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "template_div" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "dragTable",
                      staticStyle: { "max-width": "690px" },
                      attrs: {
                        size: "mini",
                        data: _vm.templateList,
                        border: "",
                        "row-class-name": _vm.tableRowClassName,
                        "max-height": "600",
                        "cell-style": { padding: 0 }
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "排序",
                          width: "50",
                          align: "center"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "栏目名称",
                          prop: "moduleName",
                          align: "center",
                          width: "100"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.ruleForm.tmplateName,
                          align: "center",
                          width: "400"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pageModel",
                                    staticStyle: {
                                      overflow: "auto",
                                      "max-height": "200px"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editItem(
                                          scope.row,
                                          scope.$index
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("column", { attrs: { data: scope.row } })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "状态", align: "center", width: "50" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.moduleStatus == 2
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("禁用")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.moduleStatus == 1
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "limegreen" } },
                                      [_vm._v("启用")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center", width: "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", { staticClass: "flex_between" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "table_td_btn",
                                      staticStyle: { color: "red" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeItem(
                                            scope.row,
                                            scope.$index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "table_td_btn",
                                      on: {
                                        click: function($event) {
                                          return _vm.editItem(
                                            scope.row,
                                            scope.$index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.tableItemShow
                    ? _c(
                        "div",
                        {
                          staticClass: "template_box",
                          staticStyle: {
                            "max-height": "600px",
                            overflow: "auto"
                          }
                        },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "templateItem",
                              staticClass: "demo-ruleForm",
                              attrs: {
                                model: _vm.templateList[_vm.temObjIndex],
                                "rules-item": _vm.rulesItem,
                                "label-width": "120px",
                                size: "mini"
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "栏目名称：",
                                    prop: "moduleName",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入栏目名称"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "form_input",
                                    model: {
                                      value:
                                        _vm.templateList[_vm.temObjIndex]
                                          .moduleName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.templateList[_vm.temObjIndex],
                                          "moduleName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "templateList[temObjIndex].moduleName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "白名单", prop: "whiteId" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        remote: "",
                                        "reserve-keyword": "",
                                        placeholder: "输入白名单名称搜索~",
                                        "remote-method": _vm.getWhiteList,
                                        "value-key": "id",
                                        clearable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.templateList[_vm.temObjIndex]
                                            .whiteId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.templateList[_vm.temObjIndex],
                                            "whiteId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "templateList[temObjIndex].whiteId"
                                      }
                                    },
                                    _vm._l(_vm.whiteList, function(so) {
                                      return _c("el-option", {
                                        key: so.id,
                                        attrs: {
                                          label: "" + so.name,
                                          value: so.id
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "form_remark" }, [
                                    _vm._v(
                                      "选择白名单后，仅白名单用户可购买；不选白名单则所有用户可购买"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "使用模板：",
                                    prop: "styleCode",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择使用模板"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: { click: _vm.getTem }
                                    },
                                    [_vm._v("选择模板")]
                                  ),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticStyle: {
                                      "max-width": "400px",
                                      "max-height": "200px"
                                    },
                                    attrs: {
                                      src:
                                        _vm.imgSrc +
                                        _vm.templateList[_vm.temObjIndex]
                                          .thumbnailPath
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "栏目状态：",
                                    prop: "moduleStatus",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择栏目状态"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 1 },
                                      model: {
                                        value:
                                          _vm.templateList[_vm.temObjIndex]
                                            .moduleStatus,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.templateList[_vm.temObjIndex],
                                            "moduleStatus",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "templateList[temObjIndex].moduleStatus"
                                      }
                                    },
                                    [_vm._v("启用")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 2 },
                                      model: {
                                        value:
                                          _vm.templateList[_vm.temObjIndex]
                                            .moduleStatus,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.templateList[_vm.temObjIndex],
                                            "moduleStatus",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "templateList[temObjIndex].moduleStatus"
                                      }
                                    },
                                    [_vm._v("禁用")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "是否团购模板：" } },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 1 },
                                      model: {
                                        value:
                                          _vm.templateList[_vm.temObjIndex]
                                            .moduleAttr.isGroupTem,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr,
                                            "isGroupTem",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "templateList[temObjIndex].moduleAttr.isGroupTem"
                                      }
                                    },
                                    [_vm._v("是")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 2 },
                                      model: {
                                        value:
                                          _vm.templateList[_vm.temObjIndex]
                                            .moduleAttr.isGroupTem,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr,
                                            "isGroupTem",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "templateList[temObjIndex].moduleAttr.isGroupTem"
                                      }
                                    },
                                    [_vm._v("否")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "form_remark danger" },
                                    [
                                      _vm._v(
                                        "是团购模板，跳转商品，优惠券，购物卡详情时为联系团购经理，不可直接购买"
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.weekGoodsList.includes(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              )
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "是否周期推荐：" } },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 1 },
                                          model: {
                                            value:
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.isWeekGoods,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].moduleAttr,
                                                "isWeekGoods",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "templateList[temObjIndex].moduleAttr.isWeekGoods"
                                          }
                                        },
                                        [_vm._v("是")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 2 },
                                          model: {
                                            value:
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.isWeekGoods,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].moduleAttr,
                                                "isWeekGoods",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "templateList[temObjIndex].moduleAttr.isWeekGoods"
                                          }
                                        },
                                        [_vm._v("否")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "form_remark danger" },
                                        [
                                          _vm._v(
                                            "周期推荐模板，周三显示周期商品模板，否则显示配置模块"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "模板上外边距：",
                                    prop: "moduleAttr.temMargin",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入模板上间距"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "form_input",
                                    model: {
                                      value:
                                        _vm.templateList[_vm.temObjIndex]
                                          .moduleAttr.temMargin,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.templateList[_vm.temObjIndex]
                                            .moduleAttr,
                                          "temMargin",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "templateList[temObjIndex].moduleAttr.temMargin"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "上下内边距：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "form_input",
                                    model: {
                                      value:
                                        _vm.templateList[_vm.temObjIndex]
                                          .moduleAttr.temPadd,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.templateList[_vm.temObjIndex]
                                            .moduleAttr,
                                          "temPadd",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "templateList[temObjIndex].moduleAttr.temPadd"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.btnWidth.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "图标宽高：",
                                        prop: "moduleAttr.width",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入宽"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "number", max: "750" },
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.width,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "width",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.width"
                                        }
                                      }),
                                      _vm._v(" X\n                "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.height,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "height",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.height"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.imgWH.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "图片宽高：",
                                        prop: "moduleAttr.imgWidth",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入宽"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "number", max: "750" },
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.imgWidth,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "imgWidth",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.imgWidth"
                                        }
                                      }),
                                      _vm._v(" X\n                "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.imgHeight,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "imgHeight",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.imgHeight"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.templateList[_vm.temObjIndex].styleCode ===
                              "video1000"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "视频链接：",
                                        prop: "moduleAttr.videoUrl",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入视频链接"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "form_input",
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.videoUrl,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "videoUrl",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.videoUrl"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.templateList[_vm.temObjIndex].styleCode ===
                              "video1000"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "视频封面图：",
                                        prop: "moduleAttr.videoImg",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请上传视频封面图"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("add-load", {
                                        staticClass: "img_list",
                                        attrs: {
                                          width: 100,
                                          height: 100,
                                          cover:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.videoImg,
                                          isRemove: ""
                                        },
                                        on: {
                                          removeImg: _vm.removeVideoUrl,
                                          setCover: _vm.setVideoUrl
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.imgWH.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "图片间距：" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "form_input",
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.marginLeft,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "marginLeft",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.marginLeft"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "背景色：",
                                    prop: "moduleAttr.bgColor"
                                  }
                                },
                                [
                                  _c("el-color-picker", {
                                    model: {
                                      value:
                                        _vm.templateList[_vm.temObjIndex]
                                          .moduleAttr.bgColor,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.templateList[_vm.temObjIndex]
                                            .moduleAttr,
                                          "bgColor",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "templateList[temObjIndex].moduleAttr.bgColor"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.templateList[
                                _vm.temObjIndex
                              ].styleCode.indexOf("text") >= 0 &&
                              !_vm.tichText.includes(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              )
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "字体颜色：",
                                        prop: "moduleAttr.textColor"
                                      }
                                    },
                                    [
                                      _c("el-color-picker", {
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.textColor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "textColor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.textColor"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.templateList[
                                _vm.temObjIndex
                              ].styleCode.indexOf("text") >= 0 &&
                              !_vm.tichText.includes(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              )
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "字体大小：",
                                        prop: "moduleAttr.textSize"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "form_input",
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.textSize,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "textSize",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.textSize"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.templateList[
                                _vm.temObjIndex
                              ].styleCode.indexOf("text") >= 0 &&
                              !_vm.tichText.includes(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              )
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "是否加粗：",
                                        prop: "moduleAttr.isBold"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value:
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.isBold,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].moduleAttr,
                                                "isBold",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "templateList[temObjIndex].moduleAttr.isBold"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("加粗")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 2 } },
                                            [_vm._v("不加粗")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.templateList[
                                _vm.temObjIndex
                              ].styleCode.indexOf("text") >= 0
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "模板宽度：",
                                        prop: "moduleAttr.textWidth"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "form_input",
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.textWidth,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "textWidth",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.textWidth"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.tichText.includes(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              )
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.textLabel[
                                            _vm.templateList[_vm.temObjIndex]
                                              .styleCode
                                          ],
                                        prop: "moduleAttr.text"
                                      }
                                    },
                                    [
                                      _c("Tinymce", {
                                        staticStyle: {
                                          "max-width": "80%",
                                          "min-width": "600px"
                                        },
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.text,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "text",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.text"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.textData.includes(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              )
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.textLabel[
                                            _vm.templateList[_vm.temObjIndex]
                                              .styleCode
                                          ],
                                        prop: "moduleAttr.text"
                                      }
                                    },
                                    [
                                      _c("Tinymce", {
                                        staticStyle: {
                                          "max-width": "80%",
                                          "min-width": "600px"
                                        },
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.text,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "text",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.text"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.text2Data.includes(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              )
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.text2Label[
                                            _vm.templateList[_vm.temObjIndex]
                                              .styleCode
                                          ],
                                        prop: "moduleAttr.text"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "form_input",
                                        attrs: {
                                          type: "textarea",
                                          rows: 2,
                                          placeholder: "请输入内容"
                                        },
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.text2,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "text2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.text2"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.templateList[_vm.temObjIndex].styleCode ==
                              "image1014"
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "图片上边距：" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "form_input",
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.imgMarginTop,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "imgMarginTop",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.imgMarginTop"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.templateList[_vm.temObjIndex].styleCode ==
                              "button1006"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "moduleAttr.chunkName",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入按钮文字"
                                          }
                                        ],
                                        label: "按钮文字："
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "form_input",
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.chunkName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "chunkName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.chunkName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.goodsList.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0 ||
                              _vm.couponStyleList.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0 ||
                              _vm.legouGoods.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "栏目类型：",
                                        prop: "moduleAttr.columnGoodsType"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value:
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.columnGoodsType,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].moduleAttr,
                                                "columnGoodsType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "templateList[temObjIndex].moduleAttr.columnGoodsType"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 2 } },
                                            [_vm._v("默认模板")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("底部栏目模板")]
                                          ),
                                          _vm._v(" "),
                                          [
                                            "coupon_com1014",
                                            "product_com1016",
                                            "legouGoods1005"
                                          ].includes(
                                            _vm.templateList[_vm.temObjIndex]
                                              .styleCode
                                          )
                                            ? _c(
                                                "el-radio",
                                                { attrs: { label: 3 } },
                                                [_vm._v("种草轮播模板")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "form_remark" }, [
                                        _vm._v(
                                          "底部栏目模板则在页面底部显示（"
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _vm._v(
                                              "不在底部栏目模板中显示，不要勾选"
                                            )
                                          ]
                                        ),
                                        _vm._v("）")
                                      ]),
                                      _vm._v(" "),
                                      [
                                        "coupon_com1014",
                                        "product_com1016",
                                        "legouGoods1005"
                                      ].includes(
                                        _vm.templateList[_vm.temObjIndex]
                                          .styleCode
                                      )
                                        ? _c(
                                            "p",
                                            { staticClass: "form_remark" },
                                            [
                                              _vm._v("种草轮播模板（"),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: { color: "red" }
                                                },
                                                [
                                                  _vm._v(
                                                    "合并种草模板，轮播图显示，显示再第一个栏目的位置"
                                                  )
                                                ]
                                              ),
                                              _vm._v("）")
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.temImg1List.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.imgText[
                                            _vm.templateList[_vm.temObjIndex]
                                              .styleCode
                                          ],
                                        prop: "moduleAttr"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "flex" }, [
                                        !_vm.unTemImg1.includes(
                                          _vm.templateList[_vm.temObjIndex]
                                            .styleCode
                                        )
                                          ? _c(
                                              "div",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.setIndex()
                                                  }
                                                }
                                              },
                                              [
                                                _c("add-load", {
                                                  staticClass: "img_list",
                                                  attrs: {
                                                    width: 100,
                                                    height: 100,
                                                    cover:
                                                      _vm.templateList[
                                                        _vm.temObjIndex
                                                      ].moduleAttr.img,
                                                    isRemove: ""
                                                  },
                                                  on: {
                                                    removeImg: function(
                                                      $event
                                                    ) {
                                                      return _vm.removeChunkName(
                                                        $event,
                                                        "img"
                                                      )
                                                    },
                                                    setCover: function($event) {
                                                      return _vm.setChunkName(
                                                        $event,
                                                        "img"
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.imgTextNoPath.includes(
                                          _vm.templateList[_vm.temObjIndex]
                                            .styleCode
                                        )
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex_column flex_between",
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      clearable: "",
                                                      filterable: "",
                                                      placeholder: "链接类型"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.dataChange(
                                                          $event,
                                                          "img"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].moduleAttr.imgCode,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.templateList[
                                                            _vm.temObjIndex
                                                          ].moduleAttr,
                                                          "imgCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "templateList[temObjIndex].moduleAttr.imgCode"
                                                    }
                                                  },
                                                  _vm._l(_vm.urlList, function(
                                                    item,
                                                    index
                                                  ) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value
                                                      }
                                                    })
                                                  }),
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].moduleAttr.imgCode == 4 ||
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].moduleAttr.imgCode == 5
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].moduleAttr.imgCode ==
                                                        4
                                                          ? _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  "label-width":
                                                                    "0",
                                                                  prop:
                                                                    "moduleAttr.imgId",
                                                                  rules: [
                                                                    {
                                                                      required: true,
                                                                      message:
                                                                        "请选择小程序"
                                                                    }
                                                                  ]
                                                                }
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "对方小程序APPid"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .templateList[
                                                                        _vm
                                                                          .temObjIndex
                                                                      ]
                                                                        .moduleAttr
                                                                        .imgId,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .templateList[
                                                                          _vm
                                                                            .temObjIndex
                                                                        ]
                                                                          .moduleAttr,
                                                                        "imgId",
                                                                        typeof $$v ===
                                                                          "string"
                                                                          ? $$v.trim()
                                                                          : $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "templateList[temObjIndex].moduleAttr.imgId"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "0",
                                                              prop:
                                                                "moduleAttr.imgPath",
                                                              rules: [
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "请输入跳转路径"
                                                                }
                                                              ]
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "跳转路径"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .templateList[
                                                                    _vm
                                                                      .temObjIndex
                                                                  ].moduleAttr
                                                                    .imgPath,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .templateList[
                                                                      _vm
                                                                        .temObjIndex
                                                                    ]
                                                                      .moduleAttr,
                                                                    "imgPath",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "templateList[temObjIndex].moduleAttr.imgPath"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.urlTypeList.indexOf(
                                                  _vm.templateList[
                                                    _vm.temObjIndex
                                                  ].moduleAttr.imgCode
                                                ) >= 0
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          "label-width": "0",
                                                          prop:
                                                            "moduleAttr.imgId",
                                                          rules: [
                                                            {
                                                              required: true,
                                                              message:
                                                                "请输入编码"
                                                            }
                                                          ]
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex_between"
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  _vm
                                                                    .templateList[
                                                                    _vm
                                                                      .temObjIndex
                                                                  ].moduleAttr
                                                                    .imgCode ==
                                                                  "tem"
                                                                    ? "请填写页面管理中的页面"
                                                                    : "选择链接编码"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .templateList[
                                                                    _vm
                                                                      .temObjIndex
                                                                  ].moduleAttr
                                                                    .imgId,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .templateList[
                                                                      _vm
                                                                        .temObjIndex
                                                                    ]
                                                                      .moduleAttr,
                                                                    "imgId",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "templateList[temObjIndex].moduleAttr.imgId"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _vm._v(" "),
                                      _vm.imgRemark[
                                        _vm.templateList[_vm.temObjIndex]
                                          .styleCode
                                      ]
                                        ? _c(
                                            "div",
                                            { staticClass: "form_remark" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.imgRemark[
                                                    _vm.templateList[
                                                      _vm.temObjIndex
                                                    ].styleCode
                                                  ]
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.temImg1Width.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "模板宽高：",
                                        prop: "moduleAttr.img1Width",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入宽"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "number", max: "750" },
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.img1Width,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "img1Width",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.img1Width"
                                        }
                                      }),
                                      _vm._v(" X\n                "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value:
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.img1height,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr,
                                              "img1height",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "templateList[temObjIndex].moduleAttr.img1height"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.couponText.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "头部数据：",
                                        prop: "moduleAttr"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "flex" }, [
                                        _vm.boxImg.indexOf(
                                          _vm.templateList[_vm.temObjIndex]
                                            .styleCode
                                        ) >= 0
                                          ? _c(
                                              "div",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.setIndex()
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "0",
                                                      prop:
                                                        "moduleAttr.chunkName",
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message: "请上传图片"
                                                        }
                                                      ]
                                                    }
                                                  },
                                                  [
                                                    _c("add-load", {
                                                      staticClass: "img_list",
                                                      attrs: {
                                                        width: 100,
                                                        height: 100,
                                                        cover:
                                                          _vm.templateList[
                                                            _vm.temObjIndex
                                                          ].moduleAttr
                                                            .chunkName,
                                                        isRemove: ""
                                                      },
                                                      on: {
                                                        removeImg: function(
                                                          $event
                                                        ) {
                                                          return _vm.removeChunkName(
                                                            $event,
                                                            "img"
                                                          )
                                                        },
                                                        setCover: function(
                                                          $event
                                                        ) {
                                                          return _vm.setChunkName(
                                                            $event,
                                                            "chunkName"
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex_column flex_between",
                                            staticStyle: {
                                              "margin-left": "10px"
                                            }
                                          },
                                          [
                                            _vm.boxImg.indexOf(
                                              _vm.templateList[_vm.temObjIndex]
                                                .styleCode
                                            ) < 0
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "0",
                                                      prop:
                                                        "moduleAttr.chunkName",
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message: "请输入标题"
                                                        }
                                                      ]
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass: "form_input",
                                                      attrs: {
                                                        placeholder: "标题名称"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.templateList[
                                                            _vm.temObjIndex
                                                          ].moduleAttr
                                                            .chunkName,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.templateList[
                                                              _vm.temObjIndex
                                                            ].moduleAttr,
                                                            "chunkName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "templateList[temObjIndex].moduleAttr.chunkName"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.boxTitle.indexOf(
                                              _vm.templateList[_vm.temObjIndex]
                                                .styleCode
                                            ) >= 0
                                              ? _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder: "链接类型11"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.dataChange(
                                                          $event,
                                                          "chunkName"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].moduleAttr.linkCode,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.templateList[
                                                            _vm.temObjIndex
                                                          ].moduleAttr,
                                                          "linkCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "templateList[temObjIndex].moduleAttr.linkCode"
                                                    }
                                                  },
                                                  _vm._l(_vm.urlList, function(
                                                    item,
                                                    index
                                                  ) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value
                                                      }
                                                    })
                                                  }),
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.linkCode == 4 ||
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.linkCode == 5
                                              ? _c(
                                                  "div",
                                                  [
                                                    _vm.templateList[
                                                      _vm.temObjIndex
                                                    ].moduleAttr.linkCode == 4
                                                      ? _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "0",
                                                              prop:
                                                                "moduleAttr.linkId",
                                                              rules: [
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "请选择小程序"
                                                                }
                                                              ]
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "对方小程序APPid"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .templateList[
                                                                    _vm
                                                                      .temObjIndex
                                                                  ].moduleAttr
                                                                    .linkId,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .templateList[
                                                                      _vm
                                                                        .temObjIndex
                                                                    ]
                                                                      .moduleAttr,
                                                                    "linkId",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "templateList[temObjIndex].moduleAttr.linkId"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          "label-width": "0",
                                                          prop:
                                                            "moduleAttr.linkPath",
                                                          rules: [
                                                            {
                                                              required: true,
                                                              message:
                                                                "请输入跳转路径"
                                                            }
                                                          ]
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "跳转路径"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.templateList[
                                                                _vm.temObjIndex
                                                              ].moduleAttr
                                                                .linkPath,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .templateList[
                                                                  _vm
                                                                    .temObjIndex
                                                                ].moduleAttr,
                                                                "linkPath",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "templateList[temObjIndex].moduleAttr.linkPath"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.urlTypeList.indexOf(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.linkCode
                                            ) >= 0
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "0",
                                                      prop: "moduleAttr.linkId",
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message: "请选择编码"
                                                        }
                                                      ]
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex_between"
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              _vm.templateList[
                                                                _vm.temObjIndex
                                                              ].moduleAttr
                                                                .linkCode ==
                                                              "tem"
                                                                ? "请填写页面管理中的页面"
                                                                : "选择链接编码"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.templateList[
                                                                _vm.temObjIndex
                                                              ].moduleAttr
                                                                .linkId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .templateList[
                                                                  _vm
                                                                    .temObjIndex
                                                                ].moduleAttr,
                                                                "linkId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "templateList[temObjIndex].moduleAttr.linkId"
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _vm.noSelect.indexOf(
                                                          _vm.templateList[
                                                            _vm.temObjIndex
                                                          ].moduleAttr.linkCode
                                                        ) < 0
                                                          ? _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  size: "mini",
                                                                  type:
                                                                    "primary"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.getLinkId()
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("选择")]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              "product_com1011" ===
                              _vm.templateList[_vm.temObjIndex].styleCode
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "圆角：",
                                        prop: "moduleAttr.radio",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入圆角"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 1 },
                                          model: {
                                            value:
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.radio,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].moduleAttr,
                                                "radio",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "templateList[temObjIndex].moduleAttr.radio"
                                          }
                                        },
                                        [_vm._v("启用")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 2 },
                                          model: {
                                            value:
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.radio,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].moduleAttr,
                                                "radio",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "templateList[temObjIndex].moduleAttr.radio"
                                          }
                                        },
                                        [_vm._v("禁用")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              "product_com1011" ===
                              _vm.templateList[_vm.temObjIndex].styleCode
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "开卡礼：",
                                        prop: "moduleAttr.openGift",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入圆角"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 1 },
                                          model: {
                                            value:
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.openGift,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].moduleAttr,
                                                "openGift",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "templateList[temObjIndex].moduleAttr.openGift"
                                          }
                                        },
                                        [_vm._v("是")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 0 },
                                          model: {
                                            value:
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.openGift,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].moduleAttr,
                                                "openGift",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "templateList[temObjIndex].moduleAttr.openGift"
                                          }
                                        },
                                        [_vm._v("否")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.moreList.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "查看更多：",
                                        prop: "moduleAttr"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            clearable: "",
                                            placeholder: "链接类型",
                                            filterable: ""
                                          },
                                          on: { change: _vm.moreChange },
                                          model: {
                                            value:
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.moreCode,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].moduleAttr,
                                                "moreCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "templateList[temObjIndex].moduleAttr.moreCode"
                                          }
                                        },
                                        _vm._l(_vm.urlList, function(
                                          item,
                                          index
                                        ) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.label,
                                              value: item.value
                                            }
                                          })
                                        }),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.templateList[_vm.temObjIndex]
                                        .moduleAttr.moreCode == 4 ||
                                      _vm.templateList[_vm.temObjIndex]
                                        .moduleAttr.moreCode == 5
                                        ? _c(
                                            "div",
                                            [
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.moreCode == 4
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        "label-width": "0",
                                                        prop:
                                                          "moduleAttr.moreId",
                                                        rules: [
                                                          {
                                                            required: true,
                                                            message:
                                                              "请选择小程序"
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          placeholder:
                                                            "对方小程序APPid"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.templateList[
                                                              _vm.temObjIndex
                                                            ].moduleAttr.moreId,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.templateList[
                                                                _vm.temObjIndex
                                                              ].moduleAttr,
                                                              "moreId",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "templateList[temObjIndex].moduleAttr.moreId"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    "label-width": "0",
                                                    prop: "moduleAttr.morePath",
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message:
                                                          "请输入跳转路径"
                                                      }
                                                    ]
                                                  }
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "跳转路径"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].moduleAttr.morePath,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.templateList[
                                                            _vm.temObjIndex
                                                          ].moduleAttr,
                                                          "morePath",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "templateList[temObjIndex].moduleAttr.morePath"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.urlTypeList.indexOf(
                                        _vm.templateList[_vm.temObjIndex]
                                          .moduleAttr.moreCode
                                      ) >= 0
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                "label-width": "0",
                                                prop: "moduleAttr.moreId",
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "链接编码"
                                                  }
                                                ]
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "flex_between" },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].moduleAttr.moreCode ==
                                                        "tem"
                                                          ? "请填写页面管理中的页面"
                                                          : "选择链接编码"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].moduleAttr.moreId,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.templateList[
                                                            _vm.temObjIndex
                                                          ].moduleAttr,
                                                          "moreId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "templateList[temObjIndex].moduleAttr.moreId"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.templateList[_vm.temObjIndex]
                                        .moduleAttr.moreCode == "tem" ||
                                      _vm.templateList[_vm.temObjIndex]
                                        .moduleAttr.moreCode == "storeTem" ||
                                      _vm.templateList[_vm.temObjIndex]
                                        .moduleAttr.moreCode == "auto"
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                "label-width": "0",
                                                prop:
                                                  "moduleAttr.morePageTitle",
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "请填写页面标题"
                                                  }
                                                ]
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请填写页面标题"
                                                },
                                                model: {
                                                  value:
                                                    _vm.templateList[
                                                      _vm.temObjIndex
                                                    ].moduleAttr.morePageTitle,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.templateList[
                                                        _vm.temObjIndex
                                                      ].moduleAttr,
                                                      "morePageTitle",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "templateList[temObjIndex].moduleAttr.morePageTitle"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.goodsList.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0 ||
                              _vm.couponStyleList.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0 ||
                              _vm.cardList.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0 ||
                              _vm.legouGoods.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0 ||
                              _vm.legouPtGoods.indexOf(
                                _vm.templateList[_vm.temObjIndex].styleCode
                              ) >= 0
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "数据项：",
                                        prop: "couponArr"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "max-height": "300px",
                                            overflow: "auto",
                                            padding: "10px",
                                            "border-radius": "10px",
                                            border: "1px solid #CCCCCC"
                                          }
                                        },
                                        [
                                          _c(
                                            "draggable",
                                            {
                                              staticClass: "tem_list   flex ",
                                              staticStyle: {
                                                "flex-wrap": "wrap"
                                              },
                                              attrs: {
                                                "force-fallback": true,
                                                list:
                                                  _vm.templateList[
                                                    _vm.temObjIndex
                                                  ].moduleAttr.dataItem,
                                                handle: ".dargBtn",
                                                animation: 1000,
                                                filter: ".undraggable"
                                              }
                                            },
                                            _vm._l(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.dataItem,
                                              function(row, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "flex_column dargBtn flex_center goodsItem",
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                      margin: "0 4px 5px 0",
                                                      "font-size": "10px"
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "goodsItem_close el-icon-circle-close",
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                        color: "red"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.delPageTemGoods(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    row.linkCode ===
                                                    "legouGoods"
                                                      ? _c("img", {
                                                          staticStyle: {
                                                            width: "50px",
                                                            height: "50px"
                                                          },
                                                          attrs: {
                                                            slot: "reference",
                                                            src: row.pic_url
                                                          },
                                                          slot: "reference"
                                                        })
                                                      : _c("img", {
                                                          staticStyle: {
                                                            width: "50px",
                                                            height: "50px"
                                                          },
                                                          attrs: {
                                                            slot: "reference",
                                                            src:
                                                              _vm.imgSrc +
                                                              row.cover
                                                          },
                                                          slot: "reference"
                                                        }),
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(row.linkId) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.templateList[_vm.temObjIndex]
                                              .moduleAttr.dataItem,
                                            function(row, index) {
                                              return _c(
                                                "span",
                                                { key: index },
                                                [
                                                  _vm._v(
                                                    _vm._s(row.linkId) + ","
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary"
                                          },
                                          on: { click: _vm.getCoupon }
                                        },
                                        [_vm._v("选择")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm.lavantActivity.indexOf(
                                    _vm.templateList[_vm.temObjIndex].styleCode
                                  ) >= 0
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "选择活动" } },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.getLavantActivity(
                                                    "svipActivityDetail"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "选择活动预约\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.getLavantActivity(
                                                    "svipServiceDetail"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "选择服务预约\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.getLavantActivity(
                                                    "svipSpaceDetail"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("选择场地预约")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "已选择活动" } },
                                        [
                                          _c(
                                            "draggable",
                                            {
                                              staticClass: "tem_list   flex ",
                                              staticStyle: {
                                                "flex-wrap": "wrap"
                                              },
                                              attrs: {
                                                "force-fallback": true,
                                                list:
                                                  _vm.templateList[
                                                    _vm.temObjIndex
                                                  ].moduleAttr.dataItem,
                                                handle: ".dargBtn",
                                                animation: 1000,
                                                filter: ".undraggable"
                                              }
                                            },
                                            _vm._l(
                                              _vm.templateList[_vm.temObjIndex]
                                                .moduleAttr.dataItem,
                                              function(row, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "flex_column dargBtn flex_center goodsItem",
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                      margin: "0 10px 5px 0",
                                                      "font-size": "10px"
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "goodsItem_close el-icon-circle-close",
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                        color: "red"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.delPageTemGoods(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "50px",
                                                        height: "50px"
                                                      },
                                                      attrs: {
                                                        slot: "reference",
                                                        src:
                                                          _vm.imgSrc + row.pic
                                                      },
                                                      slot: "reference"
                                                    }),
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(row.name) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : !_vm.unTemItem.includes(
                                    _vm.templateList[_vm.temObjIndex].styleCode
                                  )
                                ? _c(
                                    "div",
                                    [
                                      _vm.rotation.indexOf(
                                        _vm.templateList[_vm.temObjIndex]
                                          .styleCode
                                      ) >= 0
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "宽度",
                                                prop: "moduleAttr.width",
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "轮播图宽度"
                                                  }
                                                ]
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "form_input",
                                                attrs: {
                                                  placeholder: "轮播图宽度"
                                                },
                                                model: {
                                                  value:
                                                    _vm.templateList[
                                                      _vm.temObjIndex
                                                    ].moduleAttr.width,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.templateList[
                                                        _vm.temObjIndex
                                                      ].moduleAttr,
                                                      "width",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "templateList[temObjIndex].moduleAttr.width"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.rotation.indexOf(
                                        _vm.templateList[_vm.temObjIndex]
                                          .styleCode
                                      ) >= 0
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "高度",
                                                prop: "moduleAttr.height",
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "轮播图高度"
                                                  }
                                                ]
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "form_input",
                                                attrs: {
                                                  placeholder: "轮播图高度"
                                                },
                                                model: {
                                                  value:
                                                    _vm.templateList[
                                                      _vm.temObjIndex
                                                    ].moduleAttr.height,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.templateList[
                                                        _vm.temObjIndex
                                                      ].moduleAttr,
                                                      "height",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "templateList[temObjIndex].moduleAttr.height"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.rotation.indexOf(
                                        _vm.templateList[_vm.temObjIndex]
                                          .styleCode
                                      ) >= 0
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "指示点样式",
                                                prop: "moduleAttr.moduleType",
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "指示点样式"
                                                  }
                                                ]
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio-group",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.templateList[
                                                        _vm.temObjIndex
                                                      ].moduleAttr.moduleType,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].moduleAttr,
                                                        "moduleType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "templateList[temObjIndex].moduleAttr.moduleType"
                                                  }
                                                },
                                                _vm._l(_vm.iconIdxs, function(
                                                  index
                                                ) {
                                                  return _c(
                                                    "el-radio",
                                                    {
                                                      key: index,
                                                      attrs: { label: index }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          width: "200px",
                                                          display:
                                                            "inline-block"
                                                        },
                                                        attrs: {
                                                          src: require("@/assets/rotation/" +
                                                            index +
                                                            ".jpg")
                                                        }
                                                      })
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.functionTemText.includes(
                                        _vm.templateList[_vm.temObjIndex]
                                          .styleCode
                                      )
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "选中文字颜色",
                                                    prop:
                                                      "moduleAttr.activeColor",
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "选择颜色"
                                                      }
                                                    ]
                                                  }
                                                },
                                                [
                                                  _c("el-color-picker", {
                                                    model: {
                                                      value:
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].moduleAttr
                                                          .activeColor,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.templateList[
                                                            _vm.temObjIndex
                                                          ].moduleAttr,
                                                          "activeColor",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "templateList[temObjIndex].moduleAttr.activeColor"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "form_remark"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "选中的标题文字颜色"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "选中背景颜色",
                                                    prop:
                                                      "moduleAttr.activeColor",
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "选择颜色"
                                                      }
                                                    ]
                                                  }
                                                },
                                                [
                                                  _c("el-color-picker", {
                                                    model: {
                                                      value:
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].moduleAttr
                                                          .activeBgColor,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.templateList[
                                                            _vm.temObjIndex
                                                          ].moduleAttr,
                                                          "activeBgColor",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "templateList[temObjIndex].moduleAttr.activeBgColor"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "form_remark"
                                                    },
                                                    [_vm._v("选中标题背景颜色")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.templateList[_vm.temObjIndex]
                                          .moduleAttr.dataItem,
                                        function(item, index) {
                                          return _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                label:
                                                  "数据项" + (index + 1) + "：",
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "请填写数据项"
                                                  }
                                                ]
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "flex" },
                                                [
                                                  !_vm.isUnBtnImage.includes(
                                                    _vm.templateList[
                                                      _vm.temObjIndex
                                                    ].styleCode
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.setIndex(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0",
                                                                prop:
                                                                  "moduleAttr.dataItem[" +
                                                                  index +
                                                                  "].dataPath",
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请上传图片"
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _c("add-load", {
                                                                staticClass:
                                                                  "img_list",
                                                                attrs: {
                                                                  width: 100,
                                                                  height: 100,
                                                                  cover:
                                                                    item.dataPath,
                                                                  isRemove: ""
                                                                },
                                                                on: {
                                                                  removeImg: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeCover(
                                                                      $event,
                                                                      index
                                                                    )
                                                                  },
                                                                  setCover:
                                                                    _vm.setCover
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex_column flex_between",
                                                      staticStyle: {
                                                        "margin-left": "10px"
                                                      }
                                                    },
                                                    [
                                                      _vm.functionTemText.includes(
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].styleCode
                                                      )
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0",
                                                                prop:
                                                                  "moduleAttr.dataItem[" +
                                                                  index +
                                                                  "].dataPath",
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请输入标题"
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "请输入标题"
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.dataPath,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "dataPath",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.dataPath"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.functionTemText.includes(
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].styleCode
                                                      )
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0",
                                                                prop:
                                                                  "moduleAttr.dataItem[" +
                                                                  index +
                                                                  "].linkCode",
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请输入模板页面编码"
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "请输入模板页面编码"
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.linkCode,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "linkCode",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.linkCode"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%"
                                                              },
                                                              attrs: {
                                                                clearable: "",
                                                                filterable: "",
                                                                placeholder:
                                                                  "链接类型"
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.dataItemChange(
                                                                    $event,
                                                                    index
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  item.linkCode,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "linkCode",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.linkCode"
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.urlList,
                                                              function(
                                                                item,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: index,
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      value:
                                                                        item.value
                                                                    }
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                      _vm._v(" "),
                                                      item.linkCode == 4 ||
                                                      item.linkCode == 5
                                                        ? _c(
                                                            "div",
                                                            [
                                                              item.linkCode == 4
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        "label-width":
                                                                          "0",
                                                                        prop:
                                                                          "moduleAttr.dataItem[" +
                                                                          index +
                                                                          "].linkId",
                                                                        rules: [
                                                                          {
                                                                            required: true,
                                                                            message:
                                                                              "请选择小程序"
                                                                          }
                                                                        ]
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          attrs: {
                                                                            placeholder:
                                                                              "对方小程序APPid"
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              item.linkId,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "linkId",
                                                                                typeof $$v ===
                                                                                  "string"
                                                                                  ? $$v.trim()
                                                                                  : $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "item.linkId"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    "label-width":
                                                                      "0",
                                                                    prop:
                                                                      "moduleAttr.dataItem[" +
                                                                      index +
                                                                      "].linkPath"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      attrs: {
                                                                        placeholder:
                                                                          "跳转路径"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.linkPath,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "linkPath",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.linkPath"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.urlTypeList.indexOf(
                                                        item.linkCode
                                                      ) >= 0
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0",
                                                                prop:
                                                                  "moduleAttr.dataItem[" +
                                                                  index +
                                                                  "].linkId",
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "选择链接编码"
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex_between"
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      attrs: {
                                                                        placeholder:
                                                                          item.linkCode ==
                                                                            "tem" ||
                                                                          item.linkCode ==
                                                                            "storeTem" ||
                                                                          item.linkCode ==
                                                                            "auto" ||
                                                                          item.linkCode ==
                                                                            "legouGoods"
                                                                            ? "请填写页面"
                                                                            : "选择链接编码"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.linkId,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "linkId",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.linkId"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm.noSelect.indexOf(
                                                                    item.linkCode
                                                                  ) < 0
                                                                    ? _c(
                                                                        "el-button",
                                                                        {
                                                                          attrs: {
                                                                            size:
                                                                              "mini",
                                                                            type:
                                                                              "primary"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.getCoupon(
                                                                                index
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "选择"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.btnNameList.indexOf(
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].styleCode
                                                      ) >= 0
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0",
                                                                prop:
                                                                  "moduleAttr.dataItem[" +
                                                                  index +
                                                                  "].dataName",
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请填写标题"
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      attrs: {
                                                                        placeholder:
                                                                          "标题"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.dataName,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "dataName",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.dataName"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.btnRemarkList.indexOf(
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].styleCode
                                                      ) >= 0
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      attrs: {
                                                                        placeholder:
                                                                          "请填写描述"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.dataRemark,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "dataRemark",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.dataRemark"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.templateList[
                                                        _vm.temObjIndex
                                                      ].styleCode.indexOf(
                                                        "rotation"
                                                      ) >= 0
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "视频地址"
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.videoUrl,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "videoUrl",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.videoUrl"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.rotation.indexOf(
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].styleCode
                                                      ) >= 0 ||
                                                      _vm.templateList[
                                                        _vm.temObjIndex
                                                      ].styleCode.indexOf(
                                                        "image1"
                                                      ) >= 0
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "排序"
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.sort,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "sort",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.sort"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.styleList.indexOf(
                                                        _vm.templateList[
                                                          _vm.temObjIndex
                                                        ].styleCode
                                                      ) == "-1"
                                                        ? _c(
                                                            "el-popconfirm",
                                                            {
                                                              attrs: {
                                                                title:
                                                                  "确认删除该数据？"
                                                              },
                                                              on: {
                                                                confirm: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteList(
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    slot:
                                                                      "reference",
                                                                    type:
                                                                      "danger",
                                                                    size: "mini"
                                                                  },
                                                                  slot:
                                                                    "reference"
                                                                },
                                                                [_vm._v("删除")]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _vm.templateList[_vm.temObjIndex]
                                        .styleCode
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop: "giftName"
                                              }
                                            },
                                            [
                                              _vm.styleList.indexOf(
                                                _vm.templateList[
                                                  _vm.temObjIndex
                                                ].styleCode
                                              ) == "-1"
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "mini",
                                                        type: "primary",
                                                        icon:
                                                          "el-icon-circle-plus-outline"
                                                      },
                                                      on: {
                                                        click: _vm.goAddTemList
                                                      }
                                                    },
                                                    [_vm._v(" 新增数据 ")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "操作：" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeItem(
                                            _vm.templateList[_vm.temObjIndex],
                                            _vm.temObjIndex
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除模板\n                ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.submitForm("templateItem")
                                        }
                                      }
                                    },
                                    [_vm._v("保存栏目")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.newList.length > 1
            ? _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.setListSort }
                    },
                    [_vm._v("保存排序")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.temShow
        ? _c("div", { staticClass: "fixed_center" }, [
            _c(
              "div",
              { staticClass: "pop_box" },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex_between",
                    staticStyle: { "align-items": "center" }
                  },
                  [
                    _c("div", { staticClass: "pop_title" }, [
                      _vm._v("选择组件模板")
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-circle-close",
                      staticStyle: { "font-size": "28px", color: "#aaaaaa" },
                      on: {
                        click: function($event) {
                          _vm.temShow = false
                        }
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("el-divider"),
                _vm._v(" "),
                _c("div", { staticClass: "pop_content flex" }, [
                  _c(
                    "div",
                    { staticClass: "nav" },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "rgba(0, 0, 0, 0.698)"
                          }
                        },
                        [_vm._v("组件模板分类：")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.temList, function(item, index) {
                        return _c(
                          "el-button",
                          {
                            key: index,
                            staticStyle: {
                              width: "100px",
                              "text-align": "center",
                              margin: "0",
                              "margin-top": "10px"
                            },
                            attrs: {
                              type: "primary",
                              plain: _vm.temId != item.id,
                              size: "mini"
                            },
                            on: {
                              click: function($event) {
                                return _vm.setTemList(item)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.styleName) +
                                "\n            "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "template flex" },
                    _vm._l(_vm.temItemlist, function(item, index) {
                      return item.styleStatus == 1
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item flex_column",
                              class:
                                item.styleCode == _vm.styleCode
                                  ? "tem_active"
                                  : "",
                              on: {
                                click: function($event) {
                                  return _vm.setTemItem(item)
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "cover",
                                attrs: { src: _vm.imgSrc + item.thumbnailPath }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "label" }, [
                                _vm._v(
                                  _vm._s(item.styleName) +
                                    _vm._s(item.styleCode)
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("el-divider"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex_center" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "120px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.setTem }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.couponShows
        ? _c("coupon", {
            ref: "couponPop",
            attrs: { type: _vm.couponType },
            on: {
              setCoupons: _vm.setCoupons,
              closeCouponPop: _vm.closeCouponPop
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("couponPop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: _vm.popType, "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }