"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '抽奖',
      list: [{
        tit: '抽奖活动管理',
        txtArr: '<p>抽奖活动管理</p>',
        imgPath: require('@/assets/tab/activity/5.png'),
        path: '/activity/lottery/list'
      }, {
        tit: '抽奖规则',
        txtArr: '<p>抽奖规则配置</p>',
        imgPath: require('@/assets/tab/activity/5.png'),
        path: '/activity/lottery/rule'
      }, {
        tit: '抽奖记录',
        txtArr: '<p>历史抽奖记录</p>    ',
        imgPath: require('@/assets/tab/activity/6.png'),
        path: '/activity/lottery/report'
      }]
    };
  }
};
exports.default = _default;