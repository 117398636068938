"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      tableList: [],
      columns: [{
        label: '门店',
        prop: 'storeName',
        align: "left",
        width: 200
      }, {
        label: '点位',
        prop: 'pointName',
        width: 80
      }, {
        label: '打卡人数',
        prop: 'totalNum',
        width: 100
      }, {
        label: '优惠券赠送数量',
        prop: 'couponNum',
        width: 100
      }, {
        label: '总领取积分',
        prop: 'totalReceiveScoreNum',
        width: 80
      }],
      searchData: [{
        type: 'radio',
        placeholder: '状态',
        value: 'status',
        select: [{
          label: '启用',
          value: 1
        }, {
          label: '禁用',
          value: -1
        }]
      }, {
        type: 'input',
        span: 4,
        placeholder: '规则名称',
        value: 'ruleName'
      }, {
        type: 'input',
        span: 4,
        placeholder: '门店编码',
        value: 'storeCode'
      }]
    };
  },
  created: function created() {
    var _this = this;

    var id = this.$route.query.id || '';
    this.apiPost('/api/scoreGive/queryScoreGivePointDetailsById/' + id).then(function (res) {
      _this.tableList = res.data;
    });
  },
  methods: {}
};
exports.default = _default;