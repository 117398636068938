"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '制卡计划',
      list: [{
        tit: '卡类型',
        txtArr: '<p>制卡计划-类型</p>    ',
        imgPath: require('@/assets/tab/1.png'),
        path: '/card/categoryList'
      }, {
        tit: '卡种类',
        txtArr: '<p>制卡计划-种类</p>',
        imgPath: require('@/assets/tab/2.png'),
        path: '/card/classList'
      }, {
        tit: '卡计划列表',
        txtArr: '<p>抽奖活动管理、抽奖活动记录</p>',
        imgPath: require('@/assets/tab/3.png'),
        path: '/card/planList'
      }, {
        tit: '卡信息',
        txtArr: '<p>制卡计划-卡信息</p>',
        imgPath: require('@/assets/tab/10.png'),
        path: '/card/infoList'
      }, {
        tit: '出库入库记录',
        txtArr: '<p>制卡计划-出库入库</p>',
        imgPath: require('@/assets/tab/4.png'),
        path: '/card/inOutList'
      }, {
        tit: '门店库存',
        txtArr: '<p>制卡计划-卡管理</p>',
        imgPath: require('@/assets/tab/5.png'),
        path: '/card/store'
      }, {
        tit: '卡销售列表',
        txtArr: '<p>制卡计划-卡销售</p>',
        imgPath: require('@/assets/tab/6.png'),
        path: '/card/shop'
      }, {
        tit: '支付方式',
        txtArr: '<p>制卡计划-支付方式</p>',
        imgPath: require('@/assets/tab/9.png'),
        path: '/card/payType'
      }, {
        tit: '返利列表',
        txtArr: '<p>制卡计划-返利列表</p>',
        imgPath: require('@/assets/tab/11.png'),
        path: '/card/rebate'
      }, {
        tit: '返利订单',
        txtArr: '<p>制卡计划-返利订单</p>',
        imgPath: require('@/assets/tab/11.png'),
        path: '/card/rebateOrder'
      }, {
        tit: '充值记录',
        txtArr: '<p>制卡计划-充值记录</p>',
        imgPath: require('@/assets/tab/12.png'),
        path: '/card/recharge'
      }, {
        tit: '退款记录',
        txtArr: '<p>制卡计划-退款记录</p>',
        imgPath: require('@/assets/tab/18.png'),
        path: '/card/refund'
      }]
    };
  }
};
exports.default = _default;