"use strict";

var _interopRequireWildcard = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("@/api/svip/index.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var R = _interopRequireWildcard(require("ramda"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      searchParams: {
        pageNo: 1,
        pageSize: 10,
        svipName: '',
        managerName: '',
        date: null
      },
      total: 0
    };
  },
  computed: {
    columns: function columns() {
      return [{
        label: '评价客户',
        prop: 'svipName'
      }, {
        label: '被评伙伴',
        prop: 'managerName'
      }, {
        label: '亲和力',
        prop: 'affinityScore'
      }, {
        label: '服务态度',
        prop: 'attitudeScore'
      }, {
        label: '专业知识',
        prop: 'technicalScore'
      }, {
        label: '时尚敏锐度',
        prop: 'acuityScore'
      }, {
        label: '评价内容',
        prop: 'content'
      }, {
        label: '评价时间',
        prop: 'createTime'
      }, {
        label: '最新修改时间',
        prop: 'updateTime'
      }];
    }
  },
  created: function created() {
    this.fetch();
  },
  methods: {
    fetch: function fetch(type) {
      var _this = this;

      if (type === 'search') {
        this.searchParams.pageNo = 1;
        this.searchParams.pageSize = 10;
      }

      if (type === 'reset') {
        this.removeObj(this.searchParams);
      }

      var date = this.searchParams.date;
      var params = R.omit(['date'], this.searchParams);

      if (date && date.length > 0) {
        params['createTimeStartDay'] = date[0];
        params['createTimeEndDay'] = date[1];
      }

      (0, _index.getPageManagerEvaluationList)(params).then(function (res) {
        _this.total = res.data.totalNum;
        _this.list = res.data.items;
      });
    },
    goto: function goto(url) {
      this.$router.push(url);
    }
  }
};
exports.default = _default;