"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '支付通道ID',
        prop: 'cfgId',
        width: 80
      }, {
        label: '支付通道名称',
        prop: 'cfgName',
        width: 80
      }, {
        slot: 'cfgType'
      }, {
        slot: 'limitPay'
      }, {
        label: '门店或设备编码',
        prop: 'refNo',
        width: 80
      }, {
        slot: 'refType'
      }, {
        label: '开始时间',
        prop: 'startTime',
        width: 80
      }, {
        label: '结束时间',
        prop: 'endTime',
        width: 80
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'radio',
        span: 4,
        placeholder: '关联类型',
        value: 'refType',
        select: [{
          label: '门店',
          value: 1
        }, {
          label: '设备',
          value: 2
        }]
      }, {
        type: 'input',
        span: 4,
        placeholder: '门店编码/设备号',
        value: 'refNo'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '通道类型',
        value: 'cfgType',
        select: [{
          label: '主通道',
          value: 1
        }, {
          label: '活动通道',
          value: 2
        }, {
          label: '补录通道',
          value: 3
        }, {
          label: '银行虚拟券',
          value: 4
        }]
      }, {
        type: 'radio',
        span: 4,
        placeholder: '可支付方式',
        value: 'limitPay',
        select: [{
          label: '微信',
          value: 2
        }, {
          label: '支付宝',
          value: 3
        }, {
          label: '云闪付',
          value: 6
        }, {
          label: '微信支付宝',
          value: 23
        }, {
          label: '微信云闪付',
          value: 26
        }, {
          label: '微信支付宝云闪付',
          value: 236
        }]
      }]
    };
  },
  mounted: function mounted() {
    var storeId = this.$route.query.storeId;

    if (storeId) {
      this.$refs.wTable.setRequestValue('refNo', storeId);
    }

    this.$refs.wTable.getList();
  },
  methods: {}
};
exports.default = _default;