"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.regexp.match");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.function.name");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _editor = _interopRequireDefault(require("@/components/editor"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _user = require("@/api/user");

var _deposit = require("@/api/deposit");

var _mem = require("@/api/mem");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    editor: _editor.default,
    Tinymce: _Tinymce.default,
    UploadExcelComponent: _index.default
  },
  data: function data() {
    var _self = this;

    return {
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.obj.effStartTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      addGoods: null,
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/cardGoodsIds.xlsx"),
      selectEffMembers: [],
      defaultProps: {
        children: 'childNodes',
        label: function label(a, b) {
          return a.name + '   (' + a.code + ')';
        },
        disabled: function disabled(data, node) {
          if (node.level === 1) {
            return true;
          }
        }
      },
      inputVisible: false,
      inputValue: '',
      rules: {
        validTimeType: [{
          required: true,
          message: '请选择有效期类型'
        }],
        effStartTime: [{
          required: true,
          message: '请选择开始时间'
        }],
        effEndTime: [{
          required: true,
          message: '请选择结束时间'
        }],
        validDay: [{
          required: true,
          message: '请输入有效期天数'
        }],
        ifRechargeAmount: [{
          required: true,
          message: '请选择是否可输入'
        }],
        album: [{
          required: true,
          message: '请上传储值卡卡面'
        }],
        name: [{
          required: true,
          message: '请输入卡名称'
        }],
        classId: [{
          required: true,
          message: '请选择卡分类'
        }],
        cover: [{
          required: true,
          message: '请上传卡封面'
        }],
        description: [{
          required: true,
          message: '请输入一句话文案'
        }],
        number: [{
          required: true,
          message: '请输入发行数量'
        }],
        rechargeRmount: [{
          required: true,
          message: '请输入可充值金额'
        }],
        scopeType: [{
          required: true,
          message: '请选择适用范围'
        }],
        remark: [{
          required: true,
          message: '请输入卡说明'
        }],
        institutionIds: [{
          required: true,
          message: '请选择机构'
        }],
        cardValue: [{
          required: true,
          message: '请输入卡面值'
        }],
        category: [{
          required: true,
          message: '请选择购物卡 回传分类'
        }],
        ifDonation: [{
          required: true,
          message: '请选择是否支持转赠'
        }]
      },
      obj: {
        goodsIds: [],
        category: null,
        //卡回传分类
        id: '',
        //	主键（编辑标识）
        code: '',
        //	卡号(线上无卡号)
        name: '',
        //		卡名称
        classId: '',
        //		卡分类
        type: 1,
        //		卡类型(0:未知、1:线上、2:线下)
        cover: '',
        //		卡封面
        description: '',
        //			一句话文案
        number: '',
        //			发行数量
        rechargeRmount: [],
        //			可充值金额
        ifRechargeAmount: '',
        //			可输入其他金额(1:是 -1：否)
        scopeType: '',
        //			适用范围(1:全部可用 、2:部分可用)
        remark: '',
        //			卡说明
        memberIds: [],
        //			会员卡id
        institutionIds: [],
        //				机构id
        cardValue: 0,
        //				卡面值
        album: [],
        // 储值卡 卡面
        ifDonation: '',
        validTimeType: 0,
        effStartTime: null,
        effEndTime: null,
        validDay: null
      },
      title: '新增储值卡',
      id: '',
      options: []
    };
  },
  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    cardTypeChange: function cardTypeChange(e) {
      if (e === 7) {
        this.obj.scopeType = 1;
      }

      console.log(e);
    },
    delGoods: function delGoods(item, index) {
      var _self = this;

      this.$confirm("\u786E\u8BA4\u79FB\u9664\u5546\u54C1\u7801".concat(item, ", \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _self.obj.goodsIds.splice(index, 1);
      });
    },
    goAddGoods: function goAddGoods() {
      if (this.obj.goodsIds.includes(this.addGoods)) return;
      this.obj.goodsIds.push(this.addGoods);
    },
    handleDownload: function handleDownload() {
      var Timestamp = new Date().getTime();
      var tableData = this.obj.goodsIds.map(function (item) {
        return {
          goodId: item
        };
      });
      this.getHandleDownload(['goodId'], ['goodId'], tableData, this.obj.name + Timestamp);
    },
    handleSuccess: function handleSuccess(_ref) {
      var _this = this;

      var results = _ref.results,
          header = _ref.header;
      this.obj.goodsIds = [];
      console.log(results);
      results.forEach(function (data) {
        if (data.goodId) {
          _this.obj.goodsIds.push(String(data.goodId).trim());
        }
      });
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    setAlbum: function setAlbum(e, index) {
      if (index >= this.obj.album.length) {
        this.obj.album.push({
          image: e
        });
      } else {
        this.obj.album[index].image = e;
      }
    },
    delAlbum: function delAlbum(index) {
      this.obj.album.splice(index, 1);
    },
    showInput: function showInput() {
      var _this2 = this;

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this2.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.obj.rechargeRmount.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    handleClose: function handleClose(tag) {
      this.obj.rechargeRmount.splice(this.obj.rechargeRmount.indexOf(tag), 1);
    },
    handleClick: function handleClick(data, checked, node) {
      var arrNode = this.$refs.tree.getCheckedNodes();
      console.log(arrNode);
      var parentIdArr = [];
      var childNodes = [];
      arrNode.forEach(function (value) {
        if (parentIdArr.indexOf(value.parentId) === -1) {
          parentIdArr.push(value.parentId);
          childNodes.push([]);
        }
      });
      arrNode.forEach(function (value) {
        var dd = parentIdArr.indexOf(value.parentId);
        childNodes[dd].push(value.code);
      });
      this.obj.memberIds = parentIdArr;
      this.obj.institutionIds = childNodes;
    },
    init: function init() {
      var _this3 = this;

      this.id = Number(this.$route.query.id);
      (0, _deposit.listCardStoredClass)().then(function (res) {
        _this3.options = res.data;
      });
      (0, _user.listMemberCardInstitutionInfoNodes)().then(function (res) {
        _this3.selectEffMembers = res.data;
      });

      if (this.id) {
        this.title = '编辑储值卡';
        (0, _deposit.getCardStoredById)(this.id).then(function (res) {
          res.data.rechargeRmount = JSON.parse(res.data.rechargeAmount);

          if (res.data.scopeType === 2) {
            res.data.institutionIds = res.data.institutionIds.match(/[{0-9}]+/g);
          } else {
            res.data.institutionIds = [];
          }

          res.data.goodsIds = res.data.goodsIds && res.data.goodsIds != 'null' ? JSON.parse(res.data.goodsIds) : [];
          Object.keys(_this3.obj).forEach(function (key) {
            Object.keys(res.data).forEach(function (e) {
              if (e === key) {
                _this3.obj[key] = res.data[key];
              }
            });
          });
        });
      }
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      if (this.obj.scopeType == 2) {
        this.handleClick();
      } else {
        this.obj.institutionIds = [];
        this.obj.memberIds = [];
      }

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.openLoading();

          if (_this4.obj.id) {
            (0, _deposit.editCardStored)(_this4.obj).then(function (res) {
              _this4.openLoading().close();

              _this4.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this4.$router.go(-1);
            }).catch(function (err) {
              _this4.openLoading().close();
            });
          } else {
            (0, _deposit.addCardStored)(_this4.obj).then(function (res) {
              _this4.openLoading().close();

              _this4.$router.go(-1);

              _this4.$message({
                type: 'success',
                message: '保存成功!'
              });
            }).catch(function (err) {
              _this4.openLoading().close();
            });
          }
        } else {
          return false;
        }
      });
    },
    next: function next() {
      var _this5 = this;

      var self = this;

      if (this.scopeType == '2') {
        var arrNode = this.$refs.tree.getCheckedNodes(true);
        var parentIdArr = [];
        var childNodes = [];
        arrNode.forEach(function (value) {
          if (parentIdArr.indexOf(value.parentId) == '-1') {
            parentIdArr.push(value.parentId);
            childNodes.push([]);
          }
        });
        arrNode.forEach(function (value) {
          var dd = parentIdArr.indexOf(value.parentId);
          childNodes[dd].push(value.id);
        });
        this.effMembers = parentIdArr;
        this.institutionIds = childNodes;
      } else {
        this.effMembers = [];
      }

      if (!this.value) {
        this.$message.error('请选择储值卡分类');
        return;
      } else if (!this.name) {
        this.$message.error('请填写储值卡名称');
        return;
      } else if (!this.description) {
        this.$message.error('请填写一句话文案');
        return;
      } else if (this.number === '') {
        this.$message.error('请填写发行数量');
        return;
      } else if (!this.scopeType) {
        this.$message.error('请选择适用范围');
        return;
      } else if (this.scopeType == '2' && this.effMembers.length < 1) {
        this.$message.error('请选择会员体系');
        return;
      } else if (!this.cover) {
        this.$message.error('请上传储值卡图案');
        return;
      } else if (judge(this.rechargeRmount)) {
        this.$message.error('请正确输入可充值金额');
        return;
      } else if (this.remark === '<p><br></p>' || this.remark === '') {
        this.$message.error('请输入说明');
        return;
      }

      function judge(arr) {
        var moneyArr = arr.filter(function (item) {
          return Number(item) && Number(String(item).trim());
        });

        if (moneyArr.length === 0) {
          return true;
        } else {
          var dd = false;
          moneyArr.forEach(function (res) {
            if (res === '' || res > 1000 || res <= 0) {
              dd = true;
            }
          });
        }

        self.rechargeRmount = moneyArr;
        return dd;
      }

      var obj = {
        code: '',
        name: this.name,
        type: 1,
        classId: this.value,
        cover: this.cover,
        remark: this.remark,
        description: this.description,
        number: Number(this.number),
        rechargeRmount: this.rechargeRmount,
        ifRechargeAmount: this.ifRechargeAmount,
        scopeType: this.scopeType,
        memberIds: this.effMembers,
        institutionIds: this.institutionIds,
        cardValue: 0
      };
      this.$store.dispatch('editor/setDepositEditor', obj);

      if (this.id) {
        obj.id = this.id;
        (0, _deposit.editCardStored)(obj).then(function (res) {
          if (res.ret === 1000) {
            _this5.$message({
              message: '恭喜你，修改成功',
              type: 'success'
            });

            _this5.$router.push('/deposit/onLine/manage');
          }
        });
      } else {
        (0, _deposit.addCardStored)(obj).then(function (res) {
          if (res.ret === 1000) {
            _this5.$message({
              message: '恭喜你，添加成功',
              type: 'success'
            });

            _this5.$router.push('/deposit/onLine/manage');
          }
        });
      }
    },
    setCover: function setCover(index) {
      this.obj.cover = index;
    }
  }
};
exports.default = _default;