var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-button",
        {
          style: { background: _vm.color, borderColor: _vm.color },
          attrs: { icon: "el-icon-upload", size: "mini", type: "primary" },
          on: {
            click: function($event) {
              _vm.dialogVisible = true
            }
          }
        },
        [_vm._v("\n    添加图片\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-upload",
            {
              staticClass: "editor-slide-upload",
              attrs: {
                multiple: true,
                accept:
                  ".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF",
                "file-list": _vm.fileList,
                "show-file-list": true,
                "auto-upload": false,
                "on-remove": _vm.handleRemove,
                "on-success": _vm.handleSuccess,
                "on-preview": _vm.onPreview,
                "on-error": _vm.onError,
                "before-upload": _vm.beforeUpload,
                action: _vm.action,
                "on-change": _vm.beforeUpload,
                headers: _vm.headers,
                "list-type": "picture-card"
              }
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("\n        点击 添加图片\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("\n      取消\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("\n      添加\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }