"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _auth = _interopRequireDefault(require("./components/auth"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    auth: _auth.default
  },
  data: function data() {
    return {
      isAuthed: false,
      params: {
        name: "",
        remark: ""
      },
      rules: {}
    };
  },
  methods: {
    onAuthFinished: function onAuthFinished() {
      this.isAuthed = true;
    }
  }
};
exports.default = _default;