"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _flowCoupon = require("@/api/flowCoupon.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      id: '',
      active: 1,
      step: [{
        txt: 'admin  2020-09-15 17:07:29',
        tit: '新建规则'
      }, {
        txt: ' admin  2020-09-15 17:08:30',
        tit: '待审核'
      }, {
        txt: 'admin 2020-09-15 17:21:29',
        tit: '审核通过'
      }, {
        txt: 'admin 2020-09-15 17:21:29',
        tit: '启用'
      }, {
        txt: 'admin 2020-09-15 17:21:29',
        tit: '禁用'
      }],
      data: {
        ruleCode: '',
        ruleName: '',
        ruleRemark: '',
        ruleType: '',
        ruleStartTime: '',
        ruleEndTime: '',
        ruleItems: [],
        rangeName: ''
      }
    };
  },
  created: function created() {
    var _this = this;

    this.id = this.$route.query.id;
    (0, _flowCoupon.getSendCouponRule)(this.id).then(function (res) {
      console.log(res);
      Object.keys(_this.data).forEach(function (item) {
        Object.keys(res.data).forEach(function (row) {
          if (item == row) {
            _this.data[row] = res.data[row];
          }
        });
      });
    });
  },
  methods: {
    passAudit: function passAudit() {}
  }
};
exports.default = _default;