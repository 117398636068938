"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _api = require("./api.js");

var _mem = require("@/api/mem.js");

var _user = require("@/api/user.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      popShow: false,
      qrCode: '',
      couponUUid: '',
      url: '',
      obj: {
        pageNo: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
        orgCode: '',
        code: ''
      },
      time: '',
      options: [],
      list: [],
      total: 0,
      tabTh: [{
        prop: 'code',
        label: '活动编码',
        width: 100
      }, {
        prop: 'name',
        label: '活动名称',
        width: 100
      }, {
        prop: 'orgName',
        label: '主办机构',
        width: 100
      }, {
        prop: 'startTime',
        label: '活动开始时间',
        width: 100
      }, {
        prop: 'endTime',
        label: '活动结束时间',
        width: 100
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.options = res.data;
    });
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    getCode: function getCode(scope, special) {
      var _this2 = this;

      // 获取小程序码
      this.couponUUid = scope.name;
      var scene = '';
      scene = scope.code;
      var path = {
        1: 'pages/pond/pond/pond',
        2: 'pages/pond/pond/pond',
        3: 'pages/pond/pond/pond',
        4: 'activity/pond/egg',
        5: 'activity/pond/pond'
      };
      this.openLoading('获取小程序码中');
      (0, _user.couponQRCode)({
        path: path[scope.type],
        scene: scene
      }).then(function (res) {
        _this2.url = "".concat(path[scope.type], "?id=").concat(scene);

        _this2.openLoading().close();

        _this2.qrCode = res.data;
        _this2.popShow = true;
      }).catch(function (res) {
        _this2.openLoading().close();
      });
    },
    init: function init(type) {
      var _this3 = this;

      if (type === 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type === 'remove') {
        this.removeObj(this.obj);
        this.time = '';
      }

      if (this.time.length > 0) {
        this.obj.startTime = this.time[0] || '';
        this.obj.endTime = this.time[1] || '';
      }

      var data = {};
      Object.keys(this.obj).forEach(function (key) {
        if (_this3.obj[key] != '') {
          data[key] = _this3.obj[key];
        }
      });
      this.openLoading();
      (0, _api.lotteryList)(data).then(function (res) {
        _this3.openLoading().close();

        _this3.total = res.data.totalNum;
        _this3.list = res.data.items;
      }).catch(function (err) {
        _this3.openLoading().close();
      });
    },
    goAdd: function goAdd(url) {
      this.$router.push(url);
    }
  }
};
exports.default = _default;