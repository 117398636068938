"use strict";

var _interopRequireWildcard3 = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _parking = require("@/api/parking.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var R = _interopRequireWildcard3(require("ramda"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      searchParams: {
        pageNo: 1,
        pageSize: 10,
        parkName: null,
        headName: null,
        contactNumber: null,
        address: null,
        date: null
      },
      total: 0,
      exportExcelLoading: false
    };
  },
  computed: {
    columns: function columns() {
      return [{
        label: '停车场名称',
        prop: 'parkName'
      }, {
        label: '负责人',
        prop: 'headName'
      }, {
        label: '联系电话',
        prop: 'contactNumber'
      }, {
        label: '地址',
        prop: 'address'
      }, {
        label: '创建时间',
        prop: 'createTime'
      }];
    }
  },
  created: function created() {
    this.fetch();
  },
  methods: {
    fetch: function fetch(type) {
      var _this = this;

      if (type === 'search') {
        this.searchParams.pageNo = 1;
        this.searchParams.pageSize = 10;
      }

      if (type === 'reset') {
        this.removeObj(this.searchParams);
      }

      var date = this.searchParams.date;
      var params = R.omit(['date'], this.searchParams);

      if (date && date.length > 0) {
        params['createTimeStartDay'] = date[0];
        params['createTimeEndDay'] = date[1];
      }

      (0, _parking.getPageParkingList)(params).then(function (res) {
        _this.total = res.data.totalNum;
        _this.list = res.data.items;
      });
    },
    goto: function goto(url) {
      this.$router.push(url);
    },
    exportExcel: function exportExcel() {
      var _this2 = this;

      var that = this;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        that.exportExcelLoading = true;
        var headers = [];
        var filterVal = [];

        _this2.columns.forEach(function (item) {
          filterVal.push(item.prop);
          headers.push(item.label);
        });

        var date = _this2.searchParams.date;
        var params = R.omit(['date', 'pageNo', 'pageSize'], _this2.searchParams);

        if (date && date.length > 0) {
          params['createTimeStartDay'] = date[0];
          params['createTimeEndDay'] = date[1];
        }

        (0, _parking.getParkingList)(params).then(function (res) {
          var list = res.data;

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: headers,
            data: data,
            filename: '停车场列表'
          });
        }).finally(function () {
          that.exportExcelLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, tableData) {
      return tableData.map(function (v) {
        return filterVal.map(function (j) {
          return v[j];
        });
      });
    }
  }
};
exports.default = _default;