"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addArticle = addArticle;
exports.editArticle = editArticle;
exports.getArticleById = getArticleById;
exports.listArticlePage = listArticlePage;

var _request = _interopRequireDefault(require("@/utils/request"));

function listArticlePage(data) {
  // 查询文章列表
  return (0, _request.default)({
    url: '/api/article/listArticlePage',
    method: 'post',
    data: data
  });
}

function addArticle(data) {
  // 新增文章
  return (0, _request.default)({
    url: '/api/article/addArticle',
    method: 'post',
    data: data
  });
}

function editArticle(data) {
  // 编辑文章
  return (0, _request.default)({
    url: '/api/article/editArticle',
    method: 'post',
    data: data
  });
}

function getArticleById(data) {
  // 根据文章id查询文章
  return (0, _request.default)({
    url: '/api/article/getArticleById',
    method: 'post',
    params: data
  });
}