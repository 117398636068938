"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _parking = require("@/api/parking.js");

var _Descriptions = _interopRequireDefault(require("@/components/mc/Descriptions.vue"));

var _map = require("../../const/map");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Descriptions: _Descriptions.default
  },
  data: function data() {
    return {
      parkLog: {}
    };
  },
  computed: {
    basicColumns: function basicColumns() {
      return [{
        title: '车牌号',
        key: 'plateNo',
        dataIndex: 'plateNo'
      }, {
        title: '会员名称',
        key: 'memberName',
        dataIndex: 'memberName'
      }, {
        title: '会员类型',
        key: 'memberType',
        dataIndex: 'memberType',
        valueEnum: _map.ParkMemberType
      }, {
        title: '联系电话',
        key: 'contactNumber',
        dataIndex: 'contactNumber'
      }, {
        title: '停车场',
        key: 'parkName',
        dataIndex: 'parkName'
      }, {
        title: '入场时间',
        key: 'inTime',
        dataIndex: 'inTime'
      }, {
        title: '离场时间',
        key: 'outTime',
        dataIndex: 'outTime'
      }, {
        title: '停车时长',
        key: 'parkTime',
        dataIndex: 'parkTime'
      }];
    },
    payColumns: function payColumns() {
      return [{
        title: '计费',
        key: 'totalAmount',
        dataIndex: 'totalAmount'
      }, {
        title: '支付流水',
        key: 'paySn',
        dataIndex: 'paySn'
      }, {
        title: '积分抵扣',
        key: 'scoreDeductionAmount',
        dataIndex: 'scoreDeductionAmount'
      }, {
        title: '支付金额',
        key: 'paymentAmount',
        dataIndex: 'paymentAmount'
      }, {
        title: '支付时间',
        key: 'paymentTime',
        dataIndex: 'paymentTime'
      }];
    }
  },
  created: function created() {
    var _this = this;

    var id = this.$route.params && this.$route.params.id;

    if (id) {
      (0, _parking.getParkLog)({
        id: id
      }).then(function (res) {
        _this.parkLog = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, res.data), {}, {
          parkTime: _this.formatField(res.data.parkTime, '小时'),
          totalAmount: _this.formatField(res.data.totalAmount, '元'),
          scoreDeductionAmount: _this.formatField(res.data.scoreDeductionAmount, '元'),
          paymentAmount: _this.formatField(res.data.paymentAmount, '元')
        });
      });
    }
  },
  methods: {
    formatField: function formatField(v, f) {
      return v + f;
    }
  }
};
exports.default = _default;