"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.exportExchangeCode = exportExchangeCode;
exports.getByPage = getByPage;
exports.getOrderDea = getOrderDea;
exports.getUserCard = getUserCard;
exports.goChangeStatus = goChangeStatus;
exports.goUpdate = goUpdate;
exports.importWhiteList = importWhiteList;

var _request = _interopRequireDefault(require("@/utils/request"));

function getByPage(data) {
  return (0, _request.default)({
    url: '/api/groupCard/queryRebateList',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function goUpdate(data) {
  return (0, _request.default)({
    url: '/api/cardStoredLock/update',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function exportExchangeCode(data) {
  return (0, _request.default)({
    url: '/api/groupCard/exportExchangeCode',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function importWhiteList(data) {
  return (0, _request.default)({
    url: '/api/groupCard/importWhiteList',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getUserCard(data) {
  return (0, _request.default)({
    url: '/api/cardStoredLock/listCardStoredByLockParam',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getOrderDea(data) {
  return (0, _request.default)({
    url: '/api/groupCard/queryDetails',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function goChangeStatus(data) {
  return (0, _request.default)({
    url: '/api/groupCard/toExamineAndWithdraw',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addData(data) {
  return (0, _request.default)({
    url: '/api/groupCard/addGroupCard',
    method: 'post',
    loading: 'loading',
    data: data
  });
}