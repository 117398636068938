var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增/编辑报名活动 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { ref: "name", attrs: { label: "活动名称：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "活动名称"
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "name", attrs: { label: "描述：", prop: "activityDesc" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "活动描述" },
                model: {
                  value: _vm.ruleForm.activityDesc,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "activityDesc",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.activityDesc"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "name", attrs: { label: "所属门店：", prop: "storeCode" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { "max-width": "400px", width: "100%" },
                  attrs: {
                    size: "small",
                    placeholder: "所属门店",
                    filterable: "",
                    autocomplete: "off"
                  },
                  model: {
                    value: _vm.ruleForm.storeCode,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "storeCode", $$v)
                    },
                    expression: "ruleForm.storeCode"
                  }
                },
                _vm._l(_vm.storeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.storeId,
                      attrs: {
                        label: item.shortName + "（" + item.storeCode + "）",
                        value: item.storeCode
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "name", attrs: { label: "封面图：", prop: "activityDesc" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: {
                  width: 300,
                  height: 240,
                  cover: _vm.ruleForm.activityImage
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "activityImage")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [_vm._v("690*350")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "status", attrs: { label: "状态：", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("禁用")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form-item",
                {
                  ref: "startTime",
                  attrs: { label: "有效期：", prop: "startTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择开始时间"
                    },
                    model: {
                      value: _vm.ruleForm.startTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "startTime", $$v)
                      },
                      expression: "ruleForm.startTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "endTime",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { "label-width": "0", prop: "endTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      disabled: !_vm.ruleForm.startTime,
                      "picker-options": _vm.pickerTime,
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": "23:59:59",
                      placeholder: "选择结束时间"
                    },
                    model: {
                      value: _vm.ruleForm.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "endTime", $$v)
                      },
                      expression: "ruleForm.endTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "labelContent",
              attrs: { prop: "labelContent", label: "邀请用户标签：" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "输入标签名称搜索~",
                    "value-key": "id",
                    clearable: ""
                  },
                  model: {
                    value: _vm.ruleForm.labelContent,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "labelContent", $$v)
                    },
                    expression: "ruleForm.labelContent"
                  }
                },
                _vm._l(_vm.userLabelList, function(so) {
                  return _c("el-option", {
                    key: so.id,
                    attrs: { label: "" + so.labelName, value: so.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "name",
              attrs: { label: "限制人数：", prop: "limitPeople" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { type: "number", placeholder: "限制人数" },
                model: {
                  value: _vm.ruleForm.limitPeople,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "limitPeople",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.limitPeople"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "fields", attrs: { label: "报名表信息：", prop: "fields" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.addFields }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.ruleForm.fields, function(item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c("el-divider"),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            ref: "fields." + index + ".filed",
                            refInFor: true,
                            attrs: {
                              prop: "fields." + index + ".filed",
                              rules: [
                                { required: true, message: "请输入字段名称" }
                              ],
                              label: "字段名称："
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "form_input",
                              attrs: {
                                size: "mini",
                                placeholder: "请输入字段名称"
                              },
                              model: {
                                value: item.filed,
                                callback: function($$v) {
                                  _vm.$set(
                                    item,
                                    "filed",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.filed"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            ref: "fields." + index + ".filedType",
                            refInFor: true,
                            attrs: {
                              prop: "fields." + index + ".filedType",
                              rules: [{ required: true, message: "请选择" }],
                              label: "字段类型 ：",
                              "label-width": "100px"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: item.filedType,
                                  callback: function($$v) {
                                    _vm.$set(item, "filedType", $$v)
                                  },
                                  expression: "item.filedType"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "文本", value: 0 }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "数字", value: 1 }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "单选", value: 2 }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "多选", value: 3 }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "日期时间", value: 4 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            ref: "fields." + index + ".requred",
                            refInFor: true,
                            attrs: {
                              prop: "fields." + index + ".requred",
                              rules: [{ required: true, message: "请选择" }],
                              label: "是否必填 ：",
                              "label-width": "100px"
                            }
                          },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "active-value": 1,
                                "inactive-value": 0
                              },
                              model: {
                                value: item.requred,
                                callback: function($$v) {
                                  _vm.$set(item, "requred", $$v)
                                },
                                expression: "item.requred"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            ref: "fields." + index + ".sort",
                            refInFor: true,
                            attrs: {
                              prop: "fields." + index + ".sort",
                              rules: [{ required: true, message: "请输入" }],
                              label: "排序 ：",
                              "label-width": "70px"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { size: "mini", type: "number" },
                              model: {
                                value: item.sort,
                                callback: function($$v) {
                                  _vm.$set(item, "sort", $$v)
                                },
                                expression: "item.sort"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 2 } },
                      [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认删除该字段？" },
                            on: {
                              confirm: function($event) {
                                return _vm.delItem(index)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                item.filedType === 2 || item.filedType === 3
                  ? _c(
                      "el-form-item",
                      {
                        ref: "fields." + index + ".fileItem",
                        refInFor: true,
                        attrs: {
                          prop: "fields." + index + ".fileItem",
                          rules: [{ required: true, message: "请添加选项" }],
                          label: "选项内容 ："
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              staticStyle: { "max-width": "200px" },
                              attrs: {
                                placeholder: "请输入选项",
                                size: "mini"
                              },
                              model: {
                                value: item.itemName,
                                callback: function($$v) {
                                  _vm.$set(
                                    item,
                                    "itemName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.itemName"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "100px" },
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.addItem(index)
                                  }
                                }
                              },
                              [_vm._v("添加")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(item.fileItem, function(
                          fileItem,
                          fileItemIndex
                        ) {
                          return _c(
                            "el-tag",
                            {
                              key: fileItemIndex,
                              staticStyle: { margin: "10px" },
                              attrs: { size: "medium", closable: "" },
                              on: {
                                close: function($event) {
                                  return _vm.delTag(index, fileItemIndex)
                                }
                              }
                            },
                            [_vm._v(_vm._s(fileItem))]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "detail", attrs: { prop: "detail", label: "活动详情：" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.ruleForm.detail,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "detail", $$v)
                  },
                  expression: "ruleForm.detail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }