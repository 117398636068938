"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'NavigateSelect',
  components: {
    couponPop: _radioItem.default
  },
  props: {
    dataSource: {
      type: Object
    },
    propPrefix: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '链接类型：'
    }
  },
  data: function data() {
    return {
      urlList: _selectUrl.default.urlList,
      appIdArr: _selectUrl.default.appIdArr,
      urlTypeList: _selectUrl.default.urlTypeList,
      noSelect: _selectUrl.default.noSelect,
      popType: '',
      couponShow: false
    };
  },
  watch: {
    'dataSource.linkId': {
      immediate: true,
      handler: function handler(crt) {
        this.setLinkPath(this.dataSource.linkCode, crt);
      }
    },
    'dataSource.linkCode': {
      immediate: true,
      handler: function handler(crt) {
        this.setLinkPath(crt, this.dataSource.linkId);
      }
    }
  },
  methods: {
    setCoupon: function setCoupon(e) {
      if (this.popType === 'vipSubsidy') {
        this.dataSource.linkId = e.uuid;
      } else if (this.popType === 'vipCity') {
        this.dataSource.linkId = e.uuid;
      } else if (this.popType === 'card') {
        this.dataSource.linkId = e.uuid;
        this.cardType = e.type || this.cardType;
      } else if (this.popType === 'vipCityLife') {
        this.dataSource.linkId = e.productCode;
        this.citylifeId = e.id;
      } else if (this.popType === 'coupon') {
        this.dataSource.linkId = e.uuid;
      } else if (this.popType === 'couponBag' || this.popType === 'help' || this.popType === 'apply') {
        this.dataSource.linkId = e.id;
      } else if (this.popType === 'pond') {
        this.dataSource.linkId = e.code;
      } else if (this.popType === 'goods') {
        this.dataSource.linkId = e.productCode;
        this.productSource = e.productSource || this.productSource;
      } else if (this.popType === 'tem') {
        this.dataSource.linkId = e.pageCode;
      } else if (this.popType === 'svipServiceDetail') {
        this.dataSource.linkId = e.id;
      } else if (this.popType === 'svipActivityDetail') {
        this.dataSource.linkId = e.id;
      } else if (this.popType === 'svipSpaceDetail') {
        this.dataSource.linkId = e.id;
      }

      this.couponShow = false;
      this.popType = '';
    },
    getCoupon: function getCoupon() {
      this.popType = this.dataSource.linkCode;
      this.$refs.coupon1.getList(this.popType, this.dataSource.linkId);
      this.couponShow = true;
    },
    setLinkPath: function setLinkPath(linkCode, linkId) {
      if (linkCode) {
        if (linkCode === 'legouGoods') {
          this.dataSource.linkPath = '/legou/pages/goods/goods?id=' + linkId;
        } else if (linkCode === 'coupon') {
          this.dataSource.linkPath = '/pages/coupons/couponDea/couponDea?type=3&id=' + linkId;
        } else if (linkCode === 'legouGoods') {
          this.dataSource.linkPath = "/legou/pages/goods/goods?id=".concat(linkId);
        } else if (linkCode === 'legouTem') {
          this.dataSource.linkPath = "/legou/pages/index/index?page_id=".concat(linkId);
        } else if (linkCode === 'specialActivity') {
          this.dataSource.linkPath = "/activity/newGift/special?id=".concat(linkId);
        } else if (linkCode === 'waterCoupon') {
          this.dataSource.linkPath = "/activity/waterCoupon/index?id=".concat(linkId);
        } else if (linkCode === 'legouLive') {
          this.dataSource.linkPath = "plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=".concat(linkId);
        } else if (linkCode === 'legouShop') {
          this.dataSource.linkPath = "/legou/mch/shop/shop?mch_id=".concat(linkId);
        } else if (linkCode === 'vipCityLife') {
          this.dataSource.linkPath = "/pages/coupons/couponDea/couponDea?share=unShare&type=3&vip=isVip&id=".concat(linkId, "&citylifeId=").concat(this.citylifeId);
        } else if (linkCode === 'couponBag') {
          this.dataSource.linkPath = '/pages/coupons/couponGift/couponGift?id=' + linkId;
        } else if (linkCode === 'help') {
          this.dataSource.linkPath = '/activity/help/help?id=' + linkId;
        } else if (linkCode === 'apply') {
          this.dataSource.linkPath = '/activity/apply/dea?id=' + linkId;
        } else if (linkCode === 'card') {
          if (this.cardType == 3 || this.cardType == 4) {
            this.dataSource.linkPath = '/pages/starCard/giftToken/giftToken?id=' + linkId;
          } else {
            this.dataSource.linkPath = '/pages/starCard/recharge/recharge?id=' + linkId;
          }
        } else if (linkCode === 'activity') {
          this.dataSource.linkPath = '/pages/discover/detail/index/index?id=' + linkId;
        } else if (linkCode === 'vote') {
          this.dataSource.linkPath = '/pages/voteActivity/voteActivity?id=' + linkId;
        } else if (linkCode === 'goods') {
          this.dataSource.linkPath = '/mall/goods/dea?id=' + linkId;
        } else if (linkCode === 'pond') {
          this.dataSource.linkPath = '/pages/pond/pond/pond?id=' + linkId;
        } else if (linkCode === 'tem') {
          this.dataSource.linkPath = '/pages/tem/tem?id=' + linkId + '&title=' + this.pageTitle;
        } else if (linkCode === 'auto') {
          this.dataSource.linkPath = '/pages/tem/auto?id=' + linkId + '&title=' + this.pageTitle;
        } else if (linkCode === 'storeTem') {
          this.dataSource.linkPath = '/pages/storeTem/storeTem?id=' + linkId + '&title=' + this.pageTitle;
        } else if (linkCode === 'storeMap') {
          this.dataSource.linkPath = '/pages/storeTem/map?id=' + linkId;
        } else if (linkCode === 'svipServiceDetail') {
          this.dataSource.linkPath = '/svip/service/detail?id=' + linkId;
        } else if (linkCode === 'svipActivityDetail') {
          this.dataSource.linkPath = '/svip/activity/detail?id=' + linkId;
        } else if (linkCode === 'svipSpaceDetail') {
          this.dataSource.linkPath = '/svip/space/detail?id=' + linkId;
        } else if (linkCode === '4' || linkCode === '5') {
          this.dataSource.linkPath = '';
          return;
        } else {
          this.dataSource.linkPath = linkCode;
        }
      }
    },
    onChangeLinkCode: function onChangeLinkCode(e) {
      this.dataSource.linkId = '';
    }
  }
};
exports.default = _default;