"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    obj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    detailRollimg: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    arrNode: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    detailCtmfield: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      viewShow: true
    };
  },
  methods: {
    setViewShow: function setViewShow() {
      this.viewShow = !this.viewShow;
    }
  }
};
exports.default = _default2;