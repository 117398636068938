"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _api = require("./api.js");

var _request = require("@/utils/request.js");

var _auth = require("@/utils/auth");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    selectAward: _radioItem.default
  },
  data: function data() {
    return {
      couponShow: false,
      baseUrl: _request.baseUrl + 'admin/md/shop/upload',
      fileList: [],
      roleList: [],
      userRole: [],
      contactList: [],
      mch: {},
      formData: [{
        label: '购买人：',
        type: 'input',
        placeholder: '请输入购买人',
        value: 'buyUser'
      }, {
        label: '负责人手机号：',
        type: 'input',
        placeholder: '请输入负责人手机号',
        value: 'buyMobile'
      }, {
        slot: 'voucher'
      }, {
        label: '备注：',
        type: 'input',
        placeholder: '请输入备注',
        value: 'remarks'
      }, {
        slot: 'cardUuid',
        label: '团购购物卡：',
        type: 'input',
        placeholder: '请输入团购购物卡',
        value: 'cardUuid'
      }, {
        label: '购物卡面额：',
        type: 'inputNumber',
        placeholder: '请输入购物卡面额',
        value: 'denomination'
      }, {
        label: '购物卡数量：',
        type: 'inputNumber',
        placeholder: '请输入购物卡数量',
        value: 'quantity'
      }],
      ruleForm: {
        type: null,
        buyUser: null,
        buyMobile: undefined,
        voucher: '',
        remarks: null,
        cardUuid: '',
        denomination: undefined,
        quantity: undefined,
        id: null
      },
      rules: {
        type: [{
          required: true,
          message: '请选择类型'
        }],
        buyUser: [{
          required: true,
          message: '请输入购买人'
        }],
        buyMobile: [{
          required: true,
          message: '请输负责人手机号'
        }],
        voucher: [{
          required: true,
          message: '请上传凭证'
        }],
        remarks: [{
          required: true,
          message: '请输入备注'
        }],
        cardUuid: [{
          required: true,
          message: '请输入购物卡卡号'
        }],
        denomination: [{
          required: true,
          message: '请输入面额'
        }],
        quantity: [{
          required: true,
          message: '请输入数量'
        }]
      }
    };
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        ContentType: 'multipart/form-data',
        // 设置token
        'Authori-zation': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = Number(this.$route.query.id);
    this.ruleForm.type = Number(this.$route.query.type);

    if (this.ruleForm.id > 0) {
      (0, _api.getInfo)(this.ruleForm.id).then(function (res) {
        _this.mch = res; // this.ruleForm.contractNo = res.contractNo

        _this.ruleForm = res; // this.remoteMethod(null,res.contractNo)
      });
    }
  },
  methods: {
    getAward: function getAward(e) {
      this.$refs.coupon1.getList('card', this.ruleForm.cardUuid);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      console.log(e);
      this.ruleForm.cardUuid = e.uuid;
      this.couponShow = false;
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      (0, _api.addData)(this.ruleForm).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;