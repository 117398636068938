var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("方案状态")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c(
          "div",
          { staticClass: "box_step" },
          [
            _c(
              "el-steps",
              { attrs: { active: _vm.active } },
              _vm._l(_vm.step, function(item, index) {
                return _c("el-step", {
                  key: index,
                  attrs: { title: item.tit, description: item.txt }
                })
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "box_btn" }, [
          _c("p", [
            _vm._v("当前状态："),
            _c("span", [_vm._v(_vm._s(_vm.status))])
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.btnType === 1 || _vm.btnType === 3
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "putCenter:edit",
                          expression: "'putCenter:edit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.goEditor }
                    },
                    [_vm._v("修改方案")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "putCenter:audit",
                          expression: "'putCenter:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.removeStatus(2)
                        }
                      }
                    },
                    [_vm._v("通过审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "putCenter:audit",
                          expression: "'putCenter:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.removeStatus(3)
                        }
                      }
                    },
                    [_vm._v("撤回审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1 || _vm.btnType === 3
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "putCenter:audit",
                          expression: "'putCenter:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.removeStatus(4)
                        }
                      }
                    },
                    [_vm._v("撤销审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 2
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "putCenter:audit",
                          expression: "'putCenter:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.removeStatus(6)
                        }
                      }
                    },
                    [_vm._v("终止投放")]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("方案详情")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c("ul", [
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("优惠券ID：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.couponId))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("优惠券名称：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.couponName))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("优惠券类型：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.couponTypeName))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("投放时间：")
              ]),
              _vm._v(" "),
              _vm.onlineTime === 1
                ? _c("p", { staticClass: "dea_box_li_txt" }, [
                    _vm._v(_vm._s(_vm.startTime) + " 至 " + _vm._s(_vm.endTime))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.onlineTime === 2
                ? _c("p", { staticClass: "dea_box_li_txt" }, [
                    _vm._v(_vm._s(_vm.time))
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("投放数量：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.putNumber))
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("方案数据")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c("p", { staticClass: "deatil_tit none" }, [
          _vm._v("\n        方案未投放，暂无统计数据\n      ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }