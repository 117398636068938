var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          attrs: {
            "form-data": _vm.formData,
            "rule-form": _vm.ruleForm,
            rules: _vm.rules
          },
          on: { submitForm: _vm.submitForm }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentType" }, slot: "contentType" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "关联内容：", prop: "contentType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "关联内容类型"
                      },
                      on: { change: _vm.typeChange },
                      model: {
                        value: _vm.ruleForm.contentType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "contentType", $$v)
                        },
                        expression: "ruleForm.contentType"
                      }
                    },
                    _vm._l(_vm.urlList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.contentType == 4
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "目标小程序：", prop: "appid" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              attrs: { placeholder: "小程序" },
                              model: {
                                value: _vm.ruleForm.appid,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "appid", $$v)
                                },
                                expression: "ruleForm.appid"
                              }
                            },
                            _vm._l(_vm.appidArr, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "小程序路径：",
                            prop: "contentLink",
                            rules: [
                              { required: true, message: "请输入小程序路径" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_input",
                            attrs: { type: "text" },
                            model: {
                              value: _vm.ruleForm.contentLink,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "contentLink", $$v)
                              },
                              expression: "ruleForm.contentLink"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.contentType == 5
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "WEB页面：",
                        prop: "contentLink",
                        rules: [
                          { required: true, message: "请输入WEB页面地址" }
                        ]
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { type: "text" },
                        model: {
                          value: _vm.ruleForm.contentLink,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "contentLink", $$v)
                          },
                          expression: "ruleForm.contentLink"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.urlTypeList.indexOf(_vm.ruleForm.contentType) >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "链接编码：", prop: "contentLink" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { type: "text", placeholder: "输入链接编码" },
                        model: {
                          value: _vm.ruleForm.contentLink,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "contentLink", $$v)
                          },
                          expression: "ruleForm.contentLink"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "form_remark" }, [
                        _vm._v(
                          "\n          跳转其他小程序时输入跳转地址；跳转web页面输入跳转地址；其他输入对应编码；\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }