"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTaxRate = addTaxRate;
exports.deleteTaxRate = deleteTaxRate;
exports.getTaxRate = getTaxRate;
exports.listTaxRatePage = listTaxRatePage;
exports.updateTaxRate = updateTaxRate;

var _request = _interopRequireDefault(require("@/utils/request"));

function listTaxRatePage(data) {
  return (0, _request.default)({
    url: '/api/taxRate/listTaxRatePage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addTaxRate(data) {
  return (0, _request.default)({
    url: '/api/taxRate/addTaxRate',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function deleteTaxRate(data) {
  return (0, _request.default)({
    url: '/api/taxRate/deleteTaxRate',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function updateTaxRate(data) {
  return (0, _request.default)({
    url: '/api/taxRate/updateTaxRate',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getTaxRate(data) {
  return (0, _request.default)({
    url: '/api/taxRate/getTaxRate',
    method: 'get',
    loading: 'loading',
    params: data
  });
}