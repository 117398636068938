"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var statusOptions = [{
  value: '-1',
  label: '未删'
}, {
  value: '1',
  label: '已删'
}];
var _default = {
  data: function data() {
    return {
      api: '/api/invoice/listInvoiceCompanyPage',
      queryParams: [{
        type: 'input',
        placeholder: '主体名称',
        value: 'companyName'
      }, {
        type: 'input',
        placeholder: '纳税人识别号',
        value: 'userName'
      }, {
        type: 'radio',
        placeholder: '状态',
        value: 'delStatus',
        select: statusOptions
      }],
      columns: [{
        prop: 'appid',
        label: '主体编码'
      }, {
        prop: 'companyName',
        label: '主体名称'
      }, {
        prop: 'userName',
        label: '纳税人识别号'
      }, {
        prop: 'address',
        label: '注册地址'
      }, {
        prop: 'tel',
        label: '单位电话'
      }, {
        prop: 'bank',
        label: '账户开户行'
      }, {
        prop: 'bankAccount',
        label: '银行账户'
      }, {
        prop: 'proportion',
        label: '可开票金额比例'
      }, {
        prop: 'remark',
        label: '备注'
      }, {
        prop: 'delStatus',
        label: '状态',
        slot: 'status'
      }, {
        slot: 'operate'
      }]
    };
  }
};
exports.default = _default;