"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    labelCol: {
      type: Number,
      default: 4
    },
    wrapperCol: {
      type: Number,
      default: 20
    },
    verticalGutter: {
      type: Number,
      default: 20
    },
    column: {
      type: Number,
      default: 1
    },
    dataSource: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    title: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc
    };
  }
};
exports.default = _default2;