var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("新增编辑总部")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "20px" },
          attrs: { rules: _vm.rules, model: _vm.form, "label-width": "120px" }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "fullName", label: "总部名称：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { "max-width": "400px" },
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.fullName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "fullName", $$v)
                  },
                  expression: "form.fullName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "shortName", label: "总部简称：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { "max-width": "400px" },
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.shortName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "shortName", $$v)
                  },
                  expression: "form.shortName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "systemId", label: "ERP系统：", required: "" }
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "400px" },
                  attrs: { placeholder: "请选择ERP系统" },
                  model: {
                    value: _vm.form.systemId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "systemId", $$v)
                    },
                    expression: "form.systemId"
                  }
                },
                _vm._l(_vm.systemList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.systemName, value: item.systemId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "remark", label: "备注信息：" }
            },
            [
              _c("el-input", {
                staticStyle: { "max-width": "400px" },
                model: {
                  value: _vm.form.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "120px" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("form")
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }