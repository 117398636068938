"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _api = require("./api.js");

var _auth = require("@/utils/auth");

var _deliverDr = _interopRequireDefault(require("./deliverDr"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    deliverDr: _deliverDr.default
  },
  data: function data() {
    var _ref;

    return _ref = {
      isActivities: false,
      activities: {},
      isScal: false,
      dialogImageUrl: '',
      dialogVisible: false
    }, (0, _defineProperty2.default)(_ref, "isActivities", false), (0, _defineProperty2.default)(_ref, "activities", {}), (0, _defineProperty2.default)(_ref, "action", process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile'), (0, _defineProperty2.default)(_ref, "imgSrc", this.$store.state.user.imgSrc), (0, _defineProperty2.default)(_ref, "ruleForm", {
      orderNo: null,
      saleReason: null,
      saleType: null,
      systemApplySale: 1,
      attachment: []
    }), (0, _defineProperty2.default)(_ref, "rules", {
      saleReason: [{
        required: true,
        message: '请输入售后原因'
      }],
      saleType: [{
        required: true,
        message: '请选择售后类型'
      }],
      attachment: [{
        required: true,
        message: '请上传售后凭证'
      }]
    }), (0, _defineProperty2.default)(_ref, "popShow", false), (0, _defineProperty2.default)(_ref, "refusedReason", ''), (0, _defineProperty2.default)(_ref, "id", ''), (0, _defineProperty2.default)(_ref, "active", 0), (0, _defineProperty2.default)(_ref, "step", [{
      tit: '待付款'
    }, {
      tit: '待发货'
    }, {
      tit: '待收货'
    }, {
      tit: '已完成'
    }, {
      tit: '售后'
    }]), (0, _defineProperty2.default)(_ref, "stepSale", [{
      tit: '申请中'
    }, {
      tit: '退款中'
    }, {
      tit: '退款成功'
    }]), (0, _defineProperty2.default)(_ref, "data", {
      id: 0,
      logisticCode: null,
      productType: null,
      orderStatus: '',
      orderNo: '',
      orderTime: '',
      freightPrice: '',
      deliveryWay: '',
      userPhone: '',
      consigneeName: '',
      consigneePhone: '',
      consigneeAddress: '',
      cancelStatus: '',
      payStatus: '',
      saleStatus: '',
      attachment: '',
      saleReason: '',
      refusedReason: '',
      productName: '',
      productNum: '',
      productCode: '',
      productTotalPriceScoreStr: '',
      productImg: '',
      storeName: '',
      storeAddress: '',
      logisticNo: '',
      logisticName: '',
      productSpec: ''
    }), _ref;
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        'Token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.getDetail();
  },
  methods: {
    /*
    获取物流信息
    */
    getLogisticDea: function getLogisticDea() {
      var _this = this;

      this.apiGet('/api/mall/order/queryLogisticInfo/' + this.id).then(function (res) {
        _this.activities = res.data.data;
        _this.isActivities = true;
      });
    },
    // 订单详情
    getDetail: function getDetail() {
      var _this2 = this;

      this.id = this.$route.query.orderNo;
      (0, _api.orderDea)(this.id).then(function (res) {
        _this2.data = res.data;
        /*  Object.keys(this.data).forEach((item) => {
            this.data[item] = res.data[item]
          }) */

        if (res.data.attachment) {
          _this2.data.attachment = JSON.parse(res.data.attachment).map(function (item) {
            return _this2.imgSrc + item;
          });
        }

        if (res.data.payStatus === 0) {
          _this2.active = 1;
        } else {
          if (res.data.orderStatus == 1) {
            _this2.active = 2;
          }

          if (res.data.orderStatus == 2) {
            _this2.active = 3;
          }

          if (res.data.orderStatus == 3) {
            _this2.active = 4;
          }

          if (res.data.orderStatus == 4 || res.data.orderStatus == 5) {
            if (res.data.payStatus === 1) {
              if (res.data.orderStatus == 4) {
                if (res.data.saleStatus == 1 || res.data.saleStatus == 3) {
                  _this2.active = 0;
                }

                if (res.data.saleStatus == 2) {
                  _this2.active = 2;
                }

                if (res.data.saleStatus == 4) {
                  _this2.active = 3;
                }
              }

              if (res.data.orderStatus == 5) {
                if (res.data.cancelStatus === 0 || res.data.cancelStatus === 1) {
                  _this2.active = 1;
                }

                if (res.data.cancelStatus == 2) {
                  _this2.active = 2;
                }

                if (res.data.cancelStatus == 3) {
                  _this2.active = 3;
                }
              }
            } else {
              _this2.active = 5;
            }
          }
        }
      });
    },
    // 修改物流信息
    handleDeliver: function handleDeliver() {
      var ref = this.$refs.deliverDrRef;
      ref.form = JSON.parse(JSON.stringify(this.data));
      ref.open = true;
    },
    onSubmit: function onSubmit(formName) {
      var _this3 = this;

      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this3.ruleForm.orderNo = _this3.data.orderNo;
          (0, _api.applySale)(_this3.ruleForm).then(function (res) {
            _this3.$message({
              message: '申请售后成功',
              type: 'success'
            });

            _this3.$router.go(0);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onSuccess: function onSuccess(res) {
      var _this4 = this;

      this.dialogVisible = true;

      var _self = this;

      if (res.ret === 1000) {
        this.ruleForm.attachment.push(res.data);
      } else if (res.ret === 6000) {
        this.$confirm(res.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this4.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this5 = this;

      this.ruleForm.attachment = [];
      fileList.forEach(function (item) {
        _this5.ruleForm.attachment.push(item.response.data);
      });
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    goApplySale: function goApplySale() {
      /*
              {"orderNo":"订单号","saleReason":"退款原因","attachment":["退货凭证","退货凭证"]}
              */
      var data = {
        saleType: 1,
        orderNo: this.data.orderNo,
        saleReason: '退款原因',
        attachment: ['mika/pro/2021-07-30/1cb0ac371f874fa9a76ca9709c49555b.png']
      };
      (0, _api.applySale)(data);
    },
    goRefund: function goRefund() {
      var self = this;
      this.$confirm('此操作将立即退款, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        self.openLoading();
        (0, _api.saleConfirm)(self.id).then(function (res) {
          self.openLoading().close();
          self.active = 3;
          self.data.saleStatus = 4;
          self.$message({
            type: 'success',
            message: '退款成功!'
          });
        }).catch(function (err) {
          self.openLoading().close();
        });
      }).catch(function () {});
    },
    goSale: function goSale(type) {
      var _this6 = this;

      var self = this;

      if (type == 1) {
        self.openLoading();
        (0, _api.saleHandle)({
          orderNo: self.id,
          refusedReason: self.refusedReason,
          saleStatus: 3
        }).then(function (res) {
          self.openLoading().close();
          self.data.saleStatus = 3;
          self.popShow = false;
          self.refusedReason = '';

          _this6.$message({
            message: '已拒绝售后申请',
            type: 'success'
          });
        }).catch(function (err) {
          self.openLoading().close();
        });
      } else {
        self.openLoading();
        (0, _api.saleHandle)({
          orderNo: this.id,
          saleStatus: 2
        }).then(function (res) {
          self.openLoading().close();
          self.data.saleStatus = 2;

          _this6.$message({
            message: '已同意售后申请',
            type: 'success'
          });
        }).catch(function (err) {
          self.openLoading().close();
        });
      }
    },
    goCancel: function goCancel(type) {
      var _this7 = this;

      var data = {
        orderNo: this.data.orderNo,
        cancelStatus: type
      };
      var self = this;
      var str = '';

      if (type == 1) {
        str = '此操作将拒绝取消, 是否继续?';
      } else {
        str = '此操作将同意取消, 是否继续?';
      }

      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        self.openLoading();
        (0, _api.cancelHandle)(data).then(function (res) {
          self.openLoading().close();

          if (type == 1) {
            _this7.data.cancelStatus = 1;

            _this7.$message({
              message: '已拒绝取消',
              type: 'success'
            });
          } else {
            _this7.data.cancelStatus = 3;
            _this7.active = 3;

            _this7.$message({
              message: '已同意取消',
              type: 'success'
            });
          }
        }).catch(function (err) {
          self.openLoading().close();
        });
      }).catch(function () {});
    },
    passAudit: function passAudit() {}
  }
};
exports.default = _default;