"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      id: '',
      active: 1,
      btnType: 0,
      step: [{
        tit: '发布方案（待审核）',
        txt: ''
      }, {
        tit: '审核通过（待投放）',
        txt: ''
      }, {
        tit: '开始投放（投放中）',
        txt: ''
      }, {
        tit: '结束投放（完成投放）',
        txt: ''
      }],
      name: '',
      startTime: '',
      endTime: '',
      tiggType: '',
      tiggValue: '',
      putNumber: '',
      limitNumber: '',
      status: '',
      obj3: {},
      showType: '',
      couponIdBag: []
    };
  },
  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    goEditor: function goEditor() {
      this.$router.push('/coupon/couponPut/addPut?id=touch&unid=' + this.id);
    },
    setActive: function setActive(index) {
      switch (index) {
        case 1:
          this.status = '待审核';
          this.active = index;
          this.btnType = 1;
          break;

        case 2:
          this.status = '已审核';
          this.active = 2;
          this.btnType = 2;
          break;

        case 3:
          this.status = '审核撤回';
          this.active = 1;
          this.btnType = 3;
          break;

        case 4:
          this.status = '审核撤销';
          this.active = -1;
          this.btnType = 0;
          break;

        case 5:
          this.status = '已过期';
          this.active = -1;
          this.btnType = 0;
          break;

        case 6:
          this.status = '已终止';
          this.active = -1;
          this.btnType = 0;
          break;

        case 8:
          this.status = '已投放';
          this.active = 3;
          this.btnType = 2;
          break;

        case 9:
          this.status = '投放完成';
          this.active = 4;
          this.btnType = 0;
          break;
      }
    },
    init: function init() {
      var _this = this;

      this.id = Number(this.$route.query.unid);
      (0, _coupon.getPutTiggerById)(this.id).then(function (res) {
        console.log(res);
        _this.name = res.data.name;
        _this.startTime = res.data.startTime; // "有效期(开始日期)",

        _this.endTime = res.data.endTime; // "有效期(结束日期)",

        _this.showType = res.data.tiggType;
        _this.couponIds = JSON.parse(res.data.couponIds);
        _this.couponBagIds = JSON.parse(res.data.couponBagIds);
        console.log(_this.couponIds.concat(_this.couponBagIds));
        (0, _coupon.couponSchemeValid)(_this.setCouponIdBags(_this.couponIds.concat(_this.couponBagIds)), _this.startTime, _this.endTime).then(function (res) {
          _this.couponIdBag = res.data;
        }); //					listCouponAndBagByUUId(this.setCouponIdBags(this.couponIds)).then((res)=>{
        //						this.couponIdBag=res.data
        //					})

        switch (res.data.tiggType) {
          case 1:
            _this.tiggType = '用户行为';
            var value = res.data.tiggValue;
            var obj = {};
            (0, _coupon.listPutTiggerCdition)(1).then(function (res) {
              obj = res.data.find(function (item) {
                // 这里的userList就是上面遍历的数据源
                return item.id === value; // 筛选出匹配数据
              });
              _this.tiggValue = obj.relevanceName; // "触发条件值",
            });
            break;

          case 2:
            _this.tiggType = '消费满送';
            break;

          default:
            _this.tiggType = '充值满送';
            console.log(res.data.tiggValue);
            var value1 = String(res.data.tiggValue);
            (0, _coupon.listPutTiggerCdition)(3).then(function (res) {
              console.log(res);
              obj = res.data.find(function (item) {
                // 这里的userList就是上面遍历的数据源
                return item.relevanceValue === value1; // 筛选出匹配数据
              });
              _this.obj3 = obj;
            });
            _this.tiggValue = res.data.tiggValue;
          // "触发条件值",
        }

        _this.setActive(res.data.status);

        if (res.data.putNumber === 0) {
          _this.putNumber = '无限制'; // "投放数量",
        } else {
          _this.putNumber = res.data.putNumber; // "投放数量",
        }

        _this.limitNumber = res.data.limitNumber; // 限领数量",

        var putOpr = res.data.putOprLogVos;
        putOpr.forEach(function (item) {
          if (item.oprType === 1) {
            _this.step[0].txt = item.oprUserName + item.oprTime;
          } else if (item.oprType === 2) {
            _this.step[1].txt = item.oprUserName + item.oprTime;
          }
        }); //					if(putOpr.length>0){
        //						for(let i=0;i<putOpr.length;i++){
        //							if(putOpr[i].oprUserName){
        //								this.step[i].txt=putOpr[i].oprUserName+putOpr[i].oprTime
        //							}
        //						}
        //					}
      });
    },
    setStatus: function setStatus() {
      var _this2 = this;

      // 通过审核
      var oprType = '';

      if (this.active === 1) {
        oprType = 2;
      } else {
        this.$message({
          message: '已经审核通过',
          type: 'warning'
        });
        return;
      }

      this.$confirm('此操作将通过审核, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _coupon.editPutTiggerStatu)(_this2.id, oprType, oprType).then(function (res) {
          if (res.ret === 1000) {
            _this2.$message({
              type: 'success',
              message: '审核成功!'
            });

            _this2.setActive(oprType);
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消撤销'
        });
      });
    },
    removeStatus: function removeStatus(index) {
      var _this3 = this;

      var str = '';
      var str1 = '';

      if (index === 2) {
        str1 = '审核';
        str = '此操作将通过审核方案，审核通过后将不可修改， 是否继续?';
      } else if (index === 3) {
        str1 = '撤回';
        str = '此操作将撤回审核， 是否继续?';
      } else if (index === 4) {
        str1 = '撤销';
        str = '此操作将撤销审核，撤销通过后将不可修改， 是否继续?';
      } else if (index === 6) {
        str1 = '终止';
        str = '此操作将终止方案，终止通过后将作废， 是否继续?';
      }

      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _coupon.editPutTiggerStatu)(_this3.id, index).then(function (res) {
          if (res.ret === 1000) {
            _this3.setActive(index);

            _this3.$message({
              type: 'success',
              message: str1 + '成功!'
            });
          }
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消' + str1
        });
      });
    }
  }
};
exports.default = _default;