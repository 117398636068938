"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    var _rules;

    return {
      formType: null,
      formData: [{
        label: '分类名称：',
        type: 'input',
        placeholder: '请输入支付通道名称',
        value: 'categoryName'
      }, {
        label: '状态：',
        type: 'radio',
        placeholder: '请选择状态',
        value: 'status',
        selectList: [{
          label: '启用',
          value: 1
        }, {
          label: '停用',
          value: -1
        }]
      }, {
        label: '排序：',
        type: 'input',
        placeholder: '请输入排序',
        value: 'sort'
      }, {
        label: '图标：',
        type: 'img',
        placeholder: '请上传图标',
        value: 'icon'
      }, {
        label: '备注：',
        type: 'input',
        placeholder: '请输入备注',
        value: 'note'
      }],
      ruleForm: {
        categoryName: null,
        id: null,
        status: null,
        sort: null,
        icon: null,
        note: null
      },
      rules: (_rules = {
        categoryName: [{
          required: true,
          message: '请输入分类名称'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        sort: [{
          required: true,
          message: '请输入排序'
        }]
      }, (0, _defineProperty2.default)(_rules, "categoryName", [{
        required: true,
        message: '请输入分类名称'
      }]), (0, _defineProperty2.default)(_rules, "icon", [{
        required: true,
        message: '请上传图标'
      }]), (0, _defineProperty2.default)(_rules, "note", [{
        required: true,
        message: '请输入备注'
      }]), _rules)
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    this.formType = this.$route.query.type;
    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/org-store/activity/getCtgById/' + this.ruleForm.id).then(function (res) {
        if (_this.formType == 'copy') {
          delete res.data.id;
        }

        _this.ruleForm = res.data;
      });
    }
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.apiPost('/api/org-store/activity/saveOrUpdateCtg', this.ruleForm).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;