var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("方案状态")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c(
          "div",
          { staticClass: "box_step" },
          [
            _c(
              "el-steps",
              { attrs: { active: _vm.active } },
              _vm._l(_vm.step, function(item, index) {
                return _c("el-step", {
                  key: index,
                  attrs: { title: item.tit, description: item.txt }
                })
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "box_btn" }, [
          _c("p", [
            _vm._v("当前状态："),
            _c("span", [_vm._v(_vm._s(_vm.status))])
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.btnType === 1 || _vm.btnType === 3
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "putTigger:edit",
                          expression: "'putTigger:edit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.goEditor }
                    },
                    [_vm._v("修改方案")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "putTigger:audit",
                          expression: "'putTigger:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.removeStatus(2)
                        }
                      }
                    },
                    [_vm._v("通过审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "putTigger:audit",
                          expression: "'putTigger:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.removeStatus(3)
                        }
                      }
                    },
                    [_vm._v("撤回审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1 || _vm.btnType === 3
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "putTigger:audit",
                          expression: "'putTigger:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.removeStatus(4)
                        }
                      }
                    },
                    [_vm._v("撤销审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 2
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "putTigger:audit",
                          expression: "'putTigger:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.removeStatus(6)
                        }
                      }
                    },
                    [_vm._v("终止投放")]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("方案内容")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c("ul", [
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("方案名称：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _c("span", { staticClass: "coupon_name" }, [
                  _vm._v(_vm._s(_vm.name))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("方案有效期：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.startTime) + " 至 " + _vm._s(_vm.endTime))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("触发条件：")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dea_box_li_coupon" }, [
                _c("p", { staticClass: "dea_box_li_txt" }, [
                  _vm._v("【" + _vm._s(_vm.tiggType) + "】")
                ]),
                _vm._v(" "),
                _vm.showType == 3
                  ? _c("div", [
                      _c("p", { staticClass: "dea_box_li_txt" }, [
                        _vm._v(
                          "充值纳入范围：" +
                            _vm._s(_vm.obj3.relevanceName) +
                            _vm._s(_vm.obj3.relevanceValue)
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "dea_box_li_txt" }, [
                        _vm._v("充值金额：" + _vm._s(_vm.tiggValue) + "元")
                      ])
                    ])
                  : _vm.showType == 1
                  ? _c("div", [
                      _c("p", { staticClass: "dea_box_li_txt" }, [
                        _vm._v("触发行为：" + _vm._s(_vm.tiggValue))
                      ])
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("投放数量：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.putNumber))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("领取限制：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.limitNumber) + "（每个用户限制获取数）")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("投放优惠券：")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dea_box_li_coupon" },
                _vm._l(_vm.couponIdBag, function(item, index) {
                  return _c(
                    "p",
                    { key: index, staticClass: "dea_box_li_txt" },
                    [
                      _c("span", { staticClass: "coupon_id" }, [
                        _vm._v(_vm._s(item.uuid))
                      ]),
                      _vm._v(
                        "\n                  -" +
                          _vm._s(item.typeName) +
                          "-\n                  "
                      ),
                      _c("span", { staticClass: "coupon_name" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _vm._v(" "),
                      item.validTime
                        ? _c("span", [
                            _vm._v(
                              "\n                    -（" +
                                _vm._s(item.validTime) +
                                "）\n                  "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("方案数据")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c("p", { staticClass: "deatil_tit none" }, [
          _vm._v("\n          方案未投放，暂无统计数据\n        ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }