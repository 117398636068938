"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon");

var _user = require("@/api/user.js");

var _mem = require("@/api/mem.js");

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _externalCodeList = _interopRequireDefault(require("./externalCodeList.vue"));

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    qrCodePop: _qrCodePop.default,
    AppLink: _Link.default,
    addLoad: _addLoad.default,
    externalCodeList: _externalCodeList.default,
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      /*
      追加库存弹窗
      */
      isAddStock: false,
      addStockCoupon: {},
      couponRecodes: [],
      downloadLoading: false,
      isExchange: false,
      ruleForm: {
        couponId: null,
        number: null,
        remark: null,
        token: null
      },
      rules: {
        number: [{
          required: true,
          message: '请输入数量'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        token: [{
          required: true,
          message: '请上传凭证'
        }]
      },
      imgSrc: this.$store.state.user.imgSrc,
      storeList: [],
      storeCode: '',
      couponUUid: '',
      popShow: false,
      // 小程序码弹框
      qrCodeShow: true,
      // 列表是否显示获取小程序码
      qrCode: '',
      // 小程序码
      url: '',
      // 小程序链接
      btnType: 'primary',
      btn: '查看详情',
      operation: 2,
      total: 0,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      memberArr: [],
      memberId: '',
      tabTh: [{
        tit: '优惠券名称',
        prop: 'name',
        width: 180
      }, {
        tit: '优惠券编码',
        prop: 'uuid',
        width: 160
      }, {
        tit: '所属总部',
        prop: 'memberName',
        width: 220
      }, {
        tit: '类型',
        prop: 'couponTypeText',
        width: 80
      }, {
        tit: '是否商户券',
        prop: 'merchantType',
        width: 100
      }, {
        tit: '投放数量',
        prop: 'totalNumberText',
        width: 80
      }, {
        tit: '限领数量',
        prop: 'limitNumber',
        width: 80
      }, {
        tit: '券面额',
        prop: 'denomination',
        width: 80
      }, {
        tit: '优惠券有效期',
        prop: 'time',
        width: 260
      }, {
        tit: '发布时间',
        prop: 'createTime',
        width: 140
      }, {
        tit: '状态',
        prop: 'statusText',
        width: 80
      }],
      time: '',
      uuid: '',
      name: '',
      issuer: '',
      merchantType: '',
      options: [// {
      //   value: -1,
      //   label: '全部类型'
      // },
      {
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣券'
      }, {
        value: 3,
        label: '礼品券'
      }, {
        value: 4,
        label: '体验券'
      }, {
        value: 5,
        label: '外部优惠券'
      }],
      couponType: '',
      options1: [// {
      //   value: -1,
      //   label: '全部状态'
      // },
      {
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '已审核'
      }, {
        value: 3,
        label: '已撤回'
      }, {
        value: 4,
        label: '已撤销'
      }, {
        value: 5,
        label: '已过期'
      }, {
        value: 6,
        label: '已终止'
      }],
      status: ''
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.memberArr = res.data;
    });
    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.storeList = res.data;
    });
  },
  watch: {
    isExchange: function isExchange(newValue, oldValue) {
      if (!newValue) {
        this.ruleForm.couponId = '';
        this.ruleForm.number = '';
        this.ruleForm.remark = '';
        this.ruleForm.token = '';
      }
    }
  },
  activated: function activated() {// this.getList()
  },
  methods: {
    /*
    确认追加券码
    */
    goAffrimAddStock: function goAffrimAddStock() {
      var _this2 = this;

      var data = {
        id: this.addStockCoupon.id,
        additionStock: this.couponRecodes.length,
        couponRecodes: this.couponRecodes
      };
      this.apiPost('/api/coupon/addStock', data).then(function (res) {
        _this2.isAddStock = false;
        _this2.addStockCoupon = {};
        _this2.couponRecodes = [];

        _this2.$message({
          message: '追加库存成功',
          type: 'success'
        });

        _this2.getList();
      });
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },

    /*
    导入券码
    */
    handleSuccess: function handleSuccess(_ref) {
      var _this3 = this;

      var results = _ref.results,
          header = _ref.header;
      this.couponRecodes = [];
      results.forEach(function (item) {
        if (item.receivedCode) {
          _this3.couponRecodes.push({
            receivedCode: item.receivedCode,
            receivedPwd: item.receivedPwd || ''
          });
        }
      });
    },

    /*
    追加库存弹窗
    */
    goAddStock: function goAddStock(row) {
      this.addStockCoupon = row;
      this.isAddStock = true;
    },
    // 打开券码抽屉
    handleOpenCodeList: function handleOpenCodeList(row) {
      var ref = this.$refs.externalCodeListRef;
      ref.open = true;
      ref.queryParams = {
        pageNo: 1,
        pageSize: 10,
        couponId: row.id
      };
      ref.getList();
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _coupon.produceReceivedCode)(_this4.ruleForm).then(function (res) {
            Promise.resolve().then(function () {
              return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
            }).then(function (excel) {
              var tHeader = ['券名称', '券uuid', '外联券领取编码'];
              var filterVal = ['couponName', 'couponCode', 'receivedCode'];
              var list = res.data;

              var data = _this4.formatJson(filterVal, list);

              excel.export_json_to_excel({
                header: tHeader,
                data: data,
                filename: list[0].couponName
              });
            });

            _this4.openLoading().close();
          }).catch(function (err) {
            _this4.openLoading().close();
          });
        } else {
          return false;
        }
      });
    },
    setCover: function setCover(e) {
      this.ruleForm.token = e;
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    getCode5: function getCode5(row) {
      var _this5 = this;

      this.ruleForm.couponId = row.id;
      this.isExchange = true;
      return;
      this.$prompt('请输入生成数量', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /(^[1-9]\d*$)/,
        inputErrorMessage: '请输入大于0的整数'
      }).then(function (_ref2) {
        var value = _ref2.value;
        var data = {
          couponId: row.id,
          number: Number(value)
        };

        _this5.openLoading();

        (0, _coupon.produceReceivedCode)(data).then(function (res) {
          Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
          }).then(function (excel) {
            var tHeader = ['券名称', '券uuid', '外联券领取编码'];
            var filterVal = ['couponName', 'couponCode', 'receivedCode'];
            var list = res.data;

            var data = _this5.formatJson(filterVal, list);

            excel.export_json_to_excel({
              header: tHeader,
              data: data,
              filename: row.name
            });
          });

          _this5.openLoading().close();
        }).catch(function (err) {
          _this5.openLoading().close();
        });
        return;

        if (row.totalNumber == 0 || row.totalNumber >= value) {
          var _data = {
            couponId: row.id,
            number: Number(value)
          };

          _this5.openLoading();

          (0, _coupon.produceReceivedCode)(_data).then(function (res) {
            Promise.resolve().then(function () {
              return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
            }).then(function (excel) {
              var tHeader = ['券名称', '券uuid', '外联券领取编码'];
              var filterVal = ['couponName', 'couponCode', 'receivedCode'];
              var list = res.data;

              var data = _this5.formatJson(filterVal, list);

              excel.export_json_to_excel({
                header: tHeader,
                data: data,
                filename: row.name
              });
            });

            _this5.openLoading().close();
          }).catch(function (err) {
            _this5.openLoading().close();
          });
        } else {
          _this5.$alert('生成数量必须不能大于投放数量', '提示', {
            confirmButtonText: '确定',
            callback: function callback(action) {
              _this5.getCode5(row);
            }
          });
        }
      }).catch(function () {});
    },
    setShort: function setShort(e) {
      var _this6 = this;

      this.storeCode = '';
      var companyId = '';
      this.memberArr.forEach(function (item) {
        if (item.companyCode == e) {
          companyId = item.companyId;
        }
      });
      (0, _mem.listStore)({
        companyId: companyId,
        pageNo: 1,
        pageSize: 1000
      }).then(function (res) {
        _this6.storeList = res.data;
      });
      this.getList('search');
    },
    setStatus: function setStatus(row, index) {
      var _this7 = this;

      this.$confirm('此操作将通过审核方案， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this7.openLoading('审核中');

        (0, _coupon.editCouponStatu)(row.id, 2).then(function (res) {
          _this7.tableData[index].status = 2;

          _this7.openLoading().close();

          _this7.$message({
            type: 'success',
            message: '审核成功'
          });
        }).catch(function (err) {
          _this7.openLoading().close();
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    /*
      上架下架
      */
    setIsOnline: function setIsOnline(row, index) {
      var _this8 = this;

      var str = '';
      var loadStr = '';
      var thenStr = '';

      if (row.isOnline == 1) {
        str = '此操作将下架该优惠券, 是否继续?';
        loadStr = '下架中';
        thenStr = '下架成功';
      } else {
        str = '此操作将上架该优惠券, 是否继续?';
        loadStr = '下架中';
        thenStr = '上架成功';
      }

      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = {
          id: row.id,
          isOnline: row.isOnline == 1 ? 0 : 1
        };

        _this8.openLoading(loadStr);

        (0, _coupon.updateCouponOnlineStatus)(data).then(function (res) {
          _this8.tableData[index].isOnline = row.isOnline == 1 ? 0 : 1;

          _this8.openLoading().close();

          _this8.$message({
            type: 'success',
            message: thenStr
          });
        }).catch(function (err) {
          _this8.openLoading().close();
        });
      }).catch(function () {
        _this8.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getCode: function getCode(scope) {
      var _this9 = this;

      // 获取小程序码
      this.couponUUid = scope.name;
      this.url = 'pages/coupons/couponDea/couponDea?id=' + scope.uuid;
      this.openLoading('获取小程序码中');
      (0, _user.couponQRCode)({
        path: 'pages/coupons/couponDea/couponDea',
        scene: 'id=' + scope.uuid // width:'2000',
        //  path: "pages/index/index",
        // scene: 'share_id=111201&type=1'

      }).then(function (res) {
        _this9.openLoading().close();

        _this9.qrCode = res.data;
        _this9.popShow = true;
      }).catch(function (res) {
        _this9.openLoading().close();
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/coupon/coupon1/addCoupon');
    },
    setBtn: function setBtn(scope) {
      this.$router.push('/coupon/coupon1/couponDeatil?id=' + scope.id); // this.$emit('setTab',2)
    },
    handleDownload: function handleDownload() {
      var _this10 = this;

      this.downloadLoading = true;
      var tHeader = ['人群标签'];
      var filterVal = ['labelContent'];
      var list = [];
      this.tabTh.forEach(function (item) {
        if (item.prop && item.tit) {
          tHeader.push(item.tit);
          filterVal.push(item.prop);
        }
      });
      this.time = this.time ? this.time : [];
      var data = {
        pageNo: 1,
        pageSize: this.total,
        couponType: this.couponType,
        uuid: this.uuid.trim(),
        status: this.status,
        name: this.name.trim(),
        createBeginTime: this.time[0] || '',
        createEndTime: this.time[1] || '',
        memberId: this.memberId,
        storeCode: this.storeCode,
        merchantType: this.merchantType
      };
      (0, _coupon.listCouponPage)(data).then(function (res) {
        list = res.data.items;
        list.forEach(function (item) {
          item.labelContent = _this10.getUserLabel(item.labelContent);
        });
        _this10.downloadLoading = false;

        _this10.getHandleDownload(tHeader, filterVal, list, '优惠券列表');
      }).catch(function (res) {
        _this10.downloadLoading = false;
      });
    },
    getList: function getList(type) {
      var _this11 = this;

      if (type === 'search') {
        this.pageNo = 1;
        this.pageSize = 10;
      }

      this.time = this.time ? this.time : [];
      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        couponType: this.couponType,
        uuid: this.uuid.trim(),
        status: this.status,
        name: this.name.trim(),
        createBeginTime: this.time[0] || '',
        createEndTime: this.time[1] || '',
        memberId: this.memberId,
        storeCode: this.storeCode,
        merchantType: this.merchantType
      };
      this.openLoading();
      (0, _coupon.listCouponPage)(data).then(function (res) {
        _this11.openLoading().close();

        var data = res.data.items;

        if (res.ret === 1000) {
          data.filter(function (value, key, arr) {
            switch (value.buyType) {
              case 1:
                value.price = value.price > 0 ? value.price + '元' : '免费领取';
                break;

              case 2:
                value.price = value.integral > 0 ? value.integral + '积分' : '免费领取';
                break;

              case 3:
                value.price = value.integral > 0 || value.price > 0 ? value.price + '元+' + value.integral + '积分' : '免费领取';
                break;

              default:
                break;
            }

            value.limitNumber = value.limitNumber == 0 ? '无限量' : value.limitNumber + '张';
            value.totalNumberText = value.totalNumber == 0 ? '无限量' : value.totalNumber + '张';
            value.denomination = value.couponType === 2 ? value.discount / 10 + '折' : value.denomination + '元'; // if(value.couponType === 2){
            //   value.denomination=
            // }

            value.createTime = value.createTime; // .substring(0, 10)

            if (value.validTimeType === 1) {
              value.time = value.validBeginTime + '至' + value.validEndTime;
            } else if (value.validTimeType === 2) {
              value.time = "\u9886\u53D6\u540E".concat(value.validStartDay || 0, "\u5929\u5230").concat(value.validDay, "\u5929\u5185\u6709\u6548");
            }

            if (value.merchantType === 1) {
              value.merchantType = '商户券（乐购使用）';
            } else {
              value.merchantType = '非商户券';
            }

            if (value.couponType === 1) {
              value.couponTypeText = '代金券';
            } else if (value.couponType === 2) {
              value.couponTypeText = '折扣券';
            } else if (value.couponType === 3) {
              value.couponTypeText = '礼品券';
            } else if (value.couponType === 4) {
              value.couponTypeText = '体验券';
            } else if (value.couponType === 5) {
              value.couponTypeText = '外部优惠券';
            }

            switch (value.status) {
              case 1:
                value.statusText = '待审核';
                break;

              case 2:
                value.statusText = '已审核';
                break;

              case 3:
                value.statusText = '审核撤回';
                break;

              case 4:
                value.statusText = '审核撤销';
                break;

              case 5:
                value.statusText = '已过期';
                break;

              case 6:
                value.statusText = '已终止';
                break;

              case 8:
                value.statusText = '已投放';
                break;

              case 9:
                value.statusText = '投放完成';
                break;
            }
          });
          _this11.tableData = data;
          _this11.total = res.data.totalNum;
        }
      }).catch(function (res) {
        _this11.openLoading().close();
      });
    },
    remove: function remove() {
      this.time = [];
      this.uuid = '';
      this.name = '';
      this.couponType = '';
      this.status = '';
      this.memberId = '';
      this.merchantType = '';
      this.storeCode = '';
      this.getList('search');
    }
  }
};
exports.default = _default;