"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _mem = require("@/api/mem.js");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _list = _interopRequireDefault(require("@/components/addLoad/list.vue"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _image = _interopRequireDefault(require("./image.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    Tinymce: _Tinymce.default,
    addLoadList: _list.default,
    selectAward: _radioItem.default,
    UploadImage: _image.default
  },
  data: function data() {
    var _this = this;

    return {
      /*
      顶部banner： topBanner;
       抽奖页面背景图： backgroundImage;
       抽奖按钮图：buttonImage;
       抽奖按钮字体色： buttonFontColour;
       页面背景颜色： backgroundColour;
      转盘底色： turntableColour;
       转盘选项未选中颜色： turntableNotSelectedColour;
      转盘选项选中颜色： turntableSelectedColour;
      <el-radio :label="1">大转盘</el-radio>
      <el-radio :label="2">九宫格</el-radio>
      <el-radio :label="3">老虎机</el-radio>
      <el-radio :label="4">扭蛋机</el-radio>
      <el-radio :label="5">盲盒</el-radio>
      */
      pondDataLabel: {
        topBanner: {
          1: " 顶部banner",
          2: " 顶部banner",
          3: " 顶部banner",
          4: " 顶部banner",
          5: " 顶部banner"
        },
        backgroundImage: {
          1: "抽奖页面背景图",
          2: "抽奖页面背景图",
          3: "抽奖页面背景图",
          4: "抽奖页面背景图",
          5: "抽奖右下图"
        }
      },
      rulesList: [],
      disabled: false,
      companylist: [],
      popType: '',
      couponShow: false,
      count: 0,
      type: [{
        label: '优惠券',
        value: 1
      }, {
        label: '商品',
        value: 2
      }, {
        label: '谢谢参与',
        value: 3
      }, {
        label: '积分',
        value: 4
      }],
      rules: {
        topBanner: [{
          required: true,
          message: '请上传顶部banner'
        }],
        popImage: [{
          required: true,
          message: '请上传弹窗图片'
        }],
        backgroundImage: [{
          required: true,
          message: '请上传抽奖页面背景图'
        }],
        buttonImage: [{
          required: true,
          message: '请上传抽奖按钮图'
        }],
        buttonFontColour: [{
          required: true,
          message: '请选择抽奖按钮字体色'
        }],
        backgroundColour: [{
          required: true,
          message: '请选择页面背景颜色'
        }],
        turntableColour: [{
          required: true,
          message: '请选择转盘底色'
        }],
        turntableNotSelectedColour: [{
          required: true,
          message: '请选择转盘选项未选中颜色'
        }],
        turntableSelectedColour: [{
          required: true,
          message: '请选择转盘选项选中颜色'
        }],
        colorCode: [{
          required: true,
          message: '请选择背景色'
        }],
        name: [{
          required: true,
          message: '请填写活动名称'
        }],
        type: [{
          required: true,
          message: '请选择活动类型'
        }],
        method: [{
          required: true,
          message: '请选择抽奖形式'
        }],
        time: [{
          required: true,
          message: '请填写抽奖次数'
        }],
        instr: [{
          required: true,
          message: '请填写规则说明'
        }],
        score: [{
          required: true,
          message: '请输入消耗积分'
        }],
        activityTime: [{
          required: true,
          message: '请选择活动时间'
        }],
        orgCode: [{
          required: true,
          message: '请选择主办机构'
        }],
        image: [{
          required: true,
          message: '请上传活动背景'
        }],
        shareTitle: [{
          required: true,
          message: '请输入分享标题'
        }],
        shareImage: [{
          required: true,
          message: '请上传分享图片'
        }],
        drawItemParams: [{
          required: true,
          message: '请填写奖励项'
        }, {
          validator: function validator(rule, value, callback) {
            if (value.length !== 8 && _this.form.type === 2) {
              callback(new Error('九宫格为8条数据'));
            } else if (value.length !== 6 && _this.form.type === 1) {
              callback(new Error('大转盘为6条数据'));
            } else {
              callback();
            }
          }
        }]
      },
      form: {
        colorCode: '',
        code: '',
        time: undefined,
        method: '',
        type: '',
        name: '',
        score: undefined,
        activityTime: '',
        orgCode: '',
        orgName: '',
        image: '',
        shareTitle: '',
        shareImage: '',
        instr: '',
        drawItemParams: [],
        topBanner: [],
        backgroundImage: null,
        popImage: null,
        ruleId: null,
        buttonImage: null,
        buttonFontColour: null,
        backgroundColour: null,
        turntableColour: null,
        turntableNotSelectedColour: null,
        turntableSelectedColour: null
      }
    };
  },
  created: function created() {
    var _this2 = this;

    this.apiPost('/api/DrawRule/rulePage', {
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      _this2.rulesList = res.data.items.filter(function (item) {
        return item.status == 1;
      });
    });
    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      console.log(res);
      _this2.companylist = res.data;
    });

    if (this.$route.query.id) {
      this.disabled = true;
      (0, _api.lotteryDetail)({
        code: this.$route.query.id
      }).then(function (res) {
        if (res.data.topBanner) {
          res.data.topBanner = JSON.parse(res.data.topBanner);
        }

        Object.keys(_this2.form).forEach(function (key) {
          if (res.data[key]) {
            _this2.form[key] = res.data[key];
          }
        });
        res.data.drawItemVos.forEach(function (item) {
          item.prizeDescrition = item.prizeDescrition || '';
        });
        _this2.form.score = res.data.score;
        _this2.form.drawItemParams = res.data.drawItemVos;
        _this2.form.activityTime = [res.data.startTime, res.data.endTime];
      });
    }
  },
  methods: {
    setBanner: function setBanner(e, index) {
      this.$set(this.form.topBanner, index, e); //this.form.topBanner[index] = e
    },
    setCoverList: function setCoverList(e, type) {
      this.form[type].push(e);
    },
    typeChange: function typeChange(e) {
      /* if (this.form.drawItemParams.length === 0) {
        const data = {
          type: '',
          name: '',
          num: '',
          winPro: '',
          sort: '',
          prizeName: '',
          prizeImage: ''
        }
        if (e === 1) {
          this.form.drawItemParams = Array(6).fill(null).map((item) => {
            return {
              type: '',
              name: '',
              num: '',
              winPro: '',
              sort: '',
              prizeName: '',
              prizeImage: ''
            }
          })
        } else {
          this.form.drawItemParams = Array(8).fill(null).map((item) => {
            return {
              type: '',
              name: '',
              num: '',
              winPro: '',
              sort: '',
              prizeName: '',
              prizeImage: ''
            }
          })
        }
      } */
    },
    numChange: function numChange(e, index) {
      var _this3 = this;

      if (!this.disabled) return;
      console.log(e);

      if (e < this.form.drawItemParams[index].drawOutNumber) {
        this.$nextTick(function () {
          _this3.form.drawItemParams[index].totalNumber = _this3.form.drawItemParams[index].drawOutNumber;
        });
      }
    },
    setCode: function setCode(e) {
      var _this4 = this;

      console.log(e);
      this.companylist.forEach(function (item) {
        if (item.storeCode == e) {
          _this4.form.orgName = item.shortName;
        }
      });
    },

    /*
      打开弹框
      */
    getAward: function getAward(e, index) {
      this.count = index;

      if (e == 1) {
        this.popType = 'coupon';
      } else {
        this.popType = 'goods';
      }

      this.$refs.coupon1.getList(this.popType, this.form.drawItemParams[index].name);
      this.couponShow = true;
    },

    /*
      选择ID后
      */
    setCoupon: function setCoupon(e) {
      console.log(e);

      if (this.popType == 'coupon') {
        this.form.drawItemParams[this.count].name = e.uuid;
        this.form.drawItemParams[this.count].prizeName = e.name;
        this.form.drawItemParams[this.count].prizeImage = e.mainImage;
        this.form.drawItemParams[this.count].prizeDescrition = e.descrition;
      }

      if (this.popType == 'goods') {
        this.form.drawItemParams[this.count].name = e.productCode;
        this.form.drawItemParams[this.count].prizeName = e.productName;
        this.form.drawItemParams[this.count].prizeImage = e.imageUrl;
        this.form.drawItemParams[this.count].prizeDescrition = e.productInstr;
      }

      this.couponShow = false;
      this.popType = '';
    },

    /*
      关闭弹框
      */
    closeCouponPop: function closeCouponPop() {
      this.couponShows = false;
    },

    /*
      删除奖励项
      */
    delAward: function delAward(index) {
      this.form.drawItemParams.splice(index, 1);
    },

    /*
      选择奖励项
      */
    setAward: function setAward(e, index) {
      this.form.drawItemParams[index].name = '';
      this.form.drawItemParams[index].prizeName = '';
      this.form.drawItemParams[index].prizeDescrition = '';

      if (e == 3) {
        this.form.drawItemParams[index].name = '谢谢参与';
      }
    },

    /*
      添加奖励项
      */
    addAward: function addAward() {
      var _this$form = this.form,
          type = _this$form.type,
          drawItemParams = _this$form.drawItemParams;

      if (!type) {
        this.$message.error('请先选择抽奖类型');
        return;
      }

      if (type === 2 && drawItemParams.length >= 8) {
        this.$message.error('九宫格抽奖，最多添加8项');
        return;
      }

      if (type === 1 && drawItemParams.length >= 6) {
        this.$message.error('大转盘抽奖，最多添加6项');
        return;
      }

      var row = {
        type: '',
        name: '',
        num: '',
        winPro: '',
        sort: '',
        prizeName: '',
        prizeImage: '',
        prizeDescrition: '',
        titleText: ''
      };
      this.form.drawItemParams.push(row);
    },

    /* 分享图片*/
    setShare: function setShare(e) {
      this.form.shareImage = e;
    },

    /* 活动背景*/
    setCover: function setCover(e, prop) {
      this.form[prop] = e;
    },
    onSubmit: function onSubmit(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          _this5.openLoading();

          var data = JSON.parse(JSON.stringify(_this5.form));
          data.startTime = data.activityTime[0];
          data.endTime = data.activityTime[1];
          data.topBanner = JSON.stringify(_this5.form.topBanner);

          if (_this5.form.code) {
            data.drawItemParams.forEach(function (item) {
              delete item.num;
            });
            (0, _api.lotteryEdit)(data).then(function (res) {
              _this5.openLoading().close();

              _this5.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this5.$router.go(-1);
            }).catch(function () {
              _this5.openLoading().close();
            });
          } else {
            if (data.type != 5) {
              data.ruleId = null;
            }

            (0, _api.lotteryAdd)(data).then(function (res) {
              _this5.openLoading().close();

              _this5.$message({
                message: '保存成功',
                type: 'success'
              });

              _this5.$router.go(-1);
            }).catch(function (err) {
              _this5.openLoading().close();
            });
          }
        } else {
          var data = Object.keys(object);
          var dom = _this5.$refs[data[0]];
          dom.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
        }
      });
    }
  }
};
exports.default = _default;