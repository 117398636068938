"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      time: null,
      requestData: {
        name: null,
        startDate: null,
        endDate: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '标题',
        prop: 'title',
        width: 100
      }, {
        label: '短标题',
        prop: 'shortTitle',
        width: 60
      }, {
        label: '门店',
        prop: 'storeId',
        width: 50
      }, {
        slot: 'merchantId',
        label: '商户',
        prop: 'merchantId',
        width: 50
      }, {
        label: '开始时间',
        prop: 'startTime',
        width: 110
      }, {
        label: '结束时间',
        prop: 'endTime',
        width: 110
      }, {
        label: '更新时间',
        prop: 'updateTime',
        width: 110
      },
      /* {
        slot: 'isEnable',
        label: '激活',
        prop: 'isEnable'
      },*/
      {
        slot: 'isIndex',
        label: '是否首页',
        prop: 'isIndex'
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status'
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(val) {
      /*
      开始时间 startDate 结束时间 endDate
      */
      if (val) {
        this.requestData.startDate = val[0];
        this.requestData.endDate = val[1];
      } else {
        this.requestData.startDate = null;
        this.requestData.endDate = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {
    this.getList();
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this.requestData[key]) {
          data[key] = _this.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getList)(data).then(function (res) {
        list = res.data;
        _this.downloadLoading = false;

        _this.getHandleDownload(tHeader, filterVal, list, '报名活动列表');
      }).catch(function (res) {
        _this.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this2 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getList)(this.requestData).then(function (res) {
        /* console.log(res)
        return
        res.data.records.forEach((item) => {
          item.merchantId = item.merchantId ? JSON.parse(item.merchantId) : []
        }) */
        _this2.tableData = res.data.records;
        _this2.requestData.total = res.data.total;
      });
    }
  }
};
exports.default = _default;