"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCitylife = addCitylife;
exports.addCitylifeClass = addCitylifeClass;
exports.editCitylife = editCitylife;
exports.editCitylifeClass = editCitylifeClass;
exports.getCitylife = getCitylife;
exports.getCitylifeClass = getCitylifeClass;
exports.listCitylifeClass = listCitylifeClass;
exports.listCitylifePage = listCitylifePage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
1、新增会员同城分类：/api/vipCitylifeClass/addCitylifeClass
{"vipCode":"会员编码","className":"分类名称","classSort":"分类排序","classStatus":" 分类状态(0：停用 1：启用)"}

2、编辑会员同城分类：/api/vipCitylifeClass/editCitylifeClass
{"id":"id","vipCode":"会员编码","className":"分类名称","classSort":"分类排序","classStatus":" 分类状态(0：停用 1：启用)"}

3、会员同城分类明细：/api/vipCitylifeClass/getCitylifeClass/{id}

4、会员同城分类列表：/api/vipCitylifeClass/listCitylifeClass
{"pageNo":"当前页","pageSize":"页条数","vip_code":"会员编码","class_status":"分类状态"}



 */
function addCitylifeClass(data) {
  return (0, _request.default)({
    url: '/api/vipCitylifeClass/addCitylifeClass',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editCitylifeClass(data) {
  return (0, _request.default)({
    url: '/api/vipCitylifeClass/editCitylifeClass',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getCitylifeClass(data) {
  return (0, _request.default)({
    url: '/api/vipCitylifeClass/getCitylifeClass/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function listCitylifeClass(data) {
  return (0, _request.default)({
    url: '/api/vipCitylifeClass/listCitylifeClass',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
5、新增会员同城生活：/api/vipCitylife/addCitylife
{"citylifes":"[{"vipCode":"会员编码","classId":"分类id","productCode":"商品编码","productType":"商品类型(1：积分商城商品 2：直充商品 3：优惠券)","putNumber":"投放数量","productSort":"商品排序"}]"}

6、编辑会员同城生活：/api/vipCitylife/editCitylife
{"id":"id","vipCode":"会员编码","classId":"分类id","productCode":"商品编码","productType":"商品类型(1：积分商城商品 2：直充商品 3：优惠券)","putNumber":"投放数量","productSort":"商品排序"}

7、会员同城生活明细：/api/vipCitylife/getCitylife/{id}

8、会员同城生活分页：/api/vipCitylife/listCitylifePage
{"pageNo":"当前页","pageSize":"页条数","vipCode":"会员编码","classId":"分类id","productCode":"商品编码"}
 */


function listCitylifePage(data) {
  return (0, _request.default)({
    url: '/api/vipCitylife/listCitylifePage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addCitylife(data) {
  return (0, _request.default)({
    url: '/api/vipCitylife/addCitylife',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editCitylife(data) {
  console.log(JSON.stringify(data));
  return (0, _request.default)({
    url: '/api/vipCitylife/editCitylife',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getCitylife(data) {
  return (0, _request.default)({
    url: '/api/vipCitylife/getCitylife/' + data,
    method: 'post',
    loading: 'loading'
  });
}