var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 推广人指标 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "200px",
            size: "small",
            disabled: _vm.disabled
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { "max-width": "600px" },
              attrs: { data: _vm.ruleForm.kpis, size: "mini", border: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "职务" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "0" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { disabled: "" },
                                model: {
                                  value: scope.row.position,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "position", $$v)
                                  },
                                  expression: "scope.row.position"
                                }
                              },
                              _vm._l(_vm.positions, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "kpi数量", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            ref: "kpis." + scope.$index + ".number",
                            attrs: {
                              "label-width": "0",
                              prop: "kpis." + scope.$index + ".number",
                              required: "",
                              rules: [{ required: true, message: "请输入数量" }]
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                placeholder: "输入kpi数量",
                                type: "number"
                              },
                              model: {
                                value: scope.row.number,
                                callback: function($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "number",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "scope.row.number"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "200px", "margin-top": "30px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }