var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [_c(_vm.currentRole, { tag: "component", on: { setTab: _vm.setTab } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }