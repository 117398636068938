"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.find");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _finance = require("@/api/finance.js");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _list = _interopRequireDefault(require("./list"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    HistoryList: _list.default
  },
  data: function data() {
    var _ref;

    return _ref = {
      canEmail: 0,
      // 是否可以发邮件 1可以 0不可以
      canRedRush: 0,
      // 是否可以发红冲 1可以 0不可以
      canReOpen: 0,
      // 是否可以重开 1可以 0不可以
      status: 1,
      // 开票状态
      sendId: '',
      // 重发邮箱ID
      newEmail: '',
      // 重发邮箱

      /* 订单号/流水号查询*/
      rqsj: '',
      // 开票时间
      ID: '',
      xsf_nsrbh: '',
      jshj: '',
      // 税价合计
      sl: [],
      //
      xmmc: [],
      xmjshj: [],
      spbm: [],
      spdj: [],
      spsl: [],
      ggxh: [],
      jldw: [],
      draw: 0,
      drawStatus: false,
      operationType: 1,
      restaurants: [],
      state: '',
      id: '',
      sStoreId: '',
      sStoreIds: [{
        value: '01001011',
        label: '乐品中华老店'
      }, {
        value: '12001',
        label: '荔星名店B座'
      }, {
        value: '12002',
        label: '星力瑞金店'
      }, {
        value: '12003',
        label: '荔星名店A座'
      }, {
        value: '12012',
        label: '星力城贵阳汇金店'
      }, {
        value: '12011',
        label: '荔星中心'
      }, {
        value: '01001001',
        label: '乐品中华超市'
      }, {
        value: '01002001',
        label: '乐品富源超市'
      }, {
        value: '01001002',
        label: '乐品瑞金超市'
      }, {
        value: '02001006',
        label: '乐品便利店友谊路店'
      }, {
        value: '02002007',
        label: '乐品便利店青云路店'
      }, {
        value: '11004',
        label: '贵阳星力广场超市'
      }, {
        value: '01002002',
        label: '乐品望城超市'
      }, {
        value: '01006001',
        label: '乐品白云南路超市'
      }, {
        value: '01001003',
        label: '乐品小石城店'
      }, {
        value: '01001004',
        label: '乐品中天超市'
      }, {
        value: '01011001',
        label: '乐品遵义超市C'
      }, {
        value: '01005001',
        label: '乐品花溪明珠超市'
      }, {
        value: '01001005',
        label: '乐品元隆超市'
      }, {
        value: '01002003',
        label: '乐品裕阳超市'
      }, {
        value: '01007001',
        label: '乐品清镇云岭路店'
      }, {
        value: '11014',
        label: '遵义星力遵义超市A'
      }, {
        value: '11015',
        label: '乐品星小铺'
      }, {
        value: '01001006',
        label: '乐品日月园超市'
      }, {
        value: '01003001',
        label: '乐品金华园超市'
      }, {
        value: '11018',
        label: '贵阳星力汇金IU馆'
      }, {
        value: '01003002',
        label: '乐品美的林城超市'
      }, {
        value: '01003003',
        label: '乐品中铁逸都超市'
      }, {
        value: '01003004',
        label: '乐品会展城超市'
      }, {
        value: '11022',
        label: '遵义星力遵义常青藤超市'
      }, {
        value: '01003005',
        label: '乐品观山湖1号超市'
      }, {
        value: '11024',
        label: '乐品裕华超市'
      }, {
        value: '11025',
        label: '贵阳星力凤凰湾超市'
      }, {
        value: '01003006',
        label: '乐品碧海广场超市'
      }, {
        value: '01001007',
        label: '乐品蔡家街超市'
      }, {
        value: '01005002',
        label: '乐品花溪万宜超市'
      }, {
        value: '01003007',
        label: '乐品恒大金朱东路店'
      }, {
        value: '01001008',
        label: '乐品万科云岩大都会店'
      }, {
        value: '01005003',
        label: '乐品南山高地'
      }, {
        value: '01099009',
        label: '贵阳星力配送中心'
      }, {
        value: '01002004',
        label: '乐品南厂路店'
      }],
      title: '开票',
      nextTxt: '确认开票',
      sId: '',
      peopleTel: '',
      email: '',
      createTime: '',
      name: '',
      tableData: [],
      rate: '',
      rates: [{
        value: '0.00',
        label: '0.00'
      }, {
        value: '0.03',
        label: '0.03'
      }, {
        value: '0.05',
        label: '0.05'
      }, {
        value: '0.06',
        label: '0.06'
      }, {
        value: '0.09',
        label: '0.09'
      }, {
        value: '0.13',
        label: '0.13'
      }],
      type: '',
      headCode: '',
      workAddress: '',
      workTel: '',
      account: '',
      bank: '',
      fphm: '',
      fpdm: '',
      pdfUrl: '',
      bz: '',
      // 备注
      credentials: [],
      imgSrc: this.$store.state.user.imgSrc
    }, (0, _defineProperty2.default)(_ref, "peopleTel", undefined), (0, _defineProperty2.default)(_ref, "detailInfo", {}), (0, _defineProperty2.default)(_ref, "typeOptions", [{
      val: '1',
      lab: '企业'
    }, {
      val: '3',
      lab: '非企业型组织'
    }, {
      val: '2',
      lab: '个人'
    }]), (0, _defineProperty2.default)(_ref, "specialInvoiceStatusOptions", [{
      val: -1,
      lab: '开票审核不通过'
    }, {
      val: 0,
      lab: '专票：开票中 普票：待审'
    }, {
      val: 1,
      lab: '成功'
    }, {
      val: 2,
      lab: '开票失败'
    }]), (0, _defineProperty2.default)(_ref, "openChecked", false), (0, _defineProperty2.default)(_ref, "checkForm", {
      status: '',
      remark: '',
      id: 0
    }), (0, _defineProperty2.default)(_ref, "checkRules", {
      status: [{
        required: true,
        message: '请选择审核方案',
        trigger: 'change'
      }],
      remark: [{
        required: true,
        message: '请输入备注',
        trigger: 'blur'
      }]
    }), _ref;
  },
  created: function created() {
    this.init();
  },
  methods: {
    // 审核
    handleCheck: function handleCheck(open) {
      var _this = this;

      if (open == 'open') {
        if (this.$refs.checkForm) {
          this.$refs.checkForm.resetFields();
        }

        this.checkForm = {
          status: '',
          remark: '',
          id: this.detailInfo.id
        };
        this.openChecked = true;
        return;
      }

      this.$refs.checkForm.validate(function (valid) {
        if (!valid) return;
        (0, _finance.checkedInvoice)(_this.checkForm).then(function (res) {
          _this.$message.success('审核成功');

          _this.openChecked = false; // this.init()

          _this.$router.push('/finance/invoice/openInvoice');
        });
      });
    },
    // 列表格式化
    tableFormatter: function tableFormatter(row, _ref2) {
      var property = _ref2.property;
      var options = [];
      var val = '';

      if (property == 'type') {
        options = this.typeOptions;
        val = row.type;
      }

      if (property == 'specialInvoiceStatus') {
        options = this.specialInvoiceStatusOptions;
        val = row.specialInvoiceStatus;
      }

      if (!val || options.length == 0) return '';
      return options.find(function (item) {
        return item.val == val;
      }).lab;
    },
    handleRemoveImage: function handleRemoveImage(index) {
      this.credentials.splice(index, 1);
    },
    handleUploadSuccess: function handleUploadSuccess(data) {
      this.credentials.push(data);
    },
    typeChange: function typeChange() {
      this.headCode = '';
    },
    anewEmail: function anewEmail() {
      var _this2 = this;

      this.$prompt('请输入重发邮箱', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        inputValue: this.email,
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: '邮箱格式不正确'
      }).then(function (_ref3) {
        var value = _ref3.value;
        console.log(value); // return

        if (value.trim() === '') {
          _this2.$message.error('请填入邮箱');

          return;

          _this2.anewEmail();
        } else {
          var data = {
            email: value,
            id: _this2.sendId
          };

          _this2.openLoading('重发邮箱中');

          (0, _finance.sendEmail)(data).then(function (res) {
            _this2.openLoading().close;

            _this2.$message({
              type: 'success',
              message: '重发邮箱成功'
            });
          }).catch(function (res) {
            _this2.openLoading().close;
          });
        }
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },

    /* 发票冲红*/
    goRushRed: function goRushRed() {
      var _this3 = this;

      this.$confirm('此操作将进行冲红, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.openLoading('发票冲红中');

        (0, _finance.rushRed)({
          id: _this3.id
        }).then(function (res) {
          _this3.openLoading().close();

          _this3.$message({
            message: '恭喜你，发票冲红成功',
            type: 'success'
          });

          _this3.$router.push('/invoiceManage');
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消冲红'
        });
      });
    },

    /* 设置data*/
    setData: function setData(data) {
      var _this4 = this;

      this.title = '发票详情';
      console.log(data);
      this.canEmail = data.canEmail;
      this.canRedRush = data.canRedRush;
      this.canReOpen = data.canReOpen;
      var arr = data.spbm;
      arr.forEach(function (item, index) {
        console.log(data.sl[index]);

        _this4.tableData.push({
          spbm: item,
          sl: data.sl[index],
          xmmc: data.xmmc[index],
          xmjshj: data.xmjshj[index],
          spdj: data.spdj[index],
          spsl: data.spsl[index],
          ggxh: data.ggxh[index],
          jldw: data.jldw[index] // xmsl: data.xmsl[index]

        });
      });
      this.sId = data.sid;
      this.sendId = data.id;
      this.sStoreId = data.storeId;
      console.log('sdfsdfsdf');
      /* this.sl = data.sl
        this.spbm = data.spbm
        this.xmmc = data.xmmc
        this.xmjshj = data.xmjshj */

      this.status = data.status;
      this.drawStatus = data.drawStatus;
      this.createTime = data.createTime;
      this.email = data.email || '';
      this.peopleTel = data.peopleTel;
      this.name = data.name;
      this.fpdm = data.fpdm;
      this.fphm = data.fphm;
      this.pdfUrl = data.pdfUrl;
      this.jshj = data.jshj;
      this.orgcode = data.orgcode;
      this.rqsj = data.rqsj;
      this.spdj = data.spdj;
      this.spsl = data.spsl;
      this.headCode = data.headCode || '';
      this.bank = data.bank || '';
      this.type = String(data.type);
      this.workTel = data.workTel || '';
      this.workAddress = data.workAddress || '';
      this.account = data.account || '';
      this.bz = data.bz || '';
      this.credentials = data.credentials && data.credentials.length > 0 ? data.credentials.split(',') : [];
    },

    /* 有id请求*/
    init: function init() {
      var _this5 = this;

      var data = this.$route.query.id ? Number(this.$route.query.id) : '';
      this.id = data;

      if (data) {
        (0, _finance.invoiceDetail)({
          id: data
        }).then(function (res) {
          var datas = res.data;
          datas.sl = JSON.parse(datas.sl);
          datas.spbm = JSON.parse(datas.spbm);
          datas.xmjshj = JSON.parse(datas.xmjshj);
          datas.spsl = JSON.parse(datas.spsl);
          datas.xmmc = JSON.parse(datas.xmmc);
          datas.spdj = JSON.parse(datas.spdj);
          datas.ggxh = datas.ggxh ? JSON.parse(datas.ggxh) : [];
          datas.jldw = datas.jldw ? JSON.parse(datas.jldw) : [];
          _this5.peopleTel = res.data.peopleTel;
          _this5.detailInfo = datas;

          _this5.setData(datas);
        });
      } else {
        this.status = '';
      }
    },

    /* 合计*/
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });

        if (!values.every(function (value) {
          return isNaN(value);
        }) && column.property === 'xmjshj') {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);

            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' 元';
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },

    /* 订单号/流水号查询*/
    goInquire: function goInquire() {
      var _this6 = this;

      if (this.sId.trim() === '') {
        this.$message.error('请填入订单号/流水号查询');
      } else if (this.sStoreId.trim() === '') {
        this.$message.error('请填入机构编码查询');
      } else {
        var data = {
          sid: this.sId.trim(),
          storeId: this.sStoreId
        };
        this.openLoading('加载中');
        (0, _finance.invoiceInfo)(data).then(function (res) {
          _this6.openLoading().close();

          _this6.status == '';
          _this6.jshj = res.data.data.jshj;
          _this6.orgcode = res.data.data.orgcode;
          _this6.rqsj = res.data.data.rqsj;
          _this6.tableData = res.data.data.items || [];
          var dataLen = _this6.tableData.length;

          for (var i = 0; i < dataLen; i++) {
            _this6.sl.push(_this6.tableData[i].sl);

            _this6.xmjshj.push(_this6.tableData[i].xmjshj);

            _this6.spbm.push(_this6.tableData[i].spbm);

            _this6.xmmc.push(_this6.tableData[i].xmmc);

            _this6.spdj.push(_this6.tableData[i].spdj);

            _this6.spsl.push(_this6.tableData[i].spsl);

            _this6.xmsl.push(_this6.tableData[i].xmsl);

            _this6.ggxh.push(_this6.tableData[i].ggxh);

            _this6.jldw.push(_this6.tableData[i].jldw);
          }
        }).catch(function (res) {
          _this6.openLoading().close();
        });
      }
    },

    /* 确认开票 */
    openInvoice: function openInvoice() {
      var _this7 = this;

      if (this.sId.trim() === '' || this.sStoreId.trim() === '' || this.tableData.length === 0) {
        this.$message.error('请填入订单号/流水号查询');
      } else if (!/^1[3456789]\d{9}$/.test(this.peopleTel)) {
        this.$message.error('请填入手机号'); // } else if (!(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.email))) {
      } else if (this.type === '') {
        /*  else if (this.email.trim() === '') {
            this.$message.error('请填入邮箱');
          } */
        this.$message.error('请选择开票主体类型');
      } else if (this.name === '') {
        this.$message.error('请填入名称');
      } else if (this.headCode === '' && this.type == 1) {
        this.$message.error('请填入纳税人识别号');
      } else {
        var data = {
          sid: this.sId,
          storeId: this.sStoreId,
          status: 1,
          id: this.id,
          orgcode: this.orgcode,
          rqsj: this.rqsj,
          jshj: this.jshj,
          // operationType: this.operationType,
          invoiceInfo: {
            type: this.type,
            name: this.name,
            email: this.email,
            peopleTel: this.peopleTel,
            headCode: this.headCode,
            workAddress: this.workAddress,
            workTel: this.workTel,
            bank: this.bank,
            account: this.account
          },
          sl: [],
          xmmc: [],
          xmjshj: [],
          spbm: [],
          spsl: [],
          spdj: [],
          ggxh: [],
          jldw: [],
          bz: this.bz,
          credentials: this.credentials && this.credentials.length > 0 ? this.credentials.join(',') : ''
        };

        if (this.status) {
          data.status = this.status;
        }

        this.tableData.forEach(function (item) {
          data.sl.push(item.sl);
          data.xmmc.push(item.xmmc);
          data.xmjshj.push(item.xmjshj);
          data.spbm.push(item.spbm);
          data.spsl.push(item.spsl);
          data.spdj.push(item.spdj);
          data.ggxh.push(item.ggxh);
          data.jldw.push(item.jldw);
        });
        var str = '';
        var tStr = '';

        if (this.canRedRush == 1) {
          str = '此操作确认发票冲红，请确认信息无误, 是否继续?';
          tStr = '已取消冲红'; // str = '此操作确认发票冲红，请确认信息无误, 是否继续?';
        } else {
          str = '此操作确认开票，请确认信息无误, 是否继续?';
          tStr = '已取消开票';
        } //  else  if (this.status === 2){
        //   str = '此操作确认重新开票，请确认信息无误, 是否继续?';
        // }else{
        // }


        this.$confirm(str, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          console.log(JSON.stringify(data));

          _this7.openLoading('加载中');

          (0, _finance.makeInvoice)(data).then(function (res) {
            _this7.openLoading().close();

            _this7.$router.push('/finance/invoice/invoiceManage');

            console.log(res);
          }).catch(function (res) {
            _this7.openLoading().close();
          });
        }).catch(function () {
          _this7.$message({
            type: 'info',
            message: tStr
          });
        });
      }
    }
  }
};
exports.default = _default;