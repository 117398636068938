var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "descriptions" },
    [
      _c("h4", { staticClass: "form_title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "el-row",
        _vm._l(_vm.columns, function(c, index) {
          return _c(
            "el-col",
            {
              key: index,
              style: {
                paddingBottom: _vm.verticalGutter + "px"
              },
              attrs: { span: 24 / c }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "descriptions_key",
                      attrs: { span: _vm.labelCol }
                    },
                    [_vm._v("\n          " + _vm._s(c.title) + "：\n        ")]
                  ),
                  _vm._v(" "),
                  c.valueType
                    ? _c(
                        "el-col",
                        {
                          staticClass: "descriptions_image",
                          attrs: { span: _vm.wrapperCol }
                        },
                        [
                          _c("img", {
                            style: { width: c.width, height: c.height },
                            attrs: {
                              src: _vm.imgSrc + _vm.dataSource[c.dataIndex]
                            }
                          })
                        ]
                      )
                    : _c(
                        "el-col",
                        {
                          staticClass: "descriptions_value",
                          attrs: { span: _vm.wrapperCol }
                        },
                        [
                          c.dataIndex === "mobile"
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.dataSource[c.dataIndex].replace(
                                        _vm.dataSource[c.dataIndex].substring(
                                          3,
                                          9
                                        ),
                                        "******"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      c.valueEnum
                                        ? c.valueEnum[
                                            _vm.dataSource[c.dataIndex]
                                          ]
                                        : _vm.dataSource[c.dataIndex]
                                    ) +
                                    "\n          "
                                )
                              ]),
                          _vm._v(" "),
                          c.btnTxt
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "warning" },
                                  on: {
                                    click: function($event) {
                                      return _vm.wPage(
                                        c.btnUrl + _vm.dataSource[c.dataIndex]
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(c.btnTxt))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }