var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "promotion" }, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "promotion_input" }, [
      _c("ul", [
        _c(
          "li",
          [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("活动名称：")
            ]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "promontionInput",
              attrs: { type: "text", maxlength: "30" },
              model: {
                value: _vm.obj.name,
                callback: function($$v) {
                  _vm.$set(_vm.obj, "name", $$v)
                },
                expression: "obj.name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("活动分类：")
            ]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "promontionInput1",
                attrs: { placeholder: "活动类型" },
                model: {
                  value: _vm.obj.classId,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "classId", $$v)
                  },
                  expression: "obj.classId"
                }
              },
              _vm._l(_vm.obj.options, function(item) {
                return _c("el-option", {
                  key: item.classId,
                  attrs: { label: item.name, value: item.classId }
                })
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("会员机构：")
            ]),
            _vm._v(" "),
            _c("el-tree", {
              ref: "tree2",
              staticClass: "promontionInput1",
              attrs: {
                "filter-node-method": _vm.filterNode,
                data: _vm.obj.treeArr,
                "default-checked-keys": _vm.obj.institutionIds,
                "show-checkbox": "",
                "node-key": "code",
                "highlight-current": "",
                props: _vm.defaultProps
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("活动时间：")
            ]),
            _vm._v(" "),
            _c("el-date-picker", {
              attrs: {
                "value-format": "yyyy-MM-dd HH:mm:ss",
                type: "datetimerange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期"
              },
              model: {
                value: _vm.obj.time,
                callback: function($$v) {
                  _vm.$set(_vm.obj, "time", $$v)
                },
                expression: "obj.time"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("活动地点：")
            ]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "promontionInput1",
              attrs: { type: "text" },
              model: {
                value: _vm.obj.address,
                callback: function($$v) {
                  _vm.$set(_vm.obj, "address", $$v)
                },
                expression: "obj.address"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("排序号：")
            ]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "promontionInput1",
              attrs: { type: "number" },
              model: {
                value: _vm.obj.sort,
                callback: function($$v) {
                  _vm.$set(_vm.obj, "sort", $$v)
                },
                expression: "obj.sort"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "promotion_input_li_tit" }, [
            _vm._v("BANNER图：")
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("add-load", {
                attrs: { width: 375, height: 150, cover: _vm.obj.bannerPath },
                on: { setCover: _vm.setCover }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "promotion_input_li_txt" }, [
                _vm._v("图片尺寸：750px * 300px")
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "promotion_input_li_tit" }, [
            _vm._v("摘要文字：")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "min-height": "130px" } },
            [
              _c("el-input", {
                staticClass: "promontionInput",
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 6, maxRows: 6 },
                  placeholder: "请输入内容",
                  maxlength: "200",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.obj.posterAbstract,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "posterAbstract", $$v)
                  },
                  expression: "obj.posterAbstract"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "promotion_input_li_txt" }, [
                _vm._v("摘要内容用于生成海报图片，建议6行文字以内")
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "promotion_input_li_tit" }, [
            _vm._v("活动页面类型：")
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.editType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "editType", $$v)
                    },
                    expression: "obj.editType"
                  }
                },
                [_vm._v("编辑图文内容")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.obj.editType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "editType", $$v)
                    },
                    expression: "obj.editType"
                  }
                },
                [_vm._v("设置跳转链接")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.obj.editType == 2
          ? _c(
              "li",
              [
                _c("p", { staticClass: "promotion_input_li_tit" }, [
                  _vm._v("跳转链接：")
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "promontionInput1",
                  model: {
                    value: _vm.obj.urlPath,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "urlPath", $$v)
                    },
                    expression: "obj.urlPath"
                  }
                })
              ],
              1
            )
          : _c("li", [
              _c("div", { staticClass: "li_left" }, [
                _c("p", { staticClass: "promotion_input_li_tit" }, [
                  _vm._v("活动内容详情：")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "liEditor" },
                  [
                    _c("Tinymce", {
                      ref: "editor",
                      attrs: { width: 410, height: 500 },
                      on: { onChange: _vm.change },
                      model: {
                        value: _vm.obj.contentCode,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "contentCode", $$v)
                        },
                        expression: "obj.contentCode"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "li_right" },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.obj.attrCode,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "attrCode", $$v)
                      },
                      expression: "obj.attrCode"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.goSearch } },
                    [_vm._v("获取数据")]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticStyle: { "line-height": "inherit" },
                    domProps: { innerHTML: _vm._s(_vm.obj.html) }
                  }),
                  _vm._v(" "),
                  _c("div")
                ],
                1
              )
            ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "promotion_input_li_tit" }, [
            _vm._v("\n          活动上线时间：\n        ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "li_div" }, [
            _c(
              "div",
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: 1 },
                    model: {
                      value: _vm.obj.onlineType,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "onlineType", $$v)
                      },
                      expression: "obj.onlineType"
                    }
                  },
                  [_vm._v("选择上线周期")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  {
                    attrs: { label: 2 },
                    model: {
                      value: _vm.obj.onlineType,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "onlineType", $$v)
                      },
                      expression: "obj.onlineType"
                    }
                  },
                  [_vm._v("立即上线（需要手动下线）")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.obj.onlineType === 1,
                    expression: "obj.onlineType === 1"
                  }
                ]
              },
              [
                _c("el-date-picker", {
                  attrs: {
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    type: "datetimerange",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期"
                  },
                  model: {
                    value: _vm.obj.beginDate,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "beginDate", $$v)
                    },
                    expression: "obj.beginDate"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "promotion_input_li_tit" }),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.next } },
              [_vm._v("下一步")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }