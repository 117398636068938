"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.category = category;
exports.create = create;
exports.del = del;
exports.edit = edit;
exports.editSetting = editSetting;
exports.getSetting = getSetting;
exports.goodsList = goodsList;
exports.keywordDelete = keywordDelete;
exports.keywordDetail = keywordDetail;
exports.keywordSave = keywordSave;
exports.list = list;
exports.listFreight = listFreight;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 创建商品一级/二级分类
 {
     "name":"fenbxza",   分类名称
     "status": 0,         状态 -1禁用 0启用
     "parentId": 0,       父级分类的id，一级分类的parentId为0
     "sort": 2            排序
 }
 */
function create(data) {
  return (0, _request.default)({
    url: '/api/category/create',
    method: 'post',
    data: data
  });
}
/*
 /category/13分类详情

 */


function category(data) {
  return (0, _request.default)({
    url: '/api/category/' + data,
    method: 'get',
    data: data
  });
}

function edit(data) {
  return (0, _request.default)({
    url: '/api/category/edit',
    method: 'post',
    data: data
  });
}
/*
2、删除商品分类
 */


function del(data) {
  return (0, _request.default)({
    url: '/api/category/del',
    method: 'get',
    params: data
  });
}
/*
4、查询分类
 */


function list(data) {
  return (0, _request.default)({
    url: '/api/category/list',
    method: 'post',
    data: data
  });
}

function goodsList(data) {
  return (0, _request.default)({
    url: '/api/category/list',
    method: 'post',
    data: data
  });
}
/*

运费模板列表
 */


function listFreight(data) {
  return (0, _request.default)({
    url: '/api/freight/listFreight',
    method: 'get',
    params: data
  });
}
/*
1、查询全局设置：/api/mall/setting/getSetting

2、积分商城全局设置增加"自提天数（carryDay）"，
 小程序个人订单列表级详情自提订单增加 carryStatus(待自提状态(1：待自提 0：备货中)) 判断
 */


function getSetting(data) {
  return (0, _request.default)({
    url: '/api/mall/setting/getSetting',
    method: 'post',
    data: data
  });
}
/*

 2、编辑全局设置：/api/mall/setting/editSetting
 {"saleDay":"售后天数","receivingDay":"收货天数","logisticAppid":"appid","logisticSecret":"secret"}
 */


function editSetting(data) {
  return (0, _request.default)({
    url: '/api/mall/setting/editSetting',
    method: 'post',
    data: data
  });
} // 热搜词详情


function keywordDetail(id) {
  return (0, _request.default)({
    url: '/api/heatSearch/getHeatSearch/' + id,
    method: 'get'
  });
} // 增加|编辑热搜词


function keywordSave(data) {
  var url = "/api/heatSearch/addHeatSearch";

  if (data.id) {
    url = "/api/heatSearch/editHeatSearch";
  }

  return (0, _request.default)({
    url: url,
    method: 'post',
    data: data
  });
} // 删除热搜词


function keywordDelete(id) {
  return (0, _request.default)({
    url: '/api/heatSearch/delHeatSearch/' + id,
    method: 'get'
  });
}