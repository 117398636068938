"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '要客管理',
      list: [{
        tit: '消费要客列表',
        txtArr: '<p>要客信息查询、导出</p>',
        imgPath: require('@/assets/tab/member.png'),
        path: '/svip/member/list'
      }, {
        tit: '充值要客列表',
        txtArr: '<p>要客信息查询、导出</p>',
        imgPath: require('@/assets/tab/member.png'),
        path: '/svip/member/recharge'
      }, {
        tit: '要客申请',
        txtArr: '<p>审核要客申请信息</p>',
        imgPath: require('@/assets/tab/review.png'),
        path: '/svip/member/review'
      }, {
        tit: '要客等级变动列表',
        txtArr: '<p>要客等级变动记录查询、导出</p>',
        imgPath: require('@/assets/tab/member.png'),
        path: '/svip/member/change'
      }]
    };
  }
};
exports.default = _default;