"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '优惠券编码',
        value: 'couponId'
      }, {
        type: 'input',
        span: 4,
        placeholder: '核销人手机号',
        value: 'createUserMobile'
      }, {
        type: 'input',
        span: 4,
        placeholder: '用户手机号',
        value: 'mobile'
      }, {
        type: 'input',
        span: 4,
        placeholder: '核销门店编码',
        value: 'poiId'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '核销状态',
        value: 'status',
        select: [{
          value: 1,
          label: '已核销'
        }, {
          value: 2,
          label: '已撤销'
        }, {
          value: 3,
          label: '核销失败'
        }]
      }, {
        type: 'radio',
        span: 4,
        placeholder: '核销类型',
        value: 'type',
        select: [{
          value: 1,
          label: '优惠券'
        }, {
          value: 2,
          label: '优惠券包'
        }]
      }, {
        type: 'radio',
        span: 4,
        placeholder: '核销路径',
        value: 'source',
        select: [{
          value: 1,
          label: '核销助手'
        }, {
          value: 2,
          label: '昂捷收银机'
        }, {
          value: 3,
          label: '蜻蜓收银机'
        }]
      }, {
        type: 'dateTime',
        span: 4,
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        span: 4,
        placeholder: '结束时间',
        defaultTime: '23:59:59',
        value: 'endTime'
      }]
    };
  },
  methods: {
    goPageDwn: function goPageDwn(tHeader, filterVal, list) {
      list.forEach(function (item) {
        if (item.writeoffInfo) {
          var writeoffInfo = JSON.parse(item.writeoffInfo);
          item.dyOrderNo = writeoffInfo[0].orderNo || '';
        }
      });
      this.getHandleDownload(tHeader, filterVal, list, "抖音券核销记录");
    },
    goCasancelDyCoupon: function goCasancelDyCoupon(item) {
      var _this = this;

      this.apiPost('/api/dyCoupon/cancelDyCoupon', {
        id: item.id
      }).then(function (res) {
        _this.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;