"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _member = require("@/api/member.js");

var _mem = require("@/api/mem.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      list: [],
      cardList: [],
      total: 0,
      companyList: [],
      storeList: [],
      obj: {
        pageNo: 1,
        pageSize: 10,
        giftType: '',
        giftName: '',
        companyCode: '',
        storeCode: '',
        giftStatus: ''
      },
      tabTh: [{
        prop: 'id',
        label: '礼品编码',
        width: 100
      }, {
        prop: 'giftName',
        label: '礼品名称',
        width: 100
      }, {
        prop: 'giftTypeText',
        label: '礼品类型',
        width: 100
      }, {
        prop: 'storeCode',
        label: '所属门店',
        width: 100
      }, {
        prop: 'giftWorth',
        label: '价值',
        width: 100
      }, {
        prop: 'scorePrice',
        label: '积分价格',
        width: 100
      }, {
        prop: 'discountScore',
        label: '365积分价格',
        width: 100
      }, {
        prop: 'stockNumber',
        label: '库存数',
        width: 100
      }, {
        prop: 'giftStatus',
        label: '状态',
        width: 80
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _member.listType)({}).then(function (res) {
      _this.cardList = res.data;
    });
    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companyList = res.data;
    });
    (0, _mem.listStore)({
      companyId: ''
    }).then(function (res) {
      console.log(res);
      _this.storeList = res.data;
    });
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['礼品编码', '礼品名称', '礼品类型', '所属门店', '价值', '积分价格', '库存数', '状态'];
        var filterVal = ['id', 'giftName', 'giftTypeText', 'storeCode', 'giftWorth', 'scorePrice', 'stockNumber', 'giftStatus'];
        var list = [];
        var obj = JSON.parse(JSON.stringify(_this2.obj));
        obj.pageSize = _this2.total;
        (0, _member.listGiftPage)(obj).then(function (res) {
          list = res.data.records;
          list.forEach(function (item) {
            switch (item.giftType) {
              case 1:
                item.giftTypeText = '生日礼';
                break;

              case 2:
                item.giftTypeText = '升级礼';
                break;

              case 3:
                item.giftTypeText = '积兑卡';
                break;

              case 5:
                item.giftTypeText = '积分礼品';
                break;

              case 6:
                item.giftTypeText = '幸运礼';
                break;

              case 7:
                item.giftTypeText = '2020年积分兑换';
                break;

              case 8:
                item.giftTypeText = '365付费会员自提开卡礼';
                break;

              case 9:
                item.giftTypeText = '2021年积分兑换';
                break;

              case 10:
                item.giftTypeText = '2022年积分兑换';
                break;

              case 4:
                item.giftTypeText = '其他';
                break;

              default:
                break;
            }

            item.giftStatus = item.giftStatus == 1 ? '启用' : '禁用';
          });

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '礼品管理'
          });
          _this2.downloadLoading = false;
        }).catch(function (res) {
          _this2.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setShort: function setShort(e) {
      var _this3 = this;

      this.obj.storeCode = '';
      var companyId = '';
      this.companyList.forEach(function (item) {
        if (item.companyCode == e) {
          companyId = item.companyId;
        }
      });
      (0, _mem.listStore)({
        companyId: companyId
      }).then(function (res) {
        _this3.storeList = res.data;
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/member/gift/tab');
    },
    setTab: function setTab(row) {
      this.$router.push('/member/gift/add?id=' + row.id);
    },
    init: function init(type) {
      var _this4 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
      }

      this.openLoading();
      (0, _member.listGiftPage)(this.obj).then(function (res) {
        _this4.openLoading().close();

        _this4.list = res.data.records;
        _this4.total = res.data.total;

        _this4.list.forEach(function (item) {
          switch (item.giftType) {
            case 1:
              item.giftTypeText = '生日礼';
              break;

            case 2:
              item.giftTypeText = '升级礼';
              break;

            case 3:
              item.giftTypeText = '积兑卡';
              break;

            case 5:
              item.giftTypeText = '积分礼品';
              break;

            case 6:
              item.giftTypeText = '幸运礼';
              break;

            case 7:
              item.giftTypeText = '2020年积分兑换';
              break;

            case 8:
              item.giftTypeText = '365付费会员自提开卡礼';
              break;

            case 9:
              item.giftTypeText = '2021年积分兑换';
              break;

            case 10:
              item.giftTypeText = '2022年积分兑换';
              break;

            case 4:
              item.giftTypeText = '其他';
              break;

            default:
              break;
          }

          item.giftStatus = item.giftStatus === 1 ? '启用' : '禁用';
        });
      }).catch(function () {
        _this4.openLoading().close();
      });
    }
  }
};
exports.default = _default;