"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _scrollTo = require("@/utils/scroll-to");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default: function _default() {
        return [this.limit, this.limit * 2, this.limit * 3, this.limit * 4, this.limit * 5, 2000];
      }
    },
    layout: {
      type: String,
      default: 'total, sizes,prev, pager,next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get: function get() {
        return this.page;
      },
      set: function set(val) {
        this.$emit('update:page', val);
      }
    },
    pageSize: {
      get: function get() {
        return this.limit;
      },
      set: function set(val) {
        this.$emit('update:limit', val);
      }
    }
  },
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      this.$emit('pagination', {
        page: this.currentPage,
        limit: val
      });

      if (this.autoScroll) {
        (0, _scrollTo.scrollTo)(0, 800);
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.$emit('pagination', {
        page: val,
        limit: this.pageSize
      });

      if (this.autoScroll) {
        (0, _scrollTo.scrollTo)(0, 800);
      }
    }
  }
};
exports.default = _default2;