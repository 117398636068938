"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addOpengiftClass = addOpengiftClass;
exports.editOpengiftClass = editOpengiftClass;
exports.getOpengiftClass = getOpengiftClass;
exports.listOpengiftClass = listOpengiftClass;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
1、新增会员开卡礼分类：/api/vipOpengiftClass/addOpengiftClass
{"vipCode":"会员编码","className":"分类名称","classSort":"分类排序","classStatus":"分类状态(0：停用 1：启用)"}

2、编辑会员开卡礼分类：/api/vipOpengiftClass/editOpengiftClass
{"id":"id","vipCode":"会员编码","className":"分类名称","classSort":"分类排序","classStatus":"分类状态(0：停用 1：启用)"}

3、会员开卡礼分类明细：/api/vipOpengiftClass/getOpengiftClass/{id}

4、会员开卡礼分类列表：/api/vipOpengiftClass/listOpengiftClass
{"vipCode":"会员编码","classStatus":"分类状态(0：停用 1：启用)"}

 */
function addOpengiftClass(data) {
  return (0, _request.default)({
    url: '/api/vipOpengiftClass/addOpengiftClass',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editOpengiftClass(data) {
  return (0, _request.default)({
    url: '/api/vipOpengiftClass/editOpengiftClass',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getOpengiftClass(data) {
  return (0, _request.default)({
    url: '/api/vipOpengiftClass/getOpengiftClass/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function listOpengiftClass(data) {
  return (0, _request.default)({
    url: '/api/vipOpengiftClass/listOpengiftClass',
    method: 'post',
    loading: 'loading',
    data: data
  });
}