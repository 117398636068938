"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.regexp.replace");

var _index = require("@/api/svip/index.js");

var _Descriptions = _interopRequireDefault(require("@/components/mc/Descriptions.vue"));

var _map = require("../../const/map");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Descriptions: _Descriptions.default
  },
  data: function data() {
    return {
      itemName: null,
      member: {},
      logList: []
    };
  },
  computed: {
    columns: function columns() {
      return [{
        title: '会员手机号',
        key: 'mobile',
        dataIndex: 'mobile',
        btnTxt: '会员详情',
        btnUrl: '/report/memberQuery?mobile='
      }, {
        title: '要客姓名',
        key: 'name',
        dataIndex: 'name'
      }, {
        title: '性别',
        key: 'sex',
        dataIndex: 'sex',
        valueEnum: _map.SvipSex
      }, {
        title: '联系电话',
        key: 'contactNumber',
        dataIndex: 'contactNumber'
      }, {
        title: '总充值金额',
        key: 'totalChargeAmount',
        dataIndex: 'totalChargeAmount'
      }, {
        title: '当前余额',
        key: 'balance',
        dataIndex: 'balance'
      }, {
        title: '充值次数',
        key: 'chargeTimes',
        dataIndex: 'chargeTimes'
      }, {
        title: '要客加入时间',
        key: 'addTime',
        dataIndex: 'addTime'
      },
      /* {
         title: '身份到期时间',
         key: 'sVipEndDate',
         dataIndex: 'sVipEndDate',
       }, */
      {
        title: '维护荔星伙伴',
        key: 'managerName',
        dataIndex: 'managerName'
      }, {
        title: '生日',
        key: 'birthday',
        dataIndex: 'birthday'
      }, {
        title: '公司',
        key: 'company',
        dataIndex: 'company'
      }, {
        title: '职务',
        key: 'job',
        dataIndex: 'job'
      }, {
        title: '要客等级',
        key: 'levelName',
        dataIndex: 'levelName'
      }, {
        title: '充值金额',
        key: 'totalChargeAmount',
        dataIndex: 'totalChargeAmount'
      }, {
        title: '消费金额',
        key: 'consumeOneYear',
        dataIndex: 'consumeOneYear'
      },
      /*{
       title: '消费要客等级',
       key: 'consumeLevelName',
       dataIndex: 'consumeLevelName',
      }, */
      {
        title: '备注',
        key: 'remark',
        dataIndex: 'remark'
      }];
    }
  },
  created: function created() {
    this.id = this.$route.params && this.$route.params.id;

    if (this.id) {
      console.log(this.id);
      this.init(this.id);
    }
  },
  methods: {
    init: function init(id) {
      var _this = this;

      (0, _index.getMember)({
        id: id
      }).then(function (res) {
        res.data.labelList = res.data.labelList || [];
        res.data.contactNumber = res.data.contactNumber.replace(res.data.contactNumber.substring(3, 9), '******');

        if (res.data.consumeLevelId >= res.data.levelId) {
          res.data.levelName = res.data.consumeLevelName;
        } else if (res.data.consumeLevelId < res.data.levelId) {
          res.data.levelName = res.data.levelName;
        }

        _this.member = res.data;
        _this.logList = res.data.logList;
      });
    },
    goUpdate: function goUpdate() {
      var _this2 = this;

      this.apiGet('/api/svip/level/refreshSvipLevel', {
        uid: '327173012' // this.member.uid

      }).then(function (res) {
        _this2.init(_this2.id);
      });
    },
    upDateMember: function upDateMember() {
      var _this3 = this;

      (0, _index.updateMember)((0, _objectSpread2.default)({
        id: this.id
      }, this.member)).then(function (res) {
        _this3.$message({
          type: 'success',
          message: '保存成功!'
        });
      });
    },
    addItem: function addItem(index) {
      console.log(this.member.labelList);
      if (this.member.labelList.includes(this.itemName)) return;

      if (this.itemName) {
        this.member.labelList.push(this.itemName);
      }
    },
    delTag: function delTag(index) {
      this.member.labelList.splice(index, 1);
    }
  }
};
exports.default = _default;