var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-setting-home" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("荔星荟设置")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                attrs: {
                  "label-width": "106px",
                  model: _vm.ruleForm,
                  rules: _vm.rules
                }
              },
              [
                _c("h4", { staticClass: "form_title" }, [
                  _vm._v("客户未成为要客状态页面")
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "宣传海报：", prop: "noSvipPublicityPic" }
                  },
                  [
                    _c("add-load", {
                      staticClass: "img_list",
                      attrs: {
                        "is-limit": false,
                        width: 375,
                        height: 500,
                        cover: _vm.ruleForm.noSvipPublicityPic
                      },
                      on: {
                        setCover: function($event) {
                          return _vm.setCover($event, "noSvipPublicityPic")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticStyle: { color: "#999999" } }, [
                      _vm._v("图片尺寸：宽度750px 不限高度")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "form_title" }, [
                  _vm._v("客户成为要客状态页面")
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "顶部欢迎图：", prop: "topWelcomePic" } },
                  [
                    _c("add-load", {
                      staticClass: "img_list",
                      attrs: {
                        width: 375,
                        height: 215,
                        cover: _vm.ruleForm.topWelcomePic
                      },
                      on: {
                        setCover: function($event) {
                          return _vm.setCover($event, "topWelcomePic")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticStyle: { color: "#999999" } }, [
                      _vm._v("图片尺寸：750 x 430")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: { label: "宣传图：", prop: "svipPublicityPics" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "svip-setting-home_form" },
                      _vm._l(_vm.ruleForm.svipPublicityPics, function(
                        picture,
                        idx
                      ) {
                        return _c(
                          "el-form-item",
                          {
                            key: idx,
                            staticClass:
                              "svip-setting-home_form_select-pic form_item",
                            attrs: {
                              "label-position": "left",
                              "label-width": "60px"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "svipPublicityPics." + idx + ".pic",
                                  rules: {
                                    required: true,
                                    message: "请上传图片"
                                  }
                                }
                              },
                              [
                                _c("add-load", {
                                  staticClass: "img_list",
                                  attrs: {
                                    width: 230,
                                    height: 57,
                                    cover: picture.pic
                                  },
                                  on: {
                                    setCover: function($event) {
                                      return _vm.setSelectPic(
                                        $event,
                                        idx,
                                        "svipPublicityPics"
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("p", { staticStyle: { color: "#999999" } }, [
                                  _vm._v("图片尺寸：690 x 170")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass:
                                  "svip-setting-home_form_select-pic_right"
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "状态：",
                                      "label-width": "100px",
                                      prop:
                                        "svipPublicityPics." + idx + ".enable",
                                      rules: {
                                        required: true,
                                        message: "请选择状态",
                                        trigger: "change"
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: picture.enable,
                                          callback: function($$v) {
                                            _vm.$set(picture, "enable", $$v)
                                          },
                                          expression: "picture.enable"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("上架")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("下架")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("NavigateSelect", {
                                  staticClass: "form_item1",
                                  attrs: {
                                    dataSource: picture,
                                    propPrefix: "svipPublicityPics." + idx
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: { label: "轮播展示图：", prop: "slideshowPics" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "svip-setting-home_form" },
                      _vm._l(_vm.ruleForm.slideshowPics, function(
                        picture,
                        idx
                      ) {
                        return _c(
                          "el-form-item",
                          {
                            key: idx,
                            staticClass:
                              "svip-setting-home_form_select-pic form_item",
                            attrs: {
                              label: "图" + (idx + 1),
                              "label-position": "left",
                              "label-width": "60px"
                            }
                          },
                          [
                            _c("el-button", {
                              staticClass:
                                "svip-setting-home_form_select-pic_delete",
                              attrs: { type: "text", icon: "el-icon-delete" },
                              on: {
                                click: function($event) {
                                  return _vm.delSelectPic(idx, "slideshowPics")
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "slideshowPics." + idx + ".pic",
                                  rules: {
                                    required: true,
                                    message: "请上传图片"
                                  }
                                }
                              },
                              [
                                _c("add-load", {
                                  staticClass: "img_list",
                                  attrs: {
                                    width: 187.5,
                                    height: 120,
                                    cover: picture.pic
                                  },
                                  on: {
                                    setCover: function($event) {
                                      return _vm.setSelectPic(
                                        $event,
                                        idx,
                                        "slideshowPics"
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("p", { staticStyle: { color: "#999999" } }, [
                                  _vm._v("图片尺寸：690 x 360")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass:
                                  "svip-setting-home_form_select-pic_right"
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "状态：",
                                      "label-width": "100px",
                                      prop: "slideshowPics." + idx + ".enable",
                                      rules: {
                                        required: true,
                                        message: "请选择状态",
                                        trigger: "change"
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: picture.enable,
                                          callback: function($$v) {
                                            _vm.$set(picture, "enable", $$v)
                                          },
                                          expression: "picture.enable"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("上架")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("下架")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "排序：",
                                      "label-width": "100px",
                                      prop: "slideshowPics." + idx + ".sort",
                                      rules: {
                                        type: "number",
                                        required: true,
                                        message: "请输入排序",
                                        trigger: "blur"
                                      }
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "166px" },
                                      attrs: {
                                        min: 1,
                                        step: 1,
                                        precision: 0,
                                        controls: false
                                      },
                                      model: {
                                        value: picture.sort,
                                        callback: function($$v) {
                                          _vm.$set(picture, "sort", $$v)
                                        },
                                        expression: "picture.sort"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("NavigateSelect", {
                                  staticClass: "form_item1",
                                  attrs: {
                                    dataSource: picture,
                                    propPrefix: "slideshowPics." + idx
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "max-width": "100%" },
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-circle-plus-outline"
                        },
                        on: {
                          click: function($event) {
                            return _vm.addPic("slideshowPics")
                          }
                        }
                      },
                      [_vm._v("\n            添加图片\n          ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: { label: "引导封面图：", prop: "guideCoversPics" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "svip-setting-home_form" },
                      _vm._l(_vm.ruleForm.guideCoversPics, function(
                        picture,
                        idx
                      ) {
                        return _c(
                          "el-form-item",
                          {
                            key: idx,
                            staticClass:
                              "svip-setting-home_form_select-pic form_item",
                            attrs: {
                              label: "图" + (idx + 1),
                              "label-position": "left",
                              "label-width": "60px"
                            }
                          },
                          [
                            _c("el-button", {
                              staticClass:
                                "svip-setting-home_form_select-pic_delete",
                              attrs: { type: "text", icon: "el-icon-delete" },
                              on: {
                                click: function($event) {
                                  return _vm.delSelectPic(
                                    idx,
                                    "guideCoversPics"
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "guideCoversPics." + idx + ".pic",
                                  rules: {
                                    required: true,
                                    message: "请上传图片"
                                  }
                                }
                              },
                              [
                                _c("add-load", {
                                  staticClass: "img_list",
                                  attrs: {
                                    width: 187.5,
                                    height: 120,
                                    cover: picture.pic
                                  },
                                  on: {
                                    setCover: function($event) {
                                      return _vm.setSelectPic(
                                        $event,
                                        idx,
                                        "guideCoversPics"
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("p", { staticStyle: { color: "#999999" } }, [
                                  _vm._v("图片尺寸：336 x 410")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass:
                                  "svip-setting-home_form_select-pic_right"
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "状态：",
                                      "label-width": "100px",
                                      prop:
                                        "guideCoversPics." + idx + ".enable",
                                      rules: {
                                        required: true,
                                        message: "请选择状态",
                                        trigger: "change"
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: picture.enable,
                                          callback: function($$v) {
                                            _vm.$set(picture, "enable", $$v)
                                          },
                                          expression: "picture.enable"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("上架")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("下架")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "排序：",
                                      "label-width": "100px",
                                      prop: "guideCoversPics." + idx + ".sort",
                                      rules: {
                                        type: "number",
                                        required: true,
                                        message: "请输入排序",
                                        trigger: "blur"
                                      }
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "166px" },
                                      attrs: {
                                        min: 1,
                                        step: 1,
                                        precision: 0,
                                        controls: false
                                      },
                                      model: {
                                        value: picture.sort,
                                        callback: function($$v) {
                                          _vm.$set(picture, "sort", $$v)
                                        },
                                        expression: "picture.sort"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("NavigateSelect", {
                                  staticClass: "form_item1",
                                  attrs: {
                                    dataSource: picture,
                                    propPrefix: "guideCoversPics." + idx
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "max-width": "100%" },
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-circle-plus-outline"
                        },
                        on: {
                          click: function($event) {
                            return _vm.addPic("guideCoversPics")
                          }
                        }
                      },
                      [_vm._v("\n            添加图片\n          ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "150px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.submitForm("ruleForm")
                          }
                        }
                      },
                      [_vm._v("\n            保存设置\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }