"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.getInfo = getInfo;
exports.getList = getList;
exports.unDatePassword = unDatePassword;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: '/admin/md/shop/shopList',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addData(data) {
  return (0, _request.default)({
    url: '/api/lx/lx-sys-user-isssue-coupon-bag-config/saveOrUpdate',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getInfo(data) {
  return (0, _request.default)({
    url: '/api/lx/lx-sys-user-isssue-coupon-bag-config/getById',
    method: 'get',
    params: data
  });
}

function unDatePassword(data) {
  return (0, _request.default)({
    url: '/admin/user/change/password',
    method: 'post',
    data: data
  });
}