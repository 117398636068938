"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      isChagneGoodsStatus: false,
      columns: [{
        slot: "select"
      }, {
        label: '主图',
        prop: 'mainImage',
        slot: 'mainImage'
      }, {
        label: '名称',
        prop: 'goodsName',
        width: 150,
        align: 'left'
      }, {
        label: '分类',
        prop: 'allCategoryName',
        width: 150,
        align: 'left'
      }, {
        label: '商户',
        prop: 'merchantName',
        width: 100,
        align: 'left'
      }, {
        label: '品牌',
        prop: 'brandName',
        width: 80
      }, {
        slot: "price"
      }, {
        label: '成本价',
        prop: 'spuCostPrice',
        slot: 'spuCostPrice',
        width: 50
      }, {
        label: '原价',
        prop: 'spuOriginalPrice',
        slot: 'spuOriginalPrice',
        width: 50
      }, {
        label: '售价',
        prop: 'spuGoodsPrice',
        slot: 'spuGoodsPrice',
        width: 50
      }, {
        label: '库存',
        prop: 'totalStock',
        width: 50
      },
      /*  {
          label: '重量',
          prop: 'goodsWeight',
          width: 50,
        }, */
      {
        label: '排序',
        prop: 'goodsSort',
        width: 60
      },
      /* {
         label: '商品单位',
         prop: 'goodsUnit',
         align: 'left',
         width: 80,
       },
       {
         label: '加入购物车',
         prop: 'isAllowAddCart',
         //slot: 'status',
         width: 80,
       }, */
      {
        label: '热销',
        prop: 'isHot',
        slot: 'isHot',
        width: 120
      },
      /* {
         label: '是否使用规格',
         prop: 'isMultipleSku',
         width: 50,
       }, */
      {
        label: '推荐',
        prop: 'isRecommended',
        slot: 'isRecommended',
        width: 50
      }, {
        label: '会员价',
        prop: 'isOpenMemberDiscount',
        slot: 'isOpenMemberDiscount',
        width: 50
      }, {
        label: '会员折扣',
        slot: 'memberDiscountPercent',
        prop: 'memberDiscountPercent',
        width: 50
      }, {
        label: '购买会员限制',
        prop: 'userMultipleLimit',
        slot: 'userMultipleLimit',
        width: 50
      }, {
        label: '状态（0=下架,1=上架）',
        prop: 'status',
        slot: 'status',
        width: 50
      },
      /* {
        label: '创建时间',
        prop: 'createTime',
        width: 50,
      },
      {
        label: '更新',
        prop: 'updateTime',
        width: 50,
      }, */
      {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        placeholder: '商品名称',
        value: 'goodsName'
      }, {
        type: 'input',
        placeholder: '商品ID',
        value: 'id'
      }, {
        type: 'input',
        placeholder: '多商品ID查询，用,分开',
        value: 'ids'
      }, {
        type: 'radio',
        placeholder: '商品状态',
        value: 'status',
        select: [{
          label: '上架',
          value: 1
        }, {
          label: '下架',
          value: 0
        }]
      }, {
        type: 'radio',
        span: 4,
        placeholder: '是否热销',
        value: 'isHot',
        select: [{
          label: '热销',
          value: 1
        }, {
          label: '非热销',
          value: 0
        }]
      }, {
        type: 'radio',
        placeholder: '是否渠道选品',
        value: 'isAllowChannelSelect',
        select: [{
          label: '渠道选品',
          value: 1
        }, {
          label: '非渠道选品',
          value: 0
        }]
      }, {
        type: 'radio',
        placeholder: '是否推荐',
        value: 'isRecommended',
        select: [{
          label: '推荐商品',
          value: 1
        }, {
          label: '非推荐商品',
          value: 0
        }]
      }, {
        type: 'radio',
        placeholder: '是否加入购物车',
        value: 'isAllowAddCart',
        select: [{
          label: '可加入购物车',
          value: 1
        }, {
          label: '不可加入购物车',
          value: 0
        }]
      }, {
        type: 'radio',
        placeholder: '是否会员价',
        value: 'isOpenMemberDiscount',
        select: [{
          label: '开启会员价',
          value: 1
        }, {
          label: '没有开启会员价',
          value: 0
        }]
      }]
    };
  },
  mounted: function mounted() {
    this.$refs.wTable.setRequestValue('endpointFrom', 0);
    this.$refs.wTable.getList();
  },
  methods: {
    setSelectGoods: function setSelectGoods(goodslist) {
      this.isChagneGoodsStatus = goodslist.length > 0;
    },
    isSelectDisabled: function isSelectDisabled(row) {
      return row.isJoin != 1;
    },
    goGoodsStatus: function goGoodsStatus(type) {
      var _this = this;

      var selectGoods = this.$refs.wTable.getSelectLists();
      var changeGoods = selectGoods.map(function (item) {
        return item.id;
      });
      var changeGoodsName = mapGoodsProperty(selectGoods, 'goodsName');
      var onShelf = ''; //type === 'down' ? 0 : 1;

      switch (type) {
        case 'down':
          onShelf = 0;
          break;

        case 'up':
          onShelf = 1;
          break;

        default:
          break;
      }

      var affrimTypeText = {
        down: "下架",
        up: "上架",
        del: "删除"
      };

      if (type == 'down' || type == 'up') {
        var errGoods = selectGoods.filter(function (item) {
          return item.status === onShelf;
        });

        if (errGoods.length > 0) {
          this.$message.error("\u6279\u91CF".concat(type === 'down' ? '下架' : '上架', "\u53EA\u80FD\u9009\u62E9\u5DF2").concat(type === 'down' ? '上架' : '下架', "\u5546\u54C1"));
          return;
        }
      }

      this.$confirm("\u6B64\u64CD\u4F5C\u5C06".concat(affrimTypeText[type], "\u5546\u54C1\uFF1A").concat(changeGoods.join(',')), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (type == 'del') {
          _this.delGoodsItem(changeGoods);

          return;
        }

        _this.changeGoodsStatus(type == 'down' ? 0 : 1, changeGoods);
      });

      function mapGoodsProperty(goodsList, property) {
        return goodsList.map(function (item) {
          return item[property];
        });
      }
    },
    delGoodsItem: function delGoodsItem(goodsIdList, index) {
      var _this2 = this;

      this.apiPost('/api/mall/goods/goods/delete', {
        goodsIdList: goodsIdList
      }).then(function (res) {
        _this2.$refs.wTable.getList();
      });
    },
    changeGoodsStatus: function changeGoodsStatus(e, goodslist) {
      var _this3 = this;

      this.apiPost(e == 0 ? '/api/mall/goods/goods/off' : '/api/mall/goods/goods/on', {
        goodsIdList: goodslist
      }).then(function (res) {
        _this3.$refs.wTable.getList();
      }).catch(function (err) {
        _this3.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;