"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _mem = require("@/api/mem.js");

var _member = require("@/api/member.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      storeList: [],
      popShow: false,
      form: {
        userName: '',
        userSex: '',
        userMobile: '',
        userIdNo: '',
        cardNo: '',
        mkStoreId: '',
        mid: ''
      },
      rules: {
        userName: [{
          required: true,
          message: '请填写会员姓名'
        }],
        userSex: [{
          required: true,
          message: '请选择性别',
          trigger: 'change'
        }],
        userMobile: [{
          required: true,
          message: '请填写会员手机号码'
        }],
        userIdNo: [{
          required: true,
          message: '请填写会员身份证号码'
        }, {
          pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
          message: '证件号码格式有误！'
        }],
        yzmCard: [{
          required: true,
          message: '请填写验证码'
        }],
        cardNo: [{
          required: true,
          message: '请读取会员卡号'
        }],
        companyCode: [{
          required: true,
          message: '请选择总部'
        }],
        mkStoreId: [{
          required: true,
          message: '请选择门店'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
  },
  methods: {
    goMid: function goMid() {
      var _this2 = this;

      this.popShow = true;
      this.$nextTick(function () {
        _this2.$refs.midInput.focus();
      });
    },
    submitForm: function submitForm(type) {
      var _this3 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this3.openLoading();

          if (type == 'big') {
            (0, _member.crmRegBigUser)(_this3.form).then(function (res) {
              _this3.$message({
                message: '新增成功',
                type: 'success'
              });

              _this3.$router.go(-1);

              _this3.openLoading().close();
            }).catch(function (err) {
              _this3.openLoading().close();
            });
          } else {
            (0, _member.crmRegUser)(_this3.form).then(function (res) {
              _this3.$message({
                message: '新增成功',
                type: 'success'
              });

              _this3.$router.go(-1);

              _this3.openLoading().close();
            }).catch(function (err) {
              _this3.openLoading().close();
            });
          }
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
exports.default = _default;