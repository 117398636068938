"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '券包名称',
        prop: 'exportName',
        width: 120
      }, {
        label: '券包编码',
        prop: 'exportUuid',
        width: 120
      }, {
        label: '券包数量',
        prop: 'exportNum',
        width: 80
      }, {
        label: '操作人手机号',
        prop: 'operateUserMobile',
        width: 120
      }, {
        label: '操作人',
        prop: 'operateUserName',
        width: 80
      }, {
        label: '订单金额',
        prop: 'realPayMoney',
        width: 80
      }, {
        label: '备注',
        prop: 'toPlatform',
        width: 120
      }, {
        label: '导出时间',
        width: 140,
        prop: 'createTime'
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        placeholder: '操作人电话',
        value: 'operateMobile'
      }, {
        type: 'input',
        placeholder: '操作人姓名',
        value: 'operateUserName'
      }, {
        type: 'input',
        placeholder: '券包编码',
        value: 'uuid'
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        defaultTime: '23:59:59',
        value: 'endTime'
      }]
    };
  },
  created: function created() {},
  methods: {}
};
exports.default = _default;