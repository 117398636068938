"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var messageRouter = {
  path: '/message',
  component: _layout.default,
  redirect: '/message/tab',
  name: 'message',
  meta: {
    title: '信息管理'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tab.vue"));
      });
    },
    name: 'messageTab',
    meta: {
      title: '信息管理'
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./device/list.vue"));
      });
    },
    name: 'messageList',
    meta: {
      activeMenu: '/message/tab',
      title: '任务列表',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/smsTask/listSmsTaskPage',
      addUrl: '/message/add',
      addTxt: "新增任务"
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./device/add.vue"));
      });
    },
    name: 'messageListAdd',
    meta: {
      title: '新增编辑任务',
      activeMenu: '/message/tab'
    }
  }, {
    path: 'tem',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tem/list.vue"));
      });
    },
    name: 'messageTem',
    meta: {
      activeMenu: '/message/tab',
      title: '短信模板',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/smsTask/listSmsTemplatePage',
      addUrl: '/message/temAdd',
      addTxt: "新增模板"
    }
  }, {
    path: 'temAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tem/add.vue"));
      });
    },
    name: 'messageTemAdd',
    meta: {
      title: '新增编辑模板',
      activeMenu: '/message/tab'
    }
  }, {
    path: 'class',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./class/list.vue"));
      });
    },
    name: 'messageClass',
    meta: {
      activeMenu: '/message/tab',
      title: '分类管理',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/smsTask/listSmsCategoryPage',
      addUrl: '/message/classAdd',
      addTxt: "新增模板"
    }
  }, {
    path: 'classAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./class/add.vue"));
      });
    },
    name: 'messageClassAdd',
    meta: {
      title: '新增编辑模板',
      activeMenu: '/message/tab'
    }
  }, {
    path: 'log',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./channels/list.vue"));
      });
    },
    name: 'messageLog',
    meta: {
      activeMenu: '/message/tab',
      title: '发送日志',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/smsTask/listSmsSendLogPage'
    }
  }]
};
var _default = messageRouter;
exports.default = _default;