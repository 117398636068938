"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.set");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.regexp.match");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      formType: null,
      parametersList: ['会员手机号', '会员昵称', "会员类型", "充值的购物卡名称", "优惠券名称", //"维护该客服的荔星伙伴",
      '365会员类型', '365会员有效期剩余天数', //'365会员过期天数',
      '365会员身份失效日期', 'lavant会员类型', '升级后lavant会员类型', '降级后lavant会员类型', //'lavant会员过期后天数',
      //'lavant会员有效期剩余天数',
      //'lavant身份降级日期',
      //'lavant身份过期日期',
      '充值购物卡金额', '优惠券失效日期', '沙龙活动名称', '沙龙活动预约时间', '消费金额', '消费门店', '商品名称', '评价内容', '维护该客户的荔星伙伴'],
      classList: [],
      parameterItem: null,
      formData: [{
        label: '模板名称：',
        type: 'input',
        placeholder: '请输入模板名称',
        value: 'templateName'
      }, {
        slot: 'categoryId'
      }, {
        label: '信息类型：',
        type: 'radio',
        placeholder: '请选择信息类型',
        value: 'infoType',
        selectList: [{
          label: '文字信息',
          value: 1
        }, {
          label: '企微信息',
          value: 2
        }]
      }, {
        label: '状态：',
        type: 'radio',
        placeholder: '请选择状态',
        value: 'status',
        selectList: [{
          label: '停用',
          value: 1
        }, {
          label: '启用',
          value: 2
        }]
      }, {
        slot: 'content'
      }, {
        label: '频率控制：',
        type: 'inputNumber',
        placeholder: '请输入频率控制',
        value: 'dailyLimitNum',
        remark: '单个用户每天可以发送多少次'
      }],
      ruleForm: {
        parameters: [],
        content: '',
        dailyLimitNum: undefined,
        status: null,
        infoType: null,
        categoryId: null,
        templateName: null,
        inputNumber: undefined,
        id: null
      },
      rules: {
        templateName: [{
          required: true,
          message: '请输入模板名称'
        }],
        categoryId: [{
          required: true,
          message: '请选择分类'
        }],
        infoType: [{
          required: true,
          message: '请选择类型'
        }],
        content: [{
          required: true,
          message: '请输入内容'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        dailyLimitNum: [{
          required: true,
          message: '请输入频率'
        }],
        parameters: []
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    this.apiPost('/api/smsTask/listSmsCategoryPage', {
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      _this.classList = res.data.records;
    });
    this.formType = this.$route.query.type;
    this.ruleForm.id = this.$route.query.id || '';

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/smsTask/getSmsTemplateById/' + this.ruleForm.id).then(function (res) {
        delete res.data.operateTime;
        delete res.data.operateUser;
        _this.ruleForm = res.data;
      });
    }
  },
  methods: {
    changeParameItem: function changeParameItem(e) {
      if (!e) return;
      this.ruleForm.content = this.ruleForm.content + '${' + e + '}'; // this.parameterItem = ''
    },
    changeParame: function changeParame(e) {
      this.ruleForm.content = this.ruleForm.content + '${' + e[e.length - 1] + '}';
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var str = this.ruleForm.content;
      var matches = str.match(/\${(.*?)}/g);

      if (matches) {
        var parameters = matches.map(function (item) {
          return item.substring(2, item.length - 1);
        });
        this.ruleForm.parameters = Array.from(new Set(parameters)).join(',');
      } else {
        this.ruleForm.parameters = '';
      }

      var apiUrl = this.ruleForm.id && this.formType != 'copy' ? '/api/smsTask/editSmsTemplate' : '/api/smsTask/addSmsTemplate';
      this.apiPost(apiUrl, this.ruleForm).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;