"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      api: '/api/gamePrizeManager/listGamePrizeManager',
      columns: [{
        prop: 'gameCode',
        label: '游戏ID'
      }, {
        prop: 'activityName',
        label: '活动名称'
      }, {
        prop: 'prizeNum',
        label: '抽奖次数'
      }, {
        prop: 'startTime',
        label: '开始时间'
      }, {
        prop: 'endTime',
        label: '结束时间'
      }, {
        prop: 'useIntegral',
        label: '单次话费积分'
      }, {
        slot: 'status',
        prop: 'status',
        label: '游戏状态'
      }, {
        slot: 'actions',
        prop: 'actions',
        label: '操作'
      }],
      queryParams: [{
        type: 'input',
        placeholder: '游戏ID',
        value: 'gameCode'
      }, {
        type: 'input',
        placeholder: '游戏名称',
        value: 'gameName'
      }, {
        type: 'input',
        placeholder: '用户手机号',
        value: 'gameName'
      }, {
        type: 'radio',
        placeholder: '奖励类型',
        value: 'openStoreId',
        select: []
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'endTime'
      }]
    };
  }
};
exports.default = _default;