var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-member-list" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    要客列表\n    "),
        _c(
          "div",
          [
            _vm.setMenuPerms("addLavant")
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-circle-plus-outline"
                    },
                    on: {
                      click: function($event) {
                        return _vm.goto("/svip/member/add")
                      }
                    }
                  },
                  [_vm._v("\n        新增要客\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.setMenuPerms("exportLavant")
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      icon: "el-icon-download",
                      loading: _vm.exportExcelLoading,
                      size: "mini"
                    },
                    on: { click: _vm.exportExcel }
                  },
                  [_vm._v("\n        导出Excel\n      ")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticClass: "input2",
                attrs: { size: "mini", placeholder: "联系电话" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetch("search")
                  }
                },
                model: {
                  value: _vm.searchParams.contactNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "contactNumber", $$v)
                  },
                  expression: "searchParams.contactNumber"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticClass: "input2",
                attrs: { size: "mini", placeholder: "要客姓名" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetch("search")
                  }
                },
                model: {
                  value: _vm.searchParams.name,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "name", $$v)
                  },
                  expression: "searchParams.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticClass: "input2",
                attrs: { size: "mini", placeholder: "荔星伙伴" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetch("search")
                  }
                },
                model: {
                  value: _vm.searchParams.managerName,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "managerName", $$v)
                  },
                  expression: "searchParams.managerName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    filterable: "",
                    placeholder: "要客状态"
                  },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status"
                  }
                },
                _vm._l(_vm.statusList, function(s) {
                  return _c("el-option", {
                    key: s.value,
                    attrs: { label: s.label, value: s.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    filterable: "",
                    placeholder: "下月1号状态"
                  },
                  model: {
                    value: _vm.searchParams.preLevelChange,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "preLevelChange", $$v)
                    },
                    expression: "searchParams.preLevelChange"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "不变", value: 0 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "降级", value: -1 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    filterable: "",
                    placeholder: "要客等级"
                  },
                  model: {
                    value: _vm.searchParams.levelId,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "levelId", $$v)
                    },
                    expression: "searchParams.levelId"
                  }
                },
                _vm._l(_vm.levelList, function(s) {
                  return _c("el-option", {
                    key: s.id,
                    attrs: { label: s.name, value: s.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "daterange",
                  size: "mini",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd"
                },
                model: {
                  value: _vm.searchParams.date,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "date", $$v)
                  },
                  expression: "searchParams.date"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.fetch("search")
                    }
                  }
                },
                [_vm._v("\n      查询\n    ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "info",
                    size: "mini",
                    icon: "el-icon-refresh"
                  },
                  on: {
                    click: function($event) {
                      return _vm.fetch("reset")
                    }
                  }
                },
                [_vm._v("\n      重置\n    ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.setMenuPerms("inviteLavant")
        ? _c(
            "el-row",
            {
              staticStyle: { "padding-bottom": "10px" },
              attrs: { type: "flex", justify: "end" }
            },
            [
              !_vm.isBatch
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.openBatch }
                      },
                      [_vm._v("\n        批量邀请\n      ")]
                    )
                  ]
                : [
                    _c(
                      "el-button",
                      {
                        attrs: { plain: "", size: "mini" },
                        on: { click: _vm.closeBatch }
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popconfirm",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { title: "确定要发送邀请吗？" },
                        on: { confirm: _vm.batchInvite }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "primary",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("\n          批量发送\n        ")]
                        )
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            data: _vm.list,
            border: "",
            "header-cell-class-name": "table_header_cell"
          },
          on: { "selection-change": _vm.selectItems }
        },
        [
          _vm.isBatch
            ? _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "40",
                  selectable: _vm.selectable
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "130", align: "center", label: "要客姓名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "80", align: "center", label: "要客等级" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.levelId >= scope.row.consumeLevelId
                      ? _c("span", [_vm._v(_vm._s(scope.row.levelName))])
                      : scope.row.consumeLevelId >= scope.row.levelId
                      ? _c("span", [_vm._v(_vm._s(scope.row.consumeLevelName))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "80", align: "center", label: "性别" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.sex == 1 ? _c("span", [_vm._v("男")]) : _vm._e(),
                    _vm._v(" "),
                    scope.row.sex == 2 ? _c("span", [_vm._v("女")]) : _vm._e(),
                    _vm._v(" "),
                    scope.row.sex == 0 ? _c("span", [_vm._v("未知")]) : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "生日" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.birthday))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "联系电话" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.contactNumber) +
                        "\n       "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "加入要客时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.addTime))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "维护荔星伙伴" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.status === 2
                      ? [
                          _c("span", [_vm._v(_vm._s(scope.row.managerName))]),
                          _vm._v(" "),
                          _vm.setMenuPerms("lavantPartner")
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.changeOpen("change", scope.row)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-edit-outline"
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "要客状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 1
                      ? _c("div", [_vm._v("待加入")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c("div", [_vm._v("已加入")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 3
                      ? _c("div", [_vm._v("已剔除")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 4
                      ? _c("div", [_vm._v("已过期")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "下个月1号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.preLevelChange == 0
                      ? _c("div", [_vm._v("不变")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.preLevelChange == -1
                      ? _c("div", [_vm._v("降级")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "250", align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "center" } },
                      [
                        _vm.setMenuPerms("lavantDetail")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.goto(
                                      "/svip/member/detail/" + scope.row.id
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n            详情\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.setMenuPerms("editLavant")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.goto(
                                      "/svip/member/edit/" + scope.row.id
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n            编辑\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 1
                          ? [
                              scope.row.invited
                                ? _c(
                                    "el-button",
                                    { attrs: { disabled: "", type: "text" } },
                                    [
                                      _vm._v(
                                        "\n              已邀请\n            "
                                      )
                                    ]
                                  )
                                : _vm.setMenuPerms("inviteLavant")
                                ? _c(
                                    "el-popconfirm",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { title: "确定要发送邀请吗？" },
                                      on: {
                                        confirm: function($event) {
                                          return _vm.invite([scope.row.id])
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#67c23a" },
                                          attrs: {
                                            slot: "reference",
                                            type: "text"
                                          },
                                          slot: "reference"
                                        },
                                        [
                                          _vm._v(
                                            "\n                发送邀请\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 2 && _vm.setMenuPerms("delLavant")
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.reasonOpen(
                                      "reject",
                                      scope.row.id
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n            剔除\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 3 &&
                        _vm.setMenuPerms("recoverLavant")
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "#67c23a" },
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.reasonOpen(
                                      "recover",
                                      scope.row.id
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n            恢复\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status !== 1 &&
                        _vm.setMenuPerms("lavlantEquity")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.goto(
                                      "/svip/member/right/" + scope.row.uid
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n            要客权益\n          ")]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.searchParams.pageNo,
          limit: _vm.searchParams.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchParams, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchParams, "pageSize", $event)
          },
          pagination: _vm.fetch
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.reasonVisible, width: "350px" },
          on: {
            "update:visible": function($event) {
              _vm.reasonVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "reasonForm", attrs: { model: _vm.reasonForm } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "输入原因：",
                    prop: "reason",
                    rules: [
                      { required: true, message: "请输入原因", trigger: "blur" }
                    ]
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: {
                      placeholder: "请输入原因",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.reasonForm.reason,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.reasonForm,
                          "reason",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "reasonForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.reasonVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.reject("reasonForm")
                    }
                  }
                },
                [_vm._v("\n        确 定\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dateVisible, width: "350px" },
          on: {
            "update:visible": function($event) {
              _vm.dateVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "reasonForm", attrs: { model: _vm.dateForm } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "sVipEndDate",
                    rules: [
                      {
                        required: true,
                        message: "请选择变更时间",
                        trigger: "blur"
                      }
                    ]
                  }
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请选择日期",
                      type: "date",
                      size: "mini",
                      "value-format": "yyyy-MM-dd"
                    },
                    model: {
                      value: _vm.dateForm.sVipEndDate,
                      callback: function($$v) {
                        _vm.$set(_vm.dateForm, "sVipEndDate", $$v)
                      },
                      expression: "dateForm.sVipEndDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dateVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.reject("reasonForm")
                    }
                  }
                },
                [_vm._v("\n        确 定\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.changeVisible, width: "350px" },
          on: {
            "update:visible": function($event) {
              _vm.changeVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "reasonForm", attrs: { model: _vm.reasonForm } },
            [
              _vm.managerStatus === 2 || _vm.managerStatus === 3
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择荔星伙伴：",
                        prop: "managerId",
                        rules: [
                          {
                            required: true,
                            message: "请选择荔星伙伴",
                            trigger: "blur"
                          }
                        ]
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form_input",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.reasonForm.managerId,
                            callback: function($$v) {
                              _vm.$set(_vm.reasonForm, "managerId", $$v)
                            },
                            expression: "reasonForm.managerId"
                          }
                        },
                        _vm._l(_vm.managerOptions, function(manager) {
                          return _c("el-option", {
                            key: manager.value,
                            attrs: {
                              label:
                                "" +
                                manager.label +
                                (!manager.active ? "（已离职）" : ""),
                              value: manager.value,
                              disabled: !manager.active
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "输入原因：",
                    prop: "reason",
                    rules: [
                      { required: true, message: "请输入原因", trigger: "blur" }
                    ]
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: {
                      placeholder: "请输入原因",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.reasonForm.reason,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.reasonForm,
                          "reason",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "reasonForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.changeVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.reject("reasonForm")
                    }
                  }
                },
                [_vm._v("\n        确 定\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }