"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// Here is a list of the toolbar
// Detail list see https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols
var toolbar = ['searchreplace fontsizeselect forecolor backcolor bold italic underline  strikethrough alignleft aligncenter alignright outdent indent   blockquote ', 'hr bullist numlist link  charmap preview   insertdatetime media table emoticons undo redo removeformat subscript superscript code codesample fullscreen'];
var _default = toolbar;
exports.default = _default;