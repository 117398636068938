"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var getCouponRouter = {
  path: '/getCoupon',
  component: _layout.default,
  redirect: '/getCoupon/tab',
  name: 'GetCoupon',
  meta: {
    title: '领券中心',
    icon: 'nested'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/getCoupon/tab.vue'));
      });
    },
    name: 'GetCouponTab',
    meta: {
      title: '领券中心 '
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/getCoupon/list.vue'));
      });
    },
    name: 'GetCouponList',
    meta: {
      title: '领券中心'
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/getCoupon/add.vue'));
      });
    },
    name: 'GetCouponAdd',
    meta: {
      title: '在领券中心新增/编辑优惠券'
    }
  }, {
    path: 'deatil',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/getCoupon/deatil.vue'));
      });
    },
    name: 'deatil',
    meta: {
      title: '领券中心方案详情'
    }
  }, {
    path: 'labelList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/label/label/list.vue'));
      });
    },
    name: 'labelIndexs',
    meta: {
      title: '方案标签 '
    }
  }, {
    path: 'labelAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/label/label/add.vue'));
      });
    },
    name: 'labelAdds',
    meta: {
      title: '新增/编辑方案标签'
    }
  }]
};
var _default = getCouponRouter;
exports.default = _default;