var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("报名详情 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          staticClass: "demo-ruleForm",
          attrs: { size: "small", "label-width": "150px" }
        },
        [
          _c("el-form-item", { attrs: { label: "活动名称：" } }, [
            _vm._v("\n      " + _vm._s(_vm.ruleForm.activityName) + "\n    ")
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "报名用户：" } }, [
            _vm._v("\n      " + _vm._s(_vm.ruleForm.username) + "\n    ")
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "手机号码：" } }, [
            _vm._v("\n      " + _vm._s(_vm.ruleForm.userMobile) + "\n    ")
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "会员等级：" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.gradeType[_vm.ruleForm.userLevel]) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "报名时间：" } }, [
            _vm._v(
              "\n      " + _vm._s(_vm.ruleForm.registrationTime) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "view_title flex_between" }, [
            _vm._v("报名信息 ")
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _vm._l(_vm.ruleForm.registrationInfoMap, function(item, key, index) {
            return _c(
              "el-form-item",
              { key: index, attrs: { label: key + "：" } },
              [_vm._v("\n      " + _vm._s(_vm.getValue(item)) + "\n    ")]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }