var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editScoreSet" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("新增/编辑商品")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "30px" },
          attrs: {
            rules: _vm.rules,
            size: "small",
            model: _vm.form,
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "className", label: "商品分类：", required: "" }
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%", "max-width": "300px" },
                  attrs: {
                    filterable: "",
                    size: "small",
                    "collapse-tags": "",
                    placeholder: "请选择商品分类"
                  },
                  model: {
                    value: _vm.form.className,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "className", $$v)
                    },
                    expression: "form.className"
                  }
                },
                _vm._l(_vm.classlist, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.className, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "goodsName", label: "商品名称：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: { placeholder: "商品名称", size: "small" },
                model: {
                  value: _vm.form.goodsName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsName", $$v)
                  },
                  expression: "form.goodsName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "goodsCode", label: "商品编码：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: { placeholder: "商品编码", size: "small" },
                model: {
                  value: _vm.form.goodsCode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsCode", $$v)
                  },
                  expression: "form.goodsCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "goodsPrice", label: "商品价格：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: {
                  type: "number",
                  placeholder: "商品价格",
                  size: "small"
                },
                model: {
                  value: _vm.form.goodsPrice,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsPrice", $$v)
                  },
                  expression: "form.goodsPrice"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "goodsNum", label: "商品数量：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: {
                  type: "number",
                  placeholder: "商品数量",
                  size: "small"
                },
                model: {
                  value: _vm.form.goodsNum,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsNum", $$v)
                  },
                  expression: "form.goodsNum"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "goodsSort", label: "商品排序：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: {
                  type: "number",
                  placeholder: "商品排序",
                  size: "small"
                },
                model: {
                  value: _vm.form.goodsSort,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsSort", $$v)
                  },
                  expression: "form.goodsSort"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "goodsImg", label: "商品图片：", required: "" }
            },
            [
              _c("add-load", {
                attrs: { width: 300, height: 183, cover: _vm.form.goodsImg },
                on: { setCover: _vm.setCover }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "goodsStatus", label: "商品状态：", required: "" }
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.form.goodsStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "goodsStatus", $$v)
                    },
                    expression: "form.goodsStatus"
                  }
                },
                [_vm._v("启用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.form.goodsStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "goodsStatus", $$v)
                    },
                    expression: "form.goodsStatus"
                  }
                },
                [_vm._v("禁用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "goodsDesc", label: "商品描述：", required: "" }
            },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 600, height: 300 },
                model: {
                  value: _vm.form.goodsDesc,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsDesc", $$v)
                  },
                  expression: "form.goodsDesc"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: {
            width: "100%",
            "max-width": "200px",
            "margin-left": "160px"
          },
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.onSubmit("form")
            }
          }
        },
        [_vm._v("保存")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }