"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

/** When your routing table is too long, you can split it into small modules **/
var legouMerchantRouter = {
  path: 'merchant',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
    });
  },
  redirect: '/legou/merchant/category',
  name: 'legoumerchant',
  meta: {
    title: '商品管理'
  },
  children: [{
    path: 'category',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./category/list.vue"));
      });
    },
    name: 'legoumerchantCategory',
    meta: {
      title: '店内分类',
      keepAlive: true,
      isOneRequest: 'no',
      addTxt: "新增分类",
      addUrl: "/legou/merchant/categoryAdd"
    }
  }, {
    path: 'categoryAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./category/add.vue"));
      });
    },
    name: 'legoumerchantCategoryAdd',
    meta: {
      title: '新增分类',
      activeMenu: '/legou/merchant/category'
    }
  }, {
    path: 'address',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./address/list.vue"));
      });
    },
    name: 'legoumerchantaddress',
    meta: {
      title: '地址管理',
      keepAlive: true,
      isOneRequest: 'no',
      addTxt: "新增地址",
      addUrl: "/legou/merchant/addressAdd"
    }
  }, {
    path: 'addressAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./address/add.vue"));
      });
    },
    name: 'legoumerchantaddressAdd',
    meta: {
      title: '新增地址',
      activeMenu: '/legou/merchant/address'
    }
  }]
};
var _default = legouMerchantRouter;
exports.default = _default;