"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

var _manage = require("@/api/manage.js");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      title: '新增栏目组',
      obj: {
        groupId: '',
        // 栏目组ID
        groupName: '',
        // 后台名称
        frontName: '',
        // 前端名称
        memberId: '',
        // 所属会员ID
        status: 1,
        // 栏目组状态
        nameType: 1,
        // 前端名称类型
        sort: '',
        // 排序
        // 会员列表
        memArr: [{
          cardId: 0,
          institutionCode: '[]',
          name: '无'
        }]
      },
      option: [{
        label: '文字',
        value: 1
      }, {
        label: '图标',
        value: 2
      }],
      titName: '',
      titNameImg: ''
    };
  },
  watch: {},
  destroyed: function destroyed() {
    this.$store.dispatch('editor/setColumnGroupEditor', JSON.stringify(this.obj));
  },
  created: function created() {
    this.obj.groupId = Number(this.$route.query.groupId);
    var data = {};

    if (this.$store.state.editor.columnGroupEditor) {
      data = JSON.parse(this.$store.state.editor.columnGroupEditor);
    }

    if (data.groupId === this.obj.groupId) {
      this.obj = data;
    } else {
      this.init();
    }
  },
  methods: {
    selectChange: function selectChange(e) {
      var str = String(this.obj.frontName);

      if (str.indexOf('png') != -1 || str.indexOf('jpg') != -1) {
        this.titNameImg = str;
      } else {
        this.titName = str;
      }

      if (e === 2) {
        this.obj.frontName = this.titNameImg;
      } else {
        this.obj.frontName = this.titName;
      }
    },

    /*
      上传图片
      */
    setCover: function setCover(cover) {
      this.obj.frontName = cover;
    },
    change: function change(versionDesc) {
      this.obj.versionDesc = versionDesc;
    },
    init: function init() {
      var _this = this;

      (0, _manage.listMemberCards)().then(function (res) {
        _this.obj.memArr = _this.obj.memArr.concat(res.data);
      }).catch(function (res) {
        _this.openLoading().close();
      });

      if (this.obj.groupId) {
        this.openLoading();
        this.title = '编辑栏目组';
        (0, _coupon.getColumnGroupById)({
          groupId: this.obj.groupId
        }).then(function (res) {
          _this.openLoading().close();

          _this.obj.groupName = res.data.groupName;
          _this.obj.nameType = res.data.nameType;
          _this.obj.frontName = res.data.frontName;
          _this.obj.memberId = res.data.memberId;
          _this.obj.status = res.data.status;
          _this.obj.sort = res.data.sort;
        }).catch(function (res) {
          _this.openLoading().close();
        });
      }
    },
    next: function next() {
      var _this2 = this;

      console.log(this.obj.memberId);

      if (!this.obj.groupName) {
        this.$message.error('请填写栏目组名');
      } else if (this.obj.memberId === '') {
        this.$message.error('请选择会员');
      } else if (this.obj.sort === '' || this.obj.sort < 1) {
        this.$message.error('请填写排序号');
      } else if (this.obj.status === '') {
        this.$message.error('请选择版本状态');
      } else {
        this.openLoading('努力保存中');

        if (this.obj.groupId) {
          (0, _coupon.editColumnGroup)(this.obj).then(function (res) {
            _this2.openLoading().close(); // this.$store.dispatch("editor/setVersionsEditor",'');


            _this2.$router.back(-1);
          }).catch(function (res) {
            _this2.openLoading().close();
          });
        } else {
          console.log(this.obj);
          (0, _coupon.addColumnGroup)(this.obj).then(function (res) {
            _this2.openLoading().close();

            _this2.$router.back(-1);
          }).catch(function (res) {
            _this2.openLoading().close();
          });
        }
      }
    }
  }
};
exports.default = _default;