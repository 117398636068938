"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _GRCB = _interopRequireDefault(require("./GRCB"));

var _index = _interopRequireDefault(require("./index"));

var router = {
  name: '银行积分',
  path: 'scoreCB',
  component: _index.default,
  children: [{
    name: '农商行金豆',
    path: 'GRCB',
    component: _GRCB.default,
    meta: {
      title: '农商行金豆记录',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'items',
      pageTotal: 'totalNum',
      api: '/api/order/jinDouOrderPage',
      columns: [{
        label: '订单号',
        prop: 'orderNo',
        width: 80
      }, {
        label: '支付金额',
        prop: 'payAmount',
        width: 80
      }, {
        label: '交易流水号',
        prop: 'tradeNo',
        width: 60
      }, {
        label: '订单金额',
        prop: 'amountRealpay',
        width: 80
      }, {
        label: '米卡UID',
        prop: 'buyUtphone',
        width: 80
      }, {
        label: '农商行用户',
        prop: 'remark',
        width: 80
      }, {
        slot: 'payType'
      }, {
        label: '订单时间',
        prop: 'createTime',
        width: 60
      }]
    }
  }]
};
var _default = router;
exports.default = _default;