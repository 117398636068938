"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: "关键词",
        prop: "name",
        width: 50
      }, {
        label: "最小金额",
        prop: "minAmount",
        width: 50
      }, {
        label: "最大金额",
        prop: "maxAmount",
        width: 50
      }, {
        label: "创建",
        prop: "createTime",
        width: 50
      }, {
        slot: 'update'
      }]
    };
  },
  methods: {
    delGrade: function delGrade(row) {
      var _this = this;

      this.apiGet('/api/dis365ExchangeScore/deleteDis365ExchangeScoreKeyword/' + row.id).then(function (res) {
        _this.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;