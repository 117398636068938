var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("编辑广告体")]),
    _vm._v(" "),
    _c("div", { staticClass: "ecitList_box1 box" }, [
      _c(
        "div",
        { staticClass: "box1_left" },
        [
          _c("p", { staticClass: "ecit_tit" }, [_vm._v("广告体名称：")]),
          _vm._v(" "),
          _c("el-input", {
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box1_right" },
        [
          _c("p", { staticClass: "ecit_tit" }, [_vm._v("选择所属广告位：")]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择所属广告位" },
              model: {
                value: _vm.parentId,
                callback: function($$v) {
                  _vm.parentId = $$v
                },
                expression: "parentId"
              }
            },
            _vm._l(_vm.parentIds, function(item) {
              return _c("el-option", {
                key: item.advertId,
                attrs: { label: item.name, value: item.advertId }
              })
            }),
            1
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ecitList_box2 box" }, [
      _c("div", { staticClass: "box2-left" }, [
        _c("ul", [
          _c("li", [
            _c("p", { staticClass: "ecit_tit" }, [_vm._v("选择广告体类型：")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择广告体类型" },
                    model: {
                      value: _vm.adventType,
                      callback: function($$v) {
                        _vm.adventType = $$v
                      },
                      expression: "adventType"
                    }
                  },
                  _vm._l(_vm.adventTypes, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "ecit_tit" }, [_vm._v("广告体附件地址：")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("add-load", {
                  attrs: { width: 300, height: 100, cover: _vm.imgPath },
                  on: { setCover: _vm.setCover }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "ecit_tit" }, [_vm._v("广告体链接地址：")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.showPop }
                  },
                  [_vm._v("选择广告体链接")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticStyle: { "margin-left": "10px" } }, [
              _vm._v("链接地址：" + _vm._s(_vm.selectItem.label))
            ]),
            _vm._v(" "),
            _vm.selectItem.id
              ? _c("p", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v(_vm._s(_vm.selectItem.id) + ":" + _vm._s(_vm.code))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.selectItem.appId
              ? _c("p", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v(
                    _vm._s(_vm.selectItem.appId) + ":" + _vm._s(_vm.appIdName)
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "ecit_tit" }, [_vm._v("广告状态：")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1" },
                    model: {
                      value: _vm.status,
                      callback: function($$v) {
                        _vm.status = $$v
                      },
                      expression: "status"
                    }
                  },
                  [_vm._v("启用")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "-1" },
                    model: {
                      value: _vm.status,
                      callback: function($$v) {
                        _vm.status = $$v
                      },
                      expression: "status"
                    }
                  },
                  [_vm._v("停用")]
                )
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "nextBtn" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.next } },
          [_vm._v("保存")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.urlPopShow
      ? _c("div", { staticClass: "urlPop" }, [
          _c("div", { staticClass: "urlPop_box" }, [
            _c(
              "div",
              { staticClass: "urlPop_box_item" },
              [
                _c("div", { staticClass: "urlPop_box_item_tit" }, [
                  _vm._v("\n          选择页面：\n        ")
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "urlPop_box_item_input",
                    attrs: { filterable: "", placeholder: "请选择" },
                    on: { change: _vm.selectChange },
                    model: {
                      value: _vm.urlValue,
                      callback: function($$v) {
                        _vm.urlValue = $$v
                      },
                      expression: "urlValue"
                    }
                  },
                  _vm._l(_vm.options, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.selectItem.id
              ? _c(
                  "div",
                  { staticClass: "urlPop_box_item" },
                  [
                    _c("div", { staticClass: "urlPop_box_item_tit" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.selectItem.id) +
                          "：\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "urlPop_box_item_input",
                      attrs: { placeholder: _vm.selectItem.id },
                      model: {
                        value: _vm.code,
                        callback: function($$v) {
                          _vm.code = $$v
                        },
                        expression: "code"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.selectItem.appId
              ? _c(
                  "div",
                  { staticClass: "urlPop_box_item" },
                  [
                    _c("div", { staticClass: "urlPop_box_item_tit" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.selectItem.appId) +
                          "：\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "urlPop_box_item_input",
                        attrs: { placeholder: _vm.selectItem.appId },
                        on: { change: _vm.setAppId },
                        model: {
                          value: _vm.appId,
                          callback: function($$v) {
                            _vm.appId = $$v
                          },
                          expression: "appId"
                        }
                      },
                      _vm._l(_vm.appIdArr, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "urlPop_box_item",
                staticStyle: { "margin-bottom": "0" }
              },
              [
                _c("div", { staticClass: "urlPop_box_item_tit" }),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("el-button", { on: { click: _vm.closeUrlPop } }, [
                      _vm._v("取消")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.saveUrl }
                      },
                      [_vm._v("保存")]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }