"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _coupon = require("@/api/coupon.js");

var _deposit = require("@/api/deposit");

var _member = require("@/api/member");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default
  },
  data: function data() {
    return {
      cardOptions: [],
      couponOptions: [],
      loading: false,
      form: {
        id: 0,
        title: undefined,
        quantity: undefined,
        type: 0,
        code: undefined,
        remark: undefined
      },
      popType: 'coupon',
      couponShow: false,
      rules: {
        title: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        quantity: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    setCoupon: function setCoupon(e) {
      this.form.code = e.uuid;
      this.couponShow = false;
    },
    getAward: function getAward(e) {
      this.popType = e;
      this.$refs.coupon1.getList(this.popType, this.form.code);
      this.couponShow = true;
    },
    remoteCoupon: function remoteCoupon(query) {
      var _this = this;

      if (query !== '') {
        this.loading = true;
        (0, _coupon.listCouponPage)({
          pageNo: 1,
          pageSize: 10,
          name: query
        }).then(function (res) {
          _this.couponOptions = res.data.items;
          _this.loading = false;
        });
      } else {
        this.cardOptions = [];
      }
    },
    remoteCard: function remoteCard(query) {
      var _this2 = this;

      if (query !== '') {
        this.loading = true;
        (0, _deposit.listCardStored)({
          pageNo: 1,
          pageSize: 10,
          name: query,
          type: [1]
        }).then(function (res) {
          _this2.cardOptions = res.data.items;
          _this2.loading = false;
        });
      } else {
        this.cardOptions = [];
      }
    },
    handleSave: function handleSave() {
      var _this3 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var pick = function pick(obj, arr) {
            return arr.reduce(function (iter, val) {
              return val in obj && (iter[val] = obj[val]), iter;
            }, {});
          };

          var data = pick(_this3.form, ['id', 'title', 'quantity', 'type', 'code', 'remark']);
          (0, _member.updeateInformationReward)(data).then(function (res) {
            _this3.$message.success('保存成功');

            _this3.getData();
          });
        }
      });
    },
    changeType: function changeType(val) {
      var data = {
        id: this.form.id,
        title: this.form.title,
        quantity: undefined,
        type: val,
        code: undefined,
        remark: undefined
      };
      this.$refs['form'].resetFields();
      this.form = data;
    },
    getData: function getData() {
      var _this4 = this;

      (0, _member.selectInformationReward)().then(function (res) {
        _this4.$refs['form'].resetFields();

        _this4.form = res.data; // this.initOption(res.data)
      });
    },
    initOption: function initOption(data) {
      var _this5 = this;

      if (data.type == 2) {
        (0, _coupon.listCouponPage)({
          pageNo: 1,
          pageSize: 10,
          uuid: data.code
        }).then(function (res) {
          _this5.couponOptions = res.data.items;
        });
      }

      if (data.type == 3) {
        (0, _deposit.listCardStored)({
          pageNo: 1,
          pageSize: 10,
          uuid: data.code,
          type: [1]
        }).then(function (res) {
          _this5.cardOptions = res.data.items;
        });
      }
    },
    resetForm: function resetForm() {
      this.getData();
    }
  }
};
exports.default = _default;