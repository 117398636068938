var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("文章详情")]),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "appli_tit" },
      [
        _vm._v("\n    活动详情\n    "),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "has",
                rawName: "v-has",
                value: "article:edit",
                expression: "'article:edit'"
              }
            ],
            attrs: { type: "primary" },
            on: { click: _vm.goEdit }
          },
          [_vm._v("去编辑")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "appli_box",
      domProps: { innerHTML: _vm._s(_vm.content) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }