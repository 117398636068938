var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增/编辑银行网点 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "网点名称：", prop: "storeName" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入网点名称" },
                model: {
                  value: _vm.ruleForm.storeName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "storeName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.storeName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态：", prop: "storeStatus" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.ruleForm.storeStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "storeStatus", $$v)
                    },
                    expression: "ruleForm.storeStatus"
                  }
                },
                [_vm._v("停用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.storeStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "storeStatus", $$v)
                    },
                    expression: "ruleForm.storeStatus"
                  }
                },
                [_vm._v("启用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 0 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "storeAddress",
                        label: "地址：",
                        required: ""
                      }
                    },
                    [
                      _c("el-cascader", {
                        ref: "state",
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "small",
                          options: _vm.regionData,
                          clearable: "",
                          placeholder: "请选择"
                        },
                        on: { change: _vm.regionDataChange },
                        model: {
                          value: _vm.ruleForm.storeAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "storeAddress", $$v)
                          },
                          expression: "ruleForm.storeAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "0",
                        prop: "detailAddress",
                        required: ""
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: {
                          placeholder: "请输入详细地址",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.ruleForm.detailAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "detailAddress", $$v)
                          },
                          expression: "ruleForm.detailAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "纬经度：", prop: "longLat" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入纬经度" },
                model: {
                  value: _vm.ruleForm.longLat,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "longLat",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.longLat"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "remark" }, [
                _vm._v(" 请使用英文的逗号隔开； ")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "remark" }, [
                _vm._v(" 填写停车场出入口经纬度，可方便顾客导航至准确位置 ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "网点负责人：", prop: "contactPeople" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入网点负责人" },
                model: {
                  value: _vm.ruleForm.contactPeople,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "contactPeople",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.contactPeople"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "负责人电话：", prop: "contactPhone" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入负责人电话" },
                model: {
                  value: _vm.ruleForm.contactPhone,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "contactPhone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.contactPhone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "info" },
                  on: {
                    click: function($event) {
                      return _vm.clearForm("ruleForm")
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }