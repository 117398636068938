var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "parking-log" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("记录详情")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c("Descriptions", {
                attrs: {
                  title: "基本信息",
                  columns: _vm.basicColumns,
                  "data-source": _vm.parkLog
                }
              }),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("Descriptions", {
                attrs: {
                  title: "支付信息",
                  columns: _vm.payColumns,
                  "data-source": _vm.parkLog
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }