"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var validateAcquaintance = function validateAcquaintance(rule, value, callback) {
  if (value < 0) {
    callback(new Error('请输入大于等于0的整数'));
  } else if (!/(^[0-9]\d*$)/.test(value)) {
    callback(new Error('请输入大于等于0的整数'));
  } else {
    callback();
  }
};
/* const validateAcquaintance = (rule, value, callback) => {
      if (!(/(^[0-9]\d*$)/.test(value))) {
        callback(new Error('请输入大于0的整数'));
      } else {
        callback();
      }
    } */


var _default = {
  data: function data() {
    return {
      form: {
        saleDay: '',
        receivingDay: '',
        logisticAppid: '',
        logisticSecret: '',
        carryDay: '',
        carryCloseDay: ''
      },
      rules: {
        logisticAppid: [{
          required: true,
          message: '请输入物流APPID'
        }],
        logisticSecret: [{
          required: true,
          message: '请输入物流秘钥'
        }],
        saleDay: [{
          required: true,
          message: '请输入售后天数'
        }, {
          validator: validateAcquaintance,
          message: '输入为大于0的数'
        }, {
          validator: function validator(rule, value, callback) {
            if (!/(^[0-9]\d*$)/.test(value)) {
              callback(new Error('请输入大于0的整数'));
            } else {
              callback();
            }
          }
        }],
        carryCloseDay: [{
          required: true,
          message: '请输入自提天数'
        }, {
          validator: validateAcquaintance
        }],
        carryDay: [{
          required: true,
          message: '请输入自提天数'
        }, {
          validator: validateAcquaintance
        }],
        receivingDay: [{
          required: true,
          message: '请输入收货天数'
        }, {
          validator: validateAcquaintance
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.openLoading();
    (0, _api.getSetting)().then(function (res) {
      _this.openLoading().close();

      Object.keys(_this.form).forEach(function (key) {
        _this.form[key] = res.data[key];
      });
    }).catch(function (er) {
      _this.openLoading().close();
    });
  },
  methods: {
    onSubmit: function onSubmit(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.openLoading();

          (0, _api.editSetting)(_this2.form).then(function (res) {
            _this2.openLoading().close();
          }).catch(function (er) {
            _this2.openLoading().close();
          });
        }
      });
    }
  }
};
exports.default = _default;