"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _flowCoupon = require("@/api/flowCoupon.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      obj: {
        pageNo: 1,
        pageSize: 10,
        mobile: '',
        idNo: ''
      },
      list: [],
      tabTh: [{
        prop: 'rangeCode',
        label: '返券活动范围编号',
        width: 100
      }, {
        prop: 'rangeName',
        label: '返券活动范围名称',
        width: 100
      }, {
        prop: 'createTime',
        label: '创建时间',
        width: 140
      }, // {
      //   prop: 'birthday',
      //   label: '创建人',
      //   width: 120
      // },
      {
        prop: 'rangeRemark',
        label: '备注',
        width: 140 // {
        //   prop: 'rangeStatusText',
        //   label: '状态',
        //   width: 80
        // },

      }],
      total: 0
    };
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
        this.searchTime = [];
      }

      this.openLoading();
      (0, _flowCoupon.listSendCouponPage)(this.obj).then(function (res) {
        _this.openLoading().close();

        _this.list = res.data.items;
        _this.total = res.data.totalNum; // this.list.forEach((item)=>{
        //   if(item.rangeStatus==1){
        //     item.rangeStatusText="启用"
        //   }
        //   if(item.rangeStatus==2){
        //     item.rangeStatusText="禁用"
        //   }
        // })
      }).catch(function (err) {
        _this.openLoading().close();
      });
    },
    goAdd: function goAdd(url) {
      this.$router.push(url);
      return;

      if (id) {
        this.$router.push('/flowCoupon/rangeAdd?id=' + id);
      } else {
        this.$router.push('/flowCoupon/rangeAdd');
      }
    }
  }
};
exports.default = _default;