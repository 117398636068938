var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 订单商品 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "w-table",
        { attrs: { "table-data": _vm.tableData, columns: _vm.columns } },
        [
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "状态",
              align: "center",
              width: "100"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 1
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("\n          已完成\n        ")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("已取消")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }