module.exports = {
  verificationModelArr: [{
    id: 1,
    name: 'PC收银机'
  }, {
    id: 2,
    name: '手持收银机'
  }, {
    id: 3,
    name: '核销小助手'
  }, {
    id: 4,
    name: '乐品到家'
  }, {
    id: 5,
    name: '乐品扫码购'
  }, {
    id: 6,
    name: '乐品自助'
  }, {
    id: 8,
    name: '直播间'
  }, {
    id: 9,
    name: '乐购商城'
  }],
  showStatusArr: [{
    id: 1,
    name: 'MIKA小程序'
  }, {
    id: 2,
    name: '乐品到家'
  }, {
    id: 3,
    name: '乐品扫码购'
  }, {
    id: 4,
    name: '乐品自助'
  }, {
    id: 5,
    name: '停车券'
  }, {
    id: 6,
    name: '名酒预定'
  }]
};