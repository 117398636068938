"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _dcb = require("@/api/dcb.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      id: '',
      logisticsName: '',
      logisticsNo: '',
      orderStatus: '',
      obj: {},
      returnRemark: ''
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      (0, _dcb.getOrderById)(this.id).then(function (res) {
        console.log(res);
        _this.logisticsName = res.data.logisticsName;
        _this.logisticsNo = res.data.logisticsNo;
        _this.orderStatus = res.data.orderStatus;
        _this.obj = res.data;
      });
    },
    setOrder: function setOrder() {
      var _this2 = this;

      var data = {
        id: this.id,
        orderStatus: 3,
        logisticsName: this.logisticsName,
        logisticsNo: this.logisticsNo
      };
      (0, _dcb.editOrder)(data).then(function (res) {
        _this2.init();
      });
    },
    returnPay: function returnPay() {
      var _this3 = this;

      var data = this.$refs.multipleTable.store.states.selection;

      if (data.length > 0 && this.returnRemark.trim()) {
        var obj = {
          id: this.id,
          returnRemark: this.returnRemark,
          orderItems: []
        };
        data.forEach(function (item) {
          obj.orderItems.push({
            itemId: item.itemId
          });
        });
        (0, _dcb.returnOrder)(obj).then(function (res) {
          _this3.init();
        });
      }
    }
  }
};
exports.default = _default;