var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "integral" }, [
    _c(
      "div",
      { staticClass: "search_input" },
      [
        _c(
          "el-row",
          { staticClass: "row_box", attrs: { gutter: 5 } },
          [
            _c(
              "el-col",
              { attrs: { span: 2 } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { size: "mini", placeholder: "状态" },
                    model: {
                      value: _vm.obj.basicStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "basicStatus", $$v)
                      },
                      expression: "obj.basicStatus"
                    }
                  },
                  _vm._l(_vm.typeArr, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 3 } },
              [
                _c("el-input", {
                  attrs: { size: "mini", placeholder: "编号" },
                  model: {
                    value: _vm.obj.basicCode,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "basicCode", $$v)
                    },
                    expression: "obj.basicCode"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 3 } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { "max-width": "400px", width: "100%" },
                    attrs: {
                      size: "mini",
                      placeholder: "所属总部",
                      filterable: ""
                    },
                    on: { change: _vm.setShort },
                    model: {
                      value: _vm.obj.companyCode,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "companyCode", $$v)
                      },
                      expression: "obj.companyCode"
                    }
                  },
                  _vm._l(_vm.companyList, function(item) {
                    return _c("el-option", {
                      key: item.companyCode,
                      attrs: { label: item.shortName, value: item.companyCode }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 2 } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { "max-width": "400px", width: "100%" },
                    attrs: {
                      size: "mini",
                      placeholder: "所属门店",
                      filterable: ""
                    },
                    model: {
                      value: _vm.obj.storeCode,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "storeCode", $$v)
                      },
                      expression: "obj.storeCode"
                    }
                  },
                  _vm._l(_vm.storeList, function(item) {
                    return _c(
                      "el-option",
                      {
                        key: item.storeId,
                        attrs: {
                          label: item.shortName + "（" + item.storeCode + "）",
                          value: item.storeCode
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              item.shortName + "（" + item.storeCode + "）"
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 2 } },
              [
                _c("el-input", {
                  attrs: { size: "mini", placeholder: "品类编码" },
                  model: {
                    value: _vm.obj.brandClsCode,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "brandClsCode", $$v)
                    },
                    expression: "obj.brandClsCode"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 2 } },
              [
                _c("el-input", {
                  attrs: { size: "mini", placeholder: "品牌编码" },
                  model: {
                    value: _vm.obj.brandCode,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "brandCode", $$v)
                    },
                    expression: "obj.brandCode"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 2 } },
              [
                _c("el-input", {
                  attrs: { size: "mini", placeholder: "商品编码" },
                  model: {
                    value: _vm.obj.goodsCode,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "goodsCode", $$v)
                    },
                    expression: "obj.goodsCode"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  size: "mini"
                },
                on: {
                  click: function($event) {
                    return _vm.init("search")
                  }
                }
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "info", size: "mini", icon: "el-icon-refresh" },
                on: {
                  click: function($event) {
                    return _vm.init("remove")
                  }
                }
              },
              [_vm._v("重置")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: { click: _vm.goAdd }
              },
              [_vm._v("新增")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  size: "mini",
                  icon: "el-icon-download"
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出")]
            )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%", "margin-top": "20px" },
            attrs: { data: _vm.tableData, size: "mini", border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { label: "排序", width: "55", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            (_vm.obj.pageNo - 1) * _vm.obj.pageSize +
                              scope.$index +
                              1
                          ) +
                          "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _vm._l(_vm.tabTh, function(item, index) {
              return _c("el-table-column", {
                key: index,
                attrs: {
                  prop: item.prop,
                  label: item.tit,
                  "min-width": item.width,
                  align: item.align
                }
              })
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                fixed: "right",
                label: "操作",
                align: "center",
                width: "120"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between"
                          }
                        },
                        [
                          _c(
                            "app-link",
                            {
                              staticStyle: { color: "#1890ff" },
                              attrs: {
                                to:
                                  "/integral/integralDea?id=" +
                                  scope.row.basicId
                              }
                            },
                            [_vm._v("详情")]
                          ),
                          _vm._v(" "),
                          _c(
                            "app-link",
                            {
                              staticStyle: { color: "#1890ff" },
                              attrs: { to: _vm.deaPath + scope.row.basicId }
                            },
                            [_vm._v("编辑")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0"
            }
          ],
          attrs: {
            total: _vm.total,
            page: _vm.obj.pageNo,
            limit: _vm.obj.pageSize
          },
          on: {
            "update:page": function($event) {
              return _vm.$set(_vm.obj, "pageNo", $event)
            },
            "update:limit": function($event) {
              return _vm.$set(_vm.obj, "pageSize", $event)
            },
            pagination: _vm.init
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }