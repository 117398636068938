"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      searchParams: {
        keyword: "13900000000",
        type: 1,
        ops: [{
          val: 1,
          lab: "卡号"
        }, {
          val: 2,
          lab: "实名手机号"
        }, {
          val: 3,
          lab: "身份证号"
        }]
      },
      infoOption: [{
        title: "卡种ID",
        val: "0000000000000",
        default: "-"
      }, {
        title: "卡种名称",
        val: "",
        default: "-"
      }, {
        title: "卡类型",
        val: "",
        default: "-"
      }, {
        title: "消费积分",
        val: "",
        default: "-"
      }, {
        title: "记名类型",
        val: "",
        default: "-"
      }, {
        title: "卡片面额",
        val: "",
        default: "-"
      }, {
        title: "激活后有效期",
        val: "",
        default: "-"
      }, {
        title: "可绑定为电子卡",
        val: "",
        default: "-"
      }, {
        title: "备注",
        val: "",
        default: "-"
      }, {
        title: "创建时间",
        val: "",
        default: "-"
      }, {
        title: "总库存",
        val: "",
        default: "-"
      }],
      realOption: [{
        title: "实名状态",
        val: "0000000000000",
        default: "-"
      }, {
        title: "会员名称",
        val: "0000000000000",
        default: "-"
      }, {
        title: "会员手机号",
        val: "0000000000000",
        default: "-"
      }, {
        title: "身份证号",
        val: "0000000000000",
        default: "-"
      }, {
        title: "实名时间",
        val: "0000000000000",
        default: "-"
      }],
      makeOption: [{
        title: "制卡商",
        val: "",
        default: "-"
      }, {
        title: "关联制卡计划ID",
        val: "",
        default: "-"
      }, {
        title: "入库时间",
        val: "",
        default: "-"
      }, {
        title: "关联销售单号",
        val: "",
        default: "-"
      }, {
        title: "销售时间",
        val: "",
        default: "-"
      }],
      list: [],
      requestParams: {},
      total: 0
    };
  },
  methods: {
    onReset: function onReset() {
      this.$refs['requestParamsForm'].resetFields();
      this.onQuery();
    },
    onQuery: function onQuery() {
      this.requestParams.pageNo = 1;
      this.getList();
    },
    onPageChange: function onPageChange() {
      this.getList();
    },
    getList: function getList() {// linksPage(this.requestParams).then(res => {
      //     this.list = res.data.records
      //     this.total = res.data.total
      // })
    }
  }
};
exports.default = _default;