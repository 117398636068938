"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getYmgzActivityConfig = getYmgzActivityConfig;
exports.ymgzActivityConfig = ymgzActivityConfig;

var _request = _interopRequireDefault(require("@/utils/request"));

function getYmgzActivityConfig(data) {
  return (0, _request.default)({
    url: '/api/activity/getYmgzActivityConfig',
    method: 'get',
    loading: 'loading'
  });
}

function ymgzActivityConfig(data) {
  return (0, _request.default)({
    url: '/api/activity/ymgzActivityConfig',
    method: 'post',
    loading: 'loading',
    data: data
  });
}