"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      isChagneGoodsStatus: false,
      columns: [{
        label: '规则名称',
        prop: 'name',
        width: 100
      }, {
        label: '商户名称',
        prop: 'merchantName',
        width: 100
      }, {
        label: '包邮门槛',
        prop: 'price',
        width: 50
      }, {
        label: '状态（0=停用,1=启用）',
        prop: 'isEnable',
        slot: 'isEnable',
        width: 50
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 120
      }, {
        label: '更新',
        prop: 'updateTime',
        width: 120
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        placeholder: '规则名称',
        value: 'name'
      }, {
        type: 'inputNumber',
        placeholder: '包邮门槛',
        value: 'price'
      }, {
        type: 'input',
        placeholder: '商户名称',
        value: 'merchantName'
      }, {
        type: 'radio',
        placeholder: '状态',
        value: 'isEnable',
        select: [{
          label: '启用',
          value: 1
        }, {
          label: '停用',
          value: 0
        }]
      }]
    };
  },
  methods: {
    setSelectGoods: function setSelectGoods(goodslist) {
      this.isChagneGoodsStatus = goodslist.length > 0;
    },
    isSelectDisabled: function isSelectDisabled(row) {
      return row.isJoin != 1;
    },
    goGoodsStatus: function goGoodsStatus(type) {
      var _this = this;

      var selectGoods = this.$refs.wTable.getSelectLists();
      var changeGoods = selectGoods.map(function (item) {
        return item.id;
      });
      var changeGoodsName = mapGoodsProperty(selectGoods, 'goodsName');
      var onShelf = ''; //type === 'down' ? 0 : 1;

      switch (type) {
        case 'down':
          onShelf = 0;
          break;

        case 'up':
          onShelf = 1;
          break;

        default:
          break;
      }

      var affrimTypeText = {
        down: "下架",
        up: "上架",
        del: "删除"
      };

      if (type == 'down' || type == 'up') {
        var errGoods = selectGoods.filter(function (item) {
          return item.status === onShelf;
        });

        if (errGoods.length > 0) {
          this.$message.error("\u6279\u91CF".concat(type === 'down' ? '下架' : '上架', "\u53EA\u80FD\u9009\u62E9\u5DF2").concat(type === 'down' ? '上架' : '下架', "\u5546\u54C1"));
          return;
        }
      }

      this.$confirm("\u6B64\u64CD\u4F5C\u5C06".concat(affrimTypeText[type], "\u5546\u54C1\uFF1A").concat(changeGoodsName.join(',')), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (type == 'del') {
          _this.delGoodsItem(changeGoods);

          return;
        }

        _this.changeGoodsStatus(type == 'down' ? 0 : 1, changeGoods);
      });

      function mapGoodsProperty(goodsList, property) {
        return goodsList.map(function (item) {
          return item[property];
        });
      }
    },
    delGoodsItem: function delGoodsItem(goodsIdList, index) {
      var _this2 = this;

      this.apiPost('/api/mall/goods/goods/delete', {
        goodsIdList: goodsIdList
      }).then(function (res) {
        _this2.$refs.wTable.getList();
      });
    },
    changeGoodsStatus: function changeGoodsStatus(e, goodslist) {
      var _this3 = this;

      this.apiPost(e == 0 ? '/api/mall/goods/goods/off' : '/api/mall/goods/goods/on', {
        goodsIdList: goodslist
      }).then(function (res) {
        _this3.$refs.wTable.getList();
      }).catch(function (err) {
        _this3.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;