var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "memberQuery" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("会员合并")]),
      _vm._v(" "),
      _c("div", { staticClass: "memberQuery_top" }, [
        _c(
          "div",
          { staticClass: "memberQuery_top_item" },
          [
            _c(
              "el-form",
              { attrs: { "label-width": "100px", size: "small" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "合并目标：", prop: "realname" } },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "memberQuery_top_item_input",
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.memeberCode,
                          callback: function($$v) {
                            _vm.memeberCode = $$v
                          },
                          expression: "memeberCode"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "110px" },
                            attrs: { slot: "prepend", placeholder: "请选择" },
                            slot: "prepend",
                            model: {
                              value: _vm.type,
                              callback: function($$v) {
                                _vm.type = $$v
                              },
                              expression: "type"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "手机号", value: "1" }
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "输入卡号", value: "3" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "9px 30px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.goSearch }
                      },
                      [_vm._v("查询")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "9px 30px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.goMid }
                      },
                      [_vm._v("刷卡")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("p", { staticClass: "memberQuery_user_tit" }, [
        _vm._v("目标账号信息：")
      ]),
      _vm._v(" "),
      _c("userInfo", {
        attrs: { user: _vm.user, status: _vm.userForm.status }
      }),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("p", { staticClass: "memberQuery_user_tit" }, [_vm._v("身份资料：")]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { overflow: "auto" } },
        [
          _c(
            "el-form",
            {
              ref: "formUser",
              attrs: { "label-width": "100px", size: "small" }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "真实姓名：", prop: "realname" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userForm.realname) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号：", prop: "idNo" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userForm.idNo) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "民族：", prop: "nation" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userForm.nation) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别：", prop: "sex" } },
                    [
                      _vm.userForm.sex == 1
                        ? _c("span", [_vm._v("男")])
                        : _vm.userForm.sex == 2
                        ? _c("span", [_vm._v("女")])
                        : _c("span", [_vm._v("未知")])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "出生日期：", prop: "birthday" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userForm.birthday) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { width: "400px" } },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px", size: "mini" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "钉钉工单号：" } },
                [
                  _c("el-input", {
                    ref: "midInput",
                    model: {
                      value: _vm.dingtalkNo,
                      callback: function($$v) {
                        _vm.dingtalkNo = $$v
                      },
                      expression: "dingtalkNo"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.affrimCancel }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.popShow,
              expression: "popShow"
            }
          ],
          staticClass: "memberQuery_pop"
        },
        [
          _c(
            "div",
            { staticClass: "memberQuery_pop_box" },
            [
              _c(
                "p",
                { staticStyle: { "font-weight": "bold", color: "red" } },
                [_vm._v("请刷卡")]
              ),
              _vm._v(" "),
              _c("el-input", {
                ref: "midInput",
                attrs: { autofocus: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.goSearchType($event)
                  }
                },
                model: {
                  value: _vm.mid,
                  callback: function($$v) {
                    _vm.mid = $$v
                  },
                  expression: "mid"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.closePop } },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }