"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _template = require("@/api/template.js");

var _api = require("@/api/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      modifyTime: '',
      ruleForm: {
        tmplateId: '',
        pageCode: '',
        pageName: '',
        classId: '',
        id: '',
        pageColor: '',
        templateStoreId: ''
      },
      rules: {
        templateStoreId: [{
          required: true,
          message: '请选择关联门店'
        }],
        pageCode: [{
          required: true,
          message: '请选择页面编码'
        }],
        tmplateId: [{
          required: true,
          message: '请选择页面模板'
        }],
        pageName: [{
          required: true,
          message: '请输入页面名称'
        }],
        classId: [{
          required: true,
          message: '请选择页面模板分类'
        }]
      },
      temClass: [],
      pageTem: [],
      storeList: []
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
    (0, _template.listTmplateClass)().then(function (res) {
      _this.temClass = res.data;
    });

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _template.getPage)(this.ruleForm.id).then(function (res) {
        res.data.templateStoreId = res.data.templateStoreId ? Number(res.data.templateStoreId) : null;
        _this.modifyTime = res.data.modifyTime;

        _this.fzObj(res.data, _this.ruleForm);

        (0, _template.getTmplate)(_this.ruleForm.tmplateId).then(function (res) {
          _this.ruleForm.classId = res.data.classId;
          (0, _template.listTmplate)({
            classId: res.data.classId
          }).then(function (res) {
            _this.pageTem = res.data;
          });
        });
      });
    }
  },
  methods: {
    setPageCode: function setPageCode(e) {
      var _this2 = this;

      this.ruleForm.tmplateId = '';
      (0, _template.listTmplate)({
        classId: this.ruleForm.classId
      }).then(function (res) {
        _this2.pageTem = res.data;
      });
    },
    submitForm: function submitForm(form) {
      var _this3 = this;

      this.$refs[form].validate(function (valid) {
        if (valid) {
          if (_this3.ruleForm.id) {
            (0, _template.editPage)(_this3.ruleForm).then(function (res) {
              _this3.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this3.$router.go(-1);
            });
          } else {
            (0, _template.addPage)(_this3.ruleForm).then(function (res) {
              _this3.$message({
                type: 'success',
                message: '新增成功!'
              });

              _this3.$router.go(-1);
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;