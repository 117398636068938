"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var couponItemRouter = {
  path: '/couponExternal',
  component: _layout.default,
  redirect: '/couponExternal/tab',
  name: 'couponexternal',
  meta: {
    title: '优惠券导出'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./external/tab.vue"));
      });
    },
    name: 'couponExternalTab',
    meta: {
      title: '优惠券导出'
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./external/list.vue"));
      });
    },
    name: 'couponExternalList',
    meta: {
      title: '导出记录',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/couponRecordLog/couponRecordListPage',
      columns: [{
        label: '优惠券名称',
        prop: 'name',
        width: 80
      }, {
        label: '面额',
        prop: 'denomination',
        width: 80
      }, {
        label: '兑换码',
        prop: 'receivedCode',
        width: 60
      }, {
        label: '导出人',
        prop: 'exportUser',
        width: 80
      }, {
        label: '导出人手机号',
        prop: 'exportUserPhone',
        width: 80
      }, {
        label: '导出时间',
        prop: 'exportTime',
        width: 80
      }]
    }
  }, {
    path: 'exchange',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./external/exchange.vue"));
      });
    },
    name: 'couponExternalexchange',
    meta: {
      title: '兑换记录',
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/couponRecordLog/couponLogListPage',
      columns: [{
        label: '优惠券名称',
        prop: 'couponName',
        width: 80
      }, {
        label: '券编码',
        prop: 'couponId',
        width: 80
      }, {
        label: '兑换码',
        prop: 'receivedCode',
        width: 60
      }, {
        label: '面额',
        prop: 'denomination',
        width: 60
      }, {
        label: '领取人',
        prop: 'exportUser',
        width: 80
      }, {
        label: '领取人手机号',
        prop: 'receiverPhone',
        width: 80
      }, {
        label: '领取人时间',
        prop: 'receivedTime',
        width: 80
      }, {
        label: '导出人',
        prop: 'exportUser',
        width: 80
      }, {
        label: '导出人手机号',
        prop: 'exportUserPhone',
        width: 80
      }, {
        label: '导出时间',
        prop: 'exportTime',
        width: 80
      }]
    }
  }]
};
var _default = couponItemRouter;
exports.default = _default;