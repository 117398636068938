"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      urlList: _selectUrl.default.urlList,
      appidArr: _selectUrl.default.appIdArr,
      urlTypeList: _selectUrl.default.urlTypeList,
      noSelect: _selectUrl.default.noSelect,
      vlaueData: _selectUrl.default.vlaueData,
      linkUrlData: _selectUrl.default.linkUrlData,
      formData: [{
        type: 'img',
        label: '图片：',
        width: 305,
        height: 175,
        placeholder: '图片大小为710px*350px',
        value: 'pic'
      }, {
        label: '状态：',
        type: 'radio',
        value: 'status',
        selectList: [{
          label: '启用',
          value: 1
        }, {
          label: '停用',
          value: 2
        }]
      }, {
        label: '会员类型：',
        type: 'radio',
        value: 'memberType',
        selectList: [{
          label: '普通会员',
          value: 1
        }, {
          label: '要客会员',
          value: 2
        }, {
          label: '365会员',
          value: 3
        }]
      }, {
        label: '有效期：',
        type: 'timeRange',
        value1: 'startTime',
        value2: 'endTime'
      }, {
        slot: 'contentType'
      }],
      ruleForm: {
        pic: null,
        status: null,
        memberType: [],
        startTime: null,
        endTime: null,
        contentType: null,
        contentLink: null,
        appid: null
      },
      rules: {
        pic: [{
          required: true,
          message: '请上传图片'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        memberType: [{
          required: true,
          message: '请选择会员类型'
        }],
        startTime: [{
          required: true,
          message: '请选择开始时间'
        }],
        endTime: [{
          required: true,
          message: '请选择结束时间'
        }],
        contentType: [{
          required: true,
          message: '请选择关联内容'
        }],
        contentLink: [{
          required: true,
          message: '请输入'
        }],
        appid: [{
          required: true,
          message: '请选择跳转的小程序'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/svip/reservePic/queryReservePicDetail/' + this.ruleForm.id).then(function (res) {
        Object.keys(res.data).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });

        if (_this.urlTypeList.indexOf(res.data.contentType) >= 0) {
          _this.ruleForm.contentLink = res.data.contentLink.split('id=')[1];
        } else {
          _this.ruleForm.contentLink = res.data.contentLink;
        }

        _this.ruleForm.appid = res.data.appid;
      });
    }
  },
  methods: {
    typeChange: function typeChange() {
      this.ruleForm.contentLink = '';
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var data = JSON.parse(JSON.stringify(this.ruleForm));

      if (this.urlTypeList.includes(this.ruleForm.contentType)) {
        data.contentLink = this.linkUrlData[this.ruleForm.contentType].url + data.contentLink;
      } else if (!(this.ruleForm.contentType == 4 || this.ruleForm.contentType == 5)) {
        data.contentLink = this.ruleForm.contentType;
      }

      delete data.createTime;
      this.apiPost(this.ruleForm.id ? '/api/svip/reservePic/updeateReservePic' : '/api/svip/reservePic/createReservePic', data).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: _this2.ruleForm.id ? '编辑成功' : '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;