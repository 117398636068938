var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-service-form" },
    [
      _vm.isDetail
        ? _c("div", { staticClass: "view_title" }, [_vm._v("服务详情")])
        : _c("div", { staticClass: "view_title" }, [
            _vm._v(_vm._s(_vm.id ? "编辑" : "新建") + "服务")
          ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "svip-service-form_type" },
        [
          _c("h4", [_vm._v("服务预约方式：")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              attrs: { disabled: _vm.isDetail },
              model: {
                value: _vm.reserveType,
                callback: function($$v) {
                  _vm.reserveType = $$v
                },
                expression: "reserveType"
              }
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("客户自主预约")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v("联系荔星伙伴")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === 1,
              expression: "type === 1"
            }
          ],
          ref: "ruleForm",
          attrs: {
            "label-width": "180px",
            model: _vm.ruleForm,
            rules: _vm.rules,
            disabled: _vm.isDetail
          }
        },
        [
          _c("Descriptions", { attrs: { title: "基本信息" } }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "服务名称：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  placeholder: "请输入",
                  maxlength: "20",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "服务分类：", prop: "categoryId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    size: "small",
                    placeholder: "请选择",
                    filterable: "",
                    autocomplete: "off"
                  },
                  model: {
                    value: _vm.ruleForm.categoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "categoryId", $$v)
                    },
                    expression: "ruleForm.categoryId"
                  }
                },
                _vm._l(_vm.categoryList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    },
                    [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示到沙龙活动：", prop: "ifShow" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.ifShow,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "ifShow", $$v)
                    },
                    expression: "ruleForm.ifShow"
                  }
                },
                [_vm._v("显示")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.ruleForm.ifShow,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "ifShow", $$v)
                    },
                    expression: "ruleForm.ifShow"
                  }
                },
                [_vm._v("不显示")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "过期后跳转模板页面：", prop: "externalUrl" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.externalUrl,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "externalUrl",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.externalUrl"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("用于活动过期后跳转，不填不跳转")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "institutionCodeList",
              attrs: { prop: "institutionCodeList", label: "服务门店：" }
            },
            [
              _c(
                "div",
                { staticClass: "btn1_top_left" },
                [
                  _c("el-input", {
                    staticClass: "btn1_search",
                    attrs: {
                      placeholder: "输入关键字进行过滤",
                      "suffix-icon": "el-icon-search"
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn1_dea_tree" },
                    [
                      _c("el-tree", {
                        ref: "tree1",
                        staticClass: "filter-tree",
                        staticStyle: { "overflow-x": "auto" },
                        attrs: {
                          "filter-node-method": _vm.filterNode,
                          data: _vm.treeArr,
                          "check-strictly": "",
                          "default-expanded-keys":
                            _vm.ruleForm.institutionCodeList,
                          "default-checked-keys":
                            _vm.ruleForm.institutionCodeList,
                          "auto-expand-parent": "",
                          "show-checkbox": "",
                          "node-key": "code",
                          "check-on-click-node": true,
                          "highlight-current": "",
                          props: _vm.defaultProps
                        },
                        on: { "check-change": _vm.checkChange }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "启动时间：" } },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "startUpTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期时间"
                        },
                        on: { change: _vm.changeStartUpTime },
                        model: {
                          value: _vm.ruleForm.startUpTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "startUpTime", $$v)
                          },
                          expression: "ruleForm.startUpTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "结束时间：" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "endUpTime" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetime",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        placeholder: "选择日期时间",
                        disabled: !_vm.ruleForm.startUpTime,
                        "picker-options": _vm.endUpTimeDisabledRule
                      },
                      model: {
                        value: _vm.ruleForm.endUpTime,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "endUpTime", $$v)
                        },
                        expression: "ruleForm.endUpTime"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "服务时间：" } },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "startTime" } },
                    [
                      _c("el-time-select", {
                        attrs: {
                          placeholder: "开始时间：",
                          "picker-options": {
                            start: "00:00",
                            step: "01:00",
                            end: "23:00"
                          }
                        },
                        model: {
                          value: _vm.ruleForm.startTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "startTime", $$v)
                          },
                          expression: "ruleForm.startTime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { prop: "endTime" }
                    },
                    [
                      _c("el-time-select", {
                        attrs: {
                          placeholder: "结束时间",
                          "picker-options": {
                            start: "01:00",
                            step: "01:00",
                            end: "24:00",
                            minTime: _vm.ruleForm.startTime
                          }
                        },
                        model: {
                          value: _vm.ruleForm.endTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "endTime", $$v)
                          },
                          expression: "ruleForm.endTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "展示图片：", prop: "pic" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: { width: 345, height: 160, cover: _vm.ruleForm.pic },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "pic", "ruleForm")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticStyle: { color: "#999999" } }, [
                _vm._v("建议图片尺寸：690 x 320")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "简介：", prop: "introduction" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  placeholder: "请输入",
                  maxlength: "15",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm.introduction,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "introduction",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.introduction"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "描述：", prop: "description" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                staticStyle: { "line-height": "1" },
                attrs: {
                  autosize: { minRows: 4, maxRows: 6 },
                  type: "textarea",
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.ruleForm.description,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "description",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "detail", label: "详情：" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.ruleForm.detail,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "detail", $$v)
                  },
                  expression: "ruleForm.detail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { label: "销售价格：", prop: "buyType", size: "small" }
            },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { width: "1200px" },
                  attrs: { size: "small" },
                  model: {
                    value: _vm.ruleForm.buyType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "buyType", $$v)
                    },
                    expression: "ruleForm.buyType"
                  }
                },
                _vm._l(_vm.ruleForm.salesTypeList, function(salesType, index) {
                  return _c(
                    "el-row",
                    { key: index, attrs: { gutter: 0 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 2 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: salesType.buyType } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.SvipServiceBuyType[salesType.buyType]
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      salesType.buyType !== 1
                        ? [
                            _c("span", { staticClass: "form_label" }, [
                              _vm._v("原价：")
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "salesTypeList." + index + ".price",
                                      size: "small",
                                      rules: [
                                        {
                                          required:
                                            _vm.ruleForm.buyType ===
                                            salesType.buyType,
                                          message: "请输入原价"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticStyle: { width: "140px" },
                                        attrs: {
                                          type: "number",
                                          disabled:
                                            _vm.ruleForm.buyType !==
                                            salesType.buyType,
                                          size: "small"
                                        },
                                        model: {
                                          value: salesType.price,
                                          callback: function($$v) {
                                            _vm.$set(
                                              salesType,
                                              "price",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "salesType.price"
                                        }
                                      },
                                      [
                                        _c("template", { slot: "append" }, [
                                          _vm._v("元")
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      salesType.buyType !== 1
                        ? [
                            _c("span", { staticClass: "form_label" }, [
                              _vm._v("365价：")
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "salesTypeList." + index + ".price365",
                                      size: "small",
                                      rules: [
                                        {
                                          required:
                                            _vm.ruleForm.buyType ===
                                            salesType.buyType,
                                          message: "请输入365价"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticStyle: { width: "140px" },
                                        attrs: {
                                          type: "number",
                                          disabled:
                                            _vm.ruleForm.buyType !==
                                            salesType.buyType,
                                          size: "small"
                                        },
                                        model: {
                                          value: salesType.price365,
                                          callback: function($$v) {
                                            _vm.$set(
                                              salesType,
                                              "price365",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "salesType.price365"
                                        }
                                      },
                                      [
                                        _c("template", { slot: "append" }, [
                                          _vm._v("元")
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      salesType.buyType !== 1
                        ? [
                            _c("span", { staticClass: "form_label" }, [
                              _vm._v("要客价：")
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "salesTypeList." + index + ".priceSvip",
                                      size: "small",
                                      rules: [
                                        {
                                          required:
                                            _vm.ruleForm.buyType ===
                                            salesType.buyType,
                                          message: "请输入要客价"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticStyle: { width: "140px" },
                                        attrs: {
                                          type: "number",
                                          disabled:
                                            _vm.ruleForm.buyType !==
                                            salesType.buyType,
                                          size: "small"
                                        },
                                        model: {
                                          value: salesType.priceSvip,
                                          callback: function($$v) {
                                            _vm.$set(
                                              salesType,
                                              "priceSvip",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "salesType.priceSvip"
                                        }
                                      },
                                      [
                                        _c("template", { slot: "append" }, [
                                          _vm._v("元")
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      salesType.buyType === 2
                        ? [
                            _c("span", { staticClass: "form_label" }, [
                              _vm._v("积分可抵扣：")
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "salesTypeList." +
                                        index +
                                        ".deductiblePrice",
                                      size: "small"
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        type: "number",
                                        min: 0,
                                        disabled:
                                          _vm.ruleForm.buyType !==
                                          salesType.buyType,
                                        step: 0.01,
                                        precision: 2,
                                        controls: false,
                                        "step-strictly": "",
                                        size: "small"
                                      },
                                      model: {
                                        value: salesType.deductiblePrice,
                                        callback: function($$v) {
                                          _vm.$set(
                                            salesType,
                                            "deductiblePrice",
                                            $$v
                                          )
                                        },
                                        expression: "salesType.deductiblePrice"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.reserveType === 1
            ? [
                _c("el-divider"),
                _vm._v(" "),
                _c("Descriptions", { attrs: { title: "预约设置" } }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "可预约身份：", prop: "memberType" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.memberType,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "memberType", $$v)
                          },
                          expression: "ruleForm.memberType"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("全部会员")
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("要客")
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v("365会员")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: {
                      label: "预约时间范围：",
                      required: "",
                      size: "small"
                    }
                  },
                  [
                    _c("el-row", { attrs: { gutter: 0, type: "flex" } }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "reserveTimeRange",
                                size: "small",
                                rules: _vm.reserveTimeRangeRules
                              }
                            },
                            [
                              _c("span", { staticClass: "form_label" }, [
                                _vm._v("当前时间后")
                              ]),
                              _vm._v(" "),
                              _c("el-input", {
                                staticStyle: { width: "90px" },
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.ruleForm.reserveTimeRange,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "reserveTimeRange",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ruleForm.reserveTimeRange"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "reserveTimeRangeUnit",
                                size: "small"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "form_input",
                                  staticStyle: {
                                    width: "74px",
                                    "margin-left": "4px"
                                  },
                                  attrs: {
                                    size: "small",
                                    filterable: "",
                                    autocomplete: "off"
                                  },
                                  on: {
                                    change: _vm.reserveTimeRangeUnitChange
                                  },
                                  model: {
                                    value: _vm.ruleForm.reserveTimeRangeUnit,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "reserveTimeRangeUnit",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.reserveTimeRangeUnit"
                                  }
                                },
                                [
                                  _c(
                                    "el-option",
                                    { attrs: { label: "天", value: 1 } },
                                    [_vm._v("天")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-option",
                                    { attrs: { label: "月", value: 2 } },
                                    [_vm._v("月")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "gray" } }, [
                      _vm._v(
                        "例：设置10天，客户可选择当前时间后10天内（包含当天）的日期"
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: {
                      label: "提前预约设置：",
                      required: "",
                      prop: "limitAdvanceTime",
                      size: "small"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 0 } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticStyle: { width: "1000px" },
                            attrs: { size: "small" },
                            model: {
                              value: _vm.ruleForm.limitAdvanceTime,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "limitAdvanceTime", $$v)
                              },
                              expression: "ruleForm.limitAdvanceTime"
                            }
                          },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 0 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 2 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("无需提前预约")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              { attrs: { gutter: 0 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 2 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("提前")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 2 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "advanceTime",
                                          size: "small",
                                          "show-message": false,
                                          rules: [
                                            {
                                              required:
                                                _vm.ruleForm
                                                  .limitAdvanceTime === true
                                            }
                                          ]
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            size: "small",
                                            disabled:
                                              _vm.ruleForm.limitAdvanceTime ===
                                              false
                                          },
                                          model: {
                                            value: _vm.ruleForm.advanceTime,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "advanceTime",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "ruleForm.advanceTime"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 2 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "advanceTimeUnit",
                                          size: "small"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "form_input",
                                            staticStyle: { width: "74px" },
                                            attrs: {
                                              size: "small",
                                              filterable: "",
                                              autocomplete: "off",
                                              disabled:
                                                _vm.ruleForm
                                                  .limitAdvanceTime === false
                                            },
                                            model: {
                                              value:
                                                _vm.ruleForm.advanceTimeUnit,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ruleForm,
                                                  "advanceTimeUnit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ruleForm.advanceTimeUnit"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-option",
                                              {
                                                attrs: {
                                                  label: "小时",
                                                  value: 1
                                                }
                                              },
                                              [_vm._v("小时")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-option",
                                              {
                                                attrs: { label: "天", value: 2 }
                                              },
                                              [_vm._v("天")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "gray" } }, [
                      _vm._v(
                        "例：设置提前2天，客户当前预约时只可选择2天后（包含当天）的日期；无限制时可选择当前时间"
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "是否上门：", prop: "d2dServiceType" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.d2dServiceType,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "d2dServiceType", $$v)
                          },
                          expression: "ruleForm.d2dServiceType"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("不可上门")
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("必须上门")
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v("自由选择是否上门")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: {
                      label: "限制累计预约次数：",
                      prop: "restrictionsNum",
                      size: "small"
                    }
                  },
                  [
                    _c("el-input-number", {
                      staticClass: "form_input-number",
                      attrs: {
                        controls: false,
                        size: "small",
                        min: 0,
                        placeholder: "请输入"
                      },
                      model: {
                        value: _vm.ruleForm.restrictionsNum,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "restrictionsNum", _vm._n($$v))
                        },
                        expression: "ruleForm.restrictionsNum"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: {
                      label: "预约人数限制：",
                      required: "",
                      size: "small"
                    }
                  },
                  [
                    _c("el-row", { attrs: { gutter: 0, type: "flex" } }, [
                      _c("span", { staticClass: "form_label" }, [
                        _vm._v("单次限制")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "limitNum", size: "small" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("el-input-number", {
                                    staticClass: "form_input-number",
                                    attrs: {
                                      controls: false,
                                      size: "small",
                                      min: 0,
                                      placeholder: "请输入"
                                    },
                                    model: {
                                      value: _vm.ruleForm.limitNum,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "limitNum",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "ruleForm.limitNum"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "input-number-append" },
                                    [_vm._v("人")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "form_label" }, [
                            _vm._v("，免费携带")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "freeNum", size: "small" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("el-input-number", {
                                    staticClass: "form_input-number",
                                    attrs: {
                                      controls: false,
                                      size: "small",
                                      min: 0,
                                      placeholder: "请输入"
                                    },
                                    model: {
                                      value: _vm.ruleForm.freeNum,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "freeNum",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "ruleForm.freeNum"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "input-number-append" },
                                    [_vm._v("人")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.isDetail
            ? _c(
                "el-form-item",
                [
                  _vm.id
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm", "edit")
                            }
                          }
                        },
                        [_vm._v("\n        保存\n      ")]
                      )
                    : _c(
                        "el-button",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm", "add")
                            }
                          }
                        },
                        [_vm._v("\n        立即新建\n      ")]
                      )
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === 2,
              expression: "type === 2"
            }
          ],
          ref: "ruleForm2",
          attrs: {
            "label-width": "150px",
            model: _vm.ruleForm2,
            rules: _vm.rules2
          }
        },
        [
          _c("Descriptions", { attrs: { title: "基本信息" } }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "服务名称：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  placeholder: "请输入",
                  maxlength: "20",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm2.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm2,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm2.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "institutionCodeList",
              attrs: { prop: "institutionCodeList", label: "服务门店：" }
            },
            [
              _c(
                "div",
                { staticClass: "btn1_top_left" },
                [
                  _c("el-input", {
                    staticClass: "btn1_search",
                    attrs: {
                      placeholder: "输入关键字进行过滤",
                      "suffix-icon": "el-icon-search"
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn1_dea_tree" },
                    [
                      _c("el-tree", {
                        ref: "tree2",
                        staticClass: "filter-tree",
                        staticStyle: { "overflow-x": "auto" },
                        attrs: {
                          "filter-node-method": _vm.filterNode,
                          data: _vm.treeArr,
                          "check-strictly": "",
                          "default-expanded-keys":
                            _vm.ruleForm.institutionCodeList,
                          "default-checked-keys":
                            _vm.ruleForm.institutionCodeList,
                          "auto-expand-parent": "",
                          "show-checkbox": "",
                          "node-key": "code",
                          "check-on-click-node": true,
                          "highlight-current": "",
                          props: _vm.defaultProps
                        },
                        on: { "check-change": _vm.checkChange }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "展示图片：", prop: "pic" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: { width: 345, height: 160, cover: _vm.ruleForm2.pic },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "pic", "ruleForm2")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticStyle: { color: "#999999" } }, [
                _vm._v("建议图片尺寸：690 x 320")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "简介：", prop: "introduction" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  placeholder: "请输入",
                  maxlength: "15",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm2.introduction,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm2,
                      "introduction",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm2.introduction"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isDetail
            ? _c(
                "el-form-item",
                [
                  _vm.id
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm2", "edit")
                            }
                          }
                        },
                        [_vm._v("\n        保存\n      ")]
                      )
                    : _c(
                        "el-button",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm2", "add")
                            }
                          }
                        },
                        [_vm._v("\n        立即新建\n      ")]
                      )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }