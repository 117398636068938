"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '预约管理',
      list: [{
        tit: '预约列表',
        txtArr: '<p>客户的预约订单</p>',
        imgPath: require('@/assets/tab/booking.png'),
        path: '/svip/booking/bookingList'
      }, {
        tit: '售后列表',
        txtArr: '<p>售后处理记录</p>',
        imgPath: require('@/assets/tab/afterSale.png'),
        path: '/svip/booking/afterSaleList'
      }, {
        tit: '场地预约记录',
        txtArr: '<p>客户的预约场地订单</p>',
        imgPath: require('@/assets/tab/booking.png'),
        path: '/svip/booking/placeList'
      }]
    };
  }
};
exports.default = _default;