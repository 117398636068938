var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navigate-select" },
    [
      _c(
        "el-form-item",
        {
          staticClass: "navigate-select_form-item",
          attrs: {
            label: _vm.label,
            "label-width": _vm.label ? "100px" : "0",
            prop: _vm.propPrefix + ".linkCode"
          }
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { clearable: "", filterable: "", placeholder: "链接类型" },
              on: { change: _vm.onChangeLinkCode },
              model: {
                value: _vm.dataSource.linkCode,
                callback: function($$v) {
                  _vm.$set(_vm.dataSource, "linkCode", $$v)
                },
                expression: "dataSource.linkCode"
              }
            },
            _vm._l(_vm.urlList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.dataSource.linkCode == 4 || _vm.dataSource.linkCode == 5
        ? _c(
            "div",
            [
              _vm.dataSource.linkCode == 4
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "navigate-select_form-item",
                      attrs: {
                        "label-width": _vm.label ? "100px" : "0",
                        prop: _vm.propPrefix + ".linkId",
                        rules: [{ required: true, message: "请选择小程序" }]
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "小程序" },
                          model: {
                            value: _vm.dataSource.linkId,
                            callback: function($$v) {
                              _vm.$set(_vm.dataSource, "linkId", $$v)
                            },
                            expression: "dataSource.linkId"
                          }
                        },
                        _vm._l(_vm.appIdArr, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              filterable: "",
                              label: item.label,
                              value: item.value
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": _vm.label ? "100px" : "0",
                    prop: _vm.propPrefix + ".linkPath"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "跳转路径" },
                    model: {
                      value: _vm.dataSource.linkPath,
                      callback: function($$v) {
                        _vm.$set(_vm.dataSource, "linkPath", $$v)
                      },
                      expression: "dataSource.linkPath"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.urlTypeList.indexOf(_vm.dataSource.linkCode) >= 0
        ? _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.label ? "100px" : "0",
                prop: _vm.propPrefix + ".linkId",
                rules: [{ required: true, message: "选择链接编码" }]
              }
            },
            [
              _c(
                "div",
                { staticClass: "flex_between" },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        _vm.dataSource.linkCode == "tem" ||
                        _vm.dataSource.linkCode == "storeTem" ||
                        _vm.dataSource.linkCode == "auto" ||
                        _vm.dataSource.linkCode == "legouGoods"
                          ? "请填写页面"
                          : "选择链接编码"
                    },
                    model: {
                      value: _vm.dataSource.linkId,
                      callback: function($$v) {
                        _vm.$set(_vm.dataSource, "linkId", $$v)
                      },
                      expression: "dataSource.linkId"
                    }
                  }),
                  _vm._v(" "),
                  _vm.noSelect.indexOf(_vm.dataSource.linkCode) < 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.getCoupon()
                            }
                          }
                        },
                        [_vm._v("\n        选择\n      ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("couponPop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: _vm.popType, "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }