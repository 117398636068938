var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            isDown: false,
            pageApi: "/api/mall/goods/brand/list",
            "search-data": _vm.searchData
          }
        },
        [
          _vm.setMenuPerms("legou:brand:create")
            ? _c(
                "el-button",
                {
                  attrs: {
                    slot: "pageBtn",
                    type: "primary",
                    icon: "el-icon-circle-plus-outline",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      _vm.isUpdate = true
                    }
                  },
                  slot: "pageBtn"
                },
                [_vm._v("新增品牌")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "brandLogo",
              label: "logo",
              align: "center",
              width: "100"
            },
            slot: "brandLogo",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "50px" },
                      attrs: {
                        src: scope.row.brandLogo,
                        "preview-src-list": [scope.row.brandLogo]
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "brandRecommend",
              label: "是否推荐",
              align: "center",
              width: "100"
            },
            slot: "brandRecommend",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        disabled: !_vm.setMenuPerms("legou:brand:recommend"),
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                        "active-value": 1,
                        "inactive-value": 0
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeRecommend($event, scope.row)
                        }
                      },
                      model: {
                        value: scope.row.brandRecommend,
                        callback: function($$v) {
                          _vm.$set(scope.row, "brandRecommend", $$v)
                        },
                        expression: "scope.row.brandRecommend"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              align: "center",
              width: "120"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.setMenuPerms("legou:brand:update") &&
                    _vm.setMenuPerms("legou:brand:detail")
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goUpDateItem(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isUpdate
        ? _c(
            "div",
            { staticClass: "fixed_center" },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex_between",
                      staticStyle: { "align-items": "center" },
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", { staticClass: "view_title" }, [
                        _vm._v("新增/编辑品牌")
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-close danger",
                        staticStyle: { "font-size": "20px", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            _vm.isUpdate = false
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("WForm", {
                    attrs: {
                      isSetRules: "",
                      isHttps: "",
                      isTitle: false,
                      "form-data": _vm.formData,
                      "label-width": "120px",
                      "rule-form": _vm.ruleForm,
                      isHttps: ""
                    },
                    on: { submitForm: _vm.submitForm }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }