var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isConfirm,
              expression: "!isConfirm"
            }
          ],
          ref: "item",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.item, rules: _vm.rules, "label-width": "120px" }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 10, offset: 2 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "实体卡类型：", prop: "name" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "small",
                            clearable: "",
                            placeholder: " "
                          },
                          model: {
                            value: _vm.item.cardType,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "cardType", $$v)
                            },
                            expression: "item.cardType"
                          }
                        },
                        _vm._l(_vm.cardOptions, function(cardItem, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: cardItem.text,
                              value: cardItem.value
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 20, offset: 2 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      border: "1px solid #DCDFE6",
                      "border-top": "none",
                      "padding-bottom": "15px",
                      "margin-left": "120px",
                      "margin-top": "-20px"
                    },
                    attrs: { div: "" }
                  },
                  [
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "left" } },
                      [_vm._v("卡类型信息")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "label", attrs: { span: 3 } },
                          [_vm._v("卡介质：")]
                        ),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("span", { staticClass: "text" }, [
                            _vm._v("密码磁条卡")
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { staticClass: "label", attrs: { span: 3 } },
                          [_vm._v("卡面值：")]
                        ),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("span", { staticClass: "text" }, [_vm._v("500")])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "label", attrs: { span: 3 } },
                          [_vm._v("卡号区间：")]
                        ),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("span", { staticClass: "text" }, [
                            _vm._v("500051051 - 4869464654844")
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { staticClass: "label", attrs: { span: 3 } },
                          [_vm._v("已购卡数量：")]
                        ),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("span", { staticClass: "text" }, [
                            _vm._v("500/500051051张")
                          ])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "label", attrs: { span: 3 } },
                          [_vm._v("适用范围：")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 18 } },
                          [
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  data: _vm.tableData,
                                  border: "",
                                  size: "mini"
                                }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { label: "会员体系" }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "机构名称及代码" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20, offset: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "制卡方案名称：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.name,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "name", $$v)
                              },
                              expression: "item.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20, offset: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "卡片名称：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.name,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "name", $$v)
                              },
                              expression: "item.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 16, offset: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "本次制卡数量：", prop: "name" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { size: "small", placeholder: "" },
                              model: {
                                value: _vm.item.number,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "number", $$v)
                                },
                                expression: "item.number"
                              }
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "append" }, slot: "append" },
                                [_vm._v("张")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 4 }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "small" }
                        },
                        [_vm._v("获取号段")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20, offset: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "制卡号段：", prop: "name" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "padding-left": "0",
                                "padding-right": "0"
                              },
                              attrs: { span: 11 }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "small",
                                  placeholder: "开始号段"
                                },
                                model: {
                                  value: _vm.item.minNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "minNumber", _vm._n($$v))
                                  },
                                  expression: "item.minNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "padding-left": "0",
                                "padding-right": "0"
                              },
                              attrs: { span: 2 }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "32px",
                                    "text-align": "center",
                                    padding: "0px 0",
                                    "border-top": "1px solid #DCDFE6",
                                    "border-bottom": "1px solid #DCDFE6",
                                    margin: "2px -2px 0 -2px"
                                  }
                                },
                                [_vm._v("-")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "padding-left": "0",
                                "padding-right": "0"
                              },
                              attrs: { span: 11 }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "small",
                                  placeholder: "结束号段"
                                },
                                model: {
                                  value: _vm.item.maxNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "maxNumber", _vm._n($$v))
                                  },
                                  expression: "item.maxNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20, offset: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生成内容：", prop: "name" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-top": "0px" },
                              attrs: { span: 20 }
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.item.type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "type", $$v)
                                    },
                                    expression: "item.type"
                                  }
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    { attrs: { name: "name" } },
                                    [_vm._v("磁条卡卡号文件（Excel表格）")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-checkbox",
                                    { attrs: { name: "name" } },
                                    [_vm._v("二维码图片文件（JPG图片）")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "实体卡图案：" } },
                            [
                              _c("div", {
                                staticStyle: {
                                  width: "100%",
                                  height: "200px",
                                  border: "1px solid #DCDFE6"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 20, offset: 2 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注信息：" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.item.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "remark", $$v)
                          },
                          expression: "item.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isConfirm,
              expression: "isConfirm"
            }
          ]
        },
        [_c("h1", [_vm._v("详情信息")])]
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.goBack } },
            [_c("i", { staticClass: "el-icon-back" }), _vm._v(" 返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onStep }
            },
            [
              _vm._v(_vm._s(_vm.isConfirm ? "上一步" : "下一步") + " "),
              _c("i", {
                class: [_vm.isConfirm ? "el-icon-top" : "el-icon-bottom"]
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isConfirm,
                  expression: "isConfirm"
                }
              ],
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onSave }
            },
            [
              _c("i", { staticClass: "el-icon-document-checked" }),
              _vm._v(" 保存")
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }