"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

var _api = require("../api.js");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var validateNumber = function validateNumber(rule, value, callback) {
  if (!/^\+?[1-9]\d*$/.test(value)) {
    callback(new Error('请填写大于0的整数'));
  } else {
    callback();
  }
};

var _default = {
  components: {
    addLoad: _addLoad.default,
    coupon: _getCoupon.default,
    wTable: _index.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    var _self = this;

    var valiCeceiveEndTime = function valiCeceiveEndTime(rule, value, callback) {
      if (value <= _self.ruleForm.receiveBeginTime) {
        callback(new Error('需要大于开始时间'));
      } else {
        callback();
      }
    };

    return {
      userLabelList: [],
      pickerBeginTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date().getTime();
          return time.getTime() < validBeginTime;
        }
      },
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.receiveBeginTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/phone.xlsx"),
      columns: [{
        label: '券编码',
        prop: 'uuid',
        width: 100
      }, {
        label: '券名称',
        prop: 'name',
        width: 100
      },
      /* {
        label: '券类型',
        prop: 'couponType',
        width: 100
      }, {
        label: '面额/折扣',
        prop: 'discount',
        width: 100
      }, */
      {
        label: '有效期',
        prop: 'validTime',
        width: 100
      }, {
        slot: 'total'
      }, {
        slot: 'operate'
      }],
      couponShow: false,
      rules: {
        show: [{
          required: true,
          message: '请选择列表显示'
        }],
        mobiles: [{
          required: true,
          message: '请上传手机号'
        }],
        contentLink: [{
          required: true,
          message: '请选择或输入页面编码'
        }],
        tatus: [{
          required: true,
          message: '请选择状态'
        }],
        popupSort: [{
          required: true,
          message: '请输入排序'
        }],
        popupImg: [{
          required: true,
          message: '请上传图片'
        }],
        popupRate: [{
          required: true,
          message: '请选择频率'
        }],
        userLevel: [{
          required: true,
          message: '请选择等级会员'
        }],
        appid: [{
          required: true,
          message: '请选择目标小程序'
        }],
        coupons: [{
          required: true,
          message: '请选优惠券'
        }],
        putTarget: [{
          required: true,
          message: '请选择显示状态'
        }],
        name: [{
          required: true,
          message: '请输入弹框名称'
        }],
        limitNumber: [{
          required: true,
          message: '请输入限领数量'
        }, {
          validator: validateNumber
        }],
        totalNumber: [{
          required: true,
          message: '请输入投放数量'
        }, {
          validator: validateNumber
        }],
        tableImage: [{
          required: true,
          message: '请上传图片'
        }],
        description: [{
          required: true,
          message: '请输入描述'
        }],
        receiveBeginTime: [{
          required: true,
          message: '请选择时间'
        }],
        receiveEndTime: [{
          required: true,
          message: '请选择时间'
        }, {
          validator: valiCeceiveEndTime
        }],
        ruleDescription: [{
          required: true,
          message: '请输入规则'
        }],
        salestypes: [{
          required: true,
          message: '请选择领取门槛'
        }],
        labelContent: [{
          required: true,
          message: '请选择会员标签'
        }]
      },
      ruleForm: {
        labelContent: null,
        show: null,
        salestypes: [],
        payType: [{
          label: '免费',
          type: 4,
          price: null,
          integral: null,
          show: null
        }, {
          label: '金额',
          type: 1,
          price: null,
          integral: null,
          show: null
        }, {
          label: '积分',
          type: 2,
          price: null,
          integral: null,
          show: null
        }, {
          label: '金额+积分',
          type: 3,
          price: null,
          integral: null,
          show: null
        }, {
          label: '周期价格',
          type: 5,
          price: null,
          integral: null,
          show: null
        }],
        name: null,
        coupons: [],
        //  mobiles: [],
        putTarget: [],
        limitNumber: null,
        totalNumber: null,
        tableImage: null,
        description: null,
        receiveBeginTime: null,
        receiveEndTime: null,
        ruleDescription: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
      _this.userLabelList = [{
        id: 0,
        labelName: '所有会员'
      }].concat((0, _toConsumableArray2.default)(res.data));
    });
    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id) {
      (0, _api.getCouponBagById)({
        id: this.ruleForm.id
      }).then(function (res) {
        var data = JSON.parse(JSON.stringify(res.data));
        data.salestypes = [];
        var payType = _this.ruleForm.payType;
        var coupons = [];
        res.data.coupons.forEach(function (item) {
          coupons.push({
            uuid: item.uuid,
            couponId: item.id,
            name: item.name,
            total: item.total,
            validTime: item.validTimeText
          });
        });
        res.data.payType.forEach(function (item) {
          data.salestypes.push(item.payType);

          if (item.show) {
            data.show = item.payType;
          }

          payType.forEach(function (typeItem) {
            if (item.payType === typeItem.type) {
              typeItem.price = item.price;
              typeItem.show = item.show;
              typeItem.integral = item.integral;
            }
          });
        });
        data.payType = payType;
        data.coupons = coupons;
        Object.keys(_this.ruleForm).forEach(function (item) {
          Object.keys(data).forEach(function (key) {
            if (key === item) {
              _this.ruleForm[key] = data[key];
            }
          });
        });
      });
    }
  },
  methods: {
    goDel: function goDel(scope) {
      this.ruleForm.coupons.splice(scope.$index, 1);
    },
    statusChange: function statusChange(e, index) {
      this.ruleForm.payType.forEach(function (item, eIndex) {
        item.show = eIndex === index ? 1 : 0;
      });
      this.ruleForm.show = e;
      console.log(e);
    },
    buTypeChange: function buTypeChange(e) {
      this.ruleForm.show = null;

      if (e.indexOf(4) >= 0) {
        this.ruleForm.show = 4;
        this.ruleForm.payType.forEach(function (item, eIndex) {
          item.show = eIndex === 0 ? 1 : 0;
        });
        this.ruleForm.salestypes = [4];
      }
    },
    downPhone: function downPhone() {
      var _this2 = this;

      var _self = this;

      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['手机号'];
        var filterVal = ['phone'];
        var list = [];

        _this2.ruleForm.mobiles.forEach(function (item) {
          list.push({
            phone: item
          });
        });

        var data = _this2.formatJson(filterVal, list);

        excel.export_json_to_excel({
          header: tHeader,
          data: data,
          filename: _self.ruleForm.name
        });
        _this2.downloadLoading = false;
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      console.log(results);
      results.forEach(function (data) {
        if (data.手机号) {
          arr.push(String(data.手机号));
        }
      });
      this.ruleForm.mobiles = arr;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 2;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过2M的文件',
        type: 'warning'
      });
      return false;
    },
    changeUser: function changeUser(e) {
      if (e.indexOf(1) >= 0) {
        this.ruleForm.putTarget = [1];
        return;
      }
      /* if (e.indexOf(5) >= 0) {
              this.ruleForm.putTarget = [5]
              return
            } */

    },
    setCover: function setCover(e) {
      this.ruleForm.tableImage = e;
      this.submit('tableImage');
    },
    typeChange: function typeChange() {
      this.ruleForm.contentLink = '';
    },
    getLinkId: function getLinkId() {
      this.popType = this.ruleForm.contentType;
      this.$refs.coupon1.getList(this.popType, this.ruleForm.contentLink);
      this.couponShow = true;
    },
    getRelevances: function getRelevances() {
      this.$refs.couponPop.init();
      var arr = [];

      if (this.ruleForm.coupons.length > 0) {
        this.ruleForm.coupons.forEach(function (item) {
          arr.push({
            linkId: item.uuid
          });
        });
      }

      this.$refs.couponPop.assignCoupon(arr);
      this.couponShow = true;
    },
    closeCouponPop: function closeCouponPop() {
      this.couponShow = false;
    },
    setCoupons: function setCoupons(e) {
      var _this3 = this;

      var arr = [];

      if (e.length > 0) {
        e.forEach(function (item) {
          var data;

          var flag = _this3.ruleForm.coupons.some(function (value, index) {
            data = value;
            return value.uuid === item.uuid;
          });

          if (flag) {
            arr.push(data);
          } else {
            var validTime;
            /* if (item.couponType === '折扣券') {
              discount = item.discount / 10 + '折'
            } else {
              discount = item.denomination + '元'
            }*/

            if (item.validTimeType === 1) {
              validTime = item.validBeginTime + '-' + item.validEndTime;
            } else {
              validTime = _this3.getValidDay(item); // '领取后' + item.validDay + '天有效'
            }

            arr.push({
              couponId: item.id,
              uuid: item.uuid,
              name: item.name,
              // couponType: item.couponType,
              // discount: discount,
              total: 1,
              validTime: validTime
            });
          }
        });
      }

      this.ruleForm.coupons = arr;
      this.submit('coupons');
      this.couponShow = false;
    },
    submit: function submit(type) {
      this.$refs['ruleForm'].validateField(type);
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var payType = [];
          var data = JSON.parse(JSON.stringify(_this4.ruleForm));

          _this4.ruleForm.salestypes.forEach(function (item) {
            _this4.ruleForm.payType.forEach(function (row) {
              if (item === row.type) {
                var obj = {
                  payType: item,
                  price: row.price || 0,
                  integral: row.integral || 0,
                  show: row.show
                };
                payType.push(obj);
              }
            });
          });

          data.payType = payType;

          if (_this4.ruleForm.id) {
            (0, _api.editCouponBag)(data).then(function (res) {
              _this4.$message({
                message: '恭喜你，编辑成功',
                type: 'success'
              });

              _this4.$router.go(-1);
            });
          } else {
            (0, _api.addCouponBag)(data).then(function (res) {
              _this4.$message({
                message: '恭喜你，新增成功',
                type: 'success'
              });

              _this4.$router.go(-1);
            });
          }

          return;
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;