"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      gradeType: {
        8888: '银卡会员',
        8889: '金卡会员',
        8890: '砖卡会员',
        8891: '白砖卡会员',
        8892: '黑卡会员'
      },
      downloadLoading: false,
      time: null,
      requestData: {
        name: null,
        startTime: null,
        endTime: null,
        userMobile: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '活动名称',
        prop: 'activityName',
        width: 100
      }, {
        slot: 'userLevel',
        label: '会员类型',
        prop: 'userLevel',
        width: 80
      },
      /* {
        slot: 'time'
      }, */
      {
        label: '用户手机号',
        prop: 'userMobile',
        width: 80
      }, {
        label: '用户昵称',
        prop: 'username',
        width: 80
      }, {
        label: '报名时间',
        prop: 'registrationTime'
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {
    this.getList();
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this.requestData[key]) {
          data[key] = _this.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.orderPage)(data).then(function (res) {
        if (!_this.setMenuPerms('user_mobile_idCard')) {
          res.data.records.forEach(function (item) {
            if (item.userMobile) {
              item.userMobile = item.userMobile.replace(item.userMobile.substring(3, 7), "****");
            }
          });
        }

        list = res.data.records;
        _this.downloadLoading = false;

        _this.getHandleDownload(tHeader, filterVal, list, '报名活动记录');
      }).catch(function (res) {
        _this.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this2 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.orderPage)(this.requestData).then(function (res) {
        if (!_this2.setMenuPerms('user_mobile_idCard')) {
          res.data.records.forEach(function (item) {
            if (item.userMobile) {
              item.userMobile = item.userMobile.replace(item.userMobile.substring(3, 7), "****");
            }
          });
        }

        _this2.tableData = res.data.records;
        _this2.requestData.total = res.data.total;
      });
    }
  }
};
exports.default = _default;