var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          attrs: {
            "form-data": _vm.formData,
            "rule-form": _vm.ruleForm,
            rules: _vm.rules
          },
          on: { submitForm: _vm.submitForm }
        },
        [
          _c(
            "div",
            { attrs: { slot: "voucher" }, slot: "voucher" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "凭证：", prop: "voucher" } },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 200,
                      height: 200,
                      cover: _vm.ruleForm.voucher
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "voucher")
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "cardUuid" }, slot: "cardUuid" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "团购购物卡：", prop: "cardUuid" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.getAward("card")
                        }
                      }
                    },
                    [_vm._v("选择购物卡")]
                  ),
                  _vm._v(
                    "\n        " + _vm._s(_vm.ruleForm.cardUuid) + "\n      "
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "oldGroupCodes" }, slot: "oldGroupCodes" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "返利订单：", prop: "oldGroupCodes" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-plus-outline",
                        size: "small"
                      },
                      on: { click: _vm.addCoupon }
                    },
                    [_vm._v("选择返利订单\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "wwTable",
                    {
                      attrs: {
                        "table-data": _vm.selectOrderList,
                        columns: _vm.tabTh
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          slot: "type",
                          label: "类型",
                          align: "center",
                          width: "100"
                        },
                        slot: "type",
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.type === 1
                                  ? _c("span", { staticClass: "warning" }, [
                                      _vm._v("兑换码")
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.type === 2
                                  ? _c("span", { staticClass: "success" }, [
                                      _vm._v("独立二维码")
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.type === 3
                                  ? _c("span", { staticClass: "danger" }, [
                                      _vm._v("统一二维码")
                                    ])
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("合计总金额：" + _vm._s(_vm.allAmount) + "元")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "proportion" }, slot: "proportion" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "返利比例：", prop: "proportion" } },
                [
                  _c("el-input-number", {
                    attrs: { controls: false, min: 1, max: 100 },
                    model: {
                      value: _vm.ruleForm.proportion,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "proportion", $$v)
                      },
                      expression: "ruleForm.proportion"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "from_remark" }, [_vm._v("%")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("奖励金额："),
                    _c("span", { staticClass: "primary" }, [
                      _vm._v(
                        _vm._s(
                          (
                            (_vm.allAmount * _vm.ruleForm.proportion) /
                            100
                          ).toFixed(2)
                        ) + "元"
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSelectOrder,
              expression: "isSelectOrder"
            }
          ],
          staticClass: "addCoupon_table"
        },
        [
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-row",
                { staticClass: "row_div", attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-input", {
                        staticClass: "input2",
                        attrs: { placeholder: "返利订单号" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.init({ limit: 10, page: 1 })
                          }
                        },
                        model: {
                          value: _vm.obj.groupCode,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "groupCode", $$v)
                          },
                          expression: "obj.groupCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          return _vm.init({ limit: 10, page: 1 })
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    size: "mini",
                    "max-height": "500px",
                    "row-key": "groupCode",
                    data: _vm.tableData,
                    "tooltip-effect": "dark"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      align: "center",
                      "reserve-selection": true
                    }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.tabTh, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: item.label,
                        "min-width": item.width,
                        prop: item.prop,
                        align: "center"
                      }
                    })
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0"
                  }
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.obj.pageNo,
                  limit: _vm.obj.pageSize
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.obj, "pageNo", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.obj, "pageSize", $event)
                  },
                  pagination: _vm.init
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        size: "small",
                        icon: "el-icon-close"
                      },
                      on: {
                        click: function($event) {
                          _vm.isSelectOrder = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        size: "small"
                      },
                      on: { click: _vm.clearCoupon }
                    },
                    [_vm._v("清空")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.affirmAddCoupon }
                    },
                    [_vm._v("保存\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: "card", "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }