var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("新增编辑门店停车场")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            size: "small",
            rules: _vm.rules,
            model: _vm.form,
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "parkingSystem", label: "停车场系统：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.parkingSystem,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "parkingSystem", $$v)
                    },
                    expression: "form.parkingSystem"
                  }
                },
                _vm._l(_vm.parkSystem, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "parkingName", label: "停车场名称：" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                model: {
                  value: _vm.form.parkingName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "parkingName", $$v)
                  },
                  expression: "form.parkingName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "parkingId", label: "停车场ID：" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { disabled: _vm.id > 0 },
                model: {
                  value: _vm.form.parkingId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "parkingId", $$v)
                  },
                  expression: "form.parkingId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "parkingSecret", label: "停车场秘钥：" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { disabled: _vm.id > 0 },
                model: {
                  value: _vm.form.parkingSecret,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "parkingSecret", $$v)
                  },
                  expression: "form.parkingSecret"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "parkingImgs", label: "停车场照片：" } },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("add-load", {
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.form.parkingImgs
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setParkingCover($event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        color: "#666666",
                        "margin-top": "70px",
                        "margin-left": "10px"
                      }
                    },
                    [_vm._v("建议使用清晰美观的停车场入口照片，方便顾客寻找，")]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "state", label: "地址：" } },
                    [
                      _c("el-cascader", {
                        ref: "parkState",
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "small",
                          options: _vm.regionData,
                          clearable: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.regionDataParking($event)
                          }
                        },
                        model: {
                          value: _vm.form.state,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "state", $$v)
                          },
                          expression: "form.state"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "detailAddress", "label-width": "20" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "接到/门牌号" },
                        model: {
                          value: _vm.form.detailAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "detailAddress", $$v)
                          },
                          expression: "form.detailAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "longOrLat", label: "经纬度：" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                model: {
                  value: _vm.form.longOrLat,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "longOrLat", $$v)
                  },
                  expression: "form.longOrLat"
                }
              }),
              _vm._v(" "),
              _c(
                "p",
                { staticStyle: { "font-size": "12px", color: "#666666" } },
                [
                  _vm._v(
                    "请使用英文的逗号隔开，请写停车场出入口经纬度，可方便顾客导航至准确位置"
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "积分兑换停车费" } }, [
            _c(
              "p",
              { staticStyle: { "font-size": "12px", color: "#666666" } },
              [_vm._v("可设置会员使用积分兑换停车费")]
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "basicCost", label: "基础收费：", required: "" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  model: {
                    value: _vm.form.basicCost,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "basicCost", $$v)
                    },
                    expression: "form.basicCost"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("元/小时")])],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "freeTime", label: "免费时长：" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { type: "number" },
                  model: {
                    value: _vm.form.freeTime,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "freeTime", $$v)
                    },
                    expression: "form.freeTime"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("分钟")])],
                2
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticStyle: { "font-size": "12px", color: "#666666" } },
                [
                  _vm._v(
                    " 停车时长不足或支付停车费后在免费时长内出场，则不产生计费 "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { "label-width": "0" } },
            [
              _vm.form.checked1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 14 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "commonMember",
                                    label: "普通会员：",
                                    required: ""
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "form_input",
                                      model: {
                                        value: _vm.form.commonMember,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "commonMember",
                                            $$v
                                          )
                                        },
                                        expression: "form.commonMember"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("积分/小时")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "commonLimit",
                                    "label-width": "0",
                                    required: ""
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "form_input",
                                      model: {
                                        value: _vm.form.commonLimit,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "commonLimit", $$v)
                                        },
                                        expression: "form.commonLimit"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "prepend" }, [
                                        _vm._v("每日限兑")
                                      ]),
                                      _vm._v(" "),
                                      _c("template", { slot: "append" }, [
                                        _vm._v("小时")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 14 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "vipMember",
                                    label: "付费会员：",
                                    required: ""
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "form_input",
                                      model: {
                                        value: _vm.form.vipMember,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "vipMember", $$v)
                                        },
                                        expression: "form.vipMember"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("积分/小时")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "vipLimit",
                                    "label-width": "0",
                                    required: ""
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "form_input",
                                      model: {
                                        value: _vm.form.vipLimit,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "vipLimit", $$v)
                                        },
                                        expression: "form.vipLimit"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "prepend" }, [
                                        _vm._v("每日限兑")
                                      ]),
                                      _vm._v(" "),
                                      _c("template", { slot: "append" }, [
                                        _vm._v("小时")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          false
            ? _c(
                "el-form-item",
                { staticClass: "form_item" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.form.checked2,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "checked2", $$v)
                        },
                        expression: "form.checked2"
                      }
                    },
                    [_vm._v("会员优惠价")]
                  ),
                  _vm._v(" "),
                  _vm.form.vipStatus
                    ? _c(
                        "el-checkbox",
                        {
                          attrs: { border: "" },
                          model: {
                            value: _vm.form.checked3,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "checked3", $$v)
                            },
                            expression: "form.checked3"
                          }
                        },
                        [_vm._v("停车一小时以上才可以享受优惠")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.checked2
                    ? _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form_item",
                                      attrs: {
                                        prop: "commonMember1",
                                        label: "普通会员：",
                                        required: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticClass: "form_input",
                                          model: {
                                            value: _vm.form.commonMember1,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "commonMember1",
                                                $$v
                                              )
                                            },
                                            expression: "form.commonMember1"
                                          }
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("折")
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form_item",
                                      attrs: {
                                        prop: "commonLimit1",
                                        required: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticClass: "form_input",
                                          model: {
                                            value: _vm.form.commonLimit1,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "commonLimit1",
                                                $$v
                                              )
                                            },
                                            expression: "form.commonLimit1"
                                          }
                                        },
                                        [
                                          _c("template", { slot: "prepend" }, [
                                            _vm._v("优惠上限")
                                          ]),
                                          _vm._v(" "),
                                          _c("template", { slot: "append" }, [
                                            _vm._v("元")
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form_item",
                                      attrs: {
                                        prop: "vipMember1",
                                        label: "付费会员：",
                                        required: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticClass: "form_input",
                                          model: {
                                            value: _vm.form.vipMember1,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "vipMember1",
                                                $$v
                                              )
                                            },
                                            expression: "form.vipMember1"
                                          }
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("折")
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form_item",
                                      attrs: { prop: "vipLimit1", required: "" }
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticClass: "form_input",
                                          model: {
                                            value: _vm.form.vipLimit1,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "vipLimit1",
                                                $$v
                                              )
                                            },
                                            expression: "form.vipLimit1"
                                          }
                                        },
                                        [
                                          _c("template", { slot: "prepend" }, [
                                            _vm._v("优惠上限")
                                          ]),
                                          _vm._v(" "),
                                          _c("template", { slot: "append" }, [
                                            _vm._v("元")
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "remark", label: "规则说明：" }
            },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 600, height: 300 },
                model: {
                  value: _vm.form.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }