"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _template = require("@/api/template.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      list: [],
      tabTh: [{
        prop: 'styleCode',
        label: '样式类型编码',
        width: 60
      }, {
        prop: 'styleName',
        label: '样式类型名称',
        width: 100
      }, {
        prop: 'createTime',
        label: '创建时间',
        width: 120
      }],
      total: 0
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this = this;

      this.openLoading();
      (0, _template.listStyleType)({
        styleStatus: null
      }).then(function (res) {
        _this.openLoading().close();

        _this.list = res.data;
      }).catch(function (err) {
        _this.openLoading().close();
      });
    },
    goAdd: function goAdd(url) {
      this.$router.push(url);
      return;

      if (id) {
        this.$router.push('/flowCoupon/rangeAdd?id=' + id);
      } else {
        this.$router.push('/flowCoupon/rangeAdd');
      }
    }
  }
};
exports.default = _default;