"use strict";

var _interopRequireWildcard3 = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.function.name");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _api = require("./api");

var R = _interopRequireWildcard3(require("ramda"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  name: 'Dashboard',
  data: function data() {
    return {
      tableData: [],
      opration: ['上移', '置顶', '编辑'],
      total: 0,
      mobile: '',
      name: '',
      status: '',
      imgSrc: this.$store.state.user.imgSrc,
      obj: {
        pageNo: 1,
        pageSize: 10
      },
      columns: [{
        label: '序号',
        prop: 'id'
      }, {
        label: '头像',
        prop: 'headSculpture'
      }, {
        label: '姓名',
        prop: 'name'
      }, {
        label: '职位',
        prop: 'position'
      }, {
        label: '联系方式',
        prop: 'mobile'
      }, {
        label: '企微二维码',
        prop: 'qrCode'
      }, {
        label: '简介',
        prop: 'remark'
      }, {
        label: '排序',
        prop: 'sort'
      }, {
        label: '状态',
        prop: 'status'
      }]
    };
  },
  created: function created() {
    this.init();
  },
  watch: {
    '$route': function $route() {
      this.init();
    }
  },
  methods: {
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
        this.obj.status = this.status;
        this.obj.mobile = this.mobile;
        this.obj.name = this.name;
      }

      if (type == 'remove') {
        this.obj.status = '';
        this.obj.mobile = '';
        this.obj.name = '';
        this.removeObj(this.obj);
      }

      (0, _api.getGroupBuyList)(this.obj).then(function (res) {
        _this.total = res.data.total;
        _this.tableData = res.data.records;
      });
    },
    addManager: function addManager() {
      this.$router.push({
        path: '/groupBuyManager/setting'
      });
    },
    oprationView: function oprationView(index, item, tableIndex) {
      if (index == 0) {
        //上移
        item.sort = item.sort - 1;
        var preInfo = this.tableData[tableIndex - 1];

        if (item.sort == preInfo.sort) {
          preInfo.sort = preInfo.sort + 1;
          this.changeSort(item);
          this.changeSort(preInfo);
        } else {
          this.changeSort(item);
        }

        return;
      } else if (index == 1) {
        //置顶
        item.sort = 1;
        this.changeSort(item);
        return;
      } else if (index == 2) {
        //编辑
        this.$router.push({
          path: '/groupBuyManager/setting',
          query: {
            id: item.id
          }
        });
      }
    },
    changeSort: function changeSort(item) {
      var that = this;
      (0, _api.addAndEditGroupManage)(item).then(function (res) {
        if (res.success) {
          that.init();
        } else {
          that.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      });
    },
    exportExcel: function exportExcel() {
      var _this2 = this;

      var that = this;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var headers = [];
        var filterVal = [];

        _this2.columns.forEach(function (item) {
          filterVal.push(item.prop);
          headers.push(item.label);
        });

        var data = _this2.formatJson(filterVal, _this2.tableData);

        excel.export_json_to_excel({
          header: headers,
          data: data,
          filename: '团购经理'
        });
      });
    },
    formatJson: function formatJson(filterVal, tableData) {
      return tableData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'status') {
            return v[j] == 1 ? '启用' : '禁用';
          }

          return v[j];
        });
      });
    }
  }
};
exports.default = _default;