var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            "search-data": _vm.searchData
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "contextType",
              label: "信息内容",
              align: "center",
              width: "120"
            },
            slot: "contextType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.contextType == 1
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("短信模板")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.contextType == 2
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("企微消息模板")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.contextType == 3
                      ? _c("span", { staticClass: "primary" }, [
                          _vm._v("彩信模板")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.contextType == 4
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("自定义")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              align: "center",
              width: "120"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/payment/channelsAdd?refNo=" + scope.row.refNo
                            )
                          }
                        }
                      },
                      [_vm._v("编辑\n        ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }