var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        { attrs: { "search-data": _vm.searchData } },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "80"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 1
                      ? _c("span", { staticStyle: { color: "#E6A23C" } }, [
                          _vm._v("待审核")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                          _vm._v("已审核")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              align: "center",
              fixed: "right",
              width: "100"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "flex_between",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _vm.setMenuPerms("taxRate:delete")
                          ? _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认删除该条税率管理？" },
                                on: {
                                  confirm: function($event) {
                                    return _vm.delTag(scope)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      cursor: "pointer"
                                    },
                                    attrs: { slot: "reference" },
                                    slot: "reference"
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.setMenuPerms("taxRate:update")
                          ? _c(
                              "app-link",
                              {
                                staticStyle: { color: "rgb(24, 144, 255)" },
                                attrs: {
                                  to:
                                    "/finance/invoice/taxRateAdd?id=" +
                                    scope.row.id
                                }
                              },
                              [_vm._v("\n            编辑\n          ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }