var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "150px",
            rules: _vm.rules,
            size: "mini",
            model: _vm.form
          }
        },
        [
          _c("div", { staticClass: "view_title flex_between" }, [
            _vm._v("\n      基础设置\n    ")
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "type", attrs: { label: "抽奖类型：", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.disabled },
                  on: { change: _vm.typeChange },
                  model: {
                    value: _vm.form.type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("大转盘")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("九宫格")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("老虎机")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 5 } }, [_vm._v("盲盒")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "name", attrs: { label: "活动名称：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "method", attrs: { label: "抽奖形式：", prop: "method" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.form.method,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "method", $$v)
                    },
                    expression: "form.method"
                  }
                },
                [_vm._v("每日均可抽奖")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.form.method,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "method", $$v)
                    },
                    expression: "form.method"
                  }
                },
                [_vm._v("一次性抽奖")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "time", attrs: { label: "", prop: "time" } },
            [
              _c("span", { staticClass: "form_remark" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.form.method == 1
                        ? "每人每天可抽奖"
                        : "整个活动期间每人可抽奖"
                    )
                )
              ]),
              _vm._v(" "),
              _c("el-input-number", {
                attrs: {
                  controls: false,
                  min: 1,
                  step: 1,
                  "step-strictly": true
                },
                model: {
                  value: _vm.form.time,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "time", $$v)
                  },
                  expression: "form.time"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "form_remark" }, [_vm._v("次")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "activityTime",
              attrs: { label: "活动时间：", prop: "activityTime" }
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "300px" },
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.form.activityTime,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "activityTime", $$v)
                  },
                  expression: "form.activityTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "score", attrs: { label: "消耗积分：", prop: "score" } },
            [
              _c("el-input-number", {
                staticClass: "form_input",
                attrs: { controls: false, min: 0 },
                model: {
                  value: _vm.form.score,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "score", $$v)
                  },
                  expression: "form.score"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "form_remark" }, [_vm._v("分")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "orgCode", attrs: { label: "主办机构：", prop: "orgCode" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: { size: "mini", placeholder: "主办机构" },
                  on: {
                    change: function($event) {
                      return _vm.setCode($event)
                    }
                  },
                  model: {
                    value: _vm.form.orgCode,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "orgCode", $$v)
                    },
                    expression: "form.orgCode"
                  }
                },
                _vm._l(_vm.companylist, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.shortName, value: item.storeCode }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "14px",
                    color: "rgba(0, 0, 0, 0.45)"
                  }
                },
                [_vm._v("即拉新机构；")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.type == 5
            ? _c(
                "el-form-item",
                { ref: "orgCode", attrs: { label: "关联规则：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: { size: "mini", placeholder: "主办机构" },
                      on: {
                        change: function($event) {
                          return _vm.setCode($event)
                        }
                      },
                      model: {
                        value: _vm.form.ruleId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "ruleId", $$v)
                        },
                        expression: "form.ruleId"
                      }
                    },
                    _vm._l(_vm.rulesList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.ruleName, value: item.id }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        color: "rgba(0, 0, 0, 0.45)"
                      }
                    },
                    [_vm._v("\n        关联规则后，满足规则的才能抽奖\n      ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "view_title flex_between" }, [
            _vm._v("\n      页面配置\n    ")
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "popImage",
              attrs: { label: "弹窗图片：", prop: "popImage" }
            },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: { width: 100, height: 100, cover: _vm.form.popImage },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "popImage")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v(
                  "\n        文件最大1M，图片尺寸: 宽750px（高度不限），支持jpg、gif、png、jpeg格式\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.type != 5
            ? _c(
                "el-form-item",
                { ref: "topBanner", attrs: { label: "顶部banner：" } },
                [
                  _c("addLoadList", {
                    staticClass: "img_list",
                    attrs: {
                      width: 100,
                      height: 100,
                      list: _vm.form.topBanner
                    },
                    on: {
                      setCoverMore: function($event) {
                        return _vm.setCoverList($event, "topBanner")
                      },
                      setCover: _vm.setBanner
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v(
                      "\n        文件最大1M，图片尺寸: 宽750px（高度不限），支持jpg、gif、png、jpeg格式\n      "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "backgroundImage",
              attrs: {
                label:
                  _vm.pondDataLabel.backgroundImage[_vm.form.type] ||
                  "抽奖图" + "：",
                prop: "backgroundImage"
              }
            },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: {
                  width: 100,
                  height: 100,
                  cover: _vm.form.backgroundImage
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "backgroundImage")
                  }
                }
              }),
              _vm._v(" "),
              _vm.form.type != 5
                ? _c("p", { staticClass: "form_remark" }, [
                    _vm._v(
                      "\n        文件最大1M，图片尺寸: 宽750px（高度不限），支持jpg、gif、png、jpeg格式；\n      "
                    )
                  ])
                : _c("p", { staticClass: "form_remark" }, [
                    _vm._v("\n        文件最大1M，图片尺寸:180x180；\n      ")
                  ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "buttonImage",
              attrs: { label: "抽奖按钮图：", prop: "buttonImage" }
            },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: {
                  width: _vm.form.type == 5 ? 235 : 100,
                  height: _vm.form.type == 5 ? 50 : 100,
                  cover: _vm.form.buttonImage
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "buttonImage")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v(
                  "\n        抽奖按钮背景图，大转盘190*236；九宫格170*170;老虎机126*137;盲盒：470*100\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex" }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  staticStyle: { width: "800px" },
                  attrs: { column: 5, size: "mini", border: "" }
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "大转盘" } }, [
                    _c("img", {
                      staticClass: "btnCover",
                      attrs: {
                        src:
                          "https://xl-cdn.xinglico.com/mika/dev/2023-08-22/f0e9f02c0588460b853264bb6f2314db.png"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "九宫格" } }, [
                    _c("img", {
                      staticClass: "btnCover",
                      attrs: {
                        src:
                          "https://xl-cdn.xinglico.com/mika/dev/2023-08-23/0daccc16f569410ba04932b769e1135d.png"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "老虎机" } }, [
                    _c("img", {
                      staticClass: "btnCover",
                      attrs: {
                        src:
                          "https://xl-cdn.xinglico.com/mika/dev/2023-08-22/d7da7434e09a4173bd8cd8a40827c0b1.png"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "盲盒" } }, [
                    _c("img", {
                      staticClass: "btnCover",
                      staticStyle: { width: "200px" },
                      attrs: {
                        src:
                          "https://xl-cdn.xinglico.com/mika/dev/2023-10-17/01dfdb3788224155a906e4bd50720189.png"
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "backgroundColour",
              attrs: { label: "页面背景颜色：", prop: "backgroundColour" }
            },
            [
              _c("el-color-picker", {
                model: {
                  value: _vm.form.backgroundColour,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "backgroundColour", $$v)
                  },
                  expression: "form.backgroundColour"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("\n        整个页面的背景颜色\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "buttonFontColour",
              attrs: { label: "抽奖按钮字体色：", prop: "buttonFontColour" }
            },
            [
              _c("el-color-picker", {
                model: {
                  value: _vm.form.buttonFontColour,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "buttonFontColour", $$v)
                  },
                  expression: "form.buttonFontColour"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("\n        抽奖按钮的字体颜色\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "turntableColour",
              attrs: { label: "转盘底色：", prop: "turntableColour" }
            },
            [
              _c("el-color-picker", {
                model: {
                  value: _vm.form.turntableColour,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "turntableColour", $$v)
                  },
                  expression: "form.turntableColour"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("\n        抽奖模块的背景颜色\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "turntableNotSelectedColour",
              attrs: {
                label: "未选中颜色：",
                prop: "turntableNotSelectedColour"
              }
            },
            [
              _c("el-color-picker", {
                model: {
                  value: _vm.form.turntableNotSelectedColour,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "turntableNotSelectedColour", $$v)
                  },
                  expression: "form.turntableNotSelectedColour"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("\n        抽奖礼品默认背景颜色\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "turntableSelectedColour",
              attrs: { label: "选中颜色：", prop: "turntableSelectedColour" }
            },
            [
              _c("el-color-picker", {
                model: {
                  value: _vm.form.turntableSelectedColour,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "turntableSelectedColour", $$v)
                  },
                  expression: "form.turntableSelectedColour"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("\n        抽中礼品的背景颜色\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "instr", attrs: { label: "规则说明：", prop: "instr" } },
            [
              _c("Tinymce", {
                attrs: { width: 400, height: 360 },
                model: {
                  value: _vm.form.instr,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "instr", $$v)
                  },
                  expression: "form.instr"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "form_title" },
            [
              _vm._v("\n      奖励设置\n      "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.addAward }
                },
                [_vm._v("添加奖励")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "14px",
                    color: "rgba(0, 0, 0, 0.45)"
                  }
                },
                [_vm._v("九宫格需添加8项，大转盘需添加6项；")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "drawItemParams",
              attrs: { "label-width": "0", prop: "drawItemParams" }
            },
            [_c("el-divider")],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "'100%'" },
              attrs: { data: _vm.form.drawItemParams, size: "mini", border: "" }
            },
            [
              _c("el-table-column", {
                attrs: { label: "奖励类型", "min-width": 200, align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            ref: "drawItemParams." + scope.$index + ".type",
                            attrs: {
                              "label-width": "0",
                              prop: "drawItemParams." + scope.$index + ".type",
                              required: "",
                              rules: [
                                { required: true, message: "请选择奖励类型" }
                              ]
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  size: "mini",
                                  placeholder: "奖励类型"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.setAward($event, scope.$index)
                                  }
                                },
                                model: {
                                  value: scope.row.type,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "type", $$v)
                                  },
                                  expression: "scope.row.type"
                                }
                              },
                              _vm._l(_vm.type, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "奖励", "min-width": 200, align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            ref: "drawItemParams." + scope.$index + ".name",
                            attrs: {
                              "label-width": "0",
                              prop: "drawItemParams." + scope.$index + ".name",
                              required: "",
                              rules: [{ required: true, message: "请选择奖励" }]
                            }
                          },
                          [
                            scope.row.type == 1 || scope.row.type == 2
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.getAward(
                                          scope.row.type,
                                          scope.$index
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.type == 1
                                          ? "选择优惠券"
                                          : "选择商品"
                                      ) + "\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.type == 4 || scope.row.type == 5
                              ? _c("el-input", {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: scope.row.name,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "name", $$v)
                                    },
                                    expression: "scope.row.name"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.type == 3
                              ? _c("p", [_vm._v("谢谢参与")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.type == 1 || scope.row.type == 2
                              ? _c("p", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.name) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _vm.disabled
                ? _c("el-table-column", {
                    attrs: {
                      label: "投放总数量",
                      "min-width": 150,
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-form-item",
                                {
                                  ref:
                                    "drawItemParams." +
                                    scope.$index +
                                    ".totalNumber",
                                  attrs: {
                                    "label-width": "0",
                                    prop:
                                      "drawItemParams." +
                                      scope.$index +
                                      ".totalNumber",
                                    required: "",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入投放数量"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { size: "mini", type: "number" },
                                    on: {
                                      change: function($event) {
                                        return _vm.numChange(
                                          $event,
                                          scope.$index
                                        )
                                      }
                                    },
                                    model: {
                                      value: scope.row.totalNumber,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "totalNumber", $$v)
                                      },
                                      expression: "scope.row.totalNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1919735820
                    )
                  })
                : _c("el-table-column", {
                    attrs: {
                      label: "投放总数量",
                      "min-width": 150,
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                ref: "drawItemParams." + scope.$index + ".num",
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "drawItemParams." + scope.$index + ".num",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入投放数量"
                                    }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "mini", type: "number" },
                                  on: {
                                    change: function($event) {
                                      return _vm.numChange($event, scope.$index)
                                    }
                                  },
                                  model: {
                                    value: scope.row.num,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "num", $$v)
                                    },
                                    expression: "scope.row.num"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "选项标题", "min-width": 150, align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            ref:
                              "drawItemParams." + scope.$index + ".prizeName",
                            attrs: {
                              "label-width": "0",
                              prop:
                                "drawItemParams." + scope.$index + ".prizeName",
                              required: "",
                              rules: [
                                { required: true, message: "请输入选项标题" }
                              ]
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.prizeName,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "prizeName", $$v)
                                },
                                expression: "scope.row.prizeName"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "选项描述", "min-width": 150, align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            ref:
                              "drawItemParams." +
                              scope.$index +
                              ".prizeDescrition",
                            attrs: {
                              "label-width": "0",
                              prop:
                                "drawItemParams." +
                                scope.$index +
                                ".prizeDescrition",
                              required: "",
                              rules: [
                                { required: true, message: "请输入选项描述" }
                              ]
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.prizeDescrition,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "prizeDescrition", $$v)
                                },
                                expression: "scope.row.prizeDescrition"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "选项图片", "min-width": 150, align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            ref:
                              "drawItemParams." + scope.$index + ".prizeImage",
                            attrs: {
                              "label-width": "0",
                              prop:
                                "drawItemParams." +
                                scope.$index +
                                ".prizeImage",
                              required: "",
                              rules: [
                                { required: true, message: "请选择选项图片" }
                              ]
                            }
                          },
                          [
                            _c("upload-image", {
                              model: {
                                value: scope.row.prizeImage,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "prizeImage", $$v)
                                },
                                expression: "scope.row.prizeImage"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _vm.disabled
                ? _c("el-table-column", {
                    attrs: {
                      label: "已抽奖数量",
                      "min-width": 150,
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.drawOutNumber) +
                                  "\n        "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4164809424
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.disabled
                ? _c("el-table-column", {
                    attrs: {
                      label: "剩余数量",
                      "min-width": 150,
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.num) +
                                  "\n        "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2175409803
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "中奖概率", "min-width": 150, align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            ref: "drawItemParams." + scope.$index + ".winPro",
                            attrs: {
                              "label-width": "0",
                              prop:
                                "drawItemParams." + scope.$index + ".winPro",
                              required: "",
                              rules: [
                                { required: true, message: "请输入中奖概率" }
                              ]
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100px" },
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.winPro,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "winPro", $$v)
                                },
                                expression: "scope.row.winPro"
                              }
                            }),
                            _vm._v("%\n          ")
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "排序", "min-width": 100, align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            ref: "drawItemParams." + scope.$index + ".sort",
                            attrs: {
                              "label-width": "0",
                              prop: "drawItemParams." + scope.$index + ".sort",
                              required: "",
                              rules: [{ required: true, message: "请输入排序" }]
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.sort,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "sort", $$v)
                                },
                                expression: "scope.row.sort"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              !_vm.disabled
                ? _c("el-table-column", {
                    attrs: { label: "操作", "min-width": 100, align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "table_td_btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.delAward(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1579346158
                    )
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "form_title",
              staticStyle: { "margin-top": "20px" }
            },
            [_vm._v("分享设置")]
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "shareTitle",
              attrs: { label: "分享标题：", prop: "shareTitle" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.form.shareTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "shareTitle", $$v)
                  },
                  expression: "form.shareTitle"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "shareImage",
              attrs: { label: "分享图片：", prop: "shareImage" }
            },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: { width: 104, height: 84, cover: _vm.form.shareImage },
                on: { setCover: _vm.setShare }
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "14px",
                    color: "rgba(0, 0, 0, 0.45)"
                  }
                },
                [_vm._v("\n        图片尺寸：520px*416px\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "max-width": "200px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }