"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      list: [1, 2, 3, 4, 5, 6],
      tabTh: [{
        prop: '',
        label: '会员ID',
        width: 100
      }, {
        prop: '',
        label: '会员卡名称',
        width: 100
      }, {
        prop: '',
        label: '会员卡号',
        width: 100
      }, {
        prop: '',
        label: '姓名',
        width: 100
      }, {
        prop: '',
        label: '身份证号',
        width: 100
      }, {
        prop: '',
        label: '手机号',
        width: 100
      }, {
        prop: '',
        label: '性别',
        width: 100
      }],
      success: true,
      popShow: false,
      mid: '',
      rules: {
        name: [{
          required: true,
          message: '请填写会员姓名'
        }],
        sex: [{
          required: true,
          message: '请选择性别'
        }],
        phone: [{
          required: true,
          message: '请填写电话电话'
        }, {
          pattern: /^0{0,1}(1)[0-9]{10}$/,
          message: '手机号码格式不对'
        }],
        idCard: [{
          required: true,
          message: '请填写身份证号码'
        }, {
          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
          message: '身份证号码格式不对'
        }],
        authCode: [{
          required: true,
          message: '请填写验证码'
        }]
      },
      form: {
        sex: '',
        name: '',
        phone: '',
        idCard: '',
        authCode: ''
      }
    };
  },
  methods: {
    goMid: function goMid() {
      var _this = this;

      this.popShow = true;
      this.$nextTick(function () {
        _this.$refs.midInput.focus();
      });
    },
    submitForm: function submitForm(formName) {
      this.$refs[formName].validate(function (valid) {
        if (valid) {} else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
exports.default = _default;