"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _dayjs = _interopRequireDefault(require("dayjs"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    // 后台当前时间
    nowDate: {
      type: String,
      default: ''
    },
    // 订单创建时间
    createTime: {
      type: String,
      default: ''
    },
    // 倒计时时长(单位：分钟)
    minutes: {
      type: Number,
      default: 30
    },
    // 二维码
    payQrcode: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      timer: null,
      count: '',
      // 倒计时
      seconds: 0,
      payQrcodeUrl: ''
    };
  },
  watch: {
    show: function show(newValue) {
      if (newValue) {
        this.getSeconds();
      } else {
        this.clearTimer();
      }
    },
    seconds: function seconds(newValue) {
      if (newValue <= 0) {
        this.clearTimer();
      }
    },
    payQrcode: function payQrcode(newValue) {
      this.payQrcodeUrl = newValue;
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    clearTimer: function clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    // 分 秒 格式化函数
    countDown: function countDown() {
      var m = parseInt(this.seconds / 60 % 60);
      m = m < 10 ? '0' + m : m;
      var s = parseInt(this.seconds % 60);
      s = s < 10 ? '0' + s : s;
      this.count = m + '分' + s + '秒';
    },
    // 定时器每过1秒参数减1
    Time: function Time() {
      var _this = this;

      this.timer = setInterval(function () {
        _this.seconds -= 1;

        _this.countDown();
      }, 1000);
    },
    getSeconds: function getSeconds() {
      if (this.createTime && this.nowDate) {
        this.seconds = this.minutes * 60 - (0, _dayjs.default)(this.nowDate).diff(this.createTime, 'seconds');
      } else {
        this.seconds = this.minutes * 60;
      }

      if (this.seconds >= 0) {
        this.countDown();
        this.Time(); // 调用定时器
      }
    },
    closeDialog: function closeDialog() {
      this.$emit('closePayQrcode');
    }
  }
};
exports.default = _default;