"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ = _interopRequireDefault(require("@/assets/01.jpg"));

var _2 = _interopRequireDefault(require("@/assets/02.jpg"));

var _3 = _interopRequireDefault(require("@/assets/03.jpg"));

var _4 = _interopRequireDefault(require("@/assets/04.jpg"));

var _5 = _interopRequireDefault(require("@/assets/05.jpg"));

var _6 = _interopRequireDefault(require("@/assets/06.jpg"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    isCard: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: '请确认操作'
    }
  },
  data: function data() {
    return {
      msg: '',
      bgimgs: [_.default, _2.default, _3.default,, _4.default, _5.default, _6.default]
    };
  },
  methods: {
    goClose: function goClose() {
      this.$emit('update:isCard', false);
    },
    onSuccess: function onSuccess() {
      var _self = this;

      _self.goClose();

      this.$confirm("".concat(_self.text, ", \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _self.$emit('click');
      }).catch(function () {
        _self.$message({
          type: 'info',
          message: '已取消发放'
        });
      });
    },
    onFail: function onFail() {
      this.$message.error('验证失败');
    },
    onRefresh: function onRefresh() {
      this.msg = '重新生成验证码';
    }
  }
};
exports.default = _default;