var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            pageApi: "/api/scoreGive/listScoreGiveLogPage",
            "search-data": _vm.searchData
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "120"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { class: scope.row.status == 1 ? "success" : "danger" },
                      [_vm._v(_vm._s(scope.row.status == 1 ? "启用" : "停用"))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "returnType",
              label: "积分退还类型",
              align: "center",
              width: "120"
            },
            slot: "returnType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.returnType == 1
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("年度退还")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.returnType == 2
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("活动截止退还")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.returnType == 3
                      ? _c("span", { staticClass: "warning" }, [
                          _vm._v("活动截止后x天退还")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "giveRule",
              label: "赠送规则",
              align: "center",
              width: "100"
            },
            slot: "giveRule",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.giveRule == 1
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("依次递增")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.giveRule == 2
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("倍数递增")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              fixed: "right",
              align: "center",
              width: "120"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.getOrderDea(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("orderDea", {
        ref: "orderDea",
        attrs: { orderData: _vm.orderData, orderList: _vm.orderList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }