"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

/** When your routing table is too long, you can split it into small modules **/
var svipReserveRouter = {
  path: 'reserve',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
    });
  },
  redirect: '/svip/reserve/list',
  name: 'svipReserve',
  meta: {
    title: '领券中心'
  },
  children: [{
    path: 'vipTab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./vipTab.vue"));
      });
    },
    name: 'svipActivityVipTab',
    meta: {
      title: '尊享活动配置'
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./reserve/list.vue"));
      });
    },
    name: 'svipReserveList',
    meta: {
      activeMenu: '/svip/reserve/vipTab',
      title: '轮播图',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      addTxt: "新轮播图",
      addUrl: '/svip/reserve/add',
      api: '/api/svip/reservePic/queryPageReservePic',
      columns: [{
        slot: 'cover'
      }, {
        label: '开始时间',
        prop: 'startTime',
        width: 60
      }, {
        label: '结束时间',
        prop: 'endTime',
        width: 60
      }, {
        slot: 'contentType'
      }, {
        slot: 'status'
      }, {
        label: '操作时间',
        prop: 'createTime',
        width: 60
      }, {
        slot: 'update'
      }]
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./reserve/add.vue"));
      });
    },
    name: 'svipReserveListAdd',
    meta: {
      title: '编辑轮播图',
      activeMenu: '/svip/reserve/vipTab'
    }
  }]
};
var _default = svipReserveRouter;
exports.default = _default;