"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon.js");

var _manage = require("@/api/manage.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      obj: {},
      step: [{
        tit: '新建活动',
        txt: ''
      }, {
        tit: '审核活动',
        txt: ''
      }],
      active: -1,
      btnType: 0,
      status: '',
      imgSrc: this.$store.state.user.imgSrc,
      name: '',
      time: [],
      address: '',
      bannerPath: '',
      content: '',
      institutionNames: '',
      beginTime: '',
      list: [],
      voteAwardJson: [],
      tabTh: [{
        tit: '提交时间',
        prop: 'time'
      }, {
        tit: '会员手机号',
        prop: 'time'
      }, {
        tit: '会员手昵称',
        prop: 'time'
      }]
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    passAudit: function passAudit(index) {
      var _this = this;

      var str = '';

      if (index === 2) {
        str = '此操作将通过审核方案，审核通过后将不可修改， 是否继续?';
      } else if (index === 3) {
        str = '此操作将撤回审核， 是否继续?';
      } else if (index === 4) {
        str = '此操作将撤销审核，撤销通过后将不可修改， 是否继续?';
      } else if (index === 6) {
        str = '此操作将终止方案，终止通过后将作废， 是否继续?';
      }

      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = {
          baseId: _this.baseId,
          auditStatus: index
        };
        (0, _coupon.editVoteAuditStatus)(data).then(function (res) {
          console.log(res);

          _this.$message({
            type: 'success',
            message: '操作成功!'
          });

          _this.setActive(index);
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消审核'
        });
      });
    },
    goEdit: function goEdit() {
      // this.$store.dispatch('editor/setCouponEditor', '')
      this.$router.push('/coupon/coupon3/activity?id=vote&baseId=' + this.baseId);
    },
    setActive: function setActive(index) {
      switch (index) {
        case 1:
          this.status = '待审核';
          this.active = index;
          this.btnType = 1;
          break;

        case 2:
          this.status = '已审核';
          this.active = 2;
          this.btnType = 2;
          break;

        case 3:
          this.status = '审核撤回';
          this.active = 1;
          this.btnType = 1;
          break;

        case 4:
          this.status = '审核撤销';
          this.active = -1;
          this.btnType = 0;
          break;

        case 5:
          this.status = '已过期';
          this.active = 4;
          this.btnType = 0;
          break;

        case 6:
          this.status = '已终止';
          this.active = -1;
          this.btnType = 0;
          break;

        case 8:
          this.status = '已投放';
          this.active = 3;
          this.btnType = 2;
          break;

        case 9:
          this.status = '投放完成';
          this.active = 4;
          this.btnType = 0;
          break;
      }
    },
    init: function init() {
      var _this2 = this;

      this.baseId = this.$route.query.baseId;
      this.openLoading();
      (0, _coupon.getVoteByBaseId)({
        baseId: this.baseId
      }).then(function (res) {
        _this2.openLoading().close();

        _this2.obj = res.data;
        _this2.obj.tableData = [];
        _this2.obj.tableData1 = [];

        _this2.setActive(res.data.auditStatus);

        _this2.voteAwardJson = JSON.parse(res.data.voteAwardJson);
        var putOpr = res.data.oprLogs || [];
        putOpr.forEach(function (item) {
          if (item.oprType === 1) {
            _this2.step[0].txt = item.oprUserName + item.oprTime;
          } else if (item.oprType === 2) {
            _this2.step[1].txt = item.oprUserName + item.oprTime;
          }
        });
        _this2.institutionNames = res.data.activityCode;

        if (res.data.onlineType === 1) {
          _this2.beginTime = res.data.onlineStartTime + '至' + res.data.onlineEndTime;
        } else {
          _this2.beginTime = '立即上线（需手动下线）';
        }

        (0, _manage.listMemberCards)().then(function (res) {
          res.data.forEach(function (item) {
            if (item.cardId === _this2.institutionNames) {
              _this2.institutionNames = item.name;
            }
          });
        });
      }).catch(function (res) {
        _this2.openLoading().close();
      });
    },
    exportExcel: function exportExcel() {
      var _this3 = this;

      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['提交时间', '会员手机号', '会员手昵称'];
        var filterVal = ['time', 'time', 'time'];
        var list = _this3.list;

        var data = _this3.formatJson(filterVal, list);

        excel.export_json_to_excel({
          header: tHeader,
          data: data
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;