"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '商户编码',
        prop: 'merchantCode',
        width: 80
      }, {
        label: '商户名称',
        prop: 'merchantName',
        width: 80
      }, {
        label: '地址',
        prop: 'address',
        width: 80
      }, {
        label: '负责人名称',
        prop: 'headName',
        width: 80
      }, {
        label: '负责人手机号',
        prop: 'headMobile',
        width: 80
      }, {
        label: '备注',
        prop: 'remark',
        width: 80
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status',
        width: 80
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '请输入商户名称',
        value: 'merchantName'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '状态',
        value: 'status',
        select: [{
          value: 1,
          label: '启用'
        }, {
          value: 2,
          label: '禁用'
        }]
      }]
    };
  },
  computed: {
    merchantIds: function merchantIds() {
      return this.$store.state.user.merchantIds;
    },
    pageRequestData: function pageRequestData() {
      if (this.merchantIds) {
        return {
          merchantIds: this.merchantIds
        };
      }

      return;
    }
  },
  methods: {}
};
exports.default = _default;