"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var flowCouponRouter = {
  path: '/flowCoupon',
  component: _layout.default,
  redirect: '/flowCoupon/tab',
  name: 'FlowCoupon',
  meta: {
    title: '流水返券',
    icon: 'nested'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/flowCoupon/tab.vue'));
      });
    },
    name: 'FlowCouponTab',
    meta: {
      title: '流水返券'
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/flowCoupon/list.vue'));
      });
    },
    name: 'FlowCouponList',
    meta: {
      title: '流水返券记录',
      keepAlive: true
    }
  }, {
    path: 'rangeList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/flowCoupon/range/list.vue'));
      });
    },
    name: 'RangeList',
    meta: {
      title: '流水返券活动范围',
      keepAlive: true
    }
  }, {
    path: 'rangeAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/flowCoupon/range/add.vue'));
      });
    },
    name: 'RangeAdd',
    meta: {
      title: '流水返券范围'
    }
  }, {
    path: 'ruleList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/flowCoupon/rule/list.vue'));
      });
    },
    name: 'RuleList',
    meta: {
      title: '流水返券规则',
      keepAlive: true
    }
  }, {
    path: 'ruleAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/flowCoupon/rule/add.vue'));
      });
    },
    name: 'RuleAdd',
    meta: {
      title: '新增/编辑返券规则'
    }
  }, {
    path: 'ruleDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/flowCoupon/rule/dea.vue'));
      });
    },
    name: 'RuleDea',
    meta: {
      title: '返券规则详情'
    }
  }]
};
var _default = flowCouponRouter;
exports.default = _default;