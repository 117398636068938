var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("兑换权益")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-width": "120px",
            size: "small"
          }
        },
        [
          _c("el-form-item", { attrs: { "label-width": "0" } }, [
            _c(
              "div",
              { staticClass: "change-label-calss" },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c("el-form-item", { attrs: { label: "真实姓名：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.realname))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "会员卡号：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.card))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 9 } },
                      [
                        _c("el-form-item", { attrs: { label: "手机号码：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.mobile))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c("el-form-item", { attrs: { label: "优惠券码：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.couponCode))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "优惠券名称：" } },
                          [_vm._v(_vm._s(_vm.userInfo.coupnName))]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 9 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "优惠券有效期：" } },
                          [_vm._v(_vm._s(_vm.userInfo.time))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "兑换备注：", prop: "remark", required: "" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "600px" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 },
                  placeholder: "请输入备注内容"
                },
                model: {
                  value: _vm.form.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "上传凭证：", prop: "attachments", required: "" }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-wrap": "wrap",
                    "max-width": "600px"
                  }
                },
                [
                  _vm._l(_vm.form.attachments, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "cover_item",
                        staticStyle: { "margin-right": "20px" },
                        on: {
                          click: function($event) {
                            return _vm.setIndex(index)
                          }
                        }
                      },
                      [
                        _c("add-load", {
                          staticClass: "img_list",
                          attrs: { width: 100, height: 100, cover: item },
                          on: { setCover: _vm.setCover }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "deleted" }, [
                          _c("i", {
                            staticClass: "el-icon-delete",
                            on: {
                              click: function($event) {
                                return _vm.deleted(index)
                              }
                            }
                          })
                        ])
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.form.attachments.length < 3
                    ? _c(
                        "div",
                        {
                          staticStyle: { "margin-right": "20px" },
                          on: {
                            click: function($event) {
                              return _vm.setIndex(_vm.form.attachments.length)
                            }
                          }
                        },
                        [
                          _c("add-load", {
                            staticClass: "img_list",
                            attrs: { width: 100, height: 100 },
                            on: { setCover: _vm.setCover }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v("最多可添加3张图片，支持jpg、png、jpeg格式的图片")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.goDui }
                },
                [_vm._v("确认兑换")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }