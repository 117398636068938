"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      scrollInrodev: 'tab0',
      tabIndex: 0,
      temList: []
    };
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    dataItem: function dataItem() {
      if (this.data && this.data.moduleAttr) {
        return this.data.moduleAttr.dataItem;
      } else {
        return [];
      }
    }
  },
  methods: {
    changeTab: function changeTab(index) {
      this.tabIndex = index;
      this.scrollInrodev = 'tab' + (index - 1);
    }
  }
};
exports.default = _default2;