"use strict";

var _interopRequireWildcard = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _rights = require("@/api/svip/rights.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _map = require("../../const/map");

var R = _interopRequireWildcard(require("ramda"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      SvipRightsUsedRecordStatusMap: _map.SvipRightsUsedRecordStatus,
      list: [],
      searchParams: {
        pageNo: 1,
        pageSize: 10,
        svipName: '',
        itemName: '',
        itemCategoryName: '',
        rightCategoryName: '',
        date: null,
        mobile: ''
      },
      total: 0
    };
  },
  computed: {
    columns: function columns() {
      return [{
        label: '要客名称',
        prop: 'svipName'
      }, {
        label: '手机号',
        prop: 'mobile'
      }, {
        label: '服务名称',
        prop: 'itemName'
      }, {
        label: '服务分类（权益）',
        prop: 'itemCategoryName'
      }, {
        label: '权益分类',
        prop: 'rightCategoryName'
      }, {
        label: '使用次数',
        prop: 'usedNum'
      }, {
        label: '使用状态',
        prop: 'status',
        formatter: this.formatterStatus
      }];
    },
    svipRightsUsedRecordStatusList: function svipRightsUsedRecordStatusList() {
      var _this = this;

      return Object.keys(this.SvipRightsUsedRecordStatusMap).map(function (s) {
        return {
          label: _this.SvipRightsUsedRecordStatusMap[s],
          value: s
        };
      });
    }
  },
  created: function created() {
    this.fetch();
  },
  methods: {
    formatterStatus: function formatterStatus(row, column) {
      return this.SvipRightsUsedRecordStatusMap[row.status];
    },
    fetch: function fetch(type) {
      var _this2 = this;

      if (type === 'search') {
        this.searchParams.pageNo = 1;
        this.searchParams.pageSize = 10;
      }

      if (type === 'reset') {
        this.removeObj(this.searchParams);
      }

      var date = this.searchParams.date;
      var params = R.omit(['date'], this.searchParams);

      if (date && date.length > 0) {
        params['createTimeStart'] = date[0];
        params['createTimeEnd'] = date[1];
      }

      (0, _rights.getPageRightsUsedRecordList)(params).then(function (res) {
        _this2.total = res.data.totalNum;

        if (!_this2.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.mobile) {
              item.mobile = item.mobile.replace(item.mobile.substring(3, 7), "****");
            }
          });
        }

        _this2.list = res.data.items;
      });
    }
  }
};
exports.default = _default;