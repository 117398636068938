"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _user = require("@/api/user.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _coupon = require("@/api/coupon.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    qrCodePop: _qrCodePop.default,
    Pagination: _Pagination.default,
    AppLink: _Link.default
  },
  data: function data() {
    return {
      popShow: false,
      // 小程序码弹框
      qrCodeShow: true,
      // 列表是否显示获取小程序码
      qrCode: '',
      // 小程序码
      url: '',
      // 小程序链接
      pageNo: 1,
      pageSize: 10,
      total: 0,
      btn: '查看详情',
      operation: 2,
      btnType: 'primary',
      tableData: [],
      tabTh: [{
        tit: '活动名称',
        prop: 'activityName',
        width: 160
      }, // {
      //   tit: "活动编码",
      //   prop: 'uuid',
      //   width: 200,
      //   align:"center"
      // },
      {
        tit: '分类',
        prop: 'classId',
        width: 120
      },
      /* {
            tit: "新增时间",
            prop: 'createTime',
            width: 160,
            sortable: true
          }, */
      {
        tit: '活动有效期',
        prop: 'time',
        width: 260,
        align: 'center'
      }, {
        tit: '活动状态',
        prop: 'statusText',
        width: 70,
        align: 'center'
      }, {
        tit: '审核状态',
        prop: 'auditStatusTxt',
        width: 70,
        align: 'center'
      }, {
        tit: '上线状态',
        prop: 'onlineStatusTxt',
        width: 70,
        align: 'center'
      }, {
        tit: '排序',
        prop: 'activitySort',
        width: 50,
        align: 'center'
      }],
      classFly: [],
      classId: '',
      idCard: '',
      activityName: '',
      options: [{
        value: 0,
        label: '待上线'
      }, {
        value: 1,
        label: '上线中'
      }, {
        value: 2,
        label: '已下线'
      }],
      activityStatus: ''
    };
  },
  created: function created() {
    this.getCLass();
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    goEditTerminationStatus: function goEditTerminationStatus(baseId, index) {
      var _this = this;

      this.$confirm('此操作将终止活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log({
          baseId: baseId
        });

        _this.openLoading();

        (0, _coupon.editTerminationStatus)({
          baseId: baseId
        }).then(function (res) {
          _this.openLoading().close();

          _this.tableData[index].activityStatus = 3;
          _this.tableData[index].statusText = '已终止';

          _this.$message({
            type: 'success',
            message: '终止成功!'
          });
        }).catch(function (res) {
          _this.openLoading().close();
        }); //                   editTerminationStatus({baseId:baseId}).then((res)=>{
        //
        //                     this.$message({
        //                       type: 'success',
        //                       message: '终止成功!'
        //                     })
        //                   })
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消终止'
        });
      });
    },
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getCode: function getCode(scope) {
      var _this2 = this;

      // 获取小程序码
      this.url = 'pages/discover/detail/index/index?activity_id=' + scope.uuid;
      this.openLoading('获取小程序码中');
      (0, _user.xcxQRCode)({
        path: 'pages/discover/detail/index/index',
        scene: 'activity_id=' + scope.uuid
      }).then(function (res) {
        _this2.openLoading().close();

        _this2.qrCode = res.data;
        _this2.popShow = true;
      }).catch(function (res) {
        _this2.openLoading().close();
      });
    },
    goDown: function goDown(scope, index) {
      var _this3 = this;

      // 活动立即下线
      this.$confirm('此操作下线该条活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _coupon.editUnOnlineStatus)({
          baseId: scope.baseId
        }).then(function (res) {
          _this3.tableData[index].onlineStatus = 2;
          _this3.tableData[index].onlineStatusTxt = ' 已下线';
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消下线'
        });
      });
    },
    goAdd: function goAdd() {
      // 去添加
      // this.$store.dispatch('editor/setPromontionEditor', '')
      this.$router.push('/coupon/coupon3/activity?id=vote');
    },
    getList: function getList(page) {
      this.pageNo = page.page;
      this.pageSize = page.limit;
      this.init();
    },
    getSearch: function getSearch() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.init();
    },
    init: function init() {
      var _this4 = this;

      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize //  activityName: this.activityName.trim(),
        //   classId: this.classId,
        //   activityStatus: this.activityStatus

      };
      this.openLoading();
      (0, _coupon.listVotePage)(data).then(function (res) {
        _this4.openLoading().close();

        _this4.tableData = res.data.items;
        _this4.total = res.data.totalNum;

        _this4.tableData.forEach(function (item) {
          switch (item.activityStatus) {
            case 0:
              item.statusText = '待开始';
              break;

            case 1:
              item.statusText = '进行中';
              break;

            case 2:
              item.statusText = '已结束 ';
              break;

            case 3:
              item.statusText = '已终止';
              break;
          }

          switch (item.onlineStatus) {
            case 0:
              item.onlineStatusTxt = '待上线';
              break;

            case 1:
              item.onlineStatusTxt = '上线中 ';
              break;

            case 2:
              item.onlineStatusTxt = ' 已下线';
              break;
          }

          switch (item.auditStatus) {
            case 1:
              item.auditStatusTxt = '待审核';
              break;

            case 2:
              item.auditStatusTxt = '已通过 ';
              break;

            case 3:
              item.auditStatusTxt = '已撤回';
              break;

            case 4:
              item.auditStatusTxt = '已撤销';
              break;

            case 6:
              item.auditStatusTxt = '已终止';
              break;
          }

          item.time = item.activityStartTime + '-' + item.activityEndTime;

          _this4.classFly.forEach(function (classItem) {
            if (item.classId == classItem.classId) {
              item.classId = classItem.name;
            }
          });
        });
      }).catch(function (res) {
        _this4.openLoading().close();
      });
    },
    getCLass: function getCLass() {
      var _this5 = this;

      (0, _coupon.listActivityClass)().then(function (res) {
        _this5.classFly = _this5.classFly.concat(res.data);
      });
    },
    setValue: function setValue() {
      this.onlineStatus = -1;
      this.classId = -1;
      this.name = '';
      this.init();
    }
  }
};
exports.default = _default;