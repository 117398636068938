"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _slicedToArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

var _member = require("@/api/member.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      /* {"stockNumber":"今日会员名额","payAmount":"充值金额"}*/
      stockNumber_: '',
      ruleForm: {
        stockNumber: '',
        payAmount: '',
        time: null
      },
      rules: {
        time: [{
          required: true,
          message: '请选择可购买时间'
        }],
        payAmount: [{
          required: true,
          message: '请输入充值金额'
        }, {
          validator: function validator(rule, value, callback) {
            if (value <= 0) {
              callback(new Error('请输入大于0值'));
            } else {
              callback();
            }
          }
        }],
        stockNumber: [{
          required: true,
          message: '请输入今日会员名额'
        }, {
          validator: function validator(rule, value, callback) {
            if (value <= 0) {
              callback(new Error('请输入大于0值'));
            } else {
              callback();
            }
          }
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _member.getSetting)().then(function (res) {
      _this.ruleForm.time = ["".concat(res.data.startSaleTime), "".concat(res.data.endSaleTime)];
      _this.ruleForm.stockNumber = res.data.stockNumber || 0;
      _this.ruleForm.payAmount = res.data.payAmount || 0;
      _this.stockNumber_ = res.data.stockNumber_ || 0;
    });
  },
  methods: {
    submitForm: function submitForm(form) {
      var _this2 = this;

      this.$refs[form].validate(function (valid, object) {
        if (valid) {
          var _this2$ruleForm$time = (0, _slicedToArray2.default)(_this2.ruleForm.time, 2),
              startSaleTime = _this2$ruleForm$time[0],
              endSaleTime = _this2$ruleForm$time[1];

          console.log(startSaleTime, Number(endSaleTime)); // return

          (0, _member.setting)({
            startSaleTime: Number(startSaleTime),
            endSaleTime: Number(endSaleTime)
          }).then(function (res) {
            _this2.$message({
              message: '编辑成功',
              type: 'success'
            });
          }).catch(function (err) {});
        } else {}
      });
    }
  }
};
exports.default = _default;