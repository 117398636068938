var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "temBox ",
        style: {
          width: _vm.data.moduleAttr.img1Width / 2 + "px",
          height: _vm.data.moduleAttr.img1height / 2 + "px",
          background: "url(" + (_vm.imgSrc + _vm.data.moduleAttr.img) + ")",
          backgroundSize: "100% 100%",
          margin: "0 auto"
        }
      },
      _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
        return _c("div", { key: index, staticClass: "temBox_item" }, [
          _c("img", {
            staticClass: "temBox_item_cover",
            attrs: { src: item.pic_url }
          }),
          _vm._v(" "),
          _c("div", {}, [
            _c("div", { staticClass: "temBox_item_name text_ellipsis" }, [
              _vm._v("\n          " + _vm._s(item.name || "") + "\n        ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "temBox_item_price" }, [
              _vm._v(
                "\n          ￥" +
                  _vm._s(item.original_price || "") +
                  "\n        "
              )
            ])
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }