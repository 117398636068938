var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    理财产品管理\n    "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { "max-width": "100%" },
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: {
                  click: function($event) {
                    return _vm.wPage("/pufa/addManage")
                  }
                }
              },
              [_vm._v("新增理财产品")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "理财产品名称" },
                model: {
                  value: _vm.requestData.productName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "productName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.productName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "mini", clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.requestData.productStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "productStatus", $$v)
                    },
                    expression: "requestData.productStatus"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "开放申请", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "下线", value: 0 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.getList("search")
                }
              }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "mini", icon: "el-icon-refresh" },
              on: {
                click: function($event) {
                  return _vm.getList("remove")
                }
              }
            },
            [_vm._v("重置")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "productImg",
              label: "产品要素图",
              align: "center",
              width: "140"
            },
            slot: "productImg",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.productImg && _vm.imgSrc
                      ? _c("img", {
                          staticStyle: { width: "120px" },
                          attrs: { src: _vm.imgSrc + scope.row.productImg }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "productStatus",
              label: "状态",
              align: "center",
              "min-width": "80"
            },
            slot: "productStatus",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.productStatus === 0
                      ? _c("span", { staticClass: "danger" }, [_vm._v("下线")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.productStatus === 1
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("开放申请")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              align: "center",
              fixed: "right",
              width: "100"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "app-link",
                      {
                        staticStyle: { color: "rgb(24, 144, 255)" },
                        attrs: { to: "/pufa/addManage?id=" + scope.row.id }
                      },
                      [_vm._v("\n          编辑\n        ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }