var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    _vm._l(_vm.list, function(item, index) {
      return _c(
        "div",
        { key: index, staticClass: "list" },
        [
          _c(
            "el-button",
            {
              staticClass: "left_btn",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.goList(index)
                }
              }
            },
            [_vm._v(_vm._s(item.tit))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _c("p", [_vm._v(_vm._s(item.text))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right_footer" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.goList(index)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.btn1))]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.goAddput(item.url)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.btn2))]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }