"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addStopParkInfo = addStopParkInfo;
exports.addStopParkNum = addStopParkNum;
exports.listStopParkInfo = listStopParkInfo;
exports.parkOrderPage = parkOrderPage;
exports.queryUser = queryUser;
exports.saveStopParkInfo = saveStopParkInfo;
exports.updateStopParkInfo = updateStopParkInfo;

var _request = _interopRequireDefault(require("@/utils/request"));

// 停车场管理
function listStopParkInfo(data) {
  var loading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'loading';
  return (0, _request.default)({
    url: '/api/stopPark/listStopParkInfo',
    method: 'post',
    data: data,
    loading: loading
  });
}

function saveStopParkInfo(data) {
  if (data.id) {
    return updateStopParkInfo(data);
  }

  return addStopParkInfo(data);
}

function addStopParkInfo(data) {
  var loading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'loading';
  return (0, _request.default)({
    url: '/api/stopPark/addStopParkInfo',
    method: 'post',
    data: data,
    loading: loading
  });
}

function updateStopParkInfo(data) {
  var loading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'loading';
  return (0, _request.default)({
    url: '/api/stopPark/updateStopParkInfo',
    method: 'post',
    data: data,
    loading: loading
  });
} // 停车订单


function parkOrderPage(data) {
  return (0, _request.default)({
    url: '/api/stopPark/quertStopParkOrderPage',
    method: 'post',
    data: data,
    loading: 'loading'
  });
} // 根据会员码查询会员信息


function queryUser(code) {
  return (0, _request.default)({
    url: '/api/stopPark/queryUserByMemberCode/' + code,
    method: 'get',
    loading: 'loading'
  });
} // 发放次数


function addStopParkNum(data) {
  return (0, _request.default)({
    url: '/api/stopPark/addStopParkNum',
    method: 'post',
    data: data,
    loading: 'loading'
  });
}