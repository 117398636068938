var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          ref: "wForm",
          attrs: {
            "form-data": _vm.formData,
            "rule-form": _vm.ruleForm,
            rules: _vm.rules
          },
          on: { submitForm: _vm.submitForm }
        },
        [
          _c(
            "div",
            { attrs: { slot: "categoryId" }, slot: "categoryId" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类目", prop: "categoryId" } },
                [
                  _c("el-cascader", {
                    ref: "paterId",
                    staticClass: "form_input",
                    attrs: {
                      options: _vm.categoryList,
                      props: {
                        checkStrictly: true,
                        label: "categoryName",
                        value: "id"
                      },
                      clearable: ""
                    },
                    model: {
                      value: _vm.ruleForm.categoryId,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "categoryId", $$v)
                      },
                      expression: "ruleForm.categoryId"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.type == 1
            ? _c(
                "div",
                { attrs: { slot: "ruleGroupList" }, slot: "ruleGroupList" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规则组：", prop: "ruleGroupList" } },
                    [
                      _vm._l(_vm.ruleForm.ruleGroupList, function(item, index) {
                        return _c(
                          "el-card",
                          {
                            key: index,
                            staticStyle: {
                              width: "800px",
                              "margin-bottom": "10px"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "类型：",
                                  "label-width": "60",
                                  prop: "ruleGroupList[" + index + "].context",
                                  rules: _vm.rules.context
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "ruleGroupList[" + index + "].andOr",
                                      rules: _vm.rules.andOr
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.andOr,
                                          callback: function($$v) {
                                            _vm.$set(item, "andOr", $$v)
                                          },
                                          expression: "item.andOr"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("且")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 2 } },
                                          [_vm._v("或")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm._l(item.context, function(row, rowIndex) {
                                  return _c("el-form-item", { key: rowIndex }, [
                                    _c("div", { staticClass: "flex_between" }, [
                                      _c(
                                        "div",
                                        { staticClass: "flex" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticStyle: { width: "200px" },
                                              attrs: {
                                                rules: _vm.rules.type,
                                                prop:
                                                  "ruleGroupList[" +
                                                  index +
                                                  "].context[" +
                                                  rowIndex +
                                                  "].type"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  on: {
                                                    change: function($event) {
                                                      row.condition = null
                                                    }
                                                  },
                                                  model: {
                                                    value: row.type,
                                                    callback: function($$v) {
                                                      _vm.$set(row, "type", $$v)
                                                    },
                                                    expression: "row.type"
                                                  }
                                                },
                                                _vm._l(_vm.typeList, function(
                                                  type,
                                                  typeIndex
                                                ) {
                                                  return _c("el-option", {
                                                    key: typeIndex,
                                                    attrs: {
                                                      label: type.label,
                                                      value: type.value
                                                    }
                                                  })
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              staticStyle: { width: "200px" },
                                              attrs: {
                                                rules: _vm.rules.ifEqual,
                                                prop:
                                                  "ruleGroupList[" +
                                                  index +
                                                  "].context[" +
                                                  rowIndex +
                                                  "].ifEqual"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio-group",
                                                {
                                                  on: {
                                                    change: function($event) {
                                                      row.condition = null
                                                    }
                                                  },
                                                  model: {
                                                    value: row.ifEqual,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "ifEqual",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.ifEqual"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 1 } },
                                                    [_vm._v("等于")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 2 } },
                                                    [_vm._v("不等于")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          row.type
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    rules: _vm.rules.condition,
                                                    prop:
                                                      "ruleGroupList[" +
                                                      index +
                                                      "].context[" +
                                                      rowIndex +
                                                      "].condition"
                                                  }
                                                },
                                                [
                                                  row.type === 1
                                                    ? _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "300px"
                                                          },
                                                          model: {
                                                            value:
                                                              row.condition,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row,
                                                                "condition",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row.condition"
                                                          }
                                                        },
                                                        _vm._l(
                                                          _vm.svipList,
                                                          function(
                                                            svip,
                                                            svipIndex
                                                          ) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: svipIndex,
                                                                attrs: {
                                                                  label:
                                                                    svip.name,
                                                                  value: svip.id
                                                                }
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.type === 2
                                                    ? _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "300px"
                                                          },
                                                          model: {
                                                            value:
                                                              row.condition,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row,
                                                                "condition",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row.condition"
                                                          }
                                                        },
                                                        _vm._l(
                                                          _vm.vipList,
                                                          function(
                                                            vip,
                                                            vipIndex
                                                          ) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: vipIndex,
                                                                attrs: {
                                                                  label:
                                                                    "" +
                                                                    vip.payMemberName +
                                                                    (vip.payMemberNameShort
                                                                      ? "(" +
                                                                        vip.payMemberNameShort +
                                                                        ")"
                                                                      : ""),
                                                                  value: vip.id
                                                                }
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.type === 5
                                                    ? _c(
                                                        "el-radio-group",
                                                        {
                                                          model: {
                                                            value:
                                                              row.condition,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row,
                                                                "condition",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row.condition"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  row.ifEqual ==
                                                                  2,
                                                                label: "0"
                                                              }
                                                            },
                                                            [_vm._v("全部")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              attrs: {
                                                                label: "1"
                                                              }
                                                            },
                                                            [_vm._v("男")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              attrs: {
                                                                label: "2"
                                                              }
                                                            },
                                                            [_vm._v("女")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.type === 3
                                                    ? _c("el-date-picker", {
                                                        staticStyle: {
                                                          width: "300px"
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          type: "daterange",
                                                          "range-separator":
                                                            "——",
                                                          format:
                                                            "yyyy 年 MM 月 dd 日",
                                                          "value-format":
                                                            "yyyy-MM-dd",
                                                          "start-placeholder":
                                                            "开始日期",
                                                          "end-placeholder":
                                                            "结束日期"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.changeDateTime(
                                                              $event,
                                                              index,
                                                              rowIndex
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value: row.conditions,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "conditions",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.conditions"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.type === 4
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          _c(
                                                            "el-input-number",
                                                            {
                                                              staticStyle: {
                                                                width: "80px"
                                                              },
                                                              attrs: {
                                                                precision: 0,
                                                                min: 0,
                                                                max:
                                                                  row.condition2 >
                                                                  0
                                                                    ? row.condition2 -
                                                                      1
                                                                    : 99,
                                                                controls: false
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeAge(
                                                                    index,
                                                                    rowIndex
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  row.condition1,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    row,
                                                                    "condition1",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "row.condition1"
                                                              }
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                      岁\n                      "
                                                          ),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "form_remark",
                                                              staticStyle: {
                                                                margin: "0 6px"
                                                              }
                                                            },
                                                            [_vm._v("至")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-input-number",
                                                            {
                                                              staticStyle: {
                                                                width: "80px"
                                                              },
                                                              attrs: {
                                                                precision: 0,
                                                                min:
                                                                  row.condition1 +
                                                                  1,
                                                                controls: false
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeAge(
                                                                    index,
                                                                    rowIndex
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  row.condition2,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    row,
                                                                    "condition2",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "row.condition2"
                                                              }
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                      岁\n                    "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-popconfirm",
                                            {
                                              attrs: {
                                                title: "确认删除该规则？"
                                              },
                                              on: {
                                                confirm: function($event) {
                                                  return _vm.delGropItem(
                                                    index,
                                                    rowIndex
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("el-button", {
                                                staticClass: "danger",
                                                attrs: {
                                                  slot: "reference",
                                                  icon: "el-icon-delete",
                                                  type: "text",
                                                  size: "mini"
                                                },
                                                slot: "reference"
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex_between" }, [
                              _c("div", [
                                _vm._v(
                                  "共" + _vm._s(item.context.length) + "个规则"
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: { title: "确认删除该规则组？" },
                                      on: {
                                        confirm: function($event) {
                                          return _vm.delRuleGroup(index)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "danger",
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                            size: "mini"
                                          },
                                          slot: "reference"
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addRuleGroupItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                添加规则\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c("el-card", { style: { width: "800px" } }, [
                        _c(
                          "div",
                          { staticClass: "flex_between" },
                          [
                            _c("div", [
                              _vm._v(
                                "\n              共" +
                                  _vm._s(_vm.ruleForm.ruleGroupList.length) +
                                  "组, 共" +
                                  _vm._s(_vm.groupItemLength) +
                                  "个规则\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.addRuleGroup()
                                  }
                                }
                              },
                              [_vm._v("\n              添加组\n            ")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.type == 2
            ? _c(
                "div",
                { attrs: { slot: "mobiles" }, slot: "mobiles" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "mobiles" } },
                    [
                      _c("div", { staticStyle: { "max-width": "800px" } }, [
                        _c("div", { staticClass: "flex_between" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "input-with-select",
                                  staticStyle: { "max-width": "250px" },
                                  attrs: {
                                    size: "mini",
                                    placeholder: "添加手机号"
                                  },
                                  model: {
                                    value: _vm.addMobile,
                                    callback: function($$v) {
                                      _vm.addMobile = $$v
                                    },
                                    expression: "addMobile"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        background: "#409eff !important",
                                        color: "#ffffff"
                                      },
                                      attrs: {
                                        slot: "append",
                                        size: "mini",
                                        type: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.goAddMobile()
                                        }
                                      },
                                      slot: "append"
                                    },
                                    [_vm._v("添加单个手机")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                              staticStyle: { "margin-top": "6px" }
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.downloadUrl,
                                    download: "上传手机号码模板.xlsx"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "success",
                                        icon: "el-icon-download"
                                      }
                                    },
                                    [_vm._v("下载模板")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.ruleForm.mobiles.length > 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 20px" },
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        icon: "el-icon-download"
                                      },
                                      on: { click: _vm.handleDownload }
                                    },
                                    [_vm._v("导出手机号")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("upload-excel-component", {
                                staticStyle: { "margin-left": "20px" },
                                attrs: {
                                  size: "mini",
                                  "btn-text": "批量上传",
                                  type: "btn",
                                  "on-success": _vm.handleSuccess,
                                  "before-upload": _vm.beforeUpload
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "max-height": "600px",
                              overflow: "auto",
                              padding: "10px",
                              border: "1px solid #cccccc",
                              "margin-top": "10px"
                            }
                          },
                          _vm._l(_vm.ruleForm.mobiles, function(item, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.delMobile(item, index)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item) + ",")]
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }