"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var couponActivityRouter = {
  path: '/couponActivity',
  component: _layout.default,
  redirect: '/couponActivity/tab',
  name: 'couponActivity',
  meta: {
    title: '优惠活动'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/tab.vue'));
      });
    },
    name: 'couponActivityTab',
    meta: {
      title: '优惠活动'
    }
  }, {
    path: 'addTab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponC/tab.vue'));
      });
    },
    name: 'couponActivityAddTab',
    meta: {
      title: '优惠活动',
      activeMenu: '/couponActivity/couponC'
    }
  }, {
    path: 'couponC',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponC/list.vue'));
      });
    },
    name: 'couponC',
    meta: {
      title: '活动券',
      keepAlive: true
    }
  }, {
    path: 'couponCAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponC/add.vue'));
      });
    },
    name: 'couponActivitycouponCAdd',
    meta: {
      title: '编辑活动券',
      activeMenu: '/couponActivity/couponC'
    }
  }, {
    path: 'couponCDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponC/dea.vue'));
      });
    },
    name: 'couponCDea',
    meta: {
      title: '活动券详情',
      activeMenu: '/couponActivity/couponC'
    }
  }, {
    path: 'couponCGoods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponC/goods.vue'));
      });
    },
    name: 'couponCGoods',
    meta: {
      title: '编辑活动券',
      activeMenu: '/couponActivity/couponC'
    }
  }, {
    path: 'couponCOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponC/order.vue'));
      });
    },
    name: 'couponCOrder',
    meta: {
      title: '活动券订单',
      keepAlive: true,
      activeMenu: '/couponActivity/couponC'
    }
  }, {
    path: 'couponCOrderDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponC/orderDea.vue'));
      });
    },
    name: 'couponCOrderDea',
    meta: {
      title: '订单商品详情',
      activeMenu: '/couponActivity/couponC'
    }
  }, {
    path: 'couponB',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponB/list.vue'));
      });
    },
    name: 'couponB',
    meta: {
      title: '活动券B',
      keepAlive: true,
      activeMenu: '/couponActivity/couponC'
    }
  }, {
    path: 'couponBAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponB/add.vue'));
      });
    },
    name: 'couponCAdd',
    meta: {
      title: '编辑活动券B',
      activeMenu: '/couponActivity/couponC'
    }
  }, {
    path: 'couponBDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponB/dea.vue'));
      });
    },
    name: 'couponBDea',
    meta: {
      title: '活动券B详情',
      activeMenu: '/couponActivity/couponC'
    }
  }, {
    path: 'couponBGoods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponB/goods.vue'));
      });
    },
    name: 'couponBGoods',
    meta: {
      title: '编辑活动券B',
      activeMenu: '/couponActivity/couponC'
    }
  }, {
    path: 'couponBOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponB/order.vue'));
      });
    },
    name: 'couponBOrder',
    meta: {
      title: '活动券B订单',
      keepAlive: true,
      activeMenu: '/couponActivity/couponC'
    }
  }, {
    path: 'couponBOrderDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/activity/couponB/orderDea.vue'));
      });
    },
    name: 'couponBOrderDea',
    meta: {
      title: '订单商品详情',
      activeMenu: '/couponActivity/couponC'
    }
  }]
};
var _default = couponActivityRouter;
exports.default = _default;