"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    couponUUid: {
      type: String,
      default: '优惠券编码'
    },
    qrCode: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    closePop: function closePop() {
      this.$emit('closePop');
    }
  }
};
exports.default = _default;