var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("礼品导入")]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "add_btn", staticStyle: { padding: "10px 0" } },
          [
            _c(
              "a",
              { attrs: { href: _vm.downloadUrl, download: "礼品模板.xlsx" } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "upLoad",
                    attrs: { icon: "el-icon-download" }
                  },
                  [_vm._v("下载模板")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("upload-excel-component", {
              attrs: {
                "btn-text": "批量上传",
                type: "btn",
                "on-success": _vm.handleSuccess,
                "before-upload": _vm.beforeUpload
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              border: "",
              data: _vm.list,
              "row-class-name": _vm.tableRowClassName,
              size: "mini",
              "max-height": "500"
            }
          },
          [
            _c("el-table-column", {
              attrs: { label: "序号", align: "center", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " + _vm._s(scope.$index + 1) + "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "礼品类型", align: "center", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.giftType == 1
                        ? _c("span", [_vm._v("生日礼")])
                        : scope.row.giftType == 2
                        ? _c("span", [_vm._v("升级礼")])
                        : scope.row.giftType == 3
                        ? _c("span", [_vm._v("积兑卡")])
                        : scope.row.giftType == 4
                        ? _c("span", [_vm._v("其他")])
                        : scope.row.giftType == 5
                        ? _c("span", [_vm._v("积分礼品")])
                        : scope.row.giftType == 6
                        ? _c("span", [_vm._v("幸运礼")])
                        : scope.row.giftType == 7
                        ? _c("span", [_vm._v("2020年积分兑换")])
                        : _c("span", [_vm._v(_vm._s(scope.row.giftType))])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _vm._l(_vm.tabTh, function(item, index) {
              return _c("el-table-column", {
                key: index,
                attrs: {
                  prop: item.prop,
                  label: item.label,
                  align: item.align ? item.align : "center",
                  "min-width": item.width
                }
              })
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "状态", align: "center", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.giftStatus == 1
                        ? _c("span", [_vm._v("启用")])
                        : scope.row.giftStatus == 2
                        ? _c("span", [_vm._v("禁用")])
                        : _c("span", [_vm._v(_vm._s(scope.row.giftStatus))])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", align: "center", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "table_td_btn",
                          on: {
                            click: function($event) {
                              return _vm.deleteItem(scope.$index)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticStyle: { margin: "20px 0", width: "200px", display: "block" },
            attrs: { type: "primary" },
            on: { click: _vm.next }
          },
          [_vm._v("确认导入")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }