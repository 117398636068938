var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("新增/编辑首页弹窗")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型：", prop: "popuType" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.ruleForm.popuType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "popuType", $$v)
                        },
                        expression: "ruleForm.popuType"
                      }
                    },
                    [_vm._v("首页弹窗")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      model: {
                        value: _vm.ruleForm.popuType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "popuType", $$v)
                        },
                        expression: "ruleForm.popuType"
                      }
                    },
                    [_vm._v("悬浮ICON弹窗")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.popuType == 2
                ? _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "startTime",
                          attrs: { label: "弹窗周期：", prop: "startTime" }
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              placeholder: "选择开始时间"
                            },
                            model: {
                              value: _vm.ruleForm.startTime,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "startTime", $$v)
                              },
                              expression: "ruleForm.startTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "endTime",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { "label-width": "0", prop: "endTime" }
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              disabled: !_vm.ruleForm.startTime,
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "default-time": "23:59:59",
                              placeholder: "选择结束时间"
                            },
                            model: {
                              value: _vm.ruleForm.endTime,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "endTime", $$v)
                              },
                              expression: "ruleForm.endTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "弹窗名称：", prop: "popupName" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.popupName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "popupName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.popupName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "labelContent",
                  attrs: { prop: "labelContent", label: "标签选择：" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "输入标签名称搜索~",
                        "value-key": "id",
                        clearable: ""
                      },
                      model: {
                        value: _vm.ruleForm.labelContent,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "labelContent", $$v)
                        },
                        expression: "ruleForm.labelContent"
                      }
                    },
                    _vm._l(_vm.userLabelList, function(so) {
                      return _c("el-option", {
                        key: so.id,
                        attrs: { label: "" + so.labelName, value: so.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.popupScopes.indexOf(2) >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "等级会员：", prop: "userLevel" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "8888" },
                          model: {
                            value: _vm.ruleForm.userLevel,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "userLevel", $$v)
                            },
                            expression: "ruleForm.userLevel"
                          }
                        },
                        [_vm._v("银卡")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "8889" },
                          model: {
                            value: _vm.ruleForm.userLevel,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "userLevel", $$v)
                            },
                            expression: "ruleForm.userLevel"
                          }
                        },
                        [_vm._v("金卡")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "8890" },
                          model: {
                            value: _vm.ruleForm.userLevel,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "userLevel", $$v)
                            },
                            expression: "ruleForm.userLevel"
                          }
                        },
                        [_vm._v("钻卡")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "8891" },
                          model: {
                            value: _vm.ruleForm.userLevel,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "userLevel", $$v)
                            },
                            expression: "ruleForm.userLevel"
                          }
                        },
                        [_vm._v("白钻卡")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "8892" },
                          model: {
                            value: _vm.ruleForm.userLevel,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "userLevel", $$v)
                            },
                            expression: "ruleForm.userLevel"
                          }
                        },
                        [_vm._v("黑卡")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.popupScopes.indexOf(5) >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "手机号码：", prop: "mobiles" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("upload-excel-component", {
                            attrs: {
                              "btn-text": "上传手机号",
                              type: "btn",
                              "on-success": _vm.handleSuccess,
                              "before-upload": _vm.beforeUpload
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.downloadUrl,
                                download: "手机号导入.xlsx"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "upLoad",
                                  attrs: { icon: "el-icon-download" }
                                },
                                [_vm._v("下载模板")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.ruleForm.mobiles.length > 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "10px",
                                "margin-top": "10px",
                                border: "1px solid #CCCCCC",
                                "max-height": "300px",
                                overflow: "auto"
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.ruleForm.mobiles.join("、")) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ruleForm.mobiles.length > 0 && this.ruleForm.id
                        ? _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                width: "200px"
                              },
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-download"
                              },
                              on: { click: _vm.downPhone }
                            },
                            [_vm._v("导出手机号")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态：", prop: "popupStatus" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.popupStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "popupStatus", $$v)
                        },
                        expression: "ruleForm.popupStatus"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.ruleForm.popupStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "popupStatus", $$v)
                        },
                        expression: "ruleForm.popupStatus"
                      }
                    },
                    [_vm._v("禁用")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "弹窗排序：", prop: "popupSort" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.popupSort,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "popupSort",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.popupSort"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("排序越大越靠前")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "弹窗图片：", prop: "popupImg" } },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      "feil-width": 540,
                      "feil-height": 600,
                      width: 270,
                      height: 300,
                      cover: _vm.ruleForm.popupImg
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "popupImg")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticStyle: { color: "#999999" } }, [
                    _vm._v("弹窗尺寸必须为540x600像素")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.popuType == 2
                ? _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "未开始图片：",
                            prop: "unProgressImage"
                          }
                        },
                        [
                          _c("add-load", {
                            staticClass: "img_list",
                            attrs: {
                              "feil-width": 260,
                              "feil-height": 160,
                              width: 130,
                              height: 80,
                              cover: _vm.ruleForm.unProgressImage
                            },
                            on: {
                              setCover: function($event) {
                                return _vm.setCover($event, "unProgressImage")
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticStyle: { color: "#999999" } }, [
                            _vm._v("弹窗尺寸必须为260x160像素")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "进行中图片：",
                            prop: "progressImage"
                          }
                        },
                        [
                          _c("add-load", {
                            staticClass: "img_list",
                            attrs: {
                              "feil-width": 260,
                              "feil-height": 160,
                              width: 130,
                              height: 80,
                              cover: _vm.ruleForm.progressImage
                            },
                            on: {
                              setCover: function($event) {
                                return _vm.setCover($event, "progressImage")
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticStyle: { color: "#999999" } }, [
                            _vm._v("弹窗尺寸必须为260x160像素")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "弹出频率：", prop: "popupRate" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.popupRate,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "popupRate", $$v)
                        },
                        expression: "ruleForm.popupRate"
                      }
                    },
                    [_vm._v("仅一次")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.ruleForm.popupRate,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "popupRate", $$v)
                        },
                        expression: "ruleForm.popupRate"
                      }
                    },
                    [_vm._v("每日一次")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 3 },
                      model: {
                        value: _vm.ruleForm.popupRate,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "popupRate", $$v)
                        },
                        expression: "ruleForm.popupRate"
                      }
                    },
                    [_vm._v("每次进入")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "关联内容：", prop: "contentType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "关联内容类型"
                      },
                      on: { change: _vm.typeChange },
                      model: {
                        value: _vm.ruleForm.contentType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "contentType", $$v)
                        },
                        expression: "ruleForm.contentType"
                      }
                    },
                    _vm._l(_vm.urlList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.contentType == 4
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "目标小程序：", prop: "appid" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              attrs: { placeholder: "小程序" },
                              model: {
                                value: _vm.ruleForm.appid,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "appid", $$v)
                                },
                                expression: "ruleForm.appid"
                              }
                            },
                            _vm._l(_vm.appidArr, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "小程序路径：",
                            prop: "contentLink",
                            rules: [
                              { required: true, message: "请输入小程序路径" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_input",
                            attrs: { type: "text" },
                            model: {
                              value: _vm.ruleForm.contentLink,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "contentLink", $$v)
                              },
                              expression: "ruleForm.contentLink"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.contentType == 5
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "WEB页面：",
                        prop: "contentLink",
                        rules: [
                          { required: true, message: "请输入WEB页面地址" }
                        ]
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { type: "text" },
                        model: {
                          value: _vm.ruleForm.contentLink,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "contentLink", $$v)
                          },
                          expression: "ruleForm.contentLink"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.urlTypeList.indexOf(_vm.ruleForm.contentType) >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "链接编码：", prop: "contentLink" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { type: "text" },
                        model: {
                          value: _vm.ruleForm.contentLink,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "contentLink", $$v)
                          },
                          expression: "ruleForm.contentLink"
                        }
                      }),
                      _vm._v(" "),
                      _vm.noSelect.indexOf(_vm.ruleForm.contentType) < 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.getLinkId()
                                }
                              }
                            },
                            [_vm._v("\n          选择")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("couponPop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: _vm.popType, "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }