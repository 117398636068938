var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editScoreSet" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("查询/编辑积分设置")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            size: "small",
            model: _vm.form,
            "label-width": "160px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "sellAmount", label: "消费金额：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: {
                  type: "number",
                  placeholder: "只能输入大于等于0的数",
                  size: "small"
                },
                model: {
                  value: _vm.form.sellAmount,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sellAmount", _vm._n($$v))
                  },
                  expression: "form.sellAmount"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "getScore", label: "获得积分：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: {
                  type: "number",
                  placeholder: "只能输入大于等于0的数",
                  size: "small"
                },
                model: {
                  value: _vm.form.getScore,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "getScore", _vm._n($$v))
                  },
                  expression: "form.getScore"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "companyId", label: "总部：", required: "" }
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%", "max-width": "300px" },
                  attrs: {
                    filterable: "",
                    size: "small",
                    "collapse-tags": "",
                    placeholder: "请选择"
                  },
                  on: { change: _vm.setPayment },
                  model: {
                    value: _vm.form.companyId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyId", $$v)
                    },
                    expression: "form.companyId"
                  }
                },
                _vm._l(_vm.companylist, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.companyCode,
                      attrs: { label: item.shortName, value: item.companyCode }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(item.shortName) +
                          " " +
                          _vm._s(item.companyCode) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "不积积分门店：" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%", "max-width": "300px" },
                  attrs: {
                    filterable: "",
                    size: "small",
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "请选择",
                    "no-data-text": "请先选择门店"
                  },
                  model: {
                    value: _vm.form.unstoreParams,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "unstoreParams", $$v)
                    },
                    expression: "form.unstoreParams"
                  }
                },
                _vm._l(_vm.storeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.storeId,
                      attrs: {
                        label: item.shortName + "（" + item.storeCode + "）",
                        value: item.storeCode
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: {
                prop: "unpayMode",
                label: "不积积分支付方式：",
                required: ""
              }
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%", "max-width": "300px" },
                  attrs: {
                    filterable: "",
                    size: "small",
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.form.unpayMode,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "unpayMode", $$v)
                    },
                    expression: "form.unpayMode"
                  }
                },
                _vm._l(_vm.payArr, function(item) {
                  return _c("el-option", {
                    key: item.c_type,
                    attrs: { label: item.c_type, value: item.c_type }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: {
            width: "100%",
            "max-width": "200px",
            "margin-left": "160px"
          },
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.onSubmit("form")
            }
          }
        },
        [_vm._v(_vm._s(_vm.btn == "add" ? "保存" : "编辑"))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }