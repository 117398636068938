var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("权益详情")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "权益包名称：", prop: "interestsName" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.interestsName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "interestsName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.interestsName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "显示到会员：", prop: "vipCode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "显示到会员"
                      },
                      model: {
                        value: _vm.ruleForm.vipCode,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "vipCode", $$v)
                        },
                        expression: "ruleForm.vipCode"
                      }
                    },
                    _vm._l(_vm.vipList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.vipName, value: item.vipCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "跳转路径：", prop: "pageType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "跳转路径" },
                      on: { change: _vm.urlChange },
                      model: {
                        value: _vm.ruleForm.pageType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "pageType", $$v)
                        },
                        expression: "ruleForm.pageType"
                      }
                    },
                    _vm._l(_vm.urlList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.pageType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "小程序", prop: "pageAppid" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form_input",
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "小程序" },
                          model: {
                            value: _vm.ruleForm.pageAppid,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "pageAppid", $$v)
                            },
                            expression: "ruleForm.pageAppid"
                          }
                        },
                        _vm._l(_vm.appIdArr, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.placeholderText, prop: "pageCode" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { placeholder: _vm.placeholderText },
                    model: {
                      value: _vm.ruleForm.pageCode,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "pageCode", $$v)
                      },
                      expression: "ruleForm.pageCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "权益包排序：", prop: "interestsSort" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.interestsSort,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "interestsSort",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.interestsSort"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "权益包图片：", prop: "interestsIcon" } },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.interestsIcon
                    },
                    on: { setCover: _vm.setCover }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态：", prop: "interestsStatus" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.interestsStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "interestsStatus", $$v)
                        },
                        expression: "ruleForm.interestsStatus"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.ruleForm.interestsStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "interestsStatus", $$v)
                        },
                        expression: "ruleForm.interestsStatus"
                      }
                    },
                    [_vm._v("禁用")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否推荐首页：", prop: "pushIndex" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.pushIndex,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "pushIndex", $$v)
                        },
                        expression: "ruleForm.pushIndex"
                      }
                    },
                    [_vm._v("是")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.ruleForm.pushIndex,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "pushIndex", $$v)
                        },
                        expression: "ruleForm.pushIndex"
                      }
                    },
                    [_vm._v("否")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注：", prop: "interestsRemark" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { width: 600, height: 300 },
                    model: {
                      value: _vm.ruleForm.interestsRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "interestsRemark", $$v)
                      },
                      expression: "ruleForm.interestsRemark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "券信息：", prop: "couponCodes" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.getRelevances }
                    },
                    [_vm._v("选择")]
                  ),
                  _vm._v(" "),
                  _c("w-table", {
                    attrs: {
                      "table-data": _vm.ruleForm.couponCodes,
                      height: "500",
                      columns: _vm.columns
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("coupon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "couponPop",
        on: { setCoupons: _vm.setCoupons, closeCouponPop: _vm.closeCouponPop }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }