"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      formData: [{
        label: '支付编码：',
        type: 'input',
        placeholder: '请输入支付支付编码',
        value: 'chanelTypeNo'
      }, {
        label: '支付通道名称：',
        type: 'input',
        placeholder: '请输入支付通道名称',
        value: 'chanelName'
      }, {
        slot: 'templateColumnList'
      }],
      ruleForm: {
        chanelTypeNo: null,
        templateColumnList: []
      },
      rules: {
        chanelTypeNo: [{
          required: true,
          message: '请输入支付编码'
        }],
        templateColumnList: [{
          required: true,
          message: '请添加模板字段'
        }],
        chanelName: [{
          required: true,
          message: '请输入通道名称'
        }],
        column1: [{
          required: true,
          message: '请输入字段'
        }],
        columDesc: [{
          required: true,
          message: '请输入字段描述'
        }],
        columDefault: [{
          required: true,
          message: '请输入字段默认值'
        }]
      }
    };
  },
  components: {},
  computed: {},

  /* created() {
     this.ruleForm.id = Number(this.$route.query.id)
     if (this.ruleForm.id > 0) {
       this.apiGet('/api/third/party/merchant/getThirdPartyMerchantById/' + this.ruleForm.id).then((res) => {
        delete res.data.createTime
        this.ruleForm = res.data
       })
     }
   }, */
  methods: {
    delGrade: function delGrade(row, index) {
      this.ruleForm.templateColumnList.splice(index, 1);
    },

    /*
    添加阶段
    */
    goAddGrade: function goAddGrade() {
      this.ruleForm.templateColumnList.push({
        column1: null,
        columDesc: null,
        columDefault: null
      });
    },
    submitForm: function submitForm(formName) {
      var _this = this;

      this.apiPost('/api/pcc/tpt/saveBatch', this.ruleForm).then(function (res) {
        _this.$router.go(-1);

        _this.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;