"use strict";

var _interopRequireWildcard = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpdateReserveTime = UpdateReserveTime;
exports.addEquipment = addEquipment;
exports.auditMember = auditMember;
exports.batchAddCategoryGoods = batchAddCategoryGoods;
exports.batchCreateRights = batchCreateRights;
exports.batchDelCategoryGoods = batchDelCategoryGoods;
exports.batchImportSvip = batchImportSvip;
exports.bindMember = bindMember;
exports.cancelActivity = cancelActivity;
exports.cancelAfterSale = cancelAfterSale;
exports.cancelPaidSubscribe = cancelPaidSubscribe;
exports.cancelSubscribe = cancelSubscribe;
exports.cancelVerificationRecord = cancelVerificationRecord;
exports.createActivity = createActivity;
exports.createActivityCalendar = createActivityCalendar;
exports.createCategory = createCategory;
exports.createManager = createManager;
exports.createMember = createMember;
exports.createPlace = createPlace;
exports.createRight = createRight;
exports.createService = createService;
exports.creteServiceCategory = creteServiceCategory;
exports.deleteServiceCategory = deleteServiceCategory;
exports.editEquipment = editEquipment;
exports.getActivity = getActivity;
exports.getActivityCalendar = getActivityCalendar;
exports.getActivityCalendarList = getActivityCalendarList;
exports.getActivityInfo = getActivityInfo;
exports.getActivityList = getActivityList;
exports.getAfterSaleDetail = getAfterSaleDetail;
exports.getEquipmentList = getEquipmentList;
exports.getEvaluationDetail = getEvaluationDetail;
exports.getHomeSetting = getHomeSetting;
exports.getInterestsList = getInterestsList;
exports.getIntroSetting = getIntroSetting;
exports.getManager = getManager;
exports.getManagerByPhone = getManagerByPhone;
exports.getManagerList = getManagerList;
exports.getMember = getMember;
exports.getMemberAudit = getMemberAudit;
exports.getMemberList = getMemberList;
exports.getModalSetting = getModalSetting;
exports.getMySetting = getMySetting;
exports.getPageActivityCalendarList = getPageActivityCalendarList;
exports.getPageActivityList = getPageActivityList;
exports.getPageAfterSaleList = getPageAfterSaleList;
exports.getPageCategoryGoodsList = getPageCategoryGoodsList;
exports.getPageCategoryList = getPageCategoryList;
exports.getPageEquipmentList = getPageEquipmentList;
exports.getPageInterestsList = getPageInterestsList;
exports.getPageManagerEvaluationList = getPageManagerEvaluationList;
exports.getPageManagerList = getPageManagerList;
exports.getPageManagerRecordList = getPageManagerRecordList;
exports.getPageMemberList = getPageMemberList;
exports.getPageMemberReviewList = getPageMemberReviewList;
exports.getPagePlaceList = getPagePlaceList;
exports.getPageServiceCategoryList = getPageServiceCategoryList;
exports.getPageServiceList = getPageServiceList;
exports.getPageSubscribeList = getPageSubscribeList;
exports.getPlace = getPlace;
exports.getPlaceList = getPlaceList;
exports.getPlaceReserveInfo = getPlaceReserveInfo;
exports.getRightDetail = getRightDetail;
exports.getRightsList = getRightsList;
exports.getService = getService;
exports.getServiceCategoryList = getServiceCategoryList;
exports.getServiceInfo = getServiceInfo;
exports.getServiceList = getServiceList;
exports.getSetting = getSetting;
exports.getSubscribe = getSubscribe;
exports.getSvipShopTree = getSvipShopTree;
exports.inviteMember = inviteMember;
exports.levelList = levelList;
exports.listMainUserPage = listMainUserPage;
exports.moveCategory = moveCategory;
exports.moveCategoryGoods = moveCategoryGoods;
exports.moveServiceCategory = moveServiceCategory;
exports.placeReserveList = placeReserveList;
exports.putRight = putRight;
exports.recoverMember = recoverMember;
exports.rejectMember = rejectMember;
exports.reserve = reserve;
exports.saveHomeSetting = saveHomeSetting;
exports.saveIntroSetting = saveIntroSetting;
exports.saveModalSetting = saveModalSetting;
exports.saveMySetting = saveMySetting;
exports.saveSetting = saveSetting;
exports.switchActivity = switchActivity;
exports.switchActivityCalendar = switchActivityCalendar;
exports.switchService = switchService;
exports.updateActivity = updateActivity;
exports.updateActivityCalendar = updateActivityCalendar;
exports.updateCategory = updateCategory;
exports.updateEvaluation = updateEvaluation;
exports.updateManager = updateManager;
exports.updateMember = updateMember;
exports.updatePlace = updatePlace;
exports.updatePlaceShelve = updatePlaceShelve;
exports.updateService = updateService;
exports.updateServiceCategory = updateServiceCategory;
exports.updateShelve = updateShelve;
exports.verificationRecord = verificationRecord;

var _request = _interopRequireDefault(require("@/utils/request"));

var R = _interopRequireWildcard(require("ramda"));

// 荔星荟配置 start

/**
 * 获取荔星荟设置
 */
function getSetting() {
  return (0, _request.default)({
    url: '/api/svip/setting',
    method: 'get',
    loading: 'loading'
  });
}
/**
 * 查询主页设置
 */


function getHomeSetting() {
  return (0, _request.default)({
    url: '/api/svip/setting/index',
    method: 'get',
    loading: 'loading'
  });
}
/**
 * 保存主页设置
 */


function saveHomeSetting(data) {
  return (0, _request.default)({
    url: '/api/svip/setting/index',
    method: 'post',
    data: data
  });
}
/**
 * 查询介绍页设置
 */


function getIntroSetting() {
  return (0, _request.default)({
    url: '/api/svip/setting/introduce',
    method: 'get',
    loading: 'loading'
  });
}
/**
 * 保存介绍页设置
 */


function saveIntroSetting(data) {
  return (0, _request.default)({
    url: '/api/svip/setting/introduce',
    method: 'post',
    data: data
  });
}
/**
 * 查询弹窗设置
 */


function getModalSetting() {
  return (0, _request.default)({
    url: '/api/svip/setting/popup',
    method: 'get',
    loading: 'loading'
  });
}
/**
 * 保存弹窗设置
 */


function saveModalSetting(data) {
  return (0, _request.default)({
    url: '/api/svip/setting/popup',
    method: 'post',
    data: data
  });
}
/**
 * 查询我的页设置
 */


function getMySetting() {
  return (0, _request.default)({
    url: '/api/svip/setting/mine',
    method: 'get',
    loading: 'loading'
  });
}
/**
 * 保存我的页设置
 */


function saveMySetting(data) {
  return (0, _request.default)({
    url: '/api/svip/setting/mine',
    method: 'post',
    data: data
  });
} // 荔星荟配置 end
// 要客权益 start

/**
 * 权益列表查询
 */


function getInterestsList() {
  return (0, _request.default)({
    url: '/api/svip/right',
    method: 'get'
  });
}
/**
 * 权益列表查询（含分页）
 */


function getPageInterestsList(params) {
  return (0, _request.default)({
    url: '/api/svip/right/page',
    method: 'get',
    params: params
  });
}
/**
 * 保存荔星荟设置
 */


function saveSetting(data) {
  return (0, _request.default)({
    url: '/api/svip/setting',
    method: 'post',
    data: data
  });
}
/**
 * 创建权益
 */


function createRight(params) {
  return (0, _request.default)({
    url: '/api/svip/right',
    method: 'post',
    data: params
  });
}
/**
 * 更新权益
 */


function putRight(params) {
  return (0, _request.default)({
    url: '/api/svip/right/' + params.id,
    method: 'put',
    data: params
  });
}
/**
 * 权益详情
 */


function getRightDetail(params) {
  return (0, _request.default)({
    url: '/api/svip/right/' + params.id,
    method: 'get',
    params: params
  });
}
/**
 * 权益发放规则-列表查询（含分页）
 */


function getRightsList(params) {
  var loading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'loading';
  return (0, _request.default)({
    url: '/api/svip/right/rule/list',
    method: 'get',
    params: params,
    loading: loading
  });
}
/**
 * 权益发放规则-批量创建
 */


function batchCreateRights(params) {
  return (0, _request.default)({
    url: '/api/svip/right/rule/batch/create',
    method: 'post',
    data: params
  });
} // 要客权益 end
// 要客管理 start

/**
 * 要客列表（分页）
 */


function getPageMemberList(params) {
  var loading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'loading';
  return (0, _request.default)({
    url: '/api/svip/page',
    method: 'get',
    params: params,
    loading: loading
  });
}
/**
 * 要客列表
 */


function getMemberList(params) {
  return (0, _request.default)({
    url: '/api/svip',
    method: 'get',
    params: params
  });
}
/**
 * 要客详情
 */


function getMember(params, data) {
  console.log("2333333333333");
  console.log(data);
  return (0, _request.default)({
    url: "/api/svip/" + params.id,
    method: 'get',
    isErrMsg: data ? data.isErrMsg : false
  });
}
/**
 * 创建要客
 */


function createMember(data) {
  return (0, _request.default)({
    url: '/api/svip',
    method: 'post',
    data: data
  });
}
/**
 * 更新要客
 */


function updateMember(data) {
  return (0, _request.default)({
    url: '/api/svip/' + data.id,
    method: 'post',
    data: R.omit(['id'], data)
  });
}
/**
 * 更新要客
 */


function bindMember(data) {
  return (0, _request.default)({
    url: '/api/svip/manager/' + data.mobile + '/bind',
    method: 'post',
    data: R.omit(['mobile'], data)
  });
}
/**
 * 邀请要客
 */


function inviteMember(data) {
  return (0, _request.default)({
    url: '/api/svip/invite',
    method: 'put',
    data: data
  });
}
/**
 * 剔除要客
 */


function rejectMember(data) {
  return (0, _request.default)({
    url: '/api/svip/weed',
    method: 'put',
    data: data
  });
}
/**
 * 恢复要客
 */


function recoverMember(data) {
  return (0, _request.default)({
    url: '/api/svip/recover',
    method: 'put',
    data: data
  });
} // 要客管理 end
// 要客审核管理 start

/**
 * 要客审核列表（分页）
 */


function getPageMemberReviewList(params) {
  return (0, _request.default)({
    url: '/api/svip/audit/page',
    method: 'get',
    params: params
  });
}
/**
 * 查询审核详情
 */


function getMemberAudit(params) {
  return (0, _request.default)({
    url: '/api/svip/audit/' + params.id,
    method: 'get'
  });
}
/**
 * 查核要客
 */


function auditMember(data) {
  return (0, _request.default)({
    url: '/api/svip/audit',
    method: 'put',
    data: data
  });
} // 要客审核管理 end
// 荔星伙伴管理 start

/**
 * 查询荔星伙伴
 */


function getManager(params) {
  return (0, _request.default)({
    url: '/api/svip/manager/' + params.id,
    method: 'get'
  });
}
/**
 * 荔星伙伴列表
 */


function getManagerList(params) {
  return (0, _request.default)({
    url: '/api/svip/manager',
    method: 'get',
    params: params
  });
}
/**
 * 荔星伙伴列表（分页）
 */


function getPageManagerList(params) {
  return (0, _request.default)({
    url: '/api/svip/manager/page',
    method: 'get',
    params: params
  });
}
/**
 * 荔星伙伴评价列表查询（分页）
 */


function getPageManagerEvaluationList(params) {
  return (0, _request.default)({
    url: '/api/svip/manager/evaluate/page',
    method: 'get',
    params: params
  });
}
/**
 * 荔星伙伴变更记录查询（分页）
 */


function getPageManagerRecordList(params) {
  return (0, _request.default)({
    url: '/api/svip/manager/change/log/page',
    method: 'get',
    params: params
  });
}
/**
 * 创建荔星伙伴
 */


function createManager(data) {
  return (0, _request.default)({
    url: '/api/svip/manager',
    method: 'post',
    data: data
  });
}
/**
 * 编辑荔星伙伴
 */


function updateManager(data) {
  return (0, _request.default)({
    url: '/api/svip/manager/' + data.id,
    method: 'put',
    data: R.omit(['id'], data)
  });
}
/**
 * 根据企业微信手机号查询对应荔星伙伴信息
 */


function getManagerByPhone(params) {
  return (0, _request.default)({
    url: "/api/svip/manager/".concat(params.mobile, "/wx/enterprise"),
    method: 'get'
  });
}
/**
 * 评价详情
 */


function getEvaluationDetail(params) {
  return (0, _request.default)({
    url: "/api/svip/manager/evaluate/" + params.id,
    method: 'get'
  });
}
/**
 * 修改评价
 */


function updateEvaluation(data) {
  return (0, _request.default)({
    url: '/api/svip/manager/evaluate/' + data.id,
    method: 'put',
    data: R.omit(['id'], data)
  });
} // 荔星伙伴 end
// 要客分类 start

/**
 * 要客分类列表（分页）
 */


function getPageCategoryList(params) {
  return (0, _request.default)({
    url: '/api/svip/category/page',
    method: 'get',
    params: params
  });
}
/**
 * 创建要客分类
 */


function createCategory(data) {
  return (0, _request.default)({
    url: '/api/svip/category',
    method: 'post',
    data: data
  });
}
/**
 * 编辑要客分类
 */


function updateCategory(data) {
  return (0, _request.default)({
    url: '/api/svip/category/' + data.id,
    method: 'put',
    data: R.omit(['id'], data)
  });
}
/**
 * 移动要客分类
 */


function moveCategory(data) {
  return (0, _request.default)({
    url: '/api/svip/category/' + data.id + '/move/' + data.moveType,
    method: 'put',
    data: R.omit(['id', 'moveType'], data)
  });
}
/**
 * 商品查询列表（分页）
 */


function getPageCategoryGoodsList(params) {
  return (0, _request.default)({
    url: '/api/svip/category/goods',
    method: 'get',
    params: params
  });
}
/**
 * 批量添加分类商品
 */


function batchAddCategoryGoods(data) {
  return (0, _request.default)({
    url: '/api/svip/category/goods',
    method: 'put',
    data: data
  });
}
/**
 * 批量移除分类商品
 */


function batchDelCategoryGoods(data) {
  return (0, _request.default)({
    url: '/api/svip/category/goods',
    method: 'delete',
    data: data
  });
}
/**
 * 移动分类下商品位置
 */


function moveCategoryGoods(data) {
  return (0, _request.default)({
    url: '/api/svip/category/' + data.categoryId + '/goods/' + data.goodsId + '/move/' + data.moveType,
    method: 'put',
    data: R.omit(['categoryId', 'goodsId', 'moveType'], data)
  });
} // 要客分类 end
// 服务管理 start

/**
 * 服务列表（分页）
 */


function getPageServiceList(params) {
  return (0, _request.default)({
    url: '/api/svip/service/page',
    method: 'get',
    params: params
  });
}
/**
 * 服务分类列表（分页））
 */


function getPageServiceCategoryList(params) {
  return (0, _request.default)({
    url: '/api/svip/service/category/page',
    method: 'get',
    params: params
  });
}
/**
 * 服务分类列表（不分页）
 */


function getServiceCategoryList(params) {
  return (0, _request.default)({
    url: '/api/svip/service/category',
    method: 'get',
    params: params
  });
}
/**
 * 服务列表
 */


function getServiceList(params) {
  return (0, _request.default)({
    url: '/api/svip/service',
    method: 'get',
    params: params
  });
}
/**
 * 服务详情
 */


function getService(params) {
  return (0, _request.default)({
    url: "/api/svip/service/" + params.id,
    method: 'get'
  });
}
/**
 * 创建服务分类
 */


function creteServiceCategory(params) {
  return (0, _request.default)({
    url: "/api/svip/service/category",
    method: 'post',
    data: params
  });
}
/**
 * 更新服务分类
 */


function updateServiceCategory(params) {
  return (0, _request.default)({
    url: "/api/svip/service/category/" + params.id,
    method: 'put',
    data: params
  });
}
/**
 * 删除服务分类
 */


function deleteServiceCategory(params) {
  return (0, _request.default)({
    url: "/api/svip/service/category/" + params.id,
    method: 'delete',
    params: params
  });
}
/**
 * 移动分类（上移下移）
 */


function moveServiceCategory(data) {
  return (0, _request.default)({
    url: '/api/svip/service/category/' + data.id + '/move/' + data.moveType,
    method: 'put',
    data: R.omit(['id', 'moveType'], data)
  });
}
/**
 * 创建服务
 */


function createService(data) {
  return (0, _request.default)({
    url: '/api/svip/service',
    method: 'post',
    data: data
  });
}
/**
 * 编辑服务
 */


function updateService(data) {
  return (0, _request.default)({
    url: '/api/svip/service/' + data.id,
    method: 'put',
    data: R.omit(['id'], data)
  });
}
/**
 * 预约服务信息查询
 */


function getServiceInfo(params) {
  return (0, _request.default)({
    url: "/api/svip/service/".concat(params.id, "/reserve"),
    method: 'get'
  });
}
/**
 * 上下架服务
 */


function switchService(data) {
  return (0, _request.default)({
    url: "/api/svip/service/".concat(data.id, "/shelve/").concat(data.shelve),
    method: 'put'
  });
}
/**
 * 服务绑定门店树查询
 */


function getSvipShopTree(params) {
  return (0, _request.default)({
    url: "/api/svip/service/".concat(params.id, "/store/tree"),
    method: 'get'
  });
} // 服务管理 end
// 预约管理 start

/**
 * 预约订单列表查询
 */


function getPageSubscribeList(params) {
  return (0, _request.default)({
    url: '/api/svip/reserve/order/page',
    method: 'get',
    params: params
  });
}
/**
 * 预约
 */


function reserve(data) {
  return (0, _request.default)({
    url: '/api/svip/reserve/order',
    method: 'post',
    data: data
  });
}
/**
 * 已支付-取消预约
 */


function cancelPaidSubscribe(params) {
  console.log('params==>', params);
  return (0, _request.default)({
    url: '/api/svip/reserve/order/' + params.orderSn + '/paid/cancel',
    method: 'put',
    data: R.omit(['id'], params)
  });
}
/**
 * 未支付-取消预约
 */


function cancelSubscribe(params) {
  console.log('params==>', params);
  return (0, _request.default)({
    url: '/api/svip/reserve/order/' + params.orderSn + '/cancel',
    method: 'put'
  });
}
/**
 * 预约订单详情
 */


function getSubscribe(params) {
  return (0, _request.default)({
    url: '/api/svip/reserve/order/' + params.orderSn,
    method: 'get'
  });
}
/**
 * 售后列表查询（分页）
 */


function getPageAfterSaleList(params) {
  return (0, _request.default)({
    url: '/api/svip/reserve/refund/page',
    method: 'get',
    params: params
  });
}
/**
 * 查看预约售后详情
 */


function getAfterSaleDetail(params) {
  return (0, _request.default)({
    url: "/api/svip/reserve/refund/" + params.id,
    method: 'get'
  });
}
/**
 * 审核
 */


function cancelAfterSale(params) {
  console.log('params==>', params);
  return (0, _request.default)({
    url: '/api/svip/reserve/refund/' + params.orderSn + '/audit',
    method: 'put',
    data: R.omit(['id'], params)
  });
} // 预约管理 end
// 活动管理 start

/**
 * 活动列表
 */


function getActivityList(params) {
  return (0, _request.default)({
    url: '/api/svip/activity',
    method: 'get',
    params: params
  });
} // 预约管理配置 end

/* 创建活动
 */


function createActivity(data) {
  return (0, _request.default)({
    url: '/api/svip/activity',
    method: 'post',
    data: data
  });
}
/**
 * 活动列表(分页)
 */


function getPageActivityList(params) {
  return (0, _request.default)({
    url: '/api/svip/activity/page',
    method: 'get',
    params: params
  });
}
/**
 * 活动详情
 */


function getActivity(params) {
  return (0, _request.default)({
    url: '/api/svip/activity/' + params.id,
    method: 'get',
    params: params
  });
}
/**
 * 编辑活动
 */


function updateActivity(data) {
  return (0, _request.default)({
    url: '/api/svip/activity/' + data.id,
    method: 'put',
    data: R.omit(['id'], data)
  });
}
/**
 * 取消活动
 */


function cancelActivity(data) {
  return (0, _request.default)({
    url: '/api/svip/activity/' + data.id + '/cancel',
    method: 'put',
    data: R.omit(['id'], data)
  });
}
/**
 * 预约活动信息查询
 */


function getActivityInfo(params) {
  return (0, _request.default)({
    url: '/api/svip/activity/' + params.id + '/reserve',
    method: 'get',
    params: params
  });
}
/**
 * 上下架
 */


function switchActivity(data) {
  return (0, _request.default)({
    url: '/api/svip/activity/' + data.id + '/shelve/' + data.shelve,
    method: 'put',
    data: R.omit(['id', 'shelve'], data)
  });
}
/**
 * 活动日历列表
 */


function getActivityCalendarList(params) {
  return (0, _request.default)({
    url: '/api/svip/activity/calendar',
    method: 'get',
    params: params
  });
}
/**
 * 新增活动日历
 */


function createActivityCalendar(data) {
  return (0, _request.default)({
    url: '/api/svip/activity/calendar',
    method: 'post',
    data: data
  });
}
/**
 * 活动日历列表（分页）
 */


function getPageActivityCalendarList(params) {
  return (0, _request.default)({
    url: '/api/svip/activity/calendar/page',
    method: 'get',
    params: params
  });
}
/**
 * 活动日历详情
 */


function getActivityCalendar(params) {
  console.log('params', params);
  return (0, _request.default)({
    url: '/api/svip/activity/calendar/' + params.id,
    method: 'get',
    params: params
  });
}
/**
 * 修改活动日历
 */


function updateActivityCalendar(data) {
  return (0, _request.default)({
    url: '/api/svip/activity/calendar/' + data.id,
    method: 'put',
    data: R.omit(['id'], data)
  });
}
/**
 * 日历启用/禁用
 */


function switchActivityCalendar(data) {
  return (0, _request.default)({
    url: '/api/svip/activity/calendar/' + data.id + '/enable/' + data.enable,
    method: 'put',
    data: R.omit(['id', 'enable'], data)
  });
} // 活动管理 end
// 场地管理 start

/**
 * 创建场地
 */


function createPlace(params) {
  return (0, _request.default)({
    url: '/api/svip/place',
    method: 'POST',
    data: params
  });
}
/**
 * 创建场地
 */


function updatePlace(params) {
  return (0, _request.default)({
    url: '/api/svip/place/' + params.id,
    method: 'PUT',
    data: R.omit(['id'], params)
  });
}
/**
 * 场地上下架
 */


function updatePlaceShelve(params) {
  return (0, _request.default)({
    url: "/api/svip/place/".concat(params.id, "/shelve/").concat(params.shelve),
    method: 'PUT'
  });
}
/**
 * 场地详情
 */


function getPlace(params) {
  return (0, _request.default)({
    url: '/api/svip/place/' + params.id,
    method: 'GET'
  });
}
/**
 * 场地列表
 */


function getPlaceList(params) {
  return (0, _request.default)({
    url: '/api/svip/place',
    method: 'GET',
    params: params
  });
}
/**
 * 场地列表（分页）
 */


function getPagePlaceList(params) {
  return (0, _request.default)({
    url: '/api/svip/place/page',
    method: 'GET',
    params: params
  });
}
/**
 * 场地设备列表
 */


function getEquipmentList(params) {
  return (0, _request.default)({
    url: '/api/svip/place/equipment',
    method: 'GET',
    params: params
  });
}
/**
 * 场地设备列表（分页）
 */


function getPageEquipmentList(params) {
  return (0, _request.default)({
    url: '/api/svip/place/equipment/page',
    method: 'GET',
    params: params
  });
}
/**
 * 预约场地信息查询
 */


function getPlaceReserveInfo(params) {
  return (0, _request.default)({
    url: "/api/svip/place/".concat(params.id, "/reserve"),
    method: 'GET'
  });
}
/**
 * 场地设备上下架
 */


function updateShelve(params) {
  return (0, _request.default)({
    url: '/api/svip/place/equipment/' + params.id + '/shelve/' + params.shelve,
    method: 'PUT',
    data: R.omit(['id'], params)
  });
}
/**
 * 新增设备
 */


function addEquipment(params) {
  return (0, _request.default)({
    url: '/api/svip/place/equipment',
    method: 'post',
    data: params
  });
}
/**
 * 编辑设备
 */


function editEquipment(params) {
  return (0, _request.default)({
    url: '/api/svip/place/equipment/' + params.id,
    method: 'put',
    data: R.omit(['id'], params)
  });
} // 场地经理 end

/**
 * 会员列表
 */


function listMainUserPage(data) {
  var loading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'loading';
  return (0, _request.default)({
    url: '/api/mainUser/page',
    method: 'post',
    data: data,
    loading: loading
  });
} // 要客等级 start

/**
 * 要客等级列表查询
 */


function levelList() {
  return (0, _request.default)({
    url: '/api/svip/level',
    method: 'get'
  });
} // 修改服务预约时间


function UpdateReserveTime(data) {
  return (0, _request.default)({
    url: '/api/svip/service/update/reserveTime',
    method: 'post',
    data: data
  });
} // 日历显示-查询场地预约记录


function placeReserveList(data) {
  return (0, _request.default)({
    url: '/api/svip/place/reserveLog',
    method: 'post',
    data: data
  });
} // 核销预约记录


function verificationRecord(orderSn) {
  return (0, _request.default)({
    url: "/api/svip/reserve/order/".concat(orderSn, "/verification"),
    method: 'put'
  });
} // 撤销核销


function cancelVerificationRecord(data) {
  return (0, _request.default)({
    url: "/api/svip/reserve/order/cancelVerification",
    method: 'post',
    data: data
  });
} // 导入要客


function batchImportSvip(data) {
  return (0, _request.default)({
    url: "/api/svip/batchImportSvip",
    method: 'post',
    data: data
  });
}