"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

/** When your routing table is too long, you can split it into small modules **/
var iotBannerRouter = {
  path: 'iot',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/coupon/putCenter/index.vue'));
    });
  },
  redirect: '/template/iot/banner',
  name: 'iot',
  meta: {
    title: '领券中心'
  },
  children: [{
    path: 'banner',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/iot/banner/list.vue'));
      });
    },
    name: 'iotBanner',
    meta: {
      title: 'IOT广告',
      keepAlive: true
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/iot/banner/add.vue'));
      });
    },
    name: 'iotBannerAdd',
    meta: {
      title: '编辑广告',
      activeMenu: '/template/iot/banner'
    }
  }]
};
var _default = iotBannerRouter;
exports.default = _default;