"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _shopCard = require("@/api/shopCard");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      imgBaseSrc: this.$store.state.user.imgSrc,
      requestParams: {
        code: undefined,
        cardKindsName: undefined,
        cardCategoryId: undefined,
        ifIntegral: undefined,
        registeredType: undefined,
        status: undefined
      },
      classOption: [],
      total: 0,
      list: []
    };
  },
  created: function created() {
    this.getList(); // 卡类型下拉查询

    this.getCardClass();
  },
  methods: {
    onCreate: function onCreate() {
      this.$router.push("/shopCard/groupCard/save");
    },
    onReset: function onReset() {
      this.$refs['requestParamsForm'].resetFields();
      this.onQuery();
    },
    onQuery: function onQuery() {
      this.requestParams.pageNo = 1;
      this.getList();
    },
    onPageChange: function onPageChange() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      (0, _shopCard.kindsPage)(this.requestParams).then(function (res) {
        _this.list = res.data.records;
        _this.total = res.data.total;
      });
    },
    getCardClass: function getCardClass() {
      var _this2 = this;

      (0, _shopCard.cardTypePage)({
        pageSize: 1000
      }).then(function (res) {
        _this2.classOption = res.data.records;
      });
    }
  }
};
exports.default = _default;