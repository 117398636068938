"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _member = require("@/api/member.js");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/gift.xlsx"),
      list: [],
      tabTh: [{
        prop: 'giftName',
        label: '礼品名称',
        width: 100
      },
      /* {
          prop:'giftType',
          label:'礼品类型',
          width:100,
        }, */
      {
        prop: 'companyCode',
        label: '所属总部',
        width: 80
      }, {
        prop: 'storeCode',
        label: '所属门店',
        width: 80
      }, {
        prop: 'giftWorth',
        label: '礼品价值',
        width: 80
      }, {
        prop: 'purchasingPrice',
        label: '进货价格',
        width: 80
      }, {
        prop: 'stockNumber',
        label: '入库数量',
        width: 80
      }, {
        prop: 'scorePrice',
        label: '积分价格',
        width: 80
      }, {
        prop: 'discountScore',
        label: '365积分价格',
        width: 80
      }, {
        prop: 'oprRemark',
        label: '入库备注',
        width: 160,
        algin: 'left'
      }]
    };
  },
  created: function created() {},
  methods: {
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;

      if (!row.giftName || !row.companyCode || !row.storeCode || !row.oprRemark) {
        return 'err-row';
      }

      if (!/^[1-6]$/.test(row.giftType)) {
        return 'err-row';
      }

      if (!/^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/.test(row.giftWorth)) {
        return 'err-row';
      }

      if (!/^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/.test(row.purchasingPrice)) {
        return 'err-row';
      }

      if (!/(^[1-9]\d*$)/.test(row.stockNumber)) {
        return 'err-row';
      }

      if (!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(row.scorePrice)) {
        return 'err-row';
      }

      if (!/^[1-2]$/.test(row.giftStatus)) {
        return 'err-row';
      }
    },
    affirmNext: function affirmNext() {},
    next: function next() {
      var _this = this;

      var arr = [];
      this.list.forEach(function (row) {
        if (!row.giftName || !row.companyCode || !row.storeCode || !row.oprRemark) {
          return 'err-row';
        } else if (!/^[1-6]$/.test(row.giftType)) {
          return 'err-row';
        } else if (!/^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/.test(row.giftWorth)) {
          return 'err-row';
        } else if (!/^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/.test(row.purchasingPrice)) {
          return 'err-row';
        } else if (!/(^[1-9]\d*$)/.test(row.stockNumber)) {
          return 'err-row';
        } else if (!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(row.scorePrice)) {
          return 'err-row';
        } else if (!/^[1-2]$/.test(row.giftStatus)) {
          return 'err-row';
        } else {
          arr.push(row);
        }
      });
      this.$confirm('确认添加礼品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.openLoading();

        (0, _member.addGifts)({
          giftInfos: arr
        }).then(function (res) {
          _this.openLoading().close();

          _this.$router.replace('/member/gift/list');

          _this.$message({
            type: 'success',
            message: '添加成功!'
          });
        }).catch(function (err) {
          _this.openLoading().close();
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    deleteItem: function deleteItem(index) {
      this.list.splice(index, 1);
    },
    handleSuccess: function handleSuccess(_ref2) {
      var results = _ref2.results,
          header = _ref2.header;
      var arr = [];
      var myreg = /^0{0,1}(1)[0-9]{10}$/;
      results.forEach(function (data) {
        arr.push({
          companyCode: data.companyCode,
          storeCode: data.storeCode,
          giftName: data.giftName,
          giftType: data.giftType,
          giftWorth: data.giftWorth,
          purchasingPrice: data.purchasingPrice,
          stockNumber: data.stockNumber,
          scorePrice: data.scorePrice,
          discountScore: data.会员价 || null,
          oprRemark: data.oprRemark,
          giftStatus: data.giftStatus
        });
      });
      this.list = arr;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    }
  }
};
exports.default = _default;