"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.getGoodsData = getGoodsData;

var _request = _interopRequireDefault(require("@/utils/request"));

function addData(data) {
  return (0, _request.default)({
    url: '/api/user-holiday-benefits-config/saveBatch',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getGoodsData(data) {
  return (0, _request.default)({
    url: '/api/user-holiday-benefits-config/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}