"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _api = require("./api.js");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _index2 = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default,
    WTable: _index2.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      columns: [{
        label: '商品码',
        prop: 'goodsCode',
        width: 100
      }, {
        label: '门店编码',
        prop: 'storeId',
        width: 100
      }, {
        slot: 'operate'
      }],
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/couponC.xlsx"),
      requestData: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      id: null,
      ruleList: [],
      examVoList: [],
      ruleForm: {
        messageDetailVo: {},
        examVoList: [],
        ruleVoList: {}
      },
      rules: {
        goodsParamList: [{
          required: true,
          message: '请上传商品码'
        }]
      },
      goodsForm: {
        goodsParamList: [],
        activityId: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.id = this.$route.query.id;
    this.goodsForm.activityId = Number(this.$route.query.id);
    this.requestData.activityId = this.$route.query.id;

    if (this.id) {
      this.getList();
      (0, _api.getData)(this.id).then(function (res) {
        _this.ruleForm = res.data;
        _this.ruleList = res.data.ruleVoList.ruleList;
        _this.examVoList = res.data.examVoList;
      });
    }
  },
  methods: {
    delGoods: function delGoods(index) {
      console.log(index);
      this.goodsForm.goodsParamList.splice(index, 1);
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this2.requestData[key]) {
          data[key] = _this2.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getGoodsPage)(data).then(function (res) {
        list = res.data.records;
        _this2.downloadLoading = false;

        _this2.getHandleDownload(tHeader, filterVal, list, _this2.ruleForm.messageDetailVo.name);
      }).catch(function (res) {
        _this2.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.requestData.pageSize = val;
      this.requestData.pageNo = 1;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.requestData.pageNo = val;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      (0, _api.getGoodsPage)(this.requestData).then(function (res) {
        _this3.tableData = res.data.records;
        _this3.requestData.total = Number(res.data.total);
      });
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.goAddGoods)(_this4.goodsForm).then(function (res) {
            _this4.$message({
              type: 'success',
              message: '上传成功!'
            });

            _this4.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      results.forEach(function (data) {
        console.log(data);

        if (data.storeId && data.goodsCode) {
          arr.push({
            goodsCode: String(data.goodsCode).replace(/[^\d]/g, ''),
            storeId: String(data.storeId).replace(/[^\d]/g, '')
          });
        }
      });
      this.goodsForm.goodsParamList = arr;
    }
  }
};
exports.default = _default;