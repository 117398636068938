"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addVip = addVip;
exports.editVip = editVip;
exports.exportCdkeys = exportCdkeys;
exports.getData = getData;
exports.getKinPage = getKinPage;
exports.getList = getList;
exports.getVip = getVip;
exports.getVipList = getVipList;
exports.listVipPage = listVipPage;
exports.removeById = removeById;
exports.saveOrUpdate = saveOrUpdate;
exports.setRights = setRights;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
1、会员设置分页：/api/vipSet/listVipPage
{"pageNo":"当前页","pageSize":"页条数","vipName":"会员名称","vipStatus":" 会员状态(0：停用 1：启用)"}

2、会员设置明细：/api/vipSet/getVip/{id}

3、新增会员设置：/api/vipSet/addVip
{"vipName":"会员名称","saleAmount":"售卖金额","monthNumber":"生效月数",
"vipStatus":" 会员状态(0：停用 1：启用)","vipIcon":"会员图标",
"vipRemark":"会员说明",
"openGifts":[
  {"attrType":"属性类型(1：实体商品 2：直冲商品 3：优惠券)",
  "attrCode":"属性编码",
  "attrWorth":"属性价值"}]}

4、编辑会员设置：/api/vipSet/editVip
{"vipName":"会员名称","saleAmount":"售卖金额","monthNumber":"生效月数","vipStatus":" 会员状态(0：停用 1：启用)","vipIcon":"会员图标","vipRemark":"会员说明","openGifts":[{"attrType":"属性类型(1：实体商品 2：直冲商品 3：优惠券)","attrCode":"属性编码","attrWorth":"属性价值"}]}
 */
function listVipPage(data) {
  return (0, _request.default)({
    url: '/api/vipSet/listVipPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getKinPage(data) {
  return (0, _request.default)({
    url: '/api/mainUser/selectMainUserBindLogList',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
 */


function addVip(data) {
  return (0, _request.default)({
    url: '/api/vipSet/addVip',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editVip(data) {
  return (0, _request.default)({
    url: '/api/vipSet/editVip',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getVip(data) {
  return (0, _request.default)({
    url: '/api/vipSet/getVip/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function getVipList(data) {
  return (0, _request.default)({
    url: '/api/pay-member-type/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getList(data) {
  return (0, _request.default)({
    url: '/api/pay-member-type/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getData(data) {
  return (0, _request.default)({
    url: '/api/pay-member-type/getById',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function saveOrUpdate(data) {
  return (0, _request.default)({
    url: '/api/pay-member-type/saveOrUpdate',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function exportCdkeys(data) {
  return (0, _request.default)({
    url: '/api/pay-member-type/exportCdkeys',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function setRights(data) {
  return (0, _request.default)({
    url: '/api/pay-member-type/setRights',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function removeById(data) {
  return (0, _request.default)({
    url: '/api/pay-member-type/removeById',
    method: 'get',
    loading: 'loading',
    params: data
  });
}