"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeStatus = changeStatus;
exports.detailStorePrintMsg = detailStorePrintMsg;
exports.listStorePrintMsgPage = listStorePrintMsgPage;
exports.saveStorePrintMsg = saveStorePrintMsg;

var _request = _interopRequireDefault(require("@/utils/request"));

function listStorePrintMsgPage(data) {
  return (0, _request.default)({
    url: '/api/storePrintMsg/listStorePrintMsgPage',
    method: 'post',
    data: data
  });
}

function saveStorePrintMsg(data) {
  return (0, _request.default)({
    url: data.id ? "/api/storePrintMsg/editStorePrintMsg" : 'api/storePrintMsg/addStorePrintMsg',
    method: 'post',
    data: data
  });
}

function detailStorePrintMsg(id) {
  return (0, _request.default)({
    url: "/api/storePrintMsg/getStorePrintMsgById/".concat(id),
    method: 'get'
  });
}

function changeStatus(data) {
  return (0, _request.default)({
    url: "/api/storePrintMsg/changeStatus",
    method: 'post',
    data: data
  });
}