"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _api = require("./api.js");

var _api2 = require("../category/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      time: null,
      downloadLoading: false,
      categoryList: [],
      requestData: {
        cardCategoryId: null,
        cardKindsName: null,
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '卡种ID',
        prop: 'id',
        width: 60
      }, {
        label: '卡种名称',
        prop: 'cardKindsName',
        width: 100
      }, {
        label: '类型ID',
        prop: 'cardCategoryId',
        width: 100
      }, {
        label: '类型名称',
        prop: 'categoryName',
        width: 100
      }, {
        label: '面额（元）',
        prop: 'cardLimitPrice',
        width: 100
      }, {
        label: '库存',
        prop: 'currentStock',
        width: 80
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(value) {
      if (value) {
        this.requestData.activityStartTime = value[0];
        this.requestData.activityEndTime = value[0];
      } else {
        this.requestData.activityStartTime = null;
        this.requestData.activityEndTime = null;
      }
    }
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {
    var _this = this;

    (0, _api2.categoryPage)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      console.log(res);
      _this.categoryList = res.data.records;
    });
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this2.requestData[key]) {
          data[key] = _this2.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.classPage)(data).then(function (res) {
        list = res.data.records;
        _this2.downloadLoading = false;

        _this2.getHandleDownload(tHeader, filterVal, list, '卡分类列表');
      }).catch(function (res) {
        _this2.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      console.log(pageObj);
      this.requestData.pageNo = pageObj.page;
      this.requestData.pageSize = pageObj.limit;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.classPage)(this.requestData).then(function (res) {
        _this3.tableData = res.data.records;
        _this3.requestData.total = res.data.total;
        return;
      });
    }
  }
};
exports.default = _default;