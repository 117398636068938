"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _tableList = _interopRequireDefault(require("@/components/tableList"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _mem = require("@/api/mem");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    tableList: _tableList.default
  },
  data: function data() {
    var _ref;

    return _ref = {
      deaPath: '/system/mem/editor?id=',
      total: 0,
      tableData: [],
      tabTh: [{
        tit: '会员体系名称',
        prop: 'sysName',
        align: 'center',
        width: 200
      }, {
        tit: '会员卡ID',
        prop: 'cardId',
        align: 'center',
        width: 100
      }, {
        tit: '会员卡名称',
        prop: 'name',
        align: 'center',
        width: 200
      }, {
        tit: '创建时间',
        prop: 'createTime',
        sortable: true,
        align: 'center',
        width: 180
      }, {
        tit: '排序号',
        prop: 'sort',
        sortable: true,
        align: 'center',
        width: 100
      }, {
        tit: '状态',
        prop: 'status',
        align: 'center',
        width: 80
      }],
      operation: 2
    }, (0, _defineProperty2.default)(_ref, "total", 0), (0, _defineProperty2.default)(_ref, "btn", '编辑'), (0, _defineProperty2.default)(_ref, "btnType", 'primary'), (0, _defineProperty2.default)(_ref, "pageNo", 1), (0, _defineProperty2.default)(_ref, "pageSize", 10), (0, _defineProperty2.default)(_ref, "cardId", ''), _ref;
  },
  destroyed: function destroyed() {
    var initInfo = {
      pageNo: this.pageNo,
      pageSize: this.pageSize
    };
    this.$store.dispatch('buffer/setMerberList', initInfo);
  },
  created: function created() {
    if (this.$store.state.buffer.merberList.pageNo) {
      var initInfo = this.$store.state.buffer.merberList;
      this.pageNo = initInfo.pageNo;
      this.pageSize = initInfo.pageSize;
    }

    this.getList();
  },
  methods: {
    getList: function getList(e) {
      var _this = this;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      (0, _mem.listCardMember)(this.pageNo, this.pageSize, this.token).then(function (res) {
        console.log(res);
        _this.tableData = res.data.records;

        _this.tableData.forEach(function (item) {
          switch (item.status) {
            case 1:
              item.status = '正常';
              break;

            default:
              item.status = '冻结';
              break;
          }
        });

        _this.total = res.data.total;
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/system/mem/add');
    },
    setTab: function setTab(row) {
      this.$router.push('/system/mem/editor?id=' + row.cardId);
    }
  }
};
exports.default = _default;