"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.match");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.array.find");

var _request = _interopRequireDefault(require("@/utils/request"));

var _store = _interopRequireDefault(require("@/store"));

var _api = require("@/api/api.js");

var _default = {
  install: function install(Vue, options) {
    Vue.prototype.getUserLabel = function (labelId) {
      if (_store.default.getters.userLableList.length > 0 && labelId > 0) {
        var data = _store.default.getters.userLableList.find(function (item) {
          return item.id == labelId;
        });

        return data ? data.labelName : labelId;
      } else if (labelId == 0) {
        return '所有会员';
      }
    };

    Vue.prototype.apiPost = function (url, data, requestType) {
      return (0, _request.default)({
        url: url,
        // '/vue-element-admin/user/login',
        method: requestType || 'post',
        data: data
      });
    };

    Vue.prototype.apiGet = function (url, data) {
      return (0, _request.default)({
        url: url,
        // '/vue-element-admin/user/login',
        method: 'get',
        params: data
      });
    };

    Vue.prototype.setFormatData = function (time) {
      var year = time.getFullYear();
      var month = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
      var day = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
      var hour = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
      var minute = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
      var second = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
      return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(minute, ":").concat(second);
    };

    Vue.prototype.getValidDay = function (data) {
      console.log(data);
      return "\u9886\u53D6\u540E".concat(data.validStartDay || 0, "\u5230").concat(data.validDay, "\u5929\u5185\u6709\u6548");
    };

    Vue.prototype.bigNumberAdd = function (a, b) {
      var res = '',
          c = 0; //进位值，初始c值为0

      a = a.split(''); //将数据拆分为数组

      b = b.split(''); //同上

      while (a.length || b.length || c) {
        //遍历数据
        c += ~~a.pop() + ~~b.pop(); //进位值c

        res = c % 10 + res; //依次相加

        c = c > 9; //若c大于9，c为true,下次循环中true转换为1，即有进位
      }

      return res.replace(/^0+/, ''); //返回值
    };
    /**
     * 比较两个大整数的大小，返回－1，0，1  a<b返回-1
     * @param {String} a
     * @param {String} b
     * @returns {number}
     */


    Vue.prototype.bigNumCompare = function (a, b) {
      function getMidNum(str, start, len) {
        if (start + len > 0) {
          return +str.substr(start < 0 ? 0 : start, start < 0 ? start + len : len);
        } else {
          return 0;
        }
      }

      var back = 0;
      var max = Math.ceil(Math.max(a.length, b.length) / 15); //分成多少段,从左边开始

      for (var i = max; i > 0; i--) {
        var num1 = getMidNum(a, a.length - i * 15, 15);
        var num2 = getMidNum(b, b.length - i * 15, 15); //15位数字相减

        var cur = num1 - num2;
        console.log(cur);

        if (cur < 0) {
          back = false;
          break;
        } else if (cur > 0) {
          back = true;
          break;
        }
      }

      return back;
    };

    Vue.prototype.getHandleDownload = function (tHeader, filterVal, list, filename) {
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var data = list.map(function (v) {
          return filterVal.map(function (j) {
            if (j === 'timestamp') {
              return;
            } else if (j === 'memberType') {
              var Obj = {
                1: '普通会员',
                2: '要客',
                3: '365'
              };
              return Obj[v[j]];
            } else {
              try {
                return eval('v.' + j);
              } catch (error) {
                return '';
              }
            }
          });
        });
        excel.export_json_to_excel({
          header: tHeader,
          data: data,
          filename: filename
        });
      });
    };
    /*
     页面跳转
     */


    Vue.prototype.wPage = function (url) {
      this.$router.push(url);
    };
    /*
    权限
    */


    Vue.prototype.setMenuPerms = function (menuPerms) {
      if (process.env.NODE_ENV === 'development') {
        return menuPerms != 'qnyOrderSelect';
      }

      var menuPermsArr = JSON.parse(localStorage.getItem('menuPerms'));

      if (menuPermsArr.indexOf(menuPerms) != -1) {
        return true;
      } else {
        return false;
      }
    };
    /*
    对象赋值
    */


    Vue.prototype.fzObj = function (obj1, obj2) {
      Object.keys(obj1).map(function (key) {
        Object.keys(obj2).map(function (row) {
          if (key == row) {
            obj2[row] = obj1[key];
          }
        });
      });
      return obj2;
    };
    /*
    搜索列表清空
    */


    Vue.prototype.removeObj = function (obj) {
      Object.keys(obj).map(function (key) {
        if (key == 'pageNo') {
          obj[key] = 1;
        } else if (key == 'pageSize') {
          obj[key] = 10;
        } else if (key == 'total') {
          obj[key] = 0;
        } else if (key == 'beSvip') {
          obj[key] = '';
        } else if (key == 'firstChargeYear') {
          obj[key] = '';
        } else {
          obj[key] = null;
        }
      });
      return obj;
    };
    /*
    加载
    */


    Vue.prototype.openLoading = function (txt) {
      var loading = this.$loading({
        // 声明一个loading对象
        lock: true,
        // 是否锁屏
        text: txt || '正在加载...',
        // 加载动画的文字
        spinner: 'el-icon-loading',
        // 引入的loading图标
        background: 'rgba(0, 0, 0, 0.3)',
        // 背景颜色
        target: '.sub-main',
        // 需要遮罩的区域
        body: true,
        customClass: 'mask' // 遮罩层新增类名

      });
      return loading;
    };
    /*
     if(this.brandData.brandCode){
      let arr=this.brandData.brandCode.split(',').map((item) => {
         return item.trim()
        })
       this.brandData.brandCode=arr.join(',')
    }
    */


    Vue.prototype.stringTrim = function (str) {
      var arr = str.split(',').map(function (item) {
        return item.trim();
      });
      str = arr.join(',');
      return str;
    };

    Vue.prototype.upLoadExcel = function (filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    };

    Vue.prototype.getURLParameters = function (urls) {
      var getUrlData = function getUrlData(url) {
        return (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(function (a, v) {
          return a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1), a;
        }, {});
      };

      return getUrlData(urls);
    };
  }
};
exports.default = _default;