var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar print_hide" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "menuList", staticClass: "tbaTip" },
        [
          _c(
            "el-menu",
            {
              staticClass: "menuList ",
              attrs: {
                "default-active": _vm.activeIndex,
                id: "tbaTip",
                mode: "horizontal"
              }
            },
            [
              _vm._l(_vm.tablist, function(item, index) {
                return _c(
                  "el-menu-item",
                  {
                    key: index,
                    staticClass: "menuItem",
                    class: index == _vm.activeIndex ? "bordertip" : "tip",
                    attrs: { index: String(index) },
                    on: {
                      click: function($event) {
                        return _vm.tabit(index, item)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              _vm._v(" "),
              _vm.moreArr.length > 0
                ? _c(
                    "el-submenu",
                    {
                      staticClass: "menuItem el-menu-item",
                      class:
                        _vm.tablist.length <= _vm.activeIndex
                          ? "is-active"
                          : "tip",
                      attrs: { index: "99999" }
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c("div", { staticClass: "flex_center" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "30px",
                                overflow: "hidden",
                                "text-align": "center",
                                height: "60px"
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.tablist.length <= _vm.activeIndex
                                      ? _vm.activeName
                                      : "更多"
                                  ) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "el-icon-arrow-down" })
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.moreArr, function(i, iIndex) {
                        return _c(
                          "el-menu-item",
                          {
                            key: _vm.tablist.length + iIndex,
                            attrs: {
                              index: String(_vm.tablist.length + iIndex)
                            },
                            on: {
                              click: function($event) {
                                return _vm.tabit(_vm.tablist.length + iIndex, i)
                              }
                            }
                          },
                          [_vm._v(_vm._s(i.name))]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("div", { staticClass: "avatar-wrapper" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.userInfo.username) +
                      "\n          "
                  ),
                  _c("i", { staticClass: "el-icon-caret-bottom" })
                ])
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "slect_menu",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      staticClass: "dropdown-item",
                      staticStyle: {
                        "border-top": "none",
                        "margin-top": "0",
                        padding: "5px 10px"
                      },
                      attrs: { divided: "" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { width: "100%", height: "100%" },
                          on: { click: _vm.editSysUserPwd }
                        },
                        [_vm._v("修改密码")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      staticClass: "dropdown-item",
                      staticStyle: { "margin-top": "0", padding: "5px 10px" },
                      attrs: { divided: "" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { width: "100%", height: "100%" },
                          on: { click: _vm.logout }
                        },
                        [_vm._v("退出登录")]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.editPwdShow
        ? _c("div", { staticClass: "editPwd" }, [
            _c("div", { staticClass: "editPwd_box" }, [
              _c("div", { staticClass: "editPwd_box_tit" }, [
                _vm._v("\n        修改密码\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "editPwd_box_input" }, [
                _c(
                  "div",
                  { staticClass: "editPwd_box_input_item" },
                  [
                    _c("div", { staticClass: "input_label" }, [
                      _vm._v("旧密码：")
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { "show-password": "" },
                      model: {
                        value: _vm.form.password,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "password", $$v)
                        },
                        expression: "form.password"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "editPwd_box_input_item" },
                  [
                    _c("div", { staticClass: "input_label" }, [
                      _vm._v("新密码：")
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { "show-password": "" },
                      model: {
                        value: _vm.form.newPassord,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "newPassord", $$v)
                        },
                        expression: "form.newPassord"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "editPwd_box_input_item" },
                  [
                    _c("div", { staticClass: "input_label" }, [
                      _vm._v("确认密码：")
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { "show-password": "" },
                      model: {
                        value: _vm.form.affirmNewPassord,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "affirmNewPassord", $$v)
                        },
                        expression: "form.affirmNewPassord"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "demo-drawer__footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.editPwdShow = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.handleClose }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.loading ? "提交中 ..." : "确 定") +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }