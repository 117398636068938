"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addUserKpi = addUserKpi;
exports.listUserKpi = listUserKpi;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 、、:
 获取推广人指标：/api/sysUserKpi/listUserKpi

 新增推广人指标：/api/sysUserKpi/addUserKpi
 {"kpis":[{
   "position":职务(1：总经理级 2：总监级 3：经理级 4：课长级 5：主管级 6：员工级 7：百货(12001、12002、12003) 8：购物中心(12010、12009、12004、12007)),"number":"kpi数量"}]}
*/
function listUserKpi(data) {
  return (0, _request.default)({
    url: '/api/sysUserKpi/listUserKpi',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addUserKpi(data) {
  return (0, _request.default)({
    url: '/api/sysUserKpi/addUserKpi',
    method: 'post',
    loading: 'loading',
    data: data
  });
}