var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "openInvoice" },
    [
      _c("div", { staticClass: "openInvoice_content" }, [
        _c("p", { staticClass: "openInvoice_box_tit" }, [_vm._v("开票信息")]),
        _vm._v(" "),
        _c("div", { staticClass: "openInvoice_box" }, [
          _c("ul", { staticClass: "box1_ul" }, [
            _c(
              "li",
              [
                _c(
                  "p",
                  { staticClass: "li_tit" },
                  [
                    _c("font", { staticClass: "color" }, [_vm._v("*")]),
                    _vm._v("订单号/流水号：")
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "li_input",
                  attrs: {
                    size: "small",
                    disabled: _vm.status != "",
                    type: "text",
                    placeholder: "订单号/流水号"
                  },
                  model: {
                    value: _vm.sId,
                    callback: function($$v) {
                      _vm.sId = $$v
                    },
                    expression: "sId"
                  }
                }),
                _vm._v(" "),
                _vm.status == ""
                  ? _c(
                      "el-button",
                      {
                        staticClass: "li_btn",
                        attrs: { type: "primary" },
                        on: { click: _vm.goInquire }
                      },
                      [_vm._v("查询")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "p",
                  { staticClass: "li_tit" },
                  [
                    _c("font", { staticClass: "color" }, [_vm._v("*")]),
                    _vm._v("机构名称：")
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: {
                      size: "small",
                      filterable: "",
                      disabled: _vm.status != "",
                      placeholder: "机构编码"
                    },
                    model: {
                      value: _vm.sStoreId,
                      callback: function($$v) {
                        _vm.sStoreId = $$v
                      },
                      expression: "sStoreId"
                    }
                  },
                  _vm._l(_vm.sStoreIds, function(item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "p",
                  { staticClass: "li_tit" },
                  [
                    _c("font", { staticClass: "color" }, [_vm._v("*")]),
                    _vm._v("用户手机号：")
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "li_input",
                  attrs: {
                    size: "small",
                    disabled: _vm.status % 2 == 0 && _vm.status > 0,
                    type: "number",
                    maxlength: "11",
                    placeholder: "填写用户手机号"
                  },
                  model: {
                    value: _vm.peopleTel,
                    callback: function($$v) {
                      _vm.peopleTel = $$v
                    },
                    expression: "peopleTel"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c("p", { staticClass: "li_tit" }, [_vm._v("发送邮箱：")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "li_input",
                  attrs: {
                    size: "small",
                    disabled: _vm.status % 2 == 0 && _vm.status > 0,
                    type: "email",
                    placeholder: "填写邮箱地址"
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("申请时间：")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.detailInfo.createTime))])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("审核人员：")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.detailInfo.auditUser || "-"))])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("审核时间：")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.detailInfo.auditTime || "-"))])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("备注：")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.detailInfo.auditNote || "-"))])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("开票状态：")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n              " +
                    _vm._s(
                      _vm.tableFormatter(_vm.detailInfo, {
                        property: "specialInvoiceStatus"
                      })
                    )
                )
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("发票代码：")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.detailInfo.fpdm))])
            ])
          ]),
          _vm._v(" "),
          _vm.status == 2 ||
          _vm.status == 4 ||
          (_vm.status > 4 && _vm.status % 2 == 0)
            ? _c("ul", { staticClass: "box1_ul" }, [
                _c(
                  "li",
                  [
                    _c("p", { staticClass: "li_tit" }, [_vm._v("开票时间：")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "li_input",
                      attrs: {
                        size: "small",
                        disabled: true,
                        type: "text",
                        placeholder: "开票时间"
                      },
                      model: {
                        value: _vm.createTime,
                        callback: function($$v) {
                          _vm.createTime = $$v
                        },
                        expression: "createTime"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "openInvoice_box_tit" }, [_vm._v("发票信息")]),
        _vm._v(" "),
        _c("div", { staticClass: "openInvoice_box" }, [
          _c(
            "div",
            { staticClass: "box_table" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "500",
                    size: "small",
                    data: _vm.tableData,
                    border: "",
                    "show-summary": "",
                    "summary-method": _vm.getSummaries
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", width: "100", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(scope.$index + 1) +
                                "\n              "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "xmmc",
                      label: "货物或应税劳务、服务名称",
                      align: "center",
                      width: "300"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "xmjshj",
                      label: "项目价税金额",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      label: "税率",
                      align: "center",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticClass: "rate_select",
                                attrs: {
                                  size: "mini",
                                  disabled:
                                    (_vm.status % 2 == 0 && _vm.status > 0) ||
                                    _vm.status == "",
                                  placeholder: "税率"
                                },
                                model: {
                                  value: scope.row.sl,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "sl", $$v)
                                  },
                                  expression: "scope.row.sl"
                                }
                              },
                              _vm._l(_vm.rates, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "spbm", label: "税收编码", align: "center" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "totalPay" }, [
            _vm._v("税价合计：" + _vm._s(_vm.jshj))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "openInvoice_box_ul" }, [
            _c("ul", { staticClass: "box1_ul" }, [
              _c(
                "li",
                [
                  _c(
                    "p",
                    { staticClass: "li_tit" },
                    [
                      _c("font", { staticClass: "color" }, [_vm._v("*")]),
                      _vm._v("开票主体类型：")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "li_input",
                      attrs: {
                        size: "small",
                        disabled: _vm.status % 2 == 0 && _vm.status > 0,
                        placeholder: "状态"
                      },
                      on: { change: _vm.typeChange },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(item) {
                      return _c("el-option", {
                        key: item.val,
                        attrs: { label: item.lab, value: item.val }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "p",
                    { staticClass: "li_tit" },
                    [
                      _c("font", { staticClass: "color" }, [_vm._v("*")]),
                      _vm._v("名称：")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "li_input",
                    attrs: {
                      size: "small",
                      disabled: _vm.status % 2 == 0 && _vm.status > 0,
                      type: "text",
                      placeholder: "企业名称 / 发票抬头"
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.type != "2"
                ? _c(
                    "li",
                    [
                      _c(
                        "p",
                        { staticClass: "li_tit" },
                        [
                          _vm.type == "1"
                            ? _c("font", { staticClass: "color" }, [
                                _vm._v("*")
                              ])
                            : _vm._e(),
                          _vm._v("纳税人识别号：\n              ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "li_input",
                        attrs: {
                          size: "small",
                          disabled: _vm.status % 2 == 0 && _vm.status > 0,
                          type: "text",
                          placeholder: "纳税人识别号"
                        },
                        model: {
                          value: _vm.headCode,
                          callback: function($$v) {
                            _vm.headCode = $$v
                          },
                          expression: "headCode"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("p", { staticClass: "li_tit" }, [_vm._v("单位地址：")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "li_input",
                    attrs: {
                      size: "small",
                      disabled: _vm.status % 2 == 0 && _vm.status > 0,
                      type: "text",
                      placeholder: "单位地址"
                    },
                    model: {
                      value: _vm.workAddress,
                      callback: function($$v) {
                        _vm.workAddress = $$v
                      },
                      expression: "workAddress"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("p", { staticClass: "li_tit" }, [_vm._v("单位电话：")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "li_input",
                    attrs: {
                      size: "small",
                      disabled: _vm.status % 2 == 0 && _vm.status > 0,
                      type: "text",
                      placeholder: "单位电话"
                    },
                    model: {
                      value: _vm.workTel,
                      callback: function($$v) {
                        _vm.workTel = $$v
                      },
                      expression: "workTel"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("p", { staticClass: "li_tit" }, [_vm._v("账户开户行：")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "li_input",
                    attrs: {
                      size: "small",
                      disabled: _vm.status % 2 == 0 && _vm.status > 0,
                      type: "text",
                      placeholder: "账户开户行"
                    },
                    model: {
                      value: _vm.bank,
                      callback: function($$v) {
                        _vm.bank = $$v
                      },
                      expression: "bank"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("p", { staticClass: "li_tit" }, [_vm._v("银行账户：")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "li_input",
                    attrs: {
                      size: "small",
                      disabled: _vm.status % 2 == 0 && _vm.status > 0,
                      type: "text",
                      placeholder: "银行账户"
                    },
                    model: {
                      value: _vm.account,
                      callback: function($$v) {
                        _vm.account = $$v
                      },
                      expression: "account"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("p", { staticClass: "li_tit" }, [_vm._v("备注：")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "li_input",
                    attrs: {
                      size: "small",
                      disabled: _vm.status % 2 == 0 && _vm.status > 0,
                      type: "text",
                      placeholder: "备注"
                    },
                    model: {
                      value: _vm.bz,
                      callback: function($$v) {
                        _vm.bz = $$v
                      },
                      expression: "bz"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("p", { staticClass: "li_tit" }, [_vm._v("附件：")]),
                  _vm._v(" "),
                  _vm._l(_vm.credentials, function(img, imgIndex) {
                    return _c(
                      "div",
                      { key: imgIndex, staticClass: "files_item" },
                      [
                        _c("el-image", {
                          staticStyle: {
                            width: "90px",
                            height: "90px",
                            display: "block"
                          },
                          attrs: { src: _vm.imgSrc + img, fit: "cover" }
                        }),
                        _vm._v(" "),
                        !(_vm.status % 2 == 0 && _vm.status > 0)
                          ? _c("i", {
                              staticClass: "remove_icon el-icon-error",
                              on: {
                                click: function($event) {
                                  return _vm.handleRemoveImage(imgIndex)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "files_item" },
                    [
                      _c("add-load", {
                        attrs: { width: 90, height: 90 },
                        on: { setCover: _vm.handleUploadSuccess }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ]),
            _vm._v(" "),
            _vm.status % 2 == 0 && _vm.status > 0
              ? _c("ul", { staticClass: "box1_ul ul_right" }, [
                  _c(
                    "li",
                    [
                      _c("p", { staticClass: "li_tit" }, [
                        _vm._v("发票代码：")
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "li_input",
                        attrs: {
                          size: "small",
                          disabled: true,
                          type: "text",
                          placeholder: ""
                        },
                        model: {
                          value: _vm.fpdm,
                          callback: function($$v) {
                            _vm.fpdm = $$v
                          },
                          expression: "fpdm"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("p", { staticClass: "li_tit" }, [
                        _vm._v("发票号码：")
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "li_input",
                        attrs: {
                          size: "small",
                          disabled: true,
                          type: "text",
                          placeholder: ""
                        },
                        model: {
                          value: _vm.fphm,
                          callback: function($$v) {
                            _vm.fphm = $$v
                          },
                          expression: "fphm"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.status == 2 ||
          _vm.status == 4 ||
          (_vm.status > 4 && _vm.status % 2 == 0)
            ? _c("div", { staticClass: "invoice_site" }, [
                _c("ul", { staticClass: "box1_ul" }, [
                  _c("li", [
                    _c("p", { staticClass: "li_tit" }, [
                      _vm._v("电子发票地址：")
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "li_a",
                        attrs: {
                          href: _vm.pdfUrl,
                          type: "primary",
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.pdfUrl) +
                            "\n              "
                        )
                      ]
                    )
                  ])
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "openInvoice_box_tit" }, [
          _vm._v("历史开票审核记录")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "openInvoice_box" },
          [_c("HistoryList", { attrs: { peopleTel: _vm.peopleTel } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "open_btn" },
        [
          _vm.detailInfo.specialInvoiceStatus == 0 ||
          _vm.detailInfo.specialInvoiceStatus == 2
            ? _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.handleCheck("open")
                    }
                  }
                },
                [_vm._v("审核")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.canEmail == 1
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.anewEmail } },
                [_vm._v("重发邮箱")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.status % 2 == 0 && _vm.status > 0 && _vm.canRedRush == 1
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.openInvoice } },
                [_vm._v("发票冲红")]
              )
            : _vm.status == "" || _vm.canReOpen == 1
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.openInvoice } },
                [_vm._v(_vm._s(_vm.canReOpen == 1 ? "重新开票" : "确认开票"))]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "审核内容",
            visible: _vm.openChecked,
            "with-header": false,
            "append-to-body": true,
            "modal-append-to-body": false,
            size: "700px"
          },
          on: {
            "update:visible": function($event) {
              _vm.openChecked = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "30px" } },
            [
              _c(
                "el-alert",
                {
                  staticClass: "check_alert",
                  attrs: { type: "info", closable: false }
                },
                [
                  _c("div", [
                    _vm._v("在开票需审商品清单内，该主体已开票商品数量：")
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n            本年数量：" +
                        _vm._s(_vm.detailInfo.limitProductInvoice) +
                        " 累计数量：" +
                        _vm._s(_vm.detailInfo.allInvoiceCount) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v("开票数量以“订单购买时间”为计算基准")])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "checkForm",
                  staticClass: "check_form",
                  attrs: {
                    model: _vm.checkForm,
                    rules: _vm.checkRules,
                    size: "small",
                    "label-width": "auto"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "status", label: "审核方案：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.checkForm.status,
                            callback: function($$v) {
                              _vm.$set(_vm.checkForm, "status", $$v)
                            },
                            expression: "checkForm.status"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("通过，确认开票")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: -1 } }, [
                            _vm._v("审核不通过")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "remark", label: "备注：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 3 },
                          placeholder: "请输入备注"
                        },
                        model: {
                          value: _vm.checkForm.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.checkForm, "remark", $$v)
                          },
                          expression: "checkForm.remark"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.openChecked = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleCheck }
                        },
                        [_vm._v("确认审核")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }