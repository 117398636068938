var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "activity" }, [
    _vm.data.moduleAttr.img
      ? _c("img", {
          staticClass: "activity_cover",
          attrs: { src: _vm.imgSrc + _vm.data.moduleAttr.img }
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "activity_list flex_between" },
      _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "activity_list_goods flex_center" },
          [
            _c("img", {
              staticClass: "activity_list_goods_cover",
              attrs: { src: _vm.imgSrc + item.pic }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "activity_list_goods_name text_ellipsis" },
              [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "activity_list_goods_btn flex_center" }, [
              _vm._v("\n        立即预约\n      ")
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }