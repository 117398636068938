var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-activity-form" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("预约活动")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "svip-activity-form_name" }, [
        _vm._v("活动名称：" + _vm._s(_vm.activityInfo.name))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "svip-activity-form_name" }, [
        _vm._v(
          "\n    预约身份：" +
            _vm._s(
              _vm.activityInfo.memberType === 3
                ? "365会员"
                : _vm.activityInfo.memberType === 2
                ? "要客"
                : "全部会员"
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-width": "150px",
            model: _vm.ruleForm,
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "预约客户：", prop: "member" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "请输入手机号",
                    "remote-method": _vm.getMemberOptions,
                    loading: _vm.memberOptionsLoading
                  },
                  on: { change: _vm.selectMember },
                  model: {
                    value: _vm.ruleForm.member,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "member", $$v)
                    },
                    expression: "ruleForm.member"
                  }
                },
                _vm._l(_vm.memberOptions, function(member) {
                  return _c("el-option", {
                    key: member.value,
                    attrs: {
                      label:
                        "" +
                        member.label +
                        (!member.value
                          ? "（无手机号）"
                          : "（" + member.value + "）"),
                      value: member,
                      disabled: !member.value
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "姓名：",
                prop: "activityParam.userName",
                rules: [
                  { required: true, message: "请填写客户姓名", trigger: "blur" }
                ]
              }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.activityParam.userName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm.activityParam,
                      "userName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.activityParam.userName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "性别：",
                prop: "activityParam.sex",
                rules: [
                  { required: true, message: "请选择性别", trigger: "change" }
                ]
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.activityParam.sex,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm.activityParam, "sex", $$v)
                    },
                    expression: "ruleForm.activityParam.sex"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("男")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("女")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "手机号：",
                prop: "activityParam.reserveMobile",
                rules: [
                  {
                    required: true,
                    message: "请填写客户手机号",
                    trigger: "blur"
                  }
                ]
              }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.activityParam.reserveMobile,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm.activityParam,
                      "reserveMobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.activityParam.reserveMobile"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "预约人数：",
                prop: "activityParam.reserveNum",
                rules: _vm.reserveNumRules
              }
            },
            [
              _c("div", { staticClass: "svip-activity-form_number" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c("el-input-number", {
                      staticClass: "form_input input-number",
                      attrs: {
                        "controls-position": "right",
                        min: 1,
                        max: _vm.activityInfo.limitNum,
                        precision: 0,
                        placeholder: "请输入"
                      },
                      model: {
                        value: _vm.ruleForm.activityParam.reserveNum,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ruleForm.activityParam,
                            "reserveNum",
                            $$v
                          )
                        },
                        expression: "ruleForm.activityParam.reserveNum"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-number-append" }, [
                      _vm._v("人")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "svip-activity-form_number-text" }, [
                  _vm._v(
                    "\n          *单次可预约" +
                      _vm._s(_vm.activityInfo.limitNum) +
                      "人\n          "
                  ),
                  _vm.activityInfo.freeNum
                    ? _c("span", [
                        _vm._v(
                          "，可免费携带" +
                            _vm._s(_vm.activityInfo.freeNum) +
                            "人参加活动"
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("div", { staticStyle: { color: "red" } }, [
                _vm._v(
                  "\n        订单总价：" +
                    _vm._s(
                      _vm._f("totalAmountView")(
                        _vm.totalAmount,
                        _vm.activityInfo
                      )
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _vm.id
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm", "edit")
                        }
                      }
                    },
                    [_vm._v("\n        确认预约\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("PayQrcode", {
        attrs: {
          show: _vm.isShowPayQrcode,
          "create-time": _vm.payQrCodeParams.createTime,
          "now-date": _vm.payQrCodeParams.nowDate,
          minutes: _vm.payQrCodeParams.minutes,
          "pay-qrcode": _vm.payQrCodeParams.payQrCode
        },
        on: { closePayQrcode: _vm.closePayQrcode }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }