"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addLabel = addLabel;
exports.addLabelClass = addLabelClass;
exports.editLabel = editLabel;
exports.editLabelClass = editLabelClass;
exports.getLabelById = getLabelById;
exports.getLabelClassById = getLabelClassById;
exports.listLabel = listLabel;
exports.listLabelClass = listLabelClass;
exports.listLabelClassPage = listLabelClassPage;
exports.listLabelPage = listLabelPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 查询方案标签分类分页
 {"page:No":"当前页","pageSize":"页条数","className":"分类名称"}
 */
function listLabelClassPage(data) {
  return (0, _request.default)({
    url: '/api/programmeLabel/listLabelClassPage',
    method: 'post',
    data: data
  });
}
/*
 查询所有方案标签分类
 */


function listLabelClass(data) {
  return (0, _request.default)({
    url: '/api/programmeLabel/listLabelClass',
    method: 'post',
    data: data
  });
}
/*
 新增方案标签分类

 {"className":"分类名称"}
 */


function addLabelClass(data) {
  return (0, _request.default)({
    url: '/api/programmeLabel/addLabelClass',
    method: 'post',
    data: data
  });
}
/*
 编辑方案标签分类

 {"classId":"分类id","className":"分类名称"}
 */


function editLabelClass(data) {
  return (0, _request.default)({
    url: '/api/programmeLabel/editLabelClass',
    method: 'post',
    data: data
  });
}
/*
 查询方案标签分类明细

 /api/programmeLabel/getLabelClassById/{classId}
 */


function getLabelClassById(classId) {
  return (0, _request.default)({
    url: '/api/programmeLabel/getLabelClassById/' + classId,
    method: 'post'
  });
}
/*
 查询方案标签分页

 {"page:No":"当前页","pageSize":"页条数","classId":"分类ID","labelName":"标签名称","labelStatus":"标签状态(-1：停用 1启用)“}
 */


function listLabelPage(data) {
  return (0, _request.default)({
    url: '/api/programmeLabel/listLabelPage',
    method: 'post',
    data: data
  });
}
/*
 查询所有方案标签

 */


function listLabel(data) {
  return (0, _request.default)({
    url: '/api/programmeLabel/listLabel',
    method: 'post',
    data: data
  });
}
/*
 新增方案标签
 {"classId":"分类ID","labelName":"标签名称","labelStatus":"标签状态(-1：停用 1启用)"}
 */


function addLabel(data) {
  return (0, _request.default)({
    url: '/api/programmeLabel/addLabel',
    method: 'post',
    data: data
  });
}
/*
 辑方案标签
 {"labelId":"标签id","classId":"分类ID","labelName":"标签名称","labelStatus":"标签状态(-1：停用 1启用)"}
 */


function editLabel(data) {
  return (0, _request.default)({
    url: '/api/programmeLabel/editLabel',
    method: 'post',
    data: data
  });
}
/*
 查询方案标签

 /api/programmeLabel/getLabelById/{labelId}
 */


function getLabelById(labelId) {
  return (0, _request.default)({
    url: '/api/programmeLabel/getLabelById/' + labelId,
    method: 'post'
  });
}