var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-image", style: _vm.style },
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: _vm.url,
            headers: _vm.headers,
            "on-success": _vm.handUploadSuccess,
            "before-upload": _vm.handUploadBefore,
            "show-file-list": false
          }
        },
        [
          _c("div", { staticClass: "upload-image-main", style: _vm.style }, [
            _vm.value
              ? _c(
                  "div",
                  { staticClass: "upload-image-item image" },
                  [
                    _c("el-image", {
                      staticClass: "upload-image-content",
                      attrs: { fit: "cover", src: _vm.fullSrc }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "upload-image-content-actions" },
                      [
                        _c("i", {
                          staticClass: "el-icon-zoom-in",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handPreview($event)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handDelete($event)
                            }
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              : _c("div", { staticClass: "upload-image-item add-icon" }, [
                  _c("i", { staticClass: "el-icon-plus" })
                ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.visible },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [_c("img", { attrs: { width: "100%", src: _vm.fullSrc, alt: "" } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }