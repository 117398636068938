var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qinting" },
    [
      _c(
        "div",
        { staticClass: "view_title flex_between" },
        [
          _vm._v("\n    " + _vm._s(_vm.$route.meta.title) + "\n    "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: { click: _vm.handleCRE }
            },
            [_vm._v("\n      新增格式\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "requestParamsForm",
          staticClass: "request-params",
          attrs: { inline: true, model: _vm.requestParams, size: "mini" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "名称" },
                model: {
                  value: _vm.requestParams.name,
                  callback: function($$v) {
                    _vm.$set(_vm.requestParams, "name", $$v)
                  },
                  expression: "requestParams.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "storeId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "输入门店名称搜索~",
                    "remote-method": _vm.remoteStoreList,
                    loading: _vm.storeloading
                  },
                  model: {
                    value: _vm.requestParams.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.requestParams, "storeId", $$v)
                    },
                    expression: "requestParams.storeId"
                  }
                },
                _vm._l(_vm.storeOptions, function(so) {
                  return _c("el-option", {
                    key: so.storeId,
                    attrs: {
                      label: so.fullName + " " + so.storeCode,
                      value: so.storeId
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "barcodeId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "输入模板名称搜索~",
                    "remote-method": _vm.remotePageList,
                    loading: _vm.pageloading
                  },
                  model: {
                    value: _vm.requestParams.barcodeId,
                    callback: function($$v) {
                      _vm.$set(_vm.requestParams, "barcodeId", $$v)
                    },
                    expression: "requestParams.barcodeId"
                  }
                },
                _vm._l(_vm.pageOptions, function(po) {
                  return _c("el-option", {
                    key: po.pageCode,
                    attrs: {
                      label: po.pageName + " " + po.pageCode,
                      value: po.pageCode
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "全部状态" },
                  model: {
                    value: _vm.requestParams.status,
                    callback: function($$v) {
                      _vm.$set(_vm.requestParams, "status", $$v)
                    },
                    expression: "requestParams.status"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "开启", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "关闭", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.handleReset } },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            size: "mini",
            "header-cell-style": { background: "#f5f5f5" }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              width: "80",
              align: "center"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "姓名", prop: "name", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "所属门店", prop: "storeName", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "二维码页面模板",
              prop: "barcodeName",
              align: "center"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "status", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 2 },
                      on: {
                        change: function($event) {
                          return _vm.changeStatus(row)
                        }
                      },
                      model: {
                        value: row.status,
                        callback: function($$v) {
                          _vm.$set(row, "status", $$v)
                        },
                        expression: "row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", prop: "createTime", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "action", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        staticClass: "table-link",
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleDTA(row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        staticClass: "table-link",
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleEDI(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.requestParams.pageNo,
          limit: _vm.requestParams.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.requestParams, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.requestParams, "pageSize", $event)
          },
          pagination: _vm.handlePageChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }