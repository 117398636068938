var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "方案名称：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "方案描述：", prop: "describe" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                model: {
                  value: _vm.ruleForm.describe,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "describe",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.describe"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "投放时间：", prop: "time" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetime",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.ruleForm.time,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "time", $$v)
                  },
                  expression: "ruleForm.time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "投放优惠券：", prop: "coupon" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "200px" },
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.getRelevances }
                },
                [_vm._v("选择")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "max-height": "400px",
                    "border-radius": "6px",
                    padding: "10px",
                    overflow: "auto",
                    border: "1px solid #CCCCCC"
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.ruleForm.coupon.join("、")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即创建")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("coupon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "couponPop",
        on: { setCoupons: _vm.setCoupons, closeCouponPop: _vm.closeCouponPop }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }