var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "view_title flex_between" },
        [
          _vm._v("\n    触发事件\n    "),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-circle-plus-outline"
              },
              on: {
                click: function($event) {
                  return _vm.wPage("/touch/eventAdd")
                }
              }
            },
            [_vm._v("新增触发事件")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.pageObj
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "eventType",
              label: "事件类型",
              align: "center",
              "min-width": "100"
            },
            slot: "eventType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.eventType == 1
                      ? _c("span", [_vm._v("发放优惠券")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.eventType == 2
                      ? _c("span", [_vm._v("储值卡变动")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.eventType == 3
                      ? _c("span", [_vm._v("积分变动")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.eventType == 4
                      ? _c("span", [_vm._v("成长值变动")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "eventStatus",
              label: "状态",
              align: "center",
              "min-width": "100"
            },
            slot: "eventStatus",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.eventStatus == 1
                      ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                          _vm._v("启用")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.eventStatus == 0
                      ? _c("span", { staticStyle: { color: "#F56C6C" } }, [
                          _vm._v("停用")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              align: "center",
              fixed: "right",
              width: "100"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "app-link",
                      {
                        staticClass: "table_td_btn",
                        attrs: { to: "/touch/eventAdd?id=" + scope.row.id }
                      },
                      [_vm._v("\n          编辑\n        ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }