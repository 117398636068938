var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-main", attrs: { id: "app-main" } },
    [
      _c(
        "keep-alive",
        [_vm.include ? _c("router-view", { key: _vm.key }) : _vm._e()],
        1
      ),
      _vm._v(" "),
      !_vm.include ? _c("router-view", { key: _vm.key }) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }