"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCardStored = addCardStored;
exports.addCardStoredClass = addCardStoredClass;
exports.editCardStored = editCardStored;
exports.editCardStoredClass = editCardStoredClass;
exports.editCardStoredStatus = editCardStoredStatus;
exports.editIfDonation = editIfDonation;
exports.getCardStoredById = getCardStoredById;
exports.getCardStoredClass = getCardStoredClass;
exports.listCardStored = listCardStored;
exports.listCardStoredClass = listCardStoredClass;
exports.listCardStoredDetail = listCardStoredDetail;
exports.listCardStoredGive = listCardStoredGive;
exports.listCardTradeRefundPage = listCardTradeRefundPage;
exports.recharge365Reward = recharge365Reward;
exports.refund = refund;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
  补365奖励：api/cardTrade/recharge365Reward
  {"mobile":"18690730433","orderNo":"OD106332407510114304,OD106332407510114305"}
 */
function recharge365Reward(data) {
  return (0, _request.default)({
    url: '/api/cardTrade/recharge365Reward',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listCardTradeRefundPage(data) {
  // 分页查询多储值卡购买记录
  return (0, _request.default)({
    url: '/api/cardTrade/listCardTradeRefundPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
退款
 */


function refund(data) {
  return (0, _request.default)({
    url: '/api/cardTrade/refund',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listCardStored(data) {
  // 分页查询多储值卡
  return (0, _request.default)({
    url: '/api/cardStored/listCardStored',
    method: 'post',
    data: data
  });
}

function addCardStored(data) {
  // 新增线上储值卡
  console.log(data);
  return (0, _request.default)({
    url: '/api/cardStored/addCardStored',
    method: 'post',
    data: data
  });
}

function editCardStored(data) {
  // 编辑线上储值卡
  return (0, _request.default)({
    url: '/api/cardStored/editCardStored',
    method: 'post',
    data: data
  });
}

function getCardStoredById(id) {
  // 新增线上储值卡
  var data = {
    id: id
  };
  return (0, _request.default)({
    url: '/api/cardStored/getCardStoredById',
    method: 'post',
    params: data
  });
}

function listCardStoredClass(data) {
  // 查询储值卡分类列表接口
  return (0, _request.default)({
    url: '/api/cardStoredClass/listCardStoredClass',
    method: 'post',
    params: data
  });
}
/**
 * 查询单张储值卡分类
 */


function getCardStoredClass(id) {
  var data = {
    id: id
  };
  return (0, _request.default)({
    url: '/api/cardStoredClass/getCardStoredClass',
    method: 'post',
    params: data
  });
}
/*
 *新增储值卡分类接口
 **/


function addCardStoredClass(data) {
  return (0, _request.default)({
    url: '/api/cardStoredClass/addCardStoredClass',
    method: 'post',
    data: data
  });
}

function editCardStoredClass(data) {
  // 编辑储值卡分类
  return (0, _request.default)({
    url: '/api/cardStoredClass/editCardStoredClass',
    method: 'post',
    data: data
  });
}

function editCardStoredStatus(id, status) {
  // 编辑储值卡状态接口
  var data = {
    id: id,
    status: status
  };
  console.log(data);
  return (0, _request.default)({
    url: '/api/cardStored/editCardStoredStatus',
    method: 'post',
    data: data
  });
}

function listCardStoredGive(data) {
  // 分页查询储值卡赠送记录
  return (0, _request.default)({
    url: '/api/cardStored/listCardStoredGive',
    method: 'post',
    data: data
  });
}

function listCardStoredDetail(pageNo, pageSize) {
  // 分页查询储值卡消费记录接口
  var data = {
    pageNo: pageNo,
    pageSize: pageSize
  };
  return (0, _request.default)({
    url: '/api/cardStored/listCardStoredDetail',
    method: 'post',
    data: data
  });
}

function editIfDonation(data) {
  return (0, _request.default)({
    url: '/api/cardStored/editIfDonation',
    method: 'post',
    data: data
  });
}