"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '等级会员权益',
      list: [
      /*
              {
                tit: '会员等级管理',
                txtArr: '<p>等级会员-会员等级管理</p>    ',
                imgPath: require('@/assets/tab/31.png'),
                path: '/grade/list'
              }, */
      {
        tit: '会员权益',
        txtArr: '<p>等级会员-会员权益管理</p>',
        imgPath: require('@/assets/tab/30.png'),
        path: '/grade/equity'
      }, {
        tit: '领取记录',
        txtArr: '<p>等级会员-会员权益领取记录</p>',
        imgPath: require('@/assets/tab/20.png'),
        path: '/grade/order'
      }, {
        tit: '积分领取记录',
        txtArr: '<p>等级会员-会员权益积分领取记录</p>',
        imgPath: require('@/assets/tab/22.png'),
        path: '/grade/score'
      }, {
        tit: '权益推送记录',
        txtArr: '<p>等级会员-权益推送记录</p>',
        imgPath: require('@/assets/tab/25.png'),
        path: '/grade/pushRight'
      }]
    };
  }
};
exports.default = _default;