"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

/** When your routing table is too long, you can split it into small modules **/
var puCenterRouter = {
  path: 'putCenter',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/coupon/putCenter/index.vue'));
    });
  },
  redirect: '/coupon/putCenter/tab',
  name: 'putCenter',
  meta: {
    title: '领券中心'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/putCenter/tab.vue'));
      });
    },
    name: 'couponPutCenterTab',
    meta: {
      title: '领券中心'
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/putCenter/list.vue'));
      });
    },
    name: 'couponPutCenterList',
    meta: {
      title: '领券中心',
      keepAlive: true
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/putCenter/add.vue'));
      });
    },
    name: 'couponPutCenterAdd',
    meta: {
      title: '编辑领券中心',
      activeMenu: '/coupon/putCenter/list'
    }
  }]
};
var _default = puCenterRouter;
/*
import index from './index'
import list from './list'
import edit from './edit'
import detail from './detail'

const router = {
  name: 'deposit-entity-batch',
  path: 'deposit-entity-batch',
  component: index,
  children: [
    {
      name: 'depositEntityBatchList',
      path: 'list',
      component: list,
      meta: {
        title: '批量存款单'
      }
    },
    {
      name: 'depositEntityBatchEdit',
      path: 'edit',
      component: edit,
      meta: {
        title: '批量存款单-新增'
      }
    },
    {
      name: 'depositEntityBatchDetail',
      path: 'detail',
      component: detail,
      meta: {
        title: '批量存款单-详情'
      }
    }
  ]
}
export default router
 */

exports.default = _default;