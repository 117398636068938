var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 物品列表 "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticStyle: { "max-width": "100%" },
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: {
                  click: function($event) {
                    return _vm.wPage(
                      "/activity/mall/goodsAdd?mallId=" +
                        _vm.requestData.activityId
                    )
                  }
                }
              },
              [_vm._v("新增物品")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    size: "mini",
                    placeholder: "选择栏目"
                  },
                  model: {
                    value: _vm.requestData.categoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "categoryId", $$v)
                    },
                    expression: "requestData.categoryId"
                  }
                },
                _vm._l(_vm.weekList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.categoryName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    size: "mini",
                    placeholder: "选择门店"
                  },
                  model: {
                    value: _vm.requestData.storeCode,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "storeCode", $$v)
                    },
                    expression: "requestData.storeCode"
                  }
                },
                _vm._l(_vm.storeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.storeId,
                      attrs: {
                        label: item.shortName + "（" + item.storeCode + "）",
                        value: item.storeCode
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "选择状态"
                  },
                  model: {
                    value: _vm.requestData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "status", $$v)
                    },
                    expression: "requestData.status"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "启用", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "禁用", value: -1 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.getList("search")
                }
              }
            },
            [_vm._v("查询")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "productType",
              label: "物品类型",
              align: "center",
              "mni-width": "100"
            },
            slot: "productType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.productType[scope.row.productType]) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "80"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "active-value": 1,
                        "inactive-value": -1
                      },
                      on: {
                        change: function($event) {
                          return _vm.upDateStatus($event, scope.row)
                        }
                      },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              align: "center",
              width: "120"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确认删除该物品？" },
                        on: {
                          confirm: function($event) {
                            return _vm.delGoods(scope.row)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "danger",
                            attrs: {
                              slot: "reference",
                              type: "text",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }