var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "deatil" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("方案详情")]),
      _vm._v(" "),
      _c(_vm.currentRole, { tag: "component" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }