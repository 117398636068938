"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.detailRegistrationLogById = detailRegistrationLogById;
exports.editData = editData;
exports.getData = getData;
exports.getGiftPage = getGiftPage;
exports.getList = getList;
exports.getSignPage = getSignPage;
exports.goExam = goExam;
exports.goSignReissue = goSignReissue;
exports.orderPage = orderPage;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: '/api/sign/rule/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addData(data) {
  return (0, _request.default)({
    url: '/api/sign/rule',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editData(data) {
  return (0, _request.default)({
    url: '/api/sign/rule/edit',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getData(data) {
  return (0, _request.default)({
    url: '/api/sign/rule/' + data,
    method: 'get',
    loading: 'loading'
  });
}

function goSignReissue(data) {
  return (0, _request.default)({
    url: '/api/tool/signReissue',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function orderPage(data) {
  return (0, _request.default)({
    url: '/api/activityOffLine/listRegistrationLog',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function detailRegistrationLogById(data) {
  return (0, _request.default)({
    url: '/api/activityOffLine/detailRegistrationLogById',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function goExam(data) {
  return (0, _request.default)({
    url: '/api/sign/rule/exam',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getSignPage(data) {
  return (0, _request.default)({
    url: '/api/sign/records/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getGiftPage(data) {
  return (0, _request.default)({
    url: '/api/sign/reward/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}