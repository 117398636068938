var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-rights-category" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    权益分类\n    "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: {
                  click: function($event) {
                    return _vm.openForm(null)
                  }
                }
              },
              [_vm._v("\n        新建分类\n      ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            data: _vm.list,
            border: "",
            "header-cell-class-name": "table_header_cell"
          }
        },
        [
          _c("el-table-column", {
            attrs: { width: "70", align: "center", label: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          (_vm.searchParams.pageNo - 1) *
                            _vm.searchParams.pageSize +
                            scope.$index +
                            1
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "分类名称", prop: "name", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "分类图标", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.icon
                      ? _c("img", {
                          staticStyle: {
                            width: "50px",
                            height: "50px",
                            margin: "0 auto"
                          },
                          attrs: { src: _vm.imgSrc + scope.row.icon }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "分类背景图", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.bgPic
                      ? _c("img", {
                          staticStyle: {
                            width: "50px",
                            height: "50px",
                            margin: "0 auto"
                          },
                          attrs: { src: _vm.imgSrc + scope.row.bgPic }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "分类描述", prop: "description", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "300",
              align: "center",
              fixed: "right",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.openForm(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n            编辑\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.goto(
                                  "/svip/rights/category/linkCategory/" +
                                    scope.row.id +
                                    "/" +
                                    scope.row.name
                                )
                              }
                            }
                          },
                          [_vm._v("\n            关联服务分类\n          ")]
                        ),
                        _vm._v(" "),
                        !(_vm.searchParams.pageNo === 1 && scope.$index === 0)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.moveRightsCategory(
                                      scope.row.id,
                                      "TOP"
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n            上移\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !(
                          _vm.searchParams.pageNo === _vm.totalPageNum &&
                          scope.$index === _vm.list.length - 1
                        )
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.moveRightsCategory(
                                      scope.row.id,
                                      "DOWN"
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n            下移\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-popconfirm",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { title: "确认删除该分类？" },
                            on: {
                              confirm: function($event) {
                                return _vm.del(scope.row)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "danger",
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference"
                              },
                              [_vm._v("\n              删除\n            ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.searchParams.pageNo,
          limit: _vm.searchParams.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchParams, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchParams, "pageSize", $event)
          },
          pagination: _vm.fetch
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.id ? "编辑权益分类" : "新建权益分类",
            visible: _vm.visible,
            width: "480px",
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                "label-width": "106px",
                model: _vm.ruleForm,
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: {
                      maxlength: "10",
                      "show-word-limit": "",
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类图标：", prop: "icon" } },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.icon
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "icon")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v("建议尺寸：1:1")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类背景图：", prop: "bgPic" } },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.bgPic
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "bgPic")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v("建议尺寸：580*200px")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类描述：", prop: "description" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    staticStyle: { "line-height": "1" },
                    attrs: {
                      autosize: { minRows: 4, maxRows: 6 },
                      type: "textarea",
                      maxlength: "25",
                      "show-word-limit": "",
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.ruleForm.description,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "description",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.visible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("\n        确 定\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }