"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _mem = require("@/api/mem");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  //		props: {
  //			cardId: {
  //				type: Number,
  //			}
  //		},
  data: function data() {
    return {
      cardId: '',
      imgSrc: this.$store.state.user.imgSrc,
      sysName: '',
      name: '',
      uploadData: {
        file_key: 'file',
        business_id: ''
      },
      tableData: [],
      options: [{
        value: '选项1',
        label: '每行3个图标（总数6个/9个）'
      }, {
        value: '选项2',
        label: '每行4个图标（总数8个/12个）'
      }, {
        value: '选项3',
        label: '每行5个图标（总数10个/15个）'
      }],
      value: ''
    };
  },
  created: function created() {
    this.cardId = Number(this.$route.query.id);
    this.getEditor();
  },
  methods: {
    getIndex: function getIndex(scope) {
      this.img = scope.$index;
    },
    handleSucess: function handleSucess(response, file, fileList) {
      if (response.ret === 1000) {
        this.tableData[this.img].haveImg = response.data;
      }
    },
    handleSucess2: function handleSucess2(response, file, fileList) {
      if (response.ret === 1000) {
        this.tableData[this.img].unhaveImg = response.data;
      }
    },
    setTab: function setTab() {
      var _this = this;

      console.log(this.tableData);
      var show = true;
      this.tableData.forEach(function (item, key) {
        if (item.haveImg === '' || item.unhaveImg === '' || item.name === '' || item.remark === '' || item.sort === '') {
          _this.$message.error('请上完善信息');

          show = false;
          return;
        } else {
          show = true;
        }
      });

      if (show) {
        (0, _mem.editCardMemeberEquities)(this.tableData).then(function (res) {
          _this.$message({
            message: '恭喜你，保存成功',
            type: 'success'
          });

          _this.$router.go(-1);
        });
      }
    },
    getEditor: function getEditor() {
      var _this2 = this;

      (0, _mem.listCardMemeberEquities)(this.cardId).then(function (res) {
        console.log(res);

        if (res.ret === 1000) {
          _this2.tableData = res.data.cardMemeberEquities;
          _this2.cardId = res.data.cardId;
          _this2.sysName = res.data.sysName;
          _this2.name = res.data.name;
        }
      });
    },
    addList: function addList() {
      this.tableData.push({
        remark: '',
        sort: '',
        name: '',
        cardId: this.cardId,
        haveImg: '',
        unhaveImg: ''
      });
    }
  }
};
exports.default = _default;