var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [
        _vm._v("新增/编辑同城生活分类")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "会员类型：", prop: "vipCode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.ruleForm.vipCode,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "vipCode", $$v)
                        },
                        expression: "ruleForm.vipCode"
                      }
                    },
                    _vm._l(_vm.vipList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.vipName, value: item.vipCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类名称：", prop: "className" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.className,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "className",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.className"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类排序：", prop: "classSort" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.classSort,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "classSort",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.classSort"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类状态：", prop: "classStatus" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.classStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "classStatus", $$v)
                        },
                        expression: "ruleForm.classStatus"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.ruleForm.classStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "classStatus", $$v)
                        },
                        expression: "ruleForm.classStatus"
                      }
                    },
                    [_vm._v("停用")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }