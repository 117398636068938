"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.brandOptions = brandOptions;
exports.getErpGoods = getErpGoods;
exports.memberTypeOptions = memberTypeOptions;
exports.receiptSetingDelete = receiptSetingDelete;
exports.receiptUploadCheck = receiptUploadCheck;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取会员类型
function memberTypeOptions() {
  return (0, _request.default)({
    url: '/api/receiptIntegralAllocation/getMemberType',
    method: 'get'
  });
} // 获取品牌类型


function brandOptions() {
  return (0, _request.default)({
    url: '/api/receiptIntegralAllocation/getBrandType',
    method: 'get'
  });
} // 小票上传审核


function receiptUploadCheck(data) {
  return (0, _request.default)({
    url: '/api/payReceiptUpload/auditPayReceiptUpload',
    method: 'post',
    data: data
  });
} // 删除小票配置


function receiptSetingDelete(id) {
  return (0, _request.default)({
    url: '/api/receiptIntegralAllocation/removeReceiptIntegralAllocation/' + id,
    method: 'get'
  });
} // 获取昂捷商品码


function getErpGoods(storeCode, contractNo) {
  return (0, _request.default)({
    url: "/api/selectGcode/".concat(storeCode, "/").concat(contractNo),
    method: 'get'
  });
}