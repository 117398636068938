var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      !_vm.loading
        ? _c(
            "w-table",
            {
              ref: "wTable",
              attrs: {
                size: "mini",
                isShowTitle: false,
                columns: _vm.columns,
                "search-data": _vm.searchData
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      on: { change: _vm.changeType },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("优惠券")
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("购物卡")
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: 3 } }, [
                        _vm._v("积分商品")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.setMenuPerms("merchant:goods:add")
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "pageBtn",
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: {
                        click: function($event) {
                          return _vm.wPage(
                            "/external/select?id=" + _vm.merchantId
                          )
                        }
                      },
                      slot: "pageBtn"
                    },
                    [_vm._v("添加商品")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "validTime",
                  label: "有效期",
                  align: "center",
                  width: "160"
                },
                slot: "validTime",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.goodsInfo &&
                          scope.row.goodsInfo.validTimeType == 1
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      scope.row.goodsInfo.validBeginTime +
                                        "至" +
                                        scope.row.goodsInfo.validEndTime
                                    ) +
                                    "\n        "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.goodsInfo &&
                          scope.row.goodsInfo.validTimeType == 2
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      "领取后" +
                                        (scope.row.goodsInfo.validStartDay ||
                                          0) +
                                        "天到" +
                                        scope.row.goodsInfo.validDay +
                                        "天内有效"
                                    ) +
                                    "\n        "
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1984729010
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "status",
                  label: "状态",
                  align: "center",
                  width: "80"
                },
                slot: "status",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.goodsInfo
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.couponStatus[
                                        scope.row.goodsInfo.status
                                      ]
                                    )
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3049588864
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "isUse",
                  label: "是否可用",
                  align: "center",
                  width: "80"
                },
                slot: "isUse",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.goodsInfo
                            ? _c("span", [_vm._v(" 可用 ")])
                            : _c("span", [_vm._v("不可用")])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3472326927
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "couponType",
                  label: "类型",
                  align: "center",
                  width: "100"
                },
                slot: "couponType",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.type == 1 && scope.row.goodsInfo
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.couponType[
                                        scope.row.goodsInfo.couponType
                                      ]
                                    ) +
                                    "\n        "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.type == 2 && scope.row.goodsInfo
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      scope.row.goodsInfo.type == 1
                                        ? "购物卡"
                                        : "红包"
                                    ) +
                                    "\n        "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.type == 3 && scope.row.goodsInfo
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.goodsType[
                                        scope.row.goodsInfo.productSource
                                      ]
                                    ) +
                                    "\n        "
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3676740943
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "totalNumber",
                  label: "投放数量",
                  align: "center",
                  width: "100"
                },
                slot: "totalNumber",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.goodsInfo
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      scope.row.goodsInfo.totalNumber > 0
                                        ? scope.row.goodsInfo.totalNumber
                                        : "不限制"
                                    )
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3561688318
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "limitNumber",
                  label: "限购数量",
                  align: "center",
                  width: "100"
                },
                slot: "limitNumber",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.goodsInfo
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      scope.row.goodsInfo.limitNumber > 0
                                        ? scope.row.goodsInfo.limitNumber
                                        : "不限制"
                                    ) +
                                    "\n        "
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2506781095
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "mainImage",
                  label: _vm.coverData[_vm.type],
                  align: "center",
                  width: "80"
                },
                slot: "mainImage",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.type == 1 && scope.row.goodsInfo
                            ? _c("el-image", {
                                staticStyle: { width: "50px" },
                                attrs: {
                                  src:
                                    _vm.imgSrc + scope.row.goodsInfo.mainImage,
                                  "preview-src-list": [
                                    _vm.imgSrc + scope.row.goodsInfo.mainImage
                                  ]
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.type == 2 && scope.row.goodsInfo
                            ? _c("el-image", {
                                staticStyle: { width: "50px" },
                                attrs: {
                                  src: _vm.imgSrc + scope.row.goodsInfo.cover,
                                  "preview-src-list": [
                                    _vm.imgSrc + scope.row.goodsInfo.cover
                                  ]
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.type == 3 && scope.row.goodsInfo
                            ? _c("el-image", {
                                staticStyle: { width: "50px" },
                                attrs: {
                                  src:
                                    _vm.imgSrc + scope.row.goodsInfo.imageUrl,
                                  "preview-src-list": [
                                    _vm.imgSrc + scope.row.goodsInfo.imageUrl
                                  ]
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4036932981
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "update",
                  label: "操作",
                  align: "center",
                  width: "120"
                },
                slot: "update",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.setMenuPerms("merchant:goods:cancel")
                            ? _c(
                                "el-popconfirm",
                                {
                                  attrs: { title: "确认取消该商品？" },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.goCasancelGoods(scope.row)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                        size: "mini"
                                      },
                                      slot: "reference"
                                    },
                                    [_vm._v("取消选品")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "success",
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.setGoodsSort(scope.row)
                                }
                              }
                            },
                            [_vm._v("修改排序")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  646808139
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }