var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "140px",
            rules: _vm.rules,
            size: "small",
            model: _vm.form
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "父级分类" } },
            [
              _c("el-tree", {
                ref: "treeForm",
                staticClass: "li_input",
                attrs: {
                  "default-expanded-keys": _vm.defaultKeys,
                  "default-checked-keys": _vm.defaultKeys,
                  data: _vm.tableData,
                  "show-checkbox": "",
                  "node-key": "id",
                  props: _vm.defaultProps,
                  "check-on-click-node": true,
                  "check-strictly": true,
                  "expand-on-click-node": false
                },
                on: { "check-change": _vm.handleClick }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "分类名称", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: -1 },
                  model: {
                    value: _vm.form.status,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status"
                  }
                },
                [_vm._v("禁用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.form.status,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status"
                  }
                },
                [_vm._v("启用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示小程序", prop: "showApp" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: -1 },
                  model: {
                    value: _vm.form.showApp,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "showApp", $$v)
                    },
                    expression: "form.showApp"
                  }
                },
                [_vm._v("禁用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.form.showApp,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "showApp", $$v)
                    },
                    expression: "form.showApp"
                  }
                },
                [_vm._v("启用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { type: "number" },
                model: {
                  value: _vm.form.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "max-width": "200px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }