"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.regexp.replace");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _coupon = require("@/api/coupon");

var _mem = require("@/api/mem.js");

var _coupon2 = _interopRequireDefault(require("@/common/coupon.js"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _cardLoad = _interopRequireDefault(require("@/components/addLoad/cardLoad.vue"));

var _detailCtmfield = _interopRequireDefault(require("@/components/addLoad/detailCtmfield.vue"));

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _checkTree = _interopRequireDefault(require("@/components/checkTree"));

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var validateAcquaintance = function validateAcquaintance(rule, value, callback) {
  if (value < 0 || value > 100) {
    callback(new Error('在 0 至 100 %之间'));
  } else {
    callback();
  }
};

var validateNumber = function validateNumber(rule, value, callback) {
  if (/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value) === false) {
    callback(new Error('请填写大于等于0的数字'));
  } else {
    callback();
  }
};

var validateNum = function validateNum(rule, value, callback) {
  if (value !== '') {
    if (/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value) === false) {
      callback(new Error('请填写大于0的数字'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

var _default = {
  components: {
    addLoad: _addLoad.default,
    cardLoad: _cardLoad.default,
    detailCtm: _detailCtmfield.default,
    checkTree: _checkTree.default,
    Tinymce: _Tinymce.default,
    UploadExcelComponent: _index.default
  },
  data: function data() {
    var _this = this;

    var _self = this;

    var validateGoodsIds = function validateGoodsIds(rele, value, callback) {
      console.log(value, _this.obj.goodsIds1);

      if ((!value || value.length == 0) && (!_this.obj.goodsIds1 || _this.obj.goodsIds1.length === 0)) {
        callback(new Error('百货商品码和超市商品码必须上传一个'));
      } else {
        callback();
      }
    };

    return {
      classList: [],
      userLabelList: [],
      whiteList: [],
      // 白名单列表
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.obj.validBeginTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      copyType: '',
      urlTypeList: ['coupon', 'activity', 'card', 'vote', 'tem', 'goods', 'pond', 'storeTem', 'auto', 'storeMap'],
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/receivedCodes.xlsx"),
      viewShow: false,
      storeList: [],
      arrNode: [],
      imgSrc: this.$store.state.user.imgSrc,
      detailCtmfieldShow: false,
      detailRollimgShow: false,

      /*
        this.salestypesWeekData.price = item.price
        this.salestypesWeekData.priceMem = item.priceMem
        this.salestypesWeekData.deductiblePrice = item.deductiblePrice
         */
      salestypesWeekData: {
        price: undefined,
        priceMem: undefined,
        deductiblePrice: null
      },
      salestypesWeek: null,
      rules: {
        labelContent: [{
          required: true,
          message: '请选择会员标签'
        }],
        merchantType: [{
          required: true,
          message: '是否商户券'
        }],
        mainImage: [{
          required: true,
          message: '请上传优惠券主图'
        }],
        detailRollimg: [{
          required: true,
          message: '请上传优惠券滚动图'
        }],
        costType: [{
          required: true,
          message: '请选择费用出资方'
        }],
        ratioCompany: [{
          required: true,
          message: '请输入公司承担比例'
        }, {
          validator: validateAcquaintance,
          message: '公司承担比例为0到100'
        }],
        ratioTenant: [{
          required: true,
          message: '请输入商户承担比例'
        }, {
          validator: validateAcquaintance,
          message: '商户承担比例为0到100'
        }],
        name: [{
          required: true,
          message: '请输入优惠券名称'
        }],
        descrition: [{
          required: true,
          message: '请输入优惠券描述'
        }],
        categoryId: [{
          required: true,
          message: '请选择优惠券分类'
        }],
        shortTitle: [{
          required: true,
          message: '请填写优惠券短标题'
        }, {
          max: 12,
          message: '长度在 12 个字符内'
        }],
        salestypes: [{
          required: true,
          message: '请选择销售价格'
        }],
        listShow: [{
          required: true,
          message: '请选择列表显示价格'
        }],
        stockPrice: [{
          required: true,
          message: '请输入进货价'
        }],
        supplier: [{
          required: true,
          message: '请输入供应商'
        }],
        supplierMobile: [{
          required: true,
          message: '请输入供应商手机号码'
        }],
        receivedCodes: [{
          required: true,
          message: '请导入外部券码'
        }],
        costStoreId: [{
          required: true,
          message: '请选择费用承担门店'
        }],
        shareImg: [{
          required: true,
          message: '请上传分享图片'
        }],
        shareTitle: [{
          required: true,
          message: '请输入分享标题'
        }],
        isTohome: [{
          required: true,
          message: '请选择是否到家'
        }],
        isRightsCoupon: [{
          required: true,
          message: '请选择是否权益券'
        }],
        verificationModel: [{
          required: true,
          message: '请选择核销方式'
        }],
        showStatus: [{
          required: true,
          message: '请选择显示状态'
        }],
        denomination: [{
          required: true,
          message: '请输入优惠券面额'
        }, {
          validator: validateNum
        }],
        doorsillMoney: [{
          required: true,
          message: '请输入门槛金额'
        }, {
          validator: validateNumber
        }],
        discount: [{
          required: true,
          message: '请输入优惠券折扣'
        }],
        cappingMoney: [{
          required: true,
          message: '请输入优惠券折扣封顶金额'
        }],
        experienceNumber: [{
          required: true,
          message: '请输入优惠券体验次数'
        }],
        instructions: [{
          required: true,
          message: '请输入使用规则'
        }],
        geDetails: [{
          required: true,
          message: '请输入内容详情'
        }],
        validTimeType: [{
          required: true,
          message: '请选择优惠券有效期类型'
        }],
        validBeginTime: [{
          required: true,
          message: '请选择优惠券开始时间'
        }],
        validEndTime: [{
          required: true,
          message: '请选择优惠券结束时间'
        }],
        validDay: [{
          required: true,
          message: '请输入领取多少天后有效'
        }, {
          validator: validateNumber
        }],
        validStartDay: [{
          required: true,
          message: '请输入领取多少天后有效'
        }],
        totalNumber: [{
          required: true,
          message: '请输入投放数量'
        }, {
          validator: validateNumber
        }],
        limitNumber: [{
          required: true,
          message: '请输入限领/限购数量'
        }, {
          validator: validateNum
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 1) {
              callback(new Error('请输入大于等于1的值'));
            }

            if (_this.obj.salestypes == 4 && value > 10) {
              callback(new Error('免费领取的优惠券最多限领10张'));
            } else {
              callback();
            }
          }
        }],
        limitGoods: [{
          required: true,
          message: '请输入限购商品数量'
        }],
        overlayNumber: [{
          required: true,
          message: '请输入叠加数量'
        }, {
          validator: validateNumber
        }],
        rejectRule: [{
          required: true,
          message: '请选择排斥规则'
        }],
        institutionIds: [{
          required: true,
          message: '请选择会员机构'
        }],
        goodsIds: [{
          validator: validateGoodsIds
        }],
        customerType: [{
          required: true,
          message: '请选择是否专属券'
        }],
        stepType: [{
          required: true,
          message: '请选择使用规则'
        }],
        ifDonation: [{
          required: true,
          message: '请选择是否可转赠'
        }],
        rejectVipPrice: [{
          required: true,
          message: '请选择是否参与会员价'
        }],
        ifShare: [{
          required: true,
          message: '请选择优惠券是否可分享'
        }],
        isShow: [{
          required: true,
          message: '请选择是否显示到大屏'
        }],
        openSale: [{
          required: true,
          message: '请选择是否开放购买'
        }]
      },
      addPhone: '',
      obj: {
        whiteId: null,
        // 白名单ID
        labelContent: null,
        rejectVipPrice: -1,
        ifDonation: null,
        isShow: null,

        /* merchantType  0:非商户券  1：商户券（乐购使用）*/
        stepType: null,
        merchantType: 0,
        customerType: 0,
        detailCtmfield: [],
        buyTypes: [{
          label: '免费',
          type: 4,
          price: '',
          integral: ''
        }, {
          label: '金额',
          type: 1,
          price: undefined,
          integral: '',
          priceMem: undefined,
          integralMem: null,
          deductiblePrice: null
        }, {
          label: '积分',
          type: 2,
          price: '',
          integral: undefined,
          priceMem: undefined,
          integralMem: null
        }, {
          label: '金额+积分',
          type: 3,
          price: '',
          integral: undefined,
          priceMem: undefined,
          integralMem: null
        }],
        detailRollimg: [],
        tradeNoticeMobile: [],
        openSale: null,
        mainImage: '',
        //	优惠券主图
        costType: null,
        // 费用类型
        ratioCompany: null,
        // 费用比率(公司)
        ratioTenant: null,
        //	费用比率(商户)
        salestypes: null,
        listShow: null,
        stockPrice: '',
        // 进货价
        supplier: '',
        //  供应商
        supplierMobile: '',
        // 供应商手机号码
        receivedCodes: [],
        costStoreId: '',
        id: '',
        shareImg: '',
        shareTitle: '',
        isTohome: '',
        // 是否到家
        isRightsCoupon: '',
        // 是否权益券
        verificationModel: [],
        // 核销方式(1：PC收银机 2：手持收银机 3：核销小助手)
        showStatus: '',
        name: null,
        // g	优惠券名称
        shortTitle: null,
        // 短标题
        categoryId: null,
        descrition: null,
        // 优惠券描述
        couponType: 1,
        //	优惠券类型(1-代金券，2-折扣券，3-礼品券，4-体验券，5-外部优惠券)
        denomination: null,
        //		优惠券面额
        buyType: 1,
        //		购买方式(1-金额，2-积分，3-金额+积分)
        price: null,
        //			金额
        integral: null,
        //			积分
        doorsillMoney: null,
        //			门槛金额(消费达多少可用)
        discount: null,
        //			优惠折扣
        cappingMoney: null,
        //			折扣封顶金额
        experienceNumber: null,
        //			体验次数
        geDetails: null,
        //			礼品/体验项目 详情
        validTimeType: 1,
        //		有效时间类型(1-时间段，2-领取后N天内有效)
        validBeginTime: null,
        //			有效开始时间
        validEndTime: null,
        //			有效结束时间
        validDay: null,
        //		有效天数
        validStartDay: null,
        beginDate: [],
        // 有效期
        limitNumber: null,
        //			限领数量(0为无限领)
        totalNumber: null,
        //			总数量(0为不限数量)
        memberIds: [],
        //			会员卡id
        institutionIds: [],
        //			机构id
        usableType: 1,
        //			可用类型(1-可用，2-不可用)
        goodsIds: [],
        //			商品id数组
        goodsIds1: [],
        rejectRule: 1,
        //			排斥规则(1-不与其他优惠券同时使用，2-可与其他优惠券同时使用)
        instructions: null,
        //			使用说明
        overlayNumber: null,
        //		叠加数量(0为不可叠加)
        limitGoods: null,
        //		 限购商品数量(0为不限商品数量)
        treeArr: [],
        // 树形机构
        ifShare: null
      },
      volumeTit: '新增优惠券',
      verificationModelArr: _coupon2.default.verificationModelArr,
      showStatusArr: _coupon2.default.showStatusArr,
      id: '',
      img: require('@/assets/coupon/u1374.png'),
      options: [{
        value: 1,
        label: 'A类券-集团公司承担'
      }, {
        value: 2,
        label: 'B类券-专柜/品牌/商户承担'
      }, {
        value: 3,
        label: 'C类券-集团与商户按比例承担'
      }],
      treeArr: []
    };
  },
  created: function created() {
    var _this2 = this;

    /* this.apiPost('/api/coupon-category/page',{
        pageNo:1,
        pageSize:100
      }).then((res)=>{
        this.classList=res.data.records
      }) */
    (0, _user.listMemberCardInstitutionInfoNodes)().then(function (res) {
      _this2.obj.treeArr = res.data;
      /* .map((item)=>{
           const children=item.childNodes.map((row)=>{
             return {
               code:row.code,
               fullName:row.fullName,
               id:row.id,
               name:row.name,
               parentId:row.parentId
             }
           })
           return {
             ...item,
             childNodes:children
           }
         })
         console.log(treeArr)
         this.obj.treeArr=treeArr */
    });
    (0, _mem.listStore)({}).then(function (res) {
      _this2.storeList = res.data;
    });
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    goDelPhone: function goDelPhone(phone, index) {
      var _this3 = this;

      var _self = this;

      this.$confirm("\u53D6\u4EBA\u5220\u9664\u77ED\u4FE1\u901A\u77E5".concat(phone), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _self.obj.tradeNoticeMobile.splice(index, 1);

        _this3.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(function () {
        _this3.$message({
          type: 'danger',
          message: '删除失败!'
        });
      });
    },
    addUserPhone: function addUserPhone() {
      if (this.addPhone.length != 11) {
        this.$message.error('请输入11位手机号码');
        return;
      }

      if (this.obj.tradeNoticeMobile.length > 1) {
        this.$message.error('最多可添加两个手机号');
        return;
      }

      if (this.obj.tradeNoticeMobile.includes(this.addPhone)) {
        this.$message.error('不能重复添加手机号');
        return;
      }

      this.obj.tradeNoticeMobile.push(this.addPhone);
    },
    costTypeChange: function costTypeChange(e) {
      var ratios = {
        1: {
          company: 100,
          tenant: 0
        },
        2: {
          company: 0,
          tenant: 100
        }
      };

      if (ratios[e]) {
        this.obj.ratioCompany = ratios[e].company;
        this.obj.ratioTenant = ratios[e].tenant;
      }
    },
    costCompanyInput: function costCompanyInput(e) {
      this.obj.ratioTenant = 100 - this.obj.ratioCompany;
    },
    costMerchantInput: function costMerchantInput(e) {
      this.obj.ratioCompany = 100 - this.obj.ratioTenant;
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      results.forEach(function (data) {
        if (data.receivedCode) {
          arr.push({
            receivedCode: data.receivedCode,
            receivedPwd: data.receivedPwd
          });
        }
      });
      this.obj.receivedCodes = arr;
      this.obj.totalNumber = arr.length;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 5;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过5M的文件',
        type: 'warning'
      });
      return false;
    },
    buTypeChange: function buTypeChange(e) {
      console.log(e);

      if (e) {
        this.obj.listShow = e;
      }
    },
    setViewShow: function setViewShow() {
      this.viewShow = !this.viewShow;
    },
    setTime: function setTime(e) {
      if (e) {
        this.beginDate = [e[0].substring(0, 10), e[1].substring(0, 10)];
      } else {
        this.beginDate = ['', ''];
      }
    },
    addDetailRollimg: function addDetailRollimg() {
      this.detailRollimgShow = true;
    },
    setDetailRollimg: function setDetailRollimg(arr) {
      this.detailRollimgShow = false;
      this.obj.detailRollimg = arr;

      if (arr.length > 0) {
        this.$refs.form.clearValidate('detailRollimg');
      } // this.detailRollimgShow = false
      // this.detailRollimg = arr

    },
    setdetailCtmfield: function setdetailCtmfield(arr) {
      this.detailCtmfieldShow = false;
      this.obj.detailCtmfield = arr;
    },
    delGoodsItem: function delGoodsItem(index, isSupermarket) {
      if (isSupermarket) {
        this.obj.goodsIds1.splice(index, 1);
      } else {
        this.obj.goodsIds.splice(index, 1);
      }
    },
    setTable: function setTable(institutionIds, memberIds, usableType, goodsIds, goodsIds1, arrNode) {
      this.obj.memberIds = memberIds;
      this.obj.institutionIds = institutionIds;
      this.obj.usableType = usableType;
      this.obj.goodsIds = goodsIds;
      this.obj.goodsIds1 = goodsIds1;
      this.arrNode = arrNode;
    },
    setGoods: function setGoods(goodsIds, color, isSupermarket) {
      this.obj.usableType = color;

      if (isSupermarket) {
        this.obj.goodsIds1 = goodsIds;
      } else {
        this.obj.goodsIds = goodsIds;
      }
    },
    init: function init() {
      var _this4 = this;

      this.obj.id = Number(this.$route.query.id);
      this.obj.couponType = Number(this.$route.query.couponType);
      this.copyType = this.$route.query.type;

      if (this.obj.id) {
        var couponTypes = ['', '代金券', '折扣券', '礼品券', '体验券', '外部优惠券'];
        this.volumeTit = "\u7F16\u8F91".concat(couponTypes[this.obj.couponType]);
        (0, _coupon.getCouponById)(this.obj.id).then(function (res) {
          _this4.obj.ifShare = res.data.ifShare || null;
          _this4.obj.rejectVipPrice = res.data.rejectVipPrice || '';
          _this4.obj.ifDonation = res.data.ifDonation || null;
          _this4.obj.stockPrice = res.data.stockPrice || '';
          _this4.obj.supplier = res.data.supplier || '';
          _this4.obj.supplierMobile = res.data.supplierMobile || '';
          _this4.obj.customerType = res.data.customerType || 0;
          _this4.obj.merchantType = res.data.merchantType || 0;
          _this4.obj.stepType = res.data.stepType || 1;
          _this4.obj.isShow = res.data.isShow || 0;
          _this4.obj.shortTitle = res.data.shortTitle || '';
          _this4.obj.categoryId = res.data.categoryId || '';
          _this4.obj.costType = res.data.costType;
          _this4.obj.costStoreId = res.data.costStoreId;
          _this4.obj.isRightsCoupon = res.data.isRightsCoupon;
          var tradeNoticeMobile = res.data.tradeNoticeMobile || [];
          _this4.obj.tradeNoticeMobile = tradeNoticeMobile.map(function (item) {
            return item.mobie;
          });
          _this4.obj.openSale = res.data.openSale || [];
          var salestypes = res.data.salestypes || [];
          salestypes.forEach(function (item) {
            if (item.listShow === 1) {
              _this4.obj.listShow = item.buyType;
              _this4.obj.salestypes = item.buyType;
            }

            _this4.obj.buyTypes.forEach(function (row) {
              if (item.buyType === row.type) {
                row.price = item.price;
                row.integral = item.integral;
                row.priceMem = item.priceMem;
                row.integralMem = item.integralMem;
                row.deductiblePrice = item.deductiblePrice;
              }
            });

            if (item.buyType == 5) {
              _this4.salestypesWeek = 5;
              _this4.salestypesWeekData.price = item.price;
              _this4.salestypesWeekData.priceMem = item.priceMem;
              _this4.salestypesWeekData.deductiblePrice = item.deductiblePrice;
            }
          });
          _this4.obj.ratioCompany = res.data.ratioCompany;
          _this4.obj.ratioTenant = res.data.ratioTenant;

          if (_this4.obj.couponType === 3) {
            _this4.obj.isTohome = res.data.isTohome;
          }

          if (_this4.obj.couponType === 5) {
            res.data.couponRecodes.forEach(function (item) {
              _this4.obj.receivedCodes.push({
                receivedCode: item.receivedCode,
                receivedPwd: item.receivedPwd || ''
              });
            });
          }

          _this4.obj.detailRollimg = res.data.detailRollimg ? JSON.parse(res.data.detailRollimg) : [];
          _this4.obj.detailCtmfield = res.data.detailCtmfield ? JSON.parse(res.data.detailCtmfield) : [];
          _this4.obj.shareImg = res.data.shareImg || '';
          _this4.obj.shareTitle = res.data.shareTitle || '';
          _this4.obj.showStatus = res.data.showStatus;
          _this4.obj.verificationModel = res.data.verificationModel ? JSON.parse(res.data.verificationModel) : [];
          _this4.obj.couponType = res.data.couponType;
          _this4.obj.name = res.data.name;
          _this4.obj.buyType = res.data.buyType;
          _this4.obj.descrition = res.data.descrition;
          _this4.obj.doorsillMoney = res.data.doorsillMoney;
          _this4.obj.mainImage = res.data.mainImage;
          _this4.obj.price = res.data.price;
          _this4.obj.totalNumber = res.data.totalNumber;
          _this4.obj.limitNumber = res.data.limitNumber;
          _this4.obj.denomination = res.data.denomination;
          _this4.obj.validTimeType = res.data.validTimeType;
          _this4.obj.overlayNumber = res.data.overlayNumber;
          _this4.obj.limitGoods = res.data.limitGoods;
          _this4.obj.validBeginTime = res.data.validBeginTime;
          _this4.obj.validEndTime = res.data.validEndTime;
          _this4.obj.validDay = res.data.validDay;
          _this4.obj.validStartDay = res.data.validStartDay;
          _this4.obj.instructions = res.data.instructions;
          _this4.obj.memberIds = res.data.memberIds;
          _this4.obj.usableType = res.data.usableType;
          _this4.obj.goodsIds = JSON.parse(res.data.goodsIds);
          _this4.obj.goodsIds1 = res.data.goodsIds1 && res.data.goodsIds1 != 'null' ? JSON.parse(res.data.goodsIds1) : [];
          _this4.obj.rejectRule = res.data.rejectRule;
          _this4.obj.integral = res.data.integral;
          _this4.obj.discount = res.data.discount;
          _this4.obj.cappingMoney = res.data.cappingMoney;
          _this4.obj.experienceNumber = res.data.experienceNumber;
          _this4.obj.geDetails = res.data.geDetails;
          _this4.obj.whiteId = res.data.whiteId;
          _this4.obj.labelContent = res.data.labelContent; //  this.arrNode=res.data.institutionNames

          var arrNode = res.data.institutionNames || [];
          arrNode.forEach(function (item) {
            _this4.arrNode.push({
              name: item
            });
          });
          _this4.obj.institutionIds = JSON.parse(res.data.institutionIds); //  this.$refs.myTree.checkChange()
        });
      } else {
        var couponTypeData = {
          1: {
            title: '新增代金券',
            img: 'mika/pro/2019-08-12/995234b7cd5148f1bb7aaf573d8cdd99.jpg'
          },
          2: {
            title: '新增折扣券',
            img: 'mika/pro/2019-08-12/2a777322d6ab45b09577953087c710fb.jpg'
          },
          3: {
            title: '新增礼品券',
            img: 'mika/pro/2019-08-12/b7b9cc838b954178a3fb79c3b6637b1e.jpg'
          },
          4: {
            title: '新增体验券',
            img: 'mika/pro/2019-08-12/16b617e1169b4dd0bc226a661ee3c269.jpg'
          },
          5: {
            title: '新增外部优惠券',
            img: 'mika/pro/2019-08-12/16b617e1169b4dd0bc226a661ee3c269.jpg'
          }
        };
        var targetData = couponTypeData[this.obj.couponType];

        if (targetData) {
          this.volumeTit = targetData.title;
          this.obj.mainImage = targetData.img;
        }
      }

      this.getWhiteList();
      this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
        _this4.userLabelList = [{
          id: 0,
          labelName: '所有会员'
        }].concat((0, _toConsumableArray2.default)(res.data));
      });
    },
    getWhiteList: function getWhiteList(e) {
      var _this5 = this;

      this.apiPost('/api/whiteList/listWhiteListPage', {
        status: 1,
        pageNo: 1,
        pageSize: 100,
        name: e
      }).then(function (res) {
        _this5.whiteList = res.data.items;
      });
    },
    setCover: function setCover(index) {
      // 会员卡LOGO
      this.obj.mainImage = index;
    },
    setSareImg: function setSareImg(cover) {
      this.obj.shareImg = cover;
    },
    submitForm: function submitForm(formName) {
      var _this6 = this;

      this.$refs.myTree.checkChange();
      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          var goodsIdsErr = _this6.obj.goodsIds.filter(function (item) {
            return !/^[0-9]*$/.test(item) || !item;
          });

          if (goodsIdsErr.length > 0) {
            _this6.$alert(goodsIdsErr, '错误商品码，修改后重新上传', {
              confirmButtonText: '确定',
              callback: function callback(action) {
                _this6.$message({
                  type: 'info',
                  message: "action: ".concat(action)
                });
              }
            });

            return;
          }

          var salestypes = [];

          var detailRollimg = _this6.$refs.detailImg.setDetailRollimg('parent');

          _this6.obj.buyTypes.forEach(function (row) {
            if (_this6.obj.salestypes === row.type) {
              var obj = {
                buyType: _this6.obj.salestypes,
                price: row.price || 0,
                integral: row.integral || 0,
                priceMem: row.priceMem,
                integralMem: row.integralMem,
                deductiblePrice: row.deductiblePrice,
                listShow: 1
              };
              salestypes.push(obj);
            }
          });

          if (_this6.salestypesWeek == 5) {
            salestypes.push({
              buyType: 5,
              price: _this6.salestypesWeekData.price || 0,
              integral: _this6.salestypesWeekData.integral || 0,
              priceMem: _this6.salestypesWeekData.priceMem,
              deductiblePrice: _this6.salestypesWeekData.deductiblePrice
            });
          }
          /* const goodsIds = this.obj.goodsIds.map((item) => {
              return item.indexOf(',') > 0 ? item.slice(0, item.length - 1) : item
            }) */


          var _data = {
            salestypes: salestypes,
            doorsillMoney: _this6.obj.doorsillMoney,
            limitNumber: _this6.obj.limitNumber,
            totalNumber: _this6.obj.totalNumber,
            denomination: _this6.obj.denomination,
            detailRollimg: JSON.stringify(detailRollimg),
            detailCtmfield: JSON.stringify(_this6.obj.detailCtmfield),
            costType: _this6.obj.costType,
            couponType: _this6.obj.couponType,
            descrition: _this6.obj.descrition,
            costStoreId: _this6.obj.costStoreId,
            geDetails: _this6.obj.geDetails,
            goodsIds: _this6.obj.goodsIds,
            goodsIds1: _this6.obj.goodsIds1,
            institutionIds: _this6.obj.institutionIds,
            instructions: _this6.obj.instructions,
            limitGoods: _this6.obj.limitGoods,
            mainImage: _this6.obj.mainImage,
            memberIds: _this6.obj.memberIds,
            name: _this6.obj.name,
            overlayNumber: _this6.obj.overlayNumber,
            ratioCompany: _this6.obj.ratioCompany,
            ratioTenant: _this6.obj.ratioTenant,
            rejectRule: _this6.obj.rejectRule,
            shareImg: _this6.obj.shareImg,
            shareTitle: _this6.obj.shareTitle,
            shortTitle: _this6.obj.shortTitle,
            categoryId: _this6.obj.categoryId,
            showStatus: _this6.obj.showStatus,
            usableType: _this6.obj.usableType,
            verificationModel: _this6.obj.verificationModel,
            isRightsCoupon: _this6.obj.isRightsCoupon,
            stockPrice: _this6.obj.stockPrice,
            supplier: _this6.obj.supplier,
            supplierMobile: _this6.obj.supplierMobile,
            discount: _this6.obj.discount,
            cappingMoney: _this6.obj.cappingMoney,
            isTohome: _this6.obj.isTohome,
            experienceNumber: _this6.obj.experienceNumber,
            validTimeType: _this6.obj.validTimeType,
            validDay: _this6.obj.validDay,
            validStartDay: _this6.obj.validStartDay,
            validBeginTime: _this6.obj.validBeginTime,
            validEndTime: _this6.obj.validEndTime,
            receivedCodes: _this6.obj.receivedCodes,
            customerType: _this6.obj.customerType,
            merchantType: _this6.obj.merchantType || 0,
            stepType: _this6.obj.stepType || 1,
            ifDonation: _this6.obj.ifDonation || 2,
            isShow: _this6.obj.isShow,
            rejectVipPrice: _this6.obj.rejectVipPrice || -1,
            ifShare: _this6.obj.ifShare,
            whiteId: _this6.obj.whiteId,
            labelContent: _this6.obj.labelContent,
            tradeNoticeMobile: _this6.obj.tradeNoticeMobile || [],
            openSale: _this6.obj.openSale || '-1'
          };

          if (_this6.obj.id && _this6.copyType != 'copy') {
            _data.id = _this6.obj.id;
            (0, _coupon.editCoupon)(_data).then(function (res) {
              _this6.openLoading().close();

              _this6.$message({
                message: '恭喜你，编辑成功',
                type: 'success'
              });

              _this6.$router.replace('/coupon/coupon1/couponList');
            }).catch(function () {
              _this6.openLoading().close();
            });
          } else {
            (0, _coupon.addCoupon)(_data).then(function (res) {
              _this6.openLoading().close();

              _this6.$message({
                message: '恭喜你，保存成功',
                type: 'success'
              });

              _this6.$router.replace('/coupon/coupon1/couponList');
            }).catch(function () {
              _this6.openLoading().close();
            });
          }
        } else {
          var data = Object.keys(object);
          var dom = null;

          if (data[0].indexOf('buyTypes') >= 0) {
            dom = _this6.$refs['priceIntegral'];
          } else {
            dom = _this6.$refs[data[0]];
          }

          dom.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
        }
      });
    }
  }
};
exports.default = _default;