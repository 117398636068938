var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("修改商品码")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        { ref: "form", attrs: { size: "small", "label-width": "140px" } },
        [
          _c("el-form-item", { attrs: { label: "券码名称：" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.couponName) +
                "(" +
                _vm._s(_vm.uuid) +
                ")\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否可用：" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.usableType,
                    callback: function($$v) {
                      _vm.usableType = $$v
                    },
                    expression: "usableType"
                  }
                },
                [_vm._v("可用商品码")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.usableType,
                    callback: function($$v) {
                      _vm.usableType = $$v
                    },
                    expression: "usableType"
                  }
                },
                [_vm._v("不可用商品码")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "下载模板：" } }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.downloadUrl,
                  download: "MikaLife优惠券商品编码模板.xlsx"
                }
              },
              [
                _c(
                  "el-button",
                  { attrs: { type: "success", icon: "el-icon-download" } },
                  [_vm._v("下载模板")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "百货商品码：" } }, [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "input-with-select",
                    staticStyle: { "max-width": "250px" },
                    attrs: { size: "mini", placeholder: "添加商品码" },
                    model: {
                      value: _vm.addGoods,
                      callback: function($$v) {
                        _vm.addGoods = $$v
                      },
                      expression: "addGoods"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          background: "#409eff !important",
                          color: "#ffffff"
                        },
                        attrs: {
                          slot: "append",
                          size: "mini",
                          type: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.goAddGoods()
                          }
                        },
                        slot: "append"
                      },
                      [_vm._v("添加单个码")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.goodsIds.length > 0
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 20px" },
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-download"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDownload(1)
                          }
                        }
                      },
                      [_vm._v("导出商品码")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("upload-excel-component", {
                  attrs: {
                    size: "mini",
                    "btn-text": "批量上传",
                    type: "btn",
                    "on-success": _vm.handleSuccess,
                    "before-upload": _vm.beforeUpload
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "max-height": "600px",
                  overflow: "auto",
                  padding: "10px",
                  border: "1px solid #cccccc",
                  "margin-top": "10px"
                },
                style: { color: _vm.usableType === 1 ? "#67C23A" : "#F56C6C" }
              },
              _vm._l(_vm.goodsIds, function(item, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.delGoods(item, index)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item) + ",")]
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "超市商品码：" } }, [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "input-with-select",
                    staticStyle: { "max-width": "250px" },
                    attrs: { size: "mini", placeholder: "添加商品码" },
                    model: {
                      value: _vm.addGoods1,
                      callback: function($$v) {
                        _vm.addGoods1 = $$v
                      },
                      expression: "addGoods1"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          background: "#409eff !important",
                          color: "#ffffff"
                        },
                        attrs: {
                          slot: "append",
                          size: "mini",
                          type: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.goAddGoods("isSupermarket")
                          }
                        },
                        slot: "append"
                      },
                      [_vm._v("添加单个码")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.goodsIds1.length > 0
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 20px" },
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-download"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDownload(2)
                          }
                        }
                      },
                      [_vm._v("导出商品码")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("upload-excel-component", {
                  attrs: {
                    size: "mini",
                    "btn-text": "批量上传",
                    type: "btn",
                    "on-success": _vm.handleSuccess1,
                    "before-upload": _vm.beforeUpload
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "max-height": "600px",
                  overflow: "auto",
                  padding: "10px",
                  border: "1px solid #cccccc",
                  "margin-top": "10px"
                }
              },
              _vm._l(_vm.goodsIds1, function(item, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    staticStyle: { cursor: "pointer" },
                    style: {
                      color: _vm.usableType === 1 ? "#67C23A" : "#F56C6C"
                    },
                    on: {
                      click: function($event) {
                        return _vm.delGoods(item, index, "isSupermarket")
                      }
                    }
                  },
                  [_vm._v(_vm._s(item) + ",")]
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "200px" },
                  attrs: {
                    type: "primary",
                    disabled: _vm.goodsIds.length === 0
                  },
                  on: { click: _vm.goUpload }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }