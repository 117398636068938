"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listSiipWrongOrder = listSiipWrongOrder;

var _request = _interopRequireDefault(require("@/utils/request"));

function listSiipWrongOrder(data) {
  return (0, _request.default)({
    url: '/api/siip/listSiipWrongOrder',
    method: 'get',
    loading: 'loading',
    params: data
  });
}