"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var faceRouter = {
  path: '/faceToface',
  component: _layout.default,
  redirect: '/faceToface/faceList',
  name: 'FaceToface',
  meta: {
    title: '面对面发券',
    icon: 'nested'
  },
  children: [{
    path: 'faceList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/faceToface/faceList'));
      });
    },
    name: 'FaceList',
    meta: {
      title: '面对面发券'
    }
  }, {
    path: 'addface',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/faceToface/addface'));
      });
    },
    name: 'Addface',
    meta: {
      title: '新增面对面发券'
    }
  }]
};
var _default = faceRouter;
exports.default = _default;