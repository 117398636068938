"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/typeof"));

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      gradeType: {
        8888: '银卡会员',
        8889: '金卡会员',
        8890: '砖卡会员',
        8891: '白砖卡会员',
        8892: '黑卡会员'
      },
      ruleForm: {
        activityName: null,
        registrationInfoMap: {},
        registrationTime: null,
        userLevel: null,
        userMobile: null,
        username: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id) {
      (0, _api.detailRegistrationLogById)({
        id: this.ruleForm.id
      }).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    getValue: function getValue(item) {
      return (0, _typeof2.default)(item) === 'object' ? item.join('、') : item;
    }
  }
};
exports.default = _default;