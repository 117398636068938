"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("./index"));

var _list = _interopRequireDefault(require("./list"));

var router = {
  name: 'deposit-entity-replace',
  path: 'deposit-entity-replace',
  component: _index.default,
  children: [{
    name: 'depositEntityReplaceList',
    path: 'list',
    component: _list.default,
    meta: {
      title: '储蓄卡更新'
    }
  }]
};
var _default = router;
exports.default = _default;