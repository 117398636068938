module.exports = {
  vipList: [{
    value: 0,
    label: '银卡'
  }, {
    value: 1,
    label: '金卡'
  }, {
    value: 2,
    label: '钻石卡'
  }, {
    value: 3,
    label: '白钻卡'
  }, {
    value: 4,
    label: '黑卡'
  }, {
    value: 5,
    label: '365会员'
  }],
  weekList: [{
    value: 1,
    label: '周一'
  }, {
    value: 2,
    label: '周二'
  }, {
    value: 3,
    label: '周三'
  }, {
    value: 4,
    label: '周四'
  }, {
    value: 5,
    label: '周五'
  }, {
    value: 6,
    label: '周六'
  }, {
    value: 0,
    label: '周日'
  }]
};