"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var touchRouter = {
  path: '/touch',
  component: _layout.default,
  redirect: '/touch/eventList',
  name: 'touch',
  meta: {
    title: '触发营销',
    icon: 'nested'
  },
  children: [{
    path: 'eventList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/touch/event/list'));
      });
    },
    name: 'EventList',
    meta: {
      title: '触发事件'
    }
  }, {
    path: 'eventAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/touch/event/add'));
      });
    },
    name: 'EventAdd',
    hidden: true,
    meta: {
      title: '新增/编辑触发事件'
    }
  }, {
    path: 'rechargeList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/touch/recharge/list'));
      });
    },
    name: 'rechargeList',
    meta: {
      title: '充值有礼'
    }
  }, {
    path: 'rechargeAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/touch/recharge/add'));
      });
    },
    name: 'rechargeAdd',
    hidden: true,
    meta: {
      title: '新增/编辑充值有礼'
    }
  }, {
    path: 'log',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/touch/log/list'));
      });
    },
    name: 'touchLog',
    meta: {
      title: '触发事件日志'
    }
  }, {
    path: 'logDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/touch/log/dea'));
      });
    },
    name: 'touchLogDea',
    hidden: true,
    meta: {
      title: '触发事件日志详情'
    }
  }]
};
var _default = touchRouter;
exports.default = _default;