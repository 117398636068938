"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var publicRouter = {
  path: '/public',
  component: _layout.default,
  redirect: '/public/logList',
  name: 'Public',
  meta: {
    title: '操作日志',
    icon: 'nested'
  },
  children: [{
    path: 'logList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/public/log/list.vue'));
      });
    },
    name: 'LogList',
    meta: {
      title: '操作日志'
    }
  }, {
    path: 'logDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/public/log/dea.vue'));
      });
    },
    name: 'Logdea',
    meta: {
      title: '操作日志'
    }
  }]
};
var _default = publicRouter;
exports.default = _default;