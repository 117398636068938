"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCompany = addCompany;
exports.addParking = addParking;
exports.addStore = addStore;
exports.editCompany = editCompany;
exports.editParking = editParking;
exports.editStore = editStore;
exports.getCompanyById = getCompanyById;
exports.getParking = getParking;
exports.getStoreById = getStoreById;
exports.listCompany = listCompany;
exports.listParkSystem = listParkSystem;
exports.listStorePage = listStorePage;
exports.listSystem = listSystem;

var _request = _interopRequireDefault(require("@/utils/request"));

function listParkSystem(data) {
  return (0, _request.default)({
    url: '/api/storeParking/listParkSystem',
    method: 'post'
  });
}
/*
 新增：/api/storeParking/addParking
 {"storeId":"门店id","parkingId":"停车场id(商户号)","parkingSecret":"停车场秘钥",

 "parkingName":"停车场名称",
 "parkingImgs":"",
 "provinceCode":"省份编码","cityCode":"城市编码","countyCode":"区县编码",
 "detailAddress":"详细地址",
 "longitude":"经度","latitude":"维度",
 "basicCost":"基础收费(元/小时)",
 "freeTime":"免费时长",
 "sameDis":"积分兑和会员价优惠不能同时享受(1：是 0：否)",
 "remark":"备注",
 "parkingPays":[
   {"payType":"支付类型(1：积分兑换 2：会员优惠价)",
   "commonMember":"积分/小时或者折扣(普通会员)",
   "commonLimit":"每日限兑/优惠上限(普通会员)",
   "vipMember":"积分/小时或者折扣(付费会员)",
   "vipLimit":"每日限兑/优惠上限(付费会员)",
   "vipStatus":"停车一小时以上才可享受优惠(1：是 0 ：否)(付费会员)"}]}

 编辑：/api/storeParking/editParking
 {"id":"id","storeId":"门店id","parkingId":"停车场id(商户号)","parkingSecret":"停车场秘钥","parkingName":"停车场名称","parkingImgs_":["",""],"provinceCode":"省份编码","cityCode":"城市编码","countyCode":"区县编码","detailAddress":"详细地址","longitude":"经度","latitude":"维度","basicCost":"基础收费(元/小时)","freeTime":"免费时长","sameDis":"积分兑和会员价优惠不能同时享受(1：是 0：否)","remark":"备注","parkingPays":[{"payType":"支付类型(1：积分兑换 2：会员优惠价)","commonMember":"积分/小时或者折扣(普通会员)","commonLimit":"每日限兑/优惠上限(普通会员)","vipMember":"积分/小时或者折扣(付费会员)","vipLimit":"每日限兑/优惠上限(付费会员)","vipStatus":"停车一小时以上才可享受优惠(1：是 0 ：否)(付费会员)"}]}

 明细：/api/storeParking/getParking/{id}

 */


function addParking(data) {
  return (0, _request.default)({
    url: '/api/storeParking/addParking',
    method: 'post',
    data: data
  });
}

function editParking(data) {
  return (0, _request.default)({
    url: '/api/storeParking/editParking',
    method: 'post',
    data: data
  });
}

function getParking(data) {
  return (0, _request.default)({
    url: '/api/storeParking/getParking/' + data,
    method: 'post'
  });
}
/*
 查询所有会员
 */


function listCompany(data) {
  return (0, _request.default)({
    url: '/api/company/listCompany',
    method: 'post',
    data: data
  });
}
/*
查询所有系统列表
 */


function listSystem(data) {
  return (0, _request.default)({
    url: '/api/company/listSystem',
    method: 'post',
    data: data
  });
}
/*
查询公司
 */


function getCompanyById(id) {
  return (0, _request.default)({
    url: '/api/company/getCompanyById/' + id,
    method: 'post' // data

  });
}
/*
新增公司
 */


function addCompany(data) {
  return (0, _request.default)({
    url: '/api/company/addCompany',
    method: 'post',
    data: data
  });
}
/*
编辑公司
 */


function editCompany(data) {
  return (0, _request.default)({
    url: '/api/company/editCompany',
    method: 'post',
    data: data
  });
}
/*
分店分页
 */


function listStorePage(data) {
  return (0, _request.default)({
    url: '/api/store/listStorePage',
    method: 'post',
    data: data
  });
}
/*
新增门店
 */


function addStore(data) {
  return (0, _request.default)({
    url: '/api/store/addStore',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
编辑门店
 */


function editStore(data) {
  return (0, _request.default)({
    url: '/api/store/editStore',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
门店详情
 */


function getStoreById(data) {
  return (0, _request.default)({
    url: '/api/store/getStoreById/' + data,
    method: 'post'
  });
}
/*
6、分店分页：/api/store/listStorePage
      参数：pageNo; // 当且页
                pageSize; // 页条数
7、新增门店：/api/store/addStore
      参数：companyId; // 公司id
                fullName;  // 门店全称
                shortName;  // 门店简称
                storeCode;  // 门店编码
                storeStatus; // 门店状态(1:开业准备、2:正常营业、3:暂停营业、4:作废)
                provinceCode; // 省份编码
                cityCode;  // 城市编码
                countyCode; // 区县编码
                detailAddress; // 详细地址
                longitude;  // 经度
                latitude;  // 纬度
                contactPeople; // 联系人
                contactPhone; // 联系电话
8、编辑门店：/api/store/addStore
      参数：storeId;  // id
                companyId;  // 公司id
                fullName;  // 门店全称
                shortName;  // 门店简称
                storeCode;  // 门店编码
                storeStatus; // 门店状态(1:开业准备、2:正常营业、3:暂停营业、4:作废)
                provinceCode; // 省份编码
                cityCode;  // 城市编码
                countyCode; // 区县编码
                detailAddress; // 详细地址
                longitude;  // 经度
                latitude;  // 纬度
                contactPeople; // 联系人
                contactPhone; // 联系电话
9、查询门店信息：/api/store/getStoreById/{storeId}

 */