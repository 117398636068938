var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "门店名称：", prop: "storeId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择门店"
                      },
                      model: {
                        value: _vm.ruleForm.storeId,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "storeId", $$v)
                        },
                        expression: "ruleForm.storeId"
                      }
                    },
                    _vm._l(_vm.storeList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.storeId,
                          attrs: {
                            label: item.shortName + item.storeCode,
                            value: item.storeCode
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.shortName) +
                              "(" +
                              _vm._s(item.storeCode) +
                              ")\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "门店开票人：", prop: "kpName" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.kpName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "kpName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.kpName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "收款人：", prop: "skrName" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.skrName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "skrName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.skrName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "复核人：", prop: "fhrName" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.fhrName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "fhrName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.fhrName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }