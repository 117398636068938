"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _integral = require("@/api/integral.js");

var _index = _interopRequireDefault(require("@/components/integral/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var validateAcquaintance = function validateAcquaintance(rule, value, callback) {
  if (value < 0 || value > 100) {
    callback(new Error('在 0 至 100 %之间'));
  } else {
    callback();
  }
};

var _default = {
  components: {
    integral: _index.default
  },
  data: function data() {
    return {
      options: [{
        value: 1,
        label: 'A类-集团公司承担'
      }, {
        value: 2,
        label: 'B类-专柜/品牌/商户承担'
      }, {
        value: 3,
        label: 'C类-集团与商户按比例承担'
      }],
      form: {
        scoreType: '',
        cCcodes: [],
        pinleiArr: [],
        selectData: [],
        multipleSelection: [],
        specialName: '',
        sellAmount: '',
        getScore: '',
        costType: '',
        specialStatus: '',
        companyId: '',
        companyName: '',
        costCompany: '',
        costMerchant: '',
        beginDate: '',
        shortName: [],
        storeArr: [],
        basicRemark: ''
      },
      rules: {
        multipleSelection: [{
          required: true,
          message: '请选择商品'
        }],
        selectData: [{
          required: true,
          message: '请选择品牌'
        }],
        pinleiArr: [{
          required: true,
          message: '请选择品类'
        }],
        sellAmount: [{
          required: true,
          message: '请输入倍数'
        }, {
          type: 'number',
          message: '倍数必须为数字值'
        }],
        getScore: [{
          required: true,
          message: '请输入获得积分'
        }, {
          type: 'number',
          message: '积分必须为数字值'
        }],
        costCompany: [{
          required: true,
          message: '请输公司承担比例'
        },, {
          validator: validateAcquaintance,
          message: '公司承担比例为0到100'
        }],
        costMerchant: [{
          required: true,
          message: '请输商户承担比例'
        },, {
          validator: validateAcquaintance,
          message: '商户承担比例为0到100'
        }],
        specialName: [{
          required: true,
          message: '请填写活动名称'
        }],
        companyId: [{
          required: true,
          message: '请选择公司'
        }],
        specialStatus: [{
          required: true,
          message: '请填写策略状态'
        }],
        shortName: [{
          required: true,
          message: '请选择门店'
        }],
        costType: [{
          required: true,
          message: '请选择费用出资方'
        }],
        beginDate: [{
          required: true,
          message: '请选择时间'
        }, {
          required: true,
          message: '请选择时间',
          trigger: 'blur'
        }],
        type: [{
          type: 'array',
          required: true,
          message: '请至少选择一个活动性质'
        }],
        scoreType: [{
          required: true,
          message: '请选择积分类型'
        }],
        desc: [{
          required: true,
          message: '请填写活动形式'
        }]
      },
      leftList: [{
        label: '',
        minHeight: '80px'
      }, {
        label: '活动名称',
        minHeight: '90px'
      }, {
        label: '策略类型',
        minHeight: '164px'
      }, {
        label: '费用设置',
        minHeight: '162px'
      }, {
        label: '时间设置',
        minHeight: '88px'
      }, {
        label: '状态设置',
        minHeight: '80px'
      }, {
        label: '适用范围',
        minHeight: '80px'
      }],
      specialId: ''
    };
  },
  created: function created() {
    var _this = this;

    this.specialId = Number(this.$route.query.id);

    if (this.specialId) {
      (0, _integral.getScoreSpecial)(this.specialId).then(function (res) {
        // this.$refs.integralRef.companyIdChange(res.data.companyCode)
        _this.$refs.integralRef.setCompanId(res.data.companyCode);

        _this.form.specialName = res.data.specialName;
        _this.form.costType = res.data.costType;
        _this.form.costCompany = res.data.costCompany;
        _this.form.costMerchant = res.data.costMerchant;
        _this.form.companyId = res.data.companyCode;
        _this.form.specialStatus = res.data.specialStatus;
        _this.form.beginDate = [res.data.validStartTime, res.data.validEndTime];
        _this.form.basicRemark = res.data.basicRemark || '';
        _this.form.scoreType = res.data.scoreType;
        _this.form.sellAmount = res.data.sellAmount;
        _this.form.getScore = res.data.getScore; // if (res.data.sellAmount > 0) {
        //   this.form.sellAmount = res.data.sellAmount
        //   this.form.scoreType = 2
        // } else {
        //   this.form.scoreType = 1
        // }

        _this.$refs.integralRef.setCditions(res.data.cditions);
      });
    }
  },
  mounted: function mounted() {
    if (!this.specialId) {
      this.$refs.integralRef.setCompanId();
    }
  },
  methods: {
    submit: function submit(type) {
      this.$refs['form'].validateField(type);
    },
    setStoreId: function setStoreId(arr) {
      this.form.storeArr = arr;
    },
    setcompanyId: function setcompanyId(e, value) {
      this.form.companyId = e;
    },
    costTypeChange: function costTypeChange(e) {
      console.log(e);

      if (e == 1) {
        this.form.costCompany = 100;
        this.form.costMerchant = 0;
      }

      if (e == 2) {
        this.form.costCompany = 0;
        this.form.costMerchant = 100;
      }
    },
    costCompanyInput: function costCompanyInput(e) {
      this.form.costMerchant = 100 - this.form.costCompany;
    },
    costMerchantInput: function costMerchantInput(e) {
      this.form.costCompany = 100 - this.form.costMerchant;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this2.$refs.integralRef.getCditions()) {
            var data = {
              specialName: _this2.form.specialName,
              specialStatus: _this2.form.specialStatus,
              sellAmount: _this2.form.sellAmount || 0,
              companyCode: _this2.form.companyId,
              companyName: _this2.form.companyName,
              validStartTime: _this2.form.beginDate[0],
              validEndTime: _this2.form.beginDate[1],
              costType: _this2.form.costType,
              costCompany: _this2.form.costCompany,
              costMerchant: _this2.form.costMerchant,
              cditions: _this2.$refs.integralRef.getCditions(),
              basicRemark: _this2.form.basicRemark,
              scoreType: _this2.form.scoreType,
              getScore: _this2.form.getScore
            };

            _this2.openLoading();

            if (_this2.specialId) {
              data.specialId = _this2.specialId;
              console.log(JSON.stringify(data));
              (0, _integral.editScoreSpecial)(data).then(function (res) {
                _this2.openLoading().close();

                _this2.$message({
                  type: 'success',
                  message: '编辑成功!'
                });

                _this2.$router.go(-1);
              }).catch(function (err) {
                _this2.openLoading().close();
              });
            } else {
              (0, _integral.addScoreSpecial)(data).then(function (res) {
                _this2.openLoading().close();

                _this2.$message({
                  type: 'success',
                  message: '新增成功!'
                });

                _this2.$router.go(-1);
              }).catch(function (err) {
                _this2.openLoading().close();
              });
            }
          } else {
            _this2.$message.error('商户，品牌，品类必须选一个');
          }
        } else {
          _this2.$message.error('请完善信息后提交');

          return false;
        }
      });
    }
  }
};
exports.default = _default;