var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-manager-form" },
    [
      _c("div", { staticClass: "view_title" }, [
        _vm._v(_vm._s(_vm.id ? "编辑" : "新建") + "荔星伙伴")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-width": "150px",
            model: _vm.ruleForm,
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "企微电话：", prop: "mobile" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { disabled: !!_vm.id, placeholder: "请输入" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchManager($event)
                    }
                  },
                  model: {
                    value: _vm.ruleForm.mobile,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "mobile", $$v)
                    },
                    expression: "ruleForm.mobile"
                  }
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      type: "primary",
                      icon: "el-icon-search"
                    },
                    on: { click: _vm.searchManager },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "伙伴姓名：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { disabled: !_vm.manager, placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "性别：", prop: "sex" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: true },
                  model: {
                    value: _vm.ruleForm.sex,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "sex", $$v)
                    },
                    expression: "ruleForm.sex"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("未知")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("男")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("女")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "工号：", prop: "employeeNo" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { disabled: "", placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.employeeNo,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "employeeNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.employeeNo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "身高：", prop: "height" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { disabled: !_vm.manager, placeholder: "请输入" },
                  model: {
                    value: _vm.ruleForm.height,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "height",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.height"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v(".cm")])],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话：", prop: "contactNumber" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { disabled: !_vm.manager, placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.contactNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "contactNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.contactNumber"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "要客经理编码：", prop: "managerCode" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { disabled: !_vm.manager, placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.managerCode,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "managerCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.managerCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "企业微信二维码：", prop: "qrCode" } },
            [
              _c("el-image", {
                staticStyle: { width: "170px", height: "170px" },
                attrs: { src: _vm.ruleForm.qrCode }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "展示图片：", prop: "avatar" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: {
                  width: 170,
                  height: 170,
                  cover: _vm.ruleForm.avatar,
                  disabled: !_vm.manager
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "avatar")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticStyle: { color: "#999999" } }, [
                _vm._v("建议图片尺寸：340 x 340")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "简介：", prop: "introduction" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                staticStyle: { "line-height": "1" },
                attrs: {
                  autosize: { minRows: 4, maxRows: 6 },
                  disabled: !_vm.manager,
                  type: "textarea",
                  placeholder: "请输入",
                  maxlength: "60",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm.introduction,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "introduction",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.introduction"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.id
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm", "edit")
                        }
                      }
                    },
                    [_vm._v("\n        保存\n      ")]
                  )
                : _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm", "add")
                        }
                      }
                    },
                    [_vm._v("\n        立即新建\n      ")]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }