"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _index2 = _interopRequireDefault(require("@/components/tble/index.vue"));

var _order_express = _interopRequireDefault(require("@/utils/order_express.json"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default,
    wTable: _index2.default
  },
  data: function data() {
    return {
      orderExpress: _order_express.default,
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/orders.xlsx"),
      orders: [],
      orders1: [],
      columns: [{
        label: '订单号',
        prop: 'orderNo',
        width: 100
      }, {
        label: '物流单号',
        prop: 'logisticNo',
        width: 100
      }, {
        label: '物流公司',
        prop: 'logisticName',
        width: 100
      }, {
        label: '物流代码',
        prop: 'logisticName',
        width: 100
      }, {
        slot: 'del'
      }] // <!-- {"orderNo":"订单号","logisticNo":"物流单号","logisticName":"物流名称"} columns -->

    };
  },
  created: function created() {},
  methods: {
    delList: function delList(index) {
      this.orders.splice(index, 1);
    },
    goUpload: function goUpload() {
      var self = this;
      self.$confirm('确认导入, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _api.batchImportLogisticNo)({
          orders: self.orders
        }).then(function (res) {
          self.$message({
            type: 'success',
            message: '导入成功!'
          });
          self.$router.go(-1);
        });
      }).catch(function () {
        self.$message({
          type: 'info',
          message: '已取消导入'
        });
      });
    },
    handleSuccess: function handleSuccess(_ref) {
      var _this = this;

      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      this.orders1 = [];
      console.log(results);
      results.forEach(function (data) {
        if (data.订单号 && data.物流单号 && data.物流公司 && data.物流代码) {
          data.订单号 = String(data.订单号);
          data.物流单号 = String(data.物流单号);
          data.物流公司 = String(data.物流公司);
          data.物流代码 = String(data.物流代码);
          arr.push({
            orderNo: data.订单号.trim(),
            logisticNo: data.物流单号.trim(),
            logisticName: data.物流公司.trim(),
            logisticCode: data.物流代码.trim()
          });
        } else {
          _this.orders1.push({
            orderNo: data.订单号,
            logisticNo: data.物流单号,
            logisticName: data.物流公司,
            logisticCode: data.物流代码
          });
        }
      });
      this.orders = arr;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    }
  }
};
exports.default = _default;