var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { staticClass: "row", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "身份证号",
                      title: "身份证号",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.number,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "number", $$v)
                      },
                      expression: "query.number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "手机号码",
                      title: "手机号码",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.number,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "number", $$v)
                      },
                      expression: "query.number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "原卡号",
                      title: "原卡号",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.number,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "number", $$v)
                      },
                      expression: "query.number"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "btn-box", attrs: { gutter: 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small", plain: "" },
                  on: { click: _vm.onRead }
                },
                [_c("i", { staticClass: "el-icon-cpu" }), _vm._v(" 读磁条卡")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSearch }
                },
                [_c("i", { staticClass: "el-icon-search" }), _vm._v(" 查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.onReset } },
                [
                  _c("i", { staticClass: "el-icon-refresh-right" }),
                  _vm._v(" 重置")
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _c("h4", [_vm._v("原卡信息")])
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row" },
        [
          _c("el-col", { attrs: { span: 6, offset: 1 } }, [
            _c("span", { staticClass: "label" }, [_vm._v("卡号：")])
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 6, offset: 1 } }, [
            _c("span", { staticClass: "label" }, [_vm._v("卡类型：")])
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 6, offset: 1 } }, [
            _c("span", { staticClass: "label" }, [_vm._v("卡名称：")])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row" },
        [
          _c("el-col", { attrs: { span: 6, offset: 1 } }, [
            _c("span", { staticClass: "label" }, [_vm._v("卡状态：")])
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 6, offset: 1 } }, [
            _c("span", { staticClass: "label" }, [_vm._v("卡内余额：")])
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 6, offset: 1 } }, [
            _c("span", { staticClass: "label" }, [_vm._v("过期日期：")])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider", { attrs: { "content-position": "left" } }),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row" },
        [
          _c(
            "el-col",
            { attrs: { span: 20, offset: 1 } },
            [
              _c("span", { staticClass: "label" }, [_vm._v("备注信息：")]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: {
                  width: "calc(100% - 140px)",
                  "margin-left": "124px",
                  "margin-top": "-10px"
                },
                attrs: { type: "textarea", placeholder: "" },
                model: {
                  value: _vm.query.text,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "text", $$v)
                  },
                  expression: "query.text"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row" },
        [
          _c(
            "el-col",
            { attrs: { span: 20, offset: 1 } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "124px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onCancel }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSave }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }