"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '会员价',
      list: [{
        tit: '会员价-基础方案',
        txtArr: '<p>各组织机构会员价基础会员价方案</p>无特殊方案生效时执行（长期）',
        imgPath: require('@/assets/tab/13.png'),
        path: '/member/price/base'
      }, {
        tit: '会员价-活动方案',
        txtArr: '<p>最高优先级执行方案</p> 配置活动期间会员价（限制时间段）   ',
        imgPath: require('@/assets/tab/31.png'),
        path: '/member/price/activity'
      }, {
        tit: '商品-基础方案',
        txtArr: '<p>各组织机构会员价基础会员价方案</p>无特殊方案生效时执行（长期）',
        imgPath: require('@/assets/tab/13.png'),
        path: '/member/price/goods'
      }, {
        tit: '商品-活动方案',
        txtArr: '<p>最高优先级执行方案</p> 配置活动期间会员价（限制时间段）',
        imgPath: require('@/assets/tab/31.png'),
        path: '/member/price/activiTyGoods'
      }]
    };
  }
};
exports.default = _default;