"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _default = [{
  path: 'no-transaction-flow/tab',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/finance/no-transaction-flow/tab'));
    });
  },
  name: 'noTransactionFlowEnterpriseDetailstab',
  meta: {
    title: '无流水开票'
  }
}, {
  path: 'no-transaction-flow/enterprise',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/finance/no-transaction-flow/enterprise'));
    });
  },
  name: 'noTransactionFlowEnterprise',
  meta: {
    title: '经营主体企业信息管理',
    keepAlive: true,
    addUrl: '/finance/no-transaction-flow/enterprise/details',
    activeMenu: '/finance/no-transaction-flow/tab'
  }
}, {
  path: 'no-transaction-flow/enterprise/details',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/finance/no-transaction-flow/enterprise/details'));
    });
  },
  name: 'noTransactionFlowEnterpriseDetails',
  meta: {
    title: '新增/编辑税率管理',
    activeMenu: '/finance/no-transaction-flow/tab'
  }
}, {
  path: 'no-transaction-flow/invoice',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/finance/no-transaction-flow/invoice'));
    });
  },
  name: 'noTransactionFlowInvoice',
  meta: {
    title: '无流水开票',
    keepAlive: true,
    pageList: 'records',
    pageTotal: 'total',
    addTxt: '新增开票',
    addUrl: '/finance/no-transaction-flow/invoice/details',
    activeMenu: '/finance/no-transaction-flow/tab'
  }
}, {
  path: 'no-transaction-flow/invoice/details',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/finance/no-transaction-flow/invoice/details'));
    });
  },
  name: 'noTransactionFlowInvoiceDetails',
  meta: {
    title: '新增/审核开票',
    activeMenu: '/finance/no-transaction-flow/tab'
  }
}, {
  path: 'no-transaction-flow/limit',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/finance/no-transaction-flow/limit'));
    });
  },
  name: 'noTransactionFlowLimit',
  meta: {
    title: '发票项目剩余额度',
    activeMenu: '/finance/no-transaction-flow/tab',
    keepAlive: true,
    isOneRequest: 'no',
    pageList: "items",
    pageTotal: 'totalNum'
  }
}];
exports.default = _default;