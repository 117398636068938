"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _orient = _interopRequireDefault(require("./orient"));

var _touch = _interopRequireDefault(require("./touch"));

var _volume = _interopRequireDefault(require("./volume"));

//
//
//
//
//
//
var _default = {
  components: {
    orient: _orient.default,
    volume: _volume.default,
    touch: _touch.default
  },
  data: function data() {
    return {
      currentRole: 'orient'
    };
  },
  created: function created() {
    this.currentRole = this.$route.query.id;
  },
  methods: {
    next: function next() {}
  }
};
exports.default = _default;