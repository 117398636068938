"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _auth = require("@/utils/auth");

var _wangeditor = _interopRequireDefault(require("wangeditor"));

//
//
//
//
//
//
var _default = {
  props: {
    id: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      editor: null,
      info_: null,
      uploadHeader: {
        'Authorization': 'Bearer' + localStorage.token,
        contentType: 'multipart/form-data; charset=UTF-8'
      }
    };
  },
  mounted: function mounted() {
    this.createEditor();
  },
  methods: {
    createEditor: function createEditor(str) {
      // 创建编辑器
      console.log(str);
      this.editor = new _wangeditor.default('#' + this.id); // console.log(this.editor)

      this.initEditorConfig(); // 初始化编辑器配置，在create之前

      this.editor.customConfig.colors = ['#000000', '#eeece0', '#1c487f', '#4d80bf', '#c24f4a', '#8baa4a', '#7b5ba1', '#46acc8', '#f9963b', '#ffffff'];
      this.editor.customConfig.uploadImgServer = this.$store.state.user.loadUrl;
      this.editor.customConfig.uploadImgHeaders = {
        'Token_': (0, _auth.getToken)()
      };
      this.editor.create(); // 生成编辑器

      if (str) {
        this.editor.$textElem.html(str); // 初始化内容
      } else {
        this.editor.$textElem.html(this.content); // 初始化内容
      }
    },
    destroyEditor: function destroyEditor() {
      // 销毁编辑器，官方没有给出完美方案。此方案是作者给出的临时方案
      this.editor.destroy(); // 这个没有完全销毁实例，只是作了隐藏
      // $('#account--editor').remove();  // 不报错的话，这一步可以省略

      this.editor = null;
      _wangeditor.default.numberOfLocation--; // 手动清除地图的重复引用，作者的临时解决方案。否则单页面来回切换时，地图报错重复引用
    },
    initEditorConfig: function initEditorConfig() {
      var _this = this;

      // 初始化编辑器配置
      var self = this;
      this.editor.customConfig.fontSizes = {
        // 字号配置，增加14px
        // 格式：'value': 'title'
        1: '12px',
        2: '13px',
        3: '14px',
        4: '16px',
        5: '18px',
        6: '24px',
        7: '32px',
        8: '48px'
      };
      this.editor.customConfig.colors = ['#000000', '#eeece0', '#1c487f', '#4d80bf', '#c24f4a', '#8baa4a', '#7b5ba1', '#46acc8', '#f9963b', '#ffffff'];
      this.editor.customConfig.fontNames = ['宋体', '微软雅黑', 'Arial', 'Tahoma', 'Verdana'];
      this.editor.customConfig.uploadImgUrl = this.$store.state.user.loadUrl; // 图片上传地址

      this.editor.customConfig.withCredentials = true;
      this.editor.customConfig.uploadFileName = 'file';
      this.editor.customConfig.uploadImgHooks = {
        before: function before(xhr, editor, file) {},
        success: function success(xhr, editor, result) {},
        fail: function fail(xhr, editor, result) {
          self.$message.error('图片上传失败');
        },
        error: function error(xhr, editor) {
          self.$message.error('图片上传失败');
        },
        timeout: function timeout(xhr, editor) {},
        customInsert: function customInsert(insertImg, result, editor) {
          var url = self.$store.state.user.imgSrc + result.data;
          insertImg(url);
        }
      };

      this.editor.customConfig.onchange = function (html) {
        // console.log( this.editor.txt.text())
        _this.$emit('onChange', html, _this.editor.txt.text()); // 将内容同步到父组件中

      };
    },
    getEditorContent: function getEditorContent() {
      // 获取编辑器 内容区内容
      this.editorContent = this.editor.$textElem.html(); // 获取 html 格式
      // this.editor.$txt.text();  // 获取纯文本
      // this.editor.$txt.formatText();  // 获取格式化后的纯文本
    }
  }
};
exports.default = _default;