var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    触发事件日志\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.pageObj
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "eventType",
              label: "触发类型",
              align: "center",
              "min-width": "100"
            },
            slot: "eventType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.eventType == 1
                      ? _c("span", [_vm._v("发放优惠券")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.eventType == 2
                      ? _c("span", [_vm._v("储值卡变动")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.eventType == 3
                      ? _c("span", [_vm._v("积分变动")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.eventType == 4
                      ? _c("span", [_vm._v("成长值变动")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "content",
              label: "发放内容",
              align: "center",
              fixed: "right",
              "min-width": "100"
            },
            slot: "content",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(scope.row.content) }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }