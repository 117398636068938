var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "选择总部：", prop: "companyId", required: "" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { "max-width": "400px", width: "100%" },
              attrs: { placeholder: "选择总部", autocomplete: "off" },
              on: { change: _vm.companyIdChange },
              model: {
                value: _vm.form.companyId,
                callback: function($$v) {
                  _vm.$set(_vm.form, "companyId", $$v)
                },
                expression: "form.companyId"
              }
            },
            _vm._l(_vm.list, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.fullName, value: item.companyCode }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.scope
        ? _c(
            "el-form-item",
            { attrs: { prop: "usableType", label: "", required: "" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.form.usableType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "usableType", $$v)
                    },
                    expression: "form.usableType"
                  }
                },
                [_vm._v("可用范围")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.form.usableType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "usableType", $$v)
                    },
                    expression: "form.usableType"
                  }
                },
                [_vm._v("不可用范围")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "选择门店：", prop: "shortName", required: "" } },
        [
          _c(
            "el-button",
            {
              staticClass: "item_input",
              staticStyle: { width: "200px" },
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.shortNameClick }
            },
            [_vm._v("选择")]
          ),
          _vm._v(" "),
          _vm.form.storeArr.length > 0
            ? _c(
                "div",
                { staticClass: "addIntegral" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "max-width": "600px",
                        width: "100%"
                      },
                      attrs: {
                        "header-cell-style": {
                          background: "#f5f5f5",
                          height: "20px",
                          padding: "0px 0"
                        },
                        data: _vm.form.storeArr,
                        size: "mini",
                        border: "",
                        "max-height": "400px"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          "min-width": "10"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.$index + 1) +
                                      "\n          "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4281751449
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "attrCode",
                          "min-width": "30",
                          label: "门店编码",
                          "class-name":
                            _vm.form.usableType == 2 ? "errActive" : "active"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "attrName",
                          "min-width": "60",
                          label: "门店名称"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\n      合计数：" +
                      _vm._s(_vm.form.storeArr.length) +
                      "条\n    "
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "选择商户：" } },
        [
          _c(
            "el-button",
            {
              staticClass: "item_input",
              staticStyle: { width: "200px" },
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.setlistMerchant }
            },
            [_vm._v("选择")]
          ),
          _vm._v(" "),
          _vm.changeMerchant.length > 0
            ? _c(
                "div",
                { staticClass: "addIntegral" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "max-width": "600px",
                        width: "100%"
                      },
                      attrs: {
                        "header-cell-style": {
                          background: "#f5f5f5",
                          height: "20px",
                          padding: "0px 0"
                        },
                        data: _vm.changeMerchant,
                        size: "mini",
                        border: "",
                        "max-height": "400px"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          "min-width": "10"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.$index + 1) +
                                      "\n          "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4281751449
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "merchantCode",
                          "min-width": "30",
                          label: "商户编码",
                          "class-name":
                            _vm.form.usableType == 2 ? "errActive" : "active"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "merchantName",
                          "min-width": "60",
                          label: "商户名称"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\n      合计数：" +
                      _vm._s(_vm.changeMerchant.length) +
                      "条\n    "
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "品类分类：" } },
        [
          _c(
            "el-button",
            {
              staticClass: "item_input",
              staticStyle: { width: "200px" },
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.pinleiClick }
            },
            [_vm._v("选择")]
          ),
          _vm._v(" "),
          _vm.form.pinleiArr.length > 0
            ? _c(
                "div",
                { staticClass: "addIntegral" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "max-width": "600px",
                        width: "100%"
                      },
                      attrs: {
                        "header-cell-style": {
                          background: "#f5f5f5",
                          height: "20px",
                          padding: "0px 0"
                        },
                        data: _vm.form.pinleiArr,
                        size: "mini",
                        border: "",
                        "max-height": "400px"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          "min-width": "10"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.$index + 1) +
                                      "\n          "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4281751449
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "classCode",
                          "min-width": "30",
                          label: "品类编码",
                          "class-name":
                            _vm.form.usableType == 2 ? "errActive" : "active"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\n      合计数：" +
                      _vm._s(_vm.form.pinleiArr.length) +
                      "条\n\n    "
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "品牌：" } },
        [
          _c(
            "el-button",
            {
              staticClass: "item_input",
              staticStyle: { width: "200px" },
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.setlistBrand }
            },
            [_vm._v("选择")]
          ),
          _vm._v(" "),
          _vm.form.selectData.length > 0
            ? _c(
                "div",
                { staticClass: "addIntegral" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "max-width": "600px",
                        width: "100%"
                      },
                      attrs: {
                        "header-cell-style": {
                          background: "#f5f5f5",
                          height: "20px",
                          padding: "0px 0"
                        },
                        data: _vm.form.selectData,
                        size: "mini",
                        border: "",
                        "max-height": "400px"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          "min-width": "10"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.$index + 1) +
                                      "\n          "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4281751449
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "brandCode",
                          "min-width": "30",
                          label: "品牌编码",
                          "class-name":
                            _vm.form.usableType == 2 ? "errActive" : "active"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "brandCode",
                          "min-width": "60",
                          label: "品牌名称"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\n      合计数：" +
                      _vm._s(_vm.form.selectData.length) +
                      "条\n\n    "
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "form_item",
          attrs: { label: "商品码：", prop: "multipleSelection" }
        },
        [
          _c(
            "el-button",
            {
              staticClass: "item_input",
              staticStyle: { width: "200px" },
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.getShops }
            },
            [_vm._v("选择")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "item_input",
              staticStyle: { width: "200px" },
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.dowGoods }
            },
            [_vm._v("导出")]
          ),
          _vm._v(" "),
          _vm.form.multipleSelection.length > 0
            ? _c(
                "div",
                { staticClass: "addIntegral" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "max-width": "600px",
                        width: "100%"
                      },
                      attrs: {
                        "header-cell-style": {
                          background: "#f5f5f5",
                          height: "20px",
                          padding: "0px 0"
                        },
                        data: _vm.form.multipleSelection,
                        size: "mini",
                        border: "",
                        "max-height": "400px"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          "min-width": "10"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.$index + 1) +
                                      "\n          "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4281751449
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "goodsCode",
                          "min-width": "30",
                          label: "商品码",
                          "class-name":
                            _vm.form.usableType == 2 ? "errActive" : "active"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "goodsName",
                          "min-width": "60",
                          label: "商品名称"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\n      合计数：" +
                      _vm._s(_vm.form.multipleSelection.length) +
                      "条\n    "
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.shortNameShow,
              expression: "shortNameShow"
            }
          ],
          staticClass: "pop_shop"
        },
        [
          _c(
            "div",
            { staticClass: "pop_box" },
            [
              _c("div", { staticClass: "close_btn" }, [
                _c("i", {
                  staticClass: "el-icon-circle-close",
                  on: {
                    click: function($event) {
                      _vm.shortNameShow = false
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "shortTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "500",
                    size: "mini",
                    "row-key": "storeCode",
                    data: _vm.storeList.filter(function(data) {
                      return (
                        !_vm.shortNameSearch ||
                        data.fullName
                          .toLowerCase()
                          .includes(_vm.shortNameSearch.toLowerCase())
                      )
                    }),
                    "tooltip-effect": "dark"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "reserve-selection": true,
                      type: "selection",
                      width: "50"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "storeCode",
                      label: "门店编码",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fullName",
                      label: "门店名称",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: {
                                size: "mini",
                                placeholder: "输入门店名称搜索"
                              },
                              model: {
                                value: _vm.shortNameSearch,
                                callback: function($$v) {
                                  _vm.shortNameSearch = $$v
                                },
                                expression: "shortNameSearch"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        size: "mini"
                      },
                      on: { click: _vm.clearShort }
                    },
                    [_vm._v("清空")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.affirmShort }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.merchantShow,
              expression: "merchantShow"
            }
          ],
          staticClass: "pop_shop"
        },
        [
          _c(
            "div",
            { staticClass: "pop_box" },
            [
              _c("i", {
                staticClass: "el-icon-circle-close",
                on: {
                  click: function($event) {
                    _vm.merchantShow = false
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { padding: "10px 0" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "多个编码用英文逗号分开"
                        },
                        model: {
                          value: _vm.merchantData.merchantCode,
                          callback: function($$v) {
                            _vm.$set(_vm.merchantData, "merchantCode", $$v)
                          },
                          expression: "merchantData.merchantCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 4, offset: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.getMerchant("search")
                            }
                          }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "merchantTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "430",
                    size: "mini",
                    "row-key": "merchantCode",
                    data: _vm.merchantList,
                    "tooltip-effect": "dark"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "reserve-selection": true,
                      type: "selection",
                      width: "50"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "merchantCode",
                      label: "商户编码",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "merchantName",
                      label: "商户名称",
                      align: "center"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.merchantData.total > 0,
                    expression: "merchantData.total>0"
                  }
                ],
                staticStyle: { padding: "0 20px" },
                attrs: {
                  total: _vm.merchantData.total,
                  page: _vm.merchantData.pageNo,
                  limit: _vm.merchantData.pageSize
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.merchantData, "pageNo", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.merchantData, "pageSize", $event)
                  },
                  pagination: _vm.getMerchant
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        size: "mini"
                      },
                      on: { click: _vm.clearMerchant }
                    },
                    [_vm._v("清空")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.addMerchant }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.pinpaiShow,
              expression: "pinpaiShow"
            }
          ],
          staticClass: "pop_shop"
        },
        [
          _c(
            "div",
            { staticClass: "pop_box" },
            [
              _c("i", {
                staticClass: "el-icon-circle-close",
                on: {
                  click: function($event) {
                    _vm.pinpaiShow = false
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { padding: "10px 0" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "多个编码用英文逗号分开"
                        },
                        model: {
                          value: _vm.brandData.brandCode,
                          callback: function($$v) {
                            _vm.$set(_vm.brandData, "brandCode", $$v)
                          },
                          expression: "brandData.brandCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 4, offset: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.getBrandList("search")
                            }
                          }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "pinpaiTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "430",
                    size: "mini",
                    "row-key": "brandCode",
                    data: _vm.pinpaiArr,
                    "tooltip-effect": "dark"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "reserve-selection": true,
                      type: "selection",
                      width: "50"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "brandCode",
                      label: "品牌名称",
                      align: "center"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.brandData.total > 0,
                    expression: "brandData.total>0"
                  }
                ],
                staticStyle: { padding: "0 20px" },
                attrs: {
                  total: _vm.brandData.total,
                  page: _vm.brandData.pageNo,
                  limit: _vm.brandData.pageSize
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.brandData, "pageNo", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.brandData, "pageSize", $event)
                  },
                  pagination: _vm.getBrandList
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        size: "mini"
                      },
                      on: { click: _vm.clearPinPai }
                    },
                    [_vm._v("清空")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.addPinPai }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.pinleiShow,
              expression: "pinleiShow"
            }
          ],
          staticClass: "pop_shop"
        },
        [
          _c(
            "div",
            { staticClass: "pop_box" },
            [
              _c("div", { staticClass: "close_btn" }, [
                _c("i", {
                  staticClass: "el-icon-circle-close",
                  on: {
                    click: function($event) {
                      _vm.pinleiShow = false
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { padding: "10px 0" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "多个编码用英文逗号分开"
                        },
                        model: {
                          value: _vm.catData.brandClsCode,
                          callback: function($$v) {
                            _vm.$set(_vm.catData, "brandClsCode", $$v)
                          },
                          expression: "catData.brandClsCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 4, offset: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.getCatList("search")
                            }
                          }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "tree",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "430",
                    size: "mini",
                    "row-key": "classCode",
                    data: _vm.pinLeiData.data,
                    "tooltip-effect": "dark"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "reserve-selection": true,
                      type: "selection",
                      width: "50"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "classCode", label: "编码", align: "center" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.catData.total > 0,
                    expression: "catData.total>0"
                  }
                ],
                staticStyle: { padding: "0 20px" },
                attrs: {
                  total: _vm.catData.total,
                  page: _vm.catData.pageNo,
                  limit: _vm.catData.pageSize
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.catData, "pageNo", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.catData, "pageSize", $event)
                  },
                  pagination: _vm.getCatList
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        size: "mini"
                      },
                      on: { click: _vm.clearPinLei }
                    },
                    [_vm._v("清空")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.addPinLei }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.popShow,
              expression: "popShow"
            }
          ],
          staticClass: "pop_shop"
        },
        [
          _c(
            "div",
            { staticClass: "pop_box" },
            [
              _c(
                "div",
                { staticClass: "close_btn" },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "padding-bottom": "10px" },
                      attrs: { gutter: 24, type: "flex" }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c("el-input", {
                            staticClass: "input2",
                            attrs: {
                              size: "mini",
                              placeholder: "商品编码或者名称"
                            },
                            model: {
                              value: _vm.goodsData.goodsCode,
                              callback: function($$v) {
                                _vm.$set(_vm.goodsData, "goodsCode", $$v)
                              },
                              expression: "goodsData.goodsCode"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.getShops("remove")
                            }
                          }
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.dowGoodsList("remove")
                            }
                          }
                        },
                        [_vm._v("倒出")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-circle-close",
                    on: {
                      click: function($event) {
                        _vm.popShow = false
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "shopTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "430",
                    size: "mini",
                    "row-key": "goodsCode",
                    data: _vm.popTableData,
                    "tooltip-effect": "dark"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "reserve-selection": true,
                      type: "selection",
                      width: "50"
                    }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.tabTh, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        prop: item.prop,
                        label: item.tit,
                        "min-width": item.width,
                        align: item.align
                      }
                    })
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.goodsData.total > 0,
                    expression: "goodsData.total>0"
                  }
                ],
                staticStyle: { padding: "0 20px" },
                attrs: {
                  total: _vm.goodsData.total,
                  page: _vm.goodsData.pageNo,
                  limit: _vm.goodsData.pageSize
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.goodsData, "pageNo", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.goodsData, "pageSize", $event)
                  },
                  pagination: _vm.getShops
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        size: "mini"
                      },
                      on: { click: _vm.clearShop }
                    },
                    [_vm._v("清空")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.affirmAddCoupon }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }