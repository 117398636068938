var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isConfirm,
              expression: "!isConfirm"
            }
          ],
          ref: "item",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.item, rules: _vm.rules, "label-width": "120px" }
        },
        [
          _c(
            "el-row",
            [
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("h4", [_vm._v("基本信息")])
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "卡张数：", prop: "name" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { size: "small", placeholder: "" },
                              model: {
                                value: _vm.item.number,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "number", $$v)
                                },
                                expression: "item.number"
                              }
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "append" }, slot: "append" },
                                [_vm._v("张")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "卡余额：", prop: "name" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { size: "small", placeholder: "" },
                              model: {
                                value: _vm.item.number,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "number", $$v)
                                },
                                expression: "item.number"
                              }
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "append" }, slot: "append" },
                                [_vm._v("元")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "领出机构：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "number", $$v)
                              },
                              expression: "item.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "领出部门：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "number", $$v)
                              },
                              expression: "item.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "领出负责人：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "number", $$v)
                              },
                              expression: "item.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "领入机构：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "number", $$v)
                              },
                              expression: "item.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "领入部门：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "number", $$v)
                              },
                              expression: "item.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "领入负责人：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "number", $$v)
                              },
                              expression: "item.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("h4", [_vm._v("单据记录")])
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 19, offset: 2 } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableData,
                            border: "",
                            size: "mini"
                          },
                          on: { "cell-mouse-leave": _vm.onChangeCell }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              width: "50",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "实体卡类型", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { placeholder: "" },
                                        model: {
                                          value: scope.row.type,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "type", $$v)
                                          },
                                          expression: "scope.row.type"
                                        }
                                      },
                                      _vm._l(_vm.cardOptions, function(
                                        listItem,
                                        listIndex
                                      ) {
                                        return _c("el-option", {
                                          key: listIndex,
                                          attrs: {
                                            label: listItem.text,
                                            value: listItem.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "卡名称", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.type,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "type", $$v)
                                        },
                                        expression: "scope.row.type"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "起始卡号", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.type,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "type", $$v)
                                        },
                                        expression: "scope.row.type"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "终止卡号", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.type,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "type", $$v)
                                        },
                                        expression: "scope.row.type"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "卡数量",
                              align: "center",
                              width: "120px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.number,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "number",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "scope.row.number"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "卡金额",
                              align: "center",
                              width: "120px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.number,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "number",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "scope.row.number"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "已初始化卡张数",
                              align: "center",
                              width: "120px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.number1,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "number1",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "scope.row.number1"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "备注",
                              align: "center",
                              width: "180px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.type,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "type", $$v)
                                        },
                                        expression: "scope.row.type"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                align: "center",
                                width: "60",
                                fixed: "right"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                            title: "删除",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleDelete(
                                                scope.$index,
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete"
                                          })
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "template",
                                { slot: "header" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        circle: "",
                                        title: "添加"
                                      },
                                      on: { click: _vm.onAddTable }
                                    },
                                    [_c("i", { staticClass: "el-icon-plus" })]
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.tableData.length,
                              expression: "tableData.length"
                            }
                          ],
                          staticClass: "sumTabledata-box"
                        },
                        [
                          _c("span", [_vm._v("合计：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "first-box" }, [
                            _vm._v(_vm._s("卡数量：" + _vm.sumTabledata + "张"))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "last-box" }, [
                            _vm._v(
                              _vm._s(
                                "已初始化卡张数：" + _vm.sumTabledata1 + "张"
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("h4", [_vm._v("备注信息")])
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 19, offset: 2 } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 2,
                          placeholder: "备注..."
                        },
                        model: {
                          value: _vm.item.textarea,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "textarea", $$v)
                          },
                          expression: "item.textarea"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isConfirm,
              expression: "isConfirm"
            }
          ]
        },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _c("h4", [_vm._v("基本信息")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "detail-box" },
            [
              _c(
                "div",
                { staticStyle: { padding: "15px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("卡总张数：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("400")])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("卡总余额：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("400")])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("领出机构：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("星力百货集团有限公司总部")])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("领出部门：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("星力百货集团有限公司总部")])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("领出负责人：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("张三")])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("领入机构：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("中华超市")])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("领入部门：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("运营部")])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("领入负责人：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("李四")])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("h4", [_vm._v("单据记录")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { padding: "15px" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        border: "",
                        size: "mini",
                        "show-summary": "",
                        "summary-method": _vm.getSummaries
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", width: "50", align: "center" }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", { attrs: { label: "实体卡类型" } }),
                      _vm._v(" "),
                      _c("el-table-column", { attrs: { label: "卡名称" } }),
                      _vm._v(" "),
                      _c("el-table-column", { attrs: { label: "起始卡号" } }),
                      _vm._v(" "),
                      _c("el-table-column", { attrs: { label: "终止卡号" } }),
                      _vm._v(" "),
                      _c("el-table-column", { attrs: { label: "卡张数" } }),
                      _vm._v(" "),
                      _c("el-table-column", { attrs: { label: "卡金额" } }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "已初始卡张数" }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", { attrs: { label: "备注" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("h4", [_vm._v("备注信息")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { padding: "15px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("录入人员：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("黎绍弦：")])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("审核人员：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("黎绍弦")])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("录入时间：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("2019-08-16 17:58:00")])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6, offset: 1 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("审核时间：")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("2019-08-16 17:58:00")])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.goBack } },
            [_c("i", { staticClass: "el-icon-back" }), _vm._v(" 返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onStep }
            },
            [
              _vm._v(_vm._s(_vm.isConfirm ? "上一步" : "下一步") + " "),
              _c("i", {
                class: [_vm.isConfirm ? "el-icon-top" : "el-icon-bottom"]
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isConfirm,
                  expression: "isConfirm"
                }
              ],
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onSave }
            },
            [
              _c("i", { staticClass: "el-icon-document-checked" }),
              _vm._v(" 保存")
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }