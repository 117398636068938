var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-rights-setting" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("权益页面设置")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticStyle: { width: "85vw" },
          attrs: {
            "label-width": "106px",
            model: _vm.ruleForm,
            rules: _vm.rules
          }
        },
        [
          _c("h4", { staticClass: "form_title" }, [_vm._v("尊享礼遇")]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { prop: "list" } },
            [
              _c(
                "div",
                { staticClass: "svip-rights-setting_form" },
                _vm._l(_vm.ruleForm.list, function(picture, idx) {
                  return _c(
                    "el-form-item",
                    {
                      key: idx,
                      staticClass:
                        "svip-rights-setting_form_select-pic form_item",
                      attrs: {
                        label: "图标" + (idx + 1),
                        "label-position": "left",
                        "label-width": "80px"
                      }
                    },
                    [
                      _c("el-button", {
                        staticClass:
                          "svip-rights-setting_form_select-pic_delete",
                        attrs: { type: "text", icon: "el-icon-delete" },
                        on: {
                          click: function($event) {
                            return _vm.delSelectPic(idx)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "list." + idx + ".icon",
                            rules: {
                              required: true,
                              message: "请上传图片"
                            }
                          }
                        },
                        [
                          _c("add-load", {
                            staticClass: "img_list",
                            attrs: {
                              width: 120,
                              height: 120,
                              cover: picture.icon
                            },
                            on: {
                              setCover: function($event) {
                                return _vm.setSelectPic($event, idx, "list")
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticStyle: { color: "#999999" } }, [
                            _vm._v("建议尺寸：1:1")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass:
                            "svip-rights-setting_form_select-pic_right"
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "权益名称：",
                                "label-width": "100px",
                                prop: "list." + idx + ".rightsName",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入权益名称"
                                  }
                                ]
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "form_input",
                                attrs: {
                                  maxlength: "5",
                                  "show-word-limit": "",
                                  placeholder: "请输入"
                                },
                                model: {
                                  value: picture.rightsName,
                                  callback: function($$v) {
                                    _vm.$set(picture, "rightsName", $$v)
                                  },
                                  expression: "picture.rightsName"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form_item",
                              attrs: {
                                label: "跳转页面：",
                                "label-width": "100px",
                                prop: "list." + idx + ".linkType"
                              }
                            },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: 1 },
                                              model: {
                                                value: picture.linkType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    picture,
                                                    "linkType",
                                                    $$v
                                                  )
                                                },
                                                expression: "picture.linkType"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      关联内容\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c("NavigateSelect", {
                                        attrs: {
                                          label: "",
                                          dataSource: picture,
                                          propPrefix: "list." + idx
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 2 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: 2 },
                                              model: {
                                                value: picture.linkType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    picture,
                                                    "linkType",
                                                    $$v
                                                  )
                                                },
                                                expression: "picture.linkType"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      自定义\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("p", { staticStyle: { color: "#999999" } }, [
                                _vm._v(
                                  "\n                选择自定义时，请在右方富文本编辑展示内容\n              "
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "排序：",
                                "label-width": "100px",
                                prop: "list." + idx + ".sort",
                                rules: {
                                  type: "number",
                                  required: true,
                                  message: "请输入排序",
                                  trigger: "blur"
                                }
                              }
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "form_input",
                                attrs: {
                                  min: 1,
                                  step: 1,
                                  precision: 0,
                                  controls: false
                                },
                                model: {
                                  value: picture.sort,
                                  callback: function($$v) {
                                    _vm.$set(picture, "sort", $$v)
                                  },
                                  expression: "picture.sort"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "状态：",
                                "label-width": "100px",
                                prop: "list." + idx + ".status",
                                rules: {
                                  required: true,
                                  message: "请选择状态",
                                  trigger: "change"
                                }
                              }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: picture.status,
                                    callback: function($$v) {
                                      _vm.$set(picture, "status", $$v)
                                    },
                                    expression: "picture.status"
                                  }
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("上架")
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("下架")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      picture.linkType === 2
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "form_item",
                              attrs: { prop: "list." + idx + ".customContent" }
                            },
                            [
                              _c("Tinymce", {
                                ref: "editor" + idx,
                                refInFor: true,
                                attrs: { width: "100%", height: 150 },
                                model: {
                                  value: picture.customContent,
                                  callback: function($$v) {
                                    _vm.$set(picture, "customContent", $$v)
                                  },
                                  expression: "picture.customContent"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "max-width": "100%" },
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: { click: _vm.addPic }
                },
                [_vm._v("\n        添加\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "button_center" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("\n        保存设置\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }