"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.constructor");

var _manage = require("@/api/manage.js");

var _integral = require("@/api/integral.js");

var _index = _interopRequireDefault(require("@/components/integral/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var validateAcquaintance = function validateAcquaintance(rule, value, callback) {
  if (value < 0) {
    callback(new Error('输入为大于0的数值'));
  } else {
    callback();
  }
};

var _default = {
  components: {
    integral: _index.default
  },
  data: function data() {
    return {
      rules: {
        sellAmount: [{
          required: true,
          message: '请填写消费金额'
        }, {
          validator: validateAcquaintance,
          message: '请输入为大于0的数值'
        }],
        sell365Amount: [{
          required: true,
          message: '请填写消费金额'
        }],
        selllavantAmount: [{
          required: true,
          message: '请填写消费金额'
        }],
        getScore: [{
          required: true,
          message: '请填写获得积分'
        }, {
          validator: validateAcquaintance,
          message: '请输入为大于0的数值'
        }],
        basicStatus: [{
          required: true,
          message: '请选择策略状态'
        }],
        shortName: [{
          required: true,
          message: '请选择门店'
        }],
        multipleSelection: [{
          required: true,
          message: '请选择商品'
        }],
        selectData: [{
          required: true,
          message: '请选择品牌'
        }],
        pinleiArr: [{
          required: true,
          message: '请选择品类'
        }],
        companyId: [{
          required: true,
          message: '请选择公司'
        }],
        basicRemark: [{
          required: true,
          message: '请输入备注'
        }],
        memberArr: [{
          required: true,
          message: '请选择会员类型'
        }]
      },
      // 输入框
      form: {
        memberArr: [],
        companyId: '',
        basicStatus: '',
        sellAmount: null,
        sell365Amount: null,
        selllavantAmount: null,
        getScore: null,
        payModes: [],
        cCcodes: [],
        pinleiArr: [],
        selectData: [],
        multipleSelection: [],
        storeArr: [],
        shortName: [],
        basicRemark: '',
        companyName: ''
      }
    };
  },
  watch: {},
  created: function created() {
    var _this = this;

    this.basicId = Number(this.$route.query.id) || '';

    if (this.basicId) {
      (0, _integral.getScoreBasic)(this.basicId).then(function (res) {
        // this.$refs.integralRef.companyIdChange(res.data.companyCode)
        _this.$refs.integralRef.setCompanId(res.data.companyCode);

        _this.form.basicStatus = res.data.basicStatus;
        _this.form.sellAmount = res.data.sellAmount;
        _this.form.sell365Amount = res.data.sell365Amount;
        _this.form.selllavantAmount = res.data.selllavantAmount;
        _this.form.getScore = res.data.getScore;
        _this.form.companyId = res.data.companyCode;
        _this.form.basicRemark = res.data.basicRemark || '';

        _this.$refs.integralRef.setCditions(res.data.cditions);

        if (res.data.sellAmount > 0) {
          _this.form.memberArr.push(1);
        }

        if (res.data.sell365Amount > 0) {
          _this.form.memberArr.push(2);
        }

        if (res.data.selllavantAmount > 0) {
          _this.form.memberArr.push(3);
        }
      });
    }
  },
  mounted: function mounted() {
    if (!this.basicId) {
      this.$refs.integralRef.setCompanId();
    }
  },
  methods: {
    submit: function submit(type) {
      this.$refs['form'].validateField(type); // this.$refs['form'].validate((valid) => {})
    },
    setStoreId: function setStoreId(arr) {
      this.form.storeArr = arr;
      console.log(arr);
    },
    setcompanyId: function setcompanyId(e, value) {
      this.form.companyId = e;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var cditions = this.$refs.integralRef.getCditions();
      console.log(cditions);
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (cditions) {
            var data = {
              cditions: cditions,
              companyCode: _this2.form.companyId,
              companyName: _this2.form.companyName,
              basicStatus: _this2.form.basicStatus,
              sellAmount: _this2.form.memberArr.includes(1) ? _this2.form.sellAmount : null,
              sell365Amount: _this2.form.memberArr.includes(2) ? _this2.form.sell365Amount : null,
              selllavantAmount: _this2.form.memberArr.includes(3) ? _this2.form.selllavantAmount : null,
              getScore: _this2.form.getScore,
              basicRemark: _this2.form.basicRemark
            };
            console.log(JSON.stringify(data));

            _this2.openLoading();

            if (_this2.basicId) {
              data.basicId = _this2.basicId;
              (0, _integral.editScoreBasic)(data).then(function (res) {
                _this2.openLoading().close();

                _this2.$message({
                  type: 'success',
                  message: '编辑成功!'
                });

                _this2.$router.go(-1);
              }).catch(function (err) {
                _this2.openLoading().close();
              });
            } else {
              (0, _integral.addScoreBasic)(data).then(function (res) {
                _this2.openLoading().close();

                _this2.$message({
                  type: 'success',
                  message: '新增成功!'
                });

                _this2.$router.go(-1);
              }).catch(function (err) {
                _this2.openLoading().close();
              });
            }
          } else {
            _this2.$message.error('商户，品牌，品类必须选一个');
          }
        } else {
          _this2.$message.error('请完善信息后提交');

          return false;
        }
      });
    }
  }
};
exports.default = _default;