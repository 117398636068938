"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _options = require("../options");

var _image = _interopRequireDefault(require("../components/UploadFile/image"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadImage: _image.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      raffleTypeOptions: _options.raffleTypeOptions,
      raffleShapeOptions: _options.raffleShapeOptions,
      raffleForm: {
        activityName: '',
        gameType: 1,
        prizeType: 1,
        prizeNum: undefined,
        startTime: '',
        endTime: '',
        times: [],
        useIntegral: undefined,
        backgroundImage: ''
      },
      raffleRules: {}
    };
  }
};
exports.default = _default;