var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "ecitList_box2 box" }, [
      _c("div", { staticClass: "box2-left" }, [
        _c("ul", [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "ecit_tit" }, [_vm._v("轮播时间间隔：")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "选择每行图标数" },
                  model: {
                    value: _vm.time,
                    callback: function($$v) {
                      _vm.time = $$v
                    },
                    expression: "time"
                  }
                },
                _vm._l(_vm.times, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm._m(1)
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ecitList_box3 box" }, [
      _c(
        "div",
        { staticClass: "box3_tit" },
        [
          _c("p", { staticClass: "ecit_tit" }, [_vm._v("列表数据项：")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addList } },
            [_vm._v("增加数据条目")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box3_table" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  width: "120",
                  label: "图标图片（200*200）",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.getIndex(scope)
                              }
                            }
                          },
                          [
                            _c("add-load", {
                              attrs: {
                                width: 100,
                                height: 100,
                                cover: scope.row.cover
                              },
                              on: { setCover: _vm.setCover }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  label: "功能标题（五个字以内）",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { placeholder: "0" },
                          model: {
                            value: scope.row.tit,
                            callback: function($$v) {
                              _vm.$set(scope.row, "tit", $$v)
                            },
                            expression: "scope.row.tit"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "页面类型及参数", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            staticClass: "table_select",
                            attrs: { placeholder: "请选择模板" },
                            model: {
                              value: scope.row.pageType,
                              callback: function($$v) {
                                _vm.$set(scope.row, "pageType", $$v)
                              },
                              expression: "scope.row.pageType"
                            }
                          },
                          _vm._l(_vm.pageTypes, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          staticClass: "table_input",
                          attrs: { placeholder: "请输入内容" },
                          model: {
                            value: scope.row.parameter,
                            callback: function($$v) {
                              _vm.$set(scope.row, "parameter", $$v)
                            },
                            expression: "scope.row.parameter"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  width: "150",
                  prop: "sort",
                  label: "排序",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          staticClass: "table_input2",
                          attrs: { placeholder: "0" },
                          model: {
                            value: scope.row.sort,
                            callback: function($$v) {
                              _vm.$set(scope.row, "sort", $$v)
                            },
                            expression: "scope.row.sort"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "100", align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.editor(scope)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "nextBtn" },
      [_c("el-button", { attrs: { type: "primary" } }, [_vm._v("保存")])],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", { staticClass: "ecit_tit" }, [_vm._v("已选：")]),
      _vm._v(" "),
      _c("p", { staticClass: "color" }, [_vm._v("首页功能图标")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box2-right" }, [
      _c("p", { staticClass: "ecit_tit" }, [_vm._v("模板预览：")]),
      _vm._v(" "),
      _c("img", { attrs: { src: "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }