var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-setting-home" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("我的页设置")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                attrs: { "label-width": "140px", model: _vm.ruleForm }
              },
              [
                _c("h4", { staticClass: "form_title" }, [_vm._v("充值引导图")]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: {
                      label: "未成为要客状态：",
                      prop: "noSvipRechargeGuides"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "svip-setting-home_form" },
                      _vm._l(_vm.ruleForm.noSvipRechargeGuides, function(
                        picture,
                        idx
                      ) {
                        return _c(
                          "el-form-item",
                          {
                            key: idx,
                            staticClass:
                              "svip-setting-home_form_select-pic form_item",
                            attrs: {
                              "label-position": "left",
                              "label-width": "0px"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "noSvipRechargeGuides." + idx + ".pic",
                                  rules: {
                                    required: true,
                                    message: "请上传图片"
                                  }
                                }
                              },
                              [
                                _c("add-load", {
                                  staticClass: "img_list",
                                  attrs: {
                                    width: 187.5,
                                    height: 120,
                                    cover: picture.pic
                                  },
                                  on: {
                                    setCover: function($event) {
                                      return _vm.setSelectPic(
                                        $event,
                                        idx,
                                        "noSvipRechargeGuides"
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("p", { staticStyle: { color: "#999999" } }, [
                                  _vm._v("建议图片尺寸：宽750 不限高度")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass:
                                  "svip-setting-home_form_select-pic_right"
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "状态：",
                                      "label-width": "100px",
                                      prop:
                                        "noSvipRechargeGuides." +
                                        idx +
                                        ".enable",
                                      rules: {
                                        required: true,
                                        message: "请选择状态",
                                        trigger: "change"
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: picture.enable,
                                          callback: function($$v) {
                                            _vm.$set(picture, "enable", $$v)
                                          },
                                          expression: "picture.enable"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("上架")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("下架")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("NavigateSelect", {
                                  attrs: {
                                    dataSource: picture,
                                    propPrefix: "noSvipRechargeGuides." + idx
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: {
                      label: "已成为要客状态：",
                      prop: "svipRechargeGuides"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "svip-setting-home_form" },
                      _vm._l(_vm.ruleForm.svipRechargeGuides, function(
                        picture,
                        idx
                      ) {
                        return _c(
                          "el-form-item",
                          {
                            key: idx,
                            staticClass:
                              "svip-setting-home_form_select-pic form_item",
                            attrs: {
                              "label-position": "left",
                              "label-width": "0px"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "svipRechargeGuides." + idx + ".pic",
                                  rules: {
                                    required: true,
                                    message: "请上传图片"
                                  }
                                }
                              },
                              [
                                _c("add-load", {
                                  staticClass: "img_list",
                                  attrs: {
                                    width: 187.5,
                                    height: 120,
                                    cover: picture.pic
                                  },
                                  on: {
                                    setCover: function($event) {
                                      return _vm.setSelectPic(
                                        $event,
                                        idx,
                                        "svipRechargeGuides"
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("p", { staticStyle: { color: "#999999" } }, [
                                  _vm._v("建议图片尺寸：宽750 不限高度")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass:
                                  "svip-setting-home_form_select-pic_right"
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "状态：",
                                      "label-width": "100px",
                                      prop:
                                        "svipRechargeGuides." + idx + ".enable",
                                      rules: {
                                        required: true,
                                        message: "请选择状态",
                                        trigger: "change"
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: picture.enable,
                                          callback: function($$v) {
                                            _vm.$set(picture, "enable", $$v)
                                          },
                                          expression: "picture.enable"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("上架")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("下架")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("NavigateSelect", {
                                  attrs: {
                                    dataSource: picture,
                                    propPrefix: "svipRechargeGuides." + idx
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "150px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.submitForm("ruleForm")
                          }
                        }
                      },
                      [_vm._v("\n            保存设置\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }