"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon.js");

var _deposit = require("@/api/deposit");

var _api = require("@/views/mall/goods/api.js");

var _api2 = require("@/views/activity/lottery/api.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default
  },
  props: {
    couponShow: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    goodsType: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      type: '',
      getRowKeys: function getRowKeys(row) {
        return row.uuid;
      },
      radioId: '',
      tableData: [],
      total: 0,
      obj: {
        pageNo: 1,
        pageSize: 10,
        onlineStatus: 1,
        status: 2,
        onShel: 1,
        couponType: '',
        uuid: '',
        name: '',
        productCode: '',
        productName: '',
        productSource: [1]
      },
      options: [{
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣券'
      }, {
        value: 3,
        label: '礼品券'
      }, {
        value: 4,
        label: '体验券'
      }],
      tabTh: [{
        tit: '优惠券编码',
        prop: 'uuid',
        width: 180
      }, {
        tit: '优惠券名称',
        prop: 'name'
      }, {
        tit: '类型',
        prop: 'couponType',
        width: 80
      }, {
        tit: '优惠券有效期',
        prop: 'time',
        width: 200
      }, {
        tit: '状态',
        prop: 'statusText',
        width: 90
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    rowClick: function rowClick(item) {
      if (this.type == 'goods') {
        this.radioId = item.productCode;
      } else {
        this.radioId = item.uuid;
      }
    },
    affirmAddCoupon: function affirmAddCoupon() {
      this.$emit('setCoupons', this.radioId);
    },
    getList: function getList(type, id) {
      this.radioId = id || '';
      this.type = type;

      if (this.type == 'activity') {
        this.tabTh = [{
          tit: '活动名称',
          prop: 'name',
          width: 200
        }, {
          tit: '活动编码',
          prop: 'uuid',
          width: 160,
          align: 'center'
        }, {
          tit: '分类',
          prop: 'className',
          width: 140
        }];
      }

      if (this.type == 'card') {
        this.tabTh = [{
          tit: '储值卡号',
          prop: 'uuid',
          width: 200
        }, {
          tit: '储值卡名称',
          prop: 'name',
          width: 160,
          align: 'center'
        }, {
          tit: '分类',
          prop: 'className',
          width: 140
        }];
      }

      if (this.type == 'coupon') {
        this.tabTh = [{
          tit: '优惠券编码',
          prop: 'uuid',
          width: 180
        }, {
          tit: '优惠券名称',
          prop: 'name'
        }, {
          tit: '类型',
          prop: 'couponType',
          width: 80
        }, {
          tit: '优惠券有效期',
          prop: 'time',
          width: 200
        }, {
          tit: '状态',
          prop: 'statusText',
          width: 90
        }];
      }

      if (this.type == 'goods') {
        this.tabTh = [{
          tit: '商品名称',
          prop: 'productName',
          width: 200
        }, {
          tit: '商品编码',
          prop: 'productCode',
          width: 160,
          align: 'center'
        }, {
          tit: '分类',
          prop: 'categoryName',
          width: 140
        }];
      }

      if (this.type == 'pond') {
        this.tabTh = [{
          tit: '活动编码',
          prop: 'code',
          width: 200
        }, {
          tit: '活动名称',
          prop: 'name',
          width: 160,
          align: 'center'
        }, {
          tit: '主办机构',
          prop: 'orgName',
          width: 140
        }];
      }

      this.init('remove');
    },
    init: function init(types) {
      var _this = this;

      if (types == 'search') {
        this.obj.pageNo = 1;
      }

      if (types == 'remove') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
        this.obj.couponType = '';
        this.obj.uuid = '';
        this.obj.name = '';
        this.obj.productCode = '';
        this.obj.productName = '';
        this.obj.productSource = [1];
        this.tableData = [];
        this.total = 0;
      }

      if (this.type == 'activity') {
        (0, _coupon.listActivityPage)(this.obj).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      } else if (this.type == 'card') {
        (0, _deposit.listCardStored)(this.obj).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      } else if (this.type == 'goods') {
        var data = this.obj;

        if (this.goodsType == 'activity') {
          data.scoreMin = 0;
          data.scoreMax = 0;
          data.priceMin = 0;
          data.priceMax = 0;
        }

        (0, _api.list)(data).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      } else if (this.type == 'pond') {
        (0, _api2.lotteryList)(this.obj).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      } else {
        (0, _coupon.listCouponPage)(this.obj).then(function (res) {
          _this.total = res.data.totalNum;
          var data = res.data.items;
          data.forEach(function (value) {
            value.check = false;

            if (value.validTimeType === 1) {
              value.time = value.validBeginTime.substring(0, 10) + '至' + value.validEndTime.substring(0, 10);
            } else if (value.validTimeType === 2) {
              value.time = _this.getValidDay(value);
            }

            if (value.couponType === 1) {
              value.couponType = '代金券';
            } else if (value.couponType === 2) {
              value.couponType = '折扣券';
            } else if (value.couponType === 3) {
              value.couponType = '礼品券';
            } else if (value.couponType === 4) {
              value.couponType = '体验券';
            }
          });
          _this.tableData = data;
        });
      }
    }
  }
};
exports.default = _default2;