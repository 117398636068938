var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("新增/编辑触发事件")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              ref: "eventType",
              attrs: { label: "事件类型", prop: "eventType" }
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "400px" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.ruleForm.eventType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "eventType", $$v)
                    },
                    expression: "ruleForm.eventType"
                  }
                },
                _vm._l(_vm.typeList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "eventName",
              attrs: { label: "事件名称", prop: "eventName" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                model: {
                  value: _vm.ruleForm.eventName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "eventName", $$v)
                  },
                  expression: "ruleForm.eventName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "eventStatus",
              attrs: { label: "事件状态", prop: "eventStatus" }
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.eventStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "eventStatus", $$v)
                    },
                    expression: "ruleForm.eventStatus"
                  }
                },
                [_vm._v(" 启用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.ruleForm.eventStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "eventStatus", $$v)
                    },
                    expression: "ruleForm.eventStatus"
                  }
                },
                [_vm._v("停用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "eventRemark",
              attrs: { label: "事件备注", prop: "eventRemark" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 }
                },
                model: {
                  value: _vm.ruleForm.eventRemark,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "eventRemark", $$v)
                  },
                  expression: "ruleForm.eventRemark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "coupons", attrs: { label: "发放券信息", prop: "coupons" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.getRelevances }
                },
                [_vm._v("选择")]
              ),
              _vm._v(" "),
              _c(
                "w-table",
                {
                  attrs: {
                    "table-data": _vm.ruleForm.coupons,
                    columns: _vm.columns
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      slot: "sentNumber",
                      label: "数量",
                      align: "center",
                      width: "170"
                    },
                    slot: "sentNumber",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "coupons." + scope.$index + ".sentNumber",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入数量",
                                      trigger: "blur"
                                    }
                                  ]
                                }
                              },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    min: 1,
                                    step: 1,
                                    "step-strictly": ""
                                  },
                                  model: {
                                    value: scope.row.sentNumber,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "sentNumber", $$v)
                                    },
                                    expression: "scope.row.sentNumber"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即创建")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("coupon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "couponPop",
        on: { setCoupons: _vm.setCoupons, closeCouponPop: _vm.closeCouponPop }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }