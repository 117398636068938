"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.array.find-index");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

var _auth = require("@/utils/auth");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'UploadImages',
  model: {
    prop: 'value',
    event: 'updateValue'
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    size: {
      type: Number,
      default: 2
    },
    //  ["image/jpg"]
    fileTypes: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    limit: {
      type: Number,
      default: 20
    }
  },
  data: function data() {
    return {
      prefix: this.imgSrc,
      url: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
      images: [],
      visible: false,
      showSrc: ''
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)('user', ['imgSrc'])), {}, {
    headers: function headers() {
      var token = (0, _auth.getToken)();
      return {
        ContentType: 'multipart/form-data',
        Token: token
      };
    },
    fullImages: function fullImages() {
      var _this = this;

      var urls = this.value;

      if (urls && urls.length > 0) {
        return urls.map(function (item) {
          return {
            url: _this.imgSrc + item
          };
        });
      }

      return [];
    }
  }),
  beforeMount: function beforeMount() {
    this.images = this.fullImages;
  },
  methods: {
    handDelete: function handDelete(file) {
      var urls = this.value;
      var index = this.images.findIndex(function (item) {
        return item.url == file.url;
      });
      urls.splice(index, 1);
      this.images.splice(index, 1);
      this.$emit('updateValue', urls);
    },
    handPreview: function handPreview(file) {
      this.showSrc = file.url;
      this.visible = true;
    },
    handDownload: function handDownload() {
      var _this2 = this;

      var url = this.fullSrc;
      var link = document.createElement('a'); // 这里是将链接地址url转成blob地址，

      fetch(url).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        link.href = URL.createObjectURL(blob); // 下载文件的名称及文件类型后缀

        var splitPath = _this2.fullSrc.split(0);

        link.download = splitPath[splitPath.length - 1];
        link.download = document.body.appendChild(link);
        link.click(); // 在资源下载完成后 清除 占用的缓存资源

        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      });
    },
    handUploadSuccess: function handUploadSuccess(res, file, files) {
      var urls = this.value;
      urls.push(res.data);
      this.$emit('updateValue', urls);
      this.images = files;
    },
    handUploadBefore: function handUploadBefore(file) {
      var isFileType = this.fileTypes.length > 0 ? this.fileTypes.includes(file.type) : true;
      var isLimit = file.size / 1024 / 1024 < this.size;

      if (!isFileType) {
        this.$message.error("\u4E0A\u4F20\u6587\u4EF6\u53EA\u80FD\u662F ".concat(this.fileTypes.join('|'), " \u683C\u5F0F!"));
        return isFileType;
      }

      if (!isLimit) {
        this.$message.error("\u4E0A\u4F20\u6587\u4EF6\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7 ".concat(this.size, "MB!"));
        return isFileType;
      }

      return isFileType && isLimit;
    }
  }
};
exports.default = _default2;