"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCardMember = addCardMember;
exports.delCardMemberLevel = delCardMemberLevel;
exports.editCardMember = editCardMember;
exports.editCardMemeberEquities = editCardMemeberEquities;
exports.getCardMemberById = getCardMemberById;
exports.listCardMember = listCardMember;
exports.listCardMemeberEquities = listCardMemeberEquities;
exports.listCompany = listCompany;
exports.listInstitutionInfoAllTreeNodes = listInstitutionInfoAllTreeNodes;
exports.listInstitutionTreeMember = listInstitutionTreeMember;
exports.listStore = listStore;
exports.listStorePage = listStorePage;

require("core-js/modules/es6.number.constructor");

var _request = _interopRequireDefault(require("@/utils/request"));

function addCardMember(data) {
  // 分页查询优惠券接口
  return (0, _request.default)({
    url: '/api/cardMember/addCardMember',
    method: 'post',
    data: data
  });
}
/*
 查询所有会员
 */


function listCompany(data) {
  return (0, _request.default)({
    url: '/api/company/listCompany',
    method: 'post',
    data: data
  });
}
/*
分店分页
 */


function listStorePage(data) {
  return (0, _request.default)({
    url: '/api/store/listStorePage',
    method: 'post',
    data: data
  });
}
/*
所有门店相关信息
 */


function listStore(data) {
  return (0, _request.default)({
    url: '/api/store/listStore',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listCardMember(pageNo, pageSize) {
  // 分页查询会员卡
  var data = {
    pageNo: pageNo,
    pageSize: pageSize
  };
  return (0, _request.default)({
    url: '/api/cardMember/listCardMember',
    method: 'post',
    data: data
  });
}

function listCardMemeberEquities(cardId) {
  // 分页查询优惠券接口
  var data = {
    cardId: cardId
  };
  console.log(data);
  return (0, _request.default)({
    url: '/api/cardMemeberEquities/listCardMemeberEquities',
    method: 'get',
    params: data
  });
}

function getCardMemberById(cardId) {
  // 分页查询优惠券接口
  var data = {
    cardId: Number(cardId)
  };
  return (0, _request.default)({
    url: '/api/cardMember/getCardMemberOtherById',
    method: 'get',
    params: data
  });
}

function listInstitutionInfoAllTreeNodes(arr) {
  // 查询机构(tree)接口  //不用
  return (0, _request.default)({
    url: '/api/institutionInfo/listInstitutionInfoAllTreeNodes',
    method: 'post',
    arr: arr
  });
}

function listInstitutionTreeMember(arr) {
  // 查询机构(tree)接口
  return (0, _request.default)({
    // url: '/api/institutionInfo/listInstitutionTreeMember',
    url: '/api/company/listBaseTree',
    method: 'post',
    arr: arr
  });
}

function editCardMemeberEquities(data) {
  // 分页查询优惠券接口
  return (0, _request.default)({
    url: '/api/cardMemeberEquities/editCardMemeberEquities',
    method: 'post',
    data: data
  });
}

function delCardMemberLevel(cardId) {
  // 分页查询优惠券接口
  var data = {
    id: cardId
  };
  return (0, _request.default)({
    url: '/api/cardMember/delCardMemberLevel',
    method: 'get',
    params: data
  });
}

function editCardMember(data) {
  // 编辑会员卡
  return (0, _request.default)({
    url: '/api/cardMember/editCardMember',
    method: 'post',
    data: data
  });
}