"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: "用户名称",
        prop: "userName",
        width: 50
      }, {
        label: "用户手机号",
        prop: "userMobile",
        width: 50
      }, {
        label: "会员类型",
        prop: "memberType",
        width: 50
      }, {
        label: "关键词",
        prop: "keyword",
        width: 50
      }, {
        label: "年度消费金额",
        prop: "annualConsumptionAmount",
        width: 50
      }, {
        label: "年度节省金额",
        prop: "annualSaveAmount",
        width: 50
      }, {
        label: "已兑换金额",
        prop: "exchangeScore",
        width: 50
      }, {
        label: "消耗积分",
        prop: "consumeScore",
        width: 50
      }, {
        label: "兑换时间",
        prop: "createTime",
        width: 50
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '用户手机号',
        value: 'userMobile'
      }, {
        type: 'dateTime',
        span: 4,
        placeholder: '选择年份',
        value: 'year',
        dateType: "year",
        valueFormat: "yyyy"
      }]
    };
  },
  methods: {
    goPageDwn: function goPageDwn(tHeader, filterVal, list) {
      list.forEach(function (item) {
        if (item.writeoffInfo) {
          var writeoffInfo = JSON.parse(item.writeoffInfo);
          item.dyOrderNo = writeoffInfo[0].orderNo || '';
        }
      });
      this.getHandleDownload(tHeader, filterVal, list, "抖音券核销记录");
    },
    goCasancelDyCoupon: function goCasancelDyCoupon(item) {
      var _this = this;

      this.apiPost('/api/dyCoupon/cancelDyCoupon', {
        id: item.id
      }).then(function (res) {
        _this.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;