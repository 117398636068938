"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _index2 = require("@/views/system/manage/index.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      time: null,
      requestData: {
        status: null,
        name: null,
        type: null,
        startTime: null,
        endTime: null,
        activityMonth: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      storeList: [],
      columns: [{
        label: '订单号',
        prop: 'orderNo',
        width: 100
      }, {
        label: '门店编码',
        prop: 'storeId',
        width: 50
      }, {
        label: '商品码',
        prop: 'goodsCode',
        width: 120
      }, {
        label: '订单金额',
        prop: 'totalPrice',
        width: 50
      }, {
        label: '活动券B优惠金额',
        prop: 'activityDiscountPrice',
        width: 80
      }, {
        label: '订单时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'status'
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {
    var _this = this;

    (0, _index2.listStorePage)({
      pageNo: 1,
      pageSize: 1000,
      storeStatus: 2
    }).then(function (res) {
      _this.storeList = res.data.items;
    });
  },
  activated: function activated() {
    this.recordPage();
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this2.requestData[key]) {
          data[key] = _this2.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.recordPage)(data).then(function (res) {
        list = res.data.records;
        _this2.downloadLoading = false;

        _this2.getHandleDownload(tHeader, filterVal, list, '活动券B列表');
      }).catch(function (res) {
        _this2.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.recordPage();
    },
    recordPage: function recordPage(type) {
      var _this3 = this;

      if (type === 'search') {
        this.current = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.recordPage)(this.requestData).then(function (res) {
        _this3.tableData = res.data.records;
        _this3.requestData.total = Number(res.data.total);
        return;
      });
    }
  }
};
exports.default = _default;