"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addVippriceBasic = addVippriceBasic;
exports.editVippriceBasic = editVippriceBasic;
exports.getVippriceBasic = getVippriceBasic;
exports.listGoodsPage = listGoodsPage;
exports.listVippriceBasicGoodsPage = listVippriceBasicGoodsPage;
exports.listVippriceBasicPage = listVippriceBasicPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
1、基础会员价策略分页：/api/vippriceBasic/listVippriceBasicPage
{"pageNo":"当前页","pageSize":"页条数","basicCode":"策略编码"}

2、新增基础会员价策略：/api/vippriceBasic/addVippriceBasic
{"basicName":"策略名称","basicStatus":"策略状态(1：启用 -1：停用)","companyCode":"公司编码","companyName":"公司名称","discountRate":"折扣率","cliqueRate":"集团承担比率","basicRemark":"策略备注","cditions":[{"attrName":"属性名称","attrCode":"属性编码","attrType":"属性类型(1：门店 2：商户 3：品类 4：品牌 5：商品)"}]}

3、编辑基础会员价策略：/api/vippriceBasic/editVippriceBasic
{"basicId","id","basicName":"策略名称","basicStatus":"策略状态(1：启用 -1：停用)","companyCode":"公司编码","companyName":"公司名称","discountRate":"折扣率","cliqueRate":"集团承担比率","basicRemark":"策略备注","cditions":[{"attrName":"属性名称","attrCode":"属性编码","attrType":"属性类型(1：门店 2：商户 3：品类 4：品牌 5：商品)"}]}

4、基础会员价策略明细：/api/vippriceBasic/getVippriceBasic/{basicId}

5、查询分类列表分页：/api/vippriceBasic/listClassPage
{"pageNo":"当前页","pageSize":"页条数"}

6、查询品牌列表分页：/api/vippriceBasic/listBrandPage
{"pageNo":"当前页","pageSize":"页条数"}

7、查询商品列表分页：/api/vippriceBasic/listGoodsPage
{"pageNo":"当前页","pageSize":"页条数"}

基础策略查询商品列表分页：/api/vippriceBasic/listVippriceBasicGoodsPage
 */
function listVippriceBasicGoodsPage(data) {
  return (0, _request.default)({
    url: '/api/vippriceBasic/listVippriceBasicGoodsPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listGoodsPage(data) {
  return (0, _request.default)({
    url: '/api/vippriceBasic/listGoodsPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listVippriceBasicPage(data) {
  return (0, _request.default)({
    url: '/api/vippriceBasic/listVippriceBasicPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
 */


function addVippriceBasic(data) {
  return (0, _request.default)({
    url: '/api/vippriceBasic/addVippriceBasic',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editVippriceBasic(data) {
  return (0, _request.default)({
    url: '/api/vippriceBasic/editVippriceBasic',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getVippriceBasic(data) {
  return (0, _request.default)({
    url: '/api/vippriceBasic/getVippriceBasic/' + data,
    method: 'post',
    loading: 'loading'
  });
}