"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSend = addSend;
exports.editSendStatus = editSendStatus;
exports.listSendPage = listSendPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 新增面对面发券
 */
function addSend(data) {
  return (0, _request.default)({
    url: '/api/sendCouponFace/addSend',
    method: 'post',
    data: data
  });
}
/*
面对面发券列表

 */


function listSendPage(data) {
  return (0, _request.default)({
    url: '/api/sendCouponFace/listSendPage',
    method: 'post',
    data: data
  });
}
/*
面对面发券状态修改

 */


function editSendStatus(data) {
  return (0, _request.default)({
    url: '/api/sendCouponFace/editSendStatus',
    method: 'post',
    data: data
  });
}