var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("新增优惠券包")]),
    _vm._v(" "),
    _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("方案状态")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c(
          "div",
          { staticClass: "box_step" },
          [
            _c(
              "el-steps",
              { attrs: { active: _vm.active } },
              _vm._l(_vm.step, function(item, index) {
                return _c("el-step", {
                  key: index,
                  attrs: { title: item.tit, description: item.txt }
                })
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "box_btn" }, [
          _c("p", [
            _vm._v("当前状态："),
            _c("span", [_vm._v(_vm._s(_vm.status))])
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "couponBag:edit",
                          expression: "'couponBag:edit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.goEditor }
                    },
                    [_vm._v("修改优惠券包")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "couponBag:audit",
                          expression: "'couponBag:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.setStatus(2)
                        }
                      }
                    },
                    [_vm._v("通过审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "couponBag:audit",
                          expression: "'couponBag:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.setStatus(3)
                        }
                      }
                    },
                    [_vm._v("撤回审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "couponBag:audit",
                          expression: "'couponBag:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.setStatus(4)
                        }
                      }
                    },
                    [_vm._v("撤销审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnType === 2
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "couponBag:audit",
                          expression: "'couponBag:audit'"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.setStatus(6)
                        }
                      }
                    },
                    [_vm._v("终止审核")]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("券包详情")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c("ul", [
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("券包名称：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.name))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("券包BANNER：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _c("img", { attrs: { src: _vm.imgSrc + _vm.mainImage } })
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("券包介绍/文案：")
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "dea_box_li_txt",
                domProps: { innerHTML: _vm._s(_vm.remark) }
              })
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [_vm._v("优惠券：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dea_box_li_coupon" },
                _vm._l(_vm.coupons, function(item, index) {
                  return _c(
                    "p",
                    { key: index, staticClass: "dea_box_li_txt" },
                    [
                      _c("span", { staticClass: "coupon_id" }, [
                        _vm._v(_vm._s(item.uuid))
                      ]),
                      _vm._v(
                        "\n                -" +
                          _vm._s(item.couponType) +
                          "-\n                "
                      ),
                      _c("span", { staticClass: "coupon_name" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _vm._v(
                        "\n                -（" +
                          _vm._s(item.validBeginTime) +
                          "至" +
                          _vm._s(item.validEndTime) +
                          "）\n              "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("投放放方案")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c("p", { staticClass: "deatil_tit none" }, [
          _vm._v("\n        方案未投放，暂无统计数据\n      ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }