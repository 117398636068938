"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _report = require("@/api/report.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _coupon = require("@/api/coupon.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      options: [],
      activityName: '',
      obj: {
        pageNo: 1,
        pageSize: 10,
        activityId: '',
        itemName: '',
        itemTitle: '',
        startDate: '',
        endDate: '',
        winCode: ''
      },
      downloadLoading: false,
      time: [],
      list: [],
      total: 0,
      tabTh: [{
        prop: 'itemName',
        label: '投票项名称(组名)',
        width: 60
      }, {
        prop: 'itemTitle',
        label: '投票项名称',
        width: 60
      }, {
        prop: 'voteUmobile',
        label: '联系电话',
        width: 100
      }, {
        prop: 'voteUid',
        label: '投票人UID',
        width: 60
      }, {
        prop: 'winCode',
        label: '中奖优惠券',
        width: 100
      }, {
        prop: 'voteTime',
        label: '投票时间',
        width: 120
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _coupon.listVotePage)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.obj.activityId = res.data.items[0].baseId;
      _this.activityName = res.data.items[0].activityName;
      _this.options = res.data.items;

      _this.init();
    });
  },
  methods: {
    voteChange: function voteChange(e) {
      var _this2 = this;

      this.options.forEach(function (item) {
        if (item.baseId == e) {
          _this2.activityName = item.activityName;
        }
      });
    },
    init: function init(type) {
      var _this3 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'remove') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
        this.time = '';
        this.obj.itemName = '';
        this.obj.itemTitle = '';
        this.obj.winCode = '';
        this.obj.startDate = '';
        this.obj.endDate = '';
      }

      if (this.time) {
        this.obj.startDate = this.time[0] || '';
        this.obj.endDate = this.time[1] || '';
      }

      this.openLoading();
      (0, _report.listVoteLogPage)(this.obj).then(function (res) {
        _this3.openLoading().close();

        _this3.total = res.data.totalNum;

        if (!_this3.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.voteUmobile) {
              item.voteUmobile = item.voteUmobile.replace(item.voteUmobile.substring(3, 7), "****");
            }
          });
        }

        _this3.list = res.data.items;
      }).catch(function (err) {
        _this3.openLoading().close();
      });
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['活动名称', '投票项名称(组名)', '投票项名称', '联系电话', '投票人UID', '中奖优惠券', '投票时间'];
        var filterVal = ['activityName', 'itemName', 'itemTitle', 'voteUmobile', 'voteUid', 'winCode', 'voteTime'];
        var list = [];

        if (_this4.time.length > 0) {
          _this4.obj.startDate = _this4.time[0] || '';
          _this4.obj.endDate = _this4.time[1] || '';
        }

        var data = Object.assign({}, _this4.obj);
        data.pageSize = _this4.total;
        data.pageNo = 1;

        _this4.openLoading();

        (0, _report.listVoteLogPage)(data).then(function (res) {
          list = res.data.items;
          list.forEach(function (item) {
            if (!_this4.setMenuPerms('user_mobile_idCard')) {
              if (item.voteUmobile) {
                item.voteUmobile = item.voteUmobile.replace(item.voteUmobile.substring(3, 7), "****");
              }
            }

            item.activityName = _this4.activityName;
          });

          var data = _this4.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '投票统计'
          });
          _this4.downloadLoading = false;

          _this4.openLoading().close();
        }).catch(function (res) {
          _this4.downloadLoading = false;

          _this4.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;