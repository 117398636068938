var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("会员发券")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "券信息：", prop: "couponCode" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.getRelevances }
                    },
                    [_vm._v("选择")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "券名称：", "label-width": "70px" } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.ruleForm.couponName) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "券编码：", "label-width": "70px" } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.ruleForm.couponCode) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "乐购券id：" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.couponLgid,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "couponLgid",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.couponLgid"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发券数量：", prop: "sendNumber" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.sendNumber,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "sendNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.sendNumber"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发券状态：", prop: "sendStatus" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.sendStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "sendStatus", $$v)
                        },
                        expression: "ruleForm.sendStatus"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.ruleForm.sendStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "sendStatus", $$v)
                        },
                        expression: "ruleForm.sendStatus"
                      }
                    },
                    [_vm._v("禁用")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态：", prop: "sendType" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.sendType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "sendType", $$v)
                        },
                        expression: "ruleForm.sendType"
                      }
                    },
                    [_vm._v("购买触发")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.ruleForm.sendType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "sendType", $$v)
                        },
                        expression: "ruleForm.sendType"
                      }
                    },
                    [_vm._v("每周二触发")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注：", prop: "sendRemark" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "textarea", rows: 4 },
                    model: {
                      value: _vm.ruleForm.sendRemark,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "sendRemark",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.sendRemark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("coupon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "couponPop",
        on: { setAwardItem: _vm.setCoupons, closeCouponPop: _vm.closeCouponPop }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }