"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    WTable: _index.default
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      urlList: _selectUrl.default.urlList,
      appIdArr: _selectUrl.default.appIdArr,
      downloadLoading: false,
      requestData: {
        productType: '',
        productCode: '',
        vipCode: '',
        classId: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        slot: 'plusIcon',
        label: '会员图标',
        prop: 'plusIcon',
        width: 100
      }, {
        label: '类型名称',
        prop: 'typeName',
        width: 80
      }, {
        label: '生效天数',
        prop: 'validDay',
        width: 50
      }, {
        slot: 'vipRule',
        width: 80
      }, {
        label: '原价',
        prop: 'originalAmount',
        width: 50
      }, {
        label: '销售金额',
        prop: 'saleAmount',
        width: 50
      }, {
        label: '赠送积分',
        prop: 'giveScore',
        width: 50
      }, {
        label: '投放数量',
        prop: 'putNumber',
        width: 50
      }, {
        label: '今日已卖',
        prop: 'totalSaleNumber',
        width: 50
      }, {
        slot: 'firstCoupon',
        label: '第一次赠送优惠券',
        prop: 'firstCoupon',
        width: 80
      }, {
        slot: 'sendCoupon',
        label: '第二次赠送优惠券',
        prop: 'sendCoupon',
        width: 80
      }, {
        slot: 'plusStatus',
        label: '状态',
        prop: 'plusStatus',
        width: 50
      }, {
        slot: 'operate'
      }]
    };
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {},
  methods: {
    statusChange: function statusChange(e, scope) {
      var _this = this;

      return;
      var data = {
        firstCoupons: JSON.parse(scope.row.firstCoupon),
        giveScore: scope.row.giveScore,
        id: scope.row.id,
        limitNumber: scope.row.limitNumber,
        limitType: scope.row.limitType,
        plusIcon: scope.row.plusIcon,
        plusInterests: scope.row.plusInterests,
        plusStatus: e,
        putNumber: scope.row.putNumber,
        saleAmount: scope.row.saleAmount,
        sendCoupons: JSON.parse(scope.row.sendCoupon),
        typeName: scope.row.typeName,
        validDay: scope.row.validDay
      };
      (0, _api.editVipPlus)(data).then(function (res) {
        _this.getList();
      });
      return;
      this.tableData[scope.$index].plusStatus = e;
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];
        var data = {};

        _this2.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this2.requestData).forEach(function (key) {
          if (_this2.requestData[key]) {
            data[key] = _this2.requestData[key];
          }
        });
        data.pageSize = _this2.requestData.total;
        data.pageNo = 1;
        (0, _api.listVipPlus)(data).then(function (res) {
          list = res.data.items;

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '同城生活商品'
          });
          _this2.downloadLoading = false;
        }).catch(function (res) {
          _this2.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.removeObj(this.requestData);
      }

      (0, _api.listVipPlus)(this.requestData).then(function (res) {
        _this3.tableData = res.data; // this.requestData.total = res.data

        return;
      });
    }
  }
};
exports.default = _default;