"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _integral = require("@/api/integral.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      obj: {
        pageNo: 1,
        pageSize: 10,
        changeType: 5,
        userMobile: ''
        /* orderNo: '',
        mobile: '',
        idNo: '',
        storedCode: '',
        sendUname: '',
        startDate: '',
        endDate: '' */

      },
      downloadLoading: false,
      total: 0,
      time: [],
      tableData: [],
      tabTh: [{
        prop: 'userMobile',
        label: '会员手机号',
        width: 100
      }, {
        prop: 'changeScore',
        label: '积分变动',
        width: 80
      }, {
        prop: 'storeCode',
        label: '门店编码',
        width: 80
      }, {
        prop: 'changeTime',
        label: '操作时间',
        width: 140
      }, {
        prop: 'oprUname',
        label: '操作人',
        width: 80
      }, {
        prop: 'changeRemark',
        label: '备注',
        width: 150
      }]
    };
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
      }

      if (type == 'remove') {
        this.obj.pageNo = 1;
        this.obj.orderNo = '';
        this.obj.userMobile = '';
      }

      this.openLoading();
      console.log(this.obj);
      (0, _integral.listUserScorePage)(this.obj).then(function (res) {
        _this.openLoading().close();

        if (!_this.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.userMobile) {
              item.userMobile = item.userMobile.replace(item.userMobile.substring(3, 7), "****");
            }
          });
        }

        _this.total = res.data.totalNum;
        _this.tableData = res.data.items;
      }).catch(function (res) {
        _this.openLoading().close();
      });
    }
  }
};
exports.default = _default;