"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _planInto = _interopRequireDefault(require("./planInto.vue"));

var _manualInto = _interopRequireDefault(require("./manualInto.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    PlanInto: _planInto.default,
    ManualInto: _manualInto.default
  },
  data: function data() {
    return {
      intoType: {
        val: 1
      },
      rules: {
        val: [{
          required: true,
          message: '请选择入库类型',
          trigger: 'change'
        }]
      }
    };
  }
};
exports.default = _default;