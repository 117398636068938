var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("新增页面模板")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            size: "small"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { label: "最后修改时间：" }
            },
            [_vm._v("\n      " + _vm._s(_vm.modifyTime) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "模板名称：", prop: "tmplateName" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "300px" },
                model: {
                  value: _vm.ruleForm.tmplateName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "tmplateName", $$v)
                  },
                  expression: "ruleForm.tmplateName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "模板分类：", prop: "classId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "maxWidth",
                  attrs: { filterable: "", placeholder: "请选择模板分类" },
                  model: {
                    value: _vm.ruleForm.classId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "classId", $$v)
                    },
                    expression: "ruleForm.classId"
                  }
                },
                _vm._l(_vm.temClass, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.className, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即创建")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }