"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

/** When your routing table is too long, you can split it into small modules **/
var svipReplaceRouter = {
  path: 'replace',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
    });
  },
  redirect: '/svip/replace/list',
  name: 'svipReplace',
  meta: {
    title: '代客领取礼品'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tab.vue"));
      });
    },
    name: 'svipReplaceTab',
    meta: {
      title: '代客领取礼品',
      activeMenu: '/svip/manager/tab'
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./apply/list.vue"));
      });
    },
    name: 'svipReplaceList',
    meta: {
      title: '要客确认记录',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/svip/reservePic/queryPageReservePic',
      activeMenu: '/svip/manager/tab',
      columns: [{
        slot: 'cover'
      }, {
        label: '开始时间',
        prop: 'startTime',
        width: 60
      }, {
        label: '结束时间',
        prop: 'endTime',
        width: 60
      }, {
        slot: 'contentType'
      }, {
        slot: 'status'
      }, {
        label: '操作时间',
        prop: 'createTime',
        width: 60
      }, {
        slot: 'update'
      }]
    }
  }, {
    path: 'mySvip',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./mySvip/list.vue"));
      });
    },
    name: 'svipMySvipList',
    meta: {
      title: '我的要客会员',
      icon: 'el-icon-s-cooperation',
      pageList: 'items',
      isOneRequest: 'no',
      pageTotal: 'totalNum',
      method: 'get',
      api: '/api/svip/page',
      activeMenu: '/svip/manager/tab'
    }
  }, {
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./goods.vue"));
      });
    },
    name: 'svipMySvipgoods',
    meta: {
      title: '可领取礼品',
      icon: 'el-icon-s-cooperation',
      pageList: null,
      api: '/api/svip/right/listUnClaimedRightGift',
      isOneRequest: 'no',
      activeMenu: '/svip/manager/tab'
    }
  }]
};
var _default = svipReplaceRouter;
exports.default = _default;