"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '活动券管理',
      list: [{
        tit: '活动列表',
        txtArr: '<p>活动券-活动列表</p>    ',
        imgPath: require('@/assets/tab/1.png'),
        path: '/couponActivity/couponC'
      },
      /* {
        tit: '新增活动',
        txtArr: '<p>活动券C-新增活动</p>',
        imgPath: require('@/assets/tab/3.png'),
        path: '/couponActivity/addTab'
      }, */
      {
        tit: '活动订单',
        txtArr: '<p>活动券-活动订单</p>',
        imgPath: require('@/assets/tab/2.png'),
        path: '/couponActivity/couponCOrder'
      }]
    };
  }
};
exports.default = _default;