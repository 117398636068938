"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      id: '',
      title: '新增领券方案',
      couponId: '',
      time: '',
      radio: '2',
      // limitNum: '',
      putTotal: ''
    };
  },
  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.id = Number(this.$route.query.unid);

      if (this.id) {
        this.title = '编辑领券方案';
        (0, _coupon.getPutCenterById)(this.id).then(function (res) {
          console.log(res);
          _this.couponId = res.data.couponId;
          _this.putTotal = res.data.putTotal; // this.limitNum=res.data.limitNum

          _this.radio = String(res.data.onlineTime);
          console.log(_this.radio);

          if (res.data.onlineTime === 1) {
            _this.time = [res.data.onlineBeginTime, res.data.onlineEndTime];
          }
        });
      }
    },
    next: function next() {
      var _this2 = this;

      if (this.couponId === '') {
        this.$message.error('请填写优惠券ID');
        return;
      } else if (this.radio === '1' && this.time === '') {
        this.$message.error('请选择上线时间');
        return;
      } // } else if(this.putTotal==='') {
      // 	this.$message.error('请正确填写投放总数量');
      // 	return
      //  }
      else {
          console.log(this.putTotal);
          (0, _coupon.couponSchemeValid)(this.couponId, this.time[0], this.time[1]).then(function (res) {
            var obj = {
              onlineTime: _this2.radio,
              couponId: res.data,
              onlineBeginTime: _this2.time[0],
              onlineEndTime: _this2.time[1] // putTotal: this.putTotal,
              // limitNum: this.limitNum

            };

            _this2.$confirm('请确认信息是否正确?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(function () {
              console.log(obj);

              if (_this2.id) {
                obj.id = _this2.id;
                sessionStorage.setItem('orientObj', JSON.stringify(obj));

                _this2.$router.push('/coupon/couponPut/affirmAdd?id=volume&unid=' + _this2.id);
              } else {
                sessionStorage.setItem('orientObj', JSON.stringify(obj));

                _this2.$router.push('/coupon/couponPut/affirmAdd?id=volume');
              }
            }).catch(function () {
              _this2.$message({
                type: 'info',
                message: '已取消'
              });
            });
          });
        }
    }
  }
};
exports.default = _default;