"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _mem = require("@/api/mem.js");

var _integral = require("@/api/integral.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      list: [],
      total: 0,
      companyList: [],
      obj: {
        pageNo: 1,
        pageSize: 10,
        companyCode: '',
        goodsCode: '',
        basicCode: ''
      },
      tabTh: [{
        prop: 'goodsCode',
        label: '商品编码',
        width: 50
      }, {
        prop: 'goodsName',
        label: '商品名称',
        width: 100
      }, {
        prop: 'basicCode',
        label: '基础策略编码',
        width: 70
      }, {
        prop: 'classCode',
        label: '分类编码',
        width: 50
      }, {
        prop: 'brandCode',
        label: '品牌',
        width: 50
      }, {
        prop: 'sellAmount',
        label: '普会消费',
        width: 50
      }, {
        prop: 'sell365Amount',
        label: '365消费',
        width: 50
      }, {
        prop: 'selllavantAmount',
        label: 'lavant消费',
        width: 50
      }, {
        prop: 'getScore',
        label: '获得积分',
        width: 50
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companyList = res.data;
    });
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this2 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
      }

      this.openLoading();
      (0, _integral.listScoreBasicGoodsPage)(this.obj).then(function (res) {
        _this2.openLoading().close();

        res.data.items.forEach(function (item) {
          if (item.brandCode == '-1') {
            item.brandCode = '';
          }
        });
        _this2.list = res.data.items;
        _this2.total = res.data.totalNum;
      }).catch(function (err) {
        _this2.openLoading().close();
      });
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['总部', '总部编码', '门店', '商品编码', '商品名称', '基础策略编码', '分类编码', '品牌', '消费金额', '获得积分'];
        var filterVal = ['companyName', 'companyCode', 'storeName', 'goodsCode', 'goodsName', 'basicCode', 'classCode', 'brandCode', 'sellAmount', 'getScore'];
        var list = [];
        var data = JSON.parse(JSON.stringify(_this3.obj));
        data.pageSize = _this3.total;

        _this3.openLoading();

        (0, _integral.listScoreBasicGoodsPage)(data).then(function (res) {
          list = res.data.items;

          var data = _this3.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '基础策略商品 '
          });
          _this3.downloadLoading = false;

          _this3.openLoading().close();
        }).catch(function (res) {
          _this3.openLoading().close();

          _this3.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;