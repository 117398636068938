var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type == "class"
        ? _c("h2", { staticClass: "box_tit" }, [_vm._v("新增/编辑样式类型")])
        : _c("h2", { staticClass: "box_tit" }, [_vm._v("新增/编辑样式")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            size: "small"
          }
        },
        [
          _vm.type != "class"
            ? _c(
                "el-form-item",
                { attrs: { label: "模板分类：", prop: "parentId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "maxWidth",
                      attrs: { filterable: "", placeholder: "请选择模板分类" },
                      model: {
                        value: _vm.ruleForm.parentId,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "parentId", $$v)
                        },
                        expression: "ruleForm.parentId"
                      }
                    },
                    _vm._l(_vm.classArr, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.styleName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "名称：", prop: "styleName" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "300px" },
                model: {
                  value: _vm.ruleForm.styleName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "styleName", $$v)
                  },
                  expression: "ruleForm.styleName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.type == "class"
            ? _c(
                "el-form-item",
                { attrs: { label: "样式编码：", prop: "styleCode" } },
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "300px" },
                    model: {
                      value: _vm.ruleForm.styleCode,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "styleCode", $$v)
                      },
                      expression: "ruleForm.styleCode"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type != "class"
            ? _c(
                "el-form-item",
                { attrs: { label: "样式图：", prop: "thumbnailPath" } },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 300,
                      height: 100,
                      cover: _vm.ruleForm.thumbnailPath
                    },
                    on: { setCover: _vm.setCover }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态：", prop: "styleStatus" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.styleStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "styleStatus", $$v)
                    },
                    expression: "ruleForm.styleStatus"
                  }
                },
                [_vm._v("启用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.ruleForm.styleStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "styleStatus", $$v)
                    },
                    expression: "ruleForm.styleStatus"
                  }
                },
                [_vm._v("禁用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即创建")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }