"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '无流水开票',
      list: [{
        tit: '经营主体企业信息管理',
        txtArr: '经营主体企业信息管理',
        imgPath: require('@/assets/tab/23.png'),
        path: '/finance/no-transaction-flow/enterprise'
      }, {
        tit: '发票项目剩余额度',
        txtArr: '发票项目剩余额度',
        imgPath: require('@/assets/tab/20.png'),
        path: '/finance/no-transaction-flow/limit'
      }, {
        tit: '无流水开票',
        txtArr: '无流水开票',
        imgPath: require('@/assets/tab/20.png'),
        path: '/finance/no-transaction-flow/invoice'
      }]
    };
  }
};
exports.default = _default;