var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            "search-data": _vm.searchData
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "cfgType",
              label: "通道类型",
              align: "center",
              width: "120"
            },
            slot: "cfgType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.cfgType == 1
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("主通道")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.cfgType == 2
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("活动通道")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.cfgType == 3
                      ? _c("span", { staticClass: "primary" }, [
                          _vm._v("补录通道")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.cfgType == 4
                      ? _c("span", { staticClass: "primary" }, [
                          _vm._v("银行虚拟券")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "refType",
              label: "关联类型",
              align: "center",
              width: "120"
            },
            slot: "refType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.refType == 1
                      ? _c("span", { staticClass: "success" }, [_vm._v("门店")])
                      : _vm._e(),
                    _vm._v(" "),
                    row.refType == 2
                      ? _c("span", { staticClass: "primary" }, [_vm._v("设备")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "limitPay",
              label: "可支付方式",
              align: "center",
              width: "120"
            },
            slot: "limitPay",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.limitPay == 2
                      ? _c("span", { staticClass: "success" }, [_vm._v("微信")])
                      : _vm._e(),
                    _vm._v(" "),
                    row.limitPay == 3
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("支付宝")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.limitPay == 6
                      ? _c("span", { staticClass: "primary" }, [
                          _vm._v("云闪付")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.limitPay == 23
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("微信支付宝")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.limitPay == 26
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("微信云闪付")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.limitPay == 36
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("支付宝云闪付")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.limitPay == 236
                      ? _c("span", { staticClass: "primary" }, [
                          _vm._v("微信支付宝云闪付")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              align: "center",
              width: "120"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/payment/channelsAdd?refNo=" + scope.row.refNo
                            )
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }