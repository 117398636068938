"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _getCoupon = require("@/api/getCoupon.js");

var _label = _interopRequireDefault(require("@/components/label/label.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    labelPop: _label.default
  },
  data: function data() {
    return {
      labelShow: false,
      channelType: '',
      id: '',
      data: {
        couponType: '',
        recenterLabels: []
      }
    };
  },
  created: function created() {
    var _this = this;

    this.id = Number(this.$route.query.id);
    this.channelType = Number(this.$route.query.channelType);

    if (this.id) {
      (0, _getCoupon.channelRecenter)(this.id).then(function (res) {
        console.log(res);

        switch (res.data.couponType) {
          case 1:
            res.data.couponType = '代金券';
            break;

          case 2:
            res.data.couponType = '折扣券';
            break;

          case 3:
            res.data.couponType = '礼品券';
            break;

          case 4:
            res.data.couponType = '体验券';
            break;

          case 5:
            res.data.couponType = '外部优惠券';
            break;

          default:
            break;
        }

        _this.data = res.data;

        _this.$refs.labels.setTable(_this.data.recenterLabels);
      });
    }
  },
  methods: {
    setLabel: function setLabel() {
      this.labelShow = true;
    },
    addLabel: function addLabel(e) {
      var _this2 = this;

      this.data.recenterLabels = [];
      console.log(e);
      e.forEach(function (item) {
        _this2.data.recenterLabels.push({
          labelId: item.labelId,
          labelName: item.labelName
        });
      });
      this.$confirm('确认修改标签, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log(_this2.data.recenterLabel);
        var data = {
          centerId: _this2.id,
          channelType: _this2.channelType,
          couponCode: _this2.data.couponCode,
          centerStatus: _this2.data.centerStatus,
          onlineType: _this2.data.onlineType,
          onlineStartTime: _this2.data.onlineStartTime,
          onlineEndTime: _this2.data.onlineEndTime,
          putNumber: _this2.data.putNumber,
          recenterLabel: _this2.data.recenterLabels
        };
        console.log(JSON.stringify(data));
        (0, _getCoupon.editRecenter)(data).then(function (res) {
          _this2.labelShow = false;

          _this2.$message({
            type: 'success',
            message: '修改成功!'
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消修改'
        });
      });
    },
    closeLabel: function closeLabel() {
      this.labelShow = false;
    }
  }
};
exports.default = _default;