"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typeOptions = exports.storeOptions = exports.specialInvoiceStatusOptions = void 0;
var typeOptions = [{
  value: 1,
  label: '企业'
}, {
  value: 3,
  label: '非企业型组织'
}, {
  value: 2,
  label: '个人'
}];
exports.typeOptions = typeOptions;
var specialInvoiceStatusOptions = [{
  value: -1,
  label: '开票审核不通过'
}, {
  value: 0,
  label: '专票：开票中 普票：待审'
}, {
  value: 1,
  label: '成功'
}, {
  value: 2,
  label: '开票失败'
}];
exports.specialInvoiceStatusOptions = specialInvoiceStatusOptions;
var storeOptions = [{
  value: '01001011',
  label: '乐品中华老店'
}, {
  value: '12001',
  label: '荔星名店B座'
}, {
  value: '12002',
  label: '星力瑞金店'
}, {
  value: '12003',
  label: '荔星名店A座'
}, {
  value: '12012',
  label: '星力城贵阳汇金店'
}, {
  value: '12011',
  label: '荔星中心'
}, {
  value: '01001001',
  label: '乐品中华超市'
}, {
  value: '01002001',
  label: '乐品富源超市'
}, {
  value: '01001002',
  label: '乐品瑞金超市'
}, {
  value: '02001006',
  label: '乐品便利店友谊路店'
}, {
  value: '02002007',
  label: '乐品便利店青云路店'
}, {
  value: '11004',
  label: '贵阳星力广场超市'
}, {
  value: '01002002',
  label: '乐品望城超市'
}, {
  value: '01006001',
  label: '乐品白云南路超市'
}, {
  value: '01001003',
  label: '乐品小石城店'
}, {
  value: '01001004',
  label: '乐品中天超市'
}, {
  value: '01011001',
  label: '乐品遵义超市C'
}, {
  value: '01005001',
  label: '乐品花溪明珠超市'
}, {
  value: '01001005',
  label: '乐品元隆超市'
}, {
  value: '01002003',
  label: '乐品裕阳超市'
}, {
  value: '01007001',
  label: '乐品清镇云岭路店'
}, {
  value: '11014',
  label: '遵义星力遵义超市A'
}, {
  value: '11015',
  label: '乐品星小铺'
}, {
  value: '01001006',
  label: '乐品日月园超市'
}, {
  value: '01003001',
  label: '乐品金华园超市'
}, {
  value: '11018',
  label: '贵阳星力汇金IU馆'
}, {
  value: '01003002',
  label: '乐品美的林城超市'
}, {
  value: '01003003',
  label: '乐品中铁逸都超市'
}, {
  value: '01003004',
  label: '乐品会展城超市'
}, {
  value: '11022',
  label: '遵义星力遵义常青藤超市'
}, {
  value: '01003005',
  label: '乐品观山湖1号超市'
}, {
  value: '11024',
  label: '乐品裕华超市'
}, {
  value: '11025',
  label: '贵阳星力凤凰湾超市'
}, {
  value: '01003006',
  label: '乐品碧海广场超市'
}, {
  value: '01001007',
  label: '乐品蔡家街超市'
}, {
  value: '01005002',
  label: '乐品花溪万宜超市'
}, {
  value: '01003007',
  label: '乐品恒大金朱东路店'
}, {
  value: '01001008',
  label: '乐品万科云岩大都会店'
}, {
  value: '01005003',
  label: '乐品南山高地'
}, {
  value: '01099009',
  label: '贵阳星力配送中心'
}, {
  value: '01002004',
  label: '乐品南厂路店'
}];
exports.storeOptions = storeOptions;