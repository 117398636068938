"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _api = require("./api.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _mem = require("@/api/mem.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    coupon: _radioItem.default,
    addLoad: _addLoad.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      time: null,
      count: 0,
      popType: null,
      status: null,
      storeList: [],
      type: [{
        label: '优惠券包',
        value: 2
      }, {
        label: '优惠券',
        value: 1
      }],
      couponShow: false,
      columns: [{
        label: '券编码',
        prop: 'couponCode',
        width: 100
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 100
      }, {
        label: '券类型',
        prop: 'couponType',
        width: 100
      }, {
        label: '面额/折扣',
        prop: 'disdenStr',
        width: 100
      }, {
        label: '有效期',
        prop: 'validStr',
        width: 100
      }],
      rules: {
        'messageParam.labelContent': [{
          required: true,
          message: '请选择会员标签'
        }],
        'messageParam.objectLabelContent': [{
          required: true,
          message: '请选择会员标签'
        }],
        'messageParam.storeCode': [{
          required: true,
          message: '请选择门店'
        }],
        'messageParam.activityName': [{
          required: true,
          message: '请输入名称'
        }],
        'messageParam.activityStartTime': [{
          required: true,
          message: '请选择活动时间'
        }],
        'messageParam.activityImages': [{
          required: true,
          message: '请上传图片'
        }],
        'messageParam.activityBackgroundColor': [{
          required: true,
          message: '请选择颜色'
        }],
        'messageParam.mainButtonColor': [{
          required: true,
          message: '请选择颜色'
        }],
        'messageParam.activityLimitPersons': [{
          required: true,
          message: '请输入总人数限制'
        }],
        'messageParam.activityBeHelpedObjectType': [{
          required: true,
          message: '请选择发起人类型'
        }],
        'messageParam.objectHelpType': [{
          required: true,
          message: '请选择助力人类型'
        }],
        'messageParam.objectHelpNumber': [{
          required: true,
          message: '请选输入可助力数'
        }],
        'rulesParam.activityRules': [{
          required: true,
          message: '请选择类型'
        }],
        'rulesParam.activityLevelReceiveNumber': [{
          required: true,
          message: '请输入次数'
        }],
        rewardParams: [{
          required: true,
          message: '请添加助力层级'
        }],
        'conditionParam.objectRulesDescribe': [{
          required: true,
          message: '请输入规则说明'
        }],
        'conditionParam.objectShareTitle': [{
          required: true,
          message: '请输入分享文案'
        }],
        'conditionParam.objectShareImage': [{
          required: true,
          message: '请上传分享图片'
        }]
      },
      activityCode: null,
      id: null,
      userLabelList: [],
      ruleForm: {
        messageParam: {
          id: null,
          storeCode: null,
          storeName: null,
          activityCode: null,
          activityName: null,
          activityImages: null,
          activityStartTime: null,
          activityEndTime: null,
          activityBackgroundColor: '#0E1637',
          mainButtonColor: '#FFAC56',
          activityLimitPersons: null,
          activityBeHelpedObjectType: null,
          objectHelpType: null,
          objectHelpNumber: null,
          labelContent: null,
          objectLabelContent: null
        },
        rulesParam: {
          id: null,
          activityRules: null,
          activityLevelReceiveNumber: null
        },
        conditionParam: {
          id: null,
          objectRulesDescribe: null,
          objectShareTitle: null,
          objectShareImage: null
        },
        rewardParams: []
      }
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.ruleForm.messageParam.activityStartTime = val[0];
        this.ruleForm.messageParam.activityEndTime = val[1];
      } else {
        this.ruleForm.messageParam.activityStartTime = null;
        this.ruleForm.messageParam.activityEndTime = null;
      }
    }
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
      _this.userLabelList = [{
        id: 0,
        labelName: '所有会员'
      }].concat((0, _toConsumableArray2.default)(res.data));
    });
    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.storeList = res.data;
    });

    if (this.$route.query.id) {
      this.status = this.$route.query.status;
      this.id = this.$route.query.id;
      (0, _api.getHelp)(this.$route.query.id).then(function (res) {
        _this.time = [res.data.activityStartTime, res.data.activityEndTime];
        _this.ruleForm.rewardParams = res.data.rewardParamList;
        _this.activityCode = res.data.activityCode;
        Object.keys(res.data).forEach(function (key) {
          Object.keys(_this.ruleForm.messageParam).forEach(function (item) {
            if (item === key) {
              _this.ruleForm.messageParam[item] = res.data[item];
            }
          });
          Object.keys(_this.ruleForm.rulesParam).forEach(function (item) {
            if (item === key) {
              _this.ruleForm.rulesParam[item] = res.data[item];
            }
          });
          Object.keys(_this.ruleForm.conditionParam).forEach(function (item) {
            if (item === key) {
              _this.ruleForm.conditionParam[item] = res.data[item];
            }
          });
        });
      });
    }
  },
  methods: {
    onSubmit: function onSubmit(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          var storeData = _this2.storeList.find(function (item) {
            return item.storeCode === _this2.ruleForm.messageParam.storeCode;
          });

          _this2.ruleForm.messageParam.storeName = storeData.shortName;

          if (_this2.id) {
            (0, _api.editHelp)(_this2.ruleForm, 'put').then(function (res) {
              _this2.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addHelp)(_this2.ruleForm, 'post').then(function (res) {
              _this2.$message({
                message: '新增成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            });
          }
        } else {
          var data = Object.keys(object);
          var dom = _this2.$refs[data[0]];
          dom.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
        }
      });
    },
    delAward: function delAward(index) {
      this.ruleForm.rewardParams.splice(index, 1);
    },

    /*
              打开弹框
              */
    getAward: function getAward(e, index) {
      this.count = index;

      if (e === 1) {
        this.popType = 'coupon';
      } else {
        this.popType = 'couponBag';
      }

      this.$refs.couponPop.getList(this.popType, this.ruleForm.rewardParams[index].prizeCode, e);
      this.couponShow = true;
    },
    setAward: function setAward(e, index) {
      this.ruleForm.rewardParams[index].activityNeedPersons = null;
      this.ruleForm.rewardParams[index].prizeCode = null;
      this.ruleForm.rewardParams[index].prizeName = null;
      this.ruleForm.rewardParams[index].prizeNumber = null;
      this.ruleForm.rewardParams[index].receiveNumber = null;
    },
    addRule: function addRule() {
      this.ruleForm.rewardParams.push({
        activityLevel: null,
        // 活动助力级别
        activityNeedPersons: null,
        // 完成该活动级别需要的人数
        prizeCode: null,
        // 奖品编码
        prizeType: null,
        // 该级别活动奖励的奖品类型
        prizeName: null,
        // 该级别活动奖励的奖品名称
        prizeNumber: null,
        // 奖品的数量
        receiveNumber: null,
        // 满足条件该奖品可以被领取的次数
        activityMessageId: this.id || null,
        activityCode: this.activityCode || null
      });
    },
    setCover: function setCover(e, type, type1) {
      this.ruleForm[type][type1] = e;
      console.log(this.ruleForm[type][type1]);
    },
    getRelevances: function getRelevances() {
      this.$refs.couponPop.getList('coupon', this.ruleForm.couponCode);
      this.couponShow = true;
    },
    setCoupons: function setCoupons(e) {
      this.ruleForm.rewardParams[this.count].prizeCode = e.uuid;
      this.ruleForm.rewardParams[this.count].prizeName = e.name;
      this.couponShow = false;
    },
    closeCouponPop: function closeCouponPop() {
      this.couponShow = false;
    }
  }
};
exports.default = _default;