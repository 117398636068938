var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品税收分类编码：", prop: "spbm" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.spbm,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "spbm",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.spbm"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "税率：", prop: "sl" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.sl,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "sl",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.sl"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.sl == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "零税率标识：", prop: "lslbs" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.lslbs,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "lslbs", $$v)
                            },
                            expression: "ruleForm.lslbs"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("普通零税率")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("不征税")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _vm.setMenuPerms("taxRate:update") ||
                  _vm.setMenuPerms("taxRate:add")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("立即创建")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }