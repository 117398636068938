var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-calendar-form" },
    [
      _c("div", { staticClass: "view_title" }, [
        _vm._v(_vm._s(_vm.id ? "编辑" : "新建") + "日历")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("el-row", { attrs: { gutter: 0, type: "flex" } }, [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                attrs: {
                  "label-width": "110px",
                  model: _vm.ruleForm,
                  rules: _vm.rules
                }
              },
              [
                _c("Descriptions", { attrs: { title: "基本信息" } }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "活动月：", prop: "activityMonth" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "month",
                        disabled: !!_vm.id,
                        "picker-options": _vm.disabledMonth,
                        "value-format": "yyyy-MM",
                        placeholder: "选择月份"
                      },
                      on: { change: _vm.changeMonth },
                      model: {
                        value: _vm.ruleForm.activityMonth,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "activityMonth", $$v)
                        },
                        expression: "ruleForm.activityMonth"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "configRuleForm",
                    attrs: {
                      "label-width": "110px",
                      model: _vm.configRuleForm,
                      rules: _vm.configRules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form_item",
                        attrs: { label: "活动配置：", size: "small" }
                      },
                      [
                        _c("el-row", { attrs: { gutter: 0, type: "flex" } }, [
                          _c("div", [
                            _c("span", { staticClass: "form_label" }, [
                              _vm._v("已选")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "pr10",
                                    attrs: { prop: "pickDate" }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        disabled: !_vm.ruleForm.activityMonth,
                                        type: "date",
                                        "default-value": _vm.timeDefaultShow,
                                        "picker-options": _vm.disabledDate,
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "选择日期"
                                      },
                                      on: { change: _vm.changeDate },
                                      model: {
                                        value: _vm.configRuleForm.pickDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configRuleForm,
                                            "pickDate",
                                            $$v
                                          )
                                        },
                                        expression: "configRuleForm.pickDate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "pr10",
                                    attrs: { prop: "activityId" }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "form_input",
                                        staticStyle: { width: "150px" },
                                        attrs: {
                                          placeholder: "选择活动",
                                          disabled:
                                            !_vm.ruleForm.activityMonth ||
                                            !_vm.configRuleForm.pickDate
                                        },
                                        model: {
                                          value: _vm.configRuleForm.activityId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.configRuleForm,
                                              "activityId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configRuleForm.activityId"
                                        }
                                      },
                                      _vm._l(_vm.activityOptions, function(
                                        activity
                                      ) {
                                        return _c("el-option", {
                                          key: activity.value,
                                          attrs: {
                                            label: activity.label,
                                            value: activity.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                  "padding-top": "20px"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "pr10",
                                    staticStyle: { width: "80px" },
                                    attrs: { prop: "color" }
                                  },
                                  [
                                    _c("el-color-picker", {
                                      attrs: {
                                        disabled: !_vm.ruleForm.activityMonth
                                      },
                                      model: {
                                        value: _vm.configRuleForm.color,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configRuleForm,
                                            "color",
                                            $$v
                                          )
                                        },
                                        expression: "configRuleForm.color"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { width: "150px" },
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.submitConfigForm(
                                          "configRuleForm"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    确认添加\n                  "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "日历背景色：", prop: "bgColor" } },
                  [
                    _c("el-color-picker", {
                      model: {
                        value: _vm.ruleForm.bgColor,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "bgColor", $$v)
                        },
                        expression: "ruleForm.bgColor"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "日历月份图：", prop: "monthPic" } },
                  [
                    _c("add-load", {
                      staticClass: "img_list",
                      attrs: {
                        width: 365,
                        height: 132.5,
                        cover: _vm.ruleForm.monthPic
                      },
                      on: {
                        setCover: function($event) {
                          return _vm.setCover($event, "monthPic")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticStyle: { color: "#999999" } }, [
                      _vm._v("建议图片尺寸：730 x 265")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "主背景图：", prop: "mainBgPic" } },
                  [
                    _c("add-load", {
                      staticClass: "img_list",
                      attrs: {
                        width: 150,
                        height: 228,
                        cover: _vm.ruleForm.mainBgPic
                      },
                      on: {
                        setCover: function($event) {
                          return _vm.setCover($event, "mainBgPic")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticStyle: { color: "#999999" } }, [
                      _vm._v("建议图片尺寸：750 x 1140")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _vm.id
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { width: "150px" },
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("ruleForm", "edit")
                              }
                            }
                          },
                          [_vm._v("\n            保存编辑\n          ")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticStyle: { width: "150px" },
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("ruleForm", "add")
                              }
                            }
                          },
                          [_vm._v("\n            立即新建\n          ")]
                        )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-left": "20px" } }, [
          _c("div", { staticClass: "svip-tutorial_calendar" }, [
            _c("div", { staticClass: "svip-tutorial_calendar_card" }, [
              _c("img", {
                staticClass: "svip-tutorial_calendar_card_header",
                attrs: {
                  src:
                    _vm.ruleForm.monthPic && _vm.imgSrc + _vm.ruleForm.monthPic
                }
              }),
              _vm._v(" "),
              _c("div", { staticStyle: { padding: "0 10px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "svip-tutorial_calendar_card_body",
                    style: {
                      backgroundColor: _vm.ruleForm.bgColor
                        ? _vm.ruleForm.bgColor
                        : "#e8d9cf"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "svip-tutorial_calendar_card_body_week" },
                      _vm._l(_vm.weeks, function(week, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "svip-tutorial_calendar_card_body_week_item"
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(week) +
                                "\n                "
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "svip-tutorial_calendar_card_body_day" },
                      _vm._l(_vm.days, function(day, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "svip-tutorial_calendar_card_body_day_item",
                            style: {
                              color: day.activity ? "#FFFFFF" : "#000000",
                              backgroundColor:
                                day.day === 0
                                  ? "rgba(255, 255, 255, 0.7)"
                                  : day.activity
                                  ? day.activity.color
                                  : "#FFFFFF"
                            },
                            on: {
                              click: function($event) {
                                return _vm.selectActivity(day.activity)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  width: "45px",
                                  height: "55px",
                                  top: "0, left: 0"
                                }
                              },
                              [
                                day.day !== 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "svip-tutorial_calendar_card_body_day_item-day"
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(day.day) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      height: "32px",
                                      "align-items": "center",
                                      "justify-content": "center"
                                    }
                                  },
                                  [
                                    day.activity
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "svip-tutorial_calendar_card_body_day_item-activity"
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  day.activity.activityName
                                                ) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            day.activity
                              ? _c("div", { staticClass: "delete-img" }, [
                                  _c("i", {
                                    staticClass: "el-icon-delete",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteActivity(day.activity)
                                      }
                                    }
                                  })
                                ])
                              : _vm._e()
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "svip-tutorial_calendar_card_text" }, [
                _vm._v("\n            点击上方日历内活动即可预约\n          ")
              ])
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "svip-tutorial_calendar_bg",
              attrs: {
                src:
                  _vm.ruleForm.mainBgPic && _vm.imgSrc + _vm.ruleForm.mainBgPic
              }
            })
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }