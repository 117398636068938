var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-manager-list" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    要客权益\n    "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.openGoodsPopup }
              },
              [_vm._v("\n        发放权益\n      ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("el-row", { staticStyle: { "padding-bottom": "10px" } }, [
        _c("span", { staticStyle: { float: "left" } }, [
          _vm._v("要客名称：" + _vm._s(_vm.managerName))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { float: "right" } },
          [
            !_vm.isBatch
              ? [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.openBatch }
                    },
                    [_vm._v("\n          批量作废\n        ")]
                  )
                ]
              : [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", size: "mini" },
                      on: { click: _vm.closeBatch }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-popconfirm",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { title: "确定作废此权益吗？" },
                      on: { confirm: _vm.batchDelCategoryGoods }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            type: "primary",
                            size: "mini"
                          },
                          slot: "reference"
                        },
                        [_vm._v("\n            确认作废\n          ")]
                      )
                    ],
                    1
                  )
                ]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          ref: "linkTableRef",
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            data: _vm.list,
            border: "",
            "header-cell-class-name": "table_header_cell"
          },
          on: { "selection-change": _vm.selectItems }
        },
        [
          _vm.isBatch
            ? _c("el-table-column", {
                attrs: {
                  selectable: _vm.selectable,
                  type: "selection",
                  width: "40",
                  checked: true
                },
                on: { select: _vm.selectChange }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "权益名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.rightCategoryName) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "所属分类", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.itemCategoryName) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "剩余次数", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.remainNum) + "次\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 1
                      ? _c("span", [_vm._v("生效中")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c("span", [_vm._v("已使用")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 3
                      ? _c("span", [_vm._v("已作废")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "200",
              align: "center",
              fixed: "right",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status !== 3
                      ? _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "center" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "red", padding: "5px" },
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.invalidRights(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("\n            作废\n          ")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.searchParams.pageNo,
          limit: _vm.searchParams.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchParams, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchParams, "pageSize", $event)
          },
          pagination: _vm.fetch
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.rightRuleShow,
              expression: "rightRuleShow"
            }
          ],
          staticClass: "addCoupon_table"
        },
        [
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-row",
                { staticClass: "row_div", attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "选择要客等级"
                          },
                          on: { change: _vm.levelChange },
                          model: {
                            value: _vm.searchObj.levelId,
                            callback: function($$v) {
                              _vm.$set(_vm.searchObj, "levelId", $$v)
                            },
                            expression: "searchObj.levelId"
                          }
                        },
                        _vm._l(_vm.levelList, function(s) {
                          return _c("el-option", {
                            key: s.id,
                            attrs: { label: s.name, value: s.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-search",
                            size: "small"
                          },
                          on: { click: _vm.goodsInit }
                        },
                        [_vm._v("搜索")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "tableRef",
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    "max-height": "500px",
                    "row-key": _vm.getGoodsRowKeys,
                    data: _vm.rightRule,
                    "tooltip-effect": "dark"
                  },
                  on: { "selection-change": _vm.selectionChangeGoods }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      align: "center",
                      "reserve-selection": true
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "70", align: "center", label: "序号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.searchParams.pageNo - 1) *
                                    _vm.searchParams.pageSize +
                                    scope.$index +
                                    1
                                ) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.columns, function(column) {
                    return _c("el-table-column", {
                      key: column.prop,
                      attrs: {
                        label: column.label,
                        prop: column.prop,
                        width: column.width,
                        formatter: column.formatter,
                        align: "center"
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发放次数", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input-number", {
                              staticClass: "form_input",
                              attrs: {
                                min: -1,
                                controls: false,
                                placeholder: "请输入"
                              },
                              model: {
                                value: scope.row.issueNum,
                                callback: function($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "issueNum",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "scope.row.issueNum"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userTotal > 0,
                    expression: "userTotal > 0"
                  }
                ],
                attrs: {
                  total: _vm.userTotal,
                  page: _vm.searchObj.page,
                  limit: _vm.searchObj.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.searchObj, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.searchObj, "limit", $event)
                  },
                  pagination: _vm.goodsInit
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        size: "small",
                        icon: "el-icon-close"
                      },
                      on: { click: _vm.closeGoodsPopup }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.batchAddCategoryGoods }
                    },
                    [_vm._v("确认发放")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }