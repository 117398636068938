var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "keep-alive",
        [
          _c(
            "w-table",
            {
              ref: "wTable",
              attrs: {
                size: "mini",
                columns: _vm.columns,
                "search-data": _vm.searchData,
                hideAddBtn: _vm.merchantIds ? true : false
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  slot: "status",
                  label: "状态",
                  align: "center",
                  width: "120"
                },
                slot: "status",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "span",
                          {
                            class: scope.row.status == 1 ? "success" : "danger"
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status == 1 ? "启用" : "停用")
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "update",
                  label: "操作",
                  align: "center",
                  width: "120"
                },
                slot: "update",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        !_vm.merchantIds
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.wPage(
                                      "/external/merchantAdd?id=" + scope.row.id
                                    )
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/external/goods?id=" + scope.row.id
                                )
                              }
                            }
                          },
                          [_vm._v("选品")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }