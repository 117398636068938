var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("新增编辑门店")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            size: "small",
            rules: _vm.rules,
            model: _vm.form,
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20, justify: "space-between", type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "companyId",
                        label: "公司总部：",
                        required: ""
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { "max-width": "400px", width: "100%" },
                          attrs: {
                            size: "small",
                            placeholder: "公司总部",
                            autocomplete: "off"
                          },
                          model: {
                            value: _vm.form.companyId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "companyId", $$v)
                            },
                            expression: "form.companyId"
                          }
                        },
                        _vm._l(_vm.list, function(item) {
                          return _c("el-option", {
                            key: item.companyId,
                            attrs: {
                              label: item.fullName,
                              value: item.companyId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "shortName",
                        label: "门店简称：",
                        required: ""
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "400px" },
                        attrs: { size: "small", autocomplete: "off" },
                        model: {
                          value: _vm.form.shortName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "shortName", $$v)
                          },
                          expression: "form.shortName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20, justify: "space-between", type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "fullName",
                        label: "门店名称：",
                        required: ""
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "400px" },
                        attrs: { size: "small", autocomplete: "off" },
                        model: {
                          value: _vm.form.fullName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "fullName", $$v)
                          },
                          expression: "form.fullName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "storeCode",
                        label: "门店机构编码：",
                        required: ""
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "400px" },
                        attrs: { size: "small", autocomplete: "off" },
                        model: {
                          value: _vm.form.storeCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "storeCode", $$v)
                          },
                          expression: "form.storeCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { gutter: 20, justify: "space-between", type: "flex" }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "state",
                        label: "门店地址：",
                        required: ""
                      }
                    },
                    [
                      _c("el-cascader", {
                        ref: "state",
                        staticStyle: { width: "100%", "max-width": "400px" },
                        attrs: {
                          size: "small",
                          options: _vm.regionData,
                          clearable: ""
                        },
                        on: { change: _vm.regionDataChange },
                        model: {
                          value: _vm.form.state,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "state", $$v)
                          },
                          expression: "form.state"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          staticStyle: { margin: "20px 0" },
                          attrs: { prop: "detailAddress", required: "" }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "400px" },
                            attrs: { size: "small", autocomplete: "off" },
                            model: {
                              value: _vm.form.detailAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "detailAddress", $$v)
                              },
                              expression: "form.detailAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "longOrLat",
                        label: "地理位置：",
                        required: ""
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "400px" },
                        attrs: { size: "small", autocomplete: "off" },
                        model: {
                          value: _vm.form.longOrLat,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "longOrLat", $$v)
                          },
                          expression: "form.longOrLat"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticStyle: { "font-size": "12px", color: "#666666" }
                        },
                        [
                          _vm._v(
                            "\n            经度纬度，请使用英文的逗号隔开，如：105.8888888,26.88888888\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20, justify: "space-between", type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: { prop: "image", label: "门店图片：" }
                    },
                    [
                      _c("add-load", {
                        attrs: {
                          width: 100,
                          height: 100,
                          cover: _vm.form.image
                        },
                        on: {
                          setCover: function($event) {
                            return _vm.setCover($event, "image")
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: { prop: "introduction", label: "门店描述：" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%", "max-width": "400px" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 3 },
                          size: "small",
                          placeholder: "请输入门店描述"
                        },
                        model: {
                          value: _vm.form.introduction,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "introduction", $$v)
                          },
                          expression: "form.introduction"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20, justify: "space-between", type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: { label: "门店负责人：" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "400px" },
                        attrs: { size: "small" },
                        model: {
                          value: _vm.form.contactPeople,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "contactPeople", $$v)
                          },
                          expression: "form.contactPeople"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: { label: "门店电话：" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "400px" },
                        attrs: { size: "small" },
                        model: {
                          value: _vm.form.contactPhone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "contactPhone", _vm._n($$v))
                          },
                          expression: "form.contactPhone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "storeStatus",
                        label: "经营状态：",
                        required: ""
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { "max-width": "400px", width: "100%" },
                          attrs: {
                            size: "small",
                            placeholder: "经营状态",
                            autocomplete: "off"
                          },
                          model: {
                            value: _vm.form.storeStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "storeStatus", $$v)
                            },
                            expression: "form.storeStatus"
                          }
                        },
                        _vm._l(_vm.storeStatusList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex_between" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "140px",
                    color: "#1682e6",
                    "text-align": "right"
                  }
                },
                [_vm._v("\n        楼层导览图\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: { click: _vm.addFloor }
                },
                [_vm._v("新增楼层")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: { size: "mini", data: _vm.form.orgFloorList, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "楼层名称",
                  "min-width": "50"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            ref: "orgFloorList." + scope.$index + ".floor",
                            attrs: {
                              "label-width": "0",
                              required: "",
                              prop: "orgFloorList." + scope.$index + ".floor",
                              rules: [
                                { required: true, message: "请输入楼层名称" }
                              ]
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.floor,
                                callback: function($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "floor",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "scope.row.floor"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "排序(数字小的考前)",
                  "min-width": "50"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            ref: "orgFloorList." + scope.$index + ".sort",
                            attrs: {
                              "label-width": "0",
                              required: "",
                              prop: "orgFloorList." + scope.$index + ".sort",
                              rules: [{ required: true, message: "请输入排序" }]
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { size: "mini", type: "number" },
                              model: {
                                value: scope.row.sort,
                                callback: function($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "sort",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "scope.row.sort"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "楼层图", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            ref: "orgFloorList." + scope.$index + ".image",
                            attrs: {
                              "label-width": "0",
                              required: "",
                              prop: "orgFloorList." + scope.$index + ".image",
                              rules: [
                                { required: true, message: "请上传楼层图" }
                              ]
                            }
                          },
                          [
                            _c("add-load", {
                              attrs: {
                                width: 200,
                                height: 100,
                                cover: scope.row.image
                              },
                              on: {
                                setCover: function($event) {
                                  return _vm.setCover($event, scope.$index)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.delFloor(scope.$index)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        ),
                        _vm._v(" "),
                        _c("el-popconfirm", {
                          attrs: { title: "确认删除改楼层图？" }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { margin: "20px 0 0 120px" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("form")
                }
              }
            },
            [_vm._v("保存门店")]
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              staticStyle: { "margin-top": "40px" },
              attrs: { label: "门店停车场：" }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.wPage(
                        "/system/manage/addStorePark?storeId=" +
                          _vm.storeId +
                          "&institutionId=" +
                          _vm.form.storeCode
                      )
                    }
                  }
                },
                [_vm._v("\n        添加停车场")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "w-table",
            {
              attrs: {
                "table-data": _vm.form.parkingList,
                columns: _vm.columns
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  slot: "status",
                  label: "状态",
                  align: "center",
                  width: "80"
                },
                slot: "status",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status == 1
                          ? _c("span", { staticStyle: { color: "#e6a23c" } }, [
                              _vm._v("待审核")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 2
                          ? _c("span", { staticStyle: { color: "#67c23a" } }, [
                              _vm._v("已审核")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "operate",
                  label: "操作",
                  align: "center",
                  fixed: "right",
                  width: "100"
                },
                slot: "operate",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "app-link",
                          {
                            staticStyle: { color: "rgb(24, 144, 255)" },
                            attrs: {
                              to:
                                "/system/manage/addStorePark?storeId=" +
                                _vm.storeId +
                                "&id=" +
                                scope.row.id
                            }
                          },
                          [_vm._v("\n            编辑\n          ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }