var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          ref: "addGoods",
          attrs: {
            formData: _vm.formData,
            isSetRules: "",
            isHttps: "",
            ruleForm: _vm.ruleForm
          },
          on: { submitForm: _vm.submitForm }
        },
        [
          _c(
            "div",
            { attrs: { slot: "rule" }, slot: "rule" },
            [
              _c(
                "el-form-item",
                { ref: "rule", attrs: { label: "规则：", prop: "rule" } },
                [
                  _vm._l(_vm.ruleForm.rule, function(item, index) {
                    return _c(
                      "el-card",
                      { key: index, staticStyle: { "margin-bottom": "20px" } },
                      [
                        _c(
                          "el-descriptions",
                          {
                            attrs: {
                              size: "small ",
                              labelStyle: {
                                width: "130px",
                                textAlign: "right"
                              },
                              column: 2,
                              border: ""
                            }
                          },
                          [
                            _c(
                              "el-descriptions-item",
                              {
                                attrs: {
                                  label:
                                    _vm.ruleForm.type == 1
                                      ? "首件数量"
                                      : "首重数量"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.first) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-descriptions-item",
                              {
                                attrs: {
                                  label:
                                    _vm.ruleForm.type == 1
                                      ? "次件数量"
                                      : "次重数量"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.second) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-descriptions-item",
                              {
                                attrs: {
                                  label:
                                    _vm.ruleForm.type == 1
                                      ? "首件运费"
                                      : "首重运费"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.firstPrice) +
                                    "元\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-descriptions-item",
                              {
                                attrs: {
                                  label:
                                    _vm.ruleForm.type == 1
                                      ? "次件运费"
                                      : "次重运费"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.secondPrice) +
                                    "元\n            "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认删除此规则？" },
                            on: {
                              confirm: function($event) {
                                return _vm.delRuleItem(index)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "danger",
                                attrs: {
                                  slot: "reference",
                                  icon: "el-icon-delete",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("\n                删除\n              ")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function($event) {
                                return _vm.goEditItem(item, index)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("城市：" + _vm._s(item.citySet.join("、")))
                        ])
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        plain: "",
                        icon: "el-icon-plus"
                      },
                      on: { click: _vm.goAddRule }
                    },
                    [_vm._v("新增规则")]
                  )
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "type" }, slot: "type" },
            [
              _c(
                "el-form-item",
                { ref: "type", attrs: { label: "规则：", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled:
                          _vm.ruleForm.rule.length > 0 || _vm.ruleForm.id > 0
                      },
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("计件")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("计重")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.isRulePop
        ? _c(
            "div",
            { staticClass: "fixed_center" },
            [
              _c(
                "el-card",
                { staticStyle: { width: "1200px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleItemForm",
                      attrs: {
                        size: "mini",
                        "label-width": "120px",
                        model: _vm.ruleItemForm,
                        rules: _vm.ruleItem
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.ruleForm.type == 1 ? "首件：" : "首重：",
                            prop: "first"
                          }
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "form_inputNumber",
                            attrs: { controls: false, min: 1, max: 9999999 },
                            model: {
                              value: _vm.ruleItemForm.first,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleItemForm, "first", $$v)
                              },
                              expression: "ruleItemForm.first"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "form_remark" }, [
                            _vm._v(_vm._s(_vm.ruleForm.type == 1 ? "件" : "克"))
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.ruleForm.type == 1
                                ? "首件运费："
                                : "首重运费：",
                            prop: "firstPrice"
                          }
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "form_inputNumber",
                            attrs: { controls: false, min: 0, max: 9999999 },
                            model: {
                              value: _vm.ruleItemForm.firstPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleItemForm, "firstPrice", $$v)
                              },
                              expression: "ruleItemForm.firstPrice"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "form_remark" }, [
                            _vm._v("元")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.ruleForm.type == 1 ? "次件：" : "次重：",
                            prop: "second"
                          }
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "form_inputNumber",
                            attrs: { controls: false, min: 1, max: 9999999 },
                            model: {
                              value: _vm.ruleItemForm.second,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleItemForm, "second", $$v)
                              },
                              expression: "ruleItemForm.second"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "form_remark" }, [
                            _vm._v(_vm._s(_vm.ruleForm.type == 1 ? "件" : "克"))
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.ruleForm.type == 1
                                ? "次件运费："
                                : "次重运费：",
                            prop: "secondPrice"
                          }
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "form_inputNumber",
                            attrs: { controls: false, min: 0, max: 9999999 },
                            model: {
                              value: _vm.ruleItemForm.secondPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleItemForm, "secondPrice", $$v)
                              },
                              expression: "ruleItemForm.secondPrice"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "form_remark" }, [
                            _vm._v("元")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "城市：", prop: "citySelect" } },
                        _vm._l(_vm.selectRgion, function(data, dataIndex) {
                          return _c(
                            "div",
                            { key: dataIndex, staticClass: "flex" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  staticStyle: { width: "60px" },
                                  attrs: {
                                    indeterminate:
                                      _vm.cityData["city" + dataIndex].allValue,
                                    label: data.value
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeAll(
                                        $event,
                                        dataIndex,
                                        data
                                      )
                                    }
                                  },
                                  model: {
                                    value:
                                      _vm.cityData["city" + dataIndex]
                                        .checkAllL,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.cityData["city" + dataIndex],
                                        "checkAllL",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "cityData['city'+dataIndex].checkAllL"
                                  }
                                },
                                [_vm._v(_vm._s(data.label))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "50px" } },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: {
                                        change: function($event) {
                                          return _vm.changeCity(
                                            $event,
                                            dataIndex,
                                            data.children
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.cityData["city" + dataIndex]
                                            .value,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.cityData["city" + dataIndex],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "cityData['city'+dataIndex].value"
                                      }
                                    },
                                    _vm._l(data.children, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: index,
                                          attrs: {
                                            disabled: _vm.getDisableP(
                                              item,
                                              dataIndex
                                            ),
                                            indeterminate: _vm.cityData[
                                              "city" + dataIndex
                                            ].pValue.includes(item.label),
                                            label: item.label
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.changPcity(
                                                $event,
                                                dataIndex,
                                                item,
                                                data.children
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.label) +
                                              "(" +
                                              _vm._s(
                                                _vm.getSelectCityNum(
                                                  item.children
                                                )
                                              ) +
                                              ")\n                  "
                                          ),
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                width: "500",
                                                trigger: "click"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-checkbox-group",
                                                {
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.changecityItem(
                                                        $event,
                                                        dataIndex,
                                                        item,
                                                        data.children
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ruleItemForm
                                                        .citySelect,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.ruleItemForm,
                                                        "citySelect",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ruleItemForm.citySelect"
                                                  }
                                                },
                                                _vm._l(item.children, function(
                                                  row,
                                                  rowindex
                                                ) {
                                                  return _c(
                                                    "el-checkbox",
                                                    {
                                                      key: rowindex,
                                                      staticStyle: {
                                                        width: "180px",
                                                        "margin-bottom": "6px"
                                                      },
                                                      attrs: {
                                                        disabled: _vm.selectAllCity.includes(
                                                          row.label
                                                        ),
                                                        label: row.label
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(row.label) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                }),
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("el-button", {
                                                staticStyle: {
                                                  color: "#CCCCCC"
                                                },
                                                attrs: {
                                                  slot: "reference",
                                                  type: "text",
                                                  icon: "el-icon-arrow-down",
                                                  circle: ""
                                                },
                                                slot: "reference"
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100px" },
                              attrs: { type: "danger", icon: "el-icon-close" },
                              on: { click: _vm.goCancelEidt }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.ruleIndex >= 0 ? "取消编辑" : "取消添加"
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100px" },
                              attrs: { type: "primary", icon: "el-icon-plus" },
                              on: {
                                click: function($event) {
                                  return _vm.goAffrimRule("ruleItemForm")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.ruleIndex >= 0 ? "确认编辑" : "确认添加"
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }