"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var _router = _interopRequireDefault(require("../../views/svip/activity/router.js"));

var _router2 = _interopRequireDefault(require("../../views/svip/replace/router.js"));

/** When your routing table is too long, you can split it into small modules **/
var svipRouter = {
  path: '/svip',
  component: _layout.default,
  name: 'Svip',
  meta: {
    title: '要客管理',
    icon: 'nested'
  },
  children: [_router.default, _router2.default, {
    path: 'member',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
      });
    },
    name: 'SvipMember',
    meta: {
      title: '要客管理'
    },
    redirect: '/svip/member/tab',
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/member/tab.vue'));
        });
      },
      name: 'SvipMemberTab',
      meta: {
        title: '要客管理'
      }
    }, {
      path: 'check',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/member/check.vue'));
        });
      },
      name: 'SvipMembercheck',
      meta: {
        title: '要客代买单',
        keepAlive: true,
        icon: 'el-icon-s-cooperation',
        pageList: 'items',
        pageTotal: 'totalNum',
        api: '/api/svip/querySVipCodeLogPage',
        columns: [{
          label: '要客经理',
          prop: 'managerName',
          width: 80
        }, {
          label: '要客经理手机',
          prop: 'managerMobile',
          width: 80
        }, {
          label: '会员',
          prop: 'userName',
          width: 60
        }, {
          label: '会员手机号',
          prop: 'mobile',
          width: 80
        }, {
          label: '创建时间',
          prop: 'createTime',
          width: 80
        }]
      }
    }, {
      path: 'change',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/member/list/change.vue'));
        });
      },
      name: 'SvipMemberchange',
      meta: {
        activeMenu: '/svip/member/tab',
        title: '等级变动记录',
        keepAlive: true,
        icon: 'el-icon-s-cooperation',
        pageList: 'records',
        pageTotal: 'total',
        api: '/api/svip/level/pageLevelChangeLog',
        columns: [{
          label: '手机号码',
          prop: 'mobile',
          width: 80
        }, {
          label: '姓名',
          prop: 'username',
          width: 50
        }, {
          label: 'ID',
          prop: 'uid',
          width: 50
        }, {
          label: '变动之前等级状态',
          prop: 'oldSvipStatus',
          width: 50
        }, {
          label: '变动之前等级',
          prop: 'oldLevelName',
          width: 50
        }, {
          label: '更新后等级',
          prop: 'newLevelName',
          width: 50
        }, {
          label: '操作人',
          prop: 'operator',
          width: 50
        }, {
          label: '备注',
          prop: 'changeReson',
          width: 100
        }, {
          label: '更新时间',
          prop: 'changeTime',
          width: 80
        }]
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/member/list/index.vue'));
        });
      },
      name: 'SvipMemberList',
      meta: {
        title: '消费要客列表',
        keepAlive: true,
        activeMenu: '/svip/member/tab'
      }
    }, {
      path: 'recharge',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/member/list/recharge.vue'));
        });
      },
      name: 'SvipMemberrecharge',
      meta: {
        title: '充值要客列表',
        keepAlive: true,
        activeMenu: '/svip/member/tab',
        pageTotal: 'totalNum',
        pageList: 'items'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/member/list/add.vue'));
        });
      },
      name: 'SvipMemberAdd',
      meta: {
        title: '新增要客',
        activeMenu: '/svip/member/tab'
      }
    }, {
      path: 'edit/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/member/list/form.vue'));
        });
      },
      name: 'SvipMemberEdit',
      meta: {
        title: '编辑要客',
        activeMenu: '/svip/member/tab'
      }
    }, {
      path: 'detail/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/member/list/detail.vue'));
        });
      },
      name: 'SvipMemberDetail',
      meta: {
        title: '要客详情',
        activeMenu: '/svip/member/tab'
      }
    }, {
      path: 'right/:uid',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/member/list/right.vue'));
        });
      },
      name: 'SvipMemberRight',
      meta: {
        title: '要客权益'
      }
    }, {
      path: 'review',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/member/review/index.vue'));
        });
      },
      name: 'SvipMemberReview',
      meta: {
        title: '要客申请',
        activeMenu: '/svip/member/tab'
      }
    }, {
      path: 'log/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/member/review/log.vue'));
        });
      },
      name: 'SvipMemberLog',
      meta: {
        title: '审核记录'
      }
    }]
  }, {
    path: 'manager',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
      });
    },
    name: 'SvipManager',
    meta: {
      title: '要客管理'
    },
    redirect: '/svip/manager/tab',
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/manager/tab.vue'));
        });
      },
      name: 'SvipManagerTab',
      meta: {
        title: '荔星伙伴管理'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/manager/list/index.vue'));
        });
      },
      name: 'SvipManagerList',
      meta: {
        title: '荔星伙伴列表',
        activeMenu: '/svip/manager/tab'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/manager/list/form.vue'));
        });
      },
      name: 'SvipManagerAdd',
      meta: {
        title: '新增荔星伙伴',
        activeMenu: '/svip/manager/tab'
      }
    }, {
      path: 'edit/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/manager/list/form.vue'));
        });
      },
      name: 'SvipManagerEdit',
      meta: {
        title: '编辑荔星伙伴',
        activeMenu: '/svip/manager/tab'
      }
    }, {
      path: 'detail/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/manager/list/detail.vue'));
        });
      },
      name: 'SvipManagerDetail',
      meta: {
        title: '荔星伙伴详情',
        activeMenu: '/svip/manager/tab'
      }
    }, {
      path: 'own/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/manager/list/own.vue'));
        });
      },
      name: 'SvipManagerOwn',
      meta: {
        title: '我的要客',
        activeMenu: '/svip/manager/tab'
      }
    }, {
      path: 'record',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/manager/record/index.vue'));
        });
      },
      name: 'SvipManagerRecord',
      meta: {
        title: '伙伴变更记录',
        activeMenu: '/svip/manager/tab'
      }
    }, {
      path: 'evaluation',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/manager/evaluation/index.vue'));
        });
      },
      name: 'SvipManagerEvaluation',
      meta: {
        title: '评价列表',
        activeMenu: '/svip/manager/tab'
      }
    }, {
      path: 'evaluationEdit/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/manager/evaluation/form.vue'));
        });
      },
      name: 'SvipManagerEvaluationEdit',
      meta: {
        title: '编辑评分',
        activeMenu: '/svip/manager/tab'
      }
    }]
  }, {
    path: 'place',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
      });
    },
    name: 'SvipPlace',
    meta: {
      title: '场地管理'
    },
    redirect: '/svip/place/tab',
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/place/tab.vue'));
        });
      },
      name: 'SvipPlaceTab',
      meta: {
        title: '场地管理'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/place/list/index.vue'));
        });
      },
      name: 'SvipPlaceList',
      meta: {
        title: '场地列表',
        activeMenu: '/svip/place/tab'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/place/list/form.vue'));
        });
      },
      name: 'SvipPlaceAdd',
      meta: {
        title: '新增场地',
        activeMenu: '/svip/place/tab'
      }
    }, {
      path: 'edit/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/place/list/form.vue'));
        });
      },
      name: 'SvipManagerPlaceEdit',
      meta: {
        title: '编辑场地',
        activeMenu: '/svip/place/tab'
      }
    }, {
      path: 'reserve/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/place/list/reserve.vue'));
        });
      },
      name: 'SvipPlaceReserve',
      meta: {
        title: '预约场地',
        activeMenu: '/svip/place/tab'
      }
    }, {
      path: 'equipment',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/place/equipment/index.vue'));
        });
      },
      name: 'SvipEquipment',
      meta: {
        title: '场地设备',
        activeMenu: '/svip/place/tab'
      }
    }]
  }, {
    path: 'service',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
      });
    },
    name: 'SvipService',
    meta: {
      title: '服务管理'
    },
    redirect: '/svip/service/tab',
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/service/tab.vue'));
        });
      },
      name: 'SvipServiceTab',
      meta: {
        title: '服务管理'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/service/list/index.vue'));
        });
      },
      name: 'SvipServiceList',
      meta: {
        title: '服务列表',
        activeMenu: '/svip/service/tab'
      }
    }, {
      path: 'serviceCategory',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/service/serviceCategory/index.vue'));
        });
      },
      name: 'SvipServiceCategory',
      meta: (0, _defineProperty2.default)({
        title: '服务分类',
        activeMenu: '/svip/reserve/vipTab'
      }, "activeMenu", '/svip/service/tab')
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/service/list/form.vue'));
        });
      },
      name: 'SvipServiceAdd',
      meta: {
        title: '新建服务',
        activeMenu: '/svip/service/tab'
      }
    }, {
      path: 'edit/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/service/list/form.vue'));
        });
      },
      name: 'SvipServiceEdit',
      meta: {
        title: '编辑服务',
        activeMenu: '/svip/service/tab'
      }
    }, {
      path: 'detail/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/service/list/form.vue'));
        });
      },
      name: 'SvipServiceDetail',
      meta: {
        title: '服务详情',
        activeMenu: '/svip/service/tab'
      }
    }, {
      path: 'reserve/:id/:label/:labelNo',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/service/list/reserve.vue'));
        });
      },
      name: 'SvipServiceReserve',
      meta: {
        title: '预约服务',
        activeMenu: '/svip/service/tab'
      }
    }]
  }, {
    path: 'goodsZone',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
      });
    },
    name: 'SvipGoodsZone',
    meta: {
      title: '商品专区管理'
    },
    redirect: '/svip/goodsZone/firstCategory',
    children: [{
      path: 'firstCategory',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/goodsZone/firstCategory/list/index.vue'));
        });
      },
      name: 'SvipGoodsZoneCategory',
      meta: {
        title: '专区分类'
      }
    }, {
      path: 'secondCategory/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/goodsZone/secondCategory/list/index.vue'));
        });
      },
      name: 'SvipSecondCategory',
      meta: {
        title: '二级分类',
        activeMenu: '/svip/goodsZone/firstCategory'
      }
    }, {
      path: 'categoryGoods/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/goodsZone/categoryGoods/list/index.vue'));
        });
      },
      name: 'SvipCategoryGoods',
      meta: {
        title: '商品列表',
        activeMenu: '/svip/goodsZone/firstCategory'
      }
    }]
  }, {
    path: 'activity',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
      });
    },
    name: 'SvipActivity',
    meta: {
      title: '活动管理'
    },
    redirect: '/svip/activity/tab',
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/activity/tab.vue'));
        });
      },
      name: 'SvipActivityTab',
      meta: {
        title: '活动管理'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/activity/list/index.vue'));
        });
      },
      name: 'SvipActivityList',
      meta: {
        title: '活动列表',
        activeMenu: '/svip/activity/tab'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/activity/list/form.vue'));
        });
      },
      name: 'SvipActivityAdd',
      meta: {
        title: '新建活动',
        activeMenu: '/svip/activity/tab'
      }
    }, {
      path: 'edit/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/activity/list/form.vue'));
        });
      },
      name: 'SvipActivityEdit',
      meta: {
        title: '编辑活动',
        activeMenu: '/svip/activity/tab'
      }
    }, {
      path: 'detail/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/activity/list/form.vue'));
        });
      },
      name: 'SvipActivityDetail',
      meta: {
        title: '活动详情',
        activeMenu: '/svip/activity/tab'
      }
    }, {
      path: 'reserve/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/activity/list/reserve.vue'));
        });
      },
      name: 'SvipActivityReserve',
      meta: {
        title: '预约活动',
        activeMenu: '/svip/activity/tab'
      }
    }, {
      path: 'calendar',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/activity/calendar/index.vue'));
        });
      },
      name: 'SvipCalendarList',
      meta: {
        title: '活动日历',
        activeMenu: '/svip/activity/tab'
      }
    }, {
      path: 'addCalendar',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/activity/calendar/form.vue'));
        });
      },
      name: 'SvipCalendarAdd',
      meta: {
        title: '新建日历',
        activeMenu: '/svip/activity/tab'
      }
    }, {
      path: 'editCalendar/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/activity/calendar/form.vue'));
        });
      },
      name: 'SvipCalendarEdit',
      meta: {
        title: '编辑日历',
        activeMenu: '/svip/activity/tab'
      }
    }, {
      path: 'salonLog',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/activity/salonLog'));
        });
      },
      name: 'SvipSalonLog',
      meta: {
        title: '活动数据统计',
        activeMenu: '/svip/activity/tab'
      }
    }, {
      path: 'salonLog/detail/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/activity/salonLog/detail'));
        });
      },
      name: 'SvipSalonLogDetail',
      meta: {
        title: '活动数据统计详情',
        activeMenu: '/svip/activity/tab'
      }
    }]
  }, {
    path: 'booking',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
      });
    },
    name: 'SvipBooking',
    meta: {
      title: '预约管理'
    },
    redirect: '/svip/booking/tab',
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/booking/tab.vue'));
        });
      },
      name: 'SvipBookingTab',
      meta: {
        title: '预约管理'
      }
    }, {
      path: 'bookingList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/booking/bookingList/index.vue'));
        });
      },
      name: 'SvipBookingList',
      meta: {
        title: '预约列表',
        activeMenu: '/svip/booking/tab'
      }
    }, {
      path: 'detail/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/booking/bookingList/detail.vue'));
        });
      },
      name: 'SvipBookingDetail',
      meta: {
        title: '预约详情',
        activeMenu: '/svip/booking/tab'
      }
    }, {
      path: 'afterSaleList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/booking/afterSaleList/index.vue'));
        });
      },
      name: 'SvipAfterSaleList',
      meta: {
        title: '售后列表',
        activeMenu: '/svip/booking/tab'
      }
    }, {
      path: 'afterSaleList/detail/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/booking/afterSaleList/detail.vue'));
        });
      },
      name: 'SvipAfterDetail',
      meta: {
        title: '售后详情',
        activeMenu: '/svip/booking/tab'
      }
    }, {
      path: 'placeList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/booking/placeList'));
        });
      },
      name: 'SvipPlaceBookingList',
      meta: {
        title: '场地预约记录',
        activeMenu: '/svip/booking/tab'
      }
    }]
  }, {
    path: 'config',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
      });
    },
    name: 'SvipConfig',
    meta: {
      title: '荔星荟配置'
    },
    redirect: '/svip/config/tab',
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/config/tab.vue'));
        });
      },
      name: 'SvipConfigTab',
      meta: {
        title: '荔星荟配置'
      }
    }, {
      path: 'home',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/config/setting/home.vue'));
        });
      },
      name: 'SvipConfigSettingHome',
      meta: {
        title: '荔星荟主页设置',
        activeMenu: '/svip/config/tab'
      }
    }, {
      path: 'home1',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/config/setting/home1.vue'));
        });
      },
      name: 'SvipConfigSettingHome1',
      meta: {
        title: '荔星荟主页设置',
        activeMenu: '/svip/config/tab'
      }
    }, {
      path: 'intro',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/config/setting/intro.vue'));
        });
      },
      name: 'SvipConfigSettingIntro',
      meta: {
        title: '荔星荟介绍页设置',
        activeMenu: '/svip/config/tab'
      }
    }, {
      path: 'modal',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/config/setting/modal.vue'));
        });
      },
      name: 'SvipConfigSettingModal',
      meta: {
        title: '弹窗设置',
        activeMenu: '/svip/config/tab'
      }
    }, {
      path: 'my',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/config/setting/my.vue'));
        });
      },
      name: 'SvipConfigSettingMy',
      meta: {
        title: '我的页设置',
        activeMenu: '/svip/config/tab'
      }
    }]
  }, {
    path: 'rights',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
      });
    },
    name: 'SvipRights',
    meta: {
      title: '要客权益'
    },
    redirect: '/svip/right/tab',
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/rights/tab.vue'));
        });
      },
      name: 'SvipRightsTab',
      meta: {
        title: '要客权益'
      }
    }, {
      path: 'interestsList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/rights/interestsList/index.vue'));
        });
      },
      name: 'SvipInterestsList',
      meta: {
        title: '权益列表',
        activeMenu: '/svip/rights/tab'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/rights/interestsList/form.vue'));
        });
      },
      name: 'SvipInterestsListAdd',
      meta: {
        title: '新建权益',
        activeMenu: '/svip/rights/tab'
      }
    }, {
      path: 'edit/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/rights/interestsList/form.vue'));
        });
      },
      name: 'SvipInterestsListEdit',
      meta: {
        title: '权益详情',
        activeMenu: '/svip/rights/tab'
      }
    }, {
      path: 'rule',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/rights/rule.vue'));
        });
      },
      name: 'SvipRightsRule',
      meta: {
        title: '权益发放规则',
        activeMenu: '/svip/rights/tab'
      }
    }, {
      path: 'setting',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/rights/setting/index.vue'));
        });
      },
      name: 'SvipRightsSetting',
      meta: {
        title: '权益页面配置',
        activeMenu: '/svip/rights/tab'
      }
    }, {
      path: 'category',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/rights/category/index.vue'));
        });
      },
      name: 'SvipRightsCategory',
      meta: {
        title: '权益分类',
        activeMenu: '/svip/rights/tab'
      }
    }, {
      path: 'category/linkCategory/:id/:name',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/rights/category/linkCategory.vue'));
        });
      },
      name: 'SvipRightsLinkCategory',
      meta: {
        title: '关联服务分类',
        activeMenu: '/svip/rights/tab'
      }
    }, {
      path: 'level',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/rights/level/index.vue'));
        });
      },
      name: 'SvipRightsLevel',
      meta: {
        title: '要客等级',
        activeMenu: '/svip/rights/tab'
      }
    }, {
      path: 'issueRecord',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/rights/issueRecord/index.vue'));
        });
      },
      name: 'SvipRightsIssueRecord',
      meta: {
        title: '权益发放记录',
        activeMenu: '/svip/rights/tab'
      }
    }, {
      path: 'usedRecord',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/svip/rights/usedRecord/index.vue'));
        });
      },
      name: 'SvipRightsUsedRecord',
      meta: {
        title: '权益使用记录',
        activeMenu: '/svip/rights/tab'
      }
    }]
  }]
};
var _default = svipRouter;
exports.default = _default;