"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '场地管理',
      list: [{
        tit: '场地列表',
        txtArr: '<p>对场地进行增删查改</p>',
        imgPath: require('@/assets/tab/place.png'),
        path: '/svip/place/list'
      }, {
        tit: '场地设备',
        txtArr: '<p>维护场地设备</p>',
        imgPath: require('@/assets/tab/equipment.png'),
        path: '/svip/place/equipment'
      }]
    };
  }
};
exports.default = _default;