"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCard = addCard;
exports.editCard = editCard;
exports.getCardById = getCardById;
exports.listCardPage = listCardPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
8、触发规则_星礼卡分页：/api/triggerCard/listCardPage
{"pageNo":"当前页","pageSize":"页条数"} */
function listCardPage(data) {
  return (0, _request.default)({
    url: '/api/triggerCard/listCardPage',
    method: 'post',
    data: data
  });
}
/*
5、新增触发规则_星礼卡：/api/triggerCard/addCard
{"ruleName":"规则名称","startTime":"活动时间(开始)","endTime":"活动时间(结束)","appointCard":"是否指定卡(0：否 1：是)","cardCode":"储值卡编码","ruleStatus":"规则状态(0：禁用 1：启用)","ruleRemark":"规则备注",
"ladders":[{"id":"阶梯id","topupAmount":"充值金额","peopleLimit":"人数限制","eventIds":[触发事件id]}]}

 */


function addCard(data) {
  return (0, _request.default)({
    url: '/api/triggerCard/addCard',
    method: 'post',
    data: data
  });
}
/*
 6、编辑触发规则_星礼卡：/api/triggerCard/editCard
 {"id":"id","ruleName":"规则名称","startTime":"活动时间(开始)","endTime":"活动时间(结束)","appointCard":"是否指定卡(0：否 1：是)","cardCode":"储值卡编码",
 "ruleStatus":"规则状态(0：禁用 1：启用)","ruleRemark":"规则备注","ladders":[{"id":"阶梯id","topupAmount":"充值金额","peopleLimit":"人数限制","eventIds":[触发事件id]}]}

 */


function editCard(data) {
  return (0, _request.default)({
    url: '/api/triggerCard/editCard',
    method: 'post',
    data: data
  });
}
/*
7、触发规则_星礼卡详情：/api/triggerCard/getCardById/{id}
 */


function getCardById(data) {
  return (0, _request.default)({
    url: '/api/triggerCard/getCardById/' + data,
    method: 'post'
  });
}