var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add_goods" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    " + _vm._s(_vm.$route.meta.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "goodsForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "100px",
            size: "small"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "productName", label: "商品名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "商品名称" },
                model: {
                  value: _vm.form.productName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productName", $$v)
                  },
                  expression: "form.productName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "productCode", label: "商品编码" } },
            [
              _c("el-input", {
                attrs: { placeholder: "商品编码" },
                model: {
                  value: _vm.form.productCode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productCode", $$v)
                  },
                  expression: "form.productCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.handleCancel } },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }