"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.array.find");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _api = require("./api.js");

var _moment = _interopRequireDefault(require("moment"));

var _user = require("@/api/user.js");

var _mem = require("@/api/mem.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
function sortDownDate(a, b) {
  return Date.parse(a.prop) - Date.parse(b.prop);
}

var _default = {
  components: {
    wTable: _index.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      popShow: false,
      qrCode: '',
      couponUUid: '',
      storeList: '',
      orderStatus: [],
      downloadLoading: false,
      requestData: {
        position: null,
        payMode: 2,
        orderNo: '',
        pageNo: 1,
        pageSize: 10,
        total: 0,
        deptId: '',
        buyUname: '',
        beginPayTime: '',
        endPayTime: '',
        specialType: '',
        buyUtphone: ''
      },
      time: '',
      tableData: [],
      positions: [{
        label: '总裁',
        value: 10
      }, {
        label: '总经理级',
        value: 1
      }, {
        label: '总监级',
        value: 2
      }, {
        label: '经理级',
        value: 3
      }, {
        label: '课长级',
        value: 4
      }, {
        label: '主管级',
        value: 5
      }, {
        label: '员工级',
        value: 6
      }, {
        label: '银行',
        value: 9
      }, {
        label: '百货(12001、12002、12003)',
        value: 7
      }, {
        label: '购物中心(12010、12009、12004、12007)',
        value: 8
      }],
      columns: [{
        label: '门店编码',
        prop: 'storedCode',
        width: 80
      }, {
        label: '本店名称',
        prop: 'storedName',
        width: 80
      }, {
        label: '推广人姓名',
        prop: 'userName',
        width: 80
      }, {
        label: '职务',
        prop: 'position',
        width: 80
      }, {
        label: '手机号码',
        prop: 'mobile',
        width: 100
        /* {
          slot: 'upLoad'
        } */

      }]
    };
  },
  activated: function activated() {// this.getList()
  },
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({
      companyId: ''
    }).then(function (res) {
      _this.storeList = res.data;
    });
  },
  mounted: function mounted() {
    var today = new Date();
    var lastMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    var lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
    var endDateTime = (0, _moment.default)(lastMonthEndDate).format("YYYY-MM-DD HH:mm:ss");
    var startDateTime = (0, _moment.default)(lastMonthStartDate).format("YYYY-MM-DD HH:mm:ss");
    this.time = [startDateTime, endDateTime];
  },
  methods: {
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    upLoad: function upLoad(row) {
      var _this2 = this;

      // 获取小程序码
      this.couponUUid = row.userName;
      this.openLoading('获取小程序码中');
      (0, _user.couponQRCode)({
        path: 'vip/index',
        scene: 'id=' + row.userId
      }).then(function (res) {
        _this2.openLoading().close();

        _this2.qrCode = res.data;
        _this2.popShow = true;
      }).catch(function (res) {
        _this2.openLoading().close();
      });
    },
    refundPlus: function refundPlus(row) {
      var _this3 = this;

      this.$confirm('此操作将退款, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _api.refundMaoTaiVip)({
          buyUid: row.buyUid,
          orderId: row.orderId
        }).then(function (res) {
          _this3.getList();

          _this3.$message({
            type: 'success',
            message: '退款成功!'
          });
        });
      }).catch(function () {});
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var list = [];
        list = _this4.tableData;

        var data = _this4.formatJson(filterVal, list);

        excel.export_json_to_excel({
          header: tHeader,
          data: data,
          filename: '推广统计'
        });
        _this4.downloadLoading = false;
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this5 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = '';
        this.removeObj(this.requestData);
      }

      this.requestData.beginPayTime = this.time ? this.time[0] : '';
      this.requestData.endPayTime = this.time ? this.time[1] : '';
      (0, _api.listPromoOrder)(this.requestData).then(function (res) {
        _this5.tableData = res.data.items;
        _this5.requestData.total = res.data.totalNum;
        var arr = [];
        _this5.columns = [{
          label: '门店编码',
          prop: 'storedCode',
          width: 80
        }, {
          label: '本店名称',
          prop: 'storedName',
          width: 80
        }, {
          label: '推广人姓名',
          prop: 'userName',
          width: 80
        }, {
          label: '职务',
          prop: 'position',
          width: 80
        }, {
          label: '手机号码',
          prop: 'mobile',
          width: 100
        }, {
          slot: 'upLoad'
        }];

        _this5.tableData.forEach(function (item) {
          var positionObj = _this5.positions.find(function (row) {
            return item.position === row.value;
          });

          if (positionObj) {
            item.position = positionObj.label;
          } else {
            item.position = '无职位';
          }

          JSON.parse(item.dsJson).forEach(function (row) {
            var obj = {
              label: row.ds,
              prop: row.ds,
              width: 50
            };
            arr.push(row.ds);

            var result = _this5.columns.some(function (item) {
              if (item.prop === row.ds) {
                return true;
              }
            });

            if (!result) {
              _this5.columns.push(obj);
            }

            _this5.$set(item, row.ds, row.number);
          });
        });

        _this5.columns.sort(sortDownDate);

        return;
      });
    }
  }
};
exports.default = _default;