"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var _router = _interopRequireDefault(require("./group/router.js"));

/** When your routing table is too long, you can split it into small modules **/
var mallRouter = {
  path: '/mall',
  component: _layout.default,
  redirect: '/mall/index',
  name: 'mall',
  meta: {
    title: '积分商城',
    icon: 'nested'
  },
  children: [_router.default, {
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/index.vue'));
      });
    },
    name: 'mallIndex',
    meta: {
      title: '积分商城'
    }
  }, {
    path: 'set',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/set.vue'));
      });
    },
    name: 'mallSet',
    meta: {
      title: '查询全局设置'
    }
  }, {
    path: 'source',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/source/list.vue'));
      });
    },
    name: 'sourceList',
    meta: {
      title: '货源地',
      keepAlive: true,
      pageList: 'records',
      addTxt: "新增货源地",
      addUrl: "/mall/sourceAdd"
    }
  }, {
    path: 'sourceAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/source/add.vue'));
      });
    },
    name: 'sourceAdd',
    meta: {
      title: '新增/编辑货源地',
      activeMenu: '/mall/source'
    }
  }, {
    path: 'class',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/class/index.vue'));
      });
    },
    redirect: '/mall/class/list',
    name: 'GoodsClass',
    meta: {
      title: '分类管理'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/mall/class/list.vue'));
        });
      },
      name: 'GoodsClassList',
      meta: {
        title: '分类管理',
        keepAlive: true
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/mall/class/add.vue'));
        });
      },
      name: 'GoodsClassAdd',
      meta: {
        title: '新增编辑分类',
        activeMenu: '/mall/class/list'
      }
    }]
  }, {
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/goods/index.vue'));
      });
    },
    redirect: '/mall/goods/list',
    name: 'Goods',
    meta: {
      title: '商品管理'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/mall/goods/list.vue'));
        });
      },
      name: 'GoodsList',
      meta: {
        title: '商品管理',
        keepAlive: true
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/mall/goods/add.vue'));
        });
      },
      name: 'GoodsAdd',
      meta: {
        title: '新增编辑商品',
        activeMenu: '/mall/goods/list'
      }
    }]
  }, {
    path: 'freight',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/freight/index.vue'));
      });
    },
    redirect: '/mall/freight/list',
    name: 'Freight',
    meta: {
      title: '运费模板'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/mall/freight/list.vue'));
        });
      },
      name: 'FreightList',
      meta: {
        title: '运费模板',
        keepAlive: true
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/mall/freight/add.vue'));
        });
      },
      name: 'FreightAdd',
      meta: {
        title: '新增编辑运费模板',
        activeMenu: '/mall/freight/list'
      }
    }]
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/order/index.vue'));
      });
    },
    redirect: '/mall/order/list',
    name: 'order',
    meta: {
      title: '订单列表'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/mall/order/list.vue'));
        });
      },
      name: 'orderList',
      meta: {
        title: '订单列表',
        keepAlive: true
      }
    }, {
      path: 'dea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/mall/order/dea.vue'));
        });
      },
      name: 'orderAdd',
      meta: {
        title: '订单详情',
        activeMenu: '/mall/order/list'
      }
    }, {
      path: 'upload',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/mall/order/upload.vue'));
        });
      },
      name: 'orderUpload',
      meta: {
        title: '批量导入物流',
        activeMenu: '/mall/order/list'
      }
    }]
  }, {
    path: 'keyword',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/order/index.vue'));
      });
    },
    redirect: '/mall/keyword/list',
    name: 'keyword',
    meta: {
      title: '热搜词管理'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/mall/keyword/list.vue'));
        });
      },
      name: 'keywordList',
      meta: {
        title: '热搜词管理',
        keepAlive: true,
        addTxt: "新增关键词",
        addUrl: '/mall/keyword/form',
        api: '/api/heatSearch/lisHeatSearchPage',
        activeMenu: '/mall/keyword/list'
      }
    }, {
      path: 'form',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/mall/keyword/form.vue'));
        });
      },
      name: 'keywordForm',
      meta: {
        title: '新增|编辑热搜词管理',
        activeMenu: '/mall/keyword/list'
      }
    }]
  }]
};
var _default = mallRouter;
exports.default = _default;