"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var equityRouter = {
  path: '/equity',
  component: _layout.default,
  redirect: '/equity/index',
  name: 'equity',
  meta: {
    title: '权益配置'
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./list.vue"));
      });
    },
    name: 'equityIndex',
    meta: {
      title: '权益列表',
      keepAlive: true
    }
  }, {
    path: 'edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./add.vue"));
      });
    },
    name: 'equityEdit',
    meta: {
      title: '编辑权益',
      activeMenu: '/equity/index'
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./order.vue"));
      });
    },
    name: 'equityorder',
    meta: {
      title: '权益订单',
      keepAlive: true
    }
  }]
};
var _default = equityRouter;
exports.default = _default;