"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  permissionRoutes: function permissionRoutes(state) {
    return state.user.permissionRoutes;
  },
  name: function name(state) {
    return state.user.name;
  },
  sourceIds: function sourceIds(state) {
    return state.user.sourceIds;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  userLableList: function userLableList(state) {
    return state.user.userLableList;
  },
  // permission_routes: state => state.permission.routes,
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  menuName: function menuName(state) {
    return state.user.menuName;
  },
  activeMenu: function activeMenu(state) {
    return state.public.activeMenu;
  }
};
var _default = getters;
exports.default = _default;