var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("方案内容")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c("ul", [
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("方案名称：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _c("span", { staticClass: "coupon_name" }, [
                  _vm._v(_vm._s(_vm.orientObj.name))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("方案有效期：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(
                  _vm._s(_vm.orientObj.startTime) +
                    " 至 " +
                    _vm._s(_vm.orientObj.endTime)
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("触发条件：")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dea_box_li_coupon" }, [
                _c("p", { staticClass: "dea_box_li_txt" }, [
                  _vm._v("【" + _vm._s(_vm.type) + "】")
                ]),
                _vm._v(" "),
                _vm.orientObj.tiggType === 3
                  ? _c("p", { staticClass: "dea_box_li_txt" }, [
                      _vm._v("消费纳入范围：" + _vm._s(_vm.typeTxt))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.orientObj.tiggType === 3
                  ? _c("p", { staticClass: "dea_box_li_txt" }, [
                      _vm._v(
                        "累计消费金额：" + _vm._s(_vm.relevanceValue) + "元"
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("投放数量：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.putNumber))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("领取限制：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(
                  _vm._s(_vm.orientObj.limitNumber) + "（每个用户限制获取数）"
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("投放优惠券：")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dea_box_li_coupon" },
                _vm._l(_vm.orientObj.couponId, function(item, index) {
                  return _c(
                    "p",
                    { key: index, staticClass: "dea_box_li_txt" },
                    [
                      _c("span", { staticClass: "coupon_id" }, [
                        _vm._v(_vm._s(item.uuid))
                      ]),
                      _vm._v(
                        "\n                -" +
                          _vm._s(item.typeName) +
                          "-\n                "
                      ),
                      _c("span", { staticClass: "coupon_name" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _vm._v(" "),
                      item.validTime
                        ? _c("span", [
                            _vm._v(
                              "\n                  -（" +
                                _vm._s(item.validTime) +
                                "）\n                "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.next } },
          [_vm._v("确认提交方案")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }