var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 提货券列表 "),
        _c(
          "div",
          [
            _vm.setMenuPerms("couponBag:add")
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "max-width": "100%" },
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-circle-plus-outline"
                    },
                    on: {
                      click: function($event) {
                        return _vm.wPage("/giftToken/add")
                      }
                    }
                  },
                  [_vm._v("新增提货券")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  placeholder: "提货券名称"
                },
                model: {
                  value: _vm.requestData.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  placeholder: "提货券编码"
                },
                model: {
                  value: _vm.requestData.uuid,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "uuid",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.uuid"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "mini", placeholder: "是否权益卡" },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.type,
                    callback: function($$v) {
                      _vm.type = $$v
                    },
                    expression: "type"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "是", value: 4 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "否", value: 3 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.getList("search")
                }
              }
            },
            [_vm._v("查询")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "validTimeType",
              label: "周期",
              align: "center",
              width: "260"
            },
            slot: "validTimeType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.validTimeType === 1
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.effStartTime) +
                              "-" +
                              _vm._s(scope.row.effEndTime) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.validTimeType === 2
                      ? _c("span", [
                          _vm._v(
                            "\n          领取" +
                              _vm._s(scope.row.validDay) +
                              "天后有效\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              "min-width": "80"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 1
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("待审核")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 2
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("已审核")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 3
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("审核撤回")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 4
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("审核撤销")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "objectHelpType",
              label: "助力人类型",
              align: "center",
              "min-width": "80"
            },
            slot: "objectHelpType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.objectHelpType == 1
                      ? _c("span", { attrs: { type: "warning" } }, [
                          _vm._v("新会员")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.objectHelpType == 2
                      ? _c("span", { attrs: { type: "primary" } }, [
                          _vm._v("365会员")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.objectHelpType == 0
                      ? _c("span", { attrs: { type: "success" } }, [
                          _vm._v("所有会员")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "activityBeHelpedObjectType",
              label: "发起人类型",
              align: "center",
              "min-width": "80"
            },
            slot: "activityBeHelpedObjectType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.activityBeHelpedObjectType == 1
                      ? _c("span", { attrs: { type: "warning" } }, [
                          _vm._v("新会员")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.activityBeHelpedObjectType == 2
                      ? _c("span", { attrs: { type: "primary" } }, [
                          _vm._v("365会员")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.activityBeHelpedObjectType == 0
                      ? _c("span", { attrs: { type: "success" } }, [
                          _vm._v("所有会员")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              fixed: "right",
              width: "300"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/giftToken/add?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("编辑 ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/giftToken/editStock?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("修改库存 ")]
                    ),
                    _vm._v(" "),
                    scope.row.status === 1
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认通过审核？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goAudit(scope.row, 2)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "warning",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("审核")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              "qr-code": _vm.qrCode,
              url: _vm.url,
              "coupon-u-uid": _vm.couponUUid
            },
            on: { closePop: _vm.closePop }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }