"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

var _mem = require("@/api/mem.js");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    wTable: _index.default
  },
  data: function data() {
    return {
      companyList: [],
      storeList: [],
      orderStatus: [],
      downloadLoading: false,
      requestData: {
        basicName: '',
        basicStatus: '',
        companyCode: '',
        storeCode: '',
        brandClsCode: '',
        brandCode: '',
        goodsCode: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '策略名称',
        prop: 'cdtionName',
        width: 100
      }, {
        label: '总部名称',
        prop: 'companyName',
        width: 80
      }, {
        slot: 'time'
      }, // {
      //   label: '价格计算方式',
      //   prop: 'amountRealpay',
      //   width: 50
      // },
      {
        slot: 'cditionStatus'
      }, {
        slot: 'operate'
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companyList = res.data;
    });
    (0, _mem.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
  },
  activated: function activated() {
    var arr7 = Array.from(Array(33), function (v, k) {
      return k + 1;
    });
    var arr = [];
    var random = 33;

    for (var i = 0; i < 6; i++) {
      var num = Math.ceil(Math.random() * random);
      arr.push(arr7[num]);
      arr7.splice(num, 1);
      random--;
    }

    arr.sort(function (a, b) {
      return a - b;
    });
    this.$nextTick(function () {
      arr.push(Math.ceil(Math.random() * 16));
      console.log(arr.join('  '));
    }); //

    this.getList();
  },
  methods: {
    setShort: function setShort(e) {
      var _this2 = this;

      this.requestData.storeCode = '';
      this.companyList.forEach(function (item) {
        if (item.companyCode == e) {
          (0, _mem.listStore)({
            companyId: item.companyId
          }).then(function (res) {
            _this2.storeList = res.data;
          });
        }
      });
    },
    refundPlus: function refundPlus(row) {
      var _this3 = this;

      this.$confirm('此操作将退款, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        refundMaoTaiVip({
          buyUid: row.buyUid,
          orderId: row.orderId
        }).then(function (res) {
          _this3.getList();

          _this3.$message({
            type: 'success',
            message: '退款成功!'
          });
        });
      }).catch(function () {});
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];
        var data = {};

        _this4.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this4.requestData).forEach(function (key) {
          if (_this4.requestData[key]) {
            data[key] = _this4.requestData[key];
          }
        });
        data.pageSize = _this4.requestData.total;
        data.pageNo = 1;

        _this4.openLoading();

        (0, _api.listVippriceSpecialPage)(data).then(function (res) {
          list = res.data.items;

          var data = _this4.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: 'MIKA  PLUS购买记录'
          });
          _this4.downloadLoading = false;

          _this4.openLoading().close();
        }).catch(function (res) {
          _this4.downloadLoading = false;

          _this4.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this5 = this;

      if (type == 'search') {
        this.pageNo = 1;
      }

      if (type == 'remove') {
        this.removeObj(this.requestData);
      }

      (0, _api.listVippriceSpecialPage)(this.requestData).then(function (res) {
        _this5.tableData = res.data.items;
        _this5.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;