"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      categoryList: [],
      formData: [{
        slot: 'paterId'
      }, {
        label: '类目名称：',
        type: 'input',
        placeholder: '请输入类目名称',
        value: 'categoryName'
      }, {
        label: '图标：',
        type: 'img',
        placeholder: '请上传图标',
        value: 'icon'
      }, {
        label: '状态：',
        type: 'radio',
        placeholder: '请选择状态',
        value: 'status',
        selectList: [{
          label: '启用',
          value: 1
        }, {
          label: '禁用',
          value: 2
        }]
      }, {
        label: '排序：',
        type: 'inputNumber',
        placeholder: '请输入排序',
        value: 'sort'
      }, {
        type: 'input',
        label: '描述：',
        placeholder: '请输入描述',
        value: 'remark'
      }],
      ruleForm: {
        id: null,
        "paterId": 0,
        "categoryName": "",
        "icon": "",
        "status": "",
        "sort": "",
        "remark": ""
      },
      rules: {
        categoryName: [{
          required: true,
          message: '请输入名称'
        }],
        icon: [{
          required: true,
          message: '请上传ICON'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        sort: [{
          required: true,
          message: '请输入排序'
        }],
        remark: [{
          required: true,
          message: '请输入简介'
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/category/label/getUserCategoryById/' + this.ruleForm.id).then(function (res) {
        _this.ruleForm = res.data;
      });
      /* getInfo(this.ruleForm.id).then((res) => {
         this.mch = res
         // this.ruleForm.contractNo = res.contractNo
         this.ruleForm = res
         // this.remoteMethod(null,res.contractNo)
       }) */
    }

    this.apiPost('/api/category/label/listUserCategory', {
      status: 1
    }).then(function (res) {
      var result = [];
      var map = {};
      res.data.forEach(function (item) {
        map[item.id] = item;
      });
      res.data.forEach(function (item) {
        item.disabled = item.id == _this.ruleForm.id;
        var parent = map[item.paterId];

        if (parent) {
          if (!parent.children) parent.children = [];
          parent.children.push(item);
        } else {
          result.push(item);
        }
      });
      _this.categoryList = result;
    });
  },
  methods: {
    addRuleGroup: function addRuleGroup() {
      this.ruleForm.ruleGroupList.push({
        andOr: null,
        context: [{
          type: null,
          ifEqual: null,
          condition: null
        }]
      });
    },
    getVipList: function getVipList() {
      var _this2 = this;

      this.apiPost('/api/pay-member-type/page', {
        pageNo: 1,
        pageSize: 30
      }).then(function (res) {
        _this2.vipList = res.data.records;
      });
    },
    getSvipLevel: function getSvipLevel() {
      var _this3 = this;

      this.apiGet('/api/svip/level').then(function (res) {
        _this3.svipList = res.data;
      });
    },
    getAward: function getAward(e) {
      this.$refs.coupon1.getList('card', this.ruleForm.cardUuid);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      console.log(e);
      this.ruleForm.cardUuid = e.uuid;
      this.couponShow = false;
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      var paterIdData = this.$refs.paterId.getCheckedNodes();
      var data = (0, _objectSpread2.default)({}, this.ruleForm);
      data.paterId = paterIdData.length > 0 ? paterIdData[0].value : 0;
      var api = this.ruleForm.id ? '/api/category/label/editUserCategory' : '/api/category/label/addUserCategory';
      this.apiPost(api, data).then(function (res) {
        _this4.$router.go(-1);

        _this4.$message({
          type: 'success',
          message: _this4.ruleForm.id ? '编辑成功' : '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;