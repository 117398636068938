"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _dcb = require("@/api/dcb.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      obj: {
        pageNo: 1,
        pageSize: 10,
        orderNo: '',
        receiptName: '',
        receiptMobile: '',
        orderStatus: ''
      },
      total: 0,
      shopList: [{
        label: '待付款',
        value: 1
      }, {
        label: '待发货',
        value: 2
      }, {
        label: '已发货',
        value: 3
      }, {
        label: '已售后',
        value: 4
      }],
      list: [],
      tabTh: [{
        label: '订单号',
        prop: 'orderNo',
        width: 200
      }, {
        label: '金额',
        prop: 'orderAmount',
        width: 100
      }, {
        label: '时间',
        prop: 'orderTime',
        width: 200
      }, {
        label: '收货人',
        prop: 'receiptName',
        width: 100
      }, {
        label: '手机号',
        prop: 'receiptMobile',
        width: 100
      }, {
        label: '订单状态',
        prop: 'orderStatus',
        width: 100
      }]
    };
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'refresh') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
        this.obj.orderNo = '';
        this.obj.receiptName = '';
        this.obj.receiptMobile = '';
        this.obj.orderStatus = '';
      }

      this.openLoading();
      (0, _dcb.listOrderPage)(this.obj).then(function (res) {
        _this.openLoading().close();

        _this.total = res.data.totalNum;
        _this.list = res.data.items;

        _this.list.forEach(function (item) {
          if (!_this.setMenuPerms('user_mobile_idCard')) {
            if (item.receiptMobile) {
              item.receiptMobile = item.receiptMobile.replace(item.receiptMobile.substring(3, 7), "****");
            }
          }

          if (item.orderStatus == 1) {
            item.orderStatus = '待付款';
          } else if (item.orderStatus == 2) {
            item.orderStatus = '待发货';
          } else if (item.orderStatus == 3) {
            item.orderStatus = '已发货';
          } else if (item.orderStatus == 4) {
            item.orderStatus = '已售后';
          }
        });
      }).catch(function (err) {
        _this.openLoading().close();
      });
    },
    goPage: function goPage(type, id) {
      this.$router.push('/dcb/orderDea?id=' + id);
    }
  }
};
exports.default = _default;