var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-member-list" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    二级分类\n    "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: {
                  click: function($event) {
                    return _vm.openForm(null)
                  }
                }
              },
              [_vm._v("\n        新建二级分类\n      ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            data: _vm.list,
            border: "",
            "header-cell-class-name": "table_header_cell"
          }
        },
        [
          _c("el-table-column", {
            attrs: { width: "70", align: "center", label: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          (_vm.searchParams.pageNo - 1) *
                            _vm.searchParams.pageSize +
                            scope.$index +
                            1
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "二级分类", prop: "name", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "预览图", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.pic
                      ? _c("img", {
                          staticStyle: {
                            width: "50px",
                            height: "50px",
                            margin: "0 auto"
                          },
                          attrs: { src: _vm.imgSrc + scope.row.pic }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: { value: scope.row.isShow },
                      on: {
                        change: function($event) {
                          return _vm.switchField("isShow", scope.$index)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "200",
              align: "center",
              fixed: "right",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.openForm(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n            编辑\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.goto(
                                  "/svip/goodsZone/categoryGoods/" +
                                    scope.row.id
                                )
                              }
                            }
                          },
                          [_vm._v("\n            商品列表\n          ")]
                        ),
                        _vm._v(" "),
                        !(_vm.searchParams.pageNo === 1 && scope.$index === 0)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.moveCategory(scope.row.id, "TOP")
                                  }
                                }
                              },
                              [_vm._v("\n            上移\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !(
                          _vm.searchParams.pageNo === _vm.totalPageNum &&
                          scope.$index === _vm.list.length - 1
                        )
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.moveCategory(
                                      scope.row.id,
                                      "DOWN"
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n            下移\n          ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.searchParams.pageNo,
          limit: _vm.searchParams.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchParams, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchParams, "pageSize", $event)
          },
          pagination: _vm.fetch
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.id ? "编辑二级分类" : "新建二级分类",
            visible: _vm.secondCategoryVisible,
            width: "350px"
          },
          on: {
            "update:visible": function($event) {
              _vm.secondCategoryVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "secondCategoryForm",
              attrs: { model: _vm.secondCategoryForm, rules: _vm.rules }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "二级分类名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.secondCategoryForm.name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.secondCategoryForm,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "secondCategoryForm.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "预览图", prop: "pic" } },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.secondCategoryForm.pic
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "pic")
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.secondCategoryVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("secondCategoryForm")
                    }
                  }
                },
                [_vm._v("\n        确 定\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }