"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '人群标签管理',
      list: [{
        tit: '类目管理',
        txtArr: '类目管理',
        imgPath: require('@/assets/tab/23.png'),
        path: '/basic/userLabelClass'
      }, {
        tit: '标签管理',
        txtArr: '标签管理',
        imgPath: require('@/assets/tab/14.png'),
        path: '/basic/userLabel'
      }]
    };
  }
};
exports.default = _default;