"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _member = require("@/api/member.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      obj: {
        pageNo: 1,
        pageSize: 10,
        oldMobile: '',
        beginCreateTime: '',
        endCreateTime: '',
        newMobile: '',
        operatorId: '',
        operator: ''
      },
      time: '',
      storeList: [],
      list: [],
      total: 0,
      tabTh: [{
        prop: 'oldMobile',
        label: '旧手机号',
        width: 50
      }, {
        prop: 'newMobile',
        label: '新手机号',
        width: 60
      }, {
        prop: 'createTime',
        label: '切换时间',
        width: 100
      }, {
        prop: 'dingtalkNumber',
        label: '钉钉工单',
        width: 80
      }, {
        prop: 'operator',
        label: '操作人',
        width: 60
      }]
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'remove') {
        this.time = '';
        this.removeObj(this.obj);
      }

      if (this.time.length > 0) {
        this.obj.beginCreateTime = this.time[0] || '';
        this.obj.endCreateTime = this.time[1] || '';
      }

      this.openLoading();
      var data = {};
      Object.keys(this.obj).forEach(function (key) {
        if (_this.obj[key] != '') {
          data[key] = _this.obj[key];
        }
      });
      (0, _member.listUserChangeLogPage)(data).then(function (res) {
        _this.openLoading().close();

        _this.total = res.data.totalNum || 0;

        if (!_this.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.oldMobile) {
              item.oldMobile = item.oldMobile.replace(item.oldMobile.substring(3, 7), "****");
            }

            if (item.newMobile) {
              item.newMobile = item.newMobile.replace(item.newMobile.substring(3, 7), "****");
            }
          });
        }

        _this.list = res.data.items;
      }).catch(function (err) {
        _this.openLoading().close();
      });
    },
    goAdd: function goAdd(url) {
      this.$router.push(url);
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['旧手机号', '新手机号', '切换时间', '钉钉工单', '操作人'];
        var filterVal = ['oldMobile', 'newMobile', 'createTime', 'dingtalkNumber', 'operator'];
        /*
           {
            prop: 'operator',
            label: '操作人',
            width: 60
          }
        ]
         */

        var list = [];

        if (_this2.time.length > 0) {
          _this2.obj.beginCreateTime = _this2.time[0] || '';
          _this2.obj.endCreateTime = _this2.time[1] || '';
        }

        var data = {}; // Object.assign({}, this.obj);

        Object.keys(_this2.obj).forEach(function (key) {
          if (_this2.obj[key]) {
            data[key] = _this2.obj[key];
          }
        });
        data.pageSize = _this2.total;
        data.pageNo = 1;

        _this2.openLoading();

        (0, _member.listUserChangeLogPage)(data).then(function (res) {
          if (!_this2.setMenuPerms('user_mobile_idCard')) {
            res.data.items.forEach(function (item) {
              if (item.oldMobile) {
                item.oldMobile = item.oldMobile.replace(item.oldMobile.substring(3, 7), "****");
              }

              if (item.newMobile) {
                item.newMobile = item.newMobile.replace(item.newMobile.substring(3, 7), "****");
              }
            });
          }

          list = res.data.items;

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '切换记录'
          });
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        }).catch(function (res) {
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;