"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '尊享活动配置',
      list: [{
        tit: '分类管理',
        txtArr: '<p>页面分类管理</p>',
        imgPath: require('@/assets/tab/activity.png'),
        path: '/svip/service/serviceCategory'
      }, {
        tit: '轮播图管理',
        txtArr: '<p>设页面轮播图管理</p>',
        imgPath: require('@/assets/tab/log.png'),
        path: '/svip/reserve/list'
      }]
    };
  }
};
exports.default = _default;