"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addKinds = addKinds;
exports.cancelOrder = cancelOrder;
exports.classPage = classPage;
exports.delKinds = delKinds;
exports.editKinds = editKinds;
exports.getKinds = getKinds;
exports.orderRefundAdd = orderRefundAdd;
exports.recordsConfirm = recordsConfirm;
exports.startCard = startCard;
exports.storeOrder = storeOrder;
exports.waitConfirm = waitConfirm;

var _request = _interopRequireDefault(require("@/utils/request"));

function waitConfirm(data) {
  return (0, _request.default)({
    url: '/api/make/store/order/records/wait/confirm',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function recordsConfirm(data) {
  return (0, _request.default)({
    url: '/api/make/store/order/records/confirm',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function classPage(data) {
  return (0, _request.default)({
    url: '/api/make/store/order/records/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addKinds(data) {
  return (0, _request.default)({
    url: '/api/make/store/sale/batch/add/buy/records',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/kinds/update',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function delKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/kinds/' + data,
    method: 'post'
  });
}

function getKinds(data) {
  return (0, _request.default)({
    url: '/api/make/store/order/records/detail',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function startCard(data) {
  return (0, _request.default)({
    url: '/api/make/store/sale/get/batch/start/card',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
卡激活
/api/make/store/order
 */


function storeOrder(data) {
  return (0, _request.default)({
    url: '/api/make/store/order/card/active',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function cancelOrder(data) {
  return (0, _request.default)({
    url: '/api/make/store/order/cancel/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function orderRefundAdd(data) {
  return (0, _request.default)({
    url: '/api/make/store/order/refund/add',
    method: 'post',
    loading: 'loading',
    data: data
  });
}