"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      query: {}
    };
  },
  methods: {
    onReset: function onReset() {
      this.query = {};
    },
    onSearch: function onSearch() {},
    onRead: function onRead() {},
    onReadNew: function onReadNew() {},
    onCancel: function onCancel() {},
    onSave: function onSave() {}
  }
};
exports.default = _default;