"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name'])),
  data: function data() {
    return {
      tableData: [],
      requestData: {
        chargeChannel: '',
        name: null,
        account: null,
        pageSize: 10,
        page: 1
      },
      searchData: [
      /* {
        label: '订单号',
        type: 'input',
        span: 5,
        placeholder: '订单号',
        value: 'chargeOrderNo'
      }, */
      {
        label: '操作人',
        type: 'input',
        span: 3,
        placeholder: '操作人手机号',
        value: 'operUserMobile'
      }, {
        label: '用户手机号',
        type: 'input',
        span: 3,
        placeholder: '用户手机号',
        value: 'chargeUserMobile'
      }, {
        label: '卡类型',
        type: 'radio',
        span: 3,
        placeholder: '卡类型',
        value: 'chargeChannel',
        select: [{
          label: '全部订单',
          value: ''
        }, {
          label: '后台充值',
          value: 'OD'
        }, {
          label: '蜻蜓充值',
          value: 'QT'
        }]
      }, {
        label: '开始时间',
        type: 'dateTime',
        span: 4,
        placeholder: '开始时间',
        value: 'startDate'
      }, {
        label: '结束时间',
        type: 'dateTime',
        span: 4,
        placeholder: '结束时间',
        defaultTime: '23:59:59',
        value: 'endDate'
      }]
    };
  }
};
exports.default = _default;