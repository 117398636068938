"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLegouGoods = getLegouGoods;

var _phpRequest = _interopRequireDefault(require("@/utils/phpRequest.js"));

function getLegouGoods(data) {
  return (0, _phpRequest.default)({
    url: 'api/goods/venue-goods/goods-list',
    method: 'get',
    data: data
  });
}