var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            pageApi: "/api/svip/level/eventLogPage",
            "search-data": _vm.searchData
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "要客状态",
              align: "center",
              width: "120"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.oldStatus == 1
                      ? _c("div", [_vm._v("待加入")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.oldStatus == 2
                      ? _c("div", [_vm._v("已加入")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.oldStatus == 3
                      ? _c("div", [_vm._v("已剔除")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.oldStatus == 4
                      ? _c("div", [_vm._v("已过期")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              align: "center",
              width: "180"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.setMenuPerms("lavantDetail")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/svip/member/detail/" + scope.row.mobile
                                )
                              }
                            }
                          },
                          [_vm._v("\n         详情\n       ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }