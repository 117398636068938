"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.array.find");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon.js");

var _deposit = require("@/api/deposit");

var _api = require("@/views/mall/goods/api.js");

var _api2 = require("@/views/activity/lottery/api.js");

var _api3 = require("@/views/member/vipCitylife/api.js");

var _api4 = require("@/views/activity/help/api.js");

var _api5 = require("@/views/activity/apply/api.js");

var _template = require("@/api/template.js");

var _api6 = require("@/views/member/class/api.js");

var _index = require("@/api/svip/index.js");

var _api7 = require("@/api/api.js");

var _axios = _interopRequireDefault(require("axios"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _map = require("@/views/svip/const/map");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default
  },
  props: {
    couponShow: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    goodsType: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      tableWidthMap: {
        svipServiceDetail: '1200px',
        svipActivityDetail: '1200px',
        svipSpaceDetail: '1200px'
      },
      openGfit: '',
      type: '',
      getRowKeys: function getRowKeys(row) {
        return row.uuid;
      },
      radioId: {},
      tableData: [],
      total: 0,
      cardType: 1,
      obj: {
        cardNo: null,
        pageNo: 1,
        pageSize: 10,
        onlineStatus: 1,
        status: 2,
        onShel: 1,
        couponType: '',
        uuid: '',
        name: '',
        productCode: '',
        productName: '',
        productSource: [],
        categoryIds: [],
        id: ''
      },
      options: [{
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣券'
      }, {
        value: 3,
        label: '礼品券'
      }, {
        value: 4,
        label: '体验券'
      }],
      tabTh: [],
      couponOptions: [{
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '已审核'
      }, {
        value: 3,
        label: '已撤回'
      }, {
        value: 4,
        label: '已撤销'
      }, {
        value: 5,
        label: '已过期'
      }, {
        value: 6,
        label: '已终止'
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    rowClick: function rowClick(item) {
      console.log(item);
      this.radioId = item;
    },
    affirmAddCoupon: function affirmAddCoupon() {
      this.$emit('setAwardItem', this.radioId);
    },
    getList: function getList(type, id, e) {
      // this.radioId=id||''
      if (type === 'goods') {
        if (e) {
          this.openGfit = e;
          this.obj.productSource = [e];
        }

        this.radioId.productCode = id || '';
      } else if (type === 'pond') {
        this.radioId.code = id || '';
      } else if (type === 'legouGoods') {
        this.radioId.id = id || '';
      } else if (type === 'couponBag' || type === 'help') {
        this.radioId.productCode = id || '';
      } else if (type === 'vipCityLife') {
        this.radioId.productCode = id || '';
      } else if (type === 'tem') {
        this.radioId.pageCode = id || '';
      } else if (type === 'vip') {
        this.radioId.id = id ? Number(id) : '';
      } else {
        this.radioId.uuid = id || '';
      }

      this.type = type;

      if (this.type === 'storeCard') {
        this.tabTh = [{
          tit: '卡号',
          prop: 'cardNo',
          width: 120
        }, {
          tit: '卡种名称',
          prop: 'cardKindsName',
          width: 100
        }, {
          tit: '卡类型',
          prop: 'cardCategoryName',
          width: 100
        }, {
          tit: '卡片面额（元）',
          prop: 'cardLimitPrice',
          width: 100
        }, {
          tit: '激活后有效期（年）',
          prop: 'expireTradeYear',
          width: 100
        }, {
          tit: '制卡商',
          prop: 'makeFactoryName',
          width: 100
        }, {
          tit: '入库时间',
          prop: 'inTime',
          width: 120
        }, {
          tit: '状态',
          prop: 'statusDesc',
          width: 50
        }, {
          tit: '出入库状态',
          prop: 'childStatusDesc',
          width: 80
        }];
      }

      if (this.type === 'activity') {
        this.tabTh = [{
          tit: '活动名称',
          prop: 'name',
          width: 200
        }, {
          tit: '活动编码',
          prop: 'uuid',
          width: 160,
          align: 'center'
        }, {
          tit: '分类',
          prop: 'className',
          width: 140
        }];
      }

      if (this.type === 'card' || this.type === 'giftToken') {
        this.tabTh = [{
          tit: '储值卡号',
          prop: 'uuid',
          width: 200
        }, {
          tit: '储值卡名称',
          prop: 'name',
          width: 160,
          align: 'center'
        }, {
          tit: '分类',
          prop: 'className',
          width: 140
        }];
      }

      if (this.type === 'coupon') {
        this.tabTh = [{
          tit: '优惠券编码',
          prop: 'uuid',
          width: 180
        }, {
          tit: '优惠券名称',
          prop: 'name'
        }, {
          tit: '类型',
          prop: 'couponType',
          width: 80
        }, {
          tit: '优惠券有效期',
          prop: 'time',
          width: 200
        }, {
          tit: '状态',
          prop: 'statusText',
          width: 90
        }];
      }

      if (this.type === 'goods') {
        this.tabTh = [{
          tit: '商品名称',
          prop: 'productName',
          width: 200
        }, {
          tit: '商品编码',
          prop: 'productCode',
          width: 160,
          align: 'center'
        }, {
          tit: '分类',
          prop: 'categoryName',
          width: 140
        }];
      }

      if (this.type === 'pond') {
        this.tabTh = [{
          tit: '活动编码',
          prop: 'code',
          width: 200
        }, {
          tit: '活动名称',
          prop: 'name',
          width: 160,
          align: 'center'
        }, {
          tit: '主办机构',
          prop: 'orgName',
          width: 140
        }];
      }

      if (this.type === 'vipCityLife') {
        this.tabTh = [{
          tit: '商品名称',
          prop: 'productName',
          width: 100
        }, {
          tit: '商品编码',
          prop: 'productCode',
          width: 100,
          align: 'center'
        }, {
          tit: '同城分类',
          prop: 'className',
          width: 100
        }, {
          tit: '投放数量',
          prop: 'putNumber',
          width: 50
        }];
      }
      /* listCitylifePage */


      if (this.type === 'couponBag') {
        this.tabTh = [{
          tit: '券包ID',
          prop: 'id',
          width: 60
        }, {
          tit: '券包编码',
          prop: 'uuid',
          width: 120
        }, {
          tit: '券包名称',
          prop: 'name',
          width: 160,
          align: 'center'
        }, {
          tit: '有效期',
          prop: 'receiveTimeCycle',
          width: 200
        }, {
          tit: '投放对象',
          prop: 'putTarget',
          width: 60
        }];
      }

      if (this.type === 'tem') {
        this.tabTh = [{
          tit: '页面编码',
          prop: 'pageCode',
          width: 60
        }, {
          tit: '页面名称',
          prop: 'pageName',
          width: 120
        }, {
          tit: '当前页面模板',
          prop: 'tmplateName',
          width: 160,
          align: 'center'
        }];
      }

      if (this.type === 'legouGoods') {
        this.tabTh = [{
          tit: 'ID',
          prop: 'id',
          width: 60
        }, {
          tit: '商品名称',
          prop: 'name',
          width: 100
        }, {
          tit: '原价',
          prop: 'original_price',
          width: 80
        }, {
          tit: '售价',
          prop: 'price',
          width: 80
        }, {
          tit: '365折扣',
          prop: 'discount_rate',
          width: 80
        }];
      }

      if (this.type === 'help') {
        this.tabTh = [{
          tit: 'ID',
          prop: 'id',
          width: 60
        }, {
          tit: '活动编码',
          prop: 'activityCode',
          width: 120
        }, {
          tit: '活动名称',
          prop: 'activityName',
          width: 160,
          align: 'center'
        }];
      }

      if (this.type === 'apply') {
        this.tabTh = [{
          tit: 'ID',
          prop: 'id',
          width: 60
        }, {
          tit: '活动名称',
          prop: 'name',
          width: 100,
          align: 'center'
        }, {
          tit: '起始有效期',
          prop: 'startTime',
          width: 100,
          align: 'center'
        }, {
          tit: '结束有效期',
          prop: 'endTime',
          width: 100,
          align: 'center'
        }];
      }

      if (this.type === 'vip') {
        this.tabTh = [{
          tit: '付费会员名称',
          prop: 'payMemberName',
          width: 100
        }, {
          tit: '付费会员简称',
          prop: 'payMemberNameShort',
          width: 80
        }, {
          tit: '购买次数',
          prop: 'limitBuyTimes',
          width: 50
        }, {
          tit: '生效天数',
          prop: 'validDays',
          width: 50
        }, {
          tit: '售价',
          prop: 'buyPrice',
          width: 50
        }, {
          tit: '创建时间',
          prop: 'createTime',
          width: 50
        }];
      }

      if (this.type === 'svipServiceDetail') {
        this.tabTh = [{
          tit: '服务ID',
          prop: 'id',
          width: 50
        }, {
          tit: '服务名称',
          prop: 'name'
        }, {
          tit: '服务分类',
          prop: 'categoryName'
        }, {
          tit: '预约方式',
          prop: 'reserveType',
          formatter: this.formatterReserveType
        }, {
          tit: '支付方式',
          prop: 'buyType',
          formatter: this.formatterBuyType
        }, {
          tit: '创建时间',
          prop: 'createTime'
        }, {
          tit: '启动时间',
          prop: 'startUpTime'
        }, {
          tit: '结束时间',
          prop: 'endUpTime'
        }];
      }

      if (this.type === 'svipActivityDetail') {
        this.tabTh = [{
          tit: '活动ID',
          prop: 'id',
          width: 50
        }, {
          tit: '活动名称',
          prop: 'name'
        }, {
          tit: '支付方式',
          prop: 'buyType',
          formatter: this.formatterBuyType
        }, {
          tit: '活动时间',
          prop: 'activityTime'
        }, {
          tit: '活动状态',
          prop: 'status',
          formatter: this.formatterActivityStatus
        }, {
          tit: '已预约人数',
          prop: 'reservedNum'
        }];
      }

      if (this.type === 'svipSpaceDetail') {
        this.tabTh = [{
          tit: '场地ID',
          prop: 'id',
          width: 50
        }, {
          tit: '场地名称',
          prop: 'name'
        }, {
          tit: '面积',
          prop: 'area'
        }, {
          tit: '容纳量',
          prop: 'intakeCapacity'
        }, {
          tit: '销售标准',
          prop: 'saleStandard',
          formatter: this.formatterSaleStandard
        }];
      }

      this.init('remove');
    },
    init: function init(types) {
      var _this = this;

      if (types === 'search') {
        this.obj.pageNo = 1;
      }

      if (types === 'remove') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
        this.obj.couponType = '';
        this.obj.uuid = '';
        this.obj.name = '';
        this.obj.productCode = '';
        this.obj.productName = '';
        this.tableData = [];
        this.total = 0;
        this.obj.activityName = null;
        this.obj.activityCode = null;
        this.obj.cardNo = null;
      }

      if (this.type === 'vip') {
        this.obj.status = 1;
        (0, _api6.getVipList)(this.obj).then(function (res) {
          _this.tableData = res.data.records;
          _this.total = res.data.total;
        });
      } else if (this.type === 'legouGoods') {
        _axios.default.get(process.env.VUE_APP_LEGO_API, {
          params: {
            r: 'api/goods/venue-goods/goods-list',
            store_id: 2,
            page: this.obj.pageNo,
            limit: this.obj.pageSize,
            goods_name: this.obj.name,
            goods_ids: this.obj.id
          }
        }).then(function (res) {
          console.log(res);
          _this.tableData = res.data.data.list;
          _this.total = res.data.data.pagination.row_count;
        });
      } else if (this.type === 'storeCard') {
        this.obj.status = null;
        (0, _api7.getStoreCardPage)(this.obj).then(function (res) {
          _this.tableData = res.data.records;
          _this.total = res.data.count;
        });
      } else if (this.type === 'activity') {
        (0, _coupon.listActivityPage)(this.obj).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      } else if (this.type === 'card') {
        var requestData = JSON.parse(JSON.stringify(this.obj));

        if (this.cardType) {
          requestData.type = [this.cardType];
        } else {
          requestData.type = [];
        }

        (0, _deposit.listCardStored)(requestData).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      } else if (this.type === 'giftToken') {
        var data = JSON.parse(JSON.stringify(this.obj));
        data.type = 3;
        (0, _deposit.listCardStored)(data).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      } else if (this.type === 'goods') {
        var _data = this.obj;

        if (this.goodsType === 'activity') {
          /*  data.scoreMin = 0
                data.scoreMax = 0
                data.priceMin = 0
                data.priceMax = 0 */
        }

        console.log(_data);
        (0, _api.list)(_data).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      } else if (this.type === 'pond') {
        (0, _api2.lotteryList)(this.obj).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      } else if (this.type === 'couponBag') {
        var _data2 = JSON.parse(JSON.stringify(this.obj));

        _data2.status = 7;
        (0, _coupon.listCouponBagPage)(_data2).then(function (res) {
          _this.tableData = res.data.records;
          _this.total = res.data.total;
        });
      } else if (this.type === 'vipCityLife') {
        (0, _api3.listCitylifePage)(this.obj).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      } else if (this.type === 'tem') {
        var _data3 = {
          pageNo: this.obj.pageNo,
          pageSize: this.obj.pageSize
        };
        (0, _template.listPage)(_data3).then(function (res) {
          _this.total = res.data.totalNum;
          _this.tableData = res.data.items;
        });
      } else if (this.type === 'help') {
        var _data4 = {
          pageNo: this.obj.pageNo,
          pageSize: this.obj.pageSize,
          activityName: this.obj.activityName,
          activityCode: this.obj.activityCode,
          status: [2, 3]
        };
        (0, _api4.helpPage)(_data4).then(function (res) {
          _this.total = res.data.total;
          _this.tableData = res.data.records;
        });
      } else if (this.type === 'apply') {
        var _data5 = {
          pageNo: this.obj.pageNo,
          pageSize: this.obj.pageSize
        };
        (0, _api5.getList)(_data5).then(function (res) {
          _this.total = res.data.total;
          _this.tableData = res.data.records;
        });
      } else if (this.type === 'coupon') {
        var _data6 = JSON.parse(JSON.stringify(this.obj));

        _data6.status = 2;
        (0, _coupon.listCouponPage)(_data6).then(function (res) {
          _this.total = res.data.totalNum;
          var data = res.data.items;
          data.forEach(function (value) {
            value.check = false;

            if (value.validTimeType === 1) {
              value.time = value.validBeginTime.substring(0, 10) + '至' + value.validEndTime.substring(0, 10);
            } else if (value.validTimeType === 2) {
              value.time = _this.getValidDay(value); // '领取后' + value.validDay + '天内有效'
            }

            if (value.couponType === 1) {
              value.couponType = '代金券';
            } else if (value.couponType === 2) {
              value.couponType = '折扣券';
            } else if (value.couponType === 3) {
              value.couponType = '礼品券';
            } else if (value.couponType === 4) {
              value.couponType = '体验券';
            }

            var statusObj = _this.couponOptions.find(function (s) {
              return s.value === value.status;
            });

            value.statusText = statusObj ? statusObj.label : '';
          });
          _this.tableData = data;
        });
      } else if (this.type === 'svipServiceDetail') {
        (0, _index.getPageServiceList)({
          pageNo: this.obj.pageNo,
          pageSize: this.obj.pageSize,
          name: this.obj.name
        }).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      } else if (this.type === 'svipActivityDetail') {
        (0, _index.getPageActivityList)({
          pageNo: this.obj.pageNo,
          pageSize: this.obj.pageSize,
          name: this.obj.name
        }).then(function (res) {
          _this.tableData = res.data.items.map(function (item) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              activityTime: item.startTime + '~' + item.endTime
            });
          });
          _this.total = res.data.totalNum;
        });
      } else if (this.type === 'svipSpaceDetail') {
        (0, _index.getPagePlaceList)({
          pageNo: this.obj.pageNo,
          pageSize: this.obj.pageSize,
          name: this.obj.name
        }).then(function (res) {
          _this.tableData = res.data.items;
          _this.total = res.data.totalNum;
        });
      }
    },
    formatterReserveType: function formatterReserveType(row) {
      return _map.SvipServiceReserveType[row.reserveType];
    },
    formatterBuyType: function formatterBuyType(row) {
      return _map.SvipServiceBuyType[row.buyType];
    },
    formatterActivityStatus: function formatterActivityStatus(row) {
      return _map.SvipActivityStatus[row.status];
    },
    formatterSaleStandard: function formatterSaleStandard(row, column) {
      return _map.SvipPlaceSaleStandard[row.saleStandard];
    }
  }
};
exports.default = _default2;