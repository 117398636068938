var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { margin: "10px 0" },
          attrs: { size: "mini" }
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [_c("span", [_vm._v("购物卡状态")])]
          ),
          _vm._v(" "),
          _c(
            "el-steps",
            { attrs: { size: "mini", active: _vm.active } },
            _vm._l(_vm.step, function(item, index) {
              return _c("el-step", {
                key: index,
                attrs: { title: item.tit, description: item.txt }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "box_btn" }, [
            _c("p", [
              _vm._v("当前状态："),
              _c("span", [_vm._v(_vm._s(_vm.status))])
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.btnType === 1 || _vm.btnType === 2
                  ? _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "cardStored:edit",
                            expression: "'cardStored:edit'"
                          }
                        ],
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.goEditor }
                      },
                      [_vm._v("修改信息\n         ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.btnType === 1
                  ? _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "cardStored:audit",
                            expression: "'cardStored:audit'"
                          }
                        ],
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.setStatus(2)
                          }
                        }
                      },
                      [_vm._v("通过审核\n         ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.btnType === 1
                  ? _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "cardStored:audit",
                            expression: "'cardStored:audit'"
                          }
                        ],
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.setStatus(3)
                          }
                        }
                      },
                      [_vm._v("撤回审核\n         ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.btnType === 1
                  ? _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "cardStored:audit",
                            expression: "'cardStored:audit'"
                          }
                        ],
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.setStatus(4)
                          }
                        }
                      },
                      [_vm._v("撤销审核\n         ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { margin: "10px 0" },
          attrs: { size: "mini" }
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [_c("span", [_vm._v("储值卡详情")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dea_box" },
            [
              _c(
                "el-descriptions",
                {
                  attrs: {
                    size: "small ",
                    labelStyle: {
                      width: "120px",
                      textAlign: "right"
                    },
                    column: 3,
                    border: ""
                  }
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "储值卡名称：" } },
                    [_vm._v("\n           " + _vm._s(_vm.name) + "\n         ")]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "卡类型：" } }, [
                    _vm.type === 1
                      ? _c("span", [_vm._v("MIKA线上购物卡")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === 7
                      ? _c("span", { staticClass: "danger" }, [_vm._v("红包")])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "储值卡分类：" } },
                    [
                      _vm._v(
                        "\n           " + _vm._s(_vm.className) + "\n         "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "回传卡类型：" } },
                    [
                      _vm._v(
                        "\n           " +
                          _vm._s(_vm.categoryObj[_vm.category]) +
                          "\n         "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "一句话文案：" } },
                    [
                      _vm._v(
                        "\n           " +
                          _vm._s(_vm.description) +
                          "\n         "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "发行数量：" } },
                    [
                      _vm._v(
                        "\n           " + _vm._s(_vm.number) + "\n         "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "可充值金额：" } },
                    _vm._l(_vm.rechargeRmount, function(item, index) {
                      return _c(
                        "span",
                        { key: index, staticStyle: { "margin-right": "5px" } },
                        [
                          _vm._v(
                            "\n             " + _vm._s(item) + "元\n           "
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "输入其他金额：" } },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.ifRechargeAmount == 1 ? "success" : "danger"
                        },
                        [
                          _vm._v(
                            "\n             " +
                              _vm._s(
                                _vm.ifRechargeAmount == 1
                                  ? "可以输入"
                                  : "不可以输入"
                              ) +
                              "\n           "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "卡面：" } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "50px" },
                        attrs: {
                          src: _vm.cover,
                          "preview-src-list": [_vm.cover]
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "适用范围：", span: 3 } },
                    [
                      _vm.scopeType === 2
                        ? _c("div", [
                            _c("p", { staticClass: "form_remark" }, [
                              _vm._v("会员体系/机构:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "10px" } },
                              _vm._l(_vm.effMembers, function(item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticStyle: { "margin-right": "6px" }
                                  },
                                  [_vm._v("\n                 " + _vm._s(item))]
                                )
                              }),
                              0
                            )
                          ])
                        : _c("p", { staticClass: "form_remark" }, [
                            _vm._v("\n             全部可用\n           ")
                          ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.type === 7
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "不可用商品码：", span: 3 } },
                        [
                          _vm._v(
                            "\n           " +
                              _vm._s(_vm.cardData.goodsIds.join("、")) +
                              "\n         "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "储值卡说明：", span: 3 } },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.cardData.remark) }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }