"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

require("core-js/modules/es7.object.values");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es7.array.includes");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default
  },
  data: function data() {
    var _ruleForm;

    return {
      skuForm: {
        skuOriginalPrice: undefined,
        skuGoodsPrice: undefined,
        skuStock: undefined,
        goodsCode: undefined
      },
      categoryList: [],
      isDetail: false,
      inputValue: null,
      inputVisible: false,
      isSUccess: false,
      activeIndex: 'goods_one',
      jdGoodsUrl: '',
      formType: null,
      classList: [],
      parameterItem: null,
      changeSpecList: [],
      //规格数组
      specValue: null,
      deliveryList: [],
      formData: [{
        type: "divider",
        title: "一键采集",
        id: 'goods_one'
      }, {
        slot: "getJD"
      }, {
        type: "divider",
        title: "基本信息",
        id: 'goods_info'
      },
      /* {
         label: '商品来源平台：',
         type: 'radio',
         placeholder: '请选择商品来源平台',
         value: 'goodsPlatform',
         parent: 'baseGoodsSpuDTO',
         isRules:true,
         selectList: [{
           label: '乐购',
           value: 1
         }, ]
       }, */
      {
        label: '平台分类：',
        type: 'cascader',
        placeholder: '请选择平台分类',
        value: 'firstCategoryId',
        selectList: []
      }, {
        label: '店内分类：',
        type: 'select',
        placeholder: '请选择店内分类',
        value: 'merchantCategoryId',
        selectList: []
      }, {
        label: '商品品牌：',
        type: 'select',
        placeholder: '请选择商品牌',
        value: 'brandId',
        selectList: []
      }, {
        label: '商品名称：',
        type: 'input',
        placeholder: '请输入商品名称',
        parent: 'baseGoodsSpuDTO',
        value: 'goodsName'
      }, {
        label: '重量：',
        type: 'inputNumber',
        placeholder: '请输入商品重量（克）',
        value: 'goodsWeight' // end:'克'

      }, {
        label: '单位：',
        type: 'select',
        placeholder: '请选择单位',
        value: 'goodsUnit',
        selectList: []
      }, {
        label: '商品展示图：',
        type: 'imgList',
        placeholder: '请上传商品展示图',
        value: 'goodsImages',
        selectCover: 'selectCover',
        isHttp: true
      }, {
        slot: 'selectCover',
        value: 'selectCover',
        placeholder: '请选择默认图'
      }, {
        label: '商品服务：',
        type: 'tag',
        placeholder: '请输入商品服务',
        value: 'service',
        isRules: false,
        remark: "例子:正品保障,极速发货,7天退换货。"
      }, {
        type: "divider",
        title: "商品属性",
        id: 'goods_type'
      }, {
        label: '成本价：',
        type: 'inputNumber',
        placeholder: '请输入成本价',
        value: 'spuCostPrice',
        end: '元'
      }, {
        label: '原价：',
        type: 'inputNumber',
        placeholder: '请输入原价',
        value: 'spuOriginalPrice',
        end: '元'
      }, {
        label: '售价：',
        type: 'inputNumber',
        placeholder: '请输入售价',
        value: 'spuGoodsPrice',
        end: '元'
      }, {
        label: '是否使用规格：',
        type: 'radio',
        placeholder: '请选择是否使用规格',
        value: 'isMultipleSku',
        //remark:"开启规格后请重新选择商品分类",
        disabled: false,
        selectList: [{
          label: '开启',
          value: 1
        }, {
          label: '关闭',
          value: 0
        }]
      }, {
        label: '库存：',
        type: 'inputNumber',
        placeholder: '请输入库存',
        value: 'skuStock',
        min: 0,
        isShow: 'no'
      }, {
        label: '昂捷编码：',
        type: 'input',
        placeholder: '请输入昂捷编码',
        value: 'goodsCode',
        min: 0,
        isShow: 'no'
      }, {
        slot: "goodsSku",
        placeholder: '请添加规格组',
        value: 'goodsSkuDTO'
      },
      /* {
         slot: "sku",
         label: '规格设置：',
       }, */
      {
        type: "divider",
        title: "营销设置",
        id: 'goods_coupon'
      }, {
        label: '是否开启会员折扣：',
        type: 'radio',
        placeholder: '请选择是否开启会员折扣',
        value: 'isOpenMemberDiscount',
        selectList: [{
          label: '开启',
          value: 1
        }, {
          label: '关闭',
          value: 0
        }]
      }, {
        label: '会员折扣百分比：',
        type: 'inputNumber',
        placeholder: '请输入会员折扣百分比',
        value: 'memberDiscountPercent',
        min: 10,
        max: 100,
        isShow: 'no',
        precision: 0,
        remark: "请填写折扣比率，8折填写80，8.5折填写85，以此类推",
        end: '%',
        rules: [{
          validator: function validator(rule, value, callback) {
            if (value < 10 || value > 100) {
              callback(new Error('请输入10~100之间的值'));
            } else {
              callback();
            }
          }
        }]
      }, {
        label: '分销设置：',
        type: 'radio',
        placeholder: '请选择分销设置',
        value: 'isOpenShareCommission',
        selectList: [{
          label: '开启',
          value: 1
        }, {
          label: '关闭',
          value: 0
        }]
      }, {
        label: '一级分销佣金设置：',
        type: 'inputNumber',
        placeholder: '请输入分销佣金设置',
        value: 'shareCommissionFirst',
        end: '%',
        precision: 0,
        min: 0,
        max: 100,
        isShow: "no",
        remark: '一级分销佣金在0到100之间',
        rules: [{
          validator: function validator(rule, value, callback) {
            if (value < 0 || value > 100) {
              callback(new Error('请输入0~100之间的值'));
            } else {
              callback();
            }
          }
        }]
      },
      /* {
         label: '二级分销佣金设置：',
         type: 'inputNumber',
         placeholder: '请输入分销佣金设置',
         value: 'shareCommissionSecond',
         min: 1,
         max: 100
       },
       {
         label: '三级分销佣金设置：',
         type: 'inputNumber',
         placeholder: '请输入分销佣金设置',
         value: 'shareCommissionThird',
         min: 1,
         max: 100
       }, */
      {
        label: '购买会员类型限制：',
        type: 'check',
        placeholder: '请选择允许购买的会员类型',
        value: 'userMultipleLimit',
        selectList: [{
          label: '普通会员',
          value: 1
        }, {
          label: '365会员',
          value: 2
        }, {
          label: 'lavant会员',
          value: 3
        }]
      }, {
        label: '限购数量：',
        type: 'inputNumber',
        min: 0,
        precision: 0,
        placeholder: '请输入限购数量',
        value: 'limitPurchaseQuantity',
        remark: "0为不限购"
      }, {
        type: "divider",
        title: "商品设置",
        id: 'goods_set'
      }, {
        label: '运费设置：',
        type: 'select',
        placeholder: '请选择运费模板',
        value: 'freightTemplateId',
        selectList: []
      }, {
        label: '加入购物车：',
        type: 'radio',
        placeholder: '请选择是否加入购物车',
        parent: 'baseGoodsSpuDTO',
        value: 'isAllowAddCart',
        isRules: true,
        selectList: [{
          label: '是',
          value: 1
        }, {
          label: '否',
          value: 0
        }]
      }, {
        type: "tinymce",
        label: '图文详情：',
        placeholder: '请输入图文详情',
        value: 'detail'
      }],
      ruleForm: (_ruleForm = {
        "id": null,
        "goodsPlatform": 1,
        "spuNo": 0,
        "goodsName": "",
        "goodsUnit": null,
        "isAllowAddCart": 1,
        "isOpenMemberDiscount": 0,
        "memberDiscountPercent": 0,
        "service": [],
        "brandId": '',
        "freightTemplateId": null,
        "firstCategoryId": [],
        "secondCategoryId": null,
        "thirdCategoryId": null,
        "merchantCategoryId": null,
        "limitPurchaseQuantity": 0,
        "userMultipleLimit": [1, 2, 3],
        "virtualSales": 0,
        "goodsWeight": undefined,
        "goodsImages": [],
        "detail": "",
        "isOpenShareCommission": 0,
        "shareCommissionFirst": 0,
        "shareCommissionSecond": 0,
        "shareCommissionThird": 0,
        spuCostPrice: undefined,
        spuOriginalPrice: undefined
      }, (0, _defineProperty2.default)(_ruleForm, "spuOriginalPrice", undefined), (0, _defineProperty2.default)(_ruleForm, "isMultipleSku", 1), (0, _defineProperty2.default)(_ruleForm, "goodsSkuDTO", []), (0, _defineProperty2.default)(_ruleForm, "skuStock", undefined), (0, _defineProperty2.default)(_ruleForm, "goodsCode", null), (0, _defineProperty2.default)(_ruleForm, "selectCover", null), _ruleForm),
      rules: {
        skuOriginalPrice: [{
          required: true,
          message: '请输入原价'
        }],
        skuGoodsPrice: [{
          required: true,
          message: '请输入售价'
        }],
        skuStock: [{
          required: true,
          message: '请输入库存'
        }],
        goodsCode: [{
          required: true,
          message: '请输入昂捷编码'
        }],
        skuImage: [{
          required: true,
          message: '请上传图片'
        }]
      }
    };
  },
  computed: {},
  watch: {
    /* changeSpecList(newValue, oldValue) {
        console.log(arr)
     } */
  },
  created: function created() {
    var _this = this;

    /*
    店内分类模板
    */
    this.apiPost('/api/mall/merchantCategory/list', {
      pageNo: 1,
      pageSize: 200,
      endpointFrom: 0
    }).then(function (res) {
      var selectList = res.data.records.map(function (item) {
        return {
          label: item.categoryName,
          value: item.id
        };
      });

      _this.formData.forEach(function (item) {
        if (item.value === 'merchantCategoryId') {
          item.selectList = selectList;
        }
      });
    });
    /*
    运费模板
    */

    this.apiPost('/api/mall/deliveryRule/list', {
      pageNo: 1,
      pageSize: 200
    }).then(function (res) {
      var selectList = res.data.records.map(function (item) {
        return {
          label: item.name,
          value: item.id
        };
      });

      _this.formData.forEach(function (item) {
        if (item.value === 'freightTemplateId') {
          item.selectList = selectList;
        }
      });
    });
    /*
    商品单位
    */

    this.apiPost('/api/mall/goods/attribute/unit', {}).then(function (res) {
      var selectList = res.data.map(function (item) {
        return {
          label: item,
          value: item
        };
      });

      _this.formData.forEach(function (item) {
        if (item.value === 'goodsUnit') {
          item.selectList = selectList;
        }
      });
    });
    /*
    获取商品分类
    */

    this.apiPost('/api/mall/goods/category/list', {}).then(function (res) {
      var data = _this.IterationDelateMenuChildren(res.data);

      _this.categoryList = data;
      data.forEach(function (item) {
        item.disabled = true;
      });

      _this.formData.forEach(function (item) {
        if (item.value === 'firstCategoryId') {
          item.selectList = data;
        }
      });

      _this.isSUccess = true;
    });
    /*
    获取商品品牌
    */

    this.apiPost('/api/mall/goods/brand/all', {}).then(function (res) {
      _this.formData.forEach(function (item) {
        if (item.value === 'brandId') {
          item.selectList = res.data.map(function (item) {
            return {
              label: item.brandName,
              value: item.id
            };
          });
        }
      });
    });
    this.formType = this.$route.query.type;
    this.ruleForm.id = this.$route.query.id || '';
    /*
    获取商品详情
    */

    if (this.ruleForm.id > 0) {
      this.apiPost('/api/mall/goods/goods/detail', {
        id: this.ruleForm.id
      }).then(function (res) {
        var goodsSpu = res.data.goodsSpu;
        goodsSpu.service = goodsSpu.service ? JSON.parse(goodsSpu.service) : [];
        goodsSpu.userMultipleLimit = goodsSpu.userMultipleLimit.split(',').map(Number);
        var resData = (0, _objectSpread2.default)({}, goodsSpu);
        /*
        arr 规格组
        specData  规格组子集
        */

        var arr = [];
        var specData = {};
        /*
        规格组赋值
        */

        if (res.data.goodsSpu.isMultipleSku == 1) {
          resData.goodsSkuDTO = res.data.goodsSkuVO.map(function (item) {
            item.saleProperties = item.saleProperties.split(';').map(function (row) {
              var specItem = row.split(':');

              if (!arr.includes(specItem[0])) {
                arr.push(specItem[0]);
              }

              if (specData[specItem[0]]) {
                if (!specData[specItem[0]].includes(specItem[1])) {
                  specData[specItem[0]].push(specItem[1]);
                }
              } else {
                specData[specItem[0]] = [];
                specData[specItem[0]].push(specItem[1]);
              }

              return specItem[1];
            });
            item.opType = 1;
            return (0, _objectSpread2.default)({}, item);
          });
          _this.changeSpecList = arr.map(function (item) {
            return {
              label: item,
              value: item,
              list: specData[item]
            };
          });
        } else {
          resData.goodsSkuDTO = [];
          resData.skuStock = res.data.goodsSkuVO[0].skuStock;
          resData.goodsCode = res.data.goodsSkuVO[0].goodsCode;

          _this.formData.forEach(function (item) {
            if (item.value === 'skuStock' || item.value == 'goodsCode') {
              item.isShow = '';
            }
          });
        }

        _this.formData.forEach(function (item) {
          if (item.value === 'memberDiscountPercent' && goodsSpu.isOpenMemberDiscount == 1) {
            item.isShow = '';
          }

          if (item.value === 'shareCommissionFirst' && goodsSpu.isOpenShareCommission == 1) {
            item.isShow = '';
          }

          if (item.value === 'isMultipleSku') {
            item.disabled = true;
          }
        });
        /*
        默认图
        */


        resData.selectCover = goodsSpu.mainImage;
        /*
        商品图
        */

        _this.goodsImageVO = res.data.goodsImageVO;
        resData.goodsImages = res.data.goodsImageVO.map(function (item) {
          return item.goodsImageUrl;
        });
        resData.firstCategoryId = [goodsSpu.firstCategoryId, goodsSpu.secondCategoryId];

        if (goodsSpu.thirdCategoryId) {
          resData.firstCategoryId.push(goodsSpu.thirdCategoryId);
        }

        resData.merchantCategoryId = goodsSpu.merchantCategoryId < 1 ? 1 : goodsSpu.merchantCategoryId;
        resData.freightTemplateId = goodsSpu.freightTemplateId < 1 ? 1 : goodsSpu.freightTemplateId;
        _this.ruleForm = resData;
        _this.isDetail = true;
        /* "firstCategoryId": [],
         "secondCategoryId": null,
         "thirdCategoryId": null, */
      });
    }
  },
  mounted: function mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    getJdGoods: function getJdGoods() {
      var _this2 = this;

      this.apiPost('/api/mall/goods/goods/spider', {
        url: this.jdGoodsUrl
      }).then(function (res) {
        _this2.$nextTick(function () {
          this.ruleForm.detail = res.data.detail || '';
          this.ruleForm.goodsImages = res.data.goodsImageVO || [];
          this.goodsImageVO = res.data.goodsImageVO.map(function (item) {
            return {
              goodsImageUrl: item
            };
          });
          this.ruleForm.goodsName = res.data.goodsName || '';
          this.$refs.addGoods.setTinymce();
        });
      });
    },

    /*
    滚动到错误位置
    */
    goScrollIntoView: function goScrollIntoView(prop) {
      var dom = this.$refs[prop];
      dom.$el.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });
    },
    delSkuItem: function delSkuItem(row, index) {
      if (row.opType == 2) {
        this.ruleForm.goodsSkuDTO[index].opType = 1;
      } else {
        var goodsSkuDTO = this.ruleForm.goodsSkuDTO.filter(function (item) {
          return item.opType != 2;
        });

        if (goodsSkuDTO.length < 2) {
          this.$message.error('必须留有一个规格');
          return;
        }

        if (row.id) {
          this.ruleForm.goodsSkuDTO[index].opType = 2;
        } else {
          this.ruleForm.goodsSkuDTO.splice(index, 1);
        }
      }
    },
    setAllSku: function setAllSku() {
      var _this$skuForm = this.skuForm,
          skuOriginalPrice = _this$skuForm.skuOriginalPrice,
          skuGoodsPrice = _this$skuForm.skuGoodsPrice,
          skuStock = _this$skuForm.skuStock,
          goodsCode = _this$skuForm.goodsCode;
      this.ruleForm.goodsSkuDTO.forEach(function (item) {
        item.skuOriginalPrice = skuOriginalPrice >= 0 ? skuOriginalPrice : item.skuOriginalPrice;
        item.skuGoodsPrice = skuGoodsPrice >= 0 ? skuGoodsPrice : item.skuGoodsPrice;
        item.skuStock = skuStock >= 0 ? skuStock : item.skuStock;
        item.goodsCode = goodsCode ? goodsCode : item.goodsCode;
      });
    },

    /*
    设置sku图片
    */
    setCover: function setCover(e, index, prop) {
      this.ruleForm.goodsSkuDTO[index][prop] = e;
    },

    /*
    设置规格
    */
    changeSpec: function changeSpec() {
      var _this3 = this;

      var arr = []; //this.ruleForm.goodsSkuDTO=

      this.changeSpecList.forEach(function (item) {
        arr.push(item.list);
      });

      function generateCombinations(arrays, currentIndex, currentCombination, combinations) {
        if (currentIndex === arrays.length) {
          combinations.push((0, _objectSpread2.default)({}, currentCombination));
          return;
        }

        var currentArray = arrays[currentIndex];
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = currentArray[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var item = _step.value;
            currentCombination[currentIndex] = item;
            generateCombinations(arrays, currentIndex + 1, currentCombination, combinations);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      var combinations = [];
      generateCombinations(arr, 0, {}, combinations);
      var specArr = combinations.map(function (item) {
        /*
        添加规格时已有规格不覆盖
        */
        var specItem = _this3.ruleForm.goodsSkuDTO.find(function (row) {
          return row.saleProperties.toString() == Object.values(item).toString();
        });

        if (specItem) {
          return specItem;
        } else {
          return {
            saleProperties: Object.values(item),
            goodsCode: undefined,
            skuStock: undefined,
            skuImage: '',
            skuOriginalPrice: undefined,
            skuGoodsPrice: undefined,
            opType: 0,
            goodsSpuId: 0,
            skuNo: '',
            id: 0
          };
        }
      });
      this.ruleForm.goodsSkuDTO = specArr; //goodsSkuDTO
    },

    /*
    删除规格组
    */
    removeSpecItem: function removeSpecItem(e, index) {
      var _this4 = this;

      if (this.changeSpecList.length > 1) {
        this.$confirm('确认删除规格组', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this4.changeSpecList.splice(index, 1);

          _this4.changeSpec();
        }).catch(function () {
          _this4.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },

    /*
    删除规格
    */
    handleClose: function handleClose(tag, prop) {
      var _this5 = this;

      //if (this.ruleForm.goodsSkuDTO)
      this.$confirm('确认删除规格', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this5.changeSpecList[prop].list.splice(_this5.changeSpecList[prop].list.indexOf(tag), 1);

        _this5.ruleForm.goodsSkuDTO = _this5.ruleForm.goodsSkuDTO.filter(function (item) {
          return !item.saleProperties.includes(tag);
        });
      }).catch(function () {
        _this5.$message({
          type: 'info',
          message: '已取消删除'
        });
      }); //this.changeSpec()
    },

    /*
    打开规格组输入框
    */
    showInput: function showInput(index) {
      var _this6 = this;

      this.$set(this.changeSpecList[index], 'inputVisible', true); // this.inputVisible = true;

      this.$nextTick(function () {
        _this6.$refs.saveTagInput[0].$refs.input.focus();
      });
    },

    /*
    确认规格输入
    */
    handleInputConfirm: function handleInputConfirm(e, prop) {
      var inputValue = this.inputValue;

      if (inputValue) {
        if (inputValue.indexOf(':') >= 0 || inputValue.indexOf(';') >= 0) {
          this.$message.error('规格名称不能包含:和;');
        } else if (this.changeSpecList[prop].list.includes(inputValue)) {
          this.$message.error('添加规格重复');
        } else {
          this.changeSpecList[prop].list.push(inputValue);
        }
      }

      this.$set(this.changeSpecList[prop], 'inputVisible', false);
      this.changeSpec(); // this.inputVisible = false;

      this.inputValue = '';
    },

    /*
    选择分类 获取规格组
    */
    changeCascader: function changeCascader(data, prop) {
      this.ruleForm.goodsSkuDTO = [];
      var changeSpecList = data.gcGoodsSpec.split(' ');
      this.changeSpecList = changeSpecList.map(function (item) {
        return {
          label: item,
          value: item,
          list: []
        };
      });
    },

    /*
    表单change事件
    */
    changeForm: function changeForm(e, prop) {
      var _this7 = this;

      if (prop == 'isMultipleSku') {
        this.formData.forEach(function (item) {
          if (item.value === 'skuStock' || item.value == 'goodsCode') {
            item.isShow = _this7.ruleForm.isMultipleSku == 0 ? '' : 'no';
            _this7.ruleForm.skuStock = undefined;
            _this7.ruleForm.goodsCode = undefined;
          }
        });
        return;
      }

      if (prop == 'isOpenMemberDiscount') {
        this.formData.forEach(function (item) {
          if (item.value === 'memberDiscountPercent') {
            item.isShow = _this7.ruleForm.isOpenMemberDiscount == 1 ? '' : 'no';
            _this7.ruleForm.memberDiscountPercent = _this7.ruleForm.isOpenMemberDiscount == 1 ? undefined : '';
          }
        });
        return;
      }

      if (prop == 'isOpenShareCommission') {
        this.formData.forEach(function (item) {
          if (item.value === 'shareCommissionFirst') {
            item.isShow = _this7.ruleForm.isOpenShareCommission == 1 ? '' : 'no';
            _this7.ruleForm.shareCommissionFirst = _this7.ruleForm.isOpenShareCommission == 1 ? undefined : '';
          }
        });
        return;
      }
    },

    /*
    分类子集  为空数组处理
    */
    IterationDelateMenuChildren: function IterationDelateMenuChildren(rt) {
      if (rt.length) {
        for (var i in rt) {
          if (rt[i].children.length) {
            this.IterationDelateMenuChildren(rt[i].children);
          } else {
            delete rt[i].children;
          }
        }
      }

      return rt;
    },

    /*
    导航栏
    */
    handleSelect: function handleSelect(e) {
      document.getElementById(e).scrollIntoView({
        block: 'center'
      });
    },

    /*
    保存商品
    */
    submitForm: function submitForm(formName) {
      var _this8 = this;

      var testRequestData = (0, _objectSpread2.default)({}, this.ruleForm);
      var changeSpecList = this.changeSpecList;
      var goodsSkuDTO = JSON.parse(JSON.stringify(testRequestData.goodsSkuDTO));
      delete testRequestData.goodsSkuDTO;

      if (this.ruleForm.isMultipleSku == 1) {
        goodsSkuDTO.forEach(function (item) {
          item.saleProperties = item.saleProperties.map(function (row, key) {
            return "".concat(changeSpecList[key].value, ":").concat(row);
          }).join(';');
          item.opType = item.id ? item.opType : 0;
        });
      } else {
        goodsSkuDTO = [{
          saleProperties: "规格:默认",
          goodsCode: this.ruleForm.goodsCode,
          skuStock: this.ruleForm.goodsCode,
          skuImage: this.ruleForm.selectCover,
          skuOriginalPrice: this.ruleForm.goodsCode,
          skuGoodsPrice: this.ruleForm.goodsCode,
          opType: this.ruleForm.id ? 1 : 0,
          goodsSpuId: 0,
          skuNo: '',
          id: 0
        }];
      }
      /*
      服务转为JSON字符串
      */


      testRequestData.service = JSON.stringify(testRequestData.service);
      /*
      限制会员为字符串
      */

      testRequestData.userMultipleLimit = testRequestData.userMultipleLimit.join(',');
      /*
      分类ID 赋值
      */

      var _this$ruleForm$firstC = (0, _slicedToArray2.default)(this.ruleForm.firstCategoryId, 3),
          firstCategoryId = _this$ruleForm$firstC[0],
          secondCategoryId = _this$ruleForm$firstC[1],
          thirdCategoryId = _this$ruleForm$firstC[2];

      testRequestData.firstCategoryId = firstCategoryId || 0;
      testRequestData.secondCategoryId = secondCategoryId || 0;
      testRequestData.thirdCategoryId = thirdCategoryId || 0;
      /*
      图片格式化
      */

      var goodsImages = this.ruleForm.goodsImages.map(function (item, key) {
        var imagesItem = _this8.goodsImageVO.find(function (row) {
          return item === row.goodsImageUrl;
        });

        if (imagesItem) {
          imagesItem.goodsImageSort = key + 1;
          imagesItem.isDefault = imagesItem.goodsImageUrl == _this8.ruleForm.selectCover ? 1 : 0;
          return imagesItem;
        } else {
          return {
            id: 0,
            goodsId: 0,
            goodsImageUrl: item,
            goodsImageSort: key + 1,
            isDefault: item == _this8.ruleForm.selectCover ? 1 : 0
          };
        }
      });
      testRequestData.goodsImages = goodsImages;
      var formData = {
        goodsSkuDTO: goodsSkuDTO,
        baseGoodsSpuDTO: testRequestData
      };
      console.log(JSON.stringify(formData));
      var apiUrl = this.ruleForm.id ? '/api/mall/goods/goods/update' : '/api/mall/goods/goods/create';
      this.apiPost(apiUrl, formData).then(function (res) {
        _this8.$router.go(-1);

        _this8.$message({
          type: 'success',
          message: _this8.ruleForm.id ? '编辑成功!' : '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;