"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _finance = require("@/api/finance");

var _index = require("@/views/system/manage/index.js");

var _index2 = require("@/utils/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var applyWayOptions = [{
  value: 1,
  label: '小程序'
}, {
  value: 2,
  label: '米卡后台'
}, {
  value: 3,
  label: '核销助手'
}];
var statusOptions = [{
  value: -1,
  label: '已取消',
  color: ''
}, {
  value: 0,
  label: '待审核',
  color: ''
}, {
  value: 1,
  label: '审核通过',
  color: ''
}, {
  value: 2,
  label: '未通过',
  color: ''
}];
var memberTypeOptions = [{
  value: 0,
  label: '普通会员'
}, {
  value: 1,
  label: '365会员'
}, {
  value: 2,
  label: '要客'
}];
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      statusOptions: statusOptions,
      memberTypeOptions: memberTypeOptions,
      applyWayOptions: applyWayOptions,
      requestTimes: [],
      requestParams: {
        pageNo: 1,
        pageSize: 10,
        code: undefined,
        phone: undefined,
        applyPerson: undefined,
        applyWay: undefined,
        status: undefined,
        storeId: undefined
      },
      total: 0,
      list: [],
      //
      storeOptions: [],
      storeLoading: false
    };
  },
  computed: {
    _requestTimes: function _requestTimes() {
      if (this.requestTimes && this.requestTimes.length > 1) {
        return {
          beginTime: this.requestTimes[0],
          endTime: this.requestTimes[1]
        };
      }

      return {};
    }
  },
  activated: function activated() {
    this.handRemoteStore();
    this.getList();
  },
  methods: {
    getOptionsValue: _index2.getOptionsValue,
    handApply: function handApply() {
      this.$router.push('/finance/receiptUpload/apply');
    },
    handCheck: function handCheck(row) {
      this.$router.push({
        path: '/finance/receiptUpload/detail',
        query: {
          id: row.id
        }
      });
    },
    handDetail: function handDetail(row) {
      this.$router.push({
        path: '/finance/receiptUpload/detail',
        query: {
          id: row.id,
          action: 'detail'
        }
      });
    },
    handReset: function handReset() {
      this.$refs['requestParamsForm'].resetFields();
      this.handRemoteStore();
      this.requestTimes = [];
      this.handQuery();
    },
    handQuery: function handQuery() {
      this.requestParams.pageNo = 1;
      this.getList();
    },
    handPageChange: function handPageChange() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.requestParams), this._requestTimes);
      (0, _finance.receiptUploadPage)(params).then(function (res) {
        if (!_this.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.phone) {
              item.phone = item.phone.replace(item.phone.substring(3, 7), "****");
            }
          });
        }

        _this.list = res.data.items;
        _this.total = res.data.totalNum;
      });
    },
    handRemoteStore: function handRemoteStore(query) {
      var _this2 = this;

      this.storeLoading = true;
      var params = {
        pageNo: 1,
        pageSize: 20,
        fullName: query
      };
      return (0, _index.listStorePage)(params).then(function (res) {
        _this2.storeOptions = res.data.items;
        _this2.storeLoading = false;
      }).catch(function (err) {
        _this2.storeLoading = false;
      });
    }
  }
};
exports.default = _default;