var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "improve-setting" },
    [
      _c("header", [_vm._m(0), _vm._v(" "), _c("el-divider")], 1),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "cutom-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            "label-width": "180px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "提示文案", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入提示文案" },
                model: {
                  value: _vm.form.title,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "奖励类型", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeType },
                  model: {
                    value: _vm.form.type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("积分")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("优惠券")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("购物卡")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.type == 1
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "积分数量", prop: "quantity" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入积分数量" },
                      model: {
                        value: _vm.form.quantity,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "quantity", $$v)
                        },
                        expression: "form.quantity"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "备注", prop: "remark" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", placeholder: "请输入备注" },
                      model: {
                        value: _vm.form.remark,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "remark", $$v)
                        },
                        expression: "form.remark"
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.form.type == 2
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "优惠券", prop: "code" } },
                  [
                    _c("el-input", {
                      staticClass: "form_input",
                      attrs: { disabled: "", placeholder: "请选择优惠券" },
                      model: {
                        value: _vm.form.code,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "code",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.code"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "100px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.getAward("coupon")
                          }
                        }
                      },
                      [_vm._v("选择优惠券")]
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.form.type == 3
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "购物卡", prop: "code" } },
                  [
                    _c("el-input", {
                      staticClass: "form_input",
                      attrs: { disabled: "", placeholder: "请选择购物卡" },
                      model: {
                        value: _vm.form.code,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "code",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.code"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "100px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.getAward("card")
                          }
                        }
                      },
                      [_vm._v("选择购物卡")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "金额", prop: "quantity" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入金额" },
                      model: {
                        value: _vm.form.quantity,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "quantity", $$v)
                        },
                        expression: "form.quantity"
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("取消")
              ])
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "view_title" }, [
      _c("span", [_vm._v("奖励设置")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }