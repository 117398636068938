"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '券包管理',
      list: [{
        tit: '券包列表',
        txtArr: '券包查询，券包新增，券包编辑',
        imgPath: require('@/assets/tab/1.png'),
        path: '/coupon/coupon1/couponGift'
      }, {
        tit: '券包订单',
        txtArr: "\u5238\u5305\u8BA2\u5355\u67E5\u8BE2\uFF0C\u5BFC\u51FA",
        imgPath: require('@/assets/tab/2.png'),
        path: '/coupon/coupon1/couponGiftOrder'
      }, {
        tit: '券包领取记录查询',
        txtArr: "\u5238\u5305\u9886\u53D6\u8BB0\u5F55\u67E5\u8BE2\uFF0C\u5BFC\u51FA",
        imgPath: require('@/assets/tab/3.png'),
        path: '/coupon/coupon1/couponGiftOrder4'
      }, {
        tit: '券包兑换码订单',
        txtArr: "\u5238\u5305\u5151\u6362\u7801\u8BA2\u5355\u67E5\u8BE2\uFF0C\u5BFC\u51FA",
        imgPath: require('@/assets/tab/5.png'),
        path: '/coupon/coupon1/giftCodeOrder',
        limit: 'couponBag:codeOrder'
      }]
    };
  }
};
exports.default = _default;