var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 券包管理 "),
        _c(
          "div",
          [
            _vm.setMenuPerms("couponBag:add")
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "max-width": "100%" },
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-circle-plus-outline"
                    },
                    on: {
                      click: function($event) {
                        return _vm.wPage("/coupon/coupon1/addCouGift")
                      }
                    }
                  },
                  [_vm._v("新增券包")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "券包名称" },
                model: {
                  value: _vm.requestData.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "券包编码" },
                model: {
                  value: _vm.requestData.uuid,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "uuid",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.uuid"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticClass: "search_input",
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "券包状态"
                  },
                  model: {
                    value: _vm.requestData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "status", $$v)
                    },
                    expression: "requestData.status"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "待审核", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "待开始", value: 6 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "领取中", value: 7 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已暂停", value: 8 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已领完", value: 9 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已结束", value: 10 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticClass: "search_input",
                attrs: {
                  size: "mini",
                  type: "daterange",
                  "range-separator": "——",
                  format: "yyyy 年 MM 月 dd 日",
                  "value-format": "yyyy-MM-dd hh:MM:ss",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.getList("search")
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "info",
                    size: "mini",
                    icon: "el-icon-refresh"
                  },
                  on: {
                    click: function($event) {
                      return _vm.getList("remove")
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "labelContent",
              label: "可购买人群标签",
              align: "center",
              "mni-width": "100"
            },
            slot: "labelContent",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getUserLabel(scope.row.labelContent)) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "popupStatus",
              label: "状态",
              align: "center",
              "min-width": "80"
            },
            slot: "popupStatus",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 1
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("待审核")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 6
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("待开始")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 7
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("领取中")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 8
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("已暂停")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 9
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("已领完")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 10
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("已结束")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              fixed: "right",
              width: "200"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.setMenuPerms("couponBag:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/coupon/coupon1/addCouGift?id=" +
                                    scope.row.id
                                )
                              }
                            }
                          },
                          [_vm._v("编辑 ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 1 &&
                    _vm.setMenuPerms("couponBag:audit")
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认通过审核？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goAudit(scope, 2)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "warning",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("审核")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    [6, 7, 8, 9].indexOf(scope.row.status) >= 0 &&
                    _vm.setMenuPerms("couponBag:superaddition")
                      ? _c(
                          "el-button",
                          {
                            staticClass: "success",
                            attrs: { type: "text", plain: "", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.gosuperadditionById(scope)
                              }
                            }
                          },
                          [_vm._v("追加")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    [6, 7].indexOf(scope.row.status) >= 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", plain: "", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.getQrcode(scope.row)
                              }
                            }
                          },
                          [_vm._v("二维码")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    [8].indexOf(scope.row.status) >= 0
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认重启该优惠券包？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goAudit(scope, 11)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "success",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("重启")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    [6, 7].indexOf(scope.row.status) >= 0
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认暂停该优惠券包？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goAudit(scope, 8)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "danger",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("暂停")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    [1].indexOf(scope.row.status) >= 0 &&
                    _vm.setMenuPerms("couponBag:delete")
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认删除该优惠券包？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goDel(scope)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "danger",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.store > 0 &&
                    scope.row.status === 7 &&
                    _vm.setMenuPerms("couponBag:exportCode")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.getCode5(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n          兑换码\n        ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isExchange
        ? _c(
            "div",
            { staticClass: "fixed_center" },
            [
              _c(
                "el-card",
                { staticClass: "box-card", staticStyle: { width: "500px" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.codeData.name))]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "danger",
                          staticStyle: { float: "right", padding: "3px 0" },
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              _vm.isExchange = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("WForm", {
                    attrs: {
                      isSetRules: "",
                      isTitle: false,
                      btnText: "导出兑换码",
                      "form-data": _vm.formData,
                      "label-width": "100px",
                      "rule-form": _vm.ruleForm
                    },
                    on: { submitForm: _vm.submitForm }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              "qr-code": _vm.qrCode,
              url: _vm.url,
              "coupon-u-uid": _vm.couponUUid
            },
            on: { closePop: _vm.closePop }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }