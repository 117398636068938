"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _manage = require("@/api/manage.js");

var _integral = require("@/api/integral.js");

var _user = require("@/api/user");

var _coupon = require("@/api/coupon.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var validateAcquaintance = function validateAcquaintance(rule, value, callback) {
  console.log(value); // 输入 8--，value 为 8
  // 估计这里内部使用了 parseInt() / parseFloat()

  if (value < 0 || value > 3) {
    callback(new Error('在 0 至 100 %之间'));
  } else {
    callback();
  }
};

var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    var _rules;

    return {
      form: {},
      rules: (_rules = {
        cditionType: [{
          required: true,
          message: '请选择条件类型'
        }],
        time: [{
          required: true,
          message: '请选择活动时间'
        }],
        birthdayDay: [{
          required: true,
          message: '请输入生日前后N天'
        },, {
          validator: validateAcquaintance,
          message: '请输入为0到3的数值'
        }],
        scoreMultiple: [{
          required: true,
          message: '请输入额外积分倍数'
        }, {
          type: 'number',
          message: '倍数必须为数字值'
        }],
        score: [{
          required: true,
          message: '请输入额外积分'
        }, {
          type: 'number',
          message: '积分必须为数字值'
        }],
        cditionStatus: [{
          required: true,
          message: '请选择策略状态'
        }]
      }, (0, _defineProperty2.default)(_rules, "cditionStatus", [{
        required: true,
        message: '请选择策略状态'
      }]), (0, _defineProperty2.default)(_rules, "controlType", [{
        required: true,
        message: '请选择是否控制'
      }]), (0, _defineProperty2.default)(_rules, "basicCodes", [{
        required: true,
        message: '请选择基础策略编码'
      }]), _rules),
      cditionId: '',
      popShow: false,
      time: [],
      basicCodeData: {
        pageNo: 1,
        pageSize: 10,
        basicStatus: 1
      },
      popTableData: [],
      multipleSelection: [],
      getRowKeys: function getRowKeys(row) {
        return row.basicId;
      },
      getRowKeys1: function getRowKeys1(row) {
        return row.type;
      },
      total: 0,
      tabTh: [{
        tit: '编号',
        prop: 'basicCode',
        align: 'center',
        width: 150
      }, {
        tit: '消费金额(元)',
        prop: 'sellAmount',
        sortable: true,
        align: 'center',
        width: 90
      }, {
        tit: '积分',
        prop: 'getScore',
        align: 'center',
        width: 80
      }, {
        tit: '执行时间',
        prop: 'validStartTime',
        align: 'center',
        width: 170
      }, {
        tit: '截止时间',
        prop: 'validEndTime',
        align: 'center',
        width: 170
      }, {
        tit: '状态',
        prop: 'basicStatus',
        align: 'center',
        width: 80
      }],
      obj: {
        time: [],
        cditionType: 1,
        basicCodes: [],
        cycleJson: [],
        cditionStatus: '',
        controlType: '',
        birthdayDay: '',
        scoreMultiple: '',
        score: ''
      },
      listScore: [],
      cditionTypes: [],
      tableData: [{
        type: 'week',
        class: '每周',
        cycle: null,
        score: null
      }, {
        type: 'month',
        class: '每月',
        cycle: null,
        score: null
      }, {
        type: 'year',
        class: '每年',
        cycle: null,
        score: null
      }],
      options: [{
        value: 1,
        label: '周一'
      }, {
        value: 2,
        label: '周二'
      }, {
        value: 3,
        label: '周三'
      }, {
        value: 4,
        label: '周四'
      }, {
        value: 5,
        label: '周五'
      }, {
        value: 6,
        label: '周六'
      }, {
        value: 7,
        label: '周日'
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _integral.listScoreBasicPage)({
      pageSize: 10,
      pageNo: 1,
      basicStatus: 1
    }).then(function (res) {
      (0, _integral.listScoreBasicPage)({
        pageSize: res.data.totalNum,
        pageNo: 1,
        basicStatus: 1
      }).then(function (row) {
        // this.listScore=row.data.items
        _this.init(row.data.items);
      });
    });
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.time = _this2.obj.time ? _this2.obj.time : [];
          var data = _this2.obj;
          data.validStartTime = _this2.obj.time[0] || '';
          data.validEndTime = _this2.obj.time[1] || '';
          data.cycleJson = [];

          if (_this2.obj.cditionType == 3) {
            _this2.cditionTypes.forEach(function (item) {
              if (item.cycle && item.score) {
                data.cycleJson.push({
                  attr_type: item.type,
                  attr_value: item.cycle,
                  score: item.score
                });
              }
            });
          }

          data.cycleJson = JSON.stringify(data.cycleJson);

          if (data.cycleJson == '[]' && _this2.obj.cditionType == 3) {
            _this2.$message.error('请填写特定日数据');

            return;
          } else if (_this2.obj.basicCodes.length == 0) {
            _this2.$message.error('请选择基础策略编码');

            return;
          } else {
            if (_this2.cditionId) {
              data.cditionId = _this2.cditionId;
              (0, _integral.editScoreCdition)(data).then(function (res) {
                _this2.$message({
                  type: 'success',
                  message: '编辑成功!'
                });

                _this2.$router.go(-1);
              });
            } else {
              (0, _integral.addScoreCdition)(data).then(function (res) {
                _this2.$message({
                  type: 'success',
                  message: '新增成功!'
                });

                _this2.$router.go(-1);
              });
            }
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    init: function init(listScore) {
      var _this3 = this;

      this.cditionId = Number(this.$route.query.id) || '';

      if (this.cditionId) {
        (0, _integral.getScoreCdition)(this.cditionId).then(function (res) {
          console.log(res);
          _this3.obj.time = [res.data.validStartTime, res.data.validEndTime];
          _this3.obj.controlType = res.data.controlType;
          _this3.obj.cditionStatus = res.data.cditionStatus;
          _this3.obj.cditionType = res.data.cditionType;
          _this3.obj.basicCodes = JSON.parse(res.data.basicCode);

          _this3.obj.basicCodes.forEach(function (item, index) {
            listScore.forEach(function (row) {
              if (row.basicCode == item) {
                _this3.listScore.push(row);
              }
            });
          });

          _this3.listScore.forEach(function (row) {
            _this3.$refs.multipleTablePop.toggleRowSelection(row);
          });

          if (res.data.cditionType == 3) {
            var cycleJson = JSON.parse(res.data.cycleJson);

            _this3.setTableData(cycleJson);
          } else {
            if (res.data.cditionType == 2) {
              _this3.obj.birthdayDay = res.data.birthdayDay;
            }

            _this3.obj.scoreMultiple = res.data.scoreMultiple;
            _this3.obj.score = res.data.score;
          }
        });
      }
    },
    setTableData: function setTableData(cycleJson) {
      var _this4 = this;

      var chexkTable = [];
      cycleJson.forEach(function (item) {
        switch (item.attr_type) {
          case 'week':
            chexkTable.push({
              type: 'week',
              class: '每周',
              cycle: item.attr_value,
              score: item.score
            });
            break;

          case 'month':
            chexkTable.push({
              type: 'month',
              class: '每月',
              cycle: item.attr_value,
              score: item.score
            });
            break;

          case 'year':
            chexkTable.push({
              type: 'year',
              class: '每年',
              cycle: item.attr_value,
              score: item.score
            });
            break;

          default:
            break;
        }

        _this4.tableData.forEach(function (row) {
          if (row.type == item.attr_type) {
            row.cycle = item.attr_value;
            row.score = item.score;

            _this4.$refs.multipleTable.toggleRowSelection(row);
          }
        });
      });
    },
    goAdd: function goAdd() {},
    selectionCditionType: function selectionCditionType(e) {
      this.cditionTypes = e;
    },

    /*
          选择商品时
          */
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    goBasicCodes: function goBasicCodes() {
      var _this5 = this;

      (0, _integral.listScoreBasicPage)(this.basicCodeData).then(function (res) {
        _this5.popShow = true;
        _this5.total = res.data.totalNum;
        _this5.popTableData = res.data.items;

        _this5.popTableData.forEach(function (item) {
          item.basicStatus = item.basicStatus == 1 ? '启用' : '禁用';
        });
      });
    },

    /*
          关闭商品弹窗
          */
    closePop: function closePop() {
      this.popShow = false;
    },

    /*
          清空商品选择
          */
    clearCoupon: function clearCoupon() {
      this.$refs.multipleTablePop.clearSelection();
    },

    /*
          保存商品选择
          */
    affirmAddCoupon: function affirmAddCoupon() {
      var _this6 = this;

      console.log(this.multipleSelection);
      this.obj.basicCodes = [];
      this.multipleSelection.forEach(function (item) {
        _this6.obj.basicCodes.push(item.basicCode);
      });
      this.$refs['form'].validate(function (valid) {});
      this.popShow = false;
    }
  }
};
exports.default = _default;