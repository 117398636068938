"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _tableList = _interopRequireDefault(require("@/components/tableList"));

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default
  },
  data: function data() {
    return {
      deaPath: '/coupon/coupon3/addActivityClass?id=',
      pageNo: 1,
      pageSize: 10,
      total: 0,
      btn: '编辑',
      operation: 2,
      btnType: 'primary',
      tableData: [],
      tabTh: [{
        tit: '分类名称',
        prop: 'name',
        align: 'center'
      }, {
        tit: '添加时间',
        prop: 'createTime',
        sortable: true,
        align: 'center'
      }]
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    setBtn: function setBtn(scope) {
      // 编辑
      console.log(scope.id);
      this.$router.push('/coupon/coupon3/addActivityClass?id=' + scope.classId);
    },
    goAdd: function goAdd() {
      // 去添加
      this.$router.push('/coupon/coupon3/addActivityClass');
    },
    init: function init() {
      var _this = this;

      (0, _coupon.listActivityClass)().then(function (res) {
        console.log(res);
        _this.tableData = res.data;
      });
    },
    setValue: function setValue() {
      this.value = '1';
      this.idCard = '';
      this.nameCard = '';
    }
  }
};
exports.default = _default;