"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    var _self = this;

    return {
      imgSrc: this.$store.state.user.imgSrc,
      action: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
      isRefund: false,
      ruleForm: {
        attachment: ['mika/dev/2022-03-14/03cb1dff8800473a859b071eabf19a07.png'],
        orderNo: null,
        deductPrice: null,
        actualRefundPrice: null,
        remark: null
      },
      rules: {
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        attachment: [{
          required: true,
          message: '请上传附件'
        }],
        deductPrice: [{
          required: true,
          message: '请输入金额'
        }, {
          validator: function validator(rule, value, callback) {
            console.log(_self.orderData.orderVo.orderAmount);

            if (value > _self.orderData.orderVo.orderAmount) {
              callback(new Error("\u91D1\u989D\u9700\u5C0F\u4E8E\u8BA2\u5355\u91D1\u989D"));
            } else if (value < 0) {
              callback(new Error("\u91D1\u989D\u9700\u5927\u4E8E\u7B49\u4E8E0\u5143"));
            } else {
              callback();
            }
          }
        }],
        actualRefundPrice: [{
          required: true,
          message: '请输入金额'
        }, {
          validator: function validator(rule, value, callback) {
            if (value > _self.orderData.orderVo.orderAmount - _self.ruleForm.deductPrice) {
              callback(new Error("\u91D1\u989D\u9700\u5C0F\u4E8E\u8BA2\u5355\u91D1\u989D\u51CF\u53BB\u6263\u51CF\u91D1\u989D"));
            } else if (value <= 0) {
              callback(new Error("\u91D1\u989D\u9700\u5927\u4E8E0\u5143"));
            } else {
              callback();
            }
          }
        }]
      },
      orderData: {
        orderVo: {},
        buyRecordsVo: [],
        payCategoryVoList: [],
        descriptionVo: {}
      },
      cardNo: null,
      rebateOrderCardVoList: [],
      rebateTotalPrice: null,
      columns2: [{
        label: '返利卡号',
        prop: 'cardNo',
        width: 80
      }, {
        label: '返利金额',
        prop: 'rebatePrice',
        width: 80
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        label: '更新时间',
        prop: 'updateTime',
        width: 80
      }],
      columns1: [{
        label: '支付方式',
        prop: 'payCategoryName',
        width: 80
      }, {
        label: '支付金额',
        prop: 'payPrice',
        width: 80
      }],
      columns: [{
        label: '卡种名称',
        prop: 'cardKindsName',
        width: 80
      }, {
        label: '卡类型',
        prop: 'cardCategoryName',
        width: 70
      }, {
        label: '卡片面额（元）',
        prop: 'cardLimitPrice',
        width: 80
      }, {
        label: '激活后有效期（年）',
        prop: 'expireTradeYear',
        width: 80
      }, {
        label: '起始卡号',
        prop: 'cardSegmentNoStart'
      }, {
        label: '结束卡号',
        prop: 'cardSegmentNoEnd'
      }, {
        label: '数量',
        prop: 'buyNum'
      }, {
        slot: 'price',
        label: '小计（元）',
        prop: 'price'
      }, {
        slot: 'operate'
      }]
    };
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        'Token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.cardNo = this.$route.query.id;
    this.init();
  },
  methods: {
    onSuccess: function onSuccess(res) {
      var _this = this;

      if (res.ret === 1000) {
        this.ruleForm.attachment.push(res.data);
      } else if (res.ret === 6000) {
        this.$confirm(res.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this2 = this;

      this.ruleForm.attachment = [];
      fileList.forEach(function (item) {
        _this2.ruleForm.attachment.push(item.response.data);
      });
    },
    goStoreOrder: function goStoreOrder() {
      var _this3 = this;

      (0, _api.storeOrder)({
        id: this.orderData.orderVo.id,
        orderNo: this.orderData.orderVo.orderNo
      }).then(function (res) {
        _this3.init();
      });
    },
    init: function init() {
      var _this4 = this;

      if (this.cardNo) {
        (0, _api.getKinds)({
          cardNo: this.cardNo
        }).then(function (res) {
          _this4.ruleForm.orderNo = res.data.orderVo.orderNo;
          _this4.orderData = res.data;
          _this4.rebateTotalPrice = res.data.cardMakeRebateOrderInfoVo.rebateTotalPrice;
          _this4.rebateOrderCardVoList = res.data.cardMakeRebateOrderInfoVo.rebateOrderCardVoList;
        });
      }
    },
    submit: function submit(type) {
      this.$refs['ruleForm'].validateField(type);
    },
    closeSelect: function closeSelect() {
      this.isShowSelect = false;
    },
    getRelevances: function getRelevances() {
      var _this5 = this;

      this.isShowSelect = true;
      this.$nextTick(function () {
        _this5.$refs.selectPop.init();
      });
    },
    submitForm: function submitForm(formName) {
      var _this6 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this6.ruleForm));
          data.attachment = JSON.stringify(data.attachment);
          (0, _api.orderRefundAdd)(data).then(function (res) {
            _this6.$refs[formName].resetFields();

            _this6.$refs.uploadImg.clearFiles();

            _this6.isRefund = false;

            _this6.init();

            _this6.$message({
              type: 'success',
              message: '退款成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;