"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _api = require("./api.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    var _self = this;

    return {
      timeEnd: null,
      timeStart: null,
      pickerTime0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < _self.timeStart || time.getTime() > _self.timeEnd;
        }
      },
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.startTime).getTime();
          return time.getTime() < validBeginTime || time.getTime() > _self.timeEnd;
        }
      },
      pickerOptions: {},
      storeList: [],
      costList: {
        1: '集团',
        2: '门店',
        3: '专柜/品牌、商户'
      },
      rules: {
        'name': [{
          required: true,
          message: '请输入活动名称'
        }],
        'activityMonth': [{
          required: true,
          message: '请选择活动周期'
        }],
        'startTime': [{
          required: true,
          message: '请选择开始时间'
        }],
        'endTime': [{
          required: true,
          message: '请选择结束时间'
        }],
        'costContributor': [{
          required: true,
          message: '请选择活动出资方'
        }],
        'limitType': [{
          required: true,
          message: '请选择活动参与方式'
        }],
        'limitNumber': [{
          required: true,
          message: '请输入参与次数'
        }],
        'targetUser': [{
          required: true,
          message: '请选择定向人群'
        }],
        'description': [{
          required: true,
          message: '请输入规则说明'
        }],
        ruleParamList: [{
          required: true,
          message: '请添加优惠层级'
        }],
        ruleType: [{
          required: true,
          message: '请选择折扣规则'
        }],
        ratioAll: [{
          validator: function validator(rule, value, callback) {
            if (value < 0 > 100) {
              callback(new Error('乐享值需大于0'));
            } else {
              callback();
            }
          }
        }]
      },
      ruleForm: {
        ratioAll: null,
        ruleType: null,
        contributionParamList: [{
          costContributor: 1,
          ratio: 100
        }],
        messageParam: {
          activityMonth: null,
          type: null,
          name: null,
          startTime: null,
          endTime: null,
          costContributor: [1],
          limitType: null,
          limitNumber: null,
          targetUser: null
        },
        categoryName: null,
        segmentNo: null,
        ruleParamList: []
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;
    this.ruleForm.type = this.$route.query.type || 1;
    this.ruleForm.ruleType = Number(this.$route.query.type);

    if (this.ruleForm.id) {
      (0, _api.getData)(this.ruleForm.id).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.ruleForm));
          data.costContributor = _this2.ruleForm.costContributor.join(',');
          data.ruleParamList.forEach(function (item, key) {
            item.level = key + 1;
          });

          if (_this2.ruleForm.id) {
            data.id = _this2.ruleForm.id;
            (0, _api.editData)(data).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addData)(data).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;