var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          attrs: {
            "form-data": _vm.formData,
            disabled: _vm.formType != "copy" && _vm.ruleForm.id > 0,
            "rule-form": _vm.ruleForm,
            rules: _vm.rules
          },
          on: { changeForm: _vm.changeForm, submitForm: _vm.submitForm }
        },
        [
          _c(
            "div",
            { attrs: { slot: "templateId" }, slot: "templateId" },
            [
              _vm.ruleForm.contextType
                ? _c(
                    "el-form-item",
                    {
                      ref: "templateId",
                      attrs: {
                        label: _vm.contextText[_vm.ruleForm.contextType],
                        prop: "templateId"
                      }
                    },
                    [
                      _vm.ruleForm.contextType == 3
                        ? _c("el-input", {
                            staticClass: "form_input",
                            attrs: {
                              placeholder: "请输入第三方彩信模板Id",
                              clearable: ""
                            },
                            model: {
                              value: _vm.ruleForm.templateId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "templateId",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.templateId"
                            }
                          })
                        : _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择模板"
                              },
                              on: { change: _vm.changeTem },
                              model: {
                                value: _vm.ruleForm.templateId,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "templateId", $$v)
                                },
                                expression: "ruleForm.templateId"
                              }
                            },
                            _vm._l(_vm.temList, function(row, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: row.templateName,
                                  value: row.id
                                }
                              })
                            }),
                            1
                          )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.contextType == 2
            ? _c(
                "div",
                { attrs: { slot: "mobiles" }, slot: "mobiles" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "mobiles",
                      attrs: { label: "@人员手机号码：", prop: "mobiles" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            width: "150px",
                            "margin-bottom": "10px"
                          },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.ruleForm.mobiles.push("@all")
                            }
                          }
                        },
                        [_vm._v("所有人\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            width: "150px",
                            "margin-bottom": "10px"
                          },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.ruleForm.mobiles.push("要客经理")
                            }
                          }
                        },
                        [_vm._v("\n          要客经理\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            width: "150px",
                            "margin-bottom": "10px"
                          },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.ruleForm.mobiles.push("")
                            }
                          }
                        },
                        [_vm._v("指定手机号\n        ")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.ruleForm.mobiles, function(item, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: index,
                            ref: "mobiles",
                            refInFor: true,
                            attrs: {
                              "label-width": "80px",
                              label: "手机" + (index + 1) + "：",
                              prop: "mobiles"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              model: {
                                value: _vm.ruleForm.mobiles[index],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.mobiles,
                                    index,
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.mobiles[index]"
                              }
                            }),
                            _vm._v(" "),
                            _c("el-button", {
                              staticClass: "danger",
                              attrs: { icon: "el-icon-delete", type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.ruleForm.mobiles.splice(index, 1)
                                }
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "form_remark" }, [
                        _vm._v(
                          "\n          @所有人：@all,@要客经理：要客经理，@指定人：传@人手机号码\n        "
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.contextType == 3
            ? _c(
                "div",
                { attrs: { slot: "parameters" }, slot: "parameters" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "parameters",
                      attrs: { label: "自定义参数：", prop: "parameters" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            width: "150px",
                            "margin-bottom": "10px"
                          },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.ruleForm.parameters.push("")
                            }
                          }
                        },
                        [_vm._v("添加\n        ")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.ruleForm.parameters, function(item, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: index,
                            ref: "parameters",
                            refInFor: true,
                            attrs: {
                              "label-width": "80px",
                              label: "参数" + (index + 1) + "：",
                              prop: "parameters"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择模板"
                                },
                                model: {
                                  value: _vm.ruleForm.parameters[index],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.parameters,
                                      index,
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.parameters[index]"
                                }
                              },
                              _vm._l(_vm.parametersList, function(row, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: row, value: row }
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("el-button", {
                              staticClass: "danger",
                              attrs: { icon: "el-icon-delete", type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.ruleForm.parameters.splice(
                                    index,
                                    1
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "eventContext" }, slot: "eventContext" },
            [
              _vm.ruleForm.event == 14 || _vm.ruleForm.event == 15
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "eventContext.activityIds",
                          attrs: {
                            label: "活动：",
                            prop: "eventContext.activityIds"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              attrs: {
                                clearable: "",
                                filterable: "",
                                multiple: "",
                                placeholder: "请选择活动"
                              },
                              model: {
                                value: _vm.ruleForm.eventContext.activityIds,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.eventContext,
                                    "activityIds",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.eventContext.activityIds"
                              }
                            },
                            _vm._l(_vm.activityList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "eventContext.serviceIds",
                          attrs: {
                            label: "服务：",
                            prop: "eventContext.serviceIds"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              attrs: {
                                clearable: "",
                                filterable: "",
                                multiple: "",
                                placeholder: "请选择服务"
                              },
                              model: {
                                value: _vm.ruleForm.eventContext.serviceIds,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.eventContext,
                                    "serviceIds",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.eventContext.serviceIds"
                              }
                            },
                            _vm._l(_vm.serviceList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "eventContext.placeIds",
                          attrs: {
                            label: "场地：",
                            prop: "eventContext.placeIds"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              attrs: {
                                clearable: "",
                                filterable: "",
                                multiple: "",
                                placeholder: "请选择场地"
                              },
                              model: {
                                value: _vm.ruleForm.eventContext.placeIds,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.eventContext,
                                    "placeIds",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.eventContext.placeIds"
                              }
                            },
                            _vm._l(_vm.placeList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.event == 13
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "eventContext.code",
                          attrs: {
                            label: "优惠券编码：",
                            prop: "eventContext.code"
                          }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "form_input",
                              attrs: { placeholder: "请输入优惠券编码" },
                              model: {
                                value: _vm.ruleForm.eventContext.code,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.eventContext,
                                    "code",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.eventContext.code"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-arrow-down"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.goSelectCode("coupon")
                                    }
                                  },
                                  slot: "append"
                                },
                                [_vm._v("选择优惠券")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.event == 8 || _vm.ruleForm.event == 9
                ? _c(
                    "el-form-item",
                    {
                      ref: "eventContext.id",
                      attrs: {
                        label: _vm.lavantLabel[_vm.ruleForm.event],
                        prop: "eventContext.id"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form_input",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择会员类型"
                          },
                          model: {
                            value: _vm.ruleForm.eventContext.id,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm.eventContext, "id", $$v)
                            },
                            expression: "ruleForm.eventContext.id"
                          }
                        },
                        _vm._l(_vm.lavantList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showDay.includes(_vm.ruleForm.event)
                ? _c(
                    "el-form-item",
                    {
                      ref: "eventContext.days",
                      attrs: {
                        label: _vm.dayLabel[_vm.ruleForm.event],
                        prop: "eventContext.days"
                      }
                    },
                    [
                      _c("el-input-number", {
                        staticClass: "form_input",
                        attrs: {
                          controls: false,
                          min: 0,
                          placeholder: "输入天数"
                        },
                        model: {
                          value: _vm.ruleForm.eventContext.days,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm.eventContext, "days", $$v)
                          },
                          expression: "ruleForm.eventContext.days"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showSendTime.includes(_vm.ruleForm.event)
                ? _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "eventContext.sendTime",
                          attrs: {
                            label: _vm.sendTimeLabel[_vm.ruleForm.event],
                            prop: "eventContext.sendTime"
                          }
                        },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              "value-format": "HH:mm:ss",
                              placeholder: "开始时间"
                            },
                            model: {
                              value: _vm.ruleForm.eventContext.sendTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleForm.eventContext,
                                  "sendTime",
                                  $$v
                                )
                              },
                              expression: "ruleForm.eventContext.sendTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.ruleForm.event == 1 ||
                      _vm.ruleForm.event == 6 ||
                      _vm.ruleForm.event == 9 ||
                      _vm.ruleForm.event == 8
                        ? _c(
                            "el-form-item",
                            {
                              ref: "eventContext.sendEndTime",
                              attrs: {
                                label: _vm.sendTimeLabelEnd[_vm.ruleForm.event],
                                prop: "eventContext.sendEndTime"
                              }
                            },
                            [
                              _c("el-time-picker", {
                                attrs: {
                                  "value-format": "HH:mm:ss",
                                  placeholder: "开始时间"
                                },
                                model: {
                                  value: _vm.ruleForm.eventContext.sendEndTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.eventContext,
                                      "sendEndTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.eventContext.sendEndTime"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.event == 11
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "eventContext.code",
                          attrs: {
                            label: "充值至购物卡：",
                            prop: "eventContext.code"
                          }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "form_input",
                              attrs: { placeholder: "请输入线上购物卡号" },
                              model: {
                                value: _vm.ruleForm.eventContext.code,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.eventContext,
                                    "code",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.eventContext.code"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-arrow-down"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.goSelectCode("card")
                                    }
                                  },
                                  slot: "append"
                                },
                                [_vm._v("选择购物卡")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "eventContext.amount",
                          attrs: {
                            label: "充值金额：",
                            prop: "eventContext.amount"
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_input",
                            attrs: { placeholder: "请输入充值金额" },
                            model: {
                              value: _vm.ruleForm.eventContext.amount,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleForm.eventContext,
                                  "amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.eventContext.amount"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.event == 17
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "eventContext.amount",
                          attrs: {
                            label: "单笔消费金额：",
                            prop: "eventContext.amount"
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_input",
                            attrs: { placeholder: "请输入单笔消费金额" },
                            model: {
                              value: _vm.ruleForm.eventContext.amount,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleForm.eventContext,
                                  "amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.eventContext.amount"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "eventContext.manager",
                          attrs: { label: "是否有要客经理：" }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.eventContext.manager,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.eventContext,
                                    "manager",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.eventContext.manager"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("有要客经理")
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("无要客经理")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "eventContext.code",
                          attrs: {
                            label: "所属门店：",
                            prop: "eventContext.code"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              attrs: {
                                size: "small",
                                placeholder: "所属门店",
                                filterable: "",
                                autocomplete: "off"
                              },
                              model: {
                                value: _vm.ruleForm.eventContext.code,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.eventContext,
                                    "code",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.eventContext.code"
                              }
                            },
                            _vm._l(_vm.storeList, function(item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.storeId,
                                  attrs: {
                                    label:
                                      item.shortName +
                                      "（" +
                                      item.storeCode +
                                      "）",
                                    value: item.storeCode
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        item.shortName +
                                          "（" +
                                          item.storeCode +
                                          "）"
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "labelContent",
              attrs: {
                slot: "labelContent",
                prop: "labelContent",
                label: "标签选择："
              },
              slot: "labelContent"
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    filterable: "",
                    placeholder: "输入标签名称搜索1~",
                    "value-key": "id",
                    clearable: ""
                  },
                  model: {
                    value: _vm.ruleForm.labelContent,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "labelContent", $$v)
                    },
                    expression: "ruleForm.labelContent"
                  }
                },
                _vm._l(_vm.userLabelList, function(so) {
                  return _c("el-option", {
                    key: so.id,
                    attrs: { label: "" + so.labelName, value: so.id }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: _vm.popType, "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }