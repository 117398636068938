"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon");

var _tableList = _interopRequireDefault(require("@/components/tableList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default
  },
  data: function data() {
    return {
      deaPath: '/coupon/coupon4/editList?id=',
      id: '',
      tit: '',
      operation: 2,
      total: 0,
      btn: '编辑',
      btnType: 'primary',
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [//					{
      //						tit: "ID",
      //						prop: 'id'
      //					},
      {
        tit: '列表名称',
        prop: 'name',
        width: 280
      }, {
        tit: '栏目组名称',
        prop: 'groupName',
        width: 280
      }, {
        tit: '创建时间',
        prop: 'createTime',
        sortable: true,
        width: 180,
        align: 'center'
      }, {
        tit: '修改时间',
        prop: 'operatorTime',
        sortable: true,
        width: 180,
        align: 'center'
      }, {
        tit: '模板类型',
        prop: 'templateCode',
        width: 180,
        align: 'center'
      }, {
        tit: '数据条目数',
        prop: 'attrIds',
        width: 110,
        align: 'center'
      }, {
        tit: '排序号',
        prop: 'sort',
        sortable: true,
        width: 110,
        align: 'center'
      }, {
        tit: '状态',
        prop: 'status',
        width: 110,
        align: 'center'
      }],
      list: []
    };
  },
  watch: {},
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList(e) {
      var _this = this;

      this.id = this.$route.query.id;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      (0, _coupon.listColumnItemPage)(this.pageNo, this.pageSize, this.id).then(function (res) {
        _this.list = res.data.items;
        _this.total = res.data.totalNum;

        _this.list.forEach(function (item) {
          // console.log(JSON.parse(item.attrIds).length)
          item.attrIds = JSON.parse(item.attrIds).length;

          if (item.status === 1) {
            item.status = '启用';
          } else {
            item.status = '停用';
          }
        });
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/coupon/coupon4/editList');
    },
    setBtn: function setBtn(scope) {
      console.log(scope);
      this.$router.push('/coupon/coupon4/editList?id=' + scope.columnId);
    }
  }
};
exports.default = _default;