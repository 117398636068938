var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("WForm", {
        attrs: {
          "form-data": _vm.formData,
          "label-width": "180px",
          "rule-form": _vm.ruleForm,
          rules: _vm.rules
        },
        on: { changeForm: _vm.changeForm, submitForm: _vm.submitForm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }