"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _dcb = require("@/api/dcb.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      obj: {
        pageNo: 1,
        pageSize: 10,
        className: '',
        goodsCode: '',
        goodsName: ''
      },
      total: 0,
      shopList: [{
        label: '100元专区·全省嗨购'
      }, {
        label: '50元专区·全省嗨购'
      }, {
        label: '20元专区·全省嗨购'
      }],
      list: [],
      tabTh: [{
        label: '商品名称',
        prop: 'goodsName',
        width: 200
      }, {
        label: '商品分类',
        prop: 'className',
        width: 200
      }, {
        label: '商品单价',
        prop: 'goodsPrice',
        width: 100
      }, {
        label: '库存数量',
        prop: 'goodsNum',
        width: 100
      }, {
        label: '状态',
        prop: 'goodsStatus',
        width: 100
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _dcb.listGoodsClass)().then(function (res) {
      _this.shopList = res.data;
    });
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this2 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'refresh') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
        this.obj.className = '';
        this.obj.goodsCode = '';
        this.obj.goodsName = '';
      }

      this.openLoading();
      (0, _dcb.listGoodsPage)(this.obj).then(function (res) {
        _this2.openLoading().close();

        _this2.total = res.data.totalNum;
        _this2.list = res.data.items;

        _this2.list.forEach(function (item) {
          if (item.goodsStatus == 1) {
            item.goodsStatus = '禁用';
          } else {
            item.goodsStatus = '启用';
          }
        });
      }).catch(function (err) {
        _this2.openLoading().close();
      });
    },
    goPage: function goPage(type, id) {
      if (type == 'edit') {
        this.$router.push('/dcb/goods?id=' + id);
      } else {
        this.$router.push('/dcb/goods');
      }
    }
  }
};
exports.default = _default;