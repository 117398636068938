"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/web.dom.iterable");

var _mem = require("@/api/mem.js");

var _coupon = require("@/api/coupon.js");

var _flowCoupon = require("@/api/flowCoupon.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      getRowKeys: function getRowKeys(row) {
        return row.uuid;
      },
      count: 0,
      rangeList: [],
      channeList: [{
        value: 1,
        label: '超市线下'
      }, {
        value: 2,
        label: '百货线下'
      }, {
        value: 3,
        label: '到家线上'
      }, {
        value: 4,
        label: '乐购线上（美住线上14007）'
      }, {
        value: 5,
        label: '便利店线下'
      }, {
        value: 6,
        label: '美住线下'
      }, {
        value: 7,
        label: '遵义C超市线下'
      }, {
        value: 8,
        label: '遵义星力城线下'
      }],
      options: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣券'
      }, {
        value: 3,
        label: '礼品券'
      }, {
        value: 4,
        label: '体验券'
      }, {
        value: 5,
        label: '外部优惠券'
      }],
      shortNameShow: false,
      storeList: [],
      storeArr: [],
      couponData: {
        pageNo: 1,
        pageSize: 10,
        couponType: '',
        status: 2,
        uuid: '',
        name: ''
      },
      total: 0,
      ruleForm: {
        scorePrice: '',
        rangeId: '',
        ruleName: '',
        ruleType: '',
        ruleRemark: '',
        ruleChannels: [],
        ruleItems: [{
          ruleStatus: '',
          thresholdAmount: '',
          limitPeople: '',
          limitType: '',
          limitNumber: '',
          ruleItemCycle: '',
          cycleTime: '',
          ruleItemStartTime: '',
          ruleItemEndTime: '',
          couponInfos: []
        }]
      },
      rulesItem: {
        ruleStatus: [{
          required: true,
          message: '请选择规则状态'
        }],
        thresholdAmount: [{
          required: true,
          message: '请输入返券门槛'
        }],
        limitPeople: [{
          required: true,
          message: '请输入会员返券上限'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 0) {
              callback(new Error('请输入大于等于0的值'));
            } else {
              callback();
            }
          }
        }],
        limitNumber: [{
          required: true,
          message: '请输入返券上限数量'
        }],
        limitType: [{
          required: true,
          message: '请选择返券上线类型'
        }],
        ruleItemCycle: [{
          required: true,
          message: '请选择返券周期',
          trigger: 'change'
        }],
        cycleTime: [{
          required: true,
          message: '请选择返券周期时间'
        }],
        couponInfos: [{
          required: true,
          message: '请选择投放优惠券'
        }]
      },
      rules: {
        ruleChannels: [{
          required: true,
          message: '请选择适用渠道'
        }],
        ruleType: [{
          required: true,
          message: '请选择返券方式'
        }],
        ruleItems: [{
          required: true,
          message: '请选择返券方式'
        }],
        rangeId: [{
          required: true,
          message: '请选择返券活动范围'
        }],
        ruleName: [{
          required: true,
          message: '请输入返券规则名称'
        }],
        rangeMen: [{
          required: true,
          message: '请输入返券门槛'
        }],
        rangeS: [{
          required: true,
          message: '请输入会员返券上限'
        }],
        ruleRemark: [{
          required: true,
          message: '请输入入库备注'
        }],
        scorePrice: [{
          required: true,
          message: '请选择返券活动时间'
        }]
      },
      id: ''
    };
  },
  created: function created() {
    var _this = this;

    (0, _flowCoupon.listSendCouponPage)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.rangeList = res.data.items;
    });
    this.id = this.$route.query.id;

    if (this.id) {
      this.openLoading();
      (0, _flowCoupon.getSendCouponRule)(this.id).then(function (res) {
        _this.openLoading().close();

        _this.id = res.data.id;
        _this.ruleForm.ruleName = res.data.ruleName;
        _this.ruleForm.rangeId = res.data.rangeId;
        _this.ruleForm.ruleChannels = res.data.ruleChannel ? JSON.parse(res.data.ruleChannel) : [];
        _this.ruleForm.ruleType = res.data.ruleType;
        _this.ruleForm.ruleRemark = res.data.ruleRemark;
        _this.ruleForm.scorePrice = [res.data.ruleStartTime, res.data.ruleEndTime];
        var arr = res.data.ruleItems;
        _this.ruleForm.ruleItems = [];
        arr.forEach(function (item) {
          var couponInfos = [];
          item.couponInfo.forEach(function (row) {
            var obj = {
              uuid: row.couponCode,
              name: row.couponName,
              couponType: row.couponType,
              discount: row.discount,
              sendNumber: row.sendNumber,
              denomination: row.denomination,
              validTime: row.validTime
            };
            couponInfos.push(obj); // this.$refs.shortTable.toggleRowSelection(obj);
          });
          var obj = {
            id: item.id,
            ruleStatus: item.ruleStatus,
            thresholdAmount: item.thresholdAmount,
            limitPeople: item.limitPeople,
            limitType: item.limitType,
            limitNumber: item.limitNumber,
            ruleItemCycle: item.ruleItemCycle,
            couponInfos: couponInfos
          };

          if (item.ruleItemCycle == 2) {
            obj.cycleTime = [item.ruleItemStartTime, item.ruleItemEndTime];
          }

          _this.ruleForm.ruleItems.push(obj);
        });
        console.log(_this.ruleForm.ruleItems);
      }).catch(function (err) {
        _this.openLoading().close();
      });
    }
  },
  methods: {
    typeChange: function typeChange(e) {
      if (e == 2) {
        if (this.ruleForm.ruleItems.length > 1) {
          this.ruleForm.ruleItems.splice(1);
        }
      }
    },
    deleteRule: function deleteRule(index) {
      this.ruleForm.ruleItems.splice(index, 1);
    },
    deleteCoupon: function deleteCoupon(index, index1) {
      this.ruleForm.ruleItems[index].couponInfos.splice(index1, 1);
      this.$refs.form[this.count].validateField('couponInfos');
    },
    getStore: function getStore(type) {
      var _this2 = this;

      if (type == 'search') {
        this.couponData.pageNo = 1;
      }

      (0, _coupon.listCouponPage)(this.couponData).then(function (res) {
        _this2.shortNameShow = true;
        _this2.storeList = []; // res.data.items;

        _this2.total = res.data.totalNum;
        res.data.items.forEach(function (item) {
          item.sendNumber = 1;

          _this2.storeList.push(item);
        });
      });
    },
    shortNameClick: function shortNameClick(index) {
      var _this3 = this;

      this.count = index;
      this.$refs.shortTable.clearSelection();

      if (this.storeList.length > 0) {
        var arr = Object.assign([], this.storeList);
        this.storeList = arr;
        this.$nextTick(function () {
          _this3.shortNameShow = true;
        });
      } else {
        this.getStore();
      }

      if (this.ruleForm.ruleItems[index].couponInfos.length > 0) {
        this.ruleForm.ruleItems[index].couponInfos.forEach(function (obj) {
          _this3.$refs.shortTable.toggleRowSelection(obj);
        });
      }
    },
    clearShort: function clearShort() {
      this.$refs.shortTable.clearSelection();
    },
    affirmShort: function affirmShort() {
      var arr = this.$refs.shortTable.store.states.selection;
      this.ruleForm.ruleItems[this.count].couponInfos = JSON.parse(JSON.stringify(arr));
      this.$refs.form[this.count].validateField('couponInfos');
      this.shortNameShow = false;
    },
    goAdd: function goAdd() {
      this.ruleForm.ruleItems.push({
        ruleStatus: '',
        thresholdAmount: '',
        limitPeople: '',
        limitType: '',
        limitNumber: '',
        ruleItemCycle: '',
        cycleTime: '',
        ruleItemStartTime: '',
        ruleItemEndTime: '',
        couponInfos: []
      });
    },
    submitForm: function submitForm(form) {
      var _this4 = this;

      var newArr = [];
      var isPass;
      this.$refs[form].validate(function (valid, object) {
        if (valid) {
          isPass = true;
          newArr.push(true);
        } else {
          isPass = false;

          for (var i in object) {
            var dom = _this4.$refs[i]; // this.$message.error(object[i][0].message);

            if (Object.prototype.toString.call(dom) !== '[object Object]') {
              dom = dom[0];
            }

            dom.$el.scrollIntoView({
              block: 'center',
              behavior: 'smooth'
            });
            break;
          }

          newArr.push(false);
        }
      });

      try {
        this.ruleForm.ruleItems.forEach(function (ele, index) {
          _this4.$refs['form'][index].validate(function (valid, object) {
            if (valid) {
              newArr.push(true);
            } else {
              if (isPass) {
                for (var i in object) {
                  // this.$message.error(object[i][0].message);
                  var dom = _this4.$refs[i + index];

                  if (Object.prototype.toString.call(dom) !== '[object Object]') {
                    dom = dom[0];
                  }

                  dom.$el.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                  });
                  break;
                }
              }

              newArr.push(false);
              throw '循环终止';
            }
          });
        });
      } catch (e) {
        console.log('e: ', e);
      }

      var flag = newArr.every(function (val) {
        return val == true;
      });

      if (flag) {
        var data = JSON.parse(JSON.stringify(this.ruleForm));
        data.ruleStartTime = data.scorePrice[0];
        data.ruleEndTime = data.scorePrice[1];
        var arr = [];
        this.ruleForm.ruleItems.forEach(function (item) {
          var couponInfos = [];
          item.couponInfos.forEach(function (item) {
            couponInfos.push({
              couponCode: item.uuid,
              sendNumber: item.sendNumber
            });
          });

          if (item.ruleItemCycle == 2) {
            arr.push({
              id: item.id || 0,
              ruleStatus: item.ruleStatus,
              thresholdAmount: item.thresholdAmount,
              limitPeople: item.limitPeople,
              limitType: item.limitType,
              limitNumber: item.limitNumber,
              ruleItemCycle: item.ruleItemCycle,
              ruleItemStartTime: item.cycleTime[0],
              ruleItemEndTime: item.cycleTime[1],
              couponInfos: couponInfos
            });
          } else {
            arr.push({
              id: item.id || 0,
              ruleStatus: item.ruleStatus,
              thresholdAmount: item.thresholdAmount,
              limitPeople: item.limitPeople,
              limitType: item.limitType,
              limitNumber: item.limitNumber,
              ruleItemCycle: item.ruleItemCycle,
              couponInfos: couponInfos
            });
          }
        });
        this.openLoading();

        if (this.id) {
          data.ruleItems = arr;
          (0, _flowCoupon.editSendCouponRule)(data).then(function (res) {
            _this4.openLoading().close();

            _this4.$message({
              message: '编辑成功',
              type: 'success'
            });

            _this4.$router.go(-1);
          }).catch(function (err) {
            _this4.openLoading().close();
          });
        } else {
          data.ruleItems = arr;
          (0, _flowCoupon.addSendCouponRule)(data).then(function (res) {
            _this4.openLoading().close();

            _this4.$message({
              message: '新增成功',
              type: 'success'
            });

            _this4.$router.go(-1);
          });
        }
      } else {}
    }
  }
};
exports.default = _default;