"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _template = require("@/api/template.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      temClass: [],
      obj: {
        pageNo: 1,
        pageSize: 10,
        tmplateName: '',
        classId: ''
      },
      list: [],
      tabTh: [{
        prop: 'id',
        label: '模板ID',
        width: 60
      }, {
        prop: 'tmplateName',
        label: '模板名称',
        width: 100
      }, {
        prop: 'className',
        label: '模板分类',
        width: 100
      }, {
        prop: 'modules',
        label: '组件数',
        width: 80
      }, {
        prop: 'createTime',
        label: '创建时间',
        width: 120
      }, {
        prop: 'modifyTime',
        label: '更新时间',
        width: 120
      }, {
        prop: 'tmplateStatus',
        label: '模板状态',
        width: 80
      }],
      total: 0
    };
  },
  activated: function activated() {
    this.init();
  },
  created: function created() {
    var _this = this;

    (0, _template.listTmplateClass)().then(function (res) {
      _this.temClass = res.data;
    });
  },
  methods: {
    init: function init(type) {
      var _this2 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
        this.searchTime = [];
      }

      this.openLoading();
      (0, _template.listTmplatePage)(this.obj).then(function (res) {
        _this2.openLoading().close();

        _this2.list = res.data.items;
        _this2.total = res.data.totalNum;
      }).catch(function (err) {
        _this2.openLoading().close();
      });
    },
    goAdd: function goAdd(url) {
      this.$router.push(url);
      return;

      if (id) {
        this.$router.push('/flowCoupon/rangeAdd?id=' + id);
      } else {
        this.$router.push('/flowCoupon/rangeAdd');
      }
    }
  }
};
exports.default = _default;