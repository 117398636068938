"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.array.find");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.split");

var _api = require("@/api/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _self = this;

    return {
      isPageAdd: true,
      deviceList: [],
      storeList: [],
      cfgList: [],
      cfgList2: [],
      cfgList1: [],
      blCfgList: [],
      bankCfgList: [],
      limitPayList: [{
        label: '微信',
        value: 2
      }, {
        label: '支付宝',
        value: 3
      }, {
        label: '云闪付',
        value: 6
      }, {
        label: '微信支付宝',
        value: 23
      }, {
        label: '微信云闪付',
        value: 26
      }, {
        label: '支付宝云闪付',
        value: 36
      }, {
        label: '微信支付宝云闪付',
        value: 236
      }],
      formData: [{
        type: 'divider',
        title: '基础信息'
      }, {
        label: '关联类型 ：',
        type: 'radio',
        placeholder: '请选择关联类型',
        value: 'refType',
        selectList: [{
          label: '门店',
          value: 1
        }, {
          label: '设备',
          value: 2
        }]
      }, {
        slot: 'refNo'
      }, {
        type: 'divider',
        title: '主通道配置'
      }, {
        slot: 'formList'
      }, {
        type: 'divider',
        title: '活动通道配置'
      }, {
        slot: 'hdFormList'
      },
      /* {
        type: 'divider',
        title: '补录通道配置',
      }, */
      {
        slot: 'blFormList'
      },
      /* {
        type: 'divider',
        title: '银行虚拟券配置',
      }, */
      {
        slot: 'bankFormList'
      }],
      ruleForm: {
        formList: [],
        refType: null,
        refNo: null,
        hdFormList: [],
        blFormList: [],
        bankFormList: []
      },
      rules: {
        formList: [{
          required: true,
          message: '请选择关联支付'
        }],
        refType: [{
          required: true,
          message: '请选择关联类型'
        }],
        refNo: [{
          required: true,
          message: '请输入门店或设备号'
        }],
        cfgType: [{
          required: true,
          message: '请选择通道类型'
        }],
        cfgId: [{
          required: true,
          message: '请选择支付通道'
        }],
        startTime: [{
          required: true,
          message: '请选择生效时间'
        }],
        endTime: [{
          required: true,
          message: '请选择结束时间'
        }],
        hdLimitPay: [{
          required: true,
          message: '请选择可支付方式'
        }],
        limitPay: [{
          required: true,
          message: '请选择可支付方式'
        }, {
          validator: function validator(rule, value, callback, source) {
            var limitIndex = rule.field.split('.')[1];

            if (rule.field.split('.')[0] !== 'formList') {
              callback();
            }

            var payTypeList = _self.ruleForm.formList.filter(function (item) {
              return item.cfgType === 1;
            }).map(function (item) {
              return String(item.limitPay);
            });

            var limitPayList = payTypeList.filter(function (item) {
              return item.indexOf(value) >= 0 || String(value).indexOf(item) >= 0;
            });

            if (limitPayList.length > 1 && _self.ruleForm.formList[limitIndex].cfgType == 1) {
              callback(new Error('主通道支付方式重复'));
            } else {
              callback();
            }
          }
        }]
      }
    };
  },
  computed: {
    zChannelList: function zChannelList() {
      if (this.ruleForm.refType == 2) {
        return this.cfgList1.filter(function (item) {
          return item.merchantType === 1;
        });
      } else {
        return this.cfgList1;
      }
    }
  },
  created: function created() {
    var _this = this;

    (0, _api.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
    this.apiPost('/api/org/device/pageDevice', {
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.deviceList = res.data.records;
    });
    /*
    聚合支付
    */

    this.apiPost('/api/pcc/cfg/cfgPage', {
      pageNo: 1,
      pageSize: 500,
      type: 1
    }).then(function (res) {
      _this.cfgList2 = res.data.records.filter(function (item) {
        return item.type === 2;
      });
      _this.cfgList1 = res.data.records.filter(function (item) {
        return item.type === 1;
      });
      _this.cfgList = res.data.records;
    });
    /*
    补录通道
    */

    this.apiPost('/api/pcc/cfg/cfgPage', {
      pageNo: 1,
      pageSize: 500,
      childType: 1
    }).then(function (res) {
      _this.blCfgList = res.data.records;
    });
    /*
    虚拟券支付通道
    */

    this.apiPost('/api/pcc/cfg/cfgPage', {
      pageNo: 1,
      pageSize: 500,
      childType: 2
    }).then(function (res) {
      _this.bankCfgList = res.data.records;
    });
    this.ruleForm.refNo = this.$route.query.refNo || null;

    if (this.ruleForm.refNo) {
      this.apiPost('/api/pcc/cfg/ref/pageCfgRef', {
        pageNo: 1,
        pageSize: 100,
        refNo: this.ruleForm.refNo
      }).then(function (res) {
        _this.isPageAdd = false;
        _this.ruleForm.refType = res.data.records[0].refType;
        var formList = [];
        var hdFormList = [];
        var blFormList = [];
        var bankFormList = [];
        res.data.records.forEach(function (item) {
          if (item.cfgType === 1) {
            formList.push(item);
          } else if (item.cfgType === 2) {
            hdFormList.push(item);
          } else if (item.cfgType === 3) {
            blFormList.push(item);
          } else if (item.cfgType === 4) {
            bankFormList.push(item);
          }
        });

        var mapItem = function mapItem(item) {
          return {
            cfgId: item.cfgId,
            cfgName: item.cfgName,
            cfgType: item.cfgType,
            endTime: item.endTime,
            limitPay: item.limitPay,
            id: item.id,
            startTime: item.startTime
          };
        };

        _this.ruleForm.bankFormList = bankFormList.map(mapItem);
        _this.ruleForm.blFormList = blFormList.map(mapItem);
        _this.ruleForm.formList = formList.map(mapItem);
        _this.ruleForm.hdFormList = hdFormList.map(mapItem);
      });
      /* return
                  this.apiGet('/api/pcc/cfg/ref/getCfgRefById/' + this.ruleForm.id).then((res) => {
                    delete res.data.createTime
                    this.ruleForm = res.data
                  }) */
    }
  },
  methods: {
    changeRefNo: function changeRefNo(e) {
      var _this2 = this;

      if (this.isPageAdd) {
        this.apiPost('/api/pcc/cfg/ref/pageCfgRef', {
          pageNo: 1,
          pageSize: 100,
          refNo: e
        }).then(function (res) {
          _this2.ruleForm.formList = res.data.records.filter(function (item) {
            return item.cfgType === 1;
          });
        });
      }
    },
    changeForm: function changeForm(e, prop) {
      if (prop === 'refType') {
        this.ruleForm.refNo = '';
      }
    },

    /*
        滚动到错误位置
        */
    goScrollIntoView: function goScrollIntoView(prop) {
      var dom = this.$refs[prop];
      dom.$el.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });
    },
    delGrade: function delGrade(row, index, prop) {
      var _this3 = this;

      if (row.id) {
        this.apiPost('/api/pcc/cfg/ref/removeCfgRefByIds', [row.id]).then(function (res) {
          _this3.ruleForm[prop].splice(index, 1);
        });
      } else {
        this.ruleForm[prop].splice(index, 1);
      }
    },
    setpickerTime: function setpickerTime(e) {
      return {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(e).getTime();
          return time.getTime() < validBeginTime;
        }
      };
    },
    goAddGrade: function goAddGrade(prop) {
      var cfgTypeData = {
        formList: 1,
        hdFormList: 2,
        blFormList: 3,
        bankFormList: 4
      };
      this.ruleForm[prop].push({
        cfgType: cfgTypeData[prop],
        cfgName: null,
        startTime: '2023-08-23 00:00:00',
        endTime: '2029-12-31 00:00:00',
        limitPay: null
      });
    },
    channekChange: function channekChange(e, index, prop) {
      var changData = this.cfgList.find(function (item) {
        return item.id === e;
      });
      this.ruleForm[prop][index].cfgName = changData.chanelName;
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      console.log(this.ruleForm);
      var formList = this.ruleForm.formList.map(function (item) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
          refNo: _this4.ruleForm.refNo,
          refType: _this4.ruleForm.refType
        });
      });
      var blFormList = this.ruleForm.blFormList.map(function (item) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
          refNo: _this4.ruleForm.refNo,
          refType: _this4.ruleForm.refType
        });
      });
      var hdFormList = this.ruleForm.hdFormList.map(function (item) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
          refNo: _this4.ruleForm.refNo,
          refType: _this4.ruleForm.refType
        });
      });
      var bankFormList = this.ruleForm.bankFormList.map(function (item) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
          refNo: _this4.ruleForm.refNo,
          refType: _this4.ruleForm.refType
        });
      });
      var requestData = [].concat((0, _toConsumableArray2.default)(formList), (0, _toConsumableArray2.default)(blFormList), (0, _toConsumableArray2.default)(hdFormList), (0, _toConsumableArray2.default)(bankFormList));
      this.apiPost('/api/pcc/cfg/ref/saveOrUpdateBatchRef', requestData).then(function (res) {
        _this4.$router.go(-1);

        _this4.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;