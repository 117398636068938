var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new2" }, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("编辑会员体系")]),
    _vm._v(" "),
    _c("div", { staticClass: "new2_top" }, [
      _c("ul", [
        _c("li", [
          _c("span", { staticClass: "list_tit" }, [_vm._v("体系ID：")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.tabData.cardId))])
        ]),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("span", { staticClass: "list_tit" }, [_vm._v("体系名称：")]),
            _vm._v(" "),
            _c("el-input", {
              attrs: { placeholder: "请输入" },
              model: {
                value: _vm.tabData.sysName,
                callback: function($$v) {
                  _vm.$set(_vm.tabData, "sysName", $$v)
                },
                expression: "tabData.sysName"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("span", { staticClass: "list_tit" }, [_vm._v("会员卡名称：")]),
            _vm._v(" "),
            _c("el-input", {
              attrs: { placeholder: "请输入" },
              model: {
                value: _vm.tabData.name,
                callback: function($$v) {
                  _vm.$set(_vm.tabData, "name", $$v)
                },
                expression: "tabData.name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("span", { staticClass: "list_tit" }, [_vm._v("排序号：")]),
            _vm._v(" "),
            _c("el-input", {
              attrs: { placeholder: "请输入" },
              model: {
                value: _vm.tabData.sort,
                callback: function($$v) {
                  _vm.$set(_vm.tabData, "sort", $$v)
                },
                expression: "tabData.sort"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("span", { staticClass: "list_tit" }, [_vm._v("体系状态：")]),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: 1 },
                model: {
                  value: _vm.tabData.status,
                  callback: function($$v) {
                    _vm.$set(_vm.tabData, "status", $$v)
                  },
                  expression: "tabData.status"
                }
              },
              [_vm._v("启用中")]
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: 2 },
                model: {
                  value: _vm.tabData.status,
                  callback: function($$v) {
                    _vm.$set(_vm.tabData, "status", $$v)
                  },
                  expression: "tabData.status"
                }
              },
              [_vm._v("未启用")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "new2_top_logo" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("add-load", {
            attrs: { width: 120, height: 120, cover: _vm.tabData.logoImg },
            on: { setCover: _vm.setCover }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "new2_tab" },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            model: {
              value: _vm.activeNames,
              callback: function($$v) {
                _vm.activeNames = $$v
              },
              expression: "activeNames"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "关联机构", name: "first" } },
              [
                _c("el-button", [_vm._v("修改关联机构")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "max-height": "500px", overflow: "auto" } },
                  [
                    _c("el-tree", {
                      ref: "tree",
                      attrs: {
                        data: _vm.allTreeNodes,
                        "show-checkbox": "",
                        "node-key": "code",
                        "default-checked-keys": JSON.parse(
                          JSON.stringify(_vm.checkedArr)
                        ),
                        props: _vm.defaultProps
                      },
                      on: { check: _vm.checkChange }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              { attrs: { label: "成长体系", name: "second" } },
              [
                _c(
                  "div",
                  { staticClass: "tab_cz_table" },
                  [
                    _c("div", { staticClass: "tab_cz_top" }, [
                      _c(
                        "div",
                        { staticClass: "top_list" },
                        [
                          _c("span", { staticClass: "list_tit" }, [
                            _vm._v(
                              "\n                成长数值名称：\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.tabData.growName,
                              callback: function($$v) {
                                _vm.$set(_vm.tabData, "growName", $$v)
                              },
                              expression: "tabData.growName"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "list_tit" }, [
                            _vm._v(
                              "\n                例如：乐享值、星享值、星指数 ...\n              "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "top_list" },
                        [
                          _c("span", { staticClass: "list_tit" }, [
                            _vm._v(
                              "\n                是否周期评定：\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.tabData.evaluateCycle,
                                callback: function($$v) {
                                  _vm.$set(_vm.tabData, "evaluateCycle", $$v)
                                },
                                expression: "tabData.evaluateCycle"
                              }
                            },
                            [_vm._v("每1个月")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 2 },
                              model: {
                                value: _vm.tabData.evaluateCycle,
                                callback: function($$v) {
                                  _vm.$set(_vm.tabData, "evaluateCycle", $$v)
                                },
                                expression: "tabData.evaluateCycle"
                              }
                            },
                            [_vm._v("每3个月")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 3 },
                              model: {
                                value: _vm.tabData.evaluateCycle,
                                callback: function($$v) {
                                  _vm.$set(_vm.tabData, "evaluateCycle", $$v)
                                },
                                expression: "tabData.evaluateCycle"
                              }
                            },
                            [_vm._v("每6个月")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 4 },
                              model: {
                                value: _vm.tabData.evaluateCycle,
                                callback: function($$v) {
                                  _vm.$set(_vm.tabData, "evaluateCycle", $$v)
                                },
                                expression: "tabData.evaluateCycle"
                              }
                            },
                            [_vm._v("每12个月")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 5 },
                              model: {
                                value: _vm.tabData.evaluateCycle,
                                callback: function($$v) {
                                  _vm.$set(_vm.tabData, "evaluateCycle", $$v)
                                },
                                expression: "tabData.evaluateCycle"
                              }
                            },
                            [_vm._v("不设周期")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "top_list" }, [
                        _c("p", { staticClass: "annotation" }, [
                          _c("i", { staticClass: "el-icon-warning" }),
                          _vm._v(
                            "周期评定（每3个月）即：1月1日0:00、4月1日0:00、7月1日0:00、10月1日0:00"
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("el-button", { on: { click: _vm.addLevels } }, [
                      _vm._v("添加等级")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.cardMemberLevelVos,
                          border: "",
                          fit: "",
                          "highlight-current-row": ""
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            "min-width": "100",
                            align: "center",
                            label: "等级名称"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: scope.row.name,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "name", $$v)
                                      },
                                      expression: "scope.row.name"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            "min-width": "150",
                            align: "center",
                            label: "会员卡图案"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.getIndex(scope)
                                        }
                                      }
                                    },
                                    [
                                      _c("add-load", {
                                        attrs: {
                                          width: 133,
                                          height: 88,
                                          cover: scope.row.image
                                        },
                                        on: { setCover: _vm.setgradeCover }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            "min-width": "150",
                            align: "center",
                            label: "积分数量"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "div_score" },
                                    [
                                      _c("el-input", {
                                        staticClass: "jf_input",
                                        attrs: {
                                          type: "number",
                                          placeholder: "请输入"
                                        },
                                        model: {
                                          value: scope.row.score,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "score", $$v)
                                          },
                                          expression: "scope.row.score"
                                        }
                                      }),
                                      _vm._v(
                                        "\n                  分\n                "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "id",
                            label: "会员权益",
                            "min-width": "300"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", { staticClass: "haveImg" }, [
                                    _c("div", {
                                      staticClass: "img",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          scope.row.equitiesDesc
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "btn" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.addQy(scope)
                                              }
                                            }
                                          },
                                          [_vm._v("编辑")]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              { attrs: { label: "会员规则", name: "third" } },
              [
                _c("Tinymce", {
                  ref: "editor",
                  attrs: { width: 1000, height: 500 },
                  on: { onChange: _vm.setGz },
                  model: {
                    value: _vm.tabData.memberRule,
                    callback: function($$v) {
                      _vm.$set(_vm.tabData, "memberRule", $$v)
                    },
                    expression: "tabData.memberRule"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "has",
                rawName: "v-has",
                value: "cardMemberLevel:delete",
                expression: "'cardMemberLevel:delete'"
              },
              {
                name: "loading",
                rawName: "v-loading.fullscreen.lock",
                value: _vm.fullscreenLoading,
                expression: "fullscreenLoading",
                modifiers: { fullscreen: true, lock: true }
              }
            ],
            attrs: {
              type: "primary",
              "element-loading-text": "拼命加载中",
              "element-loading-spinner": "el-icon-loading"
            },
            on: { click: _vm.saveMember }
          },
          [_vm._v("保存会员体系")]
        ),
        _vm._v(" "),
        _vm.popShow
          ? _c("div", { staticClass: "pop_box" }, [
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c("Tinymce", {
                    ref: "editorEquitiesDesc",
                    attrs: { width: 600, height: 300 },
                    model: {
                      value: _vm.equitiesDesc,
                      callback: function($$v) {
                        _vm.equitiesDesc = $$v
                      },
                      expression: "equitiesDesc"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box_btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.closeShow }
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.setEquitiesDesc()
                            }
                          }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "list_tit" }, [_vm._v("会员LOGO：")]),
      _vm._v(" "),
      _c("p", { staticClass: "txt" }, [_vm._v("120x120")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }