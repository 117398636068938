"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/**
 * 游戏管理
 */
var gamesRouter = {
  path: '/games',
  component: _layout.default,
  redirect: '/games/list',
  name: 'Games',
  meta: {
    title: '游戏管理 '
  },
  children: [{
    path: '/games/list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/games/list'));
      });
    },
    name: 'GamesList',
    meta: {
      title: '游戏列表',
      keepAlive: true,
      pageList: 'items',
      pageTotal: 'totalNum',
      addUrl: '/games/settings',
      addTxt: '添加游戏'
    }
  }, {
    path: '/games/settings',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/games/settings'));
      });
    },
    name: 'GamesSetting',
    meta: {
      title: '游戏配置 ',
      activeMenu: '/games/list'
    }
  }, {
    path: '/games/rewardSetting',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/games/rewardSetting'));
      });
    },
    name: 'GamesRewardSetting',
    meta: {
      title: '奖励配置 ',
      activeMenu: '/games/list'
    }
  }, {
    path: '/games/rewardRecords',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/games/rewardRecords'));
      });
    },
    name: 'GamesRewardRecords',
    meta: {
      title: '奖励记录',
      activeMenu: '/games/list',
      keepAlive: true,
      pageList: 'items',
      pageTotal: "totalNum"
    }
  }, {
    path: '/games/raffle',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/games/raffle'));
      });
    },
    name: 'GamesRaffle',
    meta: {
      title: '抽奖管理',
      keepAlive: true,
      pageList: 'items',
      pageTotal: 'totalNum',
      addUrl: '/games/raffle/add',
      addTxt: '新增抽奖',
      activeMenu: '/games/list'
    }
  }, {
    path: '/games/raffle/add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/games/raffle/add'));
      });
    },
    name: 'GamesRaffleAdd',
    meta: {
      title: '新增抽奖',
      activeMenu: '/games/list'
    }
  }, {
    path: '/games/statistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/games/statistics'));
      });
    },
    name: 'GamesStatistics',
    meta: {
      title: '数据统计',
      activeMenu: '/games/list'
    }
  }]
};
var _default = gamesRouter;
exports.default = _default;