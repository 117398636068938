var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.data.moduleAttr.img
        ? _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.imgSrc + _vm.data.moduleAttr.img }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "tem_list flex_between   ",
          attrs: {
            "force-fallback": true,
            move: _vm.onMove,
            list: _vm.data.moduleAttr.dataItem,
            handle: ".dargBtn",
            animation: 1000,
            filter: ".undraggable"
          }
        },
        _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
          return _c(
            "div",
            { key: index, staticClass: "tem_list_item dargBtn" },
            [
              _c("img", {
                staticStyle: { width: "100%", height: "170px" },
                attrs: { src: item.pic_url }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "couponGoods_two_info" }, [
                _c(
                  "div",
                  { staticClass: "couponGoods_two_name text_ellipsis" },
                  [
                    _vm._v(
                      "\n          " + _vm._s(item.name || "") + "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "couponGoods_item_original" }, [
                  _vm._v(
                    "￥" + _vm._s(item.original_price || "") + "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "couponGoods_two_price" }, [
                  _vm._v(" 售价：￥" + _vm._s(item.price || "") + "\n        ")
                ]),
                _vm._v(" "),
                item.discount_rate > 0 && item.discount_rate < 10
                  ? _c(
                      "div",
                      {
                        staticClass: "couponGoods_item_vipPrice flex",
                        staticStyle: { "margin-top": "10upx" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "couponGoods_item_vipPrice_label" },
                          [_vm._v(" 365会员 ")]
                        ),
                        _vm._v(
                          " 享" +
                            _vm._s(item.discount_rate) +
                            "折优惠\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }