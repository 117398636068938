var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 好友助力列表 "),
        _c(
          "div",
          [
            _vm.setMenuPerms("couponBag:add")
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "max-width": "100%" },
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-circle-plus-outline"
                    },
                    on: {
                      click: function($event) {
                        return _vm.wPage("/activity/help/add")
                      }
                    }
                  },
                  [_vm._v("新增好友助力")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "好友助力名称" },
                model: {
                  value: _vm.requestData.activityName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "activityName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.activityName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "好友助力编码" },
                model: {
                  value: _vm.requestData.activityCode,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "activityCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.activityCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "search_input",
                  attrs: {
                    multiple: "",
                    size: "mini",
                    clearable: "",
                    placeholder: "好友助力状态"
                  },
                  model: {
                    value: _vm.requestData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "status", $$v)
                    },
                    expression: "requestData.status"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "待审核", value: 0 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已撤回", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "待启动", value: 2 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "进行中", value: 3 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已结束", value: 4 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已终止", value: 5 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-date-picker", {
                staticClass: "search_input",
                attrs: {
                  size: "mini",
                  type: "datetimerange",
                  "range-separator": "—",
                  format: "yyyy年MM月dd日 HH:mm:ss",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "start-placeholder": "开始日期",
                  "default-time": ["00:00:00", "23:59:59"],
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.getList("search")
                }
              }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "mini", icon: "el-icon-refresh" },
              on: {
                click: function($event) {
                  return _vm.getList("remove")
                }
              }
            },
            [_vm._v("重置")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "time",
              label: "周期",
              align: "center",
              width: "260"
            },
            slot: "time",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.activityStartTime) +
                        "-" +
                        _vm._s(scope.row.activityEndTime) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              "min-width": "80"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 0
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("待审核")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 1
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("已撤回")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 2
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("待启动")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 3
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("进行中")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 4
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("已结束")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 5
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("已终止")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "labelContent",
              label: "助力人标签",
              align: "center",
              "mni-width": "100"
            },
            slot: "labelContent",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getUserLabel(scope.row.labelContent)) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "objectLabelContent",
              label: "发起人标签",
              align: "center",
              "mni-width": "100"
            },
            slot: "objectLabelContent",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getUserLabel(scope.row.objectLabelContent)) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              fixed: "right",
              width: "300"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/activity/help/add?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("编辑 ")]
                    ),
                    _vm._v(" "),
                    [0, 1, 2, 3, 4, 5].indexOf(scope.row.status) >= 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/activity/help/orderDea?id=" +
                                    scope.row.activityCode
                                )
                              }
                            }
                          },
                          [_vm._v("邀请记录")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 0
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认通过审核？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goAudit(scope, 2)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "warning",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("审核")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 0
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认撤回审核？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goAudit(scope, 1)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("撤回")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    [3, 2].indexOf(scope.row.status) >= 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", plain: "", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.getQrcode(scope.row)
                              }
                            }
                          },
                          [_vm._v("二维码")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    [2].indexOf(scope.row.status) >= 0
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认终止该优惠好友助力？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goAudit(scope, 5)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("终止")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    [1].indexOf(scope.row.status) >= 0 &&
                    _vm.setMenuPerms("couponBag:delete")
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认删除该优惠好友助力？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goDel(scope)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              "qr-code": _vm.qrCode,
              url: _vm.url,
              "coupon-u-uid": _vm.couponUUid
            },
            on: { closePop: _vm.closePop }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }