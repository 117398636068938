var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            "search-data": _vm.searchData,
            mobile: ["contactNumber", "mobile"],
            isSowRed: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              align: "center",
              width: "200"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.getUserEquity(scope.row, 1)
                          }
                        }
                      },
                      [_vm._v("查看用户权益")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "success",
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.getUserEquity(scope.row, 2)
                          }
                        }
                      },
                      [_vm._v("刷新用户权益")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            "modal-append-to-body": false,
            size: "60%",
            title: "用户可领取权益列表",
            visible: _vm.isEquityPop
          },
          on: {
            "update:visible": function($event) {
              _vm.isEquityPop = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "20px" } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    size: "small",
                    data: _vm.equityList,
                    "row-key": "id",
                    height: "600",
                    border: ""
                  }
                },
                [
                  _vm._l(_vm.equityTab, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        align: "center",
                        "min-width": item.width
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否领取权益",
                      align: "center",
                      width: "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                class:
                                  scope.row.recived === 1 ? "success" : "danger"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      scope.row.recived === 1
                                        ? "已领取"
                                        : "未领取"
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否确认需求",
                      align: "center",
                      width: "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                class:
                                  scope.row.delegatedReceive === 1
                                    ? "success"
                                    : "danger"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      scope.row.delegatedReceive === 1
                                        ? "已确认"
                                        : "未确认"
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.getEquityGoods(scope.row)
                                  }
                                }
                              },
                              [_vm._v("查看权益")]
                            ),
                            _vm._v(" "),
                            (scope.row.recived === 0 || !scope.row.giftName) &&
                            _vm.setMenuPerms("memberGrade:del")
                              ? _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "确认刷新权益,刷新后可重新推送？"
                                    },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.goExamBox(scope.row, 2)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "danger",
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                          size: "mini"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("刷新权益 ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }