"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _template = require("@/api/template.js");

var _user = require("@/api/user.js");

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      url: '',
      couponUUid: '',
      qrCode: '',
      popShow: false,
      obj: {
        pageNo: 1,
        pageSize: 10,
        pageName: ''
      },
      list: [],
      tabTh: [{
        prop: 'pageCode',
        label: '页面编码',
        width: 100
      }, {
        prop: 'pageName',
        label: '页面名称',
        width: 100
      }, {
        prop: 'tmplateName',
        label: '当前页面模板',
        width: 140
      }, {
        prop: 'createTime',
        label: '创建时间',
        width: 80
      }, {
        prop: 'modifyTime',
        label: '更新时间',
        width: 80
      }],
      total: 0
    };
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    goLegouFx: function goLegouFx(row) {
      var _this = this;

      this.$prompt('请输入分销人员乐购ID', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '分销人ID只能为数字'
      }).then(function (_ref) {
        var value = _ref.value;

        _this.getQrcode(row, value);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getQrcode: function getQrcode(scope, leGouCode) {
      var _this2 = this;

      // 获取小程序码
      this.couponUUid = leGouCode ? "".concat(scope.pageName, "(\u4E50\u8D2D\u5206\u9500\u4EBAID\u4E3A").concat(leGouCode, ")") : scope.pageName;
      var scene = leGouCode ? "id=".concat(scope.pageCode, "&lg=").concat(leGouCode) : scope.pageCode;
      (0, _user.couponQRCode)({
        path: 'pages/tem/tem',
        scene: scene
      }).then(function (res) {
        _this2.url = 'pages/tem/tem?id=' + scope.pageCode;
        _this2.qrCode = res.data;
        _this2.popShow = true;
      });
    },
    init: function init(type) {
      var _this3 = this;

      if (type === 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type === 'remove') {
        this.removeObj(this.obj);
        this.searchTime = [];
      }

      this.openLoading();
      (0, _template.listPage)(this.obj).then(function (res) {
        _this3.openLoading().close();

        _this3.list = res.data.items;
        _this3.total = res.data.totalNum;
      }).catch(function () {
        _this3.openLoading().close();
      });
    },
    goAdd: function goAdd(url) {
      this.$router.push(url);
    }
  }
};
exports.default = _default;