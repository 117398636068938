var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        {
          staticClass: "row_div",
          staticStyle: { padding: "0 0 20px 0" },
          attrs: { gutter: 5, type: "flex", justify: "space-between" }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "商品编码" },
                    model: {
                      value: _vm.obj.goodsCode,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "goodsCode", $$v)
                      },
                      expression: "obj.goodsCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "基础策略编码" },
                    model: {
                      value: _vm.obj.basicCode,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "basicCode", $$v)
                      },
                      expression: "obj.basicCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "90%" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "所属总部",
                        filterable: ""
                      },
                      model: {
                        value: _vm.obj.companyCode,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "companyCode", $$v)
                        },
                        expression: "obj.companyCode"
                      }
                    },
                    _vm._l(_vm.companyList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.companyCode,
                          attrs: {
                            label: item.shortName,
                            value: item.companyCode
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.shortName) +
                              "（" +
                              _vm._s(item.companyCode) +
                              "）\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "分类编码" },
                    model: {
                      value: _vm.obj.classCode,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "classCode", $$v)
                      },
                      expression: "obj.classCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "品牌" },
                    model: {
                      value: _vm.obj.brandCode,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "brandCode", $$v)
                      },
                      expression: "obj.brandCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.init("search")
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "info",
                    size: "mini",
                    icon: "el-icon-refresh"
                  },
                  on: {
                    click: function($event) {
                      return _vm.init("remove")
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                icon: "el-icon-download",
                size: "mini",
                loading: _vm.downloadLoading
              },
              on: { click: _vm.handleDownload }
            },
            [_vm._v("导出Excel")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            data: _vm.list,
            border: "",
            "header-cell-class-name": "table_header_cell"
          }
        },
        [
          _c("el-table-column", {
            attrs: { width: "70", align: "center", label: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          (_vm.obj.pageNo - 1) * _vm.obj.pageSize +
                            scope.$index +
                            1
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { "min-width": "100", align: "center", label: "公司" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.companyName) +
                        "（" +
                        _vm._s(scope.row.companyCode) +
                        "）\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { "min-width": "80", align: "center", label: "门店" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.storeName
                      ? _c("span", [
                          _vm._v(
                            _vm._s(scope.row.storeName) +
                              "（" +
                              _vm._s(scope.row.storeCode) +
                              "）"
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.tabTh, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: item.prop,
                label: item.label,
                align: "center",
                "min-width": item.width
              }
            })
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { "min-width": "50", align: "center", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        style: {
                          color:
                            scope.row.validStatus == 1 ? "#67C23A" : "#F56C6C"
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.validStatus == 1 ? "启用" : "停用"
                            ) +
                            "\n\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.obj.pageNo,
          limit: _vm.obj.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.obj, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.obj, "pageSize", $event)
          },
          pagination: _vm.init
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }