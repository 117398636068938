var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "支付二维码",
        visible: _vm.show,
        width: "500px",
        center: ""
      },
      on: {
        "update:visible": function($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog
      }
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "content_title" }, [
          _vm._v("* 请将二维码截图发送给客户进行支付")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_countDown" }, [
          _vm._v("剩余支付时间：" + _vm._s(_vm.count))
        ]),
        _vm._v(" "),
        _vm.payQrcodeUrl
          ? _c(
              "div",
              { staticClass: "content_payQrcode" },
              [
                _c("el-image", {
                  staticClass: "content_payQrcode_img",
                  attrs: { lazy: "", src: _vm.payQrcodeUrl }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }