"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.find");

var _finance = require("@/api/finance.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  props: {
    peopleTel: String
  },
  data: function data() {
    return {
      total: 0,
      list: [],
      requestParams: {
        pageNo: 1,
        pageSize: 10,
        sid: '',
        fpdm: '',
        fphm: '',
        betweenTime: '',
        peopleTel: ''
      },
      tabTh: [{
        tit: '订单号/流水号',
        prop: 'sid',
        width: 160
      }, {
        tit: '类型',
        prop: 'type',
        width: 120,
        formatter: this.tableFormatter
      }, {
        tit: '发票代码',
        prop: 'fpdm',
        width: 120
      }, {
        tit: '发票号码',
        prop: 'fphm',
        width: 120
      }, {
        tit: '开票时间',
        prop: 'createTime',
        width: 180
      }, {
        tit: '开票用户',
        prop: 'name',
        width: 180
      }, {
        tit: '用户手机号',
        prop: 'peopleTel',
        width: 120
      }, {
        tit: '审核人',
        prop: 'auditUser',
        width: 120
      }, {
        tit: '开票状态',
        prop: 'specialInvoiceStatus',
        width: 100,
        formatter: this.tableFormatter
      }, {
        tit: '票据类型',
        prop: 'statusTxt',
        width: 100
      }],
      typeOptions: [{
        val: 1,
        lab: '企业'
      }, {
        val: 3,
        lab: '非企业型组织'
      }, {
        val: 2,
        lab: '个人'
      }],
      specialInvoiceStatusOptions: [{
        val: -1,
        lab: '开票审核不通过'
      }, {
        val: 0,
        lab: '专票：开票中 普票：待审'
      }, {
        val: 1,
        lab: '成功'
      }, {
        val: 2,
        lab: '开票失败'
      }]
    };
  },
  watch: {
    peopleTel: function peopleTel(val) {
      if (val) {
        this.getList();
      }
    }
  },
  methods: {
    // 列表格式化
    tableFormatter: function tableFormatter(row, _ref) {
      var property = _ref.property;
      var options = [];
      var val = '';

      if (property == 'type') {
        options = this.typeOptions;
        val = row.type;
      }

      if (property == 'specialInvoiceStatus') {
        options = this.specialInvoiceStatusOptions;
        val = row.specialInvoiceStatus;
      }

      if (!val || options.length == 0) return '';
      return options.find(function (item) {
        return item.val == val;
      }).lab;
    },
    handleReset: function handleReset() {
      this.requestParams.betweenTime = []; // this.requestParams.peopleTel = ''

      this.requestParams.fpdm = '';
      this.requestParams.sid = '';
      this.requestParams.fphm = '';
      this.requestParams.pageNo = 1;
      this.requestParams.pageSize = 10;
      this.getList();
    },
    handleQuery: function handleQuery() {
      this.requestParams.pageNo = 1;
      this.requestParams.pageSize = 10;
      this.getList();
    },
    pageChange: function pageChange(_ref2) {
      var page = _ref2.page,
          limit = _ref2.limit;
      this.requestParams.pageNo = page;
      this.requestParams.pageSize = limit;
    },
    getList: function getList() {
      var _this = this;

      var data = {
        pageNo: this.requestParams.pageNo,
        pageSize: this.requestParams.pageSize,
        sid: this.requestParams.sid.trim(),
        fpdm: this.requestParams.fpdm.trim(),
        fphm: this.requestParams.fphm.trim(),
        startTime: this.requestParams.betweenTime[0] || '',
        endTime: this.requestParams.betweenTime[1] || '',
        specialInvoiceStatus: 1,
        peopleTel: this.peopleTel
      };
      (0, _finance.listInvoice)(data).then(function (res) {
        var list = res.data.items;

        if (list.length > 0) {
          list.forEach(function (item) {
            if (item.status % 2 == 1) {
              item.statusTxt = '蓝票';
            } else if (item.status % 2 == 0) {
              item.statusTxt = '红票';
            }
          });
        }

        _this.total = res.data.totalNum || 0;
        _this.list = list;
      });
    }
  }
};
exports.default = _default;