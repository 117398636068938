var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增/编辑大屏活动 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { ref: "title", attrs: { label: "活动标题：", prop: "title" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                model: {
                  value: _vm.ruleForm.title,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "shortTitle",
              attrs: { label: "活动短标题：", prop: "shortTitle" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                model: {
                  value: _vm.ruleForm.shortTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "shortTitle", $$v)
                  },
                  expression: "ruleForm.shortTitle"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "couponList", attrs: { label: "关联券" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.getAward()
                    }
                  }
                },
                [_vm._v("\n        关联优惠券\n      ")]
              ),
              _vm._v(" "),
              _vm.ruleForm.couponList.length > 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        border: "1px solid #CCCCCC",
                        "border-radius": "10px",
                        padding: "5px"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.ruleForm.couponList.join("、")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "storeId", attrs: { label: "门店：", prop: "storeId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { "max-width": "400px", width: "100%" },
                  attrs: {
                    multiple: "",
                    size: "small",
                    placeholder: "所属门店",
                    filterable: "",
                    autocomplete: "off"
                  },
                  model: {
                    value: _vm.ruleForm.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "storeId", $$v)
                    },
                    expression: "ruleForm.storeId"
                  }
                },
                _vm._l(_vm.storeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.storeId,
                      attrs: {
                        label: item.shortName + "（" + item.storeCode + "）",
                        value: item.storeCode
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "merchantId", attrs: { label: "商户：" } },
            [
              _c(
                "div",
                {
                  staticClass: "flex",
                  staticStyle: { "align-items": "center" }
                },
                [
                  _c(
                    "a",
                    {
                      staticStyle: {
                        "margin-right": "20px",
                        "line-height": "1"
                      },
                      attrs: {
                        href: _vm.downloadUrl,
                        download: "大屏商户.xlsx"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "success",
                            icon: "el-icon-download"
                          }
                        },
                        [_vm._v("下载模板")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("upload-excel-component", {
                    attrs: {
                      "btn-text": "批量上传",
                      type: "btn",
                      "on-success": _vm.handleSuccess,
                      "before-upload": _vm.beforeUpload
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { height: "10px" } }),
              _vm._v(" "),
              _c(
                "w-table",
                {
                  attrs: {
                    height: "500",
                    "table-data": _vm.ruleForm.merchantId,
                    columns: _vm.columns
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      slot: "operate",
                      label: "操作",
                      align: "center",
                      width: "120"
                    },
                    slot: "operate",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认删除该条商品？" },
                                on: {
                                  confirm: function($event) {
                                    return _vm.delGoods(scope.$index)
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "danger",
                                      size: "mini"
                                    },
                                    slot: "reference"
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "isIndex", attrs: { label: "是否精选：", prop: "isIndex" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.isIndex,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "isIndex", $$v)
                    },
                    expression: "ruleForm.isIndex"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form-item",
                {
                  ref: "startTime",
                  attrs: { label: "有效期：", prop: "startTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择开始时间"
                    },
                    model: {
                      value: _vm.ruleForm.startTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "startTime", $$v)
                      },
                      expression: "ruleForm.startTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "endTime",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { "label-width": "0", prop: "endTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      disabled: !_vm.ruleForm.startTime,
                      "picker-options": _vm.pickerTime,
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": "23:59:59",
                      placeholder: "选择结束时间"
                    },
                    model: {
                      value: _vm.ruleForm.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "endTime", $$v)
                      },
                      expression: "ruleForm.endTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "landscapeMainPic", attrs: { label: "大屏横屏主图：" } },
            [
              _c("add-load", {
                attrs: {
                  width: 200,
                  height: 100,
                  cover: _vm.ruleForm.landscapeMainPic
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "landscapeMainPic")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "verticalMainPic",
              attrs: { label: "大屏竖屏主图：", prop: "verticalMainPic" }
            },
            [
              _c("add-load", {
                attrs: {
                  width: 100,
                  height: 200,
                  cover: _vm.ruleForm.verticalMainPic
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "verticalMainPic")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "status", attrs: { label: "状态：", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("启用")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("禁用")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "images", attrs: { label: "图片列表：" } },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _vm._l(_vm.ruleForm.images, function(item, index) {
                    return _c("div", { key: index, staticClass: "card_img" }, [
                      _c("img", {
                        staticStyle: { width: "148px", height: "148px" },
                        attrs: { src: _vm.imgSrc + item.pictureUrl }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "card_img_remove " }, [
                        _c("i", {
                          staticClass: "el-icon-delete-solid",
                          on: {
                            click: function($event) {
                              return _vm.goDeImg(index)
                            }
                          }
                        })
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _c("add-load", {
                    attrs: { width: 148, height: 148 },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "images")
                      }
                    }
                  })
                ],
                2
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "content", attrs: { prop: "content", label: "活动内容：" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isShowSelect
        ? _c("SelectTabel", {
            ref: "selectPop",
            attrs: { type: "coupon" },
            on: {
              setCoupons: _vm.setTabelData,
              closeCouponPop: _vm.closeSelect
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }