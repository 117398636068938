"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _editor = _interopRequireDefault(require("@/components/editor"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    editor: _editor.default,
    Tinymce: _Tinymce.default
  },
  props: {
    cardMemeberEquitiesVos: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tureVos: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    equitiesDesc: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      editors: 'editors11',

      /* equitiesDescs:"<p><br></p>",*/
      imgSrc: this.$store.state.user.imgSrc,
      multipleSelection: [],
      img: [],
      levelEquities: []
    };
  },
  mounted: function mounted() {
    console.log(this.equitiesDesc);
    var tureVo = this.tureVos;
    var allVox = this.cardMemeberEquitiesVos;
    var arrT = [];

    for (var i = 0; i < allVox.length; i++) {
      for (var k = 0; k < tureVo.length; k++) {
        if (allVox[i].id === tureVo[k].id) {
          this.$refs.multipleTable.toggleRowSelection(this.cardMemeberEquitiesVos[i]);
        }
      }
    }
  },
  methods: {
    setGz: function setGz(index) {
      this.equitiesDesc = index;
    },
    submitEquitiesDescs: function submitEquitiesDescs() {
      this.$emit('setImg', this.equitiesDesc);
    },
    submitOrders: function submitOrders(type) {
      var arr = this.multipleSelection;
      this.levelEquities = [];
      this.img = [];

      for (var i = 0; i < arr.length; i++) {
        this.img.push(arr[i].haveImg);
        this.levelEquities.push(arr[i].id);
      }

      this.$emit('setImg', arr, this.img, this.levelEquities); // console.log(this.levelEquities,this.img)
    },
    toggleSelection: function toggleSelection(rows) {
      var _this = this;

      if (rows) {
        rows.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
exports.default = _default2;