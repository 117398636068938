var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "type" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(_vm.$route.meta.title) + "\n\n        "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.onDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            size: "mini",
            "header-cell-style": { background: "#f5f5f5" },
            border: ""
          }
        },
        [
          _vm._l(_vm.columns, function(col, cIndex) {
            return [
              _c("el-table-column", {
                key: cIndex,
                attrs: { label: col.label, prop: col.prop, align: "center" }
              })
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.requestParams.pageNo,
          limit: _vm.requestParams.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.requestParams, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.requestParams, "pageSize", $event)
          },
          pagination: _vm.onPageChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }