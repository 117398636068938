"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _api = require("./api.js");

var _api2 = require("@/views/equity/api.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default,
    selectAward: _radioItem.default
  },
  data: function data() {
    var _self = this;

    return {
      userLabelList: [],
      couponShow: false,
      rightsIdList: [],
      vipList: [{
        label: '所有会员',
        value: 2
      }, {
        label: '365会员',
        value: 1
      }, {
        label: '非365会员',
        value: 3
      }],
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.startTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      storeList: [],
      lavantGradeList: [],
      rules: {
        labelContent: [{
          required: true,
          message: '请选择会员标签'
        }],
        svipLevelId: [{
          required: true,
          message: '请输入LAVANT等级会员'
        }],
        'activityName': [{
          required: true,
          message: '请输入活动名称'
        }],
        'startTime': [{
          required: true,
          message: '请选择开始时间'
        }],
        'endTime': [{
          required: true,
          message: '请选择结束时间'
        }],
        status: [{
          required: true,
          message: '请选择z状态'
        }],
        shortName: [{
          required: true,
          message: '请输入简称'
        }],
        limitMemberType: [{
          required: true,
          message: '请选择会员类型'
        }],
        perMemberLimitTimes: [{
          required: true,
          message: '请输入限制人数'
        }],
        totalTimes: [{
          required: true,
          message: '请输入投放总数'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < _self.ruleForm.perMemberLimitTimes) {
              callback(new Error('投放总数需大于限制次数数'));
            } else {
              callback();
            }
          }
        }],
        amoutStage: [{
          required: true,
          message: '请输入金额'
        }],
        rightId: [{
          required: true,
          message: '请选择权益'
        }],
        description: [{
          required: true,
          message: '请输入描述'
        }],
        ruleIntroduction: [{
          required: true,
          message: '请输入规则'
        }],
        image: [{
          required: true,
          message: '请上传图片'
        }],
        giveStoreCardAmount: [{
          required: true,
          message: '请输入奖励金额'
        }],
        beSvip: [{
          required: true,
          message: '请选择是否能参与要客会员'
        }]
      },

      /**
       * 赠送充值购物卡uuid
      private String giveStoreCardUuid;
       * 赠送充值购物卡名称
      private String giveStoreCardName;
        送储值卡充值金
      private StrveStoreCardAmount;
       */
      ruleForm: {
        labelContent: null,
        svipLevelId: null,
        beSvip: null,
        giveStoreCardUuid: null,
        giveStoreCardName: null,
        giveStoreCardAmount: null,
        activityName: null,
        status: null,
        shortName: null,
        startTime: null,
        endTime: null,
        limitMemberType: null,
        perMemberLimitTimes: null,
        totalTimes: null,
        cardUuid: null,
        amoutStage: null,
        rightId: null,
        description: null,
        ruleIntroduction: null,
        image: 'mika/dev/2022-06-08/826ff9163e77423685abd44d71a31ca3.png'
      },
      cardType: null
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
      _this.userLabelList = [{
        id: 0,
        labelName: '所有会员'
      }].concat((0, _toConsumableArray2.default)(res.data));
    });
    this.apiGet('/api/svip/level').then(function (res) {
      console.log(res);
      _this.lavantGradeList = res.data;
    });
    this.ruleForm.id = this.$route.query.id;
    (0, _api2.getEquityList)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.rightsIdList = res.data.records;
    });

    if (this.ruleForm.id) {
      (0, _api.getData)({
        id: this.ruleForm.id
      }).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    getAward: function getAward(type) {
      this.cardType = type;
      this.$refs.coupon1.getList('card', this.ruleForm[type]);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      if (this.cardType === 'giveStoreCardUuid') {
        this.ruleForm.giveStoreCardUuid = e.uuid;
        this.ruleForm.giveStoreCardName = e.name;
        this.couponShow = false;
        return;
      }

      this.ruleForm.cardUuid = e.uuid;
      this.couponShow = false;
    },
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.ruleForm));
          (0, _api.addData)(data).then(function (res) {
            _this2.$router.go(-1);

            _this2.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;