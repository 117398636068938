"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applySale = applySale;
exports.batchImportLogisticNo = batchImportLogisticNo;
exports.cancelHandle = cancelHandle;
exports.deliverygood = deliverygood;
exports.manualSince = manualSince;
exports.orderDea = orderDea;
exports.queryOrder = queryOrder;
exports.saleConfirm = saleConfirm;
exports.saleHandle = saleHandle;
exports.updateOrder = updateOrder;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
/api/mall/order/applySale   申请售后
 */
function applySale(data) {
  return (0, _request.default)({
    url: '/api/mall/order/applySale',
    method: 'post',
    data: data
  });
}
/*
批量导入快递单号：/api/mall/order/batchImportLogisticNo
{"orders":[{"orderNo":"订单号","logisticNo":"物流单号","logisticName":"物流名称"}]}

 */


function batchImportLogisticNo(data) {
  return (0, _request.default)({
    url: '/api/mall/order/batchImportLogisticNo',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
1、分页查询订单(后台)：/api/mall/order/queryOrder
{"pageNo":"当前页","pageSize":"页条数"}

 */


function queryOrder(data) {
  return (0, _request.default)({
    url: '/api/mall/order/queryOrder',
    method: 'get',
    params: data
  });
}
/*
售后确认退款：/api/mall/order/saleConfirm/{orderNo}
 */


function saleConfirm(data) {
  return (0, _request.default)({
    url: '/api/mall/order/saleConfirm/' + data,
    method: 'post'
  });
}
/*

2、订单详情(后台)：/api/mall/order/{orderNo}
 */


function orderDea(data) {
  return (0, _request.default)({
    url: '/api/mall/order/' + data,
    method: 'post'
  });
}
/*
 3、订单发货(后台)：/api/mall/order/deliverygood
 {"orderNo":"订单号","logisticName":"物流名称","logisticNo":"物流单号"}
 */


function deliverygood(data) {
  return (0, _request.default)({
    url: '/api/mall/order/deliverygood',
    method: 'post',
    data: data
  });
}
/*
4、售后同意/拒绝(后台)：/api/mall/order/saleHandle
{"orderNo":"订单号","refusedReason":"拒绝原因"}
 */


function saleHandle(data) {
  return (0, _request.default)({
    url: '/api/mall/order/saleHandle',
    method: 'post',
    data: data
  });
}
/*
取消同意/拒绝：/api/mall/order/cancelHandle
{"orderNo":"订单号","cancelStatus":"取消状态(1：拒绝 2：同意)"}
 */


function cancelHandle(data) {
  return (0, _request.default)({
    url: '/api/mall/order/cancelHandle',
    method: 'post',
    data: data
  });
}

function manualSince(data) {
  return (0, _request.default)({
    url: '/api/mall/order/manualSince',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function updateOrder(data) {
  return (0, _request.default)({
    url: '/api/mall/order/updateOrder',
    method: 'post',
    loading: 'loading',
    data: data
  });
}