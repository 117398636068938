"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.regexp.split");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      couponShow: false,
      popType: null,

      /*
      门店列表
      */
      storeList: [],
      formType: null,
      showDay: [4, 5, 7, 10, 13],
      sendTimeLabel: {
        1: '不发送开始时间：',
        2: '发送开始时间：',
        3: '发送开始时间：',
        4: '发送开始时间：',
        5: '发送开始时间：',
        6: '不发送开始时间：',
        7: '发送开始时间：',
        8: '不发送开始时间：',
        9: '不发送开始时间：',
        10: '发送开始时间：'
      },
      sendTimeLabelEnd: {
        1: '不发送结束时间：',
        2: '发送结束时间：',
        3: '发送结束时间：',
        4: '发送结束时间：',
        5: '发送结束时间：',
        6: '不发送结束时间：',
        7: '发送结束时间：',
        8: '不发送结束时间：',
        9: '不发送结束时间：',
        10: '发送结束时间：'
      },
      dayLabel: {
        4: '365剩余x天：',
        5: '365过期后X天：',
        7: 'lavant剩余X天：',
        10: 'lavant过期后X天：',
        13: '剩余天数：'
      },
      lavantLabel: {
        9: 'lavant降级至：',
        8: 'lavant升级至：'
      },
      showSendTime: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      parametersList: ['会员手机号', '会员昵称', "会员类型", "充值的购物卡名称", "优惠券名称", "维护该客户的荔星伙伴", '365会员类型', '365会员有效期剩余天数', '365会员过期天数', '365会员身份失效日期', 'lavant会员类型', '升级后lavant会员类型', '降级后lavant会员类型', 'lavant会员过期后天数', 'lavant会员有效期剩余天数', 'lavant身份峰级日期', 'lavant身份过期日期', '充值购物卡金额', '优惠券失效日期', '服务名称', '场地名称', '活动名称', '要客评价荔星伙伴'],
      temList: [],
      lavantList: [],
      activityList: [],
      serviceList: [],
      placeList: [],
      userLabelList: [],
      contextText: {
        1: '短信模板：',
        2: '企业微信模板：',
        3: '彩信模板：',
        4: '自定义：'
      },
      formData: [{
        label: '任务主题：',
        type: 'input',
        placeholder: '请输入任务主题',
        value: 'taskTheme'
      }, {
        label: '信息内容：',
        type: 'radio',
        placeholder: '请选择信息内容',
        value: 'contextType',
        selectList: [{
          label: '短信模板',
          value: 1
        }, {
          label: '企微消息模板',
          value: 2
        }, {
          label: '彩信模板',
          value: 3
        }]
      }, {
        slot: 'templateId'
      }, {
        slot: 'parameters'
      }, {
        label: '执行方式：',
        type: 'radio',
        placeholder: '请选择执行方式',
        value: 'executionMethod',
        selectList: [{
          label: '审核完立即发送',
          value: 1
        }, {
          label: '指定时间',
          value: 2
        }, {
          label: '事件触发',
          value: 3
        }]
      }, {
        label: '企微群地址：',
        type: 'input',
        placeholder: '请输入企微webhook地址',
        value: 'webhook',
        showType: 'no'
      }, {
        slot: "mobiles"
      }, {
        label: '指定时间：',
        type: 'startTime',
        placeholder: '请选择指定时间',
        value: 'appointTime',
        showType: 'no'
      }, {
        label: '执行事件：',
        type: 'select',
        placeholder: '请选择执行事件',
        value: 'event',
        showType: 'no',
        selectList: [{
          label: '用户注册',
          value: 1
        }, {
          label: '会员生日当天',
          value: 2
        }, {
          label: '会员生日当月 ',
          value: 3
        }, {
          label: '365会员有效期剩余x天 ',
          value: 4
        }, {
          label: '365会员过期后x天',
          value: 5
        }, {
          label: '成功加入lavant会员',
          value: 6
        },
        /* {
           label: 'lavant有效期剩余X天',
           value: 7
         }, */
        {
          label: 'lavant会员升级',
          value: 8
        }, {
          label: 'lavant会员降级',
          value: 9
        },
        /* {
           label: 'lavant会员过期后x天',
           value: 10
         }, */
        {
          label: '充值购物卡',
          value: 11
        }, {
          label: '优惠券即将失效',
          value: 13
        }, {
          label: '服务/场地/活动预定成功',
          value: 14
        }, {
          label: '服务/场地/活动预定取消 ',
          value: 15
        }, {
          label: '要客评价荔星伙伴',
          value: 16
        }, {
          label: '顾客单笔消费满',
          value: 17
        }]
      }, {
        slot: 'eventContext'
      }, {
        slot: 'labelContent'
      }, {
        label: '频率控制：',
        type: 'inputNumber',
        placeholder: '请输入频率控制',
        value: 'dailyLimitNum',
        remark: '单个用户每天可以发送多少次',
        showType: 'no'
      }],
      ruleForm: {
        id: null,
        mobiles: [],
        taskTheme: null,
        contextType: null,
        templateId: null,
        context: null,
        parameters: [],
        executionMethod: null,
        event: null,
        appointTime: null,
        eventContext: {
          sendEndTime: null,
          sendTime: null,
          days: undefined,
          id: null,
          code: null,
          amount: undefined,
          serviceIds: [],
          placeIds: [],
          activityIds: [],
          manager: null
        },
        labelContent: null,
        dailyLimitNum: undefined,
        webhook: null
      },
      rules: {
        'eventContext.manager': [{
          required: true,
          message: '请选择'
        }],
        webhook: [{
          required: true,
          message: '请输入企微webhook地址'
        }],
        dailyLimitNum: [{
          required: true,
          message: '请输入频率'
        }],
        'eventContext.amount': [{
          required: true,
          message: '请输入金额'
        }],
        'eventContext.code': [{
          required: true,
          message: '请输入编码'
        }],
        'eventContext.id': [{
          required: true,
          message: '请选择'
        }],
        'eventContext.days': [{
          required: true,
          message: '请输入天'
        }],
        'eventContext.sendTime': [{
          required: true,
          message: '请选择时间'
        }],
        'eventContext.sendEndTime': [{
          required: true,
          message: '请选择时间'
        }],
        taskTheme: [{
          required: true,
          message: '请输入任务标题'
        }],
        contextType: [{
          required: true,
          message: '请选择信息内容'
        }],
        templateId: [{
          required: true,
          message: '请选择模板'
        }],
        parameters: [{
          required: true,
          message: '请添加自定义参数'
        }],
        executionMethod: [{
          required: true,
          message: '请选择执行方式'
        }],
        appointTime: [{
          required: true,
          message: '请选择指定时间'
        }],
        event: [{
          required: true,
          message: '请选择事件'
        }],
        labelContent: [{
          required: true,
          message: '请选择人群标签'
        }]
      }
    };
  },
  components: {
    selectAward: _radioItem.default
  },
  computed: {
    isShowTime: function isShowTime() {
      return this.ruleForm.executionMethod === 2 ? 'is' : 'no';
    }
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/store/listStore', {
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      var storeList = [{
        fullName: "所有门店",
        shortName: "所有门店",
        storeCode: '0'
      }];
      _this.storeList = [].concat(storeList, (0, _toConsumableArray2.default)(res.data));
    });
    this.apiGet('/api/svip/activity/page', {
      pageNo: 1,
      pageSize: 100,
      status: 2
    }).then(function (res) {
      var list = [{
        name: '所有活动',
        id: 0
      }];
      _this.activityList = [].concat(list, (0, _toConsumableArray2.default)(res.data.items));
    });
    this.apiGet('/api/svip/service/page', {
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      var list = [{
        name: '所有服务',
        id: 0
      }];
      _this.serviceList = [].concat(list, (0, _toConsumableArray2.default)(res.data.items));
    });
    this.apiGet('/api/svip/place/page', {
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      var list = [{
        name: '所有场地',
        id: 0
      }];
      _this.placeList = [].concat(list, (0, _toConsumableArray2.default)(res.data.items));
    });
    this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
      _this.userLabelList = (0, _toConsumableArray2.default)(res.data);
    });
    this.apiPost('/api/smsTask/listSmsTemplatePage', {
      pageNo: 1,
      pageSize: 200,
      status: 2
    }).then(function (res) {
      _this.temList = res.data.records;
    });
    this.apiGet('/api/svip/level').then(function (res) {
      var memberList = [{
        id: 0,
        name: '普通会员'
      }];
      var lavantList = res.data.map(function (item) {
        return {
          name: 'lavant' + item.name,
          id: item.id
        };
      });
      _this.lavantList = [].concat(memberList, (0, _toConsumableArray2.default)(lavantList));
    });
    this.ruleForm.id = this.$route.query.id;
    this.formType = this.$route.query.type;

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/smsTask/getSmsTaskById/' + this.ruleForm.id).then(function (res) {
        delete res.data.examTime;
        delete res.data.examUser;
        delete res.data.createTime;
        var resData = (0, _objectSpread2.default)({}, res.data);

        if (res.data.mobiles) {
          resData.mobiles = JSON.parse(res.data.mobiles);
        }

        resData.parameters = res.data.parameters.split(',');
        var eventContext = {
          sendTime: null,
          day: undefined,
          id: null,
          code: null,
          amount: undefined,
          serviceIds: [],
          placeIds: [],
          activityIds: []
        };

        if (res.data.contextType == 2) {
          var webhookIndex = _this.formData.findIndex(function (item) {
            return item.value === 'webhook';
          });

          _this.formData[webhookIndex].showType = 'is';
        }

        if (res.data.eventContext) {
          var resEventContext = JSON.parse(res.data.eventContext);

          if (res.data.event == 1 || res.data.event == 6 || res.data.event == 8 || res.data.event == 9) {
            eventContext.sendTime = resEventContext.sendTime.split(',')[0];
            eventContext.sendEndTime = resEventContext.sendTime.split(',')[1];
          } else if (_this.showSendTime.includes(res.data.event)) {
            eventContext.sendTime = resEventContext.sendTime;
          }

          if (_this.showDay.includes(res.data.event)) {
            eventContext.days = resEventContext.days;
          }

          if (res.data.event == 11 || res.data.event == 13 || res.data.event == 17) {
            eventContext.code = resEventContext.code;
          }

          if (res.data.event == 11 || res.data.event == 17) {
            eventContext.amount = resEventContext.amount;
          }

          if (res.data.event == 14 || res.data.event == 15) {
            eventContext.serviceIds = resEventContext.serviceIds;
            eventContext.placeIds = resEventContext.placeIds;
            eventContext.activityIds = resEventContext.activityIds;
          }

          if (res.data.event == 17) {
            eventContext.manager = resEventContext.manager;
          }
        }

        _this.ruleForm = resData;
        _this.ruleForm.eventContext = eventContext;
      });
    }
  },
  methods: {
    goSelectCode: function goSelectCode(e) {
      this.popType = e;
      this.$refs.coupon1.getList(e, this.ruleForm.eventContext.code);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      this.ruleForm.eventContext.code = e.uuid;
      this.couponShow = false;
    },
    changeTem: function changeTem(e) {
      var temItem = this.temList.find(function (item) {
        return item.id === e;
      });

      if (e && temItem) {
        this.ruleForm.context = temItem.content;
        this.ruleForm.parameters = temItem.parameters;
      }
    },
    changeForm: function changeForm(e, prop) {
      if (prop === 'contextType') {
        this.ruleForm.templateId = null;
        this.ruleForm.parameters = [];
        var eventIndex = this.formData.findIndex(function (item) {
          return item.value === 'dailyLimitNum';
        });
        var webhookIndex = this.formData.findIndex(function (item) {
          return item.value === 'webhook';
        });

        if (e == 3) {
          this.formData[webhookIndex].showType = 'no';
          this.formData[eventIndex].showType = 'is';
        } else {
          this.ruleForm.parameters = [];
        }

        if (e == 2) {
          this.formData[eventIndex].showType = 'no';
          this.formData[webhookIndex].showType = 'is';
        }

        if (e == 1) {
          this.formData[eventIndex].showType = 'no';
          this.formData[webhookIndex].showType = 'no';
        }

        return;
      }

      if (prop === 'event') {
        this.ruleForm.eventContext.code = null;
        this.ruleForm.eventContext.sendTime = null;
        this.ruleForm.eventContext.sendEndTime = null;

        if (e == 14 || e == 15) {}

        return;
      }

      if (prop === 'executionMethod') {
        this.ruleForm.appointTime = null;
        this.ruleForm.event = null;
        var appointTimeIndex = this.formData.findIndex(function (item) {
          return item.value === 'appointTime';
        });

        var _eventIndex = this.formData.findIndex(function (item) {
          return item.value === 'event';
        });

        if (appointTimeIndex >= 0) {
          this.formData[appointTimeIndex].showType = e === 2 ? 'is' : 'no';
          this.formData[_eventIndex].showType = e === 3 ? 'is' : 'no';
        }

        return;
      }
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var requestData = (0, _objectSpread2.default)({}, this.ruleForm);

      if (this.ruleForm.contextType == 3) {
        requestData.parameters = this.ruleForm.parameters.join(',');
      }

      if (this.ruleForm.event == 1 || this.ruleForm.event == 6 || this.ruleForm.event == 8 || this.ruleForm.event == 9) {
        requestData.eventContext.sendTime = [this.ruleForm.eventContext.sendTime, this.ruleForm.eventContext.sendEndTime].join(',');
      }
      /* if (this.ruleForm.event == 14 || this.ruleForm.event == 15) {
         requestData.eventContext.serviceIds = this.ruleForm.eventContext.serviceIds.join(',')
         requestData.eventContext.placeIds = this.ruleForm.eventContext.placeIds.join(',')
         requestData.eventContext.activityIds = this.ruleForm.eventContext.activityIds.join(',')
       } */


      this.apiPost('/api/smsTask/addSmsTask', requestData).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;