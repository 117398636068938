"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("@/api/svip/index.js");

var _Descriptions = _interopRequireDefault(require("@/components/mc/Descriptions.vue"));

var _map = require("../../const/map");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Descriptions: _Descriptions.default
  },
  data: function data() {
    return {
      manager: {},
      logList: []
    };
  },
  computed: {
    columns: function columns() {
      return [{
        title: '伙伴姓名',
        key: 'name',
        dataIndex: 'name'
      }, {
        title: '性别',
        key: 'sex',
        dataIndex: 'sex',
        valueEnum: _map.SvipSex
      }, {
        title: '工号',
        key: 'employeeNo',
        dataIndex: 'employeeNo'
      }, {
        title: '身高',
        key: 'height',
        dataIndex: 'height'
      }, {
        title: '联系电话',
        key: 'contactNumber',
        dataIndex: 'contactNumber'
      }, {
        title: '展示图片',
        key: 'avatar',
        dataIndex: 'avatar',
        valueType: 'image',
        width: '300px',
        height: '300px'
      }, {
        title: '简介',
        key: 'introduction',
        dataIndex: 'introduction'
      }];
    }
  },
  created: function created() {
    var _this = this;

    var id = this.$route.params && this.$route.params.id;

    if (id) {
      (0, _index.getManager)({
        id: id
      }).then(function (res) {
        _this.manager = res.data;
        _this.logList = res.data.logList;
      });
    }
  },
  methods: {}
};
exports.default = _default;