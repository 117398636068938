"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var paymentRouter = {
  path: '/payment',
  component: _layout.default,
  redirect: '/payment/tab',
  name: 'payment',
  meta: {
    title: '聚合支付'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tab.vue"));
      });
    },
    name: 'paymentTab',
    meta: {
      title: '聚合支付'
    }
  }, {
    path: 'device',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./device/list.vue"));
      });
    },
    name: 'deviceList',
    meta: {
      activeMenu: '/payment/tab',
      title: '设备列表',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: 'api/org/device/pageDevice',
      addUrl: '/payment/deviceAdd',
      addTxt: "新增设备"
    }
  }, {
    path: 'deviceAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./device/add.vue"));
      });
    },
    name: 'deviceListAdd',
    meta: {
      title: '新增编辑设备',
      activeMenu: '/payment/tab'
    }
  }, {
    path: 'channels',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./channels/list.vue"));
      });
    },
    name: 'channelsList',
    meta: {
      activeMenu: '/payment/tab',
      title: '通道关联列表',
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      isOneRequest: "no",
      api: '/api/pcc/cfg/ref/pageCfgRef',
      addUrl: '/payment/channelsAdd',
      addTxt: "新增通道关联"
    }
  }, {
    path: 'channelsAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./channels/add.vue"));
      });
    },
    name: 'channelsListAdd',
    meta: {
      title: '新增编辑通道关联',
      activeMenu: '/payment/tab'
    }
  }, {
    path: 'cfg',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./cfg/list.vue"));
      });
    },
    name: 'cfgList',
    meta: {
      activeMenu: '/payment/tab',
      title: '支付通道列表',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/pcc/cfg/cfgPage',
      addUrl: '/payment/cfgAdd',
      addTxt: "新增通道"
    }
  }, {
    path: 'cfgAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./cfg/add.vue"));
      });
    },
    name: 'cfgAdd',
    meta: {
      title: '新增编辑通道',
      activeMenu: '/payment/tab'
    }
  }, {
    path: 'tem',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tem/list.vue"));
      });
    },
    name: 'paytemList',
    meta: {
      activeMenu: '/payment/tab',
      title: '支付模板',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/pcc/tpt/listPayChanelTemplates',
      addUrl: '/payment/temAdd',
      addTxt: "新增模板"
    }
  }, {
    path: 'temAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tem/add.vue"));
      });
    },
    name: 'paytemAdd',
    meta: {
      title: '新增编辑模板',
      activeMenu: '/payment/tab'
    }
  }]
};
var _default = paymentRouter;
exports.default = _default;