"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addModule = addModule;
exports.addPage = addPage;
exports.addStyle = addStyle;
exports.addTmplate = addTmplate;
exports.delModule = delModule;
exports.editModule = editModule;
exports.editModuleSort = editModuleSort;
exports.editPage = editPage;
exports.editStyle = editStyle;
exports.editTmplate = editTmplate;
exports.getModule = getModule;
exports.getPage = getPage;
exports.getStyleType = getStyleType;
exports.getTmplate = getTmplate;
exports.listModule = listModule;
exports.listPage = listPage;
exports.listStyle = listStyle;
exports.listStyleType = listStyleType;
exports.listTmplate = listTmplate;
exports.listTmplateClass = listTmplateClass;
exports.listTmplatePage = listTmplatePage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 页面分页
 入参：{"pageNo":"当前页","pageSize":"页条数"}
 */
function listPage(data) {
  return (0, _request.default)({
    url: '/api/page/listPage',
    method: 'post',
    data: data
  });
}
/*
 新增页面
 入参：{"pageName":"页面名称","pageCode":"页面编码","tmplateId":"页面模板id"}
 */


function addPage(data) {
  return (0, _request.default)({
    url: '/api/page/addPage',
    method: 'post',
    data: data
  });
}
/*
 编辑页面
入参：{"id":"id","pageName":"页面名称","pageCode":"页面编码","tmplateId":"页面模板id"}
 */


function editPage(data) {
  return (0, _request.default)({
    url: '/api/page/editPage',
    method: 'post',
    data: data
  });
}
/*
 页面明细
 */


function getPage(data) {
  return (0, _request.default)({
    url: '/api/page/getPage/' + data,
    method: 'post'
  });
}
/*
页面模板分类列表
*/


function listTmplateClass(data) {
  return (0, _request.default)({
    url: '/api/pageTmplate/listTmplateClass',
    method: 'post',
    data: data
  });
}
/*
页面模板列表
  入参：{"classId":"模板分类id"}
*/


function listTmplate(data) {
  return (0, _request.default)({
    url: '/api/pageTmplate/listTmplate',
    method: 'post',
    data: data
  });
}
/*
页面模板分页
 入参：{"pageNo":"当前页","pageSize":"页条数"}
*/


function listTmplatePage(data) {
  return (0, _request.default)({
    url: '/api/pageTmplate/listTmplatePage',
    method: 'post',
    data: data
  });
}
/*
页面模板明细
*/


function getTmplate(data) {
  return (0, _request.default)({
    url: '/api/pageTmplate/getTmplate/' + data,
    method: 'post'
  });
}
/*
新增页面模板
入参： {"tmplateName":"模板名称","classId":"模板分类id","tmplateStatus":"模板状态(0：停用 1：启用)"}
*/


function addTmplate(data) {
  return (0, _request.default)({
    url: '/api/pageTmplate/addTmplate',
    method: 'post',
    data: data
  });
}
/*
 编辑页面模板：/api/pageTmplate/editTmplate
 {"id":"id","tmplateName":"模板名称","classId":"模板分类id","tmplateStatus":"模板状态(0：停用 1：启用)"}

 */


function editTmplate(data) {
  return (0, _request.default)({
    url: '/api/pageTmplate/editTmplate',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
页面模板组件分页
       入参：{"tmplateId":"页面模板id","pageNo":"当前页","pageSize":"页条数"}
*/


function listModule(data) {
  return (0, _request.default)({
    url: '/api/pageModule/listModule',
    method: 'post',
    data: data
  });
}
/*
10、页面模板组件明细：/api/pageModule/getModule/{id}
*/


function getModule(data) {
  return (0, _request.default)({
    url: '/api/pageModule/getModule/' + data,
    method: 'post'
  });
}
/*
 11、新增页面模板组件：/api/pageModule/addModule
       {
           "tmplateId":"页面模板id",
           "moduleName":"组件名称",
           "moduleStatus":"组件状态(0：停用 1：启用)",
           "styleCode":"组件样式编码",
           "moduleAttr":{
               "chunkName":"",
               "linkCode":"",
               "linkId":"",
               "linkPath":"",
               "dataItem":[
                   {
                       "linkCode":"",
                       "linkId":"",
                       "linkPath":"",
                       "dataPath":"图片地址",
                       "dataName":""
                   },
                   {
                       "linkCode":"",
                       "linkId":"",
                       "linkPath":"",
                       "dataPath":"图片地址",
                       "dataName":""
                   }
               ]
           }
       }
*/


function addModule(data) {
  return (0, _request.default)({
    url: '/api/pageModule/addModule',
    method: 'post',
    data: data
  });
}
/*
12、编辑页面模板组件：/api/pageModule/editModule
       入参：{"id":"id","tmplateId":"页面模板id","moduleName":"组件名称","moduleStatus":"组件状态(0：停用 1：启用)","moduleAttr":"组件属性值"}
*/


function editModule(data) {
  return (0, _request.default)({
    url: '/api/pageModule/editModule',
    method: 'post',
    data: data
  });
}
/*
13、编辑页面模板组件序：/api/pageModule/editModuleSort
        入参：[{"id":"id","moduleAttr":"组件排序"}]
*/


function editModuleSort(data) {
  return (0, _request.default)({
    url: '/api/pageModule/editModuleSort',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
 14、删除页面模板组件：/api/pageModule/delModule/{id}
*/


function delModule(id) {
  return (0, _request.default)({
    url: '/api/pageModule/delModule/' + id,
    method: 'post'
  });
}
/*
1、样式类型列表：/api/pageModuleStyle/listStyleType
      {"styleStatus":1}
 */


function listStyleType(data) {
  return (0, _request.default)({
    url: '/api/pageModuleStyle/listStyleType',
    method: 'post',
    data: data
  });
}
/*
2、样式列表：/api/pageModuleStyle/listStyle
     {"parentId":"样式类型id","styleStatus":1}
 */


function listStyle(data) {
  return (0, _request.default)({
    url: '/api/pageModuleStyle/listStyle',
    method: 'post',
    data: data
  });
}
/*
 3、样式明细：/api/pageModuleStyle/getStyleType/{id}
 */


function getStyleType(data) {
  return (0, _request.default)({
    url: '/api/pageModuleStyle/getStyleType/' + data,
    method: 'post'
  });
}
/*
 4、新增样式：/api/pageModuleStyle/addStyle
  {"parentId":样式类型id","styleName":"样式名称","styleCode":"样式编码","styleStatus":"样式状态(0：停用 1：启用)","thumbnailPath":"缩略图路径"}
 */


function addStyle(data) {
  return (0, _request.default)({
    url: '/api/pageModuleStyle/addStyle',
    method: 'post',
    data: data
  });
}
/*
5、编辑样式：/api/pageModuleStyle/editStyle
{"id":"id","parentId":样式类型id","styleName":"样式名称","styleCode":"样式编码",
"styleStatus":"样式状态(0：停用 1：启用)","thumbnailPath":"缩略图路径"}
 */


function editStyle(data) {
  return (0, _request.default)({
    url: '/api/pageModuleStyle/editStyle',
    method: 'post',
    data: data
  });
}