"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _auth = require("@/utils/auth");

var service = _axios.default.create({
  baseURL: 'https://mall.xinglico.com/index.php?store_id=2&r=',
  // "/apis", //     url = base url + request url
  timeout: 100000 // request timeout

});

service.interceptors.request.use(function (config) {
  config.headers['Content-Type'] = 'application/json';
  config.headers['Access-Control-Allow-Headers'] = 'X-Requested-With,Content-Type,Accept,Origin';

  if (_store.default.getters.token) {
    config.headers['Token'] = (0, _auth.getToken)();
  }

  console.log(config);
  return config;
}, function (error) {
  return Promise.reject(error);
});
service.interceptors.response.use(function (response) {
  var res = response.data;

  if (res.code === 0) {
    return res;
  } else {
    (0, _elementUi.Message)({
      message: res.msg,
      type: 'error',
      duration: 5 * 1000
    });
  }
}, function (error) {
  (0, _elementUi.Message)({
    message: '网络连接超时，请稍后再试',
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;