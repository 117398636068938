var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("配置新人礼")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { ref: "title", attrs: { label: "活动名称：", prop: "title" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.title,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "title", $$v)
                      },
                      expression: "ruleForm.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "shortTitle",
                  attrs: { label: "活动简称：", prop: "shortTitle" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.shortTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "shortTitle", $$v)
                      },
                      expression: "ruleForm.shortTitle"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "introduction",
                  attrs: { label: "活动描述：", prop: "introduction" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.introduction,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "introduction", $$v)
                      },
                      expression: "ruleForm.introduction"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { ref: "status", attrs: { label: "状态：", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status"
                      }
                    },
                    _vm._l(_vm.weekList, function(item, index) {
                      return _c(
                        "el-radio",
                        { key: index, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "popFlag",
                  attrs: { label: "弹窗标志", prop: "popFlag" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.popFlag,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "popFlag", $$v)
                        },
                        expression: "ruleForm.popFlag"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: -1 } }, [
                        _vm._v("不弹")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("弹窗")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "popInterval",
                  attrs: { label: "弹出频率：", prop: "popInterval" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.popInterval,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "popInterval", $$v)
                        },
                        expression: "ruleForm.popInterval"
                      }
                    },
                    _vm._l(_vm.HZlist, function(item, index) {
                      return _c(
                        "el-radio",
                        { key: index, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "popImage",
                  attrs: { label: "广告图：", prop: "popImage" }
                },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 240,
                      height: 270,
                      cover: _vm.ruleForm.popImage
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "popImage")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [_vm._v("480*540")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物品信息：", prop: "productConfigs" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.getRelevances }
                    },
                    [_vm._v("添加物品")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.ruleForm.productConfigs,
                        size: "mini",
                        border: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "物品类型",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "productConfigs." +
                                      scope.$index +
                                      ".type",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "productConfigs." +
                                        scope.$index +
                                        ".type",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择物品类型"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          placeholder: "物品类型"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setAward(
                                              $event,
                                              scope.$index
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.type,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "type", $$v)
                                          },
                                          expression: "scope.row.type"
                                        }
                                      },
                                      _vm._l(_vm.type, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "关联物品",
                          "min-width": "200",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "productConfigs." +
                                      scope.$index +
                                      ".productCode",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "productConfigs." +
                                        scope.$index +
                                        ".productCode",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择物品"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    typeof scope.row.type === "number"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.getAward(
                                                  scope.row.type,
                                                  scope.$index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.attrType == 1
                                                    ? "选择优惠券"
                                                    : "选择商品"
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "物品编码",
                          "min-width": "200",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    scope.row.productCode
                                      ? _c("p", [
                                          _vm._v(_vm._s(scope.row.productCode))
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "物品名称",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.productName || "") +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "物品价值",
                          width: "150",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "productConfigs." +
                                      scope.$index +
                                      ".remark",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "productConfigs." +
                                        scope.$index +
                                        ".remark",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入价值"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: { type: "number" },
                                        model: {
                                          value: scope.row.remark,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "remark", $$v)
                                          },
                                          expression: "scope.row.remark"
                                        }
                                      },
                                      [
                                        _c("template", { slot: "append" }, [
                                          _vm._v("元")
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "规格", width: "150", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "productConfigs." +
                                      scope.$index +
                                      ".productNum",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "productConfigs." +
                                        scope.$index +
                                        ".productNum",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message:
                                            scope.row.type === 5
                                              ? "请输入金额"
                                              : "请输入数量"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: { type: "number" },
                                        model: {
                                          value: scope.row.productNum,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productNum",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.productNum"
                                        }
                                      },
                                      [
                                        _c("template", { slot: "append" }, [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.type === 5 ? "元" : "份"
                                            )
                                          )
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "确认删除该物品？" },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.delAward(scope.$index)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "danger",
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                          size: "mini"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "ruleIntroduction",
                  attrs: { prop: "ruleIntroduction", label: "规则介绍：" }
                },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { width: 750, height: 500 },
                    model: {
                      value: _vm.ruleForm.ruleIntroduction,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "ruleIntroduction", $$v)
                      },
                      expression: "ruleForm.ruleIntroduction"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: _vm.popType, "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }