import { render, staticRenderFns } from "./pre.vue?vue&type=template&id=aef6fed2&"
import script from "./pre.vue?vue&type=script&lang=js&"
export * from "./pre.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aef6fed2')) {
      api.createRecord('aef6fed2', component.options)
    } else {
      api.reload('aef6fed2', component.options)
    }
    module.hot.accept("./pre.vue?vue&type=template&id=aef6fed2&", function () {
      api.rerender('aef6fed2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/deposit/dep2/giveCard/components/pre.vue"
export default component.exports