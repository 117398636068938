"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _major = require("@/api/major.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      userInfo: {
        realname: '',
        mobile: '',
        card: '',
        coupnName: '',
        time: '',
        couponCode: ''
      },
      index: 0,
      form: {
        remark: '',
        uid: '',
        receivedCode: '',
        attachments: []
      },
      rules: {
        remark: [{
          required: true,
          message: '请填写备注'
        }],
        attachments: [{
          required: true,
          message: '请上传凭证'
        }]
      }
    };
  },
  created: function created() {
    console.log(this.$route.query);
    this.form.uid = this.$route.query.uid;
    this.userInfo.couponCode = this.$route.query.receivedCode;
    this.userInfo.realname = this.$route.query.username;
    this.userInfo.mobile = this.$route.query.phone;
    this.userInfo.coupnName = this.$route.query.coupnName;
    this.userInfo.time = this.$route.query.time;
    this.form.receivedCode = this.$route.query.userReceiveCode;
    (0, _major.getRightsCoupon)({
      uid: this.form.uid,
      receivedCode: this.form.receivedCode
    }).then(function (res) {
      console.log(res);
    });
  },
  methods: {
    deleted: function deleted(index) {
      this.form.attachments.splice(index, 1);
      this.$refs.form.validateField('attachments');
    },
    setIndex: function setIndex(index) {
      this.index = index;
    },
    setCover: function setCover(e) {
      if (this.index == this.form.attachments.length || this.form.attachments.length == 0) {
        this.form.attachments.push(e);
      } else {
        this.form.attachments[this.index] = e; // this.$set( this.form.attachments[this.index],{cover:e})

        this.$forceUpdate();
      }

      this.$refs.form.validateField('attachments');
    },
    goDui: function goDui() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          (0, _major.addCouponRights)(_this.form).then(function (res) {
            _this.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;