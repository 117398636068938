"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeStatus = changeStatus;
exports.whiteListDetail = whiteListDetail;
exports.whiteListSave = whiteListSave;
exports.whiteLists = whiteLists;

var _request = _interopRequireDefault(require("@/utils/request"));

function whiteLists(data) {
  return (0, _request.default)({
    url: "/api/whiteList/listWhiteListPage",
    method: "post",
    data: data
  });
}

function whiteListSave(data) {
  var suffix = data.id ? "editWhiteList" : "addWhiteList";
  return (0, _request.default)({
    url: "/api/whiteList/" + suffix,
    method: "post",
    data: data
  });
}

function whiteListDetail(id) {
  return (0, _request.default)({
    url: "/api/whiteList/getWhiteListById/" + id,
    method: "get"
  });
}

function changeStatus(data) {
  return (0, _request.default)({
    url: "/api/whiteList/changeStatus",
    method: "post",
    data: data
  });
}