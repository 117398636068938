"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addHelp = addHelp;
exports.delHelp = delHelp;
exports.editHelp = editHelp;
exports.examineHelp = examineHelp;
exports.getHelp = getHelp;
exports.helpPage = helpPage;
exports.recordsDeaPage = recordsDeaPage;
exports.recordsPage = recordsPage;

var _request = _interopRequireDefault(require("@/utils/request"));

function helpPage(data) {
  return (0, _request.default)({
    url: '/api/activity/help/page',
    method: 'post',
    data: data
  });
}

function addHelp(data, method) {
  return (0, _request.default)({
    url: '/api/activity/help',
    method: method,
    data: data
  });
}

function editHelp(data) {
  return (0, _request.default)({
    url: '/api/activity/help/update',
    method: 'post',
    data: data
  });
}

function examineHelp(data) {
  return (0, _request.default)({
    url: '/api/activity/help/examine',
    method: 'post',
    data: data
  });
}

function delHelp(data) {
  return (0, _request.default)({
    url: '/api/activity/help/' + data,
    method: 'post'
  });
}

function getHelp(data) {
  return (0, _request.default)({
    url: '/api/activity/help/' + data,
    method: 'get'
  });
}
/*
 /api/activity/help/records/page
 */


function recordsPage(data) {
  return (0, _request.default)({
    url: '/api/activity/help/reward/receive/page',
    method: 'post',
    data: data
  });
}
/*
private Long beHelpUserId;

    private String activityCode;

    private Long pageSize;

    private Long pageNo;

 */


function recordsDeaPage(data) {
  return (0, _request.default)({
    url: '/api/activity/help/records/detail/page',
    method: 'post',
    data: data
  });
}