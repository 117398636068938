"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _user = require("@/api/user.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _coupon = require("@/api/coupon.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    qrCodePop: _qrCodePop.default,
    Pagination: _Pagination.default,
    AppLink: _Link.default
  },
  data: function data() {
    return {
      popShow: false,
      // 小程序码弹框
      qrCodeShow: true,
      // 列表是否显示获取小程序码
      qrCode: '',
      // 小程序码
      url: '',
      // 小程序链接
      pageNo: 1,
      pageSize: 10,
      total: 0,
      btn: '查看详情',
      operation: 2,
      btnType: 'primary',
      tableData: [],
      tabTh: [{
        tit: '活动名称',
        prop: 'name',
        width: 200
      }, {
        tit: '活动编码',
        prop: 'uuid',
        width: 160,
        align: 'center'
      }, {
        tit: '分类',
        prop: 'classId',
        width: 140
      }, {
        tit: '活动有效期',
        prop: 'time',
        width: 210,
        align: 'center'
      }, {
        tit: '活动状态',
        prop: 'validityStatusTxt',
        width: 90,
        align: 'center'
      }, {
        tit: '排序',
        prop: 'sort',
        width: 60,
        align: 'center'
      }, {
        tit: '显示状态',
        prop: 'statusText',
        width: 80,
        align: 'center'
      }],
      classFly: [{
        name: '全部',
        classId: -1
      }],
      classId: '',
      idCard: '',
      name: '',
      options: [{
        value: -1,
        label: '全部'
      }, {
        value: 0,
        label: '待上线'
      }, {
        value: 1,
        label: '上线中'
      }, {
        value: 2,
        label: '已下线'
      }],
      onlineStatus: ''
    };
  },
  created: function created() {
    this.getCLass();
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getCode: function getCode(scope) {
      var _this = this;

      // 获取小程序码
      this.url = 'pages/discover/detail/index/index?activity_id=' + scope.uuid;
      this.openLoading('获取小程序码中');
      (0, _user.xcxQRCode)({
        // path: 'pages/coupons/couponDea/couponDea',
        // scene: 'id=CA20091805384403571'
        path: 'pages/discover/detail/index/index',
        scene: 'activity_id=' + scope.uuid
      }).then(function (res) {
        _this.openLoading().close();

        _this.qrCode = res.data;
        _this.popShow = true;
      }).catch(function (res) {
        _this.openLoading().close();
      });
    },
    goDown: function goDown(scope, index) {
      var _this2 = this;

      // 活动立即下线
      this.$confirm('此操作下线该条活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _coupon.editActivityOffline)({
          activityId: scope.activityId
        }).then(function (res) {
          _this2.tableData[index].onlineStatus = 2;
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消下线'
        });
      });
    },
    setBtn: function setBtn(scope) {
      // 查看详情
      this.$router.push('/coupon/coupon3/promotionDea?activityId=' + scope.activityId);
    },
    goEdit: function goEdit(scope) {
      this.$store.dispatch('editor/setPromontionEditor', '');
      this.$router.push('/coupon/coupon3/activity?id=promontion&activityId=' + scope.activityId);
    },
    goAdd: function goAdd() {
      // 去添加
      this.$store.dispatch('editor/setPromontionEditor', '');
      this.$router.push('/coupon/coupon3/activity?id=promontion');
    },
    getList: function getList(page) {
      this.pageNo = page.page;
      this.pageSize = page.limit;
      this.init();
    },
    getSearch: function getSearch() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.init();
    },
    init: function init() {
      var _this3 = this;

      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        name: this.name.trim(),
        classId: this.classId,
        onlineStatus: this.onlineStatus
      };
      this.openLoading();
      (0, _coupon.listActivityPage)(data).then(function (res) {
        _this3.openLoading().close();

        _this3.tableData = res.data.items;
        _this3.total = res.data.totalNum;

        _this3.tableData.forEach(function (item) {
          /* if (item.onlineType === 2) {
                item.beginTime = "立即上线"
                item.endTime = "需手动下线"
                item.time = "需手动下线"
              } else {
                item.time = item.validityStartTime + "-" + item.validityEndTime
              } */
          item.time = item.validityStartTime + '-' + item.validityEndTime;

          _this3.classFly.forEach(function (classItem) {
            if (item.classId == classItem.classId) {
              item.classId = classItem.name;
            }
          });

          switch (item.onlineStatus) {
            case 0:
              item.statusText = '待显示';
              break;

            case 1:
              item.statusText = '显示中';
              break;

            case 2:
              item.statusText = '已隐藏';
              break;
          }

          switch (item.validityStatus) {
            case 0:
              item.validityStatusTxt = '待开始';
              break;

            case 1:
              item.validityStatusTxt = '进行中';
              break;

            case 2:
              item.validityStatusTxt = '已结束';
              break;
          }
        });
      }).catch(function (res) {
        _this3.openLoading().close();
      });
    },
    getCLass: function getCLass() {
      var _this4 = this;

      (0, _coupon.listActivityClass)().then(function (res) {
        _this4.classFly = _this4.classFly.concat(res.data);
      });
    },
    setValue: function setValue() {
      this.onlineStatus = '';
      this.classId = '';
      this.name = '';
      this.init();
    }
  }
};
exports.default = _default;