"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listLogDetailPage = listLogDetailPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 1、面对面发券统计分页：/api/sendCouponFace/listLogDetailPage
 {"pageNo":"当前页","pageSize":"页条数","couponCode":"券编码","couponName":"券名称",
 "storeCode":"门店编码","sendUname":"发券人姓名","toUmobile":"会员手机号",
 "startDate":"发券开始时间","endDate":"发券结束日期"}
 */
function listLogDetailPage(data) {
  return (0, _request.default)({
    url: '/api/sendCouponFace/listLogDetailPage',
    method: 'post',
    data: data
  });
}