"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default
  },
  data: function data() {
    return (0, _defineProperty2.default)({
      name: '',
      parentId: '',
      parentIds: [],
      backName: '',
      status: '',
      adventType: 1,
      imgSrc: this.$store.state.user.imgSrc,
      urlPopShow: false,
      urlValue: '',
      urlIdShow: false,
      urlId: '',
      idTit: '',
      urlText: '',
      selectItem: {},
      code: '',
      options: [{
        judgeId: true,
        value: '/pages/coupons/couponDea/couponDea?id=',
        label: '优惠券详情',
        id: '优惠券编码'
      }, {
        judgeId: true,
        value: '/pages/discover/detail/index/index?id=',
        label: '活动详情',
        id: '活动编码'
      }, {
        value: '/pages/CEB/cebCoupon/cebCoupon',
        label: '光大银行'
      }, {
        judgeId: true,
        value: '/pages/starCard/recharge/recharge?id=',
        label: '储值卡详情',
        id: '储值卡编码'
      }, {
        value: '/pages/member/memberDea/memberDea?id=',
        label: '会员详情',
        id: '会员体系ID'
      }, {
        value: '/pages/scoreShop/scoreShop',
        label: '砍价列表'
      }, {
        value: '/seckill/seckill',
        label: '秒杀'
      }, {
        value: '/activity/card-activity/card-activity',
        label: '五鼠'
      }, {
        value: '/seckill/bank-active/index',
        label: '贵州银行工会卡用户'
      }, {
        value: '/pages/coupons/getCoupon/getCoupon',
        label: '领券中心'
      }, {
        value: '/pages/store/index/index',
        label: '星礼卡商城'
      }, {
        value: '/pages/mycard/mycard',
        label: '会员列表'
      }, {
        value: '/pages/discover/index/index',
        label: '活动列表'
      }, {
        value: '/pages/pond/pond/pond',
        label: '九宫格'
      }, {
        value: '/pages/getAllCoupon/getAllCoupon',
        label: '荔星宠粉狂欢节'
      }, {
        value: '/pages/voteActivity/voteActivity?id=',
        label: '投票',
        id: '投票ID'
      }, {
        value: '4',
        label: '其他小程序',
        id: '小程序地址',
        appId: '小程序APPID'
      }, {
        value: '5',
        label: 'WEB页面',
        id: 'WEB页面地址'
      }],
      adventTypes: [{
        value: 1,
        label: '图片' //					{
        //						value:2,
        //						lable:"视频"
        //					}

      }],
      linkTypes: [{
        value: 1,
        label: '储值卡'
      }, {
        value: 2,
        label: '优惠券'
      }, {
        value: 3,
        label: '活动'
      }, {
        value: 4,
        label: '其他小程序'
      }, {
        value: 5,
        label: 'web网页'
      }, {
        value: 6,
        label: '小程序内部地址'
      }],
      linkType: '',
      imgPath: '',
      linkUrl: '',
      url: '',
      id: '',
      appId: '',
      appIdName: '',
      appIdArr: [{
        value: 'wx90539d082f32e16f',
        label: '乐品鲜活'
      }, {
        value: 'wxe6843e111c37fa99',
        label: '景区直通车'
      }, {
        value: 'wx3d25f9499a96d57e',
        label: '星力乐购'
      }, {
        value: 'wx1f693db219ee13fa',
        label: '星力名酒预定'
      }]
    }, "imgSrc", this.$store.state.user.imgSrc);
  },
  watch: {},
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  methods: {
    setAppId: function setAppId(e) {
      var _this = this;

      this.appIdArr.forEach(function (item) {
        if (item.value == e) {
          _this.appIdName = item.label;
        }
      });
    },

    /* 选择链接*/
    showPop: function showPop() {
      this.urlPopShow = true;
    },

    /* 下拉框限制页面*/
    selectChange: function selectChange(e) {
      var _this2 = this;

      this.code = '';
      this.options.forEach(function (item) {
        if (item.value === e) {
          _this2.selectItem = item;
        }
      });
    },

    /* 取消url弹框*/
    closeUrlPop: function closeUrlPop() {
      this.urlPopShow = false;
    },

    /* 保存链接*/
    saveUrl: function saveUrl() {
      if (this.selectItem.value == 4) {
        this.linkType = this.selectItem.value;
        this.linkUrl = this.code;
        this.appId = this.appId;
      } else if (this.selectItem.value == 5) {
        this.appId = '';
        this.linkType = this.selectItem.value;
        this.linkUrl = this.code;
      } else {
        this.appId = '';
        this.linkType = 6;

        if (this.selectItem.id) {
          this.linkUrl = this.selectItem.value + this.code;
        } else {
          this.linkUrl = this.selectItem.value;
        }
      }

      this.urlPopShow = false;
    },
    setCover: function setCover(cover) {
      console.log(cover);
      this.imgPath = cover;
    },
    onChange: function onChange(e) {
      var _this3 = this;

      this.templateCodes.forEach(function (item) {
        if (item.code === e) {
          _this3.cover = _this3.$store.state.user.imgSrc + item.imgUrl;
        }
      });
    },
    next: function next() {
      var _this4 = this;

      if (this.name.trim() === '') {
        this.$message.error('请填写广告体名称');
        return;
      } else if (this.parentId === '') {
        this.$message.error('请选择所属广告位');
        return;
      } else if (this.adventType === '') {
        this.$message.error('请选择广告体类型');
        return;
      } else if (this.imgPath === '') {
        this.$message.error('请上传广告体附件');
        return;
      } else if (this.linkType === '') {
        this.$message.error('请选择广告链接类型');
        return;
      } else if (this.linkUrl === '') {
        this.$message.error('请填入广告链接地址');
        return;
      } else if (this.appId === '' && this.linkType == 4) {
        this.$message.error('请选择跳转小程序');
        return;
      } else if (this.status === '') {
        this.$message.error('请选择广告状态');
        return;
      } else {
        var obj = {
          parentId: this.parentId,
          name: this.name,
          status: Number(this.status),
          linkType: this.linkType,
          adventType: this.adventType,
          imgPath: this.imgPath,
          linkUrl: this.linkUrl,
          appId: this.appId
        };

        if (this.selectItem.judgeId) {
          (0, _coupon.listRelevanceAttrInfo)({
            attrCode: this.code
          }).then(function (res) {
            if (_this4.id) {
              obj.advertId = _this4.id;
              console.log(obj);
              (0, _coupon.editAdvertItem)(obj).then(function (res) {
                console.log(res);

                _this4.$router.back(-1);
              });
            } else {
              (0, _coupon.addAdvertItem)(obj).then(function (res) {
                console.log(res);

                _this4.$router.back(-1);
              });
            }
          });
        } else {
          if (this.id) {
            obj.advertId = this.id;
            console.log(obj);
            (0, _coupon.editAdvertItem)(obj).then(function (res) {
              console.log(res);

              _this4.$router.back(-1);
            });
          } else {
            (0, _coupon.addAdvertItem)(obj).then(function (res) {
              console.log(res);

              _this4.$router.back(-1);
            });
          }
        }
      }
    },
    init: function init() {
      var _this5 = this;

      this.id = Number(this.$route.query.id);
      /*
      获取栏目项详情
      * */

      if (this.id) {
        this.openLoading();
        (0, _coupon.getAdvertById)(this.id).then(function (res) {
          _this5.openLoading().close();

          _this5.adventType = res.data.adventType;
          _this5.name = res.data.name;
          _this5.imgPath = res.data.imgPath;
          _this5.status = String(res.data.status);
          _this5.parentId = res.data.parentId;
          _this5.linkType = res.data.linkType;
          _this5.linkUrl = res.data.linkUrl;

          if (_this5.linkType == 4) {
            _this5.urlValue = '4';
            _this5.appId = res.data.appId ? res.data.appId : 'wx90539d082f32e16f';

            _this5.selectChange(_this5.urlValue);

            _this5.setAppId(_this5.appId);

            _this5.code = res.data.linkUrl;
          } else if (_this5.linkType == 5) {
            _this5.urlValue = '5';

            _this5.selectChange(_this5.urlValue);

            _this5.code = res.data.linkUrl;
          } else if (res.data.linkUrl.split('?').length > 1 && res.data.linkType == 6) {
            // this.selectItem.value=res.data.linkUrl.split('=')[0]+'='
            _this5.urlValue = res.data.linkUrl.split('=')[0] + '=';

            _this5.selectChange(_this5.urlValue);

            _this5.code = res.data.linkUrl.split('=')[1];
            console.log(_this5.urlValue);
          } else {
            _this5.urlValue = res.data.linkUrl;

            _this5.selectChange(_this5.urlValue);
          }
        }).catch(function (res) {
          _this5.openLoading().close();
        });
      }

      (0, _coupon.listAdvert)().then(function (res) {
        _this5.parentIds = res.data;
      });
    }
  }
};
exports.default = _default;