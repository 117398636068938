"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _member = require("@/api/member.js");

var _mem = require("@/api/mem.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      list: [],
      searchTime: '',
      total: 0,
      btnText: '详情',
      storeList: [],
      obj: {
        pageNo: 1,
        pageSize: 10,
        exchangeUmobile: '',
        giftType: '',
        storeCode: '',
        exchangeNo: ''
      },
      cardList: [],
      tabTh: [{
        prop: 'exchangeNo',
        label: '馈赠单号',
        width: 160
      }, {
        prop: 'giftName',
        label: '馈赠礼品',
        width: 140
      }, {
        prop: 'exchangeUmobile',
        label: '会员手机号',
        width: 98
      }, {
        prop: 'giftType',
        label: '礼品类型',
        width: 80
      }, {
        prop: 'storeName',
        label: '所属门店',
        width: 140
      }, {
        prop: 'exchangeNumber',
        label: '数量',
        width: 60
      }, {
        prop: 'consumeScore',
        label: '积分消耗',
        width: 80
      }, {
        prop: 'exchangeTime',
        label: '兑换时间',
        width: 140
      }, {
        prop: 'exchangeOprUname',
        label: '操作人',
        width: 100
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _member.listType)({}).then(function (res) {
      _this.cardList = res.data;
    });
    (0, _mem.listStore)({
      companyId: ''
    }).then(function (res) {
      console.log(res);
      _this.storeList = res.data;
    });
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this2 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
      }

      if (type == 'remove') {
        this.searchTime = [];
        this.removeObj(this.obj);
      }

      this.obj.startDate = this.searchTime[0] || '';
      this.obj.endDate = this.searchTime[1] || '';
      this.openLoading();
      (0, _member.listExchangePage)(this.obj).then(function (res) {
        _this2.openLoading().close();

        if (!_this2.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.exchangeUmobile) {
              item.exchangeUmobile = item.exchangeUmobile.replace(item.exchangeUmobile.substring(3, 7), "****");
            }
          });
        }

        _this2.list = res.data.items;
        _this2.total = res.data.totalNum;
      }).catch(function (err) {
        _this2.openLoading().close();
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/member/present/add');
    },
    setTab: function setTab(index, row) {
      //  editExchange({id:row.id,refundsRemark:"退货备注"})
      this.$router.push('/member/present/returnSales?id=' + row.id); //  getExchangeById(row.id)
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['馈赠单号', '馈赠礼品', '会员手机号', '礼品类型', '所属门店', '数量', '积分消耗', '兑换时间', '操作人', '退货状态', '备注'];
        var filterVal = ['exchangeNo', 'giftName', 'exchangeUmobile', 'giftType', 'storeName', 'exchangeNumber', 'consumeScore', 'exchangeTime', 'exchangeOprUname', 'refundsStatus', 'exchangeRemark'];
        var list = [];
        var obj = JSON.parse(JSON.stringify(_this3.obj));
        obj.startDate = _this3.searchTime[0] || '';
        obj.endDate = _this3.searchTime[1] || '';
        obj.pageSize = _this3.total;
        (0, _member.listExchangePage)(obj).then(function (res) {
          if (!_this3.setMenuPerms('user_mobile_idCard')) {
            res.data.items.forEach(function (item) {
              if (item.exchangeUmobile) {
                item.exchangeUmobile = item.exchangeUmobile.replace(item.exchangeUmobile.substring(3, 7), "****");
              }
            });
          }

          list = res.data.items;
          list.forEach(function (item) {
            item.refundsStatus = item.refundsStatus == 2 ? '已退货' : '正常';
          });

          var data = _this3.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '会员馈赠'
          });
          _this3.downloadLoading = false;
        }).catch(function (res) {
          _this3.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;