"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _label = require("@/api/label.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    recenterLabels: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      tableData: [],
      tabTh: [{
        tit: ' 标签ID',
        prop: 'labelId',
        width: 1
      }, {
        tit: ' 标签名称',
        prop: 'labelName',
        width: 4
      }, {
        tit: ' 所属分类',
        prop: 'className',
        width: 4
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _label.listLabel)().then(function (res) {
      _this.tableData = res.data;

      if (_this.recenterLabels.length > 0) {
        _this.recenterLabels.forEach(function (item) {
          _this.tableData.forEach(function (row) {
            if (row.labelId == item.labelId) {
              _this.$refs.label.toggleRowSelection(row);
            }
          });
        });
      }
    });
  },
  methods: {
    setTable: function setTable(list) {
      var _this2 = this;

      if (list.length > 0) {
        list.forEach(function (item) {
          _this2.tableData.forEach(function (row) {
            if (row.labelId == item.labelId) {
              _this2.$refs.label.toggleRowSelection(row);
            }
          });
        });
      }
    },
    clearPop: function clearPop() {
      this.$refs.label.clearSelection();
    },
    clearTable: function clearTable(obj) {
      var _this3 = this;

      this.clearPop();
      console.log(obj.labelId.length > 0);

      if (obj.labelId.length > 0) {
        obj.labelId.forEach(function (item) {
          _this3.tableData.forEach(function (row) {
            if (row.labelId == item) {
              _this3.$refs.label.toggleRowSelection(row);
            }
          });
        });
      }
    },
    closePop: function closePop() {
      this.$emit('close');
    },
    affirmAdd: function affirmAdd() {
      this.$emit('addLabel', this.$refs.label.store.states.selection);
    }
  }
};
exports.default = _default2;