"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.editData = editData;
exports.getData = getData;
exports.getGoodsPage = getGoodsPage;
exports.getGradeList = getGradeList;
exports.getList = getList;
exports.getOrderDea = getOrderDea;
exports.goAddGoods = goAddGoods;
exports.goExam = goExam;
exports.listUserRightGiftReciveHistory = listUserRightGiftReciveHistory;
exports.listUserRightScoreReturnReciveHistory = listUserRightScoreReturnReciveHistory;
exports.recordPage = recordPage;

var _request = _interopRequireDefault(require("@/utils/request"));

function getGradeList(data) {
  return (0, _request.default)({
    url: '/api/memberLevel/list',
    method: 'get',
    loading: 'loading'
  });
}

function getList(data) {
  return (0, _request.default)({
    url: '/api/memberRight/list',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addData(data) {
  return (0, _request.default)({
    url: '/api/memberRight/add',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editData(data) {
  return (0, _request.default)({
    url: '/api/memberRight/edit',
    method: 'post',
    data: data
  });
}

function getData(data) {
  return (0, _request.default)({
    url: '/api/memberRight/detail/' + data,
    method: 'get',
    loading: 'loading'
  });
}

function goExam(data) {
  return (0, _request.default)({
    url: '/api/memberRight/editStatus',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function goAddGoods(data) {
  return (0, _request.default)({
    url: '/api/cactivity/goods/batch/add',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getGoodsPage(data) {
  return (0, _request.default)({
    url: '/api/cactivity/goods/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function recordPage(data) {
  return (0, _request.default)({
    url: '/api/cactivity/buy/record/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getOrderDea(data) {
  return (0, _request.default)({
    url: '/api/cactivity/buy/record/detail/list',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listUserRightGiftReciveHistory(data) {
  return (0, _request.default)({
    url: '/api/memberRight/listUserRightGiftReciveHistory',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listUserRightScoreReturnReciveHistory(data) {
  return (0, _request.default)({
    url: '/api/memberRight/listUserRightScoreReturnReciveHistory',
    method: 'post',
    loading: 'loading',
    data: data
  });
}