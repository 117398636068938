var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new1" },
    [
      _c(_vm.currentRole, {
        tag: "component",
        attrs: { "card-id": _vm.cardId },
        on: { setCardId: _vm.setCardId, setTab: _vm.setTab }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }