"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addOpengift = addOpengift;
exports.editOpengift = editOpengift;
exports.editProductStatus = editProductStatus;
exports.getOpengift = getOpengift;
exports.listOpengiftPage = listOpengiftPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
5、新增会员开卡礼：/api/vipOpengift/addOpengift
{"opengifts":[{"vipCode":"会员编码","classId":"会员开卡礼分类id","productCode":"商品编码","productType":"商品类型(1：积分商城商品 2：直充商品 3：优惠券)","putNumber":"投放数量","productSort":"商品排序","productHot":"爆款商品(0：否 1：是)"}]}

6、编辑会员开卡礼：/api/vipOpengift/editOpengift
{"id":"id","vipCode":"会员编码","classId":"会员开卡礼分类id","productCode":"商品编码","productType":"商品类型(1：积分商城商品 2：直充商品 3：优惠券)","putNumber":"投放数量","productSort":"商品排序","productHot":"爆款商品(0：否 1：是)"}

7、会员开卡礼明细：/api/vipOpengift/getOpengift/{id}

8、会员开卡礼分页：/api/vipOpengift/listOpengiftPage
{"pageNo":"当前页","pageSize":"页条数","vipCode":"会员编码","classId":"会员开卡礼分类id","productCode":"商品编码"}

编辑会员开卡礼商品状态：/api/vipOpengift/editProductStatus
 */
function editProductStatus(data) {
  return (0, _request.default)({
    url: '/api/vipOpengift/editProductStatus',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addOpengift(data) {
  return (0, _request.default)({
    url: '/api/vipOpengift/addOpengift',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editOpengift(data) {
  return (0, _request.default)({
    url: '/api/vipOpengift/editOpengift',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getOpengift(data) {
  return (0, _request.default)({
    url: '/api/vipOpengift/getOpengift/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function listOpengiftPage(data) {
  return (0, _request.default)({
    url: '/api/vipOpengift/listOpengiftPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}