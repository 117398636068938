var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isConfirm,
              expression: "!isConfirm"
            }
          ],
          ref: "item",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.item, rules: _vm.rules, "label-width": "120px" }
        },
        [
          _c(
            "el-row",
            [
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("h4", [_vm._v("入库信息")])
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "入库/退库：", prop: "name" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: " ",
                                clearable: "",
                                size: "small"
                              },
                              model: {
                                value: _vm.item.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "type", $$v)
                                },
                                expression: "item.type"
                              }
                            },
                            _vm._l(_vm.cardOptions, function(typeItem, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: typeItem.text,
                                  value: typeItem.value
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "本次制卡数量：", prop: "name" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { size: "small", placeholder: "" },
                              model: {
                                value: _vm.item.number,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "number", $$v)
                                },
                                expression: "item.number"
                              }
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "append" }, slot: "append" },
                                [_vm._v("张")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "入库负责人：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "number", $$v)
                              },
                              expression: "item.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("h4", [_vm._v("厂家信息")])
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "厂家名称：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "number", $$v)
                              },
                              expression: "item.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "厂家联系人：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "number", $$v)
                              },
                              expression: "item.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "厂家地址：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "number", $$v)
                              },
                              expression: "item.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "厂家联系电话：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.item.number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "number", $$v)
                              },
                              expression: "item.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("h4", [_vm._v("单据记录")])
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 17, offset: 2 } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.tableData, border: "" },
                          on: { "cell-mouse-leave": _vm.onChangeCell }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              width: "50",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "实体卡类型", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { placeholder: "" },
                                        model: {
                                          value: scope.row.type,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "type", $$v)
                                          },
                                          expression: "scope.row.type"
                                        }
                                      },
                                      _vm._l(_vm.cardOptions, function(
                                        listItem,
                                        listIndex
                                      ) {
                                        return _c("el-option", {
                                          key: listIndex,
                                          attrs: {
                                            label: listItem.text,
                                            value: listItem.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "卡名称", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.type,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "type", $$v)
                                        },
                                        expression: "scope.row.type"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "起始卡号", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.type,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "type", $$v)
                                        },
                                        expression: "scope.row.type"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "终止卡号", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.type,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "type", $$v)
                                        },
                                        expression: "scope.row.type"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "数量",
                              align: "center",
                              width: "120px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.number,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "number",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "scope.row.number"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "备注",
                              align: "center",
                              width: "180px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: " " },
                                      model: {
                                        value: scope.row.type,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "type", $$v)
                                        },
                                        expression: "scope.row.type"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                align: "center",
                                width: "60",
                                fixed: "right"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                            title: "删除",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleDelete(
                                                scope.$index,
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete"
                                          })
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "template",
                                { slot: "header" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        circle: "",
                                        title: "添加"
                                      },
                                      on: { click: _vm.onAddTable }
                                    },
                                    [_c("i", { staticClass: "el-icon-plus" })]
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.tableData.length,
                              expression: "tableData.length"
                            }
                          ],
                          staticClass: "sumTabledata-box"
                        },
                        [
                          _c("span", [_vm._v("合计：")]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s("数量：" + _vm.sumTabledata + "张"))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("h4", [_vm._v("备注信息")])
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 17, offset: 2 } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 2,
                          placeholder: "备注..."
                        },
                        model: {
                          value: _vm.item.textarea,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "textarea", $$v)
                          },
                          expression: "item.textarea"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isConfirm,
              expression: "isConfirm"
            }
          ]
        },
        [_c("h1", [_vm._v("详情信息")])]
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.goBack } },
            [_c("i", { staticClass: "el-icon-back" }), _vm._v(" 返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onStep }
            },
            [
              _vm._v(_vm._s(_vm.isConfirm ? "上一步" : "下一步") + " "),
              _c("i", {
                class: [_vm.isConfirm ? "el-icon-top" : "el-icon-bottom"]
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isConfirm,
                  expression: "isConfirm"
                }
              ],
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onSave }
            },
            [
              _c("i", { staticClass: "el-icon-document-checked" }),
              _vm._v(" 保存")
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }