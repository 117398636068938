"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _api = require("./api.js");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      requestData: {
        cardKindsId: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '卡号',
        prop: 'cardNo',
        width: 100
      }, {
        label: '类型',
        prop: 'categoryName',
        width: 100
      }, {
        label: '种类',
        prop: 'kindsName',
        width: 100
      }, {
        label: '卡面额',
        prop: 'cardLimitPrice',
        width: 50
      }, {
        label: '制卡计划',
        prop: 'planName',
        width: 100
      }, {
        slot: 'cardVerify',
        label: '是否验证',
        prop: 'cardVerify',
        width: 100
      }, {
        label: '当前余额',
        prop: 'currentPrice',
        width: 100
      }, {
        label: '激活后有效期（年）',
        prop: 'expireTradeYear',
        width: 100
      }, {
        label: '入库时间',
        prop: 'inTime',
        width: 100
      }, {
        slot: 'isRealName',
        label: '是否实名',
        prop: 'isRealName',
        width: 100
      }, {
        /*
        1-固定额度充值 0-不固定额度充值*/
        slot: 'rechargeType',
        label: '充值类型',
        prop: 'rechargeType',
        width: 100
      }, {
        label: '状态',
        prop: 'statusDesc',
        width: 100
      }, {
        label: '子状态',
        prop: 'childStatusDesc',
        width: 100
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'operate'
      }],
      ruleForm: {
        remark: null,
        isRebate: null,
        expireTradeYear: null,
        cardLimitPrice: null,
        cardCategoryId: null,
        cardKindsName: null,
        cardImageFront: '',
        cardImageBack: ''
      }
    };
  },
  created: function created() {
    var _this = this;

    this.requestData.cardKindsId = this.$route.query.id;
    (0, _api.kindsDetail)({
      id: Number(this.requestData.cardKindsId)
    }).then(function (res) {
      _this.ruleForm = res.data;
    });
    this.getList();
  },
  methods: {
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this2 = this;

      (0, _api.getList)(this.requestData).then(function (res) {
        _this2.tableData = res.data.records;
        _this2.requestData.total = res.data.count;
        return;
      });
    }
  }
};
exports.default = _default;