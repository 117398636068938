exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#000",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#3D7FFF",
	"menuBg": "#EAF1FF",
	"menuHover": "#EAF1FF",
	"subMenuBg": "#EAF1FF",
	"subMenuHover": "#EAF1FF",
	"sideBarWidth": "210px"
};