var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-place-reserve" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("预约场地")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "svip-place-reserve_name" }, [
        _vm._v("场地名称：" + _vm._s(_vm.serviceInfo.name))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "svip-place-reserve_name" }, [
        _vm._v(
          "\n    预约身份：" +
            _vm._s(_vm._f("memberTypeLabel")(_vm.serviceInfo.memberType)) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-width": "150px",
            model: _vm.ruleForm,
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "预约客户：", prop: "member" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "请输入手机号",
                    "remote-method": _vm.getMemberOptions,
                    loading: _vm.memberOptionsLoading
                  },
                  model: {
                    value: _vm.ruleForm.member,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "member", $$v)
                    },
                    expression: "ruleForm.member"
                  }
                },
                _vm._l(_vm.memberOptions, function(member) {
                  return _c("el-option", {
                    key: member.value,
                    attrs: {
                      label:
                        "" +
                        member.label +
                        (!member.value
                          ? "（无手机号）"
                          : "（" + member.value + "）"),
                      value: member,
                      disabled: !member.value
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { label: "预约时间：", prop: "timeType" }
            },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { width: "1000px" },
                  attrs: { size: "small" },
                  model: {
                    value: _vm.ruleForm.timeType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "timeType", $$v)
                    },
                    expression: "ruleForm.timeType"
                  }
                },
                [
                  _vm.serviceInfo.saleStandard === 1 ||
                  _vm.serviceInfo.saleStandard === 3
                    ? [
                        _c(
                          "el-row",
                          { attrs: { gutter: 0 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 2 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("按天")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "byDateDay",
                                      rules: [
                                        {
                                          required: _vm.ruleForm.timeType === 1,
                                          message: "请选择日期",
                                          type: "array"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        "picker-options": {
                                          disabledDate: _vm.disabledDate
                                        },
                                        disabled: _vm.ruleForm.timeType !== 1,
                                        type: "daterange",
                                        size: "mini",
                                        "range-separator": "至",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                        "value-format": "yyyy-MM-dd"
                                      },
                                      model: {
                                        value: _vm.ruleForm.byDateDay,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "byDateDay",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.byDateDay"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.serviceInfo.saleStandard === 2 ||
                  _vm.serviceInfo.saleStandard === 3
                    ? [
                        _c(
                          "el-row",
                          { attrs: { gutter: 0 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 2 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("按小时")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "byHourDate",
                                      rules: [
                                        {
                                          required: _vm.ruleForm.timeType === 2,
                                          message: "请选择日期"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "160px" },
                                      attrs: {
                                        "picker-options": {
                                          disabledDate: _vm.disabledDateRange
                                        },
                                        type: "date",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "选择日期",
                                        size: "small",
                                        disabled: _vm.ruleForm.timeType !== 2
                                      },
                                      model: {
                                        value: _vm.ruleForm.byHourDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "byHourDate",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.byHourDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-top": "2px" },
                                    attrs: {
                                      prop: "timeRange",
                                      size: "small",
                                      rules: [
                                        {
                                          required: _vm.ruleForm.timeType === 2,
                                          message: "请选择时间"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("TimeRangePicker", {
                                      attrs: {
                                        range: _vm.timeRangeList,
                                        disabled: !_vm.ruleForm.byHourDate,
                                        placeholder: "请选择时间"
                                      },
                                      model: {
                                        value: _vm.ruleForm.timeRange,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "timeRange",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.timeRange"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "预约人数：",
                required: "",
                prop: "placeParam.reserveNum",
                rules: _vm.reserveNumRules
              }
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c("el-input-number", {
                    staticClass: "form_input input-number",
                    attrs: {
                      type: "number",
                      "controls-position": "right",
                      max: _vm.serviceInfo.intakeCapacity,
                      min: 1,
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.ruleForm.placeParam.reserveNum,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm.placeParam, "reserveNum", $$v)
                      },
                      expression: "ruleForm.placeParam.reserveNum"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-number-append" }, [
                    _vm._v("人")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { "font-size": "14px", color: "#999999" } },
                [
                  _vm._v(
                    "\n        *场地限制" +
                      _vm._s(_vm.serviceInfo.intakeCapacity) +
                      "人\n      "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm.serviceInfo.itemList && _vm.serviceInfo.itemList.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: "服务项目：", prop: "placeParam.itemIds" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.ruleForm.placeParam.itemIds,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm.placeParam, "itemIds", $$v)
                        },
                        expression: "ruleForm.placeParam.itemIds"
                      }
                    },
                    _vm._l(_vm.serviceInfo.itemList, function(item) {
                      return _c(
                        "el-checkbox",
                        {
                          key: item.id,
                          attrs: { label: item.id, name: "itemIds" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.itemName) +
                              " " +
                              _vm._s(_vm._f("itemPrice")(item, _vm.ruleForm)) +
                              "\n        "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "其他需求：", prop: "placeParam.otherNeed" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                staticStyle: { "line-height": "1" },
                attrs: {
                  autosize: { minRows: 4, maxRows: 6 },
                  maxlength: "100",
                  "show-word-limit": "",
                  type: "textarea",
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.ruleForm.placeParam.otherNeed,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm.placeParam,
                      "otherNeed",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.placeParam.otherNeed"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("div", { staticStyle: { color: "red" } }, [
                _vm._v(
                  "\n        订单总价：" +
                    _vm._s(
                      _vm._f("totalAmountView")(
                        _vm.totalAmount,
                        _vm.serviceInfo,
                        _vm.ruleForm
                      )
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _vm.id
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm", "edit")
                        }
                      }
                    },
                    [_vm._v("\n        确定预约\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("PayQrcode", {
        attrs: {
          show: _vm.isShowPayQrcode,
          "create-time": _vm.payQrCodeParams.createTime,
          minutes: _vm.payQrCodeParams.minutes,
          "pay-qrcode": _vm.payQrCodeParams.payQrCode
        },
        on: { closePayQrcode: _vm.closePayQrcode }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }