var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "upload-container",
      style: {
        width: _vm.autoUpload ? _vm.width + "px" : "",
        height: _vm.autoUpload ? _vm.height + "px" : ""
      }
    },
    [
      _c(
        "el-upload",
        {
          staticClass: "avatar-uploader",
          style: {
            width: _vm.autoUpload ? _vm.width + 2 + "px" : "148px",
            height: _vm.autoUpload ? _vm.height + 2 + "px" : "148px"
          },
          attrs: {
            "auto-upload": false,
            disabled: _vm.disabled,
            accept:
              ".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF",
            action: _vm.action,
            headers: _vm.headers,
            "show-file-list": !_vm.autoUpload,
            "list-type": "picture-card",
            "on-success": _vm.handleSuccess,
            "on-error": _vm.onError,
            "before-upload": _vm.beforeAvatarUpload,
            "on-change": _vm.beforeAvatarUpload
          }
        },
        [
          _vm.cover
            ? _c("div", { staticStyle: { position: "relative" } }, [
                _vm.cover.indexOf("http") >= 0
                  ? _c("img", {
                      staticClass: "avatar",
                      style: {
                        borderRadius: "6px",
                        width: _vm.autoUpload ? _vm.width + "px" : "148px",
                        height: _vm.height + "px"
                      },
                      attrs: { src: _vm.cover }
                    })
                  : _c("img", {
                      staticClass: "avatar",
                      style: {
                        borderRadius: "6px",
                        width: _vm.autoUpload ? _vm.width + "px" : "148px",
                        height: _vm.height + "px"
                      },
                      attrs: { src: _vm.imgSrc + _vm.cover }
                    }),
                _vm._v(" "),
                _vm.isRemove
                  ? _c(
                      "div",
                      {
                        staticClass: "flex_around",
                        staticStyle: {
                          position: "absolute",
                          top: "-10px",
                          right: "-10px"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-delete",
                          staticStyle: { "font-size": "18px", color: "red" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("removeImg")
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ])
            : _c("i", {
                staticClass: "el-icon-plus avatar-uploader-icon",
                style: {
                  width: _vm.autoUpload ? _vm.width + "px" : "148px",
                  height: _vm.autoUpload ? _vm.height + "px" : "148px",
                  lineHeight: _vm.autoUpload ? _vm.height + "px" : "148px"
                }
              })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }