var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              ref: "rightName",
              attrs: { label: "权益名称：", prop: "rightName" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "权益名称"
                },
                model: {
                  value: _vm.ruleForm.rightName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "rightName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.rightName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "rightShort",
              attrs: { label: "权益简称：", prop: "rightShort" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "权益简称"
                },
                model: {
                  value: _vm.ruleForm.rightShort,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "rightShort",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.rightShort"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "rightDesc", attrs: { label: "描述：", prop: "rightDesc" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "描述"
                },
                model: {
                  value: _vm.ruleForm.rightDesc,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "rightDesc",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.rightDesc"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "rightType",
              attrs: { label: "权益类型：", prop: "rightType" }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.rightType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "rightType", $$v)
                    },
                    expression: "ruleForm.rightType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("基础权益")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("专享权益")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "popupImage", attrs: { label: "首页弹窗图片：" } },
            [
              _c("add-load", {
                attrs: {
                  width: 100,
                  height: 130,
                  cover: _vm.ruleForm.popupImage
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "popupImage")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("图片尺寸540*600")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "shareImage", attrs: { label: "代客领取海报：" } },
            [
              _c("add-load", {
                attrs: {
                  width: 100,
                  height: 200,
                  cover: _vm.ruleForm.shareImage
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "shareImage")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.rightType === 2
            ? _c(
                "el-form-item",
                {
                  ref: "rightImage",
                  attrs: { label: " 权益主图：", prop: "rightImage" }
                },
                [
                  _c("add-load", {
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.rightImage
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "rightImage")
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form-item",
                {
                  ref: "rigthUnlockIco",
                  attrs: { label: " 权益标识： ", prop: "rigthUnlockIco" }
                },
                [
                  _c("add-load", {
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.rigthUnlockIco
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "rigthUnlockIco")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("权益标识解锁")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "rightLockIco",
                  staticStyle: { "margin-left": "20px" },
                  attrs: { "label-width": "0", prop: "rightLockIco" }
                },
                [
                  _c("add-load", {
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.rightLockIco
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "rightLockIco")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("权益标识未解锁")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form-item",
                {
                  ref: "validStartTime",
                  attrs: { label: "有效期：", prop: "validStartTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择开始时间"
                    },
                    model: {
                      value: _vm.ruleForm.validStartTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "validStartTime", $$v)
                      },
                      expression: "ruleForm.validStartTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "validEndTime",
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    "label-width": "0",
                    prop: "messageParam.validEndTime"
                  }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      disabled: !_vm.ruleForm.validStartTime,
                      "picker-options": _vm.pickerTime,
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": "23:59:59",
                      placeholder: "选择结束时间"
                    },
                    model: {
                      value: _vm.ruleForm.validEndTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "validEndTime", $$v)
                      },
                      expression: "ruleForm.validEndTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.rightType === 2
            ? _c(
                "el-form-item",
                {
                  ref: "reciveMode",
                  attrs: { label: "投放方式：", prop: "reciveMode" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.ruleForm.reciveMode,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "reciveMode", $$v)
                        },
                        expression: "ruleForm.reciveMode"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1, border: "" } }, [
                        _vm._v("每天")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2, border: "" } }, [
                        _vm._v("每周")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3, border: "" } }, [
                        _vm._v("每月")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 6, border: "" } }, [
                        _vm._v("季度")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 8, border: "" } }, [
                        _vm._v("年度")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 4, border: "" } }, [
                        _vm._v("会员生日")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 5, border: "" } }, [
                        _vm._v("会员生日月")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 7, border: "" } }, [
                        _vm._v("升降级礼品")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "grade", attrs: { label: "会员等级：", prop: "grade" } },
            [
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.changeGrade },
                  model: {
                    value: _vm.ruleForm.grade,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "grade", $$v)
                    },
                    expression: "ruleForm.grade"
                  }
                },
                _vm._l(_vm.gradeList, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.rightType === 2
            ? _c(
                "div",
                _vm._l(_vm.ruleForm.rightItmes, function(item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("el-divider"),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.gradeType(item.rightLevelId) } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "类型：", "label-width": "100px" }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: item.rightItemType,
                                    callback: function($$v) {
                                      _vm.$set(item, "rightItemType", $$v)
                                    },
                                    expression: "item.rightItemType"
                                  }
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("礼品")
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("积分返利")
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 3 } }, [
                                    _vm._v("多倍积分")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          item.rightItemType === 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      ref: "rightItmes." + index + ".giftItems",
                                      refInFor: true,
                                      attrs: {
                                        label: "奖品列表：",
                                        "label-width": "100px"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addGiftItems(index)
                                            }
                                          }
                                        },
                                        [_vm._v("添加奖品\n              ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-table",
                                        {
                                          attrs: {
                                            data: item.giftItems,
                                            size: "mini",
                                            border: ""
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "奖励类型",
                                              "min-width": "100",
                                              align: "center"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          ref:
                                                            "rightItmes." +
                                                            index +
                                                            ".giftItems." +
                                                            scope.$index +
                                                            ".giftType",
                                                          refInFor: true,
                                                          attrs: {
                                                            "label-width": "0",
                                                            prop:
                                                              "rightItmes." +
                                                              index +
                                                              ".giftItems." +
                                                              scope.$index +
                                                              ".giftType",
                                                            required: "",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请选择奖励类型"
                                                              }
                                                            ]
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                sityle:
                                                                  "width: 100%;",
                                                                placeholder:
                                                                  "奖励类型"
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.typeChange(
                                                                    $event,
                                                                    index,
                                                                    scope.$index
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .giftType,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    "giftType",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "scope.row.giftType"
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.type,
                                                              function(item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key:
                                                                      item.value,
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      value:
                                                                        item.value
                                                                    }
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "关联礼品",
                                              "min-width": "200",
                                              align: "center"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      scope.row.giftType > 0
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              ref:
                                                                "rightItmes." +
                                                                index +
                                                                ".giftItems." +
                                                                scope.$index +
                                                                ".giftCode",
                                                              refInFor: true,
                                                              attrs: {
                                                                "label-width":
                                                                  "0",
                                                                prop:
                                                                  "rightItmes." +
                                                                  index +
                                                                  ".giftItems." +
                                                                  scope.$index +
                                                                  ".giftCode",
                                                                required: "",
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请选择奖品"
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type:
                                                                      "primary",
                                                                    size: "mini"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.getAward(
                                                                        scope
                                                                          .row
                                                                          .giftType,
                                                                        index,
                                                                        scope.$index
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .giftType[
                                                                          scope
                                                                            .row
                                                                            .giftType
                                                                        ]
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "奖品名称",
                                              "min-width": "100",
                                              align: "center"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            scope.row
                                                              .giftName || ""
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "奖品名称",
                                              "min-width": "100",
                                              align: "center"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            scope.row
                                                              .giftCode || ""
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "奖品数量",
                                              "min-width": "100",
                                              align: "center"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          ref:
                                                            "rightItmes." +
                                                            index +
                                                            ".giftItems." +
                                                            scope.$index +
                                                            ".num",
                                                          refInFor: true,
                                                          attrs: {
                                                            "label-width": "0",
                                                            prop:
                                                              "rightItmes." +
                                                              index +
                                                              ".giftItems." +
                                                              scope.$index +
                                                              ".num",
                                                            required: "",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请输入奖品数量"
                                                              }
                                                            ]
                                                          }
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              size: "mini",
                                                              type: "number"
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row.num,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  scope.row,
                                                                  "num",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "scope.row.num"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "操作",
                                              width: "100",
                                              align: "center"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "danger",
                                                          staticStyle: {
                                                            cursor: "pointer"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.delAward(
                                                                index,
                                                                scope.$index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("删除")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      ref: "rightItmes." + index + ".choiceNum",
                                      refInFor: true,
                                      attrs: {
                                        label: "限领个数：",
                                        "label-width": "100px"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "form_input",
                                        attrs: {
                                          type: "number",
                                          placeholder: "可领N个"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setNumber($event, index)
                                          }
                                        },
                                        model: {
                                          value: item.choiceNum,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "choiceNum",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "item.choiceNum"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "form_remark" }, [
                                        _vm._v("0为全部可领")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.rightItemType === 3
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      ref:
                                        "rightItmes." + index + ".giftCodeType",
                                      refInFor: true,
                                      attrs: {
                                        label: "返利类型：",
                                        prop:
                                          "rightItmes." +
                                          index +
                                          ".giftCodeType",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请选择返利类型"
                                          }
                                        ],
                                        "label-width": "100px"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: item.giftCodeType,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "giftCodeType",
                                                $$v
                                              )
                                            },
                                            expression: "item.giftCodeType"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "orderUnit" } },
                                            [_vm._v("订单领取")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "dayUnit" } },
                                            [_vm._v("日期领取")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  item.giftCodeType
                                    ? _c(
                                        "el-form-item",
                                        {
                                          ref:
                                            "rightItmes." +
                                            index +
                                            ".giftCodeNum",
                                          refInFor: true,
                                          attrs: {
                                            prop:
                                              "rightItmes." +
                                              index +
                                              ".giftCodeNum",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入数字"
                                              }
                                            ],
                                            label:
                                              item.giftCodeType == "orderUnit"
                                                ? "可领取多少个订单："
                                                : "可领取几天：",
                                            "label-width": "100px"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticClass: "form_input",
                                              attrs: { placeholder: "请输入" },
                                              model: {
                                                value: item.giftCodeNum,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "giftCodeNum",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.giftCodeNum"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.giftCodeType ==
                                                        "orderUnit"
                                                        ? "单"
                                                        : "天"
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "form_remark" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.giftCodeType ==
                                                    "orderUnit"
                                                    ? "用户任意选择周期的任意一笔（或多笔）消费订单，参与多倍积分活动"
                                                    : "用户任意选择周期的任意日期，期间所有消费订单均可支持多倍积分"
                                                ) + "\n               "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.rightItemType === 2 || item.rightItemType === 3
                            ? _c(
                                "el-form-item",
                                {
                                  ref: "rightItmes." + index + ".returnRadio",
                                  refInFor: true,
                                  attrs: {
                                    prop:
                                      "rightItmes." + index + ".returnRadio",
                                    rules: [
                                      { required: true, message: "请输入比例" }
                                    ],
                                    label: "返利比例：",
                                    "label-width": "100px"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "form_input",
                                      attrs: { placeholder: "返利比例" },
                                      model: {
                                        value: item.returnRadio,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "returnRadio",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "item.returnRadio"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("%")
                                      ])
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  item.rightItemType === 2
                                    ? _c("p", { staticClass: "form_remark" }, [
                                        _vm._v(
                                          "次月计算上月获得积分，按比例返利"
                                        )
                                      ])
                                    : _c("p", { staticClass: "form_remark" }, [
                                        _vm._v(
                                          "以订单商品可获得的总积分数为计算基准"
                                        )
                                      ]),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "form_remark",
                                      staticStyle: { color: "red" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              用户消费已经获得一倍积分，多倍积分应减去1倍（例：5倍积分：400%）\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "rightDetail",
              attrs: { prop: "rightDetail", label: "权益介绍：" }
            },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.ruleForm.rightDetail,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "rightDetail", $$v)
                  },
                  expression: "ruleForm.rightDetail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: _vm.popType, "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "view_title" }, [
      _vm._v(" 新增/编辑会员等级\n    "),
      _c(
        "span",
        {
          staticStyle: {
            color: "red",
            "font-size": "16px",
            "margin-left": "10px"
          }
        },
        [_vm._v("新增权益审核后立即生效；权益修改审核后下个周期生效")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }