"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      rules: {
        sellAmount: [{
          required: true,
          message: '请填写会员等级名称'
        }],
        czNum: [{
          required: true,
          message: '请填写成长值'
        }],
        bjNum: [{
          required: true,
          message: '请填写保级值'
        }],
        mainImage: [{
          required: true,
          message: '请上传会员卡图片'
        }]
      },
      form: {
        sellAmount: '',
        czNum: '',
        bjNum: '',
        mainImage: ''
      }
    };
  },
  created: function created() {
    this.form.id = this.$route.query.id;
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (_this.form.id) {} else {}
      });
    },
    setCover: function setCover(e) {
      this.form.mainImage = e;
      this.$refs['form'].validateField('mainImage'); // this.$refs.form.clearValidate('mainImage');
    }
  }
};
exports.default = _default;