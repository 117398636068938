"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _slide = _interopRequireDefault(require("@/components/slide.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default,
    slide: _slide.default
  },
  filters: {
    memberTypeObj: function memberTypeObj(type) {
      var Obj = {
        1: '普通会员',
        2: '要客',
        3: '365'
      };
      return Obj[type];
    }
  },
  data: function data() {
    return {
      isCard: false,
      time: [],
      isRemark: false,
      refundeData: {
        refundAmount: undefined,
        refundMark: null,
        orderNo: null,
        outTradeNo: null,
        moduleSource: null
      },
      rules: {
        refundAmount: [{
          required: true,
          message: '请输入退款金额'
        }],
        refundMark: [{
          required: true,
          message: '请输入退款备注'
        }]
      },
      downloadLoading: false,
      requestData: {
        orderSource: null,
        buyUtphone: '',
        goodsCode: '',
        pageNo: 1,
        pageSize: 10,
        total: 0,
        orderType: 1,
        moduleResource: null,
        moduleSource: '',
        outTradeNo: ''
      },
      refundInfo: null,
      orderSourceSelect: [{
        value: 1,
        label: '米卡系统'
      }, {
        value: 2,
        label: '支付宝小程序'
      }, {
        value: 3,
        label: '平安小程序'
      }, {
        value: 4,
        label: '黔农云支付'
      }, {
        value: 5,
        label: '云闪付'
      }, {
        value: 6,
        label: '一码贵州'
      }, {
        value: 7,
        label: '荔星中心'
      }, {
        value: 10,
        label: '建行生活'
      }],
      orderSource: {
        1: '米卡系统',
        2: '支付宝小程序',
        3: '平安小程序',
        4: '黔农云支付',
        5: '云闪付',
        6: '一码贵州',
        7: '荔星中心',
        10: '建行生活'
      },
      source: {
        1: '周期活动订单',
        2: '付费会员权益领取',
        3: '领券中心',
        4: '米卡新用户',
        5: '特价活动',
        6: '365开卡权益',
        7: '流水返劵',
        8: '浦发信用卡申卡权益',
        9: '购物卡小程序充值活动',
        10: '365会员与签到补发充值卡奖励',
        11: '荔星购物卡后台充值',
        12: '荔星购物卡后台充值推荐奖励',
        13: '特殊365团购码兑换赠送',
        14: '员工节假日福利',
        15: '荔星购物卡蜻蜓充值',
        16: '团购'
      },
      tableData: [],
      columns: [{
        label: '订单号',
        prop: 'orderNo',
        width: 10
      }, {
        label: '对外订单号',
        prop: 'outTradeNo',
        width: 10
      }, {
        label: '流水号',
        prop: 'tradeNo',
        width: 10
      }, {
        label: '购物卡编码',
        prop: 'goodsCode',
        width: 10
      }, {
        // slot: 'orderSource',
        label: '订单来源平台',
        prop: 'orderSourceTxt',
        width: 10
      }, {
        // slot: 'moduleResource',
        label: '营销渠道',
        prop: 'moduleResourceTxt',
        width: 10
      }, {
        label: '卡名称',
        prop: 'goodsName',
        width: 10
      }, {
        label: '会员手机号',
        prop: 'buyUtphone',
        width: 5
      }, {
        label: '会员名称',
        prop: 'memberName',
        width: 15
      }, {
        slot: 'memberType',
        label: '会员类型',
        prop: 'memberType',
        width: 15
      }, {
        label: '会员姓名',
        prop: 'buyUname',
        width: 5
      }, {
        label: '订单金额',
        prop: 'payScoreAmountStr',
        width: 5
      }, {
        label: '订单时间',
        prop: 'orderCreateTime',
        width: 10
      }, {
        slot: 'orderStatus',
        label: '订单状态',
        prop: 'orderStatusTxt'
      }, {
        label: '退款时间',
        prop: 'refundTime',
        width: 5
      }, {
        label: '退款金额',
        prop: 'refundedAmount',
        width: 5
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(value) {
      if (value) {
        this.requestData.startTime = value[0];
        this.requestData.endTime = value[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    },
    isRemark: function isRemark() {
      this.remark = null;
    }
  },
  created: function created() {
    /* if (this.setMenuPerms('qnyOrderSelect')&&!this.$route.query.mobile) {
       this.requestData.orderSource = 1
       this.getList()
     } else {
       this.requestData.orderSource = null
     } */
    if (this.$route.query.mobile) {
      this.requestData.buyUtphone = this.$route.query.mobile;
      this.getList();
    }
  },
  activated: function activated() {// this.getList()
  },
  methods: {
    goUserKpi: function goUserKpi() {
      var _this = this;

      console.log("交易童工");
      this.apiPost('/api/cardTrade/storedCardRefund', this.refundeData).then(function (res) {
        _this.isRemark = false;

        _this.getList();
      });
    },
    popAffrim: function popAffrim() {
      var _this2 = this;

      this.$refs.refundForm.validate(function (valid) {
        if (valid) {
          _this2.isCard = true;
        } else {
          return false;
        }
      });
    },
    getRefund: function getRefund(row) {
      var _this3 = this;

      this.apiPost('/api/cardTrade/getStoreCardSurplusRefundInfo', {
        orderNo: row.orderNo,
        outTradeNo: row.outTradeNo,
        moduleSource: row.moduleSource
      }).then(function (res) {
        _this3.refundInfo = res.data;
        _this3.refundeData.orderNo = row.orderNo;
        _this3.refundeData.outTradeNo = row.outTradeNo;
        _this3.refundeData.moduleSource = row.moduleSource;
        _this3.isRemark = true;
      });
      return;
      this.$nextTick(function () {
        this.orderNo = row.orderNo;
        this.goodsNumber = row.goodsNumber - (row.expiredNum || 0);
        this.refundGoodsNum = row.refundGoodsNum || 0;
      });
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];
        var data = {};

        _this4.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this4.requestData).forEach(function (key) {
          if (_this4.requestData[key]) {
            data[key] = _this4.requestData[key];
          }
        });
        data.pageSize = _this4.requestData.total;
        data.pageNo = 1;
        (0, _api.couponOrderPage)(data).then(function (res) {
          list = res.data.items;
          var orderStatus = {
            2: '已完成',
            4: '已退款'
          };
          list.forEach(function (item) {
            if (!_this4.setMenuPerms('user_mobile_idCard')) {
              if (item.buyUtphone) {
                item.buyUtphone = item.buyUtphone.replace(item.buyUtphone.substring(3, 7), "****");
              }
            }

            item.moduleResourceTxt = _this4.source[item.moduleSource];
            item.orderSourceTxt = _this4.orderSource[item.orderSource];
            item.orderStatusTxt = orderStatus[item.orderStatus];
          });

          var data = _this4.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '购卡'
          });
          _this4.downloadLoading = false;
        }).catch(function (res) {
          _this4.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else if (j === 'memberType') {
            var Obj = {
              1: '普通会员',
              2: '要客',
              3: '365'
            };
            return Obj[v[j]];
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this5 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = [];
        this.removeObj(this.requestData);
      }

      (0, _api.couponOrderPage)(this.requestData).then(function (res) {
        res.data.items.forEach(function (item) {
          if (!_this5.setMenuPerms('user_mobile_idCard')) {
            if (item.buyUtphone) {
              item.buyUtphone = item.buyUtphone.replace(item.buyUtphone.substring(3, 7), "****");
            }
          }

          item.moduleResourceTxt = _this5.source[item.moduleSource];
          item.orderSourceTxt = _this5.orderSource[item.orderSource];
        });
        _this5.tableData = res.data.items;
        _this5.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;