var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n      硬核好券商品\n      "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticStyle: { "max-width": "100%" },
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: {
                  click: function($event) {
                    return _vm.wPage("/member/hardCoupon/view/add")
                  }
                }
              },
              [_vm._v("新增")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { size: "mini", placeholder: "奖励类型" },
                  model: {
                    value: _vm.requestData.productType,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "productType", $$v)
                    },
                    expression: "requestData.productType"
                  }
                },
                _vm._l(_vm.type, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "会员类型"
                  },
                  model: {
                    value: _vm.requestData.vipCode,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "vipCode", $$v)
                    },
                    expression: "requestData.vipCode"
                  }
                },
                _vm._l(_vm.vipList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.vipName, value: item.vipCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "同城分类"
                  },
                  model: {
                    value: _vm.requestData.classId,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "classId", $$v)
                    },
                    expression: "requestData.classId"
                  }
                },
                _vm._l(_vm.classList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.className, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "奖励商品编码" },
                model: {
                  value: _vm.requestData.productCode,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "productCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.productCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.getList("search")
                }
              }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "mini", icon: "el-icon-refresh" },
              on: {
                click: function($event) {
                  return _vm.getList("remove")
                }
              }
            },
            [_vm._v("重置")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "productType",
              label: "奖励类型",
              align: "center",
              width: "80"
            },
            slot: "productType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.productType == 1
                      ? _c("span", [_vm._v("实体商品")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.productType == 2
                      ? _c("span", [_vm._v("直冲商品")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.productType == 3
                      ? _c("span", [_vm._v("优惠券")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "putType",
              label: "投放周期",
              align: "center",
              "min-width": "80"
            },
            slot: "putType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.putType == 1
                      ? _c("span", [_vm._v("单次")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.putType == 2
                      ? _c("span", [_vm._v("按月重复")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "vipType",
              label: "显示到会员",
              align: "center",
              "min-width": "80"
            },
            slot: "vipType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.vipType == 1
                      ? _c("span", [_vm._v("365会员")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.vipType == 2
                      ? _c("span", [_vm._v("MIKA  PLUS")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              align: "center",
              fixed: "right",
              width: "100"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "app-link",
                      {
                        staticStyle: { color: "rgb(24, 144, 255)" },
                        attrs: {
                          to: "/member/hardCoupon/view/add?id=" + scope.row.id
                        }
                      },
                      [_vm._v("\n            编辑\n          ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }