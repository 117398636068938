"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _report = require("@/api/report.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  filters: {
    memberTypeObj: function memberTypeObj(type) {
      var Obj = {
        1: '普通会员',
        2: '要客',
        3: '365'
      };
      return Obj[type];
    }
  },
  data: function data() {
    return {
      downloadLoading: false,
      time: null,
      requestData: {
        startDate: null,
        endDate: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '订单号',
        prop: 'orderNo',
        width: 80
      }, {
        label: '微信订单号',
        prop: 'tradeNo',
        width: 80
      }, {
        label: '优惠券编码',
        prop: 'couponCode',
        width: 80
      }, {
        label: '订单时间',
        prop: 'orderTime',
        width: 80
      }, {
        label: '车牌号',
        prop: 'carNo',
        width: 50
      }, {
        label: '停车单价',
        prop: 'freePrice',
        width: 50
      }, {
        label: '总金额',
        prop: 'totalAmount',
        width: 50
      }, {
        label: '使用积分',
        prop: 'totalScore',
        width: 40
      }, {
        slot: 'exchangeType',
        label: '兑换类型',
        prop: 'exchangeType',
        width: 80
      }, {
        label: '兑换小时',
        prop: 'exchangeHour',
        width: 80
      }, {
        label: '兑换金额',
        prop: 'exchangeAmount',
        width: 80
      }, {
        label: '微信支付金额',
        prop: 'surplusAmount',
        width: 80
      }, {
        label: '用户手机号',
        prop: 'userPhone',
        width: 80
      }, {
        label: '会员名称',
        prop: 'memberName',
        width: 80
      }, {
        slot: 'memberType',
        label: '会员类型',
        prop: 'memberType',
        width: 15
      }, {
        slot: 'payMode',
        label: '支付方式',
        prop: 'payMode'
      }, {
        slot: 'payStatus',
        label: '订单状态',
        prop: 'payStatus'
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startDate = val[0];
        this.requestData.endDate = val[1];
      } else {
        this.requestData.startDate = null;
        this.requestData.endDate = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {// this.getList()
  },
  methods: {
    goAudit: function goAudit(row, status) {
      var _this = this;

      goExam((0, _defineProperty2.default)({
        id: row.id,
        status: status
      }, "status", status)).then(function (res) {
        _this.getList();
      });
    },
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getQrcode: function getQrcode(scope) {
      var _this2 = this;

      // 获取小程序码
      this.couponUUid = scope.name;
      couponQRCode({
        path: 'activity/apply/dea',
        scene: String(scope.id)
      }).then(function (res) {
        _this2.url = 'activity/apply/dea?id=' + scope.id;
        _this2.qrCode = res.data;
        _this2.popShow = true;
      });
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this3.requestData[key]) {
          data[key] = _this3.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _report.listOrderCouponPage)(data).then(function (res) {
        res.data.records.forEach(function (item) {
          if (!_this3.setMenuPerms('user_mobile_idCard')) {
            if (item.userPhone) {
              item.userPhone = item.userPhone.replace(item.userPhone.substring(3, 7), "****");
            }
          }

          item.exchangeHour = item.exchangeAmount / 6;
        });
        list = res.data.records;
        _this3.downloadLoading = false;

        _this3.getHandleDownload(tHeader, filterVal, list, '停车订单列表');
      }).catch(function (res) {
        _this3.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this4 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _report.listOrderCouponPage)(this.requestData).then(function (res) {
        res.data.records.forEach(function (item) {
          item.exchangeHour = item.exchangeAmount / 6;

          if (!_this4.setMenuPerms('user_mobile_idCard')) {
            if (item.userPhone) {
              item.userPhone = item.userPhone.replace(item.userPhone.substring(3, 7), "****");
            }
          }
        });
        _this4.tableData = res.data.records;
        _this4.requestData.total = res.data.total;
      });
    }
  }
};
exports.default = _default;