"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      orientObj: {},
      list: [],
      title: '新增优惠券包'
    };
  },
  created: function created() {
    this.orientObj = JSON.parse(sessionStorage.getItem('orientObj'));
    console.log(this.orientObj);
    this.init();
  },
  methods: {
    init: function init() {
      this.id = Number(this.$route.query.id);
    },
    next: function next() {
      var _this = this;

      var rLoading = this.openLoading(); // this.orientObj.couponIds=this.orientObj.couponIds.split("\n")

      if (this.id) {
        this.orientObj.id = this.id;
        (0, _coupon.editCouponBag)(this.orientObj).then(function (res) {
          console.log(res);

          _this.$message({
            message: '恭喜你，编辑成功',
            type: 'success'
          });

          rLoading.close();

          _this.$router.push('/coupon/coupon1/couponGift');
        });
      } else {
        (0, _coupon.addCouponBag)(this.orientObj).then(function (res) {
          console.log(res);

          _this.$message({
            message: '恭喜你，添加成功',
            type: 'success'
          });

          rLoading.close();

          _this.$router.push('/coupon/coupon1/couponGift');
        });
      }
    }
  }
};
exports.default = _default;