var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rebate-from" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(_vm.$route.meta.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { model: _vm.params, "label-width": "180px" }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "widthCustom", attrs: { label: "支付方式名称" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "input-tip" }, [
                _vm._v("卡类型关联积分规则和活动信息等")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.params.status,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "status", $$v)
                    },
                    expression: "params.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "启用" } }),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "禁用" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "返利标准" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    size: "mini",
                    icon: "el-icon-plus"
                  },
                  on: { click: _vm.onAddRow }
                },
                [_vm._v("添加返利层级")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "10px" },
                  attrs: {
                    data: _vm.params.list,
                    border: "",
                    "header-cell-style": { background: "#f5f5f5" },
                    size: "mini"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "层级",
                      width: "80",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "支付金额高于(<元)",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      label: "支付金额低于(≤元)",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      label: "返利比例(%)",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var $index = ref.$index
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "orange" },
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.onDeleteRow($index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [
              _c("el-input", {
                staticStyle: { width: "80%" },
                attrs: { type: "textarea", autosize: { minRows: 5 } },
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "actions" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }