var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    " + _vm._s(this.$route.meta.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            size: "small",
            rules: _vm.rules,
            "label-width": "150px"
          }
        },
        [
          _vm._l(_vm.fields, function(item, index) {
            return _c(
              "div",
              { key: index },
              [
                item.slot
                  ? _c(
                      "div",
                      [
                        item.slot == "pricingRulesBeanList" &&
                        _vm.form.preferentialScheme == 1
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "form_item",
                                attrs: {
                                  label: item.label,
                                  prop: item.value,
                                  rules: item.rules
                                }
                              },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    staticClass: "form_horizontal_group",
                                    model: {
                                      value: _vm.form[item.value],
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, item.value, $$v)
                                      },
                                      expression: "form[item.value]"
                                    }
                                  },
                                  _vm._l(_vm.preferentialOption, function(
                                    pItem,
                                    pIndex
                                  ) {
                                    return _c("div", { key: pIndex }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form_horizontal_checkbox",
                                          attrs: { isfirst: pIndex === 0 }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form_horizontal_checkbox_item"
                                            },
                                            [
                                              _c(
                                                "el-checkbox",
                                                { attrs: { label: pItem.key } },
                                                [_vm._v(_vm._s(pItem.title))]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form_horizontal_checkbox_item"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("累计订单金额")
                                              ]),
                                              _vm._v(" "),
                                              _c("el-input-number", {
                                                attrs: {
                                                  min: 0,
                                                  disabled: !_vm.form[
                                                    item.value
                                                  ].includes(pItem.key),
                                                  controls: false
                                                },
                                                model: {
                                                  value:
                                                    pItem.accumulatedAmount,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      pItem,
                                                      "accumulatedAmount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "pItem.accumulatedAmount"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("元")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form_horizontal_checkbox_item"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("减免停车费")
                                              ]),
                                              _vm._v(" "),
                                              _c("el-input-number", {
                                                attrs: {
                                                  min: 0,
                                                  disabled: !_vm.form[
                                                    item.value
                                                  ].includes(pItem.key),
                                                  controls: false
                                                },
                                                model: {
                                                  value: pItem.freeAmount,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      pItem,
                                                      "freeAmount",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "pItem.freeAmount"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("元")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form_horizontal_checkbox_item"
                                            },
                                            [
                                              _c("span", [_vm._v("超出计价")]),
                                              _vm._v(" "),
                                              _c("el-input-number", {
                                                attrs: {
                                                  min: 0,
                                                  disabled: !_vm.form[
                                                    item.value
                                                  ].includes(pItem.key),
                                                  controls: false
                                                },
                                                model: {
                                                  value: pItem.overstepAmount,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      pItem,
                                                      "overstepAmount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "pItem.overstepAmount"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("元/小时")])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.slot == "scoreRulesBeanList" &&
                        _vm.form.preferentialScheme == 2
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "form_item",
                                attrs: {
                                  label: item.label,
                                  prop: item.value,
                                  rules: item.rules
                                }
                              },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    staticClass: "form_horizontal_group",
                                    model: {
                                      value: _vm.form[item.value],
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, item.value, $$v)
                                      },
                                      expression: "form[item.value]"
                                    }
                                  },
                                  _vm._l(_vm.preferentialOption, function(
                                    pItem,
                                    pIndex
                                  ) {
                                    return _c("div", { key: pIndex }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form_horizontal_checkbox",
                                          attrs: { isfirst: pIndex === 0 }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form_horizontal_checkbox_item"
                                            },
                                            [
                                              _c(
                                                "el-checkbox",
                                                { attrs: { label: pItem.key } },
                                                [_vm._v(_vm._s(pItem.title))]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form_horizontal_checkbox_item"
                                            },
                                            [
                                              _c("span", [_vm._v("优惠规则")]),
                                              _vm._v(" "),
                                              _c("el-input-number", {
                                                attrs: {
                                                  min: 1,
                                                  disabled: !_vm.form[
                                                    item.value
                                                  ].includes(pItem.key),
                                                  controls: false
                                                },
                                                model: {
                                                  value: pItem.disAmount,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      pItem,
                                                      "disAmount",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "pItem.disAmount"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("元/小时")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form_horizontal_checkbox_item"
                                            },
                                            [
                                              _c("span", [_vm._v("单日限制")]),
                                              _vm._v(" "),
                                              _c("el-input-number", {
                                                attrs: {
                                                  min: 0,
                                                  disabled: !_vm.form[
                                                    item.value
                                                  ].includes(pItem.key),
                                                  controls: false
                                                },
                                                model: {
                                                  value: pItem.limitationTime,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      pItem,
                                                      "limitationTime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "pItem.limitationTime"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("小时")])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c(
                      "el-form-item",
                      {
                        staticClass: "form_item",
                        attrs: {
                          label: item.label,
                          prop: item.value,
                          rules: item.rules
                        }
                      },
                      [
                        item.slot === "storeCode"
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  placeholder: _vm._f("placeholderLabel")(
                                    item.placeholderLabel || item.label
                                  )
                                },
                                model: {
                                  value: _vm.form[item.value],
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, item.value, $$v)
                                  },
                                  expression: "form[item.value]"
                                }
                              },
                              _vm._l(_vm.storeOptions, function(so, sindex) {
                                return _c(
                                  "el-option",
                                  {
                                    key: sindex,
                                    attrs: {
                                      value: so.storeCode,
                                      label: so.fullName
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { float: "left" } },
                                      [_vm._v(_vm._s(so.fullName))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          color: "#8492a6",
                                          "font-size": "13px"
                                        }
                                      },
                                      [_vm._v(_vm._s(so.storeCode))]
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          : item.type === "tinymce"
                          ? _c("Tinymce", {
                              key: _vm.TinymceKey,
                              staticStyle: { width: "700px" },
                              model: {
                                value: _vm.form[item.value],
                                callback: function($$v) {
                                  _vm.$set(_vm.form, item.value, $$v)
                                },
                                expression: "form[item.value]"
                              }
                            })
                          : item.type === "image"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-upload",
                                  {
                                    attrs: {
                                      action: _vm.uploadConfig.URL,
                                      headers: _vm.uploadConfig.HEADERS,
                                      "show-file-list": false,
                                      "on-success": _vm.onUploadSuccess,
                                      "before-upload": _vm.onUploadBefore
                                    }
                                  },
                                  [
                                    _vm.form[item.value]
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "form_upload_item",
                                            on: {
                                              click: function($event) {
                                                _vm.uploadConfig.field =
                                                  item.value
                                              }
                                            }
                                          },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src:
                                                  _vm.uploadConfig.SRCPREFIX +
                                                  _vm.form[item.value]
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form_upload_item icon",
                                            on: {
                                              click: function($event) {
                                                _vm.uploadConfig.field =
                                                  item.value
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-plus"
                                            })
                                          ]
                                        )
                                  ]
                                )
                              ],
                              1
                            )
                          : item.type === "radio"
                          ? _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.form[item.value],
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, item.value, $$v)
                                  },
                                  expression: "form[item.value]"
                                }
                              },
                              _vm._l(item.select, function(radio) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: radio.value,
                                    attrs: { label: radio.value }
                                  },
                                  [_vm._v(_vm._s(radio.label))]
                                )
                              }),
                              1
                            )
                          : item.type === "number"
                          ? _c("el-input-number", {
                              attrs: {
                                placeholder: _vm._f("placeholderLabel")(
                                  item.placeholderLabel || item.label
                                ),
                                controls: false
                              },
                              model: {
                                value: _vm.form[item.value],
                                callback: function($$v) {
                                  _vm.$set(_vm.form, item.value, $$v)
                                },
                                expression: "form[item.value]"
                              }
                            })
                          : _c("el-input", {
                              attrs: {
                                disabled:
                                  item.editDis && _vm.form.id ? true : false,
                                placeholder: _vm._f("placeholderLabel")(
                                  item.placeholderLabel || item.label
                                )
                              },
                              model: {
                                value: _vm.form[item.value],
                                callback: function($$v) {
                                  _vm.$set(_vm.form, item.value, $$v)
                                },
                                expression: "form[item.value]"
                              }
                            }),
                        _vm._v(" "),
                        _c("div", { staticClass: "form_item_tip" }, [
                          _vm._v(_vm._s(item.tip))
                        ])
                      ],
                      1
                    )
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSave } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }