var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "keep-alive",
        [
          _c(
            "w-table",
            {
              ref: "wTable",
              attrs: {
                size: "mini",
                "search-data": _vm.searchData,
                mobile: ["userMobile"]
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  slot: "status",
                  label: "状态",
                  align: "center",
                  width: "80"
                },
                slot: "status",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status === 1
                          ? _c("span", { staticClass: "danger" }, [
                              _vm._v("未兑换")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 2
                          ? _c("span", { staticClass: "success" }, [
                              _vm._v("已兑换")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "exchangeType",
                  label: "兑换方式",
                  align: "center",
                  width: "100"
                },
                slot: "exchangeType",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.exchangeType === 1
                          ? _c("span", { staticClass: "warning" }, [
                              _vm._v("兑换码")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.exchangeType === 2
                          ? _c("span", { staticClass: "success" }, [
                              _vm._v("独立二维码")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.exchangeType === 3
                          ? _c("span", { staticClass: "danger" }, [
                              _vm._v("统一二维码")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.exchangeType === 4
                          ? _c("span", [_vm._v("自动充值")])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "isRebate",
                  label: "返利状态",
                  align: "center",
                  width: "80"
                },
                slot: "isRebate",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.isRebate === 1
                          ? _c("span", { staticClass: "danger" }, [
                              _vm._v("未返利")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.isRebate === 2
                          ? _c("span", { staticClass: "success" }, [
                              _vm._v("已返利")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }