var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("页面栏目管理")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "listTable" },
      [
        _c(
          "div",
          { staticClass: "column_btn" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "has",
                    rawName: "v-has",
                    value: "columnItem:add",
                    expression: "'columnItem:add'"
                  }
                ],
                attrs: { type: "primary" },
                on: { click: _vm.goAdd }
              },
              [_vm._v("新增列表")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("table-list", {
          attrs: {
            "dea-path": _vm.deaPath,
            "table-data": _vm.list,
            "tab-th": _vm.tabTh,
            total: _vm.total,
            "page-no": _vm.pageNo,
            "page-size": _vm.pageSize,
            btn: _vm.btn,
            "btn-type": _vm.btnType,
            operation: _vm.operation,
            "btn-show": "columnItem:edit"
          },
          on: { getList: _vm.getList, setBtn: _vm.setBtn }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }