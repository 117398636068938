var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          attrs: {
            size: "mini",
            "search-data": _vm.searchData,
            isDown: false,
            mobile: ["userMobile"]
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "100"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === "received"
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("\n          已领取\n        ")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === "used"
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("\n          已使用\n        ")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === "expired"
                      ? _c("span", [_vm._v("\n          已过期\n        ")])
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }