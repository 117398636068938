"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '优惠券导出管理',
      list: [{
        tit: '导出记录',
        txtArr: '<p>导出管理-导出记录</p>    ',
        imgPath: require('@/assets/tab/1.png'),
        path: '/couponExternal/list'
      }, {
        tit: '兑换记录',
        txtArr: '<p>导出管理-兑换记录</p>',
        imgPath: require('@/assets/tab/2.png'),
        path: '/couponExternal/exchange'
      }]
    };
  }
};
exports.default = _default;