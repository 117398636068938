"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '用户手机号码',
        prop: 'mobile',
        width: 80
      }, {
        slot: 'contextType'
      }, {
        label: '发送内容',
        prop: 'context',
        align: 'left',
        width: 200
      }, {
        label: '返回结果',
        prop: 'result',
        align: 'left',
        width: 200
      }, {
        label: '发送时间',
        prop: 'createTime',
        width: 80
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '发送内容',
        value: 'context'
      }, {
        type: 'input',
        span: 4,
        placeholder: '手机号码',
        value: 'mobile'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '信息内容',
        value: 'contextType',
        select: [{
          label: '短信模板',
          value: 1
        }, {
          label: '企微消息模板',
          value: 2
        }, {
          label: '彩信模板',
          value: 3
        }, {
          label: '自定义',
          value: 4
        }]
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }]
    };
  },
  computed: {},
  mounted: function mounted() {
    var storeId = this.$route.query.storeId;

    if (storeId) {
      this.$refs.wTable.setRequestValue('refNo', storeId);
    }

    this.$refs.wTable.getList();
  },
  methods: {}
};
exports.default = _default;