var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    核销助手核销优惠券\n    "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: {
                  click: function($event) {
                    _vm.isAddQrcode = true
                  }
                }
              },
              [_vm._v("生成核销码\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { inline: "", size: "mini" } },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "券编码" },
                model: {
                  value: _vm.requestData.couponCode,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "couponCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.couponCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  staticStyle: { width: "100%" },
                  attrs: { size: "mini", clearable: "", placeholder: "券类型" },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.requestData.couponType,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "couponType", $$v)
                    },
                    expression: "requestData.couponType"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "代金券", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "折扣券", value: 2 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "礼品券", value: 3 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "体验券", value: 4 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "外部券", value: 5 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "订单类型"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.requestData.type,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "type", $$v)
                    },
                    expression: "requestData.type"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "不回传订单", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "回传订单", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    placeholder: "所属门店",
                    filterable: "",
                    autocomplete: "off"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.requestData.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "storeId", $$v)
                    },
                    expression: "requestData.storeId"
                  }
                },
                _vm._l(_vm.storeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.storeId,
                      attrs: {
                        label: item.shortName + "（" + item.storeCode + "）",
                        value: item.storeCode
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "核销人姓名" },
                model: {
                  value: _vm.requestData.uname,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "uname",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.uname"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "核销人手机号" },
                model: {
                  value: _vm.requestData.umobile,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "umobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.umobile"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "会员手机号" },
                model: {
                  value: _vm.requestData.userMobile,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "userMobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.userMobile"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": "00:00:00",
                  type: "datetime",
                  placeholder: "开始时间"
                },
                model: {
                  value: _vm.requestData.startTime,
                  callback: function($$v) {
                    _vm.$set(_vm.requestData, "startTime", $$v)
                  },
                  expression: "requestData.startTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": "23:59:59",
                  type: "datetime",
                  placeholder: "结束时间"
                },
                model: {
                  value: _vm.requestData.endTime,
                  callback: function($$v) {
                    _vm.$set(_vm.requestData, "endTime", $$v)
                  },
                  expression: "requestData.endTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.getList("search")
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "info",
                    size: "mini",
                    icon: "el-icon-refresh"
                  },
                  on: {
                    click: function($event) {
                      return _vm.getList("remove")
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c("w-table", {
        attrs: {
          "table-data": _vm.tableData,
          columns: _vm.columns,
          "page-obj": _vm.requestData
        },
        on: { pagination: _vm.setPage }
      }),
      _vm._v(" "),
      _vm.isAddQrcode
        ? _c(
            "div",
            { staticClass: "fixed_center" },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex_between",
                      staticStyle: { "align-items": "center" },
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", { staticClass: "view_title" }, [
                        _vm._v("生成核销码")
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-close danger",
                        staticStyle: { "font-size": "20px", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            _vm.isAddQrcode = false
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("WForm", {
                    attrs: {
                      isTitle: false,
                      "form-data": _vm.formData,
                      "label-width": "120px",
                      "rule-form": _vm.ruleForm,
                      rules: _vm.rules,
                      isHttps: ""
                    },
                    on: { submitForm: _vm.submitForm }
                  }),
                  _vm._v(" "),
                  _vm.qrcodeData
                    ? _c(
                        "div",
                        { staticClass: "flex_center" },
                        [
                          _c("vue-qr", {
                            attrs: { text: _vm.qrcodeData, size: 250 }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }