var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "parking" },
    [
      _c(
        "w-table",
        { attrs: { isPage: false } },
        [
          _c("el-table-column", {
            attrs: { slot: "photo", label: "图片", align: "center" },
            slot: "photo",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-image", {
                      staticClass: "photo",
                      attrs: { src: _vm.SRCPREFIX + row.photo }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }