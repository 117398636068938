"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.search");

var _api = _interopRequireDefault(require("./api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      query: {},
      list: [],
      cardOptions: [{
        text: '购物卡券100',
        value: 2
      }, {
        text: '购物卡券200',
        value: 3
      }],
      tableData: [{
        id: 1,
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金'
      }, {
        id: 10,
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路'
      }, {
        id: 11,
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路'
      }, {
        id: 15,
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路'
      }],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageTotal: 400
      }
    };
  },
  methods: {
    onReset: function onReset() {
      this.query = {};
    },
    onCreate: function onCreate() {
      this.$router.push({
        path: '/deposit/deposit-entity-init/create'
      });
    },
    onSearch: function onSearch() {
      this.list = _api.default.search(this.query, this.pagination);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.onSearch();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.onSearch();
    }
  }
};
exports.default = _default;