"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _deposit = require("@/api/deposit");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      id: '',
      active: -1,
      btnType: 0,
      step: [{
        tit: '新建储值卡',
        txt: ''
      }, {
        tit: '审核储值卡',
        txt: ''
      }],
      classId: '',
      imgSrc: this.$store.state.user.imgSrc,
      cover: '',
      description: '',
      name: '',
      className: '',
      number: '',
      rechargeRmount: '',
      ifRechargeAmount: '',
      effMembers: [],
      descrition: '',
      putTime: '',
      tiggType: '',
      tiggValue: '',
      putNumber: '',
      restrictNumber: '',
      status: '',
      remark: '',
      scopeType: null,
      category: null,
      type: null,
      cardData: {},
      categoryObj: {
        1: '正常销售卡',
        2: '赠送卡',
        3: '荔星购物卡'
      }
    };
  },
  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    goEditor: function goEditor() {
      this.$router.push('/deposit/onLine/addCard?id=' + this.id);
    },
    setActive: function setActive(index) {
      console.log(index);

      switch (index) {
        case 1:
          this.status = '待审核';
          this.active = index;
          this.btnType = 1;
          break;

        case 2:
          this.status = '已审核';
          this.active = 2;
          this.btnType = 2;
          break;

        case 3:
          this.status = '审核撤回';
          this.active = 1;
          this.btnType = 1;
          break;

        case 4:
          this.status = '审核撤销';
          this.active = -1;
          this.btnType = 0;
          break;

        case 5:
          this.status = '已过期';
          this.active = 2;
          this.btnType = 0;
          break;

        case 6:
          this.status = '已终止';
          this.active = -1;
          this.btnType = 0;
          break;

        case 8:
          this.status = '已投放';
          this.active = 2;
          this.btnType = 2;
          break;

        case 9:
          this.status = '投放完成';
          this.active = 3;
          this.btnType = 0;
          break;
      }
    },
    init: function init() {
      var _this = this;

      this.id = Number(this.$route.query.id);
      this.openLoading();
      (0, _deposit.getCardStoredById)(this.id).then(function (res) {
        _this.openLoading().close();

        res.data.cardStoredOprLogs.forEach(function (value) {});
        res.data.goodsIds = res.data.goodsIds ? JSON.parse(res.data.goodsIds) : [];
        _this.cardData = res.data;
        _this.scopeType = res.data.scopeType;
        var cardStoredOprLogs = res.data.cardStoredOprLogs;
        _this.step[0].txt = cardStoredOprLogs[0].oprUserName + cardStoredOprLogs[0].oprTime;

        for (var i = 0; i < cardStoredOprLogs.length; i++) {
          if (cardStoredOprLogs[i].oprType === 2) {
            _this.step[1].txt = cardStoredOprLogs[i].oprUserName + cardStoredOprLogs[i].oprTime;
          }
        }

        _this.type = res.data.type;
        _this.classId = res.data.classId;
        _this.category = res.data.category;
        _this.description = res.data.description;
        _this.name = res.data.name;
        _this.className = res.data.className;

        if (res.data.number === 0) {
          _this.number = '不限制';
        } else {
          _this.number = res.data.number;
        }

        _this.rechargeRmount = JSON.parse(res.data.rechargeAmount);
        _this.cover = _this.imgSrc + res.data.cover;
        _this.ifRechargeAmount = res.data.ifRechargeAmount; // console.log(this.cover)

        /* if (res.data.ifRechargeAmount === '-1') {
           this.ifRechargeAmount = '不可以'
         } else {
           this.ifRechargeAmount = '可以'
         } */

        _this.effMembers = res.data.institutionNames;
        console.log(res.data.status);
        _this.remark = res.data.remark;

        _this.setActive(res.data.status);
      }).catch(function (res) {
        _this.openLoading().close();
      });
    },
    removeStatus: function removeStatus(index) {
      var _this2 = this;

      this.$confirm('此操作将撤销方案， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _deposit.editCardStoredStatus)(_this2.id, index).then(function (res) {
          console.log(res);

          _this2.$message({
            type: 'success',
            message: '撤销成功!'
          });

          _this2.setActive(index);
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消4546'
        });
      });
    },
    setStatus: function setStatus(index) {
      var _this3 = this;

      // 通过审核
      var str = '';

      if (index === 2) {
        str = '此操作将通过审核，将不可修改, 是否继续?';
      } else if (index === 3) {
        str = '此操作将撤回审核， 是否继续?';
      } else if (index === 4) {
        str = '此操作将撤销审核，撤销通过后将不可修改， 是否继续?';
      }

      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _deposit.editCardStoredStatus)(_this3.id, index).then(function (res) {
          if (res.ret === 1000) {
            _this3.$message({
              type: 'success',
              message: '审核成功!'
            });

            _this3.setActive(index);
          }
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消撤销'
        });
      });
    }
  }
};
exports.default = _default;