var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-detail" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(this.$route.meta.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-descriptions",
        { attrs: { border: "" } },
        [
          _c("el-descriptions-item", { attrs: { label: "订单号" } }, [
            _vm._v(_vm._s(_vm.info.orderNo || "-"))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
            _vm._v(_vm._s(_vm.info.mobile))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "会员类型" } }, [
            _vm._v(_vm._s(_vm.memberType[_vm.info.memberType]))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "所属门店" } }, [
            _vm._v(_vm._s(_vm.info.storeName) + " ")
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "用户昵称" } }, [
            _vm._v(_vm._s(_vm.info.realname))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "总停车时长" } }, [
            _vm._v(_vm._s(_vm.info.elapsedTime))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "入场时间" } }, [
            _vm._v(_vm._s(_vm.info.entryTime))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "出场时间" } }, [
            _vm._v(_vm._s(_vm.info.outTime))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "订单金额" } }, [
            _vm._v(_vm._s(_vm.info.payable))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "消费优惠" } }, [
            _vm._v(_vm._s(_vm.info.consumeDisAmount || "-"))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "积分抵扣" } }, [
            _vm._v(_vm._s(_vm.info.scoreAmount || "-"))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "购物卡支付" } }, [
            _vm._v(_vm._s(_vm.info.storeCardAmount || "-"))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "会员折扣" } }, [
            _vm._v(_vm._s(_vm.info.memberDisAmount || "-"))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "会员优惠" } }, [
            _vm._v(_vm._s(_vm.info.stopAmount || "-"))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "微信支付" } }, [
            _vm._v(_vm._s(_vm.info.wxAmount || "-"))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "支付时间" } }, [
            _vm._v(_vm._s(_vm.info.outTime))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-top": "50px" } }, [
        _vm._v("\n        当日消费订单\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, border: "", size: "mini" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "orderNo", label: "相关单号" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "institutionCode", label: "门店编号" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "institutionName", label: "门店名称" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "facilityCode", label: "终端设备号" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "amountPayable", label: "消费金额" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "amountDiscount", label: "优惠金额" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "payTime", label: "支付时间" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "buyUname", label: "购买人" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "buyUtphone", label: "购买电话" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-top": "50px" } }, [
        _vm._v("\n        购物小票\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _vm.info.shoppingReceipt
        ? _c(
            "div",
            { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
            _vm._l(_vm.images, function(img) {
              return _c("el-image", {
                key: img,
                staticStyle: {
                  width: "200px",
                  height: "200px",
                  "border-radius": "10px",
                  margin: "10px"
                },
                attrs: { src: img, "preview-src-list": _vm.images }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }