var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addCard" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.obj,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "储值卡分类：", prop: "classId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.obj.classId,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "classId", $$v)
                            },
                            expression: "obj.classId"
                          }
                        },
                        _vm._l(_vm.options, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "购物卡类型 ：", prop: "category" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.cardTypeChange },
                          model: {
                            value: _vm.obj.type,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "type", $$v)
                            },
                            expression: "obj.type"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.obj.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.obj, "type", $$v)
                                },
                                expression: "obj.type"
                              }
                            },
                            [_vm._v("线上购物卡")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 7 },
                              model: {
                                value: _vm.obj.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.obj, "type", $$v)
                                },
                                expression: "obj.type"
                              }
                            },
                            [_vm._v("红包")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "卡回传分类 ：", prop: "category" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.obj.category,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "category", $$v)
                            },
                            expression: "obj.category"
                          }
                        },
                        [_vm._v("正常销售卡")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.obj.category,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "category", $$v)
                            },
                            expression: "obj.category"
                          }
                        },
                        [_vm._v("赠送卡")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.obj.category,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "category", $$v)
                            },
                            expression: "obj.category"
                          }
                        },
                        [_vm._v("荔星购物卡")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "储值卡名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        model: {
                          value: _vm.obj.name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.obj,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "obj.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "一句话文案：", prop: "description" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        model: {
                          value: _vm.obj.description,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.obj,
                              "description",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "obj.description"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "remark" }, [
                        _vm._v("简短的一句话文案(24字以内)")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发行数量：", prop: "number" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        model: {
                          value: _vm.obj.number,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.obj,
                              "number",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "obj.number"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "remark" }, [
                        _vm._v("发行数量，填写0为不限数量")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "可输入其他金额：",
                        prop: "ifRechargeAmount"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.obj.ifRechargeAmount,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "ifRechargeAmount", $$v)
                            },
                            expression: "obj.ifRechargeAmount"
                          }
                        },
                        [_vm._v("是")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "-1" },
                          model: {
                            value: _vm.obj.ifRechargeAmount,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "ifRechargeAmount", $$v)
                            },
                            expression: "obj.ifRechargeAmount"
                          }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否支持转赠", prop: "ifDonation" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.obj.ifDonation,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "ifDonation", $$v)
                            },
                            expression: "obj.ifDonation"
                          }
                        },
                        [_vm._v("不支持转赠")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.obj.ifDonation,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "ifDonation", $$v)
                            },
                            expression: "obj.ifDonation"
                          }
                        },
                        [_vm._v("支持转赠")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "可充值金额：", prop: "rechargeRmount" }
                    },
                    [
                      _vm._l(_vm.obj.rechargeRmount, function(tag) {
                        return _c(
                          "el-tag",
                          {
                            key: tag,
                            staticStyle: { "margin-right": "8px" },
                            attrs: {
                              closable: "",
                              "disable-transitions": false
                            },
                            on: {
                              close: function($event) {
                                return _vm.handleClose(tag)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " + _vm._s(tag) + "\n          "
                            )
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm.inputVisible
                        ? _c("el-input", {
                            ref: "saveTagInput",
                            staticClass: "input-new-tag",
                            staticStyle: { width: "100px" },
                            attrs: { type: "number", size: "small" },
                            on: { blur: _vm.handleInputConfirm },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleInputConfirm($event)
                              }
                            },
                            model: {
                              value: _vm.inputValue,
                              callback: function($$v) {
                                _vm.inputValue =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "inputValue"
                            }
                          })
                        : _vm.obj.rechargeRmount.length < 5
                        ? _c(
                            "el-button",
                            {
                              staticClass: "button-new-tag",
                              attrs: { size: "small" },
                              on: { click: _vm.showInput }
                            },
                            [_vm._v("添加\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", { staticClass: "remark" }, [
                        _vm._v(
                          " 可充值金额最多为5个固定金额+用户自选金额（1000以内） "
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "储值卡图案：", prop: "cover" } },
                    [
                      _c("add-load", {
                        attrs: { width: 150, height: 91, cover: _vm.obj.cover },
                        on: { setCover: _vm.setCover }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "remark" }, [
                        _vm._v(" 图片尺寸：900px * 550px ")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "储值卡卡面：", prop: "album" } },
                    [
                      _c(
                        "div",
                        { staticClass: "vendor_img flex" },
                        [
                          _vm._l(_vm.obj.album, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "up_img" },
                              [
                                _c("add-load", {
                                  attrs: {
                                    width: 150,
                                    height: 91,
                                    cover: item.image
                                  },
                                  on: {
                                    setCover: function($event) {
                                      return _vm.setAlbum($event, index)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "close flex_center",
                                    on: {
                                      click: function($event) {
                                        return _vm.delAlbum(index)
                                      }
                                    }
                                  },
                                  [_vm._v("x")]
                                )
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c("add-load", {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { width: 91, height: 91 },
                            on: {
                              setCover: function($event) {
                                return _vm.setAlbum(
                                  $event,
                                  _vm.obj.album.length
                                )
                              }
                            }
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "remark" }, [
                        _vm._v(" 图片尺寸：710px * 420px ")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "适用范围：", prop: "scopeType" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.obj.type == 7 },
                  model: {
                    value: _vm.obj.scopeType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "scopeType", $$v)
                    },
                    expression: "obj.scopeType"
                  }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.obj.scopeType,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "scopeType", $$v)
                        },
                        expression: "obj.scopeType"
                      }
                    },
                    [_vm._v("全部适用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.obj.scopeType,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "scopeType", $$v)
                        },
                        expression: "obj.scopeType"
                      }
                    },
                    [_vm._v("选择会员体系")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.obj.scopeType == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "会员机构：", prop: "institutionIds" } },
                [
                  _c(
                    "div",
                    { staticClass: "list_tree" },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          data: _vm.selectEffMembers,
                          "show-checkbox": "",
                          "node-key": "code",
                          "check-strictly": true,
                          "check-on-click-node": true,
                          "default-checked-keys": _vm.obj.institutionIds,
                          "default-expanded-keys": _vm.obj.institutionIds,
                          props: _vm.defaultProps
                        },
                        on: { "check-change": _vm.handleClick }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.obj.type == 7
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "导入不可用商品码：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          staticStyle: { "max-width": "250px" },
                          attrs: { size: "mini", placeholder: "添加商品码" },
                          model: {
                            value: _vm.addGoods,
                            callback: function($$v) {
                              _vm.addGoods = $$v
                            },
                            expression: "addGoods"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                background: "#409EFF !important",
                                color: "#FFFFFF"
                              },
                              attrs: {
                                slot: "append",
                                size: "mini",
                                type: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.goAddGoods()
                                }
                              },
                              slot: "append"
                            },
                            [_vm._v("添加单个码")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.downloadUrl,
                                download: "MikaLife优惠券商品编码模板.xlsx"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "success",
                                    icon: "el-icon-download"
                                  }
                                },
                                [_vm._v("下载模板")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticStyle: { margin: "0 20px" },
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-download"
                              },
                              on: { click: _vm.handleDownload }
                            },
                            [_vm._v("导出商品码")]
                          ),
                          _vm._v(" "),
                          _c("upload-excel-component", {
                            attrs: {
                              size: "mini",
                              "btn-text": "批量上传",
                              type: "btn",
                              "on-success": _vm.handleSuccess,
                              "before-upload": _vm.beforeUpload
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "600px",
                            overflow: "auto",
                            padding: "10px",
                            border: "1px solid #CCCCCC",
                            "margin-top": "10px"
                          }
                        },
                        _vm._l(_vm.obj.goodsIds, function(item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.delGoods(item, index)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item) + ",")]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "有效期类型：", prop: "validTimeType" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.validTimeType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "validTimeType", $$v)
                    },
                    expression: "obj.validTimeType"
                  }
                },
                [_vm._v("指定时间")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.obj.validTimeType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "validTimeType", $$v)
                    },
                    expression: "obj.validTimeType"
                  }
                },
                [_vm._v("领取后N天内有效")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.obj.validTimeType == 2
            ? _c(
                "el-form-item",
                {
                  attrs: { label: "领取多少天内有效：", prop: "validTimeType" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.obj.validDay,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.obj,
                          "validDay",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "obj.validDay"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.obj.validTimeType === 1
            ? _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-form-item",
                    { ref: "effStartTime", attrs: { prop: "effStartTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择开始时间"
                        },
                        model: {
                          value: _vm.obj.effStartTime,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "effStartTime", $$v)
                          },
                          expression: "obj.effStartTime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "effEndTime",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { "label-width": "0", prop: "effEndTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: !_vm.obj.effStartTime,
                          "picker-options": _vm.pickerTime,
                          "default-time": "23:59:59",
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择结束时间"
                        },
                        model: {
                          value: _vm.obj.effEndTime,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "effEndTime", $$v)
                          },
                          expression: "obj.effEndTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "储值卡说明：", prop: "scopeType" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 600, height: 300 },
                model: {
                  value: _vm.obj.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "remark", $$v)
                  },
                  expression: "obj.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即创建")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }