"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '领券中心',
      list: [{
        tit: '乐品到家小程序',
        txtArr: '<p>选择优惠券、设置投放数量、投放时间、定义标签</p>前端根据标签呈现列表及展现方式',
        imgPath: require('@/assets/tab/1.png'),
        path: '/getCoupon/list?id=1'
      }, {
        tit: '星力乐购小程序',
        txtArr: '<p>选择优惠券、设置投放数量、投放时间、定义标签</p>前端根据标签呈现列表及展现方式',
        imgPath: require('@/assets/tab/1.png'),
        path: '/getCoupon/list?id=2'
      }, {
        tit: 'MIKA积分商城小程序',
        txtArr: '<p>选择优惠券、设置投放数量、投放时间、定义标签</p>前端根据标签呈现列表及展现方式',
        imgPath: require('@/assets/tab/1.png'),
        path: '/getCoupon/list?id=3'
      }, {
        tit: '汇金星力城小程序',
        txtArr: '<p>选择优惠券、设置投放数量、投放时间、定义标签</p>前端根据标签呈现列表及展现方式',
        imgPath: require('@/assets/tab/1.png'),
        path: '/getCoupon/list?id=4'
      }, {
        tit: '乐品鲜活APP',
        txtArr: '<p>选择优惠券、设置投放数量、投放时间、定义标签</p>前端根据标签呈现列表及展现方式',
        imgPath: require('@/assets/tab/1.png'),
        path: '/getCoupon/list?id=5'
      }]
    };
  },
  watch: {},
  methods: {
    setTab: function setTab(path) {
      this.$router.push(path);
    }
  }
};
exports.default = _default;