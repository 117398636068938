module.exports = {
  discountType: {
    1: '百货95折',
    2: '化妆品85折',
    3: '优惠券兑换',
    4: '积分商城购买',
    5: '365会员权益',
    6: '汇金停车',
    7: '荔星停车',
    8: '活动券BC',
    9: '沙龙活动',
    10: '优惠券抵扣优惠'
  }
};