"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lotteryAdd = lotteryAdd;
exports.lotteryDetail = lotteryDetail;
exports.lotteryEdit = lotteryEdit;
exports.lotteryList = lotteryList;
exports.lotteryLogList = lotteryLogList;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
1.新增抽奖活动
{
  "type": 1,               1大转盘  2九宫格
  "name": "大赢家",
  "startTime": "2020-09-12 17:52:40",
  "endTime": "2020-09-13 18:40:23",
  "method": 1,                1每日均可抽奖  2一次性抽奖
  "time": 2,                  每日没人可抽奖次数/活动期间每人可抽奖次数
  "score": 3,                    消耗积分
  "instr": "说明",
  "image": "你是图片",
  "orgCode": "2008701687",         主办机构编码
  "shareTitle": "你是标题",
  "shareImage": "分享图片",
  "drawItemParams": [
    {
          "type": 1,                 1优惠券 2商品 3谢谢参与 4积分 5乐享值
          "name": "优惠券",
          "num": 3,               投放数量
          "winPro": 0.5,           中奖概率
          "sort": 2              排序
      },
  ]
}
 */
function lotteryAdd(data) {
  return (0, _request.default)({
    url: '/draw/add',
    method: 'post',
    data: data
  });
}
/*
/draw/query?code=BR202011051013239270&orgCode=2008701687&startTime=2020-09-12 00:00:00&endTime=2020-09-14 00:00:00

 */


function lotteryList(data) {
  return (0, _request.default)({
    url: '/draw/query',
    method: 'get',
    params: data
  });
}
/*
/draw/detail/BR202011051013239270

 */


function lotteryDetail(data) {
  return (0, _request.default)({
    url: '/draw/detail',
    method: 'get',
    params: data
  });
}
/*

 /draw/edit
 */


function lotteryEdit(data) {
  return (0, _request.default)({
    url: '/draw/edit',
    method: 'post',
    data: data
  });
}
/*
 抽奖记录分页查询
drawLog/query?drawCode=123&type=1&startTime=2000-11-05&endTime=2020-11-05&pageSize=10&pageNo=1
 */


function lotteryLogList(data) {
  return (0, _request.default)({
    url: '/drawLog/query',
    method: 'get',
    params: data
  });
}