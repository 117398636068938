"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '要客权益',
      list: [// {
      //   tit: '要客权益',
      //   txtArr: '<p>维护发放的要客权益</p>',
      //   imgPath: require('@/assets/tab/interests.png'),
      //   path: '/svip/rights/interestsList',
      // },
      {
        tit: '要客等级',
        txtArr: '<p>配置不同充值额度的要客等级</p>',
        imgPath: require('@/assets/tab/interests.png'),
        path: '/svip/rights/level'
      }, {
        tit: '权益分类',
        txtArr: '<p>不同权益的分类设置</p>',
        imgPath: require('@/assets/tab/interests.png'),
        path: '/svip/rights/category'
      }, {
        tit: '权益发放规则',
        txtArr: '<p>要客发放权益的规则设置</p>',
        imgPath: require('@/assets/tab/setting.png'),
        path: '/svip/rights/rule'
      }, {
        tit: '权益发放记录',
        txtArr: '<p>权益发放记录列表</p>',
        imgPath: require('@/assets/tab/setting.png'),
        path: '/svip/rights/issueRecord'
      }, {
        tit: '权益使用记录',
        txtArr: '<p>各客户使用权益的记录列表</p>',
        imgPath: require('@/assets/tab/setting.png'),
        path: '/svip/rights/usedRecord'
      }, {
        tit: '权益页面配置',
        txtArr: '<p>小程序端展示的页面配置</p>',
        imgPath: require('@/assets/tab/setting.png'),
        path: '/svip/rights/setting'
      }]
    };
  }
};
exports.default = _default;