var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-list" },
    [
      _c("w-table", {
        attrs: {
          pageApi: _vm.api,
          columns: _vm.columns,
          "search-data": _vm.queryParams
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }