"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _deposit = require("@/api/deposit");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      title: '新增储值卡分类',
      ruleForm: {
        id: null,
        name: null,
        imgUrl: null
      },
      rules: {
        name: [{
          required: true,
          message: '请输入名称'
        }],
        imgUrl: [{
          required: true,
          message: '请上传图片'
        }]
      }
    };
  },
  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    setCover: function setCover(index) {
      this.ruleForm.imgUrl = index;
    },
    submitForm: function submitForm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.openLoading();

          if (_this.ruleForm.id) {
            (0, _deposit.editCardStoredClass)(_this.ruleForm).then(function (res) {
              _this.openLoading().close();

              _this.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this.$router.go(-1);
            }).catch(function () {
              _this.openLoading().close();
            });
          } else {
            (0, _deposit.addCardStoredClass)(_this.ruleForm).then(function (res) {
              _this.openLoading().close();

              _this.$router.go(-1);

              _this.$message({
                type: 'success',
                message: '保存成功!'
              });
            }).catch(function () {
              _this.openLoading().close();
            });
          }
        } else {
          return false;
        }
      });
    },
    init: function init() {
      var _this2 = this;

      this.ruleForm.id = Number(this.$route.query.id);

      if (this.ruleForm.id) {
        this.title = '编辑储值卡分类';
        (0, _deposit.getCardStoredClass)(this.ruleForm.id).then(function (res) {
          console.log(res);
          _this2.ruleForm.name = res.data.name;
          _this2.ruleForm.imgUrl = res.data.imgUrl;
        });
      }
    }
  }
};
exports.default = _default;