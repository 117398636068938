var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增/编辑充值返利活动 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              ref: "activityName",
              attrs: { label: "活动名称：", prop: "activityName" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "活动名称"
                },
                model: {
                  value: _vm.ruleForm.activityName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "activityName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.activityName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "status", attrs: { label: "状态：", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("禁用")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "beSvip",
              attrs: { label: "是否成为要客会员：", prop: "beSvip" }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.beSvip,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "beSvip", $$v)
                    },
                    expression: "ruleForm.beSvip"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.beSvip === 1
            ? _c(
                "el-form-item",
                {
                  ref: "svipLevelId",
                  attrs: { label: "选择Lavant等级：", prop: "svipLevelId" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.svipLevelId,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "svipLevelId", $$v)
                        },
                        expression: "ruleForm.svipLevelId"
                      }
                    },
                    _vm._l(_vm.lavantGradeList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "shortName",
              attrs: { label: "活动简称：", prop: "shortName" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "活动名称"
                },
                model: {
                  value: _vm.ruleForm.shortName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "shortName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.shortName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "description",
              attrs: { label: "描述：", prop: "description" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "活动描述" },
                model: {
                  value: _vm.ruleForm.description,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "description",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "image", attrs: { label: "主图：", prop: "image" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: { width: 200, height: 100, cover: _vm.ruleForm.image },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "image")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form-item",
                {
                  ref: "startTime",
                  attrs: { label: "活动周期：", prop: "startTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择开始时间"
                    },
                    model: {
                      value: _vm.ruleForm.startTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "startTime", $$v)
                      },
                      expression: "ruleForm.startTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "endTime",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { "label-width": "0", prop: "endTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      disabled: !_vm.ruleForm.startTime,
                      "picker-options": _vm.pickerTime,
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": "23:59:59",
                      placeholder: "选择结束时间"
                    },
                    model: {
                      value: _vm.ruleForm.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "endTime", $$v)
                      },
                      expression: "ruleForm.endTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "labelContent",
              attrs: { prop: "labelContent", label: "标签选择：" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "输入标签名称搜索~",
                    "value-key": "id",
                    clearable: ""
                  },
                  model: {
                    value: _vm.ruleForm.labelContent,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "labelContent", $$v)
                    },
                    expression: "ruleForm.labelContent"
                  }
                },
                _vm._l(_vm.userLabelList, function(so) {
                  return _c("el-option", {
                    key: so.id,
                    attrs: { label: "" + so.labelName, value: so.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "totalTimes",
              attrs: { label: "总次数：", prop: "totalTimes" }
            },
            [
              _c("el-input-number", {
                attrs: { min: 1, type: "number", controls: false },
                model: {
                  value: _vm.ruleForm.totalTimes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "totalTimes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.totalTimes"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "perMemberLimitTimes",
              attrs: { label: "单用户限制次数：", prop: "perMemberLimitTimes" }
            },
            [
              _c("el-input-number", {
                attrs: {
                  min: 1,
                  max: _vm.ruleForm.totalTimes,
                  type: "number",
                  controls: false
                },
                model: {
                  value: _vm.ruleForm.perMemberLimitTimes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "perMemberLimitTimes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.perMemberLimitTimes"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "cardUuid",
              attrs: { label: "充值至购物卡：", prop: "cardUuid" }
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "140px" },
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.getAward("cardUuid")
                    }
                  }
                },
                [_vm._v("\n        选择购物卡")]
              ),
              _vm._v("\n      " + _vm._s(_vm.ruleForm.cardUuid) + "\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "amoutStage",
              attrs: { label: "单笔充值满：", prop: "amoutStage" }
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { type: "number" },
                  model: {
                    value: _vm.ruleForm.amoutStage,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "amoutStage",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.amoutStage"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "rightId", attrs: { label: "充值奖励：", prop: "rightId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.ruleForm.rightId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "rightId", $$v)
                    },
                    expression: "ruleForm.rightId"
                  }
                },
                _vm._l(_vm.rightsIdList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.rightName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "cardUuid", attrs: { label: "员工奖励购物卡：" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "140px" },
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.getAward("giveStoreCardUuid")
                    }
                  }
                },
                [_vm._v("\n        选择购物卡")]
              ),
              _vm._v(" "),
              _vm.ruleForm.giveStoreCardUuid
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.ruleForm.giveStoreCardName) +
                        "(" +
                        _vm._s(_vm.ruleForm.giveStoreCardUuid) +
                        ")"
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.giveStoreCardUuid
            ? _c(
                "el-form-item",
                {
                  ref: "giveStoreCardAmount",
                  attrs: {
                    label: "员工奖励金额：",
                    prop: "giveStoreCardAmount"
                  }
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "form_input",
                      attrs: { type: "number" },
                      model: {
                        value: _vm.ruleForm.giveStoreCardAmount,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "giveStoreCardAmount",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.giveStoreCardAmount"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "ruleIntroduction",
              attrs: { prop: "ruleIntroduction", label: "活动详情：" }
            },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.ruleForm.ruleIntroduction,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "ruleIntroduction", $$v)
                  },
                  expression: "ruleForm.ruleIntroduction"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: "card", "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }