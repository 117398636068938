"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.split");

var _api = require("./api.js");

var _member = require("@/api/member.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default
  },
  data: function data() {
    return {
      vipList: [],
      classList: [],
      popType: '',
      couponShow: false,
      type: [{
        label: '实体商品',
        value: 1
      }, {
        label: '直冲商品',
        value: 2
      }, {
        label: '优惠券',
        value: 3
      }],
      rules: {
        openGifts: [{
          required: true,
          message: '请选择商品信息'
        }],
        vipCode: [{
          required: true,
          message: '请选择会员类型'
        }],
        classId: [{
          required: true,
          message: '请选择会员硬核好券分类'
        }],
        putType: [{
          required: true,
          message: '请选择投放周期'
        }],
        putBeginTime: [{
          required: true,
          message: '请选择开始时间'
        }],
        putEndTime: [{
          required: true,
          message: '请选择结束时间'
        }],
        putTotal: [{
          required: true,
          message: '请输入总量'
        }],
        limitNumber: [{
          required: true,
          message: '请输入限领数量(0：不限领)'
        }],
        limitType: [{
          required: true,
          message: '请选择限领类型'
        }],
        dateList: [{
          required: true,
          message: '请选择每月日期'
        }],
        dateTime: [{
          required: true,
          message: '请选择时间'
        }],
        putStatus: [{
          required: true,
          message: '请选择投放状态'
        }]
      },
      ruleForm: {
        /*
        {
          "subsidyPut":{"putType":"投放类型(1：单次 2：按月重复)",
          "putBeginTime":"开始时间",
          "putEndTime":"结束时间",
          "putTotal":"投放总量",
         "limitType":"限领类型(0：不限领 1：一共可领 2：每周期可领)",
          "limitNumber":"限领数量(0：不限领)"}}
          lmDesc:null
        */
        putStatus: null,
        vipCode: '',
        classId: null,
        id: null,
        openGifts: [],
        putType: null,
        putBeginTime: null,
        putEndTime: null,
        putTotal: null,
        limitType: null,
        limitNumber: null,
        dateList: [],
        dateTime: null
        /*
          {"subsidys":"[
            {"vipCode":"会员编码",
            "classId":"分类id",
            "productCode":"商品编码",
            "productType":"商品类型(1：积分商城商品 2：直充商品 3：优惠券)",
            "putNumber":"投放数量",
            "productSort":"商品排序"}]"}
          */

      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.listSubsidyClass)({
      classStatus: 1
    }).then(function (res) {
      _this.classList = res.data;
    });
    (0, _member.listVip)().then(function (res) {
      console.log(res);
      _this.vipList = res.data;
    });

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _api.getSubsidy)(this.ruleForm.id).then(function (res) {
        _this.ruleForm.classId = res.data.classId;
        _this.ruleForm.id = res.data.id;
        _this.ruleForm.putType = res.data.subsidyPut.putType;
        _this.ruleForm.vipCode = res.data.vipCode;
        _this.ruleForm.putStatus = res.data.putStatus;

        _this.ruleForm.openGifts.push({
          productCode: res.data.productCode,
          productSort: res.data.productSort,
          productType: res.data.productType,
          putNumber: res.data.putNumber,
          productName: res.data.productName,
          lmDesc: res.data.lmDesc || ''
        });

        _this.ruleForm.putTotal = res.data.subsidyPut.putTotal;
        _this.ruleForm.limitNumber = res.data.subsidyPut.limitNumber;

        if (res.data.subsidyPut.putType === 2) {
          _this.ruleForm.dateList = res.data.subsidyPut.dayStr.split(',');
          _this.ruleForm.dateTime = [res.data.subsidyPut.putBeginTime, res.data.subsidyPut.putEndTime];
          _this.ruleForm.limitType = res.data.subsidyPut.limitType;
        } else {
          _this.ruleForm.putBeginTime = res.data.subsidyPut.putBeginTime;
          _this.ruleForm.putEndTime = res.data.subsidyPut.putEndTime;
        }

        console.log(_this.ruleForm);
      });
    }
  },
  methods: {
    changeLimit: function changeLimit(e) {
      if (e === 0) {
        this.ruleForm.limitNumber = 0;
      }
    },
    getRelevances: function getRelevances() {
      this.ruleForm.openGifts.push({
        productType: null,
        productCode: null,
        putNumber: null,
        productSort: null,
        productName: null,
        lmDesc: null
      });
    },

    /*
      选择奖励项
      */
    setAward: function setAward(e, index) {
      this.ruleForm.openGifts[index].productCode = '';
      this.ruleForm.openGifts[index].putNumber = '';
      this.ruleForm.openGifts[index].productSort = '';
      this.ruleForm.openGifts[index].productName = '';
      this.ruleForm.openGifts[index].lmDesc = '';
    },

    /*
      打开弹框
      */
    getAward: function getAward(e, index) {
      this.count = index;

      if (e === 3) {
        this.popType = 'coupon';
      } else {
        this.popType = 'goods';
      }

      this.$refs.coupon1.getList(this.popType, this.ruleForm.openGifts[index].productCode, e);
      this.couponShow = true;
    },

    /*
      删除奖励项
      */
    delAward: function delAward(index) {
      this.ruleForm.openGifts.splice(index, 1);
    },
    setCoupon: function setCoupon(e) {
      if (this.popType === 'coupon') {
        this.ruleForm.openGifts[this.count].productCode = e.uuid;
        this.ruleForm.openGifts[this.count].productName = e.name; // this.ruleForm.openGifts[this.count].prizeName = e.name
      }

      if (this.popType === 'goods') {
        this.ruleForm.openGifts[this.count].productCode = e.productCode;
        this.ruleForm.openGifts[this.count].productName = e.productName;
      }

      this.couponShow = false;
      this.popType = '';
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var dataList = [];

          _this2.ruleForm.dateList.forEach(function (item) {
            dataList.push({
              putBeginTime: item + ' ' + _this2.ruleForm.dateTime[0],
              putEndTime: item + ' ' + _this2.ruleForm.dateTime[1]
            });
          });

          var openGifts = [];

          _this2.ruleForm.openGifts.forEach(function (item) {
            if (_this2.ruleForm.putType === 1) {
              openGifts.push({
                productCode: item.productCode,
                productName: item.productName,
                productType: item.productType,
                putNumber: Number(item.putNumber),
                productSort: Number(item.productSort),
                vipCode: _this2.ruleForm.vipCode,
                classId: _this2.ruleForm.classId,
                putStatus: _this2.ruleForm.putStatus,
                lmDesc: item.lmDesc,
                subsidyPuts: [{
                  putType: _this2.ruleForm.putType,
                  putBeginTime: _this2.ruleForm.putBeginTime,
                  putEndTime: _this2.ruleForm.putEndTime,
                  putTotal: _this2.ruleForm.putTotal,
                  limitNumber: _this2.ruleForm.limitNumber
                }]
              });
            } else {
              var subsidyPuts = [];
              dataList.forEach(function (item) {
                subsidyPuts.push({
                  putType: _this2.ruleForm.putType,
                  putBeginTime: item.putBeginTime,
                  putEndTime: item.putEndTime,
                  putTotal: _this2.ruleForm.putTotal,
                  limitType: _this2.ruleForm.limitType,
                  limitNumber: _this2.ruleForm.limitNumber
                });
              });
              openGifts.push({
                productCode: item.productCode,
                productType: item.productType,
                putNumber: Number(item.putNumber),
                productSort: Number(item.productSort),
                vipCode: _this2.ruleForm.vipCode,
                classId: _this2.ruleForm.classId,
                lmDesc: item.lmDesc,
                subsidyPuts: subsidyPuts,
                putStatus: _this2.ruleForm.putStatus
              });
            }
          });
          /*
          {
           "subsidyPut":{"putType":"投放类型(1：单次 2：按月重复)",
           "putBeginTime":"开始时间",
           "putEndTime":"结束时间",
           "putTotal":"投放总量",
          "limitType":"限领类型(0：不限领 1：一共可领 2：每周期可领)",
           "limitNumber":"限领数量(0：不限领)"}}
           */


          console.log(JSON.stringify({
            subsidys: openGifts
          }));

          if (_this2.ruleForm.id) {
            var data = openGifts[0];
            data.id = _this2.ruleForm.id;
            (0, _api.editSubsidy)(data).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addSubsidy)({
              subsidys: openGifts
            }).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;