var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.orderData.type === 1 ? "团购订单详情" : "返利订单详情") +
            "\n      "
        ),
        _c(
          "div",
          [
            _vm.setMenuPerms("cardGroup:import")
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: {
                      click: function($event) {
                        _vm.isImportPhone = true
                      }
                    }
                  },
                  [_vm._v("导入白名单\n        ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.orderData.status === 2 &&
            _vm.setMenuPerms("cardGroup:exportCode")
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function($event) {
                        _vm.isExport = true
                      }
                    }
                  },
                  [_vm._v("导出兑换码")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          staticClass: "demo-ruleForm",
          attrs: { size: "small", "label-width": "150px" }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.orderData.type === 1 ? "销售单号" : "返利单号"
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.orderData.groupCode) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.orderData.type === 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "购买人/主体：" } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.orderData.buyUser) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "负责人电话：" } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.orderData.buyMobile) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderData.type === 2
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "销售单号：" } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.orderData.oldGroupCodes) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "合计总金额：" } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    (
                                      _vm.orderData.totalAmount /
                                      (_vm.orderData.proportion / 100)
                                    ).toFixed(2)
                                  ) +
                                  "元\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "返利比例：" } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.orderData.proportion) +
                                  "%\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "返利金额：" } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.orderData.totalAmount) +
                                  "元\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "订单状态：" } }, [
                    _vm.orderData.status === 1
                      ? _c("span", { staticClass: "warning" }, [
                          _vm._v("待审核")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.orderData.status === 2
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("已审核")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.orderData.status === 3
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("已驳回")
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.orderData.type === 1
                    ? _c("el-form-item", { attrs: { label: "返利状态：" } }, [
                        _vm.orderData.isRebate === 1
                          ? _c("span", { staticClass: "danger" }, [
                              _vm._v("未返利")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orderData.isRebate === 2
                          ? _c("span", { staticClass: "success" }, [
                              _vm._v("已返利")
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "附件凭证：" } }, [
                    _c("img", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: { src: _vm.imgSrc + _vm.orderData.voucher }
                    })
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "备注：" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.orderData.remarks) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "储值卡名称：" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.orderData.cardName) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "储值卡号：" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.orderData.cardUuid) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "面额：" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.orderData.denomination) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "订单数量：" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.orderData.quantity) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.orderData.type === 1
                    ? _c("el-form-item", { attrs: { label: "合计金额：" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.orderData.totalAmount) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "未兑换数量：" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.orderData.remainingQuantity) +
                        "\n          "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.orderData.unifiedGroupCardWhitelist.length > 0 ||
          _vm.orderData.automaticGroupCardWhitelist.length > 0
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "view_title flex_between" }, [
                    _vm._v("白名单 ")
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _vm.orderData.unifiedGroupCardWhitelist.length > 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "统一二维码白名单：" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "10px",
                                "border-radius": "6px",
                                border: "1px solid #CCCCCC",
                                "max-height": "400px",
                                overflow: "auto"
                              }
                            },
                            _vm._l(
                              _vm.orderData.unifiedGroupCardWhitelist,
                              function(item, index) {
                                return _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.mobile) +
                                      ",\n            "
                                  )
                                ])
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v(
                              "共：" +
                                _vm._s(
                                  _vm.orderData.unifiedGroupCardWhitelist.length
                                ) +
                                "个"
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderData.automaticGroupCardWhitelist.length > 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: " 自动充值白名单：" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "10px",
                                "border-radius": "6px",
                                border: "1px solid #CCCCCC",
                                "max-height": "400px",
                                overflow: "auto"
                              }
                            },
                            _vm._l(
                              _vm.orderData.automaticGroupCardWhitelist,
                              function(item, index) {
                                return _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.mobile) +
                                      ",\n            "
                                  )
                                ])
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v(
                              "共：" +
                                _vm._s(
                                  _vm.orderData.automaticGroupCardWhitelist
                                    .length
                                ) +
                                "个"
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-divider")
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.orderData.status === 2
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "view_title flex_between" }, [
                    _vm._v("兑换码导出记录 ")
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c(
                    "wwTable",
                    {
                      attrs: {
                        "table-data": _vm.orderData.exportLog,
                        columns: _vm.columns
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          slot: "type",
                          label: "类型",
                          align: "center",
                          width: "100"
                        },
                        slot: "type",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.type === 1
                                    ? _c("span", { staticClass: "warning" }, [
                                        _vm._v("兑换码")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.type === 2
                                    ? _c("span", { staticClass: "success" }, [
                                        _vm._v("独立二维码")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.type === 3
                                    ? _c("span", { staticClass: "danger" }, [
                                        _vm._v("统一二维码")
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          584210847
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            "append-to-body": true,
            "modal-append-to-body": false,
            title: "导出兑换码!",
            visible: _vm.isExport,
            direction: "rtl",
            "custom-class": "demo-drawer"
          },
          on: {
            "update:visible": function($event) {
              _vm.isExport = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "demo-drawer__content" },
            [
              _c(
                "el-form",
                {
                  ref: "ecportCode",
                  attrs: { model: _vm.ruleForm, "label-width": "120px" }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "导出方式",
                        prop: "exportType",
                        rules: [
                          {
                            required: true,
                            message: "请选择导出类型",
                            trigger: "blur"
                          }
                        ]
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form_input",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.ruleForm.exportType,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "exportType", $$v)
                            },
                            expression: "ruleForm.exportType"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "兑换码", value: 1 }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "独立二维码", value: 2 }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "统一二维码", value: 3 }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "自动充值", value: 4 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.exportType === 3
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "每人限兑",
                            prop: "limitedCollar",
                            rules: [
                              {
                                required: true,
                                message: "请输入每人限兑",
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "form_input",
                            attrs: { controls: false },
                            model: {
                              value: _vm.ruleForm.limitedCollar,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "limitedCollar", $$v)
                              },
                              expression: "ruleForm.limitedCollar"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.exportType === 4
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "每人充值数量",
                            prop: "automaticNum",
                            rules: [
                              {
                                required: true,
                                message: "请输入每人充值数量",
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "form_input",
                            attrs: { controls: false },
                            model: {
                              value: _vm.ruleForm.automaticNum,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "automaticNum", $$v)
                              },
                              expression: "ruleForm.automaticNum "
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-button", { on: { click: _vm.cancelForm } }, [
                        _vm._v("取 消")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function($event) {
                              return _vm.goExportCode()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.loading ? "提交中 ..." : "确 定") +
                              "\n            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          ref: "importPhone",
          attrs: {
            title: "导入白名单!",
            "append-to-body": true,
            "modal-append-to-body": false,
            visible: _vm.isImportPhone,
            size: "60%",
            direction: "rtl",
            "custom-class": "demo-drawer"
          },
          on: {
            "update:visible": function($event) {
              _vm.isImportPhone = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "demo-drawer__content" },
            [
              _c(
                "el-form",
                {
                  ref: "importPhoneForm",
                  attrs: {
                    "append-to-body": true,
                    "modal-append-to-body": false,
                    model: _vm.importForm,
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "导入类型",
                        prop: "exportType",
                        rules: [
                          {
                            required: true,
                            message: "请选择导入类型",
                            trigger: "blur"
                          }
                        ]
                      }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.importForm.exportType,
                            callback: function($$v) {
                              _vm.$set(_vm.importForm, "exportType", $$v)
                            },
                            expression: "importForm.exportType "
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("需兑换")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("自动充值")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "导入备注",
                        prop: "exportRemarks",
                        rules: [
                          {
                            required: true,
                            message: "请输入备注",
                            trigger: "blur"
                          }
                        ]
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.importForm.exportRemarks,
                          callback: function($$v) {
                            _vm.$set(_vm.importForm, "exportRemarks", $$v)
                          },
                          expression: "importForm.exportRemarks"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "手机号码",
                        prop: "exportMobileList",
                        rules: [
                          {
                            required: true,
                            message: "请上传手机号",
                            trigger: "blur"
                          }
                        ]
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { "align-items": "center" }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "margin-right": "20px",
                                "line-height": "1"
                              },
                              attrs: {
                                href: _vm.downloadUrl,
                                download: "团购购物卡白名单模板.xlsx"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "success",
                                    icon: "el-icon-download"
                                  }
                                },
                                [_vm._v("下载模板")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("upload-excel-component", {
                            attrs: {
                              "btn-text": "批量上传",
                              type: "btn",
                              "on-success": _vm.handleSuccess,
                              "before-upload": _vm.beforeUpload
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "10px",
                            border: "1px solid #cccccc",
                            "margin-top": "10px",
                            "max-height": "300px",
                            overflow: "auto",
                            "font-size": "12px",
                            "margin-right": "10px"
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.importForm.exportMobileList.join("、")
                              ) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.errPhone
                        ? _c("p", { staticClass: "danger" }, [
                            _vm._v("未找到以下用户，手机号码需注册后才能充值！")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errPhone
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "word-break": "break-all",
                                padding: "10px",
                                border: "1px solid red",
                                color: "red",
                                "max-height": "300px",
                                overflow: "auto",
                                "font-size": "12px",
                                "margin-right": "10px"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errPhone) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-button", { on: { click: _vm.cancelForm } }, [
                        _vm._v("取 消")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading },
                          on: { click: _vm.goImportWhite }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.loading ? "提交中 ..." : "确 定") +
                              "\n            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              "qr-code": _vm.qrCode,
              url: _vm.url,
              "coupon-u-uid": _vm.orderData.buyUser + "统一二维码"
            },
            on: { closePop: _vm.closePop }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }