"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _api = require("@/views/member/equity/api.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default
  },
  props: {
    couponShow: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    return {
      openGfit: '',
      type: '',
      getRowKeys: function getRowKeys(row) {
        return row.uuid;
      },
      radioId: {},
      tableData: [],
      total: 0,
      cardType: 1,
      obj: {
        cardNo: null,
        pageNo: 1,
        pageSize: 10,
        status: null,
        rightType: null,
        rightName: '',
        reciveMode: null
      },
      options: [{
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '启用'
      }, {
        value: 3,
        label: '停用'
      }, {
        value: 4,
        label: '已审核'
      }],
      reciveModeOptions: [{
        value: 1,
        label: '每天'
      }, {
        value: 2,
        label: '每周'
      }, {
        value: 3,
        label: '每月'
      }, {
        value: 4,
        label: '季度'
      }, {
        value: 5,
        label: '会员生日'
      }, {
        value: 6,
        label: '会员生日月'
      }, {
        value: 7,
        label: '付费会员有效期内'
      }],
      tabTh: []
    };
  },
  mounted: function mounted() {},
  methods: {
    rowClick: function rowClick(item) {
      console.log('item-==>', item);
      this.radioId = item;
    },
    affirmAddCoupon: function affirmAddCoupon() {
      this.$emit('setAwardItem', this.radioId);
    },
    getList: function getList(type, id) {
      // this.radioId=id||''
      this.radioId.id = id || '';
      this.type = type;

      if (this.type === 'coupon') {
        this.tabTh = [{
          tit: '权益标题',
          prop: 'rightName',
          width: 180
        }, {
          tit: '权益简称',
          prop: 'rightShort'
        }, {
          tit: '创建时间',
          prop: 'createTime',
          width: 180
        }];
      }

      this.init('remove');
    },
    init: function init(types) {
      var _this = this;

      if (types === 'search') {
        this.obj.pageNo = 1;
      }

      if (types === 'remove') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
        this.obj.rightType = '';
        this.obj.rightName = '';
        this.tableData = [];
        this.total = 0;
        this.obj.reciveMode = null;
        this.obj.status = null;
      }

      if (this.type === 'coupon') {
        var data = JSON.parse(JSON.stringify(this.obj));
        data.status = 2;
        (0, _api.getList)(data).then(function (res) {
          _this.total = Number(res.data.total);
          var data = res.data.records;
          _this.tableData = data;
        });
      }
    }
  }
};
exports.default = _default2;