var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("新增/编辑方案标签分类")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            size: "small",
            model: _vm.form,
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "classId", label: "标签分类：", required: "" }
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%", "max-width": "300px" },
                  attrs: { placeholder: "请选择标签分类" },
                  model: {
                    value: _vm.form.classId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "classId", $$v)
                    },
                    expression: "form.classId"
                  }
                },
                _vm._l(_vm.listLabel, function(item) {
                  return _c("el-option", {
                    key: item.classId,
                    attrs: { label: item.className, value: item.classId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "labelName", label: "标签名称：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: { size: "small" },
                model: {
                  value: _vm.form.labelName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "labelName", $$v)
                  },
                  expression: "form.labelName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "labelStatus", label: "标签状态：", required: "" }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.labelStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "labelStatus", $$v)
                    },
                    expression: "form.labelStatus"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("禁用")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "max-width": "200px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }