"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _public = require("@/utils/public.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      weekList: _public.weekList,
      rules: {
        'name': [{
          required: true,
          message: '请输入栏目名称'
        }],
        sort: [{
          required: true,
          message: '请输入排序'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        weekTag: [{
          required: true,
          message: '请选择周期'
        }]
      },
      ruleForm: {
        status: null,
        name: null,
        sort: null,
        weekTag: null,
        id: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id) {
      (0, _api.getData)({
        id: this.ruleForm.id
      }).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (item) {
          if (res.data[item]) {
            _this.ruleForm[item] = res.data[item];
          }
        });
      });
    }
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.ruleForm));
          console.log(data);
          (0, _api.addData)(data).then(function (res) {
            _this2.$router.go(-1);

            _this2.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;