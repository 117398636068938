var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("新增特价活动")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { ref: "name", attrs: { label: "活动名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "shortName",
                  attrs: { label: "活动简称：", prop: "shortName" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.shortName,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "shortName", $$v)
                      },
                      expression: "ruleForm.shortName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "introduction",
                  attrs: { label: "活动描述：", prop: "introduction" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.introduction,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "introduction", $$v)
                      },
                      expression: "ruleForm.introduction"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { ref: "remark", attrs: { label: "备注：", prop: "remark" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "remark", $$v)
                      },
                      expression: "ruleForm.remark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { ref: "status", attrs: { label: "状态：", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status"
                      }
                    },
                    _vm._l(_vm.weekList, function(item, index) {
                      return _c(
                        "el-radio",
                        { key: index, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "startTime",
                      attrs: { label: "活动周期：", prop: "startTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择开始时间"
                        },
                        model: {
                          value: _vm.ruleForm.startTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "startTime", $$v)
                          },
                          expression: "ruleForm.startTime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "endTime",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { "label-width": "0", prop: "endTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: !_vm.ruleForm.startTime,
                          "picker-options": _vm.pickerTime,
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": "23:59:59",
                          placeholder: "选择结束时间"
                        },
                        model: {
                          value: _vm.ruleForm.endTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "endTime", $$v)
                          },
                          expression: "ruleForm.endTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "labelContent",
                  attrs: { prop: "labelContent", label: "标签选择：" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "输入标签名称搜索~",
                        "value-key": "id",
                        clearable: ""
                      },
                      model: {
                        value: _vm.ruleForm.labelContent,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "labelContent", $$v)
                        },
                        expression: "ruleForm.labelContent"
                      }
                    },
                    _vm._l(_vm.userLabelList, function(so) {
                      return _c("el-option", {
                        key: so.id,
                        attrs: { label: "" + so.labelName, value: so.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "joinLimitTimes",
                  attrs: { label: "限制购买数量：", prop: "joinLimitTimes" }
                },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, step: 1, precision: 0, controls: false },
                    model: {
                      value: _vm.ruleForm.joinLimitTimes,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "joinLimitTimes", $$v)
                      },
                      expression: "ruleForm.joinLimitTimes"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { ref: "sort", attrs: { label: "排序：", prop: "sort" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, step: 1, precision: 0, controls: false },
                    model: {
                      value: _vm.ruleForm.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "sort", $$v)
                      },
                      expression: "ruleForm.sort"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { ref: "image", attrs: { label: "活动主图：", prop: "image" } },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.image
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "image")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [_vm._v("750*750")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "ruleDesc",
                  attrs: { prop: "ruleDesc", label: "规则介绍：" }
                },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { width: 750, height: 500 },
                    model: {
                      value: _vm.ruleForm.ruleDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "ruleDesc", $$v)
                      },
                      expression: "ruleForm.ruleDesc"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }