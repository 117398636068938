"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '会员管理',
      list: [{
        tit: '会员查询',
        txtArr: '<p>会员查询</p>',
        imgPath: require('@/assets/tab/13.png'),
        path: '/report/memberQuery'
      }, {
        tit: '新增会员',
        txtArr: '<p>新增会员</p>   ',
        imgPath: require('@/assets/tab/31.png'),
        path: '/member/addList'
      }, {
        tit: '会员修改',
        txtArr: '<p>会员修改</p>   ',
        imgPath: require('@/assets/tab/31.png'),
        path: '/report/memberEdit'
      },
      /* {
        tit: '会员馈赠',
        txtArr: '<p>礼品馈赠</p>',
        imgPath: require('@/assets/tab/25.png'),
        path: '/member/present/list'
      },
      {
        tit: '会员礼品管理',
        txtArr: '<p>礼品兑换</p>  ',
        imgPath: require('@/assets/tab/25.png'),
        path: '/member/gift/list'
      }, */
      {
        tit: '切换手机号记录',
        txtArr: '<p>切换手机号记录</p>  ',
        imgPath: require('@/assets/tab/25.png'),
        path: '/member/memberChange'
      }, {
        tit: '会员注销记录',
        txtArr: '<p>会员注销记录</p>  ',
        imgPath: require('@/assets/tab/25.png'),
        path: '/member/memberCancel'
      }]
    };
  }
};
exports.default = _default;