var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增/编辑卡种类 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "卡种名称：", prop: "cardKindsName" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入卡种类名称" },
                model: {
                  value: _vm.ruleForm.cardKindsName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "cardKindsName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.cardKindsName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "卡类型：", prop: "cardCategoryId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    filterable: "",
                    clearable: "",
                    placeholder: "卡类型"
                  },
                  model: {
                    value: _vm.ruleForm.cardCategoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "cardCategoryId", $$v)
                    },
                    expression: "ruleForm.cardCategoryId"
                  }
                },
                _vm._l(_vm.storeList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.categoryName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "卡片面额：", prop: "cardLimitPrice" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { type: "number", placeholder: "请输入卡面额" },
                model: {
                  value: _vm.ruleForm.cardLimitPrice,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "cardLimitPrice",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.cardLimitPrice"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("输入0，卡片不限额")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: " 激活后有效期：", prop: "expireTradeYear" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { type: "number", placeholder: "请输入激活后有效期" },
                  model: {
                    value: _vm.ruleForm.expireTradeYear,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "expireTradeYear",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.expireTradeYear"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("年")])],
                2
              ),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("仅限实体卡，根据监管要求，不记名卡有效期不得少于3年")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: " 卡面图片正面：", prop: "cardImageFront" } },
            [
              _c("add-load", {
                attrs: {
                  width: 250,
                  height: 180,
                  cover: _vm.ruleForm.cardImageFront
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "cardImageFront")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "卡面图片反面：", prop: "cardImageBack" } },
            [
              _c("add-load", {
                attrs: {
                  width: 250,
                  height: 180,
                  cover: _vm.ruleForm.cardImageBack
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "cardImageBack")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 },
                  placeholder: "请输入内容"
                },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }