"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '积分调整',
      list: [{
        tit: '逐条补录',
        txtArr: '<p>积分逐条增加、减少</p>',
        imgPath: require('@/assets/tab/9.png'),
        path: '/integral/adjust/gb'
      }, {
        tit: '批量补录',
        txtArr: '<p>导入表格，批量补录积分</p>',
        imgPath: require('@/assets/tab/9.png'),
        path: '/integral/adjust/add'
      }, {
        tit: '积分调整记录',
        txtArr: '<p>积分调整记录</p>    ',
        imgPath: require('@/assets/tab/10.png'),
        path: '/integral/adjust/list'
      }]
    };
  }
};
exports.default = _default;