"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SvipeMemberState = exports.SvipTypesStatus = exports.SvipType = exports.SvipSubStatus = exports.SvipStatus = exports.SvipSex = exports.SvipServiceType = exports.SvipServiceReserveType = exports.SvipServiceItemType = exports.SvipServiceBuyType = exports.SvipRightsUsedRecordStatus = exports.SvipReviewStatus = exports.SvipPlaceSaleStandard = exports.SvipPayment = exports.SvipNeedD2dStatus = exports.SvipGoods = exports.SvipCouponStatus = exports.SvipBookingStatus = exports.SvipAfterSaleStatus = exports.SvipAfterSaleListStatus = exports.SvipActivityStatus = void 0;
var SvipStatus = {
  1: '待加入',
  2: '已加入',
  3: '已剔除',
  4: '已过期'
};
exports.SvipStatus = SvipStatus;
var SvipReviewStatus = {
  1: '待审核',
  2: '已通过',
  3: '未通过'
};
exports.SvipReviewStatus = SvipReviewStatus;
var SvipSex = {
  0: '未知',
  1: '男',
  2: '女'
};
exports.SvipSex = SvipSex;
var SvipType = {
  1: '服务',
  2: '场地',
  3: '活动'
};
exports.SvipType = SvipType;
var SvipSubStatus = {
  1: '待支付',
  2: '已预约',
  3: '已使用',
  4: '已失效',
  5: '已取消'
};
exports.SvipSubStatus = SvipSubStatus;
var SvipPayment = {
  1: '积分',
  2: '现金'
};
exports.SvipPayment = SvipPayment;
var SvipPlaceSaleStandard = {
  1: '按天',
  2: '按小时',
  3: '按天&按小时'
};
exports.SvipPlaceSaleStandard = SvipPlaceSaleStandard;
var SvipTypesStatus = {
  1: '普通会员',
  2: '要客',
  3: '365会员'
};
exports.SvipTypesStatus = SvipTypesStatus;
var SvipGoods = {
  1: '是',
  2: '否'
};
exports.SvipGoods = SvipGoods;
var SvipServiceBuyType = {
  1: '免费',
  2: '金额',
  3: '积分'
};
exports.SvipServiceBuyType = SvipServiceBuyType;
var SvipServiceReserveType = {
  1: '客户自主预约',
  2: '联系荔星伙伴'
};
exports.SvipServiceReserveType = SvipServiceReserveType;
var SvipServiceItemType = {
  1: '固定价',
  2: '按人数价'
};
exports.SvipServiceItemType = SvipServiceItemType;
var SvipServiceType = {
  1: '场内服务',
  2: '场外服务'
};
exports.SvipServiceType = SvipServiceType;
var SvipActivityStatus = {
  1: '未开始',
  2: '进行中',
  3: '已结束',
  4: '已取消'
};
exports.SvipActivityStatus = SvipActivityStatus;
var SvipAfterSaleStatus = {
  1: '待处理',
  2: '已处理，同意取消预约',
  3: '已处理，拒绝取消预约'
};
exports.SvipAfterSaleStatus = SvipAfterSaleStatus;
var SvipAfterSaleListStatus = {
  1: '待处理',
  2: '已同意',
  3: '已拒绝'
};
exports.SvipAfterSaleListStatus = SvipAfterSaleListStatus;
var SvipBookingStatus = {
  1: '服务预约',
  2: '场地预约',
  3: '活动预约'
};
exports.SvipBookingStatus = SvipBookingStatus;
var SvipCouponStatus = {
  1: '待审核',
  2: '启用',
  3: '停用',
  4: '已审核'
};
exports.SvipCouponStatus = SvipCouponStatus;
var SvipNeedD2dStatus = {
  false: '否',
  true: '是'
};
exports.SvipNeedD2dStatus = SvipNeedD2dStatus;
var SvipeMemberState = {
  1: '体验会员',
  2: '非凡会员',
  3: '超凡会员'
};
exports.SvipeMemberState = SvipeMemberState;
var SvipRightsUsedRecordStatus = {
  1: '已使用',
  2: '已取消'
};
exports.SvipRightsUsedRecordStatus = SvipRightsUsedRecordStatus;