var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-activity-form" },
    [
      _vm.isDetail
        ? _c("div", { staticClass: "view_title" }, [_vm._v("活动详情")])
        : _c("div", { staticClass: "view_title" }, [
            _vm._v(_vm._s(_vm.id ? "编辑" : "新建") + "活动")
          ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-width": "180px",
            model: _vm.ruleForm,
            rules: _vm.rules,
            disabled: _vm.isDetail
          }
        },
        [
          _c("Descriptions", { attrs: { title: "基本信息" } }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动名称：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  placeholder: "请输入",
                  maxlength: "10",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示到沙龙活动：", prop: "ifShow" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.ifShow,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "ifShow", $$v)
                    },
                    expression: "ruleForm.ifShow"
                  }
                },
                [_vm._v("显示")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.ruleForm.ifShow,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "ifShow", $$v)
                    },
                    expression: "ruleForm.ifShow"
                  }
                },
                [_vm._v("不显示")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "过期后跳转模板页面：", prop: "externalUrl" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.externalUrl,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "externalUrl",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.externalUrl"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("用于活动过期后跳转，不填不跳转")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "服务分类：", prop: "categoryId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    size: "small",
                    placeholder: "请选择",
                    filterable: "",
                    autocomplete: "off"
                  },
                  model: {
                    value: _vm.ruleForm.categoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "categoryId", $$v)
                    },
                    expression: "ruleForm.categoryId"
                  }
                },
                _vm._l(_vm.categoryList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    },
                    [
                      _vm._v(
                        "\n           " + _vm._s(item.name) + "\n         "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "name", attrs: { label: "活动门店：", prop: "storeId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    size: "small",
                    placeholder: "请选择",
                    filterable: "",
                    autocomplete: "off"
                  },
                  model: {
                    value: _vm.ruleForm.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "storeId", $$v)
                    },
                    expression: "ruleForm.storeId"
                  }
                },
                _vm._l(_vm.storeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.storeId,
                      attrs: {
                        label: item.shortName + "（" + item.storeCode + "）",
                        value: item.storeId
                      }
                    },
                    [
                      _vm._v(
                        "\n           " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n         "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "name",
              attrs: { label: "活动时间：", prop: "activityTime" }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"]
                },
                on: { change: _vm.changeActivityDate },
                model: {
                  value: _vm.ruleForm.activityTime,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "activityTime", $$v)
                  },
                  expression: "ruleForm.activityTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "展示图片：", prop: "pic" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: { width: 345, height: 160, cover: _vm.ruleForm.pic },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "pic")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticStyle: { color: "#999999" } }, [
                _vm._v("建议图片尺寸：690 x 320")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "参与总人数：", prop: "totalNum" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: {
                    type: "number",
                    placeholder: "请输入",
                    min: "0",
                    oninput: "value = value.replace(/^0|[^0-9]/g, '')"
                  },
                  on: { change: _vm.changeTotalNum },
                  model: {
                    value: _vm.ruleForm.totalNum,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "totalNum",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.totalNum"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("人")])],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "简介：", prop: "introduction" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  placeholder: "请输入",
                  maxlength: "15",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm.introduction,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "introduction",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.introduction"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "描述：", prop: "description" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                staticStyle: { "line-height": "1" },
                attrs: {
                  autosize: { minRows: 4, maxRows: 6 },
                  type: "textarea",
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.ruleForm.description,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "description",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "detail", label: "详情：" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.ruleForm.detail,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "detail", $$v)
                  },
                  expression: "ruleForm.detail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { label: "销售价格：", prop: "buyType", size: "small" }
            },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { width: "1150px" },
                  attrs: { size: "small" },
                  model: {
                    value: _vm.ruleForm.buyType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "buyType", $$v)
                    },
                    expression: "ruleForm.buyType"
                  }
                },
                _vm._l(_vm.ruleForm.salesTypeList, function(salesType, index) {
                  return _c(
                    "el-row",
                    { key: index, attrs: { gutter: 0 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 2 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: salesType.buyType } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.SvipServiceBuyType[salesType.buyType]
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      salesType.buyType !== 1
                        ? [
                            _c("span", { staticClass: "form_label" }, [
                              _vm._v("原价：")
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "salesTypeList." + index + ".price",
                                      size: "small",
                                      rules: [
                                        {
                                          required:
                                            _vm.ruleForm.buyType ===
                                            salesType.buyType,
                                          message: "请输入原价"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticStyle: { width: "140px" },
                                        attrs: {
                                          type: "number",
                                          disabled:
                                            _vm.ruleForm.buyType !==
                                            salesType.buyType,
                                          size: "small"
                                        },
                                        model: {
                                          value: salesType.price,
                                          callback: function($$v) {
                                            _vm.$set(
                                              salesType,
                                              "price",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "salesType.price"
                                        }
                                      },
                                      [
                                        _c("template", { slot: "append" }, [
                                          _vm._v("元")
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      salesType.buyType !== 1
                        ? [
                            _c("span", { staticClass: "form_label" }, [
                              _vm._v("365价：")
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "salesTypeList." + index + ".price365",
                                      size: "small",
                                      rules: [
                                        {
                                          required:
                                            _vm.ruleForm.buyType ===
                                            salesType.buyType,
                                          message: "请输入365价"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticStyle: { width: "140px" },
                                        attrs: {
                                          type: "number",
                                          disabled:
                                            _vm.ruleForm.buyType !==
                                            salesType.buyType,
                                          size: "small"
                                        },
                                        model: {
                                          value: salesType.price365,
                                          callback: function($$v) {
                                            _vm.$set(
                                              salesType,
                                              "price365",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "salesType.price365"
                                        }
                                      },
                                      [
                                        _c("template", { slot: "append" }, [
                                          _vm._v("元")
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      salesType.buyType !== 1
                        ? [
                            _c("span", { staticClass: "form_label" }, [
                              _vm._v("要客价：")
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "salesTypeList." + index + ".priceSvip",
                                      size: "small",
                                      rules: [
                                        {
                                          required:
                                            _vm.ruleForm.buyType ===
                                            salesType.buyType,
                                          message: "请输入要客价"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticStyle: { width: "140px" },
                                        attrs: {
                                          type: "number",
                                          disabled:
                                            _vm.ruleForm.buyType !==
                                            salesType.buyType,
                                          size: "small"
                                        },
                                        model: {
                                          value: salesType.priceSvip,
                                          callback: function($$v) {
                                            _vm.$set(
                                              salesType,
                                              "priceSvip",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "salesType.priceSvip"
                                        }
                                      },
                                      [
                                        _c("template", { slot: "append" }, [
                                          _vm._v("元")
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      salesType.buyType === 2
                        ? [
                            _c("span", { staticClass: "form_label" }, [
                              _vm._v("积分可抵扣：")
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "salesTypeList." +
                                        index +
                                        ".deductiblePrice",
                                      size: "small"
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        type: "number",
                                        min: 0,
                                        disabled:
                                          _vm.ruleForm.buyType !==
                                          salesType.buyType,
                                        step: 0.01,
                                        precision: 2,
                                        controls: false,
                                        "step-strictly": "",
                                        size: "small"
                                      },
                                      model: {
                                        value: salesType.deductiblePrice,
                                        callback: function($$v) {
                                          _vm.$set(
                                            salesType,
                                            "deductiblePrice",
                                            $$v
                                          )
                                        },
                                        expression: "salesType.deductiblePrice"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("Descriptions", { attrs: { title: "预约设置" } }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "可预约身份：", prop: "memberType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.memberType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "memberType", $$v)
                    },
                    expression: "ruleForm.memberType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("全部会员")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("要客")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("365会员")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "name",
              staticClass: "form_item",
              attrs: { label: "预约时间：" }
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "reserveStartDate" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "140px" },
                        attrs: {
                          type: "date",
                          disabled: !_vm.ruleForm.activityTime.length,
                          placeholder: "开始日期",
                          clearable: false,
                          "value-format": "yyyy-MM-dd"
                        },
                        on: { change: _vm.changeReserveStartDate },
                        model: {
                          value: _vm.ruleForm.reserveStartDate,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "reserveStartDate", $$v)
                          },
                          expression: "ruleForm.reserveStartDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { prop: "reserveStartTime" }
                    },
                    [
                      _c("el-time-picker", {
                        staticStyle: { width: "140px" },
                        attrs: {
                          disabled: !_vm.ruleForm.reserveStartDate,
                          placeholder: "开始时间",
                          clearable: false,
                          "value-format": "HH:mm:ss"
                        },
                        on: { change: _vm.changeReserveStartTime },
                        model: {
                          value: _vm.ruleForm.reserveStartTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "reserveStartTime", $$v)
                          },
                          expression: "ruleForm.reserveStartTime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { prop: "reserveEndDate" }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "140px" },
                        attrs: {
                          type: "date",
                          disabled: !_vm.ruleForm.reserveStartTime,
                          placeholder: "结束日期",
                          clearable: false,
                          "value-format": "yyyy-MM-dd"
                        },
                        on: { change: _vm.changeReserveEndDate },
                        model: {
                          value: _vm.ruleForm.reserveEndDate,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "reserveEndDate", $$v)
                          },
                          expression: "ruleForm.reserveEndDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { prop: "reserveEndTime" }
                    },
                    [
                      _c("el-time-picker", {
                        staticStyle: { width: "140px" },
                        attrs: {
                          disabled: !_vm.ruleForm.reserveEndDate,
                          placeholder: "结束时间",
                          clearable: false,
                          "value-format": "HH:mm:ss"
                        },
                        model: {
                          value: _vm.ruleForm.reserveEndTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "reserveEndTime", $$v)
                          },
                          expression: "ruleForm.reserveEndTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: {
                label: "限制累计预约次数：",
                prop: "restrictionsNum",
                size: "small"
              }
            },
            [
              _c("el-input-number", {
                staticClass: "form_input-number",
                attrs: {
                  controls: false,
                  size: "small",
                  min: 0,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.ruleForm.restrictionsNum,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "restrictionsNum", _vm._n($$v))
                  },
                  expression: "ruleForm.restrictionsNum"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { label: "预约人数限制：", required: "", size: "small" }
            },
            [
              _c("el-row", { attrs: { gutter: 0, type: "flex" } }, [
                _c("span", { staticClass: "form_label" }, [_vm._v("单次限制")]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "limitNum", size: "small" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c("el-input-number", {
                              staticClass: "form_input-number",
                              attrs: {
                                controls: false,
                                size: "small",
                                min: 0,
                                placeholder: "请输入"
                              },
                              on: { change: _vm.changeLimitNum },
                              model: {
                                value: _vm.ruleForm.limitNum,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "limitNum",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "ruleForm.limitNum"
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "input-number-append" }, [
                              _vm._v("人")
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "form_label" }, [
                      _vm._v("，免费携带")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "freeNum", size: "small" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c("el-input-number", {
                              staticClass: "form_input-number",
                              attrs: {
                                controls: false,
                                size: "small",
                                min: 0,
                                placeholder: "请输入"
                              },
                              model: {
                                value: _vm.ruleForm.freeNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "freeNum", _vm._n($$v))
                                },
                                expression: "ruleForm.freeNum"
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "input-number-append" }, [
                              _vm._v("人")
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isDetail
            ? _c(
                "el-form-item",
                [
                  _vm.id
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm", "edit")
                            }
                          }
                        },
                        [_vm._v("\n         保存编辑\n       ")]
                      )
                    : _c(
                        "el-button",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm", "add")
                            }
                          }
                        },
                        [_vm._v("\n         立即新建\n       ")]
                      )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }