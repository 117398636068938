var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 团购详情 "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini"
                },
                on: {
                  click: function($event) {
                    return _vm.getBindQrcode(
                      _vm.orderRequest.orderNo,
                      "orderNo"
                    )
                  }
                }
              },
              [_vm._v("团购订单兑换二维码")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.orderDownload }
              },
              [_vm._v("\n      导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.orderList,
            height: "600",
            columns: _vm.orderTab,
            "page-obj": _vm.orderRequest
          },
          on: { pagination: _vm.setOrder }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "receiveStatus",
              label: "状态",
              align: "center",
              width: "80"
            },
            slot: "receiveStatus",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.receiveStatus == 0
                      ? _c("span", [_vm._v("未领取")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.receiveStatus == 1
                      ? _c("span", [_vm._v("已领取")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              align: "center",
              width: "120"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.receiveStatus == 0
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#409EFF",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getBindQrcode(
                                  scope.row.exchangeCode,
                                  "id"
                                )
                              }
                            }
                          },
                          [_vm._v("兑换二维码")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 白名单管理 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "WForm",
        {
          attrs: {
            "form-data": _vm.formData,
            isSubmitForm: _vm.setMenuPerms("vipPullnewSys"),
            "rule-form": _vm.ruleForm,
            "is-title": false,
            rules: _vm.rules
          },
          on: { submitForm: _vm.submitForm }
        },
        [
          _c(
            "div",
            { attrs: { slot: "whiteList" }, slot: "whiteList" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "白名单：", prop: "whiteList" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: {
                        "align-items": "center",
                        "margin-bottom": "10px"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            "line-height": "1"
                          },
                          attrs: {
                            href: _vm.templateDownload.url,
                            download: _vm.templateDownload.name
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "success",
                                icon: "el-icon-download"
                              }
                            },
                            [_vm._v(_vm._s(_vm.templateDownload.btn))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("upload-excel-component", {
                        attrs: {
                          "btn-text": "批量导入",
                          type: "btn",
                          "on-success": _vm.handleUploadSuccess,
                          "before-upload": _vm.handleBeforeUpload
                        }
                      }),
                      _vm._v(" "),
                      _vm.setMenuPerms("vipPullnewSys")
                        ? _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                title:
                                  "确认清除白名单,清除白名单后将不限制用户领取？"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.removeOrderWhite()
                                }
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "danger" },
                                  slot: "reference"
                                },
                                [_vm._v("清空白名单")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { maxRows: 10 },
                      placeholder: "请输入电话名录"
                    },
                    model: {
                      value: _vm.ruleForm.whiteList,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "whiteList", $$v)
                      },
                      expression: "ruleForm.whiteList"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("多个手机号使用英文逗号“,”隔开")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v(
                      "设置白名单仅白名单用户可以领取，不配置白名单所有用户可领取"
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _vm.errWhiteList.length > 0
                ? _c("el-form-item", { attrs: { label: "错误号码：" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          border: "1px solid red",
                          padding: "6px 10px",
                          "border-radius": "8px",
                          color: "red"
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.errWhiteList.join(",")) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              "coupon-u-uid":
                _vm.isOrderQrcede == "orderNo"
                  ? "订单全二维码"
                  : "订单单个二维码",
              "qr-code": _vm.qrCode
            },
            on: {
              closePop: function($event) {
                _vm.popShow = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }