var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [
        _vm._v("MIKA PLUS类型新增/编辑")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "typeName",
                  attrs: { label: "会员类型名称：", prop: "typeName" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.typeName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "typeName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.typeName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { ref: "originalAmount", attrs: { label: "原价：" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.originalAmount,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "originalAmount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.originalAmount"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "saleAmount",
                  attrs: { label: "售卖金额：", prop: "saleAmount" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.saleAmount,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "saleAmount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.saleAmount"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { ref: "giveScore", attrs: { label: "赠送积分：" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.giveScore,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "giveScore",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.giveScore"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "validDay",
                  attrs: { label: "生效天数：", prop: "validDay" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.validDay,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "validDay",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.validDay"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "limitType",
                  attrs: { label: "限购类型：", prop: "limitType" }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 3 },
                      model: {
                        value: _vm.ruleForm.limitType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "limitType", $$v)
                        },
                        expression: "ruleForm.limitType"
                      }
                    },
                    [_vm._v("按天")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.limitType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "limitType", $$v)
                        },
                        expression: "ruleForm.limitType"
                      }
                    },
                    [_vm._v("按月")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.ruleForm.limitType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "limitType", $$v)
                        },
                        expression: "ruleForm.limitType"
                      }
                    },
                    [_vm._v("按年")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.limitType === 3
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "limitDay",
                          attrs: { label: "限制天数：", prop: "limitDay" }
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_input",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.ruleForm.limitDay,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "limitDay",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.limitDay"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "limitYearNumber",
                          attrs: {
                            label: "全年最大数量：",
                            prop: "limitYearNumber"
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_input",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.ruleForm.limitYearNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "limitYearNumber",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.limitYearNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "limitNumber",
                  attrs: { label: "限购数量：", prop: "limitNumber" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.limitNumber,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "limitNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.limitNumber"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "plusIcon",
                  attrs: { label: "会员图标：", prop: "plusIcon" }
                },
                [
                  _c("add-load", {
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.plusIcon
                    },
                    on: { setCover: _vm.setCover }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "firstCoupons",
                  attrs: { label: "首次购买送券：", prop: "firstCoupons" }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.getRelevances("firstCoupons")
                        }
                      }
                    },
                    [_vm._v("选择优惠券")]
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.firstCoupons.length > 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "10px",
                            "margin-top": "10px",
                            border: "1px solid #CCCCCC",
                            "max-height": "300px",
                            overflow: "auto"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.ruleForm.firstCoupons.join("、")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "sendCoupons",
                  attrs: { label: "续费购买送券：", prop: "sendCoupons" }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.getRelevances("sendCoupons")
                        }
                      }
                    },
                    [_vm._v("选择优惠券")]
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.sendCoupons.length > 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "10px",
                            "margin-top": "10px",
                            border: "1px solid #CCCCCC",
                            "max-height": "300px",
                            overflow: "auto"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.ruleForm.sendCoupons.join("、")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "plusInterests",
                  attrs: { label: "plus权益：", prop: "plusInterests" }
                },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { width: 600, height: 300 },
                    model: {
                      value: _vm.ruleForm.plusInterests,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "plusInterests", $$v)
                      },
                      expression: "ruleForm.plusInterests"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("coupon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "couponPop",
        attrs: { type: "coupon" },
        on: { setCoupons: _vm.setCoupons, closeCouponPop: _vm.closeCouponPop }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }