var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("会员价-基础方案")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "策略名称：", prop: "cdtionName" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.cdtionName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "cdtionName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.cdtionName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "折扣率：", prop: "discountRate" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.discountRate,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "discountRate",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.discountRate"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态：", prop: "cditionStatus" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.cditionStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "cditionStatus", $$v)
                        },
                        expression: "ruleForm.cditionStatus"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: -1 },
                      model: {
                        value: _vm.ruleForm.cditionStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "cditionStatus", $$v)
                        },
                        expression: "ruleForm.cditionStatus"
                      }
                    },
                    [_vm._v("停用")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "集团承担比：", prop: "cliqueRate" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.cliqueRate,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "cliqueRate",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.cliqueRate"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动时间：", prop: "time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.ruleForm.time,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "time", $$v)
                      },
                      expression: "ruleForm.time"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注：", prop: "cditionRemark" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.cditionRemark,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "cditionRemark",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.cditionRemark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("integral", {
                ref: "integralRef",
                attrs: { form: _vm.ruleForm },
                on: {
                  setcompanyId: _vm.setcompanyId,
                  submitForm: _vm.submit,
                  setStoreId: _vm.setStoreId
                }
              }),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }