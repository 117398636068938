var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c("div", { staticClass: "view_title" }, [_vm._v("基础信息")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "券包名称：", prop: "name" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { maxlength: "20" },
                  model: {
                    value: _vm.ruleForm.name,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "name",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.name"
                  }
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(
                      _vm._s(_vm.ruleForm.name ? _vm.ruleForm.name.length : 0) +
                        "/20"
                    )
                  ])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "一句话描述：", prop: "description" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { maxlength: "50" },
                  model: {
                    value: _vm.ruleForm.description,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "description",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.description"
                  }
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(
                      _vm._s(
                        _vm.ruleForm.description
                          ? _vm.ruleForm.description.length
                          : 0
                      ) + "/50"
                    )
                  ])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "列表图：", prop: "tableImage" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: {
                  width: 100,
                  height: 100,
                  cover: _vm.ruleForm.tableImage
                },
                on: { setCover: _vm.setCover }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "form_remark" }, [
                _vm._v("弹窗尺寸为100*100像素")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "view_title" }, [_vm._v("配置券包")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "coupons", attrs: { label: "券包信息：", prop: "coupons" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "200px" },
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.getRelevances }
                },
                [_vm._v("选择")]
              ),
              _vm._v(" "),
              _c(
                "w-table",
                {
                  attrs: {
                    "table-data": _vm.ruleForm.coupons,
                    columns: _vm.columns,
                    size: "mini",
                    height: "500"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      slot: "total",
                      label: "数量",
                      align: "center",
                      width: "170"
                    },
                    slot: "total",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop: "coupons." + scope.$index + ".total",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入数量",
                                      trigger: "blur"
                                    }
                                  ]
                                }
                              },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    max: 99,
                                    min: 1,
                                    step: 1,
                                    "step-strictly": ""
                                  },
                                  model: {
                                    value: scope.row.total,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "total", $$v)
                                    },
                                    expression: "scope.row.total"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      slot: "operate",
                      label: "操作",
                      fixed: "right",
                      width: "100"
                    },
                    slot: "operate",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认删除该优惠券包？" },
                                on: {
                                  confirm: function($event) {
                                    return _vm.goDel(scope)
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "danger",
                                      size: "mini"
                                    },
                                    slot: "reference"
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "view_title" }, [_vm._v("投放规则")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "领取周期：", prop: "receiveBeginTime" } },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "receiveBeginTime",
                      attrs: { prop: "receiveBeginTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择开始时间"
                        },
                        model: {
                          value: _vm.ruleForm.receiveBeginTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "receiveBeginTime", $$v)
                          },
                          expression: "ruleForm.receiveBeginTime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "receiveEndTime",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { "label-width": "0", prop: "receiveEndTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: !_vm.ruleForm.receiveBeginTime,
                          "picker-options": _vm.pickerTime,
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择结束时间"
                        },
                        model: {
                          value: _vm.ruleForm.receiveEndTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "receiveEndTime", $$v)
                          },
                          expression: "ruleForm.receiveEndTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "投放总量：", prop: "totalNumber" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { type: "number" },
                model: {
                  value: _vm.ruleForm.totalNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "totalNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.totalNumber"
                }
              }),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "form_remark", staticStyle: { color: "red" } },
                [_vm._v("修改券包会重置券包数量库存")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "限领数量：", prop: "limitNumber" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { type: "number" },
                model: {
                  value: _vm.ruleForm.limitNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "limitNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.limitNumber"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "form_remark" }, [
                _vm._v("一个用户限领/限购的数量")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "salestypes",
              staticClass: "form_item",
              attrs: { label: "领取门槛：", required: "", prop: "salestypes" }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  ref: "priceIntegral",
                  staticClass: "coupon_price",
                  attrs: { size: "small" },
                  on: { change: _vm.buTypeChange },
                  model: {
                    value: _vm.ruleForm.salestypes,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "salestypes", $$v)
                    },
                    expression: "ruleForm.salestypes"
                  }
                },
                [
                  _vm._l(_vm.ruleForm.payType, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "buyType" },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticStyle: { width: "160px" },
                                attrs: {
                                  label: item.type,
                                  disabled:
                                    _vm.ruleForm.salestypes.indexOf(4) != -1 &&
                                    item.type != 4
                                }
                              },
                              [_vm._v(_vm._s(item.label))]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex_between",
                            staticStyle: { width: "600px" }
                          },
                          [
                            item.type == 1 || item.type == 3 || item.type == 5
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: " flex",
                                    staticStyle: { "font-size": "14px" },
                                    attrs: {
                                      "label-width": "0",
                                      prop: "payType." + index + ".price",
                                      rules: [
                                        {
                                          required:
                                            _vm.ruleForm.salestypes.indexOf(
                                              item.type
                                            ) >= 0,
                                          message: "请输入销售价格"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        disabled:
                                          _vm.ruleForm.salestypes.indexOf(4) !=
                                            -1 && item.type != 4
                                      },
                                      model: {
                                        value: item.price,
                                        callback: function($$v) {
                                          _vm.$set(item, "price", $$v)
                                        },
                                        expression: "item.price"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "form_remark" }, [
                                      _vm._v("元")
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type == 3 || item.type == 5
                              ? _c(
                                  "div",
                                  { staticStyle: { "font-size": "14px" } },
                                  [_vm._v("+")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type == 2 || item.type == 3 || item.type == 5
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "flex",
                                    staticStyle: { "font-size": "14px" },
                                    attrs: {
                                      "label-width": "0",
                                      prop: "payType." + index + ".integral",
                                      rules: [
                                        {
                                          required:
                                            _vm.ruleForm.salestypes.indexOf(
                                              item.type
                                            ) >= 0 && item.type != 5,
                                          message: "请输入销售积分"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        disabled:
                                          _vm.ruleForm.salestypes.indexOf(4) !=
                                            -1 && item.type != 4
                                      },
                                      model: {
                                        value: item.integral,
                                        callback: function($$v) {
                                          _vm.$set(item, "integral", $$v)
                                        },
                                        expression: "item.integral"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "form_remark" }, [
                                      _vm._v("积分")
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.type != 4,
                                        expression: "item.type != 4"
                                      }
                                    ],
                                    attrs: { "label-width": "0", prop: "show" }
                                  },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        value: _vm.ruleForm.show,
                                        "active-value": item.type,
                                        "inactive-value": 0,
                                        "active-color": "#13ce66",
                                        "inactive-color": "#ff4949",
                                        "active-text": "列表显示",
                                        disabled:
                                          _vm.ruleForm.salestypes.indexOf(
                                            item.type
                                          ) == -1 || item.type == 5
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.statusChange($event, index)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v(
                      "周期活动价格，仅在周期推荐中有效；配置后周期推荐商品购买有效"
                    )
                  ])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "labelContent",
              attrs: { prop: "labelContent", label: "标签选择：" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "输入标签名称搜索~",
                    "value-key": "id",
                    clearable: ""
                  },
                  model: {
                    value: _vm.ruleForm.labelContent,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "labelContent", $$v)
                    },
                    expression: "ruleForm.labelContent"
                  }
                },
                _vm._l(_vm.userLabelList, function(so) {
                  return _c("el-option", {
                    key: so.id,
                    attrs: { label: "" + so.labelName, value: so.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "规则说明：", prop: "ruleDescription" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 600, height: 300 },
                model: {
                  value: _vm.ruleForm.ruleDescription,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "ruleDescription", $$v)
                  },
                  expression: "ruleForm.ruleDescription"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "p",
                { staticClass: "form_remark", staticStyle: { color: "red" } },
                [_vm._v("修改券包会重置券包数量库存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "200px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("coupon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "couponPop",
        on: { setCoupons: _vm.setCoupons, closeCouponPop: _vm.closeCouponPop }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }