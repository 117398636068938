var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("页面新增/编辑")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            size: "small"
          }
        },
        [
          _c("el-form-item", { attrs: { label: "最后修改时间：" } }, [
            _vm._v("\n      " + _vm._s(_vm.modifyTime) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "页面编码：", prop: "pageCode" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "300px" },
                model: {
                  value: _vm.ruleForm.pageCode,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "pageCode", $$v)
                  },
                  expression: "ruleForm.pageCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "页面名称：", prop: "pageName" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "300px" },
                model: {
                  value: _vm.ruleForm.pageName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "pageName", $$v)
                  },
                  expression: "ruleForm.pageName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "页面背景色：" } },
            [
              _c("el-color-picker", {
                model: {
                  value: _vm.ruleForm.pageColor,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "pageColor", $$v)
                  },
                  expression: "ruleForm.pageColor"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.setMenuPerms("pageRelStore")
            ? _c(
                "el-form-item",
                { attrs: { label: "关联门店：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "maxWidth",
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择页面关联门店"
                      },
                      model: {
                        value: _vm.ruleForm.templateStoreId,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "templateStoreId", $$v)
                        },
                        expression: "ruleForm.templateStoreId"
                      }
                    },
                    _vm._l(_vm.storeList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: {
                            label: item.shortName + item.storeCode,
                            value: item.storeId
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.shortName) +
                              "(" +
                              _vm._s(item.storeCode) +
                              ")\n        "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "form_remark",
                      staticStyle: { "margin-left": "10px", color: "red" }
                    },
                    [_vm._v("一个门店只关联一个页面")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "页面模板：", prop: "classId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "maxWidth",
                          attrs: {
                            filterable: "",
                            placeholder: "请选择页面模板分类"
                          },
                          on: { change: _vm.setPageCode },
                          model: {
                            value: _vm.ruleForm.classId,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "classId", $$v)
                            },
                            expression: "ruleForm.classId"
                          }
                        },
                        _vm._l(_vm.temClass, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.className, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0", prop: "tmplateId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "maxWidth",
                          attrs: {
                            filterable: "",
                            placeholder: "请选择页面模板"
                          },
                          model: {
                            value: _vm.ruleForm.tmplateId,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "tmplateId", $$v)
                            },
                            expression: "ruleForm.tmplateId"
                          }
                        },
                        _vm._l(_vm.pageTem, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.tmplateName, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即创建")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }