"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _member = require("@/api/member.js");

var _mem = require("@/api/mem.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      time: null,
      storeList: [],
      requestData: {
        storeCode: null,
        mobile: null,
        name: null,
        startTime: null,
        endTime: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '用户昵称',
        prop: 'realname',
        width: 80
      }, {
        label: '手机号码',
        prop: 'mobile',
        width: 60
      }, {
        label: '拉新人门店',
        prop: 'storeId',
        width: 60
      }, {
        label: '员工手机号',
        prop: 'managerMobile',
        width: 60
      }, {
        label: '员工',
        prop: 'managerName',
        width: 60
      }, {
        label: '拉新来源',
        prop: 'source',
        width: 60
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 120
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status'
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      console.log(res);
      _this.storeList = res.data;
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this2.requestData[key]) {
          data[key] = _this2.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _member.listPopularizeUser)(data).then(function (res) {
        res.data.records.forEach(function (item) {
          if (!_this2.setMenuPerms('user_mobile_idCard')) {
            if (item.mobile) {
              item.mobile = item.mobile.replace(item.mobile.substring(3, 7), "****");
            }
          }

          var timestamp4 = new Date(item.createTime);
          item.createTime = timestamp4.toLocaleDateString().replace(/\//g, '-') + ' ' + timestamp4.toTimeString().substr(0, 8);
        });
        list = res.data.records;
        _this2.downloadLoading = false;

        _this2.getHandleDownload(tHeader, filterVal, list, '普通用户拉新列表');
      }).catch(function (res) {
        _this2.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _member.listPopularizeUser)(this.requestData).then(function (res) {
        res.data.records.forEach(function (item) {
          if (!_this3.setMenuPerms('user_mobile_idCard')) {
            if (item.mobile) {
              item.mobile = item.mobile.replace(item.mobile.substring(3, 7), "****");
            }
          }

          var timestamp4 = new Date(item.createTime);
          item.createTime = timestamp4.toLocaleDateString().replace(/\//g, '-') + ' ' + timestamp4.toTimeString().substr(0, 8);
        });
        _this3.tableData = res.data.records;
        _this3.requestData.total = res.data.total;
      });
    }
  }
};
exports.default = _default;