"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addScoreBasic = addScoreBasic;
exports.addScoreCdition = addScoreCdition;
exports.addScoreSet = addScoreSet;
exports.addScoreSpecial = addScoreSpecial;
exports.editScoreBasic = editScoreBasic;
exports.editScoreCdition = editScoreCdition;
exports.editScoreSet = editScoreSet;
exports.editScoreSpecial = editScoreSpecial;
exports.getScoreBasic = getScoreBasic;
exports.getScoreCdition = getScoreCdition;
exports.getScoreSet = getScoreSet;
exports.getScoreSpecial = getScoreSpecial;
exports.getTotalScoreByMobile = getTotalScoreByMobile;
exports.listBrandClass = listBrandClass;
exports.listBrandPage = listBrandPage;
exports.listGoodsPage = listGoodsPage;
exports.listIstionByType = listIstionByType;
exports.listOrderItemScore = listOrderItemScore;
exports.listScoreBasicGoodsPage = listScoreBasicGoodsPage;
exports.listScoreBasicPage = listScoreBasicPage;
exports.listScoreCditionPage = listScoreCditionPage;
exports.listScoreSet = listScoreSet;
exports.listScoreSpecialGoodsPage = listScoreSpecialGoodsPage;
exports.listScoreSpecialPage = listScoreSpecialPage;
exports.listSource = listSource;
exports.listUserScorePage = listUserScorePage;
exports.userScoreChanges = userScoreChanges;

var _request = _interopRequireDefault(require("@/utils/request"));

function getScoreSet(data) {
  // 查询积分设置
  return (0, _request.default)({
    url: '/api/scoreBasic/getScoreSet/' + data,
    method: 'post'
  });
}

function listScoreSet(data) {
  // 查询积分设置列表
  return (0, _request.default)({
    url: '/api/scoreBasic/listScoreSet',
    method: 'post',
    data: data
  });
}

function editScoreSet(data) {
  // 编辑积分设置
  return (0, _request.default)({
    url: '/api/scoreBasic/editScoreSet',
    method: 'post',
    data: data
  });
}

function addScoreSet(data) {
  // 新增积分设置
  return (0, _request.default)({
    url: '/api/scoreBasic/addScoreSet',
    method: 'post',
    data: data
  });
}

function listSource(data) {
  // 系统列表
  return (0, _request.default)({
    url: '/api/scoreBasic/listSource',
    method: 'post',
    data: data
  });
}

function listIstionByType(type) {
  // 根据系统id获取机构
  return (0, _request.default)({
    url: "/api/institutionInfo/listIstionByType/".concat(type),
    method: 'post' //   params: data

  });
}

function listBrandClass(data) {
  // 品类
  return (0, _request.default)({
    url: '/api/scoreBasic/listBrandClass',
    method: 'post',
    data: data
  });
}

function listBrandPage(data) {
  // 品牌
  return (0, _request.default)({
    url: '/api/scoreBasic/listBrandPage',
    method: 'post',
    data: data
  });
}

function listGoodsPage(data) {
  // 商品
  return (0, _request.default)({
    url: '/api/scoreBasic/listGoodsPage',
    method: 'post',
    data: data
  });
}

function addScoreBasic(data) {
  // 新增基础积分策略
  return (0, _request.default)({
    url: '/api/scoreBasic/addScoreBasic',
    method: 'post',
    data: data
  });
}

function editScoreBasic(data) {
  // 编辑基础积分策略
  return (0, _request.default)({
    url: '/api/scoreBasic/editScoreBasic',
    method: 'post',
    data: data
  });
}

function getScoreBasic(basicId) {
  // 查询基础积分策略明细
  return (0, _request.default)({
    url: "/api/scoreBasic/getScoreBasic/".concat(basicId),
    method: 'post' //   params: data

  });
}

function listScoreBasicPage(data) {
  // 基础积分策略列表
  return (0, _request.default)({
    url: '/api/scoreBasic/listScoreBasicPage',
    method: 'post',
    data: data
  });
}

function addScoreCdition(data) {
  // 新增条件限制积分策略
  return (0, _request.default)({
    url: '/api/scoreCdition/addScoreCdition',
    method: 'post',
    data: data
  });
}

function listScoreCditionPage(data) {
  // 条件限制积分策略列表
  return (0, _request.default)({
    url: '/api/scoreCdition/listScoreCditionPage',
    method: 'post',
    data: data
  });
}

function editScoreCdition(data) {
  // 编辑条件限制积分策略
  return (0, _request.default)({
    url: '/api/scoreCdition/editScoreCdition',
    method: 'post',
    data: data
  });
}

function getScoreCdition(cditionId) {
  // 查询条件限制积分策略明细
  return (0, _request.default)({
    url: "/api/scoreCdition/getScoreCdition/".concat(cditionId),
    method: 'post' //   params: data

  });
}

function listScoreSpecialPage(data) {
  // 特殊积分分页
  return (0, _request.default)({
    url: '/api/scoreSpecial/listScoreSpecialPage',
    method: 'post',
    data: data
  });
}

function addScoreSpecial(data) {
  // 新增特殊积分
  return (0, _request.default)({
    url: '/api/scoreSpecial/addScoreSpecial',
    method: 'post',
    data: data
  });
}

function getScoreSpecial(data) {
  // 特殊积分详情
  return (0, _request.default)({
    url: '/api/scoreSpecial/getScoreSpecial/' + data,
    method: 'post' // data

  });
}

function editScoreSpecial(data) {
  // 编辑特殊积分
  return (0, _request.default)({
    url: '/api/scoreSpecial/editScoreSpecial',
    method: 'post',
    data: data
  });
}

function getTotalScoreByMobile(mobile) {
  // 手机号查积分
  return (0, _request.default)({
    url: '/api/userScore/getTotalScoreByMobile/' + mobile,
    method: 'post'
  });
}

function userScoreChanges(data) {
  // 手工补录
  return (0, _request.default)({
    url: '/api/userScore/userScoreChanges',
    method: 'post',
    data: data
  });
}

function listUserScorePage(data) {
  // 手工补录记录
  return (0, _request.default)({
    url: '/api/userScore/listUserScorePage',
    method: 'post',
    data: data
  });
}

function listOrderItemScore(data) {
  // 手工补录记录
  return (0, _request.default)({
    url: '/api/userScore/listOrderItemScore',
    loading: 'loading',
    method: 'post',
    data: data
  });
}
/*

 1、商品基础积分分页：/api/scoreBasic/listScoreBasicGoodsPage
 入参：{"pageNo":"当前页","pageSize":"页条数","companyCode":"公司编码","classCode":"分类编码","brandCode":"品牌编码","goodsCode":"商品编码"}

 出参：basicCode; // 基础策略编码
  companyCode; // 公司编码
  companyName; // 公司名称
  classCode; // 分类编码
  brandCode; // 品牌编码
  goodsCode; // 商品编码
  sellAmount; // 消费金额
  getScore; // 获得积分
  validStatus; // 有效状态(1：启用 -1：停用)
   */


function listScoreBasicGoodsPage(data) {
  // 手工补录记录
  return (0, _request.default)({
    url: '/api/scoreBasic/listScoreBasicGoodsPage',
    method: 'post',
    data: data
  });
}
/*
 2、商品特殊积分策略分页：/api/scoreSpecial/listScoreSpecialGoodsPage
 入参：{"pageNo":"当前页","pageSize":"页条数","companyCode":"公司编码","classCode":"分类编码","brandCode":"品牌编码","goodsCode":"商品编码"}

 出参：specialName; // 特殊策略名称
             companyCode; // 公司编码
  companyName; // 公司名称
  classCode; // 分类编码
  brandCode; // 品牌编码
  goodsCode; // 商品编码
  scoreMultiple; // 积分倍数(0：不积分 >0：倍数)
  validStartTime; // 执行开始时间
  validEndTime;  // 执行结束时间
  validStatus; // 有效状态(1：启用 -1：停用)
 */


function listScoreSpecialGoodsPage(data) {
  // 手工补录记录
  return (0, _request.default)({
    url: '/api/scoreSpecial/listScoreSpecialGoodsPage',
    method: 'post',
    data: data
  });
}