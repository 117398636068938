var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { overflow: "auto" } },
    [
      _c(
        "el-form",
        {
          ref: "formUser",
          attrs: {
            model: _vm.userForm,
            rules: _vm.userRules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "真实姓名：", prop: "realname" } },
                [
                  _c("el-input", {
                    staticClass: "maxWidth",
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.userForm.realname,
                      callback: function($$v) {
                        _vm.$set(_vm.userForm, "realname", $$v)
                      },
                      expression: "userForm.realname"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "性　　别：", prop: "sex" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择性别" },
                      model: {
                        value: _vm.userForm.sex,
                        callback: function($$v) {
                          _vm.$set(_vm.userForm, "sex", $$v)
                        },
                        expression: "userForm.sex"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "男", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "女", value: 2 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "未知", value: 0 } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6, offset: 1 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "民　　族：", prop: "nation" } },
                [
                  _c("el-input", {
                    staticClass: "maxWidth",
                    attrs: { placeholder: "请输入民族" },
                    model: {
                      value: _vm.userForm.nation,
                      callback: function($$v) {
                        _vm.$set(_vm.userForm, "nation", $$v)
                      },
                      expression: "userForm.nation"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "出生日期：", prop: "birthday" } },
                [
                  _c("el-date-picker", {
                    staticClass: "maxWidth",
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期"
                    },
                    model: {
                      value: _vm.userForm.birthday,
                      callback: function($$v) {
                        _vm.$set(_vm.userForm, "birthday", $$v)
                      },
                      expression: "userForm.birthday"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6, offset: 7 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "身份证号：", prop: "idNo" } },
                [
                  _c("el-input", {
                    staticClass: "maxWidth",
                    attrs: { placeholder: "请输入身份证号" },
                    model: {
                      value: _vm.userForm.idNo,
                      callback: function($$v) {
                        _vm.$set(_vm.userForm, "idNo", $$v)
                      },
                      expression: "userForm.idNo"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4, offset: 1 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: !_vm.user.uid
                  },
                  on: { click: _vm.setUser }
                },
                [_vm._v("保存身份资料")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }