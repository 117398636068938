"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.function.name");

var _mem = require("@/api/mem");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      sysName: '',
      name: '',
      sort: '',
      radio: '1',
      radioTxt1: '',
      radioTxt2: '',
      inputShow: 'true'
    };
  },
  watch: {
    radio: function radio() {
      this.inputShow = !this.inputShow;
    }
  },
  methods: {
    addMember: function addMember() {
      var _this = this;

      if (this.sysName.trim() === '') {
        this.$message.error('请填写体系名称');
      } else if (this.name.trim() === '') {
        this.$message.error('请填写会员卡名称');
      } else if (this.sort === '') {
        this.$message.error('请填写排序号');
      } else {
        var data = {
          sort: this.sort,
          sysName: this.sysName,
          name: this.name
        };
        (0, _mem.addCardMember)(data).then(function (res) {
          if (res.ret === 1000) {
            _this.$message({
              message: '恭喜你，添加成功',
              type: 'success'
            });

            _this.$router.push('/system/mem/new1');
          } else {
            _this.$message.error('添加失败，完善信息重试');
          }
        });
      }
    }
  }
};
exports.default = _default;