"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _elementChinaAreaData = require("element-china-area-data");

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      regionData: _elementChinaAreaData.provinceAndCityData,
      rules: {
        freightName: [{
          required: true,
          message: '请填写运费模板名称'
        }],
        freightStatus: [{
          required: true,
          message: '请选择状态'
        }]
      },
      form: {
        id: '',
        freightName: '',
        freightStatus: '',
        areaList: [{
          freightType: 1,
          areaCode: '000000',
          firstNumber: '',
          firstAmount: '',
          secondNumber: '',
          secondAmount: ''
        }],
        assignList: [],
        freightItems: []
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    console.log(JSON.stringify(_elementChinaAreaData.provinceAndCityData));
    console.log(_elementChinaAreaData.provinceAndCityData);
    this.form.id = this.$route.query.id || '';

    if (this.form.id) {
      this.form.id = Number(this.form.id);
      (0, _api.getFreight)(this.form.id).then(function (res) {
        _this.form.freightName = res.data.freightName;
        _this.form.freightStatus = res.data.freightStatus;
        var brr = res.data.freightItems.some(function (item) {
          return item.areaCode == '000000';
        });

        if (brr) {
          _this.form.areaList = [];
        }

        res.data.freightItems.forEach(function (item) {
          if (item.freightType == 1) {
            _this.form.areaList.push(item);
          } else {
            _this.form.assignList.push(item);
          }
        });
      });
    }
  },
  methods: {
    delAssign: function delAssign(row, index, type) {
      var _this2 = this;

      this.$confirm('此操作将永久删除该项数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (row.id) {
          (0, _api.delFreightItem)(row.id).then(function (res) {
            if (type == 'assign') {
              _this2.form.assignList.splice(index, 1);
            } else {
              _this2.form.areaList.splice(index, 1);
            }

            _this2.$message({
              type: 'success',
              message: '删除成功!'
            });
          });
        } else {
          if (type == 'assign') {
            _this2.form.assignList.splice(index, 1);
          } else {
            _this2.form.areaList.splice(index, 1);
          }
        }
      }).catch(function () {});
    },
    goAddFreight: function goAddFreight(type) {
      console.log(type);

      if (type == 1) {
        this.form.areaList.push({
          freightType: type,
          areaCode: '',
          firstNumber: '',
          firstAmount: '',
          secondNumber: '',
          secondAmount: ''
        });
      } else {
        this.form.assignList.push({
          freightType: type,
          areaCode: '',
          firstNumber: '',
          firstAmount: '',
          secondNumber: '',
          secondAmount: ''
        });
      }
    },
    onSubmit: function onSubmit(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.openLoading();

          _this3.form.freightItems = [].concat((0, _toConsumableArray2.default)(_this3.form.areaList), (0, _toConsumableArray2.default)(_this3.form.assignList));

          if (_this3.form.id) {
            (0, _api.editFreight)(_this3.form).then(function (res) {
              _this3.openLoading().close();

              _this3.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this3.$router.go(-1);
            }).catch(function (err) {
              _this3.openLoading().close();
            });
          } else {
            (0, _api.addFreight)(_this3.form).then(function (res) {
              _this3.openLoading().close();

              _this3.$message({
                message: '保存成功',
                type: 'success'
              });

              _this3.$router.go(-1);
            }).catch(function (err) {
              _this3.openLoading().close();
            });
          }
        }
      });
    }
  }
};
exports.default = _default;