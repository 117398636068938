"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.regexp.split");

var _api = require("./api.js");

var _mem = require("@/api/mem.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _self = this;

    return {
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.startTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      storeList: [],
      rules: {
        storeCode: [{
          required: true,
          message: '请选择门店编码'
        }],
        storeName: [{
          required: true,
          message: '请输入门店名称'
        }],
        discountFlag: [{
          required: true,
          message: '请输入优惠标识'
        }],
        limitGoods: [{
          required: true,
          message: '请上输入限制商品'
        }],
        endTime: [{
          required: true,
          message: '请选择时间'
        }],
        startTime: [{
          required: true,
          message: '请选择时间'
        }]
      },
      ruleForm: {
        storeCode: null,
        storeName: null,
        discountFlag: null,
        limitGoods: null,
        startTime: null,
        endTime: null
      }
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.storeCode) {
      this.ruleForm = this.$route.query;
      this.ruleForm.limitGoods = this.ruleForm.limitGoods.join(',');
    }

    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.storeList = res.data;
    });
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var formData = Object.assign({}, _this2.ruleForm);

          var list = _this2.ruleForm.limitGoods.split(',');

          formData.limitGoods = list;

          var storeItem = _this2.storeList.find(function (item) {
            return item.storeCode == _this2.ruleForm.storeCode;
          });

          formData.storeName = storeItem.shortName;
          (0, _api.save)(formData).then(function (res) {
            _this2.$router.go(-1);

            _this2.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;