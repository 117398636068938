"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

var _test = _interopRequireDefault(require("../public/log/test.vue"));

var _pageUrl = _interopRequireDefault(require("@/utils/pageUrl.json"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  components: {
    JsonView: _test.default
  },
  data: function data() {
    return {
      pageUrlData: {},
      jsonViewTitle: null,
      isShowPop: false,
      imgSrc: this.$store.state.user.imgSrc,
      tableData: [],
      requestData: {
        couponId: null,
        pageSize: 10,
        page: 1,
        path: ''
      },
      jsonViewData: null,
      searchData: [{
        type: 'radio',
        span: 4,
        placeholder: '事件类型',
        value: 'type',
        select: [{
          value: 'onShow',
          label: '进入页面'
        }, {
          value: 'onHide',
          label: '离开页面'
        }, {
          value: 'onUnload',
          label: '卸载页面'
        }, {
          value: 'tap',
          label: '点击事件'
        }]
      }, {
        type: 'radio',
        span: 4,
        placeholder: '是否登录',
        value: 'login',
        select: [{
          value: 'true',
          label: '已登录'
        }, {
          value: 'false',
          label: '未登录'
        }]
      }, {
        type: 'input',
        span: 4,
        placeholder: '手机号码',
        value: 'mobile'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '访问路径',
        value: 'path',
        select: this.pageUrlArr
      }, {
        type: 'input',
        span: 4,
        placeholder: '事件名称',
        value: 'name'
      }, {
        type: 'input',
        span: 4,
        placeholder: '周期ID',
        value: 'rid'
      }],
      pageUrlArr: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    var pageUrl = {};

    _pageUrl.default.pages.forEach(function (item) {
      pageUrl[item.path] = item.style.navigationBarTitleText;
    });

    var subPackagesUrl = {};

    _pageUrl.default.subPackages.forEach(function (item) {
      item.pages.forEach(function (row) {
        subPackagesUrl[item.root + '/' + row.path] = row.style.navigationBarTitleText;
      });
    });

    this.pageUrlData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, pageUrl), subPackagesUrl);
    this.pageUrlArr = [];
    Object.keys(this.pageUrlData).map(function (key, item) {
      _this.pageUrlArr.push({
        value: key,
        label: _this.pageUrlData[key]
      });
    });
    this.searchData[3].select = this.pageUrlArr; // this.$refs.wTable.setRequestValue('path', 'legou/pages/new-order-submit/new-order-submit')
  },
  methods: {
    goOpenJson: function goOpenJson(row, type) {
      row.params = JSON.parse(row[type]);
      row.params.forEach(function (item) {
        if (item.value) {
          item.value = JSON.parse(item.value);
          /* console.log(item.value)
          console.log(JSON.parse(item.value)) */
        }
      });
      this.jsonViewData = row;
      this.jsonViewTitle = type;
      this.isShowPop = true;
    }
  }
};
exports.default = _default;