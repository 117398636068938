"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '优惠券管理',
      list: [{
        tit: '优惠券列表',
        txtArr: '优惠券列表',
        imgPath: require('@/assets/tab/23.png'),
        path: '/coupon/coupon1/couponList'
      }, {
        tit: '新增优惠券',
        txtArr: '新增优惠券',
        imgPath: require('@/assets/tab/14.png'),
        path: '/coupon/coupon1/addCoupon'
      }, {
        tit: '券核销记录',
        txtArr: '券核销记录',
        imgPath: require('@/assets/tab/14.png'),
        path: '/coupon/coupon2/record'
      }, {
        tit: '茅台活动券',
        txtArr: '茅台活动券',
        imgPath: require('@/assets/tab/coupon3.png'),
        path: '/report/mtReport'
      }, {
        tit: '优惠券统计',
        txtArr: '优惠券统计',
        imgPath: require('@/assets/tab/10.png'),
        path: '/report/couponReport'
      }, {
        tit: '优惠券订单',
        txtArr: '优惠券订单',
        imgPath: require('@/assets/tab/flow1.png'),
        path: '/report/couponOrder'
      }, {
        tit: '礼品券统计',
        txtArr: '礼品券统计',
        imgPath: require('@/assets/tab/16.png'),
        path: '/report/giftCouponReport'
      }]
    };
  }
};
exports.default = _default;