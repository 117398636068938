"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _auth = require("@/utils/auth");

var _api = require("./api.js");

var _api2 = require("../api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    var _self = this;

    return {
      payTypeList: [],
      dialogImageUrl: '',
      action: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
      imgSrc: this.$store.state.user.imgSrc,
      isGoPay: false,
      orderAmount: '',
      ruleForm: {
        payPrice: null,
        id: null,
        remark: null,
        payType: null,
        orderAmount: null,
        payAttachment: [],
        descriptionId: null,
        payCategoryList: [{
          payCategoryId: null,
          payPrice: null
        }]
      },
      rules: {
        payPrice: [{
          validator: function validator(rule, value, callback) {
            if (value != _self.orderAmount) {
              callback(new Error("\u652F\u4ED8\u91D1\u989D\u9700\u7B49\u4E8E".concat(_self.orderAmount, "\u5143")));
            } else {
              callback();
            }
          }
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        payType: [{
          required: true,
          message: '请选择支付方式'
        }],
        orderAmount: [{
          required: true,
          message: '请输入支付金额'
        }],
        payAttachment: [{
          required: true,
          message: '请上传附件'
        }]
      },
      popShow: false,
      time: null,
      downloadLoading: false,
      requestData: {
        orderNo: null,
        phone: null,
        buyUser: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '订单号',
        prop: 'orderNo',
        width: 80
      }, {
        label: '购买人',
        prop: 'buyUser',
        width: 100
      }, {
        label: '购买人手机号码',
        prop: 'phone',
        width: 100
      }, {
        label: '购买数量',
        prop: 'buyNumber',
        width: 60
      }, {
        label: '订单金额',
        prop: 'orderAmount',
        width: 60
      }, {
        slot: 'isRebate'
      }, {
        slot: 'status'
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'operate'
      }]
    };
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        'Token': (0, _auth.getToken)()
      };
    }
  },
  watch: {
    time: function time(value) {
      if (value) {
        this.requestData.activityStartTime = value[0];
        this.requestData.activityEndTime = value[0];
      } else {
        this.requestData.activityStartTime = null;
        this.requestData.activityEndTime = null;
      }
    }
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {
    var _this = this;

    (0, _api2.payList)().then(function (res) {
      _this.payTypeList = res.data;
      console.log(res);
    });
  },
  methods: {
    delPayType: function delPayType(index) {
      this.ruleForm.payCategoryList.splice(index, 1);
    },
    rebatePriceChange: function rebatePriceChange(e) {
      var rebatePrice = 0;
      this.ruleForm.payCategoryList.forEach(function (item) {
        rebatePrice = rebatePrice + Number(item.payPrice);
      });
      this.ruleForm.payPrice = rebatePrice;
    },
    addPayType: function addPayType() {
      this.ruleForm.payCategoryList.push({
        payCategoryId: null,
        payPrice: null
      });
    },
    goCancelOrder: function goCancelOrder(row) {
      var _this2 = this;

      (0, _api.cancelOrder)(row.id).then(function (res) {
        _this2.getList();
      });
    },
    gostoreOrder: function gostoreOrder(row) {
      var _this3 = this;

      (0, _api.storeOrder)({
        id: row.id,
        orderNo: row.orderNo
      }).then(function (res) {
        console.log(res);

        _this3.getList();
      });
    },
    openPay: function openPay(row) {
      this.ruleForm.id = row.id; // this.ruleForm.descriptionId = row.orderNo

      this.orderAmount = row.orderAmount;
      this.isGoPay = true;
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.ruleForm));
          data.payAttachment = data.payAttachment.join(',');
          (0, _api.waitConfirm)(data).then(function (res) {
            _this4.isGoPay = false;

            _this4.getList();

            _this4.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
        } else {
          return false;
        }
      });
    },
    onSuccess: function onSuccess(res) {
      var _this5 = this;

      this.dialogVisible = true;

      if (res.ret === 1000) {
        this.ruleForm.payAttachment.push(res.data);
      } else if (res.ret === 6000) {
        this.$confirm(res.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this5.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this6 = this;

      this.ruleForm.payAttachment = [];
      fileList.forEach(function (item) {
        _this6.ruleForm.payAttachment.push(item.response.data);
      });
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    goAudit: function goAudit(scope, status) {
      var _this7 = this;

      (0, _api.recordsConfirm)({
        id: scope.row.id,
        status: status
      }).then(function (res) {
        _this7.getList();
      });
    },
    handleDownload: function handleDownload() {
      var _this8 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this8.requestData[key]) {
          data[key] = _this8.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.classPage)(data).then(function (res) {
        list = res.data.records;
        _this8.downloadLoading = false;

        _this8.getHandleDownload(tHeader, filterVal, list, '卡片销售列表');
      }).catch(function (res) {
        _this8.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this9 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.classPage)(this.requestData).then(function (res) {
        _this9.tableData = res.data.records;
        _this9.requestData.total = res.data.total;
        return;
      });
    }
  }
};
exports.default = _default;