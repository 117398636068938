"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _tableList = _interopRequireDefault(require("@/components/tableList"));

var _coupon = require("@/api/coupon.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default
  },
  data: function data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      btn: '查看详情',
      operation: 2,
      btnType: 'primary',
      tableData: [1],
      tabTh: [//				{
      //					tit:"ID",
      //					prop:'id'
      //				},
      {
        tit: '活动编号',
        prop: 'name'
      }, {
        tit: '活动名称',
        prop: 'name'
      }, {
        tit: '分类',
        prop: 'createTime'
      }, {
        tit: '新增时间',
        prop: 'name'
      }, {
        tit: '开始时间',
        prop: 'name'
      }, {
        tit: '结束时间',
        prop: 'createTime'
      }, {
        tit: '新增人员',
        prop: 'name'
      }, {
        tit: '状态',
        prop: 'name'
      }],
      idCard: '',
      nameCard: '',
      options: [{
        value: '1',
        label: '状态'
      }, {
        value: '2',
        label: '使用中'
      }, {
        value: '3',
        label: '已停用'
      }, {
        value: '4',
        label: '已过期'
      }],
      value: '1'
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    setBtn: function setBtn(scope) {
      // 编辑
      this.$router.push('/coupon/coupon3/appliDea');
    },
    goAdd: function goAdd() {
      // 去添加
      this.$router.push('/coupon/coupon3/activity?id=apply');
    },
    getList: function getList(page) {
      this.pageNo = page.page;
      this.pageSize = page.limit;
      this.init();
    },
    init: function init() {
      (0, _coupon.listActivityPage)({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(function (res) {
        console.log(res);
        /* if(res.ret===1000){
        this.tableData=res.data
        this.total=res.data.total
        this.tableData.forEach((value)=>{
        value.createTime=value.createTime.substring(0, 10)
        })
        } */
      });
    },
    setValue: function setValue() {
      this.value = '1';
      this.idCard = '';
      this.nameCard = '';
    }
  }
};
exports.default = _default;