"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _mem = require("@/api/mem.js");

var _vendor = require("@/api/vendor.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      searchData: [{
        type: 'input',
        placeholder: '商户名称',
        value: 'fullName'
      }, {
        type: 'input',
        placeholder: '商户编码',
        value: 'merchantCode'
      }, {
        type: 'radio',
        placeholder: '状态',
        value: 'merchantStatus',
        select: [{
          label: '开业准备',
          value: 1
        }, {
          label: '正常营业',
          value: 2
        }, {
          label: '暂停营业',
          value: 3
        }, {
          label: '作废',
          value: 4
        }]
      }],
      companyList: [],
      storeList: [],
      obj: {
        merchantStatus: '',
        shortName: '',
        companyId: '',
        storeId: '',
        fullName: '',
        merchantCode: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      list: [],
      tabTh: [{
        prop: 'fullName',
        label: '商户名称',
        width: 100
      }, {
        prop: 'shortName',
        label: '商户简称',
        width: 80
      },
      /* {
          prop:'merchantId',
          label:"商户ID",
          width:70
        }, */
      {
        prop: 'merchantCode',
        label: '商户编码',
        width: 80
      },
      /* {
          prop:'supplierName',
          label:"供应商名称",
          width:150
        }, */
      {
        prop: 'companyName',
        label: '所属总部',
        width: 100
      }, {
        prop: 'storeName',
        label: '所属门店',
        width: 100
      }, {
        prop: 'deptName',
        label: '企业微信机构',
        width: 80
      }, {
        prop: 'merchantStatus',
        label: '状态',
        slot: "merchantStatus"
      }, {
        prop: 'activityStatuss',
        label: '参与活动',
        slot: "activityStatuss"
      }, {
        prop: 'merchantDesc',
        label: '商户描述',
        width: 100
      }, {
        slot: 'orderDea'
      }]
    };
  },
  created: function created() {
    var _this = this;

    // this.init()
    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companyList = res.data;
      var companyList = res.data.map(function (item) {
        return {
          label: item.shortName,
          value: item.companyId
        };
      });

      _this.searchData.unshift({
        type: 'radio',
        placeholder: '所属总部',
        value: 'companyId',
        select: companyList
      });
    });
    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      console.log(res);
      _this.storeList = res.data;
      var storeList = res.data.map(function (item) {
        return {
          label: "".concat(item.shortName, "(").concat(item.storeCode, ")"),
          value: item.storeId
        };
      });

      _this.searchData.unshift({
        type: 'radio',
        placeholder: '所属门店',
        value: 'storeId',
        select: storeList
      });
    });
  },
  methods: {
    init: function init(type) {
      var _this2 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'refresh') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
        this.obj.merchantStatus = '';
        this.obj.shortName = '';
        this.obj.fullName = '';
        this.obj.companyId = '';
        this.obj.storeId = '';
        this.obj.merchantCode = '';
      }

      (0, _vendor.listMerchantPage)(this.obj).then(function (res) {
        _this2.list = res.data.items;
        _this2.obj.total = res.data.totalNum;

        _this2.list.forEach(function (item) {
          switch (item.merchantStatus) {
            case 1:
              item.merchantStatus = '开业准备';
              break;

            case 2:
              item.merchantStatus = '正常营业';
              break;

            case 3:
              item.merchantStatus = '暂停营业';
              break;

            case 4:
              item.merchantStatus = '作废';
              break;

            default:
              break;
          }
        });
      });
    },
    goPage: function goPage(type, merchantId) {
      if (type == 'edit') {
        this.$router.push('/system/vendor/add?merchantId=' + merchantId);
      } else {
        this.$router.push('/system/vendor/add');
      }
    }
  }
};
exports.default = _default;