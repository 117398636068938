var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    商品-基础方案\n    "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: {
                  size: "mini",
                  placeholder: "商品编码",
                  type: "number"
                },
                model: {
                  value: _vm.requestData.goodsCode,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "goodsCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.goodsCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { "max-width": "400px", width: "100%" },
                  attrs: {
                    clearable: "",
                    size: "mini",
                    placeholder: "所属总部",
                    filterable: ""
                  },
                  on: { change: _vm.setShort },
                  model: {
                    value: _vm.requestData.companyCode,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "companyCode", $$v)
                    },
                    expression: "requestData.companyCode"
                  }
                },
                _vm._l(_vm.companyList, function(item) {
                  return _c("el-option", {
                    key: item.companyCode,
                    attrs: { label: item.shortName, value: item.companyCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { "max-width": "400px", width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "所属门店",
                    filterable: ""
                  },
                  model: {
                    value: _vm.requestData.storeCode,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "storeCode", $$v)
                    },
                    expression: "requestData.storeCode"
                  }
                },
                _vm._l(_vm.storeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.storeId,
                      attrs: {
                        label: item.shortName + "（" + item.storeCode + "）",
                        value: item.storeCode
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: {
                  size: "mini",
                  placeholder: "分类编码",
                  type: "number"
                },
                model: {
                  value: _vm.requestData.classCode,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "classCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.classCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: {
                  size: "mini",
                  placeholder: "品牌编码",
                  type: "number"
                },
                model: {
                  value: _vm.requestData.brandCode,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "brandCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.brandCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.getList("search")
                }
              }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "mini", icon: "el-icon-refresh" },
              on: {
                click: function($event) {
                  return _vm.getList("remove")
                }
              }
            },
            [_vm._v("重置")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "validStatus",
              label: "状态",
              align: "center",
              width: "80"
            },
            slot: "validStatus",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.validStatus == 1
                      ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                          _vm._v("启用")
                        ])
                      : _c("span", { staticStyle: { color: "#E6A23C" } }, [
                          _vm._v("停用")
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "discountRate",
              label: "折扣",
              align: "center",
              width: "100"
            },
            slot: "discountRate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n\n        " +
                        _vm._s(
                          scope.row.discountRate > 0
                            ? scope.row.discountRate / 10 + "折"
                            : "不打折"
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }