var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("编辑会员权益")]),
      _vm._v(" "),
      _c("div", { staticClass: "editor_top" }, [
        _c("div", { staticClass: "top_left" }, [
          _c("ul", [
            _c("li", [
              _c("p", { staticClass: "tit" }, [_vm._v("体系ID：")]),
              _vm._v(" "),
              _c("p", { staticClass: "txt" }, [_vm._v(_vm._s(_vm.cardId))])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "tit" }, [_vm._v("体系名称:")]),
              _vm._v(" "),
              _c("p", { staticClass: "txt" }, [_vm._v(_vm._s(_vm.sysName))])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "tit" }, [_vm._v("会员卡名称:")]),
              _vm._v(" "),
              _c("p", { staticClass: "txt" }, [_vm._v(_vm._s(_vm.name))])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "top_right" })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "editor_table" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "权益图标（点亮） 160x160",
                  width: "200",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.getIndex(scope)
                              }
                            }
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                attrs: {
                                  data: _vm.uploadData,
                                  "show-file-list": false,
                                  action:
                                    "http://172.251.251.33:9092/api/fileManage/uploadFile",
                                  "on-success": _vm.handleSucess,
                                  "auto-upload": true
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.imgSrc + scope.row.haveImg,
                                    onerror:
                                      "this.src='https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png'"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.curRowIndex = scope.$index
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "权益图标（未亮）\n160x160",
                  width: "200",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.getIndex(scope)
                              }
                            }
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                attrs: {
                                  data: _vm.uploadData,
                                  "show-file-list": false,
                                  action:
                                    "http://172.251.251.33:9092/api/fileManage/uploadFile",
                                  "on-success": _vm.handleSucess2,
                                  "auto-upload": true
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.imgSrc + scope.row.unhaveImg,
                                    onerror:
                                      "this.src='https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png'"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.curRowIndex = scope.$index
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "权益名称\n四个字为宜",
                  width: "200",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: scope.row.name,
                            callback: function($$v) {
                              _vm.$set(scope.row, "name", $$v)
                            },
                            expression: "scope.row.name"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "权益详情", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 2, maxRows: 4 },
                            placeholder: "请输入内容"
                          },
                          model: {
                            value: scope.row.remark,
                            callback: function($$v) {
                              _vm.$set(scope.row, "remark", $$v)
                            },
                            expression: "scope.row.remark"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "排序\n       \t 数字小的靠前",
                  width: "200",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { type: "number" },
                          model: {
                            value: scope.row.sort,
                            callback: function($$v) {
                              _vm.$set(scope.row, "sort", $$v)
                            },
                            expression: "scope.row.sort"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.addList()
            }
          }
        },
        [_vm._v("\n      添加\n    ")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.setTab()
            }
          }
        },
        [_vm._v("\n      保存\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }