var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("广告")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "listTable" },
      [
        _c(
          "div",
          { staticClass: "column_btn" },
          [
            _vm.type === 2 || _vm.list.length === 0
              ? _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "has",
                        rawName: "v-has",
                        value: "advertItem:add",
                        expression: "'advertItem:add'"
                      }
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.goAdd }
                  },
                  [_vm._v("新增广告")]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.list,
              border: "",
              size: "small",
              "max-height": "600"
            }
          },
          [
            _c("el-table-column", {
              attrs: { align: "center", label: "序号", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " + _vm._s(scope.$index + 1) + "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _vm._l(_vm.tabTh, function(item, index) {
              return _c("el-table-column", {
                key: index,
                attrs: {
                  prop: item.prop,
                  label: item.tit,
                  "min-width": item.width,
                  align: "center",
                  sortable: item.sortable
                }
              })
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { align: "center", label: "操作", width: "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm.type === 2
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "has",
                                  rawName: "v-has",
                                  value: "advertItem:delete",
                                  expression: "'advertItem:delete'"
                                }
                              ],
                              attrs: { size: "mini", type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.remove(scope)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "has",
                              rawName: "v-has",
                              value: "advertItem:edit",
                              expression: "'advertItem:edit'"
                            }
                          ],
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.setBtn(scope.row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }