"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _manage = require("@/api/manage.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      menuId: '',
      title: '新增资源',
      name: '',
      url: '',
      parentId: '',
      perms: '',
      type: '',
      options: [{
        value: 0,
        label: '目录'
      }, {
        value: 1,
        label: '菜单'
      }, {
        value: 2,
        label: '按钮'
      }],
      icon: '',
      orderNum: null,
      dataTree: [],
      defaultProps: {
        children: 'childMenu',
        label: 'name'
      },
      checkedId: null,
      defaultKeys: []
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.menuId = Number(this.$route.query.menuId);

      if (this.menuId) {
        this.title = '编辑资源';
        this.openLoading();
        (0, _manage.getSysMenuById)({
          menuId: this.menuId
        }).then(function (res) {
          _this.openLoading().close();

          console.log(res);

          _this.$refs.treeForm.setCheckedKeys([res.data.parentId]);

          _this.defaultKeys = [String(res.data.parentId), String(res.data.menuId)];
          _this.name = res.data.name;
          _this.url = res.data.url;
          _this.perms = res.data.perms;
          _this.orderNum = res.data.orderNum;
          _this.type = res.data.type;
          _this.icon = res.data.icon;
        }).catch(function (err) {
          _this.openLoading().close();
        });
      }

      (0, _manage.listMenuTree)().then(function (res) {
        console.log(res);
        _this.dataTree = _this.dataTree.concat(res.data);
      });
    },
    next: function next() {
      var _this2 = this;

      if (this.name.trim() === '') {
        this.$message.error('请输入资源名称');
        return;
      } else if (this.url.trim() === '' && this.type === 1) {
        this.$message.error('请输入	资源路径');
        return;
      } else if (this.perms.trim() === '' && this.type === 2) {
        this.$message.error('请输入权限标识');
        return;
      } else if (this.type === '') {
        this.$message.error('请选择类型');
        return;
      } else if (this.icon.trim() === '' && this.type !== 2) {
        this.$message.error('请输入资源图标');
        return;
      } else if (this.orderNum === '') {
        this.$message.error('请输入	排序号');
        return;
      } else {
        var _data = {
          parentId: this.$refs.treeForm.getCheckedKeys().length > 0 ? Number(this.$refs.treeForm.getCheckedKeys().join()) : 0,
          name: this.name,
          url: this.url,
          perms: this.perms,
          type: this.type,
          icon: this.icon,
          orderNum: Number(this.orderNum)
        };
        console.log(_data);
        this.$confirm('此操作保存资源信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          if (_this2.menuId) {
            _data.menuId = _this2.menuId;
            (0, _manage.editSysMenu)(_data).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _manage.addSysMenu)(_data).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        }).catch(function () {
          _this2.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    nodeClick: function nodeClick(data, node) {
      this.childNodesChange(node);
      this.parentNodesChange(node);
    },
    handleClick: function handleClick(data, checked, node) {
      if (checked == true) {
        this.checkedId = data.id;
        this.$refs.treeForm.setCheckedNodes([data]);
      }
    },
    childNodesChange: function childNodesChange(node) {
      this.checkedId = data.id;
      this.$refs.treeForm.setCheckedNodes([data]);
      var len = node.childNodes.length;

      for (var i = 0; i < len; i++) {
        node.childNodes[i].checked = false;
        this.childNodesChange(node.childNodes[i]);
      }
    },
    parentNodesChange: function parentNodesChange(node) {
      if (node.parent) {
        for (var key in node) {
          if (key == 'parent') {
            node[key].checked = true;
            this.parentNodesChange(node[key]);
          }
        }
      }
    }
  }
};
exports.default = _default;