"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addEvent = addEvent;
exports.editEvent = editEvent;
exports.getEventById = getEventById;
exports.listEventPage = listEventPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*

5、新增触发规则_星礼卡：/api/triggerCard/addCard
{"ruleName":"规则名称","startTime":"活动时间(开始)","endTime":"活动时间(结束)","appointCard":"是否指定卡(0：否 1：是)","cardCode":"储值卡编码","ruleStatus":"规则状态(0：禁用 1：启用)","ruleRemark":"规则备注","ladders":[{"id":"阶梯id","topupAmount":"充值金额","peopleLimit":"人数限制","eventIds":[触发事件id]}]}

6、编辑触发规则_星礼卡：/api/triggerCard/editCard
{"id":"id","ruleName":"规则名称","startTime":"活动时间(开始)","endTime":"活动时间(结束)","appointCard":"是否指定卡(0：否 1：是)","cardCode":"储值卡编码","ruleStatus":"规则状态(0：禁用 1：启用)","ruleRemark":"规则备注","ladders":[{"id":"阶梯id","topupAmount":"充值金额","peopleLimit":"人数限制","eventIds":[触发事件id]}]}

7、触发规则_星礼卡详情：/api/triggerCard/getCardById/{id}

8、触发规则_星礼卡分页：/api/triggerCard/listCardPage
{"pageNo":"当前页","pageSize":"页条数"}
 */

/* 4、触发事件分页：/api/triggerEvent/listEventPage
{"pageNo":"当前页","pageSize":"页条数"} */
function listEventPage(data) {
  return (0, _request.default)({
    url: '/api/triggerEvent/listEventPage',
    method: 'post',
    data: data
  });
}
/*
1、新增触发事件：/api/triggerEvent/addEvent
{"eventType":"事件类型(1：发放优惠券 2：储值卡变动 3：积分变动 4：成长值变动)","eventName":"事件名称",
"eventStatus":"事件状态(0：停用 1：启用)",
"coupons":[{"couponCode":"券编码","couponName":"券名称","couponType":"券类型","validTime":"有效期","":"sentNumber":"发券数量"}]}

 */


function addEvent(data) {
  return (0, _request.default)({
    url: '/api/triggerEvent/addEvent',
    method: 'post',
    data: data
  });
}
/*
 2、编辑触发事件：
 {"id":"id","eventType":"事件类型(1：发放优惠券 2：储值卡变动 3：积分变动 4：成长值变动)","eventName":"事件名称","eventStatus":"事件状态(0：停用 1：启用)",
 "coupons":[{"couponCode":"券编码","couponName":"券名称","couponType":"券类型","validTime":"有效期","":"sentNumber":"发券数量"}]}

 */


function editEvent(data) {
  return (0, _request.default)({
    url: '/api/triggerEvent/editEvent',
    method: 'post',
    data: data
  });
}
/*
3、触发事件明细：/api/triggerEvent/getEventById/{id}
 */


function getEventById(data) {
  return (0, _request.default)({
    url: '/api/triggerEvent/getEventById/' + data,
    method: 'post'
  });
}