"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var _router = _interopRequireDefault(require("./cardRebate/router.js"));

/** When your routing table is too long, you can split it into small modules **/
var activityRouter = {
  path: '/activity',
  component: _layout.default,
  redirect: '/activity/tab',
  name: 'activity',
  meta: {
    title: '营销工具',
    icon: 'nested'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/tab.vue'));
      });
    },
    name: 'activityTab',
    meta: {
      title: '营销工具'
    }
  }, {
    path: 'marketing',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/marketing.vue'));
      });
    },
    name: 'activitymarketing',
    meta: {
      title: '销售管理'
    }
  },, {
    path: 'gamemanager',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/games/tab/index'));
      });
    },
    name: 'activitygamemanager',
    meta: {
      title: '游戏管理'
    }
  }, _router.default, {
    path: 'sign',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/index.vue'));
      });
    },
    redirect: '/activity/sign/tab',
    name: 'sign',
    meta: {
      title: '签到'
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/tab.vue'));
        });
      },
      name: 'signTab',
      meta: {
        title: '签到'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/list.vue'));
        });
      },
      name: 'signList',
      meta: {
        title: '连续签到奖励',
        keepAlive: true,
        activeMenu: '/activity/sign/tab'
      }
    }, {
      path: 'order',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/order.vue'));
        });
      },
      name: 'signOrder',
      meta: {
        title: '签到记录',
        keepAlive: true,
        activeMenu: '/activity/sign/tab'
      }
    }, {
      path: 'giftOrder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/giftOrder.vue'));
        });
      },
      name: 'signgiftOrder',
      meta: {
        title: '签到奖励记录',
        keepAlive: true,
        activeMenu: '/activity/sign/tab'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/add.vue'));
        });
      },
      name: 'signAdd',
      meta: {
        title: '新增编辑连续签到',
        activeMenu: '/activity/sign/tab'
      }
    }, {
      path: 'repair',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/repair/list.vue'));
        });
      },
      name: 'repairList',
      meta: {
        title: '补卡',
        keepAlive: true,
        activeMenu: '/activity/sign/tab'
      }
    }, {
      path: 'repairOrder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/repair/order.vue'));
        });
      },
      name: 'repairOrder',
      meta: {
        title: '补卡领取记录',
        keepAlive: true,
        activeMenu: '/activity/sign/tab'
      }
    }, {
      path: 'repairAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/repair/add.vue'));
        });
      },
      name: 'repairAdd',
      meta: {
        title: '新增编辑补卡',
        activeMenu: '/activity/sign/tab'
      }
    }, {
      path: 'daily',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/daily/list.vue'));
        });
      },
      name: 'daily',
      meta: {
        title: '日常签到',
        keepAlive: true,
        activeMenu: '/activity/sign/tab'
      }
    }, {
      path: 'dailyAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/daily/add.vue'));
        });
      },
      name: 'dailyAdd',
      meta: {
        title: '新增编辑日常签到',
        activeMenu: '/activity/sign/tab'
      }
    }, {
      path: 'repairGift',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/sign/repairGift.vue'));
        });
      },
      name: 'repairGift',
      meta: {
        title: '补发签到奖励',
        activeMenu: '/activity/sign/tab'
      }
    }]
  }, {
    path: 'lottery',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/lottery/index.vue'));
      });
    },
    redirect: '/activity/lottery/tab',
    name: 'Lottery',
    meta: {
      title: '抽奖'
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lottery/tab.vue'));
        });
      },
      name: 'lotteryTab',
      meta: {
        title: '抽奖'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lottery/list.vue'));
        });
      },
      name: 'LotteryList',
      meta: {
        title: '抽奖管理',
        keepAlive: true,
        activeMenu: '/activity/lottery/tab'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lottery/add.vue'));
        });
      },
      name: 'LotteryAdd',
      meta: {
        title: '新增编辑抽奖',
        activeMenu: '/activity/lottery/tab'
      }
    }, {
      path: 'report',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lottery/report.vue'));
        });
      },
      name: 'LotteryReport',
      meta: {
        title: '抽奖记录',
        keepAlive: true,
        activeMenu: '/activity/lottery/tab'
      }
    }, {
      path: 'rule',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lottery/rule/list.vue'));
        });
      },
      name: 'LotteryRuleList',
      meta: {
        title: '抽奖规则',
        addTxt: "新增规则",
        keepAlive: true,
        activeMenu: '/activity/lottery/tab',
        addUrl: "/activity/lottery/ruleAdd",
        pageList: 'items',
        pageTotal: 'totalNum' // method:'get'

      }
    }, {
      path: 'ruleAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lottery/rule/add.vue'));
        });
      },
      name: 'LotteryRuleAdd',
      meta: {
        title: '新增抽奖规则',
        activeMenu: '/activity/lottery/tab'
      }
    }]
  }, {
    path: 'help',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/index.vue'));
      });
    },
    redirect: '/activity/help/tab',
    name: 'help',
    meta: {
      title: '抽奖'
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/help/tab.vue'));
        });
      },
      name: 'helpTab',
      meta: {
        title: '好友助力'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/help/list.vue'));
        });
      },
      name: 'helpList',
      meta: {
        title: '好友助力',
        keepAlive: true,
        activeMenu: '/activity/help/tab'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/help/add.vue'));
        });
      },
      name: 'helpAdd',
      meta: {
        title: '新增编辑好友助力',
        activeMenu: '/activity/help/tab'
      }
    }, {
      path: 'order',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/help/order.vue'));
        });
      },
      name: 'helpOrder',
      meta: {
        title: '助力统计',
        keepAlive: true,
        activeMenu: '/activity/help/tab'
      }
    }, {
      path: 'orderDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/help/orderDea.vue'));
        });
      },
      name: 'helpOrderDea',
      meta: {
        title: '助力详情',
        keepAlive: true,
        activeMenu: '/activity/help/tab'
      }
    }]
  }, {
    path: 'apply',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/index.vue'));
      });
    },
    redirect: '/activity/apply/tab',
    name: 'apply',
    meta: {
      title: '报名活动'
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/apply/tab.vue'));
        });
      },
      name: 'applyTab',
      meta: {
        title: '报名活动'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/apply/list.vue'));
        });
      },
      name: 'applyList',
      meta: {
        title: '报名活动',
        keepAlive: true,
        activeMenu: '/activity/apply/tab'
      }
    }, {
      path: 'order',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/apply/order.vue'));
        });
      },
      name: 'applyOrder',
      meta: {
        title: '报名记录',
        keepAlive: true,
        activeMenu: '/activity/apply/tab'
      }
    }, {
      path: 'dea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/apply/dea.vue'));
        });
      },
      name: 'applydea',
      meta: {
        title: '报名详情',
        activeMenu: '/activity/apply/tab'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/apply/add.vue'));
        });
      },
      name: 'applyAdd',
      meta: {
        title: '新增编辑报名活动',
        activeMenu: '/activity/apply/tab'
      }
    }]
  }, {
    path: 'lixing',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/index.vue'));
      });
    },
    redirect: '/activity/lixing/list',
    name: 'lixing',
    meta: {
      title: '荔星'
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lixing/tab.vue'));
        });
      },
      name: 'activitylixingTab',
      meta: {
        title: '荔星新人礼'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lixing/list.vue'));
        });
      },
      name: 'lixingList',
      meta: {
        title: '荔星礼品',
        keepAlive: true
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lixing/add.vue'));
        });
      },
      name: 'lixignAdd',
      meta: {
        title: '新增编辑荔星礼品'
      }
    }, {
      path: 'user',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lixing/user.vue'));
        });
      },
      name: 'lixingListUser',
      meta: {
        title: '领取记录',
        keepAlive: true
      }
    }, {
      path: 'hdpi',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lixing/hdpi/list.vue'));
        });
      },
      name: 'lixingListHdpi',
      meta: {
        title: '活动列表',
        keepAlive: true
      }
    }, {
      path: 'hdpiAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lixing/hdpi/add.vue'));
        });
      },
      name: 'lixingListHdpiAdd',
      meta: {
        title: '编辑活动',
        activeMenu: '/activity/lixing/hdpi'
      }
    }, {
      path: 'flowCoupon',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lixing/flowCoupon/list.vue'));
        });
      },
      name: 'lixingflowCoupon',
      meta: {
        title: '扫码返券',
        keepAlive: true,
        icon: 'el-icon-s-cooperation',
        addTxt: "新增返券",
        addUrl: '/activity/lixing/flowAdd',
        api: '/api/lx/lx-sys-user-isssue-coupon-bag-config/page',
        columns: [{
          label: '门店',
          prop: 'storeName',
          width: 80
        }, {
          label: '门店编码',
          prop: 'storeCode',
          width: 60
        }, {
          label: '券包编码',
          prop: 'couponBagUuid',
          width: 80
        }, {
          label: '库存',
          prop: 'stock',
          width: 60
        }, {
          label: '发券人手机',
          prop: 'sysUserMobile',
          width: 80
        }, {
          label: '发券人',
          prop: 'sysUserName',
          width: 60
        }, {
          label: '创建时间',
          prop: 'createTime',
          width: 100
        }, {
          slot: 'operate'
        }]
      }
    }, {
      path: 'flowOrder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lixing/flowCoupon/order.vue'));
        });
      },
      name: 'lixingflowCouponOrder',
      meta: {
        title: '扫码返券领取记录',
        keepAlive: true,
        api: '/api/lx/lx-sys-user-isssue-coupon-bag-config/pageIssueLog',
        columns: [{
          label: '门店',
          prop: 'storeName',
          width: 80
        }, {
          label: '门店编码',
          prop: 'storeCode',
          width: 60
        }, {
          label: '券包编码',
          prop: 'couponBagUuid',
          width: 100
        }, {
          label: '券包名称',
          prop: 'couponName',
          width: 100
        }, {
          label: '发券人手机',
          prop: 'sysUserMobile',
          width: 80
        }, {
          label: '发券人',
          prop: 'sysUserName',
          width: 60
        }, {
          label: '会员手机号',
          prop: 'userMobile',
          width: 80
        }, {
          slot: 'status',
          label: '状态',
          prop: 'status'
        }, {
          label: '时间',
          prop: 'receiveTime',
          width: 100
        }]
      }
    }, {
      path: 'flowAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lixing/flowCoupon/add.vue'));
        });
      },
      name: 'lixingflowCouponAdd',
      meta: {
        title: '编辑用户返券'
      }
    }, {
      path: 'flowTab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/lixing/flowCoupon/tab.vue'));
        });
      },
      name: 'lixingflowCouponflowTab',
      meta: {
        title: '扫码返券'
      }
    }]
  }, {
    path: 'week',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/index.vue'));
      });
    },
    redirect: '/activity/week/tab',
    name: 'activityWeek',
    meta: {
      title: '周期运营'
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/week/tab.vue'));
        });
      },
      name: 'activityWeekTab',
      meta: {
        title: '周期运营'
      }
    }, {
      path: 'banner',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/week/banner/list.vue'));
        });
      },
      name: 'activityWeekBanner',
      meta: {
        title: '周期运营',
        keepAlive: true
      }
    }, {
      path: 'tem',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/week/tem/list.vue'));
        });
      },
      name: 'activityWeekTem',
      meta: {
        title: '周期运营',
        keepAlive: true
      }
    }, {
      path: 'goods',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/week/goods/list.vue'));
        });
      },
      name: 'activityWeekGoods',
      meta: {
        title: '周期运营',
        keepAlive: true
      }
    }, {
      path: 'bannerAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/week/banner/add.vue'));
        });
      },
      name: 'activityWeekBannerAdd',
      meta: {
        title: '添加轮播图'
      }
    }, {
      path: 'goodsAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/week/goods/add.vue'));
        });
      },
      name: 'activityWeekgoodsAdd',
      meta: {
        title: '添加商品'
      }
    }, {
      path: 'temAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/week/tem/add.vue'));
        });
      },
      name: 'activityWeekTemAdd',
      meta: {
        title: '添加栏目'
      }
    }]
  }, {
    path: 'mall',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/index.vue'));
      });
    },
    redirect: '/activity/mall/list',
    name: 'activityMall',
    meta: {
      title: '特价活动'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/mall/list.vue'));
        });
      },
      name: 'activityMallList',
      meta: {
        title: '特价活动列表',
        keepAlive: true
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/mall/add.vue'));
        });
      },
      name: 'activityMallAdd',
      meta: {
        title: '编辑特价活动',
        activeMenu: '/activity/mall/list'
      }
    }, {
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/mall/tab.vue'));
        });
      },
      name: 'activityMallTab',
      meta: {
        title: '特价活动'
      }
    }, {
      path: 'banner',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/mall/banner/list.vue'));
        });
      },
      name: 'activityMallBanner',
      meta: {
        title: '特价活动banner',
        activeMenu: '/activity/mall/list'
      }
    }, {
      path: 'tem',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/mall/tem/list.vue'));
        });
      },
      name: 'activityMallTem',
      meta: {
        title: '特价活动栏目',
        activeMenu: '/activity/mall/list'
      }
    }, {
      path: 'goods',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/mall/goods/list.vue'));
        });
      },
      name: 'activityMallGoods',
      meta: {
        title: '商品管理',
        activeMenu: '/activity/mall/list'
      }
    }, {
      path: 'bannerAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/mall/banner/add.vue'));
        });
      },
      name: 'activityMallBannerAdd',
      meta: {
        title: '添加轮播图',
        activeMenu: '/activity/mall/list'
      }
    }, {
      path: 'goodsAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/mall/goods/add.vue'));
        });
      },
      name: 'activityMallgoodsAdd',
      meta: {
        title: '添加商品',
        activeMenu: '/activity/mall/list'
      }
    }, {
      path: 'temAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/mall/tem/add.vue'));
        });
      },
      name: 'activityMallTemAdd',
      meta: {
        title: '添加栏目',
        activeMenu: '/activity/mall/list'
      }
    }]
  }, {
    path: 'newGift',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/index.vue'));
      });
    },
    redirect: '/activity/newGift/index',
    name: 'activitynewGift',
    meta: {
      title: '新人礼'
    },
    children: [{
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/newGift/add.vue'));
        });
      },
      name: 'activitynewGiftindex',
      meta: {
        title: '编辑新人礼'
      }
    }]
  }, {
    path: 'waterCoupon',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/index.vue'));
      });
    },
    redirect: '/activity/waterCoupon/list',
    name: 'waterCoupon',
    meta: {
      title: '消费奖励'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/waterCoupon/list.vue'));
        });
      },
      name: 'waterCouponList',
      meta: {
        title: '消费奖励',
        keepAlive: true
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/waterCoupon/add.vue'));
        });
      },
      name: 'waterCouponAdd',
      meta: {
        title: '编辑消费奖励'
      }
    }, {
      path: 'order',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/waterCoupon/order.vue'));
        });
      },
      name: 'waterCouponOrder',
      meta: {
        title: '消费奖励领取记录',
        keepAlive: true
      }
    }]
  }, {
    path: 'weal',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/index.vue'));
      });
    },
    redirect: '/activity/weal/index',
    name: 'weal',
    meta: {
      title: '员工福利'
    },
    children: [{
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/weal/index.vue'));
        });
      },
      name: 'wealIndex',
      meta: {
        title: '端午员工福利'
      }
    }, {
      path: 'moon',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/weal/moon.vue'));
        });
      },
      name: 'wealmoon',
      meta: {
        title: '中秋员工福利'
      }
    }]
  }, {
    path: 'ymgz',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/ymgz/index.vue'));
      });
    },
    name: 'ymgzIndex',
    meta: {
      title: '一码贵州消费券'
    }
  }, // 蜻蜓收银活动
  {
    path: 'dragonfly',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/index.vue'));
      });
    },
    redirect: '/activity/dragonflyActive/index',
    name: 'dragonflyActive',
    meta: {
      title: '蜻蜓收银活动'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/dragonflyActive/index.vue'));
        });
      },
      name: 'dragonflyIndex',
      meta: {
        title: '蜻蜓收银活动'
      }
    }, {
      path: 'edit',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activity/dragonflyActive/editForm.vue'));
        });
      },
      name: 'editForm',
      meta: {
        title: '编辑',
        activeMenu: '/activity/dragonfly/list'
      }
    }]
  }]
};
var _default = activityRouter;
exports.default = _default;