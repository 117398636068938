"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _template = require("@/api/template.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      type: '',
      ruleForm: {
        styleName: '',
        styleStatus: '',
        parentId: '',
        styleCode: '',
        thumbnailPath: '',
        id: ''
      },
      rules: {
        parentId: [{
          required: true,
          message: '请选择模板分类'
        }],
        styleStatus: [{
          required: true,
          message: '请选择状态'
        }],
        styleName: [{
          required: true,
          message: '请输入名称'
        }],
        styleCode: [{
          required: true,
          message: '请输入样式编码'
        }],
        thumbnailPath: [{
          required: true,
          message: '请上传样式图'
        }]
      },
      classArr: []
    };
  },
  created: function created() {
    var _this = this;

    (0, _template.listStyleType)({
      styleStatus: null
    }).then(function (res) {
      _this.classArr = res.data;
    });
    this.type = this.$route.query.type;

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _template.getStyleType)(this.ruleForm.id).then(function (res) {
        _this.fzObj(res.data, _this.ruleForm);
      });
    }
  },
  methods: {
    setCover: function setCover(e) {
      this.ruleForm.thumbnailPath = e;
    },
    submitForm: function submitForm(form) {
      var _this2 = this;

      this.$refs[form].validate(function (valid) {
        if (valid) {
          if (_this2.ruleForm.id) {
            (0, _template.editStyle)(_this2.ruleForm).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _template.addStyle)(_this2.ruleForm).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '新增成功!'
              });

              _this2.$router.go(-1);
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;