"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.getList = getList;
exports.listItem = listItem;
exports.removeById = removeById;
exports.toggleStatus = toggleStatus;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: '/api/special/spacial-sale-activity-product/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addData(data) {
  return (0, _request.default)({
    url: '/api/special/spacial-sale-activity-product/saveOrUpdate',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function removeById(data) {
  return (0, _request.default)({
    url: '/api/special/spacial-sale-activity-product/removeById',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function toggleStatus(data) {
  return (0, _request.default)({
    url: '/api/special/spacial-sale-activity-product/toggleStatus',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function listItem(data) {
  return (0, _request.default)({
    url: '/api/cycle-product-category/listItem',
    method: 'get',
    loading: 'loading',
    params: data
  });
}