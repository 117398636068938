var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "memberForm" },
      [
        _c(
          "el-form",
          { ref: "ruleForm", attrs: { "label-width": "100px" } },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "会员状态：" } },
                      [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: _vm.status == 1 ? "success" : "danger",
                              effect: "dark"
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.status == 1 ? "正常" : "封禁") +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "手机号码：" } }, [
                      _vm._v(_vm._s(_vm.user.mobile))
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "会员积分：" } }, [
                      _vm._v(_vm._s(_vm.user.score))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "乐享值：" } }, [
                      _vm._v(_vm._s(_vm.user.growthValue))
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }