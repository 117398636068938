"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      title: '新增税率管理',
      rules: {
        sl: [{
          required: true,
          message: '请输入税率'
        }],
        spbm: [{
          required: true,
          message: '请输入商品税收分类编码'
        }],
        lslbs: [{
          required: true,
          message: '请选择0税率类型'
        }]
      },
      ruleForm: {
        id: '',
        sl: '',
        spbm: '',
        lslbs: null
      }
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.ruleForm.id = Number(this.$route.query.id);

      if (this.ruleForm.id) {
        this.title = '编辑税率管理';
        (0, _api.getTaxRate)({
          id: this.ruleForm.id
        }).then(function (res) {
          _this.ruleForm.spbm = res.data.spbm;
          _this.ruleForm.sl = res.data.sl;
          _this.ruleForm.lslbs = res.data.lslbs ? Number(res.data.lslbs) : null;
        });
      }
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this2.ruleForm.id) {
            (0, _api.updateTaxRate)(_this2.ruleForm).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addTaxRate)(_this2.ruleForm).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;