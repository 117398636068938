var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-list" },
    [
      _c(
        "w-table",
        {
          attrs: {
            pageApi: _vm.api,
            columns: _vm.columns,
            "search-data": _vm.queryParams
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "image",
              label: "游戏主图",
              align: "center",
              "min-width": 100
            },
            slot: "image",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-image", {
                      staticStyle: { width: "80px", height: "80px" },
                      attrs: { src: _vm.imgSrc + row.masterImg }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "gameType",
              label: "游戏类型",
              align: "center",
              "min-width": 100
            },
            slot: "gameType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.gameType == 1 ? _c("span", [_vm._v("拼图")]) : _vm._e(),
                    _vm._v(" "),
                    row.gameType == 2
                      ? _c("span", [_vm._v("消消乐")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "游戏状态",
              align: "center",
              "min-width": 100
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 2 },
                      on: {
                        change: function($event) {
                          return _vm.handStatusChange(row)
                        }
                      },
                      model: {
                        value: row.status,
                        callback: function($$v) {
                          _vm.$set(row, "status", $$v)
                        },
                        expression: "row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { slot: "actions", label: "操作", align: "center" },
            slot: "actions",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.toPage(
                                  "/games/settings?gameId=" + row.id
                                )
                              }
                            }
                          },
                          [_vm._v("游戏配置")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.toPage(
                                  "/games/rewardSetting?gameId=" + row.id
                                )
                              }
                            }
                          },
                          [_vm._v("奖励配置")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.toPage(
                                  "/games/rewardRecords?gameId=" + row.id
                                )
                              }
                            }
                          },
                          [_vm._v("奖励记录")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.toPage(
                                  "/games/statistics?gameId=" + row.id
                                )
                              }
                            }
                          },
                          [_vm._v("数据统计")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }