"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _api = require("@/api/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  // props: ['tableData',  'height', 'requestData', 'size', 'operate'],
  props: {
    searchData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    height: {
      type: Number,
      default: 600
    },
    isPageDwn: {
      type: Boolean,
      default: false
    },
    isShowTitle: {
      type: Boolean,
      default: true
    },
    isSowRed: {
      type: Boolean,
      default: false
    },
    isTableTitle: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'mini'
    },
    tableTitle: {
      type: String,
      default: ''
    },
    mobile: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    operate: {
      type: Array,
      default: function _default() {
        return ['dea'];
      }
    },
    isDown: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    isPage: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    columns: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    pageApi: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    pageRequestData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    detailKey: {
      type: String,
      default: 'id'
    },
    // 动态隐藏添加按钮
    hideAddBtn: {
      type: Boolean,
      default: false
    },
    tableList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    method: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      select: ['radio', 'check'],
      pageSizes: [10, 20, 30, 40, 50],
      pageMeta: {},
      tableData: [],
      downloadLoading: false,
      requestData: {
        pageSize: 10,
        pageNo: 1
      }
    };
  },
  computed: {
    tableColumns: function tableColumns() {
      if (this.columns.length > 0) {
        return this.columns;
      } else {
        return this.$route.meta.columns;
      }
    },
    apiUrl: function apiUrl() {
      if (this.pageApi) {
        return this.pageApi;
      } else {
        return this.$route.meta.api;
      }
    },
    showTableData: function showTableData() {
      if (this.tableList && this.tableList.length > 0) {
        return this.tableList;
      } else {
        return this.tableData;
      }
    }
  },
  activated: function activated() {
    if (this.pageMeta.isOneRequest != 'no') {
      this.getList();
    }
  },
  created: function created() {
    var _this = this;

    this.searchData.forEach(function (item) {
      if (item.type == 'inputNumber') {
        _this.requestData[item.value] = undefined;
      } else {
        _this.requestData[item.value] = null;
      }
    });
    this.requestData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.pageRequestData), this.requestData);
    this.pageMeta = this.$route.meta;
  },
  methods: {
    handleSelectionChange: function handleSelectionChange(e) {
      this.$emit('setSelectGoods', e);
    },
    changeCascaderSearch: function changeCascaderSearch(e, prop) {
      this.$set(this.requestData, prop, e[e.length - 1]);
      this.getList('search');
    },
    getSelectLists: function getSelectLists() {
      var selectGoods = this.$refs.tableList.store.states.selection;
      return selectGoods;
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;

      if (this.isSowRed) {
        return row.unclaimedRightCount > 0 ? 'tableSuccess' : '';
      } else {
        return '';
      }
    },
    setRequestValue: function setRequestValue(type, value) {
      console.log(value);
      this.$set(this.requestData, type, value);
    },
    setRequestTime: function setRequestTime(beforeTime, curTime) {
      this.requestData.startTime = beforeTime;
      this.requestData.endTime = curTime;
      this.getList();
    },
    goBtnRequest: function goBtnRequest() {
      var _this2 = this;

      if (!this.pageMeta.btnApi) return;
      (0, _api.getPageList)(this.pageMeta.btnApi).then(function (res) {
        var list = [];

        if (_this2.pageMeta.pageList === null) {
          list = res.data;
        } else if (_this2.pageMeta.pageList) {
          list = res.data[_this2.pageMeta.pageList];
        } else {
          list = res.data['records'];
        } // ...

      });
    },
    getList: function getList(type) {
      var _this3 = this;

      if (!this.apiUrl) return;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      var data = JSON.parse(JSON.stringify(this.requestData));
      this.$emit('setRequestData', this.requestData);
      delete data.total;
      this.tableData = [];

      if (this.pageMeta.method == 'get' || this.method == 'get') {
        this.apiGet(this.apiUrl, data).then(function (res) {
          var list = [];

          if (_this3.pageMeta.pageList === null) {
            list = res.data;
          } else if (_this3.pageMeta.pageList) {
            list = res.data[_this3.pageMeta.pageList];
          } else {
            list = res.data['records'];
          }

          if (!_this3.setMenuPerms('user_mobile_idCard') && _this3.mobile.length > 0) {
            list.forEach(function (item) {
              _this3.mobile.forEach(function (key) {
                if (item[key]) {
                  item[key] = item[key].replace(item[key].substring(3, 7), "****");
                }
              });
            });
          }

          _this3.tableData = list;
          _this3.requestData.total = res.data[_this3.pageMeta.pageTotal || 'total'];
        });
      } else {
        this.apiPost(this.apiUrl, data).then(function (res) {
          if (!res.data) {
            _this3.tableData = [];
            _this3.requestData.total = 0;
            return;
          }

          var list = [];

          if (_this3.pageMeta.pageList === null) {
            list = res.data;
          } else if (_this3.pageMeta.pageList) {
            list = res.data[_this3.pageMeta.pageList];
          } else {
            list = res.data['records'];
          }

          if (!_this3.setMenuPerms('user_mobile_idCard') && _this3.mobile.length > 0) {
            list.forEach(function (item) {
              _this3.mobile.forEach(function (key) {
                if (item[key]) {
                  item[key] = item[key].replace(item[key].substring(3, 7), "****");
                }
              });
            });
          }

          _this3.tableData = list;
          _this3.requestData.total = res.data[_this3.pageMeta.pageTotal || 'total'];
        });
      }
      /* getPageList(this.apiUrl, data, this.pageMeta.method).then((res) => {
         let list = []
         if (this.pageMeta.pageList === null) {
           list = res.data
         } else if (this.pageMeta.pageList) {
           list = res.data[this.pageMeta.pageList]
         } else {
           list = res.data['records']
         }
         this.tableData = list
         this.requestData.total = res.data[this.pageMeta.pageTotal || 'total']
       }) */

    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      this.tableColumns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      var data = JSON.parse(JSON.stringify(this.requestData));
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      delete data.total;
      (0, _api.getPageList)(this.apiUrl, data).then(function (res) {
        _this4.downloadLoading = false;
        var list = [];

        if (_this4.pageMeta.pageList === null) {
          list = res.data;
        } else if (_this4.pageMeta.pageList) {
          list = res.data[_this4.pageMeta.pageList];
        } else {
          list = res.data['records'];
        }

        if (!_this4.setMenuPerms('user_mobile_idCard') && _this4.mobile.length > 0) {
          list.forEach(function (item) {
            _this4.mobile.forEach(function (key) {
              if (item[key]) {
                item[key] = item[key].replace(item[key].substring(3, 7), "****");
              }
            });
          });
        }

        if (_this4.isPageDwn) {
          _this4.$emit('goPageDwn', tHeader, filterVal, list);

          return;
        }

        _this4.getHandleDownload(tHeader, filterVal, list, _this4.pageMeta.title);
      }).catch(function (res) {
        _this4.downloadLoading = false;
      });
    },
    clearValue: function clearValue(item) {
      this.requestData[item.value] = item.type === 'radio' ? null : [];
    },
    goDelData: function goDelData(data) {
      this.$emit('goDelData', data);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.requestData.pageSize = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.requestData.pageNo = val;
      this.getList();
    }
  }
};
exports.default = _default2;