var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 补发购物卡 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "120px"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "手机号码：", prop: "phone" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { maxlength: "11", placeholder: "请输入手机号码" },
                on: { change: _vm.changeMobile, input: _vm.changeMobile },
                model: {
                  value: _vm.ruleForm.phone,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "phone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.phone"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "100px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.getUserInfo }
                },
                [_vm._v("查询")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "会员信息：" } },
            [
              _c(
                "el-form",
                { attrs: { inline: "", "label-width": "100px" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "userIno_formItem",
                      attrs: { label: "手机号码：" }
                    },
                    [_vm._v(_vm._s(_vm.userInfo.mobile))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "userIno_formItem",
                      attrs: { label: "会员姓名：" }
                    },
                    [_vm._v(_vm._s(_vm.userInfo.realname))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "userIno_formItem",
                      attrs: { label: "会员状态：" }
                    },
                    [_vm._v(_vm._s(_vm.userInfo.status))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "userIno_formItem",
                      attrs: { label: "会员积分：" }
                    },
                    [_vm._v(_vm._s(_vm.userInfo.score))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "userIno_formItem",
                      attrs: { label: "乐享值：" }
                    },
                    [_vm._v(_vm._s(_vm.userInfo.growthValue))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "userIno_formItem",
                      attrs: { label: "性别：" }
                    },
                    [
                      _vm.userInfo.sex == 1
                        ? _c("span", [_vm._v("男")])
                        : _vm.userInfo.sex == 2
                        ? _c("span", [_vm._v("女")])
                        : _c("span", [_vm._v("未知")])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "userIno_formItem",
                      attrs: { label: "民族：" }
                    },
                    [_vm._v(_vm._s(_vm.userInfo.nation))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _vm.isGoCard
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "type", label: "补发类型：" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.ruleForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "type", $$v)
                            },
                            expression: "ruleForm.type"
                          }
                        },
                        [_vm._v("365拉新")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.ruleForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "type", $$v)
                            },
                            expression: "ruleForm.type"
                          }
                        },
                        [_vm._v("签到补发")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "线上卡号：", prop: "cardNo" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { disabled: "", placeholder: "请输入新卡卡号" },
                        model: {
                          value: _vm.ruleForm.cardNo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "cardNo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.cardNo"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.getAward("card")
                            }
                          }
                        },
                        [_vm._v("选择购物卡")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "补发金额：", prop: "money" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          controls: false,
                          min: 1,
                          step: 1,
                          precision: 0
                        },
                        model: {
                          value: _vm.ruleForm.money,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "money", $$v)
                          },
                          expression: "ruleForm.money"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "凭证：", prop: "voucherPic" } },
                    [
                      _c("add-load", {
                        attrs: {
                          width: 250,
                          height: 180,
                          cover: _vm.ruleForm.voucherPic
                        },
                        on: {
                          setCover: function($event) {
                            return _vm.setCover($event, "voucherPic")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { placeholder: "请输入备注" },
                        model: {
                          value: _vm.ruleForm.remark,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "remark",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.remark"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.setMenuPerms("card_adjust")
                    ? _c(
                        "el-form-item",
                        [
                          _vm.isGoCard
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { width: "150px" },
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.submitForm("ruleForm")
                                    }
                                  }
                                },
                                [_vm._v("确认\n        ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }