"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TimeRangePicker',
  filters: {
    selectTimeText: function selectTimeText(time, startTime, endTime) {
      if (time.time === startTime.time && time.time === endTime.time) {
        return '开始时间->结束时间';
      }

      if (time.time === startTime.time) return '开始时间';
      if (time.time === endTime.time) return '结束时间';
      return '';
    }
  },
  props: {
    range: {
      type: Object,
      default: function _default() {
        return {
          times: [],
          used: []
        };
      }
    },
    reservedTimes: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      visible: false,
      startTime: {},
      endTime: {},
      times: [],
      imgSrc: this.$store.state.user.imgSrc
    };
  },
  watch: {
    range: {
      immediate: true,
      handler: function handler(crt) {
        var times = [];
        var reservedTimeMap = {};
        var list = crt.times;
        var len = list.length;
        crt.used.forEach(function (r) {
          reservedTimeMap[r] = r;
        });

        for (var i = 0; i < len; i++) {
          if (i + 1 < len) {
            times.push({
              time: "".concat(list[i], "-").concat(list[i + 1]),
              startTime: list[i],
              endTime: list[i + 1],
              checked: false,
              isBooking: !!reservedTimeMap[list[i]],
              index: i
            });
          }
        }

        this.startTime = {};
        this.endTime = {};
        this.times = times;
      }
    },
    visible: {
      immediate: true,
      handler: function handler(crt) {
        console.log('visible ===>', crt);
      }
    }
  },
  created: function created() {
    document.addEventListener('click', this.hide);
  },
  destroyed: function destroyed() {
    document.removeEventListener('click', this.hide);
  },
  methods: {
    hide: function hide() {
      if (new Date().getTime() - this.timer > 200) {
        this.visible = false;
      }
    },
    inputChange: function inputChange(v) {
      if (this.startTime.startTime) {
        this.$emit('input', "".concat(this.startTime.startTime, "-").concat(this.endTime.endTime));
      }
    },
    focus: function focus(e) {
      this.timer = new Date().getTime();
      this.visible = true;
    },
    checkedTime: function checkedTime(index) {
      var times = this.times.map(function (d, i) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, d), {}, {
          checked: index === i ? !d.checked : false
        });
      });
      var item = times[index];
      if (item.isBooking) return;
      var startTime = this.startTime,
          endTime = this.endTime;
      var idx = item.index; // 未选择

      if (!startTime.time && !endTime.time) {
        this.startTime = item;
        this.endTime = item;
        this.times = times;
        return;
      }

      if (idx === startTime.index) {
        // 点击开始时间
        this.startTime = this.endTime;
      } else if (idx === endTime.index) {
        // 点击结束时间
        this.endTime = this.startTime;
      } else if (idx < startTime.index) {
        this.startTime = item;
      } else {
        this.endTime = item;
      }

      for (var i = this.startTime.index; i <= this.endTime.index; i++) {
        if (times[i].isBooking) {
          // 中途存在已预定则中断
          times = this.times.map(function (d) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, d), {}, {
              checked: false
            });
          });
          times[index].checked = true;
          this.startTime = item;
          this.endTime = item;
          break;
        } else {
          times[i].checked = true;
        }
      }

      this.times = times;
    },
    confirm: function confirm() {
      if (!this.startTime.startTime) return;
      this.$emit('input', "".concat(this.startTime.startTime, "-").concat(this.endTime.endTime));
      this.visible = false;
    },
    clear: function clear() {
      this.$emit('input', '');
      this.times = this.times.map(function (d) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, d), {}, {
          checked: false
        });
      });
      this.startTime = {};
      this.endTime = {};
    }
  }
};
exports.default = _default2;