"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.salongLogDetail = salongLogDetail;
exports.salongLogPage = salongLogPage;

var _request = _interopRequireDefault(require("@/utils/request"));

// 活动信息统计分页查询
function salongLogPage(data) {
  return (0, _request.default)({
    url: '/api/svip/salonLog/page',
    method: 'post',
    data: data
  });
} // 活动信息统计分页查询详情


function salongLogDetail(data) {
  return (0, _request.default)({
    url: '/api/svip/salonLog/detail',
    method: 'post',
    data: data
  });
}