var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title" }, [_vm._v(" 权益礼品领取\n  ")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _vm.equityGoods && _vm.specialLevelsUser
        ? _c(
            "el-descriptions",
            {
              attrs: {
                size: "small ",
                labelStyle: {
                  width: "120px",
                  textAlign: "right"
                },
                column: 3,
                border: ""
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "权益名称：" } }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.equityGoods.rightName) + "\n    "
                )
              ]),
              _vm._v(" "),
              _vm.specialLevelsUser
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "权益类型：" } },
                    [
                      _vm.specialLevelsUser.rightItemType == 1
                        ? _c("span", [_vm._v("礼品权益")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.specialLevelsUser.rightItemType == 2
                        ? _c("span", [_vm._v("积分返利")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.specialLevelsUser.rightItemType == 3
                        ? _c("span", [_vm._v("多倍积分")])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "投放方式：" } }, [
                _vm.equityGoods.reciveMode === 1
                  ? _c("span", [_vm._v("每天")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.equityGoods.reciveMode === 2
                  ? _c("span", [_vm._v("每周")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.equityGoods.reciveMode === 3
                  ? _c("span", [_vm._v("每月")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.equityGoods.reciveMode === 6
                  ? _c("span", [_vm._v("季度")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.equityGoods.reciveMode === 4
                  ? _c("span", [_vm._v("会员生日")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.equityGoods.reciveMode === 5
                  ? _c("span", [_vm._v("会员生日月")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.equityGoods.reciveMode === 7
                  ? _c("span", [_vm._v("升降级礼品")])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.specialLevelsUser.rightItemType == 1
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "可领取数量：" } },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.equityGoods.choiceNum) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.specialLevelsUser.rightItemType == 1
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "已领取数量：" } },
                    [_vm._v("\n      " + _vm._s(_vm.getGiftNum) + "\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.specialLevelsUser.rightItemType == 1
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "确认需求数量：" } },
                    [_vm._v("\n      " + _vm._s(_vm.getAffrimNum) + "\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.specialLevelsUser.rightItemType == 3
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "领取规则：" } },
                    [
                      _vm.giftItems[0].giftCode == "dayUnit"
                        ? _c("span", [
                            _vm._v(
                              "\n        可领取" +
                                _vm._s(_vm.giftItems[0].num) +
                                "天" +
                                _vm._s(
                                  (
                                    _vm.specialLevelsUser.returnRadio / 100
                                  ).toFixed(1)
                                ) +
                                "倍积分\n      "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n        可领取" +
                                _vm._s(_vm.giftItems[0].num) +
                                "个订单" +
                                _vm._s(
                                  (
                                    _vm.specialLevelsUser.returnRadio / 100
                                  ).toFixed(1)
                                ) +
                                "倍积分\n      "
                            )
                          ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.specialLevelsUser.rightItemType == 3
                ? _c("el-descriptions-item", { attrs: { label: "已领取：" } }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.alreadySelectChange.length) +
                        "\n      "
                    ),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.giftItems[0].giftCode == "dayUnit" ? "天" : "单"
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.specialLevelsUser.rightItemType == 3
                ? _c("el-descriptions-item", { attrs: { label: "可领取：" } }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.giftItems[0].num - _vm.alreadySelectChange.length
                        ) +
                        "\n      "
                    ),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.giftItems[0].giftCode == "dayUnit" ? "天" : "单"
                        )
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _vm.specialLevelsUser && _vm.specialLevelsUser.rightItemType == 1
        ? _c(
            "el-table",
            {
              ref: "goodsTable",
              staticStyle: { width: "100%" },
              attrs: {
                size: "mini",
                data: _vm.userRightGifts,
                "row-key": "id",
                border: "",
                "header-cell-class-name": "table_header_cell"
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "礼品图片", align: "center", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("el-image", {
                            staticStyle: { width: "50px" },
                            attrs: {
                              src: _vm.imgSrc + scope.row.giftImage,
                              "preview-src-list": [
                                _vm.imgSrc + scope.row.giftImage
                              ]
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1461159932
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.tabTh, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    align: "center",
                    "min-width": item.width
                  }
                })
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "100", align: "center", label: "领取状态" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.recived == 1
                            ? _c(
                                "span",
                                { staticStyle: { color: "#67C23A" } },
                                [_vm._v("已领取")]
                              )
                            : _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("未领取")
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  541626733
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "100", align: "center", label: "确认需求" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.delegatedReceive == 1
                            ? _c(
                                "span",
                                { staticStyle: { color: "#67C23A" } },
                                [_vm._v("已确认需求")]
                              )
                            : _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("未确认")
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4003926037
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  width: "170",
                  align: "center",
                  fixed: "right",
                  label: "操作"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          !(
                            scope.row.delegatedReceive == 1 ||
                            scope.row.recived == 1
                          ) &&
                          _vm.getAffrimNum < _vm.equityGoods.choiceNum &&
                          _vm.getGiftNum < _vm.equityGoods.choiceNum
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.getCode(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("二维码")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.delegatedReceive != 1 &&
                          _vm.getAffrimNum < _vm.equityGoods.choiceNum &&
                          _vm.getGiftNum < _vm.equityGoods.choiceNum
                            ? _c(
                                "el-popconfirm",
                                {
                                  attrs: { title: "确认顾客领取该礼品？" },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.goAffrimGift(scope.row)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                        size: "mini"
                                      },
                                      slot: "reference"
                                    },
                                    [
                                      _vm._v(
                                        "\n            确认需求\n          "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.recived != 1 &&
                          _vm.getGiftNum < _vm.equityGoods.choiceNum
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goHelpGetGift(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("领取礼品")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2429139094
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.specialLevelsUser && _vm.specialLevelsUser.rightItemType == 3
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "view_title" },
                [
                  _vm._v(" 积分列表\n      "),
                  _vm.giftItems[0].giftCode == "dayUnit"
                    ? _c("span", { staticClass: "form_remark" }, [
                        _vm._v(
                          "\n        可领取" +
                            _vm._s(
                              _vm.giftItems[0].num -
                                _vm.alreadySelectChange.length
                            ) +
                            "天多倍积分\n      "
                        )
                      ])
                    : _c("span", { staticClass: "form_remark" }, [
                        _vm._v(
                          "\n        可领取" +
                            _vm._s(
                              _vm.giftItems[0].num -
                                _vm.alreadySelectChange.length
                            ) +
                            "个订单多倍积分\n      "
                        )
                      ]),
                  _vm._v(" "),
                  _vm.userIntegralAbleDTO.delegatedReceive != 1
                    ? _c(
                        "el-popconfirm",
                        {
                          attrs: { title: "确认顾客领取积分权益？" },
                          on: {
                            confirm: function($event) {
                              return _vm.goAffrimGift()
                            }
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                type: "primary",
                                size: "mini"
                              },
                              slot: "reference"
                            },
                            [_vm._v("\n          确认需求\n        ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !(_vm.userIntegralAbleDTO.delegatedReceive == 1)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.getCode()
                            }
                          }
                        },
                        [_vm._v("二维码\n      ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "scoreTable",
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    border: "",
                    size: "mini",
                    "row-key":
                      _vm.giftItems[0].giftCode == "dayUnit"
                        ? "day"
                        : "orderNo",
                    data: _vm.getScoreList
                  },
                  on: { "selection-change": _vm.selectScore }
                },
                [
                  _vm.giftItems[0].num > _vm.alreadySelectChange.length &&
                  _vm.userIntegralAbleDTO.delegatedReceive == 1
                    ? _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          "reserve-selection": "",
                          width: "55",
                          fixed: "left",
                          align: "center",
                          selectable: _vm.isSelectDisabled
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.giftItems[0].giftCode == "dayUnit"
                    ? _c("el-table-column", {
                        attrs: { label: "日期", align: "center", prop: "day" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.giftItems[0].giftCode == "orderUnit"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "订单号",
                          align: "center",
                          prop: "orderNo"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.giftItems[0].giftCode == "orderUnit"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "订单时间",
                          align: "center",
                          prop: "integralDate"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.giftItems[0].giftCode == "orderUnit"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "所属门店",
                          align: "center",
                          prop: "storeName"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "积分",
                      align: "center",
                      prop: "integralScore"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否领取",
                      align: "center",
                      prop: "integralScore"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.received &&
                              (scope.row.refNo != _vm.rightId ||
                                scope.row.refLevelId != _vm.levelId)
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#67C23A" } },
                                    [_vm._v("其他权益已领取")]
                                  )
                                : scope.row.received
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#67C23A" } },
                                    [_vm._v("已领取")]
                                  )
                                : _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("未领取")]
                                  )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3319836760
                    )
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-top": "10px" } }),
              _vm._v(" "),
              _vm.userIntegralAbleDTO.delegatedReceive == 1 &&
              _vm.giftItems[0].num > _vm.alreadySelectChange.length
                ? _c(
                    "el-popconfirm",
                    {
                      attrs: { title: "确认领取用户积分权益？" },
                      on: {
                        confirm: function($event) {
                          return _vm.goAffrimScoreGet()
                        }
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            type: "primary",
                            size: "mini"
                          },
                          slot: "reference"
                        },
                        [_vm._v("\n        确认领取积分\n      ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: { couponUUid: "代客领取礼品", "qr-code": _vm.qrCode },
            on: { closePop: _vm.closePop }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isGiftPop
        ? _c("div", { staticClass: "fixed_center" }, [
            _c(
              "div",
              { staticClass: "goodsGift" },
              [
                _c(
                  "div",
                  { staticClass: "view_title flex_between" },
                  [
                    _vm._v("\n        领取礼品\n        "),
                    _c("el-button", {
                      staticClass: "danger",
                      attrs: { icon: "el-icon-close", circle: "" },
                      on: {
                        click: function($event) {
                          return _vm.goCloseGift()
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-divider"),
                _vm._v(" "),
                _c(
                  "el-descriptions",
                  {
                    attrs: {
                      size: "small ",
                      labelStyle: {
                        width: "90px",
                        textAlign: "right"
                      },
                      column: 3,
                      border: ""
                    }
                  },
                  [
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "礼品名称：" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.giftData.productName) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "礼品来源：" } },
                      [
                        _vm.giftData.productSource === 1
                          ? _c("span", [_vm._v("积分商城")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.giftData.productSource === 2
                          ? _c("span", [_vm._v("大罗马")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.giftData.productSource === 3
                          ? _c("span", [_vm._v("乐购")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.giftData.productSource === 4
                          ? _c("span", [_vm._v("乐品")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.giftData.productSource === 6
                          ? _c("span", [_vm._v("话费充值")])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "送货方式：" } },
                      [
                        _vm.giftData.deliveryType === 0
                          ? _c("span", [_vm._v("到店自提")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.giftData.deliveryType === 1
                          ? _c("span", [_vm._v("邮寄到家")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.giftData.deliveryType === 2
                          ? _c("span", [_vm._v("到店自提/邮寄到家")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.giftData.deliveryType === 3
                          ? _c("span", [_vm._v("直冲")])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "市场价：" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.giftData.marketPrice) +
                            "元\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "商品价格：" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.giftData.priceStr) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-descriptions-item", { attrs: { label: "库存：" } }, [
                      _c("span", { staticClass: "danger" }, [
                        _vm._v("            " + _vm._s(_vm.giftData.stock))
                      ])
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-divider"),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "giftForm",
                    attrs: {
                      size: "mini",
                      model: _vm.form,
                      "label-width": "120px",
                      rules: _vm.formRules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "提货方式:", prop: "deliveryWay" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.deliveryWay,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "deliveryWay", $$v)
                              },
                              expression: "form.deliveryWay"
                            }
                          },
                          [
                            _vm.giftData.deliveryType === 0 ||
                            _vm.giftData.deliveryType === 2
                              ? _c("el-radio", { attrs: { label: "0" } }, [
                                  _vm._v(
                                    "\n              到店自提\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.giftData.deliveryType === 1 ||
                            _vm.giftData.deliveryType === 2
                              ? _c("el-radio", { attrs: { label: "1" } }, [
                                  _vm._v(
                                    "\n              邮寄到家\n            "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.giftData.useSpec == 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "规格:", prop: "productSpec" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.form.productSpec,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "productSpec", $$v)
                                  },
                                  expression: "form.productSpec"
                                }
                              },
                              _vm._l(_vm.giftData.productAttrItems, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: index,
                                    attrs: { label: item.specificationIds }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          JSON.parse(
                                            item.specificationName
                                          ).join("x")
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.deliveryWay == 0
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "自提门店:", prop: "storeCode" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.form.storeCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "storeCode", $$v)
                                  },
                                  expression: "form.storeCode"
                                }
                              },
                              _vm._l(_vm.giftData.productStores, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: index,
                                    attrs: {
                                      disabled: item.storeStock <= 0,
                                      label: item.storeCode
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.storeName) +
                                        "\n            "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.deliveryWay == 1
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "收货人姓名:",
                              prop: "consigneeName"
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "form_input",
                              model: {
                                value: _vm.form.consigneeName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "consigneeName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.consigneeName"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.deliveryWay == 1
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "收货人手机号:",
                              prop: "consigneePhone"
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "form_input",
                              model: {
                                value: _vm.form.consigneePhone,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "consigneePhone",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.consigneePhone"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.deliveryWay == 1
                      ? _c(
                          "el-form-item",
                          {
                            attrs: { label: "选择地址:", prop: "provinceCode" }
                          },
                          [
                            _c("el-cascader", {
                              ref: "cascader",
                              staticClass: "form_input",
                              attrs: {
                                placeholder: "可搜索",
                                options: _vm.citys,
                                filterable: "",
                                clearable: ""
                              },
                              on: { change: _vm.handleChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var node = ref.node
                                      var data = ref.data
                                      return [
                                        _c("span", [_vm._v(_vm._s(data.label))])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3476132270
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.deliveryWay == 1
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "邮寄地址:",
                              prop: "consigneeAddress"
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "form_input",
                              model: {
                                value: _vm.form.consigneeAddress,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "consigneeAddress",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.consigneeAddress"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.goAffrimGet()
                              }
                            }
                          },
                          [_vm._v("\n            确认领取\n          ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }