var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.list, function(item, index) {
      return _c("div", { key: index }, [
        _c(
          "div",
          { style: { backgroundColor: item.isRoot ? "" : "#fafafa" } },
          [
            item.childMenu && item.childMenu.length > 0
              ? _c(
                  "div",
                  [
                    !item.hidden
                      ? _c(
                          "el-submenu",
                          { key: item.name, attrs: { index: item.name } },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  style: {
                                    textAlign: _vm.isCollapse ? "center" : ""
                                  }
                                },
                                [
                                  _c("i", { class: item.icon }),
                                  _vm._v(" "),
                                  !_vm.isCollapse
                                    ? _c("span", [_vm._v(_vm._s(item.name))])
                                    : _vm._e()
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            [_c("nav-bar", { attrs: { list: item.childMenu } })]
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    item.url.indexOf("http") >= 0
                      ? _c("el-menu-item", { key: item.name }, [
                          _c(
                            "a",
                            { attrs: { href: item.url, target: "_blank" } },
                            [
                              _c("i", { class: item.icon }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.name))])
                            ]
                          )
                        ])
                      : !item.hidden
                      ? _c(
                          "el-menu-item",
                          { key: item.name, attrs: { index: item.url } },
                          [
                            [
                              _c("i", { class: item.icon }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.name))])
                            ]
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                )
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }