"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _pre = _interopRequireDefault(require("./components/pre.vue"));

var _Next = _interopRequireDefault(require("./components/Next.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      satausOptions: {
        1: "未领取",
        2: "已取消",
        3: "赠送成功",
        5: "已经过期"
      },
      mobile: null,
      cardNo: null,
      tableData: [],
      showData: null,
      drawer: false,
      columns: [{
        label: '转赠单号',
        prop: 'id',
        width: 120
      },
      /* {
        label: '储值卡UUID',
        prop: 'cuId',
      }, */
      {
        label: '手机号码',
        prop: 'mobile',
        width: 120
      }, {
        label: '昵称',
        prop: 'nickName'
      }, {
        label: '合计数量',
        prop: 'cardNum',
        width: 80
      }, {
        label: '合计金额',
        prop: 'balance',
        width: 80
      }, {
        label: '转赠时间',
        prop: 'createTime',
        width: 110
      }, {
        label: '领取人手机',
        prop: 'toMobile',
        width: 80
      }, {
        label: '领取人',
        prop: 'toMickName',
        width: 80
      }, {
        label: '领取时间',
        prop: 'expireTime',
        width: 120
      }]
    };
  },
  components: {
    Pre: _pre.default,
    Next: _Next.default
  },
  created: function created() {
    this.mobile = this.$route.query.mobile || '';
    this.cardNo = this.$route.query.cardNo || '';
    this.getTree();
  },
  methods: {
    setShowData: function setShowData(row) {
      this.showData = row;
      this.drawer = true;
    },
    getTree: function getTree(type) {
      var _this = this;

      if (this.mobile.length != 11) {
        if (type == 'search') {
          this.$message.error('请输入11位手机号码查询');
        }

        return;
      }

      this.apiPost('/api/cardStored/listCardStoredGiveTree', {
        mobile: this.mobile,
        cardNo: this.cardNo
      }).then(function (res) {
        if (!_this.setMenuPerms('user_mobile_idCard')) {
          res.data.forEach(function (item) {
            if (item.toMobile) {
              item.toMobile = item.toMobile.replace(item.toMobile.substring(3, 7), "****");
            }

            if (item.nickName) {
              item.nickName = item.nickName.replace(item.nickName.substring(3, 7), "****");
            }
          });
        }

        _this.tableData = res.data;
      });
    }
  }
};
exports.default = _default;