"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _api = require("../api.js");

var _request = require("@/utils/request.js");

var _auth = require("@/utils/auth");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      couponShow: false,
      tableData: [],
      selectOrderList: [],
      obj: {
        pageNo: 1,
        pageSize: 10,
        status: 2,
        type: 1
      },
      total: 0,
      tabTh: [{
        label: '团购订单',
        prop: 'groupCode',
        width: 80
      }, {
        label: '购买人',
        prop: 'buyUser',
        width: 80
      }, {
        label: '购买人手机号',
        prop: 'buyMobile',
        width: 60
      }, {
        label: '面额',
        prop: 'denomination',
        width: 80
      }, {
        label: '数量',
        prop: 'quantity',
        width: 80
      }, {
        label: '未兑换数量',
        prop: 'remainingQuantity',
        width: 60
      }, {
        label: '订单金额',
        prop: 'totalAmount',
        width: 60
      }, {
        label: '操作人',
        prop: 'createUserName',
        width: 80
      }, {
        label: '操作时间',
        prop: 'createTime',
        width: 60
      }],
      isSelectOrder: false,
      // 选择团购订单
      formData: [{
        slot: 'cardUuid',
        label: '团购购物卡：',
        type: 'input',
        placeholder: '请输入团购购物卡',
        value: 'cardUuid'
      }, {
        label: '购物卡面额：',
        type: 'inputNumber',
        placeholder: '请输入购物卡面额',
        value: 'denomination'
      },
      /* {
         label: '购物卡数量：',
         type: 'inputNumber',
         placeholder: '请输入购物卡数量',
         value: 'quantity'
       }, */
      {
        slot: 'oldGroupCodes'
      }, {
        slot: 'proportion',
        label: '返利比例：',
        type: 'inputNumber',
        placeholder: '请输入返利比例',
        value: 'proportion'
      }, {
        slot: 'voucher'
      }, {
        label: '备注：',
        type: 'input',
        placeholder: '请输入备注',
        value: 'remarks'
      }],
      ruleForm: {
        type: null,
        oldGroupCodes: [],
        proportion: null,
        voucher: "mika/dev/2022-09-28/f4d2a5cb84cd44b5b80d1876cc5dec2b.png",
        remarks: null,
        cardUuid: '',
        denomination: null,
        quantity: null,
        id: null
      },
      rules: {
        type: [{
          required: true,
          message: '请选择类型'
        }],
        proportion: [{
          required: true,
          message: '请输入返利比例'
        }],
        oldGroupCodes: [{
          required: true,
          message: '请选择返利订单'
        }],
        voucher: [{
          required: true,
          message: '请上传凭证'
        }],
        remarks: [{
          required: true,
          message: '请输入备注'
        }],
        cardUuid: [{
          required: true,
          message: '请输入购物卡卡号'
        }],
        denomination: [{
          required: true,
          message: '请输入面额'
        }],
        quantity: [{
          required: true,
          message: '请输入数量'
        }]
      }
    };
  },
  components: {
    addLoad: _addLoad.default,
    Pagination: _Pagination.default,
    selectAward: _radioItem.default,
    wwTable: _index.default
  },
  computed: {
    allAmount: function allAmount() {
      var price = 0;
      this.selectOrderList.forEach(function (item) {
        price += item.totalAmount;
      });
      return price;
    }
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = Number(this.$route.query.id);
    this.ruleForm.type = Number(this.$route.query.type);

    if (this.ruleForm.id > 0) {
      (0, _api.getInfo)(this.ruleForm.id).then(function (res) {
        _this.mch = res; // this.ruleForm.contractNo = res.contractNo

        _this.ruleForm = res; // this.remoteMethod(null,res.contractNo)
      });
    }
  },
  methods: {
    getAward: function getAward(e) {
      this.$refs.coupon1.getList('card', this.ruleForm.cardUuid);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      console.log(e);
      this.ruleForm.cardUuid = e.uuid;
      this.couponShow = false;
    },
    addCoupon: function addCoupon() {
      this.isSelectOrder = true;
    },
    init: function init() {
      var _this2 = this;

      (0, _api.getByPage)(this.obj).then(function (res) {
        console.log(res);
        _this2.tableData = res.data;
      });
    },
    clearCoupon: function clearCoupon() {
      this.$refs.multipleTable.clearSelection();
    },
    affirmAddCoupon: function affirmAddCoupon() {
      this.selectOrderList = this.$refs.multipleTable.store.states.selection;
      this.ruleForm.oldGroupCodes = this.$refs.multipleTable.store.states.selection.map(function (item) {
        return item.groupCode;
      });
      this.isSelectOrder = false;
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      var data = JSON.parse(JSON.stringify(this.ruleForm));
      data.oldGroupCodes = data.oldGroupCodes.join(',');
      (0, _api.addData)(data).then(function (res) {
        _this3.$router.go(-1);

        _this3.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;