var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row_div", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 4 } }, [
            _vm._v("栏目组名称(后端)：")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-input", {
                staticClass: "setInput",
                attrs: { placeholder: "请输入内容" },
                model: {
                  value: _vm.obj.groupName,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "groupName", $$v)
                  },
                  expression: "obj.groupName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row_div", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 4 } }, [
            _vm._v("栏目组名称(前端)：")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "name_type", attrs: { span: 18 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "name_select",
                  attrs: { placeholder: "名称" },
                  on: { change: _vm.selectChange },
                  model: {
                    value: _vm.obj.nameType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "nameType", $$v)
                    },
                    expression: "obj.nameType"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "文字", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "图标", value: 2 } })
                ],
                1
              ),
              _vm._v(" "),
              _vm.obj.nameType === 2
                ? _c("add-load", {
                    staticClass: "load",
                    class: _vm.obj.frontName === "" ? "" : "border",
                    attrs: { width: 300, height: 36, cover: _vm.obj.frontName },
                    on: { setCover: _vm.setCover }
                  })
                : _c("el-input", {
                    staticClass: "frontName_input",
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.obj.frontName,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "frontName", $$v)
                      },
                      expression: "obj.frontName"
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row_div", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 4 } }, [
            _vm._v("排序：")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-input", {
                staticClass: "setInput",
                attrs: { placeholder: "请输入内容" },
                model: {
                  value: _vm.obj.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "sort", $$v)
                  },
                  expression: "obj.sort"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row_div", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 4 } }, [
            _vm._v("会员：")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "li_div", attrs: { span: 18 } },
            _vm._l(_vm.obj.memArr, function(item) {
              return _c(
                "el-radio",
                {
                  key: item.cardId,
                  staticStyle: { width: "180px", "margin-top": "10px" },
                  attrs: { value: item.cardId, label: item.cardId },
                  model: {
                    value: _vm.obj.memberId,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "memberId", $$v)
                    },
                    expression: "obj.memberId"
                  }
                },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row_div", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 4 } }, [
            _vm._v("栏目组状态：")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "radio_div", attrs: { span: 6 } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.status,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "status", $$v)
                    },
                    expression: "obj.status"
                  }
                },
                [_vm._v("启用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: -1 },
                  model: {
                    value: _vm.obj.status,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "status", $$v)
                    },
                    expression: "obj.status"
                  }
                },
                [_vm._v("停用")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row_div", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 4 } }, [
            _vm._v("\n       \n    ")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.next } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }