var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("WForm", {
        ref: "addCategory",
        attrs: {
          formData: _vm.formData,
          isSetRules: "",
          isHttps: "",
          ruleForm: _vm.ruleForm
        },
        on: { submitForm: _vm.submitForm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }