var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            "search-data": _vm.searchData
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "120"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { class: scope.row.status == 1 ? "success" : "danger" },
                      [_vm._v(_vm._s(scope.row.status == 1 ? "启用" : "停用"))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "contextType",
              label: "信息内容",
              align: "center",
              width: "120"
            },
            slot: "contextType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.contextType == 1
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("短信模板")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.contextType == 2
                      ? _c("span", { staticClass: "primary" }, [
                          _vm._v("企微消息模板")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.contextType == 3
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("彩信模板")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.contextType == 4
                      ? _c("span", { staticClass: "warning" }, [
                          _vm._v("自定义")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "examStatus",
              label: "审核状态",
              align: "center",
              width: "120"
            },
            slot: "examStatus",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.examStatus == 1
                      ? _c("span", { staticClass: "warning" }, [
                          _vm._v("待审核")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.examStatus == 2
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("审核通过")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.examStatus == 3
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("审核不通过")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "executeStatus",
              label: "执行状态",
              align: "center",
              width: "120"
            },
            slot: "executeStatus",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.executeStatus == 1
                      ? _c("span", { staticClass: "warning" }, [
                          _vm._v("待执行")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.executeStatus == 2
                      ? _c("span", { staticClass: "primary" }, [
                          _vm._v("执行中")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.executeStatus == 3
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("已结束")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "executionMethod",
              label: "执行方式",
              align: "center",
              width: "120"
            },
            slot: "executionMethod",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.executionMethod == 1
                      ? _c("span", { staticClass: "warning" }, [
                          _vm._v("审核完立即发送")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.executionMethod == 2
                      ? _c("span", { staticClass: "primary" }, [
                          _vm._v("指定时间")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.executionMethod == 3
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("事件触发")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "templateId",
              label: "关联模板",
              align: "center",
              width: "160"
            },
            slot: "templateId",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.contextType == 1 || scope.row.contextType == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/message/temAdd?id=" + scope.row.templateId
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  scope.row.templateName || scope.row.templateId
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.templateName || scope.row.templateId
                            )
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "event",
              label: "触发事件",
              align: "center",
              width: "120"
            },
            slot: "event",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.eventData[scope.row.event]) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "sendTime",
              label: "限制条件",
              align: "center",
              width: "200"
            },
            slot: "sendTime",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.eventContext
                      ? _c("div", [
                          _vm.showSendTime.includes(scope.row.event)
                            ? _c("div", [
                                _vm._v(
                                  "\n            时间：" +
                                    _vm._s(
                                      JSON.parse(scope.row.eventContext)
                                        .sendTime
                                    ) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showDay.includes(scope.row.event)
                            ? _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      JSON.parse(scope.row.eventContext).days
                                    ) +
                                    "天\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.event == 8 || scope.row.event == 9
                            ? _c("div", [
                                _vm._v(
                                  "\n            lavant会员：" +
                                    _vm._s(
                                      JSON.parse(scope.row.eventContext).id
                                    ) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.event == 11
                            ? _c("div", [
                                _vm._v(
                                  "\n            充值到" +
                                    _vm._s(
                                      JSON.parse(scope.row.eventContext).code
                                    ) +
                                    "满" +
                                    _vm._s(
                                      JSON.parse(scope.row.eventContext).amount
                                    ) +
                                    "元\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.event == 13
                            ? _c("div", [
                                _vm._v(
                                  "\n            优惠券编码" +
                                    _vm._s(
                                      JSON.parse(scope.row.eventContext).code
                                    ) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.event == 17
                            ? _c("div", [
                                _c("p", [
                                  _vm._v(
                                    "单笔消费" +
                                      _vm._s(
                                        JSON.parse(scope.row.eventContext)
                                          .amount
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                scope.row.manager > 0
                                  ? _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          JSON.parse(scope.row.eventContext)
                                            .manager == 1
                                            ? "有要客经理"
                                            : "无要客经理 "
                                        )
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "关联门店：" +
                                      _vm._s(
                                        JSON.parse(scope.row.eventContext)
                                          .code == "0"
                                          ? "所有门店"
                                          : JSON.parse(scope.row.eventContext)
                                              .code
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                scope.row.mobiles
                                  ? _c("p", [
                                      _vm._v(
                                        "@群人员手机号" +
                                          _vm._s(scope.row.mobiles)
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.executionMethod == 2
                      ? _c("div", [
                          _vm._v(
                            "\n          指定时间" +
                              _vm._s(scope.row.appointTime) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              fixed: "right",
              align: "center",
              width: "120"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.examStatus == 1
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认通过审核？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goAudit(scope.row, 2)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "success",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("\n            通过审核\n          ")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.examStatus == 1
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认驳回审核？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goAudit(scope.row, 3)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "danger",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("\n            驳回\n          ")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.examStatus == 2 && scope.row.executeStatus != 3
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认结束执行？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goStop(scope.row, 3)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "danger",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("\n            结束\n          ")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage("/message/add?id=" + scope.row.id)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/message/add?type=copy&id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("复制")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }