"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _user = require("@/api/user.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    wTable: _index.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      url: '',
      couponUUid: '',
      qrCode: '',
      popShow: false,
      urlList: _selectUrl.default.urlList,
      appIdArr: _selectUrl.default.appIdArr,
      columns: [{
        label: '页面名称',
        prop: 'label',
        width: 50
      }, {
        slot: 'pageUrl',
        label: '路径',
        prop: 'value',
        width: 50
      }, {
        label: '备注',
        prop: 'remark',
        width: 100
      }, {
        slot: 'operate'
      }],
      columns1: [{
        label: '小程序名称',
        prop: 'label',
        width: 100
      }, {
        label: 'APPID',
        prop: 'value',
        width: 50
      }]
    };
  },
  methods: {
    closePop: function closePop() {
      this.popShow = false;
    },
    goQrcode: function goQrcode(scope) {
      var _this = this;

      this.couponUUid = scope.label;
      console.log(scope);
      (0, _user.couponQRCode)({
        path: scope.value.replace('/', ''),
        scene: scope.scene || '1'
      }).then(function (res) {
        // this.url = scope.value + (scope.scene ? ('?' + scope.scene) : '')
        _this.qrCode = res.data;
        _this.popShow = true;
      });
    },
    goCopyUrl: function goCopyUrl(row) {
      var _this2 = this;

      console.log(row);

      if (row.urlScene) {
        var data = {
          value: row.page,
          label: row.label,
          scene: row.urlScene
        };
        this.goQrcode(data);
        return;
      }

      this.$prompt('请输入对应ID', '提示', {
        confirmButtonText: '生成小程序码',
        cancelButtonText: '取消'
      }).then(function (_ref) {
        var value = _ref.value;
        var data = {
          value: row.page,
          label: row.label,
          scene: row.scene + value
        };

        _this2.goQrcode(data);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    }
  }
};
exports.default = _default;