"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

var _coupon2 = _interopRequireDefault(require("@/common/coupon.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      verificationModelArr: _coupon2.default.verificationModelArr,
      showStatusArr: _coupon2.default.showStatusArr,
      CS: {
        'min-width': '250px',
        //最小宽度
        'word-break': 'break-all' //过长时自动换行

      },
      couponInfo: null,
      detailRollimg: [],
      detailCtmfield: [],
      salestypes: [],
      shareImg: '',
      shareTitle: '',
      step: [{
        tit: '新建优惠券',
        txt: ''
      }, {
        tit: '审核优惠券',
        txt: ''
      }, {
        tit: '纳入投放方案',
        txt: ''
      }, {
        tit: '优惠券有效期结束',
        txt: ''
      }],
      uuid: '',
      urlEnd: '',
      discount: '',
      cappingMoney: '',
      experienceNumber: '',
      imgSrc: this.$store.state.user.imgSrc,
      active: -1,
      btnType: 0,
      status: '',
      id: '',
      denomination: '',
      name: '',
      couponType: '',
      type: '',
      costTypes: '',
      costType: '',
      ratioTenant: '',
      ratioCompany: '',
      mainImage: '',
      descrition: '',
      validTimeType: 1,
      validBeginTime: '',
      validEndTime: '',
      validDay: '',
      validStartDay: null,
      doorsillMoney: '',
      price: 0,
      limitNumber: 0,
      totalNumber: 0,
      institutionNames: [],
      institutionIds: [],
      verificationModel: [],
      instructions: '',
      geDetails: '',
      goodsIds: [],
      goodsIds1: [],
      usableType: '1',
      usableTypeTxt: '可用',
      rejectRule: '',
      buyType: 1,
      integral: 0,
      limitGoods: '',
      overlayNumber: '',
      showStatus: '',
      isTohome: '',
      isRightsCoupon: '',
      stockPrice: '',
      supplier: '',
      supplierMobile: '',
      ifDonation: null,
      oprLogs: [],
      rejectVipPrice: ''
    };
  },
  computed: {
    isEdit: function isEdit() {
      if (this.btnType === 1 && this.setMenuPerms('coupon:edit') || this.btnType === 2 && (this.setMenuPerms('coupon:editAll') || this.setMenuPerms('coupon:editGoods'))) {
        return true;
      } else {
        return false;
      }
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    goEdit: function goEdit(type) {
      // this.$store.dispatch('editor/setCouponEditor', '')
      if (!(this.btnType === 1 || this.btnType === 2)) return;
      /*
      编辑
      */

      if (type == 1) {
        this.$router.push('/coupon/coupon1/addVolume?id=' + this.id + '&couponType=' + this.type);
        return;
      }

      if (type == 2 && this.type != 5) {
        this.$router.push('/coupon/coupon1/editGoods?id=' + this.id);
        return;
      }

      return;
    },
    setActive: function setActive(index) {
      switch (index) {
        case 1:
          this.status = '待审核';
          this.active = index;
          this.btnType = 1;
          break;

        case 2:
          this.status = '已审核';
          this.active = 2;
          this.btnType = 2;
          break;

        case 3:
          this.status = '审核撤回';
          this.active = 1; // this.btnType = 1

          break;

        case 4:
          this.status = '审核撤销';
          this.active = -1; // this.btnType = 0

          break;

        case 5:
          this.status = '已过期';
          this.active = 4; // this.btnType = 0

          break;

        case 6:
          this.status = '已终止';
          this.active = -1;
          this.btnType = 0;
          break;

        case 8:
          this.status = '已投放';
          this.active = 3; // this.btnType = 2

          break;

        case 9:
          this.status = '投放完成';
          this.active = 4; // this.btnType = 0

          break;
      }
    },
    init: function init() {
      var _this = this;

      this.id = Number(this.$route.query.id);
      (0, _coupon.getCouponById)(this.id).then(function (res) {
        console.log(res);
        _this.couponInfo = res.data;
        _this.uuid = res.data.uuid;
        _this.rejectVipPrice = res.data.rejectVipPrice;
        _this.salestypes = res.data.salestypes || [];
        var verificationModel = res.data.verificationModel ? JSON.parse(res.data.verificationModel) : [];
        _this.showStatus = _this.showStatusArr.find(function (item) {
          return item.id == res.data.showStatus;
        }).name;
        _this.verificationModel = verificationModel.map(function (item) {
          return _this.verificationModelArr.find(function (row) {
            return row.id == item;
          }).name;
        });

        _this.setActive(res.data.status);

        var detailRollimg = res.data.detailRollimg ? JSON.parse(res.data.detailRollimg) : [];
        _this.detailRollimg = detailRollimg.map(function (item) {
          return _this.imgSrc + item.img;
        });
        _this.detailCtmfield = res.data.detailCtmfield ? JSON.parse(res.data.detailCtmfield) : [], _this.stockPrice = res.data.stockPrice || '';
        _this.supplier = res.data.supplier || '';
        _this.supplierMobile = res.data.supplierMobile || '';

        _this.detailCtmfield.forEach(function (item) {
          item.text = item.text.replace(/\n/g, '<br />');
        });

        _this.shareImg = res.data.shareImg;
        _this.shareTitle = res.data.shareTitle;
        _this.denomination = res.data.denomination;
        _this.discount = res.data.discount;
        _this.cappingMoney = res.data.cappingMoney;
        _this.experienceNumber = res.data.experienceNumber;
        _this.isRightsCoupon = res.data.isRightsCoupon;
        _this.isTohome = res.data.isTohome;
        _this.name = res.data.name;
        _this.buyType = res.data.buyType;
        _this.integral = res.data.integral;
        _this.ifDonation = res.data.ifDonation;
        var putOpr = res.data.oprLogs || [];
        _this.oprLogs = res.data.oprLogs;
        putOpr.forEach(function (item) {
          if (item.oprType === 1) {
            _this.step[0].txt = item.oprUserName + item.oprTime;
          } else if (item.oprType === 2) {
            _this.step[1].txt = item.oprUserName + item.oprTime;
          }
        });
        _this.type = res.data.couponType;

        if (res.data.couponType === 1) {
          _this.urlEnd = 'addVolume';
          _this.couponType = '代金券';
        } else if (res.data.couponType === 2) {
          _this.urlEnd = 'addExperience';
          _this.couponType = '折扣券';
        } else if (res.data.couponType === 3) {
          _this.urlEnd = 'addGift';
          _this.couponType = '礼品券';
        } else if (res.data.couponType === 4) {
          _this.urlEnd = 'addDiscount';
          _this.couponType = '体验券';
        } else if (res.data.couponType === 5) {
          _this.couponType = '外部优惠券';
        }

        if (res.data.costType === 1) {
          _this.costTypes = 'A类券-集团公司承担';
        } else if (res.data.costType === 2) {
          _this.costTypes = 'B类券-专柜/品牌/商户承担';
        } else if (res.data.costType === 3) {
          _this.costTypes = 'C类券-集团与商户按比例承担';
        }

        _this.costType = res.data.costType;
        _this.ratioCompany = res.data.ratioCompany;
        _this.ratioTenant = res.data.ratioTenant;
        _this.mainImage = _this.imgSrc + res.data.mainImage;
        _this.descrition = res.data.descrition;
        _this.validTimeType = res.data.validTimeType;

        if (res.data.validTimeType === 1) {
          _this.validBeginTime = res.data.validBeginTime;
          _this.validEndTime = res.data.validEndTime;
        } else {
          _this.validDay = res.data.validDay;
          _this.validStartDay = res.data.validStartDay;
        }

        _this.doorsillMoney = res.data.doorsillMoney;
        _this.price = res.data.price;
        _this.limitNumber = res.data.limitNumber;
        _this.totalNumber = res.data.totalNumber;
        _this.institutionNames = res.data.institutionNames;
        _this.institutionIds = JSON.parse(res.data.institutionIds); //					res.data.institutionNames.forEach((value)=>{
        //						this.institutionNames+=value+' '
        //					})

        _this.usableType = res.data.usableType;

        if (res.data.usableType == '1') {
          _this.usableTypeTxt = '可用';
        } else {
          _this.usableTypeTxt = '不可用';
        }

        _this.goodsIds = JSON.parse(res.data.goodsIds);
        _this.goodsIds1 = res.data.goodsIds1 == 'null' ? [] : JSON.parse(res.data.goodsIds1);

        if (res.data.rejectRule == 1) {
          _this.rejectRule = '不与其他优惠券同时使用';
        } else if (res.data.rejectRule == 2) {
          _this.rejectRule = '可与其他优惠券同时使用';
        } else if (res.data.rejectRule == 3) {
          _this.rejectRule = '全场通用';
        }

        _this.instructions = res.data.instructions;
        _this.geDetails = res.data.geDetails;
      });
    },
    passAudit: function passAudit(index) {
      var _this2 = this;

      var str = '';

      if (index === 2) {
        str = '此操作将通过审核方案，审核通过后将不可修改， 是否继续?';
      } else if (index === 3) {
        str = '此操作将撤回审核， 是否继续?';
      } else if (index === 4) {
        str = '此操作将撤销审核，撤销通过后将不可修改， 是否继续?';
      } else if (index === 6) {
        str = '此操作将终止方案，终止通过后将作废， 是否继续?';
      }

      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _coupon.editCouponStatu)(_this2.id, index).then(function (res) {
          console.log(res);

          _this2.$message({
            type: 'success',
            message: '审核成功!'
          });

          _this2.setActive(index);
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消审核'
        });
      });
    }
  }
};
exports.default = _default;