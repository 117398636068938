"use strict";

var _interopRequireWildcard = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _index = require("@/api/svip/index.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var R = _interopRequireWildcard(require("ramda"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      searchParams: {
        pageNo: 1,
        pageSize: 10,
        svipName: '',
        managerName: '',
        date: null,
        sVipContactNumber: null
      },
      total: 0
    };
  },
  computed: {
    columns: function columns() {
      return [{
        label: '要客姓名',
        prop: 'svipName'
      }, {
        label: '联系电话',
        prop: 'sVipContactNumber'
      }, {
        label: '原荔星伙伴',
        prop: 'oldManagerName'
      }, {
        label: '现荔星伙伴',
        prop: 'currentManagerName'
      }, {
        label: '变更时间',
        prop: 'changeTime'
      }, {
        label: '变更理由',
        prop: 'reason'
      }];
    }
  },
  created: function created() {
    this.fetch();
  },
  methods: {
    fetch: function fetch(type) {
      var _this = this;

      if (type === 'search') {
        this.searchParams.pageNo = 1;
        this.searchParams.pageSize = 10;
      }

      if (type === 'reset') {
        this.removeObj(this.searchParams);
      }

      var date = this.searchParams.date;
      var params = R.omit(['date'], this.searchParams);

      if (date && date.length > 0) {
        params['changeTimeStartDay'] = date[0];
        params['changeTimeEndDay'] = date[1];
      }

      (0, _index.getPageManagerRecordList)(params).then(function (res) {
        _this.total = res.data.totalNum;

        if (!_this.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.sVipContactNumber) {
              item.sVipContactNumber = item.sVipContactNumber.replace(item.sVipContactNumber.substring(3, 7), "****");
            }
          });
        }

        _this.list = res.data.items;
      });
    },
    goto: function goto(url) {
      this.$router.push(url);
    }
  }
};
exports.default = _default;