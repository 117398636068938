"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/web.dom.iterable");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _index2 = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    WTable: _index.default,
    UploadExcelComponent: _index2.default
  },
  data: function data() {
    return {
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/unlock.xlsx"),
      isShowPop: false,
      ruleForm: {
        activationNo: null,
        interestsCode: '',
        mobileList: []
      },
      rules: {
        interestsCode: [{
          required: true,
          message: '请选择权益后激活'
        }],
        activationNo: [{
          required: true,
          message: '请输入激活批次号'
        }],
        mobileList: [{
          required: true,
          message: '请上传文件'
        }]
      },
      fileName: '',
      downloadLoading: false,
      requestData: {
        pushIndex: null,
        basicName: '',
        basicStatus: '',
        companyCode: '',
        storeCode: '',
        brandClsCode: '',
        brandCode: '',
        goodsCode: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '权益包编码',
        prop: 'interestsCode',
        width: 100
      }, {
        label: '权益包名称',
        prop: 'interestsName',
        width: 100
      }, {
        label: '券数量',
        prop: 'couponCodeNumbers',
        width: 50
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'vipType'
      }, {
        slot: 'interestsStatus'
      }, {
        slot: 'pushIndex'
      }, {
        label: '领取数量',
        prop: 'receiveNumber',
        width: 50
      }, {
        label: '激活数量',
        prop: 'activationNumber',
        width: 50
      }, {
        slot: 'operate'
      }]
    };
  },
  activated: function activated() {
    // console.log(RegExp(/^1[3456789]\d{9}$/).test(1888560807))
    this.getList();
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var arr = [];
          var self = _this;
          self.ruleForm.mobileList.forEach(function (item) {
            arr.push({
              userMobile: item,
              interestsCode: self.interestsCode,
              activationNo: self.ruleForm.activationNo
            });
          });
          (0, _api.activationInterests)({
            interests: arr
          }).then(function (res) {
            self.isShowPop = false;
            self.getList();
            console.log(res);
          });
        } else {
          return false;
        }
      });
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      console.log(results);
      results.forEach(function (data) {
        if (RegExp(/^1[3456789]\d{9}$/).test(data.mobile)) {
          arr.push(data.mobile);
        }
      });
      this.ruleForm.mobileList = arr;
      this.$refs.ruleForm.validateField('mobileList');
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 4;

      if (isLt1M) {
        this.fileName = file.name;
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    goActivationInterests: function goActivationInterests(row) {
      this.isShowPop = true;
      this.ruleForm.interestsCode = row.interestsCode;
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];
        var data = {};

        _this2.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this2.requestData).forEach(function (key) {
          if (_this2.requestData[key]) {
            data[key] = _this2.requestData[key];
          }
        });
        data.pageSize = _this2.requestData.total;
        data.pageNo = 1;
        (0, _api.listVipInterestsPage)(data).then(function (res) {
          list = res.data.items;

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '权益解锁'
          });
          _this2.downloadLoading = false;
        }).catch(function (res) {
          _this2.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.removeObj(this.requestData);
      }

      (0, _api.listVipInterestsPage)(this.requestData).then(function (res) {
        _this3.tableData = res.data.items;
        _this3.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;