"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _slide = _interopRequireDefault(require("@/components/slide.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default,
    slide: _slide.default
  },
  filters: {
    memberTypeObj: function memberTypeObj(type) {
      var Obj = {
        1: '普通会员',
        2: '要客',
        3: '365'
      };
      return Obj[type];
    }
  },
  data: function data() {
    return {
      time: [],
      isRemark: false,
      remark: '',
      isCard: false,
      orderNo: '',
      imgSrc: this.$store.state.user.imgSrc,
      downloadLoading: false,
      requestData: {
        buyUtphone: '',
        goodsCode: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        slot: 'brandName',
        label: '券图片',
        prop: 'brandName'
      }, {
        label: '订单号',
        prop: 'orderNo',
        width: 10
      }, {
        label: '流水号',
        prop: 'tradeNo',
        width: 10
      }, {
        label: '券编码',
        prop: 'goodsCode',
        width: 10
      }, {
        label: '券名称',
        prop: 'goodsName',
        width: 10
      }, {
        label: '购买数量',
        prop: 'goodsNumber',
        width: 5
      }, {
        label: '会员手机号',
        prop: 'buyUtphone',
        width: 5
      }, {
        label: '会员姓名',
        prop: 'buyUname',
        width: 5
      }, {
        label: '会员名称',
        prop: 'memberName',
        width: 10
      }, {
        slot: 'memberType',
        label: '会员类型',
        prop: 'memberType',
        width: 10
      }, {
        label: '订单金额',
        prop: 'payScoreAmountStr',
        width: 5
      }, {
        label: '订单时间',
        prop: 'orderCreateTime',
        width: 10
      },
      /* 退货时间：refundTime
      操作人：institutionName */
      {
        label: '退款操作人',
        prop: 'institutionName',
        width: 5
      }, {
        label: '退款时间',
        prop: 'refundTime',
        width: 10
      }, {
        slot: 'facilityCode',
        label: '退券备注',
        prop: 'facilityCode',
        width: 10
      }, {
        slot: 'orderStatus',
        label: '订单状态',
        prop: 'orderStatus'
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(value) {
      if (value) {
        this.requestData.startTime = value[0];
        this.requestData.endTime = value[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    },
    isRemark: function isRemark() {
      this.remark = null;
    }
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    popAffrim: function popAffrim() {
      if (this.remark) {
        this.isCard = true;
      }
    },
    goUserKpi: function goUserKpi() {
      var _this = this;

      (0, _api.couponBagRefund)({
        orderNo: this.orderNo,
        remark: this.remark
      }).then(function (res) {
        _this.isRemark = false;

        _this.getList();
      });
    },
    getRefund: function getRefund(row) {
      this.orderNo = row.orderNo;
      this.isRemark = true;
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this2.requestData[key]) {
          data[key] = _this2.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.couponBagOrderPage)(data).then(function (res) {
        list = res.data.items;
        _this2.downloadLoading = false;

        _this2.getHandleDownload(tHeader, filterVal, list, '券包订单');
      }).catch(function (res) {
        _this2.downloadLoading = false;
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else if (j === 'memberType') {
            var Obj = {
              1: '普通会员',
              2: '要客',
              3: '365'
            };
            return Obj[v[j]];
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = [];
        this.removeObj(this.requestData);
      }

      (0, _api.couponBagOrderPage)(this.requestData).then(function (res) {
        _this3.tableData = res.data.items;
        _this3.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;