"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _validate = require("@/utils/validate");

var _SocialSignin = _interopRequireDefault(require("./components/SocialSignin"));

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  components: {
    SocialSign: _SocialSignin.default
  },
  data: function data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      checked: false
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;

        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {// window.addEventListener('storage', this.afterQRScan)
  },
  mounted: function mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }

    var loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    this.loginForm = loginInfo ? loginInfo : this.loginForm;
  },
  destroyed: function destroyed() {// window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          shiftKey = _ref.shiftKey,
          key = _ref.key;

      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }

      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    showPwd: function showPwd() {
      var _this = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;

          _this2.$store.dispatch('user/login', _this2.loginForm).then(function (res) {
            if (_this2.checked) {
              localStorage.setItem('loginInfo', JSON.stringify(_this2.loginForm));
            } else {
              localStorage.removeItem('loginInfo');
            }

            if (_this2.redirect && _this2.redirect != '/dashboard') {
              _this2.$router.go(-1);
            } else {
              _this2.$router.push({
                path: '/',
                query: _this2.otherQuery
              });
            } // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })


            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }

        return acc;
      }, {});
    } // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }

  }
  /* //这是背景粒子连线js代码
   var canvas = document.createElement("canvas")
   canvas.id = "cas"
   document.body.appendChild(canvas)
   var ctx = canvas.getContext("2d");
  
   resize();
   window.onresize = resize;
  
   function resize() {
   	canvas.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
   	canvas.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
   }
  
   var RAF = (function() {
   	return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window
   		.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame ||
   		function(callback) {
   			window.setTimeout(callback, 1000 / 60);
   		};
   })();
  
   // 鼠标活动时，获取鼠标坐标
   var warea = {
   	x: null,
   	y: null,
   	max: 20000
   };
   window.onmousemove = function(e) {
   	e = e || window.event;
  
   	warea.x = e.clientX;
   	warea.y = e.clientY;
   };
   window.onmouseout = function(e) {
   	warea.x = null;
   	warea.y = null;
   };
  
   // 添加粒子
   // x，y为粒子坐标，xa, ya为粒子xy轴加速度，max为连线的最大距离
   var dots = [];
   for (var i = 0; i < 360; i++) {
   	var x = Math.random() * canvas.width;
   	var y = Math.random() * canvas.height;
   	var xa = Math.random() * 2 - 1;
   	var ya = Math.random() * 2 - 1;
  
   	dots.push({
   		x: x,
   		y: y,
   		xa: xa,
   		ya: ya,
   		max: 6000
   	})
   }
  
   // 延迟100秒开始执行动画，如果立即执行有时位置计算会出错
   setTimeout(function() {
   	animate();
   }, 100);
  
   // 每一帧循环的逻辑
   function animate() {
   	ctx.clearRect(0, 0, canvas.width, canvas.height);
  
   	// 将鼠标坐标添加进去，产生一个用于比对距离的点数组
   	var ndots = [warea].concat(dots);
  
   	dots.forEach(function(dot) {
  
   		// 粒子位移
   		dot.x += dot.xa;
   		dot.y += dot.ya;
  
   		// 遇到边界将加速度反向
   		dot.xa *= (dot.x > canvas.width || dot.x < 0) ? -1 : 1;
   		dot.ya *= (dot.y > canvas.height || dot.y < 0) ? -1 : 1;
  
   		// 绘制点
  
   		ctx.fillRect(dot.x - 0.5, dot.y - 0.5, 1, 1);
  
   		// 循环比对粒子间的距离
   		for (var i = 0; i < ndots.length; i++) {
   			var d2 = ndots[i];
  
   			if (dot === d2 || d2.x === null || d2.y === null) continue;
  
   			var xc = dot.x - d2.x;
   			var yc = dot.y - d2.y;
  
   			// 两个粒子之间的距离
   			var dis = xc * xc + yc * yc;
  
   			// 距离比
   			var ratio;
  
   			// 如果两个粒子之间的距离小于粒子对象的max值，则在两个粒子间画线
   			if (dis < d2.max) {
  
   				// 如果是鼠标，则让粒子向鼠标的位置移动
   				if (d2 === warea && dis > (d2.max / 2)) {
   					dot.x -= xc * 0.03;
   					dot.y -= yc * 0.03;
   				}
  
   				// 计算距离比
   				ratio = (d2.max - dis) / d2.max;
  
   				// 画线
   				ctx.beginPath();
   				ctx.fillStyle = "#9400D3";
   				ctx.lineWidth = ratio / 2;
   				ctx.strokeStyle = 'rgba(0,206,209,' + (ratio + 0.2) + ')';
   				ctx.moveTo(dot.x, dot.y);
   				ctx.lineTo(d2.x, d2.y);
   				ctx.stroke();
   			}
   		}
  
   		// 将已经计算过的粒子从数组中删除
   		ndots.splice(ndots.indexOf(dot), 1);
   	});
  
   	RAF(animate);
   } */

};
exports.default = _default;