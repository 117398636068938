var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "update-realname" },
    [
      _c("auth", {
        attrs: { confirmBtn: "下一步" },
        on: { finished: _vm.onAuthFinished }
      }),
      _vm._v(" "),
      _vm.isAuthed
        ? _c(
            "div",
            { staticClass: "group" },
            [
              _c("div", { staticClass: "group-title" }, [_vm._v("实名信息")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "form",
                  attrs: {
                    model: _vm.params,
                    rules: _vm.rules,
                    "label-width": "180px",
                    size: "small"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-width",
                      attrs: { label: "真实姓名", prop: "name" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.params.name,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "name", $$v)
                          },
                          expression: "params.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-width",
                      attrs: { label: "身份证号", prop: "name" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.params.name,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "name", $$v)
                          },
                          expression: "params.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-width",
                      attrs: { label: "实名手机号", prop: "name" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.params.name,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "name", $$v)
                          },
                          expression: "params.name"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-tip" }, [
                        _vm._v(
                          "若此手机号以注册米卡小程序，用户可以在小程序中查看实名卡信息"
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-width",
                      attrs: { label: "附件", prop: "name" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.params.name,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "name", $$v)
                          },
                          expression: "params.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-width",
                      attrs: { label: "备注", prop: "remark" }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", autosize: { minRows: 3 } },
                        model: {
                          value: _vm.params.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "remark", $$v)
                          },
                          expression: "params.remark"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "actions" },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("确认")
                      ]),
                      _vm._v(" "),
                      _c("el-button", [_vm._v("取消")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }