"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _layout = _interopRequireDefault(require("@/layout"));

var _router = _interopRequireDefault(require("@/views/finance/no-transaction-flow/router"));

/** When your routing table is too long, you can split it into small modules **/
var financeRouter = {
  path: '/finance',
  component: _layout.default,
  redirect: '/finance/invoice/manageArticle',
  hidden: true,
  name: 'Finance',
  meta: {
    title: '财务',
    icon: 'nested'
  },
  children: [{
    path: 'invoice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/invoice'));
      });
    },
    name: 'Invoice',
    redirect: '/finance/invoice/invoiceManage',
    meta: {
      title: '电子发票'
    },
    children: [{
      path: 'invoiceManage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/finance/invoice/invoiceManage'));
        });
      },
      name: 'InvoiceManage',
      meta: {
        title: '发票管理',
        keepAlive: true
      }
    }, {
      path: 'openInvoice',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/finance/invoice/openInvoice'));
        });
      },
      name: 'OpenInvoice',
      meta: {
        title: '开具发票'
      }
    }, {
      path: 'taxRateList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/finance/taxRate/list'));
        });
      },
      name: 'taxRateList',
      meta: {
        title: '税率管理',
        keepAlive: true,
        icon: 'el-icon-s-cooperation',
        pageList: 'items',
        pageTotal: 'totalNum',
        addTxt: '新增税率',
        addLimits: 'taxRate:add',
        addUrl: '/finance/invoice/taxRateAdd',
        api: '/api/taxRate/listTaxRatePage',
        columns: [{
          label: '税率编码',
          prop: 'spbm',
          width: 80
        }, {
          label: '税率',
          prop: 'sl',
          width: 80
        }, {
          slot: 'update'
        }]
      }
    }, {
      path: 'taxRateAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/finance/taxRate/add'));
        });
      },
      name: 'taxRateAdd',
      meta: {
        title: '税率编辑',
        activeMenu: '/finance/invoice/taxRateList'
      }
    }, {
      path: 'storeList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/finance/storeList/index'));
        });
      },
      name: 'storeList',
      meta: {
        title: '开票信息',
        keepAlive: true
      }
    }, {
      path: 'storeListAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/finance/storeList/add'));
        });
      },
      name: 'storeListAdd',
      meta: {
        title: '编辑开票信息'
      }
    }, // 蜻蜓收银机小票
    {
      path: 'qingting',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/finance/qingting/index'));
        });
      },
      name: 'qingtingList',
      meta: {
        title: '蜻蜓收银机小票',
        keepAlive: true
      }
    }, {
      path: 'qingting/create',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/finance/qingting/form'));
        });
      },
      name: 'qingtingCreate',
      meta: {
        title: '添加蜻蜓收银机小票格式',
        activeMenu: '/finance/invoice/qingting'
      }
    }, {
      path: 'qingting/edit/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/finance/qingting/form'));
        });
      },
      name: 'qingtingEdit',
      meta: {
        title: '编辑蜻蜓收银机小票格式',
        activeMenu: '/finance/invoice/qingting'
      }
    }, {
      path: 'qingting/detail/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/finance/qingting/form'));
        });
      },
      name: 'qingtingDetail',
      meta: {
        title: '蜻蜓收银机小票格式详情',
        activeMenu: '/finance/invoice/qingting'
      }
    }]
  }, {
    path: 'point',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/point'));
      });
    },
    name: 'point',
    meta: {
      title: '小票打印'
    }
  }, // 自助积分
  {
    path: 'receiptUpload',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/receiptUpload'));
      });
    },
    name: 'receiptUpload',
    meta: {
      title: '自助积分记录',
      keepAlive: true
    }
  }, {
    path: 'receiptUpload/detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/receiptUpload/detail'));
      });
    },
    name: 'receiptUploadDea',
    meta: {
      title: '自助积分记录详情',
      activeMenu: '/finance/receiptUpload'
    }
  }, {
    path: 'receiptUpload/apply',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/receiptUpload/apply'));
      });
    },
    name: 'receiptApply',
    meta: {
      title: '自助积分申请',
      activeMenu: '/finance/receiptUpload'
    }
  }, // 自助积分配置
  {
    path: 'receiptSetting',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/receiptSetting'));
      });
    },
    name: 'receiptSetting',
    meta: {
      title: '自助积分配置'
    }
  }, {
    path: 'receiptSetting/save',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/receiptSetting/detail'));
      });
    },
    name: 'receiptSettingAdd',
    meta: {
      title: '新增/编辑自助积分配置',
      activeMenu: '/finance/receiptSetting'
    }
  }, {
    path: 'receiptSetting/detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/receiptSetting/detail'));
      });
    },
    name: 'receiptSettingDetail',
    meta: {
      title: '自助积分配置详情',
      activeMenu: '/finance/receiptSetting'
    }
  }, {
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/goods'));
      });
    },
    name: 'financeGoods',
    meta: {
      title: '开票商品管理',
      keepAlive: true,
      api: '/api/invoice/limit/page',
      pageList: 'records',
      addTxt: '添加开票商品',
      addLimits: 'finance:goods:add',
      addUrl: '/finance/goods/add'
    }
  }, {
    path: 'goods/add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/goods/add'));
      });
    },
    name: 'financeGoodsAdd',
    meta: {
      title: '添加开票商品',
      activeMenu: '/finance/goods'
    }
  }].concat((0, _toConsumableArray2.default)(_router.default))
};
var _default = financeRouter;
exports.default = _default;