"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _api = require("./api.js");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    var _self = this;

    return {
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/ymgz.xlsx"),
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.startTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      rules: {
        storeActivityAmountConfigs: [{
          required: true,
          message: '请添加门店金额'
        }],
        amountStage: [{
          required: true,
          message: '请添加阶梯'
        }],
        store12003: [{
          required: true,
          message: '请输入荔星A余额'
        }],
        store12001: [{
          required: true,
          message: '请输入荔星余额'
        }],
        totalAmount: [{
          required: true,
          message: '请输入总余额'
        }, {
          validator: function validator(rule, value, callback) {
            console.log(_self.ruleForm.store12001 + _self.ruleForm.store12002 + _self.ruleForm.store12003);

            if (value < _self.ruleForm.store12001 + _self.ruleForm.store12002 + _self.ruleForm.store12003) {
              callback(new Error('总金额不能小于门店总金额'));
            } else {
              callback();
            }
          }
        }],
        startTime: [{
          required: true,
          message: '请选择开始时间'
        }],
        endTime: [{
          required: true,
          message: '请选择结束时间'
        }]
      },
      ruleForm: {
        plan: 'A',
        totalAmount: null,
        storeActivityAmountConfigs: [],
        amountStage: [],
        startTime: null,
        endTime: null,
        productCodes: []
      },
      discountOrders: []
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.getYmgzActivityConfig)().then(function (res) {
      console.log(res);
      _this.discountOrders = res.data.discountOrders;
      _this.ruleForm.store12001 = Number(res.data.store12001);
      _this.ruleForm.store12002 = Number(res.data.store12002);
      _this.ruleForm.store12003 = Number(res.data.store12003);
      _this.ruleForm.startTime = res.data.startTime;
      _this.ruleForm.endTime = res.data.endTime;
      _this.ruleForm.totalAmount = res.data.totalActivityAmount;
      _this.ruleForm.productCodes = res.data.productCodes ? JSON.parse(res.data.productCodes) : [];
      _this.ruleForm.amountStage = res.data.amountStage ? JSON.parse(res.data.amountStage) : [];
      _this.ruleForm.storeActivityAmountConfigs = res.data.storeActivityAmountConfigs ? JSON.parse(res.data.storeActivityAmountConfigs) : [];
    });
  },
  methods: {
    delTime: function delTime(index, rowIndex) {
      this.ruleForm.amountStage[index].discountAmountLimits.splice(rowIndex, 1);
    },
    addTime: function addTime(index) {
      this.ruleForm.amountStage[index].discountAmountLimits.push({
        limitDay: null,
        limitTimes: null
      });
    },
    delStage: function delStage(index) {
      this.ruleForm.amountStage.splice(index, 1);
    },
    addStage: function addStage() {
      this.ruleForm.amountStage.push({
        amount: null,
        discountAmount: null,
        discountAmountLimits: [{
          limitDay: null,
          limitTimes: null
        }]
      });
    },
    getRelevances: function getRelevances() {
      this.ruleForm.storeActivityAmountConfigs.push({
        storeCode: null,
        totalAmount: null
      });
    },
    delAward: function delAward(index) {
      this.ruleForm.storeActivityAmountConfigs.splice(index, 1);
    },
    handleDownload: function handleDownload() {
      var tHeader = ['goodsCode'];
      var filterVal = ['goodsCode'];
      var list = [];
      this.ruleForm.productCodes.forEach(function (item) {
        list.push({
          goodsCode: item
        });
      });
      this.getHandleDownload(tHeader, filterVal, list, '不可用商品码');
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      results.forEach(function (data) {
        console.log(data);

        if (data.goodsCode) {
          arr.push(String(data.goodsCode).replace(/[^\d]/g, ''));
        }
      });
      this.ruleForm.productCodes = arr;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.ruleForm));
          (0, _api.ymgzActivityConfig)(data).then(function (res) {
            _this2.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;