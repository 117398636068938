var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "group-card-from" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(_vm.$route.meta.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { model: _vm.params, "label-width": "180px" }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "input-width", attrs: { label: "卡种名称" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "卡类型" } },
            [
              _c("el-button", { attrs: { type: "primary", size: "small" } }, [
                _vm._v("选择")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "卡片图片" } }, [
            _c(
              "div",
              { staticClass: "card-image-upload" },
              [
                _c("div", { staticClass: "input-tip" }, [_vm._v("正面")]),
                _vm._v(" "),
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      action: "https://jsonplaceholder.typicode.com/posts/",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess,
                      "before-upload": _vm.beforeAvatarUpload
                    }
                  },
                  [
                    _vm.imageUrl
                      ? _c("img", {
                          staticClass: "avatar",
                          attrs: { src: _vm.imageUrl }
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon"
                        })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-image-upload" },
              [
                _c("div", { staticClass: "input-tip" }, [_vm._v("背面")]),
                _vm._v(" "),
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      action: "https://jsonplaceholder.typicode.com/posts/",
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess,
                      "before-upload": _vm.beforeAvatarUpload
                    }
                  },
                  [
                    _vm.imageUrl
                      ? _c("img", {
                          staticClass: "avatar",
                          attrs: { src: _vm.imageUrl }
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon"
                        })
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "卡片面额" } }, [
            _c(
              "div",
              { staticClass: "denomination" },
              [
                _vm._l(_vm.denomination, function(de, dIndex) {
                  return _c(
                    "el-tag",
                    {
                      key: dIndex,
                      attrs: { size: "medium", type: "info", effect: "plain" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(de === 0 ? "无面额" : de + "元") +
                          "\n                "
                      )
                    ]
                  )
                }),
                _vm._v(" "),
                _c("el-input-number", {
                  staticStyle: { width: "150px" },
                  attrs: {
                    min: 10,
                    max: 1000,
                    label: "10-1000",
                    placeholder: "10-1000",
                    controls: false
                  },
                  model: {
                    value: _vm.num,
                    callback: function($$v) {
                      _vm.num = $$v
                    },
                    expression: "num"
                  }
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "激活后有效期" } },
            [
              _c("el-input-number", {
                attrs: { min: 1, max: 100, controls: false },
                model: {
                  value: _vm.num,
                  callback: function($$v) {
                    _vm.num = $$v
                  },
                  expression: "num"
                }
              }),
              _vm._v(" 年\n            "),
              _c("p", { staticClass: "input-tip" }, [
                _vm._v("仅限实体卡，根据监管要求，不记名卡有效期不得少于3年")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "可绑定至电子卡" } },
            [_c("el-select")],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "限用范围" } },
            [_c("el-select")],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "input-width", attrs: { label: "备注" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", autosize: { minRows: 5 } },
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "actions" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }