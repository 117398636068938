"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _member = require("@/api/member.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    WTable: _index.default
  },
  data: function data() {
    return {
      vipList: [],
      classList: [],
      type: [{
        label: '实体商品',
        value: 1
      }, {
        label: '直冲商品',
        value: 2
      }, {
        label: '优惠券',
        value: 3
      }],
      downloadLoading: false,
      requestData: {
        productType: '',
        productCode: '',
        vipCode: '',
        classId: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [
      /* {
        slot: 'productType'
      }, */
      {
        label: '商品名称',
        prop: 'productName',
        width: 100
      }, {
        label: '商品编码',
        prop: 'productCode',
        width: 100
      },
      /*
       */
      {
        label: '同城分类',
        prop: 'className',
        width: 100
      }, {
        slot: 'putType'
      }, {
        label: '投放数量',
        prop: 'putNumber',
        width: 50
      }, {
        label: '奖品排序',
        prop: 'productSort',
        width: 50
      }, {
        label: '会员类型编码',
        prop: 'vipCode',
        width: 80
      },
      /* { slot: 'putStatus',
        label: '状态',
        prop: 'putStatus',
        width: 80
      }, */
      {
        slot: 'operate'
      }]
    };
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {
    var _this = this;

    (0, _api.listCitylifeClass)({}).then(function (res) {
      _this.classList = res.data;
    });
    (0, _member.listVip)().then(function (res) {
      _this.vipList = res.data;
    });
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];
        var data = {};

        _this2.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this2.requestData).forEach(function (key) {
          if (_this2.requestData[key]) {
            data[key] = _this2.requestData[key];
          }
        });
        data.pageSize = _this2.requestData.total;
        data.pageNo = 1;
        (0, _api.listCitylifePage)(data).then(function (res) {
          list = res.data.items;

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '同城生活商品'
          });
          _this2.downloadLoading = false;
        }).catch(function (res) {
          _this2.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.removeObj(this.requestData);
      }

      (0, _api.listCitylifePage)(this.requestData).then(function (res) {
        _this3.tableData = res.data.items;
        _this3.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;