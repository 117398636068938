"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.search");

require("core-js/modules/web.dom.iterable");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _index = require("./index.js");

var _user = require("@/api/user.js");

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ListStoreList',
  components: {
    Pagination: _Pagination.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      searchData: [{
        type: 'input',
        placeholder: '门店全称',
        value: 'fullName'
      }, {
        type: 'input',
        placeholder: '门店编码',
        value: 'storeCode'
      }, {
        type: 'radio',
        placeholder: '状态',
        value: 'merchantStatus',
        select: [{
          label: '开业准备',
          value: 1
        }, {
          label: '正常营业',
          value: 2
        }, {
          label: '暂停营业',
          value: 3
        }, {
          label: '作废',
          value: 4
        }]
      }],
      companylist: [],
      list: [],
      total: 0,
      tabTh: [
      /* {
         label: 'ID',
         prop: 'storeId',
         align: 'center',
         width: 1
       }, */
      {
        label: '门店全称',
        prop: 'fullName',
        align: 'center',
        width: 5
      }, {
        label: '门店简称',
        prop: 'shortName',
        align: 'center',
        width: 4
      },
      /*  {
            label: '门店ID',
            prop: 'storeId',
            align: 'center',
            width: 2
          }, */
      {
        label: '门店编码',
        prop: 'storeCode',
        align: 'center',
        width: 2
      },
      /* {
            label: '商户数量',
            prop: 'fullName',
            align: 'center',
            width: 200
          }, */
      {
        slot: "longOrLat",
        align: 'center',
        width: 4
      },
      /*
       item.longitude + ',' + item.latitude
      */
      {
        slot: "longitude",
        label: '经度',
        prop: 'longitude',
        width: 4
      }, {
        slot: "latitude",
        align: 'center',
        label: '维度',
        prop: 'latitude',
        width: 4
      }, {
        slot: "storeStatus",
        label: '状态',
        prop: 'storeStatus',
        align: 'center',
        width: 2
      }, {
        slot: 'caozuo'
      }],
      topObj: {
        fullName: '',
        shortName: ''
      },
      storeStatusList: [{
        label: '开业准备',
        value: 1
      }, {
        label: '正常营业',
        value: 2
      }, {
        label: '暂停营业',
        value: 3
      }, {
        label: '作废',
        value: 4
      }],
      searchObj: {
        pageNo: 1,
        pageSize: 10,
        companyId: '',
        storeStatus: '',
        storeCode: '',
        fullName: ''
      },
      popShow: false,
      qrCode: '',
      couponUUid: '',
      url: ''
    };
  },
  created: function created() {
    var _this = this;

    (0, _index.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companylist = res.data;
      var companyList = res.data.map(function (item) {
        return {
          label: item.shortName,
          value: item.companyId
        };
      });

      _this.searchData.unshift({
        type: 'radio',
        placeholder: '所属总部',
        value: 'companyId',
        select: companyList
      });
    }); // this.searchObj.companyId = this.$route.query.companyId

    /* getCompanyById(this.searchObj.companyId).then((res) => {
         this.topObj.fullName = res.data.fullName
         this.topObj.shortName = res.data.shortName
       }) */

    /* if (this.$store.state.buffer.storeList.pageNo) {
         const initInfo = this.$store.state.buffer.storeList
         this.searchObj.pageNo = initInfo.pageNo
         this.searchObj.pageSize = initInfo.pageSize
         this.searchObj.companyId = initInfo.companyId
         this.searchObj.storeStatus = initInfo.storeStatus
         this.searchObj.storeCode = initInfo.storeCode
         this.searchObj.storeId = initInfo.storeId
         this.searchObj.fullName = initInfo.fullName
       } */
  },
  activated: function activated() {
    this.init();
  },
  destroyed: function destroyed() {// const initInfo = this.searchObj
    // this.$store.dispatch('buffer/setStoreList', initInfo)
    // sessionStorage.setItem('getInitInfo', JSON.stringify(initInfo));
  },
  methods: {
    closePop: function closePop() {
      this.popShow = false;
    },
    getCode: function getCode(scope, special) {
      var _this2 = this;

      // 获取小程序码
      this.couponUUid = scope.shortName;
      var scene = "share_id=".concat(scope.storeCode, "&type=1");
      (0, _user.couponQRCode)({
        path: 'pages/index/index',
        // special === 'special' ? 'vip/zengKa/zengKa' : 'vip/index',
        scene: scene
      }).then(function (res) {
        _this2.url = 'pages/index/index?' + scene;

        _this2.openLoading().close();

        _this2.qrCode = res.data;
        _this2.popShow = true;
      });
    },
    addStore: function addStore() {
      this.$router.push('/system/manage/addStore');
    },
    setShort: function setShort(e) {
      var _this3 = this;

      this.companylist.forEach(function (item) {
        if (item.companyId == e) {
          _this3.searchObj.companyId = e;
          _this3.topObj.fullName = item.fullName;
          _this3.topObj.shortName = item.shortName;

          _this3.search();
        }
      });
    },
    remove: function remove() {
      this.searchObj.pageNo = 1;
      this.searchObj.pageSize = 10;
      this.searchObj.storeStatus = '';
      this.searchObj.storeCode = '';
      this.searchObj.companyId = '', this.searchObj.fullName = '';
      this.init();
    },
    search: function search() {
      this.searchObj.pageNo = 1;
      this.searchObj.pageSize = 10;
      this.init();
    },
    init: function init(e) {
      var _this4 = this;

      console.log(e);

      if (e) {
        this.searchObj.pageNo = e.page;
        this.searchObj.pageSize = e.limit;
      }

      (0, _index.listStorePage)(this.searchObj).then(function (res) {
        _this4.list = res.data.items;
        _this4.total = res.data.totalNum;

        _this4.list.forEach(function (item) {
          if (item.longitude && item.latitude) {
            item.longOrLat = item.longitude + ',' + item.latitude;
          }

          switch (item.storeStatus) {
            case 1:
              item.storeStatus = '开业准备';
              break;

            case 2:
              item.storeStatus = '正常营业';
              break;

            case 3:
              item.storeStatus = '暂停营业';
              break;

            case 4:
              item.storeStatus = '作废';
              break;

            default:
              break;
          }
        });
      });
    },
    setTab: function setTab(index) {
      console.log(index);
    },
    goEdit: function goEdit(row) {
      this.$router.push('/system/manage/addStore?storeId=' + row.storeId);
    }
  }
};
exports.default = _default;