"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        slot: 'brandLogo',
        label: 'logo',
        prop: 'brandLogo'
      }, {
        label: '品牌名称',
        prop: 'brandName',
        width: 100
      }, {
        label: '首字母',
        prop: 'brandFirstLetter',
        width: 50
      }, {
        label: '排序',
        prop: 'brandSort',
        width: 50
      }, {
        label: '是否推荐(0:否，1：是)',
        prop: 'brandRecommend',
        slot: 'brandRecommend',
        width: 80
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 100
      }, {
        label: '更新时间',
        prop: 'updateTime',
        width: 100
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'radio',
        span: 4,
        placeholder: '是否推荐品牌',
        value: 'brandRecommend',
        select: [{
          label: '是',
          value: 1
        }, {
          label: '否',
          value: 0
        }]
      }, {
        type: 'input',
        span: 4,
        placeholder: '品牌名称',
        value: 'brandName'
      }, {
        type: 'input',
        span: 4,
        placeholder: '品牌首字母',
        value: 'brandFirstLetter'
      }],
      isUpdate: false,
      formData: [{
        label: '品牌名称：',
        type: 'input',
        placeholder: '请输入品牌名称',
        value: 'brandName',
        max: 30
      }, {
        label: '排序：',
        type: 'inputNumber',
        placeholder: '请输入排序',
        value: 'brandSort',
        max: 255,
        remark: "数字越大越靠前,最大255"
      }, {
        label: '是否推荐：',
        type: 'radio',
        placeholder: '请选择是否推荐',
        value: 'brandRecommend',
        selectList: [{
          value: 1,
          label: "是"
        }, {
          value: 0,
          label: "否"
        }]
      }, {
        label: '品牌logo：',
        type: 'img',
        placeholder: '请上传品牌logo100*100',
        value: 'brandLogo'
      }],
      ruleForm: {
        brandLogo: undefined,
        brandRecommend: null,
        brandSort: undefined,
        brandName: null,
        firstCategoryIdList: []
      }
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost("/api/mall/goods/category/first").then(function (res) {
      var slectList = [];
      res.data.forEach(function (item) {
        slectList.push({
          label: item.gcName,
          value: item.id
        });
      });

      _this.formData.splice(2, 0, {
        label: '关联分类：',
        type: 'check',
        placeholder: '请选择关联分类',
        value: 'firstCategoryIdList',
        selectList: slectList
      });
    });
  },
  methods: {
    goUpDateItem: function goUpDateItem(row) {
      var _this2 = this;

      this.apiPost('/api/mall/goods/brand/detail', {
        id: row.id
      }).then(function (res) {
        var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, res.data.brand), {}, {
          firstCategoryIdList: res.data.firstCategoryIdList
        });
        _this2.ruleForm = data;
        _this2.isUpdate = true;
      });
    },
    changeRecommend: function changeRecommend(e, row) {
      var _this3 = this;

      this.apiPost('/api/mall/goods/brand/recommend', {
        id: row.id,
        brandRecommend: e
      }).then(function (res) {
        _this3.$refs.wTable.getList();
      }).catch(function (err) {
        _this3.$refs.wTable.getList();
      });
    },
    submitForm: function submitForm() {
      var _this4 = this;

      var apiUrl = this.ruleForm.id ? '/api/mall/goods/brand/update' : '/api/mall/goods/brand/create';
      this.apiPost(apiUrl, this.ruleForm).then(function (res) {
        _this4.$refs.wTable.getList();

        _this4.isUpdate = false;
      });
    }
  }
};
exports.default = _default;