"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  depositList: {},
  // 线上储值卡列表
  memberReport: {},
  // 会员统计列表
  couponReport: {},
  // 优惠券统计列表
  couponList: {},
  // 优惠券列表
  couponGiftList: {},
  // 优惠券包列表
  couponRecordList: {},
  // 优惠券核销记录
  orientPutList: {},
  // 定向投放列表
  touchPutList: {},
  // 触发投放列表
  volumeCenter: {},
  // 领券中心列表
  promotionList: {},
  // 促销类活动列表
  orientLabelList: {},
  // 定向投放标签列表
  manageArticleList: {},
  // 文章列表
  merberList: {},
  // 会员体系列表
  roleManageList: {},
  // 角色管理列表
  userManageList: {},
  // 用户管理列表
  versionsList: {},
  // 终端设备版本号列表
  activityList: {},
  // 页面栏目管理列表
  faceTofaceList: {},
  // 面对面发券列表
  invoiceManageList: {},
  // 电子发票列表
  vendorList: {},
  // 商户列表
  storeList: {},
  // 门店列表
  goodsList: {},
  // 商品列表
  orderList: {},
  // 订单列表
  presentList: {},
  // 礼品馈赠列表
  voteId: {}
};
var mutations = {
  SET_VOTE_ID: function SET_VOTE_ID(state, data) {
    state.voteId = data;
  },
  SET_PRESENT_LIST: function SET_PRESENT_LIST(state, data) {
    state.presentList = data;
  },
  SET_ORDER_LIST: function SET_ORDER_LIST(state, data) {
    state.orderList = data;
  },
  SET_GOODS_LIST: function SET_GOODS_LIST(state, data) {
    state.goodsList = data;
  },
  SET_STORE_LIST: function SET_STORE_LIST(state, data) {
    state.storeList = data;
  },
  SET_VENDOR_LIST: function SET_VENDOR_LIST(state, data) {
    state.vendorList = data;
  },
  SET_INVOICE_MANAGE_LIST: function SET_INVOICE_MANAGE_LIST(state, data) {
    state.invoiceManageList = data;
  },
  SET_ACTIVITY_LIST: function SET_ACTIVITY_LIST(state, data) {
    state.activityList = data;
  },
  SET_FACE_TO_FACE_LIST: function SET_FACE_TO_FACE_LIST(state, data) {
    state.faceTofaceList = data;
  },
  SET_VERSIONS_LIST: function SET_VERSIONS_LIST(state, data) {
    state.versionsList = data;
  },
  SET_COUPON_RECORD_LIST: function SET_COUPON_RECORD_LIST(state, data) {
    state.couponRecordList = data;
  },
  SET_ORIENT_PUT_LIST: function SET_ORIENT_PUT_LIST(state, data) {
    state.orientPutList = data;
  },
  SET_TOUCH_PUT_LIST: function SET_TOUCH_PUT_LIST(state, data) {
    state.touchPutList = data;
  },
  SET_VOLUME_CENTER: function SET_VOLUME_CENTER(state, data) {
    state.volumeCenter = data;
  },
  SET_PROMOTION_LIST: function SET_PROMOTION_LIST(state, data) {
    state.promotionList = data;
  },
  SET_ORIENTLABEL_LIST: function SET_ORIENTLABEL_LIST(state, data) {
    state.orientLabelList = data;
  },
  SET_MANAGE_ARTICLE_LIST: function SET_MANAGE_ARTICLE_LIST(state, data) {
    state.manageArticleList = data;
  },
  SET_MERBER_LIST: function SET_MERBER_LIST(state, data) {
    state.merberList = data;
  },
  SET_ROLE_MANAGE_LIST: function SET_ROLE_MANAGE_LIST(state, data) {
    state.roleManageList = data;
  },
  SET_USER_MANAGE_LIST: function SET_USER_MANAGE_LIST(state, data) {
    state.userManageList = data;
  },
  SET_DEPOSIT_LIST: function SET_DEPOSIT_LIST(state, data) {
    state.depositList = data;
  },
  SET_MEMBER_REPORT: function SET_MEMBER_REPORT(state, data) {
    state.memberReport = data;
  },
  SET_COUPON_REPORT: function SET_COUPON_REPORT(state, data) {
    state.couponReport = data;
  },
  SET_COUPON_LIST: function SET_COUPON_LIST(state, data) {
    state.couponList = data;
  },
  SET_COUPON_GIFT_LIST: function SET_COUPON_GIFT_LIST(state, data) {
    state.couponGiftList = data;
  }
};
var actions = {
  setVoteId: function setVoteId(_ref, data) {
    var commit = _ref.commit;
    commit('SET_VOTE_ID', data);
  },
  setPresentList: function setPresentList(_ref2, data) {
    var commit = _ref2.commit;
    commit('SET_PRESENT_LIST', data);
  },
  setOrderList: function setOrderList(_ref3, data) {
    var commit = _ref3.commit;
    commit('SET_ORDER_LIST', data);
  },
  setGoodsList: function setGoodsList(_ref4, data) {
    var commit = _ref4.commit;
    commit('SET_GOODS_LIST', data);
  },
  setStoreList: function setStoreList(_ref5, data) {
    var commit = _ref5.commit;
    commit('SET_STORE_LIST', data);
  },
  setVendorList: function setVendorList(_ref6, data) {
    var commit = _ref6.commit;
    commit('SET_VENDOR_LIST', data);
  },
  setInvoiceManageList: function setInvoiceManageList(_ref7, data) {
    var commit = _ref7.commit;
    commit('SET_INVOICE_MANAGE_LIST', data);
  },
  setFaceTofaceList: function setFaceTofaceList(_ref8, data) {
    var commit = _ref8.commit;
    commit('SET_FACE_TO_FACE_LIST', data);
  },
  setActivityList: function setActivityList(_ref9, data) {
    var commit = _ref9.commit;
    commit('SET_ACTIVITY_LIST', data);
  },
  setDepositList: function setDepositList(_ref10, data) {
    var commit = _ref10.commit;
    commit('SET_DEPOSIT_LIST', data);
  },
  setMemberReport: function setMemberReport(_ref11, data) {
    var commit = _ref11.commit;
    commit('SET_MEMBER_REPORT', data);
  },
  setCouponReport: function setCouponReport(_ref12, data) {
    var commit = _ref12.commit;
    commit('SET_COUPON_REPORT', data);
  },
  setCouponList: function setCouponList(_ref13, data) {
    var commit = _ref13.commit;
    commit('SET_COUPON_LIST', data);
  },
  setCouponGiftList: function setCouponGiftList(_ref14, data) {
    var commit = _ref14.commit;
    commit('SET_COUPON_GIFT_LIST', data);
  },
  setCouponRecordList: function setCouponRecordList(_ref15, data) {
    var commit = _ref15.commit;
    commit('SET_COUPON_RECORD_LIST', data);
  },
  setOrientPutList: function setOrientPutList(_ref16, data) {
    var commit = _ref16.commit;
    commit('SET_ORIENT_PUT_LIST', data);
  },
  setTouchPutList: function setTouchPutList(_ref17, data) {
    var commit = _ref17.commit;
    commit('SET_TOUCH_PUT_LIST', data);
  },
  setVolumeCenter: function setVolumeCenter(_ref18, data) {
    var commit = _ref18.commit;
    commit('SET_VOLUME_CENTER', data);
  },
  setPromotionList: function setPromotionList(_ref19, data) {
    var commit = _ref19.commit;
    commit('SET_PROMOTION_LIST', data);
  },
  setOrientLabelList: function setOrientLabelList(_ref20, data) {
    var commit = _ref20.commit;
    commit('SET_ORIENTLABEL_LIST', data);
  },
  setManageArticleList: function setManageArticleList(_ref21, data) {
    var commit = _ref21.commit;
    commit('SET_MANAGE_ARTICLE_LIST', data);
  },
  setMerberList: function setMerberList(_ref22, data) {
    var commit = _ref22.commit;
    commit('SET_MERBER_LIST', data);
  },
  setRoleManageList: function setRoleManageList(_ref23, data) {
    var commit = _ref23.commit;
    commit('SET_ROLE_MANAGE_LIST', data);
  },
  setUserManageList: function setUserManageList(_ref24, data) {
    var commit = _ref24.commit;
    commit('SET_USER_MANAGE_LIST', data);
  },
  setVersionsList: function setVersionsList(_ref25, data) {
    var commit = _ref25.commit;
    commit('SET_VERSIONS_LIST', data);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;