"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _layout = _interopRequireDefault(require("@/layout"));

var _router = _interopRequireDefault(require("@/views/parking/jianghua/router"));

/** When your routing table is too long, you can split it into small modules **/
var parkingRouter = {
  path: '/parking',
  component: _layout.default,
  name: 'Parking',
  meta: {
    title: '停车场管理',
    icon: 'nested'
  },
  children: [].concat((0, _toConsumableArray2.default)(_router.default), [{
    path: 'parking',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/parking/index.vue'));
      });
    },
    name: 'ParkingManage',
    meta: {
      title: '停车场管理'
    },
    redirect: '/parking/parking/tab',
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/parking/parking/tab.vue'));
        });
      },
      name: 'ParkingTab',
      meta: {
        title: '停车场管理'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/parking/parking/list/index.vue'));
        });
      },
      name: 'ParkingList',
      meta: {
        title: '停车场列表',
        keepAlive: true
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/parking/parking/list/form.vue'));
        });
      },
      name: 'ParkingeAdd',
      meta: {
        title: '新建停车场'
      }
    }, {
      path: 'edit/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/parking/parking/list/form.vue'));
        });
      },
      name: 'ParkingEdit',
      meta: {
        title: '编辑停车场'
      }
    }, {
      path: 'detail/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/parking/parking/log/detail.vue'));
        });
      },
      name: 'ParkingDetail',
      meta: {
        title: '记录详情'
      }
    }, {
      path: 'log',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/parking/parking/log/list.vue'));
        });
      },
      name: 'ParkingLog',
      meta: {
        title: '停车记录'
      }
    }]
  }])
};
var _default = parkingRouter;
exports.default = _default;