"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '会员管理',
      list: [{
        tit: '会员查询',
        txtArr: '<p>会员信息查询。</p><p>卡，券，账单，乐享值，积分查询</p>',
        imgPath: require('@/assets/tab/8.png'),
        path: '/report/memberQuery'
      }, {
        tit: '新增会员',
        txtArr: '会员列表，新增会员',
        imgPath: require('@/assets/tab/31.png'),
        path: '/member/addList'
      }, {
        tit: '会员切换记录',
        txtArr: '会员修改记录',
        imgPath: require('@/assets/tab/5.png'),
        path: '/member/memberChange'
      }, {
        tit: '会员注销记录',
        txtArr: '会员注销记录',
        imgPath: require('@/assets/tab/30.png'),
        path: '/member/memberCancel'
      }, {
        tit: '会员修改',
        txtArr: '修改会员基本信息，状态，大客户。',
        imgPath: require('@/assets/tab/32.png'),
        path: '/report/memberEdit'
      }]
    };
  }
};
exports.default = _default;