"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _slide = _interopRequireDefault(require("@/components/slide.vue"));

var _index2 = require("@/utils/index.js");

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default,
    slide: _slide.default
  },
  data: function data() {
    return {
      positions: [{
        label: '总裁',
        value: 10
      }, {
        label: '总经理级',
        value: 1
      }, {
        label: '总监级',
        value: 2
      }, {
        label: '经理级',
        value: 3
      }, {
        label: '课长级',
        value: 4
      }, {
        label: '主管级',
        value: 5
      }, {
        label: '员工级',
        value: 6
      }, {
        label: '百货(12001、12002、12003)',
        value: 7
      }, {
        label: '购物中心(12010、12009、12004、12007)',
        value: 8
      }, {
        label: '银行',
        value: 9
      }],
      time: '',
      isCard: false,
      downloadLoading: false,
      requestData: {
        position: null,
        mobile: null,
        username: null,
        pageNo: 1,
        pageSize: 10,
        total: 0,
        endTime: null,
        complementStatus: null
      },
      tableData: [],
      kpiData: {},
      columns: [
      /* {"pageNo":"当前页","pageSize":"页条数","mobile":"推广人手机号","username":"推广人手姓名","startTime":"开始时间","endTime":"结束时间"}*/
      {
        label: '推广人手机号',
        prop: 'mobile',
        width: 10
      }, {
        label: '推广人手姓名',
        prop: 'username',
        width: 10
      }, {
        label: '职务',
        prop: 'positionStr',
        width: 10
      }, {
        label: '推广人指标',
        prop: 'kpiNumber',
        width: 10
      }, {
        label: '推广人完成数量',
        prop: 'completeNumber',
        width: 10
      }, {
        label: '状态',
        prop: 'complementStatusDescription',
        width: 10
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(e) {
      if (e) {
        this.requestData.startTime = e[0];
        this.requestData.endTime = e[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {
    this.requestData.endTime = (0, _index2.getPreMonth)((0, _index2.parseTime)(new Date()), 'month');
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    timeChange: function timeChange(e) {
      console.log(e);
    },
    goUserKpi: function goUserKpi() {
      var _self = this;

      (0, _api.userKpiReward)({
        uid: _self.kpiData.userId,
        mobile: _self.kpiData.mobile,
        startTime: _self.requestData.startTime,
        endTime: _self.requestData.endTime
      }).then(function (res) {
        _self.getList();

        _self.$message({
          type: 'success',
          message: '发放成功!'
        });
      });
    },
    goUserKpiReward: function goUserKpiReward(row) {
      this.kpiData = row;
      this.goUserKpi(); // this.isCard = true
    },
    handleDownload: function handleDownload() {
      var _this = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];
        var data = {};

        _this.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this.requestData).forEach(function (key) {
          if (_this.requestData[key]) {
            data[key] = _this.requestData[key];
          }
        });
        data.pageSize = _this.requestData.total;
        data.pageNo = 1;
        (0, _api.listSysUserKpiPage)(data).then(function (res) {
          list = res.data.items;
          /* list.forEach((item) => {
            item.kpiStatus = item.number >= item.kpiNumber ? '已完成' : '未完成'
          }) */

          var data = _this.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '推广指标统计'
          });
          _this.downloadLoading = false;
        }).catch(function (res) {
          _this.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this2 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = '';
        this.removeObj(this.requestData);
        this.requestData.endTime = (0, _index2.getPreMonth)((0, _index2.parseTime)(new Date()), 'month');
      }

      (0, _api.listSysUserKpiPage)(this.requestData).then(function (res) {
        _this2.tableData = res.data.items;
        _this2.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;