var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "receipt_detail" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(_vm.$route.meta.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            "label-width": "120px",
            disabled: _vm.isDetail
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "配置名称", prop: "name" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "输入配置名称" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择门店", prop: "storeId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "输入门店名称搜索",
                    "remote-method": _vm.remoteStoreList,
                    loading: _vm.storeloading
                  },
                  on: { change: _vm.storeChange },
                  model: {
                    value: _vm.form.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "storeId", $$v)
                    },
                    expression: "form.storeId"
                  }
                },
                _vm._l(_vm.storeOptions, function(so, sindex) {
                  return _c(
                    "el-option",
                    {
                      key: sindex,
                      attrs: {
                        value: so.storeCode,
                        label: so.fullName + "(" + so.storeCode + ")"
                      }
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(so.fullName))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#8492a6",
                            "font-size": "13px"
                          }
                        },
                        [_vm._v(_vm._s(so.storeCode))]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "合同号", prop: "contractNo" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { placeholder: "输入合同号" },
                  on: { input: _vm.chagneContractNo },
                  model: {
                    value: _vm.form.contractNo,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "contractNo",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.contractNo"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "append",
                        type: "primary",
                        icon: "el-icon-search"
                      },
                      on: { click: _vm.giSearchGoods },
                      slot: "append"
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择商品", prop: "goodsCode" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "输入商品名称/编码搜索"
                  },
                  model: {
                    value: _vm.form.goodsCode,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "goodsCode", $$v)
                    },
                    expression: "form.goodsCode"
                  }
                },
                _vm._l(_vm.goodsList, function(so, sindex) {
                  return _c("el-option", {
                    key: sindex,
                    attrs: {
                      value: so.c_gcode,
                      label: so.c_gname + "(" + so.c_gcode + ")"
                    }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _vm.goodsList.length == 0
                ? _c("p", { staticClass: "form_remark" }, [
                    _vm._v("输入合同号后，查询商品码")
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "品牌名称", prop: "brand" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "输入品牌" },
                model: {
                  value: _vm.form.brand,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "brand", $$v)
                  },
                  expression: "form.brand"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "获得积分", prop: "scale" } },
            [
              _c("el-input-number", {
                staticClass: "form_input",
                attrs: {
                  controls: false,
                  precision: 0,
                  placeholder: "输入获得积分"
                },
                model: {
                  value: _vm.form.scale,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "scale", $$v)
                  },
                  expression: "form.scale"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "checkbox",
              attrs: { label: "需要消费金额", prop: "checkboxkeys" }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.checkboxChange },
                  model: {
                    value: _vm.form.checkboxkeys,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "checkboxkeys", $$v)
                    },
                    expression: "form.checkboxkeys"
                  }
                },
                [
                  _c("div", { staticClass: "checkbox_item" }, [
                    _c(
                      "div",
                      { staticClass: "checkbox_item_box" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: {
                              label: 0,
                              disabled:
                                _vm.form.checkboxkeys.length > 0 &&
                                !_vm.form.checkboxkeys.includes(0)
                            }
                          },
                          [_vm._v("所有会员")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkbox_item_content" }, [
                      _c(
                        "div",
                        { staticClass: "checkbox_item_checked" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              controls: false,
                              disabled: !_vm.form.checkboxkeys.includes(0),
                              placeholder: ""
                            },
                            model: {
                              value: _vm.form.memberPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "memberPrice", $$v)
                              },
                              expression: "form.memberPrice"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "input-tip" }, [
                            _vm._v("元")
                          ])
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.memberTypeOps, function(mto, mtoIndex) {
                    return _c(
                      "div",
                      { key: mtoIndex, staticClass: "checkbox_item" },
                      [
                        _c(
                          "div",
                          { staticClass: "checkbox_item_box" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { label: mto.id, disabled: mto.disabled }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(mto.name) +
                                    "\n                        "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "checkbox_item_content" },
                          _vm._l(mto.checked, function(mck, mckIndex) {
                            return _c(
                              "div",
                              {
                                key: mckIndex,
                                staticClass: "checkbox_item_checked"
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled: !_vm.form.checkboxkeys.includes(
                                        mto.id
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.checkboxSelectChange(
                                          $event,
                                          mtoIndex,
                                          mckIndex
                                        )
                                      }
                                    },
                                    model: {
                                      value: mck.basicType,
                                      callback: function($$v) {
                                        _vm.$set(mck, "basicType", $$v)
                                      },
                                      expression: "mck.basicType"
                                    }
                                  },
                                  _vm._l(mto.childs, function(mcd, mcdIndex) {
                                    return _c("el-option", {
                                      key: mcdIndex,
                                      attrs: {
                                        label: mcd.name,
                                        value: mcd.id,
                                        disabled: mto.disabled
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-input-number", {
                                  attrs: {
                                    controls: false,
                                    disabled: !_vm.form.checkboxkeys.includes(
                                      mto.id
                                    )
                                  },
                                  model: {
                                    value: mck.costPrice,
                                    callback: function($$v) {
                                      _vm.$set(mck, "costPrice", $$v)
                                    },
                                    expression: "mck.costPrice"
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "input-tip" }, [
                                  _vm._v("元")
                                ]),
                                _vm._v(" "),
                                mckIndex === 0 && !_vm.isSelectAll(mto.checked)
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "textbtn",
                                        attrs: {
                                          icon: "el-icon-plus",
                                          type: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onAppendCheckbox(
                                              mtoIndex,
                                              mckIndex
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("添加一项")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                mckIndex !== 0
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "textbtn",
                                        staticStyle: { color: "red" },
                                        attrs: {
                                          icon: "el-icon-close",
                                          type: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onRemoveCheckbox(
                                              mtoIndex,
                                              mckIndex
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "input-tip",
                  staticStyle: { "margin-top": "-10px" }
                },
                [
                  _vm._v(
                    "*每个品牌只能添加一次积分规则，未配置的会员类型不参与积分。"
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "备注", prop: "remark" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 3 },
                  placeholder: "输入备注"
                },
                model: {
                  value: _vm.form.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_buttons" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }