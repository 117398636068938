"use strict";

var _interopRequireWildcard = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _rights = require("@/api/svip/rights.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var R = _interopRequireWildcard(require("ramda"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      searchParams: {
        pageNo: 1,
        pageSize: 10,
        rightCategoryName: '',
        itemCategoryName: '',
        svipName: '',
        date: null,
        mobile: ''
      },
      total: 0
    };
  },
  computed: {
    columns: function columns() {
      return [{
        label: '权益分类',
        prop: 'rightCategoryName'
      }, {
        label: '发放权益',
        prop: 'itemCategoryName'
      }, {
        label: '发放次数',
        prop: 'issueNum'
      }, {
        label: '发放要客',
        prop: 'svipName'
      }, {
        label: '手机号',
        prop: 'mobile'
      }, {
        label: '发放时间',
        prop: 'createTime'
      }];
    }
  },
  created: function created() {
    this.fetch();
  },
  methods: {
    fetch: function fetch(type) {
      var _this = this;

      if (type === 'search') {
        this.searchParams.pageNo = 1;
        this.searchParams.pageSize = 10;
      }

      if (type === 'reset') {
        this.removeObj(this.searchParams);
      }

      var date = this.searchParams.date;
      var params = R.omit(['date'], this.searchParams);

      if (date && date.length > 0) {
        params['createTimeStart'] = date[0];
        params['createTimeEnd'] = date[1];
      }

      (0, _rights.getPageRightsIssueRecordList)(params).then(function (res) {
        _this.total = res.data.totalNum;

        if (!_this.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.mobile) {
              item.mobile = item.mobile.replace(item.mobile.substring(3, 7), "****");
            }
          });
        }

        _this.list = res.data.items;
      });
    }
  }
};
exports.default = _default;