var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-manager-detail" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("荔星伙伴详情")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c("Descriptions", {
                attrs: {
                  title: "基本信息",
                  columns: _vm.columns,
                  "data-source": _vm.manager
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("Descriptions", { attrs: { title: "信息变更记录" } }),
              _vm._v(" "),
              _c(
                "el-timeline",
                _vm._l(_vm.logList, function(log, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: index,
                      attrs: {
                        timestamp: log.createTime,
                        color: "rgb(0, 153, 255)"
                      }
                    },
                    [
                      _c("h4", [_vm._v(_vm._s(log.eventName))]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "svip-manager-detail_event-name" },
                        [_vm._v(_vm._s(log.content))]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }