var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "appliDea" }, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("活动详情")]),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "appli_tit" },
      [
        _vm._v(" 报名数据 "),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.exportExcel } },
          [_vm._v("导出报名数据表格")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "appli_box_table" },
      [
        _c(
          "el-table",
          { attrs: { data: _vm.list, border: "" } },
          [
            _c("el-table-column", {
              attrs: { width: "100", align: "center", label: "序号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " + _vm._s(scope.$index + 1) + "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _vm._l(_vm.tabTh, function(item, index) {
              return _c("el-table-column", {
                key: index,
                attrs: {
                  prop: item.prop,
                  label: item.tit,
                  width: item.width,
                  align: "center"
                }
              })
            })
          ],
          2
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "appli_tit" }, [_vm._v(" 活动详情 ")]),
    _vm._v(" "),
    _c("div", { staticClass: "appli_box" }, [
      _c("ul", [
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动名称：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [_vm._v(_vm._s(_vm.name))])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动时间：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [_vm._v(_vm._s(_vm.time))])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动地点：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [_vm._v(_vm._s(_vm.site))])
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动上线时间：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [_vm._v(_vm._s(_vm.creatTime))])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "appli_tit" }, [_vm._v(" 活动数据 ")]),
    _vm._v(" "),
    _vm._m(2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", { staticClass: "li_tit" }, [_vm._v("活动BANNEr：")]),
      _vm._v(" "),
      _c("div", { staticClass: "li_banner" }, [
        _c("img", {
          attrs: {
            src:
              "http://xl-xxp.oss-cn-shenzhen.aliyuncs.com/2019-07-16/cdc627f0572b439790d5795a8dfc7bcc.jpg"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", { staticClass: "li_tit" }, [_vm._v("活动详情内容：")]),
      _vm._v(" "),
      _c("div", { staticClass: "li_content" }, [
        _c("img", {
          attrs: {
            src:
              "http://xl-xxp.oss-cn-shenzhen.aliyuncs.com/2019-07-16/cdc627f0572b439790d5795a8dfc7bcc.jpg"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "appli_box" }, [
      _c("div", { staticClass: "appli_box_activity" }, [_vm._v("暂无数据")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }