"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default
  },
  props: {
    type: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      couponArr: [],
      total: 0,
      multipleSelection: [],
      obj: {
        pageNo: 1,
        pageSize: 10,
        status: 2,
        couponType: ''
      },
      options: [{
        value: -1,
        label: '全部类型'
      }, {
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣券'
      }, {
        value: 3,
        label: '礼品券'
      }, {
        value: 4,
        label: '体验券'
      }],
      getRowKeys: function getRowKeys(row) {
        return row.uuid;
      },
      tableData: [],
      tabTh: [{
        tit: '优惠券编码',
        prop: 'uuid',
        width: 180
      }, {
        tit: '优惠券名称',
        prop: 'name'
      }, {
        tit: '类型',
        prop: 'couponType',
        width: 80
      }, {
        tit: '优惠券有效期',
        prop: 'time',
        width: 200
      },
      /* {
                    tit: "发布时间",
                    prop: 'createTime',
                    width: 180,
                    sortable: true
                  }, */
      {
        tit: '状态',
        prop: 'statusText',
        width: 90
      }]
    };
  },
  mounted: function mounted() {
    this.listCouponPage;
  },
  methods: {
    init: function init() {
      var _this = this;

      var _self = this;

      (0, _coupon.listCouponPage)(this.obj).then(function (res) {
        var data = res.data.items;
        _this.total = res.data.totalNum;
        data.filter(function (value, key, arr) {
          if (_self.type === 'radio') {
            if (_self.multipleSelection.length > 0) {
              if (value.uuid === _self.multipleSelection[0].uuid) {
                value.check = true;
              } else {
                value.check = false;
              }
            } else {
              value.check = false;
            }
          }

          value.createTime = value.createTime; // .substring(0, 10)

          if (value.validTimeType === 1) {
            value.time = value.validBeginTime.substring(0, 10) + '至' + value.validEndTime.substring(0, 10);
          } else if (value.validTimeType === 2) {
            value.time = this.getValidDay(value); //'领取后' + value.validDay + '天内有效'
          }

          if (value.couponType === 1) {
            value.couponType = '代金券';
          } else if (value.couponType === 2) {
            value.couponType = '折扣券';
          } else if (value.couponType === 3) {
            value.couponType = '礼品券';
          } else if (value.couponType === 4) {
            value.couponType = '体验券';
          } else if (value.couponType === 5) {
            value.couponType = '外部优惠券';
          }

          switch (value.status) {
            case 1:
              value.statusText = '待审核';
              break;

            case 2:
              value.statusText = '已审核';
              break;

            case 3:
              value.statusText = '审核撤回';
              break;

            case 4:
              value.statusText = '审核撤销';
              break;

            case 5:
              value.statusText = '已过期';
              break;

            case 6:
              value.statusText = '已终止';
              break;

            case 8:
              value.statusText = '已投放';
              break;

            case 9:
              value.statusText = '投放完成';
              break;
          }
        });
        _this.tableData = data;
        console.log(_this.tableData);
      });
    },

    /*
          关闭弹框
          */
    closePop: function closePop() {
      this.$emit('close'); // this.couponTableShow = false
    },

    /*
          清空优惠券选择
          */
    clearCoupon: function clearCoupon() {
      this.$refs.multipleTable.clearSelection();
    },
    affirmAddCoupon: function affirmAddCoupon() {
      var _this2 = this;

      this.couponArr = [];
      this.multipleSelection.forEach(function (item) {
        if (_this2.couponArr.indexOf(item.uuid) === -1) {
          _this2.couponArr.push(item.uuid);
        }
      });
      this.$emit('setCoupons', this.couponArr);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);

      if (this.type === 'radio') {
        if (!val) {
          val = this.multipleSelection[0];
        }

        this.tableData.forEach(function (item) {
          if (item.uuid !== val.uuid) {
            item.check = false;
          }
        });
        this.multipleSelection = [val];
      } else {
        this.multipleSelection = val;
      }
    }
  }
};
exports.default = _default2;