var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 订单返利详情 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { size: "small", "label-width": "150px" }
        },
        [
          _c("el-form-item", { attrs: { label: "订单号：" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.orderData.rebateOrderVo.orderNo) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "返利方式：", prop: "priceParamList" } },
            [
              _c("w-table", {
                attrs: {
                  "table-data": _vm.orderData.rebateOrderCardVoList,
                  columns: _vm.columns
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }