"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      tableData: [],
      requestData: {
        couponId: null,
        pageSize: 10,
        page: 1
      },
      searchData: [{
        label: '优惠券编码',
        type: 'input',
        span: 4,
        placeholder: '优惠券编码',
        value: 'couponId'
      }]
    };
  },
  mounted: function mounted() {
    this.$refs.wTable.setRequestValue('type', 1);
  },
  methods: {
    goAudit: function goAudit(row, type) {
      var _self = this;

      goChangeStatus({
        id: row.id,
        status: type
      }).then(function (res) {
        _self.$refs.wTable.getList();

        _self.$message({
          type: 'success',
          message: '操作成功!'
        });
      });
    }
  }
};
exports.default = _default;