"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generatePayQrcode = generatePayQrcode;
exports.generateQrcode = generateQrcode;
exports.getData = getData;
exports.getInOutIn = getInOutIn;
exports.getInOutOut = getInOutOut;
exports.getListPage = getListPage;
exports.goExamine = goExamine;
exports.infoExport = infoExport;
exports.rangeCard = rangeCard;
exports.zipPayQrcode = zipPayQrcode;
exports.zipQrcode = zipQrcode;

var _request = _interopRequireDefault(require("@/utils/request"));

function getListPage(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/api/card/make/inOut/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getInOutOut(data) {
  return (0, _request.default)({
    url: '/api/card/make/inOut/out',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getInOutIn(data) {
  return (0, _request.default)({
    url: '/api/card/make/inOut/in',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getData(data) {
  return (0, _request.default)({
    url: '/api/card/make/inOut/' + data,
    loading: 'loading',
    method: 'get'
  });
}

function goExamine(data) {
  return (0, _request.default)({
    url: '/api/card/make/inOut/examine',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function rangeCard(data) {
  return (0, _request.default)({
    url: '/api/card/make/kinds/range/card/' + data,
    method: 'get',
    loading: 'loading'
  });
}

function zipQrcode(data) {
  return (0, _request.default)({
    url: '/api/card/make/plan/zip/qrcode/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function zipPayQrcode(data) {
  return (0, _request.default)({
    url: '/api/card/make/plan/zip/pay/qrcode/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function generatePayQrcode(data) {
  return (0, _request.default)({
    url: '/api/card/make/plan/generate/pay/qrcode/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function infoExport(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/export/' + data,
    loading: 'loading',
    method: 'get'
  });
}

function generateQrcode(data) {
  return (0, _request.default)({
    url: '/api/card/make/plan/generate/qrcode/' + data,
    method: 'post',
    loading: 'loading'
  });
}