var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("多彩宝活动商品管理")]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { padding: "20px 0" }, attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "商品名称" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.init("search")
                  }
                },
                model: {
                  value: _vm.obj.goodsName,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "goodsName", $$v)
                  },
                  expression: "obj.goodsName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "商品编码" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.init("search")
                  }
                },
                model: {
                  value: _vm.obj.goodsCode,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "goodsCode", $$v)
                  },
                  expression: "obj.goodsCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "商品分类",
                    filterable: "",
                    autocomplete: "off"
                  },
                  model: {
                    value: _vm.obj.className,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "className", $$v)
                    },
                    expression: "obj.className"
                  }
                },
                _vm._l(_vm.shopList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.className, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: {
                    click: function($event) {
                      return _vm.goPage("add")
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "info",
                    size: "mini",
                    icon: "el-icon-refresh"
                  },
                  on: {
                    click: function($event) {
                      return _vm.init("refresh")
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search"
                  },
                  on: {
                    click: function($event) {
                      return _vm.init("search")
                    }
                  }
                },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.list, size: "mini" }
        },
        [
          _c("el-table-column", {
            attrs: {
              width: "50",
              align: "center",
              label: "序号",
              fixed: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          (_vm.obj.pageNo - 1) * _vm.obj.pageSize +
                            scope.$index +
                            1
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.tabTh, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: item.prop,
                label: item.label,
                align: "center",
                "min-width": item.width
              }
            })
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "table_td_btn",
                        on: {
                          click: function($event) {
                            return _vm.goPage("edit", scope.row.id)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.obj.pageNo,
          limit: _vm.obj.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.obj, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.obj, "pageSize", $event)
          },
          pagination: _vm.init
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }