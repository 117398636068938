"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '添加活动券',
      list: [{
        tit: '满减活动',
        txtArr: '<p>活动券-满减活动</p>    ',
        imgPath: require('@/assets/tab/1.png'),
        path: '/couponActivity/couponCAdd?type=1'
      }, {
        tit: '多件多折',
        txtArr: '<p>活动券-多件多折</p>',
        imgPath: require('@/assets/tab/2.png'),
        path: '/couponActivity/couponCAdd?type=2'
      }]
    };
  }
};
exports.default = _default;