var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-activity-form" },
    [
      _c("div", { staticClass: "view_title" }, [
        _vm._v(_vm._s(_vm.id ? "编辑" : "新建") + "权益")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-width": "150px",
            model: _vm.ruleForm,
            rules: _vm.rules
          }
        },
        [
          _c("Descriptions", { attrs: { title: "基础设置" } }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "权益名称：", prop: "rightName" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  placeholder: "请输入",
                  maxlength: "10",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm.rightName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "rightName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.rightName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("Descriptions", { attrs: { title: "免费预约设置" } }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "服务预约：", required: "" } },
            [
              _c("el-row", { attrs: { gutter: 0, type: "flex" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          required: "",
                          prop: "serviceIssueType",
                          size: "medium"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "form_input",
                            attrs: {
                              size: "medium",
                              filterable: "",
                              autocomplete: "off"
                            },
                            on: { change: _vm.serviceTimeRangeUnitChange },
                            model: {
                              value: _vm.ruleForm.serviceIssueType,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "serviceIssueType", $$v)
                              },
                              expression: "ruleForm.serviceIssueType"
                            }
                          },
                          [
                            _c(
                              "el-option",
                              { attrs: { label: "每年", value: 1 } },
                              [_vm._v("每年")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每月", value: 2 } },
                              [_vm._v("每月")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每日", value: 3 } },
                              [_vm._v("每日")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每周", value: 4 } },
                              [_vm._v("每周")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "指定周期", value: 5 } },
                              [_vm._v("指定周期")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.serviceStatus == true
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-left": "10px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "servicePeriodStartTime",
                                size: "medium"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "form_input",
                                attrs: {
                                  size: "medium",
                                  type: "date",
                                  placeholder: "开始时间",
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.ruleForm.servicePeriodStartTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "servicePeriodStartTime",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.servicePeriodStartTime"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("span", [_vm._v("每")]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "servicePeriodGapVal",
                                    size: "medium"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "form_input",
                                      attrs: {
                                        size: "medium",
                                        placeholder: "请输入"
                                      },
                                      model: {
                                        value: _vm.ruleForm.servicePeriodGapVal,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "servicePeriodGapVal",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.servicePeriodGapVal"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("天")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "10px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "serviceNum", size: "medium" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "form_input",
                              attrs: { size: "medium", placeholder: "请输入" },
                              model: {
                                value: _vm.ruleForm.serviceNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "serviceNum", $$v)
                                },
                                expression: "ruleForm.serviceNum"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("次")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "场地预约：", required: "" } },
            [
              _c("el-row", { attrs: { gutter: 0, type: "flex" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          required: "",
                          prop: "placeIssueType",
                          size: "medium"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "form_input",
                            attrs: {
                              size: "medium",
                              filterable: "",
                              autocomplete: "off"
                            },
                            on: { change: _vm.placeTimeRangeUnitChange },
                            model: {
                              value: _vm.ruleForm.placeIssueType,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "placeIssueType", $$v)
                              },
                              expression: "ruleForm.placeIssueType"
                            }
                          },
                          [
                            _c(
                              "el-option",
                              { attrs: { label: "每年", value: 1 } },
                              [_vm._v("每年")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每月", value: 2 } },
                              [_vm._v("每月")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每日", value: 3 } },
                              [_vm._v("每日")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每周", value: 4 } },
                              [_vm._v("每周")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "指定周期", value: 5 } },
                              [_vm._v("指定周期")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.placeStatus == true
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-left": "10px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "placePeriodStartTime",
                                size: "medium"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "form_input",
                                attrs: {
                                  size: "medium",
                                  type: "date",
                                  placeholder: "开始时间",
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.ruleForm.placePeriodStartTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "placePeriodStartTime",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.placePeriodStartTime"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("span", [_vm._v("每")]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "placePeriodGapVal",
                                    size: "medium"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "form_input",
                                      attrs: {
                                        size: "medium",
                                        placeholder: "请输入"
                                      },
                                      model: {
                                        value: _vm.ruleForm.placePeriodGapVal,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "placePeriodGapVal",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.placePeriodGapVal"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("天")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "10px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "placeNum", size: "medium" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "form_input",
                              attrs: { size: "medium", placeholder: "请输入" },
                              model: {
                                value: _vm.ruleForm.placeNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "placeNum", $$v)
                                },
                                expression: "ruleForm.placeNum"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("次")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动预约：", required: "" } },
            [
              _c("el-row", { attrs: { gutter: 0, type: "flex" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          required: "",
                          prop: "activityIssueType",
                          size: "medium"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "form_input",
                            attrs: {
                              size: "medium",
                              filterable: "",
                              autocomplete: "off"
                            },
                            on: { change: _vm.activityTimeRangeUnitChange },
                            model: {
                              value: _vm.ruleForm.activityIssueType,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "activityIssueType", $$v)
                              },
                              expression: "ruleForm.activityIssueType"
                            }
                          },
                          [
                            _c(
                              "el-option",
                              { attrs: { label: "每年", value: 1 } },
                              [_vm._v("每年")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每月", value: 2 } },
                              [_vm._v("每月")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每日", value: 3 } },
                              [_vm._v("每日")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每周", value: 4 } },
                              [_vm._v("每周")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "指定周期", value: 5 } },
                              [_vm._v("指定周期")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.activityStatus == true
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-left": "10px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "activityPeriodStartTime",
                                size: "medium"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "form_input",
                                attrs: {
                                  size: "medium",
                                  type: "date",
                                  placeholder: "开始时间",
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.ruleForm.activityPeriodStartTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "activityPeriodStartTime",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.activityPeriodStartTime"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("span", [_vm._v("每")]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "activityPeriodGapVal",
                                    size: "medium"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "form_input",
                                      attrs: {
                                        size: "medium",
                                        placeholder: "请输入"
                                      },
                                      model: {
                                        value:
                                          _vm.ruleForm.activityPeriodGapVal,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "activityPeriodGapVal",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.activityPeriodGapVal"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("天")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "10px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "activityNum", size: "medium" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "form_input",
                              attrs: { size: "medium", placeholder: "请输入" },
                              model: {
                                value: _vm.ruleForm.activityNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "activityNum", $$v)
                                },
                                expression: "ruleForm.activityNum"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("次")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "免费停车：", required: "" } },
            [
              _c("el-row", { attrs: { gutter: 0, type: "flex" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          required: "",
                          prop: "freeParkIssueType",
                          size: "medium"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "form_input",
                            attrs: {
                              size: "medium",
                              filterable: "",
                              autocomplete: "off"
                            },
                            on: { change: _vm.freeParTimeRangeUnitChange },
                            model: {
                              value: _vm.ruleForm.freeParkIssueType,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "freeParkIssueType", $$v)
                              },
                              expression: "ruleForm.freeParkIssueType"
                            }
                          },
                          [
                            _c(
                              "el-option",
                              { attrs: { label: "每年", value: 1 } },
                              [_vm._v("每年")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每月", value: 2 } },
                              [_vm._v("每月")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每日", value: 3 } },
                              [_vm._v("每日")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "每周", value: 4 } },
                              [_vm._v("每周")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "指定周期", value: 5 } },
                              [_vm._v("指定周期")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.freeParkStatus == true
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-left": "10px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "freeParkPeriodStartTime",
                                size: "medium"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "form_input",
                                attrs: {
                                  size: "medium",
                                  type: "date",
                                  placeholder: "开始时间",
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.ruleForm.freeParkPeriodStartTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "freeParkPeriodStartTime",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.freeParkPeriodStartTime"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("span", [_vm._v("每")]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "freeParkPeriodGapVal",
                                    size: "medium"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "form_input",
                                      attrs: {
                                        size: "medium",
                                        placeholder: "请输入"
                                      },
                                      model: {
                                        value:
                                          _vm.ruleForm.freeParkPeriodGapVal,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "freeParkPeriodGapVal",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.freeParkPeriodGapVal"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("天")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "10px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "freeParkNum", size: "medium" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "form_input",
                              attrs: { size: "medium", placeholder: "请输入" },
                              model: {
                                value: _vm.ruleForm.freeParkNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "freeParkNum", $$v)
                                },
                                expression: "ruleForm.freeParkNum"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("小时")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("Descriptions", { attrs: { title: "赠送设置" } }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "rightGifts" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: {
                    disabled: _vm.canUpdateGift === false,
                    type: "primary",
                    size: "mini"
                  },
                  on: { click: _vm.getAward }
                },
                [_vm._v("\n        选择赠送权益\n      ")]
              ),
              _vm._v(" "),
              _vm.interestsStatus === true
                ? _c("div", [_vm._v("已选权益：" + _vm._s(_vm.interestsName))])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-table",
                { attrs: { data: _vm.list, size: "mini", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "奖励类型",
                      "min-width": "200",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.giftType[scope.row.giftType].text || ""
                                ) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "关联礼品",
                      "min-width": "200",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.giftStatus === true
                              ? _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.giftName ||
                                          "次月计算上月获得积分，按比例返利"
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _c("div", [
                                  scope.row.giftType !== 4
                                    ? _c("p", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.giftName || "") +
                                            "\n              "
                                        )
                                      ])
                                    : _c("p", [
                                        _vm._v(
                                          "次月计算上月获得积分，按比例返利"
                                        )
                                      ])
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "礼品名称",
                      "min-width": "100",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.giftName ||
                                    "获得积分" + scope.row.publicCol
                                ) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "礼品编码",
                      "min-width": "150",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.giftCode || "") +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "规格", width: "150", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.publicCol || "") +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm", "edit")
                    }
                  }
                },
                [_vm._v("\n        保存设置\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: _vm.popType, "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }