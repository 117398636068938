"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.editData = editData;
exports.getData = getData;
exports.getGoodsPage = getGoodsPage;
exports.getList = getList;
exports.getOrderDea = getOrderDea;
exports.goAddGoods = goAddGoods;
exports.goExam = goExam;
exports.recordPage = recordPage;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: '/api/cactivity/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addData(data) {
  return (0, _request.default)({
    url: '/api/cactivity',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editData(data) {
  return (0, _request.default)({
    url: '/api/admin/system/sys-menu/edit',
    method: 'put',
    data: data
  });
}

function getData(data) {
  return (0, _request.default)({
    url: '/api/cactivity/detail/' + data,
    method: 'get',
    loading: 'loading'
  });
}

function goExam(data) {
  return (0, _request.default)({
    url: '/api/cactivity/exam',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function goAddGoods(data) {
  return (0, _request.default)({
    url: '/api/cactivity/goods/batch/add',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getGoodsPage(data) {
  return (0, _request.default)({
    url: '/api/cactivity/goods/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function recordPage(data) {
  return (0, _request.default)({
    url: '/api/cactivity/buy/record/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getOrderDea(data) {
  return (0, _request.default)({
    url: '/api/cactivity/buy/record/detail/list',
    method: 'post',
    loading: 'loading',
    data: data
  });
}