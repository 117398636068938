"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      isChagneGoodsStatus: false,
      columns: [{
        label: '商户名称',
        prop: 'merchantName',
        width: 50
      }, {
        slot: 'type',
        label: '类型（1，收货地址 2，退货地址）',
        prop: 'type'
      }, {
        label: '地址',
        prop: 'address',
        width: 180,
        align: "left"
      }, {
        slot: 'isShow',
        label: '是否显示（1=是,0=否）',
        prop: 'isShow'
      }, {
        slot: 'isDelete',
        label: '是否删除（1=已删除,0=未删除）',
        prop: 'isDelete'
      }, {
        label: '手机号码',
        prop: 'mobile',
        width: 120
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 150
      }, {
        label: '更新',
        prop: 'updateTime',
        width: 150
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        placeholder: '地址',
        value: 'address'
      }, {
        type: 'input',
        placeholder: '手机号',
        value: 'mobile'
      }, {
        type: 'input',
        placeholder: '商户名称',
        value: 'merchantName'
      }, {
        type: 'radio',
        placeholder: '是否显示',
        value: 'isShow',
        select: [{
          label: '显示',
          value: 1
        }, {
          label: '不显示',
          value: 0
        }]
      }, {
        type: 'radio',
        placeholder: '类型',
        value: 'type',
        select: [{
          label: '收货地址',
          value: 1
        }, {
          label: '退货地址',
          value: 2
        }]
      }]
    };
  },
  mounted: function mounted() {},
  activated: function activated() {
    this.$refs.wTable.setRequestValue('endpointFrom', 0);
    this.$refs.wTable.getList();
  },
  methods: {
    changeRecommend: function changeRecommend(e, row) {
      var _this = this;

      this.apiPost('/api/mall/merchantAddress/show', {
        id: row.id,
        isShow: e
      }).then(function (res) {
        _this.$refs.wTable.getList();
      }).catch(function (err) {
        _this.$refs.wTable.getList();
      });
    },
    delGoodsItem: function delGoodsItem(id, index) {
      var _this2 = this;

      this.apiPost('/api/mall/merchantAddress/delete', {
        id: id
      }).then(function (res) {
        _this2.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;