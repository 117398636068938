"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _user = require("@/api/user.js");

var _deposit = require("@/api/deposit");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      cardType: {
        1: '线上储值卡',
        2: '线下储值卡',
        3: '昂婕老卡',
        4: '提货劵(昂婕)',
        5: '提货券（线上 ）',
        6: '权益卡',
        7: '红包'
      },
      category: {
        1: '正常销售卡',
        2: '转赠卡',
        3: '荔星购物卡'
      },
      popShow: false,
      // 小程序码弹框
      qrCodeShow: true,
      // 列表是否显示获取小程序码
      qrCode: '',
      // 小程序码
      url: '',
      // 小程序链接
      list: [],
      tabTh: [{
        tit: '储值卡号',
        prop: 'uuid',
        width: 200
      }, {
        tit: '储值卡名称',
        prop: 'name'
      }, {
        tit: '分类',
        prop: 'className'
      }, {
        tit: '建卡时间',
        prop: 'createTime',
        width: 180,
        sortable: true
      }, {
        tit: '发行数量',
        prop: 'number',
        width: 100
      }, {
        tit: '状态',
        prop: 'statusText',
        width: 100
      }],
      operation: 2,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      btn: '查看详情',
      uuid: '',
      name: '',
      options: [{
        value: -1,
        label: '全部'
      }, {
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '已审核'
      }, {
        value: 3,
        label: '已撤回'
      }, {
        value: 4,
        label: '已撤销'
      }],
      status: -1
    };
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    ifDonationChange: function ifDonationChange(val) {
      var _this = this;

      // 是否支持转赠
      var tip = val.ifDonation === 1 ? '储值卡将支持转赠' : '储值卡将不再支持转赠';
      this.$confirm("".concat(tip, ", \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _deposit.editIfDonation)({
          id: val.id,
          ifDonation: val.ifDonation
        }).then(function (res) {
          _this.$message.success('操作成功');
        }).catch(function () {
          val.ifDonation = val.ifDonation === 1 ? 2 : 1;
        });
      }).catch(function () {
        val.ifDonation = val.ifDonation === 1 ? 2 : 1;
      });
    },
    setBtn: function setBtn(index) {
      // 查看详情
      this.$router.push('/deposit/onLine/cardDea?id=' + index.id);
    },
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getCode: function getCode(scope) {
      var _this2 = this;

      // 获取小程序码
      this.url = 'pages/starCard/recharge/recharge?id=' + scope.uuid;
      this.openLoading('获取小程序码中');
      (0, _user.xcxQRCode)({
        path: 'pages/starCard/recharge/recharge',
        scene: 'id=' + scope.uuid
      }).then(function (res) {
        _this2.openLoading().close();

        _this2.qrCode = res.data;
        _this2.popShow = true;
      }).catch(function (res) {
        _this2.openLoading().close();
      });
    },
    getList: function getList(page) {
      this.pageNo = page.page;
      this.pageSize = page.limit;
      this.init();
    },
    init: function init() {
      var _this3 = this;

      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        uuid: this.uuid.trim(),
        name: this.name.trim(),
        status: this.status,
        type: [1, 7]
      };
      this.openLoading();
      (0, _deposit.listCardStored)(data).then(function (res) {
        _this3.openLoading().close();

        var data = res.data.items;

        if (res.ret === 1000) {
          data.filter(function (value, key, arr) {
            switch (value.status) {
              case 1:
                value.statusText = '待审核';
                break;

              case 2:
                value.statusText = '已审核';
                break;

              case 3:
                value.statusText = '审核撤回';
                break;

              case 4:
                value.statusText = '审核撤销';
                break;
            }

            if (value.number === 0) {
              value.number = '0(不限)';
            }

            if (value.type === 2) {
              value.typeText = '线下';
            } else if (value.type === 1) {
              value.typeText = '线上';
            } else {
              value.typeText = '未知';
            }
          });
          _this3.list = data;
          _this3.total = res.data.totalNum;
        }
      }).catch(function (res) {
        _this3.openLoading().close();
      });
    },
    setValue: function setValue() {
      this.status = -1;
      this.uuid = '';
      this.name = '';
      this.pageNo = 1;
      this.pageSize = 10;
      this.init();
    }
  }
};
exports.default = _default;