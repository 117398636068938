var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [
        _vm._v("在领券中心新增/编辑优惠券")
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            size: "small",
            model: _vm.form,
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "list", label: "选择优惠券：", required: "" }
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "max-width": "200px" },
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.getCoupon()
                    }
                  }
                },
                [_vm._v("选择优惠券")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    size: "mini",
                    data: _vm.form.list,
                    border: "",
                    "max-height": "500"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", width: "80", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.$index + 1) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.tabTh, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        "min-width": item.width,
                        align: "center"
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "投放数量",
                      prop: "totalNumber",
                      width: "160",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "center",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "90px" },
                                  attrs: { type: "number", size: "mini" },
                                  on: {
                                    input: function($event) {
                                      return _vm.numberChange(
                                        $event,
                                        scope.$index
                                      )
                                    },
                                    change: function($event) {
                                      return _vm.numberChange(
                                        $event,
                                        scope.$index
                                      )
                                    }
                                  },
                                  model: {
                                    value: scope.row.putNumber,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "putNumber", $$v)
                                    },
                                    expression: "scope.row.putNumber"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "18px" } },
                                  [_vm._v("/")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "70px",
                                      "text-align": "left"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.totalNumber == 0
                                          ? "不限量"
                                          : scope.row.totalNumber
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "标签",
                      prop: "labelId",
                      "min-width": "100",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.getLabel(scope.$index)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _vm._l(scope.row.labelName, function(
                                  item,
                                  index
                                ) {
                                  return _c("p", { key: index }, [
                                    _vm._v(_vm._s(item))
                                  ])
                                })
                              ],
                              2
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "80", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              { attrs: { type: "primary", size: "mini" } },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { required: "", "label-width": "0" }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "onlineType",
                    label: "上线时间：",
                    required: ""
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.onlineType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "onlineType", $$v)
                        },
                        expression: "form.onlineType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("选择上线周期")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("立即上线（需手动下线）")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.onlineType == 1
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "time", label: "  ", required: "" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%", "max-width": "400px" },
                        attrs: {
                          size: "small",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        model: {
                          value: _vm.form.time,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "time", $$v)
                          },
                          expression: "form.time"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "centerStatus", label: "状态：", required: "" }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.centerStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "centerStatus", $$v)
                    },
                    expression: "form.centerStatus"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("禁用")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: " " } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "max-width": "200px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("coupon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "couponPop",
        on: { setCoupons: _vm.setCoupons, closeCouponPop: _vm.closeCouponPop }
      }),
      _vm._v(" "),
      _c("labelPop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.labelShow,
            expression: "labelShow"
          }
        ],
        ref: "labels",
        on: { addLabel: _vm.addLabel, close: _vm.closeLabel }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }