"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _api = require("../api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var validateNumber = function validateNumber(rule, value, callback) {
  if (/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value) === false) {
    callback(new Error('请填写大于等于0的数字'));
  } else {
    callback();
  }
};

var _default = {
  data: function data() {
    return {
      storeList: [],
      rules: {
        productName: [{
          required: true,
          message: '请输入产品名称'
        }],
        productStatus: [{
          required: true,
          message: '请选择产品状态'
        }],
        applyTime: [{
          required: true,
          message: '请选择时间'
        }],
        offlineTime: [{
          required: true,
          message: '请选择时间'
        }],
        limitTypes: [{
          required: true,
          message: '请选择限制类型'
        }],
        limitConsume: [{
          required: true,
          message: '请输入限制消费额'
        }, {
          validator: validateNumber
        }],
        productImg: [{
          required: true,
          message: '请上传产品图片'
        }],
        productDetail: [{
          required: true,
          message: '请输入产品详情'
        }],
        storeIds: [{
          required: true,
          message: '请输入选择网点'
        }]
      },
      ruleForm: {
        productName: null,
        productStatus: null,
        applyTime: null,
        offlineTime: null,
        limitTypes: [],
        limitConsume: null,
        productImg: 'mika/dev/2021-07-14/51a47c63b66e41f7b601e72745fa293e.png',
        productDetail: null,
        storeIds: []
      }
    };
  },
  components: {
    addLoad: _addLoad.default,
    Tinymce: _Tinymce.default
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;
    (0, _api.listBankStore)().then(function (res) {
      _this.storeList = res.data;
    });

    if (this.ruleForm.id) {
      (0, _api.getBankProduct)(this.ruleForm.id).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    setCover: function setCover(e) {
      this.ruleForm.productImg = e;
    },
    // 三级地址选择
    regionDataChange: function regionDataChange(e) {
      var arr = this.$refs['state'].getCheckedNodes(false)[0].pathLabels || [];
      this.ruleForm.detailAddress = arr.join('');
    },
    clearForm: function clearForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.ruleForm));

          if (_this2.ruleForm.id) {
            data.id = _this2.ruleForm.id;
            (0, _api.editBankProduct)(data).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addBankProduct)(data).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;