"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _api = require("./api.js");

var _user = require("@/api/user.js");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      codeData: {
        store: 0
      },
      formData: [{
        label: '导出数量：',
        type: 'inputNumber',
        placeholder: '请输入导出数量',
        value: 'num' // max:this.codeData.store

      }, {
        label: '订单金额：',
        type: 'inputNumber',
        placeholder: '请输入订单金额',
        value: 'realPayMoney' // max:this.codeData.store

      }, {
        label: '导出备注：',
        type: 'input',
        placeholder: '请输入导出备注',
        value: 'toPlatFrom'
      }],
      ruleForm: {
        id: null,
        toPlatFrom: null,
        num: undefined,
        realPayMoney: undefined
      },
      isExchange: false,
      url: '',
      couponUUid: '',
      qrCode: '',
      popShow: false,
      time: null,
      imgSrc: this.$store.state.user.imgSrc,
      urlList: _selectUrl.default.urlList,
      appIdArr: _selectUrl.default.appIdArr,
      downloadLoading: false,
      requestData: {
        status: null,
        uuid: null,
        name: null,
        receiveBeginTime: null,
        receiveEndTime: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '券包ID',
        prop: 'id',
        width: 50
      }, {
        label: '券包编码',
        prop: 'uuid',
        width: 100
      }, {
        label: '券包名称',
        prop: 'name',
        width: 100
      }, {
        slot: 'labelContent',
        label: '可购买人群标签',
        prop: 'labelContent',
        width: 100
      },
      /* {
                      slot: 'popupRate',
                      label: '券包详情',
                      prop: 'popupRate',
                      width: 100
                    }, */
      {
        label: '领取周期',
        prop: 'receiveTimeCycle',
        width: 100
      }, {
        label: '投放数量',
        prop: 'totalNumber',
        width: 50
      }, {
        label: '剩余数量',
        prop: 'store',
        width: 50
      }, {
        label: '限领数量',
        prop: 'limitNumber',
        width: 50
      }, {
        label: '领取门槛',
        prop: 'payDetails',
        width: 80
      }, {
        slot: 'putTarget',
        label: '投放对象',
        prop: 'putTarget',
        width: 80
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'popupStatus',
        label: '状态',
        prop: 'statusText',
        width: 80
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(value) {
      if (value) {
        this.requestData.receiveBeginTime = value[0];
        this.requestData.receiveEndTime = value[0];
      } else {
        this.requestData.receiveBeginTime = null;
        this.requestData.receiveEndTime = null;
      }
    }
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {},
  methods: {
    getCode5: function getCode5(row) {
      this.codeData = row;
      this.isExchange = true;
    },
    submitForm: function submitForm() {
      var _this = this;

      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.ruleForm), {}, {
        id: this.codeData.id
      });
      this.apiPost('/api/couponBag/exportOutCode', data).then(function (res) {
        var tHeader = ['券包名称', '券包编码', '兑换码', '领取周期'];
        var filterVal = ['name', 'uuid', 'changeCode', 'receiveTimeCycle'];
        var list = res.data.map(function (item) {
          return {
            changeCode: item.exchangeOrderNo,
            uuid: _this.codeData.uuid,
            name: _this.codeData.name,
            receiveTimeCycle: _this.codeData.receiveTimeCycle
          };
        });

        _this.getHandleDownload(tHeader, filterVal, list, "".concat(_this.codeData.name, "\u5BFC\u51FA").concat(_this.ruleForm.num, "\u4E2A\u5238\u5305-").concat(_this.ruleForm.toPlatFrom));

        _this.isExchange = false;

        _this.getList();
      });
    },
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getQrcode: function getQrcode(scope) {
      var _this2 = this;

      // 获取小程序码
      this.couponUUid = scope.name;
      (0, _user.couponQRCode)({
        path: 'pages/coupons/couponGift/couponGift',
        scene: String(scope.id)
      }).then(function (res) {
        _this2.url = 'pages/coupons/couponGift/couponGift?id=' + scope.id;
        _this2.qrCode = res.data;
        _this2.popShow = true;
      });
    },
    gosuperadditionById: function gosuperadditionById(scope) {
      var _this3 = this;

      this.$prompt('请输入追加数量', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\+?[1-9][0-9]*$/,
        inputErrorMessage: '大于0的正整数'
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _api.superadditionById)({
          id: scope.row.id,
          amout: value
        }).then(function (res) {
          _this3.$message({
            type: 'success',
            message: '追加成功'
          });
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '取消追加'
        });
      });
    },
    goDel: function goDel(scope) {
      var _this4 = this;

      (0, _api.deleteById)({
        id: scope.row.id
      }).then(function (res) {
        _this4.getList();
      });
    },
    goAudit: function goAudit(scope, status) {
      var _this5 = this;

      (0, _api.editCouponBagStatu)({
        id: scope.row.id,
        status: status
      }).then(function (res) {
        _this5.getList();
      });
    },
    handleDownload: function handleDownload() {
      var _this6 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this6.requestData[key]) {
          data[key] = _this6.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.listCouponBagPage)(data).then(function (res) {
        list = res.data.records;
        list.forEach(function (item) {
          switch (item.status) {
            case 1:
              item.statusText = '待审核';
              break;

            case 6:
              item.statusText = '待开始';
              break;

            case 7:
              item.statusText = '领取中';
              break;

            case 8:
              item.statusText = '已暂停';
              break;

            case 9:
              item.statusText = '已领完';
              break;

            case 10:
              item.statusText = '已结束';
              break;

            default:
              item.statusText = '';
              break;
          }
        });
        _this6.downloadLoading = false;

        _this6.getHandleDownload(tHeader, filterVal, list, '优惠券包');
      }).catch(function (res) {
        _this6.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this7 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.listCouponBagPage)(this.requestData).then(function (res) {
        _this7.tableData = res.data.records;
        _this7.requestData.total = res.data.total;
        return;
      });
    }
  }
  /*
        couponBag:add 新增
        couponBag:delete 删除
        couponBag:edit 编辑券包
        coupon:superaddition 追加
        couponBag:audit 审核
        */

};
exports.default = _default;