var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        background: _vm.data.moduleAttr.bgColor,
        marginTop: _vm.data.moduleAttr.temMargin / 2 + "px",
        padding: _vm.data.moduleAttr.temPadd / 2 + "px" + " 0"
      },
      on: {
        click: function($event) {
          return _vm.setCount(_vm.data)
        }
      }
    },
    [
      _vm.data.styleCode == "image1020"
        ? _c("div", [
            _c("div", { staticClass: "img1020 flex" }, [
              _c("div", { staticClass: "img1020_left" }, [
                _c("img", {
                  attrs: {
                    src: _vm.imgSrc + _vm.data.moduleAttr.dataItem[0].dataPath
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    src: _vm.imgSrc + _vm.data.moduleAttr.dataItem[1].dataPath,
                    mode: "widthFix"
                  }
                })
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "img1020_right",
                attrs: {
                  src: _vm.imgSrc + _vm.data.moduleAttr.dataItem[2].dataPath,
                  mode: "widthFix"
                }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "store1000"
        ? _c("div", { staticClass: "store_site flex" }, [_vm._v(" 延安西路> ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.rotation.indexOf(_vm.data.styleCode) >= 0 &&
      _vm.data.moduleAttr.dataItem.length > 0
        ? _c(
            "div",
            {
              staticClass: "column_box swiper_box",
              style: {
                height: _vm.data.moduleAttr.height / 2 + "px",
                width: _vm.data.moduleAttr.width / 2 + "px"
              }
            },
            [
              _c(
                "el-carousel",
                {
                  staticClass: "swiper",
                  attrs: {
                    "indicator-position": "none",
                    height: _vm.data.moduleAttr.height / 2 + "px"
                  }
                },
                _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                  return _c("el-carousel-item", { key: index }, [
                    _c("img", {
                      style: {
                        height: _vm.data.moduleAttr.height / 2 + "px",
                        width: _vm.data.moduleAttr.width / 2 + "px"
                      },
                      attrs: { src: _vm.imgSrc + item.dataPath, mode: "" }
                    })
                  ])
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "indicator",
                  class: _vm.data.moduleAttr.moduleType
                },
                _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                  return _c("div", {
                    key: index,
                    staticClass: "item",
                    class: _vm.current == index ? "active" : ""
                  })
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.button.indexOf(_vm.data.styleCode) >= 0 &&
      _vm.data.moduleAttr.dataItem.length > 0
        ? _c(
            "div",
            [
              _c(
                "draggable",
                {
                  staticClass: "column_box nav  flex",
                  attrs: {
                    "force-fallback": true,
                    move: _vm.onMove,
                    list: _vm.data.moduleAttr.dataItem,
                    handle: ".dargBtn",
                    animation: 1000,
                    filter: ".undraggable"
                  }
                },
                _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item flex_column dargBtn",
                      class: _vm.data.styleCode
                    },
                    [
                      _c("img", {
                        staticClass: "cover",
                        style: {
                          width: _vm.data.moduleAttr.width / 2 + "px",
                          height: _vm.data.moduleAttr.height / 2 + "px"
                        },
                        attrs: { src: _vm.imgSrc + item.dataPath, mode: "" }
                      }),
                      _vm._v(" "),
                      _vm.data.styleCode == "button1001" ||
                      _vm.data.styleCode == "button1003" ||
                      (_vm.data.styleCode == "button1005") |
                        (_vm.data.styleCode == "button1010")
                        ? _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.dataName) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1000"
        ? _c(
            "div",
            { staticClass: " img img1 flex" },
            _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
              return _c("img", {
                key: index,
                attrs: { src: _vm.imgSrc + item.dataPath, mode: "widthFix" }
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1001"
        ? _c(
            "div",
            { staticClass: "img img2 flex_between" },
            _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
              return _c("img", {
                key: index,
                attrs: { src: _vm.imgSrc + item.dataPath, mode: "widthFix" }
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1002" || _vm.data.styleCode == "image1003"
        ? _c(
            "div",
            { staticClass: "column_box img3 flex_between" },
            _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
              return _c("img", {
                key: index,
                attrs: { src: _vm.imgSrc + item.dataPath, mode: "widthFix" }
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1004" ||
      _vm.data.styleCode == "image1005" ||
      _vm.data.styleCode == "image1006"
        ? _c(
            "div",
            { staticClass: "column_box img flex_between" },
            _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
              return _c("img", {
                key: index,
                staticStyle: { width: "115px" },
                attrs: { src: _vm.imgSrc + item.dataPath, mode: "widthFix" }
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1007" || _vm.data.styleCode == "image1008"
        ? _c(
            "div",
            { staticClass: "column_box img  flex_between" },
            _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
              return _c("img", {
                key: index,
                staticStyle: { width: "85px" },
                attrs: { src: _vm.imgSrc + item.dataPath, mode: "widthFix" }
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1010"
        ? _c("div", { staticClass: "column_box img5 img6" }, [
            _c("img", {
              attrs: {
                src: _vm.imgSrc + _vm.data.moduleAttr.dataItem[0].dataPath,
                mode: "widthFix"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex_between" },
              _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                return index > 0
                  ? _c("img", {
                      key: index,
                      attrs: {
                        src: _vm.imgSrc + item.dataPath,
                        mode: "widthFix"
                      }
                    })
                  : _vm._e()
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1011"
        ? _c("div", { staticClass: "column_box img5 " }, [
            _c("img", {
              attrs: {
                src: _vm.imgSrc + _vm.data.moduleAttr.dataItem[0].dataPath,
                mode: "widthFix"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex_between" },
              _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                return index > 0
                  ? _c("img", {
                      key: index,
                      attrs: {
                        src: _vm.imgSrc + item.dataPath,
                        mode: "widthFix"
                      }
                    })
                  : _vm._e()
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1009"
        ? _c("div", { staticClass: "column_box img img9 flex_between" }, [
            _c("img", {
              staticStyle: { width: "105px" },
              attrs: {
                src: _vm.imgSrc + _vm.data.moduleAttr.dataItem[0].dataPath,
                mode: "widthFix"
              }
            }),
            _vm._v(" "),
            _c("div", [
              _c("img", {
                staticStyle: { width: "245px" },
                attrs: {
                  src: _vm.imgSrc + _vm.data.moduleAttr.dataItem[1].dataPath,
                  mode: "widthFix"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex_between",
                  staticStyle: { "margin-top": "10px" }
                },
                _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                  return index > 1
                    ? _c("img", {
                        key: index,
                        staticStyle: { width: "120px" },
                        attrs: {
                          src: _vm.imgSrc + item.dataPath,
                          mode: "widthFix"
                        }
                      })
                    : _vm._e()
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1012"
        ? _c("div", { staticClass: "column_box img  flex_between" }, [
            _c("img", {
              staticStyle: { width: "105px" },
              attrs: {
                src: _vm.imgSrc + _vm.data.moduleAttr.dataItem[0].dataPath,
                mode: "widthFix"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex_between",
                staticStyle: { "flex-wrap": "wrap", width: "245px" }
              },
              _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                return index > 0
                  ? _c("img", {
                      key: index,
                      staticStyle: { width: "120px" },
                      style: { marginTop: index > 2 ? "5px" : "0" },
                      attrs: {
                        src: _vm.imgSrc + item.dataPath,
                        mode: "widthFix"
                      }
                    })
                  : _vm._e()
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1013"
        ? _c("div", { staticClass: "column_box" }, [
            _vm.data.moduleAttr.img
              ? _c("img", {
                  attrs: { src: _vm.imgSrc + _vm.data.moduleAttr.img }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "img_1013",
                staticStyle: { "white-space": "nowrap", overflow: "auto" }
              },
              _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                return _c("img", {
                  key: index,
                  staticClass: "cover",
                  staticStyle: { display: "inline" },
                  style: {
                    width: _vm.data.moduleAttr.imgWidth / 2 + "px",
                    height: _vm.data.moduleAttr.imgHeight / 2 + "px",
                    marginLeft: _vm.data.moduleAttr.marginLeft / 2 + "px"
                  },
                  attrs: { src: _vm.imgSrc + item.dataPath }
                })
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1014"
        ? _c(
            "div",
            {
              staticClass: "column_box flex img_1014",
              staticStyle: { "flex-wrap": "wrap" }
            },
            _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
              return _c("img", {
                key: index,
                staticClass: "cover",
                style: {
                  width: _vm.data.moduleAttr.imgWidth / 2 + "px",
                  height: _vm.data.moduleAttr.imgHeight / 2 + "px",
                  marginLeft: _vm.data.moduleAttr.marginLeft / 2 + "px"
                },
                attrs: { src: _vm.imgSrc + item.dataPath }
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "image1015"
        ? _c(
            "div",
            {
              staticClass: "column_box flex img_1015",
              staticStyle: { "flex-wrap": "wrap" },
              style: {
                paddingBottom: _vm.data.moduleAttr.marginLeft / 2 + "px"
              }
            },
            _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
              return _c("img", {
                key: index,
                staticClass: "cover",
                style: {
                  width: _vm.data.moduleAttr.imgWidth / 2 + "px",
                  height: _vm.data.moduleAttr.imgHeight / 2 + "px",
                  marginLeft: _vm.data.moduleAttr.marginLeft / 2 + "px",
                  marginTop: _vm.data.moduleAttr.marginLeft / 2 + "px"
                },
                attrs: { src: _vm.imgSrc + item.dataPath }
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.coupon1.indexOf(_vm.data.styleCode) >= 0
        ? _c(
            "div",
            [
              _c(
                "draggable",
                {
                  staticClass: "column_box   ",
                  attrs: {
                    "force-fallback": true,
                    move: _vm.onMove,
                    list: _vm.data.moduleAttr.dataItem,
                    handle: ".dargBtn",
                    animation: 1000,
                    filter: ".undraggable"
                  }
                },
                _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: " coupon1 flex_between dargBtn",
                      class: _vm.data.moduleAttr.type
                    },
                    [
                      item.linkCode === "coupon" || item.linkCode === "goods"
                        ? _c("img", {
                            staticClass: "cover",
                            style: {
                              width: _vm.data.moduleAttr.coverWidth / 2 + "px",
                              height: _vm.data.moduleAttr.coverHeight / 2 + "px"
                            },
                            attrs: { src: _vm.imgSrc + item.cover }
                          })
                        : _c("img", {
                            staticClass: "cover",
                            style: {
                              width: _vm.data.moduleAttr.coverWidth / 2 + "px",
                              height: _vm.data.moduleAttr.coverHeight / 2 + "px"
                            },
                            attrs: { src: item.pic_url }
                          }),
                      _vm._v(" "),
                      _c("div", { staticClass: "info flex_column" }, [
                        _c(
                          "div",
                          {
                            class:
                              _vm.data.moduleAttr.coverWidth == 220
                                ? "names"
                                : "name"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.name) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class:
                              item.linkCode == "coupon" ? "type" : "productDesc"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  item.linkCode == "coupon"
                                    ? "券类型"
                                    : "商品描述"
                                ) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "price" }, [_vm._v(" 价格 ")])
                      ])
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "product1008" ||
      _vm.data.styleCode == "product1010" ||
      _vm.data.styleCode == "coupon1007" ||
      _vm.data.styleCode == "coupon1009"
        ? _c(
            "div",
            { staticClass: " coupon2 flex_between" },
            _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item coupon_item",
                  class: _vm.data.moduleAttr.type
                },
                [
                  item.linkCode === "coupon" || item.linkCode === "goods"
                    ? _c("img", {
                        staticClass: "cover",
                        attrs: { src: _vm.imgSrc + item.cover }
                      })
                    : _c("img", {
                        staticClass: "cover",
                        attrs: {
                          src:
                            "http://xl-cdn.xinglico.com/mika/pro/2020-11-04/812d5d8513594f129c17e4506527dd8d.png"
                        }
                      }),
                  _vm._v(" "),
                  _c("div", { staticClass: "item_box" }, [
                    _c("div", { staticClass: "name " }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            item.linkCode == "coupon" ||
                              item.linkCode === "goods"
                              ? item.name
                              : "商品名称"
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    item.linkCode == "coupon"
                      ? _c("div", { staticClass: "type" }, [
                          _vm._v(" 优惠券类型 ")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "price1" }, [_vm._v(" 价格 ")])
                  ])
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "legouGoods1002" ||
      _vm.data.styleCode == "product1012" ||
      _vm.data.styleCode == "product1013" ||
      _vm.data.styleCode == "coupon1011" ||
      _vm.data.styleCode == "coupon1012"
        ? _c(
            "div",
            { staticClass: "column_box coupon3 flex_between" },
            [
              _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item coupon_item",
                    class: _vm.data.moduleAttr.type
                  },
                  [
                    item.linkCode === "coupon" || item.linkCode === "goods"
                      ? _c("img", {
                          staticClass: "cover",
                          attrs: { src: _vm.imgSrc + item.cover }
                        })
                      : _c("img", {
                          staticClass: "cover",
                          attrs: { src: item.pic_url }
                        }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item_box" }, [
                      _c("div", { staticClass: "name " }, [
                        _vm._v(
                          "\n            " + _vm._s(item.name) + "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "price" }, [_vm._v(" 价格 ")])
                    ])
                  ]
                )
              }),
              _vm._v(" "),
              _vm.data.moduleAttr.dataItem.length % 3 == 1 ||
              _vm.data.moduleAttr.dataItem.length % 3 == 2
                ? _c("div", { staticClass: "item" })
                : _vm._e(),
              _vm._v(" "),
              _vm.data.moduleAttr.dataItem.length % 3 == 1
                ? _c("div", { staticClass: "item" })
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode == "product1014" ||
      _vm.data.styleCode == "product1016" ||
      _vm.data.styleCode == "coupon1013" ||
      _vm.data.styleCode == "coupon1015"
        ? _c("div", { staticClass: "column_box" }, [
            _c(
              "div",
              { staticClass: "coupon4", attrs: { "scroll-x": "true" } },
              _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item coupon_item",
                    staticStyle: { "margin-top": "0" },
                    style: { width: _vm.data.moduleAttr.coverWidth / 2 + "px" }
                  },
                  [
                    item.linkCode === "coupon" || item.linkCode === "goods"
                      ? _c("img", {
                          staticClass: "cover",
                          style: {
                            width: _vm.data.moduleAttr.coverWidth / 2 + "px",
                            height: _vm.data.moduleAttr.coverHeight / 2 + "px"
                          },
                          attrs: { src: _vm.imgSrc + item.cover }
                        })
                      : _c("img", {
                          staticClass: "cover",
                          style: {
                            width: _vm.data.moduleAttr.coverWidth / 2 + "px",
                            height: _vm.data.moduleAttr.coverHeight / 2 + "px"
                          },
                          attrs: {
                            src:
                              "http://xl-cdn.xinglico.com/mika/pro/2020-11-04/812d5d8513594f129c17e4506527dd8d.png"
                          }
                        }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item_box" }, [
                      _c("div", { staticClass: "name " }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              item.linkCode == "coupon" ||
                                item.linkCode === "goods"
                                ? item.name
                                : "商品名称"
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "price1" }, [_vm._v(" 价格 ")])
                    ])
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.boxImg.indexOf(_vm.data.styleCode) >= 0
        ? _c("div", { staticClass: "column_box coupon5 " }, [
            _c("div", { staticClass: "top_img" }, [
              _c("img", {
                staticClass: "cover",
                attrs: {
                  src: _vm.imgSrc + _vm.data.moduleAttr.chunkName,
                  mode: "widthFix"
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "coupon5_box flex_around" },
              [
                _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "item coupon_item" },
                    [
                      item.linkCode === "coupon" || item.linkCode === "goods"
                        ? _c("img", {
                            staticClass: "cover",
                            attrs: { src: _vm.imgSrc + item.cover }
                          })
                        : _c("img", {
                            staticClass: "cover",
                            attrs: {
                              src:
                                "http://xl-cdn.xinglico.com/mika/pro/2020-11-04/812d5d8513594f129c17e4506527dd8d.png"
                            }
                          }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item_box" }, [
                        _c("div", { staticClass: "name " }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                item.linkCode == "coupon" ||
                                  item.linkCode === "goods"
                                  ? "优惠券名称"
                                  : "商品名称"
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "price" }, [_vm._v(" 价格 ")])
                      ])
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.data.moduleAttr.dataItem.length % 3 == 1 ||
                _vm.data.moduleAttr.dataItem.length % 3 == 2
                  ? _c("div", { staticClass: "item" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.moduleAttr.dataItem.length % 3 == 1
                  ? _c("div", { staticClass: "item" })
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.couponFooterMore.indexOf(_vm.data.styleCode) >= 0
              ? _c(
                  "div",
                  {
                    staticClass: "coupon_bottom_more flex_center",
                    staticStyle: { "margin-top": "30px" }
                  },
                  [
                    _vm._v(" 查看更多 "),
                    _c("img", {
                      attrs: { src: "/static/img/index/more.png", mode: "" }
                    })
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.coupon2.indexOf(_vm.data.styleCode) >= 0
        ? _c(
            "div",
            {
              staticClass: "column_box coupon5 ",
              staticStyle: { padding: "20px 0" }
            },
            [
              _c("div", { staticClass: "bargain_top " }, [
                _c(
                  "div",
                  {
                    staticClass: "bargain_top_name ",
                    class: _vm.data.styleCode
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "name ",
                        class:
                          _vm.couponTitleCenter.indexOf(_vm.data.styleCode) >= 0
                            ? "flex_center"
                            : ""
                      },
                      [
                        _vm.couponTitleImg2.indexOf(_vm.data.styleCode) >= 0
                          ? _c("img", {
                              staticClass: "title_icon",
                              staticStyle: { "margin-right": "15px" },
                              attrs: {
                                src:
                                  "http://xl-cdn.xinglico.com/mika/pro/2020-11-04/d6f5cf6f45b14b6c9188dfa0a8cc32ea.png",
                                mode: ""
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.couponTitleImg1.indexOf(_vm.data.styleCode) >= 0
                          ? _c("img", {
                              staticClass: "title_icon",
                              staticStyle: { "margin-right": "15px" },
                              attrs: {
                                src:
                                  "http://xl-cdn.xinglico.com/mika/pro/2020-11-04/692b4a1d1feb4743bb3bf2b650af78e8.png",
                                mode: ""
                              }
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.data.moduleAttr.chunkName) +
                            "\n            "
                        ),
                        _vm.couponTitleImg1.indexOf(_vm.data.styleCode) >= 0
                          ? _c("img", {
                              staticClass: "title_icon",
                              staticStyle: { "margin-left": "15px" },
                              attrs: {
                                src:
                                  "http://xl-cdn.xinglico.com/mika/pro/2020-11-04/692b4a1d1feb4743bb3bf2b650af78e8.png",
                                mode: ""
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.couponTitleImg2.indexOf(_vm.data.styleCode) >= 0
                          ? _c("img", {
                              staticClass: "title_icon",
                              staticStyle: { "margin-left": "15px" },
                              attrs: {
                                src:
                                  "http://xl-cdn.xinglico.com/mika/pro/2020-11-04/80ffab96590241ba9b7b7f6a6861d3a9.png",
                                mode: ""
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.couponTitleMore.indexOf(_vm.data.styleCode) >= 0
                  ? _c("div", { staticClass: "bargain_top_more" }, [
                      _vm._v(" 查看更多 "),
                      _c("img", {
                        attrs: { src: "/static/img/index/more.png", mode: "" }
                      })
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "coupon5_box flex_around" },
                [
                  _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "item coupon_item" },
                      [
                        item.linkCode === "coupon" || item.linkCode === "goods"
                          ? _c("img", {
                              staticClass: "cover",
                              attrs: { src: _vm.imgSrc + item.cover }
                            })
                          : _c("img", {
                              staticClass: "cover",
                              attrs: {
                                src:
                                  "http://xl-cdn.xinglico.com/mika/pro/2020-11-04/812d5d8513594f129c17e4506527dd8d.png"
                              }
                            }),
                        _vm._v(" "),
                        _c("div", { staticClass: "item_box" }, [
                          _c("div", { staticClass: "name " }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  item.linkCode == "coupon" ||
                                    item.linkCode === "goods"
                                    ? item.name
                                    : "商品名称"
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "price" }, [
                            _vm._v(" 价格 ")
                          ])
                        ])
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.data.moduleAttr.dataItem.length % 3 == 1 ||
                  _vm.data.moduleAttr.dataItem.length % 3 == 2
                    ? _c("div", { staticClass: "item" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.moduleAttr.dataItem.length % 3 == 1
                    ? _c("div", { staticClass: "item" })
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm.couponFooterMore.indexOf(_vm.data.styleCode) >= 0
                ? _c(
                    "div",
                    {
                      staticClass: "coupon_bottom_more flex_center",
                      staticStyle: { "margin-top": "30px" }
                    },
                    [
                      _vm._v(" 查看更多 "),
                      _c("img", {
                        attrs: { src: "/static/img/index/more.png", mode: "" }
                      })
                    ]
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.card1.indexOf(_vm.data.styleCode) >= 0
        ? _c(
            "div",
            { staticClass: "column_box card1" },
            _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  class: _vm.data.moduleAttr.type
                },
                [
                  _c("img", {
                    staticClass: "cover",
                    style: { width: _vm.data.moduleAttr.coverWidth / 2 + "px" },
                    attrs: {
                      src: _vm.imgSrc + item.cardCover,
                      mode: "widthFix"
                    }
                  }),
                  _vm._v(" "),
                  _vm.data.styleCode == "card1001" ||
                  _vm.data.styleCode == "card1003" ||
                  _vm.data.styleCode == "card1005"
                    ? _c("div", { staticClass: "card_name" }, [
                        _vm._v(
                          "\n          " + _vm._s(item.cardName) + "\n        "
                        )
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.card2.indexOf(_vm.data.styleCode) >= 0
        ? _c(
            "div",
            {
              staticClass: "card2  flex_between",
              class: [
                _vm.data.styleCode,
                _vm.data.moduleAttr.padding ? "card_bg" : ""
              ]
            },
            _vm._l(_vm.data.moduleAttr.dataItem, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  class: _vm.data.moduleAttr.type,
                  style: {
                    width: _vm.data.moduleAttr.coverWidth / 2 + "px",
                    padding: _vm.data.moduleAttr.padding + "px"
                  }
                },
                [
                  _c("img", {
                    staticClass: "cover",
                    attrs: { src: _vm.imgSrc + item.cover, mode: "widthFix" }
                  }),
                  _vm._v(" "),
                  _vm.cardNoName.indexOf(_vm.data.styleCode) < 0
                    ? _c("div", { staticClass: "card_name" }, [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        )
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.card3.indexOf(_vm.data.styleCode) >= 0
        ? _c("div", { staticClass: "card3" })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "legouGoods1001"
        ? _c("div", [_c("legouGoods1001", { attrs: { data: _vm.data } })], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "legouGoods1004"
        ? _c("div", [_c("legouGoods1004", { attrs: { data: _vm.data } })], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "legouGoods1005" ||
      _vm.data.styleCode === "coupon_com1014" ||
      _vm.data.styleCode === "product_com1016"
        ? _c("div", [_c("legouGoods1005", { attrs: { data: _vm.data } })], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "legouGoods1006"
        ? _c("div", [_c("legouGoods1006", { attrs: { data: _vm.data } })], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "functionTem1000"
        ? _c("div", [_c("functionTem1000", { attrs: { data: _vm.data } })], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "functionTem1002"
        ? _c("div", [_c("functionTem1002", { attrs: { data: _vm.data } })], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "text1001"
        ? _c("div", [
            _c(
              "p",
              {
                style: {
                  color: _vm.data.moduleAttr.textColor,
                  fontSize: _vm.data.moduleAttr.textSize / 2 + "px",
                  fontWeight: _vm.data.moduleAttr.isBold == 1 ? "bold" : ""
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.data.moduleAttr.text) + "\n      "
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "text1004"
        ? _c("div", [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.data.moduleAttr.text) }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "text1003"
        ? _c("div", [
            _c(
              "p",
              {
                style: {
                  textAlign: "center",
                  color: _vm.data.moduleAttr.textColor,
                  fontSize: _vm.data.moduleAttr.textSize / 2 + "px",
                  fontWeight: _vm.data.moduleAttr.isBold == 1 ? "bold" : ""
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.data.moduleAttr.text) + "\n      "
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "image1021"
        ? _c("div", [_c("image1021", { attrs: { data: _vm.data } })], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "product_com1014"
        ? _c("div", [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: {
                src:
                  "https://xl-cdn.xinglico.com/mika/pro/2023-02-14/09f34cd547234cbd82823f86b989d482.png"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "lavantActivity1000"
        ? _c(
            "div",
            [_c("lavantActivity1000", { attrs: { data: _vm.data } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.styleCode === "product_com1015" ||
      _vm.data.styleCode === "coupon_com1013"
        ? _c("div", [_c("product_com1015", { attrs: { data: _vm.data } })], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.showTemImage.includes(_vm.data.styleCode)
        ? _c("div", [_c("video1001", { attrs: { data: _vm.data } })], 1)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }