"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _api = require("./api.js");

var _member = require("@/api/member.js");

var _api2 = require("../class/api.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default
  },
  data: function data() {
    return {
      vipList: [],
      classList: [],
      popType: '',
      couponShow: false,
      columns: [{
        label: '券编码',
        prop: 'couponCode',
        width: 100
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 100
      }, {
        label: '券类型',
        prop: 'couponType',
        width: 100
      }, {
        label: '面额/折扣',
        prop: 'disdenStr',
        width: 100
      }, {
        label: '有效期',
        prop: 'validStr',
        width: 100
      }],
      type: [{
        label: '实体商品',
        value: 1
      }, {
        label: '直冲商品',
        value: 2
      }, {
        label: '优惠券',
        value: 3
      }],
      rules: {
        openGifts: [{
          required: true,
          message: '请选择开卡礼信息'
        }],
        vipCode: [{
          required: true,
          message: '请选择会员类型'
        }],
        classId: [{
          required: true,
          message: '请选择会员开卡礼分类'
        }]
      },
      ruleForm: {
        id: '',
        vipCode: '',
        classId: '',
        openGifts: []
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _api2.listOpengiftClass)({
      classStatus: 1
    }).then(function (res) {
      _this.classList = res.data;
    });
    (0, _member.listVip)().then(function (res) {
      console.log(res);
      _this.vipList = res.data;
    });

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _api.getOpengift)(this.ruleForm.id).then(function (res) {
        _this.ruleForm.vipCode = res.data.vipCode;
        _this.ruleForm.classId = res.data.classId;
        _this.ruleForm.id = res.data.id;

        _this.ruleForm.openGifts.push({
          productCode: res.data.productCode,
          productHot: res.data.productHot,
          pushIndex: res.data.pushIndex,
          productSort: res.data.productSort,
          productType: res.data.productType,
          productStatus: res.data.productStatus,
          putNumber: res.data.putNumber,
          productName: res.data.productName || ''
        });
      });
    }
  },
  methods: {
    /*
    打开弹框
    */
    getAward: function getAward(e, index) {
      this.count = index;

      if (e === 3) {
        this.popType = 'coupon';
      } else {
        this.popType = 'goods';
      }

      this.$refs.coupon1.getList(this.popType, this.ruleForm.openGifts[index].productCode);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      if (this.popType === 'coupon') {
        this.ruleForm.openGifts[this.count].productCode = e.uuid;
        this.ruleForm.openGifts[this.count].productName = e.name;
      }

      if (this.popType === 'goods') {
        this.ruleForm.openGifts[this.count].productCode = e.productCode;
        this.ruleForm.openGifts[this.count].productName = e.productName;
      }

      this.couponShow = false;
      this.popType = '';
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var openGifts = {};

          _this2.ruleForm.openGifts.forEach(function (item) {
            openGifts = {
              id: _this2.ruleForm.id,
              productCode: item.productCode,
              productName: item.productName,
              productType: item.productType,
              putNumber: Number(item.putNumber),
              productSort: Number(item.productSort),
              productHot: item.productHot,
              productStatus: item.productStatus,
              pushIndex: item.pushIndex,
              vipCode: _this2.ruleForm.vipCode,
              classId: _this2.ruleForm.classId
            };
          });

          (0, _api.editOpengift)(openGifts).then(function (res) {
            _this2.$message({
              type: 'success',
              message: '编辑成功!'
            });

            _this2.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;