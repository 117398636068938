"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addRecenter = addRecenter;
exports.channelRecenter = channelRecenter;
exports.editRecenter = editRecenter;
exports.listRecenterPage = listRecenterPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*

领券中心

2、新增：/api/channelRecenter/addRecenter

3、编辑：/api/channelRecenter/editRecenter
      入参：{"centerId":"id","channelType":"渠道类型(1：乐购到家 2：星力乐购 3：MIKA积分商城 4：汇金星力城 5：乐品鲜活APP)","onlineType":"上线类型(1：周期 2：手动)","onlineStartTime":"周期(开始时间)","onlineEndTime":"周期(结束时间)","centerStatus":"状态(1：停用 2：启用)","couponCode":"券编码","putNumber":"投放数量","labelId":"labelName":"标签名称"}

4、查询明细：/api/channelRecenter/{centerId}
 */

/*
分页
入参：{"pageNo":"当前页","pageSize":"页条数"}
 */
function listRecenterPage(data) {
  return (0, _request.default)({
    url: '/api/channelRecenter/listRecenterPage',
    method: 'post',
    data: data
  });
}
/*
 新增
 入参：{"channelType":"渠道类型(1：乐购到家 2：星力乐购 3：MIKA积分商城 4：汇金星力城 5：乐品鲜活APP)",
 "onlineType":"上线类型(1：周期 2：手动)",
 "onlineStartTime":"周期(开始时间)",
 "onlineEndTime":"周期(结束时间)",
 "centerStatus":"状态(1：停用 2：启用)",

 "couponCode":"券编码",
 "putNumber":"投放数量",
 "labelId":"labelName":"标签名称"}
 */


function addRecenter(data) {
  return (0, _request.default)({
    url: '/api/channelRecenter/addRecenter',
    method: 'post',
    data: data
  });
}
/*
 编辑
 入参：{
   "centerId":"id",
   "channelType":"渠道类型(1：乐购到家 2：星力乐购 3：MIKA积分商城 4：汇金星力城 5：乐品鲜活APP)",
 "onlineType":"上线类型(1：周期 2：手动)",
 "onlineStartTime":"周期(开始时间)",
 "onlineEndTime":"周期(结束时间)",
 "centerStatus":"状态(1：停用 2：启用)",
 "couponCode":"券编码",
 "putNumber":"投放数量",
 "labelId":"labelName":"标签名称"}
 */


function editRecenter(data) {
  return (0, _request.default)({
    url: '/api/channelRecenter/editRecenter',
    method: 'post',
    data: data
  });
}
/*
查询明细
入参：centerId
 */


function channelRecenter(centerId) {
  return (0, _request.default)({
    url: '/api/channelRecenter/getRecenter/' + centerId,
    method: 'post'
  });
}