"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _user = require("@/api/user.js");

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _elementChinaAreaData = require("element-china-area-data");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      citys: _elementChinaAreaData.regionData,
      formRules: {
        productSpec: [{
          required: true,
          message: '请选择规格'
        }],
        provinceCode: [{
          required: true,
          message: '请选择地址'
        }],
        consigneeName: [{
          required: true,
          message: '请输入收货人'
        }],
        consigneePhone: [{
          required: true,
          message: '请输入收货人手机号'
        }],
        consigneeAddress: [{
          required: true,
          message: '请输入收货地址'
        }],
        deliveryWay: [{
          required: true,
          message: '请选择发货方式'
        }],
        storeCode: [{
          required: true,
          message: '请选择自提门店'
        }]
      },
      form: {
        "giftId": null,
        "uid": null,
        "deliveryWay": null,
        "productSpec": null,
        "storeCode": null,
        "storeName": null,
        "addressId": null,
        "logisticNo": null,
        "orderNo": null,
        "productType": null,
        "albumId": null,
        provinceCode: null,
        consigneeName: null,
        consigneePhone: null,
        consigneeAddress: null
      },
      qrCode: null,
      popShow: false,
      equityGoods: null,
      userRightGifts: [],
      specialLevelsUser: null,
      imgSrc: this.$store.state.user.imgSrc,
      tabTh: [{
        prop: 'giftCode',
        label: "礼品编码"
      }, {
        prop: 'giftName',
        label: "礼品名称"
      }, {
        prop: 'giftDesc',
        label: "礼品描述"
      }],
      rightId: null,
      levelId: null,
      uid: null,
      userRightId: null,

      /*
      礼品领取弹窗
      */
      isGiftPop: false,

      /*
      礼品信息
      */
      giftData: null,

      /*
      自提门店
      */
      storeCodes: [],

      /*
      多倍积分权益
      */
      userIntegralAbleDTO: null
    };
  },
  computed: {
    /*
    已领取数量
    */
    getGiftNum: function getGiftNum() {
      if (this.userRightGifts.length > 0) {
        return this.userRightGifts.filter(function (item) {
          return item.recived == 1;
        }).length;
      } else {
        return 0;
      }
    },

    /*
    确认需求数量
    */
    getAffrimNum: function getAffrimNum() {
      if (this.userRightGifts.length > 0) {
        return this.userRightGifts.filter(function (item) {
          return item.delegatedReceive == 1;
        }).length;
      } else {
        return 0;
      }
    },

    /*
    权益礼品列表
    */
    giftItems: function giftItems() {
      if (this.specialLevelsUser && this.specialLevelsUser.giftItems) {
        return this.specialLevelsUser.giftItems;
      } else {
        return [];
      }
    },

    /*
    多倍积分列表
    */
    getScoreList: function getScoreList() {
      if (this.specialLevelsUser && this.specialLevelsUser.rightItemType == 3 && this.giftItems.length > 0) {
        if (this.giftItems[0].giftCode == 'dayUnit') {
          return this.userIntegralAbleDTO.scoreDays || [];
        } else {
          return this.userIntegralAbleDTO.scoreOrders || [];
        }
      } else {
        return [];
      }
    },

    /*
     已经领取多倍积分的数据
     */
    alreadySelectChange: function alreadySelectChange() {
      var _this = this;

      if (this.getScoreList && this.getScoreList.length > 0) {
        return this.getScoreList.filter(function (item) {
          return item.received && item.refNo && item.refNo == _this.rightId && item.refLevelId == _this.levelId;
        });
      } else {
        return [];
      }
    }
  },
  mounted: function mounted() {
    var _this$$route$query = this.$route.query,
        uid = _this$$route$query.uid,
        rightId = _this$$route$query.rightId,
        levelId = _this$$route$query.levelId,
        userRightId = _this$$route$query.userRightId;
    this.uid = uid;
    this.rightId = rightId;
    this.levelId = levelId;
    this.userRightId = userRightId;
    this.getEquityGoods();
  },
  methods: {
    handleChange: function handleChange(e, selectedData) {
      var _self = this;

      this.$nextTick(function () {
        if (e) {
          var checkedNode = this.$refs["cascader"].getCheckedNodes();
          console.log(checkedNode[0].pathLabels[0]);
          _self.form.provinceCode = checkedNode[0].pathLabels[0];
          _self.form.consigneeAddress = checkedNode[0].pathLabels.join('');
        } else {
          _self.form.provinceCode = null;
        }

        this.$refs.giftForm.validateField('provinceCode');
      });
    },

    /*
    多倍积分选择
    */
    selectScore: function selectScore(row) {
      if (row.length > this.giftItems[0].num - this.alreadySelectChange.length) {
        this.$refs.scoreTable.toggleRowSelection(row[row.length - 1], false);
        this.$message.error("\u6700\u591A\u53EF\u9009\u62E9".concat(this.giftItems[0].num - this.alreadySelectChange.length, "\u4E2A"));
      }
    },
    isSelectDisabled: function isSelectDisabled(row) {
      return !row.received;
    },
    goCloseGift: function goCloseGift() {
      this.isGiftPop = false;
    },

    /*
     领取积分权益
     */
    goAffrimScoreGet: function goAffrimScoreGet() {
      var _this2 = this;

      var scoreList = this.$refs.scoreTable.store.states.selection;
      var affrimList = [];

      if (scoreList.length <= 0) {
        this.$message.error('请选择多倍积分订单');
        return;
      } else {
        affrimList = scoreList.map(function (item) {
          if (_this2.giftItems[0].giftCode === 'dayUnit') {
            return item.day;
          } else {
            return item.orderNo;
          }
        });
      }

      this.apiPost('/api/svip/right/proxyReceiveUserRightScore', {
        "rightId": this.rightId,
        "uid": this.uid,
        "receiveType": this.specialLevelsUser.rightItemType,
        "receiveIndex": affrimList,
        "receiveUnit": this.giftItems[0].giftCode,
        "levelId": this.levelId
      }).then(function (res) {
        _this2.getEquityGoods();

        _this2.$message({
          type: 'success',
          message: '领取成功!'
        });
      });
    },

    /*
    领取礼品权益
    */
    goAffrimGet: function goAffrimGet() {
      var _this3 = this;

      this.$refs.giftForm.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this3.form));
          data.buyType = 1;
          data.deductibleScore = 0;
          data.productCode = _this3.giftData.productCode;
          data.uid = _this3.uid;

          _this3.apiPost('/api/svip/right/proxyReceiveUserRightProduct', data).then(function (res) {
            _this3.getEquityGoods();

            _this3.isGiftPop = false;

            _this3.$message({
              type: 'success',
              message: '领取成功!'
            });
          });
        } else {
          return false;
        }
      });
    },

    /*
    确认需求
    */
    goAffrimGift: function goAffrimGift(row) {
      var _this4 = this;

      var data = {
        giftIds: row ? [row.id] : [],
        rightId: this.userRightId
      };
      this.apiPost('/api/svip/right/adminConfirmDelegateReceive', data).then(function (res) {
        _this4.getEquityGoods();

        _this4.$message({
          type: 'success',
          message: '确认需求成功!'
        });
      });
    },

    /*
    确认领取礼品
    */
    goHelpGetGift: function goHelpGetGift(row) {
      var _this5 = this;

      this.form.giftId = row.id;
      this.form.productNum = row.num;
      this.giftType = row.giftType;

      if (row.giftType === 3) {
        this.apiGet('/product/code/' + row.giftCode).then(function (res) {
          _this5.giftData = res.data;
          _this5.isGiftPop = true;
          _this5.storeCodes = res.data.storeCodes ? JSON.parse(res.data.storeCodes) : [];
        });
        return;
      }
      /* if (row.giftType === 2) {
         this.apiGet('/api/couponBag/getCouponBagById', {
          })
         return
       } */


      if (row.giftType === 1 || row.giftType === 2) {
        this.$confirm('确认领取该礼品, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this5.apiPost('/api/svip/right/proxyReceiveUserRightProduct', {
            giftId: row.id,
            moduleSource: 29,
            uid: _this5.uid
          }).then(function (res) {
            _this5.getEquityGoods();

            _this5.isGiftPop = false;

            _this5.$message({
              type: 'success',
              message: '领取成功!'
            });
          });
        }).catch(function () {
          _this5.$message({
            type: 'info',
            message: '已取消领取'
          });
        });
        return;
      }

      return;
      /*
       if (res.data.deliveryType == 0) {
      	this.type = 1
      }
      if (res.data.deliveryType == 1) {
      	this.type = 2
      }
      if (res.data.deliveryType == 2) {
      	this.type = 1
      }
      */

      this.apiPost('/api/svip/right/proxyReceiveUserRightProduct', {
        giftId: row.id,
        uid: this.uid,
        deliveryType: null
      });
    },
    closePop: function closePop() {
      this.popShow = false;
    },
    getCode: function getCode(scope) {
      var _this6 = this;

      var scene = "a=".concat(this.rightId, "&b=").concat(this.specialLevelsUser.levelId, "&c=").concat(this.userRightId);

      if (scope) {
        scene = scene + "&d=".concat(scope.id);
      }

      (0, _user.couponQRCode)({
        path: 'svip/member',
        scene: scene
      }).then(function (res) {
        _this6.qrCode = res.data;
        _this6.popShow = true;
      }).catch(function (res) {});
    },
    handleExpandChange: function handleExpandChange(row) {
      var _this7 = this;

      this.apiPost('/api/svip/right/listUnClaimedRightGift', {
        uid: row.uid,
        rightId: row.rightId,
        levelId: row.levelId
      }).then(function (res) {
        _this7.equityGoods = res.data;

        if (_this7.expand.includes(row.key)) {
          _this7.expand = [];
        } else {
          _this7.expand = [row.key];
        }
      });
    },
    getUserEquity: function getUserEquity(row, type) {
      var _this8 = this;

      this.apiGet('/api/svip/right/listUnClaimedRight/' + row.uid).then(function (res) {
        _this8.equityList = res.data;
        _this8.isEquityPop = true;
        _this8.equityData = row;
      });
    },
    getEquityGoods: function getEquityGoods(row) {
      var _this9 = this;

      this.apiPost('/api/svip/right/listUnClaimedRightGift', {
        uid: this.uid,
        rightId: this.rightId,
        levelId: this.levelId
      }).then(function (res) {
        _this9.equityGoods = res.data;
        _this9.userRightGifts = res.data.userRightGifts || [];
        _this9.specialLevelsUser = res.data.specialLevels.find(function (item) {
          return item.correntLevel;
        });
        _this9.userIntegralAbleDTO = res.data.userIntegralAbleDTO || {};
      });
    }
  }
};
exports.default = _default;