var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "openInvoice" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    用户管理\n    "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "has",
                    rawName: "v-has",
                    value: "sysUser:add",
                    expression: "'sysUser:add'"
                  }
                ],
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: { click: _vm.addDepartment }
              },
              [_vm._v("新增用户")]
            ),
            _vm._v(" "),
            _vm.setMenuPerms("exportUser:list")
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "success",
                      icon: "el-icon-download",
                      size: "mini",
                      loading: _vm.downloadLoading
                    },
                    on: { click: _vm.handleDownload }
                  },
                  [_vm._v("导出Excel")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-form",
            { attrs: { inline: "" } },
            [
              _c(
                "el-form-item",
                [
                  _c("select-tree", {
                    attrs: {
                      placeholder: "所属机构",
                      size: "mini",
                      options: _vm.memTree
                    },
                    on: { selected: _vm.deptIdChange },
                    model: {
                      value: _vm.obj.deptId,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "deptId", $$v)
                      },
                      expression: "obj.deptId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "input2",
                    attrs: { size: "mini", placeholder: "用户昵称" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.init({ limit: 10, page: 1 })
                      }
                    },
                    model: {
                      value: _vm.obj.username,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "username", $$v)
                      },
                      expression: "obj.username"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "input2",
                    attrs: { size: "mini", placeholder: "用户工号" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.init({ limit: 10, page: 1 })
                      }
                    },
                    model: {
                      value: _vm.obj.jobNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "jobNumber", $$v)
                      },
                      expression: "obj.jobNumber"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "input2",
                    attrs: { size: "mini", placeholder: "用户ID" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.init({ limit: 10, page: 1 })
                      }
                    },
                    model: {
                      value: _vm.obj.userId,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "userId", $$v)
                      },
                      expression: "obj.userId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "input2",
                    attrs: { size: "mini", placeholder: "手机号码" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.init({ limit: 10, page: 1 })
                      }
                    },
                    model: {
                      value: _vm.obj.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "mobile", $$v)
                      },
                      expression: "obj.mobile"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        size: "mini",
                        placeholder: "选择角色"
                      },
                      on: {
                        change: function($event) {
                          return _vm.init({ limit: 10, page: 1 })
                        }
                      },
                      model: {
                        value: _vm.obj.roleId,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "roleId", $$v)
                        },
                        expression: "obj.roleId"
                      }
                    },
                    _vm._l(_vm.roleArr, function(item) {
                      return _c("el-option", {
                        key: item.roleId,
                        attrs: { label: item.roleName, value: item.roleId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini"
                      },
                      on: {
                        click: function($event) {
                          return _vm.init({ limit: 10, page: 1 })
                        }
                      }
                    },
                    [_vm._v("查询")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        size: "mini",
                        icon: "el-icon-refresh"
                      },
                      on: {
                        click: function($event) {
                          return _vm.remove()
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                size: "small",
                data: _vm.tableData,
                "row-key": "id",
                height: "600",
                border: "",
                "tree-props": {
                  children: "childNodes",
                  hasChildren: "hasChildren"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: { width: "70", align: "center", label: "序号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s((_vm.pageNo - 1) * 10 + scope.$index + 1) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "deptName", label: "所属机构" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "brandRemark", label: "所属品牌" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "username",
                  label: "用户昵称",
                  "min-width": "120"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "userId",
                  label: "ID",
                  align: "center",
                  "min-width": "50"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "mobile",
                  label: "手机号码",
                  align: "center",
                  "min-width": "100"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "jobNumber",
                  label: "工号",
                  align: "center",
                  "min-width": "80"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", align: "center", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.status === 1,
                                expression: "scope.row.status===1"
                              }
                            ]
                          },
                          [_vm._v("启用")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.status === -1,
                                expression: "scope.row.status===-1"
                              }
                            ]
                          },
                          [_vm._v("停用")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "所属角色",
                  align: "center",
                  "min-width": "140"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "table_td_roleIds" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.roleIds) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  "min-width": "150",
                  label: "创建时间",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  "min-width": "140",
                  fixed: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "table_td_btn" },
                          [
                            _c(
                              "app-link",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: "sysUser:edit",
                                    expression: "'sysUser:edit'"
                                  }
                                ],
                                attrs: {
                                  to:
                                    "/system/user/userDea?userId=" +
                                    scope.row.userId
                                }
                              },
                              [_vm._v("\n              编辑\n            ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "table_td_btn",
                                on: {
                                  click: function($event) {
                                    return _vm.getCode(scope.row)
                                  }
                                }
                              },
                              [_vm._v("\n              拉新码\n            ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.wPage(
                                      "/system/user/userRule?userId=" +
                                        scope.row.userId
                                    )
                                  }
                                }
                              },
                              [_vm._v("对应资源")]
                            ),
                            _vm._v(" "),
                            scope.row.account !== "admin"
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value: "sysUser:resetPwd",
                                        expression: "'sysUser:resetPwd'"
                                      }
                                    ],
                                    staticClass: "resetPwd",
                                    on: {
                                      click: function($event) {
                                        return _vm.resetPwd(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("重置密码")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0"
              }
            ],
            attrs: {
              total: _vm.total,
              page: _vm.obj.pageNo,
              limit: _vm.obj.pageSize
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.obj, "pageNo", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.obj, "pageSize", $event)
              },
              pagination: _vm.init
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              url: _vm.url,
              "qr-code": _vm.qrCode,
              "coupon-u-uid": _vm.couponUUid
            },
            on: { closePop: _vm.closePop }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }