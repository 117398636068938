"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var icons = [{
  class: "el-icon-delete"
}, {
  class: "el-icon-delete-solid"
}, {
  class: "el-icon-s-tools"
}, {
  class: "el-icon-setting"
}, {
  class: "el-icon-user-solid"
}, {
  class: "el-icon-user"
}, {
  class: "el-icon-phone"
}, {
  class: "el-icon-phone-outline"
}, {
  class: "el-icon-more"
}, {
  class: "el-icon-more-outline"
}, {
  class: "el-icon-star-on"
}, {
  class: "el-icon-star-off"
}, {
  class: "el-icon-s-goods"
}, {
  class: "el-icon-goods"
}, {
  class: "el-icon-warning"
}, {
  class: "el-icon-warning-outline"
}, {
  class: "el-icon-question"
}, {
  class: "el-icon-info"
}, {
  class: "el-icon-remove"
}, {
  class: "el-icon-circle-plus"
}, {
  class: "el-icon-success"
}, {
  class: "el-icon-error"
}, {
  class: "el-icon-zoom-in"
}, {
  class: "el-icon-zoom-out"
}, {
  class: "el-icon-remove-outline"
}, {
  class: "el-icon-circle-plus-outline"
}, {
  class: "el-icon-circle-check"
}, {
  class: "el-icon-circle-close"
}, {
  class: "el-icon-s-help"
}, {
  class: "el-icon-help"
}, {
  class: "el-icon-minus"
}, {
  class: "el-icon-plus"
}, {
  class: "el-icon-check"
}, {
  class: "el-icon-close"
}, {
  class: "el-icon-picture"
}, {
  class: "el-icon-picture-outline"
}, {
  class: "el-icon-picture-outline-round"
}, {
  class: "el-icon-upload"
}, {
  class: "el-icon-upload2"
}, {
  class: "el-icon-download"
}, {
  class: "el-icon-camera-solid"
}, {
  class: "el-icon-camera"
}, {
  class: "el-icon-video-camera-solid"
}, {
  class: "el-icon-video-camera"
}, {
  class: "el-icon-message-solid"
}, {
  class: "el-icon-bell"
}, {
  class: "el-icon-s-cooperation"
}, {
  class: "el-icon-s-order"
}, {
  class: "el-icon-s-platform"
}, {
  class: "el-icon-s-fold"
}, {
  class: "el-icon-s-unfold"
}, {
  class: "el-icon-s-operation"
}, {
  class: "el-icon-s-promotion"
}, {
  class: "el-icon-s-home"
}, {
  class: "el-icon-s-release"
}, {
  class: "el-icon-s-ticket"
}, {
  class: "el-icon-s-management"
}, {
  class: "el-icon-s-open"
}, {
  class: "el-icon-s-shop"
}, {
  class: "el-icon-s-marketing"
}, {
  class: "el-icon-s-flag"
}, {
  class: "el-icon-s-comment"
}, {
  class: "el-icon-s-finance"
}, {
  class: "el-icon-s-claim"
}, {
  class: "el-icon-s-custom"
}, {
  class: "el-icon-s-opportunity"
}, {
  class: "el-icon-s-data"
}, {
  class: "el-icon-s-check"
}, {
  class: "el-icon-s-grid"
}, {
  class: "el-icon-menu"
}, {
  class: "el-icon-share"
}, {
  class: "el-icon-d-caret"
}, {
  class: "el-icon-caret-left"
}, {
  class: "el-icon-caret-right"
}, {
  class: "el-icon-caret-bottom"
}, {
  class: "el-icon-caret-top"
}, {
  class: "el-icon-bottom-left"
}, {
  class: "el-icon-bottom-right"
}, {
  class: "el-icon-back"
}, {
  class: "el-icon-right"
}, {
  class: "el-icon-bottom"
}, {
  class: "el-icon-top"
}, {
  class: "el-icon-top-left"
}, {
  class: "el-icon-top-right"
}, {
  class: "el-icon-arrow-left"
}, {
  class: "el-icon-arrow-right"
}, {
  class: "el-icon-arrow-down"
}, {
  class: "el-icon-arrow-up"
}, {
  class: "el-icon-d-arrow-left"
}, {
  class: "el-icon-d-arrow-right"
}, {
  class: "el-icon-video-pause"
}, {
  class: "el-icon-video-play"
}, {
  class: "el-icon-refresh"
}, {
  class: "el-icon-refresh-right"
}, {
  class: "el-icon-rank"
}, {
  class: "el-icon-date"
}, {
  class: "el-icon-edit-outline"
}, {
  class: "el-icon-s-flag"
}, {
  class: "el-icon-folder"
}, {
  class: "el-icon-folder-opened"
}, {
  class: "el-icon-folder-remove"
}, {
  class: "el-icon-folder-delete"
}, {
  class: "el-icon-folder-checked"
}, {
  class: "el-icon-tickets"
}, {
  class: "el-icon-document-remove"
}, {
  class: "el-icon-document-delete"
}, {
  class: "el-icon-document-copy"
}, {
  class: "el-icon-document-checked"
}, {
  class: "el-icon-document"
}, {
  class: "el-icon-document-add"
}, {
  class: "el-icon-printer"
}, {
  class: "el-icon-paperclip"
}, {
  class: "el-icon-takeaway-box"
}, {
  class: "el-icon-search"
}, {
  class: "el-icon-monitor"
}, {
  class: "el-icon-attract"
}, {
  class: "el-icon-mobile"
}, {
  class: "el-icon-scissors"
}, {
  class: "el-icon-umbrella"
}, {
  class: "el-icon-headset"
}, {
  class: "el-icon-brush"
}, {
  class: "el-icon-mouse"
}, {
  class: "el-icon-coordinate"
}, {
  class: "el-icon-magic-stick"
}, {
  class: "el-icon-reading"
}, {
  class: "el-icon-data-line"
}, {
  class: "el-icon-data-board"
}, {
  class: "el-icon-pie-chart"
}, {
  class: "el-icon-data-analysis"
}, {
  class: "el-icon-collection-tag"
}, {
  class: "el-icon-film"
}, {
  class: "el-icon-suitcase"
}, {
  class: "el-icon-suitcase-1"
}, {
  class: "el-icon-receiving"
}, {
  class: "el-icon-collection"
}, {
  class: "el-icon-files"
}, {
  class: "el-icon-notebook-1"
}, {
  class: "el-icon-notebook-2"
}, {
  class: "el-icon-toilet-paper"
}, {
  class: "el-icon-office-building"
}, {
  class: "el-icon-school"
}, {
  class: "el-icon-table-lamp"
}, {
  class: "el-icon-house"
}, {
  class: "el-icon-no-smoking"
}, {
  class: "el-icon-smoking"
}, {
  class: "el-icon-shopping-cart-full"
}, {
  class: "el-icon-shopping-cart-1"
}, {
  class: "el-icon-shopping-cart-2"
}, {
  class: "el-icon-shopping-bag-1"
}, {
  class: "el-icon-shopping-bag-2"
}, {
  class: "el-icon-sold-out"
}, {
  class: "el-icon-sell"
}, {
  class: "el-icon-present"
}, {
  class: "el-icon-box"
}, {
  class: "el-icon-bank-card"
}, {
  class: "el-icon-money"
}, {
  class: "el-icon-coin"
}, {
  class: "el-icon-wallet"
}, {
  class: "el-icon-discount"
}, {
  class: "el-icon-price-tag"
}, {
  class: "el-icon-news"
}, {
  class: "el-icon-guide"
}, {
  class: "el-icon-male"
}, {
  class: "el-icon-female"
}, {
  class: "el-icon-thumb"
}, {
  class: "el-icon-cpu"
}, {
  class: "el-icon-link"
}, {
  class: "el-icon-connection"
}, {
  class: "el-icon-open"
}, {
  class: "el-icon-turn-off"
}, {
  class: "el-icon-set-up"
}, {
  class: "el-icon-chat-round"
}, {
  class: "el-icon-chat-line-round"
}, {
  class: "el-icon-chat-square"
}, {
  class: "el-icon-chat-dot-round"
}, {
  class: "el-icon-chat-dot-square"
}, {
  class: "el-icon-chat-line-square"
}, {
  class: "el-icon-message"
}, {
  class: "el-icon-postcard"
}, {
  class: "el-icon-position"
}, {
  class: "el-icon-turn-off-microphone"
}, {
  class: "el-icon-microphone"
}, {
  class: "el-icon-close-notification"
}, {
  class: "el-icon-bangzhu"
}, {
  class: "el-icon-time"
}, {
  class: "el-icon-odometer"
}, {
  class: "el-icon-crop"
}, {
  class: "el-icon-aim"
}, {
  class: "el-icon-switch-button"
}, {
  class: "el-icon-full-screen"
}, {
  class: "el-icon-copy-document"
}, {
  class: "el-icon-mic"
}, {
  class: "el-icon-stopwatch"
}, {
  class: "el-icon-medal-1"
}, {
  class: "el-icon-medal"
}, {
  class: "el-icon-trophy"
}, {
  class: "el-icon-trophy-1"
}, {
  class: "el-icon-first-aid-kit"
}, {
  class: "el-icon-discover"
}, {
  class: "el-icon-place"
}, {
  class: "el-icon-location"
}, {
  class: "el-icon-location-outline"
}, {
  class: "el-icon-location-information"
}, {
  class: "el-icon-add-location"
}, {
  class: "el-icon-delete-location"
}, {
  class: "el-icon-map-location"
}, {
  class: "el-icon-alarm-clock"
}, {
  class: "el-icon-timer"
}, {
  class: "el-icon-watch-1"
}, {
  class: "el-icon-watch"
}, {
  class: "el-icon-lock"
}, {
  class: "el-icon-unlock"
}, {
  class: "el-icon-key"
}, {
  class: "el-icon-service"
}, {
  class: "el-icon-mobile-phone"
}, {
  class: "el-icon-bicycle"
}, {
  class: "el-icon-truck"
}, {
  class: "el-icon-ship"
}, {
  class: "el-icon-basketball"
}, {
  class: "el-icon-football"
}, {
  class: "el-icon-soccer"
}, {
  class: "el-icon-baseball"
}, {
  class: "el-icon-wind-power"
}, {
  class: "el-icon-light-rain"
}, {
  class: "el-icon-lightning"
}, {
  class: "el-icon-heavy-rain"
}, {
  class: "el-icon-sunrise"
}, {
  class: "el-icon-sunrise-1"
}, {
  class: "el-icon-sunset"
}, {
  class: "el-icon-sunny"
}, {
  class: "el-icon-cloudy"
}, {
  class: "el-icon-partly-cloudy"
}, {
  class: "el-icon-cloudy-and-sunny"
}, {
  class: "el-icon-moon"
}, {
  class: "el-icon-moon-night"
}, {
  class: "el-icon-dish"
}, {
  class: "el-icon-dish-1"
}, {
  class: "el-icon-food"
}, {
  class: "el-icon-chicken"
}, {
  class: "el-icon-fork-spoon"
}, {
  class: "el-icon-knife-fork"
}, {
  class: "el-icon-burger"
}, {
  class: "el-icon-tableware"
}, {
  class: "el-icon-sugar"
}, {
  class: "el-icon-dessert"
}, {
  class: "el-icon-ice-cream"
}, {
  class: "el-icon-hot-water"
}, {
  class: "el-icon-water-cup"
}, {
  class: "el-icon-coffee-cup"
}, {
  class: "el-icon-cold-drink"
}, {
  class: "el-icon-goblet"
}, {
  class: "el-icon-goblet-full"
}, {
  class: "el-icon-goblet-square"
}, {
  class: "el-icon-goblet-square-full"
}, {
  class: "el-icon-refrigerator"
}, {
  class: "el-icon-grape"
}, {
  class: "el-icon-watermelon"
}, {
  class: "el-icon-cherry"
}, {
  class: "el-icon-apple"
}, {
  class: "el-icon-pear"
}, {
  class: "el-icon-orange"
}, {
  class: "el-icon-coffee"
}, {
  class: "el-icon-ice-tea"
}, {
  class: "el-icon-ice-drink"
}, {
  class: "el-icon-milk-tea"
}, {
  class: "el-icon-potato-strips"
}, {
  class: "el-icon-lollipop"
}, {
  class: "el-icon-ice-cream-square"
}, {
  class: "el-icon-ice-cream-round"
}];
var _default = icons;
exports.default = _default;