var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      ref: "popover",
      attrs: { placement: "bottom-start", trigger: "click" },
      on: { show: _vm.onShowPopover, hide: _vm.onHidePopover }
    },
    [
      _c("el-tree", {
        ref: "tree",
        staticClass: "select-tree",
        style: "min-width: " + _vm.treeWidth,
        attrs: {
          size: "mini",
          "highlight-current": "",
          data: _vm.data,
          props: _vm.props,
          "filter-node-method": _vm.filterNode,
          "default-expand-all": false
        },
        on: { "node-click": _vm.onClickNode }
      }),
      _vm._v(" "),
      _c("el-input", {
        ref: "input",
        class: { rotate: _vm.showStatus },
        style: "width: " + _vm.width + "px",
        attrs: {
          slot: "reference",
          size: _vm.size,
          clearable: "",
          "suffix-icon": "el-icon-arrow-down",
          placeholder: _vm.placeholder
        },
        slot: "reference",
        model: {
          value: _vm.labelModel,
          callback: function($$v) {
            _vm.labelModel = $$v
          },
          expression: "labelModel"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }