var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    浦发理财客户信息表\n    "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "开放网点"
                  },
                  model: {
                    value: _vm.requestData.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "storeId", $$v)
                    },
                    expression: "requestData.storeId"
                  }
                },
                _vm._l(_vm.storeList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.storeName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "客户姓名" },
                model: {
                  value: _vm.requestData.userName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "userName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.userName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "客户手机号" },
                model: {
                  value: _vm.requestData.userMobile,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "userMobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.userMobile"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "网点负责人" },
                model: {
                  value: _vm.requestData.contactPeople,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "contactPeople",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.contactPeople"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "负责人手机号" },
                model: {
                  value: _vm.requestData.contactPhone,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "contactPhone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.contactPhone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  size: "mini",
                  type: "datetimerange",
                  "range-separator": "——",
                  format: "yyyy 年 MM 月 dd 日",
                  "value-format": "yyyy-MM-dd  HH:mm:ss",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.getList("search")
                }
              }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "mini", icon: "el-icon-refresh" },
              on: {
                click: function($event) {
                  return _vm.getList("remove")
                }
              }
            },
            [_vm._v("重置")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "storeStatus",
              label: "状态",
              align: "center",
              width: "80"
            },
            slot: "storeStatus",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.storeStatus === 0
                      ? _c("span", [_vm._v("停用")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.storeStatus === 1
                      ? _c("span", [_vm._v("启用")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              align: "center",
              fixed: "right",
              width: "100"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "app-link",
                      {
                        staticStyle: { color: "rgb(24, 144, 255)" },
                        attrs: { to: "/pufa/addBranch?id=" + scope.row.id }
                      },
                      [_vm._v("\n          编辑\n        ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }