"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    WTable: _index.default
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      urlList: _selectUrl.default.urlList,
      appIdArr: _selectUrl.default.appIdArr,
      downloadLoading: false,
      requestData: {
        productType: '',
        productCode: '',
        vipCode: '',
        classId: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        slot: 'popupImg',
        label: '弹框图',
        prop: 'popupImg',
        width: 100
      }, {
        label: '弹框名称',
        prop: 'popupName',
        width: 100
      }, {
        slot: 'labelContent',
        label: '显示人群标签',
        prop: 'labelContent',
        width: 100
      }, {
        slot: 'popupRate',
        label: '弹出频率',
        prop: 'popupRate',
        width: 100
      }, {
        slot: 'contentType',
        label: '关联类容',
        prop: 'contentType',
        width: 100
      },
      /* {
      slot: 'required',
      label: '显示到',
      prop: 'required',
      width: 50
      }, */
      {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'popupStatus',
        label: '状态',
        prop: 'popupStatus',
        width: 80
      }, {
        label: '显示顺序(排序越大越靠前)',
        prop: 'popupSort',
        width: 80
      }, {
        slot: 'operate'
      }]
    };
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {},
  methods: {
    handleDownload: function handleDownload() {
      var _this = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];
        var data = {};

        _this.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this.requestData).forEach(function (key) {
          if (_this.requestData[key]) {
            data[key] = _this.requestData[key];
          }
        });
        data.pageSize = _this.requestData.total;
        data.pageNo = 1;
        (0, _api.listPopupPage)(data).then(function (res) {
          list = res.data.items;

          var data = _this.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '同城生活商品'
          });
          _this.downloadLoading = false;
        }).catch(function (res) {
          _this.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this2 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.removeObj(this.requestData);
      }

      (0, _api.listPopupPage)(this.requestData).then(function (res) {
        _this2.tableData = res.data.items;
        _this2.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;