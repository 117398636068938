"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _mem = require("@/api/mem.js");

var _user = require("@/api/user.js");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default,
    addLoad: _addLoad.default,
    qrCodePop: _qrCodePop.default,
    selectAward: _radioItem.default
  },
  data: function data() {
    return {
      couponShow: false,
      popType: '',
      couponUUid: '',
      popShow: false,
      // 小程序码弹框
      qrCode: '',
      // 小程序码
      url: '',
      // 小程序链接
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/phone.xlsx"),
      downloadGoodsUrl: "".concat(process.env.BASE_URL, "xlsx/couponPop.xlsx"),
      addMobile: null,
      addGoods: null,
      userLabelList: [],
      storeList: [],
      formType: null,
      classList: [],
      parameterItem: null,
      formData: [{
        type: "divider",
        title: "基础信息"
      }, {
        label: '活动名称：',
        type: 'input',
        placeholder: '请输入活动名称',
        value: 'activityName'
      }, {
        slot: 'labelContent',
        placeholder: '请选择人群',
        value: 'labelContent'
      }, {
        slot: 'labelContentNo',
        placeholder: '请选择人群',
        value: 'labelContentNo',
        isRules: false
      }, {
        label: '活动时间：',
        type: 'timeRange',
        placeholder: '请选择活动时间',
        value1: 'startTime',
        value2: 'endTime'
      }, {
        label: '状态：',
        type: 'radio',
        placeholder: '请选择状态',
        value: 'status',
        selectList: [{
          label: '启用',
          value: 1
        }, {
          label: '禁用',
          value: 2
        }]
      }, {
        type: "divider",
        title: "赠送规则"
      }, {
        label: '奖励类型：',
        type: 'radio',
        placeholder: '请选择奖奖励类型',
        value: 'type',
        selectList: [{
          label: '积分',
          value: 1
        }, {
          label: '礼包',
          value: 2
        }]
      }, {
        slot: 'giveType',
        label: '赠送形式：',
        type: 'radio',
        placeholder: '请选择赠送形式',
        value: 'giveType',
        selectList: [{
          label: '完成后获得',
          value: 1
        }, {
          label: '单次点亮获得',
          value: 2
        }]
      }, {
        slot: 'sendType',
        label: '礼包类型：',
        placeholder: '请选礼包类型',
        value: 'sendType'
      }, {
        slot: 'scoreNum',
        label: '完成赠送积分：',
        type: 'inputNumber',
        placeholder: '请输入完成赠送积分',
        value: 'scoreNum'
      }, {
        slot: 'couponUuid',
        label: '完成赠送优惠券：',
        type: 'input',
        placeholder: '请输入完成赠送优惠券',
        value: 'couponUuid'
      }, {
        slot: 'giveScore',
        label: '赠送基础积分：',
        type: 'inputNumber',
        placeholder: '请输入赠送基础积分',
        value: 'giveScore',
        end: "积分"
      }, {
        slot: 'giveRule',
        label: '赠送规则：',
        type: 'radio',
        placeholder: '请选择赠送规则',
        value: 'giveRule',
        selectList: [{
          label: '依次递增',
          value: 1
        }, {
          label: '倍数递增',
          value: 2
        }]
      }, {
        label: '递增分数或倍数：',
        type: 'inputNumber',
        slot: 'num',
        placeholder: '请输入递增分数或倍数',
        value: 'num'
      }, {
        label: '扫码范围：',
        type: "inputNumber",
        placeholder: '请输入扫码范围',
        value: 'scanRange',
        end: '米'
      }, {
        //积分退还类型（1，年度退还 2，活动截止退还 3，活动截止后x天退还
        label: '积分退还类型：',
        type: 'radio',
        placeholder: '请选择积分退还类型',
        value: 'returnType',
        selectList: [{
          label: '年度退还',
          value: 1
        }, {
          label: '活动截止退还',
          value: 2
        }, {
          label: '活动截止后x天退还',
          value: 3
        }]
      }, {
        slot: 'returnDays'
      }, {
        type: "divider",
        title: "参与门店"
      },
      /* {
         label: '显示点位：',
         type: 'radio',
         placeholder: '请选择是否显示点位',
         value: 'isShow',
         selectList: [{
             label: '显示',
             value: 1
           },
           {
             label: '不显示',
             value: 2
           }
         ]
       }, */
      {
        slot: 'pointList',
        placeholder: '请添加门店',
        value: 'pointList'
      }, {
        type: "divider",
        title: "图文信息"
      }, {
        label: '背景图片：',
        type: 'img',
        placeholder: '请上传背景图片',
        value: 'backgroundImage',
        remark: "宽750，高度自适应"
      }, {
        label: '活动规则：',
        type: 'tinymce',
        placeholder: '请输入活动规则',
        value: 'activityRule'
      }],
      pointIndex: '',
      parameIndex: '',
      ruleForm: {
        pointList: [],
        id: null,
        type: null,
        sendType: null,
        giveType: null,
        couponUuid: '',
        couponName: ''
      },
      rules: {
        storeCode: [{
          required: true,
          message: '请选择门店'
        }],
        pointName: [{
          required: true,
          message: '请输入点位名称'
        }],
        longitude: [{
          required: true,
          message: '请输入经度'
        }],
        latitude: [{
          required: true,
          message: '请输入纬度'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        address: [{
          required: true,
          message: '请输入地址'
        }],
        pointPic: [{
          required: true,
          message: '请上传点位图片'
        }],
        pointParamList: [{
          required: true,
          message: '请添加点位'
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
      _this.userLabelList = [{
        id: 0,
        labelName: '所有会员'
      }].concat((0, _toConsumableArray2.default)(res.data));
    });
    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000,
      storeStatus: 2
    }).then(function (res) {
      console.log(res);
      _this.storeList = res.data;
    });
    /* this.apiPost('/api/smsTask/listSmsCategoryPage', {
       pageNo: 1,
       pageSize: 100
     }).then((res) => {
       this.classList = res.data.records
     }) */
  },
  mounted: function mounted() {
    var _this2 = this;

    this.formType = this.$route.query.type;
    this.ruleForm.id = this.$route.query.id || '';

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/scoreGive/getScoreGiveById/' + this.ruleForm.id).then(function (res) {
        delete res.data.operateTime;
        delete res.data.operateUser;

        if (res.data.giveType == 1) {
          if (res.data.couponUuid && res.data.scoreNum) {
            res.data.sendType = 3;
          } else if (res.data.couponUuid) {
            res.data.sendType = 2;
            res.data.scoreNum = null;
            res.data.couponName = res.data.couponName || '';
          } else if (res.data.scoreNum) {
            res.data.sendType = 1;
            res.data.couponUuid = null;
            res.data.couponName = null;
          }
        } else {
          res.data.couponUuid = null;
          res.data.scoreNum = null;
          res.data.couponName = null;
        }

        _this2.$nextTick(function () {
          this.ruleForm = res.data;
        });
      });
    }
  },
  methods: {
    goScrollIntoView: function goScrollIntoView(prop) {
      var dom = this.$refs[prop];
      console.log(dom);

      if (Array.isArray(dom)) {
        dom[0].$el.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        });
      } else {
        dom.$el.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        });
      }
    },
    setCoupon: function setCoupon(e) {
      if (this.pointIndex < 0) {
        this.ruleForm.couponUuid = e.uuid;
        this.ruleForm.couponName = e.name;
        this.$nextTick(function () {
          this.$refs.wForm.goValidateField('couponUuid');
        });
      } else {
        this.ruleForm.pointList[this.pointIndex].pointParamList[this.parameIndex].couponUuid = e.uuid;
        this.ruleForm.pointList[this.pointIndex].pointParamList[this.parameIndex].couponName = e.name;
      }

      this.couponShow = false;
    },
    goSelectItemCoupon: function goSelectItemCoupon(type, index, itemIndex) {
      this.pointIndex = index;
      this.parameIndex = itemIndex;
      this.popType = type;
      this.$refs.coupon1.getList(this.popType, this.ruleForm.pointList[index].pointParamList[itemIndex].couponUuid);
      this.couponShow = true;
    },
    goSelectCoupon: function goSelectCoupon(type) {
      this.pointIndex = -1;
      this.parameIndex = -1;
      this.popType = type;
      this.$refs.coupon1.getList(this.popType, this.ruleForm.couponUuid);
      this.couponShow = true;
    },
    getWxCode: function getWxCode(scope) {
      var _this3 = this;

      this.couponUUid = scope.pointName;
      this.url = 'activity/giveScore/giveScore?id=' + this.ruleForm.id;
      (0, _user.couponQRCode)({
        path: 'activity/giveScore/giveScore',
        scene: "".concat(this.ruleForm.id, "/").concat(scope.id)
      }).then(function (res) {
        _this3.openLoading().close();

        _this3.qrCode = res.data;
        _this3.popShow = true;
      }).catch(function (res) {
        _this3.openLoading().close();
      });
    },
    setItemCover: function setItemCover(e, index, itemIndex) {
      this.$set(this.ruleForm.pointList[index].pointParamList[itemIndex], 'pointPic', e);
    },
    delGrade: function delGrade(row, index) {
      this.ruleForm.pointList[index].pointParamList.splice(index, 1);
    },
    delPointItem: function delPointItem(index) {
      this.ruleForm.pointList.splice(index, 1);
    },
    addPointItem: function addPointItem(index) {
      this.ruleForm.pointList[index].pointParamList.push({
        pointName: null,
        longitude: undefined,
        latitude: undefined,
        status: 1,
        address: null,
        pointPic: null,
        isShow: 1,
        couponUuid: null,
        couponName: null,
        scoreNum: undefined
      });
    },
    addStore: function addStore() {
      this.ruleForm.pointList.push({
        storeCode: null,
        pointParamList: [{
          pointName: null,
          longitude: undefined,
          latitude: undefined,
          status: 1,
          address: null,
          pointPic: null,
          isShow: 1,
          couponUuid: null,
          couponName: null,
          scoreNum: undefined
        }]
      });
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      if (this.ruleForm.storeRange == 1) {
        this.ruleForm.storeCode = 0;
      }

      var apiUrl = this.ruleForm.id && this.formType != 'copy' ? '/api/scoreGive/editScoreGive' : '/api/scoreGive/addScoreGive';
      var requestData = JSON.parse(JSON.stringify(this.ruleForm));

      if (this.ruleForm.sendType == 1) {
        requestData.couponUuid = null;
      }

      if (this.ruleForm.sendType == 2) {
        requestData.scoreNum = null;
      }

      this.apiPost(apiUrl, requestData).then(function (res) {
        _this4.$router.go(-1);

        _this4.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;