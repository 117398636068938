var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-activity-form" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("要客等级")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "primary", size: "mini" },
          on: { click: _vm.add }
        },
        [_vm._v("\n    添加要客等级\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "tableForm",
          attrs: { size: "mini", model: _vm.tableForm, rules: _vm.rules }
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                size: "mini",
                data: _vm.tableForm.tableData,
                border: "",
                "header-cell-class-name": "table_header_cell"
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "等级名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex-h-center",
                            attrs: {
                              prop: "tableData." + scope.$index + ".name",
                              rules: _vm.rules.name
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: scope.row.name,
                                callback: function($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "name",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "scope.row.name"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "充值金额", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "flex-h-center",
                                attrs: {
                                  prop:
                                    "tableData." +
                                    scope.$index +
                                    ".rechargeAmount",
                                  rules: _vm.rules.rechargeAmount
                                }
                              },
                              [
                                _c("el-input-number", {
                                  staticStyle: {
                                    width: "160px",
                                    padding: "0 10px"
                                  },
                                  attrs: {
                                    min: 0,
                                    controls: false,
                                    precision: 0
                                  },
                                  model: {
                                    value: scope.row.rechargeAmount,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "rechargeAmount",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "scope.row.rechargeAmount"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "margin-top": "26px" } },
                              [_vm._v("元")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "form_remark" }, [
                          _vm._v(
                            "\n            充值满，送一年" +
                              _vm._s(scope.row.name) +
                              "lavant会员\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "单笔消费满", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "flex-h-center",
                                attrs: {
                                  prop:
                                    "tableData." +
                                    scope.$index +
                                    ".singleConsume",
                                  rules: _vm.rules.singleConsume
                                }
                              },
                              [
                                _c("el-input-number", {
                                  staticStyle: {
                                    width: "160px",
                                    padding: "0 10px"
                                  },
                                  attrs: {
                                    min: 0,
                                    controls: false,
                                    precision: 0
                                  },
                                  model: {
                                    value: scope.row.singleConsume,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "singleConsume",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "scope.row.singleConsume"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "margin-top": "26px" } },
                              [_vm._v("元")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "form_remark" }, [
                          _vm._v(
                            "\n            单笔消费满，送一年" +
                              _vm._s(scope.row.name) +
                              "lavant会员\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "累计消费金额", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "flex-h-center",
                                attrs: {
                                  prop:
                                    "tableData." +
                                    scope.$index +
                                    ".cumulativeConsumption",
                                  rules: _vm.rules.cumulativeConsumption
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { placeholder: "请输入内容" },
                                    model: {
                                      value: scope.row.cumulativeConsumption,
                                      callback: function($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "cumulativeConsumption",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "scope.row.cumulativeConsumption"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "append" }, [
                                      _vm._v("元")
                                    ])
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "卡面", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex-h-center",
                            attrs: {
                              prop: "tableData." + scope.$index + ".remark"
                            }
                          },
                          [
                            _c("add-load", {
                              staticClass: "img_list",
                              attrs: {
                                width: 100,
                                height: 50,
                                cover: scope.row.remark
                              },
                              on: {
                                setCover: function($event) {
                                  return _vm.setCover(
                                    $event,
                                    scope.$index,
                                    "remark"
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "logo", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex-h-center",
                            attrs: {
                              prop: "tableData." + scope.$index + ".icon"
                            }
                          },
                          [
                            _c("add-load", {
                              staticClass: "img_list",
                              attrs: {
                                width: 100,
                                height: 30,
                                cover: scope.row.icon
                              },
                              on: {
                                setCover: function($event) {
                                  return _vm.setCover(
                                    $event,
                                    scope.$index,
                                    "icon"
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "有效期", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex-h-center",
                            attrs: {
                              prop:
                                "tableData." + scope.$index + ".validPeriod",
                              rules: _vm.rules.validPeriod
                            }
                          },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "请输入内容" },
                                model: {
                                  value: scope.row.validPeriod,
                                  callback: function($$v) {
                                    _vm.$set(
                                      scope.row,
                                      "validPeriod",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "scope.row.validPeriod"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("天")
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认删除此要客等级？" },
                            on: {
                              confirm: function($event) {
                                return _vm.del(scope.row, scope.$index)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "danger",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("\n              删除\n            ")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "150px", "margin-top": "30px" },
              attrs: { type: "primary" },
              on: { click: _vm.submitForm }
            },
            [_vm._v("\n      保存等级\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pay-methods" },
            [
              _c("h3", [_vm._v("消费额度配置：")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "30px" },
                  attrs: { label: "参与的支付方式", "label-width": "150px" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "list" },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.payModels,
                            callback: function($$v) {
                              _vm.payModels = $$v
                            },
                            expression: "payModels"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "el-checkbox-item",
                              staticStyle: {
                                "max-height": "500px",
                                overflow: "auto"
                              }
                            },
                            _vm._l(_vm.payMethodsOptions, function(
                              pm,
                              pmIndex
                            ) {
                              return _c("el-checkbox", {
                                key: pmIndex,
                                attrs: { label: pm }
                              })
                            }),
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "黑名单：", "label-width": "150px" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: {
                        "align-items": "center",
                        "margin-bottom": "10px"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            "line-height": "1"
                          },
                          attrs: {
                            href: _vm.templateDownload.url,
                            download: _vm.templateDownload.name
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "success",
                                icon: "el-icon-download"
                              }
                            },
                            [_vm._v(_vm._s(_vm.templateDownload.btn))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("upload-excel-component", {
                        attrs: {
                          type: "btn",
                          "btn-text": "批量导入",
                          "on-success": _vm.handleUploadSuccess,
                          "before-upload": _vm.handleBeforeUpload
                        }
                      }),
                      _vm._v(" "),
                      _vm.blackList.length > 0
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { margin: "0 20px" },
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-download"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleDownload()
                                }
                              }
                            },
                            [_vm._v("导出黑名单")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入内容" },
                          model: {
                            value: _vm.oneMobile,
                            callback: function($$v) {
                              _vm.oneMobile = $$v
                            },
                            expression: "oneMobile"
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.goAddOneMobile }
                                },
                                [_vm._v("添加单个号码")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-card",
                    { staticClass: "box-card" },
                    _vm._l(_vm.blackList, function(item, index) {
                      return _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            cursor: "pointer"
                          },
                          on: {
                            click: function($event) {
                              return _vm.goDelOneMobile(item, index)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("多个手机号使用英文逗号“,”隔开")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px", "margin-top": "30px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.submitPayGoods }
                },
                [_vm._v("\n        保存消费额度配置\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }