"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _index2 = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _api = require("./api.js");

var _user = require("@/api/user.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    wTable: _index.default,
    qrCodePop: _qrCodePop.default,
    UploadExcelComponent: _index2.default
  },
  data: function data() {
    return {
      templateDownload: {
        url: "".concat(process.env.BASE_URL, "xlsx/phone.xlsx"),
        name: '白名单导入模板.xlsx',
        btn: '下载模板'
      },
      formData: [{
        slot: 'whiteList'
      }],
      ruleForm: {
        whiteList: null
      },
      rules: {},
      popShow: false,
      isOrderQrcede: null,
      qrCode: null,
      orderRequest: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        orderNo: null
      },
      orderList: [],
      downloadLoading: false,
      orderTab: [{
        label: '订单号',
        prop: 'orderNo',
        width: 12
      }, {
        label: '兑换码',
        prop: 'exchangeCode',
        width: 12
      }, {
        label: '购买人',
        prop: 'buyUserMobile',
        width: 8
      }, {
        label: '购买人时间',
        prop: 'buyTime',
        width: 12
      }, {
        slot: 'receiveStatus',
        label: '状态(0:未领取，1:已领取)',
        prop: 'receiveStatus'
      }, {
        label: '兑换人',
        prop: 'exchangeUserName',
        width: 8
      }, {
        label: '兑换人手机号',
        prop: 'exchangeUserMobile',
        width: 8
      }, {
        label: '兑换人UID',
        prop: 'exchangeUserId',
        width: 8
      }, {
        label: '备注',
        prop: 'facilityCode',
        width: 8
      }, {
        label: '兑换时间',
        prop: 'exchangeTime',
        width: 10
      }, {
        slot: 'operate'
      }],
      errWhiteList: []
    };
  },
  created: function created() {
    this.orderRequest.orderNo = this.$route.query.orderNo;
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      if (this.orderRequest.orderNo) {
        this.apiGet('/api/pay/listWhitePhoneListByPayMemberOrder', {
          orderNo: this.orderRequest.orderNo
        }).then(function (res) {
          _this.ruleForm.whiteList = res.data ? res.data.join(',') : '';
        });
        this.getOrderList();
      }
    },
    orderDownload: function orderDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var data = {};

        _this2.orderTab.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this2.orderRequest).forEach(function (key) {
          if (_this2.orderRequest[key]) {
            data[key] = _this2.orderRequest[key];
          }
        });
        data.pageSize = _this2.orderRequest.total;
        data.pageNo = 1;
        (0, _api.listGroupCodePage)(data).then(function (res) {
          _this2.getHandleDownload(tHeader, filterVal, res.data.page.items, "".concat(_this2.orderRequest.orderNo, "\u5151\u6362\u7801\u8BE6\u7EC6"));

          _this2.downloadLoading = false;
        }).catch(function (res) {
          _this2.downloadLoading = false;
        });
      });
    },
    removeOrderWhite: function removeOrderWhite() {
      var _this3 = this;

      this.apiGet('/api/pay/removeWhitePhoneListByPayGroupOrder', {
        orderNo: this.orderRequest.orderNo
      }).then(function (res) {
        _this3.init();

        _this3.$message({
          message: '白名单清除成功,将不限制用户领取',
          type: 'success'
        });
      });
    },
    submitForm: function submitForm() {
      var _this4 = this;

      var whiteList = [];

      if (this.ruleForm.whiteList) {
        whiteList = this.ruleForm.whiteList.trim().split(',');
        this.errWhiteList = whiteList.filter(function (item) {
          return !_this4.validatorTel(item);
        });
        if (this.errWhiteList.length > 0) return this.$message.error('手机号码错误，更正后再提交');
      }

      this.apiPost('/api/pay/importWhitePhoneList4PayMemberOrder', {
        orderNo: this.orderRequest.orderNo,
        phoneList: whiteList
      }).then(function (res) {
        _this4.init();

        _this4.$message({
          message: '白名单修改成功',
          type: 'success'
        });
      });
    },
    validatorTel: function validatorTel(content) {
      var reg = /^1[3456789]\d{9}$/;
      return reg.test(content);
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    handleUploadSuccess: function handleUploadSuccess(data) {
      var _this5 = this;

      var whiteList = [];
      data.results.forEach(function (col) {
        if (col['手机号']) {
          whiteList.push(String(col['手机号']));
        }
      });
      this.errWhiteList = whiteList.filter(function (item) {
        return !_this5.validatorTel(item);
      }); // const successWhiteList = whiteList.filter((item) => { return this.validatorTel(item) })

      this.ruleForm.whiteList = whiteList.join(',');
    },

    /*
                获取团购订单二维码
              */
    getBindQrcode: function getBindQrcode(code, type) {
      var _this6 = this;

      /* // this.popShow = true
      this.apiPost('/api/fileManage/couponQRCode', {
        path: 'vip/zengKa/getCard',
        scene: `${type}=${code}`
      }) */
      (0, _user.couponQRCode)({
        path: 'vip/zengKa/getCard',
        scene: "".concat(type, "=").concat(code)
      }).then(function (res) {
        _this6.popShow = true;
        _this6.isOrderQrcede = type;
        _this6.qrCode = res.data;
      });
    },
    setOrder: function setOrder(pageObj) {
      this.orderRequest.pageNo = pageObj.pageNo;
      this.orderRequest.pageSize = pageObj.pageSize;
      this.getOrderList();
    },
    getOrderList: function getOrderList() {
      var _this7 = this;

      (0, _api.listGroupCodePage)(this.orderRequest).then(function (res) {
        console.log(res);
        _this7.isOrder = true;
        _this7.orderList = res.data.page.items;
        _this7.orderRequest.total = res.data.page.totalNum;
      });
    }
  }
};
exports.default = _default;