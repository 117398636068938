"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _api = require("./api.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default,
    selectAward: _radioItem.default
  },
  data: function data() {
    var _self = this;

    return {
      couponShow: false,
      popType: '',
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.validStartTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      tabGiftType: {
        1: {
          placeholder: '请输入优惠券数量',
          label: '份'
        },
        2: {
          placeholder: '请输入券包数量',
          label: '份'
        },
        3: {
          placeholder: '请输入商品数量',
          label: '份'
        },
        4: {
          placeholder: '请输入返利比例',
          label: '%'
        },
        5: {
          placeholder: '请输入购物卡金额',
          label: '元'
        },
        6: {
          placeholder: '请输入购物卡金额',
          label: '元'
        }
      },
      giftType: (0, _defineProperty2.default)({
        1: '选择优惠券',
        2: '选择券包',
        3: '选择商品',
        4: '输入返利比例',
        5: '选择购物卡'
      }, "5", '多倍积分'),
      type: [{
        label: '优惠券',
        value: 1
      }, {
        label: '券包',
        value: 2
      }, {
        label: '商品',
        value: 3
      }, {
        label: '积分返利',
        value: 4
      }, {
        label: '购物卡',
        value: 5
      }, {
        label: '多倍积分',
        value: 6
      }],
      rules: {
        /*
           * 是否副卡共享 -1 否 1-是
          private Integer secondCardShare;
            * 限制领取次数
          private Integer limitNum;
           */
        secondCardShare: [{
          required: true,
          message: '请选择是否副卡共享'
        }],
        limitNum: [{
          required: true,
          message: '请输入限制领取次数'
        }],
        rightDesc: [{
          required: true,
          message: '请输入描述'
        }],
        rightName: [{
          required: true,
          message: '请输入名称'
        }],
        validStartTime: [{
          required: true,
          message: '请选择开始时间'
        }],
        validEndTime: [{
          required: true,
          message: '请选择结束时间'
        }],
        rightShort: [{
          required: true,
          message: '请输入简称'
        }],
        rightImage: [{
          required: true,
          message: '请上传主图'
        }],
        landscapeMainPic: [{
          required: true,
          message: '请上传横屏主图'
        }],
        verticalMainPic: [{
          required: true,
          message: '请上传竖屏主图'
        }],
        rightLockIco: [{
          required: true,
          message: '请上传锁定图'
        }],
        rigthUnlockIco: [{
          required: true,
          message: '请上传解锁图'
        }],
        rightDetail: [{
          required: true,
          message: '请输入介绍'
        }],
        reciveMode: [{
          required: true,
          message: '请选择领取方式'
        }],
        choiceNum: [{
          required: true,
          message: '请输入限领个数'
        }],
        rightGifts: [{
          required: true,
          message: '请添加礼品'
        }],
        remark: [{
          required: true,
          message: '请输入节省金额'
        }]
      },
      ruleForm: {
        rightName: null,
        rightShort: null,
        rightDesc: null,
        rightImage: '',
        landscapeMainPic: null,
        verticalMainPic: null,
        rightLockIco: '',
        rigthUnlockIco: '',
        validStartTime: null,
        validEndTime: null,
        reciveMode: null,
        rightGifts: [],
        rightDetail: null,
        choiceNum: null,
        remark: null,
        secondCardShare: null,
        limitNum: null
        /*
           * 是否副卡共享 -1 否 1-是
          private Integer secondCardShare;
            * 限制领取次数
          private Integer limitNum;
           */

      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id) {
      (0, _api.getData)(this.ruleForm.id).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    setGiftCover: function setGiftCover(e, prop, index) {
      this.ruleForm.rightGifts[index][prop] = e;
    },
    setNumber: function setNumber(e, index) {
      var _this2 = this;

      this.$nextTick(function () {
        if (e > _this2.ruleForm.rightGifts.length) {
          _this2.ruleForm.choiceNum = _this2.ruleForm.rightGifts.length;
        }
      });
    },
    typeChange: function typeChange(e, index) {
      this.ruleForm.rightGifts.giftCode = null;
      this.ruleForm.rightGifts[index].giftName = null;
      this.ruleForm.rightGifts[index].giftImage = null;
      this.ruleForm.rightGifts[index].giftDesc = null;
      this.ruleForm.rightGifts[index].num = null;
    },

    /*
                删除奖励项
                */
    delAward: function delAward(index) {
      var _this3 = this;

      this.ruleForm.rightGifts.splice(index, 1);
      this.$nextTick(function () {
        if (_this3.ruleForm.choiceNum > _this3.ruleForm.rightGifts.length) {
          _this3.ruleForm.choiceNum = _this3.ruleForm.rightGifts.length;
        }
      });
    },

    /*
                打开弹框
                */
    getAward: function getAward(e, row) {
      console.log('row==>', row);
      this.rowIndex = row;
      /*
        0: '选择优惠券',
        1: '选择商品',
        2: '输入返利比例',
        3: '选择购物卡',
        4: '选择券包'
        */

      var giftType = {
        1: 'coupon',
        2: 'couponBag',
        3: 'goods',
        5: 'card'
      };
      this.popType = giftType[e];
      this.$refs.coupon1.getList(this.popType, this.ruleForm.rightGifts[row].giftCode);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      if (this.popType === 'coupon') {
        this.ruleForm.rightGifts[this.rowIndex].giftCode = e.uuid;
        this.ruleForm.rightGifts[this.rowIndex].giftName = e.name;
        this.ruleForm.rightGifts[this.rowIndex].giftImage = e.mainImage;
        this.ruleForm.rightGifts[this.rowIndex].giftDesc = e.descrition;
      }

      if (this.popType === 'couponBag') {
        this.ruleForm.rightGifts[this.rowIndex].giftCode = e.uuid;
        this.ruleForm.rightGifts[this.rowIndex].giftName = e.name;
        this.ruleForm.rightGifts[this.rowIndex].giftImage = e.tableImage;
        this.ruleForm.rightGifts[this.rowIndex].giftDesc = e.description;
      }

      if (this.popType === 'goods') {
        this.ruleForm.rightGifts[this.rowIndex].giftCode = e.productCode;
        this.ruleForm.rightGifts[this.rowIndex].giftName = e.productName;
        this.ruleForm.rightGifts[this.rowIndex].giftImage = e.imageUrl;
        this.ruleForm.rightGifts[this.rowIndex].giftDesc = e.productInstr;
      }

      if (this.popType === 'card') {
        this.ruleForm.rightGifts[this.rowIndex].giftCode = e.uuid;
        this.ruleForm.rightGifts[this.rowIndex].giftName = e.name;
        this.ruleForm.rightGifts[this.rowIndex].giftImage = e.cover;
        this.ruleForm.rightGifts[this.rowIndex].giftDesc = e.description;
      }

      this.couponShow = false;
      this.popType = '';
    },
    addGiftItems: function addGiftItems(index) {
      this.ruleForm.rightGifts.push({
        giftType: null,
        giftCode: null,
        giftName: null,
        giftImage: null,
        giftDesc: null,
        publicCol: null,
        num: null
      });
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          if (_this4.ruleForm.id) {
            (0, _api.editData)(_this4.ruleForm).then(function (res) {
              _this4.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this4.$router.go(-1);
            });
          } else {
            (0, _api.addData)(_this4.ruleForm).then(function (res) {
              _this4.$router.go(-1);

              _this4.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          var data = Object.keys(object);
          var dom = _this4.$refs[data[0]];
          dom.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
        }
      });
    }
  }
};
exports.default = _default;