var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("广告")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "listTable" },
      [
        _c("table-list", {
          attrs: {
            "dea-path": _vm.deaPath,
            type: true,
            "table-data": _vm.list,
            "tab-th": _vm.tabTh,
            total: _vm.total,
            "page-no": _vm.pageNo,
            "page-size": _vm.pageSize,
            btn: _vm.btn,
            "btn-type": _vm.btnType,
            operation: _vm.operation
          },
          on: { getList: _vm.getList, setBtn: _vm.setBtn }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }