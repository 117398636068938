var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addsImg" },
    [
      _c(
        "el-upload",
        {
          class: { hideUpload: _vm.disabled },
          attrs: {
            disabled: _vm.disabled,
            limit: _vm.maxLength,
            accept:
              ".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF",
            "list-type": "picture-card",
            "file-list": _vm.fileList,
            action: _vm.action,
            headers: _vm.headers,
            "auto-upload": false,
            multiple: "",
            "on-error": _vm.onError,
            "on-change": function(file, fileList) {
              return _vm.beforeAvatarUpload(file, fileList)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "file",
              fn: function(ref) {
                var file = ref.file
                return _c(
                  "div",
                  { staticClass: "fileItem", staticStyle: { height: "100%" } },
                  [
                    _c("img", {
                      staticClass: "el-upload-list__item-thumbnail",
                      staticStyle: { "max-height": "100%" },
                      attrs: { src: file.url, alt: "" }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "el-upload-list__item-actions" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-preview",
                            on: {
                              click: function($event) {
                                return _vm.handlePictureCardPreview(file)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-zoom-in" })]
                        ),
                        _vm._v(" "),
                        !_vm.disabled
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-delete",
                                on: {
                                  click: function($event) {
                                    return _vm.handleRemove(file)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fileItem_dif" },
                      [
                        _vm.disabled
                          ? _c("span", { staticStyle: { color: "#FFFFFF" } }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.showSelectCover == file.url
                                      ? "主图"
                                      : ""
                                  ) +
                                  "\n        "
                              )
                            ])
                          : _c(
                              "el-radio",
                              {
                                attrs: { label: file.url },
                                on: { change: _vm.changeDifUrl },
                                model: {
                                  value: _vm.showSelectCover,
                                  callback: function($$v) {
                                    _vm.showSelectCover = $$v
                                  },
                                  expression: "showSelectCover"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#FFFFFF" } },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.showSelectCover == file.url
                                            ? "默认主图"
                                            : "设置为主图"
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                      ],
                      1
                    )
                  ]
                )
              }
            }
          ])
        },
        [
          _c("i", {
            staticClass: "el-icon-plus",
            attrs: { slot: "default" },
            slot: "default"
          }),
          _vm._v(" "),
          _c("div", { attrs: { slot: "fileList" }, slot: "fileList" }, [
            _vm._v(_vm._s(_vm.fileList))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { width: "40%", top: "50px", visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }