var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "appliDea" }, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("确认新增活动")]),
    _vm._v(" "),
    _c("p", { staticClass: "appli_tit" }, [_vm._v("\n    活动详情\n  ")]),
    _vm._v(" "),
    _c("div", { staticClass: "appli_box" }, [
      _c("ul", [
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动名称：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.activityObj.name))
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动时间：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(
              _vm._s(_vm.activityObj.time[0]) +
                "至" +
                _vm._s(_vm.activityObj.time[1])
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动地点：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.activityObj.address))
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动BANNEr：")]),
          _vm._v(" "),
          _c("div", { staticClass: "li_banner" }, [
            _c("img", {
              attrs: { src: _vm.imgUrl + _vm.activityObj.bannerPath }
            })
          ])
        ]),
        _vm._v(" "),
        _vm.activityObj.editType == 1
          ? _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("活动详情内容：")]),
              _vm._v(" "),
              _c("div", {
                staticClass: "li_content",
                domProps: { innerHTML: _vm._s(_vm.content) }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.activityObj.editType == 2
          ? _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("跳转链接：")]),
              _vm._v(" "),
              _c("p", { staticClass: "li_txt" }, [
                _vm._v(_vm._s(_vm.activityObj.urlPath))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动上线时间：")]),
          _vm._v(" "),
          _vm.activityObj.onlineType === 1
            ? _c("p", { staticClass: "li_txt" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.activityObj.beginTime) +
                    "至" +
                    _vm._s(_vm.activityObj.endTime) +
                    "\n        "
                )
              ])
            : _c("p", { staticClass: "li_txt" }, [
                _vm._v("\n          立即上线（需手动下线）\n        ")
              ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer_btn" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.goBack } },
          [_vm._v("返回修改")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.next } },
          [_vm._v("确认提交")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }