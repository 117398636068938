"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var integralRouter = {
  path: '/integral',
  component: _layout.default,
  redirect: '/integral/index',
  name: 'Integral',
  meta: {
    title: '积分',
    icon: 'nested'
  },
  children: [{
    path: 'adjust',
    redirect: '/integral/adjust/tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/adjust/index.vue'));
      });
    },
    name: 'Adjust',
    meta: {
      title: '积分调整 '
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/integral/adjust/tab.vue'));
        });
      },
      name: 'AdjustTab',
      meta: {
        title: '积分调整'
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/integral/adjust/list.vue'));
        });
      },
      name: 'AdjustList',
      meta: {
        title: '积分调整记录',
        keepAlive: true,
        activeMenu: '/integral/adjust/tab'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/integral/adjust/add.vue'));
        });
      },
      name: 'AdjustAdd',
      meta: {
        title: '批量补录',
        activeMenu: '/integral/adjust/tab'
      }
    }, {
      path: 'gb',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/integral/adjust/gb.vue'));
        });
      },
      name: 'AdjustGb',
      meta: {
        title: '逐条补录',
        activeMenu: '/integral/adjust/tab'
      }
    }]
  }, {
    path: 'editScoreSet',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/scoreList.vue'));
      });
    },
    name: 'EditScoreSet',
    meta: {
      title: '积分基础设置',
      keepAlive: true
    }
  }, {
    path: 'setScore',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/editScoreSet.vue'));
      });
    },
    name: 'SetScore',
    meta: {
      title: '查询积分设置 ',
      activeMenu: '/integral/editScoreSet'
    }
  }, {
    path: 'integralTab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/integralTab.vue'));
      });
    },
    name: 'IntegralTab',
    meta: {
      title: '积分策略 '
    }
  }, {
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral'));
      });
    },
    name: 'Index',
    meta: {
      title: '基础积分策略设置 ',
      keepAlive: true
    }
  }, {
    path: 'addIntegral',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/addIntegral.vue'));
      });
    },
    name: 'AddIntegral',
    meta: {
      title: '新增/编辑基础积分策略 ',
      activeMenu: '/integral/index'
    }
  }, {
    path: 'integralDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/integralDea.vue'));
      });
    },
    name: 'IntegralDea',
    meta: {
      title: '基础积分策略详情',
      activeMenu: '/integral/index'
    }
  }, {
    path: 'addConditionIntegral',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/addConditionIntegral.vue'));
      });
    },
    name: 'AddConditionIntegral',
    meta: {
      title: '新增条件积分策略',
      activeMenu: '/integral/conditionIntegral'
    }
  }, {
    path: 'conditionIntegral',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/conditionIntegral.vue'));
      });
    },
    name: 'ConditionIntegral',
    meta: {
      title: '条件积分策略'
    }
  }, {
    path: 'addSpecial',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/addSpecial.vue'));
      });
    },
    name: 'AddSpecial',
    meta: {
      title: '特殊积分策略',
      activeMenu: '/integral/specialList'
    }
  }, {
    path: 'specialList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/specialList.vue'));
      });
    },
    name: 'SpecialList',
    meta: {
      title: '特殊积分策略',
      keepAlive: true
    }
  }, {
    path: 'listScore',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/goods/listScore.vue'));
      });
    },
    name: 'ListScore',
    meta: {
      title: '商品基础积分',
      keepAlive: true
    }
  }, {
    path: 'listScoreSpecial',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/goods/listScoreSpecial.vue'));
      });
    },
    name: 'ListScoreSpecial',
    meta: {
      title: '商品特殊积分策略',
      keepAlive: true
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/order.vue'));
      });
    },
    name: 'integralOrder',
    meta: {
      title: '积分变动记录',
      // keepAlive: true,
      pageList: 'items',
      pageTotal: 'totalNum',
      api: '/api/userScore/listUserScorePage',
      columns: [{
        prop: 'documentNo',
        label: '相关单号',
        width: 80
      }, {
        prop: 'storeName',
        label: '门店名称',
        width: 80
      }, {
        prop: 'terminalCode',
        label: '终端设备号',
        width: 40
      }, {
        prop: 'userMobile',
        label: '会员手机号',
        width: 50
      }, {
        prop: 'changeAmount',
        label: '消费金额',
        sortable: true,
        width: 50
      }, {
        prop: 'discountPrice',
        label: '优惠金额',
        sortable: true,
        width: 50
      }, {
        prop: 'discountScore',
        label: '优惠积分',
        sortable: true,
        width: 50
      }, {
        prop: 'changeScore',
        label: '积分变动',
        sortable: true,
        width: 50
      }, {
        prop: 'changeTypeName',
        label: '变动类型',
        width: 50
      }, {
        prop: 'changeTime',
        label: '变动时间',
        width: 80
      }, {
        prop: 'oprUname',
        label: '操作人',
        width: 50
      }, {
        prop: 'changeRemark',
        label: '备注',
        width: 80,
        align: 'left'
      }]
    }
  }, {
    path: 'give',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/give/list.vue'));
      });
    },
    name: 'integralGive',
    meta: {
      title: '积分赠送活动',
      addTxt: "新增活动",
      keepAlive: true,
      addUrl: "/integral/giveAdd" //pageList:null
      // method:'get'

    }
  }, {
    path: 'giveAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/give/add.vue'));
      });
    },
    name: 'integralGiveAdd',
    meta: {
      title: '新增积分赠送活动'
    }
  }, {
    path: 'pointDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/give/pointDea.vue'));
      });
    },
    name: 'integralGivepointDea',
    isOneRequest: 'no',
    meta: {
      title: '产出详情'
    }
  }, {
    path: 'giveOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/integral/give/order.vue'));
      });
    },
    name: 'integralGivegiveOrder',
    meta: {
      title: '领取记录',
      pageList: 'items',
      pageTotal: 'totalNum',
      keepAlive: true,
      isOneRequest: "no"
    }
  }]
};
var _default = integralRouter;
exports.default = _default;