"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listOrderPayMember = listOrderPayMember;
exports.listPromoOrder = listPromoOrder;
exports.recommendPage = recommendPage;
exports.relieveViceCard = relieveViceCard;
exports.selectMainUserBindLogList = selectMainUserBindLogList;

var _request = _interopRequireDefault(require("@/utils/request"));

function recommendPage(data) {
  return (0, _request.default)({
    url: '/api/order/recommend/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function selectMainUserBindLogList(data) {
  return (0, _request.default)({
    url: '/api/mainUser/selectMainUserBindLogList',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
1、付费会员订单：/api/order/listOrderPayMember
{"pageNo":"当前页","pageSize":"页条数","":"buyUtphone":"会员手机号","beginPayTime":"开始时间","endPayTime":"结束时间","orderType":"MIKA  PLUS(9)  365会员(10)"}

2、推广人统计：/api/order/listPromoOrder
{"pageNo":"当前页","pageSize":"页条数","beginPayTime":"开始时间","endPayTime":"结束时间","deptId":"机构id","buyUname":"推广人姓名","buyUtphone":"贵广人手机号"}

 */

/*
 */


function listOrderPayMember(data) {
  return (0, _request.default)({
    url: '/api/order/listOrderPayMember',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
/api/vip/refundMaoTaiVip?buyUid=xxx& orderId = xxx  茅台MIKA  PLUS退费
 */


function listPromoOrder(data) {
  return (0, _request.default)({
    url: '/api/order/listPromoOrder',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function relieveViceCard(data) {
  return (0, _request.default)({
    url: '/api/mainUser/relieveViceCard',
    method: 'get',
    loading: 'loading',
    params: data
  });
}