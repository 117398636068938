var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "memberQuery" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("修改会员信息")]),
      _vm._v(" "),
      _c("div", { staticClass: "memberQuery_top" }, [
        _c(
          "div",
          { staticClass: "memberQuery_top_item" },
          [
            _c(
              "el-form",
              { attrs: { "label-width": "100px", size: "small" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "会员卡号：", prop: "realname" } },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "memberQuery_top_item_input",
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.memeberCode,
                          callback: function($$v) {
                            _vm.memeberCode = $$v
                          },
                          expression: "memeberCode"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "110px" },
                            attrs: { slot: "prepend", placeholder: "请选择" },
                            slot: "prepend",
                            model: {
                              value: _vm.type,
                              callback: function($$v) {
                                _vm.type = $$v
                              },
                              expression: "type"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "手机号", value: "1" }
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "输入卡号", value: "3" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "9px 30px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.goSearch }
                      },
                      [_vm._v("查询")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "9px 30px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.goMid }
                      },
                      [_vm._v("刷卡")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("userInfo", {
        attrs: {
          user: _vm.user,
          lavant: _vm.lavant,
          status: _vm.userForm.status
        }
      }),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("p", { staticClass: "memberQuery_user_tit" }, [_vm._v("身份资料：")]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { overflow: "auto" } },
        [
          _c(
            "el-form",
            {
              ref: "formUser",
              attrs: {
                model: _vm.userForm,
                rules: _vm.userRules,
                "label-width": "100px",
                size: "small",
                disabled: !_vm.user.uid
              }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "真实姓名：", prop: "realname" } },
                    [
                      _c("el-input", {
                        staticClass: "maxWidth",
                        attrs: {
                          disabled: !_vm.setMenuPerms("memberUpdate:name"),
                          placeholder: "请输入姓名"
                        },
                        model: {
                          value: _vm.userForm.realname,
                          callback: function($$v) {
                            _vm.$set(_vm.userForm, "realname", $$v)
                          },
                          expression: "userForm.realname"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号：", prop: "idNo" } },
                    [
                      _c("el-input", {
                        staticClass: "maxWidth",
                        attrs: {
                          disabled: !_vm.setMenuPerms("memberUpdate:cardNo"),
                          placeholder: "请输入身份证号"
                        },
                        on: { blur: _vm.idNoChange },
                        model: {
                          value: _vm.userForm.idNo,
                          callback: function($$v) {
                            _vm.$set(_vm.userForm, "idNo", $$v)
                          },
                          expression: "userForm.idNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "性　　别：", prop: "sex" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "maxWidth",
                          attrs: {
                            disabled: !_vm.setMenuPerms("memberUpdate:sex"),
                            placeholder: "请选择性别"
                          },
                          model: {
                            value: _vm.userForm.sex,
                            callback: function($$v) {
                              _vm.$set(_vm.userForm, "sex", $$v)
                            },
                            expression: "userForm.sex"
                          }
                        },
                        [
                          _c("el-option", { attrs: { label: "男", value: 1 } }),
                          _vm._v(" "),
                          _c("el-option", { attrs: { label: "女", value: 2 } }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "未知", value: 0 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "出生日期：", prop: "birthday" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "maxWidth",
                        attrs: {
                          disabled: !_vm.setMenuPerms("memberUpdate:birthday"),
                          "value-format": "yyyy-MM-dd",
                          type: "date",
                          placeholder: "选择日期"
                        },
                        model: {
                          value: _vm.userForm.birthday,
                          callback: function($$v) {
                            _vm.$set(_vm.userForm, "birthday", $$v)
                          },
                          expression: "userForm.birthday"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "民　　族：", prop: "nation" } },
                    [
                      _c("el-input", {
                        staticClass: "maxWidth",
                        attrs: {
                          disabled: !_vm.setMenuPerms("memberUpdate:nation"),
                          placeholder: "请输入民族"
                        },
                        model: {
                          value: _vm.userForm.nation,
                          callback: function($$v) {
                            _vm.$set(_vm.userForm, "nation", $$v)
                          },
                          expression: "userForm.nation"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员状态：", prop: "status" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled: !_vm.setMenuPerms("memberUpdate:status"),
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949"
                        },
                        on: { change: _vm.setStatus },
                        model: {
                          value: _vm.userForm.status == 1,
                          callback: function($$v) {
                            _vm.$set(_vm.userForm, "status==1", $$v)
                          },
                          expression: "userForm.status==1"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          style: {
                            color:
                              _vm.userForm.status == 1 ? "#13ce66" : "#ff4949",
                            marginLeft: "10px"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.userForm.status == 1 ? "正常" : "封禁")
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        disabled: !_vm.user.uid
                      },
                      on: { click: _vm.setUser }
                    },
                    [_vm._v("保存身份资料")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _vm.setMenuPerms("mainuser:addBig") ||
      _vm.setMenuPerms("mainuser:editPwd")
        ? _c(
            "div",
            [
              _vm.setMenuPerms("mainuser:addBig")
                ? _c("p", { staticClass: "memberQuery_user_tit" }, [
                    _vm._v("大客户：")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "userPwd",
                  attrs: {
                    "label-width": "120px",
                    size: "mini",
                    model: _vm.userPwd,
                    disabled: !_vm.user.uid
                  }
                },
                [
                  _vm.setMenuPerms("mainuser:addBig")
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "大客户：", prop: "customerType" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949"
                            },
                            on: { change: _vm.setCustomerType },
                            model: {
                              value: _vm.userPwd.customerType == 2,
                              callback: function($$v) {
                                _vm.$set(_vm.userPwd, "customerType==2", $$v)
                              },
                              expression: "userPwd.customerType==2"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              style: {
                                color:
                                  _vm.userPwd.customerType == 2
                                    ? "#13ce66"
                                    : "#ff4949",
                                marginLeft: "10px"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.userPwd.customerType == 2 ? "是" : "否"
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.setMenuPerms("mainuser:addBig") &&
                  _vm.userPwd.customerType == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "客户经理编码：", prop: "managerNo" }
                        },
                        [
                          _c("el-input", {
                            staticClass: "maxWidth",
                            attrs: {
                              type: "text",
                              placeholder: "请输入客户经理编码"
                            },
                            model: {
                              value: _vm.userPwd.managerNo,
                              callback: function($$v) {
                                _vm.$set(_vm.userPwd, "managerNo", $$v)
                              },
                              expression: "userPwd.managerNo"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.setMenuPerms("mainuser:editPwd")
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "密码：", prop: "password" } },
                        [
                          _c("el-input", {
                            staticClass: "maxWidth",
                            attrs: {
                              type: "password",
                              placeholder: "请输入密码"
                            },
                            model: {
                              value: _vm.userPwd.password,
                              callback: function($$v) {
                                _vm.$set(_vm.userPwd, "password", $$v)
                              },
                              expression: "userPwd.password"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.setMenuPerms("mainuser:editPwd")
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "确认密码：", prop: "password_" } },
                        [
                          _c("el-input", {
                            staticClass: "maxWidth",
                            attrs: {
                              type: "password",
                              placeholder: "请输入密码"
                            },
                            model: {
                              value: _vm.userPwd.password_,
                              callback: function($$v) {
                                _vm.$set(_vm.userPwd, "password_", $$v)
                              },
                              expression: "userPwd.password_"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.setPwd }
                        },
                        [_vm._v("保存修改信息")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.popShow,
              expression: "popShow"
            }
          ],
          staticClass: "memberQuery_pop"
        },
        [
          _c(
            "div",
            { staticClass: "memberQuery_pop_box" },
            [
              _c(
                "p",
                { staticStyle: { "font-weight": "bold", color: "red" } },
                [_vm._v("请刷卡")]
              ),
              _vm._v(" "),
              _c("el-input", {
                ref: "midInput",
                attrs: { autofocus: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.goSearchType($event)
                  }
                },
                model: {
                  value: _vm.mid,
                  callback: function($$v) {
                    _vm.mid = $$v
                  },
                  expression: "mid"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.closePop } },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }