"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var basicRouter = {
  path: '/basic',
  component: _layout.default,
  redirect: '/basic/whiteList',
  name: 'Basic',
  children: [{
    path: 'whiteList',
    name: 'BasicWhiteList',
    meta: {
      title: '白名单'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/basic/white-list"));
      });
    }
  }, {
    path: 'whiteList/create',
    name: 'BasicWhiteListCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/basic/white-list/form"));
      });
    },
    meta: {
      title: '添加白名单',
      activeMenu: '/basic/whiteList'
    }
  }, {
    path: 'whiteList/edit/:id',
    name: 'BasicWhiteListEdit',
    meta: {
      title: '编辑白名单',
      activeMenu: '/basic/whiteList'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/basic/white-list/form"));
      });
    }
  }, {
    path: 'whiteList/dea',
    name: 'BasicWhiteListDetail',
    meta: {
      title: '白名单详情',
      activeMenu: '/basic/whiteList'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/basic/white-list/dea"));
      });
    }
  }, {
    path: 'userLabelTab',
    name: 'BasicuserLabelTab',
    meta: {
      title: '人群标签'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/basic/userLabel/tab"));
      });
    }
  }, {
    path: 'userLabel',
    name: 'BasicuserLabel',
    meta: {
      title: '人群标签',
      keepAlive: true,
      addTxt: "新增",
      addUrl: '/basic/userLabelAdd',
      activeMenu: '/basic/userLabelTab'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/basic/userLabel/list"));
      });
    }
  }, {
    path: 'userLabelAdd',
    name: 'BasicuserLabelAdd',
    meta: {
      title: '新增编辑标签',
      activeMenu: '/basic/userLabelTab'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/basic/userLabel/add"));
      });
    }
  }, {
    path: 'userLabelClass',
    name: 'BasicuserLabelCLass',
    meta: {
      title: '类目列表',
      // keepAlive:true,
      addTxt: "新增",
      addUrl: '/basic/userClassAdd',
      pageList: null,
      activeMenu: '/basic/userLabelTab'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/basic/userLabel/class"));
      });
    }
  }, {
    path: 'userClassAdd',
    name: 'BasicuserLabelClassAdd',
    meta: {
      title: '新增编辑类目',
      activeMenu: '/basic/userLabelTab'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/basic/userLabel/classAdd"));
      });
    }
  }]
};
var _default = basicRouter;
exports.default = _default;