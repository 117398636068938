"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var contentRouter = {
  path: '/content',
  component: _layout.default,
  redirect: '/content/manageContent/manageArticle',
  name: 'Content',
  meta: {
    title: '内容',
    icon: 'nested'
  },
  children: [{
    path: 'manageContent',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/content/manageContent'));
      });
    },
    name: 'ManageContent',
    redirect: '/content/manageContent/manageArticle',
    meta: {
      title: '内容管理'
    },
    children: [{
      path: 'manageArticle',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/content/manageContent/manageArticle'));
        });
      },
      name: 'ManageArticle',
      meta: {
        title: '文章管理',
        keepAlive: true
      }
    }, {
      path: 'contentDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/content/manageContent/contentDea'));
        });
      },
      name: 'ContentDea',
      hidden: true,
      meta: {
        title: '文章详情',
        activeMenu: '/content/manageContent/manageArticle'
      }
    }, {
      path: 'corfimAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/content/manageContent/corfimAdd'));
        });
      },
      name: 'CorfimAdd',
      hidden: true,
      meta: {
        title: '确认添加文章',
        activeMenu: '/content/manageContent/manageArticle'
      }
    }, {
      path: 'addArticle',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/content/manageContent/addArticle'));
        });
      },
      name: 'AddArticle',
      meta: {
        title: '新增文章',
        activeMenu: '/content/manageContent/manageArticle'
      }
    }]
  }, {
    path: 'feedback',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/content/feedback'));
      });
    },
    name: 'Feedback',
    redirect: '/content/feedback/massageList',
    meta: {
      title: '意见反馈'
    },
    children: [{
      path: 'massageList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/content/feedback/massageList'));
        });
      },
      name: 'MassageList',
      meta: {
        title: '信息列表'
      }
    }]
  }]
};
var _default = contentRouter;
exports.default = _default;