"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _api = require("./api.js");

var _api2 = require("../equity/api.js");

var _api3 = require("@/views/equity/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      rules: {
        ids: [{
          required: true,
          message: '请选择权益'
        }]
      },
      equityList: [],
      openEquityList: [],
      ruleForm: {
        id: null,
        ids: [],
        openIds: []
      },
      memberData: {
        payMemberName: null
      }
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _api.getData)({
        id: this.ruleForm.id
      }).then(function (res) {
        _this.memberData.payMemberName = res.data.payMemberName;
        _this.ruleForm.ids = res.data.rightIds ? JSON.parse(res.data.rightIds) : [];
        _this.ruleForm.openIds = res.data.openRightIds ? JSON.parse(res.data.openRightIds) : [null];
      });
    }

    this.getEquity();
  },
  methods: {
    changOpenId: function changOpenId(e, item) {
      this.ruleForm.openIds = e ? [item.id] : ['null'];
    },
    getEquity: function getEquity() {
      var _this2 = this;

      (0, _api3.getEquityList)({
        pageNo: 1,
        pageSize: 100
      }).then(function (res) {
        _this2.openEquityList = res.data.records;
      });
      (0, _api2.getList)({
        pageNo: 1,
        pageSize: 100,
        status: 1
      }).then(function (res) {
        _this2.equityList = res.data.records;
      });
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          var _data = JSON.parse(JSON.stringify(_this3.ruleForm));

          (0, _api.setRights)(_data).then(function (res) {
            _this3.$message({
              type: 'success',
              message: '保存成功!'
            });

            _this3.$router.go(-1);
          });
        } else {
          var data = Object.keys(object);
          var dom = _this3.$refs[data[0]];
          dom.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
        }
      });
    }
  }
};
exports.default = _default;