"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default,
    selectAward: _radioItem.default
  },
  data: function data() {
    return {
      popType: '',
      count: 0,
      couponShow: false,
      type: [{
        label: '实体商品',
        value: 1
      }, {
        label: '直冲商品',
        value: 2
      }, {
        label: '优惠券',
        value: 3
      }],
      rules: {
        /*
          @NotNull(message = "管理员推广奖励不能为空")
        private BigDecimal  promotionRewardAdmin;
         * 系统用户推广奖励
        @NotNull(message = "系统用户推广奖励不能为空")
        private BigDecimal  promotionRewardUser;
        */
        promotionRewardAdmin: [{
          required: true,
          message: '管理员推广奖励不能为空'
        }],
        promotionRewardUser: [{
          required: true,
          message: '系统用户推广奖励不能为空'
        }],
        payMemberName: [{
          required: true,
          message: '请输入会员类型名称'
        }],
        payMemberNameShort: [{
          required: true,
          message: '请输入会员类型简称'
        }],
        buyPrice: [{
          required: true,
          message: '请输入售卖金额'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 0) {
              callback(new Error('金额需要大于等于0'));
            } else {
              callback();
            }
          }
        }],
        buyScore: [{
          required: true,
          message: '请输入售卖积分'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 0) {
              callback(new Error('积分需要大于等于0'));
            } else {
              callback();
            }
          }
        }],
        validDays: [{
          required: true,
          message: '请输入生效天数'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 1) {
              callback(new Error('生效天数需要大于等于1'));
            } else {
              callback();
            }
          }
        }],
        limitBuyTimes: [{
          required: true,
          message: '请输入限制购买次数'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 1) {
              callback(new Error('购买次数需要大于等于1'));
            } else {
              callback();
            }
          }
        }],
        openRightFlg: [{
          required: true,
          message: '请选择是否开卡礼'
        }],
        saleType: [{
          required: true,
          message: '请选择可买用户类型'
        }],
        memberPriceFlg: [{
          required: true,
          message: '请选择是否享受百货会员价'
        }],
        status: [{
          required: true,
          message: '请选择会员状态'
        }],
        memberImage: [{
          required: true,
          message: '请上传会员图标'
        }],
        description: [{
          required: true,
          message: '请输入会员说明'
        }],
        openBuy: [{
          required: true,
          message: '请选择是否可购买'
        }],
        bindDuplicate: [{
          required: true,
          message: '请选择是否可绑亲友卡'
        }]
      },
      ruleForm: {
        openBuy: null,
        payMemberName: null,
        payMemberNameShort: null,
        buyPrice: null,
        buyScore: null,
        validDays: null,
        limitBuyTimes: null,
        openRightFlg: null,
        memberPriceFlg: 1,
        saleType: null,
        memberImage: null,
        status: null,
        description: null,
        bindDuplicate: null,
        equityDescription: null
      }
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _api.getData)({
        id: this.ruleForm.id
      }).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
      return;
      getVip(this.ruleForm.id).then(function (res) {
        _this.fzObj(res.data, _this.ruleForm);
      });
    }
  },
  methods: {
    setCover: function setCover(e) {
      this.ruleForm.memberImage = e;
    },
    getRelevances: function getRelevances() {
      this.ruleForm.openGifts.push({
        attrType: null,
        attrCode: null,
        // prizeName:null,
        attrWorth: null
      });
    },

    /*
    选择奖励项
    */
    setAward: function setAward(e, index) {
      this.ruleForm.openGifts[index].attrCode = '';
      this.ruleForm.openGifts[index].attrWorth = '';
    },

    /*
    打开弹框
    */
    getAward: function getAward(e, index) {
      this.count = index;

      if (e === 3) {
        this.popType = 'coupon';
      } else {
        this.popType = 'goods';
      }

      this.$refs.coupon1.getList(this.popType, this.ruleForm.openGifts[index].attrCode);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      if (this.popType === 'coupon') {
        this.ruleForm.openGifts[this.count].attrCode = e.uuid; // this.ruleForm.openGifts[this.count].prizeName = e.name
      }

      if (this.popType === 'goods') {
        this.ruleForm.openGifts[this.count].attrCode = e.productCode; //  this.ruleForm.openGifts[this.count].prizeName = e.productName
      }

      this.couponShow = false;
      this.popType = '';
    },

    /*
    删除奖励项
    */
    delAward: function delAward(index) {
      this.ruleForm.openGifts.splice(index, 1);
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          (0, _api.saveOrUpdate)(_this2.ruleForm).then(function (res) {
            _this2.$message({
              type: 'success',
              message: '保存成功!'
            });

            _this2.$router.go(-1);
          });
        } else {
          var data = Object.keys(object);
          var dom = _this2.$refs[data[0]];
          dom.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
        }
      });
    }
  }
};
exports.default = _default;