"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '券包名称',
        prop: 'exportName',
        width: 120
      }, {
        label: '兑换码',
        prop: 'exchangeOrderNo',
        width: 120
      }, {
        label: '兑换人手机号',
        prop: 'exchangeUserMobile',
        width: 120
      }, {
        label: '兑换人',
        prop: 'exchangeUserName',
        width: 80
      }, {
        slot: 'status',
        label: '状态1：已兑换；2：未兑换',
        prop: 'exchangeUserMobile'
      }, {
        label: '兑换时间',
        width: 140,
        prop: 'exchangeTime'
      }],
      searchData: [{
        type: 'input',
        placeholder: '兑换人电话',
        value: 'exchangeUserMobile'
      }, {
        type: 'input',
        placeholder: '兑换人姓名',
        value: 'exchangeUserName'
      }, {
        type: 'input',
        placeholder: '兑换码',
        value: 'exchangeOrderNo'
      }, {
        placeholder: '状态',
        value: 'status',
        select: [{
          label: '已兑换',
          value: 1
        }, {
          label: '为兑换',
          value: -1
        }]
      }]
    };
  },
  mounted: function mounted() {
    var id = this.$route.query.id;
    this.$refs.wTable.setRequestValue('exportId', id);
    this.$refs.wTable.getList();
  },
  methods: {}
};
exports.default = _default;