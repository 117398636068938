var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "20px" } },
    [
      _c(
        "el-descriptions",
        { attrs: { size: "mini", title: "转送给", column: 4, border: "" } },
        [
          _c("el-descriptions-item", { attrs: { label: "赠送人" } }, [
            _vm._v("\n      " + _vm._s(_vm.nextInfo.mobile) + "\n    ")
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "赠送人手机号" } }, [
            _vm._v(_vm._s(_vm.nextInfo.nickName))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "旧卡号" } }, [
            _vm._v(_vm._s(_vm.nextInfo.fromCard.cardNo))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "旧卡余额" } }, [
            _vm._v(_vm._s(_vm.nextInfo.fromCard.balance))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "领取人" } }, [
            _vm._v("\n      " + _vm._s(_vm.nextInfo.toMickName) + "\n    ")
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "领取人手机号" } }, [
            _vm._v(_vm._s(_vm.nextInfo.toMobile))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "新卡号" } }, [
            _vm._v(_vm._s(_vm.nextInfo.toCard.cardNo))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "新卡余额" } }, [
            _vm._v(_vm._s(_vm.nextInfo.toCard.balance))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "开始时间" } }, [
            _vm._v(_vm._s(_vm.nextInfo.createTime))
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "结束时间" } }, [
            _vm._v(_vm._s(_vm.nextInfo.operateTime))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.nextInfo.next && _vm.isNext == 1
        ? _c("div", [_c("Pre", { attrs: { nextInfo: _vm.nextInfo.next } })], 1)
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }