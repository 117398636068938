var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("终端设备版本号 ")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "div_add" },
      [
        _c(
          "el-row",
          { staticClass: "row_div", attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { span: 6 } },
              [
                _c("el-input", {
                  staticClass: "input2",
                  attrs: { size: "small", placeholder: "版本号" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.init({ limit: 10, page: 1 })
                    }
                  },
                  model: {
                    value: _vm.obj.versionNo,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "versionNo", $$v)
                    },
                    expression: "obj.versionNo"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 3 } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { size: "small", placeholder: "状态" },
                    model: {
                      value: _vm.obj.versionStatu,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "versionStatu", $$v)
                      },
                      expression: "obj.versionStatu"
                    }
                  },
                  _vm._l(_vm.options, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 1.5 } },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      icon: "el-icon-search",
                      size: "small"
                    },
                    on: {
                      click: function($event) {
                        return _vm.init({ limit: 10, page: 1 })
                      }
                    }
                  },
                  [_vm._v("查询")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 1.5 } },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "info",
                      size: "small",
                      icon: "el-icon-refresh"
                    },
                    on: {
                      click: function($event) {
                        return _vm.remove()
                      }
                    }
                  },
                  [_vm._v("重置")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 2 } },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "has",
                        rawName: "v-has",
                        value: "terminalVersion:add",
                        expression: "'terminalVersion:add'"
                      }
                    ],
                    attrs: {
                      type: "primary",
                      size: "small",
                      icon: "el-icon-circle-plus-outline"
                    },
                    on: { click: _vm.goAdd }
                  },
                  [_vm._v("新增版本号")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "div_table" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData, size: "small", border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { type: "expand" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(props) {
                    return [
                      _c("div", [
                        _c("ul", { staticClass: "table_ul table_ul_flex" }, [
                          _c("li", [
                            _c("p", { staticClass: "li_tit" }, [
                              _vm._v("版本号：")
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "li_txt" }, [
                              _vm._v(_vm._s(props.row.versionNo))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("p", { staticClass: "li_tit" }, [
                              _vm._v("创建时间：")
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "li_txt" }, [
                              _vm._v(_vm._s(props.row.createTime))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("p", { staticClass: "li_tit" }, [
                              _vm._v("状态：")
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "li_txt" }, [
                              _vm._v(_vm._s(props.row.versionStatuTxt))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "table_ul" }, [
                          _c("li", [
                            _c("p", { staticClass: "li_tit" }, [
                              _vm._v("版本说明：")
                            ]),
                            _vm._v(" "),
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(props.row.versionDesc)
                              }
                            })
                          ])
                        ])
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "版本号",
                prop: "versionNo",
                align: "center",
                "min-width": "180"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "创建时间",
                prop: "createTime",
                align: "center",
                "min-width": "280"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "状态",
                prop: "versionStatuTxt",
                align: "center",
                "min-width": "180"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", align: "center", "min-width": "180" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(props) {
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "has",
                              rawName: "v-has",
                              value: "terminalVersion:edit",
                              expression: "'terminalVersion:edit'"
                            }
                          ],
                          staticClass: "table_td_btn"
                        },
                        [
                          _c(
                            "app-link",
                            {
                              attrs: {
                                to:
                                  "/setting/editorVersions?versionId=" +
                                  props.row.versionId
                              }
                            },
                            [_vm._v("编辑")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total>0"
            }
          ],
          attrs: {
            total: _vm.total,
            page: _vm.obj.pageNo,
            limit: _vm.obj.pageSize
          },
          on: {
            "update:page": function($event) {
              return _vm.$set(_vm.obj, "pageNo", $event)
            },
            "update:limit": function($event) {
              return _vm.$set(_vm.obj, "pageSize", $event)
            },
            pagination: _vm.init
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }