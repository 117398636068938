"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      disabled: false,
      rules: {
        'signCardParam.description': [{
          required: true,
          message: '请输入描述'
        }],
        'signCardParam.imageUrl': [{
          required: true,
          message: '请上传图片'
        }],
        'signCardParam.status': [{
          required: true,
          message: '请选择状态'
        }],
        'signCardParam.name': [{
          required: true,
          message: '请输入名称'
        }]
      },
      form: {
        signCardParam: {
          name: null,
          status: null,
          imageUrl: 'mika/dev/2022-03-24/c4a7f8e8dd7a4a48b190b08b79e10228.png',
          description: null,
          days: null
        },
        ruleParamList: [{
          id: null,
          label: '非365会员：',
          memberType: -1,
          limitDateCycle: null,
          limitReceiveNumber: null,
          price: null,
          free: null
        }, {
          id: null,
          label: '365会员：',
          memberType: -1,
          limitDateCycle: null,
          limitReceiveNumber: null,
          price: null,
          free: null
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.form.id = this.$route.query.id;
    this.disabled = this.$route.query.edit === 'no';

    if (this.form.id) {
      (0, _api.getData)(this.form.id).then(function (res) {
        _this.form.signCardParam = res.data.repairCardVo;
        res.data.repairRuleVoList.forEach(function (item) {
          if (item.memberType === 0) {
            _this.form.ruleParamList[0].id = item.id;
            _this.form.ruleParamList[0].memberType = item.memberType;
            _this.form.ruleParamList[0].limitDateCycle = item.limitDateCycle;
            _this.form.ruleParamList[0].limitReceiveNumber = item.limitReceiveNumber;
            _this.form.ruleParamList[0].price = item.price;
            _this.form.ruleParamList[0].free = item.free;
          }

          if (item.memberType === 1) {
            _this.form.ruleParamList[1].id = item.id;
            _this.form.ruleParamList[1].memberType = item.memberType;
            _this.form.ruleParamList[1].limitDateCycle = item.limitDateCycle;
            _this.form.ruleParamList[1].limitReceiveNumber = item.limitReceiveNumber;
            _this.form.ruleParamList[1].price = item.price;
            _this.form.ruleParamList[1].free = item.free;
          }
        });
      });
    }
  },
  methods: {
    delRuleItem: function delRuleItem(item, index) {
      var _this2 = this;

      (0, _api.delRule)(item.id).then(function (res) {
        _this2.form.ruleParamList[index].id = null;
        _this2.form.ruleParamList[index].memberType = -1;
        _this2.form.ruleParamList[index].limitDateCycle = null;
        _this2.form.ruleParamList[index].limitReceiveNumber = null;
        _this2.form.ruleParamList[index].price = null;
        _this2.form.ruleParamList[index].free = null;
      });
    },
    setCover: function setCover(e, type, type1) {
      this.form[type][type1] = e;
      console.log(this.form[type][type1]);
    },
    typeChange: function typeChange(e) {
      this.form.rewardContent = null;
      this.form.num = e === 3 ? 1 : null;
    },

    /*
        打开弹框
        */
    getAward: function getAward() {
      var selectType = {
        2: 'coupon',
        3: 'card'
      };
      this.$refs.coupon1.getList(selectType[this.form.rewardType], this.form.rewardContent);
      this.couponShow = true;
    },

    /*
        选择ID后
        */
    setCoupon: function setCoupon(e) {
      this.form.rewardContent = e;
      this.couponShow = false;
    },

    /*
        关闭弹框
        */
    closeCouponPop: function closeCouponPop() {
      this.couponShows = false;
    },

    /*
        删除奖励项
        */
    delAward: function delAward(index) {
      this.form.list.splice(index, 1);
    },

    /*
        选择奖励项
        */
    setAward: function setAward(e, index) {
      this.form.list[index].award = '';

      if (e == 5) {
        this.form.list[index].award = '谢谢参与';
      }
    },

    /*
        添加奖励项
        */
    addAward: function addAward() {
      if (this.form.list.length < 8) {
        this.form.list.push({
          awardType: '',
          award: ''
        });
      }
    },

    /* 分享图片*/
    setShare: function setShare(e) {
      this.form.shareImg = e;
    },
    onSubmit: function onSubmit(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var ruleParamList = _this3.form.ruleParamList.filter(function (item, key) {
            return item.memberType >= 0;
          });

          var data = JSON.parse(JSON.stringify(_this3.form));
          data.ruleParamList = ruleParamList;

          if (_this3.form.id) {
            (0, _api.editData)(data).then(function (res) {
              _this3.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this3.$router.go(-1);
            });
          } else {
            (0, _api.addData)(data).then(function (res) {
              _this3.$message({
                message: '保存成功',
                type: 'success'
              });

              _this3.$router.go(-1);
            });
          }
        }
      });
    }
  }
};
exports.default = _default;