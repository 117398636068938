"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _api = require("./api.js");

var _api2 = require("../category/api.js");

var _api3 = require("../class/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _self = this;

    return {
      storeList: [],
      classList: [],
      rules: {
        cardSegmentNoStart: [{
          required: true,
          message: '请输入起始卡号'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < _self.ruleForm.cardSegmentNoStart || value > _self.ruleForm.cardSegmentNoEnd) {
              callback(new Error('请输入卡号区间数'));
            } else {
              callback();
            }
          }
        }],
        cardSegmentNoEnd: [{
          required: true,
          message: '请输入结束卡号'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < _self.ruleForm.cardSegmentNoStart || value > _self.ruleForm.cardSegmentNoEnd) {
              callback(new Error('请输入卡号区间数'));
            } else {
              callback();
            }
          }
        }],
        num: [{
          required: true,
          message: '请输入数量'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }]
      },
      ruleForm: (0, _defineProperty2.default)({
        planName: null,
        cardNumber: null,
        cardSegmentNoStart: null,
        cardSegmentNoEnd: null,
        makeFactoryName: null,
        remark: null,
        examRecordsVoList: [],
        status: 0
      }, "remark", null),
      inOutFirm: {
        planId: null,
        cardSegmentNoStart: null,
        cardSegmentNoEnd: null,
        num: null,
        remark: null,
        cardCategoryId: null,
        cardKindsId: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.inOutFirm.planId = this.$route.query.id;
    (0, _api2.categoryPage)({
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      _this.storeList = res.data.records;
    });

    if (this.inOutFirm.planId) {
      (0, _api.getKinds)(this.inOutFirm.planId).then(function (res) {
        _this.inOutFirm.cardCategoryId = res.data.cardCategoryId;
        _this.inOutFirm.cardKindsId = res.data.cardKindsId;

        _this.getClass(res.data.cardCategoryId);

        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    classChange: function classChange(e) {
      console.log(e);
    },
    getClass: function getClass(e) {
      var _this2 = this;

      this.classList = [];
      this.ruleForm.cardKindsId = null;
      (0, _api3.classPage)({
        pageNo: 1,
        pageSize: 100,
        cardCategoryId: e
      }).then(function (res) {
        _this2.classList = res.data.records;
      });
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    // 三级地址选择
    regionDataChange: function regionDataChange(e) {
      var arr = this.$refs['state'].getCheckedNodes(false)[0].pathLabels || [];
      this.ruleForm.detailAddress = arr.join('');
    },
    clearForm: function clearForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this3.inOutFirm));
          goInOutIn(data).then(function (res) {
            _this3.wPage('/card/inOutList');

            _this3.$message({
              type: 'success',
              message: '添加入库计划成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;