"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.to-string");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _member = require("@/api/member");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      typeOptions: {
        1: '积分',
        2: '优惠券',
        3: '购物卡'
      },
      downloadLoading: false,
      times: [],
      requestData: {
        userMobile: null,
        userName: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      list: [],
      columns: [{
        label: 'ID',
        prop: 'id'
      }, {
        label: '用户名称',
        prop: 'userName'
      }, {
        label: '用户手机',
        prop: 'userMobile'
      }, {
        slot: 'type',
        label: '奖励类型',
        prop: 'type'
      }, {
        label: '奖励内容',
        prop: 'content'
      }, {
        label: '领取时间',
        prop: 'time'
      }]
    };
  },
  watch: {
    times: function times(val) {
      if (val) {
        this.requestData.startDate = val[0];
        this.requestData.endDate = val[1];
      } else {
        this.requestData.startDate = null;
        this.requestData.endDate = null;
      }
    }
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this2 = this;

      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        _this2.downloadLoading = true;
        var headers = [];
        var datakeys = [];
        var _this = _this2;

        _this.columns.forEach(function (column) {
          headers.push(column.label);
          datakeys.push(column.prop);
        });

        (0, _member.informationRewardLogPage)(_this.requestData).then(function (res) {
          var list = res.data.items.map(function (item) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              ID: item.id.toString(),
              type: _this.typeOptions[item.type]
            });
          });

          if (!_this2.setMenuPerms('user_mobile_idCard')) {
            list.forEach(function (item) {
              if (item.userMobile) {
                item.userMobile = item.userMobile.replace(item.userMobile.substring(3, 7), "****");
              }
            });
          }

          var data = _this.formatJson(datakeys, list);

          excel.export_json_to_excel({
            header: headers,
            data: data,
            filename: '完善资料奖励领取记录'
          });
        }).catch(function (err) {
          console.log(err);
          _this.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, tableData) {
      var _this3 = this;

      return tableData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'sex') {
            return _this3.formatterSex((0, _defineProperty2.default)({}, j, v[j]));
          }

          return v[j];
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    resetQuery: function resetQuery() {
      this.removeObj(this.requestData);
      this.times = undefined;
      this.getList();
    },
    search: function search() {
      this.requestData.pageNo = 1;
      this.getList();
    },
    getList: function getList() {
      var _this4 = this;

      (0, _member.informationRewardLogPage)(this.requestData).then(function (res) {
        if (!_this4.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.userMobile) {
              item.userMobile = item.userMobile.replace(item.userMobile.substring(3, 7), "****");
            }
          });
        }

        _this4.list = res.data.items;
        _this4.requestData.total = res.data.totalNum;
      });
    }
  }
};
exports.default = _default;