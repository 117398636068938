"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      isChagneGoodsStatus: false,
      columns: [{
        label: '规则名称',
        prop: 'name',
        width: 100
      }, {
        label: '商户名称',
        prop: 'merchantName',
        width: 100
      }, {
        label: '类型（1=计件,2=计重）',
        prop: 'type',
        slot: 'type',
        width: 50
      }, {
        label: '状态（0=正常,1=已删除）',
        prop: 'isDelete',
        slot: 'isDelete',
        width: 50
      }, {
        label: '是否默认（0=否,1=是）',
        prop: 'isDefault',
        slot: 'isDefault',
        width: 50
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 120
      }, {
        label: '更新',
        prop: 'updateTime',
        width: 120
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        placeholder: '规则名称',
        value: 'name'
      }, {
        type: 'input',
        placeholder: '商户名称',
        value: 'merchantName'
      }, {
        type: 'radio',
        placeholder: '是否默认',
        value: 'isDefault',
        select: [{
          label: '默认规则',
          value: 1
        }, {
          label: '非默认规则',
          value: 0
        }]
      }]
    };
  },
  methods: {
    delGoodsItem: function delGoodsItem(id) {
      var _this = this;

      this.apiPost('/api/mall/deliveryRule/delete', {
        id: id
      }).then(function (res) {
        _this.$refs.wTable.getList();
      });
    },
    changeGoodsStatus: function changeGoodsStatus(row) {
      var _this2 = this;

      this.apiPost('/api/mall/deliveryRule/default', {
        id: row.id
      }).then(function (res) {
        _this2.$refs.wTable.getList();
      }).catch(function (err) {
        _this2.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;