"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _print = _interopRequireDefault(require("@/utils/print.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    var _self = this;

    return {
      coteGryList: [],
      props: {
        lazy: true,
        checkStrictly: true,
        value: 'code',
        label: 'name',
        lazyLoad: function lazyLoad(node, resolve) {
          var value = node.value;

          _self.apiPost('/api/invoiceNoFlowingWater/queryCategroy', {
            classCode: node.level == 0 ? '' : value
          }).then(function (res) {
            resolve(res.data || []);
          }).catch(function () {
            resolve([]);
          });

          return;
          setTimeout(function () {
            var nodes = Array.from({
              length: level + 1
            }).map(function (item) {
              return {
                value: ++id,
                label: "\u9009\u9879".concat(id),
                leaf: level >= 2
              };
            }); // 通过调用resolve将子节点数据返回，通知组件数据加载完成

            resolve(nodes);
          }, 1000);
        }
      },
      isSelectGoods: false,
      tableData: [],
      total: 0,
      tabTh: [{
        label: '门店编码',
        prop: 'storeCode',
        width: 50
      }, {
        label: '税务编号',
        prop: 'astaxNo',
        width: 80
      }, {
        label: '商品名称',
        prop: 'productName',
        width: 100
      }, {
        label: '商品编码',
        prop: 'productCode',
        width: 50
      }, {
        label: '规格型号',
        prop: 'specifications',
        width: 50
      }, {
        label: '包装含量',
        prop: 'packContent',
        width: 50
      }, {
        label: '总可开票数量',
        prop: 'invoiceNumSurplus',
        width: 50
      }, {
        label: '本次可开票数量',
        prop: 'thisNum',
        width: 50
      }, {
        label: '总可开票金额',
        prop: 'invoiceAmountSurplus',
        width: 50
      }, {
        label: '本次可开票金额',
        prop: 'thisAmount',
        width: 50
      }, {
        label: '税率',
        prop: 'taxRate',
        width: 50
      }],
      obj: {
        pageNo: 1,
        pageSize: 10,
        classCode: null,
        productName: '',
        productCode: '',
        companyId: null
      },
      formData: [{
        slot: 'invoiceAmountLogParamList'
      }, {
        label: '备注：',
        type: 'input',
        placeholder: '请输入备注',
        value: 'remark'
      }],
      id: null,
      ruleForm: {
        remark: null,
        invoiceAmountLogParamList: []
      },
      rules: {
        invoiceAmountLogParamList: [{
          required: true,
          message: '请选择商品'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        num: [{
          required: true,
          message: '请输入数量'
        }],
        amount: [{
          required: true,
          message: '请输入金额'
        }]
      }
    };
  },
  computed: {
    paramList: function paramList() {
      var amount = 0,
          taxAmount = 0,
          num = 0;
      this.ruleForm.invoiceAmountLogParamList.forEach(function (item) {
        amount += item.amount || 0;
        num += item.num || 0;

        if (item.amount) {
          taxAmount += item.amount * item.taxRate;
        }
      });
      return {
        amount: amount.toFixed(2),
        taxAmount: taxAmount.toFixed(2),
        num: num
      };
    }
  },
  created: function created() {
    var _this = this;

    this.isPointer = false;
    this.id = this.$route.query.id || null;

    if (this.id) {
      this.apiGet('/api/invoiceNoFlowingWater/getNoFlowingWaterByCode/' + this.id).then(function (res) {
        _this.ruleForm = res.data;
      });
    }
  },
  methods: {
    //原生的打印
    printAll: function printAll() {
      // const printHTML = document.querySelector('#printBill').innerHTML
      // // 将打印的区域赋值，进行打印 这种会出现多余的空白页
      // window.document.body.innerHTML = printHTML
      // window.print() // 调用window打印方法
      // window.location.reload() // 打印完成后重新加载页面
      this.formData.pop();
      this.isPointer = true;
      this.$nextTick(function () {
        var printHTML = document.querySelector('#departmentDea');
        window.document.body.innerHTML = ""; // 将打印的区域赋值，进行打印  解决上面赋值方法

        window.document.body.appendChild(printHTML);
        window.print(); // 调用window打印方法

        window.location.reload(); // 打印完成后重新加载页面
      });
    },
    //打印
    billPrintClick: function billPrintClick() {
      this.formData.pop();
      this.isPointer = true;
      setTimeout(function () {
        var style = '@page {margin:0 10mm};'; //打印时去掉眉页眉尾

        (0, _print.default)({
          printable: 'departmentDea',
          // 标签元素id
          type: 'html',
          header: '',
          targetStyles: ['*'],
          scanStyles: true,
          //打印必须加上，不然页面上的css样式无效
          style: style
        });
      }, 100);
    },
    tableRowKey: function tableRowKey(e) {
      return e.storeCode + e.productCode;
    },
    handleDownload: function handleDownload() {
      var tHeader = ['门店编码', '商品名称', '商品编码', '商品数量', '开票金额', '税率'];
      var filterVal = ['storeCode', 'goodsName', 'goodsCode', 'num', 'amount', 'taxRate'];
      this.getHandleDownload(tHeader, filterVal, this.ruleForm.invoiceAmountLogParamList, "".concat(this.ruleForm.code, "-").concat(this.ruleForm.status == 1 ? '蓝票' : '红票', "(\u9879\u76EE\u4EF7\u7A0E\u5408\u8BA1\uFF1A").concat(this.ruleForm.amount, ";\u7A0E\u4EF7\u5408\u8BA1\uFF1A").concat(this.ruleForm.taxAmount, ")"));
    },
    delGrade: function delGrade(row, index) {
      this.ruleForm.invoiceAmountLogParamList.splice(index, 1);
    },
    affirmAddCoupon: function affirmAddCoupon() {
      this.ruleForm.invoiceAmountLogParamList = [];
      this.ruleForm.invoiceAmountLogParamList = (0, _toConsumableArray2.default)(this.$refs.multipleTable.store.states.selection);
      this.isSelectGoods = false;
    },
    clearCoupon: function clearCoupon() {
      this.$refs.multipleTable.clearSelection();
    },
    changeClass: function changeClass(e) {
      this.obj.classCode = e[e.length - 1];
      this.getGoods('init');
    },
    openGoods: function openGoods() {
      var _this2 = this;

      this.$refs.multipleTable.clearSelection();

      if (this.ruleForm.invoiceAmountLogParamList.length > 0) {
        this.ruleForm.invoiceAmountLogParamList.forEach(function (item) {
          _this2.$refs.multipleTable.toggleRowSelection(item, true);
        });
      }

      this.$nextTick(function () {
        this.isSelectGoods = true;
      });
    },
    getGoods: function getGoods(type) {
      var _this3 = this;

      if (type == 'init') {
        this.obj.pageNo = 1;
      }

      this.apiPost('/api/invoiceNoFlowingWater/queryInvoiceAmount', this.obj).then(function (res) {
        _this3.tableData = res.data ? res.data.items : [];
        _this3.total = res.data ? res.data.totalNum : 0;
      });
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      var requestData = JSON.parse(JSON.stringify(this.ruleForm));
      requestData.invoiceAmountLogParamList = this.ruleForm.invoiceAmountLogParamList.map(function (item) {
        return {
          goodsCode: item.productCode,
          goodsName: item.productName,
          num: item.num,
          amount: item.amount,
          storeCode: item.storeCode,
          taxRate: item.taxRate
        };
      });
      this.apiPost('/api/invoiceNoFlowingWater/saveNoFlowingWater', requestData).then(function (res) {
        _this4.$router.go(-1);

        _this4.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;