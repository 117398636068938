var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-upload",
        {
          staticClass: "avatar-uploader",
          style: { maxWidth: _vm.width + "px", height: _vm.height + "px" },
          attrs: {
            action: _vm.action,
            headers: _vm.headers,
            "show-file-list": false,
            "on-success": _vm.handleSuccess,
            "on-error": _vm.onError,
            "on-preview": _vm.onPreview,
            "on-progress": _vm.onProgress,
            "before-upload": _vm.beforeAvatarUpload
          }
        },
        [
          _vm.cover
            ? _c("img", {
                staticClass: "avatar",
                style: { width: _vm.width + "px", height: _vm.height + "px" },
                attrs: { src: _vm.imgSrc + _vm.cover }
              })
            : _c("i", {
                staticClass: "el-icon-plus avatar-uploader-icon",
                style: {
                  width: _vm.width + "px",
                  height: _vm.height + "px",
                  lineHeight: _vm.height + "px"
                }
              })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }