"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _report = require("@/api/report.js");

var _api = require("@/api/api.js");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    wTable: _index.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      storeList: [],
      downloadLoading: false,
      balance: '',
      uStatusStr: '',
      cardNo: '',
      uid: '',
      requestData: {
        uid: '',
        cardNo: '',
        type: '',
        pageNo: 1,
        pageSize: 10,
        total: 0,
        spbm: '',
        beginCreateTime: '',
        endCreateTime: ''
      },
      time: '',
      tableData: [],
      columns: [{
        label: '流水号',
        prop: 'orderNo',
        width: 100
      }, {
        label: '交易单号',
        prop: 'payNo',
        width: 100
      }, {
        label: '变动金额',
        prop: 'payMoney',
        width: 50
      }, {
        label: '机构/地点',
        prop: 'note',
        width: 140
      }, {
        label: '时间',
        prop: 'createTime',
        width: 100
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
    this.balance = this.$route.query.balance;
    this.uStatusStr = this.$route.query.uStatusStr;
    this.cardNo = this.$route.query.cardNo;
    this.uid = this.$route.query.uid;
    this.getList();
  },
  methods: {
    selectionChange: function selectionChange(e) {
      console.log(e);

      if (e.length > 10) {
        this.$message('最多选择十条');
      }
    },
    selectTable: function selectTable(row, index) {
      return row.refundStatus == '0';
    },
    affirmAddCoupon: function affirmAddCoupon() {
      var _this2 = this;

      var selectOrder = this.$refs.multipleTable.store.states.selection;
      console.log(selectOrder);
      var payMoney = 0;
      var ids = [];
      selectOrder.forEach(function (item) {
        //if(item.payMoney>0){
        payMoney += item.payMoney;
        ids.push(item.id); // }
      });

      if (selectOrder.length > 0 && selectOrder.length < 11) {
        this.$confirm("\u786E\u8BA4\u9000\u6B3E\u5361\u4F59\u989D\u4E3A".concat(payMoney, "\u5143\uFF0C\u5171").concat(selectOrder.length, "\u5355"), '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this2.apiPost('/api/cardTrade/batchRefundCardBalance', ids).then(function (res) {
            _this2.$message({
              type: 'success',
              message: '退回成功'
            });

            _this2.$refs.multipleTable.clearSelection();

            _this2.getList();
          });
        }).catch(function () {
          _this2.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message('最低一条，最多十条');
      }
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['流水号', '交易单号', '变动金额', '机构/地点', '时间'];
        var filterVal = ['orderNo', 'payNo', 'payMoney', 'note', 'createTime'];
        var list = [];
        _this3.requestData.beginCreateTime = _this3.time[0] || '';
        _this3.requestData.endCreateTime = _this3.time[1] || '';
        var data = {};
        Object.keys(_this3.requestData).forEach(function (key) {
          if (_this3.requestData[key]) {
            data[key] = _this3.requestData[key];
          }
        });
        data.pageSize = _this3.requestData.total;
        data.pageNo = 1;
        data.cardNo = _this3.cardNo;

        _this3.openLoading();

        (0, _report.listCardTradePage)(data).then(function (res) {
          list = res.data.items;

          var data = _this3.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: _this3.cardNo
          });
          _this3.downloadLoading = false;

          _this3.openLoading().close();
        }).catch(function (res) {
          _this3.downloadLoading = false;

          _this3.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this4 = this;

      if (type == 'search') {
        this.requestData.pageNo = 1;
      }

      if (type == 'remove') {
        this.time = '';
        this.removeObj(this.requestData);
      }

      this.requestData.beginCreateTime = this.time[0] || '';
      this.requestData.endCreateTime = this.time[1] || '';
      this.requestData.cardNo = this.cardNo;
      this.requestData.uid = this.uid;
      (0, _report.listCardTradePage)(this.requestData).then(function (res) {
        _this4.tableData = res.data.items;
        _this4.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;