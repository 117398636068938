"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _slide = _interopRequireDefault(require("@/components/slide.vue"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _api = require("./api.js");

var _api2 = require("../class/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var validateNumber = function validateNumber(rule, value, callback) {
  if (/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value) === false) {
    callback(new Error('请填写大于等于0的数字'));
  } else {
    callback();
  }
};

var _default = {
  components: {
    wTable: _index.default,
    slide: _slide.default,
    qrCodePop: _qrCodePop.default
  },
  filters: {
    memberTypeObj: function memberTypeObj(type) {
      var Obj = {
        1: '普通会员',
        2: '要客',
        3: '365'
      };
      return Obj[type];
    }
  },
  data: function data() {
    return {
      refundAmount: undefined,
      qrCode: null,
      popShow: false,
      // 小程序码弹框
      exportPhone: null,
      isOrderQrcede: null,
      vipTypeList: [],
      drawer: false,
      bindItem: {},
      refundDataItem: {},
      bindForm: {
        orderNo: null
      },
      bindRules: {
        sysUserId: [{
          required: true,
          message: '请选择绑定员工'
        }]
      },
      sysList: [],
      vipList: [],
      remark: null,
      isRemark: false,
      isCard: false,
      orderId: null,
      buyUid: null,
      isPay: false,
      ruleForm: {
        number: null,
        note: null,
        payMemberTypeId: null
      },
      rules: {
        note: [{
          required: true,
          message: '请选择员工账号'
        }],
        number: [{
          required: true,
          message: '请输入数量'
        }, {
          validator: validateNumber
        }],
        payMemberTypeId: [{
          required: true,
          message: '请选择会员类型'
        }]
      },
      plusList: [],
      orderStatus: [],
      downloadLoading: false,
      requestData: {
        orderNo: '',
        outTradeNo: null,
        sysUserMobile: null,
        buyUtphone: '',
        orderStatus: '',
        orderSaleType: null,
        orderType: '',
        pageNo: 1,
        pageSize: 10,
        total: 0,
        beginPayTime: '',
        endPayTime: '',
        deviceSn: ''
      },
      time: '',
      tableData: [],
      columns: [{
        label: '订单号',
        prop: 'orderNo',
        width: 100
      }, {
        label: '外部订单号',
        prop: 'outTradeNo',
        width: 100
      }, {
        label: '流水号',
        prop: 'tradeNo',
        width: 100
      }, {
        label: '购买人',
        prop: 'buyUname',
        width: 50
      }, {
        label: '购买人手机号码',
        prop: 'buyUtphone',
        width: 80
      }, {
        label: '会员类型',
        prop: 'memberName',
        width: 80
      },
      /* {
        slot: 'memberType',
        label: '会员类型',
        prop: 'memberType',
        width: 15
      }, */

      /* {
         label: '推广人手机号码',
         prop: 'sysUserMobile',
         width: 80
       }, */
      {
        slot: 'orderType',
        label: '订单类型',
        prop: 'orderTypeTxt'
      }, {
        slot: 'plusType',
        label: 'plus类型',
        prop: 'deviceSn'
      }, {
        slot: 'orderSaleType',
        label: '购买类型(0：普通 1：小程序团购,2:后台团购)',
        prop: 'orderSaleType'
        /* orderSaleType // 购买类型(0：普通 1：团购)*/

      }, {
        slot: 'amountRealpay',
        label: '购买金额',
        prop: 'amountRealpay',
        width: 50
      }, {
        label: '数量',
        prop: 'number',
        width: 50
      }, {
        label: '购买时间',
        prop: 'orderCreateTime',
        width: 100
      }, {
        label: '订单状态',
        prop: 'orderStatusStr',
        width: 80
      }, {
        label: '已退金额',
        prop: 'refundedAmount',
        width: 80
      }, {
        label: '退款时间',
        prop: 'refundTime',
        width: 100
      }, {
        label: '拉新人ID',
        prop: 'note',
        width: 50
      }, {
        label: '备注/会员类型',
        prop: 'facilityCode',
        width: 50
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    isPay: function isPay(newValue, oldValue) {
      if (newValue) {
        this.ruleForm.payMemberTypeId = null;
        this.ruleForm.number = null;
        this.ruleForm.note = null;
        this.getVipTypeList();
      }
    }
  },
  activated: function activated() {// this.getList()
  },
  created: function created() {
    /* listVipPlus({}).then((res) => {
              this.plusList = res.data
            }) */
  },
  methods: {
    /*
        获取团购订单二维码
      */
    getVipTypeList: function getVipTypeList() {
      var _this = this;

      this.apiPost('/api/pay-member-type/page', {
        pageNo: 1,
        pageSize: 100
      }).then(function (res) {
        _this.vipTypeList = res.data.records;
        console.log(res);
      });
    },
    remoteMethod: function remoteMethod(query) {
      var _this2 = this;

      if (query !== '') {
        this.apiPost('/api/sysUser/listSysUserPage', {
          pageNo: 1,
          pageSize: 100,
          mobile: query
        }).then(function (res) {
          _this2.sysList = res.data.items;
        });
      } else {
        this.sysList = [];
      }
    },
    goBindSys: function goBindSys(item) {
      this.bindItem = item;
      this.drawer = true;
    },
    goBindSysUser: function goBindSysUser(formName) {
      var _this3 = this;

      console.log(11111111);
      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          _this3.apiPost('/api/order/setRecommenderBy365OrderNo', {
            orderNo: _this3.bindItem.orderNo,
            sysUserId: _this3.bindForm.sysUserId
          }).then(function (res) {
            _this3.$message({
              type: 'success',
              message: '绑定成功!'
            });

            _this3.drawer = false;

            _this3.getList();
          });
        }
      });
    },
    popAffrim: function popAffrim() {
      if (this.remark) {
        this.isCard = true;
      }
    },
    goRefundVip: function goRefundVip() {
      var _this4 = this;

      (0, _api.refundMaoTaiVip)({
        buyUid: this.buyUid,
        orderId: this.orderId,
        facilityCode: this.remark,
        refundAmount: this.refundAmount
      }).then(function (res) {
        _this4.isRemark = false;
        _this4.remark = null;
        _this4.refundAmount = undefined;

        _this4.getList();

        _this4.$message({
          type: 'success',
          message: '退款成功!'
        });
      });
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          (0, _api.payMemberAdmin365)(_this5.ruleForm).then(function (res) {
            _this5.isPay = false;

            _this5.getList();
          });
        } else {
          var data = Object.keys(object);
          var dom = _this5.$refs[data[0]];
          dom.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
        }
      });
    },
    typeChange: function typeChange(e) {
      var _this6 = this;

      this.getList('search');

      if (e !== '9') {
        (0, _api2.getList)({
          pageNo: 1,
          pageSize: 100
        }).then(function (res) {
          _this6.vipList = res.data.records;
        });
        this.requestData.deviceSn = '';
      } else {
        if (this.plusList.length > 0) return;
        (0, _api.listVipPlus)({}).then(function (res) {
          _this6.plusList = res.data;
        });
      }
    },
    refundPlus: function refundPlus(row) {
      this.isRemark = true;
      this.$nextTick(function () {
        this.orderId = row.orderId;
        this.buyUid = row.buyUid;
        this.refundDataItem = row;
      });
    },
    handleDownload: function handleDownload() {
      var _this7 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];

        _this7.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        _this7.requestData.beginPayTime = _this7.time ? _this7.time[0] : '';
        _this7.requestData.endPayTime = _this7.time ? _this7.time[1] : '';
        var data = {};
        Object.keys(_this7.requestData).forEach(function (key) {
          if (_this7.requestData[key]) {
            data[key] = _this7.requestData[key];
          }
        });
        data.pageSize = _this7.requestData.total;
        data.orderType = _this7.requestData.orderType || 0;
        data.pageNo = 1;

        _this7.openLoading();

        (0, _api.listMaoTaiVipPage)(data).then(function (res) {
          list = res.data.items;
          list.forEach(function (item) {
            if (!_this7.setMenuPerms('user_mobile_idCard')) {
              if (item.buyUtphone) {
                item.buyUtphone = item.buyUtphone.replace(item.buyUtphone.substring(3, 7), "****");
              }
            }

            if (item.orderSaleType === 0) {
              item.orderSaleType = '普通';
            }

            if (item.orderSaleType === 1) {
              item.orderSaleType = '小程序团购';
            }

            if (item.orderSaleType === 2) {
              item.orderSaleType = '后台团购';
            }

            if (item.orderType === 9) {
              item.orderTypeTxt = 'MIKA  PLUS';
            }

            if (item.orderType === 10) {
              item.orderTypeTxt = '365会员';
            }

            if (item.orderType === 9) {
              item.number = 1;
            } else {
              item.number = item.deviceSn;
            }
          });

          var data = _this7.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '会员购买记录'
          });
          _this7.downloadLoading = false;

          _this7.openLoading().close();
        }).catch(function (res) {
          _this7.downloadLoading = false;

          _this7.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else if (j === 'memberType') {
            var Obj = {
              1: '普通会员',
              2: '要客',
              3: '365'
            };
            return Obj[v[j]];
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this8 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = '';
        this.removeObj(this.requestData);
      }

      this.requestData.beginPayTime = this.time ? this.time[0] : '';
      this.requestData.endPayTime = this.time ? this.time[1] : '';
      var data = JSON.parse(JSON.stringify(this.requestData));
      data.orderType = this.requestData.orderType || 0;
      (0, _api.listMaoTaiVipPage)(data).then(function (res) {
        _this8.requestData.total = res.data.totalNum;
        res.data.items.forEach(function (item) {
          if (!_this8.setMenuPerms('user_mobile_idCard')) {
            if (item.buyUtphone) {
              item.buyUtphone = item.buyUtphone.replace(item.buyUtphone.substring(3, 7), "****");
            }
          }

          if (item.orderType === 9) {
            item.number = 1;
          } else {
            item.number = item.deviceSn;
          }
        });
        _this8.tableData = res.data.items;
        return;
      });
    }
  }
};
exports.default = _default;