"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addKinds = addKinds;
exports.delKinds = delKinds;
exports.editKinds = editKinds;
exports.getKinds = getKinds;
exports.getList = getList;
exports.getStartCardByKindsId = getStartCardByKindsId;
exports.goExamine = goExamine;
exports.goInOutIn = goInOutIn;
exports.verifyCardStatus = verifyCardStatus;

var _request = _interopRequireDefault(require("@/utils/request"));

function verifyCardStatus(data) {
  return (0, _request.default)({
    url: '/api/card/make/plan/verify/card/status/' + data,
    loading: 'loading',
    method: 'get'
  });
}

function getList(data) {
  return (0, _request.default)({
    url: '/api/card/make/plan/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/plan',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/plan/update',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function delKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/plan/' + data,
    method: 'post'
  });
}

function getKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/plan/' + data,
    loading: 'loading',
    method: 'get'
  });
}

function getStartCardByKindsId(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/getStartCardByKindsId/' + data,
    loading: 'loading',
    method: 'get'
  });
}

function goExamine(data) {
  return (0, _request.default)({
    url: '/api/card/make/plan/examine',
    loading: 'loading',
    method: 'post',
    data: data
  });
}

function goInOutIn(data) {
  return (0, _request.default)({
    url: '/api/card/make/inOut/in',
    loading: 'loading',
    method: 'post',
    data: data
  });
}