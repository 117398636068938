var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("活动分类")]),
    _vm._v(" "),
    _c("div", { staticClass: "manage_search" }, [
      _c("div", { staticClass: "search_left" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search_right" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "activityClass:add",
                  expression: "'activityClass:add'"
                }
              ],
              attrs: {
                size: "small",
                icon: "el-icon-circle-plus-outline",
                type: "primary"
              },
              on: { click: _vm.goAdd }
            },
            [_vm._v("新增类别")]
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "class_table" },
      [
        _c("table-list", {
          attrs: {
            "dea-path": _vm.deaPath,
            "table-data": _vm.tableData,
            "tab-th": _vm.tabTh,
            total: _vm.total,
            "page-no": _vm.pageNo,
            "page-size": _vm.pageSize,
            btn: _vm.btn,
            "btn-type": _vm.btnType,
            operation: _vm.operation,
            "btn-show": "activityClass:edit"
          },
          on: { setBtn: _vm.setBtn }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }