"use strict";

var _interopRequireWildcard = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createParking = createParking;
exports.getPageParkLogList = getPageParkLogList;
exports.getPageParkingList = getPageParkingList;
exports.getParkLog = getParkLog;
exports.getParkLogExport = getParkLogExport;
exports.getParking = getParking;
exports.getParkingList = getParkingList;
exports.updateParking = updateParking;

var _request = _interopRequireDefault(require("@/utils/request"));

var R = _interopRequireWildcard(require("ramda"));

// 停车场管理 start

/**
 * 停车场列表（分页）
 */
function getPageParkingList(params) {
  var loading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'loading';
  return (0, _request.default)({
    url: '/api/car/park/page',
    method: 'get',
    params: params,
    loading: loading
  });
}
/**
 * 停车场列表（不分页）
 */


function getParkingList(params) {
  return (0, _request.default)({
    url: '/api/car/park',
    method: 'get',
    params: params
  });
}
/**
 * 停车场详情
 */


function getParking(params) {
  return (0, _request.default)({
    url: "/api/car/park/" + params.id,
    method: 'get'
  });
}
/**
 * 创建停车场
 */


function createParking(data) {
  return (0, _request.default)({
    url: '/api/car/park',
    method: 'post',
    data: data
  });
}
/**
 * 更新停车场
 */


function updateParking(data) {
  return (0, _request.default)({
    url: '/api/car/park/' + data.id,
    method: 'put',
    data: R.omit(['id'], data)
  });
}
/**
 * 停车场记录列表（分页）
 */


function getPageParkLogList(params) {
  var loading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'loading';
  return (0, _request.default)({
    url: '/api/car/park/log/page',
    method: 'get',
    params: params,
    loading: loading
  });
}
/**
 * 导出停车记录
 */


function getParkLogExport(params) {
  return (0, _request.default)({
    url: '/api/car/park/log/export',
    method: 'get',
    params: params
  });
}
/**
 * 停车记录详情
 */


function getParkLog(params) {
  return (0, _request.default)({
    url: "/api/car/park/log/" + params.id,
    method: 'get'
  });
} // 停车场管理 end