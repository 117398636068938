"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _integral = require("@/api/integral.js");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0,
      pageNo: 1,
      pageSize: 10,
      deaPath: '/integral/addConditionIntegral?id=',
      tabTh: [{
        tit: '条件类型',
        prop: 'cditionType',
        sortable: true,
        align: 'center',
        width: 100
      }, {
        tit: '执行时间',
        prop: 'validStartTime',
        align: 'center',
        width: 180
      }, {
        tit: '截止时间',
        prop: 'validEndTime',
        align: 'center',
        width: 180
      }, {
        tit: '状态',
        prop: 'cditionStatus',
        align: 'center',
        width: 100
      }],
      obj: {
        pageNo: 1,
        pageSize: 10
      },
      typeArr: [{
        value: 1,
        label: '执行中'
      }, {
        value: 2,
        label: '未执行'
      }, {
        value: 3,
        label: '已执行'
      }, {
        value: 4,
        label: '已作废'
      }]
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    /*
      导出
      */
    handleDownload: function handleDownload() {
      var _this = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['条件类型', '执行时间', '截止时间', '状态'];
        var filterVal = ['cditionType', 'validStartTime', 'validEndTime', 'cditionStatus'];
        var list = [];
        (0, _integral.listScoreCditionPage)({
          pageNo: 1,
          pageSize: _this.total
        }).then(function (res) {
          list = res.data.items;
          list.forEach(function (item) {
            item.cditionStatus = item.cditionStatus == 1 ? '启用' : '禁用';

            switch (item.cditionType) {
              case 1:
                item.cditionType = '首单积分';
                break;

              case 2:
                item.cditionType = '生日积分';
                break;

              case 3:
                item.cditionType = '特定日';
                break;

              default:
                break;
            }
          });

          var data = _this.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '条件积分策略'
          });
          _this.downloadLoading = false;
        }).catch(function (res) {
          _this.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/integral/addConditionIntegral');
    },
    init: function init() {
      var _this2 = this;

      console.log(this.obj);
      (0, _integral.listScoreCditionPage)(this.obj).then(function (res) {
        console.log(res);
        _this2.tableData = res.data.items;

        _this2.tableData.forEach(function (item) {
          item.cditionStatus = item.cditionStatus == 1 ? '启用' : '禁用';

          switch (item.cditionType) {
            case 1:
              item.cditionType = '首单积分';
              break;

            case 2:
              item.cditionType = '生日积分';
              break;

            case 3:
              item.cditionType = '特定日';
              break;

            default:
              break;
          }
        });

        _this2.total = res.data.totalNum;
      });
    },
    setTab: function setTab() {},
    remove: function remove() {},
    setDelete: function setDelete() {
      var _this3 = this;

      console.log('sdfsdf');
      this.$confirm('此操作将永久删除该条信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};
exports.default = _default;