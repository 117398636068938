"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _api = require("./api.js");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    var _ref;

    return _ref = {
      imgSrc: this.$store.state.user.imgSrc,
      urlList: _selectUrl.default.urlList,
      appIdArr: _selectUrl.default.appIdArr
    }, (0, _defineProperty2.default)(_ref, "imgSrc", this.$store.state.user.imgSrc), (0, _defineProperty2.default)(_ref, "tableData", []), (0, _defineProperty2.default)(_ref, "requestData", {
      name: null,
      account: null,
      pageSize: 10,
      page: 1
    }), (0, _defineProperty2.default)(_ref, "searchData", []), _ref;
  },
  mounted: function mounted() {
    this.$refs.wTable.setRequestValue('type', 1);
  },
  methods: {
    goAudit: function goAudit(row, type) {
      var _self = this;

      (0, _api.goChangeStatus)({
        id: row.id,
        status: type
      }).then(function (res) {
        _self.$refs.wTable.getList();

        _self.$message({
          type: 'success',
          message: '操作成功!'
        });
      });
    }
  }
};
exports.default = _default;