"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _integral = require("@/api/integral.js");

var _mem = require("@/api/mem.js");

var _php = require("@/api/php.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var validateAcquaintance = function validateAcquaintance(rule, value, callback) {
  if (value < 0) {
    callback(new Error('输入为大于等于0的数'));
  } else {
    callback();
  }
};

var _default = {
  data: function data() {
    return {
      id: '',
      storeList: [],
      form: {
        unpayMode: [],
        sellAmount: '',
        getScore: '',
        companyId: '',
        unstoreParams: []
      },
      companylist: [],
      rules: {
        companyId: [{
          required: true,
          message: '请选择总部'
        }],
        unpayMode: [{
          required: true,
          message: '请选择支付方式'
        }],
        sellAmount: [{
          type: 'number',
          message: '必须为数字值'
        }, {
          required: true,
          message: '请填写消费金额'
        }, {
          validator: validateAcquaintance,
          message: '输入为大于0的数'
        }],
        getScore: [{
          type: 'number',
          message: '必须为数字值'
        }, {
          required: true,
          message: '请填写获得积分'
        }, {
          validator: validateAcquaintance,
          message: '输入为大于0的数'
        }]
      },
      btn: 'add',
      getScore: '',
      sellAmount: '',
      unpayMode: [],
      // 支付方式
      payArr: []
    };
  },
  created: function created() {
    var _this = this;

    this.id = this.$route.query.id;
    this.openLoading();
    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companylist = res.data;

      _this.openLoading().close();

      if (_this.id) {
        _this.init();
      }
    });
  },
  methods: {
    init: function init() {
      var _this2 = this;

      this.openLoading();
      (0, _integral.getScoreSet)(this.id).then(function (res) {
        _this2.openLoading().close();

        if (res.data) {
          _this2.btn = 'edit';

          _this2.companylist.forEach(function (item) {
            if (item.companyCode == res.data.companyCode) {
              _this2.form.companyId = item.companyCode;

              _this2.setPayment(item.companyCode);
            }
          });

          var unpayMode = res.data.scoreSettingUnpaymodelVos;
          unpayMode.forEach(function (item) {
            _this2.form.unpayMode.push(item.unpayModel);
          });
          _this2.form.sellAmount = res.data.sellAmount;
          _this2.form.getScore = res.data.getScore;
          var scoreSettingUnstoreVos = res.data.scoreSettingUnstoreVos;
          scoreSettingUnstoreVos.forEach(function (item) {
            _this2.form.unstoreParams.push(item.storeCode);
          });
        } else {}
      }).catch(function (err) {});
    },
    setPayment: function setPayment(e) {
      var _this3 = this;

      this.form.unpayMode = [];
      this.payArr = [];
      var _sid = '';
      this.form.unstoreParams = [];
      this.companylist.forEach(function (item) {
        if (item.companyCode == e) {
          _sid = item.companyId;
        }
      });
      (0, _mem.listStore)({
        companyId: _sid
      }).then(function (res) {
        _this3.storeList = res.data;
      });
      this.openLoading('不积分支付方式加载中...');
      (0, _php.payment)({
        _sid: _sid
      }).then(function (res) {
        _this3.openLoading().close();

        _this3.payArr = res.data;
      }).catch(function (err) {
        _this3.openLoading().close();
      });
    },
    onSubmit: function onSubmit(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var unpayMode = [];

          _this4.form.unpayMode.forEach(function (item) {
            unpayMode.push({
              unpayCode: item,
              unpayModel: item
            });
          });

          var unstoreParams = [];

          _this4.form.unstoreParams.forEach(function (item) {
            _this4.storeList.forEach(function (row) {
              if (item == row.storeCode) {
                unstoreParams.push({
                  storeCode: row.storeCode,
                  storeName: row.shortName
                });
              }
            });
          });

          var data = {
            unpayModes: unpayMode,
            sellAmount: _this4.form.sellAmount,
            getScore: _this4.form.getScore,
            companyCode: _this4.form.companyId,
            unstoreParams: unstoreParams
          };

          _this4.openLoading('保存中');

          if (_this4.btn == 'add') {
            (0, _integral.addScoreSet)(data).then(function (res) {
              _this4.openLoading().close();

              _this4.$message({
                message: '保存成功',
                type: 'success'
              });

              _this4.$router.go(-1);
            }).catch(function (res) {
              _this4.openLoading().close();
            });
          } else {
            data.settingId = _this4.id;
            (0, _integral.editScoreSet)(data).then(function (res) {
              _this4.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this4.openLoading().close();

              _this4.$router.go(-1);
            }).catch(function (res) {
              _this4.openLoading().close();
            });
          }
        }
      });
      return;

      if (this.disabled) {
        this.btn = '保存';
        this.disabled = false;
      } else {}
    }
  }
};
exports.default = _default;