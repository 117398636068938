import { render, staticRenderFns } from "./label.vue?vue&type=template&id=ef78e48e&scoped=true&"
import script from "./label.vue?vue&type=script&lang=js&"
export * from "./label.vue?vue&type=script&lang=js&"
import style0 from "./label.vue?vue&type=style&index=0&id=ef78e48e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef78e48e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ef78e48e')) {
      api.createRecord('ef78e48e', component.options)
    } else {
      api.reload('ef78e48e', component.options)
    }
    module.hot.accept("./label.vue?vue&type=template&id=ef78e48e&scoped=true&", function () {
      api.rerender('ef78e48e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/label/label.vue"
export default component.exports