"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _report = require("@/api/report.js");

var _integral = require("@/api/integral.js");

var _mem = require("@/api/mem.js");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default,
    AppLink: _Link.default
  },
  props: {
    uid: {
      type: [Number, String],
      default: function _default() {
        return '';
      }
    },
    memberList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      couponSource: {
        0: '用户普通购买',
        1: '周期推荐购买',
        2: '365权益领取',
        3: '领券中心',
        4: '新人礼品领取',
        5: '特价活动',
        6: '365开卡礼',
        7: '流水返券',
        8: '浦发信用卡申卡权益',
        9: '购物卡小程序充值活动',
        10: '签到获取',
        11: '荔星购物卡后台充值获取',
        12: '荔星购物卡后台充值推荐奖励',
        13: '面对面手机领取',
        14: '员工节假日福利',
        15: '荔星购物卡蜻蜓充值',
        16: '电子购物卡团购',
        17: '完善资料',
        18: 'PLUS购买',
        19: '流水返券规则新建',
        20: '系统用户发券',
        21: '抽奖',
        22: '直播间',
        23: '扫码领取购买',
        24: '同城生活',
        25: '定向投发',
        26: '面对面一键领取',
        27: '抖音小程序',
        28: '面对面发券',
        29: '等级权益领取',
        30: '外部商户，第三方平台',
        31: '互动屏游戏',
        32: '优惠券核销',
        33: '关注公众号领取',
        34: "积分赠送活动"
      },
      category: {
        1: '正常销售卡',
        2: '转赠卡',
        3: '荔星购物卡'
      },
      cardType: {
        1: '线上储值卡',
        2: '线下储值卡',
        3: '昂婕老卡',
        4: '提货劵(昂婕)',
        5: '提货券（线上 ）',
        6: '权益卡',
        7: '红包'
      },
      lxzTotal: 0,
      lxzTime: '',
      lxzObj: {
        pageNo: 1,
        pageSize: 10,
        startDate: '',
        endDate: ''
      },
      lxzList: [],
      lxzTab: [{
        prop: 'documentNo',
        label: '单据号',
        width: 90
      }, {
        prop: 'growthTypeName',
        label: '类型',
        width: 90
      }, {
        prop: 'growthValue',
        label: '数值',
        width: 90,
        sortable: true
      }, {
        prop: 'growthNote',
        label: '备注',
        width: 90
      }, {
        prop: 'createTime',
        label: '变动时间',
        width: 90
      }],
      itemShow: false,
      itemStoreName: '',
      itemStoreCode: '',
      itemOrderNo: '',
      terminalCode: '',
      itemOrderAmount: '',
      itemList: [],
      orderPayModes: [],
      scoreValue: '',
      growthValue: '',
      itemTh: [{
        prop: 'goodsCode',
        label: '商品编码',
        width: 80
      }, {
        prop: 'goodsName',
        label: '商品名称',
        width: 150
      }, {
        prop: 'goodsAmount',
        label: '小计',
        width: 70
      }, {
        prop: 'getScore',
        label: '积分',
        width: 50
      }, {
        prop: 'orderTime',
        label: '订单时间',
        width: 140
      }],
      storeLists: [],
      activeName: 'member',
      searchTime: [],
      storeObj: {
        pageNo: 1,
        pageSize: 10,
        changeType: '',
        storeCode: '',
        userId: '',
        documentNo: '',
        startDate: '',
        endDate: ''
      },
      storeTotal: 0,
      storeList: [],
      storeTh: [{
        prop: 'documentNo',
        label: '相关单号',
        width: 80
      }, {
        prop: 'storeName',
        label: '门店名称',
        width: 90
      }, {
        prop: 'terminalCode',
        label: '终端设备号',
        width: 40
      }, {
        prop: 'userMobile',
        label: '会员手机号',
        width: 50
      }, {
        prop: 'changeAmount',
        label: '消费金额',
        sortable: true,
        width: 50
      }, {
        prop: 'discountPrice',
        label: '优惠金额',
        sortable: true,
        width: 50
      }, {
        prop: 'discountScore',
        label: '优惠积分',
        sortable: true,
        width: 50
      }, {
        prop: 'changeScore',
        label: '积分变动',
        sortable: true,
        width: 50
      }, {
        prop: 'changeTypeName',
        label: '变动类型',
        width: 50
      }, {
        prop: 'changeTime',
        label: '变动时间',
        width: 80
      }, {
        prop: 'oprUname',
        label: '操作人',
        width: 50
      }, {
        prop: 'changeRemark',
        label: '备注',
        width: 80,
        align: 'left'
      }],
      cardTh: [{
        props: 'cardNo',
        tit: '卡号',
        width: 150
      }, {
        props: 'name',
        tit: '卡名称',
        width: 150
      }, {
        props: 'balance',
        tit: '卡余额',
        width: 150
      }, {
        props: 'uStatusStr',
        tit: '卡状态',
        width: 100
      }, {
        props: 'category',
        tit: '回传分类',
        width: 100
      }, {
        props: 'scopeType',
        tit: '适用范围',
        width: 100
      }, {
        props: 'createTime',
        tit: '获得时间',
        width: 100
      }],
      tabTh: [{
        props: 'cardNo',
        tit: '卡号',
        width: 150
      }, {
        props: 'statusName',
        tit: '卡状态',
        width: 70
      }, {
        props: 'cardName',
        tit: '卡名称',
        width: 150
      }],
      couponTabTh: [{
        props: 'couponName',
        tit: '优惠券名称',
        width: 150
      }, {
        props: 'couponCode',
        tit: '优惠券编码',
        width: 150
      }, {
        props: 'couponType',
        tit: '类型',
        width: 70
      }, {
        props: 'status',
        tit: '状态',
        width: 70
      }, {
        props: 'useDateTime',
        tit: '有效期',
        width: 180
      }, {
        props: 'receivedTime',
        tit: '领取时间',
        width: 180
      }, {
        props: 'usedTime',
        tit: '使用时间',
        width: 180
      }],
      couponList: [],
      cardList: [],
      pageNo: 1,
      pageSize: 10,
      couponType: 0,
      couponStatus: '',
      couponTime: [],
      couponCode: '',
      total: 0,
      cardSearchType: null,
      openOrder: false,
      orderInfo: {
        orderItemVoList: []
      },
      usePlatOptions: {
        1: '核销助手',
        2: '乐品到家',
        3: '乐购商城',
        4: '昂捷收银机',
        5: '蜻蜓收银机',
        6: '盒马提货券'
      }
    };
  },
  filters: {
    getLabel: function getLabel(key, name) {
      var options = {
        orderType: {
          0: '线下储值卡',
          1: '线上储值卡',
          2: '优惠券',
          3: '积分商城',
          4: '昂婕订单',
          5: '科传订单',
          6: '乐品到家',
          7: '乐购',
          8: '蜻蜓订单',
          9: '付费会员(茅台999)',
          10: '付费会员(365)',
          11: '同城生活',
          12: '硬核补贴'
        },
        orderStatus: {
          1: '待付款',
          2: '已完成',
          3: '已关闭',
          4: '退货',
          5: '撤销订单',
          6: '部分退款'
        },
        payStatus: {
          '-1': '未支付',
          1: '已支付'
        }
      };

      try {
        return options[name][key];
      } catch (error) {
        return '-';
      }
    }
  },
  watch: {
    // 监听data中弹层状态
    itemShow: function itemShow(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
        document.addEventListener('touchmove', {
          passive: false
        }); // 禁止页面滑动
      } else {
        document.body.style.overflow = ''; // 出现滚动条

        document.removeEventListener('touchmove', {
          passive: false
        });
      }
    }
  },
  methods: {
    getOrderDetail: function getOrderDetail(row) {
      var _this = this;

      (0, _report.getOrderinfo)(row.orderNo).then(function (res) {
        if (res.data) {
          _this.openOrder = true;
          _this.orderInfo = res.data;
        } else {
          _this.$message.error('没有找到订单记录');
        }
      });
    },
    goDown: function goDown() {
      this.downloadLoading = true;
      var tHeader = ['变动积分'];
      var filterVal = ['changeScore'];
      this.getHandleDownload(tHeader, filterVal, this.storeList, '变动积分');
    },
    getDea: function getDea(row) {
      var _this2 = this;

      var data = {
        storeCode: row.storeCode,
        orderNo: row.documentNo
      };
      (0, _integral.listOrderItemScore)(data).then(function (res) {
        _this2.itemList = res.data.orderItems;
        _this2.orderPayModes = res.data.orderPayModes || [];
        _this2.scoreValue = res.data.scoreValue;
        _this2.growthValue = res.data.growthValue;
        _this2.itemShow = true;
        _this2.itemStoreName = res.data.orderItems[0].storeName;
        _this2.itemStoreCode = res.data.orderItems[0].storeCode;
        _this2.itemOrderNo = res.data.orderItems[0].orderNo;
        _this2.terminalCode = res.data.orderItems[0].terminalCode;
        _this2.itemOrderAmount = res.data.orderItems[0].orderAmount;
      });
    },
    getUserScore: function getUserScore(type) {
      var _this3 = this;

      if (type == 'search') {
        this.storeObj.pageNo = 1;
        this.storeObj.pageSize = 10;
      }

      if (this.searchTime) {
        this.storeObj.startDate = this.searchTime[0] || '';
        this.storeObj.endDate = this.searchTime[1] || '';
      } else {
        this.storeObj.startDate = '';
        this.storeObj.endDate = '';
      }

      if (this.uid) {
        console.log(this.uid);
        this.storeObj.userId = this.uid;
        this.openLoading();
        (0, _integral.listUserScorePage)(this.storeObj).then(function (res) {
          _this3.openLoading().close();

          _this3.storeTotal = res.data.totalNum;
          _this3.storeList = res.data.items;
        }).catch(function (err) {
          _this3.openLoading().close();
        });
      }
    },
    removeCoupon: function removeCoupon() {
      this.activeName = 'member';
      this.couponList = [];
      this.storeList = [];
      this.cardList = [];
      this.lxzTime = '';
      this.pageNo = 1;
      this.pageSize = 10;
      this.searchTime = '';
      this.removeObj(this.storeObj);
      this.removeObj(this.lxzObj);
      this.lxzList = [];
    },
    setUid: function setUid(uid) {
      this.pageNo = 1; //  this.uid = uid
    },
    getCoupon: function getCoupon(type) {
      var _this4 = this;

      if (type == 'search') {
        this.pageNo = 1;
        this.pageSize = 100;
      }

      var data = {
        uid: this.uid,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        couponType: this.couponType,
        couponStatus: this.couponStatus,
        couponCode: this.couponCode
      };

      if (this.couponTime) {
        data.startDate = this.couponTime[0] || '';
        data.endDate = this.couponTime[1] || '';
      } else {
        data.startDate = '';
        data.endDate = '';
      }

      if (this.uid) {
        (0, _report.listCouponByUid)(data).then(function (res) {
          _this4.openLoading().close();

          _this4.couponList = res.data.items;
          _this4.total = res.data.totalNum;
        }).catch(function (res) {
          _this4.openLoading().close();
        });
      }
    },
    getCard: function getCard() {
      var _this5 = this;

      if (this.uid) {
        (0, _report.listMyCardStored)({
          uid: this.uid,
          type: this.cardSearchType || null
        }).then(function (res) {
          var list = res.data.cardStoredInfoVos;
          list.forEach(function (item) {
            item.category = _this5.category[item.category];
            item.scopeType = item.scopeType == 1 ? '全部适用' : '部分适用';
          });
          _this5.cardList = list;
        });
      }
    },
    getListGrowthLogPage: function getListGrowthLogPage(type) {
      var _this6 = this;

      if (this.uid) {
        if (type == 'search') {
          this.lxzObj.pageNo = 1;
          this.lxzObj.pageSize = 10;
        }

        this.lxzObj.uid = this.uid;
        this.lxzObj.startDate = this.lxzTime[0] || '';
        this.lxzObj.endDate = this.lxzTime[1] || '';
        this.openLoading();
        (0, _report.listGrowthLogPage)(this.lxzObj).then(function (res) {
          _this6.openLoading().close();

          _this6.lxzList = res.data.items;
          _this6.lxzTotal = res.data.totalNum;
        }).catch(function (err) {
          _this6.openLoading().close();
        });
      }
    },
    handleClick: function handleClick(tab, event) {
      var _this7 = this;

      if (tab.name == 'coupon') {
        if (this.couponList.length == 0) {
          this.getCoupon();
        }
      }

      if (tab.name == 'card') {
        if (this.cardList.length == 0) {
          this.getCard();
        }
      }

      if (tab.name == 'store') {
        this.getUserScore();

        if (this.storeLists.length == 0) {
          (0, _mem.listStore)({}).then(function (res) {
            _this7.storeLists = res.data;
          });
        }
      }

      if (tab.name == 'lxz') {
        if (this.lxzList.length == 0) {
          this.getListGrowthLogPage();
        }
      }
    }
  }
};
exports.default = _default2;