var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 提货券统计 "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "提货券名称" },
                model: {
                  value: _vm.requestData.activityName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "activityName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.activityName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "提货券编码" },
                model: {
                  value: _vm.requestData.activityCode,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "activityCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.activityCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "search_input",
                  attrs: {
                    multiple: "",
                    size: "mini",
                    clearable: "",
                    placeholder: "提货券状态"
                  },
                  model: {
                    value: _vm.requestData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "status", $$v)
                    },
                    expression: "requestData.status"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "待审核", value: 0 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已撤回", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "待启动", value: 2 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "进行中", value: 3 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已结束", value: 4 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已终止", value: 5 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-date-picker", {
                staticClass: "search_input",
                attrs: {
                  size: "mini",
                  type: "datetimerange",
                  "range-separator": "—",
                  format: "yyyy年MM月dd日 HH:mm:ss",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "start-placeholder": "开始日期",
                  "default-time": ["00:00:00", "23:59:59"],
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.getList("search")
                }
              }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "mini", icon: "el-icon-refresh" },
              on: {
                click: function($event) {
                  return _vm.getList("remove")
                }
              }
            },
            [_vm._v("重置")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "time",
              label: "周期",
              align: "center",
              width: "260"
            },
            slot: "time",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.activityStartTime) +
                        "-" +
                        _vm._s(scope.row.activityEndTime) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              "min-width": "80"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 0
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("待审核")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 1
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("已撤回")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 2
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("待启动")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 3
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("进行中")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 4
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("已结束")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 5
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("已终止")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "objectHelpType",
              label: "助力人类型",
              align: "center",
              "min-width": "80"
            },
            slot: "objectHelpType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.objectHelpType == 1
                      ? _c("span", { attrs: { type: "warning" } }, [
                          _vm._v("新会员")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.objectHelpType == 2
                      ? _c("span", { attrs: { type: "primary" } }, [
                          _vm._v("365会员")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.objectHelpType == 0
                      ? _c("span", { attrs: { type: "success" } }, [
                          _vm._v("所有会员")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "activityBeHelpedObjectType",
              label: "发起人类型",
              align: "center",
              "min-width": "80"
            },
            slot: "activityBeHelpedObjectType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.activityBeHelpedObjectType == 1
                      ? _c("span", { attrs: { type: "warning" } }, [
                          _vm._v("新会员")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.activityBeHelpedObjectType == 2
                      ? _c("span", { attrs: { type: "primary" } }, [
                          _vm._v("365会员")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.activityBeHelpedObjectType == 0
                      ? _c("span", { attrs: { type: "success" } }, [
                          _vm._v("所有会员")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }