var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("新增会员")]),
      _vm._v(" "),
      _c("div", { staticClass: "integral" }, [
        _c(
          "div",
          { staticClass: "integral_right" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  rules: _vm.rules,
                  size: "small",
                  model: _vm.form,
                  "label-width": "120px"
                }
              },
              [
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_left" }, [_vm._v("基本信息")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form_right" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form_item",
                              attrs: {
                                prop: "userName",
                                label: "会员姓名：",
                                required: ""
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "form_input",
                                attrs: { autocomplete: "off" },
                                model: {
                                  value: _vm.form.userName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "userName", $$v)
                                  },
                                  expression: "form.userName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form_item",
                              attrs: {
                                prop: "userSex",
                                label: "性别：",
                                required: ""
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "form_input",
                                  attrs: { placeholder: "请选择性别" },
                                  model: {
                                    value: _vm.form.userSex,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "userSex", $$v)
                                    },
                                    expression: "form.userSex"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "男", value: 1 }
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "女", value: 2 }
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "未知", value: 0 }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form_item",
                              attrs: {
                                prop: "userMobile",
                                label: "手机号码：",
                                required: ""
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "form_input",
                                attrs: {
                                  onkeypress:
                                    "return event.keyCode>=48&&event.keyCode<=57",
                                  maxlength: 11,
                                  autocomplete: "off"
                                },
                                model: {
                                  value: _vm.form.userMobile,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "userMobile", $$v)
                                  },
                                  expression: "form.userMobile"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form_item",
                              attrs: {
                                prop: "userIdNo",
                                label: "身份证号：",
                                required: ""
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "form_input",
                                attrs: { maxlength: "18", autocomplete: "off" },
                                model: {
                                  value: _vm.form.userIdNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "userIdNo", $$v)
                                  },
                                  expression: "form.userIdNo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_left" }, [_vm._v("基本信息")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form_right" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          attrs: {
                            prop: "cardNo",
                            label: "会员卡号：",
                            required: ""
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_input",
                            attrs: {
                              autocomplete: "off",
                              placeholder: "请输入卡号"
                            },
                            model: {
                              value: _vm.form.cardNo,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "cardNo", $$v)
                              },
                              expression: "form.cardNo"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          attrs: { label: "会员卡密：" }
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_input",
                            attrs: {
                              disabled: "",
                              placeholder: "请刷卡读取会员卡密"
                            },
                            model: {
                              value: _vm.form.mid,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "mid", $$v)
                              },
                              expression: "form.mid"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.goMid }
                            },
                            [_vm._v("读取会员卡密")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          attrs: {
                            prop: "mkStoreId",
                            label: "开卡机构：",
                            required: ""
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              attrs: {
                                filterable: "",
                                placeholder: "请选择门店"
                              },
                              model: {
                                value: _vm.form.mkStoreId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "mkStoreId", $$v)
                                },
                                expression: "form.mkStoreId"
                              }
                            },
                            _vm._l(_vm.storeList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.shortName,
                                  value: item.storeCode
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { margin: "30px 0 0 150px" },
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.submitForm()
            }
          }
        },
        [_vm._v("保存")]
      ),
      _vm._v(" "),
      _vm.setMenuPerms("mainuser:addBig")
        ? _c(
            "el-button",
            {
              staticStyle: { margin: "30px 0 0 50px" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("big")
                }
              }
            },
            [_vm._v("保存为大客户")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { margin: "30px 0 0 50px" },
          on: {
            click: function($event) {
              return _vm.resetForm("form")
            }
          }
        },
        [_vm._v("重置")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.popShow,
              expression: "popShow"
            }
          ],
          staticClass: "memberQuery_pop"
        },
        [
          _c(
            "div",
            { staticClass: "memberQuery_pop_box" },
            [
              _c(
                "p",
                { staticStyle: { "font-weight": "bold", color: "red" } },
                [_vm._v("请刷卡")]
              ),
              _vm._v(" "),
              _c("el-input", {
                ref: "midInput",
                attrs: { autofocus: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    _vm.popShow = false
                  }
                },
                model: {
                  value: _vm.form.mid,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "mid", $$v)
                  },
                  expression: "form.mid"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.popShow = false
                        }
                      }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }