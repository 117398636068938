"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.sort");

var _api = require("./api.js");

var _public = require("@/utils/public.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default,
    SelectTabel: _getCoupon.default
  },
  data: function data() {
    return {
      isShowSelect: false,
      selectsType: '',
      temList: [],
      weekList: _public.weekList,
      popType: '',
      couponShow: false,
      type: [{
        label: '积分商品',
        value: 0
      }, {
        label: '优惠券',
        value: 1
      }, {
        label: '乐购商品',
        value: 2
      }, {
        label: '券包',
        value: 3
      }],
      rules: {
        productType: [{
          required: true,
          message: '请选择物品类型'
        }],
        goodsLIst: [{
          required: true,
          message: '请添加物品'
        }],
        weekTag: [{
          required: true,
          message: '请选择周期'
        }],
        cycleCategoryId: [{
          required: true,
          message: '请选择栏目'
        }]
      },
      ruleForm: {
        cycleCategoryId: null,
        goodsLIst: [],
        weekTag: null,
        productType: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id) {
      (0, _api.getGoodsData)({
        id: this.ruleForm.id
      }).then(function (res) {
        _this.ruleForm.goodsLIst.push({
          productType: res.data.productType,
          productCode: res.data.productCode,
          productName: res.data.productName,
          sort: res.data.sort,
          cycleStock: res.data.cycleStock,
          status: res.data.status,
          productDetail: res.data.productDetail || null,
          price: res.data.price || null
        });

        _this.ruleForm.cycleCategoryId = res.data.cycleCategoryId;
        _this.ruleForm.weekTag = res.data.weekTag;

        _this.weekCahnge(res.data.weekTag);
      });
    }
  },
  methods: {
    closeSelect: function closeSelect() {
      this.isShowSelect = false;
    },
    setTabelData: function setTabelData(e) {
      var _this2 = this;

      if (e.length > 0) {
        //this.ruleForm.couponList = []
        e.forEach(function (item) {
          var addData = {
            productType: null,
            productCode: null,
            cycleCategoryId: null,
            productName: null,
            status: 1,
            sort: null,
            productDetail: null,
            price: null,
            cycleStock: 0
          };

          if (_this2.popType === 'coupon') {
            addData.productType = 1;
            addData.productCode = item.uuid;
            addData.productName = item.name;
            addData.maxNum = item.totalNumber == 0 ? 9999999 : item.totalNumber; // this.ruleForm.goodsLIst[this.count].prizeName = e.name
          }

          if (_this2.popType === 'goods') {
            addData.productType = 0;
            addData.productCode = item.productCode;
            addData.productName = item.productName;
            addData.maxNum = item.totalNumber == 0 ? 9999999 : item.totalNumber;
          }

          if (_this2.popType === 'legouGoods') {
            var goodsJson = JSON.stringify({
              pic_url: item.pic_url,
              price: item.price,
              name: item.name,
              discount_rate: item.discount_rate,
              original_price: item.original_price,
              id: item.id
            });
            addData.productDetail = item.price ? goodsJson : null;
            addData.productCode = item.id;
            addData.productName = item.name;
            addData.productType = 2;
          }

          if (_this2.popType === 'couponBag') {
            addData.productCode = item.uuid;
            addData.productName = item.name;
            addData.productType = 3;
          }

          _this2.ruleForm.goodsLIst.push(addData); //this.ruleForm.couponList.push(item.uuid)

        });
      }

      this.isShowSelect = false;
      return;
    },
    weekCahnge: function weekCahnge(e) {
      var _this3 = this;

      (0, _api.listItem)({
        weekTag: e
      }).then(function (res) {
        _this3.temList = res.data;
      });
    },
    changeLimit: function changeLimit(e) {
      if (e === 0) {
        this.ruleForm.limitNumber = 0;
      }
    },
    getRelevances: function getRelevances() {
      var _this4 = this;

      if (this.ruleForm.id) {
        this.ruleForm.goodsLIst.push({
          productType: null,
          productCode: null,
          cycleCategoryId: null,
          productName: null,
          status: 1,
          sort: null,
          productDetail: null,
          price: null,
          cycleStock: 0
        });
      } else {
        if (this.ruleForm.productType === null) {
          this.$message.error('请先选择物品类型');
          return;
        }

        var selectType = {
          0: 'goods',
          1: 'coupon',
          2: 'legouGoods',
          3: 'couponBag'
        };
        this.popType = selectType[this.ruleForm.productType];
        this.isShowSelect = true;
        this.$nextTick(function () {
          _this4.$refs.selectPop.init();
          /* const couponList = this.ruleForm.couponList.map((item) => {
             return { linkId: item }
           })*/


          _this4.$refs.selectPop.assignCoupon([]);
        });
        return;
        this.$refs.coupon1.getList(this.popType);
        this.couponShow = true;
      }
    },

    /*
      选择奖励项
      */
    setAward: function setAward(e, index) {
      this.ruleForm.goodsLIst[index].productCode = null;
      this.ruleForm.goodsLIst[index].cycleCategoryId = null; // this.ruleForm.goodsLIst[index].status = null

      this.ruleForm.goodsLIst[index].productDetail = null;
      this.ruleForm.goodsLIst[index].productName = null;
      this.ruleForm.goodsLIst[index].sort = null;
      this.ruleForm.goodsLIst[index].price = null;
      this.ruleForm.goodsLIst[index].cycleStock = 0;
    },

    /*
      打开弹框
      */
    getAward: function getAward(e, index) {
      this.count = index;
      var selectType = {
        0: 'goods',
        1: 'coupon',
        2: 'legouGoods',
        3: 'couponBag'
      };
      this.popType = selectType[e];
      this.$refs.coupon1.getList(this.popType, this.ruleForm.goodsLIst[index].productCode, e);
      this.couponShow = true;
    },

    /*
      删除奖励项
      */
    delAward: function delAward(index) {
      this.ruleForm.goodsLIst.splice(index, 1);
    },
    setCoupon: function setCoupon(e) {
      console.log(e);

      if (this.popType === 'coupon') {
        this.ruleForm.goodsLIst[this.count].productCode = e.uuid;
        this.ruleForm.goodsLIst[this.count].productName = e.name;
        this.ruleForm.goodsLIst[this.count].maxNum = e.totalNumber == 0 ? 9999999 : e.totalNumber; // this.ruleForm.goodsLIst[this.count].prizeName = e.name
      }

      if (this.popType === 'goods') {
        this.ruleForm.goodsLIst[this.count].productCode = e.productCode;
        this.ruleForm.goodsLIst[this.count].productName = e.productName;
        this.ruleForm.goodsLIst[this.count].maxNum = e.totalNumber == 0 ? 9999999 : e.totalNumber;
      }

      if (this.popType === 'legouGoods') {
        var goodsJson = JSON.stringify({
          pic_url: e.pic_url,
          price: e.price,
          name: e.name,
          discount_rate: e.discount_rate,
          original_price: e.original_price,
          id: e.id
        });
        this.ruleForm.goodsLIst[this.count].productDetail = e.price ? goodsJson : null;
        this.ruleForm.goodsLIst[this.count].productCode = e.id;
        this.ruleForm.goodsLIst[this.count].productName = e.name;
      }

      if (this.popType === 'couponBag') {
        this.ruleForm.goodsLIst[this.count].productCode = e.uuid;
        this.ruleForm.goodsLIst[this.count].productName = e.name;
      }

      this.couponShow = false;
      this.popType = '';
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.ruleForm.goodsLIst.forEach(function (item) {
            item.cycleCategoryId = _this5.ruleForm.cycleCategoryId;
          });

          if (_this5.ruleForm.id) {
            var data = {
              id: _this5.ruleForm.id,
              cycleCategoryId: _this5.ruleForm.cycleCategoryId,
              productType: _this5.ruleForm.goodsLIst[0].productType,
              productName: _this5.ruleForm.goodsLIst[0].productName,
              productCode: _this5.ruleForm.goodsLIst[0].productCode,
              status: _this5.ruleForm.goodsLIst[0].status,
              sort: _this5.ruleForm.goodsLIst[0].sort,
              productDetail: _this5.ruleForm.goodsLIst[0].productDetail,
              price: _this5.ruleForm.goodsLIst[0].price,
              cycleStock: _this5.ruleForm.goodsLIst[0].cycleStock
            };
            (0, _api.editData)(data).then(function (res) {
              _this5.$router.go(-1);

              _this5.$message({
                type: 'success',
                message: '编辑成功!'
              });
            });
          } else {
            (0, _api.addData)(_this5.ruleForm.goodsLIst).then(function (res) {
              _this5.$router.go(-1);

              _this5.$message({
                type: 'success',
                message: '添加成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;