"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _coupon = require("@/api/coupon.js");

var _manage = require("@/api/manage.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    Tinymce: _Tinymce.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      tableData: [],
      storeIdProps: {
        children: 'childInstitutions',
        value: 'code',
        label: 'shortName'
      },
      storeId: '',
      grop: [],
      // 组列表
      baseId: '',
      // 活动Id
      popShow: false,
      // 添加项弹框
      itemName: '',
      obj: {
        itemName: '',
        // 投票组标题
        itemTitle: '',
        // 投票项标题
        itemThumbnail: '',
        // 投票项缩略图
        itemAbstract: '',
        // 投票项摘要
        itemContent: '',
        // 投票项详情
        storeId: '' // 会员机构

      },
      records: [],
      tabTh: [{
        tit: '组名',
        prop: 'itemName',
        width: 200
      }, {
        tit: '标题',
        prop: 'itemTitle',
        width: 200
      }, {
        tit: '描述摘要',
        prop: 'itemAbstract',
        width: 250
      }],
      pageNo: 1,
      pageSize: 10,
      total: 0
    };
  },
  created: function created() {
    this.baseId = Number(this.$route.query.baseId);
    /* 组列表*/

    /* listVoteItemNames({activityId:this.baseId}).then((res)=>{
         console.log(res)
         this.grop=res.data
       }) */

    this.getList();
    this.getListVoteItemNames();
  },
  methods: {
    handleChange: function handleChange(value) {
      console.log(value);
      var len = value.length;
      this.obj.storeId = value[len - 1];
      console.log(value);
    },
    assetChange: function assetChange(val) {},
    remove: function remove() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.itemName = '';
      this.getList();
    },

    /* 组列表*/
    getListVoteItemNames: function getListVoteItemNames() {
      var _this = this;

      (0, _coupon.listVoteItemNames)({
        activityId: this.baseId
      }).then(function (res) {
        _this.grop = res.data;
      });
      (0, _manage.listInstitutionTree)({
        status: 2
      }).then(function (res) {
        _this.tableData = _this.getTreeData(res.data);
      });
    },
    getTreeData: function getTreeData(data) {
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].status != 2) {
          data[i].disabled = true; // 如果类型为t或者v，就增加禁用选项
        }

        if (data[i].childInstitutions.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].childInstitutions = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].childInstitutions);
        }
      }

      return data;
    },

    /* 数据列表*/
    getList: function getList() {
      var _this2 = this;

      var data = {
        activityId: this.baseId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        itemName: this.itemName
      };
      this.openLoading();
      (0, _coupon.listVoteItemPage)(data).then(function (res) {
        _this2.openLoading().close();

        _this2.records = res.data.records;
        _this2.total = res.data.total;
      }).catch(function (res) {
        _this2.openLoading().close();
      });
    },

    /*
      保存项
      */
    goAddVoteItem: function goAddVoteItem() {
      var _this3 = this;

      var data = this.obj;

      if (!data.activityId) {
        data.activityId = Number(this.baseId);
      }

      if (data.createTime) {
        delete data.createTime;
      }

      if (data.itemId) {
        this.openLoading('努力保存中');
        (0, _coupon.editVoteItem)(data).then(function (res) {
          _this3.openLoading().close();

          _this3.popShow = false;
          _this3.obj = {};
          _this3.storeId = '';

          _this3.getListVoteItemNames();

          _this3.getList();

          _this3.$message({
            message: '编辑成功',
            type: 'success'
          });

          _this3.$refs.editor.setContent(''); // this.$refs.editor.setContent()

        }).catch(function (res) {
          _this3.openLoading().close();
        });
      } else {
        this.openLoading('努力保存中');
        (0, _coupon.addVoteItem)(data).then(function (res) {
          _this3.obj = {};
          _this3.storeId = '';

          _this3.openLoading().close();

          _this3.popShow = false;

          _this3.getListVoteItemNames();

          _this3.getList();

          _this3.$message({
            message: '保存成功',
            type: 'success'
          });

          _this3.obj = {};
        }).catch(function (res) {
          _this3.openLoading().close();
        });
      }
    },

    /* 添加项弹框*/
    addGroupList: function addGroupList(index) {
      console.log(this.$refs.cascader.getCheckedNodes());
      this.$refs.editor.setContent('');
      this.count = index;
      this.popShow = true;
    },

    /* 活动详情*/
    change: function change(index) {
      console.log(index);
    },

    /* 关闭弹框*/
    closePop: function closePop() {
      this.obj = {};
      this.storeId = '';
      this.popShow = false;
    },

    /*
      编辑主图
      */
    setTemThumbnail: function setTemThumbnail(index) {
      this.obj.itemThumbnail = index;
    },

    /*
      删除
      */
    goDelVoteItem: function goDelVoteItem(row, index) {
      var _this4 = this;

      this.$confirm('此操作将删除该投票项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.openLoading('删除中');

        (0, _coupon.delVoteItem)({
          itemId: row.itemId
        }).then(function (res) {
          _this4.openLoading().close();

          _this4.records.splice(index, 1);

          _this4.$message({
            type: 'success',
            message: '删除成功!'
          });

          console.log(res);
        }).catch(function (res) {
          _this4.openLoading().close();
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    /*
      编辑
      */
    goEdit: function goEdit(index) {
      console.log(this.records[index]);
      this.obj = this.records[index];
      this.storeId = this.obj.storeId;
      this.$refs.editor.setContent(this.obj.itemContent || '');
      this.popShow = true;
    }
  }
};
exports.default = _default;