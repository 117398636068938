"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '黑卡会员权益兑换',
      list: [{
        tit: '兑换权益',
        txtArr: '<p>大客户权益兑换</p>',
        imgPath: require('@/assets/tab/major1.png'),
        path: '/member/major/search'
      }, {
        tit: '兑换记录',
        txtArr: '<p>大客户权益兑换记录</p>   ',
        imgPath: require('@/assets/tab/major2.png'),
        path: '/member/major/list'
      }]
    };
  }
};
exports.default = _default;