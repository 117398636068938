"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _utils = require("@/utils");

var _report = require("@/api/report.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      obj: {
        pageNo: 1,
        pageSize: 10,
        orderNo: '',
        mobile: '',
        idNo: '',
        storedCode: '',
        sendUname: '',
        startDate: '',
        endDate: ''
      },
      downloadLoading: false,
      total: 0,
      time: [],
      tableData: [],
      tabTh: [{
        props: 'orderNo',
        tit: '流水号',
        width: 120
      }, {
        props: 'toUmobile',
        tit: '会员手机号',
        width: 100
      }, {
        props: 'toUidno',
        tit: '身份证号',
        width: 120
      }, {
        props: 'sendNum',
        tit: '发券数量',
        width: 80
      }, {
        props: 'storedName',
        tit: '门店',
        width: 120
      }, {
        props: 'sendUname',
        tit: '操作人',
        width: 120
      }, {
        props: 'sendTime',
        tit: '发券时间',
        width: 140
      }]
    };
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
      }

      if (type == 'remove') {
        this.obj.pageNo = 1;
        this.obj.orderNo = '';
        this.obj.mobile = '';
        this.obj.idNo = '';
        this.obj.storedCode = '';
        this.obj.sendUname = '';
        this.time = [];
      }

      if (this.time.length > 0) {
        this.obj.startDate = this.time[0];
        this.obj.endDate = this.time[1];
      } else {
        this.obj.startDate = '';
        this.obj.endDate = '';
      }

      this.openLoading();
      console.log(this.obj);
      (0, _report.listSendCouponMTLog)(this.obj).then(function (res) {
        _this.openLoading().close();

        _this.total = res.data.totalNum;
        _this.tableData = res.data.items;

        _this.tableData.forEach(function (item) {
          item.sendNum = 1;

          if (!_this.setMenuPerms('user_mobile_idCard')) {
            if (item.toUmobile) {
              item.toUmobile = item.toUmobile.replace(item.toUmobile.substring(3, 7), "****");
            }

            if (item.toUidno) {
              item.toUidno = item.toUidno.replace(item.toUidno.substring(3, 15), "****");
            }
          }
        });
      }).catch(function (res) {
        _this.openLoading().close();
      });
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['流水号', '会员手机号', '身份证号', '发券数量', '门店', '操作人', '发券时间'];
        var filterVal = ['orderNo', 'toUmobile', 'toUidno', 'sendNum', 'storedName', 'sendUname', 'sendTime'];
        var list = [];

        if (_this2.time.length > 0) {
          _this2.obj.startTime = _this2.time[0];
          _this2.obj.endTime = _this2.time[1];
        }

        var data = _this2.obj;
        data.pageSize = _this2.total;
        (0, _report.listSendCouponMTLog)(_this2.obj).then(function (res) {
          _this2.obj.pageNo = 1;
          _this2.obj.pageSize = 10;

          _this2.init();

          list = res.data.items;
          list.forEach(function (item) {
            item.sendNum = 1;

            if (!_this2.setMenuPerms('user_mobile_idCard')) {
              if (item.toUmobile) {
                item.toUmobile = item.toUmobile.replace(item.toUmobile.substring(3, 7), "****");
              }

              if (item.toUidno) {
                item.toUidno = item.toUidno.replace(item.toUidno.substring(3, 15), "****");
              }
            }
          });

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '茅台统计'
          });
          _this2.downloadLoading = false;
        }).catch(function (res) {
          _this2.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;