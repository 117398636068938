var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 用户推广指标统计 "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "11",
                  size: "mini",
                  placeholder: "推广人手机号"
                },
                model: {
                  value: _vm.requestData.mobile,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "mobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.mobile"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "推广人手姓名" },
                model: {
                  value: _vm.requestData.username,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "username",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.username"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { clearable: "", size: "mini", placeholder: "职务" },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.requestData.position,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "position", $$v)
                    },
                    expression: "requestData.position"
                  }
                },
                _vm._l(_vm.positions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { size: "mini", placeholder: "状态" },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.requestData.complementStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "complementStatus", $$v)
                    },
                    expression: "requestData.complementStatus"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "已完成", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "未完成", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  clearable: "",
                  size: "mini",
                  type: "month",
                  placeholder: "选择年月",
                  "value-format": "yyyy-MM"
                },
                on: {
                  change: function($event) {
                    return _vm.getList("search")
                  }
                },
                model: {
                  value: _vm.requestData.endTime,
                  callback: function($$v) {
                    _vm.$set(_vm.requestData, "endTime", $$v)
                  },
                  expression: "requestData.endTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.getList("search")
                }
              }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "mini", icon: "el-icon-refresh" },
              on: {
                click: function($event) {
                  return _vm.getList("remove")
                }
              }
            },
            [_vm._v("重置")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              align: "center",
              width: "120"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.complementStatus == 2 && scope.row.kpiStatus == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.goUserKpiReward(scope.row)
                              }
                            }
                          },
                          [_vm._v("奖励发放")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("slide", {
        attrs: { "is-card": _vm.isCard, text: "确认发放奖励" },
        on: {
          "update:isCard": function($event) {
            _vm.isCard = $event
          },
          "update:is-card": function($event) {
            _vm.isCard = $event
          },
          click: _vm.goUserKpi
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }