"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = require("./api.js");

var _deposit = require("@/api/deposit");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      id: '',
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableShow: false,
      list: [],
      name: '',
      uuid: '',
      surplusNumber: '',
      number: '',
      tabTh: [{
        prop: 'number',
        label: ' 调整数量',
        width: 80
      }, {
        prop: 'oprName',
        label: '操作人姓名',
        width: 80
      }, {
        prop: 'createTime',
        label: ' 操作时间',
        width: 80
      }],
      ruleForm: {
        number: null,
        id: null
      },
      rules: {
        number: [{
          required: true,
          message: '请正确输入库存'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 0) {
              callback(new Error('请输入大于0值'));
            } else {
              callback();
            }
          }
        }]
      }
    };
  },
  created: function created() {
    this.ruleForm.cid = this.$route.query.id;
    this.getInit();
    this.init();
  },
  methods: {
    getInit: function getInit() {
      var _this = this;

      (0, _deposit.getCardStoredById)(this.ruleForm.cid).then(function (res) {
        _this.name = res.data.name;
        _this.uuid = res.data.uuid;
      });
      (0, _api.getCardStoreStock)({
        cid: this.ruleForm.cid
      }).then(function (res) {
        _this.number = res.data.number;
        _this.ruleForm.number = res.data.number;
        _this.surplusNumber = res.data.surplusNumber;
      });
    },
    init: function init() {
      var _this2 = this;

      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        cid: this.ruleForm.cid
      };
      (0, _api.listCardStoreStockLog)(data).then(function (res) {
        _this2.list = res.data.items;
        _this2.total = res.data.totalNum;
        _this2.tableShow = true;
      });
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          console.log('修改库存');
          (0, _api.updateCardStoreStock)(_this3.ruleForm).then(function (res) {
            _this3.init();

            _this3.getInit();
          });
        } else {
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
exports.default = _default;