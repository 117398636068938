var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gb" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px", size: "small" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "手机号码：" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "300px" },
                attrs: { type: "number" },
                model: {
                  value: _vm.mobile,
                  callback: function($$v) {
                    _vm.mobile = $$v
                  },
                  expression: "mobile"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.getUserInfo } },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { "label-width": "120px", size: "small" } },
        [
          _c("el-form-item", { attrs: { label: "会员信息：" } }),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-form-item", { attrs: { label: "姓名：" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.user.realname) + "\n        "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-form-item", { attrs: { label: "身份证号：" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.user.idNo) + "\n        "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-form-item", { attrs: { label: "会员积分：" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.user.score) + "\n        "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-form-item", { attrs: { label: "会员UID：" } }, [
                    _vm._v("\n          " + _vm._s(_vm.user.uid) + "\n        ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            rules: _vm.rules,
            disabled: _vm.disabled,
            model: _vm.obj,
            "label-width": "120px",
            size: "small"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "所属门店：", prop: "storeCode" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "maxWidth",
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.obj.storeCode,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "storeCode", $$v)
                    },
                    expression: "obj.storeCode"
                  }
                },
                _vm._l(_vm.storeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.storeId,
                      attrs: {
                        label: item.shortName + "（" + item.storeCode + "）",
                        value: item.storeCode
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "变动类型：", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.obj.type,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "type", $$v)
                    },
                    expression: "obj.type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("增加")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("减少")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "变动积分：", prop: "changeScore" } },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                style: { color: _vm.obj.type == 1 ? "#67C23A" : "#F56C6C" },
                model: {
                  value: _vm.obj.changeScore,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "changeScore", _vm._n($$v))
                  },
                  expression: "obj.changeScore"
                }
              }),
              _vm._v("积分\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "相关单据号：", prop: "documentNo" } },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                model: {
                  value: _vm.obj.documentNo,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "documentNo", $$v)
                  },
                  expression: "obj.documentNo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "变动原因：" } },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 },
                  placeholder: "请输入内容"
                },
                model: {
                  value: _vm.obj.changeRemark,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "changeRemark", $$v)
                  },
                  expression: "obj.changeRemark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即创建")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }