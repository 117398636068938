"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addKinds = addKinds;
exports.delKinds = delKinds;
exports.editKinds = editKinds;
exports.getInfoCheck = getInfoCheck;
exports.getKinds = getKinds;
exports.getList = getList;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/info',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/update',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function delKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/' + data,
    method: 'post'
  });
}

function getKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/' + data,
    loading: 'loading',
    method: 'get'
  });
}

function getInfoCheck(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/check',
    loading: 'loading',
    method: 'post',
    data: data
  });
}