"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _tableList = _interopRequireDefault(require("@/components/tableList"));

var _coupon = require("@/api/coupon");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default,
    Pagination: _Pagination.default,
    AppLink: _Link.default
  },
  data: function data() {
    return {
      getRowKeys: function getRowKeys(row) {
        return row.labelId;
      },
      expands: [],
      operation: 2,
      btn: '查看详情',
      btnType: 'primary',
      total: 0,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [{
        tit: '标签名称',
        prop: 'name',
        width: 300
      }, {
        tit: '创建时间',
        prop: 'createTime',
        sortable: true,
        width: 300
      }, {
        tit: '状态',
        prop: 'status',
        width: 100
      }]
    };
  },
  watch: {},
  destroyed: function destroyed() {
    var initInfo = {
      pageNo: this.pageNo,
      pageSize: this.pageSize
    };
    this.$store.dispatch('buffer/setOrientLabelList', initInfo);
  },
  created: function created() {
    if (this.$store.state.buffer.orientLabelList.pageNo) {
      var initInfo = this.$store.state.buffer.orientLabelList;
      this.pageNo = initInfo.pageNo;
      this.pageSize = initInfo.pageSize;
    }

    this.init();
  },
  methods: {
    init: function init(e) {
      var _this = this;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      (0, _coupon.listPutOrientationLabelPage)({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(function (res) {
        console.log(res);
        _this.tableData = res.data.records;
        _this.total = res.data.total;

        _this.tableData.forEach(function (item) {
          item.value = JSON.parse(item.value);

          switch (item.status) {
            case 1:
              item.status = '启用';
              break;

            case -1:
              item.status = '停用';
              break;
          }
        });
      });
      /* listPutOrientationLabel().then((res) => {
        //console.log(res)
        this.tableData = res.data
        this.tableData.forEach((item) => {
          item.value = JSON.parse(item.value)
          switch (item.status) {
            case 1:
              item.status = '启用'
              break
            case -1:
              item.status = '停用'
              break
          }
        })
      }) */
    },
    // 折叠面板每次只能展开一行
    expandSelect: function expandSelect(row, expandedRows) {
      var that = this;
      console.log('sdfsdfsdf');

      if (expandedRows.length) {
        that.expands = [];

        if (row) {
          that.expands.push(row.labelId);
        }
      } else {
        that.expands = [];
      }
    },
    setBtn: function setBtn(row) {
      this.$router.push('/coupon/couponPut/addLabel?labelId=' + row.labelId);
    },
    goAdd: function goAdd() {
      this.$router.push('/coupon/couponPut/addLabel');
    }
  }
};
exports.default = _default;