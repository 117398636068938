"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _auth = require("@/utils/auth");

var _api = require("@/api/api.js");

var _compressorjs = _interopRequireDefault(require("compressorjs"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getToken } from 'api/qiniu'
var _default = {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      imgList: [],
      listObj: {},
      fileList: [],
      imgSrc: this.$store.state.user.imgSrc,
      action: this.$store.state.user.loadUrl
    };
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        'Token': (0, _auth.getToken)()
      };
    }
  },
  methods: {
    checkAllSuccess: function checkAllSuccess() {
      var _this = this;

      return Object.keys(this.listObj).every(function (item) {
        return _this.listObj[item].hasSuccess;
      });
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      var arr = Object.keys(this.listObj).map(function (v) {
        return _this2.listObj[v];
      });

      if (!this.checkAllSuccess()) {
        this.$message('Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!');
        return;
      }

      this.$emit('successCBK', this.fileList);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    onChange: function onChange(file, fileList) {
      console.log(file);
    },
    handleSuccess: function handleSuccess(res, file, fileList) {
      var _this3 = this;

      this.openLoading().close();

      if (res.ret === 1000) {
        this.fileList.push({
          url: this.imgSrc + res.data,
          uid: file.uid
        });
      } else if (res.ret === 6000) {
        this.$confirm(res.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this3.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    onError: function onError(res) {
      this.openLoading().close();
    },
    handleRemove: function handleRemove(file) {
      var _this4 = this;

      this.fileList.forEach(function (item, key) {
        if (item.uid === file.uid) {
          _this4.fileList.splice(key, 1);
        }
      });
    },
    onPreview: function onPreview() {
      this.openLoading('努力上传中');
    },
    beforeUpload: function beforeUpload(file) {
      var _this5 = this;

      var _self = this;

      this.openLoading('努力上传中');
      var isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif' || file.type === 'image/webp';
      var isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.openLoading().close();
        this.$message.error('上传图片只能是JPG/PNG/GIF格式!');
        return false;
      }

      if (!isLt2M) {
        this.openLoading().close();
        this.$message.error('上传图片大小不能超过 2MB!');
        return false;
      }

      var isSize = new Promise(function (resolve, reject) {
        var URL = window.URL || window.webkitURL;
        var img = new Image();

        img.onload = function () {
          img.width > 750 ? reject() : resolve();
        };

        img.src = URL.createObjectURL(file.raw);
      }).then(function () {
        var quality = 1;

        if (file.size / 1024 > 500) {
          quality = 0.4;
        } else if (file.size / 1024 > 300) {
          quality = 0.5;
        } else if (file.size / 1024 > 200) {
          quality = 0.6;
        }

        console.log(quality);
        new _compressorjs.default(file.raw, {
          quality: quality,
          // 图片压缩比例，取值范围为0~1
          success: function success(result) {
            var formData = new FormData();
            formData.append('file', result, result.name);
            (0, _api.goUploadImg)(formData).then(function (res) {
              _self.fileList.push({
                url: _self.imgSrc + res.data,
                uid: file.uid
              });
            }); // 上传图片请求 示例，使用时替换成自己的上传请求即可
          },
          error: function error(err) {
            console.log(err.message);
          }
        });
        return;
        (0, _api.goUploadImg)(file).then(function (res) {
          console.log(res); //_self.$emit('setCover', res.data)
        });
        return file;
      }, function () {
        _this5.$message.error("\u4E0A\u4F20\u7684\u56FE\u7247\u5BBD\u5EA6\u4E0D\u80FD\u5927\u4E8E750");

        _this5.openLoading().close();

        return Promise.reject();
      }); //return isJPG && isLt2M && isSize
    }
  }
};
exports.default = _default;