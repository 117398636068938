var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "type" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    " + _vm._s(_vm.$route.meta.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "requestParamsForm",
          staticClass: "request-params",
          attrs: { inline: true, model: _vm.requestParams, size: "small" }
        },
        [
          _c(
            "el-form-item",
            [
              _vm.setMenuPerms("lavant_score")
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handApply }
                    },
                    [_vm._v("申请积分")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "code" } },
            [
              _c("el-input", {
                attrs: { placeholder: "申请单号" },
                model: {
                  value: _vm.requestParams.code,
                  callback: function($$v) {
                    _vm.$set(_vm.requestParams, "code", $$v)
                  },
                  expression: "requestParams.code"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "phone" } },
            [
              _c("el-input", {
                attrs: { placeholder: "用户手机号" },
                model: {
                  value: _vm.requestParams.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.requestParams, "phone", $$v)
                  },
                  expression: "requestParams.phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "storeId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "消费门店",
                    "remote-method": _vm.handRemoteStore,
                    loading: _vm.storeLoading
                  },
                  model: {
                    value: _vm.requestParams.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.requestParams, "storeId", $$v)
                    },
                    expression: "requestParams.storeId"
                  }
                },
                _vm._l(_vm.storeOptions, function(sItem, sIndex) {
                  return _c(
                    "el-option",
                    {
                      key: sIndex,
                      attrs: { value: sItem.storeCode, label: sItem.fullName }
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(sItem.fullName))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#8492a6",
                            "font-size": "13px"
                          }
                        },
                        [_vm._v(_vm._s(sItem.storeCode))]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "applyPerson" } },
            [
              _c("el-input", {
                attrs: { placeholder: "申请人" },
                model: {
                  value: _vm.requestParams.applyPerson,
                  callback: function($$v) {
                    _vm.$set(_vm.requestParams, "applyPerson", $$v)
                  },
                  expression: "requestParams.applyPerson"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "applyWay" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "申请方式" },
                  model: {
                    value: _vm.requestParams.applyWay,
                    callback: function($$v) {
                      _vm.$set(_vm.requestParams, "applyWay", $$v)
                    },
                    expression: "requestParams.applyWay"
                  }
                },
                _vm._l(_vm.applyWayOptions, function(methodOption) {
                  return _c("el-option", {
                    key: methodOption.value,
                    attrs: {
                      value: methodOption.value,
                      label: methodOption.label
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "状态" },
                  model: {
                    value: _vm.requestParams.status,
                    callback: function($$v) {
                      _vm.$set(_vm.requestParams, "status", $$v)
                    },
                    expression: "requestParams.status"
                  }
                },
                _vm._l(_vm.statusOptions, function(statusOption) {
                  return _c("el-option", {
                    key: statusOption.value,
                    attrs: {
                      value: statusOption.value,
                      label: statusOption.label
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "requestTimes" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "310px" },
                attrs: {
                  type: "datetimerange",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "default-time": ["12:00:00"],
                  format: "yyyy-MM-dd HH:mm",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.requestTimes,
                  callback: function($$v) {
                    _vm.requestTimes = $$v
                  },
                  expression: "requestTimes"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handQuery } },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.handReset } },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-top": "15px" },
          attrs: {
            data: _vm.list,
            size: "mini",
            "header-cell-style": { background: "#f5f5f5" },
            border: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              align: "center",
              width: "80"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "申请单号", prop: "code", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户姓名", prop: "name", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户手机号", prop: "phone", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "会员类型", prop: "phone", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.getOptionsValue({
                            value: row.bigType,
                            options: _vm.memberTypeOptions
                          })
                        ) +
                        "\n        " +
                        _vm._s(row.smallTypeName) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "门店", prop: "storeName", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "品牌", prop: "typeName", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单金额", prop: "price", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "编码", prop: "barcode", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "获得积分数", prop: "integral", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "申请时间", prop: "createTime", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "status", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "span",
                      {
                        style: {
                          color: _vm.getOptionsValue({
                            value: row.status,
                            options: _vm.statusOptions,
                            labelKey: "color"
                          })
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.getOptionsValue({
                                value: row.status,
                                options: _vm.statusOptions
                              })
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "申请人", prop: "applyPerson", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "申请方式", prop: "applyWay", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.getOptionsValue({
                            value: row.applyWay,
                            options: _vm.applyWayOptions
                          })
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核人", prop: "auditPerson", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核时间", prop: "auditTime", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核备注", prop: "auditMsg", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              prop: "action",
              fixed: "right",
              width: "150",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "text-btn",
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handDetail(row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    row.status === 0 && _vm.setMenuPerms("receiptUpload:check")
                      ? _c(
                          "el-button",
                          {
                            staticClass: "text-btn",
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handCheck(row)
                              }
                            }
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.requestParams.pageNo,
          limit: _vm.requestParams.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.requestParams, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.requestParams, "pageSize", $event)
          },
          pagination: _vm.handPageChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }