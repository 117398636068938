"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      columns: [{
        label: '订单号',
        prop: 'orderNo',
        width: 100
      }, {
        label: '商品码',
        prop: 'goodsCode',
        width: 100
      }, {
        label: '数量',
        prop: 'goodsNumber',
        width: 100
      }, {
        label: '单价',
        prop: 'amountOriginal',
        width: 100
      }, {
        slot: 'operate'
      }],
      tableData: [],
      id: null
    };
  },
  created: function created() {
    var _this = this;

    this.id = this.$route.query.id;

    if (this.id) {
      (0, _api.getOrderDea)({
        orderNo: this.id
      }).then(function (res) {
        _this.tableData = res.data;
      });
    }
  },
  methods: {}
};
exports.default = _default;