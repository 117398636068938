"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _deposit = require("@/api/deposit.js");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    wTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      requestData: {
        orderNo: '',
        mobile: '',
        pageNo: 1,
        pageSize: 10,
        total: 0,
        beginCreateTime: '',
        endCreateTime: ''
      },
      time: '',
      tableData: [],
      columns: [{
        label: '订单号',
        prop: 'orderNo',
        width: 100
      }, {
        label: '卡号',
        prop: 'cardNo',
        width: 50
      }, {
        label: '会员姓名',
        prop: 'realname',
        width: 50
      }, {
        label: '手机号',
        prop: 'mobile',
        width: 140
      }, {
        label: '购卡名称',
        prop: 'note',
        width: 100
      }, {
        label: '储值卡号',
        prop: 'cardNo',
        width: 100
      },
      /*  {
        label: '领取编码',
        prop: 'cardNo',
        width: 100
      }, */
      {
        label: '时间',
        prop: 'createTime',
        width: 100
      }, {
        label: '金额',
        prop: 'payMoney',
        width: 80
      },
      /*  {
        label: '卡状态',
        prop: '',
        width: 100
      }, */
      {
        slot: 'operate'
      }]
    };
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    refundCard: function refundCard(row) {
      var _this = this;

      this.$confirm('此操作将退款, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _deposit.refund)({
          id: row.id,
          cardNo: row.cardNo
        }).then(function (res) {
          _this.getList();

          _this.$message({
            type: 'success',
            message: '退款成功!'
          });
        });
      }).catch(function () {});
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['订单号', '卡号', '会员姓名', '手机号', '购卡名称', '储值卡号', '付款时间', '购卡金额'];
        var filterVal = ['orderNo', 'cardNo', 'realname', 'mobile', 'note', 'cardNo', 'createTime', 'payMoney'];
        var list = [];
        _this2.requestData.beginCreateTime = _this2.time[0] || '';
        _this2.requestData.endCreateTime = _this2.time[1] || '';
        var data = {};
        Object.keys(_this2.requestData).forEach(function (key) {
          if (_this2.requestData[key]) {
            data[key] = _this2.requestData[key];
          }
        });
        data.pageSize = _this2.requestData.total;
        data.pageNo = 1;

        _this2.openLoading();

        (0, _deposit.listCardTradeRefundPage)(data).then(function (res) {
          if (!_this2.setMenuPerms('user_mobile_idCard')) {
            res.data.items.forEach(function (item) {
              if (item.mobile) {
                item.mobile = item.mobile.replace(item.mobile.substring(3, 7), "****");
              }
            });
          }

          list = res.data.items;

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: _this2.cardNo
          });
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        }).catch(function (res) {
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type == 'search') {
        this.requestData.pageNo = 1;
      }

      if (type == 'remove') {
        this.time = '';
        this.removeObj(this.requestData);
      }

      this.requestData.beginCreateTime = this.time[0] || '';
      this.requestData.endCreateTime = this.time[1] || '';
      (0, _deposit.listCardTradeRefundPage)(this.requestData).then(function (res) {
        if (!_this3.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.mobile) {
              item.mobile = item.mobile.replace(item.mobile.substring(3, 7), "****");
            }
          });
        }

        _this3.tableData = res.data.items;
        _this3.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;