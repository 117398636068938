"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {
    onDialogVisible: function onDialogVisible() {
      this.$emit('onDialogVisible');
    }
  }
};
exports.default = _default;