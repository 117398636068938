"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      searchData: [{
        type: 'input',
        placeholder: '用户手机号码',
        value: 'mobile'
      }, {
        type: 'radio',
        placeholder: '订单状态',
        value: 'status',
        select: [{
          value: 1,
          label: "待支付"
        }, {
          value: 2,
          label: "已支付"
        }, {
          value: 3,
          label: "支付失败"
        }]
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }],
      SRCPREFIX: this.$store.state.user.imgSrc,
      memberType: {
        /* 1，普通 2 ,365 3,一星 4，二星 5三星*/
        1: '普通',
        2: '365会员',
        3: '一星Lavant',
        4: '二星Lavant',
        5: '三星Lavant'
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.$refs.table.setRequestValue('parkId', 3);
    this.$refs.table.getList();
  }
};
exports.default = _default;