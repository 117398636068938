var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 配置一码贵州消费券金额 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              ref: "totalAmount",
              attrs: { label: "总余额：", prop: "totalAmount" }
            },
            [
              _c("el-input-number", {
                attrs: { min: 0, controls: false },
                model: {
                  value: _vm.ruleForm.totalAmount,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "totalAmount", $$v)
                  },
                  expression: "ruleForm.totalAmount"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form-item",
                {
                  ref: "startTime",
                  attrs: { label: "活动周期：", prop: "startTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择开始时间"
                    },
                    model: {
                      value: _vm.ruleForm.startTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "startTime", $$v)
                      },
                      expression: "ruleForm.startTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "endTime",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { "label-width": "0", prop: "endTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      disabled: !_vm.ruleForm.startTime,
                      "picker-options": _vm.pickerTime,
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": "23:59:59",
                      placeholder: "选择结束时间"
                    },
                    model: {
                      value: _vm.ruleForm.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "endTime", $$v)
                      },
                      expression: "ruleForm.endTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "门店金额：", prop: "storeActivityAmountConfigs" }
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "200px" },
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.getRelevances }
                },
                [_vm._v("添加门店")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.ruleForm.storeActivityAmountConfigs,
                    size: "mini",
                    border: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "门店编码",
                      "min-width": "150",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                ref:
                                  "storeActivityAmountConfigs." +
                                  scope.$index +
                                  ".storeCode",
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "storeActivityAmountConfigs." +
                                    scope.$index +
                                    ".storeCode",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入门店编码"
                                    }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: scope.row.storeCode,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "storeCode", $$v)
                                    },
                                    expression: "scope.row.storeCode"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "门店总金额",
                      "min-width": "150",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                ref:
                                  "storeActivityAmountConfigs." +
                                  scope.$index +
                                  ".totalAmount",
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "storeActivityAmountConfigs." +
                                    scope.$index +
                                    ".totalAmount",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入门店总金额"
                                    }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: scope.row.totalAmount,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "totalAmount", $$v)
                                    },
                                    expression: "scope.row.totalAmount"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "100", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认删除该物品？" },
                                on: {
                                  confirm: function($event) {
                                    return _vm.delAward(scope.$index)
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "danger",
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "mini"
                                    },
                                    slot: "reference"
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动阶梯：", prop: "amountStage" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "200px" },
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.addStage }
                },
                [_vm._v("添加阶梯")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.ruleForm.amountStage, function(item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "消费满：",
                          prop: "amountStage." + index + ".amount",
                          rules: [{ required: true, message: "请输入阶梯金额" }]
                        }
                      },
                      [
                        _c("el-input-number", {
                          attrs: { min: 0, controls: false },
                          model: {
                            value: item.amount,
                            callback: function($$v) {
                              _vm.$set(item, "amount", $$v)
                            },
                            expression: "item.amount"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "优惠金额：",
                          prop: "amountStage." + index + ".discountAmount",
                          rules: [{ required: true, message: "请输入优惠金额" }]
                        }
                      },
                      [
                        _c("el-input-number", {
                          attrs: { min: 0, controls: false },
                          model: {
                            value: item.discountAmount,
                            callback: function($$v) {
                              _vm.$set(item, "discountAmount", $$v)
                            },
                            expression: "item.discountAmount"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认删除该阶梯？" },
                            on: {
                              confirm: function($event) {
                                return _vm.delStage(index)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("删除阶梯")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "阶梯周期：" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.addTime(index)
                          }
                        }
                      },
                      [_vm._v("添加周期")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: item.discountAmountLimits,
                          size: "mini",
                          border: ""
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "选择日期",
                            "min-width": "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      { attrs: { "label-width": "0" } },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            type: "datetime",
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            placeholder: "选择日期"
                                          },
                                          model: {
                                            value: scope.row.limitDay,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "limitDay",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.limitDay"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "总次数",
                            "min-width": "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      { attrs: { "label-width": "0" } },
                                      [
                                        _c("el-input-number", {
                                          attrs: { min: 0, controls: false },
                                          model: {
                                            value: scope.row.limitTimes,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "limitTimes",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.limitTimes"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            width: "100",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: { title: "确认删除该物品？" },
                                        on: {
                                          confirm: function($event) {
                                            return _vm.delTime(
                                              index,
                                              scope.$index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "danger",
                                            attrs: {
                                              slot: "reference",
                                              type: "text",
                                              size: "mini"
                                            },
                                            slot: "reference"
                                          },
                                          [_vm._v("删除")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-divider")
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "已消耗金额：" } },
            [
              _c(
                "el-table",
                {
                  attrs: { data: _vm.discountOrders, size: "mini", border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "日期",
                      prop: "createTime",
                      "min-width": "150",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "参与次数",
                      prop: "discountOrderNum",
                      "min-width": "150",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单笔金额",
                      prop: "discountStage",
                      "min-width": "150",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "总金额",
                      "min-width": "150",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.discountOrderNum *
                                    scope.row.discountStage
                                ) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "productCodes",
              attrs: { label: "不可用商品码", prop: "productCodes" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex",
                  staticStyle: { "align-items": "center" }
                },
                [
                  _c(
                    "a",
                    {
                      staticStyle: {
                        "margin-right": "20px",
                        "line-height": "1"
                      },
                      attrs: {
                        href: _vm.downloadUrl,
                        download: "一码贵州不参与活动商品码.xlsx"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "success",
                            icon: "el-icon-download"
                          }
                        },
                        [_vm._v("下载模板")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("upload-excel-component", {
                    attrs: {
                      "btn-text": "批量上传",
                      type: "btn",
                      "on-success": _vm.handleSuccess,
                      "before-upload": _vm.beforeUpload
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.productCodes.length > 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "max-height": "200px",
                        padding: "10px",
                        "border-radius": "10px",
                        overflow: "auto",
                        border: "1px solid #999999",
                        "margin-top": "20px"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.ruleForm.productCodes.join("、")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }