"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _api = require("./api.js");

var _regionData = _interopRequireDefault(require("@/utils/regionData.json"));

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    wTable: _index.default,
    coupon: _getCoupon.default
  },
  data: function data() {
    return {
      typeList: [{
        label: '发放优惠券',
        value: 1
      }, {
        label: '储值卡变动',
        value: 2
      }, {
        label: '积分变动',
        value: 3
      }, {
        label: '成长值变动',
        value: 4
      }],
      couponShow: false,
      columns: [{
        label: '券编码',
        prop: 'couponCode',
        width: 100
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 100
      }, {
        label: '券类型',
        prop: 'couponType',
        width: 100
      }, {
        label: '面额/折扣',
        prop: 'discount',
        width: 100
      }, {
        label: '有效期',
        prop: 'validTime',
        width: 100
      }, {
        slot: 'sentNumber'
      }],
      ruleForm: {
        id: '',
        eventType: '',
        eventName: '',
        eventStatus: '',
        coupons: [],
        eventRemark: ''
      },
      rules: {
        eventType: [{
          required: true,
          message: '请选择类型'
        }],
        eventName: [{
          required: true,
          message: '请输入名称'
        }],
        eventStatus: [{
          required: true,
          message: '请选择状态'
        }],
        eventRemark: [{
          required: true,
          message: '请输入备注'
        }],
        coupons: [{
          required: true,
          message: '请选择优惠券'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id) {
      (0, _api.getEventById)(this.ruleForm.id).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (key) {
          if (key === 'coupons') {
            _this.ruleForm[key] = JSON.parse(res.data.eventAttr);
          } else {
            _this.ruleForm[key] = res.data[key];
          }
        });
        console.log(_this.ruleForm);
      });
    }
  },
  methods: {
    getRelevances: function getRelevances() {
      this.$refs.couponPop.init();
      var arr = [];

      if (this.ruleForm.coupons.length > 0) {
        this.ruleForm.coupons.forEach(function (item) {
          arr.push({
            linkId: item.couponCode
          });
        });
      }

      this.$refs.couponPop.assignCoupon(arr);
      this.couponShow = true;
    },
    setCoupons: function setCoupons(e) {
      var _this2 = this;

      var arr = [];

      if (e.length > 0) {
        e.forEach(function (item) {
          var data;

          var flag = _this2.ruleForm.coupons.some(function (value, index) {
            data = value;
            return value.couponCode == item.uuid;
          });

          if (flag) {
            arr.push(data);
          } else {
            var discount, validTime, couponType;

            if (item.couponType == '折扣券') {
              discount = item.discount / 10 + '折';
            } else {
              discount = item.denomination + '元';
            }

            if (item.validTimeType == 1) {
              validTime = item.validBeginTime + '-' + item.validEndTime;
            } else {
              validTime = '领取后' + item.validDay + '天有效';
            }

            arr.push({
              couponCode: item.uuid,
              couponName: item.name,
              couponType: item.couponType,
              discount: discount,
              sentNumber: 1,
              validTime: validTime
            });
          }
        });
      }

      this.ruleForm.coupons = arr;
      this.couponShow = false;
    },
    closeCouponPop: function closeCouponPop() {
      this.couponShow = false;
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          if (_this3.ruleForm.id) {
            (0, _api.editEvent)(_this3.ruleForm).then(function (res) {
              _this3.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this3.$router.go(-1);
            });
          } else {
            (0, _api.addEvent)(_this3.ruleForm).then(function (res) {
              _this3.$message({
                type: 'success',
                message: '新增成功!'
              });

              _this3.$router.go(-1);
            });
          }
        } else {
          var data = Object.keys(object);
          var dom = _this3.$refs[data[0]];

          if (data[0].indexOf('coupons.') >= 0) {
            dom = _this3.$refs['coupons'];
          } else {
            dom = _this3.$refs[data[0]];
          }

          dom.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    setVendorImg: function setVendorImg(e, type) {
      this.ruleForm[type] = e;
    },
    setCover: function setCover(e, index) {
      if (index >= this.ruleForm.bussImgs.length) {
        this.ruleForm.bussImgs.push(e);
      } else {
        this.ruleForm.bussImgs[index] = e;
      }
    }
  }
};
exports.default = _default;