"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _index = require("@/views/system/manage/index.js");

var _template = require("@/api/template.js");

var _auth = require("@/utils/auth");

var _api = require("./api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _qingForm;

    return {
      qingForm: (_qingForm = {
        'name': '',
        'storeId': '',
        'storeName': '',
        'status': 1,
        'logoUrl': '',
        'barcodeId': '',
        'barcodeName': '',
        'salesCopyUp': '',
        'alignmentSalesUp': 1,
        'salesCopyDown': '',
        'alignmentSalesDown': 1,
        'refundOrderCopy': '',
        'alignmentRefund': 1
      }, (0, _defineProperty2.default)(_qingForm, "storeId", null), (0, _defineProperty2.default)(_qingForm, "_barcode", null), _qingForm),
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        logoUrl: [{
          required: true,
          message: '请上传顶部logo',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }],
        salesCopyUp: [{
          required: true,
          message: '请输入文案内容',
          trigger: 'blur'
        }],
        alignmentSalesUp: [{
          required: true,
          message: '请选择对齐方式',
          trigger: 'change'
        }],
        salesCopyDown: [{
          required: true,
          message: '请输入文案内容',
          trigger: 'blur'
        }],
        alignmentSalesDown: [{
          required: true,
          message: '请选择对齐方式',
          trigger: 'change'
        }],
        refundOrderCopy: [{
          required: true,
          message: '请输入文案内容',
          trigger: 'blur'
        }],
        alignmentRefund: [{
          required: true,
          message: '请选择对齐方式',
          trigger: 'change'
        }],
        storeId: [{
          required: true,
          message: '请选择门店',
          trigger: 'change'
        }],
        barcodeId: [{
          required: true,
          message: '请选择二维码模板',
          trigger: 'change'
        }]
      },
      // 门店数据
      storeloading: false,
      storeOptions: [],
      // 页面模板
      pageloading: false,
      pageOptions: []
    };
  },
  computed: {
    UPLOADURL: function UPLOADURL() {
      return this.$store.state.user.loadUrl;
    },
    IMGPRIFIX: function IMGPRIFIX() {
      return this.$store.state.user.imgSrc;
    },
    istype: function istype() {
      var name = this.$route.name;

      if (name == 'qingtingEdit') {
        return 2;
      } else if (name == 'qingtingDetail') {
        return 3;
      } else {
        return 1;
      }
    },
    headers: function headers() {
      return {
        'ContentType': 'multipart/form-data',
        'Token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    var id = this.$route.params.id;

    if (id) {
      this.getData(id);
    } else {
      this.remoteStoreList();
      this.remotePageList();
    }
  },
  methods: {
    pageChange: function pageChange(p) {
      var pageData = this.pageOptions.find(function (item) {
        return item.pageCode === p;
      });
      this.qingForm.barcodeId = pageData ? pageData.pageCode : '';
      this.qingForm.barcodeName = pageData ? pageData.pageName : '';
    },
    storeChange: function storeChange(s) {
      var storeData = this.storeOptions.find(function (item) {
        return item.storeCode === s;
      });
      this.qingForm.storeId = storeData ? storeData.storeCode : '';
      this.qingForm.storeName = storeData ? storeData.fullName : '';
    },
    // 取消
    handleCancel: function handleCancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/finance/invoice/qingting',
        replace: true
      });
    },
    // 提交
    handleCommit: function handleCommit() {
      var _this = this;

      this.$refs['qingForm'].validate(function (valid) {
        if (valid) {
          delete _this.qingForm.createTime;
          (0, _api.saveStorePrintMsg)(_this.qingForm).then(function (res) {
            _this.$message.success('保存成功');

            _this.handleCancel();
          });
        }
      });
    },
    getBase64: function getBase64(file) {
      // 把图片转成base64编码
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        var imgResult = '';
        reader.readAsDataURL(file);

        reader.onload = function () {
          imgResult = reader.result;
        };

        reader.onerror = function (error) {
          reject(error);
        };

        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    getFile: function getFile(file, fileList) {
      var _this2 = this;

      // 上传头像
      this.getBase64(file.raw).then(function (res) {
        _this2.qingForm.logoUrl = res;
        return;

        _this2.$http.post('home/ImgUpload', {
          'img': res
        }).then(function (result) {
          if (result.body.status === 200) {
            _this2.imageUrl = result.body.data;
          } else {
            Toast('图片上传失败');
          }
        });
      });
    },
    beforeUpload: function beforeUpload(file) {
      var isSize = file.size / 1024 / 1024 < 1;

      if (!isSize) {
        this.$message.error('图片最高可支持1M');
      }

      return isSize;
    },
    uploadSuccess: function uploadSuccess(res) {
      var _this3 = this;

      if (res.ret === 1000) {
        this.qingForm.logoUrl = res.data;
      } else if (res.ret === 6000) {
        this.$confirm(res.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this3.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    getData: function getData(id) {
      var _this4 = this;

      (0, _api.detailStorePrintMsg)(id).then(function (res) {
        res.data.storeId = res.data.storeId;
        _this4.qingForm = res.data;

        _this4.remoteStoreList(res.data.storeName);

        _this4.remotePageList(res.data.barcodeName);
      });
    },
    remotePageList: function remotePageList(query) {
      var _this5 = this;

      this.pageloading = true;
      var params = {
        pageNo: 1,
        pageSize: 20,
        pageName: query
      };
      return (0, _template.listPage)(params).then(function (res) {
        _this5.pageOptions = res.data.items;
        _this5.pageloading = false;
      }).catch(function () {
        _this5.pageloading = false;
      });
    },
    remoteStoreList: function remoteStoreList(query) {
      var _this6 = this;

      this.storeloading = true;
      var params = {
        pageNo: 1,
        pageSize: 20,
        fullName: query
      };
      return (0, _index.listStorePage)(params).then(function (res) {
        _this6.storeOptions = res.data.items;
        _this6.storeloading = false;
      }).catch(function (err) {
        _this6.storeloading = false;
      });
    }
  }
};
exports.default = _default;