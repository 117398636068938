"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      time: null,
      requestData: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        inOutType: null
      },
      tableData: [],
      columns: [{
        label: 'ID',
        prop: 'id',
        width: 60
      }, {
        label: '卡号段起',
        prop: 'cardSegmentNoStart',
        width: 100
      }, {
        label: '卡号段未',
        prop: 'cardSegmentNoEnd',
        width: 100
      }, {
        label: '卡种名称',
        prop: 'cardKindsName',
        width: 100
      }, {
        label: '卡数量',
        prop: 'num',
        width: 100
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'inOutType',
        label: '出入库',
        prop: 'inOutType',
        width: 80
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status',
        width: 80
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(value) {
      if (value) {
        this.requestData.activityStartTime = value[0];
        this.requestData.activityEndTime = value[0];
      } else {
        this.requestData.activityStartTime = null;
        this.requestData.activityEndTime = null;
      }
    }
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {},
  methods: {
    goInfoExport: function goInfoExport(row) {
      var _this = this;

      (0, _api.infoExport)(row.planId).then(function (res) {
        if (res.data.length > 0) {
          var tHeader = ['卡号', '卡密', '磁条卡信息'];
          var filterVal = ['cardNo', 'validateCode', 'magstripeCode'];

          _this.getHandleDownload(tHeader, filterVal, res.data, '卡号,磁码,卡校验码');
        } else {
          _this.$message.error('没有信息导出');
        }
      });
    },

    /*
    zipPayQrcode,
    generatePayQrcode
    */
    getZipPayQrcode: function getZipPayQrcode(row) {
      (0, _api.zipPayQrcode)(row.planId);
    },
    getPayQrcode: function getPayQrcode(row) {
      var _this2 = this;

      (0, _api.generatePayQrcode)(row.planId).then(function (res) {
        _this2.getList();
      });
    },
    downPayZip: function downPayZip(row) {
      var url = 'https://xl-cdn.xinglico.com/' + row.qrcodePayUrl;
      console.log(url);
      window.open(url, '_blank');
    },
    goVerifyCardStatus: function goVerifyCardStatus(row) {
      var _this3 = this;

      (0, _api.verifyCardStatus)(row.planId).then(function (res) {
        _this3.getList();
      });
    },
    downZip: function downZip(row) {
      var url = 'https://xl-cdn.xinglico.com/' + row.qrcodeUrl;
      console.log(url);
      window.open(url, '_blank');
    },
    getZipQrcode: function getZipQrcode(row) {
      (0, _api.zipQrcode)(row.planId);
    },
    getQrcode: function getQrcode(row) {
      var _this4 = this;

      (0, _api.generateQrcode)(row.planId).then(function (res) {
        _this4.getList();
      });
    },
    goAudit: function goAudit(row, status) {
      var _this5 = this;

      (0, _api.goExamine)({
        id: row.id,
        examStatus: status
      }).then(function (res) {
        _this5.getList();
      });
    },
    handleDownload: function handleDownload() {
      var _this6 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this6.requestData[key]) {
          data[key] = _this6.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getListPage)(data).then(function (res) {
        list = res.data.records;
        _this6.downloadLoading = false;

        _this6.getHandleDownload(tHeader, filterVal, list, '卡管理列表');
      }).catch(function (res) {
        _this6.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this7 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getListPage)(this.requestData).then(function (res) {
        _this7.tableData = res.data.records;
        _this7.requestData.total = res.data.total;
        return;
      });
    }
  }
};
exports.default = _default;