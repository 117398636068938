var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-service-reserve" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("预约服务")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "svip-service-reserve_name" }, [
        _vm._v("\n    服务名称：" + _vm._s(_vm.serviceInfo.name) + "\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "svip-service-reserve_name" }, [
        _vm._v("\n    可预约人群标签：" + _vm._s(_vm.label || "") + "\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "svip-service-reserve_name" }, [
        _vm._v("\n      黑名单：" + _vm._s(_vm.labelNo || "") + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-width": "150px",
            model: _vm.ruleForm,
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "预约客户：", prop: "member" } },
            [
              _c(
                "el-select",
                {
                  key: "id",
                  staticClass: "form_input",
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "请输入手机号",
                    "remote-method": _vm.getMemberOptions,
                    loading: _vm.memberOptionsLoading
                  },
                  on: { change: _vm.selectMember },
                  model: {
                    value: _vm.ruleForm.member,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "member", $$v)
                    },
                    expression: "ruleForm.member"
                  }
                },
                _vm._l(_vm.memberOptions, function(member) {
                  return _c("el-option", {
                    key: member.value,
                    attrs: {
                      label:
                        "" +
                        member.label +
                        (!member.value
                          ? "（无手机号）"
                          : "（" + member.value + "）"),
                      value: member,
                      disabled: !member.value
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.shopTreeData.length
            ? _c(
                "el-form-item",
                { staticClass: "form_item", attrs: { label: "预约品牌：" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "shopId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              attrs: {
                                size: "small",
                                placeholder: "请选择商店",
                                filterable: "",
                                autocomplete: "off"
                              },
                              on: { change: _vm.selectShop },
                              model: {
                                value: _vm.ruleForm.shopId,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "shopId", $$v)
                                },
                                expression: "ruleForm.shopId"
                              }
                            },
                            _vm._l(_vm.shopList, function(shop) {
                              return _c(
                                "el-option",
                                {
                                  key: shop.id,
                                  attrs: { label: shop.name, value: shop.id }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(shop.name) +
                                      "\n            "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { prop: "brandId" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              attrs: {
                                size: "small",
                                placeholder: "请选择品牌",
                                filterable: "",
                                autocomplete: "off"
                              },
                              on: { change: _vm.selectBrand },
                              model: {
                                value: _vm.ruleForm.brandId,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "brandId", $$v)
                                },
                                expression: "ruleForm.brandId"
                              }
                            },
                            _vm._l(_vm.brandList, function(brand) {
                              return _c(
                                "el-option",
                                {
                                  key: brand.id,
                                  attrs: { label: brand.name, value: brand.id }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(brand.name) +
                                      "\n            "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { label: "预约时间：", required: "", prop: "timeType" }
            },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { width: "1000px" },
                  attrs: { size: "small" },
                  model: {
                    value: _vm.ruleForm.timeType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "timeType", $$v)
                    },
                    expression: "ruleForm.timeType"
                  }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 0 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 2 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("按小时")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "byHourDate",
                                rules: [
                                  {
                                    required: _vm.ruleForm.timeType === 2,
                                    message: "请选择日期"
                                  }
                                ]
                              }
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "160px" },
                                attrs: {
                                  "picker-options": {
                                    disabledDate: _vm.disabledDateRange
                                  },
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  size: "small",
                                  disabled: _vm.ruleForm.timeType !== 2
                                },
                                model: {
                                  value: _vm.ruleForm.byHourDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "byHourDate", $$v)
                                  },
                                  expression: "ruleForm.byHourDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-top": "2px" },
                              attrs: {
                                prop: "timeRange",
                                size: "small",
                                rules: [
                                  {
                                    required: _vm.ruleForm.timeType === 2,
                                    message: "请选择时间"
                                  }
                                ]
                              }
                            },
                            [
                              _c("TimeRangePicker", {
                                attrs: {
                                  range: _vm.timeRangeList,
                                  disabled: !_vm.ruleForm.byHourDate,
                                  placeholder: "请选择时间"
                                },
                                model: {
                                  value: _vm.ruleForm.timeRange,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "timeRange", $$v)
                                  },
                                  expression: "ruleForm.timeRange"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "预约人数：",
                prop: "serviceParam.reserveNum",
                rules: _vm.reserveNumRules
              }
            },
            [
              _c("div", { staticClass: "svip-service-reserve_number" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c("el-input-number", {
                      staticClass: "form_input input-number",
                      staticStyle: { width: "290px" },
                      attrs: {
                        type: "number",
                        "controls-position": "right",
                        max: _vm.serviceInfo.limitNum,
                        min: 1,
                        placeholder: "请输入"
                      },
                      model: {
                        value: _vm.ruleForm.serviceParam.reserveNum,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm.serviceParam, "reserveNum", $$v)
                        },
                        expression: "ruleForm.serviceParam.reserveNum"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-number-append" }, [
                      _vm._v("人")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "svip-service-reserve_number-text" },
                  [
                    _vm._v(
                      "\n          *单次可预约" +
                        _vm._s(_vm.serviceInfo.limitNum) +
                        "人，可免费携带" +
                        _vm._s(_vm.serviceInfo.freeNum) +
                        "人参加活动\n        "
                    )
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm.serviceInfo.d2dServiceType !== 1
            ? _c(
                "el-form-item",
                {
                  attrs: { label: "是否上门：", prop: "serviceParam.needD2d" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.serviceParam.needD2d,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm.serviceParam, "needD2d", $$v)
                        },
                        expression: "ruleForm.serviceParam.needD2d"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("是")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: false,
                            disabled: _vm.serviceInfo.d2dServiceType === 2
                          }
                        },
                        [_vm._v("\n          否\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.serviceParam.needD2d &&
          _vm.serviceInfo.d2dServiceType !== 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "服务地址：",
                    prop: "serviceParam.doorAddress",
                    rules: [
                      {
                        required: true,
                        message: "请填写服务地址",
                        trigger: "change"
                      }
                    ]
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.serviceParam.doorAddress,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm.serviceParam,
                          "doorAddress",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.serviceParam.doorAddress"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("div", { staticStyle: { color: "red" } }, [
                _vm._v(
                  "\n        订单总价：" +
                    _vm._s(
                      _vm._f("totalAmountView")(
                        _vm.totalAmount,
                        _vm.serviceInfo
                      )
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _vm.id
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm", "edit")
                        }
                      }
                    },
                    [_vm._v("\n        确定预约\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("PayQrcode", {
        attrs: {
          show: _vm.isShowPayQrcode,
          "create-time": _vm.payQrCodeParams.createTime,
          minutes: _vm.payQrCodeParams.minutes,
          "pay-qrcode": _vm.payQrCodeParams.payQrCode
        },
        on: { closePayQrcode: _vm.closePayQrcode }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }