"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _api = require("./api.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import selectAward from '@/components/couponPop/radio.vue'
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    selectAward: _radioItem.default
  },
  data: function data() {
    var _self = this;

    return {
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.form.startTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      userLabelList: [],
      disabled: false,
      popType: '',
      couponShow: false,
      count: 0,
      giftText: {
        1: '积分',
        2: '优惠券',
        3: '购物卡'
      },
      giftInput: {
        1: '积分',
        2: '优惠券数量',
        3: '购物卡金额'
      },
      awardType: [{
        label: '优惠券',
        value: 1
      }, {
        label: '商品',
        value: 2
      }, {
        label: '积分',
        value: 3
      }, {
        label: '乐享值',
        value: 4
      }, {
        label: '谢谢参与',
        value: 5
      }],
      rules: {
        monthLimitNum: [{
          required: true,
          message: '请输入限领次数'
        }],
        startTime: [{
          required: true,
          message: '请选择开始时间'
        }],
        endTime: [{
          required: true,
          message: '请选择结束时间'
        }],
        labelContent: [{
          required: true,
          message: '请选择会员标签'
        }],
        signDays: [{
          required: true,
          message: '请输入生效天数'
        }],
        memberType: [{
          required: true,
          message: '请选择会员类型'
        }],
        rewardType: [{
          required: true,
          message: '请选择奖励类型'
        }],
        rewardContent: [{
          required: true,
          message: '请选择奖励类容'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        description: [{
          required: true,
          message: '请输入描述'
        }],
        num: [{
          required: true,
          message: '请输入数量'
        }],
        price: [{
          required: true,
          message: '请输入奖励金额'
        }]
      },
      form: {
        monthLimitNum: undefined,
        startTime: null,
        endTime: null,
        labelContent: null,
        signDays: undefined,
        memberType: null,
        rewardType: null,
        rewardContent: null,
        status: null,
        description: null,
        num: undefined,
        price: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
      _this.userLabelList = [{
        id: 0,
        labelName: '所有会员'
      }].concat((0, _toConsumableArray2.default)(res.data));
    });
    this.form.id = this.$route.query.id;
    this.disabled = this.$route.query.edit === 'no';

    if (this.form.id) {
      (0, _api.getData)(this.form.id).then(function (res) {
        if (res.data.rewardType === 1) {
          res.data.rewardContent = null;
        }

        Object.keys(_this.form).forEach(function (item) {
          _this.form[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    typeChange: function typeChange(e) {
      this.form.rewardContent = null;
      this.form.num = e === 3 ? 1 : null;
    },

    /*
                打开弹框
                */
    getAward: function getAward() {
      var selectType = {
        2: 'coupon',
        3: 'card'
      };
      this.$refs.coupon1.getList(selectType[this.form.rewardType], this.form.rewardContent);
      this.couponShow = true;
    },

    /*
                选择ID后
                */
    setCoupon: function setCoupon(e) {
      if (this.form.rewardType === 2) {
        this.form.price = e.denomination;
      }

      this.form.rewardContent = e.uuid;
      this.couponShow = false;
    },
    onSubmit: function onSubmit(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this2.form.id) {
            (0, _api.editData)(_this2.form).then(function (res) {
              _this2.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addData)(_this2.form).then(function (res) {
              _this2.$message({
                message: '保存成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            });
          }
        }
      });
    }
  }
};
exports.default = _default;