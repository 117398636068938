"use strict";

var _interopRequireWildcard = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _index = require("@/api/svip/index.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var R = _interopRequireWildcard(require("ramda"));

var _map = require("../../const/map");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      statusMap: _map.SvipStatus,
      searchParams: {
        pageNo: 1,
        pageSize: 10,
        contactNumber: '',
        name: '',
        status: null,
        date: null
      },
      total: 0
    };
  },
  computed: {
    columns: function columns() {
      return [{
        label: '性别',
        prop: 'sex',
        formatter: this.formatterSex
      }, {
        label: '生日',
        prop: 'birthday'
      }, {
        label: '联系电话',
        prop: 'contactNumber'
      }, {
        label: '加入要客时间',
        prop: 'addTime'
      }, {
        label: '要客状态',
        prop: 'status',
        formatter: this.formatterStatus
      }];
    },
    statusList: function statusList() {
      var _this = this;

      return Object.keys(this.statusMap).map(function (s) {
        return {
          label: _this.statusMap[s],
          value: s
        };
      });
    }
  },
  created: function created() {
    this.id = this.$route.params && this.$route.params.id;
    this.fetch();
  },
  methods: {
    fetch: function fetch(type) {
      var _this2 = this;

      if (type === 'search') {
        this.searchParams.pageNo = 1;
        this.searchParams.pageSize = 10;
      }

      if (type === 'reset') {
        this.removeObj(this.searchParams);
      }

      var date = this.searchParams.date;
      var params = R.omit(['date'], this.searchParams);

      if (date && date.length > 0) {
        params['addTimeStartDay'] = date[0];
        params['addTimeEndDay'] = date[1];
      }

      params['managerId'] = this.id;
      (0, _index.getPageMemberList)(params).then(function (res) {
        _this2.total = res.data.totalNum;
        _this2.list = res.data.items;
      });
    },
    formatterSex: function formatterSex(row, column) {
      return _map.SvipSex[row.sex];
    },
    formatterStatus: function formatterStatus(row, column) {
      return this.statusMap[row.status];
    },
    goto: function goto(url) {
      this.$router.push(url);
    }
  }
};
exports.default = _default;