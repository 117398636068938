"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      couponType: {
        1: '代金券',
        2: '折扣券',
        3: '礼品券',
        4: '体验券',
        5: '体验券'
      },
      downloadLoading: false,
      time: null,
      requestData: {
        name: null,
        startTime: null,
        endTime: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '券编码',
        prop: 'uuid',
        width: 80
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 80
      }, {
        slot: 'couponType',
        label: '券类型',
        prop: 'couponType',
        width: 50
      }, {
        label: '排序',
        prop: 'sort',
        width: 80
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'selection',
        label: '是否精选',
        prop: 'selection'
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status'
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {
    this.getList();
  },
  methods: {
    statusChange: function statusChange(item) {
      var _this = this;

      console.log(item);
      (0, _api.goExam)({
        id: item.id
      }).then(function (res) {
        _this.getList();
      });
    },
    editSort: function editSort(item) {
      var _this2 = this;

      this.$prompt('请输入排序', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\+?[1-9][0-9]*$/,
        inputErrorMessage: '大于0的正整数'
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _api.editSort)({
          id: item.id,
          sort: value
        }).then(function (res) {
          _this2.getList();

          _this2.$message({
            type: 'success',
            message: '修改成功'
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '取消修改'
        });
      });
    },
    goAudit: function goAudit(row, status) {
      var _this3 = this;

      (0, _api.delCoupon)({
        id: row.id
      }).then(function (res) {
        _this3.getList();
      });
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this4.requestData[key]) {
          data[key] = _this4.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getList)(data).then(function (res) {
        list = res.data.records;
        _this4.downloadLoading = false;

        _this4.getHandleDownload(tHeader, filterVal, list, '领券中心列表');
      }).catch(function (res) {
        _this4.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this5 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getList)(this.requestData).then(function (res) {
        _this5.tableData = res.data.records;
        _this5.requestData.total = res.data.total;
      });
    }
  }
};
exports.default = _default;