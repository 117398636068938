var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.viewShow
      ? _c("i", {
          staticClass: "el-icon-view",
          staticStyle: { "font-size": "50px", color: "#409EFF" },
          on: { click: _vm.setViewShow }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.viewShow
      ? _c("div", { staticClass: "right_box" }, [
          _c(
            "p",
            { staticClass: "close_phone", on: { click: _vm.setViewShow } },
            [_vm._v("关闭演示")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "right_box_tit" }, [_vm._v("实时演示")]),
          _vm._v(" "),
          _c("div", { staticClass: "right_phone" }, [
            _c(
              "div",
              {
                staticClass: "phone_top",
                staticStyle: {
                  background:
                    "url(http://xl-cdn.xinglico.com/mika/pro/2020-01-21/1ebbc75c06bf49e28888737c882fd15e.jpg) no-repeat",
                  "background-size": "100% 100%",
                  padding: "10px 0"
                }
              },
              [
                _c(
                  "el-carousel",
                  { attrs: { interval: 4000, type: "card", height: "151px" } },
                  _vm._l(_vm.obj.detailRollimg, function(item, index) {
                    return _c(
                      "el-carousel-item",
                      { key: index, staticClass: "phone_swiper" },
                      [
                        _c("img", {
                          staticClass: "img_list_box",
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: { src: _vm.imgSrc + item.img }
                        })
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "phone_commont" }, [
              _c(
                "div",
                {
                  staticClass: "phone_box",
                  staticStyle: { "border-radius": "5px 5px 0 0" }
                },
                [
                  _c("div", { staticClass: "phonx_box_top" }, [
                    _c("span", { staticClass: "top_lable" }, [
                      _vm._v("面额：")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "top_left_value" }, [
                      _vm.obj.couponType == 1 ||
                      _vm.obj.couponType == 4 ||
                      (_vm.obj.couponType == 3 && _vm.obj.denomination != 0)
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.obj.denomination) + "元")
                          ])
                        : _vm.obj.couponType == 2
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.obj.discount / 10) + "折")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.obj.couponType == 1
                        ? _c("span", [_vm._v("代金券")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.obj.couponType == 2
                        ? _c("span", [_vm._v("折扣券")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.obj.couponType == 3
                        ? _c("span", [_vm._v("礼品券")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.obj.couponType == 4
                        ? _c("span", [_vm._v("体验券")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.obj.couponType == 5
                        ? _c("span", [_vm._v("外部优惠券")])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "phone_coupon_name" }, [
                    _vm._v(_vm._s(_vm.obj.name))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "phone_coupon_descrition" }, [
                    _vm._v(_vm._s(_vm.obj.descrition))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "coupon_price" }, [
                    _c("div", { staticClass: "coupon_lable" }, [
                      _vm._v("价格：")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "coupon_price_value" }, [
                      _vm.obj.buyType == 1
                        ? _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.obj.price > 0
                                  ? _vm.obj.price + "元"
                                  : "免费领取"
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.obj.buyType == 2
                        ? _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.obj.integral > 0
                                  ? _vm.obj.integral + "分"
                                  : "免费领取"
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.obj.buyType == 3
                        ? _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.obj.integral == 0 && _vm.obj.price == 0
                                  ? "免费领取"
                                  : _vm.obj.price +
                                      "元" +
                                      _vm.obj.integral +
                                      "分"
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "coupon_time" }, [
                    _c("div", { staticClass: "coupon_lable" }, [
                      _vm._v("有效期：")
                    ]),
                    _vm._v(" "),
                    _vm.obj.validTimeType == 1
                      ? _c("div", { staticClass: "coupon_time_value" }, [
                          _vm._v(
                            _vm._s(
                              _vm.obj.beginDate[0].substring(0, 10) +
                                "-" +
                                _vm.obj.beginDate[1].substring(0, 10)
                            )
                          )
                        ])
                      : _c("div", { staticClass: "coupon_time_value" }, [
                          _vm._v(
                            "领取后 \n\t\t\t  " +
                              _vm._s(_vm.getValidDay(_vm.obj)) +
                              " 天内有效"
                          )
                        ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "coupon_limitNumber" }, [
                _c("div", { staticClass: "coupon_limitNumber_left" }, [
                  _c("div", { staticClass: "coupon_lable" }, [
                    _vm._v("数量：")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "left_limitNumber" }, [
                    _vm._v(
                      "限领/限购数：" +
                        _vm._s(
                          _vm.obj.limitNumber == 0
                            ? "不限"
                            : _vm.obj.limitNumber
                        )
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(0)
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "phone_box" }, [
                _c("div", { staticClass: "coupon_dea_box_tit" }, [
                  _vm._v("使用门店")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "box_txt_div",
                    staticStyle: { "text-align": "justify" }
                  },
                  _vm._l(_vm.arrNode, function(item, index) {
                    return _c("span", { key: index }, [
                      _vm._v(_vm._s(item.name) + "、")
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _vm.detailCtmfield.length > 0
                ? _c(
                    "div",
                    { staticClass: "phone_box detailCtmfield" },
                    _vm._l(_vm.detailCtmfield, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "detailCtmfield_item" },
                        [
                          _c("div", { staticClass: "detailCtmfield_tit" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.tit) +
                                "\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "detailCtmfield_text" }, [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(item.text) }
                            })
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "phone_box" }, [
                _c("div", { staticClass: "coupon_dea_box_tit" }, [
                  _vm._v("使用规则")
                ]),
                _vm._v(" "),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.obj.instructions) }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "phone_box" }, [
                _c("div", { staticClass: "coupon_dea_box_tit" }, [
                  _vm._v("内容详情")
                ]),
                _vm._v(" "),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.obj.geDetails) }
                })
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "coupon_limitNumber_right" }, [
      _c("i", {
        staticClass: "el-icon-remove-outline",
        staticStyle: { "font-size": "40px", color: "#666666" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "limitNumber_input" }, [_vm._v("1")]),
      _vm._v(" "),
      _c("i", {
        staticClass: "el-icon-circle-plus-outline",
        staticStyle: { "font-size": "40px" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }