"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _layout = _interopRequireDefault(require("@/layout"));

var _router = _interopRequireDefault(require("./goods/router.js"));

var _router2 = _interopRequireDefault(require("./basic/router.js"));

var _router3 = _interopRequireDefault(require("./merchant/router.js"));

var legouRouter = {
  path: '/legou',
  component: _layout.default,
  redirect: '/legou/goods/tab',
  name: 'legou',
  meta: {
    title: '��Ա�̳�',
    icon: 'nested'
  },
  children: [_router.default, _router2.default, _router3.default]
};
var _default = legouRouter;
exports.default = _default;