var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-from" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(_vm.$route.meta.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { model: _vm.params, rules: _vm.rules, "label-width": "180px" }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "真实姓名", prop: "name" }
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "身份证号", prop: "number" }
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.params.number,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "number", $$v)
                  },
                  expression: "params.number"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "联系电话", prop: "mobile" }
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.params.mobile,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "mobile", $$v)
                  },
                  expression: "params.mobile"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "备注", prop: "remark" }
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", autosize: { minRows: 5 } },
                model: {
                  value: _vm.params.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "remark", $$v)
                  },
                  expression: "params.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "actions" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }