"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _face = require("@/api/face.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      getRowKeys: function getRowKeys(row) {
        return row.index;
      },
      obj: {
        userName: '',
        userMobile: '',
        jobNumber: '',
        sendName: '',
        couponCode: '',
        pageNo: 1,
        pageSize: 10
      },
      expands: [],
      total: 0,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [{
        tit: '发券名称',
        prop: 'sendName',
        width: 300
      }, {
        tit: '创建时间',
        prop: 'createTime',
        sortable: true,
        width: 300
      }]
    };
  },
  destroyed: function destroyed() {
    this.$store.dispatch('buffer/setFaceTofaceList', this.obj);
  },
  created: function created() {
    if (this.$store.state.buffer.faceTofaceList.pageNo) {
      var initInfo = this.$store.state.buffer.faceTofaceList;
      this.obj = initInfo;
      /* this.pageNo=initInfo.pageNo
         this.pageSize=initInfo.pageSize
         this.time=initInfo.time */
    }

    this.init();
  },
  methods: {
    sendStatusChange: function sendStatusChange(row, index, pIndex) {
      var _this = this;

      var data = {
        sendId: row.sendId
      };
      console.log(row.sendStatus);

      if (row.sendStatus === 1) {
        data.sendStatus = -1;
      } else {
        data.sendStatus = 1;
      }

      this.$confirm('确认修改状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _face.editSendStatus)(data).then(function (res) {
          console.log(data);

          if (data.sendStatus === 1) {
            _this.tableData[pIndex].couponInfo[index].sendStatus = 1;
          } else {
            _this.tableData[pIndex].couponInfo[index].sendStatus = -1;
          }

          console.log(_this.tableData[pIndex].couponInfo[index].sendStatus);

          _this.$message({
            type: 'success',
            message: '修改成功!'
          });
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消修改'
        });
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/faceToface/addface');
    },
    // 折叠面板每次只能展开一行
    expandSelect: function expandSelect(row, expandedRows) {
      if (expandedRows.length) {
        this.expands = [];

        if (row) {
          this.expands.push(row.index);
        }
      } else {
        this.expands = [];
      }
    },
    isJSON: function isJSON(str) {
      if (typeof str === 'string') {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }
    },
    init: function init(e) {
      var _this2 = this;

      this.openLoading();
      (0, _face.listSendPage)(this.obj).then(function (res) {
        _this2.openLoading().close();

        _this2.total = res.data.totalNum;
        _this2.tableData = res.data.items;

        _this2.tableData.forEach(function (item, key) {
          if (_this2.isJSON(item.couponInfo)) {
            item.couponInfo = JSON.parse(item.couponInfo);
          } else {
            item.couponInfo = [];
          }

          item.index = key;
        });
      }).catch(function (res) {
        _this2.openLoading().close();
      });
    },
    remove: function remove() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.obj = {
        pageNo: 1,
        pageSize: 10
      };
      this.init();
    }
  }
};
exports.default = _default;