var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { staticClass: "row", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "ID",
                      size: "small",
                      title: "ID",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.name,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "name", $$v)
                      },
                      expression: "query.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "单据号",
                      size: "small",
                      title: "单据号",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.name,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "name", $$v)
                      },
                      expression: "query.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        clearable: "",
                        placeholder: "状态",
                        title: "状态",
                        size: "small"
                      },
                      model: {
                        value: _vm.query.type,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "type", $$v)
                        },
                        expression: "query.type"
                      }
                    },
                    _vm._l(_vm.cardTypes, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.text, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "领出机构",
                      size: "small",
                      title: "领出机构",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.name,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "name", $$v)
                      },
                      expression: "query.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "领出负责人",
                      size: "small",
                      title: "领出负责人",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.name,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "name", $$v)
                      },
                      expression: "query.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "领入机构",
                      size: "small",
                      title: "领入机构",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.name,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "name", $$v)
                      },
                      expression: "query.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "领入负责人",
                      size: "small",
                      title: "领入负责人",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.name,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "name", $$v)
                      },
                      expression: "query.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "录入人",
                      size: "small",
                      title: "录入人",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.name,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "name", $$v)
                      },
                      expression: "query.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "审核人",
                      size: "small",
                      title: "审核人",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.name,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "name", $$v)
                      },
                      expression: "query.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-date-picker", {
                    staticClass: "dateRange",
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      size: "small",
                      clearable: ""
                    },
                    model: {
                      value: _vm.query.dateRange,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "dateRange", $$v)
                      },
                      expression: "query.dateRange"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "btn-box", attrs: { gutter: 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onCreate }
                },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSearch }
                },
                [_c("i", { staticClass: "el-icon-search" }), _vm._v(" 查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.onReset } },
                [
                  _c("i", { staticClass: "el-icon-refresh-right" }),
                  _vm._v(" 重置")
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { gutter: 24, size: "small" }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "date",
                  label: "ID",
                  width: "180",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "单据号", align: "center" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "领出机构", align: "center" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "领入机构", align: "center" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "领出张数", align: "center" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  label: "已初始化张数",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "录入时间", align: "center" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "状态", align: "center" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.onDetail(scope.row)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "10px" }, attrs: { gutter: 24 } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.pagination.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pagination.pageSize,
              layout: "total, prev, pager, next, sizes, jumper",
              total: _vm.pagination.pageTotal
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }