"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _api2 = require("../api.js");

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      action: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
      storeList: [],
      payTypeList: [],
      cardData: {},
      rules: {
        rechargePrice: [{
          required: true,
          message: '请输入充值金额'
        }],
        payTypeId: [{
          required: true,
          message: '请选择付款方式'
        }],
        attachment: [{
          required: true,
          message: '请上传附件'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }]
      },
      select: 1,
      ruleForm: {
        cardNo: null,
        rechargePrice: null,
        payTypeId: null,
        attachment: [1],
        remark: null
      }
    };
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        'Token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    var _this = this;

    (0, _api2.payList)().then(function (res) {
      _this.payTypeList = res.data;
      console.log(res);
    });
    this.ruleForm.cardNo = this.$route.query.id;

    if (this.ruleForm.cardNo) {
      this.goCardInfo();
    }
  },
  methods: {
    onSuccess: function onSuccess(res) {
      var _this2 = this;

      if (res.ret === 1000) {
        this.ruleForm.attachment.push(res.data);
      } else if (res.ret === 6000) {
        this.$confirm(res.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this2.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this3 = this;

      this.ruleForm.attachment = [];
      fileList.forEach(function (item) {
        _this3.ruleForm.attachment.push(item.response.data);
      });
    },
    goCardInfo: function goCardInfo() {
      var _this4 = this;

      this.cardData = {};
      var data = {};
      data[this.select === 1 ? 'cardNo' : 'magstripeCode'] = this.ruleForm.cardNo;
      (0, _api.getCardInfo)(data).then(function (res) {
        _this4.cardData = res.data;
      });
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this5.ruleForm));
          data.attachment = JSON.stringify(data.attachment);
          (0, _api.cardRecharge)(data).then(function (res) {
            _this5.$message({
              type: 'success',
              message: '充值成功!'
            });

            _this5.$router.replace('/card/recharge');
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;