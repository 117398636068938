var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("新增/编辑会员类型")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "payMemberName",
                  attrs: { label: "会员类型名称：", prop: "payMemberName" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.payMemberName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "payMemberName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.payMemberName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "payMemberNameShort",
                  attrs: { label: "会员类型简称：", prop: "payMemberNameShort" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.payMemberNameShort,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "payMemberNameShort",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.payMemberNameShort"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "equityDescription",
                  attrs: { label: "会员描述：", prop: "equityDescription" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.equityDescription,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "equityDescription",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.equityDescription"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "buyPrice",
                  attrs: { label: "售卖金额：", prop: "buyPrice" }
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "form_input",
                      attrs: { type: "number" },
                      model: {
                        value: _vm.ruleForm.buyPrice,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "buyPrice",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.buyPrice"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "buyScore",
                  attrs: { label: "售卖积分：", prop: "buyScore" }
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "form_input",
                      attrs: { type: "number" },
                      model: {
                        value: _vm.ruleForm.buyScore,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "buyScore",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.buyScore"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("积分")])],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "validDays",
                  attrs: { label: "生效天数：", prop: "validDays" }
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "form_input",
                      attrs: { type: "number" },
                      model: {
                        value: _vm.ruleForm.validDays,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "validDays",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.validDays"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("天")])],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "limitBuyTimes",
                  attrs: { label: "限制购买次数：", prop: "limitBuyTimes" }
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "form_input",
                      attrs: { type: "number" },
                      model: {
                        value: _vm.ruleForm.limitBuyTimes,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "limitBuyTimes",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.limitBuyTimes"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("次")])],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "promotionRewardUser",
                  attrs: {
                    label: "用户推广奖励：",
                    prop: "promotionRewardUser"
                  }
                },
                [
                  _c("el-input-number", {
                    staticClass: "form_input",
                    attrs: { controls: false, min: 0, max: 100 },
                    model: {
                      value: _vm.ruleForm.promotionRewardUser,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "promotionRewardUser", $$v)
                      },
                      expression: "ruleForm.promotionRewardUser"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "form_remark" }, [_vm._v("元")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "limitBuyTimes",
                  attrs: {
                    label: "员工推广奖励：",
                    prop: "promotionRewardAdmin"
                  }
                },
                [
                  _c("el-input-number", {
                    staticClass: "form_input",
                    attrs: { controls: false, min: 0, max: 100 },
                    model: {
                      value: _vm.ruleForm.promotionRewardAdmin,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "promotionRewardAdmin", $$v)
                      },
                      expression: "ruleForm.promotionRewardAdmin"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "form_remark" }, [_vm._v("元")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "memberImage",
                  attrs: { label: "会员图标：", prop: "memberImage" }
                },
                [
                  _c("add-load", {
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.memberImage
                    },
                    on: { setCover: _vm.setCover }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "status",
                  attrs: { label: "会员状态：", prop: "status" }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: -1 },
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status"
                      }
                    },
                    [_vm._v("禁用")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "openBuy",
                  attrs: { label: "是否可购买：", prop: "openBuy" }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.openBuy,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "openBuy", $$v)
                        },
                        expression: "ruleForm.openBuy"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: -1 },
                      model: {
                        value: _vm.ruleForm.openBuy,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "openBuy", $$v)
                        },
                        expression: "ruleForm.openBuy"
                      }
                    },
                    [_vm._v("禁用")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "saleType",
                  attrs: { label: "可买用户类型：", prop: "saleType" }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.saleType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "saleType", $$v)
                        },
                        expression: "ruleForm.saleType"
                      }
                    },
                    [_vm._v("所有会员")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.ruleForm.saleType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "saleType", $$v)
                        },
                        expression: "ruleForm.saleType"
                      }
                    },
                    [_vm._v("续卡用户")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "memberPriceFlg",
                  attrs: { label: "是否享会员价：", prop: "memberPriceFlg" }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: -1 },
                      model: {
                        value: _vm.ruleForm.memberPriceFlg,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "memberPriceFlg", $$v)
                        },
                        expression: "ruleForm.memberPriceFlg"
                      }
                    },
                    [_vm._v("否")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.memberPriceFlg,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "memberPriceFlg", $$v)
                        },
                        expression: "ruleForm.memberPriceFlg"
                      }
                    },
                    [_vm._v("是")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "openRightFlg",
                  attrs: { label: "购买平台：", prop: "openRightFlg" }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.openRightFlg,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "openRightFlg", $$v)
                        },
                        expression: "ruleForm.openRightFlg"
                      }
                    },
                    [_vm._v("仅建行可购买")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: -1 },
                      model: {
                        value: _vm.ruleForm.openRightFlg,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "openRightFlg", $$v)
                        },
                        expression: "ruleForm.openRightFlg"
                      }
                    },
                    [_vm._v("MIKA小程序购买")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "bindDuplicate",
                  attrs: { label: "是否可绑亲友卡：", prop: "bindDuplicate" }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.bindDuplicate,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "bindDuplicate", $$v)
                        },
                        expression: "ruleForm.bindDuplicate"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: -1 },
                      model: {
                        value: _vm.ruleForm.bindDuplicate,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "bindDuplicate", $$v)
                        },
                        expression: "ruleForm.bindDuplicate"
                      }
                    },
                    [_vm._v("禁用")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "description",
                  attrs: { label: "会员说明：", prop: "description" }
                },
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "600px" },
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.ruleForm.description,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "description", $$v)
                      },
                      expression: "ruleForm.description"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }