var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "type-from" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(_vm.$route.meta.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { model: _vm.params, rules: _vm.rules, "label-width": "180px" }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "卡类型名称", prop: "categoryName" }
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.params.categoryName,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "categoryName", $$v)
                  },
                  expression: "params.categoryName"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "input-tip" }, [
                _vm._v("卡类型关联积分规则和活动信息等")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.params.status,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "status", $$v)
                    },
                    expression: "params.status"
                  }
                },
                _vm._l(_vm._Options.cardTypeState, function(op, opIndex) {
                  return _c(
                    "el-radio",
                    { key: opIndex, attrs: { label: op.value } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(op.label) +
                          "\n                "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "消费积分", prop: "ifIntegral" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.params.ifIntegral,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "ifIntegral", $$v)
                    },
                    expression: "params.ifIntegral"
                  }
                },
                _vm._l(_vm._Options.consumptionPoint, function(op, opIndex) {
                  return _c(
                    "el-radio",
                    { key: opIndex, attrs: { label: op.value } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(op.label) +
                          "\n                "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "销售用途", prop: "purpose" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.params.purpose,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "purpose", $$v)
                    },
                    expression: "params.purpose"
                  }
                },
                _vm._l(_vm._Options.salesPurpose, function(op, opIndex) {
                  return _c(
                    "el-radio",
                    { key: opIndex, attrs: { label: op.value } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(op.label) +
                          "\n                "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "记名类型", prop: "registeredType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.params.registeredType,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "registeredType", $$v)
                    },
                    expression: "params.registeredType"
                  }
                },
                _vm._l(_vm._Options.registeredType, function(op, opIndex) {
                  return _c(
                    "el-radio",
                    { key: opIndex, attrs: { label: op.value } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(op.label) +
                          "\n                "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "卡号段", prop: "segmentNo" }
            },
            [
              _c("el-input-number", {
                staticClass: "input-number",
                attrs: { controls: false, precision: 0, min: 100, max: 999 },
                model: {
                  value: _vm.params.segmentNo,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "segmentNo", $$v)
                  },
                  expression: "params.segmentNo"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "input-tip" }, [
                _vm._v(
                  "\n                3位数字，具有唯一性，用于卡号编码时使用；同一个卡种，卡号为3位卡号段+3位卡种ID+4位数面额+8位自增序列。例如：卡号段为“621”的“001”卡种，面额为1000元，则卡号为“600 001\n                1000 000000001\n            "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "备注", prop: "remark" }
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", autosize: { minRows: 5 } },
                model: {
                  value: _vm.params.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "remark", $$v)
                  },
                  expression: "params.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "actions" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }