"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _api = require("./api");

var _index = require("@/views/system/manage/index.js");

var _template = require("@/api/template.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      total: 1,
      requestParams: {
        name: undefined,
        storeId: undefined,
        barcodeId: undefined,
        status: undefined,
        pageNo: 1,
        pageSize: 10
      },
      list: [],
      // 门店数据
      storeloading: false,
      storeOptions: [],
      // 页面模板
      pageloading: false,
      pageOptions: []
    };
  },
  created: function created() {
    this.remoteStoreList();
    this.remotePageList();
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    changeStatus: function changeStatus(row) {
      var _this = this;

      console.log(row.status);
      var tip = row.status == 1 ? "开启" : "关闭";
      this.$confirm("".concat(tip, "\u6536\u94F6\u673A\u5C0F\u7968, \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _api.changeStatus)({
          id: row.id,
          status: row.status
        }).then(function () {
          _this.$message({
            type: 'success',
            message: '操作成功!'
          });
        }).catch(function () {
          row.status = row.status == 1 ? 2 : 1;
        });
      }).catch(function () {
        row.status = row.status == 1 ? 2 : 1;
      });
    },
    handleCRE: function handleCRE() {
      this.$router.push({
        path: 'qingting/create'
      });
    },
    handleDEL: function handleDEL() {
      var _this2 = this;

      this.$confirm('删除小票格式, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(function () {});
    },
    handleEDI: function handleEDI(row) {
      this.$router.push({
        path: 'qingting/edit/' + row.id
      });
    },
    handleDTA: function handleDTA(row) {
      this.$router.push({
        path: 'qingting/detail/' + row.id
      });
    },
    handleReset: function handleReset() {
      this.$refs['requestParamsForm'].resetFields();
      this.remotePageList();
      this.remoteStoreList();
      this.getList();
    },
    handleQuery: function handleQuery() {
      this.requestParams.pageNo = 1;
      this.getList();
    },
    handlePageChange: function handlePageChange() {
      this.getList();
    },
    remotePageList: function remotePageList(query) {
      var _this3 = this;

      this.pageloading = true;
      var params = {
        pageNo: 1,
        pageSize: 20,
        pageName: query
      };
      return (0, _template.listPage)(params).then(function (res) {
        _this3.pageOptions = res.data.items;
        _this3.pageloading = false;
      }).catch(function (err) {
        _this3.pageloading = false;
      });
    },
    remoteStoreList: function remoteStoreList(query) {
      var _this4 = this;

      this.storeloading = true;
      var params = {
        pageNo: 1,
        pageSize: 20,
        fullName: query
      };
      return (0, _index.listStorePage)(params).then(function (res) {
        _this4.storeOptions = res.data.items;
        _this4.storeloading = false;
      }).catch(function (err) {
        _this4.storeloading = false;
      });
    },
    getList: function getList() {
      var _this5 = this;

      (0, _api.listStorePrintMsgPage)(this.requestParams).then(function (res) {
        _this5.list = res.data.items;
        _this5.total = res.data.totalNum;
      });
    }
  }
};
exports.default = _default;