"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkedInvoice = checkedInvoice;
exports.drawer = drawer;
exports.getById = getById;
exports.getBySId = getBySId;
exports.getDetail = getDetail;
exports.getDetails = getDetails;
exports.invoiceDetail = invoiceDetail;
exports.invoiceInfo = invoiceInfo;
exports.list = list;
exports.listInvoice = listInvoice;
exports.makeInvoice = makeInvoice;
exports.receiptSettingDetail = receiptSettingDetail;
exports.receiptSettingPage = receiptSettingPage;
exports.receiptSettingSave = receiptSettingSave;
exports.receiptUploadCheck = receiptUploadCheck;
exports.receiptUploadDetail = receiptUploadDetail;
exports.receiptUploadPage = receiptUploadPage;
exports.rushRed = rushRed;
exports.sendEmail = sendEmail;

var _request = _interopRequireDefault(require("@/utils/request"));

function listInvoice(data) {
  // 已开发票列表
  return (0, _request.default)({
    url: '/api/invoice/listInvoice',
    method: 'post',
    data: data
  });
}
/*
 开票重开票  发票冲红
 status 红冲 2   开票 1   重开 3
 */


function makeInvoice(data) {
  return (0, _request.default)({
    url: '/api/invoice/makeInvoice',
    method: 'post',
    data: data
  });
}

function sendEmail(data) {
  // 重发邮箱
  return (0, _request.default)({
    url: '/api/invoice/sendEmail',
    method: 'post',
    data: data
  });
}

function invoiceInfo(data) {
  // 查看发票商品信息
  return (0, _request.default)({
    url: '/api/invoice/invoiceInfo',
    method: 'post',
    data: data
  });
}

function invoiceDetail(data) {
  // 查看发票信息
  return (0, _request.default)({
    url: '/api/invoice/invoiceDetail',
    method: 'post',
    data: data
  });
}

function getDetails(data) {
  // 查看发票信息
  return (0, _request.default)({
    url: '/api/invoice/getDetail',
    method: 'post',
    data: data
  });
}
/*
 查迅列表
 */


function list(data) {
  return (0, _request.default)({
    url: '/app/invoice/list',
    method: 'post',
    baseURL: 'https://invoice-api.xinglico.com/admin',
    data: data
  });
}
/*
 开票
 */


function drawer(data) {
  return (0, _request.default)({
    url: '/app/invoice/drawer',
    method: 'post',
    data: data
  });
}
/*
 冲红
 */


function rushRed(data) {
  return (0, _request.default)({
    url: '/app/invoice/rushRed',
    baseURL: 'https://invoice-api.xinglico.com/admin',
    method: 'post',
    data: data
  });
}
/*
 通过id查询
 */


function getById(data) {
  return (0, _request.default)({
    url: '/app/invoice/getById',
    baseURL: 'https://invoice-api.xinglico.com/admin',
    method: 'post',
    data: data
  });
}

function getBySId(data) {
  return (0, _request.default)({
    url: '/app/invoice/getBySId',
    baseURL: 'https://invoice-api.xinglico.com/admin',
    method: 'post',
    data: data
  });
}
/*
 通过流水与机构查看详情
 */


function getDetail(data) {
  return (0, _request.default)({
    url: '/app/invoice/getDetail',
    baseURL: 'https://invoice-api.xinglico.com/admin',
    method: 'post',
    data: data
  });
} // 小票金额上传


function receiptUploadPage(data) {
  return (0, _request.default)({
    url: '/api/payReceiptUpload/listStorePrintMsgPage',
    method: 'post',
    data: data
  });
}

function receiptUploadDetail(id) {
  return (0, _request.default)({
    url: '/api/payReceiptUpload/getPayReceiptUploadById/' + id,
    method: 'get'
  });
}

function receiptUploadCheck(data) {
  return (0, _request.default)({
    url: '/api/payReceiptUpload/auditPayReceiptUpload',
    method: 'post',
    data: data
  });
} // 小票积分配置


function receiptSettingPage(data) {
  return (0, _request.default)({
    url: '/api/receiptIntegralAllocation/listReceiptIntegralAllocationPage',
    method: 'post',
    data: data
  });
}

function receiptSettingSave(data) {
  var url = '/api/receiptIntegralAllocation/addReceiptIntegralAllocation';

  if (data.id) {
    url = '/api/receiptIntegralAllocation/editReceiptIntegralAllocation';
  }

  return (0, _request.default)({
    url: url,
    method: 'post',
    data: data
  });
}

function receiptSettingDetail(id) {
  return (0, _request.default)({
    url: '/api/receiptIntegralAllocation/getReceiptIntegralAllocationById/' + id,
    method: 'get'
  });
} // 开票审核


function checkedInvoice(data) {
  return (0, _request.default)({
    url: '/api/invoice/adminMakeInvoice',
    method: 'post',
    data: data
  });
}