var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            pageApi: "/api/scoreGive/listScoreGivePage",
            "search-data": _vm.searchData
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "120"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { class: scope.row.status == 1 ? "success" : "danger" },
                      [_vm._v(_vm._s(scope.row.status == 1 ? "启用" : "停用"))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "type",
              label: "礼品类型",
              align: "center",
              width: "120"
            },
            slot: "type",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { class: scope.row.type == 1 ? "success" : "danger" },
                      [_vm._v(_vm._s(scope.row.type == 1 ? "积分" : "礼品"))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "returnType",
              label: "积分退还类型",
              align: "center",
              width: "120"
            },
            slot: "returnType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.returnType == 1
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("年度退还")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.returnType == 2
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("活动截止退还")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.returnType == 3
                      ? _c("span", { staticClass: "warning" }, [
                          _vm._v("活动截止后x天退还")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "giveRule",
              label: "赠送规则",
              align: "center",
              width: "100"
            },
            slot: "giveRule",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.giveRule == 1
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("依次递增")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.giveRule == 2
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("倍数递增")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "labelContent",
              label: "参与人群",
              align: "center",
              "mni-width": "100"
            },
            slot: "labelContent",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getUserLabel(scope.row.labelContent)) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "labelContentNo",
              label: "黑名单",
              align: "center",
              "mni-width": "100"
            },
            slot: "labelContentNo",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getUserLabel(scope.row.labelContentNo)) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              fixed: "right",
              align: "center",
              width: "160"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/integral/giveAdd?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "success",
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/integral/pointDea?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("\n          产出")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "warning",
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/integral/giveOrder?name=" +
                                scope.row.activityName
                            )
                          }
                        }
                      },
                      [_vm._v("\n          明细")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "warning",
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.getWxCode(scope.row)
                          }
                        }
                      },
                      [_vm._v("\n            活动码\n            ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              url: _vm.url,
              "qr-code": _vm.qrCode,
              "coupon-u-uid": _vm.couponUUid
            },
            on: {
              closePop: function($event) {
                _vm.popShow = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }