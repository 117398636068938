"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/typeof"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.find");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _auth = require("@/utils/auth");

var _index = _interopRequireDefault(require("@/components/Tinymce/index.vue"));

var _api = require("./api");

var _index2 = require("@/views/system/manage/index.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _index.default
  },
  data: function data() {
    var _ref, _ref2, _ref3, _ref4, _ref5, _ref6;

    var fields = [{
      label: '停车场系统',
      value: 'systemName',
      rules: [{
        required: true,
        message: '请输入停车场系统',
        trigger: 'blur'
      }]
    }, {
      label: '停车场名称',
      value: 'parkName',
      rules: [{
        required: true,
        message: '请输入停车场名称',
        trigger: 'blur'
      }]
    }, {
      label: '停车场电话',
      value: 'mobile',
      rules: [{
        required: true,
        message: '请输入停车场电话',
        trigger: 'blur'
      }]
    }, {
      label: '停车场ID',
      value: 'parkId',
      editDis: true,
      rules: [{
        required: true,
        message: '请输入停车场ID',
        trigger: 'blur'
      }]
    }, {
      label: '停车场秘钥',
      value: 'secretKey',
      editDis: true,
      rules: [{
        required: true,
        message: '请输入停车场秘钥',
        trigger: 'blur'
      }]
    }, {
      label: 'appId',
      value: 'appId',
      editDis: true,
      rules: [{
        required: true,
        message: '请输入appId',
        trigger: 'blur'
      }]
    }, {
      label: '停车场照片',
      value: 'photo',
      rules: [{
        required: true,
        message: '请上传停车场照片',
        trigger: 'change'
      }],
      type: "image",
      suffixs: "image/png,image/jpg,image/jpeg",
      tip: "请上传690*250的图片，建议使用清晰美观的停车场入口照片，方便顾客寻找。"
    }, {
      label: '停车场权益展示图',
      value: 'equityPic',
      rules: [{
        required: true,
        message: '请上传停车场权益展示图',
        trigger: 'change'
      }],
      type: "image",
      suffixs: "image/png,image/jpg,image/jpeg"
    }, {
      label: '地址',
      value: 'address',
      rules: [{
        required: true,
        message: '请输入地址',
        trigger: 'blur'
      }]
    }, {
      label: '经纬度',
      value: 'longLat',
      rules: [{
        required: true,
        message: '请输入经纬度',
        trigger: 'blur'
      }],
      tip: "请使用英文逗号隔开；请填写停车场入口经纬度，方便顾客导航至准确位置。"
    }, {
      label: '选择门店',
      value: 'storeCode',
      rules: [{
        required: true,
        message: '请选择选择门店',
        trigger: 'chnage'
      }],
      slot: "storeCode"
    }, {
      label: '基础收费',
      value: 'basicCharge',
      type: "number",
      rules: [{
        required: true,
        message: '请输入基础收费',
        trigger: 'blur'
      }]
    }, {
      label: '进出场免费时长',
      value: 'freeDuration',
      rules: [{
        required: true,
        message: '请输入进出场免费时长',
        trigger: 'blur'
      }],
      type: "number",
      tip: "车辆入场时长不足或支付停车费后在指定时长内出场，则不产生计费。"
    }, {
      label: '是否支持购物卡',
      value: 'ifCard',
      rules: [{
        required: true,
        message: '请选择是否支持购物卡',
        trigger: 'blur'
      }],
      type: "radio",
      select: [{
        value: 1,
        label: "启用"
      }, {
        value: 2,
        label: "禁用"
      }] //tip: "车辆入场时长不足或支付停车费后在指定时长内出场，则不产生计费。"

    }, {
      label: '优惠方案',
      value: 'preferentialScheme',
      rules: [{
        required: true,
        message: '请选择优惠方案',
        trigger: 'blur'
      }],
      type: "radio",
      select: [{
        value: 1,
        label: "消费减免停车费"
      }, {
        value: 2,
        label: "积分抵扣停车费"
      }] //tip: "车辆入场时长不足或支付停车费后在指定时长内出场，则不产生计费。"

    }, {
      label: '会员消费优惠',
      value: 'pricingRulesBeanList',
      rules: [{
        required: true,
        message: '请选择会员消费优惠',
        trigger: 'change'
      }],
      slot: "pricingRulesBeanList"
    }, {
      label: '积分抵扣优惠',
      value: 'pricingRulesBeanList',
      rules: [{
        required: true,
        message: '请选择积分抵扣优惠',
        trigger: 'change'
      }],
      slot: "scoreRulesBeanList"
    }, {
      label: '规则说明',
      value: 'remark',
      rules: [{
        required: true,
        message: '请输入规则说明',
        trigger: 'blur'
      }],
      type: "tinymce"
    }];
    return {
      TinymceKey: 1,
      uploadConfig: {
        SRCPREFIX: this.$store.state.user.imgSrc,
        URL: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
        HEADERS: {
          ContentType: 'multipart/form-data',
          Token: (0, _auth.getToken)()
        },
        field: null
      },
      fields: fields,
      form: {
        pricingRulesBeanList: [],
        scoreRulesBeanList: []
      },
      rules: {},
      // 会员消费优惠
      preferentialOption: [(_ref = {
        title: "注册会员",
        key: 1,
        memberType: 1,
        level: 0,
        accumulatedAmount: undefined,
        freeAmount: undefined,
        overstepAmount: undefined
      }, (0, _defineProperty2.default)(_ref, "overstepAmount", undefined), (0, _defineProperty2.default)(_ref, "disAmount", undefined), (0, _defineProperty2.default)(_ref, "limitationTime", undefined), _ref), (_ref2 = {
        title: "365会员",
        key: 2,
        memberType: 2,
        accumulatedAmount: undefined,
        freeAmount: undefined,
        level: 0,
        overstepAmount: undefined
      }, (0, _defineProperty2.default)(_ref2, "overstepAmount", undefined), (0, _defineProperty2.default)(_ref2, "disAmount", undefined), (0, _defineProperty2.default)(_ref2, "limitationTime", undefined), _ref2), (_ref3 = {
        title: "lavant一星",
        key: 3,
        memberType: 3,
        level: 1,
        accumulatedAmount: undefined,
        freeAmount: undefined,
        overstepAmount: undefined
      }, (0, _defineProperty2.default)(_ref3, "overstepAmount", undefined), (0, _defineProperty2.default)(_ref3, "disAmount", undefined), (0, _defineProperty2.default)(_ref3, "limitationTime", undefined), _ref3), (_ref4 = {
        title: "lavant二星",
        key: 4,
        memberType: 3,
        level: 2,
        accumulatedAmount: undefined,
        freeAmount: undefined,
        overstepAmount: undefined
      }, (0, _defineProperty2.default)(_ref4, "overstepAmount", undefined), (0, _defineProperty2.default)(_ref4, "disAmount", undefined), (0, _defineProperty2.default)(_ref4, "limitationTime", undefined), _ref4), (_ref5 = {
        title: "lavant三星",
        key: 5,
        memberType: 3,
        level: 3,
        accumulatedAmount: undefined,
        freeAmount: undefined,
        overstepAmount: undefined
      }, (0, _defineProperty2.default)(_ref5, "overstepAmount", undefined), (0, _defineProperty2.default)(_ref5, "disAmount", undefined), (0, _defineProperty2.default)(_ref5, "limitationTime", undefined), _ref5), (_ref6 = {
        title: "lavant黑金",
        key: 6,
        memberType: 3,
        level: 4,
        accumulatedAmount: undefined,
        freeAmount: undefined,
        overstepAmount: undefined
      }, (0, _defineProperty2.default)(_ref6, "overstepAmount", undefined), (0, _defineProperty2.default)(_ref6, "disAmount", undefined), (0, _defineProperty2.default)(_ref6, "limitationTime", undefined), _ref6)],
      //
      loadingStore: false,
      storeOptions: []
    };
  },
  filters: {
    placeholderLabel: function placeholderLabel(label) {
      return "请输入" + label;
    }
  },
  created: function created() {
    this.getStores();
    this.getInfo();
  },
  methods: {
    getStores: function getStores(query) {
      var _this = this;

      this.storeloading = true;
      var params = {
        pageNo: 1,
        pageSize: 10000,
        fullName: query
      };
      return (0, _index2.listStorePage)(params).then(function (res) {
        _this.storeOptions = res.data.items;
        _this.loadingStore = false;
      }).catch(function (err) {
        _this.loadingStore = false;
      });
    },
    getInfo: function getInfo() {
      var _this2 = this;

      var id = this.$route.query.id;
      if (!id) return;
      (0, _api.listStopParkInfo)({
        pageSize: 1000,
        pageNo: 1
      }).then(function (res) {
        var list = res.data;
        var info = list.find(function (item) {
          return item.id == id;
        });

        _this2.initForm(info);
      });
    },
    initForm: function initForm(form) {
      var _this3 = this;

      var pricingRulesBeanList = []; //

      if (form.pricingRulesBeanList && form.pricingRulesBeanList.length > 0) {
        form.pricingRulesBeanList.forEach(function (item) {
          item.level = item.level || 0;

          var op = _this3.preferentialOption.find(function (op) {
            return op.memberType == item.memberType && op.level === item.level;
          });

          if (op) {
            op.accumulatedAmount = item.accumulatedAmount;
            op.freeAmount = item.freeAmount;
            op.disAmount = item.disAmount;
            op.limitationTime = item.limitationTime;
            op.overstepAmount = item.overstepAmount;
            pricingRulesBeanList.push(op.key);
          }
        });
      }

      form.pricingRulesBeanList = pricingRulesBeanList;
      this.form = form;
      this.TinymceKey++; // ...
    },
    onSave: function onSave() {
      var _this4 = this;

      this.$refs['form'].validate(function (valid) {
        if (!valid) return;
        var form = JSON.parse(JSON.stringify(_this4.form)); // 是否有错

        var bool = false;
        var pricingRulesBeanList = [];

        var _loop = function _loop(index) {
          var key = form.pricingRulesBeanList[index];

          var op = _this4.preferentialOption.find(function (op) {
            return op.key === key;
          });

          var b = true;

          if (_this4.form.preferentialScheme == 1) {
            b = op.accumulatedAmount == undefined || op.freeAmount == undefined || op.overstepAmount == undefined;
          }

          if (_this4.form.preferentialScheme == 2) {
            op.overstepAmount = _this4.form.basicCharge;
            b = op.overstepAmount == undefined || op.disAmount == undefined || op.limitationTime == undefined;
          }

          if (b) {
            _this4.$message.error("请填写会员消费优惠第" + (index + 1) + "条数据");

            bool = true;
            return {
              v: void 0
            };
          }

          if (op.overstepAmount > form.basicCharge) {
            _this4.$message.error("会员消费优惠第" + (index + 1) + "条数据的超出计价不能大于基础收费");

            bool = true;
            return {
              v: void 0
            };
          }

          pricingRulesBeanList.push(op);
        };

        for (var index = 0; index < form.pricingRulesBeanList.length; index++) {
          var _ret = _loop(index);

          if ((0, _typeof2.default)(_ret) === "object") return _ret.v;
        }

        if (bool) return;
        form.pricingRulesBeanList = pricingRulesBeanList; //

        delete form.createUpdateTime;
        (0, _api.saveStopParkInfo)(form).then(function () {
          _this4.$message.success("保存成功");

          _this4.$router.push("/parking/jianghua/list");
        });
      });
    },
    onUploadSuccess: function onUploadSuccess(res) {
      this.form[this.uploadConfig.field] = res.data;
      this.$forceUpdate();
      console.log(this.form[this.uploadConfig.field]);
    },
    onUploadBefore: function onUploadBefore(file) {
      var isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
      var isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }

      return isJPG && isLt2M;
    }
  }
};
exports.default = _default;