var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增/编辑BANNER ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { ref: "imageUrl", attrs: { label: "图片：", prop: "imageUrl" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: {
                  width: 300,
                  height: 100,
                  cover: _vm.ruleForm.imageUrl
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "imageUrl")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("图片尺寸710*420")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "status", attrs: { label: "状态：", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("上架")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("下架")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "sort", attrs: { label: "排序：", prop: "sort" } },
            [
              _c("el-input-number", {
                attrs: { controls: false, min: "1", precision: 0, step: 1 },
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "sort", $$v)
                  },
                  expression: "ruleForm.sort"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "关联内容：", prop: "linkCode" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "关联内容类型"
                  },
                  on: { change: _vm.typeChange },
                  model: {
                    value: _vm.ruleForm.linkCode,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "linkCode", $$v)
                    },
                    expression: "ruleForm.linkCode"
                  }
                },
                _vm._l(_vm.linkCodeLIst, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.linkCode == 4
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "目标小程序：", prop: "appid" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form_input",
                          attrs: { placeholder: "小程序" },
                          model: {
                            value: _vm.ruleForm.appid,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "appid", $$v)
                            },
                            expression: "ruleForm.appid"
                          }
                        },
                        _vm._l(_vm.appidArr, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "小程序路径：",
                        prop: "linkType",
                        rules: [{ required: true, message: "请输入小程序路径" }]
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { type: "text" },
                        model: {
                          value: _vm.ruleForm.linkType,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "linkType", $$v)
                          },
                          expression: "ruleForm.linkType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.linkCode == 5
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "WEB页面：",
                    prop: "linkType",
                    rules: [{ required: true, message: "请输入WEB页面地址" }]
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.ruleForm.linkType,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "linkType", $$v)
                      },
                      expression: "ruleForm.linkType"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.urlTypeList.indexOf(_vm.ruleForm.linkCode) >= 0
            ? _c(
                "el-form-item",
                { attrs: { label: "页面编码：", prop: "linkType" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.ruleForm.linkType,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "linkType", $$v)
                      },
                      expression: "ruleForm.linkType"
                    }
                  }),
                  _vm._v(" "),
                  _vm.noSelect.indexOf(_vm.ruleForm.linkCode) < 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.getLinkId()
                            }
                          }
                        },
                        [_vm._v(" 选择\n      ")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("couponPop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: _vm.popType, "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }