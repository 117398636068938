"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/web.dom.iterable");

var _regionData = _interopRequireDefault(require("@/utils/regionData.json"));

var _index = require("./index.js");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      id: '',
      regionData: _regionData.default,
      systemList: [],
      parkSystem: [{
        label: '浙江大华',
        value: 'ZJDH'
      }, {
        label: '驻停车',
        value: 'ZTC'
      }],
      form: {
        institutionId: null,
        parkingSystem: null,
        checked1: true,
        checked2: false,
        storeId: null,
        parkingId: null,
        parkingSecret: null,
        parkingName: null,
        parkingImgs: null,
        state: [],
        detailAddress: null,
        longOrLat: null,
        basicCost: null,
        freeTime: null,
        sameDis: null,
        remark: null,
        commonMember: null,
        commonMember1: null,
        commonLimit: null,
        commonLimit1: null,
        vipMember: null,
        vipMember1: null,
        vipLimit: null,
        vipLimit1: null,
        vipStatus: null
      },
      rules: {
        parkingSystem: [{
          required: true,
          message: '请输入停车场系统'
        }],
        parkingId: [{
          required: true,
          message: '请输入停车场id'
        }],
        parkingSecret: [{
          required: true,
          message: '请输入停车场秘钥'
        }],
        vipLimit: [{
          required: true,
          message: '请输入每日限兑'
        }],
        vipLimit1: [{
          required: true,
          message: '请输入优惠上限'
        }],
        vipMember: [{
          required: true,
          message: '请输入积分/小时'
        }],
        vipMember1: [{
          required: true,
          message: '请输入折扣'
        }],
        commonMember: [{
          required: true,
          message: '请输入积分/小时'
        }],
        commonMember1: [{
          required: true,
          message: '请输入折扣'
        }],
        commonLimit: [{
          required: true,
          message: '请输入每日限兑'
        }],
        commonLimit1: [{
          required: true,
          message: '请输入优惠上限'
        }],
        freeTime: [{
          required: true,
          message: '请输入免费时长'
        }],
        parkingName: [{
          required: true,
          message: '请输入停车场名称'
        }],
        parkingImgs: [{
          required: true,
          message: '请商城图片'
        }],
        state: [{
          required: true,
          message: '请选择省市区'
        }],
        detailAddress: [{
          required: true,
          message: '请填写详细地址'
        }],
        longOrLat: [{
          required: true,
          message: '请填写经纬度'
        }],
        basicCost: [{
          required: true,
          message: '请填写基础收费'
        }],
        sameDis: [{
          required: true,
          message: '请选择'
        }],
        remark: [{
          required: true,
          message: '请填写备注'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.form.storeId = this.$route.query.storeId;
    this.form.institutionId = this.$route.query.institutionId;
    this.id = this.$route.query.id;

    if (this.id) {
      (0, _index.getParking)(this.id).then(function (res) {
        _this.form.institutionId = res.data.institutionId;
        _this.form.parkingSystem = res.data.parkingSystem;
        _this.form.remark = res.data.remark;
        _this.form.vipStatus = res.data.vipStatus;
        _this.form.parkingId = res.data.parkingId;
        _this.form.parkingSecret = res.data.parkingSecret;
        _this.form.parkingName = res.data.parkingName;
        _this.form.parkingImgs = res.data.parkingImgs;
        _this.form.state = [res.data.provinceCode, res.data.cityCode, res.data.countyCode];
        _this.form.detailAddress = res.data.detailAddress;
        _this.form.longOrLat = res.data.longitude + ',' + res.data.latitude;
        _this.form.basicCost = res.data.basicCost;
        _this.form.freeTime = res.data.freeTime;
        _this.form.sameDis = res.data.sameDis === 1;
        res.data.parkingPays.forEach(function (item) {
          if (item.payType === 1) {
            _this.form.checked1 = true;
            _this.form.commonLimit = item.commonLimit;
            _this.form.commonMember = item.commonMember;
            _this.form.vipMember = item.vipMember;
            _this.form.vipLimit = item.vipLimit;
          }

          if (item.payType === 2) {
            _this.form.checked2 = true;
            _this.form.commonLimit1 = item.commonLimit;
            _this.form.commonMember1 = item.commonMember;
            _this.form.vipMember1 = item.vipMember;
            _this.form.vipLimit1 = item.vipLimit;
          }
        });
      });
    }
  },
  methods: {
    setParkingCover: function setParkingCover(e) {
      this.form.parkingImgs = e;
    },
    // 三级地址选择
    regionDataParking: function regionDataParking(e, index) {
      var arr = this.$refs['parkState'].getCheckedNodes(false)[0].pathLabels || [];
      this.form.detailAddress = arr.join('');
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (_this2.form.longOrLat.split(',').length != 2) {
          _this2.$message.error('经度纬度，请使用英文的逗号隔开');
        } else if (valid) {
          var data = {
            institutionId: _this2.form.institutionId,
            parkingSystem: _this2.form.parkingSystem,
            storeId: _this2.form.storeId,
            parkingId: _this2.form.parkingId,
            parkingSecret: _this2.form.parkingSecret,
            parkingName: _this2.form.parkingName,
            parkingImgs: _this2.form.parkingImgs,
            provinceCode: _this2.form.state[0],
            cityCode: _this2.form.state[1],
            countyCode: _this2.form.state[2],
            detailAddress: _this2.form.detailAddress,
            longitude: _this2.form.longOrLat.split(',')[0],
            latitude: _this2.form.longOrLat.split(',')[1],
            basicCost: _this2.form.basicCost,
            freeTime: _this2.form.freeTime,
            sameDis: _this2.form.sameDis ? 1 : 0,
            remark: _this2.form.remark
          };
          var parkingPays = [];

          if (_this2.form.checked1) {
            parkingPays.push({
              payType: 1,
              commonMember: _this2.form.commonMember,
              commonLimit: _this2.form.commonLimit,
              vipMember: _this2.form.vipMember,
              vipLimit: _this2.form.vipLimit
            });
          }

          if (_this2.form.checked2) {
            parkingPays.push({
              payType: 2,
              commonMember: _this2.form.commonMember1,
              commonLimit: _this2.form.commonLimit1,
              vipMember: _this2.form.vipMember1,
              vipLimit: _this2.form.vipLimit1,
              vipStatus: _this2.form.vipStatus
            });
          }

          data.parkingPays = parkingPays;

          if (_this2.id) {
            data.id = _this2.id;
            (0, _index.editParking)(data).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _index.addParking)(data).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '新增成功!'
              });

              _this2.$router.go(-1);
            });
          }
        }
      });
    }
  }
};
exports.default = _default;