var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "keep-alive",
        [
          _c(
            "w-table",
            { attrs: { size: "mini", "search-data": _vm.searchData } },
            [
              _c("el-table-column", {
                attrs: {
                  slot: "status",
                  label: "状态",
                  align: "center",
                  width: "80"
                },
                slot: "status",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "span",
                          {
                            class: scope.row.status === 1 ? "success" : "danger"
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status === 1 ? "启用" : "禁用")
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }