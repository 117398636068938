"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var labelRouter = {
  path: '/label',
  component: _layout.default,
  redirect: '/label/content/tab',
  name: 'Label',
  meta: {
    title: '标签',
    icon: 'nested'
  },
  children: [{
    path: 'content',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/label/index.vue'));
      });
    },
    redirect: '/label/content/tab',
    name: 'contentLableTab',
    meta: {
      title: '内容标签 '
    },
    children: [{
      path: 'tab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/label/contentTab.vue'));
        });
      },
      name: 'contentLableTabs',
      meta: {
        title: '方案标签分类 '
      }
    }, {
      path: 'classList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/label/labelClass/list.vue'));
        });
      },
      name: 'labelClassIndex',
      meta: {
        title: '方案标签分类 '
      }
    }, {
      path: 'classAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/label/labelClass/add.vue'));
        });
      },
      name: 'labelClassAdd',
      meta: {
        title: '新增/编辑方案标签分类 '
      }
    }, {
      path: 'labelList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/label/label/list.vue'));
        });
      },
      name: 'labelIndex',
      meta: {
        title: '方案标签 '
      }
    }, {
      path: 'labelAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/label/label/add.vue'));
        });
      },
      name: 'labelAdd',
      meta: {
        title: '新增/编辑方案标签'
      }
    }]
  }]
};
var _default = labelRouter;
exports.default = _default;