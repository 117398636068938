"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addPopupPage = addPopupPage;
exports.editPopupPage = editPopupPage;
exports.getPopup = getPopup;
exports.listPopupPage = listPopupPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*

1、弹窗分页：/api/popup/listPopupPage
{"pageNo":"当前页","pageSize":"页条数","popupName":"弹窗名称","popupStatus":"弹窗状态(0：停用 1：启用)"}

2、弹窗明细：/api/popup/getPopup/{id}

3、新增弹窗：/api/popup/addPopupPage
{"popupName":"弹窗名称","popupStatus":"弹窗状态(0：停用 1：启用)","popupSort":"弹窗排序号,"popupImg":"弹窗图片",
"popupRate":"弹窗评率(1：仅1次 2：每日1次 3：每次进入)",
"contentType":"关联内容类型",
"contentLink":"关联内容地址",
"popupScopes":["userType":"用户类型(1：所有 2：等级会员 3：365会员 4：plus会员)","userLevel":"用户等级"]}

4、编辑弹窗：/api/popup/editPopupPage
{"id":"id","popupName":"弹窗名称","popupStatus":"弹窗状态(0：停用 1：启用)","popupSort":"弹窗排序号,"popupImg":"弹窗图片","popupRate":"弹窗评率(1：仅1次 2：每日1次 3：每次进入)","contentType":"关联内容类型","contentLink":"关联内容地址","popupScopes":["userType":"用户类型(1：所有 2：等级会员 3：365会员 4：plus会员)","userLevel":"用户等级"]}
 */

/*
 */
function editPopupPage(data) {
  return (0, _request.default)({
    url: '/api/popup/editPopupPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addPopupPage(data) {
  return (0, _request.default)({
    url: '/api/popup/addPopupPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listPopupPage(data) {
  return (0, _request.default)({
    url: '/api/popup/listPopupPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getPopup(data) {
  return (0, _request.default)({
    url: '/api/popup/getPopup/' + data,
    method: 'post',
    loading: 'loading'
  });
}