"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon");

var _tableList = _interopRequireDefault(require("@/components/tableList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default
  },
  data: function data() {
    return {
      deaPath: '/coupon/couponPut/putDea?id=volume&unid=',
      operation: 2,
      btn: '查看详情',
      btnType: 'primary',
      total: 0,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [{
        tit: '券名称',
        prop: 'couponName',
        align: 'center',
        width: 190
      }, {
        tit: '券类型',
        prop: 'couponTypeName',
        align: 'center',
        width: 90
      }, {
        tit: '券编码',
        prop: 'couponId',
        align: 'center',
        width: 200
      }, //					{
      //						tit: "方案名称（优惠券名称）",
      //						prop: 'name'
      //					},
      //					{
      //						tit: "类型",
      //						prop: '',
      //						width: 80
      //					},
      {
        tit: '上线时间',
        prop: 'time',
        align: 'center',
        width: 260
      }, {
        tit: '创建时间',
        prop: 'createTime',
        align: 'center',
        width: 160
      }, {
        tit: '投放数量',
        prop: 'putTotal',
        align: 'center',
        width: 80
      }, //					{
      //						tit: "领购率",
      //						prop: '',
      //						width: 80
      //					},
      {
        tit: '状态',
        prop: 'status',
        width: 80,
        align: 'center'
      }],
      time: [],
      couponId: '',
      couponName: '',
      issuer: '',
      options: [{
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣券'
      }, {
        value: 3,
        label: '礼品券'
      }, {
        value: 4,
        label: '体验券'
      }, {
        value: 5,
        label: '外部优惠券'
      }],
      couponType: '',
      options1: [{
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '已审核'
      }, {
        value: 3,
        label: '已撤回'
      }, {
        value: 4,
        label: '已撤销'
      }, {
        value: 6,
        label: '已终止'
      }, {
        value: 9,
        label: '投放完成'
      }],
      status: ''
    };
  },
  watch: {
    time: function time() {
      console.log(this.time);
    }
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(e) {
      var _this = this;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      this.time = this.time ? this.time : [];
      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        couponType: this.couponType,
        couponId: this.couponId.trim(),
        status: this.status,
        couponName: this.couponName.trim(),
        createBeginTime: this.time[0],
        createEndTime: this.time[1]
      };
      this.openLoading();
      (0, _coupon.listPutCenterPage)(data).then(function (res) {
        _this.openLoading().close();

        _this.tableData = res.data.items;

        _this.tableData.forEach(function (value) {
          // value.couponBagIds=JSON.parse(value.couponBagIds).length
          if (value.onlineTime === 2) {
            value.time = '立即上线';
            value.onlineBeginTime = '立即上线';
          } else if (value.onlineTime === 1) {
            value.onlineBeginTime = value.onlineBeginTime; // .substring(0, 10)

            value.time = value.onlineBeginTime + '至' + value.onlineEndTime;
          } // value.onlineEndTime=value.onlineEndTime.substring(0, 10)
          // value.onlineBeginTime=value.onlineBeginTime.substring(0, 10)


          if (value.putTotal === 0) {
            value.putTotal = '无限制';
          }

          switch (value.status) {
            case 1:
              value.status = '待审核';
              break;

            case 2:
              value.status = '已审核';
              break;

            case 3:
              value.status = '审核撤回';
              break;

            case 4:
              value.status = '审核撤销';
              break;

            case 5:
              value.status = '已过期';
              break;

            case 6:
              value.status = '已终止';
              break;

            case 8:
              value.status = '已投放';
              break;

            case 9:
              value.status = '投放完成';
              break;
          }
        });

        _this.total = res.data.totalNum;
      }).catch(function (res) {
        _this.openLoading().close();
      });
    },
    setBtn: function setBtn(index) {
      console.log(index);
      this.$router.push('/coupon/couponPut/putDea?id=volume&unid=' + index.id);
    },
    goAdd: function goAdd() {
      this.$router.push('/coupon/couponPut/addPut?id=volume');
    },
    getList: function getList() {
      console.log('sdfsdf');
    },
    remove: function remove() {
      this.time = [];
      this.couponId = '';
      this.couponName = '';
      this.couponType = '';
      this.status = '';
      this.pageNo = 1;
      this.pageSize = 10;
      this.init();
    }
  }
};
exports.default = _default;