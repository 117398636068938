var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "openInvoice" },
    [
      _c(
        "div",
        { staticClass: "view_title flex_between" },
        [
          _vm._v("\n    商品分类\n    "),
          _vm.setMenuPerms("legou:category:create")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: {
                    click: function($event) {
                      _vm.isUpdate = true
                    }
                  }
                },
                [_vm._v("\n    新增分类\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-bottom": "20px" },
          attrs: {
            size: "mini",
            "header-row-class-name": "table_th",
            data: _vm.tableData,
            "row-key": "id",
            border: "",
            "tree-props": { children: "children" }
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "gcName", label: "分类", "min-width": "300" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "分类图标", align: "center", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "50px" },
                      attrs: {
                        src: scope.row.gcIcon,
                        "preview-src-list": [scope.row.gcIcon]
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "gcSort",
              label: "排序",
              align: "center",
              "min-width": "120"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "gcGoodsSpec",
              align: "center",
              label: "规格",
              "min-width": "220"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "220",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.setMenuPerms("legou:category:update") &&
                    _vm.setMenuPerms("legou:category:detail")
                      ? _c(
                          "el-button",
                          {
                            staticClass: "btnDea",
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goUppdateItem(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.gcLevel < 3 &&
                    _vm.setMenuPerms("legou:category:create")
                      ? _c(
                          "el-button",
                          {
                            staticClass: "btnDea",
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-circle-plus-outline"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goAddItem(scope.row)
                              }
                            }
                          },
                          [_vm._v("添加子分类")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isUpdate
        ? _c(
            "div",
            { staticClass: "fixed_center" },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex_between",
                      staticStyle: { "align-items": "center" },
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", { staticClass: "view_title" }, [
                        _vm._v("新增/编辑分类")
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-close danger",
                        staticStyle: { "font-size": "20px", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            _vm.isUpdate = false
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "WForm",
                    {
                      attrs: {
                        isTitle: false,
                        "form-data": _vm.formData,
                        "label-width": "120px",
                        "rule-form": _vm.ruleForm,
                        rules: _vm.rules,
                        isHttps: ""
                      },
                      on: { submitForm: _vm.submitForm }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "gcGoodsSpec" }, slot: "gcGoodsSpec" },
                        [
                          _c(
                            "el-form-item",
                            {
                              ref: "gcGoodsSpec",
                              attrs: {
                                label: "关联规格：",
                                prop: "gcGoodsSpec"
                              }
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  on: { change: _vm.changeSpec },
                                  model: {
                                    value: _vm.ruleForm.gcGoodsSpec,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "gcGoodsSpec", $$v)
                                    },
                                    expression: "ruleForm.gcGoodsSpec"
                                  }
                                },
                                _vm._l(_vm.specList, function(item, index) {
                                  return _c("el-checkbox", {
                                    key: index,
                                    attrs: { label: item }
                                  })
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "form_remark" }, [
                                _vm._v("最多可选择两个规格")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "gcParentId" }, slot: "gcParentId" },
                        [
                          _c(
                            "el-form-item",
                            {
                              ref: "gcParentId",
                              attrs: { label: "父级：", prop: "gcParentId" }
                            },
                            [
                              _c("el-tree", {
                                ref: "treeForm",
                                staticClass: "li_input form_input",
                                attrs: {
                                  data: _vm.tableData,
                                  props: _vm.treeProps,
                                  "auto-expand-parent": "",
                                  accordion: "",
                                  "check-strictly": "",
                                  "default-checked-keys": [
                                    _vm.ruleForm.gcParentId
                                  ],
                                  "default-expanded-keys": [
                                    _vm.ruleForm.gcParentId
                                  ],
                                  "show-checkbox": "",
                                  "node-key": "id",
                                  "check-strictly": true,
                                  "expand-on-click-node": false
                                },
                                on: { "check-change": _vm.handleClick }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }