"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addVersion = addVersion;
exports.editVersion = editVersion;
exports.getVersionById = getVersionById;
exports.listVersionPage = listVersionPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
版本号列表

 */
function listVersionPage(data) {
  return (0, _request.default)({
    url: '/api/terminalVersion/listVersionPage',
    method: 'post',
    data: data
  });
}
/*
通过id查询版本号

 */


function getVersionById(data) {
  return (0, _request.default)({
    url: '/api/terminalVersion/getVersionById',
    method: 'post',
    params: data
  });
}
/*
新增版本号

 */


function addVersion(data) {
  return (0, _request.default)({
    url: '/api/terminalVersion/addVersion',
    method: 'post',
    data: data
  });
}
/*
编辑版本号

 */


function editVersion(data) {
  return (0, _request.default)({
    url: '/api/terminalVersion/editVersion',
    method: 'post',
    data: data
  });
}