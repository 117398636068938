"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      list: [{
        tit: '定向投放',
        text: '按照会员特征标签、营销模型等查询条件，锁定会员人群，定向投放优惠券。',
        btn1: '定向投放方案列表',
        btn2: '新增定向投放方案',
        url: 'orient'
      }, {
        tit: '触发机制',
        text: '触发机制包括：消费满**元送券、充值满**元送券、用户行为动作（如新注册、首次支付、首次充值、完善资料等）等，向达到预设条件的用户自动发放优惠券。',
        btn1: '触发投放方案列表',
        btn2: '新增触发投放方案',
        url: 'touch'
      }, {
        tit: '领券中心',
        text: '将优惠券纳入领券中心列表，设置投放计划、投放规则、管理券的上线下线，用户可自行领取或购买。',
        btn1: '领券投放方案列表',
        btn2: '新增领券投放方案',
        url: 'volume'
      }]
    };
  },
  watch: {},
  methods: {
    goAddput: function goAddput(index) {
      console.log(index);
      this.$router.push('/coupon/couponPut/addPut?id=' + index);
    },
    goList: function goList(index) {
      if (index === 0) {
        this.$router.push('/coupon/couponPut/orientPut');
      } else if (index === 1) {
        this.$router.push('/coupon/couponPut/touchPut');
      } else if (index === 2) {
        this.$router.push('/coupon/couponPut/volumeCenter');
      }
    }
  }
};
exports.default = _default;