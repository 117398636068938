var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" " + _vm._s(_vm.ruleForm.messageDetailVo.name) + " ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.goodsForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动状态：" } },
            [
              _vm.examVoList
                ? _c(
                    "el-steps",
                    {
                      attrs: {
                        active: _vm.examVoList.length - 1,
                        "process-status": "finish",
                        "finish-status": "process "
                      }
                    },
                    _vm._l(_vm.ruleForm.examVoList, function(item, index) {
                      return _c(
                        "el-step",
                        { key: index, attrs: { title: item.statusDes } },
                        [
                          _c("template", { slot: "description" }, [
                            _c("span", [
                              _vm._v(_vm._s(item.operator)),
                              _c("br"),
                              _vm._v(_vm._s(item.createTime)),
                              _c("br"),
                              _vm._v(_vm._s(item.description))
                            ])
                          ])
                        ],
                        2
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "活动类型：" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.ruleForm.messageDetailVo.typeDesc) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "活动周期：" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.ruleForm.messageDetailVo.activityTimeRange) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "费用承担：" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.ruleForm.messageDetailVo.costContributorDesc) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "单用户限制：" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.ruleForm.ruleVoList.limitTypeDesc) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "定向人员：" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.ruleForm.ruleVoList.targetUserDesc) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动详情：" } },
            _vm._l(_vm.ruleList, function(item, index) {
              return _c("p", { key: index }, [_vm._v(_vm._s(item))])
            }),
            0
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "可用商品码：" } }, [
            _vm.tableData.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          icon: "el-icon-download",
                          size: "mini",
                          loading: _vm.downloadLoading
                        },
                        on: { click: _vm.handleDownload }
                      },
                      [_vm._v("导出可用商品码")]
                    ),
                    _vm._v(" "),
                    _c("w-table", {
                      attrs: {
                        "table-data": _vm.tableData,
                        height: "500",
                        columns: _vm.columns,
                        "page-obj": _vm.requestData
                      },
                      on: { pagination: _vm.setPage }
                    })
                  ],
                  1
                )
              : _c("div", [_vm._v(" 暂无商品码 ")])
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "添加商品：" } },
            [
              _c(
                "div",
                {
                  staticClass: "flex",
                  staticStyle: { "align-items": "center" }
                },
                [
                  _c(
                    "a",
                    {
                      staticStyle: {
                        "margin-right": "20px",
                        "line-height": "1"
                      },
                      attrs: {
                        href: _vm.downloadUrl,
                        download: "活动券B添加商品模板.xlsx"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "success",
                            icon: "el-icon-download"
                          }
                        },
                        [_vm._v("下载模板")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("upload-excel-component", {
                    attrs: {
                      "btn-text": "批量上传",
                      type: "btn",
                      "on-success": _vm.handleSuccess,
                      "before-upload": _vm.beforeUpload
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { height: "10px" } }),
              _vm._v(" "),
              _c(
                "w-table",
                {
                  attrs: {
                    height: "500",
                    "table-data": _vm.goodsForm.goodsParamList,
                    columns: _vm.columns
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      slot: "operate",
                      label: "操作",
                      align: "center",
                      width: "120"
                    },
                    slot: "operate",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认删除该条商品？" },
                                on: {
                                  confirm: function($event) {
                                    return _vm.delGoods(scope.$index)
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "danger",
                                      size: "mini"
                                    },
                                    slot: "reference"
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }