"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _api = require("./api.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    var _self = this;

    return {
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.startTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      userLabelList: [],
      temList: [],
      HZlist: [{
        label: '所有会员',
        value: 1
      }, {
        label: '365付费会员',
        value: 3
      }, {
        label: '新用户',
        value: 4
      }],
      weekList: [{
        label: '启用',
        value: 1
      }, {
        label: '禁用',
        value: -1
      }],
      popType: '',
      couponShow: false,
      type: [{
        label: '实体商品',
        value: 0
      }, {
        label: '优惠券',
        value: 1
        /* {
          label: '乐购商品',
          value: 2
        } */

      }],
      rules: {
        name: [{
          required: true,
          message: '请输入名称'
        }],
        shortName: [{
          required: true,
          message: '请输入简称'
        }],
        introduction: [{
          required: true,
          message: '请输入描述'
        }],
        image: [{
          required: true,
          message: '请上传图片'
        }],
        ruleDesc: [{
          required: true,
          message: '请输入规则说明'
        }],
        joinLimitType: [{
          required: true,
          message: '请选择会员类型'
        }],
        joinLimitTimes: [{
          required: true,
          message: '请输入参与次数'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        endTime: [{
          required: true,
          message: '请选择时间'
        }],
        startTime: [{
          required: true,
          message: '请选择时间'
        }],
        sort: [{
          required: true,
          message: '请输入排序'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        labelContent: [{
          required: true,
          message: '请选择会员标签'
        }]
      },
      ruleForm: {
        sort: null,
        endTime: null,
        startTime: null,
        status: null,
        remark: null,
        name: null,
        shortName: null,
        introduction: null,
        image: 'mika/dev/2022-04-28/a7e998607f654f82bbd2064e4ea58377.png',
        ruleDesc: null,
        joinLimitType: null,
        joinLimitTimes: null,
        labelContent: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id) {
      (0, _api.getData)({
        id: this.ruleForm.id
      }).then(function (res) {
        Object.keys(_this.ruleForm).map(function (key) {
          Object.keys(res.data).map(function (row) {
            if (key === row) {
              _this.ruleForm[row] = res.data[key];
            }
          });
        });
      });
    }

    this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
      _this.userLabelList = [{
        id: 0,
        labelName: '所有会员'
      }].concat((0, _toConsumableArray2.default)(res.data));
    });
  },
  methods: {
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.addData)(_this2.ruleForm).then(function (res) {
            _this2.$router.go(-1);

            _this2.$message({
              type: 'success',
              message: '编辑成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;