var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "领取详情",
        "append-to-body": true,
        "modal-append-to-body": false,
        visible: _vm.visible,
        size: "80%"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "20px" } },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "20px", color: "#409EFF" } },
              [_vm._v("\n      基础信息\n    ")]
            )
          ]),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              attrs: {
                size: "small ",
                labelStyle: {
                  width: "120px",
                  textAlign: "right"
                },
                column: 3,
                border: ""
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "活动名称：" } }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.orderData.activityName) + "\n    "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "活动状态：" } }, [
                _vm._v("\n      " + _vm._s(_vm.orderData.statusStr) + "\n    ")
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "领取积分：" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.orderData.totalRecevieScoreNum) +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "会员类型：" } }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.orderData.memberTypeName) + "\n    "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "用户手机号：" } }, [
                _vm._v("\n      " + _vm._s(_vm.orderData.userMobile) + "\n    ")
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "用户昵称：" } }, [
                _vm._v("\n      " + _vm._s(_vm.orderData.userName) + "\n    ")
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "活动门店：" } }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.orderData.storeCodeStr) + "\n    "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "20px", color: "#409EFF" } },
              [_vm._v("\n      领取记录\n    ")]
            )
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.orderList, border: "" } },
            _vm._l(_vm.columns, function(item, index) {
              return _c("el-table-column", {
                key: index,
                attrs: { prop: item.prop, label: item.label }
              })
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }