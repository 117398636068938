"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addKinds = addKinds;
exports.classPage = classPage;
exports.delKinds = delKinds;
exports.editKinds = editKinds;
exports.getKinds = getKinds;
exports.getList = getList;
exports.kindsDetail = kindsDetail;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function classPage(data) {
  return (0, _request.default)({
    url: '/api/card/make/kinds/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/kinds',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/kinds/update',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function delKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/kinds/' + data,
    method: 'post'
  });
}

function getKinds(data) {
  return (0, _request.default)({
    url: '/api/card/make/kinds/' + data,
    method: 'get',
    loading: 'loading'
  });
}

function kindsDetail(data) {
  return (0, _request.default)({
    url: '/api/card/make/kinds/detail',
    method: 'post',
    loading: 'loading',
    data: data
  });
}