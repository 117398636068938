"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.function.name");

var _coupon = require("@/api/coupon.js");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      /* usableType;   // 可用类型(1-可用，2-不可用)*/
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/coupon.xlsx"),
      goodsIds: [],
      goodsIds1: [],
      usableType: null,
      id: '',
      addGoods: '',
      addGoods1: '',
      couponName: null,
      uuid: null
    };
  },
  created: function created() {
    var _this = this;

    this.id = this.$route.query.id;
    (0, _coupon.getCouponById)(this.id).then(function (res) {
      _this.couponName = res.data.name;
      _this.uuid = res.data.uuid;
      _this.usableType = res.data.usableType;
      _this.goodsIds = res.data.goodsIds ? JSON.parse(res.data.goodsIds) : [];
      _this.goodsIds1 = res.data.goodsIds1 ? JSON.parse(res.data.goodsIds1) : [];
    });
  },
  methods: {
    delGoods: function delGoods(item, index, isSupermarket) {
      var _self = this;

      this.$confirm("\u786E\u8BA4\u79FB\u9664\u5546\u54C1\u7801".concat(item, ", \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (isSupermarket) {
          _self.goodsIds1.splice(index, 1);
        } else {
          _self.goodsIds.splice(index, 1);
        }
      });
    },
    goAddGoods: function goAddGoods(isSupermarket) {
      if (isSupermarket) {
        if (this.goodsIds1.includes(this.addGoods1)) return;
        this.goodsIds1.push(this.addGoods1);
      } else {
        if (this.goodsIds.includes(this.addGoods)) return;
        this.goodsIds.push(this.addGoods);
      }
    },
    goUpload: function goUpload() {
      var _this2 = this;

      var self = this;
      var goodsIdsErr = this.goodsIds.filter(function (item) {
        return !/^[0-9]*$/.test(item) || !item;
      });
      var goodsIds1Err = this.goodsIds1.filter(function (item) {
        return !/^[0-9]*$/.test(item) || !item;
      });

      if (goodsIdsErr.length > 0) {
        this.$alert(goodsIdsErr, '错误百货商品码，修改后重新上传', {
          confirmButtonText: '确定',
          callback: function callback(action) {
            _this2.$message({
              type: 'info',
              message: "action: ".concat(action)
            });
          }
        });
        return;
      }

      if (goodsIds1Err.length > 0) {
        this.$alert(goodsIds1Err, '错误超市商品码，修改后重新上传', {
          confirmButtonText: '确定',
          callback: function callback(action) {
            _this2.$message({
              type: 'info',
              message: "action: ".concat(action)
            });
          }
        });
        return;
      }

      self.$confirm('确认导入, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _coupon.editCouponGoods)({
          usableType: self.usableType,
          id: self.id,
          goodsIds: self.goodsIds,
          goodsIds1: self.goodsIds1
        }).then(function (res) {
          self.$message({
            type: 'success',
            message: '导入成功!'
          });
          self.$router.go(-1);
        });
      }).catch(function () {
        self.$message({
          type: 'info',
          message: '已取消导入'
        });
      });
    },
    handleDownload: function handleDownload(isSupermarket) {
      var Timestamp = new Date().getTime();
      var data = isSupermarket == 2 ? this.goodsIds1 : this.goodsIds;
      var tableData = data.map(function (item) {
        return {
          CommodityCode: item
        };
      });
      this.getHandleDownload(['CommodityCode'], ['CommodityCode'], tableData, this.couponName + Timestamp);
    },
    handleSuccess: function handleSuccess(_ref) {
      var _this3 = this;

      var results = _ref.results,
          header = _ref.header;
      this.goodsIds = [];
      results.forEach(function (data) {
        if (data.CommodityCode) {
          _this3.goodsIds.push(data.CommodityCode.trim());
        }
      });
    },
    handleSuccess1: function handleSuccess1(_ref2) {
      var _this4 = this;

      var results = _ref2.results,
          header = _ref2.header;
      this.goodsIds1 = [];
      results.forEach(function (data) {
        if (data.CommodityCode) {
          _this4.goodsIds1.push(data.CommodityCode.trim());
        }
      });
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    }
  }
};
exports.default = _default;