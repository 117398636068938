"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '信息管理',
      list: [{
        tit: '分类管理',
        txtArr: '分类管理',
        imgPath: require('@/assets/tab/22.png'),
        path: '/message/class'
      }, {
        tit: '模板管理',
        txtArr: '模板管理',
        imgPath: require('@/assets/tab/23.png'),
        path: '/message/tem'
      }, {
        tit: '信息任务',
        txtArr: '信息任务',
        imgPath: require('@/assets/tab/21.png'),
        path: '/message/list'
      }, {
        tit: '发送日志',
        txtArr: '发送日志',
        imgPath: require('@/assets/tab/21.png'),
        path: '/message/log'
      }]
    };
  }
};
exports.default = _default;