"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    var _this = this;

    return {
      formData: [{
        label: '关键词名称：',
        type: 'input',
        placeholder: '请输入关键词名称',
        value: 'name'
      }, {
        label: '开始金额：',
        type: 'inputNumber',
        placeholder: '请输入开始金额',
        value: 'minAmount',
        min: 0
      }, {
        label: '结束金额：',
        type: 'inputNumber',
        placeholder: '请输入结束金额',
        value: 'maxAmount'
      }, {
        label: '关键词图片：',
        type: 'img',
        placeholder: '关键词图片',
        value: 'picture'
      }],
      ruleForm: {
        id: null,
        name: null,
        maxAmount: undefined,
        minAmount: undefined,
        picture: ''
      },
      rules: {
        picture: [{
          required: true,
          message: '请上传关键词图片'
        }],
        name: [{
          required: true,
          message: '请输入关键词名称'
        }],
        minAmount: [{
          required: true,
          message: '请输入开始金额'
        }],
        maxAmount: [{
          required: true,
          message: '请输入结束金额'
        }, {
          validator: function validator(rule, value, callback) {
            if (value <= _this.ruleForm.minAmount) {
              callback(new Error('请输入大于开始金额的值'));
            } else {
              callback();
            }
          }
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    var _this2 = this;

    this.formType = this.$route.query.type;
    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/dis365ExchangeScore/getDis365ExchangeScoreKeywordById/' + this.ruleForm.id).then(function (res) {
        _this2.ruleForm = res.data;
      });
    }
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this3 = this;

      var requestData = (0, _objectSpread2.default)({}, this.ruleForm);
      var apiUrl = this.ruleForm.id ? "/api/dis365ExchangeScore/updateDis365ExchangeScoreKeyword" : "/api/dis365ExchangeScore/addDis365ExchangeScoreKeyword";
      this.apiPost(apiUrl, requestData).then(function (res) {
        _this3.$router.go(-1);

        _this3.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;