var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("新增扫码返券")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "sysUserMobile",
                  attrs: { label: "返券人：", prop: "sysUserMobile" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入手机号码",
                        "remote-method": _vm.remoteMethod
                      },
                      on: { change: _vm.changeUser },
                      model: {
                        value: _vm.ruleForm.sysUserMobile,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "sysUserMobile", $$v)
                        },
                        expression: "ruleForm.sysUserMobile"
                      }
                    },
                    _vm._l(_vm.userList, function(item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: {
                          label: item.username + "(" + item.mobile + ")",
                          value: item.mobile
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { ref: "stock", attrs: { label: "返券库存：", prop: "stock" } },
                [
                  _c("el-input-number", {
                    attrs: { controls: false, precision: 0, min: 1 },
                    model: {
                      value: _vm.ruleForm.stock,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "stock", $$v)
                      },
                      expression: "ruleForm.stock"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券包：", prop: "couponBagUuid" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.getAward("couponBag")
                        }
                      }
                    },
                    [_vm._v("\n          选择券包\n        ")]
                  ),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.ruleForm.couponBagUuid) +
                      "\n      "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }