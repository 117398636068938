"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      current: 0,
      titles: ["卡片重写", "卡片加磁", "重置校验码"],
      open: false,
      params: {},
      rules: {}
    };
  }
};
exports.default = _default;