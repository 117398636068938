"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

/** When your routing table is too long, you can split it into small modules **/
var cardRebateRouter = {
  path: 'cardRebate',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/activity/cardRebate/index.vue'));
    });
  },
  redirect: '/activity/cardRebate/tab',
  name: 'cardRebate',
  meta: {
    title: '充卡返利',
    icon: 'nested'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/cardRebate/tab.vue'));
      });
    },
    name: 'cardRebateTab',
    meta: {
      title: '充卡返利'
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/cardRebate/list.vue'));
      });
    },
    name: 'cardRebateList',
    meta: {
      title: '充卡返利',
      keepAlive: true,
      activeMenu: '/activity/cardRebate/tab'
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/cardRebate/add.vue'));
      });
    },
    name: 'cardRebateAdd',
    meta: {
      title: '编辑充卡返利',
      activeMenu: '/activity/cardRebate/tab'
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/cardRebate/order.vue'));
      });
    },
    name: 'cardRebateOrder',
    meta: {
      title: '充卡返利记录',
      activeMenu: '/activity/cardRebate/tab',
      keepAlive: true
    }
  }, {
    path: 'rechargeList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./admin/list.vue"));
      });
    },
    name: 'cardRechargeList',
    meta: {
      title: '后台和蜻蜓充值记录',
      activeMenu: '/activity/cardRebate/tab',
      keepAlive: true,
      pageList: 'records',
      pageTotal: 'total',
      //addUrl:'/activity/cardRebate/recharge',
      //addTxt:'去充值',
      api: '/api/lx/lx-store-card-charge/pageStoreCardActivityCharge4Admin',
      columns: [{
        prop: 'chargeTime',
        label: '时间',
        width: 100
      }, {
        prop: 'chargeOrderNo',
        label: '订单号',
        width: 80
      }, {
        prop: 'activityName',
        label: '活动名称',
        sortable: true,
        width: 50
      }, {
        prop: 'rightName',
        label: '权益名称',
        width: 50
      }, {
        prop: 'chargeAmount',
        label: '充值金额',
        width: 50
      }, {
        prop: 'payAmount',
        label: '支付金额',
        width: 50
      }, {
        prop: 'chargeUserMobile',
        label: '用户手机号',
        sortable: true,
        width: 50
      }, {
        prop: 'operUserName',
        label: '操作人',
        width: 50
      }, {
        prop: 'operUserMobile',
        label: '操作人手机号',
        width: 50
      }, {
        prop: 'chargeStoreCode',
        label: '门店编码',
        width: 50
      }, {
        prop: 'chargeStoreName',
        label: '门店名称',
        width: 50
      },
      /* {
         slot:'onlineCard',
         prop: 'onlineCard',
         label: '卡类型',
         width: 50
        }, */
      {
        prop: 'chargeCardUuid',
        label: '充值卡号',
        width: 80
      }, {
        label: '备注',
        prop: 'remark',
        width: 80
      }]
    }
  }, {
    path: 'recharge',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./admin/add.vue"));
      });
    },
    name: 'cardRecharge',
    meta: {
      title: '充值购物卡',
      activeMenu: '/activity/cardRebate/tab'
    }
  }, {
    path: 'giftOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./giftOrder.vue"));
      });
    },
    name: 'giftOrder',
    meta: {
      title: '礼品领取记录',
      activeMenu: '/activity/cardRebate/tab',
      keepAlive: true
    }
  }]
};
var _default = cardRebateRouter;
exports.default = _default;