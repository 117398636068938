"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

var _api2 = require("@/api/api.js");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    wTable: _index.default
  },
  data: function data() {
    return {
      storeList: [],
      requestData: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        kpName: '',
        skrName: '',
        fhrName: '',
        storeId: ''
      },
      tableData: [],
      columns: [{
        label: '门店名称',
        prop: 'storeName',
        width: 160
      }, {
        label: '门店开票人',
        prop: 'kpName',
        width: 180
      }, {
        label: '收款人',
        prop: 'skrName',
        width: 180
      }, {
        label: '复核人',
        prop: 'fhrName',
        width: 180
      }, {
        slot: 'operate'
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _api2.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    delTag: function delTag(scope) {},
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this2 = this;

      if (type == 'search') {
        this.pageNo = 1;
      }

      if (type == 'remove') {
        this.removeObj(this.requestData);
      }

      (0, _api.listLpStorePage)(this.requestData).then(function (res) {
        _this2.tableData = res.data.items;
        _this2.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;