"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkSms = checkSms;
exports.checkUserPwd = checkUserPwd;
exports.couponQRCode = couponQRCode;
exports.editSysUserPwd = editSysUserPwd;
exports.editUserPwd = editUserPwd;
exports.getInfo = getInfo;
exports.getSysLog = getSysLog;
exports.listExecuteRemark = listExecuteRemark;
exports.listInstitutionInfoAllTreeNodes = listInstitutionInfoAllTreeNodes;
exports.listMemberCardInstitutionInfoNodes = listMemberCardInstitutionInfoNodes;
exports.listMenuAndMenuPerms = listMenuAndMenuPerms;
exports.listSysLogPage = listSysLogPage;
exports.login = login;
exports.logout = logout;
exports.resetSysUserPwd = resetSysUserPwd;
exports.sendSms = sendSms;
exports.xcxQRCode = xcxQRCode;

var _request = _interopRequireDefault(require("@/utils/request"));

/* 操作日志分页：/api/sysLog/listSysLogPage
{"pageNo":"当前页","pageSize":"页条数","userName":"用户姓名","userMobile":"用户手机号"} */

/*
操作日志分页
 */
function listSysLogPage(data) {
  return (0, _request.default)({
    url: '/api/sysLog/listSysLogPage',
    method: 'post',
    data: data
  });
}

function listExecuteRemark(data) {
  return (0, _request.default)({
    url: '/api/sysLog/listExecuteRemark',
    method: 'post',
    data: data
  });
}
/*
操作日志详情
/api/sysLog/getSysLog/{logId}
 */


function getSysLog(data) {
  return (0, _request.default)({
    url: '/api/sysLog/getSysLog/' + data,
    method: 'post'
  });
}
/*
验证用户密码
 */


function checkUserPwd(data) {
  return (0, _request.default)({
    url: '/api/mainUser/checkUserPwd',
    method: 'post',
    data: data
  });
}
/*

 、编辑用户密码：/api/mainUser/editUserPwd
 {"uid":"uid","managerNo":"客户经理编码","password":"密码","password_":"确认密码"}
 */


function editUserPwd(data) {
  return (0, _request.default)({
    url: '/api/mainUser/editUserPwd',
    method: 'post',
    data: data
  });
}
/*
修改密码
 */


function editSysUserPwd(data) {
  return (0, _request.default)({
    url: '/api/sysUser/editSysUserPwd',
    method: 'post',
    data: data
  });
}
/*
重置密码
 */


function resetSysUserPwd(data) {
  return (0, _request.default)({
    url: '/api/sysUser/resetSysUserPwd',
    method: 'post',
    data: data
  });
}
/*
 发送验证码
 */


function sendSms(phone) {
  return (0, _request.default)({
    url: '/api/sms/sendSms/' + phone,
    method: 'post'
  });
}
/*
 验证验证码
 */


function checkSms(data) {
  return (0, _request.default)({
    url: '/api/sms/checkSms',
    method: 'post',
    data: data
  });
}

function login(datas) {
  var data1 = {
    account: datas.username,
    password: datas.password
  };
  return (0, _request.default)({
    url: '/api/user/login',
    method: 'post',
    params: data1
  });
}

function getInfo(token) {
  return (0, _request.default)({
    url: '/user/info',
    method: 'get',
    baseURL: '',
    params: {
      token: token
    }
  });
}

function logout() {
  return (0, _request.default)({
    url: '/user/logout',
    method: 'post'
  });
}

function listMenuAndMenuPerms() {
  return (0, _request.default)({
    url: '/api/sysMenu/listMenuAndMenuPerms',
    method: 'post',
    data: data
  });
}

function xcxQRCode(data) {
  // 获取小程序码
  // console.log('/api/fileManage/xcxQRCode/'+data.path+'/'+data.scene)
  return (0, _request.default)({
    url: '/api/fileManage/xcxQRCode',
    method: 'post',
    // params:data
    data: data
  });
}

function couponQRCode(data) {
  // 获取小程序码
  data.envVersion = process.env.VUE_APP_E_V;
  return (0, _request.default)({
    url: '/api/fileManage/couponQRCode',
    method: 'post',
    data: data
  });
}

function listInstitutionInfoAllTreeNodes(arr) {
  // 查询机构(tree)接口
  return (0, _request.default)({
    url: '/api/institutionInfo/listInstitutionInfoAllTreeNodes',
    method: 'post',
    arr: arr
  });
}
/* export function listMemberCardInstitutionInfoNodes(arr) { // 查询会员卡及机构(tree)接口
  return request({
    url: '/api/institutionInfo/listMemberCardInstitutionInfoNodes',
    method: 'post',
   	arr
  })
} */


function listMemberCardInstitutionInfoNodes(arr) {
  // 查询会员卡及机构(tree)接口
  return (0, _request.default)({
    url: '/api/company/listBaseTree',
    method: 'post',
    arr: arr
  });
}