var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row_div", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 2 } }, [
            _vm._v("版本号：")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内容" },
                model: {
                  value: _vm.obj.versionNo,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "versionNo", $$v)
                  },
                  expression: "obj.versionNo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row_div", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 2 } }, [
            _vm._v("版本更新说明：")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { height: 300 },
                on: { onChange: _vm.change },
                model: {
                  value: _vm.obj.versionDesc,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "versionDesc", $$v)
                  },
                  expression: "obj.versionDesc"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row_div", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 2 } }, [
            _vm._v("版本状态：")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "radio_div", attrs: { span: 6 } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.versionStatu,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "versionStatu", $$v)
                    },
                    expression: "obj.versionStatu"
                  }
                },
                [_vm._v("启用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: -1 },
                  model: {
                    value: _vm.obj.versionStatu,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "versionStatu", $$v)
                    },
                    expression: "obj.versionStatu"
                  }
                },
                [_vm._v("停用")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row_div", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 2 } }, [
            _vm._v("\n       \n    ")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.next } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }