var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("兑换详情")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "label-width": "120px", size: "small" }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 9 } },
                [
                  _c("el-form-item", { attrs: { label: "优惠券码：" } }, [
                    _vm._v(_vm._s(_vm.form.couponCode))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "优惠券名称：" } }, [
                    _vm._v(_vm._s(_vm.form.couponName))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c("el-form-item", { attrs: { label: "优惠券类型：" } }, [
                    _vm._v(_vm._s(_vm.form.couponTypeName))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 9 } },
                [
                  _c("el-form-item", { attrs: { label: "券领取编码：" } }, [
                    _vm._v(_vm._s(_vm.form.tradeOrderNo))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "兑换时间：" } }, [
                    _vm._v(_vm._s(_vm.form.usedTime))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c("el-form-item", { attrs: { label: "操作人：" } }, [
                    _vm._v(_vm._s(_vm.form.usedUname))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "备注：" } }, [
            _vm._v(_vm._s(_vm.form.remark))
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "兑换凭证：" } },
            _vm._l(_vm.attachment, function(item, index) {
              return _c("img", {
                key: index,
                staticStyle: {
                  width: "100px",
                  height: "100px",
                  "margin-right": "10px",
                  border: "1px solid #CCCCCC"
                },
                attrs: { src: _vm.imgSrc + item }
              })
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }