"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activationInterests = activationInterests;
exports.addVipInterests = addVipInterests;
exports.editVipInterests = editVipInterests;
exports.getVipInterests = getVipInterests;
exports.listVip = listVip;
exports.listVipInterestsPage = listVipInterestsPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
1、会员权益分页：/api/vipInterests/listVipInterestsPage
{"pageNo":"当前页","pageSize":"页条数","interestsName":"权益名称","interestsStatus":"权益状态(1：启用 0：停用)","vipType":"会员类型(1：365会员 2：MIKA  PLUS)"}

2、权益明细：/api/vipInterests/getVipInterests/{id}

3、新增权益：/api/vipInterests/addVipInterests
  {
    "interestsName":"权益名称",
    "interestsStatus":"权益状态(1：启用 0：停用)",
    "vipType":"会员类型(1：365会员 2：MIKA  PLUS)",
    "couponCodes":["边编码","券编码"]
  }

4、编辑权益：/api/vipInterests/editVipInterests
  {
    "id":"id",
    "interestsName":"权益名称",
    "interestsStatus":"权益状态(1：启用 0：停用)",
    "vipType":"会员类型(1：365会员 2：MIKA  PLUS)",
    "couponCodes":["边编码","券编码"]
  }

  会员设置所有：/api/vipSet/listVip
  {"vipName":"会员名称","vipStatus":" 会员状态(0：停用 1：启用)"}
 */
function listVip(data) {
  return (0, _request.default)({
    url: '/api/vipSet/listVip',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listVipInterestsPage(data) {
  return (0, _request.default)({
    url: '/api/vipInterests/listVipInterestsPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
 */


function addVipInterests(data) {
  return (0, _request.default)({
    url: '/api/vipInterests/addVipInterests',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editVipInterests(data) {
  return (0, _request.default)({
    url: '/api/vipInterests/editVipInterests',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getVipInterests(data) {
  return (0, _request.default)({
    url: '/api/vipInterests/getVipInterests/' + data,
    method: 'post',
    loading: 'loading'
  });
}
/* 
 /api/vipInterests/activationInterests
 {"interests":[{"userMobile":"会员手机号","interestsCode":"权益编码":"activationNo":" 激活批次号"}]}
 
 */


function activationInterests(data) {
  return (0, _request.default)({
    url: '/api/vipInterests/activationInterests',
    method: 'post',
    loading: 'loading',
    data: data
  });
}