var _defineProperty = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty");

var _ref;

module.exports = {
  urlList: [{
    value: '/shop/category/category',
    label: '商城2.0分类'
  }, {
    value: '/legou/pages/yanxuan/yanxuan',
    label: 'MIKA严选'
  }, {
    value: '/livePayment/group/manger',
    label: '团购经理页面'
  }, {
    value: '/pages/storeTem/merchant',
    label: '品牌总览'
  }, {
    value: 'openPhone',
    label: '拨打电话'
  }, {
    value: '/activity/score/score',
    label: '积分玩一玩'
  }, {
    value: '/vip/changeScore/changeScore',
    label: '365节省金额兑换积分'
  }, {
    value: '/svip/booking/storeActivity',
    label: '沙龙活动8.1'
  }, {
    value: '/svip/activity/storeActivity?shopId=12011',
    label: '门店活动-荔星中心'
  }, {
    value: '/svip/activity/storeActivity?shopId=12012',
    label: '门店活动-汇金星力城'
  }, {
    value: '/svip/activity/storeActivity?shopId=12002',
    label: '门店活动-瑞金店'
  }, {
    value: '/svip/activity/storeActivity?shopId=12004',
    label: '门店活动-遵义星力城'
  }, {
    value: '/svip/activity/storeActivity?shopId=12001',
    label: '门店活动-荔星名店'
  }, {
    value: '/legou/brand/brand?shopId=12011',
    label: '门店品牌-荔星中心'
  }, {
    value: '/legou/brand/brand?shopId=12012',
    label: '门店品牌-汇金星力城'
  }, {
    value: '/legou/brand/brand?shopId=12002',
    label: '门店品牌-瑞金店'
  }, {
    value: '/legou/brand/brand?shopId=12004',
    label: '门店品牌-遵义星力城'
  }, {
    value: '/legou/brand/brand?shopId=12001',
    label: '门店品牌-荔星名店'
  }, {
    value: '/vip/refund/index',
    label: '365买贵包退'
  }, {
    value: '/livePayment/group/index',
    label: '团购业务首页'
  }, {
    value: '/livePayment/group/manger',
    label: '团购业务经理'
  }, {
    value: '/livePayment/group/tem?id=groupIndex',
    label: '团购商品列表'
  }, {
    value: '/vip/order/index',
    label: '365会员2022消费之旅'
  }, {
    value: '/mall/search/search',
    label: '积分商城搜索'
  }, {
    value: '/vip/order/list',
    label: '365会员2022消费记录'
  }, {
    value: '/svip/goods/lists',
    label: 'Lavant专属商品'
  }, {
    value: '/legou/pages/pt/lavant/index',
    label: 'Lavant专属团购'
  }, {
    value: '/legou/pages/pt/lavant/index?type=2',
    label: '365专属团购'
  }, {
    value: '/legou/pages/pt/lavant/index?type=1',
    label: '普通会员专属团购'
  }, {
    value: '/vip/openCard/perfectInfo',
    label: '购买365'
  }, {
    value: '/svip/tutorial',
    label: '荔星荟首页'
  }, {
    value: '/pages/user/perfectInfo/perfectInfo',
    label: '完善信息'
  }, {
    value: '/activity/pufa/pufa',
    label: '浦发信用卡'
  }, {
    value: '/svip/intro',
    label: '荔星荟介绍'
  }, {
    label: '沙龙活动全部',
    value: '/svip/activity/index'
  }, {
    value: 'svipActivityClass',
    label: '沙龙活动分类',
    page: '/svip/activity/index?type=',
    scene: '',
    query: '&classId=',
    remark: '分类ID'
  }, {
    value: '/svip/activity/index?type=1',
    label: '沙龙活动首页（普通会员）',
    page: '/svip/activity/index',
    urlScene: '1'
  }, {
    value: '/svip/activity/index?type=2',
    label: '沙龙活动首页（lavant会员）',
    page: '/svip/activity/index',
    urlScene: '2'
  }, {
    value: '/svip/activity/index?type=3',
    label: '沙龙活动首页（365会员）',
    page: '/svip/activity/index',
    urlScene: '3'
  }, {
    value: '/svip/service/list',
    label: '荔星荟服务列表'
  }, {
    value: 'svipServiceDetail',
    label: '荔星荟服务详情',
    page: '/svip/service/detail',
    scene: '',
    query: '?id=',
    remark: '荔星荟服务ID'
  }, {
    value: '/svip/activity/list',
    label: '荔星荟活动列表'
  }, {
    value: 'svipActivityDetail',
    label: '荔星荟活动详情',
    page: '/svip/activity/detail',
    scene: '',
    query: '?id=',
    remark: '荔星荟活动ID'
  }, {
    value: '/svip/space/list',
    label: '荔星荟场地列表'
  }, {
    value: 'svipSpaceDetail',
    label: '荔星荟场地详情',
    page: '/svip/space/detail',
    scene: '',
    query: '?id=',
    remark: '荔星荟场地ID'
  }, {
    value: '/svip/goods/category',
    label: '要客专享商品'
  }, {
    value: '/svip/right/myRights',
    label: '我的要客权益'
  }, {
    value: '/activity/newGift/index',
    label: '新人礼'
  }, {
    value: '/activity/newGift/store?id=1&storeCode=12009',
    label: '都匀星力城-一元购'
  }, {
    value: '/activity/newGift/store?id=1&storeCode=12012',
    label: '汇金星力城-一元购'
  }, {
    value: '/activity/newGift/store?id=1&storeCode=12003',
    label: '荔星名店A-一元购'
  }, {
    value: '/activity/newGift/store?id=1&storeCode=12004',
    label: '遵义C-一元购'
  }, {
    value: '/activity/newGift/store?id=1&storeCode=12002',
    label: '瑞金-一元购'
  }, {
    value: '/activity/cardWRebate/index',
    label: '购物卡充值返利'
  }, {
    value: '/activity/cardWRebate/getGift',
    label: '购物卡充值奖励'
  }, {
    value: '/activity/week/index',
    label: '周期推荐'
  }, {
    value: '/activity/week/index?id=2',
    label: '周期推荐(周三)'
  }, {
    value: 'specialActivity',
    label: '特价活动',
    page: '/activity/newGift/special',
    scene: '',
    query: '?id=',
    remark: '活动ID'
  }, {
    value: 'waterCoupon',
    label: '消费返券',
    page: '/activity/waterCoupon/index',
    scene: '',
    query: '?id=',
    remark: '活动ID'
  }, {
    value: '/pages/newInvoice/special/special',
    label: '开专票'
  }, {
    value: '/activity/sign/index',
    label: '签到'
  }, {
    value: '/activity/week/index?id=',
    label: '周期推荐小程序码',
    page: '/activity/week/index',
    query: '?id=',
    remark: '周一到周日（0~6）',
    scene: ''
  }, {
    value: '/pages/coupons/getCoupon/getCoupon',
    label: '领券中心'
  }, {
    value: 'vipCityLife',
    label: '同城生活商品',
    unOther: true
  }, {
    value: 'legouGoods',
    label: '乐购商品',
    page: '/legou/pages/goods/goods',
    scene: 'gid=',
    query: '?id=',
    remark: '乐购商品ID'
  }, {
    value: 'legouTem',
    label: '乐购自定义页面',
    page: '/legou/pages/index/index',
    scene: 'page_id=',
    query: '?page_id=',
    remark: '乐购页面ID'
  }, {
    value: 'couponBag',
    label: '券包详情',
    page: '/pages/coupons/couponGift/couponGift',
    scene: '',
    query: '?id=',
    remark: '优惠券包ID'
  }, {
    value: 'giveScore',
    label: '赠送积分活动',
    page: '/activity/giveScore/giveScore',
    scene: '',
    query: '?id=',
    remark: '赠送积分ID'
  }, {
    value: 'help',
    label: '好友助力',
    page: '/activity/help/help',
    query: '?id=',
    remark: '好友助力ID'
  }, {
    value: '/activity/apply/list',
    label: '报名活动列表'
  }, {
    value: 'apply',
    label: '报名活动详情',
    page: '/activity/apply/dea',
    query: '?id=',
    remark: '报名活动ID'
  }, {
    value: 'getCard',
    label: '团购统一领取',
    page: '/activity/getCard/getCard',
    query: '?id=',
    remark: '团购统一领取单号'
  }, {
    value: 'usinghelpDea',
    label: '帮助协议详情',
    page: '/pages/user/usinghelpDea/usinghelpDea',
    query: '?articleId=',
    remark: '帮助协议详情ID'
  }, {
    value: '/activity/lixing/lixing',
    label: '荔星报名活动'
  }, {
    value: 'coupon',
    label: '优惠券详情',
    page: '/pages/coupons/couponDea/couponDea',
    scene: 'id=',
    query: '?id=',
    remark: '优惠券编码'
  }, {
    value: 'goods',
    label: '积分商城商品',
    page: '/mall/goods/dea',
    scene: '',
    query: '?id=',
    remark: '积分商城商品编码'
  },
  /* {
     value: 'activity',
     label: '促销活动',
    }, */
  {
    value: 'card',
    label: '购物卡详情',
    page: '/pages/starCard/recharge/recharge',
    scene: 'id=',
    query: '?id=',
    remark: '购物卡编码'
  }, {
    value: 'giftToken',
    label: '提货卡',
    page: '/pages/starCard/giftToken/giftToken',
    scene: 'id=',
    query: '?id=',
    remark: '提货卡编码',
    unOther: true
  }, {
    value: 'pond',
    label: '抽奖',
    page: '/pages/pond/pond/pond',
    scene: '',
    query: '?id=',
    remark: '抽奖活动编码'
  }, {
    value: 'blind',
    label: '盲盒抽奖',
    page: '/activity/pond/pond',
    scene: '',
    query: '?id=',
    remark: '抽奖活动编码'
  }, {
    value: 'vote',
    label: '投票',
    page: '/pages/voteActivity/voteActivity',
    scene: 'id=',
    query: '?id=',
    remark: '投票ID'
  }, {
    value: 'tem',
    label: '自定义页面',
    page: '/pages/tem/tem',
    scene: '',
    query: '?id=',
    remark: '页面编码'
  }, {
    value: 'auto',
    label: '无title自定义',
    page: '/pages/tem/auto',
    scene: '',
    query: '?id=',
    remark: '页面编码'
  }, {
    value: 'storeTem',
    label: '门店自定义',
    page: '/pages/storeTem/storeTem',
    scene: '',
    query: '?id=',
    remark: '页面编码'
  }, {
    value: '/mall/order/list',
    label: '我的订单'
  }, {
    value: '/vip/index',
    label: 'MIKA365(已废弃)'
  }, {
    value: '/vip/pullNew/pullNew',
    label: '邀请新人得50元'
  }, {
    value: '/pages/user/equity/equity',
    label: '我的权益'
  }, {
    value: '/pages/storeTem/list',
    label: '门店列表'
  }, {
    value: '/carPark/list',
    label: '停车厂列表'
  }, {
    value: '/carPark/index?carparkId=1',
    label: '荔星中心停车'
  }, {
    value: '/carPark/index?carparkId=2',
    label: '汇金停车（新）'
  }, {
    value: '/livePayment/parking/parking',
    label: '汇金停车(旧)'
  }, {
    value: '/vip/zengKa/zengKa',
    label: '团购365'
  }, {
    value: '/activity/maotai/maotai',
    label: 'MIKAPLUS'
  }, {
    value: '/vip/openGift',
    label: 'MIKA365开卡礼'
  }, {
    value: '/vip/shoppe/shoppe',
    label: '百货专柜'
  }, {
    value: '/pages/tem/tem?id=ds',
    label: '购物卡商城'
  }, {
    value: '/mall/index',
    label: '积分商城'
  }, {
    value: 'storeMap',
    label: '门店地图',
    unOther: true
  }, {
    value: '/vip/vipCityLife',
    label: '同城生活'
  }, {
    value: '/vip/pufa/manage',
    label: '浦发理财'
  }, {
    value: '/pages/discover/index/index',
    label: '活动列表'
  }, {
    value: '/pages/coupons/coupons/coupons',
    label: '我的优惠券'
  }, {
    value: '/pages/starCard/myStarCode/myStarCode',
    label: '我的购物卡'
  }, {
    value: '/pages/double11/double11',
    label: '星享券'
  }, {
    value: '4',
    label: '其他小程序'
  }, {
    value: '6',
    label: '视频号主页'
  }, {
    value: '5',
    label: 'WEB页面'
  }, {
    value: '/legou/pages/index/index',
    label: '乐购首页'
  }, {
    page: '/legou/mch/shop/shop',
    label: '乐购店铺首页',
    value: 'legouShop',
    scene: '',
    query: '?mch_id=',
    remark: '店铺ID'
  }, {
    value: '/legou/mch/shop-cat/shop-cat',
    label: '乐购店铺分类'
  }, {
    value: '/legou/pages/order/order',
    label: '乐购乐购订单'
  }, {
    value: '/legou/pages/miaosha/miaosha',
    label: '乐购整点秒杀'
  }, {
    value: '/legou/pages/list/index',
    label: '乐购星力乐购.直播间'
  }, {
    value: '/legou/auction/selectShop/selectShop',
    label: '乐购门店列表'
  }, {
    value: '/legou/pages/replay/index',
    label: '乐购直播回放'
  }, {
    value: '/legou/pages/live/index',
    label: '乐购星直播'
  }, {
    value: '/legou/pages/pt/index/index',
    label: '乐购拼团首页'
  }, {
    value: 'legouPt',
    label: '乐购拼团商品'
  }, {
    value: '/legou/packMember/pages/member-subsidies/index',
    label: '乐购硬核补贴'
  }, {
    value: '/legou/pages/share/index',
    label: '乐购分销中心'
  }, {
    value: '/legou/pages/share/order',
    label: '乐购分销订单'
  }, {
    value: '/legou/pages/favorite/favorite',
    label: '乐购我的收藏'
  }, {
    value: '/pages/starCard/tiedCard/cardInfo',
    label: '绑定实体卡'
  },
  /*
      {
        value: '/pages/puzzle/start?gameId=',
        label: '大屏拼图游戏',
      },
      {
        value: '/pages/yang/start?gameId=',
        label: '大屏羊游戏',
      },
      "bigPuzzle",
      "bigYang",
   */
  {
    value: 'bigYang',
    label: '大屏羊游戏'
  }, {
    value: 'bigPuzzle',
    label: '大屏拼图游戏'
  }, {
    value: 'mikaYang',
    label: '小程序羊游戏'
  }, {
    value: 'mikaPuzzle',
    label: '小程序图游戏'
  }, (_ref = {
    value: 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin',
    label: '直播'
  }, _defineProperty(_ref, "value", 'legouLive'), _defineProperty(_ref, "scene", ''), _defineProperty(_ref, "query", '?room_id='), _defineProperty(_ref, "remark", '直播间ID'), _ref)],
  appIdArr: [{
    value: 'wx63ea1f64620be998',
    label: 'MIKA小程序'
  }, {
    value: 'wx180401a89f051ffb',
    label: '天府租城'
  }, {
    value: 'wxb5d9d1a4a2c9434e',
    label: '白酒消费券'
  }, {
    value: 'wxf0cf91c3f4a31ff6',
    label: '助力星消费'
  }, {
    value: 'wx90539d082f32e16f',
    label: '乐品鲜活'
  }, {
    value: 'wxe6843e111c37fa99',
    label: '景区直通车'
  }, {
    value: 'wx3d25f9499a96d57e',
    label: '星力乐购'
  }, {
    value: 'wx1f693db219ee13fa',
    label: '星力名酒预定'
  }, {
    value: 'wx1e58aed1992d17d0',
    label: '偏坡'
  }, {
    value: 'wx65cc950f42e8fff1',
    label: '出行服务'
  }, {
    value: 'wxaf35009675aa0b2a',
    label: '打车'
  }, {
    value: 'wxd2ade0f25a874ee2',
    label: '生活缴费'
  }, {
    value: 'wx336dcaf6a1ecf632',
    label: '火车票机票'
  }, {
    value: 'wx2aed548e27e28de9',
    label: '梁婉禾'
  }],
  vlaueData: {
    vipSubsidy: 'uuid',
    vipCity: 'uuid',
    card: 'uuid',
    coupon: 'uuid',
    couponBag: 'id',
    giveScore: "id",
    help: 'id',
    apply: 'id',
    vipCityLife: 'productCode',
    goods: 'productCode',
    pond: 'code',
    blind: 'code',
    tem: 'pageCode',
    usinghelpDea: 'articleId'
  },
  urlTypeList: ['openPhone', "bigPuzzle", "bigYang", "mikaPuzzle", "mikaYang", 'getCard', 'coupon', //'activity',
  'card', 'vote', 'tem', 'goods', 'pond', 'blind', 'storeTem', 'auto', 'storeMap', 'vipCityLife', 'couponBag', 'giveScore', 'help', 'apply', 'usinghelpDea', 'legouGoods', 'legouTem', 'waterCoupon', 'specialActivity', 'legouShop', 'legouLive', 'svipServiceDetail', 'svipActivityDetail', 'svipSpaceDetail', '6', 'legouPt', 'svipActivityClass'],
  noSelect: ['openPhone', 'giveScore', 'blind', 'vote', 'storeTem', 'auto', 'storeMap', 'legouGoods', 'legouTem', 'specialActivity', 'waterCoupon', 'legouShop', 'legouLive', 'legouPt', '6', 'usinghelpDea', 'svipActivityDetail', 'svipServiceDetail', 'svipSpaceDetail', 'getCard'],
  linkUrlData: {
    getCard: {
      url: '/activity/getCard/getCard?id=',
      name: '团购统一领取',
      prop: 'id'
    },
    coupon: {
      url: '/pages/coupons/couponDea/couponDea?share=unShare&id=',
      name: '优惠券详情',
      prop: 'uuid'
    },
    card: {
      url: '/pages/starCard/recharge/recharge?id=',
      name: '购物卡详情',
      prop: 'uuid'
    },
    vote: {
      url: '/pages/voteActivity/voteActivity?id=',
      name: '投票'
    },
    tem: {
      url: '/pages/tem/tem?id=',
      name: '自定义页面',
      prop: 'id'
    },
    goods: {
      url: '/mall/goods/dea?id=',
      name: '积分商城商品',
      prop: 'productCode'
    },
    pond: {
      url: '/pages/pond/pond/pond?id=',
      name: '抽奖',
      prop: 'code'
    },
    blind: {
      url: '/activity/pond/pond?id=',
      name: '盲盒',
      prop: 'code'
    },
    storeTem: {
      url: '/pages/storeTem/storeTem?id=',
      name: '门店模板首页'
    },
    auto: {
      url: '/pages/tem/auto?id=',
      name: '模板页面无顶部'
    },
    storeMap: {
      url: '/pages/storeTem/map?id=',
      name: '门店地图'
    },
    vipCityLife: {
      url: '/pages/coupons/couponDea/couponDea?share=unShare&type=3&vip=isVip&id=',
      name: '同城生活详情'
    },
    couponBag: {
      url: '/pages/coupons/couponGift/couponGift?id=',
      name: '优惠券包详情',
      prop: 'id'
    },
    giveScore: {
      url: '/activity/giveScore/giveScore?id=',
      name: '赠送积分活动详情',
      prop: 'id'
    },
    help: {
      url: '/activity/help/help?id=',
      name: '好友助力详情',
      prop: 'id'
    },
    apply: {
      url: '/activity/apply/dea?id=',
      name: '报名活动详情',
      prop: 'id'
    },
    usinghelpDea: {
      url: '/pages/user/usinghelpDea/usinghelpDea?articleId=',
      name: '帮助协议详情',
      prop: 'articleId'
    },
    legouGoods: {
      url: '/legou/pages/goods/goods?id=',
      name: '乐购商品详情'
    },
    legouTem: {
      url: '/legou/pages/index/index?page_id=',
      name: '乐购模板页面',
      prop: 'page_id'
    },
    waterCoupon: {
      url: '/activity/waterCoupon/index?id=',
      name: '流水返券详情',
      prop: 'id'
    },
    specialActivity: {
      url: '/activity/newGift/special?id=',
      name: '特价活动',
      prop: 'id'
    },
    legouShop: {
      url: '/legou/mch/shop/shop?mch_id=',
      name: '乐购店铺'
    },
    legouLive: {
      url: 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=',
      name: '直播',
      prop: 'room_id'
    },
    svipServiceDetail: {
      url: '/svip/service/detail?id=',
      name: '荔星荟服务详情',
      prop: 'id'
    },
    svipActivityDetail: {
      url: '/svip/activity/detail?id=',
      name: '荔星荟活动详情',
      prop: 'id'
    },
    svipSpaceDetail: {
      url: '/svip/space/detail?id=',
      name: '荔星荟场地详情',
      prop: 'id'
    },
    legouPt: {
      url: '/legou/pages/pt/details/details?id=',
      name: '乐购拼团商品详情',
      prop: 'id'
    },
    svipActivityClass: {
      url: '/svip/activity/index?type=',
      name: '沙龙活动分类',
      prop: 'type'
    }
  }
};