"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.split");

var _api = require("./api.js");

var _mem = require("@/api/mem.js");

var _vendor = require("@/api/vendor.js");

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _index2 = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SelectTabel: _getCoupon.default,
    addLoad: _addLoad.default,
    Tinymce: _Tinymce.default,
    WTable: _index.default,
    UploadExcelComponent: _index2.default
  },
  data: function data() {
    var _self = this;

    return {
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/hdpi.xlsx"),

      /*
      merchantName 品牌名称，conNo 合同编号
      */
      columns: [{
        label: '品牌名称',
        prop: 'merchantName',
        width: 100
      }, {
        label: '合同编号',
        prop: 'conNo',
        width: 100
      }, {
        slot: 'operate'
      }],
      storeList: [],
      merchantList: [],
      imgSrc: this.$store.state.user.imgSrc,
      isShowSelect: false,
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.startTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      couponShow: false,
      rules: {
        'images': [{
          required: true,
          message: '请上传图片'
        }],
        'status': [{
          required: true,
          message: '请选择状态'
        }],
        couponList: [{
          required: true,
          message: '请选择优惠券'
        }],
        landscapeMainPic: [{
          required: true,
          message: '请上传图片'
        }],
        verticalMainPic: [{
          required: true,
          message: '请上传图片'
        }],
        title: [{
          required: true,
          message: '请输入标题'
        }],
        shortTitle: [{
          required: true,
          message: '请输入短标题'
        }],
        isEnable: [{
          required: true,
          message: '请选择是否激活'
        }],
        content: [{
          required: true,
          message: '请输入内容'
        }],
        storeId: [{
          required: true,
          message: '请选择门店'
        }],
        merchantId: [{
          required: true,
          message: '请选择商户'
        }],
        isIndex: [{
          required: true,
          message: '请选择是否首页展示'
        }],
        startTime: [{
          required: true,
          message: '请选择时间'
        }],
        endTime: [{
          required: true,
          message: '请选择时间'
        }]
      },
      ruleForm: {
        images: [],
        couponList: [],
        landscapeMainPic: '',
        title: null,
        shortTitle: null,
        status: null,
        id: null,
        isEnable: null,
        verticalMainPic: '',
        content: null,
        storeId: [],
        merchantId: [],
        isIndex: null,
        startTime: null,
        endTime: null
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.storeList = res.data;
    });
    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id) {
      (0, _api.getData)({
        id: this.ruleForm.id
      }).then(function (res) {
        res.data.couponList = res.data.couponList.split(',');
        res.data.storeId = res.data.storeId.split(',');
        res.data.merchantId = res.data.merchantId ? JSON.parse(res.data.merchantId) : [];
        Object.keys(_this.ruleForm).forEach(function (item) {
          if (res.data[item]) {
            _this.ruleForm[item] = res.data[item];
          }
        });
      });
    }
  },
  methods: {
    delGoods: function delGoods(index) {
      this.ruleForm.merchantId.splice(index, 1);
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      results.forEach(function (data) {
        console.log(data);

        if (data.合同编号 && data.品牌名称) {
          arr.push({
            merchantName: data.品牌名称,
            conNo: data.合同编号
          });
        }
      });
      this.ruleForm.merchantId = arr;
    },
    getVendor: function getVendor(e) {
      var _this2 = this;

      (0, _vendor.listMerchantPage)({
        storeId: e,
        pageNo: 1,
        pageSize: 1000
      }).then(function (res) {
        _this2.merchantList = res.data.items;
      });
    },
    closeSelect: function closeSelect() {
      this.isShowSelect = false;
    },
    setTabelData: function setTabelData(e) {
      var _this3 = this;

      if (e.length > 0) {
        this.ruleForm.couponList = [];
        e.forEach(function (item) {
          _this3.ruleForm.couponList.push(item.uuid);
        });
      }

      this.isShowSelect = false;
      return;
    },
    setCover: function setCover(e, type) {
      if (type === 'images') {
        this.ruleForm.images.push({
          pictureUrl: e
        });
      } else {
        this.ruleForm[type] = e;
      }
    },
    goDeImg: function goDeImg(index) {
      this.ruleForm.images.splice(index, 1);
    },
    setCoupon: function setCoupon(e) {
      this.ruleForm.giftIamge = e.mainImage;
      this.ruleForm.giftCode = e.uuid;
      this.ruleForm.giftName = e.name;
      this.couponShow = false;
    },
    getAward: function getAward(e, index) {
      var _this4 = this;

      this.isShowSelect = true;
      this.$nextTick(function () {
        _this4.$refs.selectPop.init();

        var couponList = _this4.ruleForm.couponList.map(function (item) {
          return {
            linkId: item
          };
        });

        _this4.$refs.selectPop.assignCoupon(couponList);
      });
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this5.ruleForm));
          data.merchantId = JSON.stringify(data.merchantId);
          data.couponList = _this5.ruleForm.couponList.join(',');
          data.storeId = _this5.ruleForm.storeId.join(',');

          if (_this5.ruleForm.id) {
            (0, _api.editData)(data).then(function (res) {
              _this5.$router.go(-1);

              _this5.$message({
                type: 'success',
                message: '编辑成功!'
              });
            });
            return;
          }

          (0, _api.addData)(data).then(function (res) {
            _this5.$router.go(-1);

            _this5.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;