"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '活动管理',
      list: [{
        tit: '活动列表',
        txtArr: '<p>对活动进行增删查改</p>',
        imgPath: require('@/assets/tab/activity.png'),
        path: '/svip/activity/list'
      }, {
        tit: '活动日历',
        txtArr: '<p>设置展示的活动日历表</p>',
        imgPath: require('@/assets/tab/log.png'),
        path: '/svip/activity/calendar'
      }, {
        tit: '数据统计',
        txtArr: '<p>点击量，浏览人数，浏览次数</p>',
        imgPath: require('@/assets/tab/log.png'),
        path: '/svip/activity/salonLog'
      }]
    };
  }
};
exports.default = _default;