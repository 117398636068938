"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _integral = require("@/api/integral.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      data: {},
      // 支付方式
      payArr: [{
        value: 1,
        label: '星汇通储值卡（不记名）'
      }, {
        value: 2,
        label: '星汇通会员卡'
      }, {
        value: 3,
        label: 'MIKA储蓄卡'
      }, {
        value: 4,
        label: '云闪付'
      }, {
        value: 5,
        label: '通联银行卡'
      }, {
        value: 6,
        label: '微信支付'
      }, {
        value: 7,
        label: '支付宝'
      }, {
        value: 8,
        label: '现金'
      }, {
        value: 9,
        label: '礼品券'
      }, {
        value: 10,
        label: '代金券'
      }, {
        value: 11,
        label: '优惠券'
      }, {
        value: 12,
        label: '挂账'
      }, {
        value: 13,
        label: '信用卡'
      }],
      institutionIds: [],
      pinleiArr: [],
      pinpaiData: [],
      multipleSelection: [],
      goodsList: []
    };
  },
  created: function created() {
    var _this = this;

    this.basicId = Number(this.$route.query.id) || '';

    if (this.basicId) {
      (0, _integral.getScoreBasic)(this.basicId).then(function (res) {
        _this.data = res.data;
        /* res.data.payModes = JSON.parse(res.data.payModes)
           console.log(res.data.payModes)
           let payMode = ''
           this.payArr.forEach((item) => {
             res.data.payModes.forEach((row) => {
               if (item.value == row) {
                 payMode += item.label + ','
               }
             })
            })
           this.data.payMode = payMode.substr(0, payMode.length - 1); */

        res.data.cditions.forEach(function (item) {
          if (item.attrType == 1) {
            _this.institutionIds.push(item.attrName);
          }

          if (item.attrType == 2) {
            _this.pinleiArr.push(item.attrName);
          }

          if (item.attrType == 3) {
            _this.pinpaiData.push(item.attrName);
          }

          if (item.attrType == 4) {
            _this.multipleSelection.push(item.attrName);
          }

          if (item.attrType == 5) {
            _this.goodsList.push(item.attrName);
          }
        });
        return;
        _this.time = [res.data.validStartTime, res.data.validEndTime];
        _this.obj.basicStatus = res.data.basicStatus;
        _this.obj.payMode = res.data.payMode;
        _this.obj.sellAmount = res.data.sellAmount;
        _this.obj.getScore = res.data.getScore;
        res.data.cditions.forEach(function (item) {
          _this.institutionIds.push(item.attrCode);
        });
      });
    }
  }
};
exports.default = _default;