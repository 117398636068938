"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _tableList = _interopRequireDefault(require("@/components/tableList"));

var _coupon = require("@/api/coupon.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    tableList: _tableList.default
  },
  data: function data() {
    return {
      name: '',
      time: [],
      address: '',
      bannerPath: '',
      content: '',
      institutionNames: [],
      beginTime: '',
      list: [],
      tabTh: [{
        tit: '提交时间',
        prop: 'time'
      }, {
        tit: '会员手机号',
        prop: 'time'
      }, {
        tit: '会员手昵称',
        prop: 'time'
      }]
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.activityId = Number(this.$route.query.activityId);
      (0, _coupon.getActivityById)({
        activityId: this.activityId
      }).then(function (res) {
        console.log(res);
        _this.address = res.data.address;
        _this.time = [res.data.validityStartTime, res.data.validityEndTime];
        _this.institutionNames = res.data.institutionNames;
        _this.name = res.data.name;
        _this.bannerPath = _this.$store.state.user.imgSrc + res.data.bannerPath;

        if (res.data.onlineType == 1) {
          _this.beginTime = res.data.beginTime + '至' + res.data.endTime;
        } else {
          _this.beginTime = '立即上线（需手动下线）';
        }

        _this.content = res.data.contentHtml;
      });
    },
    exportExcel: function exportExcel() {
      var _this2 = this;

      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['提交时间', '会员手机号', '会员手昵称'];
        var filterVal = ['time', 'time', 'time'];
        var list = _this2.list;

        var data = _this2.formatJson(filterVal, list);

        excel.export_json_to_excel({
          header: tHeader,
          data: data
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;