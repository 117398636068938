"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      disabled: true,
      positions: [{
        label: '总裁',
        value: 10
      }, {
        label: '总经理级',
        value: 1
      }, {
        label: '总监级',
        value: 2
      }, {
        label: '经理级',
        value: 3
      }, {
        label: '课长级',
        value: 4
      }, {
        label: '主管级',
        value: 5
      }, {
        label: '员工级',
        value: 6
      }, {
        label: '百货(12001、12002、12003)',
        value: 7
      }, {
        label: '购物中心(12010、12009、12004、12007)',
        value: 8
      }, {
        label: '银行',
        value: 9
      }],
      rules: {},
      ruleForm: {
        kpis: [{
          position: 10,
          number: null
        }, {
          position: 1,
          number: null
        }, {
          position: 2,
          number: null
        }, {
          position: 3,
          number: null
        }, {
          position: 4,
          number: null
        }, {
          position: 5,
          number: null
        }, {
          position: 6,
          number: null
        }, {
          position: 7,
          number: null
        }, {
          position: 8,
          number: null
        }, {
          position: 9,
          number: null
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.listUserKpi)().then(function (res) {
      _this.disabled = false;

      if (res.data.length > 0) {
        res.data.forEach(function (item) {
          _this.ruleForm.kpis.forEach(function (row) {
            if (row.position === item.position) {
              row.number = item.number;
            }
          });
        }); // this.ruleForm.kpis = res.data
      }
    });
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          (0, _api.addUserKpi)(_this2.ruleForm);
        }
      });
    }
  }
};
exports.default = _default;