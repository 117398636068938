"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.deleteData = deleteData;
exports.editData = editData;
exports.getData = getData;
exports.getList = getList;
exports.goExam = goExam;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: '/api/commonMemberRight/list',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addData(data) {
  return (0, _request.default)({
    url: '/api/commonMemberRight/add',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editData(data) {
  return (0, _request.default)({
    url: '/api/commonMemberRight/edit',
    method: 'post',
    data: data
  });
}

function getData(data) {
  return (0, _request.default)({
    url: '/api/commonMemberRight/detail/' + data,
    method: 'get',
    loading: 'loading'
  });
}

function goExam(data) {
  return (0, _request.default)({
    url: '/api/commonMemberRight/editStatus',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function deleteData(data) {
  return (0, _request.default)({
    url: '/api/commonMemberRight/delete/' + data,
    method: 'get',
    loading: 'loading'
  });
}