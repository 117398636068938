var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 活动券B列表 "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticStyle: { "max-width": "100%" },
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: {
                  click: function($event) {
                    return _vm.wPage("/couponActivity/couponBAdd?type=1")
                  }
                }
              },
              [_vm._v("新增活动券B")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  placeholder: "活动券B名称"
                },
                model: {
                  value: _vm.requestData.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "search_select",
                  attrs: {
                    size: "mini",
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择类型"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.requestData.type,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "type", $$v)
                    },
                    expression: "requestData.type"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "满减活动", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "多件多折", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  clearable: "",
                  size: "mini",
                  type: "month",
                  placeholder: "活动周期",
                  "value-format": "yyyy-MM"
                },
                on: {
                  change: function($event) {
                    return _vm.getList("search")
                  }
                },
                model: {
                  value: _vm.requestData.activityMonth,
                  callback: function($$v) {
                    _vm.$set(_vm.requestData, "activityMonth", $$v)
                  },
                  expression: "requestData.activityMonth"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 7 } },
            [
              _c("el-date-picker", {
                staticClass: "search_input",
                attrs: {
                  size: "mini",
                  type: "datetimerange",
                  "range-separator": "——",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  "default-time": ["00:00:00", "23:59:59"]
                },
                on: {
                  change: function($event) {
                    return _vm.getList("search")
                  }
                },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "search_select",
                  attrs: {
                    size: "mini",
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择状态"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.requestData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "status", $$v)
                    },
                    expression: "requestData.status"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "待审核", value: 0 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "审核通过", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "审核不通过", value: 2 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已撤回", value: 3 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已终止", value: 4 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.getList("search")
                }
              }
            },
            [_vm._v("查询")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "activityTime",
              label: "活动周期",
              align: "center",
              width: "250"
            },
            slot: "activityTime",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.startTime) +
                        "-" +
                        _vm._s(scope.row.endTime) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "limitType",
              label: "限制条件",
              align: "center",
              "mni-width": "100"
            },
            slot: "limitType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.limitTypeDesc) +
                        _vm._s(scope.row.limitNumberDesc) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              fixed: "right",
              width: "240"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/couponActivity/couponBDea?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("详情\n        ")]
                    ),
                    _vm._v(" "),
                    scope.row.status === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "success",
                              size: "mini"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goExamBox(scope.row, 1)
                              }
                            },
                            slot: "reference"
                          },
                          [_vm._v("审核 ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              size: "mini"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goAudit(scope.row, 3)
                              }
                            },
                            slot: "reference"
                          },
                          [_vm._v("撤回 ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "primary",
                              size: "mini"
                            },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/couponActivity/couponBGoods?id=" +
                                    scope.row.id
                                )
                              }
                            },
                            slot: "reference"
                          },
                          [_vm._v("商品管理")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              size: "mini"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goAudit(scope.row, 4)
                              }
                            },
                            slot: "reference"
                          },
                          [_vm._v("终止")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 2 || scope.row.status === 3
                      ? _c(
                          "el-popconfirm",
                          { attrs: { title: "删除接口还没有做,不要急？" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isPop
        ? _c("div", { staticClass: "fixed_center" }, [
            _c(
              "div",
              { staticClass: "exam" },
              [
                _vm.status === 4
                  ? _c("div", { staticClass: "exam_title" }, [
                      _vm._v(" 确认终止活动 ")
                    ])
                  : _vm.status === 3
                  ? _c("div", { staticClass: "exam_title" }, [
                      _vm._v(" 活动撤回活动 ")
                    ])
                  : _c("div", { staticClass: "exam_title" }, [
                      _vm._v(" 活动审核 ")
                    ]),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      size: "small",
                      "label-width": "150px"
                    }
                  },
                  [
                    _vm.status < 3
                      ? _c(
                          "el-form-item",
                          {
                            ref: "statusr",
                            attrs: { label: "审核方案：", prop: "status" }
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.ruleForm.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "status", $$v)
                                  },
                                  expression: "ruleForm.status"
                                }
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("通过")
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("不通过")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        ref: "description",
                        attrs: { label: "审核说明：", prop: "description" }
                      },
                      [
                        _c("el-input", {
                          staticClass: "form_input",
                          attrs: {
                            type: "te",
                            "show-word-limit": "",
                            placeholder: "活动名称"
                          },
                          model: {
                            value: _vm.ruleForm.description,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "description",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.description"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "150px" },
                            attrs: { type: "info" },
                            on: {
                              click: function($event) {
                                _vm.isPop = false
                              }
                            }
                          },
                          [_vm._v("取消")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "150px" },
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("ruleForm")
                              }
                            }
                          },
                          [_vm._v("保存")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }