var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("编辑栏目项")]),
    _vm._v(" "),
    _c("div", { staticClass: "ecitList_box2 box" }, [
      _c("div", { staticClass: "box2-left ul" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("p", { staticClass: "ecit_tit" }, [_vm._v("栏目项名称：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "table_input3",
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "ecit_tit" }, [_vm._v("所属栏目组：")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择所属栏目组" },
                  model: {
                    value: _vm.groupId,
                    callback: function($$v) {
                      _vm.groupId = $$v
                    },
                    expression: "groupId"
                  }
                },
                _vm._l(_vm.column, function(item) {
                  return _c("el-option", {
                    key: item.groupId,
                    attrs: { label: item.groupName, value: item.groupId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "ecit_tit" }, [_vm._v("排序号：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "table_input3",
                model: {
                  value: _vm.sort,
                  callback: function($$v) {
                    _vm.sort = $$v
                  },
                  expression: "sort"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "ecit_tit" }, [_vm._v("所属页面：")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择所属页面" },
                  model: {
                    value: _vm.parentId,
                    callback: function($$v) {
                      _vm.parentId = $$v
                    },
                    expression: "parentId"
                  }
                },
                _vm._l(_vm.parentIds, function(item) {
                  return _c("el-option", {
                    key: item.columnId,
                    attrs: { label: item.name, value: item.columnId }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "ecit_txt" }, [_vm._v("栏目或分类ID")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "ecit_tit" }, [_vm._v("显示模板：")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择模板" },
                  on: {
                    change: function($event) {
                      return _vm.onChange(_vm.templateCode)
                    }
                  },
                  model: {
                    value: _vm.templateCode,
                    callback: function($$v) {
                      _vm.templateCode = $$v
                    },
                    expression: "templateCode"
                  }
                },
                _vm._l(_vm.templateCodes, function(item) {
                  return _c("el-option", {
                    key: item.code,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "ecit_tit" }, [_vm._v("右边按钮文字：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "table_input3",
                model: {
                  value: _vm.btnRightName,
                  callback: function($$v) {
                    _vm.btnRightName = $$v
                  },
                  expression: "btnRightName"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "ecit_txt" }, [
                _vm._v("如：更多 或者 某功能的快捷入口名称")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "ecit_tit" }, [_vm._v("按钮链接参数：")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择模板" },
                  model: {
                    value: _vm.btnLinkType,
                    callback: function($$v) {
                      _vm.btnLinkType = $$v
                    },
                    expression: "btnLinkType"
                  }
                },
                _vm._l(_vm.btnLinkTypes, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.lable, value: item.value }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "table_input3",
                attrs: { placeholder: "https://wangzhi.com/JH_card" },
                model: {
                  value: _vm.btnLink,
                  callback: function($$v) {
                    _vm.btnLink = $$v
                  },
                  expression: "btnLink"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "ecit_tit" }, [_vm._v("栏目项状态：")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1" },
                    model: {
                      value: _vm.status,
                      callback: function($$v) {
                        _vm.status = $$v
                      },
                      expression: "status"
                    }
                  },
                  [_vm._v("启用")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "-1" },
                    model: {
                      value: _vm.status,
                      callback: function($$v) {
                        _vm.status = $$v
                      },
                      expression: "status"
                    }
                  },
                  [_vm._v("停用")]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box2-right" }, [
        _c("p", { staticClass: "ecit_tit" }, [_vm._v("模板预览：")]),
        _vm._v(" "),
        _c("div", [_c("img", { attrs: { src: _vm.cover } })])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "ecitList_box2 box",
        staticStyle: { padding: "0", "margin-top": "-15px" }
      },
      [
        _c("div", { staticClass: "box2-left" }, [
          _c("ul", [
            _c(
              "li",
              [
                _c("p", { staticClass: "ecit_tit" }, [_vm._v("关联数据项：")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "textarea",
                  attrs: {
                    type: "textarea",
                    autosize: { minRows: 20, maxRows: 30 },
                    placeholder: "请输入内容"
                  },
                  model: {
                    value: _vm.attrIds,
                    callback: function($$v) {
                      _vm.attrIds = $$v
                    },
                    expression: "attrIds"
                  }
                })
              ],
              1
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "nextBtn" },
      [
        _c("p", { staticClass: "ecit_tit" }),
        _vm._v(" "),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.next } },
          [_vm._v("保存")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }