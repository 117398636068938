"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    status: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    goPhone: function goPhone() {
      this.$router.push('/report/editPhone?uid=' + this.user.uid + '&oldMobile=' + this.user.mobile);
    }
  }
};
exports.default = _default2;