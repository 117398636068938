var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "group-box" },
    [
      _c(
        "div",
        { staticClass: "group-buy-from" },
        [
          _c(
            "div",
            { staticClass: "group-buy-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.addManager()
                    }
                  }
                },
                [_vm._v("添加经理")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { inline: "" } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "input2",
                    attrs: { size: "mini", placeholder: "联系方式" },
                    model: {
                      value: _vm.mobile,
                      callback: function($$v) {
                        _vm.mobile = $$v
                      },
                      expression: "mobile"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "input2",
                    attrs: { size: "mini", placeholder: "姓名" },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        filterable: "",
                        placeholder: "状态"
                      },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "启用", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "停用", value: 2 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini"
                      },
                      on: {
                        click: function($event) {
                          return _vm.init("search")
                        }
                      }
                    },
                    [_vm._v("查询")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        size: "mini",
                        icon: "el-icon-refresh"
                      },
                      on: {
                        click: function($event) {
                          return _vm.init("remove")
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              id: "table",
              "row-key": "id",
              border: ""
            }
          },
          [
            _c("el-table-column", {
              key: "id",
              attrs: { align: "center", prop: "id", label: "序号" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "headSculpture",
              attrs: { align: "center", prop: "headSculpture", label: "头像" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.headSculpture.indexOf("http") >= 0
                        ? _c("el-image", {
                            staticStyle: { width: "50px" },
                            attrs: {
                              src: scope.row.headSculpture,
                              "preview-src-list": [scope.row.headSculpture]
                            }
                          })
                        : _c("el-image", {
                            staticStyle: { width: "50px" },
                            attrs: {
                              src: _vm.imgSrc + scope.row.headSculpture,
                              "preview-src-list": [
                                _vm.imgSrc + scope.row.headSculpture
                              ]
                            }
                          })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "name",
              attrs: { align: "center", prop: "name", label: "姓名" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "position",
              attrs: { align: "center", prop: "position", label: "职位" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "mobile",
              attrs: { align: "center", prop: "mobile", label: "联系方式" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "qrCode",
              attrs: { align: "center", prop: "qrCode", label: "企微二维码" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.qrCode.indexOf("http") >= 0
                        ? _c("el-image", {
                            staticStyle: { width: "50px" },
                            attrs: {
                              src: scope.row.qrCode,
                              "preview-src-list": [scope.row.qrCode]
                            }
                          })
                        : _c("el-image", {
                            staticStyle: { width: "50px" },
                            attrs: {
                              src: _vm.imgSrc + scope.row.qrCode,
                              "preview-src-list": [
                                _vm.imgSrc + scope.row.qrCode
                              ]
                            }
                          })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "remark",
              attrs: { align: "center", prop: "remark", label: "简介" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "sort",
              attrs: { align: "center", prop: "sort", label: "排序" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "status",
              attrs: { align: "center", prop: "status", label: "状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        model: {
                          value: scope.row.status == 1 ? true : false,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status==1?true:false", $$v)
                          },
                          expression: "scope.row.status==1?true:false"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { align: "center", label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "opration" },
                        _vm._l(_vm.opration, function(item, index) {
                          return _c(
                            "p",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.oprationView(
                                    index,
                                    scope.row,
                                    scope.$index
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        0
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { position: "relative" } },
          [
            _c(
              "el-button",
              {
                staticStyle: {
                  "margin-top": "30px",
                  position: "absolute",
                  left: "0",
                  top: "-30px",
                  "z-index": "99"
                },
                attrs: { icon: "el-icon-download", size: "mini" },
                on: {
                  click: function($event) {
                    return _vm.exportExcel()
                  }
                }
              },
              [_vm._v("\n        导出Excel\n      ")]
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                total: _vm.total,
                page: _vm.obj.pageNo,
                limit: _vm.obj.pageSize
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.obj, "pageNo", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.obj, "pageSize", $event)
                },
                pagination: function($event) {
                  return _vm.init()
                }
              }
            })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }