"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _api = require("./api.js");

var _index = _interopRequireDefault(require("@/components/integral/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    integral: _index.default
  },
  data: function data() {
    var _ruleForm;

    return {
      shortNameShow: false,
      shortNameSearch: null,
      rules: {
        companyId: [{
          required: true,
          message: '请选择总部'
        }],
        cdtionName: [{
          required: true,
          message: '请输入策略名称'
        }],
        cditionStatus: [{
          required: true,
          message: '请选择策略状态'
        }],
        companyCode: [{
          required: true,
          message: '请选择公司'
        }],
        discountRate: [{
          required: true,
          message: '请输入折扣率'
        }],
        cliqueRate: [{
          required: true,
          message: '请输入集团承担比率'
        }],
        cditionRemark: [{
          required: true,
          message: '请输入策略备注'
        }],
        time: [{
          required: true,
          message: '请输入执行时间'
        }]
      },

      /*
        {"cdtionName":"策略名称",
        "cditionStatus":"策略状态(1：启用 -1：停用)",
        "companyCode":"公司编码",
        "companyName":"公司名称",
        "discountRate":"折扣率",
        "cliqueRate":"集团承担比率",
        "cditionRemark":"策略备注",
        "cditions":[
          {"attrName":"属性名称",
          "attrCode":"属性编码",
          "attrType":"属性类型(1：门店 2：商户 3：品类 4：品牌 5：商品)"}]}
         */
      ruleForm: (_ruleForm = {
        time: null,
        cdtionName: null,
        cditionStatus: null,
        discountRate: null,
        cliqueRate: null,
        cditionRemark: null,
        validStartTime: null,
        validEndTime: null,
        cditions: null,
        companyId: null,
        sellAmount: '',
        getScore: null,
        payModes: [],
        cCcodes: [],
        pinleiArr: [],
        selectData: [],
        multipleSelection: [],
        storeArr: [],
        shortName: []
      }, (0, _defineProperty2.default)(_ruleForm, "cditionRemark", ''), (0, _defineProperty2.default)(_ruleForm, "companyName", ''), _ruleForm)
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _api.getVippriceSpecial)(this.ruleForm.id).then(function (res) {
        _this.$refs.integralRef.setCompanId(res.data.companyCode);

        _this.ruleForm.companyId = res.data.companyCode;
        _this.ruleForm.cdtionName = res.data.cdtionName;
        _this.ruleForm.cditionRemark = res.data.cditionRemark;
        _this.ruleForm.cditionStatus = res.data.cditionStatus;
        _this.ruleForm.cliqueRate = res.data.cliqueRate;
        _this.ruleForm.discountRate = res.data.discountRate;

        _this.$refs.integralRef.setCditions(res.data.cditions);

        _this.ruleForm.time = [res.data.validStartTime, res.data.validEndTime];
      });
    }
  },
  mounted: function mounted() {
    if (!this.ruleForm.id) {
      this.$refs.integralRef.setCompanId();
    }
  },
  methods: {
    setStoreId: function setStoreId(arr) {
      this.ruleForm.storeArr = arr;
    },
    submit: function submit(type) {
      this.$refs['ruleForm'].validateField(type);
    },
    setcompanyId: function setcompanyId(e, value) {
      this.ruleForm.companyId = e;
    },
    init: function init() {
      var _this2 = this;

      this.ruleForm.id = Number(this.$route.query.id);

      if (this.ruleForm.id) {
        getTaxRate({
          id: this.ruleForm.id
        }).then(function (res) {
          _this2.ruleForm.spbm = res.data.spbm;
          _this2.ruleForm.sl = res.data.sl;
        });
      }
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      var cditions = this.$refs.integralRef.getCditions();
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = {
            cditions: cditions,
            companyCode: _this3.ruleForm.companyId,
            cdtionName: _this3.ruleForm.cdtionName,
            cditionStatus: _this3.ruleForm.cditionStatus,
            discountRate: _this3.ruleForm.discountRate,
            cliqueRate: _this3.ruleForm.cliqueRate,
            cditionRemark: _this3.ruleForm.cditionRemark,
            companyName: _this3.ruleForm.companyName,
            validStartTime: _this3.ruleForm.time[0],
            validEndTime: _this3.ruleForm.time[1]
          };

          if (_this3.ruleForm.id) {
            data.specialId = _this3.ruleForm.id;
            (0, _api.editVippriceSpecial)(data).then(function (res) {
              _this3.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this3.$router.go(-1);
            });
          } else {
            (0, _api.addVippriceSpecial)(data).then(function (res) {
              _this3.$router.go(-1);

              _this3.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;