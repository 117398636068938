"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _options = require("../options");

require("@/utils/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      statusOptions: _options.statusOptions,
      rewardTypeOptions: _options.rewardTypeOptions,
      api: '/api/gameReceivePrize/listGameReceivePrizePage',
      columns: [{
        prop: 'gameCode',
        label: '游戏ID'
      }, {
        prop: 'gameName',
        label: '游戏名称'
      }, {
        prop: 'userName',
        label: '用户名称'
      }, {
        prop: 'userPhone',
        label: '用户手机号'
      }, {
        slot: 'prizeType',
        prop: 'prizeType',
        label: '奖励类型'
      }, {
        prop: 'prizeName',
        label: '奖品名称'
      }, {
        prop: 'prizeCode',
        label: '奖励编码'
      }, {
        prop: 'receiveTime',
        label: '领取时间'
      }],
      queryParams: [{
        type: 'input',
        placeholder: '游戏ID',
        value: 'gameCode'
      }, {
        type: 'input',
        placeholder: '游戏名称',
        value: 'gameName'
      }, {
        type: 'input',
        placeholder: '用户手机号',
        value: 'userPhone'
      }, {
        type: 'radio',
        placeholder: '奖励类型',
        value: 'prizeType',
        select: _options.rewardTypeOptions
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'beginTime'
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'endTime'
      }]
    };
  }
};
exports.default = _default;