var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          attrs: {
            "form-data": _vm.formData,
            "label-width": "180px",
            "rule-form": _vm.ruleForm,
            rules: _vm.rules
          },
          on: { submitForm: _vm.submitForm }
        },
        [
          _c(
            "div",
            { attrs: { slot: "storeCode" }, slot: "storeCode" },
            [
              _c(
                "el-form-item",
                {
                  ref: "storeCode",
                  attrs: { label: "所属门店：", prop: "storeCode" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: {
                        size: "small",
                        placeholder: "所属门店",
                        filterable: "",
                        autocomplete: "off"
                      },
                      model: {
                        value: _vm.ruleForm.storeCode,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "storeCode", $$v)
                        },
                        expression: "ruleForm.storeCode"
                      }
                    },
                    _vm._l(_vm.storeList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.storeId,
                          attrs: {
                            label:
                              item.shortName + "（" + item.storeCode + "）",
                            value: item.storeCode
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                item.shortName + "（" + item.storeCode + "）"
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              attrs: { slot: "activityCategoryId" },
              slot: "activityCategoryId"
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "activityCategoryId",
                  attrs: { label: "所属分类：", prop: "activityCategoryId" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: {
                        size: "small",
                        placeholder: "所属分类",
                        filterable: "",
                        autocomplete: "off"
                      },
                      model: {
                        value: _vm.ruleForm.activityCategoryId,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "activityCategoryId", $$v)
                        },
                        expression: "ruleForm.activityCategoryId"
                      }
                    },
                    _vm._l(_vm.classList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.categoryName, value: item.id }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.categoryName) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "pageUrl" }, slot: "pageUrl" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "关联内容：", prop: "contentType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "关联内容类型"
                      },
                      on: { change: _vm.typeChange },
                      model: {
                        value: _vm.ruleForm.contentType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "contentType", $$v)
                        },
                        expression: "ruleForm.contentType"
                      }
                    },
                    _vm._l(_vm.urlList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.contentType == 4
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "目标小程序：", prop: "appid" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              attrs: { placeholder: "小程序" },
                              model: {
                                value: _vm.ruleForm.appid,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "appid", $$v)
                                },
                                expression: "ruleForm.appid"
                              }
                            },
                            _vm._l(_vm.appidArr, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "小程序路径：",
                            prop: "contentLink",
                            rules: [
                              { required: true, message: "请输入小程序路径" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_input",
                            attrs: { type: "text" },
                            model: {
                              value: _vm.ruleForm.contentLink,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "contentLink", $$v)
                              },
                              expression: "ruleForm.contentLink"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.contentType == 5
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "WEB页面：",
                        prop: "contentLink",
                        rules: [
                          { required: true, message: "请输入WEB页面地址" }
                        ]
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { type: "text" },
                        model: {
                          value: _vm.ruleForm.contentLink,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "contentLink", $$v)
                          },
                          expression: "ruleForm.contentLink"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.urlTypeList.indexOf(_vm.ruleForm.contentType) >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "链接编码：", prop: "contentLink" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { type: "text" },
                        model: {
                          value: _vm.ruleForm.contentLink,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "contentLink", $$v)
                          },
                          expression: "ruleForm.contentLink"
                        }
                      }),
                      _vm._v(" "),
                      _vm.noSelect.indexOf(_vm.ruleForm.contentType) < 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.getLinkId()
                                }
                              }
                            },
                            [_vm._v("\n          选择")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("couponPop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: _vm.popType, "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }