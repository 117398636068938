"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default,
    AppLink: _Link.default
  },
  props: {
    type: {
      type: Boolean,
      default: false
    },
    btnShow: {
      type: String,
      default: ''
    },
    operation: {
      type: Number,
      default: 0
    },
    btnType: {
      type: String,
      default: 'primary'
    },
    deaPath: {
      type: String,
      default: ''
    },
    tableData: {
      type: Array
    },
    tabTh: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    pageNo: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    btn: {
      type: String,
      default: '编辑'
    }
  },
  data: function data() {
    return {
      page: '',
      limit: ''
    };
  },
  created: function created() {
    this.page = this.pageNo;
    this.limit = this.pageSize;
  },
  methods: {
    getList: function getList(e) {
      this.$emit('getList', e);
    },
    setBtn: function setBtn(index) {
      this.$emit('setBtn', index);
    }
  }
};
exports.default = _default2;