"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/**
 * 团购经理
 */
var groupBuyManagerRouter = {
  path: '/groupBuyManager',
  component: _layout.default,
  redirect: '/groupBuyManager/index',
  name: 'GroupBuy',
  meta: {
    title: '团购经理 '
  },
  children: [{
    path: '/groupBuyManager/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/groupBuyManager/index'));
      });
    },
    name: 'GroupBuylist',
    meta: {
      title: '数据',
      keepAlive: true,
      pageList: 'items',
      pageTotal: 'totalNum',
      addUrl: '/groupBuyManager/add',
      addTxt: '新增团购经理'
    }
  }, {
    path: '/groupBuyManager/setting',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/groupBuyManager/add/index'));
      });
    },
    name: 'GroupBuySet',
    meta: {
      title: '添加或编辑团购经理',
      keepAlive: true,
      pageList: 'items',
      pageTotal: 'totalNum',
      addUrl: '/groupBuyManager/add',
      addTxt: '添加团购经理',
      activeMenu: '/groupBuyManager/index'
    }
  }]
};
var _default = groupBuyManagerRouter;
exports.default = _default;