"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      requestParams: {},
      total: 0,
      list: []
    };
  },
  methods: {
    onCreate: function onCreate() {
      this.$router.push("/shopCard/applyTag/save");
    },
    onReset: function onReset() {
      this.$refs['requestParamsForm'].resetFields();
      this.onQuery();
    },
    onQuery: function onQuery() {
      this.requestParams.pageNo = 1;
      this.getList();
    },
    onPageChange: function onPageChange() {
      this.getList();
    },
    getList: function getList() {// linksPage(this.requestParams).then(res => {
      //     this.list = res.data.records
      //     this.total = res.data.total
      // })
    }
  }
};
exports.default = _default;