"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '规则名称',
        prop: 'ruleName',
        width: 200,
        align: 'left'
      }, {
        label: '门槛金额',
        prop: 'doorConsume',
        width: 80
      }, {
        label: ' 抽奖次数',
        prop: 'drawTimes',
        width: 80
      }, {
        label: '状态-1 禁用 1 启用',
        prop: 'status',
        slot: 'status',
        width: 80
      }, {
        label: '规则类型 1-单日消费 2-累计消费 3-单日累计消费',
        prop: 'ruleType',
        slot: 'ruleType',
        width: 120
      }, {
        label: '门店编码',
        prop: 'storeCode',
        width: 50
      }, {
        label: '备注',
        prop: 'remark',
        align: 'left',
        width: 200
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 140
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'radio',
        placeholder: '状态',
        value: 'status',
        select: [{
          label: '启用',
          value: 1
        }, {
          label: '禁用',
          value: -1
        }]
      }, {
        type: 'input',
        span: 4,
        placeholder: '规则名称',
        value: 'ruleName'
      }, {
        type: 'input',
        span: 4,
        placeholder: '门店编码',
        value: 'storeCode'
      }]
    };
  },
  created: function created() {},
  methods: {}
};
exports.default = _default;