"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

/** When your routing table is too long, you can split it into small modules **/
var legouBasicRouter = {
  path: 'basic',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
    });
  },
  redirect: '/legou/basic/tab',
  name: 'legouGoodsbasic',
  meta: {
    title: '运费管理'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./free/tab.vue"));
      });
    },
    name: 'legouGoodsbasicFreeTab',
    meta: {
      title: '运费管理'
    }
  }, {
    path: 'free',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./free/list.vue"));
      });
    },
    name: 'legouGoodsbasicFree',
    meta: {
      title: '包邮规则',
      keepAlive: true,
      addUrl: "/legou/basic/freeAdd",
      activeMenu: '/legou/basic/tab',
      addTxt: "新增规则"
    }
  }, {
    path: 'freeAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./free/add.vue"));
      });
    },
    name: 'legouGoodsbasicFreeAdd',
    meta: {
      title: '新增包邮规则',
      activeMenu: '/legou/basic/tab'
    }
  }, {
    path: 'delivery',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./free/delivery/list.vue"));
      });
    },
    name: 'legouGoodsbasicFreedelivery',
    meta: {
      title: '运费规则',
      keepAlive: true,
      addUrl: "/legou/basic/deliveryAdd",
      activeMenu: '/legou/basic/tab',
      addTxt: "新增规则"
    }
  }, {
    path: 'deliveryAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./free/delivery/add.vue"));
      });
    },
    name: 'legouGoodsbasicFreedeliveryAdd',
    meta: {
      title: '新增运费规则',
      activeMenu: '/legou/basic/tab'
    }
  }, {
    path: 'config',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./config/index.vue"));
      });
    },
    name: 'legouGoodsbasicconfig',
    meta: {
      title: '基础配置'
    }
  }]
};
var _default = legouBasicRouter;
exports.default = _default;