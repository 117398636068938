"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _content = require("@/api/content.js");

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      articleId: '',
      content: ''
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.articleId = Number(this.$route.query.articleId);
      (0, _content.getArticleById)({
        articleId: this.articleId
      }).then(function (res) {
        console.log(res);
        _this.content = res.data.content;
      });
    },
    goEdit: function goEdit() {
      this.$router.push('/content/manageContent/addArticle?articleId=' + this.articleId);
    }
  }
};
exports.default = _default;