"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var _router = _interopRequireDefault(require("../../views/template/iot/banner/router.js"));

/** When your routing table is too long, you can split it into small modules **/
var templateRouter = {
  path: '/template',
  component: _layout.default,
  redirect: '/template/list',
  name: 'Template',
  meta: {
    title: '页面管理',
    icon: 'nested'
  },
  children: [_router.default, {
    path: 'pageList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/page/list.vue'));
      });
    },
    name: 'PageList',
    meta: {
      title: '页面管理',
      keepAlive: true
    }
  }, {
    path: 'pageAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/page/add.vue'));
      });
    },
    name: 'PageAdd',
    meta: {
      title: '页面新增/编辑',
      activeMenu: '/template/pageList'
    }
  }, {
    path: 'tmplatePageList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/tmplatePage/list.vue'));
      });
    },
    name: 'tmplatePageList',
    meta: {
      title: '页面模板管理',
      keepAlive: true
    }
  }, {
    path: 'tmplatePageAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/tmplatePage/add.vue'));
      });
    },
    name: 'tmplatePageAdd',
    meta: {
      title: '新增页面模板',
      activeMenu: '/template/tmplatePageList'
    }
  }, {
    path: 'styleList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/style/list.vue'));
      });
    },
    name: 'styleList',
    meta: {
      title: '样式类型'
    }
  }, {
    path: 'styleAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/style/add.vue'));
      });
    },
    name: 'styleAdd',
    meta: {
      title: '新增/编辑样式类型',
      activeMenu: '/template/modelList'
    }
  }, {
    path: 'modelList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/style/model.vue'));
      });
    },
    name: 'modelList',
    meta: {
      title: '样式列表',
      keepAlive: true
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/add.vue'));
      });
    },
    name: 'TemplateAdd',
    meta: {
      title: '新增/编辑页面',
      activeMenu: '/template/tmplatePageList'
    }
  }, {
    path: 'mikaUrl',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/mikaUrl.vue'));
      });
    },
    name: 'mikaUrl',
    meta: {
      title: '米卡小程序路径'
    }
  }, {
    path: 'newAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/newAdd.vue'));
      });
    },
    name: 'TemplatenewAdd',
    meta: {
      title: '新增/编辑页面111'
    }
  }, {
    path: 'popList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/pop/list.vue'));
      });
    },
    name: 'popList',
    meta: {
      title: '首页弹窗管理',
      keepAlive: true
    }
  }, {
    path: 'popIndex',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/template/pop/index.vue'));
      });
    },
    name: 'popIndex',
    meta: {
      title: '新增/编辑首页弹窗',
      activeMenu: '/template/popList'
    }
  }]
};
var _default = templateRouter;
exports.default = _default;