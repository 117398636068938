"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _radio = _interopRequireDefault(require("@/components/couponPop/radio.vue"));

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

var _index = _interopRequireDefault(require("@/components/column/index.vue"));

var _sortablejs = _interopRequireDefault(require("sortablejs"));

var _coupon = require("@/api/coupon");

var _template = require("@/api/template.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    couponPop: _radio.default,
    coupon: _getCoupon.default,
    column: _index.default
  },
  data: function data() {
    return {
      styleList: [],
      imgSrc: this.$store.state.user.imgSrc,
      urlList: _selectUrl.default.urlList,
      appIdArr: _selectUrl.default.appIdArr,
      dataItem: [],
      templateList: [],
      itemCount: -1,
      temRules: {},
      ruleForm: {}
    };
  },
  created: function created() {
    var _this = this;

    (0, _template.listStyleType)({
      styleStatus: 1
    }).then(function (res) {
      _this.dataItem = res.data;
    });
    (0, _template.listTmplateClass)().then(function (res) {
      _this.temClass = res.data;
    });

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _template.getTmplate)(this.ruleForm.id).then(function (res) {
        // this.modifyTime = res.data.modifyTime
        // this.createTime = res.data.createTime
        _this.fzObj(res.data, _this.ruleForm);

        _this.init();
      });
    }
  },
  methods: {
    mouseup: function mouseup(e) {
      console.log(e);
    },
    mousemove: function mousemove(e) {// console.log(e)
    },
    move: function move(e) {
      console.log(e);
    },

    /*
                获取数据项
                */
    init: function init() {
      var _this2 = this;

      (0, _template.listModule)({
        tmplateId: this.ruleForm.id
      }).then(function (res) {
        _this2.templateList = res.data;

        _this2.templateList.forEach(function (item) {
          item.moduleAttr = JSON.parse(item.moduleAttr); //   item.styleCode = item.styleCode.split('1')[0]

          if (item.styleCode === 'button1001') {
            // item.rowNum = 3
            item.styleCode = 'button';
          }
        }); // console.log(this.templateList)
        // const arr = Object.assign([], this.templateList)
        // this.newList = arr

      });
    },
    submitForm: function submitForm() {
      var _this3 = this;

      var itemTem = this.templateList[this.itemCount];
      var data = {
        tmplateId: this.ruleForm.id,
        moduleName: '测试',
        moduleStatus: 1,
        styleCode: itemTem.styleCode,
        moduleAttr: JSON.stringify(itemTem.moduleAttr),
        temMargin: 0
      };

      if (itemTem.id) {
        data.id = itemTem.id;
        (0, _template.editModule)(data).then(function (res) {
          _this3.init();
        });
      } else {
        (0, _template.addModule)(data).then(function (res) {
          _this3.init();

          _this3.$message({
            type: 'success',
            message: '新增成功!'
          });
        });
      }
    },

    /*
              添加模板数据项
              */
    adddataItem: function adddataItem() {
      var itemTem = this.templateList[this.itemCount].moduleAttr.dataItem;
      itemTem.push({
        dataName: '图标' + itemTem.length + 1,
        linkCode: '/mall/index',
        dataPath: 'mika/pro/2021-04-28/3891d4f1fa3d46c4b027a83230ce06d0.jpg'
      });
    },

    /*
             图片上传  列表
             */
    setCover: function setCover(e, index) {
      var itemTem = this.templateList[this.itemCount].moduleAttr.dataItem;
      itemTem[index].dataPath = e;
    },

    /*
              设置某个模板
              */
    setCount: function setCount(e, item) {
      console.log(1111111111111111);
      this.itemCount = e;
      console.log(item);
    },

    /*
                  添加栏目项
                  */
    giAddTem: function giAddTem(item) {
      this.templateList.push({
        styleCode: item.styleCode,
        bgColor: '#ffffff',
        rowNum: 3,
        moduleAttr: {
          width: null,
          height: null,
          dataItem: []
        }
      });
    },
    goAddTemplate: function goAddTemplate(item) {
      var _this4 = this;

      (0, _template.listStyle)({
        parentId: item.id
      }).then(function (res) {
        console.log(res);
        _this4.styleList = res.data.filter(function (item) {
          return item.styleStatus === 1;
        });
      });
    },

    /*
              复制模板
              */
    copyItem: function copyItem(index) {
      var itemTem = JSON.parse(JSON.stringify(this.templateList[this.itemCount]));
      itemTem.id = null;
      console.log(itemTem);
      this.templateList.splice(index, 0, itemTem);
    },
    // 点击上移
    clickUp: function clickUp(index) {
      this.itemCount = index - 1;
      this.swapArray(this.templateList, index - 1, index);
    },
    // 点击下移
    clickDown: function clickDown(index) {
      this.itemCount = index + 1;
      this.swapArray(this.templateList, index, index + 1);
    },
    // 数组元素互换位置
    swapArray: function swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]; // return arr
    },

    /*
                  删除栏目项
                  */
    removeItem: function removeItem(row, index) {
      var _this5 = this;

      this.$confirm('此操作将删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (row.id) {
          _this5.openLoading();

          (0, _template.delModule)(row.id).then(function (res) {
            _this5.openLoading().close();

            _this5.templateList.splice(index, 1);

            _this5.tableItemShow = false;
          }).catch(function (err) {
            _this5.openLoading().close();
          });
        } else {
          _this5.templateList.splice(index, 1);

          _this5.itemCount = -1;
          _this5.tableItemShow = false;
        }
      }).catch(function () {});
    },

    /*
                  编辑栏目项
                  */
    editItem: function editItem(row, index) {
      this.currentRow = row;
      this.tableItemShow = true;
      this.temObjIndex = index;
    }
  }
};
exports.default = _default;