"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCategory = addCategory;
exports.categoryPage = categoryPage;
exports.delCategory = delCategory;
exports.getCategory = getCategory;
exports.getOrderMessage = getOrderMessage;
exports.goExamine = goExamine;

var _request = _interopRequireDefault(require("@/utils/request"));

function categoryPage(data) {
  return (0, _request.default)({
    url: '/api/card/make/rebate/order/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addCategory(data) {
  return (0, _request.default)({
    url: '/api/card/make/rebate/order',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function goExamine(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/api/card/make/rebate/order/examine',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function delCategory(data) {
  return (0, _request.default)({
    url: '/api/card/make/category/' + data,
    method: 'post'
  });
}

function getCategory(data) {
  return (0, _request.default)({
    url: '/api/card/make/rebate/order/' + data,
    loading: 'loading',
    method: 'get'
  });
}

function getOrderMessage(data) {
  return (0, _request.default)({
    url: '/api/card/make/rebate/order/message/' + data,
    loading: 'loading',
    method: 'get'
  });
}