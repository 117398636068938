"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _label = require("@/api/label.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      form: {
        classId: '',
        labelName: '',
        labelStatus: '',
        labelId: ''
      },
      listLabel: [],
      rules: {
        labelName: [{
          required: true,
          message: '请填写标签名称'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _label.listLabelClass)().then(function (res) {
      _this.listLabel = res.data;
    });
    this.form.labelId = Number(this.$route.query.id);

    if (this.form.labelId) {
      (0, _label.getLabelById)(this.form.labelId).then(function (res) {
        _this.form.classId = res.data.classId;
        _this.form.labelName = res.data.labelName;
        _this.form.labelStatus = res.data.labelStatus;
      }).catch(function (err) {});
    }
  },
  methods: {
    onSubmit: function onSubmit(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.openLoading();

          if (_this2.form.labelId) {
            (0, _label.editLabel)(_this2.form).then(function (res) {
              _this2.openLoading().close();

              _this2.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            }).catch(function (err) {
              _this2.openLoading().close();
            });
          } else {
            (0, _label.addLabel)(_this2.form).then(function (res) {
              _this2.openLoading().close();

              _this2.$message({
                message: '保存成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            }).catch(function (err) {
              _this2.openLoading().close();
            });
          }
        }
      });
    }
  }
};
exports.default = _default;