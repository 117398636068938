"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var giftTokenRouter = {
  path: '/lock',
  component: _layout.default,
  redirect: '/lock/list',
  name: 'lock',
  meta: {
    title: '购物卡锁定'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./list.vue"));
      });
    },
    name: 'lockList',
    meta: {
      title: '购物卡锁定',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'items',
      pageTotal: 'totalNum',
      addTxt: "新增锁定",
      addUrl: '/lock/add',
      api: '/api/cardStoredLock/selectListPage',
      columns: [{
        slot: 'voucherUrl'
      }, {
        label: '冻结单号',
        prop: 'lockCode',
        width: 80
      }, {
        label: '会有手机号',
        prop: 'userPhone',
        width: 80
      }, {
        label: '实际冻结金额',
        prop: 'amount',
        width: 60
      }, {
        label: '冻结人',
        prop: 'createName',
        width: 80
      }, {
        label: '冻结人手机号',
        prop: 'createPhone',
        width: 80
      }, {
        label: '冻结时间',
        prop: 'createTime',
        width: 60
      }, {
        slot: 'status'
      }, {
        label: '备注',
        prop: 'lockReason',
        width: 60
      }, {
        label: '解除人',
        prop: 'rellieveName',
        width: 80
      }, {
        label: '解除人手机号',
        prop: 'relievePhone',
        width: 80
      }, {
        label: '解除备注',
        prop: 'relieveReason',
        width: 80
      }, {
        label: '解除时间',
        prop: 'relieveTime',
        width: 60
      }, {
        slot: 'update'
      }]
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./add.vue"));
      });
    },
    name: 'cardLockAdd',
    meta: {
      title: '购物卡锁定-编辑',
      activeMenu: '/lock/list'
    }
  }]
};
var _default = giftTokenRouter;
exports.default = _default;