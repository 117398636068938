"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _auth = require("@/utils/auth");

var _api = require("./api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      form: {
        code: "",
        uid: "",
        mobile: "",
        shoppingReceipt: "",
        images: [],
        parkId: "",
        remark: ""
      },
      rules: {
        code: [{
          required: true,
          message: '请扫描会员码',
          trigger: 'blur'
        }],
        parkId: [{
          required: true,
          message: '请选择停车场',
          trigger: 'change'
        }],
        images: [{
          required: true,
          message: '请上传小票附件',
          trigger: 'change'
        }],
        remark: [{
          required: true,
          message: '请输入备注',
          trigger: 'blur'
        }]
      },
      uploadConfig: {
        SRCPREFIX: this.$store.state.user.imgSrc,
        URL: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
        HEADERS: {
          ContentType: 'multipart/form-data',
          Token: (0, _auth.getToken)()
        }
      },
      parkOptions: [],
      user: {},
      memberType: {
        /* 1，普通 2 ,365 3,一星 4，二星 5三星*/
        1: '普通',
        2: '365会员',
        3: '一星Lavant',
        4: '二星Lavant',
        5: '三星Lavant'
      }
    };
  },
  created: function created() {
    this.getParks();
  },
  methods: {
    onRemove: function onRemove(index) {
      this.form.images.splice(index, 1);
    },
    onBack: function onBack() {
      this.$router.back();
    },
    onConfirm: function onConfirm() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this.form));
          data.shoppingReceipt = JSON.stringify(data.images);
          (0, _api.addStopParkNum)(data).then(function () {
            _this.$message.success("保存成功");

            _this.onBack();
          });
        }
      });
    },
    onQueryUers: function onQueryUers() {
      var _this2 = this;

      if (!this.form.code) {
        this.$message.error("请输入会员码查询");
        return;
      }

      (0, _api.queryUser)(this.form.code).then(function (res) {
        _this2.form.uid = res.data.uid;
        _this2.form.mobile = res.data.mobile;
        _this2.user = res.data;
      });
    },
    getParks: function getParks() {
      var _this3 = this;

      (0, _api.listStopParkInfo)({
        pageSize: 1000,
        pageNo: 1
      }).then(function (res) {
        _this3.parkOptions = res.data;
      });
    },
    onUploadSuccess: function onUploadSuccess(res) {
      this.form.images.push(res.data);
      console.log(this.form);
      this.$forceUpdate();
    },
    onUploadBefore: function onUploadBefore(file) {
      var isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
      var isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }

      return isJPG && isLt2M;
    }
  }
};
exports.default = _default;