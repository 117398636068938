"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));

var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));

var _ErrorLog = _interopRequireDefault(require("@/components/ErrorLog"));

var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));

var _SizeSelect = _interopRequireDefault(require("@/components/SizeSelect"));

var _HeaderSearch = _interopRequireDefault(require("@/components/HeaderSearch"));

//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default,
    ErrorLog: _ErrorLog.default,
    Screenfull: _Screenfull.default,
    SizeSelect: _SizeSelect.default,
    Search: _HeaderSearch.default
  },
  data: function data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      editPwdShow: false,
      loading: false,
      formLabelWidth: 80,
      form: {
        password: '',
        newPassord: '',
        affirmNewPassord: ''
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar', 'avatar', 'device'])),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    }
  }
};
exports.default = _default;