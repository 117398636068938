"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      time: null,
      requestData: {
        name: null,
        startTime: null,
        endTime: null
      },
      tableData: [],
      columns: [{
        label: '等级ID',
        prop: 'id',
        width: 60
      }, {
        label: '等级名称',
        prop: 'name',
        width: 100
      }, {
        label: '等级所需乐享值',
        prop: 'growthValue',
        width: 80
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 120
        /* {
          slot: 'operate'
        } */

      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {
    this.getList();
  },
  methods: {
    handleDownload: function handleDownload() {
      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      this.getHandleDownload(tHeader, filterVal, this.tableData, '会员等级列表');
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getList)(this.requestData).then(function (res) {
        _this.tableData = res.data;
      });
    }
  }
};
exports.default = _default;