var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("新增物品")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "storeCode",
                  attrs: { label: "所属门店：", prop: "storeCode" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: {
                        size: "small",
                        placeholder: "所属门店",
                        filterable: "",
                        autocomplete: "off"
                      },
                      on: {
                        change: function($event) {
                          return _vm.init("search")
                        }
                      },
                      model: {
                        value: _vm.ruleForm.storeCode,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "storeCode", $$v)
                        },
                        expression: "ruleForm.storeCode"
                      }
                    },
                    _vm._l(_vm.storeList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.storeId,
                          attrs: {
                            label:
                              item.shortName + "（" + item.storeCode + "）",
                            value: item.storeCode
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                item.shortName + "（" + item.storeCode + "）"
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属栏目：", prop: "categoryId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.ruleForm.categoryId,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "categoryId", $$v)
                        },
                        expression: "ruleForm.categoryId"
                      }
                    },
                    _vm._l(_vm.temList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.categoryName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物品信息：", prop: "productItems" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.getRelevances }
                    },
                    [_vm._v("添加物品")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.ruleForm.productItems,
                        size: "mini",
                        border: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "物品类型",
                          "min-width": "60",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "productItems." +
                                      scope.$index +
                                      ".productType",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "productItems." +
                                        scope.$index +
                                        ".productType",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择物品类型"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          placeholder: "物品类型"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setAward(
                                              $event,
                                              scope.$index
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.productType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.productType"
                                        }
                                      },
                                      _vm._l(_vm.type, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "关联物品",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "productItems." +
                                      scope.$index +
                                      ".productCode",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "productItems." +
                                        scope.$index +
                                        ".productCode",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择物品"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    typeof scope.row.productType === "number"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.getAward(
                                                  scope.row.productType,
                                                  scope.$index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.attrType == 1
                                                    ? "选择优惠券"
                                                    : "选择商品"
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "物品编码",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    scope.row.productCode
                                      ? _c("p", [
                                          _vm._v(_vm._s(scope.row.productCode))
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "物品名称",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.productName || "") +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "排序(倒序)",
                          width: "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "productItems." + scope.$index + ".sort",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "productItems." +
                                        scope.$index +
                                        ".sort",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入排序"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        min: 1,
                                        controls: false,
                                        precision: 0,
                                        step: 1
                                      },
                                      model: {
                                        value: scope.row.sort,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "sort", $$v)
                                        },
                                        expression: "scope.row.sort"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "状态", width: "100", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "active-value": 1,
                                    "inactive-value": -1
                                  },
                                  model: {
                                    value: scope.row.status,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "status", $$v)
                                    },
                                    expression: "scope.row.status"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "活动价格",
                          width: "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "productItems." +
                                      scope.$index +
                                      ".productPrice",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "productItems." +
                                        scope.$index +
                                        ".productPrice",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入活动价格"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        controls: false,
                                        precision: 2,
                                        step: 1
                                      },
                                      model: {
                                        value: scope.row.productPrice,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "productPrice",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.productPrice"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品原价",
                          width: "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "productItems." +
                                      scope.$index +
                                      ".productOriginalPrice",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "productItems." +
                                        scope.$index +
                                        ".productOriginalPrice",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入原价"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        controls: false,
                                        precision: 2,
                                        step: 1
                                      },
                                      model: {
                                        value: scope.row.productOriginalPrice,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "productOriginalPrice",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scope.row.productOriginalPrice"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "确认删除该物品？" },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.delAward(scope.$index)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "danger",
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                          size: "mini"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }