var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "keep-alive",
        [
          _c(
            "w-table",
            {
              ref: "wTable",
              attrs: { size: "mini", "search-data": _vm.searchData }
            },
            [
              _c("el-table-column", {
                attrs: {
                  slot: "update",
                  align: "center",
                  label: "操作",
                  width: "100"
                },
                slot: "update",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/svip/reserve/add?id=" + scope.row.id
                                )
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "status",
                  label: "状态",
                  align: "center",
                  width: "100"
                },
                slot: "status",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status === 1
                          ? _c("span", { staticClass: "success" }, [
                              _vm._v("启用")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 2
                          ? _c("span", { staticClass: "danger" }, [
                              _vm._v("禁用")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "contentType",
                  label: "关联内容",
                  align: "center",
                  "min-width": "80"
                },
                slot: "contentType",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.contentType === "4"
                          ? _c("div", [
                              scope.row.appid
                                ? _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.appIdArr.find(function(item) {
                                            return (
                                              item.value === scope.row.appid
                                            )
                                          }).label
                                        ) +
                                        "\n              ：\n            "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.contentLink) +
                                  "\n          "
                              )
                            ])
                          : scope.row.contentType === "5"
                          ? _c("div", [
                              _vm._v(
                                "\n            web页面：\n            " +
                                  _vm._s(scope.row.contentLink) +
                                  "\n          "
                              )
                            ])
                          : scope.row.contentType
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.urlList.find(function(item) {
                                      return (
                                        item.value === scope.row.contentType
                                      )
                                    }).label
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "cover",
                  label: "图片",
                  align: "center",
                  width: "150"
                },
                slot: "cover",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("img", {
                          staticStyle: { width: "100px" },
                          attrs: { src: _vm.imgSrc + scope.row.pic }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }