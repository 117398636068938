"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      tableData: [],
      requestData: {
        name: null,
        account: null,
        pageSize: 10,
        page: 1
      },
      searchData: [{
        label: '团购单号',
        type: 'input',
        span: 3,
        placeholder: '团购单号',
        value: 'groupCode'
      }, {
        label: '用户手机号',
        type: 'input',
        span: 3,
        placeholder: '请输入用户手机号',
        value: 'userMobile'
      }, {
        label: '储值卡编码',
        type: 'input',
        span: 3,
        placeholder: '储值卡编码',
        value: 'cardUuid'
      }, {
        label: '兑换方式',
        type: 'radio',
        span: 3,
        placeholder: '选择兑换方式',
        value: 'exchangeType',
        select: [{
          label: '兑换码',
          value: 1
        }, {
          label: '独立二维码',
          value: 2
        }, {
          label: '统一二维码',
          value: 3
        }, {
          label: '自动充值',
          value: 4
        }]
      }, {
        label: '开始时间',
        type: 'dateTime',
        span: 3,
        placeholder: '请选择开始时间',
        value: 'startDate'
      }, {
        label: '结束时间',
        type: 'dateTime',
        span: 3,
        placeholder: '请选择结束时间',
        value: 'endDate'
      }, {
        label: '状态',
        type: 'radio',
        span: 3,
        placeholder: '选择状态',
        value: 'status',
        select: [{
          label: '未兑换',
          value: 1
        }, {
          label: '已兑换',
          value: 2
        }]
      }]
    };
  },
  methods: {
    goAudit: function goAudit(row, type) {
      var _self = this;

      (0, _api.goChangeStatus)({
        id: row.id,
        status: type
      }).then(function (res) {
        _self.$refs.wTable.getList();

        _self.$message({
          type: 'success',
          message: '操作成功!'
        });
      });
    }
  }
};
exports.default = _default;