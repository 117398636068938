var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCard
    ? _c(
        "div",
        { staticClass: "fixed_center", staticStyle: { "z-index": "100000" } },
        [
          _c(
            "div",
            { staticClass: "card_box" },
            [
              _c("slide-verify", {
                attrs: {
                  l: 42,
                  r: 10,
                  w: 360,
                  h: 240,
                  imgs: _vm.bgimgs,
                  "slider-text": "向右滑动~"
                },
                on: {
                  success: _vm.onSuccess,
                  fail: _vm.onFail,
                  refresh: _vm.onRefresh
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card_box_close" },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-close", circle: "" },
                    on: { click: _vm.goClose }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }