"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    wTable: _index.default,
    AppLink: _Link.default
  },
  data: function data() {
    return {
      tableData: [],
      columns: [{
        label: '方案编码',
        prop: 'ruleCode',
        width: 100
      }, {
        label: '方案名称',
        prop: 'ruleName',
        width: 100
      }, {
        slot: 'startTime'
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 120
      }, {
        label: '备注',
        prop: 'ruleRemark',
        width: 120
      }, {
        slot: 'ruleStatus'
      }, {
        slot: 'operate'
      }],
      loading: true,
      pageObj: {
        pageNo: 1,
        total: 0,
        pageSize: 10
      }
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    setPage: function setPage(pageObj) {
      this.pageObj.pageNo = pageObj.pageNo;
      this.pageObj.pageSize = pageObj.pageSize;
      this.init();
    },
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.pageObj.pageNo = 1;
        this.pageObj.pageSize = 10;
      }

      if (type == 'remove') {
        this.removePage(this.pageObj);
      }

      this.loading = true;
      (0, _api.listCardPage)(this.pageObj).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data.records;
        _this.pageObj.total = res.data.total;
      });
    }
  }
};
exports.default = _default;