"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.number.constructor");

var _api = require("./api.js");

var _api2 = require("../plan/api.js");

var _mem = require("@/api/mem.js");

var _api3 = require("../category/api.js");

var _api4 = require("../class/api.js");

var _api5 = require("../api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _self = this;

    return {
      ignoreCardNos: [],
      storeLists: [],
      storeList: [],
      classList: [],
      rules: {
        cardSegmentNoStart: [{
          required: true,
          message: '请输入起始卡号'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < _self.ruleForm.cardSegmentNoStart || value > _self.ruleForm.cardSegmentNoEnd) {
              callback(new Error('请正确输入卡号'));
            } else {
              callback();
            }
          }
        }],
        cardSegmentNoEnd: [{
          required: true,
          message: '请输入结束卡号'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < _self.ruleForm.cardSegmentNoStart || value > _self.ruleForm.cardSegmentNoEnd) {
              callback(new Error('请输入正确区间数'));
            } else {
              callback();
            }
          }
        }],
        num: [{
          required: true,
          message: '请输入数量'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        storeId: [{
          required: true,
          message: '请选择出库门店'
        }],
        cardKindsId: [{
          required: true,
          message: '请选择种类'
        }]
      },
      ruleForm: {
        cardCategoryId: null,
        cardKindsId: null,
        planName: null,
        cardNumber: null,
        cardSegmentNoStart: null,
        cardSegmentNoEnd: null,
        makeFactoryName: null,
        remark: null
      },
      inOutFirm: {
        planId: null,
        cardSegmentNoStart: null,
        cardSegmentNoEnd: null,
        num: null,
        remark: null,
        cardCategoryId: null,
        cardKindsId: null,
        storeId: null
      }
    };
  },
  created: function created() {
    var _this = this;

    // this.inOutFirm.cardCategoryId = this.$route.query.cardCategoryId ? Number(this.$route.query.cardCategoryId) : null
    this.inOutFirm.cardKindsId = this.$route.query.cardKindsId ? Number(this.$route.query.cardKindsId) : null;
    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.storeLists = res.data;
    });
    this.inOutFirm.planId = this.$route.query.id;
    (0, _api3.categoryPage)({
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      _this.storeList = res.data.records;
    });
    this.getClass();

    if (this.inOutFirm.cardKindsId) {
      this.getRangeCard(this.inOutFirm.cardKindsId);
      return;
      (0, _api.rangeCard)(this.inOutFirm.cardKindsId).then(function (res) {
        _this.ruleForm.cardSegmentNoStart = res.data.mixCardNo;
        _this.ruleForm.cardSegmentNoEnd = res.data.maxCardNo;
      });
    }
  },
  methods: {
    numChange: function numChange(e) {
      var _this2 = this;

      this.$nextTick(function () {
        _this2.inOutFirm.cardSegmentNoEnd = null;
        _this2.ignoreCardNos = [];
        (0, _api5.calculateEndCardNo)({
          'model': 'CARD_STORE_IN',
          'startCardNo': _this2.inOutFirm.cardSegmentNoStart,
          'num': _this2.inOutFirm.num,
          'id': _this2.inOutFirm.cardKindsId
        }).then(function (res) {
          _this2.inOutFirm.cardSegmentNoEnd = res.data.endCardNo;
          _this2.ignoreCardNos = res.data.ignoreCardNos;
        });
      });
    },
    getRangeCard: function getRangeCard(e) {
      var _this3 = this;

      (0, _api.rangeCard)(e).then(function (res) {
        _this3.inOutFirm.cardCategoryId = res.data.cardCategoryId;
        _this3.ruleForm.cardSegmentNoStart = String(res.data.mixCardNo);
        _this3.ruleForm.cardSegmentNoEnd = String(res.data.maxCardNo);
        _this3.inOutFirm.cardSegmentNoStart = res.data.mixCardNo;
      });
    },
    classChange: function classChange(e) {
      console.log(e);
    },
    getClass: function getClass(e) {
      var _this4 = this;

      (0, _api4.classPage)({
        pageNo: 1,
        pageSize: 100
      }).then(function (res) {
        _this4.classList = res.data.records;
      });
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    clearForm: function clearForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this5.inOutFirm));
          data.ignoreCards = _this5.ignoreCardNos;
          (0, _api.getInOutOut)(data).then(function (res) {
            _this5.$router.replace('/card/inOutList');

            _this5.$message({
              type: 'success',
              message: '添加出库计划成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;