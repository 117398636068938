"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      downloadLoading: false,
      requestData: {
        weekTag: null,
        name: null,
        startTime: null,
        endTime: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '凭证',
        prop: 'voucherPic',
        slot: 'voucherPic'
      }, {
        label: '手机号',
        prop: 'phone',
        width: 100
      }, {
        slot: 'type',
        label: '类型',
        prop: 'type',
        width: 100
      }, {
        label: '补发金额',
        prop: 'money',
        width: 60
      }, {
        label: '补发购物卡',
        prop: 'cardNo',
        width: 60
      }, {
        label: '操作人',
        prop: 'userName',
        width: 80
      }, {
        label: '备注',
        prop: 'remark',
        width: 120
      }, {
        label: '补发时间',
        prop: 'createTime',
        width: 120
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {},
  methods: {
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    delGoods: function delGoods(row) {
      var _this = this;

      (0, _api.addData)({
        id: row.id
      }).then(function (res) {
        _this.getList();
      });
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = JSON.parse(JSON.stringify(this.requestData));
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getList)(data).then(function (res) {
        res.data.items.forEach(function (item) {
          if (!_this2.setMenuPerms('user_mobile_idCard')) {
            if (item.phone) {
              item.phone = item.phone.replace(item.phone.substring(3, 7), "****");
            }
          }

          item.type = item.type === 1 ? '365补发' : '签到补发';
          item.voucherPic = _this2.imgSrc + item.voucherPic;
        });
        list = res.data.items;
        console.log(list);
        _this2.downloadLoading = false;

        _this2.getHandleDownload(tHeader, filterVal, list, '购物卡补发记录');
      }).catch(function (res) {
        _this2.downloadLoading = false;
      });
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getList)(this.requestData).then(function (res) {
        if (!_this3.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.phone) {
              item.phone = item.phone.replace(item.phone.substring(3, 7), "****");
            }
          });
        }

        _this3.tableData = res.data.items;
        _this3.requestData.total = res.data.totalNum;
      });
    }
  }
};
exports.default = _default;