"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      formData: [{
        label: '商户名称：',
        type: 'input',
        placeholder: '请输入商户名称',
        value: 'merchantName'
      }, {
        label: '状态：',
        type: 'radio',
        placeholder: '请选择状态',
        value: 'status',
        selectList: [{
          label: '启用',
          value: 1
        }, {
          label: '停用',
          value: 2
        }]
      }, {
        label: '负责人：',
        type: 'input',
        placeholder: '请输入负责人',
        value: 'headName'
      }, {
        label: '负责人手机号：',
        type: 'input',
        placeholder: '请输入负责人手机号',
        value: 'headMobile'
      }, {
        label: '地址：',
        type: 'input',
        placeholder: '请输入地址',
        value: 'address'
      }, {
        label: '附件：',
        type: 'img',
        placeholder: '请上传图片',
        value: 'annexUrl'
      }, {
        label: '备注：',
        type: 'input',
        placeholder: '请输入备注',
        value: 'remark'
      }],
      ruleForm: {
        merchantName: null,
        status: null,
        address: null,
        headName: null,
        headMobile: null,
        annexUrl: null,
        remark: null,
        id: null
      },
      rules: {
        merchantName: [{
          required: true,
          message: '请输入商户名称'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        address: [{
          required: true,
          message: '请输入地址'
        }],
        headName: [{
          required: true,
          message: '请输入负责人'
        }],
        headMobile: [{
          required: true,
          message: '请输入负责人手机号'
        }],
        annexUrl: [{
          required: true,
          message: '请上传图片'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }]
      }
    };
  },
  components: {},
  computed: {},
  created: function created() {
    var _this = this;

    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/third/party/merchant/getThirdPartyMerchantById/' + this.ruleForm.id).then(function (res) {
        delete res.data.createTime;
        _this.ruleForm = res.data;
      });
    }
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var apiUrl = this.ruleForm.id ? '/api/third/party/merchant/editThirdPartyMerchant' : '/api/third/party/merchant/addThirdPartyMerchant';
      this.apiPost(apiUrl, this.ruleForm).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
      return;
      addData(this.ruleForm).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;