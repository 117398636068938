"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '新增优惠券',
      list: [{
        tit: '代金券',
        txtArr: '<p>满减规则、代金券金额、适用范围、有效时间</p>可区分线上线下',
        imgPath: require('@/assets/tab/1.png'),
        path: '/coupon/coupon1/addVolume?couponType=1'
      },
      /* {
        tit: '折扣券',
        txtArr: `<p>折扣率、适用范围、有效时间<p/>可区分线上线下`,
        imgPath: require('@/assets/tab/2.png'),
        path: '/coupon/coupon1/addVolume?couponType=2'
      },
      {
        tit: '礼品券',
        txtArr: '<p>礼品项目、面额、礼品数量、适用范围、有效时间</p>可区分线上线下礼品券对收银金额无效，所以不建议使用收银机核销',
        imgPath: require('@/assets/tab/21.png'),
        path: '/coupon/coupon1/addVolume?couponType=3'
      }, */

      /*  {
        tit: '体验券',
        txtArr: '<p>设置项目、设置次数、适用范围、有效时间</p>即将下线',
        imgPath: '',
        path: '/coupon/coupon1/addVolume?couponType=4'
      }, */
      {
        tit: '外部优惠券',
        txtArr: '<p>外部优惠券</p>',
        imgPath: require('@/assets/tab/21.png'),
        path: '/coupon/coupon1/addVolume?couponType=5'
      }]
    };
  },
  watch: {},
  methods: {
    setTab: function setTab(path) {
      this.$router.push(path);
    }
  }
};
exports.default = _default;