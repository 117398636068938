var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "label-width": "100px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称：" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入角色名称" },
                model: {
                  value: _vm.roleName,
                  callback: function($$v) {
                    _vm.roleName = $$v
                  },
                  expression: "roleName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "对应菜单：" } },
            [
              _c("el-tree", {
                ref: "tree",
                staticClass: "li_tree",
                attrs: {
                  data: _vm.menuList,
                  "show-checkbox": "",
                  "check-strictly": _vm.checkStrictly,
                  "node-key": "menuId",
                  "default-expanded-keys": _vm.expanded,
                  "default-checked-keys": _vm.expanded,
                  props: _vm.defaultProps
                },
                on: { "node-click": _vm.nodeClick }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "角色备注：" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 4, maxRows: 4 },
                  placeholder: "请输入部门备注"
                },
                model: {
                  value: _vm.remark,
                  callback: function($$v) {
                    _vm.remark = $$v
                  },
                  expression: "remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "角色名称：" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入角色名称" },
                model: {
                  value: _vm.roleName,
                  callback: function($$v) {
                    _vm.roleName = $$v
                  },
                  expression: "roleName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.next } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }