"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      status: null,
      isPop: false,
      rules: {
        status: [{
          required: true,
          message: '请选择'
        }],
        description: [{
          required: true,
          message: '请输入描述'
        }]
      },
      ruleForm: {
        status: null,
        description: null,
        activityId: null
      },
      downloadLoading: false,
      time: null,
      requestData: {
        status: null,
        name: null,
        type: null,
        startTime: null,
        endTime: null,
        activityMonth: null,
        activityType: 2,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      platfromList: [],
      tableData: [],
      columns: [{
        slot: 'activityType',
        label: '活动类型',
        prop: 'activityType',
        width: 60
      }, {
        label: '活动名称',
        prop: 'name',
        width: 60
      }, {
        label: '活动详情',
        prop: 'ruleDesc',
        width: 100
      }, {
        label: '策略类型',
        prop: 'typeDesc',
        width: 80
      }, {
        label: '定向人群',
        prop: 'targetUserDesc',
        width: 80
      }, {
        slot: 'limitType',
        label: '限制条件',
        prop: 'limitTypeDesc',
        width: 80
      }, {
        label: '活动周期',
        prop: 'activityMonth',
        width: 80
      }, {
        label: '活动时间',
        prop: 'activityTimeRange',
        width: 120
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        label: '活动状态',
        prop: 'activityDesc',
        width: 60
      }, {
        label: '审核状态状态',
        prop: 'statusDes',
        width: 60
      },
      /* {
        label: '规则描述',
        prop: 'ruleDesc',
        width: 80
      }, */
      {
        slot: 'operate'
      }]
    };
  },
  watch: {
    isPop: function isPop(newValue, oldValue) {
      if (!newValue) {
        this.ruleForm.activityId = null;
        this.ruleForm.description = null;
        this.status = null;
      }
    },
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {
    this.getList();
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.goExam)(_this.ruleForm).then(function (res) {
            _this.isPop = false;

            _this.getList();
          });
        } else {
          return false;
        }
      });
    },
    goExamBox: function goExamBox(row) {
      this.ruleForm.activityId = row.id;
      this.isPop = true;
    },
    goAudit: function goAudit(row, status) {
      this.ruleForm.activityId = row.id;
      this.ruleForm.status = status;
      this.status = status;
      this.isPop = true;
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this2.requestData[key]) {
          data[key] = _this2.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getList)(data).then(function (res) {
        list = res.data.records;
        _this2.downloadLoading = false;

        _this2.getHandleDownload(tHeader, filterVal, list, '活动券C列表');
      }).catch(function (res) {
        _this2.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.current = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getList)(this.requestData).then(function (res) {
        _this3.tableData = res.data.records;
        _this3.requestData.total = Number(res.data.total);
        return;
      });
    }
  }
};
exports.default = _default;