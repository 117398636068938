"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCategory = addCategory;
exports.categoryPage = categoryPage;
exports.delCategory = delCategory;
exports.editCategory = editCategory;
exports.getCategory = getCategory;

var _request = _interopRequireDefault(require("@/utils/request"));

function categoryPage(data) {
  return (0, _request.default)({
    url: '/api/card/make/rebate/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addCategory(data) {
  return (0, _request.default)({
    url: '/api/card/make/rebate',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editCategory(data) {
  return (0, _request.default)({
    url: '/api/card/make/rebate/update',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function delCategory(data) {
  return (0, _request.default)({
    url: '/api/card/make/category/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function getCategory(data) {
  return (0, _request.default)({
    url: '/api/card/make/rebate/' + data,
    method: 'get',
    loading: 'loading'
  });
}