"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '商城活动',
      list: [{
        tit: '商品管理',
        txtArr: '商城活动——商品管理',
        imgPath: require('@/assets/tab/activity/1.png'),
        path: '/activity/mall/goods'
      }, {
        tit: '轮播图管理',
        txtArr: '商城活动——轮播图管理',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: '/activity/mall/banner'
      }, {
        tit: '栏目管理',
        txtArr: '商城活动——栏目管理',
        imgPath: require('@/assets/tab/activity/5.png'),
        path: '/activity/mall/tem'
      }]
    };
  }
};
exports.default = _default;