"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _test = _interopRequireDefault(require("./test.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    JsonView: _test.default
  },
  data: function data() {
    return {
      isShowPop: false,
      jsonViewData: {},
      obj: {
        pageNo: 1,
        pageSize: 10,
        userMobile: '',
        userName: '',
        executeRemark: ''
      },
      list: [],
      selectList: [],
      tabTh: [{
        prop: 'logId',
        label: 'ID',
        width: 50
      }, {
        prop: 'userName',
        label: '操作人名称',
        width: 50
      }, {
        prop: 'userMobile',
        label: '操作人手机号码',
        width: 50
      }, {
        prop: 'reqIp',
        label: '请求IP地址',
        width: 80
      }, {
        prop: 'reqUrl',
        label: '请求路由',
        width: 140
      }, {
        prop: 'executeRemark',
        label: '操作描述',
        width: 80
      }, {
        prop: 'createTime',
        label: '操作时间',
        width: 100
      }],
      total: 0
    };
  },
  created: function created() {
    var _this = this;

    (0, _user.listExecuteRemark)().then(function (res) {
      _this.selectList = res.data;
    });
    this.init();
  },
  methods: {
    setJson: function setJson(row) {
      row.request = row.reqParams ? JSON.parse(row.reqParams) : '';
      this.jsonViewData = row || {};
      this.isShowPop = true;
    },
    goDea: function goDea(row) {
      this.$router.push('/public/logDea?id=' + row.logId);
    },
    init: function init(type) {
      var _this2 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
      }

      this.openLoading();
      (0, _user.listSysLogPage)(this.obj).then(function (res) {
        _this2.openLoading().close();

        _this2.list = res.data.items;
        _this2.total = res.data.totalNum;
      }).catch(function (err) {
        _this2.openLoading().close();
      });
    }
  }
};
exports.default = _default;