"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'LegouGoods1005',
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc
    };
  },
  computed: {},
  methods: {
    onMove: function onMove(relatedContext, draggedContext) {
      console.log(relatedContext.relatedContext.list);
    }
  }
};
exports.default = _default2;