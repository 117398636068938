var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "improve-record" },
    [
      _c(
        "header",
        [
          _c(
            "div",
            { staticClass: "view_title flex_between" },
            [
              _c("span", [_vm._v("奖励领取记录")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    icon: "el-icon-download",
                    size: "mini",
                    loading: _vm.downloadLoading
                  },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v("导出Excel")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { inline: true, model: _vm.requestData, size: "mini" } },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "用户手机号" },
                model: {
                  value: _vm.requestData.userMobile,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "userMobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.userMobile"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "用户名称" },
                model: {
                  value: _vm.requestData.userName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "userName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.userName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "奖励类型" },
                  on: { change: _vm.getList },
                  model: {
                    value: _vm.requestData.type,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "type", $$v)
                    },
                    expression: "requestData.type"
                  }
                },
                _vm._l(_vm.typeOptions, function(lab, val) {
                  return _c("el-option", {
                    key: val,
                    attrs: { label: lab, value: val }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "range-separator": "——",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  "default-time": ["00:00:00", "23:59:59"]
                },
                on: { change: _vm.getList },
                model: {
                  value: _vm.times,
                  callback: function($$v) {
                    _vm.times = $$v
                  },
                  expression: "times"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.search }
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.list,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: { slot: "type", label: "奖励类型", align: "center" },
            slot: "type",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.typeOptions[row.type]) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }