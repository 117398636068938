"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cardTypeDetail = cardTypeDetail;
exports.cardTypePage = cardTypePage;
exports.cardTypeSave = cardTypeSave;
exports.customerDetail = customerDetail;
exports.customerPage = customerPage;
exports.customerSave = customerSave;
exports.kindsPage = kindsPage;

var _request = _interopRequireDefault(require("@/utils/request"));

// 企业 个人客户 制卡商
function customerPage(data) {
  return (0, _request.default)({
    url: '/api/cardMakeCustomerInfo/pageCardMakeCustomerInfo',
    method: 'post',
    data: data
  });
}

function customerDetail(id) {
  return (0, _request.default)({
    url: '/api/cardMakeCustomerInfo/getByIdCardMakeCustomerInfo/' + id,
    method: 'get'
  });
}

function customerSave(data) {
  return (0, _request.default)({
    url: data.id ? '/api/cardMakeCustomerInfo/editCardMakeCustomerInfo' : '/api/cardMakeCustomerInfo/saveCardMakeCustomerInfo',
    method: 'post',
    data: data
  });
} // 卡类型


function cardTypePage(data) {
  return (0, _request.default)({
    url: '/api/card/make/category/page',
    method: 'post',
    data: data
  });
}

function cardTypeDetail(id) {
  return (0, _request.default)({
    url: '/api/card/make/category/' + id,
    method: 'get'
  });
}

function cardTypeSave(data) {
  return (0, _request.default)({
    url: data.id ? '/api/card/make/category/update' : '/api/card/make/category',
    method: 'post',
    data: data
  });
} // 卡种


function kindsPage(data) {
  return (0, _request.default)({
    url: '/api/card/make/kinds/page',
    method: 'post',
    data: data
  });
}