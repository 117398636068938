var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            size: "mini",
            "max-height": _vm.height,
            border: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              width: "50",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.pageObj
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.pageObj.pageNo - 1) * _vm.pageObj.pageSize +
                                scope.$index +
                                1
                            )
                          )
                        ])
                      : _c("span", [_vm._v(_vm._s(scope.$index + 1))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.columns, function(col, index) {
            return [
              col.slot
                ? _vm._t(col.slot)
                : _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: col.prop,
                      label: col.label,
                      "min-width": col.width,
                      formatter: col.formatter,
                      align: col.align || "center"
                    }
                  })
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.pageObj && _vm.pageObj.total > 0
        ? _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              align: "right",
              background: "",
              "page-size": _vm.pageObj.pageSize,
              total: _vm.pageObj.total,
              "page-sizes": _vm.pageSizes,
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.pageObj.pageNo
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }