var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "centent" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("发票管理")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "coupon_top" },
        [
          _c(
            "el-row",
            { staticClass: "row_box", attrs: { gutter: 5 } },
            [
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "订单号/流水号" },
                    model: {
                      value: _vm.sid,
                      callback: function($$v) {
                        _vm.sid = $$v
                      },
                      expression: "sid"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "发票代码" },
                    model: {
                      value: _vm.fpdm,
                      callback: function($$v) {
                        _vm.fpdm = $$v
                      },
                      expression: "fpdm"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "发票号码" },
                    model: {
                      value: _vm.fphm,
                      callback: function($$v) {
                        _vm.fphm = $$v
                      },
                      expression: "fphm"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.betweenTime,
                      callback: function($$v) {
                        _vm.betweenTime = $$v
                      },
                      expression: "betweenTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "用户手机号" },
                    model: {
                      value: _vm.peopleTel,
                      callback: function($$v) {
                        _vm.peopleTel = $$v
                      },
                      expression: "peopleTel"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.getList({ limit: 10, page: 1 })
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "info" },
                  on: { click: _vm.remove }
                },
                [_vm._v("重置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.goAdd }
                },
                [_vm._v("开票")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-download",
                    size: "mini",
                    loading: _vm.downloadLoading
                  },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v("导出Excel")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list_table" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                size: "mini",
                "header-cell-style": { background: "#f5f5f5" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { width: "80", align: "center", label: "序号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              (_vm.pageNo - 1) * _vm.pageSize + scope.$index + 1
                            ) +
                            "\n          "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _vm._l(_vm.tabTh, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    sortable: item.sortable,
                    prop: item.prop,
                    label: item.tit,
                    "min-width": item.width,
                    formatter: item.formatter,
                    align: "center"
                  }
                })
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "160", align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.setBtn(scope.row)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        scope.row.specialInvoiceStatus == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleCheck(scope.row)
                                  }
                                }
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.specialInvoiceStatus == 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleCheck(scope.row)
                                  }
                                }
                              },
                              [_vm._v("重开")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0"
              }
            ],
            attrs: { total: _vm.total, page: _vm.pageNo, limit: _vm.pageSize },
            on: {
              "update:page": function($event) {
                _vm.pageNo = $event
              },
              "update:limit": function($event) {
                _vm.pageSize = $event
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "审核内容",
            visible: _vm.openChecked,
            "with-header": false,
            "append-to-body": true,
            "modal-append-to-body": false,
            size: "700px"
          },
          on: {
            "update:visible": function($event) {
              _vm.openChecked = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "30px" } },
            [
              _c(
                "el-alert",
                {
                  staticClass: "check_alert",
                  attrs: { type: "info", closable: false }
                },
                [
                  _c("div", [
                    _vm._v("在开票需审商品清单内，该主体已开票商品数量：")
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n            本年数量：" +
                        _vm._s(_vm.checkData.limitProductInvoice) +
                        " 累计数量：" +
                        _vm._s(_vm.checkData.allInvoiceCount) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v("开票数量以“订单购买时间”为计算基准")])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "checkForm",
                  staticClass: "check_form",
                  attrs: {
                    model: _vm.checkForm,
                    rules: _vm.checkRules,
                    size: "small",
                    "label-width": "auto"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "status", label: "审核方案：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.checkForm.status,
                            callback: function($$v) {
                              _vm.$set(_vm.checkForm, "status", $$v)
                            },
                            expression: "checkForm.status"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("通过，确认开票")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: -1 } }, [
                            _vm._v("审核不通过")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "remark", label: "备注：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 3 },
                          placeholder: "请输入备注"
                        },
                        model: {
                          value: _vm.checkForm.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.checkForm, "remark", $$v)
                          },
                          expression: "checkForm.remark"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.openChecked = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleCheck }
                        },
                        [_vm._v("确认审核")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }