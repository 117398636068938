"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSource = addSource;
exports.editSource = editSource;
exports.getSource = getSource;
exports.listSourcePage = listSourcePage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
1、新增货源地：/mall/product/source/addSource
{"productSourceName":"货源地名称","rowStatus":"状态 0启用 -1停用","userName":"联系人","userMobile":"联系电话","sourceAddress":"所在地址","sourceRemark":"备注"}

2、编辑货源地：/mall/product/source/editSource
{"id":"id","productSourceName":"货源地名称","rowStatus":"状态 0启用 -1停用","userName":"联系人","userMobile":"联系电话",
"sourceAddress":"所在地址","sourceRemark":"备注"}

3、编辑货源地：/mall/product/source/listSourcePage
{"pageNo":"当前页","pageSize":"页条数","productSourceName":"货源地名称","rowStatus":"状态 0启用 -1停用"}

4、货源地列表：/mall/product/source/listSource
{"productSourceName":"货源地名称","rowStatus":"状态 0启用 -1停用"}

5、货源地明细：/mall/product/source/get/{id}
 */
function listSourcePage(data) {
  return (0, _request.default)({
    url: '/mall/product/source/listSourcePage',
    method: 'post',
    data: data
  });
}

function addSource(data) {
  return (0, _request.default)({
    url: '/mall/product/source/addSource',
    method: 'post',
    data: data
  });
}

function editSource(data) {
  return (0, _request.default)({
    url: '/mall/product/source/editSource',
    method: 'post',
    data: data
  });
}

function getSource(data) {
  return (0, _request.default)({
    url: '/mall/product/source/get/' + data,
    method: 'post'
  });
}