var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "w-table",
        {
          attrs: {
            pageApi: "/api/store/listStorePage",
            "search-data": _vm.searchData,
            columns: _vm.tabTh
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "storeStatus",
              label: "状态",
              width: "100px",
              align: "center"
            },
            slot: "storeStatus",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.storeStatus == 1
                      ? _c("span", { staticClass: "warning" }, [
                          _vm._v("开业准备")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.storeStatus == 2
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("正常营业")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.storeStatus == 3
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("暂停营业")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.storeStatus == 4
                      ? _c("span", { staticClass: "danger" }, [_vm._v("作废")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "longOrLat",
              label: "经纬度",
              "min-width": "5",
              align: "center"
            },
            slot: "longOrLat",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.longitude && row.latitude
                      ? _c("span", [
                          _vm._v(
                            "\n       " +
                              _vm._s(row.longitude + "," + row.latitude) +
                              "\n       "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "caozuo",
              label: "操作",
              width: "170",
              align: "center"
            },
            slot: "caozuo",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.setMenuPerms("store:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.goEdit(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.getCode(scope.row)
                          }
                        }
                      },
                      [_vm._v("拉新码")]
                    ),
                    _vm._v(" "),
                    scope.row.storeCode.indexOf("12") == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/payment/channels?storeId=" +
                                    scope.row.storeCode
                                )
                              }
                            }
                          },
                          [_vm._v("收银配置")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              url: _vm.url,
              "qr-code": _vm.qrCode,
              "coupon-u-uid": _vm.couponUUid
            },
            on: { closePop: _vm.closePop }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }