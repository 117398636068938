"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _api = require("@/api/api.js");

var _api2 = require("./api.js");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    wTable: _index.default
  },
  data: function data() {
    return {
      storeList: [],
      downloadLoading: false,
      balance: '',
      uStatusStr: '',
      cardNo: '',
      requestData: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        sendMobile: '',
        couponCode: '',
        couponName: '',
        storeCode: '',
        sendUname: '',
        toUmobile: '',
        startDate: '',
        endDate: ''
      },
      time: '',
      tableData: [],
      columns: [{
        label: '券编码',
        prop: 'couponCode',
        width: 100
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 100
      }, {
        label: '发券人',
        prop: 'sendUname',
        width: 80
      }, {
        label: '发券人手机号',
        prop: 'sendMobile',
        width: 80
      }, {
        label: '发券数量',
        prop: 'couponSendNum',
        width: 50
      }, {
        label: '发券人机构',
        prop: 'storedName',
        width: 100
      }, {
        label: '发券人机构编码',
        prop: 'storedCode',
        width: 50
      }, {
        label: '发券时间',
        prop: 'sendTime',
        width: 100
      }, {
        label: '会员姓名',
        prop: 'toUName',
        width: 80
      }, {
        label: '会员手机号',
        prop: 'toUMobile',
        width: 100 // private String couponInfo;  // 券相关信息 */

      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['券编码', '券名称', '发券人', '发券人手机号', '发券数量', '发券人机构', '发券人机构编码', '发券时间', '会员姓名', '会员手机号'];
        var filterVal = ['couponCode', 'couponName', 'sendUname', 'sendMobile', 'couponSendNum', 'storedName', 'storedCode', 'sendTime', 'toUName', 'toUMobile'];
        var list = [];
        _this2.requestData.startDate = _this2.time[0] || '';
        _this2.requestData.endDate = _this2.time[1] || '';
        var data = {};
        Object.keys(_this2.requestData).forEach(function (key) {
          if (_this2.requestData[key]) {
            data[key] = _this2.requestData[key];
          }
        });
        data.pageSize = _this2.requestData.total;
        data.pageNo = 1;

        _this2.openLoading();

        (0, _api2.listLogDetailPage)(data).then(function (res) {
          if (!_this2.setMenuPerms('user_mobile_idCard')) {
            res.data.items.forEach(function (item) {
              if (item.toUMobile) {
                item.toUMobile = item.toUMobile.replace(item.toUMobile.substring(3, 7), "****");
              }
            });
          }

          list = res.data.items;

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: _this2.cardNo
          });
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        }).catch(function (res) {
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type == 'search') {
        this.requestData.pageNo = 1;
      }

      if (type == 'remove') {
        this.time = '';
        this.removeObj(this.requestData);
      }

      this.requestData.startDate = this.time[0] || '';
      this.requestData.endDate = this.time[1] || '';
      (0, _api2.listLogDetailPage)(this.requestData).then(function (res) {
        if (!_this3.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.toUMobile) {
              item.toUMobile = item.toUMobile.replace(item.toUMobile.substring(3, 7), "****");
            }
          });
        }

        _this3.tableData = res.data.items;
        _this3.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;