var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 购物卡充值返利 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "120px"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "手机号码：", prop: "chargeUserId" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { maxlength: "11", placeholder: "请输入手机号码" },
                on: { change: _vm.changeMobile },
                model: {
                  value: _vm.ruleForm.userMobile,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "userMobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.userMobile"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "100px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.getUserInfo }
                },
                [_vm._v("查询")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "会员信息：" } }, [
            _c(
              "div",
              { staticClass: "change-label-calss" },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "手机号码：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.mobile))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "会员姓名：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.realname))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "会员ID：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.uid))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "会员等级：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.gradeName))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "会员积分：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.score))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "乐享值：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.growthValue))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "性别：" } }, [
                          _vm.userInfo.sex == 1
                            ? _c("span", [_vm._v("男")])
                            : _vm.userInfo.sex == 2
                            ? _c("span", [_vm._v("女")])
                            : _c("span", [_vm._v("未知")])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "民族：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.nation))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "充值金额：", prop: "chargeAmount" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入充值金额" },
                model: {
                  value: _vm.ruleForm.chargeAmount,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "chargeAmount",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.chargeAmount"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "支付金额：", prop: "payAmount" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入支付金额" },
                model: {
                  value: _vm.ruleForm.payAmount,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "payAmount",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.payAmount"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.isGoCard
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { ref: "activityId", attrs: { label: "返利活动：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form_input",
                          attrs: {
                            filterable: "",
                            remote: "",
                            clearable: "",
                            "reserve-keyword": "",
                            placeholder: "请输入活动名称搜索",
                            "remote-method": _vm.remoteMethod
                          },
                          on: { change: _vm.changeActivity },
                          model: {
                            value: _vm.ruleForm.activityId,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "activityId", $$v)
                            },
                            expression: "ruleForm.activityId"
                          }
                        },
                        _vm._l(_vm.activityList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.activityName, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "是否参与活动：" } }, [
                    _c(
                      "span",
                      { class: _vm.ruleForm.inActivity ? "success" : "danger" },
                      [_vm._v(_vm._s(_vm.ruleForm.inActivity ? "是" : "否"))]
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.onlineCard === 2
            ? _c(
                "el-form-item",
                { attrs: { label: "昂捷卡号：", prop: "chargeCardNo" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { placeholder: "昂捷卡号" },
                    model: {
                      value: _vm.ruleForm.chargeCardNo,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "chargeCardNo", $$v)
                      },
                      expression: "ruleForm.chargeCardNo"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.onlineCard === 1 && _vm.ruleForm.activityId
            ? _c(
                "el-form-item",
                { attrs: { label: "线上卡号：", prop: "chargeCardNo" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { disabled: "", placeholder: "请输入新卡卡号" },
                    model: {
                      value: _vm.ruleForm.chargeCardNo,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "chargeCardNo",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.chargeCardNo"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.getAward }
                    },
                    [_vm._v("\n        选择购物卡")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "recommendSysUserId", attrs: { label: "员工：" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    filterable: "",
                    remote: "",
                    clearable: "",
                    "reserve-keyword": "",
                    placeholder: "请输入手机号码查询",
                    "remote-method": _vm.remoteMethodUser
                  },
                  model: {
                    value: _vm.ruleForm.recommendSysUserId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "recommendSysUserId", $$v)
                    },
                    expression: "ruleForm.recommendSysUserId"
                  }
                },
                _vm._l(_vm.userList, function(item) {
                  return _c("el-option", {
                    key: item.userId,
                    attrs: {
                      label: item.username + "(" + item.mobile + ")",
                      value: item.userId
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "remark" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入备注" },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "remark",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("确认\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }