var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "keyword" },
    [
      _c(
        "w-table",
        {
          ref: "wtable",
          attrs: {
            searchData: _vm.searchData,
            columns: _vm.columns,
            operate: ["dea", "del"]
          },
          on: { goDelData: _vm.onDelete }
        },
        [
          _c("el-table-column", {
            attrs: { slot: "displayArea", label: "显示区域", align: "center" },
            slot: "displayArea",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("displayLabel")(
                            row.displayArea,
                            _vm.areaOptions
                          )
                        ) +
                        "\n            "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { slot: "status", label: "热搜状态", align: "center" },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 2 },
                      on: {
                        change: function($event) {
                          return _vm.onUpdateStatus(row)
                        }
                      },
                      model: {
                        value: row.status,
                        callback: function($$v) {
                          _vm.$set(row, "status", $$v)
                        },
                        expression: "row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "characteristic",
              label: "热搜标识",
              align: "center"
            },
            slot: "characteristic",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("displayLabel")(
                            row.characteristic,
                            _vm.characteristicOptions
                          )
                        ) +
                        "\n            "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }