"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _moment = _interopRequireDefault(require("moment"));

var _utils = require("@/utils");

var _report = require("@/api/report.js");

var _mem = require("@/api/mem.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* import {
     listMemberCards
   } from '@/api/manage.js' */
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      storeList: [],
      storeCode: '',
      memArr: [{
        cardId: 0,
        name: '全部体系'
      }],
      memberId: '',
      pageSize: 10,
      pageNo: 1,
      downloadLoading: false,
      total: 0,
      time: [],
      tableData: [],
      options: [{
        value: -1,
        label: '全部类型'
      }, {
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣券'
      },
      /* {
            value: 3,
            label: '礼品券'
          }, */
      {
        value: 4,
        label: '体验券'
      }],
      couponType: '',
      couponCode: '',
      couponName: '',
      tabTh: [{
        props: 'couponCode',
        tit: '券编码',
        width: 160
      }, {
        props: 'couponName',
        tit: '券名称',
        width: 150
      },
      /* {
             props: 'ReceivedName',
             tit: '领取方式',
             width: 100
           }, */
      {
        props: 'couponType',
        tit: '券类型',
        width: 70
      }, {
        props: 'statusName',
        tit: '状态',
        width: 70
      }, {
        props: 'memberName',
        tit: '会员体系',
        width: 120
      }, {
        props: 'limitDiscount',
        tit: '面额/折扣',
        width: 80
      }, {
        props: 'putNumber',
        tit: '投放数量',
        width: 70
      }, {
        props: 'getNumber',
        tit: '领取数量',
        width: 70
      }, {
        props: 'residueNumber',
        tit: '剩余数量',
        width: 70
      }, {
        props: 'useNumber',
        tit: '核销数量',
        width: 70
      }, {
        props: 'unUserNumber',
        tit: '未核销数量',
        width: 90
      }, {
        props: 'expireNumber',
        tit: '已过期',
        width: 90
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      console.log(res);
      _this.memArr = res.data;
    });
    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.storeList = res.data;
    });
  },
  activated: function activated() {// this.init()
  },
  mounted: function mounted() {
    var today = new Date();
    var lastMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    var lastMonthEndDate = (0, _moment.default)(today).format("YYYY-MM-DD  HH:mm:ss");
    var startDateTime = (0, _moment.default)(lastMonthStartDate).format("YYYY-MM-DD  HH:mm:ss");
    this.time = [startDateTime, lastMonthEndDate];
  },
  methods: {
    setShort: function setShort(e) {
      var _this2 = this;

      this.storeCode = '';
      var companyId = '';
      this.memArr.forEach(function (item) {
        if (item.companyCode == e) {
          companyId = item.companyId;
        }
      });
      (0, _mem.listStore)({
        companyId: companyId,
        pageNo: 1,
        pageSize: 1000
      }).then(function (res) {
        _this2.storeList = res.data;
      });
      this.init({
        limit: 10,
        page: 1
      });
    },
    init: function init(e) {
      var _this3 = this;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        couponType: this.couponType,
        couponCode: this.couponCode.trim(),
        couponName: this.couponName.trim(),
        startDate: this.time[0] || '',
        endDate: this.time[1] || '',
        memberId: this.memberId,
        storeCode: this.storeCode
      };
      console.log(data);
      this.openLoading();
      (0, _report.listCouponNumberPage)(data).then(function (res) {
        _this3.openLoading().close();

        _this3.total = res.data.totalNum;
        _this3.tableData = res.data.items;
        /* this.tableData.forEach((item) => {
             if (item.buyType == 1 && item.price != 0) {
               item.useNumberPice = '￥' + Number(item.useNumber) * Number(item.price)
             } else if (item.buyType == 2 && item.integral != 0) {
               item.useNumberPice = Number(item.useNumber) * Number(item.integral) + '积分'
             } else if (item.buyType == 3 && (item.integral != 0 && item.price != 0)) {
               item.useNumberPice = '￥' + Number(item.useNumber) * Number(item.price) + '+' + Number(item.useNumber) *
                 Number(item.integral) + '积分'
             } else {
               item.useNumberPice = '0'
             }
           }) */
      }).catch(function (res) {
        _this3.openLoading().close();
      });
    },
    remove: function remove() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.couponType = '';
      this.couponCode = '';
      this.couponName = '';
      this.memberId = '';
      this.time = [];
      this.storeCode = '';
      this.init();
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['券编码', '券名称', '券类型', '状态', '会员体系', '面额/折扣', '投放数量', '领取数量', '剩余数量', '核销数量', '未核销数量'];
        var filterVal = ['couponCode', 'couponName', 'couponType', 'statusName', 'memberName', 'limitDiscount', 'putNumber', 'getNumber', 'residueNumber', 'useNumber', 'unUserNumber'];
        var list = [];
        (0, _report.listCouponNumberPage)({
          pageNo: 1,
          pageSize: _this4.total,
          couponType: _this4.couponType,
          couponCode: _this4.couponCode,
          couponName: _this4.couponName,
          memberId: _this4.memberId,
          startDate: _this4.time[0] || '',
          endDate: _this4.time[1] || '',
          storeCode: _this4.storeCode
        }).then(function (res) {
          list = res.data.items;
          list.forEach(function (item) {
            if (item.buyType == 1 && item.price != 0) {
              item.useNumberPice = '￥' + Number(item.useNumber) * Number(item.price);
            } else if (item.buyType == 2 && item.integral != 0) {
              item.useNumberPice = Number(item.useNumber) * Number(item.integral) + '积分';
            } else if (item.buyType == 3 && item.integral != 0 && item.price != 0) {
              item.useNumberPice = '￥' + Number(item.useNumber) * Number(item.price) + '+' + Number(item.useNumber) * Number(item.integral) + '积分';
            } else {
              item.useNumberPice = '0';
            }
          });

          var data = _this4.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '优惠券统计'
          });
          _this4.downloadLoading = false;
        }).catch(function (res) {
          _this4.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;