"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '运费团里',
      list: [{
        tit: '包邮规则',
        txtArr: '包邮规则',
        imgPath: require('@/assets/tab/22.png'),
        path: '/legou/basic/free'
      }, {
        tit: '运费规则',
        txtArr: '商品管理',
        imgPath: require('@/assets/tab/23.png'),
        path: '/legou/basic/delivery'
      }]
    };
  }
};
exports.default = _default;