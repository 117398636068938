"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon");

var _mem = require("@/api/mem.js");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _cardLoad = _interopRequireDefault(require("@/components/addLoad/cardLoad.vue"));

var _detailCtmfield = _interopRequireDefault(require("@/components/addLoad/detailCtmfield.vue"));

var _checkTree = _interopRequireDefault(require("@/components/checkTree"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _index = _interopRequireDefault(require("@/components/phone/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var validateAcquaintance = function validateAcquaintance(rule, value, callback) {
  if (value < 0 || value > 100) {
    callback(new Error('在 0 至 100 %之间'));
  } else {
    callback();
  }
};

var validateNumber = function validateNumber(rule, value, callback) {
  if (/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value) == false) {
    callback(new Error('请填写大于等于0的数字'));
  } else {
    callback();
  }
};

var validateNum = function validateNum(rule, value, callback) {
  if (value != '') {
    if (/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value) == false) {
      callback(new Error('请填写大于0的数字'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

var _default = {
  components: {
    addLoad: _addLoad.default,
    cardLoad: _cardLoad.default,
    detailCtm: _detailCtmfield.default,
    checkTree: _checkTree.default,
    Tinymce: _Tinymce.default,
    phone: _index.default
  },
  data: function data() {
    return {
      listShow: '',
      storeList: [],
      buyType: [{
        label: '免费',
        type: 4
      }, {
        label: '金额',
        type: 1,
        price: ''
      }, {
        label: '积分',
        type: 2,
        integral: ''
      }, {
        label: '金额+积分',
        type: 3,
        price: '',
        integral: ''
      }],
      verificationModelArr: [{
        id: 1,
        name: 'PC收银机'
      }, {
        id: 2,
        name: '手持收银机'
      }, {
        id: 3,
        name: '核销小助手'
      }, {
        id: 4,
        name: '乐品到家'
      }, {
        id: 5,
        name: '乐品扫码购'
      }],
      showStatusArr: [{
        id: 1,
        name: 'MIKA小程序'
      }, {
        id: 2,
        name: '乐品到家'
      }, {
        id: 3,
        name: '乐品小程序'
      }],
      imgSrc: this.$store.state.user.imgSrc,
      detailRollimgShow: false,
      detailCtmfieldShow: false,
      options: [{
        value: 1,
        label: 'A类-集团公司承担'
      }, {
        value: 2,
        label: 'B类-专柜/品牌/商户承担'
      }, {
        value: 3,
        label: 'C类-集团与商户按比例承担'
      }],
      rules: {
        shortTitle: [{
          required: true,
          message: '请填写优惠券短标题'
        }, {
          max: 12,
          message: '长度在 12 个字符内'
        }],
        usableType: [{
          required: true,
          message: '请选择是否可用'
        }],
        mainImage: [{
          required: true,
          message: '请上传优惠券主图'
        }],
        detailRollimg: [{
          required: true,
          message: '请上传优惠券滚动图'
        }],
        costType: [{
          required: true,
          message: '请选择费用出资方'
        }],
        costStoreId: [{
          required: true,
          message: '请选择费用承担门店'
        }],
        ratioCompany: [{
          required: true,
          message: '请输入公司承担比例'
        }, {
          validator: validateAcquaintance,
          message: '公司承担比例为0到100'
        }],
        ratioTenant: [{
          required: true,
          message: '请输入商户承担比例'
        }, {
          validator: validateAcquaintance,
          message: '商户承担比例为0到100'
        }],
        name: [{
          required: true,
          message: '请输入优惠券名称'
        }],
        descrition: [{
          required: true,
          message: '请输入优惠券描述'
        }],
        denomination: [{
          required: true,
          message: '请输入优惠券面额'
        }, {
          validator: validateNum
        }],
        buyType: [{
          required: true,
          message: '请选择销售类型'
        }],
        price: [{
          required: true,
          message: '请输入销售金额'
        }, {
          validator: validateNumber
        }],
        integral: [{
          required: true,
          message: '请输入销售积分'
        }, {
          validator: validateNumber
        }],
        doorsillMoney: [{
          required: true,
          message: '请输入门槛金额'
        }, {
          validator: validateNumber
        }],
        instructions: [{
          required: true,
          message: '请输入使用规则'
        }],
        geDetails: [{
          required: true,
          message: '请输入内容详情'
        }],
        rejectRule: [{
          required: true,
          message: '请选择排斥规则'
        }],
        totalNumber: [{
          required: true,
          message: '请输入投放数量'
        }, {
          validator: validateNumber
        }],
        limitNumber: [{
          required: true,
          message: '请输入限领/限购数量'
        }, {
          validator: validateNumber
        }],
        validTimeType: [{
          required: true,
          message: '请选择优惠券有效期类型'
        }],
        beginDate: [{
          required: true,
          message: '请选择优惠券有效期时间段'
        }],
        validDay: [{
          required: true,
          message: '请输入领取多少天后有效'
        }, {
          validator: validateNumber
        }],
        limitGoods: [{
          required: true,
          message: '请输入限购商品数量'
        }],
        overlayNumber: [{
          required: true,
          message: '请输入叠加数量'
        }, {
          validator: validateNumber
        }],
        verificationModel: [{
          required: true,
          message: '请选择核销方式'
        }],
        showStatus: [{
          required: true,
          message: '请选择显示状态'
        }],
        shareTitle: [{
          required: true,
          message: '请输入分享标题'
        }],
        shareImg: [{
          required: true,
          message: '请上传分享图片'
        }]
      },
      // 输入框
      form: {
        salestypes: [],
        id: '',
        // 优惠券id
        costStoreId: '',
        couponType: '',
        // 优惠券类型
        mainImage: '',
        detailRollimg: [],
        detailCtmfield: [],
        costType: '',
        ratioCompany: '',
        ratioTenant: '',
        name: '',
        // 优惠券名称
        shortTitle: '',
        // 优惠券短标题
        descrition: '',
        // 优惠券描述
        doorsillMoney: '',
        // 门槛金额
        denomination: '',
        // 优惠券面额
        instructions: '',
        // 使用规则
        geDetails: '',
        // 内容详情
        rejectRule: '',
        // 排斥规则
        totalNumber: '',
        // 投放数量
        limitNumber: '',
        // 限领数量
        verificationModel: [],
        // 核销方式
        validTimeType: '',
        // 优惠券有效期  类型
        beginDate: '',
        // 有效期时间段
        validDay: '',
        // 领取多少天后有效
        cCcodes: [],
        usableType: '',
        //	可用类型(1-可用，2-不可用)
        limitGoods: '',
        // 限购商品数量
        overlayNumber: '',
        // 叠加数量
        showStatus: '',
        // 显示状态
        shareTitle: '',
        // 分享标题
        shareImg: '' // 分享图片

      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
    this.form.id = this.$route.query.id || '';
    this.form.couponType = this.$route.query.couponType || '';

    if (this.form.id) {
      (0, _coupon.getCouponById)(this.form.id).then(function (res) {
        var data = res.data;
        data.detailRollimg = JSON.parse(res.data.detailRollimg);
        data.detailCtmfield = data.detailCtmfield ? JSON.parse(data.detailCtmfield) : [], data.verificationModel = JSON.parse(res.data.verificationModel);

        if (data.validTimeType === 1) {
          data.beginDate = [data.validBeginTime, data.validEndTime];
          _this.beginDate = [data.validBeginTime.substring(0, 10), data.validBeginTime.substring(0, 10)];
        } else {
          data.beginDate = '';
        }

        var salestypes = [];
        data.salestypes.forEach(function (item) {
          if (item.listShow) {
            _this.listShow = item.buyType;
          }

          salestypes.push(item.buyType);

          _this.buyType.forEach(function (row) {
            if (item.buyType == row.type) {
              row.price = item.price;
              row.integral = item.integral;
            }
          });
        });
        data.salestypes = salestypes;
        Object.keys(_this.form).forEach(function (item) {
          if (data[item] != 'undefined') {
            _this.form[item] = data[item];
          }
        });
      });
    }
  },
  methods: {
    buTypeChange: function buTypeChange(e) {
      if (e.indexOf(4) != '-1') {
        this.form.salestypes = [4];
        this.listShow = 4;
      } else {
        this.listShow = '';
      }
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var Schema = require('async-validator');

      Schema.warning = function () {};

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var _data;

          /* let data={
               form.salestypes:form.salestypes,
               doorsillMoney:this.obj.doorsillMoney,
               limitNumber:this.obj.limitNumber,
               totalNumber:this.obj.totalNumber,
               denomination:this.obj.denomination,
               detailRollimg:JSON.stringify(detailRollimg),
               detailCtmfield:JSON.stringify(this.detailCtmfield),
               validTimeType:this.obj.validTimeType,
               costType:this.obj.costType,
               couponType:this.obj.couponType,
               descrition:this.obj.descrition,
               geDetails:this.obj.geDetails,
               goodsIds:this.obj.goodsIds,
               institutionIds:this.obj.institutionIds,
               instructions:this.obj.instructions,
               limitGoods:this.obj.limitGoods,
               limitNumber:this.obj.limitNumber,
               mainImage:this.obj.mainImage,
               memberIds:this.obj.memberIds,
               name:this.obj.name,
               overlayNumber:this.obj.overlayNumber,
               ratioCompany:this.obj.ratioCompany,
               ratioTenant:this.obj.ratioTenant,
               rejectRule:this.obj.rejectRule,
               shareImg:this.obj.shareImg,
               shareTitle:this.obj.shareTitle,
               shortTitle:this.obj.shortTitle,
               showStatus:this.obj.showStatus,
               totalNumber:this.obj.totalNumber,
               usableType:this.obj.usableType,
               verificationModel:this.obj.verificationModel
             }
             if(this.obj.couponType === 2){
               data.discount=this.obj.discount
             }
             if(this.obj.couponType === 4){
               data.experienceNumber=this.obj.experienceNumber
             }
             if (this.obj.validTimeType === 1) {
               data.validBeginTime = this.obj.beginDate[0]
               data.validEndTime = this.obj.beginDate[1]
             }else{
               data.validDay=this.obj.validDay
             } */
          var data = (_data = {
            buyType: _this2.form.buyType,
            costType: _this2.form.costType,
            couponType: _this2.form.couponType || 1,
            denomination: _this2.form.denomination,
            descrition: _this2.form.descrition,
            detailRollimg: _this2.form.detailRollimg,
            doorsillMoney: _this2.form.doorsillMoney,
            instructions: _this2.form.instructions,
            integral: _this2.form.integral,
            limitGoods: _this2.form.limitGoods,
            limitNumber: _this2.form.limitNumber,
            totalNumber: _this2.form.totalNumber,
            mainImage: _this2.form.mainImage,
            name: _this2.form.name,
            shortTitle: _this2.form.shortTitle,
            overlayNumber: _this2.form.overlayNumber,
            price: _this2.form.price,
            ratioCompany: _this2.form.ratioCompany,
            ratioTenant: _this2.form.ratioTenant,
            rejectRule: _this2.form.rejectRule,
            shareImg: _this2.form.shareImg,
            shareTitle: _this2.form.shareTitle,
            showStatus: _this2.form.showStatus
          }, (0, _defineProperty2.default)(_data, "shareImg", _this2.form.shareImg), (0, _defineProperty2.default)(_data, "validTimeType", _this2.form.validTimeType), (0, _defineProperty2.default)(_data, "verificationModel", _this2.form.verificationModel), (0, _defineProperty2.default)(_data, "geDetails", _this2.form.geDetails), (0, _defineProperty2.default)(_data, "detailCtmfield", _this2.form.detailCtmfield), _data);

          if (_this2.form.validTimeType == 1) {
            data.validBeginTime = _this2.form.beginDate[0] || '';
            data.validEndTime = _this2.form.beginDate[1] || '';
          } else {
            data.validDay = _this2.form.validDay;
          }

          console.log(data);
          (0, _coupon.addCoupon)(data).then(function (res) {
            console.log(res);
          });
        }
      });
    },
    submit: function submit(type) {
      this.$refs['form'].validateField(type);
    },

    /*
      分享图片
      */
    setSareImg: function setSareImg(e) {
      this.form.shareImg = e;
      this.$refs.form.clearValidate('shareImg');
    },

    /*
      使用规则
      */
    instructionsChange: function instructionsChange(index) {
      this.form.instructions = index;

      if (index) {
        this.$refs.form.clearValidate('instructions');
      }
    },

    /*
      内容详情
       */
    geDetailsChange: function geDetailsChange(e) {
      this.form.geDetails = e;

      if (e) {
        this.$refs.form.clearValidate('geDetails');
      }
    },

    /*
      费用承担
       */
    costTypeChange: function costTypeChange(e) {
      if (e == 1) {
        this.form.ratioCompany = 100;
        this.form.ratioTenant = 0;
      }

      if (e == 2) {
        this.form.ratioCompany = 0;
        this.form.ratioTenant = 100;
      }
    },
    costCompanyInput: function costCompanyInput(e) {
      this.form.ratioTenant = 100 - this.form.ratioCompany;
    },
    costMerchantInput: function costMerchantInput(e) {
      this.form.ratioCompany = 100 - this.form.ratioTenant;
    },

    /*
      自定义字段
      */
    setdetailCtmfield: function setdetailCtmfield(arr) {
      this.detailCtmfieldShow = false;
      this.form.detailCtmfield = arr;
    },

    /*
      优惠券主图
      */
    setCover: function setCover(e) {
      this.form.mainImage = e;
      this.$refs.form.clearValidate('mainImage');
    },

    /*
       优惠券滚动图*/
    addDetailRollimg: function addDetailRollimg() {
      this.detailRollimgShow = true;
    },
    setDetailRollimg: function setDetailRollimg(arr) {
      this.detailRollimgShow = false;
      this.form.detailRollimg = arr;

      if (arr.length > 0) {
        this.$refs.form.clearValidate('detailRollimg');
      }
    }
  }
};
exports.default = _default;