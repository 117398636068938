"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _legouGoods = _interopRequireDefault(require("./components/legou/legouGoods1001.vue"));

var _legouGoods2 = _interopRequireDefault(require("./components/legou/legouGoods1004.vue"));

var _legouGoods3 = _interopRequireDefault(require("./components/legou/legouGoods1005.vue"));

var _legouGoods4 = _interopRequireDefault(require("./components/legou/legouGoods1006.vue"));

var _image = _interopRequireDefault(require("./components/img/image1021.vue"));

var _lavantActivity = _interopRequireDefault(require("./components/lavantActivity1000.vue"));

var _video = _interopRequireDefault(require("./components/video1001.vue"));

var _functionTem = _interopRequireDefault(require("./components/functionTem1000.vue"));

var _functionTem2 = _interopRequireDefault(require("./components/functionTem1002.vue"));

var _product_com = _interopRequireDefault(require("./components/product_com1015.vue"));

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    legouGoods1001: _legouGoods.default,
    legouGoods1004: _legouGoods2.default,
    legouGoods1005: _legouGoods3.default,
    legouGoods1006: _legouGoods4.default,
    image1021: _image.default,
    draggable: _vuedraggable.default,
    lavantActivity1000: _lavantActivity.default,
    video1001: _video.default,
    functionTem1000: _functionTem.default,
    functionTem1002: _functionTem2.default,
    product_com1015: _product_com.default
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      showTemImage: ['coupon1016', 'product1017', 'video1001', 'rotation1017', 'rotation1016', 'functionTem1001', 'functionTem1004', 'image1024'],
      card1: ['card1000', 'card1001', 'card1002', 'card1003', 'card1004', 'card1005'],
      card2: ['card1006', 'card1007', 'card1008', 'card1009', 'card1010', 'card1011', 'card1012'],
      cardNoName: ['card1008', 'card1009', 'card1011', 'card1013', 'card1017'],
      card3: ['card1013', 'card1014', 'card1015', 'card1016', 'card1017', 'card1018', 'card1019'],
      current: 0,
      coupon1: ['coupon1000', 'coupon1002', 'coupon1004', 'coupon1006', 'product1000', 'product1002', 'product1004', 'product1006', 'legouGoods1000'],
      coupon2: ['coupon_com1000', 'coupon_com1001', 'coupon_com1002', 'coupon_com1003', 'coupon_com1004', 'coupon_com1005', 'coupon_com1007', 'coupon_com1008', 'product_com1000', 'product_com1001', 'product_com1002', 'product_com1003', 'product_com1004', 'product_com1005', 'product_com1007', 'product_com1008'],
      couponTitleCenter: ['coupon_com1000', 'coupon_com1001', 'coupon_com1007', 'coupon_com1008', 'product_com1000', 'product_com1001', 'product_com1007', 'product_com1008'],
      boxImg: ['coupon_com1006', 'product_com1006', 'coupon_com1009', 'product_com1009'],
      couponTitleImg1: ['coupon_com1000', 'coupon_com1007', 'product_com1000', 'product_com1007'],
      couponTitleImg2: ['coupon_com1001', 'coupon_com1008', 'product_com1001', 'product_com1008'],
      couponTitleMore: ['coupon_com1004', 'coupon_com1005', 'product_com1004', 'product_com1005'],
      couponFooterMore: ['coupon_com1007', 'coupon_com1008', 'product_com1007', 'product_com1008', 'coupon_com1009', 'product_com1009'],
      rotation: ['rotation1000', 'rotation1001', 'rotation1002', 'rotation1003', 'rotation1004', 'rotation1005', 'rotation1006', 'rotation1007', 'rotation1008', 'rotation1009', 'rotation1010', 'rotation1011'],
      button: ['button1000', 'button1001', 'button1002', 'button1003', 'button1004', 'button1005', 'button1010'],
      items: {
        linkCode: 'coupon',
        linkId: 'CA20090105220936150'
      },
      imgSrc: this.$store.state.user.imgSrc
    };
  },
  mounted: function mounted() {
    var _this = this;

    // 拖拽事件
    this.$dragging.$on('dragged', function (result) {
      // 将排序后的结果重新赋值
      _this.data.moduleAttr.dataItem = result.value.list;
    });
  },
  methods: {
    onMove: function onMove(relatedContext, draggedContext) {
      console.log(relatedContext.relatedContext.list);
    },
    setCount: function setCount(item) {
      this.$emit('setCount', item);
    }
  }
};
exports.default = _default2;