"use strict";

var _interopRequireWildcard = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRightsCategory = createRightsCategory;
exports.deleteLinkCategory = deleteLinkCategory;
exports.deleteMemberLevel = deleteMemberLevel;
exports.deleteRightsCategory = deleteRightsCategory;
exports.deleteRightsIssueRule = deleteRightsIssueRule;
exports.getMemberLevelList = getMemberLevelList;
exports.getPageRightsCategoryList = getPageRightsCategoryList;
exports.getPageRightsIssueRecordList = getPageRightsIssueRecordList;
exports.getPageRightsLinkCategoryList = getPageRightsLinkCategoryList;
exports.getPageRightsUsedRecordList = getPageRightsUsedRecordList;
exports.getPayMethodOfLevel = getPayMethodOfLevel;
exports.getRightsIssueRuleList = getRightsIssueRuleList;
exports.getRightsSetting = getRightsSetting;
exports.linkCategory = linkCategory;
exports.moveRightsCategory = moveRightsCategory;
exports.saveMemberLevelList = saveMemberLevelList;
exports.saveRightsIssueRuleList = saveRightsIssueRuleList;
exports.saveRightsSetting = saveRightsSetting;
exports.setPayMethodOfLevel = setPayMethodOfLevel;
exports.updateRightsCategory = updateRightsCategory;

var _request = _interopRequireDefault(require("@/utils/request"));

var R = _interopRequireWildcard(require("ramda"));

/**
 * 查询权益页面设置
 */
function getRightsSetting() {
  return (0, _request.default)({
    url: '/api/svip/right/showViewSetting',
    method: 'get',
    loading: 'loading'
  });
}
/**
 * 保存权益页面设置
 */


function saveRightsSetting(data) {
  return (0, _request.default)({
    url: '/api/svip/right/showViewSetting',
    method: 'post',
    data: data
  });
}
/**
 * 权益发放规则-列表查询（含分页）
 */


function getRightsIssueRuleList(params) {
  var loading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'loading';
  return (0, _request.default)({
    url: '/api/svip/right/rule',
    method: 'get',
    params: params,
    loading: loading
  });
}
/**
 * 权益发放规则-保存
 */


function saveRightsIssueRuleList(params) {
  return (0, _request.default)({
    url: '/api/svip/right/rule',
    method: 'post',
    data: params
  });
}
/**
 * 权益分类列表查询（分页）
 */


function getPageRightsCategoryList(params) {
  return (0, _request.default)({
    url: '/api/svip/right/category/page',
    method: 'get',
    params: params
  });
}
/**
 * 创建权益分类
 */


function createRightsCategory(data) {
  return (0, _request.default)({
    url: '/api/svip/right/category',
    method: 'post',
    data: data
  });
}
/**
 * 权益分类上移下移
 */


function moveRightsCategory(data) {
  return (0, _request.default)({
    url: '/api/svip/right/category/' + data.id + '/move/' + data.moveType,
    method: 'put',
    data: R.omit(['id', 'moveType'], data)
  });
}
/**
 * 编辑要客分类
 */


function updateRightsCategory(data) {
  return (0, _request.default)({
    url: '/api/svip/right/category/' + data.id,
    method: 'put',
    data: R.omit(['id'], data)
  });
}
/**
 * 删除权益分类
 */


function deleteRightsCategory(params) {
  return (0, _request.default)({
    url: "/api/svip/right/category/" + params.id,
    method: 'delete',
    params: params
  });
}
/**
 * 删除要客等级
 */


function deleteMemberLevel(params) {
  return (0, _request.default)({
    url: "/api/svip/level/" + params.id,
    method: 'delete',
    params: params
  });
}
/**
 * 权益分类-关联项列表查询
 */


function getPageRightsLinkCategoryList(params) {
  return (0, _request.default)({
    url: "/api/svip/right/category/".concat(params.id, "/item/page"),
    method: 'get',
    params: params
  });
}
/**
 * 批量创建关联
 */


function linkCategory(params) {
  return (0, _request.default)({
    url: "/api/svip/right/category/".concat(params.id, "/item/batch"),
    method: 'post',
    data: R.omit(['id'], params).list
  });
}
/**
 * 批量解除关联
 */


function deleteLinkCategory(params) {
  return (0, _request.default)({
    url: "/api/svip/right/category/".concat(params.id, "/item/batch"),
    method: 'delete',
    data: R.omit(['id'], params).list
  });
}
/**
 * 要客等级列表查询
 */


function getMemberLevelList(params) {
  return (0, _request.default)({
    url: "/api/svip/level",
    method: 'get',
    params: params
  });
}
/**
 * 保存要客等级
 */


function saveMemberLevelList(params) {
  return (0, _request.default)({
    url: "/api/svip/level",
    method: 'post',
    data: params
  });
}
/**
 * 权益发放记录
 */


function getPageRightsIssueRecordList(params) {
  return (0, _request.default)({
    url: '/api/svip/right/issue/log/page',
    method: 'get',
    params: params
  });
}
/**
 * 权益使用记录
 */


function getPageRightsUsedRecordList(params) {
  return (0, _request.default)({
    url: '/api/svip/right/usage/log/page',
    method: 'get',
    params: params
  });
}
/**
 * 删除发放规则
 */


function deleteRightsIssueRule(params) {
  return (0, _request.default)({
    url: "/api/svip/right/rule/" + params.id,
    method: 'delete',
    params: params
  });
}
/**
 * 获取要客等级成长相关支付方式
 */


function getPayMethodOfLevel() {
  return (0, _request.default)({
    url: "/api/svip/level/getPayMethodOfLevel",
    method: 'get'
  });
}

function setPayMethodOfLevel(data) {
  return (0, _request.default)({
    url: "/api/svip/level/setPayMethodOfLevel",
    method: 'post',
    data: data
  });
}