"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var storeActivityRouter = {
  path: '/storeActivity',
  component: _layout.default,
  redirect: '/svip/storeActivity/tab',
  name: 'storeActivity',
  meta: {
    title: '信息管理'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tab.vue"));
      });
    },
    name: 'storeActivityTab',
    meta: {
      title: '信息管理'
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./device/list.vue"));
      });
    },
    name: 'storeActivityList',
    meta: {
      activeMenu: '/storeActivity/tab',
      title: '活动列表',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'items',
      pageTotal: 'totalNum',
      api: '/api/org-store/activity/atyPage',
      addUrl: '/storeActivity/add',
      addTxt: "新增活动"
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./device/add.vue"));
      });
    },
    name: 'storeActivityListAdd',
    meta: {
      title: '新增编辑任务',
      activeMenu: '/storeActivity/tab'
    }
  }, {
    path: 'tem',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tem/list.vue"));
      });
    },
    name: 'storeActivityTem',
    meta: {
      activeMenu: '/storeActivity/tab',
      title: '短信模板',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/smsTask/listSmsTemplatePage',
      addUrl: '/storeActivity/temAdd',
      addTxt: "新增模板"
    }
  }, {
    path: 'temAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tem/add.vue"));
      });
    },
    name: 'storeActivityTemAdd',
    meta: {
      title: '新增编辑模板',
      activeMenu: '/storeActivity/tab'
    }
  }, {
    path: 'class',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./class/list.vue"));
      });
    },
    name: 'storeActivityClass',
    meta: {
      activeMenu: '/storeActivity/tab',
      title: '分类管理',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/org-store/activity/ctgPage',
      addUrl: '/storeActivity/classAdd',
      addTxt: "新增分类"
    }
  }, {
    path: 'classAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./class/add.vue"));
      });
    },
    name: 'storeActivityClassAdd',
    meta: {
      title: '新增分类',
      activeMenu: '/storeActivity/tab'
    }
  }, {
    path: 'log',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./channels/list.vue"));
      });
    },
    name: 'storeActivityLog',
    meta: {
      activeMenu: '/storeActivity/tab',
      title: '发送日志',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/smsTask/listSmsSendLogPage'
    }
  }]
};
var _default = storeActivityRouter;
exports.default = _default;