"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));

var _vueQr = _interopRequireDefault(require("vue-qr"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _mem = require("@/api/mem.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default,
    VueQr: _vueQr.default
  },
  data: function data() {
    return {
      qrcodeData: null,
      logo: require('@/assets/mika.png'),
      isAddQrcode: false,
      formData: [{
        label: '门店编码：',
        type: 'input',
        placeholder: '请输入门店编码',
        value: 'storeCode'
      }, {
        label: '合同号：',
        type: 'input',
        placeholder: '请输入合同号',
        value: 'contractNo'
      }],
      ruleForm: {
        contractNo: undefined,
        storeCode: null
      },
      rules: {
        storeCode: [{
          required: true,
          message: '请选择门店'
        }],
        contractNo: [{
          required: true,
          message: '请输入合同号'
        }]
      },
      storeList: [],
      downloadLoading: false,
      requestData: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        couponCode: null,
        couponType: null,
        uname: null,
        umobile: null,
        userMobile: null,
        storeId: null,
        type: null
      },
      tableData: [],
      columns: [{
        label: '券编码',
        prop: 'couponCode',
        width: 100
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 100
      }, {
        label: '券类型',
        prop: 'couponType',
        width: 50
      }, {
        label: '会员手机号',
        prop: 'userMobile',
        width: 50
      }, {
        label: '核销人姓名',
        prop: 'uname',
        width: 80
      }, {
        label: '核销人手机号',
        prop: 'umobile',
        width: 50
      }, {
        label: '门店编码',
        prop: 'storeId',
        width: 50
      }, {
        label: '门店名称',
        prop: 'storeName',
        width: 50
      }, {
        label: '核销时间',
        prop: 'usedTime',
        width: 50
      }]
    };
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.storeList = res.data;
    });
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.apiPost('/api/couponused/createStoreQrCodeSecretData', this.ruleForm).then(function (res) {
        _this2.qrcodeData = res.data;
        /* let qrcode= new QRCode('qrcode', {
              text: res.data,
              width: 200,
              height: 200,
              colorDark: "#333333", //二维码颜色
              colorLight: "#ffffff", //二维码背景色
            })
            console.log(qrcode) */
      });
    },
    getStoreQrcode: function getStoreQrcode() {
      this.isPopQrcode = true;
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];
        var data = {};

        _this3.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this3.requestData).forEach(function (key) {
          if (_this3.requestData[key]) {
            data[key] = _this3.requestData[key];
          }
        });
        data.pageSize = _this3.requestData.total;
        data.pageNo = 1;
        (0, _api.listCouponUsedLogPage)(data).then(function (res) {
          list = res.data.items;

          var data = _this3.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '核销助手核销优惠券'
          });
          _this3.downloadLoading = false;
        }).catch(function (res) {
          _this3.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this4 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.removeObj(this.requestData);
      }

      (0, _api.listCouponUsedLogPage)(this.requestData).then(function (res) {
        if (!_this4.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            if (item.userMobile) {
              item.userMobile = item.userMobile.replace(item.userMobile.substring(3, 7), "****");
            }
          });
        }

        _this4.tableData = res.data.items;
        _this4.requestData.total = res.data.totalNum;
      });
    }
  }
};
exports.default = _default;