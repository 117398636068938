"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  depositEditor: {},
  // 新增/编辑储值卡
  couponEditor: '',
  // 新增/编辑优惠卷
  versionsEditor: '',
  // 新增/编辑终端设备版本号
  promontionEditor: '',
  // 新增/编辑促销类活动
  columnGroupEditor: '' // 新增/编辑栏目组

};
var mutations = {
  SET_COLUMN_GROUP_EDITOR: function SET_COLUMN_GROUP_EDITOR(state, data) {
    state.columnGroupEditor = data;
  },
  SET_DEPOSIT_EDITOR: function SET_DEPOSIT_EDITOR(state, data) {
    state.depositEditor = data;
  },
  SET_COUPON_EDITOR: function SET_COUPON_EDITOR(state, data) {
    state.couponEditor = data;
  },
  SET_VERSIONS_EDITOR: function SET_VERSIONS_EDITOR(state, data) {
    state.versionsEditor = data;
  },
  SET_PROMONTION_EDITOR: function SET_PROMONTION_EDITOR(state, data) {
    state.promontionEditor = data;
  }
};
var actions = {
  setColumnGroupEditor: function setColumnGroupEditor(_ref, data) {
    var commit = _ref.commit;
    commit('SET_COLUMN_GROUP_EDITOR', data);
  },
  setDepositEditor: function setDepositEditor(_ref2, data) {
    var commit = _ref2.commit;
    commit('SET_DEPOSIT_EDITOR', data);
  },
  setCouponEditor: function setCouponEditor(_ref3, data) {
    var commit = _ref3.commit;
    commit('SET_COUPON_EDITOR', data);
  },
  setVersionsEditor: function setVersionsEditor(_ref4, data) {
    var commit = _ref4.commit;
    commit('SET_VERSIONS_EDITOR', data);
  },
  setPromontionEditor: function setPromontionEditor(_ref5, data) {
    var commit = _ref5.commit;
    commit('SET_PROMONTION_EDITOR', data);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;