"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '荔星荟配置',
      list: [{
        tit: '荔星荟主页设置',
        txtArr: '<p>小程序端展示的页面配置</p>',
        imgPath: require('@/assets/tab/setting.png'),
        // path: '/svip/config/home',
        path: '/svip/config/home1'
      }, {
        tit: '荔星荟介绍页设置',
        txtArr: '<p>小程序端展示的页面配置</p>',
        imgPath: require('@/assets/tab/setting.png'),
        path: '/svip/config/intro'
      }, {
        tit: '弹窗设置',
        txtArr: '<p>小程序端展示的页面配置</p>',
        imgPath: require('@/assets/tab/setting.png'),
        path: '/svip/config/modal'
      }, {
        tit: '我的页设置',
        txtArr: '<p>小程序端展示的页面配置</p>',
        imgPath: require('@/assets/tab/setting.png'),
        path: '/svip/config/my'
      }]
    };
  }
};
exports.default = _default;