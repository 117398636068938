var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addCard" }, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "addCard_input" }, [
      _c("div", { staticClass: "input_div" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("span", { staticClass: "list_tit" }, [
                _vm._v("\n            分类名称:\n          ")
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "li_input",
                attrs: { placeholder: "请输入内容", clearable: "" },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "list_tit" }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "addBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.next }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }