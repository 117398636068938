"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      id: null,
      imgSrc: this.$store.state.user.imgSrc,
      equityGoods: null,
      expand: null,

      /*
      查看权益弹窗
      */
      isEquityPop: false,

      /*
      用户权益列表
      */
      equityList: [],
      equityTab: [{
        label: '权益名称',
        prop: 'rightName',
        width: 60
      }, {
        label: '更新时间',
        prop: 'createTime',
        width: 60
      }, {
        label: '权益ID',
        prop: 'rightId',
        width: 60
      }],
      columns: [{
        label: '生日',
        prop: 'birthday',
        width: 60
      }, {
        label: '消费等级',
        prop: 'consumeLevelName',
        width: 60
      }, {
        label: '充值等级',
        prop: 'levelName',
        width: 60
      }, {
        label: '联系电话',
        prop: 'contactNumber',
        width: 60
      }, {
        label: '更新时间',
        prop: 'createTime',
        width: 60
      }, {
        label: '要客经理',
        prop: 'managerName',
        width: 60
      }, {
        label: '用户手机号',
        prop: 'mobile',
        width: 60
      }, {
        label: '用户昵称',
        prop: 'name',
        width: 60
      }, {
        label: '备注',
        prop: 'remark',
        width: 60
      }, {
        label: '可领取权益',
        prop: 'unclaimedRightCount',
        width: 60
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '联系电话',
        value: 'contactNumber'
      }, {
        type: 'input',
        span: 4,
        placeholder: '用户昵称',
        value: 'name'
      }, {
        type: 'input',
        span: 4,
        placeholder: '工号',
        value: 'employeeNo'
      },
      /*  {
        type: 'input',
        span: 4,
        placeholder: '信息内容',
        value: 'content',
      },*/
      {
        type: 'radio',
        span: 4,
        placeholder: '状态',
        value: 'status',

        /*
        1: '待加入',
        2: '已加入',
        3: '已剔除',
        4: '已过期',
        */
        select: [{
          label: '待加入',
          value: 1
        }, {
          label: '已加入',
          value: 2
        }, {
          label: '已剔除',
          value: 3
        }, {
          label: '已过期',
          value: 4
        }]
      }]
    };
  },
  created: function created() {
    var id = this.$route.query.id;
    this.managerId = id;
  },
  mounted: function mounted() {
    this.$refs.wTable.setRequestValue('managerId', this.managerId);
    this.$refs.wTable.setRequestValue('status', 2);
    this.$refs.wTable.getList();
  },
  methods: {
    goExamBox: function goExamBox(row, status) {
      var _this = this;

      this.apiGet('/api/memberRight/pushRightsRemoveById', {
        parm: row.id
      }).then(function (res) {
        _this.getUserEquity(_this.equityData, 1);
      });
      return;
      pushRightsRemoveById({
        parm: row.id
      }).then(function (res) {
        _this.getList();
      });
    },
    handleExpandChange: function handleExpandChange(row) {
      var _this2 = this;

      this.apiPost('/api/svip/right/listUnClaimedRightGift', {
        uid: row.uid,
        rightId: row.rightId,
        levelId: row.levelId
      }).then(function (res) {
        _this2.equityGoods = res.data;

        if (_this2.expand.includes(row.key)) {
          _this2.expand = [];
        } else {
          _this2.expand = [row.key];
        }
      });
    },
    getUserEquity: function getUserEquity(row, type) {
      var _this3 = this;

      /*
      type 1查看权益 2刷新权益
      */
      this.apiGet("/api/svip/right/listUnClaimedRight/".concat(row.uid, "/").concat(row.levelId > row.consumeLevelId ? row.levelId : row.consumeLevelId)).then(function (res) {
        _this3.$refs.wTable.getList();

        if (type == 1) {
          _this3.equityList = res.data;
          _this3.isEquityPop = true;
          _this3.equityData = row;
        }
      });
    },
    getEquityGoods: function getEquityGoods(row) {
      this.wPage("/svip/replace/goods?userRightId=".concat(row.id, "&uid=").concat(row.uid, "&rightId=").concat(row.rightId, "&levelId=").concat(row.levleId));
      this.isEquityPop = false;
      /*   this.apiPost('/api/svip/right/listUnClaimedRightGift',{
           uid:row.uid,
           rightId:row.rightId,
           levelId:row.levelId,
         }).then((res)=>{
           this.equityGoods=res.data
         }) */
    }
  }
};
exports.default = _default;