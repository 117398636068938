"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon");

var _tableList = _interopRequireDefault(require("@/components/tableList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default
  },
  data: function data() {
    return {
      deaPath: '/coupon/coupon4/advertList?id=',
      operation: 2,
      total: 0,
      btn: '管理',
      btnType: 'primary',
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [{
        tit: '广告ID',
        prop: 'code',
        align: 'center'
      }, {
        tit: '广告名称',
        prop: 'name',
        align: 'center'
      }, {
        tit: '创建时间',
        prop: 'createTime',
        sortable: true,
        align: 'center'
      }, {
        tit: '广告位类型',
        prop: 'types',
        align: 'center'
      }],
      list: []
    };
  },
  watch: {},
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.openLoading();
      (0, _coupon.listAdvert)(this.pageNo, this.pageSize).then(function (res) {
        _this.openLoading().close();

        console.log(res);
        _this.list = res.data;

        _this.list.forEach(function (item) {
          if (item.type === 1) {
            item.types = '单张广告';
          } else if (item.type === 2) {
            item.types = '多张广告';
          }
        }); // console.log(this.list)

      }).catch(function () {
        _this.openLoading().close();
      });
    },
    setBtn: function setBtn(scope) {
      console.log(scope);
      this.$router.push('/coupon/coupon4/advertList?id=' + scope.advertId + '&type=' + scope.type);
    }
  }
};
exports.default = _default;