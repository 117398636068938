var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apply" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    " + _vm._s(_vm.$route.meta.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { width: "900px" } },
        [
          _c("div", { staticClass: "group-title" }, [_vm._v("查询会员")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { margin: "20px 0 20px 160px" },
              attrs: { prop: "keywords" }
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "apply-form-code",
                  attrs: { placeholder: "请扫描会员码或输入手机号" },
                  model: {
                    value: _vm.keywords,
                    callback: function($$v) {
                      _vm.keywords = $$v
                    },
                    expression: "keywords"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { slot: "append", type: "primary" },
                      on: { click: _vm.handQueryMember },
                      slot: "append"
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "member-info" },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { column: 2 } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "会员状态" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.memberInfo.status == 1
                                  ? "正常"
                                  : _vm.memberInfo.status == 2
                                  ? "封禁"
                                  : "-"
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "手机号码" } },
                        [_vm._v(_vm._s(_vm.memberInfo.mobile || "-"))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "会员类型" } },
                        [
                          _vm.memberInfo.memberTypes &&
                          _vm.memberInfo.memberTypes.length > 0
                            ? _vm._l(_vm.memberInfo.memberTypes, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    staticStyle: { "margin-right": "5px" },
                                    attrs: { size: "small" }
                                  },
                                  [_vm._v(_vm._s(item))]
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.memberInfo.lxPayStatus == 1
                            ? _c("el-tag", { attrs: { size: "small" } }, [
                                _vm._v("要客会员")
                              ])
                            : _c("span", [_vm._v("-")])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "真实姓名" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.memberInfo.realname || "-") +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "group-title" }, [_vm._v("积分信息")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "applyForm",
              attrs: {
                model: _vm.applyForm,
                rules: _vm.applyRules,
                "label-width": "160px",
                disabled: !_vm.memberInfo.uid,
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "消费门店:", prop: "storeId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width",
                      attrs: { placeholder: "请选择消费门店" },
                      on: { change: _vm.handStoreChange },
                      model: {
                        value: _vm.applyForm.storeId,
                        callback: function($$v) {
                          _vm.$set(_vm.applyForm, "storeId", $$v)
                        },
                        expression: "applyForm.storeId"
                      }
                    },
                    _vm._l(_vm.storeOptions, function(store) {
                      return _c("el-option", {
                        key: store.value,
                        attrs: { value: store.value, label: store.label }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "品牌:", prop: "brandId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width",
                      attrs: { placeholder: "请选择品牌" },
                      model: {
                        value: _vm.applyForm.brandId,
                        callback: function($$v) {
                          _vm.$set(_vm.applyForm, "brandId", $$v)
                        },
                        expression: "applyForm.brandId"
                      }
                    },
                    _vm._l(_vm.brandOptions, function(brand) {
                      return _c("el-option", {
                        key: brand.value,
                        attrs: { value: brand.value, label: brand.label }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: { color: "orangered", "font-size": "13px" }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.ruleDesc) + "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "小票照片:", prop: "uploadImages" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadConfig.url,
                        headers: _vm.uploadConfig.headers,
                        "on-success": _vm.handUploadSuccess,
                        "before-upload": _vm.handUploadBefore,
                        "on-remove": _vm.handleUploadRemove,
                        "file-list": _vm.applyForm.uploadImages,
                        "list-type": "picture-card"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "file",
                          fn: function(ref) {
                            var file = ref.file
                            return _c("div", {}, [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: { src: file.url, alt: "" }
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-preview",
                                      on: {
                                        click: function($event) {
                                          return _vm.handlePicturePreview(file)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-zoom-in"
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                      on: {
                                        click: function($event) {
                                          return _vm.handleUploadRemove(file)
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  )
                                ]
                              )
                            ])
                          }
                        }
                      ])
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default"
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "相关单据号:", prop: "code" } },
                [
                  _c("el-input", {
                    staticClass: "width",
                    attrs: { placeholder: "请输入相关单据号" },
                    model: {
                      value: _vm.applyForm.code,
                      callback: function($$v) {
                        _vm.$set(_vm.applyForm, "code", $$v)
                      },
                      expression: "applyForm.code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "申请备注:", prop: "remark" } },
                [
                  _c("el-input", {
                    staticClass: "width",
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      placeholder: "请输入申请备注"
                    },
                    model: {
                      value: _vm.applyForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.applyForm, "remark", $$v)
                      },
                      expression: "applyForm.remark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100px" },
                      on: { click: _vm.hanCancel }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.hanConfirm }
                    },
                    [_vm._v("确认申请")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showViewer
        ? _c("image-viewer", {
            attrs: {
              "on-close": function() {
                _vm.showViewer = false
              },
              "url-list": _vm.viewerImageList
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }