var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("新增/编辑会员等级")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            rules: _vm.rules,
            size: "small",
            model: _vm.form,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "sellAmount",
                        label: "会员等级名称：",
                        required: ""
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%", "max-width": "300px" },
                        attrs: { size: "small" },
                        model: {
                          value: _vm.form.sellAmount,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sellAmount", $$v)
                          },
                          expression: "form.sellAmount"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: { prop: "czNum", label: "成长值：", required: "" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%", "max-width": "300px" },
                        attrs: { size: "small" },
                        model: {
                          value: _vm.form.czNum,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "czNum", $$v)
                          },
                          expression: "form.czNum"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: { prop: "bjNum", label: "保级值：", required: "" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%", "max-width": "300px" },
                        attrs: { size: "small" },
                        model: {
                          value: _vm.form.bjNum,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "bjNum", $$v)
                          },
                          expression: "form.bjNum"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "mainImage",
                        label: "会员卡图片：",
                        required: ""
                      }
                    },
                    [
                      _c("add-load", {
                        staticClass: "img_list",
                        attrs: {
                          width: 233,
                          height: 140,
                          cover: _vm.form.mainImage
                        },
                        on: { setCover: _vm.setCover }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { margin: "30px 0 0 150px" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("form")
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }