"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      title: '新增领券投放方案',
      orientObj: {},
      putNumber: '',
      putNumbers: '',
      couponId: []
    };
  },
  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.id = Number(this.$route.query.unid);

      if (this.id) {
        this.title = '编辑领券投放方案';
      }

      this.orientObj = JSON.parse(sessionStorage.getItem('orientObj'));
      console.log(this.orientObj);
      this.couponId = this.orientObj.couponId;
      (0, _coupon.getCouponSurplusNumber)({
        couponCode: this.couponId[0].uuid
      }).then(function (res) {
        _this.putNumbers = res.data;
      });
    },
    next: function next() {
      var _this2 = this;

      if (this.putNumber <= 0) {
        this.$message.error('投放数量必须大于0');
        return;
      } else if (this.putNumber.length > 11) {
        this.$message.error('投放数量长度不能超过11位');
        return;
      } else {
        this.$confirm('此操作将保存方案, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          var obj = _this2.orientObj;
          obj.couponId = _this2.couponId[0].uuid;
          obj.putTotal = _this2.putNumber;

          if (_this2.id) {
            (0, _coupon.editPutCenter)(obj).then(function (res) {
              console.log(res);

              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.push('/coupon/couponPut/putDea?id=volume&unid=' + _this2.id);
            });
          } else {
            (0, _coupon.addPutCenter)(obj).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });

              _this2.$router.push('/coupon/couponPut/volumeCenter');
            });
          }
        }).catch(function () {
          _this2.$message({
            type: 'info',
            message: '已取消保存'
          });
        });
      }
    }
  }
};
exports.default = _default;