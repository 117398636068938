var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 编辑礼品")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "140px",
            size: "small"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "礼品类型：", prop: "giftType" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "maxWidth",
                  attrs: {
                    disabled: _vm.id != undefined,
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.ruleForm.giftType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "giftType", $$v)
                    },
                    expression: "ruleForm.giftType"
                  }
                },
                _vm._l(_vm.cardList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.typeName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属总部：", prop: "companyCode" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "maxWidth",
                  attrs: {
                    disabled: _vm.id != undefined,
                    filterable: "",
                    placeholder: "请选择"
                  },
                  on: { change: _vm.setShort },
                  model: {
                    value: _vm.ruleForm.companyCode,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "companyCode", $$v)
                    },
                    expression: "ruleForm.companyCode"
                  }
                },
                _vm._l(_vm.companyList, function(item) {
                  return _c("el-option", {
                    key: item.companyCode,
                    attrs: { label: item.shortName, value: item.companyCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属门店：", prop: "storeCode" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "maxWidth",
                  attrs: {
                    disabled: _vm.id != undefined,
                    filterable: "",
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.ruleForm.storeCode,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "storeCode", $$v)
                    },
                    expression: "ruleForm.storeCode"
                  }
                },
                _vm._l(_vm.storeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.storeId,
                      attrs: {
                        label: item.shortName + "（" + item.storeCode + "）",
                        value: item.storeCode
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "礼品名称：", prop: "giftName" } },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                attrs: { disabled: _vm.id != undefined },
                model: {
                  value: _vm.ruleForm.giftName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "giftName", $$v)
                  },
                  expression: "ruleForm.giftName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "礼品价值：", prop: "giftWorth" } },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                attrs: { disabled: _vm.id != undefined, type: "number" },
                model: {
                  value: _vm.ruleForm.giftWorth,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "giftWorth", $$v)
                  },
                  expression: "ruleForm.giftWorth"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "进货价格：", prop: "purchasingPrice" } },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                attrs: { disabled: _vm.id != undefined, type: "number" },
                model: {
                  value: _vm.ruleForm.purchasingPrice,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "purchasingPrice", $$v)
                  },
                  expression: "ruleForm.purchasingPrice"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "积分价格：", prop: "scorePrice" } },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                attrs: { disabled: _vm.id != undefined, type: "number" },
                model: {
                  value: _vm.ruleForm.scorePrice,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "scorePrice", $$v)
                  },
                  expression: "ruleForm.scorePrice"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "365积分价格：", prop: "discountScore" } },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                attrs: { disabled: _vm.id != undefined, type: "number" },
                model: {
                  value: _vm.ruleForm.discountScore,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "discountScore", $$v)
                  },
                  expression: "ruleForm.discountScore"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.id
            ? _c(
                "el-form-item",
                { attrs: { label: "当前库存" } },
                [
                  _c("el-input", {
                    staticClass: "maxWidth",
                    attrs: { disabled: "", type: "number" },
                    model: {
                      value: _vm.stockNumber,
                      callback: function($$v) {
                        _vm.stockNumber = $$v
                      },
                      expression: "stockNumber"
                    }
                  })
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "入库数量：", prop: "stockNumber" } },
                [
                  _c("el-input", {
                    staticClass: "maxWidth",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.stockNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "stockNumber", $$v)
                      },
                      expression: "ruleForm.stockNumber"
                    }
                  })
                ],
                1
              ),
          _vm._v(" "),
          _vm.id
            ? _c(
                "el-form-item",
                { attrs: { label: "库存调整类型：", prop: "stockStatus" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.statusChage },
                      model: {
                        value: _vm.ruleForm.stockStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "stockStatus", $$v)
                        },
                        expression: "ruleForm.stockStatus"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("调入")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("调出")]),
                      _vm._v(" "),
                      _vm.setMenuPerms("gift:editVipScore")
                        ? _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("修改365积分价格")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.id &&
          (_vm.ruleForm.stockStatus == 2 || _vm.ruleForm.stockStatus == 3)
            ? _c(
                "el-form-item",
                { attrs: { label: "调整数量：", prop: "stockNumber" } },
                [
                  _c("el-input", {
                    staticClass: "maxWidth",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.stockNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "stockNumber", $$v)
                      },
                      expression: "ruleForm.stockNumber"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.id && _vm.ruleForm.stockStatus == 4
            ? _c(
                "el-form-item",
                { attrs: { label: "365积分价格：", prop: "discountScore" } },
                [
                  _c("el-input", {
                    staticClass: "maxWidth",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.discountScore,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "discountScore", $$v)
                      },
                      expression: "ruleForm.discountScore"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.id != undefined ? "调整备注：" : "入库备注：",
                prop: "oprRemark"
              }
            },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 },
                  placeholder: "请输入内容"
                },
                model: {
                  value: _vm.ruleForm.oprRemark,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "oprRemark", $$v)
                  },
                  expression: "ruleForm.oprRemark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "礼品状态：", prop: "giftStatus" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.giftStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "giftStatus", $$v)
                    },
                    expression: "ruleForm.giftStatus"
                  }
                },
                [_vm._v("启用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.ruleForm.giftStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "giftStatus", $$v)
                    },
                    expression: "ruleForm.giftStatus"
                  }
                },
                [_vm._v("禁用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.setMenuPerms("gift:editStockNumber") ||
          _vm.setMenuPerms("gift:add") ||
          _vm.setMenuPerms("gift:editVipScore")
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.id != undefined ? "保存" : "立即创建") +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.tableShow
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "40px" },
              attrs: {
                size: "mini",
                data: _vm.list,
                border: "",
                "header-cell-class-name": "table_header_cell"
              }
            },
            [
              _c("el-table-column", {
                attrs: { width: "80", align: "center", label: "序号" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                (_vm.pageNo - 1) * _vm.pageSize +
                                  scope.$index +
                                  1
                              ) +
                              "\n      "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  317802593
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "调整类型",
                  align: "center",
                  "min-width": "100"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.stockStatus == 1
                            ? _c("span", [_vm._v("初始化入库")])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.stockStatus == 2
                            ? _c("span", [_vm._v("调入")])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.stockStatus == 3
                            ? _c("span", [_vm._v("调出")])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.stockStatus == 3
                            ? _c("span", [_vm._v("调整365积分价格")])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2842737695
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.tabTh, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    align: "center",
                    "min-width": item.width
                  }
                })
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: { total: _vm.total, page: _vm.pageNo, limit: _vm.pageSize },
        on: {
          "update:page": function($event) {
            _vm.pageNo = $event
          },
          "update:limit": function($event) {
            _vm.pageSize = $event
          },
          pagination: _vm.init
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }