"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '标签名称',
        prop: 'categoryName',
        width: 80
      }, {
        label: '更新时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'showType',
        label: '是否小程序显示',
        prop: 'showType',
        width: 80
      }, {
        slot: 'operate'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '请输入标签名称',
        value: 'categoryName'
      }]
    };
  },
  methods: {}
};
exports.default = _default;