"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dlmList = dlmList;
exports.goodsAdd = goodsAdd;
exports.goodsDea = goodsDea;
exports.goodsEdit = goodsEdit;
exports.goodsUpdown = goodsUpdown;
exports.list = list;
exports.sourceAll = sourceAll;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
大罗马商品
 */
function dlmList(data) {
  return (0, _request.default)({
    url: '/product/dlmList/1',
    loading: 'loading',
    method: 'get'
  });
}
/*
 6、查询商品
 Get请求
 url  /product/list ?productName=yifuadinik

 */


function list(data) {
  return (0, _request.default)({
    url: '/product/list',
    method: 'post',
    data: data
  });
}

function goodsDea(data) {
  return (0, _request.default)({
    url: '/product/code/' + data,
    loading: 'loading',
    method: 'get'
  });
}

function goodsAdd(data) {
  return (0, _request.default)({
    url: '/product/create',
    method: 'post',
    data: data
  });
}

function goodsEdit(data) {
  return (0, _request.default)({
    url: '/product/edit',
    method: 'post',
    data: data
  });
}

function sourceAll(data) {
  return (0, _request.default)({
    url: '/mall/product/source/all',
    method: 'get',
    loading: 'loading',
    data: data
  });
}
/*

https://admin-api.starli.com.cn/product/updown
 {
     "id": 31,
     "onShel": 1   ##0下架 1上架
 }
 */


function goodsUpdown(data) {
  return (0, _request.default)({
    url: '/product/updown',
    method: 'post',
    data: data
  });
}