"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _label = require("@/api/label.js");

var _table = _interopRequireDefault(require("@/components/tableList/table.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tables: _table.default
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      obj: {
        pageNo: 1,
        pageSize: 10,
        labelName: '',
        classId: '',
        labelStatus: ''
      },
      listLabel: [],
      tabTh: [{
        prop: 'classId',
        label: '标签ID',
        width: 4
      }, {
        prop: 'labelName',
        label: '标签名称',
        width: 8
      }, {
        prop: 'className',
        label: '标签分类',
        width: 8
      }, {
        prop: 'labelStatusText',
        label: '状态',
        width: 4
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _label.listLabelClass)().then(function (res) {
      _this.listLabel = res.data;
    });
    this.init();
  },
  methods: {
    setTab: function setTab(row, index) {
      this.$router.push('/label/content/labelAdd?id=' + row.classId);
    },
    init: function init(type) {
      var _this2 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
      }

      if (type == 'remove') {
        this.obj.pageNo = 1;
        this.obj.labelName = '';
        this.obj.classId = '';
        this.obj.labelStatus = '';
      }

      (0, _label.listLabelPage)(this.obj).then(function (res) {
        _this2.total = res.data.totalNum;
        _this2.list = res.data.items;

        _this2.list.forEach(function (item) {
          item.labelStatusText = item.labelStatus == 1 ? '启用' : '禁用';
        });
      });
    },
    goAdd: function goAdd(type) {
      this.$router.push('/label/content/labelAdd');
    }
  }
};
exports.default = _default;