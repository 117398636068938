var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("特殊积分策略")]),
      _vm._v(" "),
      _c("div", { staticClass: "integral" }, [
        _c(
          "div",
          { staticClass: "integral_right" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  rules: _vm.rules,
                  size: "small",
                  model: _vm.form,
                  "label-width": "120px"
                }
              },
              [
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_left" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form_right" }, [
                    _c("div", { staticClass: "attention" }, [
                      _vm._v(
                        "\n              注意：特殊积分策略为最高优先级策略，一旦设置，如遇相同适用范围的基础策略，则基础策略不生效。\n            "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_left" }, [
                    _vm._v("\n            活动名称\n          ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form_right" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          attrs: {
                            prop: "specialName",
                            label: "活动名称：",
                            required: ""
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "400px" },
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.specialName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "specialName", $$v)
                              },
                              expression: "form.specialName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_left" }, [
                    _vm._v("\n            策略类型\n          ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form_right" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          attrs: {
                            label: "积分类型：",
                            required: "",
                            prop: "scoreType"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "radio_integral" },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { width: "60px" },
                                  attrs: { label: 1 },
                                  model: {
                                    value: _vm.form.scoreType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "scoreType", $$v)
                                    },
                                    expression: "form.scoreType"
                                  }
                                },
                                [_vm._v("不积积分")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "radio_input" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _vm._v("在该特殊策略下的指定商品，不积积分")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "radio_integral",
                              staticStyle: { "margin-top": "20px" }
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticStyle: {
                                    width: "60px",
                                    "margin-bottom": "18px"
                                  },
                                  attrs: { label: 2 },
                                  model: {
                                    value: _vm.form.scoreType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "scoreType", $$v)
                                    },
                                    expression: "form.scoreType"
                                  }
                                },
                                [_vm._v("多倍积分")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radio_input" },
                                [
                                  _vm.form.scoreType == 2
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            required: "",
                                            prop: "sellAmount"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "100px" },
                                            model: {
                                              value: _vm.form.sellAmount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "sellAmount",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "form.sellAmount"
                                            }
                                          }),
                                          _vm._v(
                                            "\n                    倍\n                  "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "text",
                                  staticStyle: { "margin-bottom": "18px" }
                                },
                                [_vm._v("此处的数字是指：额外增加的倍数")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radio_integral" },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticStyle: {
                                    width: "60px",
                                    "margin-bottom": "18px"
                                  },
                                  attrs: { label: 3 },
                                  model: {
                                    value: _vm.form.scoreType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "scoreType", $$v)
                                    },
                                    expression: "form.scoreType"
                                  }
                                },
                                [_vm._v("重置积分")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: " flex" },
                                [
                                  _vm.form.scoreType == 3
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            required: "",
                                            prop: "sellAmount"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "100px" },
                                            model: {
                                              value: _vm.form.sellAmount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "sellAmount",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "form.sellAmount"
                                            }
                                          }),
                                          _vm._v(
                                            "\n                    元\n                  "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.form.scoreType == 3
                                    ? _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px"
                                          },
                                          attrs: {
                                            required: "",
                                            prop: "getScore"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "100px" },
                                            model: {
                                              value: _vm.form.getScore,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "getScore",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "form.getScore"
                                            }
                                          }),
                                          _vm._v(
                                            "\n                    积分\n                  "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_left" }, [
                    _vm._v("\n            费用设置\n          ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form_right" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          attrs: {
                            prop: "costType",
                            label: "费用出资方：",
                            required: ""
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "350px" },
                                  attrs: { placeholder: "请选择费用出资方" },
                                  on: { change: _vm.costTypeChange },
                                  model: {
                                    value: _vm.form.costType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "costType", $$v)
                                    },
                                    expression: "form.costType"
                                  }
                                },
                                _vm._l(_vm.options, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "cost" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { required: "", prop: "costCompany" }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "140px" },
                                    attrs: {
                                      type: "number",
                                      disabled: _vm.form.costType != 3
                                    },
                                    on: { input: _vm.costCompanyInput },
                                    model: {
                                      value: _vm.form.costCompany,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "costCompany", $$v)
                                      },
                                      expression: "form.costCompany"
                                    }
                                  }),
                                  _vm._v("%\n                ")
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: { required: "", prop: "costMerchant" }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "140px" },
                                    attrs: {
                                      type: "number",
                                      disabled: _vm.form.costType != 3
                                    },
                                    on: { input: _vm.costMerchantInput },
                                    model: {
                                      value: _vm.form.costMerchant,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "costMerchant", $$v)
                                      },
                                      expression: "form.costMerchant"
                                    }
                                  }),
                                  _vm._v("%\n                ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_left" }, [
                    _vm._v("\n            时间设置\n          ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form_right" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          attrs: {
                            label: "活动有效期：",
                            required: "",
                            prop: "beginDate"
                          }
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期"
                            },
                            model: {
                              value: _vm.form.beginDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "beginDate", $$v)
                              },
                              expression: "form.beginDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_left" }, [
                    _vm._v("\n            适用范围\n          ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "form_right",
                      staticStyle: { padding: "20px 0" }
                    },
                    [
                      _c("integral", {
                        ref: "integralRef",
                        attrs: { form: _vm.form },
                        on: {
                          setcompanyId: _vm.setcompanyId,
                          submitForm: _vm.submit,
                          setStoreId: _vm.setStoreId
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_left" }, [
                    _vm._v("\n            备注\n          ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form_right" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          attrs: { label: "备注：" }
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "100%",
                              "max-width": "400px"
                            },
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 2, maxRows: 8 },
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.form.basicRemark,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "basicRemark", $$v)
                              },
                              expression: "form.basicRemark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_left" }, [
                    _vm._v("\n            状态设置\n          ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "form_right",
                      staticStyle: { "border-bottom": "none" }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          attrs: {
                            prop: "specialStatus",
                            label: "策略状态：",
                            required: ""
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.specialStatus,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "specialStatus", $$v)
                                },
                                expression: "form.specialStatus"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("启用")
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: -1 } }, [
                                _vm._v("禁用")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { margin: "30px 0 0 150px" },
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.submitForm("form")
            }
          }
        },
        [_vm._v("保存")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }