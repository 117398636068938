var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "integralDea" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("基础积分策略详情")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c("el-form-item", { attrs: { label: "状态" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.data.basicStatus == 1 ? "启用" : "禁用") +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c("el-form-item", { attrs: { label: "消费金额" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.data.sellAmount) +
                        "元\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c("el-form-item", { attrs: { label: "获得积分" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.data.getScore) + "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c("el-form-item", { attrs: { label: "会员机构" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: { "max-height": "300px", overflow: "auto" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.institutionIds.join("、")) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.pinleiArr.length > 0
            ? _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    { attrs: { "label-width": "100px" } },
                    [
                      _c("el-form-item", { attrs: { label: "商户" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "max-height": "300px",
                              overflow: "auto"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.pinleiArr.join("、")) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _vm.pinpaiData.length > 0
            ? _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    { attrs: { "label-width": "100px" } },
                    [
                      _c("el-form-item", { attrs: { label: "品类" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "max-height": "300px",
                              overflow: "auto"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.pinpaiData.join("、")) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _vm.multipleSelection.length > 0
                ? _c(
                    "el-form",
                    { attrs: { "label-width": "100px" } },
                    [
                      _c("el-form-item", { attrs: { label: "品牌" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "max-height": "300px",
                              overflow: "auto"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.multipleSelection.join("、")) +
                                "\n          "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.goodsList.length > 0
                        ? _c("el-form-item", { attrs: { label: "商品" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "max-height": "300px",
                                  overflow: "auto"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.goodsList.join("、")) +
                                    "\n          "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c("el-form-item", { attrs: { label: "备注" } }, [
            _vm._v("\n      " + _vm._s(_vm.data.basicRemark) + "\n    ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }