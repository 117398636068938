"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _tableList = _interopRequireDefault(require("@/components/tableList"));

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default
  },
  data: function data() {
    return {
      deaPath: '/coupon/couponPut/putDea?id=orient&unid=',
      operation: 2,
      btn: '查看详情',
      btnType: 'primary',
      total: 0,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [// {
      // 	tit:"方案编码",
      // 	prop:'uuid'
      // },
      {
        tit: '方案名称',
        prop: 'name',
        align: 'center',
        width: 250
      }, {
        tit: '投放时间',
        prop: 'putTime',
        align: 'center',
        width: 200
      }, {
        tit: '创建时间',
        prop: 'createTime',
        align: 'center',
        width: 200
      }, {
        tit: '券包数',
        prop: 'couponBagIds',
        width: 80,
        align: 'center'
      }, {
        tit: '券数',
        prop: 'couponIdsNum',
        width: 80,
        align: 'center'
      }, {
        tit: '券编码',
        prop: 'couponIds',
        width: 80,
        align: 'center'
      },
      /* {
          	tit:"投放数量",
          	prop:'putNumber',
          	width:80
          },*/
      {
        tit: '状态',
        prop: 'status',
        width: 100,
        align: 'center'
      }],
      time: '',
      couponId: '',
      name: '',
      issuer: '',
      options1: [{
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '已审核'
      }, {
        value: 3,
        label: '已撤回'
      }, {
        value: 4,
        label: '已撤销'
      }, {
        value: 6,
        label: '已终止'
      }, {
        value: 9,
        label: '投放完成'
      }],
      status: ''
    };
  },
  watch: {},
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(e) {
      var _this = this;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      this.time = this.time ? this.time : [];
      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        createBeginTime: this.time[0],
        createEndTime: this.time[1],
        status: this.status,
        name: this.name.trim()
      };
      this.openLoading();
      (0, _coupon.listPutOrientationPage)(data).then(function (res) {
        _this.openLoading().close();

        _this.tableData = res.data.records;

        _this.tableData.forEach(function (value) {
          value.couponIdsNum = JSON.parse(value.couponIds).length;
          value.couponBagIds = JSON.parse(value.couponBagIds).length;
          value.putTime = value.putTime; // .substring(0, 10)

          switch (value.status) {
            case 1:
              value.status = '待审核';
              break;

            case 2:
              value.status = '已审核';
              break;

            case 3:
              value.status = '已撤回';
              break;

            case 4:
              value.status = '已撤销';
              break;

            case 5:
              value.status = '已过期';
              break;

            case 6:
              value.status = '已终止';
              break;

            case 8:
              value.status = '投放中';
              break;

            case 9:
              value.status = '投放完成';
              break;
          }
        });

        _this.total = res.data.total;
      }).catch(function (res) {
        _this.openLoading().close();
      });
    },
    setBtn: function setBtn(index) {
      this.$router.push('/coupon/couponPut/putDea?id=orient&unid=' + index.id);
    },
    goAdd: function goAdd() {
      this.$router.push('/coupon/couponPut/addPut?id=orient');
    },
    getList: function getList() {},
    remove: function remove() {
      this.time = [];
      this.name = '';
      this.status = '';
      this.pageNo = 1;
      this.pageSize = 10;
      this.init();
    }
  }
};
exports.default = _default;