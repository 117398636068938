"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

var _regionData = _interopRequireDefault(require("@/utils/regionData.json"));

var _mem = require("@/api/mem.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _vendor = require("@/api/vendor.js");

var _weChat = _interopRequireDefault(require("./components/weChat.vue"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    weChat: _weChat.default,
    addLoad: _addLoad.default,
    couponPop: _radioItem.default
  },
  data: function data() {
    return {
      couponShow: false,
      floorOptions: [],
      disabled: false,
      c_provider: '',
      storeCode: '',
      suppliersShow: false,
      merchantId: '',
      regionData: _regionData.default,
      suppliers: [],
      suppliersTable: [],
      tabTh: [{
        prop: 'supplierName',
        lable: 'ERP供应商名称',
        width: '2'
      }, {
        prop: 'supplierCode',
        lable: 'ERP供应商编码',
        width: '1'
      }, {
        prop: 'contractNo',
        lable: '供应商合同号',
        width: '1'
      }],
      form: {
        outDeptUsers: [],
        merchantDesc: '',
        merchantLogo: '',
        linkPage: null,
        suppliers: [],
        storeId: '',
        companyId: '',
        fullName: '',
        shortName: '',
        longOrLat: '',
        detailAddress: '',
        merchantStatus: '',
        state: [],
        deptName: '',
        deptId: '',
        contactPeople: '',
        contactPhone: '',
        merchantCode: '',
        supplierName: '',
        contractNo: '',
        outId: null,
        activityStatuss: [],
        floorId: undefined,
        positionNum: ''
      },
      companyIdList: [],
      storeList: [],
      storeStatusList: [{
        label: '开业准备',
        value: 1
      }, {
        label: '正常营业',
        value: 2
      }, {
        label: '暂停营业',
        value: 3
      }, {
        label: '作废',
        value: 4
      }],
      rules: {
        userName: [{
          required: true,
          message: '请输入姓名'
        }],
        userMobile: [{
          required: true,
          message: '请输入手机号码'
        }],
        thumbAvatar: [{
          required: true,
          message: '请上传头像'
        }],
        qrCode: [{
          required: true,
          message: '请上传二维码'
        }],
        merchantDesc: [{
          required: true,
          message: '请输入描述'
        }],
        merchantLogo: [{
          required: true,
          message: '请上传商户LOGO'
        }],
        // suppliers:[
        //   {
        //     required: true,
        //     message: '请填写供应商编码信息'
        //   }
        // ],
        merchantCode: [{
          required: true,
          message: '请填写商户编码'
        }],
        contractNo: [{
          required: true,
          message: '请填写供应商合同号'
        }],
        supplierName: [{
          required: true,
          message: '请填写供应商名称'
        }],

        /* merchantCode: [{
          required: true,
          message: '请填写供应商编码'
        }], */
        deptId: [{
          required: true,
          message: '请选择员工信息'
        }],
        contactPeople: [{
          required: true,
          message: '请填写商户负责人'
        }],
        contactPhone: [{
          required: true,
          message: '请填写商户手机号码'
        }, {
          pattern: /^0{0,1}(1)[0-9]{10}$/,
          message: '商户号码格式不对'
        }],
        merchantStatus: [{
          required: true,
          message: '请选择商户状态'
        }],
        longOrLat: [{
          required: true,
          message: '请填写经纬度'
        }],
        state: [{
          required: true,
          message: '请选择门店地址'
        }],
        detailAddress: [{
          required: true,
          message: '请填写详细地址'
        }],
        companyId: [{
          required: true,
          message: '请选择所属总部'
        }],
        storeId: [{
          required: true,
          message: '请选择门店'
        }],
        fullName: [{
          required: true,
          message: '请填写商户全称'
        }],
        shortName: [{
          required: true,
          message: '请填写商户简称'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.merchantId = this.$route.query.merchantId;

    if (this.merchantId) {
      this.openLoading();
      (0, _vendor.getMerchantById)({
        merchantId: this.merchantId
      }).then(function (res) {
        _this.openLoading().close();

        _this.companyIdChange(res.data.companyId, 'created');

        _this.form.storeId = res.data.storeId;
        res.data.suppliers.forEach(function (item) {
          _this.suppliers.push({
            supplierName: item.supplierName,
            supplierCode: item.supplierCode,
            contractNo: item.contractNo,
            goodsCode: JSON.parse(item.goodsCode).join('\n'),
            time: [item.contractStartTime, item.contractEndTime],
            contractStatus: item.contractStatus
          });

          _this.form.suppliers.push({
            supplierName: item.supplierName,
            supplierCode: item.supplierCode,
            contractNo: item.contractNo,
            contractStartTime: item.contractStartTime,
            contractEndTime: item.contractEndTime,
            goodsCode: JSON.parse(item.goodsCode),
            contractStatus: item.contractStatus
          });
        });
        _this.form.companyId = res.data.companyId;
        _this.form.fullName = res.data.fullName;
        _this.form.detailAddress = res.data.detailAddress;
        _this.form.merchantStatus = res.data.merchantStatus;
        _this.form.shortName = res.data.shortName;
        _this.form.state = [res.data.provinceCode, res.data.cityCode, res.data.countyCode];
        _this.form.contactPeople = res.data.contactPeople;
        _this.form.contactPhone = res.data.contactPhone;
        _this.form.longOrLat = res.data.longitude + ',' + res.data.latitude;
        _this.form.deptId = res.data.deptId || '';
        _this.form.deptName = res.data.deptName;
        _this.form.merchantCode = res.data.merchantCode;
        _this.form.merchantLogo = res.data.merchantLogo;
        _this.form.linkPage = res.data.linkPage || '';
        _this.form.merchantDesc = res.data.merchantDesc || '';
        _this.form.activityStatuss = res.data.activityStatuss || [];
        /* this.form.supplierName = res.data.supplierName
         this.form.contractNo = res.data.contractNo */

        _this.form.outId = res.data.outId || '';

        _this.$refs.chatRef.getValue(res.data.deptId);

        _this.form.floorId = res.data.floorId;
        _this.form.positionNum = res.data.positionNum;
        _this.form.outDeptUsers = res.data.outDeptUsers ? JSON.parse(res.data.outDeptUsers) : [];
      }).then(function () {
        _this.getFloorOptions();
      }).catch(function (err) {
        _this.openLoading().close();
      });
    }

    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companyIdList = res.data;
    });
  },
  methods: {
    goAddWxUser: function goAddWxUser() {
      this.$refs.chatRef.setStree();
    },
    setUserCover: function setUserCover(e, index, prop) {
      this.form.outDeptUsers[index][prop] = e;
    },
    changeVendorUser: function changeVendorUser(data) {
      console.log(data);

      if (data && data.length > 0) {
        this.form.outDeptUsers = [].concat((0, _toConsumableArray2.default)(this.form.outDeptUsers), (0, _toConsumableArray2.default)(data));
      }
    },
    delGrade: function delGrade(row, index) {
      this.form.outDeptUsers.splice(index, 1);
    },
    goAddUser: function goAddUser() {
      this.form.outDeptUsers.push({
        name: null,
        mobile: null,
        qrCode: null,
        thumbAvatar: null
      });
    },
    getPageCode: function getPageCode() {
      this.$refs.coupon1.getList('tem');
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      this.form.linkPage = e.pageCode;
      this.couponShow = false;
    },
    getFloorOptions: function getFloorOptions() {
      var _this2 = this;

      (0, _vendor.getFloorOptions)(this.form.storeId).then(function (res) {
        _this2.floorOptions = res.data.floor;
      });
    },
    setCover: function setCover(e) {
      this.form.merchantLogo = e;
    },
    arrayUnique2: function arrayUnique2(arr, name1, name2) {
      var obj = {};
      return arr.reduce(function (item, next, i) {
        if (!obj[arr[i][name1] + '|' + arr[i][name2]]) {
          item.push(arr[i]);
          obj[arr[i][name1] + '|' + arr[i][name2]] = true;
        }

        return item;
      }, []);
    },
    deleteSuppliers: function deleteSuppliers(scope) {
      var _this3 = this;

      var row = scope.row;
      this.suppliers.forEach(function (item, key) {
        if (item.supplierCode == scope.row.supplierCode && item.contractNo == scope.row.contractNo) {
          _this3.suppliers.splice(key, 1);
        }
      });
      this.form.suppliers.splice(scope.$index, 1);
    },
    seachArticle: function seachArticle() {
      var _this4 = this;

      if (this.c_provider && this.storeCode) {
        var data = {
          storeCode: this.storeCode,
          supplierCodes: this.c_provider
        };
        (0, _vendor.getArticle)(data).then(function (res) {
          console.log(res);
          var list = res.data;
          var newTime = new Date().getTime();
          list.forEach(function (item) {
            var timeText = '';
            var date1 = new Date(item.c_st_dt);
            var date2 = new Date(item.c_en_dt);

            if (date1.getTime(date1) <= newTime && newTime <= date2.getTime(date2)) {
              timeText = '有效';
            } else {
              timeText = '无效';
            }

            _this4.suppliers.push({
              supplierName: item.c_name,
              supplierCode: item.c_provider,
              contractNo: item.c_con_no,
              goodsCode: item.c_gcode.split(',').join('\n'),
              time: [item.c_st_dt, item.c_en_dt],
              contractStatus: timeText
            });

            _this4.form.suppliers.push({
              supplierName: item.c_name,
              supplierCode: item.c_provider,
              contractNo: item.c_con_no,
              goodsCode: item.c_gcode.split(','),
              contractStartTime: item.c_st_dt,
              contractEndTime: item.c_en_dt,
              contractStatus: timeText
            });
          });
          _this4.form.suppliers = _this4.arrayUnique2(_this4.form.suppliers, 'supplierCode', 'contractNo');
          _this4.suppliers = _this4.arrayUnique2(_this4.suppliers, 'supplierCode', 'contractNo');
        });
      } else {
        this.$message.error('请填写供应商编码或选择门店后查询');
      }
    },

    /*
      添加供应商列表
      */
    addSuppliers: function addSuppliers() {
      this.suppliers.push({
        supplierName: '',
        supplierCode: '',
        contractNo: '',
        goodsCode: '',
        time: []
      });
    },

    /*
      保存供应商
      */
    saveSuppliers: function saveSuppliers() {
      var _this5 = this;

      this.suppliersTable = [];
      this.form.suppliers = [];
      var newTime = new Date().getTime();
      this.suppliers.forEach(function (item) {
        if (item.supplierName && item.supplierCode && item.contractNo && item.goodsCode && item.time.length > 0) {
          var timeText = '';
          var date1 = new Date(item.time[0]);
          var date2 = new Date(item.time[1]);

          if (date1.getTime(date1) <= newTime && newTime <= date2.getTime(date2)) {
            timeText = '有效';
          } else {
            timeText = '无效';
          }

          var goodsCode = [];
          item.goodsCode.split('\n').forEach(function (row) {
            if (row.trim()) {
              goodsCode.push(row.trim());
            }
          });

          _this5.form.suppliers.push({
            supplierName: item.supplierName,
            supplierCode: item.supplierCode,
            contractNo: item.contractNo,
            contractStartTime: item.time[0],
            contractEndTime: item.time[1],
            goodsCode: item.goodsCode.split('\n'),
            contractStatus: timeText
          });
        }
      });
      /* if(this.suppliersTable.length<this.suppliers.length){
            this.$confirm('此操作将永久只保存信息完善的供应商, 是否继续?', '提示', {
                     confirmButtonText: '确定',
                     cancelButtonText: '取消',
                     type: 'warning'
                   }).then(() => {
                     this.$message({
                       type: 'success',
                       message: '删除成功!'
                     });
                   }).catch(() => {
                     this.$message({
                       type: 'info',
                       message: '已取消删除'
                     });
                   });
         } */

      /* if(this.form.suppliers.length>0){
           this.$refs.form.clearValidate('suppliers');
         } */
      //  this.$refs['form'].validateField('suppliers')

      this.suppliersShow = false;
    },
    setLonOrLat: function setLonOrLat(e) {
      var _this6 = this;

      this.storeList.forEach(function (item) {
        if (item.storeId == e) {
          _this6.storeCode = item.storeCode;

          if (item.longitude && item.latitude && e != 146) {
            _this6.form.longOrLat = item.longitude + ',' + item.latitude;
          }
        }
      });
      this.form.suppliers = [];
      this.suppliers = [];
      this.getFloorOptions(e);
    },
    setValue: function setValue() {
      this.$refs.chatRef.getValue();
    },
    submit: function submit(type) {
      if (type) {
        this.$refs.form.clearValidate('deptId');
      } // this.$refs['form'].validate((valid) => {})

    },
    // 三级地址选择
    regionDataChange: function regionDataChange(e) {
      var arr = this.$refs['state'].getCheckedNodes(false)[0].pathLabels || [];
      this.form.detailAddress = arr.join('');
    },
    companyIdChange: function companyIdChange(e, type) {
      var _this7 = this;

      (0, _mem.listStore)({
        companyId: e
      }).then(function (res) {
        _this7.storeList = res.data;

        if (type != 'created') {
          _this7.form.storeId = '';
        } else {
          _this7.storeList.forEach(function (item) {
            if (item.storeId == _this7.form.storeId) {
              _this7.storeCode = item.storeCode;
              /* if (item.longitude && item.latitude) {
                this.form.longOrLat = item.longitude + ',' + item.latitude
              } */
            }
          });
        }
      });
    },
    submitForm: function submitForm(formName) {
      var _this8 = this;

      this.saveSuppliers(); // this.$refs[formName].validateField('suppliers')

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this8.form.longOrLat.split(',').length != 2) {
            _this8.$message.error('经度纬度，请使用英文的逗号隔开');
          } else {
            var data = {
              // companyId: this.form.companyId,
              storeId: _this8.form.storeId,
              fullName: _this8.form.fullName,
              shortName: _this8.form.shortName,
              provinceCode: _this8.form.state[0],
              cityCode: _this8.form.state[1],
              countyCode: _this8.form.state[2],
              detailAddress: _this8.form.detailAddress,
              longitude: _this8.form.longOrLat.split(',')[0],
              latitude: _this8.form.longOrLat.split(',')[1],
              contactPeople: _this8.form.contactPeople,
              contactPhone: _this8.form.contactPhone,
              merchantStatus: _this8.form.merchantStatus,
              deptName: _this8.form.deptName,
              deptId: _this8.form.deptId,
              suppliers: _this8.form.suppliers,
              outId: _this8.form.outId,
              merchantCode: _this8.form.merchantCode,
              merchantLogo: _this8.form.merchantLogo,
              linkPage: _this8.form.linkPage,
              merchantDesc: _this8.form.merchantDesc,
              activityStatuss: _this8.form.activityStatuss,
              // supplierName: this.form.supplierName,
              // contractNo: this.form.contractNo
              floorId: _this8.form.floorId,
              positionNum: _this8.form.positionNum,
              outDeptUsers: JSON.stringify(_this8.form.outDeptUsers)
            };
            console.log(data);
            _this8.disabled = true;

            if (_this8.merchantId) {
              data.merchantId = _this8.merchantId;
              data.merchantCode = _this8.form.merchantCode; // data.merchantCode = this.form.merchantCode

              console.log(data);
              (0, _vendor.editMerchant)(data).then(function (res) {
                _this8.disabled = false;

                _this8.$message({
                  type: 'success',
                  message: '编辑成功!'
                });

                _this8.$router.go(-1);
              }).catch(function (err) {
                _this8.disabled = false;
              });
            } else {
              (0, _vendor.addMerchant)(data).then(function (res) {
                _this8.disabled = false;

                _this8.$message({
                  type: 'success',
                  message: '保存成功!'
                });

                _this8.$router.go(-1);
              }).catch(function (err) {
                _this8.disabled = false;
              });
            }
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;