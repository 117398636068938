"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _api2 = require("../category/api.js");

var _api3 = require("../class/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      time: null,
      downloadLoading: false,
      categoryList: [],
      classList: [],
      requestData: {
        cardCategoryId: null,
        cardKindsId: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        slot: 'cardImageFront',
        label: '卡面图片正面',
        prop: 'cardImageFront'
      }, {
        slot: 'cardImageBack',
        label: '卡面图片反面',
        prop: 'cardImageBack'
      }, {
        label: '卡号',
        prop: 'cardNo',
        width: 120
      }, {
        label: '卡种名称',
        prop: 'cardKindsName',
        width: 100
      }, {
        label: '卡类型',
        prop: 'cardCategoryName',
        width: 100
      }, {
        label: '卡余额（元）',
        prop: 'currentPrice',
        width: 60
      },
      /* {
                       slot: 'isRebate',
                       label: '返利类型',
                       prop: 'isRebate',
                       width: 100
                     }, */
      {
        label: '激活后有效期（年）',
        prop: 'expireTradeYear',
        width: 80
      }, {
        label: '是否实名',
        prop: 'isRealNameDesc',
        width: 50
      }, {
        label: '是否激活',
        prop: 'isActiveDesc',
        width: 50
      }, {
        label: '入库时间',
        prop: 'inTime',
        width: 120
      }, {
        label: '状态',
        prop: 'statusDesc',
        width: 50
      }, {
        label: '出入库状态',
        prop: 'childStatusDesc',
        width: 80
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(value) {
      if (value) {
        this.requestData.activityStartTime = value[0];
        this.requestData.activityEndTime = value[0];
      } else {
        this.requestData.activityStartTime = null;
        this.requestData.activityEndTime = null;
      }
    }
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {
    var _this = this;

    (0, _api2.categoryPage)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.categoryList = res.data.records;
    });
    (0, _api3.classPage)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.classList = res.data.records;
    });
  },
  methods: {
    goAudit: function goAudit(row) {
      var _this2 = this;

      var _self = this;

      this.$prompt('请输入密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\d{6}$/,
        inputErrorMessage: '请输入六位数字密码'
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _api.resetPassword)({
          id: row.id,
          password: value
        }).then(function (res) {
          _self.$message({
            type: 'success',
            message: '重置成功!'
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this3.requestData[key]) {
          data[key] = _this3.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getList)(data).then(function (res) {
        list = res.data.records;
        _this3.downloadLoading = false;

        _this3.getHandleDownload(tHeader, filterVal, list, '卡分类列表');
      }).catch(function (res) {
        _this3.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this4 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getList)(this.requestData).then(function (res) {
        _this4.tableData = res.data.records;
        _this4.requestData.total = res.data.count;
        return;
      });
    }
  }
};
exports.default = _default;