"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '积分策略',
      list: [{
        tit: '特殊积分策略',
        txtArr: '<p>主要用于促销活动，限定时间、场地、商品的积分规则</p>可设置多倍积分，不积分',
        imgPath: '',
        path: '/integral/specialList'
      }, {
        tit: '条件策略',
        txtArr: '<p>消费金额对应成长值设置</p>    ',
        imgPath: '',
        path: '/integral/conditionIntegral'
      }, {
        tit: '基础策略',
        txtArr: "<p>\u5404\u4E2A\u7B49\u7EA7\u5BF9\u5E94\u7684\u6210\u957F\u503C\uFF0C\u5361\u9762\u7B49\u8BBE\u7F6E<p/>",
        imgPath: '',
        path: '/integral/index'
      }]
    };
  }
};
exports.default = _default;