"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteGoods = deleteGoods;
exports.detailGoods = detailGoods;
exports.goodsSave = goodsSave;

var _request = _interopRequireDefault(require("@/utils/request"));

function goodsSave(data) {
  return (0, _request.default)({
    url: '/api/invoice/limit/save',
    method: 'post',
    data: data
  });
}

function deleteGoods(id) {
  return (0, _request.default)({
    url: '/api/invoice/limit/remove/' + id,
    method: 'delete'
  });
}

function detailGoods(id) {
  return (0, _request.default)({
    url: '/api/invoice/limit/get/' + id,
    method: 'get'
  });
}