"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var groupRouter = {
  path: '/group',
  component: _layout.default,
  redirect: '/group/tab',
  name: 'cardgroup',
  meta: {
    title: '购物团购'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tab.vue"));
      });
    },
    name: 'cardgrouptab',
    meta: {
      title: '购物卡团购'
    }
  }, {
    path: 'dea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./dea.vue"));
      });
    },
    name: 'cardgroupDea',
    meta: {
      title: '购物卡团购详情',
      activeMenu: '/group/tab'
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./list.vue"));
      });
    },
    name: 'cardgroupList',
    meta: {
      title: '购物卡团购订单',
      keepAlive: true,
      icon: 'el-icon-s-cooperation',
      pageList: 'items',
      pageTotal: 'totalNum',
      addTxt: "新增团购",
      addUrl: '/group/add?type=1',
      api: '/api/groupCard/getByPage',
      addLimits: 'cardGroup:add',
      activeMenu: '/group/tab',
      columns: [{
        label: '团购订单',
        prop: 'groupCode',
        width: 80
      }, {
        label: '购买人',
        prop: 'buyUser',
        width: 80
      }, {
        label: '购买人手机号',
        prop: 'buyMobile',
        width: 60
      }, {
        label: '面额',
        prop: 'denomination',
        width: 80
      }, {
        label: '数量',
        prop: 'quantity',
        width: 80
      }, {
        label: '未兑换数量',
        prop: 'remainingQuantity',
        width: 60
      }, {
        label: '订单金额',
        prop: 'totalAmount',
        width: 60
      }, {
        slot: 'status',
        label: '订单状态（1：待审核，2：已审核，3：已驳回）',
        prop: 'status'
      }, {
        slot: 'isRebate',
        label: '返利状态（1：未返利，2：已返利）',
        prop: 'isRebate'
      }, {
        prop: 'type',
        label: '类型（1：团购订单，2：返利订单）',
        slot: 'type'
      }, {
        label: '操作人',
        prop: 'createUserName',
        width: 80
      }, {
        label: '操作时间',
        prop: 'createTime',
        width: 60
      }, {
        label: '备注',
        prop: 'remarks',
        width: 80
      }, {
        slot: 'update'
      }]
    }
  }, {
    path: 'getOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./getOrder.vue"));
      });
    },
    name: 'getOrder',
    meta: {
      title: '团购领取记录',
      keepAlive: true,
      activeMenu: '/group/tab',
      icon: 'el-icon-s-cooperation',
      pageList: 'items',
      pageTotal: 'totalNum',
      api: '/api/groupCard/getExchangeLogPage',
      columns: [{
        label: '订单号',
        prop: 'groupCode',
        width: 80
      }, {
        label: '兑换码',
        prop: 'exchangeCode',
        width: 80
      }, {
        label: '卡名称',
        prop: 'cardName',
        width: 60
      }, {
        label: '卡编码',
        prop: 'uuid',
        width: 60
      }, {
        label: '面额',
        prop: 'denomination',
        width: 60
      }, {
        label: '卡号',
        prop: 'cardNo',
        width: 60
      }, {
        label: '会员姓名',
        prop: 'userName',
        width: 60
      }, {
        label: '会员手机号',
        prop: 'userMobile',
        width: 80
      }, {
        slot: 'status'
      }, {
        slot: 'exchangeType'
      }, {
        label: '兑换时间',
        prop: 'createTime',
        width: 80
      }]
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./add.vue"));
      });
    },
    name: 'cardgroupAdd',
    meta: {
      title: '购物卡团购',
      activeMenu: '/group/tab'
    }
  }, {
    path: 'rebateadd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./rebate/add.vue"));
      });
    },
    name: 'cardgrouprebateadd',
    meta: {
      title: '购物卡团购返利',
      activeMenu: '/group/tab'
    }
  }, {
    path: 'rebate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./rebate/list.vue"));
      });
    },
    name: 'rebateList',
    meta: {
      title: '购物卡返利订单',
      keepAlive: true,
      activeMenu: '/group/tab',
      icon: 'el-icon-s-cooperation',
      pageList: 'items',
      pageTotal: 'totalNum',
      addTxt: "新增返利",
      addUrl: '/group/rebateadd?type=2',
      api: '/api/groupCard/getByPage',
      columns: [{
        label: '返利单号',
        prop: 'groupCode',
        width: 80
      }, {
        label: '销售单号',
        prop: 'oldGroupCodes',
        width: 80
      }, {
        slot: 'totalAmount',
        label: '合计总金额',
        prop: 'totalAmount',
        width: 80
      }, {
        label: '返利比例',
        prop: 'proportion',
        width: 50
      }, {
        label: '返利金额',
        prop: 'totalAmount',
        width: 60
      }, {
        slot: 'status'
      },
      /* {
         slot: 'isRebate'
       }, */
      {
        slot: 'type'
      }, {
        label: '操作人',
        prop: 'createUserName',
        width: 80
      }, {
        label: '操作时间',
        prop: 'createTime',
        width: 60
      }, {
        slot: 'update'
      }]
    }
  }]
};
var _default = groupRouter;
exports.default = _default;