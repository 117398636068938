var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "openInvoice" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _c("span", [_vm._v("资源管理\n    ")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "has",
                rawName: "v-has",
                value: "sysMenu:add",
                expression: "'sysMenu:add'"
              }
            ]
          },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "small",
                  icon: "el-icon-circle-plus-outline"
                },
                on: {
                  click: function($event) {
                    _vm.isUpdate = true
                  }
                }
              },
              [_vm._v("新增资源\n      ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                size: "small",
                "header-row-class-name": "table_th",
                data: _vm.tableData,
                "expand-row-keys": _vm.expand,
                "row-key": "menuId",
                border: "",
                "tree-props": {
                  children: "childMenu",
                  hasChildren: "hasChildren"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "资源名称", "min-width": "300" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  label: "资源类型",
                  align: "center",
                  "min-width": "120"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.type === 0
                          ? _c("span", [_vm._v("目录")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.type === 1
                          ? _c("span", [_vm._v("菜单")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.type === 2
                          ? _c("span", [_vm._v("按钮")])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "url", label: "资源路由", "min-width": "220" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "perms", label: "权限标识", "min-width": "150" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "icon",
                  label: "资源图标",
                  align: "center",
                  "min-width": "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("i", {
                          class: scope.row.icon,
                          staticStyle: { "font-size": "20px" }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "orderNum",
                  label: "资源排序",
                  align: "center",
                  "min-width": "120"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createDate",
                  label: "创建时间",
                  align: "center",
                  "min-width": "200"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  width: "240",
                  fixed: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "sysMenu:edit",
                                expression: "'sysMenu:edit'"
                              }
                            ],
                            staticClass: "btnDea",
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goUppdateItem(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "success",
                            attrs: {
                              icon: "el-icon-circle-plus-outline",
                              type: "text"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goAddClass(scope.row)
                              }
                            }
                          },
                          [_vm._v("添加下级")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认删除此资源？" },
                            on: {
                              confirm: function($event) {
                                return _vm.deleteMenu(scope.row)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "danger",
                                attrs: {
                                  slot: "reference",
                                  icon: "el-icon-delete",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("\n              删除\n            ")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isUpdate
        ? _c(
            "div",
            { staticClass: "fixed_center" },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex_between",
                      staticStyle: { "align-items": "center" },
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", { staticClass: "view_title" }, [
                        _vm._v("新增/编辑分类")
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-close danger",
                        staticStyle: { "font-size": "20px", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            _vm.isUpdate = false
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "WForm",
                    {
                      attrs: {
                        isTitle: false,
                        "form-data": _vm.formData,
                        "label-width": "120px",
                        "rule-form": _vm.ruleForm,
                        rules: _vm.rules,
                        isHttps: ""
                      },
                      on: { submitForm: _vm.submitForm }
                    },
                    [
                      _vm.ruleForm.type == 1
                        ? _c(
                            "div",
                            { attrs: { slot: "url" }, slot: "url" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  ref: "url",
                                  attrs: { label: "资源路径：", prop: "url" }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "form_input",
                                    attrs: { placeholder: "请输入资源路径" },
                                    model: {
                                      value: _vm.ruleForm.url,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "url",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.url"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ruleForm.type == 2
                        ? _c(
                            "div",
                            { attrs: { slot: "perms" }, slot: "perms" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  ref: "perms",
                                  attrs: { label: "权限标识：", prop: "perms" }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "form_input",
                                    attrs: { placeholder: "请输入权限标识" },
                                    model: {
                                      value: _vm.ruleForm.perms,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "perms",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.perms"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ruleForm.type != 2
                        ? _c(
                            "div",
                            { attrs: { slot: "icon" }, slot: "icon" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  ref: "icon",
                                  attrs: { label: "资源图标：", prop: "icon" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex",
                                      staticStyle: { "align-items": "center" }
                                    },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            width: "600",
                                            trigger: "click"
                                          },
                                          model: {
                                            value: _vm.showPop,
                                            callback: function($$v) {
                                              _vm.showPop = $$v
                                            },
                                            expression: "showPop"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "28px",
                                                "max-height": "500px",
                                                overflow: "auto"
                                              }
                                            },
                                            _vm._l(_vm.icons, function(
                                              item,
                                              index
                                            ) {
                                              return _c("i", {
                                                key: index,
                                                class: item.class,
                                                staticStyle: {
                                                  cursor: "pointer",
                                                  margin: "0 6px"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.setIcon(item)
                                                  }
                                                }
                                              })
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "reference",
                                                icon: "el-icon-finished",
                                                size: "mini",
                                                type: "text"
                                              },
                                              slot: "reference"
                                            },
                                            [_vm._v("选择图标")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "primary",
                                        class: _vm.ruleForm.icon,
                                        staticStyle: {
                                          "font-size": "24px",
                                          "margin-left": "20px"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "parentId" }, slot: "parentId" },
                        [
                          _c(
                            "el-form-item",
                            {
                              ref: "parentId",
                              attrs: { label: "父级：", prop: "parentId" }
                            },
                            [
                              _c("el-tree", {
                                ref: "treeForm",
                                staticClass: "li_input form_input",
                                attrs: {
                                  data: _vm.tableData,
                                  props: _vm.treeProps,
                                  "auto-expand-parent": "",
                                  accordion: "",
                                  "check-strictly": "",
                                  "default-checked-keys": [
                                    _vm.ruleForm.parentId
                                  ],
                                  "default-expanded-keys": [
                                    _vm.ruleForm.parentId
                                  ],
                                  "show-checkbox": "",
                                  "node-key": "menuId",
                                  "check-strictly": true,
                                  "expand-on-click-node": false
                                },
                                on: { "check-change": _vm.handleClick }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }