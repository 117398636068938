var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex" },
    [
      _vm._l(_vm.list, function(cover, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "upload-container",
            style: {
              width: _vm.autoUpload ? _vm.width + "px" : "",
              height: _vm.autoUpload ? _vm.height + "px" : ""
            }
          },
          [
            _c(
              "el-upload",
              {
                staticClass: "avatar-uploader",
                style: {
                  width: _vm.autoUpload ? _vm.width + 2 + "px" : "148px",
                  height: _vm.autoUpload ? _vm.height + 2 + "px" : "148px"
                },
                attrs: {
                  "auto-upload": false,
                  accept:
                    ".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF",
                  disabled: _vm.disabled,
                  action: _vm.action,
                  headers: _vm.headers,
                  "show-file-list": !_vm.autoUpload,
                  "list-type": "picture-card",
                  "on-error": _vm.onError,
                  "on-change": function(file) {
                    return _vm.beforeAvatarUpload(file, index)
                  }
                }
              },
              [
                cover
                  ? _c("div", { staticStyle: { position: "relative" } }, [
                      _c("img", {
                        staticClass: "avatar",
                        style: {
                          borderRadius: "6px",
                          width: _vm.autoUpload ? _vm.width + "px" : "148px"
                        },
                        attrs: { src: _vm.imgSrc + cover }
                      }),
                      _vm._v(" "),
                      _vm.isRemove
                        ? _c(
                            "div",
                            {
                              staticClass: "flex_around",
                              staticStyle: {
                                position: "absolute",
                                top: "-10px",
                                right: "-10px"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-delete",
                                staticStyle: {
                                  "font-size": "18px",
                                  color: "red"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.$emit("removeImg")
                                  }
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ])
                  : _c("i", {
                      staticClass: "el-icon-plus avatar-uploader-icon",
                      style: {
                        width: _vm.autoUpload ? _vm.width + "px" : "148px",
                        height: _vm.autoUpload ? _vm.height + "px" : "148px",
                        lineHeight: _vm.autoUpload ? _vm.height + "px" : "148px"
                      }
                    })
              ]
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.list.length < _vm.maxLength
        ? _c(
            "div",
            {
              staticClass: "upload-container",
              style: {
                width: _vm.autoUpload ? _vm.width + "px" : "",
                height: _vm.autoUpload ? _vm.height + "px" : ""
              }
            },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  style: {
                    width: _vm.autoUpload ? _vm.width + 2 + "px" : "148px",
                    height: _vm.autoUpload ? _vm.height + 2 + "px" : "148px"
                  },
                  attrs: {
                    "auto-upload": false,
                    action: _vm.action,
                    headers: _vm.headers,
                    "show-file-list": !_vm.autoUpload,
                    "list-type": "picture-card",
                    "on-error": _vm.onError,
                    "on-change": function(file) {
                      return _vm.beforeAvatarUpload(file, "more")
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus avatar-uploader-icon",
                    style: {
                      width: _vm.autoUpload ? _vm.width + "px" : "148px",
                      height: _vm.autoUpload ? _vm.height + "px" : "148px",
                      lineHeight: _vm.autoUpload ? _vm.height + "px" : "148px"
                    }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }