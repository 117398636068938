"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBankProduct = addBankProduct;
exports.addBankStore = addBankStore;
exports.editBankProduct = editBankProduct;
exports.editBankStore = editBankStore;
exports.getBankProduct = getBankProduct;
exports.getBankStore = getBankStore;
exports.listBankProductPage = listBankProductPage;
exports.listBankProductUserPage = listBankProductUserPage;
exports.listBankStore = listBankStore;
exports.listBankStorePage = listBankStorePage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 1、银行网点分页：/api/bankStore/listBankStorePage
 {"pageNo":"当前页","pageSize":"页条数","storeName":"网点名称","storeStatus":" 网点状态(0：停用 1：启用)"}

 2、银行网点列表：/api/bankStore/listBankStore

 3、银行网点明细：/api/bankStore/getBankStore/{id}

 4、新增银行网点：/api/bankStore/addBankStore
 {"storeName":"网点名称","storeStatus":"网点状态(0：停用 1：启用)","longitude":"经度","latitude":"纬度","detailAddress":"详细地址","contactPeople":"联系人","contactPhone":"联系电话"}

 5、编辑银行网点：/api/bankStore/editBankStore
 {"id":"id","storeName":"网点名称","storeStatus":"网点状态(0：停用 1：启用)","longitude":"经度","latitude":"纬度","detailAddress":"详细地址","contactPeople":"联系人","contactPhone":"联系电话"}

 5、银行理财产品分页：/api/bankProduct/listBankProductPage
 {"pageNo":"当前页","pageSize":"页条数","productName":"产品名称","productStatus":" 产品状态(0：停用 1：启用)"}

 6、 银行理财产品明细：/api/bankProduct/getBankProduct/{id}

 7、新增银行理财产品：/api/bankProduct/addBankProduct
 {"productName":"产品名称","productStatus":" 产品状态(0：停用 1：启用)","applyTime":"申请时间","offlineTime":"下线时间","limitTypes":[限制类型(1：全部 2：365会员 3：plus会员)],"limitConsume":"限制消费额","productImg":"产品图片","productDetail":"产品详情","storeIds":[网点id]]}

 8、编辑银行理财产品：/api/bankProduct/editBankProduct
 {"id":"id","productName":"产品名称","productStatus":" 产品状态(0：停用 1：启用)","applyTime":"申请时间","offlineTime":"下线时间","limitTypes":[限制类型(1：全部 2：365会员 3：plus会员)],"limitConsume":"限制消费额","productImg":"产品图片","productDetail":"产品详情","storeIds":[网点id]]}

 */
function listBankStorePage(data) {
  return (0, _request.default)({
    url: '/api/bankStore/listBankStorePage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listBankStore(data) {
  return (0, _request.default)({
    url: '/api/bankStore/listBankStore',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addBankStore(data) {
  return (0, _request.default)({
    url: '/api/bankStore/addBankStore',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getBankStore(data) {
  return (0, _request.default)({
    url: '/api/bankStore/getBankStore/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function editBankStore(data) {
  return (0, _request.default)({
    url: '/api/bankStore/editBankStore',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listBankProductPage(data) {
  return (0, _request.default)({
    url: '/api/bankProduct/listBankProductPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addBankProduct(data) {
  return (0, _request.default)({
    url: '/api/bankProduct/addBankProduct',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getBankProduct(data) {
  return (0, _request.default)({
    url: '/api/bankProduct/getBankProduct/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function editBankProduct(data) {
  return (0, _request.default)({
    url: '/api/bankProduct/editBankProduct',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/* 
 浦发理财客户信息表
/api/bankProductUser/listBankProductUserPage 
 */


function listBankProductUserPage(data) {
  return (0, _request.default)({
    url: '/api/bankProductUser/listBankProductUserPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}