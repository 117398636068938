"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applySave = applySave;
exports.getMemberByCode = getMemberByCode;
exports.getMemberByMobile = getMemberByMobile;
exports.getStoreBrands = getStoreBrands;
exports.getStores = getStores;

var _request = _interopRequireDefault(require("@/utils/request"));

function getMemberByCode(code) {
  return (0, _request.default)({
    url: "/api/stopPark/queryUserByMemberCode/".concat(code),
    method: 'get'
  });
}

function getMemberByMobile(mobile) {
  return (0, _request.default)({
    url: '/api/mainUser/handleMidUser',
    method: 'post',
    data: {
      mobile: mobile,
      type: '1'
    }
  });
}

function getStores() {
  return (0, _request.default)({
    url: "/api/receiptIntegralAllocation/getStoreList",
    method: 'get'
  });
}

function getStoreBrands(storeId) {
  return (0, _request.default)({
    url: "/api/receiptIntegralAllocation/getStoreBrand/".concat(storeId),
    method: 'get'
  });
}

function applySave(data) {
  return (0, _request.default)({
    url: "/api/payReceiptUpload/addPayReceiptUploadByManager",
    method: 'post',
    data: data
  });
}