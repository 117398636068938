"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addActivity = addActivity;
exports.addActivityClass = addActivityClass;
exports.addAdvertItem = addAdvertItem;
exports.addColumnGroup = addColumnGroup;
exports.addColumnItem = addColumnItem;
exports.addCoupon = addCoupon;
exports.addCouponBag = addCouponBag;
exports.addPullNew = addPullNew;
exports.addPutCenter = addPutCenter;
exports.addPutOrientation = addPutOrientation;
exports.addPutOrientationLabel = addPutOrientationLabel;
exports.addPutTigger = addPutTigger;
exports.addVote = addVote;
exports.addVoteItem = addVoteItem;
exports.couponLogHxPage = couponLogHxPage;
exports.couponRecodesPage = couponRecodesPage;
exports.couponSchemeValid = couponSchemeValid;
exports.delAdvertById = delAdvertById;
exports.delVoteItem = delVoteItem;
exports.editActivity = editActivity;
exports.editActivityClass = editActivityClass;
exports.editActivityOffline = editActivityOffline;
exports.editAdvertItem = editAdvertItem;
exports.editAuditStatus = editAuditStatus;
exports.editColumnGroup = editColumnGroup;
exports.editColumnItem = editColumnItem;
exports.editCoupon = editCoupon;
exports.editCouponAll = editCouponAll;
exports.editCouponBag = editCouponBag;
exports.editCouponBagStatu = editCouponBagStatu;
exports.editCouponGoods = editCouponGoods;
exports.editCouponPortion = editCouponPortion;
exports.editCouponStatu = editCouponStatu;
exports.editPullNew = editPullNew;
exports.editPutCenter = editPutCenter;
exports.editPutCenterStatu = editPutCenterStatu;
exports.editPutOrientation = editPutOrientation;
exports.editPutOrientationLabel = editPutOrientationLabel;
exports.editPutOrientationStatu = editPutOrientationStatu;
exports.editPutTigger = editPutTigger;
exports.editPutTiggerStatu = editPutTiggerStatu;
exports.editTerminationStatus = editTerminationStatus;
exports.editUnOnlineStatus = editUnOnlineStatus;
exports.editVote = editVote;
exports.editVoteAuditStatus = editVoteAuditStatus;
exports.editVoteItem = editVoteItem;
exports.editVoteTerminationStatus = editVoteTerminationStatus;
exports.editVoteUnOnlineStatus = editVoteUnOnlineStatus;
exports.externalCouponWriteOff = externalCouponWriteOff;
exports.getActivityById = getActivityById;
exports.getActivityClassById = getActivityClassById;
exports.getAdvertById = getAdvertById;
exports.getColumnById = getColumnById;
exports.getColumnGroupById = getColumnGroupById;
exports.getColumnItemById = getColumnItemById;
exports.getCouponBagById = getCouponBagById;
exports.getCouponById = getCouponById;
exports.getCouponLogHx = getCouponLogHx;
exports.getCouponSurplusNumber = getCouponSurplusNumber;
exports.getPullnewByBaseId = getPullnewByBaseId;
exports.getPutCenterById = getPutCenterById;
exports.getPutOrientationById = getPutOrientationById;
exports.getPutOrientationLabelById = getPutOrientationLabelById;
exports.getPutTiggerById = getPutTiggerById;
exports.getVoteByBaseId = getVoteByBaseId;
exports.listActivityClass = listActivityClass;
exports.listActivityPage = listActivityPage;
exports.listAdvert = listAdvert;
exports.listAdvertItemByParentId = listAdvertItemByParentId;
exports.listColumnGroup = listColumnGroup;
exports.listColumnGroupPage = listColumnGroupPage;
exports.listColumnItemPage = listColumnItemPage;
exports.listColumnPage = listColumnPage;
exports.listColumnTemplate = listColumnTemplate;
exports.listCouponBagPage = listCouponBagPage;
exports.listCouponPage = listCouponPage;
exports.listPullNewPage = listPullNewPage;
exports.listPutCenterPage = listPutCenterPage;
exports.listPutOrientationLabel = listPutOrientationLabel;
exports.listPutOrientationLabelPage = listPutOrientationLabelPage;
exports.listPutOrientationPage = listPutOrientationPage;
exports.listPutTiggerCdition = listPutTiggerCdition;
exports.listPutTiggerPage = listPutTiggerPage;
exports.listRelevanceAttrInfo = listRelevanceAttrInfo;
exports.listVoteItemNames = listVoteItemNames;
exports.listVoteItemPage = listVoteItemPage;
exports.listVotePage = listVotePage;
exports.produceReceivedCode = produceReceivedCode;
exports.updateCouponOnlineStatus = updateCouponOnlineStatus;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
/api/coupon/editCouponGoods
usableType;   // 可用类型(1-可用，2-不可用)
{"id":id,"goodsIds":["aa","bb"]}

 */
function editCouponGoods(data) {
  return (0, _request.default)({
    url: '/api/coupon/editCouponGoods',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
生成外联券领取编码：/api/coupon/produceReceivedCode
{"couponId":"券id","number":"生成数量"}
 */


function produceReceivedCode(data) {
  return (0, _request.default)({
    url: '/api/coupon/produceReceivedCode',
    method: 'post',
    data: data
  });
}

function listColumnGroupPage(data) {
  // 分页栏目组列表
  return (0, _request.default)({
    url: '/api/columnGroup/listColumnGroupPage',
    method: 'post',
    data: data
  });
}
/* export function listCouponBagPage(data) { // 券包分页
  return request({
    url: '/api/columnGroup/listCouponBagPage',
    method: 'post',
    data
  })
} */


function listColumnGroup(data) {
  // 所有栏目组列表
  return (0, _request.default)({
    url: '/api/columnGroup/listColumnGroup',
    method: 'post',
    data: data
  });
}

function getColumnGroupById(data) {
  // 根据ID查询栏目组
  return (0, _request.default)({
    url: '/api/columnGroup/getColumnGroupById',
    method: 'post',
    params: data
  });
}

function addColumnGroup(data) {
  // 新增栏目组
  return (0, _request.default)({
    url: '/api/columnGroup/addColumnGroup',
    method: 'post',
    data: data
  });
}

function editColumnGroup(data) {
  // 编辑栏目组
  return (0, _request.default)({
    url: '/api/columnGroup/editColumnGroup',
    method: 'post',
    data: data
  });
}

function couponLogHxPage(data) {
  // 核销记录列表
  return (0, _request.default)({
    url: '/api/coupon/couponLogHxPage',
    method: 'post',
    data: data
  });
}

function getCouponLogHx(data) {
  // 根据ID查询核销记录
  return (0, _request.default)({
    url: '/api/coupon/getCouponLogHx',
    method: 'post',
    params: data
  });
}

function addCoupon(data) {
  // 新增线优惠券
  return (0, _request.default)({
    url: '/api/coupon/addCoupon',
    loading: 'loading',
    method: 'post',
    data: data
  });
}

function updateCouponOnlineStatus(data) {
  // 上架下架优惠券
  return (0, _request.default)({
    url: '/api/coupon/updateCouponOnlineStatus',
    method: 'post',
    data: data
  });
}

function getCouponById(id) {
  // 根据ID查询优惠券接口
  var data = {
    id: id
  };
  return (0, _request.default)({
    url: '/api/coupon/getCouponById',
    method: 'post',
    params: data
  });
}

function editCoupon(data) {
  // 编辑优惠券接口
  return (0, _request.default)({
    url: '/api/coupon/editCoupon',
    loading: 'loading',
    method: 'post',
    data: data
  });
}

function editCouponPortion(data) {
  // 编辑优惠券接口  可改状态
  return (0, _request.default)({
    url: '/api/coupon/editCouponPortion',
    method: 'post',
    data: data
  });
}

function editCouponAll(data) {
  // 编辑优惠券接口 可改状态
  return (0, _request.default)({
    url: '/api/coupon/editCouponAll',
    method: 'post',
    data: data
  });
}

function listCouponPage(data) {
  // 分页查询优惠券接口
  return (0, _request.default)({
    url: '/api/coupon/listCouponPage',
    method: 'post',
    data: data
  });
}

function editCouponStatu(id, status) {
  // 分页查询优惠券接口
  var data = {
    id: id,
    status: status
  };
  return (0, _request.default)({
    url: '/api/coupon/editCouponStatu',
    method: 'post',
    data: data
  });
}

function listCouponBagPage(data) {
  // 分页查询优惠券包接口
  //  const data = {
  // 	pageNo:pageNo,
  // 	pageSize:pageSize
  // }
  return (0, _request.default)({
    url: '/api/couponBag/listCouponBagPage',
    method: 'post',
    data: data
  });
}

function addCouponBag(data) {
  // 新增优惠券包接口
  console.log(data);
  return (0, _request.default)({
    url: '/api/couponBag/addCouponBag',
    method: 'post',
    data: data
  });
}

function editCouponBag(data) {
  // 编辑优惠券包接口
  console.log(data);
  return (0, _request.default)({
    url: '/api/couponBag/editCouponBag',
    method: 'post',
    data: data
  });
}

function getCouponBagById(id) {
  // 根据id查询优惠券包接口
  var data = {
    id: id
  };
  return (0, _request.default)({
    url: '/api/couponBag/getCouponBagById',
    method: 'post',
    params: data
  });
}

function couponSchemeValid(couponIdBags, validBeginTime, validEndTime) {
  // 根据id查询优惠券包接口
  var data = {
    couponIdBags: couponIdBags,
    validEndTime: validEndTime,
    validBeginTime: validBeginTime
  };
  console.log(data);
  return (0, _request.default)({
    url: '/api/coupon/couponSchemeValid',
    method: 'post',
    data: data
  });
}

function editCouponBagStatu(id, status) {
  // 编辑优惠券包状态接口
  var data = {
    id: id,
    status: status
  };
  return (0, _request.default)({
    url: '/api/couponBag/editCouponBagStatu',
    method: 'post',
    data: data
  });
}

function listPutOrientationPage(data) {
  // 分页查询定向投放接口
  return (0, _request.default)({
    url: '/api/putOrientation/listPutOrientationPage',
    method: 'post',
    data: data
  });
}

function addPutOrientation(data) {
  // 新增定向投放接口
  return (0, _request.default)({
    url: '/api/putOrientation/addPutOrientation',
    method: 'post',
    data: data
  });
}

function editPutOrientation(data) {
  // 编辑定向投放接口
  return (0, _request.default)({
    url: '/api/putOrientation/editPutOrientation',
    method: 'post',
    data: data
  });
}

function listPutOrientationLabel(obj) {
  // 查询定向投放标签接口
  var data = JSON.stringify(obj);
  return (0, _request.default)({
    url: '/api/putOrientationLabel/listPutOrientationLabel',
    method: 'post',
    data: data
  });
}

function listPutOrientationLabelPage(data) {
  // 分页查询定向投放标签接口
  return (0, _request.default)({
    url: '/api/putOrientationLabel/listPutOrientationLabelPage',
    method: 'post',
    data: data
  });
}

function getPutOrientationLabelById(data) {
  // 查询单条定向投放标签接口
  return (0, _request.default)({
    url: '/api/putOrientationLabel/getPutOrientationLabelById',
    method: 'post',
    params: data
  });
}

function addPutOrientationLabel(data) {
  // 新增定向投放标签接口
  return (0, _request.default)({
    url: '/api/putOrientationLabel/addPutOrientationLabel',
    method: 'post',
    data: data
  });
}

function editPutOrientationLabel(data) {
  // 编辑定向投放标签接口
  return (0, _request.default)({
    url: '/api/putOrientationLabel/editPutOrientationLabel',
    method: 'post',
    data: data
  });
}

function getPutOrientationById(id) {
  // 查询单条定向投放接口
  var data = {
    id: id
  };
  return (0, _request.default)({
    url: '/api/putOrientation/getPutOrientationById',
    method: 'post',
    params: data
  });
}

function editPutOrientationStatu(id, status, oprType) {
  // 编辑定向投放状态接口
  var data = {
    id: id,
    status: status,
    oprType: oprType
  };
  return (0, _request.default)({
    url: '/api/putOrientation/editPutOrientationStatu',
    method: 'post',
    data: data
  });
}

function listPutTiggerPage(data) {
  // 分页查询定向投放接口
  return (0, _request.default)({
    url: '/api/putTigger/listPutTiggerPage',
    method: 'post',
    data: data
  });
}

function addPutTigger(data) {
  // 新增触发投放接口
  // var data=JSON.stringify(obj)
  return (0, _request.default)({
    url: '/api/putTigger/addPutTigger',
    method: 'post',
    data: data
  });
}

function editPutTigger(data) {
  // 编辑触发投放接口
  // var data=JSON.stringify(obj)
  return (0, _request.default)({
    url: '/api/putTigger/editPutTigger',
    method: 'post',
    data: data
  });
}

function getPutTiggerById(id) {
  // 查询单条触发投放接口
  var data = {
    id: id
  };
  return (0, _request.default)({
    url: '/api/putTigger/getPutTiggerById',
    method: 'post',
    params: data
  });
}

function editPutTiggerStatu(id, status, oprType) {
  // 编辑触发投放状态接口
  var data = {
    id: id,
    status: status,
    oprType: oprType
  };
  return (0, _request.default)({
    url: '/api/putTigger/editPutTiggerStatu',
    method: 'post',
    data: data
  });
}

function listPutTiggerCdition(type) {
  // 查询触发投放方案条件接口
  var data = {
    type: type
  };
  return (0, _request.default)({
    url: '/api/putTiggerCdition/listPutTiggerCdition',
    method: 'post',
    params: data
  });
}

function listPutCenterPage(data) {
  // 分页查询领券中心接口
  return (0, _request.default)({
    url: '/api/putCenter/listPutCenterPage',
    method: 'post',
    data: data
  });
}

function getCouponSurplusNumber(data) {
  // 查询优惠券剩余数量
  return (0, _request.default)({
    url: '/api/putCenter/getCouponSurplusNumber',
    method: 'post',
    params: data
  });
}

function addPutCenter(data) {
  // 新增领券中心接口
  return (0, _request.default)({
    url: '/api/putCenter/addPutCenter',
    method: 'post',
    data: data
  });
}

function editPutCenter(data) {
  // 编辑领券中心接口
  return (0, _request.default)({
    url: '/api/putCenter/editPutCenter',
    method: 'post',
    data: data
  });
}

function getPutCenterById(id) {
  // 查询单条领券中心接口
  var data = {
    id: id
  };
  return (0, _request.default)({
    url: '/api/putCenter/getPutCenterById',
    method: 'post',
    params: data
  });
}

function editPutCenterStatu(id, status, oprType) {
  // 编辑领取中心状态接口
  var data = {
    id: id,
    status: status,
    oprType: oprType
  };
  return (0, _request.default)({
    url: '/api/putCenter/editPutCenterStatu',
    method: 'post',
    data: data
  });
}

function listColumnItemPage(data) {
  // 分页查询栏目项 接口
  return (0, _request.default)({
    url: '/api/column/listColumnItemPage',
    method: 'post',
    data: data
  });
}

function listColumnPage(data) {
  // 分页查询栏目接口
  //	 const data = {
  //		pageNo:pageNo,
  //		pageSize:pageSize
  //	}
  return (0, _request.default)({
    url: '/api/column/listColumn',
    method: 'post',
    data: data
  });
}

function listColumnTemplate(data) {
  // 分页查询栏目接口
  return (0, _request.default)({
    url: '/api/column/listColumnTemplate',
    method: 'post',
    data: data
  });
}

function getColumnItemById(columnId) {
  // 查询单条栏目项
  var data = {
    columnId: columnId
  };
  return (0, _request.default)({
    url: '/api/column/getColumnItemById',
    method: 'post',
    params: data
  });
}

function addColumnItem(data) {
  // 新增栏目项
  return (0, _request.default)({
    url: '/api/column/addColumnItem',
    method: 'post',
    data: data
  });
}

function editColumnItem(data) {
  // 编辑栏目项
  return (0, _request.default)({
    url: '/api/column/editColumnItem',
    method: 'post',
    data: data
  });
}

function listAdvert(data) {
  // 查询所有广告位
  return (0, _request.default)({
    url: '/api/advert/listAdvert',
    method: 'post',
    data: data
  });
}

function listAdvertItemByParentId(parentId) {
  // 根据广告位查询广告体列表
  var data = {
    parentId: parentId
  };
  return (0, _request.default)({
    url: '/api/advert/listAdvertItemByParentId',
    method: 'post',
    params: data
  });
}

function getAdvertById(advertId) {
  // 查询单条广告体
  var data = {
    advertId: advertId
  };
  return (0, _request.default)({
    url: '/api/advert/getAdvertById',
    method: 'post',
    params: data
  });
}

function editAdvertItem(data) {
  // 编辑广告体
  return (0, _request.default)({
    url: '/api/advert/editAdvertItem',
    method: 'post',
    data: data
  });
}

function addAdvertItem(data) {
  // 新增广告体
  return (0, _request.default)({
    url: '/api/advert/addAdvertItem',
    method: 'post',
    data: data
  });
}

function delAdvertById(advertId) {
  // 删除广告体
  var data = {
    advertId: advertId
  };
  return (0, _request.default)({
    url: '/api/advert/delAdvertById',
    method: 'post',
    params: data
  });
}

function getColumnById(columnId) {
  // 查询单条领券中心接口
  var data = {
    columnId: columnId
  };
  return (0, _request.default)({
    url: '/api/column/getColumnById',
    method: 'post',
    params: data
  });
}

function addActivity(data) {
  // 新增促销活动
  return (0, _request.default)({
    url: '/api/activity/addActivity',
    method: 'post',
    data: data
  });
}

function editActivity(data) {
  // 编辑促销活动
  return (0, _request.default)({
    url: '/api/activity/editActivity',
    method: 'post',
    data: data
  });
}

function editActivityOffline(data) {
  // 编辑促销活动为下线状态
  return (0, _request.default)({
    url: '/api/activity/editActivityOffline',
    method: 'post',
    params: data
  });
}

function getActivityById(data) {
  // 根据促销活动ID查询活动
  return (0, _request.default)({
    url: '/api/activity/getActivityById',
    method: 'post',
    params: data
  });
}

function listActivityPage(data) {
  // 分页查询促销活动
  return (0, _request.default)({
    url: '/api/activity/listActivityPage',
    method: 'post',
    data: data
  });
}

function addPullNew(data) {
  // 新增拉新活动
  return (0, _request.default)({
    url: '/api/activityPullnew/addPullNew',
    method: 'post',
    data: data
  });
}

function editPullNew(data) {
  // 编辑拉新活动
  return (0, _request.default)({
    url: '/api/activityPullnew/editPullNew',
    method: 'post',
    data: data
  });
}

function editAuditStatus(data) {
  // 编辑拉新活动状态
  return (0, _request.default)({
    url: '/api/activityPullnew/editAuditStatus',
    method: 'post',
    data: data
  });
}

function editUnOnlineStatus(data) {
  // 拉新活动列表下线
  return (0, _request.default)({
    url: '/api/activityPullnew/editUnOnlineStatus',
    method: 'post',
    params: data
  });
}

function editTerminationStatus(data) {
  // 拉新活动列表终止
  return (0, _request.default)({
    url: '/api/activityPullnew/editTerminationStatus',
    method: 'post',
    params: data
  });
}

function listPullNewPage(data) {
  // 拉新活动列表
  return (0, _request.default)({
    url: '/api/activityPullnew/listPullNewPage',
    method: 'post',
    data: data
  });
}

function getPullnewByBaseId(data) {
  // 拉新活动 ID查询
  return (0, _request.default)({
    url: '/api/activityPullnew/getPullnewByBaseId',
    method: 'post',
    params: data
  });
}

function addVote(data) {
  // 新增投票活动
  return (0, _request.default)({
    url: '/api/activityVote/addVote',
    method: 'post',
    data: data
  });
}

function editVote(data) {
  // 编辑投票活动
  return (0, _request.default)({
    url: '/api/activityVote/editVote',
    method: 'post',
    data: data
  });
}

function listVotePage(data) {
  // 投票活动列表
  return (0, _request.default)({
    url: '/api/activityVote/listVotePage',
    method: 'post',
    data: data
  });
}

function getVoteByBaseId(data) {
  // 投票活动id查询
  return (0, _request.default)({
    url: '/api/activityVote/getVoteByBaseId',
    method: 'post',
    params: data
  });
}

function editVoteAuditStatus(data) {
  // 编辑投票活动状态
  return (0, _request.default)({
    url: '/api/activityVote/editAuditStatus',
    method: 'post',
    data: data
  });
}

function listVoteItemNames(data) {
  // 投票组
  return (0, _request.default)({
    url: '/api/activityVoteItem/listVoteItemNames',
    method: 'post',
    params: data
  });
}

function addVoteItem(data) {
  // 添加投票项
  return (0, _request.default)({
    url: '/api/activityVoteItem/addVoteItem',
    method: 'post',
    data: data
  });
}

function editVoteItem(data) {
  // 编辑投票项
  return (0, _request.default)({
    url: '/api/activityVoteItem/editVoteItem',
    method: 'post',
    data: data
  });
}

function delVoteItem(data) {
  // 删除投票项
  return (0, _request.default)({
    url: '/api/activityVoteItem/delVoteItem',
    method: 'post',
    params: data
  });
}

function listVoteItemPage(data) {
  // 投票项分页
  return (0, _request.default)({
    url: '/api/activityVoteItem/listVoteItemPage',
    method: 'post',
    data: data
  });
}

function editVoteUnOnlineStatus(data) {
  // 投票活动列表下线
  return (0, _request.default)({
    url: '/api/activityVote/editUnOnlineStatus',
    method: 'post',
    params: data
  });
}

function editVoteTerminationStatus(data) {
  // 投票活动列表终止
  return (0, _request.default)({
    url: '/api/activityVote/editTerminationStatus',
    method: 'post',
    params: data
  });
}

function listRelevanceAttrInfo(data) {
  // 根据关联属性编码查询关联信息(券、储值卡)
  return (0, _request.default)({
    url: '/api/activity/listRelevanceAttrInfo',
    method: 'post',
    params: data
  });
}

function listActivityClass(data) {
  // 查询所有活动分类
  return (0, _request.default)({
    url: '/api/activityClass/listActivityClass',
    method: 'post',
    params: data
  });
}

function getActivityClassById(data) {
  // 根据ID活动分类
  return (0, _request.default)({
    url: '/api/activityClass/getActivityClassById',
    method: 'post',
    params: data
  });
}

function editActivityClass(data) {
  // 编辑活动分类
  return (0, _request.default)({
    url: '/api/activityClass/editActivityClass',
    method: 'post',
    data: data
  });
}

function addActivityClass(data) {
  // 新增活动分类
  return (0, _request.default)({
    url: '/api/activityClass/addActivityClass',
    method: 'post',
    data: data
  });
} // couponId


function couponRecodesPage(data) {
  // 分页查询外部券券码
  return (0, _request.default)({
    url: '/api/coupon/couponRecodesPage',
    method: 'post',
    data: data
  });
} // couponId id


function externalCouponWriteOff(data) {
  // 外部券批量核销或单个核销
  return (0, _request.default)({
    url: '/api/coupon/externalCouponWriteOff',
    method: 'post',
    data: data
  });
}