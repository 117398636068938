"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '365节省金额兑换积分',
      list: [{
        tit: '关键词',
        txtArr: '365会员关键词',
        imgPath: require('@/assets/tab/14.png'),
        path: '/member/365Key'
      }, {
        tit: '节省金额兑换记录',
        txtArr: '节省金额兑换记录',
        imgPath: require('@/assets/tab/14.png'),
        path: '/member/365ChangeScore'
      }]
    };
  }
};
exports.default = _default;