"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.function.name");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

var _api = require("@/api/api.js");

var _compressorjs = _interopRequireDefault(require("compressorjs"));

var _index = _interopRequireDefault(require("@/components/Tinymce/index.vue"));

var _lists = _interopRequireDefault(require("@/components/addLoad/lists.vue"));

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
@setImg(图片地址，属性值)   上传图片成功后的方法
@submitForm  表单保存验证

*/

/*
  富文本
  */
var _default2 = {
  components: {
    Tinymce: _index.default,
    addImgList: _lists.default
  },
  props: {
    /*
      ruleForm：表单数据
       formData
        表单遍历列表
        slot：插槽使用
        value:表单属性值
        label:表单lable
        placeholder：表单placeholder
        type:
              input：输入框
              inputNumber:number输入框
              radio：单选   selectList：可选参数
              check：多选   selectList：可选参数
              select：下拉框
              startTime：日期时间选择
              timeRange：日期时间段选择  value1：开始时间  value2：结束时间
              tinymce：富文本
              img：图片
       rules 表单验证规则
       */
    isShowCancelBtn: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    btnText: {
      type: String,
      default: function _default() {
        return '保存';
      }
    },
    isHttps: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    disabled: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    isSubmitForm: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    isTitle: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    isSetRules: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    formData: {
      type: Array,
      default: function _default() {
        return {};
      }
    },
    rules: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    ruleForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    labelWidth: {
      type: String,
      default: function _default() {
        return '150px';
      }
    }
  },
  data: function data() {
    return {
      tinymceIndex: 0,

      /*
        路由meta
      */
      imgSrc: this.$store.state.user.imgSrc,
      action: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
      pageMeta: {},
      imgType: null,
      inputValue: null,
      inputVisible: false
    };
  },
  computed: {
    /*
      上传图片headers
      */
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        Token: (0, _auth.getToken)()
      };
    },
    formRules: function formRules() {
      if (this.isSetRules) {
        var rules = {};
        this.formData.forEach(function (item) {
          if (item.isRules !== false) {
            if (item.value1 && item.value2) {
              rules[item.value1] = [{
                required: true,
                message: item.placeholder
              }];
              rules[item.value2] = [{
                required: true,
                message: item.placeholder
              }];
            } else {
              rules[item.value] = [{
                required: true,
                message: item.placeholder
              }];

              if (item.rules) {
                rules[item.value] = [].concat((0, _toConsumableArray2.default)(rules[item.value]), (0, _toConsumableArray2.default)(item.rules));
              }
            }
          }
        });
        return rules;
      }
    }
  },
  created: function created() {
    this.pageMeta = this.$route.meta;
  },
  mounted: function mounted() {},
  methods: {
    setTinymce: function setTinymce() {
      this.tinymceIndex++;
    },
    handleClose: function handleClose(tag, prop) {
      this.ruleForm[prop].splice(this.ruleForm[prop].indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this = this;

      this.inputVisible = true;
      this.$nextTick(function () {
        _this.$refs.saveTagInput[0].$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm(e, prop) {
      var inputValue = this.inputValue;

      if (inputValue && !this.ruleForm[prop].includes(inputValue)) {
        this.ruleForm[prop].push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    changeCascader: function changeCascader(e, prop) {
      var cascaderData = this.$refs.wCascader[0].getCheckedNodes()[0].data;
      this.$emit('changeCascader', cascaderData, prop);
    },
    setDifCover: function setDifCover(e, prop) {
      this.ruleForm[prop] = e;
      this.$refs.ruleForm.validateField(prop);
    },
    removeCover: function removeCover(data, prop) {
      this.ruleForm[prop].splice(data.name, 1);
    },
    setBanner: function setBanner(data, prop) {
      var cover = this.isHttps ? this.imgSrc + data.cover : data.cover;
      this.$set(this.ruleForm[prop], data.index, cover); //this.form.topBanner[index] = e
    },
    setCoverList: function setCoverList(e, type) {
      var cover = this.isHttps ? this.imgSrc + e : e;
      this.ruleForm[type].push(cover);
    },
    changeForm: function changeForm(e, value) {
      this.$emit('changeForm', e, value);
    },
    goValidateField: function goValidateField(value) {
      this.$refs.ruleForm.validateField(value);
    },

    /*
      结束时间不可小于开始时间
      */
    setpickerTime: function setpickerTime(e) {
      var _self = this;

      return {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm[e]).getTime();
          return time.getTime() < validBeginTime;
        }
      };
    },

    /*
      图片上传成功
      */
    handleAvatarSuccess: function handleAvatarSuccess(response) {
      var _this2 = this;

      if (response.ret === 1000) {
        console.log(this.isHttps);

        if (this.isHttps) {
          var img = this.imgSrc + response.data;
          this.ruleForm[this.imgType] = img;
        } else {
          this.ruleForm[this.imgType] = response.data;
        } // this.$emit('setImg', img, this.imgType)

      } else if (response.ret === 6000) {
        this.$confirm(response.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this2.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(response.msg);
      }
    },

    /*
      现在图片上传大小 和格式
      */
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var _this3 = this;

      this.openLoading('努力上传中');
      var isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif' || file.raw.type === 'image/webp';
      var isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.openLoading().close();
        this.$message.error('上传图片只能是 JPG/png 格式!');
        return false;
      }

      if (!isLt2M && this.isLimit) {
        this.openLoading().close();
        this.$message.error('上传图片大小不能超过 2MB!');
        return false;
      }

      var _self = this;

      var width = _self.feilWidth; // 限制图片尺寸为744x208

      var height = _self.feilHeight;
      new Promise(function (resolve, reject) {
        var URL = window.URL || window.webkitURL;
        var img = new Image();

        img.onload = function () {
          if (width > 0 && height > 0) {
            var valid = img.width === width && img.height === height;
            valid ? resolve() : reject();
          } else {
            img.width > 750 ? reject() : resolve();
          }
        };

        img.src = URL.createObjectURL(file.raw);
      }).then(function () {
        var quality = 1;

        if (file.size / 1024 > 500) {
          quality = 0.4;
        } else if (file.size / 1024 > 300) {
          quality = 0.5;
        } else if (file.size / 1024 > 200) {
          quality = 0.6;
        }

        console.log(quality);
        new _compressorjs.default(file.raw, {
          quality: quality,
          // 图片压缩比例，取值范围为0~1
          success: function success(result) {
            console.log(result);
            var formData = new FormData();
            formData.append('file', result, result.name);
            (0, _api.goUploadImg)(formData).then(function (res) {
              // _self.$emit('setCover', res.data)
              if (_self.isHttps) {
                _self.ruleForm[_self.imgType] = _self.imgSrc + res.data;
              } else {
                _self.ruleForm[_self.imgType] = res.data;
              }
            }); // 上传图片请求 示例，使用时替换成自己的上传请求即可
          },
          error: function error(err) {
            console.log(err.message);
          }
        });
      }, function () {
        if (width > 0 && height > 0) {
          _this3.$message.error("\u8BF7\u4E0A\u4F20".concat(_self.feilWidth, "*").concat(_self.feilHeight, "\u7684\u56FE\u7247"));
        } else {
          _this3.$message.error("\u4E0A\u4F20\u7684\u56FE\u7247\u5BBD\u5EA6\u4E0D\u80FD\u5927\u4E8E750");
        }

        _this3.openLoading().close();

        return Promise.reject();
      });
      return;
      /* const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file
        .type === 'image/webp'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M */
    },
    scrollView: function scrollView(e) {
      var dom = this.$refs[e][0];
      console.log(dom);
      return;
      dom.$el.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });
    },

    /*
      验证表单数据
      */
    submitForm: function submitForm(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          _this4.$emit('submitForm');
        } else {
          var data = Object.keys(object);

          if (_this4.$refs[data[0]]) {
            console.log(_this4.$refs[data[0]][0]);
            var dom = _this4.$refs[data[0]][0];
            dom.$el.scrollIntoView({
              block: 'center',
              behavior: 'smooth'
            });
          } else {
            _this4.$emit('goScrollIntoView', data[0]);
          }

          return false;
        }
      });
    }
  }
};
exports.default = _default2;