"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _user = require("@/api/user.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  components: {
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      couponUUid: '',
      popShow: false,
      // 小程序码弹框
      qrCode: '',
      // 小程序码
      url: '',
      // 小程序链接
      columns: [{
        label: '活动名称',
        prop: 'activityName',
        align: "left",
        width: 200
      }, {
        label: '赠送规则（1，依次递增 2，倍数递增）',
        prop: 'giveRule',
        slot: 'giveRule',
        width: 80
      }, {
        label: '赠送基础积分',
        prop: 'giveScore',
        width: 100
      }, {
        label: '参与人群',
        prop: 'labelContent',
        slot: 'labelContent',
        width: 80
      }, {
        label: '黑名单',
        prop: 'labelContentNo',
        slot: 'labelContentNo',
        width: 80
      }, {
        label: '递增分数或倍数',
        prop: 'num',
        width: 120
      }, {
        slot: 'returnType',
        label: '积分退还类型（1，年度退还 2，活动截止退还 3，活动截止后x天退还',
        prop: 'returnType',
        width: 50
      }, {
        label: '参与门店',
        prop: 'storeCodes',
        align: "left",
        width: 200
      }, {
        label: '状态 1，正常 2，停用',
        prop: 'status',
        slot: "status",
        width: 50
      }, {
        label: '礼品类型 1，积分 2，礼包',
        prop: 'type',
        slot: "type",
        width: 50
      }, {
        label: '开始时间',
        prop: 'startTime',
        width: 140
      }, {
        label: '结束时间',
        prop: 'endTime',
        width: 140
      }, {
        label: '领取数量',
        prop: 'totalReceiveScoreNum',
        width: 70
      }, {
        label: '退还数量',
        prop: 'totalReturnScoreNum',
        width: 70
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '活动名称',
        value: 'activityName'
      }, {
        type: 'radio',
        placeholder: '状态',
        value: 'status',
        select: [{
          label: '启用',
          value: 1
        }, {
          label: '停用',
          value: 2
        }]
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime'
      }]
    };
  },
  created: function created() {},
  methods: {
    getWxCode: function getWxCode(scope) {
      var _this = this;

      this.couponUUid = scope.activityName;
      this.url = 'activity/giveScore/giveScore?id=' + scope.id;
      (0, _user.couponQRCode)({
        path: 'activity/giveScore/giveScore',
        scene: "".concat(scope.id)
      }).then(function (res) {
        _this.openLoading().close();

        _this.qrCode = res.data;
        _this.popShow = true;
      }).catch(function (res) {
        _this.openLoading().close();
      });
    }
  }
};
exports.default = _default;