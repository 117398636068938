var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("新增编辑优惠券")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            size: "small",
            model: _vm.form,
            "label-width": "130px"
          }
        },
        [
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [
              _vm._v("\n        优惠券图\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form_right" }, [
              _c("div", { staticClass: "add_coupon_img add_coupon_box" }, [
                _c(
                  "div",
                  { staticClass: "img_box left" },
                  [
                    _c("div", { staticClass: "img_box_tit" }, [
                      _vm._v("优惠券列表图")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form_item",
                        attrs: {
                          prop: "mainImage",
                          required: "",
                          "label-width": "0"
                        }
                      },
                      [
                        _c("add-load", {
                          staticClass: "img_list",
                          attrs: {
                            width: 100,
                            height: 100,
                            cover: _vm.form.mainImage
                          },
                          on: { setCover: _vm.setCover }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "img_box right" },
                  [
                    _c(
                      "div",
                      { staticClass: "img_box_tit" },
                      [
                        _vm._v(
                          "\n              优惠券详情轮播图\n              "
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.addDetailRollimg }
                          },
                          [_vm._v("选择上传")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form_item",
                        attrs: {
                          prop: "detailRollimg",
                          required: "",
                          "label-width": "0"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "img_list" },
                          [
                            _vm._l(_vm.form.detailRollimg, function(
                              item,
                              index
                            ) {
                              return _c("img", {
                                key: index,
                                staticClass: "img_list_box",
                                attrs: { src: _vm.imgSrc + item.img }
                              })
                            }),
                            _vm._v(" "),
                            _vm._l(5 - _vm.form.detailRollimg.length, function(
                              item
                            ) {
                              return _c("div", {
                                key: 10 + item,
                                staticClass: "img_list_box"
                              })
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [
              _vm._v("\n        费用承担\n      ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: {
                      prop: "costType",
                      label: "费用出资方：",
                      required: ""
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "350px" },
                            attrs: { placeholder: "请选择费用出资方" },
                            on: { change: _vm.costTypeChange },
                            model: {
                              value: _vm.form.costType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "costType", $$v)
                              },
                              expression: "form.costType"
                            }
                          },
                          _vm._l(_vm.options, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "cost" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { required: "", prop: "ratioCompany" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "140px" },
                              attrs: {
                                type: "number",
                                disabled: _vm.form.costType != 3
                              },
                              on: { input: _vm.costCompanyInput },
                              model: {
                                value: _vm.form.ratioCompany,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "ratioCompany", $$v)
                                },
                                expression: "form.ratioCompany"
                              }
                            }),
                            _vm._v("%\n            ")
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { required: "", prop: "ratioTenant" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "140px" },
                              attrs: {
                                type: "number",
                                disabled: _vm.form.costType != 3
                              },
                              on: { input: _vm.costMerchantInput },
                              model: {
                                value: _vm.form.ratioTenant,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "ratioTenant", $$v)
                                },
                                expression: "form.ratioTenant"
                              }
                            }),
                            _vm._v("%\n            ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [
              _vm._v("\n        名称描述\n      ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: { required: "", prop: "name", label: "优惠券名称：" }
                  },
                  [
                    _c("el-input", {
                      staticClass: "form_input",
                      attrs: { size: "small" },
                      model: {
                        value: _vm.form.name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "优惠券短标题：",
                      required: "",
                      prop: "shortTitle"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "form_input",
                      attrs: { size: "small" },
                      model: {
                        value: _vm.form.shortTitle,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "shortTitle", $$v)
                        },
                        expression: "form.shortTitle"
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "coupon_box_remark" }, [
                      _vm._v("短标题字数不能超过12个字")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      required: "",
                      prop: "descrition",
                      label: "优惠券描述："
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "form_input",
                      attrs: { size: "small" },
                      model: {
                        value: _vm.form.descrition,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "descrition", $$v)
                        },
                        expression: "form.descrition"
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "coupon_box_remark" }, [
                      _vm._v("简要描述优惠券的使用范围/使用说明/宣传文案")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [
              _vm._v("\n        金额设置\n      ")
            ]),
            _vm._v(" "),
            _vm.form.couponType != 2
              ? _c(
                  "div",
                  { staticClass: "form_right" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          required: "",
                          prop: "denomination",
                          label: "优惠券面额："
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "form_input",
                          attrs: { size: "small" },
                          model: {
                            value: _vm.form.denomination,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "denomination", $$v)
                            },
                            expression: "form.denomination"
                          }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "coupon_box_remark" }, [
                          _vm._v("优惠券面额 / 优惠券价值")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form_item",
                        attrs: {
                          label: "销售价格：",
                          required: "",
                          prop: "form.salestypes"
                        }
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            staticClass: "coupon_price",
                            attrs: { size: "small" },
                            on: { change: _vm.buTypeChange },
                            model: {
                              value: _vm.form.salestypes,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "salestypes", $$v)
                              },
                              expression: "form.salestypes"
                            }
                          },
                          _vm._l(_vm.buyType, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "buyType" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      label: item.type,
                                      disabled:
                                        _vm.form.salestypes.indexOf(4) != -1 &&
                                        item.type != 4
                                    }
                                  },
                                  [_vm._v(_vm._s(item.label))]
                                ),
                                _vm._v(" "),
                                item.type == 1 || item.type == 3
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "14px",
                                          width: "150px"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            disabled:
                                              _vm.form.salestypes.indexOf(4) !=
                                                -1 && item.type != 4
                                          },
                                          model: {
                                            value: item.price,
                                            callback: function($$v) {
                                              _vm.$set(item, "price", $$v)
                                            },
                                            expression: "item.price"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                   元\n              "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.type == 3
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "14px",
                                          width: "40px"
                                        }
                                      },
                                      [_vm._v("+")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.type == 2 || item.type == 3
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "14px",
                                          width: "150px"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            disabled:
                                              _vm.form.salestypes.indexOf(4) !=
                                                -1 && item.type != 4
                                          },
                                          model: {
                                            value: item.integral,
                                            callback: function($$v) {
                                              _vm.$set(item, "integral", $$v)
                                            },
                                            expression: "item.integral"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                积分\n              "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.type == 1 || item.type == 2
                                  ? _c("div", {
                                      staticStyle: {
                                        "font-size": "14px",
                                        width: "190px"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { float: "right" } },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: item.type != 4,
                                            expression: "item.type!=4"
                                          }
                                        ],
                                        attrs: {
                                          label: item.type,
                                          disabled:
                                            _vm.form.salestypes.indexOf(
                                              item.type
                                            ) == -1
                                        },
                                        model: {
                                          value: _vm.listShow,
                                          callback: function($$v) {
                                            _vm.listShow = $$v
                                          },
                                          expression: "listShow"
                                        }
                                      },
                                      [_vm._v("列表显示")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          required: "",
                          prop: "doorsillMoney",
                          label: "使用门槛金额："
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "form_input",
                          attrs: { size: "small" },
                          model: {
                            value: _vm.form.doorsillMoney,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "doorsillMoney", $$v)
                            },
                            expression: "form.doorsillMoney"
                          }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "coupon_box_remark" }, [
                          _vm._v(
                            "如果是满减优惠券，可填写消费金额满___元可用，不设门槛请填0"
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.form.couponType == 1 &&
                    (_vm.form.salestypes.indexOf(2) != "-1" ||
                      _vm.form.salestypes.indexOf(3) != "-1")
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              required: "",
                              prop: "costStoreId",
                              label: "费用承担门店："
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  "max-width": "400px",
                                  width: "100%"
                                },
                                attrs: {
                                  clearable: "",
                                  size: "small",
                                  placeholder: "请选择费用承担门店",
                                  filterable: ""
                                },
                                model: {
                                  value: _vm.form.costStoreId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "costStoreId", $$v)
                                  },
                                  expression: "form.costStoreId"
                                }
                              },
                              _vm._l(_vm.storeList, function(item) {
                                return _c("el-option", {
                                  key: item.storeId,
                                  attrs: {
                                    label:
                                      item.shortName +
                                      "（" +
                                      item.storeCode +
                                      "）",
                                    value: item.storeCode
                                  }
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "coupon_box_remark" }, [
                              _vm._v(
                                " 如果销售价格存在积分购买方式，必须选择一个费用承担门店"
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "form_right" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          required: "",
                          prop: "discount",
                          label: "优惠折扣："
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "form_input",
                          attrs: {
                            size: "small",
                            type: "number",
                            placeholder: "请输入优惠折扣",
                            onmousewheel: "return false;"
                          },
                          model: {
                            value: _vm.form.discount,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "discount", $$v)
                            },
                            expression: "form.discount"
                          }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "coupon_box_remark" }, [
                          _vm._v(
                            "请填写折扣比率，8折填写80，8.5折填写85，以此类推"
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "折扣金额封顶：",
                          required: "",
                          prop: "cappingMoney"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "form_input",
                          attrs: {
                            size: "small",
                            type: "number",
                            onmousewheel: "return false;",
                            placeholder: "请输入折扣金额封顶"
                          },
                          model: {
                            value: _vm.form.cappingMoney,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "cappingMoney", $$v)
                            },
                            expression: "form.cappingMoney"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [
              _vm._v("\n        特殊说明\n      ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "自定义字段：" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "200px" },
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            _vm.detailCtmfieldShow = true
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "coupon_box_remark" }, [
                      _vm._v(
                        "自定义字段主要用于商品类/票务类，可添加若干文字说明，如参数、地址、电话等等"
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [
              _vm._v("\n        规则内容\n      ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "使用规则：",
                      required: "",
                      prop: "instructions"
                    }
                  },
                  [
                    _c("Tinymce", {
                      ref: "editor",
                      attrs: { width: 600, height: 300 },
                      on: { onChange: _vm.instructionsChange },
                      model: {
                        value: _vm.form.instructions,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "instructions", $$v)
                        },
                        expression: "form.instructions"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "内容详情：",
                      required: "",
                      prop: "geDetails"
                    }
                  },
                  [
                    _c("Tinymce", {
                      ref: "editor",
                      attrs: { width: 600, height: 300 },
                      on: { onChange: _vm.geDetailsChange },
                      model: {
                        value: _vm.form.geDetails,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "geDetails", $$v)
                        },
                        expression: "form.geDetails"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.form.couponType == 5
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "导入券码：",
                          required: "",
                          prop: "geDetails"
                        }
                      },
                      [
                        _c("div", { staticStyle: { flex: "1" } }, [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    plain: "",
                                    icon: "el-icon-download"
                                  }
                                },
                                [_vm._v("下载模板")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "receivedCode" }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "13px",
                                color: "#999999",
                                "margin-top": "10px"
                              }
                            },
                            [_vm._v("投放总数：")]
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [
              _vm._v("\n        数量时间\n      ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      required: "",
                      prop: "totalNumber",
                      label: "投放总数量："
                    }
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "120px" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.form.totalNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "totalNumber", $$v)
                        },
                        expression: "form.totalNumber"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "coupon_box_remark" }, [
                      _vm._v("该优惠券预计投放总量（不限数量请填0）")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      required: "",
                      prop: "limitNumber",
                      label: "限领/限购数量："
                    }
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "120px" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.form.limitNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "limitNumber", $$v)
                        },
                        expression: "form.limitNumber"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "coupon_box_remark" }, [
                      _vm._v("一个用户限领/限购的数量（不限数量请填0）")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      required: "",
                      prop: "validTimeType",
                      label: "优惠券有效期："
                    }
                  },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 1 },
                        model: {
                          value: _vm.form.validTimeType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "validTimeType", $$v)
                          },
                          expression: "form.validTimeType"
                        }
                      },
                      [_vm._v("时间段")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 2 },
                        model: {
                          value: _vm.form.validTimeType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "validTimeType", $$v)
                          },
                          expression: "form.validTimeType"
                        }
                      },
                      [_vm._v("领取后N天内有效")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.validTimeType === 1,
                            expression: "form.validTimeType === 1"
                          }
                        ],
                        attrs: { required: "", prop: "beginDate" }
                      },
                      [
                        _c("el-date-picker", {
                          staticClass: "margin_top coupon_input",
                          attrs: {
                            size: "small",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            type: "datetimerange",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期"
                          },
                          model: {
                            value: _vm.form.beginDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "beginDate", $$v)
                            },
                            expression: "form.beginDate"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.validTimeType === 2,
                            expression: "form.validTimeType === 2"
                          }
                        ],
                        attrs: { required: "", prop: "validDay" }
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100px" },
                          attrs: {
                            size: "small",
                            onmousewheel: "return false;",
                            type: "number",
                            placeholder: ""
                          },
                          model: {
                            value: _vm.form.validDay,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "validDay", $$v)
                            },
                            expression: "form.validDay"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "coupon_box_remark" }, [
                          _vm._v("天")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [
              _vm._v("\n        规则内容\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form_right" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [
              _vm._v("\n        规则设置\n      ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      required: "",
                      prop: "limitGoods",
                      label: "限购商品数量："
                    }
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "120px" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.form.limitGoods,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "limitGoods", $$v)
                        },
                        expression: "form.limitGoods"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "coupon_box_remark" }, [
                      _vm._v("件商品（填写0为不限制商品数量）")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      required: "",
                      prop: "rejectRule",
                      label: "排斥规则："
                    }
                  },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 1 },
                        model: {
                          value: _vm.form.rejectRule,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "rejectRule", $$v)
                          },
                          expression: "form.rejectRule"
                        }
                      },
                      [_vm._v("不与其他优惠券同时使用")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 2 },
                        model: {
                          value: _vm.form.rejectRule,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "rejectRule", $$v)
                          },
                          expression: "form.rejectRule"
                        }
                      },
                      [_vm._v("可与其他优惠券同时使用")]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "coupon_box_remark" }, [
                      _vm._v("排斥规则指：甲券与乙券不能同时核销使用")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      required: "",
                      prop: "overlayNumber",
                      label: "叠加规则："
                    }
                  },
                  [
                    _c("span", { staticClass: "coupon_box_remark" }, [
                      _vm._v("可叠加使用")
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticStyle: { width: "120px" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.form.overlayNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "overlayNumber", $$v)
                        },
                        expression: "form.overlayNumber"
                      }
                    }),
                    _vm._v("张\n\n          "),
                    _c("span", { staticClass: "coupon_box_remark" }, [
                      _vm._v(
                        "叠加规则指：如用户有多张A券，\n            A券和A券可同时叠加使用，最多叠加N张"
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      required: "",
                      prop: "verificationModel",
                      label: "核销方式："
                    }
                  },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.form.verificationModel,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "verificationModel", $$v)
                          },
                          expression: "form.verificationModel"
                        }
                      },
                      _vm._l(_vm.verificationModelArr, function(item) {
                        return _c(
                          "el-checkbox",
                          {
                            key: item.cardId,
                            attrs: { value: item.id, label: item.id }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      required: "",
                      prop: "showStatus",
                      label: "显示状态："
                    }
                  },
                  _vm._l(_vm.showStatusArr, function(item) {
                    return _c(
                      "el-radio",
                      {
                        key: item.id,
                        attrs: { label: item.id },
                        model: {
                          value: _vm.form.showStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "showStatus", $$v)
                          },
                          expression: "form.showStatus"
                        }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "转赠规则：" } },
                  [
                    _c("el-radio", { attrs: { disabled: "", label: 0 } }, [
                      _vm._v("不可转赠好友")
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { disabled: "", label: 1 } }, [
                      _vm._v("可以转赠好友")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [
              _vm._v("\n        分享设置\n      ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right" },
              [
                _c(
                  "p",
                  {
                    staticClass: "coupon_box_remark",
                    staticStyle: { color: "#00C4FF" }
                  },
                  [
                    _vm._v(
                      "\n          此处设置的是优惠券详情页分享给好友或群聊时显示的标题及图片。\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "coupon_box_remark",
                    staticStyle: { color: "#00C4FF" }
                  },
                  [
                    _vm._v(
                      "如果不设置，分享时微信会对页面进行截屏，可能不利于分享效果，建议另外输出规定尺寸的图片上传此处。"
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { span: 11 }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          required: "",
                          prop: "shareTitle",
                          label: "分享标题："
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "form_input",
                          staticStyle: { width: "220px" },
                          attrs: { size: "small", type: "textarea" },
                          model: {
                            value: _vm.form.shareTitle,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "shareTitle", $$v)
                            },
                            expression: "form.shareTitle"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          required: "",
                          prop: "shareImg",
                          label: "分享图片："
                        }
                      },
                      [
                        _c("add-load", {
                          attrs: {
                            width: 220,
                            height: 140,
                            cover: _vm.form.shareImg
                          },
                          on: { setCover: _vm.setSareImg }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "coupon_box_remark" }, [
                          _vm._v("图片尺寸：520px * 416px")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { span: 11 }
                  },
                  [
                    _c("el-form-item", { attrs: { label: "分享图片演示：" } }, [
                      _c("img", {
                        staticStyle: { width: "220px", height: "268px" },
                        attrs: {
                          src:
                            "http://xl-cdn.xinglico.com/mika/pro/2020-02-19/9b4a4995f9a1466db053acd28635841d.jpg"
                        }
                      })
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { margin: "30px 0 0 150px" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("form")
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cardLoad", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.detailRollimgShow,
            expression: "detailRollimgShow"
          }
        ],
        ref: "detailImg",
        attrs: { "detail-rollimg": _vm.form.detailRollimg },
        on: { setDetailRollimg: _vm.setDetailRollimg }
      }),
      _vm._v(" "),
      _c("detailCtm", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.detailCtmfieldShow,
            expression: "detailCtmfieldShow"
          }
        ],
        attrs: { "detail-ctmfield": _vm.form.detailCtmfield },
        on: { setdetailCtmfield: _vm.setdetailCtmfield }
      }),
      _vm._v(" "),
      _c("phone", { attrs: { obj: _vm.form } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }