"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      tableData: [],
      columns: [{
        label: '最小天数',
        prop: 'minDays',
        width: 80
      }, {
        label: '最大天数',
        prop: 'maxDays',
        width: 80
      }, {
        label: '积分',
        prop: 'score',
        width: 80
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status'
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {
    this.getList();
  },
  methods: {
    statusChange: function statusChange(e, item) {
      var _this = this;

      (0, _api.editData)({
        id: item.id,
        score: item.score,
        minDays: item.minDays,
        maxDays: item.maxDays,
        status: e
      }).then(function (res) {
        _this.getList();
      });
    },
    goDelData: function goDelData(item) {
      var _this2 = this;

      (0, _api.goDel)(item.id).then(function (res) {
        _this2.getList();
      });
    },
    handleDownload: function handleDownload() {
      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      this.getHandleDownload(tHeader, filterVal, this.tableData, '日常签到列表');
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      (0, _api.getList)().then(function (res) {
        _this3.tableData = res.data;
      });
    }
  }
};
exports.default = _default;