"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      list: [{
        tit: '促销类活动',
        text: '填写活动主题、上传banner图、图文编辑、根据需要插入可领取的优惠券，生成活动页面。用户可分享该活动页面给好友或群聊，也可生成海报图片发朋友圈。',
        btn1: '促销类活动列表',
        btn2: '新增促销类活动',
        url: 'promontion'
      }, {
        tit: '报名类活动',
        text: '填写活动主题、上传banner图、图文编辑、设置活动时间、报名限制人数、设置报名用户填写的信息字段、后台可做相应统计并导出表格。',
        btn1: '报名类活动列表',
        btn2: '新增报名类活动',
        url: 'apply'
      }]
    };
  },
  watch: {},
  methods: {
    goAddput: function goAddput(index) {
      this.$router.push('/coupon/coupon3/activity?id=' + index);
    },
    goList: function goList(index) {
      if (index === 0) {
        this.$router.push('/coupon/couponPut/orientPut');
      } else if (index === 1) {
        this.$router.push('/coupon/couponPut/touchPut');
      } else if (index === 2) {
        this.$router.push('/coupon/couponPut/volumeCenter');
      }
    }
  }
};
exports.default = _default;