var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "enterprise" },
    [
      _c(
        "w-table",
        {
          attrs: {
            pageApi: _vm.api,
            columns: _vm.columns,
            "search-data": _vm.queryParams
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              "mni-width": "100"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.delStatus == -1
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v("未删")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.delStatus == 1
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("已删")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }