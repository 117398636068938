"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _add = _interopRequireDefault(require("./components/add"));

var _list = _interopRequireDefault(require("./components/list"));

var _editor = _interopRequireDefault(require("./components/editor"));

//
//
//
//
//
//
var _default = {
  components: {
    list: _list.default,
    add: _add.default,
    editor: _editor.default
  },
  data: function data() {
    return {
      currentRole: 'list',
      cardId: ''
    };
  },
  watch: {},
  methods: {
    setTab: function setTab(index) {},
    setCardId: function setCardId(id) {
      // console.log(id)
      this.cardId = id;
    }
  }
};
exports.default = _default;