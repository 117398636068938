var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 购物卡充值 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "查询卡信息：", prop: "cardNo" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "500px" },
                  attrs: { placeholder: "请输入内容" },
                  model: {
                    value: _vm.ruleForm.cardNo,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "cardNo", $$v)
                    },
                    expression: "ruleForm.cardNo"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { slot: "prepend", placeholder: "请选择" },
                      slot: "prepend",
                      model: {
                        value: _vm.select,
                        callback: function($$v) {
                          _vm.select = $$v
                        },
                        expression: "select"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "输入卡号", value: 1 }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "刷卡查询", value: 2 }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function($event) {
                        return _vm.goCardInfo()
                      }
                    },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "卡号：" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.ruleForm.cardNo) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "卡类型：" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cardData.categoryName) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "卡种类：" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cardData.kindsName) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "实名制时间：" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cardData.realNameTime) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.cardData.status === 7
                    ? _c("el-form-item", { attrs: { label: "卡状态：" } }, [
                        _c("span", { staticClass: "danger" }, [
                          _vm._v("挂失换新")
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "卡余额：" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cardData.currentPrice) +
                        "元\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态：" } },
                    [
                      _vm.cardData.isActive === 1
                        ? _c("el-tag", { attrs: { type: "primary" } }, [
                            _vm._v("已激活")
                          ])
                        : _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("未激活(不可充值)")
                          ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "激活时间：" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cardData.activeTime) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "有效期：" } }, [
                    _vm._v(
                      "\n          激活后 " +
                        _vm._s(_vm.cardData.expireTradeYear) +
                        "年后过期\n        "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "实名状态：" } },
                    [
                      _vm.cardData.isRealName === 1
                        ? _c("el-tag", { attrs: { type: "primary" } }, [
                            _vm._v("已实名")
                          ])
                        : _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("未实名")
                          ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "用户姓名：" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cardData.userName) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "手机号码：" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.cardData.phone) + "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "身份证号：" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cardData.idCard) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div"),
          _vm._v(" "),
          _vm.cardData.isRealName === 1 && _vm.cardData.status < 7
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "支付方式：", prop: "payTypeId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form_input",
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            filterable: "",
                            clearable: "",
                            placeholder: "支付方式"
                          },
                          model: {
                            value: _vm.ruleForm.payTypeId,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "payTypeId", $$v)
                            },
                            expression: "ruleForm.payTypeId"
                          }
                        },
                        _vm._l(_vm.payTypeList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "充值金额：", prop: "rechargePrice" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: {
                          type: "number",
                          placeholder: "请输入充值金额"
                        },
                        model: {
                          value: _vm.ruleForm.rechargePrice,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "rechargePrice",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.rechargePrice"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "附件：", prop: "attachment" } },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.action,
                            "list-type": "picture-card",
                            headers: _vm.headers,
                            "on-success": _vm.onSuccess,
                            "on-remove": _vm.handleRemove
                          }
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { placeholder: "请输入备注" },
                        model: {
                          value: _vm.ruleForm.remark,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "remark",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.remark"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("充值")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }