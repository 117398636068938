var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goods" },
    [
      _c("w-table", {
        ref: "wTabel",
        attrs: {
          columns: _vm.columns,
          searchData: _vm.searchData,
          operate: _vm.operate
        },
        on: { goDelData: _vm.handleDelete }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }