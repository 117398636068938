"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _integral = require("@/api/integral.js");

var _mem = require("@/api/mem.js");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableData: [],
      companyList: [],
      storeList: [],
      total: 0,
      deaPath: '/integral/addIntegral?id=',
      // 支付方式
      payArr: [{
        value: 1,
        label: '星汇通储值卡（不记名）'
      }, {
        value: 2,
        label: '星汇通会员卡'
      }, {
        value: 3,
        label: 'MIKA储蓄卡'
      }, {
        value: 4,
        label: '云闪付'
      }, {
        value: 5,
        label: '通联银行卡'
      }, {
        value: 6,
        label: '微信支付'
      }, {
        value: 7,
        label: '支付宝'
      }, {
        value: 8,
        label: '现金'
      }, {
        value: 9,
        label: '礼品券'
      }, {
        value: 10,
        label: '代金券'
      }, {
        value: 11,
        label: '优惠券'
      }, {
        value: 12,
        label: '挂账'
      }, {
        value: 13,
        label: '信用卡'
      }],
      tabTh: [{
        tit: '编号',
        prop: 'basicCode',
        align: 'center',
        width: 180
      },
      /* {
          tit: '所属公司',
          prop: 'systemName',
          align: 'center',
          width: 120
        }, */
      {
        prop: 'sellAmount',
        tit: '普通会员消费',
        align: 'center',
        width: 50
      }, {
        prop: 'sell365Amount',
        tit: '365消费',
        align: 'center',
        width: 50
      }, {
        prop: 'selllavantAmount',
        tit: 'Lavant消费',
        align: 'center',
        width: 50
      }, {
        tit: '积分',
        prop: 'getScore',
        align: 'center',
        width: 100
      },
      /* {
          tit: '执行时间',
          prop: 'validStartTime',
          align: 'center',
          width: 170
        }, {
          tit: '截止时间',
          prop: 'validEndTime',
          align: 'center',
          width: 170
        },
        {
          tit: '支付方式',
          prop: 'payMode',
          align: 'center',
          width: 130
         }, */
      {
        tit: '备注',
        prop: 'basicRemark',
        align: 'left',
        width: 200
      }, {
        tit: '状态',
        prop: 'basicStatus',
        align: 'center',
        width: 100
      }],
      obj: {
        pageNo: 1,
        pageSize: 10,
        basicStatus: '',
        storeCode: '',
        companyCode: '',
        basicCode: '',
        brandClsCode: '',
        brandCode: '',
        goodsCode: ''
      },
      typeArr: [{
        value: 1,
        label: '启用'
      }, {
        value: -1,
        label: '禁用'
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companyList = res.data;
    });
    (0, _mem.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    setShort: function setShort(e) {
      var _this2 = this;

      this.obj.storeCode = '';
      this.companyList.forEach(function (item) {
        if (item.companyCode == e) {
          (0, _mem.listStore)({
            companyId: item.companyId
          }).then(function (res) {
            _this2.storeList = res.data;
          });
        }
      });
    },

    /*
        导出
        */
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['编号', '消费金额(元)', '积分', '状态'];
        var filterVal = ['basicCode', 'sellAmount', 'getScore', 'basicStatus'];
        var list = [];
        var data = Object.assign({}, _this3.obj);
        data.pageSize = _this3.total;
        data.pageNo = 1;
        (0, _integral.listScoreBasicPage)(data).then(function (res) {
          list = res.data.items;
          list.forEach(function (item) {
            item.basicStatus = item.basicStatus == 1 ? '启用' : '禁用';
          });

          var data = _this3.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '基础积分策略'
          });
          _this3.downloadLoading = false;
        }).catch(function (res) {
          _this3.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/integral/addIntegral');
    },
    init: function init(type) {
      var _this4 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
      }

      this.openLoading();
      (0, _integral.listScoreBasicPage)(this.obj).then(function (res) {
        _this4.openLoading().close();

        _this4.tableData = res.data.items;

        _this4.tableData.forEach(function (item) {
          item.basicStatus = item.basicStatus == 1 ? '启用' : '禁用';
        });

        _this4.total = res.data.totalNum;
      }).catch(function (err) {
        _this4.openLoading().close();
      });
    },
    setTab: function setTab() {},
    remove: function remove() {},
    setDelete: function setDelete() {
      var _this5 = this;

      console.log('sdfsdf');
      this.$confirm('此操作将永久删除该条信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this5.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(function () {
        _this5.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};
exports.default = _default;