"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

/** When your routing table is too long, you can split it into small modules **/
var mallGroupRouter = {
  path: 'group',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
    });
  },
  redirect: '/mall/group/list',
  name: 'mallgrouplist',
  meta: {
    title: '积分商品订单补录'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./list.vue"));
      });
    },
    name: 'mallGroupList',
    meta: {
      title: '积分商品订单补录',
      keepAlive: true,
      addTxt: "新增团购",
      addUrl: '/mall/group/add',
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/mall/group-order/groupOrderPage',
      columns: []
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./add.vue"));
      });
    },
    name: 'mallGroupAdd',
    meta: {
      title: '新增团购',
      activeMenu: '/mall/group/list'
    }
  }, {
    path: 'dea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./dea.vue"));
      });
    },
    name: 'mallGroupDea',
    meta: {
      title: '团购详情',
      activeMenu: '/mall/group/list'
    }
  }]
};
var _default = mallGroupRouter;
exports.default = _default;