"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      id: '',
      active: -1,
      btnType: 0,
      list: [],
      step: [{
        tit: '发布方案（待审核）',
        txt: ''
      }, {
        tit: '审核通过（待投放）',
        txt: ''
      }, {
        tit: '执行方案（已投放）',
        txt: ''
      }],
      name: '',
      descrition: '',
      putTime: '',
      tiggType: '',
      tiggValue: '',
      putNumber: '',
      restrictNumber: '',
      status: '',
      couponIds: [],
      couponIdBag: []
    };
  },
  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    goEditor: function goEditor() {
      this.$router.push('/coupon/couponPut/addPut?id=orient&unid=' + this.id);
    },
    setActive: function setActive(index) {
      switch (index) {
        case 1:
          this.status = '待审核';
          this.active = index;
          this.btnType = 1;
          break;

        case 2:
          this.status = '已审核';
          this.active = 2;
          this.btnType = 2;
          break;

        case 3:
          this.status = '审核撤回';
          this.active = 1;
          this.btnType = 3;
          break;

        case 4:
          this.status = '审核撤销';
          this.active = -1;
          this.btnType = 0;
          break;

        case 5:
          this.status = '已过期';
          this.active = -1;
          this.btnType = 0;
          break;

        case 6:
          this.status = '已终止';
          this.active = -1;
          this.btnType = 0;
          break;

        case 8:
          this.status = '已投放';
          this.active = 3;
          this.btnType = 2;
          break;

        case 9:
          this.status = '投放完成';
          this.active = 4;
          this.btnType = 0;
          break;
      }
    },
    init: function init() {
      var _this = this;

      this.id = Number(this.$route.query.unid);
      (0, _coupon.getPutOrientationById)(this.id).then(function (res) {
        console.log(res);
        _this.list = res.data.putOrientationLabelVos;
        _this.name = res.data.name;
        _this.couponIds = JSON.parse(res.data.couponIds);
        _this.couponBagIds = JSON.parse(res.data.couponBagIds);
        _this.descrition = res.data.descrition;
        _this.putTime = res.data.putTime; // "有效期(结束日期)",

        (0, _coupon.couponSchemeValid)(_this.setCouponIdBags(_this.couponIds.concat(_this.couponBagIds))).then(function (res) {
          _this.couponIdBag = res.data;
        });

        _this.setActive(res.data.status);

        var putOpr = res.data.putOprLogVos;
        putOpr.forEach(function (item) {
          if (item.oprType === 1) {
            _this.step[0].txt = item.oprUserName + item.oprTime;
          } else if (item.oprType === 2) {
            _this.step[1].txt = item.oprUserName + item.oprTime;
          }
        });
        /* if(putOpr.length>0){
        for(let i=0;i<this.step.length;i++){
        if(putOpr[i].oprUserName){
        this.step[i].txt=putOpr[i].oprUserName+putOpr[i].oprTime
        }
        }
        }*/
      });
    },
    setStatus: function setStatus() {
      var _this2 = this;

      // 通过审核
      var oprType = '';

      if (this.active === 1) {
        oprType = 2;
      } else {
        this.$message({
          message: '已经审核通过',
          type: 'warning'
        });
        return;
      }

      this.$confirm('此操作将通过审核, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _coupon.editPutOrientationStatu)(_this2.id, oprType, oprType).then(function (res) {
          if (res.ret === 1000) {
            _this2.$message({
              type: 'success',
              message: '审核成功!'
            });

            _this2.setActive(oprType);
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消撤销'
        });
      });
    },
    removeStatus: function removeStatus(index) {
      var _this3 = this;

      var str = '';
      var str1 = '';

      if (index === 2) {
        str1 = '审核';
        str = '此操作将通过审核方案，审核通过后将不可修改， 是否继续?';
      } else if (index === 3) {
        str1 = '撤回';
        str = '此操作将撤回审核， 是否继续?';
      } else if (index === 4) {
        str1 = '撤销';
        str = '此操作将撤销审核，撤销通过后将不可修改， 是否继续?';
      } else if (index === 6) {
        str1 = '终止';
        str = '此操作将终止方案，终止通过后将作废， 是否继续?';
      }

      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _coupon.editPutOrientationStatu)(_this3.id, index).then(function (res) {
          if (res.ret === 1000) {
            _this3.setActive(index);

            _this3.$message({
              type: 'success',
              message: str1 + '成功！'
            });
          }
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消' + str1
        });
      });
    }
  }
};
exports.default = _default;