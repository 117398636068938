"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var pufaRouter = {
  path: '/pufa',
  component: _layout.default,
  redirect: '/pufa/tab',
  name: 'pufa',
  meta: {
    title: '浦发'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/pufa/view/tab.vue'));
      });
    },
    name: 'pufaTab',
    meta: {
      title: '浦发理财'
    }
  }, {
    path: 'user',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/pufa/user.vue'));
      });
    },
    name: 'pufaUser',
    meta: {
      title: '浦发理财客户信息表',
      keepAlive: true,
      activeMenu: '/pufa/tab'
    }
  }, {
    path: 'branchList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/pufa/branch/list.vue'));
      });
    },
    name: 'branchList',
    meta: {
      title: '银行网点管理',
      keepAlive: true,
      activeMenu: '/pufa/tab'
    }
  }, {
    path: 'addBranch',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/pufa/branch/add.vue'));
      });
    },
    name: 'addBranch',
    meta: {
      title: '新增/编辑银行网点',
      activeMenu: '/pufa/tab'
    }
  }, {
    path: 'manageList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/pufa/manage/list.vue'));
      });
    },
    name: 'pufamanageList',
    meta: {
      title: '理财产品管理',
      activeMenu: '/pufa/tab',
      keepAlive: true
    }
  }, {
    path: 'addManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/pufa/manage/add.vue'));
      });
    },
    name: 'pufamanageAdd',
    meta: {
      title: '新增/编辑理财产品管理',
      activeMenu: '/pufa/tab'
    }
  }]
};
var _default = pufaRouter;
exports.default = _default;