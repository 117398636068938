"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _api = require("./api.js");

var _regionData = _interopRequireDefault(require("@/utils/regionData.json"));

var _radio = _interopRequireDefault(require("@/components/couponPop/radio.vue"));

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    wTable: _index.default,
    coupon: _getCoupon.default,
    selectAward: _radio.default
  },
  data: function data() {
    return {
      couponShows: false,
      count: '',
      typeList: [{
        label: '发放优惠券',
        value: 1
      }, {
        label: '储值卡变动',
        value: 2
      }, {
        label: '积分变动',
        value: 3
      }, {
        label: '成长值变动',
        value: 4
      }],
      couponShow: false,
      columns: [{
        label: '阶梯奖励',
        prop: 'id',
        width: 80
      }, {
        label: '充值金额',
        prop: 'topupAmount',
        width: 80
      }, {
        label: '名额限制',
        prop: 'peopleLimit',
        width: 80
      }, {
        slot: 'event'
      }, {
        slot: 'operate'
      }],
      ruleForm: {
        id: '',
        appointCard: '',
        cardCode: '',
        ruleName: '',
        ruleStatus: '',
        ruleRemark: '',
        ladders: [],
        time: ''
      },
      rules: {
        cardCode: [{
          required: true,
          message: '请输入星礼卡编码'
        }],
        time: [{
          required: true,
          message: '请选择活动时间'
        }],
        appointCard: [{
          required: true,
          message: '请选择是否指定返赠星礼卡'
        }],
        ruleName: [{
          required: true,
          message: '请输入名称'
        }],
        ruleStatus: [{
          required: true,
          message: '请选择状态'
        }],
        ruleRemark: [{
          required: true,
          message: '请输入备注'
        }],
        ladders: [{
          required: true,
          message: '请选择优惠券'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id) {
      (0, _api.getCardById)(this.ruleForm.id).then(function (res) {
        var ladderVo = res.data.ladderVo;
        ladderVo.forEach(function (item) {
          item.eventIds = item.event;
        });
        Object.keys(_this.ruleForm).forEach(function (key) {
          if (key == 'ladders') {
            _this.ruleForm[key] = res.data.ladderVo;
          } else if (key === 'time') {
            _this.ruleForm[key] = [res.data.startTime, res.data.endTime];
          } else {
            _this.ruleForm[key] = res.data[key];
          }
        });
        console.log(_this.ruleForm);
      });
    }
  },
  methods: {
    /*
      打开弹框
      */
    getAward: function getAward() {
      this.$refs.coupon1.getList('card', this.ruleForm.cardCode);
      this.couponShows = true;
    },

    /*
      选择ID后
      */
    setCoupon: function setCoupon(e) {
      this.ruleForm.cardCode = e;
      this.couponShows = false;
    },
    delLadders: function delLadders(index) {
      this.ruleForm.ladders.splice(index, 1);
    },
    addLadders: function addLadders() {
      this.ruleForm.ladders.push({
        id: 0,
        topupAmount: '',
        peopleLimit: '',
        eventIds: []
      });
    },
    getRelevances: function getRelevances(index) {
      this.count = index;
      this.$refs.couponPop.init();
      var arr = [];
      var eventIds = this.ruleForm.ladders[this.count].eventIds;

      if (eventIds.length > 0) {
        eventIds.forEach(function (item) {
          arr.push({
            linkId: item.id
          });
        });
      }

      this.$refs.couponPop.assignCoupon(arr);
      this.couponShow = true;
    },
    setCoupons: function setCoupons(e) {
      var arr = [];

      if (e.length > 0) {
        e.forEach(function (item) {
          arr.push({
            eventName: item.eventName,
            id: item.id
          });
        });
      }

      this.ruleForm.ladders[this.count].eventIds = arr;
      this.couponShow = false;
    },
    closeCouponPop: function closeCouponPop() {
      this.couponShow = false;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          var requestData = JSON.parse(JSON.stringify(_this2.ruleForm));
          delete requestData.time;
          requestData.startTime = _this2.ruleForm.time[0];
          requestData.endTime = _this2.ruleForm.time[1];

          _this2.ruleForm.ladders.forEach(function (item, key) {
            requestData.ladders[key].eventIds = [];
            delete requestData.ladders[key].event;
            item.eventIds.forEach(function (row) {
              requestData.ladders[key].eventIds.push(row.id);
            });
          });

          if (_this2.ruleForm.id) {
            (0, _api.editCard)(requestData).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addCard)(requestData).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '新增成功!'
              });

              _this2.$router.go(-1);
            });
          }
        } else {
          var data = Object.keys(object);
          var dom = _this2.$refs[data[0]];

          if (data[0].indexOf('ladders.') >= 0) {
            dom = _this2.$refs['ladders'];
          } else {
            dom = _this2.$refs[data[0]];
          }

          dom.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    setVendorImg: function setVendorImg(e, type) {
      this.ruleForm[type] = e;
    },
    setCover: function setCover(e, index) {
      if (index >= this.ruleForm.bussImgs.length) {
        this.ruleForm.bussImgs.push(e);
      } else {
        this.ruleForm.bussImgs[index] = e;
      }
    }
  }
};
exports.default = _default;