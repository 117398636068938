"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _api = require("./api.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      temList: [],
      popType: '',
      couponShow: false,
      storeList: [],
      tabGiftType: {
        1: {
          placeholder: '请输入优惠券数量',
          label: '份'
        },
        2: {
          placeholder: '请输入券包数量',
          label: '份'
        },
        3: {
          placeholder: '请输入商品数量',
          label: '份'
        },
        4: {
          placeholder: '请输入积分值',
          label: '积分'
        },
        5: {
          placeholder: '请输入购物卡金额',
          label: '元'
        },
        6: {
          placeholder: '请输入兑换码数量',
          label: '份'
        }
      },
      type: [{
        label: '优惠券',
        value: 1
      }, {
        label: '券包',
        value: 2
      },
      /* {
         label: '商品',
         value: 3
       }, */

      /* {
         label: '积分返利',
         value: 4
       }, */
      {
        label: '购物卡',
        value: 5
      }],
      rules: {
        rightName: [{
          required: true,
          message: '请输入名称'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        ruleDesc: [{
          required: true,
          message: '请输入规则描述'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        rightDesc: [{
          required: true,
          message: '请输入权益描述'
        }],
        productItemList: [{
          required: true,
          message: '请添加商品'
        }],
        rightType: [{
          required: true,
          message: '请选择类型'
        }]
      },
      ruleForm: {
        id: null,
        image: null,
        rightName: null,
        status: null,
        ruleDesc: null,
        remark: null,
        rightDesc: null,
        rightType: null,
        productItemList: []
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;
    this.ruleForm.activityId = this.$route.query.mallId;

    if (this.ruleForm.id) {
      (0, _api.getData)({
        id: this.ruleForm.id
      }).then(function (res) {
        res.data.image = res.data.image || '';
        _this.ruleForm = res.data;
      });
    }
  },
  methods: {
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    getRelevances: function getRelevances() {
      this.ruleForm.productItemList.push({
        productName: null,
        productCode: null,
        productType: null,
        productNum: null,
        remark: null,
        productImage: null,
        productDesc: null
      });
    },

    /*
      选择奖励项
      */
    setAward: function setAward(e, index) {
      console.log(e);
      this.ruleForm.productItemList[index].productCode = e === 4 ? '赠送积分' : null;
      this.ruleForm.productItemList[index].productName = e === 4 ? '赠送积分' : null;
      this.ruleForm.productItemList[index].productDesc = e === 4 ? '赠送积分' : null;
      this.ruleForm.productItemList[index].productImage = e === 4 ? 'mika/dev/2022-07-14/76a4b728fad240fda6e5ee8de7a7b28f.png' : null;
      this.ruleForm.productItemList[index].sort = null; // this.ruleForm.productItemList[index].productImage = null
    },

    /*
      打开弹框
      */
    getAward: function getAward(e, index) {
      this.count = index;
      var selectType = {
        1: 'coupon',
        2: 'couponBag',
        3: 'goods',
        5: 'card',
        6: 'vip'
      };
      this.popType = selectType[e];
      this.$refs.coupon1.getList(this.popType, this.ruleForm.productItemList[index].productCode);
      this.couponShow = true;
    },

    /*
      删除奖励项
      */
    delAward: function delAward(index) {
      this.ruleForm.productItemList.splice(index, 1);
    },
    setCoupon: function setCoupon(e) {
      var godosItem = this.ruleForm.productItemList[this.count];
      var goodsType = {
        coupon: {
          productCode: 'uuid',
          productName: 'name',
          productImage: 'mainImage',
          productDesc: 'descrition'
        },
        goods: {
          productCode: 'productCode',
          productName: 'productName',
          productImage: 'imageUrl',
          productOriginalPrice: 'marketPrice',
          productDesc: 'productInstr'
        },
        legouGoods: {
          productCode: 'id',
          productName: 'name',
          productImage: 'pic_url',
          productOriginalPrice: 'original_price'
        },
        couponBag: {
          productCode: 'uuid',
          productName: 'name',
          productImage: 'tableImage',
          productDesc: 'description'
        },
        vip: {
          productCode: 'id',
          productName: 'payMemberName',
          productImage: 'memberImage',
          productDesc: 'description'
        },
        card: {
          productCode: 'uuid',
          productName: 'name',
          productImage: 'cover',
          productDesc: 'description'
        }
      };

      if (this.popType === 'goods' && (e.productSource === 2 || e.productSource === 6)) {
        this.$message.error('直充商品不可添加');
        this.couponShow = false;
        this.popType = '';
        return;
      }

      if (this.popType === 'goods' && ![1, 2].includes(e.deliveryType)) {
        this.$message.error('只能添加邮寄商品');
        this.couponShow = false;
        this.popType = '';
        return;
      }

      if (this.popType === 'coupon' && e.couponType != '代金券') {
        this.$message.error('只能添加代金券');
        this.couponShow = false;
        this.popType = '';
        return;
      }

      if (this.popType === 'legouGoods') {
        var goodsJson = JSON.stringify({
          pic_url: e.pic_url,
          price: e.price,
          name: e.name,
          discount_rate: e.discount_rate,
          original_price: e.original_price,
          id: e.id
        });
        this.ruleForm.productItemList[this.count].remark = e.price ? goodsJson : null;
      }

      godosItem.productCode = e[goodsType[this.popType].productCode];
      godosItem.productName = e[goodsType[this.popType].productName];
      godosItem.productImage = e[goodsType[this.popType].productImage];
      godosItem.productDesc = e[goodsType[this.popType].productDesc];

      if (this.popType === 'goods') {
        /*
        自提：1
        邮寄：2
        */
        godosItem.remark = e.deliveryType === 0 ? 1 : 2;
      } else {
        godosItem.remark = null;
      }

      console.log(godosItem);
      this.couponShow = false;
      this.popType = '';
      return;

      if (this.popType != 'couponBag') {
        godosItem.productOriginalPrice = e[goodsType[this.popType].productOriginalPrice];
      }
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.addData)(_this2.ruleForm).then(function (res) {
            _this2.$router.go(-1);

            _this2.$message({
              type: 'success',
              message: '添加成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;