var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("编辑消费奖励活动")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { ref: "title", attrs: { label: "活动名称：", prop: "title" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.title,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "title", $$v)
                      },
                      expression: "ruleForm.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "isEnable",
                  attrs: { label: "状态：", prop: "isEnable" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.isEnable,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "isEnable", $$v)
                        },
                        expression: "ruleForm.isEnable"
                      }
                    },
                    _vm._l(_vm.weekList, function(item, index) {
                      return _c(
                        "el-radio",
                        { key: index, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "shortTitle",
                  attrs: { label: "活动简称：", prop: "shortTitle" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.shortTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "shortTitle", $$v)
                      },
                      expression: "ruleForm.shortTitle"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "scopeId",
                  attrs: { label: "活动范围：", prop: "scopeId" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "maxWidth",
                      attrs: { filterable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.scopeId,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "scopeId", $$v)
                        },
                        expression: "ruleForm.scopeId"
                      }
                    },
                    _vm._l(_vm.rangeList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.rangeName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "masterPicture",
                  attrs: { label: "主图：", prop: "masterPicture" }
                },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 240,
                      height: 270,
                      cover: _vm.ruleForm.masterPicture
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "masterPicture")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [_vm._v("480*540")])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "view_title" }, [_vm._v("投放规则")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "startTime",
                      attrs: { label: "活动周期：", prop: "startTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择开始时间"
                        },
                        model: {
                          value: _vm.ruleForm.startTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "startTime", $$v)
                          },
                          expression: "ruleForm.startTime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "endTime",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { "label-width": "0", prop: "endTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: !_vm.ruleForm.startTime,
                          "picker-options": _vm.pickerTime,
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": "23:59:59",
                          placeholder: "选择结束时间"
                        },
                        model: {
                          value: _vm.ruleForm.endTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "endTime", $$v)
                          },
                          expression: "ruleForm.endTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "memberType",
                  attrs: { label: "会员类型：", prop: "memberType" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.memberType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "memberType", $$v)
                        },
                        expression: "ruleForm.memberType"
                      }
                    },
                    _vm._l(_vm.memberTypeList, function(item, index) {
                      return _c(
                        "el-radio",
                        { key: index, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label) + "\n           ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "payType",
                  attrs: { label: "支付方式：", prop: "payType" }
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.ruleForm.payType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "payType", $$v)
                        },
                        expression: "ruleForm.payType"
                      }
                    },
                    _vm._l(_vm.payTypeList, function(item) {
                      return _c(
                        "el-checkbox",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label) + "\n           ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "throwType",
                      attrs: { label: "投放总量：", prop: "throwType" }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.ruleForm.throwType,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "throwType", $$v)
                            },
                            expression: "ruleForm.throwType"
                          }
                        },
                        [_vm._v("总人数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.ruleForm.throwType,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "throwType", $$v)
                            },
                            expression: "ruleForm.throwType"
                          }
                        },
                        [_vm._v("总次数")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "throwNumber",
                      attrs: { "label-width": "92px", prop: "throwNumber" }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder:
                              _vm.ruleForm.throwType === 1
                                ? "参与人数限制"
                                : "总次数限制"
                          },
                          model: {
                            value: _vm.ruleForm.throwNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "throwNumber", $$v)
                            },
                            expression: "ruleForm.throwNumber"
                          }
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(
                              _vm._s(_vm.ruleForm.throwType === 1 ? "人" : "次")
                            )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", [
                _c("p", { staticClass: "form_remark" }, [
                  _vm._v("限制参与总会员数量或参与总次数；")
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "limitType",
                      attrs: { label: "单用户限参与：", prop: "limitType" }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.ruleForm.limitType,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "limitType", $$v)
                            },
                            expression: "ruleForm.limitType"
                          }
                        },
                        [_vm._v("总可参与")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.ruleForm.limitType,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "limitType", $$v)
                            },
                            expression: "ruleForm.limitType"
                          }
                        },
                        [_vm._v("单日可参与")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "number",
                      attrs: { "label-width": "50px", prop: "number" }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          model: {
                            value: _vm.ruleForm.number,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "number", $$v)
                            },
                            expression: "ruleForm.number"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v("次")])],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "isMerge",
                  attrs: { label: "合并多个流水：", prop: "isMerge" }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.ruleForm.isMerge,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "isMerge", $$v)
                        },
                        expression: "ruleForm.isMerge"
                      }
                    },
                    [_vm._v("不支持")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.isMerge,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "isMerge", $$v)
                        },
                        expression: "ruleForm.isMerge"
                      }
                    },
                    [_vm._v("支持")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v(
                      "可设置是否支持顾客活动期间多笔订单的消费流水，来合并计算是否满足活动门槛"
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "view_title" }, [_vm._v("活动门槛")]),
              _vm._v(" "),
              _vm._l(_vm.ruleForm.item, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("el-divider"),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "form_title" },
                      [
                        _vm._v(
                          "活动阶梯——" + _vm._s(index + 1) + "\n           "
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认删除？" },
                            on: {
                              confirm: function($event) {
                                return _vm.delRuleEquity(index)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "danger",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        ref: "item." + index + ".money",
                        refInFor: true,
                        attrs: {
                          label: "消费金额满：",
                          prop: "item." + index + ".money",
                          rules: [{ required: true, message: "请输入金额" }]
                        }
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "form_input",
                            model: {
                              value: item.money,
                              callback: function($$v) {
                                _vm.$set(item, "money", $$v)
                              },
                              expression: "item.money"
                            }
                          },
                          [_c("template", { slot: "append" }, [_vm._v("元")])],
                          2
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        ref: "item." + index + ".rightsId",
                        refInFor: true,
                        attrs: {
                          label: "选择权益：",
                          prop: "item." + index + ".rightsId",
                          rules: [{ required: true, message: "请选择权益" }]
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "", placeholder: "请选择" },
                            model: {
                              value: item.rightsId,
                              callback: function($$v) {
                                _vm.$set(item, "rightsId", $$v)
                              },
                              expression: "item.rightsId"
                            }
                          },
                          _vm._l(_vm.rightsIdList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.rightName, value: item.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _vm.ruleForm.item.length < 3
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.addrightsId }
                        },
                        [_vm._v("添加活动阶梯")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "remark",
                  attrs: { prop: "remark", label: "规则介绍：" }
                },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { width: 750, height: 500 },
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "remark", $$v)
                      },
                      expression: "ruleForm.remark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("保存活动")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }