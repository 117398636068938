var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "excel", class: _vm.type == "btn" ? "btnExcel" : "" },
    [
      _c("input", {
        ref: "excel-upload-input",
        staticClass: "excel-upload-input",
        attrs: { type: "file", accept: ".xlsx, .xls" },
        on: { change: _vm.handleClick }
      }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "btn",
          attrs: {
            size: "mini",
            loading: _vm.loading,
            type: "primary",
            icon: "el-icon-upload2"
          },
          on: { click: _vm.handleUpload }
        },
        [_vm._v("\n    " + _vm._s(_vm.btnText) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }