var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("优惠券核销记录详情")]),
    _vm._v(" "),
    _c("p", { staticClass: "appli_tit" }, [_vm._v("\n      核销信息\n    ")]),
    _vm._v(" "),
    _c("div", { staticClass: "appli_box box_info" }, [
      _c("ul", { staticClass: "border_top" }, [
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("会员手机号：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.couponLog.uMobile))
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("会员姓名：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.couponLog.uName))
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("机构编码：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.order.institutionCode))
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("订单号：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.couponLog.orderNo))
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "border_top", staticStyle: { "border-bottom": "none" } },
        [
          _c("li", [
            _c("p", { staticClass: "li_tit" }, [_vm._v("核销时间：")]),
            _vm._v(" "),
            _c("p", { staticClass: "li_txt" }, [
              _vm._v(_vm._s(_vm.couponLog.usedTime))
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "li_tit" }, [_vm._v("核销人姓名：")]),
            _vm._v(" "),
            _c("p", { staticClass: "li_txt" }, [
              _vm._v(_vm._s(_vm.couponLog.sysUsername))
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "li_tit" }, [_vm._v("核销人手机号：")]),
            _vm._v(" "),
            _c("p", { staticClass: "li_txt" }, [
              _vm._v(_vm._s(_vm.couponLog.sysMobile))
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "li_tit" }, [_vm._v("核销工号：")]),
            _vm._v(" "),
            _c("p", { staticClass: "li_txt" }, [
              _vm._v(_vm._s(_vm.couponLog.sysJobNumber))
            ])
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "appli_tit" }, [_vm._v("\n      订单信息\n    ")]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.orderItem, border: "" }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "goodsCode",
                label: "商品编码",
                align: "center",
                "min-width": "280"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "goodsName",
                align: "center",
                label: "商品名称",
                "min-width": "280"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "goodsNumber",
                align: "center",
                label: "商品数量",
                "min-width": "150"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "amountSale",
                align: "center",
                label: "销售金额",
                "min-width": "150"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "record_order" }, [
          _c("ul", [
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("应收合计：")]),
              _vm._v(" "),
              _c("p", { staticClass: "li_txt" }, [
                _vm._v(_vm._s(_vm.order.amountPayable) + "元")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("优惠金额：")]),
              _vm._v(" "),
              _c("p", { staticClass: "li_txt" }, [
                _vm._v(_vm._s(_vm.order.amountDiscount) + "元")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("实付金额：")]),
              _vm._v(" "),
              _c("p", { staticClass: "li_txt" }, [
                _vm._v(_vm._s(_vm.order.amountRealpay) + "元")
              ])
            ])
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "appli_tit" }, [_vm._v("\n      优惠券详情\n    ")]),
    _vm._v(" "),
    _c("div", { staticClass: "appli_box appli_coupon_dea" }, [
      _c("div", { staticClass: "coupon_dea_top" }, [
        _c("div", { staticClass: "coupon_dea_top_left" }, [
          _c("img", { attrs: { src: _vm.imgSrc + _vm.coupon.mainImage } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "coupon_dea_top_right" }, [
          _c("ul", [
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("优惠券面额：")]),
              _vm._v(" "),
              _c("p", { staticClass: "li_txt color" }, [
                _vm._v(_vm._s(_vm.coupon.denomination) + "元")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("优惠券类型：")]),
              _vm._v(" "),
              _c("p", { staticClass: "li_txt color" }, [
                _vm._v(_vm._s(_vm.coupon.couponType))
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("费用出资方：")]),
              _vm._v(" "),
              _c("p", { staticClass: "li_txt" }, [
                _vm._v(_vm._s(_vm.coupon.costType))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("优惠券名称：")]),
              _vm._v(" "),
              _c("p", { staticClass: "li_txt" }, [
                _vm._v(_vm._s(_vm.coupon.name))
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("优惠券编码：")]),
              _vm._v(" "),
              _c("p", { staticClass: "li_txt" }, [
                _vm._v(_vm._s(_vm.coupon.uuid))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("优惠券描述：")]),
              _vm._v(" "),
              _c("p", { staticClass: "li_txt" }, [
                _vm._v(_vm._s(_vm.coupon.descrition))
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "coupon_dea_top" }, [
        _c("div", { staticClass: "coupon_dea_top_left" }),
        _vm._v(" "),
        _c("div", { staticClass: "coupon_dea_top_right" }, [
          _c("ul", [
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("优惠券有效期：")]),
              _vm._v(" "),
              _vm.coupon.validTimeType === 2
                ? _c("p", { staticClass: "li_txt color" }, [
                    _vm._v(
                      "\n\t\t\t\t  领取后" +
                        _vm._s(_vm.getValidDay(_vm.coupon)) +
                        "天内有效"
                    )
                  ])
                : _c("p", { staticClass: "li_txt color" }, [
                    _vm._v(
                      _vm._s(_vm.coupon.validBeginTime) +
                        "—" +
                        _vm._s(_vm.coupon.validEndTime)
                    )
                  ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("销售价格：")]),
              _vm._v(" "),
              _vm.coupon.buyType === 1
                ? _c("p", { staticClass: "li_txt color" }, [
                    _vm.coupon.price == 0
                      ? _c("span", [_vm._v("免费领取")])
                      : _c("span", [_vm._v(_vm._s(_vm.coupon.price) + "元")])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.coupon.buyType === 2
                ? _c("p", { staticClass: "li_txt color" }, [
                    _vm.coupon.integral == 0
                      ? _c("span", [_vm._v("免费领取")])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.coupon.integral) + "积分")
                        ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.coupon.buyType === 3
                ? _c("p", { staticClass: "li_txt color" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.coupon.price) +
                        "元+" +
                        _vm._s(_vm.coupon.integral) +
                        "积分\n              "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("使用门槛金额：")]),
              _vm._v(" "),
              _c("p", { staticClass: "li_txt color" }, [
                _vm._v(_vm._s(_vm.coupon.doorsillMoney) + "元")
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "coupon_dea_top" }, [
        _c("div", { staticClass: "coupon_dea_top_left" }),
        _vm._v(" "),
        _c("div", { staticClass: "coupon_dea_top_right" }, [
          _c("ul", [
            _c("li", [
              _c("p", { staticClass: "li_tit" }, [_vm._v("使用说明：")]),
              _vm._v(" "),
              _c("p", {
                staticClass: "li_txt",
                staticStyle: { "font-weight": "500" },
                domProps: { innerHTML: _vm._s(_vm.coupon.instructions) }
              })
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }