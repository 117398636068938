var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          ref: "wForm",
          attrs: {
            "form-data": _vm.formData,
            isSetRules: "",
            isTitle: false,
            "rule-form": _vm.ruleForm
          },
          on: {
            submitForm: _vm.submitForm,
            goScrollIntoView: _vm.goScrollIntoView
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "storeCode" }, slot: "storeCode" },
            [
              _vm.ruleForm.storeRange == 2
                ? _c(
                    "el-form-item",
                    {
                      ref: "name",
                      attrs: { label: "限制门店：", prop: "storeCode" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form_input",
                          attrs: {
                            size: "small",
                            placeholder: "请选择限制门店",
                            filterable: "",
                            autocomplete: "off"
                          },
                          model: {
                            value: _vm.ruleForm.storeCode,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "storeCode", $$v)
                            },
                            expression: "ruleForm.storeCode"
                          }
                        },
                        _vm._l(_vm.storeList, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.storeId,
                              attrs: {
                                label:
                                  item.shortName + "（" + item.storeCode + "）",
                                value: item.storeCode
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    item.shortName +
                                      "（" +
                                      item.storeCode +
                                      "）"
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "labelContent" }, slot: "labelContent" },
            [
              _c(
                "el-form-item",
                {
                  ref: "labelContent",
                  attrs: { prop: "labelContent", label: "可参与人群：" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: {
                        filterable: "",
                        placeholder: "输入标签名称搜索~",
                        "value-key": "id",
                        clearable: ""
                      },
                      model: {
                        value: _vm.ruleForm.labelContent,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "labelContent", $$v)
                        },
                        expression: "ruleForm.labelContent"
                      }
                    },
                    _vm._l(_vm.userLabelList, function(so) {
                      return _c("el-option", {
                        key: so.id,
                        attrs: { label: "" + so.labelName, value: so.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "labelContentNo" }, slot: "labelContentNo" },
            [
              _c(
                "el-form-item",
                {
                  ref: "labelContentNo",
                  attrs: { prop: "labelContentNo", label: "黑名单人群：" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: {
                        filterable: "",
                        placeholder: "输入标签名称搜索~",
                        "value-key": "id",
                        clearable: ""
                      },
                      model: {
                        value: _vm.ruleForm.labelContentNo,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "labelContentNo", $$v)
                        },
                        expression: "ruleForm.labelContentNo"
                      }
                    },
                    _vm._l(_vm.userLabelList, function(so) {
                      return _c("el-option", {
                        key: so.id,
                        attrs: { label: "" + so.labelName, value: so.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.returnType == 3
            ? _c(
                "div",
                { attrs: { slot: "returnDays" }, slot: "returnDays" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "退还积分天数：", prop: "returnDays" } },
                    [
                      _c("el-input-number", {
                        staticClass: "form_input",
                        attrs: { controls: false, min: 1, max: 365 },
                        model: {
                          value: _vm.ruleForm.returnDays,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "returnDays", $$v)
                          },
                          expression: "ruleForm.returnDays"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.type == 1
            ? _c(
                "div",
                { attrs: { slot: "giveRule" }, slot: "giveRule" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "giveRule",
                      attrs: { label: "赠送规则：", prop: "giveRule" }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.giveRule,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "giveRule", $$v)
                            },
                            expression: "ruleForm.giveRule"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("依次递增")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("倍数递增")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.type == 1
            ? _c(
                "div",
                { attrs: { slot: "giveScore" }, slot: "giveScore" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "giveScore",
                      attrs: { label: "赠送基础积分：", prop: "giveScore" }
                    },
                    [
                      _c("el-input-number", {
                        attrs: { controls: false, min: 1 },
                        model: {
                          value: _vm.ruleForm.giveScore,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "giveScore", $$v)
                          },
                          expression: "ruleForm.giveScore"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.giveRule > 0 && _vm.ruleForm.type == 1
            ? _c(
                "div",
                { attrs: { slot: "num" }, slot: "num" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.ruleForm.giveRule == 1
                            ? "递增分数："
                            : "积分倍数：",
                        prop: "num"
                      }
                    },
                    [
                      _c("el-input-number", {
                        attrs: { controls: false, min: 1 },
                        model: {
                          value: _vm.ruleForm.num,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "num", $$v)
                          },
                          expression: "ruleForm.num"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "form_remark" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.ruleForm.giveRule == 1 ? "积分" : "倍") +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.type == 2
            ? _c(
                "div",
                { attrs: { slot: "giveType" }, slot: "giveType" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "giveType",
                      attrs: { label: "赠送形式：", prop: "giveType" }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.giveType,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "giveType", $$v)
                            },
                            expression: "ruleForm.giveType"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("完成后获得")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("单次点亮获得")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.giveType == 1 && _vm.ruleForm.type == 2
            ? _c(
                "div",
                { attrs: { slot: "sendType" }, slot: "sendType" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "sendType",
                      attrs: { label: "赠送类型：", prop: "sendType" }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.sendType,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "sendType", $$v)
                            },
                            expression: "ruleForm.sendType"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("积分")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("优惠券")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("积分+优惠券")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.giveType == 1 && [1, 3].includes(_vm.ruleForm.sendType)
            ? _c(
                "div",
                { attrs: { slot: "scoreNum" }, slot: "scoreNum" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "scoreNum",
                      attrs: { label: "完成赠送积分：", prop: "scoreNum" }
                    },
                    [
                      _c("el-input-number", {
                        attrs: { controls: false, min: 1 },
                        model: {
                          value: _vm.ruleForm.scoreNum,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "scoreNum", $$v)
                          },
                          expression: "ruleForm.scoreNum"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "form_remark" }, [
                        _vm._v("\n          积分\n        ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.giveType == 1 && [2, 3].includes(_vm.ruleForm.sendType)
            ? _c(
                "div",
                { attrs: { slot: "couponUuid" }, slot: "couponUuid" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "couponUuid",
                      attrs: { label: "完成赠送优惠券：", prop: "couponUuid" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入编码" },
                        model: {
                          value: _vm.ruleForm.couponUuid,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "couponUuid", $$v)
                          },
                          expression: "ruleForm.couponUuid"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: {
                            click: function($event) {
                              return _vm.goSelectCoupon("coupon")
                            }
                          }
                        },
                        [_vm._v("选择优惠券")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", icon: "el-icon-plus" },
                          on: {
                            click: function($event) {
                              return _vm.goSelectCoupon("couponBag")
                            }
                          }
                        },
                        [_vm._v("选择优惠券包")]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "form_remark" }, [
                        _vm._v("可以选择优惠券编码或者券包编码")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "couponName",
                      attrs: { label: "礼品名称：", prop: "couponName" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { placeholder: "请输入礼品名称" },
                        model: {
                          value: _vm.ruleForm.couponName,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "couponName", $$v)
                          },
                          expression: "ruleForm.couponName"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "form_remark" }, [
                        _vm._v("优惠券名称，用于弹窗提示礼品文案")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "pointList" }, slot: "pointList" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "参与门店：", prop: "pointList" } },
                [
                  _vm._l(_vm.ruleForm.pointList, function(item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "",
                              "label-width": "0",
                              rules: _vm.rules.storeCode,
                              prop: "pointList." + index + ".storeCode"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "form_input",
                                attrs: {
                                  size: "small",
                                  placeholder: "请选择门店",
                                  filterable: "",
                                  autocomplete: "off"
                                },
                                model: {
                                  value: item.storeCode,
                                  callback: function($$v) {
                                    _vm.$set(item, "storeCode", $$v)
                                  },
                                  expression: "item.storeCode"
                                }
                              },
                              _vm._l(_vm.storeList, function(item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.storeId,
                                    attrs: {
                                      label:
                                        item.shortName +
                                        "（" +
                                        item.storeCode +
                                        "）",
                                      value: item.storeCode
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          item.shortName +
                                            "（" +
                                            item.storeCode +
                                            "）"
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-circle-plus-outline"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.addPointItem(index)
                                  }
                                }
                              },
                              [_vm._v("\n              添加点位\n            ")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "",
                              "label-width": "0",
                              rules: _vm.rules.pointParamList,
                              prop: "pointList." + index + ".pointParamList"
                            }
                          },
                          [
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  size: "mini",
                                  data: item.pointParamList,
                                  "max-height": "800px",
                                  border: "",
                                  "header-cell-class-name": "table_header_cell"
                                }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "点位名称",
                                    align: "center",
                                    "min-width": "100"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "pointList." +
                                                  index +
                                                  ".pointParamList." +
                                                  scope.$index +
                                                  ".pointName",
                                                refInFor: true,
                                                attrs: {
                                                  prop:
                                                    "pointList." +
                                                    index +
                                                    ".pointParamList." +
                                                    scope.$index +
                                                    ".pointName",
                                                  rules: _vm.rules.pointName
                                                }
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: "点位名称"
                                                  },
                                                  model: {
                                                    value: scope.row.pointName,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "pointName",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.pointName"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "点位地址",
                                    align: "center",
                                    "min-width": "100"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "pointList." +
                                                  index +
                                                  ".pointParamList." +
                                                  scope.$index +
                                                  ".address",
                                                refInFor: true,
                                                attrs: {
                                                  prop:
                                                    "pointList." +
                                                    index +
                                                    ".pointParamList." +
                                                    scope.$index +
                                                    ".address",
                                                  rules: _vm.rules.address
                                                }
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: "点位地址"
                                                  },
                                                  model: {
                                                    value: scope.row.address,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "address",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.address"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "经纬度",
                                    align: "center",
                                    width: "270"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "flex_between" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    ref:
                                                      "pointList." +
                                                      index +
                                                      ".pointParamList." +
                                                      scope.$index +
                                                      ".longitude",
                                                    refInFor: true,
                                                    attrs: {
                                                      prop:
                                                        "pointList." +
                                                        index +
                                                        ".pointParamList." +
                                                        scope.$index +
                                                        ".longitude",
                                                      rules: _vm.rules.longitude
                                                    }
                                                  },
                                                  [
                                                    _c("el-input-number", {
                                                      staticClass:
                                                        "form_grade_number",
                                                      attrs: {
                                                        controls: false
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.longitude,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "longitude",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.longitude"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    ref:
                                                      "pointList." +
                                                      index +
                                                      ".pointParamList." +
                                                      scope.$index +
                                                      ".latitude",
                                                    refInFor: true,
                                                    attrs: {
                                                      prop:
                                                        "pointList." +
                                                        index +
                                                        ".pointParamList." +
                                                        scope.$index +
                                                        ".latitude",
                                                      rules: _vm.rules.latitude
                                                    }
                                                  },
                                                  [
                                                    _c("el-input-number", {
                                                      staticClass:
                                                        "form_grade_number",
                                                      attrs: {
                                                        controls: false
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.latitude,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "latitude",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.latitude"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _vm.ruleForm.type == 2 &&
                                _vm.ruleForm.giveType == 2
                                  ? _c("el-table-column", {
                                      attrs: {
                                        label: "点位积分",
                                        align: "center",
                                        width: "270"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    ref:
                                                      "pointList." +
                                                      index +
                                                      ".pointParamList." +
                                                      scope.$index +
                                                      ".scoreNum",
                                                    refInFor: true,
                                                    attrs: {
                                                      prop:
                                                        "pointList." +
                                                        index +
                                                        ".pointParamList." +
                                                        scope.$index +
                                                        ".scoreNum",
                                                      rules: _vm.rules.scoreNum
                                                    }
                                                  },
                                                  [
                                                    _c("el-input-number", {
                                                      staticClass:
                                                        "form_grade_number",
                                                      attrs: {
                                                        min: 0,
                                                        controls: false
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.scoreNum,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "scoreNum",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.scoreNum"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ruleForm.type == 2 &&
                                _vm.ruleForm.giveType == 2
                                  ? _c("el-table-column", {
                                      attrs: {
                                        label: "点位优惠券",
                                        align: "center",
                                        width: "270"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      icon: "el-icon-plus"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.goSelectItemCoupon(
                                                          "coupon",
                                                          index,
                                                          scope.$index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("选择优惠券")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "success",
                                                      icon: "el-icon-plus"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.goSelectItemCoupon(
                                                          "couponBag",
                                                          index,
                                                          scope.$index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("选择优惠券包")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    ref:
                                                      "pointList." +
                                                      index +
                                                      ".pointParamList." +
                                                      scope.$index +
                                                      ".couponUuid",
                                                    refInFor: true,
                                                    attrs: {
                                                      prop:
                                                        "pointList." +
                                                        index +
                                                        ".pointParamList." +
                                                        scope.$index +
                                                        ".couponUuid",
                                                      rules:
                                                        _vm.rules.couponUuid
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          scope.row.couponUuid
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ruleForm.type == 2 &&
                                _vm.ruleForm.giveType == 2
                                  ? _c("el-table-column", {
                                      attrs: {
                                        label: "点位优惠券礼品名称",
                                        align: "center",
                                        width: "270"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c("el-input", {
                                                  model: {
                                                    value: scope.row.couponName,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "couponName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.couponName"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "form_remark"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "优惠券名称，用于弹窗提示礼品文案"
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "点位状态",
                                    align: "center",
                                    width: "100px"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "pointList." +
                                                  index +
                                                  ".pointParamList." +
                                                  scope.$index +
                                                  ".status",
                                                refInFor: true,
                                                attrs: {
                                                  prop:
                                                    "pointList." +
                                                    index +
                                                    ".pointParamList." +
                                                    scope.$index +
                                                    ".status",
                                                  rules: _vm.rules.status
                                                }
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#13ce66",
                                                    "inactive-color": "#ff4949",
                                                    "active-value": 1,
                                                    "inactive-value": 2
                                                  },
                                                  model: {
                                                    value: scope.row.status,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "status",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.status"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "是否显示点位",
                                    align: "center",
                                    width: "100px"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "pointList." +
                                                  index +
                                                  ".pointParamList." +
                                                  scope.$index +
                                                  ".isShow",
                                                refInFor: true,
                                                attrs: {
                                                  prop:
                                                    "pointList." +
                                                    index +
                                                    ".pointParamList." +
                                                    scope.$index +
                                                    ".isShow",
                                                  rules: _vm.rules.isShow
                                                }
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#13ce66",
                                                    "inactive-color": "#ff4949",
                                                    "active-value": 1,
                                                    "inactive-value": 2
                                                  },
                                                  model: {
                                                    value: scope.row.isShow,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "isShow",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.isShow"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "点位图片",
                                    align: "center",
                                    width: "120px"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "pointList." +
                                                  index +
                                                  ".pointParamList." +
                                                  scope.$index +
                                                  ".pointPic",
                                                refInFor: true,
                                                attrs: {
                                                  prop:
                                                    "pointList." +
                                                    index +
                                                    ".pointParamList." +
                                                    scope.$index +
                                                    ".pointPic",
                                                  rules: _vm.rules.pointPic
                                                }
                                              },
                                              [
                                                _c("add-load", {
                                                  staticClass: "img_list",
                                                  attrs: {
                                                    width: 100,
                                                    height: 100,
                                                    cover: scope.row.pointPic
                                                  },
                                                  on: {
                                                    setCover: function($event) {
                                                      return _vm.setItemCover(
                                                        $event,
                                                        index,
                                                        scope.$index
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "操作",
                                    align: "center",
                                    fixed: "right",
                                    width: "120"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            scope.row.id
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.getWxCode(
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    点位小程序码\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.ruleForm.pointList.length > 0
                    ? _c("el-divider")
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.addStore }
                    },
                    [_vm._v("\n          添加门店\n        ")]
                  )
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              url: _vm.url,
              "qr-code": _vm.qrCode,
              "coupon-u-uid": _vm.couponUUid
            },
            on: {
              closePop: function($event) {
                _vm.popShow = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }