var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            "search-data": _vm.searchData
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "120"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { class: scope.row.status == 1 ? "success" : "danger" },
                      [_vm._v(_vm._s(scope.row.status == 1 ? "启用" : "停用"))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "storeName",
              label: "门店名称",
              align: "center",
              width: "120"
            },
            slot: "storeName",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getStoreName(scope.row.storeCode)) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              fixed: "right",
              align: "center",
              width: "120"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/storeActivity/add?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确认删除此该活动？" },
                        on: {
                          confirm: function($event) {
                            return _vm.delGrade(scope.row, scope.$index)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "danger",
                            attrs: {
                              slot: "reference",
                              type: "text",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("\n            删除\n          ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }