var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addCoupon" }, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "add_box" }, [
      _c("p", { staticClass: "tit" }, [_vm._v("选择功能模块")]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "box_list" },
        [
          _vm._l(_vm.tabList, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "item",
                class: item.path ? "" : "errActive",
                on: {
                  click: function($event) {
                    return _vm.setTab(item.path)
                  }
                }
              },
              [
                _c("div", { staticClass: "item_left" }, [
                  _c("img", { attrs: { src: item.imgPath } })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item_right" }, [
                  _c("p", { staticClass: "item_title" }, [
                    _vm._v(_vm._s(item.tit))
                  ]),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "item_text",
                    domProps: { innerHTML: _vm._s(item.txtArr) }
                  })
                ])
              ]
            )
          }),
          _vm._v(" "),
          _vm.tabList.length % 2 == 1
            ? _c("li", {
                staticClass: "item",
                staticStyle: { border: "none", "box-shadow": "none" }
              })
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }