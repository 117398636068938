"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      infoOption: [{
        title: "计划ID",
        val: "0000000000000",
        default: "-"
      }, {
        title: "计划名称",
        val: "",
        default: "-"
      }, {
        title: "卡类型",
        val: "",
        default: "-"
      }, {
        title: "消费积分",
        val: "",
        default: "-"
      }, {
        title: "记名类型",
        val: "",
        default: "-"
      }, {
        title: "卡种名称",
        val: "",
        default: "-"
      }, {
        title: "卡种名称",
        val: "",
        default: "-"
      }, {
        title: "激活后有效期",
        val: "",
        default: "-"
      }, {
        title: "制卡数量",
        val: "",
        default: "-"
      }, {
        title: "卡号区间",
        val: "",
        default: "-"
      }, {
        title: "制卡商",
        val: "",
        default: "-"
      }, {
        title: "备注",
        val: "",
        default: "-"
      }, {
        title: "卡面图片",
        val: "",
        default: "-"
      }],
      params: {
        list: []
      },
      rules: {}
    };
  },
  methods: {
    onConfirm: function onConfirm() {},
    onCancel: function onCancel() {},
    onAddRow: function onAddRow() {
      this.params.list.push({});
    },
    onDeleteRow: function onDeleteRow(index) {
      this.params.list.splice(index, 1);
    }
  }
};
exports.default = _default;