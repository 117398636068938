"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _coupon = require("@/api/coupon");

var _tableList = _interopRequireDefault(require("@/components/tableList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default
  },
  data: function data() {
    return {
      columnId: '',
      deaPath: '/coupon/coupon4/editList?id=',
      operation: 2,
      total: 0,
      btn: '编辑',
      btnType: 'primary',
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      name: '',
      status: '',
      options: [{
        value: 0,
        label: '全部状态'
      }, {
        value: 1,
        label: '启用'
      }, {
        value: -1,
        label: '停用'
      }],
      groupId: '',
      tabTh: [//					{
      //						tit: "ID",
      //						prop: 'id'
      //					},
      {
        tit: '栏目项名称',
        prop: 'name',
        width: 280
      }, {
        tit: '栏目组名称',
        prop: 'groupName',
        width: 280
      }, {
        tit: '创建时间',
        prop: 'createTime',
        sortable: true,
        width: 180,
        align: 'center'
      }, {
        tit: '修改时间',
        prop: 'operatorTime',
        sortable: true,
        width: 180,
        align: 'center'
      }, {
        tit: '模板类型',
        prop: 'templateCode',
        width: 180,
        align: 'center'
      }, {
        tit: '数据条目数',
        prop: 'attrIds',
        width: 110,
        align: 'center'
      }, {
        tit: '排序号',
        prop: 'sort',
        sortable: true,
        width: 110,
        align: 'center'
      }, {
        tit: '状态',
        prop: 'status',
        width: 110,
        align: 'center'
      }],
      list: [],
      pageList: [],
      olumnGroup: []
    };
  },
  watch: {},
  destroyed: function destroyed() {
    var initInfo = {
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      columnId: this.columnId,
      name: this.name,
      status: this.status,
      groupId: this.groupId
    };
    this.$store.dispatch('buffer/setActivityList', initInfo);
  },
  created: function created() {
    var _this = this;

    (0, _coupon.listColumnGroup)().then(function (res) {
      console.log(res);
      _this.olumnGroup = res.data;
    });

    if (this.$store.state.buffer.activityList.pageNo) {
      var initInfo = this.$store.state.buffer.activityList;
      this.pageNo = initInfo.pageNo;
      this.pageSize = initInfo.pageSize;
      this.columnId = initInfo.columnId;
      this.name = initInfo.name;
      this.status = initInfo.status;
      this.groupId = initInfo.groupId;
    }

    this.getList();
  },
  methods: {
    remove: function remove() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.name = '';
      this.status = '';
      this.groupId = '';
      this.getItemPage();
    },
    setChange: function setChange() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.name = '';
      this.status = '';
      this.groupId = '';
      this.getItemPage();
    },
    getList: function getList() {
      var _this2 = this;

      this.openLoading();
      (0, _coupon.listColumnPage)().then(function (res) {
        _this2.openLoading().close();

        _this2.list = res.data;

        if (!_this2.columnId) {
          _this2.columnId = _this2.list[0].columnId;
        }

        _this2.getItemPage();
      }).catch(function (res) {
        _this2.openLoading().close();
      });
    },
    getItemPage: function getItemPage(e) {
      var _this3 = this;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        parentId: this.columnId,
        name: this.name,
        status: this.status,
        groupId: this.groupId
      };
      this.openLoading();
      (0, _coupon.listColumnItemPage)(data).then(function (res) {
        _this3.openLoading().close();

        _this3.pageList = res.data.items;
        _this3.total = res.data.totalNum;

        _this3.pageList.forEach(function (item) {
          // console.log(JSON.parse(item.attrIds).length)
          item.attrIds = JSON.parse(item.attrIds).length;

          if (item.status === 1) {
            item.status = '启用';
          } else {
            item.status = '停用';
          }
        });
      }).catch(function (res) {
        _this3.openLoading().close();
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/coupon/coupon4/editList?columnId=' + this.columnId);
    },
    setBtn: function setBtn(scope) {
      console.log(scope);
      this.$router.push('/coupon/coupon4/editList?id=' + scope.columnId); // this.$router.push('/coupon/coupon4/column?id=' + scope.columnId)
    }
  }
};
exports.default = _default;