"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

var _label = _interopRequireDefault(require("@/components/label/label.vue"));

var _getCoupon2 = require("@/api/getCoupon.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    coupon: _getCoupon.default,
    labelPop: _label.default
  },
  data: function data() {
    return {
      labelShow: false,
      couponShow: false,
      channelType: '',
      count: '',
      list: [],
      tabTh: [{
        prop: 'uuid',
        label: '优惠券编码',
        width: 70
      }, {
        prop: 'name',
        label: '优惠券名称',
        width: 70
      }, {
        prop: 'couponType',
        label: '优惠券类型',
        width: 70
      }, {
        prop: 'time',
        label: '有效期',
        width: 70
      }],
      rules: {
        onlineType: [{
          required: true,
          message: '请选择上线时间类型'
        }],
        centerStatus: [{
          required: true,
          message: '请选择状态'
        }],
        time: [{
          required: true,
          message: '请选择上线时间'
        }],
        list: [{
          required: true,
          message: '请选择优惠券并填写数量'
        }]
      },
      form: {
        onlineType: 1,
        time: [],
        list: [],
        centerStatus: '',
        labelId: [],
        couponCode: [],
        putNumber: [],
        labelName: []
      }
    };
  },
  created: function created() {
    this.id = Number(this.$route.query.id);
    this.channelType = this.$route.query.channelType;

    if (this.id) {
      (0, _getCoupon2.channelRecenter)(this.id).then(function (res) {
        var obj = {
          uuid: res.data.couponCode
        };
      });
    }
  },
  methods: {
    numberChange: function numberChange(val, index) {
      if (this.form.list[index].totalNumber > 0 && val > this.form.list[index].totalNumber) {
        this.form.list[index].putNumber = this.form.list[index].totalNumber;
      }
    },
    getLabel: function getLabel(index) {
      this.count = index;
      this.labelShow = true;
      var obj = this.form.list[index];
      this.$refs.labels.clearTable(obj);
    },
    getCoupon: function getCoupon() {
      this.$refs.couponPop.init();
      this.couponShow = true;
    },
    onSubmit: function onSubmit() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var data = [];

          _this.form.list.forEach(function (item) {
            if (item.putNumber) {
              var recenterLabel = [];
              var obj = {};

              if (item.labelId.length > 0) {
                item.labelId.forEach(function (row, key) {
                  recenterLabel.push({
                    labelId: row,
                    labelName: item.labelName[key]
                  });
                });
              }

              obj = {
                channelType: _this.channelType,
                couponCode: item.uuid,
                centerStatus: _this.form.centerStatus,
                onlineType: _this.form.onlineType,
                putNumber: item.putNumber,
                recenterLabel: recenterLabel
              };

              if (_this.form.onlineType == 1) {
                obj.onlineStartTime = _this.form.time[0] || '';
                obj.onlineEndTime = _this.form.time[1] || '';
              }

              data.push(obj);
            }
          });

          if (data.length > 0) {
            console.log(JSON.stringify(data));
            (0, _getCoupon2.addRecenter)({
              recenterInfo: data
            }).then(function (res) {
              console.log(res);

              _this.$router.go(-1);
            });
          } else {
            _this.$message.error('请填写优惠券投放数量');
          }
        } else {
          console.log(1111111111111111111111111);
        }
      });
    },
    setTab: function setTab() {},
    setCoupons: function setCoupons(e) {
      console.log(e);
      this.form.list = e;
      this.$refs['form'].validateField('list');
      this.couponShow = false;
    },
    closeCouponPop: function closeCouponPop() {
      this.couponShow = false;
    },

    /*
      保存标签
      */
    addLabel: function addLabel(e) {
      var _this2 = this;

      this.form.list[this.count].labelId = [];
      this.form.list[this.count].labelName = [];
      e.forEach(function (item) {
        _this2.form.list[_this2.count].labelId.push(item.labelId);

        _this2.form.list[_this2.count].labelName.push(item.labelName);
      });
      this.labelShow = false;
    },
    closeLabel: function closeLabel() {
      this.labelShow = false;
    }
  }
};
exports.default = _default;