"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _member = require("@/api/member.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Print from '@/utils/print'
var _default = {
  data: function data() {
    return {
      data: {
        consumeScore: '',
        exchangeNo: '',
        exchangeNumber: '',
        exchangeOprUid: '',
        exchangeOprUname: '',
        exchangeRemark: '',
        exchangeTime: '',
        exchangeUmobile: '',
        exchangeUname: '',
        giftId: '',
        giftName: '',
        giftScore: '',
        giftType: '',
        refundsOprUname: '',
        refundsRemark: '',
        refundsStatus: '',
        refundsTime: '',
        storeCode: ''
      },
      ruleForm: {
        refundsRemark: ''
      },
      rules: {
        refundsRemark: [{
          required: true,
          message: '请填写退款备注'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.id = this.$route.query.id;
    this.openLoading();
    (0, _member.getExchangeById)(this.id).then(function (res) {
      var self = _this;

      _this.openLoading().close();

      self.fzObj(res.data, self.data); // this.data.exchangeUmobile  =  this.data.exchangeUmobile.replace(/(\d{3})\d*(\d{4})/,'$1****$2');
      // this.data.exchangeUname  =    this.noPassByName(this.data.exchangeUname)
    }).catch(function (err) {
      _this.openLoading().close();
    });
  },
  methods: {
    noPassByName: function noPassByName(str) {
      return str.substring(0, 1) + new Array(str.length).join('*'); // if (null != str && str != undefined) {
      //   if (str.length <= 3) {
      //     return "*" + str.substring(1, str.length);
      //   } else if (str.length > 3 && str.length <= 6) {
      //     return "**" + str.substring(2, str.length);
      //   } else if (str.length > 6) {
      //     return str.substring(0, 2) + "****" + str.substring(6, str.length)
      //   }
      // } else {
      //   return "";
      // }
    },
    goprint: function goprint() {
      print();
      return;
      this.$print(this.$refs.printsss);
      return;
      var newStr = document.getElementsByClassName('print')[0].innerHTML;
      var oldStr = document.body.innerHTML;
      document.body.innerHTML = newStr;
      window.print();
      document.body.innerHTML = oldStr;
      window.location.reload(); //   this.$print(this.$ref.printsss)
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = {
            id: _this2.id,
            refundsRemark: _this2.ruleForm.refundsRemark
          };

          _this2.openLoading();

          (0, _member.editExchange)(data).then(function (res) {
            _this2.$message({
              type: 'success',
              message: '退款成功'
            });

            _this2.openLoading().close();

            _this2.$router.go(-1);
          }).catch(function (err) {
            _this2.openLoading().close();
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;