"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      content: '',
      title: '新增报名类活动',
      name: '',
      time: '',
      state: '',
      cover: '',
      myEditor: 'myEditor',
      validTimeType: '1',
      beginDate: '',
      validDay: '',
      options1: [{
        value: '1',
        label: '文本'
      }, {
        value: '2',
        label: '数字'
      }, {
        value: '3',
        label: '单选项'
      }, {
        value: '4',
        label: '多选项'
      }],
      value1: '',
      list: [{
        name: '',
        type: '',
        checkbox: false,
        project: [{
          txt: ''
        }]
      }]
    };
  },
  methods: {
    addList: function addList() {
      this.list.push({
        name: '',
        type: '',
        checkbox: false,
        project: [{
          txt: ''
        }]
      });
    },
    addProject: function addProject(index) {
      if (this.list[index].project.length > 4) return;
      this.list[index].project.push({
        txt: ''
      });
    },
    setCover: function setCover(cover) {
      this.cover = cover;
    },
    change: function change(index) {
      console.log(index); // this.$refs.myEditor.createEditor(this.instructions);
    }
  }
};
exports.default = _default;