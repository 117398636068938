var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 购卡单 "),
        _c(
          "div",
          [
            _vm.orderData.orderVo.payStatus === 2
              ? _c(
                  "el-button",
                  {
                    staticStyle: { width: "150px" },
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function($event) {
                        _vm.isRefund = true
                      }
                    }
                  },
                  [_vm._v("退款")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { size: "small", "label-width": "150px" }
        },
        [
          _c("el-form-item", { attrs: { label: "购买人/单位：" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.orderData.orderVo.buyUser) +
                "（" +
                _vm._s(_vm.orderData.orderVo.phone) +
                "）\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "购卡单号：" } }, [
            _vm._v(
              "\n      " + _vm._s(_vm.orderData.orderVo.orderNo) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "购卡数量：" } }, [
            _vm._v(
              "\n      " + _vm._s(_vm.orderData.orderVo.buyNumber) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "订单金额：" } }, [
            _vm._v(
              "\n      " + _vm._s(_vm.orderData.orderVo.orderAmount) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "创建时间：" } }, [
            _vm._v(
              "\n      " + _vm._s(_vm.orderData.orderVo.createTime) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "view_title" }, [_vm._v(" 已添加购物卡 ")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "w-table",
            {
              attrs: {
                "table-data": _vm.orderData.buyRecordsVo,
                columns: _vm.columns
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  slot: "price",
                  label: "小计",
                  align: "center",
                  "min-width": "100"
                },
                slot: "price",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.cardLimitPrice * scope.row.buyNum
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "view_title" }, [_vm._v(" 付款信息 ")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "付款状态：" } }, [
            _vm.orderData.orderVo.payStatus === 0
              ? _c("span", { staticClass: "danger" }, [
                  _vm._v("未提交支付凭证")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.orderData.orderVo.payStatus === 1
              ? _c("span", { staticClass: "warning" }, [
                  _vm._v("提交支付凭证待确认")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.orderData.orderVo.payStatus === 2
              ? _c("span", { staticClass: "success" }, [
                  _vm._v("提交支付凭证已确认")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.orderData.orderVo.payStatus === 3
              ? _c("span", { staticClass: "danger" }, [_vm._v("订单取消")])
              : _vm._e(),
            _vm._v(" "),
            _vm.orderData.orderVo.payStatus === 4
              ? _c("span", { staticClass: "danger" }, [_vm._v("订单退款")])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "支付方式：" } },
            [
              _c(
                "w-table",
                {
                  attrs: {
                    "table-data": _vm.orderData.payCategoryVoList,
                    columns: _vm.columns1
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      slot: "isRebate",
                      label: "返利类型",
                      align: "center",
                      "min-width": "80"
                    },
                    slot: "isRebate",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.isRebate === 0
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("无返利")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.isRebate === 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("有返利")
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "附件：" } }, [
            _c("img", {
              staticStyle: { width: "100px", height: "100px" },
              attrs: {
                src: _vm.imgSrc + _vm.orderData.descriptionVo.payAttachment
              }
            })
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "备注：" } }, [
            _vm._v(
              "\n      " + _vm._s(_vm.orderData.descriptionVo.remark) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "view_title" }, [_vm._v(" 返利信息 ")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "返利金额：" } }, [
            _vm._v("\n      " + _vm._s(_vm.rebateTotalPrice) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "返利详情：" } },
            [
              _c(
                "w-table",
                {
                  attrs: {
                    "table-data": _vm.rebateOrderCardVoList,
                    columns: _vm.columns2
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      slot: "isRebate",
                      label: "返利类型",
                      align: "center",
                      "min-width": "80"
                    },
                    slot: "isRebate",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.isRebate === 0
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("无返利")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.isRebate === 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("有返利")
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRefund,
              expression: "isRefund"
            }
          ],
          staticClass: "fixed_center"
        },
        [
          _c(
            "div",
            { staticClass: "fixed_center_box" },
            [
              _c("div", { staticClass: "view_title " }, [
                _vm._v(" 卡片销售列表 ")
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    size: "small",
                    "label-width": "120px"
                  }
                },
                [
                  _c("el-form-item", { attrs: { label: "订单号：" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.ruleForm.orderNo) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "扣减金额：", prop: "deductPrice" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "form_input",
                          attrs: { type: "number", placeholder: "请输入金额" },
                          model: {
                            value: _vm.ruleForm.deductPrice,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "deductPrice", $$v)
                            },
                            expression: "ruleForm.deductPrice"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v(".元")])],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "实际退款：", prop: "actualRefundPrice" }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "form_input",
                          attrs: { type: "number", placeholder: "请输入金额" },
                          model: {
                            value: _vm.ruleForm.actualRefundPrice,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "actualRefundPrice", $$v)
                            },
                            expression: "ruleForm.actualRefundPrice"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v(".元")])],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "附件：", prop: "attachment" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "uploadImg",
                          attrs: {
                            action: _vm.action,
                            "list-type": "picture-card",
                            headers: _vm.headers,
                            "on-success": _vm.onSuccess,
                            "on-remove": _vm.handleRemove,
                            "show-file-list": ""
                          }
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 4 },
                          placeholder: "请输入内容"
                        },
                        model: {
                          value: _vm.ruleForm.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "remark", $$v)
                          },
                          expression: "ruleForm.remark"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "138px" },
                          attrs: { type: "info" },
                          on: {
                            click: function($event) {
                              _vm.isRefund = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("确认")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }