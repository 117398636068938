"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _vendor = require("@/api/vendor.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    form: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      checkedId: '',
      treeShow: false,
      filterText: '',
      defaultProps: {
        children: 'childNodes',
        label: 'name'
      },
      treeList: [],
      weChatList: [],
      selectType: "",
      tabTh: [{
        prop: 'name',
        label: '姓名',
        width: 100
      }, {
        prop: 'sex',
        label: '性别',
        width: 100
      }, {
        prop: 'mobile',
        label: '手机号码',
        width: 100
      }, {
        prop: 'position',
        label: '职务',
        width: 100
      }]
    };
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    getValue: function getValue(deptId) {
      var _this = this;

      (0, _vendor.getWxDeptList)().then(function (res) {
        _this.treeList = res.data;
      });

      if (deptId) {
        (0, _vendor.getWxDeptPersonList)(this.form.deptId).then(function (res) {
          _this.weChatList = res.data;
        });
      }
    },
    handleClick: function handleClick(data, checked, node) {
      if (checked == true) {
        this.checkedId = data.id;
        this.$refs.tree.setCheckedNodes([data]);
      }
    },
    childNodesChange: function childNodesChange(node) {
      this.checkedId = data.id;
      this.$refs.tree.setCheckedNodes([data]);
      var len = node.childNodes.length;

      for (var i = 0; i < len; i++) {
        node.childNodes[i].checked = false;
        this.childNodesChange(node.childNodes[i]);
      }
    },
    parentNodesChange: function parentNodesChange(node) {
      if (node.parent) {
        for (var key in node) {
          if (key == 'parent') {
            node[key].checked = true;
            this.parentNodesChange(node[key]);
          }
        }
      }
    },
    setStree: function setStree(type) {
      var _this2 = this;

      this.selectType = "vendorUser";

      if (this.treeList.length == 0) {
        (0, _vendor.getWxDeptList)().then(function (res) {
          console.log(res.data);
          _this2.treeList = res.data;
          _this2.treeShow = true;
        });
      } else {
        this.treeShow = true;
      }
    },
    clearTree: function clearTree() {
      this.$refs.tree.setCheckedKeys([]);
    },
    addTree: function addTree() {
      var _this3 = this;

      this.treeShow = false;

      if (this.$refs.tree.getCheckedNodes().length > 0) {
        (0, _vendor.getWxDeptPersonList)(this.checkedId).then(function (res) {
          if (_this3.selectType === 'vendorUser') {
            _this3.$emit('changeVendorUser', res.data);
          } else {
            _this3.weChatList = res.data;
          }
        });

        if (this.selectType != 'vendorUser') {
          this.form.deptName = this.$refs.tree.getCheckedNodes()[0].name;
          this.form.deptId = this.$refs.tree.getCheckedNodes()[0].id;
          this.$emit('submit', true);
        }
      } else {
        this.form.deptName = '';
        this.form.deptId = '';
        this.weChatList = [];
        this.$emit('submit', false);
      }
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    }
  }
};
exports.default = _default2;