"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'LegouGoods1001',
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc
    };
  },
  methods: {
    onMove: function onMove(relatedContext, draggedContext) {
      console.log(relatedContext.relatedContext.list);
    }
  }
};
exports.default = _default2;