var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column center", staticStyle: { "min-height": "720px" } },
    [
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  size: "small",
                  type: "text",
                  placeholder: "订单号/流水号"
                },
                model: {
                  value: _vm.sId,
                  callback: function($$v) {
                    _vm.sId = $$v
                  },
                  expression: "sId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    size: "small",
                    filterable: "",
                    placeholder: "机构编码"
                  },
                  model: {
                    value: _vm.sStoreId,
                    callback: function($$v) {
                      _vm.sStoreId = $$v
                    },
                    expression: "sStoreId"
                  }
                },
                _vm._l(_vm.sStoreIds, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "li_btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.goInquire }
                },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: { width: "160px", "padding-top": "22px" },
          attrs: { id: "printBill" }
        },
        [
          _c(
            "div",
            {
              staticClass: "point_title",
              staticStyle: { "font-size": "12px" }
            },
            [_vm._v("欢迎光临")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "font-size": "10px" } },
            [
              _c(
                "div",
                {
                  staticClass: "point_title",
                  staticStyle: { margin: "4px 0" }
                },
                [_vm._v("恒大店")]
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", {}, [
                _vm._v("\n        流水号：925023112900005\n      ")
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        ===========================\n      ")
              ]),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _vm._l(_vm.goodsList, function(item, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    {
                      staticClass: "goods_name",
                      staticStyle: { "max-width": "120px" }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(item.goodsName) + "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex_between" }, [
                    _c("div", [_vm._v("\n            *\n          ")]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(item.goodsNum) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(item.goodsAmount) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n            " + _vm._s(item.goodsHj) + "\n          "
                      )
                    ])
                  ])
                ])
              }),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        ==========10:53:04==========\n      ")
              ]),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("div", {}, [_vm._v("\n        会员卡号：1993834\n      ")]),
              _vm._v(" "),
              _c("div", {}, [_vm._v("\n        可用金豆：1893.47\n      ")]),
              _vm._v(" "),
              _c("div", {}, [_vm._v("\n        优惠金额：19.12\n      ")]),
              _vm._v(" "),
              _c("div", {}, [_vm._v("\n        超市省券优惠：0\n      ")]),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        ===========================\n      ")
              ]),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _c("div", [_vm._v("\n        360008021\n      ")]),
              _vm._v(" "),
              _c("div", [_vm._v("\n        余额：0.00\n      ")]),
              _vm._v(" "),
              _vm._m(5),
              _vm._v(" "),
              _c("div", [_vm._v("\n        PA231129105337251313_1\n      ")]),
              _vm._v(" "),
              _c("div", [_vm._v("\n        余额：0.00\n      ")]),
              _vm._v(" "),
              _vm._m(6),
              _vm._v(" "),
              _c("div", [_vm._v("\n        PA231129105337251313_2\n      ")]),
              _vm._v(" "),
              _c("div", [_vm._v("\n        余额：0.00\n      ")]),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        ===========================\n      ")
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        此单为退换货凭证，请妥善保管。\n      ")
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("\n        客服热线:400 999 8836\n      ")]),
              _vm._v(" "),
              _c("div", [_vm._v("\n        退货请出示积分卡。\n      ")]),
              _vm._v(" "),
              _c("div", [_vm._v("\n        此票不做任何报销凭证!\n      ")]),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        如需开发票,请扫二维码办理!\n      ")
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        发票只保留90日,建议尽快下载!\n      ")
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        查看星豆,请扫二维码或到总台查询!\n      ")
              ]),
              _vm._v(" "),
              _c("vue-qr", { attrs: { text: _vm.qrcodeData, size: 160 } })
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row flex-end",
          staticStyle: { "padding-right": "100px", "margin-top": "20px" }
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.printAll } },
            [_vm._v("js原生打印")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.billPrintClick } },
            [_vm._v("打印小票")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex", staticStyle: { "line-height": "16px" } },
      [
        _c("div", [_vm._v("店号：010030")]),
        _vm._v(" "),
        _c("div", [_vm._v("收款机:9250")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex_between" }, [
      _c("div", [_vm._v("收款员:080812")]),
      _vm._v(" "),
      _c("div", [
        _c("span", { staticStyle: { "letter-spacing": "5px" } }, [
          _vm._v("日")
        ]),
        _vm._v("期:2023-11-28\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex_between" }, [
      _c("div", { staticStyle: { width: "40px", "text-align": "center" } }, [
        _vm._v("\n          品名\n        ")
      ]),
      _vm._v(" "),
      _c("div", [_vm._v("\n          数量\n        ")]),
      _vm._v(" "),
      _c("div", [_vm._v("\n          成交价\n        ")]),
      _vm._v(" "),
      _c("div", { staticStyle: { width: "40px", "text-align": "center" } }, [
        _vm._v("\n          金额\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex_between" }, [
      _c("div", [_vm._v("实收：241.40")]),
      _vm._v(" "),
      _c("div", [_vm._v("\n          商品数量22.582\n        ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex_between" }, [
      _c("div", [_vm._v("2购物券卡(2000元)")]),
      _vm._v(" "),
      _c("div", [_vm._v("\n          226.4\n        ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex_between" }, [
      _c("div", [_vm._v("4365专享券抵扣_1")]),
      _vm._v(" "),
      _c("div", [_vm._v("\n          0\n        ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex_between" }, [
      _c("div", [_vm._v("4365专享券抵扣_2")]),
      _vm._v(" "),
      _c("div", [_vm._v("\n          15\n        ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }