"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLog = getLog;
exports.listLogPage = listLogPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
触发事件日志分页：/api/triggerLog/listLogPage
触发事件日志明细：/api/triggerLog/getLog/{id}
 */
function listLogPage(data) {
  return (0, _request.default)({
    url: '/api/triggerLog/listLogPage',
    method: 'post',
    data: data
  });
}

function getLog(data) {
  return (0, _request.default)({
    url: '/api/triggerLog/getLog/' + data,
    method: 'post'
  });
}