"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _orderDea = _interopRequireDefault(require("./components/orderDea.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      orderData: {},
      orderList: [],
      columns: [{
        label: '活动名称',
        prop: 'activityName',
        align: "left",
        width: 100
      }, {
        label: '会员类型',
        prop: 'memberTypeName',
        width: 80
      }, {
        label: '用户名称',
        prop: 'userName',
        width: 100
      }, {
        label: '用户手机号',
        prop: 'userMobile',
        width: 80
      }, {
        label: '设置总点位',
        prop: 'pointNum',
        width: 80
      }, {
        label: '领取点位',
        prop: 'receviePointNum',
        width: 80
      }, {
        label: '领取总积分',
        prop: 'totalRecevieScoreNum',
        width: 100
      }, {
        label: '门店',
        prop: 'storeCodeStr',
        align: "left",
        width: 200
      }, {
        label: '活动状态',
        prop: 'statusStr',
        width: 80
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        placeholder: '活动名称',
        value: 'activityName'
      }, {
        type: 'input',
        placeholder: '用户手机号',
        value: 'userMobile'
      }, {
        type: 'input',
        placeholder: '门店编码',
        value: 'storeCode'
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime'
      }]
    };
  },
  components: {
    orderDea: _orderDea.default
  },
  created: function created() {},
  mounted: function mounted() {
    var activityName = this.$route.query.name;
    this.$refs.wTable.setRequestValue('activityName', activityName);
    this.$refs.wTable.getList();
  },
  methods: {
    getOrderDea: function getOrderDea(row) {
      var _this = this;

      this.orderData = row;
      this.apiPost('/api/scoreGive/queryReceiveDateils', {
        id: row.scoreGiveId,
        uid: row.uid
      }).then(function (res) {
        _this.orderList = res.data;

        _this.$refs.orderDea.open();
      });
    }
  }
};
exports.default = _default;