"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon");

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    coupon: _getCoupon.default
  },
  data: function data() {
    return {
      couponShow: false,
      id: '',
      title: '新增定向投放方案',
      labelIds: [],
      ruleForm: {
        name: null,
        describe: null,
        time: null,
        coupon: []
      },
      rules: {
        name: [{
          required: true,
          message: '请输入名称'
        }],
        describe: [{
          required: true,
          message: '请输入方案描述'
        }],
        time: [{
          required: true,
          message: '请选择时间'
        }],
        coupon: [{
          required: true,
          message: '请选择优惠券'
        }]
      }
    };
  },
  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    closeCouponPop: function closeCouponPop() {
      this.couponShow = false;
    },
    setCoupons: function setCoupons(e) {
      var arr = [];

      if (e.length > 0) {
        e.forEach(function (item) {
          arr.push(item.uuid);
        });
      }

      this.ruleForm.coupon = arr;
      this.couponShow = false;
    },
    getRelevances: function getRelevances() {
      this.$refs.couponPop.init();
      var arr = [];

      if (this.ruleForm.coupon.length > 0) {
        this.ruleForm.coupon.forEach(function (item) {
          arr.push({
            linkId: item
          });
        });
      }

      this.$refs.couponPop.assignCoupon(arr);
      this.couponShow = true;
    },
    init: function init() {
      var _this = this;

      this.id = Number(this.$route.query.unid);

      if (this.id) {
        this.title = '编辑定向投放方案';
        (0, _coupon.getPutOrientationById)(this.id).then(function (res) {
          _this.ruleForm.name = res.data.name;
          _this.ruleForm.describe = res.data.descrition;
          _this.ruleForm.time = res.data.putTime;
          _this.labelIds = JSON.parse(res.data.labelIds);
          _this.ruleForm.coupon = JSON.parse(res.data.couponIds);
        });
      }
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _coupon.couponSchemeValid)(_this2.ruleForm.coupon.join('-'), _this2.ruleForm.time, _this2.ruleForm.time).then(function (res) {
            if (res.ret === 1000) {
              var obj = {
                name: _this2.ruleForm.name,
                descrition: _this2.ruleForm.describe,
                putTime: _this2.ruleForm.time,
                coupon: res.data,
                couponIdBags: _this2.ruleForm.coupon.join('-'),
                labelIds: [],
                putNumber: 0
              };

              if (_this2.id) {
                obj.labelIds = _this2.labelIds;
                sessionStorage.setItem('orientObj', JSON.stringify(obj));

                _this2.$router.push('/coupon/couponPut/affirmAdd?id=orient&unid=' + _this2.id);
              } else {
                sessionStorage.setItem('orientObj', JSON.stringify(obj));

                _this2.$router.push('/coupon/couponPut/affirmAdd?id=orient');
              }
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;