"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'JsonView',
  props: {
    json: [Object, Array],
    jsonKey: {
      type: String,
      default: ''
    },
    closed: {
      type: Boolean,
      default: true
    },
    isLast: {
      type: Boolean,
      default: true
    },
    fontSize: {
      type: Number,
      default: 13
    }
  },
  data: function data() {
    return {
      innerclosed: true
    };
  },
  computed: {
    isArray: function isArray() {
      return Object.prototype.toString.call(this.json) === '[object Array]';
    },
    length: function length() {
      return this.isArray ? this.json.length : Object.keys(this.json).length;
    },
    subfix: function subfix() {
      return (this.isArray ? ']' : '}') + (this.isLast ? '' : ',');
    },
    prefix: function prefix() {
      return this.isArray ? '[' : '{';
    },
    items: function items() {
      var _this = this;

      if (this.isArray) {
        return this.json.map(function (item) {
          var isJSON = _this.isObjectOrArray(item);

          return {
            value: isJSON ? item : JSON.stringify(item),
            isJSON: isJSON,
            key: ''
          };
        });
      }

      var json = this.json;
      return Object.keys(json).map(function (key) {
        var item = json[key];

        var isJSON = _this.isObjectOrArray(item);

        return {
          value: isJSON ? item : JSON.stringify(item),
          isJSON: isJSON,
          key: key
        };
      });
    }
  },
  created: function created() {
    var _this2 = this;

    this.innerclosed = this.closed;
    this.$watch('closed', function () {
      _this2.innerclosed = _this2.closed;
    });
  },
  methods: {
    isObjectOrArray: function isObjectOrArray(source) {
      var type = Object.prototype.toString.call(source);
      var res = type === '[object Array]' || type === '[object Object]';
      return res;
    },
    toggleClose: function toggleClose() {
      if (this.innerclosed) {
        this.innerclosed = false;
      } else {
        this.innerclosed = true;
      }
    }
  }
};
exports.default = _default;