"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var _router = _interopRequireDefault(require("../../views/deposit-entity/router"));

var _router2 = _interopRequireDefault(require("../../views/deposit-entity-rebate/router"));

var _router3 = _interopRequireDefault(require("../../views/deposit-entity-card/router"));

var _router4 = _interopRequireDefault(require("../../views/deposit-entity-init/router"));

var _router5 = _interopRequireDefault(require("../..//views/deposit-entity-storage/router"));

var _router6 = _interopRequireDefault(require("../..//views/deposit-entity-use/router"));

var _router7 = _interopRequireDefault(require("../..//views/deposit-entity-batch/router"));

var _router8 = _interopRequireDefault(require("../..//views/deposit-entity-replace/router"));

var _router9 = _interopRequireDefault(require("../..//views/deposit-entity-rewrite/router"));

/** When your routing table is too long, you can split it into small modules **/
var depositRouter = {
  path: '/deposit',
  component: _layout.default,
  redirect: '/deposit/onLine/manage',
  name: 'Deposit',
  meta: {
    title: '储值卡',
    icon: 'nested'
  },
  children: [{
    path: 'onLine',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep1/onLine'));
      });
    },
    name: 'OnLine',
    redirect: '/deposit/onLine/manage',
    meta: {
      title: '储值卡管理'
    },
    children: [{
      path: 'manage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep1/onLine/manage'));
        });
      },
      name: 'Manage',
      meta: {
        title: '储值卡管理',
        keepAlive: true
      }
    }, {
      path: 'recharge365Reward',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/recharge365Reward.vue'));
        });
      },
      name: 'recharge365Reward',
      meta: {
        title: '补365奖励'
      }
    }, {
      path: 'changeCoupon',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/changeCoupon.vue'));
        });
      },
      name: 'changeCoupon',
      meta: {
        title: '优惠券换购物卡'
      }
    }, {
      path: 'cardOrder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep1/onLine/order'));
        });
      },
      name: 'cardOrder',
      meta: {
        title: '购卡记录',
        keepAlive: true
      }
    }, {
      path: 'orderList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep1/onLine/order/list.vue'));
        });
      },
      name: 'cardOrderList',
      meta: {
        title: '购卡订单',
        keepAlive: true
      }
    }, {
      path: 'addCard',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep1/onLine/addCard'));
        });
      },
      name: 'AddCard',
      meta: {
        title: '新增线上储值卡',
        activeMenu: '/deposit/onLine/manage'
      }
    }, {
      path: 'classify',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep1/onLine/classify'));
        });
      },
      name: 'Classify',
      meta: {
        title: '储值卡分类'
      }
    }, {
      path: 'cardDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep1/onLine/cardDea'));
        });
      },
      hidden: true,
      name: 'CardDea',
      meta: {
        title: '储值卡详情',
        activeMenu: '/deposit/onLine/manage'
      }
    }, {
      path: 'addClass',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep1/onLine/classify/addClass'));
        });
      },
      hidden: true,
      name: 'AddClass',
      meta: {
        title: '新增储值卡分类',
        activeMenu: '/deposit/onLine/classify'
      }
    }]
  }, _router.default, _router2.default, _router3.default, _router4.default, _router5.default, _router6.default, _router7.default, _router8.default, _router9.default, {
    path: 'dep2',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep2'));
      });
    },
    name: 'Dep2',
    redirect: '/deposit/dep2/purchase',
    meta: {
      title: '订单记录'
    },
    children: [{
      path: 'purchase',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep2/purchase'));
        });
      },
      name: 'Purchase',
      meta: {
        title: '购卡充值记录'
      }
    }, {
      path: 'tiedCard',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep2/tiedCard'));
        });
      },
      name: 'TiedCard',
      meta: {
        title: '绑卡激活记录'
      }
    }, {
      path: 'giveCard',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep2/giveCard'));
        });
      },
      name: 'GiveCard',
      meta: {
        title: '赠卡记录',
        keepAlive: true
      }
    }, {
      path: 'tree',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep2/giveCard/tree.vue'));
        });
      },
      name: 'dep2Tree',
      meta: {
        title: '转赠链路查询'
      }
    }]
  }, {
    path: 'adjust',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/deposit/dep2'));
      });
    },
    name: 'cardadjust',
    redirect: '/deposit/adjust/list',
    meta: {
      title: '补发记录'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/adjust/list.vue'));
        });
      },
      name: 'cardadjustList',
      meta: {
        title: '补发记录'
      }
    }, {
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/deposit/adjust/index.vue'));
        });
      },
      name: 'cardadjustIndex',
      meta: {
        title: '补发购物卡',
        activeMenu: '/deposit/adjust/list'
      }
    }]
  }]
};
var _default = depositRouter;
exports.default = _default;