"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.delCoupon = delCoupon;
exports.detailRegistrationLogById = detailRegistrationLogById;
exports.editData = editData;
exports.editSort = editSort;
exports.getData = getData;
exports.getList = getList;
exports.goExam = goExam;
exports.orderPage = orderPage;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: '/api/putCenter/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addData(data) {
  return (0, _request.default)({
    url: '/api/putCenter/saveBatch',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editData(data) {
  return (0, _request.default)({
    url: '/api/sign/repair/card/edit',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editSort(data) {
  return (0, _request.default)({
    url: '/api/putCenter/editSort',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getData(data) {
  return (0, _request.default)({
    url: '/api/putCenter/getById/' + data,
    method: 'get',
    loading: 'loading'
  });
}

function delCoupon(data) {
  return (0, _request.default)({
    url: '/api/putCenter/removeById',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function orderPage(data) {
  return (0, _request.default)({
    url: '/api/activityOffLine/listRegistrationLog',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function detailRegistrationLogById(data) {
  return (0, _request.default)({
    url: '/api/activityOffLine/detailRegistrationLogById',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function goExam(data) {
  return (0, _request.default)({
    url: '/api/putCenter/editStatus',
    method: 'get',
    loading: 'loading',
    params: data
  });
}