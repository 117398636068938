"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _report = require("@/api/report.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      pageSize: 10,
      pageNo: 1,
      total: 0,
      time: [],
      tableData: [],
      tabTh: [{
        props: 'ds',
        tit: '日期'
      }, {
        props: 'peoples11013',
        tit: '乐品清镇店'
      }, {
        props: 'peoples12004',
        tit: '遵义星力城C馆'
      }, {
        props: 'peoples12007',
        tit: '遵义星力城A馆'
      }, {
        props: 'peoples12008',
        tit: '汇安星力百货'
      }, {
        props: 'peoples13002',
        tit: '汇金星力城'
      }, {
        props: 'peoples11',
        tit: '乐品鲜活超市'
      }, {
        props: 'peoples12002',
        tit: '星力瑞金店'
      }, {
        props: 'peoples12003',
        tit: '荔星A馆'
      }, {
        props: 'peoples12001',
        tit: '荔星B馆'
      }, {
        props: 'sum',
        tit: '合计'
      }]
    };
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    rowSum: function rowSum(scope) {
      var sum = 0;

      for (var item in scope.row) {
        console.log(Number(scope.row[item]) === 'NaN');

        if (item !== 'ds') {
          sum += Number(scope.row[item]);
        }
      }

      return sum;
    },
    init: function init(e) {
      var _this = this;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        startDate: this.time[0],
        endDate: this.time[1]
      };
      console.log(this.time);
      this.openLoading();
      (0, _report.unlineLogInstitutionPage)(data).then(function (res) {
        _this.openLoading().close();

        _this.total = res.data.totalNum;
        _this.tableData = res.data.items;

        _this.tableData.forEach(function (data) {
          var sum = 0;

          for (var item in data) {
            if (Number(data[item])) {
              sum += Number(data[item]);
            }
          }

          data.sum = sum;
        });
      }).catch(function (res) {
        _this.openLoading().close();
      });
    },
    remove: function remove() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.time = [];
      this.init();
    }
  }
};
exports.default = _default;