"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon.js");

var _activity = require("@/api/activity.js");

var _deposit = require("@/api/deposit");

var _legou = require("@/api/legou.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _api = require("@/views/mall/goods/api.js");

var _api2 = require("@/views/member/vipCitylife/api.js");

var _api3 = require("@/views/member/hardCoupon/api.js");

var _api4 = require("@/views/mall/api.js");

var _axios = _interopRequireDefault(require("axios"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default,
    UploadExcelComponent: _index.default
  },
  props: {
    disabledArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    type: {
      type: String,
      default: function _default() {
        return 'coupon';
      }
    },
    productSourceOptions: {
      type: Array,
      default: function _default() {
        return [{
          label: '积分商城',
          value: 1
        }, {
          label: '大罗马',
          value: 2
        }, {
          label: '乐购',
          value: 3
        }, {
          label: '乐品',
          value: 4
        }, {
          label: '话费充值',
          value: 6
        }];
      }
    }
  },
  data: function data() {
    return {
      classProp: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'childInstitutions',
        multiple: true
      },
      classList: [],
      couponArr: [],
      total: 0,
      multipleSelection: [],
      scoreX: '',
      scoreB: '',
      cardType: '',
      obj: {
        productCode: null,
        pageNo: 1,
        pageSize: 10,
        status: 2,
        onShel: 1,
        couponType: '',
        categoryIds: [],
        scoreMax: '',
        scoreMin: '',
        productSource: [],
        ids: null,
        uuid: null
      },
      options: [{
        value: -1,
        label: '全部类型'
      }, {
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣券'
      }, {
        value: 3,
        label: '礼品券'
      }, {
        value: 4,
        label: '体验券'
      }, {
        value: 5,
        label: '外部优惠券'
      }],
      getRowKeys: function getRowKeys(row) {
        return row.uuid; // if(type=='goods'){
        //   return row.productCode
        // }else{
        //   return row.uuid
        // }
      },
      getRowKey: function getRowKey(row) {
        return row.productCode;
      },
      tableData: [],
      tabTh: [{
        tit: '优惠券编码',
        prop: 'uuid',
        width: 180
      }, {
        tit: '优惠券名称',
        prop: 'name'
      }, {
        tit: '类型',
        prop: 'couponType',
        width: 80
      }, {
        tit: '优惠券有效期',
        prop: 'time',
        width: 200
      },
      /* {
                          tit: "发布时间",
                          prop: 'createTime',
                          width: 180,
                          sortable: true
                        }, */
      {
        tit: '状态',
        prop: 'statusText',
        width: 90
      }]
    };
  },
  watch: {
    type: function type(e) {
      if (e === 'card') {
        this.obj.type = 1;
      } else {
        this.obj.type = '';
      }
    }
  },
  methods: {
    handleBeforeUpload: function handleBeforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    handleUploadSuccess: function handleUploadSuccess(data) {
      var whiteList = [];
      data.results.forEach(function (col) {
        if (col['编码']) {
          whiteList.push(String(col['编码']));
        }
      });

      if (this.type === 'legouGoods') {
        this.obj.ids = whiteList.join(',');
        this.init('search');
        return;
      }

      if (this.type === 'coupon') {
        this.obj.uuid = whiteList.join(',');
        this.init('search');
        return;
      }

      if (this.type === 'goods') {
        this.obj.productCode = whiteList.join(',');
        this.init('search');
        return;
      }
    },
    inputChange: function inputChange(e, type) {
      this.obj[type] = e.replace(/[ ]/g, '').replace(/[，]/g, ',');
      console.log(this.obj[type]);
    },
    selectable: function selectable(row, index) {
      return !this.disabledArr.includes(row.cardKindsId);
    },
    cateChange: function cateChange(e) {
      var _this = this;

      this.obj.categoryIds = [];
      e.forEach(function (item) {
        _this.obj.categoryIds.push(item[item.length - 1]);
      });
      return;
      this.obj.categoryIds = e[e.length - 1];
    },
    getBatchPage: function getBatchPage() {
      var _this2 = this;

      this.tabTh = [
      /* {
        tit: '卡号',
        prop: 'cardNo',
        width: 60
      }, */
      {
        tit: '卡种名称',
        prop: 'cardKindsName',
        width: 100
      }, {
        tit: '卡类型',
        prop: 'cardCategoryName',
        width: 100
      }, {
        slot: 'isRebate',
        tit: '返利类型',
        prop: 'isRebate',
        width: 100
      }, {
        tit: '激活后有效期（年）',
        prop: 'expireTradeYear',
        width: 100
      }, {
        tit: '卡片面额（元）',
        prop: 'cardLimitPrice',
        width: 100
      }];
      (0, _activity.batchPage)(this.obj).then(function (res) {
        _this2.tableData = res.data.records;
        _this2.total = res.data.count;
      });
    },
    getLegouGoodsList: function getLegouGoodsList() {
      var _this3 = this;

      this.tabTh = [{
        tit: '商品ID',
        prop: 'id'
      }, {
        tit: '商品名称',
        prop: 'name',
        width: 200
      }, {
        tit: '商品价格',
        prop: 'original_price'
      }];

      _axios.default.get('https://mall.xinglico.com/index.php', {
        params: {
          r: 'api/goods/venue-goods/goods-list',
          store_id: 2,
          page: this.obj.pageNo,
          limit: this.obj.pageSize,
          goods_name: this.obj.name,
          goods_ids: this.obj.ids
        }
      }).then(function (res) {
        console.log(res);
        _this3.tableData = res.data.data.list;
        _this3.total = res.data.data.pagination.row_count;
      });
    },
    getLegouPtGoodsList: function getLegouPtGoodsList() {
      var _this4 = this;

      this.tabTh = [{
        tit: '商品ID',
        prop: 'id'
      }, {
        tit: '商品名称',
        prop: 'name',
        width: 200
      }, {
        tit: '商品价格',
        prop: 'original_price'
      }];

      _axios.default.get('https://mall.xinglico.com/index.php', {
        params: {
          r: 'api/group/index/index',
          store_id: 2,
          page: this.obj.pageNo,
          limit: this.obj.pageSize,
          goods_name: this.obj.name,
          goods_id: this.obj.id
        }
      }).then(function (res) {
        console.log(res);
        _this4.tableData = res.data.data.goods.list;
        _this4.total = res.data.data.goods.row_count;
      });
    },
    getGoods: function getGoods() {
      var _this5 = this;

      this.tabTh = [{
        tit: '商品编码',
        prop: 'productCode',
        width: 200
      }, {
        tit: '商品名称',
        prop: 'productName'
      }, {
        tit: '商品积分',
        prop: 'starBean'
      }];
      (0, _api.list)(this.obj).then(function (res) {
        console.log('请求成功');
        var data = res.data.items;
        _this5.tableData = data;
        _this5.total = res.data.totalNum;
      });
    },
    getCard: function getCard() {
      var _this6 = this;

      this.tabTh = [{
        tit: '储值卡号',
        prop: 'uuid',
        width: 200
      }, {
        tit: '储值卡名称',
        prop: 'name'
      }, {
        tit: '分类',
        prop: 'className'
      }, {
        tit: '建卡时间',
        prop: 'createTime',
        width: 180,
        sortable: true
      }, {
        tit: '发行数量',
        prop: 'number',
        width: 100
      }, {
        tit: '状态',
        prop: 'statusText',
        width: 100
      }];
      var requestData = JSON.parse(JSON.stringify(this.obj));
      console.log(this.cardType);

      if (this.cardType) {
        requestData.type = [this.cardType];
      } else {
        requestData.type = [1];
      }

      (0, _deposit.listCardStored)(requestData).then(function (res) {
        var data = res.data.items;
        data.filter(function (value, key, arr) {
          switch (value.status) {
            case 1:
              value.statusText = '待审核';
              break;

            case 2:
              value.statusText = '已审核';
              break;

            case 3:
              value.statusText = '审核撤回';
              break;

            case 4:
              value.statusText = '审核撤销';
              break;
          }

          if (value.number === 0) {
            value.number = '0(不限)';
          }
        });
        _this6.tableData = data;
        _this6.total = res.data.totalNum;
      });
    },
    getVipSubsidy: function getVipSubsidy() {
      var _this7 = this;

      this.tabTh = [{
        tit: '硬核分类',
        prop: 'className',
        width: 100
      }, {
        tit: '奖品编码',
        prop: 'productCode',
        width: 100
      }, {
        tit: '奖品名称',
        prop: 'productName',
        width: 100
      }];
      (0, _api3.listSubsidyPage)(this.obj).then(function (res) {
        _this7.loading = false;
        _this7.tableData = res.data.items;
        _this7.total = res.data.totalNum;
      });
    },
    getVipCity: function getVipCity() {
      var _this8 = this;

      this.tabTh = [{
        tit: '同城分类',
        prop: 'className',
        width: 100
      }, {
        tit: '奖品编码',
        prop: 'productCode',
        width: 100
      }, {
        tit: '奖品名称',
        prop: 'productName',
        width: 100
      }];
      (0, _api2.listCitylifePage)(this.obj).then(function (res) {
        _this8.loading = false;
        _this8.tableData = res.data.items;
        _this8.total = res.data.totalNum;
      });
    },
    getCoupon: function getCoupon() {
      var _this9 = this;

      this.tabTh = [{
        tit: '优惠券编码',
        prop: 'uuid',
        width: 180
      }, {
        tit: '优惠券名称',
        prop: 'name'
      }, {
        tit: '类型',
        prop: 'couponType',
        width: 80
      }, {
        tit: '优惠券有效期',
        prop: 'time',
        width: 200
      }, {
        tit: '状态',
        prop: 'statusText',
        width: 90
      }];

      var _self = this;

      (0, _coupon.listCouponPage)(this.obj).then(function (res) {
        var data = res.data.items;
        _this9.total = res.data.totalNum;
        data.filter(function (value, key, arr) {
          if (_self.type === 'radio') {
            if (_self.multipleSelection.length > 0) {
              if (value.uuid === _self.multipleSelection[0].uuid) {
                value.check = true;
              } else {
                value.check = false;
              }
            } else {
              value.check = false;
            }
          }

          value.createTime = value.createTime; // .substring(0, 10)

          if (value.validTimeType === 1) {
            value.time = value.validBeginTime.substring(0, 10) + '至' + value.validEndTime.substring(0, 10);
          } else if (value.validTimeType === 2) {
            value.time = _self.getValidDay(value); // '领取后' + value.validDay + '天内有效'
          }

          value.couponTypeValue = value.couponType;

          if (value.couponType === 1) {
            value.couponType = '代金券';
          } else if (value.couponType === 2) {
            value.couponType = '折扣券';
          } else if (value.couponType === 3) {
            value.couponType = '礼品券';
          } else if (value.couponType === 4) {
            value.couponType = '体验券';
          } else if (value.couponType === 5) {
            value.couponType = '外部优惠券';
          }

          switch (value.status) {
            case 1:
              value.statusText = '待审核';
              break;

            case 2:
              value.statusText = '已审核';
              break;

            case 3:
              value.statusText = '审核撤回';
              break;

            case 4:
              value.statusText = '审核撤销';
              break;

            case 5:
              value.statusText = '已过期';
              break;

            case 6:
              value.statusText = '已终止';
              break;

            case 8:
              value.statusText = '已投放';
              break;

            case 9:
              value.statusText = '投放完成';
              break;
          }

          value.labelId = [];
          value.labelName = [];
        });
        _this9.tableData = data;
        console.log(_this9.tableData);
      });
    },
    getEvent: function getEvent() {
      var _this10 = this;

      this.tabTh = [{
        tit: '事件编码',
        prop: 'eventCode',
        width: 200
      }, {
        tit: '事件名称',
        prop: 'eventName'
      }, {
        tit: '创建时间',
        prop: 'createTime'
      }];
      (0, _activity.listEventPage)(this.obj).then(function (res) {
        _this10.loading = false;
        _this10.tableData = res.data.records;
        _this10.total = res.data.total;
      });
    },
    init: function init(type) {
      var _this11 = this;

      if (type === 'search') {
        this.obj.pageNo = 1;
      }

      if (type === 'remove') {
        this.removeObj(this.obj);
        this.obj.productSource = [1];
      }

      this.tableData = [];

      if (this.type === 'legouGoods') {
        this.getLegouGoodsList();
      }

      if (this.type === 'legouPtGoods') {
        this.getLegouPtGoodsList();
      }

      if (this.type === 'batchCard') {
        this.getBatchPage();
      }

      if (this.type === 'coupon') {
        this.getCoupon();
      }

      if (this.type === 'vipCity') {
        this.getVipCity();
      }

      if (this.type === 'vipSubsidy') {
        this.getVipSubsidy();
      }

      if (this.type === 'card') {
        this.getCard();
      }

      if (this.type === 'goods') {
        this.getGoods();

        if (this.classList.length < 1) {
          (0, _api4.goodsList)({}).then(function (res) {
            _this11.classList = _this11.getTreeData(res.data);
          });
        }
      }

      if (this.type === 'event') {
        this.getEvent();
      }
    },
    getTreeData: function getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].childInstitutions.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].childInstitutions = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].childInstitutions);
        }
      }

      return data;
    },

    /*
              关闭弹框
              */
    closePop: function closePop() {
      this.$emit('closeCouponPop'); // this.couponTableShow = false
    },

    /*
              清空优惠券选择
              */
    clearCoupon: function clearCoupon() {
      this.$refs.multipleTable.clearSelection();
    },

    /*
        赋值选中
        */
    assignCoupon: function assignCoupon(arr) {
      var _this12 = this;

      this.obj.pageNo = 1;
      this.clearCoupon();
      this.$nextTick(function () {
        if (arr.length > 0) {
          console.log(arr);
          arr.forEach(function (obj) {
            if (_this12.type === 'batchCard') {
              console.log(obj);

              _this12.$refs.multipleTable.toggleRowSelection(obj, true);
            } else if (_this12.type === 'coupon') {
              var data = {
                uuid: obj.linkId,
                mainImage: obj.cover,
                name: obj.name
              };

              _this12.$refs.multipleTable.toggleRowSelection(data);
            } else if (_this12.type === 'goods') {
              var _data = {
                productCode: obj.linkId,
                productSource: obj.productSource,
                imageUrl: obj.cover,
                productSourceName: obj.name
              };

              _this12.$refs.multipleTable.toggleRowSelection(_data);
            } else if (_this12.type === 'event') {
              var _data2 = {
                id: obj.linkId
              };

              _this12.$refs.multipleTable.toggleRowSelection(_data2);
            } else if (_this12.type === 'vipCity') {
              var _data3 = {
                id: obj.citylifeId
              };

              _this12.$refs.multipleTable.toggleRowSelection(_data3);
            } else if (_this12.type === 'vipSubsidy') {
              var _data4 = {
                id: obj.citylifeId
              };

              _this12.$refs.multipleTable.toggleRowSelection(_data4);
            } else if (_this12.type === 'legouGoods') {
              var _data5 = {
                id: obj.linkId,
                pic_url: obj.pic_url,
                name: obj.name,
                price: obj.price,
                original_price: obj.original_price,
                discount_rate: obj.discount_rate
              };

              _this12.$refs.multipleTable.toggleRowSelection(_data5);
            } else {
              _this12.$refs.multipleTable.toggleRowSelection({
                uuid: obj.linkId,
                name: obj.name,
                cover: obj.cover
              });
            }
          });
        }
      });
    },
    affirmAddCoupon: function affirmAddCoupon() {
      this.couponArr = [];
      this.multipleSelection = this.$refs.multipleTable.store.states.selection;
      this.$emit('setCoupons', this.$refs.multipleTable.store.states.selection);
    }
  }
};
exports.default = _default2;