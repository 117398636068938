"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _deposit = require("@/api/deposit");

var _user = require("@/api/user.js");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      url: '',
      couponUUid: '',
      qrCode: '',
      popShow: false,
      time: null,
      imgSrc: this.$store.state.user.imgSrc,
      urlList: _selectUrl.default.urlList,
      appIdArr: _selectUrl.default.appIdArr,
      downloadLoading: false,
      type: '',
      requestData: {
        uuid: null,
        name: null,
        pageNo: 1,
        pageSize: 10,
        total: 0,
        type: ''
      },
      tableData: [],
      columns: [{
        label: '编码',
        prop: 'uuid',
        width: 100
      }, {
        label: '名称',
        prop: 'name',
        width: 100
      }, {
        slot: 'validTimeType',
        label: '有效期',
        prop: 'validTimeType',
        width: 80
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status',
        width: 80
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(value) {
      if (value) {
        this.requestData.activityStartTime = value[0];
        this.requestData.activityEndTime = value[0];
      } else {
        this.requestData.activityStartTime = null;
        this.requestData.activityEndTime = null;
      }
    }
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {},
  methods: {
    goAudit: function goAudit(row, status) {
      var _this = this;

      console.log(111111);
      (0, _deposit.editCardStoredStatus)(row.id, status).then(function (res) {
        _this.getList();
      });
    },
    setBtn: function setBtn(index) {
      // 查看详情
      this.$router.push('/deposit/onLine/cardDea?id=' + index.id);
    },
    editStock: function editStock(scope) {
      var _this2 = this;

      this.$prompt('请输入库存数量', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\+?[1-9][0-9]*$/,
        inputErrorMessage: '大于0的正整数'
      }).then(function (_ref) {
        var value = _ref.value;
        superadditionById({
          id: scope.row.id,
          amout: value
        }).then(function (res) {
          _this2.$message({
            type: 'success',
            message: '修改成功'
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '取消'
        });
      });
    },
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getQrcode: function getQrcode(scope) {
      var _this3 = this;

      // 获取小程序码
      this.couponUUid = scope.name;
      (0, _user.couponQRCode)({
        path: 'pages/starCard/giftToken/giftToken',
        scene: 'id=' + scope.uuid
      }).then(function (res) {
        _this3.url = 'pages/starCard/giftToken/giftToken?id=' + scope.uuid;
        _this3.qrCode = res.data;
        _this3.popShow = true;
      });
    },
    goDel: function goDel(scope) {
      var _this4 = this;

      delHelp({
        id: scope.row.id
      }).then(function (res) {
        _this4.getList();
      });
    },
    handleDownload: function handleDownload() {
      var _this5 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this5.requestData[key]) {
          data[key] = _this5.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _deposit.listCardStored)(data).then(function (res) {
        list = res.data.records;
        _this5.downloadLoading = false;

        _this5.getHandleDownload(tHeader, filterVal, list, '提货券列表');
      }).catch(function (res) {
        _this5.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this6 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (this.type) {
        this.requestData.type = [this.type];
      } else {
        this.requestData.type = [3, 4];
      }

      (0, _deposit.listCardStored)(this.requestData).then(function (res) {
        _this6.tableData = res.data.items;
        _this6.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;