"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCategory = addCategory;
exports.categoryPage = categoryPage;
exports.delCategory = delCategory;
exports.editCategory = editCategory;
exports.getCategory = getCategory;
exports.getPayList = getPayList;

var _request = _interopRequireDefault(require("@/utils/request"));

function getPayList(data) {
  return (0, _request.default)({
    url: '/api/card/make/pay/category/list',
    method: 'get'
  });
}

function categoryPage(data) {
  return (0, _request.default)({
    url: '/api/card/make/pay/category/page',
    method: 'post',
    data: data
  });
}

function addCategory(data) {
  return (0, _request.default)({
    url: '/api/card/make/pay/category',
    method: 'post',
    data: data
  });
}

function editCategory(data) {
  return (0, _request.default)({
    url: '/api/card/make/pay/category/update',
    method: 'post',
    data: data
  });
}

function delCategory(data) {
  return (0, _request.default)({
    url: '/api/card/make/category/' + data,
    method: 'post'
  });
}

function getCategory(data) {
  return (0, _request.default)({
    url: '/api/card/make/pay/category/' + data,
    method: 'get'
  });
}