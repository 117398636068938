"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '基本信息',
      list: [{
        tit: '适用范围标签',
        txtArr: '<p>基本信息-适用范围标签</p>    ',
        imgPath: require('@/assets/tab/1.png'),
        path: '/shopCard/applyTag'
      }, {
        tit: '卡类型',
        txtArr: '<p>基本信息-卡类型</p>',
        imgPath: require('@/assets/tab/2.png'),
        path: '/shopCard/type'
      }, {
        tit: '支付及返利管理',
        txtArr: '<p>基本信息-支付及返利管理</p>',
        imgPath: require('@/assets/tab/3.png'),
        path: '/shopCard/rebate'
      }, {
        tit: '企业客户',
        txtArr: '<p>基本信息-企业客户</p>',
        imgPath: require('@/assets/tab/10.png'),
        path: '/shopCard/enterpriseCustomer'
      }, {
        tit: '个人客户',
        txtArr: '<p>基本信息-个人客户</p>',
        imgPath: require('@/assets/tab/4.png'),
        path: '/shopCard/Customer'
      }, {
        tit: '制卡商',
        txtArr: '<p>基本信息-制卡商</p>',
        imgPath: require('@/assets/tab/6.png'),
        path: '/shopCard/maker'
      }]
    };
  }
};
exports.default = _default;