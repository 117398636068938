"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.sort");

var _api = require("./api.js");

var _public = require("@/utils/public.js");

var _mem = require("@/api/mem.js");

var _api2 = require("../tem/api.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default
  },
  data: function data() {
    return {
      temList: [],
      weekList: _public.weekList,
      popType: '',
      couponShow: false,
      storeList: [],
      type: [{
        label: '优惠券',
        value: 1
      }, {
        label: '实体商品',
        value: 2
      }, {
        label: '乐购商品',
        value: 3
      }, {
        label: '券包',
        value: 4
      }],
      rules: {
        categoryId: [{
          required: true,
          message: '请选择栏目'
        }],
        storeCode: [{
          required: true,
          message: '请选择门店'
        }],
        productItems: [{
          required: true,
          message: '请添加商品'
        }]
      },
      ruleForm: {
        activityId: null,
        categoryId: null,
        categoryName: null,
        storeCode: null,
        storeName: null,
        productItems: []
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _api2.listCategoryItemByActivityId)({
      id: this.$route.query.mallId
    }).then(function (res) {
      _this.temList = res.data;
    });
    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      console.log(res);
      _this.storeList = res.data;
    });
    this.ruleForm.id = this.$route.query.id;
    this.ruleForm.activityId = this.$route.query.mallId;

    if (this.ruleForm.id) {
      (0, _api.getGoodsData)({
        id: this.ruleForm.id
      }).then(function (res) {
        _this.ruleForm.productItems.push({
          productType: res.data.productType,
          productCode: res.data.productCode,
          productName: res.data.productName,
          sort: res.data.sort,
          status: res.data.status,
          productDetail: res.data.productDetail || null
        });

        _this.ruleForm.cycleCategoryId = res.data.cycleCategoryId;
        _this.ruleForm.weekTag = res.data.weekTag;

        _this.weekCahnge(res.data.weekTag);
      });
    }
  },
  methods: {
    changeLimit: function changeLimit(e) {
      if (e === 0) {
        this.ruleForm.limitNumber = 0;
      }
    },
    getRelevances: function getRelevances() {
      this.ruleForm.productItems.push({
        productName: null,
        productCode: null,
        productPrice: null,
        productType: null,
        status: 1,
        sort: null,
        remark: null,
        productOriginalPrice: null,
        productImage: null
      });
    },

    /*
      选择奖励项
      */
    setAward: function setAward(e, index) {
      this.ruleForm.productItems[index].productCode = null;
      this.ruleForm.productItems[index].productName = null;
      this.ruleForm.productItems[index].sort = null;
      this.ruleForm.productItems[index].productImage = null;
    },

    /*
      打开弹框
      */
    getAward: function getAward(e, index) {
      this.count = index;
      var selectType = {
        2: 'goods',
        1: 'coupon',
        3: 'legouGoods',
        4: 'couponBag'
      };
      this.popType = selectType[e];
      this.$refs.coupon1.getList(this.popType, this.ruleForm.productItems[index].productCode);
      this.couponShow = true;
    },

    /*
      删除奖励项
      */
    delAward: function delAward(index) {
      this.ruleForm.productItems.splice(index, 1);
    },
    setCoupon: function setCoupon(e) {
      var godosItem = this.ruleForm.productItems[this.count];
      var goodsType = {
        coupon: {
          productCode: 'uuid',
          productName: 'name',
          productImage: 'mainImage',
          productOriginalPrice: 'denomination'
        },
        goods: {
          productCode: 'productCode',
          productName: 'productName',
          productImage: 'imageUrl',
          productOriginalPrice: 'marketPrice'
        },
        legouGoods: {
          productCode: 'id',
          productName: 'name',
          productImage: 'pic_url',
          productOriginalPrice: 'original_price'
        },
        couponBag: {
          productCode: 'uuid',
          productName: 'name',
          productImage: 'tableImage'
        }
      };

      if (this.popType === 'legouGoods') {
        var goodsJson = JSON.stringify({
          pic_url: e.pic_url,
          price: e.price,
          name: e.name,
          discount_rate: e.discount_rate,
          original_price: e.original_price,
          id: e.id
        });
        this.ruleForm.productItems[this.count].remark = e.price ? goodsJson : null;
      }

      godosItem.productCode = e[goodsType[this.popType].productCode];
      godosItem.productName = e[goodsType[this.popType].productName];
      godosItem.productImage = e[goodsType[this.popType].productImage];

      if (this.popType != 'couponBag') {
        godosItem.productOriginalPrice = e[goodsType[this.popType].productOriginalPrice];
      }

      this.couponShow = false;
      this.popType = '';
      return;

      if (this.popType === 'coupon') {
        this.ruleForm.productItems[this.count].productCode = e.uuid;
        this.ruleForm.productItems[this.count].productName = e.name;
        this.ruleForm.productItems[this.count].productOriginalPrice = e.denomination;
      }

      if (this.popType === 'goods') {
        this.ruleForm.productItems[this.count].productCode = e.productCode;
        this.ruleForm.productItems[this.count].productName = e.productName;
        this.ruleForm.productItems[this.count].productOriginalPrice = e.marketPrice;
      }

      if (this.popType === 'legouGoods') {
        var _goodsJson = JSON.stringify({
          pic_url: e.pic_url,
          price: e.price,
          name: e.name,
          discount_rate: e.discount_rate,
          original_price: e.original_price,
          id: e.id
        });

        this.ruleForm.productItems[this.count].remark = e.price ? _goodsJson : null;
        this.ruleForm.productItems[this.count].productCode = e.id;
        this.ruleForm.productItems[this.count].productName = e.name;
        this.ruleForm.productItems[this.count].productOriginalPrice = e.original_price;
      }

      this.couponShow = false;
      this.popType = '';
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var storeData = _this2.storeList.find(function (item) {
            return item.storeCode === _this2.ruleForm.storeCode;
          });

          _this2.ruleForm.storeName = storeData.shortName;

          var temData = _this2.temList.find(function (item) {
            return item.id === _this2.ruleForm.categoryId;
          });

          _this2.ruleForm.categoryName = temData.categoryName;
          (0, _api.addData)(_this2.ruleForm).then(function (res) {
            _this2.$router.go(-1);

            _this2.$message({
              type: 'success',
              message: '添加成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;