"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var _router = _interopRequireDefault(require("../../views/coupon/putCenter/router.js"));

/** When your routing table is too long, you can split it into small modules **/
var couponRouter = {
  path: '/coupon',
  component: _layout.default,
  redirect: '/coupon/couponTab',
  name: 'Coupon',
  meta: {
    title: '营销',
    roles: ['editor', 'admin'],
    icon: 'nested'
  },
  children: [_router.default, {
    path: 'couponTab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/tab'));
      });
    },
    name: 'couponTab',
    meta: {
      title: '优惠券管理'
    }
  }, {
    path: 'coupon1',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/index'));
      });
    },
    // Parent router-view
    name: 'Coupon1',
    meta: {
      title: '优惠券管理',
      roles: ['admin']
    },
    redirect: '/coupon/coupon1/addCoupon',
    children: [{
      path: 'addCoupon',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/addCoupon'));
        });
      },
      name: 'AddCoupon',
      meta: {
        title: '选择优惠券类型',
        activeMenu: '/coupon/coupon1/couponList'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/addCoupon/add.vue'));
        });
      },
      name: 'addCoupons',
      meta: {
        title: '新增编辑优惠券'
      }
    }, {
      path: 'editGoods',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponList/editGoods.vue'));
        });
      },
      hidden: true,
      name: 'editGoods',
      meta: {
        title: '修改商品码',
        activeMenu: '/coupon/coupon1/couponList'
      }
    }, {
      path: 'addVolume',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/addCoupon/components/addVolume'));
        });
      },
      hidden: true,
      name: 'AddVolume',
      meta: {
        title: '新增/编辑优惠券',
        activeMenu: '/coupon/coupon1/couponList'
      }
    }, {
      path: 'couponList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponList'));
        });
      },
      name: 'CouponList',
      meta: {
        title: '优惠券列表',
        keepAlive: true
      }
    }, {
      path: 'couponDeatil',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponList/components/couponDeatil'));
        });
      },
      hidden: true,
      name: 'CouponDeatil',
      meta: {
        title: '优惠券详情',
        activeMenu: '/coupon/coupon1/couponList'
      }
    }, {
      path: 'couponGiftTab',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponGift/tab'));
        });
      },
      name: 'couponGiftTab',
      meta: {
        title: '券包管理',
        keepAlive: true
      }
    }, {
      path: 'giftCodeOrder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponGift/codeOrder'));
        });
      },
      name: 'giftCodeOrder',
      meta: {
        title: '券包兑换码订单',
        keepAlive: true,
        pageList: 'items',
        pageTotal: 'totalNum',
        activeMenu: '/coupon/coupon1/couponGiftTab'
      }
    }, {
      path: 'giftCodeChange',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponGift/changeDea'));
        });
      },
      name: 'giftCodeChange',
      meta: {
        title: '券包兑换详情',
        pageList: 'items',
        pageTotal: 'totalNum',
        isOneRequest: 'no',
        activeMenu: '/coupon/coupon1/couponGiftTab'
      }
    }, {
      path: 'couponGiftOrder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponGift/order'));
        });
      },
      name: 'couponGiftOrder',
      meta: {
        title: '券包订单',
        keepAlive: true,
        activeMenu: '/coupon/coupon1/couponGiftTab'
      }
    }, {
      path: 'couponGiftOrder4',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponGift/order4'));
        });
      },
      name: 'couponGiftOrder4',
      meta: {
        title: '券包订单',
        keepAlive: true,
        activeMenu: '/coupon/coupon1/couponGiftTab'
      }
    }, {
      path: 'couponGift',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponGift'));
        });
      },
      name: 'CouponGift',
      meta: {
        title: '优惠券礼包',
        keepAlive: true,
        activeMenu: '/coupon/coupon1/couponGiftTab'
      }
    }, {
      path: 'addCouGift',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponGift/addCouGift'));
        });
      },
      hidden: true,
      name: 'AddCouGift',
      meta: {
        title: '新增优惠券包',
        activeMenu: '/coupon/coupon1/couponGiftTab'
      }
    }, {
      path: 'giftDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponGift/giftDea'));
        });
      },
      hidden: true,
      name: 'GiftDea',
      meta: {
        title: '确认优惠券包'
      }
    }, {
      path: 'wraparoundDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon1/couponGift/wraparoundDea'));
        });
      },
      hidden: true,
      name: 'WraparoundDea',
      meta: {
        title: '优惠券包详情'
      } //       {
      //        path: 'grantCoupon',
      //        component: () => import('@/views/coupon/coupon1/grantCoupon'),
      //        name: 'GrantCoupon',
      //        meta: { title: '发放优惠券' }
      //      },
      //       {
      //        path: 'verifyCoupon',
      //        component: () => import('@/views/coupon/coupon1/verifyCoupon'),
      //        name: 'CerifyCoupon',
      //        meta: { title: '核销优惠券' }
      //      }

    }]
  }, {
    path: 'couponPut',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/couponPut/index'));
      });
    },
    // Parent router-view
    name: 'CouponPut',
    meta: {
      title: '优惠券投放',
      roles: ['editor']
    },
    redirect: '/coupon/couponPut/putCoupon',
    children: [{
      path: 'putCoupon',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/couponPut/putCoupon'));
        });
      },
      name: 'PutCoupon',
      meta: {
        title: '投放优惠券'
      }
    }, {
      path: 'orientPut',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/couponPut/orientPut'));
        });
      },
      name: 'OrientPut',
      meta: {
        title: '定向投放方案',
        keepAlive: true
      }
    }, {
      path: 'orientLabel',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/couponPut/orientLabel'));
        });
      },
      name: 'OrientLabel',
      meta: {
        title: '定向投放标签'
      }
    }, {
      path: 'addLabel',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/couponPut/orientLabel/addLabel.vue'));
        });
      },
      name: 'addLabel',
      hidden: true,
      meta: {
        title: '新增定向投放标签'
      }
    }, {
      path: 'touchPut',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/couponPut/touchPut'));
        });
      },
      name: 'TouchPut',
      meta: {
        title: '触发投放方案',
        keepAlive: true
      }
    }, {
      path: 'volumeCenter',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/couponPut/volumeCenter'));
        });
      },
      name: 'VolumeCenter',
      meta: {
        title: '领券中心管理',
        keepAlive: true
      }
    }, {
      path: 'addPut',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/couponPut/putCoupon/addPut'));
        });
      },
      hidden: true,
      name: 'AddPut',
      meta: {
        title: '新增投放方案'
      }
    }, {
      path: 'affirmAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/couponPut/putCoupon/addPut/affirmAdd'));
        });
      },
      hidden: true,
      name: 'AffirmAdd',
      meta: {
        title: '新增投放方案'
      }
    }, {
      path: 'putDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/couponPut/putDea'));
        });
      },
      hidden: true,
      name: 'PutDea',
      meta: {
        title: '方案详情'
      }
    }]
  }, {
    path: 'coupon2',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon2/index'));
      });
    },
    // Parent router-view
    name: 'Coupon2',
    meta: {
      title: '优惠券核销'
    },
    redirect: '/coupon/coupon2/record',
    children: [{
      path: 'record',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon2/record'));
        });
      },
      name: 'Record',
      meta: {
        title: '核销记录',
        keepAlive: true
      }
    }, {
      path: 'recordDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon2/recordDea'));
        });
      },
      name: 'RecordDea',
      meta: {
        title: '优惠券核销记录详情'
      }
    }]
  }, {
    path: 'coupon3',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/index'));
      });
    },
    // Parent router-view
    name: 'Coupon3',
    meta: {
      title: '活动管理'
    },
    redirect: '/coupon/coupon3/activityList',
    children: [{
      path: 'addActivity',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/addActivity'));
        });
      },
      name: 'AddActivity',
      meta: {
        title: '新增活动'
      }
    }, {
      path: 'activity',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/addActivity/activity'));
        });
      },
      name: 'Activity',
      hidden: true,
      meta: {
        title: '新增活动' //activeMenu: '/coupon/coupon3/pullNewList'

      }
    }, {
      path: 'confirmActivity',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/confirmActivity'));
        });
      },
      name: 'ConfirmActivity',
      hidden: true,
      meta: {
        title: '确认新增活动'
      }
    }, {
      path: 'activityClass',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/activityClass'));
        });
      },
      name: 'ActivityClass',
      meta: {
        title: '活动分类'
      }
    }, {
      path: 'addActivityClass',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/activityClass/addClass'));
        });
      },
      hidden: true,
      name: 'AddActivityClass',
      meta: {
        title: '新增活动分类',
        activeMenu: '/coupon/coupon3/activityClass'
      }
    }, {
      path: 'promotionList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/promotionList'));
        });
      },
      name: 'PromotionList',
      meta: {
        title: '促销类活动',
        keepAlive: true
      }
    }, {
      path: 'promotionDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/promotionList/promotionDea'));
        });
      },
      name: 'PromotionDea',
      hidden: true,
      meta: {
        title: '活动详情'
      }
    }, {
      path: 'voteActivity',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/voteActivity'));
        });
      },
      name: 'VoteActivity',
      meta: {
        title: '拉新活动',
        keepAlive: true
      }
    }, {
      path: 'voteDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/voteActivity/voteDea'));
        });
      },
      name: 'VoteDea',
      meta: {
        title: '活动详情'
      }
    }, {
      path: 'voteGroup',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/voteActivity/voteGroup'));
        });
      },
      name: 'VoteGroup',
      meta: {
        title: '项管理',
        activeMenu: '/coupon/coupon3/voteActivity'
      }
    }, {
      path: 'pullNewList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/pullNewList'));
        });
      },
      name: 'PullNewList',
      meta: {
        title: '拉新活动',
        keepAlive: true
      }
    }, {
      path: 'pullNewDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/pullNewList/pullNewDea'));
        });
      },
      name: 'PullNewDea',
      hidden: true,
      meta: {
        title: '拉新活动详情',
        activeMenu: '/coupon/coupon3/pullNewList'
      }
    }, {
      path: 'applyList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/applyList'));
        });
      },
      name: 'ApplyList',
      meta: {
        title: '报名类活动'
      }
    }, {
      path: 'appliDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon3/applyList/appliDea'));
        });
      },
      name: 'AppliDea',
      hidden: true,
      meta: {
        title: '活动详情'
      }
    }]
  }, {
    path: 'coupon4',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon4/index'));
      });
    },
    // Parent router-view
    name: 'Coupon4',
    meta: {
      title: '布局设置'
    },
    redirect: '/coupon/coupon4/activityList',
    children: [{
      path: 'activityList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon4/activityList'));
        });
      },
      name: 'ActivityList4',
      meta: {
        title: '栏目管理'
      }
    }, {
      path: 'addColumn',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon4/addColumn'));
        });
      },
      name: 'AddColumn',
      meta: {
        title: '栏目组'
      }
    }, {
      path: 'editorColumn',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon4/editorColumn'));
        });
      },
      name: 'EditorColumn',
      meta: {
        title: '编辑栏目组'
      }
    }, {
      path: 'advert',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon4/advert'));
        });
      },
      name: 'Advert',
      meta: {
        title: '广告'
      }
    }, {
      path: 'advertList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon4/advert/advertList'));
        });
      },
      name: 'AdvertList',
      hidden: true,
      meta: {
        title: '广告体'
      }
    }, {
      path: 'storeEditor',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon4/storeEditor'));
        });
      },
      hidden: true,
      name: 'StoreEditor',
      meta: {
        title: '编辑广告体'
      }
    }, {
      path: 'column',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon4/column'));
        });
      },
      hidden: true,
      name: 'Column',
      meta: {
        title: '页面栏目管理'
      }
    }, {
      path: 'editList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/coupon/coupon4/editList'));
        });
      },
      hidden: true,
      name: 'EditList',
      meta: {
        title: '编辑列表'
      }
    }]
  }]
};
var _default = couponRouter;
exports.default = _default;