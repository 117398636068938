"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      textarea2: '',

      /* name: '',*/
      recomTit: '',
      recomId: '',
      recomBtn: '',
      recomUrl: '',

      /* box1Btn: ["模板1", "模板2", "模板3", "模板4", "模板5"],
      templates: [{
      value: '1',
      label: '首页轮播图710x240'
      }, {
      value: '2',
      label: '首页功能图标'
      }, {
      value: '3',
      label: '首页列表模板1'
      }, {
      value: '4',
      label: '首页列表模板2'
      }, {
      value: '5',
      label: '首页列表模板3'
      }],
      template: '',*/
      times: [{
        value: '1',
        label: '2000'
      }, {
        value: '2',
        label: '2500'
      }, {
        value: '3',
        label: '3000'
      }, {
        value: '4',
        label: '3500'
      }, {
        value: '5',
        label: '4000'
      }, {
        value: '6',
        label: '4500'
      }, {
        value: '7',
        label: '5000'
      }],
      time: '',
      pageTypes: [{
        value: '1',
        label: '板块页面'
      }, {
        value: '2',
        label: '分类页面 list'
      }, {
        value: '3',
        label: '详情页面 detail'
      }, {
        value: '4',
        label: '外部页面网址'
      }]
    };
  },
  methods: {}
};
exports.default = _default;