"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _api = require("@/api/api.js");

var _api2 = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      title: '新增税率管理',
      storeList: [],
      rules: {
        storeId: [{
          required: true,
          message: '请选择门店'
        }],
        kpName: [{
          required: true,
          message: '请输入门店开票人'
        }],
        skrName: [{
          required: true,
          message: '请输入收款人'
        }],
        fhrName: [{
          required: true,
          message: '请输入复核人'
        }]
      },
      ruleForm: {
        id: '',
        storeId: '',
        storeName: '',
        kpName: '',
        skrName: '',
        fhrName: ''
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
    this.init();
  },
  methods: {
    init: function init() {
      var _this2 = this;

      this.ruleForm.id = Number(this.$route.query.id);

      if (this.ruleForm.id) {
        this.title = '编辑税率管理';
        (0, _api2.getLpStore)({
          id: this.ruleForm.id
        }).then(function (res) {
          _this2.ruleForm.storeName = res.data.storeName;
          _this2.ruleForm.kpName = res.data.kpName;
          _this2.ruleForm.skrName = res.data.skrName;
          _this2.ruleForm.fhrName = res.data.fhrName;
        });
      }
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this3.ruleForm.id) {
            (0, _api2.updateLpStore)(_this3.ruleForm).then(function (res) {
              _this3.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this3.$router.go(-1);
            });
          } else {
            (0, _api2.addLpStore)(_this3.ruleForm).then(function (res) {
              _this3.$router.go(-1);

              _this3.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;