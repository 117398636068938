"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.goReward = goReward;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: '/api/reissue/listReissuePage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function goReward(data) {
  return (0, _request.default)({
    url: '/api/reissue/reward',
    method: 'post',
    loading: 'loading',
    data: data
  });
}