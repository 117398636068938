"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
function add0(m) {
  return m < 10 ? '0' + m : m;
}

function formatTime(time) {
  //shijianchuo是整数，否则要parseInt转换
  var time = new Date(time);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}

var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      tableData: [],
      requestData: {
        name: null,
        account: null,
        pageSize: 10,
        page: 1
      },
      searchData: [{
        type: 'input',
        span: 3,
        placeholder: '请输入优惠券名称',
        value: 'couponName'
      }, {
        type: 'input',
        span: 3,
        placeholder: '券编码',
        value: 'couponCode'
      }, {
        type: 'input',
        span: 2,
        placeholder: '兑换码',
        value: 'receivedCode'
      }, {
        type: 'input',
        span: 2,
        placeholder: '操作人手机号',
        value: 'exportPhone'
      }, {
        type: 'input',
        span: 2,
        placeholder: '用户手机号',
        value: 'receiverPhone'
      }, {
        type: 'dateTime',
        span: 4,
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        span: 4,
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var times = 86400000 * 7; //一整周的毫秒数

    var curTime = formatTime(new Date().getTime());
    var beforeTime = formatTime(new Date().getTime() - times);
    this.$refs.wTable.setRequestTime(beforeTime, curTime);
  },
  methods: {}
};
exports.default = _default;