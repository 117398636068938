"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSendCoupon = addSendCoupon;
exports.addSendCouponRule = addSendCouponRule;
exports.editSendCoupon = editSendCoupon;
exports.editSendCouponRule = editSendCouponRule;
exports.getSendCoupon = getSendCoupon;
exports.getSendCouponRule = getSendCouponRule;
exports.listOrderSendCouponLogs = listOrderSendCouponLogs;
exports.listSendCouponPage = listSendCouponPage;
exports.listSendCouponRulePage = listSendCouponRulePage;

var _request = _interopRequireDefault(require("@/utils/request"));

/* 1、流水返券范围列表：/api/orderSendCoupon/listSendCouponPage
{"pageNo":"当前页","pageSize":"页条数"}

2、流水返券范围明细：/api/orderSendCoupon/getSendCoupon/{id}

3、流水返券范围新增：api/orderSendCoupon/addSendCoupon
{
 "rangeName":"范围名称",
"companyCode":"公司编码",
"storeCodes":[],
"goodsStatus":"商品码状态(1：可用 2：不可用)",
"goodsCodes":[],
"rangeRemark":"范围备注",
"rangeStatus":"范围状态(1：启用 2：禁用)"
}

4、流水返券范围编辑：api/orderSendCoupon/editSendCoupon
{"id":"id","rangeName":"范围名称","companyCode":"公司编码","storeCodes":[],"goodsStatus":"商品码状态(1：可用 2：不可用)","goodsCodes":[],"rangeRemark":"范围备注","rangeStatus":"范围状态(1：启用 2：禁用)"}

5、流水返券范围编辑状态：api/orderSendCoupon/editStauts
{"id":"id","rangeStatus":"范围状态(1：启用 2：禁用)"} */
function listSendCouponPage(data) {
  // 流水返券范围列表
  return (0, _request.default)({
    url: '/api/orderSendCoupon/listSendCouponPage',
    method: 'post',
    data: data
  });
}

function addSendCoupon(data) {
  // 流水返券范围新增
  return (0, _request.default)({
    url: '/api/orderSendCoupon/addSendCoupon',
    method: 'post',
    data: data
  });
}

function editSendCoupon(data) {
  // 流水返券范围编辑
  return (0, _request.default)({
    url: '/api/orderSendCoupon/editSendCoupon',
    method: 'post',
    data: data
  });
}

function getSendCoupon(id) {
  // 流水返券范围明细
  return (0, _request.default)({
    url: '/api/orderSendCoupon/getSendCoupon/' + id,
    method: 'post'
  });
}
/*
1、流水返券规则列表：/api/orderSendCouponRule/listSendCouponRulePage
{"pageNo":"当前页","pageSize":"页条数"}

2、流水返券规则明细：/api/orderSendCouponRule/getSendCouponRule/{ruleCode}

3、流水返券规则新增：api/orderSendCouponRule/addSendCouponRule
{"rangeId":"范围id","ruleName":"规则名称","ruleStartTime":"规则开始时间",
"ruleEndTime":"规则结束时间","ruleType":"规则类型(1：门槛返 2：阶梯返)","ruleRemark":"规则备注",
"ruleItems":["ruleStatus":"规则状态(1：启用 2：禁用)","thresholdAmount":"门槛金额","limitPeople":"会员返券上限,0无上限","limitType":"上限类型(1：返券总人数 2：返券总次数)","limitNumber":"上限类型数量","ruleItemCycle":"规则项周期(1：每日 2：周期)","ruleItemStartTime":"规则项开始时间","ruleItemEndTime":"规则项结束时间","couponInfos":["couponCode":"券编码","sendNumber":"单次返券数量"]]}

4、流水返券规则编辑：api/orderSendCouponRule/editSendCouponRule
{"id":"id","rangeId":"范围id","ruleName":"规则名称","ruleStartTime":"规则开始时间",
"ruleEndTime":"规则结束时间","ruleType":"规则类型(1：门槛返 2：阶梯返)","ruleRemark":"规则备注",
"ruleItems":["ruleStatus":"规则状态(1：启用 2：禁用)","thresholdAmount":"门槛金额","limitPeople":"会员返券上限,0无上限",
"limitType":"上限类型(1：返券总人数 2：返券总次数)","limitNumber":"上限类型数量","ruleItemCycle":"规则项周期(1：每日 2：周期)"
,"ruleItemStartTime":"规则项开始时间","ruleItemEndTime":"规则项结束时间","couponInfos":["couponCode":"券编码","sendNumber":"单次返券数量"]]}

5、流水返券规则编辑状态：api/orderSendCouponRule/editSendCouponRuleStatus
{"id":"id","ruleStatus":"规则状态(1：启用 2：禁用)"}

 */


function listSendCouponRulePage(data) {
  // 流水返券规则列表
  return (0, _request.default)({
    url: '/api/orderSendCouponRule/listSendCouponRulePage',
    method: 'post',
    data: data
  });
}

function addSendCouponRule(data) {
  // 流水返券规则新增
  return (0, _request.default)({
    url: '/api/orderSendCouponRule/addSendCouponRule',
    method: 'post',
    data: data
  });
}

function editSendCouponRule(data) {
  // 流水返券规则编辑
  return (0, _request.default)({
    url: '/api/orderSendCouponRule/editSendCouponRule',
    method: 'post',
    data: data
  });
}

function getSendCouponRule(data) {
  // 流水返券规则新增
  return (0, _request.default)({
    url: '/api/orderSendCouponRule/getSendCouponRule/' + data,
    method: 'post'
  });
}
/*

入参：{"ruleCode":"规则编码","userMobile":"会员手机号",
"couponCode":"券编码","orderNo":"流水号","startDate":"券开始日期","endDate":"发券结束日期"}
 */


function listOrderSendCouponLogs(data) {
  return (0, _request.default)({
    url: '/api/orderSendCouponRule/listOrderSendCouponLogs',
    method: 'post',
    data: data
  });
}