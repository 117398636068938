var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "into" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(_vm.$route.meta.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { model: _vm.intoType, "label-width": "180px" }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "入库类型", prop: "val", rules: _vm.rules.val }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.intoType.val,
                    callback: function($$v) {
                      _vm.$set(_vm.intoType, "val", $$v)
                    },
                    expression: "intoType.val"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("手动入库")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("关联制卡计划")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "into-content" },
        [
          _vm.intoType.val === 1 ? _c("manual-into") : _vm._e(),
          _vm._v(" "),
          _vm.intoType.val === 2 ? _c("plan-into") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }