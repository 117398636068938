"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _auth = require("@/utils/auth");

var _report = require("@/api/report.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      userInfo: {},
      isGoCard: false,
      currentPrice: null,
      changType: 2,
      popType: 'storeCard',
      couponShow: false,
      action: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
      rules: {
        certificate: [{
          required: true,
          message: '请上传凭证'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        code: [{
          required: true,
          message: '请刷卡查询'
        }],
        newCardNo: [{
          required: true,
          message: '请输入新卡号'
        }],
        userMobile: [{
          required: true,
          message: '请输入手机号码'
        }],
        onlineCardUuid: [{
          required: true,
          message: '请选择购物卡'
        }]
      },
      ruleForm: {
        code: null,
        newCardNo: null,
        remark: null,
        onlineCardUuid: 'CC22053111380552296',
        userMobile: null,
        certificate: null
      }
    };
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        'Token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {},
  methods: {
    changeMobile: function changeMobile() {
      this.isGoCard = false;
    },
    getUserInfo: function getUserInfo() {
      var _this = this;

      var data = {
        mobile: this.ruleForm.userMobile,
        type: 1
      };
      this.isGoCard = false;
      (0, _report.handleMidUser)(data).then(function (res) {
        console.log(111111111);
        _this.isGoCard = true;
        _this.userInfo = res.data.user;
        _this.userInfo.status = res.data.status;
      });
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    setCoupon: function setCoupon(e) {
      if (this.popType === 'card') {
        this.ruleForm.onlineCardUuid = e.uuid;
      } else {
        this.ruleForm.newCardNo = e.cardNo;
      }

      this.couponShow = false;
    },
    getAward: function getAward(e) {
      this.popType = e;
      this.$refs.coupon1.getList(this.popType, this.ruleForm.newCardNo);
      this.couponShow = true;
    },
    onSuccess: function onSuccess(res) {
      var _this2 = this;

      if (res.ret === 1000) {
        this.ruleForm.attachment.push(res.data);
      } else if (res.ret === 6000) {
        this.$confirm(res.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this2.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this3 = this;

      this.ruleForm.attachment = [];
      fileList.forEach(function (item) {
        _this3.ruleForm.attachment.push(item.response.data);
      });
    },
    goCardInfo: function goCardInfo() {
      var _this4 = this;

      var code = this.ruleForm.code.replace(/[^0-9]/ig, '');
      this.currentPrice = null;
      (0, _api.getOldCardBalanceInfos)({
        code: code
      }).then(function (res) {
        _this4.currentPrice = res.data;
      });
    },
    clearForm: function clearForm(ruleForm) {
      this.cardData = {};
      this.$refs[ruleForm].resetFields();
    },

    /* submitFormLine(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.ruleForm))
          data.code = data.code.replace(/[^0-9]/ig, '')
          oldCardBingOnlineCard(data).then(res => {
            this.$message({
              type: 'success',
              message: '换新成功!'
            })
            // this.$router.go(-1)
          })
        } else {
          return false
        }
      })
    },
    */
    submitForm: function submitForm(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this5.ruleForm));
          data.code = data.code.replace(/[^0-9]/ig, '');
          var str = _this5.changType == 1 ? '此操作换新线下卡, 是否继续?' : '此操作绑定线上卡，实体卡将回收, 是否继续?';

          _this5.$confirm(str, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            if (_this5.changType === 1) {
              (0, _api.oldCardChange)(data).then(function (res) {
                _this5.$message({
                  type: 'success',
                  message: '换新成功!'
                });
              });
            } else {
              (0, _api.oldCardBingOnlineCard)(data).then(function (res) {
                _this5.$router.go(0);

                _this5.$message({
                  type: 'success',
                  message: '换新成功!'
                });
              });
            }
          }).catch(function () {
            _this5.$message({
              type: 'info',
              message: '已取消换卡'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;