"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/weal.xlsx"),
      rules: {
        configUserSet: [{
          required: true,
          message: '请上传员工手机号'
        }]
      },
      ruleForm: {
        type: 1,
        configUserSet: [],
        title: null,
        popImage: 'mika/dev/2022-04-26/65e5ba0c316342ea92dcf855140dd3b3.png'
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.getGoodsData)({
      type: 1,
      pageNo: 1,
      pageSize: 3000
    }).then(function (res) {
      if (!res.data) return;
      _this.ruleForm.configUserSet = res.data.records;
    });
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      console.log(this.ruleForm);
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.addData)(_this2.ruleForm).then(function (res) {
            _this2.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
        } else {
          return false;
        }
      });
    },
    handleDownload: function handleDownload() {
      var tHeader = ['手机号码'];
      var filterVal = ['mobile'];
      this.getHandleDownload(tHeader, filterVal, this.ruleForm.configUserSet, '员工福利');
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      results.forEach(function (data) {
        if (data.手机号码) {
          arr.push({
            mobile: data.手机号码
          });
        }
      });
      this.ruleForm.configUserSet = arr;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    }
  }
};
exports.default = _default;