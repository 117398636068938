"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '模板名称',
        prop: 'templateName',
        width: 50
      }, {
        label: '所属分类',
        prop: 'categoryName',
        width: 50
      }, {
        label: '信息类型（1，文字短信 2，企微消息）',
        prop: 'infoType',
        slot: 'infoType',
        width: 80
      }, {
        label: ' 单个用户每天可以发送几次',
        prop: 'dailyLimitNum',
        slot: 'dailyLimitNum'
      }, {
        label: '内容',
        prop: 'content',
        align: 'left',
        width: 200
      }, {
        label: '变量',
        prop: 'parameters',
        align: 'left',
        width: 80
      }, {
        label: '状态（1，停用 2，启用）',
        prop: 'status',
        slot: 'status',
        width: 80
      }, {
        label: '更新时间',
        prop: 'operateTime',
        width: 120
      }, {
        label: '操作人',
        prop: 'operateUser',
        width: 50
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '模板名称',
        value: 'templateName'
      }, {
        type: 'input',
        span: 4,
        placeholder: '信息内容',
        value: 'content'
      }, {
        type: 'input',
        span: 4,
        placeholder: '操作人',
        value: 'operateUser'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '状态',
        value: 'status',
        select: [{
          label: '停用',
          value: 1
        }, {
          label: '启用',
          value: 2
        }]
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }]
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/smsTask/listSmsCategoryPage', {
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      var select = res.data.records.map(function (item) {
        return {
          label: item.categoryName,
          value: item.id
        };
      });

      _this.searchData.push({
        type: 'radio',
        span: 4,
        placeholder: '分类',
        value: 'categoryId',
        select: select
      });
    });
  },
  methods: {}
};
exports.default = _default;