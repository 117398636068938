var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "temBox flex" }, [
    _c("img", {
      staticClass: "temBox_cover",
      attrs: { src: _vm.imgSrc + _vm.data.moduleAttr.img }
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "temBox_box" },
      _vm._l(_vm.list, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "temBox_item flex_center" },
          [
            item.linkCode === "coupon"
              ? _c("img", {
                  staticClass: "temBox_item_cover",
                  attrs: { src: _vm.imgSrc + item.cover }
                })
              : item.linkCode === "goods"
              ? _c("img", {
                  staticClass: "temBox_item_cover",
                  attrs: { src: _vm.imgSrc + item.cover }
                })
              : _c("img", {
                  staticClass: "temBox_item_cover",
                  attrs: { src: item.pic_url }
                }),
            _vm._v(" "),
            _c("div", { staticClass: "temBox_item_info" }, [
              _c("div", { staticClass: "temBox_item_name text_ellipsis" }, [
                _vm._v(
                  "\n            " + _vm._s(item.name || "") + "\n          "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "temBox_item_price" }, [
                _vm._v(
                  "\n            ￥" +
                    _vm._s(item.original_price || "") +
                    "\n          "
                )
              ])
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }