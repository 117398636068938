var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px", size: "mini" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "关联商品：" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addGoods("legouGoods")
                    }
                  }
                },
                [_vm._v("乐购商品")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addGoods("goods")
                    }
                  }
                },
                [_vm._v("积分商城商品")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addGoods("coupon")
                    }
                  }
                },
                [_vm._v("优惠券")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addTem } },
                [_vm._v("添加栏目")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.slelctPopShow
        ? _c("selectPop", {
            ref: "selectRef",
            attrs: { type: _vm.selectType },
            on: {
              setCoupons: _vm.setCoupons,
              closeCouponPop: _vm.closeCouponPop
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }