"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      columns: [{
        label: '返利卡号',
        prop: 'cardNo'
      }, {
        label: '返利金额',
        prop: 'rebatePrice'
      }],
      id: null,
      orderData: null
    };
  },
  created: function created() {
    var _this = this;

    this.id = this.$route.query.id;
    (0, _api.getCategory)(this.id).then(function (res) {
      _this.orderData = res.data;
    });
  },
  methods: {
    addParam: function addParam() {
      this.ruleForm.priceParamList.push({
        cardNo: null,
        rebatePrice: null
      });
    }
  }
};
exports.default = _default;