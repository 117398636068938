"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _regionData = _interopRequireDefault(require("@/utils/regionData.json"));

var _api = require("../api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var validatelongLat = function validatelongLat(rule, value, callback) {
  if (value !== '') {
    if (/^([0-9]+\.{0,1}[0-9]{0,})+\,+([0-9]+\.{0,1}[0-9]{0,})$/.test(value) === false) {
      callback(new Error('请填写用因为逗号分开'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

var _default = {
  data: function data() {
    return {
      regionData: _regionData.default,
      rules: {
        storeName: [{
          required: true,
          message: '请输入名称'
        }],
        storeStatus: [{
          required: true,
          message: '请选择状态'
        }],
        storeAddress: [{
          required: true,
          message: '请选择地址'
        }],
        longLat: [{
          required: true,
          message: '请输入经纬度'
        }, {
          validator: validatelongLat
        }],
        detailAddress: [{
          required: true,
          message: '请输入详细地址'
        }]
      },
      ruleForm: {
        storeAddress: null,
        storeName: null,
        storeStatus: null,
        longitude: null,
        latitude: null,
        detailAddress: null,
        contactPeople: null,
        contactPhone: null,
        longLat: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id) {
      (0, _api.getBankStore)(this.ruleForm.id).then(function (res) {
        res.data.longLat = res.data.latitude + ',' + res.data.longitude;
        res.data.storeAddress = res.data.storeAddress ? JSON.parse(res.data.storeAddress) : [];
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    // 三级地址选择
    regionDataChange: function regionDataChange(e) {
      var arr = this.$refs['state'].getCheckedNodes(false)[0].pathLabels || [];
      this.ruleForm.detailAddress = arr.join('');
    },
    clearForm: function clearForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.ruleForm));
          /* "longitude":"经度","latitude":"纬度"*/

          data.storeAddress = JSON.stringify(data.storeAddress);
          data.longitude = data.longLat.split(',')[1];
          data.latitude = data.longLat.split(',')[0];

          if (_this2.ruleForm.id) {
            data.id = _this2.ruleForm.id;
            (0, _api.editBankStore)(data).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addBankStore)(data).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;