"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var _api = require("./api.js");

var _manage = require("@/api/manage.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      userList: [],
      popType: '',
      couponShow: false,
      rules: {
        sysUserMobile: [{
          required: true,
          message: '请选择发券人'
        }],
        couponBagUuid: [{
          required: true,
          message: '请选择券包'
        }],
        stock: [{
          required: true,
          message: '请输入库存'
        }]
      },
      ruleForm: {
        id: null,
        sysUserId: null,
        sysUserName: null,
        sysUserMobile: null,
        couponBagUuid: null,
        couponBagImage: null,
        stock: null,
        storeCode: null,
        orgCode: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;
    this.ruleForm.activityId = this.$route.query.mallId;

    if (this.ruleForm.id) {
      (0, _api.getInfo)({
        id: this.ruleForm.id
      }).then(function (res) {
        _this.ruleForm = res.data;

        _this.remoteMethod(res.data.sysUserMobile);
      });
    }
  },
  methods: {
    changeUser: function changeUser(e) {
      var data = this.userList.find(function (item) {
        return item.mobile === e;
      });
      this.ruleForm.sysUserId = data ? data.userId : null;
      this.ruleForm.orgCode = data ? data.orgCode : null;
      this.ruleForm.storeCode = data ? data.orgCode : null;
      this.ruleForm.sysUserName = data ? data.username : null;
    },
    remoteMethod: function remoteMethod(e) {
      var _this2 = this;

      if (e) {
        var data = {
          pageNo: 1,
          pageSize: 100,
          mobile: e
        };
        (0, _manage.listSysUserPage)(data).then(function (res) {
          _this2.userList = res.data.items;
        });
      } else {
        this.userList = [];
      }
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    getAward: function getAward(type) {
      this.popType = type;
      this.$refs.coupon1.getList(this.popType, this.ruleForm.couponBagUuid);
      this.couponShow = true;
    },

    /*
      删除奖励项
      */
    setCoupon: function setCoupon(e) {
      this.ruleForm.couponBagUuid = e.uuid;
      this.ruleForm.couponBagImage = e.tableImage;
      this.couponShow = false;
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.addData)(_this3.ruleForm).then(function (res) {
            _this3.$router.go(-1);

            _this3.$message({
              type: 'success',
              message: '添加成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;