"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseUrl = baseUrl;
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var TokenKey = 'TOKEN__';

function baseUrl() {
  //return "http://10.7.0.59:9092"
  //return "http://10.6.1.140:9092"
  //  return "https://crm-api.xingli.proximateach.cn"
  return 'https://crm-api.xinglico.com'; //return 'https://mika-admin-app.blx.bodata.cn:18444'
}
/*
 https://jenkins.xinglico.com:8443/
 mikacrm-prod
 WpKES3ce8FkU01wk


http://10.7.0.4:8080/job/Xinglico-test/view/%E7%B1%B3%E5%8D%A1%E4%BC%9A%E5%91%98/
 账号：mika-test
 密码：j0SbBd643ndeKXE
 */


function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}