var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-place-form" },
    [
      _c("div", { staticClass: "view_title" }, [
        _vm._v(_vm._s(_vm.id ? "编辑" : "新建") + "场地")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-width": "180px",
            model: _vm.ruleForm,
            rules: _vm.rules
          }
        },
        [
          _c("Descriptions", { attrs: { title: "基本信息" } }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "场地名称：", required: "", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "10",
                  "show-word-limit": "",
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "服务分类：", prop: "categoryId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    size: "small",
                    placeholder: "请选择",
                    filterable: "",
                    autocomplete: "off"
                  },
                  model: {
                    value: _vm.ruleForm.categoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "categoryId", $$v)
                    },
                    expression: "ruleForm.categoryId"
                  }
                },
                _vm._l(_vm.categoryList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.name) + "\n          "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示到沙龙活动：", prop: "ifShow" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.ifShow,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "ifShow", $$v)
                    },
                    expression: "ruleForm.ifShow"
                  }
                },
                [_vm._v("显示")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.ruleForm.ifShow,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "ifShow", $$v)
                    },
                    expression: "ruleForm.ifShow"
                  }
                },
                [_vm._v("不显示")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "过期后跳转模板页面：", prop: "externalUrl" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.externalUrl,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "externalUrl",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.externalUrl"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("用于活动过期后跳转，不填不跳转")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "面积：", required: "", prop: "area" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { placeholder: "请输入" },
                  model: {
                    value: _vm.ruleForm.area,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "area",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.area"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("㎡")])],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "容纳量：", required: "", prop: "intakeCapacity" }
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { placeholder: "请输入" },
                  model: {
                    value: _vm.ruleForm.intakeCapacity,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "intakeCapacity",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.intakeCapacity"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("人")])],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "展示图片：", prop: "pic" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: { width: 345, height: 160, cover: _vm.ruleForm.pic },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "pic")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticStyle: { color: "#999999" } }, [
                _vm._v("建议图片尺寸：690 x 320")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "开放时间：" } },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "startTime" } },
                    [
                      _c("el-time-select", {
                        attrs: {
                          placeholder: "开始时间：",
                          "picker-options": {
                            start: "00:00",
                            step: "01:00",
                            end: "23:00"
                          }
                        },
                        model: {
                          value: _vm.ruleForm.startTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "startTime", $$v)
                          },
                          expression: "ruleForm.startTime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { prop: "endTime" }
                    },
                    [
                      _c("el-time-select", {
                        attrs: {
                          placeholder: "结束时间",
                          "picker-options": {
                            start: "01:00",
                            step: "01:00",
                            end: "24:00",
                            minTime: _vm.ruleForm.startTime
                          }
                        },
                        model: {
                          value: _vm.ruleForm.endTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "endTime", $$v)
                          },
                          expression: "ruleForm.endTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属门店：", prop: "storeId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    placeholder: "请选择",
                    filterable: "",
                    autocomplete: "off"
                  },
                  model: {
                    value: _vm.ruleForm.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "storeId", $$v)
                    },
                    expression: "ruleForm.storeId"
                  }
                },
                _vm._l(_vm.storeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.storeId,
                      attrs: {
                        label: item.shortName + "（" + item.storeCode + "）",
                        value: item.storeId
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n          "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "设备提供：", prop: "equipmentIdList" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    placeholder: "请选择",
                    multiple: "",
                    filterable: "",
                    autocomplete: "off"
                  },
                  model: {
                    value: _vm.ruleForm.equipmentIdList,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "equipmentIdList", $$v)
                    },
                    expression: "ruleForm.equipmentIdList"
                  }
                },
                _vm._l(_vm.equipmentList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label:
                        "" + item.name + (!item.shelve ? "（已下架）" : ""),
                      value: item.id,
                      disabled: !item.shelve
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "描述：", prop: "description" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                staticStyle: { "line-height": "1" },
                attrs: {
                  autosize: { minRows: 4, maxRows: 6 },
                  type: "textarea",
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.ruleForm.description,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "description",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "detail", label: "详情：" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.ruleForm.detail,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "detail", $$v)
                  },
                  expression: "ruleForm.detail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "服务项目：" } },
            [
              _c(
                "div",
                { staticClass: "svip-place-form_item" },
                _vm._l(_vm.ruleForm.itemList, function(item, idx) {
                  return _c(
                    "div",
                    { key: idx, staticClass: "svip-place-form_item_item" },
                    [
                      _c(
                        "div",
                        { staticClass: "svip-place-form_item_item_left" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "itemList." + idx + ".itemName",
                                size: "small",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入项目名称"
                                  }
                                ]
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "120px" },
                                attrs: { size: "small" },
                                model: {
                                  value: item.itemName,
                                  callback: function($$v) {
                                    _vm.$set(item, "itemName", $$v)
                                  },
                                  expression: "item.itemName"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-button", {
                            staticClass:
                              "svip-place-form_item_item_left_delete",
                            attrs: { type: "text", icon: "el-icon-delete" },
                            on: {
                              click: function($event) {
                                return _vm.delItem(idx)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "itemList." + idx + ".buyType",
                            rules: [
                              {
                                required: true,
                                message: "请选择价格标准原价"
                              }
                            ]
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: { width: "1000px" },
                              attrs: { size: "small" },
                              model: {
                                value: item.buyType,
                                callback: function($$v) {
                                  _vm.$set(item, "buyType", $$v)
                                },
                                expression: "item.buyType"
                              }
                            },
                            _vm._l(item.saleTypeList, function(
                              salesType,
                              index
                            ) {
                              return _c(
                                "el-row",
                                { key: index, attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label: salesType.buyType
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.SvipServiceItemType[
                                                    salesType.buyType
                                                  ]
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "form_label" }, [
                                    _vm._v("原价：")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "itemList." +
                                              idx +
                                              ".saleTypeList." +
                                              index +
                                              ".price",
                                            size: "small",
                                            rules: [
                                              {
                                                required:
                                                  item.buyType ===
                                                  salesType.buyType,
                                                message: "请输入原价"
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticStyle: { width: "150px" },
                                              attrs: {
                                                type: "number",
                                                disabled:
                                                  item.buyType !==
                                                  salesType.buyType,
                                                size: "small"
                                              },
                                              model: {
                                                value: salesType.price,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    salesType,
                                                    "price",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "salesType.price"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("元")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "form_label" }, [
                                    _vm._v("要客价：")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "itemList." +
                                              idx +
                                              ".saleTypeList." +
                                              index +
                                              ".priceSvip",
                                            size: "small",
                                            rules: [
                                              {
                                                required:
                                                  item.buyType ===
                                                  salesType.buyType,
                                                message: "请输入要客价"
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticStyle: { width: "150px" },
                                              attrs: {
                                                type: "number",
                                                disabled:
                                                  item.buyType !==
                                                  salesType.buyType,
                                                size: "small"
                                              },
                                              model: {
                                                value: salesType.priceSvip,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    salesType,
                                                    "priceSvip",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "salesType.priceSvip"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("元")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "form_label" }, [
                                    _vm._v("365价：")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "itemList." +
                                              idx +
                                              ".saleTypeList." +
                                              index +
                                              ".price365",
                                            size: "small",
                                            rules: [
                                              {
                                                required:
                                                  item.buyType ===
                                                  salesType.buyType,
                                                message: "请输入365价"
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticStyle: { width: "150px" },
                                              attrs: {
                                                type: "number",
                                                disabled:
                                                  item.buyType !==
                                                  salesType.buyType,
                                                size: "small"
                                              },
                                              model: {
                                                value: salesType.price365,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    salesType,
                                                    "price365",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "salesType.price365"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("元")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "max-width": "100%" },
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: { click: _vm.addItem }
                },
                [_vm._v("\n          添加项目\n        ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "销售标准：", prop: "saleType" } },
            [
              _c(
                "div",
                [
                  _c("el-checkbox", {
                    attrs: { label: "按天" },
                    on: {
                      change: function($event) {
                        return _vm.saleTypeChange($event, "isDay")
                      }
                    },
                    model: {
                      value: _vm.ruleForm.isDay,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "isDay", $$v)
                      },
                      expression: "ruleForm.isDay"
                    }
                  }),
                  _vm._v(" "),
                  _vm.ruleForm.isDay
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          attrs: {
                            label: "销售价格：",
                            "label-width": "100px",
                            prop: "dailyBuyType",
                            size: "small"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: { width: "1200px" },
                              attrs: { size: "small" },
                              model: {
                                value: _vm.ruleForm.dailyBuyType,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "dailyBuyType", $$v)
                                },
                                expression: "ruleForm.dailyBuyType"
                              }
                            },
                            _vm._l(_vm.ruleForm.dailySaleTypeList, function(
                              salesType,
                              index
                            ) {
                              return _c(
                                "el-row",
                                { key: index, attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 2 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label: salesType.buyType
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.SvipServiceBuyType[
                                                    salesType.buyType
                                                  ]
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  salesType.buyType !== 1
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "form_label" },
                                          [_vm._v("原价：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "dailySaleTypeList." +
                                                    index +
                                                    ".price",
                                                  size: "small",
                                                  rules: [
                                                    {
                                                      required:
                                                        _vm.ruleForm
                                                          .dailyBuyType ===
                                                        salesType.buyType,
                                                      message: "请输入原价"
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    staticStyle: {
                                                      width: "140px"
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      disabled:
                                                        _vm.ruleForm
                                                          .dailyBuyType !==
                                                        salesType.buyType,
                                                      size: "small"
                                                    },
                                                    model: {
                                                      value: salesType.price,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          salesType,
                                                          "price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "salesType.price"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  salesType.buyType !== 1
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "form_label" },
                                          [_vm._v("365价：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "dailySaleTypeList." +
                                                    index +
                                                    ".price365",
                                                  size: "small",
                                                  rules: [
                                                    {
                                                      required:
                                                        _vm.ruleForm
                                                          .dailyBuyType ===
                                                        salesType.buyType,
                                                      message: "请输入365价"
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    staticStyle: {
                                                      width: "140px"
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      disabled:
                                                        _vm.ruleForm
                                                          .dailyBuyType !==
                                                        salesType.buyType,
                                                      size: "small"
                                                    },
                                                    model: {
                                                      value: salesType.price365,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          salesType,
                                                          "price365",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "salesType.price365"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  salesType.buyType !== 1
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "form_label" },
                                          [_vm._v("要客价：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "dailySaleTypeList." +
                                                    index +
                                                    ".priceSvip",
                                                  size: "small",
                                                  rules: [
                                                    {
                                                      required:
                                                        _vm.ruleForm
                                                          .dailyBuyType ===
                                                        salesType.buyType,
                                                      message: "请输入要客价"
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    staticStyle: {
                                                      width: "140px"
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      disabled:
                                                        _vm.ruleForm
                                                          .dailyBuyType !==
                                                        salesType.buyType,
                                                      size: "small"
                                                    },
                                                    model: {
                                                      value:
                                                        salesType.priceSvip,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          salesType,
                                                          "priceSvip",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "salesType.priceSvip"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  salesType.buyType === 2
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "form_label" },
                                          [_vm._v("积分可抵扣：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "dailySaleTypeList." +
                                                    index +
                                                    ".deductiblePrice",
                                                  size: "small"
                                                }
                                              },
                                              [
                                                _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100px"
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    min: 0,
                                                    disabled:
                                                      _vm.ruleForm
                                                        .dailyBuyType !==
                                                      salesType.buyType,
                                                    step: 0.01,
                                                    precision: 2,
                                                    controls: false,
                                                    "step-strictly": "",
                                                    size: "small"
                                                  },
                                                  model: {
                                                    value:
                                                      salesType.deductiblePrice,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        salesType,
                                                        "deductiblePrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "salesType.deductiblePrice"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", {
                    attrs: { label: "按小时" },
                    on: {
                      change: function($event) {
                        return _vm.saleTypeChange($event, "isHour")
                      }
                    },
                    model: {
                      value: _vm.ruleForm.isHour,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "isHour", $$v)
                      },
                      expression: "ruleForm.isHour"
                    }
                  }),
                  _vm._v(" "),
                  _vm.ruleForm.isHour
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "form_item",
                          attrs: {
                            label: "销售价格：",
                            "label-width": "100px",
                            prop: "hourBuyType",
                            size: "small"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: { width: "1200px" },
                              attrs: { size: "small" },
                              model: {
                                value: _vm.ruleForm.hourBuyType,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "hourBuyType", $$v)
                                },
                                expression: "ruleForm.hourBuyType"
                              }
                            },
                            _vm._l(_vm.ruleForm.hourSaleTypeList, function(
                              salesType,
                              index
                            ) {
                              return _c(
                                "el-row",
                                { key: index, attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 2 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label: salesType.buyType
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.SvipServiceBuyType[
                                                    salesType.buyType
                                                  ]
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  salesType.buyType !== 1
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "form_label" },
                                          [_vm._v("原价：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "hourSaleTypeList." +
                                                    index +
                                                    ".price",
                                                  size: "small",
                                                  rules: [
                                                    {
                                                      required:
                                                        _vm.ruleForm
                                                          .hourBuyType ===
                                                        salesType.buyType,
                                                      message: "请输入原价"
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    staticStyle: {
                                                      width: "140px"
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      disabled:
                                                        _vm.ruleForm
                                                          .hourBuyType !==
                                                        salesType.buyType,
                                                      size: "small"
                                                    },
                                                    model: {
                                                      value: salesType.price,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          salesType,
                                                          "price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "salesType.price"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  salesType.buyType !== 1
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "form_label" },
                                          [_vm._v("365价：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "hourSaleTypeList." +
                                                    index +
                                                    ".price365",
                                                  size: "small",
                                                  rules: [
                                                    {
                                                      required:
                                                        _vm.ruleForm
                                                          .hourBuyType ===
                                                        salesType.buyType,
                                                      message: "请输入365价"
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    staticStyle: {
                                                      width: "140px"
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      disabled:
                                                        _vm.ruleForm
                                                          .hourBuyType !==
                                                        salesType.buyType,
                                                      size: "small"
                                                    },
                                                    model: {
                                                      value: salesType.price365,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          salesType,
                                                          "price365",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "salesType.price365"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  salesType.buyType !== 1
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "form_label" },
                                          [_vm._v("要客价：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "hourSaleTypeList." +
                                                    index +
                                                    ".priceSvip",
                                                  size: "small",
                                                  rules: [
                                                    {
                                                      required:
                                                        _vm.ruleForm
                                                          .hourBuyType ===
                                                        salesType.buyType,
                                                      message: "请输入要客价"
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    staticStyle: {
                                                      width: "140px"
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      disabled:
                                                        _vm.ruleForm
                                                          .hourBuyType !==
                                                        salesType.buyType,
                                                      size: "small"
                                                    },
                                                    model: {
                                                      value:
                                                        salesType.priceSvip,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          salesType,
                                                          "priceSvip",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "salesType.priceSvip"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("元")]
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  salesType.buyType === 2
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "form_label" },
                                          [_vm._v("积分可抵扣：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "hourSaleTypeList." +
                                                    index +
                                                    ".deductiblePrice",
                                                  size: "small"
                                                }
                                              },
                                              [
                                                _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100px"
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    min: 0,
                                                    disabled:
                                                      _vm.ruleForm
                                                        .hourBuyType !==
                                                      salesType.buyType,
                                                    step: 0.01,
                                                    precision: 2,
                                                    controls: false,
                                                    "step-strictly": "",
                                                    size: "small"
                                                  },
                                                  model: {
                                                    value:
                                                      salesType.deductiblePrice,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        salesType,
                                                        "deductiblePrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "salesType.deductiblePrice"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("Descriptions", { attrs: { title: "预约设置" } }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "可预约身份：", prop: "memberType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.memberType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "memberType", $$v)
                    },
                    expression: "ruleForm.memberType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("全部会员")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("要客")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("365会员")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { label: "预约时间范围：", required: "", size: "small" }
            },
            [
              _c("el-row", { attrs: { gutter: 0, type: "flex" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "reserveTimeRange",
                          size: "small",
                          rules: _vm.reserveTimeRangeRules
                        }
                      },
                      [
                        _c("span", { staticClass: "form_label" }, [
                          _vm._v("当前时间后")
                        ]),
                        _vm._v(" "),
                        _c("el-input", {
                          staticStyle: { width: "90px" },
                          attrs: { type: "number", size: "small" },
                          model: {
                            value: _vm.ruleForm.reserveTimeRange,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "reserveTimeRange",
                                _vm._n($$v)
                              )
                            },
                            expression: "ruleForm.reserveTimeRange"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { prop: "reserveTimeRangeUnit", size: "small" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "form_input",
                            staticStyle: {
                              width: "74px",
                              "margin-left": "4px"
                            },
                            attrs: {
                              size: "small",
                              filterable: "",
                              autocomplete: "off"
                            },
                            on: { change: _vm.reserveTimeRangeUnitChange },
                            model: {
                              value: _vm.ruleForm.reserveTimeRangeUnit,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "reserveTimeRangeUnit",
                                  $$v
                                )
                              },
                              expression: "ruleForm.reserveTimeRangeUnit"
                            }
                          },
                          [
                            _c(
                              "el-option",
                              { attrs: { label: "天", value: 1 } },
                              [_vm._v("天")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-option",
                              { attrs: { label: "月", value: 2 } },
                              [_vm._v("月")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("span", { staticStyle: { color: "gray" } }, [
                _vm._v(
                  "例：设置10天，客户可选择当前时间后10天内（包含当天）的日期"
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: {
                label: "限制累计预约次数：",
                prop: "restrictionsNum",
                size: "small"
              }
            },
            [
              _c("el-input-number", {
                staticClass: "form_input-number",
                attrs: {
                  controls: false,
                  size: "small",
                  min: 0,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.ruleForm.restrictionsNum,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "restrictionsNum", _vm._n($$v))
                  },
                  expression: "ruleForm.restrictionsNum"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: {
                label: "提前预约设置：",
                required: "",
                prop: "limitAdvanceTime",
                size: "small"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 0 } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { width: "1000px" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.ruleForm.limitAdvanceTime,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "limitAdvanceTime", $$v)
                        },
                        expression: "ruleForm.limitAdvanceTime"
                      }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 0 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("无需提前预约")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 0 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("提前")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "advanceTime",
                                    size: "small",
                                    "show-message": false,
                                    rules: [
                                      {
                                        required:
                                          _vm.ruleForm.limitAdvanceTime === true
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "80px" },
                                    attrs: {
                                      size: "small",
                                      disabled:
                                        _vm.ruleForm.limitAdvanceTime === false
                                    },
                                    model: {
                                      value: _vm.ruleForm.advanceTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "advanceTime",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "ruleForm.advanceTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "advanceTimeUnit",
                                    size: "small"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "form_input",
                                      staticStyle: { width: "74px" },
                                      attrs: {
                                        size: "small",
                                        filterable: "",
                                        autocomplete: "off",
                                        disabled:
                                          _vm.ruleForm.limitAdvanceTime ===
                                          false
                                      },
                                      model: {
                                        value: _vm.ruleForm.advanceTimeUnit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "advanceTimeUnit",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.advanceTimeUnit"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-option",
                                        { attrs: { label: "小时", value: 1 } },
                                        [_vm._v("小时")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-option",
                                        { attrs: { label: "天", value: 2 } },
                                        [_vm._v("天")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { color: "gray" } }, [
                _vm._v(
                  "例：设置提前2天，客户当前预约时只可选择2天后（包含当天）的日期；无限制时可选择当前时间"
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.id
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm", "edit")
                        }
                      }
                    },
                    [_vm._v("\n          保存编辑\n        ")]
                  )
                : _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm", "add")
                        }
                      }
                    },
                    [_vm._v("\n          立即新建\n        ")]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }