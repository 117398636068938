"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

var _manage = require("@/api/manage.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 筛选函数
function filterTreeArray(array, condition) {
  var result = [];
  array.forEach(function (node) {
    if (condition(node)) {
      result.push(node);
    }

    if (node.children && node.children.length > 0) {
      var filteredChildren = filterTreeArray(node.children, condition);

      if (filteredChildren.length > 0) {
        node.children = filteredChildren;
        result.push(node);
      }
    }
  });
  return result;
}

var _default = {
  data: function data() {
    return {
      dataRole: null,
      // 角色树形
      userId: null,
      roleIds: null,
      menuList: [],
      showMenus: [],
      expanded: [],
      defaultProps: {
        children: 'childMenu',
        label: 'name'
      },
      selectMenus: []
    };
  },
  computed: {
    shouRole: function shouRole() {
      var _this = this;

      if (this.roleIds && this.dataRole) {
        return this.dataRole.filter(function (item) {
          return _this.roleIds.includes(item.roleId);
        });
      } else {
        return [];
      }
    }
  },
  created: function created() {
    this.userId = Number(this.$route.query.userId);

    if (this.userId) {
      this.init();
    }
  },
  methods: {
    init: function init() {
      var _this2 = this;

      (0, _manage.listSysRole)().then(function (res) {
        _this2.dataRole = res.data;
      }).catch(function (res) {
        _this2.openLoading().close();
      });
      (0, _manage.getSysUserById)({
        userId: this.userId
      }).then(function (res) {
        _this2.roleIds = JSON.parse(res.data.roleIds);
      });
      (0, _manage.listMenuTree)().then(function (res) {
        _this2.menuList = res.data;
      });
    },
    getRoleDea: function getRoleDea(item) {
      var _this3 = this;

      // this.$refs.dialogForm.clearValidate();
      //  this.$refs.tree.setCheckedKeys([]);
      this.apiGet('/api/sysRole/getSysRoleById?roleId=' + item.roleId).then(function (res) {
        _this3.expanded = JSON.parse(res.data.menuIds);
        _this3.selectMenus = filterTreeArray(_this3.menuList, function (node) {
          return _this3.expanded.includes(node.menuId);
        });
      });
    }
  }
};
exports.default = _default;