"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addVippriceSpecial = addVippriceSpecial;
exports.editVippriceSpecial = editVippriceSpecial;
exports.getVippriceSpecial = getVippriceSpecial;
exports.listVippriceSpecialGoodsPage = listVippriceSpecialGoodsPage;
exports.listVippriceSpecialPage = listVippriceSpecialPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
1、特殊会员策略分页：/api/vippriceSpecial/listVippriceSpecialPage
{"pageNo":"当前页","pageSize":"页条数"}

2、新增特殊会员价策略：/api/vippriceSpecial/addVippriceSpecial
{"cdtionName":"策略名称","cditionStatus":"策略状态(1：启用 -1：停用)","cditionRemark":"策略备注",
"discountRate":"折扣率","cliqueRate":"集团承担比率","validStartTime":"执行开始时间","validEndTime":"执行结束时间","companyCode":"公司编码","companyName":"公司名称","cditions":[{"attrName":"属性名称","attrCode":"属性编码","attrType":"属性类型(1：门店 2：商户 3：品类 4：品牌 5：商品)"}]}

3、编辑特殊会员价策略：/api/vippriceSpecial/editVippriceSpecial
{"specialId","id","cdtionName":"策略名称","cditionStatus":"策略状态(1：启用 -1：停用)","cditionRemark":"策略备注","discountRate":"折扣率","cliqueRate":"集团承担比率","validStartTime":"执行开始时间","validEndTime":"执行结束时间","companyCode":"公司编码","companyName":"公司名称","cditions":[{"attrName":"属性名称","attrCode":"属性编码","attrType":"属性类型(1：门店 2：商户 3：品类 4：品牌 5：商品)"}]}

4、查询特殊会员价策略明细：/api/vippriceSpecial/getVippriceSpecial/{specialId}
特殊策略查询商品列表分：/api/vippriceSpecial/listVippriceSpecialGoodsPage

 */
function listVippriceSpecialGoodsPage(data) {
  return (0, _request.default)({
    url: '/api/vippriceSpecial/listVippriceSpecialGoodsPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getVippriceSpecial(data) {
  return (0, _request.default)({
    url: '/api/vippriceSpecial/getVippriceSpecial/' + data,
    method: 'post',
    loading: 'loading'
  });
}
/*
 */


function listVippriceSpecialPage(data) {
  return (0, _request.default)({
    url: '/api/vippriceSpecial/listVippriceSpecialPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
 */


function addVippriceSpecial(data) {
  return (0, _request.default)({
    url: '/api/vippriceSpecial/addVippriceSpecial',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editVippriceSpecial(data) {
  return (0, _request.default)({
    url: '/api/vippriceSpecial/editVippriceSpecial',
    method: 'post',
    loading: 'loading',
    data: data
  });
}