"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.goodsBrand = goodsBrand;
exports.goodsCat = goodsCat;
exports.goodsList = goodsList;
exports.listMerchantPage = listMerchantPage;
exports.payment = payment;

var _request = _interopRequireDefault(require("@/utils/request"));

var _phpRequest = _interopRequireDefault(require("@/utils/phpRequest.js"));

/*
 商户
 */
function listMerchantPage(data) {
  return (0, _request.default)({
    url: '/api/scoreBasic/listMerchantPage',
    method: 'post',
    data: data
  });
}
/*
 商品
 */


function goodsList(data) {
  return (0, _request.default)({
    // url: 'api/goods/goods-list&_sid='+sid,
    url: '/api/scoreBasic/listGoodsPage',
    method: 'post',
    // baseURL:'https://goods.xinglico.com/index.php?r=',
    data: data
  });
}
/*
 分类
 */


function goodsCat(data) {
  return (0, _request.default)({
    //  url: 'api/goods/cat',
    url: '/api/scoreBasic/listClassPage',
    method: 'post',
    // baseURL:'https://goods.xinglico.com/index.php?r=',
    data: data
  });
}
/*
 品牌
 */


function goodsBrand(data) {
  return (0, _request.default)({
    // url: 'api/goods/brand',
    url: '/api/scoreBasic/listBrandPage',
    method: 'post',
    // baseURL:'https://goods.xinglico.com/index.php?r=',
    data: data
  });
}
/*
 品牌
 */


function payment(data) {
  return (0, _phpRequest.default)({
    url: 'api/goods/payment',
    method: 'get',
    baseURL: 'https://goods.xinglico.com/index.php?r=',
    params: data
  });
}