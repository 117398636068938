"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _regionData = _interopRequireDefault(require("@/utils/regionData.json"));

var _index = require("./index.js");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index2 = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    wTable: _index2.default,
    AppLink: _Link.default
  },
  data: function data() {
    return {
      columns: [{
        label: '名称',
        prop: 'parkingName',
        width: 100
      }, {
        label: '地址',
        prop: 'detailAddress',
        width: 100
      }, {
        label: '基础收费（元）',
        prop: 'basicCost',
        width: 50
      }, {
        label: '免费时长（分钟）',
        prop: 'freeTime',
        width: 50
      }, {
        slot: 'operate'
      }],
      storeId: '',
      list: [],
      state: [],
      regionData: _regionData.default,
      systemList: [],
      form: {
        parkingList: [],
        checked1: null,
        checked2: null,
        orgFloorList: [],
        companyId: '',
        fullName: '',
        shortName: '',
        storeCode: '',
        storeStatus: '',
        state: [],
        detailAddress: '',
        longOrLat: '',
        contactPeople: '',
        contactPhone: '',
        image: '',
        introduction: ''
      },
      options: [],
      storeStatusList: [{
        label: '开业准备',
        value: 1
      }, {
        label: '正常营业',
        value: 2
      }, {
        label: '暂停营业',
        value: 3
      }, {
        label: '作废',
        value: 4
      }],
      rules: {
        companyId: [{
          required: true,
          message: '请选择公司总部'
        }],
        fullName: [{
          required: true,
          message: '请填写门店全称'
        }],
        shortName: [{
          required: true,
          message: '请填写公司简称'
        }],
        storeCode: [{
          required: true,
          message: '请填写ERP机构编码'
        }],
        state: [{
          type: 'array',
          required: true,
          message: '请选择门店地址'
        }],
        detailAddress: [{
          required: true,
          message: '请填写详细地址'
        }],
        longOrLat: [{
          required: true,
          message: '请填写经纬度'
        }],
        contactPeople: [{
          required: true,
          message: '请填写门店负责人'
        }],
        contactPhone: [{
          type: 'number',
          required: true,
          message: '请输入门店电话'
        }, {
          pattern: /^0{0,1}(1)[0-9]{10}$/,
          message: '门店电话格式不对'
        }],
        storeStatus: [{
          required: true,
          message: '请选择门状态'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.storeId = this.$route.query.storeId;
    (0, _index.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.list = res.data;

      if (_this.storeId) {
        (0, _index.getStoreById)(_this.storeId).then(function (res) {
          var data = res.data;
          _this.form.parkingList = res.data.parkings || [];
          _this.form.orgFloorList = res.data.floorList.floor;
          _this.form.companyId = data.companyId;
          _this.form.fullName = data.fullName;
          _this.form.shortName = data.shortName;
          _this.form.storeCode = data.storeCode;
          _this.form.storeStatus = data.storeStatus;

          if (data.provinceCode) {
            _this.form.state = [data.provinceCode, data.cityCode, data.countyCode];
          }

          _this.form.detailAddress = data.detailAddress;

          if (data.longitude && data.latitude) {
            _this.form.longOrLat = data.longitude + ',' + data.latitude;
          }

          _this.form.contactPeople = data.contactPeople;
          _this.form.contactPhone = data.contactPhone;
          _this.form.introduction = data.introduction;
          _this.form.image = data.image;
        });
      }
    });
  },
  methods: {
    addPark: function addPark() {
      this.form.parkingList.push({
        parkLongOrLat: null,
        parkSite: null,
        detailAddress: null,
        parkCover: null,
        parkingName: null
      });
    },
    deleteParking: function deleteParking(index) {
      var _this2 = this;

      this.$confirm('此操作将删除该停车场, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.form.parkingList.splice(index, 1);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    setParkingCover: function setParkingCover(e, index) {
      this.form.parkingList[index].parkCover = e;
    },
    delFloor: function delFloor(index) {
      var _this3 = this;

      this.$confirm('此操作将删除楼层图, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.form.orgFloorList.splice(index, 1);
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    setCover: function setCover(e, index) {
      if (index == 'image') {
        this.form.image = e;
      } else {
        this.form.orgFloorList[index].image = e;
      }
    },
    addFloor: function addFloor() {
      this.form.orgFloorList.push({
        sort: '',
        floor: '',
        image: ''
      });
    },
    // 三级地址选择
    regionDataChange: function regionDataChange(e) {
      var arr = this.$refs['state'].getCheckedNodes(false)[0].pathLabels || [];
      this.form.detailAddress = arr.join('');
    },
    regionDataParking: function regionDataParking(e, index) {
      var arr = this.$refs['parkState' + index][0].getCheckedNodes(false)[0].pathLabels || [];
      this.form.orgFloorList[index].detailAddress = arr.join('');
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this4.form.longOrLat.split(',').length !== 2) {
            _this4.$message.error('经度纬度，请使用英文的逗号隔开');
          } else {
            var data = {
              companyId: _this4.form.companyId,
              fullName: _this4.form.fullName,
              shortName: _this4.form.shortName,
              storeCode: _this4.form.storeCode,
              storeStatus: _this4.form.storeStatus,
              provinceCode: _this4.form.state[0],
              cityCode: _this4.form.state[1],
              countyCode: _this4.form.state[2],
              detailAddress: _this4.form.detailAddress,
              longitude: _this4.form.longOrLat.split(',')[0],
              latitude: _this4.form.longOrLat.split(',')[1],
              contactPeople: _this4.form.contactPeople,
              contactPhone: _this4.form.contactPhone,
              orgFloorList: _this4.form.orgFloorList,
              image: _this4.form.image,
              introduction: _this4.form.introduction
            };

            if (_this4.storeId) {
              data.storeId = _this4.storeId;
              (0, _index.editStore)(data).then(function (res) {
                _this4.$message({
                  type: 'success',
                  message: '编辑成功!'
                });

                _this4.$router.push('/system/manage/listStoreList');
              });
            } else {
              console.log(JSON.stringify(data));
              (0, _index.addStore)(data).then(function (res) {
                _this4.$message({
                  type: 'success',
                  message: '新增成功!'
                });

                _this4.$router.push('/system/manage/listStoreList');
              });
            }
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;