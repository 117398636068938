"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.function.name");

var _finance = require("@/api/finance");

var _index = require("@/views/system/manage/index.js");

var _api = require("../receiptSetting/api");

var _index2 = require("@/utils/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var statusOptions = [{
  value: -1,
  label: '已取消'
}, {
  value: 0,
  label: '待审核'
}, {
  value: 1,
  label: '审核通过'
}, {
  value: 2,
  label: '未通过'
}];
var memberTypeOptions = [{
  value: 0,
  label: '普通会员'
}, {
  value: 1,
  label: '365会员'
}, {
  value: 2,
  label: '要客'
}];
var _default = {
  data: function data() {
    return {
      statusOptions: statusOptions,
      memberTypeOptions: memberTypeOptions,
      info: {
        id: 0
      },
      form: {
        status: 2,
        auditStoreName: undefined,
        auditBrandId: undefined,
        auditPrice: undefined,
        orderCode: undefined,
        auditBrandName: undefined,
        auditStoreId: undefined,
        attachment: undefined,
        auditMsg: undefined
      },
      rules: {
        status: [{
          required: true,
          message: '请选择审核方案',
          trigger: 'change'
        }],
        auditStoreId: [{
          required: true,
          message: '请选择门店',
          trigger: 'change'
        }],
        auditBrandId: [{
          required: true,
          message: '请选择品牌',
          trigger: 'change'
        }],
        orderCode: [{
          required: true,
          message: '请输入相关单据',
          trigger: 'blur'
        }],
        auditPrice: [{
          required: true,
          message: '请输入订单金额',
          trigger: 'blur'
        }],
        auditMsg: [{
          required: true,
          message: '请输入审核说明',
          trigger: 'blur'
        }]
      },
      //
      storeOptions: [],
      storeloading: false,
      //
      brandOptions: []
    };
  },
  computed: {
    isDetail: function isDetail() {
      return this.$route.query.action === 'detail';
    },
    imgSrc: function imgSrc() {
      return this.$store.state.user.imgSrc;
    },
    getIntegral: function getIntegral() {
      var _this$info = this.info,
          unitPrice = _this$info.unitPrice,
          unitScore = _this$info.unitScore;
      var auditPrice = this.form.auditPrice || 0;
      return parseInt(auditPrice / unitPrice * unitScore);
    }
  },
  created: function created() {
    this.info.id = this.$route.query.id;
    this.getInfo();
    this.getBrands();

    if (!this.info.id) {
      this.remoteStoreList();
    }
  },
  methods: {
    getOptionsValue: _index2.getOptionsValue,
    initForm: function initForm(data) {
      this.form.auditStoreId = data.storeId;
      this.storeOptions = [{
        storeCode: data.storeId,
        fullName: data.storeName
      }];
      this.form.auditBrandId = parseInt(data.type);
      this.form.auditPrice = data.price;
      this.$forceUpdate();
    },
    onCancel: function onCancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/finance/receiptUpload',
        replace: true
      });
    },
    onConfirm: function onConfirm() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var params = JSON.parse(JSON.stringify(_this.form));

          if (params.auditBrandId) {
            params.auditBrandName = _this.brandOptions.find(function (item) {
              return item.id === params.auditBrandId;
            }).name;
          }

          if (params.auditStoreId) {
            params.auditStoreName = _this.storeOptions.find(function (item) {
              return item.storeCode === params.auditStoreId;
            }).fullName;
          }

          params.id = _this.info.id;
          (0, _api.receiptUploadCheck)(params).then(function (res) {
            _this.onCancel();
          });
        }
      });
    },
    getInfo: function getInfo() {
      var _this2 = this;

      (0, _finance.receiptUploadDetail)(this.info.id).then(function (res) {
        var data = res.data;
        var imgs = [];

        if (data.imgurl) {
          imgs = data.imgurl.split(',');

          if (imgs.length > 0) {
            imgs = imgs.map(function (img) {
              return _this2.imgSrc + img;
            });
          }
        }

        data.imgs = imgs;
        _this2.info = data;

        _this2.initForm(data);
      });
    },
    remoteStoreList: function remoteStoreList(query) {
      var _this3 = this;

      this.storeloading = true;
      var params = {
        pageNo: 1,
        pageSize: 20,
        fullName: query
      };
      return (0, _index.listStorePage)(params).then(function (res) {
        _this3.storeOptions = res.data.items;
        _this3.storeloading = false;
      }).catch(function (err) {
        _this3.storeloading = false;
      });
    },
    getBrands: function getBrands() {
      var _this4 = this;

      (0, _api.brandOptions)().then(function (res) {
        _this4.brandOptions = res.data;
      });
    }
  }
};
exports.default = _default;