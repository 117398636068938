"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _member = require("@/api/member.js");

var _mem = require("@/api/mem.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      list: [],
      searchTime: '',
      total: 0,
      btnText: '详情',
      storeList: [],
      obj: {
        pageNo: 1,
        pageSize: 10,
        exchangeUmobile: '',
        giftType: '',
        // giftName:'',
        storeCode: ''
      },
      cardList: [],
      tabTh: [{
        prop: 'giftType',
        label: '礼品类型',
        width: 60
      }, {
        prop: 'giftName',
        label: '馈赠礼品',
        width: 100
      }, {
        prop: 'scorePrice',
        label: '积分价格',
        width: 60
      }, {
        prop: 'purchasingPrice',
        label: '进货价',
        width: 60
      }, {
        prop: 'exchangeNumber',
        label: '兑换数量',
        width: 60
      }, {
        prop: 'stockNumber',
        label: '剩余数量',
        width: 60
      }, {
        prop: 'purchasingPriceTotal',
        label: '进货价合计',
        width: 60
      }, {
        prop: 'consumeScore',
        label: '积分合计',
        width: 60
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _member.listType)({}).then(function (res) {
      _this.cardList = res.data;
    });
    (0, _mem.listStore)({
      companyId: ''
    }).then(function (res) {
      _this.storeList = res.data;
    });
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this2 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
      }

      if (type == 'remove') {
        this.searchTime = [];
        this.removeObj(this.obj);
      }

      this.obj.startDate = this.searchTime[0] || '';
      this.obj.endDate = this.searchTime[1] || '';
      this.openLoading();
      (0, _member.listExchangeReportPage)(this.obj).then(function (res) {
        _this2.openLoading().close();

        _this2.list = res.data.items;
        _this2.total = res.data.totalNum;
      }).catch(function (err) {
        _this2.openLoading().close();
      });
    },
    goAdd: function goAdd() {// this.$router.push('/member/present/add');
    },
    setTab: function setTab(index, row) {//  editExchange({id:row.id,refundsRemark:"退货备注"})
      // this.$router.push('/member/present/returnSales?id='+row.id)
      //  getExchangeById(row.id)
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['门店名称', '门店编码', '礼品类型', '馈赠礼品', '积分价格', '进货价', '兑换数量', '剩余数量', '进货价合计', '积分合计'];
        var filterVal = ['storeName', 'storeCode', 'giftType', 'giftName', 'scorePrice', 'purchasingPrice', 'exchangeNumber', 'stockNumber', 'purchasingPriceTotal', 'consumeScore'];
        var list = [];
        var obj = JSON.parse(JSON.stringify(_this3.obj));
        obj.startDate = _this3.searchTime[0] || '';
        obj.endDate = _this3.searchTime[1] || '';
        obj.pageSize = _this3.total;
        (0, _member.listExchangeReportPage)(obj).then(function (res) {
          list = res.data.items;

          var data = _this3.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '馈赠报表'
          });
          _this3.downloadLoading = false;
        }).catch(function (res) {
          _this3.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;