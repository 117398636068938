var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增购物卡锁定 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "120px"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "冻结卡门店：", prop: "institutionCode" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    size: "small",
                    placeholder: "所属门店",
                    filterable: "",
                    autocomplete: "off"
                  },
                  model: {
                    value: _vm.ruleForm.institutionCode,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "institutionCode", $$v)
                    },
                    expression: "ruleForm.institutionCode"
                  }
                },
                _vm._l(_vm.storeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.storeId,
                      attrs: {
                        label: item.shortName + "（" + item.storeCode + "）",
                        value: item.storeCode
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.shortName + "（" + item.storeCode + "）"
                          ) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v(
                  "购物卡分通用卡和门店限用卡；不选择门店只冻结全场通用的，选择门店就冻结店铺专用加全场通用"
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "手机号码：", prop: "uid" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { maxlength: "11", placeholder: "请输入手机号码" },
                on: { change: _vm.changeMobile },
                model: {
                  value: _vm.ruleForm.userMobile,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "userMobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.userMobile"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "100px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.getUserInfo }
                },
                [_vm._v("查询")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "会员信息：" } }, [
            _c(
              "div",
              { staticClass: "change-label-calss" },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "手机号码：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.mobile))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "会员姓名：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.realname))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "会员ID：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.uid))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "会员积分：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.score))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "乐享值：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.growthValue))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "性别：" } }, [
                          _vm.userInfo.sex == 1
                            ? _c("span", [_vm._v("男")])
                            : _vm.userInfo.sex == 2
                            ? _c("span", [_vm._v("女")])
                            : _c("span", [_vm._v("未知")])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "民族：" } }, [
                          _vm._v(_vm._s(_vm.userInfo.nation))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "卡余额：" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.userCard.cardStoreAmount || 0) +
                "元\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "卡数量：" } }, [
            _vm._v("\n      " + _vm._s(_vm.userCard.num || 0) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "冻结金额：", prop: "amount" } },
            [
              _c("el-input-number", {
                attrs: {
                  controls: false,
                  min: 1,
                  max: _vm.userCard.cardStoreAmount
                },
                model: {
                  value: _vm.ruleForm.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "amount", $$v)
                  },
                  expression: "ruleForm.amount"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "冻结理由：", prop: "lockReason" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入冻结理由" },
                model: {
                  value: _vm.ruleForm.lockReason,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "lockReason",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.lockReason"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "冻结凭证：", prop: "voucherUrl" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: {
                  width: 240,
                  height: 270,
                  cover: _vm.ruleForm.voucherUrl
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "voucherUrl")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.isGoCard
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("确认\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }