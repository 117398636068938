"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regionAll = _interopRequireDefault(require("@/utils/regionAll.json"));

//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      formData: [{
        label: '下单后不支付取消时间：',
        type: 'inputNumber',
        placeholder: '请输入下单后不支付取消时间',
        value: 'noPayCancelTime',
        end: "分钟"
      }, {
        label: '自动确认收货的时间：',
        type: 'inputNumber',
        placeholder: '请输入从发货到自动确认收货的时间',
        value: 'noPayCancelTime',
        end: "天"
      }, {
        label: '可售后周期：',
        type: 'inputNumber',
        placeholder: '请输入可售后周期',
        value: 'canAfterSalesDay',
        end: "天"
      }],
      ruleForm: {
        inputNumber: undefined,
        noPayCancelTime: undefined,
        canAfterSalesDay: undefined,
        id: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/mall/configuration/detail').then(function (res) {
      _this.ruleForm = res.data;
    });
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.apiPost('/api/mall/configuration/edit', this.ruleForm).then(function (res) {
        _this2.$message({
          type: 'success',
          message: '编辑成功!'
        });
      });
    }
  }
};
exports.default = _default;