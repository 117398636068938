var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    馈赠详情\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "print_show printShowTitle" }, [
        _vm._v("\n    会员馈赠单\n  ")
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          staticClass: "print",
          staticStyle: { "margin-top": "10px" },
          attrs: { "label-width": "96px", size: "small" }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "会员名称：" } }, [
                    _c("span", { staticClass: "print_show" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.noPassByName(_vm.data.exchangeUname)) +
                          "\n\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "print_hide" }, [
                      _vm._v(_vm._s(_vm.data.exchangeUname))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c("el-form-item", { attrs: { label: "会员手机号：" } }, [
                    _c("span", { staticClass: "print_hide" }, [
                      _vm._v(_vm._s(_vm.data.exchangeUmobile))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "print_show" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.data.exchangeUmobile.replace(
                              /(\d{3})\d*(\d{4})/,
                              "$1****$2"
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "馈赠单号：" } }, [
                    _vm._v(_vm._s(_vm.data.exchangeNo))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c("el-form-item", { attrs: { label: "礼品类型：" } }, [
                    _vm._v(_vm._s(_vm.data.giftType))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-form-item", { attrs: { label: "礼品名称：" } }, [
                    _vm._v(_vm._s(_vm.data.giftName))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "馈赠数量：" } }, [
                    _vm._v(_vm._s(_vm.data.exchangeNumber))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c("el-form-item", { attrs: { label: "礼品积分：" } }, [
                    _vm._v(_vm._s(_vm.data.giftScore))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-form-item", { attrs: { label: "积分小计：" } }, [
                    _vm._v(_vm._s(_vm.data.consumeScore))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "馈赠门店：" } }, [
                    _vm._v(_vm._s(_vm.data.storeCode))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c("el-form-item", { attrs: { label: "馈赠时间：" } }, [
                    _vm._v(_vm._s(_vm.data.exchangeTime))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-form-item", { attrs: { label: "馈赠人：" } }, [
                    _vm._v(_vm._s(_vm.data.exchangeOprUname))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "馈赠备注：" } }, [
            _vm._v(_vm._s(_vm.data.exchangeRemark))
          ]),
          _vm._v(" "),
          _vm.data.refundsStatus == 2
            ? _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "退货人：" } }, [
                        _vm._v(_vm._s(_vm.data.refundsOprUname))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c("el-form-item", { attrs: { label: "退货时间：" } }, [
                        _vm._v(_vm._s(_vm.data.refundsTime))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "退货备注：" } }, [
                        _vm._v(_vm._s(_vm.data.refundsRemark))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "print_show", attrs: { gutter: 24 } },
            [_c("el-form-item", { attrs: { label: "兑换人签名：" } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.data.refundsStatus == 1
        ? _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm print_hide",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "退货备注：", prop: "refundsRemark" } },
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "400px" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 4 },
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.ruleForm.refundsRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "refundsRemark", $$v)
                      },
                      expression: "ruleForm.refundsRemark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即退货")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.goprint()
                        }
                      }
                    },
                    [_vm._v("打印馈赠单")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }