var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 3 } }, [
            _vm._v("\n      标签名称：\n    ")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.item.name,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "name", $$v)
                  },
                  expression: "item.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-col", { staticClass: "lable", attrs: { span: 3 } }, [
            _vm._v("状态:")
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-radio",
                {
                  staticClass: "lable",
                  attrs: { label: 1 },
                  model: {
                    value: _vm.item.status,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "status", $$v)
                    },
                    expression: "item.status"
                  }
                },
                [_vm._v("启用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticClass: "lable",
                  attrs: { label: -1 },
                  model: {
                    value: _vm.item.status,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "status", $$v)
                    },
                    expression: "item.status"
                  }
                },
                [_vm._v("停用")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "addlabel_value", attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("upload-excel-component", {
                attrs: {
                  "on-success": _vm.handleSuccess,
                  "before-upload": _vm.beforeUpload
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-col", { staticClass: "right", attrs: { span: 2 } }, [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "http://xl-cdn.xinglico.com/mika/statics/MIKA会员标签模板表.xlsx"
                }
              },
              [
                _c("el-button", { attrs: { type: "primary" } }, [
                  _vm._v("\n          下载Excel表格模板\n        ")
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 1 } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c("el-col", { staticClass: "lable", attrs: { span: 3 } }, [
            _vm._v("投放用户:")
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "addLabel_box", attrs: { span: 21 } }, [
            _vm.item.values.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.item.values, function(items, index) {
                    return _c("span", { key: index }, [_vm._v(_vm._s(items))])
                  }),
                  0
                )
              : _c("div")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { staticClass: "lable", attrs: { span: 3 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.next } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }