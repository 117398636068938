"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '购物卡团购',
      list: [{
        tit: '购物卡团购列表',
        txtArr: '<p>购物卡团购列表</p>',
        imgPath: require('@/assets/tab/activity/5.png'),
        path: '/group/list'
      }, {
        tit: '购物卡团购返利',
        txtArr: '<p>购物卡团购返利</p>',
        imgPath: require('@/assets/tab/activity/6.png'),
        path: '/group/rebate'
      }, {
        tit: '兑换记录',
        txtArr: '<p>兑换记录</p>',
        imgPath: require('@/assets/tab/activity/2.png'),
        path: '/group/getOrder'
      }]
    };
  }
};
exports.default = _default;