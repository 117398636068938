"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var shopCardRouter = {
  path: '/shopCard',
  component: _layout.default,
  name: 'ShopCard',
  meta: {
    title: '购物卡管理'
  },
  redirect: '/shopCard/baseTab',
  children: [// 入库管理
  {
    path: 'stockWarehousing',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/stockWarehousing'));
      });
    },
    name: 'ShopCardstockWarehousing',
    meta: {
      title: '入库管理'
    }
  }, {
    path: 'stockWarehousing/into',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/stockWarehousing/into'));
      });
    },
    name: 'ShopCardstockWarehousingInto',
    meta: {
      title: '制卡入库'
    }
  }, // 领取记录(出库)
  {
    path: 'stockOutbound',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/stockOutbound'));
      });
    },
    name: 'ShopCardStockOutbound',
    meta: {
      title: '领用记录（出库）'
    }
  }, {
    path: 'stockOutbound/collecting',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/stockOutbound/collecting'));
      });
    },
    name: 'ShopCardStockOutboundCollecting',
    meta: {
      title: '卡片领用'
    }
  }, // 出入库管理
  {
    path: 'stockTab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/stockTab'));
      });
    },
    name: 'ShopCardStockTab',
    meta: {
      title: '出入库管理'
    }
  }, // 基础信息
  {
    path: 'baseTab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/baseTab'));
      });
    },
    name: 'ShopCardBaseTab',
    meta: {
      title: '基础信息'
    }
  }, // 卡类型
  {
    path: 'type',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/type'));
      });
    },
    name: 'ShopCardType',
    meta: {
      title: '卡类型'
    }
  }, {
    path: 'type/save',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/type/form'));
      });
    },
    name: 'ShopCardTypeCU',
    meta: {
      title: '新增/编辑卡类型'
    }
  }, // 个人客户
  {
    path: 'customer',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/customer'));
      });
    },
    name: 'ShopCardCustomer',
    meta: {
      title: '个人客户'
    }
  }, {
    path: 'customer/save',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/customer/form'));
      });
    },
    name: 'ShopCardCustomerCU',
    meta: {
      title: '新增/编辑个人客户'
    }
  }, // 企业客户
  {
    path: 'enterpriseCustomer',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/enterpriseCustomer'));
      });
    },
    name: 'ShopCardEnterpriseCustomer',
    meta: {
      title: '企业客户'
    }
  }, {
    path: 'enterpriseCustomer/save',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/enterpriseCustomer/form'));
      });
    },
    name: 'ShopCardEnterpriseCustomerCU',
    meta: {
      title: '新增/编辑企业客户'
    }
  }, // 支付及返利
  {
    path: 'rebate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/rebate'));
      });
    },
    name: 'ShopCardRebate',
    meta: {
      title: '支付及返利'
    }
  }, {
    path: 'rebate/save',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/rebate/form'));
      });
    },
    name: 'ShopCardRebateCU',
    meta: {
      title: '新增/编辑支付及返利'
    }
  }, // 制卡商
  {
    path: 'maker',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/maker'));
      });
    },
    name: 'ShopCardMaker',
    meta: {
      title: '制卡商'
    }
  }, {
    path: 'maker/save',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/maker/form'));
      });
    },
    name: 'ShopCardMakerCU',
    meta: {
      title: '新增/编辑制卡商'
    }
  }, // 集团购物卡
  {
    path: 'groupCard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/groupCard'));
      });
    },
    name: 'ShopCardGroupCard',
    meta: {
      title: '集团购物卡'
    }
  }, {
    path: 'groupCard/save',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/groupCard/form'));
      });
    },
    name: 'ShopCardGroupCardCU',
    meta: {
      title: '新增/编辑卡种'
    }
  }, {
    path: 'groupCard/detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/groupCard/detail'));
      });
    },
    name: 'ShopCardGroupCardD',
    meta: {
      title: '卡种详情'
    }
  }, // 制卡计划
  {
    path: 'plan',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/plan'));
      });
    },
    name: 'ShopCardPlan',
    meta: {
      title: '制卡计划'
    }
  }, {
    path: 'plan/save',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/plan/form'));
      });
    },
    name: 'ShopCardPlanCU',
    meta: {
      title: '新增/编辑制卡计划'
    }
  }, {
    path: 'plan/detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/plan/detail'));
      });
    },
    name: 'ShopCardPlanD',
    meta: {
      title: '制卡计划详情'
    }
  }, {
    path: 'plan/storage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/plan/storage'));
      });
    },
    name: 'ShopCardPlanStorage',
    meta: {
      title: '制卡入库'
    }
  }, // 适用范围标签
  {
    path: 'applyTag',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/applyTag'));
      });
    },
    name: 'ShopCardReceiveApplyTag',
    meta: {
      title: '适用范围标签'
    }
  }, {
    path: 'applyTag/save',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/applyTag/form'));
      });
    },
    name: 'ShopCardReceiveApplyTagCU',
    meta: {
      title: '新增/编辑范围标签'
    }
  }, // 购物卡查询
  {
    path: 'query',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/query'));
      });
    },
    name: 'ShopCardQuery',
    meta: {
      title: '购物卡查询'
    }
  }, {
    path: 'query/realname',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/query/realname'));
      });
    },
    name: 'ShopCardrealname',
    meta: {
      title: '购物卡实名'
    }
  }, {
    path: 'query/updateRealname',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopCard/query/updateRealname'));
      });
    },
    name: 'ShopCardQueryupdateRealname',
    meta: {
      title: '修改实名信息'
    }
  }]
};
var _default = shopCardRouter;
exports.default = _default;