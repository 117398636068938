"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _shopCard = require("@/api/shopCard");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      requesTimes: [],
      requestParams: {
        pageNo: 1,
        pageSize: 10,
        type: 3,
        name: undefined,
        number: undefined,
        contactMobile: undefined,
        remark: undefined
      },
      total: 0,
      list: []
    };
  },
  computed: {
    _requesTimes: function _requesTimes() {
      if (this.requesTimes && this.requesTimes.length > 1) {
        return {
          startTime: this.requesTimes[0],
          endTime: this.requesTimes[1]
        };
      }

      return {};
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    onEdit: function onEdit(row) {
      this.$router.push("/shopCard/maker/save?makerId=" + row.id);
    },
    onCreate: function onCreate() {
      this.$router.push("/shopCard/maker/save");
    },
    onReset: function onReset() {
      this.$refs['requestParamsForm'].resetFields();
      this.requesTimes = [];
      this.onQuery();
    },
    onQuery: function onQuery() {
      this.requestParams.pageNo = 1;
      this.getList();
    },
    onPageChange: function onPageChange() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.requestParams), this._requesTimes);
      (0, _shopCard.customerPage)(params).then(function (res) {
        _this.list = res.data.records;
        _this.total = res.data.total;
      });
    }
  }
};
exports.default = _default;