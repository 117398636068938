"use strict";

var _interopRequireWildcard3 = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _parking = require("@/api/parking.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var R = _interopRequireWildcard3(require("ramda"));

var _map = require("../../const/map.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      searchParams: {
        pageNo: 1,
        pageSize: 10,
        plateNo: null,
        memberName: null,
        contactNumber: null,
        memberType: null,
        inTime: null,
        outTime: null,
        parkName: null
      },
      exportExcelLoading: false,
      total: 0
    };
  },
  computed: {
    columns: function columns() {
      return [{
        label: '车牌号',
        prop: 'plateNo'
      }, {
        label: '会员名称',
        prop: 'memberName'
      }, {
        label: '会员类型',
        prop: 'memberType',
        formatter: this.formatterParkMemberType
      }, {
        label: '联系电话',
        prop: 'contactNumber'
      }, {
        label: '入场时间',
        prop: 'inTime'
      }, {
        label: '离场时间',
        prop: 'outTime'
      }, {
        label: '计费',
        prop: 'totalAmount',
        formatter: this.formatterTotalAmount
      }, {
        label: '积分抵扣',
        prop: 'scoreDeductionAmount',
        formatter: this.formatterScoreDeductionAmount
      }, {
        label: '支付金额',
        prop: 'paymentAmount',
        formatter: this.formatterPaymentAmount
      }, {
        label: '停车场名称',
        prop: 'parkName'
      }];
    },
    memberTypeList: function memberTypeList() {
      return Object.keys(_map.ParkMemberType).map(function (s) {
        return {
          label: _map.ParkMemberType[s],
          value: s
        };
      });
    }
  },
  created: function created() {
    this.fetch();
  },
  methods: {
    fetch: function fetch(type) {
      var _this = this;

      if (type === 'search') {
        this.searchParams.pageNo = 1;
        this.searchParams.pageSize = 10;
      }

      if (type === 'reset') {
        this.removeObj(this.searchParams);
      }

      var _this$searchParams = this.searchParams,
          inTime = _this$searchParams.inTime,
          outTime = _this$searchParams.outTime;
      var params = R.omit(['inTime', 'outTime'], this.searchParams);

      if (inTime && inTime.length > 0) {
        params['inTimeStartTime'] = inTime[0];
        params['inTimeEndTime'] = inTime[1];
      }

      if (outTime && outTime.length > 0) {
        params['outTimeStartTime'] = outTime[0];
        params['outTimeEndTime'] = outTime[1];
      }

      (0, _parking.getPageParkLogList)(params).then(function (res) {
        _this.total = res.data.totalNum;
        _this.list = res.data.items;
      });
    },
    formatterParkMemberType: function formatterParkMemberType(row) {
      return _map.ParkMemberType[row.memberType];
    },
    formatterTotalAmount: function formatterTotalAmount(row) {
      return row.totalAmount + '元';
    },
    formatterScoreDeductionAmount: function formatterScoreDeductionAmount(row) {
      return row.scoreDeductionAmount + '元';
    },
    formatterPaymentAmount: function formatterPaymentAmount(row) {
      return row.paymentAmount + '元';
    },
    goto: function goto(url) {
      this.$router.push(url);
    },
    exportExcel: function exportExcel() {
      var _this2 = this;

      var that = this;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        that.exportExcelLoading = true;
        var headers = [];
        var filterVal = [];

        _this2.columns.forEach(function (item) {
          filterVal.push(item.prop);
          headers.push(item.label);
        });

        var _this2$searchParams = _this2.searchParams,
            inTime = _this2$searchParams.inTime,
            outTime = _this2$searchParams.outTime;
        var params = R.omit(['inTime', 'outTime', 'pageNo', 'pageSize'], _this2.searchParams);

        if (inTime && inTime.length > 0) {
          params['inTimeStartTime'] = inTime[0];
          params['inTimeEndTime'] = inTime[1];
        }

        if (outTime && outTime.length > 0) {
          params['outTimeStartTime'] = outTime[0];
          params['outTimeEndTime'] = outTime[1];
        }

        (0, _parking.getParkLogExport)(params).then(function (res) {
          var list = res.data.map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              memberType: _this2.formatterParkMemberType(v),
              totalAmount: _this2.formatterTotalAmount(v),
              scoreDeductionAmount: _this2.formatterScoreDeductionAmount(v),
              paymentAmount: _this2.formatterPaymentAmount(v)
            });
          });

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: headers,
            data: data,
            filename: '停车记录列表'
          });
        }).finally(function () {
          that.exportExcelLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, tableData) {
      return tableData.map(function (v) {
        return filterVal.map(function (j) {
          return v[j];
        });
      });
    }
  }
};
exports.default = _default;