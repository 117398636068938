"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _api = require("./api.js");

var _public = require("@/utils/public.js");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    couponPop: _radioItem.default
  },
  data: function data() {
    var _self = this;

    return {
      couponShow: false,
      popType: null,
      urlList: _selectUrl.default.urlList,
      appidArr: _selectUrl.default.appIdArr,
      urlTypeList: _selectUrl.default.urlTypeList,
      noSelect: _selectUrl.default.noSelect,
      weekList: _public.weekList,
      vlaueData: _selectUrl.default.vlaueData,
      linkUrlData: _selectUrl.default.linkUrlData,
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.validStartTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      rules: {
        'video': [{
          required: true,
          message: '请输入视频地址'
        }],
        'sort': [{
          required: true,
          message: '请输入排序'
        }],
        'validStartTime': [{
          required: true,
          message: '请选择开始时间'
        }],
        'validEndTime': [{
          required: true,
          message: '请选择结束时间'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        weekTag: [{
          required: true,
          message: '请选择周期'
        }],
        image: [{
          required: true,
          message: '请上传图片'
        }],
        linkCode: [{
          required: true,
          message: '请选择关联内容'
        }],
        hyperLink: [{
          required: true,
          message: '请选择'
        }],
        appid: [{
          required: true,
          message: '请选择小程序'
        }]
      },
      ruleForm: {
        status: 1,
        validStartTime: null,
        validEndTime: null,
        id: null,
        weekTag: 1,
        image: 'mika/pro/2022-01-19/65a363ca6098418185b9e17e7d0e4e9a.png',
        hyperLink: null,
        linkCode: null,
        sort: null,
        appid: null,
        video: null
      }
    };
  },
  computed: {
    linkCodeLIst: function linkCodeLIst() {
      return this.urlList.filter(function (item) {
        return !item.unOther;
      });
    }
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id) {
      (0, _api.getData)({
        id: this.ruleForm.id
      }).then(function (res) {
        if (_this.urlTypeList.includes(res.data.linkCode)) {
          res.data.hyperLink = _this.getURLParameters(res.data.hyperLink).id;
        }

        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    typeChange: function typeChange() {
      this.ruleForm.hyperLink = '';
    },
    getLinkId: function getLinkId() {
      this.popType = this.ruleForm.linkCode;
      this.$refs.coupon1.getList(this.popType, this.ruleForm.hyperLink);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      this.ruleForm.hyperLink = e[this.vlaueData[this.popType]];
      this.couponShow = false;
    },
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.ruleForm));

          if (_this2.urlTypeList.includes(_this2.ruleForm.linkCode)) {
            data.hyperLink = _this2.linkUrlData[_this2.ruleForm.linkCode].url + data.hyperLink;
          } else if (_this2.ruleForm.linkCode) {
            data.hyperLink = _this2.ruleForm.linkCode;
          }

          (0, _api.addData)(data).then(function (res) {
            _this2.$router.go(-1);

            _this2.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;