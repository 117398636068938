var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("用户资源")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            { attrs: { label: "对应角色：" } },
            _vm._l(_vm.shouRole, function(item, index) {
              return _c(
                "el-button",
                {
                  key: index,
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      return _vm.getRoleDea(item)
                    }
                  }
                },
                [_vm._v("\n         " + _vm._s(item.roleName) + "\n       ")]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "对应菜单：" } },
            [
              _c("el-tree", {
                staticClass: "li_tree",
                attrs: {
                  data: _vm.selectMenus,
                  props: _vm.defaultProps,
                  "default-expanded-keys": _vm.expanded,
                  "node-key": "menuId"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }