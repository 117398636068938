"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "externalCodeList",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      open: false,
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        couponId: undefined
      },
      downloadLoading: false
    };
  },
  filters: {},
  mounted: function mounted() {},
  methods: {
    handleExcelDownload: function handleExcelDownload() {
      var _this = this;

      this.downloadLoading = true;
      var titles = ["序号", "外部码", "外部码密码", "销售状态", "核销状态"];
      var names = ["id", "receivedCode", "receivedPwd", "receivedStatus", "couponStatus"];
      (0, _coupon.couponRecodesPage)(this.queryParams).then(function (res) {
        var list = res.data.items;
        list.forEach(function (item) {
          item.receivedStatus = _this.getReceivedStatusLabel(item);
          item.couponStatus = _this.getCouponStatusLabel(item);
        });

        _this.getHandleDownload(titles, names, list, '外部券码列表');

        _this.downloadLoading = false;
      });
    },
    handleManualWrite: function handleManualWrite(row) {
      var _this2 = this;

      if (row.receivedStatus === 1 && row.couponStatus === 'received') {
        this.$confirm('此操作手动核销外部券码， 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this2.openLoading('正在核销...');

          (0, _coupon.externalCouponWriteOff)({
            id: row.id
          }).then(function () {
            row.couponStatus = "used";
          });

          _this2.openLoading().close();
        }).catch(function () {});
      }
    },
    getCouponStatusLabel: function getCouponStatusLabel(row) {
      if (row.receivedStatus === -1 || row.couponStatus === "received") {
        return "未核销";
      } else if (row.couponStatus === "used") {
        return "已核销";
      } else if (row.couponStatus === "expired") {
        return "已过期";
      } else {
        return "";
      }
    },
    getReceivedStatusLabel: function getReceivedStatusLabel(row) {
      if (row.receivedStatus === -1) {
        return "未售";
      } else if (row.receivedStatus === 1) {
        return "已售";
      } else {
        return "";
      }
    },
    handleSizeChange: function handleSizeChange(size) {
      this.queryParams.pageSize = size;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(no) {
      this.queryParams.pageNo = no;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;

      this.loading = true;
      (0, _coupon.couponRecodesPage)(this.queryParams).then(function (res) {
        _this3.list = res.data.items;
        _this3.total = res.data.totalNum;
        _this3.loading = false;
      });
    }
  }
};
exports.default = _default;