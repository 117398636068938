"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("../api.js");

var _member = require("@/api/member.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      vipList: [],
      columns: [{
        label: '券编码',
        prop: 'couponCode',
        width: 100
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 100
      }, {
        label: '券类型',
        prop: 'couponType',
        width: 100
      }, {
        label: '面额/折扣',
        prop: 'disdenStr',
        width: 100
      }, {
        label: '有效期',
        prop: 'validStr',
        width: 100
      }],
      rules: {
        classStatus: [{
          required: true,
          message: '请选择分类状态'
        }],
        vipCode: [{
          required: true,
          message: '请选择会员类型'
        }],
        className: [{
          required: true,
          message: '请输入名称'
        }],
        classSort: [{
          required: true,
          message: '请输入排序'
        }]
      },
      ruleForm: {
        id: null,
        vipCode: null,
        className: null,
        classSort: null,
        classStatus: null
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _member.listVip)().then(function (res) {
      console.log(res);
      _this.vipList = res.data;
    });

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _api.getSubsidyClass)(this.ruleForm.id).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (key) {
          _this.ruleForm[key] = res.data[key];
        });
      });
    }
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this2.ruleForm.id) {
            (0, _api.editSubsidyClass)(_this2.ruleForm).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addSubsidyClass)(_this2.ruleForm).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;