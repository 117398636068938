"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addExchange = addExchange;
exports.addGift = addGift;
exports.addGifts = addGifts;
exports.changeMobile = changeMobile;
exports.closeSVipAuth = closeSVipAuth;
exports.crmRegBigUser = crmRegBigUser;
exports.crmRegUser = crmRegUser;
exports.editExchange = editExchange;
exports.editRebateStatus365 = editRebateStatus365;
exports.editStockNumber = editStockNumber;
exports.getExchangeById = getExchangeById;
exports.getGiftById = getGiftById;
exports.getSetting = getSetting;
exports.informationRewardLogPage = informationRewardLogPage;
exports.listByParam = listByParam;
exports.listCancel = listCancel;
exports.listExchangePage = listExchangePage;
exports.listExchangeReportPage = listExchangeReportPage;
exports.listGiftLogPage = listGiftLogPage;
exports.listGiftPage = listGiftPage;
exports.listMainUserPage = listMainUserPage;
exports.listOrderHomePage = listOrderHomePage;
exports.listPopularizeUser = listPopularizeUser;
exports.listType = listType;
exports.listUserChangeLogPage = listUserChangeLogPage;
exports.listVip = listVip;
exports.memberCancel = memberCancel;
exports.memberMerge = memberMerge;
exports.selectInformationReward = selectInformationReward;
exports.setting = setting;
exports.updateExpressStatus = updateExpressStatus;
exports.updeateInformationReward = updeateInformationReward;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
1、礼品分页：/api/gift/listGiftPage
2、新增礼品：/api/gift/addGift
      入参：{"giftType":"礼品类型(1：生日礼 2：升级礼 3：积兑卡 4：其他)",
      "giftName":"礼品名称"
      ,"giftWorth":"礼品价值",
      "companyCode","公司编码",
      "storeCode":"门店编码",
      "purchasingPrice":"进货价格",
      "stockNumber":"库存数量",
      "scorePrice":"积分价格"}

1、礼品兑换分页：/api/giftExchange/listExchangePage
{"exchangeUmobile":"会员手机号","pageNo":"当前页","pageSize":"页条数"}

2、新增礼品兑换：/api/giftExchange/addExchange
{"exchangeUmobile":"兑换人手机号","gifts":[{"giftId":"礼品id","exchangeNumber":"兑换数量"}]}

会员注册：/api/mainUser/crmRegUser
{"userName":"会员姓名",
"userMobile":"会员手机号",
"userIdNo":"会员身份证号",
"cardNo":"卡号",
"mid":"卡介质",
"mkStoreId":"制卡机构"}
"userSex":"会员性别(0：未知、1：男、2：女)",

   <!-- 更改手机号日志：/api/user/change/log/listByParam
    {"newMobile":"手机号"} -->
 */
function listPopularizeUser(data) {
  return (0, _request.default)({
    url: '/api/sysUserKpi/listPopularizeUser',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function closeSVipAuth(data) {
  return (0, _request.default)({
    url: '/api/mainUser/closeSVipAuth',
    method: 'get',
    loading: 'loading',
    params: data
  });
}
/*

/* 编辑365是否可折扣状态：/api/mainUser/editRebateStatus365/{userId} */


function editRebateStatus365(data) {
  return (0, _request.default)({
    url: '/api/mainUser/editRebateStatus365/' + data,
    method: 'post',
    loading: 'loading'
  });
}
/*
所有会员类型
 */


function listVip(data) {
  return (0, _request.default)({
    url: '/api/vipSet/listVip',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
礼品分类：/api/gift/listType
 */


function listType(data) {
  return (0, _request.default)({
    url: '/api/gift/listType',
    method: 'post',
    data: data
  });
}

function getSetting(data) {
  return (0, _request.default)({
    url: '/api/mainUser/getSetting',
    method: 'post',
    data: data
  });
}

function setting(data) {
  return (0, _request.default)({
    url: '/api/mainUser/setting',
    method: 'post',
    data: data
  });
}

function listUserChangeLogPage(data) {
  return (0, _request.default)({
    url: '/api/user/change/log/listUserChangeLogPage',
    method: 'post',
    data: data
  });
}

function listByParam(data) {
  return (0, _request.default)({
    url: '/api/user/change/log/listByParam',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
修改手机号

 */


function changeMobile(data) {
  return (0, _request.default)({
    url: '/api/mainUser/changeMobile',
    method: 'post',
    data: data
  });
}
/*
 会员注销分页：/api/mainUser/listCancel
 会员注销：/api/mainUser/cancel
 {"userId":"用户id","dingtalkNo":"钉钉工单号"}
 会员合并：/api/mainUser/merge
 {"userId":"用户id","dingtalkNo":"钉钉工单号","mergeUmobile":"合并手机号"}
 */


function listCancel(data) {
  return (0, _request.default)({
    url: '/api/mainUser/listCancel',
    method: 'post',
    data: data
  });
}

function memberCancel(data) {
  return (0, _request.default)({
    url: '/api/mainUser/cancel',
    method: 'post',
    data: data
  });
}

function memberMerge(data) {
  return (0, _request.default)({
    url: '/api/mainUser/merge',
    method: 'post',
    data: data
  });
}
/* 礼品到家列表
 */


function listOrderHomePage(data) {
  return (0, _request.default)({
    url: '/api/orderTohome/listOrderHomePage',
    method: 'post',
    data: data
  });
}
/*
 api/orderTohome/updateExpressStatus 提交发货快递信息 orderId，expressNo（运单号），expressName（快递公司）

 */


function updateExpressStatus(data) {
  return (0, _request.default)({
    url: '/api/orderTohome/updateExpressStatus',
    method: 'post',
    data: data
  });
}
/*

新增会员列表
 */


function listMainUserPage(data) {
  var loading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'loading';
  return (0, _request.default)({
    url: '/api/mainUser/listMainUserPage',
    method: 'post',
    data: data,
    loading: loading
  });
}
/*
 会员注册  普通客户
 */


function crmRegUser(data) {
  return (0, _request.default)({
    url: '/api/mainUser/crmRegUser',
    method: 'post',
    data: data
  });
}
/*
 会员注册  大客户
 */


function crmRegBigUser(data) {
  return (0, _request.default)({
    url: '/api/mainUser/crmRegBigUser',
    method: 'post',
    data: data
  });
}
/*
 礼品分页
 */


function listGiftPage(data) {
  return (0, _request.default)({
    url: '/api/gift/listGiftPage',
    method: 'post',
    data: data
  });
}
/*
 新增礼品
 */


function addGift(data) {
  return (0, _request.default)({
    url: '/api/gift/addGift',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*

批量导入礼品
 */


function addGifts(data) {
  console.log(JSON.stringify(data));
  return (0, _request.default)({
    url: '/api/gift/addGifts',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
礼品编辑库存数量
 */


function editStockNumber(data) {
  return (0, _request.default)({
    url: '/api/gift/editStockNumber',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
礼品详情
 */


function getGiftById(id) {
  return (0, _request.default)({
    url: '/api/gift/getGiftById/' + id,
    method: 'post'
  });
}

function listGiftLogPage(data) {
  return (0, _request.default)({
    url: '/api/gift/listGiftLogPage',
    method: 'post',
    data: data
  });
}
/*

明细：/api/giftExchange/getExchangeById/{id}
 */


function getExchangeById(id) {
  return (0, _request.default)({
    url: '/api/giftExchange/getExchangeById/' + id,
    method: 'post'
  });
}
/* 礼品退货：api/giftExchange/editExchange
{"id":"id","":"refundsRemark":"退货备注"} */

/*
 礼品退货
 */


function editExchange(data) {
  return (0, _request.default)({
    url: '/api/giftExchange/editExchange',
    method: 'post',
    data: data
  });
}
/*
 礼品兑换分页

 */


function listExchangePage(data) {
  return (0, _request.default)({
    url: '/api/giftExchange/listExchangePage ',
    method: 'post',
    data: data
  });
}

function addExchange(data) {
  return (0, _request.default)({
    url: '/api/giftExchange/addExchange ',
    method: 'post',
    data: data
  });
}

function listExchangeReportPage(data) {
  return (0, _request.default)({
    url: '/api/giftExchange/listExchangeReportPage',
    method: 'post',
    data: data
  });
}

function informationRewardLogPage(data) {
  return (0, _request.default)({
    url: '/api/informationReward/informationRewardLogPage',
    method: 'post',
    data: data
  });
}

function selectInformationReward(data) {
  return (0, _request.default)({
    url: '/api/informationReward/selectInformationReward',
    method: 'post',
    data: data
  });
}

function updeateInformationReward(data) {
  return (0, _request.default)({
    url: '/api/informationReward/updeateInformationReward',
    method: 'post',
    data: data
  });
}