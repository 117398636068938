var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("荔星礼品列表 "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "giftContent",
              label: "领取礼品",
              align: "center",
              "min-width": "150"
            },
            slot: "giftContent",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.giftContent, function(item, index) {
                    return _c("p", { key: index }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(item.giftName) +
                          "(" +
                          _vm._s(item.giftCode) +
                          ")\n        "
                      )
                    ])
                  })
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }