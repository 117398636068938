"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var setRouter = {
  path: '/setting',
  component: _layout.default,
  redirect: '/setting/terminalVersions',
  name: 'Setting',
  meta: {
    title: '设置',
    icon: 'nested'
  },
  children: [{
    path: 'terminalVersions',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/terminalVersions'));
      });
    },
    name: 'TerminalVersions',
    meta: {
      title: '终端设备版本号 '
    }
  }, {
    path: 'editorVersions',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/editorVersions'));
      });
    },
    name: 'EditorVersions',
    meta: {
      title: '编辑终端设备版本号 ',
      activeMenu: '/setting/terminalVersions'
    }
  }]
};
var _default = setRouter;
exports.default = _default;