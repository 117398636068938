var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    " + _vm._s(_vm.$route.meta.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "raffleForm",
          attrs: {
            model: _vm.raffleForm,
            rules: _vm.raffleRules,
            "label-width": "180px",
            size: "small"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "activityName", label: "活动名称：" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "输入活动名称" },
                model: {
                  value: _vm.raffleForm.activityName,
                  callback: function($$v) {
                    _vm.$set(_vm.raffleForm, "activityName", $$v)
                  },
                  expression: "raffleForm.activityName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "gameType", label: "抽奖类型：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择抽奖类型" },
                  model: {
                    value: _vm.raffleForm.gameType,
                    callback: function($$v) {
                      _vm.$set(_vm.raffleForm, "gameType", $$v)
                    },
                    expression: "raffleForm.gameType"
                  }
                },
                _vm._l(_vm.raffleTypeOptions, function(raffleType) {
                  return _c("el-option", {
                    key: raffleType.label,
                    attrs: { value: raffleType.value, label: raffleType.label }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "prizeType", label: "抽奖形式：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.raffleForm.prizeType,
                    callback: function($$v) {
                      _vm.$set(_vm.raffleForm, "prizeType", $$v)
                    },
                    expression: "raffleForm.prizeType"
                  }
                },
                _vm._l(_vm.raffleShapeOptions, function(shape) {
                  return _c(
                    "el-radio",
                    { key: shape.value, attrs: { label: shape.value } },
                    [_vm._v(_vm._s(shape.label))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "prizeNum", label: "抽奖次数：" } },
            [
              _c("el-input-number", {
                attrs: { controls: false, placeholder: "抽奖次数" },
                model: {
                  value: _vm.raffleForm.prizeNum,
                  callback: function($$v) {
                    _vm.$set(_vm.raffleForm, "prizeNum", $$v)
                  },
                  expression: "raffleForm.prizeNum"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "times", label: "活动时间：" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  clearable: ""
                },
                model: {
                  value: _vm.raffleForm.times,
                  callback: function($$v) {
                    _vm.$set(_vm.raffleForm, "times", $$v)
                  },
                  expression: "raffleForm.times"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "useIntegral", label: "单次消耗积分：" } },
            [
              _c("el-input-number", {
                attrs: { controls: false, placeholder: "单次消耗积分" },
                model: {
                  value: _vm.raffleForm.useIntegral,
                  callback: function($$v) {
                    _vm.$set(_vm.raffleForm, "useIntegral", $$v)
                  },
                  expression: "raffleForm.useIntegral"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "organizationId", label: "主办机构：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择主办机构" },
                  model: {
                    value: _vm.raffleForm.organizationId,
                    callback: function($$v) {
                      _vm.$set(_vm.raffleForm, "organizationId", $$v)
                    },
                    expression: "raffleForm.organizationId"
                  }
                },
                _vm._l(_vm.raffleTypeOptions, function(raffleType) {
                  return _c("el-option", {
                    key: raffleType.label,
                    attrs: { value: raffleType.value, label: raffleType.label }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "backgroundImage", label: "活动背景：" } },
            [
              _c("upload-image", {
                model: {
                  value: _vm.raffleForm.backgroundImage,
                  callback: function($$v) {
                    _vm.$set(_vm.raffleForm, "backgroundImage", $$v)
                  },
                  expression: "raffleForm.backgroundImage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "backgroundColor", label: "背景色：" } },
            [
              _c("el-color-picker", {
                model: {
                  value: _vm.raffleForm.backgroundColor,
                  callback: function($$v) {
                    _vm.$set(_vm.raffleForm, "backgroundColor", $$v)
                  },
                  expression: "raffleForm.backgroundColor"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "ruleText", label: "规则说明：" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.raffleForm.ruleText,
                  callback: function($$v) {
                    _vm.$set(_vm.raffleForm, "ruleText", $$v)
                  },
                  expression: "raffleForm.ruleText"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "group" }, [
            _c(
              "div",
              { staticClass: "group-title" },
              [
                _c("span", [_vm._v("奖励设置")]),
                _vm._v(" "),
                _c("el-button", [_vm._v("添加奖励")])
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }