var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth" }, [
    _c("div", { staticClass: "group" }, [
      _c("div", { staticClass: "group-title" }, [_vm._v("卡片信息")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "info-list" },
        _vm._l(_vm.infoOption, function(info, infoIndex) {
          return _c("div", { key: infoIndex, staticClass: "flex info-item" }, [
            _c("div", { staticClass: "lab" }, [
              _vm._v(_vm._s(info.title) + "：")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "val" }, [
              _vm._v(
                _vm._s(info.val ? info.val : info.default ? info.default : "")
              )
            ])
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _vm.isAuth && !_vm.isFinished
      ? _c(
          "div",
          { staticClass: "group" },
          [
            _c("div", { staticClass: "group-title" }, [_vm._v("身份验证")]),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "form",
                attrs: {
                  model: _vm.authParams,
                  rules: _vm.authRules,
                  "label-width": "180px",
                  size: "small"
                }
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "input-width",
                    attrs: { label: "认证方式", prop: "name" }
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.authParams.method,
                          callback: function($$v) {
                            _vm.$set(_vm.authParams, "method", $$v)
                          },
                          expression: "authParams.method"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("手机号+验证码")
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("实名信息认证")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.authParams.method === 1
                  ? [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "input-width",
                          attrs: { label: "实名手机号", prop: "name" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.authParams.name,
                              callback: function($$v) {
                                _vm.$set(_vm.authParams, "name", $$v)
                              },
                              expression: "authParams.name"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.sendSmscode }
                            },
                            [
                              _vm.num >= 120
                                ? _c("span", [_vm._v("发送验证码")])
                                : _c("span", [_vm._v(_vm._s(_vm.num) + "s")])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.authParams.method === 2
                  ? [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "input-width",
                          attrs: { label: "真实姓名", prop: "name" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.authParams.name,
                              callback: function($$v) {
                                _vm.$set(_vm.authParams, "name", $$v)
                              },
                              expression: "authParams.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "input-width",
                          attrs: { label: "身份证号", prop: "name" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.authParams.name,
                              callback: function($$v) {
                                _vm.$set(_vm.authParams, "name", $$v)
                              },
                              expression: "authParams.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "input-width",
                          attrs: { label: "实名手机号", prop: "name" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.authParams.name,
                              callback: function($$v) {
                                _vm.$set(_vm.authParams, "name", $$v)
                              },
                              expression: "authParams.name"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "input-width",
                    attrs: { label: "备注", prop: "remark" }
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", autosize: { minRows: 3 } },
                      model: {
                        value: _vm.authParams.remark,
                        callback: function($$v) {
                          _vm.$set(_vm.authParams, "remark", $$v)
                        },
                        expression: "authParams.remark"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "actions" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onFinished }
                      },
                      [_vm._v(_vm._s(_vm.confirmBtn))]
                    ),
                    _vm._v(" "),
                    _c("el-button", [_vm._v("取消")])
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }