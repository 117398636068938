var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("新增编辑商户")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { padding: "20px 0 0 0" },
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            size: "mini",
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "第三方ID：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%", "max-width": "300px" },
                        attrs: { type: "number", autocomplete: "off" },
                        model: {
                          value: _vm.form.outId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "outId", $$v)
                          },
                          expression: "form.outId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户编码：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%", "max-width": "300px" },
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.merchantCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "merchantCode", $$v)
                          },
                          expression: "form.merchantCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "companyId",
                        label: "所属总部：",
                        required: ""
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%", "max-width": "300px" },
                          attrs: { autocomplete: "off" },
                          on: { change: _vm.companyIdChange },
                          model: {
                            value: _vm.form.companyId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "companyId", $$v)
                            },
                            expression: "form.companyId"
                          }
                        },
                        _vm._l(_vm.companyIdList, function(item, index) {
                          return _c(
                            "el-option",
                            {
                              key: index,
                              attrs: {
                                label: item.shortName,
                                value: item.companyId
                              }
                            },
                            [
                              _vm._v(
                                "\n               " +
                                  _vm._s(
                                    item.shortName +
                                      "(" +
                                      item.companyCode +
                                      ")"
                                  ) +
                                  "\n             "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "storeId",
                        label: "所属门店：",
                        required: ""
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%", "max-width": "300px" },
                          attrs: { autocomplete: "off" },
                          on: { change: _vm.setLonOrLat },
                          model: {
                            value: _vm.form.storeId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "storeId", $$v)
                            },
                            expression: "form.storeId"
                          }
                        },
                        _vm._l(_vm.storeList, function(item, index) {
                          return _c(
                            "el-option",
                            {
                              key: index,
                              attrs: {
                                label: item.shortName,
                                value: item.storeId
                              }
                            },
                            [
                              _vm._v(
                                "\n               " +
                                  _vm._s(
                                    item.shortName + "(" + item.storeCode + ")"
                                  ) +
                                  "\n             "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "fullName",
                        label: "商户全称：",
                        required: ""
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%", "max-width": "300px" },
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.fullName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "fullName", $$v)
                          },
                          expression: "form.fullName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "shortName",
                        label: "商户简称：",
                        required: ""
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%", "max-width": "300px" },
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.shortName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "shortName", $$v)
                          },
                          expression: "form.shortName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "门店地址：", required: "" } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "state", required: "" } },
                    [
                      _c("el-cascader", {
                        ref: "state",
                        staticStyle: { width: "100%", "max-width": "300px" },
                        attrs: { options: _vm.regionData, clearable: "" },
                        on: { change: _vm.regionDataChange },
                        model: {
                          value: _vm.form.state,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "state", $$v)
                          },
                          expression: "form.state"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { "margin-left": "10px" }, attrs: { span: 11 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "detailAddress", required: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%", "max-width": "500px" },
                        attrs: {
                          placeholder: "请输入详细地址",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.form.detailAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "detailAddress", $$v)
                          },
                          expression: "form.detailAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "floorId",
                        label: "所在楼层：",
                        disabled: !_vm.form.storeId
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%", "max-width": "300px" },
                          attrs: {
                            clearable: "",
                            placeholder: "选择门店后再选择所在楼层"
                          },
                          model: {
                            value: _vm.form.floorId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "floorId", $$v)
                            },
                            expression: "form.floorId"
                          }
                        },
                        _vm._l(_vm.floorOptions, function(floor) {
                          return _c("el-option", {
                            key: floor.id,
                            attrs: { label: floor.floor, value: floor.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "positionNum", label: "铺位号：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%", "max-width": "300px" },
                        attrs: { placeholder: "请输入铺位号" },
                        model: {
                          value: _vm.form.positionNum,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "positionNum", $$v)
                          },
                          expression: "form.positionNum"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商户描述：", prop: "merchantDesc" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "500px" },
                attrs: {
                  type: "textarea",
                  rows: 2,
                  placeholder: "请输入商户描述",
                  autocomplete: "off"
                },
                model: {
                  value: _vm.form.merchantDesc,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "merchantDesc", $$v)
                  },
                  expression: "form.merchantDesc"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "参与活动：", prop: "activityStatuss" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.activityStatuss,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "activityStatuss", $$v)
                    },
                    expression: "form.activityStatuss"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: 1 } }, [_vm._v("全部")]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: 2 } }, [
                    _vm._v("365会员折扣")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "longOrLat",
                        label: "地理位置：",
                        required: ""
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "300px" },
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.longOrLat,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "longOrLat", $$v)
                          },
                          expression: "form.longOrLat"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticStyle: { "font-size": "12px", color: "#666666" }
                        },
                        [
                          _vm._v(
                            "\n             经度纬度，请使用英文的逗号隔开，如：105.8888888,26.88888888\n           "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "merchantStatus",
                        label: "商户状态：",
                        required: ""
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { "max-width": "300px", width: "100%" },
                          attrs: {
                            placeholder: "商户状态",
                            autocomplete: "off"
                          },
                          model: {
                            value: _vm.form.merchantStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "merchantStatus", $$v)
                            },
                            expression: "form.merchantStatus"
                          }
                        },
                        _vm._l(_vm.storeStatusList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "商户LOGO：" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: {
                  width: 100,
                  height: 100,
                  cover: _vm.form.merchantLogo
                },
                on: { setCover: _vm.setCover }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "关联页面编码：" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "300px" },
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.linkPage,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "linkPage", $$v)
                  },
                  expression: "form.linkPage"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.getPageCode } },
                [_vm._v("选择页面")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v(
                  "\n         配置后小程序门店品牌页面跳转到指定模板页面，不配置不跳转\n       "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticStyle: {
                padding: "20px 0",
                "border-bottom": "1px solid #eeeeee",
                "border-top": "1px solid #eeeeee"
              },
              attrs: { gutter: 20 }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "contactPeople",
                        label: "商户负责人：",
                        required: ""
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "300px", width: "100%" },
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.contactPeople,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "contactPeople", $$v)
                          },
                          expression: "form.contactPeople"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        prop: "contactPhone",
                        label: "商户电话：",
                        required: ""
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "300px", width: "100%" },
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.contactPhone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "contactPhone", _vm._n($$v))
                          },
                          expression: "form.contactPhone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "chat_top" }, [
            _c("div", { staticClass: "top_lable" }, [_vm._v("供应商信息")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "top_edit",
                staticStyle: {
                  flex: "1",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "flex-end"
                }
              },
              [
                _c("el-input", {
                  staticStyle: { flex: "1", "max-width": "600px" },
                  attrs: { placeholder: "供应商编码,多个用逗号隔开" },
                  model: {
                    value: _vm.c_provider,
                    callback: function($$v) {
                      _vm.c_provider = $$v
                    },
                    expression: "c_provider"
                  }
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "0 10px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.seachArticle }
                  },
                  [_vm._v("查询")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        _vm.suppliersShow = true
                      }
                    }
                  },
                  [_vm._v("编辑")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              staticStyle: {
                "padding-bottom": "20px",
                "border-bottom": "1px solid #eeeeee"
              },
              attrs: { label: "" }
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    size: "mini",
                    data: _vm.form.suppliers,
                    border: "",
                    "max-height": "500",
                    "header-cell-style": {
                      background: "#f5f5f5",
                      height: "20px",
                      padding: "0px 0"
                    }
                  }
                },
                [
                  _vm._l(_vm.tabTh, function(item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: item.lable,
                        prop: item.prop,
                        align: item.align ? "left" : "center",
                        "min-width": item.width
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "有效期",
                      align: "center",
                      "min-width": "2"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n             " +
                                _vm._s(
                                  scope.row.contractStartTime +
                                    "至" +
                                    scope.row.contractEndTime
                                ) +
                                "\n           "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否有效",
                      prop: "contractStatus",
                      align: "center",
                      "min-width": "1"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品编码",
                      prop: "goodsCode",
                      "min-width": "3"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "max-height": "100px",
                                  overflow: "auto"
                                }
                              },
                              [
                                _vm._v(
                                  "\n               " +
                                    _vm._s(scope.row.goodsCode.join("、")) +
                                    "\n             "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteSuppliers(scope)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商户人员：" } },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.goAddUser }
                    },
                    [_vm._v("添加人员")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.goAddWxUser }
                    },
                    [_vm._v("添加企业微信人员")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { "max-width": "822px" },
                  attrs: {
                    data: _vm.form.outDeptUsers,
                    size: "mini",
                    "max-height": "800px",
                    border: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      align: "center",
                      width: "100"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "姓名", align: "center", width: "160" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                ref: "outDeptUsers." + scope.$index + ".name",
                                attrs: {
                                  prop:
                                    "outDeptUsers." + scope.$index + ".name",
                                  rules: _vm.rules.userName
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "姓名" },
                                  model: {
                                    value: scope.row.name,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "name",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "scope.row.name"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "手机号码", align: "center", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                ref: "outDeptUsers." + scope.$index + ".mobile",
                                attrs: {
                                  prop:
                                    "outDeptUsers." + scope.$index + ".mobile",
                                  rules: _vm.rules.userMobile
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "手机号码" },
                                  model: {
                                    value: scope.row.mobile,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "mobile",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "scope.row.mobile"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "头像", align: "center", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                ref:
                                  "outDeptUsers." +
                                  scope.$index +
                                  ".thumbAvatar",
                                attrs: {
                                  prop:
                                    "outDeptUsers." +
                                    scope.$index +
                                    ".thumbAvatar",
                                  rules: _vm.rules.thumbAvatar
                                }
                              },
                              [
                                _c("add-load", {
                                  staticClass: "img_list",
                                  attrs: {
                                    width: 100,
                                    height: 100,
                                    cover: scope.row.thumbAvatar
                                  },
                                  on: {
                                    setCover: function($event) {
                                      return _vm.setUserCover(
                                        $event,
                                        scope.$index,
                                        "thumbAvatar"
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "二维码", align: "center", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                ref:
                                  "outDeptUsers." + scope.$index + ".lockImage",
                                attrs: {
                                  prop:
                                    "outDeptUsers." + scope.$index + ".qrCode",
                                  rules: _vm.rules.qrCode
                                }
                              },
                              [
                                _c("add-load", {
                                  staticClass: "img_list",
                                  attrs: {
                                    width: 100,
                                    height: 100,
                                    cover: scope.row.qrCode
                                  },
                                  on: {
                                    setCover: function($event) {
                                      return _vm.setUserCover(
                                        $event,
                                        scope.$index,
                                        "qrCode"
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认删除此该用户？" },
                                on: {
                                  confirm: function($event) {
                                    return _vm.delGrade(scope.row, scope.$index)
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "danger",
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "mini"
                                    },
                                    slot: "reference"
                                  },
                                  [
                                    _vm._v(
                                      "\n                 删除\n               "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("weChat", {
            ref: "chatRef",
            attrs: { form: _vm.form },
            on: { changeVendorUser: _vm.changeVendorUser, submit: _vm.submit }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-left": "120px" },
          attrs: { disabled: _vm.disabled, type: "primary" },
          on: {
            click: function($event) {
              return _vm.submitForm("form")
            }
          }
        },
        [_vm._v("保存")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.suppliersShow,
              expression: "suppliersShow"
            }
          ],
          staticClass: "pop"
        },
        [
          _c(
            "div",
            { staticClass: "pop_box" },
            [
              _c("div", { staticClass: "pop_tab" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-circle-close",
                  on: {
                    click: function($event) {
                      _vm.suppliersShow = false
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  attrs: {
                    size: "mini",
                    data: _vm.suppliers,
                    border: "",
                    "max-height": "450"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "ERP供应商编码",
                      align: "center",
                      "min-width": "2"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.supplierCode,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "supplierCode", $$v)
                                },
                                expression: "scope.row.supplierCode"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "ERP供应商名称",
                      align: "center",
                      "min-width": "4"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.supplierName,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "supplierName", $$v)
                                },
                                expression: "scope.row.supplierName"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "合同编号",
                      align: "center",
                      "min-width": "2"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.contractNo,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "contractNo", $$v)
                                },
                                expression: "scope.row.contractNo"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "有效期",
                      align: "center",
                      "min-width": "4"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "mini",
                                "value-format": "yyyy-MM-dd",
                                type: "daterange",
                                "range-separator": "-",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期"
                              },
                              model: {
                                value: scope.row.time,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "time", $$v)
                                },
                                expression: "scope.row.time"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品码",
                      align: "center",
                      "min-width": "3"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: {
                                size: "mini",
                                type: "textarea",
                                autosize: { minRows: 1, maxRows: 8 }
                              },
                              model: {
                                value: scope.row.goodsCode,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "goodsCode", $$v)
                                },
                                expression: "scope.row.goodsCode"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pop_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.addSuppliers }
                    },
                    [_vm._v("添加")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.saveSuppliers }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("couponPop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: "tem", "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("*")]), _vm._v("多个商品码用回车分开")])
  }
]
render._withStripped = true

export { render, staticRenderFns }