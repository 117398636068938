"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _set = require("@/api/set");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    AppLink: _Link.default
  },
  data: function data() {
    return {
      obj: {
        pageNo: 1,
        pageSize: 10,
        versionStatu: 0,
        versionNo: ''
      },
      total: 0,
      btn: '编辑',
      tableData: [],
      options: [{
        value: 0,
        label: '全部状态'
      }, {
        value: 1,
        label: '启用'
      }, {
        value: -1,
        label: '停用'
      }]
    };
  },
  destroyed: function destroyed() {
    this.$store.dispatch('buffer/setVersionsList', this.obj);
  },
  created: function created() {
    if (this.$store.state.buffer.versionsList.pageNo) {
      this.obj = this.$store.state.buffer.versionsList;
    }

    this.init();
  },
  methods: {
    goAdd: function goAdd() {
      this.$store.dispatch('editor/setVersionsEditor', '');
      this.$router.push('/setting/editorVersions');
    },
    setBtn: function setBtn(row) {
      this.$router.push('/setting/editorVersions?versionId=' + row.versionId);
    },
    remove: function remove() {
      this.obj.pageNo = 1;
      this.obj.pageSize = 10;
      this.obj.versionStatu = 0;
      this.obj.versionNo = '';
      this.init();
    },
    init: function init(e) {
      var _this = this;

      if (e) {
        this.obj.pageNo = e.page;
        this.obj.pageSize = e.limit;
      }

      this.openLoading();
      (0, _set.listVersionPage)(this.obj).then(function (res) {
        _this.tableData = res.data.records;
        _this.total = res.data.total;

        _this.tableData.forEach(function (item) {
          switch (item.versionStatu) {
            case 1:
              item.versionStatuTxt = '启用';
              break;

            default:
              item.versionStatuTxt = '停用';
              break;
          }
        });

        _this.openLoading().close();
      }).catch(function (res) {
        _this.openLoading().close();
      });
    }
  }
};
exports.default = _default;