var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("w-table", {
        ref: "wTable",
        attrs: {
          size: "mini",
          pageApi: "/api/dis365ExchangeScore/listDis365ExchangeScorePage",
          "search-data": _vm.searchData,
          columns: _vm.columns
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }