var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add" }, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("新增会员体系")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "new1_input" },
      [
        _c("ul", [
          _c(
            "li",
            [
              _c("span", { staticClass: "list_tit" }, [
                _vm._v("\n            体系名称：\n          ")
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "请输入", clearable: "" },
                model: {
                  value: _vm.sysName,
                  callback: function($$v) {
                    _vm.sysName = $$v
                  },
                  expression: "sysName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "list_tit" }, [
                _vm._v("\n            会员卡名称：\n          ")
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "请输入", clearable: "" },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "list_tit" }, [
                _vm._v("\n            排序号：\n          ")
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { type: "number", placeholder: "请输入", clearable: "" },
                model: {
                  value: _vm.sort,
                  callback: function($$v) {
                    _vm.sort = $$v
                  },
                  expression: "sort"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.addMember } },
          [_vm._v("保存")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }