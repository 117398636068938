"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      gradeList: {},
      imgSrc: this.$store.state.user.imgSrc,
      status: null,
      isPop: false,
      rules: {
        status: [{
          required: true,
          message: '请选择'
        }],
        description: [{
          required: true,
          message: '请输入描述'
        }]
      },
      ruleForm: {
        status: null,
        description: null,
        activityId: null
      },
      downloadLoading: false,
      time: null,
      requestData: {
        rightName: null,
        mobile: null,
        type: null,
        startTime: null,
        endTime: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      platfromList: [],
      tableData: [],
      columns: [{
        slot: 'giftImage',
        label: '礼品图片',
        prop: 'giftImage',
        width: 80
      }, {
        label: '订单号',
        prop: 'orderNo',
        width: 100
      }, {
        label: '礼品编码',
        prop: 'giftCode',
        width: 100
      }, {
        label: '礼品名称',
        prop: 'giftName',
        width: 80
      }, {
        label: '数量',
        prop: 'num',
        width: 50
      }, {
        slot: 'giftType',
        label: '礼品类型',
        prop: 'giftType',
        width: 50
      }, {
        label: '权益名称',
        prop: 'rightName',
        width: 80
      }, {
        slot: 'delegatedReceive'
      }, {
        label: '代领取人',
        prop: 'delegatedUsername',
        width: 80
      }, {
        label: '代领取人手机号',
        prop: 'delegatedMobile',
        width: 80
      }, {
        label: '确认人',
        prop: 'confirmUser',
        width: 80
      }, {
        label: '确认人手机号',
        prop: 'confirmUserMobile',
        width: 80
      },
      /* {
         label: 'uid',
         prop: 'uid',
         width: 80
       }, */
      {
        label: '会员手机号',
        prop: 'userMobile',
        width: 80
      }, {
        slot: 'userLevelId',
        label: '会员等级',
        prop: 'userLevelId',
        width: 50
      }, {
        label: '会员昵称',
        prop: 'username',
        width: 50
      }, {
        label: '领取时间',
        prop: 'recivedTime',
        width: 100
      }]
    };
  },
  watch: {
    isPop: function isPop(newValue, oldValue) {
      if (!newValue) {
        this.ruleForm.activityId = null;
        this.ruleForm.description = null;
        this.status = null;
      }
    },
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {
    var _this = this;

    (0, _api.getGradeList)().then(function (res) {
      res.data.forEach(function (item) {
        _this.gradeList[item.id] = item.name;
      });
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    goExamBox: function goExamBox(row, status) {
      var _this2 = this;

      (0, _api.goExam)({
        id: row.id,
        status: status
      }).then(function (res) {
        _this2.getList();
      });
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this3.requestData[key]) {
          data[key] = _this3.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.listUserRightGiftReciveHistory)(data).then(function (res) {
        list = res.data.records;
        _this3.downloadLoading = false;

        _this3.getHandleDownload(tHeader, filterVal, list, '会员权益列表');
      }).catch(function (res) {
        _this3.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this4 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.listUserRightGiftReciveHistory)(this.requestData).then(function (res) {
        _this4.tableData = res.data.records;
        _this4.requestData.total = Number(res.data.total);
        return;
      });
    }
  }
};
exports.default = _default;