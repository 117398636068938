"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.number.constructor");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _api = require("./api.js");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    couponPop: _radioItem.default,
    UploadExcelComponent: _index.default
  },
  data: function data() {
    var _self = this;

    return {
      userLabelList: [],
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.startTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/phone.xlsx"),
      couponShow: false,
      popType: null,
      urlList: _selectUrl.default.urlList,
      appidArr: _selectUrl.default.appIdArr,
      urlTypeList: _selectUrl.default.urlTypeList,
      noSelect: _selectUrl.default.noSelect,
      vlaueData: _selectUrl.default.vlaueData,
      linkUrlData: _selectUrl.default.linkUrlData,
      columns: [{
        label: '券编码',
        prop: 'couponCode',
        width: 100
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 100
      }, {
        label: '券类型',
        prop: 'couponType',
        width: 100
      }, {
        label: '面额/折扣',
        prop: 'disdenStr',
        width: 100
      }, {
        label: '有效期',
        prop: 'validStr',
        width: 100
      }],
      rules: {
        mobiles: [{
          required: true,
          message: '请上传手机号'
        }],
        contentLink: [{
          required: true,
          message: '请选择或输入链接编码'
        }],
        popupScopes: [{
          required: true,
          message: '请选择显示状态'
        }],
        popupName: [{
          required: true,
          message: '请输入弹框名称'
        }],
        popupStatus: [{
          required: true,
          message: '请选择状态'
        }],
        popupSort: [{
          required: true,
          message: '请输入排序'
        }],
        popupImg: [{
          required: true,
          message: '请上传图片'
        }],
        popupRate: [{
          required: true,
          message: '请选择频率'
        }],
        userLevel: [{
          required: true,
          message: '请选择等级会员'
        }],
        appid: [{
          required: true,
          message: '请选择目标小程序'
        }],
        popuType: [{
          required: true,
          message: '请选择类型'
        }],
        startTime: [{
          required: true,
          message: '请选择事件'
        }],
        endTime: [{
          required: true,
          message: '请选择事件'
        }],
        progressImage: [{
          required: true,
          message: '请上传图片'
        }],
        unProgressImage: [{
          required: true,
          message: '请上传图片'
        }],
        labelContent: [{
          required: true,
          message: '请选择会员标签'
        }]
      },

      /*
      {
         "popuType": 1, // 弹窗类型 1-普通探春  2-悬浮弹窗
        "startTime": "2022/12/19 11:29",
        "endTime": "2022/12/19 11:29",
        "progressImage": "demoData", // 进行中的图片
        "unProgressImage": "demoData", // 非进行中图片
      }
       */
      ruleForm: {
        popuType: null,
        startTime: null,
        endTime: null,
        labelContent: null,
        progressImage: null,
        unProgressImage: null,
        mobiles: [],
        popupScopes: [],
        popupName: null,
        popupStatus: null,
        popupSort: null,
        popupImg: null,
        popupRate: null,
        contentType: null,
        contentLink: null,
        userLevel: null,
        appid: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
      _this.userLabelList = [{
        id: 0,
        labelName: '所有会员'
      }].concat((0, _toConsumableArray2.default)(res.data));
    });
    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id) {
      (0, _api.getPopup)(this.ruleForm.id).then(function (res) {
        _this.ruleForm.contentType = res.data.contentType;
        _this.ruleForm.popupImg = res.data.popupImg;
        _this.ruleForm.popupName = res.data.popupName;
        _this.ruleForm.popupRate = res.data.popupRate;
        _this.ruleForm.popupSort = res.data.popupSort;
        _this.ruleForm.popupStatus = res.data.popupStatus;
        _this.ruleForm.popuType = res.data.popuType;
        _this.ruleForm.startTime = res.data.startTime;
        _this.ruleForm.endTime = res.data.endTime;
        _this.ruleForm.progressImage = res.data.progressImage;
        _this.ruleForm.unProgressImage = res.data.unProgressImage;
        _this.ruleForm.labelContent = res.data.labelContent;
        _this.ruleForm.popupScopes = [];

        if (_this.urlTypeList.indexOf(res.data.contentType) >= 0) {
          console.log(res.data.contentLink);
          _this.ruleForm.contentLink = res.data.contentLink.split('id=')[1];
        } else {
          _this.ruleForm.contentLink = res.data.contentLink;
        }

        _this.ruleForm.appid = res.data.appid;
        res.data.popupScopes.forEach(function (item) {
          _this.ruleForm.popupScopes.push(item.userType);

          if (item.userType === 2) {
            _this.ruleForm.userLevel = item.userLevel;
          }

          if (item.userType === 5) {
            _this.ruleForm.mobiles = item.mobiles || [];
          }
        });
      });
    }
  },
  methods: {
    downPhone: function downPhone() {
      var _this2 = this;

      var _self = this;

      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['手机号'];
        var filterVal = ['phone'];
        var list = [];

        _this2.ruleForm.mobiles.forEach(function (item) {
          list.push({
            phone: item
          });
        });

        var data = _this2.formatJson(filterVal, list);

        excel.export_json_to_excel({
          header: tHeader,
          data: data,
          filename: _self.ruleForm.popupName
        });
        _this2.downloadLoading = false;
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      console.log(results);
      results.forEach(function (data) {
        if (data.手机号) {
          arr.push(String(data.手机号));
        }
      });
      this.ruleForm.mobiles = arr;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 2;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过2M的文件',
        type: 'warning'
      });
      return false;
    },
    changeUser: function changeUser(e) {
      if (e.indexOf(1) >= 0) {
        this.ruleForm.popupScopes = [1];
        return;
      }

      if (e.indexOf(5) >= 0) {
        this.ruleForm.popupScopes = [5];
        return;
      }
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
      console.log(this.ruleForm);
    },
    typeChange: function typeChange() {
      this.ruleForm.contentLink = '';
    },
    getLinkId: function getLinkId() {
      this.popType = this.ruleForm.contentType;
      this.$refs.coupon1.getList(this.popType, this.ruleForm.contentLink);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      if (this.popType === 'tem') {
        this.ruleForm.contentLink = e.pageCode;
      } else if (this.popType === 'vipCity' || this.popType === 'card' || this.popType === 'coupon') {
        this.ruleForm.contentLink = e.uuid;
      } else if (this.popType === 'goods') {
        // temItem.productSource = e.productSource
        this.ruleForm.contentLink = e.productCode;
      } else if (this.popType === 'couponBag') {
        // temItem.productSource = e.productSource
        this.ruleForm.contentLink = e.id;
      } else if (this.popType === 'pond') {
        this.ruleForm.contentLink = e.code;
      }

      this.couponShow = false;
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var popupScopes = [];

          _this3.ruleForm.popupScopes.forEach(function (item) {
            popupScopes.push({
              userType: item,
              userLevel: item === 2 ? _this3.ruleForm.userLevel : '',
              mobiles: item === 5 ? _this3.ruleForm.mobiles : []
            });
          });

          var data = JSON.parse(JSON.stringify(_this3.ruleForm));

          if (_this3.urlTypeList.includes(_this3.ruleForm.contentType)) {
            data.contentLink = _this3.linkUrlData[_this3.ruleForm.contentType].url + data.contentLink;
          } else if (_this3.ruleForm.contentType) {
            data.contentLink = _this3.ruleForm.contentType;
          }

          data.popupScopes = popupScopes;
          data.popupSort = Number(data.popupSort);

          if (_this3.ruleForm.id) {
            (0, _api.editPopupPage)(data).then(function (res) {
              _this3.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this3.$router.go(-1);
            });
          } else {
            (0, _api.addPopupPage)(data).then(function (res) {
              _this3.$router.go(-1);

              _this3.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;