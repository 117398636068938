var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            pageApi: "/api/mall/goods/goods/list",
            "search-data": _vm.searchData
          },
          on: { setSelectGoods: _vm.setSelectGoods }
        },
        [
          _vm.isChagneGoodsStatus
            ? _c(
                "div",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { slot: "tableSelect" },
                  slot: "tableSelect"
                },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", size: "mini" } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "warning",
                                icon: "el-icon-download"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.goGoodsStatus("down")
                                }
                              }
                            },
                            [_vm._v("批量下架")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                icon: "el-icon-upload2"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.goGoodsStatus("up")
                                }
                              }
                            },
                            [_vm._v("批量上架")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger", icon: "el-icon-delete" },
                              on: {
                                click: function($event) {
                                  return _vm.goGoodsStatus("del")
                                }
                              }
                            },
                            [_vm._v("批量删除")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "select",
              type: "selection",
              selectable: _vm.isSelectDisabled,
              "reserve-selection": "",
              width: "55",
              fixed: "left",
              align: "center"
            },
            slot: "select"
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "mainImage",
              label: "主图",
              align: "center",
              width: "70"
            },
            slot: "mainImage",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "45px" },
                      attrs: {
                        src: scope.row.mainImage,
                        "preview-src-list": [scope.row.mainImage]
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "上架",
              align: "center",
              width: "80"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        size: "mini",
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                        "active-value": 1,
                        "inactive-value": 0
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeGoodsStatus($event, [scope.row.id])
                        }
                      },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "price",
              label: "价格",
              align: "left",
              width: "160"
            },
            slot: "price",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "p",
                      [
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              width: "45px",
                              "text-align": "center",
                              "margin-right": "6px"
                            },
                            attrs: { size: "mini", type: "warning" }
                          },
                          [_vm._v("成本价")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "form_remark" }, [
                          _vm._v(_vm._s(scope.row.spuCostPrice) + "元")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticStyle: { margin: "6px 0" } },
                      [
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              width: "45px",
                              "text-align": "center",
                              "margin-right": "6px"
                            },
                            attrs: { size: "mini" }
                          },
                          [_vm._v("原价")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "form_remark" }, [
                          _vm._v(_vm._s(scope.row.spuOriginalPrice) + "元")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              width: "45px",
                              "text-align": "center",
                              "margin-right": "6px"
                            },
                            attrs: { size: "mini", type: "success" }
                          },
                          [_vm._v("售价")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "form_remark" }, [
                          _vm._v(_vm._s(scope.row.spuGoodsPrice) + "元")
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "isHot",
              label: "热销",
              align: "center",
              width: "60"
            },
            slot: "isHot",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { class: scope.row.isHot == 1 ? "success" : "danger" },
                      [_vm._v(_vm._s(scope.row.isHot == 1 ? "是" : "否"))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "isRecommended",
              label: "推荐",
              align: "center",
              width: "60"
            },
            slot: "isRecommended",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        class:
                          scope.row.isRecommended == 1 ? "success" : "danger"
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.isRecommended == 1 ? "是" : "否")
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "isOpenMemberDiscount",
              label: "会员折扣",
              align: "center",
              width: "100"
            },
            slot: "isOpenMemberDiscount",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        class:
                          scope.row.isOpenMemberDiscount == 1
                            ? "success"
                            : "danger"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.isOpenMemberDiscount == 1 ? "是" : "否"
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    scope.row.isOpenMemberDiscount == 1
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.memberDiscountPercent) +
                              "%\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "userMultipleLimit",
              label: "可购买会员",
              align: "center",
              width: "100"
            },
            slot: "userMultipleLimit",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.userMultipleLimit
                      ? _c(
                          "div",
                          _vm._l(
                            scope.row.userMultipleLimit.split(","),
                            function(item, index) {
                              return _c(
                                "p",
                                { key: index },
                                [
                                  item == 1
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "warning"
                                          }
                                        },
                                        [_vm._v("普通会员")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item == 2
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "mini" } },
                                        [_vm._v("365会员")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item == 3
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "success"
                                          }
                                        },
                                        [_vm._v("lavant会员")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "dailyLimitNum",
              label: "次数",
              align: "center",
              width: "180"
            },
            slot: "dailyLimitNum",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        单个用户每天可以发送" +
                        _vm._s(scope.row.dailyLimitNum) +
                        "次\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              fixed: "right",
              align: "center",
              width: "90"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          size: "mini",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/legou/goods/add?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-document"
                            },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/legou/goods/dea?id=" + scope.row.id
                                )
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          title: "确认删除商品：" + scope.row.goodsName + "？"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.delGoodsItem(
                              [scope.row.id],
                              scope.$index
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "danger",
                            attrs: {
                              slot: "reference",
                              icon: "el-icon-delete",
                              type: "text",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("\n            删除\n          ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }