var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-setting-home" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("荔星荟设置")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                attrs: {
                  "label-width": "90px",
                  model: _vm.ruleForm,
                  rules: _vm.rules
                }
              },
              [
                _c("h4", { staticClass: "form_title" }, [_vm._v("主页设置")]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "欢迎图：", prop: "welcomePic" } },
                  [
                    _c("add-load", {
                      staticClass: "img_list",
                      attrs: {
                        width: 375,
                        height: 175,
                        cover: _vm.ruleForm.welcomePic
                      },
                      on: {
                        setCover: function($event) {
                          return _vm.setCover($event, "welcomePic")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticStyle: { color: "#999999" } }, [
                      _vm._v("建议图片尺寸：750 x 350")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "活动日历：" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goto(
                              "/svip/activity/editCalendar/" +
                                _vm.activityCalendarInfo.id
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.activityCalendarInfo.activityCalendarName ||
                                "-"
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "精选图片：", prop: "selectionPics" } },
                  [
                    _c(
                      "div",
                      { staticClass: "svip-setting-home_form" },
                      _vm._l(_vm.ruleForm.selectionPics, function(
                        picture,
                        idx
                      ) {
                        return _c(
                          "el-form-item",
                          {
                            key: idx,
                            staticClass: "svip-setting-home_form_select-pic",
                            attrs: {
                              label: "图" + (idx + 1),
                              "label-position": "left",
                              "label-width": "60px"
                            }
                          },
                          [
                            _c("el-button", {
                              staticClass:
                                "svip-setting-home_form_select-pic_delete",
                              attrs: { type: "text", icon: "el-icon-delete" },
                              on: {
                                click: function($event) {
                                  return _vm.delSelectPic(idx)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "selectionPics." + idx + ".pic",
                                  rules: {
                                    required: true,
                                    message: "请上传图片"
                                  }
                                }
                              },
                              [
                                _c("add-load", {
                                  staticClass: "img_list",
                                  attrs: {
                                    width: 187.5,
                                    height: 120,
                                    cover: picture.pic
                                  },
                                  on: {
                                    setCover: function($event) {
                                      return _vm.setSelectPic($event, idx)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("p", { staticStyle: { color: "#999999" } }, [
                                  _vm._v("建议图片尺寸：宽750 不限高度")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass:
                                  "svip-setting-home_form_select-pic_right"
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "状态：",
                                      "label-width": "100px",
                                      prop: "selectionPics." + idx + ".enable",
                                      rules: {
                                        required: true,
                                        message: "请选择状态",
                                        trigger: "change"
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: picture.enable,
                                          callback: function($$v) {
                                            _vm.$set(picture, "enable", $$v)
                                          },
                                          expression: "picture.enable"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("上架")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("下架")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "排序：",
                                      "label-width": "100px",
                                      prop: "selectionPics." + idx + ".sort",
                                      rules: {
                                        type: "number",
                                        required: true,
                                        message: "请输入排序",
                                        trigger: "blur"
                                      }
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "166px" },
                                      attrs: {
                                        min: 1,
                                        step: 1,
                                        precision: 0,
                                        controls: false
                                      },
                                      model: {
                                        value: picture.sort,
                                        callback: function($$v) {
                                          _vm.$set(picture, "sort", $$v)
                                        },
                                        expression: "picture.sort"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "关联内容：",
                                      "label-width": "100px",
                                      prop: "selectionPics." + idx + ".link"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "form_input",
                                        staticStyle: { width: "166px" },
                                        attrs: {
                                          clearable: "",
                                          filterable: "",
                                          placeholder: "关联内容类型"
                                        },
                                        on: { change: _vm.typeChange },
                                        model: {
                                          value: picture.link,
                                          callback: function($$v) {
                                            _vm.$set(picture, "link", $$v)
                                          },
                                          expression: "picture.link"
                                        }
                                      },
                                      _vm._l(_vm.selectUrlList, function(
                                        item,
                                        index
                                      ) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "max-width": "100%" },
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-circle-plus-outline"
                        },
                        on: { click: _vm.addPic }
                      },
                      [_vm._v("\n            添加图片\n          ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "150px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.submitForm("ruleForm")
                          }
                        }
                      },
                      [_vm._v("\n            保存\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-left": "20px" } }, [
          _c("h4", { staticClass: "form_title" }, [_vm._v("预览图")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "svip-tutorial" },
            [
              _c("div", { staticClass: "svip-tutorial_access" }, [
                _c(
                  "div",
                  { staticClass: "svip-tutorial_access_item" },
                  [
                    _c("el-image", {
                      staticClass: "svip-tutorial_access_item_icon",
                      attrs: {
                        src:
                          _vm.imgSrc +
                          "mika/dev/2022-07-21/84bc931b92784d24998f36baac87f105.png"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "svip-tutorial_access_item_text" },
                      [_vm._v("服务预约")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "svip-tutorial_access_item" },
                  [
                    _c("el-image", {
                      staticClass: "svip-tutorial_access_item_icon",
                      attrs: {
                        src:
                          _vm.imgSrc +
                          "mika/dev/2022-07-21/c5408ba608524be1a489a1387198db45.png"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "svip-tutorial_access_item_text" },
                      [_vm._v("场地预约")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "svip-tutorial_access_item_online" },
                      [_vm._v("即将上线")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "svip-tutorial_access_item" },
                  [
                    _c("el-image", {
                      staticClass: "svip-tutorial_access_item_icon",
                      attrs: {
                        src:
                          _vm.imgSrc +
                          "mika/dev/2022-07-21/96539f73312149a7924adc0ddb9f209c.png"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "svip-tutorial_access_item_text" },
                      [_vm._v("专享商品")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "svip-tutorial_access_item" },
                  [
                    _c("el-image", {
                      staticClass: "svip-tutorial_access_item_icon",
                      attrs: {
                        src:
                          _vm.imgSrc +
                          "mika/dev/2022-07-28/7076755e777240d98894581e4febffd8.png"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "svip-tutorial_access_item_text" },
                      [_vm._v("充值中心")]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "svip-tutorial_welcome" },
                [
                  _vm.ruleForm.welcomePic
                    ? _c("el-image", {
                        staticClass: "svip-tutorial_welcome_bg",
                        attrs: {
                          src: _vm.imgSrc + _vm.ruleForm.welcomePic,
                          alt: ""
                        }
                      })
                    : _c("div", { staticClass: "svip-tutorial_image-empty" }, [
                        _vm._v("暂无图片")
                      ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "svip-tutorial_calendar" },
                [
                  _c(
                    "div",
                    { staticClass: "svip-tutorial_calendar_card" },
                    [
                      _vm.activityCalendarInfo.monthPic
                        ? _c("el-image", {
                            staticClass: "svip-tutorial_calendar_card_header",
                            attrs: {
                              src:
                                _vm.imgSrc + _vm.activityCalendarInfo.monthPic
                            }
                          })
                        : _c(
                            "div",
                            { staticClass: "svip-tutorial_image-empty" },
                            [_vm._v("暂无图片")]
                          ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { padding: "0 10px" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "svip-tutorial_calendar_card_body",
                            style: {
                              backgroundColor: _vm.activityCalendarInfo.bgColor
                                ? _vm.activityCalendarInfo.bgColor
                                : "#e8d9cf"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "svip-tutorial_calendar_card_body_week"
                              },
                              _vm._l(_vm.weeks, function(week, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "svip-tutorial_calendar_card_body_week_item"
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(week) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "svip-tutorial_calendar_card_body_day"
                              },
                              _vm._l(_vm.days, function(day, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "svip-tutorial_calendar_card_body_day_item",
                                    style: {
                                      color: day.activity
                                        ? "#FFFFFF"
                                        : "#000000",
                                      backgroundColor:
                                        day.day === 0
                                          ? "rgba(255, 255, 255, 0.7)"
                                          : day.activity
                                          ? day.activity.color
                                          : "#FFFFFF"
                                    }
                                  },
                                  [
                                    day.day !== 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "svip-tutorial_calendar_card_body_day_item-day"
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(day.day) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          height: "32px",
                                          "align-items": "center",
                                          "justify-content": "center"
                                        }
                                      },
                                      [
                                        day.activity
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "svip-tutorial_calendar_card_body_day_item-activity"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      day.activity.activityName
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "svip-tutorial_calendar_card_text" },
                        [
                          _vm._v(
                            "\n              点击上方日历内活动即可预约\n            "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.activityCalendarInfo.mainBgPic
                    ? _c("el-image", {
                        staticClass: "svip-tutorial_calendar_bg",
                        attrs: {
                          src: _vm.imgSrc + _vm.activityCalendarInfo.mainBgPic
                        }
                      })
                    : _c("div", { staticClass: "svip-tutorial_image-empty" }, [
                        _vm._v("暂无图片")
                      ])
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.selectionPicsList, function(selection, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    selection.pic
                      ? _c("el-image", {
                          attrs: {
                            src: _vm.imgSrc + selection.pic,
                            mode: "widthFix",
                            lazy: ""
                          }
                        })
                      : _c(
                          "div",
                          { staticClass: "svip-tutorial_image-empty" },
                          [_vm._v("暂无图片")]
                        )
                  ],
                  1
                )
              })
            ],
            2
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }