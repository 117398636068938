"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    wTable: _index.default
  },
  data: function data() {
    return {
      orderStatus: [],
      downloadLoading: false,
      requestData: {
        orderNo: '',
        buyUtphone: '',
        orderStatus: '',
        pageNo: 1,
        pageSize: 10,
        total: 0,
        beginPayTime: '',
        endPayTime: ''
      },
      time: '',
      tableData: [],
      columns: [{
        label: '会员UUId',
        prop: 'buyUid',
        width: 100
      }, {
        label: '订单号',
        prop: 'orderNo',
        width: 50
      }, {
        label: '365会员',
        prop: 'buyUtphone',
        width: 80
      }, {
        label: '联名卡号',
        prop: 'amountRealpay',
        width: 50
      }, {
        label: '手机号',
        prop: 'orderCreateTime',
        width: 100
      }, {
        label: '会员状态',
        prop: 'orderStatusStr',
        width: 50 // {
        //   slot: 'operate'
        // }

      }]
    };
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    refundPlus: function refundPlus(row) {
      var _this = this;

      this.$confirm('此操作将退款, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        refundMaoTaiVip({
          buyUid: row.buyUid,
          orderId: row.orderId
        }).then(function (res) {
          _this.getList();

          _this.$message({
            type: 'success',
            message: '退款成功!'
          });
        });
      }).catch(function () {});
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['订单号', '购买人', '手机号码', '购买金额', '购买时间', '订单状态'];
        var filterVal = ['orderNo', 'buyUname', 'buyUtphone', 'amountRealpay', 'orderCreateTime', 'orderStatusStr'];
        var list = [];
        _this2.requestData.beginPayTime = _this2.time ? _this2.time[0] : '';
        _this2.requestData.endPayTime = _this2.time ? _this2.time[1] : '';
        var data = {};
        Object.keys(_this2.requestData).forEach(function (key) {
          if (_this2.requestData[key]) {
            data[key] = _this2.requestData[key];
          }
        });
        data.pageSize = _this2.requestData.total;
        data.pageNo = 1;

        _this2.openLoading();

        (0, _api.listOrderPayMember)(data).then(function (res) {
          list = res.data.items;

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: 'MIKA  PLUS购买记录'
          });
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        }).catch(function (res) {
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type == 'search') {
        this.pageNo = 1;
      }

      if (type == 'remove') {
        this.time = '';
        this.removeObj(this.requestData);
      }

      this.requestData.beginPayTime = this.time ? this.time[0] : '';
      this.requestData.endPayTime = this.time ? this.time[1] : '';
      (0, _api.listOrderPayMember)(this.requestData).then(function (res) {
        _this3.tableData = res.data.items;
        _this3.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;