var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "deatil" },
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px 0" } },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [_c("span", [_vm._v("优惠券状态")])]
          ),
          _vm._v(" "),
          _c(
            "el-steps",
            { attrs: { size: "mini", active: _vm.active } },
            _vm._l(_vm.step, function(item, index) {
              return _c("el-step", {
                key: index,
                attrs: { title: item.tit, description: item.txt }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "coupon_steps", staticStyle: { overflow: "auto" } },
            [
              _c(
                "el-steps",
                {
                  staticStyle: { padding: "13px 0", display: "block" },
                  attrs: {
                    size: "mini",
                    active: _vm.oprLogs.length,
                    space: "100px"
                  }
                },
                _vm._l(_vm.oprLogs, function(item, index) {
                  return _c("el-step", {
                    key: index,
                    staticStyle: { "max-width": "auto !important" },
                    attrs: {
                      title: item.oprRemark + "(" + item.oprUserName + ")",
                      description: item.oprTime
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "box_btn" }, [
            _c("p", [
              _vm._v("当前状态："),
              _c("span", { staticClass: "color" }, [_vm._v(_vm._s(_vm.status))])
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.isEdit && _vm.setMenuPerms("coupon:editAll")
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.goEdit(1)
                          }
                        }
                      },
                      [_c("span", [_vm._v("修改优惠券")])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isEdit && _vm.setMenuPerms("coupon:editGoods")
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.goEdit(2)
                          }
                        }
                      },
                      [_vm._v("\n          修改商品码\n        ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.btnType === 1 && _vm.setMenuPerms("coupon:audit")
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.passAudit(2)
                          }
                        }
                      },
                      [_vm._v("\n          通过审核\n        ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.btnType === 2 && _vm.setMenuPerms("coupon:audit")
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.passAudit(6)
                          }
                        }
                      },
                      [_vm._v("\n          终止\n        ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.couponInfo
        ? _c(
            "el-card",
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [_c("span", [_vm._v("优惠券详情")])]
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    labelStyle: {
                      width: "120px"
                    },
                    labelClassName: "couponLabel",
                    size: "small ",
                    column: 3,
                    border: ""
                  }
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "优惠券名称：" } },
                    [_vm._v("\n        " + _vm._s(_vm.name) + "\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "优惠券编码：" } },
                    [_vm._v("\n        " + _vm._s(_vm.uuid) + "\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "优惠券类型：" } },
                    [_vm._v("\n        " + _vm._s(_vm.couponType) + "\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "费用出资方：", span: 3 } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.costTypes) +
                          "\n        (公司承担比例" +
                          _vm._s(_vm.ratioCompany) +
                          "%——商户承担比例" +
                          _vm._s(_vm.ratioTenant) +
                          "%)\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "优惠券描述：", span: 3 } },
                    [_vm._v("\n        " + _vm._s(_vm.descrition) + "\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "短信通知：", span: 3 } },
                    [
                      _vm.couponInfo &&
                      _vm.couponInfo.tradeNoticeMobile.length > 0
                        ? _c(
                            "p",
                            _vm._l(_vm.couponInfo.tradeNoticeMobile, function(
                              item,
                              index
                            ) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  staticStyle: { "margin-right": "10px" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.mobie) +
                                      "\n          "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "是否开放购买：" } },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.couponInfo.openSale === 1 ? "success" : "danger"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.couponInfo.openSale === 1
                                  ? "开放"
                                  : "不开放"
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "销售价格：" } },
                    _vm._l(_vm.salestypes, function(item, index) {
                      return _c("p", { key: index }, [
                        item.buyType == 4
                          ? _c(
                              "span",
                              [
                                _vm._v("免费领取"),
                                _c("font", { staticClass: "margin_left" }, [
                                  _vm._v("（用户可免费获得）")
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.buyType == 1
                          ? _c("span", [
                              _vm._v("金额：" + _vm._s(item.price) + "元")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        item.buyType == 2
                          ? _c("span", [
                              _vm._v("积分：" + _vm._s(item.integral) + "积分")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        item.buyType == 3
                          ? _c("span", [
                              _vm._v(
                                "金额+积分：" +
                                  _vm._s(item.price) +
                                  "元+" +
                                  _vm._s(item.integral) +
                                  "积分"
                              )
                            ])
                          : _vm._e()
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "365会员价：" } },
                    _vm._l(_vm.salestypes, function(item, index) {
                      return _c("p", { key: index }, [
                        item.buyType == 4
                          ? _c(
                              "span",
                              [
                                _vm._v("免费领取"),
                                _c("font", { staticClass: "margin_left" }, [
                                  _vm._v("（用户可免费获得）")
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.buyType == 1 && item.priceMem > 0
                          ? _c("span", [
                              _vm._v("金额：" + _vm._s(item.priceMem) + "元")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        item.buyType == 2 && item.integralMem > 0
                          ? _c("span", [
                              _vm._v(
                                "积分：" + _vm._s(item.integralMem) + "积分"
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        item.buyType == 3 &&
                        (item.priceMem > 0 || item.integralMem > 0)
                          ? _c("span", [
                              _vm._v(
                                "金额+积分：" +
                                  _vm._s(item.priceMem) +
                                  "元+" +
                                  _vm._s(item.integralMem) +
                                  "积分"
                              )
                            ])
                          : _vm._e()
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "核销规则：" } },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.couponInfo.stepType === 1 ? "success" : "danger"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.couponInfo.stepType === 1
                                  ? "单门槛"
                                  : "阶梯门槛"
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "投放总数：" } },
                    [
                      _vm.totalNumber == 0
                        ? _c(
                            "p",
                            [
                              _vm._v("0"),
                              _vm.totalNumber === 0
                                ? _c("font", { staticClass: "margin_left" }, [
                                    _vm._v("（不限量投放）")
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _c("p", [_vm._v(_vm._s(_vm.totalNumber) + "张")])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "限领/限购数：" } },
                    [
                      _vm.limitNumber == 0
                        ? _c(
                            "p",
                            [
                              _vm._v("0"),
                              _vm.limitNumber === 0
                                ? _c("font", { staticClass: "margin_left" }, [
                                    _vm._v("（不限领数量）")
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _c(
                            "p",
                            [
                              _vm._v(_vm._s(_vm.limitNumber) + "\n          "),
                              _c("font", { staticClass: "margin_left" }, [
                                _vm._v(
                                  "（一个用户可获得" +
                                    _vm._s(_vm.limitNumber) +
                                    "张）"
                                )
                              ])
                            ],
                            1
                          )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "优惠券面额：" } },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.denomination) + "元\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "使用门槛金额：", span: 3 } },
                    [
                      _vm._v("\n        " + _vm._s(_vm.doorsillMoney) + "元 "),
                      _vm.doorsillMoney === 0
                        ? _c("font", { staticClass: "margin_left" }, [
                            _vm._v("（无需门槛，可直接使用）")
                          ])
                        : _c("font", { staticClass: "margin_left" }, [
                            _vm._v(
                              "（消费满" +
                                _vm._s(_vm.doorsillMoney) +
                                "元可优惠" +
                                _vm._s(_vm.denomination) +
                                "元）"
                            )
                          ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "供应商：" } }, [
                    _vm._v("\n        " + _vm._s(_vm.supplier) + "\n      ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "供应商手机：" } },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.supplierMobile) + "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "进货价：" } }, [
                    _vm._v("\n        " + _vm._s(_vm.stockPrice) + "元\n      ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "优惠券有效期：" } },
                    [
                      _vm.couponInfo.validTimeType === 2
                        ? _c("p", [
                            _vm.couponInfo.validStartDay === 0
                              ? _c("span", [
                                  _vm._v(
                                    "\n            领取后" +
                                      _vm._s(_vm.couponInfo.validDay) +
                                      "天内有效\n          "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n            领取后" +
                                      _vm._s(
                                        _vm.couponInfo.validStartDay || 0
                                      ) +
                                      "天到" +
                                      _vm._s(_vm.couponInfo.validDay) +
                                      "内有效\n          "
                                  )
                                ])
                          ])
                        : _c("p", [
                            _vm._v(
                              _vm._s(_vm.couponInfo.validBeginTime) +
                                "至" +
                                _vm._s(_vm.couponInfo.validEndTime)
                            )
                          ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "排斥规则：" } },
                    [_vm._v("\n        " + _vm._s(_vm.rejectRule) + "\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "会员价排斥：" } },
                    [
                      _vm.rejectVipPrice == 1
                        ? _c("span", [_vm._v("不能与365会员价同时使用")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.rejectVipPrice == -1
                        ? _c("span", [_vm._v("能与365会员价同时使用")])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "核销方式：", span: 1 } },
                    [
                      _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.verificationModel.join("、")) +
                            "\n        "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "是否到家：", span: 1 } },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.couponInfo.isTohome === 1 ? "success" : "danger"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.couponInfo.isTohome === 1 ? "是" : "否"
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "是否权益券：", span: 1 } },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.couponInfo.isRightsCoupon === 1
                              ? "success"
                              : "danger"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.couponInfo.isRightsCoupon === 1
                                  ? "是"
                                  : "否"
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "专属券：" } }, [
                    _c(
                      "span",
                      {
                        class:
                          _vm.couponInfo.customerType === 1
                            ? "success"
                            : "danger"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.couponInfo.customerType === 1
                                ? "365会员"
                                : "全部会员"
                            ) +
                            "可购买\n        "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "是否商户券：" } },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.couponInfo.merchantType === 1
                              ? "success"
                              : "danger"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.couponInfo.merchantType === 1
                                  ? "非商户券"
                                  : "商户券（乐购使用）"
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "365会员价排斥：" } },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.couponInfo.rejectVipPrice === 1
                              ? "danger"
                              : "success"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.couponInfo.rejectVipPrice === 1
                                  ? "不能与会员价使用"
                                  : "能与会员价同时使用）"
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "费否转赠：" } },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.couponInfo.ifDonation === 1
                              ? "success"
                              : "danger"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.couponInfo.ifDonation === 1
                                  ? "可转赠"
                                  : "不可转赠"
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "是否可分享：" } },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.couponInfo.ifShare === 1 ? "success" : "danger"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.couponInfo.ifShare === 1
                                  ? "可分享"
                                  : "不可分享"
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "显示状态：" } },
                    [_vm._v("\n        " + _vm._s(_vm.showStatus) + "\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "使用范围：", span: 3 } },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: { color: "#666666", "font-size": "14px" }
                        },
                        [
                          _vm._v(
                            "会员体系/机构：" +
                              _vm._s(_vm.institutionNames.length) +
                              "个机构"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        _vm._l(_vm.institutionNames, function(item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticStyle: { "margin-right": "5px" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item) +
                                  "(" +
                                  _vm._s(_vm.institutionIds[index] || "") +
                                  ")\n          "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "百货商品码：", span: 3 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "goods",
                          class: _vm.usableType == 1 ? "success" : "danger"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.usableTypeTxt) +
                              "商品：" +
                              _vm._s(_vm.goodsIds.length) +
                              "个商品码\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "300px",
                            overflow: "auto"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.goodsIds.join("、")) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "超市商品码：", span: 3 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "goods",
                          class: _vm.usableType == 1 ? "success" : "danger"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.usableTypeTxt) +
                              "商品：" +
                              _vm._s(_vm.goodsIds1 ? _vm.goodsIds1.length : 0) +
                              "个商品码"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "300px",
                            overflow: "auto"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.goodsIds1.join("、")) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.couponInfo.ifShare == 1
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "分享标题：", span: 1 } },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.shareTitle) + "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.couponInfo.ifShare == 1
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "分享图片：", span: 2 } },
                        [
                          _c("el-image", {
                            staticStyle: { width: "50px" },
                            attrs: {
                              src: _vm.imgSrc + _vm.shareImg,
                              "preview-src-list": [_vm.imgSrc + _vm.shareImg]
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "优惠券图片：", span: 1 } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "50px" },
                        attrs: {
                          src: _vm.mainImage,
                          "preview-src-list": [_vm.mainImage]
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "详情页滚动图：", span: 2 } },
                    _vm._l(_vm.detailRollimg, function(item, index) {
                      return _c("el-image", {
                        key: index,
                        staticStyle: { width: "50px", "margin-right": "10px" },
                        attrs: {
                          src: item,
                          "preview-src-list": _vm.detailRollimg
                        }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "内容详情：", span: 1 } },
                    [
                      _c("p", {
                        staticStyle: { "max-width": "375px" },
                        domProps: { innerHTML: _vm._s(_vm.geDetails) }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "使用规则：", span: 2 } },
                    [
                      _c("p", {
                        staticStyle: { "max-width": "375px" },
                        domProps: { innerHTML: _vm._s(_vm.instructions) }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm.detailCtmfield.length > 0
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "自定义字段：", span: 3 } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { size: "mini", data: _vm.detailCtmfield }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "tit",
                                  label: "字段名称",
                                  width: "180",
                                  align: "center"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "text", label: "字段内容" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("p", {
                                            domProps: {
                                              innerHTML: _vm._s(scope.row.text)
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2639774178
                                )
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "sort",
                                  label: "排序",
                                  width: "180",
                                  align: "center"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }