var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            pageApi: "/api/mall/merchantAddress/list",
            "search-data": _vm.searchData
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "type",
              label: "类型",
              align: "center",
              width: "80"
            },
            slot: "type",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { class: scope.row.type == 1 ? "success" : "danger" },
                      [
                        _vm._v(
                          _vm._s(scope.row.type == 1 ? "收货地址" : "退货地址")
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "isDelete",
              label: "是否删除",
              align: "center",
              width: "80"
            },
            slot: "isDelete",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { class: scope.row.isDelete == 0 ? "success" : "danger" },
                      [
                        _vm._v(
                          _vm._s(scope.row.isDelete == 0 ? "未删除" : "已删除")
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "isShow",
              label: "是否显示",
              align: "center",
              width: "100"
            },
            slot: "isShow",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                        "active-value": 1,
                        "inactive-value": 0
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeRecommend($event, scope.row)
                        }
                      },
                      model: {
                        value: scope.row.isShow,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isShow", $$v)
                        },
                        expression: "scope.row.isShow"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              fixed: "right",
              align: "center",
              width: "140"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          size: "mini",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/legou/merchant/addressAdd?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _vm._v(" "),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          title: "确认删除地址：" + scope.row.address + "？"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.delGoodsItem(scope.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "danger",
                            attrs: {
                              slot: "reference",
                              icon: "el-icon-delete",
                              type: "text",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("\n            删除\n          ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }