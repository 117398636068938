"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.includes");

require("core-js/modules/es7.array.includes");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _lists = _interopRequireDefault(require("@/components/addLoad/lists.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addImgList: _lists.default
  },
  data: function data() {
    var _ruleForm;

    return {
      changeSpecList: [],
      formData: [{
        label: '商品分类：',
        value: 'allCategoryName'
      }, {
        label: '店内分类：',
        value: 'merchantName'
      }, {
        label: '商品品牌：',
        value: 'brandName'
      }, {
        label: '商品名称：',
        value: 'goodsName'
      }, {
        label: '重量：',
        value: 'goodsWeight',
        end: 'g'
      }, {
        label: '单位：',
        value: 'goodsUnit'
      }, {
        label: '商品服务：',
        value: 'service'
      }, {
        label: '价格：',
        value: 'spuCostPrice',
        end: '元'
      },
      /* {
         label: '原价：',
         value: 'spuOriginalPrice',
         end: '元'
       },
       {
         label: '售价：',
         value: 'spuGoodsPrice',
         end: '元'
       }, */
      {
        label: '是否使用规格：',
        value: 'isMultipleSku',
        type: 'radio'
      }, {
        label: '总库存：',
        value: 'totalStock'
      }, {
        label: '是否开启会员折扣：',
        value: 'isOpenMemberDiscount',
        type: 'radio'
      }, {
        label: '会员折扣百分比：',
        value: 'memberDiscountPercent',
        end: '%'
      }, {
        label: '分销设置：',
        value: 'isOpenShareCommission',
        type: 'radio'
      }, {
        label: '一级分销佣金：',
        value: 'shareCommissionFirst',
        end: '%'
      }, {
        label: '允许购买会员类型：',
        value: 'userMultipleLimit'
      }, {
        label: '限购数量：',
        value: 'limitPurchaseQuantity'
      }, {
        label: '加入购物车：',
        value: 'isAllowAddCart',
        type: 'radio'
      }, {
        label: '运费设置：',
        value: 'freightTemplateId'
      }, {
        label: '创建时间：',
        value: 'createTime'
      }, {
        label: '更新时间：',
        value: 'updateTime'
      }, {
        label: 'spu编码：',
        value: 'spuNo'
      }],
      ruleForm: (_ruleForm = {
        "id": null,
        "goodsPlatform": 1,
        "spuNo": 0,
        "goodsName": "",
        "goodsUnit": null,
        "isAllowAddCart": 1,
        "isOpenMemberDiscount": 0,
        "memberDiscountPercent": 0,
        "service": [],
        "brandId": '',
        "freightTemplateId": null,
        "firstCategoryId": [],
        "secondCategoryId": null,
        "thirdCategoryId": null,
        "merchantCategoryId": null,
        "limitPurchaseQuantity": 0,
        "userMultipleLimit": [1, 2, 3],
        "virtualSales": 0,
        "goodsWeight": undefined,
        "goodsImages": [],
        "detail": "",
        "isOpenShareCommission": 0,
        "shareCommissionFirst": 0,
        "shareCommissionSecond": 0,
        "shareCommissionThird": 0,
        spuCostPrice: undefined,
        spuOriginalPrice: undefined
      }, (0, _defineProperty2.default)(_ruleForm, "spuOriginalPrice", undefined), (0, _defineProperty2.default)(_ruleForm, "isMultipleSku", 1), (0, _defineProperty2.default)(_ruleForm, "goodsSkuDTO", []), _ruleForm)
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    /*
    商品单位
    */
    this.apiPost('/api/mall/goods/attribute/unit', {}).then(function (res) {
      var selectList = res.data.map(function (item) {
        return {
          label: item,
          value: item
        };
      });

      _this.formData.forEach(function (item) {
        if (item.value === 'goodsUnit') {
          item.selectList = selectList;
        }
      });
    });
    /*
    获取商品分类
    */

    this.apiPost('/api/mall/goods/category/list', {}).then(function (res) {
      var data = _this.IterationDelateMenuChildren(res.data);

      _this.categoryList = data;
      data.forEach(function (item) {
        item.disabled = true;
      });

      _this.formData.forEach(function (item) {
        if (item.value === 'firstCategoryId') {
          item.selectList = data;
        }
      });

      _this.isSUccess = true;
    });
    /*
    获取商品品牌
    */

    this.apiPost('/api/mall/goods/brand/all', {}).then(function (res) {
      _this.formData.forEach(function (item) {
        if (item.value === 'brandId') {
          item.selectList = res.data.map(function (item) {
            return {
              label: item.brandName,
              value: item.id
            };
          });
        }
      });
    });
    this.formType = this.$route.query.type;
    this.ruleForm.id = this.$route.query.id || '';
    /*
    获取商品详情
    */

    if (this.ruleForm.id > 0) {
      this.apiPost('/api/mall/goods/goods/detail', {
        id: this.ruleForm.id
      }).then(function (res) {
        var goodsSpu = res.data.goodsSpu;
        goodsSpu.service = goodsSpu.service ? JSON.parse(goodsSpu.service) : [];
        goodsSpu.userMultipleLimit = goodsSpu.userMultipleLimit.split(',').map(Number);
        var resData = (0, _objectSpread2.default)({}, goodsSpu);
        /*
        arr 规格组
        specData  规格组子集
        */

        var arr = [];
        var specData = {};
        /*
        规格组赋值
        */

        if (res.data.goodsSpu.isMultipleSku == 1) {
          resData.goodsSkuDTO = res.data.goodsSkuVO.map(function (item) {
            item.saleProperties = item.saleProperties.split(';').map(function (row) {
              var specItem = row.split(':');

              if (!arr.includes(specItem[0])) {
                arr.push(specItem[0]);
              }

              if (specData[specItem[0]]) {
                if (!specData[specItem[0]].includes(specItem[1])) {
                  specData[specItem[0]].push(specItem[1]);
                }
              } else {
                specData[specItem[0]] = [];
                specData[specItem[0]].push(specItem[1]);
              }

              return specItem[1];
            });
            item.opType = 1;
            return (0, _objectSpread2.default)({}, item);
          });
          _this.changeSpecList = arr.map(function (item) {
            return {
              label: item,
              value: item,
              list: specData[item]
            };
          });
        } else {
          resData.goodsSkuDTO = [];
          resData.skuStock = res.data.goodsSkuVO[0].skuStock;
          resData.goodsCode = res.data.goodsSkuVO[0].goodsCode;

          _this.formData.forEach(function (item) {
            if (item.value === 'skuStock' || item.value == 'goodsCode') {
              item.isShow = '';
            }
          });
        }

        _this.formData.forEach(function (item) {
          if (item.value === 'memberDiscountPercent' && goodsSpu.isOpenMemberDiscount == 1) {
            item.isShow = '';
          }

          if (item.value === 'shareCommissionFirst' && goodsSpu.isOpenShareCommission == 1) {
            item.isShow = '';
          }

          if (item.value === 'isMultipleSku') {
            item.disabled = true;
          }
        });

        console.log(_this.formData);
        /*
        默认图
        */

        resData.selectCover = goodsSpu.mainImage;
        /*
        商品图
        */

        _this.goodsImageVO = res.data.goodsImageVO;
        resData.goodsImages = res.data.goodsImageVO.map(function (item) {
          return item.goodsImageUrl;
        });
        resData.firstCategoryId = [goodsSpu.firstCategoryId, goodsSpu.secondCategoryId];

        if (goodsSpu.thirdCategoryId) {
          resData.firstCategoryId.push(goodsSpu.thirdCategoryId);
        }

        resData.merchantCategoryId = goodsSpu.merchantCategoryId < 1 ? 1 : goodsSpu.merchantCategoryId;
        resData.freightTemplateId = goodsSpu.freightTemplateId < 1 ? 1 : goodsSpu.freightTemplateId;
        _this.ruleForm = resData;
        _this.isDetail = true;
      });
    }
  },
  mounted: function mounted() {},
  methods: {
    /*
    分类子集  为空数组处理
    */
    IterationDelateMenuChildren: function IterationDelateMenuChildren(rt) {
      if (rt.length) {
        for (var i in rt) {
          if (rt[i].children.length) {
            this.IterationDelateMenuChildren(rt[i].children);
          } else {
            delete rt[i].children;
          }
        }
      }

      return rt;
    }
  }
};
exports.default = _default;