"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      searchData: [{
        type: 'radio',
        placeholder: '操作类型',
        value: 'oprType',
        select: [{
          label: '撤回',
          value: 1
        }, {
          label: '红冲',
          value: 2
        }]
      }, {
        type: 'input',
        placeholder: '用户手机号码',
        value: 'mobile'
      }, {
        type: 'input',
        placeholder: '用户UID',
        value: 'receiveUid'
      }, {
        type: 'input',
        placeholder: '操作人昵称',
        value: 'oprUname'
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }],
      SRCPREFIX: this.$store.state.user.imgSrc,
      memberType: {
        /* 1，普通 2 ,365 3,一星 4，二星 5三星*/
        1: '普通',
        2: '365会员',
        3: '一星Lavant',
        4: '二星Lavant',
        5: '三星Lavant'
      }
    };
  }
};
exports.default = _default;