var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增/编辑日常签到奖励 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            disabled: _vm.disabled,
            "label-width": "120px",
            rules: _vm.rules,
            size: "mini",
            model: _vm.form
          }
        },
        [
          _vm.form.status < 2
            ? _c(
                "el-form-item",
                { attrs: { label: "状态：", prop: "status" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [_vm._v("禁用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [_vm._v("启用")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "积分：", prop: "score" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { type: "number", placeholder: "请输入积分" },
                  model: {
                    value: _vm.form.score,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "score",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.score"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("积分")])],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "最小天数：", prop: "minDays" } },
            [
              _c("el-input-number", {
                attrs: { controls: false, min: 1, step: 1 },
                on: { change: _vm.changeMinDays },
                model: {
                  value: _vm.form.minDays,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "minDays", $$v)
                  },
                  expression: "form.minDays"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "最大天数：", prop: "maxDays" } },
            [
              _c("el-input-number", {
                attrs: {
                  controls: false,
                  min: Number(_vm.form.minDays + 1),
                  step: 1
                },
                model: {
                  value: _vm.form.maxDays,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "maxDays", $$v)
                  },
                  expression: "form.maxDays"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "max-width": "200px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }