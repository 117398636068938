"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkUserInActivity = checkUserInActivity;
exports.goRecharge = goRecharge;

var _request = _interopRequireDefault(require("@/utils/request"));

function checkUserInActivity(data) {
  return (0, _request.default)({
    url: '/api/lx/lx-store-card-charge/checkUserInActivity',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function goRecharge(data) {
  return (0, _request.default)({
    url: '/api/lx/lx-store-card-charge/storeCardActivityChargeFromAdmin',
    method: 'post',
    loading: 'loading',
    data: data
  });
}