"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _member = require("@/api/member.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      form: {
        expressName: '',
        expressNo: ''
      },
      popShow: false,
      row: {},
      list: [],
      total: 0,
      time: '',
      obj: {
        pageNo: 1,
        pageSize: 10,
        goodsName: '',
        goodsCode: '',
        orderUserMobile: '',
        orderNo: '',
        startTime: '',
        endTime: ''
      },
      tabTh: [{
        prop: 'goodsCode',
        label: '优惠券编码',
        width: 100
      }, {
        prop: 'goodsName',
        label: '优惠券名称',
        width: 100
      }, {
        prop: 'goodsNumber',
        label: '数量',
        width: 40
      }, {
        prop: 'orderUserName',
        label: '收货人',
        width: 50
      }, {
        prop: 'orderUserMobile',
        label: '收货人手机号',
        width: 80
      }, {
        prop: 'orderUserAddress',
        label: '收货地址',
        width: 130
      }, {
        prop: 'orderNo',
        label: '订单号',
        width: 80
      }, {
        prop: 'orderCreateTime',
        label: '下单时间',
        width: 110
      }, {
        prop: 'expressName',
        label: '物流公司',
        width: 80
      }, {
        prop: 'expressNo',
        label: '物流单号',
        width: 80
      }, {
        prop: 'orderStatusName',
        label: '状态',
        width: 50
      }]
    };
  },
  computed: {
    btnStatus: function btnStatus() {
      if (this.form.expressName && this.form.expressNo) {
        return true;
      } else {
        return false;
      }
    }
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this = this;

      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['优惠券编码', '优惠券名称', '数量', '收货人', '收货人手机号', '收货地址', '订单号', '下单时间', '物流公司', '物流单号', '状态'];
        var filterVal = ['goodsCode', 'goodsName', 'goodsNumber', 'orderUserName', 'orderUserMobile', 'orderUserAddress', 'orderNo', 'orderCreateTime', 'expressName', 'expressNo', 'orderStatusName'];
        var list = [];
        var data = JSON.parse(JSON.stringify(_this.obj));
        data.startTime = _this.time[0] || '';
        data.endTime = _this.time[1] || '';
        data.pageSize = _this.total;
        _this.downloadLoading = true;
        (0, _member.listOrderHomePage)(data).then(function (res) {
          if (!_this.setMenuPerms('user_mobile_idCard')) {
            res.data.items.forEach(function (item) {
              item.orderUserMobile = item.orderUserMobile.replace(item.orderUserMobile.substring(3, 7), "****");
            });
          }

          list = res.data.items;

          var data = _this.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '礼品到家'
          });
          _this.downloadLoading = false;
        }).catch(function (res) {
          _this.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setTab: function setTab(row) {
      this.row = row;
      this.popShow = true;
    },
    subForm: function subForm() {
      var _this2 = this;

      var data = this.form;
      data.orderId = this.row.orderId;
      this.openLoading();
      (0, _member.updateExpressStatus)(data).then(function (res) {
        _this2.openLoading().close();

        console.log(res);
        _this2.popShow = false;

        _this2.init();
      }).catch(function (err) {
        _this2.openLoading().close();
      });
    },
    init: function init(type) {
      var _this3 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
        this.time = '';
      }

      this.obj.startTime = this.time[0] || '';
      this.obj.endTime = this.time[1] || '';
      this.openLoading();
      (0, _member.listOrderHomePage)(this.obj).then(function (res) {
        _this3.openLoading().close();

        if (!_this3.setMenuPerms('user_mobile_idCard')) {
          res.data.items.forEach(function (item) {
            item.orderUserMobile = item.orderUserMobile.replace(item.orderUserMobile.substring(3, 7), "****");
          });
        }

        _this3.list = res.data.items;
        _this3.total = res.data.totalNum;
      }).catch(function (err) {
        _this3.openLoading().close();
      });
    }
  }
};
exports.default = _default;