"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '团购方',
        prop: 'buyer',
        width: 60
      }, {
        label: '团购时间',
        prop: 'tradeTime',
        width: 60
      }, {
        label: "商品数量",
        prop: 'productNum',
        width: 60
      }, {
        label: "销售价合计(元)",
        prop: 'saleAmount',
        width: 60
      }, {
        label: '结算价合计(元)',
        prop: 'settlementAmount',
        width: 60
      }, {
        slot: 'status',
        label: '状态(1:正常，2:作废)',
        prop: 'status',
        width: 60
      },
      /* {
         label:"合计金额",
         prop: 'settlementAmount',
         width: 60
       }, */
      {
        label: '录入人员',
        prop: 'createUserName',
        width: 60
      }, {
        label: '录入人员手机号',
        prop: 'createUserPhone',
        width: 60
      }, {
        label: '备注',
        prop: 'remark',
        align: 'left',
        width: 60
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 60
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '团购人',
        value: 'buyer'
      }, {
        type: 'dateTime',
        placeholder: '团购开始时间',
        value: 'tradeStartTime' //dateType:'date'

      }, {
        type: 'dateTime',
        placeholder: '团购结束时间',
        value: 'tradeEndTime',
        defaultTime: '23:59:59' // dateType:'date'

      }, {
        type: 'input',
        span: 4,
        placeholder: '录入人名称',
        value: 'createUserName'
      }, {
        type: 'input',
        span: 4,
        placeholder: '录入人手机号',
        value: 'createUserPhone'
      }]
    };
  },
  methods: {
    goAudit: function goAudit(row, status) {
      var _this = this;

      this.apiPost('/api/smsTask/editSmsTask', {
        id: row.id,
        examStatus: status
      }).then(function (res) {
        _this.$refs.wTable.getList();
      });
    },
    goStop: function goStop(row) {
      var _this2 = this;

      this.apiPost('/api/smsTask/editSmsTask', {
        id: row.id,
        executeStatus: 3
      }).then(function (res) {
        _this2.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;