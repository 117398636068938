"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    coupon: _getCoupon.default,
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      couponShow: false,
      typePops: {
        1: 'coupon',
        2: 'card',
        3: 'goods'
      },
      typeData: {
        1: '优惠券',
        2: '购物卡',
        3: '积分商品'
      },
      formData: [{
        label: '商品类型：',
        type: 'radio',
        placeholder: '请选择商品类型',
        value: 'type',
        selectList: [{
          label: '优惠券',
          value: 1
        }, {
          label: '购物卡',
          value: 2
        }, {
          label: '积分商品',
          value: 3
        }]
      }, {
        slot: 'codeList'
      }, {
        label: '排序：',
        type: 'inputNumber',
        placeholder: '请输入排序',
        value: 'sort'
      }],
      ruleForm: {
        type: 1,
        codeList: [],
        merchantId: null
      },
      rules: {
        sort: [{
          required: true,
          message: '请输入排序'
        }],
        type: [{
          required: true,
          message: '请选择商品类型'
        }],
        codeList: [{
          required: true,
          message: '请选择商品'
        }]
      }
    };
  },
  created: function created() {
    this.ruleForm.merchantId = Number(this.$route.query.id);
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(data) {
      var whiteList = [];
      data.results.forEach(function (col) {
        if (col['编码']) {
          whiteList.push(String(col['编码']));
        }
      });
      this.ruleForm.codeList = whiteList;
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    changeForm: function changeForm() {
      this.ruleForm.codeList = [];
    },
    closeCouponPop: function closeCouponPop() {
      this.couponShow = false;
    },
    setCoupons: function setCoupons(e) {
      var _this = this;

      var arr = [];

      if (e.length > 0) {
        arr = e.map(function (item) {
          if (_this.ruleForm.type != 3) {
            return item.uuid;
          } else {
            return item.productCode;
          }
        });
      }

      this.ruleForm.codeList = arr;
      this.couponShow = false;
    },
    getRelevances: function getRelevances() {
      var _this2 = this;

      this.couponShow = true;
      var arr = [];

      if (this.ruleForm.codeList.length > 0) {
        this.ruleForm.codeList.forEach(function (item) {
          arr.push({
            linkId: item
          });
        });
      }

      this.$nextTick(function () {
        _this2.$refs.couponPop.obj.productSource = 1;

        _this2.$refs.couponPop.init();

        _this2.$refs.couponPop.assignCoupon(arr);
      });
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.apiPost('/api/third/party/merchant/addThirdPartyMerchantGoods', this.ruleForm).then(function (res) {
        _this3.$router.go(-1);

        _this3.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;