"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _option = _interopRequireDefault(require("./option"));

// 显示 options.cardTypeState.getLabel(1)
// value值 options.cardTypeState.ENABLE.value
var _default = {
  // 卡类型状态
  cardTypeState: new _option.default().add('ENABLE', '启用', 1).add('DISABLE', '禁用', 2),
  // 消费积分
  consumptionPoint: new _option.default().add('ENABLE', '不支持积分', 1).add('DISABLE', '支持积分', 2),
  // 记名类型
  registeredType: new _option.default().add('ENABLE', '不记名卡', 1).add('DISABLE', '记名卡', 2),
  // 销售用途
  salesPurpose: new _option.default().add('sale', '销售卡', 1).add('activity', '活动卡', 2),
  // 卡种状态
  cardClassState: new _option.default().add('ENABLE', '启用', 1).add('DISABLE', '停用', 2) // https://evestorm.github.io/posts/56971/

};
exports.default = _default;