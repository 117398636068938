var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-manager-detail" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("售后详情")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c("Descriptions", {
                attrs: {
                  title: "基本信息",
                  columns: _vm.columns,
                  "data-source": _vm.manager
                }
              }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "售后编号"
                      }
                    },
                    [
                      _c("a", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.refundSn) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "关联预约单"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "svip-manager-detail_itemName",
                          staticStyle: { color: "#36a3f7" },
                          on: {
                            click: function($event) {
                              return _vm.gotoBooking(_vm.manager)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.manager.orderSn) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "会员手机号"
                      }
                    },
                    [
                      _c("a", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.mobile) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "预约要客"
                      }
                    },
                    [
                      _c("a", { staticClass: "svip-manager-detail_itemName" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.manager.realname) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "预约类型"
                      }
                    },
                    [
                      _vm.manager.type === 1
                        ? _c("div", [_vm._v("服务预约")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.manager.type === 2
                        ? _c("div", [_vm._v("场地预约")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.manager.type === 3
                        ? _c("div", [_vm._v("活动预约")])
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: { "label-class-name": "my-label", label: "名称" }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "svip-manager-detail_itemName",
                          staticStyle: { color: "#36a3f7" },
                          on: {
                            click: function($event) {
                              return _vm.goto(_vm.manager)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.manager.itemName) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        "label-class-name": "my-label",
                        label: "售后状态"
                      }
                    },
                    [
                      _vm.manager.status === 1
                        ? _c("div", [_vm._v("待处理")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.manager.status === 2
                        ? _c("div", [_vm._v("已处理，同意取消预约")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.manager.status === 3
                        ? _c("div", [_vm._v("已处理，拒绝取消预约")])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("Descriptions", { attrs: { title: "信息变更记录" } }),
              _vm._v(" "),
              _c(
                "el-timeline",
                _vm._l(_vm.logList, function(log, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: index,
                      attrs: {
                        timestamp: log.createTime,
                        color: "rgb(0, 153, 255)"
                      }
                    },
                    [
                      _c("h4", [_vm._v(_vm._s(log.eventName))]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "svip-manager-detail_event-name" },
                        [_vm._v(_vm._s(log.content))]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.status === 1
        ? _c(
            "div",
            { staticClass: "svip-manager-detail_footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.reasonOpen("reject", _vm.manager.refundSn)
                    }
                  }
                },
                [_vm._v("\n      同意退款\n    ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.reasonOpen("refuse", _vm.manager.refundSn)
                    }
                  }
                },
                [_vm._v("\n      拒绝退款\n    ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.reasonVisible, width: "350px" },
          on: {
            "update:visible": function($event) {
              _vm.reasonVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "reasonForm", attrs: { model: _vm.reasonForm } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "reason",
                    rules: [
                      { required: true, message: "请输入原因", trigger: "blur" }
                    ]
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: {
                      placeholder: "请输入原因",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.reasonForm.reason,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.reasonForm,
                          "reason",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "reasonForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.reasonVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.reject("reasonForm")
                    }
                  }
                },
                [_vm._v("\n        确 定\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }