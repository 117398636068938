"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _shopCard = require("@/api/shopCard");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      params: {
        id: undefined,
        categoryName: undefined,
        status: undefined,
        ifIntegral: undefined,
        purpose: undefined,
        registeredType: undefined,
        segmentNo: undefined,
        remark: undefined
      },
      rules: {
        categoryName: [{
          required: true,
          message: '请输入卡类型名称',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }],
        ifIntegral: [{
          required: true,
          message: '请选择消费积分',
          trigger: 'change'
        }],
        purpose: [{
          required: true,
          message: '请选择销售用途',
          trigger: 'change'
        }],
        registeredType: [{
          required: true,
          message: '请选择记名类型',
          trigger: 'change'
        }],
        segmentNo: [{
          required: true,
          message: '请输入卡号段',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.params.id = this.$route.query.cardTypeId;
    this.getCardType();
  },
  methods: {
    onConfirm: function onConfirm() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          delete _this.params.createTime;
          (0, _shopCard.cardTypeSave)(_this.params).then(function () {
            _this.$message.success("保存成功");

            _this.onCancel();
          });
        }
      });
    },
    onCancel: function onCancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push("/shopCard/type");
    },
    getCardType: function getCardType() {
      var _this2 = this;

      if (!this.params.id) return;
      (0, _shopCard.cardTypeDetail)(this.params.id).then(function (res) {
        _this2.params = res.data;
      });
    }
  }
};
exports.default = _default;