var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("方案详情")]),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { "label-width": "140px" } },
        [
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "优惠券编码：" } },
            [_vm._v("\n      " + _vm._s(_vm.data.couponCode) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "优惠券名称：" } },
            [_vm._v("\n      " + _vm._s(_vm.data.couponName) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "优惠券类型：" } },
            [_vm._v("\n      " + _vm._s(_vm.data.couponType) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "投放时间：" } },
            [
              _vm.data.onlineType == 1
                ? _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.data.onlineStartTime +
                            "-" +
                            _vm.data.onlineEndTime
                        ) +
                        "\n      "
                    )
                  ])
                : _c("p", [_vm._v("立即上线")])
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "投放数量：" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.data.putNumber == 0 ? "不限量" : _vm.data.putNumber
                  ) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "优惠券标签：" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.setLabel }
                },
                [_vm._v("编辑")]
              ),
              _vm._v(" "),
              _vm._l(_vm.data.recenterLabels, function(item, index) {
                return _c(
                  "el-button",
                  {
                    key: index,
                    attrs: { type: "primary", size: "mini", plain: "" }
                  },
                  [_vm._v(_vm._s(item.labelName))]
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("labelPop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.labelShow,
            expression: "labelShow"
          }
        ],
        ref: "labels",
        attrs: { "recenter-labels": _vm.data.recenterLabels },
        on: { addLabel: _vm.addLabel, close: _vm.closeLabel }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }