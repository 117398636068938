"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _table = _interopRequireDefault(require("@/components/tableList/table.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tables: _table.default
  },
  data: function data() {
    return {
      list: [1, 2, 3],
      total: 1,
      btnText: '编辑',
      obj: {
        pageNo: 1,
        pageSize: 10
      },
      tabTh: [{
        prop: '',
        label: '等级名称',
        width: 100
      }, {
        prop: '',
        label: '等级ID',
        width: 100
      }, {
        prop: '',
        label: '成长值（达到值）',
        width: 100
      }, {
        prop: '',
        label: '保级值',
        width: 100
      }, {
        prop: '',
        label: '权益总数',
        width: 100
      }]
    };
  },
  methods: {
    goAdd: function goAdd() {
      this.$router.push('/member/equity/gradeAdd');
    },
    setTab: function setTab(row, index) {
      this.$router.push('/member/equity/gradeAdd?id=' + row.id);
    },
    init: function init() {}
  }
};
exports.default = _default;