var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("付费会员权益配置")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c("el-form-item", { attrs: { label: "会员类型名称：" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.memberData.payMemberName) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "会员权益：", prop: "ids" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.ruleForm.ids,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "ids", $$v)
                        },
                        expression: "ruleForm.ids"
                      }
                    },
                    _vm._l(_vm.equityList, function(item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          staticStyle: { "min-width": "300px" },
                          attrs: { label: item.id }
                        },
                        [
                          _vm._v(
                            _vm._s(item.rightName) +
                              "(" +
                              _vm._s(item.rightShort) +
                              ")\n\n          "
                          ),
                          item.status === 0
                            ? _c("el-tag", { attrs: { type: "warning" } }, [
                                _vm._v("待审核")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.status === 1
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v("启用")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.status === 2
                            ? _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("停用")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.status === 3
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v("已审核")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开卡礼权益：", prop: "openIds" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.ruleForm.openIds,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "openIds", $$v)
                        },
                        expression: "ruleForm.openIds"
                      }
                    },
                    _vm._l(_vm.openEquityList, function(item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          staticStyle: { "min-width": "300px" },
                          attrs: { label: item.id },
                          on: {
                            change: function($event) {
                              return _vm.changOpenId($event, item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.rightName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("保存权益")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }