"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      downloadLoading: false,
      obj: {
        pageNo: 1,
        pageSize: 10,
        drawCode: '',
        prizeCode: null,
        startTime: '',
        endTime: '',
        type: ''
      },
      time: '',
      storeList: [],
      list: [],
      total: 0,
      tabTh: [{
        prop: 'drawCode',
        label: '活动编码',
        width: 100
      }, {
        prop: 'drawUid',
        label: '抽奖人UID',
        width: 100
      }, {
        prop: 'typeTxt',
        label: '奖励类型',
        width: 100
      }, {
        prop: 'prizeCode',
        label: '奖励编码',
        width: 100
      }, {
        prop: 'result',
        label: '抽奖结果',
        width: 100
      }, {
        prop: 'drawTime',
        label: '抽奖时间',
        width: 60
      }]
    };
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this = this;

      if (type === 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type === 'remove') {
        this.time = '';
        this.removeObj(this.obj);
      }

      if (this.time.length > 0) {
        this.obj.startTime = this.time[0] || '';
        this.obj.endTime = this.time[1] || '';
      }

      this.openLoading();
      var data = {};
      Object.keys(this.obj).forEach(function (key) {
        if (_this.obj[key] != '') {
          data[key] = _this.obj[key];
        }
      });
      (0, _api.lotteryLogList)(data).then(function (res) {
        _this.openLoading().close();

        _this.total = res.data.totalNum || 0;
        _this.list = res.data.items;

        _this.list.forEach(function (item) {
          switch (item.type) {
            case 1:
              item.typeTxt = '优惠券';
              break;

            case 2:
              item.typeTxt = '商品';
              break;

            case 3:
              item.typeTxt = '谢谢参与';
              break;

            case 4:
              item.typeTxt = '积分';
              break;

            case 5:
              item.typeTxt = '乐享值';
              break;

            default:
              break;
          }
        });
      }).catch(function () {
        _this.openLoading().close();
      });
    },
    goAdd: function goAdd(url) {
      this.$router.push(url);
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['活动编码', '抽奖人UID', '奖励类型', '抽奖结果', '抽奖时间'];
        var filterVal = ['drawCode', 'drawUid', 'typeTxt', 'result', 'drawTime'];
        var list = [];

        if (_this2.time.length > 0) {
          _this2.obj.startTime = _this2.time[0] || '';
          _this2.obj.endTime = _this2.time[1] || '';
        }

        var data = {}; // Object.assign({}, this.obj);

        Object.keys(_this2.obj).forEach(function (key) {
          if (_this2.obj[key]) {
            data[key] = _this2.obj[key];
          }
        });
        data.pageSize = _this2.total;
        data.pageNo = 1;

        _this2.openLoading();

        (0, _api.lotteryLogList)(data).then(function (res) {
          list = res.data.items;
          list.forEach(function (item) {
            switch (item.type) {
              case 1:
                item.typeTxt = '优惠券';
                break;

              case 2:
                item.typeTxt = '商品';
                break;

              case 3:
                item.typeTxt = '谢谢参与';
                break;

              case 4:
                item.typeTxt = '积分';
                break;

              case 5:
                item.typeTxt = '乐享值';
                break;

              default:
                break;
            }
          });

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '抽奖统计'
          });
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        }).catch(function (res) {
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;