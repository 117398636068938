"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/web.dom.iterable");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    addLoad: _addLoad.default
  },
  props: {
    detailRollimg: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      count: ''
    };
  },
  methods: {
    setCover: function setCover(index) {
      this.detailRollimg[this.count].img = index;
      console.log(this.detailRollimg[this.count]);
    },
    setCount: function setCount(index) {
      this.count = index;
    },
    addDetailRollimg: function addDetailRollimg() {
      if (this.detailRollimg.length < 5) {
        this.detailRollimg.push({
          img: '',
          sort: ''
        });
      } else {
        this.$message({
          message: '最多只能添加张图片',
          type: 'warning'
        });
      }
    },
    deleteDetailRollimg: function deleteDetailRollimg(index) {
      this.detailRollimg.splice(index, 1);
    },
    setDetailRollimg: function setDetailRollimg(type) {
      var _this = this;

      var arr = [];
      this.detailRollimg.forEach(function (item, key) {
        if (item.img != '' && item.sort != '') {
          arr.push(item);
        }
      });

      if (arr.length < this.detailRollimg.length && type != 'parent') {
        this.$confirm('只保存详情图和排序都填写的信息,是否继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this.$parent.setDetailRollimg(arr);
        }).catch(function () {// this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        }); // this.$alert('只保存详情图和排序都填写的信息', '提示', {
        //       confirmButtonText: '确定',
        //       callback: action => {
        //           this.$parent.setDetailRollimg(arr)
        //       }
        //  });
      } else {
        this.$parent.setDetailRollimg(arr);
      }

      return arr;
    }
  }
};
exports.default = _default2;