"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _slide = _interopRequireDefault(require("@/components/slide.vue"));

var _api = require("./api.js");

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var weekTag = {
  1: '周一',
  2: '周二',
  3: '周三',
  4: '周四',
  5: '周五',
  6: '周六',
  0: '周日'
};
var _default = {
  components: {
    WTable: _index.default,
    slide: _slide.default
  },
  filters: {
    memberTypeObj: function memberTypeObj(type) {
      var Obj = {
        1: '普通会员',
        2: '要客',
        3: '365'
      };
      return Obj[type];
    }
  },
  data: function data() {
    return {
      orderSource: {
        1: '米卡系统',
        2: '支付宝小程序',
        3: '平安小程序',
        4: '黔农云支付',
        5: '云闪付',
        6: '一码贵州',
        7: '荔星中心',
        12: '第三方商户'
      },
      time: null,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      isRemark: false,
      remark: '',
      refundNum: null,
      refundGoodsNum: null,
      isCard: false,
      orderNo: '',
      goodsNumber: null,
      imgSrc: this.$store.state.user.imgSrc,
      downloadLoading: false,
      requestData: {
        moduleSource: null,
        orderSource: null,
        buyUtphone: '',
        goodsCode: '',
        pageNo: 1,
        orderType: 0,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '订单号',
        prop: 'orderNo',
        width: 120
      }, {
        label: '外部订单号',
        prop: 'outTradeNo',
        width: 120
      }, {
        label: '流水号',
        prop: 'tradeNo',
        width: 80
      }, {
        label: '订单来源平台',
        prop: 'orderSourceTxt',
        width: 120
      }, {
        prop: 'merchantName',
        label: '来源平台名称',
        width: 100
      }, {
        label: '券编码',
        prop: 'goodsCode',
        width: 80
      }, {
        label: '券名称',
        prop: 'goodsName',
        width: 80
      }, {
        label: '购买数量',
        prop: 'goodsNumber',
        width: 80
      }, {
        label: '使用数量',
        prop: 'expiredNum',
        width: 80
      }, {
        label: '会员手机号',
        prop: 'buyUtphone',
        width: 100
      }, {
        label: '会员姓名',
        prop: 'buyUname',
        width: 80
      }, {
        label: '会员名称',
        prop: 'memberName',
        width: 100
      }, {
        slot: 'memberType',
        label: '会员类型',
        prop: 'memberType',
        width: 100
      }, {
        label: '订单总额',
        prop: 'payScoreAmountStr',
        width: 80
      }, {
        label: '订单金额',
        prop: 'payAmount',
        width: 80
      }, {
        label: '订单积分',
        prop: 'payScore',
        width: 80
      }, {
        label: '订单时间',
        prop: 'orderCreateTime',
        width: 80
      }, {
        label: '退款操作人',
        prop: 'institutionName',
        width: 100
      }, {
        label: '退款时间',
        prop: 'refundTime',
        width: 80
      }, {
        label: '已退款数量',
        prop: 'refundGoodsNum',
        width: 100
      }, {
        label: '已退款金额',
        prop: 'refundedAmount',
        width: 100
      }, {
        label: '已退款积分',
        prop: 'refundedScore',
        width: 100
      }, {
        slot: 'facilityCode',
        label: '退券备注',
        prop: 'facilityCode',
        width: 80
      },
      /* <el-option label="待支付" :value="1" />
      <el-option label="已完成" :value="2" />
      <el-option label="已取消" :value="3" />
      <el-option label="全退款" :value="4" />
      <el-option label="部分退款" :value="6" />*/
      {
        slot: 'orderStatus',
        label: '订单状态 1:待支付,2:已完成,3:已取消,4:全退款,6:部分退款',
        prop: 'orderStatus',
        width: 80
      }, {
        label: '周期',
        prop: 'week',
        width: 80
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(value) {
      if (value) {
        this.requestData.startTime = value[0];
        this.requestData.endTime = value[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    },
    isRemark: function isRemark() {
      this.remark = null;
      this.refundNum = null;
      this.refundGoodsNum = null;
    }
  },
  activated: function activated() {// this.requestData.orderSource = null

    /* if (this.setMenuPerms('qnyOrderSelect')) {
       this.requestData.orderSource = 1
       //this.getList()
     } else {
       this.requestData.orderSource = null
     } */
    // this.getList()
  },
  mounted: function mounted() {
    var today = new Date();
    var lastMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    var lastMonthEndDate = (0, _moment.default)(today).format("YYYY-MM-DD  HH:mm:ss");
    var startDateTime = (0, _moment.default)(lastMonthStartDate).format("YYYY-MM-DD  HH:mm:ss");
    this.time = [startDateTime, lastMonthEndDate];
  },
  methods: {
    popAffrim: function popAffrim() {
      if (this.remark) {
        this.isCard = true;
      }
    },
    goUserKpi: function goUserKpi() {
      var _this = this;

      if (this.goodsNumber > 0 && this.goodsNumber >= this.refundNum) {
        // {"orderNo":"orderNo","facilityCode":"退款备注"}
        (0, _api.couponOrderRefund)({
          orderNo: this.orderNo,
          facilityCode: this.remark,
          refundNum: this.refundNum
        }).then(function (res) {
          _this.isRemark = false;

          _this.getList();
        });
      }
    },
    getRefund: function getRefund(row) {
      this.isRemark = true;
      this.$nextTick(function () {
        this.orderNo = row.orderNo;
        this.goodsNumber = row.goodsNumber - (row.expiredNum || 0);
        this.refundGoodsNum = row.refundGoodsNum || 0;
      });
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];
        var data = {};

        _this2.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this2.requestData).forEach(function (key) {
          if (_this2.requestData[key]) {
            data[key] = _this2.requestData[key];
          }
        });
        data.pageSize = _this2.requestData.total;
        data.pageNo = 1;
        data.orderType = 0;
        (0, _api.couponOrderPage)(data).then(function (res) {
          list = res.data.items;
          list.forEach(function (item) {
            if (!_this2.setMenuPerms('user_mobile_idCard')) {
              if (item.buyUtphone) {
                item.buyUtphone = item.buyUtphone.replace(item.buyUtphone.substring(3, 7), "****");
              }
            }

            item.week = weekTag[new Date(item.orderCreateTime).getDay()];
            item.orderSourceTxt = _this2.orderSource[item.orderSource];
          });

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '优惠券订单'
          });
          _this2.downloadLoading = false;
        }).catch(function (res) {
          _this2.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else if (j === 'memberType') {
            var Obj = {
              1: '普通会员',
              2: '要客',
              3: '365'
            };
            return Obj[v[j]];
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = [];
        this.removeObj(this.requestData);
      }

      (0, _api.couponOrderPage)(this.requestData).then(function (res) {
        res.data.items.forEach(function (item) {
          if (!_this3.setMenuPerms('user_mobile_idCard')) {
            if (item.buyUtphone) {
              item.buyUtphone = item.buyUtphone.replace(item.buyUtphone.substring(3, 7), "****");
            }
          }

          item.week = weekTag[new Date(item.orderCreateTime).getDay()];
          item.orderSourceTxt = _this3.orderSource[item.orderSource];
        });
        _this3.tableData = res.data.items;
        _this3.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;