"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

var _api = require("../api.js");

var _api2 = require("./api.js");

var _api3 = require("../category/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default,
    SelectTabel: _getCoupon.default
  },
  data: function data() {
    return {
      isShowSelect: false,
      columns: [{
        label: '卡种名称',
        prop: 'cardKindsName',
        width: 80
      }, {
        label: '卡类型',
        prop: 'cardCategoryName',
        width: 70
      }, {
        slot: 'isRebate',
        label: '返利类型',
        prop: 'isRebate',
        width: 80
      }, {
        label: '激活后有效期（年）',
        prop: 'expireTradeYear',
        width: 80
      }, {
        slot: 'cardEndNo',
        label: '可选卡号区间',
        prop: 'cardEndNo'
      }, {
        slot: 'cardSegmentNoStart',
        label: '起始卡号',
        prop: 'cardSegmentNoStart'
      }, {
        slot: 'buyNum',
        label: '数量',
        prop: 'buyNum'
      }, {
        slot: 'cardSegmentNoEnd',
        label: '结束卡号',
        prop: 'cardSegmentNoEnd'
      }, {
        slot: 'cardLimitPrice',
        label: '卡片面额（元）',
        prop: 'cardLimitPrice',
        width: 80
      }, {
        slot: 'cardLimitPriceNum',
        label: '小计（元）',
        prop: 'cardLimitPrice'
      }, {
        slot: 'operate'
      }],
      storeList: [],
      rules: {
        paramList: [{
          required: true,
          message: '请选择购物卡'
        }],
        'buyRecordsOrder.buyUser': [{
          required: true,
          message: '请输入购买人'
        }],
        'buyRecordsOrder.isRebate': [{
          required: true,
          message: '请选择是否返利'
        }],
        'buyRecordsOrder.phone': [{
          required: true,
          message: '请输入购买人联系方式'
        }],
        'recordsOrderDescribe.description': [{
          required: true,
          message: '请输入购买说明'
        }]
      },
      ruleForm: {
        paramList: [],
        buyRecordsOrder: {
          buyUser: '',
          phone: ''
        },
        recordsOrderDescribe: {
          description: ''
        }
      }
    };
  },
  computed: {
    disabledArr: function disabledArr() {
      var arr = [];
      this.ruleForm.paramList.forEach(function (item) {
        arr.push(item.cardKindsId);
      });
      return arr;
    },
    allNum: function allNum() {
      var price = 0;
      this.ruleForm.paramList.forEach(function (item) {
        price = Number(item.buyNum) + price;
      });
      return price;
    },
    allAmount: function allAmount() {
      var price = 0;
      this.ruleForm.paramList.forEach(function (item) {
        price = item.buyNum * item.cardLimitPrice + price;
      });
      return price;
    }
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;
    (0, _api3.categoryPage)({
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      _this.storeList = res.data.records;
    });

    if (this.ruleForm.id) {
      (0, _api2.getKinds)(this.ruleForm.id).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    goAudit: function goAudit(index) {
      this.ruleForm.paramList.splice(index, 1);
    },
    changNum: function changNum(e, scope) {
      var _this2 = this;

      this.ruleForm.paramList[scope.$index].cardSegmentNoEnd = null;
      (0, _api.calculateEndCardNo)({
        storeId: scope.row.storeId,
        startCardNo: scope.row.cardSegmentNoStart,
        id: scope.row.cardKindsId,
        model: 'CARD_STORE_SALE',
        num: e
      }).then(function (res) {
        _this2.ruleForm.paramList[scope.$index].ignoreCards = res.data.ignoreCardNos;
        _this2.ruleForm.paramList[scope.$index].cardSegmentNoEnd = res.data.endCardNo;
      });
      return;
      this.ruleForm.paramList[scope.$index].cardSegmentNoEnd = this.bigNumberAdd(String(this.ruleForm.paramList[scope.$index].cardSegmentNoStart), String(e - 1));
    },
    validateAcquaintance: function validateAcquaintance(rule, value, callback) {
      if (value < 0 || value > 100) {
        callback(new Error('在 0 至 100 %之间'));
      } else {
        callback();
      }
    },
    cardNoEndChange: function cardNoEndChange(e, scope) {
      var _this3 = this;

      console.log(e);
      this.$nextTick(function () {
        if (e < scope.row.cardSegmentNoStart) {
          _this3.ruleForm.paramList[scope.$index].cardSegmentNoEnd = scope.row.cardSegmentNoStart;
        } else if (e > scope.row.cardEndNo) {
          _this3.ruleForm.paramList[scope.$index].cardSegmentNoEnd = scope.row.cardEndNo;
        }
      });
      console.log(scope.row.cardSegmentNoStart);
    },
    cardNoStartChange: function cardNoStartChange(e, scope) {
      var _this4 = this;

      this.$nextTick(function () {
        if (e < scope.row.cardNo) {
          _this4.ruleForm.paramList[scope.$index].cardSegmentNoStart = scope.row.cardNo;
        } else if (e > scope.row.cardEndNo) {
          _this4.ruleForm.paramList[scope.$index].cardSegmentNoStart = scope.row.cardEndNo;
        }

        console.log(_this4.ruleForm.paramList[scope.$index]);
      });
    },
    setTabelData: function setTabelData(e) {
      var _this5 = this;

      if (e.length > 0) {
        var cardCategoryIds = [];
        var cardKindsIds = [];
        e.forEach(function (item) {
          cardCategoryIds.push(item.cardCategoryId);
          cardKindsIds.push(item.cardKindsId);
        });
        (0, _api2.startCard)({
          cardCategoryIds: cardCategoryIds,
          cardKindsIds: cardKindsIds
        }).then(function (res) {
          e.forEach(function (item, key) {
            _this5.ruleForm.paramList.push({
              uniqueCode: item.uniqueCode,
              description: item.description,
              cardKindsId: item.cardKindsId,
              cardCategoryName: item.cardCategoryName,
              cardKindsName: item.cardKindsName,
              cardCategoryId: item.cardCategoryId,
              cardLimitPrice: item.cardLimitPrice || null,
              isChangeNum: item.cardLimitPrice > 0,
              cardSegmentNoStart: res.data[key].cardNo,
              cardSegmentNoEnd: '',
              cardNo: res.data[key].cardNo,
              cardEndNo: res.data[key].cardEndNo,
              buyNum: 1,
              isRebate: item.isRebate,
              expireTradeYear: item.expireTradeYear,
              storeId: res.data[key].storeId,
              ignoreCards: []
            });
          });

          _this5.$nextTick(function () {
            _this5.submit('paramList');
          });
        });
      }

      this.isShowSelect = false;
      return;
    },
    submit: function submit(type) {
      this.$refs['ruleForm'].validateField(type);
    },
    closeSelect: function closeSelect() {
      this.isShowSelect = false;
    },
    getRelevances: function getRelevances() {
      var _this6 = this;

      this.isShowSelect = true;
      this.$nextTick(function () {
        _this6.$refs.selectPop.init();
      });
    },
    submitForm: function submitForm(formName) {
      var _this7 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this7.ruleForm));

          if (_this7.ruleForm.id) {
            data.id = _this7.ruleForm.id;
            (0, _api2.editKinds)(data).then(function (res) {
              _this7.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this7.$router.replace('/card/shop');
            });
          } else {
            (0, _api2.addKinds)(data).then(function (res) {
              _this7.$router.replace('/card/shop');

              _this7.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;