"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCouponBag = addCouponBag;
exports.couponBagOrderPage = couponBagOrderPage;
exports.couponBagRefund = couponBagRefund;
exports.deleteById = deleteById;
exports.editCouponBag = editCouponBag;
exports.editCouponBagStatu = editCouponBagStatu;
exports.getCouponBagById = getCouponBagById;
exports.listCouponBagPage = listCouponBagPage;
exports.superadditionById = superadditionById;

var _request = _interopRequireDefault(require("@/utils/request"));

function couponBagRefund(data) {
  return (0, _request.default)({
    url: '/api/couponBag/order/refund',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function couponBagOrderPage(data) {
  return (0, _request.default)({
    url: '/api/order/couponBagOrderPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
 */


function listCouponBagPage(data) {
  return (0, _request.default)({
    url: '/api/couponBag/listCouponBagPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addCouponBag(data) {
  return (0, _request.default)({
    url: '/api/couponBag/addCouponBag',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editCouponBag(data) {
  return (0, _request.default)({
    url: '/api/couponBag/editCouponBag',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getCouponBagById(data) {
  return (0, _request.default)({
    url: '/api/couponBag/getCouponBagById',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function deleteById(data) {
  return (0, _request.default)({
    url: '/api/couponBag/deleteById',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function editCouponBagStatu(data) {
  return (0, _request.default)({
    url: '/api/couponBag/editCouponBagStatu',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function superadditionById(data) {
  return (0, _request.default)({
    url: '/api/couponBag/superadditionById',
    method: 'post',
    loading: 'loading',
    data: data
  });
}