var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pop_box" }, [
    _c(
      "div",
      { staticClass: "box" },
      [
        _c("Tinymce", {
          ref: "editor",
          attrs: { width: 600, height: 300 },
          on: { onChange: _vm.setGz },
          model: {
            value: _vm.equitiesDesc,
            callback: function($$v) {
              _vm.equitiesDesc = $$v
            },
            expression: "equitiesDesc"
          }
        }),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function($event) {
                return _vm.submitEquitiesDescs()
              }
            }
          },
          [_vm._v("提交")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }