"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.to-string");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _report = require("@/api/report.js");

var _manage = require("@/api/manage.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
function sortDownDate(a, b) {
  return Date.parse(a.props) - Date.parse(b.props);
}

var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    var _this = this;

    return {
      selectDate: '',
      // 日期选择范围在一个月内
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
              minDate = _ref.minDate;
          _this.selectDate = minDate.getTime();

          if (maxDate) {
            _this.selectDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.selectDate !== '') {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.selectDate - one;
            var maxTime = _this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      downloadLoading: false,
      total: 0,
      time: [],
      tableData: [],
      obj: {
        memberId: '',
        storedId: '',
        type: 0
      },
      tabTh: [],
      memArr: [{
        cardId: 1,
        name: '乐品鲜活'
      }, {
        cardId: 2,
        name: '遵义星力城C馆'
      }, {
        cardId: 3,
        name: '星力百货瑞金店'
      }, {
        cardId: 5,
        name: '荔星名店'
      }, {
        cardId: 6,
        name: '汇安购物中心'
      }, {
        cardId: 7,
        name: '汇金星力城'
      }, {
        cardId: 8,
        name: '遵义星力城A馆'
      }, {
        cardId: 9,
        name: '集团内部'
      }, {
        cardId: 10,
        name: '汇安星力百货'
      }, {
        cardId: 11,
        name: '乐品便利'
      }, {
        cardId: 12,
        name: '兴义汇金星力城'
      }, {
        cardId: 13,
        name: '都匀星力城'
      }, {
        cardId: 18,
        name: '凯里星力城'
      }, {
        cardId: 19,
        name: '美住·家居'
      }, {
        cardId: 20,
        name: '铜仁江华星力城'
      }, {
        cardId: 21,
        name: '乐购总店'
      }, {
        cardId: 22,
        name: '意外·全球购'
      }],
      options: [{
        value: 0,
        label: '全部活动'
      }, {
        value: 1,
        label: '二维码'
      }, {
        value: 2,
        label: '公众号关注后弹出小程序卡片 '
      }, {
        value: 3,
        label: '公众号底部菜单入口 '
      }, {
        value: 4,
        label: '优惠券详情页 '
      }, {
        value: 5,
        label: '普通活动页面 '
      }, {
        value: 6,
        label: '老带新活动'
      }, {
        value: 7,
        label: '面对面发券  '
      }, {
        value: 8,
        label: '砍价活动 '
      }, {
        value: 9,
        label: '秒杀活动  '
      }, {
        value: 10,
        label: '拼团活动 '
      }, {
        value: 11,
        label: '投票活动 '
      }, {
        value: 12,
        label: '抽奖活动 '
      }, {
        value: 13,
        label: '五鼠聚 '
      }, {
        value: 23,
        label: '乐购店铺小程序码 '
      }, {
        value: 24,
        label: '商品分享购买（包含秒杀，拼团）'
      }, {
        value: 25,
        label: '券详情分享 '
      }, {
        value: 26,
        label: '商家优惠券领取 '
      }, {
        value: 27,
        label: '直播间 '
      }, {
        value: 30,
        label: '贵州通'
      }]
    };
  },

  /*
    13：五鼠聚 23：乐购店铺小程序码 24：商品分享购买（包含秒杀，拼团）25：券详情分享 26：商家优惠券领取 27：直播间*/
  created: function created() {
    return;

    if (!this.time) {
      var getDay = function getDay(day) {
        var today = new Date();
        var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
        today.setTime(targetday_milliseconds); // 注意，这行是关键代码

        var tYear = today.getFullYear();
        var tMonth = today.getMonth();
        var tDate = today.getDate();
        tMonth = doHandleMonth(tMonth + 1);
        tDate = doHandleMonth(tDate);
        return tYear + '-' + tMonth + '-' + tDate;
      };

      var doHandleMonth = function doHandleMonth(month) {
        var m = month;

        if (month.toString().length == 1) {
          m = '0' + month;
        }

        return m;
      };
    }
    /* listMemberCards().then((res) => {
        this.init()
        this.memArr = this.memArr.concat(res.data)
      }).catch((res) => {
        this.openLoading().close()
      }) */

  },
  activated: function activated() {
    this.$router.replace('/member/pullNew');
    return;
    this.init();
  },
  methods: {
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });

        if (column.property == 'storedName' || column.property == 'storedId') {
          sums[index] = '——';
        } else {
          if (!values.every(function (value) {
            return isNaN(value);
          })) {
            sums[index] = values.reduce(function (prev, curr) {
              var value = Number(curr);

              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0); // sums[index];
          } else {
            sums[index] = 'N/A';
          }
        }
      });
      return sums;
    },
    remove: function remove() {
      this.obj = {};
      this.time = [];
      this.init();
    },

    /* 下载*/
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      var tHeader = ['门店', '门店编码'];
      var filterVal = ['storedName', 'storedId'];
      this.tabTh.forEach(function (item) {
        tHeader.push(item.tit);
        filterVal.push(item.tit);
      });
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var list = [];
        list = _this2.tableData;

        var data = _this2.formatJson(filterVal, list);

        excel.export_json_to_excel({
          header: tHeader,
          data: data,
          filename: '拉新统计'
        });
        _this2.downloadLoading = false;
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },

    /*
      求和
      */
    setSum: function setSum() {
      var sum = 0;
      this.tableData.forEach(function (item) {});
    },

    /*
      数据列表
      */
    init: function init() {
      var _this3 = this;

      this.openLoading();
      this.obj.startTime = this.time[0] || '';
      this.obj.endTime = this.time[1] || '';
      (0, _report.unlineLogDsReport)(this.obj).then(function (res) {
        _this3.openLoading().close();

        console.log(res);
        _this3.tableData = res.data;
        _this3.tabTh = [];
        var arr = [];

        _this3.tableData.forEach(function (item) {
          JSON.parse(item.dsJson).forEach(function (row) {
            var obj = {
              tit: row.ds,
              props: row.ds
            };
            arr.push(row.ds);

            var result = _this3.tabTh.some(function (item) {
              if (item.tit === row.ds) {
                return true;
              }
            });

            if (!result) {
              _this3.tabTh.push(obj);
            }

            _this3.$set(item, row.ds, row.number);
          });
        });

        _this3.tabTh.sort(sortDownDate);
      }).catch(function (res) {
        _this3.openLoading().close();
      });
    }
  }
};
exports.default = _default;