"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _mode = _interopRequireDefault(require("./mode1.vue"));

var _mode2 = _interopRequireDefault(require("./mode2.vue"));

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    mode1: _mode.default,
    mode2: _mode2.default
  },
  data: function data() {
    return {};
  },
  computed: {
    isSale: function isSale() {
      return this.$route.query.isSale == 1;
    }
  },
  methods: {}
};
exports.default = _default;