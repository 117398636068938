var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "box_input" }, [
      _c("ul", [
        _c("li", [
          _c("p", { staticClass: "input_tit" }, [
            _vm._v("\n            优惠券编码：\n          ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-input", {
                staticClass: "input2",
                model: {
                  value: _vm.couponId,
                  callback: function($$v) {
                    _vm.couponId = $$v
                  },
                  expression: "couponId"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "input_txt" }, [
                _vm._v("优惠券编码必须为审核通过且在有效期内")
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "input_tit" }, [
            _vm._v("\n            触发条件：\n          ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "condition" },
            [
              _c(
                "div",
                { staticClass: "condition_radio" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.radio,
                        callback: function($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio"
                      }
                    },
                    [_vm._v("选择上线周期")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      model: {
                        value: _vm.radio,
                        callback: function($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio"
                      }
                    },
                    [_vm._v("立即上线（需手动下线）")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.radio == 1
                ? _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.time,
                      callback: function($$v) {
                        _vm.time = $$v
                      },
                      expression: "time"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "input_tit" }),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.next } },
              [_vm._v("下一步")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }