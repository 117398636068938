var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "promotion" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "promotion_input" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("p", { staticClass: "promotion_input_li_tit" }, [
                _vm._v("活动名称：")
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "promontionInput",
                attrs: { type: "text", maxlength: "30" },
                model: {
                  value: _vm.obj.activityName,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "activityName", $$v)
                  },
                  expression: "obj.activityName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "promotion_input_li_tit" }, [
                _vm._v("活动分类：")
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "promontionInput1",
                  attrs: { placeholder: "活动类型" },
                  model: {
                    value: _vm.obj.classId,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "classId", $$v)
                    },
                    expression: "obj.classId"
                  }
                },
                _vm._l(_vm.obj.options, function(item) {
                  return _c("el-option", {
                    key: item.classId,
                    attrs: { label: item.name, value: item.classId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v(" 所属会员：")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { flex: "1" } },
              _vm._l(_vm.obj.memArr, function(item) {
                return _c(
                  "el-radio",
                  {
                    key: item.cardId,
                    staticStyle: { width: "180px" },
                    attrs: { label: item.cardId },
                    model: {
                      value: _vm.obj.memArrShow,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "memArrShow", $$v)
                      },
                      expression: "obj.memArrShow"
                    }
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.name) + "\n          "
                    )
                  ]
                )
              }),
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "promotion_input_li_tit" }, [
                _vm._v("活动时间：")
              ]),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.obj.time,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "time", $$v)
                  },
                  expression: "obj.time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "promotion_input_li_tit" }, [
                _vm._v("活动地点：")
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "promontionInput1",
                attrs: { type: "text" },
                model: {
                  value: _vm.obj.activityAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "activityAddress", $$v)
                  },
                  expression: "obj.activityAddress"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "promotion_input_li_tit" }, [
                _vm._v("排序号：")
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "promontionInput1",
                attrs: { type: "number" },
                model: {
                  value: _vm.obj.activitySort,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "activitySort", $$v)
                  },
                  expression: "obj.activitySort"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("BANNER图：")
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("add-load", {
                  attrs: { width: 375, height: 200, cover: _vm.obj.bannerImg },
                  on: { setCover: _vm.setBanner }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "promotion_input_li_txt" }, [
                  _vm._v("图片尺寸：750px * 400px")
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("活动详情页图：")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "min-height": "130px" } },
              [
                _c("add-load", {
                  attrs: { width: 375, height: 540, cover: _vm.obj.detailImg },
                  on: { setCover: _vm.setDetail }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "promotion_input_li_txt" }, [
                  _vm._v("图片尺寸：750px * 1080px")
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c("div", { staticClass: "li_left" }, [
              _c("p", { staticClass: "promotion_input_li_tit" }, [
                _vm._v("小程序卡片图：")
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("add-load", {
                    attrs: { width: 173, height: 139, cover: _vm.obj.shareImg },
                    on: { setCover: _vm.setShare }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "promotion_input_li_txt" }, [
                    _vm._v("图片尺寸：520px * 416px")
                  ])
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "promotion_input_li_tit" }, [
                _vm._v("小程序卡片标题：")
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "promontionInput",
                attrs: { type: "text", maxlength: "30" },
                model: {
                  value: _vm.obj.shareTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "shareTitle", $$v)
                  },
                  expression: "obj.shareTitle"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("\n          活动上线时间：\n        ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "li_div" }, [
              _c(
                "div",
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.obj.onlineType,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "onlineType", $$v)
                        },
                        expression: "obj.onlineType"
                      }
                    },
                    [_vm._v("选择上线周期")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.obj.onlineType,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "onlineType", $$v)
                        },
                        expression: "obj.onlineType"
                      }
                    },
                    [_vm._v("立即上线（需要手动下线）")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.obj.onlineType === 1,
                      expression: "obj.onlineType === 1"
                    }
                  ]
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.obj.beginDate,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "beginDate", $$v)
                      },
                      expression: "obj.beginDate"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c("el-col", { staticClass: "width_min" }, [
                    _c("p", { staticClass: "promotion_input_li_tit" }, [
                      _vm._v("上线奖励设置："),
                      _c("br"),
                      _vm._v("（邀请者）")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 20 } }, [
                    _c(
                      "div",
                      { staticClass: "li_div" },
                      [
                        _c("p", { staticClass: "annotation" }, [
                          _vm._v(
                            "\n                请注意：下一阶段获得的奖励为追加，不是替换或覆盖上一阶段的奖励。\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "annotation" }, [
                          _vm._v(
                            " 举例：如设置成长值奖励规则为一阶段奖励100、二阶段奖励200，则用户达到第二阶段时将获得300成长值，而不是200。"
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.tableData, border: "" }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "date",
                                label: "邀请人数",
                                align: "center",
                                width: "180"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("el-input-number", {
                                        attrs: { size: "small" },
                                        model: {
                                          value: scope.row.invitePeople,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "invitePeople",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.invitePeople"
                                        }
                                      }),
                                      _vm._v(
                                        "\n                    人\n                  "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "获得优惠券",
                                align: "center",
                                "min-width": "200"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "tem_div" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                                icon:
                                                  "el-icon-circle-plus-outline"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addCoupon(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("获取优惠券")]
                                          ),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  scope.row.getCoupons.join(
                                                    "、"
                                                  )
                                                ) +
                                                "\n\n                      "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "获得成长值",
                                align: "center",
                                width: "140"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: { placeholder: "成长值" },
                                        model: {
                                          value: scope.row.getGrowth,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "getGrowth",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.getGrowth"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "addres",
                                label: "备注",
                                "min-width": "200",
                                align: "center"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 1, maxRows: 4 },
                                          placeholder: "请输入内容"
                                        },
                                        model: {
                                          value: scope.row.remark,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "remark", $$v)
                                          },
                                          expression: "scope.row.remark"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "删除",
                                width: "100",
                                align: "center"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "danger",
                                            size: "mini",
                                            icon: "el-icon-delete"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeCoupon(
                                                scope.$index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "promotion_input_li_tit" }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-circle-plus-outline",
                    type: "primary"
                  },
                  on: { click: _vm.addTableData }
                },
                [_vm._v("添加奖励")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c("el-col", { staticClass: "width_min" }, [
                    _c("p", { staticClass: "promotion_input_li_tit" }, [
                      _vm._v("下线奖励设置："),
                      _c("br"),
                      _vm._v("（受邀者）")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 20 } }, [
                    _c(
                      "div",
                      { staticClass: "li_div" },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.tableData1, border: "" }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "获得优惠券",
                                align: "center",
                                "min-width": "200"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "tem_div" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                icon:
                                                  "el-icon-circle-plus-outline",
                                                size: "mini"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addCoupon1(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("获取优惠券")]
                                          ),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  scope.row.getCoupons.join(
                                                    "、"
                                                  )
                                                ) +
                                                "\n\n                      "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "获得成长值",
                                align: "center",
                                width: "140"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: { placeholder: "成长值" },
                                        model: {
                                          value: scope.row.getGrowth,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "getGrowth",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.getGrowth"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                label: "备注",
                                "min-width": "200",
                                align: "center"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 1, maxRows: 4 },
                                          placeholder: "请输入内容"
                                        },
                                        model: {
                                          value: scope.row.remark,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "remark", $$v)
                                          },
                                          expression: "scope.row.remark"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "promotion_input_li_tit" }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.next } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("couponPop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow1,
            expression: "couponShow1"
          }
        ],
        ref: "coupon1",
        on: { setCoupons: _vm.setCoupons1, close: _vm.closeCouponPop }
      }),
      _vm._v(" "),
      _c("couponPop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon",
        on: { setCoupons: _vm.setCoupons, close: _vm.closeCouponPop }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }