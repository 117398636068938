"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _deposit = require("@/api/deposit.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
  补365奖励：api/cardTrade/recharge365Reward
  {"mobile":"18690730433","orderNo":"OD106332407510114304,OD106332407510114305"}
  */
var _default = {
  data: function data() {
    return {
      rules: {
        mobile: [{
          required: true,
          message: '请输入手机号'
        }],
        orderNo: [{
          required: true,
          message: '请输入订单号'
        }]
      },
      ruleForm: {
        orderNo: null,
        mobile: null
      }
    };
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = {
            mobile: _this.ruleForm.mobile,
            orderNo: _this.ruleForm.orderNo
          };
          (0, _deposit.recharge365Reward)(data).then(function (res) {
            _this.$alert('补发奖励成功', '提示', {
              confirmButtonText: '确定',
              callback: function callback(action) {
                _this.$refs[formName].resetFields();
              }
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;