var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "volume" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.volumeTit))]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            size: "small",
            model: _vm.obj,
            "label-width": "150px"
          }
        },
        [
          _c("div", { staticClass: "add_coupon_img add_coupon_box" }, [
            _c(
              "div",
              { staticClass: "img_box left" },
              [
                _c("div", { staticClass: "img_box_tit" }, [
                  _c("span", { staticStyle: { color: "#f56c6c" } }, [
                    _vm._v("*")
                  ]),
                  _vm._v("优惠券列表图\n        ")
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    ref: "mainImage",
                    staticClass: "form_item",
                    attrs: { prop: "mainImage", "label-width": "0" }
                  },
                  [
                    _c("add-load", {
                      staticClass: "img_list",
                      attrs: {
                        width: 100,
                        height: 100,
                        cover: _vm.obj.mainImage
                      },
                      on: { setCover: _vm.setCover }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "img_box right" },
              [
                _c(
                  "div",
                  { staticClass: "img_box_tit" },
                  [
                    _c("span", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v("*")
                    ]),
                    _vm._v("优惠券详情轮播图\n          "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "20px" },
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            _vm.detailRollimgShow = true
                          }
                        }
                      },
                      [_vm._v("选择上传\n          ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    ref: "detailRollimg",
                    staticClass: "form_item",
                    attrs: { prop: "detailRollimg", "label-width": "0" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "img_list" },
                      [
                        _vm._l(_vm.obj.detailRollimg, function(item, index) {
                          return _c("img", {
                            key: index,
                            staticClass: "img_list_box",
                            attrs: { src: _vm.imgSrc + item.img }
                          })
                        }),
                        _vm._v(" "),
                        _vm._l(5 - _vm.obj.detailRollimg.length, function(
                          item
                        ) {
                          return _c("div", {
                            key: 10 + item,
                            staticClass: "img_list_box"
                          })
                        })
                      ],
                      2
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "costType",
              staticClass: "form_item",
              attrs: { prop: "costType", label: "费用出资方：" }
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "350px" },
                      attrs: { placeholder: "请选择费用出资方" },
                      on: { change: _vm.costTypeChange },
                      model: {
                        value: _vm.obj.costType,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "costType", $$v)
                        },
                        expression: "obj.costType"
                      }
                    },
                    _vm._l(_vm.options, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cost" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "ratioCompany",
                      attrs: { required: "", prop: "ratioCompany" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: {
                          type: "number",
                          disabled: _vm.obj.costType != 3
                        },
                        on: { input: _vm.costCompanyInput },
                        model: {
                          value: _vm.obj.ratioCompany,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "ratioCompany", $$v)
                          },
                          expression: "obj.ratioCompany"
                        }
                      }),
                      _vm._v("\n          %\n        ")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "ratioTenant",
                      attrs: { required: "", prop: "ratioTenant" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: {
                          type: "number",
                          disabled: _vm.obj.costType != 3
                        },
                        on: { input: _vm.costMerchantInput },
                        model: {
                          value: _vm.obj.ratioTenant,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "ratioTenant", $$v)
                          },
                          expression: "obj.ratioTenant"
                        }
                      }),
                      _vm._v("%\n        ")
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "name", attrs: { prop: "name", label: "优惠券名称：" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { size: "small" },
                model: {
                  value: _vm.obj.name,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "name", $$v)
                  },
                  expression: "obj.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "shortTitle",
              attrs: { label: "优惠券短标题：", prop: "shortTitle" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { size: "small" },
                model: {
                  value: _vm.obj.shortTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "shortTitle", $$v)
                  },
                  expression: "obj.shortTitle"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "coupon_box_remark" }, [
                _vm._v("短标题字数不能超过12个字")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "descrition",
              attrs: { prop: "descrition", label: "优惠券描述：" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { size: "small" },
                model: {
                  value: _vm.obj.descrition,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "descrition", $$v)
                  },
                  expression: "obj.descrition"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "coupon_box_remark" }, [
                _vm._v(
                  "\n        简要描述优惠券的使用范围/使用说明/宣传文案\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.obj.couponType != 2
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "stepType",
                      attrs: { prop: "stepType", label: "使用规则：" }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.obj.stepType,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "stepType", $$v)
                            },
                            expression: "obj.stepType"
                          }
                        },
                        [_vm._v("单门槛")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.obj.stepType,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "stepType", $$v)
                            },
                            expression: "obj.stepType"
                          }
                        },
                        [_vm._v("阶梯门槛")]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "form_remark" }, [
                        _vm._v(
                          "\n          单门槛优惠券只要剩余支付金额满足就能使用优惠券；\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "form_remark" }, [
                        _vm._v(
                          "\n          阶梯门槛需要优惠券抵扣后的金额满足后再能使用，（如200减50的优惠券，需要交易金额400能使用两张，300只能使用一张）\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "openSale",
                      attrs: { prop: "openSale", label: "是否开放购买：" }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: -1 },
                          model: {
                            value: _vm.obj.openSale,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "openSale", $$v)
                            },
                            expression: "obj.openSale"
                          }
                        },
                        [_vm._v("不开放")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.obj.openSale,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "openSale", $$v)
                            },
                            expression: "obj.openSale"
                          }
                        },
                        [_vm._v("开放")]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "form_remark" }, [
                        _vm._v("小程序端是否开放给用户购买")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "tradeNoticeMobile",
                      attrs: {
                        prop: "tradeNoticeMobile",
                        label: "短信通知购买："
                      }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "form_input",
                          attrs: {
                            placeholder: "请输入需要短信通知的手机号码"
                          },
                          model: {
                            value: _vm.addPhone,
                            callback: function($$v) {
                              _vm.addPhone = $$v
                            },
                            expression: "addPhone"
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.addUserPhone }
                                },
                                [_vm._v("添加")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        _vm._l(_vm.obj.tradeNoticeMobile, function(
                          item,
                          index
                        ) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticStyle: {
                                "margin-right": "10px",
                                cursor: "pointer"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.goDelPhone(item, index)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " + _vm._s(item) + "\n          "
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "form_remark" }, [
                        _vm._v(
                          "\n          优惠券购买数量超过10张，会短信通知给配置的手机号\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "denomination",
                      attrs: { prop: "denomination", label: "优惠券面额：" }
                    },
                    [
                      _c("el-input-number", {
                        attrs: { controls: false },
                        model: {
                          value: _vm.obj.denomination,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "denomination", $$v)
                          },
                          expression: "obj.denomination"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "coupon_box_remark" }, [
                        _vm._v("优惠券面额 / 优惠券价值")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "doorsillMoney",
                      attrs: { prop: "doorsillMoney", label: "使用门槛金额：" }
                    },
                    [
                      _c("el-input-number", {
                        attrs: { controls: false, min: 0 },
                        model: {
                          value: _vm.obj.doorsillMoney,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "doorsillMoney", $$v)
                          },
                          expression: "obj.doorsillMoney"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "coupon_box_remark" }, [
                        _vm._v(
                          "\n          如果是满减优惠券，可填写消费金额满___元可用，不设门槛请填0\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "customerType",
                      attrs: { prop: "customerType", label: "专属券：" }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.obj.customerType,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "customerType", $$v)
                            },
                            expression: "obj.customerType"
                          }
                        },
                        [_vm._v("全部会员")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.obj.customerType,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "customerType", $$v)
                            },
                            expression: "obj.customerType"
                          }
                        },
                        [_vm._v("付费会员专享")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "labelContent",
                      attrs: { prop: "labelContent", label: "标签选择：" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "输入标签名称搜索1~",
                            "value-key": "id",
                            clearable: ""
                          },
                          model: {
                            value: _vm.obj.labelContent,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "labelContent", $$v)
                            },
                            expression: "obj.labelContent"
                          }
                        },
                        _vm._l(_vm.userLabelList, function(so) {
                          return _c("el-option", {
                            key: so.id,
                            attrs: { label: "" + so.labelName, value: so.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "白名单", prop: "whiteId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "输入白名单名称搜索~",
                            "remote-method": _vm.getWhiteList,
                            "value-key": "id",
                            clearable: ""
                          },
                          model: {
                            value: _vm.obj.whiteId,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "whiteId", $$v)
                            },
                            expression: "obj.whiteId"
                          }
                        },
                        _vm._l(_vm.whiteList, function(so) {
                          return _c("el-option", {
                            key: so.id,
                            attrs: { label: "" + so.name, value: so.id }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "form_remark" }, [
                        _vm._v(
                          "\n          选择白名单后，仅白名单用户可购买；不选白名单则所有用户可购买\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "merchantType",
                      attrs: { prop: "merchantType", label: "是否商户券：" }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.obj.merchantType,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "merchantType", $$v)
                            },
                            expression: "obj.merchantType"
                          }
                        },
                        [_vm._v("非商户券")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.obj.merchantType,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "merchantType", $$v)
                            },
                            expression: "obj.merchantType"
                          }
                        },
                        [_vm._v("商户券（乐购使用）")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "salestypes",
                      staticClass: "form_item",
                      attrs: {
                        label: "销售价格：",
                        required: "",
                        prop: "salestypes"
                      }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          ref: "priceIntegral",
                          staticClass: "coupon_price",
                          attrs: { size: "small" },
                          on: { change: _vm.buTypeChange },
                          model: {
                            value: _vm.obj.salestypes,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "salestypes", $$v)
                            },
                            expression: "obj.salestypes"
                          }
                        },
                        _vm._l(_vm.obj.buyTypes, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "flex" },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: item.type } },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              item.type == 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "原价：",
                                        "label-width": "128px",
                                        prop: "buyTypes." + index + ".price",
                                        rules: [
                                          {
                                            required: _vm.obj.salestypes === 1,
                                            message: "请输入销售价格"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          disabled: _vm.obj.salestypes != 1,
                                          controls: false,
                                          min: 0.01
                                        },
                                        model: {
                                          value: item.price,
                                          callback: function($$v) {
                                            _vm.$set(item, "price", $$v)
                                          },
                                          expression: "item.price"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "form_remark" },
                                        [_vm._v("元")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.type == 2
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "原价：",
                                        "label-width": "128px",
                                        prop: "buyTypes." + index + ".integral",
                                        rules: [
                                          {
                                            required: _vm.obj.salestypes === 2,
                                            message: "请输入销售积分"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          disabled: _vm.obj.salestypes != 2,
                                          controls: false,
                                          min: 1
                                        },
                                        model: {
                                          value: item.integral,
                                          callback: function($$v) {
                                            _vm.$set(item, "integral", $$v)
                                          },
                                          expression: "item.integral"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "form_remark" },
                                        [_vm._v("分")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.type == 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "会员价：",
                                        "label-width": "90px"
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          disabled: _vm.obj.salestypes != 1,
                                          controls: false,
                                          min: 0,
                                          max: item.price
                                        },
                                        model: {
                                          value: item.priceMem,
                                          callback: function($$v) {
                                            _vm.$set(item, "priceMem", $$v)
                                          },
                                          expression: "item.priceMem"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "form_remark" },
                                        [_vm._v("元")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.type == 2
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "会员价：",
                                        "label-width": "90px"
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          disabled: _vm.obj.salestypes != 2,
                                          controls: false,
                                          min: 0,
                                          max: item.integral
                                        },
                                        model: {
                                          value: item.integralMem,
                                          callback: function($$v) {
                                            _vm.$set(item, "integralMem", $$v)
                                          },
                                          expression: "item.integralMem"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "form_remark" },
                                        [_vm._v("分")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.type == 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "积分可抵扣：",
                                        "label-width": "120px"
                                      }
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#13ce66",
                                          "inactive-color": "#ff4949",
                                          "active-value": item.price,
                                          "inactive-value": 0
                                        },
                                        model: {
                                          value: item.deductiblePrice,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "deductiblePrice",
                                              $$v
                                            )
                                          },
                                          expression: "item.deductiblePrice"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.type == 3
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        "label-width": "40px",
                                        prop: "buyTypes." + index + ".price",
                                        rules: [
                                          {
                                            required: _vm.obj.salestypes === 3,
                                            message: "请输入销售价格"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          disabled: _vm.obj.salestypes != 3,
                                          controls: false,
                                          min: 0.01
                                        },
                                        model: {
                                          value: item.price,
                                          callback: function($$v) {
                                            _vm.$set(item, "price", $$v)
                                          },
                                          expression: "item.price"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "form_remark" },
                                        [_vm._v("元")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "form_remark",
                                          staticStyle: { margin: "0 10px" }
                                        },
                                        [_vm._v("+")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.type == 3
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        "label-width": "0",
                                        prop: "buyTypes." + index + ".integral",
                                        rules: [
                                          {
                                            required: _vm.obj.salestypes === 3,
                                            message: "请输入销售价格"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          disabled: _vm.obj.salestypes != 3,
                                          controls: false,
                                          min: 0.01
                                        },
                                        model: {
                                          value: item.integral,
                                          callback: function($$v) {
                                            _vm.$set(item, "integral", $$v)
                                          },
                                          expression: "item.integral"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "form_remark" },
                                        [_vm._v("分")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: { label: "周期活动价格：" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex", staticStyle: { width: "100%" } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ff4949",
                                  "active-value": 5,
                                  "inactive-value": 0
                                },
                                model: {
                                  value: _vm.salestypesWeek,
                                  callback: function($$v) {
                                    _vm.salestypesWeek = $$v
                                  },
                                  expression: "salestypesWeek"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.salestypesWeek == 5
                            ? _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "原价：",
                                        "label-width": "139px",
                                        rules: [
                                          {
                                            required: _vm.salestypesWeek === 5,
                                            message: "请输入销售价格"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          disabled: _vm.salestypesWeek != 5,
                                          controls: false,
                                          min: 0.01
                                        },
                                        model: {
                                          value: _vm.salestypesWeekData.price,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.salestypesWeekData,
                                              "price",
                                              $$v
                                            )
                                          },
                                          expression: "salestypesWeekData.price"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "form_remark" },
                                        [_vm._v("元")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "会员价：",
                                        "label-width": "90px"
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          disabled: _vm.salestypesWeek != 5,
                                          controls: false,
                                          min: 0,
                                          max: _vm.salestypesWeekData.price
                                        },
                                        model: {
                                          value:
                                            _vm.salestypesWeekData.priceMem,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.salestypesWeekData,
                                              "priceMem",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "salestypesWeekData.priceMem"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "form_remark" },
                                        [_vm._v("元")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "积分可抵扣：",
                                        "label-width": "120px"
                                      }
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#13ce66",
                                          "inactive-color": "#ff4949",
                                          "active-value":
                                            _vm.salestypesWeekData.price,
                                          "inactive-value": 0
                                        },
                                        model: {
                                          value:
                                            _vm.salestypesWeekData
                                              .deductiblePrice,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.salestypesWeekData,
                                              "deductiblePrice",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "salestypesWeekData.deductiblePrice"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-form-item", {
                    ref: "listShow",
                    attrs: { prop: "listShow" }
                  }),
                  _vm._v(" "),
                  _vm.obj.couponType == 4
                    ? _c(
                        "el-form-item",
                        {
                          ref: "experienceNumber",
                          attrs: {
                            prop: "experienceNumber",
                            label: "体验项目次数："
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_input",
                            attrs: {
                              size: "small",
                              maxlength: "100",
                              type: "number",
                              placeholder: "请输入体验项目次数"
                            },
                            model: {
                              value: _vm.obj.experienceNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.obj, "experienceNumber", $$v)
                              },
                              expression: "obj.experienceNumber"
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "coupon_box_remark" }, [
                            _vm._v(
                              "\n          体验券为子母券机制，一张券可包含多个核销码，购买/领取后可分多次核销使用\n        "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "discount",
                      attrs: { prop: "discount", label: "优惠折扣：" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: {
                          size: "small",
                          type: "number",
                          maxlength: "100",
                          placeholder: "请输入优惠折扣"
                        },
                        model: {
                          value: _vm.obj.discount,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "discount", $$v)
                          },
                          expression: "obj.discount"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "coupon_box_remark" }, [
                        _vm._v(
                          "\n          请填写折扣比率，8折填写80，8.5折填写85，以此类推\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "cappingMoney",
                      attrs: { prop: "cappingMoney", label: "折扣金额封顶：" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: {
                          size: "small",
                          type: "number",
                          placeholder: "请输入折扣金额封顶"
                        },
                        model: {
                          value: _vm.obj.cappingMoney,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "cappingMoney", $$v)
                          },
                          expression: "obj.cappingMoney"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "stockPrice",
              attrs: { prop: "stockPrice", label: "进货价：" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  size: "small",
                  maxlength: "100",
                  type: "number",
                  placeholder: "请输入进货价"
                },
                model: {
                  value: _vm.obj.stockPrice,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "stockPrice", $$v)
                  },
                  expression: "obj.stockPrice"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "supplier", attrs: { prop: "supplier", label: "供应商：" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  size: "small",
                  maxlength: "100",
                  placeholder: "请输入供应商"
                },
                model: {
                  value: _vm.obj.supplier,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "supplier", $$v)
                  },
                  expression: "obj.supplier"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "supplierMobile",
              attrs: { prop: "supplierMobile", label: "供应商手机：" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  size: "small",
                  type: "number",
                  maxlength: "100",
                  placeholder: "请输入供应商手机"
                },
                model: {
                  value: _vm.obj.supplierMobile,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "supplierMobile", $$v)
                  },
                  expression: "obj.supplierMobile"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "自定义字段：" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.detailCtmfieldShow = true
                    }
                  }
                },
                [_vm._v("编辑")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "coupon_box_remark" }, [
                _vm._v(
                  "\n        自定义字段主要用于商品类/票务类，可添加若干文字说明，如参数、地址、电话等等\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "instructions",
              attrs: { prop: "instructions", label: "使用规则：" }
            },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 600, height: 300 },
                model: {
                  value: _vm.obj.instructions,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "instructions", $$v)
                  },
                  expression: "obj.instructions"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "geDetails",
              attrs: { prop: "geDetails", label: "内容详情：" }
            },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 600, height: 300 },
                model: {
                  value: _vm.obj.geDetails,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "geDetails", $$v)
                  },
                  expression: "obj.geDetails"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.obj.couponType == 5
            ? _c(
                "el-form-item",
                {
                  ref: "receivedCodes",
                  attrs: { prop: "receivedCodes", label: "导入券码：" }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            href: _vm.downloadUrl,
                            download: "外联券模板.xlsx"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                plain: "",
                                icon: "el-icon-download"
                              }
                            },
                            [_vm._v("下载模板")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("upload-excel-component", {
                        attrs: {
                          "btn-text": "批量导入",
                          type: "btn",
                          size: "mini",
                          "on-success": _vm.handleSuccess,
                          "before-upload": _vm.beforeUpload
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "receivedCode" },
                    _vm._l(_vm.obj.receivedCodes, function(item, index) {
                      return _c("span", { key: index }, [
                        _vm._v(_vm._s(item.receivedCode) + "\n        ")
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "13px",
                        color: "#999999",
                        "margin-top": "10px"
                      }
                    },
                    [
                      _vm._v(
                        "\n        投放总数：" +
                          _vm._s(_vm.obj.receivedCodes.length) +
                          "\n      "
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "totalNumber",
              attrs: { prop: "totalNumber", label: "投放总数量：" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  disabled: _vm.obj.couponType == 5,
                  size: "small",
                  placeholder: "请输入投放总数量"
                },
                model: {
                  value: _vm.obj.totalNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "totalNumber", $$v)
                  },
                  expression: "obj.totalNumber"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "coupon_box_remark" }, [
                _vm._v("该优惠券预计投放总量（不限数量请填0）")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "limitNumber",
              attrs: { prop: "limitNumber", label: "限领/限购数量：" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { size: "small", placeholder: "请输入投放总数量" },
                model: {
                  value: _vm.obj.limitNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "limitNumber", $$v)
                  },
                  expression: "obj.limitNumber"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "coupon_box_remark" }, [
                _vm._v("\n        一个用户限领/限购的数量\n        "),
                _vm.obj.salestypes == 4
                  ? _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        "\n          （免费领取的优惠券最多限制领取数量为10张）\n        "
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "validTimeType",
              attrs: { prop: "validTimeType", label: "优惠券有效期：" }
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.validTimeType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "validTimeType", $$v)
                    },
                    expression: "obj.validTimeType"
                  }
                },
                [_vm._v("时间段")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.obj.validTimeType,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "validTimeType", $$v)
                    },
                    expression: "obj.validTimeType"
                  }
                },
                [_vm._v("领取后N天内有效")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _vm.obj.validTimeType === 1
                ? _c(
                    "el-form-item",
                    {
                      ref: "validBeginTime",
                      attrs: { prop: "validBeginTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择开始时间"
                        },
                        model: {
                          value: _vm.obj.validBeginTime,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "validBeginTime", $$v)
                          },
                          expression: "obj.validBeginTime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.obj.validTimeType === 1
                ? _c(
                    "el-form-item",
                    {
                      ref: "validEndTime",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { "label-width": "0", prop: "validEndTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: !_vm.obj.validBeginTime,
                          "picker-options": _vm.pickerTime,
                          type: "datetime",
                          "default-time": "23:59:59",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择结束时间"
                        },
                        model: {
                          value: _vm.obj.validEndTime,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "validEndTime", $$v)
                          },
                          expression: "obj.validEndTime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.obj.validTimeType === 2
            ? _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-form-item",
                      {
                        ref: "validStartDay",
                        attrs: { prop: "validStartDay" }
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            min: 0,
                            step: 1,
                            "step-strictly": "",
                            controls: false
                          },
                          model: {
                            value: _vm.obj.validStartDay,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "validStartDay", $$v)
                            },
                            expression: "obj.validStartDay"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "form_remark" }, [
                          _vm._v("天")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "form_remark",
                        staticStyle: { margin: "0 10px" }
                      },
                      [_vm._v("——")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { ref: "validDay", attrs: { prop: "validDay" } },
                      [
                        _c("el-input-number", {
                          attrs: {
                            min: Number(_vm.obj.validStartDay),
                            step: 1,
                            "step-strictly": "",
                            controls: false
                          },
                          model: {
                            value: _vm.obj.validDay,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "validDay", $$v)
                            },
                            expression: "obj.validDay"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "form_remark" }, [
                          _vm._v("天内有效")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm.obj.couponType == 1 || _vm.obj.couponType == 3) &&
          _vm.obj.salestypes != 4
            ? _c(
                "el-form-item",
                {
                  ref: "costStoreId",
                  attrs: { prop: "costStoreId", label: "费用承担门店：" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { "max-width": "400px", width: "100%" },
                      attrs: {
                        clearable: "",
                        size: "small",
                        placeholder: "请选择费用承担门店",
                        filterable: ""
                      },
                      model: {
                        value: _vm.obj.costStoreId,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "costStoreId", $$v)
                        },
                        expression: "obj.costStoreId"
                      }
                    },
                    _vm._l(_vm.storeList, function(item) {
                      return _c("el-option", {
                        key: item.storeId,
                        attrs: {
                          label: item.shortName + "（" + item.storeCode + "）",
                          value: item.storeCode
                        }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "coupon_box_remark" }, [
                    _vm._v(
                      "\n        如果销售价格存在积分购买方式，必须选择一个费用承担门店\n      "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "institutionIds",
              attrs: { prop: "institutionIds", label: "使用范围：" }
            },
            [
              _c("check-tree", {
                ref: "myTree",
                attrs: {
                  "coupon-name": _vm.obj.name,
                  "coupon-type": _vm.obj.couponType,
                  "tree-arr": _vm.obj.treeArr,
                  "table-data": _vm.obj.goodsIds,
                  tableData1: _vm.obj.goodsIds1,
                  "institution-ids": _vm.obj.institutionIds,
                  color: _vm.obj.usableType
                },
                on: {
                  setTable: _vm.setTable,
                  delGoodsItem: _vm.delGoodsItem,
                  setGoods: _vm.setGoods
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.obj.couponType != 5
            ? _c("el-form-item", {
                ref: "usableType",
                attrs: { prop: "usableType" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.obj.couponType != 5
            ? _c("el-form-item", {
                ref: "goodsIds",
                attrs: { prop: "goodsIds" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "rejectRule",
              attrs: { prop: "rejectRule", label: "排斥规则：" }
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.rejectRule,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "rejectRule", $$v)
                    },
                    expression: "obj.rejectRule"
                  }
                },
                [_vm._v("不与其他优惠券同时使用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.obj.rejectRule,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "rejectRule", $$v)
                    },
                    expression: "obj.rejectRule"
                  }
                },
                [_vm._v("可与其他优惠券同时使用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 3 },
                  model: {
                    value: _vm.obj.rejectRule,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "rejectRule", $$v)
                    },
                    expression: "obj.rejectRule"
                  }
                },
                [_vm._v("全场通用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "overlayNumber",
              attrs: { prop: "overlayNumber", label: "叠加规则：" }
            },
            [
              _vm._v("\n      可叠加使用\n      "),
              _c("el-input", {
                staticStyle: { width: "100px", display: "inline-block" },
                attrs: {
                  size: "small",
                  onmousewheel: "return false;",
                  type: "number",
                  placeholder: ""
                },
                model: {
                  value: _vm.obj.overlayNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "overlayNumber", $$v)
                  },
                  expression: "obj.overlayNumber"
                }
              }),
              _vm._v("张\n      "),
              _c("p", { staticClass: "coupon_box_remark" }, [
                _vm._v(
                  "\n        叠加规则指：如用户有多张A券，A券和A券可同时叠加使用，最多叠加N张\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "limitGoods",
              attrs: { prop: "limitGoods", label: "限购商品数量：" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { size: "small", placeholder: "请输入限购商品数量" },
                model: {
                  value: _vm.obj.limitGoods,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "limitGoods", $$v)
                  },
                  expression: "obj.limitGoods"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "coupon_box_remark" }, [
                _vm._v("件商品（填写0为不限制商品数量）")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "verificationModel",
              attrs: { prop: "verificationModel", label: "核销方式：" }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.obj.verificationModel,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "verificationModel", $$v)
                    },
                    expression: "obj.verificationModel"
                  }
                },
                _vm._l(_vm.verificationModelArr, function(item) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.cardId,
                      staticClass: "checkboxs",
                      attrs: { value: item.id, label: item.id }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("选中的核销方式才能核销优惠券")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "showStatus",
              attrs: { prop: "showStatus", label: "显示状态：" }
            },
            _vm._l(_vm.showStatusArr, function(item) {
              return _c(
                "el-radio",
                {
                  key: item.id,
                  attrs: { label: item.id },
                  model: {
                    value: _vm.obj.showStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "showStatus", $$v)
                    },
                    expression: "obj.showStatus"
                  }
                },
                [_vm._v(_vm._s(item.name) + "\n      ")]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示大屏：", prop: "isShow" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.isShow,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "isShow", $$v)
                    },
                    expression: "obj.isShow"
                  }
                },
                [_vm._v("显示")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.obj.isShow,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "isShow", $$v)
                    },
                    expression: "obj.isShow"
                  }
                },
                [_vm._v("不显示")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("是否显示到荔心中心门店的大屏")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "365会员价排斥：", prop: "rejectVipPrice" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: -1 },
                  model: {
                    value: _vm.obj.rejectVipPrice,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "rejectVipPrice", $$v)
                    },
                    expression: "obj.rejectVipPrice"
                  }
                },
                [_vm._v("能与会员价同时使用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.rejectVipPrice,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "rejectVipPrice", $$v)
                    },
                    expression: "obj.rejectVipPrice"
                  }
                },
                [_vm._v("不能与会员价使用")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("是否和蜻蜓上的365会员折扣同时使用")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "转赠规则：", prop: "ifDonation" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.obj.ifDonation,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "ifDonation", $$v)
                    },
                    expression: "obj.ifDonation"
                  }
                },
                [_vm._v("不可转赠好友")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.ifDonation,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "ifDonation", $$v)
                    },
                    expression: "obj.ifDonation"
                  }
                },
                [_vm._v("可以转赠好友")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("用户领取优惠券后是否可以转赠给好友")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.obj.couponType == 3
            ? _c(
                "el-form-item",
                {
                  ref: "isTohome",
                  attrs: { prop: "isTohome", label: "邮寄到家：" }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.obj.isTohome,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "isTohome", $$v)
                        },
                        expression: "obj.isTohome"
                      }
                    },
                    [_vm._v("否")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.obj.isTohome,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "isTohome", $$v)
                        },
                        expression: "obj.isTohome"
                      }
                    },
                    [_vm._v("是")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("礼品券是否可以邮寄到家")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "isRightsCoupon",
              attrs: { prop: "isRightsCoupon", label: "权益劵：" }
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.obj.isRightsCoupon,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "isRightsCoupon", $$v)
                    },
                    expression: "obj.isRightsCoupon"
                  }
                },
                [_vm._v("否")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.isRightsCoupon,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "isRightsCoupon", $$v)
                    },
                    expression: "obj.isRightsCoupon"
                  }
                },
                [_vm._v("是")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "ifShare",
              attrs: { prop: "ifShare", label: "是否可分享：" }
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.obj.ifShare,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "ifShare", $$v)
                    },
                    expression: "obj.ifShare"
                  }
                },
                [_vm._v("是")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.obj.ifShare,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "ifShare", $$v)
                    },
                    expression: "obj.ifShare"
                  }
                },
                [_vm._v("否")]
              ),
              _vm._v(" "),
              _c("p", [_vm._v("优惠券是否可分享给好友")])
            ],
            1
          ),
          _vm._v(" "),
          _vm.obj.ifShare === 1
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { span: 11 }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "shareTitle",
                          attrs: { prop: "shareTitle", label: "分享标题：" }
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_input",
                            staticStyle: { width: "220px" },
                            attrs: { size: "small", type: "textarea" },
                            model: {
                              value: _vm.obj.shareTitle,
                              callback: function($$v) {
                                _vm.$set(_vm.obj, "shareTitle", $$v)
                              },
                              expression: "obj.shareTitle"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "shareImg",
                          attrs: { prop: "shareImg", label: "分享图片：" }
                        },
                        [
                          _c("add-load", {
                            attrs: {
                              width: 220,
                              height: 140,
                              cover: _vm.obj.shareImg
                            },
                            on: { setCover: _vm.setSareImg }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "coupon_box_remark" }, [
                            _vm._v("图片尺寸：520px * 416px")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { span: 11 }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分享图片演示：" } },
                        [
                          _c("img", {
                            staticStyle: { width: "220px", height: "268px" },
                            attrs: {
                              src:
                                "http://xl-cdn.xinglico.com/mika/pro/2020-02-19/9b4a4995f9a1466db053acd28635841d.jpg"
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.obj.couponType == 5 && _vm.obj.id
                ? _c(
                    "el-button",
                    { attrs: { type: "primary", disabled: "" } },
                    [_vm._v("外部优惠券不支持修改")]
                  )
                : _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("form")
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cardLoad", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.detailRollimgShow,
            expression: "detailRollimgShow"
          }
        ],
        ref: "detailImg",
        attrs: { "detail-rollimg": _vm.obj.detailRollimg },
        on: { setDetailRollimg: _vm.setDetailRollimg }
      }),
      _vm._v(" "),
      _c("detailCtm", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.detailCtmfieldShow,
            expression: "detailCtmfieldShow"
          }
        ],
        attrs: { "detail-ctmfield": _vm.obj.detailCtmfield },
        on: { setdetailCtmfield: _vm.setdetailCtmfield }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }