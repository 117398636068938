var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          ref: "wForm",
          attrs: {
            "form-data": _vm.formData,
            isTitle: false,
            "rule-form": _vm.ruleForm,
            rules: _vm.rules
          },
          on: { submitForm: _vm.submitForm }
        },
        [
          _c(
            "div",
            { attrs: { slot: "storeCode" }, slot: "storeCode" },
            [
              _vm.ruleForm.storeRange == 2
                ? _c(
                    "el-form-item",
                    {
                      ref: "storeCode",
                      attrs: { label: "限制门店：", prop: "storeCode" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form_input",
                          attrs: {
                            size: "small",
                            placeholder: "请选择限制门店",
                            filterable: "",
                            autocomplete: "off"
                          },
                          model: {
                            value: _vm.ruleForm.storeCode,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "storeCode", $$v)
                            },
                            expression: "ruleForm.storeCode"
                          }
                        },
                        _vm._l(_vm.storeList, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.storeId,
                              attrs: {
                                label:
                                  item.shortName + "（" + item.storeCode + "）",
                                value: item.storeCode
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    item.shortName +
                                      "（" +
                                      item.storeCode +
                                      "）"
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.ruleType > 0
            ? _c(
                "div",
                { attrs: { slot: "ruleRemak" }, slot: "ruleRemak" },
                [
                  _c("el-form-item", { attrs: { label: "规则描述：" } }, [
                    _vm.ruleForm.ruleType == 1
                      ? _c("div", [
                          _c(
                            "p",
                            {
                              staticClass: "form_remark",
                              staticStyle: { color: "red" }
                            },
                            [
                              _vm._v(
                                "*活动期间内单笔消费满足门槛，即发送抽奖次数 (算订单)"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "form_remark",
                              staticStyle: { color: "red" }
                            },
                            [_vm._v("示例：")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v("活动周期为:9月10日-9月20日 (10天)")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v("消费门槛: "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("100元")
                            ]),
                            _vm._v(" ;可抽奖: "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("2次")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v("在活动周期内只要单笔消费达到"),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("100元")
                            ]),
                            _vm._v("，\n            即触发对应抽奖次数: "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("2次")
                            ]),
                            _vm._v("；\n            如:一位用户在11号消费 "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("2笔订单")
                            ]),
                            _vm._v(
                              "满足抽奖门槛，\n            则立即发送抽奖"
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("4次")
                            ]),
                            _vm._v("数")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ruleForm.ruleType == 2
                      ? _c("div", [
                          _c(
                            "p",
                            {
                              staticClass: "form_remark",
                              staticStyle: { color: "red" }
                            },
                            [
                              _vm._v(
                                "*活动期间内累计消费满足门槛，即发送抽奖次数 (只算第一次满足消费门槛)"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "form_remark",
                              staticStyle: { color: "red" }
                            },
                            [_vm._v("示例：")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v("活动周期为:9月10日-9月20日 (10天)")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v("消费门槛: "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("1000元")
                            ]),
                            _vm._v(" ;可抽奖:\n          "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("4次")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v("在活动周期内只要累计消费达到 "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("1000元")
                            ]),
                            _vm._v(" ;可抽奖:\n          "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("4次")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v("如：一位用户在10号11号累计消费"),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("1000元")
                            ]),
                            _vm._v(
                              "，\n            满足抽奖门槛， 则立即发送抽奖"
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("4次")
                            ]),
                            _vm._v(
                              "；\n            在后续的活动期间内又满足消费门槛后， "
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                "，则不触发抽奖次数在整个活动期间，仅触发一次"
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ruleForm.ruleType == 3
                      ? _c("div", [
                          _c(
                            "p",
                            {
                              staticClass: "form_remark",
                              staticStyle: { color: "red" }
                            },
                            [
                              _vm._v(
                                "\n            *活动期间内单日消费满足门槛，即发送抽奖次数 (只算当日第一次满足的消费门槛)\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "form_remark",
                              staticStyle: { color: "red" }
                            },
                            [_vm._v("示例：")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v("活动周期为:9月10日-9月20日 (10天)")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v("消费门槛: "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("1000元")
                            ]),
                            _vm._v(" ;可抽奖:\n          "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("4次")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v("在活动周期内只要单日累计消费达到"),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("1000元")
                            ]),
                            _vm._v(" ;可抽奖:\n          "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("4次")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "form_remark" }, [
                            _vm._v("如：一位用户在10号上午消费累计达到"),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("1000元")
                            ]),
                            _vm._v(
                              "，\n            满足抽奖门槛， 则立即发送抽奖"
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("4次")
                            ]),
                            _vm._v(
                              "；\n            （如果该用户10号下午又消费累计满足1000元，\n          则"
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("不触发")
                            ]),
                            _vm._v("\n          第二次抽奖机会， "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("(每日只触发一次)")
                            ]),
                            _vm._v(
                              "该用户11号继续消费累计达到1000元，则又继续触发抽奖机会）\n          "
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "userGroup" }, slot: "userGroup" },
            [
              _c(
                "el-form-item",
                {
                  ref: "userGroup",
                  attrs: { prop: "userGroup", label: "邀请用户标签：" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: {
                        filterable: "",
                        placeholder: "输入标签名称搜索~",
                        "value-key": "id",
                        clearable: ""
                      },
                      model: {
                        value: _vm.ruleForm.userGroup,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "userGroup", $$v)
                        },
                        expression: "ruleForm.userGroup"
                      }
                    },
                    _vm._l(_vm.userLabelList, function(so) {
                      return _c("el-option", {
                        key: so.id,
                        attrs: { label: "" + so.labelName, value: so.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "blacklist" }, slot: "blacklist" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "黑名单：", prop: "blacklist" } },
                [
                  _c("div", { staticStyle: { "max-width": "800px" } }, [
                    _c("div", { staticClass: "flex_between" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              staticStyle: { "max-width": "250px" },
                              attrs: {
                                size: "mini",
                                placeholder: "添加手机号"
                              },
                              model: {
                                value: _vm.addMobile,
                                callback: function($$v) {
                                  _vm.addMobile = $$v
                                },
                                expression: "addMobile"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    background: "#409eff !important",
                                    color: "#ffffff",
                                    width: "100px"
                                  },
                                  attrs: {
                                    slot: "append",
                                    size: "mini",
                                    type: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.goAddMobile("mobile")
                                    }
                                  },
                                  slot: "append"
                                },
                                [_vm._v("添加号码")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { "margin-top": "6px" }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.downloadUrl,
                                download: "上传手机号码模板.xlsx"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "success",
                                    icon: "el-icon-download"
                                  }
                                },
                                [_vm._v("下载模板")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.ruleForm.blacklist.length > 0
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { margin: "0 20px" },
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-download"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDownload("mobile")
                                    }
                                  }
                                },
                                [_vm._v("导出手机号")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("upload-excel-component", {
                            staticStyle: { "margin-left": "20px" },
                            attrs: {
                              size: "mini",
                              "btn-text": "批量上传",
                              type: "btn",
                              "on-success": _vm.handleSuccess,
                              "before-upload": _vm.beforeUpload
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "max-height": "600px",
                          overflow: "auto",
                          padding: "10px",
                          border: "1px solid #cccccc",
                          "margin-top": "10px"
                        }
                      },
                      _vm._l(_vm.ruleForm.blacklist, function(item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.delMobile(item, index, "mobile")
                              }
                            }
                          },
                          [_vm._v(_vm._s(item) + ",")]
                        )
                      }),
                      0
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "limitGoods" }, slot: "limitGoods" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "限制商品：", prop: "limitGoods" } },
                [
                  _c("div", { staticStyle: { "max-width": "800px" } }, [
                    _c("div", { staticClass: "flex_between" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              staticStyle: { "max-width": "250px" },
                              attrs: {
                                size: "mini",
                                placeholder: "添加商品码"
                              },
                              model: {
                                value: _vm.addGoods,
                                callback: function($$v) {
                                  _vm.addGoods = $$v
                                },
                                expression: "addGoods"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    background: "#409eff !important",
                                    color: "#ffffff",
                                    width: "100px"
                                  },
                                  attrs: {
                                    slot: "append",
                                    size: "mini",
                                    type: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.goAddMobile("goods")
                                    }
                                  },
                                  slot: "append"
                                },
                                [_vm._v("添加商品")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { "margin-top": "6px" }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.downloadGoodsUrl,
                                download: "上传商品码模板.xlsx"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "success",
                                    icon: "el-icon-download"
                                  }
                                },
                                [_vm._v("下载模板")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.ruleForm.limitGoods.length > 0
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { margin: "0 20px" },
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-download"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDownload("goods")
                                    }
                                  }
                                },
                                [_vm._v("导出商品码")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("upload-excel-component", {
                            staticStyle: { "margin-left": "20px" },
                            attrs: {
                              size: "mini",
                              "btn-text": "批量上传",
                              type: "btn",
                              "on-success": _vm.handleGoodsSuccess,
                              "before-upload": _vm.beforeUpload
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "max-height": "600px",
                          overflow: "auto",
                          padding: "10px",
                          border: "1px solid #cccccc",
                          "margin-top": "10px"
                        }
                      },
                      _vm._l(_vm.ruleForm.limitGoods, function(item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.delMobile(item, index, "goods")
                              }
                            }
                          },
                          [_vm._v(_vm._s(item) + ",")]
                        )
                      }),
                      0
                    )
                  ])
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }