var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "keep-alive",
        [
          _c(
            "w-table",
            {
              ref: "wTable",
              attrs: { size: "mini", "search-data": _vm.searchData }
            },
            [
              _c("el-table-column", {
                attrs: {
                  slot: "login",
                  label: "状态",
                  align: "center",
                  width: "80"
                },
                slot: "login",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.login === "true"
                          ? _c("span", { staticClass: "success" }, [
                              _vm._v("已登录")
                            ])
                          : _c("span", { staticClass: " danger" }, [
                              _vm._v("未已登录")
                            ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "type",
                  label: "类型",
                  align: "center",
                  width: "80"
                },
                slot: "type",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.type === "onHide"
                          ? _c("span", { staticClass: "warning" }, [
                              _vm._v("离开页面")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.type === "onShow"
                          ? _c("span", { staticClass: "success" }, [
                              _vm._v("进入页面")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.type === "onUnload"
                          ? _c("span", { staticClass: "danger" }, [
                              _vm._v("卸载页面")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.type === "tap"
                          ? _c("span", { staticClass: "primary" }, [
                              _vm._v("点击事件")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.type === "appShow"
                          ? _c("span", { staticClass: "success" }, [
                              _vm._v("首次进入程序")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "params",
                  label: "数据参数",
                  align: "center",
                  width: "120"
                },
                slot: "params",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.params
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.goOpenJson(scope.row, "params")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      scope.row.type === "tap"
                                        ? "事件参数"
                                        : "用户手机信息"
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "query",
                  label: "访问参数",
                  align: "center",
                  width: "120"
                },
                slot: "query",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.query
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.goOpenJson(scope.row, "query")
                                  }
                                }
                              },
                              [_vm._v("\n            访问参数\n          ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  slot: "pathName",
                  label: "访问路径名称",
                  align: "center",
                  width: "120"
                },
                slot: "pathName",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.pageUrlData[scope.row.path]) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isShowPop
        ? _c(
            "div",
            {
              staticClass: "fixed_center flex_center",
              on: {
                click: function($event) {
                  _vm.isShowPop = false
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "fixed_center_box",
                  staticStyle: {
                    width: "800px",
                    padding: "20px",
                    background: "#FFFFFF"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                },
                [
                  _vm.jsonViewData
                    ? _c("div", [
                        _vm.jsonViewTitle === "query"
                          ? _c("div", [
                              _vm._v("\n          访问参数\n        ")
                            ])
                          : _c(
                              "div",
                              {
                                staticClass: "view_title flex_between",
                                staticStyle: { "align-items": "center" }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.jsonViewData.type === "tap"
                                        ? "事件参数"
                                        : "手机信息"
                                    ) +
                                    "\n        "
                                )
                              ]
                            ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "fixed_center_box_pre",
                            staticStyle: {
                              "max-height": "500px",
                              overflow: "auto"
                            }
                          },
                          [
                            _c("JsonView", {
                              attrs: {
                                json: _vm.jsonViewData.params,
                                closed: false
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }