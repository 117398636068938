"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _api = require("./api.js");

var _user = require("@/api/user.js");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      url: '',
      couponUUid: '',
      qrCode: '',
      popShow: false,
      time: null,
      imgSrc: this.$store.state.user.imgSrc,
      urlList: _selectUrl.default.urlList,
      appIdArr: _selectUrl.default.appIdArr,
      downloadLoading: false,
      requestData: {
        status: [],
        activityCode: null,
        activityName: null,
        activityStartTime: null,
        activityEndTime: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: 'ID',
        prop: 'id',
        width: 60
      }, {
        label: '活动编码',
        prop: 'activityCode',
        width: 100
      }, {
        label: '活动名称',
        prop: 'activityName',
        width: 100
      }, {
        slot: 'time',
        label: '活动周期',
        prop: 'time',
        width: 100
      },
      /* {
                      slot: 'popupRate',
                      label: '好友助力详情',
                      prop: 'popupRate',
                      width: 100
                    }, */
      {
        label: '总人数限制',
        prop: 'activityLimitPersons',
        width: 80
      }, {
        label: '助力层级',
        prop: 'totalLevel',
        width: 80
      }, {
        slot: 'labelContent',
        label: '发起人标签',
        prop: 'labelContent',
        width: 80
      }, {
        slot: 'objectLabelContent',
        label: '助力人标签',
        prop: 'objectLabelContent',
        width: 80
      },
      /* {
         slot: 'activityBeHelpedObjectType',
         label: '发起人类型',
         prop: 'activityBeHelpedObjectType',
         width: 80
       }, {
         slot: 'objectHelpType',
         label: '助力人类型',
         prop: 'objectHelpType',
         width: 80
       }, */
      {
        slot: 'status',
        label: '状态',
        prop: 'status',
        width: 50
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(value) {
      if (value) {
        this.requestData.activityStartTime = value[0];
        this.requestData.activityEndTime = value[0];
      } else {
        this.requestData.activityStartTime = null;
        this.requestData.activityEndTime = null;
      }
    }
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {},
  methods: {
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getQrcode: function getQrcode(scope) {
      var _this = this;

      // 获取小程序码
      this.couponUUid = scope.name;
      (0, _user.couponQRCode)({
        path: 'activity/help/help',
        scene: 'id=' + String(scope.id)
      }).then(function (res) {
        _this.url = 'activity/help/help?id=' + scope.id;
        _this.qrCode = res.data;
        _this.popShow = true;
      });
    },
    goDel: function goDel(scope) {
      var _this2 = this;

      (0, _api.delHelp)({
        id: scope.row.id
      }).then(function (res) {
        _this2.getList();
      });
    },
    goAudit: function goAudit(scope, status) {
      var _this3 = this;

      (0, _api.examineHelp)({
        id: scope.row.id,
        status: status
      }).then(function (res) {
        _this3.getList();
      });
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this4.requestData[key]) {
          data[key] = _this4.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.helpPage)(data).then(function (res) {
        list = res.data.records;
        _this4.downloadLoading = false;

        _this4.getHandleDownload(tHeader, filterVal, list, '好友助力列表');
      }).catch(function (res) {
        _this4.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this5 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.helpPage)(this.requestData).then(function (res) {
        _this5.tableData = res.data.records;
        _this5.requestData.total = res.data.total;
        return;
      });
    }
  }
};
exports.default = _default;