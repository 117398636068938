"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("../api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var areaOps = [{
      value: 1,
      label: "积分商城"
    }, {
      value: 2,
      label: "会员商城"
    }];
    var characteristicOps = [{
      value: 1,
      label: "显示"
    }, {
      value: 2,
      label: "不显示"
    }];
    var stateOps = [{
      value: 1,
      label: "启用"
    }, {
      value: 2,
      label: "禁用"
    }];
    var fields = [{
      type: "select",
      label: '显示区域',
      value: 'displayArea',
      rules: [{
        required: true,
        message: '请选择显示区域',
        trigger: 'change'
      }],
      options: areaOps
    }, {
      label: '关键词',
      value: 'keyword',
      rules: [{
        required: true,
        message: '请输入关键词',
        trigger: 'blur'
      }]
    }, {
      type: "textarea",
      label: '联想词',
      value: 'associationalWord',
      // rules: [{ required: true, message: '请输入联想词', trigger: 'blur' }],
      tip: "联想词可包括：近义词，同类词等；多个内容请使用英文逗号“,”隔开。"
    }, {
      type: "radio",
      label: '热搜状态',
      value: 'status',
      rules: [{
        required: true,
        message: '请选择热搜状态',
        trigger: 'change'
      }],
      options: stateOps
    }, {
      type: "radio",
      label: '热搜标识',
      value: 'characteristic',
      rules: [{
        required: true,
        message: '请选择热搜标识',
        trigger: 'change'
      }],
      options: characteristicOps,
      tip: "此关键词是否显示热搜图标。"
    }, {
      type: "textarea",
      label: '备注',
      value: 'remark',
      rules: [{
        required: true,
        message: '请输入备注',
        trigger: 'blur'
      }]
    }];
    return {
      fields: fields,
      form: {}
    };
  },
  filters: {
    placeholderLabel: function placeholderLabel(label) {
      return "请输入" + label;
    }
  },
  created: function created() {
    this.getInfo();
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      var id = this.$route.query.id;
      if (!id) return;
      (0, _api.keywordDetail)(id).then(function (res) {
        _this.form = res.data;
      });
    },
    onSave: function onSave() {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (!valid) return;
        var form = JSON.parse(JSON.stringify(_this2.form));
        delete form.createTime;
        (0, _api.keywordSave)(form).then(function () {
          _this2.$message.success("保存成功");

          _this2.onCancel();
        });
      });
    },
    onCancel: function onCancel() {
      this.$router.push("/mall/keyword/list");
    }
  }
};
exports.default = _default;