"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = require("./api.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    coupon: _radioItem.default
  },
  data: function data() {
    return {
      couponShow: false,
      columns: [{
        label: '券编码',
        prop: 'couponCode',
        width: 100
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 100
      }, {
        label: '券类型',
        prop: 'couponType',
        width: 100
      }, {
        label: '面额/折扣',
        prop: 'disdenStr',
        width: 100
      }, {
        label: '有效期',
        prop: 'validStr',
        width: 100
      }],
      rules: {
        couponCode: [{
          required: true,
          message: '请选择优惠券'
        }],
        sendStatus: [{
          required: true,
          message: '请选择发券状态'
        }],
        sendNumber: [{
          required: true,
          message: '请输入发券数量'
        }],
        sendRemark: [{
          required: true,
          message: '请输入发券备注'
        }],
        sendType: [{
          required: true,
          message: '请选择发券类型'
        }]
      },
      ruleForm: {
        couponLgid: null,
        couponCode: null,
        couponName: null,
        sendStatus: null,
        sendNumber: null,
        sendType: null,
        sendRemark: null
      }
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _api.getVipSendCoupon)(this.ruleForm.id).then(function (res) {
        _this.ruleForm.couponCode = res.data.couponCode;
        _this.ruleForm.couponName = res.data.couponName;
        _this.ruleForm.sendNumber = res.data.sendNumber;
        _this.ruleForm.sendStatus = res.data.sendStatus;
        _this.ruleForm.sendType = res.data.sendType;
        _this.ruleForm.sendRemark = res.data.sendRemark;
      });
    }
  },
  methods: {
    getRelevances: function getRelevances() {
      this.$refs.couponPop.getList('coupon', this.ruleForm.couponCode);
      this.couponShow = true;
    },
    setCoupons: function setCoupons(e) {
      this.ruleForm.couponCode = e.uuid;
      this.ruleForm.couponName = e.name;
      this.couponShow = false;
    },
    closeCouponPop: function closeCouponPop() {
      this.couponShow = false;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this2.ruleForm.id) {
            (0, _api.editVipSendCoupon)(_this2.ruleForm).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addVipSendCoupon)(_this2.ruleForm).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;