var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            searchData: _vm.searchData,
            mobile: ["phone"],
            pageApi: "/api/sign/reward/page"
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "memberType",
              label: "会员类型",
              align: "center",
              "mni-width": "100"
            },
            slot: "memberType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.memberType === 0 ? "非365会员" : "365会员"
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "rewardType",
              label: "礼品类型",
              align: "center",
              "mni-width": "100"
            },
            slot: "rewardType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.rewardType[scope.row.rewardType]) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "price",
              label: "礼品面额",
              align: "center",
              "mni-width": "100"
            },
            slot: "price",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.price) +
                        _vm._s(
                          scope.row.rewardType == 1 || scope.row.rewardType == 5
                            ? "积分"
                            : "元"
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "rewardSignDate",
              label: "签到时间",
              align: "center",
              "mni-width": "100"
            },
            slot: "rewardSignDate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.rewardSignDate.split(" ")[0]) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "80"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 2
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("正常签到")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("补签卡签到")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }