"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _default = [{
  path: 'jianghua/giveCount',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/parking/jianghua/giveCount.vue'));
    });
  },
  name: 'ParkingJianghuaGiveCount',
  meta: {
    title: '发放停车次数',
    activeMenu: '/parking/jianghua/tab'
  }
}, {
  path: 'jianghua/tab',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/parking/jianghua/tab.vue'));
    });
  },
  name: 'ParkingJianghuaTab',
  meta: {
    title: '江华停车管理'
  }
}, {
  path: 'jianghua/list',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/parking/jianghua/index.vue'));
    });
  },
  name: 'ParkingJianghua',
  meta: {
    title: '江华停车停车场',
    keepAlive: true,
    addTxt: "新增停车场",
    addUrl: '/parking/jianghua/form',
    api: '/api/stopPark/listStopParkInfo',
    activeMenu: '/parking/jianghua/tab',
    pageList: null,
    columns: [{
      label: '图片',
      prop: 'photo',
      slot: "photo"
    }, {
      label: '停车场系统',
      prop: 'systemName'
    }, {
      label: '停车场名称',
      prop: 'parkName'
    }, {
      label: '停车场ID',
      prop: 'parkId'
    }, {
      label: '停车场秘钥',
      prop: 'secretKey'
    }, {
      label: '经纬度',
      prop: 'longLat'
    }, {
      label: '基础收费',
      prop: 'basicCharge'
    }, {
      label: '进入场免费时长',
      prop: 'freeDuration'
    }, {
      slot: "operate"
    }]
  }
}, {
  path: 'jianghua/form',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/parking/jianghua/form.vue'));
    });
  },
  name: 'ParkingJianghuaForm',
  meta: {
    title: '新增/编辑江华停车',
    activeMenu: '/parking/jianghua/tab'
  }
}, {
  path: 'jianghua/order',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("./order.vue"));
    });
  },
  name: 'ParkingJianghuaOrder',
  meta: {
    title: '江华停车订单',
    keepAlive: true,
    api: '/api/stopPark/quertStopParkOrderPage',
    addUrl: '/parking/jianghua/order/detail',
    activeMenu: '/parking/jianghua/tab',
    pageList: 'items',
    isOneRequest: 'no',
    pageTotal: 'totalNum',
    columns: [{
      label: '订单号',
      prop: 'orderNo',
      width: 120
    }, {
      label: '停车场名称',
      prop: 'parkName',
      width: 80
    }, {
      label: '停车场基础收费',
      prop: 'parkBasicCharge',
      width: 80
    }, {
      label: '车牌号',
      prop: 'plateNo',
      width: 80
    }, {
      label: '停车费用',
      prop: 'payable',
      width: 80
    }, {
      label: '消费优惠',
      prop: 'consumeDisAmount',
      width: 80
    }, {
      label: '会员折扣',
      prop: 'memberDisAmount',
      width: 80
    }, {
      label: '会员优惠',
      prop: 'stopAmount',
      width: 80
    }, {
      label: '购物卡支付',
      prop: 'storeCardAmount',
      width: 80
    }, {
      label: '积分支付',
      prop: 'scoreAmount',
      width: 80
    }, {
      label: '微信支付',
      prop: 'wxAmount',
      width: 80
    }, {
      label: '用户手机号',
      prop: 'mobile',
      width: 100
    }, {
      label: '用户昵称',
      prop: 'realname',
      width: 80
    }, {
      slot: 'memberType',
      label: '会员类型',
      prop: 'memberType',
      width: 80
    }, {
      label: '当日总消费',
      prop: 'totalAmount',
      width: 80
    }, {
      slot: 'status',
      label: '状态',
      prop: 'status',
      width: 80
    }, {
      label: '入场时间',
      prop: 'entryTime',
      width: 135
    }, {
      label: '出场时间',
      prop: 'outTime',
      width: 135
    }, {
      slot: "orderDea"
    }]
  }
}, {
  path: 'jianghua/huijinOrder',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("./huijinOrder.vue"));
    });
  },
  name: 'ParkingHuiJinOrder',
  meta: {
    title: '汇金停车订单',
    api: '/api/stopPark/quertStopParkOrderPage',
    addUrl: '/parking/jianghua/order/detail',
    pageList: 'items',
    pageTotal: 'totalNum',
    isOneRequest: 'no',
    columns: [{
      label: '订单号',
      prop: 'orderNo',
      width: 120
    }, {
      label: '停车场名称',
      prop: 'parkName',
      width: 80
    }, {
      label: '停车场基础收费(元)',
      prop: 'parkBasicCharge',
      width: 80
    }, {
      label: '停车时长',
      prop: 'elapsedTime',
      width: 80
    }, {
      label: '车牌号',
      prop: 'plateNo',
      width: 80
    }, {
      label: '停车费用',
      prop: 'payable',
      width: 80
    },
    /* {
      label: '消费优惠',
      prop: 'consumeDisAmount',
      width: 80
    }, */
    {
      label: '会员优惠金额(元)',
      prop: 'stopAmount',
      width: 80
    }, {
      label: '购物卡支付时长(h)',
      prop: 'cardTime',
      width: 80
    }, {
      label: '积分支付时长(h)',
      prop: 'scoreTime',
      width: 80
    }, {
      label: '微信支付(元)',
      prop: 'wxAmount',
      width: 80
    },
    /* {
       label: '用户手机号',
       prop: 'mobile',
       width: 100
     }, {
       label: '用户昵称',
       prop: 'realname',
       width: 80
     },
     {
       slot: 'memberType',
       label: '会员类型',
       prop: 'memberType',
       width: 80
     },
     {
       label: '当日总消费',
       prop: 'totalAmount',
       width: 80
     }, */
    {
      slot: 'status',
      label: '状态',
      prop: 'status',
      width: 80
    }, {
      label: '入场时间',
      prop: 'entryTime',
      width: 135
    }, {
      label: '出场时间',
      prop: 'outTime',
      width: 135
    }]
  }
}, {
  path: 'jianghua/ruijinOrder',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("./ruijin.vue"));
    });
  },
  name: 'ParkingruijinOrder',
  meta: {
    title: '瑞金停车订单',
    api: '/api/stopPark/quertStopParkOrderPage',
    addUrl: '/parking/jianghua/order/detail',
    pageList: 'items',
    pageTotal: 'totalNum',
    isOneRequest: 'no',
    columns: [{
      label: '订单号',
      prop: 'orderNo',
      width: 120
    }, {
      label: '停车场名称',
      prop: 'parkName',
      width: 80
    }, {
      label: '停车场基础收费',
      prop: 'parkBasicCharge',
      width: 80
    }, {
      label: '车牌号',
      prop: 'plateNo',
      width: 80
    }, {
      label: '停车费用',
      prop: 'payable',
      width: 80
    }, {
      label: '消费优惠',
      prop: 'consumeDisAmount',
      width: 80
    }, {
      label: '会员折扣',
      prop: 'memberDisAmount',
      width: 80
    }, {
      label: '会员优惠',
      prop: 'stopAmount',
      width: 80
    }, {
      label: '购物卡支付',
      prop: 'storeCardAmount',
      width: 80
    }, {
      label: '积分支付',
      prop: 'scoreAmount',
      width: 80
    }, {
      label: '微信支付',
      prop: 'wxAmount',
      width: 80
    }, {
      label: '用户手机号',
      prop: 'mobile',
      width: 100
    }, {
      label: '用户昵称',
      prop: 'realname',
      width: 80
    }, {
      slot: 'memberType',
      label: '会员类型',
      prop: 'memberType',
      width: 80
    }, {
      label: '当日总消费',
      prop: 'totalAmount',
      width: 80
    }, {
      slot: 'status',
      label: '状态',
      prop: 'status',
      width: 80
    }, {
      label: '入场时间',
      prop: 'entryTime',
      width: 135
    }, {
      label: '出场时间',
      prop: 'outTime',
      width: 135
    }, {
      slot: "orderDea"
    }]
  }
}, {
  path: 'jianghua/order/detail',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/parking/jianghua/orderDetail.vue'));
    });
  },
  name: 'ParkingJianghuaDetail',
  meta: {
    title: '江华停车订单详情',
    activeMenu: '/parking/jianghua/tab'
  }
}, {
  path: 'jianghua/coupon',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("./coupon.vue"));
    });
  },
  name: 'ParkingJianghuaCoupon',
  meta: {
    title: '停车优惠发放记录',
    keepAlive: true,
    api: '/api/stopPark/queryStopParkNumPage',
    activeMenu: '/parking/jianghua/tab',
    pageList: 'items',
    pageTotal: 'totalNum',
    columns: [{
      label: '用户手机号',
      prop: 'mobile',
      width: 100
    }, {
      label: '用户昵称',
      prop: 'realname',
      width: 80
    }, {
      slot: 'memberType',
      label: '会员类型',
      prop: 'memberType',
      width: 80
    },
    /* {
       slot:"parkPhoto",
         label: '停车场照片',
         prop: 'parkPhoto',
     }, */

    /* {
         label: '停车场地址',
         prop: 'parkAddress',
     }, */
    {
      label: '停车场名称',
      prop: 'parkName',
      width: 100
    }, {
      slot: 'status',
      label: '状态',
      prop: 'status',
      width: 80
    }, {
      label: '操作人手机号',
      prop: 'createUserMobile',
      width: 100
    }, {
      label: '时间',
      prop: 'createTime',
      width: 140
    }, {
      label: '备注',
      prop: 'remark',
      width: 140
    }]
  }
}];
exports.default = _default;