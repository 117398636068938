"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _manage = require("@/api/manage.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      obj: {
        pageNo: 1,
        pageSize: 10,
        memberId: '',
        // 会员体系ID
        couponType: '' // 优惠卷类型

      },
      memArr: [],
      // 会员体系数组
      couponTypeArr: [{
        label: '代金券',
        value: 1
      }, {
        label: '折扣券',
        value: 2
      }, {
        label: '礼品券',
        value: 3
      }, {
        label: '体验券',
        value: 4
      }, {
        label: '外部优惠券',
        value: 5
      }],
      // 优惠券类型数组
      time: [] // 时间

    };
  },
  created: function created() {
    var _this = this;

    (0, _manage.listMemberCards)().then(function (res) {
      _this.memArr = res.data;
    });
  },
  methods: {
    init: function init() {
      console.log(this.obj);
    },

    /* 重置*/
    remove: function remove() {
      this.obj = {
        pageNo: 1,
        pageSize: 10
      };
      this.time = [];
      this.init();
    },

    /* 导出Excel*/
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['核销时间', '会员体系', '门店名称', '机构编码', '会员姓名', '会员手机号', '订单流水号', '订单金额', '券领取编码', '优惠券名称', '优惠券编码', '优惠券类型', '优惠券面额', '折扣比例', '实际优惠金额'];
        var filterVal = ['couponCode', 'couponName', 'couponType', 'statusName', 'limitDiscount', 'putNumber', 'getNumber', 'residueNumber', 'useNumber', 'unUserNumber'];
        var list = [];
        listCouponNumberPage({
          pageNo: 1,
          pageSize: _this2.total
        }).then(function (res) {
          list = res.data.items;

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '优惠券统计'
          });
          _this2.downloadLoading = false;
        }).catch(function (res) {
          _this2.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;