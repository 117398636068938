"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _integral = require("@/api/integral.js");

var _mem = require("@/api/mem.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      total: 0,
      companylist: [],
      obj: {
        companyCode: ''
      },
      list: [],
      tabTh: [{
        prop: 'companyName',
        label: '总部',
        width: 160
      }, {
        prop: 'sellAmount',
        label: '消费金额',
        width: 50
      }, {
        prop: 'getScore',
        label: '获得积分',
        width: 50
      }, {
        prop: 'storeName',
        label: '不积积分门店',
        width: 300,
        algin: 'left'
      }, {
        prop: 'unpayModel',
        label: '不积积分方式',
        width: 300,
        algin: 'left'
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companylist = res.data;
    });
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    goAdd: function goAdd() {
      this.$router.push('/integral/setScore');
    },
    setTab: function setTab(row) {
      this.$router.push('/integral/setScore?id=' + row.settingId);
    },
    init: function init() {
      var _this2 = this;

      this.openLoading();
      (0, _integral.listScoreSet)(this.obj).then(function (res) {
        _this2.openLoading().close();

        _this2.list = res.data;

        _this2.list.forEach(function (item) {
          var unpayModel = [];
          var scoreSet = [];
          item.scoreSettingUnpaymodelVos.forEach(function (row) {
            unpayModel.push(row.unpayModel);
          });
          item.scoreSettingUnstoreVos.forEach(function (row) {
            scoreSet.push(row.storeName);
          });
          item.storeName = scoreSet.join('、');
          item.unpayModel = unpayModel.join('、');
        });
      }).catch(function (err) {
        _this2.openLoading().close();
      });
    }
  }
};
exports.default = _default;