"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _public = require("@/utils/public.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      weekList: _public.weekList,
      requestData: {
        weekTag: null,
        name: null,
        startTime: null,
        endTime: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '门店编码',
        prop: 'storeCode',
        width: 100
      }, {
        label: '门店名称',
        prop: 'storeName',
        width: 80
      }, {
        label: '优惠标识',
        prop: 'discountFlag',
        width: 80
      }, {
        slot: 'operate'
      }]
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    delTem: function delTem(row) {
      var _this = this;

      this.apiGet('/api/lot-bank-activity/remove/' + row.storeCode).then(function (res) {
        _this.getList();
      });
    },
    wPage: function wPage(row) {
      this.$router.push({
        path: '/activity/dragonfly/edit',
        query: row
      });
    },
    getList: function getList(type) {
      var _this2 = this;

      (0, _api.getList)(this.requestData).then(function (res) {
        _this2.tableData = res.data;
      });
    }
  }
};
exports.default = _default;