"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _integral = require("@/api/integral.js");

var _report = require("@/api/report.js");

var _mem = require("@/api/mem.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      mobile: '',
      disabled: true,
      storeList: [],
      user: {
        realname: '',
        idNo: '',
        score: '',
        uid: ''
      },
      obj: {
        type: '',
        storeCode: '',
        changeScore: '',
        changeRemark: '',
        documentNo: ''
      },
      rules: {
        documentNo: [{
          required: true,
          message: '请填写相关单据号'
        }],
        changeScore: [{
          type: 'number',
          message: '积分必须为数字值'
        }, {
          required: true,
          message: '请填写变动积分'
        }, {
          validator: function validator(rule, value, callback) {
            if (value <= 0) {
              callback(new Error('请填写大于0值'));
            } else {
              callback();
            }
          }
        }],
        type: [{
          required: true,
          message: '请选择条件类型'
        }],
        storeCode: [{
          required: true,
          message: '请选择门店'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({
      companyId: ''
    }).then(function (res) {
      _this.storeList = res.data;
    });
  },
  methods: {
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var obj = {
            userId: _this2.user.uid,
            changeRemark: _this2.obj.changeRemark,
            storeCode: _this2.obj.storeCode,
            documentNo: _this2.obj.documentNo
          };

          if (_this2.obj.type == 1) {
            obj.changeScore = _this2.obj.changeScore;
          } else {
            obj.changeScore = -_this2.obj.changeScore;
          }

          (0, _integral.userScoreChanges)({
            userScores: [obj]
          }).then(function (res) {
            console.log(res);

            _this2.$message({
              type: 'success',
              message: '补录成功!'
            });

            _this2.$router.push('/integral/adjust/list');
          });
        } else {
          return false;
        }
      });
    },
    getUserInfo: function getUserInfo() {
      var _this3 = this;

      var data = {
        mobile: this.mobile,
        type: 1
      };
      this.openLoading();
      (0, _report.handleMidUser)(data).then(function (res) {
        _this3.openLoading().close();

        _this3.disabled = false;

        _this3.fzObj(res.data.user, _this3.user);
      }).catch(function (err) {
        _this3.openLoading().close();
      });
    }
  }
};
exports.default = _default;