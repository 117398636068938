var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-list" },
    [
      _c(
        "w-table",
        {
          attrs: {
            pageApi: _vm.api,
            columns: _vm.columns,
            "search-data": _vm.queryParams,
            mobile: ["userPhone"]
          }
        },
        [
          _c("el-table-column", {
            attrs: { slot: "prizeType", label: "奖励类型", align: "center" },
            slot: "prizeType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.prizeType == 1
                      ? _c("span", [_vm._v("会员积分")])
                      : _vm._e(),
                    _vm._v(" "),
                    row.prizeType == 2
                      ? _c("span", [_vm._v("优惠券包")])
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }