var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qingting-form" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    " + _vm._s(_vm.$route.meta.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "qingForm",
          staticClass: "qing-form",
          attrs: {
            model: _vm.qingForm,
            rules: _vm.rules,
            "label-width": "auto",
            size: "small"
          }
        },
        [
          _c(
            "div",
            { staticClass: "mask" },
            [
              _c(
                "div",
                { staticClass: "form-item-group" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "30",
                          "show-word-limit": "",
                          placeholder: "请输入名称"
                        },
                        model: {
                          value: _vm.qingForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.qingForm, "name", $$v)
                          },
                          expression: "qingForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.qingForm.status,
                            callback: function($$v) {
                              _vm.$set(_vm.qingForm, "status", $$v)
                            },
                            expression: "qingForm.status"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("启用")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("禁用")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属门店", prop: "storeId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "输入门店名称搜索~",
                            "remote-method": _vm.remoteStoreList,
                            loading: _vm.storeloading,
                            "value-key": "storeCode"
                          },
                          on: { change: _vm.storeChange },
                          model: {
                            value: _vm.qingForm.storeId,
                            callback: function($$v) {
                              _vm.$set(_vm.qingForm, "storeId", $$v)
                            },
                            expression: "qingForm.storeId"
                          }
                        },
                        _vm._l(_vm.storeOptions, function(so) {
                          return _c("el-option", {
                            key: so.storeCode,
                            attrs: {
                              label: so.fullName + " " + so.storeCode,
                              value: so.storeCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "顶部logo", prop: "logoUrl" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: "",
                            "show-file-list": false,
                            "on-change": _vm.getFile
                          }
                        },
                        [
                          _vm.qingForm.logoUrl
                            ? _c("img", {
                                ref: "phoUrl",
                                staticClass: "avatar",
                                attrs: { src: _vm.qingForm.logoUrl }
                              })
                            : _c("i", {
                                staticClass: "el-icon-plus avatar-uploader-icon"
                              })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticStyle: { color: "#757575", "font-size": "13px" }
                        },
                        [
                          _vm._v(
                            "请上传透明的黑白图片，宽度不超过480像素，最高可支持1M。"
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "二维码页面模板", prop: "barcodeId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "输入模板名称搜索~",
                            "remote-method": _vm.remotePageList,
                            loading: _vm.pageloading,
                            "value-key": "pageCode"
                          },
                          on: { change: _vm.pageChange },
                          model: {
                            value: _vm.qingForm.barcodeId,
                            callback: function($$v) {
                              _vm.$set(_vm.qingForm, "barcodeId", $$v)
                            },
                            expression: "qingForm.barcodeId"
                          }
                        },
                        _vm._l(_vm.pageOptions, function(po) {
                          return _c("el-option", {
                            key: po.pageCode,
                            attrs: {
                              label: po.pageName + " " + po.pageCode,
                              value: po.pageCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "separate-title" }, [
                _vm._v("\n        销售单二维码上方文案\n      ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-item-group" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "文案内容", prop: "salesCopyUp" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 5 },
                          placeholder: "请输入文案内容",
                          maxlength: "100",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.qingForm.salesCopyUp,
                          callback: function($$v) {
                            _vm.$set(_vm.qingForm, "salesCopyUp", $$v)
                          },
                          expression: "qingForm.salesCopyUp"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排列方向", prop: "alignmentSalesUp" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.qingForm.alignmentSalesUp,
                            callback: function($$v) {
                              _vm.$set(_vm.qingForm, "alignmentSalesUp", $$v)
                            },
                            expression: "qingForm.alignmentSalesUp"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("左对齐")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("居中对齐")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("右对齐")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "separate-title" }, [
                _vm._v("\n        销售单二维码下方文案\n      ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-item-group" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "文案内容", prop: "salesCopyDown" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 5 },
                          placeholder: "请输入内容",
                          maxlength: "100",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.qingForm.salesCopyDown,
                          callback: function($$v) {
                            _vm.$set(_vm.qingForm, "salesCopyDown", $$v)
                          },
                          expression: "qingForm.salesCopyDown"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "排列方向", prop: "alignmentSalesDown" }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.qingForm.alignmentSalesDown,
                            callback: function($$v) {
                              _vm.$set(_vm.qingForm, "alignmentSalesDown", $$v)
                            },
                            expression: "qingForm.alignmentSalesDown"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("左对齐")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("居中对齐")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("右对齐")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "separate-title" }, [
                _vm._v("\n        退款单文案\n      ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-item-group" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "文案内容", prop: "refundOrderCopy" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 5 },
                          placeholder: "请输入内容",
                          maxlength: "100",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.qingForm.refundOrderCopy,
                          callback: function($$v) {
                            _vm.$set(_vm.qingForm, "refundOrderCopy", $$v)
                          },
                          expression: "qingForm.refundOrderCopy"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排列方向", prop: "alignmentRefund" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.qingForm.alignmentRefund,
                            callback: function($$v) {
                              _vm.$set(_vm.qingForm, "alignmentRefund", $$v)
                            },
                            expression: "qingForm.alignmentRefund"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("左对齐")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("居中对齐")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("右对齐")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "qing-form-buttons" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleCommit }
                    },
                    [_vm._v("添加完成")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v("取消")
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }