var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "type" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(_vm.$route.meta.title) + "\n        "),
        _c(
          "div",
          [
            _vm.setMenuPerms("receiptSetting:add")
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus"
                    },
                    on: { click: _vm.onCreate }
                  },
                  [_vm._v("新增")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "requestParamsForm",
          staticClass: "request-params",
          attrs: { inline: true, model: _vm.requestParams, size: "small" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "规则名称" },
                model: {
                  value: _vm.requestParams.name,
                  callback: function($$v) {
                    _vm.$set(_vm.requestParams, "name", $$v)
                  },
                  expression: "requestParams.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "storeId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "门店",
                    "remote-method": _vm.remoteStoreList,
                    loading: _vm.storeloading
                  },
                  model: {
                    value: _vm.requestParams.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.requestParams, "storeId", $$v)
                    },
                    expression: "requestParams.storeId"
                  }
                },
                _vm._l(_vm.storeOptions, function(so, sindex) {
                  return _c(
                    "el-option",
                    {
                      key: sindex,
                      attrs: { value: so.storeCode, label: so.fullName }
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(so.fullName))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#8492a6",
                            "font-size": "13px"
                          }
                        },
                        [_vm._v(_vm._s(so.storeCode))]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "brand" } },
            [
              _c("el-input", {
                attrs: { placeholder: "品牌名称" },
                model: {
                  value: _vm.requestParams.brand,
                  callback: function($$v) {
                    _vm.$set(_vm.requestParams, "brand", $$v)
                  },
                  expression: "requestParams.brand"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "状态" },
                  model: {
                    value: _vm.requestParams.status,
                    callback: function($$v) {
                      _vm.$set(_vm.requestParams, "status", $$v)
                    },
                    expression: "requestParams.status"
                  }
                },
                _vm._l(_vm.statusOps, function(sop, sIndex) {
                  return _c("el-option", {
                    key: sIndex,
                    attrs: { label: sop.l, value: sop.k }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onQuery } },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.onReset } },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            size: "mini",
            "header-cell-style": { background: "#f5f5f5" },
            border: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              align: "center",
              width: "80"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "名称", prop: "name", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "门店", prop: "storeName", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "品牌", prop: "brand", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "获得积分数", prop: "scale", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注", prop: "remark", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", prop: "createTime", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "status", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm.setMenuPerms("receiptSetting:check")
                      ? _c("el-switch", {
                          attrs: { "active-value": 1, "inactive-value": 2 },
                          on: {
                            change: function($event) {
                              return _vm.onChangeStatus(row)
                            }
                          },
                          model: {
                            value: row.status,
                            callback: function($$v) {
                              _vm.$set(row, "status", $$v)
                            },
                            expression: "row.status"
                          }
                        })
                      : _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("opLabel")(row.status, _vm.statusOps))
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              prop: "action",
              width: "150",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "text-btn",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.onDetail(row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    _vm.setMenuPerms("receiptSetting:edit")
                      ? _c(
                          "el-button",
                          {
                            staticClass: "text-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onUpdate(row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.setMenuPerms("receiptSetting:delete")
                      ? _c(
                          "el-button",
                          {
                            staticClass: "text-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onDelete(row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.requestParams.pageNo,
          limit: _vm.requestParams.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.requestParams, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.requestParams, "pageSize", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }