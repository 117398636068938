"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '分类名称',
        prop: 'categoryName',
        width: 80
      }, {
        label: '排序',
        prop: 'sort',
        width: 80
      }, {
        slot: 'status'
      }, {
        label: '备注',
        prop: 'note',
        width: 180
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 180
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '分类名称',
        value: 'categoryName'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '状态',
        value: 'status',
        select: [{
          label: '启用',
          value: 1
        }, {
          label: '停用',
          value: -1
        }]
      }]
    };
  },
  methods: {
    delGrade: function delGrade(row, index) {
      var _this = this;

      this.apiGet('/api/org-store/activity/removeCtgById/' + row.id).then(function (res) {
        _this.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;