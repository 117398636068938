var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "departmentDea" },
      [
        _c("div", { staticClass: "view_title" }, [_vm._v("补365奖励")]),
        _vm._v(" "),
        _c("el-divider"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "departmentDea_input" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  size: "small",
                  "label-width": "150px"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "手机号码：", prop: "mobile" } },
                  [
                    _c("el-input", {
                      staticClass: "form_input",
                      attrs: {
                        placeholder: "请输入补发的手机号",
                        maxlength: "11",
                        type: "number"
                      },
                      model: {
                        value: _vm.ruleForm.mobile,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "mobile", $$v)
                        },
                        expression: "ruleForm.mobile"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "订单号：", prop: "orderNo" } },
                  [
                    _c("el-input", {
                      staticClass: "form_input",
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 4, maxRows: 10 },
                        placeholder: "请输入内容"
                      },
                      model: {
                        value: _vm.ruleForm.orderNo,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "orderNo",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.orderNo"
                      }
                    }),
                    _vm._v(" "),
                    _c("p", [_vm._v("多个订单用英文逗号分开")])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.submitForm("ruleForm")
                          }
                        }
                      },
                      [_vm._v("立即补发")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }