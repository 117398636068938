"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '分类名称',
        prop: 'categoryName',
        width: 80
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '分类名称',
        value: 'categoryName'
      }]
    };
  },
  methods: {
    delGrade: function delGrade(row, index) {
      var _this = this;

      this.apiGet('/api/smsTask/delSmsCategory/' + row.id).then(function (res) {
        _this.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;