module.exports = {
  payTypeList: [{
    label: '米卡购物卡',
    value: '1'
  }, {
    label: '微信',
    value: '2'
  }, {
    label: '支付宝',
    value: '3'
  }, {
    label: '现金',
    value: '4'
  }, {
    label: '积分',
    value: '5'
  }, {
    label: '银联云闪付',
    value: '6'
  }, {
    label: '贵阳银行券',
    value: '7'
  }, {
    label: '微信刷脸',
    value: '8'
  }, {
    label: '提货券',
    value: '9'
  }, {
    label: '线下购物卡',
    value: '10'
  }, {
    label: '微信补录',
    value: '11'
  }, {
    label: '支付宝补录',
    value: '12'
  }, {
    label: '学校IC卡支付',
    value: '13'
  }, {
    label: '银行卡补录',
    value: '14'
  }, {
    label: '米卡提货卡券',
    value: '15'
  }, {
    label: '黔农云支付',
    value: '16'
  }, {
    label: '线下不记名卡支付',
    value: '17'
  }, {
    label: '建行龙支付',
    value: '18'
  }, {
    label: '汇联通',
    value: '21'
  }]
};