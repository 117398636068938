"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default,
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      temList: [],
      HZlist: [{
        label: '仅一次',
        value: 1
      }, {
        label: '每日一次',
        value: 2
      }, {
        label: '每次进入',
        value: 3
      }],
      weekList: [{
        label: '启用',
        value: 1
      }, {
        label: '禁用',
        value: -1
      }],
      popType: '',
      couponShow: false,
      type: [{
        label: '优惠券',
        value: 1
      }, {
        label: '券包',
        value: 2
      }, {
        label: '实体商品',
        value: 3
      }, {
        label: '购物卡',
        value: 5
      }],
      rules: {
        productConfigs: [{
          required: true,
          message: '请添加物品'
        }],
        title: [{
          required: true,
          message: '请输入昵称'
        }],
        shortTitle: [{
          required: true,
          message: '请输入简称'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        introduction: [{
          required: true,
          message: '请输入介绍'
        }],
        ruleIntroduction: [{
          required: true,
          message: '请输入描述'
        }],
        popFlag: [{
          required: true,
          message: '请选择弹窗标志'
        }],
        popImage: [{
          required: true,
          message: '请上传图片'
        }],
        popInterval: [{
          required: true,
          message: '请选择弹窗频率'
        }]
      },
      ruleForm: {
        title: null,
        shortTitle: null,
        status: null,
        introduction: null,
        ruleIntroduction: null,
        popFlag: null,
        popInterval: null,
        productConfigs: [],
        popImage: 'mika/dev/2022-04-26/65e5ba0c316342ea92dcf855140dd3b3.png'
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.getGoodsData)().then(function (res) {
      if (!res.data) return;
      _this.ruleForm = res.data;
    });
  },
  methods: {
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    changeLimit: function changeLimit(e) {
      if (e === 0) {
        this.ruleForm.limitNumber = 0;
      }
    },
    getRelevances: function getRelevances() {
      console.log(11111111);
      this.ruleForm.productConfigs.push({
        type: null,
        productCode: null,
        productImage: null,
        productName: null,
        productNum: null,
        remark: null
      });
    },

    /*
        选择奖励项
        */
    setAward: function setAward(e, index) {
      this.ruleForm.productConfigs[index].productCode = null;
      this.ruleForm.productConfigs[index].productDetail = null;
      this.ruleForm.productConfigs[index].productName = null;
      this.ruleForm.productConfigs[index].productImage = null;
      this.ruleForm.productConfigs[index].productNum = null;
      this.ruleForm.productConfigs[index].remark = null;
    },

    /*
        打开弹框
        */
    getAward: function getAward(e, index) {
      this.count = index;
      var selectType = {
        1: 'coupon',
        2: 'couponBag',
        3: 'goods',
        5: 'card'
      };
      this.popType = selectType[e];
      this.$refs.coupon1.getList(this.popType, this.ruleForm.productConfigs[index].productCode);
      this.couponShow = true;
    },

    /*
        删除奖励项
        */
    delAward: function delAward(index) {
      this.ruleForm.productConfigs.splice(index, 1);
    },
    setCoupon: function setCoupon(e) {
      console.log(e);
      var godosItem = this.ruleForm.productConfigs[this.count];
      var goodsType = {
        coupon: {
          productCode: 'uuid',
          productName: 'name',
          productImage: 'mainImage'
        },
        couponBag: {
          productCode: 'uuid',
          productName: 'name',
          productImage: 'tableImage'
        },
        goods: {
          productCode: 'productCode',
          productName: 'productName',
          productImage: 'imageUrl'
        },
        card: {
          productCode: 'uuid',
          productName: 'name',
          productImage: 'cover'
        }
      };
      godosItem.productCode = e[goodsType[this.popType].productCode];
      godosItem.productName = e[goodsType[this.popType].productName];
      godosItem.productImage = e[goodsType[this.popType].productImage];
      this.couponShow = false;
      this.popType = '';
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.addData)(_this2.ruleForm).then(function (res) {
            _this2.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;