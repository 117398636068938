var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            isPageDwn: "",
            "search-data": _vm.searchData,
            mobile: ["mobile"]
          },
          on: { goPageDwn: _vm.goPageDwn }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "80"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 1
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("已核销")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 2
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("已撤销")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 3
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("核销失败")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "type",
              label: "类型",
              align: "center",
              width: "80"
            },
            slot: "type",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.type === 1
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("优惠券")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type === 2
                      ? _c("span", { staticClass: "danger" }, [_vm._v("券包")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "source",
              label: "核销路径",
              align: "center",
              width: "100"
            },
            slot: "source",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.source === 3
                      ? _c("span", { staticClass: "success" }, [
                          _vm._v("蜻蜓收银机")
                        ])
                      : scope.row.source === 2
                      ? _c("span", { staticClass: "danger" }, [
                          _vm._v("昂捷收银机")
                        ])
                      : _c("span", { staticClass: "warning" }, [
                          _vm._v("核销助手")
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "storeName",
              label: "核销门店",
              align: "center",
              width: "150"
            },
            slot: "storeName",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.storeName) +
                        "(" +
                        _vm._s(scope.row.storeCode) +
                        ")\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "dyConponInfo",
              label: "抖音券信息",
              align: "center",
              width: "400"
            },
            slot: "dyConponInfo",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.writeoffInfo
                      ? _c(
                          "div",
                          _vm._l(JSON.parse(scope.row.writeoffInfo), function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "flex_between" },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n              抖音订单号：" +
                                      _vm._s(item.orderNo) +
                                      "\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n              核销:" +
                                      _vm._s(item.verifyId) +
                                      "\n            "
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 3
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.dyConponInfo) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "cancel",
              label: "操作",
              align: "center",
              width: "80"
            },
            slot: "cancel",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.ifCancel &&
                    _vm.setMenuPerms("cancelDy:coupon") &&
                    scope.row.status === 1
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认撤销核销？" },
                            on: {
                              confirm: function($event) {
                                return _vm.goCasancelDyCoupon(scope.row)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("撤销核销")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }