"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSubsidy = addSubsidy;
exports.addSubsidyClass = addSubsidyClass;
exports.editSubsidy = editSubsidy;
exports.editSubsidyClass = editSubsidyClass;
exports.getSubsidy = getSubsidy;
exports.getSubsidyClass = getSubsidyClass;
exports.listSubsidyClass = listSubsidyClass;
exports.listSubsidyPage = listSubsidyPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
接口：Subsidy 更换为 Subsidy
实体属性：subsidy 更换为 subsidy
 */

/*
1、新增会员同城分类：/api/vipSubsidyClass/addSubsidyClass
{"vipCode":"会员编码","className":"分类名称","classSort":"分类排序","classStatus":" 分类状态(0：停用 1：启用)"}

2、编辑会员同城分类：/api/vipSubsidyClass/editSubsidyClass
{"id":"id","vipCode":"会员编码","className":"分类名称","classSort":"分类排序","classStatus":" 分类状态(0：停用 1：启用)"}

3、会员同城分类明细：/api/vipSubsidyClass/getSubsidyClass/{id}

4、会员同城分类列表：/api/vipSubsidyClass/listSubsidyClass
{"pageNo":"当前页","pageSize":"页条数","vip_code":"会员编码","class_status":"分类状态"}



 */
function addSubsidyClass(data) {
  return (0, _request.default)({
    url: '/api/vipSubsidyClass/addSubsidyClass',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editSubsidyClass(data) {
  return (0, _request.default)({
    url: '/api/vipSubsidyClass/editSubsidyClass',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getSubsidyClass(data) {
  return (0, _request.default)({
    url: '/api/vipSubsidyClass/getSubsidyClass/' + data,
    method: 'post',
    loading: 'loading'
  });
}

function listSubsidyClass(data) {
  return (0, _request.default)({
    url: '/api/vipSubsidyClass/listSubsidyClass',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
5、新增会员硬核好券：/api/vipSubsidy/addSubsidy
{"subsidys":"[{"vipCode":"会员编码","classId":"分类id","productCode":"商品编码","productType":"商品类型(1：积分商城商品 2：直充商品 3：优惠券)","putNumber":"投放数量","productSort":"商品排序"}]"}

6、编辑会员硬核好券：/api/vipSubsidy/editSubsidy
{"id":"id","vipCode":"会员编码","classId":"分类id","productCode":"商品编码","productType":"商品类型(1：积分商城商品 2：直充商品 3：优惠券)","putNumber":"投放数量","productSort":"商品排序"}

7、会员硬核好券明细：/api/vipSubsidy/getSubsidy/{id}

8、会员硬核好券分页：/api/vipSubsidy/listSubsidyPage
{"pageNo":"当前页","pageSize":"页条数","vipCode":"会员编码","classId":"分类id","productCode":"商品编码"}
 */


function listSubsidyPage(data) {
  return (0, _request.default)({
    url: '/api/vipSubsidy/listSubsidyPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addSubsidy(data) {
  return (0, _request.default)({
    url: '/api/vipSubsidy/addSubsidy',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editSubsidy(data) {
  console.log(JSON.stringify(data));
  return (0, _request.default)({
    url: '/api/vipSubsidy/editSubsidy',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getSubsidy(data) {
  return (0, _request.default)({
    url: '/api/vipSubsidy/getSubsidy/' + data,
    method: 'post',
    loading: 'loading'
  });
}