"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.set");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.constructor");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    UploadExcelComponent: _index.default
  },
  props: {
    couponName: {
      type: String,
      default: '优惠券商品码'
    },
    couponType: {
      type: [Number, String],
      default: 1
    },
    treeArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tableData1: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    institutionIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    color: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      addGoods: null,
      addGoods1: null,
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/couponGoods.xlsx"),
      txt: 1,
      numShow: true,
      popShow: false,
      tableHeader: [],
      filterText: '',
      defaultProps: {
        children: 'childNodes',
        label: function label(a, b) {
          return a.fullName + '   (' + a.code + ')';
        },
        disabled: function disabled(data, node) {
          if (node.level === 1) {
            return true;
          }
        }
      },
      allId: [],
      // 一级id
      systemNum: 0,
      // 所选体系总数
      orgNum: 0,
      // 所选机构总数
      childNodes: [],
      // 所有选中子节点
      parentIdArr: []
    };
  },
  watch: {
    filterText: function filterText(val) {
      // 树形搜索框
      this.$refs.tree2.filter(val);
    },
    color: function color(val) {
      this.txt = val;
    }
  },
  created: function created() {// console.log(this.institutionIds)
  },
  mounted: function mounted() {
    this.checkChange();
  },
  methods: {
    goAddGoods: function goAddGoods(isSupermarket) {
      if (isSupermarket) {
        if (this.tableData1.includes(this.addGoods1)) return;
        var arr = JSON.parse(JSON.stringify(this.tableData1));
        arr.push(this.addGoods1);
        this.$emit('setGoods', arr, this.txt, 'isSupermarket');
      } else {
        if (this.tableData.includes(this.addGoods)) return;

        var _arr = JSON.parse(JSON.stringify(this.tableData));

        _arr.push(this.addGoods);

        this.$emit('setGoods', _arr, this.txt);
      }
    },
    handleDownload: function handleDownload() {
      var Timestamp = new Date().getTime();
      var tableData = this.tableData.map(function (item) {
        return {
          CommodityCode: item
        };
      });
      this.getHandleDownload(['CommodityCode'], ['CommodityCode'], tableData, this.couponName + Timestamp);
    },
    delGoods: function delGoods(item, index, isSupermarket) {
      var _self = this;

      this.$confirm("\u786E\u8BA4\u79FB\u9664\u5546\u54C1\u7801".concat(item, ", \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _self.$emit('delGoodsItem', index, isSupermarket);
      });
    },
    setCheckedKeys: function setCheckedKeys(arr) {
      console.log(arr);
      this.$refs.tree2.setCheckedKeys(arr);
    },
    next: function next() {
      // this.$emit('setTable', this.childNodes, this.parentIdArr, this.color, this.tableData)
      this.popShow = false;
      this.numShow = true;
    },
    choice: function choice(isSupermarket) {
      this.popShow = true;
      this.numShow = false;
      this.isSupermarket = isSupermarket;
    },
    getTreeNode: function getTreeNode(node, key) {
      // 获取当前树节点和其父级节点
      var parent = '';

      if (node) {
        if (node.level !== 1) {
          this.getTreeNode(node.parent, key); // 递归
        } else {
          this.parentIdArr[key].parentId = node.data.code;
        }
      }
    },
    checkChange: function checkChange(data, checked, node) {
      var _this = this;

      // 树形选择时
      if (this.couponType == 5 && checked == true) {
        this.$refs.tree2.setCheckedNodes([data]);
      }

      var arrNode = this.$refs.tree2.getCheckedNodes();
      this.childNodes = [];
      this.parentIdArr = [];
      arrNode.forEach(function (item, key) {
        _this.parentIdArr.push({
          childNodes: _this.$refs.tree2.getNode(item).data.code,
          parentId: ''
        });

        _this.getTreeNode(_this.$refs.tree2.getNode(item), key);
      });
      var arr = [];
      this.parentIdArr.forEach(function (item) {
        arr.push(item.parentId);
      });
      arr = (0, _toConsumableArray2.default)(new Set(arr));
      arr.forEach(function (item) {
        var childArr = [];

        _this.parentIdArr.forEach(function (row) {
          if (row.parentId == item) {
            childArr.push(row.childNodes);
          }
        });

        _this.childNodes.push(childArr);
      });
      this.$emit('setTable', this.childNodes, arr, this.txt, this.tableData, this.tableData1, arrNode);
    },
    filterNode: function filterNode(value, data) {
      // 树形搜索框
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      results.forEach(function (data) {
        if (data.CommodityCode) {
          var CommodityCode = String(data.CommodityCode);
          arr.push(CommodityCode.trim());
        }
      });
      this.$emit('setGoods', arr, this.txt, this.isSupermarket);
      this.tableHeader = header;
    }
  }
};
exports.default = _default2;