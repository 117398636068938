var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "group-card-from" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(_vm.$route.meta.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { model: _vm.params, "label-width": "180px" }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "input-width", attrs: { label: "计划名称" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择卡种" } },
            [
              _c("el-button", { attrs: { type: "primary", size: "small" } }, [
                _vm._v("选择卡种")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "制卡数量" } },
            [
              _c("el-input-number", {
                attrs: { min: 10, max: 1000 },
                model: {
                  value: _vm.params.num,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "num", $$v)
                  },
                  expression: "params.num"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "制卡商" } },
            [
              _c("el-select", {
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "input-width", attrs: { label: "备注" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", autosize: { minRows: 5 } },
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "actions" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }