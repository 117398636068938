"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name'])),
  data: function data() {
    return {
      tableData: [],
      requestData: {
        name: null,
        account: null,
        pageSize: 10,
        page: 1
      },
      searchData: [{
        label: '发券人手机名称',
        type: 'input',
        span: 4,
        placeholder: '请输入发券人名称',
        value: 'sysUserName'
      }, {
        label: '发券人手机号码',
        type: 'input',
        span: 4,
        placeholder: '请输入发券人手机号码',
        value: 'sysUserMobile'
      }, {
        label: '会员手机号码',
        type: 'input',
        span: 4,
        placeholder: '请输入会员手机号码',
        value: 'userMobile'
      }, {
        label: '门店编码',
        type: 'input',
        span: 4,
        placeholder: '请输入门店编码',
        value: 'storeCode'
      }]
    };
  }
};
exports.default = _default;