"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _member = require("@/api/member.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      obj: {
        pageNo: 1,
        pageSize: 10,
        mobile: '',
        idNo: '',
        startDate: '',
        endDate: ''
      },
      searchTime: [],
      list: [],
      tabTh: [{
        prop: 'realname',
        label: '会员姓名',
        width: 100
      }, {
        prop: 'mobile',
        label: '手机号码',
        width: 100
      }, {
        prop: 'idNo',
        label: '身份证号码',
        width: 140
      }, {
        prop: 'birthday',
        label: '生日',
        width: 120
      }, {
        prop: 'sex',
        label: '性别',
        width: 80
      }, {
        prop: 'status',
        label: '状态',
        width: 80
      }, {
        prop: 'createTime',
        label: '创建时间',
        width: 120
      }, {
        prop: 'oprUname',
        label: '创建人',
        width: 80
      }],
      total: 0
    };
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
        this.searchTime = [];
      }

      this.obj.startDate = this.searchTime[0] || '';
      this.obj.endDate = this.searchTime[1] || '';
      this.openLoading();
      (0, _member.listMainUserPage)(this.obj).then(function (res) {
        _this.openLoading().close();

        _this.list = res.data.items;
        _this.total = res.data.totalNum;
        res.data.items.forEach(function (item) {
          item.status = item.status == 1 ? '正常' : '封禁';

          switch (item.sex) {
            case 0:
              item.sex = '未知';
              break;

            case 1:
              item.sex = '男';
              break;

            case 2:
              item.sex = '女';
              break;

            default:
              break;
          }
        });
      }).catch(function (err) {
        _this.openLoading().close();
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/member/addMember');
    }
  }
};
exports.default = _default;