var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("新增物品")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { ref: "weekTag", attrs: { label: "周期：", prop: "weekTag" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.weekCahnge },
                      model: {
                        value: _vm.ruleForm.weekTag,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "weekTag", $$v)
                        },
                        expression: "ruleForm.weekTag"
                      }
                    },
                    _vm._l(_vm.weekList, function(item, index) {
                      return _c(
                        "el-radio",
                        { key: index, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属栏目：", prop: "cycleCategoryId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.ruleForm.cycleCategoryId,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "cycleCategoryId", $$v)
                        },
                        expression: "ruleForm.cycleCategoryId"
                      }
                    },
                    _vm._l(_vm.temList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.ruleForm.id
                ? _c(
                    "el-form-item",
                    { attrs: { label: "物品类型：", prop: "productType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form_input",
                          attrs: { size: "mini", placeholder: "物品类型" },
                          model: {
                            value: _vm.ruleForm.productType,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "productType", $$v)
                            },
                            expression: "ruleForm.productType"
                          }
                        },
                        _vm._l(_vm.type, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物品信息：", prop: "goodsLIst" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.getRelevances }
                    },
                    [_vm._v("添加物品")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.ruleForm.goodsLIst,
                        size: "mini",
                        border: ""
                      }
                    },
                    [
                      _vm.ruleForm.id
                        ? _c("el-table-column", {
                            attrs: {
                              label: "物品类型",
                              "min-width": "100",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref:
                                            "goodsLIst." +
                                            scope.$index +
                                            ".productType",
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "goodsLIst." +
                                              scope.$index +
                                              ".productType",
                                            required: "",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请选择物品类型"
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                placeholder: "物品类型"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.setAward(
                                                    $event,
                                                    scope.$index
                                                  )
                                                }
                                              },
                                              model: {
                                                value: scope.row.productType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "productType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.productType"
                                              }
                                            },
                                            _vm._l(_vm.type, function(item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              685799491
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ruleForm.id
                        ? _c("el-table-column", {
                            attrs: {
                              label: "关联物品",
                              "min-width": "200",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref:
                                            "goodsLIst." +
                                            scope.$index +
                                            ".productCode",
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "goodsLIst." +
                                              scope.$index +
                                              ".productCode",
                                            required: "",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请选择物品"
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          typeof scope.row.productType ===
                                          "number"
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.getAward(
                                                        scope.row.productType,
                                                        scope.$index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.attrType == 1
                                                          ? "选择优惠券"
                                                          : "选择商品"
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2327110064
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "物品编码",
                          "min-width": "200",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    scope.row.productCode
                                      ? _c("p", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(scope.row.productCode) +
                                              "\n                "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "物品名称",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.productName || "") +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "库存", width: "160", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "goodsLIst." +
                                      scope.$index +
                                      ".cycleStock",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "goodsLIst." +
                                        scope.$index +
                                        ".cycleStock",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入库存"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        disabled: scope.row.productType != 1,
                                        controls: false,
                                        min: 0,
                                        max: scope.row.maxNum
                                      },
                                      model: {
                                        value: scope.row.cycleStock,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "cycleStock", $$v)
                                        },
                                        expression: "scope.row.cycleStock"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "form_remark" }, [
                                      _vm._v("0为商品总库存")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "排序(倒序)",
                          width: "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref: "goodsLIst." + scope.$index + ".sort",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "goodsLIst." + scope.$index + ".sort",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入排序"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value: scope.row.sort,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "sort", $$v)
                                        },
                                        expression: "scope.row.sort"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "物品金额",
                          width: "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref: "goodsLIst." + scope.$index + ".price",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "goodsLIst." + scope.$index + ".price",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入排序"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value: scope.row.price,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "price", $$v)
                                        },
                                        expression: "scope.row.price"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "状态", width: "100", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "active-value": 1,
                                    "inactive-value": -1
                                  },
                                  model: {
                                    value: scope.row.status,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "status", $$v)
                                    },
                                    expression: "scope.row.status"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "确认删除该物品？" },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.delAward(scope.$index)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "danger",
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                          size: "mini"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      }),
      _vm._v(" "),
      _vm.isShowSelect
        ? _c("SelectTabel", {
            ref: "selectPop",
            attrs: { type: _vm.popType },
            on: {
              setCoupons: _vm.setTabelData,
              closeCouponPop: _vm.closeSelect
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }