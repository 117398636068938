var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(this.$route.meta.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, size: "small", "label-width": "150px" }
        },
        [
          _vm._l(_vm.fields, function(item, index) {
            return _c(
              "el-form-item",
              {
                key: index,
                staticClass: "form_item",
                attrs: {
                  label: item.label,
                  prop: item.value,
                  rules: item.rules
                }
              },
              [
                item.type === "textarea"
                  ? _c("el-input", {
                      attrs: {
                        type: "textarea",
                        placeholder: _vm._f("placeholderLabel")(
                          item.placeholderLabel || item.label
                        ),
                        autosize: { minRows: 4, maxRows: 6 },
                        "show-word-limit": ""
                      },
                      model: {
                        value: _vm.form[item.value],
                        callback: function($$v) {
                          _vm.$set(_vm.form, item.value, $$v)
                        },
                        expression: "form[item.value]"
                      }
                    })
                  : item.type === "radio"
                  ? _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form[item.value],
                          callback: function($$v) {
                            _vm.$set(_vm.form, item.value, $$v)
                          },
                          expression: "form[item.value]"
                        }
                      },
                      _vm._l(item.options, function(op) {
                        return _c(
                          "el-radio",
                          { key: op.value, attrs: { label: op.value } },
                          [_vm._v(_vm._s(op.label))]
                        )
                      }),
                      1
                    )
                  : item.type === "select"
                  ? _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          placeholder: _vm._f("placeholderLabel")(
                            item.placeholderLabel || item.label
                          )
                        },
                        model: {
                          value: _vm.form[item.value],
                          callback: function($$v) {
                            _vm.$set(_vm.form, item.value, $$v)
                          },
                          expression: "form[item.value]"
                        }
                      },
                      _vm._l(item.options, function(op) {
                        return _c("el-option", {
                          key: op.value,
                          attrs: { value: op.value, label: op.label }
                        })
                      }),
                      1
                    )
                  : _c("el-input", {
                      attrs: {
                        placeholder: _vm._f("placeholderLabel")(
                          item.placeholderLabel || item.label
                        )
                      },
                      model: {
                        value: _vm.form[item.value],
                        callback: function($$v) {
                          _vm.$set(_vm.form, item.value, $$v)
                        },
                        expression: "form[item.value]"
                      }
                    }),
                _vm._v(" "),
                _c("div", { staticClass: "form_item_tip" }, [
                  _vm._v(_vm._s(item.tip))
                ])
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_buttons" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSave } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }