"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _tableList = _interopRequireDefault(require("@/components/tableList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    tableList: _tableList.default
  },
  data: function data() {
    return {
      name: '',
      time: '',
      site: '',
      creatTime: '',
      list: [{
        time: '5455454'
      }, {
        time: '5455454'
      }, {
        time: '5455454'
      }, {
        time: '5455454'
      }, {
        time: '5455454'
      }],
      tabTh: [{
        tit: '提交时间',
        prop: 'time'
      }, {
        tit: '会员手机号',
        prop: 'time'
      }, {
        tit: '会员手昵称',
        prop: 'time'
      }]
    };
  },
  methods: {
    exportExcel: function exportExcel() {
      var _this = this;

      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['提交时间', '会员手机号', '会员手昵称'];
        var filterVal = ['time', 'time', 'time'];
        var list = _this.list;

        var data = _this.formatJson(filterVal, list);

        excel.export_json_to_excel({
          header: tHeader,
          data: data
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;