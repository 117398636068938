var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          attrs: {
            disabled: _vm.disabled,
            accept: ".mp4",
            action: _vm.action,
            headers: _vm.headers,
            "show-file-list": false,
            "on-success": _vm.handleSuccess,
            "on-error": _vm.onError,
            "before-upload": _vm.beforeAvatarUpload,
            "on-change": _vm.beforeAvatarUpload
          }
        },
        [
          _c(
            "div",
            [
              _vm.cover
                ? _c("span", [
                    _vm._v(
                      "\n      " + _vm._s(_vm.imgSrc + _vm.cover) + "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v("上传视频")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }