"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _mem = require("@/api/mem.js");

var _member = require("@/api/member.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      stockNumber: '',
      id: '',
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableShow: false,
      list: [],
      tabTh: [{
        prop: 'stockNumber',
        label: ' 调整数量',
        width: 80
      },
      /* {
        prop: 'stockNumber',
        label: ' 调整积分价格',
        width: 80
      }, */
      {
        prop: 'oprUid',
        label: ' 操作人id',
        width: 80
      }, {
        prop: 'oprUname',
        label: '操作人姓名',
        width: 80
      }, {
        prop: 'oprRemark',
        label: ' 操作人备注',
        width: 80
      }, {
        prop: 'oprTime',
        label: ' 操作时间',
        width: 80
      }],
      companyList: [],
      storeList: [],
      cardList: [{
        value: 1,
        label: '生日礼'
      }, {
        value: 2,
        label: '升级礼'
      }, {
        value: 3,
        label: '积兑卡'
      }, {
        value: 5,
        label: '积分礼品'
      }, {
        value: 6,
        label: '幸运礼'
      }, {
        value: 7,
        label: '2020年积分兑换'
      }, {
        value: 4,
        label: '其他'
      }],
      ruleForm: {
        companyCode: '',
        storeCode: '',
        giftName: '',
        giftType: '',
        giftWorth: '',
        purchasingPrice: '',
        stockNumber: '',
        scorePrice: '',
        oprRemark: '',
        giftStatus: '',
        stockStatus: '',
        discountScore: ''
      },
      rules: {
        /* discountScore: [{
          required: true,
          message: '请输入365积分价格'
        }], */
        stockStatus: [{
          required: true,
          message: '请选择调整类型'
        }],
        giftStatus: [{
          required: true,
          message: '请选择状态'
        }],
        companyCode: [{
          required: true,
          message: '请选择总部'
        }],
        storeCode: [{
          required: true,
          message: '请选择门店'
        }],
        giftName: [{
          required: true,
          message: '请输入会员礼品名称'
        }],
        giftType: [{
          required: true,
          message: '请选择礼品类型'
        }],
        oprRemark: [{
          required: true,
          message: '请输入备注'
        }],
        purchasingPrice: [{
          required: true,
          message: '请正确输入进货价格'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 0) {
              callback(new Error('请输入大于0值'));
            } else {
              callback();
            }
          }
        }],
        stockNumber: [{
          required: true,
          message: '请正确输数量'
        }, {
          validator: function validator(rule, value, callback) {
            if (!/(^[0-9]\d*$)/.test(value)) {
              callback(new Error('请输入大于0的正整数'));
            } else {
              callback();
            }
          }
        }],
        scorePrice: [{
          required: true,
          message: '请正确输积分价格'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 0) {
              callback(new Error('请输入不小于0值'));
            } else {
              callback();
            }
          }
        }],
        giftWorth: [{
          required: true,
          message: '请填写大于0值'
        }, {
          validator: function validator(rule, value, callback) {
            if (value <= 0) {
              callback(new Error('请填写大于0值'));
            } else {
              callback();
            }
          }
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _member.listType)({}).then(function (res) {
      console.log(res);
      _this.cardList = res.data;
    });
    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companyList = res.data;
    });
    (0, _mem.listStore)({
      companyId: ''
    }).then(function (res) {
      _this.storeList = res.data;
    });
    this.id = this.$route.query.id;

    if (this.id) {
      this.init();
      this.getdata();
    }
  },
  methods: {
    statusChage: function statusChage(e) {
      if (e === 4) {
        this.ruleForm.stockNumber = 0;
      }
    },
    getdata: function getdata() {
      var _this2 = this;

      (0, _member.getGiftById)(this.id).then(function (res) {
        _this2.stockNumber = res.data.stockNumber;
        res.data.stockNumber = '';
        Object.keys(_this2.ruleForm).forEach(function (item) {
          Object.keys(res.data).forEach(function (row) {
            if (row == item) {
              _this2.ruleForm[item] = res.data[item];
            }
          });
        });
      });
    },
    init: function init() {
      var _this3 = this;

      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        giftId: this.id
      };
      (0, _member.listGiftLogPage)(data).then(function (res) {
        _this3.list = res.data.items;
        _this3.total = res.data.totalNum;
        _this3.tableShow = true;
      });
    },
    setShort: function setShort(e) {
      var _this4 = this;

      this.ruleForm.storeCode = '';
      var companyId = '';
      this.companyList.forEach(function (item) {
        if (item.companyCode == e) {
          companyId = item.companyId;
        }
      });
      (0, _mem.listStore)({
        companyId: companyId
      }).then(function (res) {
        _this4.storeList = res.data;
      });
    },
    submitScore: function submitScore() {
      var _this5 = this;

      var data = {
        id: this.id,
        stockStatus: 4,
        discountScore: this.ruleForm.discountScore
      };
      (0, _member.editStockNumber)(data).then(function (res) {
        _this5.$message({
          message: '编辑成功',
          type: 'success'
        });

        _this5.getdata();
      });
    },
    submitForm: function submitForm(formName) {
      var _this6 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this6.openLoading();

          if (_this6.id) {
            var data = {
              id: _this6.id,
              stockNumber: _this6.ruleForm.stockNumber,
              giftStatus: _this6.ruleForm.giftStatus,
              oprRemark: _this6.ruleForm.oprRemark,
              stockStatus: _this6.ruleForm.stockStatus,
              discountScore: _this6.ruleForm.discountScore
            };
            (0, _member.editStockNumber)(data).then(function (res) {
              _this6.openLoading().close();

              _this6.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this6.$router.replace('/member/gift/list');
            }).catch(function () {
              _this6.openLoading().close();
            });
          } else {
            (0, _member.addGift)(_this6.ruleForm).then(function (res) {
              _this6.openLoading().close();

              _this6.$message({
                message: '新增成功',
                type: 'success'
              });

              _this6.$router.replace('/member/gift/list');
            }).catch(function () {
              _this6.openLoading().close();
            });
          }
        } else {
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
exports.default = _default;