var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box" }, [
      _c("div", { staticClass: "wscn-http404-container" }, [
        _c("div", { staticClass: "wscn-http404" }, [
          _c("div", { staticClass: "pic-404" }, [
            _c("img", {
              staticClass: "pic-404__parent",
              attrs: { src: require("@/assets/404.png"), alt: "404" }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bullshit" }, [
            _c("div", { staticClass: "bullshit__oops" }, [_vm._v("404")]),
            _vm._v(" "),
            _c("div", { staticClass: "bullshit__info" }, [
              _vm._v("抱歉，您访问的页面不存在。")
            ]),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "bullshit__return-home", attrs: { href: "" } },
              [_vm._v("回到首页")]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }