"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _jszip = _interopRequireDefault(require("jszip"));

var _fileSaver = _interopRequireDefault(require("file-saver"));

var _user = require("@/api/user.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      isPop: false,
      ruleForm: {
        num: null,
        id: null,
        couponUuid: null
      },
      rules: {
        num: [{
          required: true,
          message: '请输入导出数量'
        }]
      },
      companyList: [],
      storeList: [],
      orderStatus: [],
      downloadLoading: false,
      requestData: {
        vipName: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '付费会员名称',
        prop: 'payMemberName',
        width: 100
      }, {
        label: '付费会员简称',
        prop: 'payMemberNameShort',
        width: 80
      }, {
        label: '购买次数',
        prop: 'limitBuyTimes',
        width: 50
      }, {
        label: '生效天数',
        prop: 'validDays',
        width: 50
      }, {
        label: '售价',
        prop: 'buyPrice',
        width: 50
      }, {
        slot: 'openBuy'
      }, {
        slot: 'vipStatus'
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 50
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    isPop: function isPop(value) {
      this.ruleForm.num = null;
      this.ruleForm.couponUuid = null;
    }
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    submitForm: function submitForm(type) {
      var _this = this;

      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          (0, _api.exportCdkeys)(_this.ruleForm).then(function (res) {
            if (!res.data) return;
            _this.isPop = false;

            if (type === 'qrCode') {
              _this.geVipQrcode(res.data);
            }

            var filterVal = ['payMemberTypeName', 'validDays', 'createTime', 'exchangeCode'];
            var tHeader = ['会员类型', '生效天数', '导出时间', '兑换码'];

            _this.getHandleDownload(tHeader, filterVal, res.data, "".concat(_this.ruleForm.payMemberName, "(").concat(_this.ruleForm.num, "\u4E2A\u5151\u6362\u7801)"));

            console.log(res);
          });
        } else {
          return false;
        }
      });
    },
    downLoad: function downLoad(qrcodeArr) {
      // 配合我的方法使用的参数 不需要直接去掉
      var blogTitle = '二维码包'; // 图片压缩包名称

      var zip = new _jszip.default();
      var imgs = zip.folder(blogTitle);
      var baseList = []; // 需要压缩图片的路径

      var imgNameList = []; // 单张图片名称

      var arr = [];
      var loading = this.$loading({
        lock: true,
        text: '小程序码导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      var _loop = function _loop() {
        imgNameList.push(i);
        var code = qrcodeArr[i];
        arr.push(code);
        var image = new Image();
        image.src = qrcodeArr[i]; // 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(qrcodeArr[i].qr_code))); //给图片对象写入base64编码的svg流

        canvas = document.createElement('canvas'); // 准备空画布

        canvas.width = 200;
        canvas.height = 200;
        context = canvas.getContext('2d'); // 取得画布的2d绘图上下文

        image.onload = function () {
          // 需要图片加载完成在执行canvas绘制
          context.drawImage(image, 0, 0, 200, 200);
          var url = canvas.toDataURL(); // 得到图片的base64编码数据

          canvas.toDataURL('image/png');
          baseList.push(url.substring(22)); // 去掉base64编码前的 data:image/png;base64,

          if (baseList.length === arr.length && baseList.length > 0) {
            for (var k = 0; k < baseList.length; k++) {
              imgs.file(imgNameList[k] + '.png', baseList[k], {
                // 设置单张图片名称
                base64: true
              });
            }

            zip.generateAsync({
              type: 'blob'
            }).then(function (content) {
              // 调用 FileSaver.js
              _fileSaver.default.saveAs(content, blogTitle + '.zip');

              loading.close();
            });
          }

          image.src = arr[i];
        };
      };

      for (var i = 0; i < qrcodeArr.length; i++) {
        var canvas;
        var context;

        _loop();
      }
    },
    geVipQrcode: function geVipQrcode(data) {
      var _self = this;

      var promises = data.map(function (item) {
        return (0, _user.couponQRCode)({
          path: 'vip/zengKa/getCard',
          scene: 'id=' + item.exchangeCode
        });
      });
      var arr = [];
      var loading = this.$loading({
        lock: true,
        text: '小程序码导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      Promise.allSettled(promises).then(function (posts) {
        posts.forEach(function (data, key) {
          arr.push(data.value.data);
        });
        loading.close();

        _self.downLoad(arr);
      }).catch(function (reason) {
        this.$message.error('网络连接超时，请稍后再试！');
      });
    },
    goDeleteData: function goDeleteData(row) {
      var _this2 = this;

      (0, _api.removeById)({
        id: row.id
      }).then(function (res) {
        _this2.getList();
      });
    },
    goExportCdkeys: function goExportCdkeys(row) {
      var _this3 = this;

      this.ruleForm.id = row.id;
      this.ruleForm.payMemberName = row.payMemberName;
      this.isPop = true;
      return;

      var _self = this;

      this.$prompt('请输入导出数量', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\+?[1-9][0-9]*$/,
        inputErrorMessage: '请输入大于0的整数'
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _api.exportCdkeys)({
          id: row.id,
          num: value // couponUuid: 'CA22081506304747849'

        }).then(function (res) {
          if (!res.data) return;

          _self.geVipQrcode(res.data);

          var filterVal = ['payMemberTypeName', 'validDays', 'createTime', 'exchangeCode'];
          var tHeader = ['会员类型', '生效天数', '导出时间', '兑换码'];

          _self.getHandleDownload(tHeader, filterVal, res.data, "".concat(row.payMemberName, "(").concat(value, "\u4E2A\u5151\u6362\u7801)"));

          console.log(res);
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];
        var list = [];
        var data = {};

        _this4.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        Object.keys(_this4.requestData).forEach(function (key) {
          if (_this4.requestData[key]) {
            data[key] = _this4.requestData[key];
          }
        });
        data.pageSize = _this4.requestData.total;
        data.pageNo = 1;
        (0, _api.getList)(data).then(function (res) {
          list = res.data.items;

          var data = _this4.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '权益解锁'
          });
          _this4.downloadLoading = false;
        }).catch(function (res) {
          _this4.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this5 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.removeObj(this.requestData);
      }

      (0, _api.getList)(this.requestData).then(function (res) {
        _this5.tableData = res.data.records;
        _this5.requestData.total = res.data.total;
        return;
      });
    }
  }
};
exports.default = _default;