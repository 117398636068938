"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _mem = require("@/api/mem.js");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/phone.xlsx"),
      downloadGoodsUrl: "".concat(process.env.BASE_URL, "xlsx/couponPop.xlsx"),
      addMobile: null,
      addGoods: null,
      userLabelList: [],
      storeList: [],
      formType: null,
      classList: [],
      parameterItem: null,
      formData: [{
        type: "divider",
        title: "基础信息"
      }, {
        label: '规则名称：',
        type: 'input',
        placeholder: '请输入规则名称',
        value: 'ruleName'
      }, {
        label: '规则类型：',
        type: 'radio',
        placeholder: '请选择规则类型',
        value: 'ruleType',

        /* 规则类型 1-单日消费 2-累计消费 3-单日累计消费*/
        selectList: [{
          label: '单笔订单消费',
          value: 1
        }, {
          label: '累计消费',
          value: 2
        }, {
          label: '单日累计消费',
          value: 3
        }]
      }, {
        slot: "ruleRemak"
      }, {
        label: '门槛金额：',
        type: 'inputNumber',
        placeholder: '请输入门槛金额',
        value: 'doorConsume',
        end: "元"
      }, {
        label: '抽奖次数：',
        type: 'inputNumber',
        placeholder: '请输入门槛金额',
        value: 'drawTimes',
        end: "次"
      }, {
        label: '状态：',
        type: 'radio',
        placeholder: '请选择状态',
        value: 'status',
        selectList: [{
          label: '启用',
          value: 1
        }, {
          label: '禁用',
          value: -1
        }]
      }, {
        type: "divider",
        title: "限制范围"
      }, {
        label: '门店范围：',
        type: 'radio',
        placeholder: '请选择门店范围',
        value: 'storeRange',
        selectList: [{
          label: '全集团',
          value: 1
        }, {
          label: '部分门店',
          value: 2
        }]
      }, {
        slot: "storeCode"
      }, {
        label: '可参与人群：',
        type: 'select',
        slot: "userGroup",
        placeholder: '请选择可参与人群',
        value: 'userGroup',
        selectList: [{
          label: '全集团',
          value: 1
        }, {
          label: '部分门店',
          value: 2
        }]
      }, {
        slot: "blacklist"
      }, {
        label: '限制类型：',
        type: 'radio',
        placeholder: '请选择规限制类型',
        value: 'limitType',

        /* 限制类型 1-限制 2-不限制*/
        selectList: [{
          label: '可用商品码',
          value: 1
        }, {
          label: '不可用商品码',
          value: 2
        }]
      }, {
        slot: "limitGoods"
      }, {
        label: '备注：',
        type: 'input',
        placeholder: '请输入备注',
        value: 'remark'
      }],
      ruleForm: {
        blacklist: [],
        limitGoods: [],
        id: null
      },
      rules: {
        userGroup: [{
          required: true,
          message: '请选择人群标签'
        }],
        limitGoods: [{
          required: true,
          message: '请上传商品'
        }],

        /* blacklist: [{
           required: true,
           message: '请上传黑名单'
         }], */
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        storeCode: [{
          required: true,
          message: '请选择门店'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        drawTimes: [{
          required: true,
          message: '请输入限制次数'
        }],
        doorConsume: [{
          required: true,
          message: '请输入门槛金额'
        }],
        ruleType: [{
          required: true,
          message: '请选择规则类型'
        }],
        ruleName: [{
          required: true,
          message: '请输入规则名称'
        }],
        storeRange: [{
          required: true,
          message: '请选择范围'
        }],
        limitType: [{
          required: true,
          message: '请选择商品类型'
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
      _this.userLabelList = [{
        id: 0,
        labelName: '所有会员'
      }].concat((0, _toConsumableArray2.default)(res.data));
    });
    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000,
      storeStatus: 2
    }).then(function (res) {
      console.log(res);
      _this.storeList = res.data;
    });
    /* this.apiPost('/api/smsTask/listSmsCategoryPage', {
       pageNo: 1,
       pageSize: 100
     }).then((res) => {
       this.classList = res.data.records
     }) */

    this.formType = this.$route.query.type;
    this.ruleForm.id = this.$route.query.id || '';

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/DrawRule//getRuleById/' + this.ruleForm.id).then(function (res) {
        delete res.data.operateTime;
        delete res.data.operateUser;
        res.data.storeRange = res.data.storeCode == 0 ? 1 : 2;
        res.data.blacklist = JSON.parse(res.data.blacklist);
        res.data.limitGoods = JSON.parse(res.data.limitGoods);
        _this.ruleForm = res.data;
      });
    }
  },
  methods: {
    handleDownload: function handleDownload(type) {
      if (type === 'mobile') {
        var tableData = this.ruleForm.blacklist.map(function (item) {
          return {
            手机号: item
          };
        });
        this.getHandleDownload(['手机号'], ['手机号'], tableData, '手机号码模板');
      }

      if (type === 'goods') {
        var _tableData = this.ruleForm.limitGoods.map(function (item) {
          return {
            商品码: item
          };
        });

        this.getHandleDownload(['编码'], ['商品码'], _tableData, '商品模板');
      }
    },
    delMobile: function delMobile(item, index, type) {
      var _self = this;

      var affrimStr = type == 'mobile' ? "\u786E\u8BA4\u79FB\u9664\u624B\u673A\u53F7".concat(item, ", \u662F\u5426\u7EE7\u7EED?") : "\u786E\u8BA4\u79FB\u9664\u5546\u54C1\u7801".concat(item, ", \u662F\u5426\u7EE7\u7EED?");
      this.$confirm(affrimStr, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (type == 'mobile') {
          _self.ruleForm.blacklist.splice(index, 1);
        }

        if (type == 'goods') {
          _self.ruleForm.limitGoods.splice(index, 1);
        }
      });
    },
    handleGoodsSuccess: function handleGoodsSuccess(_ref) {
      var _this2 = this;

      var results = _ref.results,
          header = _ref.header;
      this.ruleForm.limitGoods = [];
      results.forEach(function (data) {
        var mobile = String(data.编码);

        _this2.ruleForm.limitGoods.push(mobile.trim());
      });
    },
    handleSuccess: function handleSuccess(_ref2) {
      var _this3 = this;

      var results = _ref2.results,
          header = _ref2.header;
      this.ruleForm.blacklist = [];
      results.forEach(function (data) {
        var mobile = String(data.手机号);

        if (mobile.length == 11) {
          _this3.ruleForm.blacklist.push(mobile.trim());
        }
      });
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    goAddMobile: function goAddMobile(type) {
      if (type === 'mobile') {
        if (this.addMobile && this.addMobile.length === 11) {
          if (this.ruleForm.blacklist.includes(this.addMobile)) return;
          this.ruleForm.blacklist.push(this.addMobile);
          this.$refs.wForm.goValidateField('blacklist');
        }
      }

      if (type === 'goods') {
        if (this.addGoods) {
          if (this.ruleForm.limitGoods.includes(this.addGoods)) return;
          this.ruleForm.limitGoods.push(this.addGoods);
          this.$refs.wForm.goValidateField('limitGoods');
        }
      }
    },
    changeParameItem: function changeParameItem(e) {
      if (!e) return;
      this.ruleForm.content = this.ruleForm.content + '${' + e + '}'; // this.parameterItem = ''
    },
    changeParame: function changeParame(e) {
      this.ruleForm.content = this.ruleForm.content + '${' + e[e.length - 1] + '}';
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      if (this.ruleForm.storeRange == 1) {
        this.ruleForm.storeCode = 0;
      } //const apiUrl = this.ruleForm.id && this.formType != 'copy' ? '/api/smsTask/editSmsTemplate' : '/api/smsTask/addSmsTemplate'


      this.apiPost("/api/DrawRule/saveOrUpdateRule", this.ruleForm).then(function (res) {
        _this4.$router.go(-1);

        _this4.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;