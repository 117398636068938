"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      id: '',
      tit: '',
      operation: 2,
      total: 0,
      btn: '编辑',
      btnType: 'primary',
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [//					{
      //						tit: "ID",
      //						prop: 'id'
      //					},
      {
        tit: '列表名称',
        prop: 'name',
        width: 200
      }, {
        tit: '创建时间',
        prop: 'createTime',
        sortable: true,
        width: 200
      }, {
        tit: '广告类型',
        prop: 'adventType',
        width: 100
      }, {
        tit: '广告体状态',
        prop: 'status',
        width: 100
      }],
      list: [],
      type: 1
    };
  },
  watch: {},
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.id = this.$route.query.id;
      this.type = Number(this.$route.query.type);

      if (this.id === '001') {
        this.tit = '首页';
      }

      this.openLoading();
      (0, _coupon.listAdvertItemByParentId)(this.id).then(function (res) {
        _this.openLoading().close();

        _this.list = res.data;

        _this.list.forEach(function (item) {
          if (item.adventType === 1) {
            item.adventType = '图片';
          } else if (item.adventType === 2) {
            item.adventType = '视频';
          }

          if (item.status === 1) {
            item.status = '启用';
          } else if (item.status === -1) {
            item.status = '停用';
          }
        });
      }).catch(function (res) {
        _this.openLoading().close();
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/coupon/coupon4/storeEditor');
    },
    setBtn: function setBtn(scope) {
      console.log(scope);
      this.$router.push('/coupon/coupon4/storeEditor?id=' + scope.advertId);
    },
    remove: function remove(scope) {
      var _this2 = this;

      this.openLoading('删除中...');
      (0, _coupon.delAdvertById)(scope.row.advertId).then(function (res) {
        _this2.openLoading().close();

        _this2.list.splice(scope.$index, 1);
      }).catch(function (res) {
        _this2.openLoading().close();
      });
    }
  }
};
exports.default = _default;