"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      title: '新增活动分类',
      name: ''
    };
  },
  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.id = Number(this.$route.query.id);

      if (this.id) {
        this.title = '编辑活动分类';
        (0, _coupon.getActivityClassById)({
          classId: this.id
        }).then(function (res) {
          _this.name = res.data.name;
        });
      }
    },
    next: function next() {
      var _this2 = this;

      if (!this.name) {
        this.$message.error('请填写分类名称');
      } else {
        if (this.id) {
          (0, _coupon.editActivityClass)({
            classId: this.id,
            name: this.name
          }).then(function (res) {
            _this2.$message({
              message: '恭喜你，修改成功',
              type: 'success'
            });

            _this2.$router.go(-1);
          });
        } else {
          (0, _coupon.addActivityClass)({
            name: this.name
          }).then(function (res) {
            _this2.$message({
              message: '恭喜你，添加成功',
              type: 'success'
            });

            _this2.$router.go(-1);
          });
        }
      }
    }
  }
};
exports.default = _default;