"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var memRouter = {
  path: '/system',
  component: _layout.default,
  redirect: '/system/mem/new1',
  name: 'System',
  meta: {
    title: '系统',
    icon: 'nested'
  },
  children: [{
    path: 'mem',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/mem/index'));
      });
    },
    // Parent router-view
    name: 'mem',
    meta: {
      title: '会员设置'
    },
    redirect: '/system/mem/new1',
    children: [{
      path: 'new1',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/mem/mem1/new1'));
        });
      },
      name: 'New1',
      meta: {
        title: '会员体系'
      }
    }, {
      path: 'addMember',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/mem/add.vue'));
        });
      },
      name: 'AddMember',
      meta: {
        title: '新增会员'
      }
    }, {
      path: 'editor',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/mem/mem1/new1/components/editor'));
        });
      },
      hidden: true,
      name: 'Editor',
      meta: {
        title: '编辑会员体系'
      }
    }, {
      path: 'editor2',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/mem/mem2/components/editor'));
        });
      },
      hidden: true,
      name: 'Editor2',
      meta: {
        title: '编辑会员权益'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/mem/mem1/new1/components/add'));
        });
      },
      hidden: true,
      name: 'Add',
      meta: {
        activeMenu: '/system/mem/new1',
        title: '新增会员体系'
      }
      /*  {
            path: 'mem2',
            component: () => import('@/views/system/mem/mem2'),
            name: 'Mem2',
            meta: { title: '会员权益' }
          }*/

    }]
  }, {
    path: 'manage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/manage/index.vue'));
      });
    },
    name: 'manage',
    meta: {
      title: '管理'
    },
    redirect: '/system/manage/departmentManage',
    children: [{
      path: 'departmentManage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/manage/departmentManage'));
        });
      },
      name: 'DepartmentManage',
      meta: {
        title: '部门管理'
      }
    }, {
      path: 'addFull',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/manage/addFull.vue'));
        });
      },
      name: 'AddFull',
      meta: {
        title: '新增编辑公司总部',
        activeMenu: '/system/manage/departmentManage'
      }
    }, {
      path: 'listStoreList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/manage/listStoreList.vue'));
        });
      },
      name: 'ListStoreList',
      meta: {
        title: '门店管理',
        keepAlive: true,
        addTxt: "新增门店",
        addUrl: '/system/manage/addStore',
        pageList: 'items',
        pageTotal: 'totalNum',
        addLimits: 'store:add'
      }
    }, {
      path: 'addStore',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/manage/addStore.vue'));
        });
      },
      name: 'AddStore',
      meta: {
        activeMenu: '/system/manage/listStoreList',
        title: '新增编辑门店'
      }
    }, {
      path: 'addStorePark',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/manage/addStorePark.vue'));
        });
      },
      name: 'addStorePark',
      meta: {
        title: '新增编辑门店停车场'
      }
    }, {
      path: 'departmentDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/manage/departmentDea'));
        });
      },
      name: 'DepartmentDea',
      hidden: true,
      meta: {
        title: '编辑部门'
      }
    }]
  }, {
    path: 'menu',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/menu/index'));
      });
    },
    name: 'Menu',
    meta: {
      title: '资源管理'
    },
    redirect: '/system/Menu/menuManage',
    children: [{
      path: 'menuManage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/menu/menuManage'));
        });
      },
      name: 'MenuManage',
      meta: {
        title: '资源管理'
      }
    }, {
      path: 'menuDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/menu/menuDea'));
        });
      },
      name: 'MenuDea',
      hidden: true,
      meta: {
        title: '编辑资源'
      }
    }]
  }, {
    path: 'role',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/role/index'));
      });
    },
    name: 'Role',
    meta: {
      title: '角色管理'
    },
    redirect: '/system/role/roleManage',
    children: [{
      path: 'roleManage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/role/roleManage'));
        });
      },
      name: 'roleManage',
      meta: {
        title: '角色管理',
        keepAlive: true
      }
    }, {
      path: 'roleDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/role/roleDea'));
        });
      },
      name: 'RoleDea',
      hidden: true,
      meta: {
        activeMenu: '/system/role/roleManage',
        title: '编辑角色'
      }
    }]
  }, {
    path: 'user',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/user/index'));
      });
    },
    name: 'User',
    meta: {
      title: '用户管理'
    },
    redirect: '/system/user/userManage',
    children: [{
      path: 'userManage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/user/userManage'));
        });
      },
      name: 'UserManage',
      meta: {
        title: '用户管理'
      }
    }, {
      path: 'kpi',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/user/kpi'));
        });
      },
      name: 'userKpi',
      meta: {
        title: '推广人指标'
      }
    }, {
      path: 'userDea',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/user/userDea'));
        });
      },
      name: 'UserDea',
      hidden: true,
      meta: {
        title: '编辑用户',
        activeMenu: '/system/user/userManage'
      }
    }, {
      path: 'userRule',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/user/userRule.vue'));
        });
      },
      name: 'userRule',
      hidden: true,
      meta: {
        title: '用户资源',
        activeMenu: '/system/user/userManage'
      }
    }]
  }, {
    path: 'vendor',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/vendor/index.vue'));
      });
    },
    name: 'Vendor',
    meta: {
      title: '用户管理'
    },
    redirect: '/system/vendor/list',
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/vendor/list.vue'));
        });
      },
      name: 'vendorList',
      meta: {
        title: '商户管理',
        keepAlive: true,
        addTxt: "新增商户",
        addUrl: '/system/vendor/add',
        api: '/api/merchant/listMerchantPage',
        pageList: 'items',
        pageTotal: 'totalNum',
        addLimits: 'merchant:add'
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/vendor/add.vue'));
        });
      },
      name: 'AddVendor',
      meta: {
        title: '新增编辑商户',
        activeMenu: '/system/vendor/list'
      }
    }]
  }]
};
var _default = memRouter;
exports.default = _default;