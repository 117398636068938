var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 昂捷卡换新卡 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "120px"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "昂捷卡号：", prop: "code" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "500px" },
                  attrs: { placeholder: "刷卡查询" },
                  on: { change: _vm.goCardInfo },
                  model: {
                    value: _vm.ruleForm.code,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "code", $$v)
                    },
                    expression: "ruleForm.code"
                  }
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function($event) {
                        return _vm.goCardInfo()
                      }
                    },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.code
            ? _c("el-form-item", { attrs: { label: "卡余额：" } }, [
                _vm._v("\n      " + _vm._s(_vm.currentPrice) + "元\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentPrice > 0
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "换卡凭证：", prop: "certificate" } },
                    [
                      _c("add-load", {
                        attrs: {
                          width: 250,
                          height: 180,
                          cover: _vm.ruleForm.certificate
                        },
                        on: {
                          setCover: function($event) {
                            return _vm.setCover($event, "certificate")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { placeholder: "请输入备注" },
                        model: {
                          value: _vm.ruleForm.remark,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "remark",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.remark"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.changType === 2
                    ? _c(
                        "div",
                        [
                          _c("el-divider"),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "线上卡号：",
                                prop: "onlineCardUuid"
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "form_input",
                                attrs: {
                                  disabled: "",
                                  placeholder: "请输入新卡卡号"
                                },
                                model: {
                                  value: _vm.ruleForm.onlineCardUuid,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "onlineCardUuid",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.onlineCardUuid"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.getAward("card")
                                    }
                                  }
                                },
                                [_vm._v("选择购物卡")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "手机号码：", prop: "userMobile" }
                            },
                            [
                              _c("el-input", {
                                staticClass: "form_input",
                                attrs: {
                                  maxlength: "11",
                                  placeholder: "请输入手机号码"
                                },
                                on: { change: _vm.changeMobile },
                                model: {
                                  value: _vm.ruleForm.userMobile,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "userMobile",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.userMobile"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: { type: "primary" },
                                  on: { click: _vm.getUserInfo }
                                },
                                [_vm._v("查询")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "会员信息：" } },
                            [
                              _c(
                                "div",
                                { staticClass: "change-label-calss" },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "手机号码：" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.userInfo.mobile)
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "会员姓名：" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.userInfo.realname)
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "会员状态：" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.userInfo.status)
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "会员积分：" } },
                                            [_vm._v(_vm._s(_vm.userInfo.score))]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "乐享值：" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.userInfo.growthValue)
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "性别：" } },
                                            [
                                              _vm.userInfo.sex == 1
                                                ? _c("span", [_vm._v("男")])
                                                : _vm.userInfo.sex == 2
                                                ? _c("span", [_vm._v("女")])
                                                : _c("span", [_vm._v("未知")])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "民族：" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.userInfo.nation)
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "出生日期：" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.userInfo.birthday)
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "身份证号：" } },
                                            [_vm._v(_vm._s(_vm.userInfo.idNo))]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 6 } }),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 6 } })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _vm.isGoCard
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { width: "150px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.submitForm("ruleForm")
                                        }
                                      }
                                    },
                                    [_vm._v("确认")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.changType === 1
                    ? _c(
                        "div",
                        [
                          _c("el-divider"),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: " 新卡卡号：", prop: "newCardNo" }
                            },
                            [
                              _c("el-input", {
                                staticClass: "form_input",
                                attrs: {
                                  disabled: "",
                                  placeholder: "请输入新卡卡号"
                                },
                                model: {
                                  value: _vm.ruleForm.newCardNo,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "newCardNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.newCardNo"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { width: "150px" },
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.submitForm("ruleForm")
                                    }
                                  }
                                },
                                [_vm._v("确认")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }