"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name'])),
  data: function data() {
    return {
      tableData: [],
      requestData: {
        name: null,
        account: null,
        pageSize: 10,
        page: 1
      },
      searchData: [{
        label: '会员手机号码',
        type: 'input',
        span: 5,
        placeholder: '会员手机号码',
        value: 'userMobile'
      },
      /*
      <el-option label="订单消费" :value="1" />
      <el-option label="订单退货" :value="2" />
      <el-option label="礼品兑换" :value="3" />
      <el-option label="礼品兑换退货 " :value="4" />
      <el-option label="手工补录" :value="5" />
      <el-option label="积分返利" :value="10" />
      <el-option label="云闪付多倍积分" :value="12" />
      <el-option label="荔星充电宝" :value="13" />
      */
      {
        label: '变动类型',
        type: 'radio',
        span: 5,
        placeholder: '选择变动类型',
        value: 'changeType',
        select: [{
          label: '订单消费',
          value: 1
        }, {
          label: '订单退货',
          value: 2
        }, {
          label: '礼品兑换',
          value: 3
        }, {
          label: '礼品兑换退货',
          value: 4
        }, {
          label: '手工补录',
          value: 5
        }, {
          label: '积分返利',
          value: 10
        }, {
          label: '云闪付多倍积分',
          value: 12
        }, {
          label: '荔星充电宝',
          value: 13
        }, {
          label: '等级权益多倍积分',
          value: 14
        }, {
          label: '怪兽充电宝',
          value: 15
        }]
      }, {
        label: '时间',
        type: 'date',
        span: 4,
        placeholder: '请选择开始时间',
        value: 'startDate'
      }, {
        label: '时间',
        type: 'date',
        span: 4,
        placeholder: '请选择结束时间',
        value: 'endDate',
        defaultTime: "23:59:59"
      }]
    };
  }
};
exports.default = _default;