"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var gradeRouter = {
  path: '/grade',
  component: _layout.default,
  redirect: '/grade/tab',
  name: 'memberGrade',
  meta: {
    title: '会员等级'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/grade/tab.vue'));
      });
    },
    name: 'memberGradeTab',
    meta: {
      title: '会员等级'
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/grade/manage/list.vue'));
      });
    },
    name: 'memberGradeList',
    meta: {
      title: '会员等级列表',
      keepAlive: true,
      activeMenu: '/grade/tab'
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/grade/manage/add.vue'));
      });
    },
    name: 'memberGradeAdd',
    meta: {
      title: '编辑会员等级',
      activeMenu: '/grade/tab'
    }
  }, {
    path: 'equity',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/grade/equity/list.vue'));
      });
    },
    name: 'memberGradeequity',
    meta: {
      title: '会员权益',
      keepAlive: true,
      activeMenu: '/grade/tab'
    }
  }, {
    path: 'equityAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/grade/equity/add.vue'));
      });
    },
    name: 'memberGradeequityAdd',
    meta: {
      title: '编辑会员权益',
      activeMenu: '/grade/tab'
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/grade/equity/order.vue'));
      });
    },
    name: 'memberGradeeorder',
    meta: {
      title: '领取记录',
      keepAlive: true,
      activeMenu: '/grade/tab'
    }
  }, {
    path: 'score',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/grade/equity/orderScore.vue'));
      });
    },
    name: 'memberGradeeorderscore',
    meta: {
      title: '积分领取记录',
      keepAlive: true,
      activeMenu: '/grade/tab'
    }
  }, {
    path: 'pushRight',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/grade/pushRight/list.vue'));
      });
    },
    name: 'pushRight',
    meta: {
      title: '权益推送记录',
      keepAlive: true,
      activeMenu: '/grade/tab'
    }
  }]
};
var _default = gradeRouter;
exports.default = _default;