"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var dcbRouter = {
  path: '/dcb',
  component: _layout.default,
  redirect: '/dcb/index',
  name: 'Dcb',
  meta: {
    title: '多彩宝活动商品管理',
    icon: 'nested'
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dcb/index.vue'));
      });
    },
    name: 'DcbIndex',
    meta: {
      title: '多彩宝活动商品管理',
      keepAlive: true
    }
  }, {
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dcb/edit.vue'));
      });
    },
    name: 'editDcbGoods',
    meta: {
      title: '新增/编辑商品',
      activeMenu: '/dcb/index'
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dcb/order.vue'));
      });
    },
    name: 'DcbOrder',
    meta: {
      title: '多彩宝活动订单管理',
      keepAlive: true
    }
  }, {
    path: 'orderDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dcb/orderDea.vue'));
      });
    },
    name: 'DcbOrderDea',
    meta: {
      title: '订单详情',
      activeMenu: '/dcb/order'
    }
  }]
};
var _default = dcbRouter;
exports.default = _default;