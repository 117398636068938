"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '支付通道',
        prop: 'chanelName',
        width: 80
      }, {
        label: '支付通道编码',
        prop: 'chanelTypeNo',
        width: 50
      }, {
        label: '字段名称',
        prop: 'column1',
        width: 50
      }, {
        label: '字段描述',
        prop: 'columDesc',
        width: 80
      }, {
        label: '默认值',
        prop: 'columDefault',
        width: 50
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 120
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '支付通道编码',
        value: 'chanelTypeNo'
      }, {
        type: 'input',
        span: 4,
        placeholder: '支付通道名称',
        value: 'chanelName'
      }]
    };
  },
  methods: {}
};
exports.default = _default;