"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _dcb = require("@/api/dcb.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      id: '',
      form: {
        className: '',
        goodsName: '',
        goodsCode: '',
        goodsPrice: '',
        goodsNum: '',
        goodsSort: '',
        goodsDesc: '',
        goodsStatus: '',
        goodsImg: ''
      },
      rules: {
        className: [{
          required: true,
          message: '请选择商品分类'
        }],
        goodsName: [{
          required: true,
          message: '请输入商品名称'
        }],
        goodsCode: [{
          required: true,
          message: '请输入商品编码'
        }],
        goodsPrice: [{
          required: true,
          message: '请输入商品价格'
        }],
        goodsNum: [{
          required: true,
          message: '请输入商品数量'
        }],
        goodsSort: [{
          required: true,
          message: '请输入商品排序'
        }],
        goodsStatus: [{
          required: true,
          message: '请选择商品状态'
        }],
        goodsImg: [{
          required: true,
          message: '请上传商品图片'
        }],
        goodsDesc: [{
          required: true,
          message: '请输入商品描述'
        }]
      },
      classlist: []
    };
  },
  created: function created() {
    var _this = this;

    this.id = this.$route.query.id;
    (0, _dcb.listGoodsClass)().then(function (res) {
      console.log(res);
      _this.classlist = res.data;
    });

    if (this.id) {
      this.openLoading();
      (0, _dcb.getGoodsById)(this.id).then(function (res) {
        _this.form.className = Number(res.data.className);
        _this.form.goodsCode = res.data.goodsCode;
        _this.form.goodsDesc = res.data.goodsDesc;
        _this.form.goodsName = res.data.goodsName;
        _this.form.goodsNum = res.data.goodsNum;
        _this.form.goodsPrice = res.data.goodsPrice;
        _this.form.goodsSort = res.data.goodsSort;
        _this.form.goodsStatus = res.data.goodsStatus;
        _this.form.goodsImg = res.data.goodsImg;

        _this.openLoading().close();
      }).catch(function (err) {
        _this.openLoading().close();
      });
    }
  },
  methods: {
    setCover: function setCover(img) {
      this.form.goodsImg = img;
    },
    onSubmit: function onSubmit(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.openLoading();

          console.log(_this2.form);

          if (_this2.id) {
            var data = _this2.form;
            data.id = _this2.id;
            (0, _dcb.editGoods)(data).then(function (res) {
              _this2.openLoading().close();

              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            }).catch(function (err) {
              _this2.openLoading().close();
            });
          } else {
            (0, _dcb.addGoods)(_this2.form).then(function (res) {
              _this2.openLoading().close();

              _this2.$message({
                type: 'success',
                message: '新增成功!'
              });

              _this2.$router.go(-1);
            }).catch(function (err) {
              _this2.openLoading().close();
            });
          }
        }
      });
    }
  }
};
exports.default = _default;