"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _mem = require("@/api/mem.js");

var _flowCoupon = require("@/api/flowCoupon.js");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/goods.xlsx"),
      companyList: [],
      companyId: '',
      shortNameShow: false,
      shortNameSearch: '',
      storeList: [],
      storeArr: [],
      goosList: [],
      ruleForm: {
        rangeName: '',
        companyCode: '',
        storeCodes: [],
        goodsStatus: '',
        goodsCodes: [],
        rangeRemark: '',
        // rangeStatus:'',
        id: ''
      },
      rules: {
        giftStatus: [{
          required: true,
          message: '请选择状态'
        }],
        companyCode: [{
          required: true,
          message: '请选择总部'
        }],
        storeCodes: [{
          required: true,
          message: '请选择门店'
        }],
        goodsStatus: [{
          required: true,
          message: '请选择商品码状态'
        }],
        goodsCodes: [{
          required: true,
          message: '请导入商品码'
        }],
        rangeName: [{
          required: true,
          message: '请输入名称'
        }],
        rangeRemark: [{
          required: true,
          message: '请输入备注'
        }],
        rangeStatus: [{
          required: true,
          message: '请选择范围状态'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companyList = res.data;
    });
    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id) {
      (0, _flowCoupon.getSendCoupon)(this.ruleForm.id).then(function (res) {
        _this.ruleForm.companyCode = res.data.companyCode;
        _this.ruleForm.goodsStatus = res.data.goodsStatus;
        _this.ruleForm.rangeName = res.data.rangeName;
        _this.ruleForm.rangeRemark = res.data.rangeRemark;
        _this.ruleForm.rangeStatus = res.data.rangeStatus;

        _this.setShort(res.data.companyCode);

        res.data.store.forEach(function (item) {
          _this.ruleForm.storeCodes.push(item.storeCode);

          _this.storeArr.push({
            attrCode: item.storeCode,
            attrName: item.storeName
          });

          _this.$refs.shortTable.toggleRowSelection({
            storeCode: item.storeCode,
            shortName: item.shortName
          });
        });
        var goodsCodes = res.data.goodsCodes ? JSON.parse(res.data.goodsCodes) : [];
        _this.ruleForm.goodsCodes = goodsCodes;
        goodsCodes.forEach(function (item) {
          _this.goosList.push({
            goodsCode: item
          });
        });
      });
    }
  },
  methods: {
    submitForm: function submitForm(form) {
      var _this2 = this;

      this.$refs[form].validate(function (valid) {
        if (valid) {
          _this2.openLoading();

          if (_this2.ruleForm.id) {
            (0, _flowCoupon.editSendCoupon)(_this2.ruleForm).then(function (res) {
              _this2.openLoading().close();

              _this2.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            }).catch(function (err) {
              _this2.openLoading().close();
            });
          } else {
            (0, _flowCoupon.addSendCoupon)(_this2.ruleForm).then(function (res) {
              _this2.openLoading().close();

              _this2.$message({
                message: '新增成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            }).catch(function (err) {
              _this2.openLoading().close();
            });
          }
        } else {
          return false;
        }
      });
    },
    setShort: function setShort(e) {
      var _this3 = this;

      this.ruleForm.storeCodes = [];
      this.storeList = [];
      this.clearShort();
      this.storeArr = [];
      this.ruleForm.shortName = [];
      var companyId = '';
      this.companyList.forEach(function (item) {
        if (item.companyCode == e) {
          _this3.companyId = item.companyId;
        }
      });
    },
    getStore: function getStore() {
      var _this4 = this;

      (0, _mem.listStore)({
        companyId: this.companyId
      }).then(function (res) {
        _this4.shortNameShow = true;
        _this4.storeList = res.data;
      });
    },

    /*
      选择门店
      */
    shortNameClick: function shortNameClick() {
      if (this.ruleForm.companyCode) {
        if (this.storeList.length > 0) {
          this.shortNameShow = true;
        } else {
          this.getStore();
        }
      } else {
        this.$message({
          message: '请先选择总部',
          type: 'warning'
        });
      }
    },

    /*
      清空门店
      */
    clearShort: function clearShort() {
      this.$refs.shortTable.clearSelection();
    },

    /*
      保存门店
      */
    affirmShort: function affirmShort() {
      var _this5 = this;

      var shortName = this.$refs.shortTable.store.states.selection;
      var arr = [];
      this.ruleForm.shortName = [];
      shortName.forEach(function (item) {
        if (item.storeCode && item.fullName) {
          arr.push({
            attrCode: item.storeCode,
            attrName: item.fullName,
            attrType: 1
          });

          _this5.ruleForm.storeCodes.push(item.storeCode);
        }
      });
      this.$refs.ruleForm.validateField('storeCodes');
      this.storeArr = arr; // this.$emit('submitForm', 'shortName')

      this.shortNameShow = false; // this.init()
    },
    handleSuccess: function handleSuccess(_ref) {
      var _this6 = this;

      var results = _ref.results,
          header = _ref.header;
      var arr = []; // var myreg = /^0{0,1}(1)[0-9]{10}$/;

      this.ruleForm.goodsCodes = [];
      results.forEach(function (data) {
        if (data.goodsCode) {
          arr.push({
            goodsCode: data.goodsCode,
            goodsName: data.goodsName
          });

          _this6.ruleForm.goodsCodes.push(data.goodsCode);
        }
      });
      this.goosList = arr;
      this.$refs.ruleForm.validateField('goodsCodes');
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    }
  }
};
exports.default = _default;