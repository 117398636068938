var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              ref: "rightName",
              attrs: { label: "权益名称：", prop: "rightName" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "权益名称"
                },
                model: {
                  value: _vm.ruleForm.rightName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "rightName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.rightName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "rightShort",
              attrs: { label: "权益简称：", prop: "rightShort" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "权益简称"
                },
                model: {
                  value: _vm.ruleForm.rightShort,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "rightShort",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.rightShort"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "rightDesc", attrs: { label: "描述：", prop: "rightDesc" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "描述"
                },
                model: {
                  value: _vm.ruleForm.rightDesc,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "rightDesc",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.rightDesc"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "rightImage",
              attrs: { label: "首页弹窗图：", prop: "rightImage" }
            },
            [
              _c("add-load", {
                attrs: {
                  width: 100,
                  height: 130,
                  cover: _vm.ruleForm.rightImage
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "rightImage")
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("图片尺寸540*600")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form-item",
                {
                  ref: "rigthUnlockIco",
                  attrs: { label: " 权益标识： ", prop: "rigthUnlockIco" }
                },
                [
                  _c("add-load", {
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.rigthUnlockIco
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "rigthUnlockIco")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("权益标识解锁")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "rightLockIco",
                  staticStyle: { "margin-left": "20px" },
                  attrs: { "label-width": "0", prop: "rightLockIco" }
                },
                [
                  _c("add-load", {
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.rightLockIco
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "rightLockIco")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("权益标识未解锁")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "reciveMode",
              attrs: { label: "投放方式：", prop: "reciveMode" }
            },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "recive-mode-group",
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.ruleForm.reciveMode,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "reciveMode", $$v)
                    },
                    expression: "ruleForm.reciveMode"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1, border: "" } }, [
                    _vm._v("每天")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2, border: "" } }, [
                    _vm._v("每周")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 3, border: "" } }, [
                    _vm._v("每月")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 6, border: "" } }, [
                    _vm._v("季度")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 4, border: "" } }, [
                    _vm._v("会员生日")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 5, border: "" } }, [
                    _vm._v("会员生日月")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 7, border: "" } }, [
                    _vm._v("付费会员有效期内")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 8, border: "" } }, [
                    _vm._v("有效期内领取一次")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 9, border: "" } }, [
                    _vm._v("按累计节省金额")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 10, border: "" } }, [
                    _vm._v("按会员周期内的节省金额")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.reciveMode === 8
            ? _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "validStartTime",
                      attrs: { label: "有效期：", prop: "validStartTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择开始时间"
                        },
                        model: {
                          value: _vm.ruleForm.validStartTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "validStartTime", $$v)
                          },
                          expression: "ruleForm.validStartTime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "validEndTime",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { "label-width": "0", prop: "validEndTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: !_vm.ruleForm.validStartTime,
                          "picker-options": _vm.pickerTime,
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": "23:59:59",
                          placeholder: "选择结束时间"
                        },
                        model: {
                          value: _vm.ruleForm.validEndTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "validEndTime", $$v)
                          },
                          expression: "ruleForm.validEndTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.reciveMode === 9 || _vm.ruleForm.reciveMode === 10
            ? _c(
                "el-form-item",
                { attrs: { label: "节省金额：", prop: "remark" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { placeholder: "节省金额", type: "number" },
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "remark", $$v)
                      },
                      expression: "ruleForm.remark"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "secondCardShare",
              attrs: { label: "副卡是否共享：", prop: "secondCardShare" }
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.ruleForm.secondCardShare,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "secondCardShare", $$v)
                    },
                    expression: "ruleForm.secondCardShare"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("否")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "rightGifts", attrs: { label: "礼品列表：" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.addGiftItems()
                    }
                  }
                },
                [_vm._v("添加礼品\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.ruleForm.rightGifts,
                    size: "mini",
                    border: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "奖励类型",
                      "min-width": "100",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                ref: "rightGifts." + scope.$index + ".giftType",
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "rightGifts." + scope.$index + ".giftType",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择奖励类型"
                                    }
                                  ]
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      sityle: "width: 100%;",
                                      placeholder: "奖励类型"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.typeChange(
                                          $event,
                                          scope.$index
                                        )
                                      }
                                    },
                                    model: {
                                      value: scope.row.giftType,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "giftType", $$v)
                                      },
                                      expression: "scope.row.giftType"
                                    }
                                  },
                                  _vm._l(_vm.type, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "关联礼品",
                      "min-width": "200",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.giftType == 6 &&
                            typeof scope.row.giftType === "number"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "rightGifts." +
                                      scope.$index +
                                      ".giftCode",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "rightGifts." +
                                        scope.$index +
                                        ".giftCode",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择类型"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: scope.row.giftCode,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "giftCode", $$v)
                                          },
                                          expression: "scope.row.giftCode"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "dayUnit" } },
                                          [_vm._v("可选几天")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "orderUnit" } },
                                          [_vm._v("可选几个订单")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : scope.row.giftType != 4 &&
                                typeof scope.row.giftType === "number"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "rightGifts." +
                                      scope.$index +
                                      ".giftCode",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "rightGifts." +
                                        scope.$index +
                                        ".giftCode",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择礼品"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    scope.row.giftType != 4
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.getAward(
                                                  scope.row.giftType,
                                                  scope.$index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.giftType[
                                                    scope.row.giftType
                                                  ]
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.giftType === 4
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "form_remark",
                                    staticStyle: { "font-size": "12px" }
                                  },
                                  [
                                    _vm._v(
                                      "\n              次月计算上月获得积分，按比例返利\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "礼品名称",
                      "min-width": "100",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.giftType === 6
                              ? _c(
                                  "div",
                                  [
                                    _c("add-load", {
                                      attrs: {
                                        width: 100,
                                        height: 100,
                                        cover: scope.row.giftImage
                                      },
                                      on: {
                                        setCover: function($event) {
                                          return _vm.setGiftCover(
                                            $event,
                                            "giftImage",
                                            scope.$index
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.giftName || "") +
                                      "\n\n            "
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "礼品编码",
                      "min-width": "150",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.giftType != 6
                              ? _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.giftCode) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.giftType === 6
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "rightGifts." +
                                      scope.$index +
                                      ".publicCol",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "rightGifts." +
                                        scope.$index +
                                        ".publicCol",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: { size: "mini", type: "number" },
                                        model: {
                                          value: scope.row.publicCol,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "publicCol",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.publicCol"
                                        }
                                      },
                                      [
                                        typeof scope.row.giftType === "number"
                                          ? _c("template", { slot: "append" }, [
                                              _vm._v(
                                                "\n                  %\n                "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "form_remark",
                                        staticStyle: {
                                          color: "red",
                                          "line-height": "16px",
                                          "text-align": "left"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "用户消费已经获得一倍积分，多倍积分应减去1倍（例：5倍积分：400%）"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "规格", width: "150", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.giftType === 6
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "rightGifts." +
                                      scope.$index +
                                      ".giftName",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "rightGifts." +
                                        scope.$index +
                                        ".giftName",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: { size: "mini", type: "number" },
                                        model: {
                                          value: scope.row.giftName,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "giftName", $$v)
                                          },
                                          expression: "scope.row.giftName"
                                        }
                                      },
                                      [
                                        typeof scope.row.giftType === "number"
                                          ? _c("template", { slot: "append" }, [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.giftCode ===
                                                      "dayUnit"
                                                      ? "天"
                                                      : "单"
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              : typeof scope.row.giftType === "number"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "rightGifts." +
                                      scope.$index +
                                      ".publicCol",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "rightGifts." +
                                        scope.$index +
                                        ".publicCol",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message:
                                            _vm.tabGiftType[scope.row.giftType]
                                              .placeholder
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: {
                                          placeholder:
                                            _vm.tabGiftType[scope.row.giftType]
                                              .placeholde,
                                          size: "mini",
                                          type: "number"
                                        },
                                        model: {
                                          value: scope.row.publicCol,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "publicCol",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.publicCol"
                                        }
                                      },
                                      [
                                        typeof scope.row.giftType === "number"
                                          ? _c("template", { slot: "append" }, [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.tabGiftType[
                                                      scope.row.giftType
                                                    ].label
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "100", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认删除该礼品？" },
                                on: {
                                  confirm: function($event) {
                                    return _vm.delAward(scope.$index)
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "danger",
                                      size: "mini"
                                    },
                                    slot: "reference"
                                  },
                                  [_vm._v("删除\n              ")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "choiceNum", attrs: { label: "限领个数：" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { type: "number", placeholder: "可领N个" },
                on: { change: _vm.setNumber },
                model: {
                  value: _vm.ruleForm.choiceNum,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "choiceNum",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.choiceNum"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "secondCardShare",
              attrs: { label: "总限领取次数：", prop: "secondCardShare" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  type: "number",
                  placeholder: "会员周期内共能领取几次"
                },
                model: {
                  value: _vm.ruleForm.limitNum,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "limitNum",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.limitNum"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "rightDetail",
              attrs: { prop: "rightDetail", label: "权益介绍：" }
            },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.ruleForm.rightDetail,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "rightDetail", $$v)
                  },
                  expression: "ruleForm.rightDetail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: _vm.popType, "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "view_title" }, [
      _vm._v("\n    新增/编辑会员权益\n    "),
      _c(
        "span",
        {
          staticStyle: {
            color: "red",
            "font-size": "16px",
            "margin-left": "10px"
          }
        },
        [_vm._v("新增权益审核后立即生效；权益修改审核后下个周期生效")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }