"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _content = require("@/api/content.js");

var _coupon = require("@/api/coupon");

var _tableList = _interopRequireDefault(require("@/components/tableList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default
  },
  data: function data() {
    return {
      deaPath: '/content/manageContent/contentDea?articleId=',
      btnType: 'primary',
      btn: '查看详情',
      operation: 2,
      total: 0,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [{
        tit: '文章标题',
        prop: 'title',
        width: 250
      }, {
        tit: '文章类别',
        prop: 'classId',
        align: 'center',
        width: 250
      }, {
        tit: '发布时间',
        prop: 'createTime',
        sortable: true,
        align: 'center',
        width: 250
      }, {
        tit: '发布人',
        prop: 'issuer',
        width: 180,
        align: 'center'
      }, {
        tit: '状态',
        prop: 'onlineStatus',
        width: 100,
        align: 'center'
      }],
      time: '',
      couponId: '',
      title: '',
      issuer: '',
      options: [{
        value: 1,
        label: '使用帮助QA'
      }, {
        value: 2,
        label: '协议类文章'
      }],
      classId: '',
      options1: [{
        value: 1,
        label: '启用'
      }, {
        value: -1,
        label: '停用'
      }],
      onlineStatus: ''
    };
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    goAdd: function goAdd() {
      this.$router.push('/content/manageContent/addArticle');
    },
    setBtn: function setBtn(scope) {
      this.$router.push('/content/manageContent/contentDea?articleId=' + scope.articleId);
    },
    getSearch: function getSearch() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.getList();
    },
    getList: function getList(e) {
      var _this = this;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        onlineStatus: this.onlineStatus,
        classId: this.classId,
        issuer: this.issuer.trim(),
        title: this.title.trim()
      };
      this.openLoading();
      (0, _content.listArticlePage)(data).then(function (res) {
        _this.openLoading().close();

        _this.tableData = res.data.records;
        _this.total = res.data.total;

        _this.tableData.forEach(function (item) {
          switch (item.classId) {
            case 1:
              item.classId = '使用帮助QA';
              break;

            case 2:
              item.classId = '协议类文章';
              break;
          }

          switch (item.onlineStatus) {
            case 1:
              item.onlineStatus = '启用';
              break;

            case -1:
              item.onlineStatus = '停用';
              break;
          }
        });
      }).catch(function (res) {
        _this.openLoading().close();
      });
    },
    remove: function remove() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.onlineStatus = '';
      this.classId = '';
      this.issuer = '';
      this.title = '';
      this.getList();
    }
  }
};
exports.default = _default;