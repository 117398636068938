var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tem" }, [
    _vm.dataItem.length > 0
      ? _c(
          "div",
          { staticClass: "tem_class" },
          _vm._l(_vm.dataItem, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "tem_class_item",
                class: { tem_class_active: _vm.tabIndex == index },
                style: {
                  color:
                    _vm.tabIndex == index
                      ? _vm.data.moduleAttr.activeColor || "#333333"
                      : "rgba(51, 51, 51, 0.8)",
                  background:
                    _vm.tabIndex == index
                      ? _vm.data.moduleAttr.activeBgColor || "#cccccc"
                      : ""
                },
                attrs: { id: "tab" + index },
                on: {
                  click: function($event) {
                    return _vm.changeTab(index)
                  }
                }
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(item.dataPath) + "\n\t\t\t")]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex_center", staticStyle: { height: "100px" } },
      [
        _vm._v(
          "\n      这里是" +
            _vm._s(_vm.dataItem[_vm.tabIndex].dataPath) +
            "内容,页面编码为" +
            _vm._s(_vm.dataItem[_vm.tabIndex].linkCode) +
            "\n    "
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }