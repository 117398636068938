"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _api = require("./api.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    var _self = this;

    return {
      timeEnd: null,
      timeStart: null,
      pickerTime0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < _self.timeStart || time.getTime() > _self.timeEnd;
        }
      },
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.messageParam.startTime).getTime();
          return time.getTime() < validBeginTime || time.getTime() > _self.timeEnd;
        }
      },
      pickerOptions: {},
      storeList: [],
      costList: {
        1: '集团',
        2: '门店',
        3: '专柜/品牌、商户'
      },
      rules: {
        'messageParam.verificationModelList': [{
          required: true,
          message: '请选择核销方式'
        }],
        'messageParam.name': [{
          required: true,
          message: '请输入活动名称'
        }],
        'messageParam.activityMonth': [{
          required: true,
          message: '请选择活动周期'
        }],
        'messageParam.startTime': [{
          required: true,
          message: '请选择开始时间'
        }],
        'messageParam.endTime': [{
          required: true,
          message: '请选择结束时间'
        }],
        'messageParam.costContributor': [{
          required: true,
          message: '请选择活动出资方'
        }],
        'messageParam.limitType': [{
          required: true,
          message: '请选择活动参与方式'
        }],
        'messageParam.limitNumber': [{
          required: true,
          message: '请输入参与次数'
        }],
        'messageParam.targetUser': [{
          required: true,
          message: '请选择定向人群'
        }],
        'messageParam.description': [{
          required: true,
          message: '请输入规则说明'
        }],
        'messageParam.activityType': [{
          required: true,
          message: '请选择活动类型'
        }],
        ruleParamList: [{
          required: true,
          message: '请添加优惠层级'
        }],
        ruleType: [{
          required: true,
          message: '请选择折扣规则'
        }],
        ratioAll: [{
          validator: function validator(rule, value, callback) {
            if (value !== 100) {
              callback(new Error('出资比例之和必须为100'));
            } else {
              callback();
            }
          }
        }]
      },
      ruleForm: {
        ratioAll: null,
        ruleType: null,
        labelContent: null,
        contributionParamList: [],
        messageParam: {
          verificationModelList: [],
          activityMonth: null,
          type: null,
          name: null,
          startTime: null,
          endTime: null,
          costContributor: [],
          limitType: null,
          limitNumber: null,
          targetUser: null,
          activityType: null
        },
        categoryName: null,
        segmentNo: null,
        ruleParamList: []
      },
      userLabelList: []
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
      _this.userLabelList = res.data;
    });
    this.ruleForm.id = this.$route.query.id;
    this.ruleForm.messageParam.type = this.$route.query.type || 1;
    this.ruleForm.ruleType = Number(this.$route.query.type);

    if (this.ruleForm.id) {
      (0, _api.getData)(this.ruleForm.id).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    changeStart: function changeStart() {
      if (this.ruleForm.messageParam.endTime && this.ruleForm.messageParam.startTime > this.ruleForm.messageParam.endTime) {
        this.ruleForm.messageParam.endTime = null;
      }
    },
    changActivityType: function changActivityType(e) {
      console.log(e);
      this.ruleForm.contributionParamList = [{
        costContributor: 1,
        ratio: 100
      }];
      this.ruleForm.messageParam.costContributor = [1];
      this.setRatio();
    },
    startChange: function startChange(e) {
      var now = new Date(e); // 当前日期
      // now.getFullYear() -- 当前年  now.getMonth() -- 当前月

      var monthEndDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59); // 获取本月的结束日期

      var monthStartDate = new Date(now.getFullYear(), now.getMonth(), 1); // 获取本月的开始日期

      this.timeEnd = Date.parse(monthEndDate);
      this.timeStart = Date.parse(monthStartDate);
      this.ruleForm.messageParam.startTime = this.setFormatData(monthStartDate);
      this.ruleForm.messageParam.endTime = this.setFormatData(monthEndDate);
      /* this.pickerOptions.disabledDate = time => (time.getTime() < timeEnd) || (time.getTime() > timeEnd + 60 * 60 * 24 * 1000)
      console.log(monthEndDate) */
    },
    setRatio: function setRatio() {
      var ratio = 0;
      this.ruleForm.contributionParamList.forEach(function (item) {
        ratio = ratio + Number(item.ratio);
      });
      this.ruleForm.ratioAll = ratio;
      console.log(this.ruleForm.ratioAll);
    },
    ruleTypeChange: function ruleTypeChange() {
      this.ruleForm.ruleParamList = [];
    },
    changCost: function changCost(e) {
      this.ruleForm.contributionParamList = e.map(function (item) {
        return {
          costContributor: item,
          ratio: null
        };
      });
    },
    delRule: function delRule(index) {
      this.ruleForm.ruleParamList.splice(index, 1);
    },
    setSort: function setSort() {
      this.ruleForm.ruleParamList.sort(this.compare('orderPrice'));
    },
    addRule: function addRule() {
      this.ruleForm.ruleParamList.push({
        ruleType: this.ruleForm.ruleType,
        // 活动规则类型 1-满减活动按档位 2-多件多折按订单 3-多件多折按数量
        level: null,
        // 活动档位级别 -【满减活动】【多件多折按商品数量】规则档位级别
        orderPrice: null,
        // 订单价-【满减活动】【多件多折按订单】按档位-订单金额
        subPrice: null,
        // 【满减活动】按档位-扣减金额
        isCeiling: 0,
        // 【满减活动】 是否封顶 0-否 1-是
        maxDiscountPrice: null,
        // 单笔订单优惠金额 或最多优惠
        isGiveCoupon: 0,
        // 是否送优惠卷0-否 1-是
        buyNumber: null,
        // 【多件多折按数量】商品数量
        discount: null // 多件多折】按数量优惠折扣

      });

      if (this.ruleForm.ruleParamList.length > 0) {
        this.ruleForm.ruleParamList.forEach(function (item) {
          item.isCeiling = 0;
        });
      } // this.ruleForm.ruleParamList.sort(this.compare('orderPrice'))

    },
    clearForm: function clearForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
    },
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.setSort();

          var data = JSON.parse(JSON.stringify(_this2.ruleForm));
          data.messageParam.costContributor = _this2.ruleForm.messageParam.costContributor.join(',');
          data.ruleParamList.forEach(function (item, key) {
            item.level = key + 1;
          });

          if (_this2.ruleForm.id) {
            data.id = _this2.ruleForm.id;
            (0, _api.editData)(data).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addData)(data).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;