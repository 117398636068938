"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _coupon = require("@/api/coupon");

var _tableList = _interopRequireDefault(require("@/components/tableList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default
  },
  data: function data() {
    return {
      btnType: "primary",
      btn: "查看详情",
      operation: 2,
      total: 0,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [{
        tit: "文章ID",
        prop: "uuid"
      }, {
        tit: "提交时间",
        prop: "name"
      }, {
        tit: "类别",
        prop: "couponType",
        width: 100
      }, {
        tit: "姓名",
        prop: "time"
      }, {
        tit: "手机号",
        prop: "createTime",
        width: 180
      }, //					{
      //						tit: "发布人",
      //						prop: 'publishUser'
      //					},
      {
        tit: "状态",
        prop: "status",
        width: 100
      }],
      time: "",
      couponId: "",
      name: "",
      issuer: "",
      options: [{
        value: "1",
        label: "意见建议"
      }, {
        value: "2",
        label: "用户投诉"
      }],
      value: "",
      options1: [{
        value: "1",
        label: "待审核"
      }, {
        value: "2",
        label: "已审核"
      }],
      value1: ""
    };
  },
  watch: {
    time: function time() {
      console.log(this.time);
    }
  },
  created: function created() {// this.getList()
  },
  methods: {
    goAdd: function goAdd() {
      this.$router.push("/content/manageContent/addArticle");
    },
    setBtn: function setBtn(scope) {
      console.log(scope);
      this.$router.push("/coupon/coupon1/couponDeatil?id=" + scope.id); // this.$emit('setTab',2)
    },
    getList: function getList(e) {
      var _this = this;

      console.log(e);

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      (0, _coupon.listCouponPage)(this.pageNo, this.pageSize).then(function (res) {
        var data = res.data.records;
        console.log(data);

        if (res.ret === 1000) {
          data.filter(function (value, key, arr) {
            value.createTime = value.createTime; // .substring(0, 10)

            if (value.validTimeType === 1) {
              value.time = value.validBeginTime + "至" + value.validEndTime;
            } else if (value.validTimeType === 2) {
              value.time = this.getValidDay(value); //"领取后" + value.validDay + "天内有效";
            }

            if (value.couponType === 1) {
              value.couponType = "代金券";
            } else if (value.couponType === 2) {
              value.couponType = "折扣券";
            } else if (value.couponType === 3) {
              value.couponType = "礼品券";
            } else if (value.couponType === 4) {
              value.couponType = "体验券";
            } else if (value.couponType === 5) {
              value.couponType = "外部优惠券";
            }

            switch (value.status) {
              case 1:
                value.status = "待审核";
                break;

              case 2:
                value.status = "已审核";
                break;

              case 3:
                value.status = "审核撤回";
                break;

              case 4:
                value.status = "审核撤销";
                break;

              case 5:
                value.status = "已过期";
                break;

              case 6:
                value.status = "已终止";
                break;

              case 8:
                value.status = "已投放";
                break;

              case 9:
                value.status = "投放完成";
                break;
            }
          });
          _this.tableData = data;
          _this.total = res.data.total;
        }
      });
    },
    remove: function remove() {
      this.time = "";
      this.couponId = "";
      this.name = "";
      this.issuer = "";
      this.value1 = "";
      this.value = "";
    }
  }
};
exports.default = _default;