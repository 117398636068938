var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "table",
          attrs: {
            "search-data": _vm.searchData,
            pageApi: "/mall/product/source/listSourcePage",
            columns: _vm.columns,
            detailKey: "orderNo"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "rowStatus",
              label: "状态",
              align: "center",
              width: "80"
            },
            slot: "rowStatus",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.rowStatus == -1
                      ? _c("span", { staticStyle: { color: "#E6A23C" } }, [
                          _vm._v("停用")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.rowStatus == 0
                      ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                          _vm._v("启用")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "dea",
              label: "操作",
              align: "center",
              fixed: "right",
              width: "100"
            },
            slot: "dea",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "app-link",
                      {
                        staticStyle: { color: "rgb(24, 144, 255)" },
                        attrs: { to: "/mall/sourceAdd?id=" + scope.row.id }
                      },
                      [_vm._v(" 编辑 ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }