"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      searchData: [{
        type: 'input',
        placeholder: '用户手机号',
        value: 'mobile'
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }],
      columns: [{
        label: '用户ID',
        prop: 'uid'
      }, {
        label: '用户手机号',
        prop: 'mobile'
      }, {
        label: '用户券UUID',
        prop: 'couponId'
      }, {
        label: '领取时间',
        prop: 'createTime'
      }]
    };
  }
};
exports.default = _default;