"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '扫码返券',
      list: [{
        tit: '扫码返券列表',
        txtArr: '扫码返券——配置扫码返券',
        imgPath: require('@/assets/tab/activity/1.png'),
        path: '/activity/lixing/flowCoupon'
      }, {
        tit: '领取记录',
        txtArr: '扫码返券——领取记录',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: '/activity/lixing/flowOrder'
      }]
    };
  }
};
exports.default = _default;