"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regionAll = _interopRequireDefault(require("@/utils/regionAll.json"));

//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      formData: [{
        label: '分类名称：',
        type: 'input',
        placeholder: '请输入分类名称',
        value: 'categoryName'
      }, {
        label: '排序：',
        type: 'inputNumber',
        placeholder: '请输入排序',
        value: 'categorySort'
      }],
      ruleForm: {
        "id": null,
        categoryName: null,
        categorySort: undefined
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id || '';

    if (this.ruleForm.id) {
      this.apiPost('/api/mall/merchantCategory/detail', {
        id: this.ruleForm.id
      }).then(function (res) {
        _this.ruleForm = res.data;
      });
    }
  },
  methods: {
    /*
    保存商品
    */
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var apiUrl = this.ruleForm.id ? '/api/mall/merchantCategory/update' : '/api/mall/merchantCategory/create';
      var requestData = JSON.parse(JSON.stringify(this.ruleForm));
      this.apiPost(apiUrl, requestData).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: _this2.ruleForm.id ? '编辑成功!' : '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;