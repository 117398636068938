"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _template = require("@/api/template.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      list: [],
      tabTh: [{
        prop: 'styleCode',
        label: '样式编码',
        width: 60
      }, {
        prop: 'styleName',
        label: '样式名称',
        width: 100
      }, {
        prop: 'createTime',
        label: '创建时间',
        width: 120
      }],
      classList: [],
      styleId: ''
    };
  },
  activated: function activated() {
    this.init();
  },
  created: function created() {
    var _this = this;

    (0, _template.listStyleType)({
      styleStatus: null
    }).then(function (res) {
      _this.classList = res.data;
      _this.styleId = _this.classList[0].id;

      _this.init();
    });
  },
  methods: {
    init: function init(id) {
      var _this2 = this;

      this.openLoading();
      (0, _template.listStyle)({
        parentId: this.styleId
      }).then(function (res) {
        _this2.openLoading().close();

        _this2.list = res.data;
      }).catch(function (err) {
        _this2.openLoading().close();
      });
    },
    goAdd: function goAdd(url) {
      this.$router.push(url);
      return;

      if (id) {
        this.$router.push('/flowCoupon/rangeAdd?id=' + id);
      } else {
        this.$router.push('/flowCoupon/rangeAdd');
      }
    }
  }
};
exports.default = _default;