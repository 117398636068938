var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增/编辑卡类型 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "卡类型名称：", prop: "categoryName" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入卡类型名称" },
                model: {
                  value: _vm.ruleForm.categoryName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "categoryName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.categoryName"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("卡类型关联积分规则和活动信息等")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "卡类型号段：", prop: "segmentNo" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { maxlength: "3", placeholder: "请输入卡类型号段" },
                model: {
                  value: _vm.ruleForm.segmentNo,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "segmentNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.segmentNo"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v(
                  "3位数字，具有唯一性，用于卡号编码时使用；同一个卡种，卡号为3位卡号段+3位卡种ID+4位数面额+8位自增序列。例如：卡号段为“621”的“001”卡种，面额为1000元，则卡号为“600 001 1000 000000001”"
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }