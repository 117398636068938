"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    coupon: _getCoupon.default,
    wTable: _index.default
  },
  data: function data() {
    var _self = this;

    return {
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.validStartTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/phone.xlsx"),
      columns: [{
        label: '券编码',
        prop: 'uuid',
        width: 100
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 100
      },
      /* {
        label: '券类型',
        prop: 'couponType',
        width: 100
      }, {
        label: '面额/折扣',
        prop: 'discount',
        width: 100
      }, */
      {
        label: '有效期',
        prop: 'couponValidTimeStr',
        width: 100
      }, {
        slot: 'selection'
      }, {
        slot: 'sort'
      }],
      couponShow: false,
      rules: {
        status: [{
          required: true,
          message: '请选择状态'
        }],
        validStartTime: [{
          required: true,
          message: '请选择时间'
        }],
        validEndTime: [{
          required: true,
          message: '请选择时间'
        }],
        coupons: [{
          required: true,
          message: '请选择优惠券'
        }]
      },
      ruleForm: {
        status: null,
        validStartTime: null,
        validEndTime: null,
        coupons: []
      }
    };
  },
  created: function created() {
    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id) {}
  },
  methods: {
    goDel: function goDel(scope) {
      this.ruleForm.coupons.splice(scope.$index, 1);
    },
    statusChange: function statusChange(e, index) {
      this.ruleForm.payType.forEach(function (item, eIndex) {
        item.show = eIndex === index ? 1 : 0;
      });
      this.ruleForm.show = e;
      console.log(e);
    },
    buTypeChange: function buTypeChange(e) {
      this.ruleForm.show = null;

      if (e.indexOf(4) >= 0) {
        this.ruleForm.show = 4;
        this.ruleForm.payType.forEach(function (item, eIndex) {
          item.show = eIndex === 0 ? 1 : 0;
        });
        this.ruleForm.salestypes = [4];
      }
    },
    downPhone: function downPhone() {
      var _this = this;

      var _self = this;

      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['手机号'];
        var filterVal = ['phone'];
        var list = [];

        _this.ruleForm.mobiles.forEach(function (item) {
          list.push({
            phone: item
          });
        });

        var data = _this.formatJson(filterVal, list);

        excel.export_json_to_excel({
          header: tHeader,
          data: data,
          filename: _self.ruleForm.name
        });
        _this.downloadLoading = false;
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      console.log(results);
      results.forEach(function (data) {
        if (data.手机号) {
          arr.push(String(data.手机号));
        }
      });
      this.ruleForm.mobiles = arr;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 2;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过2M的文件',
        type: 'warning'
      });
      return false;
    },
    changeUser: function changeUser(e) {
      if (e.indexOf(1) >= 0) {
        this.ruleForm.putTarget = [1];
        return;
      }
      /* if (e.indexOf(5) >= 0) {
              this.ruleForm.putTarget = [5]
              return
            } */

    },
    setCover: function setCover(e) {
      this.ruleForm.tableImage = e;
      this.submit('tableImage');
    },
    typeChange: function typeChange() {
      this.ruleForm.contentLink = '';
    },
    getLinkId: function getLinkId() {
      this.popType = this.ruleForm.contentType;
      this.$refs.coupon1.getList(this.popType, this.ruleForm.contentLink);
      this.couponShow = true;
    },
    getRelevances: function getRelevances() {
      this.$refs.couponPop.init();
      var arr = [];

      if (this.ruleForm.coupons.length > 0) {
        this.ruleForm.coupons.forEach(function (item) {
          arr.push({
            linkId: item.uuid
          });
        });
      }

      this.$refs.couponPop.assignCoupon(arr);
      this.couponShow = true;
    },
    closeCouponPop: function closeCouponPop() {
      this.couponShow = false;
    },
    setCoupons: function setCoupons(e) {
      var _this2 = this;

      console.log(e);
      var arr = [];

      if (e.length > 0) {
        e.forEach(function (item) {
          var data;

          var flag = _this2.ruleForm.coupons.some(function (value, index) {
            data = value;
            return value.uuid === item.uuid;
          });

          if (flag) {
            arr.push(data);
          } else {
            var validTime;

            if (item.validTimeType === 1) {
              validTime = item.validBeginTime + '-' + item.validEndTime;
            } else {
              validTime = _this2.getValidDay(item); // '领取后' + item.validDay + '天有效'
            }

            arr.push({
              couponId: item.id,
              uuid: item.uuid,
              couponName: item.name,
              couponImage: item.mainImage,
              couponType: item.couponTypeValue,
              couponValidTimeStr: validTime,
              sort: null,
              selection: -1,
              storeId: '12003'
            });
          }
        });
      }

      this.ruleForm.coupons = arr;
      this.submit('coupons');
      this.couponShow = false;
    },
    submit: function submit(type) {
      this.$refs['ruleForm'].validateField(type);
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.addData)(_this3.ruleForm).then(function (res) {
            _this3.$message({
              message: '恭喜你，新增成功',
              type: 'success'
            });

            _this3.$router.go(-1);
          });
          return;
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;