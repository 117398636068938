"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _api2 = require("../category/api.js");

var _api3 = require("../class/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      categoryList: [],
      classList: [],
      requestData: {
        cardNo: null,
        cardCategoryId: null,
        cardKindsId: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '卡号',
        prop: 'cardNo',
        width: 100
      }, {
        label: '类型',
        prop: 'categoryName',
        width: 100
      }, {
        label: '种类',
        prop: 'kindsName',
        width: 100
      }, {
        label: '卡面额',
        prop: 'cardLimitPrice',
        width: 50
      }, {
        label: '制卡计划',
        prop: 'planName',
        width: 100
      }, {
        slot: 'cardVerify',
        label: '是否验证',
        prop: 'cardVerify',
        width: 100
      }, {
        label: '当前余额',
        prop: 'currentPrice',
        width: 100
      }, {
        label: '激活后有效期（年）',
        prop: 'expireTradeYear',
        width: 100
      }, {
        label: '入库时间',
        prop: 'inTime',
        width: 100
      }, {
        slot: 'isRealName',
        label: '是否实名',
        prop: 'isRealName',
        width: 100
      }, {
        /*
        1-固定额度充值 0-不固定额度充值*/
        slot: 'rechargeType',
        label: '充值类型',
        prop: 'rechargeType',
        width: 100
      }, {
        label: '状态',
        prop: 'statusDesc',
        width: 100
      }, {
        label: '子状态',
        prop: 'childStatusDesc',
        width: 100
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'operate'
      }]
    };
  },
  activated: function activated() {
    this.getList();
  },
  created: function created() {
    var _this = this;

    (0, _api2.categoryPage)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.categoryList = res.data.records;
    });
    (0, _api3.classPage)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.classList = res.data.records;
    });
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this2.requestData[key]) {
          data[key] = _this2.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getList)(data).then(function (res) {
        list = res.data.records;
        _this2.downloadLoading = false;

        _this2.getHandleDownload(tHeader, filterVal, list, '卡信息列表');
      }).catch(function (res) {
        _this2.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getList)(this.requestData).then(function (res) {
        _this3.tableData = res.data.records;
        _this3.requestData.total = res.data.count;
        return;
      });
    }
  }
};
exports.default = _default;