"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _image = _interopRequireDefault(require("../components/UploadFile/image"));

var _images = _interopRequireDefault(require("../components/UploadFile/images"));

var _file = _interopRequireDefault(require("../components/UploadFile/file"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _api = require("../api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadImage: _image.default,
    UploadImages: _images.default,
    UploadFile: _file.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      title: '新增',
      ruleForm: {
        mobile: '',
        supportPhone: '',
        name: '',
        position: '',
        status: '',
        sort: '',
        remark: '',
        headSculpture: '',
        qrCode: ''
      },
      id: 0,
      rules: {
        mobile: [{
          required: true,
          message: '请输入企微电话',
          trigger: 'blur'
        }, {
          min: 11,
          message: "企微电话格式不正确",
          trigger: "blur"
        }],
        supportPhone: [{
          required: true,
          message: '请选择是否支持',
          trigger: 'change'
        }],
        position: [{
          required: true,
          message: '请输入职位'
        }],
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        headSculpture: [{
          required: true,
          message: '请上传头像',
          trigger: 'blur'
        }],
        qrCode: [{
          required: true,
          message: '请上传企微二维码',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }]
      }
    };
  },
  activated: function activated() {
    if (this.$route.query.id) {
      this.getInfoMes(this.$route.query.id);
      this.title = '编辑';
    } else {
      this.title = '新增';
    }
  },
  methods: {
    setCover: function setCover(e, prop) {
      this.ruleForm[prop] = e;
      console.log(this.ruleForm);
    },
    goSearch: function goSearch() {
      var _this = this;

      this.apiGet('/api/groupManage/queryWeChatByMobile/' + this.ruleForm.mobile).then(function (res) {
        _this.ruleForm.name = res.data.name;
        _this.ruleForm.qrCode = res.data.qrCode;
        _this.ruleForm.headSculpture = res.data.avatar;
      });
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var that = this;
      var obj = Object.assign({}, this.ruleForm);
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.addAndEditGroupManage)(obj).then(function (res) {
            if (res.success) {
              that.$message({
                message: that.title + '成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            } else {
              that.$message({
                message: res.msg,
                type: 'warning'
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getInfoMes: function getInfoMes(id) {
      var _this3 = this;

      (0, _api.getInfo)(id).then(function (res) {
        _this3.ruleForm = res.data;
      });
    }
  }
};
exports.default = _default;