var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c(
      "div",
      { staticClass: "card_box" },
      [
        _c("div", { staticClass: "box_top" }, [
          _c(
            "p",
            [
              _c("font", [_vm._v("*")]),
              _vm._v("\n        只保存详情图和排序都填写的信息\n      ")
            ],
            1
          ),
          _vm._v(" "),
          _c("i", {
            staticClass: "el-icon-circle-close",
            on: { click: _vm.setDetailRollimg }
          })
        ]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              size: "small",
              data: _vm.detailRollimg,
              "max-height": "460"
            }
          },
          [
            _c("el-table-column", {
              attrs: { prop: "date", label: "图片", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center"
                          },
                          on: {
                            click: function($event) {
                              return _vm.setCount(scope.$index)
                            }
                          }
                        },
                        [
                          _c("add-load", {
                            attrs: {
                              width: 100,
                              height: 80,
                              cover: scope.row.img
                            },
                            on: { setCover: _vm.setCover }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "排序",
                width: "120",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        attrs: { size: "mini", type: "number" },
                        model: {
                          value: scope.row.sort,
                          callback: function($$v) {
                            _vm.$set(scope.row, "sort", $$v)
                          },
                          expression: "scope.row.sort"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "address",
                label: "编辑",
                width: "120",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.deleteDetailRollimg(scope.$index)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.addDetailRollimg }
              },
              [_vm._v("添加")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.setDetailRollimg }
              },
              [_vm._v("保存")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }