"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      tableData: [],
      columns: [{
        label: '订单号',
        prop: 'orderNo',
        width: 10
      }, {
        label: '外部订单号',
        prop: 'outOrderNo',
        width: 10
      }, {
        label: '退款单号',
        prop: 'refundeNo',
        width: 10
      }, {
        label: '用户名称',
        prop: 'username',
        width: 8
      }, {
        label: '用户电话',
        prop: 'userMobile',
        width: 8
      }, {
        label: '商品名称',
        prop: 'productName',
        width: 10
      }, {
        label: '商品编码',
        prop: 'productCode',
        width: 10
      }, {
        label: '下单时间',
        prop: 'orderCreateDate',
        width: 10
      }]
    };
  },
  methods: {
    handleDownload: function handleDownload(type) {
      var _this = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];

        _this.columns.forEach(function (item) {
          if (item.prop && item.label) {
            tHeader.push(item.label);
            filterVal.push(item.prop);
          }
        });

        var data = _this.formatJson(filterVal, _this.tableData);

        excel.export_json_to_excel({
          header: tHeader,
          data: data,
          filename: type ? '黔农云订单退款' : '黔农云订单查询'
        });
        _this.downloadLoading = false;
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this2 = this;

      (0, _api.listSiipWrongOrder)({
        flag: type === 'refund' ? true : false
      }).then(function (res) {
        _this2.tableData = res.data.refundList;

        _this2.handleDownload(type === 'refund' ? true : false);

        return;
      });
    }
  }
};
exports.default = _default;