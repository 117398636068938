var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "appliDea" }, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("活动详情")]),
    _vm._v(" "),
    _c("p", { staticClass: "appli_tit" }, [_vm._v("\n    活动详情\n  ")]),
    _vm._v(" "),
    _c("div", { staticClass: "appli_box" }, [
      _c("ul", [
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动名称：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [_vm._v(_vm._s(_vm.name))])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动时间：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.time[0]) + "至" + _vm._s(_vm.time[1]))
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动地点：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [_vm._v(_vm._s(_vm.address))])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动BANNEr：")]),
          _vm._v(" "),
          _c("div", { staticClass: "li_banner" }, [
            _c("img", { attrs: { src: _vm.bannerPath } })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("会员机构：")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "li_institutionNames" },
            _vm._l(_vm.institutionNames, function(item, index) {
              return _c("span", { key: index }, [_vm._v(_vm._s(item))])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动详情内容：")]),
          _vm._v(" "),
          _c("div", {
            staticClass: "li_content",
            domProps: { innerHTML: _vm._s(_vm.content) }
          })
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("活动上线时间：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [_vm._v(_vm._s(_vm.beginTime))])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "appli_tit" }, [_vm._v("\n    活动数据\n  ")]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "appli_box" }, [
      _c("div", { staticClass: "appli_box_activity" }, [_vm._v("暂无数据")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }