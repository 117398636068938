"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

var _api = require("./api.js");

var _order_express = _interopRequireDefault(require("@/utils/order_express.json"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "deliverDr",
  data: function data() {
    return {
      expressList: _order_express.default,
      open: false,
      subing: false,
      form: {}
    };
  },
  mounted: function mounted() {},
  methods: {
    changeExpress: function changeExpress(e) {
      console.log(e);
      var express = this.expressList.find(function (item) {
        return item.code == e;
      });
      this.form.logisticName = express.name;
    },
    handleSubmit: function handleSubmit() {
      var _this = this;

      this.subing = true;
      var _this$form = this.form,
          id = _this$form.id,
          logisticName = _this$form.logisticName,
          logisticNo = _this$form.logisticNo,
          logisticCode = _this$form.logisticCode;
      (0, _api.updateOrder)({
        id: id,
        logisticName: logisticName,
        logisticNo: logisticNo,
        logisticCode: logisticCode
      }).then(function () {
        _this.$message.success("操作成功");

        _this.subing = false;
        _this.open = false;

        _this.$emit('finished');
      }).catch(function () {
        _this.subing = false;
      });
    }
  }
};
exports.default = _default;