"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//import {goUpdate,goChangeStatus} from "./api.js"
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      tableData: [],
      requestData: {
        name: null,
        account: null,
        pageSize: 10,
        page: 1
      },
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '订单号',
        value: 'orderNo'
      }, {
        type: 'input',
        span: 4,
        placeholder: '米卡UID',
        value: 'buyUtphone'
      }, {
        type: 'input',
        span: 4,
        placeholder: '农商行用户名，手机号查询',
        value: 'remark'
      }, {
        label: '开始时间',
        type: 'dateTime',
        span: 4,
        placeholder: '请选择开始时间',
        value: 'startTime'
      }, {
        label: '结束时间',
        type: 'dateTime',
        span: 4,
        placeholder: '请选择结束时间',
        defaultTime: '23:59:59',
        value: 'endTime'
      }]
    };
  },
  mounted: function mounted() {//this.$refs.wTable.setRequestValue('type',1)
  },
  methods: {
    goAudit: function goAudit(row, type) {
      var _self = this;

      goChangeStatus({
        id: row.id,
        status: type
      }).then(function (res) {
        _self.$refs.wTable.getList();

        _self.$message({
          type: 'success',
          message: '操作成功!'
        });
      });
    }
  }
};
exports.default = _default;