"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _api = require("../api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      tableData: [],
      defaultKeys: [],
      defaultProps: {
        children: 'childInstitutions',
        label: 'name'
      },
      rules: {
        name: [{
          required: true,
          message: '请填写名称'
        }],
        showApp: [{
          required: true,
          message: '请选择显示状态'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        sort: [{
          required: true,
          message: '请填写排序'
        }]
      },
      form: {
        id: '',
        name: '',
        status: '',
        parentId: 0,
        sort: '',
        showApp: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.form.id = this.$route.query.id || '';

    if (this.form.id) {
      this.form.id = Number(this.form.id);
      (0, _api.category)(this.form.id).then(function (res) {
        console.log(res);
        _this.form.showApp = res.data.showApp;
        _this.form.name = res.data.name;
        _this.form.sort = res.data.sort;
        _this.form.status = res.data.status;

        if (res.data.parentId != 0) {
          _this.form.parentId = res.data.parentId;
          _this.defaultKeys = [res.data.parentId];
        }
      });
    }

    (0, _api.list)({}).then(function (res) {
      _this.tableData = res.data;
    });
  },
  methods: {
    nodeClick: function nodeClick(data, node) {
      this.childNodesChange(node);
      this.parentNodesChange(node);
    },
    handleClick: function handleClick(data, checked, node) {
      if (checked == true) {
        this.form.parentId = data.id;
        this.$refs.treeForm.setCheckedNodes([data]);
      } else {
        this.form.parentId = 0;
      }
    },
    childNodesChange: function childNodesChange(node) {
      this.form.parentId = data.id;
      this.$refs.treeForm.setCheckedNodes([data]);
      var len = node.childNodes.length;

      for (var i = 0; i < len; i++) {
        node.childNodes[i].checked = false;
        this.childNodesChange(node.childNodes[i]);
      }
    },
    parentNodesChange: function parentNodesChange(node) {
      if (node.parent) {
        for (var key in node) {
          if (key == 'parent') {
            node[key].checked = true;
            this.parentNodesChange(node[key]);
          }
        }
      }
    },
    onSubmit: function onSubmit(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.openLoading();

          if (_this2.form.id) {
            (0, _api.edit)(_this2.form).then(function (res) {
              _this2.openLoading().close();

              _this2.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            }).catch(function (err) {
              _this2.openLoading().close();
            });
          } else {
            (0, _api.create)(_this2.form).then(function (res) {
              _this2.openLoading().close();

              _this2.$message({
                message: '保存成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            }).catch(function (err) {
              _this2.openLoading().close();
            });
          }
        }
      });
    }
  }
};
exports.default = _default;