"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addKinds = addKinds;
exports.cardLoss = cardLoss;
exports.cardRecharge = cardRecharge;
exports.editKinds = editKinds;
exports.getBindPage = getBindPage;
exports.getCardInfo = getCardInfo;
exports.getCardName = getCardName;
exports.getKinds = getKinds;
exports.getList = getList;
exports.getOldCardBalanceInfos = getOldCardBalanceInfos;
exports.oldCardBingOnlineCard = oldCardBingOnlineCard;
exports.oldCardChange = oldCardChange;
exports.resetPassword = resetPassword;
exports.tradePage = tradePage;

var _request = _interopRequireDefault(require("@/utils/request"));

function getBindPage(data) {
  return (0, _request.default)({
    url: '/api/make/store/pageCardBindLog',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*

 */


function cardLoss(data) {
  return (0, _request.default)({
    url: '/api/make/store/loss',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function resetPassword(data) {
  return (0, _request.default)({
    url: '/api/make/store/reset/password',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getList(data) {
  return (0, _request.default)({
    url: '/api/make/store/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function addKinds(data) {
  return (0, _request.default)({
    url: '/api/make/store',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editKinds(data) {
  return (0, _request.default)({
    url: '/api/make/store/update',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getKinds(data) {
  return (0, _request.default)({
    url: '/api/make/store/' + data,
    method: 'get',
    loading: 'loading'
  });
}

function getCardInfo(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/detail',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getCardName(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/real/name',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function cardRecharge(data) {
  return (0, _request.default)({
    url: '/api/card/make/info/recharge',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function tradePage(data) {
  return (0, _request.default)({
    url: '/api/make/trade/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function oldCardChange(data) {
  return (0, _request.default)({
    url: '/api/make/store/oldCardChange',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function oldCardBingOnlineCard(data) {
  return (0, _request.default)({
    url: '/api/make/store/oldCardBingOnlineCard',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getOldCardBalanceInfos(data) {
  return (0, _request.default)({
    url: '/api/make/store/getOldCardBalanceInfos',
    method: 'get',
    loading: 'loading',
    params: data
  });
}