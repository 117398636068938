"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      id: '',
      title: '新增触发投放方案',
      orientObj: {},
      putNumber: '',
      type: '',
      typeTxt: '',
      relevanceValue: ''
    };
  },
  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      this.id = Number(this.$route.query.unid);

      if (this.id) {
        this.title = '编辑触发投放方案';
      }

      this.orientObj = JSON.parse(sessionStorage.getItem('orientObj'));

      if (this.orientObj.putNumber === 0) {
        this.putNumber = '无限制';
      } else {
        this.putNumber = this.orientObj.putNumber;
      }

      console.log(this.orientObj);

      if (this.orientObj.tiggType === 1) {
        this.type = '用户行为';
        this.typeTxt = this.orientObj.tiggValue.relevanceName;
        this.relevanceValue = this.orientObj.tiggValue.id;
      } else if (this.orientObj.tiggType === 3) {
        this.type = '充值满送';
        this.typeTxt = this.orientObj.tiggValue.relevanceName + this.orientObj.tiggValue.relevanceValue;
        this.relevanceValue = this.orientObj.tiggValue.relevanceValue;
      }
    },
    next: function next() {
      var _this = this;

      this.$confirm('此操作将保存方案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var obj = _this.orientObj;
        obj.tiggValue = _this.relevanceValue;

        if (_this.id) {
          obj.id = _this.id;
          console.log(obj);
          (0, _coupon.editPutTigger)(obj).then(function (res) {
            _this.$message({
              type: 'success',
              message: '编辑成功!'
            });

            _this.$router.push('/coupon/couponPut/putDea?id=touch&unid=' + _this.id);
          });
        } else {
          (0, _coupon.addPutTigger)(obj).then(function (res) {
            _this.$message({
              type: 'success',
              message: '保存成功!'
            });

            _this.$router.push('/coupon/couponPut/touchPut');
          });
        }
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消保存'
        });
      });
    }
  }
};
exports.default = _default;