"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("@/api/svip/index.js");

var _Descriptions = _interopRequireDefault(require("@/components/mc/Descriptions.vue"));

var _map = require("../../const/map");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Descriptions: _Descriptions.default
  },
  data: function data() {
    return {
      member: {},
      logList: []
    };
  },
  computed: {
    columns: function columns() {
      return [{
        title: '会员姓名',
        key: 'name',
        dataIndex: 'name'
      }, {
        title: '性别',
        key: 'sex',
        dataIndex: 'sex',
        valueEnum: _map.SvipSex
      }, {
        title: '会员手机号',
        key: 'mobile',
        dataIndex: 'mobile'
      }, {
        title: '联系电话',
        key: 'contactNumber',
        dataIndex: 'contactNumber'
      }, {
        title: '荔星伙伴',
        key: 'managerName',
        dataIndex: 'managerName'
      }, {
        title: '申请时间',
        key: 'createTime',
        dataIndex: 'createTime'
      }, {
        title: '状态',
        key: 'status',
        dataIndex: 'status',
        valueEnum: _map.SvipReviewStatus
      }, {
        title: '原因',
        key: 'reason',
        dataIndex: 'reason'
      }];
    }
  },
  created: function created() {
    var _this = this;

    var id = this.$route.params && this.$route.params.id;

    if (id) {
      (0, _index.getMemberAudit)({
        id: id
      }).then(function (res) {
        _this.member = res.data;
        _this.logList = res.data.logList;
      });
    }
  },
  methods: {}
};
exports.default = _default;