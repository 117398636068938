"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var giftTokenRouter = {
  path: '/giftToken',
  component: _layout.default,
  redirect: '/giftToken/list',
  name: 'giftToken',
  meta: {
    title: '提货券'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/deposit/giftToken/list.vue'));
      });
    },
    name: 'giftTokenList',
    meta: {
      title: '提货券',
      keepAlive: true
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/deposit/giftToken/add.vue'));
      });
    },
    name: 'giftTokenAdd',
    meta: {
      title: '提货券-编辑',
      activeMenu: '/giftToken/list'
    }
  }, {
    path: 'editStock',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/deposit/giftToken/editStock.vue'));
      });
    },
    name: 'giftTokeneditStock',
    meta: {
      title: '提货券-库存',
      activeMenu: '/giftToken/list'
    }
  }, {
    path: 'set',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/deposit/giftToken/set.vue'));
      });
    },
    name: 'cardClassSet',
    meta: {
      title: '提货券-设置'
    }
  }, {
    path: 'report',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/deposit/giftToken/report.vue'));
      });
    },
    name: 'giftTokenReport',
    meta: {
      title: '提货券统计',
      keepAlive: true
    }
  }]
};
var _default = giftTokenRouter;
exports.default = _default;