var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search_input" },
        [
          _c(
            "el-row",
            {
              staticClass: "row_box",
              staticStyle: { padding: "20px 0" },
              attrs: { gutter: 5 }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "活动名称" },
                    model: {
                      value: _vm.obj.specialName,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "specialName", $$v)
                      },
                      expression: "obj.specialName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { size: "mini", placeholder: "积分倍数" },
                      model: {
                        value: _vm.obj.scoreMultiple,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "scoreMultiple", $$v)
                        },
                        expression: "obj.scoreMultiple"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "所有", value: -1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "不积分", value: 0 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "多倍", value: 1 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { size: "mini", placeholder: "状态" },
                      model: {
                        value: _vm.obj.specialStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "specialStatus", $$v)
                        },
                        expression: "obj.specialStatus"
                      }
                    },
                    _vm._l(_vm.typeArr, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { "max-width": "400px", width: "100%" },
                      attrs: {
                        size: "mini",
                        placeholder: "所属总部",
                        filterable: ""
                      },
                      on: { change: _vm.setShort },
                      model: {
                        value: _vm.obj.companyCode,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "companyCode", $$v)
                        },
                        expression: "obj.companyCode"
                      }
                    },
                    _vm._l(_vm.companyList, function(item) {
                      return _c("el-option", {
                        key: item.companyCode,
                        attrs: {
                          label: item.shortName,
                          value: item.companyCode
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { "max-width": "400px", width: "100%" },
                      attrs: {
                        size: "mini",
                        placeholder: "所属门店",
                        filterable: ""
                      },
                      model: {
                        value: _vm.obj.storeCode,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "storeCode", $$v)
                        },
                        expression: "obj.storeCode"
                      }
                    },
                    _vm._l(_vm.storeList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.storeId,
                          attrs: {
                            label:
                              item.shortName + "（" + item.storeCode + "）",
                            value: item.storeCode
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                item.shortName + "（" + item.storeCode + "）"
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "品类编码" },
                    model: {
                      value: _vm.obj.brandClsCode,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "brandClsCode", $$v)
                      },
                      expression: "obj.brandClsCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "品牌编码" },
                    model: {
                      value: _vm.obj.brandCode,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "brandCode", $$v)
                      },
                      expression: "obj.brandCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "商品编码" },
                    model: {
                      value: _vm.obj.goodsCode,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "goodsCode", $$v)
                      },
                      expression: "obj.goodsCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.init("search")
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "info",
                    size: "mini",
                    icon: "el-icon-refresh"
                  },
                  on: {
                    click: function($event) {
                      return _vm.init("remove")
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: {
                    click: function($event) {
                      return _vm.goPage("add")
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.list, size: "mini" }
        },
        [
          _vm._l(_vm.tabTh, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: item.prop,
                label: item.label,
                align: "center",
                "min-width": item.width
              }
            })
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "table_td_btn",
                        on: {
                          click: function($event) {
                            return _vm.goPage("edit", scope.row.specialId)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.obj.pageNo,
          limit: _vm.obj.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.obj, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.obj, "pageSize", $event)
          },
          pagination: _vm.init
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }