"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _report = require("@/api/report.js");

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      userInfo: {},
      signList: [],
      isGoCard: false,
      ruleForm: {
        userMobile: null
      }
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    submitForm: function submitForm() {
      (0, _api.goSignReissue)({
        phone: this.ruleForm.userMobile
      }).then(function (res) {
        console.log(res);
      });
    },
    changeMobile: function changeMobile() {
      this.isGoCard = false;
    },
    getUserInfo: function getUserInfo() {
      var _this = this;

      this.signList = [];
      (0, _api.getSignPage)({
        pageNo: 1,
        pageSize: 500,
        phone: this.ruleForm.userMobile
      }).then(function (res) {
        _this.signList = res.data.records;
      });
      var data = {
        mobile: this.ruleForm.userMobile,
        type: 1
      };
      this.isGoCard = false;
      (0, _report.handleMidUser)(data).then(function (res) {
        _this.isGoCard = true;
        _this.userInfo = res.data.user;
        _this.userInfo.status = res.data.status;
      });
    }
  }
};
exports.default = _default;