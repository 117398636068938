var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container flex_center" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            size: "mini",
            model: _vm.loginForm,
            rules: _vm.loginRules,
            autocomplete: "on",
            "label-position": "left"
          }
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("h3", { staticClass: "title" }, [_vm._v("MikaCRM平台登录")])
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                ref: "username",
                attrs: {
                  placeholder: "用户名",
                  name: "username",
                  type: "text",
                  tabindex: "1",
                  autocomplete: "on"
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function($$v) {
                    _vm.$set(_vm.loginForm, "username", $$v)
                  },
                  expression: "loginForm.username"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                key: _vm.passwordType,
                ref: "password",
                attrs: {
                  type: _vm.passwordType,
                  placeholder: "密码",
                  name: "password",
                  tabindex: "2",
                  autocomplete: "on"
                },
                nativeOn: {
                  keyup: [
                    function($event) {
                      return _vm.checkCapslock($event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleLogin($event)
                    }
                  ]
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v)
                  },
                  expression: "loginForm.password"
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.passwordType === "password" ? "eye" : "eye-open"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "remenberPassword" }, [
            _c(
              "p",
              { staticStyle: { color: "#555557" } },
              [
                _c("el-checkbox", {
                  staticStyle: { "margin-right": "10px" },
                  model: {
                    value: _vm.checked,
                    callback: function($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked"
                  }
                }),
                _vm._v("记住密码")
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticStyle: { color: "#1890FF" } }, [_vm._v("忘记密码")])
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-bottom": "30px" },
              attrs: { loading: _vm.loading, type: "primary" },
              nativeOn: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleLogin($event)
                }
              }
            },
            [_vm._v("登录")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "Or connect with", visible: _vm.showDialog },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            }
          }
        },
        [
          _vm._v(
            "\n    Can not be simulated on local, so please combine you own business simulation! ! !\n    "
          ),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("social-sign")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }