"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SingleImageUpload3',
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    cover: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      imgSrc: this.$store.state.user.imgSrc,
      action: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile'
    };
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        'Token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {},
  methods: {
    handleSuccess: function handleSuccess(res) {
      var _this = this;

      this.openLoading().close();
      this.dialogVisible = true;

      if (res.ret === 1000) {
        console.log(res.data);
        this.$emit('setCover', res.data);
      } else if (res.ret === 6000) {
        this.$confirm(res.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      this.openLoading('努力上传中');
      var isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 2;
      console.log(file.size / 1024 / 1024);

      if (!isJPG) {
        this.openLoading().close();
        this.$message.error('上传图片只能是 JPG/png 格式!');
        return false;
      }

      if (!isLt2M) {
        this.openLoading().close();
        this.$message.error('上传图片大小不能超过 2MB!');
        return false;
      }
    },
    onPreview: function onPreview() {
      console.log('onPreview');
    },
    onProgress: function onProgress() {
      console.log('onProgress');
    },
    onError: function onError(res) {
      this.openLoading().close();
      this.$message.error('网络连接超时，请稍后重试');
    }
  }
};
exports.default = _default;