"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.search");

var _api = require("../api.js");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default
  },
  data: function data() {
    return {
      search: '',
      obj: {},
      tableData: [],
      expand: []
    };
  },
  computed: {
    dataList: function dataList() {
      var _this = this;

      var arr = this.tableData.filter(function (data) {
        return JSON.stringify(data).indexOf(_this.search) >= 0;
      });
      return arr;
    }
  },
  activated: function activated() {
    var _this2 = this;

    (0, _api.list)({}).then(function (res) {
      _this2.tableData = res.data;
    });
  },
  created: function created() {},
  methods: {
    goAdd: function goAdd(url) {
      this.$router.push(url);
    }
  }
};
exports.default = _default;