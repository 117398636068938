"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regionAll = _interopRequireDefault(require("@/utils/regionAll.json"));

//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      formData: [{
        label: '类型：',
        type: 'radio',
        placeholder: '请选择类型',
        value: 'type',
        selectList: [{
          label: '收货地址',
          value: 1
        }, {
          label: '退货地址',
          value: 2
        }]
      }, {
        label: '是否显示：',
        type: 'radio',
        placeholder: '请选择是否显示',
        value: 'isShow',
        selectList: [{
          label: '显示',
          value: 1
        }, {
          label: '不显示',
          value: 0
        }]
      }, {
        label: '地址：',
        type: 'input',
        placeholder: '请输入地址',
        value: 'address'
      }, {
        label: '手机号码：',
        type: 'input',
        placeholder: '请输入手机号码',
        value: 'mobile'
      }],
      ruleForm: {
        "id": null,
        type: null,
        address: undefined,
        mobile: undefined,
        isShow: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id || '';

    if (this.ruleForm.id) {
      this.apiPost('/api/mall/merchantCategory/detail', {
        id: this.ruleForm.id
      }).then(function (res) {
        _this.ruleForm = res.data;
      });
    }
  },
  methods: {
    /*
    保存商品
    */
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var apiUrl = this.ruleForm.id ? '/api/mall/merchantAddress/update' : '/api/mall/merchantAddress/create';
      var requestData = JSON.parse(JSON.stringify(this.ruleForm));
      this.apiPost(apiUrl, requestData).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: _this2.ruleForm.id ? '编辑成功!' : '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;