var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pop" }, [
    _c("div", { staticClass: "pop_box" }, [
      _c("img", { attrs: { src: _vm.qrCode } }),
      _vm._v(" "),
      _c("p", { staticClass: "url" }, [_vm._v(_vm._s(_vm.url))]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn_dow",
          attrs: { href: _vm.qrCode, download: _vm.couponUUid }
        },
        [_vm._v("下载二维码")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "close_btn", on: { click: _vm.closePop } }, [
        _vm._v("X")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }