var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("确认文章添加")]),
    _vm._v(" "),
    _c("p", { staticClass: "appli_tit" }, [_vm._v("\n    文章详情\n  ")]),
    _vm._v(" "),
    _c("div", { staticClass: "appli_box" }, [
      _c("ul", [
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("文章分类：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.contentObj.classId))
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("文章标题：")]),
          _vm._v(" "),
          _c("p", { staticClass: "li_txt" }, [
            _vm._v(_vm._s(_vm.contentObj.title))
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [_vm._v("文章内容：")]),
          _vm._v(" "),
          _c("p", {
            staticClass: "li_txt content",
            domProps: { innerHTML: _vm._s(_vm.contentObj.content) }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cofim_btn" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.next } },
          [_vm._v("确认添加")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }