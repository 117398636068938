"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("../index.js");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default
  },
  data: function data() {
    return {
      list: [],
      obj: {
        fullName: '',
        systemId: ''
      },
      systemList: [],
      tabTh: [{
        label: '总部编码',
        prop: 'companyCode',
        width: 80
      }, {
        label: '总部全称',
        prop: 'fullName',
        width: 200
      }, {
        label: '总部简称',
        prop: 'shortName',
        width: 150
      }, {
        label: '备注信息',
        prop: 'remark',
        width: 200
      }, {
        label: '门店数量',
        prop: 'storeNumber',
        width: 100
      }, {
        label: 'ERP',
        prop: 'systemName',
        width: 100
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _index.listSystem)().then(function (res) {
      _this.systemList = res.data;
    });
    this.init();
  },
  methods: {
    init: function init(type) {
      var _this2 = this;

      if (type == 'remove') {
        this.obj = {
          fullName: '',
          systemId: ''
        };
      }

      (0, _index.listCompany)(this.obj).then(function (res) {
        _this2.list = res.data;
      });
    },
    addDepartment: function addDepartment() {
      // 新增
      this.$router.push('/system/manage/departmentDea');
    },
    storeManage: function storeManage(row) {
      this.$router.push('/system/manage/listStoreList?companyId=' + row.companyId);
    },
    goEdit: function goEdit(row) {
      this.$router.push('/system/manage/addFull?companyId=' + row.companyId);
    },

    /*
      新增总部
      */
    addFull: function addFull() {
      this.$router.push('/system/manage/addFull');
    },
    addStore: function addStore() {
      this.$router.push('/system/manage/addStore');
    }
  }
};
exports.default = _default;