var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    [
      _vm.hasOneShowingChild(_vm.item.childMenu, _vm.item) &&
      (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
      !_vm.item.alwaysShow
        ? [
            _vm.onlyOneChild.name
              ? _c(
                  "app-link",
                  { attrs: { to: _vm.resolvePath(_vm.onlyOneChild.url) } },
                  [
                    _c(
                      "el-menu-item",
                      {
                        class: { "submenu-title-noDropdown": !_vm.isNest },
                        attrs: { index: _vm.resolvePath(_vm.onlyOneChild.url) }
                      },
                      [
                        _c("item", {
                          attrs: {
                            icon: _vm.item.icon,
                            title: _vm.onlyOneChild.name
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _c(
            "el-submenu",
            {
              ref: "subMenu",
              attrs: {
                index: _vm.resolvePath(_vm.item.url),
                "popper-append-to-body": ""
              }
            },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _vm.item.name
                    ? _c("item", {
                        attrs: { icon: _vm.item.icon, title: _vm.item.name }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.item.childMenu, function(child, index) {
                return _c("sidebar-item", {
                  key: index,
                  staticClass: "nest-menu",
                  attrs: {
                    "is-nest": true,
                    item: child,
                    "base-path": _vm.resolvePath(child.url)
                  }
                })
              })
            ],
            2
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }