"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _api = require("./api.js");

var _api2 = require("../api.js");

var _user = require("@/api/user.js");

var _axios = _interopRequireDefault(require("axios"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _jsTable2excel = _interopRequireDefault(require("js-table2excel"));

var _rights = require("@/api/svip/rights.js");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      sourceList: [],
      couponUUid: '',
      popShow: false,
      // 小程序码弹框
      qrCode: '',
      // 小程序码
      url: '',
      // 小程序链接
      imgSrc: this.$store.state.user.imgSrc,
      downloadLoading: false,
      classProp: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'childInstitutions',
        multiple: true
      },
      categoryId: [],
      obj: {
        pageNo: 1,
        pageSize: 10,
        productCode: '',
        categoryIds: [],
        productName: '',
        productSource: [],
        onShel: 1,
        productSourceId: []
      },
      classList: [],
      dataList: [],
      total: 0,
      tabTh: [{
        prop: 'productName',
        label: '商品名称',
        width: 80
      }, {
        prop: 'productCode',
        label: '商品编码',
        width: 100
      }, {
        prop: 'productSourceName',
        label: '货源地',
        width: 80
      }, {
        prop: 'categoryName',
        label: '分类',
        width: 70
      }, {
        prop: 'costPrice',
        label: '成本价（元）',
        width: 60
      }, {
        prop: 'marketPrice',
        label: '市场价（元）',
        width: 60
      }, {
        prop: 'memberTypeText',
        label: '可购买会员类型',
        width: 100
      }],
      lavantList: {}
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/mall/product/source/listSource', {}).then(function (res) {
      _this.sourceList = res.data.filter(function (item) {
        return _this.sourceIds.includes(item.id);
      });
    });
    /*  sourceAll().then((res) => {
        this.sourceList = res.data.filter((item) => {
          return this.sourceIds.includes(item.id)
        })
      }) */

    if (this.sourceIds && this.sourceIds.length == 1) {
      this.obj.productSourceId = [this.sourceIds[0]];
    }

    (0, _rights.getMemberLevelList)().then(function (res) {
      res.data.forEach(function (item) {
        _this.lavantList[item.id] = item.name;
      });
    });
    (0, _api2.goodsList)({}).then(function (res) {
      _this.classList = _this.getTreeData(res.data);
    });
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sourceIds'])),
  activated: function activated() {
    this.init();
  },
  methods: {
    clearSelect: function clearSelect() {
      this.$refs.goodsTable.clearSelection();
    },
    goGoodsStatus: function goGoodsStatus(type) {
      var _this2 = this;

      /*
       这段 Vue 代码的作用是实现商品的上架和下架。
      具体来说，代码首先获取了当前选中的商品列表，
      然后根据传入的 type 参数确定商品的上架或下架状态，
      接着根据选择的商品列表和上架或下架状态过滤出不符合条件的商品，
      如果有不符合条件的商品则弹出错误提示并返回。
      如果所有商品均符合条件，则将商品的 id、productName 和 productCode 分别存储在
      changeGoods、changeGoodsName 和 changeGoodsCode 变量中，
      并弹出确认提示框，提示用户此次操作会将选择的商品上架或下架，
      确认后调用 goodsUpdown 方法实现商品的上架或下架。
      最后清空选中的商品列表并更新数据。其中 mapGoodsProperty 函数是一个辅助函数，
      用于获取商品列表中指定属性的值。
      */
      var selectGoods = this.$refs.goodsTable.store.states.selection;
      var onShelf = ''; //type === 'down' ? 0 : 1;

      switch (type) {
        case 'down':
          onShelf = 0;
          break;

        case 'up':
          onShelf = 1;
          break;

        case 'del':
          onShelf = 2;
          break;

        default:
          break;
      } //  let onShelf = type === 'down' ? 0 : 1;


      var errGoods = selectGoods.filter(function (item) {
        return item.onShel === onShelf;
      });

      if (errGoods.length > 0) {
        if (type === 'del') {
          this.$message.error("\u5DF2\u7ECF\u5220\u9664\u7684\u5546\u54C1\u4E0D\u652F\u6301\u518D\u5220\u9664");
          return;
        }

        this.$message.error("".concat(type === 'down' ? '下架' : '上架', "\u5546\u54C1\u53EA\u80FD\u9009\u62E9\u5DF2").concat(type === 'down' ? '上架' : '下架', "\u5546\u54C1"));
        return;
      }

      var changeGoods = selectGoods.map(function (item) {
        return item.id;
      });
      var changeGoodsName = mapGoodsProperty(selectGoods, 'productName');
      var changeGoodsCode = mapGoodsProperty(selectGoods, 'productCode');
      var affrimTypeText = {
        down: "下架",
        up: "上架",
        del: "删除"
      };
      this.$confirm("\u6B64\u64CD\u4F5C\u5C06".concat(affrimTypeText[type], "\u5546\u54C1").concat(changeGoodsName.join(','), ",\u7F16\u7801\uFF1A").concat(changeGoodsCode.join(',')), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = {
          ids: changeGoods,
          onShel: onShelf
        };
        (0, _api.goodsUpdown)(data).then(function (res) {
          _this2.$refs.goodsTable.clearSelection();

          _this2.init();

          _this2.$message({
            type: 'success',
            message: '修改成功!'
          });
        });
      });

      function mapGoodsProperty(goodsList, property) {
        return goodsList.map(function (item) {
          return item[property];
        });
      }
    },
    isSelectDisabled: function isSelectDisabled(row) {
      return row.isJoin != 1;
    },
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getCode: function getCode(scope) {
      var _this3 = this;

      // 获取小程序码
      this.couponUUid = scope.productName;
      this.url = 'mall/goods/dea?id=' + scope.productCode;
      this.openLoading('获取小程序码中');
      (0, _user.couponQRCode)({
        path: 'mall/goods/dea',
        scene: scope.productCode // width:'2000',
        //  path: "pages/index/index",
        // scene: "share_id=70004&type=1"

      }).then(function (res) {
        _this3.openLoading().close();

        _this3.qrCode = res.data;
        _this3.popShow = true;
      }).catch(function (res) {
        _this3.openLoading().close();
      });
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = [];
        var filterVal = [];

        var column = _this4.tabTh.map(function (item) {
          return {
            title: item.label,
            key: item.prop,
            type: 'text'
          };
        });
        /* this.tabTh.forEach((item) => {
           filterVal.push(item.prop)
           tHeader.push(item.label)
         }) */

        /* tHeader = tHeader.concat(['销售价格', '库存', '状态'])
        filterVal = filterVal.concat(['priceTxt', 'specsTxt', 'onShelTxt'])
        tHeader.unshift('图片')
        filterVal.unshift('imageUrl') */


        column.unshift({
          title: "商品图片",
          key: 'imageUrl',
          type: 'image',
          width: 100,
          height: 100
        });
        var nextTab = [{
          title: "销售价格",
          key: 'priceTxt',
          type: 'text'
        }, {
          title: "库存",
          key: 'specsTxt',
          type: 'text'
        }, {
          title: "状态",
          key: 'onShelTxt',
          type: 'text'
        }];
        var dowColumn = [].concat((0, _toConsumableArray2.default)(column), nextTab);
        var listData = [];
        var data = JSON.parse(JSON.stringify(_this4.obj));
        data.pageSize = _this4.total;
        data.pageNo = 1;

        _this4.openLoading();

        (0, _api.list)(data).then(function (res) {
          listData = res.data.items;
          listData.forEach(function (item) {
            if (item.memberType) {
              var memberType = JSON.parse(item.memberType);
              var memberTypeText = memberType.map(function (item) {
                if (item === 0) {
                  return '所有会员';
                } else if (item === 1) {
                  return '普通会员';
                } else if (item === 2) {
                  return '365会员';
                } else {
                  return _this4.lavantList[item] || '';
                }
              });
              item.memberTypeText = memberTypeText.join('、');
            } else {
              item.memberTypeText = '';
            }

            item.productCode = "编码：" + item.productCode;
            item.imageUrl = _this4.imgSrc + item.imageUrl;
            item.priceTxt = item.salePrice + '元+' + item.starBean + '积分';
            item.onShelTxt = item.onShel == 1 ? '上架' : '下架';
            var str = '';

            if (item.useSpec == 1) {
              item.specs.forEach(function (row) {
                str += '规格：' + row.specificationName + '库存：' + row.stock + '已售：' + row.saleStock + '\n';
              });
            } else {
              item.specs.forEach(function (row) {
                str += '库存：' + row.stock + '已售：' + row.saleStock + '\n';
              });
            }

            item.specsTxt = str;
          });
          (0, _jsTable2excel.default)(dowColumn, listData, '商品列表');
          _this4.downloadLoading = false;
          return;

          var data = _this4.formatJson(filterVal, listData);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '商品列表'
          });
          _this4.downloadLoading = false;

          _this4.openLoading().close();
        }).catch(function (res) {
          _this4.downloadLoading = false;

          _this4.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    getTreeData: function getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].childInstitutions.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].childInstitutions = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].childInstitutions);
        }
      }

      return data;
    },
    cateChange: function cateChange(e) {
      var _this5 = this;

      this.obj.categoryIds = [];
      e.forEach(function (item) {
        _this5.obj.categoryIds.push(item[item.length - 1]);
      });
      return;
      this.obj.categoryIds = e[e.length - 1];
    },
    upDown: function upDown(row, onShel) {
      var _this6 = this;

      var str = '';
      var data = {
        ids: [row.id],
        onShel: onShel
      };

      if (onShel == 0) {
        str = '此操作将下架商品, 是否继续?';
      } else if (onShel == 1) {
        str = '此操作将上架商品, 是否继续?';
      } else if (onShel == 2) {
        str = '此操作将删除商品, 是否继续?';
      }

      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _api.goodsUpdown)(data).then(function (res) {
          _this6.init();
          /* if (data.onShel === 1) {
           } */


          _this6.$message({
            type: 'success',
            message: '修改成功!'
          });
        });
      }).catch(function () {});
    },
    init: function init(type) {
      var _this7 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
      }

      var requestData = (0, _objectSpread2.default)({}, this.obj);
      (0, _api.list)(requestData).then(function (res) {
        _this7.total = res.data.totalNum;
        res.data.items.forEach(function (item) {
          if (item.memberType) {
            var memberType = JSON.parse(item.memberType);
            var memberTypeText = memberType.map(function (item) {
              if (item === 0) {
                return '所有会员';
              } else if (item === 1) {
                return '普通会员';
              } else if (item === 2) {
                return '365会员';
              } else {
                return _this7.lavantList[item];
              }
            });
            item.memberTypeText = memberTypeText.join('、');
          }
        });
        _this7.dataList = res.data.items;
      });
    },
    goAdd: function goAdd(url) {
      this.$router.push(url);
    }
  }
};
exports.default = _default;