var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bgView" }, [
    _c(
      "div",
      {
        class: ["json-view", _vm.length ? "closeable" : ""],
        style: "font-size:" + _vm.fontSize + "px"
      },
      [
        _vm.length
          ? _c("span", {
              class: ["angle", _vm.innerclosed ? "closed" : ""],
              on: { click: _vm.toggleClose }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "content-wrap" }, [
          _c("p", { staticClass: "first-line" }, [
            _vm.jsonKey
              ? _c("span", { staticClass: "json-key" }, [
                  _vm._v('"' + _vm._s(_vm.jsonKey) + '": ')
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.length
              ? _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.prefix) +
                      "\n          " +
                      _vm._s(_vm.innerclosed ? "..." + _vm.subfix : "") +
                      "\n          "
                  ),
                  _c("span", { staticClass: "json-note" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.innerclosed ? " // count: " + _vm.length : ""
                        ) +
                        "\n          "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.length
              ? _c("span", [_vm._v(_vm._s(_vm.isArray ? "[]" : "{}"))])
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.innerclosed && _vm.length
            ? _c(
                "div",
                { staticClass: "json-body" },
                [
                  _vm._l(_vm.items, function(item, index) {
                    return [
                      item.isJSON
                        ? _c("json-view", {
                            key: index,
                            attrs: {
                              closed: _vm.closed,
                              json: item.value,
                              "json-key": item.key,
                              "is-last": index === _vm.items.length - 1
                            }
                          })
                        : _c("p", { key: index, staticClass: "json-item" }, [
                            _c("span", { staticClass: "json-key" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.isArray ? index : '"' + item.key + '"'
                                  ) +
                                  "\n            "
                              )
                            ]),
                            _vm._v("\n            :\n            "),
                            _c("span", { staticClass: "json-value" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    item.value +
                                      (index === _vm.items.length - 1
                                        ? ""
                                        : ",")
                                  ) +
                                  "\n            "
                              )
                            ])
                          ])
                    ]
                  }),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.innerclosed,
                        expression: "!innerclosed"
                      }
                    ],
                    staticClass: "body-line"
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.innerclosed && _vm.length
            ? _c("p", { staticClass: "last-line" }, [
                _c("span", [_vm._v(_vm._s(_vm.subfix))])
              ])
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }