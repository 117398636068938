"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default
  },
  data: function data() {
    return {
      couponShow: false,
      rules: {
        'giftCode': [{
          required: true,
          message: '请选择券'
        }],
        'status': [{
          required: true,
          message: '请选择状态'
        }]
      },
      ruleForm: {
        giftIamge: null,
        giftCode: null,
        giftName: null,
        status: null,
        id: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id) {
      (0, _api.getData)({
        id: this.ruleForm.id
      }).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    setCoupon: function setCoupon(e) {
      this.ruleForm.giftIamge = e.mainImage;
      this.ruleForm.giftCode = e.uuid;
      this.ruleForm.giftName = e.name;
      this.couponShow = false;
    },
    getAward: function getAward(e, index) {
      this.$refs.coupon1.getList('coupon', this.ruleForm.giftCode);
      this.couponShow = true;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.addData)(_this2.ruleForm).then(function (res) {
            _this2.$router.go(-1);

            _this2.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;