var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "promotion" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { size: "small", "label-width": "150px" }
        },
        [
          _c(
            "el-form-item",
            { ref: "name", attrs: { label: "活动名称：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { maxlength: "16" },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "time", attrs: { label: "活动时间：", prop: "time" } },
            [
              _c("el-date-picker", {
                staticClass: "form_input",
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetime",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "state", attrs: { label: "活动地点：", prop: "state" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { maxlength: "16" },
                model: {
                  value: _vm.state,
                  callback: function($$v) {
                    _vm.state = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "state"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "state", attrs: { label: "BANNER图：", prop: "state" } },
            [
              _c("add-load", {
                attrs: { width: 375, height: 150, cover: _vm.cover },
                on: { setCover: _vm.setCover }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "promotion_input_li_txt" }, [
                _vm._v("图片尺寸：750px * 300px")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "content",
              attrs: { label: "活动内容详情：", prop: "content" }
            },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 600, height: 300 },
                on: { onChange: _vm.change },
                model: {
                  value: _vm.content,
                  callback: function($$v) {
                    _vm.content = $$v
                  },
                  expression: "content"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "content",
              attrs: { label: "报名信息字段：", prop: "content" }
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addList } },
                [_vm._v("添加字段")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                { attrs: { data: _vm.list, border: "", size: "mini" } },
                [
                  _c("el-table-column", {
                    attrs: { label: "名称", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              model: {
                                value: scope.row.name,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "name", $$v)
                                },
                                expression: "scope.row.name"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "数据类型", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticClass: "input1",
                                attrs: { placeholder: "数据类型" },
                                model: {
                                  value: scope.row.type,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "type", $$v)
                                  },
                                  expression: "scope.row.type"
                                }
                              },
                              _vm._l(_vm.options1, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "备选项", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-checkbox",
                              {
                                model: {
                                  value: scope.row.checkbox,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "checkbox", $$v)
                                  },
                                  expression: "scope.row.checkbox"
                                }
                              },
                              [_vm._v("备选项")]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "项目", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._l(scope.row.project, function(item2, index2) {
                              return _c(
                                "el-form-item",
                                { key: index2, attrs: { "label-width": "0" } },
                                [
                                  _c("el-input", {
                                    staticClass: "input2",
                                    model: {
                                      value: item2.txt,
                                      callback: function($$v) {
                                        _vm.$set(item2, "txt", $$v)
                                      },
                                      expression: "item2.txt"
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            scope.row.type === "4" &&
                            scope.row.project.length < 5
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.addProject(scope.$index)
                                      }
                                    }
                                  },
                                  [_vm._v("添加一个项目")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "content",
              attrs: { label: "活动上线时间：", prop: "content" }
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.validTimeType,
                    callback: function($$v) {
                      _vm.validTimeType = $$v
                    },
                    expression: "validTimeType"
                  }
                },
                [_vm._v("选择上线周期")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.validTimeType,
                    callback: function($$v) {
                      _vm.validTimeType = $$v
                    },
                    expression: "validTimeType"
                  }
                },
                [_vm._v("立即上线（需要手动下线）")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "content", attrs: { label: "", prop: "content" } },
            [
              _vm.validTimeType == 1
                ? _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.beginDate,
                      callback: function($$v) {
                        _vm.beginDate = $$v
                      },
                      expression: "beginDate"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.validTimeType == 2
                ? _c("el-input", {
                    staticClass: "form_input",
                    attrs: {
                      onmousewheel: "return false;",
                      type: "number",
                      placeholder: ""
                    },
                    model: {
                      value: _vm.validDay,
                      callback: function($$v) {
                        _vm.validDay = $$v
                      },
                      expression: "validDay"
                    }
                  })
                : _vm._e(),
              _c("span", { staticClass: "li_txt left" }, [_vm._v("天")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "promotion_input" }, [
        _c("ul", [
          _c("li", [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("活动名称：")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name"
                }
              ],
              attrs: { type: "text", maxlength: "16" },
              domProps: { value: _vm.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.name = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "promotion_input_li_tit" }, [
                _vm._v("活动时间：")
              ]),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetime",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("活动地点：")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.state,
                  expression: "state"
                }
              ],
              attrs: { type: "text", maxlength: "16" },
              domProps: { value: _vm.state },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.state = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("BANNER图：")
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("add-load", {
                  attrs: { width: 375, height: 150, cover: _vm.cover },
                  on: { setCover: _vm.setCover }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "promotion_input_li_txt" }, [
                  _vm._v("图片尺寸：750px * 300px")
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c("div", { staticClass: "li_left" }, [
              _c("p", { staticClass: "promotion_input_li_tit" }, [
                _vm._v("活动内容详情：")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "liEditor" },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { width: 600, height: 300 },
                    on: { onChange: _vm.change },
                    model: {
                      value: _vm.content,
                      callback: function($$v) {
                        _vm.content = $$v
                      },
                      expression: "content"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "li_right" })
          ]),
          _vm._v(" "),
          _c("li", [
            _c("div", { staticClass: "li_left" }, [
              _c("p", { staticClass: "promotion_input_li_tit" }, [
                _vm._v("报名信息字段：")
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "liDea" },
                  _vm._l(_vm.list, function(item, index) {
                    return _c("div", { staticClass: "dea_ul" }, [
                      _c(
                        "div",
                        { staticClass: "dea_li dea_li1" },
                        [
                          _c("el-input", {
                            staticClass: "input1",
                            model: {
                              value: item.name,
                              callback: function($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dea_li dea_li2" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input1",
                              attrs: { placeholder: "数据类型" },
                              model: {
                                value: item.type,
                                callback: function($$v) {
                                  _vm.$set(item, "type", $$v)
                                },
                                expression: "item.type"
                              }
                            },
                            _vm._l(_vm.options1, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dea_li dea_li3" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: item.checkbox,
                                callback: function($$v) {
                                  _vm.$set(item, "checkbox", $$v)
                                },
                                expression: "item.checkbox"
                              }
                            },
                            [_vm._v("备选项")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dea_li dea_li4" },
                        [
                          _vm._l(item.project, function(item2, index2) {
                            return _c("el-input", {
                              key: index2,
                              staticClass: "input2",
                              model: {
                                value: item2.txt,
                                callback: function($$v) {
                                  _vm.$set(item2, "txt", $$v)
                                },
                                expression: "item2.txt"
                              }
                            })
                          }),
                          _vm._v(" "),
                          item.type === "4"
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addProject(index)
                                    }
                                  }
                                },
                                [_vm._v("添加一个项目")]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dea_btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.addList }
                      },
                      [_vm._v("添加字段")]
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "li_right" })
          ]),
          _vm._v(" "),
          _c("li", [
            _c("p", { staticClass: "promotion_input_li_tit" }, [
              _vm._v(" 活动上线时间： ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "li_div" }, [
              _c(
                "div",
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.validTimeType,
                        callback: function($$v) {
                          _vm.validTimeType = $$v
                        },
                        expression: "validTimeType"
                      }
                    },
                    [_vm._v("选择上线周期")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      model: {
                        value: _vm.validTimeType,
                        callback: function($$v) {
                          _vm.validTimeType = $$v
                        },
                        expression: "validTimeType"
                      }
                    },
                    [_vm._v("立即上线（需要手动下线）")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.validTimeType === "1",
                      expression: "validTimeType === '1'"
                    }
                  ]
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.beginDate,
                      callback: function($$v) {
                        _vm.beginDate = $$v
                      },
                      expression: "beginDate"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.validTimeType === "2",
                      expression: "validTimeType==='2'"
                    }
                  ],
                  staticClass: "radio_div"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.validDay,
                        expression: "validDay"
                      }
                    ],
                    staticClass: "input2",
                    attrs: {
                      onmousewheel: "return false;",
                      type: "number",
                      placeholder: ""
                    },
                    domProps: { value: _vm.validDay },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.validDay = $event.target.value
                      }
                    }
                  }),
                  _c("span", { staticClass: "li_txt left" }, [_vm._v("天")])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "promotion_input_li_tit" }),
              _vm._v(" "),
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v("下一步")
              ])
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }