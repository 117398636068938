"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    selectPop: _getCoupon.default
  },
  props: {
    ruleForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      slelctPopShow: false,
      selectType: null
    };
  },
  methods: {
    addGoods: function addGoods(type, index) {
      var _this = this;

      this.selectType = type; // this.columnIndex = index

      this.slelctPopShow = true;
      this.$nextTick(function () {
        _this.$refs.selectRef.init(); // this.$refs.selectRef.assignCoupon(temItem.dataItem)

      });
    },
    addTem: function addTem() {
      this.ruleForm.dataItem.push({
        name: null,
        goodsList: [],
        goodsType: null
      }); // this.$emit('addColumnGoods', this.key)
    },
    setCoupons: function setCoupons(e) {
      var _this2 = this;

      console.log(e);
      this.slelctPopShow = false;
      this.ruleForm.goodsType = this.selectType;
      this.ruleForm.dataItem[this.columnIndex].goodsList = e;

      if (this.selectType === 'legouGoods') {
        this.ruleForm.dataItem[this.columnIndex].goodsList = e.map(function (item) {
          return {
            pic_url: item.pic_url,
            name: item.name,
            price: item.price,
            original_price: item.original_price,
            discount_rate: item.discount_rate,
            pageUrl: "/legou/pages/goods/goods?id=".concat(item.id),
            user_mult_limit: item.user_mult_limit
          };
        });
      }

      if (this.selectType === 'goods') {
        this.ruleForm.dataItem[this.columnIndex].goodsList = e.map(function (item) {
          return {
            pageUrl: "/legou/pages/goods/goods?id=".concat(item.productCode),
            name: item.productName,
            salePrice: item.salePrice,
            starBean: item.starBean
          };
        });
      }

      if (this.selectType === 'coupon') {
        this.ruleForm.dataItem[this.columnIndex].goodsList = e.map(function (item) {
          return {
            pageUrl: "/pages/coupons/couponDea/couponDea?id=".concat(item.uuid),
            name: item.name,
            salePrice: item.salePrice,
            starBean: item.starBean
          };
        });
      }

      return;
      e.forEach(function (item) {
        if (_this2.couponType === 'legouGoods') {
          temItem.dataItem.push({
            linkId: item.id,
            pic_url: item.pic_url,
            name: item.name,
            price: item.price,
            original_price: item.original_price,
            discount_rate: item.discount_rate,
            linkCode: 'legouGoods',
            user_mult_limit: item.user_mult_limit
          });
        }

        if (_this2.couponType === 'legouPtGoods') {
          temItem.dataItem.push({
            linkId: item.id,
            pic_url: item.cover_pic,
            name: item.name,
            price: item.price,
            original_price: item.original_price,
            discount_rate: item.discount_rate,
            linkCode: 'legouPtGoods',
            user_mult_limit: item.user_mult_limit
          });
        }

        if (_this2.couponType === 'coupon') {
          temItem.dataItem.push({
            linkId: item.uuid,
            linkCode: 'coupon'
          });
        }

        if (_this2.couponType === 'card') {
          temItem.dataItem.push({
            linkId: item.uuid,
            linkCode: 'card',
            cardType: item.type
          });
        }

        if (_this2.couponType === 'goods') {
          temItem.dataItem.push({
            linkId: item.productCode,
            linkCode: 'goods',
            productSource: item.productSource
          });
        }

        if (_this2.couponType === 'vipCity') {
          temItem.dataItem.push({
            linkId: item.productCode,
            linkCode: 'vipCity',
            citylifeId: item.id
          });
        }

        if (_this2.couponType === 'vipSubsidy') {
          temItem.dataItem.push({
            linkId: item.productCode,
            linkCode: 'vipSubsidy',
            citylifeId: item.id
          });
        }
      });
    },
    closeCouponPop: function closeCouponPop() {
      this.slelctPopShow = false;
    }
  }
};
exports.default = _default2;