var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(
          " 新增活动券—— " +
            _vm._s(
              _vm.ruleForm.messageParam.type == 1 ? "满减活动" : "多件多折"
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              ref: "messageParam.activityType",
              attrs: { label: "定向人群：", prop: "messageParam.activityType" }
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changActivityType },
                  model: {
                    value: _vm.ruleForm.messageParam.activityType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm.messageParam, "activityType", $$v)
                    },
                    expression: "ruleForm.messageParam.activityType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("活动券B")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("活动券C")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "messageParam.verificationModelList",
              attrs: {
                label: "核销方式：",
                prop: "messageParam.verificationModelList"
              }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.messageParam.verificationModelList,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm.messageParam,
                        "verificationModelList",
                        $$v
                      )
                    },
                    expression: "ruleForm.messageParam.verificationModelList"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: 1 } }, [
                    _vm._v("线下门店（蜻蜓收银机）")
                  ]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: 2 } }, [
                    _vm._v("会员商城（乐购商品）")
                  ]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: 3 } }, [
                    _vm._v("小程序直播间（乐购直播间商品）")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("活动券可以在选中的核销方式中使用")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "messageParam.name",
              attrs: { label: "活动名称：", prop: "messageParam.name" }
            },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "活动名称"
                },
                model: {
                  value: _vm.ruleForm.messageParam.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm.messageParam,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.messageParam.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "messageParam.activityMonth",
              attrs: { label: "活动周期：", prop: "messageParam.activityMonth" }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM",
                  type: "month",
                  placeholder: "选择活动周期"
                },
                on: { change: _vm.startChange },
                model: {
                  value: _vm.ruleForm.messageParam.activityMonth,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm.messageParam, "activityMonth", $$v)
                  },
                  expression: "ruleForm.messageParam.activityMonth"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form-item",
                {
                  ref: "messageParam.startTime",
                  attrs: { label: "活动时间：", prop: "messageParam.startTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      disabled: !_vm.ruleForm.messageParam.activityMonth,
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择开始时间",
                      "picker-options": _vm.pickerTime0
                    },
                    on: { change: _vm.changeStart },
                    model: {
                      value: _vm.ruleForm.messageParam.startTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm.messageParam, "startTime", $$v)
                      },
                      expression: "ruleForm.messageParam.startTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "messageParam.endTime",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { "label-width": "0", prop: "messageParam.endTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      disabled: !_vm.ruleForm.messageParam.startTime,
                      "picker-options": _vm.pickerTime,
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": "23:59:59",
                      placeholder: "选择结束时间"
                    },
                    model: {
                      value: _vm.ruleForm.messageParam.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm.messageParam, "endTime", $$v)
                      },
                      expression: "ruleForm.messageParam.endTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "messageParam.costContributor",
              attrs: {
                label: "费用出资方：",
                prop: "messageParam.costContributor"
              }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: {
                    disabled: _vm.ruleForm.messageParam.activityType === 2
                  },
                  on: { change: _vm.changCost },
                  model: {
                    value: _vm.ruleForm.messageParam.costContributor,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm.messageParam,
                        "costContributor",
                        $$v
                      )
                    },
                    expression: "ruleForm.messageParam.costContributor"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: 1 } }, [_vm._v("集团")]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: 2 } }, [_vm._v("门店")]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: 3 } }, [
                    _vm._v("专柜/品牌/商户")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            _vm._l(_vm.ruleForm.contributionParamList, function(item, index) {
              return _c(
                "el-form-item",
                {
                  key: index,
                  ref: "contributionParamList." + index + ".ratio",
                  refInFor: true,
                  attrs: {
                    prop: "contributionParamList." + index + ".ratio",
                    required: "",
                    rules: [{ required: true, message: "请输入百分比" }],
                    label: _vm.costList[item.costContributor] + "："
                  }
                },
                [
                  _c("el-input-number", {
                    staticStyle: { "max-width": "150px" },
                    attrs: {
                      disabled: _vm.ruleForm.messageParam.activityType === 2,
                      min: 0,
                      type: "number",
                      controls: false
                    },
                    model: {
                      value: item.ratio,
                      callback: function($$v) {
                        _vm.$set(
                          item,
                          "ratio",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "item.ratio"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "form_remark" }, [_vm._v("%")])
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.messageParam.costContributor.length > 0
            ? _c("el-form-item", { attrs: { prop: "ratioAll" } })
            : _vm._e(),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "view_title flex_between" }, [
            _vm._v(
              " 提示：同一个商品码，在同一个时间内，每针对个会员类型，相同的活动策略只能设置一个优惠； "
            )
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "messageParam.limitNumber",
              attrs: {
                label: "单用户限参与：",
                prop: "messageParam.limitNumber"
              }
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: {
                    type: "number",
                    placeholder: "请输入参与次数",
                    disabled: _vm.ruleForm.messageParam.limitType < 1
                  },
                  model: {
                    value: _vm.ruleForm.messageParam.limitNumber,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm.messageParam,
                        "limitNumber",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.messageParam.limitNumber"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { slot: "prepend", placeholder: "请选择" },
                      slot: "prepend",
                      model: {
                        value: _vm.ruleForm.messageParam.limitType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm.messageParam, "limitType", $$v)
                        },
                        expression: "ruleForm.messageParam.limitType"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "每日参与", value: 1 }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "总共参与", value: 2 }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("template", { slot: "append" }, [_vm._v("次")])
                ],
                2
              ),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v("每个用户限制参与的次数（不限次数请填0）")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "messageParam.targetUser",
              attrs: { label: "定向人群：", prop: "messageParam.targetUser" }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.messageParam.targetUser,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm.messageParam, "targetUser", $$v)
                    },
                    expression: "ruleForm.messageParam.targetUser"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("全部用户(无须登录会员)")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("MIKA会员")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("普通会员")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 4 } }, [_vm._v("365会员")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 5 } }, [
                    _vm._v("Lavant会员")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _vm.ruleForm.messageParam.type == 2
            ? _c(
                "el-form-item",
                {
                  ref: "ruleType",
                  attrs: { label: "折扣规则：", prop: "ruleType" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.ruleTypeChange },
                      model: {
                        value: _vm.ruleForm.ruleType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "ruleType", $$v)
                        },
                        expression: "ruleForm.ruleType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("多件多折按订单")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("多件多折按数量")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "ruleParamList",
              attrs: { label: "优惠层级：", prop: "ruleParamList" }
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.addRule }
                },
                [_vm._v("添加层级")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "success" },
                  on: { click: _vm.setSort }
                },
                [_vm._v("等级排序")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.ruleForm.ruleParamList, function(item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: " 优惠层级——档位" + (index + 1) } },
                  [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确认删除该层级？" },
                        on: {
                          confirm: function($event) {
                            return _vm.delRule(index)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "150px" },
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "danger"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.ruleForm.ruleType == 1
                  ? _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "align-items": "center" }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            ref: "ruleParamList." + index + ".orderPrice",
                            refInFor: true,
                            attrs: {
                              prop: "ruleParamList." + index + ".orderPrice",
                              required: "",
                              rules: [
                                { required: true, message: "请输入金额" }
                              ],
                              label: "定案金额满："
                            }
                          },
                          [
                            _c(
                              "el-input-number",
                              {
                                staticStyle: { "max-width": "150px" },
                                attrs: {
                                  min: 1,
                                  "controls-position": "right",
                                  type: "number",
                                  controls: false
                                },
                                model: {
                                  value: item.orderPrice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item,
                                      "orderPrice",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.orderPrice"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("元")
                                ])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "form_remark" }, [
                              _vm._v("元")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { padding: "0 10px" },
                            attrs: { label: "", "label-width": "0" }
                          },
                          [_vm._v(" 减 ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            ref: "ruleParamList." + index + ".subPrice",
                            refInFor: true,
                            attrs: {
                              label: "",
                              "label-width": "0",
                              prop: "ruleParamList." + index + ".subPrice",
                              required: "",
                              rules: [{ required: true, message: "请输入金额" }]
                            }
                          },
                          [
                            _c(
                              "el-input-number",
                              {
                                staticStyle: { "max-width": "200px" },
                                attrs: {
                                  controls: false,
                                  "controls-position": "right",
                                  min: 0.1,
                                  max: Number(item.orderPrice) - 0.1
                                },
                                model: {
                                  value: item.subPrice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item,
                                      "subPrice",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.subPrice"
                                }
                              },
                              [
                                _vm._v("> "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("元")
                                ])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "form_remark" }, [
                              _vm._v("元")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.ruleForm.ruleParamList.length === 1
                          ? _c(
                              "el-form-item",
                              {
                                ref: "ruleParamList." + index + ".isCeiling",
                                refInFor: true,
                                attrs: {
                                  prop: "ruleParamList." + index + ".isCeiling",
                                  required: "",
                                  rules: [
                                    { required: true, message: "请选择" }
                                  ],
                                  label: "附加条件："
                                }
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      "false-label": 0,
                                      "true-label": 1
                                    },
                                    model: {
                                      value: item.isCeiling,
                                      callback: function($$v) {
                                        _vm.$set(item, "isCeiling", $$v)
                                      },
                                      expression: "item.isCeiling"
                                    }
                                  },
                                  [_vm._v("上不封顶")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.ruleForm.ruleType == 2
                  ? _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "align-items": "center" }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            ref: "ruleParamList." + index + ".orderPrice",
                            refInFor: true,
                            attrs: {
                              prop: "ruleParamList." + index + ".orderPrice",
                              required: "",
                              rules: [
                                { required: true, message: "请输入金额" }
                              ],
                              label: "单笔订单金额满："
                            }
                          },
                          [
                            _c(
                              "el-input-number",
                              {
                                staticStyle: { "max-width": "150px" },
                                attrs: {
                                  "controls-position": "right",
                                  min: 1,
                                  controls: false,
                                  placeholder: "不限制输入0",
                                  type: "number"
                                },
                                model: {
                                  value: item.orderPrice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item,
                                      "orderPrice",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.orderPrice"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("元")
                                ])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "form_remark" }, [
                              _vm._v("元")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { padding: "0 10px" },
                            attrs: { label: "", "label-width": "0" }
                          },
                          [_vm._v(" 享 ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            ref: "ruleParamList." + index + ".subPrice",
                            refInFor: true,
                            attrs: {
                              label: "",
                              "label-width": "0",
                              prop: "ruleParamList." + index + ".subPrice",
                              required: "",
                              rules: [{ required: true, message: "请输入折扣" }]
                            }
                          },
                          [
                            _c(
                              "el-input-number",
                              {
                                staticStyle: { "max-width": "250px" },
                                attrs: {
                                  "controls-position": "right",
                                  min: 10,
                                  max: 99,
                                  controls: false,
                                  placeholder: "如8.5折请填写85",
                                  type: "number"
                                },
                                model: {
                                  value: item.subPrice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item,
                                      "subPrice",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.subPrice"
                                }
                              },
                              [_vm._v(">\n          ")]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "form_remark" }, [
                              _vm._v("折(如8.5折请填写85)")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            ref: "ruleParamList." + index + ".maxDiscountPrice",
                            refInFor: true,
                            attrs: {
                              prop:
                                "ruleParamList." + index + ".maxDiscountPrice",
                              required: "",
                              rules: [
                                { required: true, message: "请输入金额" }
                              ],
                              label: "最多优惠："
                            }
                          },
                          [
                            _c(
                              "el-input-number",
                              {
                                attrs: {
                                  min: 0,
                                  controls: false,
                                  "controls-position": "right",
                                  placeholder: "不限制输入0",
                                  type: "number"
                                },
                                model: {
                                  value: item.maxDiscountPrice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item,
                                      "maxDiscountPrice",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.maxDiscountPrice"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("元")
                                ])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "form_remark" }, [
                              _vm._v("元")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.ruleForm.ruleType == 3
                  ? _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "align-items": "center" }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            ref: "ruleParamList." + index + ".orderPrice",
                            refInFor: true,
                            attrs: {
                              prop: "ruleParamList." + index + ".orderPrice",
                              required: "",
                              rules: [
                                { required: true, message: "请输入数量" }
                              ],
                              label: "商品数量满："
                            }
                          },
                          [
                            _c(
                              "el-input-number",
                              {
                                staticStyle: { "max-width": "150px" },
                                attrs: {
                                  "controls-position": "right",
                                  min: 1,
                                  controls: false,
                                  type: "number"
                                },
                                model: {
                                  value: item.orderPrice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item,
                                      "orderPrice",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.orderPrice"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("件")
                                ])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "form_remark" }, [
                              _vm._v("件")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { padding: "0 10px" },
                            attrs: { label: "", "label-width": "0" }
                          },
                          [_vm._v(" 享 ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            ref: "ruleParamList." + index + ".subPrice",
                            refInFor: true,
                            attrs: {
                              label: "",
                              "label-width": "0",
                              prop: "ruleParamList." + index + ".subPrice",
                              required: "",
                              rules: [{ required: true, message: "请输入折扣" }]
                            }
                          },
                          [
                            _c(
                              "el-input-number",
                              {
                                staticStyle: { "max-width": "200px" },
                                attrs: {
                                  "controls-position": "right",
                                  min: 10,
                                  controls: false,
                                  placeholder: "如8.5折请填写85",
                                  type: "number"
                                },
                                model: {
                                  value: item.subPrice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item,
                                      "subPrice",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.subPrice"
                                }
                              },
                              [
                                _vm._v(">\n            "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("折")
                                ])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "form_remark" }, [
                              _vm._v("折(如8.5折请填写85)")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            ref: "ruleParamList." + index + ".maxDiscountPrice",
                            refInFor: true,
                            attrs: {
                              prop:
                                "ruleParamList." + index + ".maxDiscountPrice",
                              required: "",
                              rules: [
                                { required: true, message: "请输入金额" }
                              ],
                              label: "最多优惠："
                            }
                          },
                          [
                            _c(
                              "el-input-number",
                              {
                                attrs: {
                                  min: 0,
                                  controls: false,
                                  "controls-position": "right",
                                  placeholder: "不限制输入0",
                                  type: "number"
                                },
                                model: {
                                  value: item.maxDiscountPrice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item,
                                      "maxDiscountPrice",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.maxDiscountPrice"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("元")
                                ])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "form_remark" }, [
                              _vm._v("元")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("el-divider")
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "messageParam.description",
              attrs: { prop: "messageParam.description", label: "规则说明：" }
            },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.ruleForm.messageParam.description,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm.messageParam, "description", $$v)
                  },
                  expression: "ruleForm.messageParam.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }