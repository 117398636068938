"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.sort");

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon");

var _banner = _interopRequireDefault(require("./banner"));

var _action = _interopRequireDefault(require("./action"));

var _recom = _interopRequireDefault(require("./recom"));

var _integral = _interopRequireDefault(require("./integral"));

var _coupon2 = _interopRequireDefault(require("./coupon"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    banner: _banner.default,
    action: _action.default,
    recom: _recom.default,
    integral: _integral.default,
    coupon: _coupon2.default
  },
  data: function data() {
    return {
      column: [],
      groupId: '',
      name: '',
      cover: '',
      parentId: '',
      parentIds: [],
      backName: '',
      sort: '',
      status: '',
      templateCode: '',
      templateCodes: [],
      attrIds: '',
      btnRightName: '',
      btnLinkType: '',
      btnLinkTypes: [{
        value: 1,
        lable: '板块页面'
      }, {
        value: 2,
        lable: '分类页面 '
      }, {
        value: 3,
        lable: '详情页面 '
      }, {
        value: 4,
        lable: '外部页面地址'
      }],
      btnLink: '',
      remark: '',
      id: '',
      imgSrc: this.$store.state.user.imgSrc
    };
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  methods: {
    onChange: function onChange(e) {
      var _this = this;

      this.templateCodes.forEach(function (item) {
        if (item.code === e) {
          _this.cover = _this.$store.state.user.imgSrc + item.imgUrl;
        }
      });
    },
    next: function next() {
      var _this2 = this;

      // if(this.backName.trim()==='') {
      // 	this.$message.error('请填写列表名称');
      // 	return
      // } else
      if (this.templateCode === '') {
        this.$message.error('请选择栏目项显示模板');
        return;
      } else if (this.sort === '') {
        this.$message.error('请填写排序号');
        return;
      } else if (this.name.trim() === '') {
        this.$message.error('请填写栏目项名称');
        return;
      } else if (this.parentId === '') {
        this.$message.error('请选择所属页面');
        return;
      } else if (this.status === '') {
        this.$message.error('请选择栏目项状态');
        return;
      } else if (this.attrIds.trim() === '') {
        this.$message.error('请填写列表数据项');
        return;
      } else if (this.groupId === '') {
        this.$message.error('请选择栏目组');
        return;
      } else {
        var attrIdsArr = this.attrIds.split('\n').filter(function (s) {
          return s && s.trim();
        });
        var obj = {
          parentId: this.parentId,
          name: this.name,
          // backName:this.backName,
          sort: Number(this.sort),
          status: Number(this.status),
          templateCode: this.templateCode,
          attrIds: attrIdsArr,
          btnRightName: this.btnRightName,
          btnLinkType: this.btnLinkType,
          btnLink: this.btnLink,
          remark: this.remark,
          groupId: this.groupId
        };
        this.openLoading();

        if (this.id) {
          obj.columnId = this.id;
          console.log(obj);
          (0, _coupon.editColumnItem)(obj).then(function (res) {
            _this2.openLoading().close();

            _this2.$router.back(-1);
          }).catch(function (res) {
            _this2.openLoading().close();
          });
        } else {
          (0, _coupon.addColumnItem)(obj).then(function (res) {
            _this2.openLoading().close();

            _this2.$router.back(-1);
          }).catch(function (res) {
            _this2.openLoading().close();
          });
        }
      }
    },
    init: function init() {
      var _this3 = this;

      this.id = this.$route.query.id;
      this.openLoading();
      (0, _coupon.listColumnGroup)().then(function (res) {
        console.log(res);
        _this3.column = res.data;
      });
      (0, _coupon.listColumnPage)().then(function (res) {
        _this3.parentIds = res.data;

        if (_this3.$route.query.columnId) {
          _this3.parentId = Number(_this3.$route.query.columnId);
        }
      });
      (0, _coupon.listColumnTemplate)().then(function (res) {
        _this3.templateCodes = res.data;

        _this3.onChange(_this3.templateCode);
      });
      /*
         获取栏目项详情
         * */

      if (this.id) {
        // this.openLoading()
        (0, _coupon.getColumnItemById)(this.id).then(function (res) {
          _this3.openLoading().close();

          _this3.name = res.data.name;
          _this3.attrIds = res.data.attrIds.join('\n');
          _this3.parentId = res.data.parentId; // this.backName=res.data.backName

          _this3.sort = res.data.sort;
          _this3.status = String(res.data.status);
          _this3.templateCode = res.data.templateCode;

          _this3.onChange(_this3.templateCode);

          _this3.btnRightName = res.data.btnRightName;
          _this3.btnLinkType = res.data.btnLinkType;
          _this3.btnLink = res.data.btnLink;
          _this3.remark = res.data.remark;
          _this3.groupId = res.data.groupId;
        }).catch(function (res) {
          _this3.openLoading().close();
        });
      } else {
        this.openLoading().close();
      }
      /*
         	获取模板
         * */

    }
  }
};
exports.default = _default;