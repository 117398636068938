"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      total: 100,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [{
        tit: 'ID',
        prop: ''
      }, {
        tit: '储值卡名称',
        prop: ''
      }, {
        tit: '分类',
        prop: ''
      }, {
        tit: '发行时间',
        prop: ''
      }, {
        tit: '类型',
        prop: ''
      }, {
        tit: '发行渠道',
        prop: ''
      }, {
        tit: '状态',
        prop: ''
      }, {
        tit: '操作',
        prop: ''
      }],
      username: '',
      phone: '',
      wxname: '',
      time: '',
      couponId: '',
      name: '',
      issuer: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: '',
      options1: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value1: ''
    };
  },
  watch: {
    time: function time() {
      console.log(this.time);
    }
  },
  methods: {
    getList: function getList() {
      console.log('sdfsdf');
    },
    remove: function remove() {
      this.time = '';
      this.couponId = '';
      this.name = '';
      this.issuer = '';
      this.value1 = '';
      this.value = '';
      this.username = '';
      this.phone = '';
      this.wxname = '';
    }
  }
};
exports.default = _default;