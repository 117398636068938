var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("方案详情")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c("ul", [
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("方案名称：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.orientObj.name))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("方案描述：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.orientObj.descrition))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [_vm._v("优惠券：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dea_box_li_coupon" },
                _vm._l(_vm.orientObj.coupon, function(item, index) {
                  return _c(
                    "p",
                    { key: index, staticClass: "dea_box_li_txt" },
                    [
                      _c("span", { staticClass: "coupon_id" }, [
                        _vm._v(_vm._s(item.uuid))
                      ]),
                      _vm._v(" -" + _vm._s(item.typeName) + "- "),
                      _c("span", { staticClass: "coupon_name" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _vm._v(
                        " -（" + _vm._s(item.validTime) + "）\n              "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("投放时间：")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dea_box_li_txt" }, [
                _vm._v(_vm._s(_vm.orientObj.putTime))
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "dea_box" }, [
      _c("p", { staticClass: "dea_box_tit" }, [_vm._v("目标用户")]),
      _vm._v(" "),
      _c("div", { staticClass: "dea_box_border" }, [
        _c("ul", [
          _c("li", { staticClass: "dea_box_li" }, [
            _c("div", { staticClass: "dea_box_li_div" }, [
              _c("p", { staticClass: "dea_box_li_tit" }, [
                _vm._v("会员标签：")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dea_box_li_user" }, [
                _c(
                  "div",
                  { staticClass: "checkbox" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { indeterminate: _vm.isIndeterminate },
                        on: { change: _vm.handleCheckAllChange },
                        model: {
                          value: _vm.checkAll,
                          callback: function($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll"
                        }
                      },
                      [_vm._v("全选\n                ")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        on: { change: _vm.handleCheckedCitiesChange },
                        model: {
                          value: _vm.checkedCities,
                          callback: function($$v) {
                            _vm.checkedCities = $$v
                          },
                          expression: "checkedCities"
                        }
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "ul_checkbox" },
                          _vm._l(_vm.cities, function(city) {
                            return _c(
                              "li",
                              { key: city.labelId, staticClass: "li_checkbox" },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: city.labelId } },
                                  [_vm._v(_vm._s(city.name))]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.errData instanceof Array
      ? _c(
          "div",
          {
            staticStyle: {
              "max-height": "300px",
              overflow: "auto",
              padding: "10px",
              "border-radius": "10px",
              border: "1px solid red",
              color: "red",
              margin: "20px 0"
            }
          },
          [_vm._v("\n    " + _vm._s(_vm.errData.join("、")) + "\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.next } },
          [_vm._v("确认提交方案")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }