var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    " + _vm._s(_vm.$route.meta.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "current-game" },
        [
          _c("div", { staticClass: "current-game-label" }, [
            _vm._v("游戏名称:")
          ]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择游戏", size: "small" },
              on: { change: _vm.handCurrentGameChange },
              model: {
                value: _vm.currentGameId,
                callback: function($$v) {
                  _vm.currentGameId = $$v
                },
                expression: "currentGameId"
              }
            },
            _vm._l(_vm.gamesOptions, function(game) {
              return _c("el-option", {
                key: game.id,
                attrs: { value: game.id, label: game.gameName }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "rewardForm",
          staticClass: "reward-form",
          attrs: {
            model: _vm.rewardForm,
            "label-width": "160px",
            size: "small"
          }
        },
        [
          _vm._l(_vm.rewardForm.list, function(item, listIndex) {
            return _c(
              "div",
              { key: listIndex, staticClass: "list-item" },
              [
                _c("el-form-item", [
                  _c(
                    "span",
                    {
                      staticClass: "link-text",
                      staticStyle: { "font-size": "15px" }
                    },
                    [_vm._v("奖励方案 " + _vm._s(listIndex + 1))]
                  ),
                  _vm._v(" "),
                  listIndex !== 0
                    ? _c(
                        "div",
                        {
                          staticClass: "delete-button",
                          on: {
                            click: function($event) {
                              return _vm.handRowChange(2, {
                                listIndex: listIndex
                              })
                            }
                          }
                        },
                        [_vm._v("\n          删除方案\n        ")]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "list[" + listIndex + "].score",
                      label: "获得分数:",
                      rules: _vm.rewardRules.score
                    }
                  },
                  [
                    _c("el-input-number", {
                      attrs: {
                        controls: false,
                        min: 1,
                        placeholder: "大于等于"
                      },
                      model: {
                        value: item.score,
                        callback: function($$v) {
                          _vm.$set(item, "score", $$v)
                        },
                        expression: "item.score"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "通过奖励:", rules: _vm.rewardRules.list }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "link-text",
                        on: {
                          click: function($event) {
                            return _vm.handRowChange(3, {
                              listIndex: listIndex
                            })
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v(" 添加规则\n              ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%", "margin-top": "10px" },
                        attrs: {
                          size: "mini",
                          data: item.details,
                          "max-height": "800px",
                          border: "",
                          "header-cell-class-name": "table_header_cell"
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "序号",
                            type: "index",
                            align: "center",
                            width: "50"
                          }
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "奖励人群",
                            align: "center",
                            width: "200"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        ref:
                                          "list[" +
                                          listIndex +
                                          "].details[" +
                                          scope.$index +
                                          "].crowdId",
                                        refInFor: true,
                                        attrs: {
                                          prop:
                                            "list[" +
                                            listIndex +
                                            "].details[" +
                                            scope.$index +
                                            "].crowdId",
                                          rules: _vm.rewardRules.crowdId
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择人群"
                                            },
                                            model: {
                                              value: scope.row.crowdId,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "crowdId",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.crowdId"
                                            }
                                          },
                                          _vm._l(_vm.crowdOptions, function(
                                            crowdItem
                                          ) {
                                            return _c("el-option", {
                                              key: crowdItem.id,
                                              attrs: {
                                                value: crowdItem.id,
                                                label: crowdItem.labelName
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "权重",
                            align: "center",
                            width: "160"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        ref:
                                          "list[" +
                                          listIndex +
                                          "].details[" +
                                          scope.$index +
                                          "].weightNum",
                                        refInFor: true,
                                        attrs: {
                                          prop:
                                            "list[" +
                                            listIndex +
                                            "].details[" +
                                            scope.$index +
                                            "].weightNum",
                                          rules: _vm.rewardRules.weightNum
                                        }
                                      },
                                      [
                                        _c("el-input-number", {
                                          staticStyle: {
                                            "text-align": "center"
                                          },
                                          attrs: {
                                            controls: false,
                                            min: 1,
                                            max: 10
                                          },
                                          model: {
                                            value: scope.row.weightNum,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "weightNum",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.weightNum"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "奖励类型",
                            align: "center",
                            width: "160"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        ref:
                                          "list[" +
                                          listIndex +
                                          "].details[" +
                                          scope.$index +
                                          "].rewardType",
                                        refInFor: true,
                                        attrs: {
                                          prop:
                                            "list[" +
                                            listIndex +
                                            "].details[" +
                                            scope.$index +
                                            "].rewardType",
                                          rules: _vm.rewardRules.rewardType
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "选择奖励类型"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.changeRewardType(
                                                  $event,
                                                  listIndex,
                                                  scope.$index
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.rewardType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "rewardType",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.rewardType"
                                            }
                                          },
                                          _vm._l(
                                            _vm.rewardTypeOptions,
                                            function(rewardT) {
                                              return _c("el-option", {
                                                key: rewardT.value,
                                                attrs: {
                                                  value: rewardT.value,
                                                  label: rewardT.label
                                                }
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "奖励",
                            align: "center",
                            width: "260"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        ref:
                                          "list[" +
                                          listIndex +
                                          "].details[" +
                                          scope.$index +
                                          "].typeValue",
                                        refInFor: true,
                                        attrs: {
                                          prop:
                                            "list[" +
                                            listIndex +
                                            "].details[" +
                                            scope.$index +
                                            "].typeValue",
                                          rules: _vm.rewardRules.typeValue
                                        }
                                      },
                                      [
                                        scope.row.rewardType == 2
                                          ? _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  placeholder:
                                                    "请输入优惠券包编码"
                                                },
                                                model: {
                                                  value: scope.row.typeValue,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "typeValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.typeValue"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "link-text",
                                                    staticStyle: {
                                                      padding: "0 10px"
                                                    },
                                                    attrs: { slot: "suffix" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handSelectCoupon(
                                                          {
                                                            value:
                                                              scope.row
                                                                .typeValue,
                                                            listIndex: listIndex,
                                                            detailIndex:
                                                              scope.$index
                                                          }
                                                        )
                                                      }
                                                    },
                                                    slot: "suffix"
                                                  },
                                                  [_vm._v("选择")]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.rewardType == 1
                                          ? _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  placeholder: "请输入会员积分"
                                                },
                                                model: {
                                                  value: scope.row.typeValue,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "typeValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.typeValue"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      padding: "0 10px"
                                                    },
                                                    attrs: { slot: "suffix" },
                                                    slot: "suffix"
                                                  },
                                                  [_vm._v("分")]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "奖励图片",
                            align: "center",
                            width: "120"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        ref:
                                          "list[" +
                                          listIndex +
                                          "].details[" +
                                          scope.$index +
                                          "].rewardPic",
                                        refInFor: true,
                                        attrs: {
                                          prop:
                                            "list[" +
                                            listIndex +
                                            "].details[" +
                                            scope.$index +
                                            "].rewardPic",
                                          rules: _vm.rewardRules.rewardPic
                                        }
                                      },
                                      [
                                        _c("add-load", {
                                          staticClass: "img_list",
                                          attrs: {
                                            width: 100,
                                            height: 100,
                                            cover: scope.row.rewardPic
                                          },
                                          on: {
                                            setCover: function($event) {
                                              return _vm.updateValue(
                                                $event,
                                                listIndex,
                                                scope.$index
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "奖励名称", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        ref:
                                          "list[" +
                                          listIndex +
                                          "].details[" +
                                          scope.$index +
                                          "].rewardTypeName",
                                        refInFor: true,
                                        attrs: {
                                          prop:
                                            "list[" +
                                            listIndex +
                                            "].details[" +
                                            scope.$index +
                                            "].rewardTypeName",
                                          rules: _vm.rewardRules.rewardTypeName
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "奖励名称" },
                                          model: {
                                            value: scope.row.rewardTypeName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "rewardTypeName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.rewardTypeName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "奖励描述", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        ref:
                                          "list[" +
                                          listIndex +
                                          "].details[" +
                                          scope.$index +
                                          "].rewardDescribe",
                                        refInFor: true,
                                        attrs: {
                                          prop:
                                            "list[" +
                                            listIndex +
                                            "].details[" +
                                            scope.$index +
                                            "].rewardDescribe",
                                          rules: _vm.rewardRules.rewardDescribe
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "奖励描述" },
                                          model: {
                                            value: scope.row.rewardDescribe,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "rewardDescribe",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.rewardDescribe"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            fixed: "right",
                            width: "80"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: { title: "确认删除此奖励？" },
                                        on: {
                                          confirm: function($event) {
                                            return _vm.handRowChange(4, {
                                              listIndex: listIndex,
                                              detailIndex: scope.$index
                                            })
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "danger",
                                            attrs: {
                                              slot: "reference",
                                              type: "text",
                                              size: "mini"
                                            },
                                            slot: "reference"
                                          },
                                          [
                                            _vm._v(
                                              "\n                删除\n              "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "list[" + listIndex + "].limitQuantity",
                      label: "游戏周期内最多可获次数:",
                      rules: _vm.rewardRules.limitQuantity
                    }
                  },
                  [
                    _c("el-input-number", {
                      attrs: {
                        placeholder: "请输入游戏周期内可获次数",
                        controls: false,
                        min: 1
                      },
                      model: {
                        value: item.limitQuantity,
                        callback: function($$v) {
                          _vm.$set(item, "limitQuantity", $$v)
                        },
                        expression: "item.limitQuantity"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("同一个用户多次通关最多可领取的奖励次数限制")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: { plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.handRowChange(1)
                    }
                  }
                },
                [_vm._v("添加奖励方案")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handConfirm }
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  on: { click: _vm.handCancel }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("select-award", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showSelectAward,
            expression: "showSelectAward"
          }
        ],
        ref: "selectCouponBag",
        attrs: {
          type: "couponBag",
          "goods-type": "activity",
          "coupon-show": _vm.showSelectAward
        },
        on: {
          "update:couponShow": function($event) {
            _vm.showSelectAward = $event
          },
          "update:coupon-show": function($event) {
            _vm.showSelectAward = $event
          },
          setAwardItem: _vm.handSelectCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }