"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/importDevice.xlsx"),
      formData: [{
        slot: 'deviceList'
      }],
      ruleForm: {
        deviceList: []
      },
      rules: {
        deviceSn: [{
          required: true,
          message: '设备编码'
        }],
        deviceName: [{
          required: true,
          message: '设备名称'
        }],
        status: [{
          required: true,
          message: '状态'
        }],
        storeId: [{
          required: true,
          message: '门店编码'
        }],
        contractNo: [{
          required: true,
          message: '合同号'
        }],
        longitude: [{
          required: true,
          message: '经度'
        }],
        latitude: [{
          required: true,
          message: '纬度'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id > 0) {
      this.apiGet('api/org/device//getDeviceById/' + this.ruleForm.id).then(function (res) {
        delete res.data.createTime;
        _this.ruleForm.deviceList = [res.data];
      });
    }
  },
  methods: {
    delGrade: function delGrade(row, index) {
      this.ruleForm.deviceList.splice(index, 1);
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      results.forEach(function (data) {
        arr.push({
          deviceSn: data.deviceSn,
          deviceName: data.deviceName,
          status: data.status == 1 ? 1 : 0,
          contractNo: data.contractNo,
          latitude: data.latitude,
          longitude: data.longitude,
          storeId: data.storeId
        });
      });
      this.ruleForm.deviceList = arr;
    },
    goAddGrade: function goAddGrade() {
      this.ruleForm.deviceList.push({
        deviceSn: null,
        deviceName: null,
        status: 1,
        storeId: null,
        longitude: null,
        latitude: null,
        contractNo: null
      });
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      if (this.ruleForm.deviceList.length < 1) {
        this.$message.error('请添加设备');
        return;
      }

      this.apiPost('/api/org/device/saveOrUpdateBatchDevice', this.ruleForm.deviceList).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;