"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

var _default = {
  data: function data() {
    return {
      id: ''
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    (0, _api.getLog)(this.id);
  }
};
exports.default = _default;