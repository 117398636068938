"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      showDay: [4, 5, 7, 10, 13],
      showSendTime: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      eventData: {
        1: '用户注册',
        2: '会员生日当天',
        3: '会员生日当月',
        4: '365会员有效期剩余x天 ',
        5: '365会员过期后x天',
        6: '成功加入lavant会员',
        7: 'lavant有效期剩余X天',
        8: 'lavant会员升级',
        9: 'lavant会员降级',
        10: 'lavant会员过期后x天',
        11: '充值购物卡',
        12: '模板页面',
        13: '优惠券即将失效',
        14: '服务/场地/活动预定成功',
        15: '服务/场地/活动预定取消 ',
        16: '要客评价荔星伙伴',
        17: '顾客单笔消费满'
      },
      columns: [{
        label: '任务主题',
        prop: 'taskTheme',
        width: 80
      }, {
        slot: 'contextType',
        label: '信息内容(1，短信模板 2，企微消息模板 3，彩信模板 )',
        prop: 'contextType',
        width: 80
      }, {
        slot: 'examStatus',
        label: '审核状态（1，待审核 2，审核通过 3，审核不通过）',
        prop: 'examStatus',
        width: 80
      }, {
        slot: 'executeStatus',
        label: '执行状态（1，待执行 2，执行中 3，已结束）',
        prop: 'executeStatus',
        width: 80
      }, {
        slot: 'executionMethod',
        label: '执行方式（1，审核完立即发送 2，指定时间 3，事件触发）',
        prop: 'executionMethod',
        width: 80
      }, {
        label: '发送人群标签',
        prop: 'labelContentName',
        width: 80
      }, {
        slot: 'templateId',
        label: '关联模板',
        prop: 'templateId',
        width: 80
      }, {
        slot: 'event',
        label: '触发事件',
        prop: 'event',
        width: 80
      }, {
        slot: 'sendTime',
        label: '时间限制',
        prop: 'sendTime',
        width: 80
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '任务主题',
        value: 'taskTheme'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '信息内容',
        value: 'contextType',
        select: [{
          label: '短信模板',
          value: 1
        }, {
          label: '企微消息模板',
          value: 2
        }, {
          label: '彩信模板',
          value: 3
        }, {
          label: '自定义',
          value: 4
        }]
      }, {
        type: 'radio',
        span: 4,
        placeholder: '执行方式',
        value: 'executionMethod',
        select: [{
          label: '审核完立即发送',
          value: 1
        }, {
          label: '指定时间',
          value: 2
        }, {
          label: '事件触发',
          value: 3
        }]
      }, {
        type: 'radio',
        span: 4,
        placeholder: '执行状态',
        value: 'executeStatus',
        select: [{
          label: '待执行',
          value: 1
        }, {
          label: '执行中',
          value: 2
        }, {
          label: '已结束',
          value: 3
        }]
      }, {
        type: 'radio',
        span: 4,
        placeholder: '审核状态',
        value: 'examStatus',
        select: [{
          label: '待审核',
          value: 1
        }, {
          label: '审核通过',
          value: 2
        }, {
          label: '审核不通过',
          value: 3
        }]
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }]
    };
  },
  methods: {
    goAudit: function goAudit(row, status) {
      var _this = this;

      this.apiPost('/api/smsTask/editSmsTask', {
        id: row.id,
        examStatus: status
      }).then(function (res) {
        _this.$refs.wTable.getList();
      });
    },
    goStop: function goStop(row) {
      var _this2 = this;

      this.apiPost('/api/smsTask/editSmsTask', {
        id: row.id,
        executeStatus: 3
      }).then(function (res) {
        _this2.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;