"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCouponRights = addCouponRights;
exports.couponCodeImg = couponCodeImg;
exports.getCouponRightsView = getCouponRightsView;
exports.getRightsCoupon = getRightsCoupon;
exports.listCouponRightsPage = listCouponRightsPage;
exports.rightsCoupon = rightsCoupon;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 查询用户权益券分页：/api/coupon/rightsCoupon
 {"uid":"uid","pageNo":"当前页","pageSize":"页条数"}
 */
function rightsCoupon(data) {
  return (0, _request.default)({
    url: '/api/coupon/rightsCoupon',
    method: 'post',
    data: data
  });
}
/*

 /api/coupon/getRightsCoupon
 {"uid":"uid","receivedCode":"领取编码"}
 */


function getRightsCoupon(data) {
  return (0, _request.default)({
    url: '/api/coupon/getRightsCoupon',
    method: 'post',
    data: data
  });
}
/*
 核销权益券：/api/coupon/addCouponRights
 {"uid":"uid","receivedCode":"领取编码","remark":"核销备注","attachments":"核销附件(数组)"}

 */


function addCouponRights(data) {
  return (0, _request.default)({
    url: '/api/coupon/addCouponRights',
    method: 'post',
    data: data
  });
}
/*
 权益券核销记录：/api/coupon/listCouponRightsPage
 {"pageNo":"当前页","pageSize":"页条数","uMobile":"用户手机号","uCardNo":"用户卡号",
 "couponCode":"券编码 ","couponType":"券类型","startDate":"开始日期","endDate":"结束日期"}

 */


function listCouponRightsPage(data) {
  return (0, _request.default)({
    url: '/api/coupon/listCouponRightsPage',
    method: 'post',
    data: data
  });
}
/*
权益券核销记录详情：/api/coupon/getCouponRightsView/{id}

 */


function getCouponRightsView(data) {
  return (0, _request.default)({
    url: '/api/coupon/getCouponRightsView/' + data,
    method: 'post',
    data: data
  });
}
/*
 /api/coupon/couponCodeImg
 {"uid":"uid","receivedCode":"领取编码"}
 */


function couponCodeImg(data) {
  return (0, _request.default)({
    url: '/api/coupon/couponCodeImg',
    method: 'post',
    data: data
  });
}