"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _api = require("./api.js");

var _api2 = require("../api.js");

var _mem = require("@/api/mem.js");

var _vuex = require("vuex");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _order_express = _interopRequireDefault(require("@/utils/order_express.json"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var weekTag = {
  1: '周一',
  2: '周二',
  3: '周三',
  4: '周四',
  5: '周五',
  6: '周六',
  0: '周日'
};
var moduleSourceOptions = {
  1: '周期推荐',
  5: '特价活动',
  12: '第三方商户'
};
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      moduleSourceOptions: moduleSourceOptions,
      categoryId: [],
      classProp: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'childInstitutions'
      },
      classList: [],
      expressList: _order_express.default,
      sourceList: [],
      storeList: [],
      downloadLoading: false,
      popShow: false,
      row: {},
      form: {
        logisticName: '',
        logisticNo: '',
        logisticCode: null
      },
      time: '',
      obj: {
        pageNo: 1,
        pageSize: 10,
        moduleSource: null,
        storeCode: '',
        userPhone: '',
        productSourceId: '',
        startTime: '',
        endTime: '',
        orderNo: '',
        productName: '',
        deliveryWay: '',
        orderStatus: '',
        minScore: '',
        maxScore: '',
        orderType: '',
        categoryId: null
      },
      list: [],
      total: 0,
      tabTh: [{
        prop: 'orderNo',
        label: '订单号',
        width: 120
      }, {
        prop: 'payNo',
        label: '付款交易号',
        width: 120
      }, {
        prop: 'outTradeNo',
        label: '外部订单号',
        width: 120
      }, {
        prop: 'orderTime',
        label: '下单时间',
        width: 100
      }, {
        prop: 'productName',
        label: '商品',
        width: 100
      }, {
        prop: 'categoryName',
        label: '分类',
        width: 80
      }, {
        prop: 'productSpec',
        label: '规格',
        width: 80
      }, {
        prop: 'costPrice',
        label: '成本价',
        width: 80
      }, {
        prop: 'productPriceScoreStr',
        label: '单价',
        width: 80
      }, {
        prop: 'productNum',
        label: '数量',
        width: 80
      }, {
        prop: 'productSourceName',
        label: '货源地',
        width: 80
      }, {
        prop: 'consigneeName',
        label: '收货人',
        width: 80
      }, {
        prop: 'userPhone',
        label: '会员手机号',
        width: 100
      }, {
        prop: 'memberName',
        label: '会员类型',
        width: 100
      }, {
        prop: 'consigneePhone',
        label: '收货电话',
        width: 80
      }, {
        prop: 'storeName',
        label: '自提门店',
        width: 100
      }, {
        prop: 'consigneeAddress',
        label: '收货地址',
        width: 100
      }, {
        prop: 'logisticTime',
        label: '自提时间',
        width: 100
      }, {
        prop: 'totalPrice',
        label: '实付金额',
        width: 100
      }, {
        prop: 'productScoreSubtotal',
        label: '实付积分',
        width: 100
      }, {
        prop: 'freightPrice',
        label: '运费',
        width: 80
      }, {
        prop: 'deliveryWayText',
        label: '发货方式',
        width: 100
      }, {
        prop: 'moduleSource',
        label: '来源平台',
        width: 100
      }, {
        prop: 'merchantName',
        label: '来源平台名称',
        width: 100
      }, {
        prop: 'week',
        label: '周几',
        width: 80
      }, {
        prop: 'refundTime',
        label: '退款时间',
        width: 100
      }, {
        prop: 'orderRemark',
        label: '订单备注',
        width: 100
      }]
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sourceIds'])), {}, {
    btnStatus: function btnStatus() {
      if (this.form.logisticName && this.form.logisticNo) {
        return true;
      } else {
        return false;
      }
    }
  }),
  watch: {
    time: function time(newValue, oldValue) {
      if (newValue) {
        this.obj.startTime = newValue[0];
        this.obj.endTime = newValue[1];
      } else {
        this.obj.startTime = null;
        this.obj.endTime = null;
      }
    }
  },
  created: function created() {
    var _this = this;

    (0, _api2.goodsList)({}).then(function (res) {
      _this.classList = _this.getTreeData(res.data);
    });
    (0, _mem.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
    this.apiPost('/mall/product/source/listByIds', {
      sourceIds: this.sourceIds
    }).then(function (res) {
      _this.sourceList = res.data || [];

      if (_this.sourceList.length < 5) {
        _this.obj.productSourceId = _this.sourceList[0].id;
      }
    });
  },
  activated: function activated() {// this.init()
  },
  methods: {
    cateChange: function cateChange(e) {
      if (e.length > 0) {
        this.obj.categoryId = e[e.length - 1];
      } else {
        this.obj.categoryId = null;
      }

      this.init();
    },
    getTreeData: function getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].childInstitutions.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].childInstitutions = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].childInstitutions);
        }
      }

      return data;
    },
    changeExpress: function changeExpress(e) {
      console.log(e);
      var express = this.expressList.find(function (item) {
        return item.code == e;
      });
      this.form.logisticName = express.name;
    },
    goAffrim: function goAffrim(row) {
      var _this2 = this;

      this.$confirm('确认自提成功?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _api.manualSince)({
          orderNo: row.orderNo,
          id: row.id
        }).then(function (res) {
          _this2.$message({
            message: '确认自提成功！',
            type: 'success'
          });

          _this2.init();
        });
      }).catch(function () {});
    },
    arraySpanMethod: function arraySpanMethod(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (row.deliveryWay == 1) {
        if (columnIndex === 14) {
          return {
            rowspan: 1,
            colspan: 2
          };
        } else if (columnIndex === 13) {
          return [0, 0];
        }
      } else {
        if (columnIndex === 13) {
          return {
            rowspan: 1,
            colspan: 2
          };
        } else if (columnIndex === 14) {
          return [0, 0];
        }
      }
    },
    setTab: function setTab(row) {
      this.row = row;
      this.popShow = true;
    },
    subForm: function subForm() {
      var _this3 = this;

      var data = this.form;
      data.orderNo = this.row.orderNo;
      this.openLoading();
      (0, _api.deliverygood)(data).then(function (res) {
        _this3.openLoading().close();

        console.log(res);
        _this3.popShow = false;

        _this3.init();
      }).catch(function (err) {
        _this3.openLoading().close();
      });
    },
    init: function init(type) {
      var _this4 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
        this.time = '';
      }

      this.openLoading();
      (0, _api.queryOrder)(this.obj).then(function (res) {
        _this4.openLoading().close();
        /* if (!this.setMenuPerms('user_mobile_idCard')) {
           res.data.items.forEach((item) => {
             if(item.userPhone){
               item.userPhone = item.userPhone.replace(item.userPhone.substring(3, 7), "****")
             }
             if(item.consigneePhone){
               item.consigneePhone = item.consigneePhone.replace(item.consigneePhone.substring(3, 7), "****")
             }
           })
         } */


        _this4.total = res.data.totalNum;
        _this4.list = res.data.items;

        _this4.list.forEach(function (item) {
          item.week = weekTag[new Date(item.payTime).getDay()];

          if (item.deliveryWay == '0') {
            item.deliveryWayText = '自提';
          }

          if (item.deliveryWay == 1) {
            item.deliveryWayText = '邮寄';
          }

          item.moduleSource = _this4.moduleSourceOptions[item.moduleSource] || '';
        });
      }).catch(function (err) {
        _this4.openLoading().close();
      });
    },
    goAdd: function goAdd(url) {
      this.$router.push(url);
    },
    handleDownload: function handleDownload() {
      var _this5 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['订单号', '付款交易号', '外部订单号', '下单时间', '商品', '分类', '规格', '成本价', '单价', '数量', '货源地', '自提门店', '收货人', '会员手机号', '会员类型', '收货电话', '收货地址', '自提时间', '实付金额', '实付积分', '运费', '发货方式', '来源平台名称', "支付状态", '状态', '周期', '退款时间'];
        var filterVal = ['orderNo', 'payNo', 'outTradeNo', 'orderTime', 'productName', 'categoryName', 'productSpec', 'costPrice', 'productPriceScoreStr', 'productNum', 'productSourceName', 'storeName', 'consigneeName', 'userPhone', 'memberName', 'consigneePhone', 'consigneeAddress', 'logisticTime', 'totalPrice', 'productScoreSubtotal', 'freightPrice', 'deliveryWayText', 'merchantName', "payStatusTxt", 'orderStatusTxt', 'week', 'refundTime'];
        var list = [];
        var data = {};
        Object.keys(_this5.obj).forEach(function (key) {
          if (key) {
            data[key] = _this5.obj[key];
          }
        });
        data.pageSize = _this5.total;
        data.pageNo = 1;

        _this5.openLoading();

        (0, _api.queryOrder)(data).then(function (res) {
          list = res.data.items;
          /*  if (!this.setMenuPerms('user_mobile_idCard')) {
              res.data.items.forEach((item) => {
                if(item.userPhone){
                  item.userPhone = item.userPhone.replace(item.userPhone.substring(3, 7), "****")
                }
                if(item.consigneePhone){
                  item.consigneePhone = item.consigneePhone.replace(item.consigneePhone.substring(3, 7), "****")
                }
              })
            } */

          list.forEach(function (item) {
            item.week = weekTag[new Date(item.payTime).getDay()];

            if (item.deliveryWay == '0') {
              item.deliveryWayText = '自提';
            }

            if (item.deliveryWay == 1) {
              item.deliveryWayText = '邮寄';
            }

            switch (item.orderStatus) {
              case 1:
                item.orderStatusTxt = '待发货/待自提';
                break;

              case 2:
                item.orderStatusTxt = '待收货';
                break;

              case 3:
                item.orderStatusTxt = '已完成';
                break;

              case 4:
                item.orderStatusTxt = '售后';
                break;

              case 5:
                item.orderStatusTxt = '取消';
                break;

              default:
                break;
            }

            item.payStatusTxt = item.payStatus == 1 ? '已支付' : '未支付';
          });

          var data = _this5.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '订单列表'
          });
          _this5.downloadLoading = false;

          _this5.openLoading().close();
        }).catch(function (res) {
          _this5.downloadLoading = false;

          _this5.openLoading().close();
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;