var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("新增/编辑基础积分策略")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            size: "small",
            model: _vm.form,
            "label-width": "120px"
          }
        },
        [
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [_vm._v(" 消费金额 ")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: {
                      prop: "memberArr",
                      label: "会员类型：",
                      required: ""
                    }
                  },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.form.memberArr,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "memberArr", $$v)
                          },
                          expression: "form.memberArr"
                        }
                      },
                      [
                        _c("el-checkbox", { attrs: { label: 1 } }, [
                          _vm._v("普通会员")
                        ]),
                        _vm._v(" "),
                        _c("el-checkbox", { attrs: { label: 2 } }, [
                          _vm._v("365会员")
                        ]),
                        _vm._v(" "),
                        _c("el-checkbox", { attrs: { label: 3 } }, [
                          _vm._v("Lavant会员")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.form.memberArr.includes(1)
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "form_item",
                        attrs: {
                          prop: "sellAmount",
                          label: "普通会员：",
                          required: ""
                        }
                      },
                      [
                        _c("el-input-number", {
                          staticClass: "form_input",
                          attrs: { min: 1, controls: false },
                          model: {
                            value: _vm.form.sellAmount,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sellAmount", $$v)
                            },
                            expression: "form.sellAmount"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.form.memberArr.includes(2)
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "form_item",
                        attrs: {
                          prop: "sell365Amount",
                          label: "365会员：",
                          required: ""
                        }
                      },
                      [
                        _c("el-input-number", {
                          staticClass: "form_input",
                          attrs: { min: 1, controls: false },
                          model: {
                            value: _vm.form.sell365Amount,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sell365Amount", $$v)
                            },
                            expression: "form.sell365Amount"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.form.memberArr.includes(3)
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "form_item",
                        attrs: {
                          prop: "selllavantAmount",
                          label: "Lavant会员：",
                          required: ""
                        }
                      },
                      [
                        _c("el-input-number", {
                          staticClass: "form_input",
                          attrs: { min: 1, controls: false },
                          model: {
                            value: _vm.form.selllavantAmount,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "selllavantAmount", $$v)
                            },
                            expression: "form.selllavantAmount"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [_vm._v(" 获得积分 ")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: {
                      prop: "getScore",
                      label: "获得积分：",
                      required: ""
                    }
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "100%", "max-width": "300px" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.form.getScore,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "getScore", $$v)
                        },
                        expression: "form.getScore"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [_vm._v(" 策略状态 ")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: {
                      prop: "basicStatus",
                      label: "策略状态：",
                      required: ""
                    }
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.basicStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "basicStatus", $$v)
                          },
                          expression: "form.basicStatus"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("启用")
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: -1 } }, [
                          _vm._v("禁用")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [_vm._v(" 适用范围 ")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right", staticStyle: { padding: "20px 0" } },
              [
                _c("integral", {
                  ref: "integralRef",
                  attrs: { form: _vm.form },
                  on: {
                    setcompanyId: _vm.setcompanyId,
                    submitForm: _vm.submit,
                    setStoreId: _vm.setStoreId
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form_box" }, [
            _c("div", { staticClass: "form_left" }, [_vm._v(" 备注 ")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form_right" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "form_item",
                    attrs: { label: "备注：", prop: "basicRemark" }
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "100%", "max-width": "400px" },
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 2, maxRows: 8 },
                        placeholder: "请输入内容"
                      },
                      model: {
                        value: _vm.form.basicRemark,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "basicRemark", $$v)
                        },
                        expression: "form.basicRemark"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { margin: "30px 0 0 150px" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("form")
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }