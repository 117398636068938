"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _deposit = require("@/api/deposit.js");

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AppLink: _Link.default,
    wTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      requestData: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        mobile: '',
        orderNo: '',
        startDate: '',
        endDate: ''
      },
      time: null,
      tableData: [],
      columns: [{
        label: '订单号',
        prop: '',
        width: 80
      }, {
        label: '购物卡名称',
        prop: '',
        width: 80
      }, {
        label: '下单时间',
        prop: '',
        width: 100
      }, {
        label: '充值金额',
        prop: '',
        width: 50
      }, {
        label: '用户微信昵称',
        prop: '',
        width: 50
      }, {
        slot: 'operate'
      }]
    };
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    goRefund: function goRefund(row) {
      var _this = this;

      refund({
        id: row.id
      }).then(function (res) {
        _this.getList();
      });
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['订单号', '会员姓名', '手机号', '购物卡名称', '储值卡卡号', '领取编码', '付款时间', '购卡金额', '卡状态'];
        var filterVal = ['orderNo', 'name', 'mobile', 'cardName', 'cardNo', 'code', 'payTime', 'amount', 'status'];
        _this2.requestData.startDate = _this2.time[0] || '';
        _this2.requestData.endDate = _this2.time[1] || '';
        var data = JSON.parse(JSON.stringify(_this2.requestData));
        data.pageSize = data.total;
        data.pageNo = 1;

        _this2.openLoading();

        (0, _deposit.listCardStoredDetail)(data).then(function (res) {
          var list = res.data.items;

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '购卡记录'
          });
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        }).catch(function (res) {
          _this2.downloadLoading = false;

          _this2.openLoading().close();
        });
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type == 'search') {
        this.pageNo = 1;
      }

      if (type == 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      this.requestData.startDate = this.time[0] || '';
      this.requestData.endDate = this.time[1] || '';
      (0, _deposit.listCardStoredDetail)(this.requestData).then(function (res) {
        _this3.tableData = res.data.items;
        _this3.requestData.total = res.data.totalNum;
        return;
      });
    }
  }
};
exports.default = _default;