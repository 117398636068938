var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          attrs: {
            "form-data": _vm.formData,
            "rule-form": _vm.ruleForm,
            rules: _vm.rules
          },
          on: { submitForm: _vm.submitForm }
        },
        [
          _c(
            "div",
            { attrs: { slot: "voucher" }, slot: "voucher" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "凭证：", prop: "voucher" } },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 200,
                      height: 200,
                      cover: _vm.ruleForm.voucher
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "voucher")
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "cardUuid" }, slot: "cardUuid" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "团购购物卡：", prop: "cardUuid" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.getAward("card")
                        }
                      }
                    },
                    [_vm._v("选择购物卡")]
                  ),
                  _vm._v(
                    "\n        " + _vm._s(_vm.ruleForm.cardUuid) + "\n      "
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: { type: "card", "coupon-show": _vm.couponShow },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }