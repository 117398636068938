"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      tableData: [],
      requestData: {
        name: null,
        account: null,
        pageSize: 10,
        page: 1
      },
      searchData: [{
        label: '用户手机号',
        type: 'input',
        span: 4,
        placeholder: '请输入用户手机号',
        value: 'mobile'
      }, {
        label: '操作人',
        type: 'input',
        span: 4,
        placeholder: '操作人手机号码',
        value: 'managerMobile'
      }]
    };
  },
  methods: {}
};
exports.default = _default;