"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _template = require("@/api/template.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      modifyTime: '',
      ruleForm: {
        tmplateName: '',
        classId: '',
        tmplateStatus: '1' // id: ''

      },
      rules: {
        classId: [{
          required: true,
          message: '模板分类'
        }],
        tmplateStatus: [{
          required: true,
          message: '请选择模板状态'
        }],
        tmplateName: [{
          required: true,
          message: '请输入模板名称'
        }]
      },
      temClass: []
    };
  },
  created: function created() {
    var _this = this;

    (0, _template.listTmplateClass)().then(function (res) {
      _this.temClass = res.data;
    });

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _template.getTmplate)(this.ruleForm.id).then(function (res) {
        _this.modifyTime = res.data.modifyTime;

        _this.fzObj(res.data, _this.ruleForm);
      });
    }
  },
  methods: {
    submitForm: function submitForm(form) {
      var _this2 = this;

      this.$refs[form].validate(function (valid) {
        if (valid) {
          (0, _template.addTmplate)(_this2.ruleForm).then(function (res) {
            _this2.$message({
              type: 'success',
              message: '新增成功!'
            });

            _this2.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;