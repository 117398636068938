var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "departmentDea" }, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "departmentDea_input" }, [
      _c("ul", [
        _c("li", [
          _c("p", { staticClass: "li_tit" }, [
            _vm._v("\n          父级资源：\n        ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "li_div" },
            [
              _c("el-tree", {
                ref: "treeForm",
                staticClass: "li_input",
                attrs: {
                  "default-expanded-keys": _vm.defaultKeys,
                  data: _vm.dataTree,
                  "show-checkbox": "",
                  "node-key": "menuId",
                  props: _vm.defaultProps,
                  "check-strictly": true,
                  "expand-on-click-node": false
                },
                on: {
                  "check-change": _vm.handleClick,
                  "node-click": _vm.nodeClick
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "li_tit" }, [
              _vm._v("\n          资源名称：\n        ")
            ]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "li_input",
              attrs: { placeholder: "请输入资源名称" },
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "li_tit" }, [
              _vm._v("\n          资源类型：\n        ")
            ]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择类型" },
                model: {
                  value: _vm.type,
                  callback: function($$v) {
                    _vm.type = $$v
                  },
                  expression: "type"
                }
              },
              _vm._l(_vm.options, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value }
                })
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.type === 1
          ? _c(
              "li",
              [
                _c("p", { staticClass: "li_tit" }, [
                  _vm._v("\n          资源路径：\n        ")
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "li_input",
                  attrs: { placeholder: "请输入资源路径" },
                  model: {
                    value: _vm.url,
                    callback: function($$v) {
                      _vm.url = $$v
                    },
                    expression: "url"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.type === 2
          ? _c(
              "li",
              [
                _c("p", { staticClass: "li_tit" }, [
                  _vm._v("\n          权限标识：\n        ")
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "li_input",
                  attrs: { placeholder: "请输入权限标识" },
                  model: {
                    value: _vm.perms,
                    callback: function($$v) {
                      _vm.perms = $$v
                    },
                    expression: "perms"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.type !== 2
          ? _c(
              "li",
              [
                _c("p", { staticClass: "li_tit" }, [
                  _vm._v("\n          资源图标：\n        ")
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "li_input",
                  attrs: { placeholder: "请输入资源图标" },
                  model: {
                    value: _vm.icon,
                    callback: function($$v) {
                      _vm.icon = $$v
                    },
                    expression: "icon"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "li_tit" }, [
              _vm._v("\n          排序号：\n        ")
            ]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "li_input",
              attrs: { type: "number", placeholder: "请输入\t排序号" },
              model: {
                value: _vm.orderNum,
                callback: function($$v) {
                  _vm.orderNum = $$v
                },
                expression: "orderNum"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "li_tit" }),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.next } },
              [_vm._v("保存")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }