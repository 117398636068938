"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _report = require("@/api/report.js");

var _member = require("@/api/member.js");

var _userInfo = _interopRequireDefault(require("./userInfo.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    userInfo: _userInfo.default
  },
  data: function data() {
    return {
      cancelShow: false,
      dingtalkNo: '',
      userForm: {
        realname: '',
        sex: 0,
        nation: '',
        birthday: '',
        // voucher: '',
        idNo: '',
        status: 1
      },
      userRules: {
        realname: [{
          required: true,
          message: '请输入用户真实姓名',
          trigger: 'blur'
        }],
        sex: [{
          required: true,
          message: '请选择性别',
          trigger: 'change'
        }],
        nation: [{
          required: true,
          message: '请输入用户民族',
          trigger: 'blur'
        }],
        birthday: [{
          required: true,
          message: '请选择生日',
          trigger: 'change'
        }],
        // voucher: [{ required: true, message: '请选择证件类型', trigger: 'change' }],
        idNo: [{
          required: true,
          message: '请输入身份证号',
          trigger: 'blur'
        }, {
          pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
          message: '证件号码格式有误！',
          trigger: 'blur'
        }]
      },
      type: '1',
      popShow: false,
      autofocus: true,
      memeberCode: '',
      memeberMobile: '',
      mid: '',
      user: {},
      uid: '',
      status: '',
      memberList: [],
      couponList: [],
      userId: ''
    };
  },
  created: function created() {
    this.userId = this.$route.query.userId;
  },
  methods: {
    affrimCancel: function affrimCancel() {
      var _this = this;

      this.$confirm('此操作将确认合并会员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _member.memberMerge)({
          userId: _this.userId,
          dingtalkNo: _this.dingtalkNo,
          mergeUmobile: _this.user.mobile
        }).then(function (res) {
          _this.cancelShow = false;
          _this.dingtalkNo = '';

          _this.$message({
            type: 'success',
            message: '合并成功!'
          });
        }).catch(function () {});
      });
    },
    setStatus: function setStatus(e) {
      console.log(e);

      if (e) {
        this.userForm.status = 1;
      } else {
        this.userForm.status = 2;
      }
    },
    setUser: function setUser() {
      var _this2 = this;

      this.$refs['formUser'].validate(function (valid) {
        if (valid) {
          console.log(_this2.userForm);
          var data = _this2.userForm;
          data.uid = _this2.user.uid;

          _this2.openLoading();

          (0, _report.editUser)(data).then(function (res) {
            _this2.openLoading().close();

            _this2.$message({
              showClose: true,
              message: '保存成功',
              type: 'success'
            });
          }).catch(function (err) {
            _this2.openLoading().close();
          });
        }
      });
    },
    closePop: function closePop() {
      this.popShow = false;
    },
    goMid: function goMid() {
      var _this3 = this;

      this.popShow = true;
      this.$nextTick(function () {
        _this3.$refs.midInput.focus();
      });
    },
    goSearchType: function goSearchType() {
      var data = {
        type: 2,
        mobile: this.mid
      };
      this.goSearch(data);
    },
    goSearch: function goSearch(obj) {
      var _this4 = this;

      var data = {};

      if (obj.type == 2) {
        data = obj;
      } else {
        data = {
          mobile: this.memeberCode,
          type: this.type
        };
      }

      this.openLoading();
      this.memberList = [];
      this.user = {};
      this.status = '';
      this.$refs['formUser'].resetFields();
      (0, _report.handleMidUser)(data).then(function (res) {
        _this4.openLoading().close();

        if (_this4.popShow) {
          _this4.popShow = false;
        }

        _this4.pageNo = 1;
        _this4.mid = '';
        _this4.user = res.data.user;
        _this4.userForm.status = res.data.user.status;
        _this4.uid = res.data.user.uid;
        _this4.userForm.uid = res.data.user.uid;
        _this4.userForm.realname = res.data.user.realname;
        _this4.userForm.sex = res.data.user.sex;
        _this4.userForm.nation = res.data.user.nation;
        _this4.userForm.birthday = res.data.user.birthday ? res.data.user.birthday.split('.').join('-') : '';
        _this4.userForm.mobile = res.data.user.mobile;
        _this4.userForm.idNo = res.data.user.idNo;
        _this4.userForm.gradeName = res.data.user.gradeName;
        _this4.userForm.growthValue = res.data.user.growthValue;
        _this4.memberList = res.data.cards;
        _this4.status = res.data.status; // this.$refs.card.setUid(res.data.user.uid);
      }).catch(function (res) {
        _this4.uid = '';
        _this4.memberList = [];
        _this4.user = {};
        _this4.status = '';

        _this4.$refs['formUser'].resetFields();

        _this4.openLoading().close();
      });
    }
  }
};
exports.default = _default;