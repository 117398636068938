"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _content = require("@/api/content.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      contentObj: {}
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      this.contentObj = JSON.parse(sessionStorage.getItem('contentObj'));
      console.log(this.contentObj);
    },
    next: function next() {
      var _this = this;

      this.$confirm('此操作将添加文章, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this.contentObj.articleId) {
          (0, _content.editArticle)(_this.contentObj).then(function (res) {
            _this.$router.push('/content/manageContent/manageArticle');
          });
        } else {
          (0, _content.addArticle)(_this.contentObj).then(function (res) {
            _this.$router.push('/content/manageContent/manageArticle');
          });
        }
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消添加'
        });
      });
    }
  }
};
exports.default = _default;