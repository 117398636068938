"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addGoods = addGoods;
exports.editGoods = editGoods;
exports.editOrder = editOrder;
exports.getGoodsById = getGoodsById;
exports.getOrderById = getOrderById;
exports.listGoodsClass = listGoodsClass;
exports.listGoodsPage = listGoodsPage;
exports.listOrderPage = listOrderPage;
exports.returnOrder = returnOrder;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
1、商品分页：/api/goods/listGoodsPage
2、新增商品：/api/goods/addGoods
3、编辑商品：/api/goods/editGoods
4、查询商品明细：/api/goods/getGoodsById/{id}
 */
function listGoodsClass(data) {
  return (0, _request.default)({
    url: '/api/goods/listGoodsClass',
    method: 'post',
    data: data
  });
}

function listGoodsPage(data) {
  return (0, _request.default)({
    url: '/api/goods/listGoodsPage',
    method: 'post',
    data: data
  });
}

function addGoods(data) {
  return (0, _request.default)({
    url: '/api/goods/addGoods',
    method: 'post',
    data: data
  });
}

function editGoods(data) {
  return (0, _request.default)({
    url: '/api/goods/editGoods',
    method: 'post',
    data: data
  });
}

function getGoodsById(id) {
  return (0, _request.default)({
    url: '/api/goods/getGoodsById/' + id,
    method: 'get'
  });
}

function listOrderPage(data) {
  return (0, _request.default)({
    url: '/api/dcbOrder/listOrderPage',
    method: 'post',
    data: data
  });
}

function editOrder(data) {
  return (0, _request.default)({
    url: '/api/dcbOrder/editOrder',
    method: 'post',
    data: data
  });
}

function getOrderById(id) {
  return (0, _request.default)({
    url: '/api/dcbOrder/getOrderById/' + id,
    method: 'post'
  });
}
/*
退货：/api/dcbOrder/returnOrder
入参：{"id":"订单id","returnRemark":"退货备注","orderItems":[{"itemId":"订单项id"}]}
 */


function returnOrder(data) {
  return (0, _request.default)({
    url: '/api/dcbOrder/returnOrder',
    method: 'post',
    data: data
  });
}