"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      rules: {
        rowStatus: [{
          required: true,
          message: '请选择状态'
        }],
        productSourceName: [{
          required: true,
          message: '请输入名称'
        }],
        userMobile: [{
          required: true,
          message: '请输入联系电话'
        }],

        /* sourceAddress: [
          {
            required: true,
            message: '请输入所在地址'
          }
        ], */

        /* sourceRemark: [
          {
            required: true,
            message: '请输入备注'
          }
        ], */
        userName: [{
          required: true,
          message: '请输入联系人'
        }]
      },
      ruleForm: {
        productSourceName: null,
        userName: null,
        userMobile: null,
        sourceAddress: null,
        sourceRemark: null,
        rowStatus: null
      }
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _api.getSource)(this.ruleForm.id).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (key) {
          _this.ruleForm[key] = res.data[key];
        });
      });
    }
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this2.ruleForm.id) {
            (0, _api.editSource)(_this2.ruleForm).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addSource)(_this2.ruleForm).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;