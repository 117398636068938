"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _tableList = _interopRequireDefault(require("@/components/tableList"));

var _deposit = require("@/api/deposit");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default
  },
  data: function data() {
    return {
      deaPath: '/deposit/onLine/addClass?id=',
      pageNo: 1,
      pageSize: 10,
      total: 0,
      btn: '编辑',
      operation: 2,
      btnType: 'primary',
      tableData: [],
      tabTh: [//				{
      //					tit:"ID",
      //					prop:'id'
      //				},
      {
        tit: '分类名称',
        prop: 'name',
        align: 'center'
      }, {
        tit: '添加时间',
        prop: 'createTime',
        sortable: true,
        align: 'center'
      }],
      idCard: '',
      nameCard: '',
      options: [{
        value: '1',
        label: '状态'
      }, {
        value: '2',
        label: '使用中'
      }, {
        value: '3',
        label: '已停用'
      }, {
        value: '4',
        label: '已过期'
      }],
      value: '1'
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    setBtn: function setBtn(scope) {
      // 编辑
      console.log(scope.id);
      this.$router.push('/deposit/onLine/addClass?id=' + scope.id);
    },
    goAdd: function goAdd() {
      // 去添加
      this.$router.push('/deposit/onLine/addClass');
    },
    getList: function getList(page) {
      this.pageNo = page.page;
      this.pageSize = page.limit;
      this.init();
    },
    init: function init() {
      var _this = this;

      (0, _deposit.listCardStoredClass)().then(function (res) {
        console.log(res);

        if (res.ret === 1000) {
          _this.tableData = res.data;
          _this.total = res.data.total;

          _this.tableData.forEach(function (value) {
            value.createTime = value.createTime.substring(0, 10);
          });
        }
      });
    },
    setValue: function setValue() {
      this.value = '1';
      this.idCard = '';
      this.nameCard = '';
    }
  }
};
exports.default = _default;