"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '订单号',
        prop: 'issueOrder',
        width: 80
      }, {
        label: '礼品编码',
        prop: 'productCode',
        width: 80
      }, {
        label: '礼品名称',
        prop: 'productName',
        width: 80
      }, {
        label: '活动ID',
        prop: 'joinLogId',
        width: 50
      }, {
        label: '用户手机号',
        prop: 'mobile',
        width: 50
      }, {
        label: '用户昵称',
        prop: 'name',
        width: 80
      }, {
        label: '礼品数量/购物卡金额',
        prop: 'productNum',
        width: 50
      }],
      searchData: [{
        type: 'input',
        placeholder: '用户手机号码',
        value: 'mobile'
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }]
    };
  },
  methods: {}
};
exports.default = _default;