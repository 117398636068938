"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.editUser = editUser;
exports.getOrderinfo = getOrderinfo;
exports.handleMidUser = handleMidUser;
exports.listCardTradePage = listCardTradePage;
exports.listCouponByUid = listCouponByUid;
exports.listCouponNumberPage = listCouponNumberPage;
exports.listGiftCouponNumberPage = listGiftCouponNumberPage;
exports.listGrowthLogPage = listGrowthLogPage;
exports.listMyCardStored = listMyCardStored;
exports.listOrderCouponPage = listOrderCouponPage;
exports.listSendCouponMTLog = listSendCouponMTLog;
exports.listVoteLogPage = listVoteLogPage;
exports.unlineLogDsReport = unlineLogDsReport;
exports.unlineLogInstitutionPage = unlineLogInstitutionPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 /api/cardTrade/listCardTradePage

 */
function listOrderCouponPage(data) {
  return (0, _request.default)({
    url: '/api/parkingOrder/listOrderCouponPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listCardTradePage(data) {
  return (0, _request.default)({
    url: '/api/cardTrade/listCardTradePage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function unlineLogInstitutionPage(data) {
  return (0, _request.default)({
    url: '/api/unlineLog/unlineLogInstitutionPage',
    method: 'post',
    data: data
  });
}
/*
 茅台券
 */


function listSendCouponMTLog(data) {
  return (0, _request.default)({
    url: '/api/mt/listSendCouponMTLog',
    method: 'post',
    data: data
  });
}
/*
 礼品券查询
 */


function listGiftCouponNumberPage(data) {
  return (0, _request.default)({
    url: '/api/coupon/listGiftCouponNumberPage',
    method: 'post',
    data: data
  });
}

function listCouponNumberPage(data) {
  return (0, _request.default)({
    url: '/api/coupon/listCouponNumberPage',
    method: 'post',
    data: data
  });
}

function handleMidUser(data) {
  return (0, _request.default)({
    url: '/api/mainUser/handleMidUser',
    method: 'post',
    data: data
  });
}

function listCouponByUid(data) {
  return (0, _request.default)({
    url: '/api/coupon/listCouponByUid',
    method: 'post',
    data: data
  });
}

function listGrowthLogPage(data) {
  return (0, _request.default)({
    url: '/api/growthLog/listGrowthLogPage',
    method: 'post',
    data: data
  });
}

function listMyCardStored(uid) {
  return (0, _request.default)({
    //  url: 'api/cardStored/listMyCardStored/' + uid.uid,
    url: 'api/cardStored/listMyCardStored',
    method: 'post',
    data: uid
  });
}
/*
 编辑用户
 */


function editUser(data) {
  return (0, _request.default)({
    url: '/api/mainUser/editUser',
    method: 'post',
    data: data
  });
}
/*
拉新活动
 */


function unlineLogDsReport(data) {
  return (0, _request.default)({
    url: 'api/unlineLog/unlineLogDsReport',
    method: 'post',
    data: data
  });
}
/*
投票统计

      /api/activityVoteItem/listVoteLogPage
      {"pageNo":"当前页","pageSize":"页条数","activityId":"活动id","itemName":"投票项名称(组名)",
      "itemTitle":"投票项标题","startDate":"开始日期","endDate":"结束日期"}
 */


function listVoteLogPage(data) {
  return (0, _request.default)({
    url: '/api/activityVoteItem/listVoteLogPage',
    method: 'post',
    data: data
  });
}

function getOrderinfo(orderNo) {
  return (0, _request.default)({
    url: '/api/order/getOrderByOrderNo/' + orderNo,
    method: 'get'
  });
}