var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "print_hide ", class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isCollapse
        ? _c(
            "div",
            { staticStyle: { padding: "5px 10px" } },
            [
              _c("el-input", {
                attrs: { "prefix-icon": "el-icon-search", placeholder: "搜索" },
                model: {
                  value: _vm.filterText,
                  callback: function($$v) {
                    _vm.filterText = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "filterText"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-vertical-demo",
              attrs: {
                "unique-opened": "",
                router: "",
                collapse: _vm.isCollapse,
                "default-active": _vm.activeMenu,
                "active-text-color": _vm.variables.menuActiveText,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText
              }
            },
            [_c("navbar", { attrs: { list: _vm.menuList } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }