"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '营销工具',
      list: [{
        tit: '抽奖',
        txtArr: '<p>抽奖活动管理、抽奖活动记录</p>',
        imgPath: require('@/assets/tab/activity/2.png'),
        path: '/activity/lottery/tab'
      }, {
        tit: '好友助力',
        txtArr: '<p>好友助力</p>',
        imgPath: require('@/assets/tab/activity/1.png'),
        path: '/activity/help/tab'
      }, {
        tit: '签到',
        txtArr: '<p>签到</p>',
        imgPath: require('@/assets/tab/activity/6.png'),
        path: '/activity/sign/tab'
      }, {
        tit: '游戏',
        txtArr: '<p>游戏管理</p>    ',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: ''
      }, {
        tit: '秒杀',
        txtArr: '<p>秒杀管理</p>    ',
        imgPath: require('@/assets/tab/activity/4.png'),
        path: ''
      }]
    };
  }
};
exports.default = _default;