"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon.js");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/phone.xlsx"),
      addMobile: null,
      svipList: [],
      vipList: [],
      categoryList: [],
      typeList: [{
        label: '要客等级',
        value: 1
      }, {
        label: '365类型',
        value: 2
      }, {
        label: '注册时间',
        value: 3
      }, {
        label: '用户年龄',
        value: 4
      }, {
        label: '用户性别',
        value: 5
      }],
      formData: [{
        slot: 'categoryId'
      }, {
        label: '标签名称：',
        type: 'input',
        placeholder: '请输入标签名称',
        value: 'labelName'
      },
      /* {
           label: '图标：',
           type: 'img',
           placeholder: '请上传图标',
           value: 'icon'
         }, */
      {
        slot: 'voucher'
      }, {
        label: '状态：',
        type: 'radio',
        placeholder: '请选择状态',
        value: 'status',
        selectList: [{
          label: '启用',
          value: 1
        }, {
          label: '禁用',
          value: 2
        }]
      }, {
        label: '类型：',
        type: 'radio',
        placeholder: '请选择类型',
        value: 'andOr',
        selectList: [{
          label: '且',
          value: 1
        }, {
          label: '或',
          value: 2
        }]
      }, {
        label: '简介：',
        type: 'input',
        placeholder: '请输入简介',
        value: 'describes'
      }, {
        label: '筛选类型：',
        type: 'radio',
        placeholder: '请选择类型',
        value: 'type',
        selectList: [{
          label: '按规则',
          value: 1
        }, {
          label: '按名单',
          value: 2
        }]
      }, {
        slot: 'ruleGroupList',
        label: '规则组：',
        value: 'ruleGroupList'
      }, {
        slot: 'mobiles',
        label: '手机号：',
        value: 'mobiles'
      }],
      ruleForm: {
        id: null,
        type: 2,
        mobiles: [],
        categoryId: null,
        labelName: null,
        icon: null,
        status: null,
        andOr: null,
        describes: null,
        ruleGroupList: []
      },
      rules: {
        mobiles: [{
          required: true,
          message: '请上传手机号'
        }],
        categoryId: [{
          required: true,
          message: '请选择类目'
        }],
        labelName: [{
          required: true,
          message: '请输入标签名称'
        }],
        icon: [{
          required: true,
          message: '请上传ICON'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        andOr: [{
          required: true,
          message: '请选择类型'
        }],
        describes: [{
          required: true,
          message: '请输入简介'
        }],
        ruleGroupList: [{
          required: true,
          message: '请添加规则组'
        }],
        context: [{
          required: true,
          message: '请添加规则'
        }, {
          validator: function validator(rule, value, callback) {
            console.log(value);

            if (value.length < 1) {
              callback(new Error('请添加规则，至少需要一条规则'));
            } else {
              callback();
            }
          }
        }],
        type: [{
          required: true,
          message: '请选择规则类型'
        }, {
          validator: function validator(rule, value, callback) {
            if (!/(^[0-9]\d*$)/.test(value)) {
              callback(new Error('请输入大于0的正整数'));
            } else {
              callback();
            }
          }
        }],
        ifEqual: [{
          required: true,
          message: '请选择条件'
        }],
        condition: [{
          required: true,
          message: '请补充条件值'
        }]
      }
    };
  },
  computed: {
    groupItemLength: function groupItemLength() {
      var goodsNum = 0;
      this.ruleForm.ruleGroupList.forEach(function (item) {
        goodsNum += item.context.length;
      });
      return goodsNum;
    }
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/category/label/getUserCategoryLabelById/' + this.ruleForm.id).then(function (res) {
        res.data.mobiles = res.data.type == 2 ? res.data.mobiles.split(',') : [];
        res.data.ruleGroupList = res.data.type == 1 ? res.data.ruleGroupList : [];

        if (res.data.type == 1) {
          res.data.ruleGroupList.forEach(function (item) {
            item.context.forEach(function (row) {
              if (row.type == 3) {
                row.conditions = row.condition.split(',');
              }

              if (row.type == 4) {
                row.condition1 = Number(row.condition.split(',')[0]);
                row.condition2 = Number(row.condition.split(',')[1]);
              }
            });
          });
        }

        _this.ruleForm = res.data;
      });
      /* getInfo(this.ruleForm.id).then((res) => {
           this.mch = res
           this.ruleForm = res
         }) */
    }

    this.apiPost('/api/category/label/listUserCategory', {
      status: 1
    }).then(function (res) {
      var result = [];
      var map = {};
      res.data.forEach(function (item) {
        map[item.id] = item;
      });
      res.data.forEach(function (item) {
        item.disabled = item.id == _this.ruleForm.id;
        var parent = map[item.paterId];

        if (parent) {
          if (!parent.children) parent.children = [];
          parent.children.push(item);
        } else {
          result.push(item);
        }
      });
      _this.categoryList = result;
    });
    this.getVipList();
    this.getSvipLevel();
  },
  methods: {
    changeAge: function changeAge(index, rowIndex) {
      var item = this.ruleForm.ruleGroupList[index].context[rowIndex];

      if (item.condition1 >= 0 && item.condition2) {
        this.ruleForm.ruleGroupList[index].context[rowIndex].condition = "".concat(item.condition1, ",").concat(item.condition2);
      } else {
        this.ruleForm.ruleGroupList[index].context[rowIndex].condition = null;
      }
    },
    changeDateTime: function changeDateTime(e, index, rowIndex) {
      if (e) {
        this.ruleForm.ruleGroupList[index].context[rowIndex].condition = e.join(',');
      } else {
        this.ruleForm.ruleGroupList[index].context[rowIndex].condition = null;
      }
    },
    delGropItem: function delGropItem(index, rowIndex) {
      this.ruleForm.ruleGroupList[index].context.splice(rowIndex, 1);
    },
    handleDownload: function handleDownload() {
      var tableData = this.ruleForm.mobiles.map(function (item) {
        return {
          手机号: item
        };
      });
      this.getHandleDownload(['手机号'], ['手机号'], tableData, this.ruleForm.labelName);
    },
    delMobile: function delMobile(item, index) {
      var _self = this;

      this.$confirm("\u786E\u8BA4\u79FB\u9664\u624B\u673A\u53F7".concat(item, ", \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _self.ruleForm.mobiles.splice(index, 1);
      });
    },
    handleSuccess: function handleSuccess(_ref) {
      var _this2 = this;

      var results = _ref.results,
          header = _ref.header;
      this.ruleForm.mobiles = [];
      results.forEach(function (data) {
        var mobile = String(data.手机号);

        if (mobile.length == 11) {
          _this2.ruleForm.mobiles.push(mobile.trim());
        }
      });
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 3;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过3M的文件',
        type: 'warning'
      });
      return false;
    },
    goAddMobile: function goAddMobile() {
      if (this.addMobile && this.addMobile.length === 11) {
        if (this.ruleForm.mobiles.includes(this.addMobile)) return;
        this.ruleForm.mobiles.push(this.addMobile);
        this.$refs.wForm.goValidateField('mobiles');
      }
    },
    delRuleGroup: function delRuleGroup(index) {
      this.ruleForm.ruleGroupList.splice(index, 1);
    },
    addRuleGroupItem: function addRuleGroupItem(index) {
      this.ruleForm.ruleGroupList[index].context.push({
        type: null,
        ifEqual: null,
        condition: null
      });
    },
    addRuleGroup: function addRuleGroup() {
      this.ruleForm.ruleGroupList.push({
        andOr: null,
        context: [{
          type: null,
          ifEqual: null,
          condition: null
        }]
      });
    },
    getVipList: function getVipList() {
      var _this3 = this;

      this.apiPost('/api/pay-member-type/page', {
        pageNo: 1,
        pageSize: 30
      }).then(function (res) {
        var vipList = res.data.records.map(function (item) {
          return {
            id: String(item.id),
            payMemberName: item.payMemberName,
            payMemberNameShort: item.payMemberNameShort
          };
        });
        var list = [{
          id: '0',
          payMemberName: '全部',
          payMemberNameShort: '全部365'
        }, {
          id: '-1',
          payMemberName: '365副卡'
        }];
        _this3.vipList = [].concat(list, (0, _toConsumableArray2.default)(vipList));
      });
    },
    getSvipLevel: function getSvipLevel() {
      var _this4 = this;

      this.apiGet('/api/svip/level').then(function (res) {
        _this4.svipList = res.data.map(function (item) {
          return {
            id: String(item.id),
            name: item.name
          };
        });

        _this4.svipList.unshift({
          id: '0',
          name: '全部'
        });
      });
    },
    getAward: function getAward(e) {
      this.$refs.coupon1.getList('card', this.ruleForm.cardUuid);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      console.log(e);
      this.ruleForm.cardUuid = e.uuid;
      this.couponShow = false;
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;

      var paterIdData = this.$refs.paterId.getCheckedNodes();
      var data = (0, _objectSpread2.default)({}, this.ruleForm);
      data.categoryId = paterIdData.length > 0 ? paterIdData[0].value : 0;

      if (this.ruleForm.type == 2) {
        data.mobiles = this.ruleForm.mobiles.join(',');
      } else {
        data.mobiles = null;
      }

      var api = this.ruleForm.id ? '/api/category/label/editUserCategoryLabel' : '/api/category/label/addUserCategoryLabel';
      this.apiPost(api, data).then(function (res) {
        _this5.$router.go(-1);

        _this5.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;