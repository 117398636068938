"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DataTable',
  props: ['tableData', 'columns', 'loading', 'pageObj', 'height'],
  data: function data() {
    return {
      pageSizes: [10, 20, 30, 40, 50, 2000]
    };
  },
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      this.$emit('pagination', {
        pageNo: this.pageObj.pageNo,
        pageSize: val
      });

      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.$emit('pagination', {
        pageNo: val,
        pageSize: this.pageObj.pageSize
      });

      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    }
  }
};
exports.default = _default;