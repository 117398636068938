"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      isUpdate: 0,
      templateDownload: {
        url: "".concat(process.env.BASE_URL, "xlsx/mallGoodsGroup.xlsx"),
        name: "商品补录模板.xlsx",
        btn: "下载模板"
      },
      formType: null,
      formData: [{
        label: '团购方：',
        type: 'input',
        placeholder: '请输入团购方',
        value: 'buyer'
      }, {
        label: '团购时间：',
        type: 'startTime',
        placeholder: '请输入团购方',
        value: 'tradeTime',
        dataType: 'date',
        valueFormat: 'yyyy-MM-dd'
      }, {
        label: '状态：',
        type: 'radio',
        placeholder: '请选择状态',
        value: 'status',
        selectList: [{
          label: '正常',
          value: 1
        }, {
          label: '作废',
          value: 2
        }]
      }, {
        slot: 'groupOrderItems'
      }, {
        label: '备注：',
        type: 'input',
        placeholder: '请输入备注',
        value: 'remark'
      }],
      ruleForm: {
        id: null,
        buyer: null,
        tradeTime: null,
        remark: null,
        status: null,
        groupOrderItems: []
      },
      tableForm: [{
        prop: 'productName',
        label: '商品名称',
        width: 100,
        type: 'input'
      }, {
        prop: 'productCode',
        label: '商品编码',
        width: 100,
        type: 'input'
      }, {
        prop: 'productSource',
        label: '货源地',
        width: 80,
        type: 'input'
      }, {
        prop: 'productCategory',
        label: '商品分类',
        width: 100,
        type: 'input'
      }, {
        prop: 'productNum',
        label: '商品数量',
        width: 50,
        type: 'inputNumber'
      }, {
        prop: 'itemSalePrice',
        label: '销售单价',
        width: 50,
        type: 'inputNumber'
      }, {
        prop: 'saleAmount',
        label: '销售总价',
        width: 80,
        type: 'inputNumber'
      }, {
        prop: 'itemSettlementAmount',
        label: '结算单价',
        width: 50,
        type: 'inputNumber'
      }, {
        prop: 'settlementAmount',
        label: '结算总价',
        width: 80,
        type: 'inputNumber'
      }],
      rules: {
        buyer: [{
          required: true,
          message: '请输入团购方'
        }],
        tradeTime: [{
          required: true,
          message: '请选择团购时间'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        groupOrderItems: [{
          required: true,
          message: '请添加团购商品'
        }],
        productName: [{
          required: true,
          message: '请输入商品名称'
        }],
        productCode: [{
          required: true,
          message: '请输入商品编码'
        }],
        productSource: [{
          required: true,
          message: '请输入货源地'
        }],
        productCategory: [{
          required: true,
          message: '请输入商品分类'
        }],
        productNum: [{
          required: true,
          message: '请输入商品数量'
        }],
        itemSalePrice: [{
          required: true,
          message: '请输入销售单价'
        }],
        saleAmount: [{
          required: true,
          message: '请输入销售总价'
        }],
        itemSettlementAmount: [{
          required: true,
          message: '请输入结算单价'
        }],
        settlementAmount: [{
          required: true,
          message: '请输入结算总价'
        }]
      }
    };
  },
  computed: {
    goodsAllData: function goodsAllData() {
      console.log("sdsdfsdf");

      if (this.ruleForm.groupOrderItems.length > 0) {
        var num = this.isUpdate;
        console.log(num);
        var saleAmountAll = 0;
        var settlementAmountAll = 0;
        var productNumAll = 0;
        this.ruleForm.groupOrderItems.forEach(function (item) {
          saleAmountAll += item.saleAmount;
          settlementAmountAll += item.settlementAmount;
          productNumAll += item.productNum;
        });
        return {
          saleAmountAll: saleAmountAll,
          settlementAmountAll: settlementAmountAll,
          productNumAll: productNumAll
        };
      } else {
        console.log("sdsdfsdf");
        return {
          saleAmountAll: 0,
          settlementAmountAll: 0,
          productNumAll: 0
        };
      }
    }
  },
  created: function created() {
    var _this = this;

    this.formType = this.$route.query.type;
    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/mall/group-order/listGroupOrderItemByGroupOrderId/' + this.ruleForm.id).then(function (res) {
        res.data.groupOrderItems = res.data.groupOrderItemList;
        _this.ruleForm = res.data; // this.ruleForm.groupOrderItems = JSON.parse(JSON.stringify(res.data.groupOrderItemList))

        delete _this.ruleForm.groupOrderItemList;
      });
    }
  },
  methods: {
    clearGoods: function clearGoods() {
      this.ruleForm.groupOrderItems = [];
      this.isUpdate++;
    },
    delGoodsItem: function delGoodsItem(item, index) {
      this.ruleForm.groupOrderItems.splice(index, 1);
      this.isUpdate++;
    },
    addItems: function addItems() {
      this.ruleForm.groupOrderItems.push({
        "productName": null,
        "productCode": null,
        "productSource": null,
        "productCategory": null,
        "productNum": undefined,
        "itemSalePrice": undefined,
        "saleAmount": undefined,
        "itemSettlementAmount": undefined,
        "settlementAmount": undefined
      });
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    handleUploadSuccess: function handleUploadSuccess(data) {
      var _this2 = this;

      var groupOrderItems = [];
      data.results.forEach(function (col) {
        _this2.ruleForm.groupOrderItems.push({
          productName: col['商品名称'],
          productCode: col['商品编码'],
          productSource: col['货源地'],
          productCategory: col['商品分类'],
          productNum: col['商品数量'],
          itemSalePrice: col['销售单价'],
          saleAmount: col['销售总价'],
          itemSettlementAmount: col['结算单价'],
          settlementAmount: col['结算总价']
        });
      });
      this.ruleForm.groupOrderItems = [].concat((0, _toConsumableArray2.default)(this.ruleForm.groupOrderItems), groupOrderItems);
      this.isUpdate++;
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.apiPost('/api/mall/group-order/createGroupOrder', this.ruleForm).then(function (res) {
        _this3.$router.go(-1);

        _this3.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;