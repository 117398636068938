"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _api = require("./api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      info: {},
      memberType: {
        /* 1，普通 2 ,365 3,一星 4，二星 5三星*/
        1: '普通',
        2: '365会员',
        3: '一星Lavant',
        4: '二星Lavant',
        5: '三星Lavant'
      },
      SRCPREFIX: this.$store.state.user.imgSrc,
      images: []
    };
  },
  computed: {
    list: function list() {
      if (this.info.orderInfo) {
        return JSON.parse(this.info.orderInfo);
      }

      return [];
    }
  },
  created: function created() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;

      (0, _api.parkOrderPage)({
        pageSize: 1,
        pageNo: 1,
        orderNo: this.$route.query.id
      }).then(function (res) {
        var data = res.data.items[0];

        if (data.shoppingReceipt) {
          var imgs = JSON.parse(data.shoppingReceipt);
          var images = [];
          imgs.forEach(function (img) {
            images.push(_this.SRCPREFIX + img);
          });
          console.log(images);
          _this.images = images;
        }

        _this.info = data;
      });
    }
  }
};
exports.default = _default;