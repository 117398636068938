"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addVipPlus = addVipPlus;
exports.editVipPlus = editVipPlus;
exports.getVipPlus = getVipPlus;
exports.listGroupCodePage = listGroupCodePage;
exports.listMaoTaiVipPage = listMaoTaiVipPage;
exports.listVipPlus = listVipPlus;
exports.payMemberAdmin365 = payMemberAdmin365;
exports.refundMaoTaiVip = refundMaoTaiVip;

var _request = _interopRequireDefault(require("@/utils/request"));

/* 
 2、后台365付费会员充值：/api/pay/payMemberAdmin365
 {"number":"数量","note":"备注"}
 */
function payMemberAdmin365(data) {
  return (0, _request.default)({
    url: '/api/pay/payMemberAdmin365',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
 */


function listMaoTaiVipPage(data) {
  return (0, _request.default)({
    url: '/api/vip/listMaoTaiVipPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
/api/vip/refundMaoTaiVip?buyUid=xxx& orderId = xxx  茅台MIKA  PLUS退费
 */


function refundMaoTaiVip(data) {
  return (0, _request.default)({
    url: '/api/vip/refundMaoTaiVip',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*

 */


function listGroupCodePage(data) {
  return (0, _request.default)({
    url: '/api/vipGroupCode/listGroupCodePage',
    method: 'post',
    loading: "loading",
    data: data
  });
}
/*
1、列表：/api/vipPlus/listVipPlus
{"plusStatus":"plus状态(0：停用 1：启用)"}

2、明细：/api/vipPlus/getVipPlus/{id}

3、新增：/api/vipPlus/addVipPlus
{"typeName":"类型名称",
"validDay":"生效天数",
"saleAmount":"销售金额",
"plusStatus":"plus状态(0：停用 1：启用)",

"putNumber":"投放数量",

"firstCoupons":[""],
"sendCoupons":[""],

"plusIcon":"plus图标",

"plusInterests":"plus权益"}

4、新增：/api/vipPlus/editVipPlus
{"id":"id","typeName":"类型名称","validDay":"生效天数","saleAmount":"销售金额","plusStatus":"plus状态(0：停用 1：启用)","putNumber":"投放数量","firstCoupons":[""],"sendCoupons":[""],"plusIcon":"plus图标","plusInterests":"plus权益"}

 */


function listVipPlus(data) {
  return (0, _request.default)({
    url: '/api/vipPlus/listVipPlus',
    method: 'post',
    loading: "loading",
    data: data
  });
}

function getVipPlus(data) {
  return (0, _request.default)({
    url: '/api/vipPlus/getVipPlus/' + data,
    method: 'post',
    loading: "loading"
  });
}

function addVipPlus(data) {
  return (0, _request.default)({
    url: '/api/vipPlus/addVipPlus',
    method: 'post',
    loading: "loading",
    data: data
  });
}

function editVipPlus(data) {
  return (0, _request.default)({
    url: '/api/vipPlus/editVipPlus',
    method: 'post',
    loading: "loading",
    data: data
  });
}