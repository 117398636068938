"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _api = require("./api.js");

var _api2 = require("../category/api.js");

var _api3 = require("../class/api.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    cardSelect: _radioItem.default
  },
  data: function data() {
    return {
      couponShow: false,
      storeList: [],
      classList: [],
      rules: {
        planName: [{
          required: true,
          message: '请输入计划名称'
        }],
        cardCategoryId: [{
          required: true,
          message: '请选择卡类型'
        }],
        cardKindsId: [{
          required: true,
          message: '请选择卡种类'
        }],
        cardNumber: [{
          required: true,
          message: '请输入卡数量'
        }],
        cardSegmentNoStart: [{
          required: true,
          message: '请输入起始号段'
        }],
        cardSegmentNoEnd: [{
          required: true,
          message: '请输入结束号段'
        }],
        makeFactoryName: [{
          required: true,
          message: '请输入制卡商'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }]
      },
      ruleForm: {
        cardCategoryId: null,
        cardKindsId: null,
        planName: null,
        cardNumber: null,
        cardSegmentNoStart: null,
        cardSegmentNoEnd: null,
        makeFactoryName: null,
        remark: null,
        bindCardNo: null
      },
      segmentNo: null,
      cardLimitPrice: null,
      formClass: false
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.cardCategoryId = this.$route.query.cardCategoryId ? Number(this.$route.query.cardCategoryId) : null;
    (0, _api2.categoryPage)({
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      _this.storeList = res.data.records;
    });

    if (this.ruleForm.cardCategoryId) {
      this.formClass = true;
      this.getClass(this.ruleForm.cardCategoryId);
      this.ruleForm.cardKindsId = this.$route.query.cardKindsId ? Number(this.$route.query.cardKindsId) : null;
      this.classChange(this.ruleForm.cardKindsId);
      return;
      (0, _api.getKinds)(this.ruleForm.id).then(function (res) {
        _this.getClass(res.data.cardCategoryId);

        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    getCardShow: function getCardShow() {
      this.$refs.cardRef.getList('card', this.ruleForm.bindCardNo);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      this.ruleForm.bindCardNo = e.uuid;
      this.couponShow = false;
    },
    formatZero: function formatZero(num, len) {
      if (String(num).length > len) return num;
      return (Array(len).join(0) + num).slice(-len);
    },
    cardNumberChange: function cardNumberChange(e) {
      var _this2 = this;

      this.$nextTick(function () {
        if (_this2.ruleForm.cardSegmentNoStart) {
          _this2.ruleForm.cardSegmentNoEnd = _this2.bigNumberAdd(String(_this2.ruleForm.cardSegmentNoStart), String(_this2.ruleForm.cardNumber - 1));
        }
      });
    },
    classChange: function classChange(e) {
      var _this3 = this;

      (0, _api.getStartCardByKindsId)(e).then(function (res) {
        console.log(res);
        _this3.ruleForm.cardSegmentNoStart = res.data;
      });
    },
    getClass: function getClass(e, type) {
      var _this4 = this;

      /* if (type === 'get' && e) {
        const cateData = this.storeList.find((item) => {
          return item.id === e
        })
        this.ruleForm.cardSegmentNoStart = ''
        this.ruleForm.cardSegmentNoEnd = ''
        this.segmentNo = String(cateData.segmentNo)
      } */
      this.classList = [];
      this.ruleForm.cardKindsId = null;
      (0, _api3.classPage)({
        pageNo: 1,
        pageSize: 100,
        cardCategoryId: e
      }).then(function (res) {
        _this4.classList = res.data.records;
      });
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    // 三级地址选择
    regionDataChange: function regionDataChange(e) {
      var arr = this.$refs['state'].getCheckedNodes(false)[0].pathLabels || [];
      this.ruleForm.detailAddress = arr.join('');
    },
    clearForm: function clearForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this5.ruleForm));
          (0, _api.addKinds)(data).then(function (res) {
            _this5.$message({
              type: 'success',
              message: '保存成功!'
            });

            if (_this5.formClass) {
              _this5.$router.replace('/card/planList');
            } else {
              _this5.$router.go(-1);
            }
          });
          return;

          if (_this5.ruleForm.id) {
            data.id = _this5.ruleForm.id;
            (0, _api.editKinds)(data).then(function (res) {
              _this5.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this5.$router.go(-1);
            });
          } else {
            (0, _api.addKinds)(data).then(function (res) {
              _this5.$router.go(-1);

              _this5.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;