"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

/** When your routing table is too long, you can split it into small modules **/
var legouGoodsRouter = {
  path: 'goods',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/svip/index.vue'));
    });
  },
  redirect: '/legou/goods/tab',
  name: 'legouGoods',
  meta: {
    title: '商品管理'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./tab.vue"));
      });
    },
    name: 'legouGoodsTab',
    meta: {
      title: '商品管理'
    }
  }, {
    path: 'class',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./class/list.vue"));
      });
    },
    name: 'legouGoodsClass',
    meta: {
      title: '分类管理',
      keepAlive: true
    }
  }, {
    path: 'spec',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./spec/list.vue"));
      });
    },
    name: 'legouGoodsSpec',
    meta: {
      title: '规格管理',
      keepAlive: true
    }
  }, {
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./goods/list.vue"));
      });
    },
    name: 'legouGoodsGoods',
    meta: {
      title: '商品管理',
      keepAlive: true,
      addTxt: "新增商品",
      addUrl: "/legou/goods/add",
      isOneRequest: 'no'
    }
  }, {
    path: 'add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./goods/add.vue"));
      });
    },
    name: 'legouGoodsGoodsAdd',
    meta: {
      title: '新增商品',
      activeMenu: '/legou/goods/goods'
    }
  }, {
    path: 'dea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./goods/dea.vue"));
      });
    },
    name: 'legouGoodsGoodsdea',
    meta: {
      title: '商品详情',
      activeMenu: '/legou/goods/goods'
    }
  }, {
    path: 'brand',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./brand/list.vue"));
      });
    },
    name: 'legouGoodsbrand',
    meta: {
      title: '品牌管理',
      keepAlive: true
    }
  }]
};
var _default = legouGoodsRouter;
exports.default = _default;