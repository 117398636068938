"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '销售管理',
      list: [{
        tit: '触发投放方案',
        txtArr: '<p>触发投放方案</p>',
        imgPath: require('@/assets/tab/activity/1.png'),
        path: '/coupon/couponPut/touchPut'
      }]
    };
  }
};
exports.default = _default;