var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-setting-intro" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("荔星荟设置")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20, type: "flex" } },
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    "label-width": "150px",
                    model: _vm.ruleForm,
                    rules: _vm.rules
                  }
                },
                [
                  _c("h4", { staticClass: "form_title" }, [
                    _vm._v("荔星荟介绍")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: { label: "介绍1：", prop: "introduce1" }
                    },
                    [
                      _c("Tinymce", {
                        ref: "editor1",
                        attrs: { width: "100%", height: 300 },
                        model: {
                          value: _vm.ruleForm.introduce1,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "introduce1", $$v)
                          },
                          expression: "ruleForm.introduce1"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: {
                        label: "入会条件设置：",
                        prop: "joinConditionList"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "svip-setting-intro_form" },
                        _vm._l(_vm.ruleForm.joinConditionList, function(
                          condition,
                          idx
                        ) {
                          return _c(
                            "el-form-item",
                            {
                              key: idx,
                              staticClass: "svip-setting-intro_form_select-pic"
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "joinConditionList." + idx + ".pic",
                                    rules: {
                                      required: true,
                                      message: "请上传图片"
                                    }
                                  }
                                },
                                [
                                  _c("add-load", {
                                    staticClass: "img_list",
                                    attrs: {
                                      width: 345,
                                      height: 175,
                                      cover: condition.pic
                                    },
                                    on: {
                                      setCover: function($event) {
                                        return _vm.setSelectPic($event, idx)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticStyle: { color: "#999999" } },
                                    [_vm._v("建议图片尺寸：宽690 不限高度")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("NavigateSelect", {
                                attrs: {
                                  dataSource: condition,
                                  propPrefix: "joinConditionList." + idx
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form_item",
                      attrs: { label: "介绍2：", prop: "introduce2" }
                    },
                    [
                      _c("Tinymce", {
                        ref: "editor2",
                        attrs: { width: "100%", height: 300 },
                        model: {
                          value: _vm.ruleForm.introduce2,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "introduce2", $$v)
                          },
                          expression: "ruleForm.introduce2"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("\n            保存\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("h4", { staticClass: "form_title" }, [_vm._v("预览图")]),
            _vm._v(" "),
            _c("div", { staticClass: "svip-intro" }, [
              _c("div", {
                staticClass: "svip-intro_rich",
                domProps: { innerHTML: _vm._s(_vm.ruleForm.introduce1) }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "svip-intro_join-condition" }, [
                _c("div", { staticClass: "svip-intro_title" }, [
                  _c("div", { staticClass: "svip-intro_title_text" }, [
                    _vm._v("入会条件")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "svip-intro_join-condition_content",
                    style:
                      _vm.ruleForm.joinConditionList.length <= 3 &&
                      "justify-content: space-around;"
                  },
                  _vm._l(_vm.ruleForm.joinConditionList, function(
                    condition,
                    index
                  ) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "svip-intro_join-condition_content_item"
                      },
                      [
                        condition.pic
                          ? _c("el-image", {
                              staticClass:
                                "svip-intro_join-condition_content_item_img",
                              attrs: { src: _vm.imgSrc + condition.pic }
                            })
                          : _c(
                              "div",
                              { staticClass: "svip-tutorial_image-empty" },
                              [_vm._v("暂无图片")]
                            ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "svip-intro_join-condition_content_item_text"
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(condition.name) +
                                "\n              "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "svip-intro_rich",
                domProps: { innerHTML: _vm._s(_vm.ruleForm.introduce2) }
              })
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }