"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    tabList: function tabList() {
      var _this = this;

      return this.list.filter(function (item) {
        return !item.limit || _this.setMenuPerms(item.limit);
      });
    }
  },
  data: function data() {
    return {};
  },
  watch: {},
  methods: {
    setTab: function setTab(path) {
      if (this.type === 'replace') {
        this.$router.replace(path);
      } else {
        this.$router.push(path);
      }
    }
  }
};
exports.default = _default2;