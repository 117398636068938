"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addLpStore = addLpStore;
exports.getLpStore = getLpStore;
exports.listLpStorePage = listLpStorePage;
exports.updateLpStore = updateLpStore;

var _request = _interopRequireDefault(require("@/utils/request"));

function listLpStorePage(data) {
  return (0, _request.default)({
    url: '/api/lpStore/listLpStorePage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function updateLpStore(data) {
  return (0, _request.default)({
    url: '/api/lpStore/updateLpStore',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getLpStore(data) {
  return (0, _request.default)({
    url: '/api/lpStore/getLpStore',
    method: 'get',
    loading: 'loading',
    params: data
  });
}

function addLpStore(data) {
  return (0, _request.default)({
    url: '/api/lpStore/addLpStore',
    method: 'post',
    loading: 'loading',
    data: data
  });
}