"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _deposit = require("@/api/deposit.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
  补365奖励：api/cardTrade/recharge365Reward
  {"mobile":"18690730433","orderNo":"OD106332407510114304,OD106332407510114305"}
  */
var _default = {
  data: function data() {
    return {
      rules: {
        couponUuids: [{
          required: true,
          message: '请输入手机号'
        }],
        storeCardUUid: [{
          required: true,
          message: '请输入订单号'
        }]
      },
      ruleForm: {
        couponUuids: null,
        storeCardUUid: null
      }
    };
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.apiPost('/api/coupon/switchCoupon2StoreCard', {
            couponUuids: _this.ruleForm.couponUuids.split(','),
            storeCardUUid: _this.ruleForm.storeCardUUid
          }).then(function (res) {
            console.log(res);
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;