"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    AppLink: _Link.default
  },
  data: function data() {
    return {
      obj: {
        pageNo: 1,
        pageSize: 10,
        status: 0,
        groupName: ''
      },
      options: [{
        value: 0,
        label: '全部状态'
      }, {
        value: 1,
        label: '启用'
      }, {
        value: -1,
        label: '停用'
      }],
      total: 0,
      tableData: []
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init(e) {
      var _this = this;

      if (e) {
        this.obj.pageNo = e.page;
        this.obj.pageSize = e.limit;
      }

      this.obj.groupName = this.obj.groupName.trim();
      this.openLoading();
      (0, _coupon.listColumnGroupPage)(this.obj).then(function (res) {
        console.log(res);

        _this.openLoading().close();

        _this.tableData = res.data.records;
        _this.total = res.data.total;

        _this.tableData.forEach(function (item) {
          switch (item.status) {
            case 1:
              item.statuTxt = '启用';
              break;

            default:
              item.statuTxt = '停用';
              break;
          }
        });
      }).catch(function (err) {
        _this.openLoading().close();
      });
    },
    remove: function remove() {
      this.obj.pageNo = 1;
      this.obj.pageSize = 10;
      this.obj.status = 0;
      this.obj.groupName = '';
      this.init();
    },
    goAdd: function goAdd() {
      this.$router.push('/coupon/coupon4/editorColumn');
    }
  }
};
exports.default = _default;