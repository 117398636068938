"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      columns: [{
        label: "订单号",
        prop: "tradeOrderNo",
        width: 100
      }, {
        label: "券包编码",
        prop: "couponBagUid",
        width: 100
      }, {
        label: "购买人",
        prop: "mobile",
        width: 100
      }, {
        label: "购买数量",
        prop: "byNum",
        width: 100
      }, {
        slot: 'buyType'
      }, {
        label: "购买时间",
        prop: "buyTime",
        width: 100
      }],
      searchData: [{
        type: 'input',
        placeholder: '券包编码',
        value: 'couponBagUid'
      }, {
        type: 'input',
        placeholder: '用户手机号',
        value: 'mobile'
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }],
      SRCPREFIX: this.$store.state.user.imgSrc,
      memberType: {
        /* 1，普通 2 ,365 3,一星 4，二星 5三星*/
        1: '普通',
        2: '365会员',
        3: '一星Lavant',
        4: '二星Lavant',
        5: '三星Lavant'
      }
    };
  }
};
exports.default = _default;