var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit print_hide" }, [_vm._v("日志详情")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          staticClass: "print",
          staticStyle: { "margin-top": "10px" },
          attrs: { "label-width": "120px", size: "small" }
        },
        [
          _c("el-form-item", { attrs: { label: "操作人：" } }, [
            _vm._v(_vm._s(_vm.data.userName))
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "操作人手机号：" } }, [
            _vm._v(_vm._s(_vm.data.userMobile))
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "请求IP地址：" } }, [
            _vm._v(_vm._s(_vm.data.reqIp))
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "请求路由：" } }, [
            _vm._v(_vm._s(_vm.data.reqUrl))
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "操作描述：" } }, [
            _vm._v(_vm._s(_vm.data.executeRemark))
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "操作时间：" } }, [
            _vm._v(_vm._s(_vm.data.createTime))
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "请求参数：" } },
            [_c("JsonView", { attrs: { json: _vm.data.reqParams } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }