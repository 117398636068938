var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    " + _vm._s(_vm.$route.meta.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "enterpriseForm",
          attrs: {
            model: _vm.enterprise,
            rules: _vm.rules,
            "label-width": "200px",
            size: "small"
          }
        },
        [
          _vm._l(_vm.columns, function(col) {
            return _c(
              "el-form-item",
              {
                key: col.prop,
                attrs: { prop: col.prop, label: col.label + ":" }
              },
              [_vm._v("\n      " + _vm._s(_vm.enterprise[col.prop]) + "\n    ")]
            )
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "proportion", label: "可开票金额比例:" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "400px" },
                  attrs: { placeholder: "商品编码" },
                  model: {
                    value: _vm.enterprise.proportion,
                    callback: function($$v) {
                      _vm.$set(_vm.enterprise, "proportion", _vm._n($$v))
                    },
                    expression: "enterprise.proportion"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "el-input__icon",
                      attrs: { slot: "suffix" },
                      slot: "suffix"
                    },
                    [_vm._v("%")]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "remark", label: "备注:" } },
            [
              _c("el-input", {
                staticStyle: { width: "600px" },
                attrs: {
                  type: "textarea",
                  placeholder: "备注",
                  autosize: { minRows: 4 }
                },
                model: {
                  value: _vm.enterprise.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.enterprise, "remark", $$v)
                  },
                  expression: "enterprise.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handSubmit } },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.handCancel } },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }