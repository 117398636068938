"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.search");

var _api = _interopRequireDefault(require("./api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      query: {},
      tableData: [{
        id: 2,
        date: 1,
        name: 'asd',
        address: 'asd'
      }],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageTotal: 400
      }
    };
  },
  methods: {
    onReset: function onReset() {
      this.query = {};
    },
    onCreate: function onCreate() {
      this.$router.push({
        path: '/deposit/deposit-entity-rebate/create'
      });
    },
    onSearch: function onSearch() {
      this.list = _api.default.search(this.query, this.pagination);
    },
    onEdit: function onEdit(item) {
      this.$router.push({
        path: '/deposit/deposit-entity-rebate/edit',
        query: {
          id: item.id
        }
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.onSearch();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.onSearch();
    },
    onEnable: function onEnable() {}
  }
};
exports.default = _default;