var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _vm.setMenuPerms("addAll:user")
            ? _c(
                "el-form-item",
                { attrs: { label: "机构类型：", prop: "sysStore" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.sysStore,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "sysStore", $$v)
                        },
                        expression: "ruleForm.sysStore"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("全平台")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("选择机构")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.sysStore == 2 || !_vm.setMenuPerms("addAll:user")
            ? _c(
                "el-form-item",
                { attrs: { label: "所属机构：", prop: "deptId" } },
                [
                  _c(
                    "div",
                    { staticClass: "li_div" },
                    [
                      _c("el-tree", {
                        ref: "treeForm",
                        staticClass: "li_tree ",
                        attrs: {
                          "default-expanded-keys": _vm.defaultKeys,
                          "default-checked-keys": _vm.defaultKeys,
                          data: _vm.dataTree,
                          "show-checkbox": "",
                          "node-key": "id",
                          props: _vm.defaultProps,
                          "check-strictly": true,
                          "expand-on-click-node": false
                        },
                        on: { check: _vm.handleClick }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属品牌：", prop: "brandRemark" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入所属品牌" },
                model: {
                  value: _vm.ruleForm.brandRemark,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "brandRemark",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.brandRemark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "姓名：", prop: "username" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入用户名称" },
                model: {
                  value: _vm.ruleForm.username,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "username",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.username"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "登录账号：", prop: "account" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入用户用户名(账号)" },
                model: {
                  value: _vm.ruleForm.account,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "account",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.account"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.ruleForm.userId
            ? _c(
                "el-form-item",
                { attrs: { label: "用户密码：", prop: "password" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { placeholder: "请输入用户密码" },
                    model: {
                      value: _vm.ruleForm.password,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "password", $$v)
                      },
                      expression: "ruleForm.password"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "工号：", prop: "jobNumber" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { type: "number", placeholder: "请输入用户工号" },
                model: {
                  value: _vm.ruleForm.jobNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "jobNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.jobNumber"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "手机号：", prop: "mobile" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { type: "number", placeholder: "请输入用户手机号" },
                model: {
                  value: _vm.ruleForm.mobile,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "mobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.mobile"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "职务：" } },
            [
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.changePosition },
                  model: {
                    value: _vm.ruleForm.position,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "position", $$v)
                    },
                    expression: "ruleForm.position"
                  }
                },
                _vm._l(_vm.positions, function(item, index) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.index,
                      staticClass: "checkboxs",
                      attrs: { value: item.value, label: item.value }
                    },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "第三方商户：" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.merchantIds,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "merchantIds", $$v)
                    },
                    expression: "ruleForm.merchantIds"
                  }
                },
                _vm._l(_vm.merchantList, function(item, index) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.id,
                      staticClass: "checkboxs",
                      attrs: { value: item.id, label: item.id }
                    },
                    [_vm._v(_vm._s(item.merchantName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态：", prop: "status" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status"
                  }
                },
                [_vm._v("启用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: -1 },
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status"
                  }
                },
                [_vm._v("停用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "对应角色：", prop: "roleIds" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.roleIds,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "roleIds", $$v)
                    },
                    expression: "ruleForm.roleIds"
                  }
                },
                _vm._l(_vm.dataRole, function(item) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.roleId,
                      staticClass: "checkboxs",
                      attrs: { value: item.roleId, label: item.roleId }
                    },
                    [_vm._v(_vm._s(item.roleName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "供应商/货源地：" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.sourceIds,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "sourceIds", $$v)
                    },
                    expression: "ruleForm.sourceIds"
                  }
                },
                _vm._l(_vm.sourceList, function(item) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.id,
                      staticClass: "checkboxs",
                      attrs: { value: item.id, label: item.id }
                    },
                    [_vm._v(_vm._s(item.productSourceName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即创建")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      false
        ? _c("div", { staticClass: "departmentDea_input" }, [
            _c(
              "li",
              [
                _c("p", { staticClass: "li_tit" }),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.next } },
                  [_vm._v("保存")]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }