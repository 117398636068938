var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [
        _vm._v(
          _vm._s(_vm.ruleForm.storeCode ? "编辑" : "新增") + "蜻蜓收银活动"
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "storeCode",
                  attrs: { label: "所属门店：", prop: "storeCode" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: {
                        size: "small",
                        placeholder: "所属门店",
                        filterable: "",
                        autocomplete: "off"
                      },
                      model: {
                        value: _vm.ruleForm.storeCode,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "storeCode", $$v)
                        },
                        expression: "ruleForm.storeCode"
                      }
                    },
                    _vm._l(_vm.storeList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.storeId,
                          attrs: {
                            label:
                              item.shortName + "（" + item.storeCode + "）",
                            value: item.storeCode
                          }
                        },
                        [
                          _vm._v(
                            "\n             " +
                              _vm._s(
                                item.shortName + "（" + item.storeCode + "）"
                              ) +
                              "\n           "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "discountFlag",
                  attrs: { label: "优惠标识：", prop: "discountFlag" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.discountFlag,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "discountFlag", $$v)
                      },
                      expression: "ruleForm.discountFlag"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "limitGoods",
                  attrs: { label: "限制商品：", prop: "limitGoods" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { type: "textarea", autosize: { maxRows: 10 } },
                    model: {
                      value: _vm.ruleForm.limitGoods,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "limitGoods", $$v)
                      },
                      expression: "ruleForm.limitGoods"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("多个限制商品使用英文逗号“,”隔开")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "startTime",
                      attrs: { label: "活动时间：", prop: "startTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择开始时间"
                        },
                        model: {
                          value: _vm.ruleForm.startTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "startTime", $$v)
                          },
                          expression: "ruleForm.startTime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "endTime",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { "label-width": "0", prop: "endTime" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: !_vm.ruleForm.startTime,
                          "picker-options": _vm.pickerTime,
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": "23:59:59",
                          placeholder: "选择结束时间"
                        },
                        model: {
                          value: _vm.ruleForm.endTime,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "endTime", $$v)
                          },
                          expression: "ruleForm.endTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }