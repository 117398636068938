var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增/编辑理财产品 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "产品名称：", prop: "productName" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "请输入网点名称" },
                model: {
                  value: _vm.ruleForm.productName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "productName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.productName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态：", prop: "productStatus" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.productStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "productStatus", $$v)
                    },
                    expression: "ruleForm.productStatus"
                  }
                },
                [_vm._v("开放申请")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.ruleForm.productStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "productStatus", $$v)
                    },
                    expression: "ruleForm.productStatus"
                  }
                },
                [_vm._v("下线")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "开放网点：", prop: "storeIds" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    multiple: "",
                    filterable: "",
                    clearable: "",
                    placeholder: "开放网点"
                  },
                  model: {
                    value: _vm.ruleForm.storeIds,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "storeIds", $$v)
                    },
                    expression: "ruleForm.storeIds"
                  }
                },
                _vm._l(_vm.storeList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.storeName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "开放申请时间：", prop: "applyTime" } },
            [
              _c("el-date-picker", {
                staticClass: "form_input",
                staticStyle: { width: "100%" },
                attrs: {
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择日期时间"
                },
                model: {
                  value: _vm.ruleForm.applyTime,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "applyTime", $$v)
                  },
                  expression: "ruleForm.applyTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "下线时间：", prop: "offlineTime" } },
            [
              _c("el-date-picker", {
                staticClass: "form_input",
                staticStyle: { width: "100%" },
                attrs: {
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择日期时间"
                },
                model: {
                  value: _vm.ruleForm.offlineTime,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "offlineTime", $$v)
                  },
                  expression: "ruleForm.offlineTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "限制会员类型：", prop: "limitTypes" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.limitTypes,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "limitTypes", $$v)
                    },
                    expression: "ruleForm.limitTypes"
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    { staticClass: "checkboxs", attrs: { value: 1, label: 1 } },
                    [_vm._v("全部会员")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    { staticClass: "checkboxs", attrs: { value: 2, label: 2 } },
                    [_vm._v("365会员")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    { staticClass: "checkboxs", attrs: { value: 3, label: 3 } },
                    [_vm._v("Plus会员")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "限制消费额：", prop: "limitConsume" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { type: "number", placeholder: "请输入网点名称" },
                  model: {
                    value: _vm.ruleForm.limitConsume,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "limitConsume",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.limitConsume"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("万元")])],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "产品要素图：", prop: "productImg" } },
            [
              _c("add-load", {
                attrs: {
                  width: 250,
                  height: 180,
                  cover: _vm.ruleForm.productImg
                },
                on: { setCover: _vm.setCover }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "remark" }, [_vm._v("750x500")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "产品详情：", prop: "productDetail" } },
            [
              _c("Tinymce", {
                attrs: { width: 800 },
                model: {
                  value: _vm.ruleForm.productDetail,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "productDetail", $$v)
                  },
                  expression: "ruleForm.productDetail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "info" },
                  on: {
                    click: function($event) {
                      return _vm.clearForm("ruleForm")
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }