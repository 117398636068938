"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.regexp.match");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _user = require("@/api/user");

var _deposit = require("@/api/deposit");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    UploadExcelComponent: _index.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    var _self = this;

    return {
      downloadUrl: "".concat(process.env.BASE_URL, "xlsx/giftToken.xlsx"),
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var effStartTime = new Date(_self.obj.effStartTime).getTime();
          return time.getTime() < effStartTime;
        }
      },
      selectEffMembers: [],
      defaultProps: {
        children: 'childNodes',
        label: function label(a, b) {
          return a.name + '   (' + a.code + ')';
        }
      },
      inputVisible: false,
      inputValue: '',
      rules: {
        type: [{
          required: true,
          message: '请选择是否权益卡'
        }],
        ifRechargeAmount: [{
          required: true,
          message: '请选择是否可输入'
        }],
        album: [{
          required: true,
          message: '请上传提货卡卡面'
        }],
        name: [{
          required: true,
          message: '请输入卡名称'
        }],
        classId: [{
          required: true,
          message: '请选择卡分类'
        }],
        cover: [{
          required: true,
          message: '请上传卡封面'
        }],
        description: [{
          required: true,
          message: '请输入一句话文案'
        }],
        number: [{
          required: true,
          message: '请输入发行数量'
        }],
        rechargeRmount: [{
          required: true,
          message: '请输入可充值金额'
        }],
        scopeType: [{
          required: true,
          message: '请选择适用范围'
        }],
        remark: [{
          required: true,
          message: '请输入卡说明'
        }],
        institutionIds: [{
          required: true,
          message: '请选择机构'
        }],
        cardValue: [{
          required: true,
          message: '请输入卡面值'
        }],
        usableType: [{
          required: true,
          message: '请选择是否可用商品码'
        }],
        goodsIds: [{
          required: true,
          message: '请上传商品码'
        }],
        validTimeType: [{
          required: true,
          message: '请选择类型'
        }],
        effStartTime: [{
          required: true,
          message: '请选择起始时间'
        }],
        effEndTime: [{
          required: true,
          message: '请选择结束时间'
        }],
        validDay: [{
          required: true,
          message: '请输入有效天数'
        }]
      },
      obj: {
        /*
            Integer validTimeType;  有效时间类型(1-时间段，2-领取后N天内有效)
             Integer validDay; 有效天数
             String usableType;  可用类型(1-可用，2-不可用)
             String goodsIds; 限制商品码
             effStartTime effEndTime
            */
        validTimeType: null,
        effStartTime: null,
        effEndTime: null,
        validDay: null,
        usableType: null,
        goodsIds: [],
        id: '',
        //	主键（编辑标识）
        code: '',
        //	卡号(线上无卡号)
        name: '',
        //		卡名称
        classId: '',
        //		卡分类
        type: 3,
        //		卡类型(0:未知、1:线上、2:线下)
        cover: 'mika/pro/2021-11-22/ed22987555bf4ac78216fb1d04816330.png',
        //		卡封面
        description: '',
        //			一句话文案
        number: 0,
        //			发行数量
        rechargeRmount: null,
        //			可充值金额
        ifRechargeAmount: '-1',
        //			可输入其他金额(1:是 -1：否)
        scopeType: 1,
        //			适用范围(1:全部可用 、2:部分可用)
        remark: '',
        //			卡说明
        memberIds: [],
        //			会员卡id
        institutionIds: [],
        //				机构id
        cardValue: 0,
        //				卡面值
        album: [] // 提货卡 卡面

      },
      title: '新增提货卡',
      id: '',
      options: []
    };
  },
  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
          header = _ref.header;
      var arr = [];
      results.forEach(function (data) {
        if (data.商品码) {
          arr.push(data.商品码);
        }
      });
      this.obj.goodsIds = arr;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      });
      return false;
    },
    setAlbum: function setAlbum(e, index) {
      if (index >= this.obj.album.length) {
        this.obj.album.push({
          image: e
        });
      } else {
        this.obj.album[index].image = e;
      }
    },
    delAlbum: function delAlbum(index) {
      this.obj.album.splice(index, 1);
    },
    showInput: function showInput() {
      var _this = this;

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleClick: function handleClick(data, checked, node) {
      var arrNode = this.$refs.tree.getCheckedNodes(true);
      var parentIdArr = [];
      var childNodes = [];
      arrNode.forEach(function (value) {
        if (parentIdArr.indexOf(value.parentId) === -1) {
          parentIdArr.push(value.parentId);
          childNodes.push([]);
        }
      });
      arrNode.forEach(function (value) {
        var dd = parentIdArr.indexOf(value.parentId);
        childNodes[dd].push(value.code);
      });
      this.obj.memberIds = parentIdArr;
      this.obj.institutionIds = childNodes;
    },
    init: function init() {
      var _this2 = this;

      this.id = Number(this.$route.query.id);
      (0, _deposit.listCardStoredClass)().then(function (res) {
        _this2.options = res.data;
      });
      (0, _user.listMemberCardInstitutionInfoNodes)().then(function (res) {
        _this2.selectEffMembers = res.data;
      });

      if (this.id) {
        this.title = '编辑提货卡';
        (0, _deposit.getCardStoredById)(this.id).then(function (res) {
          res.data.rechargeRmount = JSON.parse(res.data.rechargeAmount)[0];
          res.data.goodsIds = JSON.parse(res.data.goodsIds);

          if (res.data.scopeType === 2) {
            res.data.institutionIds = res.data.institutionIds.match(/[{0-9}]+/g);
          } else {
            res.data.institutionIds = [];
          }

          Object.keys(_this2.obj).forEach(function (key) {
            Object.keys(res.data).forEach(function (e) {
              if (e === key) {
                _this2.obj[key] = res.data[key];
              }
            });
          });
        });
      }
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      if (this.obj.scopeType == 2) {
        this.handleClick();
      }

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.openLoading();

          var data = JSON.parse(JSON.stringify(_this3.obj));
          data.rechargeRmount = [data.rechargeRmount];

          if (_this3.obj.id) {
            (0, _deposit.editCardStored)(data).then(function (res) {
              _this3.openLoading().close();

              _this3.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this3.$router.go(-1);
            }).catch(function () {
              _this3.openLoading().close();
            });
          } else {
            (0, _deposit.addCardStored)(data).then(function (res) {
              _this3.openLoading().close();

              _this3.$router.go(-1);

              _this3.$message({
                type: 'success',
                message: '保存成功!'
              });
            }).catch(function () {
              _this3.openLoading().close();
            });
          }
        } else {
          return false;
        }
      });
    },
    setCover: function setCover(index) {
      this.obj.cover = index;
    }
  }
};
exports.default = _default;