"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _set = require("@/api/set");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      title: '新增终端设备版本号',
      obj: {
        versionId: '',
        versionNo: '',
        versionDesc: '',
        versionStatu: 1
      }
    };
  },
  destroyed: function destroyed() {
    this.$store.dispatch('editor/setVersionsEditor', this.obj);
  },
  created: function created() {
    var data = this.$store.state.editor.versionsEditor;

    if (data && data.versionId === Number(this.$route.query.versionId)) {
      this.obj = this.$store.state.editor.versionsEditor;
    } else {
      this.init();
    }
  },
  methods: {
    change: function change(versionDesc) {
      this.obj.versionDesc = versionDesc;
    },
    init: function init() {
      var _this = this;

      this.obj.versionId = Number(this.$route.query.versionId);

      if (this.obj.versionId) {
        this.openLoading();
        this.title = '编辑终端设备版本号';
        (0, _set.getVersionById)({
          versionId: this.obj.versionId
        }).then(function (res) {
          _this.openLoading().close();

          _this.obj.versionDesc = res.data.versionDesc;
          _this.obj.versionStatu = res.data.versionStatu;
          _this.obj.versionNo = res.data.versionNo;
        }).catch(function (res) {
          _this.openLoading().close();
        });
      }
    },
    next: function next() {
      var _this2 = this;

      if (!this.obj.versionNo) {
        this.$message.error('请填写版本号');
      } else if (!this.obj.versionDesc) {
        this.$message.error('请填写版本更新说明');
      } else if (this.obj.versionStatu === '') {
        this.$message.error('请选择版本状态');
      } else {
        this.openLoading('努力保存中');

        if (this.obj.versionId) {
          (0, _set.editVersion)(this.obj).then(function (res) {
            _this2.$store.dispatch('editor/setVersionsEditor', '');

            _this2.$router.back(-1);
          }).catch(function (res) {
            _this2.openLoading().close();
          });
        } else {
          (0, _set.addVersion)(this.obj).then(function (res) {
            _this2.openLoading().close();

            _this2.$router.back(-1);
          }).catch(function (res) {
            _this2.openLoading().close();
          });
        }
      }
    }
  }
};
exports.default = _default;