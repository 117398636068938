"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '浦发理财',
      list: [{
        tit: '理财产品管理',
        txtArr: "\u7406\u8D22\u4EA7\u54C1\u7BA1\u7406",
        imgPath: require('@/assets/tab/2.png'),
        path: '/pufa/manageList'
      }, {
        tit: '银行网点管理',
        txtArr: '<p>银行网点管理</p>',
        imgPath: require('@/assets/tab/1.png'),
        path: '/pufa/branchList'
      }, {
        tit: '浦发理财客户信息表',
        txtArr: "\u6D66\u53D1\u7406\u8D22\u5BA2\u6237\u4FE1\u606F\u8868",
        imgPath: require('@/assets/tab/2.png'),
        path: '/pufa/user'
      }]
    };
  },
  methods: {
    setTab: function setTab(path) {
      this.$router.push(path);
    }
  }
};
exports.default = _default;