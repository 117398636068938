"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      isShowTable: true,
      type: 3,
      imgSrc: this.$store.state.user.imgSrc,
      merchantId: null,
      goodsType: {
        1: '积分商城',
        2: '大罗马',
        3: '乐购',
        4: '乐品',
        6: '话费充值'
      },
      coverData: {
        1: '优惠券图片',
        2: '购物卡图片',
        3: '商品图片'
      },
      couponStatus: {
        1: '待审核',
        2: '已审核',
        3: '审核撤回',
        4: '审核撤销',
        5: '已过期',
        6: '已终止'
      },
      couponType: {
        1: '代金券',
        2: '折扣券',
        3: '礼品券',
        4: '体验券',
        5: '外部优惠券'
      },
      columnsGoods: [{
        slot: 'mainImage'
      }, {
        label: '商品编码',
        prop: 'code',
        width: 80
      }, {
        label: '商品名称',
        prop: 'goodsInfo.productName',
        width: 80
      }, {
        slot: 'couponType'
      }, {
        label: '排序',
        prop: 'sort',
        width: 80
      }, {
        label: '商品简介',
        prop: 'goodsInfo.productInstr',
        width: 50
      }, {
        slot: 'limitNumber',
        label: '限购数量',
        prop: 'goodsInfo.limitNumber',
        width: 50
      }, {
        label: '商品数量',
        prop: 'goodsInfo.stock',
        width: 50
      }, {
        slot: 'isUse'
      }, {
        slot: 'update'
      }],
      columnsCard: [{
        slot: 'mainImage'
      }, {
        label: '购物卡编码',
        prop: 'code',
        width: 80
      }, {
        label: '购物卡名称',
        prop: 'goodsInfo.name',
        width: 80
      }, {
        slot: 'couponType'
      }, {
        label: '描述',
        prop: 'goodsInfo.description',
        width: 50
      }, {
        label: '是否支持转赠',
        prop: 'goodsInfo.ifDonation',
        width: 50
      }, {
        label: '数量',
        prop: 'goodsInfo.number',
        width: 50
      }, {
        label: '充值金额',
        prop: 'goodsInfo.rechargeAmount',
        width: 50
      }, {
        slot: 'status'
      }, {
        slot: 'isUse'
      }, {
        slot: 'update'
      }],
      columnsCoupon: [{
        slot: 'mainImage'
      }, {
        label: '优惠券编码',
        prop: 'code',
        width: 80
      }, {
        label: '优惠券名称',
        prop: 'goodsInfo.name',
        width: 80
      }, {
        slot: 'couponType'
      }, {
        slot: 'totalNumber',
        label: '投放数量',
        prop: 'goodsInfo.totalNumber',
        width: 50
      }, {
        label: '限领数量',
        prop: 'goodsInfo.limitNumber',
        width: 50
      }, {
        label: '券面额',
        prop: 'goodsInfo.denomination',
        width: 50
      }, {
        slot: 'validTime'
      }, {
        slot: 'status'
      }, {
        slot: 'isUse'
      }, {
        slot: 'update'
      }],
      columns: [],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '请输入相关编码',
        value: 'code'
      },
      /* {
        type: 'radio',
        span: 4,
        placeholder: '请选择商品类型',
        value: 'type',
        select:[
          {
            label:'优惠券',
            value:1
          },
          {
            label:'购物卡',
            value:2
          },
          {
            label:'积分商品',
            value:3
          }
        ]
      }, */
      {
        type: 'dateTime',
        span: 4,
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        span: 4,
        placeholder: '结束时间',
        defaultTime: '23:59:59',
        value: 'endTime'
      }],
      loading: false
    };
  },
  created: function created() {},
  computed: {// merchantIds() {
    //   return this.$store.state.user.merchantIds
    // },
    // pageRequestData() {
    //   if (this.merchantIds) {
    //     return { merchantIds: this.merchantIds }
    //   }
    //   return
    // },
  },
  mounted: function mounted() {
    this.merchantId = Number(this.$route.query.id);
    this.columns = this.columnsGoods;
    this.$refs.wTable.setRequestValue('merchantId', this.merchantId);
    this.$refs.wTable.setRequestValue('type', 3);
    this.$refs.wTable.getList();
  },
  methods: {
    setGoodsSort: function setGoodsSort(row) {
      var _this = this;

      this.$prompt("\u4FEE\u6539'".concat(row.goodsInfo.productName, "'\u7684\u6392\u5E8F\uFF0C\u5F53\u524D\u4E3A").concat(row.sort), '排序修改', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /(^[1-9]\d*$)/,
        inputErrorMessage: '请输入大于0的整数'
      }).then(function (_ref) {
        var value = _ref.value;

        _this.apiPost('/api/third/party/merchant/updateThirdPartyMerchantGoods', {
          sort: value,
          id: row.id
        }).then(function (res) {
          _this.$message({
            type: 'success',
            message: '修改成功'
          });

          _this.$refs.wTable.getList();
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    changeType: function changeType(e) {
      this.loading = true;
      this.$refs.wTable.setRequestValue('type', e);
      this.$refs.wTable.getList();

      if (e == 1) {
        this.columns = this.columnsCoupon;
      }

      if (e == 2) {
        this.columns = this.columnsCard;
      }

      if (e == 3) {
        this.columns = this.columnsGoods;
      }

      this.loading = false;
    },
    goCasancelGoods: function goCasancelGoods(row) {
      var _this2 = this;

      this.apiGet('/api/third/party/merchant/delThirdPartyMerchantGoods/' + row.id).then(function (res) {
        _this2.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;