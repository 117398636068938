var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "openInvoice" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("总部管理")]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row_div", attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                staticClass: "input2",
                attrs: { size: "mini", placeholder: "总部名称" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.init({ limit: 10, page: 1 })
                  }
                },
                model: {
                  value: _vm.obj.fullName,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "fullName", $$v)
                  },
                  expression: "obj.fullName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "mini", placeholder: "请选择ERP" },
                  model: {
                    value: _vm.obj.systemId,
                    callback: function($$v) {
                      _vm.$set(_vm.obj, "systemId", $$v)
                    },
                    expression: "obj.systemId"
                  }
                },
                _vm._l(_vm.systemList, function(item) {
                  return _c("el-option", {
                    key: item.systemId,
                    attrs: { label: item.systemName, value: item.systemId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.init()
                    }
                  }
                },
                [_vm._v("查询")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "info",
                    size: "mini",
                    icon: "el-icon-refresh"
                  },
                  on: {
                    click: function($event) {
                      return _vm.init("remove")
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.setMenuPerms("company:add")
            ? _c(
                "el-col",
                {
                  staticStyle: { float: "right", "min-width": "100px" },
                  attrs: { span: 3, offset: 1 }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "max-width": "100%", padding: "7px 5px" },
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.addFull }
                    },
                    [_vm._v("新增总部")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: "small",
            data: _vm.list,
            "row-key": "id",
            border: "",
            "tree-props": { children: "childNodes", hasChildren: "hasChildren" }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              width: "50",
              align: "center",
              label: "序号",
              fixed: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.$index + 1) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.tabTh, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: item.prop,
                label: item.label,
                align: "center",
                "min-width": item.width
              }
            })
          }),
          _vm._v(" "),
          _vm.setMenuPerms("company:edit")
            ? _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "table_td_btn",
                              on: {
                                click: function($event) {
                                  return _vm.goEdit(scope.row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3294013970
                )
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }