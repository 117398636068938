"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      formType: null,
      paramList: [],
      chanelList: [],
      payTemList: [],
      formData: [{
        label: '支付通道名称：',
        type: 'input',
        placeholder: '请输入支付通道名称',
        value: 'chanelName'
      }, {
        label: '支付通道简称：',
        type: 'input',
        placeholder: '请输入支付通道简称',
        value: 'shortName'
      },
      /* {
            label: '支付通道编号：',
            type: 'input',
            placeholder: '支付通道编号',
            value: 'channelTypeNo'
          }, */
      {
        slot: 'type',
        label: '通道类型：',
        type: 'radio',
        placeholder: '请选择通道类型',
        value: 'type',
        selectList: [{
          label: '聚合',
          value: 1
        }, {
          label: '非聚合',
          value: 2
        }]
      }, {
        slot: 'channelTypeNo'
      }, {
        slot: 'merchantType',
        label: '收银方式：',
        type: 'radio',
        placeholder: '请选择收银方式',
        value: 'merchantType',
        selectList: [{
          label: '自收银',
          value: 1
        }, {
          label: '集团收银',
          value: 2
        }]
      }, {
        label: '状态：',
        type: 'radio',
        placeholder: '请选择状态',
        value: 'status',
        selectList: [{
          label: '启用',
          value: 1
        }, {
          label: '停用',
          value: -1
        }]
      },
      /* {
         label: '图标：',
         type: 'img',
         placeholder: '请上传图标(50*50)',
         value: 'icon'
       }, */
      {
        slot: 'param',
        label: '支付参数：',
        type: 'input',
        placeholder: '请输入支付参数',
        value: 'param'
      }],
      ruleForm: {
        icon: '2222',
        param: {},
        status: null,
        type: 1,
        channelTypeNo: null,
        chanelName: null,
        shortName: null,
        merchantType: null
      },
      rules: {
        merchantType: [{
          required: true,
          message: '请选择收银方式'
        }],
        shortName: [{
          required: true,
          message: '请输入通道简称'
        }],
        chanelName: [{
          required: true,
          message: '请输入通道名称'
        }],
        channelTypeNo: [{
          required: true,
          message: '请输入通道编码'
        }],
        type: [{
          required: true,
          message: '请选择类型'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        param: [{
          required: true,
          message: '请选择参数'
        }],
        icon: [{
          required: true,
          message: '请上传图标'
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    this.formType = this.$route.query.type;
    this.apiGet('/api/pcc/tpt/listPayChanelTemplateEnum').then(function (res) {
      _this.chanelList = res.data;
    });
    this.ruleForm.id = Number(this.$route.query.id);

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/pcc/cfg/getCfgById/' + this.ruleForm.id).then(function (res) {
        delete res.data.createTime;

        if (_this.formType === 'copy') {
          delete res.data.id;
        }

        var param = res.data.param ? JSON.parse(res.data.param) : {};
        _this.ruleForm = res.data;

        if (res.data.type == 1) {
          _this.ruleForm.param = param;

          _this.channekChange(res.data.channelTypeNo, 'init');
        }
      });
    }
  },
  methods: {
    changType: function changType(e) {
      if (e == 2) {
        this.ruleForm.merchantType = 2;
      }
    },
    changeForm: function changeForm(value, prop) {
      if (prop == 'type' && value == 2) {
        this.ruleForm.merchantType = 2;
      }
    },
    channekChange: function channekChange(e, type) {
      var _this2 = this;

      this.apiGet('/api/pcc/tpt//listByPayTypeNo/' + e).then(function (res) {
        _this2.paramList = res.data;

        if (type != 'init') {
          _this2.ruleForm.param = {};
        }

        res.data.forEach(function (item) {
          if (item.columDefault) {
            _this2.ruleForm.param[item.column1] = item.columDefault;
          }

          _this2.rules[item.column1] = [{
            required: true,
            message: '请输入' + item.columDesc
          }];
        });
        console.log(_this2.paramList);
      });
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      var requestData = (0, _objectSpread2.default)({}, this.ruleForm);
      requestData.param = JSON.stringify(this.ruleForm.param);
      this.apiPost('/api/pcc/cfg/saveOrUpdateCfg', requestData).then(function (res) {
        _this3.$router.go(-1);

        _this3.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
      return;
      addData(this.ruleForm).then(function (res) {
        _this3.$router.go(-1);

        _this3.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;