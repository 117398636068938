"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addFreight = addFreight;
exports.delFreightItem = delFreightItem;
exports.editFreight = editFreight;
exports.getFreight = getFreight;
exports.listFreightPage = listFreightPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 2、运费模板分页：/api/freight/listFreightPage
 {"pageNo":"当前页","pageSize":"页条数","freightName":"运费模板名称","freightStatus":"运费模板状态(0：停用 1：启用)"}

 */
function listFreightPage(data) {
  return (0, _request.default)({
    url: '/api/freight/listFreightPage',
    method: 'post',
    data: data
  });
}
/*
 3、运费模板详情：/api/freight/getFreight/{id}
 */


function getFreight(data) {
  return (0, _request.default)({
    url: '/api/freight/getFreight/' + data,
    method: 'post'
  });
}
/*
4、新增运费模板：/api/freight/addFreight
{"freightName":"运费模板名称","freightStatus":"运费模板状态(0：停用 1：启用)",
"freightItems":[{"freightType":"运费类型(1：配送区域及运费 2：指定包邮)","areaCode":"区域编码",
"firstNumber":"首件/包邮件数","firstAmount":"首件运费/包邮运费","secondNumber":"续件","secondAmount":"续件运费"}]}

 */


function addFreight(data) {
  return (0, _request.default)({
    url: '/api/freight/addFreight',
    method: 'post',
    data: data
  });
}
/*
 5、编辑运费模板：/api/freight/editFreight
 {"id":"id","freightName":"运费模板名称","freightStatus":"运费模板状态(0：停用 1：启用)",
 "freightItems":[{"id":"id","freightType":"运费类型(1：配送区域及运费 2：指定包邮)",
 "areaCode":"区域编码","firstNumber":"首件/包邮件数","firstAmount":"首件运费/包邮运费","secondNumber":"续件","secondAmount":"续件运费"}]}
 */


function editFreight(data) {
  return (0, _request.default)({
    url: '/api/freight/editFreight',
    method: 'post',
    data: data
  });
}
/*
删除模板   所选区域
/api/freight/delFreightItem/{id}
 */


function delFreightItem(data) {
  return (0, _request.default)({
    url: '/api/freight/delFreightItem/' + data,
    method: 'post'
  });
}