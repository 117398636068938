"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      tableData: [],
      requestData: {
        name: null,
        account: null,
        pageSize: 10,
        page: 1
      },
      searchData: [{
        label: '用户手机号',
        type: 'input',
        span: 4,
        placeholder: '请输入用户手机号',
        value: 'userPhone'
      }, {
        label: '操作人',
        type: 'input',
        span: 4,
        placeholder: '冻结人手机号码',
        value: 'createPhone'
      }, {
        label: '操作人',
        type: 'input',
        span: 4,
        placeholder: '解除人手机号码',
        value: 'relievePhone'
      }, {
        label: '开始时间',
        type: 'dateTime',
        span: 3,
        placeholder: '请选择开始时间',
        value: 'startDate'
      }, {
        label: '结束时间',
        type: 'dateTime',
        span: 3,
        placeholder: '请选择结束时间',
        value: 'endDate'
      }, {
        label: '状态',
        type: 'radio',
        span: 3,
        placeholder: '选择状态',
        value: 'status',
        select: [{
          label: '已冻结',
          value: 1
        }, {
          label: '已解除',
          value: 2
        }]
      }]
    };
  },
  methods: {
    goAudit: function goAudit(row) {
      var _this = this;

      var _self = this;

      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _api.goUpdate)({
          id: row.id,
          relieveReason: value
        }).then(function (res) {
          _self.$refs.wTable.getList();

          _self.$message({
            type: 'success',
            message: '重置成功!'
          });
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    }
  }
};
exports.default = _default;