var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("返券规则详情")]),
      _vm._v(" "),
      false
        ? _c("p", { staticClass: "deatil_tit" }, [_vm._v("\n    规则状态\n  ")])
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "div",
            { staticClass: "step_box" },
            [
              _c(
                "el-steps",
                { attrs: { active: _vm.active } },
                _vm._l(_vm.step, function(item, index) {
                  return _c("el-step", {
                    key: index,
                    attrs: { title: item.tit, description: item.txt }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex_between" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.passAudit(2)
                          }
                        }
                      },
                      [_vm._v("通过审核")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.passAudit(3)
                          }
                        }
                      },
                      [_vm._v("启用")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.passAudit(4)
                          }
                        }
                      },
                      [_vm._v("禁用")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "box_title" }, [_vm._v("\n    规则信息：\n  ")]),
      _vm._v(" "),
      _c("div", { staticClass: "form" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v(" 规则编码：")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v("\n        " + _vm._s(_vm.data.ruleCode) + "\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v(" 返券规则名称：")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.data.ruleName))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v(" 返券活动范围：")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.data.rangeName))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v(" 活动时间：")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.data.ruleStartTime) +
                "至" +
                _vm._s(_vm.data.ruleEndTime) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v(" 返券方式：")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.data.ruleType == 1 ? "门槛返" : "阶梯返") +
                "\n      "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "box_title" }, [_vm._v("\n    返券门槛：\n  ")]),
      _vm._v(" "),
      _vm._l(_vm.data.ruleItems, function(item, index) {
        return _c("div", { key: index, staticClass: "form" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 返券门槛：")]),
            _vm._v(" "),
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(item.thresholdAmount) + "元")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 会员返券上限：")]),
            _vm._v(" "),
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(item.limitPeople) + "次")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 返券上限：")]),
            _vm._v(" "),
            _c("div", { staticClass: "value" }, [
              _vm._v(
                "\n        " +
                  _vm._s(item.limitType == 1 ? "返券总人数" : "返券总次数") +
                  "\n        "
              ),
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v(
                  " " +
                    _vm._s(item.limitNumber) +
                    " " +
                    _vm._s(item.limitType == 1 ? "人" : "次")
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 返券周期：")]),
            _vm._v(" "),
            _c("div", { staticClass: "value" }, [
              _vm._v(
                "\n        " +
                  _vm._s(item.ruleItemCycle == 1 ? "每日" : "周期") +
                  "\n        "
              ),
              item.ruleItemCycle == 2
                ? _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v(
                      _vm._s(item.ruleItemStartTime) +
                        "至" +
                        _vm._s(item.ruleItemEndTime)
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 投放优惠券：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "value" },
              _vm._l(item.couponInfo, function(row, rowIndex) {
                return _c("span", { staticStyle: { "margin-right": "10px" } }, [
                  _vm._v("\n          " + _vm._s(row.couponCode) + " "),
                  _c("span", { staticStyle: { "margin-left": "5px" } }, [
                    _vm._v("*" + _vm._s(row.sendNumber))
                  ])
                ])
              }),
              0
            )
          ])
        ])
      }),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "form" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v(" 备注：")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v("\n        " + _vm._s(_vm.data.ruleRemark) + "\n      ")
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "status" }, [
      _vm._v("当前状态："),
      _c("span", { staticClass: "color" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }