"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/es6.set");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/web.dom.iterable");

var _axios = _interopRequireDefault(require("axios"));

var _mem = require("@/api/mem.js");

var _integral = require("@/api/integral.js");

var _php = require("@/api/php.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default
  },
  props: {
    scope: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      shortNameShow: false,
      sId: '',
      pinpaiSearch: '',
      shortNameSearch: '',
      pinpaiShow: false,
      companyId: '',
      list: [],
      storeList: [],
      pinpaiData: {
        total: 0,
        pageNo: 1,
        pageSize: 10,
        selectData: [],
        data: []
      },
      popTableData: [],
      getPinPaiKeys: function getPinPaiKeys(row) {
        return row.c_name;
      },
      pinleiShow: false,
      cCcodes: [],
      pinLeiData: {
        data: [],
        defaultProps: {
          children: 'items',
          label: function label(a, b) {
            return a.c_name + '     ' + a.c_ccode;
          }
        }
      },
      filterText: '',
      popShow: false,
      // 商品刷选条件
      couponData: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        cName: ''
      },
      shopKeyWord: '',
      // 商品框 table key
      getRowKeys: function getRowKeys(row) {
        return row.c_gcode;
      },
      // 商品数组
      // 商品选中后的值
      multipleSelection: [],
      tabTh: [{
        tit: '商品编码',
        prop: 'goodsCode',
        align: 'center'
      }, {
        tit: '商品名称',
        prop: 'goodsName',
        align: 'center'
      }, {
        tit: '品牌',
        prop: 'brandCode',
        align: 'center'
      }, {
        tit: '品类编码',
        prop: 'classCode',
        align: 'center'
      }],
      catData: {
        pageNo: 1,
        pageSize: 10,
        brandClsCode: '',
        total: 0
      },
      brandData: {
        pageNo: 1,
        pageSize: 10,
        brandCode: '',
        total: 0
      },
      pinpaiArr: [],
      goodsData: {
        pageNo: 1,
        pageSize: 10,
        goodsCode: '',
        total: 0
      },
      merchantShow: false,
      merchantList: [],
      merchantData: {
        pageNo: 1,
        pageSize: 10,
        merchantCode: '',
        total: 0
      },
      changeMerchant: []
    };
  },
  computed: {// pinpaiArr() {
    //   let arr = this.pinpaiSearch.split(',')
    //   if (this.pinpaiSearch) {
    //     let arr2 = []
    //     arr.forEach((item) => {
    //       arr2.push(item.trim())
    //     })
    //     let newArr = this.pinpaiData.data.filter(function(n) {
    //       return arr2.indexOf(n.c_name) != -1
    //     })
    //     return newArr
    //   } else {
    //     return this.pinpaiData.data
    //   }
    // }
  },
  watch: {// filterText(val) {
    //   this.$refs.tree.filter(val)
    // }
  },
  created: function created() {},
  methods: {
    dowGoodsList: function dowGoodsList() {
      var _this = this;

      if (this.sId && this.form.shortName.length > 0) {
        var brand = '';
        var ccode = '';
        var merchantCode = '';

        if (this.form.selectData.length > 0) {
          this.form.selectData.forEach(function (item) {
            brand += item.brandCode + ',';
          });
        }

        if (this.form.cCcodes.length > 0) {
          this.form.cCcodes.forEach(function (item) {
            ccode += item.classCode + ',';
          });
        }

        if (this.changeMerchant.length > 0) {
          this.changeMerchant.forEach(function (item) {
            merchantCode += item.merchantCode + ',';
          });
        }

        this.goodsData.companyCode = this.form.companyId;
        this.goodsData.storeCode = this.form.shortName.join(',');

        if (this.goodsData.goodsCode) {
          this.goodsData.goodsCode = this.stringTrim(this.goodsData.goodsCode);
        }

        var requestData = JSON.parse(JSON.stringify(this.goodsData));
        requestData.pageSize = this.goodsData.total;
        requestData.pageNo = 1;
        (0, _php.goodsList)(requestData).then(function (res) {
          res.data.items.forEach(function (item) {
            item.companyCode = _this.goodsData.companyCode;
            item.storeCode = _this.goodsData.storeCode;
          });
          var tHeader = ['公司编码', '门店编码', '商品码', '商品名称', '品牌编码', '品类编码'];
          var filterVal = ['companyCode', 'storeCode', 'goodsCode', 'goodsName', 'brandCode', 'classCode'];

          _this.getHandleDownload(tHeader, filterVal, res.data.items, '商品信息');
        }).catch(function (s) {});
      } else {
        this.$message({
          message: '请先选择总部或门店',
          type: 'warning'
        });
      }
    },
    dowGoods: function dowGoods() {
      /*
       <el-table-column align="center" prop="goodsCode" min-width="30" label="商品码"
      <el-table-column align="center" prop="goodsName" min-width="60" label="商品名称" />
       */
      var tHeader = ['商品码', '商品名称'];
      var filterVal = ['goodsCode', 'goodsName'];
      this.getHandleDownload(tHeader, filterVal, this.form.multipleSelection, '折扣商品码');
    },
    setCompanId: function setCompanId(e) {
      var _this2 = this;

      (0, _mem.listCompany)({
        fullName: '',
        companyId: ''
      }).then(function (res) {
        _this2.openLoading().close();

        _this2.list = res.data;

        if (e) {
          _this2.list.forEach(function (item) {
            if (item.companyCode == e) {
              _this2.sId = item.companyId;
              _this2.form.companyName = item.fullName;
            }
          });

          var data = {
            companyId: _this2.sId
          };
          _this2.storeList = [];

          _this2.$refs.shortTable.clearSelection();

          (0, _mem.listStore)(data).then(function (res) {
            _this2.storeList = res.data;

            _this2.form.storeArr.forEach(function (item) {
              _this2.storeList.forEach(function (row) {
                if (item.attrCode == row.storeCode) {
                  _this2.$refs.shortTable.toggleRowSelection(row);
                }
              });
            });
          });
        }
      }).catch(function () {
        _this2.openLoading().close();
      });
    },
    init: function init(type) {
      var _this3 = this;

      if (type == 'company') {
        this.clearShort();
        this.form.shortName = [];
        this.form.storeArr = [];
        var data = {
          companyId: this.sId
        };
        this.storeList = [];
        this.$refs.shortTable.clearSelection();
        (0, _mem.listStore)(data).then(function (res) {
          _this3.storeList = res.data;

          _this3.form.storeArr.forEach(function (item) {
            _this3.storeList.forEach(function (row) {
              if (item.attrCode == row.storeCode) {
                _this3.$refs.shortTable.toggleRowSelection(row);
              }
            });
          });
        });
      }

      this.clearMerchant();
      this.removeShop();
      this.clearPinLei();
      this.clearPinPai();
      this.catData.pageNo = 1;
      this.goodsData.pageNo = 1;
      this.brandData.pageNo = 1;
      this.merchantData.pageNo = 1;
      this.changeMerchant = [];
      this.form.selectData = [];
      this.pinpaiData = {
        cName: '',
        pageNo: 1,
        pageSize: 10,
        total: 0,
        data: [],
        selectData: []
      };
      this.$refs.pinpaiTable.clearSelection();
      this.pinLeiData.data = [];
      this.form.pinleiArr = [];
      this.form.cCcodes = [];
    },

    /*
      切换总部时
      */
    companyIdChange: function companyIdChange(e) {
      var _this4 = this;

      this.$emit('setcompanyId', e);

      if (this.list.length == 0) {
        (0, _mem.listCompany)({
          fullName: '',
          companyId: ''
        }).then(function (res) {
          _this4.list = res.data;

          _this4.list.forEach(function (item) {
            if (item.companyCode == e) {
              _this4.sId = item.companyId;

              _this4.init('company');

              _this4.form.companyName = item.fullName;
            }
          });
        });
      } else {
        this.list.forEach(function (item) {
          if (item.companyCode == e) {
            _this4.sId = item.companyId;

            _this4.init('company');

            _this4.form.companyName = item.fullName;
          }
        });
      }
    },

    /*
      选择门店
      */
    shortNameClick: function shortNameClick() {
      if (this.sId) {
        this.shortNameShow = true;
      } else {
        this.$message({
          message: '请先选择总部',
          type: 'warning'
        });
      }
    },

    /*
      清空门店
      */
    clearShort: function clearShort() {
      this.$refs.shortTable.clearSelection();
    },

    /*
      保存门店
      */
    affirmShort: function affirmShort() {
      var _this5 = this;

      var shortName = this.$refs.shortTable.store.states.selection;
      var arr = [];
      this.form.shortName = [];
      shortName.forEach(function (item) {
        if (item.storeCode && item.fullName) {
          arr.push({
            attrCode: item.storeCode,
            attrName: item.fullName,
            attrType: 1
          });

          _this5.form.shortName.push(item.storeCode);
        }
      });
      this.form.storeArr = arr;
      this.$emit('submitForm', 'shortName');
      this.shortNameShow = false;
      this.init();
    },
    shortNameChange: function shortNameChange(e) {
      var _this6 = this;

      var arr = [];
      this.form.shortName.forEach(function (row) {
        _this6.storeList.forEach(function (item) {
          if (row == item.storeCode && item.storeCode && item.fullName) {
            arr.push({
              attrCode: row,
              attrName: item.fullName,
              attrType: 1
            });
          }
        });
      });
      this.form.storeArr = arr;
      this.removeShop();
    },

    /*
      全选
      */
    selectAll: function selectAll() {
      var _this7 = this;

      if (this.form.shortName.length < this.storeList.length) {
        this.form.shortName = [];
        this.storeList.map(function (item) {
          _this7.form.shortName.push(item.storeCode);
        });
        this.form.shortName.unshift('全选');
        this.shortNameChange();
      } else {
        this.form.shortName = [];
      }
    },

    /*
       *************************************************************************************
       */

    /* 打开商户列表*/
    setlistMerchant: function setlistMerchant() {
      if (this.sId && this.form.shortName.length > 0) {
        if (this.merchantList.length > 0) {
          this.merchantShow = true;
        } else {
          this.getMerchant();
        }
      } else {
        this.$message({
          message: '请先选择总部或者门店',
          type: 'warning'
        });
      }
    },

    /*
     获取商户列表
      */
    getMerchant: function getMerchant(type) {
      var _this8 = this;

      this.merchantData.companyCode = this.form.companyId;
      this.merchantData.storeCode = this.form.shortName.join(',');

      if (type == 'search') {
        this.merchantData.pageNo = 1;
      }

      if (this.merchantData.merchantCode) {
        this.merchantData.merchantCode = this.stringTrim(this.merchantData.merchantCode);
      }

      this.openLoading();
      (0, _php.listMerchantPage)(this.merchantData).then(function (res) {
        _this8.openLoading().close();

        _this8.merchantList = res.data.items;
        _this8.merchantData.total = res.data.totalNum;
        _this8.merchantShow = true;
      }).catch(function (ss) {
        _this8.openLoading().close();
      });
    },

    /*
      设置商户选中
      */
    setMerChant: function setMerChant(item) {
      this.$refs.merchantTable.toggleRowSelection({
        merchantCode: item.attrCode // c_name: item.attrName

      });
      this.changeMerchant = this.$refs.merchantTable.store.states.selection;
    },
    clearMerchant: function clearMerchant() {
      this.$refs.merchantTable.clearSelection();
    },
    addMerchant: function addMerchant() {
      var changeMerchant = this.$refs.merchantTable.store.states.selection;
      this.changeMerchant = JSON.parse(JSON.stringify(changeMerchant)); // this.$emit('submitForm', 'selectData')
      // this.removeShop()

      this.merchantShow = false;
      this.removeShop();
    },

    /*
       **************************************************************************************
       */

    /* 打开品牌列表*/
    setlistBrand: function setlistBrand() {
      if (this.sId && this.form.shortName.length > 0) {
        if (this.pinpaiArr.length > 0) {
          this.pinpaiShow = true;
        } else {
          this.getBrandList();
        }
      } else {
        this.$message({
          message: '请先选择总部或者门店',
          type: 'warning'
        });
      }
    },

    /*
      获取品牌数据
      */
    getBrandList: function getBrandList(type) {
      var _this9 = this;

      this.brandData.companyCode = this.form.companyId;
      this.brandData.storeCode = this.form.shortName.join(',');

      if (type == 'search') {
        this.catData.pageNo = 1;
      }

      if (this.brandData.brandCode) {
        this.brandData.brandCode = this.stringTrim(this.brandData.brandCode);
      }

      this.openLoading();
      (0, _php.goodsBrand)(this.brandData).then(function (res) {
        _this9.openLoading().close();

        _this9.pinpaiArr = res.data.items;
        _this9.brandData.total = res.data.totalNum;
        _this9.pinpaiShow = true;
      }).catch(function (s) {
        _this9.openLoading().close();
      });
    },

    /*
      保存品牌
      */
    addPinPai: function addPinPai() {
      var pinPaiArr = this.$refs.pinpaiTable.store.states.selection;
      this.form.selectData = JSON.parse(JSON.stringify(pinPaiArr));
      this.$emit('submitForm', 'selectData'); // this.removeShop()

      this.pinpaiShow = false;
      this.removeShop();
    },

    /*
      设置品牌选中
      */
    setPinpai: function setPinpai(item) {
      this.$refs.pinpaiTable.toggleRowSelection({
        brandCode: item.attrCode // c_name: item.attrName

      });
      this.form.selectData = this.$refs.pinpaiTable.store.states.selection;
    },

    /*
      清空品牌
       */
    clearPinPai: function clearPinPai() {
      this.$refs.pinpaiTable.clearSelection();
    },

    /*
       **************************************************************************************
       */

    /*
      打开品类弹框
      */
    pinleiClick: function pinleiClick() {
      if (this.sId && this.form.shortName.length > 0) {
        if (this.pinLeiData.data.length > 0) {
          this.pinleiShow = true;
        } else {
          this.getCatList();
        }
      } else {
        this.$message({
          message: '请先选择总部或门店',
          type: 'warning'
        });
      }
    },

    /*
      获取品类数据
      */
    getCatList: function getCatList(type) {
      var _this10 = this;

      this.catData.companyCode = this.form.companyId;
      this.catData.storeCode = this.form.shortName.join(',');

      if (type == 'search') {
        this.catData.pageNo = 1;
      }

      if (this.catData.brandClsCode) {
        this.catData.brandClsCode = this.stringTrim(this.catData.brandClsCode);
      }

      this.openLoading();
      (0, _php.goodsCat)(this.catData).then(function (res) {
        _this10.openLoading().close();

        _this10.pinLeiData.data = res.data.items;
        _this10.catData.total = res.data.totalNum;
        _this10.pinleiShow = true;
      }).catch(function () {
        _this10.openLoading().close();
      });
    },

    /*
        品类搜索
        */
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.c_ccode.indexOf(value) !== -1 || data.c_name.indexOf(value) !== -1;
    },

    /*
        清空品类
        */
    clearPinLei: function clearPinLei() {
      this.$refs.tree.clearSelection(); // this.$refs.tree.setCheckedKeys([])
    },

    /*
      设置品类选中
      */
    setPinLei: function setPinLei(item) {
      this.$refs.tree.toggleRowSelection({
        classCode: item.attrCode
      });
      this.form.pinleiArr = this.$refs.tree.store.states.selection;
      this.form.cCcodes = this.$refs.tree.store.states.selection;
    },

    /*
        保存品类*/
    addPinLei: function addPinLei(type) {
      // this.form.pinleiArr = this.$refs.tree.store.states.selection
      var pinLeiArr = this.$refs.tree.store.states.selection;
      this.form.pinleiArr = JSON.parse(JSON.stringify(pinLeiArr));
      this.form.cCcodes = this.$refs.tree.store.states.selection;
      this.$emit('submitForm', 'pinleiArr');
      this.pinleiShow = false;
      this.removeShop();
      return;
      this.form.multipleSelection = this.$refs.shopTable.store.states.selection;
      this.form.pinleiArr = this.$refs.tree.getCheckedNodes();
      this.form.cCcodes = this.$refs.tree.getCheckedKeys();
      this.$emit('submitForm', 'pinleiArr');

      if (type != 'setCode') {// this.removeShop()
      }
    },

    /*
       **************************************************************************************
       */
    removeShop: function removeShop() {
      this.popTableData = [];
      this.$refs.shopTable.clearSelection();
      this.shopKeyWord = '';
      this.form.multipleSelection = [];
    },

    /* 获取商品*/
    getShops: function getShops(type) {
      var _this11 = this;

      if (this.sId && this.form.shortName.length > 0) {
        if (type == 'remove') {
          this.goodsData.pageNo = 1;
        }

        var brand = '';
        var ccode = '';
        var merchantCode = '';

        if (this.form.selectData.length > 0) {
          this.form.selectData.forEach(function (item) {
            brand += item.brandCode + ',';
          });
        }

        if (this.form.cCcodes.length > 0) {
          this.form.cCcodes.forEach(function (item) {
            ccode += item.classCode + ',';
          });
        }

        if (this.changeMerchant.length > 0) {
          this.changeMerchant.forEach(function (item) {
            merchantCode += item.merchantCode + ',';
          });
        }

        this.goodsData.companyCode = this.form.companyId;
        this.goodsData.storeCode = this.form.shortName.join(','); // this.goodsData.brandClsCode = ccode
        // this.goodsData.brandCode = brand
        // this.goodsData.merchantCode=merchantCode

        if (this.goodsData.goodsCode) {
          this.goodsData.goodsCode = this.stringTrim(this.goodsData.goodsCode);
        }

        this.openLoading();
        (0, _php.goodsList)(this.goodsData).then(function (res) {
          _this11.openLoading().close();

          _this11.popTableData = res.data.items;
          _this11.goodsData.total = res.data.totalNum;
          _this11.popShow = true;
        }).catch(function (s) {
          _this11.openLoading().close();
        });
      } else {
        this.$message({
          message: '请先选择总部或门店',
          type: 'warning'
        });
      }
    },

    /*
      设置商品选中
       */
    setShop: function setShop(obj) {
      this.$refs.shopTable.toggleRowSelection(obj);
      this.form.multipleSelection = this.$refs.shopTable.store.states.selection;
    },

    /*
          清空商品选择
          */
    clearShop: function clearShop() {
      this.$refs.shopTable.clearSelection();
    },

    /*
          保存商品选择
          */
    affirmAddCoupon: function affirmAddCoupon() {
      var _this12 = this;

      var goodsArr = this.$refs.shopTable.store.states.selection;
      console.log(goodsArr);
      this.form.multipleSelection = JSON.parse(JSON.stringify(goodsArr));
      var c_ccode = [];
      var c_brand = [];
      var merChant = [];
      var storeCode = this.form.shortName.join(','); // const companyCode = this.form.companyId

      this.form.multipleSelection.forEach(function (item) {
        item.storeCode = storeCode;

        if (item.classCode && item.classCode != '-1') {
          c_ccode.push(item.classCode);
        }

        if (item.brandCode && item.brandCode != '-1') {
          c_brand.push({
            brandCode: item.brandCode
          });
        }

        if (item.merchantCode && item.merchantCode != '-1') {
          merChant.push({
            merchantCode: item.merchantCode
          });
        }
      });
      this.form.cCcodes = Array.from(new Set(c_ccode));
      var obj = {};
      var peon2 = c_brand.reduce(function (cur, next) {
        obj[next.brandCode] ? '' : obj[next.brandCode] = true && cur.push(next);
        return cur;
      }, []);

      if (merChant.length > 0) {
        var merChants = [].concat(merChant, (0, _toConsumableArray2.default)(this.changeMerchant));
        var peon3 = merChants.reduce(function (cur, next) {
          obj[next.merchantCode] ? '' : obj[next.merchantCode] = true && cur.push(next);
          return cur;
        }, []);
        this.clearMerchant();
        peon3.forEach(function (item) {
          _this12.setMerChant({
            attrCode: item.merchantCode
          });
        });
      }

      if (this.form.cCcodes.length > 0) {
        this.form.cCcodes.forEach(function (item) {
          var result = _this12.form.pinleiArr.some(function (row) {
            if (row.classCode == item) {
              return true;
            }
          });

          if (!result) {
            _this12.setPinLei({
              attrCode: item
            });
          }
        });
      }

      if (peon2.length > 0) {
        peon2.forEach(function (item) {
          var result = _this12.form.selectData.some(function (row) {
            if (row.brandCode == item.brandCode) {
              return true;
            }
          });

          if (!result) {
            _this12.setPinpai({
              attrCode: item.brandCode
            });
          }
        });
      }

      this.popShow = false;
    },

    /*
      设置选中信息
      */
    setCditions: function setCditions(arr) {
      var _this13 = this;

      arr.forEach(function (item) {
        if (item.attrType == 1) {
          _this13.form.shortName.push(item.attrCode);

          _this13.form.storeArr.push({
            attrCode: item.attrCode,
            attrName: item.attrName
          });
        }

        if (item.attrType == 2) {
          _this13.setMerChant(item);
        }

        if (item.attrType == 3) {
          _this13.setPinLei(item);
        }

        if (item.attrType == 4) {
          _this13.setPinpai(item);
        }

        if (item.attrType == 5) {
          var obj = {
            goodsCode: item.attrCode,
            goodsName: item.attrName,
            classCode: item.classCode,
            brandCode: item.brandCode,
            storeCode: item.storeCode
          };

          _this13.setShop(obj);
        }
      });
    },

    /*
      获取所有选中信息
      */
    getCditions: function getCditions() {
      var cditions = [];
      this.form.storeArr.forEach(function (item) {
        cditions.push({
          attrCode: item.attrCode,
          attrName: item.attrName,
          attrType: 1
        });
      });
      this.changeMerchant.forEach(function (item) {
        cditions.push({
          attrCode: item.merchantCode,
          attrName: item.merchantName || item.merchantCode,
          attrType: 2
        });
      });
      this.form.multipleSelection.forEach(function (item) {
        cditions.push({
          attrCode: item.goodsCode,
          attrName: item.goodsName,
          storeCode: item.storeCode,
          brandCode: item.brandCode,
          classCode: item.classCode,
          attrType: 5
        });
      });
      this.form.pinleiArr.forEach(function (item) {
        cditions.push({
          attrCode: item.classCode,
          attrName: item.classCode,
          attrType: 3
        });
      });
      this.form.selectData.forEach(function (item) {
        cditions.push({
          attrCode: item.brandCode,
          attrName: item.brandCode,
          attrType: 4
        });
      });

      if (this.form.selectData.length == 0 && this.form.pinleiArr.length == 0 && this.changeMerchant.length == 0) {
        return false;
      } else {
        return cditions;
      }
    },
    shopRemove: function shopRemove() {
      this.couponData = {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        cName: ''
      };
      this.getShops();
    },
    pinpaiRemoveCoupon: function pinpaiRemoveCoupon() {},

    /*
          商品搜索
          */
    shopSearch: function shopSearch() {
      this.getShops(this.shop);
    },

    /*
          选择品牌时
          */
    pinPaiChange: function pinPaiChange(val) {
      /* this.pinpaiData.selectData = val
         this.couponData = {
           pageNo: 1,
           pageSize: 10,
           total: 0,
           cName: ''
         }
         this.popTableData = [] */
      // this.$refs.shopTable.clearSelection();
    },

    /*
          选择商品时
          */
    handleSelectionChange: function handleSelectionChange(val) {// this.multipleSelection = val
    }
  }
};
exports.default = _default2;