"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _member = require("@/api/member.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      uid: '',
      form: {
        oldMobile: '18885608507',
        newMobile: '',
        dingtalkNumber: ''
      },
      rules: {
        newMobile: [{
          required: true,
          message: '请输入新手机号'
        }],
        dingtalkNumber: [{
          required: true,
          message: '请输入钉钉工单号'
        }]
      }
    };
  },
  created: function created() {
    this.uid = this.$route.query.uid;
    this.form.oldMobile = this.$route.query.oldMobile;
  },
  methods: {
    onSubmit: function onSubmit(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.$confirm('此操作将确认切换手机号码, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            (0, _member.changeMobile)(_this.form).then(function (res) {
              _this.$message({
                type: 'success',
                message: '修改成功!'
              });
            });
          }).catch(function () {});
        }
      });
    }
  }
};
exports.default = _default;