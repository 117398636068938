"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      time: null,
      requestData: {
        weekTag: null,
        name: null,
        startTime: null,
        endTime: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '消费奖励ID',
        prop: 'consumerIncentivesId',
        width: 120
      }, {
        label: '权益ID',
        prop: 'rightsId',
        width: 80
      }, {
        label: '用户UID',
        prop: 'uid',
        width: 80
      }, {
        label: '使用订单号',
        prop: 'orderNo',
        width: 120
      }, {
        label: '领取时间',
        prop: 'createTime',
        width: 140
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {
    this.getOrder();
  },
  methods: {
    delGoods: function delGoods(row) {
      var _this = this;

      (0, _api.removeById)({
        id: row.id
      }).then(function (res) {
        _this.getOrder();
      });
    },
    upDateStatus: function upDateStatus(e, row) {
      var _this2 = this;

      (0, _api.toggleStatus)({
        id: row.id
      }).then(function (res) {
        _this2.getOrder();
      });
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this3.requestData[key]) {
          data[key] = _this3.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getOrder)(data).then(function (res) {
        res.data.records.forEach(function (item) {
          var data = item.payType.split(',');
          var payType = data.map(function (e) {
            var payData = _this3.payTypeList.find(function (row) {
              return e == row.value;
            });

            if (payData) {
              return payData.label;
            }
          });
          item.payType = payType;
        });
        list = res.data.records;
        _this3.downloadLoading = false;

        _this3.getHandleDownload(tHeader, filterVal, list, '消费奖励记录');
      }).catch(function (res) {
        _this3.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getOrder();
    },
    getOrder: function getOrder(type) {
      var _this4 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getOrder)(this.requestData).then(function (res) {
        _this4.tableData = res.data.records;
        _this4.requestData.total = res.data.total;
      });
    }
  }
};
exports.default = _default;