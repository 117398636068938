"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* import Chart from '@/components/Charts/LineMarker'
  import Chart1 from '@/components/Charts/Keyboard'
  import Chart2 from '@/components/Charts/MixChart' */
var _default = {
  name: 'Page404',

  /* components: {
      Chart,
      Chart1,
      Chart2
    }, */
  computed: {
    message: function message() {
      return '网站管理员说你不能进入这个页面…';
    }
  }
  /* mounted() {
      let _this = this;
      this.$nextTick(() => {
        window.addEventListener('resize', () => { //监听浏览器窗口大小改变
          //浏览器变化执行动作
          _this.$refs.resizeTheChart1.resizeTheChart()
          _this.$refs.resizeTheChart2.resizeTheChart()
          _this.$refs.resizeTheChart3.resizeTheChart()
          _this.$refs.resizeTheChart4.resizeTheChart()
          _this.$refs.resizeTheChart5.resizeTheChart()
          _this.$refs.resizeTheChart6.resizeTheChart()
        });
      })
    },
    beforeDestroy () {
      let _this = this;
      this.$nextTick(() => {
        window.removeEventListener('resize', () => { //监听浏览器窗口大小改变
          //浏览器变化执行动作
          _this.$refs.resizeTheChart1.resizeTheChart()
          _this.$refs.resizeTheChart2.resizeTheChart()
          _this.$refs.resizeTheChart3.resizeTheChart()
          _this.$refs.resizeTheChart4.resizeTheChart()
          _this.$refs.resizeTheChart5.resizeTheChart()
          _this.$refs.resizeTheChart6.resizeTheChart()
        });
      })
    //	window.removeEventListener('resize', this.resizeChart)
    }, */

};
exports.default = _default;