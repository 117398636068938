"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      cardData: {},
      ruleForm: {
        magstripeCode: '32100200000000002194924593206745811648499462978653'
      }
    };
  },
  methods: {
    submitForm: function submitForm(type) {
      var _this = this;

      this.cardData = {};
      (0, _api.getInfoCheck)({
        magstripeCode: this.ruleForm.magstripeCode
      }).then(function (res) {
        _this.cardData = res.data;
      });
    }
  }
};
exports.default = _default;