var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("订单详情")]),
      _vm._v(" "),
      _c("p", { staticClass: "deatil_tit" }, [_vm._v(" 订单状态 ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "step_box" },
        [
          (_vm.data.orderStatus == 4 || _vm.data.orderStatus == 5) &&
          _vm.data.payStatus == 1
            ? _c(
                "el-steps",
                {
                  attrs: {
                    "process-status":
                      (_vm.data.saleStatus == "3" &&
                        _vm.data.orderStatus == 4) ||
                      (_vm.data.orderStatus == 1 && _vm.data.cancelStatus === 1)
                        ? "error"
                        : "process",
                    active: _vm.active
                  }
                },
                _vm._l(_vm.stepSale, function(item, index) {
                  return _c("el-step", {
                    key: index,
                    attrs: { title: item.tit }
                  })
                }),
                1
              )
            : _c(
                "el-steps",
                { attrs: { active: _vm.active } },
                _vm._l(_vm.step, function(item, index) {
                  return _c("el-step", {
                    key: index,
                    attrs: { title: item.tit }
                  })
                }),
                1
              ),
          _vm._v(" "),
          _c("div", { staticClass: "flex_between" }, [
            _c("p", { staticClass: "status" }, [
              _vm._v("当前状态：\n\n          "),
              _vm.data.payStatus != 1
                ? _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v("未支付订单")
                  ])
                : _vm.data.orderStatus == "-1"
                ? _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v("充值失败")
                  ])
                : _c("span", [
                    _vm.data.orderStatus == 1
                      ? _c("span", { staticStyle: { color: "#E6A23C" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.data.deliveryWay == 1 ? "待发货" : "待自提"
                              ) +
                              "\n            "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.orderStatus == 2
                      ? _c("span", { staticStyle: { color: "#E6A23C" } }, [
                          _vm._v(" 待收货 ")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.orderStatus == 3
                      ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                          _vm._v("已完成")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.payStatus === 1 &&
                    _vm.data.orderStatus == 4 &&
                    _vm.data.saleStatus == "1"
                      ? _c("span", { staticStyle: { color: "#409EFF" } }, [
                          _vm._v("售后申请")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.payStatus === 1 &&
                    _vm.data.orderStatus == 4 &&
                    _vm.data.saleStatus == "2"
                      ? _c("span", { staticStyle: { color: "#E6A23C" } }, [
                          _vm._v("退款中")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.payStatus === 1 &&
                    _vm.data.orderStatus == 4 &&
                    _vm.data.saleStatus == "3"
                      ? _c("span", [_vm._v("已拒绝售后")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.payStatus === 1 &&
                    _vm.data.orderStatus == 4 &&
                    _vm.data.saleStatus == "4"
                      ? _c("span", { staticStyle: { color: "#F56C6C" } }, [
                          _vm._v("已退款")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.orderStatus == 5 && _vm.data.cancelStatus === 0
                      ? _c("span", [_vm._v("已提交取消")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.payStatus === 1 &&
                    _vm.data.orderStatus == 5 &&
                    _vm.data.cancelStatus === 2
                      ? _c("span", { staticStyle: { color: "#F56C6C" } }, [
                          _vm._v("待退款")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.payStatus === 1 &&
                    _vm.data.orderStatus == 5 &&
                    _vm.data.cancelStatus === 3
                      ? _c("span", [_vm._v("已退款")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.orderStatus == 5 && _vm.data.cancelStatus === 1
                      ? _c("span", [_vm._v("拒绝取消")])
                      : _vm._e()
                  ])
            ]),
            _vm._v(" "),
            _vm.setMenuPerms("orderSale") && _vm.data.payStatus == 1
              ? _c(
                  "div",
                  [
                    _vm.data.orderStatus == 5 && _vm.data.cancelStatus == 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.goCancel(1)
                                  }
                                }
                              },
                              [_vm._v("拒绝取消")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.goCancel(2)
                                  }
                                }
                              },
                              [_vm._v("同意取消")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.orderStatus == 4 && _vm.data.saleStatus == 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function($event) {
                                    _vm.popShow = true
                                  }
                                }
                              },
                              [_vm._v("拒绝")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.goSale(2)
                                  }
                                }
                              },
                              [_vm._v("同意售后")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.orderStatus == 4 && _vm.data.saleStatus == 2
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: { click: _vm.goRefund }
                              },
                              [_vm._v("退款")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.orderStatus == "-1" ||
                    (_vm.data.orderStatus == 1 ||
                      _vm.data.orderStatus == 2 ||
                      _vm.data.orderStatus == 3) ||
                    (_vm.data.orderStatus == 4 && _vm.data.saleStatus == 3)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                _vm.isScal = true
                              }
                            }
                          },
                          [_vm._v("申请售后\n          ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.data.payStatus === 1 &&
          _vm.data.orderStatus == 4 &&
          _vm.data.saleStatus == "3"
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px", "font-size": "14px" } },
                [
                  _vm._v(
                    "\n        拒绝原因：" +
                      _vm._s(_vm.data.refusedReason) +
                      "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.data.orderStatus == 1 && _vm.data.deliveryWay == 0
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px", "font-size": "14px" } },
                [
                  _vm._v(
                    "\n        自提门店：" + _vm._s(_vm.data.storeName) + " "
                  ),
                  _c("span", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v("门店地址：" + _vm._s(_vm.data.storeAddress))
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.data.orderStatus > 1 && _vm.data.deliveryWay == 1
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px", "font-size": "14px" } },
                [
                  _vm.data.productType === 2 || _vm.data.productType === 6
                    ? _c("div", [
                        _c("span", { staticStyle: { "margin-left": "20px" } }, [
                          _vm._v("充值账号：" + _vm._s(_vm.data.logisticNo))
                        ])
                      ])
                    : _vm.data.payStatus == 1
                    ? _c(
                        "div",
                        [
                          _vm._v(
                            "\n          物流公司：" +
                              _vm._s(_vm.data.logisticName) +
                              " "
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v("物流单号：" + _vm._s(_vm.data.logisticNo))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.handleDeliver }
                            },
                            [_vm._v("修改物流单号\n          ")]
                          ),
                          _vm._v(" "),
                          _vm.data.logisticCode
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { type: "success", size: "small" },
                                  on: { click: _vm.getLogisticDea }
                                },
                                [_vm._v("查看物流信息\n          ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { border: "", size: "mini", data: [1] } },
            [
              _c("el-table-column", {
                attrs: { label: "商品图片", align: "center", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("img", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: _vm.imgSrc + _vm.data.productImg }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品名称", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.data.productName) +
                            "\n          "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品编码", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.data.productCode) +
                            "\n          "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "商品数量",
                  prop: "productNum",
                  align: "center",
                  width: "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.data.productNum) +
                            "\n          "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品规格", align: "center", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.data.productSpec
                                ? JSON.parse(_vm.data.productSpec).join("x")
                                : ""
                            ) +
                            "\n          "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              attrs: {
                size: "small ",
                title: "订单信息",
                labelStyle: {
                  width: "120px",
                  textAlign: "right"
                },
                column: 3,
                border: ""
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "订单编号：" } }, [
                _vm._v("\n          " + _vm._s(_vm.data.orderNo) + "\n        ")
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "外部订单号：" } }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.data.outTradeNo) + "\n        "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "商品价格：" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.data.productPriceScoreStr) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "支付方式：" } }, [
                _vm.data.buyType == 1
                  ? _c("span", [
                      _vm._v("\n            金额+积分抵扣\n          ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.buyType == 2
                  ? _c("span", [_vm._v("\n            积分支付\n          ")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.buyType == 3
                  ? _c("span", [_vm._v("\n            金额加积分\n          ")])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "下单时间：" } }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.data.orderTime) + "\n        "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "支付时间：" } }, [
                _vm._v("\n          " + _vm._s(_vm.data.payTime) + "\n        ")
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "支付积分：" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.data.productScoreSubtotal) +
                    "积分\n        "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "支付金额：" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.data.productPriceSubtotal) +
                    "元\n        "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "优惠金额：" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.data.productPriceDiscount) +
                    "元\n        "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "邮费：" } }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.data.freightPrice) + "\n        "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "会员手机号：" } }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.data.userPhone) + "\n        "
                )
              ]),
              _vm._v(" "),
              _vm.data.productType == 6 || _vm.data.productType == 2
                ? _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label:
                          _vm.data.productType === 6
                            ? "充值号码："
                            : "充值账号："
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.logisticNo) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "收货人：" } }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.data.consigneeName) + "\n        "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "收货人电话：" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.data.consigneePhone) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "发货时间：" } }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.data.logisticTime) + "\n        "
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "收货地址：" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.data.consigneeAddress) +
                    "\n        "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _vm.data.orderStatus == 4
            ? _c(
                "el-descriptions",
                {
                  attrs: {
                    size: "small ",
                    title: "售后信息",
                    labelStyle: {
                      width: "120px",
                      textAlign: "right"
                    },
                    column: 3,
                    border: ""
                  }
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "退款单号：" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.refundOutNo) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "售后类型：" } },
                    [
                      _vm.data.saleType == 1
                        ? _c("span", [_vm._v("仅退款")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.data.saleType == 2
                        ? _c("span", [_vm._v("退款退货")])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "售后状态：" } },
                    [
                      _vm.data.saleStatus == "1"
                        ? _c("span", { staticStyle: { color: "#409EFF" } }, [
                            _vm._v("售后申请")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.data.saleStatus == "2"
                        ? _c("span", { staticStyle: { color: "#E6A23C" } }, [
                            _vm._v("退款中")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.data.saleStatus == "3"
                        ? _c("span", [_vm._v("已拒绝售后")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.data.saleStatus == "4"
                        ? _c("span", { staticStyle: { color: "#F56C6C" } }, [
                            _vm._v("已退款")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "售后时间：" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.refundTime) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "售后原因：", span: 2 } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.saleReason) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.data.attachment
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "售后图片：" } },
                        _vm._l(_vm.data.attachment, function(item, index) {
                          return _c("el-image", {
                            staticStyle: { width: "50px", height: "50px" },
                            attrs: {
                              src: item,
                              "preview-src-list": _vm.data.attachment
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.popShow
        ? _c("div", { staticClass: "fixed_center" }, [
            _c(
              "div",
              { staticClass: "fixed_box" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("提示")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "textarea",
                  attrs: {
                    type: "textarea",
                    autosize: { minRows: 2, maxRows: 4 },
                    placeholder: "请输入拒绝原因"
                  },
                  model: {
                    value: _vm.refusedReason,
                    callback: function($$v) {
                      _vm.refusedReason = $$v
                    },
                    expression: "refusedReason"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pop_btn flex_center" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "infor" },
                        on: {
                          click: function($event) {
                            _vm.popShow = false
                          }
                        }
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.goSale(1)
                          }
                        }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isScal
        ? _c("div", { staticClass: "fixed_center" }, [
            _c(
              "div",
              { staticClass: "scale_box" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("申请售后")]),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "100px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "售后原因：", prop: "saleReason" } },
                      [
                        _c("el-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.ruleForm.saleReason,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "saleReason", $$v)
                            },
                            expression: "ruleForm.saleReason"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "售后类型：", prop: "saleType" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.ruleForm.saleType,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "saleType", $$v)
                              },
                              expression: "ruleForm.saleType"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("仅退款")
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("退款退货")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "售后凭证：", prop: "attachment" } },
                      [
                        _c(
                          "el-upload",
                          {
                            attrs: {
                              action: _vm.action,
                              "list-type": "picture-card",
                              headers: _vm.headers,
                              "on-preview": _vm.handlePictureCardPreview,
                              "on-success": _vm.onSuccess,
                              "on-remove": _vm.handleRemove
                            }
                          },
                          [_c("i", { staticClass: "el-icon-plus" })]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onSubmit }
                          },
                          [_vm._v("立即申请")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.isScal = false
                              }
                            }
                          },
                          [_vm._v("取消")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("deliver-dr", {
        ref: "deliverDrRef",
        on: { finished: _vm.getDetail }
      }),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            "modal-append-to-body": false,
            visible: _vm.isActivities,
            size: 760,
            "custom-class": "cutom-drawer"
          },
          on: {
            "update:visible": function($event) {
              _vm.isActivities = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "header-title",
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _vm._v("\n        物流信息\n        "),
              _c(
                "span",
                {
                  class: {
                    success: _vm.activities.status == 3,
                    warning: _vm.activities.status == 2
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.activities.status_text || "") +
                      "\n        "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "30px" } },
            [
              _vm.activities.list && _vm.activities.list.length > 0
                ? _c(
                    "el-timeline",
                    _vm._l(_vm.activities.list, function(activity, index) {
                      return _c(
                        "el-timeline-item",
                        {
                          key: index,
                          attrs: {
                            color: index == 0 ? "#409EFF" : "",
                            timestamp: activity.datetime
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(activity.detail) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "6px",
                                color: "#666666",
                                "text-indent": "10px"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(activity.memo) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("el-empty", { attrs: { description: "暂无物流信息" } })
                    ],
                    1
                  )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }