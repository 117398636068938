var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    新增/编辑会员等级\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { ref: "name", attrs: { label: "活动名称：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "活动名称"
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: " 等级图片：", prop: "cardImageFront" } },
            [
              _c("add-load", {
                attrs: {
                  width: 250,
                  height: 180,
                  cover: _vm.ruleForm.cardImageFront
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "cardImageFront")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "targetUser",
              attrs: { label: "状态：", prop: "targetUser" }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.targetUser,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "targetUser", $$v)
                    },
                    expression: "ruleForm.targetUser"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("禁用")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "name", attrs: { label: "所需乐享值：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { placeholder: "所需乐享值" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.name"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v(
                  "每月1号计算乐享值，达到标准可升级，连续3次未达到标准则降级"
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "description",
              attrs: { prop: "description", label: "等级介绍：" }
            },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.ruleForm.description,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "description", $$v)
                  },
                  expression: "ruleForm.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }