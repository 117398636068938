"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _major = require("@/api/major.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      userInfo: {
        realname: '',
        mobile: '',
        card: '',
        coupnName: '',
        time: ''
      },
      imgSrc: this.$store.state.user.imgSrc,
      form: {
        couponCode: '',
        couponName: '',
        remark: '',
        usedTime: '',
        usedUname: '',
        couponTypeName: '',
        tradeOrderNo: ''
      },
      attachment: []
    };
  },
  created: function created() {
    var _this = this;

    var id = this.$route.query.id;
    this.openLoading();
    (0, _major.getCouponRightsView)(id).then(function (res) {
      _this.openLoading().close();

      Object.keys(_this.form).forEach(function (item) {
        Object.keys(res.data).forEach(function (row) {
          if (row == item) {
            _this.form[item] = res.data[row];
          }
        });
      });

      if (res.data.attachment) {
        _this.attachment = JSON.parse(res.data.attachment);
      }
    }).catch(function (err) {
      _this.openLoading().close();
    });
  },
  methods: {}
};
exports.default = _default;