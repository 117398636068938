"use strict";

var _interopRequireWildcard = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPageServiceCategoryList = getPageServiceCategoryList;

var _request = _interopRequireDefault(require("@/utils/request"));

var R = _interopRequireWildcard(require("ramda"));

/**
 * 服务列表（分页）
 */
function getPageServiceCategoryList(params) {
  return (0, _request.default)({
    url: "/api/svip/service/category/page",
    method: 'get',
    params: params
  });
}