"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _api = require("./api.js");

var _user = require("@/api/user.js");

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      gradeType: {
        1: '365会员',
        2: '全部会员',
        3: '普通会员'
      },
      url: '',
      couponUUid: '',
      qrCode: '',
      popShow: false,
      downloadLoading: false,
      time: null,
      requestData: {
        name: null,
        startTime: null,
        endTime: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '活动名称',
        prop: 'activityName',
        width: 100
      }, {
        slot: 'labelContent',
        label: '会员类型',
        prop: 'labelContent',
        width: 80
      }, {
        label: '总次数',
        prop: 'totalTimes',
        width: 80
      }, {
        label: '单人限制',
        prop: 'perMemberLimitTimes',
        width: 80
      }, {
        label: '购物卡',
        prop: 'cardUuid',
        width: 80
      }, {
        label: '充值（元）',
        prop: 'amoutStage',
        width: 80
      },
      /* {
        slot: 'time'
      }, */
      {
        label: '起始有效期',
        prop: 'startTime',
        width: 120
      }, {
        label: '结束有效期',
        prop: 'endTime',
        width: 120
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 120
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status'
      }, {
        slot: 'operate'
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {
    this.getList();
  },
  methods: {
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getQrcode: function getQrcode(scope) {
      var _this = this;

      // 获取小程序码
      this.couponUUid = scope.name;
      (0, _user.couponQRCode)({
        path: 'activity/apply/dea',
        scene: String(scope.id)
      }).then(function (res) {
        _this.url = 'activity/apply/dea?id=' + scope.id;
        _this.qrCode = res.data;
        _this.popShow = true;
      });
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this2.requestData[key]) {
          data[key] = _this2.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getList)(data).then(function (res) {
        list = res.data.records;
        _this2.downloadLoading = false;

        _this2.getHandleDownload(tHeader, filterVal, list, '充卡返利列表');
      }).catch(function (res) {
        _this2.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this3 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getList)(this.requestData).then(function (res) {
        _this3.tableData = res.data.records;
        _this3.requestData.total = res.data.total;
      });
    }
  }
};
exports.default = _default;