"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default,
    coupon: _getCoupon.default
  },
  data: function data() {
    return {
      popType: '',
      count: 0,
      couponShow: false,
      rules: {
        giveScore: [{
          required: true,
          message: '请输入赠送积分'
        }],
        typeName: [{
          required: true,
          message: '请输入会员类型名称'
        }],
        originalAmount: [{
          required: true,
          message: '请输入原价'
        }],
        saleAmount: [{
          required: true,
          message: '请输入售卖金额'
        }],
        validDay: [{
          required: true,
          message: '请输入生效天数'
        }],
        plusStatus: [{
          required: true,
          message: '请选择会员状态'
        }],
        plusIcon: [{
          required: true,
          message: '请上传会员图标'
        }],
        plusInterests: [{
          required: true,
          message: '请输入plus权益'
        }],
        openGifts: [{
          required: true,
          message: '请输入会员说明'
        }],
        putNumber: [{
          required: true,
          message: '请输入投放数量'
        }],
        firstCoupons: [{
          required: true,
          message: '请选择优惠券'
        }],
        sendCoupons: [{
          required: true,
          message: '请选择优惠券'
        }],
        limitType: [{
          required: true,
          message: '请选择限购类型'
        }],
        limitNumber: [{
          required: true,
          message: '请输入限购数量'
        }],
        limitYearNumber: [{
          required: true,
          message: '请输入全年最大数量'
        }],
        limitDay: [{
          required: true,
          message: '请输入限制天数'
        }]
      },
      surplusNumber: null,
      totalSaleNumber: null,
      ruleForm: {
        limitDay: null,
        limitYearNumber: null,
        giveScore: null,
        limitType: null,
        limitNumber: null,
        typeName: null,
        originalAmount: null,
        saleAmount: null,
        validDay: null,
        plusStatus: 0,
        plusIcon: null,
        plusInterests: null,
        putNumber: null,
        firstCoupons: [],
        sendCoupons: []
      }
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;

      if (this.ruleForm.id) {
        (0, _api.getVipPlus)(this.ruleForm.id).then(function (res) {
          _this.surplusNumber = res.data.surplusNumber;
          _this.totalSaleNumber = res.data.totalSaleNumber;

          _this.fzObj(res.data, _this.ruleForm);
        });
      }
    }
  },
  methods: {
    closeCouponPop: function closeCouponPop() {
      this.couponShow = false;
    },
    setCoupons: function setCoupons(e) {
      var _this2 = this;

      this.ruleForm[this.couponType] = [];
      e.forEach(function (item) {
        _this2.ruleForm[_this2.couponType].push(item.uuid);
      });
      this.couponShow = false;
    },
    setCover: function setCover(e) {
      this.ruleForm.plusIcon = e;
    },
    getRelevances: function getRelevances(type) {
      this.couponType = type;
      this.couponShow = true;
      this.$refs.couponPop.init();
      var arr = [];
      this.ruleForm[this.couponType].forEach(function (item) {
        arr.push({
          linkId: item
        });
      });
      this.$refs.couponPop.assignCoupon(arr);
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          if (_this3.ruleForm.id) {
            (0, _api.editVipPlus)(_this3.ruleForm).then(function (res) {
              _this3.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this3.$router.go(-1);
            });
          } else {
            (0, _api.addVipPlus)(_this3.ruleForm).then(function (res) {
              _this3.$router.go(-1);

              _this3.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          var data = Object.keys(object);
          var dom = _this3.$refs[data[0]];
          dom.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
        }
      });
    }
  }
};
exports.default = _default;