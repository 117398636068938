"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var cardRouter = {
  path: '/card',
  component: _layout.default,
  redirect: '/card/tab',
  name: 'card',
  meta: {
    title: '制卡计划'
  },
  children: [{
    path: 'tab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/tab.vue'));
      });
    },
    name: 'cardTab',
    meta: {
      title: '制卡计划'
    }
  }, {
    path: 'classList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/class/list.vue'));
      });
    },
    name: 'cardClassList',
    meta: {
      title: '制卡计划-种类',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }, {
    path: 'classAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/class/add.vue'));
      });
    },
    name: 'cardClassAdd',
    meta: {
      title: '制卡计划-种类',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'classDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/class/dea.vue'));
      });
    },
    name: 'cardClassDea',
    meta: {
      title: '制卡计划-卡中种详情',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'categoryList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/category/list.vue'));
      });
    },
    name: 'cardcategoryList',
    meta: {
      title: '制卡计划-类型',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }, {
    path: 'categoryAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/category/add.vue'));
      });
    },
    name: 'cardcategoryAdd',
    meta: {
      title: '制卡计划-类型',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'planList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/plan/list.vue'));
      });
    },
    name: 'cardplanList',
    meta: {
      title: '制卡计划-列表',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }, {
    path: 'planAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/plan/add.vue'));
      });
    },
    name: 'cardplanAdd',
    meta: {
      title: '制卡计划-新增编辑',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'planDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/plan/dea.vue'));
      });
    },
    name: 'cardplanDea',
    meta: {
      title: '制卡计划-计划详情',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'planInOut',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/plan/inOut.vue'));
      });
    },
    name: 'planInOut',
    meta: {
      title: '制卡计划-卡片入库',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'infoList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/info/list.vue'));
      });
    },
    name: 'cardinfoList',
    meta: {
      title: '制卡计划-卡信息',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }, {
    path: 'infoAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/info/add.vue'));
      });
    },
    name: 'cardinfoAdd',
    meta: {
      title: '制卡计划-卡信息新增编辑',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'infoCheck',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/info/check.vue'));
      });
    },
    name: 'cardinfoCheck',
    meta: {
      title: '制卡计划-卡校验',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'inOutList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/inOut/list.vue'));
      });
    },
    name: 'inOutList',
    meta: {
      title: '制卡计划-卡管理',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }, {
    path: 'inOutOut',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/inOut/inOut.vue'));
      });
    },
    name: 'inOutOut',
    meta: {
      title: '制卡计划-卡出库',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'infoAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/inOut/add.vue'));
      });
    },
    name: 'inOutAdd',
    meta: {
      title: '制卡计划-卡管理出库入库',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'shop',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/shop/list.vue'));
      });
    },
    name: 'cardShop',
    meta: {
      title: '制卡计划-卡销售批量',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }, {
    path: 'shopAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/shop/add.vue'));
      });
    },
    name: 'cardShopAdd',
    meta: {
      title: '制卡计划-编辑卡销售批量',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'shopDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/shop/dea.vue'));
      });
    },
    name: 'cardShopDea',
    meta: {
      title: '制卡计划-销售详情',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'store',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/store/list.vue'));
      });
    },
    name: 'cardstore',
    meta: {
      title: '制卡计划-门店卡',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }, {
    path: 'storeAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/store/add.vue'));
      });
    },
    name: 'cardstoreAdd',
    meta: {
      title: '制卡计划-编辑门店卡',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'erpRenew',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/store/erpRenew.vue'));
      });
    },
    name: 'erpRenew',
    meta: {
      title: '昂捷卡换新卡'
    }
  }, {
    path: 'bindCardOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/store/bindOrder.vue'));
      });
    },
    name: 'bindCardOrder',
    meta: {
      title: '绑线上卡记录',
      keepAlive: true
    }
  }, {
    path: 'cardDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/store/dea.vue'));
      });
    },
    name: 'cardcardDea',
    meta: {
      title: '卡详情',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'realName',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/store/realName.vue'));
      });
    },
    name: 'cardstorerealName',
    meta: {
      title: '制卡计划-实名',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'loss',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/store/loss.vue'));
      });
    },
    name: 'cardstoreloss',
    meta: {
      title: '制卡计划-挂失',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'payType',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/payType/list.vue'));
      });
    },
    name: 'payType',
    meta: {
      title: '制卡计划-支付方式',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }, {
    path: 'payTypeAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/payType/add.vue'));
      });
    },
    name: 'payTypeAdd',
    meta: {
      title: '制卡计划-编辑支付方式',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'rebateOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/rebateOrder/list.vue'));
      });
    },
    name: 'rebateOrder',
    meta: {
      title: '返利订单',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }, {
    path: 'rebateOrderAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/rebateOrder/add.vue'));
      });
    },
    name: 'rebateOrderAdd',
    meta: {
      title: '编辑返利订单',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'rebateOrderDea',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/rebateOrder/dea.vue'));
      });
    },
    name: 'rebateOrderDea',
    meta: {
      title: '返利详情',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'rebate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/rebate/list.vue'));
      });
    },
    name: 'rebate',
    meta: {
      title: '返利列表',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }, {
    path: 'rebateAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/rebate/add.vue'));
      });
    },
    name: 'rebateAdd',
    meta: {
      title: '编辑返利',
      activeMenu: '/card/tab'
    }
  }, {
    path: 'recharge',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/recharge/list.vue'));
      });
    },
    name: 'cardRechargeLIst',
    meta: {
      title: '充值列表',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }, {
    path: 'refund',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/card/refund/list.vue'));
      });
    },
    name: 'cardRefundList',
    meta: {
      title: '退款列表',
      keepAlive: true,
      activeMenu: '/card/tab'
    }
  }]
};
var _default = cardRouter;
exports.default = _default;