var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("新增硬核好券商品")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "会员类型：", prop: "vipCode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.ruleForm.vipCode,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "vipCode", $$v)
                        },
                        expression: "ruleForm.vipCode"
                      }
                    },
                    _vm._l(_vm.vipList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.vipName, value: item.vipCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "硬核好券分类：", prop: "classId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.ruleForm.classId,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "classId", $$v)
                        },
                        expression: "ruleForm.classId"
                      }
                    },
                    _vm._l(_vm.classList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.className, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "投放状态：", prop: "putStatus" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.putStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "putStatus", $$v)
                        },
                        expression: "ruleForm.putStatus"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("停用")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "硬核好券信息：", prop: "openGifts" } },
                [
                  !_vm.ruleForm.id
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.getRelevances }
                        },
                        [_vm._v("添加硬核好券商品")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.ruleForm.openGifts,
                        size: "mini",
                        border: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "奖励类型",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "openGifts." +
                                      scope.$index +
                                      ".productType",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "openGifts." +
                                        scope.$index +
                                        ".productType",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择奖励类型"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          placeholder: "奖励类型"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setAward(
                                              $event,
                                              scope.$index
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.productType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.productType"
                                        }
                                      },
                                      _vm._l(_vm.type, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "关联礼品",
                          "min-width": "200",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "openGifts." +
                                      scope.$index +
                                      ".productCode",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "openGifts." +
                                        scope.$index +
                                        ".productCode",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择礼品"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.getAward(
                                              scope.row.productType,
                                              scope.$index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              scope.row.attrType == 3
                                                ? "选择优惠券"
                                                : "选择商品"
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    scope.row.productCode
                                      ? _c("p", [
                                          _vm._v(_vm._s(scope.row.productCode))
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品名称",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n                 " +
                                    _vm._s(scope.row.productName || "") +
                                    "\n               "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "联名卡支付金额",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    _c("el-input", {
                                      attrs: { size: "mini", type: "number" },
                                      model: {
                                        value: scope.row.lmDesc,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "lmDesc", $$v)
                                        },
                                        expression: "scope.row.lmDesc"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品排序",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "openGifts." +
                                      scope.$index +
                                      ".productSort",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "openGifts." +
                                        scope.$index +
                                        ".productSort",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入奖品排序"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { size: "mini", type: "number" },
                                      model: {
                                        value: scope.row.productSort,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "productSort",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.productSort"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "table_td_btn",
                                    on: {
                                      click: function($event) {
                                        return _vm.delAward(scope.$index)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "投放周期：", prop: "putType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.putType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "putType", $$v)
                        },
                        expression: "ruleForm.putType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("单次")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("按月重复")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.putType === 1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "开始时间：", prop: "putBeginTime" }
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm",
                              placeholder: "选择日期时间"
                            },
                            model: {
                              value: _vm.ruleForm.putBeginTime,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "putBeginTime", $$v)
                              },
                              expression: "ruleForm.putBeginTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "结束时间：", prop: "putEndTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm",
                              placeholder: "选择日期时间"
                            },
                            model: {
                              value: _vm.ruleForm.putEndTime,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "putEndTime", $$v)
                              },
                              expression: "ruleForm.putEndTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "投放数量：", prop: "putTotal" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "form_input",
                              model: {
                                value: _vm.ruleForm.putTotal,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "putTotal",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.putTotal"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("份")])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "每人限领：", prop: "limitNumber" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "form_input",
                              model: {
                                value: _vm.ruleForm.limitNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "limitNumber",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.limitNumber"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("次")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.putType === 2
                ? _c(
                    "div",
                    { staticClass: "select_style" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "每月：", prop: "dateList" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_input",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "popper-class": "date_list",
                                "popper-append-to-body": false,
                                multiple: "",
                                placeholder: "请选择"
                              },
                              model: {
                                value: _vm.ruleForm.dateList,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "dateList", $$v)
                                },
                                expression: "ruleForm.dateList"
                              }
                            },
                            _vm._l(31, function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  label: item,
                                  value: item >= 10 ? String(item) : "0" + item
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: " 选择时间：", prop: "dateTime" } },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              "is-range": "",
                              "value-format": "HH:mm",
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              placeholder: "选择时间范围"
                            },
                            model: {
                              value: _vm.ruleForm.dateTime,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "dateTime", $$v)
                              },
                              expression: "ruleForm.dateTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "每周期总量：", prop: "putTotal" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "form_input",
                              model: {
                                value: _vm.ruleForm.putTotal,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "putTotal",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.putTotal"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("份")])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "每人限领：", prop: "limitType" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.changeLimit },
                                  model: {
                                    value: _vm.ruleForm.limitType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "limitType", $$v)
                                    },
                                    expression: "ruleForm.limitType"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "不限领", value: 0 }
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "一共可领", value: 1 }
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "每周期可领", value: 2 }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.ruleForm.limitType !== 0
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "20px",
                                    prop: "limitNumber"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "form_input",
                                      model: {
                                        value: _vm.ruleForm.limitNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "limitNumber",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "ruleForm.limitNumber"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("次")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }