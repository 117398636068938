"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

var _getCoupon = _interopRequireDefault(require("@/components/couponPop/getCoupon.vue"));

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    wTable: _index.default,
    coupon: _getCoupon.default,
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default
  },
  computed: {
    placeholderText: function placeholderText() {
      if (this.ruleForm.pageType === 1) {
        return '跳转的地址';
      } else if (this.ruleForm.pageType === 2) {
        return '跳转的小程序';
      } else if (this.ruleForm.pageType === 3) {
        return '的页面编码';
      } else {
        return '跳转的地址';
      }
    }
  },
  data: function data() {
    return {
      /* 页面类型(1：外部地址 2：小程序地址 3：页面编码)*/
      urlList: [{
        label: '外部地址',
        value: 1
      }, {
        label: '小程序地址',
        value: 2
      }, {
        label: '页面编码',
        value: 3
      }],
      appIdArr: _selectUrl.default.appIdArr,
      couponShow: false,
      vipList: [],
      columns: [{
        label: '券编码',
        prop: 'couponCode',
        width: 100
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 100
      }, {
        label: '券类型',
        prop: 'couponType',
        width: 100
      }, {
        label: '面额/折扣',
        prop: 'disdenStr',
        width: 100
      }, {
        label: '有效期',
        prop: 'validStr',
        width: 100
      }],
      rules: {
        // 权益排序：interestsSort
        interestsSort: [{
          required: true,
          message: '请输入权益排序'
        }],
        interestsIcon: [{
          required: true,
          message: '请上传图片'
        }],
        interestsRemark: [{
          required: true,
          message: '请输入备注'
        }],
        couponCodes: [{
          required: true,
          message: '请选择优惠券'
        }],
        interestsName: [{
          required: true,
          message: '请输入权益包名称'
        }],
        vipCode: [{
          required: true,
          message: '请选择显示到会员'
        }],
        interestsStatus: [{
          required: true,
          message: '请选择状态'
        }],
        pushIndex: [{
          required: true,
          message: '请选择是否推荐首页'
        }],
        pageType: [{
          required: true,
          message: '请选择跳转路径'
        }],
        pageAppid: [{
          required: true,
          message: '请选择小程序'
        }],
        pageCode: [{
          required: true,
          message: '请输入路径或编码'
        }]
      },
      ruleForm: {
        pageType: null,
        pageAppid: null,
        interestsSort: null,
        pushIndex: null,
        interestsIcon: null,
        pageCode: null,
        interestsRemark: null,
        couponCodes: [],
        interestsStatus: null,
        vipCode: null,
        interestsName: null
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.listVip)().then(function (res) {
      _this.vipList = res.data;
    });

    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      (0, _api.getVipInterests)(this.ruleForm.id).then(function (res) {
        _this.ruleForm.interestsName = res.data.interestsName;
        _this.ruleForm.interestsStatus = res.data.interestsStatus;
        _this.ruleForm.vipCode = res.data.vipCode;
        _this.ruleForm.couponCodes = res.data.couponInfos;
        _this.ruleForm.interestsRemark = res.data.interestsRemark;
        _this.ruleForm.interestsSort = res.data.interestsSort;
        _this.ruleForm.interestsIcon = res.data.interestsIcon;
        _this.ruleForm.pageCode = res.data.pageCode;
        _this.ruleForm.pushIndex = res.data.pushIndex;
        _this.ruleForm.pageType = res.data.pageType;
        _this.ruleForm.pageAppid = res.data.pageAppid;
      });
    }
  },
  methods: {
    urlChange: function urlChange(e) {
      console.log(e);
    },
    setCover: function setCover(e) {
      this.ruleForm.interestsIcon = e;
    },
    getRelevances: function getRelevances() {
      this.$refs.couponPop.init();
      var arr = [];

      if (this.ruleForm.couponCodes.length > 0) {
        this.ruleForm.couponCodes.forEach(function (item) {
          arr.push({
            linkId: item.couponCode
          });
        });
      }

      this.$refs.couponPop.assignCoupon(arr);
      this.couponShow = true;
    },
    setCoupons: function setCoupons(e) {
      var _this2 = this;

      var arr = [];

      if (e.length > 0) {
        e.forEach(function (item) {
          var data;

          var flag = _this2.ruleForm.couponCodes.some(function (value, index) {
            data = value;
            return value.couponCode == item.uuid;
          });

          if (flag) {
            arr.push(data);
          } else {
            var discount, validTime, couponType;

            if (item.couponType == '折扣券') {
              discount = item.discount / 10 + '折';
            } else {
              discount = item.denomination + '元';
            }

            if (item.validTimeType == 1) {
              validTime = item.validBeginTime + '-' + item.validEndTime;
            } else {
              validTime = '领取后' + item.validDay + '天有效';
            }

            arr.push({
              couponCode: item.uuid,
              couponName: item.name,
              couponType: item.couponType,
              disdenStr: discount,
              validStr: validTime
            });
          }
        });
      }

      this.ruleForm.couponCodes = arr;
      this.couponShow = false;
    },
    closeCouponPop: function closeCouponPop() {
      this.couponShow = false;
    },
    init: function init() {
      var _this3 = this;

      this.ruleForm.id = Number(this.$route.query.id);

      if (this.ruleForm.id) {
        getTaxRate({
          id: this.ruleForm.id
        }).then(function (res) {
          _this3.ruleForm.spbm = res.data.spbm;
          _this3.ruleForm.sl = res.data.sl;
        });
      }
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var couponCodes = [];

          _this4.ruleForm.couponCodes.forEach(function (item) {
            couponCodes.push(item.couponCode);
          });

          var data = {
            interestsName: _this4.ruleForm.interestsName,
            interestsStatus: _this4.ruleForm.interestsStatus,
            vipCode: _this4.ruleForm.vipCode,
            interestsRemark: _this4.ruleForm.interestsRemark,
            couponCodes: couponCodes,
            interestsIcon: _this4.ruleForm.interestsIcon,
            pageCode: _this4.ruleForm.pageCode,
            pushIndex: _this4.ruleForm.pushIndex,
            interestsSort: _this4.ruleForm.interestsSort,
            pageType: _this4.ruleForm.pageType,
            pageAppid: _this4.ruleForm.pageAppid
          };

          if (_this4.ruleForm.id) {
            data.id = _this4.ruleForm.id;
            (0, _api.editVipInterests)(data).then(function (res) {
              _this4.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this4.$router.go(-1);
            });
          } else {
            (0, _api.addVipInterests)(data).then(function (res) {
              _this4.$router.go(-1);

              _this4.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;