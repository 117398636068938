"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      params: {},
      rules: {},
      list: []
    };
  },
  methods: {
    onAddRow: function onAddRow() {
      this.params.list.push({});
    },
    onDeleteRow: function onDeleteRow(index) {
      this.params.list.splice(index, 1);
    },
    onConfirm: function onConfirm() {},
    onCancel: function onCancel() {}
  }
};
exports.default = _default;