"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _mem = require("@/api/mem.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _integral = require("@/api/integral.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      companyList: [],
      storeList: [],
      total: 0,
      obj: {
        pageNo: 1,
        pageSize: 10,
        specialName: '',
        specialStatus: '',
        scoreMultiple: '',
        storeCode: '',
        companyCode: '',
        brandClsCode: '',
        brandCode: '',
        goodsCode: ''
      },
      typeArr: [{
        value: 1,
        label: '启用'
      }, {
        value: -1,
        label: '禁用'
      }],
      tabTh: [{
        prop: 'scoreMultiple',
        label: '策略类型',
        width: 120
      }, {
        prop: 'specialName',
        label: '策略（活动）名称',
        width: 120
      }, {
        prop: 'validStartTime',
        label: '开始时间',
        width: 120
      }, {
        prop: 'validEndTime',
        label: '结束时间',
        width: 120
      }, {
        prop: 'specialStatus',
        label: '状态',
        width: 120
      }]
    };
  },
  created: function created() {
    var _this = this;

    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.companyList = res.data;
    });
    (0, _mem.listStore)({}).then(function (res) {
      _this.storeList = res.data;
    });
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    setShort: function setShort(e) {
      var _this2 = this;

      this.obj.storeCode = '';
      this.companyList.forEach(function (item) {
        if (item.companyCode == e) {
          (0, _mem.listStore)({
            companyId: item.companyId
          }).then(function (res) {
            _this2.storeList = res.data;
          });
        }
      });
    },
    goPage: function goPage(type, id) {
      if (type == 'add') {
        this.$router.push('/integral/addSpecial');
      } else {
        this.$router.push('/integral/addSpecial?id=' + id);
      }
    },
    init: function init(type) {
      var _this3 = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
        this.obj.pageSize = 10;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
      }

      this.openLoading();
      (0, _integral.listScoreSpecialPage)(this.obj).then(function (res) {
        _this3.openLoading().close();

        _this3.list = res.data.items;
        _this3.total = res.data.totalNum;

        _this3.list.forEach(function (item) {
          // item.time=item.createTime+'-'
          item.specialStatus = item.specialStatus == 1 ? '启用' : '停用';

          if (item.scoreType == 1) {
            item.scoreMultiple = '不积积分';
          } else if (item.scoreType == 2) {
            item.scoreMultiple = '多倍积分（' + item.sellAmount + '倍）';
          } else if (item.scoreType == 3) {
            item.scoreMultiple = '重置积分（' + item.sellAmount + '元' + item.getScore + '积分）';
          }

          switch (item.costType) {
            case 1:
              item.costText = 'A类-集团公司承担';
              break;

            case 2:
              item.costText = 'B类-专柜/品牌/商户承担';
              break;

            case 3:
              item.costText = 'C类-集团与商户按比例承担';
              break;

            default:
              break;
          }
        });
      }).catch(function (err) {
        _this3.openLoading().close();
      });
    }
  }
};
exports.default = _default;