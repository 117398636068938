"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      id: '',
      active: -1,
      btnType: 0,
      status: '',
      imgSrc: this.$store.state.user.imgSrc,
      step: [{
        tit: '发布方案（待审核）',
        txt: ''
      }, {
        tit: '审核通过（待投放）',
        txt: ''
      }, {
        tit: '执行方案（已投放）',
        txt: ''
      }],
      name: '',
      mainImage: '',
      remark: '',
      coupons: []
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    setActive: function setActive(index) {
      switch (index) {
        case 1:
          this.status = '待审核';
          this.active = index;
          this.btnType = 1;
          break;

        case 2:
          this.status = '已审核';
          this.active = 2;
          this.btnType = 2;
          break;

        case 3:
          this.status = '审核撤回';
          this.active = 1;
          this.btnType = 1;
          break;

        case 4:
          this.status = '审核撤销';
          this.active = -1;
          this.btnType = 0;
          break;

        case 6:
          this.status = '已终止';
          this.active = -1;
          this.btnType = 0;
          break;

        case 8:
          this.status = '已投放';
          this.active = 3;
          this.btnType = 0;
          break;

        case 9:
          this.status = '投放完成';
          this.active = 4;
          this.btnType = 0;
          break;
      }
    },
    init: function init() {
      var _this = this;

      this.id = Number(this.$route.query.id);
      (0, _coupon.getCouponBagById)(this.id).then(function (res) {
        _this.setActive(res.data.status);

        _this.name = res.data.name;
        _this.mainImage = res.data.mainImage;
        _this.remark = res.data.remark;
        _this.coupons = res.data.coupons;
        var putOpr = res.data.oprLogs;
        putOpr.forEach(function (item) {
          if (item.oprType === 1) {
            _this.step[0].txt = item.oprUserName + item.oprTime;
          } else if (item.oprType === 2) {
            _this.step[1].txt = item.oprUserName + item.oprTime;
          }
        });
        res.data.coupons.forEach(function (value) {
          if (value.couponType == '1') {
            value.couponType = '代金券';
          } else if (value.couponType == '2') {
            value.couponType = '折扣券';
          } else if (value.couponType == '3') {
            value.couponType = '礼品券';
          } else if (value.couponType == '4') {
            value.couponType = '体验券';
          } else if (value.couponType == 5) {
            value.couponType = '外部优惠券';
          }
        });
      });
    },
    setStatus: function setStatus(index) {
      var _this2 = this;

      // 通过审核
      var str = '';

      if (index === 2) {
        str = '此操作将通过审核方案，审核通过后将不可修改， 是否继续?';
      } else if (index === 3) {
        str = '此操作将撤回审核， 是否继续?';
      } else if (index === 4) {
        str = '此操作将撤销审核，撤销通过后将不可修改， 是否继续?';
      } else if (index === 6) {
        str = '此操作将终止方案，终止通过后将作废， 是否继续?';
      }

      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _coupon.editCouponBagStatu)(_this2.id, index).then(function (res) {
          if (res.ret === 1000) {
            _this2.$message({
              type: 'success',
              message: '审核成功!'
            });

            _this2.setActive(index);
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消审核'
        });
      });
    },
    goEditor: function goEditor() {
      this.$router.push('/coupon/coupon1/addCouGift?id=' + this.id);
    }
  }
};
exports.default = _default;