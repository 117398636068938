"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.object.values");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.set");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

var _regionAll = _interopRequireDefault(require("@/utils/regionAll.json"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      ruleItem: {
        first: [{
          required: true,
          message: '请输入'
        }],
        firstPrice: [{
          required: true,
          message: '请输入运费'
        }],
        second: [{
          required: true,
          message: '请输入'
        }],
        secondPrice: [{
          required: true,
          message: '请输入运费'
        }],
        citySelect: [{
          required: true,
          message: '请选择城市'
        }]
      },
      ruleItemForm: {
        first: undefined,
        firstPrice: undefined,
        second: undefined,
        secondPrice: undefined,
        citySelect: []
      },
      disCity: [],
      ruleIndex: 0,
      price: undefined,
      isRulePop: false,
      selectRgion: [],
      checkList: [],
      citySelect: [],
      cityData: {
        city0: {
          value: [],
          pValue: [],
          allValue: false,
          checkAllL: false
        },
        city1: {
          value: [],
          pValue: [],
          allValue: false,
          checkAllL: false
        },
        city2: {
          value: [],
          pValue: [],
          allValue: false,
          checkAllL: false
        },
        city3: {
          value: [],
          pValue: [],
          allValue: false,
          checkAllL: false
        },
        city4: {
          value: [],
          pValue: [],
          allValue: false,
          checkAllL: false
        },
        city5: {
          value: [],
          pValue: [],
          allValue: false,
          checkAllL: false
        },
        city6: {
          value: [],
          pValue: [],
          allValue: false,
          checkAllL: false
        },
        city7: {
          value: [],
          pValue: [],
          allValue: false,
          checkAllL: false
        }
      },
      props: {
        multiple: true,
        label: 'label',
        value: "label",
        checkStrictly: true
      },
      formData: [{
        label: '规则名称：',
        type: 'input',
        placeholder: '请输入规则名称',
        value: 'name'
      }, {
        label: '类型：',
        type: 'radio',
        placeholder: '请选择类型',
        slot: 'type',
        value: 'type',
        selectList: [{
          label: '计件',
          value: 1
        }, {
          label: '计重',
          value: 2
        }]
      }, {
        slot: 'rule',
        placeholder: '请添加规则',
        value: 'rule'
      }],
      ruleForm: {
        "id": null,
        name: null,
        type: undefined,
        rule: []
      },
      editCitys: []
    };
  },
  computed: {
    selectAllCity: function selectAllCity() {
      var city = [];
      this.ruleForm.rule.forEach(function (item) {
        city = [].concat((0, _toConsumableArray2.default)(city), (0, _toConsumableArray2.default)(item.citySet));
      });
      return city;
    }
  },
  created: function created() {
    var _this = this;

    this.dealTree();
    this.ruleForm.id = this.$route.query.id || '';

    if (this.ruleForm.id) {
      this.apiPost('/api/mall/deliveryRule/detail', {
        id: this.ruleForm.id
      }).then(function (res) {
        console.log(res);
        _this.ruleForm = res.data;
      });
    }
  },
  methods: {
    getSelectCityNum: function getSelectCityNum(data) {
      var nowCitys = data.map(function (item) {
        return item.label;
      });
      var nowNum = this.ruleItemForm.citySelect.filter(function (item) {
        return nowCitys.includes(item);
      }).length;
      return nowNum;
    },

    /*
    删除子规则
    */
    delRuleItem: function delRuleItem(index) {
      this.ruleForm.rule.splice(index, 1);
    },

    /*
    禁用已经选择过的省
    */
    getDisableP: function getDisableP(data, index) {
      var _this2 = this;

      var pData = data.children.filter(function (item) {
        return _this2.selectAllCity.includes(item.label);
      });
      /* if(pData.length==data.children.length){
       } */

      return pData.length == data.children.length;
    },

    /*
    添加规则子集
     */
    goAffrimRule: function goAffrimRule(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid, object) {
        if (valid) {
          /* 判断当前选中的市区是否已经配置过 */
          var commonElements = _this3.ruleItemForm.citySelect.filter(function (item) {
            return _this3.selectAllCity.includes(item);
          });

          if (commonElements.length > 0) {
            _this3.$alert("".concat(commonElements.join('、'), "\u5DF2\u7ECF\u914D\u7F6E\u6709\u5305\u90AE\u89C4\u5219\uFF01"), '温馨提示', {
              confirmButtonText: '确定',
              callback: function callback(action) {}
            });

            return;
          }
          /*
          添加规则
          */


          if (_this3.ruleIndex >= 0) {
            _this3.$set(_this3.ruleForm.rule, _this3.ruleIndex, {
              first: _this3.ruleItemForm.first,
              firstPrice: _this3.ruleItemForm.firstPrice,
              second: _this3.ruleItemForm.second,
              secondPrice: _this3.ruleItemForm.secondPrice,
              citySet: _this3.ruleItemForm.citySelect
            });
          } else {
            _this3.ruleForm.rule.push({
              first: _this3.ruleItemForm.first,
              firstPrice: _this3.ruleItemForm.firstPrice,
              second: _this3.ruleItemForm.second,
              secondPrice: _this3.ruleItemForm.secondPrice,
              citySet: _this3.ruleItemForm.citySelect
            });
          }

          _this3.isRulePop = false;
        } else {}
      });
    },

    /*
    编辑规则
    */
    goEditItem: function goEditItem(item, index) {
      var _self = this;

      function toggleSelected(node) {
        var count = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
        var key = arguments.length > 2 ? arguments[2] : undefined;
        var isNext = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
        node.children.forEach(function (city, itemIndex) {
          /*
          获取当前省的所有城市
          */
          var itemCitys = city.children.map(function (row, rowKey) {
            return row.label;
          });
          /*
          获取当前省的所有选中城市
          */

          var itemSelectCitys = itemCitys.filter(function (row) {
            return item.citySet.includes(row);
          });
          /*
           选中城市与当前城市相同时  全选中
           小于当前城市时           半选中
           没有选中城市时           不选中
          */

          if (itemSelectCitys.length == itemCitys.length) {
            _self.cityData['city' + count].value.push(city.label);
          } else if (itemSelectCitys.length > 0) {
            _self.cityData['city' + count].pValue.push(city.label);
          } else {
            _self.cityData['city' + count].value = _self.cityData['city' + count].value.filter(function (pCity) {
              return pCity != city.label;
            });
            _self.cityData['city' + count].pValue = _self.cityData['city' + count].pValue.filter(function (pCity) {
              return pCity != city.label;
            });
          }

          _self.cityData['city' + count].value = (0, _toConsumableArray2.default)(new Set(_self.cityData['city' + count].value));
          _self.cityData['city' + count].pValue = (0, _toConsumableArray2.default)(new Set(_self.cityData['city' + count].pValue));
        });

        if (_self.cityData['city' + count].value.length == node.children.length) {
          _self.cityData['city' + count].checkAllL = true;
        } else if (_self.cityData['city' + count].value.length > 0) {
          _self.cityData['city' + count].checkAllL = false;
          _self.cityData['city' + count].allValue = true;
        } else {
          _self.cityData['city' + count].checkAllL = false;
          _self.cityData['city' + count].allValue = false;
        }
      }

      this.selectRgion.forEach(function (node, count) {
        toggleSelected(node, count);
      });
      this.ruleItemForm.first = item.first;
      this.ruleItemForm.firstPrice = item.firstPrice;
      this.ruleItemForm.second = item.second;
      this.ruleItemForm.secondPrice = item.secondPrice;
      this.ruleItemForm.citySelect = item.citySet;
      this.editCitys = JSON.parse(JSON.stringify(item));
      var ruleItem = JSON.parse(JSON.stringify(item));
      ruleItem.citySet = [];
      this.$set(this.ruleForm.rule, index, ruleItem);
      this.ruleIndex = index;
      this.$nextTick(function () {
        this.isRulePop = true;
      });
    },

    /*
    取消保存
    */
    goCancelEidt: function goCancelEidt() {
      this.isRulePop = false;
      /*
      如果是编辑子规则 取消赋值之前的数据
      */

      if (this.ruleIndex >= 0) {
        this.$set(this.ruleForm.rule, this.ruleIndex, this.editCitys);
      }
    },

    /*
    添加规则
    */
    goAddRule: function goAddRule() {
      if (!this.ruleForm.type) {
        this.$message.error('请先选择运费类型');
        return;
      }

      this.ruleIndex = -1;
      this.ruleItemForm.citySelect = [];
      this.ruleItemForm.first = undefined;
      this.ruleItemForm.firstPrice = undefined;
      this.ruleItemForm.second = undefined;
      this.ruleItemForm.secondPrice = undefined;
      /* 清空选项 */

      Object.values(this.cityData).forEach(function (value) {
        value.value = [];
        value.allValue = false;
        value.checkAllL = false;
      });
      this.$nextTick(function () {
        this.isRulePop = true;
      });
    },

    /*
    市选中
    */
    changecityItem: function changecityItem(e, index, data, pCity) {
      /*
      获取当前省的所有市区
      */
      var itemData = data.children.map(function (item) {
        return item.label;
      });
      /*
      数组1是否包含在数组2中
      */

      function arraysHaveCommonElements(arr1, arr2) {
        return arr1.every(function (item) {
          return arr2.includes(item);
        });
      }

      if (arraysHaveCommonElements(itemData, this.ruleItemForm.citySelect)) {
        /*
        包含的时候  选中省
        */
        if (!this.cityData['city' + index].value.includes(data.label)) {
          this.cityData['city' + index].value.push(data.label);
        }
        /*
        省选中的时候  取消半选择状态
        */


        this.cityData['city' + index].pValue = this.cityData['city' + index].pValue.filter(function (item) {
          return item != data.label;
        });
      } else {
        /*
        获取当前选中的市区
        */
        var itemDataSelect = this.ruleItemForm.citySelect.map(function (item) {
          return itemData.includes(item);
        });

        if (itemDataSelect <= 0) {
          /*
          没有选中的当前省选中的市区  取消半选择状态
          */
          this.cityData['city' + index].pValue = this.cityData['city' + index].pValue.filter(function (item) {
            return item != data.label;
          });
        } else if (!this.cityData['city' + index].pValue.includes(data.label)) {
          /*
          有选中的市区  半选择状态
          */
          this.cityData['city' + index].pValue.push(data.label);
        }
      }
      /*
      赋值地区选择状态
      */


      var pSelectData = this.cityData['city' + index].pValue;
      this.cityData['city' + index].allValue = pSelectData.length != pCity.length;
      this.cityData['city' + index].checkAllL = pSelectData.length == pCity.length;

      if (pSelectData.length == 0) {
        this.cityData['city' + index].allValue = false;
      }
    },

    /*
    省选中
    */
    changPcity: function changPcity(e, index, data, pCity) {
      var _this4 = this;

      /*
      获取省下面的所有城市
      */
      var selectCityData = data.children.map(function (item) {
        return item.label;
      });
      /*
      判断当前状态是否选中
      */

      if (!e) {
        this.ruleItemForm.citySelect = this.ruleItemForm.citySelect.filter(function (item) {
          return !selectCityData.includes(item);
        });
        this.cityData['city' + index].pValue = this.cityData['city' + index].pValue.filter(function (item) {
          return item != data.label;
        });
        this.cityData['city' + index].value = this.cityData['city' + index].value.filter(function (item) {
          return item != data.label;
        });
      } else {
        var pCitySelect = selectCityData.filter(function (item) {
          return !_this4.selectAllCity.includes(item);
        });
        this.ruleItemForm.citySelect = (0, _toConsumableArray2.default)(new Set([].concat((0, _toConsumableArray2.default)(this.ruleItemForm.citySelect), (0, _toConsumableArray2.default)(pCitySelect))));
      }
      /*
      获取当前地区所有选中的值  改变地区选中状态
      */


      var pSelectData = this.cityData['city' + index].value;
      this.cityData['city' + index].allValue = pSelectData.length != pCity.length;
      this.cityData['city' + index].checkAllL = pSelectData.length == pCity.length;

      if (pSelectData.length == 0) {
        this.cityData['city' + index].allValue = false;
      }
    },

    /*
    省选中
    */
    changeCity: function changeCity(e, index, data, pData) {
      // console.log(pData)
      return;

      var _slef = this;
      /*
      地区选中和半选中状态赋值
      */


      this.cityData['city' + index].allValue = e.length != data.length;
      this.cityData['city' + index].checkAllL = e.length == data.length;

      if (e.length == 0) {
        this.cityData['city' + index].allValue = false;
      }
      /*
      获取选中的城市
      */


      var cityItem = [];
      e.forEach(function (item) {
        var slectCityItem = data.find(function (city) {
          return city.label === item && !_slef.selectAllCity.includes(city.label);
        }).children.map(function (item) {
          return item.label;
        });
        cityItem = [].concat((0, _toConsumableArray2.default)(cityItem), (0, _toConsumableArray2.default)(slectCityItem));
      });
      var unSlectCityItem = data.filter(function (city) {
        return !e.includes(city.label);
      });
      console.log(unSlectCityItem);
      /*
      选中城市赋值去重
      */

      this.ruleItemForm.citySelect = (0, _toConsumableArray2.default)(new Set([].concat((0, _toConsumableArray2.default)(this.ruleItemForm.citySelect), (0, _toConsumableArray2.default)(cityItem))));
    },

    /*
    地区选中
    */
    changeAll: function changeAll(e, index, data) {
      var _slef = this;
      /*
      获取选中地区 的所有城市
      */


      function getLeafLabels(tree, prop) {
        var leafLabels = [];

        function traverse(node) {
          if (node && node.children && node.children.length > 0) {
            node.children.forEach(function (child) {
              traverse(child);
            });
          } else if (node && node.label) {
            if (!_slef.selectAllCity.includes(node.label)) {
              leafLabels.push(node.label);
            }
          }
        }

        traverse(tree);
        return leafLabels;
      }
      /*
      所有选中的城市
      */


      var citySelect = getLeafLabels(data);
      /*
      是否选中地区判断
      */

      if (e) {
        /*
        赋值选中地区的所有省份
        */
        this.cityData['city' + index].value = data.children.map(function (item) {
          return item.label;
        });
        this.cityData['city' + index].pValue = [];
        this.cityData['city' + index].allValue = false;
        /*
         赋值选中城市  去重
        */

        this.ruleItemForm.citySelect = (0, _toConsumableArray2.default)(new Set([].concat((0, _toConsumableArray2.default)(this.ruleItemForm.citySelect), (0, _toConsumableArray2.default)(citySelect))));
      } else {
        /*
        清空选中地区 的所有城市和省份
        */
        this.ruleItemForm.citySelect = this.ruleItemForm.citySelect.filter(function (item) {
          return !citySelect.includes(item);
        });
        this.cityData['city' + index].value = []; //this.cityData['city' + index].allValue = this.cityData['city' + index].value.length != data.children.length
        //this.cityData['city' + index].checkAllL = this.cityData['city' + index].value.length == data.children.length
        //          this.cityData['city' + index].checkAllL=false
      }
    },
    dealTree: function dealTree(data) {
      function extractThreeLevels(tree) {
        var level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

        if (level > 0) {
          tree.forEach(function (item) {
            delete item.children;
          });
        } else {
          tree.forEach(function (item) {
            item.children = extractThreeLevels(item.children, level + 1);
          });
        }

        return tree;
      }

      var selectRgion = _regionAll.default.map(function (item) {
        item.children = extractThreeLevels(item.children, 0);
        return item;
      });

      this.selectRgion = selectRgion;
    },

    /*
    保存商品
    */
    submitForm: function submitForm(formName) {
      var _this5 = this;

      var apiUrl = this.ruleForm.id ? '/api/mall/deliveryRule/update' : '/api/mall/deliveryRule/create';
      var requestData = JSON.parse(JSON.stringify(this.ruleForm));
      this.apiPost(apiUrl, requestData).then(function (res) {
        _this5.$router.go(-1);

        _this5.$message({
          type: 'success',
          message: _this5.ruleForm.id ? '编辑成功!' : '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;