"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//import WTable from '@/components/tble/index.vue'
var _default = {
  components: {// WTable
  },
  data: function data() {
    return {
      searchData: [{
        type: "radio",
        value: 'rewardType',
        placeholder: "请选择奖励类型",
        select: [{
          label: '连续签到积分',
          value: 1
        }, {
          label: '优惠券',
          value: 2
        }, {
          label: '购物卡',
          value: 3
        }, {
          label: '日常签到积分',
          value: 5
        }]
      }, {
        type: "input",
        value: 'phone',
        placeholder: "请输入用户手机号码"
      }, {
        type: "dateTime",
        value: 'startTime',
        placeholder: "选择开始时间"
      }, {
        type: "dateTime",
        value: 'endTime',
        placeholder: "选择结束时间"
      }],

      /* rewardTypeArr: [{
           label: '连续签到积分',
           value: 1
         },
         {
           label: '优惠券',
           value: 2
         },
         {
           label: '购物卡',
           value: 3
         },
         {
           label: '日常签到积分',
           value: 5
         }
       ], */
      rewardType: {
        1: '连续签到积分',
        2: '优惠券',
        3: '购物卡',
        5: '日常签到积分'
      },
      downloadLoading: false,
      time: null,
      requestData: {
        phone: null,
        startTime: null,
        endTime: null,
        pageNo: 1,
        rewardType: null,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      columns: [{
        label: '手机号',
        prop: 'phone',
        width: 80
      },
      /* {
        slot: 'memberType',
        label: '会员类型',
        prop: 'memberType',
        width: 80
      }, */
      {
        label: '用户昵称',
        prop: 'username',
        width: 50
      }, {
        label: '签到天数',
        prop: 'signDays',
        width: 50
      }, {
        label: '描述',
        prop: 'rewardContent',
        width: 100
      }, {
        slot: 'rewardType',
        label: '礼品类型',
        prop: 'rewardType'
      }, {
        slot: 'price',
        label: '礼品面额',
        prop: 'price'
      }, {
        slot: 'rewardSignDate',
        label: '签到时间',
        prop: 'rewardSignDate',
        width: 100
      }, {
        label: '领取时间',
        prop: 'receiveDate',
        width: 100
      }]
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.requestData.startTime = val[0];
        this.requestData.endTime = val[1];
      } else {
        this.requestData.startTime = null;
        this.requestData.endTime = null;
      }
    }
  },
  created: function created() {},
  activated: function activated() {
    this.getList();
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this = this;

      this.downloadLoading = true;
      var tHeader = [];
      var filterVal = [];
      var list = [];
      var data = {};
      this.columns.forEach(function (item) {
        if (item.prop && item.label) {
          tHeader.push(item.label);
          filterVal.push(item.prop);
        }
      });
      Object.keys(this.requestData).forEach(function (key) {
        if (_this.requestData[key]) {
          data[key] = _this.requestData[key];
        }
      });
      data.pageSize = this.requestData.total;
      data.pageNo = 1;
      (0, _api.getGiftPage)(data).then(function (res) {
        list = res.data.records;
        _this.downloadLoading = false;

        _this.getHandleDownload(tHeader, filterVal, list, '签到记录列表');
      }).catch(function (res) {
        _this.downloadLoading = false;
      });
    },
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this2 = this;

      if (type === 'search') {
        this.requestData.pageNo = 1;
      }

      if (type === 'remove') {
        this.time = null;
        this.removeObj(this.requestData);
      }

      (0, _api.getGiftPage)(this.requestData).then(function (res) {
        _this2.tableData = res.data.records;
        _this2.requestData.total = res.data.total;
      });
    }
  }
};
exports.default = _default;