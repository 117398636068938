var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manual-into" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: {
            model: _vm.params,
            rules: _vm.rules,
            size: "small",
            "label-width": "180px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "是否需加磁", prop: "val" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.params.val,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "val", $$v)
                    },
                    expression: "params.val"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("无需加磁")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("需加磁")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "入库范围", prop: "val" } },
            [
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v("添加购物卡")
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "20px" },
                  attrs: {
                    data: _vm.list,
                    size: "mini",
                    "header-cell-style": { background: "#f5f5f5" },
                    border: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      align: "center",
                      width: "80"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "卡面图",
                      prop: "pageTitle",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "卡种名称",
                      prop: "pageUrl",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "卡类型", prop: "link", align: "center" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "卡片面额",
                      prop: "createTime",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "消费积分",
                      prop: "createTime",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "记名类型",
                      prop: "createTime",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "起始卡号",
                      prop: "createTime",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数量",
                      prop: "createTime",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "结束卡号",
                      prop: "createTime",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "操作",
                        prop: "action",
                        width: "150",
                        align: "center"
                      }
                    },
                    [
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "orange" },
                            attrs: { type: "text" }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "text-align": "right",
                    "padding-right": "50px"
                  }
                },
                [_vm._v("合计数量：2500张")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "备注", prop: "remark" }
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", autosize: { minRows: 5 } },
                model: {
                  value: _vm.params.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "remark", $$v)
                  },
                  expression: "params.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "actions" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }