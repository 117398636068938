"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    confirmBtn: {
      type: String,
      default: "确认"
    }
  },
  data: function data() {
    return {
      isAuth: true,
      isFinished: false,
      infoOption: [{
        title: "卡内余额",
        val: "",
        default: "-"
      }, {
        title: "状态",
        val: "",
        default: "-"
      }, {
        title: "卡种ID",
        val: "",
        default: "-"
      }, {
        title: "卡种名称",
        val: "",
        default: "-"
      }, {
        title: "卡类型",
        val: "",
        default: "-"
      }, {
        title: "消费积分",
        val: "",
        default: "-"
      }, {
        title: "记名类型",
        val: "",
        default: "-"
      }, {
        title: "卡片面额",
        val: "",
        default: "-"
      }, {
        title: "激活后有效期",
        val: "",
        default: "-"
      }, {
        title: "可绑定为电子卡",
        val: "",
        default: "-"
      }, {
        title: "备注",
        val: "",
        default: "-"
      }, {
        title: "创建时间",
        val: "",
        default: "-"
      }, {
        title: "总库存",
        val: "",
        default: "-"
      }],
      authParams: {
        method: 1
      },
      authRules: {},
      // 倒计时
      num: 120,
      interval: null
    };
  },
  methods: {
    startCountDown: function startCountDown() {
      var _this = this;

      this.interval = setInterval(function () {
        if (_this.num === 0) {
          clearInterval(_this.interval);
          _this.num = 120;
        }

        _this.num--;
      }, 1000);
    },
    sendSmscode: function sendSmscode() {
      if (this.num !== 120) return;
      this.$message.success("验证码发送成功");
      this.startCountDown();
    },
    onFinished: function onFinished() {
      this.isFinished = true;
      this.$emit("finished", true);
    }
  },
  destroyed: function destroyed() {
    if (this.interval) clearInterval(this.interval);
  }
};
exports.default = _default;