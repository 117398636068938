var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          attrs: {
            "form-data": _vm.formData,
            "rule-form": _vm.ruleForm,
            rules: _vm.rules
          },
          on: { changeForm: _vm.changeForm, submitForm: _vm.submitForm }
        },
        [
          _c(
            "el-form-item",
            {
              ref: "codeList",
              attrs: {
                slot: "codeList",
                prop: "codeList",
                label: _vm.typeData[_vm.ruleForm.type]
              },
              slot: "codeList"
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.getRelevances }
                },
                [
                  _vm._v(
                    "\n        请选择" + _vm._s(_vm.typeData[_vm.ruleForm.type])
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c("upload-excel-component", {
                    attrs: {
                      "btn-text": "批量导入",
                      type: "btn",
                      size: "mini",
                      "on-success": _vm.handleUploadSuccess,
                      "before-upload": _vm.handleBeforeUpload
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: "/xlsx/couponPop.xlsx",
                    download: "选品编码导入模板.xlsx"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-download",
                        size: "mini"
                      }
                    },
                    [_vm._v("下载模板")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #cccccc",
                    padding: "10px",
                    "border-radius": "10px",
                    "margin-top": "10px",
                    "max-height": "500px",
                    overflow: "auto"
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.ruleForm.codeList.join("、")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.couponShow
        ? _c("coupon", {
            ref: "couponPop",
            attrs: {
              type: _vm.typePops[_vm.ruleForm.type],
              productSourceOptions: [{ value: 1, label: "积分商城" }]
            },
            on: {
              setCoupons: _vm.setCoupons,
              closeCouponPop: _vm.closeCouponPop
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }