"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '通道名称',
        prop: 'chanelName',
        width: 80
      }, {
        label: '通道简称',
        prop: 'shortName',
        width: 80
      }, {
        label: '通道编码',
        prop: 'channelTypeNo',
        width: 80
      }, {
        slot: 'type',
        label: '通道类型',
        prop: 'type',
        width: 80
      }, {
        slot: 'merchantType',
        label: '收银方式',
        prop: 'type',
        width: 80
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status',
        width: 80
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '通道名称',
        value: 'chanelName'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '通道类型',
        value: 'type',
        select: [{
          label: '聚合',
          value: 1
        }, {
          label: '非聚合',
          value: 2
        }]
      }, {
        type: 'radio',
        span: 4,
        placeholder: '状态',
        value: 'status',
        select: [{
          value: 1,
          label: '启用'
        }, {
          value: 2,
          label: '禁用'
        }]
      }]
    };
  }
};
exports.default = _default;