"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default,
    selectAward: _radioItem.default
  },
  data: function data() {
    var _self = this;

    return {
      couponShow: false,
      popType: '',
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.validStartTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      giftType: {
        1: '选择优惠券',
        2: '选择券包',
        3: '选择商品'
      },
      type: [{
        label: '优惠券',
        value: 1
      }, {
        label: '券包',
        value: 2
      }, {
        label: '商品',
        value: 3
      }],

      /* gradeType: {
        8888: '银卡会员：',
        8889: '金卡会员：',
        8890: '砖卡会员：',
        8891: '白砖卡会员：',
        8892: '黑卡会员：'
      }, */
      gradeList: [],
      rules: {
        grade: [{
          required: true,
          message: '请选择等级会员'
        }],
        'rightDesc': [{
          required: true,
          message: '请输入描述'
        }],
        'rightName': [{
          required: true,
          message: '请输入名称'
        }],
        'validStartTime': [{
          required: true,
          message: '请选择开始时间'
        }],
        'validEndTime': [{
          required: true,
          message: '请选择结束时间'
        }],
        'rightShort': [{
          required: true,
          message: '请输入简称'
        }],
        popupImage: [{
          required: true,
          message: '请上传弹窗广告'
        }],
        shareImage: [{
          required: true,
          message: '请上传代客领取权益图片'
        }],
        'landscapeMainPic': [{
          required: true,
          message: '请上传横屏主图'
        }],
        'verticalMainPic': [{
          required: true,
          message: '请上传竖屏主图'
        }],
        'rightImage': [{
          required: true,
          message: '请上传主图'
        }],
        'rightLockIco': [{
          required: true,
          message: '请上传锁定图'
        }],
        'rigthUnlockIco': [{
          required: true,
          message: '请上传解锁图'
        }],
        'rightDetail': [{
          required: true,
          message: '请输入介绍'
        }],
        rightType: [{
          required: true,
          message: '请选择权益类型'
        }],
        reciveMode: [{
          required: true,
          message: '请选择领取方式'
        }],
        ratioAll: [{
          validator: function validator(rule, value, callback) {
            if (value < 0 > 100) {
              callback(new Error('乐享值需大于0'));
            } else {
              callback();
            }
          }
        }]
      },

      /*
      <!--
      横屏主图 landscape_main_pic  landscapeMainPic
      竖屏主图 vertical_main_pic  verticalMainPic
      需要后台增加传参
      -->
      */
      ruleForm: {
        grade: [],
        rightName: null,
        rightShort: null,
        rightDesc: null,
        rightImage: '',
        shareImage: null,
        popupImage: null,
        landscapeMainPic: null,
        verticalMainPic: null,
        rightLockIco: null,
        rigthUnlockIco: null,
        rightType: null,
        validStartTime: null,
        validEndTime: null,
        reciveMode: null,
        rightItmes: [],
        rightDetail: null,
        formType: null
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.getGradeList)().then(function (res) {
      _this.gradeList = res.data.filter(function (item) {
        return item.id < 0;
      });
      console.log(res);
    });
    this.ruleForm.id = this.$route.query.id;
    this.formType = this.$route.query.type;

    if (this.ruleForm.id) {
      (0, _api.getData)(this.ruleForm.id).then(function (res) {
        var arr = [];
        res.data.rightItmes.forEach(function (item) {
          if (item.rightItemType === 3 && item.giftItems && item.giftItems.length > 0) {
            /*
            "giftCode":item.giftCodeType,
              "num": item.giftCodeNum
            */
            item.giftCodeType = item.giftItems[0].giftCode;
            item.giftCodeNum = item.giftItems[0].num;
          }

          arr.push(item.rightLevelId);
        });
        res.data.grade = arr;
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    gradeType: function gradeType(id) {
      var data = this.gradeList.find(function (item) {
        return id === item.id;
      });
      return data ? data.name : '';
    },
    setNumber: function setNumber(e, index) {
      var _this2 = this;

      this.$nextTick(function () {
        if (e > _this2.ruleForm.rightItmes[index].giftItems.length) {
          _this2.ruleForm.rightItmes[index].choiceNum = _this2.ruleForm.rightItmes[index].giftItems.length;
        }
      });
    },
    typeChange: function typeChange(e, index, row) {
      this.ruleForm.rightItmes[index].giftItems[row].giftCode = null;
      this.ruleForm.rightItmes[index].giftItems[row].giftName = null;
      this.ruleForm.rightItmes[index].giftItems[row].giftImage = null;
      this.ruleForm.rightItmes[index].giftItems[row].giftDesc = null;
      this.ruleForm.rightItmes[index].giftItems[row].num = null;
    },

    /*
        删除奖励项
        */
    delAward: function delAward(index, row) {
      var _this3 = this;

      this.ruleForm.rightItmes[index].giftItems.splice(row, 1);
      this.$nextTick(function () {
        if (_this3.ruleForm.rightItmes[index].choiceNum > _this3.ruleForm.rightItmes[index].giftItems.length) {
          _this3.ruleForm.rightItmes[index].choiceNum = _this3.ruleForm.rightItmes[index].giftItems.length;
        }
      });
    },

    /*
        打开弹框
        */
    getAward: function getAward(e, index, row) {
      this.count = index;
      this.rowIndex = row;
      var giftType = {
        1: 'coupon',
        2: 'couponBag',
        3: 'goods'
      };
      this.popType = giftType[e];
      this.$refs.coupon1.getList(this.popType, this.ruleForm.rightItmes[index].giftItems[row].giftCode);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      if (this.popType === 'coupon') {
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftCode = e.uuid;
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftName = e.name;
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftImage = e.mainImage;
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftDesc = e.descrition;
      }

      if (this.popType === 'couponBag') {
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftCode = e.uuid;
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftName = e.name;
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftImage = e.tableImage;
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftDesc = e.description;
      }

      if (this.popType === 'goods') {
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftCode = e.productCode;
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftName = e.productName;
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftImage = e.imageUrl;
        this.ruleForm.rightItmes[this.count].giftItems[this.rowIndex].giftDesc = e.productInstr;
      }

      this.couponShow = false;
      this.popType = '';
    },
    changeGrade: function changeGrade(e) {
      var _this4 = this;

      console.log(e);
      var arr = JSON.parse(JSON.stringify(this.ruleForm.rightItmes));
      this.ruleForm.rightItmes = [];
      e.forEach(function (item) {
        var data = arr.find(function (row) {
          return item === row.rightLevelId;
        });

        if (data) {
          _this4.ruleForm.rightItmes.push(data);
        } else {
          _this4.ruleForm.rightItmes.push({
            rightLevelId: item,
            rightItemType: 1,
            // rule: 1,
            choiceNum: null,
            giftItems: [],
            returnRadio: null
          });
        }
      });
    },
    addGiftItems: function addGiftItems(index) {
      this.ruleForm.rightItmes[index].giftItems.push({
        giftType: null,
        giftCode: null,
        giftName: null,
        num: null,
        giftImage: null,
        desc: null
      });
      this.$nextTick(function () {});
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.ruleForm.rightItmes.forEach(function (item) {
            if (item.rightItemType === 1) {
              item.returnRadio = null;
            }

            if (item.rightItemType === 3) {
              item.giftItems = [{
                "giftCode": item.giftCodeType,
                "num": item.giftCodeNum
              }];
              item.choiceNum = null;
            }

            if (item.rightItemType === 2) {
              item.giftItems = [];
              item.choiceNum = null;
            }
          });
          /* this.ruleForm.id = null
            addData(this.ruleForm).then(res => {
              // this.$router.go(-1)
              this.$message({
                type: 'success',
                message: '保存成功!'
              })
            })
            return */


          if (_this5.ruleForm.id && _this5.formType != 'copy') {
            (0, _api.editData)(_this5.ruleForm).then(function (res) {
              _this5.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this5.$router.go(-1);
            });
          } else {
            delete _this5.ruleForm.id;
            (0, _api.addData)(_this5.ruleForm).then(function (res) {
              _this5.$router.go(-1);

              _this5.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;