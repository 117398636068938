"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPageList = getPageList;
exports.getStoreCardPage = getStoreCardPage;
exports.getUserLabel = getUserLabel;
exports.goUploadImg = goUploadImg;
exports.listSource = listSource;
exports.listStore = listStore;

var _request = _interopRequireDefault(require("@/utils/request"));

function goUploadImg(data) {
  return (0, _request.default)({
    url: '/api/fileManage/uploadFile',
    method: 'post',
    data: data
  });
}

function getUserLabel(data) {
  return (0, _request.default)({
    url: '/api/category/label/listUserCategoryLabel',
    method: 'post',
    data: data
  });
}
/*
 门店列表
 */


function listStore(data) {
  return (0, _request.default)({
    url: '/api/store/listStore',
    method: 'post',
    data: data
  });
}

function listSource(data) {
  return (0, _request.default)({
    url: '/mall/product/source/listSource',
    method: 'post',
    data: data
  });
}

function getStoreCardPage(data) {
  return (0, _request.default)({
    url: '/api/make/store/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getPageList(url, data, method) {
  return (0, _request.default)({
    url: url,
    method: method || 'post',
    loading: 'loading',
    data: data
  });
}