var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "ecitList_box2 box" }, [
      _c("div", { staticClass: "box2-left" }, [
        _c("ul", [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "ecit_tit" }, [_vm._v("栏目标题：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "table_input3",
                attrs: { placeholder: "0" },
                model: {
                  value: _vm.recomTit,
                  callback: function($$v) {
                    _vm.recomTit = $$v
                  },
                  expression: "recomTit"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "ecit_txt" }, [
                _vm._v("前端显示的栏目标题")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("p", { staticClass: "ecit_tit" }, [_vm._v("列表数据源：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "table_input2",
                attrs: { placeholder: "0" },
                model: {
                  value: _vm.recomId,
                  callback: function($$v) {
                    _vm.recomId = $$v
                  },
                  expression: "recomId"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "ecit_txt" }, [_vm._v("栏目或分类ID")])
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm._m(1)
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ecitList_box2 box" }, [
      _c("div", { staticClass: "box2-left" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("p", { staticClass: "ecit_tit" }, [_vm._v("列表数据项：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "textarea",
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 20, maxRows: 30 },
                  placeholder: "请输入内容"
                },
                model: {
                  value: _vm.textarea2,
                  callback: function($$v) {
                    _vm.textarea2 = $$v
                  },
                  expression: "textarea2"
                }
              })
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "nextBtn" },
      [_c("el-button", { attrs: { type: "primary" } }, [_vm._v("保存")])],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", { staticClass: "ecit_tit" }, [_vm._v("已选：")]),
      _vm._v(" "),
      _c("p", { staticClass: "color" }, [_vm._v("首页列表模板1")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box2-right" }, [
      _c("p", { staticClass: "ecit_tit" }, [_vm._v("模板预览：")]),
      _vm._v(" "),
      _c("img", { attrs: { src: "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }