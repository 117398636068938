var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-member-detail" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("要客详情")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c("Descriptions", {
                attrs: {
                  title: "基本信息",
                  columns: _vm.columns,
                  "data-source": _vm.member
                }
              }),
              _vm._v(" "),
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签：", "label-width": "100px" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "200px" },
                        attrs: { placeholder: "请输入标签", size: "mini" },
                        model: {
                          value: _vm.itemName,
                          callback: function($$v) {
                            _vm.itemName =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "itemName"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.addItem()
                            }
                          }
                        },
                        [_vm._v("添加")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        _vm._l(_vm.member.labelList, function(fileItem, index) {
                          return _c(
                            "el-tag",
                            {
                              key: index,
                              staticStyle: { margin: "10px" },
                              attrs: { size: "medium", closable: "" },
                              on: {
                                close: function($event) {
                                  return _vm.delTag(index)
                                }
                              }
                            },
                            [_vm._v(_vm._s(fileItem))]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100px" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.upDateMember }
                        },
                        [_vm._v("保存")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "success" },
                          on: {
                            click: function($event) {
                              return _vm.goUpdate()
                            }
                          }
                        },
                        [_vm._v("刷新要客等级")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("Descriptions", { attrs: { title: "信息变更记录" } }),
              _vm._v(" "),
              _c(
                "el-timeline",
                _vm._l(_vm.logList, function(log, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: index,
                      attrs: {
                        timestamp: log.createTime,
                        color: "rgb(0, 153, 255)"
                      }
                    },
                    [
                      _c("h4", [_vm._v(_vm._s(log.eventName))]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "svip-member-detail_event-name" },
                        [_vm._v(_vm._s(log.content))]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }