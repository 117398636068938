var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title " }, [_vm._v(" 页面路径 ")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.urlList,
            columns: _vm.columns,
            height: "500"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "pageUrl",
              label: "页面路径",
              align: "center",
              "min-width": "100"
            },
            slot: "pageUrl",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.page || scope.row.value) +
                        _vm._s(scope.row.query) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              align: "center",
              fixed: "right",
              width: "200"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.page
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { width: "100px" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.goCopyUrl(scope.row)
                              }
                            }
                          },
                          [_vm._v("小程序码")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticStyle: { width: "100px" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.goQrcode(scope.row)
                              }
                            }
                          },
                          [_vm._v("小程序码")]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "view_title " }, [_vm._v(" 关联小程序 ")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("w-table", {
        attrs: {
          "table-data": _vm.appIdArr,
          columns: _vm.columns1,
          height: "500"
        }
      }),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              "qr-code": _vm.qrCode,
              url: _vm.url,
              "coupon-u-uid": _vm.couponUUid
            },
            on: { closePop: _vm.closePop }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }