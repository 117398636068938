var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                disabled: _vm.status === "dea",
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c("div", { staticClass: "view_title" }, [_vm._v("活动信息")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "messageParam.activityName",
                  attrs: {
                    label: "活动名称：",
                    prop: "messageParam.activityName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { maxlength: "30", "show-word-limit": "" },
                    model: {
                      value: _vm.ruleForm.messageParam.activityName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm.messageParam,
                          "activityName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.messageParam.activityName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "messageParam.storeCode",
                  attrs: { label: "所属门店：", prop: "messageParam.storeCode" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      attrs: {
                        size: "small",
                        placeholder: "所属门店",
                        filterable: "",
                        autocomplete: "off"
                      },
                      on: {
                        change: function($event) {
                          return _vm.init("search")
                        }
                      },
                      model: {
                        value: _vm.ruleForm.messageParam.storeCode,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm.messageParam, "storeCode", $$v)
                        },
                        expression: "ruleForm.messageParam.storeCode"
                      }
                    },
                    _vm._l(_vm.storeList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.storeId,
                          attrs: {
                            label:
                              item.shortName + "（" + item.storeCode + "）",
                            value: item.storeCode
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                item.shortName + "（" + item.storeCode + "）"
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "messageParam.activityStartTime",
                  attrs: {
                    label: "活动周期：",
                    prop: "messageParam.activityStartTime"
                  }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "mini",
                      type: "datetimerange",
                      "range-separator": "—",
                      format: "yyyy年MM月dd日 HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "start-placeholder": "开始日期",
                      "default-time": ["00:00:00", "23:59:59"],
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.time,
                      callback: function($$v) {
                        _vm.time = $$v
                      },
                      expression: "time"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "form_remark" }, [
                    _vm._v("默认起始时间00：00：00，默认结束时间59：59：59")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "messageParam.activityImages",
                  attrs: {
                    label: "活动主图：",
                    prop: "messageParam.activityImages"
                  }
                },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 100,
                      height: 100,
                      cover: _vm.ruleForm.messageParam.activityImages
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover(
                          $event,
                          "messageParam",
                          "activityImages"
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        color: "rgba(0, 0, 0, 0.45)"
                      }
                    },
                    [_vm._v("图片限制2M，图片过大请压缩后再上传")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "messageParam.activityBackgroundColor",
                  attrs: {
                    label: "背景色：",
                    prop: "messageParam.activityBackgroundColor"
                  }
                },
                [
                  _c("el-color-picker", {
                    model: {
                      value: _vm.ruleForm.messageParam.activityBackgroundColor,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm.messageParam,
                          "activityBackgroundColor",
                          $$v
                        )
                      },
                      expression:
                        "ruleForm.messageParam.activityBackgroundColor"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "messageParam.mainButtonColor",
                  attrs: {
                    label: "主题按钮颜色：",
                    prop: "messageParam.mainButtonColor"
                  }
                },
                [
                  _c("el-color-picker", {
                    model: {
                      value: _vm.ruleForm.messageParam.mainButtonColor,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm.messageParam,
                          "mainButtonColor",
                          $$v
                        )
                      },
                      expression: "ruleForm.messageParam.mainButtonColor"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "view_title" }, [_vm._v("活动规则")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "messageParam.activityLimitPersons",
                  attrs: {
                    label: "限制活动发起人数：",
                    prop: "messageParam.activityLimitPersons"
                  }
                },
                [
                  _c("el-input-number", {
                    attrs: { min: 1 },
                    model: {
                      value: _vm.ruleForm.messageParam.activityLimitPersons,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm.messageParam,
                          "activityLimitPersons",
                          $$v
                        )
                      },
                      expression: "ruleForm.messageParam.activityLimitPersons"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "messageParam.labelContent",
                  attrs: {
                    prop: "messageParam.labelContent",
                    label: "发起人标签："
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "输入标签名称搜索~",
                        "value-key": "id",
                        clearable: ""
                      },
                      model: {
                        value: _vm.ruleForm.messageParam.labelContent,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ruleForm.messageParam,
                            "labelContent",
                            $$v
                          )
                        },
                        expression: "ruleForm.messageParam.labelContent"
                      }
                    },
                    _vm._l(_vm.userLabelList, function(so) {
                      return _c("el-option", {
                        key: so.id,
                        attrs: { label: "" + so.labelName, value: so.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "rulesParam.activityRules",
                  attrs: {
                    label: "每人最多领奖次数：",
                    prop: "rulesParam.activityRules"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.id > 0 },
                      model: {
                        value: _vm.ruleForm.rulesParam.activityRules,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ruleForm.rulesParam,
                            "activityRules",
                            $$v
                          )
                        },
                        expression: "ruleForm.rulesParam.activityRules"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("按层级设置")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("一共可领取")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.rulesParam.activityRules === 2
                ? _c(
                    "el-form-item",
                    {
                      ref: "rulesParam.activityLevelReceiveNumber",
                      attrs: {
                        label: "领取次数：",
                        prop: "rulesParam.activityLevelReceiveNumber"
                      }
                    },
                    [
                      _c("el-input-number", {
                        attrs: { disabled: _vm.id > 0, min: 1 },
                        model: {
                          value:
                            _vm.ruleForm.rulesParam.activityLevelReceiveNumber,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ruleForm.rulesParam,
                              "activityLevelReceiveNumber",
                              $$v
                            )
                          },
                          expression:
                            "ruleForm.rulesParam.activityLevelReceiveNumber"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "rewardParams",
                  attrs: { label: "助力层级：", prop: "rewardParams" }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.addRule }
                    },
                    [_vm._v(" 添加档位")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.ruleForm.rewardParams,
                        size: "mini",
                        border: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "排序", width: "100", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.$index + 1) +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖励类型",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "rewardParams." +
                                      scope.$index +
                                      ".prizeType",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "rewardParams." +
                                        scope.$index +
                                        ".prizeType",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择奖励类型"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: scope.row.id > 0,
                                          size: "mini",
                                          placeholder: "奖励类型"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setAward(
                                              $event,
                                              scope.$index
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.prizeType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "prizeType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.prizeType"
                                        }
                                      },
                                      _vm._l(_vm.type, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "关联礼品",
                          "min-width": "200",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "rewardParams." +
                                      scope.$index +
                                      ".prizeCode",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "rewardParams." +
                                        scope.$index +
                                        ".prizeCode",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择礼品"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: scope.row.id > 0,
                                          type: "primary",
                                          size: "mini"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.getAward(
                                              scope.row.prizeType,
                                              scope.$index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              scope.row.prizeType == 1
                                                ? "选择优惠券"
                                                : "选择券包"
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    scope.row.prizeCode
                                      ? _c("p", [
                                          _vm._v(_vm._s(scope.row.prizeCode))
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品名称",
                          "min-width": "100",
                          prop: "prizeName",
                          align: "center"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "累计邀请人数",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "rewardParams." +
                                      scope.$index +
                                      ".activityNeedPersons",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "rewardParams." +
                                        scope.$index +
                                        ".activityNeedPersons",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入累计邀请人数"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: scope.row.id > 0,
                                        size: "mini",
                                        type: "number"
                                      },
                                      model: {
                                        value: scope.row.activityNeedPersons,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "activityNeedPersons",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scope.row.activityNeedPersons"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品数量",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "rewardParams." +
                                      scope.$index +
                                      ".prizeNumber",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "rewardParams." +
                                        scope.$index +
                                        ".prizeNumber",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入奖品数量"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: scope.row.id > 0,
                                        size: "mini",
                                        type: "number"
                                      },
                                      model: {
                                        value: scope.row.prizeNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "prizeNumber",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.prizeNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _vm.ruleForm.rulesParam.activityRules === 1
                        ? _c("el-table-column", {
                            attrs: {
                              label: "每层级领奖次数",
                              "min-width": "100",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref:
                                            "rewardParams." +
                                            scope.$index +
                                            ".receiveNumber",
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "rewardParams." +
                                              scope.$index +
                                              ".receiveNumber",
                                            required: "",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入最多领奖次数"
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              size: "mini",
                                              disabled: scope.row.id > 0,
                                              min: 1
                                            },
                                            model: {
                                              value: scope.row.receiveNumber,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "receiveNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.receiveNumber"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3676513408
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.id < 1
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width: "100",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "table_td_btn",
                                          on: {
                                            click: function($event) {
                                              return _vm.delAward(scope.$index)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1579346158
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "view_title" }, [_vm._v("助力人条件")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "messageParam.objectLabelContent",
                  attrs: {
                    prop: "messageParam.objectLabelContent",
                    label: "助力人标签："
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "输入标签名称搜索~",
                        "value-key": "id",
                        clearable: ""
                      },
                      model: {
                        value: _vm.ruleForm.messageParam.objectLabelContent,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ruleForm.messageParam,
                            "objectLabelContent",
                            $$v
                          )
                        },
                        expression: "ruleForm.messageParam.objectLabelContent"
                      }
                    },
                    _vm._l(_vm.userLabelList, function(so) {
                      return _c("el-option", {
                        key: so.id,
                        attrs: { label: "" + so.labelName, value: so.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "messageParam.objectHelpNumber",
                  attrs: {
                    label: "每个用户可为：",
                    prop: "messageParam.objectHelpNumber"
                  }
                },
                [
                  _c("el-input-number", {
                    attrs: { disabled: _vm.id > 0, min: 1 },
                    model: {
                      value: _vm.ruleForm.messageParam.objectHelpNumber,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm.messageParam,
                          "objectHelpNumber",
                          $$v
                        )
                      },
                      expression: "ruleForm.messageParam.objectHelpNumber"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        color: "rgba(0, 0, 0, 0.45)",
                        "margin-left": "10px"
                      }
                    },
                    [_vm._v("人助力")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "conditionParam.objectRulesDescribe",
                  attrs: {
                    label: "规则说明：",
                    prop: "conditionParam.objectRulesDescribe"
                  }
                },
                [
                  _c("Tinymce", {
                    attrs: { width: 750, height: 400 },
                    model: {
                      value: _vm.ruleForm.conditionParam.objectRulesDescribe,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm.conditionParam,
                          "objectRulesDescribe",
                          $$v
                        )
                      },
                      expression: "ruleForm.conditionParam.objectRulesDescribe"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "conditionParam.objectShareTitle",
                  attrs: {
                    label: "分享标题：",
                    prop: "conditionParam.objectShareTitle"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { maxlength: "24", "show-word-limit": "" },
                    model: {
                      value: _vm.ruleForm.conditionParam.objectShareTitle,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm.conditionParam,
                          "objectShareTitle",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.conditionParam.objectShareTitle"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "conditionParam.objectShareImage",
                  attrs: {
                    label: "分享图片：",
                    prop: "conditionParam.objectShareImage"
                  }
                },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 300,
                      height: 240,
                      cover: _vm.ruleForm.conditionParam.objectShareImage
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover(
                          $event,
                          "conditionParam",
                          "objectShareImage"
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        color: "rgba(0, 0, 0, 0.45)"
                      }
                    },
                    [
                      _vm._v(
                        " （尺寸602px * 482px） 图片限制2M，图片过大请压缩后再上传"
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "form_item", attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%", "max-width": "200px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit("ruleForm")
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("coupon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "couponPop",
        attrs: { "coupon-show": _vm.couponShow, type: _vm.popType },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupons,
          closeCouponPop: _vm.closeCouponPop
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }