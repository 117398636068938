"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _member = require("@/api/member.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      userInfo: {},
      gifts: [],
      exchangeRemark: '',
      consumeStore: '',
      username: ''
    };
  },
  created: function created() {
    var data = this.$route.query.data;
    console.log(data);
    console.log(JSON.parse(data));

    if (data) {
      data = JSON.parse(data);
      this.userInfo = data.userInfo;
      this.gifts = data.gifts;
      this.exchangeRemark = data.exchangeRemark;
      this.consumeStore = data.consumeStore;
      this.username = data.username;
    }
  },
  methods: {
    goPresent: function goPresent() {
      var _this = this;

      var data = {
        exchangeUid: this.userInfo.uid,
        gifts: [],
        exchangeRemark: this.exchangeRemark
      };
      this.gifts.forEach(function (item) {
        if (item.exchangeNumber) {
          data.gifts.push({
            giftId: item.id,
            exchangeNumber: item.exchangeNumber
          });
        }
      });
      this.$confirm('此操作将发放礼品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.openLoading();

        (0, _member.addExchange)(data).then(function (res) {
          _this.openLoading().close();

          _this.$message({
            message: '发放成功',
            type: 'success'
          });

          _this.$router.push('/member/present/list'); // this.$router.go(-1)

        }).catch(function (err) {
          _this.openLoading().close();
        });
      });
    }
  }
};
exports.default = _default;