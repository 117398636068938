"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.find");

var _api = require("./api.js");

var _auth = require("@/utils/auth");

var _api2 = require("../api.js");

var _report = require("@/api/report.js");

var _manage = require("@/api/manage.js");

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    selectAward: _radioItem.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      userList: [],
      activityList: [],
      userInfo: {},
      isGoCard: false,
      currentPrice: null,
      changType: 2,
      popType: 'storeCard',
      couponShow: false,
      action: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
      rules: {
        inActivity: [{
          required: true,
          message: '请选择'
        }],
        payAmount: [{
          required: true,
          message: '请输入支付金额'
        }],
        chargeAmount: [{
          required: true,
          message: '请输入充值金额'
        }],
        chargeCardNo: [{
          required: true,
          message: '请选择购物卡'
        }],
        activityId: [{
          required: true,
          message: '请选择活动'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        onlineCard: [{
          required: true,
          message: '请选择卡类型'
        }],
        chargeUserId: [{
          required: true,
          message: '请先输入手机号查询用户'
        }]
      },
      ruleForm: {
        userMobile: '',
        inActivity: false,
        payAmount: null,
        chargeAmount: null,
        chargeCardNo: null,
        faceValue: null,
        chargeUserId: null,
        activityId: null,
        remark: null,
        onlineCard: 1,
        recommendSysUserId: null
      }
    };
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        'Token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {},
  methods: {
    remoteMethodUser: function remoteMethodUser(e) {
      var _this = this;

      if (e) {
        var data = {
          pageNo: 1,
          pageSize: 100,
          mobile: e
        };
        (0, _manage.listSysUserPage)(data).then(function (res) {
          _this.userList = res.data.items;
        });
      } else {
        this.userList = [];
      }
    },
    remoteMethod: function remoteMethod(e) {
      var _this2 = this;

      if (e) {
        var data = {
          pageNo: 1,
          pageSize: 100,
          activityName: e
        };
        (0, _api2.getList)(data).then(function (res) {
          _this2.activityList = res.data.records;
        });
      } else {
        this.activityList = [];
      }
    },
    changeActivity: function changeActivity(e) {
      var _this3 = this;

      if (e) {
        var data = this.activityList.find(function (item) {
          return item.id === e;
        });
        this.ruleForm.chargeCardNo = data.cardUuid;
        (0, _api.checkUserInActivity)({
          uid: this.ruleForm.chargeUserId,
          activityId: e
        }).then(function (res) {
          _this3.ruleForm.inActivity = res.data.inActivity;
        });
      } else {
        this.ruleForm.chargeCardNo = null;
        this.ruleForm.inActivity = false;
      }
    },
    changeMobile: function changeMobile() {
      this.isGoCard = false;
    },
    getUserInfo: function getUserInfo() {
      var _this4 = this;

      var data = {
        mobile: this.ruleForm.userMobile,
        type: 1
      };
      this.isGoCard = false;
      (0, _report.handleMidUser)(data).then(function (res) {
        console.log(111111111);
        _this4.isGoCard = true;
        _this4.userInfo = res.data.user;
        _this4.ruleForm.chargeUserId = res.data.user.uid;
      });
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    setCoupon: function setCoupon(e) {
      this.ruleForm.chargeCardNo = e.uuid;
      this.couponShow = false;
    },
    getAward: function getAward(e) {
      this.popType = 'card';
      this.$refs.coupon1.getList(this.popType, this.ruleForm.chargeCardNo);
      this.couponShow = true;
    },
    onSuccess: function onSuccess(res) {
      var _this5 = this;

      if (res.ret === 1000) {
        this.ruleForm.attachment.push(res.data);
      } else if (res.ret === 6000) {
        this.$confirm(res.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this5.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this6 = this;

      this.ruleForm.attachment = [];
      fileList.forEach(function (item) {
        _this6.ruleForm.attachment.push(item.response.data);
      });
    },
    clearForm: function clearForm(ruleForm) {
      this.cardData = {};
      this.$refs[ruleForm].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this7 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this7.ruleForm.faceValue = _this7.ruleForm.chargeAmount > 1000 ? 1000 : _this7.ruleForm.chargeAmount;
          (0, _api.goRecharge)(_this7.ruleForm).then(function (res) {
            _this7.$router.go(-1);

            _this7.$message({
              type: 'success',
              message: '充值成功!'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;