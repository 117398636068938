"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addInstitution = addInstitution;
exports.addSysDept = addSysDept;
exports.addSysMenu = addSysMenu;
exports.addSysRole = addSysRole;
exports.addSysUser = addSysUser;
exports.delMenu = delMenu;
exports.editInstitution = editInstitution;
exports.editSysDept = editSysDept;
exports.editSysMenu = editSysMenu;
exports.editSysRole = editSysRole;
exports.editSysUser = editSysUser;
exports.getInstitutionById = getInstitutionById;
exports.getSysDeptById = getSysDeptById;
exports.getSysMenuById = getSysMenuById;
exports.getSysRoleById = getSysRoleById;
exports.getSysUserById = getSysUserById;
exports.listInstitutionTree = listInstitutionTree;
exports.listMemberCards = listMemberCards;
exports.listMenuTree = listMenuTree;
exports.listSysDeptTree = listSysDeptTree;
exports.listSysRole = listSysRole;
exports.listSysRolePage = listSysRolePage;
exports.listSysUserPage = listSysUserPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 查询所有会员
 */
function listMemberCards(data) {
  return (0, _request.default)({
    url: '/api/institutionInfo/listMemberCards',
    method: 'post',
    data: data
  });
}
/*
 查询所有机构(tree)
 */


function listInstitutionTree(data) {
  return (0, _request.default)({
    url: '/api/institutionInfo/listInstitutionTree',
    method: 'post',
    data: data
  });
}
/*
 根据部门id查询机构
 */


function getInstitutionById(data) {
  return (0, _request.default)({
    url: '/api/institutionInfo/getInstitutionById',
    method: 'post',
    params: data
  });
}
/*
 新增机构
 */


function addInstitution(data) {
  return (0, _request.default)({
    url: '/api/institutionInfo/addInstitution',
    method: 'post',
    data: data
  });
}
/*
 编辑机构
 */


function editInstitution(data) {
  return (0, _request.default)({
    url: '/api/institutionInfo/editInstitution',
    method: 'post',
    data: data
  });
}
/*
 查询所有部门(tree)
 */


function listSysDeptTree(data) {
  return (0, _request.default)({
    url: '/api/sysDept/listSysDeptTree',
    method: 'post',
    data: data
  });
}
/*
 根据部门id查询部门
 */


function getSysDeptById(data) {
  return (0, _request.default)({
    url: '/api/sysDept/getSysDeptById',
    method: 'post',
    params: data
  });
}
/*
 新增部门
 */


function addSysDept(data) {
  return (0, _request.default)({
    url: '/api/sysDept/addSysDept',
    method: 'post',
    data: data
  });
}
/*
 编辑部门
 */


function editSysDept(data) {
  return (0, _request.default)({
    url: '/api/sysDept/editSysDept',
    method: 'post',
    data: data
  });
}
/*
    查看所有菜单
 */


function listMenuTree(data) {
  return (0, _request.default)({
    url: '/api/sysMenu/listMenuTree',
    method: 'post',
    data: data
  });
}
/*
    删除菜单
 */


function delMenu(id) {
  return (0, _request.default)({
    url: '/api/sysMenu/delSysMenu/' + id,
    method: 'post'
  });
}
/*
 根据部门id查询菜单
 */


function getSysMenuById(data) {
  return (0, _request.default)({
    url: '/api/sysMenu/getSysMenuById',
    method: 'post',
    params: data
  });
}
/*
    新增菜单
 */


function addSysMenu(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/api/sysMenu/addSysMenu',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
    编辑菜单
 */


function editSysMenu(data) {
  return (0, _request.default)({
    url: '/api/sysMenu/editSysMenu',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
    分页查询角色
 */


function listSysRolePage(data) {
  return (0, _request.default)({
    url: '/api/sysRole/listSysRolePage',
    method: 'post',
    data: data
  });
}
/*
    查询所有角色
 */


function listSysRole(data) {
  return (0, _request.default)({
    url: '/api/sysRole/listSysRole',
    method: 'post',
    params: data
  });
}
/*
 根据角色id查询角色
 */


function getSysRoleById(data) {
  return (0, _request.default)({
    url: '/api/sysRole/getSysRoleById',
    method: 'post',
    params: data
  });
}
/*
    新增角色
 */


function addSysRole(data) {
  return (0, _request.default)({
    url: '/api/sysRole/addSysRole',
    method: 'post',
    data: data
  });
}
/*
    编辑角色
 */


function editSysRole(data) {
  return (0, _request.default)({
    url: '/api/sysRole/editSysRole',
    method: 'post',
    data: data
  });
}
/*
    分页查询用户
 */


function listSysUserPage(data) {
  return (0, _request.default)({
    url: '/api/sysUser/listSysUserPage',
    method: 'post',
    data: data
  });
}
/*
 根据用户id查询用户
 */


function getSysUserById(data) {
  return (0, _request.default)({
    url: '/api/sysUser/getSysUserById',
    method: 'post',
    params: data
  });
}
/*
    新增用户
 */


function addSysUser(data) {
  return (0, _request.default)({
    url: '/api/sysUser/addSysUser',
    method: 'post',
    data: data
  });
}
/*
    编辑用户
 */


function editSysUser(data) {
  return (0, _request.default)({
    url: '/api/sysUser/editSysUser',
    method: 'post',
    data: data
  });
}