"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _mem = _interopRequireDefault(require("./modules/mem"));

var _deposit = _interopRequireDefault(require("./modules/deposit"));

var _coupon = _interopRequireDefault(require("./modules/coupon"));

var _content = _interopRequireDefault(require("./modules/content"));

var _finance = _interopRequireDefault(require("./modules/finance"));

var _report = _interopRequireDefault(require("./modules/report"));

var _face = _interopRequireDefault(require("./modules/face"));

var _set = _interopRequireDefault(require("./modules/set"));

var _integral = _interopRequireDefault(require("./modules/integral"));

var _member = _interopRequireDefault(require("./modules/member"));

var _label = _interopRequireDefault(require("./modules/label.js"));

var _getCoupon = _interopRequireDefault(require("./modules/getCoupon.js"));

var _flowCoupon = _interopRequireDefault(require("./modules/flowCoupon.js"));

var _dcb = _interopRequireDefault(require("./modules/dcb.js"));

var _public = _interopRequireDefault(require("./modules/public.js"));

var _template = _interopRequireDefault(require("./modules/template.js"));

var _svip = _interopRequireDefault(require("./modules/svip.js"));

var _parking = _interopRequireDefault(require("./modules/parking.js"));

var _router = _interopRequireDefault(require("../views/mall/router.js"));

var _router2 = _interopRequireDefault(require("../views/activity/router.js"));

var _router3 = _interopRequireDefault(require("../views/touch/router.js"));

var _router4 = _interopRequireDefault(require("../views/pufa/router.js"));

var _router5 = _interopRequireDefault(require("../views/card/router.js"));

var _router6 = _interopRequireDefault(require("../views/deposit/giftToken/router.js"));

var _router7 = _interopRequireDefault(require("../views/coupon/activity/router.js"));

var _router8 = _interopRequireDefault(require("../views/coupon/router.js"));

var _router9 = _interopRequireDefault(require("../views/member/grade/router.js"));

var _router10 = _interopRequireDefault(require("../views/qny/router.js"));

var _router11 = _interopRequireDefault(require("../views/equity/router.js"));

var _router12 = _interopRequireDefault(require("../views/deposit/lock/router.js"));

var _router13 = _interopRequireDefault(require("../views/deposit/group/router.js"));

var _basic = _interopRequireDefault(require("./modules/basic"));

var _miniapp = _interopRequireDefault(require("./modules/miniapp"));

var _shopCard = _interopRequireDefault(require("./modules/shopCard"));

var _router14 = _interopRequireDefault(require("../views/coupon/label/router.js"));

var _router15 = _interopRequireDefault(require("../views/external/router.js"));

var _router16 = _interopRequireDefault(require("../views/games/router"));

var _router17 = _interopRequireDefault(require("../views/payment/router.js"));

var _router18 = _interopRequireDefault(require("../views/message/router.js"));

var _router19 = _interopRequireDefault(require("../views/storeActivity/router.js"));

var _router20 = _interopRequireDefault(require("../views/legou/router.js"));

var _router21 = _interopRequireDefault(require("../views/groupBuyManager/router.js"));

_vue.default.use(_vueRouter.default);

var constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true,
      roles: ['admin', 'editor']
    }
  }]
}];
exports.constantRoutes = constantRoutes;
var asyncRoutes = [_router20.default, _router3.default, _coupon.default, _getCoupon.default, _deposit.default, _mem.default, _content.default, _finance.default, _report.default, _set.default, _face.default, _integral.default, _member.default, _svip.default, _parking.default, _label.default, _dcb.default, _flowCoupon.default, _public.default, _template.default, _router.default, _router2.default, _router4.default, _router5.default, _router6.default, _router12.default, _router13.default, _router7.default, _router8.default, _router14.default, _router9.default, _router10.default, _router11.default, _basic.default, _miniapp.default, _shopCard.default, _router15.default, _router16.default, _router17.default, _router18.default, _router19.default, _router21.default, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;