var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "div",
        { staticStyle: { padding: "20px 0" } },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "20px", color: "#409EFF" } },
              [_vm._v("\n        商品信息\n      ")]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-descriptions",
        {
          attrs: {
            size: "small ",
            labelStyle: {
              width: "130px",
              textAlign: "right"
            },
            column: 2,
            border: ""
          }
        },
        _vm._l(_vm.formData, function(item, index) {
          return _c(
            "el-descriptions-item",
            { key: index, attrs: { label: item.label } },
            [
              item.value === "service"
                ? _c(
                    "div",
                    _vm._l(_vm.ruleForm.service, function(
                      service,
                      serviceindex
                    ) {
                      return _c(
                        "el-tag",
                        { key: serviceindex, attrs: { size: "mini" } },
                        [_vm._v(_vm._s(service) + "\n        ")]
                      )
                    }),
                    1
                  )
                : item.value === "userMultipleLimit"
                ? _c(
                    "div",
                    _vm._l(_vm.ruleForm.userMultipleLimit, function(
                      limit,
                      limitIndex
                    ) {
                      return _c(
                        "span",
                        { key: limitIndex },
                        [
                          limit == 1
                            ? _c(
                                "el-tag",
                                { attrs: { size: "mini", type: "warning" } },
                                [_vm._v("普通会员")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          limit == 2
                            ? _c("el-tag", { attrs: { size: "mini" } }, [
                                _vm._v("365会员")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          limit == 3
                            ? _c(
                                "el-tag",
                                { attrs: { size: "mini", type: "success" } },
                                [_vm._v("lavant会员")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                : item.value === "spuCostPrice"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-tag",
                        {
                          staticStyle: {
                            width: "45px",
                            "text-align": "center",
                            "margin-right": "6px"
                          },
                          attrs: { size: "mini", type: "warning" }
                        },
                        [_vm._v("成本价")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "form_remark",
                          staticStyle: { "margin-right": "10px" }
                        },
                        [_vm._v(_vm._s(_vm.ruleForm.spuCostPrice) + "元")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tag",
                        {
                          staticStyle: {
                            width: "45px",
                            "text-align": "center",
                            "margin-right": "6px"
                          },
                          attrs: { size: "mini" }
                        },
                        [_vm._v("原价")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "form_remark",
                          staticStyle: { "margin-right": "10px" }
                        },
                        [_vm._v(_vm._s(_vm.ruleForm.spuOriginalPrice) + "元")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tag",
                        {
                          staticStyle: {
                            width: "45px",
                            "text-align": "center",
                            "margin-right": "6px"
                          },
                          attrs: { size: "mini", type: "success" }
                        },
                        [_vm._v("售价")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "form_remark" }, [
                        _vm._v(_vm._s(_vm.ruleForm.spuGoodsPrice) + "元")
                      ])
                    ],
                    1
                  )
                : item.value === "limitPurchaseQuantity"
                ? _c("div", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.ruleForm.limitPurchaseQuantity > 0
                            ? _vm.ruleForm.limitPurchaseQuantit + "件"
                            : "不限购"
                        ) +
                        "\n      "
                    )
                  ])
                : item.type === "radio"
                ? _c(
                    "div",
                    [
                      _vm.ruleForm[item.value] == 1
                        ? _c(
                            "el-tag",
                            { attrs: { size: "mini", type: "success" } },
                            [_vm._v("启用")]
                          )
                        : _c("el-tag", { attrs: { size: "mini" } }, [
                            _vm._v("关闭")
                          ])
                    ],
                    1
                  )
                : _c("div", [
                    _vm._v(_vm._s(_vm.ruleForm[item.value]) + "\n        "),
                    item.end
                      ? _c("span", { staticClass: "form_remark" }, [
                          _vm._v(_vm._s(item.end))
                        ])
                      : _vm._e()
                  ])
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { padding: "20px 0" } },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "20px", color: "#409EFF" } },
              [_vm._v("\n        规格组\n      ")]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            size: "mini",
            data: _vm.ruleForm.goodsSkuDTO,
            "max-height": "800px",
            border: "",
            "header-cell-class-name": "table_header_cell"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              align: "center",
              width: "60"
            }
          }),
          _vm._v(" "),
          _vm._l(_vm.changeSpecList, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: { label: item.label, align: "center", width: "80" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(scope.row.saleProperties[index]) +
                            "\n      "
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "原价",
              align: "center",
              prop: "skuOriginalPrice",
              "min-width": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "售价",
              align: "center",
              prop: "skuGoodsPrice",
              "min-width": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "库存",
              align: "center",
              prop: "skuStock",
              "min-width": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "昂捷编码",
              align: "center",
              prop: "goodsCode",
              "min-width": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "SKU编码",
              align: "center",
              prop: "skuNo",
              "min-width": "130"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "图片", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: {
                        width: "50px",
                        height: "50px",
                        "margin-right": "5px"
                      },
                      attrs: {
                        src: scope.row.skuImage,
                        "preview-src-list": [scope.row.skuImage]
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.opType == 2
                      ? _c(
                          "el-tag",
                          { attrs: { size: "mini", type: "danger" } },
                          [_vm._v("已删除")]
                        )
                      : _c(
                          "el-tag",
                          { attrs: { size: "mini", type: "success" } },
                          [_vm._v("正常")]
                        )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { padding: "20px 0" } },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "20px", color: "#409EFF" } },
              [_vm._v("\n        商品图文\n      ")]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-descriptions",
        {
          attrs: {
            size: "small ",
            labelStyle: {
              width: "130px",
              textAlign: "right"
            },
            column: 1,
            border: ""
          }
        },
        [
          _vm.ruleForm.goodsImages.length > 0
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "商品图：" } },
                [
                  _c("addImgList", {
                    staticClass: "img_list",
                    attrs: {
                      width: 100,
                      disabled: "",
                      isHttp: "",
                      maxLength: _vm.ruleForm.goodsImages.length,
                      selectCover: _vm.ruleForm.selectCover,
                      height: 100,
                      list: _vm.ruleForm.goodsImages
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.goodsImages.length > 0
            ? _c("el-descriptions-item", { attrs: { label: "图文详情：" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "375px",
                      height: "540px",
                      overflow: "auto"
                    }
                  },
                  [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.ruleForm.detail) }
                    })
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }