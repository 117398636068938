"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.link");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _api = require("./api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      total: 1,
      requestParams: {
        pageTitle: undefined,
        pageUrl: undefined,
        pageNo: 1,
        pageSize: 10
      },
      list: [],
      dialogOpen: false,
      linkForm: {
        page_title: "",
        page_url: ""
      },
      linkRules: {
        page_title: [{
          required: true,
          message: '请输入页面标题',
          trigger: 'blur'
        }],
        page_url: [{
          required: true,
          message: '请输入页面地址',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleCopy: function handleCopy(row) {
      var input = document.createElement('input');
      input.value = row.link;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      this.$message.success('复制成功!');
      document.body.removeChild(input);
    },
    handleCommit: function handleCommit() {
      var _this = this;

      this.$refs["linkForm"].validate(function (valid) {
        if (valid) {
          var data = Object.assign({}, _this.linkForm);
          data.is_permanent = true;
          (0, _api.linksCreate)(data).then(function () {
            _this.$message.success("添加成功");

            _this.dialogOpen = false;

            _this.getList();
          });
        }
      });
    },
    handleCreate: function handleCreate() {
      if (this.$refs["linkForm"]) {
        this.$refs["linkForm"].resetFields();
      }

      this.dialogOpen = true;
    },
    handleReset: function handleReset() {
      this.$refs['requestParamsForm'].resetFields();
      this.handleQuery();
    },
    handleQuery: function handleQuery() {
      this.requestParams.pageNo = 1;
      this.getList();
    },
    handlePageChange: function handlePageChange() {
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;

      (0, _api.linksPage)(this.requestParams).then(function (res) {
        _this2.list = res.data.records;
        _this2.total = res.data.total;
      });
    }
  }
};
exports.default = _default;