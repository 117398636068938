"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.number.constructor");

var _content = require("@/api/content.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      tit: '新增文章',
      title: '',
      articleId: '',
      onlineStatus: '1',
      options: [{
        value: 1,
        label: '使用帮助QA'
      }, {
        value: 2,
        label: '协议类文章'
      }],
      classId: '',
      sort: '',
      name: '',
      myEditor: 'myEditor',
      content: ''
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    change: function change(instructions) {
      this.instructions = instructions;
    },
    init: function init() {
      var _this = this;

      this.articleId = Number(this.$route.query.articleId);

      if (this.articleId) {
        (0, _content.getArticleById)({
          articleId: this.articleId
        }).then(function (res) {
          console.log(res);
          _this.title = res.data.title;
          _this.sort = res.data.sort;
          _this.content = res.data.content;
          _this.classId = res.data.classId;
          _this.onlineStatus = String(res.data.onlineStatus);
        });
      }
    },
    next: function next() {
      var _this2 = this;

      var data = {
        title: this.title,
        classId: this.classId,
        content: this.content,
        onlineStatus: Number(this.onlineStatus),
        sort: Number(this.sort)
      };
      this.$confirm('此操作将保存方案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this2.articleId) {
          data.articleId = _this2.articleId;
        }

        sessionStorage.setItem('contentObj', JSON.stringify(data));

        _this2.$router.push('/content/manageContent/corfimAdd');
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消保存'
        });
      });
    }
  }
};
exports.default = _default;