"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _report = require("@/api/report.js");

var _user = require("@/api/user.js");

var _index = require("@/api/svip/index.js");

var _userInfo = _interopRequireDefault(require("./userInfo.vue"));

var _editInfo = _interopRequireDefault(require("./editInfo.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    userInfo: _userInfo.default,
    editInfo: _editInfo.default
  },
  data: function data() {
    var _this = this;

    return {
      userPwd: {
        password: '',
        password_: '',
        customerType: '',
        managerNo: ''
      },
      userPwdRules: {
        managerNo: [{
          required: true,
          message: '请输入密码'
        }],
        password: [{
          required: true,
          message: '请输入密码'
        }, {
          pattern: /^[^\s]{6,20}$/,
          message: '请输入6到20位的密码！'
        }],
        password_: [{
          required: true,
          message: '请输入确认密码'
        }, {
          pattern: /^[^\s]{6,20}$/,
          message: '请输入6到20位的密码！'
        }, {
          validator: function validator(rule, value, callback) {
            if (value != _this.userPwd.password) {
              callback(new Error('*密码不一致'));
            } else {
              callback();
            }
          }
        }]
      },
      userForm: {
        realname: '',
        sex: 0,
        nation: '',
        birthday: '',
        // voucher: '',
        idNo: '',
        status: 1
      },
      userRules: {
        realname: [{
          required: true,
          message: '请输入用户真实姓名'
        }],
        sex: [{
          required: true,
          message: '请选择性别'
        }],
        nation: [{
          required: true,
          message: '请输入用户民族'
        }],
        birthday: [{
          required: true,
          message: '请选择生日'
        }],
        // voucher: [{ required: true, message: '请选择证件类型', trigger: 'change' }],
        idNo: [{
          required: true,
          message: '请输入身份证号'
        }, {
          pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/,
          message: '证件号码格式有误！'
        }]
      },
      type: '1',
      popShow: false,
      autofocus: true,
      memeberCode: '',
      memeberMobile: '',
      mid: '',
      user: {},
      uid: '',
      status: '',
      memberList: [],
      couponList: [],
      lavant: null
    };
  },
  created: function created() {},
  methods: {
    setCustomerType: function setCustomerType(e) {
      if (e) {
        this.userPwd.customerType = 2;
      } else {
        this.userPwd.customerType = 1;
      }
    },
    setPwd: function setPwd() {
      var _this2 = this;

      this.$refs['userPwd'].validate(function (valid) {
        if (valid) {
          console.log(_this2.userPwd);
          var data = _this2.userPwd;
          data.uid = _this2.user.uid;

          _this2.openLoading();

          (0, _user.editUserPwd)(data).then(function (res) {
            _this2.openLoading().close();

            _this2.$message({
              showClose: true,
              message: '保存成功',
              type: 'success'
            });
          }).catch(function (err) {
            _this2.openLoading().close();
          });
        }
      });
    },
    IdCard: function IdCard(UUserCard) {
      var sex = '';

      if (UUserCard.length == 15) {
        sex = UUserCard.substr(14, 1);
        this.userForm.birthday = '19' + UUserCard.substring(6, 8) + '-' + UUserCard.substring(8, 10) + '-' + UUserCard.substring(10, 12);
      } else {
        sex = UUserCard.substr(16, 1);
        this.userForm.birthday = UUserCard.substring(6, 10) + '-' + UUserCard.substring(10, 12) + '-' + UUserCard.substring(12, 14);
      }

      if (sex % 2 == 1) {
        // 男
        this.userForm.sex = 1;
      } else {
        // 女
        this.userForm.sex = 2;
      }
    },
    idNoChange: function idNoChange(e) {
      var _this3 = this;

      this.$refs.formUser.validateField('idNo', function (res) {
        if (!res) {
          _this3.IdCard(_this3.userForm.idNo);
        }
      });
    },
    setStatus: function setStatus(e) {
      if (e) {
        this.userForm.status = 1;
      } else {
        this.userForm.status = 2;
      }
    },
    setUser: function setUser() {
      var _this4 = this;

      this.$refs['formUser'].validate(function (valid) {
        if (valid) {
          console.log(_this4.userForm);
          var data = _this4.userForm;
          data.uid = _this4.user.uid;

          _this4.openLoading();

          (0, _report.editUser)(data).then(function (res) {
            _this4.openLoading().close();

            _this4.$message({
              showClose: true,
              message: '保存成功',
              type: 'success'
            });
          }).catch(function (err) {
            _this4.openLoading().close();
          });
        }
      });
    },
    closePop: function closePop() {
      this.popShow = false;
    },
    goMid: function goMid() {
      var _this5 = this;

      this.popShow = true;
      this.$nextTick(function () {
        _this5.$refs.midInput.focus();
      });
    },
    goSearchType: function goSearchType() {
      var data = {
        type: 2,
        mobile: this.mid
      };
      this.goSearch(data);
    },
    goSearch: function goSearch(obj) {
      var _this6 = this;

      var data = {};

      if (obj.type == 2) {
        data = obj;
      } else {
        data = {
          mobile: this.memeberCode,
          type: this.type
        };
      }

      this.openLoading();
      this.memberList = [];
      this.user = {};
      this.status = '';
      (0, _report.handleMidUser)(data).then(function (res) {
        _this6.openLoading().close();

        if (_this6.popShow) {
          _this6.popShow = false;
        }

        _this6.userPwd.customerType = res.data.user.customerType; // console.log(res.data.user.birthday.split('.').join('-'))

        _this6.mid = '';
        _this6.user = res.data.user;
        _this6.userForm.status = res.data.user.status;
        _this6.userForm.uid = res.data.user.uid;
        _this6.userForm.realname = res.data.user.realname;
        _this6.userForm.sex = res.data.user.sex;
        _this6.userForm.nation = res.data.user.nation;
        _this6.userForm.birthday = res.data.user.birthday.split('.').join('-');
        _this6.userForm.mobile = res.data.user.mobile;
        _this6.userForm.idNo = res.data.user.idNo;
        _this6.userForm.gradeName = res.data.user.gradeName;
        _this6.userForm.growthValue = res.data.user.growthValue;
        _this6.memberList = res.data.cards;
        _this6.status = res.data.status;
        console.log(232323);
        (0, _index.getMember)({
          id: res.data.user.mobile
        }, {
          isErrMsg: true
        }).then(function (res) {
          _this6.lavant = res.data;
        });

        _this6.$refs.card.setUid(res.data.user.uid);
      }).catch(function (res) {
        _this6.openLoading().close();
      });
    }
  }
};
exports.default = _default;