var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-member-form" },
    [
      _c("div", { staticClass: "view_title" }, [
        _vm._v(_vm._s(_vm.id ? "编辑" : "新建") + "要客")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-width": "150px",
            model: _vm.ruleForm,
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "会员手机号：", prop: "mobile" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "form_input",
                  attrs: { disabled: !!_vm.id, placeholder: "请输入" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchMember($event)
                    }
                  },
                  model: {
                    value: _vm.ruleForm.mobile,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "mobile", $$v)
                    },
                    expression: "ruleForm.mobile"
                  }
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      type: "primary",
                      icon: "el-icon-search"
                    },
                    on: { click: _vm.searchMember },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "要客姓名：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { disabled: !_vm.member, placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "性别：", prop: "sex" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !_vm.id },
                  model: {
                    value: _vm.ruleForm.sex,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "sex", $$v)
                    },
                    expression: "ruleForm.sex"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("未知")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("男")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("女")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话：", prop: "contactNumber" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { disabled: !_vm.member, placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.contactNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "contactNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.contactNumber"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "生日：", prop: "birthday" } },
            [
              _c("el-date-picker", {
                staticClass: "form_input",
                attrs: {
                  disabled: !_vm.member || _vm.ruleForm.birthdayByMainUser,
                  type: "date",
                  placeholder: "选择日期",
                  "value-format": "yyyy-MM-dd"
                },
                model: {
                  value: _vm.ruleForm.birthday,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "birthday", $$v)
                  },
                  expression: "ruleForm.birthday"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "公司：", prop: "company" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { disabled: !_vm.member, placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.company,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "company",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.company"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "职务：", prop: "job" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { disabled: !_vm.member, placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.job,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "job",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.job"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "要客等级：", prop: "levelId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  attrs: {
                    disabled: !_vm.member || _vm.member.status === 2,
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.ruleForm.levelId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "levelId", $$v)
                    },
                    expression: "ruleForm.levelId"
                  }
                },
                _vm._l(_vm.levelList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    },
                    [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { color: "#999" } }, [
                _vm._v("选择等级后，此要客将拥有此等级的权益")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "remark" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                staticStyle: { "line-height": "1" },
                attrs: {
                  autosize: { minRows: 4, maxRows: 6 },
                  disabled: !_vm.member,
                  type: "textarea",
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "remark",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "标签 ：" } },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "200px" },
                    attrs: { placeholder: "请输入选项", size: "mini" },
                    model: {
                      value: _vm.itemName,
                      callback: function($$v) {
                        _vm.itemName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "itemName"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.addItem()
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.ruleForm.labelList, function(fileItem, index) {
                return _c(
                  "el-tag",
                  {
                    key: index,
                    staticStyle: { margin: "10px" },
                    attrs: { size: "medium", closable: "" },
                    on: {
                      close: function($event) {
                        return _vm.delTag(index)
                      }
                    }
                  },
                  [_vm._v(_vm._s(fileItem))]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.id
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm", "edit")
                        }
                      }
                    },
                    [_vm._v("\n        保存\n      ")]
                  )
                : _c(
                    "el-button",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm", "add")
                        }
                      }
                    },
                    [_vm._v("\n        立即新建\n      ")]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }