var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "svip-activity-list" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n      活动列表\n      "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: {
                  click: function($event) {
                    return _vm.goto("/svip/activity/add")
                  }
                }
              },
              [_vm._v("\n          新增活动\n        ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-download",
                  loading: _vm.exportExcelLoading,
                  size: "mini"
                },
                on: { click: _vm.exportExcel }
              },
              [_vm._v("\n          导出Excel\n        ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticClass: "row_div",
          staticStyle: { padding: "0 0 10px 0" },
          attrs: { gutter: 10 }
        },
        [
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                staticClass: "input2",
                attrs: { size: "mini", placeholder: "活动名称" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetch("search")
                  }
                },
                model: {
                  value: _vm.searchParams.name,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "name", $$v)
                  },
                  expression: "searchParams.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    filterable: "",
                    placeholder: "活动状态"
                  },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status"
                  }
                },
                _vm._l(_vm.activityStatusList, function(s) {
                  return _c("el-option", {
                    key: s.value,
                    attrs: { label: s.label, value: s.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    filterable: "",
                    placeholder: "支付方式"
                  },
                  model: {
                    value: _vm.searchParams.buyerType,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "buyerType", $$v)
                    },
                    expression: "searchParams.buyerType"
                  }
                },
                _vm._l(_vm.buyTypeList, function(s) {
                  return _c("el-option", {
                    key: s.value,
                    attrs: { label: s.label, value: s.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    filterable: "",
                    placeholder: "所属门店"
                  },
                  model: {
                    value: _vm.searchParams.storeId,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "storeId", $$v)
                    },
                    expression: "searchParams.storeId"
                  }
                },
                _vm._l(_vm.storeOptions, function(store) {
                  return _c(
                    "el-option",
                    {
                      key: store.id,
                      attrs: {
                        label: store.shortName + "（" + store.storeCode + "）",
                        value: store.storeId
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            store.shortName + "（" + store.storeCode + "）"
                          ) +
                          "\n          "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "daterange",
                  size: "mini",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd"
                },
                model: {
                  value: _vm.searchParams.date,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "date", $$v)
                  },
                  expression: "searchParams.date"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.fetch("search")
                }
              }
            },
            [_vm._v("\n        查询\n      ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "mini", icon: "el-icon-refresh" },
              on: {
                click: function($event) {
                  return _vm.fetch("reset")
                }
              }
            },
            [_vm._v("\n        重置\n      ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            data: _vm.list,
            border: "",
            "header-cell-class-name": "table_header_cell"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              width: "70",
              fixed: "left",
              align: "center",
              label: "序号"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          (_vm.searchParams.pageNo - 1) *
                            _vm.searchParams.pageSize +
                            scope.$index +
                            1
                        ) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.columns, function(column) {
            return _c("el-table-column", {
              key: column.prop,
              attrs: {
                label: column.label,
                prop: column.prop,
                width: column.width,
                formatter: column.formatter,
                align: "center"
              }
            })
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120", align: "center", label: "上下架" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        disabled: !_vm.setMenuPerms("changActivityStatus"),
                        value: scope.row.shelve
                      },
                      on: {
                        change: function($event) {
                          return _vm.switchField("shelve", scope.$index)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "可购买人群标签",
              align: "center",
              "mni-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getUserLabel(scope.row.labelContent)) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "不可购买人群标签",
              align: "center",
              "mni-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getUserLabel(scope.row.labelContentNo)) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "280",
              align: "center",
              fixed: "right",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goto(
                              "/svip/activity/edit/" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("\n            编辑\n          ")]
                    ),
                    _vm._v(" "),
                    scope.row.shelve && scope.row.status === 1
                      ? _c(
                          "el-popconfirm",
                          {
                            style: "margin: 0 10px",
                            attrs: { title: "确定要取消活动吗？" },
                            on: {
                              confirm: function($event) {
                                return _vm.cancelActivity(scope.row.id)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference"
                              },
                              [_vm._v("取消活动")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.shelve &&
                    (scope.row.status === 1 ||
                      (scope.row.status === 2 &&
                        _vm.isReserve(
                          scope.row.reserveStartTime,
                          scope.row.reserveEndTime
                        )))
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.reserve(
                                  scope.row.id,
                                  scope.row.reserveStartTime,
                                  scope.row.reserveEndTime
                                )
                              }
                            }
                          },
                          [_vm._v("\n            预约\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.shelve && scope.row.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.goto(
                                  "/svip/activity/detail/" +
                                    scope.row.id +
                                    "?type=detail"
                                )
                              }
                            }
                          },
                          [_vm._v("\n            详情\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.shelve
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.getQrcode(scope.row)
                              }
                            }
                          },
                          [_vm._v("二维码\n          ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.searchParams.pageNo,
          limit: _vm.searchParams.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchParams, "pageNo", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchParams, "pageSize", $event)
          },
          pagination: _vm.fetch
        }
      }),
      _vm._v(" "),
      _vm.qrParams.open
        ? _c("qrCodePop", {
            attrs: {
              "qr-code": _vm.qrParams.code,
              url: _vm.qrParams.url,
              "coupon-u-uid": _vm.qrParams.uuid
            },
            on: {
              closePop: function($event) {
                _vm.qrParams.open = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }