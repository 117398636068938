"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _report = require("@/api/report.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    userForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    userRules: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    setUser: function setUser() {
      var _this = this;

      this.$refs['formUser'].validate(function (valid) {
        if (valid) {
          console.log(_this.userForm);
          var data = _this.userForm;
          data.uid = _this.user.uid;
          (0, _report.editUser)(data).then(function (res) {
            console.log(res);
          });
        }
      });
    }
  }
};
exports.default = _default2;