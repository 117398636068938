"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/es6.array.find");

var _finance = require("@/api/finance.js");

var _tableList = _interopRequireDefault(require("@/components/tableList"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tableList: _tableList.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      btnType: 'primary',
      btn: '查看详情',
      operation: 2,
      total: 0,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [{
        tit: '订单号/流水号',
        prop: 'sid',
        width: 160
      }, {
        tit: '类型',
        prop: 'type',
        width: 120,
        formatter: this.tableFormatter
      }, {
        tit: '发票代码',
        prop: 'fpdm',
        width: 120
      }, {
        tit: '发票号码',
        prop: 'fphm',
        width: 120
      }, {
        tit: '开票时间',
        prop: 'createTime',
        width: 180
      }, {
        tit: '开票用户',
        prop: 'name',
        width: 180
      }, {
        tit: '用户手机号',
        prop: 'peopleTel',
        width: 120
      }, {
        tit: '审核人',
        prop: 'auditUser',
        width: 120
      }, {
        tit: '开票状态',
        prop: 'specialInvoiceStatus',
        width: 100,
        formatter: this.tableFormatter
      }, {
        tit: '票据类型',
        prop: 'statusTxt',
        width: 100
      }],
      sid: '',
      fpdm: '',
      fphm: '',
      peopleTel: '',
      betweenTime: [],
      options1: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '已开票'
      }, {
        value: 2,
        label: '已冲红'
      }, {
        value: 3,
        label: '重新开票'
      }],
      value1: '',
      typeOptions: [{
        val: 1,
        lab: '企业'
      }, {
        val: 3,
        lab: '非企业型组织'
      }, {
        val: 2,
        lab: '个人'
      }],
      specialInvoiceStatusOptions: [{
        val: -1,
        lab: '开票审核不通过'
      }, {
        val: 0,
        lab: '专票：开票中 普票：待审'
      }, {
        val: 1,
        lab: '成功'
      }, {
        val: 2,
        lab: '开票失败'
      }],
      openChecked: false,
      checkData: {},
      checkForm: {
        status: '',
        remark: '',
        id: 0
      },
      checkRules: {
        status: [{
          required: true,
          message: '请选择审核方案',
          trigger: 'change'
        }],
        remark: [{
          required: true,
          message: '请输入备注',
          trigger: 'blur'
        }]
      }
    };
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    // 审核
    handleCheck: function handleCheck(row) {
      var _this = this;

      if (row.id) {
        (0, _finance.invoiceDetail)({
          id: row.id
        }).then(function (res) {
          _this.checkData = res.data;

          if (_this.$refs.checkForm) {
            _this.$refs.checkForm.resetFields();
          }

          _this.checkForm = {
            status: '',
            remark: '',
            id: row.id
          };
          _this.openChecked = true;
        });
        return;
      }

      this.$refs.checkForm.validate(function (valid) {
        if (!valid) return;
        (0, _finance.checkedInvoice)(_this.checkForm).then(function (res) {
          _this.$message.success('审核成功');

          _this.openChecked = false;

          _this.getList();
        });
      });
    },
    // 列表格式化
    tableFormatter: function tableFormatter(row, _ref) {
      var property = _ref.property;
      var options = [];
      var val = '';

      if (property == 'type') {
        options = this.typeOptions;
        val = row.type;
      }

      if (property == 'specialInvoiceStatus') {
        options = this.specialInvoiceStatusOptions;
        val = row.specialInvoiceStatus;
      }

      if (!val || options.length == 0) return '';
      return options.find(function (item) {
        return item.val == val;
      }).lab;
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['订单号/流水号', '发票代码', '发票号码', '开票时间', '用户手机号', '票据类型', '价税合计', '发票地址'];
        var filterVal = ['sid', 'fpdm', 'fphm', 'createTime', 'peopleTel', 'statusTxt', 'jshj', 'pdfUrl'];
        var list = [];
        var data = {
          pageNo: _this2.pageNo,
          pageSize: _this2.total,
          peopleTel: _this2.peopleTel.trim(),
          sId: _this2.sid.trim(),
          fpdm: _this2.fpdm.trim(),
          fphm: _this2.fphm.trim(),
          startTime: _this2.betweenTime[0] || '',
          endTime: _this2.betweenTime[1] || ''
        };
        (0, _finance.listInvoice)(data).then(function (res) {
          list = res.data.items;
          list.forEach(function (item) {
            if (!_this2.setMenuPerms('user_mobile_idCard')) {
              if (item.peopleTel) {
                item.peopleTel = item.peopleTel.replace(item.peopleTel.substring(3, 7), "****");
              }
            }

            if (item.status % 2 == 1) {
              item.statusTxt = '蓝票';
            } else if (item.status % 2 == 0) {
              item.statusTxt = '红票';
            } // if (item.status > 3) {
            //   if (item.status % 2 == 1) {
            //     item.statusTxt = '重新开票'
            //   } else if (item.status % 2 == 0) {
            //     item.statusTxt = '再次冲红'
            //   }
            // } else {
            //   switch (item.status) {
            //     case 1:
            //       item.statusTxt = '已开票'
            //       break
            //     case 2:
            //       item.statusTxt = '已冲红'
            //       break
            //     case 3:
            //       item.statusTxt = '重新开票'
            //       break
            //   }
            // }

          });

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '发票'
          });
          _this2.downloadLoading = false;
        }).catch(function (res) {
          _this2.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return;
          } else {
            return v[j];
          }
        });
      });
    },
    goAdd: function goAdd() {
      this.$router.push('/finance/invoice/openInvoice');
    },
    setBtn: function setBtn(scope) {
      console.log(scope); // let data=JSON.stringify(scope)

      this.$router.push('/finance/invoice/openInvoice?id=' + scope.id);
    },
    getList: function getList(e) {
      var _this3 = this;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        peopleTel: this.peopleTel.trim(),
        sid: this.sid.trim(),
        fpdm: this.fpdm.trim(),
        fphm: this.fphm.trim(),
        startTime: this.betweenTime[0] || '',
        endTime: this.betweenTime[1] || ''
      };

      if (this.sid) {// data.sId=this.sid.trim()
      }

      console.log(data);
      (0, _finance.listInvoice)(data).then(function (res) {
        _this3.total = res.data.totalNum || 0;
        _this3.tableData = res.data.items;

        if (res.data.items.length > 0) {
          _this3.tableData.forEach(function (item) {
            if (!_this3.setMenuPerms('user_mobile_idCard')) {
              if (item.peopleTel) {
                item.peopleTel = item.peopleTel.replace(item.peopleTel.substring(3, 7), "****");
              }
            }

            if (item.status % 2 == 1) {
              item.statusTxt = '蓝票';
            } else if (item.status % 2 == 0) {
              item.statusTxt = '红票';
            } // if (item.status > 3) {
            //   if (item.status % 2 == 1) {
            //     item.statusTxt = '重新开票'
            //   } else if (item.status % 2 == 0) {
            //     item.statusTxt = '再次冲红'
            //   }
            // } else {
            //   switch (item.status) {
            //     case 1:
            //       item.statusTxt = '已开票'
            //       break
            //     case 2:
            //       item.statusTxt = '已冲红'
            //       break
            //     case 3:
            //       item.statusTxt = '重新开票'
            //       break
            //   }
            // }

          });
        }
      });
    },
    remove: function remove() {
      this.betweenTime = [];
      this.peopleTel = '';
      this.fpdm = '';
      this.sid = '';
      this.value1 = '';
      this.fphm = '';
      this.pageNo = 1;
      this.pageSize = 10;
      this.getList();
    }
  }
};
exports.default = _default;