var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-table" },
    [
      _vm.isTitle
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "view_title flex_between" },
                [
                  _vm._v("\n      " + _vm._s(_vm.pageMeta.title) + "\n      "),
                  _vm._t("titleBtn")
                ],
                2
              ),
              _vm._v(" "),
              _c("el-divider")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            "validate-on-rule-change": false,
            model: _vm.ruleForm,
            size: "mini",
            rules: _vm.isSetRules ? _vm.formRules : _vm.rules,
            disabled: _vm.disabled,
            "label-width": _vm.labelWidth
          }
        },
        [
          _vm._l(_vm.formData, function(item, index) {
            return item.isShow != "no"
              ? _c(
                  "div",
                  { key: index },
                  [
                    item.type === "divider"
                      ? _c(
                          "div",
                          {
                            staticStyle: { padding: "20px 0" },
                            attrs: { id: item.id }
                          },
                          [
                            _c(
                              "el-divider",
                              { attrs: { "content-position": "left" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "20px",
                                      color: "#409EFF"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.title) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : item.slot
                      ? _vm._t(item.slot)
                      : !item.showType || item.showType !== "no"
                      ? _c(
                          "div",
                          [
                            item.type === "input"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref: item.value,
                                    refInFor: true,
                                    attrs: {
                                      label: item.label,
                                      prop: item.value
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "form_input",
                                      attrs: {
                                        maxlength: item.max,
                                        "show-word-limit": item.max > 0,
                                        placeholder: item.placeholder
                                      },
                                      model: {
                                        value: _vm.ruleForm[item.value],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            item.value,
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm[item.value]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "inputNumber"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref: item.value,
                                    refInFor: true,
                                    attrs: {
                                      label: item.label,
                                      prop: item.value
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticClass: "form_inputNumber",
                                      attrs: {
                                        precision:
                                          item.precision >= 0
                                            ? item.precision
                                            : undefined,
                                        controls: false,
                                        min:
                                          typeof item.min == "number"
                                            ? item.min
                                            : 1,
                                        max: item.max || 9999999,
                                        placeholder: item.placeholder
                                      },
                                      model: {
                                        value: _vm.ruleForm[item.value],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            item.value,
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm[item.value]"
                                      }
                                    }),
                                    _vm._v(" "),
                                    item.end
                                      ? _c(
                                          "span",
                                          { staticClass: "form_remark" },
                                          [_vm._v(_vm._s(item.end))]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "cascader"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref: item.value,
                                    refInFor: true,
                                    attrs: {
                                      label: item.label,
                                      prop: item.value
                                    }
                                  },
                                  [
                                    _c("el-cascader", {
                                      ref: "wCascader",
                                      refInFor: true,
                                      staticClass: "form_input",
                                      attrs: {
                                        options: item.selectList,
                                        filterable: "",
                                        props: {
                                          value: "id",
                                          label: "gcName",
                                          children: "children",
                                          checkStrictly: true
                                        }
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.changeCascader(
                                            $event,
                                            item.value
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.ruleForm[item.value],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            item.value,
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm[item.value]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "radio"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref: item.value,
                                    refInFor: true,
                                    attrs: {
                                      label: item.label,
                                      prop: item.value
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: item.disabled },
                                        on: {
                                          change: function($event) {
                                            return _vm.changeForm(
                                              $event,
                                              item.value
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.ruleForm[item.value],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              item.value,
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm[item.value]"
                                        }
                                      },
                                      _vm._l(item.selectList, function(
                                        row,
                                        index
                                      ) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: index,
                                            attrs: { label: row.value }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(row.label) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "tag"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref: item.value,
                                    refInFor: true,
                                    attrs: {
                                      label: item.label,
                                      prop: item.value
                                    }
                                  },
                                  [
                                    _vm._l(_vm.ruleForm[item.value], function(
                                      tag
                                    ) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: tag,
                                          staticStyle: {
                                            margin: "0 10px 10px 0"
                                          },
                                          attrs: {
                                            closable: "",
                                            "disable-transitions": false
                                          },
                                          on: {
                                            close: function($event) {
                                              return _vm.handleClose(
                                                tag,
                                                item.value
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(tag) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _vm.inputVisible
                                      ? _c("el-input", {
                                          ref: "saveTagInput",
                                          refInFor: true,
                                          staticClass: "input-new-tag",
                                          staticStyle: { width: "200px" },
                                          attrs: { size: "small" },
                                          on: {
                                            blur: function($event) {
                                              return _vm.handleInputConfirm(
                                                $event,
                                                item.value
                                              )
                                            }
                                          },
                                          nativeOn: {
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.handleInputConfirm(
                                                $event,
                                                item.value
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.inputValue,
                                            callback: function($$v) {
                                              _vm.inputValue = $$v
                                            },
                                            expression: "inputValue"
                                          }
                                        })
                                      : _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini"
                                            },
                                            on: { click: _vm.showInput }
                                          },
                                          [_vm._v("+ 添加")]
                                        )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "check"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref: item.value,
                                    refInFor: true,
                                    attrs: {
                                      label: item.label,
                                      prop: item.value
                                    }
                                  },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        model: {
                                          value: _vm.ruleForm[item.value],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              item.value,
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm[item.value]"
                                        }
                                      },
                                      _vm._l(item.selectList, function(
                                        row,
                                        index
                                      ) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: index,
                                            attrs: { label: row.value }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(row.label) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "select"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref: item.value,
                                    refInFor: true,
                                    attrs: {
                                      label: item.label,
                                      prop: item.value
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "form_input",
                                        attrs: {
                                          clearable: "",
                                          filterable: "",
                                          placeholder: item.placeholder
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changeForm(
                                              $event,
                                              item.value
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.ruleForm[item.value],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              item.value,
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm[item.value]"
                                        }
                                      },
                                      _vm._l(item.selectList, function(
                                        row,
                                        index
                                      ) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: row.label,
                                            value: row.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "startTime"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref: item.value,
                                    refInFor: true,
                                    attrs: {
                                      label: item.label,
                                      prop: item.value
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: item.dataType || "datetime",
                                        "value-format":
                                          item.valueFormat ||
                                          "yyyy-MM-dd HH:mm:ss",
                                        placeholder: "选择开始时间"
                                      },
                                      model: {
                                        value: _vm.ruleForm[item.value],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            item.value,
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm[item.value]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "timeRange"
                              ? _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        ref: item.value1,
                                        refInFor: true,
                                        attrs: {
                                          label: item.label,
                                          prop: item.value1
                                        }
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            type: "datetime",
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            placeholder: "选择开始时间"
                                          },
                                          model: {
                                            value: _vm.ruleForm[item.value1],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                item.value1,
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm[item.value1]"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        ref: item.value2,
                                        refInFor: true,
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          prop: item.value2,
                                          "label-width": "0"
                                        }
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            disabled: !_vm.ruleForm[
                                              item.value1
                                            ],
                                            "picker-options": _vm.setpickerTime(
                                              item.value1
                                            ),
                                            type: "datetime",
                                            "default-time": "23:59:59",
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            placeholder: "选择结束时间"
                                          },
                                          model: {
                                            value: _vm.ruleForm[item.value2],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                item.value2,
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm[item.value2]"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "tinymce"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref: item.value,
                                    refInFor: true,
                                    attrs: {
                                      label: item.label,
                                      prop: item.value
                                    }
                                  },
                                  [
                                    _c("Tinymce", {
                                      key: _vm.tinymceIndex,
                                      staticStyle: {
                                        "max-width": "80%",
                                        "min-width": "600px"
                                      },
                                      model: {
                                        value: _vm.ruleForm[item.value],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            item.value,
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm[item.value]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "imgList"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref: item.value,
                                    refInFor: true,
                                    attrs: {
                                      label: item.label,
                                      prop: item.value
                                    }
                                  },
                                  [
                                    _c("addImgList", {
                                      key:
                                        JSON.stringify(
                                          _vm.ruleForm[item.value]
                                        ) || item.value,
                                      staticClass: "img_list",
                                      attrs: {
                                        width: 100,
                                        disabled: _vm.disabled,
                                        selectCover:
                                          _vm.ruleForm[item.selectCover],
                                        isHttp: item.isHttp,
                                        height: 100,
                                        list: _vm.ruleForm[item.value]
                                      },
                                      on: {
                                        setDifCover: function($event) {
                                          return _vm.setDifCover(
                                            $event,
                                            item.selectCover
                                          )
                                        },
                                        removeCover: function($event) {
                                          return _vm.removeCover(
                                            $event,
                                            item.value
                                          )
                                        },
                                        setCoverMore: function($event) {
                                          return _vm.setCoverList(
                                            $event,
                                            item.value
                                          )
                                        },
                                        setCover: function($event) {
                                          return _vm.setBanner(
                                            $event,
                                            item.value
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "img"
                              ? _c(
                                  "el-form-item",
                                  {
                                    ref: item.value,
                                    refInFor: true,
                                    attrs: {
                                      label: item.label,
                                      prop: item.value
                                    }
                                  },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "avatar-uploader",
                                        attrs: {
                                          action: _vm.action,
                                          headers: _vm.headers,
                                          "show-file-list": false,
                                          "auto-upload": false,
                                          "on-success": _vm.handleAvatarSuccess,
                                          "on-change": _vm.beforeAvatarUpload,
                                          accept:
                                            "image/png, image/jpeg, image/gif, image/webp"
                                        }
                                      },
                                      [
                                        _vm.ruleForm[item.value]
                                          ? _c("img", {
                                              staticClass: "avatar",
                                              style: {
                                                width: item.width + "px",
                                                height: item.height + "px"
                                              },
                                              attrs: {
                                                src: _vm.isHttps
                                                  ? _vm.ruleForm[item.value]
                                                  : _vm.imgSrc +
                                                    _vm.ruleForm[item.value]
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.imgType = item.value
                                                }
                                              }
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "el-icon-plus avatar-uploader-icon",
                                              style: {
                                                width: item.width + "px",
                                                height: item.height + "px",
                                                lineHeight: item.height + "px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.imgType = item.value
                                                }
                                              }
                                            })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    item.placeholder
                                      ? _c(
                                          "p",
                                          { staticClass: "form_remark" },
                                          [_vm._v(_vm._s(item.placeholder))]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.remark
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "form_remark",
                                    staticStyle: { "margin-bottom": "10px" },
                                    style: { paddingLeft: _vm.labelWidth }
                                  },
                                  [_vm._v("\n          " + _vm._s(item.remark))]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e()
          }),
          _vm._v(" "),
          !_vm.disabled
            ? _c(
                "el-form-item",
                [
                  _vm.isShowCancelBtn
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form_btn",
                          staticStyle: { width: "180px" },
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("goCancelForm")
                            }
                          }
                        },
                        [_vm._v("取消")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSubmitForm
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form_btn",
                          staticStyle: { width: "180px" },
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }