"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.split");

var _mem = require("@/api/mem.js");

var _selectUrl = _interopRequireDefault(require("@/common/selectUrl.js"));

var _radioItem = _interopRequireDefault(require("@/components/couponPop/radioItem.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    couponPop: _radioItem.default
  },
  data: function data() {
    return {
      couponShow: false,
      popType: null,
      urlList: _selectUrl.default.urlList,
      appidArr: _selectUrl.default.appIdArr,
      urlTypeList: _selectUrl.default.urlTypeList,
      noSelect: _selectUrl.default.noSelect,
      vlaueData: _selectUrl.default.vlaueData,
      linkUrlData: _selectUrl.default.linkUrlData,
      formType: null,
      storeList: [],
      classList: [],
      formData: [{
        label: '活动主题：',
        type: 'input',
        placeholder: '请输入活动主题',
        value: 'activtyThme'
      }, {
        label: '按钮文字：',
        type: 'input',
        placeholder: '请输入按钮文字',
        value: 'buttonDesc'
      }, {
        label: '状态：',
        type: 'radio',
        placeholder: '请选择状态',
        value: 'status',
        selectList: [{
          label: '启用',
          value: 1
        }, {
          label: '停用',
          value: -1
        }]
      }, {
        label: '活动时间：',
        type: 'timeRange',
        placeholder: '请选择时间',
        value1: 'startTime',
        value2: 'endTime'
      }, {
        label: '活动图片：',
        type: 'img',
        placeholder: '请上传活动图片',
        value: 'activityImage'
      }, {
        slot: 'storeCode',
        label: '门店编码：',
        type: 'input',
        placeholder: '请输入门店编码',
        value: 'storeCode'
      }, {
        slot: 'activityCategoryId',
        label: '分类：',
        type: 'input',
        placeholder: '请输入分类',
        value: 'activityCategoryId'
      }, {
        slot: 'pageUrl'
      }],
      ruleForm: {
        "id": null,
        "contentLink": null,
        "contentType": null,
        "appid": null,
        "activtyThme": null,
        "startTime": null,
        "endTime": null,
        "storeCode": null,
        "activityCategoryId": null,
        "status": null,
        activityImage: null
      },
      rules: {
        activityImage: [{
          required: true,
          message: '请上传图片'
        }],
        activtyThme: [{
          required: true,
          message: '请输入活动主题'
        }],
        startTime: [{
          required: true,
          message: '请选择开始时间'
        }],
        endTime: [{
          required: true,
          message: '请选择结束时间'
        }],
        storeCode: [{
          required: true,
          message: '请选择门店'
        }],
        activityCategoryId: [{
          required: true,
          message: '请选择分类'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.storeList = res.data;
    });
    this.apiPost('/api/org-store/activity/ctgPage', {
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      _this.classList = res.data.records;
    });
    this.formType = this.$route.query.type;
    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/org-store/activity/getAtyById/' + this.ruleForm.id).then(function (res) {
        if (_this.formType == 'copy') {
          delete res.data.id;
        }

        _this.ruleForm = res.data;

        if (_this.urlTypeList.indexOf(res.data.contentType) >= 0) {
          console.log(res.data.contentLink);
          _this.ruleForm.contentLink = res.data.contentLink.split('id=')[1];
        } else {
          _this.ruleForm.contentLink = res.data.contentLink;
        }
      });
    }
  },
  methods: {
    typeChange: function typeChange() {
      this.ruleForm.contentLink = '';
    },
    getLinkId: function getLinkId() {
      this.popType = this.ruleForm.contentType;
      this.$refs.coupon1.getList(this.popType, this.ruleForm.contentLink);
      this.couponShow = true;
    },
    setCoupon: function setCoupon(e) {
      if (this.popType === 'tem') {
        this.ruleForm.contentLink = e.pageCode;
      } else if (this.popType === 'vipCity' || this.popType === 'card' || this.popType === 'coupon') {
        this.ruleForm.contentLink = e.uuid;
      } else if (this.popType === 'goods') {
        // temItem.productSource = e.productSource
        this.ruleForm.contentLink = e.productCode;
      } else if (this.popType === 'couponBag') {
        // temItem.productSource = e.productSource
        this.ruleForm.contentLink = e.id;
      } else if (this.popType === 'pond') {
        this.ruleForm.contentLink = e.code;
      }

      this.couponShow = false;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var data = JSON.parse(JSON.stringify(this.ruleForm));

      if (this.urlTypeList.includes(this.ruleForm.contentType)) {
        data.contentLink = this.linkUrlData[this.ruleForm.contentType].url + data.contentLink;
      } else if (this.ruleForm.contentType) {
        data.contentLink = this.ruleForm.contentType;
      }

      this.apiPost('/api/org-store/activity/saveOrUpdateAty', data).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;