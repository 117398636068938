var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("订单详情")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "order_box" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("\n      订单信息\n    ")]),
        _vm._v(" "),
        _c(
          "el-form",
          { attrs: { "label-width": "120" } },
          [
            _c("el-form-item", { attrs: { label: "订单编号" } }, [
              _vm._v("\n        " + _vm._s(_vm.obj.orderNo || "") + "\n      ")
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "商品信息" } },
              _vm._l(_vm.obj.orderItems, function(item, index) {
                return _c("p", { key: index }, [
                  _vm._v("\n          " + _vm._s(item.goodsName) + " "),
                  _c("span", { staticStyle: { margin: "0 20px" } }, [
                    _vm._v(
                      "(￥" +
                        _vm._s(item.goodsPrice) +
                        "*" +
                        _vm._s(item.goodsNum) +
                        ")"
                    )
                  ])
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "订单金额" } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.obj.orderAmount || "") + "\n      "
              )
            ]),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "优惠金额" } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.obj.discountAmount || "") + "\n      "
              )
            ]),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "实付金额" } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.obj.realpayAmount || "") + "\n      "
              )
            ]),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "付款时间" } }, [
              _vm._v("\n        " + _vm._s(_vm.obj.payTime || "") + "\n      ")
            ]),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "支付单号" } }, [
              _vm._v("\n        " + _vm._s(_vm.obj.payNo || "") + "\n      ")
            ]),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "订单状态" } }, [
              _vm.obj.orderStatus == 1
                ? _c("span", [_vm._v("\n          待付款\n        ")])
                : _vm.obj.orderStatus == 2
                ? _c("span", [_vm._v("\n          待发货\n        ")])
                : _vm.obj.orderStatus == 3
                ? _c("span", [_vm._v("\n          已发货\n        ")])
                : _vm.obj.orderStatus == 4
                ? _c("span", [_vm._v("\n          已售后\n        ")])
                : _vm._e()
            ])
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "order_box" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v("\n      收货人信息\n    ")
        ]),
        _vm._v(" "),
        _c(
          "el-form",
          { attrs: { "label-width": "120" } },
          [
            _c("el-form-item", { attrs: { label: "收货人姓名" } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.obj.receiptName || "") + "\n      "
              )
            ]),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "收货人电话" } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.obj.receiptMobile || "") + "\n      "
              )
            ]),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "收货人地址" } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.obj.receiptAddress || "") + "\n      "
              )
            ])
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "order_box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("\n      物流信息\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "logistics" }, [
        _c("div", { staticClass: "name" }, [_vm._v("物流单号")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "logisticsName" },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                type: "text",
                disabled: _vm.orderStatus != 2,
                placeholder: "物流名称"
              },
              model: {
                value: _vm.logisticsName,
                callback: function($$v) {
                  _vm.logisticsName = $$v
                },
                expression: "logisticsName"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "logisticsNo" },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                type: "text",
                disabled: _vm.orderStatus != 2,
                placeholder: "物流单号"
              },
              model: {
                value: _vm.logisticsNo,
                callback: function($$v) {
                  _vm.logisticsNo = $$v
                },
                expression: "logisticsNo"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "btn" }, [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.orderStatus != 2,
                    size: "mini"
                  },
                  on: { click: _vm.setOrder }
                },
                [_vm._v("保存物流信息")]
              )
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.orderStatus == 2 || _vm.orderStatus == 3
      ? _c(
          "div",
          { staticClass: "order_box" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("\n      退款\n    ")]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticStyle: { width: "100%" },
                attrs: {
                  border: "",
                  data: _vm.obj.orderItems,
                  "tooltip-effect": "dark"
                }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", width: "55", align: "center" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "goodsName",
                    label: "商品名称",
                    align: "center"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "goodsNum",
                    label: "商品数量",
                    align: "center"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "goodsPrice",
                    label: "商品单价",
                    align: "center"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "goodsSubtotal",
                    label: "商品总价",
                    align: "center"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("el-input", {
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "textarea", rows: 2, placeholder: "退款备注" },
              model: {
                value: _vm.returnRemark,
                callback: function($$v) {
                  _vm.returnRemark = $$v
                },
                expression: "returnRemark"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.returnPay } },
              [_vm._v("退款")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }