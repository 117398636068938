"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _api = require("./api.js");

var _api2 = require("../category/api.js");

var _api3 = require("../class/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      storeList: [],
      classList: [],
      rules: {
        planName: [{
          required: true,
          message: '请输入计划名称'
        }],
        cardCategoryId: [{
          required: true,
          message: '请选择卡类型'
        }],
        cardKindsId: [{
          required: true,
          message: '请选择卡种类'
        }],
        cardNumber: [{
          required: true,
          message: '请输入卡数量'
        }],
        cardSegmentNoStart: [{
          required: true,
          message: '请输入起始号段'
        }],
        cardSegmentNoEnd: [{
          required: true,
          message: '请输入结束号段'
        }],
        makeFactoryName: [{
          required: true,
          message: '请输入制卡商'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }]
      },
      ruleForm: {
        cardCategoryId: null,
        cardKindsId: null,
        planName: null,
        cardNumber: null,
        cardSegmentNoStart: null,
        cardSegmentNoEnd: null,
        makeFactoryName: null,
        remark: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;
    (0, _api2.categoryPage)({
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      _this.storeList = res.data.records;
    });

    if (this.ruleForm.id) {
      (0, _api.getKinds)(this.ruleForm.id).then(function (res) {
        _this.getClass(res.data.cardCategoryId);

        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    classChange: function classChange(e) {
      console.log(e);
    },
    getClass: function getClass(e) {
      var _this2 = this;

      this.classList = [];
      this.ruleForm.cardKindsId = null;
      (0, _api3.classPage)({
        pageNo: 1,
        pageSize: 100,
        cardCategoryId: e
      }).then(function (res) {
        _this2.classList = res.data.records;
      });
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    // 三级地址选择
    regionDataChange: function regionDataChange(e) {
      var arr = this.$refs['state'].getCheckedNodes(false)[0].pathLabels || [];
      this.ruleForm.detailAddress = arr.join('');
    },
    clearForm: function clearForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this3.ruleForm));
          (0, _api.addKinds)(data).then(function (res) {
            _this3.$router.go(-1);

            _this3.$message({
              type: 'success',
              message: '保存成功!'
            });
          });
          return;

          if (_this3.ruleForm.id) {
            data.id = _this3.ruleForm.id;
            (0, _api.editKinds)(data).then(function (res) {
              _this3.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this3.$router.go(-1);
            });
          } else {
            (0, _api.addKinds)(data).then(function (res) {
              _this3.$router.go(-1);

              _this3.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;