"use strict";

var _interopRequireWildcard = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/core-js/modules/es6.array.iterator.js");

require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/core-js/modules/es6.promise.js");

require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/core-js/modules/es6.object.assign.js");

require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

require("babel-polyfill");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("element-ui/lib/theme-chalk/display.css");

require("@/styles/index.scss");

require("./styles/public.css");

var _aweDnd = _interopRequireDefault(require("awe-dnd"));

var _App = _interopRequireDefault(require("./App"));

var _method = _interopRequireDefault(require("./method.js"));

var _vueMonoplastySlideVerify = _interopRequireDefault(require("vue-monoplasty-slide-verify"));

var _index2 = _interopRequireDefault(require("@/components/W-table/index.vue"));

var _index3 = _interopRequireDefault(require("@/components/W-form/index.vue"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("./icons");

require("./permission");

require("./utils/error-log");

var filters = _interopRequireWildcard(require("./filters"));

var _options = _interopRequireDefault(require("@/utils/options"));

var requireComponent = require.context('./components/addLoad', false, /\.vue$/);

_vue.default.use(_aweDnd.default);

_vue.default.component('WTable', _index2.default);

_vue.default.component('WForm', _index3.default);

_vue.default.use(_vueMonoplastySlideVerify.default);

_vue.default.use(_method.default);

// global filters
_vue.default.config.devtools = true;
/** 权限指令**/

_vue.default.directive('has', {
  inserted: function inserted(el, binding) {
    if (!_vue.default.prototype.$_has(binding.value)) {
      el.parentNode.removeChild(el);
    }
  }
});

_vue.default.prototype.$_has = function (value) {
  var isExist = false;
  var buttonpermsStr = JSON.parse(localStorage.getItem('menuPerms'));

  if (buttonpermsStr === undefined || buttonpermsStr === null) {
    return false;
  }

  if (buttonpermsStr.indexOf(value) !== -1) {
    isExist = true;
  } else {
    isExist = false;
  }

  return isExist;
};

_vue.default.prototype.setCouponIdBags = function (arr) {
  var str = '';
  arr.forEach(function (value) {
    str += value + '-';
  });
  return str.substr(0, str.length - 1);
};

_vue.default.prototype.setTree = function (arr, arrId) {
  var arrTree = [];
  var returnId = [];
  arr.forEach(function (value) {
    if (value.childNodes.length > 0) {
      value.childNodes.forEach(function (item) {
        arrTree.push(item);
      });
    }
  });
  arrTree.forEach(function (item) {
    if (arrId.indexOf(item.selectStatu) !== -1) {
      returnId.push(item.id);
    }
  });
  return returnId;
};

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size

}); // register global utility filters


Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
}); // 全局枚举值

_vue.default.prototype._Options = _options.default;
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});