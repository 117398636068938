var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manual-into" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: {
            model: _vm.params,
            rules: _vm.rules,
            size: "small",
            "label-width": "180px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "是否需加磁", prop: "val" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.params.val,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "val", $$v)
                    },
                    expression: "params.val"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("无需加磁")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("需加磁")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "input-width", attrs: { label: "计划单号" } },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.params.name,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "name", $$v)
                      },
                      expression: "params.name"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", size: "small" }
                    },
                    [_vm._v("选择计划")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info-list" },
                _vm._l(_vm.infoOption, function(info, infoIndex) {
                  return _c(
                    "div",
                    { key: infoIndex, staticClass: "flex info-item" },
                    [
                      _c("div", { staticClass: "lab" }, [
                        _vm._v(_vm._s(info.title) + "：")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "val" }, [
                        _vm._v(
                          _vm._s(
                            info.val
                              ? info.val
                              : info.default
                              ? info.default
                              : ""
                          )
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "制卡商" } },
            [
              _c("el-select", {
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticStyle: { width: "900px" }, attrs: { label: "入库范围" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus"
                  },
                  on: { click: _vm.onAddRow }
                },
                [_vm._v("添加范围")]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { color: "#666666", float: "right" } },
                [
                  _vm._v("未入库数量 "),
                  _c("span", { staticStyle: { color: "#1980ff" } }, [
                    _vm._v("9990")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { data: _vm.params.list, size: "mini", border: "" }
                },
                [
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "date",
                        label: "起始卡号",
                        align: "center"
                      }
                    },
                    [
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { controls: false, placeholder: "起始卡号" }
                        })
                      ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    { attrs: { prop: "name", label: "数量", align: "center" } },
                    [
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { controls: false, placeholder: "数量" }
                        })
                      ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "address",
                        label: "结束卡号",
                        align: "center"
                      }
                    },
                    [
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { controls: false, placeholder: "结束卡号" }
                        })
                      ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var $index = ref.$index
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "orange" },
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onDeleteRow($index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "input-width",
              attrs: { label: "备注", prop: "remark" }
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", autosize: { minRows: 5 } },
                model: {
                  value: _vm.params.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "remark", $$v)
                  },
                  expression: "params.remark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "actions" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }