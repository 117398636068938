var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 新增/编辑补签卡 ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            disabled: _vm.disabled,
            "label-width": "120px",
            rules: _vm.rules,
            size: "mini",
            model: _vm.form
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称：", prop: "signCardParam.name" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { type: "text", placeholder: "填写名称" },
                model: {
                  value: _vm.form.signCardParam.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.signCardParam,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.signCardParam.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "过期天数：", prop: "signCardParam.days" } },
            [
              _c("el-input", {
                staticClass: "form_input",
                attrs: { type: "text", placeholder: "填写过期天数" },
                model: {
                  value: _vm.form.signCardParam.days,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.signCardParam,
                      "days",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.signCardParam.days"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.signCardParam.status < 2
            ? _c(
                "el-form-item",
                { attrs: { label: "状态：", prop: "signCardParam.status" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.form.signCardParam.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form.signCardParam, "status", $$v)
                        },
                        expression: "form.signCardParam.status"
                      }
                    },
                    [_vm._v("禁用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.form.signCardParam.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form.signCardParam, "status", $$v)
                        },
                        expression: "form.signCardParam.status"
                      }
                    },
                    [_vm._v("启用")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "图片：", prop: "signCardParam.imageUrl" } },
            [
              _c("add-load", {
                staticClass: "img_list",
                attrs: {
                  width: 100,
                  height: 100,
                  cover: _vm.form.signCardParam.imageUrl
                },
                on: {
                  setCover: function($event) {
                    return _vm.setCover($event, "signCardParam", "imageUrl")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "view_title flex_between" }, [
            _vm._v(" 领取限制 ")
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _vm._l(_vm.form.ruleParamList, function(item, index) {
            return _c(
              "el-form-item",
              {
                key: index,
                attrs: { label: item.label, prop: "ruleParamList" }
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 2 } },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-value": index === 0 ? 0 : 1,
                                "inactive-value": -1
                              },
                              model: {
                                value: item.memberType,
                                callback: function($$v) {
                                  _vm.$set(item, "memberType", $$v)
                                },
                                expression: "item.memberType"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        item.memberType >= 0
                          ? _c(
                              "el-col",
                              { attrs: { span: 20 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    ref: "ruleParamList." + index + ".free",
                                    refInFor: true,
                                    attrs: {
                                      label: "领取类型：",
                                      prop: "ruleParamList." + index + ".free",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择领取类型"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 1 },
                                        model: {
                                          value: item.free,
                                          callback: function($$v) {
                                            _vm.$set(item, "free", $$v)
                                          },
                                          expression: "item.free"
                                        }
                                      },
                                      [_vm._v("免费领取")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 0 },
                                        model: {
                                          value: item.free,
                                          callback: function($$v) {
                                            _vm.$set(item, "free", $$v)
                                          },
                                          expression: "item.free"
                                        }
                                      },
                                      [_vm._v("积分兑换")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        ref:
                                          "ruleParamList." +
                                          index +
                                          ".limitDateCycle",
                                        refInFor: true,
                                        attrs: {
                                          label: "限制类型：",
                                          prop:
                                            "ruleParamList." +
                                            index +
                                            ".limitDateCycle",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择类型"
                                            }
                                          ]
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100px" },
                                            attrs: { placeholder: "请选择" },
                                            model: {
                                              value: item.limitDateCycle,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "limitDateCycle",
                                                  $$v
                                                )
                                              },
                                              expression: "item.limitDateCycle"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "不限制",
                                                value: 0
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: { label: "每周", value: 1 }
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: { label: "每月", value: 2 }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    item.limitDateCycle !== 0 &&
                                    typeof item.limitDateCycle === "number"
                                      ? _c(
                                          "el-form-item",
                                          {
                                            ref:
                                              "ruleParamList." +
                                              index +
                                              ".limitReceiveNumber",
                                            refInFor: true,
                                            attrs: {
                                              label: "领取数量：",
                                              prop:
                                                "ruleParamList." +
                                                index +
                                                ".limitReceiveNumber",
                                              rules: [
                                                {
                                                  required: true,
                                                  message: "请输入数量"
                                                }
                                              ]
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "form_input",
                                              attrs: {
                                                type: "number",
                                                controls: false,
                                                placeholder: "填写数量"
                                              },
                                              model: {
                                                value: item.limitReceiveNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "limitReceiveNumber",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "item.limitReceiveNumber"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-form-item", {
                                  ref:
                                    "ruleParamList." +
                                    index +
                                    ".limitDateCycle",
                                  refInFor: true,
                                  attrs: {
                                    prop:
                                      "ruleParamList." +
                                      index +
                                      ".limitDateCycle",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择限领规则"
                                      }
                                    ]
                                  }
                                }),
                                _vm._v(" "),
                                item.free === 0
                                  ? _c(
                                      "el-form-item",
                                      {
                                        ref:
                                          "ruleParamList." + index + ".price",
                                        refInFor: true,
                                        attrs: {
                                          label: "积分价格：",
                                          prop:
                                            "ruleParamList." + index + ".price",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请输入积分价格"
                                            }
                                          ]
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "form_input",
                                          attrs: {
                                            type: "number",
                                            placeholder: "填写积分"
                                          },
                                          model: {
                                            value: item.price,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "price",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "item.price"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.id
                          ? _c(
                              "el-col",
                              { attrs: { span: 2 } },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "确认删除该条规则？" },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.delRuleItem(item, index)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "danger",
                                          size: "mini"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "描述：", prop: "signCardParam.description" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { width: 750, height: 500 },
                model: {
                  value: _vm.form.signCardParam.description,
                  callback: function($$v) {
                    _vm.$set(_vm.form.signCardParam, "description", $$v)
                  },
                  expression: "form.signCardParam.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "max-width": "200px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }