"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      isShowTable: true,
      goodsType: {
        1: '优惠券',
        2: '购物卡',
        3: '积分商品'
      },
      columnsGoods: [{
        label: '订单号',
        prop: 'orderNo',
        width: 80
      }, {
        slot: 'type'
      }, {
        label: '商品名称',
        prop: 'goodsName',
        width: 80
      }, {
        label: '商品编码',
        prop: 'code',
        width: 80
      }, {
        label: '数量',
        prop: 'num',
        width: 80
      }, {
        label: '订单金额',
        prop: 'amount',
        width: 80
      }, {
        label: '用户手机号',
        prop: 'mobile',
        width: 80
      }, {
        label: '外部商户名称',
        prop: 'merchantName',
        width: 80
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 80
      }, {
        slot: 'status'
      }],
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '订单号',
        value: 'orderNo'
      }, {
        type: 'input',
        span: 4,
        placeholder: '商品名称',
        value: 'goodsName'
      }, {
        type: 'input',
        span: 4,
        placeholder: '商品编码',
        value: 'code'
      }, {
        type: 'input',
        span: 4,
        placeholder: '用户手机号',
        value: 'mobile'
      }, {
        type: 'radio',
        span: 4,
        placeholder: '商品类型',
        value: 'type',
        select: [{
          label: '优惠券',
          value: 1
        }, {
          label: '购物卡',
          value: 2
        }, {
          label: '积分商品',
          value: 3
        }]
      }, {
        type: 'radio',
        span: 4,
        placeholder: '商品状态',
        value: 'status',
        select: [{
          label: '已售',
          value: 1
        }, {
          label: '已退',
          value: 2
        }]
      }, {
        type: 'dateTime',
        span: 4,
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        span: 4,
        placeholder: '结束时间',
        defaultTime: '23:59:59',
        value: 'endTime'
      }]
    };
  },
  methods: {
    changeType: function changeType(e) {
      this.$refs.wTable.setRequestValue('type', e);
      this.$refs.wTable.getList();

      if (e == 1) {
        this.columns = this.columnsCoupon;
      }

      if (e == 2) {
        this.columns = this.columnsCard;
      }

      if (e == 3) {
        this.columns = this.columnsGoods;
      }
    },
    goCasancelGoods: function goCasancelGoods(row) {
      var _this = this;

      this.apiGet('/api/third/party/merchant/getThirdPartyMerchantGoodsDateil/' + row.id).then(function (res) {
        _this.$refs.wTable.getList();
      });
    }
  }
};
exports.default = _default;