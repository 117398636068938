var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addCoupon" },
    [_c("pathTab", { attrs: { list: _vm.list, title: _vm.title } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }