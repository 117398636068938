"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _apply = _interopRequireDefault(require("./apply"));

var _promontion = _interopRequireDefault(require("./promontion"));

var _pullNew = _interopRequireDefault(require("./pullNew"));

var _vote = _interopRequireDefault(require("./vote"));

//
//
//
//
//
var _default = {
  components: {
    apply: _apply.default,
    promontion: _promontion.default,
    pullNew: _pullNew.default,
    vote: _vote.default
  },
  data: function data() {
    return {
      currentRole: 'promontion'
    };
  },
  created: function created() {
    this.currentRole = this.$route.query.id;
  },
  methods: {}
};
exports.default = _default;