var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editScoreSet" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("查询/编辑全局设置")]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "40px" } }),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            size: "small",
            model: _vm.form,
            "label-width": "160px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "saleDay", label: "售后天数：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: {
                  type: "number",
                  placeholder: "只能输入大于等于0的数",
                  size: "small"
                },
                model: {
                  value: _vm.form.saleDay,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "saleDay", _vm._n($$v))
                  },
                  expression: "form.saleDay"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "receivingDay", label: "收货天数：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: {
                  type: "number",
                  placeholder: "只能输入大于等于0的数",
                  size: "small"
                },
                model: {
                  value: _vm.form.receivingDay,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "receivingDay", _vm._n($$v))
                  },
                  expression: "form.receivingDay"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "carryDay", label: "自提天数：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: {
                  type: "number",
                  placeholder: "只能输入大于等于0的数",
                  size: "small"
                },
                model: {
                  value: _vm.form.carryDay,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "carryDay", _vm._n($$v))
                  },
                  expression: "form.carryDay"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: {
                prop: "carryCloseDay",
                label: "自提关闭天数：",
                required: ""
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: {
                  type: "number",
                  placeholder: "只能输入大于等于0的数",
                  size: "small"
                },
                model: {
                  value: _vm.form.carryCloseDay,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "carryCloseDay", _vm._n($$v))
                  },
                  expression: "form.carryCloseDay"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: {
                prop: "logisticAppid",
                label: "物流APPID：",
                required: ""
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: { type: "number", size: "small" },
                model: {
                  value: _vm.form.logisticAppid,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "logisticAppid", _vm._n($$v))
                  },
                  expression: "form.logisticAppid"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: {
                prop: "logisticSecret",
                label: "物流秘钥：",
                required: ""
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: { type: "number", size: "small" },
                model: {
                  value: _vm.form.logisticSecret,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "logisticSecret", _vm._n($$v))
                  },
                  expression: "form.logisticSecret"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: {
            width: "100%",
            "max-width": "200px",
            "margin-left": "160px"
          },
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.onSubmit("form")
            }
          }
        },
        [_vm._v("保存")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }