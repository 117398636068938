var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "box_tit" }, [_vm._v("积分补录")]),
    _vm._v(" "),
    !_vm.successShow
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "add_btn" },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.downloadUrl,
                      download: "积分补录模板.xlsx"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "upLoad",
                        attrs: { icon: "el-icon-download" }
                      },
                      [_vm._v("下载模板")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("upload-excel-component", {
                  attrs: {
                    "btn-text": "批量上传",
                    type: "btn",
                    "on-success": _vm.handleSuccess,
                    "before-upload": _vm.beforeUpload
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  border: "",
                  data: _vm.list,
                  size: "mini",
                  "max-height": "500"
                }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "序号", align: "center", width: "80" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.$index + 1) +
                                "\n        "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3769485177
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "会员手机号",
                    align: "center",
                    "min-width": "100"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.phone) +
                                "\n        "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1070346721
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "所属门店",
                    align: "center",
                    "min-width": "100"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.storeCode) +
                                "\n        "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2736067119
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "积分变动类型",
                    align: "center",
                    "min-width": "80"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.type == 1 ? "增加" : "减少") +
                                "\n        "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2762925873
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "积分变动数",
                    align: "center",
                    "min-width": "80"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.number) +
                                "\n        "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    155182142
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "相关单据号",
                    prop: "documentNo",
                    align: "center",
                    "min-width": "80"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "积分变动理由",
                    align: "center",
                    "min-width": "200"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.state) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1989603018
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", align: "center", width: "80" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "table_td_btn",
                                on: {
                                  click: function($event) {
                                    return _vm.deleteItem(scope.$index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    903210553
                  )
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: {
                  margin: "20px auto",
                  width: "200px",
                  display: "block"
                },
                attrs: { type: "primary" },
                on: { click: _vm.next }
              },
              [_vm._v("下一步")]
            )
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%", "margin-top": "20px" },
                attrs: {
                  border: "",
                  data: _vm.getnewList,
                  "row-class-name": _vm.tableRowClassName,
                  size: "mini",
                  "max-height": "500"
                }
              },
              _vm._l(_vm.tabTh, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    align: "center",
                    "min-width": item.width
                  }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "20px"
                }
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger" },
                    on: {
                      click: function($event) {
                        _vm.successShow = false
                      }
                    }
                  },
                  [_vm._v("返回修改")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.affirmNext } },
                  [_vm._v("确认补录")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.handleScore } },
              [_vm._v("导出可补录积分用户")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.handleUnScore } },
              [_vm._v("导出积分不足用户")]
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%", "margin-top": "20px" },
                attrs: {
                  border: "",
                  data: _vm.getUnNewList,
                  "row-class-name": _vm.tableRowClassName,
                  size: "mini",
                  "max-height": "500"
                }
              },
              _vm._l(_vm.tabTh, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    align: "center",
                    "min-width": item.width
                  }
                })
              }),
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }