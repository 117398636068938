var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "keep-alive",
        [
          _c(
            "w-table",
            {
              ref: "wTable",
              attrs: {
                size: "mini",
                columns: _vm.columns,
                "search-data": _vm.searchData
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  slot: "update",
                  label: "操作",
                  align: "center",
                  width: "120"
                },
                slot: "update",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/message/classAdd?id=" + scope.row.id
                                )
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.wPage(
                                  "/message/classAdd?type=copy&id=" +
                                    scope.row.id
                                )
                              }
                            }
                          },
                          [_vm._v("复制")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认删除该分类？" },
                            on: {
                              confirm: function($event) {
                                return _vm.delGrade(scope.row, scope.$index)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "danger",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("\n              删除\n            ")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }