var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            size: "mini",
            columns: _vm.columns,
            pageApi: "/api/mall/merchantCategory/list",
            "search-data": _vm.searchData
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "update",
              label: "操作",
              fixed: "right",
              align: "center",
              width: "140"
            },
            slot: "update",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          size: "mini",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/legou/merchant/categoryAdd?id=" + scope.row.id
                            )
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _vm._v(" "),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          title:
                            "确认删除分类：" + scope.row.categoryName + "？"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.delGoodsItem(scope.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "danger",
                            attrs: {
                              slot: "reference",
                              icon: "el-icon-delete",
                              type: "text",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("\n            删除\n          ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }