var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("会员等级")]),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            padding: "20px 0",
            "border-bottom": "1px solid #36A3F7",
            margin: "0"
          },
          attrs: { gutter: 24 }
        },
        [
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: { click: _vm.goAdd }
                },
                [_vm._v("新增会员等级")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("tables", {
        staticStyle: { "margin-top": "20px" },
        attrs: {
          list: _vm.list,
          "tab-th": _vm.tabTh,
          "btn-text": _vm.btnText,
          total: _vm.total,
          "page-no": _vm.obj.pageNo,
          "page-size": _vm.obj.pageSize
        },
        on: { setTab: _vm.setTab, init: _vm.init }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }