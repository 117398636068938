"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//import WTable from '@/components/tble/index.vue'
var _default = {
  components: {
    AppLink: _Link.default // WTable

  },
  data: function data() {
    return {
      searchData: [{
        type: 'input',
        placeholder: '名称',
        value: 'productSourceName'
      }, {
        type: 'radio',
        placeholder: '状态',
        value: 'rowStatus',
        select: [{
          value: 0,
          label: "启用"
        }, {
          value: -1,
          label: "停用"
        }]
      }],
      requestData: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        productSourceName: '',
        userName: '',
        userMobile: '',
        rowStatus: ''
      },
      tableData: [],
      columns: [{
        label: '名称',
        prop: 'productSourceName',
        width: 100
      }, {
        label: '负责人',
        prop: 'userName',
        width: 50
      }, {
        label: '负责人手机号',
        prop: 'userMobile',
        width: 80
      }, {
        label: '所在地址',
        prop: 'sourceAddress',
        width: 80
      }, {
        label: '备注',
        prop: 'sourceRemark',
        width: 80
      }, {
        slot: 'rowStatus'
      }, {
        slot: 'dea'
      }]
    };
  },
  activated: function activated() {// this.getList()
  },
  created: function created() {},
  methods: {
    setPage: function setPage(pageObj) {
      this.requestData.pageNo = pageObj.pageNo;
      this.requestData.pageSize = pageObj.pageSize;
      this.getList();
    },
    getList: function getList(type) {
      var _this = this;

      if (type === 'search') {
        this.pageNo = 1;
      }

      if (type === 'remove') {
        this.removeObj(this.requestData);
      }

      (0, _api.listSourcePage)(this.requestData).then(function (res) {
        _this.tableData = res.data.records;
        _this.requestData.total = res.data.total;
      });
    }
  }
};
exports.default = _default;