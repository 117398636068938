var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            size: "small",
            model: _vm.form,
            "label-width": "160px"
          }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "会员UUD：" } },
            [_vm._v("\n      " + _vm._s(_vm.uid) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "原手机号：" } },
            [_vm._v("\n      " + _vm._s(_vm.form.oldMobile) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: { prop: "newMobile", label: "新手机号：", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: { type: "number", size: "small" },
                model: {
                  value: _vm.form.newMobile,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "newMobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.newMobile"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "form_item",
              attrs: {
                prop: "dingtalkNumber",
                label: "钉钉工单号：",
                required: ""
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%", "max-width": "300px" },
                attrs: { type: "number", size: "small" },
                model: {
                  value: _vm.form.dingtalkNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "dingtalkNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.dingtalkNumber"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }