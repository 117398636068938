"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _manage = require("@/api/manage.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    AppLink: _Link.default
  },
  data: function data() {
    return {
      tableData: [],
      pageSize: 10,
      pageNo: 1,
      total: 0,
      btn: '查看详情',
      operation: 2,
      roleName: ''
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init(e) {
      var _this = this;

      if (e) {
        this.pageNo = e.page;
        this.pageSize = e.limit;
      }

      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        roleName: this.roleName
      };
      (0, _manage.listSysRolePage)(data).then(function (res) {
        console.log(res);
        _this.total = res.data.total;
        _this.tableData = res.data.records; // this.tableData=JSON.parse(JSON.stringify(res.data).replace(/childNodes/g,"children"))
      });
    },
    addDepartment: function addDepartment() {
      // 新增
      this.$router.push('/system/role/roleDea');
    },
    setBtn: function setBtn(roleId) {
      // 操作按钮执行函数
      this.$router.push('/system/role/roleDea?roleId=' + roleId);
    }
  }
};
exports.default = _default;