"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _elementUi = require("element-ui");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _mem = require("@/api/mem");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _popBox = _interopRequireDefault(require("@/components/popBox"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default,
    Tinymce: _Tinymce.default,
    popBox: _popBox.default
  },
  props: {//			cardId: {
    //				type: Number,
    //			}
  },
  data: function data() {
    return {
      editors: 'editor',
      defaultProps: {
        children: 'childInstitutions',
        label: 'name'
      },
      loadTxt: '保存中',
      fullscreenLoading: false,
      checkedArr: [],
      activeNames: 'first',
      total: 0,
      tabData: {},
      pageNo: 1,
      pageSize: 10,
      name: '',
      sysName: '',
      logoImg: '',
      createdTimes: 0,
      radio: '1',
      timeRadio: '',
      data: {},
      cardId: '',
      allTreeNodes: [],
      memberRule: '',
      parentLevel: [],
      // 树形父级
      cardMemberLevelVos: [],
      count: '',
      equitiesDesc: '',
      popShow: false // 编辑会员权益弹框

    };
  },
  created: function created() {
    this.cardId = Number(this.$route.query.id);
    this.getEditor();
    this.init();
  },
  methods: {
    /*
      选择会员时
      */
    checkChange: function checkChange() {
      this.checkedArr = this.$refs.tree.getCheckedKeys(true);
    },
    addLevels: function addLevels() {
      // 添加等级按钮
      this.cardMemberLevelVos.push({
        id: '',
        cardId: '',
        equitiesIds: '',
        name: '',
        shortName: '',
        image: '',
        score: '',
        createTime: '',
        equitiesDescs: ''
      });
    },
    closeShow: function closeShow() {
      // 取消权益编辑
      this.popShow = false;
    },
    setEquitiesDesc: function setEquitiesDesc(equitiesDesc) {
      // 保存会员规则
      this.popShow = false;
      this.$set(this.cardMemberLevelVos[this.count], 'equitiesDesc', this.equitiesDesc);
      this.equitiesDesc = '';
    },
    setgradeCover: function setgradeCover(index) {
      // 会员等级图片
      this.cardMemberLevelVos[this.count].image = index;
    },
    getIndex: function getIndex(scope) {
      // 编辑会员等级  获取下标
      this.count = scope.$index;
    },
    addQy: function addQy(scope) {
      // 编辑会员等级  权益
      this.count = scope.$index;
      this.equitiesDesc = scope.row.equitiesDesc;
      this.popShow = true;
    },
    handleModifyStatus: function handleModifyStatus(scope) {
      var _this = this;

      // 删除会员卡等级
      this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (scope.row.id) {
          var rLoading = _this.openLoading('正在删除中');

          (0, _mem.delCardMemberLevel)(scope.row.id).then(function (res) {
            rLoading.close();

            _this.cardMemberLevelVos.splice(scope.$index, 1);

            _this.$message({
              type: 'success',
              message: '删除成功!'
            });
          });
        } else {
          _this.cardMemberLevelVos.splice(scope.$index, 1);
        }
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    setGz: function setGz(index) {
      // 保存会员规则
      this.tabData.memberRule = index;
    },
    saveMember: function saveMember() {
      var _this2 = this;

      // 提交保存
      var obj = {
        cardId: this.tabData.cardId,
        name: this.tabData.name,
        sysName: this.tabData.sysName,
        sort: this.tabData.sort,
        logoImg: this.tabData.logoImg,
        growName: this.tabData.growName,
        evaluateCycle: this.tabData.evaluateCycle,
        memberRule: this.tabData.memberRule,
        status: this.tabData.status,
        institutionCodes: this.$refs.tree.getCheckedKeys(true),
        levelNames: [],
        levelPatterns: [],
        equitiesDescs: [],
        levelScores: [],
        levelIds: []
      };

      function setVos(cardVos) {
        cardVos.forEach(function (item) {
          obj.levelNames.push(item.name);
          obj.levelPatterns.push(item.image);
          obj.levelScores.push(item.score);
          obj.equitiesDescs.push(item.equitiesDesc);
          obj.levelIds.push(item.id || 0);
        });
      }

      if (!obj.name) {
        this.$message.error('请填写会员卡名称');
        return;
      } else if (!obj.sysName) {
        this.$message.error('请填写体系名称');
        return;
      } else if (!obj.sort) {
        this.$message.error('请填写排序号');
        return;
      } else if (!obj.logoImg) {
        this.$message.error('请上传会员卡LOGO');
        return;
      } else if (!obj.status) {
        this.$message.error('请选择体系状态');
        return;
      } else if (obj.institutionCodes.length < 1) {
        this.$message.error('请选择关联机构');
        return;
      } else if (!obj.growName) {
        this.$message.error('请填写成长数值名称');
        return;
      } else if (!obj.evaluateCycle) {
        this.$message.error('请选择周期评定');
        return;
      } else if (obj.memberRule === '') {
        this.$message.error('请填写会员规则');
        return;
      } else if (this.cardMemberLevelVos.length < 1) {
        this.$message.error('请添加等级');
      } else if (this.cardMemberLevelVos.length > 0) {
        var cardVos = [];
        this.cardMemberLevelVos.forEach(function (data) {
          if (data.name && data.image && data.score >= 0 && data.equitiesDesc && data.equitiesDesc !== '') {
            cardVos.push(data);
          }
        });

        if (cardVos.length < 1) {
          this.$message.error('请完善等级添加');
        } else if (cardVos.length < this.cardMemberLevelVos.length) {
          this.$confirm('你所添加的等级信息没有完善，我们将只保存完善信息的等级, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            setVos(cardVos);
            _this2.fullscreenLoading = true;
            (0, _mem.editCardMember)(obj).then(function (res) {
              _this2.fullscreenLoading = false;

              if (res.ret === 1000) {
                _this2.fullscreenLoading = false;

                _this2.$message({
                  message: '恭喜你，保存成功',
                  type: 'success'
                }); // this.$router.back(-1)

              }
            });
          });
        } else {
          this.$confirm('确认保存会员体系, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            setVos(cardVos);
            _this2.fullscreenLoading = true;
            (0, _mem.editCardMember)(obj).then(function (res) {
              _this2.fullscreenLoading = false;

              if (res.ret === 1000) {
                _this2.fullscreenLoading = false;

                _this2.$message({
                  message: '恭喜你，保存成功',
                  type: 'success'
                }); // this.$router.back(-1)

              }
            });
          });
        }
      }
    },
    setCover: function setCover(index) {
      // 会员卡LOGO
      this.$set(this.tabData, 'logoImg', index);
    },
    init: function init() {
      var _this3 = this;

      (0, _mem.listInstitutionTreeMember)().then(function (res) {
        _this3.allTreeNodes = res.data;
      });
      (0, _mem.getCardMemberById)(this.cardId).then(function (res) {
        _this3.tabData = res.data;
        _this3.cardMemberLevelVos = res.data.cardMemberLevelVos;
        res.data.cardMemberMtVos.forEach(function (item) {
          _this3.checkedArr.push(item.code);
        }); // for (let i = 0; i < res.data.cardMemberMtVos.length; i++) {
        //   if (this.parentLevel.indexOf(res.data.cardMemberMtVos[i].code) === -1) {
        //     this.checkedArr.push(res.data.cardMemberMtVos[i].code)
        //   }
        // }

        if (!_this3.tabData.memberRule) {
          _this3.$set(_this3.tabData, 'memberRule', '');
        }

        if (!_this3.tabData.evaluateCycle) {
          _this3.$set(_this3.tabData, 'evaluateCycle', '');
        }

        _this3.cardMemberLevelVos.forEach(function (item) {
          if (!item.equitiesDesc) {
            item.equitiesDesc = '';
          }
        });

        if (!_this3.tabData.cardMemberLevelVos) {
          _this3.tabData.cardMemberLevelVos = [];
        }
      });
    },
    getEditor: function getEditor() {
      var _this4 = this;

      (0, _mem.listCardMemeberEquities)(this.cardId).then(function (res) {
        if (res.ret === 1000) {
          _this4.data = res.data;
          _this4.tableData = res.data.cardMemeberEquities;
          _this4.cardId = res.data.cardId;
          _this4.sysName = res.data.sysName;
          _this4.name = res.data.name;
          _this4.logoImg = res.data.logoImg;
          _this4.radio = res.data.status;
        }
      });
    }
  }
};
exports.default = _default;