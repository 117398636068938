"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = require("./api.js");

var _api2 = require("../payType/api.js");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WTable: _index.default
  },
  data: function data() {
    return {
      payList: [],
      columns: [{
        slot: 'startPrice',
        label: '起始金额',
        prop: 'startPrice'
      }, {
        slot: 'endPrice',
        label: '截止金额',
        prop: 'endPrice'
      }, {
        slot: 'ratio',
        label: '返利比例',
        prop: 'ratio'
      }, {
        slot: 'operate'
      }],
      storeList: [],
      rules: {
        'rebateParam.name': [{
          required: true,
          message: '请输入返利名称'
        }],
        ratioParamList: [{
          required: true,
          message: '请添加返利方式'
        }],
        'rebateParam.payTypeId': [{
          required: true,
          message: '请选择支付方式'
        }],
        'rebateParam.remark': [{
          required: true,
          message: '请输入备注'
        }],
        'rebateParam.status': [{
          required: true,
          message: '请选择状态'
        }]
      },
      ruleForm: {
        ratioParamList: [],
        rebateParam: {
          name: null,
          payTypeId: null,
          remark: null,
          status: null,
          id: null
        }
      },
      id: null
    };
  },
  created: function created() {
    var _this = this;

    (0, _api2.getPayList)().then(function (res) {
      _this.payList = res.data;
    });
    this.id = this.$route.query.id;

    if (this.id) {
      (0, _api.getCategory)(this.id).then(function (res) {
        _this.ruleForm.rebateParam.name = res.data.rebateVo.name;
        _this.ruleForm.rebateParam.payTypeId = res.data.rebateVo.payTypeId;
        _this.ruleForm.rebateParam.remark = res.data.rebateVo.remark;
        _this.ruleForm.rebateParam.status = res.data.rebateVo.status;
        _this.ruleForm.rebateParam.id = res.data.rebateVo.id;
        _this.ruleForm.ratioParamList = res.data.rebateRatioVoList;
      });
    }
  },
  methods: {
    delList: function delList(index) {
      this.ruleForm.ratioParamList.splice(index, 1);
    },
    addParam: function addParam() {
      this.ruleForm.ratioParamList.push({
        startPrice: null,
        endPrice: null,
        ratio: null
      });
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this2.id) {
            (0, _api.editCategory)(_this2.ruleForm).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addCategory)(_this2.ruleForm).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;