"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _print = _interopRequireDefault(require("@/utils/print.js"));

var _vueQr = _interopRequireDefault(require("vue-qr"));

var _finance = require("@/api/finance.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      sId: null,
      sStoreId: null,
      sStoreIds: [{
        value: '01001011',
        label: '乐品中华老店'
      }, {
        value: '12001',
        label: '荔星名店B座'
      }, {
        value: '12002',
        label: '星力瑞金店'
      }, {
        value: '12003',
        label: '荔星名店A座'
      }, {
        value: '12012',
        label: '星力城贵阳汇金店'
      }, {
        value: '12011',
        label: '荔星中心'
      }, {
        value: '01001001',
        label: '乐品中华超市'
      }, {
        value: '01002001',
        label: '乐品富源超市'
      }, {
        value: '01001002',
        label: '乐品瑞金超市'
      }, {
        value: '02001006',
        label: '乐品便利店友谊路店'
      }, {
        value: '02002007',
        label: '乐品便利店青云路店'
      }, {
        value: '11004',
        label: '贵阳星力广场超市'
      }, {
        value: '01002002',
        label: '乐品望城超市'
      }, {
        value: '01006001',
        label: '乐品白云南路超市'
      }, {
        value: '01001003',
        label: '乐品小石城店'
      }, {
        value: '01001004',
        label: '乐品中天超市'
      }, {
        value: '01011001',
        label: '乐品遵义超市C'
      }, {
        value: '01005001',
        label: '乐品花溪明珠超市'
      }, {
        value: '01001005',
        label: '乐品元隆超市'
      }, {
        value: '01002003',
        label: '乐品裕阳超市'
      }, {
        value: '01007001',
        label: '乐品清镇云岭路店'
      }, {
        value: '11014',
        label: '遵义星力遵义超市A'
      }, {
        value: '11015',
        label: '乐品星小铺'
      }, {
        value: '01001006',
        label: '乐品日月园超市'
      }, {
        value: '01003001',
        label: '乐品金华园超市'
      }, {
        value: '11018',
        label: '贵阳星力汇金IU馆'
      }, {
        value: '01003002',
        label: '乐品美的林城超市'
      }, {
        value: '01003003',
        label: '乐品中铁逸都超市'
      }, {
        value: '01003004',
        label: '乐品会展城超市'
      }, {
        value: '11022',
        label: '遵义星力遵义常青藤超市'
      }, {
        value: '01003005',
        label: '乐品观山湖1号超市'
      }, {
        value: '11024',
        label: '乐品裕华超市'
      }, {
        value: '11025',
        label: '贵阳星力凤凰湾超市'
      }, {
        value: '01003006',
        label: '乐品碧海广场超市'
      }, {
        value: '01001007',
        label: '乐品蔡家街超市'
      }, {
        value: '01005002',
        label: '乐品花溪万宜超市'
      }, {
        value: '01003007',
        label: '乐品恒大金朱东路店'
      }, {
        value: '01001008',
        label: '乐品万科云岩大都会店'
      }, {
        value: '01005003',
        label: '乐品南山高地'
      }, {
        value: '01099009',
        label: '贵阳星力配送中心'
      }, {
        value: '01002004',
        label: '乐品南厂路店'
      }],
      qrcodeData: 'https://mikacrm.xinglico.com/dzfp?storeId=01003007&sId=1oCeFp9NKAYHsE3NtHtHNg',
      goodsList: [{
        goodsName: "271088203273032804(促)国产香蕉 (L)",
        goodsNum: '3.28',
        goodsAmount: '9.98',
        goodsHj: "32.73"
      }, {
        goodsName: "271565404394024448(促)新疆香梨 (L)",
        goodsNum: '2.444',
        goodsAmount: '17.98',
        goodsHj: "43.94"
      }, {
        goodsName: "271078305393038582( 促)火龙果 (L)",
        goodsNum: '3.858',
        goodsAmount: '13.98',
        goodsHj: "53.93"
      }, {
        goodsName: "6907992507385   (促)伊利金典纯牛奶250m1",
        goodsNum: '12',
        goodsAmount: '5.42',
        goodsHj: "65.00"
      }, {
        goodsName: "6923644266318   (促)蒙牛特仑苏纯牛奶250mT*12",
        goodsNum: '1',
        goodsAmount: '45.800',
        goodsHj: "45.80"
      }]
    };
  },
  components: {
    VueQr: _vueQr.default
  },
  mounted: function mounted() {
    var _this = this;

    window.addEventListener('beforeprint', function () {
      // 打印开始时触发
      _this.isLoading = _this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }); //打印开始时隐藏不必要显示的样式
      // this.isBorder = 'none'
    });
    window.addEventListener('afterprint', function () {
      // 打印结束是触发
      _this.isLoading.close(); //打印结束恢复必要显示的样式
      // this.isBorder = '1px solid #000000'

    });
  },
  methods: {
    goInquire: function goInquire() {
      if (this.sId.trim() === '') {
        this.$message.error('请填入订单号/流水号查询');
      } else if (this.sStoreId.trim() === '') {
        this.$message.error('请填入机构编码查询');
      } else {
        var data = {
          sid: this.sId.trim(),
          storeId: this.sStoreId
        };
        (0, _finance.invoiceInfo)(data).then(function (res) {
          console.log(res.data.data.items);
        }).catch(function (res) {});
      }
    },
    //原生的打印
    printAll: function printAll() {
      // const printHTML = document.querySelector('#printBill').innerHTML
      // // 将打印的区域赋值，进行打印 这种会出现多余的空白页
      // window.document.body.innerHTML = printHTML
      // window.print() // 调用window打印方法
      // window.location.reload() // 打印完成后重新加载页面
      var printHTML = document.querySelector('#printBill');
      window.document.body.innerHTML = ""; // 将打印的区域赋值，进行打印  解决上面赋值方法

      window.document.body.appendChild(printHTML);
      window.print(); // 调用window打印方法

      window.location.reload(); // 打印完成后重新加载页面
    },
    //打印
    billPrintClick: function billPrintClick() {
      setTimeout(function () {
        var style = '@page {margin:0 10mm};'; //打印时去掉眉页眉尾

        (0, _print.default)({
          printable: 'printBill',
          // 标签元素id
          type: 'html',
          header: '',
          targetStyles: ['*'],
          scanStyles: true,
          //打印必须加上，不然页面上的css样式无效
          style: style
        });
      }, 100);
    }
  }
};
exports.default = _default;