var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "enterprise" },
    [
      _c(
        "w-table",
        {
          ref: "wTable",
          attrs: {
            pageApi: _vm.api,
            columns: _vm.columns,
            "search-data": _vm.queryParams,
            pageRequestData: { waterType: 1 }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "票据类型",
              align: "center",
              "min-width": 100
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status == 1
                      ? _c("span", { staticStyle: { color: "blue" } }, [
                          _vm._v("蓝票")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.status == 2
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("红票")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "actions",
              label: "操作",
              align: "center",
              "min-width": 100
            },
            slot: "actions",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handDetails(row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    row.status == 1
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认红冲该发票？" },
                            on: {
                              confirm: function($event) {
                                return _vm.handCheck(row)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "danger",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini"
                                },
                                slot: "reference"
                              },
                              [_vm._v("\n              红冲\n            ")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("CheckInvoice", {
        ref: "checkInvoiceRef",
        on: { confirm: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }