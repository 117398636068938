var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex" }, [
      _c(
        "div",
        { staticClass: "template_menu" },
        _vm._l(_vm.dataItem, function(item, index) {
          return _c(
            "div",
            { key: index },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100px", "margin-bottom": "20px" },
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.goAddTemplate(item)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(item.styleName) + "\n          "
                  )
                ]
              )
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "template_style flex" },
        _vm._l(_vm.styleList, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "template_style_item",
              on: {
                click: function($event) {
                  return _vm.giAddTem(item)
                }
              }
            },
            [
              _c("img", {
                staticClass: "template_style_item_cover",
                attrs: { src: _vm.imgSrc + item.thumbnailPath }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "template_style_item_name" }, [
                _vm._v(" " + _vm._s(item.styleName))
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "analog_fixed" }, [
        _c("div", { staticClass: "analog" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "58px" } }),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.templateList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "itemCount",
                  class: {
                    tem_active_box: index === _vm.itemCount,
                    border_active: !item.id
                  },
                  style: {
                    background: item.moduleAttr.bgColor,
                    marginTop: item.moduleAttr.temMargin / 2 + "px",
                    padding: item.moduleAttr.temPadd / 2 + "px  0"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setCount(index, item)
                    }
                  }
                },
                [
                  index === _vm.itemCount
                    ? _c("div", [
                        item.id
                          ? _c("img", {
                              staticClass: "change change1",
                              attrs: { src: require("@/assets/copy.png") },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.copyItem(index)
                                }
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.id
                          ? _c("img", {
                              staticClass: "change change2",
                              attrs: { src: require("@/assets/x.png") },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.removeItem(item, index)
                                }
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        index > 0 && item.id
                          ? _c("img", {
                              staticClass: "change change3",
                              attrs: { src: require("@/assets/up.png") },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.clickUp(index)
                                }
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        index < _vm.templateList.length - 1 && item.id
                          ? _c("img", {
                              staticClass: "change change4",
                              attrs: { src: require("@/assets/down.png") },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.clickDown(index)
                                }
                              }
                            })
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.styleCode === "button"
                    ? _c(
                        "div",
                        {
                          staticClass: "button_box flex",
                          style: { background: item.bgColor }
                        },
                        _vm._l(item.moduleAttr.dataItem, function(
                          row,
                          rowIndex
                        ) {
                          return _c(
                            "div",
                            {
                              key: rowIndex,
                              staticClass: "item flex",
                              class: "button" + item.moduleAttr.rowNum
                            },
                            [
                              _c("img", {
                                staticClass: "img",
                                attrs: { src: _vm.imgSrc + row.dataPath }
                              }),
                              _vm._v(" "),
                              row.dataName
                                ? _c("p", { staticClass: "name" }, [
                                    _vm._v(_vm._s(row.dataName))
                                  ])
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.styleCode.indexOf("rotation") >= 0
                    ? _c(
                        "div",
                        {
                          staticClass: "rotation_box flex",
                          style: { background: item.bgColor }
                        },
                        [
                          _c(
                            "el-carousel",
                            {
                              staticStyle: { margin: "0 auto" },
                              style: {
                                width: item.moduleAttr.width / 2 + "px",
                                height: item.moduleAttr.height / 2 + "px"
                              }
                            },
                            _vm._l(item.moduleAttr.dataItem, function(
                              row,
                              rowIndex
                            ) {
                              return _c("el-carousel-item", { key: rowIndex }, [
                                _c("img", {
                                  staticClass: "img",
                                  style: {
                                    width: item.moduleAttr.width / 2 + "px",
                                    height: item.moduleAttr.height / 2 + "px"
                                  },
                                  attrs: { src: _vm.imgSrc + row.dataPath }
                                })
                              ])
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.itemCount >= 0
          ? _c(
              "div",
              { staticClass: "edit_tem" },
              [
                _c(
                  "el-form",
                  {
                    ref: "tem_ruleForm",
                    attrs: {
                      model: _vm.templateList[_vm.itemCount],
                      rules: _vm.temRules,
                      "label-width": "160px",
                      size: "mini"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "栏目状态：",
                          prop:
                            "templateList[" + _vm.itemCount + "].moduleStatus",
                          rules: [{ required: true, message: "选择栏目状态" }]
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value:
                                _vm.templateList[_vm.itemCount].moduleStatus,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.templateList[_vm.itemCount],
                                  "moduleStatus",
                                  $$v
                                )
                              },
                              expression: "templateList[itemCount].moduleStatus"
                            }
                          },
                          [_vm._v("启用")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            model: {
                              value:
                                _vm.templateList[_vm.itemCount].moduleStatus,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.templateList[_vm.itemCount],
                                  "moduleStatus",
                                  $$v
                                )
                              },
                              expression: "templateList[itemCount].moduleStatus"
                            }
                          },
                          [_vm._v("禁用")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "背景颜色" } },
                      [
                        _c("el-color-picker", {
                          model: {
                            value:
                              _vm.templateList[_vm.itemCount].moduleAttr
                                .bgColor,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.templateList[_vm.itemCount].moduleAttr,
                                "bgColor",
                                $$v
                              )
                            },
                            expression:
                              "templateList[itemCount].moduleAttr.bgColor"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "模板上外边距：" } },
                      [
                        _c("el-input", {
                          model: {
                            value:
                              _vm.templateList[_vm.itemCount].moduleAttr
                                .temMargin,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.templateList[_vm.itemCount].moduleAttr,
                                "temMargin",
                                $$v
                              )
                            },
                            expression:
                              "templateList[itemCount].moduleAttr.temMargin"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "上下内边距：" } },
                      [
                        _c("el-input", {
                          model: {
                            value:
                              _vm.templateList[_vm.itemCount].moduleAttr
                                .temPadd,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.templateList[_vm.itemCount].moduleAttr,
                                "temPadd",
                                $$v
                              )
                            },
                            expression:
                              "templateList[itemCount].moduleAttr.temPadd"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.templateList[_vm.itemCount].styleCode.indexOf(
                      "rotation"
                    ) >= 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "图宽",
                                  prop:
                                    "templateList[" +
                                    _vm.itemCount +
                                    "].moduleAttr.width",
                                  rules: [
                                    { required: true, message: "轮播图宽度" }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "轮播图宽度" },
                                  model: {
                                    value:
                                      _vm.templateList[_vm.itemCount].moduleAttr
                                        .width,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.templateList[_vm.itemCount]
                                          .moduleAttr,
                                        "width",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "templateList[itemCount].moduleAttr.width"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "图宽",
                                  prop: "moduleAttr.height",
                                  rules: [
                                    { required: true, message: "轮播图高度" }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "轮播图高度" },
                                  model: {
                                    value:
                                      _vm.templateList[_vm.itemCount].moduleAttr
                                        .height,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.templateList[_vm.itemCount]
                                          .moduleAttr,
                                        "height",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "templateList[itemCount].moduleAttr.height"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { "max-height": "400px", overflow: "auto" }
                      },
                      _vm._l(
                        _vm.templateList[_vm.itemCount].moduleAttr.dataItem,
                        function(item, index) {
                          return _c("el-form-item", { key: index }, [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c("add-load", {
                                        staticClass: "img_list",
                                        attrs: {
                                          width: 100,
                                          height: 100,
                                          cover: item.dataPath
                                        },
                                        on: {
                                          setCover: function($event) {
                                            return _vm.setCover($event, index)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "图标标题" },
                                        model: {
                                          value: item.dataName,
                                          callback: function($$v) {
                                            _vm.$set(item, "dataName", $$v)
                                          },
                                          expression: "item.dataName"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { placeholder: "链接页面" },
                                          model: {
                                            value: item.linkCode,
                                            callback: function($$v) {
                                              _vm.$set(item, "linkCode", $$v)
                                            },
                                            expression: "item.linkCode"
                                          }
                                        },
                                        _vm._l(_vm.urlList, function(
                                          item,
                                          index
                                        ) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.label,
                                              value: item.value
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        }
                      ),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              plain: "",
                              icon: "el-icon-plus"
                            },
                            on: { click: _vm.adddataItem }
                          },
                          [_vm._v("添加")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", icon: "el-icon-plus" },
                            on: { click: _vm.submitForm }
                          },
                          [_vm._v("保存")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page_title" }, [
      _c("img", {
        staticStyle: { width: "100%", height: "100%" },
        attrs: {
          src:
            "http://xl-cdn.xinglico.com/mika/pro/2021-04-25/42de5b659b25498ba5bb2c2111c087d7.png"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }