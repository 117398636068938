var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    购买记录\n    "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", plain: "", size: "mini" },
                on: {
                  click: function($event) {
                    _vm.isPay = true
                  }
                }
              },
              [_vm._v("生成团购")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { float: "right" },
                attrs: {
                  type: "primary",
                  icon: "el-icon-download",
                  size: "mini",
                  loading: _vm.downloadLoading
                },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("导出Excel")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { inline: "", gutter: 5 } },
        [
          _c(
            "el-form-item",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "订单号" },
                model: {
                  value: _vm.requestData.orderNo,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "orderNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.orderNo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: {
                  size: "mini",
                  placeholder: "购买人手机号",
                  type: "number"
                },
                model: {
                  value: _vm.requestData.buyUtphone,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "buyUtphone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.buyUtphone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: {
                  size: "mini",
                  placeholder: "推广人手机号",
                  type: "number"
                },
                model: {
                  value: _vm.requestData.sysUserMobile,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "sysUserMobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.sysUserMobile"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "订单状态"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.requestData.orderStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "orderStatus", $$v)
                    },
                    expression: "requestData.orderStatus"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "待支付", value: "1" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已完成", value: "2" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已取消", value: "3" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "已退款", value: "4" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "部分退款", value: "6" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "购买类型"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getList("search")
                    }
                  },
                  model: {
                    value: _vm.requestData.orderSaleType,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "orderSaleType", $$v)
                    },
                    expression: "requestData.orderSaleType"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "普通", value: "0" } }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "小程序团购", value: "1" }
                  }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "后台团购", value: "2" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "form_input",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "会员类型"
                  },
                  on: { change: _vm.typeChange },
                  model: {
                    value: _vm.requestData.orderType,
                    callback: function($$v) {
                      _vm.$set(_vm.requestData, "orderType", $$v)
                    },
                    expression: "requestData.orderType"
                  }
                },
                [
                  _c("el-option", {
                    attrs: { label: "MIKA  PLUS", value: "9" }
                  }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "365会员", value: "10" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.requestData.orderType === "10"
            ? _c(
                "el-form-item",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "365类型"
                      },
                      on: {
                        change: function($event) {
                          return _vm.getList("search")
                        }
                      },
                      model: {
                        value: _vm.requestData.facilityCode,
                        callback: function($$v) {
                          _vm.$set(_vm.requestData, "facilityCode", $$v)
                        },
                        expression: "requestData.facilityCode"
                      }
                    },
                    _vm._l(_vm.vipList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.payMemberNameShort,
                          value: item.id
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.requestData.orderType === "9"
            ? _c(
                "el-form-item",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "plus类型"
                      },
                      on: {
                        change: function($event) {
                          return _vm.getList("search")
                        }
                      },
                      model: {
                        value: _vm.requestData.deviceSn,
                        callback: function($$v) {
                          _vm.$set(_vm.requestData, "deviceSn", $$v)
                        },
                        expression: "requestData.deviceSn"
                      }
                    },
                    _vm._l(_vm.plusList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.typeName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { span: 6 } },
            [
              _c("el-date-picker", {
                staticClass: "search_input",
                staticStyle: { "max-width": "100%" },
                attrs: {
                  size: "mini",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "range-separator": "-",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.getList("search")
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "info",
                    size: "mini",
                    icon: "el-icon-refresh"
                  },
                  on: {
                    click: function($event) {
                      return _vm.getList("remove")
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "status",
              label: "状态",
              align: "center",
              width: "80"
            },
            slot: "status",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 1
                      ? _c("span", { staticStyle: { color: "#e6a23c" } }, [
                          _vm._v("待审核")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c("span", { staticStyle: { color: "#67c23a" } }, [
                          _vm._v("已审核")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "amountRealpay",
              label: "购买金额",
              align: "center",
              width: "100"
            },
            slot: "amountRealpay",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.payMode === 2
                      ? _c("span", [
                          _vm._v(
                            "\n          ￥" +
                              _vm._s(scope.row.amountRealpay) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.payMode === 5
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.amountRealpay) +
                              "积分\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.payMode === 1
                      ? _c("span", [
                          _vm._v(
                            "\n          ￥" +
                              _vm._s(scope.row.amountRealpay) +
                              "购物卡\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "orderType",
              label: "订单类型",
              align: "center",
              width: "100"
            },
            slot: "orderType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderType == 9
                      ? _c("span", [_vm._v("MIKA PLUS")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.orderType == 10
                      ? _c("span", [_vm._v("365会员")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "plusType",
              label: "plus类型",
              align: "center",
              width: "100"
            },
            slot: "plusType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderType === 9
                      ? _c(
                          "div",
                          [
                            _vm._l(_vm.plusList, function(item, index) {
                              return [
                                scope.row.deviceSn == item.id
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.typeName) +
                                          "\n            "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "orderSaleType",
              label: "购买类型",
              align: "center",
              width: "100"
            },
            slot: "orderSaleType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderSaleType == 0
                      ? _c("span", [_vm._v("普通")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.orderSaleType == 1
                      ? _c("div", [_c("span", [_vm._v("小程序团购")])])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.orderSaleType == 2
                      ? _c("div", [_c("span", [_vm._v("后台团购")])])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              align: "center",
              fixed: "right",
              width: "180"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "flex_around" }, [
                      (scope.row.orderStatus == 2 ||
                        scope.row.orderStatus == 6) &&
                      _vm.setMenuPerms("vipPlusRefund")
                        ? _c(
                            "span",
                            {
                              staticStyle: { color: "red", cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.refundPlus(scope.row)
                                }
                              }
                            },
                            [_vm._v("退款")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      (scope.row.orderSaleType == 1 ||
                        scope.row.orderSaleType == 2) &&
                      _vm.setMenuPerms("vipWhiteDea") &&
                      scope.row.orderStatus != 4
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#409eff",
                                cursor: "pointer"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.wPage(
                                    "/member/vipWhite?orderNo=" +
                                      scope.row.orderNo
                                  )
                                }
                              }
                            },
                            [_vm._v("团购详情")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.orderType == 10 &&
                      !scope.row.note &&
                      scope.row.orderStatus == 2 &&
                      _vm.setMenuPerms("vipPullnewSys")
                        ? _c(
                            "span",
                            {
                              staticClass: "primary",
                              on: {
                                click: function($event) {
                                  return _vm.goBindSys(scope.row)
                                }
                              }
                            },
                            [_vm._v("绑定拉新员工")]
                          )
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isPay
        ? _c("div", { staticClass: "fixed_center" }, [
            _c(
              "div",
              { staticClass: "pay_box" },
              [
                _c("div", { staticClass: "pay_box_title" }, [_vm._v("提示")]),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      size: "small",
                      "label-width": "120px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "会员类型：", prop: "payMemberTypeId" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "" },
                            model: {
                              value: _vm.ruleForm.payMemberTypeId,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "payMemberTypeId", $$v)
                              },
                              expression: "ruleForm.payMemberTypeId"
                            }
                          },
                          _vm._l(_vm.vipTypeList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.payMemberNameShort,
                                value: item.id
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "数量：", prop: "number" } },
                      [
                        _c("el-input", {
                          staticClass: "form_input",
                          model: {
                            value: _vm.ruleForm.number,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "number",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.number"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "员工账号", prop: "note" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              "remote-method": _vm.remoteMethod,
                              placeholder: "请输入手机号码查询"
                            },
                            model: {
                              value: _vm.ruleForm.note,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "note", $$v)
                              },
                              expression: "ruleForm.note"
                            }
                          },
                          _vm._l(_vm.sysList, function(item) {
                            return _c("el-option", {
                              key: item.userId,
                              attrs: {
                                label: item.username + "(" + item.mobile + ")",
                                value: item.userId
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("ruleForm")
                              }
                            }
                          },
                          [_vm._v("立即生成")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "info" },
                            on: {
                              click: function($event) {
                                _vm.isPay = false
                              }
                            }
                          },
                          [_vm._v("取消")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isRemark
        ? _c("div", { staticClass: "fixed_center" }, [
            _c(
              "div",
              { staticClass: "remark" },
              [
                _c("p", { staticClass: "remark_title" }, [_vm._v("提示")]),
                _vm._v(" "),
                _c(
                  "el-form",
                  { attrs: { size: "mini", "label-width": "100px" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退款备注：" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 1, maxRows: 8 },
                            placeholder: "请输入备注"
                          },
                          model: {
                            value: _vm.remark,
                            callback: function($$v) {
                              _vm.remark =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "remark"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.refundDataItem.payMode == 5
                              ? "退款积分："
                              : "退款金额："
                        }
                      },
                      [
                        _c("el-input-number", {
                          staticClass: "form_input",
                          attrs: {
                            controls: false,
                            min: 0,
                            max:
                              _vm.refundDataItem.amountRealpay -
                              _vm.refundDataItem.refundedAmount,
                            placeholder:
                              _vm.refundDataItem.payMode == 5
                                ? "请输入退款积分："
                                : "请输入退款金额："
                          },
                          model: {
                            value: _vm.refundAmount,
                            callback: function($$v) {
                              _vm.refundAmount = $$v
                            },
                            expression: "refundAmount"
                          }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "form_remark" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.refundDataItem.payMode == 5
                                  ? "支付积分："
                                  : "支付金额："
                              ) +
                              "\n            " +
                              _vm._s(_vm.refundDataItem.amountRealpay) +
                              "," +
                              _vm._s(
                                _vm.refundDataItem.payMode == 5
                                  ? "已退积分："
                                  : "已退金额："
                              ) +
                              _vm._s(_vm.refundDataItem.refundedAmount) +
                              "\n          "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "remark_footer flex_center" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "infor" },
                        on: {
                          click: function($event) {
                            _vm.isRemark = false
                          }
                        }
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: !_vm.remark },
                        on: { click: _vm.popAffrim }
                      },
                      [_vm._v("确认")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("slide", {
        attrs: { "is-card": _vm.isCard, text: "确认退款" },
        on: {
          "update:isCard": function($event) {
            _vm.isCard = $event
          },
          "update:is-card": function($event) {
            _vm.isCard = $event
          },
          click: _vm.goRefundVip
        }
      }),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            "modal-append-to-body": false,
            title: "绑定员工账号",
            visible: _vm.drawer,
            size: 600
          },
          on: {
            "update:visible": function($event) {
              _vm.drawer = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "bindForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.bindForm,
                rules: _vm.bindRules,
                size: "small",
                "label-width": "180px"
              }
            },
            [
              _c("el-form-item", { attrs: { label: "订单号" } }, [
                _vm._v("\n        " + _vm._s(_vm.bindItem.orderNo) + "\n      ")
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "用户手机号" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.bindItem.buyUtphone) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "员工账号", prop: "sysUserId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": _vm.remoteMethod,
                        placeholder: "请输入手机号码查询"
                      },
                      model: {
                        value: _vm.bindForm.sysUserId,
                        callback: function($$v) {
                          _vm.$set(_vm.bindForm, "sysUserId", $$v)
                        },
                        expression: "bindForm.sysUserId"
                      }
                    },
                    _vm._l(_vm.sysList, function(item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: {
                          label: item.username + "(" + item.mobile + ")",
                          value: item.userId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-popconfirm",
                    {
                      attrs: { title: "确认绑定系统用户？" },
                      on: {
                        confirm: function($event) {
                          return _vm.goBindSysUser("bindForm")
                        }
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "primary" },
                          slot: "reference"
                        },
                        [_vm._v("立即绑定")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info" },
                      on: {
                        click: function($event) {
                          _vm.drawer = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.popShow
        ? _c("qrCodePop", {
            attrs: {
              "coupon-u-uid":
                _vm.exportPhone +
                (_vm.isOrderQrcede == "orderNo"
                  ? "订单全二维码"
                  : "订单单个二维码"),
              "qr-code": _vm.qrCode
            },
            on: {
              closePop: function($event) {
                _vm.popShow = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }