"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _api = require("./api.js");

var _api2 = require("../category/api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      storeList: [],
      rules: {
        isRebate: [{
          required: true,
          message: '请选择是否返利'
        }],
        cardCategoryId: [{
          required: true,
          message: '请选择卡类型'
        }],
        cardKindsName: [{
          required: true,
          message: '请输入卡种类名称'
        }],
        cardImageFront: [{
          required: true,
          message: '请上传图片'
        }],
        cardImageBack: [{
          required: true,
          message: '请上传图片'
        }],
        cardLimitPrice: [{
          required: true,
          message: '请输入卡面额'
        }],
        expireTradeYear: [{
          required: true,
          message: '请输入有效期'
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 3) {
              callback(new Error('请输入大于等于3的值'));
            } else {
              callback();
            }
          }
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }]
      },
      ruleForm: {
        remark: null,
        isRebate: null,
        expireTradeYear: null,
        cardLimitPrice: null,
        cardCategoryId: null,
        cardKindsName: null,
        cardImageFront: 'mika/dev/2021-07-14/51a47c63b66e41f7b601e72745fa293e.png',
        cardImageBack: 'mika/dev/2021-07-14/51a47c63b66e41f7b601e72745fa293e.png'
      },
      formSource: false
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;
    this.ruleForm.cardCategoryId = this.$route.query.categoryId ? Number(this.$route.query.categoryId) : null;

    if (this.$route.query.categoryId) {
      this.formSource = true;
    }

    (0, _api2.categoryPage)({
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      _this.storeList = res.data.records;
    });

    if (this.ruleForm.id) {
      (0, _api.getKinds)(this.ruleForm.id).then(function (res) {
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    // 三级地址选择
    regionDataChange: function regionDataChange(e) {
      var arr = this.$refs['state'].getCheckedNodes(false)[0].pathLabels || [];
      this.ruleForm.detailAddress = arr.join('');
    },
    clearForm: function clearForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.ruleForm));

          if (_this2.ruleForm.id) {
            data.id = _this2.ruleForm.id;
            (0, _api.editKinds)(data).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addKinds)(data).then(function (res) {
              if (_this2.formSource) {
                _this2.$router.replace('/card/classList');
              } else {
                _this2.$router.go(-1);
              }

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;