"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default
  },
  data: function data() {
    return {
      /* name: '',
      box1Btn: ["模板1", "模板2", "模板3", "模板4", "模板5"],
      templates: [{
      value: '1',
      label: '首页轮播图710x240'
      }, {
      value: '2',
      label: '首页功能图标'
      }, {
      value: '3',
      label: '首页列表模板1'
      }, {
      value: '4',
      label: '首页列表模板2'
      }, {
      value: '5',
      label: '首页列表模板3'
      }],
      template: '',*/
      times: [{
        value: '1',
        label: '每行3个'
      }, {
        value: '2',
        label: '每行4个'
      }, {
        value: '3',
        label: '每行5个'
      }],
      time: '',
      pageTypes: [{
        value: '1',
        label: '板块页面'
      }, {
        value: '2',
        label: '分类页面 list'
      }, {
        value: '3',
        label: '详情页面 detail'
      }, {
        value: '4',
        label: '外部页面网址'
      }],
      tableData: [{
        cover: '',
        pageType: '',
        tit: '',
        parameter: '',
        sort: 1
      }],
      count: ''
    };
  },
  methods: {
    setCover: function setCover(index) {
      // banner图片
      this.tableData[this.count].cover = index;
    },
    getIndex: function getIndex(scope) {
      // 获取下标
      this.count = scope.$index;
    },
    addList: function addList() {
      // 添加数据
      this.tableData.push({
        cover: '',
        pageType: '',
        tit: '',
        parameter: '',
        sort: this.tableData.length + 1
      });
    },
    editor: function editor(scope) {
      // 删除数据
      console.log(scope);
      this.tableData.splice(scope.$index, 1);
    }
  }
};
exports.default = _default;