"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _Logo = _interopRequireDefault(require("./Logo"));

var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));

var _Link = _interopRequireDefault(require("./Link"));

var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));

var _navbar = _interopRequireDefault(require("./navbar"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var findValues = function findValues(obj, url) {
  var result = [];
  var initIndex = [];
  var tabIndex = 0;
  var activityIndex = 0;

  var search = function search(obj, type) {
    for (var i = 0; i < obj.length; i++) {
      var item = obj[i];

      if (type == 'init') {
        tabIndex = i; //initIndex.push(i)
      }

      if (item.childMenu && item.childMenu.length > 0) {
        search(item.childMenu, i);
      }

      if (item.url == url) {
        activityIndex = tabIndex;
        result.push(item);
        break;
      }
    }
  };

  search(obj, 'init');
  return String(activityIndex);
};

var _default = {
  components: {
    Logo: _Logo.default,
    AppLink: _Link.default,
    navbar: _navbar.default
  },
  data: function data() {
    return {
      // permission_routes: JSON.parse(localStorage.getItem('menus')),
      activeMenuParent: '',
      filterText: ''
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)([// 'permission_routes',
  'permissionRoutes', 'sidebar', 'menuName'])), {}, {
    menuList: function menuList() {
      if (this.filterText) {
        return this.readNodes(this.permissionRoutes);
      } else {
        var menus = localStorage.getItem('menus');

        if (menus) {
          this.filterText = '';
          var tablist = JSON.parse(menus);
          var path = this.$route.meta.activeMenu || this.$route.path;
          var activeIndex = findValues(tablist, path);
          var nowTabNav = tablist[activeIndex];
          return nowTabNav.childMenu;
        } else {
          return this.permissionRoutes;
        } // this.$store.dispatch('user/setMune', nowTabNav.name)
        // return this.getSubMeu(this.permissionRoutes)

      }
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path;
      this.filterText = ''; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      this.$store.dispatch('public/set_active_menu', meta.title);
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  watch: {
    menuName: {
      handler: function handler(nval, oval) {
        this.filterText = '';
      },
      deep: true,
      // 深度监听
      immediate: true //立即执行

    }
  },
  methods: {
    readNodes: function readNodes(nodes) {
      var _this = this;

      var arr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      var filterFn = function filterFn(item) {
        return item.name.indexOf(_this.filterText) >= 0;
      };

      var traverse = function traverse(nodes) {
        nodes.forEach(function (item) {
          if (filterFn(item)) {
            arr.push(item);
          }

          if (item.childMenu.length) {
            traverse(item.childMenu);
          }
        });
      };

      traverse(nodes);
      return arr;
    },
    getSubMeu: function getSubMeu(nodes) {
      var _this2 = this;

      var arr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      var filterFn = function filterFn(item) {
        return item.name.indexOf(_this2.menuName) >= 0;
      };

      var traverse = function traverse(nodes) {
        nodes.forEach(function (item) {
          if (filterFn(item)) {
            if (item.childMenu.length) {
              item.childMenu.forEach(function (item) {
                arr.push(item);
              });
            }
          }
        });
      };

      traverse(nodes);
      return arr;
    }
  }
};
exports.default = _default;