"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '停车场管理',
      list: [{
        tit: '停车场列表',
        txtArr: '<p>停车场信息查询、导出</p>',
        imgPath: require('@/assets/tab/member.png'),
        path: '/parking/parking/list'
      }, {
        tit: '停车记录',
        txtArr: '<p>停车记录信息查询、导出</p>',
        imgPath: require('@/assets/tab/review.png'),
        path: '/parking/parking/log'
      }]
    };
  }
};
exports.default = _default;