"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      columns: [{
        label: '名称',
        prop: 'labelName'
      }, {
        slot: 'andOr',
        label: '类型',
        prop: 'andOr'
      }, {
        slot: 'type',
        label: '规则类型',
        prop: 'type'
      }, {
        slot: 'mobiles',
        label: '手机号码',
        prop: 'mobiles'
      }, {
        label: '简介',
        prop: 'describes',
        align: 'left'
      }, {
        label: '操作人',
        prop: 'createUser',
        width: 50
      }, {
        label: '时间',
        prop: 'createTime'
      }, {
        slot: 'status',
        label: '状态',
        prop: 'status'
      }, {
        slot: 'dea'
      }],
      searchData: [{
        type: 'input',
        placeholder: '标签名称',
        value: 'labelName'
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }],
      SRCPREFIX: this.$store.state.user.imgSrc,
      memberType: {
        /* 1，普通 2 ,365 3,一星 4，二星 5三星*/
        1: '普通',
        2: '365会员',
        3: '一星Lavant',
        4: '二星Lavant',
        5: '三星Lavant'
      }
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/category/label/listUserCategory', {}).then(function (res) {
      var selectList = res.data.map(function (item) {
        return {
          label: item.categoryName,
          value: item.id
        };
      });

      _this.searchData.unshift({
        type: 'radio',
        placeholder: '请选择类目',
        value: 'categoryId',
        select: selectList
      });
    });
  }
};
exports.default = _default;