"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      form: {
        productName: '',
        productCode: ''
      },
      rules: {
        productName: [{
          required: true,
          message: '请输入商品名称',
          trigger: 'blur'
        }],
        productCode: [{
          required: true,
          message: '请输入商品编码',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getDetail();
  },
  methods: {
    getDetail: function getDetail() {
      var _this = this;

      var id = this.$route.query.id;
      if (!id) return;
      (0, _api.detailGoods)(id).then(function (res) {
        _this.form = res.data;
      });
    },
    handleSave: function handleSave() {
      var _this2 = this;

      this.$refs.goodsForm.validate(function (valid) {
        if (!valid) return;
        (0, _api.goodsSave)(_this2.form).then(function () {
          _this2.$message.success('添加成功');

          _this2.handleCancel();
        });
      });
    },
    handleCancel: function handleCancel() {
      this.$router.push({
        path: '/finance/goods'
      });
    }
  }
};
exports.default = _default;