"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

var _couponDeatil = _interopRequireDefault(require("./components/couponDeatil"));

var _list = _interopRequireDefault(require("./components/list"));

//
//
//
//
//
//
var _default = {
  components: {
    list: _list.default,
    couponDeatil: _couponDeatil.default
  },
  data: function data() {
    return {
      currentRole: 'list'
    };
  },
  methods: {
    setTab: function setTab(index) {
      console.log('asdfsadf', index);

      if (index === 1) {
        this.currentRole = 'list';
      } else if (index === 2) {
        this.currentRole = 'couponDeatil';
      }
    }
  }
};
exports.default = _default;