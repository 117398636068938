"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.linksCreate = linksCreate;
exports.linksPage = linksPage;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询小程序页面链接列表
function linksPage(data) {
  return (0, _request.default)({
    url: '/api/wx/short-link/page',
    method: 'post',
    data: data
  });
} // 生成小程序链接


function linksCreate(data) {
  return (0, _request.default)({
    url: '/api/wx/short-link/generateWxShortLink',
    method: 'post',
    data: data
  });
}