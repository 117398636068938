"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: '优惠券统计',
      list: [{
        tit: '优惠券编码统计',
        txtArr: '<p>优惠券编码统计</p>',
        imgPath: require('@/assets/tab/activity/1.png'),
        path: '/report/couponReportNum'
      }, {
        tit: '优惠券订单',
        txtArr: '<p>优惠券订单</p>',
        imgPath: require('@/assets/tab/activity/6.png'),
        path: '/report/couponOrder'
      }, {
        tit: '优惠券转增记录',
        txtArr: '<p>优惠券转增记录</p>',
        imgPath: require('@/assets/tab/activity/2.png'),
        path: '/report/donation'
      }, {
        tit: '茅台活动券',
        txtArr: '<p>茅台活动券</p>',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: '/report/mtReport'
      }, {
        tit: '关注公众号发券记录',
        txtArr: '<p>关注公众号发券记录</p>',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: '/report/followingWeChatReward'
      }, {
        tit: '代金券统计',
        txtArr: '<p>代金券统计</p>',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: '/report/couponReport'
      }, {
        tit: '礼品券统计',
        txtArr: '<p>礼品券统计</p>',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: '/report/giftCouponReport'
      }, {
        tit: '核销助手核销统计',
        txtArr: '<p>核销助手核销统计</p>',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: '/report/posCoupon'
      }, {
        tit: '面对面发券记录',
        txtArr: '<p>面对面发券记录</p>',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: '/report/faceToface'
      }, {
        tit: '优惠券撤回/红冲记录',
        txtArr: '<p>优惠券撤回/红冲记录</p>',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: '/report/couponOpr'
      }, {
        tit: '抖音券核销记录',
        txtArr: '<p>抖音券核销记录</p>',
        imgPath: require('@/assets/tab/activity/3.png'),
        path: '/report/dyCoupon'
      }]
    };
  }
};
exports.default = _default;