"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _manage = require("@/api/manage.js");

var _mem = require("@/api/mem.js");

var _user = require("@/api/user.js");

var _index = _interopRequireDefault(require("@/components/SelectTree/index.vue"));

var _qrCodePop = _interopRequireDefault(require("@/components/qrCodePop"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    AppLink: _Link.default,
    SelectTree: _index.default,
    qrCodePop: _qrCodePop.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      popShow: false,
      qrCode: '',
      couponUUid: '',
      url: '',
      deptId: '',
      defaultKeys: [],
      defaultProps: {
        children: 'childNodes',
        label: function label(a, b) {
          return a.fullName + '   (' + a.code + ')';
        }
      },
      memTree: [],
      roleArr: [],
      tableData: [],
      pageSize: 10,
      pageNo: 1,
      total: 0,
      btn: '查看详情',
      operation: 2,
      obj: {
        status: null,
        username: '',
        deptName: '',
        mobile: '',
        pageNo: 1,
        pageSize: 10,
        deptId: 0,
        roleId: '',
        userId: ''
      }
    };
  },
  destroyed: function destroyed() {
    /* const initInfo = {
               pageNo: this.pageNo,
               pageSize: this.pageSize
             }; */
    this.$store.dispatch('buffer/setUserManageList', this.obj);
  },
  created: function created() {
    var _this = this;

    (0, _mem.listInstitutionTreeMember)().then(function (res) {
      _this.memTree = res.data;
    });
    (0, _manage.listSysRole)().then(function (res) {
      console.log(res);
      _this.roleArr = res.data;
    });

    if (this.$store.state.buffer.userManageList.pageNo) {
      var initInfo = this.$store.state.buffer.userManageList;
      console.log(initInfo);

      if (initInfo.deptId) {
        this.defaultKeys = [initInfo.deptId];
      } else {
        this.defaultKeys = [];
      }
      /* this.pageNo = initInfo.pageNo;
         this.pageSize = initInfo.pageSize; */


      this.obj = initInfo;
    }

    this.init();
  },
  methods: {
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['所属机构', '用户昵称', 'ID', '手机号码', '工号', '状态（1：启用；-1:停用）', '所属角色', '创建时间'];
        var filterVal = ['deptName', 'username', 'userId', 'mobile', 'jobNumber', 'status', 'roleIds', 'createTime'];
        var list = [];
        var data = {};
        data.pageSize = _this2.total;
        data.pageNo = 1;
        (0, _manage.listSysUserPage)(data).then(function (res) {
          list = res.data.items;

          _this2.getHandleDownload(tHeader, filterVal, list, '用户信息');

          _this2.downloadLoading = false;
        }).catch(function (res) {
          _this2.downloadLoading = false;
        });
      });
    },
    deptIdChange: function deptIdChange(e) {
      this.init();
    },
    closePop: function closePop() {
      /* 关闭小程序码弹框 */
      this.popShow = false;
    },
    getCode: function getCode(scope, special) {
      var _this3 = this;

      // 获取小程序码
      this.couponUUid = scope.username;
      var scene = "share_id=".concat(scope.userId, "&type=-1");
      this.openLoading('获取小程序码中');
      (0, _user.couponQRCode)({
        path: 'pages/qrCode/qrCode',
        // special === 'special' ? 'vip/zengKa/zengKa' : 'vip/index',
        // scene: scene
        // width:'2000',
        //  path: "pages/index/index",
        scene: scene
      }).then(function (res) {
        _this3.url = 'pages/qrCode/qrCode?' + scene;

        _this3.openLoading().close();

        _this3.qrCode = res.data;
        _this3.popShow = true;
      }).catch(function (res) {
        _this3.openLoading().close();
      });
    },
    handleClick: function handleClick(data, checked, node) {
      if (checked) {
        this.$refs.treeForm.setCheckedNodes([data]); // console.log(this.obj)
      } else {
        this.obj.deptId = 0;
      } // this.init()

    },
    checkClick: function checkClick(data, checked, halfCheckedNodes, halfCheckedKeys) {
      if (checked) {
        this.obj.deptId = checked.checkedKeys[0];
        this.init();
      }
    },

    /*
      重置密码
      */
    resetPwd: function resetPwd(row) {
      var _this4 = this;

      this.$confirm('确定要重置密码吗？').then(function (_) {
        (0, _user.resetSysUserPwd)({
          userId: row.userId,
          account: row.account
        }).then(function (res) {
          console.log(res);

          _this4.$alert("<div style=\"text-align:center\">\n                            <p style=\"margin:10px 0;font-size:16px\">\n                                \u65B0\u5BC6\u7801\u4E3A\n                            </p>\n                            <p style=\"font-size:24px;font-weight:600;color:red\">\n                            ".concat(res.data, "\n                            </p>\n                          </div>"), '重置成功', {
            dangerouslyUseHTMLString: true
          });
        });
      }).catch(function (res) {
        _this4.$message('已取消重置');
      });
    },
    init: function init(e) {
      var _this5 = this;

      this.openLoading();

      if (e) {
        this.obj.pageNo = e.page;
        this.obj.pageSize = e.limit;
      }
      /* let data = {
           pageNo: this.pageNo,
           pageSize: this.pageSize,
           username:this.obj.username,
           mobile:this.obj.mobile,
           deptName:this.obj.deptName
          } */


      (0, _manage.listSysUserPage)(this.obj).then(function (res) {
        _this5.openLoading().close();

        _this5.total = res.data.totalNum;
        _this5.tableData = res.data.items; // this.tableData=JSON.parse(JSON.stringify(res.data).replace(/childNodes/g,"children"))
      }).catch(function (res) {
        _this5.openLoading().close();
      });
    },
    addDepartment: function addDepartment() {
      // 新增
      this.$router.push('/system/user/userDea');
    },
    setBtn: function setBtn(userId) {
      // 操作按钮执行函数
      this.$router.push('/system/user/userDea?userId=' + userId);
    },
    remove: function remove() {
      // this.$refs.treeForm.setCheckedNodes([])
      this.obj = {
        pageNo: 1,
        pageSize: 10
      };
      this.init();
    }
  }
};
exports.default = _default;