"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _major = require("@/api/major.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      time: '',
      cardList: [{
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣气'
      }, {
        value: 3,
        label: '礼品券'
      }, {
        value: 4,
        label: '体验券'
      }, {
        value: 5,
        label: '外联券'
      }],
      obj: {
        pageNo: 1,
        pageSize: 10,
        uMobile: '',
        uCardNo: '',
        couponCode: '',
        couponType: '',
        startDate: '',
        endDate: ''
      },
      tabTh: [{
        prop: 'couponName',
        label: '优惠券名称',
        width: 100
      }, {
        prop: 'couponCode',
        label: '优惠券编码',
        width: 100
      }, {
        prop: 'couponTypeName',
        label: '优惠券类型',
        width: 50
      }, {
        prop: 'usedTime',
        label: '兑换时间',
        width: 100
      }, {
        prop: 'usedUname',
        label: '核销人',
        width: 50
      }, {
        prop: 'remark',
        label: '备注',
        width: 100
      }]
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    setTab: function setTab(row) {
      this.$router.push('/member/major/dea?id=' + row.id);
    },
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
      }

      if (type == 'remove') {
        this.removeObj(this.obj);
        this.time = '';
      }

      this.obj.startDate = this.time[0] || '';
      this.obj.endDate = this.time[1] || '';
      this.openLoading();
      (0, _major.listCouponRightsPage)(this.obj).then(function (res) {
        _this.openLoading().close();

        _this.list = res.data.items;
        _this.total = res.data.totalNum;
      }).catch(function (err) {
        _this.openLoading().close();
      });
    }
  }
};
exports.default = _default;