"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var _router = _interopRequireDefault(require("@/views/report/scoreCb/router.js"));

/** When your routing table is too long, you can split it into small modules **/
var reportRouter = {
  path: '/report',
  component: _layout.default,
  redirect: '/report/memberReport',
  name: 'Report',
  meta: {
    title: '报表',
    icon: 'nested'
  },
  children: [{
    path: "followingWeChatReward",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/followingWeChatReward'));
      });
    },
    name: 'followingWeChatReward',
    meta: {
      title: '关注公众号奖励统计',
      keepAlive: true,
      api: '/api/wxMember/bindingMemberLogPage',
      activeMenu: '/report/followingWeChatReward'
    }
  }, {
    path: 'couponTab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/couponTab'));
      });
    },
    name: 'couponReportTab',
    meta: {
      title: '优惠券统计',
      keepAlive: true
    }
  }, {
    path: 'couponOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/couponOrder'));
      });
    },
    name: 'couponOrder',
    meta: {
      title: '优惠券订单',
      keepAlive: true
    }
  }, {
    path: 'donation',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/donation.vue'));
      });
    },
    name: 'couponDonation',
    meta: {
      title: '优惠券转赠记录',
      keepAlive: true
    }
  }, {
    path: 'parkOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/parkOrder'));
      });
    },
    name: 'parkOrder',
    meta: {
      title: '停车订单',
      keepAlive: true
    }
  }, {
    path: 'kpi',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/kpi'));
      });
    },
    name: 'kpiReport',
    meta: {
      title: '用户推广指标统计',
      keepAlive: true
    }
  }, {
    path: 'mtReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/mtReport'));
      });
    },
    name: 'MtReport',
    meta: {
      title: '茅台统计',
      keepAlive: true
    }
  }, {
    path: 'memberReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/memberReport'));
      });
    },
    name: 'MemberReport',
    meta: {
      title: '会员统计',
      keepAlive: true
    }
  }, {
    path: 'memberQuery',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/memberQuery'));
      });
    },
    name: 'MemberQuery',
    meta: {
      title: '会员查询',
      keepAlive: true
    }
  }, {
    path: 'cardRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/memberQuery/cardRecord.vue'));
      });
    },
    name: 'cardRecord',
    meta: {
      title: '卡变动明细'
    }
  }, {
    path: 'editPhone',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/editPhone'));
      });
    },
    name: 'EditPhone',
    meta: {
      title: '修改手机号'
    }
  }, {
    path: 'memberEdit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/memberQuery/edit.vue'));
      });
    },
    name: 'MemberEdit',
    meta: {
      title: '修改会员信息'
    }
  }, {
    path: 'couponReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/couponReport'));
      });
    },
    name: 'CouponReport',
    meta: {
      title: '优惠券统计',
      keepAlive: true
    }
  }, {
    path: 'contrast',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/couponReport/contrast.vue'));
      });
    },
    name: 'CouponRecontrast',
    meta: {
      title: '手机号码对比',
      keepAlive: true
    }
  }, {
    path: 'mikaInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/mikaInfo.vue'));
      });
    },
    name: 'mikaInfo',
    meta: {
      title: 'mika埋点统计',
      keepAlive: true,
      isOneRequest: 'no',
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/sys-data/click/page',
      columns: [{
        slot: 'type',
        label: '类型',
        prop: 'type'
      }, {
        slot: "pathName",
        label: '访问路径名称',
        prop: 'path',
        width: 80
      }, {
        label: '访问路径',
        prop: 'path',
        width: 80
      }, {
        slot: "query",
        label: '路径参数',
        prop: 'query',
        width: 80
      }, {
        slot: 'login',
        label: '是否登录',
        prop: 'query'
      }, {
        label: '用户手机号',
        prop: 'mobile',
        width: 60
      }, {
        label: 'UID',
        prop: 'uid',
        width: 60
      }, {
        label: '用户昵称',
        prop: 'nickname',
        width: 60
      }, {
        label: '周期ID',
        prop: 'rid',
        width: 80
      }, {
        label: '事件名称',
        prop: 'name',
        width: 80
      }, {
        slot: "params",
        label: '事件参数',
        prop: 'params',
        width: 80
      }, {
        label: '停留时间(时间戳)',
        prop: 'pageTime',
        width: 80
      }, {
        label: '访问时间',
        prop: 'timestamp',
        width: 80
      }]
    }
  }, {
    path: 'dyCoupon',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/couponReport/dy.vue'));
      });
    },
    name: 'dyCoupon',
    meta: {
      title: '抖音券核销记录',
      keepAlive: true,
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/dyCoupon/queryDyCouponLogPage',
      columns: [{
        label: 'MIKA券编码',
        prop: 'couponId'
      }, {
        label: 'MIKA券名称',
        prop: 'couponName',
        width: 80
      }, {
        label: '核销时间',
        prop: 'createTime',
        width: 80
      }, {
        label: '核销人手机号',
        prop: 'createUserMobile',
        width: 80
      }, {
        label: '核销人',
        prop: 'createUserName'
      }, {
        slot: 'storeName',
        label: '核销门店',
        prop: 'storeName'
      }, {
        label: '发券数量',
        prop: 'num',
        width: 60
      }, {
        label: '用户手机号码',
        prop: 'mobile',
        width: 60
      }, {
        label: '核销路径（1:核销助手，2:昂捷收银机）',
        prop: 'source',
        slot: "source"
      }, {
        label: '核销类型（1:优惠券，2：优惠券券包）',
        prop: 'type',
        slot: "type"
      }, {
        label: '状态（1:已核销，2已撤销）',
        prop: 'status',
        slot: "status"
      }, {
        label: '抖音券订单号',
        prop: 'dyOrderNo',
        slot: "dyConponInfo"
      }, {
        slot: "cancel"
      }]
    }
  }, {
    path: 'couponReportNum',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/couponReport/num.vue'));
      });
    },
    name: 'CouponReportNum',
    meta: {
      title: '优惠券统计',
      keepAlive: true,
      isOneRequest: 'no',
      icon: 'el-icon-s-cooperation',
      pageList: 'records',
      pageTotal: 'total',
      api: '/api/coupon/selectCouponCount',
      columns: [{
        label: '优惠券编码',
        prop: 'couponId',
        width: 80
      }, {
        label: '用户手机号',
        prop: 'mobile',
        width: 80
      }, {
        label: '用户编码',
        prop: 'uid',
        width: 80
      }, {
        label: '用户总数量',
        prop: 'totalNum',
        width: 60
      }, {
        label: '用户可使用数量',
        prop: 'receivedNum',
        width: 60
      }, {
        label: '用户已过期数量',
        prop: 'expiredNum',
        width: 80
      }, {
        label: '用户已使用数量',
        prop: 'usedNum',
        width: 80
      }]
    }
  }, {
    path: 'couponOpr',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/couponOpr.vue'));
      });
    },
    name: 'couponOpr',
    meta: {
      title: '优惠券撤回/红冲记录',
      keepAlive: true,
      isOneRequest: 'no',
      icon: 'el-icon-s-cooperation',
      pageList: 'items',
      pageTotal: 'totalNum',
      api: '/api/couponOprLog/listCouponOprLog',
      columns: [{
        label: '优惠券编码',
        prop: 'couponCode',
        width: 120
      }, {
        label: '券名称',
        prop: 'couponName',
        width: 120
      }, {
        label: '相关订单',
        prop: 'orderNo',
        width: 120
      }, {
        label: '券类型',
        prop: 'couponTypeName',
        width: 50
      }, {
        label: '用户UID',
        prop: 'receiveUid',
        width: 50
      }, {
        label: '操作人',
        prop: 'oprUname',
        width: 50
      }, {
        label: '操作时间',
        prop: 'oprTime',
        width: 100
      }, {
        label: '备注',
        prop: 'oprRemark',
        width: 120,
        align: 'left'
      }]
    }
  }, {
    path: 'giftCouponReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/giftCouponReport'));
      });
    },
    name: 'GiftCouponReport',
    meta: {
      title: '礼品券券统计',
      keepAlive: true
    }
  }, {
    path: 'pullNewReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/pullNewReport'));
      });
    },
    name: 'PullNewReport',
    meta: {
      title: '拉新统计',
      keepAlive: true
    }
  }, {
    path: 'faceToface',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/faceToface'));
      });
    },
    name: 'faceToface',
    meta: {
      title: '面对面发券统计',
      keepAlive: true
    }
  }, {
    path: 'verificationReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/verificationReport'));
      });
    },
    name: 'VerificationReport',
    meta: {
      title: '优惠券核销记录'
    }
  }, {
    path: 'voteReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/voteReport.vue'));
      });
    },
    name: 'VoteReport',
    meta: {
      title: '投票统计'
    }
  }, {
    path: 'posCoupon',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/report/posCoupon.vue'));
      });
    },
    name: 'posCoupon',
    meta: {
      title: '核销助手销券统计',
      keepAlive: true
    }
  }, _router.default]
};
var _default = reportRouter;
exports.default = _default;