"use strict";

var _interopRequireWildcard = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRightIssue = getRightIssue;
exports.getRightLog = getRightLog;
exports.invalidRight = invalidRight;
exports.managerInfo = managerInfo;
exports.ruleList = ruleList;

var _request = _interopRequireDefault(require("@/utils/request"));

var R = _interopRequireWildcard(require("ramda"));

/**
 * 用户权益发放记录
 */
function getRightLog(params) {
  return (0, _request.default)({
    url: '/api/svip/right/issue/log/user/' + params.uId,
    method: 'get',
    params: params
  });
}
/**
 * 用户权益发放记录
 */


function managerInfo(params) {
  return (0, _request.default)({
    url: '/api/svip/detail/' + params.uId,
    method: 'get',
    params: params
  });
}
/**
 * 权益发放
 */


function getRightIssue(params) {
  return (0, _request.default)({
    url: '/api/svip/right/issue',
    method: 'post',
    data: params
  });
}
/**
 * 规则列表
 */


function ruleList(params) {
  return (0, _request.default)({
    url: '/api/svip/right/rule',
    method: 'get',
    params: params
  });
}
/**
 * 权益记录作废
 */


function invalidRight(params) {
  return (0, _request.default)({
    url: '/api/svip/right/issue/log/invalid',
    method: 'put',
    data: params.ids
  });
}