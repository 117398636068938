var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isConfirm,
              expression: "!isConfirm"
            }
          ],
          ref: "item",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.item, rules: _vm.rules, "label-width": "120px" }
        },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("基本信息")
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "存款类型：", prop: "name" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "", size: "small" },
                          model: {
                            value: _vm.item.select,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "select", $$v)
                            },
                            expression: "item.select"
                          }
                        },
                        _vm._l(_vm.cardOptions, function(
                          optionItem,
                          optionIndex
                        ) {
                          return _c("el-option", {
                            key: optionIndex,
                            attrs: {
                              label: optionItem.text,
                              value: optionIndex
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "存款机构：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "存款部门：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "源单据号：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "源单返赠：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "顾客编码：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "顾客姓名：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否新客：", prop: "name" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "", size: "small" },
                          model: {
                            value: _vm.item.select,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "select", $$v)
                            },
                            expression: "item.select"
                          }
                        },
                        _vm._l(_vm.cardOptions, function(
                          optionItem,
                          optionIndex
                        ) {
                          return _c("el-option", {
                            key: optionIndex,
                            attrs: {
                              label: optionItem.text,
                              value: optionIndex
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户银行：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户账户：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户账号：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款票证号：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "总张数：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "总存款额：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款确认时间：", prop: "name" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "",
                          size: "small",
                          clearable: ""
                        },
                        model: {
                          value: _vm.item.date,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "date", $$v)
                          },
                          expression: "item.date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款确认人：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "建议返赠：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "实际返赠：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款情况：", prop: "name" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: { size: "small", placeholder: "" },
                          model: {
                            value: _vm.item.input,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "input", $$v)
                            },
                            expression: "item.input"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "120px" },
                              attrs: {
                                slot: "prepend",
                                placeholder: "付款方式"
                              },
                              slot: "prepend",
                              model: {
                                value: _vm.item.payType,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "payType", $$v)
                                },
                                expression: "item.payType"
                              }
                            },
                            _vm._l(_vm.payOptions, function(payItem, payIndex) {
                              return _c("el-option", {
                                key: payIndex,
                                attrs: {
                                  label: payItem.text,
                                  value: payItem.value
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 20, offset: 1 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "说明：" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "" },
                        model: {
                          value: _vm.item.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "remark", $$v)
                          },
                          expression: "item.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _c("h4", [_vm._v("单据记录")])
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 19, offset: 2 } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableData, border: "" },
                      on: { "cell-mouse-leave": _vm.onChangeCell }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "起始卡号", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: { placeholder: " " },
                                  model: {
                                    value: scope.row.type,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "type", $$v)
                                    },
                                    expression: "scope.row.type"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "终止卡号", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: { placeholder: " " },
                                  model: {
                                    value: scope.row.type,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "type", $$v)
                                    },
                                    expression: "scope.row.type"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "张数",
                          align: "center",
                          width: "120px"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: { placeholder: " " },
                                  model: {
                                    value: scope.row.number,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "number", _vm._n($$v))
                                    },
                                    expression: "scope.row.number"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "每张存款额",
                          align: "center",
                          width: "120px"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: { placeholder: " " },
                                  model: {
                                    value: scope.row.number,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "number", _vm._n($$v))
                                    },
                                    expression: "scope.row.number"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "原总余额",
                          align: "center",
                          width: "120px"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: { placeholder: " " },
                                  model: {
                                    value: scope.row.number,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "number", _vm._n($$v))
                                    },
                                    expression: "scope.row.number"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "存款总额",
                          align: "center",
                          width: "120px"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: { placeholder: " " },
                                  model: {
                                    value: scope.row.number,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "number", _vm._n($$v))
                                    },
                                    expression: "scope.row.number"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "存款后总额",
                          align: "center",
                          width: "120px"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: { placeholder: " " },
                                  model: {
                                    value: scope.row.number,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "number", _vm._n($$v))
                                    },
                                    expression: "scope.row.number"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "备注",
                          align: "center",
                          width: "180px"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: { placeholder: " " },
                                  model: {
                                    value: scope.row.type,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "type", $$v)
                                    },
                                    expression: "scope.row.type"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            align: "center",
                            width: "60",
                            fixed: "right"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "danger",
                                        title: "删除",
                                        circle: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleDelete(
                                            scope.$index,
                                            scope.row
                                          )
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "template",
                            { slot: "header" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    circle: "",
                                    title: "添加"
                                  },
                                  on: { click: _vm.onAddTable }
                                },
                                [_c("i", { staticClass: "el-icon-plus" })]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tableData.length,
                          expression: "tableData.length"
                        }
                      ],
                      staticClass: "sumTabledata-box"
                    },
                    [
                      _c("span", [_vm._v("合计：")]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s("张数：" + _vm.sumTabledata))]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s("原总余额：" + _vm.sumTabledata))
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s("存款总额：" + _vm.sumTabledata))
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s("存款后总额：" + _vm.sumTabledata))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _c("h4", [_vm._v("备注信息")])
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 17, offset: 2 } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      placeholder: "备注..."
                    },
                    model: {
                      value: _vm.item.textarea,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "textarea", $$v)
                      },
                      expression: "item.textarea"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isConfirm,
              expression: "isConfirm"
            }
          ]
        },
        [_c("h1", [_vm._v("详情信息")])]
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.goBack } },
            [_c("i", { staticClass: "el-icon-back" }), _vm._v(" 返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onStep }
            },
            [
              _vm._v(_vm._s(_vm.isConfirm ? "上一步" : "下一步") + " "),
              _c("i", {
                class: [_vm.isConfirm ? "el-icon-top" : "el-icon-bottom"]
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isConfirm,
                  expression: "isConfirm"
                }
              ],
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onSave }
            },
            [
              _c("i", { staticClass: "el-icon-document-checked" }),
              _vm._v(" 保存")
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("addInvoiceRecord", {
        attrs: { "dialog-visible": _vm.dialogVisible },
        on: { onDialogVisible: _vm.onDialogVisible }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }