var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    会员馈赠单确认\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("会员馈赠单")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { "label-width": "120px", size: "small" }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "会员姓名：" } }, [
                        _vm._v(_vm._s(_vm.userInfo.realname))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "手机号码：" } }, [
                        _vm._v(_vm._s(_vm.userInfo.mobile))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "身份证号：" } }, [
                        _vm._v(_vm._s(_vm.userInfo.idNo))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "max-height": "420",
                size: "mini",
                border: "",
                data: _vm.gifts
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  "min-width": "80",
                  prop: "id",
                  label: "礼品ID",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "120",
                  prop: "giftName",
                  label: "礼品名称",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "80",
                  prop: "giftTypeText",
                  label: "礼品类型",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "80",
                  prop: "storeCode",
                  label: "所属门店",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "80",
                  prop: "giftWorth",
                  label: "价值",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "80",
                  prop: "scorePrice",
                  label: "积分价格",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "80",
                  prop: "discountScore",
                  label: "365积分价格",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "80",
                  prop: "stockNumber",
                  label: "库存数",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "80",
                  prop: "exchangeNumber",
                  align: "center",
                  label: "兑换数量"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { "label-width": "120px", size: "small" }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "会员现有积分：" } },
                        [_vm._v(_vm._s(_vm.userInfo.score))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-form-item", { attrs: { label: "备注信息：" } }, [
                        _vm._v(_vm._s(_vm.exchangeRemark))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "本次积分变动：" } },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("-" + _vm._s(_vm.consumeStore))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "操作账号：" } }, [
                        _vm._v(_vm._s(_vm.username))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "变动后余额：" } }, [
                        _vm._v(_vm._s(_vm.userInfo.score - _vm.consumeStore))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.goPresent } },
            [_vm._v("确认发放")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }