"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    var _self = this;

    return {
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.form.startTime).getTime();
          return time.getTime() < validBeginTime || time.getTime() > _self.timeEnd;
        }
      },
      disabled: false,
      popType: '',
      couponShow: false,
      count: 0,
      giftText: {
        1: '积分',
        2: '优惠券',
        3: '购物卡'
      },
      giftInput: {
        1: '积分',
        2: '优惠券数量',
        3: '购物卡金额'
      },
      awardType: [{
        label: '优惠券',
        value: 1
      }, {
        label: '商品',
        value: 2
      }, {
        label: '积分',
        value: 3
      }, {
        label: '乐享值',
        value: 4
      }, {
        label: '谢谢参与',
        value: 5
      }],
      rules: {
        score: [{
          required: true,
          message: '请输入积分'
        }],
        minDays: [{
          required: true,
          message: '请输入最小天数'
        }],
        maxDays: [{
          required: true,
          message: '请输入最大天数'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }]
      },
      form: {
        score: null,
        minDays: null,
        maxDays: null,
        status: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.form.id = this.$route.query.id;
    this.disabled = this.$route.query.edit === 'no';

    if (this.form.id) {
      (0, _api.getData)(this.form.id).then(function (res) {
        if (res.data.rewardType === 1) {
          res.data.rewardContent = null;
        }

        Object.keys(_this.form).forEach(function (item) {
          _this.form[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    changeMinDays: function changeMinDays(e) {
      if (this.form.maxDays < e) {
        this.form.maxDays = e + 1;
      }
    },
    typeChange: function typeChange(e) {
      this.form.rewardContent = null;
      this.form.num = e === 3 ? 1 : null;
    },

    /*
        打开弹框
        */
    getAward: function getAward() {
      var selectType = {
        2: 'coupon',
        3: 'card'
      };
      this.$refs.coupon1.getList(selectType[this.form.rewardType], this.form.rewardContent);
      this.couponShow = true;
    },

    /*
        选择ID后
        */
    setCoupon: function setCoupon(e) {
      this.form.rewardContent = e;
      this.couponShow = false;
    },

    /*
        关闭弹框
        */
    closeCouponPop: function closeCouponPop() {
      this.couponShows = false;
    },

    /*
        删除奖励项
        */
    delAward: function delAward(index) {
      this.form.list.splice(index, 1);
    },

    /*
        选择奖励项
        */
    setAward: function setAward(e, index) {
      this.form.list[index].award = '';

      if (e == 5) {
        this.form.list[index].award = '谢谢参与';
      }
    },

    /*
        添加奖励项
        */
    addAward: function addAward() {
      if (this.form.list.length < 8) {
        this.form.list.push({
          awardType: '',
          award: ''
        });
      }
    },

    /* 分享图片*/
    setShare: function setShare(e) {
      this.form.shareImg = e;
    },

    /* 活动背景*/
    setCover: function setCover(e) {
      this.form.activityBg = e;
    },
    onSubmit: function onSubmit(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this2.form.id) {
            (0, _api.editData)(_this2.form).then(function (res) {
              _this2.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addData)(_this2.form).then(function (res) {
              _this2.$message({
                message: '保存成功',
                type: 'success'
              });

              _this2.$router.go(-1);
            });
          }
        }
      });
    }
  }
};
exports.default = _default;