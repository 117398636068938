var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "promotion" },
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "promotion_input" },
        [
          _c(
            "el-row",
            { staticClass: "el_row", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("活动名称：")
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "maxInput",
                    attrs: {
                      placeholder: "活动名称",
                      type: "text",
                      maxlength: "30"
                    },
                    model: {
                      value: _vm.obj.activityName,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "activityName", $$v)
                      },
                      expression: "obj.activityName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("活动分类：")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "maxInput",
                      attrs: { placeholder: "活动分类" },
                      model: {
                        value: _vm.obj.classId,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "classId", $$v)
                        },
                        expression: "obj.classId"
                      }
                    },
                    _vm._l(_vm.obj.options, function(item) {
                      return _c("el-option", {
                        key: item.classId,
                        attrs: { label: item.name, value: item.classId }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "el_row", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("活动时间：")
                  ]),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    staticClass: "maxInput",
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.obj.time,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "time", $$v)
                      },
                      expression: "obj.time"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("活动地点：")
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "maxInput",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.obj.activityAddress,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "activityAddress", $$v)
                      },
                      expression: "obj.activityAddress"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el_row el_row_div" }, [
            _c("span", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("活动上线时间：")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "el_row_div_item" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: 1 },
                    model: {
                      value: _vm.obj.onlineType,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "onlineType", $$v)
                      },
                      expression: "obj.onlineType"
                    }
                  },
                  [_vm._v("选择上线周期")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  {
                    attrs: { label: 2 },
                    model: {
                      value: _vm.obj.onlineType,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "onlineType", $$v)
                      },
                      expression: "obj.onlineType"
                    }
                  },
                  [_vm._v("立即上线（需要手动下线）")]
                ),
                _vm._v(" "),
                _vm.obj.onlineType === 1
                  ? _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        type: "datetimerange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      model: {
                        value: _vm.obj.beginDate,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "beginDate", $$v)
                        },
                        expression: "obj.beginDate"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "el_row el_row_div" }, [
            _c("span", { staticClass: "promotion_input_li_tit" }, [
              _vm._v("所属会员：")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "el_row_div_item" },
              _vm._l(_vm.obj.memArr, function(item) {
                return _c(
                  "el-radio",
                  {
                    key: item.cardId,
                    staticStyle: { width: "180px" },
                    attrs: { label: item.cardId },
                    model: {
                      value: _vm.obj.memArrShow,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "memArrShow", $$v)
                      },
                      expression: "obj.memArrShow"
                    }
                  },
                  [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                )
              }),
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "el_row", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("排序号：")
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "maxInput",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.obj.activitySort,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "activitySort", $$v)
                      },
                      expression: "obj.activitySort"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("小程序卡片标题：")
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "maxInput",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.obj.shareTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "shareTitle", $$v)
                      },
                      expression: "obj.shareTitle"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "el_row", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("活动详情页图：")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("add-load", {
                        attrs: {
                          width: 375,
                          height: 540,
                          cover: _vm.obj.detailImg
                        },
                        on: { setCover: _vm.setDetail }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "promotion_input_li_txt" }, [
                        _vm._v("图片尺寸：750px * 1080px")
                      ])
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { display: "flex" },
                          attrs: { span: 24 }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "promotion_input_li_tit" },
                            [_vm._v("BANNER图：")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("add-load", {
                                attrs: {
                                  width: 375,
                                  height: 200,
                                  cover: _vm.obj.bannerImg
                                },
                                on: { setCover: _vm.setBanner }
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "promotion_input_li_txt" },
                                [_vm._v("图片尺寸：750px * 400px")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-top": "20px"
                          },
                          attrs: { span: 24 }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "promotion_input_li_tit" },
                            [_vm._v("小程序卡片图：")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("add-load", {
                                attrs: {
                                  width: 375,
                                  height: 298,
                                  cover: _vm.obj.shareImg
                                },
                                on: { setCover: _vm.setShare }
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "promotion_input_li_txt" },
                                [_vm._v("图片尺寸：520px * 416px")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "el_row", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("投票类型：")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.obj.voteType,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "voteType", $$v)
                        },
                        expression: "obj.voteType"
                      }
                    },
                    [_vm._v("普通投票")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.obj.voteType,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "voteType", $$v)
                        },
                        expression: "obj.voteType"
                      }
                    },
                    [_vm._v("报名投票")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("报名时间：")
                  ]),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    attrs: {
                      size: "mini",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.obj.applyTime,
                      callback: function($$v) {
                        _vm.$set(_vm.obj, "applyTime", $$v)
                      },
                      expression: "obj.applyTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "el_row", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 24 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("活动说明：")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { width: 600, height: 200 },
                        model: {
                          value: _vm.obj.activityRemark,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "activityRemark", $$v)
                          },
                          expression: "obj.activityRemark"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "el_row", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 24 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("报名须知：")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { width: 600, height: 200 },
                        model: {
                          value: _vm.obj.applyRemark,
                          callback: function($$v) {
                            _vm.$set(_vm.obj, "applyRemark", $$v)
                          },
                          expression: "obj.applyRemark"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "el_row", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 24 } },
                [
                  _c("span", { staticClass: "promotion_input_li_tit" }, [
                    _vm._v("投票形式：")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.obj.voteMode,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "voteMode", $$v)
                            },
                            expression: "obj.voteMode"
                          }
                        },
                        [_vm._v("每日均可投票")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.obj.voteMode,
                            callback: function($$v) {
                              _vm.$set(_vm.obj, "voteMode", $$v)
                            },
                            expression: "obj.voteMode"
                          }
                        },
                        [_vm._v("一次性投票")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "promotion_input_box" }, [
        _c("div", { staticClass: "promotion_input_item" }, [
          _c("div", { staticClass: "promotion_input_item_tit" }, [
            _vm._v("\n        投票规则设置\n      ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "promotion_input_item_txt" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.obj.voteMode == 1 ? "每日每人可投" : "每人最多可为"
                  ) +
                  "\n        "
              ),
              _c("el-input-number", {
                attrs: { size: "mini" },
                model: {
                  value: _vm.obj.voteNumberDay,
                  callback: function($$v) {
                    _vm.$set(_vm.obj, "voteNumberDay", $$v)
                  },
                  expression: "obj.voteNumberDay"
                }
              }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.obj.voteMode == 1 ? "" : "名选手投票") +
                  " 票\n      "
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "promotion_input_box" },
        [
          _c("div", { staticClass: "promotion_input_item" }, [
            _c(
              "div",
              { staticClass: "promotion_input_item_tit" },
              [
                _vm._v("\n        奖励设置\n\n        "),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.addCouponArr }
                  },
                  [_vm._v("添加奖励")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "promotion_input_item_coupon" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.obj.couponArr, border: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "scope",
                        label: "优惠券",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.couponCode) +
                                  "\n              "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addCoupon(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v("选择优惠券")]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "scope", label: "概率", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-input", {
                                staticStyle: { width: "100px" },
                                attrs: { type: "number", placeholder: "概率" },
                                model: {
                                  value: scope.row.probability,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "probability", $$v)
                                  },
                                  expression: "scope.row.probability"
                                }
                              }),
                              _vm._v("\n              %\n            ")
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "scope",
                        label: "投放数量",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-input", {
                                staticStyle: { width: "100px" },
                                attrs: {
                                  type: "number",
                                  placeholder: "每日投放数量"
                                },
                                model: {
                                  value: scope.row.ratIden,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "ratIden", $$v)
                                  },
                                  expression: "scope.row.ratIden"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "scope",
                        label: "操作",
                        width: "100",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    icon: "el-icon-delete-solid",
                                    size: "mini"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteClick(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.next } },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _vm._v(" "),
      false
        ? _c("div", { staticClass: "promotion_input_box" }, [
            _c("div", { staticClass: "promotion_input_item" }, [
              _c("div", { staticClass: "promotion_input_item_tit" }, [
                _vm._v("\n        投票组设置\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "promotion_input_item_group" }, [
                _c(
                  "div",
                  { staticClass: "promotion_input_item_group_top" },
                  [
                    _vm._v("\n          投票组名称：\n          "),
                    _c("el-input", { staticStyle: { width: "300px" } }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "promotion_input_item_group_top_btn" },
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "mini" } },
                          [_vm._v("新增投票项")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "promotion_input_item_group_table" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.tableData, border: "" }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "scope",
                            label: "序号",
                            width: "100",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          scope.$index >= 10
                                            ? scope.$index + 1
                                            : "0" + (scope.$index + 1)
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            94463550
                          )
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.tabTh, function(item, index) {
                          return _c("el-table-column", {
                            key: index,
                            attrs: {
                              prop: item.activityName,
                              label: item.tit,
                              "min-width": "item.width",
                              align: "center"
                            }
                          })
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "scope",
                            label: "操作",
                            width: "100",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [_c("span", [_vm._v("编辑")])]
                                }
                              }
                            ],
                            null,
                            false,
                            556795276
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("couponPop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon",
        attrs: { type: "radio" },
        on: { setCoupons: _vm.setCoupons, close: _vm.closeCouponPop }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }