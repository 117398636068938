"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      formType: null,
      formData: [{
        label: '分类名称：',
        type: 'input',
        placeholder: '请输入支付通道名称',
        value: 'categoryName'
      }],
      ruleForm: {
        categoryName: null,
        id: null
      },
      rules: {
        categoryName: [{
          required: true,
          message: '请输入分类名称'
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    this.formType = this.$route.query.type;
    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id > 0) {
      this.apiGet('/api/smsTask/getSmsCategoryById/' + this.ruleForm.id).then(function (res) {
        _this.ruleForm = res.data;
      });
    }
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var apiUrl = this.ruleForm.id && this.formType != 'copy' ? '/api/smsTask/editSmsCategory' : '/api/smsTask/addSmsCategory';
      this.apiPost(apiUrl, this.ruleForm).then(function (res) {
        _this2.$router.go(-1);

        _this2.$message({
          type: 'success',
          message: '添加成功!'
        });
      });
    }
  }
};
exports.default = _default;