"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _label = require("@/api/label.js");

var _table = _interopRequireDefault(require("@/components/tableList/table.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    tables: _table.default
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      obj: {
        pageNo: 1,
        pageSize: 10,
        className: ''
      },
      tabTh: [{
        prop: 'classId',
        label: 'ID',
        width: 2
      }, {
        prop: 'className',
        label: '分类名称',
        width: 8
      }]
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    setTab: function setTab(row, index) {
      this.$router.push('/label/content/classAdd?id=' + row.classId);
    },
    init: function init(type) {
      var _this = this;

      if (type == 'search') {
        this.obj.pageNo = 1;
      }

      if (type == 'remove') {
        this.obj.pageNo = 1;
        this.obj.className = '';
      }

      (0, _label.listLabelClassPage)(this.obj).then(function (res) {
        _this.total = res.data.total;
        _this.list = res.data.records;
      });
    },
    goAdd: function goAdd(type) {
      this.$router.push('/label/content/classAdd');
    }
  }
};
exports.default = _default;