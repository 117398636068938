"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/pathTab/index.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    pathTab: _index.default
  },
  data: function data() {
    return {
      title: 'MIKA365',
      list: [{
        tit: '365会员类型列表',
        txtArr: '365会员类型列表查询，导出',
        imgPath: require('@/assets/tab/14.png'),
        path: '/member/class/list'
      }, {
        tit: '365推广统计',
        txtArr: '365推广统计查询，导出',
        imgPath: require('@/assets/tab/14.png'),
        path: '/member/365Report'
      }, {
        tit: '365用户拉新记录',
        txtArr: "365\u62C9\u65B0\u8BB0\u5F55\u67E5\u8BE2\uFF0C\u5BFC\u51FA",
        imgPath: require('@/assets/tab/14.png'),
        path: '/member/365PullNew'
      }, {
        tit: '365会员权益列表',
        txtArr: "365\u4F1A\u5458\u6743\u76CA\u5217\u8868",
        imgPath: require('@/assets/tab/14.png'),
        path: '/member/equity/equityList'
      }, {
        tit: '365用户推广指标统计',
        txtArr: "365\u63A8\u5E7F\u6307\u6807\u7EDF\u8BA1\u67E5\u8BE2\uFF0C\u5BFC\u51FA",
        imgPath: require('@/assets/tab/14.png'),
        path: '/report/kpi'
      }, {
        tit: '硬核补贴',
        txtArr: "365\u63A8\u5E7F\u6307\u6807\u7EDF\u8BA1\u67E5\u8BE2\uFF0C\u5BFC\u51FA",
        imgPath: require('@/assets/tab/flow1.png'),
        path: '/member/hardCoupon/view/tab'
      }, {
        tit: '会员价',
        txtArr: "365\u63A8\u5E7F\u6307\u6807\u7EDF\u8BA1\u67E5\u8BE2\uFF0C\u5BFC\u51FA",
        imgPath: require('@/assets/tab/25.png'),
        path: '/member/price/tab'
      }, {
        tit: '权益解锁',
        txtArr: "365\u63A8\u5E7F\u6307\u6807\u7EDF\u8BA1\u67E5\u8BE2\uFF0C\u5BFC\u51FA",
        imgPath: require('@/assets/tab/4.png'),
        path: '/member/unlock/view/tab'
      }, {
        tit: '会员发券',
        txtArr: "365\u63A8\u5E7F\u6307\u6807\u7EDF\u8BA1\u67E5\u8BE2\uFF0C\u5BFC\u51FA",
        imgPath: require('@/assets/tab/coupon1.png'),
        path: '/member/coupon/list'
      }, {
        tit: '同城生活',
        txtArr: "365\u63A8\u5E7F\u6307\u6807\u7EDF\u8BA1\u67E5\u8BE2\uFF0C\u5BFC\u51FA",
        imgPath: require('@/assets/tab/31.png'),
        path: '/member/vipCityLift/view/tab'
      }, {
        tit: '开卡礼',
        txtArr: "365\u63A8\u5E7F\u6307\u6807\u7EDF\u8BA1\u67E5\u8BE2\uFF0C\u5BFC\u51FA",
        imgPath: require('@/assets/tab/coupon3.png'),
        path: '/member/openGift/openGiftTab'
      }]
    };
  }
};
exports.default = _default;