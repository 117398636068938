"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchPage = batchPage;
exports.list = list;
exports.listEventPage = listEventPage;

var _request = _interopRequireDefault(require("@/utils/request"));

function batchPage(data) {
  return (0, _request.default)({
    url: '/api/make/store/sale/batch/page',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
 查迅列表
 */


function list(data) {
  return (0, _request.default)({
    url: '/app/invoice/list',
    method: 'post',
    data: data
  });
}

function listEventPage(data) {
  return (0, _request.default)({
    url: '/api/triggerEvent/listEventPage',
    method: 'post',
    data: data
  });
}