"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("./index"));

var _list = _interopRequireDefault(require("./list"));

var _edit = _interopRequireDefault(require("./edit"));

var _detail = _interopRequireDefault(require("./detail"));

var router = {
  name: 'deposit-entity-card',
  path: 'deposit-entity-card',
  component: _index.default,
  children: [{
    name: 'depositEntityCardList',
    path: 'list',
    component: _list.default,
    meta: {
      title: '制卡'
    }
  }, {
    name: 'depositEntityCardEdit',
    path: 'edit',
    component: _edit.default,
    meta: {
      title: '制卡-新增'
    }
  }, {
    name: 'depositEntityCardDetail',
    path: 'detail',
    component: _detail.default,
    meta: {
      title: '制卡-详情'
    }
  }]
};
var _default = router;
exports.default = _default;