"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _member = require("@/api/member.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    WTable: _index.default
  },
  props: {
    lavant: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    user: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    status: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      labelStyle: {
        width: 'auto'
      },
      isListByParam: false,
      tableData: [],
      columns: [{
        label: '原手机号',
        prop: 'oldMobile',
        width: 80
      }, {
        label: '新手机号',
        prop: 'newMobile',
        width: 80
      }, {
        label: '工单号',
        prop: 'dingtalkNumber',
        width: 100
      }, {
        label: '操作人',
        prop: 'operator',
        width: 50
      }, {
        label: '修改时间',
        prop: 'createTime',
        width: 100
      }]
    };
  },
  methods: {
    goEditSvip: function goEditSvip() {
      if (this.user.isAuthorized != 2) return;
      (0, _member.closeSVipAuth)({
        uid: this.user.uid
      }).then(function (res) {});
    },
    goEditStatus: function goEditStatus() {
      var _this = this;

      (0, _member.editRebateStatus365)(this.user.uid).then(function (res) {
        if (_this.user.rebateStatus365) {
          _this.user.rebateStatus365 = 0;
        } else {
          _this.user.rebateStatus365 = 1;
        }
      });
    },
    goListByParam: function goListByParam() {
      var _this2 = this;

      (0, _member.listByParam)({
        newMobile: this.user.mobile
      }).then(function (res) {
        _this2.isListByParam = true;
        _this2.tableData = res.data;
      });
    },
    goPhone: function goPhone() {
      this.$router.push('/report/editPhone?uid=' + this.user.uid + '&oldMobile=' + this.user.mobile);
    }
  }
};
exports.default = _default2;