var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "white-list-form" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    " + _vm._s(_vm.$route.meta.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "auto",
            size: "small"
          }
        },
        [
          _c("el-form-item", { attrs: { label: "名单名称", prop: "name" } }, [
            _vm._v("\n      " + _vm._s(_vm.form.name) + "\n    ")
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "名单状态", prop: "status" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.form.status === 1 ? "有效" : "失效") +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "起止日期", prop: "_date" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.form.startDate) +
                "至" +
                _vm._s(_vm.form.endDate) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "已添加白名单" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  "max-height": "400px",
                  border: "1px solid #cccccc",
                  padding: "10px",
                  "border-radius": "10px",
                  overflow: "auto"
                }
              },
              [_vm._v("\n        " + _vm._s(_vm.form._phoneItem) + "\n      ")]
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "备注说明", prop: "remark" } }, [
            _vm._v("\n      " + _vm._s(_vm.form.remark) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "操作类型", prop: "type" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.form.type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type"
                  }
                },
                [_vm._v("导入白名单")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.form.type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type"
                  }
                },
                [_vm._v("移除白名单")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "form_remark" }, [
                _vm._v(
                  "导入白名单为添加白名单手机号；移除白名单为移除白名单内的手机号"
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.type > 0
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.form.type === 1 ? "添加白名单" : "移除白名单",
                    prop: "newPhone"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { "align-items": "center", margin: "10px 0" }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            "line-height": "1"
                          },
                          attrs: {
                            href: _vm.templateDownload.url,
                            download: _vm.templateDownload.name
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "success",
                                icon: "el-icon-download"
                              }
                            },
                            [_vm._v(_vm._s(_vm.templateDownload.btn))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("upload-excel-component", {
                        attrs: {
                          "btn-text":
                            _vm.form.type === 1
                              ? "添加白名单导入"
                              : "移除白名单导入",
                          type: "btn",
                          "on-success": _vm.handleUploadSuccess,
                          "before-upload": _vm.handleBeforeUpload
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 20 },
                      placeholder: "请输入电话名录"
                    },
                    model: {
                      value: _vm.form.newPhone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newPhone", $$v)
                      },
                      expression: "form.newPhone"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "input-tip" }, [
                    _vm._v("多个手机号使用英文逗号“,”隔开")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.type > 0
            ? _c(
                "el-form-item",
                { staticClass: "form-buttons" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleCommit }
                    },
                    [_vm._v("确认")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v("取消")
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }