"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParkMemberType = void 0;
var ParkMemberType = {
  1: '普通会员',
  2: '365会员',
  3: '要客'
};
exports.ParkMemberType = ParkMemberType;