var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "receipt_detail" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    " + _vm._s(_vm.$route.meta.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "info" },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 2 } },
            [
              _c("el-descriptions-item", { attrs: { label: "申请单号" } }, [
                _vm._v(_vm._s(_vm.info.code))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
                _vm._v(_vm._s(_vm.info.phone))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "用户昵称" } }, [
                _vm._v(_vm._s(_vm.info.name))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "会员类型" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.getOptionsValue({
                        value: _vm.info.bigType,
                        options: _vm.memberTypeOptions
                      })
                    ) +
                    "\n        " +
                    _vm._s(_vm.info.smallTypeName)
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.getOptionsValue({
                      value: _vm.info.status,
                      options: _vm.statusOptions
                    })
                  )
                )
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "所属门店" } }, [
                _vm._v(_vm._s(_vm.info.storeName))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "品牌" } }, [
                _vm._v(_vm._s(_vm.info.typeName))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "申请时间" } }, [
                _vm._v(_vm._s(_vm.info.createTime))
              ]),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                { attrs: { label: "备注", span: 2 } },
                [_vm._v(_vm._s(_vm.info.remark))]
              ),
              _vm._v(" "),
              _c("el-descriptions-item", {
                attrs: { label: "小票图片", span: 2 }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "images" },
            _vm._l(_vm.info.imgs, function(img) {
              return _c("el-image", {
                key: img,
                staticClass: "img",
                attrs: {
                  src: img,
                  fit: "cover",
                  "preview-src-list": _vm.info.imgs
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isDetail
        ? _c("div", { staticClass: "check" }, [
            _c(
              "div",
              { staticClass: "check_title" },
              [_c("span", [_vm._v("审核内容")]), _vm._v(" "), _c("el-divider")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "check_content" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      size: "small",
                      "label-width": "120px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "input-width",
                        attrs: { label: "审核方案", prop: "status" }
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.status,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("通过")
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("不通过")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.form.status === 1
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "选择门店", prop: "auditStoreId" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    remote: "",
                                    "reserve-keyword": "",
                                    placeholder: "输入门店名称搜索",
                                    "remote-method": _vm.remoteStoreList,
                                    loading: _vm.storeloading
                                  },
                                  model: {
                                    value: _vm.form.auditStoreId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "auditStoreId", $$v)
                                    },
                                    expression: "form.auditStoreId"
                                  }
                                },
                                _vm._l(_vm.storeOptions, function(so, sindex) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: sindex,
                                      attrs: {
                                        value: so.storeCode,
                                        label: so.fullName
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [_vm._v(_vm._s(so.fullName))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "right",
                                            color: "#8492a6",
                                            "font-size": "13px"
                                          }
                                        },
                                        [_vm._v(_vm._s(so.storeCode))]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "选择品牌", prop: "auditBrandId" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "选择品牌" },
                                  model: {
                                    value: _vm.form.auditBrandId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "auditBrandId", $$v)
                                    },
                                    expression: "form.auditBrandId"
                                  }
                                },
                                _vm._l(_vm.brandOptions, function(brand) {
                                  return _c("el-option", {
                                    key: brand.id,
                                    attrs: {
                                      value: brand.id,
                                      label: brand.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "input-width",
                              attrs: { label: "相关单据", prop: "orderCode" }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "输入相关单据" },
                                model: {
                                  value: _vm.form.orderCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "orderCode", $$v)
                                  },
                                  expression: "form.orderCode"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "订单金额", prop: "auditPrice" }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  placeholder: "输入订单金额"
                                },
                                model: {
                                  value: _vm.form.auditPrice,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "auditPrice", $$v)
                                  },
                                  expression: "form.auditPrice"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "input-tip" }, [
                                _vm._v("元")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { color: "#1890ff" },
                              attrs: { label: "增加积分数" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.getIntegral) +
                                  "分\n          "
                              )
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "input-width",
                        attrs: { label: "审核说明", prop: "auditMsg" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 3 },
                            placeholder: "输入审核说明"
                          },
                          model: {
                            value: _vm.form.auditMsg,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "auditMsg", $$v)
                            },
                            expression: "form.auditMsg"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "form_buttons" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onConfirm }
                          },
                          [_vm._v("确认")]
                        ),
                        _vm._v(" "),
                        _c("el-button", { on: { click: _vm.onCancel } }, [
                          _vm._v("取消")
                        ])
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }