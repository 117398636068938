var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("会员开卡礼详情")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "会员类型：", prop: "vipCode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.ruleForm.vipCode,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "vipCode", $$v)
                        },
                        expression: "ruleForm.vipCode"
                      }
                    },
                    _vm._l(_vm.vipList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.vipName, value: item.vipCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开卡礼分类：", prop: "classId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form_input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.ruleForm.classId,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "classId", $$v)
                        },
                        expression: "ruleForm.classId"
                      }
                    },
                    _vm._l(_vm.classList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.className, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开卡礼信息：", prop: "openGifts" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.getRelevances }
                    },
                    [_vm._v("添加开卡礼")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.ruleForm.openGifts,
                        size: "mini",
                        border: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "奖励类型",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "openGifts." +
                                      scope.$index +
                                      ".productType",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "openGifts." +
                                        scope.$index +
                                        ".productType",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择奖励类型"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          placeholder: "奖励类型"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setAward(
                                              $event,
                                              scope.$index
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.productType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.productType"
                                        }
                                      },
                                      _vm._l(_vm.type, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "关联礼品",
                          "min-width": "200",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "openGifts." +
                                      scope.$index +
                                      ".productCode",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "openGifts." +
                                        scope.$index +
                                        ".productCode",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择礼品"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.getAward(
                                              scope.row.productType,
                                              scope.$index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              scope.row.attrType == 3
                                                ? "选择优惠券"
                                                : "选择商品"
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    scope.row.productCode
                                      ? _c("p", [
                                          _vm._v(_vm._s(scope.row.productCode))
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "礼品名称",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(scope.row.productName || "") +
                                    "\n                "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品数量",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "openGifts." +
                                      scope.$index +
                                      ".putNumber",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "openGifts." +
                                        scope.$index +
                                        ".putNumber",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入奖品数量"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { size: "mini", type: "number" },
                                      model: {
                                        value: scope.row.putNumber,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "putNumber", $$v)
                                        },
                                        expression: "scope.row.putNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品排序",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "openGifts." +
                                      scope.$index +
                                      ".productSort",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "openGifts." +
                                        scope.$index +
                                        ".productSort",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入奖品排序"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { size: "mini", type: "number" },
                                      model: {
                                        value: scope.row.productSort,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "productSort",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.productSort"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "开卡礼商品状态",
                          width: "170",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "openGifts." +
                                      scope.$index +
                                      ".productStatus",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "openGifts." +
                                        scope.$index +
                                        ".productStatus",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择开卡礼商品状态"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 1 },
                                        model: {
                                          value: scope.row.productStatus,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productStatus",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.productStatus"
                                        }
                                      },
                                      [_vm._v("启用")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 0 },
                                        model: {
                                          value: scope.row.productStatus,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productStatus",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.productStatus"
                                        }
                                      },
                                      [_vm._v("停用")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否爆款商品",
                          width: "150",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "openGifts." +
                                      scope.$index +
                                      ".productHot",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "openGifts." +
                                        scope.$index +
                                        ".productHot",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择是否爆款商品"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 1 },
                                        model: {
                                          value: scope.row.productHot,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productHot",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.productHot"
                                        }
                                      },
                                      [_vm._v("是")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 0 },
                                        model: {
                                          value: scope.row.productHot,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productHot",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.productHot"
                                        }
                                      },
                                      [_vm._v("否")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否推荐首页",
                          width: "150",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "openGifts." +
                                      scope.$index +
                                      ".pushIndex",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "openGifts." +
                                        scope.$index +
                                        ".pushIndex",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择是否推荐首页"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 1 },
                                        model: {
                                          value: scope.row.pushIndex,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "pushIndex",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.pushIndex"
                                        }
                                      },
                                      [_vm._v("是")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 0 },
                                        model: {
                                          value: scope.row.pushIndex,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "pushIndex",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.pushIndex"
                                        }
                                      },
                                      [_vm._v("否")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "table_td_btn",
                                    on: {
                                      click: function($event) {
                                        return _vm.delAward(scope.$index)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }