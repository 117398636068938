"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _report = require("@/api/report.js");

var _user = require("@/api/user.js");

var _major = require("@/api/major.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      couponPop: false,
      isSzm: false,
      couponCover: '',
      password: '',
      type: 1,
      userInfo: {
        realname: '',
        status: '',
        mobile: '',
        score: '',
        sex: '',
        birthday: '',
        idNo: '',
        nation: '',
        gradeName: '',
        growthValue: '',
        customerType: '',
        uid: ''
      },
      mobile: '',
      tableData: [],
      presentObj: {
        pageNo: 1,
        pageSize: 10,
        uid: ''
      },
      presentTotal: 0
    };
  },
  methods: {
    getCover: function getCover(row) {
      var _this = this;

      (0, _major.couponCodeImg)({
        uid: this.userInfo.uid,
        receivedCode: row.userReceiveCode
      }).then(function (res) {
        console.log(res);
        _this.couponPop = true;
        _this.couponCover = 'data:image/png;base64,' + res.data.imageBase64;
      });
    },
    goDui: function goDui(row) {
      this.$router.push("/member/major/add?uid=".concat(this.userInfo.uid, "&receivedCode=").concat(row.couponCode, "&username=").concat(this.userInfo.realname, "&phone=").concat(this.userInfo.mobile, "&coupnName=").concat(row.couponName, "&time=").concat(row.validTime, "&userReceiveCode=").concat(row.userReceiveCode));
    },
    goUserPwd: function goUserPwd() {
      var _this2 = this;

      var data = {
        uid: this.userInfo.uid,
        password: this.password
      };
      this.openLoading();
      (0, _user.checkUserPwd)(data).then(function (res) {
        _this2.openLoading().close();

        _this2.isSzm = true;

        _this2.$message({
          message: '验证成功',
          type: 'success'
        });
      }).catch(function (err) {
        _this2.openLoading().close();
      });
    },
    removeData: function removeData() {
      this.tableData = 0;
      this.presentTotal = [];
      this.isSzm = false;
      this.password = '';
      this.removeObj(this.userInfo);
      this.removeObj(this.presentObj);
    },
    getUserInfo: function getUserInfo() {
      var _this3 = this;

      var slef = this;
      var data = {
        mobile: this.mobile,
        type: this.type
      };
      this.removeData();
      this.openLoading();
      (0, _report.handleMidUser)(data).then(function (res) {
        _this3.openLoading().close();

        Object.keys(res.data.user).forEach(function (key) {
          Object.keys(slef.userInfo).forEach(function (row) {
            if (key == row) {
              if (key == 'sex') {
                if (res.data.user[key] == 1) {
                  slef.userInfo[row] = '男';
                }

                if (res.data.user[key] == 2) {
                  slef.userInfo[row] = '女';
                }

                if (res.data.user[key] == 0) {
                  slef.userInfo[row] = '未知';
                }
              } else {
                slef.userInfo[row] = res.data.user[key];
              }
            }
          });
        });
        slef.userInfo.status = res.data.status;
        slef.presentList();
      }).catch(function (err) {
        _this3.openLoading().close();
      });
    },
    presentList: function presentList() {
      var _this4 = this;

      this.presentObj.uid = this.userInfo.uid;
      (0, _major.rightsCoupon)(this.presentObj).then(function (res) {
        _this4.tableData = res.data.items;
        _this4.presentTotal = res.data.totalNum;
      });
    }
  }
};
exports.default = _default;