var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("新增物品")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "departmentDea_input" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "rightName",
                  attrs: { label: "权益名称：", prop: "rightName" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    attrs: { maxlength: "10", "show-word-limit": "" },
                    model: {
                      value: _vm.ruleForm.rightName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "rightName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.rightName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "status",
                  attrs: { label: "权益状态：", prop: "status" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: -1 } }, [_vm._v("禁用")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "rightType",
                  attrs: { label: "权益类型：", prop: "rightType" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.rightType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "rightType", $$v)
                        },
                        expression: "ruleForm.rightType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("主动发放")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("用户领取")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "ruleDesc",
                  attrs: { label: "规则描述：", prop: "ruleDesc" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.ruleDesc,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "ruleDesc",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.ruleDesc"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { ref: "remark", attrs: { label: "备注：", prop: "remark" } },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "remark",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.remark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  ref: "rightDesc",
                  attrs: { label: "权益描述：", prop: "rightDesc" }
                },
                [
                  _c("el-input", {
                    staticClass: "form_input",
                    model: {
                      value: _vm.ruleForm.rightDesc,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "rightDesc",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.rightDesc"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { ref: "image", attrs: { label: "封面图：", prop: "image" } },
                [
                  _c("add-load", {
                    staticClass: "img_list",
                    attrs: {
                      width: 200,
                      height: 100,
                      cover: _vm.ruleForm.image
                    },
                    on: {
                      setCover: function($event) {
                        return _vm.setCover($event, "image")
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物品信息：", prop: "productItemList" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.getRelevances }
                    },
                    [_vm._v("添加物品")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.ruleForm.productItemList,
                        size: "mini",
                        border: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "物品类型",
                          "min-width": "60",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    ref:
                                      "productItemList." +
                                      scope.$index +
                                      ".productType",
                                    attrs: {
                                      "label-width": "0",
                                      prop:
                                        "productItemList." +
                                        scope.$index +
                                        ".productType",
                                      required: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择物品类型"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          placeholder: "物品类型"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setAward(
                                              $event,
                                              scope.$index
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.productType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.productType"
                                        }
                                      },
                                      _vm._l(_vm.type, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "关联物品",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.productType != 4 &&
                                typeof scope.row.productType === "number"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        ref:
                                          "productItemList." +
                                          scope.$index +
                                          ".productCode",
                                        attrs: {
                                          "label-width": "0",
                                          prop:
                                            "productItemList." +
                                            scope.$index +
                                            ".productCode",
                                          required: "",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择物品"
                                            }
                                          ]
                                        }
                                      },
                                      [
                                        typeof scope.row.productType ===
                                        "number"
                                          ? _c("div")
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.productType != 4
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.getAward(
                                                      scope.row.productType,
                                                      scope.$index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      scope.row.attrType == 1
                                                        ? "选择优惠券"
                                                        : "选择商品"
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _c("div", [
                                              _vm._v(
                                                "\n                  赠送积分\n                "
                                              )
                                            ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "物品编码",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    scope.row.productCode
                                      ? _c("p", [
                                          _vm._v(_vm._s(scope.row.productCode))
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "物品名称",
                          "min-width": "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.productName || "") +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "规格", width: "150", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                typeof scope.row.productType === "number"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        ref:
                                          "productItemList." +
                                          scope.$index +
                                          ".productNum",
                                        attrs: {
                                          "label-width": "0",
                                          prop:
                                            "productItemList." +
                                            scope.$index +
                                            ".productNum",
                                          required: "",
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                _vm.tabGiftType[
                                                  scope.row.productType
                                                ].placeholder
                                            }
                                          ]
                                        }
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            attrs: {
                                              placeholder:
                                                _vm.tabGiftType[
                                                  scope.row.productType
                                                ].placeholde,
                                              size: "mini",
                                              type: "number"
                                            },
                                            model: {
                                              value: scope.row.productNum,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "productNum",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.productNum"
                                            }
                                          },
                                          [
                                            typeof scope.row.productType ===
                                            "number"
                                              ? _c(
                                                  "template",
                                                  { slot: "append" },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.tabGiftType[
                                                            scope.row
                                                              .productType
                                                          ].label
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "确认删除该物品？" },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.delAward(scope.$index)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "danger",
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                          size: "mini"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "coupon1",
        attrs: {
          type: _vm.popType,
          "goods-type": "activity",
          "coupon-show": _vm.couponShow
        },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShow = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShow = $event
          },
          setAwardItem: _vm.setCoupon
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }