var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          attrs: {
            "form-data": _vm.formData,
            disabled: _vm.formType == "dea",
            isSubmitForm: _vm.formType != "dea",
            "label-width": "180px",
            "rule-form": _vm.ruleForm,
            rules: _vm.rules
          },
          on: { submitForm: _vm.submitForm }
        },
        [
          _c(
            "div",
            { attrs: { slot: "groupOrderItems" }, slot: "groupOrderItems" },
            [
              _c(
                "el-form-item",
                {
                  ref: "groupOrderItems",
                  attrs: { label: "商品清单", prop: "groupOrderItems" }
                },
                [
                  _vm.formType != "dea"
                    ? _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-plus"
                              },
                              on: { click: _vm.addItems }
                            },
                            [_vm._v("添加一条")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "danger",
                                icon: "el-icon-delete"
                              },
                              on: { click: _vm.clearGoods }
                            },
                            [_vm._v("清空商品")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticStyle: {
                                margin: "0 10px",
                                "line-height": "1"
                              },
                              attrs: {
                                href: _vm.templateDownload.url,
                                download: _vm.templateDownload.name
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-download"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.templateDownload.btn))]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("upload-excel-component", {
                            attrs: {
                              "btn-text": "批量导入",
                              type: "btn",
                              "on-success": _vm.handleUploadSuccess,
                              "before-upload": _vm.handleBeforeUpload
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      key: _vm.isUpdate,
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        size: "mini",
                        data: _vm.ruleForm.groupOrderItems,
                        "max-height": "500px",
                        border: "",
                        "header-cell-class-name": "table_header_cell"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: "center",
                          width: "50"
                        }
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.tableForm, function(item, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: item.label,
                            align: "center",
                            "min-width": item.width
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm.formType === "dea"
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(scope.row[item.prop]) +
                                              "\n              "
                                          )
                                        ])
                                      : _c(
                                          "el-form-item",
                                          {
                                            ref:
                                              "groupOrderItems.'" +
                                              scope.$index +
                                              "." +
                                              item.prop,
                                            refInFor: true,
                                            attrs: {
                                              prop:
                                                "groupOrderItems." +
                                                scope.$index +
                                                "." +
                                                item.prop,
                                              rules: _vm.rules[item.prop]
                                            }
                                          },
                                          [
                                            item.type === "inputNumber"
                                              ? _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    controls: false,
                                                    min: 0
                                                  },
                                                  model: {
                                                    value: scope.row[item.prop],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        item.prop,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[item.prop]"
                                                  }
                                                })
                                              : _c("el-input", {
                                                  attrs: {
                                                    placeholder: item.label
                                                  },
                                                  model: {
                                                    value: scope.row[item.prop],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        item.prop,
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[item.prop]"
                                                  }
                                                })
                                          ],
                                          1
                                        )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      }),
                      _vm._v(" "),
                      _vm.formType != "dea"
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              fixed: "right",
                              width: "80"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-popconfirm",
                                        {
                                          attrs: {
                                            title:
                                              "确认删除商品:" +
                                              scope.row.productName +
                                              "？"
                                          },
                                          on: {
                                            confirm: function($event) {
                                              return _vm.delGoodsItem(
                                                scope.row,
                                                scope.$index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "danger",
                                              attrs: {
                                                slot: "reference",
                                                type: "text",
                                                size: "mini"
                                              },
                                              slot: "reference"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  删除\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              398069733
                            )
                          })
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions",
                    {
                      attrs: {
                        size: "small ",
                        labelStyle: {
                          width: "120px",
                          textAlign: "right"
                        },
                        column: 3,
                        border: ""
                      }
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "销售价合计：" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.goodsAllData.saleAmountAll) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "结算价合计：" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.goodsAllData.settlementAmountAll) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "数量合计：" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.goodsAllData.productNumAll) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }