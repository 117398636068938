"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _api = require("./api.js");

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      action: process.env.VUE_APP_BASE_API + '/api/fileManage/uploadFile',
      storeList: [],
      cardData: {},
      rules: {
        userName: [{
          required: true,
          message: '请输入用户姓名'
        }],
        phone: [{
          required: true,
          message: '请输入手机号码'
        }],
        idCard: [{
          required: true,
          message: '请输入身份证号码'
        }],
        cardNo: [{
          required: true,
          message: '请刷卡查询'
        }],
        newCardNo: [{
          required: true,
          message: '请输入新卡号'
        }],
        attachment: [{
          required: true,
          message: '请上传凭证'
        }],
        remark: [{
          required: true,
          message: '请输入备注'
        }],
        type: [{
          required: true,
          message: '请选择类型'
        }]
      },
      select: 1,
      ruleForm: {
        id: null,
        phone: null,
        userName: null,
        idCard: null,
        cardNo: null,
        newCardNo: null,
        attachment: [],
        remark: null,
        type: 1
      }
    };
  },
  computed: {
    headers: function headers() {
      return {
        // 设置Content-Type类型为multipart/form-data
        'ContentType': 'multipart/form-data',
        // 设置token
        'Token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.ruleForm.cardNo = this.$route.query.id;
    this.ruleForm.id = this.$route.query.storeId;

    if (this.ruleForm.cardNo) {
      this.goCardInfo();
    }
  },
  methods: {
    onSuccess: function onSuccess(res) {
      var _this = this;

      if (res.ret === 1000) {
        this.ruleForm.attachment.push(res.data);
      } else if (res.ret === 6000) {
        this.$confirm(res.msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this.$store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this2 = this;

      this.ruleForm.attachment = [];
      fileList.forEach(function (item) {
        _this2.ruleForm.attachment.push(item.response.data);
      });
    },
    goCardInfo: function goCardInfo() {
      var _this3 = this;

      this.cardData = {
        cardNo: ''
      };
      var data = {};
      data[this.select === 1 ? 'cardNo' : 'magstripeCode'] = this.ruleForm.cardNo;
      (0, _api.getCardInfo)(data).then(function (res) {
        _this3.cardData = res.data ? res.data : {};
        _this3.ruleForm.cardNo = res.data ? res.data.cardNo : '';
        _this3.ruleForm.id = res.data ? res.data.id : '';
      });
    },
    clearForm: function clearForm(ruleForm) {
      this.cardData = {};
      this.$refs[ruleForm].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.ruleForm));
          data.type = 1;
          data.attachment = JSON.stringify(data.attachment);
          (0, _api.cardLoss)(data).then(function (res) {
            _this4.$message({
              type: 'success',
              message: '换新成功!'
            });

            _this4.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;