"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addGame = addGame;
exports.enableGame = enableGame;
exports.getCrowds = getCrowds;
exports.getDayStatistics = getDayStatistics;
exports.getGame = getGame;
exports.getGames = getGames;
exports.getStoreStatistics = getStoreStatistics;
exports.getTotalStatistics = getTotalStatistics;
exports.saveGameReward = saveGameReward;

var _request = _interopRequireDefault(require("@/utils/request"));

// 人群标签
function getCrowds() {
  return (0, _request.default)({
    url: "/api/category/label/listUserCategoryLabel",
    method: 'post',
    data: {}
  });
}

function getGames(data) {
  return (0, _request.default)({
    url: "/api/gameManager/listGameManagerPage",
    method: 'post',
    data: data
  });
}

function getGame(id) {
  return (0, _request.default)({
    url: "/api/gameManager/getGameManagerById",
    method: 'post',
    data: {
      id: id
    }
  });
} // 添加游戏


function addGame(data) {
  if (data.id) {
    return (0, _request.default)({
      url: "/api/gameManager/editGameManager",
      method: 'post',
      data: data
    });
  } else {
    return (0, _request.default)({
      url: "/api/gameManager/addGameManager",
      method: 'post',
      data: data
    });
  }
} // 新增或编辑游戏奖励


function saveGameReward(data) {
  return (0, _request.default)({
    url: "/api/gameReward/addAndUpdateGameReward",
    method: 'post',
    data: data
  });
}

function getTotalStatistics(gameId) {
  return (0, _request.default)({
    url: "/api/gameHits/totalStatistics/".concat(gameId),
    method: 'get'
  });
}

function getStoreStatistics(data) {
  return (0, _request.default)({
    url: "/api/gameHits/storeDateStatistics",
    method: 'post',
    data: data
  });
}

function getDayStatistics(data) {
  return (0, _request.default)({
    url: "/api/gameHits/getStoreDataEveryDay",
    method: 'post',
    data: data
  });
} // 改变游状态


function enableGame(data) {
  return (0, _request.default)({
    url: "/api/gameManager/enableGameManager",
    method: 'post',
    data: data
  });
}