var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-file" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: _vm.url,
            headers: _vm.headers,
            "on-success": _vm.handUploadSuccess,
            "before-upload": _vm.handUploadBefore,
            "show-file-list": false
          }
        },
        [
          _vm.value
            ? _c("el-button", { attrs: { type: "text", size: "small" } }, [
                _vm._v(_vm._s(_vm.fullSrc))
              ])
            : _c(
                "el-button",
                {
                  attrs: { plain: "", icon: "el-icon-upload2", size: "small" }
                },
                [_vm._v("点击上传")]
              ),
          _vm._v(" "),
          _vm.tip
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip"
                },
                [_vm._v(_vm._s(_vm.tip))]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }