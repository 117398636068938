"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.split");

var _api = require("./api.js");

var _api2 = require("@/views/equity/api.js");

var _flowCoupon = require("@/api/flowCoupon.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _payType = require("./payType.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    var _self = this;

    return {
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.startTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      rangeList: [],
      rightsIdList: [],
      payTypeList: _payType.payTypeList,
      memberTypeList: [{
        label: '所有会员',
        value: 2
      }, {
        label: '365会员',
        value: 1
      }, {
        label: '普通会员（不含365会员）',
        value: 0
      }],
      weekList: [{
        label: '启用',
        value: 1
      }, {
        label: '禁用',
        value: 0
      }],
      rules: {
        item: [{
          required: true,
          message: '请添加活动门槛'
        }],
        payType: [{
          required: true,
          message: '请选择支付方式'
        }],
        throwType: [{
          required: true,
          message: '请选择投放方式'
        }],
        title: [{
          required: true,
          message: '请输入昵称'
        }],
        shortTitle: [{
          required: true,
          message: '请输入简称'
        }],
        isEnable: [{
          required: true,
          message: '请选择状态'
        }],
        introduction: [{
          required: true,
          message: '请输入介绍'
        }],
        remark: [{
          required: true,
          message: '请输入描述'
        }],
        throwNumber: [{
          required: true,
          message: '请输入限制'
        }],
        masterPicture: [{
          required: true,
          message: '请上传图片'
        }],
        startTime: [{
          required: true,
          message: '请选择开始时间'
        }],
        endTime: [{
          required: true,
          message: '请选择结束时间'
        }],
        memberType: [{
          required: true,
          message: '请选择会员类型'
        }],
        limitType: [{
          required: true,
          message: '请选择限制类型'
        }],
        number: [{
          required: true,
          message: '请输入限制次数'
        }],
        isMerge: [{
          required: true,
          message: '请选择是否合并流水'
        }],
        scopeId: [{
          required: true,
          message: '请选择活动范围'
        }]
      },
      ruleForm: {
        scopeId: null,
        title: null,
        item: [],
        isEnable: null,
        shortTitle: null,
        masterPicture: 'mika/dev/2022-04-26/65e5ba0c316342ea92dcf855140dd3b3.png',
        startTime: null,
        endTime: null,
        memberType: null,
        payType: [],
        throwType: null,
        throwNumber: null,
        number: null,
        limitType: null,
        isMerge: null,
        introduction: null,
        remark: null
      }
    };
  },
  created: function created() {
    var _this = this;

    this.ruleForm.id = this.$route.query.id;

    if (this.ruleForm.id) {
      (0, _api.getData)({
        id: this.ruleForm.id
      }).then(function (res) {
        res.data.payType = res.data.payType.split(',');
        res.data.item.forEach(function (item) {
          item.rightsId = Number(item.rightsId);
        });
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }

    (0, _flowCoupon.listSendCouponPage)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.rangeList = res.data.items;
    });
    (0, _api2.getEquityList)({
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.rightsIdList = res.data.records;
    });
    /* getGoodsData().then((res) => {
        if (!res.data) return
        this.ruleForm = res.data
      }) */
  },
  methods: {
    delRuleEquity: function delRuleEquity(index) {
      this.ruleForm.item.splice(index, 1);
    },
    addrightsId: function addrightsId() {
      if (this.ruleForm.item.length > 2) return;
      this.ruleForm.item.push({
        money: null,
        rightsId: null
      });
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.ruleForm.item.sort(_this2.compare('money'));

          var data = JSON.parse(JSON.stringify(_this2.ruleForm));
          data.payType = _this2.ruleForm.payType.join(',');

          if (_this2.ruleForm.id) {
            (0, _api.editData)(data).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
            return;
          } else {
            (0, _api.addData)(data).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;