var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h2", { staticClass: "box_tit" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "box_input" }, [
      _c("ul", [
        _c(
          "li",
          [
            _c("p", { staticClass: "input_tit" }, [
              _vm._v("\n          方案名称：\n        ")
            ]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "input1",
              attrs: { placeholder: "请输入内容" },
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "input_tit" }, [
              _vm._v("\n          方案有效期：\n        ")
            ]),
            _vm._v(" "),
            _c("el-date-picker", {
              ref: "picker",
              attrs: {
                "value-format": "yyyy-MM-dd HH:mm:ss",
                type: "datetimerange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期"
              },
              model: {
                value: _vm.time,
                callback: function($$v) {
                  _vm.time = $$v
                },
                expression: "time"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "input_tit" }, [
            _vm._v("\n          触发条件：\n        ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "condition" },
            [
              _c(
                "div",
                { staticClass: "condition_radio" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.radio,
                        callback: function($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio"
                      }
                    },
                    [_vm._v("用户行为")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "3" },
                      model: {
                        value: _vm.radio,
                        callback: function($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio"
                      }
                    },
                    [_vm._v("充值满送")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.radio == 1,
                      expression: "radio==1"
                    }
                  ],
                  attrs: { placeholder: "请选择用户行为" },
                  on: { change: _vm.onUser },
                  model: {
                    value: _vm.user,
                    callback: function($$v) {
                      _vm.user = $$v
                    },
                    expression: "user"
                  }
                },
                _vm._l(_vm.users, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.relevanceName, value: item.id }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.radio == 3,
                      expression: "radio==3"
                    }
                  ],
                  staticClass: "radio_div2"
                },
                [
                  _c("p", { staticClass: "input_txt padd" }, [
                    _vm._v("选择充值满送的储值卡：")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择" },
                      on: { change: _vm.onChange },
                      model: {
                        value: _vm.send,
                        callback: function($$v) {
                          _vm.send = $$v
                        },
                        expression: "send"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        attrs: { slot: "prefix" },
                        slot: "prefix"
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.sends, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: {
                            label: item.relevanceName + item.relevanceValue,
                            value: item.id
                          }
                        })
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radio_div2_footer" },
                    [
                      _c("p", { staticClass: "input_txt" }, [
                        _vm._v(
                          "\n                储值金额大于等于（满）：\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "input3",
                        attrs: { disabled: true },
                        model: {
                          value: _vm.money,
                          callback: function($$v) {
                            _vm.money = $$v
                          },
                          expression: "money"
                        }
                      }),
                      _vm._v("\n              元\n            ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "input_tit" }, [
            _vm._v("\n          投放总数量：\n        ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-input-number", {
                attrs: { min: 0 },
                model: {
                  value: _vm.putNumber,
                  callback: function($$v) {
                    _vm.putNumber = $$v
                  },
                  expression: "putNumber"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "input_txt" }, [
                _vm._v("如填写0，则不限制投放数量，满足触发条件即向用户投放")
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "input_tit" }, [
            _vm._v("\n          获取限制：\n        ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-input-number", {
                attrs: { min: 1 },
                model: {
                  value: _vm.limitNumber,
                  callback: function($$v) {
                    _vm.limitNumber = $$v
                  },
                  expression: "limitNumber"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "input_txt" }, [
                _vm._v("每个用户限制获取数量")
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", { staticClass: "input_tit" }, [
            _vm._v("\n          投放优惠券：\n        ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-input", {
                staticClass: "input2",
                attrs: { type: "textarea", rows: 8, placeholder: "请输入内容" },
                model: {
                  value: _vm.couponIdBags,
                  callback: function($$v) {
                    _vm.couponIdBags = $$v
                  },
                  expression: "couponIdBags"
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "input_txt" }, [
                _vm._v("请填写优惠券/优惠券包的编码，以回车分隔")
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("p", { staticClass: "input_tit" }),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.next } },
              [_vm._v("下一步")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }