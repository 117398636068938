"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      id: '',
      couponLog: {},
      orderItem: [],
      order: {},
      orderDiscount: [],
      // 使用优惠券
      coupon: {}
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.id = Number(this.$route.query.id);
      this.openLoading();
      (0, _coupon.getCouponLogHx)({
        id: this.id
      }).then(function (res) {
        _this.openLoading().close();

        _this.orderItem = res.data.orderItem || {};
        _this.couponLog = res.data.couponLog || {};
        _this.orderDiscount = res.data.orderDiscount;
        _this.coupon = res.data.coupon || {};
        _this.order = res.data.order || {};

        if (_this.coupon.couponType === 1) {
          _this.coupon.couponType = '代金券';
        } else if (_this.coupon.couponType === 2) {
          _this.coupon.couponType = '折扣券';
        } else if (_this.coupon.couponType === 3) {
          _this.coupon.couponType = '礼品券';
        } else if (_this.coupon.couponType === 4) {
          _this.coupon.couponType = '体验券';
        } else if (_this.coupon.couponType === 5) {
          _this.coupon.couponType = '外部优惠券';
        }

        if (_this.coupon.costType === 1) {
          _this.coupon.costType = 'A类券(公司承担营销费用) ';
        } else if (_this.coupon.costType === 2) {
          _this.coupon.costType = '类券(专柜/品牌承担营销费用)';
        } else if (_this.coupon.costType === 3) {
          _this.coupon.costType = 'C类券-集团与商户按比例承担';
        }
      }).catch(function (res) {
        _this.openLoading().close();
      });
    }
  }
};
exports.default = _default;