"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default,
    AppLink: _Link.default
  },
  props: {
    pageNo: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tabTh: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    btnText: {
      type: String,
      default: '查看详情'
    }
  },
  data: function data() {
    return {
      page: '',
      limit: ''
    };
  },
  created: function created() {
    this.page = this.pageNo;
    this.limit = this.pageSize;
  },
  methods: {
    init: function init(e) {
      this.$emit('init', e);
    },
    goPage: function goPage(index, row) {
      this.$emit('setTab', row, index);
    }
  }
};
exports.default = _default2;