"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api");

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      searchData: [{
        type: 'input',
        placeholder: '商品名称',
        value: 'productName'
      }, {
        type: 'input',
        placeholder: '商品编码',
        value: 'productCode'
      }],
      columns: [{
        prop: 'productName',
        label: '商品名称'
      }, {
        prop: 'productCode',
        label: '商品编码'
      }, {
        slot: 'operate'
      }]
    };
  },
  computed: {
    operate: function operate() {
      // if (this.setMenuPerms('finance:goods:delete')) {
      return ['del']; // }
    }
  },
  methods: {
    handleDelete: function handleDelete(row) {
      var _this = this;

      (0, _api.deleteGoods)(row.id).then(function () {
        _this.$message.success('删除成功');

        _this.refresh();
      });
    },
    refresh: function refresh() {
      this.$refs.wTabel.getList();
    }
  }
};
exports.default = _default;