"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addVipSendCoupon = addVipSendCoupon;
exports.editVipSendCoupon = editVipSendCoupon;
exports.editVipSendCouponStatus = editVipSendCouponStatus;
exports.getVipSendCoupon = getVipSendCoupon;
exports.listVipSendCouponPage = listVipSendCouponPage;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
1、会员发券分页：/api/vipSendCoupon/listVipSendCouponPage
{"pageNo":"当前页","pageNo":"页条数","couponCode":"券编码","sendStatus":"发券状态(0：停用 1：启用)","sendType":"发券类型(1：购买触发 2：每周二触发)"}

2、会员发券明细：/api/vipSendCoupon/getVipSendCoupon/{id}

3、新增会员发券：/api/vipSendCoupon/addVipSendCoupon
{"couponCode":"券编码","couponName":"券名称","sendStatus":"发券状态(0：停用 1：启用)","sendNumber":"发券数量","sendType":"发券类型(1：购买触发 2：每周二触发)"}

4、编辑会员发券：/api/vipSendCoupon/editVipSendCoupon
{"id":"id","couponCode":"券编码","couponName":"券名称","sendStatus":"发券状态(0：停用 1：启用)","sendNumber":"发券数量","sendType":"发券类型(1：购买触发 2：每周二触发)"}

5、编辑会员发券状态：/api/vipSendCoupon/editVipSendCouponStatus
{"id":"id","sendStatus":"发券状态(0：停用 1：启用)"}
 */
function editVipSendCouponStatus(data) {
  return (0, _request.default)({
    url: '/api/vipSendCoupon/editVipSendCouponStatus',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function listVipSendCouponPage(data) {
  return (0, _request.default)({
    url: '/api/vipSendCoupon/listVipSendCouponPage',
    method: 'post',
    loading: 'loading',
    data: data
  });
}
/*
 */


function addVipSendCoupon(data) {
  return (0, _request.default)({
    url: '/api/vipSendCoupon/addVipSendCoupon',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function editVipSendCoupon(data) {
  return (0, _request.default)({
    url: '/api/vipSendCoupon/editVipSendCoupon',
    method: 'post',
    loading: 'loading',
    data: data
  });
}

function getVipSendCoupon(data) {
  return (0, _request.default)({
    url: '/api/vipSendCoupon/getVipSendCoupon/' + data,
    method: 'post',
    loading: 'loading'
  });
}