"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.number.constructor");

var _coupon = require("@/api/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      id: '',
      title: '新增定向投放方案',
      checkAll: false,
      checkedCities: [],
      cities: [],
      isIndeterminate: true,
      all: [],
      orientObj: {},
      errData: null
    };
  },
  watch: {},
  created: function created() {
    this.orientObj = JSON.parse(sessionStorage.getItem('orientObj'));
    console.log(this.orientObj);
    this.checkedCities = this.orientObj.labelIds;
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.id = Number(this.$route.query.unid);

      if (this.id) {
        this.title = '编辑定向投放方案';
      }

      this.apiPost('/api/putOrientationLabel/listPutOrientationLabelPage', {
        pageNo: 1,
        pageSize: 20
      }).then(function (res) {
        _this.cities = res.data.records;

        for (var i = 0; i < res.data.records.length; i++) {
          _this.all.push(res.data.records[i].labelId);
        }

        console.log(res);
      });
      return;
      (0, _coupon.listPutOrientationLabel)().then(function (res) {
        _this.cities = res.data;

        for (var i = 0; i < res.data.length; i++) {
          _this.all.push(res.data[i].labelId);
        }
      });
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedCities = val ? this.all : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    next: function next() {
      var _this2 = this;

      this.orientObj.labelIds = this.checkedCities;
      this.errData = null;
      console.log(this.orientObj);

      if (this.id) {
        this.orientObj.id = this.id;
        (0, _coupon.editPutOrientation)(this.orientObj).then(function (res) {
          _this2.$message({
            message: '恭喜你，编辑成功',
            type: 'success'
          });

          _this2.$router.push('/coupon/couponPut/putDea?id=orient&unid=' + _this2.id);
        });
      } else {
        (0, _coupon.addPutOrientation)(this.orientObj).then(function (res) {
          if (res.ret === 1000) {
            _this2.$message({
              message: '恭喜你，投放成功',
              type: 'success'
            });

            _this2.$router.push('/coupon/couponPut/orientPut');
          } else {
            _this2.$message.error('错了哦，投放失败');
          }
        }).catch(function (err) {
          var errArr = err.msg.split('以下手机号未注册系统：');
          console.log(errArr);

          if (errArr.length == 2) {
            console.log(JSON.parse(errArr[1]));
            _this2.errData = JSON.parse(errArr[1]);
          } else {
            _this2.errData = err.msg;
          }
        });
      }
    }
  }
};
exports.default = _default;