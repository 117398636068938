var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n    " + _vm._s(_vm.$route.meta.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "el-form",
            {
              ref: "settingForm",
              attrs: {
                model: _vm.settingForm,
                "label-width": "180px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "name",
                    label: "游戏名称:",
                    rules: _vm.settingRules.name
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "请输入游戏名称" },
                    model: {
                      value: _vm.settingForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.settingForm, "name", $$v)
                      },
                      expression: "settingForm.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "cover",
                    label: "主图:",
                    rules: _vm.settingRules.cover
                  }
                },
                [
                  _c("upload-image", {
                    model: {
                      value: _vm.settingForm.cover,
                      callback: function($$v) {
                        _vm.$set(_vm.settingForm, "cover", $$v)
                      },
                      expression: "settingForm.cover"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "status",
                    label: "状态:",
                    rules: _vm.settingRules.cover
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.settingForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.settingForm, "status", $$v)
                        },
                        expression: "settingForm.status"
                      }
                    },
                    _vm._l(_vm.statusOptions, function(state) {
                      return _c(
                        "el-radio",
                        { key: state.value, attrs: { label: state.value } },
                        [_vm._v(_vm._s(state.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "type",
                    label: "游戏类型:",
                    rules: _vm.settingRules.type
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择游戏类型" },
                      model: {
                        value: _vm.settingForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.settingForm, "type", $$v)
                        },
                        expression: "settingForm.type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(typeItem) {
                      return _c("el-option", {
                        key: typeItem.value,
                        attrs: { label: typeItem.label, value: typeItem.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "storeIds",
                    label: "开放门店:",
                    rules: _vm.settingRules.storeIds
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "360px" },
                      attrs: {
                        placeholder: "请选择开放门店",
                        filterable: "",
                        multiple: ""
                      },
                      model: {
                        value: _vm.settingForm.storeIds,
                        callback: function($$v) {
                          _vm.$set(_vm.settingForm, "storeIds", $$v)
                        },
                        expression: "settingForm.storeIds"
                      }
                    },
                    _vm._l(_vm.storeOptions, function(store) {
                      return _c(
                        "el-option",
                        {
                          key: store.storeId,
                          attrs: {
                            label: store.shortName,
                            value: store.storeId
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(store.shortName) +
                              _vm._s(store.storeCode) +
                              "\n        "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "times", label: "开放时间:" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: ""
                    },
                    model: {
                      value: _vm.settingForm.times,
                      callback: function($$v) {
                        _vm.$set(_vm.settingForm, "times", $$v)
                      },
                      expression: "settingForm.times"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "music", label: "背景音乐:" } },
                [
                  _c("upload-file", {
                    attrs: { size: 10, tip: "支持扩展名 .MP3 .WMA .WAV" },
                    model: {
                      value: _vm.settingForm.music,
                      callback: function($$v) {
                        _vm.$set(_vm.settingForm, "music", $$v)
                      },
                      expression: "settingForm.music"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "remark", label: "备注:" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 3 },
                      placeholder: "请输入备注"
                    },
                    model: {
                      value: _vm.settingForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.settingForm, "remark", $$v)
                      },
                      expression: "settingForm.remark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "group-title" }, [_vm._v("游戏信息")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "game-levels" },
                [
                  _vm.settingForm.type == 1
                    ? _vm._l(_vm.settingForm.levels, function(
                        level,
                        levelIndex
                      ) {
                        return _c(
                          "div",
                          { key: levelIndex, staticClass: "ping-tu" },
                          [
                            _c("el-form-item", [
                              _c(
                                "div",
                                { staticClass: "level-name" },
                                [
                                  _c("span", [
                                    _vm._v("第" + _vm._s(levelIndex + 1) + "关")
                                  ]),
                                  _vm._v(" "),
                                  levelIndex > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "red" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.handRowChange(
                                                2,
                                                levelIndex
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                删除关卡\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "levels[" + levelIndex + "].image",
                                  label: "关卡图片:",
                                  rules: _vm.settingRules.puzzleLevelImage
                                }
                              },
                              [
                                _c("upload-image", {
                                  model: {
                                    value: level.image,
                                    callback: function($$v) {
                                      _vm.$set(level, "image", $$v)
                                    },
                                    expression: "level.image"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "levels[" + levelIndex + "].num",
                                  label: "切片数量:",
                                  rules: _vm.settingRules.puzzleLevelNum
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "120px" },
                                    attrs: { placeholder: "切片数量" },
                                    model: {
                                      value: level.num,
                                      callback: function($$v) {
                                        _vm.$set(level, "num", $$v)
                                      },
                                      expression: "level.num"
                                    }
                                  },
                                  _vm._l(_vm.sliceNumOptions, function(
                                    sNum,
                                    sNumIndex
                                  ) {
                                    return _c("el-option", {
                                      key: sNumIndex,
                                      attrs: {
                                        value: sNum.value,
                                        label: sNum.label
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "levels[" + levelIndex + "].score",
                                  label: "通关分数:",
                                  rules: _vm.settingRules.puzzleLevelScore
                                }
                              },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    controls: false,
                                    min: 1,
                                    placeholder: "分数"
                                  },
                                  model: {
                                    value: level.score,
                                    callback: function($$v) {
                                      _vm.$set(level, "score", $$v)
                                    },
                                    expression: "level.score"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-plus",
                                      plain: "",
                                      round: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handRowChange(1)
                                      }
                                    }
                                  },
                                  [_vm._v("\n              添加关卡")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.settingForm.type == 2
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "images",
                              label: "图片元素",
                              rules: _vm.settingRules.images
                            }
                          },
                          [
                            _c("upload-images", {
                              model: {
                                value: _vm.settingForm.images,
                                callback: function($$v) {
                                  _vm.$set(_vm.settingForm, "images", $$v)
                                },
                                expression: "settingForm.images"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.settingForm.levels, function(
                          level,
                          levelIndex
                        ) {
                          return _c(
                            "div",
                            { key: levelIndex, staticClass: "ping-tu" },
                            [
                              _c("el-form-item", [
                                _c(
                                  "div",
                                  { staticClass: "level-name" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "第" + _vm._s(levelIndex + 1) + "关"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    levelIndex > 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: { color: "red" },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.handRowChange(
                                                  2,
                                                  levelIndex
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                删除关卡\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "levels[" + levelIndex + "].num",
                                    label: "元素数量:",
                                    rules: _vm.settingRules.puzzleXingLevelNum
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "120px" },
                                    attrs: {
                                      controls: false,
                                      min: 6,
                                      step: 6,
                                      placeholder: "元素数量"
                                    },
                                    model: {
                                      value: level.num,
                                      callback: function($$v) {
                                        _vm.$set(level, "num", $$v)
                                      },
                                      expression: "level.num"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "levels[" + levelIndex + "].score",
                                    label: "通关分数:",
                                    rules: _vm.settingRules.puzzleLevelScore
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "120px" },
                                    attrs: {
                                      controls: false,
                                      min: 1,
                                      placeholder: "分数"
                                    },
                                    model: {
                                      value: level.score,
                                      callback: function($$v) {
                                        _vm.$set(level, "score", $$v)
                                      },
                                      expression: "level.score"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-plus",
                                  plain: "",
                                  round: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handRowChange(1)
                                  }
                                }
                              },
                              [_vm._v("\n            添加关卡")]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "group-title" }, [_vm._v("参与规则")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "cycle",
                    label: "次数更新周期:",
                    rules: _vm.settingRules.cycle
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.settingForm.cycle,
                        callback: function($$v) {
                          _vm.$set(_vm.settingForm, "cycle", $$v)
                        },
                        expression: "settingForm.cycle"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("按天")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2, disabled: "" } }, [
                        _vm._v("按周")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3, disabled: "" } }, [
                        _vm._v("按月")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticStyle: {
                  "max-width": "800px",
                  "border-bottom": "1px dashed #ccc",
                  margin: "-10px 0 20px"
                }
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "basicScore",
                    label: "基础计费规则:",
                    rules: _vm.settingRules.basicScore
                  }
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "150px" },
                    attrs: { controls: false, placeholder: "基础计费规则" },
                    model: {
                      value: _vm.settingForm.basicScore,
                      callback: function($$v) {
                        _vm.$set(_vm.settingForm, "basicScore", $$v)
                      },
                      expression: "settingForm.basicScore"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("积分/次")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "crowd",
                    label: "参与人群类型:",
                    rules: _vm.settingRules.crowd
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "population-type" },
                    _vm._l(_vm.settingForm.crowd, function(crowd, crowdIndex) {
                      return _c(
                        "div",
                        { key: crowdIndex, staticClass: "population-type-row" },
                        [
                          _c(
                            "div",
                            { staticClass: "population-type-col" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "crowd[" + crowdIndex + "].groupId",
                                    rules: _vm.settingRules.crowdGroupId
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "人群名称~",
                                        "value-key": "id",
                                        clearable: ""
                                      },
                                      model: {
                                        value: crowd.groupId,
                                        callback: function($$v) {
                                          _vm.$set(crowd, "groupId", $$v)
                                        },
                                        expression: "crowd.groupId"
                                      }
                                    },
                                    _vm._l(_vm.crowdOptions, function(crowd) {
                                      return _c("el-option", {
                                        key: crowd.id,
                                        attrs: {
                                          label: crowd.labelName,
                                          value: crowd.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "population-type-col" },
                            [
                              _c("span", [_vm._v("权重:")]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "crowd[" + crowdIndex + "].index",
                                    rules: _vm.settingRules.crowdIndex
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "80px" },
                                    attrs: {
                                      controls: false,
                                      min: 1,
                                      max: 10,
                                      placeholder: "1~10"
                                    },
                                    model: {
                                      value: crowd.index,
                                      callback: function($$v) {
                                        _vm.$set(crowd, "index", $$v)
                                      },
                                      expression: "crowd.index"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "population-type-col" },
                            [
                              _c("span", [_vm._v("免费次数:")]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "crowd[" + crowdIndex + "].num",
                                    rules: _vm.settingRules.crowdNum
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "130px" },
                                    attrs: {
                                      controls: false,
                                      min: 0,
                                      placeholder: "免费次数"
                                    },
                                    model: {
                                      value: crowd.num,
                                      callback: function($$v) {
                                        _vm.$set(crowd, "num", $$v)
                                      },
                                      expression: "crowd.num"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "population-type-col" },
                            [
                              _c("span", [_vm._v("超出计费:")]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "crowd[" + crowdIndex + "].price"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      min: 1,
                                      controls: false,
                                      placeholder: "超出计费"
                                    },
                                    model: {
                                      value: crowd.price,
                                      callback: function($$v) {
                                        _vm.$set(crowd, "price", $$v)
                                      },
                                      expression: "crowd.price"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v("积分/次")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "population-type-col" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { color: "red" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handRowChange(4, crowdIndex)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", plain: "", round: "" },
                      on: {
                        click: function($event) {
                          return _vm.handRowChange(3)
                        }
                      }
                    },
                    [_vm._v("\n        添加规则")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "dayNum",
                    label: "每日游戏上限:",
                    rules: _vm.settingRules.dayNum
                  }
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "150px" },
                    attrs: { controls: false, placeholder: "每日游戏上限" },
                    model: {
                      value: _vm.settingForm.dayNum,
                      callback: function($$v) {
                        _vm.$set(_vm.settingForm, "dayNum", $$v)
                      },
                      expression: "settingForm.dayNum"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("次/天")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { color: "#757575", "font-size": "13px" } },
                    [
                      _vm._v(
                        "\n        每周期参与游戏次数上限，防止用户沉迷\n      "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "group-title" }, [_vm._v("规则介绍")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "content",
                    label: "规则内容:",
                    rules: _vm.settingRules.content
                  }
                },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { width: 750, height: 500 },
                    model: {
                      value: _vm.settingForm.content,
                      callback: function($$v) {
                        _vm.$set(_vm.settingForm, "content", $$v)
                      },
                      expression: "settingForm.content"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "min-width": "100px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.handConfirm }
                    },
                    [_vm._v("确认")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "min-width": "100px" },
                      on: { click: _vm.handCancel }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }