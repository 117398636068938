"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _api = require("./api.js");

var _mem = require("@/api/mem.js");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

var _public = require("@/utils/public.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default,
    addLoad: _addLoad.default
  },
  data: function data() {
    var _self = this;

    return {
      userLabelList: [],
      vipList: _public.vipList,
      timeEnd: null,
      timeStart: null,
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.startTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      pickerOptions: {},
      storeList: [],
      costList: {
        1: '集团',
        2: '门店',
        3: '专柜/品牌、商户'
      },
      rules: {
        labelContent: [{
          required: true,
          message: '请选择会员标签'
        }],
        'name': [{
          required: true,
          message: '请输入活动名称'
        }],
        'startTime': [{
          required: true,
          message: '请选择开始时间'
        }],
        'endTime': [{
          required: true,
          message: '请选择结束时间'
        }],
        inviteUserLvel: [{
          required: true,
          message: '请选择会员等级'
        }],
        fields: [{
          required: true,
          message: '请添加报名信息'
        }],
        detail: [{
          required: true,
          message: '请输入详情'
        }],
        limitPeople: [{
          required: true,
          message: '请输入限制人数'
        }],
        activityDesc: [{
          required: true,
          message: '请输入描述'
        }],
        storeCode: [{
          required: true,
          message: '请选择门店'
        }],
        activityImage: [{
          required: true,
          message: '请上传图片'
        }]
      },
      ruleForm: {
        labelContent: null,
        activityDesc: null,
        storeCode: null,
        activityImage: null,
        storeName: null,
        status: null,
        startTime: null,
        endTime: null,
        inviteUserLvel: [],
        name: null,
        detail: null,
        itemName: null,
        limitPeople: null,
        fields: []
      }
    };
  },
  created: function created() {
    var _this = this;

    this.apiPost('/api/category/label/listUserCategoryLabel', {}).then(function (res) {
      _this.userLabelList = [{
        id: 0,
        labelName: '所有会员'
      }].concat((0, _toConsumableArray2.default)(res.data));
    });
    this.ruleForm.id = this.$route.query.id;
    (0, _mem.listStore)({
      pageNo: 1,
      pageSize: 1000,
      storeStatus: 2
    }).then(function (res) {
      console.log(res);
      _this.storeList = res.data;
    });

    if (this.ruleForm.id) {
      (0, _api.getData)({
        id: this.ruleForm.id
      }).then(function (res) {
        res.data.fields.forEach(function (item, key) {
          item.sort = key + 1;
        });
        Object.keys(_this.ruleForm).forEach(function (item) {
          _this.ruleForm[item] = res.data[item];
        });
      });
    }
  },
  methods: {
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    delItem: function delItem(index) {
      this.ruleForm.fields.splice(index, 1);
    },
    delTag: function delTag(index, row) {
      this.ruleForm.fields[index].fileItem.splice(row, 1);
    },
    addItem: function addItem(index) {
      var fields = this.ruleForm.fields[index];
      if (fields.fileItem.includes(fields.itemName)) return;

      if (fields.itemName) {
        fields.fileItem.push(fields.itemName);
      }

      this.$refs.ruleForm.validateField('fields.' + index + '.fileItem');
    },
    addFields: function addFields() {
      this.ruleForm.fields.push({
        filed: null,
        requred: 0,
        filedType: 0,
        sort: null,
        itemName: null,
        fileItem: []
      });
    },
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.ruleForm));

          var storeData = _this2.storeList.find(function (item) {
            return item.storeCode === _this2.ruleForm.storeCode;
          });

          data.storeName = storeData.shortName;
          data.fields.sort(_this2.compare('sort'));

          if (_this2.ruleForm.id) {
            data.id = _this2.ruleForm.id;
            (0, _api.editData)(data).then(function (res) {
              _this2.$message({
                type: 'success',
                message: '编辑成功!'
              });

              _this2.$router.go(-1);
            });
          } else {
            (0, _api.addData)(data).then(function (res) {
              _this2.$router.go(-1);

              _this2.$message({
                type: 'success',
                message: '保存成功!'
              });
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;