"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addLoad: _addLoad.default
  },
  data: function data() {
    var _self = this;

    return {
      pickerTime: {
        disabledDate: function disabledDate(time) {
          var validBeginTime = new Date(_self.ruleForm.validStartTime).getTime();
          return time.getTime() < validBeginTime;
        }
      },
      rules: {
        title: [{
          required: true,
          message: '请输入名称'
        }],
        sort: [{
          required: true,
          message: '请输入排序'
        }],
        validStartTime: [{
          required: true,
          message: '请选择时间'
        }],
        validEndTime: [{
          required: true,
          message: '请选择时间'
        }],
        linkUrl: [{
          required: true,
          message: '请上传图片'
        }]
      },
      ruleForm: {
        id: null,
        title: null,
        validStartTime: null,
        validEndTime: null,
        linkUrl: null,
        sort: null
      }
    };
  },
  created: function created() {},
  methods: {
    setCover: function setCover(e, type) {
      this.ruleForm[type] = e;
    },
    submitForm: function submitForm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.addData)(_this.ruleForm).then(function (res) {
            _this.$message({
              message: '恭喜你，新增成功',
              type: 'success'
            });

            _this.$router.go(-1);
          });
          return;
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;