"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEnterprise = getEnterprise;
exports.getEnterprises = getEnterprises;
exports.makeInvoice = makeInvoice;
exports.queryInvoiceAmount = queryInvoiceAmount;
exports.saveEnterprise = saveEnterprise;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询经营主体
function getEnterprises(data) {
  return (0, _request.default)({
    url: "/api/invoice/listInvoiceCompanyPage",
    method: 'post',
    data: data
  });
} // 查询经营主体


function getEnterprise(id) {
  return (0, _request.default)({
    url: "/api/invoice/invoiceCompanyById/".concat(id),
    method: 'get'
  });
} // 编辑经营主体


function saveEnterprise(data) {
  return (0, _request.default)({
    url: "/api/invoice/updateInvoiceCompany",
    method: 'post',
    data: data
  });
} // 查询商品可开票金额或表格列表查询


function queryInvoiceAmount(data) {
  return (0, _request.default)({
    url: "/api/invoice/queryInvoiceAmount",
    method: 'post',
    data: data
  });
} // 查询商品可开票金额或表格列表查询


function makeInvoice(data) {
  return (0, _request.default)({
    url: "/api/invoice/makeInvoice",
    method: 'post',
    data: data
  });
}