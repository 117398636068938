"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _mem = require("@/api/mem.js");

var _coupon = require("@/api/coupon");

var _index = _interopRequireDefault(require("@/components/tble/index.vue"));

var _Link = _interopRequireDefault(require("@/layout/components/Sidebar/Link.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    wTable: _index.default,
    AppLink: _Link.default
  },
  data: function data() {
    return {
      time: [],
      memArr: [],
      obj: {
        pageNo: 1,
        pageSize: 10,
        couponName: '',
        // 优惠券名称
        couponType: '',
        // 优惠券类型
        couponCode: '',
        // 券编码
        memeberId: '',
        // 会员体系
        storeCode: '',
        // 门店编码
        memberMobile: '',
        // 会员手机号
        orderNo: '',
        // 订单编号
        sysMobile: '',
        // 核销人手机号
        startDate: '',
        // 开始时间
        endDate: '',
        // 结束时间
        time: [],
        total: ''
      },
      downloadLoading: false,
      deaPath: '/coupon/coupon2/recordDea?id=',
      operation: 2,
      btnType: 'primary',
      btn: '查看详情',
      total: 0,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      tabTh: [{
        label: '券编码',
        prop: 'couponId',
        align: 'center',
        width: 80
      }, {
        label: '券名称',
        prop: 'couponName',
        align: 'center',
        width: 100
      }, {
        label: '类型',
        prop: 'couponType',
        align: 'center',
        width: 50
      }, {
        label: '会员手机号',
        prop: 'uMobile',
        align: 'center',
        width: 50
      }, {
        label: '券领取编码',
        prop: 'couponCode',
        align: 'center',
        width: 50
      }, {
        label: '核销时间',
        prop: 'usedTime',
        align: 'center',
        width: 80
      }, {
        label: '订单号',
        prop: 'orderNo',
        align: 'center',
        width: 100
      }, {
        slot: 'operate'
        /* {
            	tit:"核销设备",
            	prop:'status'
            },
            {
            	tit:"核销人",
            	prop:'status'
            } */

      }],
      couponCode: '',
      couponName: '',
      options: [{
        value: 1,
        label: '代金券'
      }, {
        value: 2,
        label: '折扣券'
      }, {
        value: 3,
        label: '礼品券'
      }, {
        value: 4,
        label: '体验券'
      }, {
        value: 5,
        label: '外部优惠券'
      }],
      couponType: ''
    };
  },
  watch: {},

  /* destroyed() {
      console.log(this.obj)
      this.$store.dispatch('buffer/setCouponRecordList', this.obj)
    }, */
  created: function created() {
    var _this = this;

    (0, _mem.listCompany)({
      fullName: '',
      systemId: ''
    }).then(function (res) {
      _this.memArr = res.data;
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    setPage: function setPage(pageObj) {
      this.obj.pageNo = pageObj.pageNo;
      this.obj.pageSize = pageObj.pageSize;
      this.getList();
    },
    setBtn: function setBtn(row) {
      this.$router.push('/coupon/coupon2/recordDea?id=' + row.id);
    },
    getList: function getList(e) {
      var _this2 = this;

      if (e) {
        this.obj.pageNo = e.page;
        this.obj.pageSize = e.limit;
      }

      this.obj.time = this.obj.time ? this.obj.time : [];
      this.obj.startDate = this.obj.time[0];
      this.obj.endDate = this.obj.time[1];
      this.openLoading();
      (0, _coupon.couponLogHxPage)(this.obj).then(function (res) {
        _this2.openLoading().close();

        _this2.tableData = res.data.items;
        _this2.obj.total = res.data.totalNum;

        _this2.tableData.forEach(function (value) {
          if (value.couponType === 1) {
            value.couponType = '代金券';
          } else if (value.couponType === 2) {
            value.couponType = '折扣券';
          } else if (value.couponType === 3) {
            value.couponType = '礼品券';
          } else if (value.couponType === 4) {
            value.couponType = '体验券';
          }
        });
      }).catch(function (res) {
        _this2.openLoading().close();
      });
    },

    /* 导出Excel*/
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['券编码', '券名称', '券类型', '会员手机号', '券领取编码', '核销时间', '订单号', '核销人手机号', '核销人工号', '核销人姓名'];
        var filterVal = ['couponId', 'couponName', 'couponType', 'uMobile', 'couponCode', 'usedTime', 'orderNo', 'sysMobile', 'sysJobNumber', 'sysUsername'];
        var list = [];
        _this3.obj.time = _this3.obj.time ? _this3.obj.time : [];
        _this3.obj.startDate = _this3.obj.time[0];
        _this3.obj.endDate = _this3.obj.time[1];
        var obj = _this3.obj;
        obj.pageNo = 1;
        obj.pageSize = _this3.total;
        console.log(obj);
        (0, _coupon.couponLogHxPage)(obj).then(function (res) {
          list = res.data.items;
          list.forEach(function (value) {
            if (value.couponType === 1) {
              value.couponType = '代金券';
            } else if (value.couponType === 2) {
              value.couponType = '折扣券';
            } else if (value.couponType === 3) {
              value.couponType = '礼品券';
            } else if (value.couponType === 4) {
              value.couponType = '体验券';
            } else if (value.couponType === 5) {
              value.couponType = '外部优惠券';
            }
          });

          var data = _this3.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: '优惠券统计'
          });
          _this3.downloadLoading = false;
        }).catch(function (res) {
          _this3.downloadLoading = false;
        });
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return p;
          } else {
            return v[j];
          }
        });
      });
    },
    remove: function remove() {
      this.time = [];
      this.obj.pageNo = 1;
      this.obj.pageSize = 10;
      this.obj.couponName = '';
      this.obj.couponType = '';
      this.obj.couponCode = '';
      this.obj.memeberId = '';
      this.obj.storeCode = '';
      this.obj.memberMobile = '';
      this.obj.orderNo = '';
      this.obj.sysMobile = '';
      this.obj.time = [];
      this.getList();
    }
  }
};
exports.default = _default;