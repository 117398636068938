"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/Xinglico-test/mika-mikacrm/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/web.dom.iterable");

var _addLoad = _interopRequireDefault(require("@/components/addLoad"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    addLoad: _addLoad.default
  },
  props: {
    detailCtmfield: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      count: ''
    };
  },
  methods: {
    setCover: function setCover(index) {
      this.detailCtmfield[this.count].img = index;
      console.log(this.detailCtmfield[this.count]);
    },
    setCount: function setCount(index) {
      this.count = index;
    },
    adddetailCtmfield: function adddetailCtmfield() {
      this.detailCtmfield.push({
        sort: '',
        tit: '',
        text: ''
      });
    },
    deletedetailCtmfield: function deletedetailCtmfield(index) {
      this.detailCtmfield.splice(index, 1);
    },
    setdetailCtm: function setdetailCtm() {
      var _this = this;

      var arr = [];
      this.detailCtmfield.forEach(function (item, key) {
        if (item.tit != '' && item.text != '' && item.sort != '') {
          arr.push(item);
        }
      });

      if (arr.length < this.detailCtmfield.length) {
        this.$confirm('只保存信息填写完整的,是否继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this.$parent.setdetailCtmfield(arr);
        }).catch(function () {});
      } else {
        this.$parent.setdetailCtmfield(arr);
      }

      return;
      this.detailCtmfield.forEach(function (item, key) {
        if (item.tit != '' && item.text != '' && item.sort != '') {
          console.log(item);
        } else {
          _this.detailCtmfield.splice(key, 1);
        }
      });
      console.log(this.detailCtmfield);
      this.$emit('setdetailCtmfield', this.detailCtmfield);
    }
  }
};
exports.default = _default2;