var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "memberForm" },
      [
        _c(
          "el-descriptions",
          {
            attrs: {
              "label-style": _vm.labelStyle,
              border: "",
              column: 4,
              size: "mini"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "desTitle",
                attrs: { slot: "title" },
                slot: "title"
              },
              [_vm._v("基础信息")]
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "会员状态：" }
              },
              [
                _c("span", { class: _vm.status == 1 ? "success" : "danger" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.status == 1 ? "正常" : "封禁") +
                      "\n        "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "手机号码：" }
              },
              [
                _vm.user.mobile
                  ? _c("div", [
                      _vm.setMenuPerms("user_mobile_idCard")
                        ? _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.user.mobile) +
                                "\n          "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.user.mobile.replace(
                                    _vm.user.mobile.substring(3, 7),
                                    "****"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "会员类型：" }
              },
              [
                _vm._l(_vm.user.memberTypes, function(item, index) {
                  return _c(
                    "el-tag",
                    { key: index, staticStyle: { "margin-right": "5px" } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                _vm._v(" "),
                _vm.user.lxPayStatus == 1
                  ? _c("el-tag", [_vm._v("要客会员")])
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "注册时间：" }
              },
              [_vm._v(_vm._s(_vm.user.createTime))]
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "过去12月消费：" }
              },
              [_vm._v(_vm._s(_vm.user.consumeOneYear))]
            ),
            _vm._v(" "),
            _vm.setMenuPerms("userScore:listUserScorePage")
              ? _c(
                  "el-descriptions-item",
                  {
                    staticClass: "memberForm_item",
                    attrs: { label: "会员积分：" }
                  },
                  [_vm._v("\n        " + _vm._s(_vm.user.score) + "\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.setMenuPerms("userScore:listUserScorePage")
              ? _c(
                  "el-descriptions-item",
                  {
                    staticClass: "memberForm_item",
                    attrs: { label: "乐享值：" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.user.growthValue) + "\n      "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "会员  UID：" }
              },
              [_vm._v(_vm._s(_vm.user.uid))]
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "真实姓名：", prop: "realname" }
              },
              [_vm._v("\n        " + _vm._s(_vm.user.realname) + "\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "会员昵称：", prop: "nickname" }
              },
              [_vm._v("\n        " + _vm._s(_vm.user.nickname) + "\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "身份证号：", prop: "idNo" }
              },
              [
                _vm.user.idNo
                  ? _c("div", [
                      _vm.setMenuPerms("user_mobile_idCard")
                        ? _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.user.idNo) +
                                "\n          "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.user.idNo.replace(
                                    _vm.user.idNo.substring(8, 16),
                                    "********"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "民族：", prop: "nation" }
              },
              [_vm._v("\n        " + _vm._s(_vm.user.nation) + "\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "性别：", prop: "sex" }
              },
              [
                _vm.user.sex == 1
                  ? _c("span", [_vm._v("男")])
                  : _vm.user.sex == 2
                  ? _c("span", [_vm._v("女")])
                  : _c("span", [_vm._v("未知")])
              ]
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "出生日期：", prop: "birthday" }
              },
              [_vm._v("\n        " + _vm._s(_vm.user.birthday) + "\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              {
                staticClass: "memberForm_item",
                attrs: { label: "手机号码归属地：" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.user.province) +
                    "-" +
                    _vm._s(_vm.user.city) +
                    "\n      "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.user.payStatus365 == 1
          ? _c(
              "el-descriptions",
              {
                staticStyle: { "margin-top": "20px" },
                attrs: {
                  "label-style": _vm.labelStyle,
                  border: "",
                  column: 4,
                  title: "365会员信息",
                  size: "mini"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "desTitle",
                    attrs: { slot: "title" },
                    slot: "title"
                  },
                  [_vm._v("365会员信息")]
                ),
                _vm._v(" "),
                _vm.setMenuPerms("mainuser:rebateStatus365") && _vm.user.mobile
                  ? _c(
                      "el-descriptions-item",
                      {
                        staticClass: "memberForm_item",
                        attrs: {
                          label: "365商品折扣状态：",
                          "label-width": "160"
                        }
                      },
                      [
                        _c("el-switch", {
                          attrs: {
                            value: _vm.user.rebateStatus365,
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949"
                          },
                          on: {
                            change: function($event) {
                              return _vm.goEditStatus($event, _vm.scope)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class:
                              _vm.user.rebateStatus365 == 1
                                ? "success"
                                : "danger",
                            staticStyle: { "margin-left": "10px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.user.rebateStatus365 == 1 ? "启用" : "停用"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.user.payStatus365 == 1 || _vm.user.lxPayStatus == 1
                  ? _c(
                      "el-descriptions-item",
                      {
                        staticClass: "memberForm_item",
                        attrs: { label: "总节省金额：", "label-width": "160" }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.user.savaAmountTotal) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.user.payStatus365 == 1 || _vm.user.lxPayStatus == 1
                  ? _c(
                      "el-descriptions-item",
                      {
                        staticClass: "memberForm_item",
                        attrs: {
                          label: "当前周期节省金额：",
                          "label-width": "160"
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.user.savaAmountInCurrentCycle) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  {
                    staticClass: "memberForm_item",
                    attrs: { label: "推广来源：" }
                  },
                  [
                    _vm.user.extensionUserName
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.user.extensionStoreName || "") +
                              "," +
                              _vm._s(_vm.user.extensionUserName || "") +
                              "," +
                              _vm._s(_vm.user.extensionTime || "") +
                              "\n        "
                          )
                        ])
                      : _c("div", [_vm._v(" 未知来源 ")])
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.lavant
          ? _c(
              "el-descriptions",
              {
                staticStyle: { "margin-top": "20px" },
                attrs: {
                  "label-style": _vm.labelStyle,
                  border: "",
                  column: 4,
                  title: "Lavant会员信息",
                  size: "mini"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "desTitle",
                    attrs: { slot: "title" },
                    slot: "title"
                  },
                  [_vm._v("Lavant会员信息")]
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  {
                    staticClass: "memberForm_item",
                    attrs: { label: "Lavant等级：" }
                  },
                  [
                    _vm.lavant.levelId >= _vm.lavant.consumeLevelId
                      ? _c("span", [_vm._v(_vm._s(_vm.lavant.levelName))])
                      : _vm.lavant.consumeLevelId >= _vm.lavant.levelId
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.lavant.consumeLevelName))
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  {
                    staticClass: "memberForm_item",
                    attrs: { label: "开始时间：" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.lavant.addTime) + "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  {
                    staticClass: "memberForm_item",
                    attrs: { label: "当前余额：" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/deposit/onLine/orderList?mobile=" +
                                _vm.user.mobile
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.user.storeCardBalance) +
                            " "
                        ),
                        _c("span", { staticClass: "el-icon-arrow-right" })
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  {
                    staticClass: "memberForm_item",
                    attrs: { label: "要客会员：" }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.wPage(
                              "/svip/member/detail/" + _vm.user.mobile
                            )
                          }
                        }
                      },
                      [_vm._v("查看详情")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.user.lxPayStatus == 1 &&
                _vm.setMenuPerms("mainuser:rebateStatus365") &&
                _vm.user.mobile
                  ? _c(
                      "el-descriptions-item",
                      {
                        staticClass: "memberForm_item",
                        attrs: { label: "授权代买单：", "label-width": "160" }
                      },
                      [
                        _c("el-switch", {
                          attrs: {
                            value: _vm.user.isAuthorized,
                            "active-value": 2,
                            size: "mini",
                            "inactive-value": 1,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949"
                          },
                          on: {
                            change: function($event) {
                              return _vm.goEditSvip($event, _vm.scope)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class:
                              _vm.user.isAuthorized == 2 ? "success" : "danger",
                            staticStyle: { "margin-left": "10px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.user.isAuthorized == 2 ? "启用" : "停用"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  {
                    staticClass: "memberForm_item",
                    attrs: { label: "要客经理：" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.lavant.managerName) + "\n      "
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _vm.isListByParam
      ? _c("div", { staticClass: "fixed_center flex_center" }, [
          _c(
            "div",
            { staticClass: "pop_box" },
            [
              _c(
                "div",
                { staticClass: "pop_box_top flex_between" },
                [
                  _c("div", { staticClass: "pop_box_top_title" }, [
                    _vm._v(" 修改记录 ")
                  ]),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: { icon: "el-icon-close", circle: "" },
                    on: {
                      click: function($event) {
                        _vm.isListByParam = false
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("w-table", {
                attrs: {
                  height: "500",
                  "table-data": _vm.tableData,
                  columns: _vm.columns
                }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }