"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      storeList: [],
      cardData: {},
      rules: {
        userName: [{
          required: true,
          message: '请输入用户姓名'
        }],
        phone: [{
          required: true,
          message: '请输入手机号码'
        }],
        idCard: [{
          required: true,
          message: '请输入身份证号码'
        }],
        cardNo: [{
          required: true,
          message: '请刷卡查询'
        }]
      },
      select: 1,
      ruleForm: {
        cardNo: null,
        userName: null,
        phone: null,
        idCard: null
      }
    };
  },
  created: function created() {
    this.ruleForm.cardNo = this.$route.query.id;

    if (this.ruleForm.cardNo) {
      this.goCardInfo();
    }
  },
  methods: {
    goCardInfo: function goCardInfo() {
      var _this = this;

      this.cardData = {};
      var data = {};
      data[this.select === 1 ? 'cardNo' : 'magstripeCode'] = this.ruleForm.cardNo;
      (0, _api.getCardInfo)(data).then(function (res) {
        _this.cardData = res.data;
      });
    },
    clearForm: function clearForm(ruleForm) {
      this.cardData = {};
      this.$refs[ruleForm].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _api.getCardName)(_this2.ruleForm).then(function (res) {
            _this2.$message({
              type: 'success',
              message: '实名成功成功!'
            });

            _this2.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;