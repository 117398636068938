var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apply-tag-form" },
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v("\n        " + _vm._s(_vm.$route.meta.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { size: "samll", model: _vm.params, "label-width": "180px" }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "input-width", attrs: { label: "标签名称" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择机构" } },
            [
              _c("el-select", {
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择店铺范围" } },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { width: "100%" },
                  model: {
                    value: _vm.params.name,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "name", $$v)
                    },
                    expression: "params.name"
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("指定业态")
                      ]),
                      _vm._v(" "),
                      _c("el-select", {
                        model: {
                          value: _vm.params.name,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "name", $$v)
                          },
                          expression: "params.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("指定品牌")
                      ]),
                      _vm._v(" "),
                      _c("el-select", {
                        model: {
                          value: _vm.params.name,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "name", $$v)
                          },
                          expression: "params.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("选择具体店铺")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "mini", type: "primary" } },
                            [_vm._v("添加店铺")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            { attrs: { size: "mini", type: "primary" } },
                            [_vm._v("批量导入")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            { attrs: { size: "mini", type: "text" } },
                            [_vm._v("下载模板")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%", "margin-top": "10px" },
                          attrs: {
                            data: _vm.params.list,
                            size: "mini",
                            "header-cell-style": { background: "#f5f5f5" },
                            border: ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              align: "center",
                              width: "80"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "店铺号",
                              prop: "pageTitle",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "店铺名称",
                              prop: "pageUrl",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "品牌名称",
                              prop: "createTime",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "业态类型",
                              prop: "createTime",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "商圈类型",
                              prop: "createTime",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "经营状态",
                              prop: "createTime",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              prop: "action",
                              width: "150",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_c("el-button", [_vm._v("删除")])]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择商品码" } },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { width: "100%" },
                  model: {
                    value: _vm.params.name,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "name", $$v)
                    },
                    expression: "params.name"
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("指定商品分类")
                      ]),
                      _vm._v(" "),
                      _c("el-select", {
                        model: {
                          value: _vm.params.name,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "name", $$v)
                          },
                          expression: "params.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("选择具体商品")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "mini", type: "primary" } },
                            [_vm._v("添加商品")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            { attrs: { size: "mini", type: "primary" } },
                            [_vm._v("批量导入")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            { attrs: { size: "mini", type: "text" } },
                            [_vm._v("下载模板")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%", "margin-top": "10px" },
                          attrs: {
                            data: _vm.params.list,
                            size: "mini",
                            "header-cell-style": { background: "#f5f5f5" },
                            border: ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              align: "center",
                              width: "80"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品编码",
                              prop: "pageTitle",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品名称",
                              prop: "pageUrl",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品价格",
                              prop: "createTime",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品分类",
                              prop: "createTime",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "品牌名称",
                              prop: "createTime",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "状态",
                              prop: "createTime",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              prop: "action",
                              width: "150",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_c("el-button", [_vm._v("删除")])]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "input-width", attrs: { label: "备注" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", autosize: { minRows: 5 } },
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "actions" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }