"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      columns: [{
        label: '姓名',
        prop: 'realname',
        width: 50
      }, {
        label: '手机号码',
        prop: 'mobile',
        width: 100
      }, {
        label: '要客等级',
        prop: 'levelName',
        width: 60
      }, {
        label: '变动单号',
        prop: 'changeOrderNo',
        width: 120
      },
      /* {
         slot:"status"
       }, */
      {
        label: '变动时间',
        prop: 'createTime',
        width: 80
      },
      /* {
         label: '变动事件',
         prop: 'eventName',
         width: 80,
       }, */
      {
        label: '上次结束时间',
        prop: 'oldSvipEndDate',
        width: 50
      }, {
        label: '备注',
        prop: 'content',
        width: 200,
        align: 'left'
      }, {
        slot: 'update'
      }],
      searchData: [{
        type: 'input',
        placeholder: '用户手机号',
        value: 'mobile'
      }, {
        type: 'input',
        placeholder: '用户手姓名',
        value: 'username'
      }, {
        type: 'input',
        placeholder: '变动关键字',
        value: 'content'
      }, {
        type: 'dateTime',
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }]
    };
  },
  created: function created() {},
  methods: {}
};
exports.default = _default;