var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "departmentDea" },
    [
      _c(
        "WForm",
        {
          attrs: {
            "form-data": _vm.formData,
            "label-width": "180px",
            "rule-form": _vm.ruleForm,
            rules: _vm.rules
          },
          on: { changeForm: _vm.changeForm, submitForm: _vm.submitForm }
        },
        [
          _vm.ruleForm.type == 1
            ? _c(
                "div",
                { attrs: { slot: "param" }, slot: "param" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "channelTypeNo",
                      attrs: { label: "支付通道类型：", prop: "channelTypeNo" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form_input",
                          attrs: { clearable: "", filterable: "" },
                          on: { change: _vm.channekChange },
                          model: {
                            value: _vm.ruleForm.channelTypeNo,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "channelTypeNo", $$v)
                            },
                            expression: "ruleForm.channelTypeNo"
                          }
                        },
                        _vm._l(_vm.chanelList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.chanelName,
                              value: item.chanelTypeNo
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.paramList, function(item, index) {
                    return _c(
                      "el-form-item",
                      {
                        key: index,
                        ref: item.column1,
                        refInFor: true,
                        attrs: {
                          label: item.columDesc + "(" + item.column1 + ")：",
                          prop: "param." + item.column1,
                          rules: _vm.rules[item.column1]
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "form_input",
                          attrs: {
                            type: "text",
                            placeholder: "请输入" + item.columDesc
                          },
                          model: {
                            value: _vm.ruleForm.param[item.column1],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ruleForm.param,
                                item.column1,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.param[item.column1]"
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "merchantType" }, slot: "merchantType" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "收银类型：", prop: "merchantType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.ruleForm.type == 2 },
                      model: {
                        value: _vm.ruleForm.merchantType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "merchantType", $$v)
                        },
                        expression: "ruleForm.merchantType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("自收银")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("集团收银")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.type == 2
            ? _c(
                "div",
                { attrs: { slot: "channelTypeNo" }, slot: "channelTypeNo" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "支付通道编码：", prop: "channelTypeNo" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form_input",
                        attrs: { placeholder: "`请输入支付通道编码" },
                        model: {
                          value: _vm.ruleForm.channelTypeNo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "channelTypeNo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.channelTypeNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "type" }, slot: "type" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "通道类型：", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changType },
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("聚合")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("非聚合")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }