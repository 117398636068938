var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("新增/编辑充值有礼")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "160px"
          }
        },
        [
          _c(
            "el-form-item",
            { ref: "ruleName", attrs: { label: "方案名称", prop: "ruleName" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                model: {
                  value: _vm.ruleForm.ruleName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "ruleName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.ruleName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "time", attrs: { label: "活动时间", prop: "time" } },
            [
              _c("el-date-picker", {
                staticClass: "margin_top coupon_input",
                attrs: {
                  size: "small",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.ruleForm.time,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "time", $$v)
                  },
                  expression: "ruleForm.time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "appointCard",
              attrs: { label: "是否指定返赠星礼卡", prop: "appointCard" }
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.appointCard,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "appointCard", $$v)
                    },
                    expression: "ruleForm.appointCard"
                  }
                },
                [_vm._v(" 指定")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.ruleForm.appointCard,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "appointCard", $$v)
                    },
                    expression: "ruleForm.appointCard"
                  }
                },
                [_vm._v("不指定")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.appointCard == 1
            ? _c(
                "el-form-item",
                {
                  ref: "cardCode",
                  attrs: { label: "星礼卡编码", prop: "cardCode" }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "340px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm.cardCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "cardCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.cardCode"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.getAward } },
                    [_vm._v("选择")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "ruleStatus",
              attrs: { label: "事件状态", prop: "ruleStatus" }
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.ruleStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "ruleStatus", $$v)
                    },
                    expression: "ruleForm.ruleStatus"
                  }
                },
                [_vm._v(" 启用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.ruleForm.ruleStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "ruleStatus", $$v)
                    },
                    expression: "ruleForm.ruleStatus"
                  }
                },
                [_vm._v("停用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              ref: "ruleRemark",
              attrs: { label: "方案备注", prop: "ruleRemark" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 }
                },
                model: {
                  value: _vm.ruleForm.ruleRemark,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "ruleRemark",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.ruleRemark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { ref: "ladders", attrs: { label: "发放券信息", prop: "ladders" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.addLadders }
                },
                [_vm._v("添加")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  attrs: {
                    size: "mini",
                    data: _vm.ruleForm.ladders,
                    border: "",
                    "header-cell-class-name": "table_header_cell"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      label: "充值金额",
                      size: "mini"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "ladders." + scope.$index + ".topupAmount",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入充值金额"
                                    }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: scope.row.topupAmount,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "topupAmount", $$v)
                                    },
                                    expression: "scope.row.topupAmount"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      label: "名额限制"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "ladders." + scope.$index + ".peopleLimit",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入名额限制"
                                    }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "mini", type: "number" },
                                  model: {
                                    value: scope.row.peopleLimit,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "peopleLimit", $$v)
                                    },
                                    expression: "scope.row.peopleLimit"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "200",
                      align: "center",
                      label: "触发事件"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop: "ladders." + scope.$index + ".eventIds",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择触发事件"
                                    }
                                  ]
                                }
                              },
                              [
                                _vm._l(scope.row.eventIds, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "el-button",
                                    {
                                      key: index,
                                      attrs: { type: "info", disabled: "" }
                                    },
                                    [_vm._v(_vm._s(item.eventName))]
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.getRelevances(scope.$index)
                                      }
                                    }
                                  },
                                  [_vm._v("选择触发事件")]
                                )
                              ],
                              2
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "120", align: "center", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.delLadders(
                                      scope.$index,
                                      scope.row
                                    )
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即创建")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAward", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShows,
            expression: "couponShows"
          }
        ],
        ref: "coupon1",
        attrs: { type: "card", "coupon-show": _vm.couponShows },
        on: {
          "update:couponShow": function($event) {
            _vm.couponShows = $event
          },
          "update:coupon-show": function($event) {
            _vm.couponShows = $event
          },
          setCoupons: _vm.setCoupon
        }
      }),
      _vm._v(" "),
      _c("coupon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.couponShow,
            expression: "couponShow"
          }
        ],
        ref: "couponPop",
        attrs: { type: "event" },
        on: { setCoupons: _vm.setCoupons, closeCouponPop: _vm.closeCouponPop }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }