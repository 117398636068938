var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v("\n  库存-详情\n  "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.goBack } },
            [_c("i", { staticClass: "el-icon-back" }), _vm._v(" 返回")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }