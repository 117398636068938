var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "temBox ",
        style: {
          width: _vm.data.moduleAttr.img1Width / 2 + "px",
          height: _vm.data.moduleAttr.img1height / 2 + "px",
          background: "url(" + (_vm.imgSrc + _vm.data.moduleAttr.img) + ")",
          backgroundSize: "100% 100%",
          margin: "0 auto"
        }
      },
      [
        _c("div", { staticClass: "lgXin_top flex_center" }, [
          _vm.data.moduleAttr.text
            ? _c("div", { staticClass: "lgXin_top_title" }, [
                _vm._v("\n  \t\t" + _vm._s(_vm.data.moduleAttr.text) + "\n  \t")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.data.moduleAttr.text2
            ? _c("div", { staticClass: "lgXin_top_remark" }, [
                _vm._v(
                  "\n  \t\t" + _vm._s(_vm.data.moduleAttr.text2) + "\n  \t"
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex_around" },
          _vm._l(_vm.showGoodsList, function(item, index) {
            return _c("div", { key: index, staticClass: "temBox_item" }, [
              _c("img", {
                staticClass: "temBox_item_cover",
                attrs: { src: _vm.imgSrc + item.cover }
              }),
              _vm._v(" "),
              _c("div", {}, [
                _c("div", { staticClass: "temBox_item_name text_ellipsis" }, [
                  _vm._v("\n         " + _vm._s(item.name) + "\n        ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "temBox_item_price" }, [
                  _vm._v("\n          商品价格\n        ")
                ])
              ])
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "lgXin_more flex_center" }, [
          _vm._v("\n  \t查看更多\n  ")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }