var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "activity" }, [
    _c("img", {
      staticClass: "activity_cover",
      attrs: { src: _vm.imgSrc + _vm.data.thumbnailPath }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }