var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title" }, [_vm._v("编辑返券活动范围")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            size: "small"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "返券活动范围名称：", prop: "rangeName" } },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.ruleForm.rangeName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "rangeName", $$v)
                  },
                  expression: "ruleForm.rangeName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择总部：", prop: "companyCode" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "maxWidth",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.setShort },
                  model: {
                    value: _vm.ruleForm.companyCode,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "companyCode", $$v)
                    },
                    expression: "ruleForm.companyCode"
                  }
                },
                _vm._l(_vm.companyList, function(item) {
                  return _c("el-option", {
                    key: item.companyCode,
                    attrs: { label: item.shortName, value: item.companyCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "门店：", prop: "storeCodes" } },
            [
              _c(
                "div",
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.shortNameClick }
                        },
                        [_vm._v(" 选择门店")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        "max-width": "500px",
                        "margin-top": "10px"
                      },
                      attrs: {
                        "max-height": "500px",
                        border: "",
                        size: "mini",
                        data: _vm.storeArr
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          "min-width": "10"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.$index + 1) +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "attrCode",
                          "min-width": "30",
                          label: "门店编码"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "attrName",
                          "min-width": "60",
                          label: "门店名称"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品码：", prop: "goodsStatus" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.goodsStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "goodsStatus", $$v)
                    },
                    expression: "ruleForm.goodsStatus"
                  }
                },
                [_vm._v("可用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.ruleForm.goodsStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "goodsStatus", $$v)
                    },
                    expression: "ruleForm.goodsStatus"
                  }
                },
                [_vm._v("不可用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品码：", prop: "goodsCodes" } },
            [
              _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.downloadUrl,
                            download: "商品.xlsx"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-download"
                              }
                            },
                            [_vm._v(" 下载模版")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("upload-excel-component", {
                        attrs: {
                          "btn-text": "导入商品编码",
                          type: "btn",
                          "on-success": _vm.handleSuccess,
                          "before-upload": _vm.beforeUpload
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        "max-width": "500px",
                        "margin-top": "10px"
                      },
                      attrs: {
                        "max-height": "500px",
                        border: "",
                        size: "mini",
                        data: _vm.goosList
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          "min-width": "10"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.$index + 1) +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "goodsCode",
                          "min-width": "30",
                          label: "商品码"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "goodsName",
                          "min-width": "60",
                          label: "商品名称"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "rangeRemark" } },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 },
                  placeholder: "输入备注"
                },
                model: {
                  value: _vm.ruleForm.rangeRemark,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "rangeRemark", $$v)
                  },
                  expression: "ruleForm.rangeRemark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即创建")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.shortNameShow,
              expression: "shortNameShow"
            }
          ],
          staticClass: "pop_shop"
        },
        [
          _c(
            "div",
            { staticClass: "pop_box" },
            [
              _c("div", { staticClass: "close_btn" }, [
                _c("i", {
                  staticClass: "el-icon-circle-close",
                  on: {
                    click: function($event) {
                      _vm.shortNameShow = false
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "shortTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "500",
                    size: "mini",
                    "row-key": "storeCode",
                    data: _vm.storeList.filter(function(data) {
                      return (
                        !_vm.shortNameSearch ||
                        data.fullName
                          .toLowerCase()
                          .includes(_vm.shortNameSearch.toLowerCase())
                      )
                    }),
                    "tooltip-effect": "dark"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "reserve-selection": true,
                      type: "selection",
                      width: "50"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "storeCode",
                      label: "门店编码",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fullName",
                      label: "门店名称",
                      align: "center"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: {
                                size: "mini",
                                placeholder: "输入门店名称搜索"
                              },
                              model: {
                                value: _vm.shortNameSearch,
                                callback: function($$v) {
                                  _vm.shortNameSearch = $$v
                                },
                                expression: "shortNameSearch"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table_btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        size: "mini"
                      },
                      on: { click: _vm.clearShort }
                    },
                    [_vm._v("清空")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: { click: _vm.affirmShort }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }