var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "box_tit" }, [_vm._v("批量导入物流")]),
      _vm._v(" "),
      _c(
        "el-descriptions",
        {
          staticStyle: { "margin-top": "30px" },
          attrs: {
            labelStyle: {
              width: "130px"
            },
            title: "物流公司对应编码",
            column: 5,
            border: ""
          }
        },
        _vm._l(_vm.orderExpress, function(item, index) {
          return _c(
            "el-descriptions-item",
            { key: index, attrs: { label: item.name } },
            [_vm._v(_vm._s(item.code))]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "30px" },
          attrs: { size: "small", "label-width": "90px" }
        },
        [
          _c("el-form-item", { attrs: { label: "上传数据：" } }, [
            _c(
              "div",
              { staticClass: "add_btn" },
              [
                _c(
                  "a",
                  {
                    staticStyle: { "margin-right": "30px" },
                    attrs: {
                      href: _vm.downloadUrl,
                      download: "批量导入物流模板.xlsx"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "upLoad",
                        attrs: {
                          type: "success",
                          size: "mini",
                          icon: "el-icon-download"
                        }
                      },
                      [_vm._v("下载模板")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("upload-excel-component", {
                  attrs: {
                    "btn-text": "批量上传",
                    type: "btn",
                    "on-success": _vm.handleSuccess,
                    "before-upload": _vm.beforeUpload
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "导入列表：" } },
            [
              _c(
                "w-table",
                {
                  attrs: {
                    height: "500",
                    "table-data": _vm.orders,
                    columns: _vm.columns
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      slot: "del",
                      label: "操作",
                      align: "center",
                      fixed: "right",
                      width: "100"
                    },
                    slot: "del",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  cursor: "pointer"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.delList(scope.$index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _vm.setMenuPerms("mallOrder:impLogisticNo")
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        type: "primary",
                        disabled: _vm.orders.length === 0
                      },
                      on: { click: _vm.goUpload }
                    },
                    [_vm._v("保存")]
                  )
                : _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "danger", disabled: "" }
                    },
                    [_vm._v("暂无权限,请联系管理员")]
                  )
            ],
            1
          ),
          _vm._v(" "),
          _vm.orders1.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: "信息残缺：" } },
                [
                  _c("w-table", {
                    attrs: {
                      height: "500",
                      "table-data": _vm.orders1,
                      columns: _vm.columns
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }