"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      imgSrc: this.$store.state.user.imgSrc,
      tableData: [],
      requestData: {
        name: null,
        account: null,
        pageSize: 10,
        page: 1
      },
      searchData: [{
        type: 'input',
        span: 4,
        placeholder: '请输入优惠券名称',
        value: 'couponName'
      }, {
        type: 'input',
        span: 3,
        placeholder: '券编码',
        value: 'couponCode'
      }, {
        type: 'input',
        span: 2,
        placeholder: '兑换码',
        value: 'receivedCode'
      }, {
        type: 'input',
        span: 4,
        placeholder: '操作人手机号',
        value: 'exportPhone'
      }, {
        type: 'dateTime',
        span: 4,
        placeholder: '开始时间',
        value: 'startTime'
      }, {
        type: 'dateTime',
        span: 4,
        placeholder: '结束时间',
        value: 'endTime',
        defaultTime: '23:59:59'
      }]
    };
  },
  methods: {}
};
exports.default = _default;