var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "view_title " }, [
        _vm._v(" 编辑提货券库存\n  ")
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "140px",
            size: "small"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "提货卡名称：", prop: "giftName" } },
            [_vm._v("\n      " + _vm._s(_vm.name) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "提货卡编码：", prop: "giftName" } },
            [_vm._v("\n      " + _vm._s(_vm.uuid) + "\n    ")]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "总库存：" } }, [
            _vm._v("\n      " + _vm._s(_vm.number) + "\n    ")
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "当日库存：" } }, [
            _vm._v("\n      " + _vm._s(_vm.surplusNumber) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "修改总库存：", prop: "number" } },
            [
              _c("el-input", {
                staticClass: "maxWidth",
                attrs: { type: "number" },
                model: {
                  value: _vm.ruleForm.number,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "number", $$v)
                  },
                  expression: "ruleForm.number"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.tableShow
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "40px" },
              attrs: {
                size: "mini",
                data: _vm.list,
                border: "",
                "header-cell-class-name": "table_header_cell"
              }
            },
            [
              _c("el-table-column", {
                attrs: { width: "80", align: "center", label: "序号" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                (_vm.pageNo - 1) * _vm.pageSize +
                                  scope.$index +
                                  1
                              ) +
                              "\n      "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  317802593
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.tabTh, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    align: "center",
                    "min-width": item.width
                  }
                })
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: { total: _vm.total, page: _vm.pageNo, limit: _vm.pageSize },
        on: {
          "update:page": function($event) {
            _vm.pageNo = $event
          },
          "update:limit": function($event) {
            _vm.pageSize = $event
          },
          pagination: _vm.init
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }