var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "140px",
            rules: _vm.rules,
            size: "mini",
            model: _vm.form
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "运费模板名称", prop: "freightName" } },
            [
              _c("el-input", {
                staticClass: "inpput_width",
                model: {
                  value: _vm.form.freightName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "freightName", $$v)
                  },
                  expression: "form.freightName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "配送区域及运费" } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    size: "mini",
                    data: _vm.form.areaList,
                    border: "",
                    "header-cell-class-name": "table_header_cell"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      label: "可配送区域"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.areaCode == "000000"
                              ? _c("div", [_vm._v("默认全国")])
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          "label-width": "0",
                                          prop:
                                            "areaList." +
                                            scope.$index +
                                            ".areaCode",
                                          required: "",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择可配送区域"
                                            }
                                          ]
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              filterable: "",
                                              placeholder: "请选择"
                                            },
                                            model: {
                                              value: scope.row.areaCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "areaCode",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.areaCode"
                                            }
                                          },
                                          _vm._l(_vm.regionData, function(
                                            item
                                          ) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.label
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      label: "首件",
                      size: "mini"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "areaList." + scope.$index + ".firstNumber",
                                  required: "",
                                  rules: [
                                    { required: true, message: "请输入首件" }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.firstNumber,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "firstNumber", $$v)
                                    },
                                    expression: "scope.row.firstNumber"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      label: "运费"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "areaList." + scope.$index + ".firstAmount",
                                  required: "",
                                  rules: [
                                    { required: true, message: "请输入运费" }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: scope.row.firstAmount,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "firstAmount", $$v)
                                    },
                                    expression: "scope.row.firstAmount"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      label: "续件"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "areaList." +
                                    scope.$index +
                                    ".secondNumber",
                                  required: "",
                                  rules: [
                                    { required: true, message: "请输入续件" }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: scope.row.secondNumber,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "secondNumber", $$v)
                                    },
                                    expression: "scope.row.secondNumber"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      label: "续费"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "areaList." +
                                    scope.$index +
                                    ".secondAmount",
                                  required: "",
                                  rules: [
                                    { required: true, message: "请输入续费" }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: scope.row.secondAmount,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "secondAmount", $$v)
                                    },
                                    expression: "scope.row.secondAmount"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "120", align: "center", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.areaCode != "000000"
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "table_td_btn",
                                    on: {
                                      click: function($event) {
                                        return _vm.delAssign(
                                          scope.row,
                                          scope.$index,
                                          "area"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: {
                    click: function($event) {
                      return _vm.goAddFreight(1)
                    }
                  }
                },
                [_vm._v("添加配送区域")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "指定包邮" } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    size: "mini",
                    data: _vm.form.assignList,
                    border: "",
                    "header-cell-class-name": "table_header_cell"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      label: "选择地区"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "assignList." + scope.$index + ".areaCode",
                                  required: "",
                                  rules: [
                                    { required: true, message: "选择地区" }
                                  ]
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: scope.row.areaCode,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "areaCode", $$v)
                                      },
                                      expression: "scope.row.areaCode"
                                    }
                                  },
                                  _vm._l(_vm.regionData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.label
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      label: "包邮件数"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "assignList." +
                                    scope.$index +
                                    ".firstNumber",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入包邮件数"
                                    }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: scope.row.firstNumber,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "firstNumber", $$v)
                                    },
                                    expression: "scope.row.firstNumber"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      label: "包邮积分"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "assignList." +
                                    scope.$index +
                                    ".secondAmount",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入包邮件数"
                                    }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: scope.row.secondAmount,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "secondAmount", $$v)
                                    },
                                    expression: "scope.row.secondAmount"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      label: "包邮金额"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0",
                                  prop:
                                    "assignList." +
                                    scope.$index +
                                    ".firstAmount",
                                  required: "",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入包邮金额"
                                    }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: scope.row.firstAmount,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "firstAmount", $$v)
                                    },
                                    expression: "scope.row.firstAmount"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "120", align: "center", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "table_td_btn",
                                on: {
                                  click: function($event) {
                                    return _vm.delAssign(
                                      scope.row,
                                      scope.$index,
                                      "assign"
                                    )
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: {
                    click: function($event) {
                      return _vm.goAddFreight(2)
                    }
                  }
                },
                [_vm._v("添加指定包邮")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "freightStatus" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.form.freightStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "freightStatus", $$v)
                    },
                    expression: "form.freightStatus"
                  }
                },
                [_vm._v("启用")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.form.freightStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "freightStatus", $$v)
                    },
                    expression: "form.freightStatus"
                  }
                },
                [_vm._v("停用")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "form_item", attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "max-width": "200px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }