var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "view_title flex_between" }, [
        _vm._v(" 首页弹窗管理 "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticStyle: { "max-width": "100%" },
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-circle-plus-outline"
                },
                on: {
                  click: function($event) {
                    return _vm.wPage("/template/popIndex")
                  }
                }
              },
              [_vm._v("新增")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: "弹框名称" },
                model: {
                  value: _vm.requestData.productCode,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.requestData,
                      "productCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "requestData.productCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.getList("search")
                }
              }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "mini", icon: "el-icon-refresh" },
              on: {
                click: function($event) {
                  return _vm.getList("remove")
                }
              }
            },
            [_vm._v("重置")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c(
        "w-table",
        {
          attrs: {
            "table-data": _vm.tableData,
            columns: _vm.columns,
            "page-obj": _vm.requestData
          },
          on: { pagination: _vm.setPage }
        },
        [
          _c("el-table-column", {
            attrs: {
              slot: "popupRate",
              label: "弹出频率",
              align: "center",
              width: "80"
            },
            slot: "popupRate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.popupRate == 1
                      ? _c("span", [_vm._v("仅一次")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.popupRate == 2
                      ? _c("span", [_vm._v("每日一次")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.popupRate == 3
                      ? _c("span", [_vm._v("每次进入")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "labelContent",
              label: "显示人群标签",
              align: "center",
              "mni-width": "100"
            },
            slot: "labelContent",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getUserLabel(scope.row.labelContent)) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "popupStatus",
              label: "状态",
              align: "center",
              "min-width": "80"
            },
            slot: "popupStatus",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.popupStatus === 1
                      ? _c("span", { staticClass: "success" }, [_vm._v("启用")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.popupStatus === 0
                      ? _c("span", { staticClass: "danger" }, [_vm._v("禁用")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "contentType",
              label: "关联内容",
              align: "center",
              "min-width": "80"
            },
            slot: "contentType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.contentType === "4"
                      ? _c("div", [
                          scope.row.appid
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.appIdArr.find(function(item) {
                                        return item.value === scope.row.appid
                                      }).label
                                    ) +
                                    "\n              ：\n            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.contentLink) +
                              "\n          "
                          )
                        ])
                      : scope.row.contentType === "5"
                      ? _c("div", [
                          _vm._v(
                            "\n            web页面：\n            " +
                              _vm._s(scope.row.contentLink) +
                              "\n          "
                          )
                        ])
                      : scope.row.contentType
                      ? _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.urlList.find(function(item) {
                                  return item.value === scope.row.contentType
                                }).label
                              ) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "popupImg",
              label: "弹框图",
              align: "center",
              width: "130"
            },
            slot: "popupImg",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "108px", height: "120px" },
                      attrs: { src: _vm.imgSrc + scope.row.popupImg }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              slot: "operate",
              label: "操作",
              align: "center",
              fixed: "right",
              width: "100"
            },
            slot: "operate",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "app-link",
                      {
                        staticStyle: { color: "rgb(24, 144, 255)" },
                        attrs: { to: "/template/popIndex?id=" + scope.row.id }
                      },
                      [_vm._v(" 编辑\n          ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }